import { createAction } from '@reduxjs/toolkit';
import { ClientLibraryRequest } from 'core/models/ClientLibrary.model';

export const getClientLibrary = createAction<{ year: number }>(
  '[client-library-report] GET_CLIENT_LIRBARY_REPORT',
);
export const storeCLientLibrary = createAction<ClientLibraryRequest[]>(
  '[client-library-report] STORE_CLIENT_LIRBARY_REPORT',
);
export const clearClientLibrary = createAction(
  '[client-library-report] CLEAR_CLIENT_LIRBARY_REPORT',
);


export const getClientFolder = createAction<{ folderPath: string }>(
  '[client-library-report] GET_CLIENT_FOLDER',
);
export const storeClientFolder = createAction<string>(
  '[client-library-report] STORE_CLIENT_FOLDER',
);
export const clearClientFolder = createAction(
  '[client-library-report] CLEAR_CLIENT_FOLDER',
);




