import { FieldInputSettings, FormMethods } from 'core/components/form-controls/types';
import { Control, FieldValues, useFieldArray } from 'react-hook-form';
import React, { useImperativeHandle, useState } from 'react';
import { ControlDatePickerGrp, InputGrp, SelectGrp } from 'core/components/form-controls';
import { AcaStatus, Employee } from 'core/models';
import { useDispatch } from 'react-redux';
import { createEmployeeACAStatus, deleteEmployeeACAStatus, handleError, updateEmployeeACAStatus } from 'core/store/actions';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import Icon from 'core/components/shared/Icon';
import { getAccess } from 'utilities/utilities';
import { useAppSelector } from 'utilities/hooks';
import { ControlIds } from 'core/constants';

const acaStatusOpts = [
  {
    id: 'F',
    description: 'Full Time',
  },
  {
    id: 'P',
    description: 'Variable Hours',
  },
  {
    id: 'S',
    description: 'Seasonal',
  },
  {
    id: 'O',
    description: 'Owner',
  },
  {
    id: 'N',
    description: 'Not Applicable',
  },
];

const fs: FieldInputSettings = {
  effectiveACAStatus: {
    name: 'effectiveACAStatus',
    label: 'Effective ACA Status',
  },
  effectiveDate: {
    name: 'effectiveDate',
    label: '',
  },
  status: {
    name: 'status',
    label: '',
  },
};

type Methods = {
  errors: FormMethods['errors'];
  register: FormMethods['register'];
  setValue: FormMethods['setValue'];
  getValues: FormMethods['getValues'];
  watch: FormMethods['watch'];
};

type PropTypes = {
  formMethods: Methods;
  control: Control<FieldValues> | undefined;
  protectedEmpNo: string;
  selEmp: Employee | null;
  protectedClientNo: string;
  clientNo: number;
  eligible: boolean,
  acaStatus: AcaStatus[];
};

const ACAStatus = React.forwardRef((
  {
    formMethods: { errors, register, setValue, getValues, watch },
    control,
    protectedEmpNo,
    selEmp,
    protectedClientNo,
    clientNo,
    eligible,
    acaStatus,
  }: PropTypes, ref) => {

  const dispatch = useDispatch();
  
  const activeStatus = (acaStatus.length) ? [...acaStatus]?.sort((a, b) => { return new Date(b.effectiveDate).getDate() - new Date(a.effectiveDate).getDate(); })[0] :  acaStatus[0] as AcaStatus;
  const currentYear = new Date().getFullYear();
  
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState<AcaStatus>();
  
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });

  const { fields, insert, remove } = useFieldArray<AcaStatus>({
    control,
    name: 'acaStatus',
    keyName: 'id',
  });

  const onDelete = (item: AcaStatus) => {
    //Don't delete if read only access. Disabling span tag doesn't stop click events.
    if (getAccess(sectionAccess, 40)?.readOnly) return;

    setDeleteItem(item);
    setShowConfirmationModal(true);
  };

  const confirmClose = (confirmed: boolean) => {
    if (!(confirmed && deleteItem)) return;
    if (deleteItem.isNew) {
      const deleteIndex = fields.findIndex((x) => x.isNew && x.effectiveDate === deleteItem.effectiveDate);
      if (deleteIndex === -1) {
        console.error('could not find ACA status to delete from field array');
        dispatch(handleError('Error deleting ACA status'));
        return;
      }
      remove(deleteIndex);
    } else {
      dispatch(deleteEmployeeACAStatus({
        ...{
          protectedEmpNo,
          effectiveDate: deleteItem.effectiveDate.toString(),
          prYear: currentYear,
        },
      }));
    }
  };

  const handleStatusChange = (statusName: string, e: React.BaseSyntheticEvent) => {
    setValue(statusName, e.target.value);
  };

  const isDisabled = (item: AcaStatus) => {
    return !item.isNew && activeStatus?.effectiveDate !== item.effectiveDate;
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        onAddACAStatus() {
          const values = getValues();
          const hasNew = values?.acaStatus?.some((s: AcaStatus) => { return s.isNew; });
          const date = activeStatus?.effectiveDate && new Date(activeStatus?.effectiveDate) > new Date() ? new Date(activeStatus?.effectiveDate) : new Date();
          if (!hasNew) {
            const status: AcaStatus = {
              clientNo,
              empNo: selEmp?.empNo ?? 0,
              empId: selEmp?.empId ?? 0,
              protectedClientNo,
              protectedEmpNo,
              status: 'F',
              effectiveDate: new Date(date.setDate(date.getDate() + 1)).toISOString(),
              isNew: true,
            };
            insert(0, status);
          }
        },
        onSaveAcaStatuses() {
          fields.forEach((status) => {
            onSave(status as AcaStatus); // realy don't like casting these
          });
        },
      };
    },
  );

  const onSave = (item: AcaStatus) => {
    //Don't save if read only access. Disabling span tag doesn't stop click events.
    if (getAccess(sectionAccess, 40)?.readOnly) return;

    const values = getValues();
    
    const formStatus: AcaStatus = item.isNew ?
      values?.acaStatus?.find((s: AcaStatus) => String(s.isNew) === 'true') :
      values?.acaStatus?.find((s: AcaStatus) => s.effectiveDate === item.effectiveDate);
    
    const update = acaStatus?.some((s: AcaStatus) => s.effectiveDate === item.effectiveDate);
    
    const newStatus: AcaStatus = {
      ...formStatus,
      empNo: selEmp?.empNo ?? 0,
      protectedEmpNo,
      protectedClientNo,
      clientNo,
      empId: selEmp?.empId ?? 0,
    };
    if (update) {
      dispatch(updateEmployeeACAStatus({
        status: newStatus,
        prYear: currentYear,
      }));
    } else if (item.isNew) {
      dispatch(createEmployeeACAStatus({
        status: newStatus,
        prYear: currentYear,
      }));
    }
  };

  return (
    <div className="d-flex flex-row flex-grow-1 dm-panel-border pt-2">
      <div className="col-5">
        <div className="dm-card-subtitle3">
          Eligibility Status: {eligible ?
            <span style={{ color: 'orange' }}>Eligible</span> :
            <span style={{ color: 'red' }}>Not Eligible</span>}</div>
        <InputGrp
          {...fs.effectiveACAStatus}
          defaultValue={acaStatusOpts?.find(s => { return s.id === activeStatus?.status; })?.description ?? 'Not Applicable'}
          readOnly = {true}
          errors={errors.effectiveACAStatus}
        />
      </div>
      <div className="col-7">
        <div className="dm-card-subtitle3">ACA Status History</div>
        <div style={{
          overflowY: 'auto',
          maxHeight: '110px',
        }}
        >
          <div className="d-flex flex-row">
            <div className="form-group gc12 mw125">
              <div className="labelContainer">
                <label className="form-label">Effective Date</label>
              </div>
            </div>
            <div className="form-group gc12 mw150">
              <div className="labelContainer">
                <label className="form-label">Status</label>
              </div>
            </div>
          </div>
          {fields?.map((item, index) => {
            const watchedStatus = watch(`acaStatus[${index}].status`);
            
            return (
              <div
                className="d-flex flex-row"
                key={item.id}
              >
                <input
                  type="checkbox"
                  name={`acaStatus[${index}].isNew`}
                  style={{ visibility: 'hidden' }}
                  defaultChecked={item.isNew}
                  ref={register()}
                />
                <ControlDatePickerGrp
                  {...fs.effectiveDate}
                  {...getAccess(sectionAccess, 40)}
                  groupClass="gc12 mw125"
                  name={`acaStatus[${index}].effectiveDate`}
                  value={item.effectiveDate}
                  control={control}
                  disabled={!item.isNew || isDisabled(item as AcaStatus)}
                />
                <SelectGrp
                  {...fs.status}
                  {...getAccess(sectionAccess, 40, ControlIds.status)}
                  groupClass="gc12 mw125"
                  name={`acaStatus[${index}].status`}
                  errors={errors.status}
                  value={watchedStatus}
                  ref={register()}
                  onChange={(value: React.BaseSyntheticEvent) => {
                    return handleStatusChange(`acaStatus[${index}].status`, value);
                  }}
                  options={acaStatusOpts}
                />
                <div className={'gc12 mw75 mb-2'}>
                  <span 
                    {...getAccess(sectionAccess, 40, undefined, { disabledSameAsReadOnly: true })}
                    onClick={() => { return onSave(item as AcaStatus); }}
                  >
                    <Icon 
                      name="save"
                      className="fa-save" 
                    />
                  </span>

                  {(!item.isNew || acaStatus?.length > 1) && 
                    <span 
                      {...getAccess(sectionAccess, 40, undefined, { disabledSameAsReadOnly: true })}
                      onClick={() => { return onDelete(item as AcaStatus); }}
                    >
                      <Icon 
                        name="trash"
                        className="fa-save" 
                      />
                    </span>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          title="Delete Status"
          message={'Are you sure you would like to delete this status?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
    </div>
  );
});

ACAStatus.displayName = 'ACAStatus';

export default ACAStatus;