import { createSelector } from 'reselect';

import { appState } from '../reducers';
import { State as AppState } from '../reducers/app.reducer';

export const getNextURL = createSelector(
  appState,
  (state: AppState) => {return state.next;},
);

export const getEmpSearch = createSelector(
  appState,
  (state: AppState) => {return state.employeeSearch;},
);

export const appModal = createSelector(appState, (state: AppState) => {
  return {
    modalType: state.modalType,
    modalProps: state.modalProps,
  };
});
