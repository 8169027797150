import React, { Dispatch, SetStateAction, CSSProperties, useContext } from 'react';
import { SelectGrp, InputGrp } from 'core/components/form-controls';
import { FormMethods } from 'core/components/form-controls/types';
import Icon from 'core/components/shared/Icon';
import { TransmittalDeduction } from 'core/models';
import { getAllDeductionCodes, getClientGarnishmentDedNo, getDeductionUnits, getPayrollsUserOptions } from 'core/store/selectors';
import { ArrayField } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { cleanAmount, ddLookup, formatWithCommas } from 'utilities/utilities';
import { ManualChangeArgs } from './CheckItem';
import { garnishmentCategoryOptions } from 'dropdowns/garnishmentCategoryOptions';
import { FormContext } from './FormContext';

const inputStyle: CSSProperties = { textAlign: 'right' };
const editableDeductionStyles: CSSProperties = {
  flex: '1',
  marginLeft: '20px',
};
const readOnlyDeductionStyles: CSSProperties = {
  flex: '1',
  marginLeft: '45px',
};


type Props = {
  deduction: Partial<ArrayField<TransmittalDeduction, 'id'>>;
  deductionIndex: number;
  editableDeductionIndex: boolean;
  isReadOnly: boolean;
  addingDeduction: boolean;
  deductionIndexEdit: string[];
  setDeductionIndexEdit: Dispatch<SetStateAction<string[]>>,
  errors: FormMethods['errors'];
  register: FormMethods['register'];
  setValue: FormMethods['setValue'];
  watch: FormMethods['watch'];
  curriedRemove: (id: string | undefined) => void;
  saveChanges: (unregisteredInput?: boolean, args?: ManualChangeArgs) => Promise<any> | undefined;
};

const TransmittalDeductionsItem = ({
  deduction,
  deductionIndex,
  editableDeductionIndex,
  isReadOnly,
  addingDeduction,
  deductionIndexEdit,
  setDeductionIndexEdit,
  curriedRemove,
  errors,
  register,
  setValue,
  saveChanges,
  watch,
}: Props) => {
  const deductionUnitOptions = useSelector(getDeductionUnits);
  const deductionCodeOpts = useSelector(getAllDeductionCodes);
  const clientGarnishmentDedNo = useSelector(getClientGarnishmentDedNo) as number;
  const payrollsUserOptions = useSelector(getPayrollsUserOptions);
  
  const { updateActiveElement, setDirtyCheck } = useContext(FormContext);
  
  const watchedDedAmt = watch(`deductions[${deductionIndex}].dedAmount`);
  
  const formatDeduction = (deductionNumber: number | undefined, categoryId: number | null | undefined) => {
    const deductionType = ddLookup(deductionNumber, deductionCodeOpts);
    const category = garnishmentCategoryOptions.find(x => {return x.id === categoryId;})?.description || '';
    return deductionType + ((category !== '' && deductionNumber === clientGarnishmentDedNo) ? (' - ' + category) : '');
  };

  const setOnEditClick = (newId: string | undefined, dedIndex: number, dedAmount: number) => {
    if (!newId) return;
    const cloneIndex = structuredClone(deductionIndexEdit);

    if (deductionIndexEdit.find(x => {return x === newId;})) {
      setDeductionIndexEdit(cloneIndex.filter(x => {return x !== newId;}));
      setValue(`deductions[${dedIndex}].dedAmount`, dedAmount);
    } else setDeductionIndexEdit([...cloneIndex, newId]);
  };

  const onChangeDedNo = () => {
    saveChanges();
  };
  
  const autoSaveDeduction = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (deduction.overridedDeduction || deduction.transmittalDeductionId == -1) return; // don't update existing or new overrides
    
    const formatResult = Number(cleanAmount(e.target.value));
    if (formatResult === deduction.dedAmount) return; // don't update if nothing has changed
    
    setValue?.(`deductions[${deductionIndex}].dedAmount`, formatResult);
    e.target.value = formatWithCommas(e.target.value);
    saveChanges();
  };
  
  return (
    <div
      className="row"
      key={deduction.id}
    >
      <input
        type="hidden"
        name={`deductions[${deductionIndex}].transmittalDeductionId`}
        ref={register({
          valueAsNumber: true,
        })}
        value={deduction.transmittalDeductionId}
      />
      <input
        type="hidden"
        name={`deductions[${deductionIndex}].dedId`}
        ref={register({
          valueAsNumber: true,
        })}
        value={deduction?.dedId || undefined}
      />
      <input
        type="hidden"
        name={`deductions[${deductionIndex}].dept`}
        ref={register({
          valueAsNumber: true,
        })}
        value={deduction?.dept}
      />
      <input
        type="hidden"
        name={`deductions[${deductionIndex}].subDept`}
        ref={register({
          valueAsNumber: true,
        })}
        value={deduction?.subDept}
      />
      <input
        type="hidden"
        name={`deductions[${deductionIndex}].subDept2`}
        ref={register({
          valueAsNumber: true,
        })}
        value={deduction?.subDept2}
      />
      <div className="col-sm-3">
        {deduction.transmittalDeductionId !== -1
          ? (
            <div className="dm-form-text">
              <strong>{deduction.dedNo} - {formatDeduction(deduction.dedNo, deduction.categoryId)}</strong>
              <input
                type="hidden"
                name={`deductions[${deductionIndex}].dedNo`}
                ref={register({ valueAsNumber: true })}
                value={deduction?.dedNo}
              />
            </div>
          ) : (
            <SelectGrp
              id={`dedNo-${deduction.dedId}`}
              showCustom={true}
              onChange={onChangeDedNo}
              options={deductionCodeOpts}
              name={`deductions[${deductionIndex}].dedNo`}
              showId={true}
              ref={register({
                required: 'Deduction Type is required',
                valueAsNumber: true,
              })}
              defaultValue={deduction.dedNo !== undefined ? deduction.dedNo : deductionCodeOpts?.[0] }
            />
          )}
      </div> 
      <div style={{ width: [19, 20].includes(deduction.dedNo ?? 0) && deduction.dedId ? '2.5rem' : '1rem' }}>
        {editableDeductionIndex ? (
          <button
            type="button"
            className="btn btn-link dm-grid-action-title p-0"
            title="Confirm override change"
            onClick={() => {
              setValue(`deductions[${deductionIndex}].overridedDeduction`, true);
              saveChanges();
            }}
          >
            <Icon
              name="check"
              style={{ color: 'green',
                padding: '0' }}
            />
            <span className="sr-only">Click to confirm override change</span>
          </button>
        ) : null}
      </div>                  
      <div className="d-flex ml-2">
        <div>
          <input
            type="hidden"
            name={`deductions[${deductionIndex}].overridedDeduction`}
            ref={register()}
          />
          {deduction.transmittalDeductionId === 0 && ![19, 20].includes(deduction.dedNo ?? 0) ? 
            (<div
              className="d-flex"
              style={{ gap: '10px' }}
            >
              <button 
                type="button"
                name="ignore"
                disabled={isReadOnly}
                className="btn btn-link dm-grid-action-title p-0"
                title={(!editableDeductionIndex) ? 'Click here to override Scheduled Deduction' : 'Revert override change'}
                ref={register()}
                onClick={() => { setOnEditClick(deduction.id, deductionIndex, deduction?.dedAmount || 0); }}
              >{(!editableDeductionIndex) ? <Icon
                  name="pencil-alt"
                  style={{ color: 'blue' }}
                /> : <Icon
                name="cancel"
                style={{ color: 'red' }}
              />}
                <span className="sr-only">{(!editableDeductionIndex) ? 'Click here to override Scheduled Deduction' : 'Revert override change'}</span>
              </button> 
            </div>)
            : null}
        </div>     
        <InputGrp
          groupClass={`${deduction.transmittalDeductionId === 0 ? '' : 'number-underline'}`}
          groupStyle={deduction.transmittalDeductionId === 0 ? editableDeductionStyles : readOnlyDeductionStyles}
          inputStyle={inputStyle}
          name={`deductions[${deductionIndex}].dedAmount`}
          id={`dedAmount-${deduction.transmittalDeductionId}`}
          errors={errors?.deductions?.[deductionIndex]?.dedAmount}
          ref={register({
            valueAsNumber: true,
            required: 'Deduction Amount is required',
          })}
          places={payrollsUserOptions?.amountDecimals}
          defaultValue={formatWithCommas(watchedDedAmt, payrollsUserOptions?.amountDecimals)}
          setValue={setValue}
          readOnly={isReadOnly}
          onFocus={() => {
            updateActiveElement(`dedAmount-${deduction.transmittalDeductionId}`);
          }}
          onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(`deductions[${deductionIndex}].overridedDeduction`, !addingDeduction && deduction.dedId);
            setDirtyCheck(true);
          }}
          onBlur={autoSaveDeduction}
          type={'string'}
          disabled={deduction.transmittalDeductionId === 0 && !editableDeductionIndex}
          formatNumber
          hiddenValue={watchedDedAmt ?? 0}
          hiddenRef={register({
            valueAsNumber: true,
          })}
        />
      </div>
      {deduction.transmittalDeductionId === 0 && !editableDeductionIndex ?
        <div className="dm-form-text">
          <strong>{ddLookup(deduction.unit, deductionUnitOptions)}</strong>
          <input
            type="hidden"
            name={`deductions[${deductionIndex}].unit`}
            value={deduction.unit}
          />
        </div> : null}
      {deduction.transmittalDeductionId !== 0 && (
        <div className="col-sm-3">
          { !isReadOnly ? <button
            type="button"
            className="btn btn-link dm-grid-action-title p-0"
            onClick={() => { return curriedRemove(deduction?.id); }
            }
          >
            {(deduction.dedId) ? 'Delete Override ' : 'Delete '}
            <Icon
              name="minus-circle"
              className="fa-minus-circle"
            />
          </button> : null}
        </div>
      )}
    </div>
  );
};

export default TransmittalDeductionsItem;