import Icon from 'core/components/shared/Icon';
import React, { useEffect, useRef } from 'react';
import HrEmployeeCompanyProperty from '../../../core/components/shared/HrEmployeeCompanyProperty';
import PanelHeader from '../../../core/components/shared/PanelHeader';
import { CommonService } from '../../../core/services';
import {
  changeHrEmployeeCompanyProperty,
  loadHrEmployeeCompanyProperty,
} from '../../../core/store/slices/hr.slice';
import { useAppDispatch, useAppSelector } from '../../../utilities/hooks';

const HrEmployeeStatusCompanyPropertyPage = () => {
  const dispatch = useAppDispatch();
  const refDiv = useRef<HTMLDivElement>(null);

  const selectedEmployee = useAppSelector(
    (state) => { return state.selEmployee.employee; },
  );
  const hrEmployeeCompanyIssuedProperties = useAppSelector(
    (state) => { return state.hr.hrEmployeeCompanyIssuedProperties; },
  );

  useEffect(() => {
    if (selectedEmployee) {
      dispatch(
        loadHrEmployeeCompanyProperty(selectedEmployee.protectedEmpNo),
      );
    }
  }, [selectedEmployee]);

  const addNewCompanyProperty = () => {
    if (selectedEmployee) {
      dispatch(
        changeHrEmployeeCompanyProperty({
          clientNo: selectedEmployee.clientNo,
          protectedClientNo: CommonService.getProtectedClientNo(),
          empNo: selectedEmployee.empNo,
          protectedEmpNo: selectedEmployee.protectedEmpNo,
          propertyId: Math.random() * -1,
          issueDate: '',
          returnDate: '',
          identification: '',
          notes: '',
          propertyTypeId: 0,
        }),
      );
      setTimeout(() => {
        refDiv.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }, 500);
    }
  };

  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Company Property">
        <button
          type="button"
          className="btn btn-link dm-grid-action-title pb-0 mr-2"
          onClick={addNewCompanyProperty}
        >
          Add New Row <Icon name="plus-circle"
            className="fa-plus-circle" />
        </button>
      </PanelHeader>
      <div ref={refDiv}>
        {hrEmployeeCompanyIssuedProperties &&
          hrEmployeeCompanyIssuedProperties.map((property) => {
            return (
              <HrEmployeeCompanyProperty
                key={property.propertyId}
                hrEmployeeCompanyIssuedProperty={property}
              />
            );
          })}
      </div>
    </div>
  );
};

export default HrEmployeeStatusCompanyPropertyPage;
