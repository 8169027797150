import { createSelector } from 'reselect';
import { convToFormObject } from '../../../utilities/classUtils';
import { SpecialInfo } from '../../models';
import { specialInfoState } from '../reducers';
import { State as SpecialInfoState } from '../reducers/special-info.reducer';

export const getSpecialInfo = createSelector(
  specialInfoState,
  (state: SpecialInfoState) => {
    if (!state?.specialInfo) return null;

    return convToFormObject(state.specialInfo, SpecialInfo.convProps);
  },
);
