import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { TransmittalEmployee } from 'core/models/Payroll.model';
import TransmittalEmployeeItem from './TransmittalEmployeeItem';
import Pagination from 'core/components/shared/Pagination';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { useSelector } from 'react-redux';
import { getShowAddtl } from 'core/store/selectors';
import PageSpinner from 'core/components/shared/PageSpinner';
import ErrorPage from 'ErrorPage';
import { FormContext, EarningsErrorInfo, FormContextType } from './FormContext';
import usePagination from 'hooks/usePagination';
import { updateCurrentTransmittalPage } from 'core/store/actions';
import { debounce } from 'lodash';

const inRange = (index: number, range: [number, number]) => index >= range[0] && index <= range[1];

const HeaderRow = (showAddtlFields: boolean) => {
  return (
    <div
      className="mock-header row"
      style={{
        top: 0,
        position: 'sticky',
        zIndex: 999,
        textAlign: 'center',
      }}
    >
      <div
        className="col-sm-2"
        style={showAddtlFields ? { maxWidth: '10.5%' } : undefined}
      >Name</div>
      {showAddtlFields && <div
        className="col"
        style={{ maxWidth: '30%' }}
      >Additional Items</div>}
      <div
        className="col"
        style={showAddtlFields ? { maxWidth: '15%' } : { maxWidth: '30%' }}
      >Description</div>
      <div
        className="col"
        style={showAddtlFields ? { maxWidth: '15%' } : { maxWidth: '25%' }}
      >Amount</div>
      <div
        className="col"
        style={{ maxWidth: '15%' }}
      >Rate/Earnings</div>
      <div
        className="col"
        style={showAddtlFields ? { maxWidth: '15%' } : { maxWidth: '20%' }}
      >Total Amount</div>
    </div>
  );
};

type PropTypes = {
  payrollHistoryId: number;
  controlTotalId: number;
  employees: TransmittalEmployee[];
  earningsError: EarningsErrorInfo;
  setEarningsError: Dispatch<SetStateAction<EarningsErrorInfo>>;
  triggerSave: boolean;
  setTriggerSave: Dispatch<SetStateAction<boolean>>;
  dirtyCheck: boolean;
  setDirtyCheckState: Dispatch<SetStateAction<boolean>>;
  isReadOnly: boolean;
};

const TransmittalEmployeeItems = ({
  payrollHistoryId,
  controlTotalId,
  employees,
  earningsError,
  setEarningsError,
  triggerSave,
  setTriggerSave,
  dirtyCheck,
  setDirtyCheckState,
  isReadOnly,
}: PropTypes) => {
  const dispatch = useAppDispatch();
  
  const showAddtlFields = useSelector(getShowAddtl);
  const {
    transmittalEmpsLoading,
    currentTransmittalPage,
  } = useAppSelector(({ payroll }) => payroll);

  const { currentRange, numPages } = usePagination(employees.length, currentTransmittalPage, 20);
  
  //ID of whichever element was focused before a save so we can refocus it
  const [activeElement, setActiveElement] = useState<string | null>(null);
  const [inputVal, setInputVal] = useState<number>(currentTransmittalPage);
  
  // ref for the container holding the employee list
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const updateActiveElement = (id: string | null) => {
    setActiveElement(id);
  };
  
  useEffect(() => {
    setInputVal(currentTransmittalPage);
  }, [currentTransmittalPage]);
  
  const changePages = (newPageNum: number) => {
    dispatch(updateCurrentTransmittalPage(newPageNum));
    if (scrollContainerRef?.current) scrollContainerRef.current.scrollTo({ top: 0 });
  };
  
  const debounceUpdate = useMemo(() =>
    debounce((newPageNum: number) => {
      changePages(newPageNum);
      if (scrollContainerRef?.current) scrollContainerRef.current.scrollTo({ top: 0 });
    }, 250), []);
  
  const onManualUpdate = (newPageNum: number) => {
    setInputVal(newPageNum);
    debounceUpdate(newPageNum);
  };
  
  const setEarningsErrorCallback = (newValue: EarningsErrorInfo) => {
    setEarningsError(newValue);
    
    if (newValue.checkIndex > -1) {
      setTriggerSave(false);
    }
  };
    
  const setDirtyCheck = (newValue: boolean) => {
    setDirtyCheckState(newValue);
  };
  
  const manualSaveTriggerCallback = (newValue: boolean) => {
    setTriggerSave(newValue);
  };
  
  const formContextValue: FormContextType = {
    formInfo: { triggerSave: triggerSave },
    earningsError,
    dirtyCheck,
    activeElement,
    updateActiveElement,
    setEarningsErrorCallback,
    setDirtyCheck,
    manualSaveTriggerCallback,
  };
  
  const handleMouseEnterPaginationContainer = () => {
    manualSaveTriggerCallback(dirtyCheck);
  };
  
  return (
    <div className="d-flex flex-column">
      <div
        style={{
          height: 'calc(95vh - 360px)',
          overflow: 'hidden auto',
        }}
        id="emp-scroll-container"
        ref={scrollContainerRef}
      >
        {HeaderRow(showAddtlFields)}
        <div style={{ height: 'calc(100% - 25px)' }}>
          <FormContext.Provider value={formContextValue}>      
            {transmittalEmpsLoading === 'loading' ? (
              <PageSpinner
                loadingMessage="Loading Employees..."
                overrideStyles={{ spinnerWrapper: { height: '40vh' } }}
              />
            ) : transmittalEmpsLoading === 'error' ? (
              <ErrorPage subTitle="Could not load employees." />
            ) : !employees?.length && transmittalEmpsLoading === 'success' ? (
              <span className="add-item-message fade-in mt-5">
                Click &ldquo;Add Employee to Transmittal&rdquo; to get started.
              </span>
            ) : (
              <>
                {employees
                  .filter((_, index) => inRange(index, currentRange))
                  .map((employee, employeeIndex) => {
                    return (
                      <TransmittalEmployeeItem
                        key={employee.empNo}
                        employee={employee}
                        employeeIndex={employeeIndex}
                        payrollHistoryId={payrollHistoryId}
                        controlTotalId={controlTotalId}
                        isReadOnly={isReadOnly}
                      />
                    );
                  })}
              </>
            )}
          </FormContext.Provider>
        </div>
      </div>
      <Pagination
        loading={transmittalEmpsLoading === 'loading'}
        totalCount={employees.length}
        currentPage={currentTransmittalPage}
        pageSize={20}
        currentRange={currentRange}
        numPages={numPages}
        onPageChange={changePages}
        inputValue={inputVal}
        onPageInputChange={onManualUpdate}
        onMouseEnter={handleMouseEnterPaginationContainer}
        disabled={false}
      />
    </div>
  );
};

export default TransmittalEmployeeItems;
