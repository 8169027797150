import React from 'react';
import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';
import { AllModules, ColDef, GridOptions } from '@ag-grid-enterprise/all-modules';
import { agCurrencyRenderer } from 'utilities/ag-grid-renderers';
import { PayrollCheckResult } from 'core/models';

//#region AgGrid
const columns: ColDef[] = [
  {
    field: 'description',
    headerName: 'Type',
    sortable: true,
    width: 140,
    resizable: true,
    aggFunc: () => {
      return 'Total WH';
    },
  },
  {
    field: 'withholding',
    headerName: 'Tax',
    sortable: true,
    width: 80,
    aggFunc: 'sum',
    cellStyle: { 'text-align': 'right' },
    cellRenderer: 'currencyRenderer',
  },
];

const gridOptions: GridOptions = {
  columnDefs: columns,
  domLayout: 'autoHeight',
  groupIncludeFooter: true,
  groupIncludeTotalFooter: true,
  defaultColDef: {
    suppressMenu: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  }, // @ts-ignore
  components: {
    currencyRenderer: agCurrencyRenderer,
  },
};

type PropTypes = {
  items: PayrollCheckResult[];
};

const formatItems = (checkTaxes: PayrollCheckResult[]) => {
  const checkTaxesCopy = structuredClone(checkTaxes);
  checkTaxesCopy.map(x => {
    if (x.description === 'Employee FICA Social Security') x.description = 'FICA-Soc Sec';
    else if (x.description === 'Employee FICA Medicare') x.description = 'FICA-Med';
    else if (x.description === 'Employee FICA Medicare Additional') x.description = 'FICA-Med > 200K';
    return x;
  });

  return checkTaxesCopy;
};

const CCTaxes: React.FC<PropTypes> = ({ items }) => {
  return (
    <div>
      <div className="mb-5">
        <div className="table-wrapper-wrapper ag-theme-balham">
          <AgGridReact
            gridOptions={gridOptions}
            rowData={formatItems(items)}
            modules={AllModules}
          />
        </div>
      </div>
    </div>
  );
};

export default CCTaxes;
