import React, { useEffect, useRef, useState } from 'react';
import Modal from 'core/components/modals/Modal';
import { RadioGrpInLine } from 'core/components/form-controls/RadioGrpInLine';
import { TextareaGrp } from 'core/components/form-controls/TextareaGrp';
import { getScorecardCategories } from 'core/store/slices/applicantTracking.slice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'utilities/hooks';
import InterviewerScoreCard from './InterviewerScoreCard';
import { Interviewer, ScoreCard } from 'core/models';
import './applicant-status.scss';

type Props = {
  title: string;
  show: boolean;
  onHide(): void;
  onAccept(): void;
  selectedScoreCardRating: { item: Interviewer; itemIndex: number; } | null;
  setSelectedScoreCardRating: React.Dispatch<React.SetStateAction<{
    item: Interviewer;
    itemIndex: number;
  } | null>>;
  setSelectedInterviewer: React.Dispatch<React.SetStateAction<Interviewer[]>>;
  selectedInterviewers: Interviewer[];
  isEditing: boolean;
};

type CommentSectionProps = {
  defaultValue: string;
  disabled: boolean;
  onBlur: (e: any) => void;
};

const overallRatingScale = {
  1: 'strongNo',
  2: 'no',
  3: 'undecided',
  4: 'yes',
  5: 'strongYes',
};

const recommendationRadioOpts: { label: string; value: string }[] = [
  { label: 'Strong No', value: 'strongNo' },
  { label: 'No', value: 'no' },
  { label: 'Undecided', value: 'undecided' },
  { label: 'Yes', value: 'yes' },
  { label: 'Strong Yes', value: 'strongYes' },
];

const CommentSection = ({ defaultValue, disabled, onBlur }: CommentSectionProps) => (
  <div className="rating-section">
    <TextareaGrp
      label="General Notes about Candidate"
      groupClass="w-100"
      name="comment"
      defaultValue={defaultValue}
      disabled={disabled}
      onBlur={onBlur}
    />
  </div>
);

const ScoreCardRatingModal = ({
  title,
  show,
  onHide,
  onAccept,
  selectedScoreCardRating,
  setSelectedScoreCardRating,
  selectedInterviewers,
  setSelectedInterviewer,
  isEditing = false,
}: Props) => {
  const dispatch = useDispatch();
  
  const initialScoreCardState = useRef<Interviewer | null>(null);
  
  const interviewTemplates = useAppSelector(({ applicantTracking }) => applicantTracking.interviewTemplates);
  const currentTemplate = interviewTemplates?.find((template) => template.title === title);
  
  const [notes, setNotes] = useState('');
  const [defaultView, setDefaultView] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [tempInterviewerByIndex, setTempInterviewerByIndex] = useState<Interviewer>();
  const [isDirty, setIsDirty] = useState(false);
  
  useEffect(() => {
    setNotes(selectedScoreCardRating?.item?.notes || '');
    setSubmitted(false);
  }, [selectedScoreCardRating]);

  useEffect(() => {
    if (selectedInterviewers?.length > 0 && show && selectedScoreCardRating) {
      dispatch(getScorecardCategories());
    }
  }, [selectedInterviewers?.length > 0 && show]);

  
  useEffect(() => {
    if (!selectedScoreCardRating) return;
    setTempInterviewerByIndex(selectedInterviewers?.[selectedScoreCardRating?.itemIndex]);
  }, [selectedInterviewers, selectedScoreCardRating?.itemIndex]);
  
  useEffect(() => {
    if (!tempInterviewerByIndex?.interviewerScorecards?.length) return;
    if (!selectedScoreCardRating) return;
    initialScoreCardState.current = selectedInterviewers?.[selectedScoreCardRating?.itemIndex];
  }, [tempInterviewerByIndex?.interviewerScorecards?.length, selectedScoreCardRating?.itemIndex]);
  
  const updateScoreCard = (newScoreCard: ScoreCard) => {
    const scoreCardIndex = tempInterviewerByIndex?.interviewerScorecards?.findIndex(({ interviewerScorecardId, trait, category }) =>
      interviewerScorecardId ? interviewerScorecardId === newScoreCard.interviewerScorecardId
        : trait === newScoreCard.trait && category === newScoreCard.category);
    if (scoreCardIndex === -1 || scoreCardIndex === undefined) {
      console.error('Could not find card to update');
      return;
    }
      
    const newState = structuredClone(tempInterviewerByIndex);
    
    if (!(newState?.interviewerScorecards?.length && selectedScoreCardRating && selectedScoreCardRating?.itemIndex > -1)) return console.error('Cannot update');
    newState?.interviewerScorecards?.splice(scoreCardIndex, 1, newScoreCard);
      
    const sum = newState?.interviewerScorecards
      ?.map((scoreCard) => scoreCard?.rating ?? 0)
      ?.reduce((total, currentVal) => total + currentVal, 0);
    
    newState.scorecardRating = sum;
      
    const tempInterviewersInner = [...selectedInterviewers];
    const itemIndex = tempInterviewersInner?.findIndex((interviewer) => interviewer.secUserId === newState.secUserId);
    tempInterviewersInner[itemIndex] = newState;
      
    setSelectedScoreCardRating({ item: newState, itemIndex: itemIndex });
    setSelectedInterviewer(tempInterviewersInner);
    setTempInterviewerByIndex(newState);
    setIsDirty(true);
  };
  
  const onRecommendationChange = (e: any) => {
    let value = 0;
    switch (e.target.value) {
      case 'strongNo':
        value = 1;
        break;
      case 'no':
        value = 2;
        break;
      case 'undecided':
        value = 3;
        break;
      case 'yes':
        value = 4;
        break;
      case 'strongYes':
        value = 5;
        break;
      default:
        value = 0;
        break;
    }
    if (selectedScoreCardRating && selectedScoreCardRating?.itemIndex > -1) {
      const tempInterviewersInner = [...selectedInterviewers];
      const updatedInterviewer: Interviewer = {
        ...tempInterviewersInner[selectedScoreCardRating?.itemIndex],
        rating: value,
      };
      tempInterviewersInner[selectedScoreCardRating?.itemIndex] = updatedInterviewer;
      setSelectedInterviewer(tempInterviewersInner);
      setIsDirty(true);
    }
  };

  const handleSave = () => {
    if (selectedScoreCardRating && selectedScoreCardRating?.itemIndex > -1) {
      const tempInterviewersInner: Interviewer[] = [...selectedInterviewers ?? []];
      const tempInterviewerByIndexInner: Interviewer = tempInterviewersInner?.[selectedScoreCardRating?.itemIndex];
      const scorecardRating = tempInterviewerByIndexInner?.interviewerScorecards
        ?.map((scoreCard) => scoreCard?.rating ?? 0)
        ?.reduce((total, currentVal) => total + currentVal, 0);
      const defaultScorecardsFromTemplate: ScoreCard[] = currentTemplate?.scorecardCategories?.flatMap((category) => {
        return category?.traits?.map((trait) => ({
          secUserId: tempInterviewerByIndexInner.secUserId,
          interviewId: tempInterviewerByIndexInner.interviewId,
          applicantId: tempInterviewerByIndexInner.applicantId,
          interviewerScorecardId: 0,
          category: category.category,
          trait: trait.trait,
          rating: null,
        }));
      }) ?? [];
      const interviewerScorecards: ScoreCard[] = tempInterviewerByIndexInner?.interviewerScorecards?.length
        ? tempInterviewerByIndexInner.interviewerScorecards
        : defaultScorecardsFromTemplate;
      const updatedInterviewer: Interviewer = {
        ...tempInterviewerByIndexInner,
        interviewerScorecards: interviewerScorecards,
        notes: tempInterviewerByIndexInner?.notes ?? '',
        scorecardRating,
      };
      tempInterviewersInner[selectedScoreCardRating?.itemIndex] = updatedInterviewer;
      setSelectedInterviewer(tempInterviewersInner);
    }
    onAccept();
    setSubmitted(true);
    setIsDirty(false);
    onHide();
  };
  
  const hideModal = () => {
    if (!isDirty || (isDirty && confirm('You have unsaved changes. Are you sure you want to exit?'))) {
      const tempInterviewersInner: Interviewer[] = [...selectedInterviewers ?? []];
          
      if (initialScoreCardState?.current && tempInterviewersInner?.length && selectedScoreCardRating?.itemIndex !== undefined) {
        tempInterviewersInner[selectedScoreCardRating?.itemIndex] = initialScoreCardState.current;
        setSelectedInterviewer(tempInterviewersInner);
        setIsDirty(false);
      }
      onHide();
    }
  };
  
  return (
    <Modal
      centered
      show={show}
      isDirty={isDirty}
      onHide={hideModal}
      title="Score Card Rating"
      size="sm"
    >
      <div className="rating-section">
        <div className="d-flex align-items-center">
          <p className="font-weight-bold m-0 mr-1">Total Score Card Rating:</p>
          <div className="rating-wrapper">
            <p className="m-0 font-weight-bold">{tempInterviewerByIndex?.scorecardRating}</p>
          </div>
        </div>
        <div>
          <button
            type="button"
            className="btn view-toggle"
            onClick={() => {
              return setDefaultView(!defaultView);
            }}
          >
            <b>Switch View</b>
          </button>
        </div>
      </div>
      <CommentSection
        defaultValue={notes}
        disabled={!isEditing}
        onBlur={(e) => {
          setNotes(e.target.value);
          setIsDirty(true);
          const newState = structuredClone(tempInterviewerByIndex);
          
          if (!newState) return;
          
          const tempInterviewersInner = structuredClone(selectedInterviewers);
          const itemIndex = tempInterviewersInner?.findIndex((interviewer) => interviewer.secUserId === newState.secUserId);
          
          tempInterviewersInner[itemIndex] = { ...newState, notes: e.target.value };
          
          setSelectedScoreCardRating({ item: { ...newState, notes: e.target.value }, itemIndex: itemIndex });
          setSelectedInterviewer(tempInterviewersInner);
          setTempInterviewerByIndex({ ...newState, notes: e.target.value });
        }}
      />
      <div className="rating-section">
        <div className="d-flex align-items-center">
          <RadioGrpInLine
            name="overAllRecommendation"
            label="Overall Recommendation"
            labelClass={'radio-label-equal'}
            labelWidth={25}
            defaultChecked={overallRatingScale?.[(selectedScoreCardRating?.item?.rating ?? -1) as keyof typeof overallRatingScale] ?? ''}
            disabled={!isEditing}
            onChange={onRecommendationChange}
            radioOptions={recommendationRadioOpts}
          />
        </div>
      </div>
      <div className="d-flex flex-column">
        {currentTemplate?.scorecardCategories?.map(({ category, scorecardCategoryId }) => {
          return (
            <div
              key={scorecardCategoryId}
              className="dm-panel-border mb-2 p-2"
              style={{ backgroundColor: '#fefae5' }}
            >
              <div className="scorecard-category-title mb-1">{category}</div>
              <div className="scorecard-grid">
                {tempInterviewerByIndex?.interviewerScorecards
                  ?.filter((item) => item.category === category)
                  ?.map((item, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <InterviewerScoreCard
                          item={item}
                          index={index}
                          isEditing={isEditing}
                          defaultView={defaultView}
                          currentTemplate={currentTemplate}
                          updateScoreCard={updateScoreCard}
                        />
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="d-flex align-items-center justify-content-end mt-4">
        <button
          type="button"
          className="btn btn-primary orange-button mr-2 mt-2"
          onClick={handleSave}
          disabled={submitted || !isEditing}
        >
          Save
        </button>
      </div>
    </Modal >
  );
};

export default ScoreCardRatingModal;