import React, { Component } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import Icon from 'core/components/shared/Icon';
import { IconTypes } from 'enums/IconTypes';

export default class AGDeleteHeaderComponent extends Component {
  constructor(public props: ICellRendererParams) {
    super(props);
  }

  componentDidMount() { }

  refresh(params: ICellRendererParams) { }

  render() {
    return (
      <Icon name="trash-alt"
        type={IconTypes.REGULAR}
        color="red"
        style={{
          width: '18px',
          height: 'auto',
        }}
      />
    );
  }
}
