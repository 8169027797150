
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BambooHR, BambooHRRequest, GetBambooHRRequest } from 'core/models/HumanCapitalReports.model';

export interface BambooHRState {
  loading: boolean;
  bambooHrReport:string | null;
  bambooHrSettings: BambooHR | null;
}

const initialState: BambooHRState = {
  loading: false,
  bambooHrReport:null,
  bambooHrSettings:null,
};

export const bambooHrSlice = createSlice({
  name: 'bambooHr',
  initialState,
  reducers: {
    resetLoading: (state) => {
      state.loading = false;
    },
    downloadBambooHRReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: BambooHRRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeBambooHRReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.bambooHrReport = action.payload;
    },
    getBambooHRSettings: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: GetBambooHRRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeBambooHrSettings: (state, action: PayloadAction<BambooHR>) => {
      state.loading = false;
      state.bambooHrSettings = action.payload;
    },
  },
});

export const {
  resetLoading,
  downloadBambooHRReport,
  storeBambooHRReport,
  getBambooHRSettings,
  storeBambooHrSettings,
} = bambooHrSlice.actions;
export default bambooHrSlice.reducer;
