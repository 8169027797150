import React from 'react';
import { useSelector } from 'react-redux';
import { Deduction } from '../../../../core/models';
import {
  getDeductionCodes,
  getDeductionFrequencies,
  getDropdownOptions,
} from '../../../../core/store/selectors';
import { ddLookup } from '../../../../utilities/utilities';
import { TableRow, TableCell } from '@mui/material';
interface EmployeeDirectDepositProps {
  directDeposit: Deduction;
}

const decimalPlaces = (num: number) => {
  if (Number.isInteger(num)) {
    return 0;
  }

  return num.toString().split('.')[1].length;
};

const EmpDirectDepositItem: React.FC<EmployeeDirectDepositProps> = ({
  directDeposit,
}) => {
  const deductionCodeOpts = useSelector(getDeductionCodes);
  const frequencyOpts = useSelector(getDeductionFrequencies);
  const { unitOpts } = useSelector(getDropdownOptions);

  return (
    <TableRow
      sx={{
        '& td': {
          fontFamily: "Open Sans, 'sans-serif'",
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '24px',
          paddingLeft: '5px',
          transition: 'backgroundColor 0.5s ease',
        },
        '&:hover': {
          backgroundColor: '#f0f4fa',
        },
      }}
    >
      <TableCell>
        {ddLookup(directDeposit.dedNo, deductionCodeOpts)}
      </TableCell>
      <TableCell sx={{ width: 80 }} align="right">
        {decimalPlaces(directDeposit.dedAmount) === 1
          ? `${directDeposit.dedAmount}0`
          : decimalPlaces(directDeposit.dedAmount) === 0
            ? `${directDeposit.dedAmount}.00`
            : directDeposit.dedAmount}
      </TableCell>
      <TableCell sx={{ width: 120 }}>
        {ddLookup(directDeposit.unit, unitOpts)}
      </TableCell>
      <TableCell>{ddLookup(directDeposit.freq, frequencyOpts)}</TableCell>
      <TableCell>{directDeposit.routingNo}</TableCell>
      <TableCell>{directDeposit.bankAcctNo}</TableCell>
    </TableRow>
  );
};

export default EmpDirectDepositItem;
