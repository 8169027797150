import React, { CSSProperties } from 'react';
import { Spinner } from 'react-bootstrap';
import Icon from './Icon';

type ButtonStateLabels = {
  default: string;
  saving: string;
};

type Props = {
  saving: boolean;
  type?: 'submit' | 'reset' | 'button' | undefined;
  labels?: ButtonStateLabels;
  styles?: CSSProperties;
  classes?: string;
  disabled?: boolean;
  onClick?: (...args: any[]) => void;
};

const fallbackStateLabels: ButtonStateLabels = {
  default: 'Save',
  saving: 'Saving',
};

const SaveButton = ({
  saving,
  type = 'submit',
  labels = fallbackStateLabels,
  styles,
  classes,
  disabled,
  onClick,
}: Props) => {
  return (
    <button
      className={`btn orange-button ${classes}`}
      style={styles}
      type={type}
      disabled={disabled || saving}
      onClick={onClick}
    >
      {saving ? (
        <>
          <span className="mr-1">{labels.saving}&nbsp;</span>
          <Spinner
            animation={'border'}
            size="sm"
          />
        </>
      ) : (
        <>
          {labels.default}
        </>
      )}
    </button>
  );
};

export default SaveButton;