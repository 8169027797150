import React, { useEffect, useState } from 'react';
import { InputGrp } from '../../../core/components/form-controls';
import { FieldInputSettings } from '../../../core/components/form-controls/types';
import { OfferOfCoverage, ZipCode } from '../../../core/models';
import { useAppSelector } from '../../../utilities/hooks';

const fs: FieldInputSettings = {
  zipCodeAll12Months: {
    name: 'zipCode.zipCodeAll12Months',
    label: '',
    groupClass: 'gc08 mw100 pr-1',
  },
  zipCodeJanuary: {
    name: 'zipCode.zipCodeJanuary',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  zipCodeFebruary: {
    name: 'zipCode.zipCodeFebruary',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  zipCodeMarch: {
    name: 'zipCode.zipCodeMarch',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  zipCodeApril: {
    name: 'zipCode.zipCodeApril',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  zipCodeMay: {
    name: 'zipCode.zipCodeMay',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  zipCodeJune: {
    name: 'zipCode.zipCodeJune',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  zipCodeJuly: {
    name: 'zipCode.zipCodeJuly',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  zipCodeAugust: {
    name: 'zipCode.zipCodeAugust',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  zipCodeSeptember: {
    name: 'zipCode.zipCodeSeptember',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  zipCodeOctober: {
    name: 'zipCode.zipCodeOctober',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  zipCodeNovember: {
    name: 'zipCode.zipCodeNovember',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  zipCodeDecember: {
    name: 'zipCode.zipCodeDecember',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
};

type PropTypes = {
  formMethods: any;
  item: ZipCode;
  offerOfCoverage: OfferOfCoverage;
};

const ACAZipCode: React.FC<PropTypes> = ({
  formMethods: { errors, register, setValue },
  item,
  offerOfCoverage,
}) => {
  const [monthReadOnly, setMonthReadOnly] = useState(
    item?.zipCodeAll12Months !== '' ? true : false,
  );

  const [readOnly, setReadOnly] = useState<boolean>(false);

  const employeeZipCode = useAppSelector(
    (state) => {return state.selEmployee.employee?.zip;},
  );

  const offerValuesEnabled = ['1L', '1M', '1N', '1O', '1P', '1Q'];
  const offerValuesSetZipCode = ['1L', '1M', '1N'];
  useEffect(() => {
    if (offerOfCoverage && offerOfCoverage.offerOfCoverageAll12Months) {
      setReadOnly(
        !offerValuesEnabled.includes(
          offerOfCoverage.offerOfCoverageAll12Months,
        ),
      );
      setMonthReadOnly(offerOfCoverage.offerOfCoverageAll12Months !== '');

      for (const [key, value] of Object.entries(offerOfCoverage)) {
        if (offerValuesSetZipCode.includes(value)) {
          setValue(
            `zipCode.${key.replace('offerOfCoverage', 'zipCode')}`,
            employeeZipCode,
          );
          continue;
        }
        setValue(
          `zipCode.${key.replace('offerOfCoverage', 'zipCode')}`,
          '',
        );
      }
    }
  }, [offerOfCoverage]);

  useEffect(() => {
    setValue('zipCode.zipCodeJanuary', monthReadOnly ? '' : item?.zipCodeJanuary);
    setValue('zipCode.zipCodeFebruary', monthReadOnly ? '' : item?.zipCodeFebruary);
    setValue('zipCode.zipCodeMarch', monthReadOnly ? '' : item?.zipCodeMarch);
    setValue('zipCode.zipCodeApril', monthReadOnly ? '' : item?.zipCodeApril);
    setValue('zipCode.zipCodeMay', monthReadOnly ? '' : item?.zipCodeMay);
    setValue('zipCode.zipCodeJune', monthReadOnly ? '' : item?.zipCodeJune);
    setValue('zipCode.zipCodeJuly', monthReadOnly ? '' : item?.zipCodeJuly);
    setValue('zipCode.zipCodeAugust', monthReadOnly ? '' : item?.zipCodeAugust);
    setValue('zipCode.zipCodeSeptember', monthReadOnly ? '' : item?.zipCodeSeptember);
    setValue('zipCode.zipCodeOctober', monthReadOnly ? '' : item?.zipCodeOctober);
    setValue('zipCode.zipCodeNovember', monthReadOnly ? '' : item?.zipCodeNovember);
    setValue('zipCode.zipCodeDecember', monthReadOnly ? '' : item?.zipCodeDecember);
    setValue('zipCode.zipCodeAll12Months', !monthReadOnly ? '' : item?.zipCodeAll12Months);
  }, [monthReadOnly]);

  useEffect(() => {
    if (readOnly) {
      setValue('zipCode.zipCodeAll12Months', '');
    }
  }, [readOnly]);

  return (
    <div className="d-flex flex-row flex-wrap">
      <div
        className="gc15 font-weight-bold"
        style={{ lineHeight: '12px', fontSize: '10px' }}
      >
        17 Zip Code
      </div>
      <InputGrp
        {...fs.zipCodeAll12Months}
        errors={errors?.zipCode?.zipCodeAll12Months}
        ref={register({
          pattern: {
            value: /^[0-9]{5}$/,
            message: 'Invalid Zip Code',
          },
        })}
        disabled={readOnly}
      />
      <InputGrp
        {...fs.zipCodeJanuary}
        errors={errors?.zipCode?.zipCodeJanuary}
        ref={register({
          pattern: {
            value: /^[0-9]{5}$/,
            message: 'Invalid Zip Code',
          },
        })}
        disabled={
          monthReadOnly ||
                    !offerValuesEnabled.includes(
                      offerOfCoverage.offerOfCoverageJanuary,
                    )
        }
      />
      <InputGrp
        {...fs.zipCodeFebruary}
        errors={errors?.zipCode?.zipCodeFebruary}
        ref={register({
          pattern: {
            value: /^[0-9]{5}$/,
            message: 'Invalid Zip Code',
          },
        })}
        disabled={
          monthReadOnly ||
                    !offerValuesEnabled.includes(
                      offerOfCoverage.offerOfCoverageFebruary,
                    )
        }
      />
      <InputGrp
        {...fs.zipCodeMarch}
        errors={errors?.zipCode?.zipCodeMarch}
        ref={register({
          pattern: {
            value: /^[0-9]{5}$/,
            message: 'Invalid Zip Code',
          },
        })}
        disabled={
          monthReadOnly ||
                    !offerValuesEnabled.includes(
                      offerOfCoverage.offerOfCoverageMarch,
                    )
        }
      />
      <InputGrp
        {...fs.zipCodeApril}
        errors={errors?.zipCode?.zipCodeApril}
        ref={register({
          pattern: {
            value: /^[0-9]{5}$/,
            message: 'Invalid Zip Code',
          },
        })}
        disabled={
          monthReadOnly ||
                    !offerValuesEnabled.includes(
                      offerOfCoverage.offerOfCoverageApril,
                    )
        }
      />
      <InputGrp
        {...fs.zipCodeMay}
        errors={errors?.zipCode?.zipCodeMay}
        ref={register({
          pattern: {
            value: /^[0-9]{5}$/,
            message: 'Invalid Zip Code',
          },
        })}
        disabled={
          monthReadOnly ||
                    !offerValuesEnabled.includes(
                      offerOfCoverage.offerOfCoverageMay,
                    )
        }
      />
      <InputGrp
        {...fs.zipCodeJune}
        errors={errors?.zipCode?.zipCodeJune}
        ref={register({
          pattern: {
            value: /^[0-9]{5}$/,
            message: 'Invalid Zip Code',
          },
        })}
        disabled={
          monthReadOnly ||
                    !offerValuesEnabled.includes(
                      offerOfCoverage.offerOfCoverageJune,
                    )
        }
      />
      <InputGrp
        {...fs.zipCodeJuly}
        errors={errors?.zipCode?.zipCodeJuly}
        ref={register({
          pattern: {
            value: /^[0-9]{5}$/,
            message: 'Invalid Zip Code',
          },
        })}
        disabled={
          monthReadOnly ||
                    !offerValuesEnabled.includes(
                      offerOfCoverage.offerOfCoverageJuly,
                    )
        }
      />
      <InputGrp
        {...fs.zipCodeAugust}
        errors={errors?.zipCode?.zipCodeAugust}
        ref={register({
          pattern: {
            value: /^[0-9]{5}$/,
            message: 'Invalid Zip Code',
          },
        })}
        disabled={
          monthReadOnly ||
                    !offerValuesEnabled.includes(
                      offerOfCoverage.offerOfCoverageAugust,
                    )
        }
      />
      <InputGrp
        {...fs.zipCodeSeptember}
        errors={errors?.zipCode?.zipCodeSeptember}
        ref={register({
          pattern: {
            value: /^[0-9]{5}$/,
            message: 'Invalid Zip Code',
          },
        })}
        disabled={
          monthReadOnly ||
                    !offerValuesEnabled.includes(
                      offerOfCoverage.offerOfCoverageSeptember,
                    )
        }
      />
      <InputGrp
        {...fs.zipCodeOctober}
        errors={errors?.zipCode?.zipCodeOctober}
        ref={register({
          pattern: {
            value: /^[0-9]{5}$/,
            message: 'Invalid Zip Code',
          },
        })}
        disabled={
          monthReadOnly ||
                    !offerValuesEnabled.includes(
                      offerOfCoverage.offerOfCoverageOctober,
                    )
        }
      />
      <InputGrp
        {...fs.zipCodeNovember}
        errors={errors?.zipCode?.zipCodeNovember}
        ref={register({
          pattern: {
            value: /^[0-9]{5}$/,
            message: 'Invalid Zip Code',
          },
        })}
        disabled={
          monthReadOnly ||
                    !offerValuesEnabled.includes(
                      offerOfCoverage.offerOfCoverageNovember,
                    )
        }
      />
      <InputGrp
        {...fs.zipCodeDecember}
        errors={errors?.zipCode?.zipCodeDecember}
        ref={register({
          pattern: {
            value: /^[0-9]{5}$/,
            message: 'Invalid Zip Code',
          },
        })}
        disabled={
          monthReadOnly ||
                    !offerValuesEnabled.includes(
                      offerOfCoverage.offerOfCoverageDecember,
                    )
        }
      />
    </div>
  );
};

export default ACAZipCode;
