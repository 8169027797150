import React from 'react';
import { PageSection, UserMapSelectedField } from 'core/models/Downloader.model';
import { Col, Row } from 'react-bootstrap';
import GridSelect from '../../Shared/GridSelect';
import { useAppSelector } from 'utilities/hooks';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for the Checkbox and Grid select forms
const DeductionsFields = ({ sectionFields, formMethods }: EEDComponentProps) => {
  const deductionsOptions = useAppSelector((state) => { return state.dropdown.deductionCode; });

  const deductionFields = sectionFields.find(f => {
    return f.name === 'Deductions';
  })?.fields;

  return (
    <Row>
      {!!sectionFields?.length && (
        <Col xs={12}>
          <GridSelect
            section="Deductions"
            title="Employee Deductions"
            dFields={deductionFields?.filter(f => { return f.name !== 'Separate Deductions'; })}
            checkboxes={deductionFields?.filter(f => { return f.name === 'Separate Deductions'; })}
            options={deductionsOptions}
            formMethods={formMethods}
            isCodeField = {false}
          />
        </Col>
      )}
    </Row>
  );
};

export default DeductionsFields;