import React, { useState } from 'react';
import CorrectionsListSelect from './CorrectionsListSelect';
import CorrectionsList from './CorrectionsList';
import '../corrections.scss';

const CorrectionsPage: React.FC = () => {
  const [empNos, setEmpNos] = useState<string>('');
  
  return (
    <div className="page-container">
      <div className="employee-filter-container">
        <div className="list-wrapper">
          <CorrectionsListSelect setEmpNos={setEmpNos} />
        </div>
      </div>
      <div className="selected-employees-container">
        <div className="list-wrapper">
          <CorrectionsList empNos={empNos} />
        </div>
      </div>
    </div>
  );
};

export default CorrectionsPage;