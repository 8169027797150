import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, ColDef, GridApi, GridOptions, GridReadyEvent, RowNode, ValueGetterParams, ValueSetterParams } from '@ag-grid-enterprise/all-modules';
import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { agSelectEditor } from 'utilities/ag-grid-editors';
import { agLookupRenderer } from 'utilities/ag-grid-renderers';
import AGDeleteHeaderComponent from 'utilities/ag-grid-renderers/AGDeleteHeaderComponent';
import CheckboxRenderer from 'utilities/ag-grid-renderers/CheckboxRenderer';
import { Client } from 'core/models/Client.model';
import { ShiftPremium, ShiftPremiumEarnings } from 'core/models/ShiftPremium.model';
import { createShiftPremium, createShiftPremiumEarningsCode, deleteShiftPremiumEarningsCode, handleError, updateShiftPremium } from 'core/store/actions';
import { getClient } from 'core/store/selectors';
import { CheckboxGrp, InputGrp, SelectGrp } from 'core/components/form-controls';
import { TextareaGrp } from 'core/components/form-controls/TextareaGrp';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { getEarningCodes } from 'core/store/selectors/earning-code.selector';

const fs: FieldInputSettings = {
  shiftCode: {
    name: 'shiftCode',
    label: 'SHIFT CODE',
    groupClass: 'groupClass08',
  },
  earningsCodeId: {
    name: 'earningsCodeId',
    label: 'EARNING CODE',
    groupClass: 'groupClass15',
  },
  description: {
    name: 'description',
    label: 'DESCRIPTION',
    groupClass: 'groupClass15',
  },
  isPercent: {
    name: 'isPercent',
    label: 'IS PERCENT',
    groupClass: 'groupClass08',
  },
  amount: {
    name: 'amount',
    label: 'AMOUNT',
    groupClass: 'groupClass10',
  },
  applyToAllEarnCodes: {
    name: 'applyToAllEarnCodes',
    label: ['APPLY TO ALL', 'EARNING CODES'],
    groupClass: 'groupClass10',
  },
  includeInControlTotals: {
    name: 'includeInControlTotals',
    label: ['INCLUDE', 'CONTROL TOTALS'],
    groupClass: 'groupClass12',
  },
  paysAtStraightTime: {
    name: 'paysAtStraightTime',
    label: ['PAY AS', 'STRIGHT TIME'],
    groupClass: 'groupClass10',
  },
  internalComment: {
    name: 'internalComment',
    label: 'COMMENT',
    groupClass: 'groupClass45 pt-1',
    rows: 2,
  },
};

type PropTypes = {
  item: ShiftPremium;
  onDelete: any;
};

const ShiftPremiumItem: React.FC<PropTypes> = ({
  item,
  onDelete,
}) => {
  const dispatch = useDispatch();
  
  const client = useSelector(getClient) as Client;

  const { register, handleSubmit, errors, setValue, watch } = useForm<ShiftPremium>({
    defaultValues: item,
  });
  
  const shiftPremiumId = watch('shiftPremiumId');
  
  const earningsCodeOpts = useSelector(getEarningCodes);

  const [earningsCodes, setEarningsCode] = useState<ShiftPremiumEarnings[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  useEffect(() => {
    setEarningsCode(
      item.earningsCodes.map((ec: ShiftPremiumEarnings) => {
        return {
          ...ec,
          delete: false,
        };
      }),
    );
  }, [item]);

  useEffect(() => {
    gridApi?.setRowData(earningsCodes);
  }, [earningsCodes]);

  const columns: ColDef[] = [
    {
      field: 'earningsCodeId',
      headerName: 'EARNING CODE WHICH THE SHIFT CODE WILL BE APPLIED TO',
      cellRendererParams: { options: earningsCodeOpts, valueField: 'earningsCodeId', labelField: 'shortDescription' },
      cellRenderer: 'selectEditor',
      width: 410,
      valueSetter: (params: ValueSetterParams) => {
        params.data.earningsCodeId = params.newValue;
        return true;
      },
    },
    {
      field: 'delete',
      headerComponentFramework: AGDeleteHeaderComponent,
      cellRenderer: 'checkBoxRenderer',
      cellStyle: { paddingLeft: '16px' },
      width: 50,
    },
  ];

  const gridOptions: GridOptions = {
    columnDefs: columns,
    rowSelection: 'multiple',
    defaultColDef: {
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
      singleClickEdit: true,
    },
    // @ts-ignore
    components: {
      lookupRenderer: agLookupRenderer,
      selectEditor: agSelectEditor,
    },
    // @ts-ignore
    frameworkComponents: {
      checkBoxRenderer: CheckboxRenderer,
    },
    stopEditingWhenCellsLoseFocus: true,
  };

  const onGridReady = (e: GridReadyEvent) => {
    setGridApi(e.api);
  };

  const onAddEarningsCode = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setEarningsCode((prev) => {
      return [
        ...prev,
        new ShiftPremiumEarnings(item.shiftPremiumId, 0, earningsCodeOpts?.[0]?.earningsCodeId ?? 0),
      ];
    });
  };

  const onSave = (data: ShiftPremium) => {
    if (!client) {
      dispatch(handleError('Error saving shift premium'));
      console.log('Client not properly set in store');
      return;
    }
    
    const sp = new ShiftPremium(
      client.clientID,
      client.clientNo,
      {
        ...data,
        earningsCodes,
      },
    );

    gridApi?.forEachNode((row: RowNode) => {
      const spEarningsCode = new ShiftPremiumEarnings(
        row.data.shiftPremiumId,
        row.data.shiftPremiumDetailId,
        row.data.earningsCodeId,
      );
      
      if (row.data?.shiftPremiumId) {
        if (row.data?.delete && row.data?.shiftPremiumDetailId) {
          dispatch(deleteShiftPremiumEarningsCode(spEarningsCode));
        } else if (row.data.earningsCodeId) {
          sp.earningsCodes.push(spEarningsCode);
          dispatch(createShiftPremiumEarningsCode(spEarningsCode));
        }
      }
    });

    sp.shiftPremiumId = +sp.shiftPremiumId ?? item.shiftPremiumId;
    sp.earningsCodeId = String(sp.earningsCodeId) === '' ? null : sp.earningsCodeId;
    sp.shiftPremiumId ? dispatch(updateShiftPremium(sp)) : dispatch(createShiftPremium(sp));
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <div className="d-flex flex-row flex-wrap pt-3">
        <input
          name="shiftPremiumId"
          type="hidden"
          value={shiftPremiumId}
          ref={register({ valueAsNumber: true })}
        />
        <InputGrp
          {...fs.shiftCode}
          errors={errors.shiftCode}
          ref={register}
        />
        <SelectGrp
          {...fs.earningsCodeId}
          errors={errors.earningsCodeId}
          ref={register}
          options={earningsCodeOpts}
          valueField="earningsCodeId"
          labelField="shortDescription"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setValue('earningsCodeId', e.target.value);
          }}
        />
        <InputGrp
          {...fs.description}
          errors={errors.description}
          ref={register}
        />
        <CheckboxGrp
          {...fs.isPercent}
          errors={errors.isPercent}
          ref={register}
        />
        <InputGrp
          {...fs.amount}
          errors={errors.amount}
          ref={register}
        />
        <CheckboxGrp
          {...fs.applyToAllEarnCodes}
          errors={errors.applyToAllEarnCodes}
          ref={register}
        />
        <CheckboxGrp
          {...fs.includeInControlTotals}
          errors={errors.includeInControlTotals}
          ref={register}
        />
        <CheckboxGrp
          {...fs.paysAtStraightTime}
          errors={errors.paysAtStraightTime}
          ref={register}
        />

        <div className="d-flex flex-row flex-wrap pt-3">
          <div
            className="d-flex flex-column  pr-4"
            style={{ width: '55%' }}
          >
            <div
              className="w-100 text-right mt-auto pr-5"
              onClick={onAddEarningsCode}
            >
              <button
                type="button"
                className="btn btn-link dm-grid-action-title py-0"
              >
                Add Shift Premium{' '}
                <Icon
                  name="plus-circle"
                  className="fa-plus-circle"
                />
              </button>
            </div>
            <div
              className="table-wrapper-wrapper ag-theme-balham"
              style={{ height: '150px' }}
            >
              <AgGridReact
                gridOptions={gridOptions}
                rowData={earningsCodes}
                modules={AllModules}
                onGridReady={onGridReady}
              />
            </div>
          </div>

          <TextareaGrp
            {...fs.internalComment}
            errors={errors.internalComment}
            ref={register}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-right">
          <button
            type="button"
            className="btn btn-link dm-grid-action-title mr-4"
            onClick={() => { return onDelete(item); }}
          >
            Delete Shift Premium
            <Icon
              name="minus-circle"
              className="fa-minus-circle"
            />
          </button>
          <button
            type="submit"
            className="btn orange-button-sm mr-3"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default ShiftPremiumItem;
