import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { from } from 'rxjs';
import { useAppDispatch, useAppSelector } from '../../../../utilities/hooks';
import {
  PayrollCheck,
  PrintCheckVerify,
  RecordPrepay,
} from '../../../models/CheckCalculator.model';
import { calculateCheckPrintVerify, loadCalculateRecordPrepay } from '../../../store/actions';
import { InputGrp, RadioGrpInLine, TextareaGrp } from '../../form-controls';
import PdfViewer from '../../shared/PDFViewer/PdfViewer';

type Props = {
  weekEnd: Date;
  checkDate: Date;
  payrollWeekEnd: Date;
  payrollCheckDate: Date;
  printCheck: RecordPrepay;
};

const CheckCalculatorPrintModal = (props: Props) => {
  const checkPrintIframeRef = useRef<HTMLIFrameElement>(null);
  const protectedEmpNo = useAppSelector(
    (state) => { return state.selEmployee.employee?.protectedEmpNo; },
  );
  const checkInfo = useAppSelector(
    (state) => { return state.selEmployeeDetails.checkCalculator.checkCalculation; },
  ) as PayrollCheck;

  const checkPdf = useAppSelector(
    (state) => { return state.selEmployeeDetails.checkCalculator.pdf; },
  );
  const pdfType = useAppSelector(
    (state) => { return state.selEmployeeDetails.checkCalculator.pdfType; },
  );

  const dispatch = useAppDispatch();
  const [verifyCheckShow, setVerifyCheckShow] = useState<boolean>(false);
  const { handleSubmit, register, errors, getValues } =
    useForm<PrintCheckVerify>({
      defaultValues: {
        weekEnd: DateTime.fromJSDate(
          new Date(props.weekEnd),
        ).toFormat('MM/dd/yyyy'),
        payrollWeekEnd: DateTime.fromISO(
          props.payrollWeekEnd.toString(),
        ).toFormat('MM/dd/yyyy'),
        payrollCheckDate: DateTime.fromISO(
          props.payrollCheckDate.toString(),
        ).toFormat('MM/dd/yyyy'),
        checkDate: DateTime.fromJSDate(
          new Date(props.checkDate),
        ).toFormat('MM/dd/yyyy'),
        check: checkInfo,
        success: 'true',
      },
    });

  useEffect(() => {
    const convertToBlob = async () => {
      if (!checkPrintIframeRef.current) return;
      const base64Response = await fetch(
        `data:application/pdf;base64,${checkPdf}`,
      );
      const blob = await base64Response.blob();

      checkPrintIframeRef.current.src = URL.createObjectURL(blob);
    };
    if (checkPdf.length > 0 && pdfType === 'check') {
      convertToBlob();
    }
  }, [checkPdf]);

  const submitForm = (data: PrintCheckVerify) => {
    if (protectedEmpNo) {
      data.weekEnd = new Date(data.weekEnd);
      data.checkDate = new Date(data.checkDate);
      data.payrollCheckDate = new Date(props.payrollCheckDate);
      data.payrollWeekEnd = new Date(props.payrollWeekEnd);
      data.check = checkInfo;
      data.success = data.success === 'true';
      if(data.success){
        from([  
          dispatch(
            loadCalculateRecordPrepay({
              recordPrepay: props.printCheck,
              protectedEmpNo,
              printCheckVerify: data,
            }),
          ),
         ]).subscribe(() => { return setVerifyCheckShow(false); });;
        }
      else{
          from([
            dispatch(
              calculateCheckPrintVerify({
                printCheckVerify: data,
                protectedEmpNo,
              }),
            ),
          ]).subscribe(() => { return setVerifyCheckShow(false); });
        }
      }
    };

  return (
    <>
      <div className="d-flex flex-row py-3 justify-content-start">
        <button
          type="button"
          className="orange-button-sm btn btn-primary btn-sm mr-2"
          onClick={() => {
            checkPrintIframeRef?.current?.contentWindow?.print();
            setVerifyCheckShow(true);
          }}
        >
          Print Check
        </button>
        <button
          type="button"
          className="orange-button-sm btn btn-primary btn-sm"
          onClick={() => {
            checkPrintIframeRef?.current?.contentWindow?.print();
          }}
        >
          Test Print
        </button>
      </div>
      <iframe
        ref={checkPrintIframeRef}
        id="check-pdf"
        width="0"
        height="0"
        title="Check"
        className="d-none"
      ></iframe>
      <PdfViewer
        pdfData={checkPdf}
        reportName="Confirm Check Printing"
        checkCalcTestPrint={true}
      />
      <Modal
        show={verifyCheckShow}
        backdrop="static"
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>
            <div className="dm-grid-title">
              Confirm Check Printing
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Did the check print successfully?</p>
          <p>
            If so, an adjustment will be inserted into the selected
            payroll.
          </p>
          <form onSubmit={handleSubmit(submitForm)}>
            <div className="row">
              <div className="col">
                <InputGrp
                  name="weekEnd"
                  ref={register}
                  readOnly={true}
                  label="Week Ending"
                />
              </div>
              <div className="col">
                <InputGrp
                  name="checkDate"
                  ref={register}
                  readOnly={true}
                  label="Check Date"
                />
              </div>
              <div className="col">
                <InputGrp
                  name="check.checkNo"
                  ref={register}
                  readOnly={true}
                  label="Check Number"
                />
              </div>
            </div>
            <RadioGrpInLine
              name="success"
              errors={errors.success}
              ref={register}
            />
            <TextareaGrp
              name="comment"
              ref={register({
                validate: {
                  required: (value) => {
                    return getValues('success') === 'false' &&
                      value.length === 0
                      ? 'Comment is required'
                      : true;
                  },
                },
              })}
              errors={errors.comment}
            ></TextareaGrp>
            <button className="orange-button-sm btn btn-primary btn-sm">
              Continue
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CheckCalculatorPrintModal;
