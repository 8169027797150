export class Location {
  clientNo = 0;

  locationID = 0;

  clientID = 0;

  locationCode = 0;

  locationDesc = '';

  locationDescWithCode = '';

  glAccount = '';

  addressID = 0;

  quickenClass = '';

  alphaCode = '';

  constructor(clientNo: number, clientID: number, locationCode: number = 0) {
    this.clientNo = clientNo;
    this.clientID = clientID;
    this.locationCode = locationCode;
  }
}