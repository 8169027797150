import React, { CSSProperties, useContext } from 'react';
import Modal from 'core/components/modals/Modal';
import Icon from 'core/components/shared/Icon';
import { TimeCardDeduction } from 'core/models';
import { TimeSheetSummaryContext } from '../time-sheet-summary/TimeSheetSummary';
import SummaryDeductionItem from '../time-sheet-summary/SummaryDeductionItem';
import { generateNegativeId } from 'utilities/utilities';

const modalStyles: CSSProperties = {
  minWidth: '800px',
};

type Props = {
  show: boolean;
  onHide: () => void;
  canEdit?: boolean;
  deductions: TimeCardDeduction[];
  transmittalTimeCardId: number;
  timeCardIndex: number;
};

const SummaryDeductionsModal = ({
  show,
  onHide,
  deductions,
  transmittalTimeCardId,
  timeCardIndex,
  canEdit = true,
}: Props) => {
  
  const { updateTimeCardState } = useContext(TimeSheetSummaryContext);

  const addDeduction = () => {
    const newDeduction: TimeCardDeduction = {
      transmittalTimeCardId: transmittalTimeCardId,
      transmittalTimeCardDeductionId: generateNegativeId(),
      deductionNumber: 0,
      amount: 0,
    };
    
    updateTimeCardState({ type: 'ADD_DEDUCTION', newDeduction });
  };
  
  const removeDeduction = (timeCardId: number, timeCardDeductionId: number) => {
    updateTimeCardState({
      type: 'DELETE_DEDUCTION',
      timeCardId,
      timeCardDeductionId,
    });
  };
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Deductions"
      additionalStyles={modalStyles}
    >
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-end">
          {canEdit && (
            <button
              className="section-action btn btn-link dm-grid-action-title p-0 d-flex no-wrap"
              style={{ whiteSpace: 'nowrap' }}
              onClick={addDeduction}
            >
              Add Deduction <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          )}
        </div>
        <div className="d-flex flex-column mb-2">
          <div className="d-flex ml-4">
            <div className="d-flex flex-column w-25">
              <span>Deduction Type</span>
            </div>
            <span style={{ marginLeft: canEdit ? '125px' : '45px' }}>Amount</span>
          </div>
          {deductions?.map((deduction, index) => (
            <SummaryDeductionItem
              key={deduction.transmittalTimeCardDeductionId}
              deduction={deduction}
              index={index}
              canEdit={canEdit}
              timeCardIndex={timeCardIndex}
              removeDeduction={removeDeduction}
            />
          ))}
        </div>
        <div className="d-flex justify-content-end mt-2">
          <button
            className="btn orange-button-sm"
            onClick={onHide}
          >
            Close
          </button>
        </div>

      </div>
    </Modal>    
  );
};

export default SummaryDeductionsModal;