import React from 'react';
import styles from './styles.module.scss';
import sharedStyles from 'core/components/shared/shared.module.scss';
import { useBreakpoint } from 'utilities/BreakpointProvider';
import DetailRow from 'core/components/shared/DetailRow';
import { WorkFlowListChanges } from 'core/models/WorkFlow.model';

type Props = {
  details: WorkFlowListChanges[];
};

const ChangeFields = ({ details }: Props) => {
  const breakpoints: Record<string, string> = useBreakpoint();
  
  return (
    <div className="mt-3">
      <div className={sharedStyles['section-title-blue']}>
        Changes
      </div>
      <hr className="dm-panel-hr" />
      {/* on mobile render collapse rows, else render a table */}
      {breakpoints.xs ? (
        <div className="d-flex flex-column">
          {details.map((x) => {
            return (
              <DetailRow
                key={x.fieldName}
                rowClick
                expandByDefault
              >
                <DetailRow.SummaryContainer>
                  <span className="font-weight-bold">{x.fieldName}</span>
                </DetailRow.SummaryContainer>
                <DetailRow.DetailsContainer>
                  <div className="d-flex flex-column pb-2">
                    <div>
                      <span className="font-weight-bold">Old value:&nbsp;</span>
                      <span>{x.oldValue ?? 'N/A'}</span>
                    </div>
                    <div>
                      <span className="font-weight-bold">New value:&nbsp;</span>
                      <span style={{ color: '#4A9125' }}>{x.newValue}</span>
                    </div>
                  </div>
                </DetailRow.DetailsContainer>
              </DetailRow>
            );
          })}
        </div>
      ) : (
        <div className="dm-table-container-sm">
          <table className={`base-table sortable ${styles['workflow-table']}`}>
            <thead>
              <tr>
                <th>Field Name</th>
                <th>Old Value</th>
                <th>New Value</th>
              </tr>
            </thead>
            <tbody>
              {details.map((x, i) => {
                return (
                  <tr key={i}>
                    <td>{x.fieldName}</td>
                    <td>{x.oldValue}</td>
                    <td style={{ color: '#4A9125' }}>{x.newValue}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ChangeFields;