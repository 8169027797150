import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ColDef, ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import DownloadIcon from '@mui/icons-material/Download';
import { Link } from 'react-router-dom';
import { BaseProps } from './TransmittalError';
import { useSelector, useDispatch } from 'react-redux';
import { getClient } from 'core/store/selectors';
import {
  Payroll,
  PayrollTimeOffRequests,
  PendingTimeOffRequest,
  UnpaidTimeOffRequest,
} from 'core/models/Payroll.model';
import { MarkRequestTaken, OutstandingTimeOffRequest, ValidateDetails } from 'core/models';
import {
  applyPayrollTimeOffRequests,
  loadPayrollTimeOffRequests,
  updateMarkTaken,
  movePayrollTimeOffRequest,
  loadPayrollForTimeOffRequestModal,
  toggleAccrualLoadingState,
} from 'core/store/actions';
import TimeOffGrid from './TimeOffGrid';
import { formatDecimal } from 'utilities/utilities';
import { useAppSelector } from 'utilities/hooks';
import { CommonService } from 'core/services';
import Icon from 'core/components/shared/Icon';
import PayrollSingleSelection from './PayrollSingleSelection.modal';
import PageSpinner from 'core/components/shared/PageSpinner';

interface Props {
  id: number;
  controlTotalId: number
  onUpdateItems: (rowData: ValidateDetails[] | null) => void;
  modalFromPreviewScreen?: boolean;
}

const OutstandingTimeOffModal = ({ show, onHide, id, controlTotalId, onUpdateItems, items, modalFromPreviewScreen = true  }: BaseProps & Props) => {
  const [showTaken, setShowTaken] = useState(false);
  const [showOnTrans, setShowOnTrans] = useState(false);
  const [showMoveRequest, setShowMoveRequest] = useState(false);
  const [rowData, setRowData] = useState<PayrollTimeOffRequests>({
    outstandingTimeOffRequests: [],
    pendingTimeOffRequests: [],
    unpaidTimeOffRequests: [],
  });
  const [selected, setSelected] = useState<OutstandingTimeOffRequest | UnpaidTimeOffRequest | null>(null);
  const [selectedPayroll, setSelectedPayroll] = useState<Payroll | null>(null);
  const payrollDatelines = useAppSelector((state) => { return state.payroll.payrollsTimeOffDialog; });
  const [filteredPayrollDatelines, setFilteredPayrollDatelines] = useState<Payroll[]>(new Array<Payroll>());

  const dispatch = useDispatch();
  const getCurrentYear = new Date().getFullYear();

  const currentClient = useSelector(getClient);
  const {
    pendingAccrualReport: pendingReport,
    approvedAccrualReport: approvedReport,
    payrollTimeOffRequests: payrollTORs,
    loadingTimeOffRequests,
  } = useAppSelector(({ payroll }) => payroll);
  const { outstandingTimeOffRequests, pendingTimeOffRequests, unpaidTimeOffRequests } = payrollTORs || {};
  
  const [selectedPayrollId, selectedControlTotalId] = [
    selected?.payrollHistoryId ?? pendingTimeOffRequests?.[0]?.payrollHistoryId,
    selected?.controlTotalId ?? pendingTimeOffRequests?.[0]?.controlTotalId,
  ];
  
  const currentTransmittalLink = `/open-current-transmittal?payrollHistoryId=${selectedPayrollId}&controlTotalId=${selectedControlTotalId}`;
  
  const timeOffCols: ColDef[] = [
    {
      field: 'empNo',
      headerName: 'Employee No.',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
    },
    {
      field: 'employeeName',
      headerName: 'Employee Name',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
      cellStyle: {
        overflow: 'visible !important',
        whiteSpace: 'normal',
      },
    },
    {
      field: 'timeOffRequestId',
      headerName: 'Request ID',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
    },
    {
      field: 'earningsCode',
      headerName: 'Earnings Code',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
    },
    {
      field: 'transmittalHours',
      headerName: 'Other Hrs on Transmittal',
      cellRenderer: (params: ICellRendererParams) => { return formatDecimal(params.data.transmittalHours); },
      cellStyle: { 'text-align': 'right' },
      editable: false,
    },
    {
      field: 'requestedHours',
      headerName: 'Requested Hours',
      cellRenderer: (params: ICellRendererParams) => { return formatDecimal(params.data.requestedHours); },
      cellStyle: { 'text-align': 'right' },
      editable: false,
    },
    {
      field: 'protectedEmpNo',
      headerName: '',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
      hide: true,
    },
  ];

  const unpaidTimeOffCols: ColDef[] = [
    {
      field: 'empNo',
      headerName: 'Employee No.',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
    },
    {
      field: 'employeeName',
      headerName: 'Employee Name',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
      cellStyle: {
        overflow: 'visible !important',
        whiteSpace: 'normal',
      },
    },
    {
      field: 'timeOffRequestId',
      headerName: 'Request ID',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
    },
    {
      field: 'earningsCode',
      headerName: 'Earnings Code',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
    },
    {
      field: 'transmittalHours',
      headerName: 'Other Hrs on Transmittal',
      cellRenderer: (params: ICellRendererParams) => { return formatDecimal(params.data.transmittalHours); },
      cellStyle: { 'text-align': 'right' },
      editable: false,
    },
    {
      field: 'protectedEmpNo',
      headerName: '',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
      hide: true,
    },
  ];

  const accrualCols: ColDef[] = [
    {
      field: 'empNo',
      headerName: 'Employee No.',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
    },
    {
      field: 'employeeName',
      headerName: 'Employee Name',
      editable: false,
      cellStyle: { whiteSpace: 'normal' },
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Link
            to={`/employee/detail/${params.data.protectedEmpNo}/snapshot`}
          >
            {params.data.employeeName}
          </Link>
        );
      },
    },
    {
      field: 'timeOffRequestId',
      headerName: 'Request ID',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
    },
    {
      field: 'earningsCode',
      headerName: 'Earnings Code',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
    },
    {
      field: 'transmittalHours',
      headerName: 'Other Hrs on Transmittal',
      cellRenderer: (params: ICellRendererParams) => { return formatDecimal(params.data.transmittalHours); },
      cellStyle: { 'text-align': 'right' },
      editable: false,
    },
    {
      field: 'requestedHours',
      headerName: 'Pending Hrs',
      cellRenderer: (params: ICellRendererParams) => { return formatDecimal(params.data.requestedHours); },
      cellStyle: { 'text-align': 'right' },
      editable: false,
    },
    {
      field: 'supervisorEmpNo',
      headerName: 'Supervisor Emp No.',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
    },
    {
      field: 'supervisorName',
      headerName: 'Supervisor Name',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
    },
    {
      field: 'secondSupervisorEmpNo',
      headerName: 'Supervisor 2 Emp No.',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
    },
    {
      field: 'secondSupervisorName',
      headerName: 'Supervisor 2 Name',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
    },
    {
      field: 'protectedEmpNo',
      headerName: '',
      cellRenderer: 'agGroupCellRenderer',
      editable: false,
      hide: true,
    },
  ];

  const updateSelected = (newRow: OutstandingTimeOffRequest | UnpaidTimeOffRequest) => {
    setSelected(newRow);
  };

  const markAsTaken = (tor: PendingTimeOffRequest) => {
    if (!currentClient) throw new Error('Error: client is undefined');
    
    const rows = rowData.outstandingTimeOffRequests;
    const newRows = rows.filter(
      (row) => { return row.timeOffRequestId !== tor.timeOffRequestId; },
    );

    dispatch(
      updateMarkTaken(
        new MarkRequestTaken(
          currentClient.clientNo,
          tor.empNo,
          tor.protectedEmpNo,
          tor.timeOffRequestId,
          { taken: true },
        ),
      ),
    );

    setRowData((prevState) => {
      return {
        ...prevState,
        outstandingTimeOffRequests: newRows,
      };
    });
  };

  const applyToTransmittal = (tor: PendingTimeOffRequest) => {
    const rows = rowData.outstandingTimeOffRequests;
    const newRows = rows.filter(
      (row) => { return row.timeOffRequestId !== tor.timeOffRequestId; },
    );

    dispatch(
      applyPayrollTimeOffRequests({
        payrollHistoryId: id,
        controlTotalId: controlTotalId,
        empNo: tor.empNo,
        protectedEmpNo: tor.protectedEmpNo,
        timeOffRequestId: tor.timeOffRequestId,
      }),
    );
  };

  const downloadReport = (reportType: number) => {
    if (reportType === 1) {
      CommonService.downloadBase64File(
        'application/excel',
        pendingReport,
        'PendingAccrualReport.xlsx',
      );
    } else {
      CommonService.downloadBase64File(
        'application/excel',
        approvedReport,
        'ApprovedAccrualReport.xlsx',
      );
    }
  };
  
  useEffect(() => {
    dispatch(loadPayrollTimeOffRequests(id));
  }, []);

  useEffect(() => {
    if (outstandingTimeOffRequests) {
      setRowData((prev) => {
        return {
          ...prev,
          outstandingTimeOffRequests,
        };
      });
    }
    if (pendingTimeOffRequests) {
      setRowData((prev) => {
        return {
          ...prev,
          pendingTimeOffRequests,
        };
      });
    }
    if (unpaidTimeOffRequests) {
      setRowData((prev) => {
        return {
          ...prev,
          unpaidTimeOffRequests,
        };
      });
    }
  }, [payrollTORs]);

  useEffect(() => {
    if (payrollDatelines) {
      setFilteredPayrollDatelines(payrollDatelines.filter(x => x.useNewCheckRegister && !x.hasSignedWireOnlyAgreement && (x.status === 'O' || x.status === 'R')));
    }
  }, [payrollDatelines]);

  const onClick = () => {
    onUpdateItems(items?.filter(x => { return x.description.toLowerCase() !== 'outstanding time off request'; }) || null);
    dispatch(toggleAccrualLoadingState(true));
  };

  const showMoveRequestDialog = () => {
    if (!selected) return;

    const currentYear = (selected as UnpaidTimeOffRequest | null)?.payrollYear ?? getCurrentYear;
    dispatch(
      loadPayrollForTimeOffRequestModal({
        beginDate: `01/01/${currentYear}`,
        endDate: `01/01/${currentYear}`,
        byCheckDate: true,
      }),
    );

    setShowMoveRequest(true);
  };
  
  const hideAndToggleLoadState = () => {
    dispatch(toggleAccrualLoadingState(true));
    onHide();
  };

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={hideAndToggleLoadState}
      >
        <div className="modal-header">
          <div className="dm-card-title">
            Outstanding Time Off Requests
          </div>
          <button
            type="button"
            onClick={hideAndToggleLoadState}
            className="modal-close-btn"
          >
            <Icon name="times" />
          </button>
        </div>
        {loadingTimeOffRequests ? (
          <PageSpinner />
        ) : (
          <>
            <Modal.Body>
              <div className="d-flex flex-column">
                {rowData.outstandingTimeOffRequests?.length ? (     
                  <div className="pb-1">
                    The following&nbsp;{rowData.outstandingTimeOffRequests?.length}&nbsp;employee(s) have approved time-off requests. However, the transmittal being previewed does not include the requested time.
                  </div>
                ) : null}
                <div className="d-flex">
                  <button
                    className="btn orange-outline-button mt-2 mr-2 mb-2"
                    onClick={() => { return setShowTaken(true); }}
                    disabled={
                      !selected || !rowData.outstandingTimeOffRequests || rowData.outstandingTimeOffRequests?.length === 0
                    }
                  >
                    Mark as Taken
                  </button>
                  <button
                    className="btn orange-outline-button mt-2 mb-2"
                    onClick={() => { return setShowOnTrans(true); }}
                    disabled={
                      !selected || !rowData.outstandingTimeOffRequests || rowData.outstandingTimeOffRequests?.length === 0
                    }
                  >
                    Insert into Transmittal
                  </button>
                </div>
                <div className="table-wrapper-wrapper ag-theme-balham mb-2">
                  <TimeOffGrid
                    type="outstanding"
                    requestData={rowData.outstandingTimeOffRequests}
                    update={updateSelected}
                    columnDefs={timeOffCols}
                  />
                </div>
                {modalFromPreviewScreen ? <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-primary orange-button mt-2 mb-2"
                    disabled={!selected}
                  >
                    <Link
                      to={currentTransmittalLink}
                      style={{
                        color: 'white',
                        textDecoration: 'none',
                      }}
                    >
                      Open Transmittal
                    </Link>
                  </button>
                </div> : null}
              </div>
            </Modal.Body>
            <div className="modal-header">
              <div className="dm-card-title">
                Pending Accrual Requests
              </div>
            </div>
            <Modal.Body className="pt-1">
              <div className="d-flex flex-column">
                <div className="mb-2">
                  The following pending accrual requests are not yet
                  answered.
                </div>
                <div className="table-wrapper-wrapper ag-theme-balham mb-2">
                  <TimeOffGrid
                    type="pending"
                    requestData={rowData.pendingTimeOffRequests}
                    columnDefs={accrualCols}
                  />
                </div>
              </div>
            </Modal.Body>
            <div className="modal-header">
              <div className="dm-card-title">
                Unpaid Accrual Requests
              </div>
            </div>
            <Modal.Body className="pt-1">
              <div className="d-flex flex-column">
                <div className="mb-2">
                  The following accrual requests are on control totals not being paid
                </div>
                <div className="d-flex">
                  <button
                    className="btn orange-outline-button mt-2 mr-2 mb-2"
                    onClick={showMoveRequestDialog}
                    disabled={
                      !selected || !rowData.unpaidTimeOffRequests || rowData.unpaidTimeOffRequests?.length === 0
                    }
                  >
                    Move Request
                  </button>
                </div>
                <div className="table-wrapper-wrapper ag-theme-balham mb-2">
                  <TimeOffGrid
                    type="unpaid"
                    update={updateSelected}
                    requestData={rowData.unpaidTimeOffRequests}
                    columnDefs={unpaidTimeOffCols}
                  />
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <div
                    style={{
                      display: 'flex',
                      padding: '0 30px',
                      boxSizing: 'border-box',
                    }}
                  >
                    <button
                      className="btn btn-link"
                      style={{
                        padding: '0 15px',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                      onClick={() => { return downloadReport(0); }}
                    >
                      Download Approved to Excel <DownloadIcon />
                    </button>
                    <button
                      className="btn btn-link"
                      style={{
                        padding: '0 15px',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                      onClick={() => { return downloadReport(1); }}
                    >
                      Download Pending to Excel <DownloadIcon />
                    </button>
                  </div>
                  <button
                    className="btn orange-outline-button mt-2 mr-2 mb-2"
                    onClick={hideAndToggleLoadState}
                  >
                    Cancel
                  </button>
                  {modalFromPreviewScreen ? <button
                    className="btn btn-primary orange-button mt-2 mb-2"
                    disabled={
                      rowData.outstandingTimeOffRequests.length > 0 && rowData.unpaidTimeOffRequests.length > 0
                    }
                    onClick={onClick}
                  >
                    Continue
                  </button> : null}
                </div>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
      {showTaken ? (
        <div>
          <Modal
            show={show}
            onHide={() => { return setShowTaken(false); }}
            size="sm"
          >
            <div className="modal-header">
              <div className="dm-card-title">
                Mark Taken Hours
              </div>
              <button
                type="button"
                onClick={() => { return setShowTaken(false); }}
                className="modal-close-btn"
              >
                <Icon name="times"/>
              </button>
            </div>
            <Modal.Body>
              <div className="d-flex flex-column">
                <p>
                  This will mark all the employee&apos;s requested
                  hours in this grid as &lsquo;taken&rsquo; in the time
                  off request system.
                </p>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn orange-outline-button mt-2 mr-2 mb-2"
                    onClick={() => { return setShowTaken(false); }}
                  >
                    No
                  </button>
                  <button
                    className="btn btn-primary orange-button mt-2 mb-2"
                    onClick={() => {
                      markAsTaken(
                        selected as PendingTimeOffRequest,
                      );
                      setShowTaken(false);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      ) : null}
      {showOnTrans ? (
        <div>
          <Modal
            show={show}
            onHide={() => { return setShowOnTrans(false); }}
            size="sm"
          >
            <div className="modal-header">
              <div className="dm-card-title">
                Add Requested Hours
              </div>
              <button
                type="button"
                onClick={() => { return setShowOnTrans(false); }}
                className="modal-close-btn"
              >
                <Icon name="times"/>
              </button>
            </div>
            <Modal.Body>
              <div className="d-flex flex-column">
                <p>
                  This will add all the employee&apos;s requested
                  hours in this grid to the current
                  transmittal.
                </p>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn orange-outline-button mt-2 mr-2 mb-2"
                    onClick={() => { return setShowOnTrans(false); }}
                  >
                    No
                  </button>
                  <button
                    className="btn btn-primary orange-button mt-2 mb-2"
                    onClick={() => {
                      applyToTransmittal(
                        selected as PendingTimeOffRequest,
                      );
                      setShowOnTrans(false);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      ) : null}
      {showMoveRequest ? (
        <div>
          <PayrollSingleSelection
            payrollDatelines={filteredPayrollDatelines}
            currentPayrollHistoryId={id}
            show={show}
            onHide={() => { 
              if (selectedPayroll) {
                dispatch(
                  movePayrollTimeOffRequest({
                    oldPayrollHistoryId: id,
                    oldControlTotalId: controlTotalId,
                    newPayrollHistoryId: selectedPayroll.payrollHistoryId,
                    timeOffRequestId: selected?.timeOffRequestId ?? 0,
                    empNo: selected?.empNo ?? 0,
                    protectedEmpNo: selected?.protectedEmpNo ?? '',
                  }),
                );
              }
              return setShowMoveRequest(false); 
            }}
            update={setSelectedPayroll}
          />
        </div>
      ) : null}
    </div>
  );
};

export default OutstandingTimeOffModal;
