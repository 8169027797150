import  { GroupBase, StylesConfig } from 'react-select';

export type StylesConfigType = StylesConfig<any, boolean, GroupBase<unknown>> | undefined;
export const compStyles: StylesConfigType = {
  container: (base) => {
    return {
      ...base,
      width: '100%',
    };
  },
  control: (base) => {
    return {
      ...base,
      minHeight: '30px',
      width: '100%',
      borderRadius: '0.25rem',
      fontSize: '12px',
      color: 'black',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#0074D9',
      },
    };
  },
  valueContainer: (base) => {
    return {
      ...base,
      padding: '0 0.5rem',
      top: '0',
      position: 'relative',
      overflow: 'hidden',
      width: 'fit-content',
      color: 'black',
      boxSizing: 'border-box',
    };
  },
  indicatorSeparator: (base) => {
    return {
      ...base,
      display: 'none',
    };
  },
  dropdownIndicator: (base) => {
    return {
      ...base,
      padding: '0 0.5rem',
      color: '#3a3a3a',
      '&:hover': {
        color: 'black',
      },
    };
  },
  menuPortal: (base) => {
    return {
      ...base,
      zIndex: 9999,
      width: 'fit-content',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 10px 0px',
      background: 'rgb(255, 255, 255)',
      cursor: 'pointer',
    };
  },
};