import { createReducer } from '@reduxjs/toolkit';
import { GlobalDeduction } from '../../models';
import { clearGlobalDeductions, loadGlobalDeductions, storeGlobalDeductions } from '../actions';

export interface State {
  loading: boolean;
  globalDeductions: GlobalDeduction[];
}

export const INITIAL_STATE: State = {
  loading: false,
  globalDeductions: [],
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadGlobalDeductions.type]: (state) => {return { ...state, loading: true };},
  [storeGlobalDeductions.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      globalDeductions: action.payload,
    };
  },
  [clearGlobalDeductions.type]: (state) => {return { ...state, loading: false, globalDeductions: [] };},
});
