import React from 'react';
import Modal from 'core/components/modals/Modal';
import { EarningsReportType } from 'core/models/EarningsReports.model';
import EarningsReports from '../earnings-reports/earnings-reports';

interface Props {
  reportType: EarningsReportType | null;
  show: boolean;
  onHide: () => void;
}

const EarningsModal = ({ reportType, show, onHide }: Props) => {
  return (
    <Modal
      size="sm"
      title={reportType?.split(/(?=[A-Z])/).join(' ')}
      show={show}
      onHide={onHide}
    >
      {(closeModal) => {
        return (
          <EarningsReports
            show={show}
            onHide={closeModal}
            earningsReportType={reportType as EarningsReportType}
          />
        );
      }}
    </Modal>
  );
};

export default EarningsModal;
