import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import EmpPhoto from 'core/components/shared/EmpPhoto';
import Icon from 'core/components/shared/Icon';
import { Employee } from 'core/models';
import '../time-card-styles.scss';
import tableStyles from 'core/components/shared/dm-table/_styles.module.scss';
import TableHeader from 'core/components/shared/dm-table/Header';
import { ColumnHeaderData, Row } from 'core/components/shared/dm-table/types';
import { JobError } from './dummyData';
import ErrorRow from './ErrorRow';
import { currencyFormatter } from 'utilities/utilities';
import { DiagnosticContext } from './Diagnostics';

const tradeDetailColumns: ColumnHeaderData[] = [
  { title: 'Error', styleClass: 'th-xl' },
  { title: 'Notes', styleClass: 'th-xl' },
  { title: 'Job No.', styleClass: 'th' },
  { title: 'Total Hours', styleClass: 'th' },
  { title: 'Regular', styleClass: 'th' },
  { title: '1 1/2', styleClass: 'th' },
  { title: 'Double', styleClass: 'th' },
  { title: 'Other Hours', styleClass: 'th' },
  { title: 'Ded. Amount', styleClass: 'th' },
  { title: 'Other Earnings', styleClass: 'th' },
  { title: 'Reviewed', styleClass: 'th' },
];

type CheckboxProps = {
  value: boolean;
  update: Dispatch<SetStateAction<boolean>>;
};

export const IgnoreCheckbox = ({ value, update }: CheckboxProps) => (
  <div className="d-flex justify-content-center w-100">
    <span>{value}</span>
    <input
      type="checkbox"
      title='Click to mark this error "reviewed".'
      checked={value}
      onChange={({ target }) => { update(target.checked); }}
    />
  </div>
);

export const buildErrorRow = (error: JobError, props: CheckboxProps): Row => {
  return {
    id: error.id,
    cells: [
      { children: error.description, styleClass: 'td-xl' },
      { children: error.notes, styleClass: 'td-xl' },
      { children: error.jobNo, styleClass: 'td' },
      { children: error.totalHours.toFixed(2), styleClass: 'td' },
      { children: error.regular.toFixed(2), styleClass: 'td' },
      { children: error.timeHalf.toFixed(2), styleClass: 'td' },
      { children: error.double.toFixed(2), styleClass: 'td' },
      { children: error.otherHours.toFixed(2), styleClass: 'td' },
      { children: currencyFormatter(error.dedAmount), styleClass: 'td' },
      { children: currencyFormatter(error.otherEarnings), styleClass: 'td' },
      {
        children: <IgnoreCheckbox {...props} />,
        styleClass: 'td',
        onClick: () => { props.update(!props.value); },
      },
    ],
  };
};

type Props = {
  employee: Employee;
};

const DiagnosticDetailItem = ({ employee }: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [reviewAll, setReviewAll] = useState<boolean>(false);
  const [rowState, setRowState] = useState<JobError[]>([]);

  
  const { selectedError, showReviewed, expandAll, errors, update } = useContext(DiagnosticContext);
  
  useEffect(() => {
    setRowState(errors);
  }, [errors]);
  
  useEffect(() => {
    setIsExpanded(expandAll);
  }, [expandAll]);
  
  useEffect(() => {
    setIsExpanded(true);
  }, [selectedError]);
  
  return (
    <>
      {rowState?.length ? (
        <div
          className="time-card-header detail-item-header"
          onClick={() => { setIsExpanded(!isExpanded); }}
        >
          <div className="card-header-row">
            <div className="emp-main-info">
              <EmpPhoto
                empPicData={employee.employeePhoto}
                empName={`${employee.firstName} ${employee.lastName}`}
                scaleFactor="65"
              />
              <div className="d-flex flex-column">
                <span className="dm-grid-title emp-name mb-1">{employee.firstName}&nbsp;{employee.lastName}</span>
                <div className="header-info-section">
                  <strong>Emp No:&nbsp;{employee.empNo}</strong>
                  <strong className="ml-3">Errors to Review:&nbsp;0</strong>
                </div>
              </div>
            </div>
            <div className="d-flex ml-auto">
              <button
                className="btn btn-link mr-2"
                onClick={(e) => {
                  setReviewAll(!reviewAll);
                  e.stopPropagation();
                }}
              >
                Mark all as&nbsp;{reviewAll ? 'unreviewed' : 'reviewed'}&nbsp;
                <Icon
                  name={reviewAll ? 'xmark-circle' : 'check-circle'}
                  color={reviewAll ? '#d73c28' : '#4a9125'}
                />
              </button>
              <button
                className="btn btn-link"
                onClick={() => { setIsExpanded(!isExpanded); }}
              >
                {isExpanded ? 'Collapse' : 'Expand'}
            &nbsp;
                <Icon
                  name={isExpanded ? 'chevron-circle-up' : 'chevron-circle-down'}
                  className={isExpanded ? 'roster-chevron flipped' : 'roster-chevron'}
                />
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {isExpanded ? (
        <div className={`${tableStyles['table-container']} m-0`}>
          <div className={`${tableStyles.table} ${tableStyles['error-theme']}`}>
            <TableHeader columns={tradeDetailColumns} />
            <div className={tableStyles['tbody-fit']}>
              {/* We wrap the TableRow in this because it manages its own state */}
              {rowState?.map(x => (
                <ErrorRow
                  key={x.id}
                  error={x}
                  reviewAll={reviewAll}
                /> 
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DiagnosticDetailItem;