import { ICellRendererParams } from '@ag-grid-community/core';

export function agSSNRenderer(): any {}

agSSNRenderer.prototype.init = function (params: ICellRendererParams) {
  const value = params.value;
  this.divWrapper = document.createElement('div');
  this.divWrapper.innerHTML = `${value.substring(0, 3)}-${value.substring(
    3,
    5,
  )}-${value.substring(5)}`;
};

agSSNRenderer.prototype.getGui = function (params: ICellRendererParams) {
  return this.divWrapper;
};

agSSNRenderer.prototype.refresh = function (params: ICellRendererParams) {
  return true;
};

agSSNRenderer.prototype.destroy = function () {};
