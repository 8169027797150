import { createReducer } from '@reduxjs/toolkit';
import { groupBy } from 'lodash';
import {
  Department,
  Location,
  NOVATimeRule,
  NOVATimeSettings,
  SubDepartment,
  SubDepartment2,
  TaxEntity,
} from '../../models';
import { Dropdown, PaygradeDropdown, SchoolDropdown } from '../../models/Dropdown.model';
import { ShiftPremium } from '../../models/ShiftPremium.model';
import {
  deleteLocation,
  removeDepartment,
  removeLocation,
  removeSubDepartment,
  removeSubDepartment2,
  storeAllDropdowns,
  storeAODItems,
  storeDepartment,
  storeDepartments,
  storeDropdown,
  storeLocation,
  storeLocations,
  storeNOVATime,
  storeNOVATimeRules,
  storeNOVATimeSettings,
  storeNOVATimeShiftNumbers,
  storePaygradeDropdowns,
  storeSchoolDropdowns,
  storeShiftPremium,
  storeShiftPremiums,
  storeSubdepartment,
  storeSubdepartment2,
  storeSubdepartments,
  storeSubdepartments2,
  storeTaxEntities,
} from '../actions/dropdown.action';

interface Actions {
  type: string;
  payload: any;
}

export interface State {
  loading: boolean;
  error: any;

  aca1095BOriginOfPolicy: Dropdown[];
  acaOverrideOfferOfCoverage: Dropdown[];
  acaOverrideSafeHarbor: Dropdown[];
  acaSafeHarborWaiveCoverage: Dropdown[];

  accrualRule: Dropdown[];
  activeStatus: Dropdown[];
  activeStatusConditions: Dropdown[];

  benefitClassCode: Dropdown[];
  benefitEligibilityGroup: Dropdown[];

  careerLevel: Dropdown[];
  changeReasons: Dropdown[];
  clockGroups: Dropdown[];
  communicationPreference: Dropdown[];
  contactRelationship: Dropdown[];
  companyBenefitDeductionCode: Dropdown[];

  deferredCompCatchUpMethod: Dropdown[];
  deferredCompClassExclusion: Dropdown[];
  department: Department[];
  deductionCode: Dropdown[];
  deductionFreq: Dropdown[];
  deductionStatus: Dropdown[];
  deductionUnit: Dropdown[];
  displayOnTransmittal: Dropdown[];
  divisionCode: Dropdown[];

  earningsCode: Dropdown[];
  educationDegreeCodes: Dropdown[];
  educationMajorCodes: Dropdown[];
  educationSchools: SchoolDropdown[];
  eeoCode: Dropdown[];
  eeoOccupationalGroup: Dropdown[];
  eeoUnitNo: Dropdown[];
  employeeStatus: Dropdown[];
  employmentType: Dropdown[];
  empMasterCustomFields: Dropdown[];
  empIssuedPropertyType: Dropdown[];
  empSkillSourceCodes: Dropdown[];
  empSkillTypes: Dropdown[];
  ePrescribe: Dropdown[];
  exitInterviewQuestion: Dropdown[];
  exemptStatus: Dropdown[];

  federalTaxes: Dropdown[];
  federalTaxEntity: Dropdown[];
  fileAccessLevel: Dropdown[];
  fileAdminType: Dropdown[];
  fileCategory: Dropdown[];
  fileFillType: Dropdown[];
  foreignCountry: Dropdown[];

  garnishmentFIPsCode: Dropdown[];
  garnishmentEntity: Dropdown[];
  gender: Dropdown[];
  geographicLevel: Dropdown[];
  gEDCMultiStep: Dropdown[];
  gEDCDocCompCareerLevel: Dropdown[];
  gEDCDocCompContractVersion: Dropdown[];
  gEDCAnnualDues: Dropdown[];
  gEDCCompensationType: Dropdown[];
  groupTermLifeEarningsCode: Dropdown[];

  hireReferralSource: Dropdown[];
  hourlyStatuses: Dropdown[];

  i9DocumentTitle: Dropdown[];
  inactiveStatusConditions: Dropdown[];
  federalBlsSocCodes: Dropdown[];

  jobFamily: Dropdown[];
  jobCategory: Dropdown[];
  jobNumbers: Dropdown[];
  costCode: Dropdown[];
  jobTitle: Dropdown[];

  layoffCode: Dropdown[];
  licenseRenewalTypes: Dropdown[];
  localTaxEntity: Dropdown[];
  localTaxEntityQuickOnboard: Dropdown[];
  localTaxEntityWithCode: Dropdown[];
  location: Location[];

  majorCodes: Dropdown[];
  maritalStatus: Dropdown[];

  novaTimeClockSetting: Dropdown[];
  newJobTrainingProgram: Dropdown[];
  nonCompete: Dropdown[];
  novatimeSettings: NOVATimeSettings | null;
  novatimeShift: Dropdown[];
  novatimeRule: NOVATimeRule[];
  novatimeGroup1: Dropdown[];
  novatimeGroup2: Dropdown[];
  novatimeGroup3: Dropdown[];
  novatimeGroup4: Dropdown[];
  novatimeGroup5: Dropdown[];
  novatimeGroup6: Dropdown[];
  novatimeGroup7: Dropdown[];
  novatimeGroup8: Dropdown[];

  otherDateDescription: Dropdown[];

  paidSickTimeEntity: Dropdown[];
  payrollInfoEicCode: Dropdown[];
  payPolicy: Dropdown[];
  payPeriod: Dropdown[];
  payGrade: PaygradeDropdown[];
  payGroup: Dropdown[];
  payCategory: Dropdown[];
  payClasses: Dropdown[];
  payTypes: Dropdown[];
  performanceActionCodes: Dropdown[];
  performanceIncreaseTypes: Dropdown[];
  performanceRatingCodes: Dropdown[];
  performanceReviewCodes: Dropdown[];
  publicTitle: Dropdown[];

  salaryGrade: Dropdown[];
  schedulePatterns: Dropdown[];
  schoolTypeCode: Dropdown[];
  seniorityCalculation: Dropdown[];
  shiftCode: Dropdown[];
  shiftPremium: ShiftPremium[];
  shiftPremiumEarningsCode: Dropdown[];
  staffingReason: Dropdown[];
  state: Dropdown[];  
  stateAdditionalTaxEntity: Dropdown[];
  stateTaxEntity: Dropdown[];
  subDepartment: SubDepartment[];
  subDepartment2: SubDepartment2[];

  taxes: any[];
  taxEntity: TaxEntity[];
  ten99: Dropdown[];
  ten99TaxpayerIdType: Dropdown[];
  termCode: Dropdown[];
  trainingStatusTypes: Dropdown[];

  vaccinationBrand: Dropdown[];
  vaccinationDosageType: Dropdown[];
  vaccinationStatus: Dropdown[];
  vaccinationType: Dropdown[];
  visaType: Dropdown[];

  workersCompensation: Dropdown[];
  workgroupLevels: Dropdown[];
  workgroups: Dropdown[];
  wgLocations: Dropdown[];
  wgDepartments: Dropdown[];
  wgTasks: Dropdown[];
  wgPositions: Dropdown[];
  wgSupervisors: Dropdown[];
  cineTrainJobTitle: Dropdown[];

  prevailingWageTradeName: Dropdown[];
  
  // MISSING
  fanPercentage: Dropdown[];
  taxTitle: Dropdown[];
  filingStatus: Dropdown[];
}

export const INITIAL_STATE: State = {
  loading: false,
  error: null,

  aca1095BOriginOfPolicy: [],
  acaOverrideOfferOfCoverage: [],
  acaOverrideSafeHarbor: [],
  acaSafeHarborWaiveCoverage: [],

  accrualRule: [],
  activeStatus: [],
  activeStatusConditions: [],

  benefitClassCode: [],
  benefitEligibilityGroup: [],

  careerLevel: [],
  changeReasons: [],
  clockGroups: [],
  communicationPreference: [],
  companyBenefitDeductionCode: [],
  contactRelationship: [],

  deferredCompCatchUpMethod: [],
  deferredCompClassExclusion: [],
  department: [],
  deductionCode: [],
  deductionFreq: [],
  deductionStatus: [],
  deductionUnit: [],
  displayOnTransmittal: [],
  divisionCode: [],

  earningsCode: [],
  educationDegreeCodes: [],
  educationMajorCodes: [],
  educationSchools: [],
  eeoCode: [],
  eeoOccupationalGroup: [],
  eeoUnitNo: [],
  employeeStatus: [],
  employmentType: [],
  empMasterCustomFields: [],
  empIssuedPropertyType: [],
  empSkillSourceCodes: [],
  empSkillTypes: [],
  ePrescribe: [],
  exitInterviewQuestion: [],
  exemptStatus: [],

  federalTaxes:[],
  federalTaxEntity: [],
  fileAccessLevel: [],
  fileAdminType: [],
  fileCategory: [],
  fileFillType: [],
  foreignCountry: [],

  garnishmentFIPsCode: [],
  garnishmentEntity: [],
  gender: [],
  geographicLevel: [],
  gEDCMultiStep: [],
  gEDCDocCompCareerLevel: [],
  gEDCDocCompContractVersion: [],
  gEDCAnnualDues: [],
  gEDCCompensationType: [],
  groupTermLifeEarningsCode: [],

  hireReferralSource: [],
  hourlyStatuses: [],

  i9DocumentTitle: [],
  inactiveStatusConditions: [],
  federalBlsSocCodes: [],

  jobFamily: [],
  jobCategory: [],
  jobNumbers: [],
  costCode: [],
  jobTitle: [],

  layoffCode: [],
  licenseRenewalTypes: [],
  localTaxEntity: [],
  localTaxEntityQuickOnboard: [],
  localTaxEntityWithCode: [],
  location: [],

  maritalStatus: [],
  majorCodes: [],

  newJobTrainingProgram: [],
  nonCompete: [],
  novaTimeClockSetting: [],
  novatimeSettings: null,
  novatimeShift: [],
  novatimeRule: [],
  novatimeGroup1: [],
  novatimeGroup2: [],
  novatimeGroup3: [],
  novatimeGroup4: [],
  novatimeGroup5: [],
  novatimeGroup6: [],
  novatimeGroup7: [],
  novatimeGroup8: [],

  otherDateDescription: [],

  paidSickTimeEntity: [],
  payrollInfoEicCode: [],
  payPolicy: [],
  payPeriod: [],
  payGrade: [],
  payGroup: [],
  payCategory: [],
  payClasses: [],
  payTypes: [],
  performanceActionCodes: [],
  performanceIncreaseTypes: [],
  performanceRatingCodes: [],
  performanceReviewCodes: [],
  publicTitle: [],

  salaryGrade: [],
  schedulePatterns: [],
  schoolTypeCode: [],
  seniorityCalculation: [],
  shiftCode: [],
  shiftPremium: [],
  shiftPremiumEarningsCode: [],
  staffingReason: [],
  state: [],
  stateAdditionalTaxEntity: [],
  stateTaxEntity: [],
  subDepartment: [],
  subDepartment2: [],

  taxes: [],
  taxEntity: [],
  ten99: [],
  ten99TaxpayerIdType: [],
  termCode: [],
  trainingStatusTypes: [],

  vaccinationBrand: [],
  vaccinationDosageType: [],
  vaccinationStatus: [],
  vaccinationType: [],

  visaType: [],

  workersCompensation: [],
  workgroupLevels: [],
  workgroups: [],
  wgLocations: [],
  wgDepartments: [],
  wgTasks: [],
  wgPositions: [],
  wgSupervisors: [],
  cineTrainJobTitle: [],

  prevailingWageTradeName: [],
  
  // MISSING
  fanPercentage: [],
  taxTitle: [],
  filingStatus: [],
};

const storeAllDropdownsReducer = (state: State, action: Actions) => {
  const dropdowns: { [key: string]: Dropdown[] } = {};
  const data = action.payload;
  data.forEach((item: any) => {
    let { dropdownName } = item;
    // These items will be loaded individually as they have many more properties.
    if (
      [
        'Department',
        'SubDepartment',
        'SubDepartment2',
        'Location',
        'PayGrade',
        'EducationSchools',
      ].includes(dropdownName)
    ) {
      return;
    }
    // dropdown name comes in properly cased -> convert to camel-case.
    dropdownName = dropdownName.replace(/NOVA/, 'nova');
    dropdownName = dropdownName.replace(
      /^.{1}/g,
      dropdownName[0].toLowerCase(),
    );
    
    if (dropdowns[dropdownName]) {
      dropdowns[dropdownName].push(item);
    } else {
      dropdowns[dropdownName] = [item];
    }
  });
  // TODO for testing until we get the real data populated.
  const maritalStatus = [
    {
      clientNo: '',
      id: 'M',
      description: 'Married',
      allowChanges: false,
      usesCode: false,
      code: '',
    },
    {
      clientNo: '',
      id: 'S',
      description: 'Single',
      allowChanges: false,
      usesCode: false,
      code: '',
    },
  ];
  dropdowns.maritalStatus = maritalStatus;
  return { ...state,
    ...dropdowns };
};

const storeDropdownReducer = (state: State, action: Actions) => {
  const updatedDropdown: { [key: string]: Dropdown[] } = {};
  const clientNo = action.payload.clientNo;
  action.payload.dropdowns.forEach((item: any) => {
    let { dropDownName, allowChanges } = item;
    dropDownName = dropDownName.replace(/NOVA/, 'nova');
    dropDownName = dropDownName.replace(
      /^.{1}/g,
      dropDownName[0].toLowerCase(),
    );
    updatedDropdown[dropDownName] = item.values.map((x: any) => {
      return {
        ...x,
        clientNo,
        allowChanges,
      };
    });
  });
  return { ...state,
    ...updatedDropdown };
};

const sortDepartments = (departments: Department[]): Department[] => {return departments.sort((a: Department, b: Department) => {return a.deptCode - b.deptCode;});};

export const reducer = createReducer(INITIAL_STATE, {
  [storeAllDropdowns.type]: storeAllDropdownsReducer,
  [storeDropdown.type]: storeDropdownReducer,
  [storeLocations.type]: (state, action) => {
    return {
      ...state,
      location: action.payload
        ? action.payload.sort(
          (a: any, b: any) => {return a.locationCode - b.locationCode;},
        )
        : [],
    };
  },
  [storeLocation.type]: (state, action) => {
    return {
      ...state,
      location: [
        ...state.location.filter(
          (l: any) => {return l.locationID !== action.payload.locationID;},
        ),
        action.payload,
      ].sort((a, b) => {return a.locationCode - b.locationCode;}),
    };
  },
  [removeLocation.type]: (state, action) => {
    return {
      ...state,
      location: [
        ...state.location.filter((d) => {return d.locationID !== +action.payload;}),
      ],
    };
  },

  [storeDepartments.type]: (state, action) => {
    return {
      ...state,
      department: action.payload ? sortDepartments(action.payload) : [],
    };
  },
  [storeDepartment.type]: (state, action) => {
    return {
      ...state,
      department: sortDepartments([
        ...state.department.filter(
          (d: any) => {return d.deptId !== action.payload.deptId;},
        ),
        action.payload,
      ]),
    };
  },

  [removeDepartment.type]: (state, action) => {
    return {
      ...state,
      department: [
        ...state.department.filter((d) => {return d.deptId !== action.payload;}),
      ],
    };
  },

  [storeSubdepartments.type]: (state, action) => {
    return {
      ...state,
      subDepartment: action.payload
        ? action.payload.sort(
          (a: any, b: any) => {return a.subDeptCode - b.subDeptCode;},
        )
        : [],
    };
  },
  [storeSubdepartment.type]: (state, action) => {
    return {
      ...state,
      subDepartment: [
        ...state.subDepartment.filter(
          (d: any) => {return d.subDeptID !== action.payload.subDeptID;},
        ),
        action.payload,
      ].sort((a, b) => {return a.subDeptCode - b.subDeptCode;}),
    };
  },

  [removeSubDepartment.type]: (state, action) => {
    return {
      ...state,
      subDepartment: [
        ...state.subDepartment.filter(
          (d) => {return d.subDeptID !== action.payload;},
        ),
      ],
    };
  },

  [storeSubdepartments2.type]: (state, action) => {
    return {
      ...state,
      subDepartment2: action.payload
        ? action.payload.sort((a: any, b: any) => {return a.sub2Code - b.sub2Code;})
        : [],
    };
  },
  [storeSubdepartment2.type]: (state, action) => {
    return {
      ...state,
      subDepartment2: [
        ...state.subDepartment2.filter(
          (d: any) => {return d.subDept2ID !== action.payload.subDept2ID;},
        ),
        action.payload,
      ].sort((a, b) => {return a.sub2Code - b.sub2Code;}),
    };
  },

  [removeSubDepartment2.type]: (state, action) => {
    return {
      ...state,
      subDepartment2: [
        ...state.subDepartment2.filter(
          (d) => {return d.subDept2ID !== action.payload;},
        ),
      ],
    };
  },

  [storeNOVATimeSettings.type]: (state, action) => {
    return {
      ...state,
      novatimeSettings: action.payload,
    };
  },
  [storeNOVATime.type]: (state, action) => {
    return {
      ...state,
      novatime: action.payload,
    };
  },
  [storeNOVATimeShiftNumbers.type]: (state, action) => {
    return {
      ...state,
      novatimeShifts: action.payload,
    };
  },
  [storeNOVATimeRules.type]: (state, action) => {
    return {
      ...state,
      novatimeRule: action.payload,
    };
  },

  [storeShiftPremiums.type]: (state, action) => {
    return {
      ...state,
      shiftPremium: action.payload,
    };
  },
  [storeShiftPremium.type]: (state, action) => {
    return {
      ...state,
      shiftPremium: [
        ...state.shiftPremium.filter(
          (s: ShiftPremium) => {return s.shiftPremiumId !== action.payload.shiftPremiumId;},
        ),
        action.payload,
      ],
    };
  },
  [storePaygradeDropdowns.type]: (state, action) => {
    return {
      ...state,
      payGrade: action.payload,
    };
  },

  [storeSchoolDropdowns.type]: (state, action) => {
    return {
      ...state,
      educationSchools: action.payload,
    };
  },

  [storeTaxEntities.type]: (state, action) => {
    return {
      ...state,
      taxEntity: action.payload,
    };
  },

  [storeAODItems.type]: (state, action) => {
    const dropdowns = action.payload.dropdowns;
    const clientNo = action.payload.clientNo;
    const workgroupLevels: Dropdown[] = [];
    const workgroupDropDowns: Dropdown[] = [];
    if (dropdowns?.workgroups) {
      const levelGroups = groupBy(dropdowns.workgroups, (w) => {return w.level;});
      const wg = dropdowns.workgroups.map(
        (d: any) => {
          return new Dropdown(clientNo, d.level, {
            description: d.levelName,
          });
        },
      );
      const map = new Map();
      for (const item of wg) {
        if (!map.has(item.description)) {
          map.set(item.description, true);
          workgroupLevels.push({ ...(item as Dropdown) });
        }
      }

      Object.keys(levelGroups).forEach((key) => {
        const level = levelGroups[key];
        level.forEach((l) => {
          const dd = new Dropdown(clientNo, +l.num, {
            description: l.name,
            code: l.code,
            usesCode: true,
            wgLevel: key,
          });
          workgroupDropDowns.push({ ...dd });
        });
      });

      return {
        ...state,
        activeStatusConditions: dropdowns.activeStatusConditions.map(
          (d: any) => {
            const dd = new Dropdown(
              action.payload.clientNo,
              d.num,
              {
                description: d.nameLabel,
              },
            );
            return { ...dd };
          },
        ),
        clockGroups: dropdowns.clockGroups.map((d: any) => {
          const dd = new Dropdown(action.payload.clientNo, d.num, {
            description: d.nameLabel,
          });
          return { ...dd };
        }),
        hourlyStatuses: dropdowns.hourlyStatuses.map((d: any) => {
          const dd = new Dropdown(action.payload.clientNo, d.num, {
            description: d.nameLabel,
          });
          return { ...dd };
        }),
        inactiveStatusConditions:
                    dropdowns.inactiveStatusConditions.map((d: any) => {
                      const dd = new Dropdown(
                        action.payload.clientNo,
                        d.num,
                        {
                          description: d.nameLabel,
                        },
                      );
                      return { ...dd };
                    }),
        payClasses: dropdowns.payClasses.map((d: any) => {
          const dd = new Dropdown(action.payload.clientNo, d.num, {
            description: d.nameLabel,
          });
          return { ...dd };
        }),
        payTypes: dropdowns.payTypes.map((d: any) => {
          const dd = new Dropdown(action.payload.clientNo, d.num, {
            description: d.nameLabel,
          });
          return { ...dd };
        }),
        schedulePatterns: dropdowns.schedulePatterns.map((d: any) => {
          const dd = new Dropdown(action.payload.clientNo, d.num, {
            description: d.nameLabel,
          });
          return { ...dd };
        }),
        workgroupLevels,
        workgroups: workgroupDropDowns,
        wgLocations: workgroupDropDowns.filter(
          (w) => {return w.wgLevel === '1';},
        ),
        wgDepartments: workgroupDropDowns.filter(
          (w) => {return w.wgLevel === '2';},
        ),
        wgTasks: workgroupDropDowns.filter((w) => {return w.wgLevel === '3';}),
        wgPositions: workgroupDropDowns.filter(
          (w) => {return w.wgLevel === '4';},
        ),
        wgSupervisors: workgroupDropDowns.filter(
          (w) => {return w.wgLevel === '5';},
        ),
      };
    }
  },
});
