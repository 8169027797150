import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import EmpPhoto from 'core/components/shared/EmpPhoto';
import Icon from 'core/components/shared/Icon';
import { Employee } from 'core/models';
import styles from './job-roster/styles.module.scss';
import JobRosterDeductionsModal from './job-roster/JobRosterDeductions.modal';
import { TimeCardJobRosterDeductions } from 'core/models/Contractor.model';

type Props = {
  employee: Employee;
  viewDeductions?: boolean;
  expandAll: boolean;
  deductions?: TimeCardJobRosterDeductions[];
  headerInfo?: { field: string; data: string | number; }[];
};

const EmpSnapshotRow = ({ employee, viewDeductions, headerInfo, children, expandAll, deductions = [] }: PropsWithChildren<Props>) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showDeductionsModal, setShowDeductionsModal] = useState<boolean>(false);

  useEffect(() => {
    setIsExpanded(false);
  }, [children]);

  useEffect(() => {
    setIsExpanded(expandAll);
  }, [expandAll]);
  
  return (
    <>
      {showDeductionsModal && (
        <JobRosterDeductionsModal
          show={showDeductionsModal}
          onHide={() => { setShowDeductionsModal(false); }}
          deductions={deductions}
        />
      )}
      <div className="d-flex flex-column">
        <div
          className={`time-card-header ${styles['detail-item-header']}`}
          onClick={() => { setIsExpanded(!isExpanded); }}
        >
          <div className="card-header-row">
            <div className="emp-main-info">
              <EmpPhoto
                empPicData={employee.employeePhoto}
                empName={`${employee.firstName} ${employee.lastName}`}
                scaleFactor="65"
              />
              <div className="d-flex flex-column">
                <span className="dm-grid-title emp-name mb-1">{employee.firstName}&nbsp;{employee.lastName}</span>
                <div className="header-info-section">
                  <span className="font-weight-bold">Emp No:&nbsp;{employee.empNo}</span>
                  {headerInfo?.length ? (
                    <>
                      {headerInfo.map((info) => (
                        <span
                          key={info.field}
                          className="font-weight-bold"
                        >
                          {info.field}:&nbsp;{info.data}
                        </span>
                      ))}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="d-flex ml-auto">
              {viewDeductions && (
                <button
                  className="btn btn-link dm-grid-action-title py-0 mr-3"
                  onClick={(e) => {
                    setShowDeductionsModal(true);
                    e.stopPropagation();
                  }}
                >
                  View Deductions
                </button>
              )}
              <button
                className="btn btn-link"
                onClick={() => { setIsExpanded(!isExpanded); }}
              >
                {isExpanded ? 'Collapse' : 'Expand'}
            &nbsp;
                <Icon
                  name={isExpanded ? 'chevron-circle-up' : 'chevron-circle-down'}
                  className={isExpanded ? 'roster-chevron flipped' : 'roster-chevron'}
                />
              </button>
            </div>
          </div>
        </div>
        {isExpanded ? children : null}
      </div>
    </>
  );
};

export default EmpSnapshotRow;