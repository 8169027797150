import React, { CSSProperties } from 'react';
import Spinner from './Spinner';
import Icon from './Icon';

type StylesObject = { [key: string]: CSSProperties };

const styles: StylesObject = {
  spinnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
    width: 'auto',
    fontSize: '1rem',
  },
  spinner: {
    width: '65px',
    height: '65px',
  },
};

type Props = {
  overrideStyles?: StylesObject;
  showActionButton?: boolean;
  error?: boolean;
  loadingMessage?: string;
  errorMessage?: string;
  actionMessage?: string;
  onActionClick?: () => void;
};

const PageSpinner = ({
  overrideStyles,
  error = false,
  showActionButton = false,
  actionMessage = '',
  loadingMessage = 'Loading...',
  errorMessage = 'Error',
  onActionClick,
}: Props) => {
  return (
    <div style={{ ...styles.spinnerWrapper,
      ...overrideStyles?.spinnerWrapper }}
    >
      {error ? (
        <>
          <Icon
            name="warning"
            style={styles.spinner}
            color="gray"
          />
          <div className="mt-3">{errorMessage}</div>
          {showActionButton && (
            <button
              className="btn orange-button"
              onClick={onActionClick}
            >
              {actionMessage}
            </button>
          )}
        </>
      ) : (
        <>
          <Spinner
            styles={{
              ...styles.spinner,
              ...overrideStyles?.spinner,
            }}
          />
          <div className="mt-3">{loadingMessage}</div>
        </>
      )}
    </div>
  );
};

export default PageSpinner;