import React, { CSSProperties } from 'react';
import { InputGrp } from 'core/components/form-controls';
import { inputGrpStyle } from './ContractorTimeSheet';
import './time-card-styles.scss';

export const inputStyle: CSSProperties = {
  height: '26px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textAlign: 'center',
};

type InputSectionProps = {
  label?: string;
  value?: string | number;
  defaultValue?: string | number;
  labelStyles?: CSSProperties;
  addtlInputStyles?: CSSProperties;
  readOnly?: boolean;
};

const InputSection = ({
  defaultValue,
  value,
  label,
  labelStyles,
  addtlInputStyles = {},
  readOnly = false,
}: InputSectionProps) => (
  <div className="input-section">
    {label && (
      <span
        className="input-label"
        style={labelStyles}
      >
        {label}
      </span>
    )}          
    <InputGrp 
      value={value}
      defaultValue={defaultValue}
      title={value}
      readOnly={readOnly}
      groupStyle={inputGrpStyle}
      inputStyle={{ ...inputStyle, ...addtlInputStyles }}
    />
  </div>
);

export default InputSection;