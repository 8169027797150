import React, { useEffect, useState } from 'react';
import { Prompt, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PanelHeader from 'core/components/shared/PanelHeader';
import { HRExitInterview, HRExitInterviewRequest } from 'core/models/HREmployee.model';
import {
  loadHRExitInterview, updateHRExitInterview,
} from 'core/store/actions/hr-employee.action';
import { getExitInterview } from 'core/store/selectors/hr-employee.selector';
import { useForm, useFieldArray } from 'react-hook-form';
import { UNSAVED_MESSAGE } from 'core/constants';
import InterviewAnswerItem from './InterviewAnswerItem';
import { CheckboxGrpInLine, ControlDatePickerGrp, SelectGrp, TextareaGrp } from 'core/components/form-controls';
import { getUsers } from 'core/store/slices/cmUsers.slice';
import { useAppSelector } from 'utilities/hooks';
import { getExitInterviewQuestions } from 'core/store/selectors';
import { Dropdown } from 'core/models';
import Icon from 'core/components/shared/Icon';
import { toggleBlockNavigation } from 'core/store/actions';

const ExitInterviewPage = () => {
  const dispatch = useDispatch();

  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();
  const exitInterviewStore = useSelector(getExitInterview) as HRExitInterview;
  const [exitInterviewItem, setExitInterview] = useState<HRExitInterview>();
  const users = useAppSelector((state) => { return state.cmUsers.users; });

  const questions = useSelector(getExitInterviewQuestions);
  const [filteredQuestions, setFilteredQuestions] = useState<Dropdown[]>([]);

  useEffect(() => {
    const usedQuestions = exitInterviewStore?.answers?.map(a => { return a.exitInterviewQuestionId; });
    const filterQuestions = questions?.filter(q => { return !usedQuestions?.includes(+q.id); }) as unknown as Dropdown[];
    filterQuestions && setFilteredQuestions(filterQuestions);
  }, [exitInterviewStore, questions]);

  const {
    reset,
    control,
    handleSubmit,
    formState,
    ...formMethods
  } = useForm<HRExitInterview>({
    defaultValues: exitInterviewItem,
  });

  const { fields, append } =
    useFieldArray({
      control,
      name: 'answers',
      keyName: 'id',
    });

  useEffect(() => {
    dispatch(getUsers());
  }, []);


  useEffect(() => {
    protectedEmpNo && dispatch(loadHRExitInterview(protectedEmpNo));
  }, [protectedEmpNo]);

  useEffect(() => {
    exitInterviewStore && setExitInterview(exitInterviewStore);
  }, [exitInterviewStore]);

  useEffect(() => {
    exitInterviewItem && reset(exitInterviewItem);
  }, [exitInterviewItem]);


  const addQuestion = () => {
    append({
      exitInterviewId: exitInterviewItem?.empExitInterviewId,
      exitInterviewQuestionId: 0,
    }, true);
  };

  const onSubmit = (data: HRExitInterview, e?: React.BaseSyntheticEvent) => {
    if (!e?.target.isConnected) {
      return false;
    }

    const request: HRExitInterviewRequest = {
      protectedEmpNo,
      data,
    };

    dispatch(updateHRExitInterview(request));
  };

  const onCancel = () => {
    reset();
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: formState.isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [formState.isDirty]);

  return (
    <div>
      <div className="dm-panel dm-panel-border" >
        <PanelHeader title="Exit Interview" >
        </PanelHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="hidden"
            name="empExitInterviewId"
            defaultValue={exitInterviewItem?.empExitInterviewId}
            ref={formMethods.register({ valueAsNumber: true })}
          />
          <div className="d-flex flex-row">
            <div className="d-flex flex-column flex-grow-1">
              <div className="d-flex flex-row">
                <ControlDatePickerGrp
                  name={'interviewDate'}
                  label="EXIT INTERVIEW DATE"
                  groupClass="gc12 mw150"
                  value={exitInterviewItem?.interviewDate}
                  setValue={formMethods.setValue}
                  control={control}
                />
                <CheckboxGrpInLine
                  name={'wouldRehire'}
                  labelFirst={false}
                  labelWidth={150}
                  labelWidthUnit="px"
                  label="NOT ELIGIBLE FOR REHIRE"
                  ref={formMethods.register()}
                  groupClass="gc12 mw150"
                  defaultChecked={exitInterviewItem?.wouldRehire}
                />
              </div>
              <div>
                <TextareaGrp
                  defaultValue={exitInterviewItem?.comments}
                  label="COMMENTS"
                  groupClass="gc12"
                  ref={formMethods.register()}
                  name="comments"
                />
              </div>
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <SelectGrp
                groupClass="gc12 mw125"
                label={'PERFORMED BY'}
                name={'performedBy'}
                labelField="fullName"
                valueField="userName"
                options={users}
                ref={formMethods.register()}
                defaultValue={exitInterviewItem?.performedBy}
              />
              <TextareaGrp
                label="EMPLOYEE COMMENTS"
                defaultValue={exitInterviewItem?.empComments}
                ref={formMethods.register()}
                name="empComments"
              />
            </div>
          </div>
          <PanelHeader title="Questions">
            <button
              type="button"
              className="btn btn-link dm-grid-action-title p-0 mr-2"
              onClick={addQuestion}
            >
              Add Question <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          </PanelHeader>
          {fields?.map((item, index: number) => {
            return (
              <div key={item.id} >
                <InterviewAnswerItem
                  key={item.id}
                  item={item}
                  index={index}
                  formMethods={formMethods}
                  questions={filteredQuestions}
                />
                <hr className="dm-panel-hr mt-2" />
              </div>
            );
          })}
          <div className="text-right mt-3">
            <button
              type="button"
              className="btn btn-primary orange-outline-button ml-auto mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              disabled={!formState.isDirty}
              type="submit"
              className="btn orange-button-sm mr-3"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExitInterviewPage;
