import { Employee3YearPayHistoryRequest, EmployeePayRateDownloadRequest } from 'core/models/PayRateReports.model';
import { ofType } from 'redux-observable';
import { catchError, from, map, mergeMap, Observable, switchMap } from 'rxjs';
import { HttpResponse } from '../../models/HttpResponse.model';
import { PayRateReportsService } from '../../services/payRateReports.service';
import { handleError, handleSuccess, handleWarning } from '../actions/app.action';
import {
  getFuturePayRateReport,
  storePayRateReport,
  downloadEmployee3YearPayHistory,
  storeEmployee3YearPayHistory,
  downloadEmployeePayRateDownloadReport,
  storeEmployeePayRateDownloadReport,
} from '../slices/payRateReports.slice';

interface Actions<Type> {
  type: string;
  payload: Type;
}

const getFuturePayRateReport$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getFuturePayRateReport.type),
    switchMap(() => {
      return from(PayRateReportsService.postFuturePayRateReport()).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storePayRateReport(res.value); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};


const downloadEmployee3YearPayHistoryDownload$ = (action$: Observable<Actions<Employee3YearPayHistoryRequest>>) => {
  return action$.pipe(
    ofType(downloadEmployee3YearPayHistory.type),
    switchMap((action: { payload: Employee3YearPayHistoryRequest; }) => {
      return from(PayRateReportsService.postEmployee3YearPayHistory(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeEmployee3YearPayHistory(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadEmployeePayRateDownloadReport$ = (action$: Observable<Actions<EmployeePayRateDownloadRequest>>) => {
  return action$.pipe(
    ofType(downloadEmployeePayRateDownloadReport.type),
    switchMap((action: { payload: EmployeePayRateDownloadRequest })  => {
      return from(PayRateReportsService.postEmployeePayRateDownloadReport(action.payload)).pipe(
        map((res) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeEmployeePayRateDownloadReport(res.value),
            handle,
          ];
        }),
      );
    }),
  );
};

export const epics: any[] = [
  getFuturePayRateReport$,
  downloadEmployee3YearPayHistoryDownload$,
  downloadEmployeePayRateDownloadReport$,
];
