import React, { useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import SalesTaxDetailModal from 'features/payroll/sales-tax-transmittal/SalesTaxDetail.modal';
import { DateTime } from 'luxon';
import { AllModules, MasterDetailModule } from '@ag-grid-enterprise/all-modules';

type PropTypes = {
  item: any;
  onClick?: any;
};

const GridItem: React.FC<PropTypes> = ({ item, onClick }) => {
  const [salesTaxEntityId, setSalesTaxEntityId] = useState(0);
  const [salesTaxMonthId, setSalesTaxMonthId] = useState(0);
  const [salesTaxState, setSalesTaxState] = useState('');
  const [closed, setClosed] = useState(false);

  const DetailCellRenderer: React.FC<any> = (props) => {
    return (<div style={{ padding: '10px', background: '#e3fedb' }}>
      {`Closed on ${DateTime.fromISO(props?.data?.closedByDate).toFormat('LL/dd/yyyy hh:mm:ss a')} by ${props?.data?.closedBy}`}
    </div>);
  };
  
  const LinkCellRenderer: React.FC<any> = (props) => {
    return <div>
      <button
        type="button"
        className="btn btn-link"
        style={{ fontWeight: 'bold' }}
        onClick={() => {
          setSalesTaxEntityId(props?.data?.entityId);
          setSalesTaxMonthId(props?.data?.salesTaxMonthId);
          setSalesTaxState(props?.data?.state);
          setClosed(!!props?.data?.closedByDate);
        }}
      >
        View Details
      </button>
    </div>;
  };

  const onFirstDataRendered = (params: any) => {
    return params.api.forEachNode((node: any) => {
      return node.setExpanded(true);
    });
  };

  return (
    <div className="ag-theme-balham">
      {
        item?.masterDetail ?
          (
            <AgGridReact
              detailRowAutoHeight={true}
              masterDetail={true}
              detailCellRenderer={'myDetailCellRenderer'}
              detailCellRendererParams={{ closedBy: item?.closedBy, closedByDate: item.closedByDate }}
              frameworkComponents={{
                myDetailCellRenderer: DetailCellRenderer,
                linkCellRenderer: LinkCellRenderer,
              }}
              onFirstDataRendered={onFirstDataRendered}
              rowData={item?.data}
              gridOptions={item?.gridOptions}
              modules={[MasterDetailModule]}
            >
            </AgGridReact>
          ) :
          (
            <AgGridReact
              masterDetail={false}
              rowData={item?.data}
              gridOptions={item?.gridOptions}
              frameworkComponents={{ linkCellRenderer: LinkCellRenderer }}
              modules={AllModules}
            >
            </AgGridReact>
          )
      }
      {(salesTaxEntityId > 0) && (salesTaxMonthId > 0) &&
        <SalesTaxDetailModal
          show={(salesTaxEntityId > 0) && (salesTaxMonthId > 0)}
          year={item.year}
          month={item.month}
          salesTaxMonthId={salesTaxMonthId}
          entityId={salesTaxEntityId}
          state={salesTaxState}
          closed={closed}
          onHide={() => { return setSalesTaxEntityId(0); }}
        />}
    </div>
  );
};

export default GridItem;