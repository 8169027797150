import React, { useEffect, useState } from 'react';
import Icon from 'core/components/shared/Icon';
import { Alert, Nav, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import MyInfoChangeHistoryModal from 'core/components/modals/myinfochanges-history/ChangeHistory.modal';
import EmpPhoto from 'core/components/shared/EmpPhoto';
import { MyInfoChangeLog } from 'core/models/MyInfoChangeLog';
import { loadEmployee, loadMyInfoChangesHistory } from 'core/store/actions';
import { loadMyInfoChangeLog } from 'core/store/actions/my-info-changes.action';
import { getMyInfoChangesHistory, getSelectedEmp } from 'core/store/selectors';
import { getMyInfoChanges } from 'core/store/selectors/my-info-changes.selector';
import { getEmployees } from 'core/store/selectors/employees.selector';
import MyInfoChanges from '../emp-detail/my-info-changes/MyInfoChanges';

type EmployeeChanges = {
  empNo: number;
  protectedEmpNo: string;
  empName: string;
};

const ReviewMyInfoChanges = () => {
  const [namesChanges, setNamesChanges] = useState<EmployeeChanges[]>([]);
  const [selectedEmp, setSelectedEmp] = useState<number>(0);
  const [showMyInfoHistory, setShowMyInfoHistory] = useState<boolean>(false);
 
  const myInfoChanges = useSelector(getMyInfoChanges);
  const employeeInfo = useSelector(getSelectedEmp);
  const employeeList = useSelector(getEmployees);
  const myInfoChangesHistory = useSelector(getMyInfoChangesHistory);

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(loadMyInfoChangeLog());
  }, []);

  useEffect(() => {
    if (myInfoChanges) {
      setNamesChanges(myInfoChanges.map((item) => {
        const employee = employeeList.find((emp) => { return emp.empNo === item.empNo; });
        return {
          empNo: item.empNo,
          protectedEmpNo: item.protectedEmpNo,
          empName: employee ? `${employee.lastName}, ${employee.firstName} ${employee.midName}` : '',
        };
      }));
    }
  }, [myInfoChanges]);

  useEffect(() => {
    if (namesChanges.length > 0 && (selectedEmp === 0 || namesChanges.findIndex((item) => { return item.empNo === selectedEmp; }) === -1)) {
      setSelectedEmp(namesChanges[0].empNo);
    }
  }, [namesChanges]);

  useEffect(() => {
    const name = namesChanges.find(a => { return a.empNo === selectedEmp; });
    if (name) {
      dispatch(loadEmployee(name.protectedEmpNo));
      dispatch(loadMyInfoChangesHistory({ protectedEmpNo: name.protectedEmpNo }));
    }
  }, [selectedEmp]);

  const employeeMasterSelect = (eventKey: string | null) => {
    setSelectedEmp(parseInt(eventKey as string));
  };

  const onMyInfoChangeHistory = () => {
    setShowMyInfoHistory(true);
  };

  return (
    <div className="container-fluid shadow shadow-lg">
      <div className="row mx-2">
        <div className="col-auto d-flex">
          <div className="dm-page-title">Pending MyInfo Changes</div>
        </div>
        {myInfoChanges.length !== 0 && (
          <div className="col d-flex justify-content-end align-items-end">
            <Nav onSelect={employeeMasterSelect} >
              <NavDropdown
                title="Employees with Pending MyInfo Changes"
                id="nav-dropdown"
              >
                {namesChanges.map((name) => {
                  return (
                    <NavDropdown.Item
                      key={name.empNo}
                      eventKey={name.empNo}
                    >
                      {name.empName}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
            </Nav>
          </div>
        )}
      </div>
      <hr className="dm-page-hr mx-4 mb-4 row" />
      {myInfoChanges.length === 0 && (
        <div className="row mx-2">
          <div className="col">
            <Alert variant="warning">
              There are no pending MyInfo changes.
            </Alert>
          </div>
        </div>
      )}
      {selectedEmp !== 0 && myInfoChanges.length > 0 && (
        <div className="animate">
          <div
            className="row mx-2 mb-4"
          >
            <div className="col-sm-1">
              <EmpPhoto
                empPicData={employeeInfo?.employeePhoto}
                empName={`${employeeInfo?.firstName} ${employeeInfo?.lastName}`}
              />
            </div>
            <div className="col-auto mr-auto">
              <strong>
                {employeeInfo?.firstName} {employeeInfo?.lastName}
              </strong>
              <br />
              <strong>Emp No {employeeInfo?.empNo}</strong>
            </div>
            <div className="col-auto">
              <Icon name="eye" />
              <button
                type="button"
                className="btn btn-link"
                onClick={onMyInfoChangeHistory}
              >View Myinfo Change History</button>
            </div>
          </div>
          <div className="row mx-2 mb-4">
            <div className="w-100">
              {myInfoChanges.find(a => { return a.empNo === selectedEmp; }) && (
                <MyInfoChanges myInfoChange={myInfoChanges.find(a => { return a.empNo === selectedEmp; }) as MyInfoChangeLog} />
              )}
            </div>
          </div>
        </div>
      )}
      {showMyInfoHistory && <MyInfoChangeHistoryModal
        data={myInfoChangesHistory}
        show={showMyInfoHistory}
        onHide={() => { return setShowMyInfoHistory(false); }}
      />}
    </div >
  );
};

export default ReviewMyInfoChanges;