import { createSelector } from 'reselect';
import { payrollsUserOptionsState } from '../reducers';
import { State as PayrollsUserOptionsState } from '../reducers/payrolls-user-options.reducer';

export const getPayrollsUserOptions = createSelector(
  payrollsUserOptionsState,
  (state: PayrollsUserOptionsState) => {
    return state.payrollsUserOptions;
  });

export const getShowAddtl = createSelector(payrollsUserOptionsState, (state: PayrollsUserOptionsState) => {
  return !!state?.payrollsUserOptions?.showExtraDetails;
});