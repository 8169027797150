import { createReducer } from '@reduxjs/toolkit';
import { MyInfoChangeLog, MyInfoChangeLogHistory } from '../../models/MyInfoChangeLog';
import { loadMyInfoChangeLog, loadMyInfoChangeLogHistory, storeMyInfoChangeLog, storeMyInfoChangeLogHistory } from '../actions/my-info-changes.action';


export interface MyInfoChangeLogState {
  myInfoChangeLogHistory: MyInfoChangeLogHistory | null;
  myInfoChangeLogs: MyInfoChangeLog[];
  loading: boolean;
}

const initialState: MyInfoChangeLogState = {
  myInfoChangeLogHistory: null,
  myInfoChangeLogs: [],
  loading: false,
};

export const myInfoChangesReducer = createReducer(initialState, (builder) => {
  builder.addCase(loadMyInfoChangeLogHistory, (state) => {
    state.loading = true;
  }).addCase(storeMyInfoChangeLogHistory, (state, action) => {
    state.loading = false;
    state.myInfoChangeLogHistory = action.payload;
  }).addCase(loadMyInfoChangeLog, (state) => {
    state.loading = true;
  }).addCase(storeMyInfoChangeLog, (state, action) => {
    state.loading = false;
    state.myInfoChangeLogs = action.payload;
  });
});