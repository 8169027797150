import React, { useState, useEffect, useContext } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DownloaderField } from 'core/models/Downloader.model';
import CheckBoxForm from './CheckBoxForm';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import SelectCell from './SelectCell';
import { removeSelectedFieldBySection, setSelectedFields } from 'core/store/slices/employee-earnings-download.slice';
import { MapDropdownOption, MapNonDropdown } from './AddSelectedFields';
import { DownloaderContext } from '../UserMapPage';

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

export interface SelectedColumn {
  header: string,
  section: string,
}

type Props = {
  formMethods: any;
  section: string;
  title: string;
  dFields?: DownloaderField[];
  //Note: I had to make this type any instead of type Dropdown because LUX added some logic that added grossKey and withHolding key to the dropdown.
  options: any[];
  checkboxes?: DownloaderField[];
  checkboxesSecondSet?: DownloaderField[];
  disableCheckBox?: boolean | null;
  disableSecondSetCheckBox?: boolean | null;
  grossToNetChecked?: boolean | null;
  selectedFields?: any;
  pushSelectedFields?: any;
  isCodeField?: boolean;
  hasCodeColumn?: boolean;
  hasDescColumn?: boolean;
  hiddenColumns?: string[];
  tableHeight?: number;
  isMultiSelect?: boolean;
  fieldAddition?: string;
  useKey?: boolean;
};

const GridSelect = ({
  formMethods,
  section,
  title,
  dFields,
  options,
  checkboxes,
  checkboxesSecondSet,
  disableCheckBox = null,
  disableSecondSetCheckBox = null, 
  grossToNetChecked = true,
  isCodeField = true,
  hasCodeColumn = true,
  hasDescColumn = true,
  hiddenColumns,
  tableHeight = 275,
  isMultiSelect = false,
  fieldAddition = '',
  useKey = false,
}: Props) => {
  const [columns, setColumn] = useState<Column[]>([]);
  const [columnSelected, setColumnSelected] = useState<SelectedColumn[]>([]);
  const [columnUnselected, setColumnUnselected] = useState<SelectedColumn | null>(null);
  
  const selectedFieldsState = useAppSelector(({ employeeEarningsDownload }) => employeeEarningsDownload.selectedFields);
  const selectedFieldsCopy = structuredClone(selectedFieldsState);
  
  const { setIsDirty } = useContext(DownloaderContext);
  
  const appDispatch = useAppDispatch();

  useEffect(() => {
    if (dFields?.length) {
      getInitalHeaderSelected();
      buildColumns();
    }
  }, []);

  //Will check to see if any of the fields are already fully selected if so will add the header selected name so if clicked again will unselect them.
  const getInitalHeaderSelected = () => {
    let headerSelectorCopy = structuredClone(columnSelected);
    if (!selectedFieldsCopy) return;
     
    dFields?.forEach(col => {
      const currentColumn = selectedFieldsCopy.filter(x => x.fieldName.includes(col.name) && x.section === section);
      if (
        currentColumn.length === options.filter((x) =>
          !((col.name === 'Gross' && x.description === 'EIC')
            || (col.name === 'Withholding' && ['FUTA', 'Local SUI PA'].includes(x.description))),
        ).length
      ) {
        const selectedColumn: SelectedColumn = { header: col.name, section: section };
        headerSelectorCopy = ([...headerSelectorCopy, selectedColumn]);
      }
    });
    
    setColumnSelected([...headerSelectorCopy]);
  };

  //Will check to see if they unchecked a selected field if so reomve all of those fields if not will add all the selected fields in state.
  useEffect(() => {
    let filteredFields = structuredClone(selectedFieldsCopy).filter(x => !(x.fieldName === columnUnselected?.header && x.section === columnUnselected?.section));

    //Since the Earnings Federal Taxes has special conditions for its field names had to have its own logic for header select
    if (section === 'Earnings Federal Taxes' || section === 'Taxes') {
      options.map(option => {
        columns.map(column => {
          //Step 1a: Get Field Name
          let fieldName = (fieldAddition + option.description + ' ' + column.id);
          
          //Step 1b: Override fieldName if it uses a key
          if (useKey && column.id === 'Gross') fieldName = option.grossKey;
          else if (useKey && column.id === 'Withholding') fieldName = option.withHoldingKey;

          //Step 2: get the filter code and make sure that its a valid option and not disabled option
          const filterCode = (isCodeField) ? option.code : option.id;
          if (((column.id === 'Gross' && option.description === 'EIC') || (column.id === 'Withholding' && ['FUTA', 'Local SUI PA'].includes(option.description)) || column.id === 'description')) return;
          
          //Step 3a: If there was any unselected columns ensure you filter out the field name
          if (columnUnselected) {
            if (columnUnselected.header !== column.id) return;
            filteredFields = filteredFields.filter(x => !(x.fieldName === fieldName));
          }
          //Step 3b: Else we will add the field to the map
          else {
            if (!columnSelected.find(x => x.header === column.id)) return;
            if (filteredFields.find(x => x.fieldName === fieldName && (x.filter?.code || '') === filterCode)) return;
            filteredFields = [...filteredFields, MapNonDropdown(section, fieldName, filterCode, useKey, option.description)];
          }
        });
      });
    }
    //Else if it is not a special case we will just filter add any selected new fields.
    else {
      columnSelected.forEach(column => {
        //This way we dont add duplicates
        if (filteredFields.find(x => x.fieldName === column.header && x.section === column.section)) return;
        filteredFields = [...filteredFields, ...MapDropdownOption(column.section, column.header, options, isCodeField)];
      });
    }
    //Then set the unselected to null and save the fields in the redux store. 
    setColumnUnselected(null);
    appDispatch(setSelectedFields(filteredFields));
  }, [columnSelected, columnUnselected]);

  //This will let the user select a header cell and it will set the header that was selected or unselected so we can select all cells in that column
  const onHeaderClick = (selectedSection: string, selectedHeader: string) =>{
    if (!selectedHeader || !selectedSection || ['description', 'code'].includes(selectedHeader)) return;
    const headerSelectorCopy = structuredClone(columnSelected);
    const selectedColumn: SelectedColumn = { header: selectedHeader, section: selectedSection };

    if (!columnSelected.find(x => x.header === selectedColumn.header && x.section === selectedColumn.section)) {
      setColumnSelected([...headerSelectorCopy, selectedColumn ]);
    } else {
      const columns = headerSelectorCopy.filter(x => !(x.header === selectedColumn.header && x.section === selectedColumn.section));
      setColumnSelected(columns);
      setColumnUnselected(selectedColumn);
    } 
    
    setIsDirty(true);
  };

  //Builds the columns for the table based off the fields that are passed in along with adding the description and code if they are required.
  //This is where you can format the columns specifically or add any columns. 
  const buildColumns = () => {
    let returnColumns: Column[] = []; 

    if (hasDescColumn) {
      returnColumns = [
        {
          id: 'description',
          label: 'Description',
          minWidth: 80,
        },
        ...returnColumns,
      ]; 
    }
    
    if (hasCodeColumn) {
      returnColumns = [
        {
          id: 'code',
          label: 'Code',
          minWidth: 80,
        },
        ...returnColumns,
      ]; 
    }

    dFields?.forEach((field) => {
      returnColumns.push({
        id: field.name,
        label: field.name,
      });
    });

    setColumn(returnColumns);
  };

  return (
    <div
      key={section}
      className="dm-panel-blue dm-panel-border"
    >
      <div className="dm-grid-action-title-blue">{title}</div>
      <hr className="dm-panel-hr" />
      <div
        className="ag-theme-balham"
        style={{ height: '350px' }}
      >
        <Paper sx={{ width: '100%',
          overflow: 'hidden' }}
        >
          <TableContainer sx={{ maxHeight: tableHeight }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              className="ag-theme-balham"
            >
              <TableHead>
                <TableRow>
                  {columns
                    .map((column, index) => {
                      return (
                        <TableCell
                          key={section + column.id + index}
                          align={'left'}
                          className="CheckboxHeaderCustom"
                          style={{ minWidth: 90, cursor: 'pointer' }}
                          onClick={() => onHeaderClick(section, column.id)}
                          hidden={hiddenColumns?.includes(column.id)}
                        >
                          {column.label}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {options.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={section + row.id + index}
                    >
                      {columns
                        .map((column) => {
                        //Checks to see if we need to auto select a field for gross to net being checked or disable a field because they dont exist
                          const checked = (!!grossToNetChecked && ((column.id === 'Withholding' || column.id === 'Earnings') && row.description !== 'FUTA')) ? true : null;
                          const disableField = ((column.id === 'Gross' && row.description === 'EIC') || (column.id === 'Withholding' && ['FUTA', 'Local SUI PA'].includes(row.description)));
                          const rowCode = (isCodeField) ? row.code : row.id;

                          //Will find if the field was already saved and check it if it was. 
                          let foundField = selectedFieldsState.find(item => {
                            if (isMultiSelect)
                              return item.fieldName === (fieldAddition + row.description + ' ' + column.id);
                            //Some of dropdowns have grossKey and withholdingKey added to them so handled that logic here as we want to use those keys. TODO: CLEAN ALL THIS UP
                            if (useKey && column.id === 'Gross')
                              return (item.fieldName === row.grossKey && item.filter?.description === row.description);
                            if (useKey && column.id === 'Withholding')
                              return (item.fieldName === row.withHoldingKey && item.filter?.description === row.description);
                            else
                              return item.fieldName === column.id && item?.filter?.code === String(rowCode) && item?.filter?.description === row.description;
                          });

                          //NOTE: This is hacky, figure out a way to make this more generic/clean for any downloader grids that need it; 
                          if (section === 'Education History') {
                            foundField = selectedFieldsState.find(x => {return x.fieldName === column.id && x?.filter?.code === String(row.id);});
                          }

                          return (
                            <SelectCell
                              key={column.id}
                              column={column}
                              row={row}
                              section={section}
                              checkAll={checked ?? !!foundField}
                              isMultiSelect={isMultiSelect}
                              disable = {disableField}
                              isCodeField={isCodeField}
                              fieldAddition = {fieldAddition}
                              useKey={useKey}
                              shouldHideCell={hiddenColumns?.includes(column.id)}
                            />
                          );
                        })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {!!checkboxes?.length && (
          <div>
            <CheckBoxForm
              key={title}
              formMethods={formMethods}
              dFields={checkboxes ?? false}
              section={section}
              isSelectAll={false}
              disabled = {disableCheckBox}
            />
          </div>
        )}
        {/* This is used for the Earnings Info checkboxes since half them get disabled with one option selected the other dont. So I need to seperate the two
          and this seemed like the "cleanest" way to handle it...I am sorry */}
        {!!checkboxesSecondSet?.length && (
          <div>
            <CheckBoxForm
              key={title}
              formMethods={formMethods}
              dFields={checkboxesSecondSet ?? false}
              section={section}
              isSelectAll={false}
              disabled = {disableSecondSetCheckBox}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default GridSelect;