import { AxiosResponse } from "axios";
import { File, FileResponse, MultipleFileRequest } from '../models/File.model';
import { HttpResponse } from '../models/HttpResponse.model';
import { API_EMPLOYEE_MASTER } from './API';
import { EmployeeEndPoints, FileEndPoints } from './URL';
import { CommonService } from "./common.service";

const getFile = (filePath: string): Promise<AxiosResponse<FileResponse>> => {return API_EMPLOYEE_MASTER.get<FileResponse>(FileEndPoints.FILE(filePath));};

const postClientFileUpload = (
  file: File.FileUpload,
): Promise<AxiosResponse<HttpResponse<File.FileRecord>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<File.FileRecord>>(
    FileEndPoints.FILE_UPLOAD(),
    file,
  );
};

const putClientFile = (
  file: File.FileRecord,
): Promise<AxiosResponse<HttpResponse<File.FileRecord>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<File.FileRecord>>(
    FileEndPoints.FILE_ID(file.fileRecordId),
    file,
  );
};

const deleteClientFile = (
  fileRecordId: number,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    FileEndPoints.FILE_ID(fileRecordId),
  );
};

const getUserFiles = (
  displayType: string,
): Promise<AxiosResponse<File.UserFiles>> => {
  return API_EMPLOYEE_MASTER.get<File.UserFiles>(
    FileEndPoints.USER_FILES(displayType),
  );
};

const postFileFolder = (
  folder: File.ManageFolder,
): Promise<AxiosResponse<HttpResponse<File.ManageFolder>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<File.ManageFolder>>(
    FileEndPoints.FILE_FOLDER(),
    folder,
  );
};

const putFileFolder = (
  folder: File.ManageFolder,
): Promise<AxiosResponse<HttpResponse<File.ManageFolder>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<File.ManageFolder>>(
    FileEndPoints.FILE_FOLDER_ID(folder.fileCategoryId),
    folder,
  );
};

const deleteFileFolder = (
  fileCategoryId: number,
): Promise<AxiosResponse<HttpResponse<File.ManageFolder>>> => {
  return API_EMPLOYEE_MASTER.delete<HttpResponse<File.ManageFolder>>(
    FileEndPoints.FILE_FOLDER_ID(fileCategoryId),
  );
};

const putAssignFile = (
  file: File.AssignFile,
): Promise<AxiosResponse<HttpResponse<File.AssignFile>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<File.AssignFile>>(
    FileEndPoints.FILE_ASSIGN_FILE(),
    file,
  );
};

const moveClientH2R = (): Promise<AxiosResponse<HttpResponse<any>>> => {
   return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(EmployeeEndPoints.MOVE_CLIENT_H2R(CommonService.getProtectedClientNo())); 
};

const postDownloadMultipleFiles = (
  request: MultipleFileRequest,
): Promise<AxiosResponse<HttpResponse<FileResponse[]>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<FileResponse[]>>(
    FileEndPoints.DOWNLOAD_MULTIPLE_FILES(),
    request,
  );
};

export const FileService = {
  getFile,
  postClientFileUpload,
  putClientFile,
  deleteClientFile,
  getUserFiles,
  postFileFolder,
  putFileFolder,
  deleteFileFolder,
  putAssignFile,
  moveClientH2R,
  postDownloadMultipleFiles,
};
