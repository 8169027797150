import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  get401KOptions,
  getDeferredCompCatchUpMethod,
  getDeferredCompClassExclusion,
  getDivisionCodes,
  getSelectedEmp,
} from 'core/store/selectors';
import { FieldInputSettings } from 'core/components/form-controls/types';
import {
  ControlDatePickerGrp,
  InputGrp,
  RadioGrp,
  SelectGrp,
} from 'core/components/form-controls';
import { useForm } from 'react-hook-form';
import PanelHeader from 'core/components/shared/PanelHeader';
import { DeferredCompensation } from 'core/models/DeferredCompensation.model';
import {
  handleError,
  load401KOptions,
  loadDeferredCompensation,
  toggleBlockNavigation,
  updateDeferredCompensation,
} from 'core/store/actions';
import { getDeferredCompensation } from 'core/store/selectors/deferred-compensation.selector';
import { dateDiff, getAccess } from 'utilities/utilities';
import { useAppSelector, useRadioButtonChange } from 'utilities/hooks';

import { generateCheckDigit, validateRoutingNo } from 'core/helper/routingNumberValidator';
import CatchUpMethodNoticeModal from './CatchUpMethodNotice.modal';
import { UNSAVED_MESSAGE, ControlIds } from 'core/constants';

type RouteParams = {
  protectedEmpNo: string;
};

const DeferredCompPage: React.FC = () => {  
  const dispatch = useDispatch();

  const [eligible401KDateDisabled, setEligible401KDateDisabled] = useState(false);
  const [showCatchUpMsg, setShowCatchUpMsg] = useState<boolean>(false);
  const [ageInYears, setAgeInYears] = useState(0);
  const [fs, setFs] = useState<FieldInputSettings>({
    eligible401K: {
      name: 'eligible401K',
      label: '401K ELIGIBLE',
      groupClass: 'gc12 mw100',
    },
    eligible401KDate: {
      name: 'eligible401KDate',
      label: '401K ELIGIBLE DATE',
      groupClass: 'gc12 mw150',
    },
    eligibleDate401KMatch: {
      name: 'eligibleDate401KMatch',
      label: ['401K ELIGIBLE', 'MATCH DATE'],
      groupClass: 'gc12 mw150',
    },
    eligibleDate401kContribution: {
      name: 'eligibleDate401kContribution',
      label: ['401K ELIGIBLE', 'CONTR. DATE'],
      groupClass: 'gc12 mw150',
    },
    highlyCompEmp: {
      name: 'highlyCompEmp',
      label: 'HIGHLY CMP EMP',
      groupClass: 'gc12 mw100',
    },
    keyEmployee: {
      name: 'keyEmployee',
      label: 'KEY EMPLOYEE',
      groupClass: 'gc12 mw100',
    },
    unionIndicator: {
      name: 'unionIndicator',
      label: 'UNION INDICATOR',
      groupClass: 'gc12 mw100',
    },
    catchUpMethodId: {
      name: 'catchUpMethodId',
      label: 'CATCH UP METHOD',
      groupClass: 'gc40 mw300',
    },
    divisionId: {
      name: 'divisionId',
      label: 'EMPLOYEE DEFERRAL DIV',
      groupClass: 'gc20 mw200',
    },
    classExclusionId: {
      name: 'classExclusionId',
      label: 'CLASS EXCLUDED',
      groupClass: 'gc20 mw200',
    },
    eligibleCompanyCont: {
      name: 'eligibleCompanyCont',
      label: 'COMPANY CONTRIBUTION',
      groupClass: 'gc12 mw100',
    },
    contributionRoutingNo: {
      name: 'contributionRoutingNo',
      label: 'ROUTING NUMBER',
      groupClass: 'gc15 mw150',
    },
    contributionAccountNo: {
      name: 'contributionAccountNo',
      label: 'BANK ACCOUNT NUMBER',
      groupClass: 'gc15 mw150',
    },
    eligibleCompanyMatch: {
      name: 'eligibleCompanyMatch',
      label: 'COMPANY MATCH',
      groupClass: 'gc12 mw100',
    },
    matchRoutingNo: {
      name: 'matchRoutingNo',
      label: 'ROUTING NUMBER',
      groupClass: 'gc15 mw150',
    },
    matchAccountNo: {
      name: 'matchAccountNo',
      label: 'BANK ACCOUNT NUMBER',
      groupClass: 'gc15 mw150',
    },
  });

  const [handleRadioButtonChange] = useRadioButtonChange(fs);

  const { protectedEmpNo } = useParams<RouteParams>();

  const clientNo = useAppSelector((state) => { return state?.client?.client?.clientNo; });
  const k401Options = useSelector(get401KOptions);
  const loading401kOpts = useAppSelector(({ client }) => client.loading);
  const loadingDeferredComp = useAppSelector((state) => state.selEmployeeDetails.deferredComp.loading);
  const emp = useSelector(getSelectedEmp);
  const deferredComp = useSelector(getDeferredCompensation);
  const deferredCompCatchUpMethodOpts = useSelector(
    getDeferredCompCatchUpMethod,
  );
  const deferredCompClassExclusionOpts = useSelector(
    getDeferredCompClassExclusion,
  );
  const divisionCodesOpts = useSelector(getDivisionCodes);
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  
  const {
    setValue,
    clearErrors,
    errors,
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState,
  } = useForm<DeferredCompensation>({
    defaultValues: new DeferredCompensation(0, 0, ''),
  });

  const { isDirty, dirtyFields } = formState;

  const {
    eligible401K,
    eligible401KDate,
    eligibleDate401KMatch,
    eligibleDate401kContribution,
  } = watch([
    'eligible401K',
    'eligible401KDate',
    'eligibleDate401KMatch',
    'eligibleDate401kContribution',
  ]);

  useEffect(() => {
    dispatch(load401KOptions());
  }, []);

  useEffect(() => {
    if (!protectedEmpNo) return;
    dispatch(loadDeferredCompensation(protectedEmpNo));
  }, [protectedEmpNo]);

  useEffect(() => {
    if (emp) {
      const currYear = new Date().getFullYear();
      // Only care if emp is going to turn 50 by year end.
      const x = dateDiff(emp.birthDate, new Date(currYear, 11, 31));
      setAgeInYears(x.years ?? 0);
    }
  }, [emp]);

  useEffect(() => {
    if (deferredComp) {
      reset(deferredComp);
    }

    setEligible401KDateDisabled(
      deferredComp.eligible401K === 'false' ? true : false,
    );
    const currentState = { ...fs };
    currentState.eligible401K.defaultChecked = deferredComp.eligible401K;
    currentState.highlyCompEmp.defaultChecked = deferredComp.highlyCompEmp;
    currentState.keyEmployee.defaultChecked = deferredComp.keyEmployee;
    currentState.unionIndicator.defaultChecked = deferredComp.unionIndicator;
    currentState.eligibleCompanyCont.defaultChecked = deferredComp.eligibleCompanyCont;
    currentState.eligibleCompanyMatch.defaultChecked = deferredComp.eligibleCompanyMatch;
    
    setFs(currentState);
  }, [deferredComp]);
  
  useEffect(() => {
    setShowCatchUpMsg(false);
    if (!(ageInYears >= 49 && k401Options?.showCatchUpPrompt && String(deferredComp?.qualifiedForCatchUp) === 'true') || loading401kOpts || loadingDeferredComp) return;
    setShowCatchUpMsg(true);
    setValue('catchUpMethodId', null);
  }, [k401Options, deferredComp, ageInYears, loading401kOpts, loadingDeferredComp, eligible401K]);

  const onEligible401KDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (String(e.target.value) === 'true') {
      setValue('eligible401KDate', new Date());
      setEligible401KDateDisabled(false);
    } else {
      setValue('eligible401KDate', null);
      setEligible401KDateDisabled(true);
    }
    handleRadioButtonChange(e);
  };

  const onSave = (data: DeferredCompensation) => {
    clearErrors();

    if (!clientNo) return dispatch(handleError('Error with client number'));
    if (data.contributionRoutingNo?.length === 8) {
      const routingNo = data.contributionRoutingNo + generateCheckDigit(data.contributionRoutingNo);
      data.contributionRoutingNo = routingNo;
    }
    if (data.matchRoutingNo?.length === 8) {
      const routingNo = data.matchRoutingNo + generateCheckDigit(data.matchRoutingNo);
      data.matchRoutingNo = routingNo;
    }
    const newData = new DeferredCompensation(
      clientNo,
      emp?.empNo ?? 0,
      protectedEmpNo,
      data,
    );

    if (!newData.eligible401K) {
      (newData.eligible401KDate = null);
    }

    newData.catchUpMethodId = ageInYears < 49 ? null : newData.catchUpMethodId;

    dispatch(updateDeferredCompensation(newData));
  };

  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: Object.keys(dirtyFields).length > 0,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [Object.keys(dirtyFields).length > 0]);
  
  return (
    <div className="dm-panel dm-panel-border">
      {showCatchUpMsg && (
        <CatchUpMethodNoticeModal
          show={showCatchUpMsg}
          onHide={() => {
            setShowCatchUpMsg(false);
          }}
          setValue={setValue}
        />
      )}
      <PanelHeader title="Deferred Compensation" />
      <form onSubmit={handleSubmit(onSave)}>
        <div className="d-flex flex-wrap mt-3">
          <RadioGrp
            {...fs.eligible401K}
            {...getAccess(sectionAccess, 30, undefined, { disabledSameAsReadOnly: true })}
            errors={errors.eligible401K}
            onChange={onEligible401KDate}
            ref={register}
          />
          <ControlDatePickerGrp
            {...fs.eligible401KDate}
            {...getAccess(sectionAccess, 30, ControlIds.eligible401KDate)}
            errors={errors.eligible401KDate}
            value={eligible401KDate}
            disabled={eligible401KDateDisabled}
            setValue={setValue}
            control={control}
          />
          <ControlDatePickerGrp
            {...fs.eligibleDate401KMatch}
            {...getAccess(sectionAccess, 30, ControlIds.eligibleDate401KMatch)}
            errors={errors.eligibleDate401KMatch}
            value={eligibleDate401KMatch}
            setValue={setValue}
            control={control}
          />
          <ControlDatePickerGrp
            {...fs.eligibleDate401kContribution}
            {...getAccess(sectionAccess, 30, fs.eligibleDate401KMatch.controlId)}
            errors={errors.eligibleDate401KMatch}
            value={eligibleDate401kContribution}
            setValue={setValue}
            control={control}
          />
          <RadioGrp
            {...fs.highlyCompEmp}
            {...getAccess(sectionAccess, 30, undefined, { disabledSameAsReadOnly: true })}
            errors={errors.highlyCompEmp}
            ref={register}
            onChange={handleRadioButtonChange}
            name="highlyCompEmp"
          />
          <RadioGrp
            {...fs.keyEmployee}
            {...getAccess(sectionAccess, 30, undefined, { disabledSameAsReadOnly: true })}
            errors={errors.keyEmployee}
            ref={register}
            onChange={handleRadioButtonChange}
            name="keyEmployee"
          />
          <RadioGrp
            {...fs.unionIndicator}
            {...getAccess(sectionAccess, 30, undefined, { disabledSameAsReadOnly: true })}
            errors={errors.unionIndicator}
            ref={register}
            onChange={handleRadioButtonChange}
            name="unionIndicator"
          />
          <SelectGrp
            {...fs.catchUpMethodId}
            {...getAccess(sectionAccess, 30)}
            errors={errors.catchUpMethodId}
            ref={register({
              validate: (value) => value == '' || (value != '' && ageInYears >= 49),
            })}
            errorMsg="This employee is not eligible to have a Catch-Up. They must be turning 50 years of age during this calendar year."
            options={deferredCompCatchUpMethodOpts}
            addEmptyValue={ageInYears < 49}
            name="catchUpMethodId"
            defaultValue={deferredComp?.catchUpMethodId}
          />
          <SelectGrp
            {...fs.divisionId}
            {...getAccess(sectionAccess, 30, ControlIds.divisionId)}
            errors={errors.divisionId}
            ref={register}
            options={divisionCodesOpts}
          />
          <SelectGrp
            {...fs.classExclusionId}
            {...getAccess(sectionAccess, 30)}
            errors={errors.classExclusionId}
            ref={register}
            options={deferredCompClassExclusionOpts}
          />
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="dm-panel-hr" />
          </div>
        </div>
        <div className="d-flex flex-wrap mt-3">
          <RadioGrp
            {...fs.eligibleCompanyCont}
            {...getAccess(sectionAccess, 30, undefined, { disabledSameAsReadOnly: true, disabledDefault: !k401Options?.hasContribution })}
            errors={errors.eligibleCompanyCont}
            onChange={handleRadioButtonChange}
            ref={register}
            name="eligibleCompanyCont"
          />

          <InputGrp
            {...fs.contributionRoutingNo}
            {...getAccess(sectionAccess, 30, undefined, { disabledDefault: !k401Options?.hasContribution })}
            errors={errors.contributionRoutingNo}
            style={{ backgroundColor: !k401Options?.hasContribution ? '#e9ecef' : '#fff' }}
            type={'text'}
            maxLength={9}
            ref={register({
              validate: (value: string) => { return validateRoutingNo(value); },
            })}
          />

          <InputGrp
            {...fs.contributionAccountNo}
            {...getAccess(sectionAccess, 30, undefined, { disabledDefault: !k401Options?.hasContribution })}
            errors={errors.contributionAccountNo}
            style={{
              backgroundColor: !k401Options?.hasContribution
                ? '#e9ecef'
                : '#fff',
            }}
            ref={register}
          />
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="dm-panel-hr" />
          </div>
        </div>
        <div className="d-flex flex-wrap mt-3">
          <RadioGrp
            {...fs.eligibleCompanyMatch}
            {...getAccess(sectionAccess, 30, undefined, { disabledSameAsReadOnly: true, disabledDefault: !k401Options?.hasMatch })}
            errors={errors.eligibleCompanyMatch}
            onChange={handleRadioButtonChange}
            ref={register}
            name="eligibleCompanyMatch"
          />
          <InputGrp
            {...fs.matchRoutingNo}
            {...getAccess(sectionAccess, 30, undefined, { disabledDefault: !k401Options?.hasMatch })}
            errors={errors.matchRoutingNo}
            style={{ backgroundColor: !k401Options?.hasMatch ? '#e9ecef' : '#fff' }}
            type={'text'}
            maxLength={9}
            ref={register({ validate: (value: string) => {return validateRoutingNo(value);} })}
          />
          <InputGrp
            {...fs.matchAccountNo}
            {...getAccess(sectionAccess, 30, undefined, { disabledDefault: !k401Options?.hasMatch })}
            errors={errors.matchAccountNo}
            style={{
              backgroundColor: !k401Options?.hasMatch
                ? '#e9ecef'
                : '#fff',
            }}
            ref={register}
          />
        </div>
        <div className="row mt-2">
          <div className="col-12 text-right">
            <button
              {...getAccess(sectionAccess, 30, undefined, { disabledSameAsReadOnly: true, disabledDefault: !isDirty })}
              type="submit"
              className="orange-button ml-2"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DeferredCompPage;
