import React from 'react';
import { Labels } from '.';
import styles from './form-controls.module.scss';

interface PropTypes {
  id?: string;
  name: string;
  label?: string;
  tallLabel?: boolean;
  groupClass?: string;
  groupStyle?: object;
  placeholder?: string;
  rows?: number,
  errors?: any;
  required?: boolean;
  onChange?: (e: any) => void;
}

export const TextareaGrp: React.FC<any> = React.forwardRef(({
  id,
  name,
  label,
  tallLabel = false,
  groupClass,
  groupStyle,
  rows = 4,
  errors,
  required = false,
  onChange, ...extraProps }: PropTypes, ref: any) => {

  const groupClass2 = styles['dm-form-group'] + ' ' + (groupClass ?? '');
  id = id ?? name;

  return (
    <div className={groupClass2} style={groupStyle}>
      <Labels label={label} tallLabel={tallLabel} id={id} hasError={!!errors} required={required} />
      <textarea
        className={styles['dm-textarea-control'] + (errors ? ' ' + styles['dm-is-invalid'] : '')}
        ref={ref}
        id={id}
        name={name}
        onChange={onChange}
        {...extraProps}
        rows={rows} />
      <small className="text-danger">{errors?.message} </small>
    </div>
  );
});
