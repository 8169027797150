import React, { createRef, useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { getNextEmpNo, getTransfers, getTransferStatus } from 'core/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import RelatedClients from './RelatedClients';
import EmpList from './EmpList';
import { employeeTransferClearStatus, executeEmployeeTransfer, loadEmployeeTransfer, loadEmployeeTransferValidate, loadNextEmployeeNumber } from '../../../store/actions';
import { Client, Employee, EmployeeToTransfer } from 'core/models';
import Modal from '../Modal';
import Icon from 'core/components/shared/Icon';

const TransferSuccess: React.FC<any> = ({ title, message, show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      title={title}
    >
      {(closeModal) => {
        return (
          <>
            <div>{message}</div>
            <div className="row mt-4">
              <div className="col-12 text-right">
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button"
                  onClick={closeModal}
                >Continue</button>
              </div>
            </div>
          </>
        );
      }}
    </Modal>
  );
};

type PropTypes = {
  show: boolean;
  onHide: () => void;
};

const EmpTransferModal: React.FC<PropTypes> = ({ show, onHide }) => {
  const dispatch = useDispatch();

  const [selectedEmp, setSelectedEmp] = useState<Employee | null>(null);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [selectedEmpNo, setSelectedEmpNo] = useState('');
  const [empNoType, setEmpNoType] = useState('1');
  const [validated, setValidated] = useState(false);

  const [statusMsg, setStatusMsg] = useState<any>('');
  const [statusIndicator, setStatusIndicator] = useState<any>('');
  const [showTransferSuccess, setShowTransferSuccess] = useState(false);
  const [showTransferFail, setShowTransferFail] = useState(false);

  const empListRef: React.RefObject<any> = createRef();
  const relatedClientsRef: React.RefObject<any> = createRef();

  const storedTransfers = useSelector(getTransfers);
  const nextEmpNo = useSelector(getNextEmpNo);
  const transferStatus = useSelector(getTransferStatus);

  useEffect(() => {
    dispatch(loadEmployeeTransfer());
    setStatusMsg('');
    setStatusIndicator('');
  }, []);

  useEffect(() => {
    setSelectedEmpNo('' + nextEmpNo);
  }, [nextEmpNo]);

  useEffect(() => {
    //Sets the status message for inside the box
    if (storedTransfers?.validateResponse) {
      setStatusMsg(`${storedTransfers?.validateResponse?.messages[0]}`);
    }
    else{
      setStatusMsg('');
      setStatusIndicator('');
    }

    //Sets the indicator header that is by the status title.
    if (storedTransfers?.validateResponse?.statusCode === 'OK') {
      setStatusIndicator('- (Validation Ok)');
      setValidated(true);
    }
    else if(storedTransfers?.validateResponse?.statusCode === 'BadRequest'){
      setStatusIndicator('- (Validation Failed: See Errors)');
    }
  }, [storedTransfers]);

  useEffect(() => {
    if (transferStatus === 'success') {
      setShowTransferSuccess(true);
    } else if (transferStatus === 'fail') {
      setShowTransferFail(true);
    }
    dispatch(employeeTransferClearStatus());
  }, [transferStatus]);

  const onValidate = () => {
    let empNo;
    if (empNoType === '1') {
      empNo = selectedEmp?.empNo;
    } else {
      empNo = +selectedEmpNo;
    }

    dispatch(loadEmployeeTransferValidate(new EmployeeToTransfer(
      // @ts-ignore
      selectedClient?.clientNo,
      empNo,
      selectedEmp?.clientNo,
      selectedEmp?.empNo,
      selectedEmp?.empId,
    )));
  };

  const onReset = () => {
    empListRef.current.api.deselectAll();
    relatedClientsRef.current.api.deselectAll();
    setSelectedEmp(null);
    setSelectedClient(null);
    setSelectedEmpNo('');
    setEmpNoType('1');
    setValidated(false);
    dispatch(employeeTransferClearStatus());
  };

  const empNoTypeHandler = (e: any) => {
    setEmpNoType(e.target.value);
    if (e.target.value === '1') {
      setSelectedEmpNo('' + selectedEmp?.empNo);
    } else if (e.target.value === '3') {
      dispatch(loadNextEmployeeNumber());
      setSelectedEmpNo('');
    }
  };

  const onCompleteTransfer = () => {
    if (!(selectedClient && selectedEmp)) return console.error('No selected client and/or employee');

    let empNo;
    if (empNoType === '1') {
      empNo = selectedEmp?.empNo;
    } else {
      empNo = +selectedEmpNo;
    }

     dispatch(executeEmployeeTransfer(new EmployeeToTransfer(
       selectedClient?.clientNo,
       empNo,
       selectedEmp?.clientNo,
       selectedEmp?.empNo,
       selectedEmp?.empId,
     )));
    onReset();
  };

  const onHideOverride = () => {
    onReset();
    onHide();
  }

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHideOverride}
        size="lg"
        title="Employee Transfer"
      >
        <Row>
          <Col
            xs={6}
            className="pr-0"
          >
            <EmpList
              employees={storedTransfers?.employees}
              setSelectedEmp={setSelectedEmp}
              ref={empListRef}
            />
          </Col>
          <Col
            xs={1}
            className="d-flex justify-content-center align-self-center"
          >
            <Icon
              name="arrow-alt-circle-right"
              color="#00558c"
              style={{
                height: '40px',
                width: 'auto',
              }}
            />
          </Col>
          <Col
            xs={5}
            className="pl-0"
          >
            <RelatedClients
              clients={storedTransfers?.relatedClients}
              setSelectedClient={setSelectedClient}
              ref={relatedClientsRef}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={3}
            className="pr-0"
          >
            <div className="form-group">
              <label
                className="form-label"
                htmlFor="selectedEmp"
              >SELECTED EMPLOYEE</label>
              <input
                type="text"
                className="form-control"
                name="selectedEmp"
                defaultValue={selectedEmp ? selectedEmp.firstName + ' ' + selectedEmp.lastName : ''}
                readOnly
              />
            </div>
          </Col>
          <Col
            xs={3}
            className="pr-0"
          >
            <div className="form-group">
              <label className="form-label">&nbsp;</label>
              <select
                className="form-control"
                name="empNoType"
                value={empNoType}
                onChange={empNoTypeHandler}
              >
                <option value="1">Use current Emp No.</option>
                <option value="2">Enter new Emp No.</option>
                <option value="3">Generate new Emp No.</option>
              </select>
            </div>
          </Col>
          <Col xs={1}></Col>
          <Col
            xs={5}
            className="pl-0"
          >
            <div className="form-group">
              <label
                className="form-label"
                htmlFor="selectedClient"
              >SELECTED CLIENT</label>
              <input
                type="text"
                className="form-control"
                name="selectedClient"
                defaultValue={selectedClient?.clientName}
                readOnly
              />
            </div>
          </Col>
        </Row>
        <div className="row">
          <div className="col-3">
            {empNoType === '2' && <div className="form-group">
              <label
                className="form-label"
                htmlFor="selectedEmpNo"
              >SELECTED EMP NO.</label>
              <input
                type="text"
                className="form-control"
                name="selectedEmpNo"
                defaultValue={selectedEmpNo}
                onChange={(e: any) => { return setSelectedEmpNo(e.target.value); }}
              />
            </div>}
          </div>
          <div className="col-9 text-right">
            <Button
              className="orange-outline-button"
              type="button"
              onClick={onReset}
            >Reset</Button>
            <Button
              type="button"
              className="orange-button ml-2"
              disabled={!selectedEmp || !selectedClient || (empNoType !== '1' && !selectedEmpNo)}
              onClick={onValidate}
            >
              Validate Transfer
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <div className="dm-grid-title">Status {statusIndicator}</div>
          </div>
          <div className="col-7 d-flex justify-content-end py-2">
            <button
              type="button"
              className="btn btn-primary orange-button"
              disabled={!validated}
              onClick={onCompleteTransfer}
            >
              Complete Transfer
            </button>
          </div>
          <div className="col-12">
            <div className="form-group">
              <textarea
                name="statusArea"
                className="form-control"
                defaultValue={statusMsg}
                rows={5}
                readOnly
              />
            </div>
          </div>
        </div>
      </Modal>
      {
        showTransferSuccess && <TransferSuccess
          title="TRANSFER SUCCESSFUL"
          message="Employee has been successfully transferred."
          show={showTransferSuccess}
          onHide={() => { return setShowTransferSuccess(false); }}
        />
      }
      {
        showTransferFail && <TransferSuccess
          title="TRANSFER FAILED"
          message="Unable to transfer Employee, an unexpected error has occured."
          show={showTransferSuccess}
          onHide={() => { return setShowTransferFail(false); }}
        />
      }
    </div >
  );
};

export default EmpTransferModal;
