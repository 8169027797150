import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';
import { ColDef, GridApi, GridOptions, RowSelectedEvent } from '@ag-grid-enterprise/all-modules';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'utilities/hooks';


type PropTypes = {
  codes: string[];
  passDataFromComissionsGrid: (codes: string[], modified: boolean) => void
};

const ComissionsGrid: React.FC<PropTypes> = ({ codes, passDataFromComissionsGrid }) => {

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [changed, setChanged] = useState(false);
  const earningCodes = useAppSelector((state) => {return state.dropdown.earningsCode;});

  const columns: ColDef[] = [
    {
      field: 'select',
      headerName: '',
      editable: false,
      checkboxSelection: true,
      width: 40,
    },
    {
      field: 'id',
      headerName: 'Code',
      width: 60,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 180,
    },
  ];

  const comissionGridOpts: GridOptions = {
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    columnDefs: columns,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (codes && gridApi) {
      gridApi?.forEachNode((node) => {
        if (codes.includes('' + node.data.id)) {
          node.setSelected(true);
        }
      });
    }
  }, [codes, gridApi]);


  const onRowClicked = (e: RowSelectedEvent) => {
    e && e.node.setSelected(e.node.isSelected() === true ? true : false);
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      setChanged(selectedRows.length ? true : false);
      passDataFromComissionsGrid(selectedRows.map(r => {return r.id;}), changed);
    }
  };

  return (
    <AgGridReact
      gridOptions={comissionGridOpts}
      rowData={earningCodes}
      onGridReady={onGridReady}
      onRowClicked={onRowClicked}
      onSelectionChanged={onSelectionChanged}
    />
  );
};

export default ComissionsGrid;