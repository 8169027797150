import { AxiosResponse } from "axios";
import { HttpResponse } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { EmployeeEndPoints } from './URL';
import { CommonService } from './common.service';
import { Sso } from '../models/Sso.model';

const PostSso = (): Promise<AxiosResponse<HttpResponse<Sso>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<Sso>>(
    EmployeeEndPoints.SSO(CommonService.getProtectedClientNo()),
  );
};

export const SsoService = {
  PostSso,
};
