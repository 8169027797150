import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonService } from 'core/services';
import { Files } from 'enums/Files';
import { downloadReportThunk } from '../actions/download-report-file.action';
import { DownloaderResponse } from 'core/models/Downloader.model';

type FileTypes = Files.EXCEL | Files.PDF | Files.CSV | Files.ALL | Files.XLS;

export interface ReportsDownloaderState {
  isDownloading: boolean;
  exporting: boolean;
  exportingFulfilled: boolean;
  fileName?: string;
  fileType: FileTypes;
}

const initialState: ReportsDownloaderState = {
  isDownloading: false,
  exporting: false,
  exportingFulfilled: false,
  fileType: Files.EXCEL,
  fileName: '',
};

export const downloadReportFileSlice = createSlice({
  name: 'report-downloader',
  initialState,
  reducers: {
    setReportFileName(state: ReportsDownloaderState, action: PayloadAction<string>) {
      state.fileName = action.payload;
    },
    clearReportFile(state: ReportsDownloaderState) {
      state.fileName = '';
      state.isDownloading = false;
    },
    setReportFileType(state: ReportsDownloaderState, action: PayloadAction<FileTypes>) {
      state.fileType = action.payload;
    },
    resetExportState(state: ReportsDownloaderState, action: PayloadAction<boolean>) {
      state.exportingFulfilled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadReportThunk.pending, (state) => {
        state.isDownloading = true;
        state.exporting = true;
        state.exportingFulfilled = false;
      })
      //TODO CLEAN THIS UP
      .addCase(downloadReportThunk.fulfilled, (state, action) => {
        //If it has file Info follow the following flow
        if (action?.payload?.fileInfos) {
          const downloaderResponse = action.payload as DownloaderResponse; 

          downloaderResponse.fileInfos.forEach((file) => {
            let fileType = '';
            if (file.fileType === '.csv') fileType = 'application/csv';
            else if (file.fileType == '.pdf') fileType = 'application/pdf';
            else fileType = 'application/excel';

            CommonService.downloadBase64File(
              fileType,
              file.fileData,
              `${state.fileName}${file.fileType}`,
            );
          });
        }

        if (action?.payload && !action?.payload?.fileInfos) {
          let fileExt = '.xlsx';
          let fileType = 'application/excel';

          if (state.fileType === Files.CSV) {
            fileExt = '.csv';
            fileType = 'application/csv';
          }

          if (state.fileType === Files.PDF) {
            fileExt = '.pdf';
            fileType = 'application/pdf';
          }

          CommonService.downloadBase64File(
            fileType,
            action.payload?.reportData || action.payload,
            `${state.fileName}${fileExt}`,
          );
        }

        state.exportingFulfilled = true;
        state.exporting = false;
        state.isDownloading = false;
      })
      .addCase(downloadReportThunk.rejected, (state) => {
        state.isDownloading = false;
        state.exporting = false;
        state.exportingFulfilled = false;
        // create error notification
      });
  },
});

export const { setReportFileName, clearReportFile, setReportFileType, resetExportState } = downloadReportFileSlice.actions;

export default downloadReportFileSlice.reducer;