import { createReducer } from '@reduxjs/toolkit';
import { PayrollCheck, CheckCalculator } from '../../models';
import {
  loadCheckCalculator,
  calculateCheck,
  storeCheckCalculator,
  storeCheckCalculation,
  loadCalculateCheckReport,
  loadCalculateCheckPrint,
  calculateCheckPrintVerify,
  storeCalculateCheckPdf,
  clearCalculateCheckPdf,
  showAdjustmentModal,
  toggleCalculateFlag,
} from '../actions';

export interface State {
  loading: boolean;
  calculating: boolean;
  checkCalculator: CheckCalculator | null;
  checkCalculation: PayrollCheck | null;
  pdf: string;
  pdfType: string;
  showAdjustmentModal: boolean;
}

export const INITIAL_STATE: State = {
  loading: false,
  calculating: false,
  checkCalculator: null,
  checkCalculation: null,
  pdf: '',
  pdfType: '',
  showAdjustmentModal: false,
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadCheckCalculator, (state) => {
      state.loading = true;
    })
    .addCase(storeCheckCalculator, (state, action) => {
      state.loading = false;
      state.checkCalculator = action.payload;
    })
    .addCase(calculateCheck, (state) => {
      state.loading = true;
      state.calculating = true;
    })
    .addCase(storeCheckCalculation, (state, action) => {
      state.loading = false;
      state.calculating = false;
      state.checkCalculation = action.payload;
    })
    .addCase(loadCalculateCheckReport, (state) => {
      state.loading = true;
    })
    .addCase(loadCalculateCheckPrint, (state) => {
      state.loading = true;
    })
    .addCase(calculateCheckPrintVerify, (state) => {
      state.loading = true;
    })
    .addCase(storeCalculateCheckPdf, (state, action) => {
      state.loading = false;
      state.pdf = action.payload.pdf;
      state.pdfType = action.payload.pdfType;
    })
    .addCase(clearCalculateCheckPdf, (state) => {
      state.pdf = '';
      state.pdfType = '';
    })
    .addCase(showAdjustmentModal, (state, action) => {
      state.showAdjustmentModal = action.payload;
    })
    .addCase(toggleCalculateFlag, (state, action) => {
      state.calculating = action.payload;
    });
});
