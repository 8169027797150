import { ICellRendererParams } from '@ag-grid-community/core';

export function agDateRenderer(): any {}

agDateRenderer.prototype.init = function (params: ICellRendererParams) {
  this.dateDiv = document.createElement('div');
  this.dateDiv.innerHTML = params.value ? this.formatDate(params.value) : '';
};

agDateRenderer.prototype.formatDate = function (date: Date | string): string {
  let d;
  if (typeof date === 'string') {
    const i = date.indexOf('T');
    if (i >= 0) {
      // Save the time portion of date.
      this.timezone = date.substring(i);
      d = new Date(date);
    } else {
      d = new Date(this.timezone ? date + this.timezone : date);
    }
  } else {
    d = date;
  }
  
  // adds leading 0 regardless, then takes last two chars (so 011 --> 11 and 09 --> 09)
  return ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2) + '/' + d.getFullYear();
};

agDateRenderer.prototype.getGui = function (_params: ICellRendererParams) {
  return this.dateDiv;
};

agDateRenderer.prototype.refresh = function (params: ICellRendererParams) {
  this.dateDiv.innerHTML = params.value ? this.formatDate(params.value) : '';
  return true;
};

agDateRenderer.prototype.destroy = function () {};
