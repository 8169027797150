import Modal from 'core/components/modals/Modal';
import { sendApplicantOfferEmail } from 'core/store/actions/applicant-tracking.action';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const fileInputStyle: CSSProperties = {
  visibility: 'hidden',
  opacity: 0,
  width: 0,
  height: 0,
};

const SendOfferModal = () => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [selectLetterModal, setSelectLetterModal] = useState(false);
  const [fileSelectedModal, setFileSelectedModal] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { active, offer } = useAppSelector((state) => {
    return state.applicantTrackingV2;
  });

  const onHide = () => {
    setIsModalShown(false);
  };

  const appDispatch = useAppDispatch();

  const onSend = async () => {
    await appDispatch(
      sendApplicantOfferEmail({
        applicantId: active?.applicantId || 0,
        params: {
          applicantId: active?.applicantId,
          uploadNewFile: false,
          resendEmail: true,
        },
      }),
    );
  };

  const initiateFirstTimeOfferSendFlow = () => {
    setSelectLetterModal(true);
  };

  const sendOffer = () => {
    if (offer?.offerSheetSentDate?.length) {
      setIsModalShown(true);
    } else {
      initiateFirstTimeOfferSendFlow();
    }
  };

  const chooseFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      setFileSelectedModal(true);
    } else {
      setFileSelectedModal(false);
    }
  }, [selectedFile]);

  const processAndSendOffer = () => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result as string;
        appDispatch(
          sendApplicantOfferEmail({
            applicantId: active?.applicantId || 0,
            params: {
              applicantId: active?.applicantId,
              data: base64String.split(',')[1],
              extension: '.' + selectedFile?.name?.split('.')?.[1],
              uploadNewFile: true,
              resendEmail: true,
            },
          }),
        ).then(() => {
          setFileSelectedModal(false);
          setSelectedFile(null);
        }).catch((error) => {
          setFileSelectedModal(false);
          setSelectedFile(null);
        });
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  return (
    <div className="d-flex justify-content-end mb-2">
      <button
        type="button"
        className="orange-button mr-2"
        onClick={() => {
          sendOffer();
        }}
      >
        Send Offer
      </button>
      <input
        type="file"
        style={fileInputStyle}
        ref={inputRef}
        onChange={(e) => {
          chooseFile(e);
        }}
      />
      <Modal
        centered
        show={fileSelectedModal}
        onHide={() => {
          return setFileSelectedModal(false);
        }}
        title="Send Offer Letter"
      >
        <p>
          You&apos;ve selected `{selectedFile?.name}` to send to the applicant.
          Are you sure you would like to use this offer letter?
        </p>
        <div className="d-flex align-items-center justify-content-end">
          <button
            type="button"
            className="btn btn-primary orange-outline-button mr-2 mt-2"
            onClick={() => {
              setFileSelectedModal(false);
              setSelectedFile(null);
            }}
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-primary orange-button mr-2 mt-2"
            onClick={async () => {
              setFileSelectedModal(false);
              await processAndSendOffer();
            }}
          >
            Yes
          </button>
        </div>
      </Modal>
      <Modal
        centered
        show={selectLetterModal}
        onHide={() => {
          return setSelectLetterModal(false);
        }}
        title="Send Offer Letter"
      >
        <p>
          Next, you will select the offer letter that will be sent to the
          applicant.
        </p>
        <div className="d-flex align-items-center justify-content-end">
          <button
            type="button"
            className="btn btn-primary orange-outline-button mr-2 mt-2"
            onClick={() => {
              inputRef.current?.click();
              return setSelectLetterModal(false);
            }}
          >
            OK
          </button>
        </div>
      </Modal>
      <Modal
        centered
        show={isModalShown}
        onHide={onHide}
        title="Send Offer"
      >
        <p>
          An offer sheet already exists for this applicant. Would you like
          to resend the email using the same sheet?
        </p>
        <div className="d-flex align-items-center justify-content-end">
          <button
            type="button"
            className="btn btn-primary orange-outline-button mr-2 mt-2"
            onClick={() => {
              setSelectLetterModal(true);
              onHide();
            }}
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-primary orange-button mr-2 mt-2"
            onClick={() => {
              onHide();
              onSend();
            }}
          >
            Yes
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default SendOfferModal;
