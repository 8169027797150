import { ColDef, GridOptions, RowEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  agCheckboxRenderer,
  agDateRenderer,
  agLookupRenderer,
  agSSNRenderer,
} from 'utilities/ag-grid-renderers';
import { Employee } from 'core/models';
import { getDepartments } from 'core/store/selectors';

type Props = {
  url: string;
  onHide: () => void;
  employees: Employee[];
};

const EmployeeSearchGridResults: React.FC<Props> = ({
  url,
  employees,
  onHide,
}) => {
  const deptOpts = useSelector(getDepartments);
  const history = useHistory();
  const colDefs = useMemo<ColDef[]>(
    () => {
      return [
        {
          field: 'empNo',
          headerName: 'Emp #',
          sortable: true,
          width: 70,
        },
        {
          field: 'ssn',
          headerName: 'SSN',
          cellRenderer: 'ssnRenderer',
          sortable: true,
          suppressMenu: false,
          width: 110,
        },
        {
          field: 'firstName',
          headerName: 'First Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          suppressMenu: false,
          width: 110,
        },
        {
          field: 'midName',
          headerName: 'MI',
          sortable: true,
          filter: 'agTextColumnFilter',
          suppressMenu: false,
          width: 60,
        },
        {
          field: 'lastName',
          headerName: 'Last Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          suppressMenu: false,
          width: 110,
        },
        {
          field: 'loc',
          headerName: 'Loc',
          width: 50,
        },
        {
          field: 'dept',
          headerName: 'Dept',
          sortable: true,
          cellRendererParams: {
            options: deptOpts,
            labelField: 'deptDesc',
            valueField: 'deptCode',
            showId: true,
          },
          cellRenderer: 'lookupRenderer',
          width: 100,
        },
        {
          field: 'hireDate',
          headerName: 'Hire Date',
          cellRenderer: 'dateRenderer',
          cellEditor: 'dateEditor',
          width: 90,
        },
        {
          field: 'termDate',
          headerName: 'Term Date',
          cellRenderer: 'dateRenderer',
          cellEditor: 'dateEditor',
          width: 90,
        },
        {
          field: 'isSupervisor',
          headerName: 'Super',
          cellRenderer: 'checkboxRenderer',
          cellEditor: 'checkboxEditor',
          width: 70,
        },
        {
          field: 'onboardingStatus',
          headerName: 'Onboarding Status',
          width: 150,
        },
      ];
    },
    [],
  );

  const gridOptions = useMemo<GridOptions>(
    () => {
      return {
        defaultColDef: {
          suppressMenu: true,
          resizable: true,
          singleClickEdit: true,
          cellClass: 'ag-cell-left-border',
          headerClass: 'grid-header',
        },
        components: {
          checkboxRenderer: agCheckboxRenderer,
          dateRenderer: agDateRenderer,
          lookupRenderer: agLookupRenderer,
          ssnRenderer: agSSNRenderer,
        },
        overlayLoadingTemplate:
                '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
        overlayNoRowsTemplate:
                "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;\">No row data' overlay</span>",
      };
    },
    [],
  );

  const onEmployeeSelected = (e: RowEvent) => {
    const urlReplace = url.replace(
      /:protectedEmpNo/gi,
      e.data.protectedEmpNo,
    );
    history.push(urlReplace);
    onHide();
  };

  return (
    <div className="ag-theme-balham">
      <AgGridReact
        columnDefs={colDefs}
        gridOptions={gridOptions}
        rowData={employees}
        onRowClicked={onEmployeeSelected}
      />
    </div>
  );
};

export default EmployeeSearchGridResults;
