import { createReducer } from '@reduxjs/toolkit';
import { GeneralLedgerExportResult, GeneralLedgerReport, OverTimeSetting } from '../../models/AccountingReports.model';

import {
  clearBankDisbursementsReport,
  clearEmployeeAverageHoursReport,
  clearHoursAndEarningsReport,
  storeBankDisbursementsReport,
  storeEmployeeAverageHoursReport,
  storeHoursAndEarningsReport,
  storeOverTimeSettings,
  clearNewJobTrainingCreditReport,
  storeNewJobTrainingCreditReport,
  clearEmployeeAverageHoursWithStatusReport,
  storeEmployeeAverageHoursWithStatusReport,
  storeGeneralLedgerReport,
  clearGeneralLedgerReport,
  storeGeneralLedgerReportErrors,
  clearGeneralLedgerReportErrors,
  storeGeneralLedgerDetailReport,
  clearGeneralLedgerDetailReport,
  storeGeneralLedgerExportReport,
  clearGeneralLedgerExportReport,
  store_CARES_SBA_PPP_Report,
  clear_CARES_SBA_PPP_Report,
} from '../actions/accounting-reports.action';

export interface State {
  loading: boolean;
  bankDisbursementReport: string;
  employeeAverageHoursReport: string;
  hoursEarningReport: string;
  overTimeSettings: OverTimeSetting[];
  newJobTrainingCreditReport: string;
  employeeAverageHoursWithStatusReport: string;
  generalLedgerReport: GeneralLedgerReport | undefined;
  generalLedgerReportErrors: string | null;
  generalLedgerDetailReport: string;
  generalLedgerExportReport: GeneralLedgerExportResult[];
  caresSBAPPReport: string;
}

const INITIAL_STATE: State = {
  loading: false,
  bankDisbursementReport: '',
  employeeAverageHoursReport: '',
  hoursEarningReport: '',
  overTimeSettings: [],
  newJobTrainingCreditReport: '',
  employeeAverageHoursWithStatusReport: '',
  generalLedgerReport: undefined,
  generalLedgerReportErrors: null,
  generalLedgerDetailReport: '',
  generalLedgerExportReport: [],
  caresSBAPPReport: '',
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(storeBankDisbursementsReport, (state, action) => {
      state.loading = false;
      state.bankDisbursementReport = action.payload;
    })
    .addCase(clearBankDisbursementsReport, (state) => {
      state.bankDisbursementReport = '';
    })
    .addCase(storeEmployeeAverageHoursReport, (state, action) => {
      state.loading = false;
      state.employeeAverageHoursReport = action.payload;
    })
    .addCase(clearEmployeeAverageHoursReport, (state) => {
      state.employeeAverageHoursReport = '';
    })
    .addCase(storeHoursAndEarningsReport, (state, action) => {
      state.hoursEarningReport = action.payload;
    })
    .addCase(clearHoursAndEarningsReport, (state) => {
      state.hoursEarningReport = '';
    })
    .addCase(storeOverTimeSettings, (state, action) => {
      state.overTimeSettings = action.payload;
    })
    .addCase(storeNewJobTrainingCreditReport, (state, action) => {
      state.loading = false;
      state.newJobTrainingCreditReport = action.payload;
    })
    .addCase(clearNewJobTrainingCreditReport, (state) => {
      state.newJobTrainingCreditReport = '';
    })
    .addCase(storeEmployeeAverageHoursWithStatusReport, (state, action) => {
      state.loading = false;
      state.employeeAverageHoursWithStatusReport = action.payload;
    })
    .addCase(clearEmployeeAverageHoursWithStatusReport, (state) => {
      state.employeeAverageHoursWithStatusReport = '';
    })
    .addCase(storeGeneralLedgerReport, (state, action) => {
      state.generalLedgerReport = action.payload;
    })
    .addCase(clearGeneralLedgerReport, (state) => {
      state.generalLedgerReport = undefined;
    })
    .addCase(storeGeneralLedgerReportErrors, (state, action) => {
      state.generalLedgerReportErrors = action.payload;
    })
    .addCase(clearGeneralLedgerReportErrors, (state) => {
      state.generalLedgerReportErrors = null;
    })
    .addCase(storeGeneralLedgerDetailReport, (state, action) => {
      state.generalLedgerDetailReport = action.payload;
    })
    .addCase(clearGeneralLedgerDetailReport, (state) => {
      state.generalLedgerDetailReport = '';
    })
    .addCase(storeGeneralLedgerExportReport, (state, action) => {
      state.generalLedgerExportReport = action.payload;
    })
    .addCase(clearGeneralLedgerExportReport, (state) => {
      state.generalLedgerExportReport = [];
    })
    .addCase(store_CARES_SBA_PPP_Report, (state, action) => {
      state.caresSBAPPReport = action.payload;
    })
    .addCase(clear_CARES_SBA_PPP_Report, (state) => {
      state.caresSBAPPReport = '';
    });
});