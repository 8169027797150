import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Client } from 'core/models/Client.model';
import { ShiftPremium } from 'core/models/ShiftPremium.model';
import { deleteShiftPremium, handleError } from 'core/store/actions';
import {
  getClient,
  getShiftPremiums,
} from 'core/store/selectors';
import Modal from '../Modal';
import ShiftPremiumItem from './ShiftPremiumItem';

type PropTypes = {
  show: boolean;
  onHide: any;
};

const ManageShiftPremiumModal: React.FC<PropTypes> = ({ show, onHide }) => {
  const dispatch = useDispatch();

  const [shfts, setShfts] = useState<ShiftPremium[]>([]);

  const client = useSelector(getClient) as Client;
  const shifts = useSelector(getShiftPremiums);

  useEffect(() => {
    setShfts([...shifts]);
  }, [shifts]);

  const onAddShift = (e: any) => {
    e?.stopPropagation();
    
    if (!client) {
      dispatch(handleError('Cannot add shift'));
      console.error('Client not properly set in store');
      return;
    }
    
    setShfts((prev) => {
      return [
        ...prev,
        new ShiftPremium(client.clientID, client.clientNo),
      ];
    });
  };

  const onDeleteShift = (item: ShiftPremium) => {
    if (item.shiftPremiumId) {
      dispatch(deleteShiftPremium(item));
    } else {
      // Remove all new Pay Rates that haven't already been saved.
      setShfts((prev) => { return prev.filter((sp: ShiftPremium) => { return sp.shiftPremiumId; }); },
      );
    }
  };

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        title="Manage Shift Premiums"
        headerSlot={(
          <div
            className="col-8 text-right mt-auto"
            onClick={onAddShift}
          >
            <span className="dm-grid-action-title">
              Add Shift Premium
            </span>
            <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </div>
        )}
      >
        <div className="dm-panel dm-panel-border">
          {shfts.map((item: ShiftPremium, index: number) => {
            return (
              <>
                <ShiftPremiumItem
                  key={index}
                  item={item}
                  onDelete={onDeleteShift}
                />
                {index + 1 < shfts.length && (
                  <hr className="hr-1 pb-2" />
                )}
              </>
            );
          })}
        </div>
      </Modal>
    </div>
  );
};

export default ManageShiftPremiumModal;
