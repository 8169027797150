import React from 'react';
import { useSelector } from 'react-redux';
import { EarningsCodeAutoCalculation, EarningsCodeAutoCalculationResult } from 'core/models';
import { getEarningCodes } from 'core/store/selectors/earning-code.selector';
import { InputGrp, SelectGrp } from '../form-controls';
import Icon from './Icon';
import {
  Control,
  FieldElement,
  FieldName,
  RegisterOptions,
  SetFieldValue,
  SetValueConfig,
  useFieldArray,
} from 'react-hook-form';

type FieldArray = {
  autoCalc: EarningsCodeAutoCalculation[];
};

type Props = {
  autoCalcIndex: number;
  control: Control<FieldArray>;
  register(
    rules?: RegisterOptions,
  ): FieldElement<EarningsCodeAutoCalculationResult[]> | any;
  errors: any;
  setValue(
    name: FieldName<EarningsCodeAutoCalculationResult[]>,
    value: SetFieldValue<EarningsCodeAutoCalculationResult[]>,
    config?: SetValueConfig,
  ): void;
};

export const EarningsCodeAutoCalculationResultComponent = (
  props: Props,
): JSX.Element => {
  const control = props.control;
  const earningCodes = useSelector(getEarningCodes);
  const { fields, remove, append } =
    useFieldArray<EarningsCodeAutoCalculationResult>({
      control,
      name: `autoCalc[${props.autoCalcIndex}].results`,
    });

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-4 ml-3  d-flex align-items-center text-uppercase grid-header">
            Auto Calculations Earning Code
          </div>
          <div className="col-sm-2 d-flex align-items-center text-uppercase grid-header">
            Unit
          </div>
          <div className="col-sm-4 align-items-center">
            <button
              className="btn btn-link dm-grid-action-title"
              type="button"
              onClick={() => {
                return append({
                  autoCalcResultId: 0,
                  unit: 0,
                  earningsCodeId: 0,
                });
              }
              }
            >
              Add New Auto Calculation Result{' '}
              <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          </div>
        </div>
        {fields.map((result, resultIndex) => {
          return (
            <div
              className="row"
              key={`${result.id}`}
            >
              <div className="col-sm-4 ml-3">
                <input
                  type="hidden"
                  name={`autoCalc[${props.autoCalcIndex}].results[${resultIndex}].autoCalcResultId`}
                  ref={props.register()}
                  defaultValue={result.autoCalcResultId}
                />
                <SelectGrp
                  ref={props.register()}
                  name={`autoCalc[${props.autoCalcIndex}].results[${resultIndex}].earningsCodeId`}
                  options={earningCodes}
                  valueField="earningsCodeId"
                  labelField="selectDescription"
                  defaultValue={result.earningsCodeId}
                ></SelectGrp>
              </div>
              <div className="col-sm-2">
                <InputGrp
                  ref={props.register({
                    required: true,
                    min: {
                      value: 0.0001,
                      message:
                        'Please enter a number greater than 0',
                    },
                    valueAsNumber: true,
                  })}
                  step="0.0001"
                  type="number"
                  required={true}
                  style={{ textAlign: 'right' }}
                  errors={
                    props.errors.autoCalc?.[props.autoCalcIndex]
                      ?.results?.[resultIndex]?.unit
                  }
                  name={`autoCalc[${props.autoCalcIndex}].results[${resultIndex}].unit`}
                  defaultValue={result.unit?.toFixed(4)}
                ></InputGrp>
              </div>
              <div className="col-sm-4">
                <button
                  type="button"
                  className="btn btn-link dm-grid-action-title pt-0"
                  onClick={() => { return remove(resultIndex); }}
                >
                  Delete{' '}
                  <Icon
                    name="minus-circle"
                    className="fa-minus-circle"
                  />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
