import React, { CSSProperties, useContext, useState, ChangeEvent, FocusEvent, useRef, useEffect } from 'react';
import Icon from 'core/components/shared/Icon';
import '../time-card-styles.scss';
import { InputGrp, SelectGrp } from 'core/components/form-controls';
import { useSelector } from 'react-redux';
import { getDropdownOptions } from 'core/store/selectors';
import { ddLookup, formatWithCommas } from 'utilities/utilities';
import { TimeSheetSummaryContext } from './TimeSheetSummary';
import { DeductionArrayField, TimeCardDeduction } from 'core/models';

const infoGrpStyle: CSSProperties = {
  marginBottom: '0',
  width: '125px',
  minWidth: '125px',
  marginLeft: '5%',
};

type Props = {
  deduction: DeductionArrayField;
  index: number;
  showLabel?: boolean;
  canEdit?: boolean;
  timeCardIndex: number;
  removeDeduction: (timeCardId: number, timeCardDeductionId: number) => void;
};

const SummaryDeductionItem = ({ deduction, index, removeDeduction, timeCardIndex, canEdit = true }: Props) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  
  const { deductionCodeOptions } = useSelector(getDropdownOptions);
  
  const { updateTimeCardState } = useContext(TimeSheetSummaryContext);
  
  const originalVal = useRef<number>(0);
  
  function updateDeduction(field: keyof TimeCardDeduction, value: any) {
    updateTimeCardState({
      type: 'UPDATE_DEDUCTION',
      timeCardId: deduction?.transmittalTimeCardId ?? 0,
      timeCardDeductionId: deduction?.transmittalTimeCardDeductionId ?? 0,
      field,
      value,
    });
  }
  
  const updateDedNo = (e: ChangeEvent<HTMLSelectElement>) => {
    updateDeduction('deductionNumber', e.target.value);
  };
  
  const updateAmount = (e: FocusEvent<HTMLInputElement, Element>) => {
    updateDeduction('amount', e.target.value);
    e.target.value = formatWithCommas(e.target.value);
  };
  
  const handleCancelEdit = () => {
    if (editMode) {
      updateDeduction('amount', formatWithCommas(originalVal.current));
      setEditMode(false);
      return;
    }

    setEditMode(true);    
  };
  
  return (
    <div className="earning-item">
      <div
        className="d-flex flex-column"
        style={{ width: canEdit ? '33.3%' : '25%' }}
      >
        {(deduction?.transmittalTimeCardDeductionId ?? 0) > 0 ? (
          <strong className="input-label">{deduction.deductionNumber} - {ddLookup(deduction.deductionNumber, deductionCodeOptions)}</strong>
        ) : (
          <SelectGrp
            name={`timeCardItems[${timeCardIndex}].deductions[${index}].deductionNumber`}
            options={deductionCodeOptions}
            defaultValue={deduction?.deductionNumber ?? 0}
            onChange={updateDedNo}
            selectStyles={{ height: '30px' }}
            groupStyle={{ margin: '0' }}
          />
        )}
      </div>
      <div
        style={{ marginLeft: editMode ? '10px' : '30px', width: 'fit-content' }}
      >
        <div className="d-flex align-items-center">
          {canEdit && editMode && (
            <button
              className="section-action btn btn-link dm-grid-action-title p-0 m-0"
              onClick={() => { setEditMode(false); }}
            >
              <Icon
                name="check"
                style={{ color: 'green',
                  padding: '0', marginRight: '3px' }}
              />
            </button>
          )}
          {+(deduction?.transmittalTimeCardDeductionId ?? 0) > 0 ? (
            <>
              {canEdit && (
                <button
                  className="section-action btn btn-link dm-grid-action-title p-0 m-0"
                >
                  <Icon
                    name={editMode ? 'cancel' : 'pen'}
                    style={editMode ? { color: 'red' } : { color: 'blue' }}
                    className="fa-pen"
                    title={editMode ? 'cancel' : 'edit'}
                    onClick={handleCancelEdit}
                  />
                </button>
              )}
              <InputGrp
                name={`timeCardItems[${timeCardIndex}].deductions[${index}].amount`}
                groupStyle={infoGrpStyle}
                defaultValue={formatWithCommas(deduction?.amount ?? 0)}
                disabled={!canEdit || !editMode}
                onBlur={updateAmount}
                style={{ height: '30px' }}
              />
              <span
                className="mx-2 font-weight-bold"
                style={{ whiteSpace: 'nowrap' }}
              >
                % of Net
              </span>
            </>
          ) : (
            <>
              <InputGrp
                name={`timeCardItems[${timeCardIndex}].deductions[${index}].amount`}
                groupClass="number-underline"
                groupStyle={infoGrpStyle}
                defaultValue={formatWithCommas(deduction?.amount ?? 0)}
                value={deduction?.amount ?? 0}
                onBlur={updateAmount}
                style={{ height: '30px', marginLeft: '30px' }}
              />
              {(deduction?.transmittalTimeCardDeductionId ?? 0) > 0 && (
                <span
                  className="mx-2 font-weight-bold"
                  style={{ whiteSpace: 'nowrap' }}
                >% of Net</span>
              )}
            </>
          )}
        </div>
      </div>
      {(deduction?.transmittalTimeCardDeductionId ?? 0) === 0 && (
        <div
          className="d-flex align-items-center justify-content-end"
          style={{ marginLeft: 'auto' }}
        >
          <button
            className="section-action btn btn-link dm-grid-action-title p-0 m-0"
            onClick={() => { removeDeduction(deduction.transmittalTimeCardId ?? 0, deduction.transmittalTimeCardDeductionId ?? 0); }}
          >
            Delete&nbsp;<Icon
              name="minus-circle"
              className="fa-minus-circle"
              title="delete deduction"
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default SummaryDeductionItem;