import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpResponse, TimeOffRequest } from '../../models';
import { TimeOffService } from '../../services/timeoff.service';
import { handleError } from '../actions';
import {
  loadTimeOffPastDue,
  storeTimeOffPastDue,
} from '../actions/time-off.action';

interface Actions<Type> {
  type: string;
  payload: Type;
}

const loadTimeOffPastDue$ = (action$: Observable<Actions<null>>) => {
  return action$.pipe(
    ofType(loadTimeOffPastDue.type),
    switchMap(() => {
      return from(TimeOffService.getTimeOffPastDue()).pipe(
        map((res: any) => { return res.data; }),
        map((res: TimeOffRequest[]) => { return storeTimeOffPastDue(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics = [loadTimeOffPastDue$];
