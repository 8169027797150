import React, { useEffect, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import {
  AllModules,
  ColDef,
  GridApi,
  GridOptions,
} from '@ag-grid-enterprise/all-modules';
import { Col, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getLocationDepartmentsWithoutHome } from '../../../../core/store/selectors';
import { MessageSelection, PayrollMessage } from '../../../../core/models';
import Icon from 'core/components/shared/Icon';

const columns: ColDef[] = [
  {
    field: 'select',
    headerName: 'Selected',
    checkboxSelection: true,
    width: 80,
  },
  {
    field: 'sub2Code',
    headerName: 'Sub Dept2',
    sortable: true,
    width: 100,
  },
  {
    field: 'sub2Desc',
    headerName: 'Description',
    sortable: true,
    minWidth: 140,
  },
];

const gridOptions: GridOptions = {
  domLayout: 'autoHeight',
  columnDefs: columns,
  rowSelection: 'multiple',
  enableCellTextSelection: true,
  defaultColDef: {
    suppressMenu: true,
    resizable: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
};

type PropTypes = {
  message: PayrollMessage | undefined;
  show: boolean;
  onHide: () => void;
  selectedItems: (items: MessageSelection[]) => void;
};

const MessageSelectSubDept2Modal: React.FC<PropTypes> = ({
  message,
  show,
  onHide,
  selectedItems,
}) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [, setGridColumnApi] = useState(null);
  const [subDept2s, setSubDept2s] = useState<any[]>([]);
  const [selectedSubDepts, setSelectedSubDepts] =
    useState<MessageSelection[]>();
  const { subdept2Opts } = useSelector(getLocationDepartmentsWithoutHome);

  useEffect(() => {
    setSubDept2s(subdept2Opts);
  }, [subdept2Opts]);

  const onGridReady = (params: any) => {
    gridOptions?.api?.forEachNode((node) => {
      if (message?.subDept2Selections) {
        return message.subDept2Selections.some(
          (s: MessageSelection) => { return +s.value === node.data.sub2Code; },
        )
          ? node.setSelected(true)
          : 0;
      }
    });

    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      selectedRows.length > 0 &&
        setSelectedSubDepts(
          selectedRows.map((r) => {
            return {
              messageDetailId: r.messageDetailId || 0,
              value: '' + r.sub2Code,
            };
          }),
        );
    }
  };

  const onSave = () => {
    selectedSubDepts && selectedItems(selectedSubDepts);
    closeModal();
  };

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal show={show}
        onHide={onHide}
        size="sm"
        animation={false}>
        <div className="modal-header">
          <div className="dm-card-title">Select SubDept2</div>
          <button type="button"
            onClick={closeModal}>
            <Icon name="times"
              className="modal-close-btn"
            />
          </button>
        </div>
        <Modal.Body>
          <Row>
            <Col
              xs={12}
              className="table-wrapper-wrapper ag-theme-balham"
            >
              <div
                className="p-2"
                style={{
                  height: '400px',
                  overflowY: 'scroll',
                }}
              >
                <AgGridReact
                  gridOptions={gridOptions}
                  rowData={subDept2s}
                  modules={AllModules}
                  onFirstDataRendered={onGridReady}
                  onSelectionChanged={onSelectionChanged}
                />
              </div>
              <div className="d-flex mt-3">
                <button
                  className="orange-outline-button ml-auto mr-2"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="orange-button mr-2"
                  onClick={onSave}
                >
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MessageSelectSubDept2Modal;
