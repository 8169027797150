import React, { useState } from 'react';
import Modal from 'core/components/modals/Modal';
import { useHistory } from 'react-router-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, ColGroupDef, GetDetailRowDataParams, GridApi, GridOptions, GridReadyEvent, MasterDetailModule } from '@ag-grid-enterprise/all-modules';
import { IntegrationLog, IntegrationLogDetail, IntegrationLogDetailRequest } from 'core/models';
import { useSelector } from 'react-redux';
import { getClientIntegrationLogs } from 'core/store/selectors/integration.selector';
import { agDateTimeRenderer } from 'utilities/ag-grid-renderers';
import PageSpinner from 'core/components/shared/PageSpinner';
import { useAppDispatch } from 'utilities/hooks';
import { getIntegrationLogDetails, loadIntegrationLogs } from 'core/store/actions';

type Props = {
  show: boolean;
  onHide: () => void;
};

export const defaultColumnDef: ColDef = {
  resizable: true,
  sortable: true,
  filter: true,
  autoHeight: true,
  cellClass: 'ag-cell-left-border',
  headerClass: 'grid-header',
};

const masterColumnDef: ColGroupDef[] =
  [
    {
      headerName: 'Integration Log',
      children: [
        {field: 'clientNo', headerName: 'Client Number', cellRenderer: 'agGroupCellRenderer', width: 140, sortable: false, filter: false },
        {field: 'description', headerName: 'Category', flex: 1},
        {field: 'startDateTime', headerName: 'Start Date/Time', cellRenderer: 'dateRenderer', flex: 1, sort: 'desc', sortingOrder: ['desc', 'asc'] },
        {field: 'endDateTime', headerName: 'End Date/Time', cellRenderer: 'dateRenderer', flex: 1, sortingOrder: ['desc', 'asc'] },
      ],
    },
  ];

const detailColumnDef: ColDef[] = [
  {field: 'severity', headerName: 'Severity', width: 115},
  {field: 'message', headerName: 'Message', width: 1300},
];

const IntegrationLogsModal = ({ show, onHide }: Props) => {
  const history = useHistory();
  const [gridApi, setGridApi] = useState<GridApi>();
  const clientIntegrationLogs = useSelector(getClientIntegrationLogs);
  const dispatch = useAppDispatch();
  
  const onGridReady = (e: GridReadyEvent) => {
    setGridApi(e.api);
  };

  const groupOptions: GridOptions =
  {
    domLayout: 'autoHeight',
    columnDefs: masterColumnDef,
    masterDetail: true,
    defaultColDef: defaultColumnDef,
    pagination: true,
    paginationPageSize: 15,
    components: {
      dateRenderer: agDateTimeRenderer,
    },
    detailCellRendererParams: {
      detailGridOptions: {
        domLayout: 'autoHeight',
        defaultColDef: defaultColumnDef,
        columnDefs: detailColumnDef,
      },
      getDetailRowData(params: GetDetailRowDataParams) {
        const data: IntegrationLog = params?.data;

        const request : IntegrationLogDetailRequest = {
          integrationLogCategoryId: data.integrationLogCategoryId,
          integrationLogId: data.integrationLogId
        }

        dispatch(getIntegrationLogDetails(request)).then((value) => {
          const details = value.payload as IntegrationLogDetail[];
          params.successCallback(details || []);
        });
        
      },
    },
  };
  
  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        //Do we want it to push them back to the main page? Maybe?
        //history.push('/');
      }}
      size='lg'
      title="Integration Logs"
    >
      {(closeModal) => {
        return (
        <>
          {(clientIntegrationLogs.length === 0) ? <PageSpinner /> :
          <div className="d-flex w-100 flex-wrap">
            <div className="table-wrapper-wrapper ag-theme-balham">
              <AgGridReact
                detailRowAutoHeight
                masterDetail
                modules={[MasterDetailModule]}
                gridOptions={groupOptions}
                rowData={clientIntegrationLogs}
                onGridReady={onGridReady}
              />
            </div>
            <div className="d-flex w-100 justify-content-end" style={{paddingTop: '10px'}}>
              <button
                className="orange-button mr-2"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
          }
        </>
        );
      }}
    </Modal>
  );
};

export default IntegrationLogsModal;