import React, { useEffect, useRef, useState } from 'react';
import { Labels } from '.';
import Select, { GroupBase, MenuPlacement, StylesConfig } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import styles from './form-controls.module.scss';
import { FormMethods } from './types';
import { ShiftPremium } from 'core/models/ShiftPremium.model';
import { useSelector } from 'react-redux';
import { getShiftPremiums } from 'core/store/selectors';
import 'features/payroll/open-current-transmittal/transmittal-modal/shift-premium-table-styles.scss';

type StylesConfigType = StylesConfig<any, boolean, GroupBase<unknown>> | undefined;
type CompsType = Partial<SelectComponents<any, boolean, GroupBase<unknown>>> | undefined;

const compStyles: StylesConfigType = {
  control: (base) => {
    return {
      ...base,
      minHeight: '22px',
      height: '22px',
      width: '85px',
      borderRadius: '0.25rem',
      fontSize: '12px',
      color: 'black',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#0074D9',
      },
    };
  },
  valueContainer: (base) => {
    return {
      ...base,
      padding: '0',
      paddingLeft: '2px',
      top: '0',
      position: 'relative',
      overflow: 'hidden',
      width: 'fit-content',
      color: 'black',
      boxSizing: 'border-box',
    };
  },
  indicatorSeparator: (base) => {
    return {
      ...base,
      display: 'none',
    };
  },
  dropdownIndicator: (base) => {
    return {
      ...base,
      padding: '0px',
      paddingLeft: '5px',
      color: '#3a3a3a',
      '&:hover': {
        color: 'black',
      },
    };
  },
  menuPortal: (base) => {
    return {
      ...base,
      zIndex: 9999,
      width: 'fit-content',
      maxWidth: '700px',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 10px 0px',
      background: 'rgb(255, 255, 255)',
      cursor: 'pointer',
      position: 'fixed',
    };
  },
};

type PropTypes = {
  id?: string;
  name: string;
  value?: string | null;
  label?: string | string[];
  groupClass?: string;
  groupStyle?: object;
  required?: boolean;
  menuPlacement?: MenuPlacement;
  errors: any;
  setValue: any;
  onFocus?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  onChange?: (newVal: string | null) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  readonly?: boolean;
};

export const ShiftCodeSelect: React.FC<PropTypes> = ({
  id,
  name,
  value,
  label,
  groupClass,
  required = false,
  menuPlacement = 'auto',
  setValue,
  onFocus,
  onChange,
  onBlur,
  readonly = false,
}) => {
  const groupClass2 = styles['dm-form-group'] + ' ' + (groupClass ?? '');
  id = id ?? name;
  
  const shiftPremiums = useSelector(getShiftPremiums);

  const [renderedVal, setRenderedVal] = useState<{ label: string, value: string } | null>(null);
  
  useEffect(() => {
    if (!value) return;

    setRenderedVal({
      label: shiftPremiums?.find((x) => String(x.shiftPremiumId) === String(value))?.shiftCode ?? '',
      value: value,
    });
    setValue(name, value, {
      shouldDirty: false,
    });
  }, [value, shiftPremiums]);

  const handleChange = (newVal: ShiftPremium | null) => {
    const shiftPremiumId = newVal ? String(newVal.shiftPremiumId) : null;

    setRenderedVal({
      label: newVal?.shiftCode ?? 'None',
      value: shiftPremiumId ?? '',
    });
    setValue(name, shiftPremiumId, {
      shouldDirty: false,
    });
    if (onChange) onChange(shiftPremiumId);
  };

  const CustomMenu = () => {
    const handleOptClick = (opt: ShiftPremium | null) => {
      handleChange(opt);
    };

    return (
      <table style={{ fontSize: '11px' }}>
        <thead>
          <tr>
            <th className="premium-table-cell narrow-column table-head">Shift Code</th>
            <th className="premium-table-cell narrow-column table-head">Earnings Code</th>
            <th className="premium-table-cell narrow-column table-head">Short Description</th>
            <th className="premium-table-cell table-head">Description</th>
            <th className="premium-table-cell narrow-column table-head">Is Percent</th>
            <th className="premium-table-cell table-head">Amount</th>
            <th className="premium-table-cell table-head">Apply to All Earn Codes</th>
            <th className="premium-table-cell table-head">Include in Control Totals</th>
            <th className="premium-table-cell table-head">Internal Comment</th>
          </tr>
        </thead>
        <tbody className="premium-table-body">
          {shiftPremiums?.map((shiftPremium: ShiftPremium) => {
            return (
              <tr
                className="premium-table-row"
                key={shiftPremium.shiftPremiumId}
                onMouseDown={() => { handleOptClick(shiftPremium); }}
              >
                <td className="premium-table-cell">{shiftPremium.shiftCode}</td>
                <td className="premium-table-cell">{shiftPremium?.earningsCode ?? 'N/A'}</td>
                <td className="premium-table-cell">{shiftPremium?.shortDescription ?? 'N/A'}</td>
                <td className="premium-table-cell">{shiftPremium.description}</td>
                <td className="premium-table-cell">
                  <input
                    type="checkbox"
                    checked={shiftPremium.isPercent}
                    disabled
                  />
                </td>
                <td className="premium-table-cell">{shiftPremium.amount}</td>
                <td className="premium-table-cell">
                  <input
                    type="checkbox"
                    checked={shiftPremium.applyToAllEarnCodes}
                    disabled
                  />
                </td>
                <td className="premium-table-cell">
                  <input
                    type="checkbox"
                    checked={shiftPremium.includeInControlTotals}
                    disabled
                  />
                </td>
                <td className="premium-table-cell">{shiftPremium.internalComment}</td>
              </tr>
            );
          },
          )}
          <tr
            className="premium-table-row"
            onMouseDown={() => { handleOptClick(null); }}
          >
            <td className="premium-table-cell">
              <strong>None</strong>
            </td>
            <td className="premium-table-cell"></td>
            <td className="premium-table-cell"></td>
            <td className="premium-table-cell"></td>
            <td className="premium-table-cell"></td>
            <td className="premium-table-cell"></td>
            <td className="premium-table-cell"></td>
            <td className="premium-table-cell"></td>
            <td className="premium-table-cell"></td>

          </tr>
        </tbody>
      </table>
    );
  };

  const customComponents: CompsType = {
    Menu: CustomMenu,
  };

  return (
    <div
      className={groupClass2}
      id="check-code-wrapper"
    >
      <Labels
        label={label}
        id={id}
        hasError={false}
        required={required}
      />
      {/* 
				// @ts-ignore */}
      <Select
        inputId={id}
        styles={compStyles as StylesConfigType}
        components={customComponents as CompsType}
        value={renderedVal}
        options={shiftPremiums}
        getOptionLabel={(optionData) => renderedVal?.label ?? `${optionData?.shiftPremiumId}`}
        getOptionValue={(optionData) => renderedVal?.value ?? `${optionData?.shiftPremiumId}`}
        onFocus={onFocus}
        onChange={handleChange}
        onBlur={onBlur}
        isDisabled={readonly}
        isSearchable={false}
        isClearable={false}
        menuPlacement={menuPlacement}
        menuPortalTarget={document.getElementById('check-code-wrapper')}
        closeMenuOnSelect={false}
      />
    </div>
  );
};
