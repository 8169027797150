import React from 'react';
import { DeepMap, FieldError } from 'react-hook-form';
import { InputGrp, InputGrpDecimal } from '../../../core/components/form-controls';
import {
  TransmittalEmployee,
  TransmittalOtherInfo,
} from '../../../core/models';
import { useSelector } from 'react-redux';
import { getIsFinishedPayroll } from 'core/store/selectors/payroll.selector';
import { FormMethods } from 'core/components/form-controls/types';
import { formatWithCommas } from 'utilities/utilities';

type Props = {
  memoItems: TransmittalOtherInfo[];
  checkIndex: number;
  register: FormMethods['register'];
  watch: FormMethods['watch'];
  setValue: FormMethods['setValue'];
  errors: DeepMap<TransmittalEmployee, FieldError>;
};

const MemoItems: React.FC<Props> = ({
  memoItems,
  checkIndex,
  register,
  watch,
  setValue,
  errors,
}) => {
  const isFinishedPayroll =  useSelector(getIsFinishedPayroll);
  return (
    <>
      {memoItems.map((item, index) => {
        const watchedMemoAmt = watch(`checks[${checkIndex}].memoItems[${index}].amount`);
        
        return (
          <div
            className="row align-items-center"
            key={index}
          >
            <div className="col font-weight-bold">{item.description}</div>
            <input
              type="hidden"
              name={`checks[${checkIndex}].memoItems[${index}].clientOtherInfoId`}
              ref={register({
                valueAsNumber: true,
              })}
              value={item.clientOtherInfoId}
            />
            <input
              type="hidden"
              name={`checks[${checkIndex}].memoItems[${index}].description`}
              ref={register()}
              value={item.description}
            />
            <input
              type="hidden"
              name={`checks[${checkIndex}].memoItems[${index}].displayOrder`}
              ref={register({
                valueAsNumber: true,
              })}
              value={item.displayOrder}
            />
            <input
              type="hidden"
              name={`checks[${checkIndex}].memoItems[${index}].transmittalOtherInfoId`}
              ref={register({
                valueAsNumber: true,
              })}
              value={item.transmittalOtherInfoId}
            />
            <div className="col">
              <InputGrp
                label="Amount"
                type="text"
                name={`checks[${checkIndex}].memoItems[${index}].amount`}
                defaultValue={formatWithCommas(watchedMemoAmt ?? 0)}
                readOnly={isFinishedPayroll}
                setValue={setValue}
                ref={register({
                  valueAsNumber: true,
                  required: { value: true, message: 'Required' },
                  min: {
                    value: 0,
                    message: 'Amount must be greater than 0',
                  },
                })}
                errors={
                  errors?.checks?.[checkIndex]?.memoItems?.[index]
                    ?.amount
                }
                formatNumber
                hiddenValue={watchedMemoAmt ?? 0}
                hiddenRef={register({
                  valueAsNumber: true,
                })}
                style={{ textAlign: 'right' }}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MemoItems;
