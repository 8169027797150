import { createAction } from '@reduxjs/toolkit';
import { EarningsReportsStandardReportsRequest, EmployeeWageDetailDownloadReportRequest } from '../../models/EarningsReports.model';

export const loadStandardReports =
    createAction<EarningsReportsStandardReportsRequest>(
      '[earnings-reports] LOAD_EARNINGS_REPORTS',
    );
export const storeStandardReports = createAction<string>(
  '[earnings-reports] STORE_EARNINGS_REPORTS',
);

export const storeBeginDate = createAction<string>(
  '[earnings-reports] STORE_BEGIN_DATE',
);
export const storeEndDate = createAction<string>(
  '[earnings-reports] STORE_END_DATE',
);
export const storeCheckDate = createAction<boolean>(
  '[earnings-reports] STORE_CHECK_DATE',
);
export const downloadEmployeeWageDetailDownloadReport = createAction<EmployeeWageDetailDownloadReportRequest>(
  '[earnings-reports] LOAD_EMPLOYEE_WAGE_DETAIL_DOWNLOAD',
);
export const storeEmployeeWageDetailDownloadReport = createAction<string>(
  '[earnings-reports] STORE_EMPLOYEE_WAGE_DETAIL_DOWNLOAD',
);

export const clearEmployeeWageDetailDownloadReport = createAction(
  '[earnings-reports] CLEAR_EMPLOYEE_WAGE_DETAIL_DOWNLOAD',
);