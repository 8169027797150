import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class HireHistory {
  empId = 0;

  empDateID = 0;

  clientNo = 0;

  empNo = 0;

  protectedEmpNo = '';

  hireDate: Date | string | null = null;

  termDate: Date | string | null = null;

  termCodeID = 0;

  notes = ''

  static readonly convProps: PropTypeLists = {
    dateProps: ['hireDate', 'termDate'],
    numberProps: ['empID', 'empDateID', 'clientNo', 'empNo', 'termCodeID'],
  };

  constructor(
    empId: number | string,
    clientNo: number | string,
    empNo: string | number,
    protectedEmpNo: string,
    props?: { [key: string]: any }) {

    this.empId = (typeof empId === 'string') ? parseInt(empId) : empId;
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;
    props && convToClass(this, props, HireHistory.convProps);
  }
}

enum FilesToResend
{
    W4 = 1,
    I9 = 2,
    ALL = 3,
    NONE = 4,
}

const getFilesToResend = (resendI9: boolean, resendW4: boolean) => {
  if(resendI9 && resendW4) return FilesToResend.ALL
  else if (resendI9 && !resendW4) return FilesToResend.I9;
  else if (resendW4 && !resendI9) return FilesToResend.W4;
  else return FilesToResend.NONE; 
}

export class HireHistoryWithRehireInfo extends HireHistory {
  
  previousHireDate: Date | string = '';

  isRehire: boolean = false;

  filesToResend: FilesToResend = FilesToResend.NONE;

  static readonly convProps: PropTypeLists = {
    dateProps: ['previousHireDate'],
    boolProps: ['isRehire', 'notifyEmployee'],
  };

  constructor(
    empId: number | string,
    clientNo: number | string,
    empNo: string | number,
    protectedEmpNo: string,
    previousHireDate: Date | string,
    isRehire: boolean,
    resendI9: boolean,
    resendW4: boolean,
    props?: { [key: string]: any }) {

    super(empId, clientNo, empNo, protectedEmpNo, props);
    this.empId = (typeof empId === 'string') ? parseInt(empId) : empId;
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;
    this.previousHireDate = previousHireDate;
    this.isRehire = isRehire;
    this.filesToResend = getFilesToResend(resendI9, resendW4);
    props && convToClass(this, props, HireHistory.convProps);
  }
}