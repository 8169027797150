import { AgGridReact } from '@ag-grid-community/react';
import {
  AllModules,
  CellClassParams,
  CellValueChangedEvent,
  ColDef,
  ColumnApi,
  EditableCallbackParams,
  GetMainMenuItemsParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellEditorParams,
  ICellRendererParams,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
} from '@ag-grid-enterprise/all-modules';
import Modal from 'core/components/modals/Modal';
import Icon from 'core/components/shared/Icon';
import uniq from 'lodash/uniq';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { TextareaGrp } from 'core/components/form-controls/TextareaGrp';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import {
  PayrollUploadFields,
  PayrollUploadMap,
  PayrollUploadVM,
} from 'core/models/PayrollUpload.model';
import {
  createPayrollUploadField,
  createPayrollUploadFile,
  deletePayrollUploadFields,
  storeSelectedPayrollUploadFile,
  updatePayrollUploadField,
  updatePayrollUploadFile,
} from 'core/store/actions';
import { getDropdownOptions } from 'core/store/selectors';
import {
  getPayrollUploadClientFile,
  getPayrollUploadVM,
} from 'core/store/selectors/payroll-upload.selector';
import {
  agInputNumberEditor,
  agSelectEditor,
} from 'utilities/ag-grid-editors';
import { agLookupRenderer } from 'utilities/ag-grid-renderers';
import AGDeleteHeaderComponent from 'utilities/ag-grid-renderers/AGDeleteHeaderComponent';
import AGEditButtonRendererComponent from 'utilities/ag-grid-renderers/AGEditButtonRendererComponent';
import CheckboxRenderer from 'utilities/ag-grid-renderers/CheckboxRenderer';
import UploadPayrollExceptionsModal from './Exceptions.modal';
import PayrollUpdateInvalidColumnNumbers from './InvalidColumNumber.modal';
import UploadPayrollRatesModal from './Rates.modal';
import UploadPayrollTranslationsModal from './Translations.modal';
import { buildHeaders, buildParsedFile, generateColumnDef } from 'utilities/utilities';

const ignoreField: PayrollUploadFields = {
  uploadFieldId: 31,
  actualFieldname: '',
  visible: true,
  visibleFieldName: 'Ignore Column',
  visiblePosition: 0,
};

const otherInfoTypeOpts = [
  {
    id: 'System.Int',
    description: 'Integer',
  },
  {
    id: 'System.String',
    description: 'String/Text',
  },
  {
    id: 'System.Decimal',
    description: 'Decimal',
  },
  {
    id: 'System.Boolean',
    description: 'True/False',
  },
];

const numericValueSetter = (params: ValueSetterParams) => {
  if (+params.oldValue !== +params.newValue) {
    params.data[params.colDef.field ?? ''] = +params.newValue;
    return true;
  }
  return false;
};

const buildCol1Defs = (
  fieldOpts: any,
  dedCodeOpts: any,
  earningsCodesOpts: any,
  onTranslations: any,
  onExceptions: any,
  onRates: any,
): ColDef[] => {
  return [
    {
      field: 'uploadFieldId',
      headerName: 'Map to Field',
      editable: false,
      cellEditorParams: (_params: ICellEditorParams) => {
        return {
          options: fieldOpts,
          labelField: 'visibleFieldName',
          valueField: 'uploadFieldId',
        };
      },
      cellEditor: 'selectEditor',
      cellRendererParams: (_params: ICellRendererParams) => {
        return {
          options: fieldOpts,
          labelField: 'visibleFieldName',
          valueField: 'uploadFieldId',
        };
      },
      cellRenderer: 'selectEditor',
      valueGetter: (params: ValueGetterParams) => { return params.data.field.uploadFieldId; },
      valueSetter: (params: ValueSetterParams) => {
        if (+params.oldValue !== +params.newValue) {
          params.data.field = fieldOpts?.find(
            (f: any) => { return f.uploadFieldId === +params.newValue; },
          );
          return true;
        }
        return false;
      },
      width: 210,
    },
    {
      field: 'fieldOrdinal',
      headerName: 'Column No.',
      editable: true,
      cellEditor: 'inputNumberEditor',
      valueSetter: numericValueSetter,
      width: 120,
    },
    {
      field: 'decimals',
      headerName: 'Decimals',
      editable: true,
      cellEditor: 'inputNumberEditor',
      valueSetter: numericValueSetter,
      width: 100,
    },
    {
      field: 'defaultValue',
      headerName: 'Default Value',
      editable: true,
      width: 120,
    },
    {
      field: 'deductionCode',
      headerName: 'Deduction Code',
      editable: (params: EditableCallbackParams) => { return params.data.field.actualFieldName === 'SpecialDedAmt'; },
      cellEditorParams: { options: dedCodeOpts },
      cellEditor: 'selectEditor',
      cellRendererParams: { options: dedCodeOpts },
      cellRenderer: 'lookupRenderer',
      cellClassRules: {
        'ag-cell-disabled': (params: CellClassParams) => { return params.data.field.actualFieldName !== 'SpecialDedAmt'; },
        'ag-cell-enabled': (params: CellClassParams) => { return params.data.field.actualFieldName === 'SpecialDedAmt'; },
      },
      width: 120,
    },
    {
      field: 'earningCode',
      headerName: 'Earning Code',
      editable: (params: EditableCallbackParams) => {
        return params.data.field.actualFieldName === 'OtherHours' ||
          params.data.field.actualFieldName === 'OtherEarns';
      },
      cellEditorParams: { options: earningsCodesOpts },
      cellEditor: 'selectEditor',
      cellRendererParams: { options: earningsCodesOpts },
      cellRenderer: 'lookupRenderer',
      cellClassRules: {
        'ag-cell-disabled': (params: CellClassParams) => {
          return params.data.field.actualFieldName !== 'OtherHours' &&
            params.data.field.actualFieldName !== 'OtherEarns';
        },
        'ag-cell-enabled': (params: CellClassParams) => {
          return params.data.field.actualFieldName === 'OtherHours' ||
            params.data.field.actualFieldName === 'OtherEarns';
        },
      },
      width: 120,
    },
    {
      field: 'otherPayrollInfoType',
      headerName: 'Other Info Type',
      editable: (params: EditableCallbackParams) => { return params.data.field.actualFieldName === 'OtherPayrollInfo'; },
      cellEditorParams: { options: otherInfoTypeOpts },
      cellEditor: 'selectEditor',
      cellRendererParams: { options: otherInfoTypeOpts },
      cellRenderer: 'lookupRenderer',
      cellClassRules: {
        'ag-cell-disabled': (params: CellClassParams) => { return params.data.field.actualFieldName !== 'OtherPayrollInfo'; },
        'ag-cell-enabled': (params: CellClassParams) => { return params.data.field.actualFieldName === 'OtherPayrollInfo'; },
      },
      width: 120,
    },
    {
      field: 'otherPayrollInfoDesc',
      headerName: 'Other Info Desc',
      editable: (params: EditableCallbackParams) => { return params.data.field.actualFieldName === 'OtherPayrollInfo'; },
      cellClassRules: {
        'ag-cell-disabled': (params: CellClassParams) => { return params.data.field.actualFieldName !== 'OtherPayrollInfo'; },
        'ag-cell-enabled': (params: CellClassParams) => { return params.data.field.actualFieldName === 'OtherPayrollInfo'; },
      },
      width: 120,
    },
    {
      field: 'translations',
      headerName: 'Translate',
      editable: false,
      cellRendererParams: { clickHandler: onTranslations },
      cellRenderer: 'editButtonRendererComponent',
      width: 110,
    },
    {
      field: 'earningCodeExceptions',
      headerName: 'Exception',
      editable: false,
      cellRendererParams: { clickHandler: onExceptions },
      cellRenderer: 'editButtonRendererComponent',
      cellClassRules: {
        'ag-cell-disabled': (params: CellClassParams) => {
          return params.data.field.actualFieldName !== 'OtherHours' &&
            params.data.field.actualFieldName !== 'OtherEarns';
        },
        'ag-cell-enabled': (params: CellClassParams) => {
          return params.data.field.actualFieldName === 'OtherHours' ||
            params.data.field.actualFieldName === 'OtherEarns';
        },
      },
      width: 110,
    },
    {
      field: 'earningCodeRates',
      headerName: 'Rate Map',
      editable: false,
      cellRendererParams: { clickHandler: onRates },
      cellRenderer: 'editButtonRendererComponent',
      cellClassRules: {
        'ag-cell-disabled': (params: CellClassParams) => { return params.data.field.actualFieldName !== 'OtherHoursCode'; },
        'ag-cell-enabled': (params: CellClassParams) => { return params.data.field.actualFieldName === 'OtherHoursCode'; },
      },
      width: 110,
    },
    {
      field: 'delete',
      headerComponentFramework: AGDeleteHeaderComponent,
      editable: false,
      cellRenderer: 'checkBoxRenderer',
      cellEditor: 'checkboxEditor',
      cellStyle: { paddingLeft: '16px' },
      width: 45,
    },
  ];
};

const gridOptions1: GridOptions = {
  defaultColDef: {
    resizable: true,
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
  // @ts-ignore
  components: {
    inputNumberEditor: agInputNumberEditor,
    selectEditor: agSelectEditor,
    lookupRenderer: agLookupRenderer,
  },
  // @ts-ignore
  frameworkComponents: {
    editButtonRendererComponent: AGEditButtonRendererComponent,
    checkBoxRenderer: CheckboxRenderer,
  },
  stopEditingWhenCellsLoseFocus: true,
  rowSelection: 'single',
};

const gridOptions2: GridOptions = {
  defaultColDef: {
    resizable: true,
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
  stopEditingWhenCellsLoseFocus: true,
  rowSelection: 'single',
};

interface DataField extends PayrollUploadMap {
  field: PayrollUploadFields;
  modified: boolean;
  delete: boolean;
}

type PropTypes = {
  csvFile: any;
  fileRows: string[];
  show: boolean;
  headerStartingLine: number;
  onHide: () => void;
  prev: () => void;
};

const UploadPayrollStep2Modal: React.FC<PropTypes> = ({
  csvFile,
  fileRows,
  show,
  headerStartingLine,
  onHide,
  prev,
}) => {
  const dispatch = useDispatch();
  const payrollUploadVM = useSelector(getPayrollUploadVM) as PayrollUploadVM;
  const [rowData, setRowData] = useState<DataField[]>([]);
  const [gridApi1, setGridApi1] = useState<GridApi | null>(null);
  const [gridApi2, setGridApi2] = useState<GridApi | null>(null);
  const [, setGridColumnApi1] = useState<ColumnApi | null>(null);
  const [, setGridColumnApi2] = useState<ColumnApi | null>(null);
  const [grid2Data, setGrid2Data] = useState<any[]>([]);
  const [showTranslations, setShowTranslations] = useState(false);
  const [showExceptions, setShowExceptions] = useState(false);
  const [showRates, setShowRates] = useState(false);
  const [showInvalidColNo, setShowInvalidColNo] = useState(false);
  const [selectedField, setSelectedField] = useState<DataField | null>(null);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { deductionCodeOptions, earningsCodesOptions } = useSelector(getDropdownOptions);
  const payrollUploadClientFile = useSelector(getPayrollUploadClientFile);

  useEffect(() => {
    if (!payrollUploadClientFile?.fields) return;
    setRowData(
      payrollUploadClientFile.fields
        .sort((a, b) => { return a.fieldOrdinal - b.fieldOrdinal; })
        .map((f: PayrollUploadMap) => {
          const field = {
            ...payrollUploadVM?.fields?.find(
              (x) => { return x.uploadFieldId === f.uploadFieldId; },
            ),
          } as PayrollUploadFields;
          return {
            ...f,
            field,
            modified: false,
            delete: false,
          };
        }),
    );
  }, [payrollUploadClientFile, payrollUploadVM]);

  const onTranslations = (rowData: any) => {
    setSelectedField(rowData);
    setShowTranslations(true);
  };

  const onExceptions = (rowData: any) => {
    if (
      rowData.field.actualFieldName === 'OtherHours' ||
      rowData.field.actualFieldName === 'OtherEarns'
    ) {
      setSelectedField(rowData);
      setShowExceptions(true);
    }
  };

  const onRates = (rowData: any) => {
    if (rowData.field.actualFieldName === 'OtherHoursCode') {
      setSelectedField(rowData);
      setShowRates(true);
    }
  };

  const onCellValueChanged = (e: CellValueChangedEvent) => {
    if (e.oldValue !== e.newValue) e.data.modified = true;
    setFormIsDirty(e.oldValue !== e.newValue);
  };

  useEffect(() => {
    if (!gridApi1) return;
    gridApi1.setColumnDefs(
      buildCol1Defs(
        payrollUploadVM.fields,
        deductionCodeOptions,
        earningsCodesOptions,
        onTranslations,
        onExceptions,
        onRates,
      ),
    );
  }, [gridApi1, payrollUploadVM]);

  useEffect(() => {
    if (gridApi2) {
      const fileRowsCopy = structuredClone(fileRows).filter(x => x);
      const headers = buildHeaders(headerStartingLine, fileRowsCopy);
      const colDefs = generateColumnDef(headerStartingLine, headers);
      const parsedFile = buildParsedFile(headerStartingLine, fileRowsCopy, headers);

      gridApi2.setColumnDefs(colDefs);
      setGrid2Data(parsedFile);
    }
  }, [gridApi2, csvFile, headerStartingLine]);

  const onGridReady1 = (e: GridReadyEvent) => {
    setGridApi1(e.api);
    setGridColumnApi1(e.columnApi);
  };

  const onGridReady2 = (e: GridReadyEvent) => {
    setGridApi2(e.api);
    setGridColumnApi2(e.columnApi);
  };

  const onPrev = () => {
    onHide();
    prev();
  };

  const getNextColumnNo = (): number => {
    if (payrollUploadClientFile) {
      let usedOrdinals: number[] = [];
      gridApi1?.forEachNode((node: RowNode) => { return usedOrdinals.push(node.data.fieldOrdinal); },
      );
      usedOrdinals = uniq(usedOrdinals).sort((a, b) => {
        return a - b;
      });

      return usedOrdinals?.slice(-1)[0] + 1;
    }

    return 0;
  };

  const onAddField = (fieldOrdinal: number, ignore: boolean) => {
    const newMap: any = {};

    if (payrollUploadClientFile) {
      let usedOrdinals: number[] = [];
      gridApi1?.forEachNode((node: RowNode) => { return usedOrdinals.push(node.data.fieldOrdinal); },
      );
      usedOrdinals = uniq(usedOrdinals).sort((a, b) => {
        return a - b;
      });

      let valid = true;
      valid = !usedOrdinals.some((o: number) => { return o === fieldOrdinal; });

      if (!valid) {
        setShowInvalidColNo(true);
        return;
      }
    }

    if (payrollUploadClientFile?.style === 'D') {
      if (fieldOrdinal) {
        newMap.fieldOrdinal = fieldOrdinal;
      } else {
        let maxOrdinal = 0;
        rowData.forEach(
          (f: PayrollUploadMap) => {
            return f.fieldOrdinal > maxOrdinal &&
              (maxOrdinal = f.fieldOrdinal);
          },
        );
        newMap.fieldOrdinal = maxOrdinal;
      }
    }
    if (ignore) {
      newMap.uploadFieldId = ignoreField.uploadFieldId;
    }
    setRowData((prevState) => {
      return [
        ...prevState,
        {
          ...newMap,
          field: ignore
            ? ignoreField
            : {
              uploadFieldId: 0,
              actualFieldname: '',
              visible: true,
              visibleFieldName: '',
              visiblePosition: 1,
            },
          modified: ignore ? true : false,
          delete: false,
        },
      ];
    });
    setFormIsDirty(true);
  };

  const getMainMenuItems = (params: GetMainMenuItemsParams) => {
    const colDef = params.column.getColDef() as any;
    const fieldOrdinal = colDef ? +colDef.fieldOrdinal : 0;

    const menuItems = [];
    menuItems.push({
      name: 'Add Column to Map',
      action: () => { return onAddField(fieldOrdinal, false); },
    });
    menuItems.push({
      name: 'Ignore Column',
      action: () => { return onAddField(fieldOrdinal, true); },
    });
    menuItems.push({
      name: 'Close Menu',
      action: () => { return gridApi2?.hidePopupMenu(); },
    });
    return menuItems;
  };

  const onDeleteSelected = () => {
    if (gridApi1) {
      const fieldsToDelete: PayrollUploadMap[] = [];

      gridApi1.forEachNode((node: RowNode) => {
        if (node.data.delete && node.data.mapId) fieldsToDelete.push(node.data);
      });
      
      setRowData((_prev) => {
        const newNodes: any[] = [];
        gridApi1.forEachNode((node: RowNode) => {
          if (!node.data.delete) newNodes.push(node.data);
        });
        return newNodes;
      });
      if (fieldsToDelete.length) {
        dispatch(deletePayrollUploadFields(fieldsToDelete));

        //This will set the payroll upload fields that are shown in the table so after we delete one it will not show on the table still.
        const payrollUploadClientFileCopy = structuredClone(payrollUploadClientFile);
        payrollUploadClientFileCopy.fields = payrollUploadClientFileCopy.fields.filter(x => !fieldsToDelete.map(x => x.uploadFieldId).includes(x.uploadFieldId));
        dispatch(storeSelectedPayrollUploadFile(payrollUploadClientFileCopy));
      }
    }
  };

  const onSave = () => {
    if (payrollUploadClientFile) {
      let usedOrdinals: number[] = [];
      gridApi1?.forEachNode((node: RowNode) => { return usedOrdinals.push(node.data.fieldOrdinal); },
      );
      usedOrdinals = uniq(usedOrdinals).sort((a, b) => {
        return a - b;
      });

      let valid = true;
      valid =
        valid &&
        usedOrdinals.every((n: number, i: number) => { return n === i; });

      if (!valid) {
        setShowInvalidColNo(true);
        return;
      }

      const payrollUploadClone = structuredClone(payrollUploadClientFile);
      payrollUploadClone.fields = [];
      
      gridApi1?.forEachNode((node: RowNode) => {
        const uploadFieldId = node.data.field.uploadFieldId;
        delete node.data.field;
        const newPayrollUploadField = {
          ...node.data,
          mapId: payrollUploadClientFile.mapId ?? 0,
          uploadFieldId,
        } as PayrollUploadMap;

        payrollUploadClone.fields.push(newPayrollUploadField);

        if (payrollUploadClientFile.mapId) {
          if (!node.data.customUploadMapId) {
            dispatch(
              createPayrollUploadField(newPayrollUploadField),
            );
          } else if (node.data.modified) {
            dispatch(
              updatePayrollUploadField(newPayrollUploadField),
            );
          }
        }
      });

      if (payrollUploadClientFile.mapId) {
        dispatch(
          updatePayrollUploadFile({
            ...payrollUploadClone,
            fields: [],
          }),
        );
      } else {
        dispatch(createPayrollUploadFile(payrollUploadClone));
        setFormIsDirty(false);
      }
      
      dispatch(storeSelectedPayrollUploadFile(payrollUploadClone));
      onHide();
    }
  };

  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="xl"
        title="Step 2 of 2: Upload New Payroll Map"
      >
        {(_closeModal) => {
          return (
            <>
              <div
                className="d-flex justify-content-end mr-3 mb-1"
                onClick={() => { return onAddField(getNextColumnNo(), false); }}
              >
                <button
                  type="button"
                  className="btn btn-link dm-grid-action-title p-0 mr-2"
                >
                  Add New Field{' '}
                  <Icon
                    name="plus-circle"
                    className="fa-plus-circle"
                  />
                </button>
              </div>
              <div className="row">
                <div
                  className="col-12 table-wrapper-wrapper ag-theme-balham"
                  style={{ maxHeight: '300px' }}
                >
                  <div style={{ height: '300px' }}>
                    <AgGridReact
                      gridOptions={gridOptions1}
                      rowData={rowData}
                      modules={AllModules}
                      onGridReady={onGridReady1}
                      onCellValueChanged={onCellValueChanged}
                    />
                  </div>
                </div>
                <div className="col-12 text-right">
                  <button
                    type="button"
                    className="btn btn-link pb-0"
                    onClick={onDeleteSelected}
                  >
                    Delete Selected Items
                    <Icon
                      name="minus-circle"
                      className="fa-minus-circle"
                    />
                  </button>
                </div>
              </div>
              <div className="row mt-3">
                {payrollUploadClientFile?.style === 'F' ? (
                  <div className="col-12">
                    <TextareaGrp
                      name="fileBody"
                      label="FIXED"
                      value={csvFile}
                      style={{ fontFamily: 'monospace' }}
                    />
                  </div>
                ) : (
                  <div
                    className="col-12 table-wrapper-wrapper ag-theme-balham"
                    style={{ maxHeight: '300px' }}
                  >
                    <div style={{ height: '300px' }}>
                      <AgGridReact
                        gridOptions={gridOptions2}
                        rowData={grid2Data}
                        modules={AllModules}
                        getMainMenuItems={getMainMenuItems}
                        onGridReady={onGridReady2}
                      />
                    </div>
                  </div>
                )}
              </div> 
              <div className="row">
                <div className="col-12 text-right">
                  <button
                    type="button"
                    className="btn btn-link"
                    style={{ fontSize: '14px' }}
                    onClick={onPrev}
                  >
                    <Icon name="chevron-left" /> Previous
                  </button>
                  <button
                    type="button"
                    className="btn btn-link"
                    style={{ fontSize: '14px' }}
                    disabled={true}
                  >
                    Next <Icon name="chevron-right" />
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-right">
                  <Button
                    className="orange-button"
                    onClick={onSave}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </>
          );
        }}
      </Modal>
      {
        showTranslations && selectedField && (
          <UploadPayrollTranslationsModal
            customUploadMapId={selectedField.customUploadMapId}
            mapId={selectedField.mapId}
            translations={selectedField.translations}
            show={showTranslations}
            onHide={() => { return setShowTranslations(false); }}
          />
        )
      }
      {
        showExceptions && selectedField && (
          <UploadPayrollExceptionsModal
            customUploadMapId={selectedField.customUploadMapId}
            mapId={selectedField.mapId}
            exceptions={selectedField?.earningCodeExceptions}
            show={showExceptions}
            onHide={() => { return setShowExceptions(false); }}
          />
        )
      }
      {
        showRates && selectedField && (
          <UploadPayrollRatesModal
            customUploadMapId={selectedField.customUploadMapId}
            mapId={selectedField.mapId}
            rates={selectedField.earningCodeRates}
            show={showRates}
            onHide={() => { return setShowRates(false); }}
          />
        )
      }
      {
        showInvalidColNo && (
          <PayrollUpdateInvalidColumnNumbers
            show={showInvalidColNo}
            onHide={() => { return setShowInvalidColNo(false); }}
          />
        )
      }
      {
        showConfirmationModal && (
          <ConfirmationModal
            title="You Have Unsaved Changes"
            message={'Would you still like to close?'}
            show={showConfirmationModal}
            onConfirmed={confirmClose}
            onHide={() => { return setShowConfirmationModal(false); }}
          />
        )
      }
    </>
  );
};
export default UploadPayrollStep2Modal;