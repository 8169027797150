import { SelectGrp } from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import Icon from 'core/components/shared/Icon';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';

type PropTypes = {
  show: boolean;
  onHide: any;
  onContinue: () => void
  setWithholdingState: React.Dispatch<React.SetStateAction<{code: string; description: string;}>>
  clientStateEntities: {id: number | undefined, description: string | undefined, code: string | undefined;}[];
};

const fs: FieldInputSettings = {
  state: { name: 'state', label: 'SELECT STATE FOR WITHHOLDING:', groupClass: 'groupClass65'},
};

const OnboardInvalidStateModal: React.FC<PropTypes> = ({ show, onContinue, onHide, clientStateEntities, setWithholdingState }) => {
  const closeModal = (e: any) => {
    e.stopPropagation();
    onHide();
  };

  useEffect(() => {
    setWithholdingState({code: '', description: ''});
  }, []);

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        size="sm"
        animation={false}
      >
        <div className="modal-header" >
          <div className="dm-card-title">Select State for Withholding</div>
          <button
            className="modal-close-btn"
            onClick={closeModal}
          >
            <Icon name="times" />
          </button>
        </div>
        <Modal.Header>
          <p><b><u>Attention:</u></b> This employee's selected state is currently not set up for this client.</p>
        </Modal.Header>
        <Modal.Body>
          <p>Please select a state for tax withholding from the list of currently set up states below to continue. If you require a new state to be set up click the 'Cancel' button 
            and contact Customer Service at 248.244.3293 for assistance. </p>
        <SelectGrp
          {...fs.state}
          options={clientStateEntities}
          addEmptyText
          onChange={(e: any) => {
            const state = clientStateEntities.find(x => x.id === +e.target.value);
            setWithholdingState({code: state?.code || '', description: state?.description || ''})}
          }
          required
        />
          <div className="row mt-4">
            <div className="col-12 text-right">
              <button
                type="button"
                className="orange-outline-button"
                onClick={closeModal}
              >Cancel</button>
              <button
                type="button"
                className="orange-button ml-2"
                onClick={() => onContinue()}
              >Select State and Continue</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div >
  );
};
export default OnboardInvalidStateModal;

