import React from 'react';
import styles from './form-controls.module.scss';
import { Labels } from './Labels';

const defaultRadioOptions = [
  { value: 'true',
    label: 'yes' },
  { value: 'false',
    label: 'no' },
];

export interface RadioOptions {
  value: string;
  label?: string;
}

export interface RadioYesNoProps {
  id?: string;
  name: string;
  label?: string | string[];
  tallLabel?: boolean;
  groupClass?: string;
  groupStyle?: object;
  errors?: any;
  errorMsg?: string;
  required?: boolean;
  visible?: boolean;
  disabled?: boolean;
  controlled?: boolean;
  checked?: string | number | boolean;
  defaultChecked?: string | number | boolean;
  isVertical?: boolean;
  value?: any;
  radioOptions?: RadioOptions[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioGrp = React.forwardRef<HTMLInputElement, RadioYesNoProps>(
  (
    {
      id,
      name,
      label,
      tallLabel = false,
      groupClass,
      groupStyle,
      errors,
      errorMsg,
      required = false,
      visible = true,
      disabled = false,
      controlled,
      checked,
      defaultChecked,
      isVertical = false,
      radioOptions = defaultRadioOptions,
      onChange,
      ...extraProps
    }: RadioYesNoProps,
    ref: any,
  ) => {
    if (!visible) return null;
    
    const errMsg = errors
      ? errors.type === 'validate' && !errors.message
        ? errorMsg
        : errors.message
      : '';
    const groupClass2 = styles['dm-form-group'] + ' ' + (groupClass ?? '');
    const lastIndex = radioOptions.length - 1;
    id = id ?? name;
    
    return (
      <div
        className={groupClass2}
        style={groupStyle}
      >
        <Labels
          label={label}
          tallLabel={tallLabel}
          id={id}
          hasError={!!errors}
          required={required}
        />
        {radioOptions.map((item: RadioOptions, index: number) => {
          return (
            <label
              key={index}
              htmlFor={id + '' + index}
              style={isVertical ? { display: 'block' } : undefined}
              className={
                index === 0
                  ? styles['radio-label-first']
                  : index === lastIndex
                    ? styles['radio-label-last']
                    : styles['radio-label']
              }
            >
              {controlled ? (
                <input
                  id={id + '' + index}
                  type="radio"
                  name={name}
                  ref={ref}
                  onChange={onChange}
                  className={styles['radio-input']}
                  defaultValue={item.value}
                  disabled={disabled}
                  defaultChecked
                  checked={checked?.toString() === item.value}
                  {...extraProps}
                />
              ) : (
                <input
                  id={id + '' + index}
                  type="radio"
                  name={name}
                  ref={ref}
                  onChange={onChange}
                  className={styles['radio-input']}
                  defaultValue={item.value}
                  disabled={disabled}
                  defaultChecked={
                    defaultChecked?.toString() === item.value
                  }
                  {...extraProps}
                />
              )}{' '}
              {item.label}
            </label>
          );
        })}

        <div>
          <small className="text-danger">{errMsg}</small>
        </div>
      </div>
    );
  },
);

RadioGrp.displayName = 'RadioGrp';