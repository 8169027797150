import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Client, DtoOptions } from '../../core/models';
import { useAppSelector } from '../../utilities/hooks';
import InputReportDateSelector from '../../core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import { NationalAgreementRequest } from '../../core/models/ContractorReports.model';
import { clearNationalAgreementReport, downloadNationalAgreementReport } from '../../core/store/slices/contractorReports.slice';
import { useForm } from 'react-hook-form';
import { RadioGrp } from 'core/components/form-controls';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';

const dateOptions: RadioOptions[] = [
  {
    value: 'ByWeekEnding',
    label: 'By Week End',
  },
  {
    value: 'ByCheckDate',
    label: 'By Check Date',
  },
];

const NationalMaintenanceAgreementReport = () => {

  const dispatch = useDispatch();

  const childRef: React.RefObject<any> = useRef();

  const report = useAppSelector((state) => {return state.contractorReports.nationalAgreementReport;});
  const client = useAppSelector((state) => {return state.client.client;}) as Client;
  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);

  const { handleSubmit, register } = useForm<NationalAgreementRequest>({
    defaultValues: {
      byOption: 'ByWeekEnding',
    },
  });

  const downloadReport = (data: NationalAgreementRequest) => {
    const submitData: NationalAgreementRequest = {
      clientNo: client.clientNo,
      dtoOptions: reportDates,
      byOption: data.byOption,
    };
    dispatch(downloadNationalAgreementReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearNationalAgreementReport());
  };

  return (

    <div className="dm-panel dm-panel-border" >
      <form onSubmit={handleSubmit(downloadReport)}>

        <div className="dm-grid-title">National Maintenance Agreement Report</div>
        <hr className="dm-panel-hr" />

        <div className="d-flex flex-row flex-grow-1" >
          <div>
            <InputReportDateSelector
              ref={childRef}
              returnDates={(dates: DtoOptions[]) => {return setReportDates(dates);}}
            />
          </div>
          <div className="d-flex flex-column flex-grow-1">
            <div className="text-right" >
              <button
                type="button"
                className="orange-outline-button mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="orange-button mr-2"
                disabled={!reportDates.length}>
                Run Report
              </button>
            </div>
            <div
              className="dm-panel dm-panel-border"
              style={{ marginTop: '66px', width: '20%' }}
            >
              <div className="dm-grid-action-title mb-2">
                Options
              </div>
              <div>
                <div className="d-flex">
                  <div className="d-flex flex-row flex-grow-1">
                    <div className="d-flex flex-column flex-grow-1">
                      <RadioGrp
                        isVertical={true}
                        radioOptions={dateOptions}
                        name="byOption"
                        ref={register}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {
        report ? <PDFViewerModal
          show={report.length > 0}
          pdfData={report}
          onHide={hidePdfViewer}
          reportName="NationalAgreementReport"
        /> : null
      }

    </div>


  );

};
export default NationalMaintenanceAgreementReport;


