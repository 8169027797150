export interface IOnboardPayrollUser {
  hrEntityId: number;
  clientID: number;
  clientNo: number;
  clientEntityID: number;
  clientEntityName: string;
  isTestClient: boolean;
  secUserID: number;
  dmUserName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export class OnboardPayrollUser implements IOnboardPayrollUser {
  hrEntityId = 0;

  clientID = 0;

  clientNo = 0;

  clientEntityID = 0;

  clientEntityName = '';

  isTestClient = false;

  secUserID = 0;

  dmUserName = '';

  firstName = '';

  lastName = '';

  emailAddress = '';
}