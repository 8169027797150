import React, { useState } from 'react';
import { SelectGrp } from 'core/components/form-controls';
import Modal from 'core/components/modals/Modal';
import { Employee } from 'core/models';
import { getTen99 } from 'core/store/selectors';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { createEmployee, handleError } from 'core/store/actions';

type Props = {
  employee: Employee;
  show: boolean;
  onHide: () => void;
  onCancel: () => void;
};

const Confirm1099EmpModal = ({ employee, show, onHide, onCancel }: Props) => {
  const ten99Opts = useSelector(getTen99);
  const ten99TaxIdOpts = useAppSelector(({ dropdown }) => dropdown.ten99TaxpayerIdType);
  
  const [ten99LookupId, setTen99LookupId] = useState<string | null>(ten99Opts?.[0].id ?? null);
  const [taxpayerIdType, setTaxpayerIdType] = useState<string | null>(null);
  
  const dispatch = useAppDispatch();
  
  const addEmp = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!ten99LookupId) return dispatch(handleError('1099 Box # is required'));
    
    //Set the 1099 employee info since they will be at this point.
    employee.is1099Employee = true;
    employee.ten99LookupId = +ten99LookupId,
    employee.taxpayerIdType = taxpayerIdType?.length ? +taxpayerIdType : null,

    dispatch(createEmployee({
      employee,
      next: '/employee/detail/{empNo}/payrate',
    })).then((_res) => {
      onHide();
    });    
  };
  
  return (
    <Modal
      title="1099 Employee Information"
      show={show}
      onHide={onHide}
    >
      <div className="d-flex flex-column">
        <span>You must select a 1099 box # before continuing:</span>
        <SelectGrp
          name="1099BoxNo"
          groupClass="w-50"
          options={ten99Opts}
          value={ten99LookupId}
          onChange={(e: any) => { setTen99LookupId(e.target.value); }}
        />
        <span>Taxpayer Identification Type (may be used for electronic filing):</span>
        <SelectGrp
          name="taxpayerIdType"
          groupClass="w-50"
          options={ten99TaxIdOpts}
          value={taxpayerIdType}
          onChange={(e: any) => { setTaxpayerIdType(e.target.value); }}
          addEmptyValue={true}
        />
        <div className="d-flex justify-content-end">
          <button
            className="btn orange-outline-button-sm mr-2"
            onClick={(e) => {
              e.preventDefault();
              onCancel();
              onHide();
            }}
          >
            Cancel
          </button>
          <button
            className="btn orange-button-sm"
            onClick={addEmp}
            disabled={!ten99LookupId}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default Confirm1099EmpModal;