import { ColDef, GridOptions } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import React, { useEffect, useRef, useState } from 'react';
import { agDateRenderer } from '../../../utilities/ag-grid-renderers';
import { useAppDispatch, useAppSelector } from '../../../utilities/hooks';
import {
  EmpHistory,
  HistoryRecord,
  HistoryRequest,
} from '../../models/HREmployee.model';
import {
  clearEmployeeFieldHistory,
  loadEmployeeFieldHistory,
} from '../../store/actions/hr-employee.action';
import Modal from './Modal';

type PropTypes = {
  show: boolean;
  protectedEmpNo: string;
  section: string;
  field: string;
  onHide: () => void;
};
const HrFieldHistoryModal: React.FC<PropTypes> = ({
  show,
  protectedEmpNo,
  section,
  field,
  onHide,
}) => {
  const dispatch = useAppDispatch();

  const gridRef = useRef<AgGridReact>(null);

  const [rowData, setRowData] = useState<HistoryRecord[]>([]);
  const [fieldHistory, setFieldHistory] = useState<EmpHistory | null>(null);
  const [showFieldHistory, setShowFieldHistory] = useState<boolean>(false);


  const currentFieldHistory = useAppSelector(
    (state) => { return state.hrEmployeeInfo.employeeFieldHistory; },
  );

  const [colDefs] = useState<ColDef[]>([
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      editable: false,
      cellRenderer: 'dateRenderer',
      width: 130,
      cellStyle: {
        overflow: 'visible !important',
        textAlign: 'right',
      },
    },
    {
      field: 'value',
      headerName: fieldHistory?.fieldName,
      editable: false,
      width: 130,
    },
  ]);

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    components: {
      dateRenderer: agDateRenderer,
    },
  };

  useEffect(() => {
    if (!currentFieldHistory) {
      return;
    }

    setRowData(currentFieldHistory.historyRecords);
    setFieldHistory(currentFieldHistory);
    setShowFieldHistory(true);

    colDefs[1].headerName = currentFieldHistory?.fieldName;
    if (gridRef.current) {
      gridRef.current.api.setColumnDefs(colDefs);
    }
  }, [currentFieldHistory]);

  useEffect(() => {
    if (protectedEmpNo && section && field) {
      const request: HistoryRequest = {
        protectedEmpNo,
        section,
        field,
      };
      dispatch(loadEmployeeFieldHistory(request));
    }
  }, [protectedEmpNo, section, field]);

  const hideModal = () => {
    dispatch(clearEmployeeFieldHistory());
    onHide();
  };

  const closeModal = (e: any) => {
    e.stopPropagation();
    hideModal();
  };

  return (
    <Modal
      show={show}
      onHide={hideModal}
      dialogClassName="modal-xs"
      title={`${fieldHistory?.fieldName} History`}
    >
      {showFieldHistory &&
        <>
          <div className="row">
            {rowData.length ? (
              <div className="col-12 table-wrapper-wrapper ag-theme-balham">
                <AgGridReact
                  gridOptions={gridOptions}
                  rowData={rowData}
                  columnDefs={colDefs}
                  ref={gridRef} />
              </div>
            ) : (
              <span>No History for this field</span>
            )}
          </div>
          <div className="row">
            <div className="col-12 text-right">
              <button
                type="button"
                className="btn btn-primary orange-button mr-2 mt-2"
                onClick={closeModal}
              >
                Okay
              </button>
            </div>
          </div>
        </>
      }
    </Modal>
  );
};
export default HrFieldHistoryModal;
