import { createAction } from '@reduxjs/toolkit';
import { EmpGroupTerm } from '../../models';

export const GTL_EM_VALIDATION_ACTIONS = {
  UPDATE_EMPLOYEE_GROUP_TERM: '[emp-group-term] UPDATE_EMPLOYEE_GROUP_TERM',
} as const;

export const loadEmployeeGroupTerm = createAction<{ protectedEmpNo: string }>('[emp-group-term] LOAD_EMPLOYEE_GROUP_TERM');
export const storeEmployeeGroupTerm = createAction<EmpGroupTerm>('[emp-group-term] STORE_EMPLOYEE_GROUP_TERM');
export const updateEmployeeGroupTerm = createAction<EmpGroupTerm>('[emp-group-term] UPDATE_EMPLOYEE_GROUP_TERM');
