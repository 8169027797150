import React, { useState } from 'react';
import Modal from 'core/components/modals/Modal';

type Props = {
  show: boolean;
  onHide: () => void;
};

const NoteSavedModal = ({
  show,
  onHide,
}: Props) => {

  return (
    <Modal
      title="Note Saved"
      show={show}
      onHide={onHide}
    >
      {(closeModal) => {
        return (
          <>
            <p>Your data has been successfully saved.</p>
            <div
              className="d-flex align-items-center justify-content-end"
              style={{ gap: '0.5rem' }}
            >
              <button
                className="btn btn-primary orange-button"
                onClick={closeModal}
              >
                Okay
              </button>
            </div>
          </>
        );
      }}
    </Modal>
  );
};

export default NoteSavedModal;