import React, { useEffect, useState } from 'react';
import { DirectDepositChangeLog } from 'core/models/MyInfoChangeLog';
import { SelectionChangeType } from './MyInfoChanges';
import DirectDepositChangeTable from './DirectDepositChangeTable';
import { useSelector } from 'react-redux';
import { getSelectedDirectDepositChange, getSelectedEmp } from 'core/store/selectors';
import { CheckboxGrpInLine } from 'core/components/form-controls';
import { useAppDispatch } from 'utilities/hooks';
import { clearSelectedChanges } from 'core/store/actions';

type PropTypes = {
  directDepositChange: DirectDepositChangeLog | null;
  onSelectionChanged: (type: SelectionChangeType, selection: any[]) => void;
};

const DirectDepositChangeLogComponent: React.FC<PropTypes> = ({ directDepositChange, onSelectionChanged }) => {
  const directDepositChanges = useSelector(getSelectedDirectDepositChange);
  const currentEmp = useSelector(getSelectedEmp);
  
  const [checked, setChecked] = useState<boolean>(false);
  
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    setChecked(!!directDepositChanges?.length);
  }, [directDepositChanges?.length]);
  
  useEffect(() => {
    setChecked(false);
    dispatch(clearSelectedChanges({
      directDepositChange: [],
      employeeChanges: [],
      taxChanges: [],
    }));
  }, [currentEmp?.empNo]);
  
  const selectionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setChecked(true);
      onSelectionChanged('DIRECTDEPOSIT', directDepositChange?.changes ?? []);
    } else {
      setChecked(false);
      onSelectionChanged('DIRECTDEPOSIT', []);
    }
  };
  
  return (
    <div className="d-flex flex-column">
      <CheckboxGrpInLine
        name="selectAllDdChanges"
        label="Select All"
        labelWidth={150}
        labelFirst={false}
        value={checked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { selectionChanged(e); }}
      />
      {directDepositChange?.changes?.map((change, index) => {
        return (
          <DirectDepositChangeTable
            key={change.directDepositChangeLogId}
            change={change}
            index={index}
          /> 
        );          
      })}
    </div>
  );
};

export default DirectDepositChangeLogComponent;
