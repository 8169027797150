import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  OhioI29Request,
  Week_Month_JTD_Job_Cost_Report_Request,
  JobDetailRequest,
  FringeReportRequest,
  WH347_Certified_Report_Request,
  ApprovedJobsReportRequest,
  BrickLayerCementMasonRate,
  ElectricalFringeReportRequest,
  WorkersCompenationReportRequest,
  NationalAgreementRequest,
  EEO_CC257Request,
  JobGrossSummaryReportRequest,
  JobLaborRequest,
  JobToDateGrossSummaryReportRequest,
  EmployeeTimeCardReqest,
  RateMasterRequest,
  RateMaster,
  OCIPReportRequest,
  EmployeeGrossSummaryReportRequest,
}
  from 'core/models/ContractorReports.model';
export interface ContractorReportsState {
  loading: boolean;
  report: string;
  ohioI29Report: string;
  wh347CertifiedReport: string;
  weekMonthJTDJobCostReport: string;
  jobDetailReport: string;
  approvedJobsReport: string;
  brickLayerCementMasonRate: BrickLayerCementMasonRate | null;
  fringeReport: string;
  electricalFringeReport: string;
  workersCompenationReport: string;
  nationalAgreementReport: string;
  eeoCC257: string;
  jobGrossSummaryReport: string;
  jobLaborReport: string;
  jobToDateGrossJobSummary: string;
  employeeTimeCardReport: string;
  rateMaster: RateMaster[];
  rateMasterReport: string;
  ocipReport: string;
  employeeGrossSummaryReport: string;
}

const initialState: ContractorReportsState = {
  loading: false,
  report: '',
  ohioI29Report: '',
  wh347CertifiedReport: '',
  weekMonthJTDJobCostReport: '',
  jobDetailReport: '',
  fringeReport: '',
  approvedJobsReport: '',
  brickLayerCementMasonRate: null,
  electricalFringeReport: '',
  workersCompenationReport: '',
  nationalAgreementReport: '',
  eeoCC257: '',
  employeeTimeCardReport: '',
  rateMaster: [],
  rateMasterReport: '',
  jobGrossSummaryReport: '',
  jobLaborReport: '',
  jobToDateGrossJobSummary: '',
  ocipReport: '',
  employeeGrossSummaryReport: '',
};

export const contractorReportsSlice = createSlice({
  name: 'contractorReports',
  initialState,
  reducers: {
    downloadOhioI29Report: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: OhioI29Request) => {
        return {
          payload: request,
        };
      },
    },
    storeOhioI29Report: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.ohioI29Report = action.payload;
    },
    clearOhioI29Report: (state) => {
      state.ohioI29Report = '';
    },
    downloadWeekMonthJTDJobCostReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: Week_Month_JTD_Job_Cost_Report_Request) => {
        return {
          payload: request,
        };
      },
    },
    storeWeekMonthJTDJobCostReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.weekMonthJTDJobCostReport = action.payload;
    },
    clearWeekMonthJTDJobCostReport: (state) => {
      state.weekMonthJTDJobCostReport = '';
    },
    downloadWH347CertifiedReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: WH347_Certified_Report_Request) => {
        return {
          payload: request,
        };
      },
    },
    storeWH347CertifiedReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.wh347CertifiedReport = action.payload;
    },
    clearWH347CertifiedReport: (state) => {
      state.wh347CertifiedReport = '';
    },
    downloadJobDetailReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: JobDetailRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeJobDetailReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.jobDetailReport = action.payload;
    },
    clearJobDetailReport: (state) => {
      state.jobDetailReport = '';
    },
    downloadApprovedJobsReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: ApprovedJobsReportRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeApprovedJobsReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.approvedJobsReport = action.payload;
    },
    clearApprovedJobsReport: (state) => {
      state.approvedJobsReport = '';
    },
    getBrickLayerRates: (state) => {
      state.loading = true;
    },
    storeBrickLayerRates: (state, action: PayloadAction<BrickLayerCementMasonRate>) => {
      state.loading = false;
      state.brickLayerCementMasonRate = action.payload;
    },
    downloadFringeReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: FringeReportRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeFringeReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.fringeReport = action.payload;
    },
    clearFringeReport: (state) => {
      state.fringeReport = '';
    },
    downloadElectricalFringeReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: ElectricalFringeReportRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeElectricalFringeReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.electricalFringeReport = action.payload;
    },
    clearElectricalFringeReport: (state) => {
      state.electricalFringeReport = '';
    },
    downloadNationalAgreementReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: NationalAgreementRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeNationalAgreementReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.nationalAgreementReport = action.payload;
    },
    clearNationalAgreementReport: (state) => {
      state.nationalAgreementReport = '';
    },

    downloadWorkersCompenationReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: WorkersCompenationReportRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeWorkersCompenationReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.workersCompenationReport = action.payload;
    },
    clearWorkersCompenationReport: (state) => {
      state.workersCompenationReport = '';
    },
    downloadEEOCC257Report: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: EEO_CC257Request) => {
        return {
          payload: request,
        };
      },
    },
    storeEEOC257Report: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.eeoCC257 = action.payload;
    },
    clearEEOC257Report: (state) => {
      state.eeoCC257 = '';
    },
    downloadJobGrossSummaryReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: JobGrossSummaryReportRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeJobGrossSummaryReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.jobGrossSummaryReport = action.payload;
    },
    clearJobGrossSummaryReport: (state) => {
      state.jobGrossSummaryReport = '';
    },
    downloadJobLaborReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: JobLaborRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeJobLaborReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.jobLaborReport = action.payload;
    },
    clearJobLaborReport: (state) => {
      state.jobLaborReport = '';
    },
    downloadJobToDateGrossSummaryReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: JobToDateGrossSummaryReportRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeJobToDateGrossSummaryReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.jobToDateGrossJobSummary = action.payload;
    },
    clearJobToDateGrossSummaryReport: (state) => {
      state.jobToDateGrossJobSummary = '';
    },
    downloadEmployeeTimeCardReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: EmployeeTimeCardReqest) => {
        return {
          payload: request,
        };
      },
    },
    storeEmployeeTimeCardReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.employeeTimeCardReport = action.payload;
    },
    clearEmployeeTimeCardReport: (state) => {
      state.employeeTimeCardReport = '';
    },
    getRateMaster: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: RateMasterRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeRateMaster: (state, action: PayloadAction<RateMaster[]>) => {
      state.loading = false;
      state.rateMaster = action.payload;
    },
    downloadRateMasterReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: RateMasterRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeRateMasterReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.rateMasterReport = action.payload;
    },
    clearRateMasterReport: (state) => {
      state.rateMasterReport = '';
    },
    downloadOCIPReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: OCIPReportRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeOCIPReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.ocipReport = action.payload;
    },
    clearOCIPReport: (state) => {
      state.ocipReport = '';
    },
    downloadEmployeeGrossSummaryReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: EmployeeGrossSummaryReportRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeEmployeeGrossSummaryReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.employeeGrossSummaryReport = action.payload;
    },
    clearEmployeeGrossSummaryReport: (state) => {
      state.employeeGrossSummaryReport = '';
    },
  },
});

export const {
  downloadOhioI29Report,
  storeOhioI29Report,
  clearOhioI29Report,
  downloadWH347CertifiedReport,
  storeWH347CertifiedReport,
  clearWH347CertifiedReport,
  downloadWeekMonthJTDJobCostReport,
  storeWeekMonthJTDJobCostReport,
  clearWeekMonthJTDJobCostReport,
  downloadJobDetailReport,
  storeJobDetailReport,
  clearJobDetailReport,
  downloadApprovedJobsReport,
  storeApprovedJobsReport,
  clearApprovedJobsReport,
  getBrickLayerRates,
  storeBrickLayerRates,
  downloadFringeReport,
  storeFringeReport,
  clearFringeReport,
  downloadElectricalFringeReport,
  storeElectricalFringeReport,
  clearElectricalFringeReport,
  downloadNationalAgreementReport,
  storeNationalAgreementReport,
  clearNationalAgreementReport,
  downloadWorkersCompenationReport,
  storeWorkersCompenationReport,
  clearWorkersCompenationReport,
  downloadEEOCC257Report,
  storeEEOC257Report,
  clearEEOC257Report,
  downloadJobGrossSummaryReport,
  storeJobGrossSummaryReport,
  clearJobGrossSummaryReport,
  downloadJobLaborReport,
  storeJobLaborReport,
  clearJobLaborReport,
  downloadJobToDateGrossSummaryReport,
  storeJobToDateGrossSummaryReport,
  clearJobToDateGrossSummaryReport,
  downloadEmployeeTimeCardReport,
  storeEmployeeTimeCardReport,
  clearEmployeeTimeCardReport,
  getRateMaster,
  downloadRateMasterReport,
  storeRateMaster,
  storeRateMasterReport,
  clearRateMasterReport,
  downloadOCIPReport,
  storeOCIPReport,
  clearOCIPReport,

  downloadEmployeeGrossSummaryReport,
  storeEmployeeGrossSummaryReport,
  clearEmployeeGrossSummaryReport,
} = contractorReportsSlice.actions;
export default contractorReportsSlice.reducer;
