import { CheckboxGrpInLine, SelectGrp } from 'core/components/form-controls';
import { UserMapSelectedField, UserMapFont, fontSizeeOpts, fontStyleOpts, AvailableFont } from 'core/models/Downloader.model';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Icon from 'core/components/shared/Icon';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'utilities/hooks';

type PropTypes = {
  isHeaderFont: boolean;
  show: boolean;
  onHide: () => void;
  setFont: (font: UserMapFont, selectedAreaId?: number) => void;
  field?: UserMapSelectedField;
  font?: UserMapFont;
};

const SetFontModal: React.FC<PropTypes> = ({ isHeaderFont, show, onHide, setFont, field, font }) => {
  const availableFonts = useAppSelector((state) => { return state?.downloader.availableFonts; });
  const fontDefault = (font) ? font : {font: 'Calibri', fontStyle: 'Regular', fontSize: 11, underline: false, effects: false}
  const { register, handleSubmit, reset } = useForm<UserMapFont>({
    defaultValues: (isHeaderFont && field?.font) ? field?.font : fontDefault
  });

  const fonts = availableFonts?.map((f: AvailableFont) => {
    return {
      id: f.fontName,
      description: f.fontName,
    };
  });

  const onSubmit = (data: UserMapFont) => {
    setFont({
      ...data,
      fontSize: +(data.fontSize ? data.fontSize : 8),
    }, field?.selectedAreaId ?? 0);
    onHide();
  };

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        size="sm"
        animation={false}
      >
        <div className="modal-header" >
          <div className="dm-card-title">Set {isHeaderFont ? 'Header' : 'Download'} Font</div>
          <button
            type="button"
            onClick={closeModal}
          >
            <Icon
              name="times"
              className="modal-close-btn"
            />
          </button>
        </div>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="dm-panel dm-panel-border">
              <div className="d-flex flex-row justify-content-between">
                {availableFonts ? <div className="flex-grow-1 mr-4 text-center">
                  <SelectGrp
                    name="font"
                    options={fonts}
                    placeholder="Font"
                    defaultValue={isHeaderFont ? field?.font?.font : font?.font}
                    ref={register()}
                  />
                </div> : null}
                <div className="flex-grow-1 mr-4 text-center">
                  <SelectGrp
                    name="fontStyle"
                    options={fontStyleOpts}
                    placeholder="Style"
                    defaultValue={isHeaderFont ? field?.font?.fontStyle : font?.fontStyle}
                    ref={register()}
                  />
                </div>
                <div className="flex-grow-1 text-center">
                  <SelectGrp
                    name="fontSize"
                    options={fontSizeeOpts}
                    placeholder="Size"
                    defaultValue={isHeaderFont ? field?.font?.fontSize : font?.fontSize}
                    ref={register()}
                  />
                </div>
              </div>
              <div className="d-flex flex-row">
                <div>
                  <div>Effects</div>
                  <div className="d-flex flex-row">
                    {/* <label className="mr-3">
                      <input
                        name="effects"
                        type="checkbox"
                        defaultChecked={ isHeaderFont ? field?.font?.underline : font?.underline}
                        ref={register()}
                      />
                      <span
                        className="blue-image-link"
                        aria-hidden="true"
                      >
                        {' '}Strikethrough
                      </span>
                    </label> */}
                    <label>
                      <input
                        name="underline"
                        type="checkbox"
                        defaultChecked={isHeaderFont ? field?.font?.effects : font?.effects}
                        ref={register()}
                      />
                      <span
                        className="blue-image-link"
                        aria-hidden="true"
                      >
                        {' '}Underline
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              {isHeaderFont ? <div>
                <CheckboxGrpInLine
                  name="applyAll"
                  ref={register()}
                  labelFirst={false}
                  label="Apply header format to all column headers"
                />
              </div> : null}
            </div>
            <div className="d-flex justify-content-end mt-2 mb-2">
              <div className="d-flex flex-row">
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button mr-2"
                  onClick={() => { closeModal(); }}
                >Cancel</button>
                <button
                  type="submit"
                  className="btn btn-primary orange-button"
                >Save</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>);
};

export default SetFontModal;