import { createReducer } from '@reduxjs/toolkit';
import { CheckCode } from '../../models';
import { loadCheckCodes, storeCheckCodes } from '../actions';

export interface State {
  loading: boolean;
  checkCode: CheckCode[];
}

export const INITIAL_STATE: State = {
  loading: false,
  checkCode: [],
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadCheckCodes.type]: (state) => {return { ...state, loading: true };},
  [storeCheckCodes.type]: (state, action) => {return { ...state, loading: false, checkCode: action.payload };},
});