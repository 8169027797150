import React, { useEffect, useContext, useState, useRef } from 'react';
import { TransmittalCheck, TransmittalEmployeeCheckParams, TransmittalEmployee, TransmittalEarning, TransmittalDeduction } from 'core/models';
import { clearTransmittalEmployee, deleteTransmittalEmployeeCheck, handleError,  updateTransmittalEmployeeCheck } from 'core/store/actions';
import { ArrayField, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import TransmittalDeductionsItemList from './TransmittalDeductionsItemList';
import TransmittalEarningsItemsList from './TransmittalEarningsItemsList';
import Icon from 'core/components/shared/Icon';
import { useSelector } from 'react-redux';
import { getShowAddtl } from 'core/store/selectors';
import { stringToBool } from 'utilities/classUtils';
import { FormContext, FormContextType } from './FormContext';
import { formatWithCommas } from 'utilities/utilities';
import { Alert } from 'react-bootstrap';

export type ManualChangeArgs = {
  key: string;
  idKey: 'transmittalEarningsId' | 'transmittalDeductionId';
  id: string | number;
  newValue: string | number | boolean | null;
};

type Props = {
  employee: Partial<ArrayField<TransmittalEmployee, 'id'>>;
  check: Partial<ArrayField<TransmittalCheck, 'id'>>;
  payrollHistoryId: number;
  controlTotalId: number;
  checkIndex: number;
  index: number;
  empNo: number;
  isReadOnly: boolean;
};

const CheckItem = ({
  employee,
  check,
  payrollHistoryId,
  controlTotalId,
  checkIndex,
  index,
  empNo,
  isReadOnly,
}: Props) => {
  const dispatch = useAppDispatch();
 
  const showAddtlFields = useSelector(getShowAddtl);

  const {
    newTransmittalEmpNo,
    mostRecentlyUpdatedCheck,
    savingCheck,
    savingFromModal,
  } = useAppSelector(({ payroll }) => { return payroll; });
  
  const {
    formInfo,
    activeElement,
    dirtyCheck,
    setDirtyCheck,
    manualSaveTriggerCallback,
  } = useContext<FormContextType>(FormContext);
  
  const {
    register,
    setValue,
    control,
    errors,
    getValues,
    watch,
    setError,
    reset,
    formState: { isDirty, dirtyFields, errors: formStateErrors },
  } = useForm<TransmittalCheck>({ defaultValues: check });
  
  const shouldSaveForm = isDirty || Object.keys(dirtyFields)?.length;
  
  const checkErrors = watch('checkErrors');

  const [addingDeduction, setAddingDeduction] = useState(false);

  /* ref to the ID of whichever element was focused before a save so we can refocus it.
  Here's the thing: this is set based on the context value. We have the activeElement
  all the way up the component tree because the page can only focus one input at a time.
  However, with how state updates work, once it gets to the refocus function below it 
  uses a stale value. So we keep it in a ref locally so that it updates in sync with the
  context and then we can use THAT value in the refocus closure rather than the old one.
 */
  const activeRef = useRef<string | null>(activeElement);
  
  const savingFromModalRef = useRef<boolean>(false);
  
  useEffect(() => {
    // every time that context value changes, update the ref. The ref will persist between renders.
    activeRef.current = activeElement;
  }, [activeElement]);
  
  useEffect(() => {
    if (!(isDirty && formInfo.triggerSave) || addingDeduction) return;
    saveChanges(true);
    setDirtyCheck(false);
  }, [isDirty, formInfo.triggerSave]);
  
  useEffect(() => {
    if (!savingFromModal) {
      savingFromModalRef.current = false;
    } else {
      savingFromModalRef.current = true;
    }
  }, [savingFromModal, savingCheck]);
  
  useEffect(() => {
    if (!savingFromModalRef.current) return;
    const resetTimeout = setTimeout(() => {
      reset(check);
      savingFromModalRef.current = false;
    }, (0));
    
    return () => clearTimeout(resetTimeout);
  }, [check]);
  
  const onDeleteCheck = () => {
    if (!(employee.protectedEmpNo)) throw new Error('ERROR: No protected employee number found');

    const checkParams: TransmittalEmployeeCheckParams = {
      payrollHistoryId,
      controlTotalId,
      protectedEmpNo: employee.protectedEmpNo,
      transmittalCheckId: check?.transmittalCheckId || 0,
    };

    dispatch(deleteTransmittalEmployeeCheck(checkParams));
  };
  
  /**
   * Transforms form check earnings with any additional fields (IDs, etc.) or custom/formatted values.
   * @param earnings the form check earning items
   * @param args arguments for specific fields to update with custom values on the earning items.
   * @returns the tranformed earning items
   */
  const transformEarnings = (earnings: TransmittalEarning[], args?: ManualChangeArgs) => {
    return earnings?.map((earning: TransmittalEarning) => {
      // doing it this way only allows for one additional argument to change, but that's how onSave works by default
      if (args?.idKey === 'transmittalEarningsId' && args?.id === earning.transmittalEarningsId) {
        return {
          ...earning,
          altRate: +earning.altRate.toFixed(4),
          tracked: !!stringToBool(earning.tracked),
          shiftPremiumId: earning.shiftPremiumId === 0 ? null : earning.shiftPremiumId,
          [args.key! as keyof TransmittalEarning]: args.newValue, 
        };
      }
      return {
        ...earning,
        transmittalEarningsId: (earning.transmittalEarningsId && earning.transmittalEarningsId < 0) ? 0 : earning.transmittalEarningsId,
        tracked: !!stringToBool(earning.tracked),
        //PI-8575 If the rateId is 0 (Alt Rate Selected) then set the altRate else set the alt rate to 0.
        altRate: (earning.rateId === 0) ? +earning.altRate.toFixed(4) : 0,
        shiftPremiumId: earning.shiftPremiumId === 0 ? null : earning.shiftPremiumId,
      };
    });
  };
  
  /**
   * Transforms form check deductions with any additional fields (IDs, etc.) or custom/formatted values.
   * @param deductions the form check deduction items
   * @param args arguments for specific fields to update with custom values on the deduction items.
   * @returns the tranformed deduction items
   */
  const transformDeductions = (deductions: TransmittalDeduction[], args?: ManualChangeArgs) => {
    return deductions?.map((deduction: TransmittalDeduction) => {
      if (args?.idKey === 'transmittalDeductionId' && args?.id === deduction.transmittalDeductionId) {
        return {
          ...deduction,
          overridedDeduction: !!stringToBool(deduction.overridedDeduction),
          [args.key! as keyof TransmittalDeduction]: args.newValue, 
        };      
      }
      return {
        ...deduction,
        overridedDeduction: String(deduction.overridedDeduction) !== '' && String(deduction.overridedDeduction) !== 'false',
      };
    });
  };
  
  /**
   * Determines which element should be refocused after a save based on its ID. Element IDs are in the form
   * [fieldName]-[id], so the function looks at the second half of this string for the transmittalEarningId
   * @example ```
    // function will look at 207184 as the ID to find the element by. In practice this'll be something like earning.transmittalEarningId.
    const someElementId = 'amount-207184';
    ```
   */
  const refocus = () => {
    // refocus the element from before the save (sigh)
    // in here we use the local ref. Go to activeRef's definition for the reasoning
    if (!activeRef.current) return;
      
    const active = document.getElementById(activeRef.current);
    if (!active) return console.error(`Could not restore focus to element ${activeRef.current}`);
      
    // throw in a timeout so this executes last.
    setTimeout(() => {
      active.focus();
    }, 1);
  };
  
  // TODO: fix types here
  const resetCheck = (updatedCheck: any) => {
    if (updatedCheck.transmittalCheckId === check.transmittalCheckId) {
      reset(updatedCheck);
    }
  };
  
  /**
   * Saves a transmittal check. Combines the form's check values with the check from props so that we include all fields.
   * @param unregisteredInput 
   * @param args 
   * @returns Promise from the check PUT to use in other functions, or undefined if we shouldn't be saving.
   * @example ```
     // since we return Promise OR undefined, use optional chaining here (?).
     saveChanges(true)?.then((res) => { doSomethingOnSuccess(); });
   ```
   */
  const saveChanges = (unregisteredInput?: boolean, args?: ManualChangeArgs): Promise<any> | undefined => {
    if (isReadOnly || !(unregisteredInput || shouldSaveForm)) return;
    //This will make sure the save does not happen if its a finished payroll
    if (!employee.protectedEmpNo) throw new Error('ERROR: No protected employee number found');
    
    // get check values from form
    const formCheck = getValues();
    
    // Prevent the case where a user adds two earnings items and doesn't add earnings code to one
    //PI-8597 We only want to add this case when there is no earnings code and the amount is not 0
    const earningWithoutCode = formCheck?.earnings?.findIndex((earning) => { return (earning.earningsCode === '' && earning.amount !== 0); });
    if (earningWithoutCode > -1) {
      dispatch(handleError(`Missing earnings code on check #${checkIndex + 1} for employee ${employee.empNo}`));
      setError(`earnings[${earningWithoutCode}].earningsCode`, {
        type: 'validate',
        message: 'Enter an earnings code',
      });
      return;
    }
    
    // TODO: util fn similar to convToClass
    const earningsConvert = transformEarnings(formCheck?.earnings, args);
    const deductionsConvert = transformDeductions(formCheck.deductions, args);
    
    formCheck.earnings = earningsConvert;
    formCheck.deductions = deductionsConvert;
    
    const checkParams: TransmittalEmployeeCheckParams = {
      payrollHistoryId,
      controlTotalId,
      protectedEmpNo: employee.protectedEmpNo,
      transmittalCheckId: formCheck.transmittalCheckId as number,
    };
    
    if (formCheck?.transmittalCheckId === undefined) return;
    
    // combine props check with form check
    const mergedCheck = {
      ...check,
      ...formCheck,
    };
    
    // remove the id added to the object by the form
    delete mergedCheck?.id;
      
    // return this Promise and execute methods in .finally once it's completed (whether fulfilled or rejected)
    return dispatch(updateTransmittalEmployeeCheck({
      protectedEmpNo: employee.protectedEmpNo,
      params: checkParams,
      data: mergedCheck,
      showSuccess: false,
    }))
      .then((res) => {
        // TODO: fix type here
        resetCheck((res.payload as any).value);
      })
      .finally(() => {
        refocus(); // refocus the input
        setDirtyCheck(false); // no more dirty check
        manualSaveTriggerCallback(false); // don't autosave in any special cases
      });
  };
  
  useEffect(() => {
    if (!(newTransmittalEmpNo && newTransmittalEmpNo === employee?.empNo) || mostRecentlyUpdatedCheck?.transmittalCheckId === 0) return;
    saveChanges(true);
    dispatch(clearTransmittalEmployee());
  }, [newTransmittalEmpNo]);
  
  const mappedPayRateDropdown = check.payRateDropdown?.map((item) => {
    if (!isNaN(+item.hourlyDescription)) {
      return {
        ...item,
        hourlyDescription: formatWithCommas(item.hourlyDescription, 6),
        salaryDescription: formatWithCommas(item.salaryDescription),
      };
    }
    return item;
  }) ?? [];
  
  return (
    <div
      key={check.id}
      id={`${check.transmittalCheckId}`}
      className="border shadow-sm p-3 mb-3"
    >
      <div className="row mb-1 justify-content-between">
        <div
          className="col font-weight-bold mb-1"
          style={{ maxWidth: '29%' }} // description header width - 1%
        >
          Check #: {checkIndex + 1}
        </div>
        {showAddtlFields ? (
          <>
            <div className="col-sm-2 mb-1"></div>
            <div className="col-sm-2 mb-1"></div>
            <div className="col font-weight-bold mb-1 ml-2">Track</div>
            <div className="col-sm-2 mb-1"></div>
            <div className="col-sm-2 mb-1"></div>
          </>
        ) : (
          <div className="col font-weight-bold mb-1 ml-2">
            Track
          </div>
        )}
      </div>
      {checkErrors && checkErrors?.filter(x => x.transmittalCheckId === check.transmittalCheckId)?.length ? 
        <Alert variant="danger">
          {checkErrors[0].errorMessage}
        </Alert> : null}
      <form>
        <input
          type="hidden"
          name={'transmittalCheckId'}
          ref={register({ valueAsNumber: true })}
          defaultValue={check.transmittalCheckId}
        />
        <TransmittalEarningsItemsList
          formStateErrors={formStateErrors}
          setError={setError}
          empNo={employee.empNo ?? 0}
          index={index}
          checkIndex={checkIndex}
          checkId={check.transmittalCheckId}
          errors={errors}
          register={register}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          control={control}
          isReadOnly={isReadOnly}
          payRateDropdown={mappedPayRateDropdown}
          saveChanges={saveChanges}
          employeeShiftPremiumId={employee?.employeeShiftPremiumId}
        />
        <TransmittalDeductionsItemList
          isDirty={isDirty}
          isReadOnly={isReadOnly}
          checkIndex={checkIndex}
          errors={errors}
          register={register}
          control={control}
          empNo={empNo}
          setValue={setValue}
          saveChanges={saveChanges}
          watch={watch}
          addingDeduction={addingDeduction}
        />
      </form>
      {!isReadOnly ? <div className="row d-flex justify-content-end px-3">
        <button
          type="button"
          disabled={isReadOnly}
          className="btn btn-link dm-grid-action-title float-right"
          onClick={onDeleteCheck}
        >
          Delete Check <Icon
            name="minus-circle"
            className="fa-minus-circle"
          />
        </button>
      </div> : null }
    </div>
  );
};

export default CheckItem;
