import React from 'react';
import Icon from 'core/components/shared/Icon';
import { useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Employee,
  PayrollsUserOptions,
  TransmittalDeduction,
} from 'core/models';
import { getIsFinishedPayroll } from 'core/store/selectors';
import TransmittalModalDeductionsItem from './TransmittalModalDeductionsItem';
import { FormMethods } from 'core/components/form-controls/types';

type Props = {
  checkIndex: number;
  control: any;
  errors: FormMethods['errors'];
  isReadOnly: boolean;
  register: FormMethods['register'];
  watch: FormMethods['watch'];
  setValue: FormMethods['setValue'];
  payrollsUserOptions: PayrollsUserOptions | null;
  employee: Employee;
};

const TransmittalModalDeductions = ({
  checkIndex,
  control,
  errors,
  isReadOnly,
  register,
  watch,
  setValue,
  employee,
}: Props) => {
  const { fields, remove, append } = useFieldArray<TransmittalDeduction>({
    control,
    name: `checks[${checkIndex}].deductions`,
  });


  return (
    <>
      <div className="text-right mt-2 mb-2">
        {!isReadOnly ? <button
          type="button"
          className="btn btn-link dm-grid-action-title p-0"
          onClick={() => {
            append({
              dedNo: 0,
              dedAmount: 0,
              transmittalDeductionId: -1,
              dept: employee?.dept ?? 0,
              subDept: employee?.subDept ?? 0,
              subDept2: employee?.subDept2 ?? 0,
            });
          }}
        >
          Add Deduction <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button> : null}
      </div>
      <hr />
      {fields.map((deduction, deductionIndex) => {
        return (
          <div
            key={deduction.id}
            className="border-bottom mb-3"
          >
            <TransmittalModalDeductionsItem
              deduction={deduction}
              deductionIndex={deductionIndex}
              checkIndex={checkIndex}
              isReadOnly={isReadOnly}
              register={register}
              errors={errors}
              remove={remove}
              watch={watch}
              setValue={setValue}
            />
          </div>
        );
      })}
    </>
  );
};

export default TransmittalModalDeductions;
