import Modal from 'core/components/modals/Modal';
import React from 'react';

type PropTypes = {
  weekEnd: string;
  checkDate: string;
  show: boolean;
  onHide: () => void;
  onConfirmed: (confirmed: boolean) => void;
};

const VerifyUploadedAdjustmentModal: React.FC<PropTypes> = ({ weekEnd, checkDate, show, onHide, onConfirmed }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Verify Uploaded Adjustment"
    >
      {(closeModal) => {
        return (
          <>
            <div className="text-center m-2 p-3"
              style={{ backgroundColor: '#FCE6E5' }}>
              Do you want to validate the adjusment for:<br />
              <strong>Week End: {weekEnd} &nbsp; &nbsp; Check Date: {checkDate}</strong>
            </div>
            <div className="row">
              <div className="col-12 text-right">
                <button type="button"
                  className="orange-outline-button mr-2"
                  onClick={() => { closeModal(); onConfirmed(false); }}>{'No'}</button>
                <button type="button"
                  className="orange-button"
                  onClick={() => { closeModal(); onConfirmed(true); }}>{'Yes'}</button>
              </div>
            </div>
          </>
        );
      }}
    </Modal>
  );
};

export default VerifyUploadedAdjustmentModal;