import { AxiosResponse } from "axios";
import { API_EMPLOYEE_MASTER } from "./API";
import { CommonService } from "./common.service";
import { WorkFlowEndpoints } from "./URL";
import { WorkFlowTask, WorkFlowTaskList, WorkFlowTaskStatusRequest } from "core/models/WorkFlow.model";

const postWorkFlowTask = (data: WorkFlowTask): Promise<AxiosResponse<WorkFlowTask>> => { return API_EMPLOYEE_MASTER.post<WorkFlowTask>(WorkFlowEndpoints.ADD_WORK_FLOW_TASK(CommonService.getProtectedClientNo()), data); };
const putWorkFlowTaskStatus = (data: WorkFlowTaskStatusRequest): Promise<AxiosResponse<WorkFlowTaskStatusRequest>> => { return API_EMPLOYEE_MASTER.put<WorkFlowTaskStatusRequest>(WorkFlowEndpoints.UPDATE_WORK_FLOW_TASK_STATUS(CommonService.getProtectedClientNo(), data.workFlowTaskId), data); };
const getWorkFlowTaskList = (): Promise<AxiosResponse<WorkFlowTaskList[]>> => { return API_EMPLOYEE_MASTER.get<WorkFlowTaskList[]>(WorkFlowEndpoints.GET_WORK_FLOW_TASK_LIST(CommonService.getProtectedClientNo())); };
const postCreateDefaultWorkFlowLevelUser = (): Promise<AxiosResponse> => { return API_EMPLOYEE_MASTER.post(WorkFlowEndpoints.CREATE_DEFAULT_LEVEL_USER(CommonService.getProtectedClientNo()), []); };

export const WorkFlowService = {
    postWorkFlowTask,
    getWorkFlowTaskList,
    putWorkFlowTaskStatus,
    postCreateDefaultWorkFlowLevelUser
  };