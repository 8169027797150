import { createReducer } from '@reduxjs/toolkit';
import { PayrollsUserOptions } from '../../models';
import { loadPayrollsUserOptions, putPayrollsUserOptions, storePayrollsUserOptions } from '../actions';

export interface State {
  loading: boolean;
  payrollsUserOptions: PayrollsUserOptions | null;
}

export const INITIAL_STATE: State = {
  loading: false,
  payrollsUserOptions: null,
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadPayrollsUserOptions.type]: (state, _) => {return { ...state, loading: true };},
  [putPayrollsUserOptions.type]: (state, _) => {return { ...state, loading: true };},
  [storePayrollsUserOptions.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      payrollsUserOptions: action.payload,
    };
  },
});