import { SelectModalGrpModel as S } from './types';

export const selectModalGrpStyles = (controlMinWidth: string, controlMaxWidth: string | undefined): S.StylesConfigType => ({
  control: (base) => {
    return {
      ...base,
      minHeight: '22px',
      height: '22px',
      minWidth: controlMinWidth,
      maxWidth: controlMaxWidth,
      borderRadius: '0.25rem',
      fontSize: '12px',
      color: 'black',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#0074D9',
      },
    };
  },
  valueContainer: (base) => {
    return {
      ...base,
      height: '22px',
      padding: '2px',
      top: '0',
      position: 'relative',
      overflow: 'hidden',
      width: 'fit-content',
      color: 'black',
    };
  },
  // only appears for searchable inputs
  singleValue: (base) => {
    return {
      ...base,
      marginBottom: 'auto',
    };
  },
  input: (base) => {
    return {
      ...base,
      marginTop: '-2px',
      paddingTop: '0',
    };
  },
  indicatorSeparator: (base) => {
    return {
      ...base,
      display: 'none',
    };
  },
  dropdownIndicator: (base) => {
    return {
      ...base,
      padding: '0px',
      paddingLeft: '5px',
      color: '#3a3a3a',
      '&:hover': {
        color: 'black',
      },
    };
  },
  menu: (base) => {
    return {
      ...base,
      background: '#FFF',
      width: '100%',
      minWidth: 'fit-content',
    };
  },
  menuList: (base) => {
    return {
      ...base,
      fontSize: '12px',
      color: 'black',
      whiteSpace: 'nowrap',
    };
  },
  option: (base) => {
    return {
      ...base,
      cursor: 'pointer',
    };
  },
});