import React, { CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlipProp, IconName, library, SizeProp, Transform } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { IconTypes } from 'enums/IconTypes';
import { IconType } from 'interfaces';

library.add(fal);
library.add(fat);
library.add(far);
library.add(fas);
library.add(fad);

type Props = {
  name: IconName;
  type?: IconType;
  size?: SizeProp;
  flip?: FlipProp;
  transform?: string | Transform;
  fontSize?: string | number;
  color?: string;
  className?: string;
  style?: CSSProperties;
  title?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
};

const Icon = ({
  name,
  type = IconTypes.SOLID,
  size,
  fontSize = '1rem',
  color,
  className,
  style,
  title,
  onClick,
  ...extraProps
}: Props) => {
  if (!name) {
    throw new Error('name prop is required');
  }

  return (
    <>
      <FontAwesomeIcon
        icon={[type, name]}
        size={size}
        color={color}
        className={className}
        onClick={(event) => {return onClick?.(event);}}
        title={title}
        style={{
          height: fontSize || style?.height,
          width: fontSize || style?.width,
          padding: '0 0.25rem',
          ...style,
        }}
        {...extraProps}
      />
      {/* for screen readers */}
      <span className="sr-only">{title ?? name}</span>
    </>
  );
};

export default Icon;