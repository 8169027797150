import React, { useEffect, useState } from 'react';
import Icon from 'core/components/shared/Icon';
import Modal from 'core/components/modals/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlDatePickerGrpInLine } from 'core/components/form-controls';
import { EarningsReportType } from 'core/models/EarningsReports.model';
import {
  storeBeginDate,
  storeEndDate,
} from 'core/store/actions/earnings-reports.action';
import { convToDateISOString } from 'utilities/utilities';
import { DateRange } from 'features/employee/EmployeeToolbar';

interface Props {
  show: boolean;
  onHide: () => void;
  onSubmit: (newVal: DateRange) => void;
  reportType: EarningsReportType | null;
}

interface FormProps {
  fromDate: Date;
  toDate: Date;
}

const currentYear = new Date().getFullYear();

const DateRangeModal = ({ show, onHide, onSubmit, reportType }: Props) => {
  const { setValue, watch, control, errors, getValues } = useForm<FormProps>({
    defaultValues: {
      fromDate: new Date('01/01/' + currentYear),
      toDate: new Date('12/31/' + currentYear),
    },
  });
  const { fromDate, toDate }: FormProps = watch(['fromDate', 'toDate']);
  const [dateErrors, setDateErrors] = useState<{ errorMessage: string, hasError: boolean }>({errorMessage: "", hasError: false});

  const dispatch = useDispatch();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit({
      beginDate: fromDate,
      endDate: toDate,
    });
    dispatch(storeBeginDate(convToDateISOString(fromDate)));
    dispatch(storeEndDate(convToDateISOString(toDate)));
  };

  useEffect(() => {
    validateDate();
  }, [getValues('toDate'), getValues('fromDate')]);

  // function for validating date
  const validateDate = () => {
    const fromDate = getValues('fromDate');
    const toDate = getValues('toDate');

    const fromDateTime = fromDate ? fromDate.getTime() : null;
    const toDateTime = toDate ? toDate.getTime() : null;

    if (toDateTime && ((fromDateTime ?? 0) > toDateTime)) 
      setDateErrors({errorMessage: 'From Date must come before To Date', hasError: true});
    else
      setDateErrors({errorMessage: '', hasError: false})
  };

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        title={reportType?.split(/(?=[A-Z])/).join(' ')}
        show={show}
        onHide={onHide}
      >
        {(closeModal) => {
          return (
            <form onSubmit={handleSubmit}>
              {dateErrors.hasError && (
                <div className="text-danger mb-2">
                  {dateErrors.errorMessage}
                </div>
              )}
              <div className="row pl-3">Select Dates</div>
              <div className="row mt-2">
                <div className="col">
                  <ControlDatePickerGrpInLine
                    name={'fromDate'}
                    label={'FROM: '}
                    labelWidth={40}
                    groupClass={'groupClass50'}
                    control={control}
                    required={true}
                    value={fromDate}
                    setValue={setValue}
                    errors={errors.fromDate}
                    rules={{
                      required: 'From Date is required',
                    }}
                  />
                </div>
                <div className="col">
                  <ControlDatePickerGrpInLine
                    name={'toDate'}
                    label={'TO: '}
                    labelWidth={40}
                    groupClass={'groupClass50'}
                    required={true}
                    value={toDate}
                    setValue={setValue}
                    control={control}
                    errors={errors.toDate}
                    rules={{
                      required: 'To Date is required',
                    }}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-9 text-right">
                  <button
                    type="button"
                    className="btn btn-primary orange-outline-button"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-3">
                  <button
                    type="submit"
                    className="btn btn-primary orange-button"
                    disabled={!fromDate || !toDate || dateErrors.hasError}
                  >
                    Generate
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </Modal>
    </div>
  );
};

export default DateRangeModal;
