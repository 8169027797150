import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Client, LookupEEOUnit } from '../../core/models';
import {
  deleteLookupEEOUnit,
  loadEEOCertifyOfficials,
  loadLookupEEOUnits,
} from '../../core/store/actions';
import {
  getClient,
  getLookupEEOCertifyOfficial,
  getLookupEEOUnit,
} from '../../core/store/selectors';
import EEOUnit from './EEOUnit';
import EEOUnitTable from './EEOUnitTable';

const EEOUnitPage: React.FC<any> = () => {
  const dispatch = useDispatch();
  const client = useSelector(getClient) as Client;
  const lookupEEOUnits = useSelector(getLookupEEOUnit);
  const lookupCertifyOfficials = useSelector(getLookupEEOCertifyOfficial);

  const [selectedEEOUnit, setSelectedEEOUnit] = useState<LookupEEOUnit>(
    new LookupEEOUnit(0, client.clientEntityID),
  );

  useEffect(() => {
    dispatch(loadLookupEEOUnits());
    dispatch(loadEEOCertifyOfficials());
  }, []);

  useEffect(() => {
    if (lookupEEOUnits?.length > 0) {
      setSelectedEEOUnit(lookupEEOUnits[0]);
    }
  }, [lookupEEOUnits]);

  const onAddEEOUnit = () => {
    setSelectedEEOUnit(new LookupEEOUnit(0, client.clientEntityID));
  };

  const onDeleteEEOUnit = () => {
    selectedEEOUnit.eeoUnitID &&
            dispatch(deleteLookupEEOUnit(selectedEEOUnit));
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="row dm-panel dm-panel-border">
          <div className="col-12">
            <EEOUnit
              selectedEEOUnit={selectedEEOUnit}
              onAdd={onAddEEOUnit}
              onDelete={onDeleteEEOUnit}
            />
          </div>
        </div>
      </div>
      <div className="col-12 pt-4">
        <EEOUnitTable
          lookupEEOUnits={lookupEEOUnits}
          lookupCertifyOfficials={lookupCertifyOfficials}
          setSelectedEEOUnit={setSelectedEEOUnit}
        />
      </div>
    </div>
  );
};

export default EEOUnitPage;
