import { createReducer } from '@reduxjs/toolkit';
import { EmpFte } from '../../models';
import { loadEmployeeFte, storeEmployeeFte, storeEmployeeFtes } from '../actions';

export interface State {
  loading: boolean;
  employeeFte: EmpFte[]
}

const INITIAL_STATE = {
  loading: false,
  employeeFte: [],
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadEmployeeFte.type]: (state) => {return { ...state, loading: true };},
  [storeEmployeeFtes.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      employeeFte: action.payload,
    };
  },
  [storeEmployeeFte.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      employeeFte: [
        ...state.employeeFte.filter((rr: EmpFte) => {return rr.fteHeadId !== action.payload.fteHeadId;}),
        action.payload,
      ],
    };
  },
});
