import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpResponse } from '../../models';
import { handleError, handleSuccess, handleWarning } from '../actions';
import { ContractorReportService } from 'core/services/contractor-reports.service';

import {
  downloadApprovedJobsReport,
  downloadEEOCC257Report,
  downloadElectricalFringeReport,
  downloadFringeReport,
  downloadJobDetailReport,
  downloadOhioI29Report,
  downloadWH347CertifiedReport,
  downloadWeekMonthJTDJobCostReport,
  downloadWorkersCompenationReport,
  getBrickLayerRates,
  storeApprovedJobsReport,
  storeBrickLayerRates,
  storeEEOC257Report,
  storeElectricalFringeReport,
  storeFringeReport,
  storeJobDetailReport,
  storeOhioI29Report,
  storeWH347CertifiedReport,
  storeWeekMonthJTDJobCostReport,
  storeNationalAgreementReport,
  downloadNationalAgreementReport,
  storeWorkersCompenationReport,
  downloadJobToDateGrossSummaryReport,
  storeJobToDateGrossSummaryReport,
  storeJobGrossSummaryReport,
  downloadJobGrossSummaryReport,
  downloadJobLaborReport,
  storeJobLaborReport,
  downloadEmployeeTimeCardReport,
  storeEmployeeTimeCardReport,
  getRateMaster,
  storeRateMaster,
  downloadRateMasterReport,
  storeRateMasterReport,
  downloadOCIPReport,
  storeOCIPReport,
  downloadEmployeeGrossSummaryReport,
  storeEmployeeGrossSummaryReport,
} from '../slices/contractorReports.slice';
import { ApprovedJobsReportRequest, BrickLayerCementMasonRate, 
  OhioI29Request, JobDetailRequest, FringeReportRequest, ElectricalFringeReportRequest, 
  NationalAgreementRequest, EEO_CC257Request, JobGrossSummaryReportRequest, 
  JobLaborRequest, JobToDateGrossSummaryReportRequest, EmployeeTimeCardReqest, 
  OCIPReportRequest, EmployeeGrossSummaryReportRequest, RateMaster, 
  Week_Month_JTD_Job_Cost_Report_Request, WH347_Certified_Report_Request, 
  WorkersCompenationReportRequest, RateMasterRequest } from 'core/models/ContractorReports.model';

interface Actions {
  type: string;
  payload: any;
}

const downloadOhioI29Report$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadOhioI29Report.type),
    switchMap((action: { payload: OhioI29Request; }) => {
      return from(ContractorReportService.postOhioI29(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeOhioI29Report(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const downloadWeekMonthJTDJobCostReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadWeekMonthJTDJobCostReport.type),
    switchMap((action: { payload: Week_Month_JTD_Job_Cost_Report_Request; }) => {
      return from(ContractorReportService.postWeekMonthJTDJobCostReport(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeWeekMonthJTDJobCostReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const downloadWH347CertifiedReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadWH347CertifiedReport.type),
    switchMap((action: { payload: WH347_Certified_Report_Request; }) => {
      return from(ContractorReportService.postWH347CertifiedReport(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeWH347CertifiedReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};


const downloadJobDetailReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadJobDetailReport.type),
    switchMap((action: { payload: JobDetailRequest; }) => {
      return from(ContractorReportService.postJobDetailReport(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeJobDetailReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};



const downloadFringeReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadFringeReport.type),
    switchMap((action: { payload: FringeReportRequest; }) => {
      return from(ContractorReportService.postFringeReport(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeFringeReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};


const downloadApprovedJobsReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadApprovedJobsReport.type),
    switchMap((action: { payload: ApprovedJobsReportRequest; }) => {
      return from(ContractorReportService.postApprovedjobsReport(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeApprovedJobsReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const getBrickLayerRates$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(getBrickLayerRates.type),
    switchMap(() => {
      return from(ContractorReportService.getBrickLayerCementRates()).pipe(
        map((res: any) => {return res.data;}),
        map((res: BrickLayerCementMasonRate) => {return storeBrickLayerRates(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const downloadElectricalFringeReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadElectricalFringeReport.type),
    switchMap((action: { payload: ElectricalFringeReportRequest; }) => {
      return from(ContractorReportService.postElectricalFringeReport(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeElectricalFringeReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const downloadNationalAgreementReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadNationalAgreementReport.type),
    switchMap((action: { payload: NationalAgreementRequest; }) => {
      return from(ContractorReportService.postNationalAgreementReport(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeNationalAgreementReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};


const downloadWorkersCompenationReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadWorkersCompenationReport.type),
    switchMap((action: { payload: WorkersCompenationReportRequest; }) => {
      return from(ContractorReportService.postWorkersCompensationReport(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeWorkersCompenationReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};


const downloadEEO_CC22557_Report$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadEEOCC257Report.type),
    switchMap((action: { payload: EEO_CC257Request; }) => {
      return from(ContractorReportService.postEEOCC257Report(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeEEOC257Report(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const downloadJobGrossSummaryReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadJobGrossSummaryReport.type),
    switchMap((action: { payload: JobGrossSummaryReportRequest; }) => {
      return from(ContractorReportService.postJobGrossSummaryReport(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeJobGrossSummaryReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const downloadJobLaborReort$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadJobLaborReport.type),
    switchMap((action: { payload: JobLaborRequest; }) => {
      return from(ContractorReportService.postJobLaborReport(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeJobLaborReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const downloadJobToDateGrossSummaryReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadJobToDateGrossSummaryReport.type),
    switchMap((action: { payload: JobToDateGrossSummaryReportRequest; }) => {
      return from(ContractorReportService.postJobToDateGrossSummaryReport(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeJobToDateGrossSummaryReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const downloadEmployeeTimeCardReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadEmployeeTimeCardReport.type),
    switchMap((action: { payload: EmployeeTimeCardReqest; }) => {
      return from(ContractorReportService.postEmployeeTimeCardReport(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeEmployeeTimeCardReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const getRateMaster$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(getRateMaster.type),
    switchMap((action: { payload: RateMasterRequest; }) => {
      return from(ContractorReportService.getRateMaster(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map((res: RateMaster[]) => {return storeRateMaster(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const downloadRateMasterReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadRateMasterReport.type),
    switchMap((action: { payload: RateMasterRequest; }) => {
      return from(ContractorReportService.postRateMasterReport(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeRateMasterReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};


const downloadOCIPReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadOCIPReport.type),
    switchMap((action: { payload: OCIPReportRequest; }) => {
      return from(ContractorReportService.postOCIPReport(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeOCIPReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const downloadEmployeeGrossSummaryReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadEmployeeGrossSummaryReport.type),
    switchMap((action: { payload: EmployeeGrossSummaryReportRequest; }) => {
      return from(ContractorReportService.postEmployeeGrossSummaryReport(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeEmployeeGrossSummaryReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};
export const epics: any[] = [
  downloadOhioI29Report$,
  downloadWeekMonthJTDJobCostReport$,
  downloadWH347CertifiedReport$,
  downloadFringeReport$,
  downloadJobDetailReport$,
  downloadApprovedJobsReport$,
  getBrickLayerRates$,
  downloadWorkersCompenationReport$,
  downloadElectricalFringeReport$,
  downloadEEO_CC22557_Report$,
  downloadJobLaborReort$,
  downloadNationalAgreementReport$,
  downloadJobGrossSummaryReport$,
  downloadJobToDateGrossSummaryReport$,
  downloadOCIPReport$,
  downloadEmployeeGrossSummaryReport$,
  downloadEmployeeTimeCardReport$,
  getRateMaster$,
  downloadRateMasterReport$,
];

