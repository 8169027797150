import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PanelHeader from 'core/components/shared/PanelHeader';
import { HRLicense, HRLicenseRequest } from 'core/models/HREmployee.model';
import { createHRLicense, deleteHRLicense, loadHRLicense, updateHRLicense } from 'core/store/actions/hr-employee.action';
import { getHireInfoLicense } from 'core/store/selectors/hr-employee.selector';
import LicenseItem from './LicenseItem';
import { useFieldArray, useForm } from 'react-hook-form';
import { UNSAVED_MESSAGE } from 'core/constants';
import Icon from 'core/components/shared/Icon';
import { toggleBlockNavigation } from 'core/store/actions';

type FormType = {
  licenseItems: HRLicense[];
};

const LicensePage = () => {
  const dispatch = useDispatch();

  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();
  const [licenseItems, setLicenseItems] = useState<HRLicense[]>([]);
  const empLicenseIdStore = useSelector(getHireInfoLicense);

  const {
    reset,
    control,
    handleSubmit,
    formState,
    ...formMethods
  } = useForm<FormType>({
    defaultValues: { licenseItems },
  });

  const { fields, prepend } =
    useFieldArray<HRLicense>({
      control,
      name: 'licenseItems',
      keyName: 'id',
    });

  useEffect(() => {
    if (!protectedEmpNo) return;    
    dispatch(loadHRLicense(protectedEmpNo));
  }, [protectedEmpNo]);

  useEffect(() => {
    if (!empLicenseIdStore) return;    
    setLicenseItems(empLicenseIdStore);
  }, [empLicenseIdStore]);

  useEffect(() => {
    if (!licenseItems) return;    
    reset({ licenseItems });
  }, [licenseItems]);

  const addLicense = () => {
    prepend({
      empLicenseId: 0,
      protectedEmpNo,
    });
  };

  const deleteLicense = (item: HRLicense) => {
    if (item.empLicenseId) {
      dispatch(deleteHRLicense({
        data: item,
        protectedEmpNo,
      }));
    } else {
      setLicenseItems((prev: HRLicense[]) => { return prev.filter((cb: HRLicense) => { return cb.empLicenseId; }); });
    }
  };
  
  const handleNullUndefinedOrEmptyDateString = (dateString: string | null | undefined) => {
    if (dateString === null || dateString === undefined || dateString === '') return null;
    return new Date(dateString).toISOString();
  };

  const onSubmit = (data: FormType, e?: React.BaseSyntheticEvent) => {
    if (!e?.target.isConnected) {
      return false;
    }
    
    if (!data) return console.error('Could not get form data');
    
    data.licenseItems.forEach((item: HRLicense) => {
      const request: HRLicenseRequest = {
        protectedEmpNo,
        data: {
          ...item,
          empLicenseId: +item.empLicenseId,
          startDate: handleNullUndefinedOrEmptyDateString(item.startDate),
          expirationDate: handleNullUndefinedOrEmptyDateString(item.expirationDate),
          renewalDate: handleNullUndefinedOrEmptyDateString(item.renewalDate),
        },
      };
      
      if (request.data.empLicenseId !== 0) {
        dispatch(updateHRLicense(request));
      } else {
        dispatch(createHRLicense(request));
      }
    });
  };

  const onCancel = () => {
    reset();
  };

  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: formState.isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [formState.isDirty]);

  return (
    <div>
      <div className="dm-panel dm-panel-border" >
        <PanelHeader title="Licenses" >
          <button
            className="btn btn-link dm-grid-action-title p-0 mr-2"
            onClick={addLicense}
          >
            Add License	<Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields?.map((item, index) => {
            return (
              <div key={item.id} >
                <LicenseItem
                  item={item}
                  index={index}
                  control={control}
                  formMethods={formMethods}
                  onDelete={deleteLicense}
                />
                {index + 1 < licenseItems.length && <hr className="dm-panel-hr mt-2" />}
              </div>
            );
          })}
          <hr className="dm-panel-hr mt-2" />
          <div className="text-right">
            <button
              type="button"
              className="btn btn-primary orange-outline-button ml-auto mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              disabled={!formState.isDirty}
              type="submit"
              className="btn orange-button mr-3"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LicensePage;
