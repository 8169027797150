import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../providers/authProvider';
import { handleError } from '../../store/actions';
import { persistor } from '../../store/store';
import RedirectSpinner from './RedirectSpinner';

export const Logout: React.FC = () => {
  const auth = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    async function logOutAsync() {
      await auth.logout();
    }
    persistor.pause();
    persistor.flush()
      .then(() => {return persistor.purge();})
      .catch((e: any) => {return handleError(e);});
    logOutAsync();
  }, [dispatch]);

  return <RedirectSpinner />;
};

export default Logout;
