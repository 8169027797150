import Modal from 'core/components/modals/Modal';
import React from 'react';
import EEOUnitPage from './EEOUnitPage';

type PropTypes = {
  show: boolean;
  onHide: () => void;
};

const EEOModal: React.FC<PropTypes> = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      title="EEO Unit"
    >
      <EEOUnitPage />
    </Modal>
  );
};
export default EEOModal;
