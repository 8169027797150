import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class AccrualRule {
  clientNo = 0;

  empId = 0;

  empNo = 0;

  protectedEmpNo = '';

  ruleDescription = '';

  ltdBeginBalance = 0;

  creditedService = 0;

  accrualHireDate: Date | string | null = null;

  ltdHours = 0;

  paidSickTimeEntityId: number | null = null;

  static readonly convProps: PropTypeLists = {
    dateProps: ['accrualHireDate'],
    numberProps: ['clientNo', 'empId', 'empNo', 'paidSickTimeEntityId'],
    floatProps: ['ltdBeginBalance', 'creditedService', 'ltdHours'],
  };

  constructor(
    clientNo: number | string,
    empId: number | string,
    empNo: number | string,
    protectedEmpNo: string,
    props?: { [key: string]: any },
  ) {
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empId = (typeof empId === 'string') ? parseInt(empId) : empId;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;
    props && convToClass(this, props, AccrualRule.convProps);
  } 
}


export interface AccrualRuleRequest {
  clientNo: number, 
  empId: number, 
  empNo: number, 
  protectedEmpNo: string 
}
