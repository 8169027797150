import { Dictionary } from '@reduxjs/toolkit';
import Icon from 'core/components/shared/Icon';
import { groupBy, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import EmployeeSearch from 'core/components/shared/EmployeeSearch';
import EmployeeSearchHeader, { SearchForm } from 'core/components/shared/EmployeeSearchHeader';
import EmpPhoto from 'core/components/shared/EmpPhoto';
import { W4Info } from 'core/models';
import { TaxRequest, TaxService } from 'core/services/tax.service';
import { loadEmployee } from 'core/store/actions';
import { loadW4Info } from 'core/store/actions/tax.action';
import { getEmpSearch, getSelectedEmp } from 'core/store/selectors';
import { getW4Info } from 'core/store/selectors/tax.selector';
import { convertToBlob, dummyPdf } from 'utilities/base64';
import { useAppDispatch } from 'utilities/hooks';

type collapseState = {
  [key: string]: boolean;
};

const W4Status: React.FC = () => {
  // State
  const [searchForm, setSearchForm] = useState<string>('');
  const [isSelectedEmp, setIsSelectedEmp] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [w4InfoState, setW4InfoState] = useState<Dictionary<W4Info[]>>();
  const [openInfo, setOpenInfo] = useState<collapseState>({});
  const [searchAllClients, setSearchAllClients] = useState<boolean>(false);
  
  // Selectors
  const employeeInfo = useSelector(getSelectedEmp);
  const employeeSearch = useSelector(getEmpSearch);
  const w4Info = useSelector(getW4Info);

  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isSearching && employeeSearch.length === 1) {
      setIsSelectedEmp(true);
      dispatch(loadEmployee(employeeSearch[0].protectedEmpNo));
    }
  }, [employeeSearch]);

  useEffect(() => {
    const groupByW4 = groupBy(w4Info, (e) => { return e.groupIndex; });
    setW4InfoState(groupByW4);
    const openInfoState: collapseState = {};
    map(groupByW4, (e, i) => {
      openInfoState[i] = true;
    });
    setOpenInfo(openInfoState);
  }, [w4Info]);

  useEffect(() => {
    if (isSelectedEmp && employeeInfo) {
      dispatch(
        loadW4Info({
          protectedEmpNo: employeeInfo.protectedEmpNo,
        } as TaxRequest),
      );
    }
  }, [employeeInfo]);

  const selectEmployee = (protectedEmpNo: string) => {
    dispatch(loadEmployee(protectedEmpNo));
    setIsSelectedEmp(true);
    setIsSearching(false);
  };

  const printEntity = (
    empNo: number,
    protectedEmpNo: string,
    year: number,
    entityId: number,
  ) => {
    let base64 = '';
    if (process.env.REACT_APP_ENV !== 'production') {
      base64 = dummyPdf;
    } else {
      TaxService.generateTaxForm({
        empNo,
        protectedEmpNo,
        year,
        entityId,
      } as TaxRequest)
        .then((response) => {
          base64 = response.data.value;
        })
        .catch((error) => {
          alert(error);
        });
    }
    if (base64.length === 0) {
      return;
    }
    const file = convertToBlob(base64, 'application/pdf');
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const toggleInfo = (groupIndex: string) => {
    const currentState = openInfo;
    currentState.hasOwnProperty(groupIndex)
      ? delete currentState[groupIndex]
      : (currentState[groupIndex] = true);
    setOpenInfo({ ...currentState });
  };

  const onSearch = (search: SearchForm) => {
    setSearchForm(search.searchEmp);
    setIsSearching(true);
    setIsSelectedEmp(false);
    setOpenInfo({});
  };

  return (
    <div className="container-fluid shadow shadow-lg">
      <EmployeeSearchHeader
        headerName="W4 Status"
        onSearch={onSearch}
        isSearching={isSearching}
        isSelectedEmp={isSelectedEmp}
        searchAll={searchAllClients}
        setSearchAllClients={setSearchAllClients}
      />
      <EmployeeSearch
        selectEmployee={selectEmployee}
        query={searchForm}
        isSearching={isSearching}
        searchAll={searchAllClients}
      />
      {isSelectedEmp && (
        <div className="animate">
          <div className="row mx-2 mb-4">
            <div className="col-sm-1">
              <EmpPhoto
                empPicData={employeeInfo?.employeePhoto}
                empName={`${employeeInfo?.firstName} ${employeeInfo?.lastName}`}
              />
            </div>
            <div className="col">
              <strong>
                {employeeInfo?.firstName}{' '}
                {employeeInfo?.lastName}
              </strong>
              <br />
              <strong>Emp No {employeeInfo?.empNo}</strong>
            </div>
          </div>
          {w4InfoState && (
            <div className="row mx-2">
              <div className="col-sm-12">
                {map(
                  w4InfoState,
                  (taxInfo: W4Info[], index: string) => {
                    const entityDescription =
                      taxInfo[0].entityDescription;
                    return (
                      <div className="card">
                        <div className="card-header bg-primary text-white p-0">
                          <div className="row font-weight-bold">
                            <div className="col d-flex align-items-center pr-0">
                              <Button
                                variant="primary"
                                onClick={() => {
                                  return toggleInfo(
                                    index,
                                  );
                                }
                                }
                                block
                                className="font-weight-bold text-left"
                              >
                                <Icon
                                  name={openInfo[index] ? 'chevron-down' : 'chevron-right'}
                                  className="mr-2"
                                />
                                {entityDescription}{' '}
                                {entityDescription !==
                                  'Employee Info' &&
                                  taxInfo[0]
                                    .year}
                              </Button>
                            </div>
                            <div className="col-auto d-flex justify-content-end align-items-center pl-0">
                              {employeeInfo && entityDescription !== 'Employee Info' && (
                                <button
                                  type="button"
                                  className="btn btn-primary btn-block text-white font-weight-bold"
                                  onClick={printEntity.bind(
                                    this,
                                    employeeInfo.empNo,
                                    employeeInfo.protectedEmpNo,
                                    taxInfo[0]
                                      .year,
                                    taxInfo[0]
                                      .entityId,
                                  )}
                                >
                                  <Icon name="print" />
                                  &nbsp;Print
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <Collapse in={openInfo[index]}>
                          <div className="card-body p-0">
                            <div
                              className="table-responsive"
                              key={
                                taxInfo[0]
                                  .groupIndex
                              }
                            >
                              <table className="table-wrapper-wrapper table-bordered table-hover">
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col">
                                      Field
                                      Name
                                    </th>
                                    <th scope="col">
                                      Value
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {taxInfo.map(
                                    (
                                      w4: W4Info,
                                    ) => {
                                      return (
                                        <tr
                                          key={`${w4.groupIndex}${w4.index}`}
                                        >
                                          <td>
                                            {
                                              w4.fieldName
                                            }
                                          </td>
                                          <td>
                                            {
                                              w4.value
                                            }
                                          </td>
                                        </tr>
                                      );
                                    },
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    );
                  },
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default W4Status;
