import { AxiosResponse } from "axios";
import { HttpResponse } from '../models';
import { EmpFte } from '../models/EmpFte.model';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

function getEmployeeFte(protectedEmpNo: string): Promise<AxiosResponse<EmpFte>> {
  return API_EMPLOYEE_MASTER.get<EmpFte>(EmployeeEndPoints.EMPLOYEES_FTE(CommonService.getProtectedClientNo(), protectedEmpNo));
}

function postEmployeeFte(data: EmpFte): Promise<AxiosResponse<HttpResponse<EmpFte>>> {
  return API_EMPLOYEE_MASTER.post<HttpResponse<EmpFte>>(EmployeeEndPoints.EMPLOYEES_FTE(CommonService.getProtectedClientNo(), data.protectedEmpNo), data);
}

function putEmployeeFte(data: EmpFte): Promise<AxiosResponse<HttpResponse<EmpFte>>> {
  return API_EMPLOYEE_MASTER.put<HttpResponse<EmpFte>>(EmployeeEndPoints.EMPLOYEES_FTE_ID(CommonService.getProtectedClientNo(), data.protectedEmpNo, data.fteHeadId), data);
}

function deleteEmployeeFte(data: any): Promise<AxiosResponse<HttpResponse<EmpFte>>> {
  return API_EMPLOYEE_MASTER.delete<HttpResponse<EmpFte>>(EmployeeEndPoints.EMPLOYEES_FTE_ID(CommonService.getProtectedClientNo(), data.protectedEmpNo, data.fteHeadId));
}

export const EmployeeFteService = {
  getEmployeeFte,
  postEmployeeFte,
  putEmployeeFte,
  deleteEmployeeFte,
};