import React, { CSSProperties } from 'react';
import '../time-card-styles.scss';
import { InputGrpCurrency } from 'core/components/form-controls';
import { TimeCardJobRosterEarnings } from 'core/models/Contractor.model';

type Props = {
  showLabel?: boolean;
  earning: TimeCardJobRosterEarnings
};

const JobRosterEarningItem = ({ showLabel, earning }: Props) => {
  const infoGrpStyle: CSSProperties = {
    marginBottom: '0',
    maxWidth: 'unset',
    marginLeft: '24px',
  };
  
  return (
    <div className="earning-item">
      <div style={{ width: '33%' }}>
        <span className="input-label">{earning.earningsDesc} {earning.earningsCode}</span>
      </div>
        <div className="d-flex align-items-center">
          <InputGrpCurrency
            label={ showLabel ? 'Amount' : undefined}
            groupStyle={infoGrpStyle}
            inputStyle={{ height: '30px' }}
            disabled={true}
            defaultValue={earning.earningsAmount}
          />
        </div>
    </div>
  );
};

export default JobRosterEarningItem;