import { PropTypeLists, convToClass } from "utilities/classUtils";

export class DeptSubSub2TransmittalUpdate {
    empNo = 0;
    empId = 0;
    dept = 0;
    sub = 0;
    sub2 = 0;

    static readonly convProps: PropTypeLists = {
        numberProps: [
          'empNo',
          'empId',
          'loc',
          'dept',
          'sub',
          'sub2',
        ],
    };

    constructor(empId: string | number, empNo: number, props?: { [key: string]: any }) {
        this.empId = (typeof empId === 'string') ? parseInt(empId) : empId;
        this.empNo = empNo;
    
        props && convToClass(this, props, DeptSubSub2TransmittalUpdate.convProps);
      }
}
