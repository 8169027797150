import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { PayrollTotalByTimePeriod } from 'core/models/PayrollTotalByTimePeriod';
import { useAppSelector } from 'utilities/hooks';
import { currencyFormatter } from 'utilities/utilities';
import LegendLine from './LegendLine';
import styles from 'core/components/form-controls/form-controls.module.scss';
const COLORS = ['#348ea9', '#52ba9b', '#62bed2'];

type Props = {
  visible: boolean;
};

const PayrollBreakDownChart: React.FC<Props> = (props: Props) => {
  const payrollTotalByTimePeriod = useAppSelector(
    (state) => {return state.dashboard.payrollTotalsByTimePeriod;},
  ) as PayrollTotalByTimePeriod[];

  const [chartData, setChartData] = useState<
  { name: string; value: number }[] | null
  >(null);

  const [timeDescription, setTimeDescription] = useState<string>(payrollTotalByTimePeriod[0]?.dateDescription);
  
  useEffect(() => {
    if (payrollTotalByTimePeriod.length > 0) {
      setDataForChart(payrollTotalByTimePeriod[0].timePeriod);
    }
  }, [payrollTotalByTimePeriod]);

  const timePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDataForChart(e.target.value);
  };

  const setDataForChart = (data: string) => {
    const info =
            payrollTotalByTimePeriod.find((p) => {return p.timePeriod === data;}) ?? null;
    if (info) {
      setTimeDescription(info.dateDescription);
      setChartData([
        {
          name: 'Payroll Taxes',
          value: info.taxesTotal,
        },
        {
          name: 'Employee ACH',
          value: info.employeeACHTotal,
        },
        {
          name: 'Payroll Checks',
          value: info.checksTotal,
        },
      ]);
    }
  };

  if (!props.visible) return null;
  return (
    <div className="payroll-break-down-chart-page-container dm-card">
      <div className="dm-card-body">
        <div className="dm-card-body-title">
          <div className="mb-2 text-uppercase">
            Payroll Summary
          </div>
        </div>
        {chartData ? (
          <>
            <Row className="mb-2">
              <Col>
                <select
                  onChange={timePeriodChange}
                  className={styles['dm-form-control']}
                >
                  {payrollTotalByTimePeriod.map((item) => {
                    return (
                      <option key={item.timePeriod}>
                        {item.timePeriod}
                      </option>
                    );
                  })}
                </select>
              </Col>
              <Col xs={'auto'}>
                <div className="dm-grid-action-title text-right">
                  {currencyFormatter(
                    chartData
                      .map((a) => {return a.value;})
                      .reduce((a, b) => {return a + b;}, 0),
                  )}
                </div>
              </Col>
            </Row>
            <hr />
            <div className="dm-card-content">
              <Row>
                <Col>
                  <div className="dm-text-primary font-weight-bold text-center">
                    {
                      timeDescription
                    }
                  </div>
                </Col>
              </Row>
              <Row style={{ minHeight: '250px' }}>
                <Col
                  xs={5}
                  style={{ padding: '0' }}
                >
                  <ResponsiveContainer
                    width="100%"
                    height="100%"
                  >
                    <PieChart>
                      <Pie
                        data={chartData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        innerRadius="20%"
                        outerRadius="80%"
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {chartData.map(
                          (entry, index) => {
                            return (
                              <Cell
                                key={`cell-${index}`}
                                fill={
                                  COLORS[
                                    index %
                                                                        COLORS.length
                                  ]
                                }
                              />
                            );
                          },
                        )}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Col>
                <Col
                  xs={7}
                  className={'my-auto text-left pl-2'}
                >
                  {chartData.map((item, index) => {
                    return (
                      <LegendLine
                        key={index}
                        dotColor={COLORS[index]}
                        legendText={item.name}
                        amount={item.value}
                      />
                    );
                  })}
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
};

export default PayrollBreakDownChart;
