import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class ContractorOption {
  clientNo = 0;

  rateId = 0;

  empId = 0;

  empNo = 0;

  protectedEmpNo = '';

  fringeGuid = '';

  fringeType = '';

  fringeDescription = '';

  fringeRate = 0;

  ovrYn = false;

  ovrAmount = 0;

  isDeducted = false;

  earnIsIncluded = false;

  empOvrYn = false;

  dedFringeCheckEmpMaster = false;

  earnFringeCheckEmpMaster = false;


  static readonly convProps: PropTypeLists = {
    numberProps: ['clientNo', 'rateId', 'empId', 'empNo'],
    floatProps: ['fringeRate'],
    currencyProps: ['ovrAmount'],
    boolProps: ['ovrYn', 'isDeducted', 'earnIsIncluded', 'empOvrYn', 'dedFringeCheckEmpMaster', 'earnFringeCheckEmpMaster'],
  };

  constructor(props?: { [key: string]: any }) {
    props && convToClass(this, props, ContractorOption.convProps);
  }
}