import { AxiosResponse } from 'axios';
import { AccrualBalanceRecord, AccrualEarningsCode, AccrualEdit, AccrualManualEntry, AccrualRule, AccrualRuleRequest, AccrualTimeOffSummary, HttpResponse, MarkRequestTaken, TimeOffRequest } from '../models';
import { API_CLIENT, API_EMPLOYEE_MASTER } from './API';
import { EmployeeEndPoints } from './URL';
import { CommonService } from './common.service';
import { CreateDefaultAccrualRequest } from 'core/store/actions';

const GetAccruals = (request: string): Promise<AxiosResponse<AccrualBalanceRecord[]>> => { return API_EMPLOYEE_MASTER.get<AccrualBalanceRecord[]>(EmployeeEndPoints.GET_ACCRUALS(CommonService.getProtectedClientNo(), request)); };

const PutAccrual = (data: AccrualEdit): Promise<AxiosResponse<HttpResponse<AccrualEdit>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<AccrualEdit>>(EmployeeEndPoints.PUT_ACCRUAL(CommonService.getProtectedClientNo(), data.protectedEmpNo, data.type), data); };

const PutAccrualBeginBalance = (data: AccrualEdit): Promise<AxiosResponse<HttpResponse<AccrualEdit>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<AccrualEdit>>(EmployeeEndPoints.PUT_ACCRUAL_BEGIN_BALANCE(CommonService.getProtectedClientNo(), data.protectedEmpNo), data); };

const PostAccrualManualEntry = (data: AccrualManualEntry): Promise<AxiosResponse<HttpResponse<AccrualManualEntry>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<AccrualManualEntry>>(EmployeeEndPoints.POST_ACCRUAL_MANUAL_ENTRY(CommonService.getProtectedClientNo(), data.protectedEmpNo, data.type), data); };

const GetAccrualRule = (protectedEmpNo: string): Promise<AxiosResponse<AccrualRule>> => { return API_EMPLOYEE_MASTER.get<AccrualRule>(EmployeeEndPoints.GET_ACCRUAL_RULE(CommonService.getProtectedClientNo(), protectedEmpNo)); };

const PutAccrualRule = (data: AccrualRule): Promise<AxiosResponse<HttpResponse<AccrualRule>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<AccrualRule>>(EmployeeEndPoints.PUT_ACCRUAL_RULE(CommonService.getProtectedClientNo(), data.protectedEmpNo), data); };

const GetTimeOffRequests = (protectedEmpNo: string): Promise<AxiosResponse<TimeOffRequest[]>> => { return API_EMPLOYEE_MASTER.get<TimeOffRequest[]>(EmployeeEndPoints.GET_TIME_OFF_REQUESTS(CommonService.getProtectedClientNo(), protectedEmpNo)); };

const GetTimeOffRequest = (protectedEmpNo: string, timeOffRequestId: number): Promise<AxiosResponse<TimeOffRequest>> => { return API_EMPLOYEE_MASTER.get<TimeOffRequest>(EmployeeEndPoints.GET_TIME_OFF_REQUEST(CommonService.getProtectedClientNo(), protectedEmpNo, timeOffRequestId)); };

const PutMarkTaken = (data: MarkRequestTaken): Promise<AxiosResponse<HttpResponse<MarkRequestTaken>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<MarkRequestTaken>>(EmployeeEndPoints.PUT_MARK_TAKEN(CommonService.getProtectedClientNo(), data.protectedEmpNo, data.timeOffRequestId), data); };

const GetAccrualTimeOffSummary = (protectedEmpNo: string): Promise<AxiosResponse<AccrualTimeOffSummary[]>> => { return API_EMPLOYEE_MASTER.get<AccrualTimeOffSummary[]>(EmployeeEndPoints.GET_ACCRUAL_TIME_OFF_SUMMARY(CommonService.getProtectedClientNo(), protectedEmpNo)); };

const GetAccrualEarningsCodes = (): Promise<AxiosResponse<AccrualEarningsCode[]>> => { return API_CLIENT.get<AccrualEarningsCode[]>(EmployeeEndPoints.GET_ACCRUAL_EARNINGS_CODES(CommonService.getProtectedClientNo())); };

const CreateDefaultAccrualRecords = (request: CreateDefaultAccrualRequest): Promise<AxiosResponse<any, any>> => {
  return API_EMPLOYEE_MASTER.post<any>(EmployeeEndPoints.POST_DEFAULT_ACCRUALS(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.hireDate));
};

export const AccrualService = {
  GetAccruals,
  PutAccrual,
  PutAccrualBeginBalance,
  PostAccrualManualEntry,
  GetAccrualRule,
  PutAccrualRule,
  GetTimeOffRequests,
  GetTimeOffRequest,
  PutMarkTaken,
  GetAccrualTimeOffSummary,
  GetAccrualEarningsCodes,
  CreateDefaultAccrualRecords,
};