
import { RadioGrp } from 'core/components/form-controls';
import InputReportDateSelector from 'core/components/form-controls/InputReportDateSelector';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import Icon from 'core/components/shared/Icon';
import { DtoOptions, Client } from 'core/models';
import { EmployeeGrossSummaryReportRequest } from 'core/models/ContractorReports.model';
import { CommonService } from 'core/services';
import {
  clearEmployeeGrossSummaryReport,
  downloadEmployeeGrossSummaryReport,
} from 'core/store/slices/contractorReports.slice';
import React, { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const dateOptions: RadioOptions[] = [
  {
    value: 'ByWeekEnding',
    label: 'By Week End',
  },
  {
    value: 'ByCheckDate',
    label: 'By Check Date',
  },
];
const EmployeeGrossSummaryReport = () => {
  const dispatch = useAppDispatch();
  const childRef: React.RefObject<any> = useRef();

  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [fileType, setFileType] = useState<string>('pdf');

  const client = useAppSelector((state) => { return state?.client?.client; }) as Client;

  const report = useAppSelector(
    (state) => { return state.contractorReports.employeeGrossSummaryReport; },
  );

  const hidePdfViewer = () => {
    dispatch(clearEmployeeGrossSummaryReport());
  };

  const { register, handleSubmit } = useForm<EmployeeGrossSummaryReportRequest>({
    defaultValues: {
      dateOption: 'ByWeekEnding',
    },
  });

  useEffect(() => {
    if (report && fileType === 'excel') {
      CommonService.downloadBase64File(
        'application/excel',
        report,
        'EmployeeGrossSummaryReport.xlsx',
      );
      dispatch(clearEmployeeGrossSummaryReport());
    }
  }, [report]);

  const downloadReport = (data: EmployeeGrossSummaryReportRequest) => {
    const submitData: EmployeeGrossSummaryReportRequest = {
      clientNo: client.clientNo,
      dateOption: data.dateOption,
      dtoOptions: reportDates,
      reportType: fileType,
    };
    dispatch(downloadEmployeeGrossSummaryReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };
  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">
            Employee Gross Summary Report
          </div>
          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title "
              onClick={() => { return setFileType('excel'); }}
              disabled={!reportDates.length}
            >
              Download Report{' '}
              <Icon
                name="download"
                className="fa-download"
              />
            </button>
          </div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div>
              <InputReportDateSelector
                ref={childRef}
                returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }
                }
              />
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <div className=" text-right">
                <button
                  type="button"
                  className="orange-outline-button mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="orange-button mr-2"
                  onClick={() => { return setFileType('pdf'); }}
                  disabled={!reportDates.length}
                >
                  Run Report
                </button>
              </div>

              <div
                className="dm-panel dm-panel-border"
                style={{
                  width: '20%',
                  marginTop: '66px',
                }}
              >
                <div className="dm-grid-action-title mb-2">
                  Report Options
                </div>
                <div>
                  <div className="d-flex">
                    <div className="d-flex flex-row flex-grow-1">
                      <div className="d-flex flex-column flex-grow-1">
                        <RadioGrp
                          isVertical={true}
                          radioOptions={dateOptions}
                          name="dateOption"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {report ? (
          <PDFViewerModal
            show={report.length > 0}
            pdfData={report}
            onHide={hidePdfViewer}
            reportName="EmployeeGrossSummaryReport"
          />
        ) : null}
      </div>
    </div>
  );
};

export default EmployeeGrossSummaryReport;