import { createAction } from '@reduxjs/toolkit';
import { PayrollInfo } from '../../models';

export const PAYROLLINFO_EM_VALIDATION_ACTIONS = {
  UPDATE_PAYROLL_INFO: '[employee] UPDATE_PAYROLL_INFO',
} as const;

export const loadPayrollInfo = createAction<string>('[employee] LOAD_PAYROLL_INFO');
export const storePayrollInfo = createAction<PayrollInfo>('[employee] STORE_PAYROLL_INFO');
export const updatePayrollInfo = createAction<PayrollInfo>('[employee] UPDATE_PAYROLL_INFO');
