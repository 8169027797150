import React, { useState } from 'react';
import Modal from 'core/components/modals/Modal';
import styles from './styles.module.scss';
import sharedStyles from 'core/components/shared/shared.module.scss';
import { useBreakpoint } from 'utilities/BreakpointProvider';
import ChangeRequestDetails from './ChangeRequestDetails';
import ChangeFields from './ChangeFields';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { useAppDispatch } from 'utilities/hooks';
import { handleError, handleSuccess } from 'core/store/actions';
import { WorkFlowStatus, WorkFlowTaskList, WorkFlowTaskStatusRequest } from 'core/models/WorkFlow.model';
import { putWorkFlowTaskStatus } from 'core/store/actions/work-flow.action';

type Props = {
  show: boolean;
  onHide: () => void;
  task: WorkFlowTaskList;
};

const ChangeRequestModal = ({ show, onHide, task }: Props) => {
  const isActive = task.status === WorkFlowStatus.Pending;
  
  const [notes, setNotes] = useState<string>(task.notes);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [statusType, setWorkFlowStatus] = useState<WorkFlowStatus>();
  const [confirmationMessage, setConfirmationMessage] = useState<string>('');

  const setConfirmMessage = (status: WorkFlowStatus) => {
    switch(status){
      case WorkFlowStatus.Canceled: return setConfirmationMessage('Are you sure you want to cancel this workflow?');
      case WorkFlowStatus.Denied : return setConfirmationMessage('Are you sure you want to deny this change request?');
      case WorkFlowStatus.Approved: return setConfirmationMessage('Are you sure you want to approve this workflow?');
    }
  }
  
  const dispatch = useAppDispatch();
  
  const breakpoints: Record<string, string> = useBreakpoint();
  
  const handleStatusChange = (status: WorkFlowStatus) => {
    setWorkFlowStatus(status);
    setConfirmMessage(status);
    setShowConfirm(true);
  };
  
  const handleConfirm = (confirmed: boolean) => {
    if (!statusType) return dispatch(handleError('Error while trying to send update request'));

    if (!confirmed) {
      setShowConfirm(false);
      return;
    }

    const request: WorkFlowTaskStatusRequest = {
      workFlowTaskId: task.workFlowTaskId,
      workFlowTypeId: task.workFlowTypeId,
      targetEmpId: task.targetEmpId,
      status: statusType,
      notes: notes
    }

    dispatch(putWorkFlowTaskStatus(request));
    setShowConfirm(false);
    onHide();
  };
  
  const handleViewPdf = () => {
    
  };
  
  return (
    <>
      {showConfirm && statusType && confirmationMessage && (
        <ConfirmationModal
          title="Confirm"
          message={confirmationMessage}
          show={showConfirm}
          onHide={() => { setShowConfirm(false); }}
          onConfirmed={handleConfirm}
        />
      )}
      <Modal
        title={`Change Request for ${task.targetEmployeeName}`}
        animation
        size="xl"
        show={show}
        onHide={onHide}
      >
        <div className={styles['change-request-modal-content']}>
          <ChangeRequestDetails
            workFlowTask={task}
            status={task.status}
          />
          <ChangeFields details={task.changes} />
          <div className="mt-3 d-flex flex-column">
            <div className={sharedStyles['section-title-blue']}>
              Notes
            </div>
            <hr className="dm-panel-hr" />
            <label htmlFor="request-notes">Visible to anyone allowed to see pending workflows, which may include employees.</label>
            <textarea
              id="request-notes"
              rows={4}
              className={styles['request-notes-section']}
              value={notes}
              readOnly={!isActive}
              aria-readonly={!isActive}
              onChange={(e) => { setNotes(e.target.value); }}
            />
          </div>
          <div className={styles['change-button-container']}>
            {isActive ? (
              <>
                <button
                  className="btn orange-outline-button"
                  onClick={() => handleStatusChange(WorkFlowStatus.Canceled)}
                  disabled={!task.canApprove}
                >
                  Cancel{breakpoints.xs ? '' : ' Workflow'}
                </button>
                <button
                  className="btn orange-outline-button"
                  onClick={() => handleStatusChange(WorkFlowStatus.Denied)}
                  disabled={!task.canApprove}
                >
                  Deny
                </button>
                <button
                  className="btn orange-button"
                  onClick={() => handleStatusChange(WorkFlowStatus.Approved)}
                  disabled={!task.canApprove}
                >
                  Approve
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn orange-outline-button"
                  onClick={onHide}
                >
                  Close
                </button>
                <button
                  className="orange-button"
                  onClick={handleViewPdf}
                >
                  View PDF
                </button>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChangeRequestModal;