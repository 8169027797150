import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpResponse } from '../../models';
import {
  EarningCode,
  EarningsCodeAutoCalculation,
} from '../../models/EarningCode.model';
import { EarningCodeService } from '../../services/earning-code.service';
import { handleError } from '../actions';
import {
  loadEarningCodes,
  loadEarningCodesAutoCalculations,
  storeEarningCodes,
  storeEarningCodesAutoCalculations,
  storeEarningCodesAutoCalculationsMessages,
  updateEarningCodesAutoCalculations,
} from '../actions/earning-codes.action';

interface Actions {
  type: string;
  payload: any;
}

const loadEarningCodes$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEarningCodes.type),
    switchMap((action: any) => {
      return from(EarningCodeService.getEarningCodes()).pipe(
        map((res: any) => {return res.data;}),
        map((res: EarningCode[]) => {return storeEarningCodes(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadAutoCalculations$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEarningCodesAutoCalculations.type),
    switchMap((action: any) => {
      return from(EarningCodeService.getEarningCodesAutoCalculations()).pipe(
        map((res: any) => {return res.data;}),
        map((res: EarningsCodeAutoCalculation[]) => {return storeEarningCodesAutoCalculations(res);},
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const putAutoCalculations$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateEarningCodesAutoCalculations.type),
    switchMap((action: { payload: EarningsCodeAutoCalculation[]; }) => {
      return from(
        EarningCodeService.putEarningCodesAutoCalculations(
          action.payload,
        ),
      ).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<EarningsCodeAutoCalculation[]>) => {
          return [
            storeEarningCodesAutoCalculations(res.value),
            storeEarningCodesAutoCalculationsMessages(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadEarningCodes$,
  loadAutoCalculations$,
  putAutoCalculations$,
];
