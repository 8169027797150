import { createSelector } from 'reselect';
import { payrollReportReportState } from '../reducers';
import { State } from '../reducers/payroll-reports.reducer';

export const getPayroll401kReport = createSelector(
  payrollReportReportState,
  (state: State) => {return state?.payroll401kReport;},
);

export const getPayroll4070TipReport = createSelector(
  payrollReportReportState,
  (state: State) => {return state?.payroll4070TipReport;},
);

export const getCashFlowReport = createSelector(
  payrollReportReportState,
  (state: State) => {return state?.cashFlowReport;},
);

export const getAnnualFICATipCreditReport = createSelector(
  payrollReportReportState,
  (state: State) => {return state?.annualFICTipCreditReport;},
);

export const getScheduledDeductionsReport = createSelector(
  payrollReportReportState,
  (state:
  State) => {return state?.scheduledDeductionsReport;},
);

export const getPensionReport = createSelector(
  payrollReportReportState,
  (state:
  State) => {return state?.pensionReport;},
);

export const getPensionSettings = createSelector(
  payrollReportReportState,
  (state:
  State) => {return state?.pensionSettings;},
);

export const getWorkersCompnesationReport = createSelector(
  payrollReportReportState,
  (state:
  State) => {return state?.workersCompensationReport;},
);


export const getMemoCashFlowReport = createSelector(
  payrollReportReportState,
  (state:
  State) => {return state?.memoCashFlowReport;},
);


export const getEmployeeLastCheckReport = createSelector(
  payrollReportReportState,
  (state:
  State) => {return state?.employeeLastCheckReport;},
);
export const getGrossToNetReport = createSelector(payrollReportReportState, (state: any) => {return state.grossToNetReport;});
export const getUnionDuesReport = createSelector(
  payrollReportReportState,
  (state:
  State) => {return state?.unionDuesReport;},
);

export const getUnionDuesSettings = createSelector(
  payrollReportReportState,
  (state:
  State) => {return state?.unionDueSettings;},
);

export const getDeductionShortagesReport = createSelector(
  payrollReportReportState,
  (state: State) => {return state?.deductionShortagesReport;},
);

export const getMontlyCashFlowAnalysisDownloadReport = createSelector(
  payrollReportReportState,
  (state: State) => {return state?.monthlyCashFlowAnalysisDownloadReport;},
);

export const getCARESCreditReport = createSelector(
  payrollReportReportState,
  (state: State) => {return state?.caresCreditReport;},
);




