import { AxiosResponse } from 'axios';
import ConnectionOption from 'core/models/intuit/connectionOption.model'; 
import IntuitAuth from 'core/models/intuit/intuitAuth.model';
import QuickBooksConnection from 'core/models/intuit/quickBooksConnection.model';
import QuickBooksExportResult from 'core/models/intuit/quickBooksExportResult.model';
import { HttpResponse } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import {  IntuitEndpoints } from './URL';
import AccountMismatch from 'core/models/intuit/accountMismatch.model';

const getClientIntuitAuth = (): Promise<AxiosResponse<IntuitAuth>> => {
    return API_EMPLOYEE_MASTER.get<IntuitAuth>(IntuitEndpoints.GET_CLIENT_INTUIT_AUTH(CommonService.getProtectedClientNo())); ;
}

const putPayRate = (option: ConnectionOption): Promise<AxiosResponse<HttpResponse<QuickBooksConnection>>> => {
    return API_EMPLOYEE_MASTER.put<HttpResponse<QuickBooksConnection>>(IntuitEndpoints.PUT_MANAGE_QUICKBOOKS_CONNECTION(CommonService.getProtectedClientNo()), option);
}

const postExportToQuickBooks = (): Promise<AxiosResponse<HttpResponse<QuickBooksExportResult>>> => {
    return API_EMPLOYEE_MASTER.post<HttpResponse<QuickBooksExportResult>>(IntuitEndpoints.POST_EXPORT_TO_QUICKBOOKS(CommonService.getProtectedClientNo()));
}

const postChartOfAccounts = (accounts: Array<AccountMismatch>): Promise<AxiosResponse<HttpResponse<string>>> => {
    return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(IntuitEndpoints.POST_CHART_OF_ACCOUNTS(CommonService.getProtectedClientNo()), accounts);
}
    

export const IntuitService = {
    getClientIntuitAuth,
    putPayRate,
    postExportToQuickBooks,
    postChartOfAccounts
};
