import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CopyMapRequest,
  DownloaderRequest,
  EmailRequest,
  FormatExportRequest,
  ScheduledDownloadRequest,
  UserMapSelectedField,
  UserMap,
  UserMapItem,
  AvailableFont,
  DownloaderPage,
} from 'core/models/Downloader.model';
import { saveUserMap } from '../actions/employee-earnings-download.action';
import { Client } from 'core/models';

export interface DownloaderState {
  loading: boolean;
  downloader: string | null;
  userMaps: UserMap | null;
  selectedUserMap: UserMapItem | null;
  availableFonts: AvailableFont[];
  pageSectionFields: DownloaderPage[];
  selectedClients: Client[]
}

const initialState: DownloaderState = {
  loading: false,
  downloader: '',
  userMaps: null,
  selectedUserMap: null,
  availableFonts:[],
  pageSectionFields:[],
  selectedClients: [],
};

export const downloaderSlice = createSlice({
  name: 'downloader',
  initialState,
  reducers: {
    resetLoading: (state) => {
      state.loading = false;
    },
    postDownloader: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: DownloaderRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeDownloader: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.downloader = action.payload;
    },
    getDownloaderFields: (state) => {
      state.loading = true;
    },
    storeDownloaderFields: (state, action: PayloadAction<DownloaderPage[]>) => {
      state.loading = false;
      state.pageSectionFields = action.payload;
    },
    //PI-8693 We want the selected clients from the main download page to be carried over to the map page. So we store them in state.
    storeSelectedClients: (state, action: PayloadAction<Client[]>) => {
      state.selectedClients = action.payload;
    },
    getUserMaps: (state) => {
      state.loading = true;
    },
    storeUserMaps: (state, action: PayloadAction<UserMap>) => {
      state.loading = false;
      state.userMaps = action.payload;
    },
    getAvailableFonts: (state) => {
      state.loading = true;
    },
    storeAvailableFonts: (state, action: PayloadAction<AvailableFont[]>) => {
      state.loading = false;
      state.availableFonts = action.payload;
    },
    getUserMap: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (userMapId: number) => {
        return {
          payload: userMapId,
        };
      },
    },
    postUserMap: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (userMap: UserMapItem) => {
        return {
          payload: userMap,
        };
      },
    },
    putUserMap: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (userMap: UserMapItem) => {
        return {
          payload: userMap,
        };
      },
    },
    storeUserMap: (state, action: PayloadAction<UserMapItem>) => {
      state.loading = false;

      state.selectedUserMap = {
        ...action.payload,
      };
    },
    updateSelectedUserMap: (state, action: PayloadAction<UserMapItem>) => {
      state.selectedUserMap = action.payload;
    },
    putCopyMap: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: CopyMapRequest) => {
        return {
          payload: request,
        };
      },
    },
    putEmails: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (emails: EmailRequest) => {
        return {
          payload: emails,
        };
      },
    },
    putFormatExport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (format: FormatExportRequest) => {
        return {
          payload: format,
        };
      },
    },
    putScheduleDownload: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: ScheduledDownloadRequest) => {
        return {
          payload: request,
        };
      },
    },
    deleteUserMap: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (userMapId: number) => {
        return {
          payload: userMapId,
        };
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveUserMap.fulfilled, (state, action: any) => {
        state.selectedUserMap = action.payload.value;
      });
  },
});

export const {
  resetLoading,
  postDownloader,
  storeDownloader,
  getDownloaderFields,
  storeDownloaderFields,
  getAvailableFonts,
  storeAvailableFonts,
  getUserMaps,
  storeUserMaps,
  getUserMap,
  postUserMap,
  putUserMap,
  updateSelectedUserMap,
  storeUserMap,
  putCopyMap,
  putEmails,
  putFormatExport,
  putScheduleDownload,
  deleteUserMap,
  storeSelectedClients,
} = downloaderSlice.actions;
export default downloaderSlice.reducer;
