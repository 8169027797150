import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { UserPassword } from '../../models';
import { updateDefaultUserPassword } from '../../store/actions';
import { getUserAccess } from '../../store/selectors';
import { CheckboxGrpInLine, InputGrpInLine } from '../form-controls';
import { FieldInputSettings } from '../form-controls/types';
import Modal from './Modal';

const fs: FieldInputSettings = {
  password: {
    name: 'password',
    label: 'Password:',
    labelWidth: 25,
    type: 'password',
    required: true,
  },
  confirmPassword: {
    name: 'confirmPassword',
    label: 'Confirm Password:',
    labelWidth: 25,
    type: 'password',
    required: true,
  },
  saveDefaultPW: {
    name: 'saveDefaultPW',
  },
};

// type OptionalClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEventHandler<SVGElement> | undefined;
type PropTypes = {
  show: boolean;
  onHide: () => void;
  defaultPasswordData?: UserPassword;
  addPasswordToPdf?: any;
};

const PasswordModal: React.FC<PropTypes> = ({
  show,
  onHide,
  defaultPasswordData,
  addPasswordToPdf,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserAccess);

  const { getValues, register, handleSubmit, errors } = useForm<any>({
    defaultValues: {
      password: defaultPasswordData?.defaultPdfpassword,
      confirmPassword: defaultPasswordData?.defaultPdfpassword,
    },
  });

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };

  const onSave = (data: any) => {
    if (addPasswordToPdf) {
      addPasswordToPdf(data.password);
    }
    if (data.saveDefaultPW) {
      dispatch(
        updateDefaultUserPassword({
          passwordId: defaultPasswordData?.passwordId ?? 0,
          secUserId: user?.secUserId ?? 0,
          defaultPdfpassword: data.password,
        }),
      );
    }
    closeModal();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title="PASSWORD"
    >
      <div>
        To ensure document security, please enter a password.
        This password
      </div>
      <div>will need to be entered when opening the PDF.</div>
      <form onSubmit={handleSubmit(onSave)}
        className="mt-3">
        <InputGrpInLine
          {...fs.password}
          errors={errors.password}
          ref={register({
            required: {
              value: true,
              message: 'Password is required',
            },
          })}
        />
        <InputGrpInLine
          {...fs.confirmPassword}
          errors={errors.confirmPassword}
          ref={register({
            required: {
              value: true,
              message: 'Confirm Password is required',
            },
            validate: (value) => { return getValues('password') === value; },
          })}
        />
        <CheckboxGrpInLine
          {...fs.saveDefaultPW}
          ref={register()}
          label="Save this password as your default password?"
          labelFirst={false}
        />
        {errors.confirmPassword && errors.confirmPassword.type === 'validate' && (
          <div className="text-danger ml-5">
            Password and Confirm Password do not match.
          </div>
        )}
        <div className="d-flex mt-3">
          <button
            className="orange-outline-button ml-auto mr-2"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="orange-button mr-2"
          >
            Okay
          </button>
        </div>
      </form>
    </Modal>
  );
};
export default PasswordModal;
