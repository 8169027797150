import React, { ChangeEvent, useState } from 'react';
import Modal from './Modal';
import InputGrpUncontrolled from '../form-controls/InputGrpUncontrolled';
import { useAppDispatch } from 'utilities/hooks';
import { handleError, updateSqlMescMainCheckNo } from 'core/store/actions';
import { PayrollVoidSearchParams, UpdateCheckNoRequest } from 'core/models';

type Props = {
  show: boolean;
  onHide: () => void;
  checkNo: number;
  mescId: number;
  clientNo: number;
  empNo: number;
  beginDate: Date | string;
  endDate: Date | string;
  protectedEmpNo: string;
};

const UpdateCheckNoModal = ({ show, onHide, checkNo, empNo, mescId, clientNo, beginDate, endDate, protectedEmpNo }: Props) => { 
  const [newCheckNo, setNewCheckNo] = useState<number>(checkNo);
  const onUpdateCheckNo = (e: ChangeEvent<HTMLInputElement>) => {setNewCheckNo(+e.target.value)}
  const dispatch = useAppDispatch();

  const onSave = () => {
    if(!mescId || !newCheckNo) return dispatch(handleError('Error while trying to send update request.'));
    const checkNoRequest: UpdateCheckNoRequest = {
        mescId: mescId,
        checkNo: newCheckNo,
        clientNo: clientNo,
        empNo: empNo
    }

    const searchRequest: PayrollVoidSearchParams = {
        beginDate: beginDate,
        endDate: endDate,
        protectedEmpNo: protectedEmpNo
    }

    dispatch(updateSqlMescMainCheckNo({checkNoRequest: checkNoRequest, searchRequest: searchRequest}))
    onHide();
  }
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Update Check Number"
    >
      {() => {
        return (
        <div className="d-flex flex-column">
            <div className="col-auto ml-2">
              <InputGrpUncontrolled
                name={`checkNumber`}
                label="Check Number"
                defaultValue={newCheckNo}
                onChange={onUpdateCheckNo}
              />
            </div>
            <div className="d-flex w-100 justify-content-end">
              <button
                type="button"
                className="btn btn-primary orange-button mr-3"
                onClick={onHide}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary orange-button"
                onClick={onSave}
                disabled={checkNo === newCheckNo}
              >
                Save
              </button>
            </div>
        </div>
        );        
      }}
    </Modal>
  );
};

export default UpdateCheckNoModal;