import React, { } from 'react';

import {
  InputGrp,
  SelectModalGrp,
} from '../../../core/components/form-controls';
import { HRSkills } from '../../../core/models/HREmployee.model';
import DropdownOptionForm from '../../../core/components/form-controls/select-modal/DropdownOptionForm';
import { ArrayField } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getSkillTypes } from '../../../core/store/selectors';

import Icon from 'core/components/shared/Icon';

type PropTypes = {
  item: Partial<ArrayField<HRSkills>>;
  index: number;
  control: any;
  formMethods: any;
  onDelete: (item: HRSkills) => void;
};

const SkillsItem: React.FC<PropTypes> = ({
  item,
  index,
  control,
  formMethods: { setValue, register },
  onDelete,
}) => {

  const skillTypes = useSelector(getSkillTypes);

  return (

    <div className="d-flex flex-column">
      <div className="d-flex flex-wrap">
        <input
          type="hidden"
          name={`skillsItems[${index}].empSkillId`}
          defaultValue={item.empSkillId}
          ref={register({ valueAsNumber: true })}
        />
        <SelectModalGrp
          noOption={true}
          groupClass="gc12 mw150"
          modalTitle="SKILL TYPE"
          label="SKILL TYPE"
          formComponent={DropdownOptionForm}
          dropdownName="EmpSkillTypes"
          addOptionText="Skill Type"
          name={`skillsItems[${index}].skillTypeId`}
          options={skillTypes}
          control={control}
          value={item.skillTypeId}
          setValue={setValue} />
        <InputGrp
          name={`skillsItems[${index}].years`}
          label="YEARS"
          defaultValue={item.years}
          groupClass="gc12 mw125"
          ref={register({ valueAsNumber: true })} />
        <InputGrp
          name={`skillsItems[${index}].month`}
          defaultValue={item.month}
          label="MONTHS"
          groupClass="gc12 mw125"
          ref={register({ valueAsNumber: true })} />
        <InputGrp
          name={`skillsItems[${index}].comments`}
          defaultValue={item.comments}
          label="COMMENTS"
          groupClass="gc12"
          ref={register()} />

        <div className="text-right">
          <button
            type="button"
            className="btn btn-link dm-grid-action-title mt-2"
            onClick={() => { return onDelete(item as HRSkills); }}
          >
            Delete Skill
            <Icon name="minus-circle"
              className="fa-minus-circle" />
          </button>
        </div>
      </div>

    </div>
  );
};

export default SkillsItem;
