import { AxiosResponse } from "axios";
import { HttpResponse } from '../models';
import { CommonService } from './common.service';
import { BankDisbursementsReportRequest, CARES_SBA_PPP_Request, EmployeeAverageHoursReportRequest, EmployeeAverageHoursWithStatusReportRequest, GeneralLedgerDetailReportRequest, GeneralLedgerExportReportRequest, GeneralLedgerReport, GeneralLedgerReportRequest, HoursEarningsReportRequest, NewJobTrainingCreditReportRequest, OverTimeSetting } from '../models/AccountingReports.model';
import { AccountingReportsEndpoints, ReportsEndpoints } from './URL';
import { API_EMPLOYEE_MASTER } from './API';

const postBankDisbursementsReportsTab = (request: BankDisbursementsReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    AccountingReportsEndpoints.POST_BANK_DISBURSEMENTS_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postHoursEarningsReport = (data: HoursEarningsReportRequest): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(AccountingReportsEndpoints.HOURS_EARNINGS_REPORT(CommonService.getProtectedClientNo()), data); };

const getOverTimeSettings = (): Promise<AxiosResponse<any>> => { return API_EMPLOYEE_MASTER.get<OverTimeSetting[]>(AccountingReportsEndpoints.OVERTIME_SETTINGS(CommonService.getProtectedClientNo())); };

const postOverTimeSettings = (data: OverTimeSetting): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(AccountingReportsEndpoints.OVERTIME_SETTINGS(CommonService.getProtectedClientNo()), data); };

const putOverTimeSettings = (data: OverTimeSetting): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<any>>(AccountingReportsEndpoints.OVERTIME_SETTINGS_ID(CommonService.getProtectedClientNo(), data.otid!), data); };

const deleteOverTimeSettings = (data: OverTimeSetting): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.delete<HttpResponse<any>>(AccountingReportsEndpoints.OVERTIME_SETTINGS_ID(CommonService.getProtectedClientNo(), data.otid!)); };

const postNewJobTrainingCreditReport = (request: NewJobTrainingCreditReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    AccountingReportsEndpoints.POST_NEW_JOB_TRAINING_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postEmployeeAverageHoursReportTab = (request: EmployeeAverageHoursReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ReportsEndpoints.POST_EMPLOYEE_AVERAGE_HOURS_REPORT(
    ), request);
};

const postEmployeeAverageHoursWithStatusReportTab = (request: EmployeeAverageHoursWithStatusReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ReportsEndpoints.POST_EMPLOYEE_AVERAGE_HOURS_REPORT(
    ), request);
};

const postGeneralLedgerReport = (request: GeneralLedgerReportRequest): Promise<AxiosResponse<HttpResponse<GeneralLedgerReport>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<GeneralLedgerReport>>(
    AccountingReportsEndpoints.POST_GENERAL_LEDGER_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postGeneralLedgerReportErrors = (request: GeneralLedgerReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    AccountingReportsEndpoints.POST_GENERAL_LEDGER_REPORT_ERRORS(
      CommonService.getProtectedClientNo()), request);
};

const postGeneralLedgerDetailReport = (request: GeneralLedgerDetailReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    AccountingReportsEndpoints.POST_GENERAL_LEDGER_DETAIL_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postGeneralLedgerExportReport = (request: GeneralLedgerExportReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    AccountingReportsEndpoints.POST_GENERAL_LEDGER_EXPORT_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const post_CARES_SBA_PPP_Report = (request: CARES_SBA_PPP_Request): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    AccountingReportsEndpoints.POST_CARES_SBA_PPP_REPORT(
      CommonService.getProtectedClientNo()), request);
};

export const AccountingService = {
  postBankDisbursementsReportsTab,
  postHoursEarningsReport,
  getOverTimeSettings,
  postOverTimeSettings,
  putOverTimeSettings,
  deleteOverTimeSettings,
  postNewJobTrainingCreditReport,
  postEmployeeAverageHoursReportTab,
  postEmployeeAverageHoursWithStatusReportTab,
  postGeneralLedgerReport,
  postGeneralLedgerReportErrors,
  postGeneralLedgerDetailReport,
  postGeneralLedgerExportReport,
  post_CARES_SBA_PPP_Report,
};

