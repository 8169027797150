import React from 'react';
import Icon from 'core/components/shared/Icon';

type Props = {
  expanded: boolean;
};

const DisclosureIndicator = ({ expanded }: Props) => {
  return (
    <div
      role="button"
      className="payroll-card-chevron-wrapper h-100"
    >
      <Icon
        name={expanded ? 'chevron-down' : 'chevron-right'}
        className="payroll-card-chevron"
        fontSize={'1.1rem'}
      />
    </div>
  );
};

export default DisclosureIndicator;