import { AxiosResponse } from "axios";
import { DateTime } from 'luxon';
import {
  EmpGrossPayAnalysis,
  EmpPaidBreakdown,
  IssueToBeResolved,
  NewFeature,
  NewHireBreakdown,
  Reminder,
} from '../models';
import { PayrollTotalsByLocDeptSubSummary } from '../models/PayrollTotalsByLocDeptSubSummary';
import { PayrollTotalsByTimePeriodSummary } from '../models/PayrollTotalsByTimePeriodSummary';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { DashboardEndPoints } from './URL';

const getNewFeatures = (): Promise<AxiosResponse<NewFeature[]>> => {
  return API_EMPLOYEE_MASTER.get<NewFeature[]>(
    DashboardEndPoints.GET_NEWFEATURES(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getReminders = (): Promise<AxiosResponse<Reminder[]>> => {
  return API_EMPLOYEE_MASTER.get<Reminder[]>(
    DashboardEndPoints.GET_REMINDERS(CommonService.getProtectedClientNo()),
  );
};

const getEmpPaidBreakdown = (): Promise<AxiosResponse<EmpPaidBreakdown>> => {
  return API_EMPLOYEE_MASTER.get<EmpPaidBreakdown>(
    DashboardEndPoints.GET_EMPPAIDBREAKDOWN(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getIssues = (): Promise<AxiosResponse<IssueToBeResolved[]>> => {
  return API_EMPLOYEE_MASTER.get<IssueToBeResolved[]>(
    DashboardEndPoints.GET_ISSUESTOBERESOLVED(
      CommonService.getProtectedClientNo(),
      DateTime.now().toFormat('yyyy'),
    ),
  );
};

const getEmpGrossAnalysis = (): Promise<AxiosResponse<EmpGrossPayAnalysis>> => {
  return API_EMPLOYEE_MASTER.get<EmpGrossPayAnalysis>(
    DashboardEndPoints.GET_EMPGROSSPAYANALYSIS(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getNewHireBreakdown = (): Promise<AxiosResponse<NewHireBreakdown>> => {
  return API_EMPLOYEE_MASTER.get<NewHireBreakdown>(
    DashboardEndPoints.GET_EMPAPPLICANTNEWHIRE(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getPayrollTotalByTimePeriod = (): Promise<
  AxiosResponse<PayrollTotalsByTimePeriodSummary>
> => {
  return API_EMPLOYEE_MASTER.get<PayrollTotalsByTimePeriodSummary>(
    DashboardEndPoints.GET_PAYROLLTOTALSBYTIMEPERIODSUMMARY(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getPayrollTotalsByLocDeptSub = (): Promise<
  AxiosResponse<PayrollTotalsByLocDeptSubSummary>
> => {
  return API_EMPLOYEE_MASTER.get<PayrollTotalsByLocDeptSubSummary>(
    DashboardEndPoints.GET_PAYROLLTOTALSBYLOCDEPTSUB(
      CommonService.getProtectedClientNo(),
    ),
  );
};

export const DashboardService = {
  getNewFeatures,
  getReminders,
  getEmpPaidBreakdown,
  getIssues,
  getEmpGrossAnalysis,
  getNewHireBreakdown,
  getPayrollTotalByTimePeriod,
  getPayrollTotalsByLocDeptSub,
};
