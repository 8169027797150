import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { InputGrp, InputGrpDecimal } from '..';
import { PaygradeDropdown } from '../../../models';
import {
  createPaygradeDropdown,
  updatePaygradeDropdown,
} from '../../../store/actions';
import { FieldInputSettings } from '../types';

const fs: FieldInputSettings = {
  code: {
    name: 'code',
    label: 'Code',
    labelWidth: 25,
    required: true,
  },
  description: {
    name: 'description',
    label: 'Description',
    labelWidth: 25,
    required: true,
  },
  minAmount: {
    name: 'minAmount',
    label: 'Min',
    labelWidth: 25,
    required: true,
  },
  midAmount: {
    name: 'midAmount',
    label: 'Mid',
    labelWidth: 25,
    required: true,
  },
  maxAmount: {
    name: 'maxAmount',
    label: 'Max',
    labelWidth: 25,
    required: true,
  },
};

type PropTypes = {
  item: PaygradeDropdown;
  options: any[];
  onHide: any;
};

const GradeLevelOptionForm: React.FC<PropTypes> = ({
  item,
  options,
  onHide,
}) => {
  const dispatch = useDispatch();

  const { register, errors, handleSubmit, setError, clearErrors } = useForm({
    defaultValues: {
      ...item,
      minAmount: item.minAmount || 0,
      midAmount: item.midAmount || 0,
      maxAmount: item.maxAmount || 0,
    },
  });

  const onCodeChange = (e: any) => {
    options.find((l: PaygradeDropdown) => {return l.code === e.target.value;})
      ? setError('code', {
        type: 'BadPayGradeCode',
        message: 'Paygrade Code already exists.',
      })
      : clearErrors('code');
  };

  const onSubmit = (data: any, event?: React.BaseSyntheticEvent) => {
    event?.preventDefault();
    event?.stopPropagation();
    const updatedPaygrade = { ...item, ...data } as PaygradeDropdown;
    updatedPaygrade.code = data.code;
    updatedPaygrade.id = '' + item.id;
    if (updatedPaygrade.id) {
      dispatch(updatePaygradeDropdown(updatedPaygrade));
    } else {
      updatedPaygrade.id = '0';
      dispatch(createPaygradeDropdown(updatedPaygrade));
    }
    onHide(false);
  };

  return (
    <div
      className="dm-panel dm-panel-border mx-4 d-flex flex-column"
    >
      <div className="dm-grid-action-title">Add/Edit</div>
      <form id="option-form" onSubmit={handleSubmit(onSubmit)}>
        <InputGrp
          {...fs.code}
          errors={errors.code}
          onChange={onCodeChange}
          ref={register}
        />
        <InputGrp
          {...fs.description}
          errors={errors.description}
          ref={register({ required: 'Description is Required' })}
        />
        <InputGrpDecimal
          {...fs.minAmount}
          errors={errors.minAmount}
          ref={register({
            valueAsNumber: true,
            min: {
              value: 0.01,
              message: 'Must be greater than 0',
            },
          },
          )}
        />
        <InputGrpDecimal
          {...fs.midAmount}
          errors={errors.midAmount}
          ref={register({
            valueAsNumber: true, min: {
              value: 0.01,
              message: 'Must be greater than 0',
            },
          })}
        />
        <InputGrpDecimal
          {...fs.maxAmount}
          errors={errors.maxAmount}
          ref={register({
            valueAsNumber: true, min: {
              value: 0.01,
              message: 'Must be greater than 0',
            },
          })}
        />

        <div className="d-flex">
          <button
            type="button"
            className="btn btn-primary orange-outline-button ml-auto mr-2"
            onClick={() => {return onHide(false);}}
          >
            Cancel
          </button>
          <button type="submit" form="option-form" className="orange-button mr-2">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default GradeLevelOptionForm;
