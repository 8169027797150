import React, { CSSProperties } from 'react';
import styles from './shared.module.scss';

type Props = {
  additionalStyles?: CSSProperties;
};

const ThreeDotLoader = ({ additionalStyles }: Props) => {
  return (
    <span
      className={styles['dot-loader']}
      style={additionalStyles}
    >
    </span>
  );
};

export default ThreeDotLoader;