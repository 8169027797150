import { AgGridReact } from '@ag-grid-community/react';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { LookupEEOUnit } from 'core/models/HumanCapitalReports.model';
import { CheckboxGrp, CheckboxGrpInLine, InputGrp, SelectGrp } from 'core/components/form-controls';
import { ColDef, FirstDataRenderedEvent, GridOptions, SelectionChangedEvent } from '@ag-grid-enterprise/all-modules';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { useSelector } from 'react-redux';
import { createLookupEEOUnit, deleteLookupEEOUnit, loadLookupEEOUnits, putClientOption, updateLookupEEOUnit } from 'core/store/actions';
import { getLookupEEOUnit, getStates } from 'core/store/selectors';
import { Client } from 'core/models';
import Select from 'react-select';
import Icon from 'core/components/shared/Icon';
import { getUsers } from 'core/store/slices/cmUsers.slice';
import { agCheckboxRenderer } from 'utilities/ag-grid-renderers/agCheckboxRenderer';
import { agCheckboxEditor } from 'utilities/ag-grid-editors/agCheckboxEditor';
import CertifyingOfficer from './CertifyingOfficer';

const CheckBoxRenderer = () => {
  return (
    <div>
      <CheckboxGrpInLine
        id=""
        name=""
        disabled={true}
      />
    </div>
  );
};

//#region for Columns
const columns: ColDef[] = [
  {
    field: 'number',
    headerName: 'Number',
    width: 100,
    maxWidth: 130,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 100,
    maxWidth: 130,
  },
  {
    field: 'address',
    headerName: 'Address',
    editable: false,
    width: 100,
    maxWidth: 130,
  },
  {
    field: 'address2',
    headerName: 'Address 2',
    editable: false,
    width: 100,
    maxWidth: 130,
  },
  {
    field: 'city',
    headerName: 'City',
    editable: false,
    width: 100,
    maxWidth: 130,
  },
  {
    field: 'state',
    headerName: 'State',
    editable: false,
    width: 100,
    maxWidth: 130,
  },
  {
    field: 'bradStreetNo',
    headerName: 'Don & Brad Street No.',
    editable: false,
    width: 100,
    maxWidth: 130,
  },
  {
    field: 'county',
    headerName: 'County',
    editable: false,
    width: 100,
    maxWidth: 130,
  },
  {
    field: 'nasics',
    headerName: 'NASICS',
    editable: false,
    width: 100,
    maxWidth: 130,
  },
  {
    field: 'sectionB2C',
    headerName: 'Q/A B2C',
    cellRenderer: CheckBoxRenderer,
    width: 100,
    maxWidth: 130,
    editable: false,
  },
  {
    field: 'sectionC1',
    headerName: 'Q/A C1',
    cellRenderer: CheckBoxRenderer,
    width: 100,
    maxWidth: 130,
    editable: false,
  },
  {
    field: 'sectionC2',
    headerName: 'Q/A C2',
    cellRenderer: CheckBoxRenderer,
    width: 100,
    maxWidth: 130,
    editable: false,
  },
  {
    field: 'sectionC3',
    headerName: 'Q/A C3',
    cellRenderer: CheckBoxRenderer,
    width: 100,
    maxWidth: 130,
    editable: false,
  },
  {
    field: 'sectionD2',
    headerName: 'Q/A D2',
    cellRenderer: CheckBoxRenderer,
    width: 100,
    maxWidth: 130,
    editable: false,
  },
];
  //#endregion

const statusOpts = [
  {
    id: 1,
    description: 'Single-Established Employer',
  },
  {
    id: 2,
    description: 'Consolidated Report',
  },
  {
    id: 3,
    description: 'Headquarters Report',
  },
  {
    id: 4,
    description: 'Establishment Report',
  },
  {
    id: 5,
    description: 'Special Reporting Procedure',
  },
  {
    id: 8,
    description: 'Less Than 50 Employees',
  },
  {
    id: 9,
    description: '50 Employees - First Time Reporting',
  },
];

type EeoUnitDropdownOpt = LookupEEOUnit & {
  value: string;
  label: number;
};

type PropTypes = {
  show: boolean;
  onHide: () => void;
};

const EEOUnitModal: React.FC<PropTypes> = ({ show, onHide }) => {
  const dispatch = useAppDispatch();
  
  const client = useAppSelector((state) => { return state.client.client; }) as Client;
  const states = useSelector(getStates);
  const eeoLookUpUnitData = useSelector(getLookupEEOUnit);
  const cmOptions = useAppSelector(({ client }) => client?.clientOptions?.options);
  const userIdCmOption = cmOptions?.[2543];
  const hqUnitNoCmOption = cmOptions?.[388];
  
  const [selectedRowData, setSelectedRowData] = useState<LookupEEOUnit>();
  const [userId, setUserId] = useState<string>(userIdCmOption?.optionValue ?? '');
  const [hqUnitNo, setHqUnitNo] = useState<string>(hqUnitNoCmOption?.optionValue ?? '');
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isAddingNewRecord, setIsAddingNewRecord] = useState<boolean>(false);

  const { register, reset, handleSubmit, errors } = useForm<LookupEEOUnit>({
    defaultValues: {
      eeoUnitID: 0,
      clientEntityID: undefined,
      number: '',
      statusCode: '',
      name:  '',
      address: '',
      address2:  '',
      city: '',
      state:  '',
      zip:  '',
      bradStreetNo:  '',
      county:  '',
      nasics:  '',
      certifyOfficerID: undefined,
      eeocAssignedNumber:undefined,
      sectionB2C: false,
      sectionC1: false,
      sectionC2: false,
      sectionC3: false,
      sectionD2: false,
    },
  });

  const converted = eeoLookUpUnitData?.map(item => {
    return {
      ...item,
      value: item?.eeoUnitID,
      label: item?.name,
    };
  });

  useEffect(() => {
    dispatch(getUsers());
    dispatch(loadLookupEEOUnits());
  }, []);
  
  const editSectionTitle = !(isAddingNewRecord || !!selectedRowData)
    ? `Click "Add EEO Unit" ${!eeoLookUpUnitData?.length ? ''
      : 'or select a record from the list'} to edit.` : undefined;
  
  const updateClientOptions = () => {
    if (userIdCmOption && userId !== userIdCmOption?.optionValue) {
      dispatch(putClientOption({
        ...userIdCmOption,
        optionValue: userId ?? null,
      }));
    }
    if (hqUnitNoCmOption && hqUnitNo !== hqUnitNoCmOption?.optionValue) {
      dispatch(putClientOption({
        ...hqUnitNoCmOption,
        optionValue: hqUnitNo ?? null,
      }));
    }
  };

  const onSubmit = (data: LookupEEOUnit) => {
    if (!(eeoLookUpUnitData?.length || isAddingNewRecord)) return alert('You must create at least one establishment record by clicking "Add EEO Unit".');
    if (!(selectedRowData || isDirty)) return alert('Please select a unit from the list or click "Add EEO Unit".');
    
    const submitData: LookupEEOUnit = {
      eeoUnitID: data?.eeoUnitID,
      clientEntityID: client.clientEntityID,
      number: data?.number,
      statusCode: data?.statusCode,
      eeocAssignedNumber: data?.eeocAssignedNumber,
      name: data?.name,
      address: data?.address,
      address2: data?.address2,
      city: data?.city,
      state: data?.state,
      zip: data?.zip,
      bradStreetNo: data?.bradStreetNo,
      county: data?.county,
      nasics: data?.nasics,
      certifyOfficerID: data?.certifyOfficerID,     
      sectionB2C: data?.sectionB2C ? true : false,
      sectionC1: data?.sectionC1 ? true : false,
      sectionC2: data?.sectionC2 ? true : false,
      sectionC3: data?.sectionC3 ? true : false,
      sectionD2: data?.sectionD2 ? true : false,
    };

    if (data?.eeoUnitID) {
      dispatch(updateLookupEEOUnit(submitData))
        .then((_res) => {
          setIsDirty(false);
          setIsAddingNewRecord(false);
        });
    } else {
      dispatch(createLookupEEOUnit(submitData))
        .then((_res) => {
          setIsDirty(false);
          setIsAddingNewRecord(false);
        });
    }

    updateClientOptions();
  };
  
  const onDelete = () => {
    if (!selectedRowData) return;
    dispatch(deleteLookupEEOUnit(selectedRowData));
    setSelectedRowData(undefined);
  };

  //Grid Options
  const gridOptions: GridOptions = {
    columnDefs: columns,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },   
    components: {
      checkboxRenderer: agCheckboxRenderer,
      checkboxEditor: agCheckboxEditor,
      CheckBoxRenderer: CheckBoxRenderer,
    },
    rowSelection: 'single',
  };

  const onFirstDataRendered = (e: FirstDataRenderedEvent) => {
    const selectedRow = e.api.getDisplayedRowAtIndex(0);
    if (!selectedRow) return;
    
    selectedRow.setSelected(true, true);
  };
  
  const onSelectionChanged = (e: SelectionChangedEvent) => {
    const selectedRows = e.api.getSelectedRows();
    if (!selectedRows) return;
    
    setSelectedRowData(selectedRows[0]);
    reset(selectedRows[0]);
    setIsAddingNewRecord(false);
  };

  const closeModal = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.stopPropagation();
    onHide();
  };
  
  const myOptionGroupStyle = {
    width: '200px',
    padding:5,
  };

  const GroupHeading = () => {
    return (
      <div
        className="d-flex flex-row ustify-content-space-evenly"
        style={{ backgroundColor: '#E5E5E5' }}
      >
        <div style={myOptionGroupStyle}>Number</div>
        <div style={myOptionGroupStyle}>Name</div>
        <div style={myOptionGroupStyle}>Address</div>
        <div style={myOptionGroupStyle}>City</div>
        <div style={myOptionGroupStyle}>State</div>
        <div style={myOptionGroupStyle}>Zip</div>
        <div style={myOptionGroupStyle}>NASICS</div>
      </div>
    );
  };
   
  const myOptionStyle = {
    width: '200px',
    padding:5,
    pointer:'cursor',
  };

  
  // TODO: Get this type right. Using the unit class in the selector, but the interface for the rest of this screen?
  const MyOption = (props: any /* OptionProps<EeoUnitDropdownOpt> */) => {
    const { innerProps, innerRef } = props;
    return (
      <div
        {...innerProps}
        ref={innerRef}
        className="d-flex flex-row justify-content-space-evenly"
      >
        <div style={myOptionStyle}>{props.data?.number}</div>
        <div style={myOptionStyle} >{props.data?.name}</div>
        <div style={myOptionStyle}>{props.data?.address}</div>
        <div style={myOptionStyle}>{props.data?.city}</div>
        <div style={myOptionStyle}>{props.data?.state}</div>
        <div style={myOptionStyle}>{props.data?.zip}</div>
        <div style={myOptionStyle}>{props.data?.nasics}</div>
      </div>
    );
  };
  
  const resetEeoUnit = () => {
    setIsDirty(true);
    setIsAddingNewRecord(true);
    reset({
      eeoUnitID: 0,
      clientEntityID: undefined,
      number: '',
      statusCode: '',
      name:  '',
      address: '',
      address2:  '',
      city: '',
      state:  '',
      zip:  '',
      bradStreetNo:  '',
      county:  '',
      nasics:  '',
      certifyOfficerID: undefined,
      eeocAssignedNumber:undefined,
      sectionB2C: false,
      sectionC1: false,
      sectionC2: false,
      sectionC3: false,
      sectionD2: false,
    });
  };

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="modal-md"
        animation={false}
      >
        <div className="modal-header" >
          <div className="dm-grid-title"> EEO Unit</div>
          <div
            id="beforeGrid"
            className="d-flex row-wrap flex-wrap"
            style={{ marginLeft: 'auto', marginRight: '25px' }}
          >
            <div>
              <button
                type="button"
                className="btn btn-link dm-grid-action-title pb-0"
                onClick={resetEeoUnit}
              >
                Add EEO Unit
                <Icon
                  name="plus-circle"
                  className="fa-plus-circle"
                />
              </button>
            </div>
          </div>
          <button
            type="button"
            onClick={closeModal}
            className="modal-close-btn ml-0"
          >
            <Icon name="times" />
          </button>
        </div>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className="d-flex flex-row flex-grow-1"
              style={{ justifyContent: 'space-around' }}
            >  
              <input
                type="hidden"
                name={'eeoUnitID'}          
                ref={register({ valueAsNumber: true })}
              />     
              <input
                type="hidden"
                name={'certifyOfficerID'}          
                ref={register({ valueAsNumber: true })}
              />            
              <div
                className="d-flex flex-column"
                style={{ width: '600px' }}
              >
                <label className="dm-form-label mt-1">Headquarters Unit No</label>
                <Select                 
                  name="select"
                  components={{
                    Option: MyOption,
                    GroupHeading,
                  }}
                  value={converted?.find((opt) => opt.label === hqUnitNo)}
                  onChange={(newVal) => {
                    setHqUnitNo(newVal?.label ?? '');
                    setIsDirty(true);
                  }}
                  options={[{ options: converted }]}
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                />
              </div>
              <div>
                <InputGrp
                  name="userId"
                  label="User ID"
                  style={{ height:35 }}
                  value={userId}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUserId(e.target.value);
                    setIsDirty(true);
                  }}
                />
              </div>
            </div>
            <div
              className="d-flex flex-column flex-grow-1"
              title={editSectionTitle}
            >
              <div className="d-flex flex-row row-wrap">
                <div
                  className="ag-theme-balham mt-2"
                  style={{
                    height: '200px',
                    width: '1300px',
                  }}
                >
                  <AgGridReact
                    gridOptions={gridOptions}
                    rowData={eeoLookUpUnitData}
                    onFirstDataRendered={onFirstDataRendered}
                    onSelectionChanged={onSelectionChanged}
                  />
                </div>
              </div>
              <div className="d-flex flex-grow-1 mt-2">
                <div
                  className="d-flex flex-column flex-grow-1 p-2"
                  style={{ backgroundColor: '#F0F6FC' }}
                >
                  <div id="establishmentSection">
                    <div className="dm-grid-title">Establishment</div>
                  </div>
                  <div
                    id="middleRow"
                    className="d-flex flex-column flex-grow-1"
                  >
                    <div
                      id="1stRow"
                      className="d-flex flex-row"
                    >
                      <InputGrp
                        name="number"
                        ref={register}
                        label={'UNIT'}
                        readOnly={!(isAddingNewRecord || !!selectedRowData)}
                      />
                      {/* TODO: change spelling from NASICS to NAICS in backend */}
                      <InputGrp
                        name="nasics"
                        type="number"
                        label={'NAICS'}
                        ref={register({                          
                          maxLength: {
                            value: 6,
                            message: 'NAICS must be 6 characters or less',
                          } })}
                        errors={errors.nasics}
                        readOnly={!(isAddingNewRecord || !!selectedRowData)}
                      />
                      <InputGrp
                        name="eeocAssignedNumber"
                        label={'EEOC ASSIGNED #'}
                        ref={register}
                        readOnly={true}
                      />
                      <InputGrp
                        name="name"
                        label={'Name'}
                        ref={register}
                        readOnly={!(isAddingNewRecord || !!selectedRowData)}
                      />
                      <InputGrp
                        name="bradStreetNo"
                        label={"Dun 'N BradStreet"}
                        ref={register({                          
                          maxLength: {
                            value: 9,
                            message: 'Dun `N BradStreet must be 9 characters or less',
                          } })}
                        errors={errors.bradStreetNo}
                        readOnly={!(isAddingNewRecord || !!selectedRowData)}
                      />
                      <SelectGrp
                        name="statusCode"
                        label={'Status CD'}
                        options={statusOpts}
                        ref={register}
                        required={isAddingNewRecord}
                        readOnly={!(isAddingNewRecord || !!selectedRowData)}
                      />
                    </div>
                    <div
                      id="2ndRow"
                      className="d-flex flex-row flex-grow-1"
                    >
                      <InputGrp
                        label={'Address 1'}
                        ref={register}
                        name="address"
                        readOnly={!(isAddingNewRecord || !!selectedRowData)}
                      />
                      <InputGrp
                        name="address2"
                        label={'Address 2'}
                        ref={register}
                        readOnly={!(isAddingNewRecord || !!selectedRowData)}
                      />
                      <InputGrp
                        label={'City'}
                        name="city"
                        ref={register}
                        readOnly={!(isAddingNewRecord || !!selectedRowData)}
                      />
                      <SelectGrp
                        label={'State'}
                        options={states}
                        ref={register}
                        name="state"
                        readOnly={!(isAddingNewRecord || !!selectedRowData)}
                      />
                      <InputGrp
                        name="county"
                        label={'County'}
                        ref={register}
                        readOnly={!(isAddingNewRecord || !!selectedRowData)}
                      />
                      <InputGrp
                        name="zip"
                        label={'ZIP CODE'}
                        ref={register}
                        readOnly={!(isAddingNewRecord || !!selectedRowData)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="rowForCheckboxes"
                className="d-flex flex-column flex-wrap flex-grow-1 p-2"
                style={{
                  backgroundColor: '#F0F6FC',
                  padding: '0px 10px 0px 10px',
                }}
              >
                <div
                  id="borderForCheckboxes"
                  style={{ border: '4px solid white' }}
                  className="p-2"
                >
                  <div className="d-flex">
                    <div className="mr-2">
                      <CheckboxGrp
                        name="sectionB2C"
                        ref={register}
                        disabled={!(isAddingNewRecord || !!selectedRowData)}
                      />
                    </div>
                    <div>
                      <label htmlFor="">
                        Is your company affiliated through common ownership and/or centralized
                        management with other entities in an enterprise with a total employment
                        of 100 or more?
                      </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="mr-2">
                      <CheckboxGrp
                        name="sectionC1"
                        ref={register}
                        disabled={!(isAddingNewRecord || !!selectedRowData)}
                      />
                    </div>
                    <div>
                      <label htmlFor="">
                        Was an EEO-1 report filed for this establishment last year?
                      </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="mr-2">
                      <CheckboxGrp
                        name="sectionC2"
                        ref={register}
                        disabled={!(isAddingNewRecord || !!selectedRowData)}
                      />
                    </div>
                    <div>
                      <label htmlFor="">
                        Does the entire company have at least 100 employees in the payroll period for which you are reporting?
                      </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="mr-2">
                      <CheckboxGrp
                        name="sectionC3"
                        ref={register}
                        disabled={!(isAddingNewRecord || !!selectedRowData)}
                      />
                    </div>
                    <div>
                      <label htmlFor="">
                        Does the company or any of its establishments <b> (a)</b> have 50 or more employees
                        {' '}<b>AND</b> <b>(b)</b> is not exempt as provided by 41 CFR 60-1.5, {' '}
                        <b>AND</b> <b>either</b> <b>(1)</b> is a prime government contractor or first-tier subcontractor,
                        and has a contract, subcontract or purchase order amounting to $50,000
                        or more, or <b>(2)</b> serves as a depository of Governement funds in any amount
                        or is a finanical instution which is an issuing and paying agent for U.S. Savings
                        Bonds and Savings Notes?
                      </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="mr-2">
                      <CheckboxGrp
                        name="sectionD2"
                        ref={register}
                        disabled={!(isAddingNewRecord || !!selectedRowData)}
                      />
                    </div>
                    <div>
                      <label htmlFor="">
                        Is this establishment: <b>(1)</b> Not exempt as provided for by 41 CFS 60-1.5; <b>(2)</b> A
                        prime contractor or first-tier subcontractor; and <b>(3)</b> have a contract,
                        subcontract, or purchase order amounting to $50,000 or more; Or serve as a depository of government
                        funds in any amount; Or is a finanacial institution which is an issuing and paying agent for U.S. Savings Bonds and
                        Savings Notes?
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <CertifyingOfficer />
              <div
                className="d-flex flex-grow-1 text-right mt-4 "
                style={{ justifyContent: 'flex-end' }}
              >
                <button
                  type="button"
                  className="btn  orange-outline-button mr-2"
                  onClick={onDelete}
                  disabled={!(selectedRowData || !!eeoLookUpUnitData?.length)}
                >
                  Delete Unit
                </button>
                <button
                  type="submit"
                  className="orange-button mr-2"
                  disabled={!(selectedRowData || isDirty || isAddingNewRecord)}
                >
                  Save Unit
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

    </div>
  );
};
export default EEOUnitModal;