import { createSelector } from 'reselect';
import { Sso } from '../../models/Sso.model';
import { ssoState } from '../reducers';
import { SsoState } from '../reducers/sso.reducer';

export const getSso = createSelector(
  ssoState,
  (state: SsoState): Sso | null => {
    return state.sso;
  },
);
