import { createSelector } from 'reselect';
import { employeesFeatureState } from '../reducers';
import { State as EmployeesState } from '../reducers/employees.reducer';
import { convToFormObject } from 'utilities/classUtils';
import { EmpDate } from 'core/models';
import { IOption, ISelectMultipleOption } from 'interfaces/IOption';

export const getEmployees = createSelector(
  employeesFeatureState,
  (state: EmployeesState) => {
    return state.employees.map(e => {
      return {
        ...e,
        hireDate: typeof e.hireDate === 'string' ? new Date(e.hireDate) : null,
        termDate: e.termDate ? new Date(e.termDate) : null,
        birthDate: e.birthDate ? new Date(e.birthDate) : null,
      };
    });
  },
);

export const getFilteredEmployees = createSelector(employeesFeatureState, (state: EmployeesState) => {
  return state.filteredEmployees.map(e => {
    return {
      ...e,
      hireDate: typeof e.hireDate === 'string' ? new Date(e.hireDate) : null,
      termDate: e.termDate ? new Date(e.termDate) : null,
      birthDate: e.birthDate ? new Date(e.birthDate) : null,
    };
  });
});

export const getFilterModel = createSelector(employeesFeatureState, (state: EmployeesState) => {return state.filterModel;});
export const getRadioSelection = createSelector(employeesFeatureState, (state: EmployeesState) => {return state.radioSelection;});

export const getSupervisors = createSelector(
  employeesFeatureState,
  (state: EmployeesState) => {return state.supervisors;},
);

export const getEmpDate = createSelector(
  employeesFeatureState,
  (state: EmployeesState) => {
    if (!state?.empDate) return null;

    return convToFormObject(state.empDate, EmpDate.convProps) as EmpDate;
  },
);

export const getEmployeesIOptions = createSelector(
  employeesFeatureState,
  (state: EmployeesState) => {
    return state.employees.map(e => {
      return {label: `${e.empNo} - ${e.lastName},${e.firstName}`, value: e.empId, displayValue: e.empNo}
    }) as ISelectMultipleOption[]
  });