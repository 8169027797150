import Modal from 'core/components/modals/Modal';
import React from 'react';

const MissingFile: React.FC<any> = ({ show, onHide }) => {
  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="UPLOAD FILE"
    >
      <div>Please upload a data file before continuing.</div>
      <div className="row mt-4">
        <div className="col-12 text-right">
          <button type="button"
            className="btn btn-primary orange-outline-button"
            onClick={closeModal}
          >
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MissingFile;