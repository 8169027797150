import React, { useMemo, DependencyList } from 'react';

type PaginationData = {
  currentRange: [number, number];
  numPages: number;
};

/**
 * Hook that can be used to break a large list into pages with `pageSize` items per page. Intended for use with the 
 * Pagination component, but can be used with anything else that you build.
 * @param totalCount - The length of the list.
 * @param currentPage - The current page to display.
 * @param pageSize - The number of items per page (defaults to 20).
 * @param dependencies - Anything reactive values for useMemo to use in its dependency array.
 * @returns an object with the calculated currentRange ([lowerBound, upperBound]) and the number of pages.
 * @example
 * ```
 * const otherState = useAppSelector((state) => state.someOtherState);
 * const [localCurrentPage, setLocalCurrentPage] = useState<number>(1); // 1-indexed
 * const { currentRange, numPages } = usePagination(employees.length, localCurrentPage, 40, [otherState]);
 * ```
 */
const usePagination = (
  totalCount: number,
  currentPage: number,
  pageSize = 20,
  dependencies: DependencyList = [],
): PaginationData => {
  const range = useMemo((): PaginationData => {
    const numPages = Math.ceil(totalCount / pageSize);
    const lowerBound = pageSize * (currentPage - 1);
    const upperBound = Math.min((pageSize * currentPage) - 1, totalCount - 1);
    
    return {
      currentRange: [lowerBound, upperBound],
      numPages,
    };
  }, [totalCount, pageSize, currentPage, ...dependencies]);
  
  return range;
};

export default usePagination;