import { createAction } from '@reduxjs/toolkit';
import { DeductionsShortage, EmployeeChangeReport, EmployeeChangeReportField, EmployeeChangeReportRequest } from '../../models';
import { EmployeeMasterReport } from '../../models/EmployeeMaster.model';
import { EmployeePayCategoryHistoryRequest } from 'core/models/EmployeeReports.model';

export const storeEmployeeDeductionsShortageReport = createAction<DeductionsShortage>('[employee-reports] STORE_EMPLOYEE_DEDUCTIONS_SHORTAGE_REPORT');
export const getEmployeeDeductionsShortage = createAction<{ fileType: string, runReportBy: any }>('[employee-reports] GET_EMPLOYEE_DEDUCTIONS_SHORTAGE');
export const clearEmployeeDeductionsShortageReport = createAction('[employee-reports] CLEAR_EMPLOYEE_DEDUCTIONS_SHORTAGE_REPORT');

export const storeEmployeeChangeReport = createAction<EmployeeChangeReport>('[employee-reports] STORE_EMPLOYEE_CHANGE_REPORT');
export const postEmployeeChangeReport = createAction<EmployeeChangeReportRequest>('[employee-reports] POST_EMPLOYEE_CHANGE');
export const clearEmployeeChangeReport = createAction('[employee-reports] CLEAR_EMPLOYEE_CHANGE_REPORT');
export const errorEmployeeChangeReport = createAction('[employee-reports] ERROR_EMPLOYEE_CHANGE_REPORT');

export const getEmployeeChangeReportFields = createAction<{ beginDate: string, endDate: string }>('[employee-reports] GET_EMPLOYEE_CHANGE_REPORT_FIELDS');
export const storeEmployeeChangeReportFields = createAction<EmployeeChangeReportField[]>('[employee-reports] STORE_EMPLOYEE_CHANGE_REPORT_FIELDS');
export const clearEmployeeChangeReportFields = createAction('[employee-reports] CLEAR_EMPLOYEE_CHANGE_REPORT_FIELDS');
export const setEmployeeChangeReportError = createAction('[employee-reports] SET_EMPLOYEE_CHANGE_REPORT_ERROR');

export const storeEmployeeMasterReport = createAction<EmployeeMasterReport>('[employee-reports] STORE_EMPLOYEE_MASTER_REPORT');
export const postEmployeeMasterReport = createAction<{ clientNo: number, empNo: number, reportType: string }>('[employee-reports] POST_EMPLOYEE_MASTER_REPORT');
export const clearEmployeeMasterReport = createAction('[employee-reports] CLEAR_EMPLOYEE_MASTER_REPORT');

export const downloadPayCategoryHistoryReport = createAction<EmployeePayCategoryHistoryRequest>(
  '[employee-reports] DOWNLOAD_PAY_CATEGORY_HISTORY_REPORT',
);
export const storePayCatgegoryHistoryReport = createAction<string>(
  '[employee-reports] STORE_PAY_CATEGORY_HISTORY_REPORT',
);
export const clearPayCategoryHIstoryReport = createAction(
  '[employee-reports] CLEAR_PAY_CATEGORY_HISTORY_REPORT',
);