import { createSelector } from 'reselect';
import { convToFormObject } from '../../../utilities/classUtils';
import { OtherDate } from '../../models';
import { otherDateState } from '../reducers';
import { State as OtherDateState } from '../reducers/other-date.reducer';

export const getOtherDates = createSelector(
  otherDateState,
  (state: OtherDateState) => {
    if (!state?.otherDate) return [];

    return state.otherDate.map((h: OtherDate) => {return convToFormObject(h, OtherDate.convProps);}) as OtherDate[];
  },
);
