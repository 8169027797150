import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import JobRosterDetails from './JobRosterDetails';
import JobSnapshotList from './JobSnapshotList';
import { TimeCardJobRoster } from 'core/models/Contractor.model';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { ContractorService } from 'core/services';
import { handleError } from 'core/store/actions';

const JobRoster = () => {
  const { currentTimeCard } = useAppSelector(({ contractor }) => contractor);
  const [selectedJob, setSelectedJob] = useState<TimeCardJobRoster | null>(null);
  const [timeCards, setTimeCards] = useState<TimeCardJobRoster[]>([]);
  const dispatch = useAppDispatch();
  
  const onLoad = async () => {
    const payrollHistoryId = currentTimeCard?.payrollHistoryId;
    if(!payrollHistoryId) return console.log("Payroll History Id is undefined");
    
    try{
      const timeCardByJobRoster = await ContractorService.getTimeCardsByJobRoster(payrollHistoryId);
      if(!timeCardByJobRoster.data) throw new Error("Error getting time card job roster info.");

      setTimeCards(timeCardByJobRoster.data.reverse() as TimeCardJobRoster[]);
      setSelectedJob(timeCardByJobRoster.data[0] as TimeCardJobRoster);
    }
    catch{
      (err: any) => { return dispatch(handleError(err))};
    }
  };

  useEffect(() => {
    onLoad();
  }, []);
  
  return (
    <main className={styles['main-content']}>
      <div className="d-flex justify-content-between align-items-end">
        <h1 className="dm-page-title">Job Roster</h1>
      </div>
      <hr className="dm-page-hr" />
      {selectedJob ? <div className={styles['page-body']}>
        <JobSnapshotList
          jobList={timeCards}
          selectedJob={selectedJob}
          setSelectedJob={setSelectedJob}
        />
        <JobRosterDetails
          selectedJob={selectedJob}
        />
      </div> : null}
    </main>
  );
};

export default JobRoster;