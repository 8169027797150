import React from 'react';
// '<br /><span class="dot" style="background-color: ' + COLORS[index] + '">&nbsp;</span>' + data[index].name + '  ' + data[index].value);
interface legendLineProps {
  dotColor: any;
  legendText: string;
  amount: number;
}

const LegendLine: React.FC<any> = (props: legendLineProps) => {
  const dotColorStyle = {
    backgroundColor: props.dotColor,
  };

  return (
    <>
      <div className="mb-0" style={{ fontSize: '12px', fontWeight: 600 }}>
        <span className="dot" style={dotColorStyle}>
                    &nbsp;&nbsp;
        </span>
        {props.legendText}
      </div>
      <hr style={{ borderTop: '1.5px solid #62bed2' }} className="m-0 p-0" />
      <div
        style={{ fontSize: '10px', fontWeight: 600 }}
        className="text-right mb-0"
      >
        <span>
          {props.amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
      </div>
    </>
  );
};

export default LegendLine;
