import { createReducer } from '@reduxjs/toolkit';
import { SpecialInfo } from '../../models';
import { loadSpecialInfo, storeSpecialInfo } from '../actions';

export interface State {
  loading: boolean;
  specialInfo: SpecialInfo | null;
}

export const INITIAL_STATE: State = {
  loading: false,
  specialInfo: null,
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadSpecialInfo.type]: (state) => {return { ...state, loading: true };},

  [storeSpecialInfo.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      specialInfo: action.payload,
    };
  },
});
