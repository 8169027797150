import React from 'react';
import GridItem from 'core/components/form-controls/nested-accordion/GridItem';
import Level1Toggle from 'core/components/form-controls/nested-accordion/Level1Toggle';
import NestedAccordion from 'core/components/form-controls/nested-accordion/NestedAccordion';
import { NestedAccordionItem } from 'core/components/form-controls/nested-accordion/NestedAccordionItem';

type TaxSectionProps = {
  title: string;
  data: NestedAccordionItem[];
};

const TaxSection = ({ title, data }: TaxSectionProps) => {
  const toggleComponents: any[] = [Level1Toggle, GridItem];
  
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="dm-grid-title">{title}</div>
        </div>
      </div>
      <div
        className="d-flex justify-content-between"
        style={{
          border: '1px solid #cfcaca',
          margin: '0',
          background: '#e5e5e5',
        }}
      >
        <div className="grid-header pl-2">MONTH</div>
      </div>
      {data?.length > 0 ? (
        <div className="row">
          <div className="col-12">
            {data?.map((item: NestedAccordionItem) => {
              return (
                <NestedAccordion
                  key={item.id}
                  level={1}
                  item={item}
                  toggleComponents={toggleComponents}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div className="grid-header pl-2">No data found</div>
      )}
    </>
  );
};

export default TaxSection;