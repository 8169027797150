import { AgGridReact } from '@ag-grid-community/react';
import {
  CellValueChangedEvent,
  ColDef,
  GridApi,
  GridOptions,
  RowNode,
} from '@ag-grid-enterprise/all-modules';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ControlDatePickerGrp,
  InputGrp,
  InputGrpDecimal,
  RadioGrpStacked,
  SelectModalGrp,
} from 'core/components/form-controls';
import DropdownOptionForm from 'core/components/form-controls/select-modal/DropdownOptionForm';
import GradeLevelOptionForm from 'core/components/form-controls/select-modal/GradeLevelOptionForm';
import {
  CustomField,
  HireGeneral,
  HireGeneralRequest,
  HireInfoCustomField,
  HireInfoFieldDescription,
} from 'core/models/HREmployee.model';
import { updateEmployeeHireGeneral } from 'core/store/actions/hr-employee.action';
import { cloneDeep } from 'lodash';
import {
  getBenefitsEligibilityGroups,
  getCommunicationPreferences,
  getHireReferralSource,
  getJobFamilies,
  getJobTitles,
  getNonCompete,
  getPayGrades,
  getPublicTitles,
} from 'core/store/selectors';
import AGDeleteHeaderComponent from 'utilities/ag-grid-renderers/AGDeleteHeaderComponent';
import AGEditButtonRendererComponent from 'utilities/ag-grid-renderers/AGEditButtonRendererComponent';
import CheckboxRenderer from 'utilities/ag-grid-renderers/CheckboxRenderer';
import HistoryLabel from 'core/components/form-controls/HistoryLabel';
import HrCustomizedFieldModal from './CustomizedField.modal';
import PanelHeader from 'core/components/shared/PanelHeader';
import { agLookupRenderer } from 'utilities/ag-grid-renderers';
import { agSelectEditor } from 'utilities/ag-grid-editors';
import { FormState } from 'react-hook-form';
import { UNSAVED_MESSAGE } from 'core/constants';
import { stringToInt } from 'utilities/classUtils';
import Icon from 'core/components/shared/Icon';
import { toggleBlockNavigation } from 'core/store/actions';

const buildColDefs = (
  customFields: any[],
  fieldDescriptions: any[],
): ColDef[] => {
  return [
    {
      field: 'customFieldId',
      headerName: 'Name',
      width: 340,
      editable: true,
      cellEditor: 'selectEditor',
      cellEditorParams: {
        options: customFields,
        valueField: 'customFieldId',
        labelField: 'fieldName',
      },
      cellRenderer: 'lookupRenderer',
      cellRendererParams: {
        options: customFields,
        valueField: 'customFieldId',
        labelField: 'fieldName',
      },
    },
    {
      field: 'customFieldDescriptionId',
      headerName: 'Description',
      editable: true,
      width: 340,
      cellEditorParams: (params: any) => {
        return {
          options: fieldDescriptions?.filter(d => { return d.customFieldId === +params.data.customFieldId; }),
          valueField: 'customFieldDescriptionId',
          labelField: 'description',
        };
      },
      cellEditor: 'selectEditor',
      cellRendererParams: (params: any) => {
        return {
          options: fieldDescriptions?.filter(d => { return d.customFieldId === +params.data.customFieldId; }),
          valueField: 'customFieldDescriptionId',
          labelField: 'description',
        };
      },
      cellRenderer: 'lookupRenderer',
    },
    {
      field: 'delete',
      headerComponentFramework: AGDeleteHeaderComponent,
      editable: false,
      cellRenderer: 'checkBoxRenderer',
      cellEditor: 'checkboxEditor',
      cellStyle: { paddingLeft: '16px' },
      width: 45,
    },
  ];
};

const gridOptions: GridOptions = {
  domLayout: 'autoHeight',
  defaultColDef: {
    suppressMenu: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
    singleClickEdit: true,
  },
  stopEditingWhenCellsLoseFocus: true,
  getRowNodeId: (data: any) => { return data.empCustomFieldId; },
  frameworkComponents: {
    editButtonRendererComponent: AGEditButtonRendererComponent,
    checkBoxRenderer: CheckboxRenderer,
  },
  components: {
    lookupRenderer: agLookupRenderer,
    selectEditor: agSelectEditor,
  },
};

type PropTypes = {
  formMethods: any;
  protectedEmpNo: string;
  hireGeneral: HireGeneral;
  hireInfoCustomFields: HireInfoCustomField[];
  formState: FormState<HireGeneral>;
};

const HireGeneralComponent: React.FC<PropTypes> = ({
  formMethods: { handleSubmit, control, errors, register, setValue, reset },
  protectedEmpNo,
  hireGeneral,
  hireInfoCustomFields,
  formState,
}) => {
  const dispatch = useDispatch();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState<CustomField[]>([]);
  const [customFields, setCustomFields] = useState<HireInfoCustomField[]>([]);
  const [fieldDescriptions, setFieldDescriptions] = useState<HireInfoFieldDescription[]>([]);
  const [showFieldModal, setShowFieldModal] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const jobTitleOpts = useSelector(getJobTitles);
  const jobFamilyOpts = useSelector(getJobFamilies);
  const publicTitleOpts = useSelector(getPublicTitles);
  const payGradesOpts = useSelector(getPayGrades);
  const nonCompVersionOpts = useSelector(getNonCompete);
  const commPreferenceOpts = useSelector(getCommunicationPreferences);
  const hireReferralSourceOpts = useSelector(getHireReferralSource);
  const benefitsEligibilityGroups = useSelector(getBenefitsEligibilityGroups);

  useEffect(() => {
    setRowData(cloneDeep(hireGeneral.customFields));
    setFormIsDirty(false);
  }, [hireGeneral]);

  useEffect(() => {
    if (hireInfoCustomFields) {
      hireInfoCustomFields && setCustomFields(hireInfoCustomFields);
      const desc = hireInfoCustomFields?.map(f => { return f.descriptions; }).flat() as HireInfoFieldDescription[];
      desc && setFieldDescriptions(desc);
    }
  }, [hireInfoCustomFields]);

  useEffect(() => {
    if (
      gridApi &&
      customFields &&
      fieldDescriptions
    ) {
      gridApi.setColumnDefs(
        buildColDefs(customFields, fieldDescriptions),
      );
      const row: RowNode | undefined = gridApi?.getDisplayedRowAtIndex(0);
      if (row) {
        row.setSelected(true, true);
      }
    }
  }, [gridApi, gridColumnApi, customFields, fieldDescriptions]);


  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onAddField = () => {
    const field: HireInfoCustomField =
    {
      customFieldId: 0,
      descriptions: [{
        customFieldId: 0,
      }],
    };

    gridApi?.applyTransaction({
      add: [field],
      addIndex: 0,
    });
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    reset();
  };

  const onDeleteSelected = () => {
    if (gridApi) {
      const items: CustomField[] = [];
      gridApi?.forEachNode((node) => {
        !node.data.delete && items.push(node.data);
      });

      const request: HireGeneralRequest = {
        protectedEmpNo,
        data: {
          ...hireGeneral,
          customFields: items,
        },
      };
      dispatch(updateEmployeeHireGeneral(request));
    }
  };

  const onSubmit = (data: any, e?: React.BaseSyntheticEvent) => {
    if (!e?.target.isConnected || e.target.id === 'option-form') {
      return false;
    }

    const formData = {
      ...data,
      townHallInvite: data.townHallInvite === 'true',
      coachEmailDistributionList:
        data.coachEmailDistributionList === 'true',
      textAuthorized: data.textAuthorized === 'true',
      emailAuthorized: data.emailAuthorized === 'true',
    };


    const items: CustomField[] = [];
    gridApi?.forEachNode((node) => {
      const item = node.data as CustomField;

      items.push(item);
    });

    const hgItem = {
      ...hireGeneral,
      ...formData,
    } as HireGeneral;
    const request: HireGeneralRequest = {
      protectedEmpNo,
      data: {
        ...hgItem,
        customFields: items,
      },
    };
    dispatch(updateEmployeeHireGeneral(request));
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: formState.isDirty || formIsDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [formState.isDirty || formIsDirty]);

  return (
    <div>
      <PanelHeader title="General" >
        <div
          className="ml-auto btn-link"
          onClick={() => { return setShowFieldModal(true); }}
        >
          <span className="dm-grid-action-title">
            Customizable Field Setup
          </span>
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </div>
      </PanelHeader>
      <div className="col-12 p-3">
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-3">
                <div className="dm-card-subtitle2 mb-2">Job</div>
                <hr className="dm-panel-hr" />
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    {jobTitleOpts && (
                      <div className="d-flex flex-column">
                        <HistoryLabel
                          labelTitle="TITLE"
                          section="hireStatus"
                          field="jobTitleId"
                          protectedEmpNo={protectedEmpNo}
                        />
                        <SelectModalGrp
                          noOption={true}
                          modalTitle="JOB TITLES"
                          formComponent={DropdownOptionForm}
                          addOptionText="Job Title"
                          name={'jobTitleId'}
                          dropdownName="JobTitle"
                          options={jobTitleOpts}
                          errors={errors.jobTitleId}
                          control={control}
                          value={hireGeneral.jobTitleId}
                          setValue={setValue}
                        />
                      </div>
                    )}

                    {jobFamilyOpts && (
                      <div className="d-flex flex-column">
                        <HistoryLabel
                          labelTitle="JOB"
                          section="hireInfo"
                          field="jobFamilyId"
                          protectedEmpNo={protectedEmpNo}
                        />
                        <SelectModalGrp
                          noOption={true}
                          modalTitle="JOBS"
                          formComponent={DropdownOptionForm}
                          addOptionText="Jobs"
                          name={'jobFamilyId'}
                          dropdownName="JobFamily"
                          options={jobFamilyOpts}
                          errors={errors.jobFamilyId}
                          control={control}
                          value={hireGeneral.jobFamilyId}
                          setValue={setValue}
                        />
                      </div>
                    )}

                    {publicTitleOpts && (
                      <div className="d-flex flex-column">
                        <HistoryLabel
                          labelTitle="PUBLIC TITLE"
                          section="hireInfo"
                          field="publicTitleId"
                          protectedEmpNo={protectedEmpNo}
                        />
                        <SelectModalGrp
                          noOption={true}
                          modalTitle="JOB PUBLIC TITLE"
                          formComponent={DropdownOptionForm}
                          addOptionText="Job Public Title"
                          name={'jobPublicTitleId'}
                          dropdownName="PublicTitle"
                          options={publicTitleOpts}
                          errors={errors.jobPublicTitleId}
                          control={control}
                          value={hireGeneral.jobPublicTitleId}
                          setValue={setValue}
                        />
                      </div>
                    )}
                    {payGradesOpts && (
                      <div className="d-flex flex-column">
                        <HistoryLabel
                          labelTitle="GRADE LEVEL"
                          section="misc"
                          field="payGrade"
                          protectedEmpNo={protectedEmpNo}
                        />
                        <SelectModalGrp
                          noOption={true}
                          modalTitle="GRADE LEVEL MAINTENANCE"
                          formComponent={GradeLevelOptionForm}
                          addOptionText="GRADE LEVEL"
                          name={'gradeLevel'}
                          options={payGradesOpts}
                          errors={errors.gradeLevel}
                          control={control}
                          value={hireGeneral.gradeLevel}
                          setValue={setValue}
                          dropdownName="payGrade"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-6">
                    <div className="dm-card-subtitle2 mb-2">
                      Non-Compete Non-Solicit
                    </div>
                    <hr className="dm-panel-hr" />
                    <div className="d-flex">
                      <div className="d-flex flex-column flex-grow-1">
                        {nonCompVersionOpts && (
                          <div className="d-flex flex-column">
                            <HistoryLabel
                              labelTitle="VERSION"
                              section="hireInfo"
                              field="nonCompeteVersionID"
                              protectedEmpNo={protectedEmpNo}
                            />
                            <SelectModalGrp
                              noOption={true}
                              modalTitle="NON-COMPETE VERSION"
                              formComponent={DropdownOptionForm}
                              addOptionText="Non-Compete Version"
                              name={'nonCompeteVersionId'}
                              dropdownName="NonCompete"
                              options={nonCompVersionOpts}
                              control={control}
                              value={hireGeneral.nonCompeteVersionId}
                              setValue={setValue}
                            />
                          </div>
                        )}

                        <div className="d-flex flex-column">
                          <HistoryLabel
                            labelTitle="DATE SIGNED"
                            section="hireInfo"
                            field="nonCompeteSignedDate"
                            protectedEmpNo={protectedEmpNo}
                          />
                          <ControlDatePickerGrp
                            errors={
                              errors.nonCompeteSignDate
                            }
                            value={
                              hireGeneral.nonCompeteSignDate
                            }
                            setValue={setValue}
                            control={control}
                            name="nonCompeteSignDate"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="dm-card-subtitle2 mb-2">
                      Annual Bonus Target
                    </div>
                    <hr className="dm-panel-hr" />
                    <div className="d-flex">
                      <div className="d-flex flex-column flex-grow-1">
                        <div className="d-flex flex-column">
                          <HistoryLabel
                            labelTitle="PERCENT %"
                            section="hireInfo"
                            field="bonusPercent"
                            protectedEmpNo={protectedEmpNo}
                          />
                          <InputGrpDecimal
                            name="annualBonusTargetPercent"
                            errors={
                              errors.annualBonusTargetPercent
                            }
                            ref={register({
                              valueAsNumber: true,
                            })}
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <HistoryLabel
                            labelTitle="AMOUNT"
                            section="hireInfo"
                            field="bonusAmount"
                            protectedEmpNo={protectedEmpNo}
                          />
                          <InputGrpDecimal
                            name="annualBonusTargetAmount"
                            errors={
                              errors.annualBonusTargetAmount
                            }
                            ref={register({
                              valueAsNumber: true,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex flex-column  flex-grow-1">
                    <div className="dm-card-subtitle2 mb-2">
                      Communication
                    </div>
                    <hr className="dm-panel-hr" />
                    <div className="d-flex flex-row  flex-grow-1">
                      <div className="d-flex flex-column">
                        <div className="flex-grow-1 mw150">
                          <HistoryLabel
                            labelTitle="TOWN HALL INVITE"
                            section="hireInfo"
                            field="towHall"
                            protectedEmpNo={protectedEmpNo}
                          />
                          <RadioGrpStacked
                            name="townHallInvite"
                            ref={register()}
                            stacked={false}
                          />
                        </div>
                        <div className="flex-grow-1">
                          <HistoryLabel
                            labelTitle="TEXT AUTHORIZED"
                            section="hireInfo"
                            field="textAuthorized"
                            showHistory={false}
                            protectedEmpNo={protectedEmpNo}
                          />
                          <RadioGrpStacked
                            name="textAuthorized"
                            ref={register()}
                            stacked={false}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column">
                        <div className="flex-grow-1">
                          <HistoryLabel
                            labelTitle="COACH EMAIL DISTRIBUTION LIST"
                            section="hireInfo"
                            field="emailList"
                            protectedEmpNo={protectedEmpNo}
                          />
                          <RadioGrpStacked
                            name="coachEmailDistributionList"
                            ref={register()}
                            stacked={false}
                          />
                        </div>

                        <div className="flex-grow-1">
                          <HistoryLabel
                            labelTitle="EMAIL AUTHORIZED"
                            section="hireInfo"
                            field="emailAuthorized"
                            showHistory={false}
                            protectedEmpNo={protectedEmpNo}
                          />
                          <RadioGrpStacked
                            name="emailAuthorized"
                            ref={register()}
                            stacked={false}
                          />
                        </div>

                      </div>
                      <div className="d-flex flex-column">

                        {commPreferenceOpts && (
                          <div className="flex-grow-1">
                            <HistoryLabel
                              labelTitle="COMMUNICATION PREFERENCE"
                              section="hireInfo"
                              field="commPreferenceID"
                              protectedEmpNo={protectedEmpNo}
                            />
                            <SelectModalGrp
                              noOption={true}
                              modalTitle="COMMUNICATION PREFERENCE"
                              formComponent={DropdownOptionForm}
                              addOptionText="Communication Preference"
                              name={'communicationPreferenceId'}
                              dropdownName="CommunicationPreference"
                              options={commPreferenceOpts}
                              control={control}
                              value={hireGeneral.communicationPreferenceId}
                              setValue={setValue}
                            />

                          </div>
                        )}

                        <div className="flex-grow-1">
                          <HistoryLabel
                            labelTitle="NICKNAME"
                            section="hireInfo"
                            field="nickName"
                            protectedEmpNo={protectedEmpNo}
                          />
                          <InputGrp
                            name="nickname"
                            errors={errors.nickname}
                            ref={register()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="dm-card-subtitle2 mb-2">
                  General Info
                </div>
                <hr className="dm-panel-hr" />
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    {benefitsEligibilityGroups && (
                      <div className="d-flex flex-column">
                        <HistoryLabel
                          labelTitle="BENEFITS ELIGIBILITY GROUP"
                          section="hireInfo"
                          field="benefitEligibleGroupId"
                          protectedEmpNo={protectedEmpNo}
                        />
                        <SelectModalGrp
                          noOption={true}
                          modalTitle="BENEFITS ELIGIBILITY GROUP"
                          formComponent={DropdownOptionForm}
                          addOptionText="Benefits Eligibility Group"
                          name={'benefitsEligibilityGroupId'}
                          dropdownName="BenefitEligibilityGroup"
                          options={benefitsEligibilityGroups}
                          control={control}
                          value={hireGeneral.benefitsEligibilityGroupId}
                          setValue={setValue}
                        />
                      </div>
                    )}

                    <div className="d-flex flex-column">
                      <HistoryLabel
                        labelTitle="I9 AUDIT DUE DATE"
                        section="hireInfo"
                        field="i9DueDate"
                        protectedEmpNo={protectedEmpNo}
                      />
                      <ControlDatePickerGrp
                        errors={errors.i9AuditDueDate}
                        value={hireGeneral.i9AuditDueDate}
                        setValue={setValue}
                        control={control}
                        name={'i9AuditDueDate'}
                      />
                    </div>
                    <div className="d-flex flex-column">
                      <HistoryLabel
                        labelTitle="TEMP AGENCY START DATE"
                        section="hireInfo"
                        field="agencyStartDate"
                        protectedEmpNo={protectedEmpNo}
                      />
                      <ControlDatePickerGrp
                        errors={errors.tempAgencyStartDate}
                        value={hireGeneral.tempAgencyStartDate}
                        setValue={setValue}
                        control={control}
                        name="tempAgencyStartDate"
                      />
                    </div>

                    {hireReferralSourceOpts && (
                      <div className="d-flex flex-column">
                        <HistoryLabel
                          labelTitle="HIRE REFERRAL SOURCE"
                          section="hireInfo"
                          field="hReferralSourceID"
                          protectedEmpNo={protectedEmpNo}
                        />
                        <SelectModalGrp
                          noOption={true}
                          modalTitle="HIRE REFERRAL SOURCE"
                          formComponent={DropdownOptionForm}
                          addOptionText="Hire Referral Source"
                          name={'hireReferralSourceId'}
                          dropdownName="HireReferralSource"
                          options={hireReferralSourceOpts}
                          control={control}
                          value={hireGeneral.hireReferralSourceId}
                          setValue={setValue}
                        />

                      </div>
                    )}

                    <div className="d-flex flex-column">
                      <HistoryLabel
                        labelTitle="AUTO INSURANCE AUDIT DATE"
                        section="hireInfo"
                        field="autoAuditDate"
                        protectedEmpNo={protectedEmpNo}
                      />
                      <ControlDatePickerGrp
                        errors={errors.autoInsuranceAuditDate}
                        value={
                          hireGeneral.autoInsuranceAuditDate
                        }
                        setValue={setValue}
                        control={control}
                        name="autoInsuranceAuditDate"
                      />
                    </div>

                    <div className="d-flex flex-column">
                      <HistoryLabel
                        labelTitle="DRIVING RECORD VALIDATION DATE"
                        section="hireInfo"
                        field="validationDate"
                        protectedEmpNo={protectedEmpNo}
                      />
                      <ControlDatePickerGrp
                        errors={
                          errors.drivingRecordValidationDate
                        }
                        value={
                          hireGeneral.drivingRecordValidationDate
                        }
                        setValue={setValue}
                        control={control}
                        name="drivingRecordValidationDate"
                      />
                    </div>

                    <div className="d-flex flex-column">
                      <HistoryLabel
                        labelTitle="DRIVER'S LICENCE NO"
                        section="payrollInfo"
                        field="driverLicNumber"
                        protectedEmpNo={protectedEmpNo}
                      />
                      <InputGrp
                        errors={errors.driversLicenceNo}
                        ref={register()}
                        name="driversLicenceNo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-9">
              <div className="d-flex">
                <div className="dm-card-subtitle2 mb-2">
                  Custom Field Information
                </div>

                <div
                  className="ml-auto btn-link"
                  onClick={onAddField}
                >
                  <span className="dm-grid-action-title">
                    Add Custom Field
                  </span>
                  <Icon
                    name="plus-circle"
                    className="fa-plus-circle"
                  />
                </div>
              </div>

              <hr className="dm-panel-hr" />

              <div className="table-wrapper-wrapper ag-theme-balham">
                {(customFields.length && fieldDescriptions.length) ?
                  <AgGridReact
                    gridOptions={gridOptions}
                    rowData={rowData}
                    onGridReady={onGridReady}
                    onCellValueChanged={(e: CellValueChangedEvent) => {
                      const newValue = stringToInt(e.newValue);
                      if (e.oldValue !== newValue) {
                        setFormIsDirty(true);
                      }
                    }}
                  /> : null}
              </div>
              <div className="text-right p-3">
                <button
                  type="button"
                  className="btn btn-link dm-grid-action-title py-0"
                  onClick={() => { return onDeleteSelected(); }}
                >
                  Delete Selected{' '}
                  <Icon
                    name="minus-circle"
                    className="fa-plus-circle"
                  />
                </button>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn btn-primary orange-outline-button ml-auto mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                disabled={(!formState.isDirty && !formIsDirty)}
                type="submit"
                className="btn orange-button-sm mr-3"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
      {showFieldModal &&
        <HrCustomizedFieldModal
          show={showFieldModal}
          onHide={() => { return setShowFieldModal(false); }}
        />
      }
    </div>
  );
};

export default HireGeneralComponent;
