import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpResponse } from '../../models';
import { Sso } from '../../models/Sso.model';
import { SsoService } from '../../services/sso.service';
import { handleError } from '../actions';
import { loadSso, storeSso } from '../actions/sso.action';

interface Actions<Type> {
  type: string;
  payload: Type;
}

const loadSso$ = (action$: Observable<Actions<null>>) => {
  return action$.pipe(
    ofType(loadSso.type),
    switchMap((action: any) => {
      return from(SsoService.PostSso()).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<Sso>) => { return storeSso(res.value); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics = [loadSso$];
