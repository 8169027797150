import React from 'react';
import { InputGrp, InputGrpDecimal } from 'core/components/form-controls';
import { currencyFormatter, formatWithCommas } from 'utilities/utilities';
import { PayrollAdjustmentSummary } from 'core/models';
import { useSelector } from 'react-redux';
import { getIsFinishedPayroll } from 'core/store/selectors';
import { FormMethods } from 'core/components/form-controls/types';

type Props = {
  check?: PayrollAdjustmentSummary;
  index: number;
  voidDisabled?: boolean;
  register: FormMethods['register'];
  watch: FormMethods['watch'];
  setValue: FormMethods['setValue'];
};

const enabledWithholding: string[] = ['company contribution', '401k match'];
const enabledGross: string[] = [];
const hiddenGrossFields: string[] = ['state', 'addtl state', 'local'];

const SummaryFields = ({ check, register, index, voidDisabled, watch, setValue }: Props) => {
  const isFinishedPayroll =  useSelector(getIsFinishedPayroll);
  return (
    <>
      <div className="row font-weight-bolder mb-3">
        <div className="col">Earnings/Deductions</div>
        <div className="col-3">Amount</div>
        <div className="col-3">Gross</div>
      </div>
      {check?.employeeEarningsDeductions?.map((ed, edi) => {
        return (
          <div
            className="row font-weight-bold"
            key={edi}
          >
            <div className="col">{ed.description}</div>
            <div className="col-3 text-right">
              {ed.showWithholding && (
                <>
                  {ed.description.indexOf('Hours') > -1
                    ? ed.withholding.toFixed(2)
                    : currencyFormatter(ed.withholding)}
                </>
              )}
            </div>
            <div className="col-3 text-right">
              {ed.showGross && (
                <>
                  {ed.description.indexOf('Hours') > -1
                    ? ed.gross.toFixed(2)
                    : currencyFormatter(ed.gross)}
                </>
              )}
            </div>
          </div>
        );
      })}
      <div className="mt-3">
        <span className="font-weight-bolder">W/H Taxes</span>
        {check?.employeeTaxes?.map((ed, edi) => {
          const disabled = (['Local', 'State', 'Addtl State'].includes(ed.description)) || isFinishedPayroll; 
          const eeGrossAmount = watch(`items[${index}].employeeTaxes[${edi}].gross`);
          const eeWithholdingAmount = watch(`items[${index}].employeeTaxes[${edi}].withholding`);
          
          return (
            <div
              className="row font-weight-bold"
              key={edi}
            >
              <div className="col ml-2">{ed.description}</div>
              <div className="col-3">
                {ed.showWithholding && (
                  <InputGrp
                    groupClass="number-underline"
                    type="text"
                    name={`items[${index}].employeeTaxes[${edi}].withholding`}
                    ref={register()}
                    defaultValue={formatWithCommas(eeWithholdingAmount ?? 0)}
                    setValue={setValue}
                    formatNumber
                    hiddenValue={eeWithholdingAmount ?? 0}
                    hiddenRef={register({
                      valueAsNumber: true,
                    })}
                    disabled={disabled || voidDisabled}
                  />                
                )}
              </div>
              <div className="col-3">
                {ed.showGross && !hiddenGrossFields.includes(ed.description.toLowerCase()) && (
                  <InputGrp
                    groupClass="gc03 number-underline mw75"
                    type="text"
                    name={`items[${index}].employeeTaxes[${edi}].gross`}
                    ref={register()}
                    defaultValue={formatWithCommas(eeGrossAmount ?? 0)}
                    setValue={setValue}
                    formatNumber
                    hiddenValue={eeGrossAmount ?? 0}
                    hiddenRef={register({
                      valueAsNumber: true,
                    })}
                    disabled={true}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-3">
        <span className="font-weight-bolder">Employer Tax</span>
        {check?.employerTaxes?.map((ed, edi) => {
          const erGrossAmount = watch(`items[${index}].employerTaxes[${edi}].gross`);
          const erWithholdingAmount = watch(`items[${index}].employerTaxes[${edi}].withholding`);

          return (
            <div
              key={edi}
              className="row font-weight-bold"
            >
              <div className="col ml-2">{ed.description}</div>
              <div className="col-3">
                {ed.showWithholding && (
                  <InputGrp
                    groupClass="number-underline"
                    type="text"
                    name={`items[${index}].employerTaxes[${edi}].withholding`}
                    ref={register()}
                    defaultValue={formatWithCommas(erWithholdingAmount ?? 0)}
                    setValue={setValue}
                    formatNumber
                    hiddenValue={erWithholdingAmount ?? 0}
                    hiddenRef={register({
                      valueAsNumber: true,
                    })}
                    disabled={!enabledWithholding.includes(ed.description.toLowerCase())}
                  />  
                )}
              </div>
              <div className="col-3">
                {ed.showGross && !hiddenGrossFields.includes(ed.description.toLowerCase()) && (
                  <InputGrp
                    groupClass="gc03 number-underline mw75"
                    type="text"
                    name={`items[${index}].employerTaxes[${edi}].gross`}
                    ref={register()}
                    defaultValue={formatWithCommas(erGrossAmount ?? 0)}
                    setValue={setValue}
                    formatNumber
                    hiddenValue={erGrossAmount ?? 0}
                    hiddenRef={register({
                      valueAsNumber: true,
                    })}
                    disabled={true}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SummaryFields;
