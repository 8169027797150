import React, { } from 'react';
import {
  SelectGrp,
  TextareaGrp,
} from '../../../core/components/form-controls';
import { InterviewAnswer } from '../../../core/models/HREmployee.model';
import { ArrayField } from 'react-hook-form';
import { Dropdown } from '../../../core/models';
import { useSelector } from 'react-redux';
import { getExitInterviewQuestions } from '../../../core/store/selectors';

type PropTypes = {
  item: Partial<ArrayField<InterviewAnswer>>;
  index: number;
  formMethods: any;
  questions: Dropdown[];
};

const InterviewAnswerItem: React.FC<PropTypes> = ({
  item,
  index,
  formMethods: { register },
  questions,
}) => {

  const allQuestions = useSelector(getExitInterviewQuestions);

  return (

    <div className="d-flex flex-column">
      <div className="d-flex flex-wrap">
        <input
          type="hidden"
          name={`answers[${index}].exitInterviewId`}
          defaultValue={item.exitInterviewId}
          ref={register({ valueAsNumber: true })}
        />
        <SelectGrp
          label="QUESTION"
          groupClass="gc12 question-disabled"
          name={`answers[${index}].exitInterviewQuestionId`}
          options={item.exitInterviewQuestionId ? allQuestions : questions}
          defaultValue={item.exitInterviewQuestionId}
          ref={register({ valueAsNumber: true })}
          disabled={(item?.exitInterviewQuestionId || 0) > 0}
        />

        <TextareaGrp
          name={`answers[${index}].answer`}
          defaultValue={item.answer}
          label="ANSWER"
          groupClass="gc12"
          ref={register()} />
      </div>

    </div>

  );
};

export default InterviewAnswerItem;
