import React, { CSSProperties } from 'react';
import { Labels } from '.';
import styles from './form-controls.module.scss';

type PropTypes = {
  id?: string;
  name: string;
  label?: string | string[];
  tallLabel?: boolean;
  labelClass?: string;
  groupClass?: string;
  selectClass?: string;
  selectStyles?: CSSProperties;
  groupStyle?: object;
  placeholder?: string;
  errors?: any;
  errorMsg?: string;
  required?: boolean;
  visible?: boolean;
  options: any[];
  openModal?: boolean;
  valueField?: string;
  labelField?: string;
  showId?: boolean;
  addEmptyValue?: boolean;
  addEmptyValueText?: string;
  addEmptyText?: string;
  noOption?: boolean;
  noOptionValue?: any;
  noOptionText?: string;
  addOptionText?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLSelectElement, Element>) => void;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement, Element>) => void;
  disabled?: boolean;
  readOnly?: boolean;
  addHomeOption?: string;
  value?: any;
  defaultValue?: string | number | readonly string[] | undefined;
};

export const SelectGrpUncontrolled = (
  {
    id,
    name,
    label,
    tallLabel = false,
    labelClass,
    groupClass,
    selectClass = '',
    selectStyles = {},
    groupStyle,
    placeholder,
    required = false,
    visible = true,
    errors,
    errorMsg,
    options,
    openModal = false,
    valueField = 'id',
    labelField = 'description',
    showId = false,
    addEmptyValue = false,
    addEmptyValueText = '',
    addEmptyText = '',
    noOption = false,
    noOptionValue = '',
    noOptionText = '',
    addOptionText = '',
    addHomeOption = '',
    onFocus,
    onChange,
    onBlur,
    disabled = false,
    readOnly,
    defaultValue,
    ...extraProps
  }: PropTypes) => {
  if (!visible) return null;
    
  const groupClass2 = styles['dm-form-group'] + ' ' + (groupClass ?? '');
  id = id ?? name;
  const errMsg: string = errors
    ? errors.type === 'validate'
      ? errorMsg
      : errors.message
    : '';
    
  return (
    <fieldset
      aria-disabled={disabled || readOnly}
      disabled={disabled || readOnly}
      className={groupClass2}
      style={groupStyle}
    >
      <Labels
        label={label}
        tallLabel={tallLabel}
        labelClass={labelClass}
        id={id}
        hasError={!!errors}
        required={required}
      />
      <select
        required={required}
        id={id}
        name={name}
        className={`${selectClass} ${styles['dm-form-control']} ${errors ? ' ' + styles['dm-is-invalid'] : ''}`.trim()}
        style={selectStyles}
        placeholder={placeholder}
        value={extraProps?.value}
        defaultValue={defaultValue}
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        {...extraProps}
      >
        {placeholder && (
          <option
            key="placeHolder"
            value=""
            disabled
            hidden
            selected
          >
            {placeholder}
          </option>
        )}
        {addEmptyValueText && (
          <option
            key="emptyValueText"
            value={addEmptyValueText}
          >{addEmptyValueText}</option>
        )}
        {addEmptyValue && (
          <option
            key="empty"
            value={undefined}
          ></option>
        )}
        {addEmptyText && (
          <option
            key="emptyText"
            value={''}
          >
            {addEmptyText}
          </option>
        )}
        {noOption && (
          <option
            key="noOption"
            value={noOptionValue}
          >
            &lt;no {noOptionText} &gt;
          </option>
        )}
        {addHomeOption && addHomeOption !== '' && (
          <option
            key="homeOption"
            value={-1}
          >
            {addHomeOption}
          </option>
        )}
        {openModal && (
          <option
            key="modal"
            value="open-modal"
          >
            &lt;add, change, delete {addOptionText}&gt;
          </option>
        )}
        {options.map((opt: any) => {
          return (
            <option
              key={opt[valueField.trim()]}
              value={opt[valueField.trim()]}
              title={opt[labelField.trim()]}
              style={opt?.color ? { color: opt.color } : undefined}
              disabled={opt?.disabled}
              aria-disabled={opt?.disabled}
            >
              {showId
                ? opt[valueField] + ' - ' + opt[labelField]
                : opt[labelField]}
            </option>
          );
        })}
      </select>
      <small className="text-danger">{errMsg}</small>
    </fieldset>
  );
};
