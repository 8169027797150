import React from 'react';
import styles from './form-controls.module.scss';
import { Label } from '.';

const defaultRadioOptions = [
  { value: 'true', label: 'yes' },
  { value: 'false', label: 'no' },
];

interface RadioYesNoProps {
  id?: string;
  name: string;
  label?: string;
  labelWidth?: number;
  labelClass?: string;
  tallLabel?: boolean;
  groupClass?: string;
  groupStyle?: object;
  errors?: any;
  required?: boolean;
  defaultChecked?: string | number | boolean;
  radioOptions?: { value: string; label: string }[];
  onChange?: (e: any) => void;
}

export const RadioGrpInLine: React.FC<any> = React.forwardRef(
  (
    {
      id,
      name,
      label,
      labelWidth = 60,
      tallLabel = false,
      groupClass,
      groupStyle,
      errors,
      required = false,
      defaultChecked,
      radioOptions = defaultRadioOptions,
      onChange,
      ...extraProps
    }: RadioYesNoProps,
    ref: any,
  ) => {
    const groupClass2 =
            styles['dm-form-group-inline'] + ' ' + (groupClass ?? '');
    const lastIndex = radioOptions.length - 1;
    id = id ?? name;
    const inputWidthString = '' + (100 - labelWidth) + '%';
    const labelWidthString = '' + labelWidth + '%';

    return (
      <div
        className={groupClass2}
        style={groupStyle}
      >
        {label && (
          <div style={{ width: labelWidthString }}>
            <Label
              id={id}
              hasError={!!errors}
              text={label}
              required={required}
            />
          </div>
        )}
        <div style={{ width: inputWidthString }}>
          {radioOptions.map((item: any, index: number) => {
            return (
              <label
                key={index}
                className={
                  extraProps?.labelClass ? extraProps.labelClass
                    : index === 0
                      ? styles['radio-label-first']
                      : index === lastIndex
                        ? styles['radio-label-last']
                        : styles['radio-label']
                }
              >
                <input
                  id={id + '' + index}
                  type="radio"
                  name={name}
                  ref={ref}
                  defaultValue={item.value}
                  onChange={onChange}
                  className={styles['radio-input']}
                  defaultChecked={
                    defaultChecked?.toString() === item.value
                  }
                  required={required}
                  {...extraProps}
                />{' '}
                {item.label}
              </label>
            );
          })}
        </div>
      </div>
    );
  },
);

RadioGrpInLine.displayName = 'RadioGrpInLine';