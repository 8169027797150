import { createAction } from '@reduxjs/toolkit';
import {
  PayrollAdjustmentEmployee,
  PayrollAdjustmentCheckRequest,
  PayrollAdjustmentCheckResponse,
  PayrollAdjustmentRequest,
  PayrollAdjustmentSummary,
} from '../../models';

export const loadPayrollAdjustments = createAction<{
  payrollHistoryId: number;
}>('[payroll] LOAD_PAYROLL_ADJUSTMENTS');

export const storePayrollAdjustments = createAction<
PayrollAdjustmentEmployee[]
>('[payroll] STORE_PAYROLL_ADJUSTMENTS');

export const loadEmployeePayrollAdjustment = createAction<{
  payrollHistoryId: number;
  protectedEmpNo: string;
}>('[payroll] LOAD_PAYROLL_ADJUSTMENT');

export const storeEmployeePayrollAdjustment =
    createAction<PayrollAdjustmentEmployee>(
      '[payroll] STORE_EMPLOYEE_PAYROLL_ADJUSTMENT',
    );

export const loadPayrollAdjustmentNewCheck = createAction<{
  payrollHistoryId: number;
  protectedEmpNo: string;
}>('[payroll] LOAD_PAYROLL_ADJUSTMENT_NEW_CHECK');

export const storePayrollAdjustmentNewCheck =
    createAction<PayrollAdjustmentSummary>(
      '[payroll] STORE_EMPLOYEE_PAYROLL_ADJUSTMENT_NEW_CHECK',
    );

export const payrollAdjustmentCheckValidate =
    createAction<PayrollAdjustmentCheckRequest>(
      '[payroll] LOAD_PAYROLL_ADJUSTMENT_VALIDATE',
    );

export const createPayrollAdjustmentCheck =
    createAction<PayrollAdjustmentCheckRequest>(
      '[payroll] CREATE_PAYROLL_ADJUSTMENT_CHECK',
    );

export const updatePayrollAdjustmentCheck =
    createAction<PayrollAdjustmentCheckRequest>(
      '[payroll] UPDATE_PAYROLL_ADJUSTMENT_CHECK',
    );

export const deletePayrollAdjustmentCheck = createAction<{
  payrollHistoryId: number;
  protectedEmpNo: string;
  empNo: number;
  adjustmentId: number;
}>('[payroll] DELETE_PAYROLL_ADJUSTMENT_CHECK');

export const loadPayrollAdjustmentEmployeeVoids = createAction<{
  data: PayrollAdjustmentEmployee;
  payrollHistoryId: number;
  empNo: string;
}>('[payroll] PAYROLL_ADJUSTMENT_EMPLOYEE_VOIDS');

export const createAdjustmentType = createAction<{
  empNo: number;
  blankCheck: PayrollAdjustmentSummary;
}>('[payroll] CREATE_ADJUSTMENT_TYPE');

export const clearPayrollAdjustment = createAction(
  '[payroll] CLEAR_PAYROLL_ADJUSTMENT',
);

export const storeValidatedCheck = createAction<PayrollAdjustmentCheckResponse>(
  '[payroll] STORE_VALIDATED_CHECK',
);
export const clearValidatedCheck = createAction(
  '[payroll] CLEAR_VALIDATED_CHECK',
);

export const deleteBlankCheck = createAction<number>(
  '[payroll] DELETE_BLANK_CHECK',
);

export const loadPrintAdjustmentCheck = createAction<PayrollAdjustmentRequest>(
  '[payroll] LOAD_PRINT_ADJUSTMENT_CHECK',
);

export const storePrintAdjustmentCheck = createAction<string>(
  '[payroll] STORE_PRINT_ADJUSTMENT_CHECK',
);

export const clearPrintAdjustmentCheck = createAction(
  '[payroll] CLEAR_ADJUSTMENT_CHECK',
);

export const setCloseModal = createAction<boolean>('[payroll] TOGGLE_CLOSE_ADJ_MODAL');

export const breakValidationChain = createAction<boolean>('[payroll] BREAK_VALIDATE_CHAIN');