import { ofType } from 'redux-observable';
import { Observable, forkJoin, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  EmployeeUploadClientFile,
  EmployeeUploadClientLibrary,
  EmployeeUploadMap,
  EmployeeUploadTranslation,
  HttpResponse,
} from '../../models';
import { EmployeeUploadService } from '../../services';
import {
  handleError,
  createEmpUploadClientFile,
  loadEmpUploads,
  storeEmpUpload,
  storeEmpUploads,
  updateEmpUploadClientFile,
  storeEmpUploadField,
  createEmpUploadField,
  updateEmpUploadField,
  deleteEmpUploadFields,
  deleteEmpUploadTranslation,
  updateEmpUploadTranslation,
  createEmpUploadTranslation,
  storeEmpUploadTranslation,
  createEmpUploadClientLibrary,
  storeEmpUploadClientLibrary,
  loadEmployees,
  handleSuccess,
  addUpdateEmpUploadTranslation,
  storeEmpUploadTranslations,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const getEmpUploads$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmpUploads.type),
    switchMap(() => {
      return from(EmployeeUploadService.getEmpUploads()).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeEmpUploads(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createEmpUploadClientFile$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createEmpUploadClientFile.type),
    switchMap((action: { payload: EmployeeUploadClientFile; }) => {
      return from(EmployeeUploadService.postEmpUpload(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => { return [
          storeEmpUpload(res),
          handleSuccess(res.messages[0])
        ]}),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEmpUploadClientFile$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateEmpUploadClientFile.type),
    switchMap((action: { payload: EmployeeUploadClientFile; }) => {
      return from(EmployeeUploadService.putEmpUpload(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: EmployeeUploadClientFile) => storeEmpUpload(res)),
        map(() => { return loadEmpUploads(); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createEmpUploadClientLibrary$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createEmpUploadClientLibrary.type),
    switchMap((action: { payload: EmployeeUploadClientLibrary; }) => {
      return from(
        EmployeeUploadService.putEmpUploadClientLibrary(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: EmployeeUploadClientLibrary) => {
          return [
            handleSuccess('File has been successfully uploaded.'),
            storeEmpUploadClientLibrary(res),
            loadEmployees(),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            storeEmpUploadClientLibrary(err.value),
            handleError('An error has occured in uploading the file: ' + err),
          ];
        }),
      );
    },
    ),
  );
};

const createEmpUploadField$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createEmpUploadField.type),
    switchMap((action: { payload: EmployeeUploadMap; }) => {
      return from(EmployeeUploadService.postEmpUploadField(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeEmpUploadField(res); }),
        map((res: any) => { return handleSuccess(res.payload.messages[0]); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEmpUploadField$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateEmpUploadField.type),
    switchMap((action: { payload: EmployeeUploadMap; }) => {
      return from(EmployeeUploadService.putEmpUploadField(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeEmpUploadField(res); }),
        map((res: any) => { return handleSuccess(res.payload.messages[0]); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteEmpUploadFields$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteEmpUploadFields.type),
    switchMap((action: { payload: EmployeeUploadMap[]; }) => {
      return forkJoin(
        action.payload.map((x: EmployeeUploadMap) => { return from(EmployeeUploadService.deleteEmpUploadField(x)); },
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        switchMap((res: any) => [
          loadEmpUploads(),
          handleSuccess("Upload field deleted successfully"),
        ]),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const addUpdateEmpUploadTranslation$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(addUpdateEmpUploadTranslation.type),
    switchMap((action: { payload: { clientFileId: number; mapId: number; translations: EmployeeUploadTranslation[]; }; }) => {
      return from(
        EmployeeUploadService.postAddUpdateEmployeeTranslation(
          action.payload.clientFileId,
          action.payload.mapId,
          action.payload.translations,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => { return [
          loadEmpUploads(),
          handleSuccess(res.messages[0]),
        ]}),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteEmpUploadTranslation$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteEmpUploadTranslation.type),
    switchMap((action: { payload: { clientFileId: number; translations: EmployeeUploadTranslation[]; }; }) => {
      return forkJoin(
        action.payload.translations.map((x: EmployeeUploadTranslation) => { return from(EmployeeUploadService.deleteEmpUploadTranslation(action.payload.clientFileId, x)); },
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        switchMap((res: any) => [
          loadEmpUploads(),
          handleSuccess("Upload map translations deleted successfully"),
        ]),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

//Create, Update and Delete Translations all added to one API that logic is above. 
const createEmpUploadTranslation$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createEmpUploadTranslation.type),
    switchMap((action: { payload: { clientFileId: number; translation: EmployeeUploadTranslation; }; }) => {
      return from(
        EmployeeUploadService.postEmpUploadTranslation(
          action.payload.clientFileId,
          action.payload.translation,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeEmpUploadTranslation(action.payload); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEmpUploadTranslation$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateEmpUploadTranslation.type),
    switchMap((action: { payload: { clientFileId: number; translation: EmployeeUploadTranslation; }; }) => {
      return from(
        EmployeeUploadService.putEmpUploadTranslation(
          action.payload.clientFileId,
          action.payload.translation,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeEmpUploadTranslation(action.payload); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  getEmpUploads$,
  createEmpUploadClientFile$,
  updateEmpUploadClientFile$,

  createEmpUploadClientLibrary$,

  createEmpUploadField$,
  updateEmpUploadField$,
  deleteEmpUploadFields$,

  createEmpUploadTranslation$,
  updateEmpUploadTranslation$,
  deleteEmpUploadTranslation$,
  addUpdateEmpUploadTranslation$,
];
