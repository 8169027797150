import { Client } from 'core/models';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputDateSelector from '../../core/components/form-controls/InputDateSelector';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import { MonthlyCashFlowAnalysisDownloadReport } from '../../core/models/PayrollReports.model';
import { clearMontlyCashFlowAnalysisDownloadReport, downloadMontlyCashFlowAnalysisDownloadReport } from '../../core/store/actions/payoll-reports.action';
import { useAppDispatch, useAppSelector } from '../../utilities/hooks';
import { CommonService } from 'core/services';
import Icon from 'core/components/shared/Icon';


const MontlyCashFlowAnalysisDownloadReport = () => {

  const dispatch = useAppDispatch();
  const childRef: React.RefObject<any> = useRef();

  const [reportDates, setReportDates] = useState<string[]>([]);
  const [fileType, setFileType] = useState<string>('');
  const report = useAppSelector((state) => { return state?.payrollReports.monthlyCashFlowAnalysisDownloadReport; });
  const client = useAppSelector((state) => { return state.client.client; }) as Client;


  const hidePdfViewer = () => {
    dispatch(clearMontlyCashFlowAnalysisDownloadReport());
  };

  const { handleSubmit } =
    useForm<MonthlyCashFlowAnalysisDownloadReport>();
  
  useEffect(() => {
    if (report && fileType === 'excel') {
      CommonService.downloadBase64File(
        'application/excel',
        report,
        'MonthlyCashFlowAnalysisDownloadReport.xlsx',
      );
      dispatch(clearMontlyCashFlowAnalysisDownloadReport());
    }
  }, [report]);

  const downloadReport = (data: MonthlyCashFlowAnalysisDownloadReport) => {
    const submitData: MonthlyCashFlowAnalysisDownloadReport = {
      clientNo: client.clientNo,
      dateFrom: reportDates[0],
      dateTo: reportDates[1],
    };
    dispatch(downloadMontlyCashFlowAnalysisDownloadReport(submitData));
  };

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">Monthly CashFlow Analysis Download Report</div>
          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title "
              onClick={() => { return setFileType('excel'); }}
            >
              Download Report  <Icon
                name="download"
                className="fa-download"
              />
            </button>
          </div>
          <hr className="dm-panel-hr" />
          <div className="d-flex flex-row flex-grow-1">
            <InputDateSelector
              ref={childRef}
              returnDates={(dates: string[]) => { return setReportDates(dates); }}
              hideDateTypes={true}
            />
          </div>
        </form>
      </div>
      {report && (
        <PDFViewerModal
          show={report.length > 0
          }
          pdfData={report}
          onHide={hidePdfViewer}
          reportName="MonthlyCashFlowAnalysisDownloadReport"
        />
      )}
    </div>
  );
};

export default MontlyCashFlowAnalysisDownloadReport;