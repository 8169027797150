import React, { useEffect, useState } from 'react';
import { PayrollTimeCardJobInfo, TaxingCity } from 'core/models/Contractor.model';
import { useForm } from 'react-hook-form';
import Modal from 'core/components/modals/Modal';
import { addUpdateJobInfo2 } from 'core/store/actions';
import { Tab, Tabs } from 'react-bootstrap';
import { Prompt } from 'react-router-dom';
import { UNSAVED_MESSAGE } from 'core/constants';
import PrevailingWagesTab from './PrevailingWagesTab';
import JobDetailsTab from './JobDetailsTab';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import ConfirmationModal from 'core/components/modals/confirmation.modal';

const newJob: PayrollTimeCardJobInfo = {
  clientNo: -9999,
  jobNo: '',
  description: '',
  projectNo: '',
  name: '',
  address1: '',
  address2: '',
  city: '',
  county: '',
  state: '',
  zip: '',
  zipPlus4: '',
  taxCityCode: '',
  taxCityDesc: '',
  startDate: null,
  closeDate: null,
  certYN: true,
  activeYN: true,
  excludeFromWc: false,
  showAddressOnCertifiedReport: true,
  isOcip: false,
  isSubContractor: false,
  bricklayerCementMasonRate: '',
  certIncAll: false,
  useDavisBaconWage: false,
  subjectToExecutiveOrder: false,
  isNationalJob: false,
  approvedForSpecialUnionRate: false,
  procoreCostCode: '',
  prevailingWages: [],
};

type Props = {
  jobInfo?: PayrollTimeCardJobInfo;
  addJob: boolean;
  show: boolean;
  onHide: () => void;
};

const JobDetail = ({ jobInfo = newJob, show, addJob, onHide }: Props) => {
  const [activeKey, setActiveKey] = useState<string>('jobDetails');
  const [selectedState, setSelectedState] = useState<string>(jobInfo.state);
  const [selectedTaxingCity, setSelectedTaxingCity] = useState<TaxingCity>();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  
  const clientNo = useAppSelector(({ client }) => client?.client?.clientNo);
  
  const appDispatch = useAppDispatch();
  
  const defaultObj: PayrollTimeCardJobInfo = {
    ...jobInfo,
    clientNo: clientNo ?? -9999, 
    startDate: jobInfo?.startDate ? new Date(jobInfo.startDate) : null,
    closeDate: jobInfo?.closeDate ? new Date(jobInfo.closeDate) : null,
  };
  
  const {
    control,
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { isDirty },
    reset,
  } = useForm<PayrollTimeCardJobInfo>({
    defaultValues: defaultObj,
  });
  
  const { useDavisBaconWage, jobNo } = watch(['useDavisBaconWage', 'jobNo']);
  
  const handleSave = async (updatedJobInfo: PayrollTimeCardJobInfo) => {
    const transformedSubmitData = structuredClone(updatedJobInfo);
    transformedSubmitData.jobNo = String(transformedSubmitData.jobNo);
    transformedSubmitData.prevailingWages = transformedSubmitData?.prevailingWages?.length ? transformedSubmitData.prevailingWages : [];
    transformedSubmitData.state = selectedState;
    transformedSubmitData.taxCityCode = selectedTaxingCity?.entityCode ?? jobInfo.taxCityCode;
    transformedSubmitData.taxCityDesc = selectedTaxingCity?.codeDesc ?? jobInfo.taxCityDesc;
    transformedSubmitData.taxCityId = selectedTaxingCity?.entityId ?? jobInfo?.taxCityId;

    appDispatch(addUpdateJobInfo2(transformedSubmitData))
      .then((_res) => {
        reset({
          ...updatedJobInfo,
          clientNo: clientNo ?? -9999,
          startDate: updatedJobInfo?.startDate ? new Date(updatedJobInfo.startDate) : null,
          closeDate: updatedJobInfo?.closeDate ? new Date(updatedJobInfo.closeDate) : null,
        });
      });
  };
  
  const hideModal = () => {
    if (isDirty) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };
  
  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };
  
  return (
    <Modal
      title="Job Detail"
      size={activeKey === 'prevailingWages' ? 'xl' : 'sm'}
      show={show}
      onHide={hideModal}
      isDirty={isDirty}
    >
      <Prompt
        when={isDirty}
        message={UNSAVED_MESSAGE}
      />
      <form onSubmit={handleSubmit(handleSave)}>
        <input
          type="hidden"
          name="clientNo"
          ref={register({
            valueAsNumber: true,
          })}
        />
        <Tabs
          defaultActiveKey={'jobDetails'}
          activeKey={activeKey}
          onSelect={(e) => {
            if (!e) return;
            setActiveKey(e);
          }}
        >
          <Tab
            title="Job Details"
            eventKey={'jobDetails'}
          >
            <JobDetailsTab
              addJob={addJob}
              control={control}
              register={register}
              setValue={setValue}
              watch={watch}
              setSelectedState={setSelectedState}
              setSelectedTaxingCity={setSelectedTaxingCity}
            />
          </Tab>
          {useDavisBaconWage && (
            <Tab
              title="Prevailing Wages"
              eventKey={'prevailingWages'}
            >
              <PrevailingWagesTab
                register={register}
                control={control}
                jobNo={jobNo}
              />
            </Tab>
          )}
        </Tabs>
        <div className="d-flex justify-content-end mt-3">
          <button
            className="orange-outline-button-sm mr-2"
            onClick={(e) => {
              e.preventDefault();
              hideModal();
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={!isDirty}
            className="orange-button-sm"
          >
            Save
          </button>
        </div>
      </form>
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )
      }
    </Modal>
  );
};

export default JobDetail;