import { createAsyncThunk } from '@reduxjs/toolkit';
import { getACADatesPerYear, getACAYear, getEmpHours, getFullTimeCalculationReport as getFTCR, postFullTimeCalculationReport as postFTCR, updateACADatesPerYear, updateEmpHours } from 'core/services/human-capital-reports.service';
import { ClientState } from 'core/store/reducers/client.reducer';
import { ACAFullTimeCalculationReportState } from 'core/store/slices/human-capital-management/aca-full-time-calculation.slice';
import DateObject from 'react-date-object';


export const getACADate = createAsyncThunk(
  'reports/getACADatePerYear',
  async (year: number) => {
    const response = await getACADatesPerYear(year);

    return response?.data || response;
  },
);

export const updateACADates = createAsyncThunk(
  'reports/updateACADatePerYear',
  async (year: number, { getState }) => {
    const { acaFullTimeReport, client } = getState() as { acaFullTimeReport: ACAFullTimeCalculationReportState; client: ClientState };

    const response = await updateACADatesPerYear({
      year,
      acaDecisionEffectiveDate: acaFullTimeReport.acaDecisionEffectiveDate,
      activateAcaEffectiveDate: acaFullTimeReport.activateAcaEffectiveDate,
      adminFrom: acaFullTimeReport.adminFrom,
      adminTo: acaFullTimeReport.adminTo,
      measureFrom: acaFullTimeReport.measureFrom,
      measureTo: acaFullTimeReport.measureTo,
      stabilityFrom: acaFullTimeReport.stabilityFrom,
      stabilityTo: acaFullTimeReport.stabilityTo,
      clientId: client?.client?.clientID,
      clientNo: client?.client?.clientNo,
    });

    return response?.data || response;
  },
);

export const getMescAcaHours = createAsyncThunk(
  'reports/getMescAcaHours',
  async (empNo: string, { getState }) => {
    const { acaFullTimeReport } = getState() as { acaFullTimeReport: ACAFullTimeCalculationReportState };

    if (acaFullTimeReport.measureFrom && acaFullTimeReport.measureTo) {
      const beginDate = new DateObject(new Date(acaFullTimeReport?.measureFrom)).format('YYYY-MM-DD');
      const endDate = new DateObject(new Date(acaFullTimeReport?.measureTo)).format('YYYY-MM-DD');
      const response = await getEmpHours(empNo, beginDate, endDate);

      return response?.data || [];
    }
    
    return;
  },
);

export const updateMescAcaHours = createAsyncThunk(
  'reports/updateMescAcaHours',
  async (empNo: string, { getState }) => {
    const { acaFullTimeReport } = getState() as { acaFullTimeReport: ACAFullTimeCalculationReportState };

    const response = await updateEmpHours(empNo, acaFullTimeReport.data.mescEmployeeUpdates);

    return response?.data || response;
  },
);

export const getFullTimeCalculationReport = createAsyncThunk(
  'reports/getFullTimeCalculationReport',
  async (year: number, { getState }) => {
    const { acaFullTimeReport } = getState() as { acaFullTimeReport: ACAFullTimeCalculationReportState };

    if (acaFullTimeReport.measureFrom && acaFullTimeReport.measureTo) {
      const beginDate = new DateObject(new Date(acaFullTimeReport?.measureFrom)).format('YYYY-MM-DD');
      const endDate = new DateObject(new Date(acaFullTimeReport?.measureTo)).format('YYYY-MM-DD');
      
      const response = await getFTCR(year, beginDate, endDate);

      return response?.data || response;
    }

    return;
  },
);

export const postFullTimeCalculationReport = createAsyncThunk(
  'reports/postFullTimeCalculationReport',
  async (_, { getState }) => {
    const { acaFullTimeReport, client } = getState() as { acaFullTimeReport: ACAFullTimeCalculationReportState, client: ClientState };

    if (acaFullTimeReport.measureFrom && acaFullTimeReport.measureTo && client?.client?.clientNo) {
      const clientNo = client.client.clientNo;
      const beginDate = acaFullTimeReport?.measureFrom;
      const endDate = acaFullTimeReport?.measureTo;
      const eligibility = acaFullTimeReport.eligibility;
      const sortBy = acaFullTimeReport.sortBy;
      let sortByValue = sortBy;

      switch (sortBy) {
        case 'Name Alpha': {
          sortByValue = 'Employee Name';
          break;
        }

        case 'Sub Dept': {
          sortByValue = 'Sub Department';
          break;
        }

        case 'Sub Dept2': {
          sortByValue = 'Sub Department2';
          break;
        }

        default: {
          break;
        }
      }

      const response = await postFTCR({
        clientNo,
        beginDate,
        endDate,
        sortBy: sortByValue?.replaceAll(' ', '').replace(/[^a-zA-Z0-9 ]/g, ''),
        type: eligibility,
        employeeIsEligible: acaFullTimeReport.employeeIsEligible,
        waitingPeriodDays: +acaFullTimeReport.waitingPeriodDays,
      });

      return response?.data || response; 
    }

    return;
  },
);

export const getFullTimeCalculationStates = createAsyncThunk(
  'reports/getFullTimeCalculationStates',
  async (year: number) => {
    const response = await getACAYear(year);
    return response?.data;
  },
);