import { createSelector } from 'reselect';
import { RootState } from '../store';

export const payrollAdjustments = (state: RootState) => {return state.payrollAdjustment.payrollAdjustments;};

export const getPayrollAdjustments = createSelector(
  payrollAdjustments,
  (state) => {return state.slice().sort((a, b) => {return a.empNo - b.empNo;});},
);

export const getEmployeePayrollAdjustment = createSelector(
  [
    (state: RootState) => {return state.payrollAdjustment.payrollAdjustments;},
    (_, empNo: number) => {return empNo;},
  ],
  (payrollAdjustments, empNo) => {
    return payrollAdjustments?.find((item) => {return item.empNo === empNo;});
  },
);
