import { createAction } from '@reduxjs/toolkit';
import {
  Payroll401kReportRequest,
  Payroll4070TipReportRequest,
  CashFlowReportRequest,
  AnnualFICATipCreditReportRequest,
  PensionReportRequest,
  PensionSetting,
  ScheduledDeductionsReportRequest,
  WorkersCompensationReportRequest,
  DeductionOptionsReportRequest,
  TaxReportRequest,
  MemoCashFlowReportRequest,
  EmployeeLastCheckReportRequest,
  GrossToNetRequest,
  UnionDuesReportRequest,
  UnionDuesSettingsRequest,
  DeductionShortagesReportRequest,
  MonthlyCashFlowAnalysisDownloadReport,
  CARESCreditReportRequest,
} from '../../models/PayrollReports.model';

export const downloadPayroll401kReport = createAction<Payroll401kReportRequest>(
  '[payroll-report] DOWNLOAD_PAYROLL_401K_REPORT',
);
export const storePayroll401kReport = createAction<string>(
  '[payroll-report] STORE_PAYROLL_401K_REPORT',
);
export const clearPayroll401kReport = createAction(
  '[payroll-report] CLEAR_PAYROLL_401K_REPORT',
);


export const downloadPayroll4070TipReport = createAction<Payroll4070TipReportRequest>(
  '[payroll-report] DOWNLOAD_PAYROLL_4070Tip_REPORT',
);
export const storePayroll4070TipReport = createAction<string>(
  '[payroll-report] STORE_PAYROLL_4070Tip_REPORT',
);
export const clearPayroll4070TipReport = createAction(
  '[payroll-report] CLEAR_PAYROLL_4070Tip_REPORT',
);


export const downloadCashFlowReport = createAction<CashFlowReportRequest>(
  '[payroll-report] DOWNLOAD_CASH_FLOW_REPORT',
);
export const storeCashFlowReport = createAction<string>(
  '[payroll-report] STORE_CASH_FLOW_REPORT',
);
export const clearCashFlowReport = createAction(
  '[payroll-report] CLEAR_CASH_FLOW_REPORT',
);

export const downloadAnnualFICATipCreditReport = createAction<AnnualFICATipCreditReportRequest>(
  '[payroll-report] DOWNLOAD_ANNUAL_FICA_TIP_CREDIT_REPORT',
);
export const storeAnnualFICATipCreditReport = createAction<string>(
  '[payroll-report] STORE_ANNUAL_FICA_TIP_CREDIT_REPORT',
);
export const clearAnnualFICATipCreditReport = createAction(
  '[payroll-report] CLEAR_ANNUAL_FICA_TIP_CREDIT_REPORT',
);

export const downloadScheduledDeductionsReport = createAction<ScheduledDeductionsReportRequest>(
  '[payroll-report] DOWNLOAD_SCHEUDLED_DEDUCTIONS_REPORT',
);
export const storeScheduledDeductionsReport = createAction<string>(
  '[payroll-report] STORE_SCHEUDLED_DEDUCTIONS_REPORT',
);
export const clearScheduledDeductionsReport = createAction(
  '[payroll-report] CLEAR_SCHEUDLED_DEDUCTIONS_REPORT',
);


export const downloadPensionReport = createAction<PensionReportRequest>(
  '[payroll-report] DOWNLOAD_PENSION_REPORT',
);
export const storePensionReport = createAction<string>(
  '[payroll-report] STORE_PENSION_REPORT',
);
export const clearPensionReport = createAction(
  '[payroll-report] CLEAR_PENSION_REPORT',
);

export const loadPensionSettings = createAction(
  '[payroll-report] LOAD_PENSION_SETTINGS',
);
export const storePensionSettings = createAction<PensionSetting[]>(
  '[payroll-report] STORE_PENSION_SETTINGS',
);
export const createPensionSettings = createAction<PensionSetting>(
  '[payroll-report] CREATE_PENSION_SETTINGS',
);
export const updatePensionSettings = createAction<PensionSetting>(
  '[payroll-report] UPDATE_PENSION_SETTINGS',
);
export const deletePensionSettings = createAction<PensionSetting>(
  '[payroll-report] DELETE_PENSION_SETTINGS',
);

export const downloadWorkersCompensationReport = createAction<WorkersCompensationReportRequest>(
  '[payroll-report] DOWNLOAD_WORKERS_COMPENSATION_REPORT',
);
export const storeWorkersCompensationReport = createAction<string>(
  '[payroll-report] STORE_WORKERS_COMPENSATION_REPORT',
);
export const clearWorkersCompensationReport = createAction(
  '[payroll-report] CLEAR_WORKERS_COMPENSATION_REPORT',
);


export const downloadDeductionOptionsReport = createAction<DeductionOptionsReportRequest>(
  '[accounting-report] DOWNLOAD_DEDUCTION_OPTIONS_REPORT',
);
export const storeDeductionOptionsReport = createAction<string>(
  '[accounting-report] STORE_DEDUCTION_OPTIONS_REPORT',
);
export const clearDeductionOptionsReport = createAction(
  '[accounting-report] CLEAR_DEDUCTION_OPTIONS_REPORT',
);

export const downloadTaxReport = createAction<TaxReportRequest>(
  '[payroll-report] DOWNLOAD_TAX_REPORT',
);
export const storeTaxReport = createAction<string>(
  '[payroll-report] STORE_TAX_REPORT',
);
export const clearTaxReport = createAction(
  '[payroll-report] CLEAR_TAX_REPORT',
);


export const downloadMemoCashFlowReport = createAction<MemoCashFlowReportRequest>(
  '[payroll-report] DOWNLOAD_MEMO_CASH_FLOW_REPORT',
);
export const storeMemoCashFlowReport = createAction<string>(
  '[payroll-report] STORE_MEMO_CASH_FLOW_REPORT',
);
export const clearMemoCashFlowReport = createAction(
  '[payroll-report] CLEAR_MEMO_CASH_FLOW_REPORT',
);

export const downloadEmployeeLastCheckReport = createAction<EmployeeLastCheckReportRequest>(
  '[payroll-report] DOWNLOAD_EMPLOYEE_LAST_CHECK_REPORT',
);
export const storeEmployeeLastCheckReport = createAction<string>(
  '[payroll-report] STORE_EMPLOYEE_LAST_CHECK_REPORT',
);
export const clearEmployeeLastCheckReport = createAction(
  '[payroll-report] CLEAR_EMPLOYEE_LAST_CHECK_REPORT',
);
export const loadGrossToNetReport = createAction<GrossToNetRequest>(
  '[payroll] LOAD_GROSS_TO_NET_REPORT',
);
export const storeGrossToNetReport = createAction<string>(
  '[payroll] STORE_GROSS_TO_NET_REPORT',
);
export const clearGrossToNetReport = createAction(
  '[payroll] CLEAR_GROSS_TO_NET_REPORT',
);
export const downloadUnionDuesReport = createAction<UnionDuesReportRequest>(
  '[payroll-report] LOAD_UNION_DUES_REPORT',
);

export const toggleRunningUnionDues = createAction<'pending' | 'success' | 'error'>('[payroll-report] TOGGLE_RUN_UDR');

export const storeUnionDuesReport = createAction<string>(
  '[payroll-report] STORE_UNION_DUES_REPORT',
);
export const clearUnionDuesReport = createAction(
  '[payroll-report] CLEAR_UNION_DUES_REPORT',
);
export const storeUnionDuesSettings = createAction<UnionDuesSettingsRequest[]>(
  '[payroll-report] STORE_UNION_DUES_SETTINGS',
);
export const createUnionDuesSettings = createAction<UnionDuesSettingsRequest>(
  '[payroll-report] CREATE_UNION_DUES_SETTINGS',
);
export const updateUnionDuesSettings = createAction<UnionDuesSettingsRequest>(
  '[payroll-report] UPDATE_UNION_DUES_SETTINGS',
);
export const deleteUnionDuesSettings = createAction<UnionDuesSettingsRequest>(
  '[payroll-report] DELETE_UNION_DUES_SETTINGS',
);

export const downloadDeductionShortagesReport = createAction<DeductionShortagesReportRequest>(
  '[payroll-report] LOAD_DEDUCTION_SHORTAGE_REPORT',
);

export const storeDeductionShortagesReport = createAction<string>(
  '[payroll-report] STORE_DEDUCTION_SHORTAGE_REPORT',
);
export const clearDeductionShortagesReport = createAction(
  '[payroll-report] CLEAR_DEDUCTION_SHORTAGE_REPORT',
);

export const downloadMontlyCashFlowAnalysisDownloadReport = createAction<MonthlyCashFlowAnalysisDownloadReport>(
  '[payroll-report] DOWNLOAD_MONTHLY_CASHFLOW_ANALYSIS_DOWNLOAD_REPORT',
);
export const storeMontlyCashFlowAnalysisDownloadReport = createAction<string>(
  '[payroll-report] STORE_MONTHLY_CASHFLOW_ANALYSIS_DOWNLOAD_REPORT',
);
export const clearMontlyCashFlowAnalysisDownloadReport = createAction(
  '[payroll-report] CLEAR_MONTHLY_CASHFLOW_ANALYSIS_DOWNLOAD_REPORT',
);

export const downloadCARESCreditReport = createAction<CARESCreditReportRequest>(
  '[payroll-report] LOAD_CARES_CREDIT_REPORT',
);

export const storeCARESCreditReport = createAction<string>(
  '[payroll-report] STORE_CARES_CREDIT_REPORT',
);
export const clearCARESCreditReport = createAction(
  '[payroll-report] CLEAR_CARES_CREDIT_REPORT',
);





