import React from 'react';
import { ControlDatePickerGrpInLine } from 'core/components/form-controls';
import { Modal } from 'react-bootstrap';
import Icon from 'core/components/shared/Icon';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'utilities/hooks';

type Props = {
  show: boolean;
  onHide: () => void;
};

const AdjustTaxesModal = ({ show, onHide }: Props) => {
  const { setValue, watch, control, errors } = useForm<any>({});
  
  const dispatch = useAppDispatch();
  
  const dates = { weekEnd: new Date(),
    checkDate: new Date() };
  
  const handleSubmit = () => { onHide(); };
  
  return (
    <Modal show={show}
      onHide={onHide}
      size="sm">
      <Modal.Header>
        <div className="dm-card-title">
          Adjust Fica/SUI/Futa Penalty Taxes
        </div>
        <button type="button"
          onClick={onHide}>
          <Icon name="times"
            className="modal-close-btn"
          />
        </button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="d-flex flex-column">
            <div className="d-flex">
              <ControlDatePickerGrpInLine
                name={'weekEnd'}
                label={'Current Week End: '}
                value={dates.weekEnd}
                setValue={setValue}
                labelWidth={40}
                groupClass={'groupClass50'}
                control={control}
                maxDate={new Date()}
              />
              <ControlDatePickerGrpInLine
                name={'checkDate'}
                label={'Current Check Date: '}
                value={dates.checkDate}
                setValue={setValue}
                labelWidth={40}
                groupClass={'groupClass50'}
                control={control}
                maxDate={new Date()}
              />
            </div>
            <div className="d-flex justify-content-end">
              <div>
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button"
                  onClick={onHide}
                >
                  Cancel
                </button>
              </div>
              <div className="ml-2">
                <button
                  type="submit"
                  className="btn btn-primary orange-button"
                  disabled={!(dates.weekEnd && dates.checkDate)}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>          
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AdjustTaxesModal;