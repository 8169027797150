import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CustomEmail, TestEmail } from 'core/models';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { InputGrp } from 'core/components/form-controls';
import { postCustomEmail, testCustomEmail } from 'core/store/slices/applicantTracking.slice';
import Modal from 'core/components/modals/Modal';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import ReactQuill from 'react-quill';
import QuillCustomToolbar from 'core/components/form-controls/QuillCustomToolbar';

type Props = {
  show:boolean;  
  item: CustomEmail | undefined;
  onHide: ()=> void;
};


const CustomEmailModal: React.FC<Props> = ({
  show,
  item,
  onHide,
}) => {

  const dispatch = useAppDispatch();
  const [client] = useAppSelector(
    (state) => {
      return [
        state.client.client];
    },
  );
  const [emailBody, setEmailBody] = useState<string>('');
  const [testEmailAddress, setTestEmailAddress] = useState<string>('');
  const [quillIsDirty, setQuillIsDirty] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const { register, reset, handleSubmit, getValues, formState:{ isDirty, dirtyFields } } = useForm<CustomEmail>({
    defaultValues: { ...item,
      customEmailId: item?.customEmailId ?? 0,
    },
  });  

  const modules = {
    toolbar: {
      container: '#toolbar',
    },
  };

  useEffect(() => {    
    if (item) {
      reset(item);    
      setEmailBody(item.emailBody);         
    }
    setQuillIsDirty(false); 
  }, [item]);

  const hideModal = () => {  
    if (isDirty || quillIsDirty) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };

  const onSetEmailBody = (e:any) => {
    item?.emailBody !== e && setQuillIsDirty(true);
    setEmailBody(e);       
  };

  const onSubmit = (data: any) => {
    const ce:CustomEmail = item ? {
      ...item,
      description: data.description,
      emailBody: emailBody,
      emailSubject:data.emailSubject,
    } : {
      customEmailId: 0,      
      hrEntityId: client?.hrEntityId ?? 0,
      description: data.description,
      emailBody: emailBody,
      emailSubject:data.emailSubject,
    };

    dispatch(postCustomEmail(ce));
    setQuillIsDirty(false);
  };
  
  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };

  const onSendTestEmail = () => {
    const values = getValues();
    const testRequest: TestEmail = {
      description: values.description,
      body: emailBody,
      subject: values.emailSubject,
      testEmailAddress: testEmailAddress,
    };
    dispatch(testCustomEmail(testRequest));
  };
 
  return (
    
    <Modal
      show={show}
      onHide={hideModal}
      dialogClassName="lg" 
      isDirty={isDirty || quillIsDirty}     
      backdrop={'static'}
      title="Custom Email"
    >     
     
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="d-flex flex-column flex-grow-1"
        >  
          <input
            type="hidden"
            name={'customEmailId'}          
            ref={register({ valueAsNumber: true })}
          />      
          <div>
            <div className="dm-subtitle3">Description</div>
            <InputGrp
              label={''}
              name={'description'}
              defaultValue={item?.description}
              ref={register}
            />
          </div>             
          <div>
            <div className="dm-subtitle3">Subject</div>
            <InputGrp
              label={''}
              name={'emailSubject'}
              defaultValue={item?.emailSubject}
              ref={register}
            />
          </div>
          <div>
            <div className="dm-subtitle3">Send Test Email To</div>
            <InputGrp
              label={''}
              type="email"
              value={testEmailAddress}
              onChange={(e:any)=> {return setTestEmailAddress(e.target.value);}}             
            />
          </div>             
          <div className="dm-subtitle3">Body</div>
          <div className="dm-panel dm-panel-border">  
            <QuillCustomToolbar />         
            <ReactQuill             
              theme="snow"
              value={emailBody}
              modules={modules}
              onChange={(e: any) => { return onSetEmailBody(e); }}
            />
          </div>
        </div>      
        <div className="d-flex mt-3">
          <button
            type="button"
            className="btn btn-link ml-auto mr-2"
            onClick={onSendTestEmail}
          >
            Send Test Email
          </button>
          <button
            type="button"
            className="orange-outline-button mr-2"
            onClick={hideModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="orange-button mr-2"
            disabled={!((isDirty && !Object.keys(dirtyFields).includes('testEmailAddress')) || quillIsDirty)}
          >
            Save
          </button>
        </div>
      </form>
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
    </Modal>
  );
};
export default CustomEmailModal;

