import Modal from 'core/components/modals/Modal';
import React from 'react';
import { BaseProps } from './TransmittalError';

const RecoverySubmittedModal = ({ show, onHide }: BaseProps) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Recovery Submitted"
    >
      {(closeModal) => {
        return (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            <div>
              This payroll has been submitted for recovery.
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <button
                className="btn btn-primary orange-button mt-2 mb-2"
                onClick={closeModal}
              >
                Okay
              </button>
            </div>
          </div>
        );
      }}
    </Modal>
  );
};

export default RecoverySubmittedModal;
