import { createAction } from '@reduxjs/toolkit';
import { DeferredCompensation } from 'core/models';

export const DEFCOMP_EM_VALIDATION_ACTIONS = {
  UPDATE_DEFERRED_COMPENSATION: '[deferredComp] UPDATE_DEFERRED_COMPENSATION',
} as const ;

export const loadDeferredCompensation = createAction<string>('[deferredComp] LOAD_DEFERRED_COMPENSATION');
export const updateDeferredCompensation = createAction<DeferredCompensation>('[deferredComp] UPDATE_DEFERRED_COMPENSATION');
export const storeDeferredCompensation = createAction<DeferredCompensation>('[deferredComp] STORE_DEFERRED_COMPENSATION');
