import {
  ColDef,
  GridApi,
  RowNode,
  ValueSetterParams,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, GridOptions, MasterDetailModule } from '@ag-grid-enterprise/all-modules';
import Icon from 'core/components/shared/Icon';
import { cloneDeep, isEqual } from 'lodash';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  InputGrpInLine,
  RadioGrpInLine,
  SelectGrpInLine,
} from '../../../core/components/form-controls';
import { FieldInputSettings } from '../../../core/components/form-controls/types';
import { ClientACA, MonthlyPremium, PremiumAmount } from '../../../core/models';
import { updateClientACA } from '../../../core/store/actions';
import { getStates } from '../../../core/store/selectors';
import {
  agDateEditor,
  agSelectEditor,
} from '../../../utilities/ag-grid-editors';
import {
  agDateRenderer,
  agLookupRenderer,
} from '../../../utilities/ag-grid-renderers';
import AGAddItemHeaderComponent from '../../../utilities/ag-grid-renderers/AGAddItemHeaderComponent';
import AGDeleteButtonRendererComponent from '../../../utilities/ag-grid-renderers/AGDeleteButtonRendererComponent';

const monthNameOpts = [
  {
    id: 1,
    description: 'January',
  },
  {
    id: 2,
    description: 'February',
  },
  {
    id: 3,
    description: 'March',
  },
  {
    id: 4,
    description: 'April',
  },
  {
    id: 5,
    description: 'May',
  },
  {
    id: 6,
    description: 'June',
  },
  {
    id: 7,
    description: 'July',
  },
  {
    id: 8,
    description: 'August',
  },
  {
    id: 9,
    description: 'September',
  },
  {
    id: 10,
    description: 'October',
  },
  {
    id: 11,
    description: 'November',
  },
  {
    id: 12,
    description: 'December',
  },
];

const yesNoOpts = [
  {
    id: 'true',
    description: 'Yes',
  },
  {
    id: 'false',
    description: 'No',
  },
];

const radioOptions = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];

const fs: FieldInputSettings = {
  employeeEligible: {
    name: 'waitingPeriodFollowingMonth',
    label: 'Employee is eligible beginning of the month following the waiting period.',
    groupClass: 'gc40 mw500',
    radioOptions,
  },
  waitingPeriod: {
    name: 'waitingPeriodDays',
    label: 'Waiting Period (Days)',
    groupClass: 'gc30 mw200',
  },
  planStartMonth: {
    name: 'planStartMonth',
    label: 'Plan Start Month',
    groupClass: 'gc30 mw200',
  },
};

type PropTypes = {
  clientAca: ClientACA;
  formMethods: any;
  getValues: any;
  childCloseModal: any;
};

let expandedPremiumRow: { acaMonthlyPremiumId: string } | null;

const QuestionsIITab: React.FC<any> = React.forwardRef(
  (props: PropTypes, ref: any) => {
    const dispatch = useDispatch();

    const [rowData, setRowData] = useState<any[]>([]);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const stateOpts = useSelector(getStates);

    useEffect(() => {
      props.clientAca.monthlyPremiums?.length &&
        setRowData(cloneDeep(props.clientAca.monthlyPremiums));
    }, [props.clientAca.monthlyPremiums]);

    useImperativeHandle(ref, () => {
      return {
        checkGridHasChanges(): boolean {
          const gridData = getGridData();
          const originalData = props.clientAca.monthlyPremiums;
          const hasChanges = !isEqual(gridData, originalData);
          return hasChanges;
        },
      };
    });

    const onExpandClick = (params: any) => {
      if (params.node.expanded) {
        params?.api?.forEachNode((node: RowNode) => {
          if (
            node.expanded &&
            node.id !== params.node.id &&
            node.uiLevel === params.node.uiLevel
          ) {
            node.setExpanded(false);
          }
        });
        expandedPremiumRow = params.data;
      } else {
        expandedPremiumRow = null;
      }
    };

    const onAddState = () => {
      const mp: any = [
        {
          acaMonthlyPremiumId: 0,
          state: '',
          startMonthAfterWaitingPeriod: true,
          waitingPeriod: 0,
          planStartMonth: '1',
          amounts: [],
        },
      ];
      gridApi?.applyTransaction({
        add: mp,
        addIndex: 0,
      });
    };

    const onDeleteState = (e: any) => {
      gridOptions?.api?.forEachNode((node: RowNode) => {
        const item = node.data as MonthlyPremium;
        if (item.acaMonthlyPremiumId === e.acaMonthlyPremiumId) {
          setRowData((prev) => {
            return prev.filter(
              (x) => {
                return x.acaMonthlyPremiumId !==
                  item.acaMonthlyPremiumId;
              },
            );
          },
          );
        }
      });
    };

    const onAddAmount = (item: any) => {
      const amounts = props.clientAca.monthlyPremiums?.find(
        (p: any) => { return p.acaMonthlyPremiumId === item?.acaMonthlyPremiumId; },
      )?.amounts;
      const detailGrid = gridOptions?.api?.getDetailGridInfo(
        'detail_' + item?.acaMonthlyPremiumId,
      );
      const detailGridApi = detailGrid?.api;
      const amountIndex = amounts?.length
        ? detailGridApi?.getRowNode(
          '' +
          amounts[amounts.length - 1].acaMonthlyPremiumAmountId,
        )?.rowIndex
        : 0;

      const amount: any = [
        {
          acaMonthlyPremiumAmountId: 0,
          amount: 0,
          effectiveDate: new Date(),
          editable: true,
        },
      ];

      detailGridApi?.applyTransaction({
        add: amount,
        addIndex: (amountIndex || 0) + 1,
      });
    };

    const onDeleteAmount = (e: any) => {
      const items: MonthlyPremium[] = [];
      gridOptions?.api?.forEachNode((node: RowNode) => {
        const item = node.data as MonthlyPremium;
        const amounts: PremiumAmount[] = [];
        item.amounts.forEach((amount: PremiumAmount) => {
          if (
            amount.acaMonthlyPremiumAmountId !==
            e.acaMonthlyPremiumAmountId
          ) {
            amounts.push(amount);
          }
        });
        items.push({
          ...item,
          amounts: amounts,
        });
      });
      setRowData(items);
    };

    //#region AgGrid
    const columns: ColDef[] = [
      {
        field: 'state',
        headerName: '',
        cellRenderer: 'agGroupCellRenderer',
        width: 30,
        editable: false,
      },
      {
        field: 'state',
        headerName: 'State',
        width: 150,
        cellEditorParams: { options: stateOpts },
        cellEditor: 'selectEditor',
        cellRendererParams: { options: stateOpts },
        cellRenderer: 'lookupRenderer',
      },
      {
        field: 'startMonthAfterWaitingPeriod',
        headerName: 'Start Month After Waiting Period',
        cellEditorParams: { options: yesNoOpts },
        cellEditor: 'selectEditor',
        cellRendererParams: { options: yesNoOpts },
        cellRenderer: 'lookupRenderer',
        width: 230,
      },
      {
        field: 'waitingPeriod',
        headerName: 'Waiting Period',
        width: 200,
      },
      {
        field: 'planStartMonth',
        headerName: 'Plan Start Month',
        cellEditorParams: { options: monthNameOpts },
        cellEditor: 'selectEditor',
        cellRendererParams: { options: monthNameOpts },
        cellRenderer: 'lookupRenderer',
        width: 150,
      },
      {
        field: 'delete',
        editable: false,
        cellRendererParams: { clickHandler: onDeleteState },
        cellRenderer: 'agDeleteButtonRendererComponent',
        width: 100,
      },
    ];

    const gridOptions: GridOptions = {
      columnDefs: columns,
      domLayout: 'autoHeight',
      defaultColDef: {
        editable: true,
        suppressMenu: true,
        cellClass: 'ag-cell-left-border',
        headerClass: 'grid-header',
        singleClickEdit: true,
      },
      pagination: true,
      paginationPageSize: 10,
      stopEditingWhenCellsLoseFocus: true,
      getRowNodeId: (data: any) => { return data.acaMonthlyPremiumId; },
      masterDetail: true,
      detailRowAutoHeight: true,
      detailCellRendererParams: {
        refreshStrategy: 'everything',
        detailGridOptions: {
          domLayout: 'autoHeight',
          stopEditingWhenCellsLoseFocus: true,
          defaultColDef: {
            editable: true,
            suppressMenu: true,
            resizable: true,
            singleClickEdit: true,
            cellClass: 'ag-cell-left-border',
          },
          getRowNodeId: (data: any) => { return data.acaMonthlyPremiumAmountId; },
          columnDefs: [
            {
              field: 'effectiveDate',
              headerName: 'Effective Date',
              cellRenderer: 'dateRenderer',
              cellEditor: 'dateEditor',
              width: 135,
              valueSetter: (params: ValueSetterParams) => {
                const { newValue } = params;
                if (!newValue) return false;
                const newDate = newValue + 'T00:00:00';
                params.data.effectiveDate = newDate;
                return true;
              },
            },
            {
              field: 'amount',
              headerName: 'Amount',
            },
            {
              field: 'delete',
              headerComponentParams: () => {
                return {
                  clickHandler: onAddAmount,
                  label: 'Add State Premium',
                  item: expandedPremiumRow,
                };
              },
              headerComponentFramework: AGAddItemHeaderComponent,
              editable: false,
              cellRendererParams: {
                clickHandler: onDeleteAmount,
              },
              cellRenderer: 'agDeleteButtonRendererComponent',
              width: 200,
            },
          ],         
          components: {
            dateRenderer: agDateRenderer,
            dateEditor: agDateEditor,
            agDeleteButtonRendererComponent:
            AGDeleteButtonRendererComponent,
          },
        },
        getDetailRowData: function (params: any) {
          params.successCallback(params.data.amounts);
        },
      },     
      components: {
        lookupRenderer: agLookupRenderer,
        selectEditor: agSelectEditor,
        agDeleteButtonRendererComponent:
        AGDeleteButtonRendererComponent,
      },
    };

    const onGridReady = (params: any) => {
      setGridApi(params.api);
    };

    const getGridData = () => {
      const items: any[] = [];
      gridApi?.forEachNode((node) => {
        const item = node.data as any;
        gridApi?.forEachDetailGridInfo((detailGridInfo, index) => {
          const detailData = detailGridInfo.api?.getRowNode(
            '' + index,
          )?.data;
          if (detailData) {
            const amount = detailData;
            delete amount.editable;
            amount.effectiveDate = new Date(
              amount.effectiveDate,
            ).toISOString();
            item.amounts.push({ ...amount });
          }
        });
        items.push(item);
      });
      return items;
    };

    const onSave = () => {
      const items: any[] = [];
      gridApi?.forEachNode((node) => {
        const item = node.data as any;
        gridApi?.forEachDetailGridInfo((detailGridInfo, index) => {
          const detailData = detailGridInfo.api?.getRowNode(
            '' + index,
          )?.data;
          if (
            detailData &&
            detailGridInfo.id ===
            'detail_' + item.acaMonthlyPremiumId
          ) {
            const amount = detailData;
            amount.effectiveDate = new Date(
              amount.effectiveDate,
            ).toISOString();
            item.amounts.push({ ...amount });
          }
        });
        items.push(item);
      });
      const values = props.getValues();
      const updatedValues = {
        ...values,
        monthlyPremiums: items?.map((item: MonthlyPremium) => {
          return { ...new MonthlyPremium(item) };
        }),
      };
      const updateACA = {
        ...props.clientAca,
        ...updatedValues,
      };
      const aca = { ...new ClientACA(updateACA) };
      dispatch(updateClientACA(aca));
    };

    const closeParentModal = () => {
      props.childCloseModal();
    };

    return (
      <div className="m-2">
        <div className="d-flex flex-row">
          <RadioGrpInLine
            {...fs.employeeEligible}
            errors={props.formMethods.errors.employeeEligible}
            ref={props.formMethods.register()}
          />
          <InputGrpInLine
            {...fs.waitingPeriod}
            errors={props.formMethods.errors.waitingPeriod}
            ref={props.formMethods.register()}
          />
          <SelectGrpInLine
            {...fs.planStartMonth}
            errors={props.formMethods.errors.planStartMonth}
            defaultValue={props.formMethods.planStartMonth}
            ref={props.formMethods.register()}
            options={monthNameOpts}
          />
        </div>
        <hr />

        <div className="d-flex flex-row p-2">
          <strong>
            Employee Share of Lower Cost Monthly Premium, for
            Self-Only Minimum Value Coverage
          </strong>
          <div className="ml-auto d-flex flex-wrap">
            <button
              type="button"
              className="btn btn-link dm-grid-action-title p-0 mr-2"
              onClick={onAddState}
            >
              Add State{' '}
              <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          </div>
        </div>

        {props.clientAca.monthlyPremiums && (
          <Col
            xs={12}
            style={{ height: '375px' }}
          >
            <div className="table-wrapper-wrapper ag-theme-balham">
              <AgGridReact
                gridOptions={gridOptions}
                rowData={rowData}
                modules={[MasterDetailModule]}
                onRowGroupOpened={onExpandClick}
                onGridReady={onGridReady}
                immutableData={true}
              ></AgGridReact>
            </div>

          </Col>
        )}
        <div className="row mt-4">
          <div className="col-12 text-right">
            <button
              type="button"
              className="btn btn-primary orange-outline-button ml-auto mr-2"
              onClick={closeParentModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn orange-button-sm mr-3"
              onClick={onSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  },
);

QuestionsIITab.displayName = 'QuestionsIITab';

export default QuestionsIITab;
