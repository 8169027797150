import { createReducer } from '@reduxjs/toolkit';
import { AccrualEarningsCode, AttendanceOnDemand, ClientAod, WorkgroupItem } from '../../models';
import { loadAttendanceOnDemand, storeAttendanceOnDemand, storeClientAODWorkgroup, storeClientAttendanceOnDemand } from '../actions';

export interface State {
  loading: boolean;
  attendanceOnDemand: AttendanceOnDemand | null;
  clientAttendanceOnDemand: ClientAod | null;
  workgroups: WorkgroupItem[];
}

const INITIAL_STATE: State = {
  loading: false,
  attendanceOnDemand: null,
  clientAttendanceOnDemand: null,
  workgroups: [],
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadAttendanceOnDemand, (state) => {
      state.loading = true;
    })
    .addCase(storeAttendanceOnDemand, (state, action) => {
      state.loading = false;
      state.attendanceOnDemand = action.payload;
    })
    .addCase(storeClientAttendanceOnDemand, (state, action) => {
      state.loading = false;
      state.clientAttendanceOnDemand = action.payload;
    })
    .addCase(storeClientAODWorkgroup, (state, action) => {
      state.loading = false;
      state.workgroups = action.payload;
    })
  ;
});
