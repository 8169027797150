import React, { useEffect, useState } from 'react';
import { InterviewLocation } from 'core/models';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { postInterviewLocation } from 'core/store/slices/applicantTracking.slice';
import Modal from 'core/components/modals/Modal';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { useForm } from 'react-hook-form';
import { InputGrp } from 'core/components/form-controls';

type Props = {
  show: boolean; 
  item:InterviewLocation | undefined;
  onHide: () => void;
};

const InterviewLocationModal: React.FC<Props> = ({
  show,
  item,
  onHide,
}) => {

  const dispatch = useAppDispatch();
  const [client] = useAppSelector(
    (state) => {
      return [
        state.client.client];
    },
  );
  
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  
  const { register, reset, handleSubmit, formState:{ isDirty } } = useForm<InterviewLocation>({
    defaultValues: { ...item,
      interviewLocationId: item?.interviewLocationId ?? 0 },
  });
  
  useEffect(() => {    
    item && reset(item);   
  }, [item]);

  const hideModal = () => {
    if (isDirty) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };

  const onSubmit = (data: any) => {

    const il: InterviewLocation = item ? {
      ...item,
      description: data.description,
    } : {
      interviewLocationId: 0,
      hrEntityId: client?.hrEntityId ?? 0,
      description: data.description,
    };

    dispatch(postInterviewLocation(il));
    reset(il);
  };

  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };

  return (

    <Modal
      show={show}
      onHide={hideModal}
      dialogClassName="lg"
      isDirty={isDirty}      
      backdrop={'static'}
      title="Design Interview Location"
    >
      
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="d-flex flex-column flex-grow-1"
        >  
          <input
            type="hidden"
            name={'interviewLocationId'}          
            ref={register({ valueAsNumber: true })}
          />      
          <div>
            <InputGrp
              label={'Location'}
              name={'description'}
              defaultValue={item?.description}
              ref={register}
            />
          </div>
        </div>     
        <div className="d-flex mt-3">
          <button
            type="button"
            className="orange-outline-button ml-auto mr-2"
            onClick={hideModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="orange-button mr-2"
          >
            Save
          </button>
        </div>
      </form>     
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
    </Modal>
  );
};
export default InterviewLocationModal;
