import React from 'react';
import Modal from 'core/components/modals/Modal';
import { toReadableDate } from 'utilities/utilities';

interface Props {
  hide: () => void;
  dateOfApplication: string;
  status: string;
  positionAppliedFor: string;
}

const ApplicationHistoryModal = ({
  hide,
  dateOfApplication,
  positionAppliedFor,
  status,
}: Props) => {
  const onHide = () => {
    hide();
  };
  return (
    <Modal
      centered
      show
      onHide={onHide}
      title="Application Details"
      size="lg"
    >
      <table>
        <tbody>
          <tr>
            <th
              style={{
                paddingRight: '3rem',
                cursor: 'pointer',
              }}
              className="py-1"
            >
              Date Of Application
            </th>
            <th
              style={{
                paddingRight: '3rem',
                cursor: 'pointer',
              }}
              className="py-1"
            >
              Status
            </th>
            <th
              style={{
                paddingRight: '3rem',
                cursor: 'pointer',
              }}
              className="py-1"
            >
              Position
            </th>
          </tr>
          <tr key={dateOfApplication}>
            <td
              style={{ paddingRight: '3rem' }}
              className="py-1"
            >
              {toReadableDate(dateOfApplication)}
            </td>
            <td
              style={{ paddingRight: '3rem' }}
              className="py-1"
            >
              {status}
            </td>
            <td
              style={{ paddingRight: '3rem' }}
              className="py-1"
            >
              {positionAppliedFor}
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default ApplicationHistoryModal;
