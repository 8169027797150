import { createSelector } from 'reselect';
import { convToFormObject } from '../../../utilities/classUtils';
import { EmployeePayRate, EmployeePayRateScreen, FuturePayRate, HourlySalaryAnnualPayRates, PayRate } from '../../models';
import { payRateState } from '../reducers';
import { State as PayRateState } from '../reducers/pay-rate.reducer';

export const getPayRates = createSelector(
  payRateState,
  (state: PayRateState) => {
    if (!state.payRates) return [];

    return state.payRates.map((pr: PayRate) => {return convToFormObject(pr, PayRate.convProps);}) as PayRate[];
  },
);

export const getEmployeePayRatesScreen = createSelector(
  payRateState,
  (state: PayRateState) => {
    if (!state.employeePayRateScreen) return null;

    return state.employeePayRateScreen as EmployeePayRateScreen;
  },
);

export const getPayRatesHistory = createSelector(
  payRateState,
  (state: PayRateState) => {
    if (!state.payRatesHistory) return [];

    // return state.payRatesHistory.map((prh: PayRateHistory) =>
    // 	convToFormObject(prh, PayRateHistory.convProps)) as PayRateHistory[];
    return state.payRatesHistory.map(h => {return { ...h };})
      .sort((a, b) => {
        const x = typeof a.effectiveDate === 'string' ? new Date(a.effectiveDate).getTime() : 0;
        const y = typeof b.effectiveDate === 'string' ? new Date(b.effectiveDate).getTime() : 0;
        return y - x;
      });
  },
);

export const getFuturePayRates = createSelector(
  payRateState,
  (state: PayRateState) => {
    if (!state.futurePayRates) return [];

    return state.futurePayRates.map((fpr: FuturePayRate) => {return convToFormObject(fpr, FuturePayRate.convProps);})
      .sort((a, b) => {return b.effectiveDate - a.effectiveDate;}) as FuturePayRate[];
  },
);

export const getCalculatedPayRates = createSelector(
  payRateState,
  (state: PayRateState) => {
    if (!state.payRates) return null;
    if (state.calculatePayRates)
    return state?.calculatePayRates as HourlySalaryAnnualPayRates;
  },
);