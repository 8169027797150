import React, { useEffect, useState, ChangeEvent, useRef, useMemo, CSSProperties } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import {
  CellFocusedEvent, 
  ColDef, 
  ColumnApi, 
  FirstDataRenderedEvent,
  GridApi, 
  GridOptions, 
  GridReadyEvent, 
  GridSizeChangedEvent, 
  ValueGetterParams, 
} from '@ag-grid-enterprise/all-modules';
import { SelectGrp } from 'core/components/form-controls';
import { RadioGrp, RadioOptions } from 'core/components/form-controls/RadioGrp';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { AllocationEmployee, Payroll } from 'core/models';
import { getDeptAllocationCorrections, storeReportRequestData } from 'core/store/actions';
import { getClient, getProcessedPayrolls } from 'core/store/selectors';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector, useEffectOnMount } from 'utilities/hooks';
import { compareLastName, compareEmpNo } from '../utilities';
import InputDateSelector from 'core/components/form-controls/InputDateSelector';
import { convToDateString } from 'utilities/utilities';

type Option<T> = { id: T, description: string };
type Props = {
  selectedEmp: AllocationEmployee | undefined;
  protectedEmpNo: string | undefined;
  setEmp: React.Dispatch<React.SetStateAction<AllocationEmployee | undefined>>;
};

const payrollDropdownGroupStyle: CSSProperties = {
  minWidth: '40%',
};

const columns: ColDef[] = [
  {
    field: 'protectedEmpNo',
    hide: true,
    // done to ignore hidden columns in quick filter
    getQuickFilterText: () => {return '';}, 
  },
  {
    field: 'empNo',
    headerName: 'Emp #',
    sortable: false,
    width: 80,
  },
  {
    headerName: 'Name',
    sortable: false,
    valueGetter: (params: ValueGetterParams) => {
      const { data }: { data: AllocationEmployee } = params;
      return `${data.lastName}, ${data.firstName} ${data.middleName}`;
    },
  },
];

const gridOptions: GridOptions = {
  columnDefs: columns,
  suppressRowClickSelection: true,
  rowSelection: 'single',
  domLayout: 'autoHeight',
  defaultColDef: {
    autoHeight: true,
    suppressMenu: true,
    resizable: true,
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
};

const employeeFilterOptions: Option<string>[] = [
  { id: 'all',
    description: 'All Employees' },
  { id: 'active',
    description: 'Active Employees' },
  { id: 'allocated',
    description: 'Allocated Employees' },
  { id: 'unallocated',
    description: 'Unallocated Employees' },
  { id: 'terminated',
    description: 'Terminated Employees' },
];

const fs: FieldInputSettings = {
  payrollDate: {
    name: 'payrollDate',
    label: 'Selected payroll',
    groupClass: 'gc10 w-50',
  },
  employeeFilter: {
    name: 'employeeFilter',
    label: 'Show',
    groupClass: 'gc10 w-50',
  },
  sortBy: {
    name: 'dateType',
    groupClass: 'gc12',
  },
};

const sortByOptions: RadioOptions[] = [
  {
    value: 'lastName',
    label: 'Last Name',
  },
  {
    value: 'empNo',
    label: 'Employee #',
  },
];

const FilteredEmployeeList = ({ selectedEmp, setEmp }: Props) => {
  const allocationEmployees = useAppSelector((state) => { return state.corrections.employees; });
  const masterEmployees = useAppSelector((state) => {return state.employees.employees;});
  const processedPayrolls = useSelector(getProcessedPayrolls);
  
  const [rowData, setRowData] = useState<AllocationEmployee[]>([]);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const [sortBy, setSortBy] = useState('lastName');
  const [dateRange, setDateRange] = useState<string[]>();
  const [dateRangeOptions, setDateRangeOptions] = useState<Option<number>[]>();
  const [selectedPayroll, setSelectedPayroll] = useState<Payroll>();

  const { register, errors } = useForm();
  
  const dispatch = useAppDispatch();
  
  const selectedRowIndexRef = useRef<number>(-1);
  
  const protectedEmpNo = useMemo(() => {
    const match = masterEmployees.find((employee) => {return employee.empNo === selectedEmp?.empNo;});
    
    return match?.protectedEmpNo;
  }, [selectedEmp?.empNo]);
  
  const sortData = (data: AllocationEmployee[]) => {
    const temp = structuredClone(data);
    
    if (!temp) return;
    if (sortBy === 'lastName') {
      temp.sort(compareLastName);
    } else {
      temp.sort(compareEmpNo);
    }
    
    setRowData(temp);
  };
  
  useEffect(() => {
    sortData(allocationEmployees);
  }, [allocationEmployees?.length]);
  
  useEffectOnMount(() => {
    sortData(rowData);
  }, [sortBy]);
  
  useEffect(() => {
    if (processedPayrolls.length && dateRange?.[0] && dateRange?.[1]) {
      const payrollOptions = processedPayrolls.filter((payroll) => {
        return new Date(payroll.weekEnd).toISOString() >= new Date(dateRange[0]).toISOString()
          && new Date(payroll.checkDate).toISOString() <= new Date(dateRange[1]).toISOString();
      });
      const payrollsMappedToList = payrollOptions.map((payroll) => {
        return {
          id: payroll.payrollHistoryId,
          description:
            convToDateString(new Date(payroll.weekEnd)) +
            ' - ' +
            convToDateString(new Date(payroll.checkDate)),
        };
      });
      
      setSelectedPayroll(payrollOptions?.[0]);
      setDateRangeOptions(payrollsMappedToList);
    }
  }, [processedPayrolls, dateRange]);
  
  useEffect(() => {
    if (!(selectedEmp && (dateRange?.length || processedPayrolls?.length) && protectedEmpNo && processedPayrolls?.[0])) return;
    dispatch(storeReportRequestData({
      beginDate: selectedPayroll?.weekEnd ?? processedPayrolls?.[0].weekEnd ?? '',
      endDate: selectedPayroll?.checkDate ?? processedPayrolls?.[0].checkDate ?? '',
      clientNo: selectedPayroll?.clientNo ?? processedPayrolls?.[0].clientNo ?? 0,
      selectedEmpNo: selectedEmp.empNo,
      reportType: 'pdf',
    }));
    dispatch(getDeptAllocationCorrections({
      empNo: protectedEmpNo,
      weekEnd: selectedPayroll?.weekEnd ?? processedPayrolls[0].weekEnd,
      checkDate: selectedPayroll?.checkDate ?? processedPayrolls[0].checkDate,
    }));
    
    return () => {
      dispatch(storeReportRequestData({
        beginDate: '',
        endDate: '',
        clientNo: -1,
        selectedEmpNo: -1,
        reportType: 'pdf',
      }));
    };
  }, [selectedEmp, selectedPayroll, processedPayrolls, protectedEmpNo]);
  
  const onPayrollDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const matchedPayroll = processedPayrolls?.find((payroll) => {
      return payroll.payrollHistoryId === parseInt(e.target.value);
    });
    setSelectedPayroll(matchedPayroll);
  };
  
  const onEmployeeFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    let result: AllocationEmployee[];
    
    switch (e.target.value) {
      case 'active':
        result = [...allocationEmployees].filter((emp) => {return !emp.isTerminated;});
        break;
      case 'terminated':
        result = [...allocationEmployees].filter((emp) => {return emp.isTerminated;});
        break;
      case 'allocated':
        result = [...allocationEmployees].filter((emp) => {return emp.hasAllocations;});
        break;
      case 'unallocated':
        result = [...allocationEmployees].filter((emp) => {return !emp.hasAllocations;});
        break;
      default:
        result = [...allocationEmployees];
        break;
    }
    setRowData((_) => {
      return result;
    });
    sortData(result);
  };
  
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };
  
  const onFirstDataRendered = (e: FirstDataRenderedEvent) => {
    const firstRow = e.api.getDisplayedRowAtIndex(0);
    
    if (!firstRow) return;

    const { data: selectedEmployee }: { data: AllocationEmployee } = firstRow;
    firstRow.setSelected(true, true);
    setEmp(selectedEmployee);
  };
  
  const onGridSizeChanged = (e: GridSizeChangedEvent) => {
    e.api.sizeColumnsToFit();
  };
  
  const onCellFocused = (e: CellFocusedEvent) => {
    if (!gridApi) return;
    selectedRowIndexRef.current = e.rowIndex ?? 0;
    const selectedRow = gridApi.getDisplayedRowAtIndex(selectedRowIndexRef.current);
      
    if (!selectedRow) return;
    const { data: selectedEmployee }: { data: AllocationEmployee } = selectedRow;
    e.api.deselectAll(); // better way to do this? --> felix from the future says no
    selectedRow.setSelected(true, true);
    setEmp(selectedEmployee);
  };
  
  const onSearch = (query: string) => {
    if (!gridApi) return;
    gridApi.setQuickFilter(query);
  };
  
  return (
    <>
      <div className="border p-1 filter-list-wrapper top-filter-wrapper">
        <div className="w-100 d-flex flex-wrap ">
          <div className="mt-1 mr-2">            
            <InputDateSelector
              dateTitleOverwrite="Select payroll date range"
              hideDateTypes={true}
              returnDates={(dates: string[]) => {return setDateRange(dates);}}
            />
          </div>
          <SelectGrp
            {...fs.payrollDate}
            groupClass="groupClassAuto"
            groupStyle={payrollDropdownGroupStyle}
            errors={errors.payrollDate}
            ref={register()}
            options={dateRangeOptions ?? []}
            onChange={onPayrollDateChange}
          />
        </div>
      </div>
      <div className="shadow border p-1 filter-list-wrapper">
        <div className="w-100 d-flex flex-column">
          <div className="w-100 d-flex flex-wrap">
            <SelectGrp
              {...fs.employeeFilter}
              errors={errors.employeeFilter}
              ref={register()}
              options={employeeFilterOptions}
              onChange={onEmployeeFilterChange}
            />
            <div className="d-flex flex-column mt-1">
              <div className="dm-form-label">Sort by:</div>
              <RadioGrp
                {...fs.sortBy}
                controlled
                ref={register}
                checked={sortBy}
                radioOptions={sortByOptions}
                onChange={(
                  e: ChangeEvent<HTMLInputElement>,
                ) => {
                  if (!columnApi) return;
                  setSortBy(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-100 d-flex flex-column">
            <div className="dm-form-label">Search</div>
            <input
              type="text"
              className="form-control w-50 mb-2"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                return onSearch(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="grid-wrapper">
        <div className="table-wrapper-wrapper ag-theme-balham">
          <AgGridReact
            gridOptions={gridOptions}
            rowData={rowData}
            onGridReady={onGridReady}
            onGridSizeChanged={onGridSizeChanged}
            onCellFocused={onCellFocused}
            onFirstDataRendered={onFirstDataRendered}
          />
        </div>
      </div>
    </>
  );
};

export default FilteredEmployeeList;