import { createSelector } from 'reselect';
import { NestedAccordionItem } from '../../components/form-controls/nested-accordion/NestedAccordionItem';
import { documentState } from '../reducers';
import { State as DocumentState } from '../reducers/document.reducer';
import { EmployeeFile, W4File } from 'core/models';
import { groupByField } from 'utilities/utilities';

export const getTaxDocuments = createSelector(
  documentState,
  (state: DocumentState) => {return state.taxDocuments;},
);

export const getTaxDocumentsForAccordion = createSelector(
  documentState,
  (state: DocumentState) => {
    return [
      topLevelFileMap(state.taxDocuments.i9Files, 'i9FileId', 'I9'),
      topLevelFileMap(state.taxDocuments.w2Files, 'myInfoEmpDocId', 'W2'),
      nestedFileMap<W4File>(state.taxDocuments.w4Files, 'employeeTaxFileId', 'W4'),
    ];
  },
);

export const getTaxDocumentPDF = createSelector(
  documentState,
  (state: DocumentState) => {
    if (!state?.taxDocumentPDF) return null;

    const binary_string = atob(state.taxDocumentPDF.data);
    return { ...state.taxDocumentPDF,
      bdata: binary_string };
  },
);

export const getEmpDocuments = createSelector(
  documentState,
  (state: DocumentState) => {return state.empDocuments;},
);

export const empDocumentsForAccordion = createSelector(
  documentState,
  (state: DocumentState) => {
    return [
      topLevelFileMap(state.empDocuments.i9Files, 'employeeFileId', 'I9'),
      convertTax(state.empDocuments.onboardFiles, 'employeeFileId', 'name', 'Onboard'),
      convertTax(state.empDocuments.directDepositForms, 'directDepositFormID', 'name', 'Direct Deposit'),
      employeeFileMap(state.empDocuments.employeeFiles, 'Hire To Retire'),
    ];
  },
);

export const getEmpDocumentPDF = createSelector(
  documentState,
  (state: DocumentState) => {
    if (!state?.empDocumentPDF) return null;

    const binary_string = atob(state.empDocumentPDF.data);
    return { ...state.empDocumentPDF,
      bdata: binary_string };
  },
);

const months: { [key: number]: string } = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

const entityTypes: { [key: string]: string } = {
  'FED': 'Federal',
  'STA': 'State',
  'CTY': 'Local',
};

function employeeFileMap(empFiles: EmployeeFile[], title: string): NestedAccordionItem {
  const result: NestedAccordionItem = {
    id: title,
    title,
    children: [],
  };
  if (!empFiles?.length) return result;
  
  const clone = structuredClone(empFiles)?.filter((file) => !!file.clientFileId);
  const groupedFiles = groupByField(clone, 'fileCategoryId');

  for (const fileCategoryId of Object.keys(groupedFiles)) {
    if (['4', '2', '11'].includes(fileCategoryId)) continue; // skip I9/onboarding/DD here
    
    const folder = groupedFiles[fileCategoryId];
    const mappedFolder: NestedAccordionItem[] = folder?.map((file) => ({
      id: `${file.employeeFileId}`,
      title: file.name,
      data: file,
    }));
    
    result.children?.push({
      id: fileCategoryId,
      title: folder?.[0]?.category ?? '',
      children: mappedFolder,
    });
  }

  return result;
}

// TODO: just make these three a single function with more args/callbacks
function topLevelFileMap(taxFiles: { [key: string]: any }[], idField: string, title: string): NestedAccordionItem {
  const result: NestedAccordionItem = {
    id: title,
    title,
    children: [],
  };
  if (!taxFiles?.length) return result;
  
  const clone = structuredClone(taxFiles);
  clone.sort().reverse(); // warning: this assumes these files will always start with year...
  clone.forEach((taxFile) => {
    result.children?.push({
      title: taxFile.name,
      id: `D${taxFile[idField]}`,
      data: taxFile,
    });
  });
  
  return result;
}

type NestedFileMapGeneric = { entityType: string, name: string, year: number, entityCodeDesc: string };

function nestedFileMap<T extends NestedFileMapGeneric>(taxFiles: T[], idField: keyof T, title: string): NestedAccordionItem {
  const result: NestedAccordionItem = {
    id: title,
    title,
  };
  
  if (!taxFiles?.length) return result;
  
  taxFiles.forEach((taxFile: T) => {
    const entityType = entityTypes[taxFile.entityType];
    const newChild = {
      title: entityType,
      id: `M${taxFile[idField]}`,
      children: [{
        title: taxFile.name,
        id: `D${taxFile[idField]}`,
        data: taxFile,
      }],
    };
    
    if (result.children) {
      const entityTypeMatch = result.children.find((item: NestedAccordionItem) => {
        return item.title === entityType;
      });
      if (entityTypeMatch) {
        entityTypeMatch?.children ?? (entityTypeMatch.children = []);
        entityTypeMatch.children.push({
          title: taxFile.name,
          id: `D${taxFile[idField]}`,
          data: taxFile,
        });        
      } else {
        result.children.push(newChild);
      }
    } else {
      result.children = [newChild];
    }
  });
  
  if (!result?.children) return result;
  
  // sort ascending by entity code, then descending by year
  result.children.forEach((child) => {
    child?.children
      ?.sort((a: NestedAccordionItem, b: NestedAccordionItem) => {
        const { data: { entityCodeDesc: aEntity, year: aYear } } = a;
        const { data: { entityCodeDesc: bEntity, year: bYear } } = b;
      
        return bEntity.localeCompare(aEntity) || aYear - bYear;
      })
      ?.reverse();
  });
  
  // return in order of fed, state, local
  return {
    ...result,
    children: result.children?.reverse(),
  };
}

const convertTax = (taxFiles: { [key: string]: any }[], idField: string, nameField: string, title: string): NestedAccordionItem => {
  // create top-level folder
  const result: NestedAccordionItem = {
    id: title,
    title,
  };
  
  // if no files, just return the empty folder
  if (!taxFiles?.length) return result;
  
  taxFiles.forEach((w2: { [key: string]: any }) => {
    const date = w2.modifyDate ? new Date(w2.modifyDate) : new Date();
    // will be used for the title/grouping, i.e. "August 2024"
    const yearMonth = `${date.getFullYear()} ${months[date.getMonth()]}`;
    
    if (result.children) {
      // attempt to find folder of file's modifyDate month
      const monthYearMatch = result.children.find((z: NestedAccordionItem) => z.title === yearMonth);
      // we have a year,month folder
      if (monthYearMatch) {
        // create children of year,month folder if it does not exist already 
        monthYearMatch.children ?? (monthYearMatch.children = []);
        // put the file into this year,month
        monthYearMatch.children.push({ title: w2[nameField],
          id: 'D' + w2[idField],
          data: w2 });
        // we don't have a year,month folder yet. Create it and add this file as the first child
        /* unshift places at beginning of array. Doing this because these files come across in ASC order by date, so 
        this way they'll be DESC. */
      } else {
        result.children.unshift({
          title: yearMonth,
          id: 'M' + w2[idField],
          data: w2,
          children: [{
            title: w2[nameField],
            id: 'D' + w2[idField],
            data: w2,
          }],
        });
      }
    } else {
      // no children on top-level folder, so create it and put current file on before next file
      result.children = [{
        title: yearMonth,
        id: 'M' + w2[idField],
        data: w2,
        children: [{
          title: w2[nameField],
          id: 'D' + w2[idField],
          data: w2,
        }],
      }];
    }
  });

  return result;
};