import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { CheckboxGrpInLine } from '../../core/components/form-controls';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import { Client, Dropdown } from '../../core/models';
import { ScheduledDeductionsReportRequest } from '../../core/models/PayrollReports.model';
import { clearScheduledDeductionsReport, downloadScheduledDeductionsReport } from '../../core/store/actions/payoll-reports.action';
import { getScheduledDeductionsReport } from '../../core/store/selectors/payroll-reports.selector';
import { useAppSelector } from '../../utilities/hooks';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi, GridOptions, RowSelectedEvent } from '@ag-grid-enterprise/all-modules';
import { CommonService } from 'core/services';
import Icon from 'core/components/shared/Icon';



const ScheduledDeductionsReport = () => {
  const dispatch = useDispatch();

  const client = useAppSelector((state) => { return state.client.client; }) as Client;
  const report = useSelector(getScheduledDeductionsReport);
  const [fileType, setFileType] = useState<string>('pdf');

  const deductionCodes = useAppSelector((state) => { return state.dropdown.deductionCode; });
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [selectedCodes, setSelectedCodes] = useState<Dropdown[]>();

  const { register, handleSubmit, reset } =
    useForm<ScheduledDeductionsReportRequest>();

  const columns: ColDef[] = [
    {
      field: 'id',
      headerName: 'Code',
      width: 60,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 100,
      maxWidth: 100,
    },
    {
      field: 'select',
      headerName: 'Select',
      editable: false,
      checkboxSelection: true,
      width: 100,
      maxWidth: 70,
    },
  ];

  const gridOptions: GridOptions = {
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    columnDefs: columns,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (report && fileType === 'excel') {
      report &&
        CommonService.downloadBase64File(
          'application/excel',
          report,
          'ScheduledDeductionReport.xlsx',
        );
      dispatch(clearScheduledDeductionsReport());
    }
  }, [report]);


  const downloadReport = (data: ScheduledDeductionsReportRequest) => {
    const submitData: ScheduledDeductionsReportRequest = {
      clientNo: client.clientNo,
      deductionNumbers: selectedCodes?.map(c => { return +c.id; }) || [],
      activeEmpsOnly: data.activeEmpsOnly,
      showDetail: data.showDetail,
      reportType: fileType,
    };
    dispatch(downloadScheduledDeductionsReport(submitData));
  };


  const hidePdfViewer = () => {
    dispatch(clearScheduledDeductionsReport());
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      selectedRows && setSelectedCodes(selectedRows);
    }
  };

  const onRowClicked = (e: RowSelectedEvent) => {
    e && e.node.setSelected(e.node.isSelected() === true ? true : false);
  };

  const onCancel = () => {
    reset();
    gridApi?.deselectAll();
  };

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">Scheduled Deductions Report</div>
          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title"
              disabled={!selectedCodes?.length}
              onClick={() => { return setFileType('excel'); }}
            >
              Download Report{' '}
              <Icon name="download"
                className="fa-download" />
            </button>
          </div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div className="d-flex">
              <CheckboxGrpInLine
                name="activeEmpsOnly"
                ref={register}
                labelFirst={false}
                label="Active Employee Only"
              />
              <CheckboxGrpInLine style={{ marginLeft: '20px' }}
                name="showDetail"
                ref={register}
                labelFirst={false}
                label="Show Detail With Scheduled Frequency"

              />
            </div>
            <div className="flex-grow-1 text-right">
              <button
                type="button"
                className="orange-outline-button mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="orange-button mr-2"
                onClick={() => { return setFileType('pdf'); }}
                disabled={!selectedCodes?.length}
              >
                Run Report
              </button>
            </div>
          </div>
          <div className="ag-theme-balham mt-2"
            style={{
              height: '220px',
              width: '250px'
            }}>
            <AgGridReact
              gridOptions={gridOptions}
              rowData={deductionCodes}
              onGridReady={onGridReady}
              onRowClicked={onRowClicked}
              onSelectionChanged={onSelectionChanged}
            />
          </div>
        </form>

      </div>
      {
        report ? <PDFViewerModal
          show={report.length > 0}
          pdfData={report}
          onHide={hidePdfViewer}
          reportName="SchuduleDeductionsReport"
        /> : null
      }
    </div >
  );
};

export default ScheduledDeductionsReport;