import React, { CSSProperties, useCallback } from 'react';
import { Labels } from '.';
import styles from './form-controls.module.scss';
import { cleanAmount, formatWithCommas } from 'utilities/utilities';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import Icon from '../shared/Icon';

const toolTipStyles: CSSProperties = { fontSize: '0.75rem' };

interface PropTypes<T = React.ChangeEvent<HTMLInputElement>> {
  id?: string;
  name: string;
  label?: string | string[];
  tallLabel?: boolean;
  labelClass?: string;
  groupClass?: string;
  inputClass?: string;
  groupStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  placeholder?: string;
  type?: string;
  step?: string;
  maxLength?: number;
  errors?: any;
  required?: boolean;
  disabled?: boolean;
  errorMsg?: string;
  readOnly?: boolean;
  visible?: boolean;
  formatNumber?: boolean;
  defaultValue?: any;
  setValue?: any;
  hiddenRef?: any;
  hiddenValue?: any;
  title?: string;
  places?: number;
  detailMessage?: string;
  onChange?: (e: T) => void;
  onFocus?: (e?: React.FocusEvent<HTMLInputElement, Element>) => void;
  onBlur?: (e?: React.FocusEvent<HTMLInputElement, Element>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  validateAndSave?: () => void;
}
/**
 * @property {string} id?
 * @property {string} name
 * @property {string | string[]} label?
 * @property {boolean} tallLabel?
 * @property {string} groupClass?
 * @property {object} groupStyle?
 * @property {string} placeholder?
 * @property {sting} type?
 * @property {sting} step?
 * @property {any} errors?
 * @property {boolean} required?
 * @property {() => void} onChange?
 * @property {boolean} disabled?
 * @property {string} errorMsg?
 * @property {boolean} readOnly?
 */
export const InputGrp: React.FC<any> = React.forwardRef(
  (
    {
      id,
      label,
      tallLabel = false,
      labelClass,
      groupClass,
      inputClass,
      groupStyle,
      inputStyle,
      placeholder,
      required = false,
      disabled = false,
      errors,
      type,
      step,
      maxLength,
      errorMsg,
      readOnly = false,
      visible = true,
      defaultValue,
      onChange,
      formatNumber,
      setValue,
      hiddenRef,
      hiddenValue,
      detailMessage,
      places = 2,
      ...extraProps
    }: PropTypes,
    ref: any,
  ) => {
    const groupClass2 = styles['dm-form-group'] + ' ' + (groupClass ?? '');
    id = id ?? extraProps.name;
    const errMsg = errors
      ? errors.type === 'validate' && !errors.message
        ? errorMsg
        : errors.message
      : '';
          
    const formatNumberOnBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      const formatResult = Number(cleanAmount(e.target.value));
      setValue?.(extraProps.name, formatResult);
      e.target.value = formatWithCommas(e.target.value, places);
      extraProps?.onBlur?.();
      extraProps?.validateAndSave?.();
    };
    
    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (setValue && formatNumber) setValue(extraProps.name, Number(cleanAmount(e.target.value)));
      onChange?.(e);
    };
      
    const renderToolTip = useCallback((props: OverlayInjectedProps) => {
      return (
        <Tooltip
          id="info-tooltip"
          {...props}
        >
          <div style={toolTipStyles}>
            {detailMessage}
          </div>
        </Tooltip>
      );
    }, [detailMessage]);
    
    return (
      <>
        {visible ? (
          <div
            className={groupClass2}
            style={groupStyle}
          >
            <div className="d-flex">
              <Labels
                label={label}
                tallLabel={tallLabel}
                labelClass={labelClass}
                id={id}
                hasError={!!errors}
                required={required}
              />
              {detailMessage ? (
                <OverlayTrigger
                  placement="top"
                  overlay={renderToolTip}
                >
                  <div className="d-flex align-items-center m-0 ml-1">
                    <Icon
                      name="circle-info"
                      fontSize={'0.75rem'}
                    />
                  </div>
                </OverlayTrigger>
              ) : null}
            </div>
            <input
              className={`${inputClass} ${styles['dm-form-control']} ${
                errors ? ' ' + styles['dm-is-invalid'] : ''
              }`.trim()}
              style={inputStyle}
              ref={ref}
              id={id}
              type={type}
              step={step}
              disabled={disabled}
              required={required}
              onChange={onInputChange}
              readOnly={readOnly}
              placeholder={placeholder}
              maxLength={maxLength}
              defaultValue={defaultValue}
              onBlur={formatNumber ? formatNumberOnBlur : extraProps.onBlur }
              {...extraProps}
            />
            <small className="text-danger">{errMsg}</small>
            {formatNumber && hiddenRef && ![null, undefined].includes(hiddenValue) ? (
              <input
                name={extraProps.name}
                type="hidden"
                ref={hiddenRef}
                value={cleanAmount(String(defaultValue))}
                disabled={disabled}
                onKeyDown={extraProps?.onKeyDown}
              />
            ) : null}
          </div>
        ) : null}
      </>
    );
  },
);

InputGrp.displayName = 'InputGrp';