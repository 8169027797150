import { createAction } from '@reduxjs/toolkit';
import { RealignSupervisedEmployee, SupervisedEmployee } from '../../models';

export const loadSupervisorEmployees = createAction<{ empNo: string }>(
  '[supervisorEmployees] LOAD_SUPERVISOR_EMPLOYEES',
);
export const updateSupervisorEmployees = createAction<RealignSupervisedEmployee>('[supervisorEmployees] UPDATE_SUPERVISOR_EMPLOYEES');
export const storeSupervisorEmployees = createAction<SupervisedEmployee[]>(
  '[supervisorEmployees] STORE_SUPERVISOR_EMPLOYEES',
);

export const reviseSupervisorEmployees = createAction<SupervisedEmployee[]>('[supervisorEmployees] REVISE_SUPERVISOR_EMPLOYEES');

export const cleanupSupervisorEmployees = createAction('[supervisorEmployees] CLEANUP_SUPERVISOR_EMPLOYEES');
