import React, { useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, ColDef, GridApi, GridOptions, GridReadyEvent, GridSizeChangedEvent } from '@ag-grid-enterprise/all-modules';
import { Col, Row } from 'react-bootstrap';
import { Client } from 'core/models';

const columns: ColDef[] = [{
  field: 'select',
  headerName: 'SELECT',
  checkboxSelection: true,
  width: 80,
}, {
  field: 'clientNo',
  headerName: 'CLIENT #',
  sortable: true,
  width: 80,
}, {
  field: 'clientName',
  headerName: 'CLIENT Name',
  sortable: true,
  minWidth: 140,
}];

const gridOptions: GridOptions = {
  columnDefs: columns,
  defaultColDef: {
    suppressMenu: true,
    resizable: true,
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
};

type RelatedClientsProps = {
  clients: Client[];
  setSelectedClient: React.Dispatch<React.SetStateAction<Client | null>>;
};

const RelatedClients = React.forwardRef<RelatedClientsProps, any>((props: RelatedClientsProps, ref: any) => {
  const { clients, setSelectedClient } = props;
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };
  
  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit();
  };

  const onSelectionChanged = () => {
    let selectedRows;

    if (gridApi) {
      selectedRows = gridApi.getSelectedRows();
      if (!selectedRows?.length) return;
      setSelectedClient(selectedRows[0]);
    }
  };

  return (
    <div className="dm-panel dm-panel-border">
      <div className="dm-grid-title">Related Clients</div>
      <div> Select client to transfer employee to</div>
      <Row>
        <Col
          xs={12}
          className="table-wrapper-wrapper ag-theme-balham"
          style={{ height: '400px' }}
        >
          <AgGridReact
            ref={ref}
            gridOptions={gridOptions}
            rowData={clients}
            modules={AllModules}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            onGridSizeChanged={onGridSizeChanged}
          />
        </Col>
      </Row>
    </div>
  );
});

RelatedClients.displayName = 'RelatedClients';

export default RelatedClients;