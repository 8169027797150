import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { InputGrpInLine } from '..';
import { SubDepartment } from '../../../models';
import {
  createSubdepartment,
  updateSubdepartment,
} from '../../../store/actions';
import { getClient } from '../../../store/selectors';
import { FieldInputSettings } from '../types';

const fs: FieldInputSettings = {
  subDeptCode: {
    name: 'subDeptCode',
    label: 'Sub Dept Code',
    labelWidth: 25,
    type: 'number',
    required: true,
  },
  subDeptDesc: {
    name: 'subDeptDesc',
    label: 'Description',
    labelWidth: 25,
    required: true,
  },
  glAccount: { name: 'glAccount', label: 'GL Account', labelWidth: 25 },
  alphaCode: { name: 'alphaCode', label: 'Alpha Code', labelWidth: 25 },
  quickenClass: {
    name: 'quickenClass',
    label: 'Quickbooks Class',
    labelWidth: 25,
  },
};

type PropTypes = {
  item: any;
  options: any[];
  onHide: any;
};

const SubDepartmentOptionForm: React.FC<PropTypes> = ({
  item,
  options,
  onHide,
}) => {
  const dispatch = useDispatch();
  const currentClient = useSelector(getClient);
  const { register, errors, handleSubmit, setError, clearErrors } =
        useForm<SubDepartment>({
          defaultValues: item
            ? { ...item }
            : new SubDepartment(
              currentClient!.clientNo,
              currentClient!.clientID,
            ),
        });

  const onSubDeptCodeChange = (e: any) => {
    options.find((l: SubDepartment) => {return l.subDeptCode === +e.target.value;})
      ? setError('subDeptCode', {
        type: 'BadSubDeptCode',
        message: 'Sub Department Code already exists.',
      })
      : clearErrors('subDeptCode');
  };

  const onSubmit = (data: any) => {
    const updatedSubDept = { ...item, ...data } as SubDepartment;
    updatedSubDept.subDeptCode = parseInt(data.subDeptCode);
    updatedSubDept.deptID = parseInt(data.deptID);
    if (updatedSubDept.subDeptID) {
      dispatch(updateSubdepartment(updatedSubDept));
    } else {
      updatedSubDept.clientNo = currentClient!.clientNo;
      updatedSubDept.clientID = currentClient!.clientID;
      updatedSubDept.deptID = 0;
      updatedSubDept.subDeptID = 0;
      dispatch(createSubdepartment(updatedSubDept));
    }
    onHide(false);
  };

  return (
    <div className="dm-panel dm-panel-border mx-4 d-flex flex-column flex-nowrap">
      <div className="dm-grid-action-title">Add/Edit</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="hidden"
          name="deptID"
          ref={register({ valueAsNumber: true })}
        />
        <InputGrpInLine
          {...fs.subDeptCode}
          errors={errors.subDeptCode}
          onChange={onSubDeptCodeChange}
          ref={register}
        />
        <InputGrpInLine
          {...fs.subDeptDesc}
          errors={errors.subDeptDesc}
          ref={register({ required: 'Description is Required' })}
        />
        <InputGrpInLine
          {...fs.glAccount}
          errors={errors.glAccount}
          ref={register}
        />
        <InputGrpInLine
          {...fs.alphaCode}
          errors={errors.alphaCode}
          ref={register}
        />
        <InputGrpInLine
          {...fs.quickenClass}
          errors={errors.quickenClass}
          ref={register}
        />

        <div style={{ display: 'flex' }}>
          <button
            type="button"
            className="btn btn-primary orange-outline-button ml-auto mr-2"
            onClick={() => {return onHide(false);}}
          >
            Cancel
          </button>
          <button type="submit" className="orange-button mr-2">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default SubDepartmentOptionForm;
