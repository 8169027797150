
import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpResponse } from '../../models';
import { Payroll401kReportRequest, Payroll4070TipReportRequest, CashFlowReportRequest, AnnualFICATipCreditReportRequest, PensionReportRequest, DeductionOptionsReportRequest, MemoCashFlowReportRequest, EmployeeLastCheckReportRequest, DeductionShortagesReportRequest, GrossToNetRequest, MonthlyCashFlowAnalysisDownloadReport, CARESCreditReportRequest, PensionSetting, ScheduledDeductionsReportRequest, TaxReportRequest, UnionDuesReportRequest, UnionDuesSettingsRequest, WorkersCompensationReportRequest } from '../../models/PayrollReports.model';
import { PayrollReportsService } from '../../services/payroll-reports.service';
import {
  createPensionSettings,
  deletePensionSettings,
  handleError, handleSuccess,
  handleWarning,
  loadPensionSettings,
  loadUnionDuesSettings,
  storePensionSettings,
} from '../actions';
import {
  downloadPayroll401kReport,
  storePayroll401kReport,
  downloadPayroll4070TipReport,
  storePayroll4070TipReport,
  downloadAnnualFICATipCreditReport,
  storeAnnualFICATipCreditReport,
  downloadCashFlowReport,
  storeCashFlowReport,
  downloadPensionReport,
  storePensionReport,
  updatePensionSettings,
  storeScheduledDeductionsReport,
  downloadScheduledDeductionsReport,
  storeWorkersCompensationReport,
  downloadWorkersCompensationReport,
  storeDeductionOptionsReport,
  downloadDeductionOptionsReport,
  downloadTaxReport,
  storeTaxReport,
  storeMemoCashFlowReport,
  downloadMemoCashFlowReport,
  downloadEmployeeLastCheckReport,
  storeEmployeeLastCheckReport,
  downloadDeductionShortagesReport,
  storeDeductionShortagesReport,
  storeGrossToNetReport,
  loadGrossToNetReport,
  createUnionDuesSettings,
  deleteUnionDuesSettings,
  downloadUnionDuesReport,
  storeUnionDuesReport,
  storeUnionDuesSettings,
  updateUnionDuesSettings,
  storeMontlyCashFlowAnalysisDownloadReport,
  downloadMontlyCashFlowAnalysisDownloadReport,
  downloadCARESCreditReport,
  storeCARESCreditReport,
  toggleRunningUnionDues,
} from '../actions/payoll-reports.action';
interface Actions<Type> {
  type: string;
  payload: Type;
}
const downloadPayroll401kReport$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(downloadPayroll401kReport.type),
    switchMap((action: { payload: Payroll401kReportRequest; }) => {
      return from(PayrollReportsService.postPayroll401kReportsTab(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storePayroll401kReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadPayroll4070TipReport$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(downloadPayroll4070TipReport.type),
    switchMap((action: { payload: Payroll4070TipReportRequest; }) => {
      return from(PayrollReportsService.postPayroll4070TipReportsTab(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storePayroll4070TipReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadCashFlowReport$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(downloadCashFlowReport.type),
    switchMap((action: { payload: CashFlowReportRequest; }) => {
      return from(PayrollReportsService.postCashFlowReportsTab(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeCashFlowReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadAnnualFICATipCreditReport$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(downloadAnnualFICATipCreditReport.type),
    switchMap((action: { payload: AnnualFICATipCreditReportRequest; }) => {
      return from(PayrollReportsService.postAnnualFICATipCreditReportsTab(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeAnnualFICATipCreditReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadScheduledDeductionsReport$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(downloadScheduledDeductionsReport.type),
    switchMap((action: { payload: ScheduledDeductionsReportRequest; }) => {
      return from(PayrollReportsService.postScheduledDeductionsReportTab(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<string>) => { return storeScheduledDeductionsReport(res.value); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};


const downloadPensionReport$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(downloadPensionReport.type),
    switchMap((action: { payload: PensionReportRequest; }) => {
      return from(PayrollReportsService.postPensionReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storePensionReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadPensionSettings$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadPensionSettings.type),
    switchMap((action: any) => {
      return from(PayrollReportsService.getPensionSettings()).pipe(
        map((res: any) => { return res.data; }),
        map((res: PensionSetting[]) => { return storePensionSettings(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createPensionSettings$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(createPensionSettings.type),
    switchMap((action: { payload: PensionSetting; }) => {
      return from(PayrollReportsService.postPensionSettings(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<PensionSetting>) => {
          return [
            loadPensionSettings(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updatePensionSettings$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(updatePensionSettings.type),
    switchMap((action: { payload: PensionSetting; }) => {
      return from(PayrollReportsService.putPensionSettings(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<PensionSetting>) => {
          return [
            loadPensionSettings(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deletePensionSettings$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(deletePensionSettings.type),
    switchMap((action: { payload: PensionSetting; }) => {
      return from(PayrollReportsService.deletePensionSettings(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<PensionSetting>) => {
          return [
            loadPensionSettings(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadWorkersCompensationReport$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(downloadWorkersCompensationReport.type),
    switchMap((action: { payload: WorkersCompensationReportRequest; }) => {
      return from(PayrollReportsService.postWorkersCompensationReportTab(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeWorkersCompensationReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};


const downloadDeductionOptionsReport$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(downloadDeductionOptionsReport.type),
    switchMap((action: { payload: DeductionOptionsReportRequest; }) => {
      return from(PayrollReportsService.postDeductionOptionsReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeDeductionOptionsReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadTaxReport$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(downloadTaxReport.type),
    switchMap((action: { payload: TaxReportRequest; }) => {
      return from(PayrollReportsService.postTaxReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeTaxReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadMemoCashFlowReport$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(downloadMemoCashFlowReport.type),
    switchMap((action: { payload: MemoCashFlowReportRequest; }) => {
      return from(PayrollReportsService.postMemoCashFlowReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeMemoCashFlowReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadEmployeeLastCheckReport$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(downloadEmployeeLastCheckReport.type),
    switchMap((action: { payload: EmployeeLastCheckReportRequest; }) => {
      return from(PayrollReportsService.postEmployeeLastCheckReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeEmployeeLastCheckReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};
const downloadDeductionShortagesReport$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(downloadDeductionShortagesReport.type),
    switchMap((action: { payload: DeductionShortagesReportRequest; }) => {
      return from(PayrollReportsService.postDedctionShortagesReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeDeductionShortagesReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadGrossToNewReport$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadGrossToNetReport.type),
    switchMap((action: { payload: GrossToNetRequest; }) => {
      return from(PayrollReportsService.postGrossToNetReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeGrossToNetReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadUnionDuesReport$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(downloadUnionDuesReport.type),
    switchMap((action: { payload: UnionDuesReportRequest; }) => {
      return from(PayrollReportsService.postUnionDuesReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeUnionDuesReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            toggleRunningUnionDues('error'),
          ];
        }),
      );
    },
    ),
  );
};

const loadUnionDuesSettings$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadUnionDuesSettings.type),
    switchMap((action: any) => {
      return from(PayrollReportsService.getUnionDuesSettings()).pipe(
        map((res: any) => { return res.data; }),
        map((res: UnionDuesSettingsRequest[]) => { return storeUnionDuesSettings(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createUnionDuesSettings$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(createUnionDuesSettings.type),
    switchMap((action: { payload: UnionDuesSettingsRequest; }) => {
      return from(PayrollReportsService.postUnionDuesSettings(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<UnionDuesSettingsRequest>) => {
          return [
            loadUnionDuesSettings(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateUnionDuesSettings$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(updateUnionDuesSettings.type),
    switchMap((action: { payload: UnionDuesSettingsRequest; }) => {
      return from(PayrollReportsService.putUnionDuesSettings(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<UnionDuesSettingsRequest>) => {
          return [
            loadUnionDuesSettings(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};


const deleteUnionDuesSettings$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(deleteUnionDuesSettings.type),
    switchMap((action: { payload: UnionDuesSettingsRequest; }) => {
      return from(PayrollReportsService.deleteUnionDuesSettings(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<UnionDuesSettingsRequest>) => {
          return [
            loadUnionDuesSettings(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadMontlyCashFlowAnalysisDownloadReport$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(downloadMontlyCashFlowAnalysisDownloadReport.type),
    switchMap((action: { payload: MonthlyCashFlowAnalysisDownloadReport; }) => {
      return from(PayrollReportsService.postMontlyCashFlowAnalysisDownloadReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeMontlyCashFlowAnalysisDownloadReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};


const downloadCARESCreditReport$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(downloadCARESCreditReport.type),
    switchMap((action: { payload: CARESCreditReportRequest; }) => {
      return from(PayrollReportsService.postCARESCreditReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeCARESCreditReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};



export const epics: any[] = [
  downloadPayroll401kReport$,
  downloadPayroll4070TipReport$,
  downloadPensionReport$,
  loadPensionSettings$,
  createPensionSettings$,
  updatePensionSettings$,
  deletePensionSettings$,
  downloadAnnualFICATipCreditReport$,
  downloadCashFlowReport$,
  downloadScheduledDeductionsReport$,
  downloadWorkersCompensationReport$,
  downloadDeductionOptionsReport$,
  downloadTaxReport$,
  downloadMemoCashFlowReport$,
  downloadEmployeeLastCheckReport$,
  downloadDeductionShortagesReport$,
  loadGrossToNewReport$,
  downloadUnionDuesReport$,
  loadUnionDuesSettings$,
  createUnionDuesSettings$,
  updateUnionDuesSettings$,
  deleteUnionDuesSettings$,
  downloadMontlyCashFlowAnalysisDownloadReport$,
  downloadCARESCreditReport$,
];


