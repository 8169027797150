import React, { Component } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';


export default class CheckboxRenderer extends Component {
  state = {
    value: false,
  };

  constructor(public props: ICellRendererParams, public disabled?: boolean, public className?: string, public callback?: (params: { colId: string | number; checked: boolean }) => {}) {
    super(props);
    this.state.value = !!props.value;
    this.disabled = typeof disabled === 'object' ? false : disabled;
    this.checkedHandler = this.checkedHandler.bind(this);
  }

  checkedHandler(e: React.ChangeEvent<HTMLInputElement>) {
    const checked = e.target.checked;
    if (this.props.column) {
      const colId = this.props.column.getColId();

      this.props.node.setDataValue(colId, checked);
      this.setState({ value: checked });

      if (this.callback) {
        this.callback({
          colId,
          checked,
        });
      }
    }
  }

  render() {
    return (
      <div className="checkbox-renderer-wrapper">
        <input
          type="checkbox"
          data-checked={this.state.value}
          className={this.className}
          onChange={this.checkedHandler}
          checked={this.state.value}
          disabled={this.disabled}
        />
      </div>
    );
  }
}
