import React, { useRef, useState } from 'react';
import DatePicker, { CalendarContainer, CalendarContainerProps } from 'react-datepicker';
import InputMask from 'react-input-mask';
import styles from './form-controls.module.scss';
import { ShortCutKey, getMondayAndFriday, handleShortcutSelection } from 'utilities/utilities';
import DateRangeShortcuts from './DateRangeShortcuts';

const now = new Date();

type PropTypes = {
  name: string;
  returnDates: (dates: [Date | null, Date | null]) => void;
  id?: string;
  required?: boolean;
  date1?: Date;
  date2?: Date;
  errorMsg?: string;
  onChange?: (...args: any[]) => void;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
};

const DateRangeSelector = ({
  name,
  returnDates,
  id = name,
  required,
  date1,
  date2,
  disabled,
  minDate,
  maxDate,
}: PropTypes) => {
  const [range, setRange] = useState<[Date | null, Date | null]>(date1 && date2 ? [date1, date2] : getMondayAndFriday(now));
  const [shortcutOpt, setShortcutOpt] = useState<ShortCutKey | null>('thisWeek');
  
  const calendarRef = useRef<any>();
  
  const handleShortcutClick = (option: ShortCutKey) => {
    const [start, end] = handleShortcutSelection(option);
    
    setShortcutOpt(option);
    setRange([start, end]);
    returnDates([start, end]);
    
    calendarRef?.current?.setOpen(false);
  };
  
  const handleUserInput = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (!e.target.value) return;
    const newRange = structuredClone(range);
    const from = e.target.value.split('-')[0];
    const to = e.target.value.split('-')[1];
            
    if (from?.replace('_', '').length !== 10) newRange[0] = new Date(from);
    if (to?.replace('_', '').length !== 10) newRange[1] = new Date(to);
            
    setRange(newRange);
    returnDates(newRange);
  };
  
  const handleDateClick = (e: [Date | null, Date | null]) => {
    if (!e) return;
    setRange(e);
    setShortcutOpt(null);
    returnDates(e);
  };
  
  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Tab') {
      calendarRef?.current?.setOpen(false);
    }
  };
  
  return (
    <div className={styles['date-range-select-container']}>
      <DatePicker
        id={id}
        name={name}
        ref={calendarRef}
        calendarContainer={DateRangeShortcuts({ shortcutOpt, handleShortcutClick })}
        onKeyDown={onKeyDown}
        enableTabLoop={false}
        autoComplete="off"
        disabled={disabled}
        required={required}
        preventOpenOnFocus={true}
        selectsRange={true}
        startDate={range[0]}
        endDate={range[1]}
        minDate={minDate}
        maxDate={maxDate}
        showMonthDropdown
        showYearDropdown
        onChangeRaw={handleUserInput}
        onChange={handleDateClick}
        customInput={<InputMask mask="99/99/9999 - 99/99/9999" />}
      />
    </div>
  );
};

export default DateRangeSelector;