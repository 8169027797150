import { PayrollAdjustmentType } from './Payroll.model';

export interface PayrollAdjustmentEmployee {
  weekEnd: string;
  checkDate: string;
  adjustmentTotalId: number;
  payrollHistoryId: number;
  empNo: number;
  protectedEmpNo: string;
  name: string;
  ssn: string;
  adjustmentChecks: PayrollAdjustmentSummary[];
}

export enum NegativeNetPayDecision {
  NoDecision = 0,
  MoveToFederalWH = 1,
  CreateNegativeDeduction = 2
}

export interface PayrollAdjustmentSummary {
  adjustmentId: number;
  weekEnd: string;
  checkDate: string;
  adjustmentType: PayrollAdjustmentType;
  checkNo: number;
  netPay?: number;
  suiState?: string;
  overrideStateAdditionalGross: boolean;
  negativeNetPayDecision: number; //NoDecision = 0, MoveToFederalWH = 1,  CreateNegativeDeduction = 2
  federalWithholding?: PayrollAdjustmentFederalWithholding;
  earnings: PayrollAdjustmentEarning[];
  deductions: PayrollAdjustmentDeduction[];
  stateWithholdings: PayrollStateWithholding[];
  localWithholdings: PayrollLocalWithholding[];
  employeeEarningsDeductions: PayrollEmployeeEarningsDeduction[];
  employeeTaxes?: PayrollEmployeeEarningsDeduction[];
  employerTaxes?: PayrollEmployeeEarningsDeduction[];
}

export interface PayrollEmployeeEarningsDeduction {
  description: string;
  withholding: number;
  gross: number;
  showWithholding: boolean;
  showGross: boolean;
}

export interface PayrollLocalWithholding {
  adjustmentLocalTaxId?: number;
  taxEntityId: number;
  entity: string;
  withholding: number;
  employerTax: number;
  gross: number;
}

export interface PayrollStateWithholding {
  adjustmentStateTaxId?: number;
  taxEntityId: number;
  entity: string;
  withholding: number;
  gross: number;
  addtlWithholdings: AddtlWithholding[];
  isSuiOrWhState?: boolean;
  isSuiState: boolean;
  isWHState: boolean;
}

export interface AddtlWithholding {
  additionTaxId: number;
  categoryId: number;
  description: string;
  withholding: number;
  gross: number;
  isEmployeeTax: boolean;
  taxFollowed: string;
}

export interface PayrollAdjustmentDeduction {
  adjustmentDeductionId?: number;
  dedNo: number;
  amount: number;
}

export interface PayrollAdjustmentEarning {
  adjustmentEarningId: number;
  hours: number;
  tranDigit: number;
  earnings: number;
  earningsCode: string;
  loc: number;
  dept: number;
  subDept: number;
  subDept2: number;
}

export interface PayrollAdjustmentFederalWithholding {
  federalWh: number;
  ficaRWh: number;
  ficaMWh: number;
  ficaXWh: number;
}

export interface PayrollAdjustmentEmployeeVoid {
  clientNo: number;
  adjustmentType: string;
  voidPrepayInfo: VoidPrepayInfo;
  transactions: VoidTransaction[];
}

export interface VoidTransaction {
  mescId: number;
  checkType: string;
  checkTypeDescription: string;
  empNo: number;
  protectedEmpNo: string;
  weekEnd: string;
  checkDate: string;
  quarter: number;
  checkNo: number;
  firstName: string;
  lastName: string;
  companyGross: number;
  void: boolean;
  addPrePaid: boolean;
}

export interface VoidPrepayInfo {
  clientNo: number;
  empNo: number;
  weekEnd: string;
  checkDate: string;
}

export interface PayrollAdjustmentRequest {
  payrollHistoryId: number;
  protectedEmpNo: string;
  adjustmentId: number;
}

export interface PayrollAdjustmentCheckRequest
  extends PayrollAdjustmentRequest {
  data: PayrollAdjustmentSummary;
}

export interface PayrollAdjustmentCheckResponse {
  adjustment: PayrollAdjustmentSummary;
  originalAdjustment: PayrollAdjustmentSummary;
  errors: string[];
}

export interface ValidateTotals {
  employee: string,
  empNo: number, 
  totalEarnings: number,
  earnings: number, 
  totalHours: number,
  hours: number,
  totalDeductions: number,
  dedAmount: number,
  totalStateWh: number,
  stateWh: number,
  totalLocalWh: number,
  localWh: number
}

export interface VerifyResult {
  validationErrors: ValidateTotals[],
  validationErrorReport: string,
  hasValidationErrors: boolean
}
