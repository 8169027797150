import React, { CSSProperties } from 'react';
import { Label } from '.';
import styles from './form-controls.module.scss';

type PropTypes = {
  id?: string;
  name: string;
  label?: string;
  labelWidth?: number;
  groupClass?: string;
  selectClass?: CSSProperties;
  groupStyle?: object;
  required?: boolean;
  errors?: any;
  options: any[];
  valueField?: string;
  labelField?: string;
  showId?: boolean;
  addEmptyValue?: boolean;
  addEmptyText?:string;
  noOption?: boolean;
  addOptionText?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;  
  value?: any;
  defaultValue?: string | number | readonly string[] | undefined;
};

export const SelectGrpInLine: React.FC<any> = React.forwardRef(
  (
    {
      id,
      name,
      label,
      labelWidth = 60,
      groupClass,
      groupStyle,
      required = false,
      errors,
      options,
      valueField = 'id',
      labelField = 'description',
      showId = false,
      addEmptyValue = false,
      addEmptyText = '',
      noOption = false,
      addOptionText = '',
      disabled = false,
      onChange,      
      ...extraProps
    }: PropTypes,
    ref: any,
  ) => {
    const groupClass2 =
            styles['dm-form-group-inline'] + ' ' + (groupClass ?? '');
    const inputWidthString = '' + (100 - labelWidth) + '%';
    const labelWidthString = '' + labelWidth + '%';
    id = id ?? name;

    return (
      <div
        className={groupClass2}
        style={groupStyle}
      >
        {label && (
          <div style={{ width: labelWidthString }}>
            <Label
              id={id}
              hasError={errors}
              text={label}
              required={required}
            />
          </div>
        )}

        <div style={{ width: inputWidthString }}>
          <select
            ref={ref}
            id={id}
            name={name}
            className={
              styles['dm-form-control'] + ' ' + extraProps?.selectClass +
                            (errors ? ' ' + styles['dm-is-invalid'] : '')
            }
            disabled={disabled}
            value={extraProps?.value}
            defaultValue={extraProps.defaultValue}
            onChange={onChange}
          >
            {addEmptyValue && <option value={undefined}> </option>}
            {addEmptyText && (
              <option
                key="emptyText"
                value={''}
              >
                {addEmptyText}
              </option>
            )}
            {noOption && (
              <option value="">
                &lt;no {addOptionText} &gt;
              </option>
            )}
            {options.map((opt: any) => {
              return (
                <option
                  key={opt[valueField]}
                  value={opt[valueField]}
                >
                  {showId
                    ? opt[valueField] + ' - ' + opt[labelField]
                    : opt[labelField]}
                </option>
              );
            })}
          </select>
          <small className="text-danger">{errors?.message} </small>
        </div>
      </div>
    );
  },
);
