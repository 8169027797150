import { GridOptions } from '@ag-grid-enterprise/all-modules';

export const gridOptionsDefault: GridOptions = {
  defaultColDef: {
    suppressMenu: true,
    resizable: true,
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
  pagination: true,
  paginationPageSize: 20,
};
