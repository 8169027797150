import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class ApClientVendor {
  apVendorId = 0;

  apClientId = 0;

  name = '';

  address1 = '';

  address2 = '';

  city = '';

  state = '';

  country = '';

  zipCode = '';

  zipCodePlus4 = '';

  phoneNumber = '';

  fax = '';

  description = '';

  agencyId = 0;

  categoryId = 0;

  entityId = 0;

  visible = false;

  federalId = '';


  static readonly convProps: PropTypeLists = {
    numberProps: ['apVendorId', 'apClientId', 'agencyId', 'categoryId', 'entityId'],
    boolProps: ['visible'],
  };

  constructor(
    apVendorId: number | string,
    apClientId: number | string,
    props?: { [key: string]: any }) {

    this.apVendorId = (typeof apVendorId === 'string') ? parseInt(apVendorId) : apVendorId;
    this.apClientId = (typeof apClientId === 'string') ? parseInt(apClientId) : apClientId;
    props && convToClass(this, props, ApClientVendor.convProps);
  }
}