import React, { useEffect, useRef, RefObject, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CheckboxGrpInLine, RadioGrp, SelectGrp } from 'core/components/form-controls';
import InputReportDateSelector from 'core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { DtoOptions } from 'core/models';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';
import { GrossToNetRequest } from 'core/models/PayrollReports.model';
import { CommonService } from 'core/services';
import { clearGrossToNetReport, handleError, loadGrossToNetReport } from 'core/store/actions';
import Icon from 'core/components/shared/Icon';

const groupByOpts: RadioOptions[] = [
  {
    value: '0',
    label: 'By Loc-Dept-Sub',
  },
  {
    value: '1',
    label: 'By Location Only',
  },
  {
    value: '2',
    label: 'By Location and Dept',
  },
  {
    value: 'ByDepartmentOnly',
    label: 'By Department Only',
  },
  {
    value: 'BySubDeptOnly',
    label: 'By Sub Dept Only',
  },
  {
    value: 'BySubDept2Only',
    label: 'By Sub Dept 2 Only',
  },
];

const pageBreakBy = [
  {
    label: '0',
    description: 'Location',

  },
  {
    label: '1',
    description: 'Department',

  },
  {
    label: '2',
    description: 'SubDepartment',

  },
  {
    label: '3',
    description: 'SubDepartment2',

  },
];

const GrossToNetReport = () => {
  const dispatch = useAppDispatch();
  
  const childRef: RefObject<any> = useRef();

  const client = useAppSelector((state) => { return state?.client?.client; });
  const report = useAppSelector((state) => { return state.payrollReports.grossToNetReport; });
  
  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [fileType, setFileType] = useState<string>('pdf');
  const [enable, setEnabled] = useState<boolean>(false);
  const [disableAutoDateSelect, setDisableAutoDateSelect] = useState<boolean>(false);

  const { register, handleSubmit, setValue, watch } = useForm<GrossToNetRequest>({
    defaultValues: {
      groupByOptions: '0',
    },
  });
  
  const watchedEmpDetailOption = watch('showEmployeeDetail');
  const watchedSsnOption = watch('showSSN');

  useEffect(() => {
    if (!(report && fileType === 'excel')) return;
    
    CommonService.downloadBase64File(
      'application/excel',
      report,
      'GrossToNetReport.xlsx',
    );
    dispatch(clearGrossToNetReport());
  }, [report]);

  const downloadReport = (data: GrossToNetRequest) => {
    if (!client) return dispatch(handleError('Unable to retrieve client information'));
    
    const submitData: GrossToNetRequest = {
      clientNo: client.clientNo,
      showEmployeeDetail: data.showEmployeeDetail,
      showSSN: data.showSSN,
      byWeekEndCheckDate: data.byWeekEndCheckDate,
      oneLinePerEmployee: data.oneLinePerEmployee,
      dtoOptions: reportDates,
      groupByOptions: data.groupByOptions,
      pageBreakOptions: data.usePageBreak ? data.pageBreakOptions : 'NoPageBreak',
      usePageBreak: data.usePageBreak,
      reportTypeOption: fileType,

    };
    dispatch(loadGrossToNetReport(submitData));
  };
  
  const hidePdfViewer = () => {
    dispatch(clearGrossToNetReport());
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '0' || e.target.value === '2') {
      setEnabled(false);
    } else if (e.target.value === '1') {
      setEnabled(true);
      setValue('pageBreakOptions', 'Location');
    } else if (e.target.value === 'ByDepartmentOnly') {
      setEnabled(true);
      setValue('pageBreakOptions', 'Department');
    } else if (e.target.value === 'BySubDeptOnly') {
      setEnabled(true);
      setValue('pageBreakOptions', 'SubDepartment');
    } else if (e.target.value === 'BySubDept2Only') {
      setEnabled(true);
      setValue('pageBreakOptions', 'SubDepartment2');
    }
  };

  return (
    <div className="dm-panel dm-panel-border">
      <form onSubmit={handleSubmit(downloadReport)}>
        <div className="dm-grid-title">
          Gross To Net Report
        </div>
        <div className="w-100 text-right">
          <button
            type="submit"
            className="btn btn-link dm-grid-action-title "
            onClick={() => { return setFileType('excel'); }}
            disabled={!reportDates.length}
          >
            Download Report{' '}
            <Icon
              name="download"
              className="fa-download"
            />
          </button>
        </div>
        <hr className="dm-panel-hr" />
        <div className="d-flex flex-row flex-grow-1">
          <InputReportDateSelector
            ref={childRef}
            returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }
            }
            disableAutoDateSelect={disableAutoDateSelect}
            isSingleSelect={disableAutoDateSelect}
            hideSelectAllBtn={disableAutoDateSelect}
          />
          <div className="d-flex flex-column flex-grow-1">
            <div className=" text-right">
              <button
                type="button"
                className="orange-outline-button mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="orange-button mr-2"
                onClick={() => { return setFileType('pdf'); }}
                disabled={!reportDates.length}
              >
                Run Report
              </button>
            </div>
            <div
              className="w-50 dm-panel dm-panel-border"
              style={{ marginTop: '66px' }}
            >
              <div className="d-flex">
                <div className="d-flex flex-row flex-grow-1">
                  <div className="d-flex flex-column flex-grow-1">
                    <div className="dm-grid-action-title mb-2">
                      Report Type
                    </div>
                    <CheckboxGrpInLine
                      name="showEmployeeDetail"
                      ref={register}
                      labelFirst={false}
                      label="Show Employee Detail"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (!e.target.checked) {
                          setValue('showSSN', false);
                        }
                      }}
                    />
                    <CheckboxGrpInLine
                      name="showSSN"
                      ref={register}
                      labelFirst={false}
                      label="Show SSN"
                      value={watchedSsnOption}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setValue('showSSN', e.target.checked);
                      }}
                      disabled={!watchedEmpDetailOption}
                    />
                    <RadioGrp
                      isVertical={true}
                      name="groupByOptions"
                      ref={register}
                      radioOptions={
                        groupByOpts
                      }
                      onChange={handleChange}
                    />
                  </div>
                  <div className="d-flex flex-column flex-grow-1 pt-1 pl-1">
                    <div className="dm-grid-action-title mb-2">
                      Other Options
                    </div>
                    <div className="mb-2">
                      <div
                        id="pageBreakByAndDropDown"
                        className="d-flex flex-row flex-grow-1"
                      >
                        <CheckboxGrpInLine
                          name="usePageBreak"
                          ref={register}
                          labelFirst={false}
                          label="Page Break By:"
                        />
                        <div style={{ width: '50%' }}>
                          <SelectGrp
                            options={pageBreakBy}
                            ref={register}
                            name="pageBreakOptions"
                            disabled={enable}
                          />
                        </div>
                      </div>
                      <CheckboxGrpInLine
                        name="byWeekEndCheckDate"
                        ref={register}
                        labelFirst={false}
                        onChange={(e: any) => {
                          return setDisableAutoDateSelect(e.target.checked);
                        }}
                        label="By Week End and Check Date"
                      />
                    </div>
                    <div className="dm-grid-action-title mb-2 ">
                      Download Options
                    </div>
                    <CheckboxGrpInLine
                      name="oneLinePerEmployee"
                      ref={register}
                      labelFirst={false}
                      label="One Line Per Employee"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {report ? (
        <PDFViewerModal
          show={report.length > 0}
          pdfData={report}
          onHide={hidePdfViewer}
          reportName="GrossToNetReport"
        />
      ) : null}
    </div>
  );
};
export default GrossToNetReport;
