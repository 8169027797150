import { API_IDENTITY_SERVER, API_CLIENT } from './API';
import { AuthEndPoints, ClientMasterEndPoints } from './URL';
import { BffUser } from 'core/models/BffUser.model';
import { SessionStorageService } from './session-storage.service';

export default class UserAuthService {

  public getUser = async () => {
    const user = await API_IDENTITY_SERVER.get<BffUser[]>(AuthEndPoints.BFFUSER)
      .catch((error) => {return null;} );
    return user;
  };

  public signInRedirect = () => {
    sessionStorage.setItem('redirectUri', window.location.pathname);

    const redirectUri = (process.env.REACT_APP_URL ?? '') + 'signin-oidc';
    const loginUri = process.env.REACT_APP_IDENTITY_SERVER_BASE_URL + AuthEndPoints.LOGIN(redirectUri);
    
    window.location.href = loginUri;
  };

  public navigateToScreen = () => {
    window.location.replace('/');
  };

  public isAuthenticated = () => {
    const isAuthenticated = SessionStorageService.getIsAuthenticated();
    return isAuthenticated;
  };

  public logout = async () => {
    const user = await this.getUser();
    if (!user) return;

    const logoutInfo = user.data.find(x => x.type === 'bff:logout_url');
    const logoutUri = process.env.REACT_APP_LOGOUT_BASE_URL + (logoutInfo?.value ?? '');

    window.location.href = logoutUri;
  };

  public signoutRedirectCallback = async () => {
    sessionStorage.clear();
    window.location.replace(`${process.env.REACT_APP_URL}`);
  };
}
