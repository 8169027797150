import { AxiosResponse } from "axios";
import { YTD } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getYearToDate = (empNo: number, prYear: number): Promise<AxiosResponse<YTD>> => {return API_EMPLOYEE_MASTER.get<YTD>(EmployeeEndPoints.GET_YEAR_TO_DATE(CommonService.getProtectedClientNo(), empNo, prYear));};

export const YearToDateService = {
  getYearToDate,
};
