import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { EmpDate, HireHistory } from 'core/models';
import { updateEmpDate } from 'core/store/actions';
import { CheckboxGrpInLine, SelectModalGrp } from '../form-controls';
import { ControlDatePickerGrp } from '../form-controls/ControlDatePickerGrp';
import HistoryLabel from '../form-controls/HistoryLabel';
import DropdownOptionForm from '../form-controls/select-modal/DropdownOptionForm';
import AlertModal from '../modals/alert-modal.modal';
import {
  getEmpDate,
  getHireHistories,
  getLayoffCodes,
  getOtherDateDescription,
  getReportOptions,
  getTermCode,
} from 'core/store/selectors';

type Props = {
  termDate: Date | null;
  hireDate: Date | null;
  showHistory: boolean;
};

const EmpDateComponent: React.FC<Props> = ({ termDate, hireDate, showHistory }: Props) => {
  const dispatch = useAppDispatch();

  const [hireHistories, setHireHistories] = useState<HireHistory[]>([]);
  const [show, setShow] = useState(false);

  const selectedEmp = useAppSelector(({ selEmployee }) => { return selEmployee.employee?.empNo; });
  const empDate = useSelector(getEmpDate) as EmpDate;
  const termCodeOpts = useSelector(getTermCode);
  const layoffCodeOpts = useSelector(getLayoffCodes);
  const otherDateDescriptionOpts = useSelector(getOtherDateDescription);
  const hireHistoryItems = useSelector(getHireHistories);
  const minEmpAge = useAppSelector(({ client }) => client.clientOptions?.options?.[2527]?.optionValue);
  const usesTerminationWorkFlow = useSelector(getReportOptions('TerminationWorkFlow'));

  const { register, control, handleSubmit, errors, setValue, reset, formState } =
        useForm<EmpDate>({
          defaultValues: empDate,
        });
  const { dirtyFields } = formState;
  const dirtyFieldKeys = Object.keys(dirtyFields);
  
  useEffect(() => {
    reset({ ...empDate });
  }, [empDate, selectedEmp]);

  useEffect(() => {
    setValue('termDate', termDate);
  }, [termDate]);

  useEffect(() => {
    setValue('hireDate', hireDate);
  }, [hireDate]);

  useEffect(() => {
    if (hireHistoryItems) {
      setHireHistories(hireHistoryItems);
    }
  }, [hireHistoryItems]);

  const validateHireHistoryDates = () => {
    if (hireHistories?.length < 1) return true;

    let valid = true;
    let prevHireDate = hireHistories[0].hireDate as Date;

    hireHistories.forEach((hh, index) => {
      if (index > 0) {
        valid =
          !hh.termDate ||
          (hh.hireDate as Date).getTime() <=
          (hh.termDate as Date)?.getTime();
        valid =
          valid &&
          prevHireDate.getTime() >= (hh.termDate as Date)?.getTime();
        prevHireDate = hh.hireDate as Date;
      }
    });

    return valid;
  };
  const onSave = (data: EmpDate) => {
    if (empDate) {
      if (!validateHireHistoryDates()) {
        setShow(true);
        return;
      }
      
      const empAge = Math.abs((new Date().getFullYear()) - (new Date(data?.birthDate ?? new Date()).getFullYear()));
      if (
        (!!data?.birthDate)
        && (empAge < Number(minEmpAge))
        && !confirm(`The birth date entered indicates the employee is below the minimum age of ${minEmpAge}. Continue?`)
      ) {
        return;
      }
      setHireHistories((prev) => {
        return prev.map((hh, index) => {
          return !index
            ? {
              ...hh,
              termDate: data.termDate,
              termCodeID: data.termCode,
            }
            : { ...hh };
        },
        );
      },
      );
      const newEmpDate = new EmpDate(
        empDate.empId,
        empDate.clientNo,
        empDate.empNo,
        empDate.protectedEmpNo,
        data,
      );
      dispatch(updateEmpDate({ empDate: newEmpDate,
        updateKeys: dirtyFieldKeys }));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col sm={6}>
            <Row className="no-gutters">
              <Col
                xs="6"
                className="pr-1"
              >
                <HistoryLabel
                  labelTitle="Birth Date"
                  section="date"
                  field="birthDate"
                  protectedEmpNo={empDate.protectedEmpNo}
                  showHistory={showHistory}
                />
                <ControlDatePickerGrp
                  name="birthDate"
                  control={control}
                />
              </Col>
              <Col xs="6">
                <HistoryLabel
                  labelTitle="Hire Date"
                  section="date"
                  field="hireDate"
                  protectedEmpNo={empDate.protectedEmpNo}
                  showHistory={showHistory}
                />
                <ControlDatePickerGrp
                  name="hireDate"
                  errors={errors.hireDate}
                  control={control}
                  readOnly
                />
              </Col>
              <Col
                xs="6"
                className="pr-1"
              >
                <HistoryLabel
                  labelTitle="Seniority Date"
                  section="date"
                  field="seniorityDate"
                  protectedEmpNo={empDate.protectedEmpNo}
                  showHistory={showHistory}
                />
                <ControlDatePickerGrp
                  name="seniorityDate"
                  control={control}
                />
              </Col>
              <Col xs="6">
                <HistoryLabel
                  labelTitle="Hire Date 2"
                  section="date"
                  field="hireDate2"
                  protectedEmpNo={empDate.protectedEmpNo}
                  showHistory={showHistory}
                />
                <ControlDatePickerGrp
                  name="hireDate2"
                  control={control}
                />
              </Col>
              <Col
                xs="6"
                className="pr-1"
              >
                <HistoryLabel
                  labelTitle="Benefit Eligible Date"
                  section="date"
                  field="benefitEligibleDate"
                  protectedEmpNo={empDate.protectedEmpNo}
                  showHistory={showHistory}
                />
                <ControlDatePickerGrp
                  name="benefitEligibleDate"
                  control={control}
                />
              </Col>
              <Col xs="6">
                <HistoryLabel
                  labelTitle="COBRA Start Date"
                  section="date"
                  field="cobraStartDate"
                  protectedEmpNo={empDate.protectedEmpNo}
                  showHistory={showHistory}
                />
                <ControlDatePickerGrp
                  name="cobraStartDate"
                  control={control}
                />
              </Col>
            </Row>
          </Col>

          <Col
            sm={6}
            className="border-left"
          >
            <Row className="no-gutters">
              <Col
                xs="4"
                className="pr-1"
              >
                <HistoryLabel
                  labelTitle="Term Date"
                  section="date"
                  field="termDate"
                  protectedEmpNo={empDate.protectedEmpNo}
                  showHistory={showHistory}
                />
                <ControlDatePickerGrp
                  name="termDate"
                  control={control}
                  disabled={usesTerminationWorkFlow?.showReport || false}
                />
              </Col>
              <Col xs="8">
                <HistoryLabel
                  labelTitle="Term Reason/Description"
                  section="hireStatus"
                  field="termCode"
                  protectedEmpNo={empDate.protectedEmpNo}
                  showHistory={showHistory}
                />
                <SelectModalGrp
                  name="termCode"
                  options={termCodeOpts}
                  errors={errors.termCode}
                  control={control}
                  value={empDate.termCode ?? '423'}
                  setValue={setValue}
                  addOptionText="Term Reason"
                  modalTitle="TERMINATION REASON MAINTENANCE"
                  formComponent={DropdownOptionForm}
                  dropdownName="TermCode"
                  disabled={usesTerminationWorkFlow?.showReport || false}
                />
              </Col>
              <Col
                xs="4"
                className="pr-1"
              >
                <HistoryLabel
                  labelTitle="Layoff Date"
                  section="date"
                  field="layOffDate"
                  protectedEmpNo={empDate.protectedEmpNo}
                  showHistory={showHistory}
                />
                <ControlDatePickerGrp
                  name="layOffDate"
                  control={control}
                />
              </Col>
              <Col xs="8">
                <SelectModalGrp
                  name="layOffCode"
                  options={layoffCodeOpts}
                  errors={errors.layOffCode}
                  control={control}
                  label="Layoff Reason/Description"
                  value={empDate.layOffCode}
                  setValue={setValue}
                  addOptionText="Layoff Reason"
                  modalTitle="LAYOFF REASON MAINTENANCE"
                  formComponent={DropdownOptionForm}
                  dropdownName="LayoffCode"
                />
              </Col>
              <Col
                xs="4"
                className="pr-1"
              >

                <HistoryLabel
                  labelTitle="Other Date"
                  section="date"
                  field="otherDate"
                  protectedEmpNo={empDate.protectedEmpNo}
                  showHistory={showHistory}
                />
                <ControlDatePickerGrp
                  name="otherDate"
                  control={control}
                />
              </Col>
              <Col xs="8">

                <HistoryLabel
                  labelTitle="Other Reason/Description"
                  section="date"
                  field="otherDateDescriptionID"
                  protectedEmpNo={empDate.protectedEmpNo}
                  showHistory={showHistory}
                />
                <SelectModalGrp
                  name="otherDateDescriptionID"
                  options={otherDateDescriptionOpts}
                  errors={errors.otherDateDescriptionID}
                  control={control}
                  value={empDate.otherDateDescriptionID}
                  setValue={setValue}
                  addOptionText="Other Reason"
                  modalTitle="OTHER DATE DESC. MAINTENANCE"
                  formComponent={DropdownOptionForm}
                  dropdownName="OtherDateDescription"
                />
              </Col>
              <Col xs="12">
                <CheckboxGrpInLine
                  name="notEligibleForRehire"
                  errors={errors.notEligibleForRehire}
                  ref={register}
                  label="Not Eligible for Rehire"
                  labelFirst={false}
                  disabled={usesTerminationWorkFlow?.showReport || false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <button
              type="submit"
              className="btn orange-button"
            >
              Save
            </button>
          </Col>
        </Row>
      </form>
      {show && (
        <AlertModal
          title="Data Error"
          message="Hire Dates must be <= Term Dates with no overlap in Hire History"
          btnText="OK"
          show={show}
          onHide={() => {return setShow(false);}}
        />
      )}
    </>
  );
};

export default EmpDateComponent;
