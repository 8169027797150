import React from 'react';
import Icon from 'core/components/shared/Icon';
import { IconTypes } from 'enums/IconTypes';
import { Row, Col, Accordion } from 'react-bootstrap';
import ContextAwareToggle from 'core/components/shared/ContextAwareToggle';
import { useAppSelector } from 'utilities/hooks';
import NewFeatureItem from './NewFeatureItem';

type Props = {
  visible: boolean;
};

const NewFeaturesPage: React.FC<any> = (props: Props) => {
  const newFeatureItemData = useAppSelector(
    (state) => { return state.dashboard.newFeatures; },
  );
  
  if (!props.visible) return null;
  return (
    <div className="new-features-page-container dm-card">
      <div className="dm-card-body p-0">
        <div className="dm-card-body-title text-uppercase pl-2 pt-2">
          <Icon
            name="star"
            type={IconTypes.REGULAR}
          />
          <span>&nbsp;New Features</span>
        </div>
        <Accordion defaultActiveKey="0">
          <ContextAwareToggle
            eventKey="0"
            className="blue-bar"
            backgroundColor=""
          >
            <Row className="blue-bar no-gutters">
              <Col className="my-auto pl-1 pr-0">
                New Features
              </Col>
            </Row>
          </ContextAwareToggle>
          <Accordion.Collapse eventKey="0">
            <div style={{ height: '400px',
              overflow: 'auto' }}
            >            
              <ul className="list-group">
                {newFeatureItemData?.map((item) => {
                  return (
                    <li
                      className="list-group-item"
                      key={item.newFeatureId}
                    >
                      <NewFeatureItem {...item} />
                    </li>
                  );
                })}
              </ul>
            </div>
          </Accordion.Collapse>
        </Accordion>
      </div>
    </div>
  );
};

export default NewFeaturesPage;
