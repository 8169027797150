import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { EEOUnitSetting } from '.../../core/models/HumanCapitalReports.model';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEEOUnitSettings, loadEEOUnitSettings } from 'core/store/actions';
import { getEEOUnitSettings } from 'core/store/selectors';
import { useAppSelector } from 'utilities/hooks';
import { Client } from 'core/models';
import AssignUnitModalItem from './AssignUnitModalItem';
import Icon from 'core/components/shared/Icon';
import { uniqueId } from 'lodash';

type PropTypes = {
  show: boolean;
  onHide: () => void;
  setting?: EEOUnitSetting;
};

const AssignUnitModal: React.FC<PropTypes> = ({ show, onHide, setting }) => {
  const dispatch = useDispatch();
  const client = useAppSelector((state) => { return state.client.client; }) as Client;
  const eeoUnitSettingsStore = useSelector(getEEOUnitSettings) as EEOUnitSetting[];
  const [eeoUnitSettingsList, setEEOUnitSettingsList] = useState<EEOUnitSetting[]>([]);

  useEffect(() => {
    dispatch(loadEEOUnitSettings());
  }, []);

  useEffect(() => {
    if (eeoUnitSettingsStore) {
      setEEOUnitSettingsList([...eeoUnitSettingsStore].map(item => {
        return {
          ...item,
          uid: uniqueId(),
        };
      }) as EEOUnitSetting[]);
    }
  }, [eeoUnitSettingsStore]);

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };

  const addEEOUnitSettings = () => {
    const addSetting: EEOUnitSetting = {
      uid: uniqueId(),
      ruleID: 0,
      entityID: client.clientEntityID,
      clientEntityID: client.clientEntityID,
      clientNo: client.clientNo,
      loc: null,
      dept: null,
      sub: null,
      sub2: null,
      eeoUnitID: null,
    };

    setEEOUnitSettingsList((prev: EEOUnitSetting[]) => {
      return [
        ...prev,
        addSetting,
      ];
    });
  };

  const deleteAssignUnit = (item: EEOUnitSetting) => {
    if (item.ruleID) {
      dispatch(deleteEEOUnitSettings(item));
    }

    setEEOUnitSettingsList(eeoUnitSettingsList.filter((eeoUnit) => {
      return eeoUnit.uid !== item.uid;
    }));
  };

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="modal-md"
        animation={false}
      >
        <div className="modal-header" >
          <div className="dm-grid-title">Assign Unit Numbers</div>
          <div className="d-flex flex-row ml-auto mt-auto mr-4">
            <button
              type="button"
              className="btn btn-link dm-grid-action-title pb-0"
              onClick={addEEOUnitSettings}
            >
              Add New Assignment
              <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          </div>
          <button
            type="button"
            onClick={closeModal}
            className="modal-close-btn ml-0"
          >
            <Icon name="times" />
          </button>
        </div>
        <Modal.Body>
          <div>
            {eeoUnitSettingsList?.map((item: any, index: number) => {
              return (
                <div key={item.id}>
                  <AssignUnitModalItem
                    index={index}
                    onDelete={deleteAssignUnit}
                    setting={item}
                  />
                  <hr className="dm-panel-hr mt-0" />
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default AssignUnitModal;



