import Modal from 'core/components/modals/Modal';
import React from 'react';

type Props = {
  empNo: number;
  show: boolean;
  onHide: () => void;
};

const DefaultTaxNoticeModal = ({ empNo, show, onHide }: Props) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Default Tax Information Generated"
    >
      {(closeModal) => {
        return (
          <div className="d-flex flex-column">
            <div>
              Notice: Default pay rate and tax information has
              been generated for employee {empNo}. Please review this
              under the respective sections.
            </div>
            <div className="d-flex flex-row pt-2 justify-content-end">
              <button
                type="button"
                className="btn btn-primary orange-button"
                onClick={closeModal}
              >
                Okay
              </button>
            </div>
          </div>
        );
      }}
    </Modal>
  );
};

export default DefaultTaxNoticeModal;
