import { createAction } from '@reduxjs/toolkit';
import { RateRule } from '../../models';

export const loadRateRules = createAction('[rate-rule] LOAD_RATE_RULES');
export const storeRateRules = createAction<RateRule[]>('rate-rule] STORE_RATE_RULES');

export const loadRateRule = createAction<number>('[rate-rule] LOAD_RATE_RULE');
export const createRateRule = createAction<RateRule>('[rate-rule] CREATE_RATE_RULE');
export const updateRateRule = createAction<RateRule>('[rate-rule] UPDATE_RATE_RULE');
export const storeRateRule = createAction<RateRule>('[rate-rule] STORE_RATE_RULE');

export const deleteRateRule = createAction<RateRule>('[rate-rule] DELETE_RATE_RULE');



