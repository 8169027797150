import React, { CSSProperties, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom';
import { CheckboxGrp, InputGrp, InputGrpDecimal } from 'core/components/form-controls';
import PanelHeader from 'core/components/shared/PanelHeader';
import { ContractorOption } from 'core/models';
import { loadContractorOptions, updateContractorOptions } from 'core/store/actions';
import { getContractorOptions } from 'core/store/selectors';
import { UNSAVED_MESSAGE } from 'core/constants';

const checkboxStyles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const fs = {
  fringeType: { label: 'Type', readOnly: true, groupClass: 'groupClass12' },
  fringeDescription: { label: 'DESCRIPTION', readOnly: true, groupClass: 'groupClass13'  },
  fringeRate: { label: 'FRINGE RATE', readOnly: true, groupClass: 'groupClass15'  },
  ovrYn: { label: 'APPLY OVERRIDE', groupClass: 'groupClass10', groupStyle: checkboxStyles  },
  ovrAmount: { label: 'OVERRIDE AMOUNT', groupClass: 'groupClass12' },
  isDeducted: { label: ['EMPLOYEE WILL HAVE THIS', 'FRINGE DEDUCTED'], groupClass: 'groupClass15', groupStyle: checkboxStyles  },
  earnIsIncluded: { label: ['EMPLOYEE WIL HAVE THIS', 'FRINGE INCLUDED IN EARNINGS'], groupClass: 'groupClass20', groupStyle: checkboxStyles  },
};

const ContractorOptionsPage = () => {
  const dispatch = useDispatch();

  const { register, handleSubmit, reset, control, formState: { isDirty } } = useForm();
  const { fields } = useFieldArray({
    control,
    name: 'items',
    keyName: 'fringeGuid',
  });

  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();

  const contractorOptions = useSelector(getContractorOptions);

  useEffect(() => {
    if (!contractorOptions) return;
    reset({ items: contractorOptions });
  }, [contractorOptions]);

  useEffect(() => {
    dispatch(loadContractorOptions(protectedEmpNo));
  }, [protectedEmpNo]);

  const onSave = (data: any) => {
    const transformedUpdateData = data.items.map((item: ContractorOption, index: number) => {
      return new ContractorOption({ ...contractorOptions[index], ...item });
    },
    );
    
    dispatch(updateContractorOptions(transformedUpdateData));
  };


  return (
    <div className="dm-panel dm-panel-border" >
      <Prompt
        when={isDirty}
        message={UNSAVED_MESSAGE}
      />
      <PanelHeader title="Contractor Options" />
      <form onSubmit={handleSubmit(onSave)}>
        {fields.map((item, index) => {
          return (

            <div
              key={item.fringeGuid}
              className="d-flex flex-wrap mt-3"
            >
              <InputGrp
                {...fs.fringeType}
                name={`items[${index}].fringeType`}
                ref={register}
                defaultValue={item.fringeType}
              />
              <InputGrp
                {...fs.fringeDescription}
                name={`items[${index}].fringeDescription`}
                ref={register}
                defaultValue={item.fringeDescription}
              />
              <InputGrpDecimal
                {...fs.fringeRate}
                name={`items[${index}].fringeRate`}
                ref={register}
                defaultValue={item.fringeRate}
                fixed={6}
              />
              <CheckboxGrp
                {...fs.ovrYn}
                name={`items[${index}].ovrYn`}
                ref={register}
                defaultChecked={item.ovrYn}
              />
              <InputGrpDecimal
                {...fs.ovrAmount}
                name={`items[${index}].ovrAmount`}
                ref={register}
                defaultValue={item.ovrAmount}
                fixed={6}
              />
              <CheckboxGrp
                {...fs.isDeducted}
                name={`items[${index}].isDeducted`}
                ref={register}
                defaultChecked={item.isDeducted}
              />
              <CheckboxGrp
                {...fs.earnIsIncluded}
                name={`items[${index}].earnIsIncluded`}
                ref={register}
                defaultChecked={item.earnIsIncluded}
              />
              {index + 1 < fields.length && <hr className="dm-panel-hr mt-2 w-100" />}
            </div>

          );
        })}
        <div className="row">
          <div className="col-12 text-right mt-3" >
            <button
              type="submit"
              className="btn orange-button-sm mr-3"
              disabled={!isDirty}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContractorOptionsPage;