import React, { CSSProperties } from 'react';

type ForbiddenRouteStyle = {
  [key: string]: CSSProperties
};

const forbiddenRouteStyles: ForbiddenRouteStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '80vh',
    userSelect: 'none',
  },
  title: {
    fontSize: '3rem',
  },
  subTitle: {
    fontSize: '1.5rem',
  },
};

const ForbiddenRoute = () => {
  return (
    <div style={forbiddenRouteStyles.container}>
      <div style={forbiddenRouteStyles.title}>Access Denied</div>
      <div style={forbiddenRouteStyles.subTitle}>
        You don&apos;t have permission to view this page.
      </div>
    </div>
  );
};

export default ForbiddenRoute;