import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import ConnectionOption from 'core/models/intuit/connectionOption.model';
import IntuitAuth from 'core/models/intuit/intuitAuth.model';
import QuickBooksConnection from 'core/models/intuit/quickBooksConnection.model';
import QuickBooksExportResult from 'core/models/intuit/quickBooksExportResult.model';
import AccountMismatch from 'core/models/intuit/accountMismatch.model';

export const loadIntuitAuth = createAction('[intuit] LOAD_INTUIT_AUTH');
export const storeIntuitAuth = createAction<IntuitAuth>('[intuit] STORE_INTUIT_AUTH');
export const handleIntuitAuthError = createAction('[intuit] HANDLE_INTUIT_AUTH_ERROR');
export const manageQuickBooksConnection = createAction<ConnectionOption>('[intuit] MANAGE_QUICKBOOKS_CONNECTION');
export const storeQuickBooksConnection = createAction<QuickBooksConnection>('[intuit] STORE_QUICKBOOKS_CONNECTION');
export const handleQuickBooksConnectionError = createAction('[intuit] HANDLE_INTUIT_QUICKBOOKS_CONNECTION_ERROR');
export const exportToQuickBooks = createAction('[intuit] EXPORT_TO_QUICKBOOKS');
export const handleExportToQuickBooksError = createAction('[intuit] HANDLE_EXPORT_TO_QUICKBOOKS_ERROR');
export const storeQuickBooksExportResult = createAction<QuickBooksExportResult>('[intuit] STORE_QUICKBOOKS_EXPORT_RESULT');
export const clearQuickBooksExportResultAndFileData = createAction('[intuit] CLEAR_QUICKBOOKS_EXPORT_RESULT');
export const generateChartOfAccounts = createAction<Array<AccountMismatch>>('[intuit] GENERATE_CHART_OF_ACCOUNTS');
export const storeChartOfAccounts = createAction<string>('[intuit] STORE_CHART_OF_ACCOUNTS');
export const handleGenerateChartOfAccountsError = createAction('[intuit] HANDLE_GENERATE_CHART_OF_ACCOUNTS_ERROR');
