import { CheckboxGrpInLine } from 'core/components/form-controls';
import Modal from 'core/components/modals/Modal';
import { TimeCardCrewSheetColumns } from 'core/models';
import { addUpdateTimeCardCrewSheetColumns, handleError } from 'core/store/actions';
import React, { useState } from 'react';
import { useAppDispatch } from 'utilities/hooks';

type Props = {
  show: boolean;
  onHide: () => void;
  timeCardCrewSheetColumns: TimeCardCrewSheetColumns | null
};

const ColumnSettingsModal = ({ show, onHide, timeCardCrewSheetColumns }: Props) => {

  const [crewSheet, setCrewSheet] = useState<TimeCardCrewSheetColumns | null>(timeCardCrewSheetColumns);
  const dispatch = useAppDispatch();

  //Will set the value that was checked and set it to the local state. We then use this to save there changes.
  const onChange = (value: boolean, name: string) => {
    let crewSheetCopy = structuredClone(crewSheet);
    if(!crewSheetCopy) return;

    switch(name){
      case 'area': crewSheetCopy.hideArea = value; break;
      case 'trade': crewSheetCopy.hideTrade = value; break;
      case 'sub': crewSheetCopy.hideSub = value; break;
      case 'description': crewSheetCopy.hideSubDesc = value; break;
      case 'jobNo': crewSheetCopy.hideJobNo = value; break;
      case 'subNo': crewSheetCopy.hideSubNo = value; break;
      case 'foExtra': crewSheetCopy.hideFoExtra = value; break;
      case 'phase': crewSheetCopy.hidePhase = value; break;
      case 'costCode': crewSheetCopy.hideCostCode = value; break;
      case 'earnings': crewSheetCopy.hideEarnings = value; break;
      case 'hours': crewSheetCopy.hideHours = value; break;
      case 'notes': crewSheetCopy.hideNotes = value; break;
      case 'deductions': crewSheetCopy.hideDeductions = value; break;
    }

    setCrewSheet(crewSheetCopy);
  }

  const onSave = () => {
    if(!crewSheet) return dispatch(handleError("Error when attempting to save selected columns."))
    dispatch(addUpdateTimeCardCrewSheetColumns(crewSheet))
  }

  return (
    <Modal
      title="Column Settings"
      show={show}
      onHide={onHide}
    >
      {() => (
        <div className="d-flex flex-column">
          <span className="mb-2">Checked columns will be hidden.</span>
          <div className="d-flex flex-column">
            <CheckboxGrpInLine
              name="area"
              label={'Area'}
              defaultChecked={crewSheet?.hideArea || false}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, 'area')}
            />
            <CheckboxGrpInLine
              name="trade"
              label={'Trade'}
              defaultChecked={crewSheet?.hideTrade || false}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, 'trade')}
            />
            <CheckboxGrpInLine
              name="sub"
              label={'Sub'}
              defaultChecked={crewSheet?.hideSub || false}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, 'sub')}
            />
            <CheckboxGrpInLine
              name="description"
              label={'Description'}
              defaultChecked={crewSheet?.hideSubDesc || false}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, 'description')}
            />
            <CheckboxGrpInLine
              name="jobNo"
              label={'Job no.'}
              defaultChecked={crewSheet?.hideJobNo || false}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, 'jobNo')}
            />
            <CheckboxGrpInLine
              name="subNo"
              label={'Sub no.'}
              defaultChecked={crewSheet?.hideSubNo || false}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, 'subNo')}
            />
            <CheckboxGrpInLine
              name="foExtra"
              label={'FO/Extra'}
              defaultChecked={crewSheet?.hideFoExtra || false}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, 'foExtra')}
            />
            <CheckboxGrpInLine
              name="phase"
              label={'Phase'}
              defaultChecked={crewSheet?.hidePhase || false}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, 'phase')}
            />
            <CheckboxGrpInLine
              name="costCode"
              label={'Cost code'}
              defaultChecked={crewSheet?.hideCostCode || false}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, 'costCode')}
            />
            <CheckboxGrpInLine
              name="earnings"
              label={'Earnings'}
              defaultChecked={crewSheet?.hideEarnings || false}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, 'earnings')}
            />
            <CheckboxGrpInLine
              name="hours"
              label={'Hours'}
              defaultChecked={crewSheet?.hideHours || false}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, 'hours')}
            />
            <CheckboxGrpInLine
              name="notes"
              label={'Notes'}
              defaultChecked={crewSheet?.hideNotes || false}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, 'notes')}
            />
            <CheckboxGrpInLine
              name="deduction"
              label={'Deduction'}
              defaultChecked={crewSheet?.hideDeductions || false}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, 'deductions')}
            />
          </div>
          <div className="d-flex mt-2 justify-content-end">
            <button
              className="orange-outline-button-sm mr-2"
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              className="orange-button-sm"
              onClick={onSave}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ColumnSettingsModal;