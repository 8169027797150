import React, { useEffect, useState } from 'react';
import Modal from 'core/components/modals/Modal';
import Table from 'core/components/shared/dm-table/Table';
import { ColumnHeaderData, Row } from 'core/components/shared/dm-table/types';
import { Dropdown } from 'core/models';
import { getFederalBlsSocCodes } from 'core/store/selectors';
import { useSelector } from 'react-redux';

const buildJobNoRow = (dropdown: Dropdown): Row => {
  return {
    id: dropdown.id,
    cells: [
      { children: dropdown.code, styleClass: 'td-20' },
      { children: dropdown.description, styleClass: 'td-80' },
    ],
  };
};

const jobNoColumns: ColumnHeaderData[] = [
  { title: 'Code', styleClass: 'th-20' },
  { title: 'Description', styleClass: 'th-80' },
];

type Props = {
  show: boolean;
  onHide: () => void;
  setControlledField: (newValue: string | number) => void;
};

const FederalBLSSOCCodeModal = ({ show, onHide, setControlledField }: Props) => {
  const federalBlsSocCodeOpts = useSelector(getFederalBlsSocCodes);

  const [filteredOptions, setFilteredOptions] = useState<Dropdown[]>(federalBlsSocCodeOpts);

  useEffect(() => {
    const options = [...federalBlsSocCodeOpts];
    options.unshift({ id: '0', description: '' } as Dropdown);
    setFilteredOptions(options);
  }, federalBlsSocCodeOpts);
  
  return (
    <Modal
      title="Change Federal BLS SOC Code"
      show={show}
      onHide={onHide}      
    >
      <div className="d-flex flex-column">
        <Table
          columns={jobNoColumns}
          rows={filteredOptions?.map((job) => buildJobNoRow(job))}
          bodyClass="tbody-fit"
          modules={['Search']}
          onRowClick={(id) => {
            setControlledField(id);
            onHide();
          }}
        />
      </div>
    </Modal>
  );
};

export default FederalBLSSOCCodeModal;