import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loadEmployeePayRatesScreen } from 'core/store/actions';
import CurrentPayRates from 'core/components/shared/CurrentPayRates';

type RouteParams = {
  protectedEmpNo: string;
};

const PayRatePage = () => {
  const dispatch = useDispatch();
  const { protectedEmpNo } = useParams<RouteParams>();
  
  const [dirtyState, setDirtyState] = useState<boolean>(false);
  
  const updateDirtyState = (newVal: boolean) => {
    setDirtyState(newVal);
  };

  useEffect(() => {
    dispatch(loadEmployeePayRatesScreen(protectedEmpNo));
  }, [protectedEmpNo]);

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <CurrentPayRates
          dirtyState={dirtyState}
          updateDirtyState={updateDirtyState}
          protectedEmpNo={protectedEmpNo}
        />
      </div>
    </div>
  );
};

export default PayRatePage;
