import React from 'react';
import styles from './form-controls.module.scss';
import { RadioOptions } from './RadioGrp';

const defaultRadioOptions = [
  { value: 'true', label: 'yes' },
  { value: 'false', label: 'no' },
];

interface RadioYesNoProps {
  id?: string;
  name: string;
  radioOptions?: RadioOptions[];
  stacked: boolean;
  defaultChecked?: RadioOptions;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioGrpStacked = React.forwardRef<
HTMLInputElement,
RadioYesNoProps
>(({ id, name, radioOptions = defaultRadioOptions, stacked, defaultChecked, onChange }, ref) => {
  id = id ?? name;
  return (
    <>
      {radioOptions.map((item: any, index: number) => {
        return (
          <div
            className={`form-check ${stacked ? 'd-block' : 'd-inline-block'
            }`}
            key={index}
          >
            <input
              id={`${id}${index}`}
              type="radio"
              name={name}
              ref={ref}
              className={styles['radio-input']}
              defaultValue={item.value}
              defaultChecked={(defaultChecked?.value === item.value) ? true : false}
              onChange={onChange}
            />{' '}
            <label
              className={styles['radio-label']}
              htmlFor={`${id}${index}`}
            >
              {item.label}
            </label>
          </div>
        );
      })}
    </>
  );
});

RadioGrpStacked.displayName = 'RadioGrpStacked';
