import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpResponse, OtherDate } from '../../models';
import { OtherDateService } from '../../services';
import {
  handleError,
  createOtherDate,
  deleteOtherDate,
  loadOtherDate,
  loadOtherDates,
  storeOtherDate,
  storeOtherDates,
  updateOtherDate,
  handleSuccess,
  triggerEmFieldValidation,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadOtherDates$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadOtherDates.type),
    switchMap((action: { payload: string; }) => {
      return from(OtherDateService.getOtherDates(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: OtherDate[]) => { return storeOtherDates(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadOtherDate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadOtherDate.type),
    switchMap((action: { payload: OtherDate; }) => {
      return from(OtherDateService.getOtherDate(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: OtherDate) => { return storeOtherDate(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createOtherDate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createOtherDate.type),
    switchMap((action: { payload: OtherDate; }) => {
      return from(OtherDateService.postOtherDate(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<OtherDate>) => {
          return [
            storeOtherDate(res.value),
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'dates',
              actionType: createOtherDate.type,
              callerPayload: res.value,
            }),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateOtherDate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateOtherDate.type),
    switchMap((action: { payload: OtherDate; }) => {
      return from(OtherDateService.putOtherDate(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<OtherDate>) => {
          return [
            storeOtherDate(res.value),
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'dates',
              actionType: updateOtherDate.type,
              callerPayload: res.value,
            }),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteOtherDate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteOtherDate.type),
    switchMap((action: { payload: OtherDate; }) => {
      return from(OtherDateService.deleteOtherDate(action.payload)).pipe(
        mergeMap(() => {
          return [
            loadOtherDates(action.payload.protectedEmpNo),
            handleSuccess(`Other date ${action.payload.otherDateID} deleted successfully`),
            triggerEmFieldValidation({
              section: 'dates',
              actionType: deleteOtherDate.type,
              callerPayload: action.payload,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};
export const epics: any[] = [
  loadOtherDates$,
  loadOtherDate$,
  createOtherDate$,
  updateOtherDate$,
  deleteOtherDate$,
];
