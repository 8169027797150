import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MultiplePayStub, PayStub } from '../../models/PayStub.model';
import { clearPayStubPDF, handleError, loadPayStubPDF, loadPayStubs, postDownloadMultipleFiles } from '../../store/actions';
import {
  getClientOptionRequirePassword,
  getPayStubPDF,
  getPayStubsForAccordion,
  getSelectedEmp,
} from '../../store/selectors';
import FileItem from '../form-controls/nested-accordion/FileItem';
import Level1Toggle from '../form-controls/nested-accordion/Level1Toggle';
import Level2Toggle from '../form-controls/nested-accordion/Level2Toggle';
import NestedAccordion from '../form-controls/nested-accordion/NestedAccordion';
import { NestedAccordionItem } from '../form-controls/nested-accordion/NestedAccordionItem';
import Modal from './Modal';
import PDFViewerModal from './pdf-viewer.modal';
import DownloadIcon from '@mui/icons-material/Download';
import { EmployeeService } from 'core/services';
import { useAppDispatch } from 'utilities/hooks';
import PasswordModal from './Password.modal';
import LoadingBar from '../shared/LoadingBar';
import { fileDownloadHeaderButton } from '../shared/FileDownload';

const toggleComponents: any[] = [
  Level1Toggle,
  Level2Toggle,
  Level2Toggle,
  FileItem,
];

type PropTypes = {
  show: boolean;
  onHide: () => void;
};

const PayStubsModal: React.FC<PropTypes> = ({ show, onHide }) => {
  const [showPDF, setShowPDF] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showLoadingBar, setShowLoadingBar] = useState<boolean>(false);
  const [selectedPayStubs, setSelectedPayStubs] = useState<PayStub[]>([]);
  const requirePassword = useSelector(getClientOptionRequirePassword);
  const dispatch = useAppDispatch();
  const employee = useSelector(getSelectedEmp);
  const empPayStubs: NestedAccordionItem[] = useSelector(
    getPayStubsForAccordion,
  );
  // TODO make this 2 separate selectors so effect doesn't keep firing.
  const pdfData: { name: string, data: string, bdata: string } = useSelector(getPayStubPDF);

  useEffect(() => {
    if (!employee) {
      return;
    }

    dispatch(loadPayStubs(employee.protectedEmpNo));
  }, [dispatch, employee]);

  const onSelectPayStub = (ps: PayStub) => {
    dispatch(loadPayStubPDF(ps));
    setShowPDF(true);
  };

  const onCheckPayStub = (ps: PayStub) => {
    if(selectedPayStubs.includes(ps)) setSelectedPayStubs(selectedPayStubs.filter(x => x.mescID !== ps.mescID));
    else setSelectedPayStubs([...selectedPayStubs, ps]);
  };

  const onClick = () => {
    if(!selectedPayStubs) return dispatch(handleError('Error While Downloading File(s)'));
    if(requirePassword) return setShowPasswordModal(true);
    setShowLoadingBar(true);

    const request : MultiplePayStub = {
      usesPassword: false,
      password: '',
      payStubs: selectedPayStubs,
      protectedEmpNo: selectedPayStubs[0].protectedEmpNo
    };

    dispatch(postDownloadMultipleFiles(() => {return EmployeeService.postMultiplePayStubs(request);})).then(() => {dispatch(setShowLoadingBar(false));});
  };

  const addPasswordToPdf = (passWord: string) => {
    if(!selectedPayStubs) return dispatch(handleError('Error While Downloading File(s)'));
    setShowLoadingBar(true);

    const request : MultiplePayStub = {
      usesPassword: true,
      password: passWord,
      payStubs: selectedPayStubs,
      protectedEmpNo: selectedPayStubs[0].protectedEmpNo
    };
    dispatch(postDownloadMultipleFiles(() => {return EmployeeService.postMultiplePayStubs(request);})).then(() => {dispatch(setShowLoadingBar(false));});
  };

  return (
    <>
    <Modal
      show={show}
      onHide={onHide}
      title="PAY STUBS"
      headerSlot={fileDownloadHeaderButton(onClick, selectedPayStubs, 'Download Selected Pay Stubs')}
    >
      {empPayStubs.map((item, index: number) => {
        return (
          <NestedAccordion
            key={item.id}
            level={1}
            item={item}
            toggleComponents={toggleComponents}
            onClick={onSelectPayStub}
            onCheckBoxClick={onCheckPayStub}
            showCheckBox={true}
            defaultActiveKey={(index === 0) ? item.id : ''}
          />
        );
      })}
      {showPDF && pdfData && (
        <PDFViewerModal
          show={showPDF}
          onHide={() => {
            setShowPDF(false);
            dispatch(clearPayStubPDF());
          }}
          pdfData={pdfData.data}
          reportName="PayStubs"
        />
      )}
    </Modal>
    <PasswordModal
      show={showPasswordModal}
      onHide={() => {setShowPasswordModal(false);}}
      addPasswordToPdf={addPasswordToPdf}
    />
    <LoadingBar
      showLoadingBar={showLoadingBar}
      loadingMessage={'Downloading Pay Stubs...'}  
    />
  </>
  );
};
export default PayStubsModal;
