import React from 'react';
import {
  ControlDatePickerGrp,
  InputGrpCurrency,
  InputGrpDecimal,
  SelectModalGrp,
} from '../../../core/components/form-controls';
import { HREducation } from '../../../core/models/HREmployee.model';
import { ArrayField } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  getEducationDegreeCodes,
  getEducationMajorCodes,
  getEducationSchools,
} from '../../../core/store/selectors';
import DropdownOptionForm from '../../../core/components/form-controls/select-modal/DropdownOptionForm';
import SchoolOptionForm from '../../../core/components/form-controls/select-modal/SchoolOptionForm';
import { CURRENCY_VALIDATION } from '../../../core/constants';

import Icon from 'core/components/shared/Icon';

type PropTypes = {
  item: Partial<ArrayField<HREducation>>;
  index: number;
  control: any;
  formMethods: any,
  onDelete: (item: HREducation) => void;
};

const EducationItem: React.FC<PropTypes> = ({
  item,
  index,
  control,
  formMethods: { errors, setValue, register },
  onDelete,
}) => {

  const educationDegreeCodes = useSelector(getEducationDegreeCodes);
  const educationMajorCodes = useSelector(getEducationMajorCodes);
  const educationSchools = useSelector(getEducationSchools);

  return (

    <div className="d-flex flex-column">
      <div className="d-flex flex-wrap">
        <input
          type="hidden"
          name={`educationItems[${index}].educationId`}
          defaultValue={item.educationId}
          ref={register({ valueAsNumber: true })}
        />

        <SelectModalGrp
          noOption={true}
          groupClass="gc12 mw125"
          modalTitle="DEGREE"
          label={'DEGREE'}
          formComponent={DropdownOptionForm}
          addOptionText="Degree"
          name={`educationItems[${index}].degreeTypeId`}
          dropdownName="EducationDegreeCodes"
          options={educationDegreeCodes}
          control={control}
          value={item.degreeTypeId}
          setValue={setValue}
        />

        <SelectModalGrp
          noOption={true}
          groupClass="gc12 mw125"
          modalTitle="SCHOOL"
          label={'SCHOOL'}
          labelField={'name'}
          valueField={'schoolId'}
          formComponent={SchoolOptionForm}
          addOptionText="School"
          name={`educationItems[${index}].schoolId`}
          dropdownName="EducationSchools"
          options={educationSchools}
          control={control}
          value={item.schoolId}
          setValue={setValue}
        />

        <SelectModalGrp
          noOption={true}
          groupClass="gc12 mw125"
          modalTitle="MAJOR"
          label={'MAJOR'}
          formComponent={DropdownOptionForm}
          addOptionText="Major"
          name={`educationItems[${index}].majorId`}
          dropdownName="EducationMajorCodes"
          options={educationMajorCodes}
          control={control}
          value={item.majorId}
          setValue={setValue}
        />

        <ControlDatePickerGrp
          name={`educationItems[${index}].enrollmentDate`}
          label="ENROLLMENT DATE"
          groupClass="gc12 mw125"
          value={item.enrollmentDate}
          setValue={setValue}
          control={control}
        />
        <ControlDatePickerGrp
          name={`educationItems[${index}].graduateDate`}
          label="GRADUATION DATE"
          groupClass="gc12 mw125"
          value={item.graduateDate}
          setValue={setValue}
          control={control}
        />
        <InputGrpDecimal
          name={`educationItems[${index}].gpa`}
          defaultValue={item.gpa}
          label="GPA"
          groupClass="gc12 mw75"
          errors={errors.educationItems?.[index]?.gpa}
          ref={register({
            valueAsNumber: true,
            maxLength: {
              value: 4,
              message: 'Max length is 4 chars',
            },
            max: {
              value: 5,
              message: 'Max value is 5',
            },
          })}
        />
        <InputGrpCurrency
          name={`educationItems[${index}].employerCost`}
          defaultValue={item.employerCost}
          label="EMPLOYER COST"
          groupClass="gc12 mw100"
          errors={errors.educationItems?.[index]?.employerCost}
          setValue={setValue}
          ref={register({
            CURRENCY_VALIDATION,
          })}
        />
        <InputGrpCurrency
          name={`educationItems[${index}].employeeCost`}
          defaultValue={item.employeeCost}
          label="EMPLOYEE COST"
          groupClass="gc12 mw100"
          setValue={setValue}
          errors={errors.educationItems?.[index]?.employeeCost}
          ref={register({
            CURRENCY_VALIDATION,
          })}
        />
      </div>
      <div className="text-right">
        <button
          type="button"
          className="btn btn-link dm-grid-action-title"
          onClick={() => { return onDelete(item as HREducation); }}
        >
          Delete Education
          <Icon name="minus-circle"
            className="fa-minus-circle" />
        </button>
      </div>
    </div>
  );
};

export default EducationItem;
