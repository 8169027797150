import {
  AgGridEvent,
  AllCommunityModules,
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  RowNodeTransaction,
  RowValueChangedEvent,
} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from '@ag-grid-community/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSubdepartment2,
  deleteSubdepartment2,
  loadSubdepartments2,
  updateSubdepartment2,
} from '../../../../core/store/actions';
import { getSubdepartments2 } from '../../../../core/store/selectors';
import {
  agInputNumberEditor,
  agSelectEditor,
} from '../../../../utilities/ag-grid-editors';
import { SubDepartment2 } from '../../../../core/models';
import Icon from 'core/components/shared/Icon';

type Props = {
  onHide: () => void;
  dirtyCallback: (isDirty: boolean) => void;
};

export const ManageSubDepartments2 = (props: Props): JSX.Element => {
  const dispatch = useDispatch();

  const columns = useMemo<ColDef[]>(
    () => {
      return [
        {
          field: 'Select',
          checkboxSelection: true,
          width: 70,
        },
        {
          field: 'sub2Code',
          headerName: 'Code',
          cellEditor: 'agInputNumberEditor',
          editable: (params) => { return !params.data.subDept2ID; },
        },
        {
          field: 'sub2Desc',
          headerName: 'Description',
          editable: true,
        },
        {
          field: 'alphaCode',
          headerName: 'Alpha Code',
          editable: true,
        },
        {
          field: 'glAccount',
          headerName: 'GL Account',
          editable: true,
        },
        {
          field: 'quickenClass',
          headerName: 'Quickbooks Class',
          editable: true,
        },
      ];
    },
    [],
  );

  // Selectors
  const departmentsInfo = useSelector(getSubdepartments2);

  // State
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [departments, setDepartments] = useState<SubDepartment2[]>([]);
  const [changedRows, setChangedRows] = useState<Set<string>>(new Set());

  const onGridReady = (ev: AgGridEvent) => {
    setGridApi(ev.api);
  };

  const onFirstDataRendered = (ev: FirstDataRenderedEvent) => {
    ev.api.sizeColumnsToFit();
  };

  const onSave = () => {
    changedRows.forEach((rowId) => {
      const row = gridApi!.getRowNode(rowId);
      if ((row?.data as SubDepartment2).subDept2ID !== 0) {
        dispatch(updateSubdepartment2(row?.data));
      } else {
        dispatch(createSubdepartment2(row?.data));
      }
    });
    setChangedRows(new Set());
  };

  const deleteSelected = () => {
    (gridApi?.getSelectedRows() as SubDepartment2[]).forEach((row) => {
      dispatch(deleteSubdepartment2(row.subDept2ID));
    });
  };

  const onCellValueChanged = (ev: RowValueChangedEvent) => {
    addNewChangedRows(ev.node.id || '');
  };

  const addNew = () => {
    const nodeInfo = gridApi?.applyTransaction({
      add: [{
        subDept2ID: 0,
        sub2Code: 0,
      }] as SubDepartment2[],
    }) as RowNodeTransaction;
    addNewChangedRows(nodeInfo.add[0].id ?? '');
    gridApi?.ensureIndexVisible(nodeInfo.add[0].rowIndex, 'bottom');
  };

  const addNewChangedRows = (rowId: string) => {
    const current = changedRows;
    current.add(rowId);
    setChangedRows(new Set(current));
  };

  const closeModal = () => {
    props.onHide();
  };

  useEffect(() => {
    dispatch(loadSubdepartments2());
  }, []);

  useEffect(() => {
    props.dirtyCallback(changedRows.size > 0);
  }, [changedRows]);

  useEffect(() => {
    setDepartments(
      departmentsInfo.map((a) => {
        return { ...a };
      }),
    );
  }, [departmentsInfo]);

  return (
    <>
      <div className="row mb-3 align-items-end">
        <div className="col mr-auto">
          <h2 className="dm-page-title">Manage Sub-Department 2</h2>
        </div>
        <div className="col-auto">
          <button
            type="button"
            className="btn btn-link dm-grid-action-title mr-4"
            onClick={() => { return addNew(); }}
          >
            Add New
            <Icon name="plus-circle"
              className="fa-plus-circle" />
          </button>
        </div>
        <div className="col-sm-12">
          <hr className="hr-2" />
        </div>
      </div>
      <div
        className="ag-theme-balham mb-3"
        style={{
          height: '50vh',
          width: '100%',
        }}
      >
        <AgGridReact
          rowData={departments}
          rowSelection="multiple"
          modules={AllCommunityModules}
          stopEditingWhenCellsLoseFocus={true}
          defaultColDef={{
            suppressMenu: true,
            resizable: true,
          }}
          columnDefs={columns}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          singleClickEdit={true}
          components={{
            agInputNumberEditor,
            agSelectEditor,
          }}
          onCellValueChanged={onCellValueChanged}
        ></AgGridReact>
      </div>

      <div className="row">
        <div className="col mr-auto">
          <button
            type="button"
            className="btn btn-link"
            onClick={() => { return deleteSelected(); }}
          >
            Delete Selected
          </button>
        </div>
        <div className="col-auto">
          <button
            type="button"
            className="btn btn-primary orange-outline-button mr-3"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary orange-button"
            onClick={onSave}
            disabled={changedRows.size === 0}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};
