import { AxiosResponse } from 'axios';
import {
  PayrollAdjustmentEmployee,
  PayrollAdjustmentSummary,
  PayrollAdjustmentEmployeeVoid,
  HttpResponse,
  PayrollAdjustmentCheckRequest,
  VerifyResult,
} from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getPayrollAdjustments = (
  payrollHistoryId: number,
): Promise<AxiosResponse<PayrollAdjustmentEmployee[]>> => {
  return API_EMPLOYEE_MASTER.get<PayrollAdjustmentEmployee[]>(
    EmployeeEndPoints.PAYROLL_ADJUSTMENTS(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
    ),
  );
};

const getEmployeePayrollAdjustment = (
  payrollHistoryId: number,
  empNo: string,
): Promise<AxiosResponse<PayrollAdjustmentEmployee>> => {
  return API_EMPLOYEE_MASTER.get<PayrollAdjustmentEmployee>(
    EmployeeEndPoints.PAYROLL_ADJUSTMENTS_EMPLOYEE(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
      empNo,
    ),
  );
};

const getPayrollAdjustmentNewCheck = (
  payrollHistoryId: number,
  protectedEmpNo: string,
): Promise<AxiosResponse<PayrollAdjustmentEmployee>> => {
  return API_EMPLOYEE_MASTER.get<PayrollAdjustmentEmployee>(
    EmployeeEndPoints.PAYROLL_ADJUSTMENTS_EMPLOYEE_NEWCHECK(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
      protectedEmpNo,
    ),
  );
};

const postPayrollAdjustmentCheckValidate = (
  data: PayrollAdjustmentSummary,
  payrollHistoryId: number,
  empNo: string,
): Promise<AxiosResponse<any>> => {
  return API_EMPLOYEE_MASTER.post<any>(
    EmployeeEndPoints.PAYROLL_ADJUSTMENTS_EMPLOYEE_CHECK_VALIDATE(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
      empNo,
    ),
    data,
  );
};

const postPayrollAdjustmentCheck = (
  data: PayrollAdjustmentSummary,
  payrollHistoryId: number,
  protectedEmpNo: string,
): Promise<AxiosResponse<any>> => {
  return API_EMPLOYEE_MASTER.post<any>(
    EmployeeEndPoints.PAYROLL_ADJUSTMENTS_EMPLOYEE_CHECKS(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
      protectedEmpNo,
    ),
    data,
  );
};

const postPayrollAdjustmentEmployeeVoids = (
  data: PayrollAdjustmentEmployeeVoid,
  payrollHistoryId: number,
  empNo: string,
): Promise<AxiosResponse<any>> => {
  return API_EMPLOYEE_MASTER.post<any>(
    EmployeeEndPoints.PAYROLL_ADJUSTMENTS_EMPLOYEE_VOIDS(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
      empNo,
    ),
    data,
  );
};

const putPayrollAdjustmentCheck = (
  request: PayrollAdjustmentCheckRequest,
): Promise<AxiosResponse<any>> => {
  return API_EMPLOYEE_MASTER.put<any>(
    EmployeeEndPoints.PAYROLL_ADJUSTMENTS_EMPLOYEE_CHECKS_ID(
      CommonService.getProtectedClientNo(),
      request.payrollHistoryId,
      request.protectedEmpNo,
      request.adjustmentId,
    ),
    request.data,
  );
};

const deletePayrollAdjustmentChecks = (
  payrollHistoryId: number,
  protectedEmpNo: string,
  adjustmentId: number,
): Promise<AxiosResponse<any>> => {
  return API_EMPLOYEE_MASTER.delete<any>(
    EmployeeEndPoints.PAYROLL_ADJUSTMENTS_EMPLOYEE_CHECKS_ID(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
      protectedEmpNo,
      adjustmentId,
    ),
  );
};

const postPrintAdjustmentCheck = (
  request: PayrollAdjustmentCheckRequest,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.PAYROLL_ADJUSTMENTS_PRINT(
      CommonService.getProtectedClientNo(),
      request.payrollHistoryId,
      request.protectedEmpNo,
      request.adjustmentId!,
    ),
  );
};

const postVerifyAdjustmentCheck = (
  payrollHistoryId: number,
): Promise<AxiosResponse<HttpResponse<VerifyResult>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<VerifyResult>>(
    EmployeeEndPoints.PAYROLL_VERIFY_ADJUSTMENT_CHECKS(
      CommonService.getProtectedClientNo(),
      payrollHistoryId
    ),
  );
};

export const PayrollAdjustmentService = {
  getPayrollAdjustments,
  getEmployeePayrollAdjustment,
  getPayrollAdjustmentNewCheck,
  postPayrollAdjustmentCheckValidate,
  postPayrollAdjustmentCheck,
  postPayrollAdjustmentEmployeeVoids,
  putPayrollAdjustmentCheck,
  deletePayrollAdjustmentChecks,
  postPrintAdjustmentCheck,
  postVerifyAdjustmentCheck,
};
