import { AgGridReact } from '@ag-grid-community/react';
import {
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
} from '@ag-grid-enterprise/all-modules';
import Modal from 'core/components/modals/Modal';
import { getJazzHrApplicants } from 'core/store/selectors';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'core/components/shared/Icon';
import { JazzHRApplicantDetail, JazzHRIntegrationRequest } from 'core/models';
import { addUpdateJazzHrIntegration } from 'core/store/actions';

type PropTypes = {
    show: boolean,
    clientNo: number,
    onHide: () => void;
    setSelectedJazzApplicant: React.Dispatch<React.SetStateAction<JazzHRIntegrationRequest | null>>
};

const OnboardJazzHRIntegrationModal: React.FC<PropTypes> = ({
    show,
    clientNo,
    onHide,
    setSelectedJazzApplicant,
}) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [, setGridColumnApi] = useState<ColumnApi | null>(null);
  const jazzHrApplicants = useSelector(getJazzHrApplicants);
  const dispatch = useDispatch();

  const onImport = (data: JazzHRApplicantDetail, isNewImport: boolean) => {
    const messageEnding = (isNewImport) ? '?' : ' again?';
    if(!confirm('Import the selected applicant' + messageEnding)) return;
      const request: JazzHRIntegrationRequest = {
          clientNo: clientNo,
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          address: data.address,
          applyDate: data.apply_date,
          isNewImport: isNewImport
        }
      dispatch(addUpdateJazzHrIntegration(request));
      setSelectedJazzApplicant(request);
      onHide();
  }

  const importColDef = (importName: string, isNewImport: boolean) => {
      const colDef = {
          field: 'import',
          headerName: importName,
          cellRenderer: (params: any) => {
          return (
              <>
              <button
                  type="button"
                  className="btn btn-link btn-block p-0"
                  onClick={() => { return onImport(params.data as JazzHRApplicantDetail, isNewImport) }
                  }
              >
                  {importName}<Icon
                  name="upload"
                  className="ml-2"
                   style={{
                      height: '15px',
                  }}
                  />
              </button>
              </>
            );
          },
          cellClass: 'ag-cell-border',
          width: 150,
        }
      return colDef;
    }
        

  const columns = (importName: string, isNewImport: boolean) => [
    {
        field: 'email',
        headerName: 'Email',
        sortable: true,
        editable: false,
        width: 260,
    },
    {
        field: 'first_name',
        headerName: 'First Name',
        sortable: true,
        editable: false,
        width: 230,
    },
    {
        field: 'last_name',
        headerName: 'Last Name',
        sortable: true,
        editable: false,
        width: 230,
    },
    {
        field: 'location',
        headerName: 'Address',
        sortable: true,
        editable: false,
        width: 380,
    },
    {
        field: 'apply_date',
        headerName: 'Apply Date',
        sortable: true,
        editable: false,
        width: 210,
    },
    importColDef(importName, isNewImport)
  ];

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    columnDefs: columns("Import Applicant", true),
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const gridOptions2: GridOptions = {
      domLayout: 'autoHeight',
      columnDefs: columns("Import Again", false),
      defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
      },
  };
  
  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      animation={false}
      title="Jazz HR Import"
    >
      {(closeModal) => {
        return (
          <>
            <span className="dm-grid-action-title">Jazz HR Applicants:</span>
             <div className="table-wrapper-wrapper ag-theme-balham">
                <AgGridReact
                gridOptions={gridOptions}
                rowData={jazzHrApplicants?.applicantsToImport || []}
                onGridReady={onGridReady}
                />
            </div> 
            <br/>
            <span className="dm-grid-action-title">Previously Imported Jazz HR Applicants:</span>
            <div className="table-wrapper-wrapper ag-theme-balham">
                <AgGridReact
                gridOptions={gridOptions2}
                rowData={jazzHrApplicants?.applicantsAlreadyImported || []}
                onGridReady={onGridReady}
                />
            </div>
            <div className="row mt-3">
              <div className="col-12 text-right">
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button mr-2"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </>
        );
      }}
    </Modal>
  );
};
export default OnboardJazzHRIntegrationModal;
