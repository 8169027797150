import { Dropdown } from "core/models/Dropdown.model";
import { TO_ABBREVIATED, convertRegion } from "core/states";
import { createField } from "utilities/utilities";

//Will add a field record for each option in the options that was passed in. 
export function MapDropdownOption(section: string, fieldName: string, dropDown: Dropdown[], isCodeField: boolean){
    return(dropDown.map((item) => {
      let filterCode = (isCodeField) ? item.code : item.id;

      //This is how the code was added for a normal select so copied that logic here REFACTOR in future to add abbriviation to dropdown maybe?
      if(section === 'Earnings State Taxes' && fieldName === 'Withholding')
          filterCode = convertRegion(item.description, TO_ABBREVIATED);

      return createField(fieldName, section, {
        filterId: 0,
        code: '' + filterCode,
        description: item.description as string,
      })
    }))
  }

  export function MapNonDropdown(section: string, fieldName: string, code: string, needsFilter: boolean, description: string | null = null){

    const filter = (needsFilter) ? {
      filterId: 0,
      code: '' + code,
      description: description,
    } : null;

    return createField(fieldName, section, filter)
  }