import React from 'react';
import { Col, Row } from 'react-bootstrap';
import GridSelect from '../../Shared/GridSelect';
import { useAppSelector } from 'utilities/hooks';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for Checkbox and Grid select forms
const TrainingSourcesFeesFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  const empSkillSourceCodesOptions = useAppSelector((state) => { return state?.dropdown.empSkillSourceCodes; });

  return (
    <Row>
      {!!sectionFields?.length && (
        <Col>
          <GridSelect
            section="Source and Fees"
            title="Source and Fees"
            isCodeField={false}
            dFields={sectionFields?.find(f => {
              return f.name === 'Source and Fees';
            })?.fields}
            options={empSkillSourceCodesOptions}
            hasCodeColumn={false}
            formMethods={formMethods}
          />
        </Col>
      )}
    </Row>
  );
};

export default TrainingSourcesFeesFields;