import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';
import { ColDef, GridOptions, MasterDetailModule } from '@ag-grid-enterprise/all-modules';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { agCheckboxRenderer, agCurrencyRenderer, agDateRenderer } from 'utilities/ag-grid-renderers';

//#region AgGrid

const columns: ColDef[] = [
  {
    field: 'employeeName',
    headerName: 'Employee',
    sortable: true,
    width: 160,
    cellRenderer: 'agGroupCellRenderer',
  },
  {
    field: 'dateSubmitted',
    headerName: 'Date Submitted',
    cellRenderer: 'dateRenderer',
    sortable: true,
    width: 120,
  }, {
    field: 'dateManaged',
    headerName: 'Date Managed',
    cellRenderer: 'dateRenderer',
    sortable: true,
    width: 120,
  }, {
    field: 'actionTaken',
    headerName: 'Action Taken',
    sortable: true,
    width: 130,
  }, {
    field: 'managedBy',
    headerName: 'Managed By',
    sortable: true,
    width: 150,
  }];

const gridOptions: GridOptions = {
  columnDefs: columns,
  domLayout: 'autoHeight',
  defaultColDef: {
    autoHeight: true,
    suppressMenu: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
  pagination: true,
  paginationPageSize: 10,
  masterDetail: true,
  detailRowAutoHeight: true,
  detailCellRendererParams: {
    detailGridOptions: {
      defaultColDef: {
        autoHeight: true,
        suppressMenu: true,
        resizable: true,
        cellClass: 'ag-cell-left-border',
      },
      columnDefs: [
        {
          field: 'accountType',
          headerName: 'Account Type',
          width: 120,
        },
        {
          field: 'financialInstitution',
          headerName: 'Financial Institution',
          width: 230,
        },
        {
          field: 'routingNo',
          headerName: 'Routing No',
          width: 120,
        },
        {
          field: 'bankAccountNo',
          headerName: 'Bank Account No',
          width: 120,
        },
        {
          field: 'active',
          headerName: 'Active',
          cellRenderer: 'checkboxRenderer',
          width: 70,
        },
        {
          field: 'amount',
          headerName: 'Amount',
          width: 120,
          cellStyle: { 'text-align': 'right' },
          cellRenderer: 'currencyRenderer',
        },
      ],
      // @ts-ignore
      components: {
        checkboxRenderer: agCheckboxRenderer,
        currencyRenderer: agCurrencyRenderer,
      },
    },
    getDetailRowData: function (params: any) {
      params.successCallback(params.data.details);
    },
  },
  // @ts-ignore
  components: {
    dateRenderer: agDateRenderer,
  },
};

type PropTypes = {
  items: any[];
};

const DirectDepositChangeHistory: React.FC<PropTypes> = ({ items }) => {
  return (
    <div>
      <Row>
        <Col
          xs={12}
          className="table-wrapper-wrapper ag-theme-balham"
        >
          <AgGridReact
            gridOptions={gridOptions}
            rowData={items}
            overlayNoRowsTemplate={
              '<span>No Changes to Display</span>'
            }
            modules={[MasterDetailModule]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DirectDepositChangeHistory;