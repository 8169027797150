import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { CustomAnswer, PageCustomField } from 'core/models';
import Icon from 'core/components/shared/Icon';
import { InputGrp, SelectGrp } from 'core/components/form-controls';
import Modal from 'core/components/modals/Modal';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { postATSCustomField } from 'core/store/slices/applicantTracking.slice';

const answerTypes = [
  {
    id: 'pre',
    description: 'Use Predefined Answers',
  },
  {
    id: 'let',
    description: 'Let Applicant Enter Answer',
  },
];

const webPageTypes = [
  {
    id: 1,
    description: 'Personal Information',
  },
  {
    id: 2,
    description: 'Employment History',
  },
  {
    id: 3,
    description: 'Education History',
  },
  {
    id: 4,
    description: 'Training History',
  },
];

type Props = {
  show: boolean;
  item: PageCustomField | undefined;
  webSiteConfigId: number;
  onHide: () => void;
};

const CustomFieldModal: React.FC<Props> = ({
  show,
  item,
  webSiteConfigId,
  onHide,
}) => {
  
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => {return state.auth.userAccess;});
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [answerType, setAnswerType] = useState<string>(item?.hasPredefinedAnswers ? 'pre' : 'let');
  const [deletedAnswer, setDeletedAnswer] = useState<number>(0);
  const [webpageId, setwebpageId] = useState<number>(1);
  const { register, reset, handleSubmit, getValues, control, formState: { isDirty } } = useForm<PageCustomField>({
    defaultValues: {
      ...item,
      customFieldId: item?.customFieldId ?? 0,
    },
  });

  const { fields, remove, append } =
    useFieldArray<CustomAnswer>({
      control,
      name: 'customAnswers',
      keyName: 'id',
    });

  useEffect(() => {
    item && reset({ ...item,
      customAnswers: item.customAnswers });
  }, [item]);

  const onAdd = () => {
   
    const answer: CustomAnswer = {
      customAnswerId: 0,
      customFieldId: item?.customFieldId ?? 0,
      label: '',
      name: '',
      visible: false,
      required: false,
      description: '',
    };

    append(answer, true);
  };

  const hideModal = () => {
    if (isDirty) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };

  const onSubmit = (data: PageCustomField) => {

    const cf:PageCustomField = item ? {
      ...item, 
      customAnswers: answerType === 'pre' ? data.customAnswers : [],
      pageId: webpageId,
      modifyUser: user?.dmUserName ?? '',
      description: data.description,
      hasPredefinedAnswers: answerType === 'pre',
    } : {
      customFieldId: 0,
      pageId:webpageId,
      webSiteConfigId: data.webSiteConfigId,      
      description: data.description,
      visible: true,
      required: true,
      hasPredefinedAnswers: answerType === 'pre',
      archived: false,
      alreadyAnswered: false,
      modifyUser:user?.dmUserName ?? '',     
      customAnswers:  answerType === 'pre' ? data?.customAnswers || [] : [],
      customFieldQA: [],
    };   

    dispatch(postATSCustomField(cf));
    reset(cf);
    setTimeout(()=> {return onHide();}, 1000);
  };

  const onDelete = (index:number) => {
    index && setDeletedAnswer(index);
    setShowDeleteConfirmationModal(true);
  };

  const confirmDelete = (confirm: boolean) => {
    if (confirm) {
      item?.alreadyAnswered ?
        onArchiveField() : onDeleteAnswer();
    }
  };

  const onArchiveField = () => {
    const fieldValues = getValues() as PageCustomField;
    const cf:PageCustomField = {
      ...item,
      ...fieldValues,
      archived: true,
    };
    dispatch(postATSCustomField(cf));
  };

  const onDeleteAnswer = ()=> {
    remove(deletedAnswer);
  };

  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };

  return (

    <Modal
      show={show}
      onHide={hideModal}
      dialogClassName="lg"
      isDirty={isDirty}
      backdrop={'static'}
      title="Add Custom Field"
    >

      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="d-flex flex-column flex-grow-1"
        >
          <input
            type="hidden"
            name={'customFieldId'}
            defaultValue={item?.customFieldId ?? 0}
            ref={register({ valueAsNumber: true })}
          />
          <input
            type="hidden"
            name={'webSiteConfigId'}
            defaultValue={webSiteConfigId}
            ref={register({ valueAsNumber: true })}
          />
          <div>
            <InputGrp
              label={'Field Name'}
              name={'description'}
              defaultValue={item?.description}
              ref={register}
            />
          </div>
          <div>
            <SelectGrp             
              label="Answer Type"              
              onChange={(e: React.BaseSyntheticEvent) => { 
                return setAnswerType(e.target.value); 
              }}
              defaultValue={answerType}
              options={answerTypes}
            />
          </div>
          <div>
            <SelectGrp             
              label="Web Page"              
              onChange={(e: React.BaseSyntheticEvent) => { 
                return setwebpageId(+e.target.value); 
              }}
              options={webPageTypes}
            />
          </div>
        </div>
        {answerType === 'pre' && (
          <>
            <div className="d-flex flex-row justify-content-end">
              <button
                type="button"
                className="btn btn-link dm-grid-action-title pb-0 mr-2"
                onClick={onAdd}
              >
                Add Custom Answer{' '}
                <Icon
                  name="plus-circle"
                  className="fa-plus-circle"
                />
              </button>
            </div>
            <div className="d-flex flex-column  flex-grow-1 mb-3">
              {fields?.map((a, index) => {
                return (                 
                  <div
                    key={a?.id}
                    className="d-flex flex-row flex-grow-1"
                  >
                    <input
                      type="hidden"
                      name={`customAnswers[${index}].customAnswerId`}
                      defaultValue={a.customAnswerId}
                      ref={register({ valueAsNumber:true })}
                    />
                    <input
                      type="hidden"
                      name={`customAnswers[${index}].customFieldId`}
                      defaultValue={a.customFieldId}
                      ref={register({ valueAsNumber:true })}
                    />
                    <input
                      type="hidden"
                      name={`customAnswers[${index}].label`}
                      defaultValue={a.label}
                      ref={register()}
                    />
                    <input
                      type="hidden"
                      name={`customAnswers[${index}].name`}
                      defaultValue={a.name}
                      ref={register()}
                    />                   
                    <InputGrp
                      groupClass=" flex-grow-1"
                      inputClass="w-100"
                      label={''}
                      name={`customAnswers[${index}].description`}
                      defaultValue={a.description}
                      ref={register()}
                    />
                    {!item?.alreadyAnswered && (
                      <button
                        type="button"
                        className="btn btn-link dm-grid-action-title pb-0 mr-auto"
                        onClick={()=>{return onDelete(index);}}
                      >             
                        <Icon
                          name="minus-circle"
                          className="fa-minus-circle"
                        />
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        )}
       
        <div className="d-flex flex-row justify-content-end mt-3">
          {!item?.alreadyAnswered && (  <button
            type="button"
            className="btn btn-link ml-auto mr-2"
            onClick={onArchiveField}
          >
            Archive Field
          </button>
          )}
          <button
            type="button"
            className="orange-outline-button mr-2"
            onClick={hideModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="orange-button mr-2"
          >
            Save
          </button>
        </div>
      </form>
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
      {showDeleteConfirmationModal && (
        <ConfirmationModal
          title={item?.alreadyAnswered ? 'Archive Custom Field' : 'Delete Custom Answer'}
          message={`Are you sure you want to ${item?.alreadyAnswered ? 'Archive this field?' : 'Delete this answer?'}`}
          show={showDeleteConfirmationModal}
          onConfirmed={confirmDelete}
          onHide={() => { return setShowDeleteConfirmationModal(false); }}
        />
      )}
    </Modal>
  );
};
export default CustomFieldModal;
