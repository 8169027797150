import React from 'react';
import styles from './time-card-styles.module.scss';
import ContractorTimeSheet from './ContractorTimeSheet';
import { TimeCardDetail } from 'core/models';
import { useAppSelector } from 'utilities/hooks';

type Props = {
  timeEntries: TimeCardDetail[];
  onDeleteTimeEntry: (id: string | undefined) => void;
};

const TimeEntryList = ({ timeEntries, onDeleteTimeEntry }: Props) => {
  const isBiWeeklyClient = useAppSelector(({ client }) => client.client?.clientPayrollFreq)?.toLowerCase() === 'bi-weekly';
  
  return (
    <div className={`${isBiWeeklyClient ? styles['biweekly-list'] : styles['time-card-list']}`}>
      {!timeEntries?.length ? (
        <div className="add-entry">
          Click &ldquo;Add Time Entry&rdquo;
        </div>
      ) : timeEntries?.map((timeSheet: TimeCardDetail, index) => (
        <ContractorTimeSheet
          key={timeSheet.transmittalTimeCardDetailId}
          index={index}
          timeSheet={timeSheet}
          deleteTimeSheet={onDeleteTimeEntry}
        />
      ))
      }
    </div>
  );
};

export default TimeEntryList;