import Icon from 'core/components/shared/Icon';
import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useAppSelector } from '../../utilities/hooks';

type Props = {
  visible: boolean;
};

const ImportantReminder: React.FC<Props> = (props: Props) => {
  const reminders =
    useAppSelector((state) => { return state.dashboard.reminders; }) ?? [];

  if (!props.visible) return null;
  return (
    <div className="dm-card">
      <div className="dm-card-body">
        <div className="dm-card-body-title text-uppercase">
          <Icon name="list" />
          &nbsp;
          <span>Important Reminders</span>
        </div>
        <Tabs id="uncontrolled-tab-example">
          {reminders.length
            ? reminders.map((item) => {
              return (
                <Tab
                  key={item.reminderId}
                  eventKey={item.reminderId}
                  title={item.subject}
                >
                  <div
                    className="mt-3"
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {item.body}
                  </div>
                </Tab>
              );
            })
            : null}
        </Tabs>
      </div>
    </div>
  );
};

export default ImportantReminder;
