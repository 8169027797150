import { createSelector } from 'reselect';

import { clientState } from '../reducers';
import { ClientState } from '../reducers/client.reducer';

export const getClient = createSelector(
  clientState,
  (state: ClientState) => { return state.client; },
);

export const getClientOptions = createSelector(
  clientState,
  (state: ClientState) => { return state.clientOptions.options; },
);

export const getClientOptionDefaultPayPeriod = createSelector(
  clientState,
  (state: ClientState) => {
    return {
      id: ' ',
      description: state.clientOptions
        ? state.clientOptions.options[10].optionValue
        : '',
    };
  },
);

export const getClientOptionIsContractor = createSelector(
  clientState,
  (state: ClientState) => {
    return state.clientOptions &&
      state.clientOptions.options &&
      state.clientOptions.options[387] &&
      state.clientOptions.options[387].optionValue
      ? state.clientOptions.options[387].optionValue.toLowerCase() ===
        'yes'
        ? true
        : false
      : false;
  },
);

export const getClientOptionRequirePassword = createSelector(
  clientState,
  (state: ClientState) => {
    return state.clientOptions &&
      state.clientOptions.options &&
      state.clientOptions.options[253] &&
      state.clientOptions.options[253].optionValue
      ? state.clientOptions.options[253].optionValue.toLowerCase() ===
        'yes'
        ? true
        : false
      : false;
  },
);

export const getYesNoCMOption = (optionId: number) => {
  return createSelector(clientState, (state: ClientState) => {
    return state?.clientOptions?.options?.[optionId]?.optionValue?.toLowerCase() === 'yes';
  });
};

export const getClientEnumOptions = createSelector(
  clientState,
  (state: ClientState) => { return state.enumOptions; },
);

export const getYear = createSelector(
  clientState,
  (state: ClientState) => { return state.year; },
);

export const get401KOptions = createSelector(
  clientState,
  (state: ClientState) => { return state.k401Options; },
);

export const getControlTotalsByOption = createSelector(
  clientState,
  (state: ClientState) => { return state?.clientOptions?.options?.[7]?.optionValue; },
);

export const getClientDeductions = createSelector(
  clientState,
  (state: ClientState) => { return state.clientDeductions; },
);

export const getClientGarnishmentDedNo = createSelector(
  clientState,
  (state: ClientState) => { return state.clientDeductions?.find(x => {return x.deductionTypeCode === 'G';})?.deductionNumber; },
);

export const getTaxEntityFips = createSelector(
  clientState,
  (state: ClientState) => { return state.clientTaxEntityFips; },
);

export const getFeedRequestsIntoTransmittal = createSelector(
  clientState,
  (state: ClientState) => { return state?.clientOptions?.options?.[2510]?.optionValue; },
);