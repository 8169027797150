import React, { CSSProperties, FunctionComponent, useCallback, useMemo, useState } from 'react';
import NestedAccordion from 'core/components/form-controls/nested-accordion/NestedAccordion';
import MenuItem from 'core/components/form-controls/nested-accordion/MenuItem';
import MenuToggle from 'core/components/form-controls/nested-accordion/MenuToggle';
import { data } from './SidebarData';
import styles from '../emp-detail.module.scss';
import { useAppSelector } from 'utilities/hooks';
import { getAccess } from 'utilities/utilities';
import { useSelector } from 'react-redux';
import { getClientAOD, getCompanyBenefitDeductionCodes } from 'core/store/selectors';
import { useBreakpoint } from 'utilities/BreakpointProvider';
import Icon from 'core/components/shared/Icon';
import HamburgerFab from 'core/components/shared/HamburgerFab';

type EmpDetailSidebarSubItem = {
  id: string;
  title: string;
  href: string;
  workItemId?: number;
  modal?: FunctionComponent<any>;
};
type EmpDetailSidebarItem = {
  id: string;
  title: string;
  children: EmpDetailSidebarSubItem[];
};

const toggleComponents: FunctionComponent<any>[] = [MenuToggle, MenuItem];

const localStyles: { [key: string]: CSSProperties } = {
  content: { margin: '0' },
  toggleContainer: { marginTop: '3px' },
  bodyContainer: { backgroundColor: 'white' },
};

const EmpDetailSidebar: React.FC = () => {
  const breakpoints = useBreakpoint();
  
  const sectionAccess = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections);
  const client = useAppSelector(({ client }) => client.client);
  const clientAodInfo = useSelector(getClientAOD);
  const dedCodeOpts = useSelector(getCompanyBenefitDeductionCodes);
  
  const [showNav, setShowNav] = useState<boolean>(false);
  
  const memoizedData = useMemo(() => { return data; }, [data]);
  
  const buildSidebar = useCallback((): EmpDetailSidebarItem[] => {
    if (!client) {
      console.error('Unable to build sibdebar data. Client not properly set in store');
      return [];
    }
    
    const securedData = memoizedData.map((item) => {
      const modifiedChildren: EmpDetailSidebarSubItem[] = [];
      item.children.map((subItem) => {
        if (subItem?.workItemId) {
          // hide AoD if client doesn't have "In Production Mode" option enabled in CM (CM --> (J) Integration --> (5) Attendance on Demand)
          if (subItem.workItemId === 44 && !clientAodInfo?.inProductionMode) return; 
          if (!client.clientName.match(/ADG/g) && ['4o', '4p'].includes(subItem.id)) return;
          if (!dedCodeOpts?.length && subItem.id === '4r') return; 
          
          const accessData = getAccess(sectionAccess, subItem.workItemId);
        
          if (accessData?.visible === false) return;
        }
        modifiedChildren.push(subItem);
      });
      
      return { ...item,
        children: modifiedChildren };
    });
  
    return securedData;
  }, [memoizedData]);
  
  const securedData = buildSidebar();
  
  return (
    <>
      {!securedData?.length ? null : (
          
        <div className={styles['ed-sidebar-container']}>
          {securedData.map((item) => {
            if (!item.children?.length) return;
            return (
              <NestedAccordion
                key={item.id}
                level={1}
                item={item}
                toggleComponents={toggleComponents}
                contentStyle={localStyles.content}
                toggleContainerStyle={localStyles.toggleContainer}
                bodyContainerStyle={localStyles.bodyContainer}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default EmpDetailSidebar;
