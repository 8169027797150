import {  AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpResponse } from '../models';
import { CommonService } from './common.service';
import { EmployeeReportsEndPoints, HumanCapitalReportEndpoints } from './URL';
import { API_EMPLOYEE_MASTER, API_CLIENT } from './API';
import {
  FullTimeEquivalentReportRequest,
  CumulativeSeniorityReportRequest,
  MyInfoDocStatusReportRequest,
  MyInfoDocStatusFile,
  EmployeeNavigatorAuditReportRequest,
  DemographicAndTurnoverReportRequest,
  DemographicAndTurnoverEmployee,
  DepartmentofLaborSetting,
  DepartmentofLaborRequest,
  BambooHRRequest,
  GetBambooHRRequest,
  WOTCReportRequest,
} from '../models/HumanCapitalReports.model';
import { EmployeeChangeReportRequest } from '../models/EmployeeReports.model';

const getClientReportOptions = () => {
  return API_EMPLOYEE_MASTER.get(HumanCapitalReportEndpoints.GET_CLIENT_REPORT_OPTIONS(CommonService.getProtectedClientNo()));
};

const postFullTimeEquivalentReportsTab = (request: FullTimeEquivalentReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    HumanCapitalReportEndpoints.POST_FULLTIME_EQUIVALENT_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postEmployeeChangeReportTab = (request: EmployeeChangeReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeReportsEndPoints.POST_EMP_CHANGE_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postCumulativeSeniorityReportsTab = (request: CumulativeSeniorityReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    HumanCapitalReportEndpoints.POST_CUMULATIVE_SENIORITY_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const getMyInfoDocStatusFiles = (
): Promise<AxiosResponse<MyInfoDocStatusFile[]>> => {
  return API_EMPLOYEE_MASTER.get<MyInfoDocStatusFile[]>(
    HumanCapitalReportEndpoints.MYINFO_DOC_STATUS(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const postEmployeeNavigatorAuditReportTab = (request: EmployeeNavigatorAuditReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    HumanCapitalReportEndpoints.POST_EMPLOYEE_NAVIGATOR_AUDIT_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postMyInfoDocStatusReport = (request: MyInfoDocStatusReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    HumanCapitalReportEndpoints.MYINFO_DOC_STATUS(
      CommonService.getProtectedClientNo()), request);
};

const postDepartmentofLaborReportsTab = (request: DepartmentofLaborRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    HumanCapitalReportEndpoints.POST_DEPARTMENT_OF_LABOR_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postDemographicAndTurnoverReport = (request: DemographicAndTurnoverReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    HumanCapitalReportEndpoints.POST_DEMOGRPAPHIC_AND_TURNOVER_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const getDemographicAndTurnoverEmployees = (
): Promise<AxiosResponse<DemographicAndTurnoverEmployee[]>> => {
  return API_EMPLOYEE_MASTER.get<DemographicAndTurnoverEmployee[]>(
    HumanCapitalReportEndpoints.DEMOGRPAPHIC_AND_TURNOVER_EMPLOYEES(
      CommonService.getProtectedClientNo()),
  );
};

const putDemographicAndTurnoverEmployee = (request: DemographicAndTurnoverEmployee): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<any>>(
    HumanCapitalReportEndpoints.DEMOGRPAPHIC_AND_TURNOVER_EMPLOYEES_ID(
      CommonService.getProtectedClientNo(), request.protectedEmpNo), request);
};

const getDepartmentofLaborSettings = (): Promise<AxiosResponse<any>> => {
  return API_EMPLOYEE_MASTER.get<DepartmentofLaborSetting[]>(
    HumanCapitalReportEndpoints.DEPARTMENT_OF_LABOR_SETTINGS(CommonService.getProtectedClientNo()));
};

const postDepartmentofLaborSettings = (data: DepartmentofLaborSetting): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(
    HumanCapitalReportEndpoints.ADD_DEPARTMENT_OF_LABOR_SETTINGS(CommonService.getProtectedClientNo()), data);
};

const putDepartmentofLaborSettings = (data: DepartmentofLaborSetting): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<any>>(
    HumanCapitalReportEndpoints.PUT_DEPARTMENT_OF_LABOR_SETTINGS(CommonService.getProtectedClientNo(), data.settingID), data);
};

const deleteDepartmentofLaborSettings = (data: DepartmentofLaborSetting): 
Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.delete<HttpResponse<any>>(
    HumanCapitalReportEndpoints.DELETE_DEPARTMENT_OF_LABOR_SETTINGS(CommonService.getProtectedClientNo(), data.settingID));
};

const postACAProofList = (payrollYear: number): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    HumanCapitalReportEndpoints.POST_ACAPROOFLIST_REPORT(CommonService.getProtectedClientNo(), payrollYear));
};

export const getEmpHours = (empNo: string, beginDate: string, endDate: string) => {
  return API_EMPLOYEE_MASTER.get(HumanCapitalReportEndpoints.MESC_ACA_HOURS(CommonService.getProtectedClientNo(), empNo, beginDate, endDate));
};

export const updateEmpHours = (empNo: string, payload: any) => {
  return API_EMPLOYEE_MASTER.put(HumanCapitalReportEndpoints.PUT_MESC_ACA_HOURS(CommonService.getProtectedClientNo(), empNo), payload);
};

export const getFullTimeCalculationReport = (year: number, beginDate: string, endDate: string) => {
  return API_EMPLOYEE_MASTER.get(HumanCapitalReportEndpoints.GET_FULL_TIME_CALCULATION_REPORT(CommonService.getProtectedClientNo(), year), {
    params: {
      beginDate,
      endDate,
    },
  });
};

export const postFullTimeCalculationReport = (request: any) => {
  return API_EMPLOYEE_MASTER.post(HumanCapitalReportEndpoints.POST_FULL_TIME_CALCULATION_REPORT(CommonService.getProtectedClientNo()), request);
};

export const getACADatesPerYear = (year: number) => {
  return API_CLIENT.get(
    HumanCapitalReportEndpoints.GET_ACA_DATES_PER_YEAR(CommonService.getProtectedClientNo(), year),
  );
};

export const updateACADatesPerYear = (request: any) => {
  return API_CLIENT.put(
    HumanCapitalReportEndpoints.UPDATE_ACA_DATES(CommonService.getProtectedClientNo(), request?.year),
    request,
  );
};

export const getACAYear = (year: number) => {
  return API_CLIENT.get(
    HumanCapitalReportEndpoints.GET_ACA_YEAR(CommonService.getProtectedClientNo(), year),
  );
};

const postBambooHrReport = (request: BambooHRRequest): Promise<
AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    HumanCapitalReportEndpoints.POST_BAMBOO_HR(CommonService.getProtectedClientNo()), request.bambooHrEmployeeComparison); 
};

const getBambooHrReport = (request: GetBambooHRRequest): Promise<AxiosResponse<any>> => { 
  const config: AxiosRequestConfig = {
    params: { clientNos: request.clientNos.toString() },
  };

  return API_EMPLOYEE_MASTER.get<HttpResponse<any>>(
    HumanCapitalReportEndpoints.GET_BAMBOO_HR(CommonService.getProtectedClientNo(), 
      request.showTerminated, 
      request.duplicatesOnly, 
      request.clientNos)); 
};

const postWOTCReport = (request: WOTCReportRequest): Promise<
  AxiosResponse<HttpResponse<string>>
> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    HumanCapitalReportEndpoints.POST_WOTC_REPORT(CommonService.getProtectedClientNo()), request,
  );
};

export const HumanCapitalReportService = {
  postFullTimeEquivalentReportsTab,
  postCumulativeSeniorityReportsTab,
  postEmployeeChangeReportTab,
  getMyInfoDocStatusFiles,
  postMyInfoDocStatusReport,
  postEmployeeNavigatorAuditReportTab,
  postDemographicAndTurnoverReport,
  getDemographicAndTurnoverEmployees,
  putDemographicAndTurnoverEmployee,
  postDepartmentofLaborReportsTab,
  getDepartmentofLaborSettings,
  putDepartmentofLaborSettings,
  deleteDepartmentofLaborSettings,
  postDepartmentofLaborSettings,
  postACAProofList,
  getACADatesPerYear,
  getBambooHrReport,
  postBambooHrReport,
  getClientReportOptions,
  postWOTCReport,
};

