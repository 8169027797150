import { AxiosResponse } from 'axios';
import {ReferenceFolder} from '../models/HelpReferenceGuide.model';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getReferenceGuide = (): Promise<AxiosResponse<ReferenceFolder>> => {
  return API_EMPLOYEE_MASTER.get<ReferenceFolder>(
    EmployeeEndPoints.GET_HELP_REFERENCE_GUIDE(CommonService.getProtectedClientNo()),
  );
};

export const HelpReferenceGuideService = {
    getReferenceGuide
};
