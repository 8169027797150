import React, { CSSProperties, useEffect, useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CheckboxGrpInLine, ControlDatePickerGrp, InputGrp, SelectGrp, SelectModalGrp } from 'core/components/form-controls';
import DepartmentOptionForm from 'core/components/form-controls/select-modal/DepartmentOptionForm';
import LocationOptionForm from 'core/components/form-controls/select-modal/LocationOptionForm';
import SubDepartment2OptionForm from 'core/components/form-controls/select-modal/SubDepartment2OptionForm';
import SubDepartmentOptionForm from 'core/components/form-controls/select-modal/SubDepartmentOptionForm';
import HistoryLabel from 'core/components/form-controls/HistoryLabel';
import { PHONE_VALIDATION, SSN_INFO_MESSAGE, SSN_VALIDATION, UNSAVED_MESSAGE, ControlIds } from 'core/constants';
import { DeptSubSub2TransmittalUpdate, Employee, ResendOnboardingEmailRequest } from 'core/models';
import { resendOnboardingEmail, toggleBlockNavigation, updateEmployee } from 'core/store/actions';
import { formatPhone, formatSSN, getAccess, getAllAccess } from 'utilities/utilities';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { getForeignCountries, getGenders, getLocationDepartmentsWithoutHome, getMaritalStatuses, getStates, getYesNoCMOption } from 'core/store/selectors';
import Icon from 'core/components/shared/Icon';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { PayrollService } from 'core/services';

const fs: FieldInputSettings = {
  firstName: { name: 'firstName' },
  midName: { name: 'midName' },
  lastName: { name: 'lastName' },
  suffix: { name: 'suffix' },
  sex: { name: 'sex' },
  address: { name: 'address' },
  city: { name: 'city' },
  state: { name: 'state' },
  zip: { name: 'zip' },
  zip4: { name: 'zip4' },
  ssn: { name: 'ssn' },
  ssnisappliedFor: {
    name: 'ssnisappliedFor',
    label: 'SSN Applied For',
    labelWidth: 100,
    groupClass: 'gc50 mw125 pt-3',
  },
  birthDate: { name: 'birthDate' },
  hireDate: { name: 'hireDate' },
  loc: { name: 'loc' },
  dept: { name: 'dept' },
  subDept: { name: 'subDept' },
  subDept2: { name: 'subDept2' },
  cellPhone: { name: 'cellPhone' },
  homePhone: { name: 'homePhone' },
  email: { name: 'email' },
  email2: { name: 'email2' },
  nickName: { name: 'nickName' },
  preferredPronouns: { name: 'preferredPronouns' },
};

const toolTipStyles: CSSProperties = { fontSize: '0.75rem' };

const hireDateInfoMessage = 'Hire Date changes must be made in the Additional Information > Dates screen.';

type PropTypes = {
  employee: Employee;
  showHistory: boolean;
  onCancel: () => void;
};

const EmpInfoEdit: React.FC<PropTypes> = ({
  employee,
  onCancel,
  showHistory,
}) => {
  const dispatch = useAppDispatch();

  const { locationOpts, deptOpts, subdeptOpts, subdept2Opts } = useSelector(
    getLocationDepartmentsWithoutHome,
  );
  const clientNo = useAppSelector(({ client }) => { return client.client?.clientNo; });
  const genderOpts = useSelector(getGenders);
  const stateOpts = useSelector(getStates);
  const foreignCountryOpts = useSelector(getForeignCountries);
  const maritalStatusOpts = useSelector(getMaritalStatuses);
  const sectionAccess = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections);
  const minEmpAge = useAppSelector(({ client }) => client.clientOptions?.options?.[2527]?.optionValue);
    
  const [usePrimary, setUsePrimary] = useState(true);
  const [showResendModal, setShowResendModal] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [isForeignAddress, setIsForeignAddress] = useState(employee.country !== 'US');
  
  const renderToolTip = (props: OverlayInjectedProps) => {
    return (
      <Tooltip
        id="info-tooltip"
        {...props}
      >
        <div style={toolTipStyles}>
          {emailMessage}
        </div>
      </Tooltip>
    );
  };

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    errors,
    control,
    reset,
    formState,
    watch,
  } = useForm<Employee>({
    defaultValues: {
      ...employee,
      ssn: formatSSN(employee.ssn),
      cellPhone: formatPhone(employee.cellPhone),
      homePhone: formatPhone(employee.homePhone),
      workPhone: formatPhone(employee.workPhone),
    },
  });
  const { isDirty, dirtyFields } = formState; 
  const { email: formEmail, email2: formEmail2, ssnisappliedFor } = watch(['email', 'email2', 'ssnisappliedFor']);
  
  //#region Module access definitions
  // get all fields' access configs
  const allAccess = getAllAccess(sectionAccess?.find((x) => x.workItemId === 1));
  // section(s)
  const empInfoSectionAccess = getAccess(sectionAccess, 1);
  const empDatesAccess = getAccess(sectionAccess, 7);
  // fields with special condiions
  const ssnAppliedForFieldAccess = getAccess(sectionAccess, 1, undefined, { disabledSameAsReadOnly: true });
  const birthDateFieldAccess = getAccess(sectionAccess, 1, ControlIds.birthDate, { disabledSameAsReadOnly: true });
  const hireDateInputFieldAccess = getAccess(sectionAccess, 1, ControlIds.hireDate, { disabledSameAsReadOnly: true, disabledDefault: employee.hireDate !== null });
  const saveButtonAccess = getAccess(sectionAccess, 1, undefined, { disabledDefault: !isDirty, disabledSameAsReadOnly: true });
  //#endregion
  
  useEffect(() => {
    reset({
      ...employee,
      ssn: formatSSN(employee.ssn),
      cellPhone: formatPhone(employee.cellPhone),
      homePhone: formatPhone(employee.homePhone),
      workPhone: formatPhone(employee.workPhone),
    });
  }, [employee]);
  
  useEffect(() => {
    switch (employee.onboardingStatus.toLowerCase()) {
      case 'onboarding':
        setEmailMessage('Resend Onboarding Credentials');
        setConfirmMessage("Are you sure you want to resend this employee's onboarding credentials using this email address?");
        break;
      case 'employee':
        setEmailMessage('Send MyInfo Login Info');
        setConfirmMessage(`You are about to send login information for myinfo.dmpayroll.com to ${usePrimary ? formEmail : formEmail2}. Continue?`);
        break;
      default:
        break;
    }
  }, [employee.onboardingStatus, formEmail, formEmail2, usePrimary]);
  
  const onSubmit = (data: Partial<Employee>, event?: React.BaseSyntheticEvent) => {
    if (!event?.target.isConnected) {
      return false;
    }

    const newDept = getValues('dept');
    const newSub = getValues('subDept');
    const newSub2 = getValues('subDept2');

    const newEmp = new Employee(employee.clientNo, employee.empNo, {
      ...employee,
      ...data,
    });
    const empAge = Math.abs((new Date().getFullYear()) - (new Date(newEmp?.birthDate ?? new Date()).getFullYear()));
    if (
      (!!newEmp?.birthDate)
      && empAge < Number(minEmpAge)
      && !confirm(`The birth date entered indicates the employee is below the minimum age of ${minEmpAge}. Continue?`)
    ) {
      return;
    }
    
    dispatch(updateEmployee(newEmp))
      .then((_res) => {
        //As long as one of the three changes then we call the endpoint else we dont have anything to update so we can skip it.
        if (employee.dept !== newDept || employee.subDept !== newSub || employee.subDept2 !== newSub2) {
          const newDeptSubSub2Update = new DeptSubSub2TransmittalUpdate(employee.empId, employee.empNo, {
            dept: newDept,
            sub: newSub,
            sub2: newSub2,
          });
      
          PayrollService.PutDeptSubSub2OnEmpChange(employee.protectedEmpNo, newDeptSubSub2Update).catch((err: any) => { return alert(err); });
        }
    
        //PI-8715: unrelated to the actual issue, but had to fix it. Not sure why it's b
        reset({
          ...employee,
          ssn: formatSSN(employee.ssn),
          cellPhone: formatPhone(employee.cellPhone),
          homePhone: formatPhone(employee.homePhone),
          workPhone: formatPhone(employee.workPhone),
        });

        onCancel();
      });
  };

  const onCountryChange = (e: React.BaseSyntheticEvent) => {
    setIsForeignAddress(e.target.value !== 'US');
  };
  
  const onPhoneChange = (e: React.BaseSyntheticEvent) => {
    const { value } = e.target;
    const caretEnd = e.target.selectionEnd;
    
    e.target.value = formatPhone(value, caretEnd);
  };
  
  const onResend = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, isPrimaryEmail: boolean) => {
    e.preventDefault();
    setUsePrimary(isPrimaryEmail);
    setShowResendModal(true);
    e.stopPropagation();
  };

  const onConfirmResend = (confirmed: boolean) => {
    if (confirmed && clientNo) {
      const request: ResendOnboardingEmailRequest = {
        clientNo: clientNo,
        protectedEmpNo: employee.protectedEmpNo,
        empNo: employee.empNo,
        empId: employee.empId,
        emailAddress: usePrimary ? formEmail : formEmail2,
        isOnboardingEmailType: employee.onboardingStatus.toLowerCase() === 'onboarding',
      };
      dispatch(resendOnboardingEmail(request));
    }
    setShowResendModal(false);
  };
  
  const onSsnIsAppliedFor = (e: any) => {
    setValue('ssn', e.nativeEvent.target.checked ? '000-00-0000' : formatSSN(employee.ssn));
    setValue('ssnisappliedFor', e.nativeEvent.target.checked);
  };
  
  const handleBlockUnsavedChanges = (newVal: boolean) => {
    dispatch(toggleBlockNavigation({
      block: newVal,
      type: 'confirmation',
      message: `${UNSAVED_MESSAGE}`,
    }));
  };
  
  useEffect(() => {
    handleBlockUnsavedChanges(Object.keys(dirtyFields).length > 0);
  }, [Object.keys(dirtyFields).length]);
  
  return !employee ? (
    <div>Missing Employee</div>
  ) : (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-sm-4">
            <Row>
              <Col xs={8}>
                <HistoryLabel
                  labelTitle="First Name"
                  section="employee"
                  field="firstName"
                  protectedEmpNo={employee.protectedEmpNo}
                  showHistory={showHistory}
                  required={allAccess?.[ControlIds.firstName]?.required}
                  visible={allAccess?.[ControlIds.firstName].visible}
                />
                <InputGrp
                  {...fs.firstName}
                  {...allAccess?.[ControlIds.firstName]}
                  required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.firstName]?.required}
                  errors={errors.firstName}
                  ref={register({
                    required: 'Required Field',
                  })}
                />
              </Col>
              <Col xs={4}>
                <HistoryLabel
                  labelTitle="Middle"
                  section="employee"
                  field="midName"
                  protectedEmpNo={employee.protectedEmpNo}
                  showHistory={showHistory}
                  required={allAccess?.[ControlIds.midName]?.required}
                  visible={allAccess?.[ControlIds.midName].visible}
                />
                <InputGrp
                  {...fs.midName}
                  {...allAccess?.[ControlIds.midName]}
                  required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.midName]?.required}
                  errors={errors.midName}
                  maxLength={1}
                  ref={register}
                />
              </Col>
            </Row>
          </div>
          <Col xs={4}>
            <Row>
              <Col xs={8}>
                <HistoryLabel
                  labelTitle="Last Name"
                  section="employee"
                  field="lastName"
                  protectedEmpNo={employee.protectedEmpNo}
                  showHistory={showHistory}
                  required={allAccess?.[ControlIds.lastName]?.required}
                  visible={allAccess?.[ControlIds.lastName].visible}
                />
                <InputGrp
                  {...fs.lastName}
                  {...allAccess?.[ControlIds.lastName]}
                  required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.lastName]?.required}
                  errors={errors.lastName}
                  ref={register}
                />
              </Col>
              <Col xs={4}>
                <HistoryLabel
                  labelTitle="Suffix"
                  section="employee"
                  field="suffix"
                  protectedEmpNo={employee.protectedEmpNo}
                  showHistory={showHistory}
                  required={allAccess?.[ControlIds.suffix]?.required}
                  visible={allAccess?.[ControlIds.suffix].visible}
                />
                <InputGrp
                  {...fs.suffix}
                  {...allAccess?.[ControlIds.suffix]}
                  required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.suffix]?.required}
                  errors={errors.suffix}
                  ref={register}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={4}>
            <HistoryLabel
              labelTitle="Gender"
              section="employee"
              field="sex"
              protectedEmpNo={employee.protectedEmpNo}
              showHistory={showHistory}
              required={allAccess?.[ControlIds.sex]?.required}
              visible={allAccess?.[ControlIds.sex].visible}
            />
            <SelectGrp
              {...fs.sex}
              {...allAccess?.[ControlIds.sex]}
              required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.sex]?.required}
              defaultValue={employee.sex}
              ref={register}
              errors={errors.sex}
              options={genderOpts}
            />
          </Col>
        </div>
        <Row>
          {(allAccess?.[ControlIds.preferredPronouns]?.visible ?? true) && (
            <Col xs={4}>
              <HistoryLabel
                labelTitle="Preferred Name"
                section="employee"
                field="nickName"
                protectedEmpNo={employee.protectedEmpNo}
                showHistory={showHistory}
                required={allAccess?.[ControlIds.nickName]?.required}
                visible={allAccess?.[ControlIds.nickName].visible}
              />
              <InputGrp
                {...fs.nickName}
                {...allAccess?.[ControlIds.nickName]}
                required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.nickName]?.required}
                errors={errors.nickName}
                ref={register}
              />
            </Col>
          )}
          <Col xs={4}>
            <HistoryLabel
              labelTitle="Pronouns"
              section="employee"
              field="preferredPronouns"
              protectedEmpNo={employee.protectedEmpNo}
              showHistory={showHistory}
              required={allAccess?.[ControlIds.preferredPronouns]?.required}
              visible={allAccess?.[ControlIds.preferredPronouns].visible}
            />
            <InputGrp
              {...fs.preferredPronouns}
              {...allAccess?.[ControlIds.preferredPronouns]}
              required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.preferredPronouns]?.required}
              errors={errors.preferredPronouns}
              ref={register}
            />
          </Col>
        </Row>
        <div className="row">
          <div className="col-12 col-sm-4 pr-2 pr-sm-0">
            <HistoryLabel
              labelTitle="Address Line 1"
              section="employee"
              field="address"
              protectedEmpNo={employee.protectedEmpNo}
              showHistory={showHistory}
              required={allAccess?.[ControlIds.address]?.required}
              visible={allAccess?.[ControlIds.address].visible}
            />
            <InputGrp
              {...fs.address}
              {...allAccess?.[ControlIds.address]}
              required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.address]?.required}
              errors={errors.address}
              ref={register}
            />
            <HistoryLabel
              labelTitle="City"
              section="employee"
              field="city"
              protectedEmpNo={employee.protectedEmpNo}
              showHistory={showHistory}
              required={allAccess?.[ControlIds.city]?.required}
              visible={allAccess?.[ControlIds.city].visible}
            />
            <InputGrp
              {...fs.city}
              {...allAccess?.[ControlIds.city]}
              required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.city]?.required}
              errors={errors.city}
              ref={register}
            />
            <div className="row">
              <div className="col-4 pr-0">
                <HistoryLabel
                  labelTitle={
                    isForeignAddress
                      ? 'State/Province'
                      : 'State'
                  }
                  section="employee"
                  field="state"
                  protectedEmpNo={employee.protectedEmpNo}
                  showHistory={showHistory}
                  required={allAccess?.[ControlIds.state]?.required}
                  visible={allAccess?.[ControlIds.state].visible}
                />
                {isForeignAddress ? (
                  <InputGrp
                    {...fs.state}
                    {...allAccess?.[ControlIds.state]}
                    required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.state]?.required}
                    errors={errors.state}
                    ref={register}
                  />
                ) : (
                  <SelectGrp
                    {...fs.state}
                    {...allAccess?.[ControlIds.state]}
                    required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.state]?.required}
                    errors={errors.state}
                    ref={register}
                    options={stateOpts}
                  />
                )}
              </div>
              <div className="col-8">
                <div className="d-flex flex-row">
                  <div>
                    <HistoryLabel
                      labelTitle="Zip"
                      section="employee"
                      field="zip"
                      protectedEmpNo={
                        employee.protectedEmpNo
                      }
                      showHistory={showHistory}
                      required={allAccess?.[ControlIds.zip]?.required}
                      visible={allAccess?.[ControlIds.zip].visible}
                    />
                    <InputGrp
                      {...fs.zip}
                      {...allAccess?.[ControlIds.zip]}
                      required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.zip]?.required}
                      errors={errors.zip}
                      ref={register}
                    />
                  </div>
                  {!isForeignAddress && (
                    <div>
                      <HistoryLabel
                        labelTitle="Zip+4"
                        section="employee"
                        field="zip4"
                        protectedEmpNo={
                          employee.protectedEmpNo
                        }
                        showHistory={false}
                        required={allAccess?.[ControlIds.zip4]?.required}
                        visible={allAccess?.[ControlIds.zip4].visible}
                      />
                      <div className="w-50 ml-1">
                        <InputGrp
                          {...fs.zip4}
                          {...allAccess?.[ControlIds.zip4]}
                          required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.zip4]?.required}
                          errors={errors.zip4}
                          ref={register}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <SelectGrp
              {...empInfoSectionAccess} 
              name="country"
              label="Country"
              errors={errors.country}
              ref={register}
              options={foreignCountryOpts}
              onChange={onCountryChange}
            />
          </div>
          {(allAccess?.[ControlIds.ssn]?.visible ?? true) && (
            <div className="col-12 col-sm-4 pr-0">
              <HistoryLabel
                labelTitle="Social Security"
                section="employee"
                field="ssn"
                protectedEmpNo={employee.protectedEmpNo}
                showHistory={showHistory}
                detailMessage={SSN_INFO_MESSAGE}
                required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.ssn]?.required}
                visible={allAccess?.[ControlIds.ssn].visible}
              />
              <div className="d-flex flex-wrap">              
                <InputGrp
                  {...fs.ssn}
                  {...allAccess?.[ControlIds.ssn]}
                  required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.ssn]?.required}
                  errors={errors.ssn}
                  onChange={(e: React.BaseSyntheticEvent) => {
                    const { value } = e.target;
                    const caretEnd = e.target.selectionEnd;
                    
                    e.target.value = formatSSN(value, caretEnd);
                    setValue('ssnisappliedFor', e.target.value === '000-00-0000');
                  }}
                  ref={register({
                    required: employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.ssn]?.required,
                    pattern: {
                      value: ssnisappliedFor ? /0{3}-0{2}-0{4}$/ : SSN_VALIDATION,
                      message: 'Invalid SSN',
                    }, 
                  })}
                />
                <CheckboxGrpInLine
                  {...fs.ssnisappliedFor}
                  {...ssnAppliedForFieldAccess}
                  visible={(allAccess?.[ControlIds.ssn]?.visible ?? true) && ssnAppliedForFieldAccess.visible}
                  required={employee.onboardingStatus === 'Employee' && ssnAppliedForFieldAccess?.required}
                  groupClass="p-0 ml-1"
                  errors={errors.ssnisappliedFor}
                  onChange={onSsnIsAppliedFor}
                  ref={register}
                />
              </div>
              <HistoryLabel
                labelTitle="Marital Status"
                section="employee"
                field="maritalStatus"
                protectedEmpNo={employee.protectedEmpNo}
                showHistory={showHistory}
                required={allAccess?.[ControlIds.maritalStatus]?.required}
                visible={allAccess?.[ControlIds.maritalStatus].visible}
              />
              <SelectGrp
                {...fs.maritalStatus}
                {...allAccess?.[ControlIds.maritalStatus]}
                required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.maritalStatus]?.required}
                name="maritalStatus"
                options={maritalStatusOpts}
                errors={errors.maritalStatus}
                ref={register}
              />
              <HistoryLabel
                labelTitle="Birth Date"
                section="date"
                field="birthDate"
                protectedEmpNo={employee.protectedEmpNo}
                showHistory={showHistory}
                required={employee.onboardingStatus === 'Employee' && birthDateFieldAccess?.required}
                visible={birthDateFieldAccess.visible && empDatesAccess.visible}
              />
              <ControlDatePickerGrp
                {...fs.birthDate}
                {...birthDateFieldAccess}
                visible={birthDateFieldAccess.visible && empDatesAccess.visible}
                required={employee.onboardingStatus === 'Employee' && birthDateFieldAccess?.required}
                control={control}
              />
              <HistoryLabel
                labelTitle="Hire Date"
                section="date"
                field="hireDate"
                protectedEmpNo={employee.protectedEmpNo}
                showHistory={showHistory}
                detailMessage={hireDateInfoMessage}
                required={employee.onboardingStatus === 'Employee' && hireDateInputFieldAccess?.required}
                visible={empDatesAccess.visible}
              />
              <ControlDatePickerGrp
                {...fs.hireDate}
                {...hireDateInputFieldAccess}
                required={employee.onboardingStatus === 'Employee' && hireDateInputFieldAccess?.required}
                control={control}
                visible={empDatesAccess.visible}
              />
            </div>
          )}
          <div className="col-12 col-sm-4">
            {locationOpts?.length > 0 && (
              <>
                <HistoryLabel
                  labelTitle="Location"
                  section="payrollInfo"
                  field="loc"
                  protectedEmpNo={employee.protectedEmpNo}
                  showHistory={showHistory}
                  required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.loc]?.required}
                  visible={allAccess?.[ControlIds.loc].visible}
                />
                <SelectModalGrp
                  {...fs.loc}
                  {...allAccess?.[ControlIds.loc]}
                  required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.loc]?.required}
                  labelField="locationDesc"
                  valueField="locationCode"
                  idField="locationID"
                  modalTitle="LOCATION"
                  formComponent={LocationOptionForm}
                  showId={true}
                  addOptionText="Location"
                  options={locationOpts}
                  errors={errors.loc}
                  control={control}
                  value={employee.loc}
                  dropdownName="Location"
                  rules={{ required: 'Location is required' }}
                  setValue={setValue}
                />
              </>
            )}
            {deptOpts?.length > 0 && (
              <>
                <HistoryLabel
                  labelTitle="Department"
                  section="payrollInfo"
                  field="dept"
                  protectedEmpNo={employee.protectedEmpNo}
                  showHistory={showHistory}
                  required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.dept]?.required}
                  visible={allAccess?.[ControlIds.dept].visible}
                />
                <SelectModalGrp
                  {...fs.dept}
                  {...allAccess?.[ControlIds.dept]}
                  required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.dept]?.required}
                  labelField="deptDesc"
                  valueField="deptCode"
                  idField="deptId"
                  modalTitle="DEPARTMENTS"
                  formComponent={DepartmentOptionForm}
                  showId={true}
                  addOptionText="Department"
                  options={deptOpts}
                  errors={errors.dept}
                  control={control}
                  value={employee.dept}
                  dropdownName="Department"
                  rules={{
                    required: 'Department is required',
                  }}
                  setValue={setValue}
                />
              </>
            )}
            {subdeptOpts?.length > 0 && (
              <>
                <HistoryLabel
                  labelTitle="Sub Department"
                  section="payrollInfo"
                  field="subDept"
                  protectedEmpNo={employee.protectedEmpNo}
                  showHistory={showHistory}
                  required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.subDept]?.required}
                  visible={allAccess?.[ControlIds.subDept].visible}
                />
                <SelectModalGrp
                  {...fs.subDept}
                  {...allAccess?.[ControlIds.subDept]}
                  required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.subDept]?.required}
                  labelField="subDeptDesc"
                  valueField="subDeptCode"
                  idField="subDeptID"
                  modalTitle="SUB DEPARTMENTS"
                  formComponent={SubDepartmentOptionForm}
                  showId={true}
                  addOptionText="Sub Dept"
                  options={subdeptOpts}
                  errors={errors.subDept}
                  control={control}
                  value={employee.subDept}
                  dropdownName="SubDepartment"
                  rules={{
                    required: 'Sub Department is required',
                  }}
                  setValue={setValue}
                />
              </>
            )}
            {subdept2Opts?.length > 0 && (
              <>
                <HistoryLabel
                  labelTitle="Sub Department2"
                  section="payrollInfo"
                  field="subDept2"
                  protectedEmpNo={employee.protectedEmpNo}
                  showHistory={showHistory}
                  required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.subDept2]?.required}
                  visible={allAccess?.[ControlIds.subDept2].visible}
                />
                <SelectModalGrp
                  {...fs.subDept2}
                  {...allAccess?.[ControlIds.subDept2]}
                  required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.subDept2]?.required}
                  labelField="sub2Desc"
                  valueField="sub2Code"
                  idField="subDept2ID"
                  modalTitle="SUB DEPARTMENTS 2"
                  formComponent={SubDepartment2OptionForm}
                  showId={true}
                  addOptionText="Sub Dept 2"
                  options={subdept2Opts}
                  errors={errors.subDept2}
                  control={control}
                  value={employee.subDept2}
                  dropdownName="SubDepartment2"
                  rules={{
                    required: 'Sub Department 2 is required',
                  }}
                  setValue={setValue}
                />
              </>
            )}
          </div>
        </div>
        <Row>
          <Col xs={4}>
            <HistoryLabel
              labelTitle="Cell Phone"
              section="contact"
              field="cellPhone"
              protectedEmpNo={employee.protectedEmpNo}
              showHistory={showHistory}
              required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.cellPhone]?.required}
              visible={allAccess?.[ControlIds.cellPhone].visible}
            />
            <InputGrp
              {...fs.cellPhone}
              {...allAccess?.[ControlIds.cellPhone]}
              required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.cellPhone]?.required}
              errors={errors.cellPhone}
              onChange={onPhoneChange}
              ref={register({ pattern: PHONE_VALIDATION })}
            />
          </Col>
          <Col xs={4}>
            <HistoryLabel
              labelTitle="Home Phone"
              section="contact"
              field="homePhone"
              protectedEmpNo={employee.protectedEmpNo}
              showHistory={showHistory}
              required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.homePhone]?.required}
              visible={allAccess?.[ControlIds.homePhone].visible}
            />
            <InputGrp
              {...fs.homePhone}
              {...allAccess?.[ControlIds.homePhone]}
              required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.homePhone]?.required}
              errors={errors.homePhone}
              onChange={onPhoneChange}
              ref={register({ pattern: PHONE_VALIDATION })}
            />
          </Col>
          <Col xs={4}>
            <Row>
              <Col>
                <HistoryLabel
                  labelTitle="Work Phone"
                  section="contact"
                  field="workPhone"
                  protectedEmpNo={employee.protectedEmpNo}
                  showHistory={showHistory}
                  visible={empInfoSectionAccess.visible}
                />
                <InputGrp
                  {...empInfoSectionAccess}
                  name="workPhone"
                  errors={errors.workPhone}
                  onChange={onPhoneChange}
                  ref={register({
                    pattern: PHONE_VALIDATION,
                  })}
                />
              </Col>
              <Col>
                <HistoryLabel
                  labelTitle="Ext"
                  section="contact"
                  field="workPhoneExt"
                  protectedEmpNo={employee.protectedEmpNo}
                  showHistory={showHistory}
                  visible={empInfoSectionAccess.visible}
                />
                <InputGrp
                  {...empInfoSectionAccess}
                  name="workPhoneExt"
                  errors={errors.workPhoneExt}
                  onChange={onPhoneChange}
                  ref={register}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <HistoryLabel
              labelTitle="Email"
              section="contact"
              field="email"
              protectedEmpNo={employee.protectedEmpNo}
              showHistory={showHistory}
              required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.email]?.required}
              visible={allAccess?.[ControlIds.email].visible}
            />
            <div className="dm-email-input-container">
              <InputGrp
                {...fs.email}
                {...allAccess?.[ControlIds.email]}
                required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.email]?.required}
                groupClass="dm-email-input"                  
                errors={errors.email}
                ref={register}
                type="email"
              />
              {allAccess?.[ControlIds.email].visible && (
                <OverlayTrigger
                  placement="top"
                  overlay={renderToolTip}
                >                
                  <button
                    className="btn dm-email-button"
                    type="button"
                    onClick={(e) => { onResend(e, true); }}
                    disabled={!formEmail?.length}
                  >
                    <Icon
                      name="envelope"
                      className="fa-envelope p-0"
                    />  
                    <span className="sr-only">Send MyInfo Login Info</span>
                  </button>
                </OverlayTrigger>
              )}
            </div>
          </Col>
          <Col xs={4}>
            <HistoryLabel
              labelTitle="Email 2"
              section="contact"
              field="email2"
              protectedEmpNo={employee.protectedEmpNo}
              showHistory={showHistory}
              required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.email2]?.required}
              visible={allAccess?.[ControlIds.email2].visible}
            />
            <div className="dm-email-input-container">
              <InputGrp
                {...fs.email2}
                {...allAccess?.[ControlIds.email2]}
                required={employee.onboardingStatus === 'Employee' && allAccess?.[ControlIds.email2]?.required}
                groupClass="dm-email-input"                  
                errors={errors.email2}
                ref={register}
                type="email"
              />
              {allAccess?.[ControlIds.email2].visible && (
                <OverlayTrigger
                  placement="top"
                  overlay={renderToolTip}
                >                
                  <button
                    className="btn dm-email-button"
                    type="button"
                    onClick={(e) => { onResend(e, false); }}
                    disabled={!formEmail2?.length}
                  >
                    <Icon
                      name="envelope"
                      className="fa-envelope p-0"
                    />  
                    <span className="sr-only">Send MyInfo Login Info</span>
                  </button>
                </OverlayTrigger>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <HistoryLabel
              labelTitle="MyInfo Email"
              section="contact"
              field="myInfoEmail"
              protectedEmpNo={employee.protectedEmpNo}
              showHistory={showHistory}
              visible={empInfoSectionAccess.visible}
            />
            <InputGrp
              {...empInfoSectionAccess}
              name="myInfoEmail"
              type="text"
              ref={register}
              disabled={true}
            />
          </Col>
          <Col xs={4}>
            <HistoryLabel
              labelTitle="MYInfo Last Login"
              section="contact"
              field="lastLogin"
              protectedEmpNo={employee.protectedEmpNo}
              showHistory={showHistory}
              visible={empInfoSectionAccess.visible}
            />
            <InputGrp
              {...empInfoSectionAccess}
              placeholder = {employee.lastMyInfoLogin?.toLocaleString()}
              type="text"
              ref={register}
              disabled={true}
            />
          </Col>
        </Row>
        <div className="row">
          <div className="col 12 text-right">
            <button 
              onClick={onCancel}
              className="orange-outline-button"
              type="button"
            >
              Cancel
            </button>
            <button
              {...saveButtonAccess}
              type="submit"
              className="orange-button ml-2"
              aria-disabled={!isDirty}
            >
              Save
            </button>
          </div>
        </div>
      </form>
      {showResendModal ? (
        <ConfirmationModal            
          show={showResendModal}
          title="Confirm"
          message={confirmMessage}
          onHide={() => { return setShowResendModal(false); } } 
          onConfirmed={onConfirmResend}
        />
      ) : null}
    </>
  );
};

export default EmpInfoEdit;
