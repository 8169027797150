import { CheckboxGrpInLine, SelectGrp, InputGrp } from 'core/components/form-controls';
import ExcelReportDownloadButton from 'core/components/shared/ExcelReportDownloadButton';
import { Client } from 'core/models';
import { Employee3YearPayHistoryRequest } from 'core/models/PayRateReports.model';
import { CommonService } from 'core/services';
import { PayRateReportsService } from 'core/services/payRateReports.service';
import { downloadReportThunk } from 'core/store/actions/download-report-file.action';
import { getLocationDepartmentsWithoutHome } from 'core/store/selectors';
import { clearReportFile, setReportFileName, setReportFileType } from 'core/store/slices/reportDownloader.slice';
import { Files } from 'enums/Files';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const Employee3YearPayHistory: React.FC = () => {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => { return state?.client?.client; }) as Client;
  const [startYear, setStartYear] = useState(`${DateTime.fromJSDate(new Date()).year - 2}`);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [dates, setDates] = useState([{ label: '',
    description: '',
  }]);
  
  const { locationOpts, deptOpts, subdeptOpts, subdept2Opts } = useSelector(getLocationDepartmentsWithoutHome);

  const { register, handleSubmit, setError, clearErrors, errors } = useForm<Employee3YearPayHistoryRequest>({
    defaultValues: {
      location: !!locationOpts?.length,
      locationDescription: !!locationOpts?.length,
      department: !!deptOpts?.length,
      departmentDescription: !!deptOpts?.length,
      subDepartment: !!subdeptOpts?.length,
      subDepartmentDescription: !!subdeptOpts?.length,
      subDepartment2: !!subdept2Opts?.length,
      subDepartment2Description: !!subdept2Opts?.length,
    },
  });

  useEffect(() => {
    //Populate Dropdown with dates
    const selectDates = [];
    for (let i = 0; i <= 10; i++) {
      const dateString = `${DateTime.fromJSDate(new Date()).year - i}`;
      
      selectDates.push({
        label: dateString,
        description: dateString,
      });
    }
    
    setDates(selectDates);
    dispatch(clearReportFile());
    setStartAndEndDate(`${DateTime.fromJSDate(new Date()).year - 2}`);
  }, []);

  const downloadReport = async (data: Employee3YearPayHistoryRequest) => {
    clearErrors('hourlyRates');

    if (!data.hourlyRates && !data.salaryRates && !data.annualRates) {
      setError('hourlyRates', {
        type: 'required',
        message: 'Either Hourly Rates, Salary Rates or Annual Rates must be selected',
      });
      return;
    }

    const submitData: Employee3YearPayHistoryRequest = {
      clientNo: client.clientNo,
      protectedClientNo: CommonService.getProtectedClientNo(),
      beginDate: DateTime.fromJSDate(new Date(startDate)).toFormat('yyyy-MM-dd'),
      endDate: DateTime.fromJSDate(new Date(endDate)).toFormat('yyyy-MM-dd'),
      hourlyRates: data.hourlyRates,
      salaryRates: data.salaryRates,
      annualRates: data.annualRates,
      includeTerminated: data.includeTerminated,
      location: data.location,
      locationDescription: data.locationDescription,
      department: data.department,
      departmentDescription: data.departmentDescription,
      subDepartment: data.subDepartment,
      subDepartmentDescription: data.subDepartmentDescription,
      subDepartment2: data.subDepartment2,
      subDepartment2Description: data.subDepartment2Description,
    };

    dispatch(setReportFileName('Employee3YearPayHistoryReport.xlsx'));
    dispatch(setReportFileType(Files.EXCEL));
    dispatch(downloadReportThunk(() => { return PayRateReportsService.postEmployee3YearPayHistory(submitData); }));
  };


  const setStartAndEndDate = (value: string) => {
    const newStartDate = DateTime.fromJSDate(new Date(+value, 0, 1)).toFormat('MM-dd-yyyy');
    const finalEndDate = DateTime.fromJSDate(new Date(+value, 11, 31)).plus({ year: 2 }).toFormat('MM-dd-yyyy');

    setStartYear(value);
    setStartDate(newStartDate);
    setEndDate(finalEndDate);
  };

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">
            Employee 3 Year Pay History Report
          </div>
          <div className="w-100 text-right">
            <ExcelReportDownloadButton disabled={!startDate || !endDate} />
          </div>
          <hr className="dm-panel-hr" />
          <div style={{ width: '10%' }}>
            <SelectGrp
              ref={register}
              options={dates}
              label={'Select a Start Year'}
              value={startYear}
              onChange={(e: any) => { setStartAndEndDate(e.target.value); }}
            />
          </div>
          <hr className="dm-panel-hr" />
          <div
            id="inputAndCheckboxes"
            className="d-flex flex-row flex-grow-1"
          >
            <div className="d-flex flex-column">
              <div>
                <InputGrp
                  label={'Selected Start Year'}
                  name="beginDate"
                  disabled={true}
                  value={startDate}
                />
              </div>
              <InputGrp
                name="endDate"
                label={'End Year'}
                disabled={true}
                value={endDate}
              />
            </div>
            <div className="w-30 dm-panel dm-panel-border ml-3">
              <div className="dm-grid-action-title mb-2">
                Show
              </div>
              <div className="d-flex flex-column">
                {/** 1st Row */}
                <div className="d-flex flex-row">
                  <CheckboxGrpInLine
                    name="includeTerminated"
                    ref={register}
                    labelFirst={false}
                    label="Include Terminated Employees"
                  />
                </div>
                {/** 2nd Row */}
                <div className="d-flex flex-row">
                  <CheckboxGrpInLine
                    name="hourlyRates"
                    ref={register}
                    labelFirst={false}
                    label="Hourly Rate"
                    errors={errors.hourlyRates}
                  />
                  <CheckboxGrpInLine
                    name="salaryRates"
                    ref={register}
                    labelFirst={false}
                    label="Salary Rate"
                  />
                  <CheckboxGrpInLine
                    name="annualRates"
                    ref={register}
                    labelFirst={false}
                    label="Annual Rate"
                  />
                </div>
                {/** 3rd Row */}
                {locationOpts?.length ? (
                  <div className="d-flex flex-row">
                    <CheckboxGrpInLine
                      name="location"
                      ref={register}
                      labelFirst={false}
                      label="Location"
                    />
                    <div style={{ marginLeft: '17px' }}>
                      <CheckboxGrpInLine
                        name="locationDescription"
                        ref={register}
                        labelFirst={false}
                        label="Location Description"
                      />
                    </div>
                  </div>
                ) : null}
                {/** 4th Row */}
                {deptOpts?.length ? (
                  <div className="d-flex flex-row">
                    <CheckboxGrpInLine
                      name="department"
                      ref={register}
                      labelFirst={false}
                      label="Dept"
                    />
                    <div style={{ marginLeft: '44px' }}>
                      <CheckboxGrpInLine
                        name="departmentDescription"
                        ref={register}
                        labelFirst={false}
                        label="Dept Description"
                      />
                    </div>
                  </div>
                ) : null}
                {/**5th Row */}
                {subdeptOpts?.length ? (
                  <div className="d-flex flex-row">
                    <CheckboxGrpInLine
                      name="subDepartment"
                      ref={register}
                      labelFirst={false}
                      label="Sub Dept"
                    />
                    <div style={{ marginLeft: '22px' }}>
                      <CheckboxGrpInLine
                        name="subDepartmentDescription"
                        ref={register}
                        labelFirst={false}
                        label="Sub Dept Description"
                      />
                    </div>
                  </div>
                ) : null}
                {/** 6th Row */}
                {subdept2Opts?.length ? (
                  <div className="d-flex flex-row">
                    <CheckboxGrpInLine
                      name="subDepartment2"
                      ref={register}
                      labelFirst={false}
                      label="Sub Dept 2"
                    />
                    <div style={{ marginLeft: '13px' }}>
                      <CheckboxGrpInLine
                        name="subDepartment2Description"
                        ref={register}
                        labelFirst={false}
                        label="Sub Dept 2 Description"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Employee3YearPayHistory;
