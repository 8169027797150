import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ScorecardRate, ScorecardTrait } from 'core/models';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams } from '@ag-grid-community/core';
import { InputGrp } from 'core/components/form-controls';
import { postScorecardTrait } from 'core/store/slices/applicantTracking.slice';
import Modal from 'core/components/modals/Modal';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { cloneDeep } from 'lodash';
import FiveStar from 'core/components/shared/FiveStar';

type Props = {
  show:boolean;  
  item: ScorecardTrait | undefined;
  onHide: ()=> void;
};

const newRatings: ScorecardRate[] = [{
  scorecardTraitId: 0,
  rating: 1,
  description: '',
},
{
  scorecardTraitId: 0,
  rating: 2,
  description: '',
},
{
  scorecardTraitId: 0,
  rating: 3,
  description: '',
},
{
  scorecardTraitId: 0,
  rating: 4,
  description: '',
}];

const ScorecardTraitModal: React.FC<Props> = ({
  show,
  item,
  onHide,
}) => {

  const dispatch = useAppDispatch();
  const [client] = useAppSelector(
    (state) => {
      return [
        state.client.client];
    },
  );
  const [rowData, setRowData] = useState<ScorecardRate[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridDirty, setGridDirty] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const { register, reset, handleSubmit, formState:{ isDirty } } = useForm<ScorecardTrait>({
    defaultValues: { ...item,
      scorecardTraitId: item?.scorecardTraitId ?? 0,
      rates: item?.rates },
  });

  useEffect(() => {    
    item && reset(item);
    (item && item.rates) ? setRowData(cloneDeep(item.rates)) : setRowData(newRatings);
    setGridDirty(false);
  }, [item]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const getGridData = () => {
    const items: any[] = [];
    gridApi?.forEachNode((node) => {
      items.push(node.data);
    });
    return items;
  };

  const hideModal = () => {  
    if (isDirty || gridDirty) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };

  const onSubmit = (data: any) => {
   
    const st:ScorecardTrait = item ? {
      ...item,  
      trait: data.trait,   
      rates: getGridData() as ScorecardRate[],
    } : {
      scorecardCategoryId: 0,
      hrEntityId: client?.hrEntityId ?? 0,
      scorecardTraitId:0,
      trait:data.trait,
      rates: getGridData() as ScorecardRate[],
    };

    dispatch(postScorecardTrait(st));

  };
  
  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };
  
  const columns = useMemo<ColDef[]>(
    () => {
      return [
        {
          field: 'rating',
          headerName: 'Rating',  
          cellRenderer: (params: any) => {
            return FiveStar(params.data.rating);
          },   
          width: 300,    
        },       
        {
          field: 'description',
          editable:true,
          headerName: 'Trait Description',
          width: 190,
        },      
      ];
    },
    [],
  );

  const gridOptions: GridOptions = {
    stopEditingWhenCellsLoseFocus:true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  return (
    
    <Modal
      show={show}
      onHide={hideModal}
      dialogClassName="lg" 
      isDirty={isDirty || gridDirty}     
      backdrop={'static'}
      title="Scorecard Traits"
    >     
     
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="d-flex flex-column flex-grow-1"
        >  
          <input
            type="hidden"
            name={'scorecardTraitId'}          
            ref={register({ valueAsNumber: true })}
          />      
          <div>
            <InputGrp
              label={'Trait Name'}
              name={'trait'}
              defaultValue={item?.trait}
              ref={register}
            />
          </div>
        </div>        
        <div className="d-flex mb-3">             
          <div className="table-wrapper-wrapper ag-theme-balham">
            <AgGridReact
              gridOptions={gridOptions}
              rowData={rowData}
              columnDefs={columns}
              onGridReady={onGridReady}
              onCellValueChanged={()=>{return setGridDirty(true);}}
            />
          </div>
        </div>
        <div className="d-flex mt-3">
          <button
            type="button"
            className="orange-outline-button ml-auto mr-2"
            onClick={hideModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="orange-button mr-2"
            disabled={!(isDirty || gridDirty)}
          >
            Save
          </button>
        </div>
      </form>
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
    </Modal>
  );
};
export default ScorecardTraitModal;
