export class Department {
  clientNo = 0;

  deptId = 0;

  clientId = 0;

  deptCode = 0;

  deptDesc = '';

  departmentDescWithCode = '';

  locationId?: number = 0;

  glAccount = '';

  alphaCode = '';

  addressId?: number = 0;

  stop?: boolean = false;

  quickenClass = '';

  cityTaxCode?: number = 0;

  constructor(clientNo: number, clientId: number, deptCode: number = 0) {
    this.clientNo = clientNo;
    this.clientId = clientId;
    this.deptCode = deptCode;
  }
}