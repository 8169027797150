import { createReducer } from '@reduxjs/toolkit';
import {  loadReferenceGuide, storeReferenceGuide } from '../actions/help-reference-guide.action';
import {  ReferenceFolder } from 'core/models/HelpReferenceGuide.model';



export interface State {
  loading: boolean;
  referenceGuide: ReferenceFolder | null;
}

const INITIAL_STATE: State = {
  loading: false,
  referenceGuide: null,
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadReferenceGuide, (state) => {
      state.loading = true;
    })
    .addCase(storeReferenceGuide, (state, action) => {
      state.loading = false;
      state.referenceGuide = action.payload;
    });
});