import React, { useCallback, useEffect, useState } from 'react';
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, GridSizeChangedEvent, RowSelectedEvent, ValueGetterParams } from '@ag-grid-community/core';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { agCheckboxRenderer, agSSNRenderer } from 'utilities/ag-grid-renderers';
import { AgGridReact } from '@ag-grid-community/react';
import { InputGrp, CheckboxGrpInLine } from 'core/components/form-controls';
import { Employee } from 'core/models';
import { useAppSelector } from 'utilities/hooks';
import '../corrections.scss';
import { convToDateString } from 'utilities/utilities';

type Props = {
  setEmpNos: React.Dispatch<React.SetStateAction<string>>
};

const fs: FieldInputSettings = {
  startEmpNo: {
    name: 'from',
    label: 'From: ',
  },
  endEmpNo: {
    name: 'to',
    label: 'To: ',
  },
  includeTerminatedEmps: {
    name: 'includeTerminatedEmps',
    label: 'Include terminated employees',
  },
};

const CorrectionsListSelect = ({ setEmpNos }: Props) => {
  const employees = useAppSelector((state) => { return state.employees.employees; });
  
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const [rowData, setRowData] = useState<Employee[]>([]);
  const [includeTerminated, setIncludeTerminated] = useState(false);
  const [startIndex, setStartIndex] = useState(String(employees?.[0]?.empNo));
  const [endIndex, setEndIndex] = useState(String(employees?.[employees?.length - 1]?.empNo));
  const [columns, setColumns] = useState<ColDef[]>([
    {
      field: '',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 10,
    },
    {
      field: 'empNo',
      headerName: 'Emp #',
      sortable: true,
      width: 20,
    },
    {
      field: 'ssn',
      headerName: 'SSN',
      cellRenderer: 'ssnRenderer',
      sortable: true,
      filter: true,
      suppressMenu: false,
      width: 35,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressMenu: false,
      width: 35,
    },
    {
      field: 'midName',
      headerName: 'MI',
      suppressMenu: true,
      width: 10,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressMenu: false,
      width: 40,
    },
    {
      field: 'termDate',
      sortable: true,
      width: 40,
      hide: true, // hide by default
      valueGetter: (params: ValueGetterParams) => {
        if (!params.data.termDate) return;
        return convToDateString(new Date(params.data.termDate));
      },
    },
  ]); 
  const [gridOptions] = useState<GridOptions>({
    columnDefs: columns,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    components: {
      checkBoxRenderer: agCheckboxRenderer,
      ssnRenderer: agSSNRenderer,
    },
  });
  
  const activeEmps = employees?.filter((emp) => { return !emp.termDate; });


  useEffect(() => {
    setRowData(activeEmps ?? []);
  }, []);
  
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };
  
  const resizeColumns = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit();
  };
  
  /*
    TODO: Set this up to only trigger a request when necessary (e.g., new employee is selected or 
    new date range is outside of previous) so that we can just filter rather than make new requests
    every time. Will have to think about the best way to do this.
 */
  const onSelectionChanged = (_: RowSelectedEvent) => {
    const selected = gridApi?.getSelectedNodes();
    const newEmpNos = selected?.map((node) => { return (node.data as Employee).empNo; })?.toString();
    setEmpNos(newEmpNos ?? '');
  };
  
  // Function to set next closest employee if upper bound is not in list
  const getProximalIndex = useCallback((arr: Employee[], target: number): number => {
    let min = Number.MAX_VALUE;
    let index = arr.length; // since slice is non-inclusive for end
    
    // TODO: flexible for start or end index
    for (let i = arr.length - 1; i >= 0; i--) {
      const difference = target - arr[i]?.empNo;
      
      if (!(difference < min && difference > 0)) {
        continue;
      } else {
        min = difference;
        index = i;
        break;
      }
    }
    
    return index;
  }, [employees, endIndex]);
  
  const filterList = () => {
    const emps = employees?.filter((emp) => { return includeTerminated ? true : !emp.termDate; });
    const begin = emps?.findIndex((emp) => {return String(emp.empNo) === startIndex;});
    const end = emps?.findIndex((emp) => { return String(emp.empNo) === endIndex; });
    const calculatedEnd = (end > -1 ? end : getProximalIndex(emps, +endIndex)) + 1;
    const updatedEmps = [...emps].slice(begin > -1 ? begin : 0, calculatedEnd);
    
    setRowData(updatedEmps);
    
    if (!columnApi) return;
    columnApi.setColumnVisible('termDate', includeTerminated);
    gridApi?.sizeColumnsToFit();
  };
  
  return (
    <>
      <div className="shadow border p-1 filter-list-wrapper constrained-filter-wrapper">          
        <div className="inputs-container m-2">
          <div className="range-group">
            <InputGrp
              {...fs.startEmpNo}
              value={startIndex}
              type="number"
              onChange={(e: React.BaseSyntheticEvent) => { setStartIndex(e.target.value ?? '0'); }}
            />
            <InputGrp
              {...fs.endEmpNo}
              value={endIndex}
              type="number"
              onChange={(e: React.BaseSyntheticEvent) => { setEndIndex(e.target.value); }}
            />
          </div>
          <CheckboxGrpInLine
            {...fs.includeTerminatedEmps}
            labelWidth={100}
            labelWidthUnit={'%'}
            alignment={'end'}
            value={includeTerminated}
            onChange={() => {return setIncludeTerminated(!includeTerminated);}}
          />
          <button
            className="btn btn-primary mb-1 h-100"
            onClick={filterList}
          >
            Filter
          </button>
        </div>
      </div>
      <div className="grid-wrapper">
        <div
          className="table-wrapper-wrapper ag-theme-balham"
        >
          <AgGridReact
            gridOptions={gridOptions}
            rowData={rowData}
            onGridReady={onGridReady}
            onGridSizeChanged={resizeColumns}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      </div>
    </>
  );
};

export default CorrectionsListSelect;