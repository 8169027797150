import React from 'react';
import Modal from 'core/components/modals/Modal';
import styles from 'core/components/shared/dm-error-card/error-card.module.scss';
import ErrorCard from 'core/components/shared/dm-error-card/ErrorCard';

type Props = {
  show: boolean,
  onHide: () => void
  errors: Partial<Record<string, string[]>>
};
  

const OnboardingProgressModal = ({ show, onHide, errors }: Props) => {
    
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Incomplete Onboarding Information"
      size="sm"
    >
      <div
        className={styles['ec-modal-body']}
        onKeyDown={(e) => {
          if (e.key === 'Tab') {
            e.preventDefault();
            e.stopPropagation();
            return;
          }
        }}
      >
        <p className={styles['ec-modal-notice-msg']}>
          <strong>Notice:</strong> The following onboarding steps are incomplete for the employee.
        </p>
        <ul className={styles['ec-section-card-list']}>
          {errors && Object.keys(errors).map((key) => {
            if (!key) return null;            
            return (
              <ErrorCard
                section={key}
                errors={errors?.[key as keyof typeof errors.errors]}
              />
            );
          })}
        </ul>
        <button
          className="orange-button ml-auto"
          onClick={onHide}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default OnboardingProgressModal;