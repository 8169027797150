import { createSelector } from 'reselect';
import { LookupEEOUnit } from '../../models';
import { eeoState } from '../reducers';
import { State as EEOState } from '../reducers/eeo.reducer';
import { createStack } from '@mui/system';

export const getEEONoSettings = createSelector(
  eeoState,
  (state: EEOState) => { return state.eeoNoSettings; },
);

export const getEEOUnitSettings = createSelector(
  eeoState,
  (state: EEOState) => { return state.eeoUnitSettings; },
);

export const getLookupEEOUnit = createSelector(
  eeoState,
  (state: EEOState) => { return state.lookupEEOUnit; },
);

export const getLookupEEOUnit2 = createSelector(
  eeoState,
  (state: EEOState) => {
    return state.lookupEEOUnit.map((eeoUnit: LookupEEOUnit) => {
      return { ...eeoUnit,
        eeoUnitID: '' + eeoUnit.eeoUnitID }; 
    });
  },
);

export const getLookupEEOCertifyOfficial = createSelector(
  eeoState,
  (state: EEOState) => {
    return state.lookupCertifyOfficial; 
  },
);

export const getEEOReport = createSelector(
  eeoState,
  (state: EEOState) => { return state.eeoReport; },
);

export const getEEOPayrollDates = createSelector(
  eeoState,
  (state: EEOState) => { return state.eeoPayrollDates; },
);
