import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { getACADocumentPDF } from 'core/store/selectors/aca-full-time-report.selector';
import { postFullTimeCalculationReport } from 'core/store/actions/human-capital-management/aca-full-time-calculation.action';
import { Spinner } from 'react-bootstrap';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';

const ACAReportPreview = () => {
  const dispatch = useAppDispatch();
  const isGettingReportsPdf = useAppSelector((state) => { return state.acaFullTimeReport.isGettingReportsPdf; });
  const pdfData = useAppSelector(getACADocumentPDF);
  const [pdfReportModal, setPdfReportModal] = useState<boolean>(false);

  return (
    <>
      <button
        type="button"
        className="orange-button mt-2"
        style={{
          whiteSpace: 'nowrap',
          minWidth: 130,
        }}
        onClick={async () => {
          await dispatch(postFullTimeCalculationReport());
          setPdfReportModal(true);
        }}
        disabled={isGettingReportsPdf}
      >
        Run Report
        {isGettingReportsPdf && (
          <Spinner
            animation="border"
            size="sm"
            className="ml-2"
          />
        )}
      </button>
        {pdfData && pdfReportModal && (
          <PDFViewerModal
            pdfData={pdfData}
            show={pdfReportModal}
            onHide={() => {
              setPdfReportModal(false);
            }}
            reportName="Confirm Check Printing"
          />
        )}
    </>
  );
};

export default ACAReportPreview;