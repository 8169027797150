import { combineEpics, StateObservable } from 'redux-observable';
import { Actions, Epic } from './types';
import * as fromApClientVendor from './ap-client-vendor.epic';
import * as fromApp from './app.epic';
import * as fromAuth from './auth.epic';
import * as fromClient from './client.epic';
import * as fromDashboard from './dashboard.epic';
import * as fromDropdown from './dropdown.epic';
import * as fromEEO from './eeo.epic';
import * as fromRateRule from './rate-rule.epic';
import * as fromWorkerComp from './workerComp.epic';
import * as fromEmployees from './employees.epic';
import * as fromAccrualRecords from './accrual-records.epic';
import * as fromAccrual from './accrual.epic';
import * as fromAttendanceOnDemand from './attendance-on-demand.epic';
import * as fromCheckCalculator from './check-calculator.epic';
import * as fromCheckCodes from './check-code.epic';
import * as fromContact from './contact.epic';
import * as fromContractorOptions from './contractor-options.epic';
import * as fromDeduction from './deduction.epic';
import * as fromDeferredCompensation from './deferred-compensation.epic';
import * as fromDocument from './document.epic';
import * as fromEarningCode from './earning-code.epic';
import * as fromEarningsRecords from './earnings-records.epic';
import * as fromEarningsReports from './earnings-reports.epic';
// import * as fromEmpDate from './emp-date.epic';
import * as fromEmpACA from './employee-aca.epic';
import * as fromEmpDocComp from './employee-doc-comp.epic';
import * as fromEmpFte from './employee-fte.epic';
import * as fromEmpGroupTerm from './employee-group-term.epic';
import * as fromEmpI9 from './employee-i9.epic';
import * as fromEmployeeReports from './employee-reports.epic';
import * as fromEmpSelfService from './employee-self-service.epic';
import * as fromEmployeeUpload from './employee-upload.epic';
import * as fromEmployee from './employee.epic';
import * as fromFile from './file.epic';
import * as fromGlobalDeduction from './global-deduction.epic';
import * as fromHireHistory from './hire-history.epic';
import * as fromEmployeeFieldHistory from './hr-employee.epic';
import * as fromHr from './hr.epic';
import * as fromMyInfoChanges from './my-info-changes.epic';
import * as fromOnboard from './onboard.epic';
import * as fromOtherDate from './other-date.epic';
import * as fromPayRate from './pay-rate.epic';
import * as fromPayStub from './pay-stub.epic';
import * as fromPayRateReports from './payRateReports.epic';
import * as fromPayrollAdjustment from './payroll-adustment.epic';
import * as fromPayrollInfo from './payroll-info.epic';
import * as fromPayrollUpload from './payroll-upload.epic';
import * as fromPayroll from './payroll.epic';
import * as fromPayrollsUserOptions from './payrolls-user-options.epic';
import * as fromSalesTaxTransmittal from './sales-tax-transmittal.epic';
import * as fromSpecialInfo from './special-info.epic';
import * as fromSso from './sso.epic';
import * as fromSupervisedEmployee from './supervised-employee.epic';
import * as fromTax from './tax.epic';
import * as fromTimeOff from './time-off.epic';
import * as fromTransfer from './transfer.epic';
import * as fromVisaStatus from './visa-status.epic';
import * as fromYearToDate from './year-to-date.epic';
import * as fromUsers from './users.epic';
import * as fromAccountingReports from './accounting-reports.epic';
import * as fromHumanCapitalReports from './human-capital.epic';
import * as fromPayrollReports from './payroll-reports.epic';
import * as fromContractorReports from './contractor-reports.epic';
import * as fromDownloader from './downloader.epics';
import * as fromClientLibrary from './client-library.epic';
import * as fromApplicantTracking from './applicant-tracking.epic';
import * as fromHelpReferenceGuide from './help-reference-guide.epic';
import * as fromCorrections from './corrections.epic';
import * as fromIntegration from './integration.epic';
import * as fromContractor from './contractor.epic';
import * as fromIntuit from './intuit.epic';
import * as fromEmFieldValidation from './em-field-validation.epic';
import { Action as ReduxAction, Observable } from 'redux';
import { RootState } from '../store';
import { catchError, concat, merge, of } from 'rxjs';
import { handleError } from '../actions';

export const epics: any[] = [
  ...fromDropdown.epics,
  ...fromDashboard.epics,
  ...fromApp.epics,
  ...fromAuth.epics,
  ...fromApClientVendor.epics,
  ...fromClient.epics,
  ...fromEEO.epics,
  ...fromWorkerComp.epics,
  ...fromRateRule.epics,
  ...fromAttendanceOnDemand.epics,
  ...fromEmployees.epics,
  ...fromEmployee.epics,
  ...fromEmployeeReports.epics,
  ...fromPayRate.epics,
  ...fromDocument.epics,
  ...fromPayStub.epics,
  ...fromContact.epics,
  ...fromTransfer.epics,
  ...fromDeduction.epics,
  ...fromVisaStatus.epics,
  ...fromOnboard.epics,
  ...fromEmployeeUpload.epics,
  ...fromGlobalDeduction.epics,
  ...fromPayrollInfo.epics,
  ...fromDeferredCompensation.epics,
  ...fromSpecialInfo.epics,
  ...fromEmpACA.epics,
  ...fromEmpDocComp.epics,
  ...fromEmpFte.epics,
  ...fromEmpGroupTerm.epics,
  ...fromHireHistory.epics,
  ...fromOtherDate.epics,
  ...fromCheckCodes.epics,
  ...fromAccrual.epics,
  ...fromYearToDate.epics,
  ...fromEarningsRecords.epics,
  ...fromContractorOptions.epics,
  ...fromEmpSelfService.epics,
  ...fromCheckCalculator.epics,
  ...fromSupervisedEmployee.epics,
  ...fromPayroll.epics,
  ...fromPayrollsUserOptions.epics,
  ...fromMyInfoChanges.epics,
  ...fromTax.epics,
  ...fromSalesTaxTransmittal.epics,
  ...fromEarningCode.epics,
  ...fromEmpI9.epics,
  ...fromSso.epics,
  ...fromTimeOff.epics,
  ...fromPayrollUpload.epics,
  ...fromPayrollAdjustment.epics,
  ...fromEarningsReports.epics,
  ...fromEmployeeFieldHistory.epics,
  ...fromAccrualRecords.epics,
  ...fromHr.epics,
  ...fromPayRateReports.epics,
  ...fromFile.epics,
  ...fromUsers.epics,
  ...fromAccountingReports.epics,
  ...fromHumanCapitalReports.epics,
  ...fromPayrollReports.epics,
  ...fromContractorReports.epics,
  ...fromDownloader.epics,
  ...fromClientLibrary.epics,
  ...fromApplicantTracking.epics,
  ...fromHelpReferenceGuide.epics,
  ...fromCorrections.epics,
  ...fromIntegration.epics,
  ...fromContractor.epics,
  ...fromIntuit.epics,
  ...fromEmFieldValidation.epics,
];

export const rootEpic = (action$: any, state$: any, dependencies: any) =>
  combineEpics(...epics)(action$, state$, dependencies).pipe(
    catchError((error, source$) => {
      console.error('****UNCAUGHT ERROR****', error);
      alert('An unexpected error occurred. Please contact customer service at (248) 244-3293 for assistance.');
      return source$;
    }),
  );
