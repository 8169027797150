import { createSelector } from 'reselect';
import { empI9State } from '../reducers';
import { State as EmpI9State } from '../reducers/emp-i9.reducer';

export const getAllEmpI9 = createSelector(empI9State, (state: EmpI9State) => {
  return state.allEmployeeI9
    ?.slice()
    .sort((a, b) => {
      return a.sectionOne.lastName < b.sectionOne.lastName
        ? -1
        : b.sectionOne.lastName < a.sectionOne.lastName
          ? 1
          : 0;
    },
    );
},
);

export const getEmpI9 = createSelector(
  empI9State,
  (state: EmpI9State) => {return state.employeeI9;},
);

export const getEmpI9DataReport = createSelector(
  empI9State,
  (state: EmpI9State) => {return state.employeeI9DataReport?.value;},
);
