import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PanelHeader from '../../../core/components/shared/PanelHeader';
import {
  cleanupSupervisorEmployees,
  loadSupervisorEmployees,
  loadSupervisors,
  updateSupervisorEmployees,
} from '../../../core/store/actions';
import { getSupervisors } from '../../../core/store/selectors/employees.selector';
import { SelectGrp } from '../../../core/components/form-controls';
import { FieldInputSettings } from '../../../core/components/form-controls/types';
import { useForm } from 'react-hook-form';
import { getSupervisedEmployees } from '../../../core/store/selectors/supervised-employee.selector';
import { AgGridReact, AgGridColumn } from '@ag-grid-community/react';
import {
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from '@ag-grid-enterprise/all-modules';
import { RealignSupervisedEmployee, Supervisor } from '../../../core/models';
import { getSelectedEmp } from '../../../core/store/selectors';
import Icon from 'core/components/shared/Icon';

const fs: FieldInputSettings = {
  supervisorEmpId: {
    name: 'supervisorEmpId',
    label: '',
    valueField: 'empId',
    labelField: 'name',
    required: true,
  },
};

const RealignSupervisedEmployeesPage: React.FC<any> = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm<any>();

  const { protectedEmpNo } = useParams<any>();
  const supervisors = useSelector(getSupervisors);
  const supervisorEmployees = useSelector(getSupervisedEmployees);
  const employeeInfo = useSelector(getSelectedEmp);

  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const [rowsSelectedLength, setRowsSelectedLength] = useState<number>(0);
  const [nameOfSupervisor, setNameOfSupervisor] = useState<string>('');
  const [supervisorsSelect, setSupervisorsSelect] = useState<Supervisor[]>(
    [],
  );
  const [singularUpdate, setSingularUpdate] = useState<boolean>(true);
  const [updatedSupervisedEmployees, setUpdatedSupervisedEmployees] =
    useState<boolean>(false);

  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(loadSupervisors());
      dispatch(loadSupervisorEmployees({ empNo: protectedEmpNo }));
    }
  }, [protectedEmpNo]);

  useEffect(() => {
    if (supervisors && employeeInfo) {
      const supervisorList = [...supervisors];

      // This removes the current employee from the supervisor list.
      const removeCurrentSupervisorIndex = supervisors.findIndex(
        (a) => { return a.empId === employeeInfo.empId; },
      );
      supervisorList.splice(removeCurrentSupervisorIndex, 1);
      setSupervisorsSelect(supervisorList);
    }
  }, [supervisors, employeeInfo]);

  useEffect(() => {
    return () => {
      dispatch(cleanupSupervisorEmployees());
    };
  }, []);

  useEffect(() => {
    setRowsSelectedLength(gridApi?.getSelectedRows().length ?? 0);
  }, [supervisorEmployees]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onFirstDataRendered = (params: GridReadyEvent) => {
    params.api.sizeColumnsToFit();
    gridColumnApi?.autoSizeColumn('employeeName');
  };

  const gridOptions: GridOptions = {
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    rowSelection: 'multiple',
  };

  const getSupervisorName = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    setNameOfSupervisor(ev.target.options[ev.target.selectedIndex].text);
    setUpdatedSupervisedEmployees(false);
  };
  const onSelectionChanged = (params: GridReadyEvent) => {
    setRowsSelectedLength(params.api.getSelectedRows().length);
  };

  const realignEmployees = (data: any) => {
    const updatedData: RealignSupervisedEmployee = {
      supervisorEmpId: data.supervisorEmpId,
      employees: gridApi!.getSelectedRows(),
    };
    dispatch(updateSupervisorEmployees(updatedData));
    setUpdatedSupervisedEmployees(true);
    setSingularUpdate(updatedData.employees.length === 1);
    gridColumnApi?.autoSizeColumn('employeeName');
  };

  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Realign Supervised Employees" />
      {!supervisorEmployees.loading && (
        <>
          {supervisorEmployees.supervisedEmployees.length === 0 ? (
            <div className="alert alert-danger mt-3">
              <Icon name="times-circle"
                className="fa-check mr-3" />
              The current employee does not supervise any
              employees.
            </div>
          ) : (
            <form onSubmit={handleSubmit(realignEmployees)}>
              <div className="row mb-5">
                <div className="col-9">
                  <div className="my-3 dm-grid-action-title-blue">
                    Select Employees to be Realigned
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ minHeight: 150 }}
                  >
                    <div style={{ height: '100%' }}>
                      <AgGridReact
                        rowData={
                          supervisorEmployees.supervisedEmployees
                        }
                        onSelectionChanged={
                          onSelectionChanged
                        }
                        onGridReady={onGridReady}
                        onFirstDataRendered={
                          onFirstDataRendered
                        }
                        gridOptions={gridOptions}
                      >
                        <AgGridColumn
                          field=""
                          headerCheckboxSelection={
                            true
                          }
                          checkboxSelection={true}
                          width={85}
                        />
                        <AgGridColumn field="clientNo" />
                        <AgGridColumn field="empNo" />
                        <AgGridColumn field="employeeName" />
                        <AgGridColumn field="loc" />
                        <AgGridColumn field="dept" />
                        <AgGridColumn field="subDept" />
                        <AgGridColumn field="subDept2" />
                      </AgGridReact>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="my-3 dm-grid-action-title-blue">
                    Select New Supervisor
                  </div>
                  <SelectGrp
                    {...fs.supervisorEmpId}
                    ref={register}
                    options={supervisorsSelect}
                    addEmptyValue={true}
                    required={true}
                    onChange={getSupervisorName}
                  />
                </div>
              </div>
              <div className="row justify-content-end mb-3">
                <button
                  type="submit"
                  className="btn btn-primary orange-button mx-3"
                  disabled={rowsSelectedLength === 0}
                >
                  Realign Employees
                </button>
              </div>

              {updatedSupervisedEmployees && (
                <div className="alert alert-success">
                  <Icon name="check-circle"
                    className="fa-check mr-3" />
                  {singularUpdate ? (
                    <span>
                      The selected employee has been
                      transferred to{' '}
                    </span>
                  ) : (
                    <span>
                      The selected employees have been
                      transferred to{' '}
                    </span>
                  )}
                  {nameOfSupervisor}.
                </div>
              )}
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default RealignSupervisedEmployeesPage;
