import React, { useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ControlDatePickerGrpInLine } from 'core/components/form-controls';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { Client } from 'core/models';
import { AnnualFICATipCreditReportRequest } from 'core/models/PayrollReports.model';
import { downloadAnnualFICATipCreditReport, clearAnnualFICATipCreditReport } from 'core/store/actions/payoll-reports.action';
import { getAnnualFICATipCreditReport } from 'core/store/selectors';
import { useAppSelector } from 'utilities/hooks';

const currentYear = new Date().getFullYear();

const AnnualFICATipCreditReport = () => {

  const dispatch = useDispatch();

  const childRef: React.RefObject<any> = useRef();

  const report = useSelector(getAnnualFICATipCreditReport);
  const client = useAppSelector((state) => {return state.client.client;}) as Client;
  const { control, getValues, watch } = useForm<AnnualFICATipCreditReportRequest>({
    defaultValues: {
      fromDate: new Date(`01/01/${currentYear}`),
      toDate: new Date(`12/31/${currentYear}`), 
    }, 
  });

  const downloadReport = () => {
    const submitData: AnnualFICATipCreditReportRequest = {
      clientNo: client.clientNo,
      fromDate: new Date(getValues('fromDate')).toISOString(),
      toDate: new Date(getValues('toDate')).toISOString(),
      reportType: 'pdf',

    };
    dispatch(downloadAnnualFICATipCreditReport(submitData));
  };

  const startDate = watch('fromDate');
  const fromDate = watch('toDate');

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearAnnualFICATipCreditReport());
  };

  return (
    <Row className="w-100">
      <Col>
        <div className="dm-panel dm-panel-border">
          <div className="dm-grid-title">Annual FICA Tip Credit Report</div>
          <hr className="dm-panel-hr" />
          <div className="d-flex flex-row flex-grow-1">
            <div>
              <ControlDatePickerGrpInLine
                name={'fromDate'}
                label={'From'}
                labelWidth={40}
                groupClass={'groupClass50'}
                required={true}
                control={control}
                maxDate={new Date()}
                rules={{
                  required: 'From Date is required',
                }}
              />
              <ControlDatePickerGrpInLine
                name={'toDate'}
                label={'To'}
                labelWidth={40}
                groupClass={'groupClass50'}
                required={true}
                control={control}
                maxDate={new Date()}
                rules={{
                  required: 'To Date is required',
                }}
              />
            </div>
            <div className="flex-grow-1 text-right">
              <button
                type="button"
                className="orange-outline-button mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="orange-button mr-2"
                onClick={downloadReport}
                disabled={!startDate || !fromDate ? true : false}
              >
                Run Report
              </button>
            </div>
          </div>
        </div>
        {report ? <PDFViewerModal
          show={report.length > 0}
          pdfData={report}
          onHide={hidePdfViewer}
          reportName="AnnualFICATipCreditReort"
        /> : null}
      </Col>
    </Row>
  );

};

export default AnnualFICATipCreditReport;