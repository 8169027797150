import { convToClass, PropTypeLists } from '../../utilities/classUtils';
import { AccrualRecord } from './AccrualRecord.model';

export class AccrualBalanceRecord {
  clientNo = 0;

  empId = 0;

  empNo = 0;

  protectedEmpNo = '';

  balanceId = 0;

  earningsCode = '';

  asOfDate: Date | string | null = null;

  beginBalance = 0;

  earnedAmount = 0;

  takenAmount = 0;

  currentBalance = 0;

  accrualRecords: AccrualRecord[] = [];

  static readonly convProps: PropTypeLists = {
    dateProps: ['asOfDate'],
    floatProps: ['beginBalance', 'earnedAmount', 'takenAmount', 'currentBalance'],
  };

  constructor(
    clientNo: number | string,
    empId: number | string,
    empNo: number | string,
    protectedEmpNo: string,
    balanceId: number | string,
    props?: { [key: string]: any },
  ) {
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empId = (typeof empId === 'string') ? parseInt(empId) : empId;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;
    this.balanceId = (typeof balanceId === 'string') ? parseInt(balanceId) : balanceId;

    props && convToClass(this, props, AccrualBalanceRecord.convProps);
  }
}