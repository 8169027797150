import React, { useEffect, useMemo, useState } from 'react';
import '../corrections.scss';
import FilteredEmployeeList from './FilteredEmployeeList';
import AllocationGrids from './AllocationGrids';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { loadDeptAllocationEmployees, loadPayroll, storeDeptAllocationEmployees } from 'core/store/actions';
import { AllocationEmployee } from 'core/models';

const currentYear = new Date().getFullYear();

const DeptAllocations = () => {
  const [selectedEmp, setSelectedEmp] = useState<AllocationEmployee>();
  
  const masterEmployees = useAppSelector((state) => { return state.employees.employees; });
  
  const protectedEmpNo = useMemo(() => {
    const match = masterEmployees.find((employee) => {return employee.empNo === selectedEmp?.empNo;});
    
    return match?.protectedEmpNo;
  }, [selectedEmp?.empNo]);
  
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(loadDeptAllocationEmployees());
    dispatch(loadPayroll({
      beginDate: `${currentYear - 4}-01-01T05:00:00.000Z`,
      endDate: `${currentYear}-12-31T05:00:00.000Z`,
      byCheckDate: true,
    }));
    
    return () => { dispatch(storeDeptAllocationEmployees([])); };
  }, []);
  
  return (
    <div className="page-container">
      <div className="employee-filter-container">
        <div className="list-wrapper">
          <FilteredEmployeeList
            protectedEmpNo={protectedEmpNo}
            selectedEmp={selectedEmp}
            setEmp={setSelectedEmp}
          />
        </div>
      </div>
      <div className="allocation-grids-container">
        <div className="list-wrapper">
          <AllocationGrids
            selectedEmp={selectedEmp}
            protectedEmpNo={protectedEmpNo}
          />
        </div>
      </div>
    </div>
  );
};

export default DeptAllocations;