import { AxiosResponse } from "axios";
import { CommonService } from '.';
import {
  ClientMasterDtoTaxEntitiesClientTaxEntity,
  ClientMasterDtoTaxEntitiesFIPS,
  EmployeeTaxInfo,
  HttpResponse,
  W4Info,
} from '../models';
import { API_CLIENT, API_EMPLOYEE_MASTER } from './API';
import { ClientMasterEndPoints, EmployeeEndPoints } from './URL';

export interface TaxRequest {
  clientNo?: string;
  protectedEmpNo: string;
  year?: number;
  entityId?: number;
  employeeMasterDtoTaxTaxEntity?: EmployeeTaxInfo | null;
}

const getTaxes = (
  request: TaxRequest,
): Promise<AxiosResponse<EmployeeTaxInfo>> => {
  return API_EMPLOYEE_MASTER.get<EmployeeTaxInfo>(
    EmployeeEndPoints.TAX({
      ...{ clientNo: CommonService.getProtectedClientNo() },
      ...request,
    }),
  );
};

const putTaxes = (
  request: TaxRequest,
): Promise<AxiosResponse<HttpResponse<EmployeeTaxInfo>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<EmployeeTaxInfo>>(
    EmployeeEndPoints.TAX({
      clientNo: CommonService.getProtectedClientNo(),
      protectedEmpNo: request.protectedEmpNo,
    }),
    request.employeeMasterDtoTaxTaxEntity,
  );
};

const generateTaxForm = (
  request: TaxRequest,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.TAX_GENERATETAXFROM(
      CommonService.getProtectedClientNo(),
      request.protectedEmpNo,
    ),
    request,
  );
};

const getTaxEntities = (): Promise<
  AxiosResponse<ClientMasterDtoTaxEntitiesClientTaxEntity[]>
> => {
  return API_CLIENT.get<ClientMasterDtoTaxEntitiesClientTaxEntity[]>(
    ClientMasterEndPoints.TAX_ENTITIES(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getW4Info = (request: TaxRequest): Promise<AxiosResponse<W4Info[]>> => {
  return API_EMPLOYEE_MASTER.get<W4Info[]>(
    EmployeeEndPoints.TAX_W4INFO(
      CommonService.getProtectedClientNo(),
      request.protectedEmpNo,
    ),
  );
};

export const TaxService = {
  getTaxes,
  putTaxes,
  generateTaxForm,
  getW4Info,
  getTaxEntities
};
