import { AvailableFont, CopyMapRequest, DownloaderPage, 
  DownloaderRequest, EmailRequest, FormatExportRequest, 
  ScheduledDownloadRequest, UserMap, UserMapItem } from 'core/models/Downloader.model';
import { DownloaderService } from 'core/services/downloader.service';
import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpResponse } from '../../models';
import { handleError, handleSuccess } from '../actions';
import {
  postDownloader,
  storeDownloader,
  getUserMaps,
  resetLoading,
  deleteUserMap,
  storeUserMaps,
  postUserMap,
  putUserMap,
  storeUserMap,
  putCopyMap,
  putEmails,
  putScheduleDownload,
  putFormatExport,
  getAvailableFonts,
  storeAvailableFonts,
  getDownloaderFields,
  storeDownloaderFields,
  getUserMap,
} from '../slices/downloader.slice';

interface Actions<T> {
  type: string;
  payload: T;
}

const getUserMaps$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getUserMaps.type),
    switchMap(() => {
      return from(DownloaderService.getUserMaps()).pipe(
        map((res: any) => { return res.data; }),
        map((res: UserMap) => { return storeUserMaps(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getUserMap$ = (action$: Observable<Actions<number>>) => {
  return action$.pipe(
    ofType(getUserMap.type),
    switchMap((action: { payload: number; }) => {
      return from(DownloaderService.getUserMapsId(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: UserMapItem) => { return storeUserMap(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postUserMap$ = (
  action$: Observable<Actions<UserMapItem>>,

) => {
  return action$.pipe(
    ofType(postUserMap.type),
    switchMap((action: { payload: UserMapItem; }) => {
      return from(DownloaderService.postUserMap(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getUserMaps(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const putUserMap$ = (action$: Observable<Actions<UserMapItem>>) => {
  return action$.pipe(
    ofType(putUserMap.type),
    switchMap((action: { payload: UserMapItem; }) => {
      return from(DownloaderService.putUserMap(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getUserMap(action.payload.userMapId),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const postDownloader$ = (action$: Observable<Actions<DownloaderRequest>>) => {
  return action$.pipe(
    ofType(postDownloader.type),
    switchMap((action: { payload: DownloaderRequest; }) => {
      return from(DownloaderService.postDownloader(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeDownloader(res.value); }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const putCopyMap$ = (
  action$: Observable<Actions<CopyMapRequest>>,

) => {
  return action$.pipe(
    ofType(putCopyMap.type),
    switchMap((action: { payload: CopyMapRequest; }) => {
      return from(DownloaderService.putCopyMap(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getUserMaps(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const putEmails$ = (
  action$: Observable<Actions<EmailRequest>>,

) => {
  return action$.pipe(
    ofType(putEmails.type),
    switchMap((action: { payload: EmailRequest; }) => {
      return from(DownloaderService.putEmails(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getUserMaps(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const putFormatExport$ = (
  action$: Observable<Actions<FormatExportRequest>>,

) => {
  return action$.pipe(
    ofType(putFormatExport.type),
    switchMap((action: { payload: FormatExportRequest; }) => {
      return from(DownloaderService.putFormatExport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getUserMaps(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const putScheduleDownload$ = (
  action$: Observable<Actions<ScheduledDownloadRequest>>,

) => {
  return action$.pipe(
    ofType(putScheduleDownload.type),
    switchMap((action: { payload: ScheduledDownloadRequest; }) => {
      return from(DownloaderService.putScheduleDownload(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getUserMaps(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const deleteUserMap$ = (
  action$: Observable<Actions<number>>,

) => {
  return action$.pipe(
    ofType(deleteUserMap.type),
    switchMap((action: { payload: number; }) => {
      return from(DownloaderService.deleteUserMap(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getUserMaps(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const getAvailableFonts$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getAvailableFonts.type),
    switchMap(() => {
      return from(DownloaderService.getAvailableFonts()).pipe(
        map((res: any) => { return res.data; }),
        map((res: AvailableFont[]) => { return storeAvailableFonts(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getDownloaderFields$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getDownloaderFields.type),
    switchMap(() => {
      return from(DownloaderService.getDownloaderFields()).pipe(
        map((res: any) => { return res.data; }),
        map((res: DownloaderPage[]) => { return storeDownloaderFields(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  getUserMaps$,
  getUserMap$,  
  postDownloader$,
  postUserMap$,
  putUserMap$,
  putCopyMap$,
  putEmails$,
  putFormatExport$,
  putScheduleDownload$,
  deleteUserMap$,
  getAvailableFonts$,
  getDownloaderFields$,
];
