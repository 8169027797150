import { AxiosResponse } from 'axios';
import {
  CheckCode,
  Department,
  Dropdown,
  HttpResponse,
  JobTitle,
  JobTitles,
  Location,
  PaygradeDropdown,
  SchoolDropdown,
  SubDepartment,
  SubDepartment2,
  TaxEntity,
} from '../models';
import {
  ShiftPremium,
  ShiftPremiumEarnings,
} from '../models/ShiftPremium.model';
import { API_CLIENT, API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { DropdownEndPoints } from './URL';

const getAllDropdowns = (): Promise<AxiosResponse<any>> => {
  return API_CLIENT.get<any>(
    DropdownEndPoints.GET_ALL(CommonService.getProtectedClientNo()),
  );
};

const createDropdown = (data: Dropdown): Promise<AxiosResponse<Dropdown>> => {
  return API_CLIENT.post<Dropdown>(
    DropdownEndPoints.POST_DROPDOWN(CommonService.getProtectedClientNo()),
    data,
  );
};

const updateDropdown = (data: Dropdown): Promise<AxiosResponse<Dropdown>> => {
  return API_CLIENT.put<Dropdown>(
    DropdownEndPoints.PUT_DROPDOWN(CommonService.getProtectedClientNo()),
    data,
  );
};

const deleteDropdown = (
  id: string,
  dropdownName: string,
): Promise<AxiosResponse<Dropdown>> => {
  return API_CLIENT.delete<Dropdown>(
    DropdownEndPoints.DELETE_DROPDOWN(
      id,
      dropdownName,
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getJobTitles = (): Promise<AxiosResponse<JobTitles>> => {
  return API_CLIENT.get<JobTitles>(
    DropdownEndPoints.GET_JOB_TITLES(CommonService.getProtectedClientNo()),
  );
};

const createJobTitle = (): Promise<AxiosResponse<JobTitle>> => {
  return API_CLIENT.post<JobTitle>(
    DropdownEndPoints.GET_JOB_TITLES(CommonService.getProtectedClientNo()),
  );
};

const updateJobTitle = (): Promise<AxiosResponse<JobTitle>> => {
  return API_CLIENT.put<JobTitle>(
    DropdownEndPoints.GET_JOB_TITLES(CommonService.getProtectedClientNo()),
  );
};

const deleteJobTitle = (): Promise<AxiosResponse<JobTitle>> => {
  return API_CLIENT.delete<JobTitle>(
    DropdownEndPoints.GET_JOB_TITLES(CommonService.getProtectedClientNo()),
  );
};

const getLocations = (): Promise<AxiosResponse<Location[]>> => {
  return API_CLIENT.get<Location[]>(
    DropdownEndPoints.GET_LOCATIONS(CommonService.getProtectedClientNo()),
  );
};

const getLocation = (locationID: number): Promise<AxiosResponse<Location>> => {
  return API_CLIENT.get<Location>(
    DropdownEndPoints.GET_LOCATION(
      locationID,
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getVerifyLocationCode = (locationID: number): Promise<boolean> => {
  return API_CLIENT.get<boolean>(
    DropdownEndPoints.GET_LOCATION(
      locationID,
      CommonService.getProtectedClientNo(),
    ),
  )
    .then(() => { return true; })
    .catch(() => { return false; });
};

const createLocation = (
  loc: Location,
): Promise<AxiosResponse<HttpResponse<Location>>> => {
  return API_CLIENT.post<HttpResponse<Location>>(
    DropdownEndPoints.POST_LOCATION(CommonService.getProtectedClientNo()),
    loc,
  );
};

const updateLocation = (
  loc: Location,
): Promise<AxiosResponse<HttpResponse<Location>>> => {
  return API_CLIENT.put<HttpResponse<Location>>(
    DropdownEndPoints.PUT_LOCATION(
      loc.locationID,
      CommonService.getProtectedClientNo(),
    ),
    loc,
  );
};

const deleteLocation = (locationId: number): Promise<AxiosResponse<any>> => {
  return API_CLIENT.delete<HttpResponse<Department>>(
    DropdownEndPoints.PUT_LOCATION(
      locationId,
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getDepartments = (): Promise<AxiosResponse<Department[]>> => {
  return API_CLIENT.get<Department[]>(
    DropdownEndPoints.DEPARTMENTS(CommonService.getProtectedClientNo()),
  );
};

const getDepartment = (deptId: number): Promise<AxiosResponse<Department>> => {
  return API_CLIENT.get<Department>(
    DropdownEndPoints.DEPARTMENT(
      deptId,
      CommonService.getProtectedClientNo(),
    ),
  );
};

const createDepartment = (
  dept: Department,
): Promise<AxiosResponse<HttpResponse<Department>>> => {
  return API_CLIENT.post<HttpResponse<Department>>(
    DropdownEndPoints.DEPARTMENTS(CommonService.getProtectedClientNo()),
    dept,
  );
};

const updateDepartment = (
  dept: Department,
): Promise<AxiosResponse<HttpResponse<Department>>> => {
  return API_CLIENT.put<HttpResponse<Department>>(
    DropdownEndPoints.DEPARTMENT(
      dept.deptId,
      CommonService.getProtectedClientNo(),
    ),
    dept,
  );
};

const deleteDepartment = (
  deptId: number,
): Promise<AxiosResponse<HttpResponse<Department>>> => {
  return API_CLIENT.delete<HttpResponse<Department>>(
    DropdownEndPoints.DEPARTMENT(
      deptId,
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getSubdepartments = (): Promise<AxiosResponse<SubDepartment[]>> => {
  return API_CLIENT.get<SubDepartment[]>(
    DropdownEndPoints.SUBDEPARTMENTS(CommonService.getProtectedClientNo()),
  );
};

const getSubdepartment = (
  subDeptID: number,
): Promise<AxiosResponse<SubDepartment>> => {
  return API_CLIENT.get<SubDepartment>(
    DropdownEndPoints.SUBDEPARTMENT(
      subDeptID,
      CommonService.getProtectedClientNo(),
    ),
  );
};

const createSubdepartment = (
  subDept: SubDepartment,
): Promise<AxiosResponse<HttpResponse<SubDepartment>>> => {
  return API_CLIENT.post<HttpResponse<SubDepartment>>(
    DropdownEndPoints.SUBDEPARTMENTS(CommonService.getProtectedClientNo()),
    subDept,
  );
};

const updateSubdepartment = (
  subDept: SubDepartment,
): Promise<AxiosResponse<HttpResponse<SubDepartment>>> => {
  return API_CLIENT.put<HttpResponse<SubDepartment>>(
    DropdownEndPoints.SUBDEPARTMENT(
      subDept.subDeptID,
      CommonService.getProtectedClientNo(),
    ),
    subDept,
  );
};

const deleteSubDepartment = (
  subDeptId: number,
): Promise<AxiosResponse<HttpResponse<SubDepartment>>> => {
  return API_CLIENT.delete<HttpResponse<SubDepartment>>(
    DropdownEndPoints.SUBDEPARTMENT(
      subDeptId,
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getSubdepartments2 = (): Promise<AxiosResponse<SubDepartment2[]>> => {
  return API_CLIENT.get<SubDepartment2[]>(
    DropdownEndPoints.SUBDEPARTMENTS2(CommonService.getProtectedClientNo()),
  );
};

const getSubdepartment2 = (
  subDept2ID: number,
): Promise<AxiosResponse<SubDepartment2>> => {
  return API_CLIENT.get<SubDepartment2>(
    DropdownEndPoints.SUBDEPARTMENT2(
      subDept2ID,
      CommonService.getProtectedClientNo(),
    ),
  );
};

const createSubdepartment2 = (
  subDept2: SubDepartment2,
): Promise<AxiosResponse<HttpResponse<SubDepartment2>>> => {
  return API_CLIENT.post<HttpResponse<SubDepartment2>>(
    DropdownEndPoints.SUBDEPARTMENTS2(CommonService.getProtectedClientNo()),
    subDept2,
  );
};

const updateSubdepartment2 = (
  subDept2: SubDepartment2,
): Promise<AxiosResponse<HttpResponse<SubDepartment2>>> => {
  return API_CLIENT.put<HttpResponse<SubDepartment2>>(
    DropdownEndPoints.SUBDEPARTMENT2(
      subDept2.subDept2ID,
      CommonService.getProtectedClientNo(),
    ),
    subDept2,
  );
};

const deleteSubDepartment2 = (
  subDept2Id: number,
): Promise<AxiosResponse<HttpResponse<SubDepartment2>>> => {
  return API_CLIENT.delete<HttpResponse<SubDepartment2>>(
    DropdownEndPoints.SUBDEPARTMENT2(
      subDept2Id,
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getShiftPremiums = (): Promise<AxiosResponse<ShiftPremium[]>> => {
  return API_CLIENT.get<ShiftPremium[]>(
    DropdownEndPoints.GET_SHIFT_PREMIUMS(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getShiftPremium = (
  shiftPremiumId: number,
): Promise<AxiosResponse<ShiftPremium>> => {
  return API_CLIENT.get<ShiftPremium>(
    DropdownEndPoints.GET_SHIFT_PREMIUM(
      shiftPremiumId,
      CommonService.getProtectedClientNo(),
    ),
  );
};

const postShiftPremium = (
  shiftPremium: ShiftPremium,
): Promise<AxiosResponse<ShiftPremium>> => {
  return API_CLIENT.post<ShiftPremium>(
    DropdownEndPoints.POST_SHIFT_PREMIUM(
      CommonService.getProtectedClientNo(),
    ),
    shiftPremium,
  );
};

const putShiftPremium = (
  shiftPremium: ShiftPremium,
): Promise<AxiosResponse<ShiftPremium>> => {
  return API_CLIENT.put<ShiftPremium>(
    DropdownEndPoints.PUT_SHIFT_PREMIUM(
      shiftPremium.shiftPremiumId,
      CommonService.getProtectedClientNo(),
    ),
    shiftPremium,
  );
};

const deleteShiftPremium = (
  shiftPremium: ShiftPremium,
): Promise<AxiosResponse<ShiftPremium>> => {
  return API_CLIENT.delete<ShiftPremium>(
    DropdownEndPoints.DELETE_SHIFT_PREMIUM(
      shiftPremium.shiftPremiumId,
      CommonService.getProtectedClientNo(),
    ),
  );
};

const postShiftPremiumEarningsCode = (
  data: ShiftPremiumEarnings,
): Promise<AxiosResponse<ShiftPremiumEarnings>> => {
  return API_CLIENT.post<ShiftPremiumEarnings>(
    DropdownEndPoints.POST_SHIFT_PREMIUM_EARNINGS_CODE(
      data.shiftPremiumId,
      CommonService.getProtectedClientNo(),
    ),
    data,
  );
};

const deleteShiftPremiumEarningsCode = (
  data: ShiftPremiumEarnings,
): Promise<AxiosResponse<ShiftPremiumEarnings>> => {
  return API_CLIENT.delete<ShiftPremiumEarnings>(
    DropdownEndPoints.DELETE_SHIFT_PREMIUM_EARNINGS_CODE(
      data.shiftPremiumId,
      data.shiftPremiumDetailId,
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getTaxEntities = (
  country: string,
  state: string,
  type: string,
): Promise<AxiosResponse<TaxEntity[]>> => {
  return API_CLIENT.get<TaxEntity[]>(
    DropdownEndPoints.GET_TAX_ENTITIES(country, state, type),
  );
};

const getCheckCodes = (): Promise<AxiosResponse<CheckCode[]>> => { return API_EMPLOYEE_MASTER.get<CheckCode[]>(DropdownEndPoints.GET_CHECK_CODES); };

const getPaygradeDropdowns = (): Promise<AxiosResponse<PaygradeDropdown[]>> => {
  return API_CLIENT.get<PaygradeDropdown[]>(
    DropdownEndPoints.GET_DROPDOWN_PAYGRADE(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const createPaygradeDropdown = (
  request: PaygradeDropdown,
): Promise<AxiosResponse<HttpResponse<PaygradeDropdown>>> => {
  return API_CLIENT.post<HttpResponse<PaygradeDropdown>>(
    DropdownEndPoints.POST_DROPDOWN_PAYGRADE(
      CommonService.getProtectedClientNo(),
    ),
    request,
  );
};

const updatePaygradeDropdown = (
  request: PaygradeDropdown,
): Promise<AxiosResponse<HttpResponse<PaygradeDropdown>>> => {
  return API_CLIENT.put<HttpResponse<PaygradeDropdown>>(
    DropdownEndPoints.PUT_DROPDOWN_PAYGRADE(
      CommonService.getProtectedClientNo(),
    ),
    request,
  );
};

const getEducationSchools = (): Promise<AxiosResponse<SchoolDropdown[]>> => {
  return API_CLIENT.get<SchoolDropdown[]>(
    DropdownEndPoints.SCHOOLS(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const createEducationSchools = (
  request: SchoolDropdown,
): Promise<AxiosResponse<HttpResponse<SchoolDropdown>>> => {
  return API_CLIENT.post<HttpResponse<SchoolDropdown>>(
    DropdownEndPoints.SCHOOLS(
      CommonService.getProtectedClientNo(),
    ),
    request,
  );
};

const updateEducationSchools = (
  request: SchoolDropdown,
): Promise<AxiosResponse<HttpResponse<SchoolDropdown>>> => {
  return API_CLIENT.put<HttpResponse<SchoolDropdown>>(
    DropdownEndPoints.SCHOOL(
      request.schoolId,
      CommonService.getProtectedClientNo(),
    ),
    request,
  );
};

export const DropdownService = {
  getAllDropdowns,
  createDropdown,
  updateDropdown,
  deleteDropdown,

  getJobTitles,
  createJobTitle,
  updateJobTitle,
  deleteJobTitle,

  getLocations,
  getLocation,
  getVerifyLocationCode,
  createLocation,
  updateLocation,
  deleteLocation,

  getDepartments,
  getDepartment,
  createDepartment,
  updateDepartment,
  deleteDepartment,

  getSubdepartments,
  getSubdepartment,
  createSubdepartment,
  updateSubdepartment,
  deleteSubDepartment,

  getSubdepartments2,
  getSubdepartment2,
  createSubdepartment2,
  updateSubdepartment2,
  deleteSubDepartment2,

  getShiftPremiums,
  getShiftPremium,
  postShiftPremium,
  putShiftPremium,
  deleteShiftPremium,
  postShiftPremiumEarningsCode,
  deleteShiftPremiumEarningsCode,

  getTaxEntities,
  getCheckCodes,

  getPaygradeDropdowns,
  createPaygradeDropdown,
  updatePaygradeDropdown,

  getEducationSchools,
  createEducationSchools,
  updateEducationSchools,
};
