import { Modal as ModalBootStrap, ProgressBar } from 'react-bootstrap';

type Props = {
  loadingMessage?: string
  showLoadingBar: boolean
};

const LoadingBar = ({
  showLoadingBar,
  loadingMessage = 'Loading...',
}: Props) => {
  return (
    <div>
        <ModalBootStrap
            backdrop="static"
            keyboard={false}
            show={showLoadingBar}
        >
        <ModalBootStrap.Header>
        <ModalBootStrap.Title>
            {loadingMessage}
        </ModalBootStrap.Title>
        </ModalBootStrap.Header>
        <ModalBootStrap.Body>
        <ProgressBar
            animated
            now={100}
        />
        </ModalBootStrap.Body>
        </ModalBootStrap>
    </div>
  );
};

export default LoadingBar;