import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class EmployeeUploadTranslation {
  translationId = 0;

  mapId = 0;

  originalValue = '';

  translatedValue = '';

  constructor(mapId: number) {
    this.mapId = mapId;
  }
}

export class EmployeeUploadMap {
  clientFileId = 0;

  mapId = 0;

  fieldId = 0;

  fieldOrdinal = 0;

  startPosition = 0;

  length = 0;

  decimals = 0;

  defaultValue = '';

  dedFreq = '';

  translations: EmployeeUploadTranslation[] = [];

  static readonly convProps: PropTypeLists = {
    numberProps: ['mapId', 'clientFileId', 'fieldId', 'fieldOrdinal', 'startPosition',
      'length', 'decimals'],
  };

  constructor(clientFileId: number | string, mapId: number | string, props?: { [key: string]: any }) {
    this.clientFileId = (typeof clientFileId === 'string') ? parseInt(clientFileId) : clientFileId;
    this.mapId = (typeof mapId === 'string') ? parseInt(mapId) : mapId;

    props && convToClass(this, props, EmployeeUploadMap.convProps);
  }
}

export class EmployeeUploadClientFile {
  clientNo = 0;

  clientFileId = 0;

  description = '';

  clientNoInFile = false;

  startOnLine = 1;

  style = 'D';

  delimiter = ',';

  fileName = '';

  filePath = '';

  useClientEntityId = false;

  appendOrOverwriteDeductions = 2;

  fields: EmployeeUploadMap[] = [];

  static readonly convProps: PropTypeLists = {
    numberProps: ['clientFileId', 'clientNo', 'startOnLine', 'appendOrOverwriteDeductions'],
    boolProps: ['clientNoInFile', 'useClientEntityId'],
  };

  constructor(clientNo: number | string, clientFileId: number | string, props?: { [key: string]: any }) {
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.clientFileId = (typeof clientFileId === 'string') ? parseInt(clientFileId) : clientFileId;

    props && convToClass(this, props, EmployeeUploadClientFile.convProps);
  }
}

export class EmployeeUploadFields {
  fieldId = 0;

  tableName = '';

  fieldName = '';

  displayTableName = '';

  displayFieldName = '';

  visiblePosition = 0;

  visible = true;

  mandatory = false;
}

export class EmployeeUploadVM {
  clientMaps: EmployeeUploadClientFile[] = [];

  fields: EmployeeUploadFields[] = [];
}

export class EmployeeUploadClientLibrary {
  clientFileId = 0;

  empCount = 0;

  fileName = '';

  file = '';

  constructor(clientFileId: number) {
    this.clientFileId = clientFileId;
  }
}