import { AgGridReact } from '@ag-grid-community/react';
import {
  AllModules,
  CellClassParams,
  CellValueChangedEvent,
  Column,
  ColumnApi,
  EditableCallbackParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  RowClassParams,
  RowClassRules,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
} from '@ag-grid-enterprise/all-modules';
import Modal from 'core/components/modals/Modal';
import Icon from 'core/components/shared/Icon';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import {
  SalesTaxTransmittalEntity,
  SalesTaxTransmittalEntityPayload,
} from 'core/models/SalesTaxTransmittal.model';
import { CommonService } from 'core/services';
import {
  clearSalesTaxTransmittalReport,
  closeSalesTaxTransmittal,
  loadSalesTaxTransmittalByEntity,
  loadSalesTaxTransmittalReport,
  updateSalesTaxTransmittalEntity,
} from 'core/store/actions';
import {
  getSalesTaxTransmittalEntity,
  getSalesTaxTransmittalReport,
} from 'core/store/selectors';
import { agInputNumberEditor } from 'utilities/ag-grid-editors';
import {
  agCurrencyRenderer,
  agPercentRenderer,
} from 'utilities/ag-grid-renderers';
import ConfirmModal from './Confirm.modal';
import { useAppSelector } from 'utilities/hooks';
import PageSpinner from 'core/components/shared/PageSpinner';
import { FormattedAmountRenderer } from 'utilities/ag-grid-renderers/FormattedAmountRenderer';
import { formatWithCommas } from 'utilities/utilities';

const numericValueSetter = (params: ValueSetterParams) => {
  if (+params.oldValue !== +params.newValue) {
    params.data[params.colDef.field ?? ''] = isNaN(+params.newValue) ? +params.oldValue : +params.newValue;
    return true;
  }
  return false;
};

const rowCanBeAdjusted = (data: SalesTaxTransmittalEntity): boolean => {
  const calculatedTax = (parseFloat(String(data.sales)) * (parseFloat(String(data.salesTaxRate)) / 100));
  const convertedVal = isNaN(calculatedTax) ? 0 : calculatedTax;
    
  if (data.salesTax < convertedVal) return true;
  return false;
};

type AdjustChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, params: ICellRendererParams) => void;
type CheckBoxCellRendererProps = ICellRendererParams & {
  readOnly: boolean,
  onChange: AdjustChangeHandler
};


const CheckBoxCellRenderer = (props: CheckBoxCellRendererProps) => {
  const data: SalesTaxTransmittalEntity = props.data;
  
  return data?.dateEntered ? (
    <div>
      <input
        type="checkbox"
        checked={data.adjusted}
        onChange={(e) => { props.onChange(e, props); }}
        disabled={props.readOnly}
        aria-disabled={props.readOnly}
      />
    </div>
  ) : null;
};

type PropTypes = {
  show: boolean;
  year: number;
  month: number;
  salesTaxMonthId: number;
  entityId: number;
  state: string;
  closed: boolean;
  onHide: () => void;
};

const SalesTaxDetailModal: React.FC<PropTypes> = ({
  show,
  year,
  month,
  salesTaxMonthId,
  entityId,
  state,
  closed,
  onHide,
}) => {  
  const salesTaxDataStore = useSelector(getSalesTaxTransmittalEntity);
  const report = useSelector(getSalesTaxTransmittalReport);
  const { loading } = useAppSelector(({ salesTaxTransmittal }) => salesTaxTransmittal);
    
  const [showConfirm, setShowConfirm] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const [salesTaxData, setSalesTaxData] = useState<SalesTaxTransmittalEntity[]>([]);
  const [revertState, setRevertState] = useState<SalesTaxTransmittalEntity[]>(salesTaxDataStore ?? []);
  const [closeMonthWarning, setCloseMonthWarning] = useState(salesTaxDataStore?.some((i) => i.sales === 0));
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [showMissingEntriesOnly, setShowMissingEntriesOnly] = useState<boolean>(false);
  const [rowsToBeAdjusted, setRowsToBeAdjusted] = useState<number[]>(
    salesTaxDataStore
      .filter((x) => rowCanBeAdjusted(x))
      .map((x) => x.salesTaxDetailId),
  );
  
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(
      loadSalesTaxTransmittalByEntity({
        entityId,
        month,
        year,
        salesTaxMonthId,
      }),
    );
  }, []);
  
  useEffect(() => {
    if (salesTaxDataStore) {
      setSalesTaxData(
        salesTaxDataStore.map((s) => {
          return { ...s };
        }),
      );
    }
    setRowsToBeAdjusted(salesTaxDataStore.filter((x) => rowCanBeAdjusted(x)).map((x) => x.salesTaxDetailId));
    setFormIsDirty(false);
  }, [salesTaxDataStore]);

  useEffect(() => {
    if (report) {
      CommonService.downloadBase64File(
        'application/pdf',
        report,
        'sales_tax_transmittal_report.pdf',
      );
      dispatch(clearSalesTaxTransmittalReport());
    }
  }, [report]);

  useEffect(() => {
    if (gridApi && gridColumnApi) {
      gridApi.setPinnedBottomRowData(generatePinnedBottomData());
    }
  }, [gridApi, gridColumnApi, salesTaxData]);
  
  const canEditCell = (params: EditableCallbackParams) => {
    const data: SalesTaxTransmittalEntity = params.data;
    return !closed && new Date(data?.dateEntered ?? new Date()) <= new Date();
  };
  
  const derivedCellClass = (params: CellClassParams) => closed || (new Date(params.data?.dateEntered ?? new Date()) > new Date());
  
  const onAdjust = (e: React.ChangeEvent<HTMLInputElement>, params: ICellRendererParams) => {
    const data: SalesTaxTransmittalEntity = params.data;

    setFormIsDirty(true);
    setRowsToBeAdjusted((prevState) => {
      if (!e.target.checked) {
        if (prevState.includes(data.salesTaxDetailId)) return prevState;
        return [...prevState, data.salesTaxDetailId];
      }
      return prevState.filter((x) => x !== data.salesTaxDetailId);
    });
    
    data.adjusted = e.target.checked;
    params.api.refreshCells({ force: true });
  };

  const rowClassRules: RowClassRules = {
    'adjust-sales-tax-row': (params: RowClassParams) => rowCanBeAdjusted(params.data),
  };

  const gridOptions: GridOptions = {
    columnDefs: [
      {
        headerName: 'Date',
        field: 'dateEntered',
        cellRenderer: (prop: any) => {
          return prop?.data?.dateEntered
            ? DateTime.fromISO(prop.data.dateEntered).toFormat(
              'ccc dd LLL yyyy',
            )
            : '';
        },
        width: 125,
        cellStyle: { 'justify-content': 'start' },
        cellClass: 'ag-cell-disabled-right',
      },
      {
        headerName: 'Sales',
        field: 'sales',
        editable: canEditCell, 
        width: 175,
        cellRenderer: 'formattedAmountRenderer',
        cellStyle: { 'justify-content': 'end', 'text-align': 'right' },
        cellClassRules: {
          'ag-cell-disabled-right': derivedCellClass,
          'ag-cell-enabled-right': (params: CellClassParams) => !derivedCellClass(params),
        },
        valueSetter: numericValueSetter,
      },
      {
        headerName: 'Calculated Sales',
        field: 'calculatedSales',
        width: 125,
        cellClass: 'ag-cell-disabled-right',
        cellStyle: { 'justify-content': 'end' },
        cellRenderer: 'formattedAmountRenderer',
        valueSetter: numericValueSetter,
        valueGetter: (params: ValueGetterParams) => {
          const data: SalesTaxTransmittalEntity = params.data;
          const caluclatedValue = (parseFloat(String(data.salesTax)) / (parseFloat(String(data.salesTaxRate)) / 100)).toFixed(2);
          return caluclatedValue === 'NaN' ? '0.00' : caluclatedValue;
        },
      },
      {
        headerName: 'Tax Rate',
        field: 'salesTaxRate',
        cellStyle: { 'justify-content': 'end' },
        cellClass: 'ag-cell-disabled-right',
        cellRenderer: 'percentRenderer',
      },
      {
        headerName: 'Sales Tax',
        field: 'salesTax',
        cellStyle: { 'justify-content': 'end' },
        editable: canEditCell, 
        cellRenderer: 'formattedAmountRenderer',
        cellClassRules: {
          'ag-cell-disabled-right': derivedCellClass,
          'ag-cell-enabled-right': (params: CellClassParams) => !derivedCellClass(params),
        },
        valueSetter: numericValueSetter,
      },
      {
        headerName: 'Calculated Tax',
        field: 'calculatedTax',
        cellStyle: { 'justify-content': 'end' },
        width: 125,
        cellRenderer: 'formattedAmountRenderer',
        cellClass: 'ag-cell-disabled-right',
        valueGetter: (params: ValueGetterParams) => {
          const data: SalesTaxTransmittalEntity = params.data;
          const caluclatedValue = (parseFloat(String(data.sales)) * (parseFloat(String(data.salesTaxRate)) / 100)).toFixed(2);
          return caluclatedValue === 'NaN' ? '0.00' : caluclatedValue;
        },
      },
      {
        headerName: 'Business or Personal Use',
        field: 'use',
        cellStyle: { 'justify-content': 'end' },
        width: 175,
        editable: canEditCell, 
        cellRenderer: 'formattedAmountRenderer',
        cellClassRules: {
          'ag-cell-disabled-right': derivedCellClass,
          'ag-cell-enabled-right': (params: CellClassParams) => !derivedCellClass(params),
        },
        valueSetter: numericValueSetter,
      },
      {
        headerName: 'Calculated Use Tax',
        field: 'calculatedUseTax',
        width: 125,
        cellStyle: { 'justify-content': 'end' },
        wrapText: true,
        cellRenderer: 'formattedAmountRenderer',
        cellClass: 'ag-cell-disabled-right',
        valueSetter: numericValueSetter,
        valueGetter: (params: ValueGetterParams) => {
          const data: SalesTaxTransmittalEntity = params.data;
          const caluclatedValue = (parseFloat(String(data.useTax)) / (parseFloat(String(data.useTaxRate)) / 100)).toFixed(2);
          return caluclatedValue === 'NaN' ? '0.00' : caluclatedValue;
        },
      },
      {
        headerName: 'Tax Rate',
        field: 'useTaxRate',
        cellStyle: { 'justify-content': 'end' },
        cellClass: 'ag-cell-disabled-right',
        cellRenderer: 'percentRenderer',
      },
      {
        headerName: 'Use Tax',
        field: 'useTax',
        editable: canEditCell, 
        cellRenderer: 'formattedAmountRenderer',
        cellStyle: { 'justify-content': 'end' },
        cellClassRules: {
          'ag-cell-disabled-right': derivedCellClass,
          'ag-cell-enabled-right': (params: CellClassParams) => !derivedCellClass(params),
        },
        valueSetter: numericValueSetter,
      },
      {
        headerName: 'Calculated Use',
        field: 'calculatedUse',
        width: 125,
        cellStyle: { 'justify-content': 'end' },
        cellRenderer: 'formattedAmountRenderer',
        cellClass: 'ag-cell-disabled-right',
        valueGetter: (params: ValueGetterParams) => {
          const data: SalesTaxTransmittalEntity = params.data;
          const caluclatedValue = (parseFloat(String(data.use)) * (parseFloat(String(data.useTaxRate)) / 100)).toFixed(2);
          return caluclatedValue === 'NaN' ? '0.00' : caluclatedValue;
        },
      },
      {
        headerName: 'Adjust',
        field: 'adjust',
        cellRenderer: (params: ICellRendererParams) => {
          const data: SalesTaxTransmittalEntity = params.data;
          const readOnly = !rowCanBeAdjusted(data);
          
          return CheckBoxCellRenderer({
            ...params,
            readOnly: readOnly,
            onChange: onAdjust,
          });
        },
        cellRendererParams: { readOnly: true },
        cellClass: 'ag-cell-disabled-right',
      },
    ],
    defaultColDef: {
      width: 75,
      resizable: true,
      editable: false,
      headerClass: 'grid-header',
      suppressMenu: true,
    },
    stopEditingWhenCellsLoseFocus: true,
    components: {
      inputNumberEditor: agInputNumberEditor,
      currencyRenderer: agCurrencyRenderer,
      percentRenderer: agPercentRenderer,
      formattedAmountRenderer: FormattedAmountRenderer,
    },
    frameworkComponents: {
      checkBoxCellRenderer: CheckBoxCellRenderer,
    },
  };

  const onSalesTaxClose = () => {
    setCloseMonthWarning(salesTaxData?.some((i) => { return i.sales === 0; }));
    setShowConfirm(true);
  };
  
  const handleHide = () => {
    if (formIsDirty) {
      setShowSaveModal(true);
    } else {
      onHide();
    }
  };

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      if (!closeMonthWarning) {
        setShowConfirm(false);
        dispatch(closeSalesTaxTransmittal({
          year,
          month,
        }));
        handleHide();
      } else {
        setCloseMonthWarning(false);
      }
    } else {
      setShowConfirm(false);
    }
  };

  const handleSaveConfirm = (confirm: boolean) => {
    if (confirm) {
      setShowSaveModal(false);
      onHide();
    }
  };

  const onPrint = () => {
    return dispatch(loadSalesTaxTransmittalReport({
      year,
      month,
      entityId,
      salesTaxMonthId,
    }));
  };

  const onCellValueChanged = (e: CellValueChangedEvent) => {
    if (e.oldValue !== e.newValue) e.data.modified = true;
    
    setFormIsDirty(e.oldValue !== e.newValue);
    setRowsToBeAdjusted((prevState) => {
      if (rowCanBeAdjusted(e.data)) {
        if (prevState.includes(e.data.salesTaxDetailId)) return prevState;
        return [...prevState, e.data.salesTaxDetailId];
      }
      return prevState.filter((x) => x !== e.data.salesTaxDetailId);
    });
    
    gridApi?.setPinnedBottomRowData(generatePinnedBottomData());
    gridApi?.refreshCells({ force: true });
  };

  const generatePinnedBottomData = () => {
    const aggRowData: any = {};
    gridColumnApi
      ?.getAllGridColumns()
      ?.forEach((col: Column) => { return (aggRowData[col.getColId()] = null); });
    return [calculatePinnedBottomData(aggRowData)];
  };

  const calculatePinnedBottomData = (aggRowData: any) => {
    if (!gridApi) return;
    //list of columns for aggregation
    const aggDataList: any = {};
    const columnsWithAggregation = [
      'sales',
      'salesTax',
      'calculatedTax',
      'use',
      'useTax',
      'calculatedUse',
    ];
    const columnsWithoutAggregation = [
      'calculatedSales',
      'salesTaxRate',
      'useTaxRate',
    ];
    columnsWithAggregation.forEach((colName) => {
      gridApi?.forEachNodeAfterFilter((rowNode: RowNode) => {
        if (rowNode.data[colName]) {
          aggRowData[colName] += +rowNode.data[colName];
        }
      });
      aggDataList[colName] = aggRowData[colName];
      if (colName === 'sales') {
        aggDataList[colName] =
          aggDataList[colName] != null
            ? (aggDataList[colName] = `Reported Sales: ${formatWithCommas(aggDataList?.[colName] ?? 0)}`)
            : 'Reported Sales: -';
      } else if (colName === 'use') {
        aggDataList[colName] =
          aggDataList[colName] != null
            ? `Reported Use: ${formatWithCommas(aggDataList?.[colName] ?? 0)}`
            : 'Reported Use: -';
      } else {
        aggDataList[colName] = formatWithCommas(aggDataList?.[colName] ?? 0);
      }
    });

    columnsWithoutAggregation.forEach((colName) => {
      aggDataList[colName] = '';
    });

    return aggDataList;
  };

  const onSelectMissingEntries = (showMissing: boolean) => {
    if (!gridApi) return;
    if (!showMissing) {
      setSalesTaxData(revertState);
      setShowMissingEntriesOnly(false);
      return;
    }
    
    const updatedRowData: SalesTaxTransmittalEntity[] = [];
    const revertRowData: SalesTaxTransmittalEntity[] = [];
    
    gridApi?.forEachNodeAfterFilter((rowNode: RowNode) => {
      const data: SalesTaxTransmittalEntity | undefined = rowNode?.data;
      
      if (data) {
        revertRowData.push(data);
        if (!data?.sales) updatedRowData.push(data);
      }
    });
    
    setRevertState(revertRowData); // what we can revert to when resetting
    setSalesTaxData(updatedRowData);
    setShowMissingEntriesOnly(true);
  };

  const onSaveChanges = () => {
    const salesTaxData: SalesTaxTransmittalEntity[] = [];
    gridApi?.forEachNodeAfterFilter((rowNode: RowNode) => {
      const data = rowNode.data;
      salesTaxData.push({
        ...salesTaxDataStore.find((s) => { return s.entityId === data.entityId; }),
        ...data,
      });
    });

    const salesTax = salesTaxData[0];
    const payload: SalesTaxTransmittalEntityPayload = {
      entityId: salesTax.entityId,
      month: salesTax.month,
      year: salesTax.year,
      salesTaxMonthId: salesTax.salesTaxMonthId,
      data: salesTaxData,
    };
    
    dispatch(updateSalesTaxTransmittalEntity(payload));
  };

  const onGridReady = (e: GridReadyEvent) => {
    setGridApi(e.api);
    setGridColumnApi(e.columnApi);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleHide}
        isDirty={formIsDirty}
        size="xl"
        title={closed
          ? 'Closed State Sales Tax Details'
          : 'State Sales Tax Details'}
        headerSlot={(
          <div className="d-flex justify-content-end">
            {closed === false && (
              <div className="dm-grid-title">
                <button
                  type="button"
                  className="btn btn-link dm-grid-action-title"
                  onClick={() => { return onSalesTaxClose(); }}
                >
                  Close Month{' '}
                  <Icon
                    name="calendar-alt"
                    className="fa-calendar-alt"
                  />
                </button>
              </div>
            )}
            <div className="dm-grid-title">
              <button
                type="button"
                className="btn btn-link dm-grid-action-title"
                onClick={() => { return onPrint(); }}
              >
                Print Sales Tax Detail{' '}
                <Icon
                  name="print"
                  className="fa-print"
                />
              </button>
            </div>
          </div>
        )}
      >
        <div className="row">
          <div
            className="col-12"
            style={{ minHeight: '400px' }}
          >
            {rowsToBeAdjusted?.length > 0 ? (
              <span style={{ fontSize: '1.025rem' }}>
                <strong>Notice:</strong> Highlighted rows have an entered sales tax amount that is less than the calculated tax amount.
                Clicking the checkbox in the &ldquo;Adjust&rdquo; column for that row will set the sales tax to the calculated tax amount once you save.
                If this difference is intentional, then no action is required and the entered amount will not be changed.
              </span>
            ) : null}
            <div className="dm-modal-title">
              {`${closed ? 'Closed ' : ''}${CommonService.getMonthName(month)} ${year} ${state}`}
            </div>
            <div
              className="ag-theme-balham"
              style={{ height: '700px' }}
            >
              {loading ? (
                <PageSpinner />
              ) : (
                <AgGridReact
                  modules={AllModules}
                  rowSelection="multiple"
                  rowData={salesTaxData}
                  gridOptions={gridOptions}
                  onGridReady={onGridReady}
                  onCellValueChanged={onCellValueChanged}
                  rowClassRules={rowClassRules}
                />
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-2">
          <button
            type="button"
            className="btn btn-primary orange-button mr-2"
            onClick={() => { onSelectMissingEntries(!showMissingEntriesOnly); }} 
            disabled={!salesTaxDataStore.length}
          >
            {showMissingEntriesOnly ? 'Reset' : 'Select Missing Entries'}
          </button>
          <button
            type="button"
            className="btn btn-primary orange-button"
            onClick={onSaveChanges}
            disabled={!formIsDirty}
          >
            Save
          </button>
        </div>
      </Modal>
      {showConfirm && (
        <ConfirmModal
          show={showConfirm}
          title={
            closeMonthWarning ? 'Error Close Month' : 'Close Month'
          }
          body={
            closeMonthWarning
              ? 'One or more days have no business activity. Continue to Close the Month ? '
              : 'Are you sure you want to Close the Month ?'
          }
          onConfirm={(confirm: boolean) => { return handleConfirm(confirm); }}
          onHide={() => { return setShowConfirm(false); }}
        />
      )}

      {showSaveModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close?'}
          show={showSaveModal}
          onConfirmed={handleSaveConfirm}
          onHide={() => { return setShowSaveModal(false); }}
        />
      )}
    </>
  );
};

export default SalesTaxDetailModal;
