import rootReducer from './reducers';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './epics';
import { Action, configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
} from 'redux-persist';
import { loadUserAccess } from './actions';
import { SessionStorageService } from 'core/services';
import { isTestEnvironment } from 'core/constants';
import { Observable } from 'rxjs';

const epicMiddleware = createEpicMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(epicMiddleware);
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

epicMiddleware.run((action$: Observable<Action<any>>, _store, dependencies): Observable<Action<any>> => rootEpic(action$, _store, dependencies));

// PI-8410: This should replace that janky call to this on the home page and kick this off before render. Also gives us much more control over this piece.
if (isTestEnvironment || SessionStorageService.getIsAuthenticated()) { // make sure we have a user before loading their access
  store.dispatch(loadUserAccess()); 
} 

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
