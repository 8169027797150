import React, { CSSProperties } from 'react';
import { InputGrp, SelectGrp } from 'core/components/form-controls';
import Icon from 'core/components/shared/Icon';
import { PayrollsUserOptions, TransmittalDeduction } from 'core/models';
import { getAllDeductionCodes, getDeductionUnits, getPayrollsUserOptions } from 'core/store/selectors';
import { ArrayField } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ddLookup, formatWithCommas } from 'utilities/utilities';
import { FormMethods } from 'core/components/form-controls/types';

const unitColumnStyles: CSSProperties = { width: '75px' };
const inputStyle: CSSProperties = { textAlign: 'right' };

type Props = {
  deduction: Partial<ArrayField<TransmittalDeduction, 'id'>>;
  checkIndex: number;
  deductionIndex: number;
  isReadOnly: boolean;
  errors: FormMethods['errors'];
  setValue: FormMethods['setValue'];
  register: FormMethods['register'];
  watch: FormMethods['watch'];
  remove: (index?: number | number[] | undefined) => void
};

const TransmittalModalDeductionsItem = ({
  deduction,
  checkIndex,
  deductionIndex,
  isReadOnly,
  errors,
  setValue,
  register,
  watch,
  remove,
}: Props) => {
  const payrollsUserOptions: PayrollsUserOptions | null = useSelector(getPayrollsUserOptions);
  const deductionCodeOpts = useSelector(getAllDeductionCodes);
  const deductionUnitOpts = useSelector(getDeductionUnits);
  
  const watchedDedAmt = watch(`checks[${checkIndex}].deductions[${deductionIndex}].dedAmount`);
  
  return (
    <>
      <div className="row">
        <input
          type="hidden"
          name={`checks[${checkIndex}].deductions[${deductionIndex}].transmittalDeductionId`}
          value={deduction.transmittalDeductionId}
          ref={register({
            valueAsNumber: true,
          })}
        />
        <SelectGrp
          groupClass="col"
          options={deductionCodeOpts}
          name={`checks[${checkIndex}].deductions[${deductionIndex}].dedNo`}
          addEmptyValue={true}
          showId={true}
          errorMsg="Required"
          errors={errors.dedNo}
          ref={register({
            valueAsNumber: true,
          })}
          label="&nbsp;"
          disabled={deduction.transmittalDeductionId === 0}
          defaultValue={deduction.dedNo}
        />
        <InputGrp
          label="Amount"
          groupClass={`col ${deduction.transmittalDeductionId === 0
            ? ''
            : 'number-underline'
          }`}
          name={`checks[${checkIndex}].deductions[${deductionIndex}].dedAmount`}
          ref={register({
            valueAsNumber: true,
          })}
          places={payrollsUserOptions?.amountDecimals}
          setValue={setValue}
          defaultValue={formatWithCommas(watchedDedAmt, payrollsUserOptions?.amountDecimals)}
          disabled={deduction.transmittalDeductionId === 0}
          inputStyle={inputStyle}
          type={'text'}
          formatNumber
          hiddenValue={watchedDedAmt ?? 0}
          hiddenRef={register({
            valueAsNumber: true,
          })}
        />
        {deduction.transmittalDeductionId === 0 ? (
          <div
            className="dm-grid-action-title d-flex align-items-center mt-3"
            style={unitColumnStyles}
          >
            {ddLookup(deduction.unit, deductionUnitOpts)}
          </div>
        ) : null}
        <div className="col-auto">
          {deduction.transmittalDeductionId !== 0 && (
            <button
              type="button"
              className="mt-3 btn btn-link dm-grid-action-title"
              onClick={() => { return remove(deductionIndex); }}
            >
              Delete
              <Icon
                name="minus-circle"
                className="fa-minus-circle"
              />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default TransmittalModalDeductionsItem;