import React, { useState } from 'react';
import Modal from 'core/components/modals/Modal';
import Icon from 'core/components/shared/Icon';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { PayrollUploadErrors } from 'core/models/PayrollUpload.model';

type PropTypes = {
  show: boolean;
  fileToolErrors: PayrollUploadErrors[];
  onHide: () => void;
  onConfirm: (confirm: boolean) => void;
  onTrackedEmployee: (empNo: string) => void;
  onSelectAllEmployees: (selectAll: boolean) => void;
  trackedEmployees: string[];
};

const FileToolErrorsModal = ({
  show,
  fileToolErrors,
  onHide,
  onConfirm,
  onTrackedEmployee,
  onSelectAllEmployees,
  trackedEmployees,
}: PropTypes) => {

  const isTracked = (empNo: string) => {
    return trackedEmployees.includes(empNo);
  };
  const uploadFileErrors = structuredClone(fileToolErrors.filter(x => !x.isHighValidationError));
  const highValidationErrors = structuredClone(fileToolErrors.filter(x => x.isHighValidationError));
  const selectAllChecked = trackedEmployees.length === highValidationErrors.map(x => x.empNo).length;
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Payroll Upload Validation Errors"
    >
      {(closeModal) => {
        return (
          <>
            <div className="row">
              <div className="col-12  m-2">
                <h4>
                  Would you like to continue the upload with the
                  following validations errors?
                  <br />
                  Hit Cancel to exit and fix the errors.
                </h4>

                {/*This is for the Employee Errors and will not need a check box option*/}
                {uploadFileErrors.length > 0 ? <Accordion>
                  <AccordionSummary
                    expandIcon={<Icon
                      name={'chevron-down'}
                      className="mr-1"
                    />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Upload File Errors</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {uploadFileErrors.map((value, index) => {
                      return (
                        <div
                          className="modal-error-panel p-2 m-2"
                          key={index}
                        >
                          <div>EmpNo: {value.empNo}</div>
                          <div>Employee Name: {value.empName}</div>
                          <div>Error Message: {value.error}</div>
                        </div>);
                    })}
                  </AccordionDetails>
                </Accordion> : null}

                {/*This is for the high gross/hour errors and will need a check box option*/}
                {highValidationErrors.length > 0 ? <Accordion>
                  <AccordionSummary
                    expandIcon={<Icon
                      name={'chevron-down'}
                      className="mr-1"
                    />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>High Hours, High Gross, and Zero Pay Rate with Hours Errors</Typography>
                  </AccordionSummary>
                  {highValidationErrors?.filter(x => x.isHighHourError || x.isHighValidationError)?.length > 0 ? <div style={{ paddingLeft: '20px' }}>
                    <label style={{ paddingRight: '10px' }}>
                      Track All Hours:   
                    </label>
                    <input
                      type="checkbox"
                      onChange={(e: any) => onSelectAllEmployees(e.target.checked)}
                      checked={selectAllChecked}
                    />
                  </div> : null}
                  <AccordionDetails>
                    {highValidationErrors.map((value, index) => {
                      return (
                        <div
                          className="modal-error-panel p-2 m-2"
                          key={index}
                        >
                          <div>EmpNo: {value.empNo}
                            {value.isHighHourError || value.isHighValidationError ? (
                              <button
                                type="button"
                                className={`btn orange-${isTracked(value.empNo) ? '' : 'outline-'}button-xs`}
                                style={{ float: 'right' }}
                                title={isTracked(value.empNo) ?
                                  'Click here to stop tracking these hours' :
                                  'Click here to track the hours entered on this line'}
                                onClick={() => {
                                  onTrackedEmployee(value.empNo);
                                }}
                              >
                                {isTracked(value.empNo) ? 'Tracking Hours' : (<br />) }
                              </button>
                            ) : null}
                          </div>
                          <div>Employee Name: {value.empName}</div>
                          <div>Error Message: {value.error}</div>
                        </div>);
                    })}
                  </AccordionDetails>
                </Accordion> : null}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div className="mr-2">
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-primary orange-button"
                  onClick={() => {
                    onConfirm(true);
                    closeModal();
                  }}
                >
                  Continue Uploading
                </button>
              </div>
            </div>
          </>
        );
      }}
    </Modal>

  );
};

export default FileToolErrorsModal;
