import { ofType } from 'redux-observable';
import { catchError, from, map, mergeMap, Observable, switchMap } from 'rxjs';
import {
  Client,
  ClientMasterDtoTaxEntitiesFIPS,
  ClientOption,
  ClientOptions,
  HttpResponse,
  K401Options,
  Option,
} from '../../models';
import { ClientService } from '../../services';
import {
  load401KOptions,
  loadClient,
  loadClientEnumsOptions,
  loadClientOption,
  loadClientOptions,
  loadClientDeductions,
  putClientOption,
  store401KOptions,
  storeClient,
  storeClientEnumsOptions,
  storeClientOption,
  storeClientOptions,
  storeClientDeductions,
  loadClientTaxEntityFips,
  storeClientTaxEntityFips,
  loadIsNOVAClient,
  storeIsNOVAClient,
} from '../actions';
import { handleError, handleSuccess } from '../actions/app.action';
import { ClientDeduction } from 'core/models/ClientDeduction.model';

interface Actions<Type> {
  type: string;
  payload: Type;
}

const loadClient$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadClient.type),
    switchMap((action: any) => {
      return from(ClientService.getClient()).pipe(
        map((res: any) => {return res.data;}),
        map((res: Client) => {
          return storeClient(res);
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadClientOptions$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadClientOptions.type),
    switchMap((action: any) => {
      return from(ClientService.getClientOptions()).pipe(
        map((res: any) => {return res.data;}),
        map((res: ClientOptions) => {return storeClientOptions(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadClientOption$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadClientOption.type),
    switchMap((action: { payload: { optionId: number; }; }) => {
      return from(ClientService.getClientOption(action.payload.optionId)).pipe(
        map((res: any) => {return res.data;}),
        map((res: ClientOption) => {return storeClientOption(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadIsNOVAClient$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadIsNOVAClient.type),
    switchMap(() => {
      return from(ClientService.getIsNOVAClient()).pipe(
        map((res) => {return res.data;}),
        map((res) => {return storeIsNOVAClient(res.isNovaClient);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadClientDeductions$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadClientDeductions.type),
    switchMap(() => {
      return from(ClientService.getClientDeductions()).pipe(
        map((res: any) => {return res.data;}),
        map((data: ClientDeduction[]) => {return storeClientDeductions(data);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadClientTaxFips$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadClientTaxEntityFips.type),
    switchMap(() => {
      return from(ClientService.getClientTaxFips()).pipe(
        map((res: any) => {return res.data;}),
        map((data: ClientMasterDtoTaxEntitiesFIPS[]) => {return storeClientTaxEntityFips(data);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const putClientOption$ = (action$: Observable<Actions<ClientOption>>) => {
  return action$.pipe(
    ofType(putClientOption.type),
    switchMap((action: { payload: ClientOption; }) => {
      return from(ClientService.putClientOption(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<ClientOption>) => {
          return [
            storeClientOption(res.value),
          ];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadClientEnumsOptions$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadClientEnumsOptions.type),
    switchMap(() => {
      return from(ClientService.getClientEnumsOptions()).pipe(
        map((res: any) => {return res.data;}),
        map((res: Option[]) => {return storeClientEnumsOptions(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const load401KOptions$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(load401KOptions.type),
    switchMap(() => {
      return from(ClientService.get401KOptions()).pipe(
        map((res: any) => {return res.data;}),
        map((res: K401Options) => {return store401KOptions(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadClient$,
  loadClientOptions$,
  loadClientOption$,
  putClientOption$,
  loadClientEnumsOptions$,
  load401KOptions$,
  loadIsNOVAClient$,
  loadClientDeductions$,
  loadClientTaxFips$,
];
