import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { AttendanceOnDemand, HttpResponse, WorkgroupItem } from '../../models';
import { AttendanceOnDemandService } from '../../services';
import {
  createClientAODWorkgroup,
  handleError,
  handleSuccess,
  loadAttendanceOnDemand,
  loadClientAttendanceOnDemand,
  storeAODItems,
  storeAttendanceOnDemand,
  storeClientAttendanceOnDemand,
  updateAttendanceOnDemand,
  updateClientAODWorkgroup,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadAttendanceOnDemand$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadAttendanceOnDemand.type),
    switchMap((action: { payload: string; }) => {
      return from(AttendanceOnDemandService.getAOD(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: AttendanceOnDemand) => { return storeAttendanceOnDemand(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateAttendanceOnDemand$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateAttendanceOnDemand.type),
    switchMap((action: { payload: AttendanceOnDemand; }) => {
      return from(AttendanceOnDemandService.putAOD(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<AttendanceOnDemand>) => {
          return [
            storeAttendanceOnDemand(res.value),
            handleSuccess(res.messages),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadClientAttendanceOnDemand$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadClientAttendanceOnDemand.type),
    switchMap((action: { payload: { suppressError?: boolean } }) => {
      return from(AttendanceOnDemandService.getClientAOD()).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            storeClientAttendanceOnDemand(res),
            storeAODItems(res),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          if (action.payload?.suppressError) return [];
          return [handleError(err)];
        }),
      );
    },
    ),
  );
};

const createAODWorkGroup$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createClientAODWorkgroup.type),
    switchMap((action: { payload: WorkgroupItem; }) => {
      return from(
        AttendanceOnDemandService.postAODWorkgroup(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return loadClientAttendanceOnDemand({ suppressError: false }); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateAODWorkGroup$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateClientAODWorkgroup.type),
    switchMap((action: { payload: WorkgroupItem; }) => {
      return from(
        AttendanceOnDemandService.putAODWorkgroup(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return loadClientAttendanceOnDemand({ suppressError: false }); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadAttendanceOnDemand$,
  updateAttendanceOnDemand$,
  loadClientAttendanceOnDemand$,
  createAODWorkGroup$,
  updateAODWorkGroup$,
];
