import React, { DependencyList, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import EmployeeInformation from 'core/components/shared/EmployeeInformation';
import { loadEmployee, storePrevHRLink, storeSelectedEmployee, toggleBlockNavigation } from 'core/store/actions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import EmployeeStatusPage from './employee-hire-info/EmployeeStatusPage';
import HrGeneralPage from './employee-hire-info/HireGeneralPage';
import HrEmployeeStatusCompanyPropertyPage from './employee-status/CompanyPropertyPage';
import HrEmployeeStatusCustomPage from './employee-status/CustomPage';
import HrEmployeeStatusDatesPage from './employee-status/DatesPage';
import DependentsPage from './employee-status/DependentsPage';
import HrEmployeeStatusEmergencyContactPage from './employee-status/EmergencyContactPage';
import HrEmployeeStatusGeneralPage from './employee-status/GeneralPage';
import HrEmployeeStatusPayPage from './employee-status/PayPage';
import HrEmployeeStatusStatusPage from './employee-status/StatusPage';
import EducationPage from './employee-training/EducationPage';
import EmploymentPage from './employee-training/EmploymentPage';
import LicensePage from './employee-training/LicensePage';
import SkillsPage from './employee-training/SkillsPage';
import SourcesPage from './employee-training/SourcesPage';
import TrainingEventPage from './employee-training/TrainingEventPage';
import EmployeeList from '../employee/EmployeeList';
import HrProfileSidebar from './HrProfileSidebar';
import ExitInterviewPage from './performance-appraisals/ExitInterviewPage';
import IncreasePage from './performance-appraisals/IncreasePage';
import ReviewsPage from './performance-appraisals/ReviewsPage';
import VaccinationPage from './vaccination-reporting/VaccinationPage';
import Icon from 'core/components/shared/Icon';
import { IconTypes } from 'enums/IconTypes';
import ConfirmationModal from 'core/components/modals/confirmation.modal';

type HrParams = {
  protectedEmpNo: string;
};

type BlockNavProps = {
  shouldBlock: boolean;
  handleBlock?: (newVal: boolean, newPath: string[]) => void;
  whiteList?: string[];
  dependencies?: DependencyList;
};

const titleSwitch = {
  sections: [
    {
      title: 'Employee Hire Info',
      relatedPaths: ['general-info', 'general-info-status'],
    },
    {
      title: 'Employee Status',
      relatedPaths: [
        'general',
        'dates',
        'status',
        'emergency-contact',
        'pay',
        'custom',
        'company-property',
        'dependents',
      ],
    },
    {
      title: 'Training',
      relatedPaths: [
        'training-employment',
        'training-education',
        'training-events',
        'training-licenses',
        'training-skills',
        'training-sources',
      ],
    },
    {
      title: 'Performance Appraisal',
      relatedPaths: [
        'exit-interview',
        'appraisal-reviews',
        'appraisal-increase',
      ],
    },
    {
      title: 'Vaccination Record',
      relatedPaths: ['vaccination'],
    },
  ],
};

const HrProfilePage = () => {
  const dispatch = useAppDispatch();

  const [title, setTitle] = useState<string>('Employee List');
  const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false);
  const [path, setPath] = useState<string[]>([]);
  
  const { protectedEmpNo } = useParams<HrParams>();
  
  const history = useHistory();
  
  const location = useLocation();
  const isEmployeeList = location.pathname.includes('employee-list');

  const { blockNavigation } = useAppSelector((state) => state.app);
  const selectedEmployee = useAppSelector((state) => state.selEmployee.employee);
  const { employees, filteredEmployees } = useAppSelector((state) => state.employees );
  const groupAccess = useAppSelector((state) => {return state.app.moduleAccess?.groups;});
 
  const emGroupAccessParams = groupAccess?.find((group) => { return group.description === 'Employee Master'; });
  
  const toggleShowConfirm = (newVal: boolean, newPath: string[]) => {
    setShowNotificationModal(newVal);
    setPath(newPath);
  };
  
  const unblock = (shouldBlock?: boolean) => history.block((_location, _action) => {
    if (shouldBlock) {
      toggleShowConfirm(true, _location.pathname.split('/'));
      return false;
    }
    
    unblock();
    
    return;
  });
  
  const handleBlock = (newVal: boolean, newPath: string[]) => {
    toggleShowConfirm(newVal, newPath);
  };
  
  const onConfirm = (confirmed: boolean, _path?: string[]) => {
    if (!confirmed) return;
    
    unblock();
    
    dispatch(toggleBlockNavigation({ block: false, message: undefined }));
    setShowNotificationModal(false);
   
    if (_path) {
      history.push(_path.join('/'));
      if (_path.includes('detail')) dispatch(loadEmployee(_path[3]));
    }
  };
  
  const unblockValidation = (shouldBlock?: boolean, handleBlock?: (newVal: boolean, newPath: string[]) => void, whiteList?: string[]) => history.block((_location, _action) => {
    if (!(whiteList?.length && whiteList.find((x) => _location.pathname.includes(x)))) {
      if (shouldBlock) {
        handleBlock?.(true, _location.pathname.split('/'));
        return false;
      }
      unblockValidation();
    }
    
    return;
  });
  
  const NavBlock = ({ shouldBlock, handleBlock, whiteList, dependencies = [] }: BlockNavProps) => {
    useEffect(() => {
      unblockValidation(shouldBlock, handleBlock, whiteList);
      
      return () => {
        unblockValidation();
      };
    }, [shouldBlock, history, ...dependencies]);

    return null;
  };

  useEffect(() => {
    if (protectedEmpNo === undefined && filteredEmployees.length) {
      dispatch(storeSelectedEmployee(filteredEmployees[0]));
      history.push(
        `/hr-profile/detail/${filteredEmployees[0].protectedEmpNo}/employee-list`,
      );
    }
    if (protectedEmpNo) {
      dispatch(loadEmployee(protectedEmpNo));
    }
  }, [protectedEmpNo]);

  useEffect(() => {
    if (location) {
      const path = location.pathname.split('/').pop() ?? '';
      const title = titleSwitch.sections?.find((s) => { return s.relatedPaths.includes(path); },
      )?.title;
      setTitle(title ?? 'Employee List');
    }
  }, [location]);
  
  const onPrevEmp = () => {
    // TODO: this could probably just be handled in the middleware somewhere
    const definedEmps = structuredClone(filteredEmployees);
    
    if (!definedEmps?.length) return console.error('Error: no employees');
    if (!selectedEmployee) return;
    
    const index = definedEmps.findIndex((e) => { return e.empNo === selectedEmployee.empNo; });
    const _path = location.pathname.split('/');
    
    if (!(index > 0)) {
      if (!confirm('You have reached the beginning of the list. Continue to last employee?')) return;
      _path[3] = definedEmps[definedEmps.length - 1].protectedEmpNo;
    } else {
      _path[3] = definedEmps[index - 1].protectedEmpNo;
    }
    
    if (blockNavigation.block) {
      toggleShowConfirm(true, _path);
    } else {
      history.push(_path.join('/'));
      dispatch(loadEmployee(_path[3]));
    }
  };
  
  const onNextEmp = () => {
    const definedEmps = structuredClone(filteredEmployees);
    
    if (!definedEmps?.length) return console.error('Error: no employees');
    if (!selectedEmployee) return;
    
    const index = definedEmps.findIndex((e) => { return e.empNo === selectedEmployee.empNo; });
    const _path = location.pathname.split('/');
    
    if (!(index >= 0 && index < definedEmps.length - 1)) {
      if (!confirm('You have reached the end of the list. Continue to first employee?')) return;
      _path[3] = definedEmps[0].protectedEmpNo;
    } else {
      _path[3] = definedEmps[index + 1].protectedEmpNo;
    }

    if (blockNavigation.block) {
      toggleShowConfirm(true, _path);
    } else {
      history.push(_path.join('/'));
      dispatch(loadEmployee(_path[3]));
    }
  };

  return (
    <Col>
      <NavBlock
        shouldBlock={blockNavigation.block}
        handleBlock={handleBlock}
        dependencies={[]}
      />
      {showNotificationModal && blockNavigation.block && !!blockNavigation?.message && !!path.length && (
        <ConfirmationModal
          title="You have unsaved changes"
          show={showNotificationModal && blockNavigation.block && !!blockNavigation?.message}
          message={blockNavigation.message}
          onConfirmed={onConfirm}
          onHide={() => { toggleShowConfirm(false, []); }}
          path={path}
        /> 
      )
      }
      <Row>
        {!isEmployeeList && (
          <Col sm={3}>
            <HrProfileSidebar />
          </Col>
        )}
        <Col sm={isEmployeeList ? 12 : 9}>
          <Row className="align-items-end mb-3">
            <Col>
              <h2 className="dm-page-title">{title}</h2>
            </Col>
            <Col className="text-right mt-2">
              {emGroupAccessParams?.visible !== false ? (
                <Link
                  to={{
                    pathname: `/employee/detail/${protectedEmpNo}/snapshot`,
                    state: { prevPath: location.pathname },
                  }}
                  onClick={() => {
                    dispatch(storePrevHRLink(location.pathname));
                  }}
                  className="btn orange-outline-button mr-3"
                >
                  To Employee Master
                </Link>
              ) : null}
              {!isEmployeeList && (
                <>
                  <div className="d-flex d-flex-row justify-content-end mt-3 mr-3">
                    <div className="d-flex justify-content-between mr-2">
                      <Icon
                        name="circle-arrow-left"
                        type={IconTypes.THIN}
                        fontSize={'1.5rem'}
                        onClick={onPrevEmp}
                      />
                      <span className="dm-grid-action-title2 px-2">
                        Employee
                      </span>
                      <Icon
                        name="circle-arrow-right"
                        type={IconTypes.THIN}
                        fontSize={'1.5rem'}
                        onClick={onNextEmp}
                      />
                    </div>
                  </div>
                </>
              )}
            </Col>
          </Row>
          <hr className="dm-page-hr" />
          <div className="shadow border p-3 dm-panel dm-panel-border">
            {!location.pathname.includes('employee-list') && (
              <Row>
                <Col xs={12}>
                  <EmployeeInformation
                    protectedEmpNo={protectedEmpNo}
                  />
                </Col>
              </Row>
            )}
            <Switch>
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/employee-list"
                component={EmployeeList}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/general-info"
                component={HrGeneralPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/general-info-status"
                component={EmployeeStatusPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/general"
                component={HrEmployeeStatusGeneralPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/dates"
                component={HrEmployeeStatusDatesPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/status"
                component={HrEmployeeStatusStatusPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/emergency-contact"
                component={HrEmployeeStatusEmergencyContactPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/pay"
                component={HrEmployeeStatusPayPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/custom"
                component={HrEmployeeStatusCustomPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/company-property"
                component={HrEmployeeStatusCompanyPropertyPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/dependents"
                component={DependentsPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/training-employment"
                component={EmploymentPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/training-education"
                component={EducationPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/training-events"
                component={TrainingEventPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/training-licenses"
                component={LicensePage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/training-skills"
                component={SkillsPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/training-sources"
                component={SourcesPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/exit-interview"
                component={ExitInterviewPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/appraisal-reviews"
                component={ReviewsPage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/appraisal-increase"
                component={IncreasePage}
              />
              <Route
                exact
                path="/hr-profile/detail/:protectedEmpNo/vaccination"
                component={VaccinationPage}
              />
            </Switch>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default HrProfilePage;
