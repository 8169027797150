export interface PayrollUser {
  hrEntityId: number;
  name: string;
  secUserId: number;
}

export interface CustomInterviewEmail {
  customEmailId: number
  description: string;
  emailBody: string;
  emailSubject: string;
  hrEntityId: number;
}

export interface InterviewQuestionsReport {
  value: string;
}

export interface WebsiteImage {
  webSiteImageId: number;
  webSiteConfigId: number;
  name: string;
  size: number;
  extension: string;
  visible: boolean;
  image: string;
}

export interface WebsiteWaiver {
  waiverId: number;
  visableRequired: boolean;
  waiverTitle: string;
  waiverDescription: string;
}

export interface WebsiteCustomWaiver {
  customWaiverId: number;
  webSiteConfigId: number;
  title: string;
  description: string;
  visible: boolean;
  required: boolean;
  archived: boolean;  
  modifiedUser?:string;
}

export interface PageField {
  fieldId: number;
  pageId: number;
  fieldDescription: string;
  visible: boolean;
  required: boolean;
  answers: PageFieldAnswer[];
}

export interface PageFieldAnswer {
  answerId: number;
  description: string;
  fieldId: number;
  required: boolean;
  visible: boolean;
}

export interface CustomAnswer {
  customAnswerId: number;
  customFieldId: number;
  label: string;
  name: string;
  visible: boolean;
  required: boolean;
  description: string;
}


export interface CustomFieldQA {
  question: string;
  answer: string;
}

export interface PageCustomField {
  customFieldId: number;
  pageId: number;
  webSiteConfigId: number;
  label?: string;
  name?: string;
  description: string;
  visible: boolean;
  required: boolean;
  hasPredefinedAnswers: boolean;
  archived: boolean;
  alreadyAnswered: boolean;
  modifyUser:string;
  customAnswers: CustomAnswer[];
  customFieldQA: CustomFieldQA[]; 
}

export interface DesignHistory {
  pageFields: PageField[],
  pageCustomFields: PageCustomField[];
  visible: boolean;
}

export interface DesignWebsite {
  webSiteConfigId: number;
  pageId:number;
  hrEntityId: number;
  webSiteAddress: string;
  webSiteTitle: string;
  webSiteURL: string;
  signatureDescription: string;
  webSiteImages: WebsiteImage[];
  webSiteWaivers: WebsiteWaiver[];
  webSiteCustomWaivers: WebsiteCustomWaiver[];
  personalInformation: DesignHistory;
  employmentHistory: DesignHistory;
  educationHistory: DesignHistory;
  trainingHistory: DesignHistory;
}

export interface SendApplicantInterviewEmail {
  applicantId: number;
  interviewId: number;
  customEmailId: number;
  sendToApplicant: boolean;
  sendToInterviewers: boolean;
  attachResume: boolean;
  attachQuestions: boolean;
}

export type OfferStatus = 'NotSent' | 'SentPending' | 'Denied' | 'AcceptedPending' | 'Accepted';

export interface Applicant {
  applicantId: number;
  hrEntityId: number;
  jobId: number;
  jobTitle: string;
  firstName: string;
  middleName: string;
  lastName: string;
  maidenName: string;
  dateApplied: string;
  infoSent: boolean;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zip: string;
  homePhone: string;
  cellPhone: string;
  otherPhone: string;
  overallRating: number;
  emailAddress: string;
  dateAvailable: string;
  pleaseExplain: string;
  status: number;
  referralCategory: number;
  referralSource: string;
  scorecardRating: string;
  eeo: string;
  gender: string;
  driverLicenseNumber: string;
  customPersonalFields: CustomPersonalField[];
  employmentHistory: EmploymentHistory[];
  educationHistory: EducationHistory[];
  trainingHistory: TrainingHistory[];
  applicationHistory: ApplicationHistory[];
  notes: Note[];
  offerStatus: OfferStatus;
}

export interface Note {
  noteId: number;
  applicantId: number;
  noteDescription: string;
  createDate: string;
  createUser: string;
}

export interface ApplicationHistory {
  applicantId: number;
  dateOfApplication: string;
  status: string;
  positionAppliedFor: string;
}

export interface TrainingHistory {
  trainingHistoryId: number;
  applicantId: number;
  nameOfInstitution: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zip: string;
  coursesTaken: string;
  degreesEarned: string;
  explanation: string;
  graduated: boolean;
  typeOfTraining: string;
  customTrainingFields: CustomPersonalField[];
}

export interface EducationHistory {
  eductionHistoryId: number;
  applicantId: number;
  schoolName: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zip: string;
  yearsCompleted: number;
  graduated: boolean;
  degreeLevel: number;
  explanation: string;
  graduationMonth: number;
  graduationYear: number;
  gpa: number;
  enrollmentMonth: number;
  enrollmentYear: number;
  major: string;
  customEducationFields: CustomPersonalField[];
}

export interface EmploymentHistory {
  employmentHistoryId: number;
  applicantId: number;
  nameOfEmployer: string;
  typeOfBusiness: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zip: string;
  telephoneNumber: string;
  nameOfSupervisor: string;
  positionTitle: string;
  startingWage: number;
  endingWage: number;
  responsibilities: string;
  reasonForLeaving: string;
  currentlyEmployed: boolean;
  startingWageType: string;
  endingWageType: string;
  firstDateOfEmployment: string;
  lastDateOfEmployment: string;
  mayContactEmployer: boolean;
  likedAboutPosition: string;
  dislikedAboutPosition: string;
  customEmploymentFields: CustomPersonalField[];
}

export interface CustomPersonalField {
  question: string;
  answer: string;
}

export interface StatusRequest {
  applicantId: number;
  status: string;
}

export interface DeleteInterviewRequest {
  applicantId: number;
  interviewId: number;
}

export interface SendApplicantInterviewEmails {
  applicantId: number;
  interviewId: number;
  params: any;
}

export enum ApplicantStatus {
  New_Applicant = 0,
  Interview = 1,
  Offer = 2,
  Onboard = 3,
  Employee = 4,
  Reject = -1,
}


export const applicantStatusOpts = [
  {
    id: 0,
    key: 0,
    value: 0,
    color: '#e6ac29',
    iconName: 'file-import',
    description: 'New Applicant',
  },
  {
    id: 1,
    key: 1,
    value: 1,
    color: '#3577ae',
    iconName: 'message-captions',
    description: 'Interview',
  },
  {
    id: 2,
    key: 2,
    value: 2,
    color: '#1c8e57',
    iconName: 'handshake',
    description: 'Offer',
  },
  {
    id: 3,
    key: 3,
    value: 3,
    color: '#22558c',
    iconName: 'id-card',
    description: 'Onboard',
  },
  {
    id: -1,
    key: 4,
    value: -1,
    color: '#d64624',
    iconName: 'cancel',
    description: 'Reject',
  },
];

export interface ClientJobs {
  clientNo: number;
  protectedClientNo: string;
  hrEntityId: number;
  availableJobs: AvailableJob[];
}

export interface AvailableJob {
  jobId: number;
  hrEntityId: number;
  company: string;
  jobTitle: string;
  description: string;
  city: string;
  state: string;
  isActive: boolean;
  location: number;
  department: number;
  subDepartment: number;
  subDepartment2: number;
  zipCode: string;
  archived: boolean;
  createDate: string;
  interviewProcessId: number;
  employmentStatus: number;
  tags: Tag[];
  taxEntities: JobTaxEntity[];
  applicants: Applicant[];
  reviewerPayrolls: ReviewerPayroll[];
}

export const newJob: AvailableJob = {
  jobId: 0,
  hrEntityId: 1,
  company: '',
  jobTitle: '',
  description: '',
  city: '',
  state: '',
  isActive: true,
  location: 0,
  department: 0,
  subDepartment: 0,
  subDepartment2: 0,
  zipCode: '',
  archived: false,
  createDate: new Date().toISOString(),
  interviewProcessId: 0,
  employmentStatus: 0,
  tags: [],
  taxEntities: [],
  applicants: [],
  reviewerPayrolls: [],
};

export interface ReviewerPayroll {
  jobId: number;
  secUserId: number;
}

export interface JobTaxEntity {
  jobId: number;
  entityId: number;
}

export interface Tag {
  jobId: number;
  tag: string;
}

export interface InterviewProcess {
  interviewProcessId: number;
  hrEntityId: number;
  title: string;
  description: string;
  isDefault: boolean;
  processTemplates: ProcessTemplate[];
}

export interface InterviewLocation {
  interviewLocationId: number;
  hrEntityId: number;
  description: string;
}


export interface InterviewQuestion {
  interviewQuestionId: number,
  hrEntityId: number,
  interviewTemplateId: number,
  title: string,
  description: string,
  isDefault: boolean
}

export interface InterviewType {
  hrEntityId: number,
  interviewTypeId: number,
  description: string,
}

export interface InterviewTemplate {
  interviewTemplateId: number,
  hrEntityId: number,
  title: string,
  description: string,
  interviewTypeId: number,
  interviewTypeDescription: string,
  questions: InterviewQuestion[],
  scorecardCategories: ScorecardCategory[],
  isDefault: boolean
}

export interface TemplateRequest {
  interviewTypeId: number,
  interviewTemplateId: number
}

export interface ProcessTemplate {
  hrEntityId: number;
  interviewProcessId: number;
  stepNo?: number;
  interviewTemplateId: number;
  templateTitle: string;
  templateDescription: string;
  interviewTypeId: number;
  interviewTypeDescription: string;
  isDefault: boolean;
}

export interface ScorecardCategory {
  scorecardCategoryId: number;
  hrEntityId: number;
  category: string;
  traits: ScorecardTrait[];
}


export interface ScorecardTrait {
  hrEntityId: number;
  scorecardCategoryId: number;
  scorecardTraitId: number;
  trait: string;
  rates: ScorecardRate[];
}

export interface ScorecardRate {
  scorecardTraitId: number;
  rating: number;
  description: string;
}

export interface CustomEmail {
  customEmailId: number;
  hrEntityId: number;
  description: string;
  emailBody: string;
  emailSubject: string;
}

export interface TestEmail {
  description: string;
  body: string;
  subject: string;
  testEmailAddress?: string;
}

export const localTaxOpts = [
  {
    id: 227,
    description: 'Detroit',
  },
  {
    id: 228,
    description: 'Pontiac',
  },
];

export const employmentStatusOpts = [
  {
    id: 0,
    description: 'None',
  },
  {
    id: 1,
    description: 'Full Time',
  },
  {
    id: 2,
    description: 'Part Time',
  },
  {
    id: 3,
    description: 'Seasonal',
  },
  {
    id: 4,
    description: 'Temporary',
  },
];

export type ScoreCard = {
  secUserId: number;
  interviewId: number;
  applicantId: number;
  interviewerScorecardId: number;
  category: string;
  trait: string;
  rating: number | null;
};

export type Interviewer = {
  applicantId: number;
  hrEntityId: number;
  secUserId: number;
  interviewId: number;
  name: string;
  rating: number;
  notes: string;
  scorecardRating: number;
  interviewerScorecards: ScoreCard[];
};

export type QuestionAnswers = {
  applicantId: number;
  hrEntityId: number;
  interviewQAId: number;
  interviewId: number;
  question: string;
  answer: string;
  modifyDate: any;
  modifyUser: string;
};

export type Interview = {
  interviewId: number;
  applicantId: number;
  hrEntityId: number;
  description: string;
  type: string;
  date: any;
  completed: boolean;
  dateCompleted: any;
  didNotHireReasonId?: number;
  time: string;
  title: string;
  interviewLocationId?: number;
  interviewLocationDescription: string;
  createDate: any;
  createUser: string;
  modifyDate: any;
  modifyUser: string;
  interviewers: Interviewer[];
  questionAnswers: QuestionAnswers[];
};