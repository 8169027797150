import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleError, handleSuccess, handleWarning } from './app.action';

export const downloadReportThunk = createAsyncThunk(
  'reportDownloader/fetch',
  async (api: any, { dispatch }) => {
    try {
      const response = await api();
      //Updated to use the message on the backend to keep it constant with all the others and more generic. -PI-8403
      dispatch(handleSuccess(response?.data?.messages[0]));
      return response?.data?.value || response?.data;
    } catch (err: any) {
      if(err?.statusCode === "NotFound") dispatch(handleWarning(err?.messages[0]));
      else dispatch(handleError(err));
    }
  },
);