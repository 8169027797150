import React, { CSSProperties, useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import sageLogo from 'assets/images/SageLogo.png';
import plexLogo from 'assets/images/PlexLogo.png';
import { DtoOptions } from 'core/models';
import ThirdPartyExportModal from './ThirdPartyExportModal.modal';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { CommonService } from 'core/services';
import Icon from 'core/components/shared/Icon';
import { QuickBooksButton } from './QuickBooksButton';
import ConfirmModal from 'features/payroll/sales-tax-transmittal/Confirm.modal';
import AccountMismatch from 'core/models/intuit/accountMismatch.model';
import useQuickBooksExportResult from 'hooks/intuit/useQuickBooksExportResult';
import useQuickBooksConnection from 'hooks/intuit/useQuickBooksConnection';
import { Spinner } from 'react-bootstrap';
import { GeneralLedgerExportReportRequest, PlexOptions, PowerChurchExportOptions, SageExportOptions, ThirdPartyEnum, } from 'core/models/AccountingReports.model';
import { 
  downloadGeneralLedgerExportReport, 
  loadIntuitAuth, 
  clearQuickBooksExportResultAndFileData,
  exportToQuickBooks, 
  generateChartOfAccounts,
} from 'core/store/actions';

type ThirdPartyProps = {
  groupByLocation: boolean;
  dtoOptions: DtoOptions[];
};

const selectedLinkStyle: CSSProperties = {
  borderColor: '#048521',
  color: '#048521',
  fontSize: '13px'
};
//Making the style slightly smaller but did not want to mess with Global Style that is being used. 
const defaultStyleOverride: CSSProperties = {
  fontSize: '13px'
};
const exportWrapperStyle: CSSProperties = {
  position: 'absolute',
  backgroundColor: '#fff',
  zIndex: 1020,
  right: 0,
  left: 0,
};

const ThirdPartyExport: React.FC<ThirdPartyProps> = ({ groupByLocation, dtoOptions }) => {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => { return state.client.client; });
  const intuitLoading = useAppSelector((state) => state.intuit.loading);
  const intuitExporting = useAppSelector((state) => state.intuit.exporting);
  const intuitAuth = useAppSelector((state) => state.intuit.intuitAuth);
  const chartOfAccountsData = useAppSelector((state) => state.intuit.chartOfAccountsData);
  const reports = useAppSelector((state) => { return state.accountingReports.generalLedgerExportReport; });
  const [showExport, setShowExport] = useState<boolean>(false);
  const [showSage, setShowSage] = useState<boolean>(false);
  const [showPlex, setShowPlex] = useState<boolean>(false);
  const [connected, setConnected] = useState<boolean>(false);
  const [tryToRedirect, setTryToRedirect] = useState<boolean>(false);
  const [selectedLink, setSelectedLink] = useState<string>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);  
  const showExportModal = [
    ThirdPartyEnum.CSV_POWERCHURCH,
    ThirdPartyEnum.GREAT_PLAINS,
    ThirdPartyEnum.CSV_SAGE500,
    ThirdPartyEnum.CSV_SAGE_ACCPAC,
    ThirdPartyEnum.CSV_PLEX_CLASSIC,
    ThirdPartyEnum.QUICKBOOKS,
    ThirdPartyEnum.QUICKEN2018ANDBEFORE,
    ThirdPartyEnum.CSV_PLEX_UX].includes(selectedLink as ThirdPartyEnum);

  const quickBooksOptions = [
    ThirdPartyEnum.QUICKBOOKS,
    ThirdPartyEnum.QUICKEN2018ANDBEFORE].includes(selectedLink as ThirdPartyEnum);

  const getDownloadInformation = () => {
    switch(selectedLink as ThirdPartyEnum){
      case ThirdPartyEnum.DATA:
      case ThirdPartyEnum.CSV_SAGE_X3:
        return {Base64ContentType: 'application/octet-stream', FileExtension: 'dat'};
      case ThirdPartyEnum.CSV_SAGE500:
        return {Base64ContentType: 'application/octet-stream', FileExtension: 'del'}
      case ThirdPartyEnum.QUICKBOOKS:
      case ThirdPartyEnum.QUICKEN2018ANDBEFORE:
        return {Base64ContentType: 'application/octet-stream', FileExtension: 'iif'}
      default:
        return {Base64ContentType: 'text/csv', FileExtension: 'csv'}
    }
  }

  useEffect(() => {
    if (!reports) return;
    reports.forEach(report => {
      const downloadInfo = getDownloadInformation();     
      CommonService.downloadBase64File(
        downloadInfo.Base64ContentType,
        report.reportData,
        `GeneralLedgerReport_${report.reportName}.${downloadInfo.FileExtension}`,
      );
    });
  }, [reports]);
  
  //Intuit/QuickBooks Logic: ---------------------------------------------------------------
  const quickBooksExportResult = useQuickBooksExportResult(setShowAlertModal)
  const quickBooksConnection = useQuickBooksConnection(setConnected, setTryToRedirect, tryToRedirect);

  useEffect(function getIntuitAuth() { //Load client intuit auth setup;
    dispatch(loadIntuitAuth());
  }, [])

  useEffect(function setConnectedFromIntuitAuth() {//set "quick books connection" toggle based on if access token is set on intuit auth setup; 
    if (!intuitAuth) return;

    setConnected(((intuitAuth?.accessToken?.length || 0) > 0))
  }, [intuitAuth])

  useEffect(function downloadChartOfAccountsFile() {
    if (!chartOfAccountsData) return;

    CommonService.downloadFileFromBase64('DMPayroll_ChartOfAccounts.xlsx', chartOfAccountsData);
    dispatch(clearQuickBooksExportResultAndFileData());
  }, [chartOfAccountsData])

  const onConfirmMisMatchedAccounts = (confirm: boolean) => {
    setShowAlertModal(false);
    dispatch(clearQuickBooksExportResultAndFileData());

    if (confirm) {
      let accountMismatches = quickBooksExportResult?.accountMismatches || new Array<AccountMismatch>();
      dispatch(generateChartOfAccounts(accountMismatches))
    }
  }

  const onExportQuickBooks = () => {
    dispatch(exportToQuickBooks());
  };
  //------------------------------------------------------------------------------------------------------------------------------

  const confirmClose = (confirmed: boolean) => {
    onExport(undefined, undefined, confirmed);
    setShowConfirmationModal(false);
  };

  const onExportWithOptions = (options: any) => {
    if (selectedLink === ThirdPartyEnum.CSV_POWERCHURCH) {
      const powerChurchOptions: PowerChurchExportOptions = {
        powerChurchJournalNumber: options.powerChurchJournalNumber,
        powerChurchMemoTransaction: options.powerChurchMemoTransaction,
      };
      onExport(powerChurchOptions);
      return;
    }

    if (selectedLink === ThirdPartyEnum.CSV_PLEX_CLASSIC || selectedLink === ThirdPartyEnum.CSV_PLEX_UX) {
      const plexOpts: PlexOptions = {
        periodDate: options.periodDate,
        postDate: options.postDate,
      };
      onExport(plexOpts);
      return;
    }

    if (selectedLink === ThirdPartyEnum.CSV_SAGE500 || selectedLink === ThirdPartyEnum.CSV_SAGE_ACCPAC) {
      const sageOptions: SageExportOptions = {
        fiscalYear: options.fiscalYear,
        fiscalPeriod: options.fiscalPeriod,
        description: options.description,
        endDate: options.endDate,
        companyID: options.companyID,
      };
      onExport(sageOptions);
      return;
    }

    if (selectedLink === ThirdPartyEnum.GREAT_PLAINS) {
      onExport(undefined, options.useCheckDate === 'true', undefined);
      return;
    }

    if(quickBooksOptions){
      onExport(undefined, undefined, undefined, options.quickBooksVersion as ThirdPartyEnum);
    }
  };

  const onExport = (
    options?: PowerChurchExportOptions | PlexOptions | SageExportOptions,
    useCheckDate?: boolean,
    combineDates?: boolean,
    selectedLinkOverride?: ThirdPartyEnum,) => {
    if (showExportModal && !options && !useCheckDate && !selectedLinkOverride) {
      setShowModal(true);
      return;
    }
    if (selectedLink === ThirdPartyEnum.DATA && !combineDates) {
      setShowConfirmationModal(true);
      return;
    }
    if (!selectedLink) return;
    const powerChurchOptions = selectedLink === ThirdPartyEnum.CSV_POWERCHURCH ? options as PowerChurchExportOptions : undefined;
    const sageOptions =
      [ThirdPartyEnum.CSV_SAGE500, ThirdPartyEnum.CSV_SAGE_ACCPAC].includes(selectedLink as ThirdPartyEnum) ? options as SageExportOptions : undefined;
    const plexOptions =
      [ThirdPartyEnum.CSV_PLEX_CLASSIC, ThirdPartyEnum.CSV_PLEX_UX].includes(selectedLink as ThirdPartyEnum) ? options as PlexOptions : undefined;

    const request: GeneralLedgerExportReportRequest = {
      clientNo: client?.clientNo ?? 0,
      groupByLocation,
      groupByEmployee: !groupByLocation,
      combineDates: combineDates ? true : false,
      useCheckDate: selectedLink === ThirdPartyEnum.GREAT_PLAINS ? useCheckDate as boolean : false,
      generalLedgerType: (selectedLinkOverride) ? selectedLinkOverride : selectedLink,
      powerChurchExportOptions: powerChurchOptions,
      sageExportOptions: sageOptions,
      plexOptions: plexOptions,
      dtoOptions,
    };

    dispatch(downloadGeneralLedgerExportReport(request));
    setShowExport(false);
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-link"
        style={{ fontWeight: 'bold' }}
        onClick={() => {
          setShowExport(!showExport);
        }}
      >
        Export Report <Icon
          name="chevron-down"
          className="mr-2"
        />
      </button>

      <div style={{ position: 'relative' }}>
        {showExport ?
          <div
            className="dm-panel-border p-3 mt-3"
            style={exportWrapperStyle}
          >
            <div className="d-flex flex-row">
              <div className="d-flex flex-column mx-2">
                <img
                  src={sageLogo}
                  alt="Sage Export"
                  width="50px"
                  height="auto"
                  style={{ marginRight: '20px' }}
                />
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    setShowSage(!showSage);
                  }}
                >
                  Sage Export <Icon
                    name="chevron-down"
                    className="mr-2"
                  />
                </button>
                {showSage ?
                  <ul className="list-group">
                    <li
                      onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_SAGE100C); }}
                      className={'list-group-item link'}
                      style={selectedLink === ThirdPartyEnum.CSV_SAGE100C ? selectedLinkStyle : defaultStyleOverride}
                    >Sage 100 C</li>
                    <li
                      onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_SAGE300); }}
                      className={'list-group-item link'}
                      style={selectedLink === ThirdPartyEnum.CSV_SAGE300 ? selectedLinkStyle : defaultStyleOverride}
                    >Sage 300</li>
                    <li
                      onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_SAGE_ACCPAC); }}
                      className={'list-group-item link'}
                      style={selectedLink === ThirdPartyEnum.CSV_SAGE_ACCPAC ? selectedLinkStyle : defaultStyleOverride}
                    >Sage ACCPAC</li>
                    <li
                      onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_SAGE500); }}
                      className={'list-group-item link'}
                      style={selectedLink === ThirdPartyEnum.CSV_SAGE500 ? selectedLinkStyle : defaultStyleOverride}
                    >Sage ERP 500</li>
                    <li
                      onClick={() => { return setSelectedLink(ThirdPartyEnum.SAGE_HRMS); }}
                      className={'list-group-item link'}
                      style={selectedLink === ThirdPartyEnum.SAGE_HRMS ? selectedLinkStyle : defaultStyleOverride}
                    >Sage HRMS</li>
                    <li
                      onClick={() => { return setSelectedLink(ThirdPartyEnum.SAGE_INTACCT); }}
                      className={'list-group-item link'}
                      style={selectedLink === ThirdPartyEnum.SAGE_INTACCT ? selectedLinkStyle : defaultStyleOverride}
                    >Sage Intacct</li>
                    <li
                      onClick={() => { return setSelectedLink(ThirdPartyEnum.SAGE_INTACCTII); }}
                      className={'list-group-item link'}
                      style={selectedLink === ThirdPartyEnum.SAGE_INTACCTII ? selectedLinkStyle : defaultStyleOverride}
                    >Sage Intacct II</li>
                    <li
                      onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_SAGE_X3); }}
                      className={'list-group-item link'}
                      style={selectedLink === ThirdPartyEnum.CSV_SAGE_X3 ? selectedLinkStyle : defaultStyleOverride}
                    >Sage Intacct X3</li>
                  </ul> : null}
              </div>
              <div className="d-flex flex-column mx-2">
                <img
                  src={plexLogo}
                  alt="Plex Export"
                  width="50px"
                  height="auto"
                  style={{ marginRight: '20px' }}
                />
                <button
                  type="button"
                  className="btn btn-link mt-2"
                  onClick={() => {
                    setShowPlex(!showPlex);
                  }}
                >
                  Plex Export <Icon
                    name="chevron-down"
                    className="mr-2"
                  />
                </button>
                {showPlex ?
                  <ul className="list-group">
                    <li
                      onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_PLEX); }}
                      className={'list-group-item link'}
                      style={selectedLink === ThirdPartyEnum.CSV_PLEX ? selectedLinkStyle : defaultStyleOverride}
                    >PLEX</li>
                    <li
                      onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_PLEX_CLASSIC); }}
                      className={'list-group-item link'}
                      style={selectedLink === ThirdPartyEnum.CSV_PLEX_CLASSIC ? selectedLinkStyle : defaultStyleOverride}
                    >PLEX Classic</li>
                    <li
                      onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_PLEX_UX); }}
                      className={'list-group-item link'}
                      style={selectedLink === ThirdPartyEnum.CSV_PLEX_UX ? selectedLinkStyle : defaultStyleOverride}
                    >PLEX UX</li>
                  </ul> : null}
              </div>
              <div className="gc12">
                <ul className="list-group">
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.BSA); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.BSA ? selectedLinkStyle : defaultStyleOverride}
                  >BSA</li>
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_CDK); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.CSV_CDK ? selectedLinkStyle : defaultStyleOverride}
                  >CDK Export</li>
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_CLOUDSUITE); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.CSV_CLOUDSUITE ? selectedLinkStyle : defaultStyleOverride}
                  >Cloudsuite</li>
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_CONTRACTOR); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.CSV_CONTRACTOR ? selectedLinkStyle : defaultStyleOverride}
                  >Contact Format</li>
                </ul>
              </div>
              <div className="gc12">
                <ul className="list-group">
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_GEDC); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.CSV_GEDC ? selectedLinkStyle : defaultStyleOverride}
                  >GED to CSV</li>
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_GLRA); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.CSV_GLRA ? selectedLinkStyle : defaultStyleOverride}
                  >GLRA Export</li>
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.GREAT_PLAINS); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.GREAT_PLAINS ? selectedLinkStyle : defaultStyleOverride}
                  >Great Plains</li>
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.GREAT_PLAINS_LABOR_COST); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.GREAT_PLAINS_LABOR_COST ? selectedLinkStyle : defaultStyleOverride}
                  >Great Plains Labor Cost</li>
                </ul>
              </div>
              <div className="gc12">
                <ul className="list-group">
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_GLOBALSHOP); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.CSV_GLOBALSHOP ? selectedLinkStyle : defaultStyleOverride}
                  >Global Shop</li>
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_IDEA); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.CSV_IDEA ? selectedLinkStyle : defaultStyleOverride}
                  >IDEA</li>
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_ODOO); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.CSV_ODOO ? selectedLinkStyle : defaultStyleOverride}
                  >Odoo</li>
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV_POWERCHURCH); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.CSV_POWERCHURCH ? selectedLinkStyle : defaultStyleOverride}
                  >Powerchurch</li>
                </ul>
              </div>
              <div className="gc12">
                <ul className="list-group">
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.SAP); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.SAP ? selectedLinkStyle : defaultStyleOverride}
                  >SAP CSV</li>
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.QUICKBOOKS); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.QUICKBOOKS ? selectedLinkStyle : defaultStyleOverride}
                  >Quickbooks</li>
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.QUICKBOOK_ONLINE); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.QUICKBOOK_ONLINE ? selectedLinkStyle : defaultStyleOverride}
                  >Quickbooks Online</li>
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.EPICORPRO21_CSV); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.EPICORPRO21_CSV ? selectedLinkStyle : defaultStyleOverride}
                  >Epicor Pro</li>
                </ul>
              </div>
              <div className="gc12">
                <ul className="list-group">
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.MEDSTAR); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.MEDSTAR ? selectedLinkStyle : defaultStyleOverride}
                  >Medstar</li>
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.TRANSACTION_PRO); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.TRANSACTION_PRO ? selectedLinkStyle : defaultStyleOverride}
                  >Transaction Pro Export</li>
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.SAMPro); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.SAMPro ? selectedLinkStyle : defaultStyleOverride}
                  >SAMPro</li>
                </ul>
              </div>
              <div className="gc12">
                <ul className="list-group">
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.CSV); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.CSV ? selectedLinkStyle : defaultStyleOverride}
                  >Export to CSV</li>
                  <li
                    onClick={() => { return setSelectedLink(ThirdPartyEnum.DATA); }}
                    className={'list-group-item link'}
                    style={selectedLink === ThirdPartyEnum.DATA ? selectedLinkStyle : defaultStyleOverride}
                  >Export Data</li>
                </ul>
              </div>
              <div className="gc12">
                <div className=" d-flex flex-row">
                  <QuickBooksButton loading={intuitLoading} connected={connected} setTryToRedirect={setTryToRedirect} />
                </div>
                <div className=" d-flex flex-row">
                  <button disabled={!connected || intuitExporting} type="button" className="orange-button mt-3" onClick={() => { return onExportQuickBooks(); }}>
                    {intuitExporting ? <div>{'Exporting to QuickBooks Online...   '}<Spinner animation='border' size='sm' /></div> : 'Export to QuickBooks Online'}
                  </button>
                </div>
              </div>
            </div>
            <div className="w-100 text-right">
              <button
                type="submit"
                className="btn btn-link dm-grid-action-title "
                onClick={() => { return onExport(); }}
                disabled={!selectedLink}
              >
                Export <Icon
                  name="file-export"
                  className="fa-download"
                />
              </button>
            </div>
          </div>
          : null}
      </div>
      {showModal && selectedLink ? (
        <ThirdPartyExportModal
          show={showModal}
          addOptions={(options) => { return onExportWithOptions(options); }}
          onHide={() => { return setShowModal(false); }}
          selectedLink={selectedLink}
        />
      ) : null}
      {showConfirmationModal ? (
        <ConfirmationModal
          title="DM Payroll Services"
          message={'Do you want to combine dates on the report?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      ) : null}
      {showAlertModal && (
        <ConfirmModal
          show={showAlertModal}
          title={'Attention'}
          body={(quickBooksExportResult) ?quickBooksExportResult?.message : ''}
          onConfirm={(confirm: boolean) => { return onConfirmMisMatchedAccounts(confirm); }}
          onHide={() => { dispatch(clearQuickBooksExportResultAndFileData()); setShowAlertModal(false); return; }}
        />
      )}
    </div>
  );
};

export default ThirdPartyExport;