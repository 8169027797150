import React, { useEffect, useState } from 'react';
import { InterviewType } from 'core/models';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams } from '@ag-grid-community/core';
import { postInterviewType, deleteInterviewType } from 'core/store/slices/applicantTracking.slice';
import Modal from 'core/components/modals/Modal';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { cloneDeep } from 'lodash';
type Props = {
  show:boolean;  
  onHide: ()=> void;
};

const InterviewTemplateTypesModal: React.FC<Props> = ({
  show,
  onHide,
}) => {

  const dispatch = useAppDispatch();

  const [client, interviewTypes] = useAppSelector(
    (state) => {
      return [
        state.client.client,
        state.applicantTracking.interviewTypes];
    },
  );

  const [rowData, setRowData] = useState<InterviewType[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);  
  const [gridDirty, setGridDirty] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  useEffect(() => {    
    interviewTypes && setRowData(cloneDeep(interviewTypes));
    setGridDirty(false);
  }, [interviewTypes]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const onAddType = () =>{
   
    const pType: InterviewType = {
      hrEntityId: client?.hrEntityId ?? 0,  
      description: '',
      interviewTypeId:  0,    
    };
    
    setRowData((prevState) => {
      return [ ...prevState,
        pType,
      ];
    });

    setGridDirty(true);
  };

  const onDeleteType = (params: ICellRendererParams) =>{   
    dispatch(deleteInterviewType(params.data.interviewTypeId));
    setGridDirty(true);
  };

  const getGridData = () => {
    const items: any[] = [];
    gridApi?.forEachNode((node) => {
      items.push(node.data);
    });
    return items;
  };

  const onSubmit = () => {
    getGridData().forEach(type => {
      const it:InterviewType = {
        ...type,        
        description: type.description,
      };
      dispatch(postInterviewType(it));
    });   
  };

  const hideModal = () => {
    if (gridDirty) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };

  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };
  
  const [columns] = useState<ColDef[]>([
    {
      field: 'description',
      headerName: '',
      editable:true,
      width: 430,
    },
    {
      field: 'delete',
      cellRenderer: (params: ICellRendererParams) => {
        if (params.data.isDefault) {
          return (<span></span>);
        } else {      
          return (
            <button
              type="button"
              className="btn btn-link p-0"
              onClick={() => { return onDeleteType(params); }
              }
            >
              <Icon
                name="minus-circle"
                className="fa-minus-circle"
              />
            </button>             
          );
        }
      },
      width: 70,
    },
  ]);


  const gridOptions: GridOptions = {
    stopEditingWhenCellsLoseFocus:true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },    
  };

  return (
    <Modal
      show={show}
      onHide={hideModal}
      dialogClassName="lg"
      isDirty={gridDirty}
      backdrop={'static'}
      title="Manage Interview Types"
    >     
    
      <div className="d-flex flex-row justify-content-end">         
        <button
          type="button"
          className="btn btn-link dm-grid-action-title pb-0 mr-2"
          onClick={onAddType}
        >
          Add Type{' '}
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button>
      </div>
      <div className="d-flex mb-3">             
        <div className="table-wrapper-wrapper ag-theme-balham">
          <AgGridReact
            gridOptions={gridOptions}
            rowData={rowData}
            columnDefs={columns}
            onGridReady={onGridReady}
          />
        </div>
      </div>
      <div className="d-flex mt-3">
        <button
          className="orange-outline-button ml-auto mr-2"
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={onSubmit}
          className="orange-button mr-2"
        >
          Save
        </button>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
    </Modal>
  );
};
export default InterviewTemplateTypesModal;
