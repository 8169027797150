import React from 'react';

import { InputGrpInLine } from '../../core/components/form-controls';
import { FieldInputSettings } from '../../core/components/form-controls/types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getClient } from '../../core/store/selectors';
import { Client, LookupEEOCertifyOfficial } from '../../core/models';
import {
  createEEOCertifyOfficial,
  updateEEOCertifyOfficial,
} from '../../core/store/actions';

const fs: FieldInputSettings = {
  name: { name: 'name', label: 'NAME', labelWidth: 25, required: true },
  title: { name: 'title', label: 'TITLE', labelWidth: 25, required: true },
  telphone: { name: 'telphone', label: 'PHONE', labelWidth: 25 },
  emailAddress: { name: 'emailAddress', label: 'EMAIL', labelWidth: 25 },
};

type PropTypes = {
  item: LookupEEOCertifyOfficial;
  options: any[];
  onHide: any;
};

const EEOCertifyingOfficialOptionForm: React.FC<PropTypes> = ({
  item,
  options,
  onHide,
}) => {
  const dispatch = useDispatch();
  const client = useSelector(getClient) as Client;

  const { register, errors, handleSubmit } =
        useForm<LookupEEOCertifyOfficial>({
          defaultValues: item
            ? { ...item }
            : new LookupEEOCertifyOfficial(0, 0),
        });

  const onSubmit = (data: any) => {
    const updatedCO = {
      ...item,
      ...data,
      clientEntityID: client.clientEntityID,
    };
    item
      ? dispatch(updateEEOCertifyOfficial(updatedCO))
      : dispatch(createEEOCertifyOfficial(updatedCO));
    onHide(false);
  };

  return (
    <div
      className="dm-panel dm-panel-border mx-4"
      style={{ display: 'flex', flexFlow: 'column nowrap' }}
    >
      <div className="dm-grid-action-title">Add/Edit</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGrpInLine
          {...fs.name}
          errors={errors.name}
          ref={register({ required: 'Name is Required' })}
        />
        <InputGrpInLine
          {...fs.title}
          errors={errors.title}
          ref={register({ required: 'Title is Required' })}
        />
        <InputGrpInLine
          {...fs.telphone}
          errors={errors.telphone}
          ref={register}
        />
        <InputGrpInLine
          {...fs.emailAddress}
          errors={errors.emailAddress}
          ref={register}
        />

        <div style={{ display: 'flex' }}>
          <button
            type="submit"
            className="orange-button ml-auto mr-2"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default EEOCertifyingOfficialOptionForm;
