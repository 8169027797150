import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Client, Dropdown, DtoOptions } from '../../core/models';
import { useAppSelector } from '../../utilities/hooks';
import InputReportDateSelector from '../../core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import { CheckboxGrpInLine, RadioGrp } from '../../core/components/form-controls';
import { DeductionOptionsReportRequest } from '../../core/models/PayrollReports.model';
import { useForm } from 'react-hook-form';
import { CommonService } from '../../core/services';
import { clearDeductionOptionsReport, downloadDeductionOptionsReport } from '../../core/store/actions';
import { ColDef, GridApi, GridOptions, RowSelectedEvent } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from '@ag-grid-community/react';
import { RadioOptions } from '../../core/components/form-controls/RadioGrp';
import Icon from 'core/components/shared/Icon';
import { getAllDeductionCodes } from 'core/store/selectors';


const groupByOptions: RadioOptions[] = [
  {
    value: 'ByLocDeptSub',
    label: 'By Loc-Dept-Sub',
  },
  {
    value: 'ByLocationOnly',
    label: 'By Location Only',
  },
  {
    value: 'ByDepartmentOnly',
    label: 'By Department Only',
  },
  {
    value: 'BySubDeptOnly ',
    label: 'By Sub-Department Only ',
  },
];

const DeductionOptionsReport = () => {

  const dispatch = useDispatch();

  const childRef: React.RefObject<any> = useRef();
  const report = useAppSelector((state) => { return state.payrollReports.deductionOptions; });
  const client = useAppSelector((state) => { return state.client.client; }) as Client;
  const deductionCodes = useAppSelector(getAllDeductionCodes); //PI-8680 We want all deduction codes in this dropdown including client deductions and want it to be type Dropdown
  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [fileType, setFileType] = useState<string>('pdf');
  const [disableAutoDateSelect, setDisableAutoDateSelect] = useState<boolean>(false);
  const [selectedCodes, setSelectedCodes] = useState<Dropdown[]>();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const reportName = 'DeductionOptionsReport';

  const { register, handleSubmit, watch } =
    useForm<DeductionOptionsReportRequest>({
      defaultValues:
      {
        options: 'ByLocDeptSub',
      },
    });

  const showEmployeeDetail = watch('showEmployeeDetail');
  const options = watch('options');

  const columns: ColDef[] = [
    {
      field: 'select',
      headerName: '',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 40,
    },
    {
      field: 'id',
      headerName: 'Code',
      width: 120,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 180,
    },
  ];

  const gridOptions: GridOptions = {
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    columnDefs: columns,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const downloadReport = (data: DeductionOptionsReportRequest) => {
    const submitData: DeductionOptionsReportRequest = {
      clientNo: client.clientNo,
      byWeekEndCheckDate: data.byWeekEndCheckDate,
      dtoOptions: reportDates,
      reportType: fileType,
      deductionNumbers: selectedCodes?.map(c => { return +c.id; }) || [],
      options: data.options,
      showEmployeeDetail: data.showEmployeeDetail,
      showSubTotals: data.showSubTotals,
      showSSN: data.showSSN,
      sortByLastName: data.sortByLastName,
    };
    dispatch(downloadDeductionOptionsReport(submitData));
  };

  useEffect(() => {
    if (report && fileType === 'excel') {
      report && CommonService.downloadBase64File(
        'application/excel',
        report,
        `${reportName}.xlsx`,
      );
      dispatch(clearDeductionOptionsReport());
    }
  }, [report]);

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      selectedRows && setSelectedCodes(selectedRows);
    }
  };

  const onRowClicked = (e: RowSelectedEvent) => {
    e && e.node.setSelected(e.node.isSelected() === true ? true : false);
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearDeductionOptionsReport());
  };

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">Deductions Report</div>

          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title "
              onClick={() => { return setFileType('excel'); }}
              disabled={!reportDates.length}>
              Download Report  <Icon name="download"
                className="fa-download" />
            </button>
          </div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div>
              <InputReportDateSelector
                ref={childRef}
                returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }}
                disableAutoDateSelect={disableAutoDateSelect}
              />
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <div className=" text-right">
                <button
                  type="button"
                  className="orange-outline-button mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="orange-button mr-2"
                  onClick={() => { return setFileType('pdf'); }}
                  disabled={!reportDates.length}>
                  Run Report
                </button>
              </div>

              <div className="dm-panel dm-panel-border"
                style={{ marginTop: '66px' }}>

                <div className="d-flex flex-row flex-grow-1">
                  <div className="d-flex flex-column mr-5">
                    <div className="dm-grid-action-title mb-2">Report Type</div>
                    <CheckboxGrpInLine
                      name="showEmployeeDetail"
                      ref={register}
                      labelFirst={false}
                      label="Show Employee Detail"
                    />
                    {showEmployeeDetail ? <div className="pl-3">
                      <CheckboxGrpInLine
                        name="showSSN"
                        ref={register}
                        labelFirst={false}
                        label="Show SSN"
                        disabled={!showEmployeeDetail}
                      />
                      <CheckboxGrpInLine
                        name="sortByLastName"
                        ref={register}
                        labelFirst={false}
                        label="Sort by Last Name"
                        disabled={!showEmployeeDetail}
                      />
                    </div> : null}
                    <CheckboxGrpInLine
                      name="byWeekEndCheckDate"
                      ref={register}
                      labelFirst={false}
                      onChange={(e: any) => {
                        return setDisableAutoDateSelect(e.target.checked);
                      }}
                      label="By Week End and Check Date"
                    />

                    <div className="dm-grid-action-title mb-2">Group By</div>
                    <RadioGrp
                      isVertical={true}
                      radioOptions={groupByOptions}
                      name="options"
                      ref={register()}
                      defaultChecked={'ByLocDeptSub'}
                    />
                    {/* Commented out the show Sub Totals checkbox as it looks like it should always show keeping it here incase someone changes there mind.  
                    {options !== 'ByLocDeptSub' ? <CheckboxGrpInLine
                      name="showSubTotals"
                      ref={register}
                      labelFirst={false}
                      label="Show Sub Totals"
                    /> : null} */}
                  </div>

                  <div className="ag-theme-balham mt-2"
                    style={{
                      height: '220px',
                      width: '400px',
                    }}>
                    <AgGridReact
                      gridOptions={gridOptions}
                      rowData={deductionCodes}
                      onGridReady={onGridReady}
                      onRowClicked={onRowClicked}
                      onSelectionChanged={onSelectionChanged}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {report ? <PDFViewerModal
        show={report.length > 0}
        pdfData={report}
        onHide={hidePdfViewer}
        reportName="DeductionOptionsReport"
      /> : null}
    </div>
  );



};
export default DeductionOptionsReport;