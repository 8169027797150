import { CheckboxGrp } from 'core/components/form-controls';
import { WebsiteWaiver } from 'core/models';
import React, { useState } from 'react';
import { useAppDispatch } from 'utilities/hooks';

type Props = {
  waiver: WebsiteWaiver;
  onOpenWaiver: (waiver: WebsiteWaiver) => void;
  onUpdateWaiverCheckbox: (waiver: WebsiteWaiver) => void;
};

const Waiver = ({ waiver, onOpenWaiver, onUpdateWaiverCheckbox }: Props) => {
  const [waiverState, setWaiverState] = useState(structuredClone(waiver));
  
  const onCheckboxChange = (newValue: boolean) => {
    const updatedWaiver: WebsiteWaiver = {
      ...waiverState,
      visableRequired: newValue,
    };
    
    onUpdateWaiverCheckbox(updatedWaiver);
  };
  
  return (
    <div                                
      className="d-flex flex-row flex-wrap pr-4"
      style={{ width: '50%' }}
    >
      <div className="d-flex justify-content-start">
        <button
          type="button"
          className="btn btn-link ml-auto mr-2"
          onClick={()=> { onOpenWaiver(waiver); }}
        >
          {waiver.waiverTitle}
        </button>
      </div>
      <div className="d-flex justify-content-end flex-grow-1">
        <div
          className="d-flex flex-column text-center"
          style={{ width: 150 }}
        >
          <CheckboxGrp
            label=""                                     
            defaultChecked={waiver.visableRequired}
            onChange={(e: any) => { onCheckboxChange(e.target.checked); }}
          />
        </div>
      </div>                          
    </div>
  );
};

export default Waiver;