import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ControlDatePickerGrp, InputGrp, SelectGrp } from 'core/components/form-controls';
import { FieldInputSettings, FormMethods } from 'core/components/form-controls/types';
import { SSN_VALIDATION } from 'core/constants';
import { EmailTemplate, JazzHRIntegrationRequest } from 'core/models';
import { getGenders, getLocalTaxEntityQuickOnboard, getStates } from 'core/store/selectors';
import { formatSSN } from 'utilities/utilities';

// clientNo, empNo
const fs: FieldInputSettings = {
  firstName: { name: 'firstName', label: 'FIRST NAME', required: true },
  middleInitial: { name: 'middleInitial', label: 'MIDDLE' },
  lastName: { name: 'lastName', label: 'LAST NAME', required: true },
  suffix: { name: 'suffix', label: 'SUFFIX' },
  ssn: { name: 'ssn', label: 'SOCIAL SECURITY' },
  gender: { name: 'gender', label: 'GENDER' },
  address: { name: 'address', label: 'STREET ADDRESS' },
  city: { name: 'city', label: 'CITY' },
  state: { name: 'state', label: 'STATE', required: true },
  zip: { name: 'zip', label: 'ZIP' },
  zip4: { name: 'zip4', label: ' ' },
  birthDate: { name: 'birthDate', label: 'Birth Date' },
  emailAddress: { name: 'emailAddress', label: 'EMAIL', required: true },
  localTaxEntityID: { name: 'localTaxEntityID', label: 'LOCAL TAX' },
  emailTemplateID: {
    name: 'emailTemplateID',
    label: 'CREDENTIAL EMAIL TEMPLATE',
    valueField: 'credentialEmailID',
    labelField: 'templateName',
  },
};

type PropTypes = {
  control: any;
  setValue: FormMethods['setValue'];
  register: FormMethods['register'];
  errors: FormMethods['errors'];
  watch: FormMethods['watch'];
  emailTemplates: EmailTemplate[];
  selectedJazzApplicant: JazzHRIntegrationRequest | null;
};

const OnboardEmpInfo: React.FC<PropTypes> = ({
  setValue,
  control,
  register,
  errors,
  watch,
  emailTemplates,
  selectedJazzApplicant,
}) => {
  const stateOpts = useSelector(getStates);
  const genderOpts = useSelector(getGenders);
  const locaTaxOpts = useSelector(getLocalTaxEntityQuickOnboard);
  const birthDate = watch('birthDate');

  useEffect(() => {
    if (!selectedJazzApplicant) return;
    setValue('firstName', selectedJazzApplicant.firstName);
    setValue('lastName', selectedJazzApplicant.lastName);
    setValue('emailAddress', selectedJazzApplicant.email);
    setValue('address', selectedJazzApplicant.address);
  }, [selectedJazzApplicant]);

  return (
    <div className="row">
      <Col
        xs={12}
        className=""
      >
        <div className="dm-panel dm-panel-border">
          <div className="dm-card-title">Employee Information</div>
          <div className="dm-card-subtitle">
            Enter a new employee's information
          </div>
          <div className="row mt-3">
            <Col
              xs={3}
              className="pr-0"
            >
              <InputGrp
                {...fs.firstName}
                errors={errors.firstName}
                ref={register({
                  required: 'First Name is required.',
                })}
              />
            </Col>
            <Col
              xs={2}
              className="pr-0"
            >
              <InputGrp
                {...fs.middleInitial}
                errors={errors.middleInitial}
                ref={register}
              />
            </Col>
            <Col
              xs={4}
              className="pr-0"
            >
              <InputGrp
                {...fs.lastName}
                errors={errors.lastName}
                ref={register({
                  required: 'Last Name is required.',
                })}
              />
            </Col>
            <Col xs={3}>
              <InputGrp
                {...fs.suffix}
                errors={errors.suffix}
                ref={register}
              />
            </Col>
          </div>
          <div className="row">
            <Col
              xs={4}
              className="pr-0"
            >
              <InputGrp
                {...fs.ssn}
                errors={errors.ssn}
                onChange={(e: any) => {
                  const { value } = e?.target;
                  e.target.value = formatSSN(value);
                }}
                ref={register({ pattern: SSN_VALIDATION })}
              />
            </Col>
            <Col
              xs={4}
              className="pr-0"
            >
              <SelectGrp
                {...fs.gender}
                errors={errors.gender}
                ref={register}
                options={genderOpts}
              />
            </Col>
          </div>
          <div className="row">
            <Col xs={12}>
              <InputGrp
                {...fs.address}
                errors={errors.address}
                ref={register}
              />
            </Col>
          </div>
          <div className="row">
            <Col
              xs={5}
              className="pr-0"
            >
              <InputGrp
                {...fs.city}
                errors={errors.city}
                ref={register}
              />
            </Col>
            <Col
              xs={3}
              className="pr-0"
            >
              <SelectGrp
                {...fs.state}
                errors={errors.state}
                ref={register({
                  required: 'State is required',
                })}
                options={stateOpts}
              />
            </Col>
            <Col xs={4}>
              <div className="d-flex flex-row">
                <InputGrp
                  {...fs.zip}
                  errors={errors.zip}
                  ref={register}
                />
                <span
                  style={{
                    paddingTop: '22px',
                    paddingLeft: '3px',
                    paddingRight: '3px',
                  }}
                >
                  -
                </span>
                <InputGrp
                  {...fs.zip4}
                  errors={errors.zip4}
                  ref={register}
                />
              </div>
            </Col>
          </div>
          <div className="row">
            <Col
              xs={4}
              className="pr-0"
            >
              <ControlDatePickerGrp
                {...fs.birthDate}
                value={birthDate}
                setValue={setValue}
                control={control}
              />
            </Col>
            <Col xs={8}>
              <InputGrp
                {...fs.emailAddress}
                errors={errors.emailAddress}
                ref={register({
                  required: 'Email is required.',
                })}
              />
            </Col>
          </div>
          <div className="row mt-2">
            <Col xs={12}>
              <div>
                <strong>Additional Tax Entity</strong>
              </div>
            </Col>
            <Col xs={12}>
              <SelectGrp
                {...fs.localTaxEntityID}
                errors={errors.localTaxEntityID}
                ref={register}
                options={locaTaxOpts}
              />
            </Col>
            <Col xs={12}>
              <div>
                <strong>Other</strong>
              </div>
            </Col>
            <Col xs={12}>
              <SelectGrp
                {...fs.emailTemplateID}
                errors={errors.emailTemplateID}
                ref={register}
                options={emailTemplates}
              />
            </Col>
          </div>
          <Row className="mt-3">
            <Col xs={12}>
              <div>
                <strong>NOTE:</strong> Fields marked with{' '}
                <span
                  style={{ fontWeight: 'bold', color: 'red' }}
                >
                  *
                </span>{' '}
                are required
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </div>
  );
};

export default OnboardEmpInfo;
