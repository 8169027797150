import cloneDeep from 'lodash/cloneDeep';
import { createSelector } from 'reselect';
import { EmailTemplate } from '../../models';
import { onboardState } from '../reducers';
import { State as OnboardState } from '../reducers/onboard.reducer';

export const getOnboardStatus = createSelector(
  onboardState,
  (state: OnboardState) => {return state.status;},
);

export const getJazzHrApplicants = createSelector(
  onboardState,
  (state: OnboardState) => {return state.jazzHRApplicants;},
);

export const getSelectedJazzHrApplicant = createSelector(
  onboardState,
  (state: OnboardState) => {return state.selectedJazzHRApplicant;},
);

export const getNextEmpNo = createSelector(
  onboardState,
  (state: OnboardState) => {return state.nextEmpNo;},
);

export const getPayrollUsers = createSelector(
  onboardState,
  (state: OnboardState) => {return state.payrollUsers;},
);

export const getOnboardDocs = createSelector(
  onboardState,
  (state: OnboardState) => {return state.onboardDocs;},
);

export const getOnboardPageConfigs = createSelector(
  onboardState,
  (state: OnboardState) => {return cloneDeep(state.pageConfigs);},
);

export const getOnboardEmailTemplates = createSelector(
  onboardState,
  (state: OnboardState) => {return state.emailTemplates.map((t: EmailTemplate) => {return { ...t };});},
);