import React, { createRef, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RadioGrpInLine } from '../../../core/components/form-controls';
import { FieldInputSettings } from '../../../core/components/form-controls/types';
import { ClientACA } from '../../../core/models';
import { convToFormObject } from '../../../utilities/classUtils';
import QuestionsIITab from './QuestionsIITab';
import { DateTime } from 'luxon';
import {
  loadClientACALastYear,
  updateClientACA,
} from '../../../core/store/actions';
import { getClientACALastYear } from '../../../core/store/selectors';
import { TextareaGrp } from '../../../core/components/form-controls/TextareaGrp';
import ConfirmationModal from '../../../core/components/modals/confirmation.modal';
import { isEqual } from 'lodash';
import Modal from 'core/components/modals/Modal';
import { useAppDispatch } from 'utilities/hooks';
import { setDataKey } from 'core/store/slices/human-capital-management/aca-full-time-calculation.slice';

const radioOptions = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
  {
    value: 'unknown',
    label: 'Unknown',
  },
];

const fs: FieldInputSettings = {
  offerEmployeesInsurance: {
    name: 'offerEmployeesInsurance',
    label: '1)	Do you offer health insurance to your employees?',
    groupClass: 'gc95',
    radioOptions,
    labelWidth: 60,
  },
  offerSpouseInsurance: {
    name: 'offerSpouseInsurance',
    label: '2) Do you offer health insurance to your employees’ spouse and dependents?',
    groupClass: 'gc95 ',
    radioOptions,
    labelWidth: 60,
  },
  selfInsured: {
    name: 'selfInsured',
    label: '3)	Is the health plan that you offer “self-insured”?',
    groupClass: 'gc95 ',
    radioOptions,
    labelWidth: 60,
  },
  offerMinCoverage: {
    name: 'offerMinCoverage',
    label: '4) Does the health insurance you offer to your employees provide minimum essential coverage and provide minimum value as defined by the aca? (You may need to contact your insurance carrier to confirm this)',
    groupClass: 'gc95 ',
    radioOptions,
    labelWidth: 60,
  },
  needInsuranceReferral: {
    name: 'needInsuranceReferral',
    label: '5) Would you like a competitive quote from our health insurance provider?',
    groupClass: 'gc95 ',
    radioOptions,
    labelWidth: 60,
  },
  acaComment: {
    name: 'acaComment',
    label: '6) Please provide any additional comments or questions:',
    groupClass: 'gc95 ',
    radioOptions,
    labelWidth: 60,
  },
};

type PropTypes = {
  clientACA: ClientACA | null;
  show: boolean;
  onHide: any;
};

const ACAQuestionsModal: React.FC<PropTypes> = ({
  clientACA,
  show,
  onHide,
}) => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const clientACALastYear = useSelector(getClientACALastYear);
  const questionTabRef: React.RefObject<any> = createRef();
  const [, setKey] = useState('aca-general-questions');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { handleSubmit, reset, setValue, getValues, ...formMethods } =
    useForm<any>();

  useEffect(() => {
    if (clientACA) {
      const currentYearAca = {
        ...convToFormObject(clientACA, ClientACA.convProps),
      };
      reset(currentYearAca);
    }
  }, [clientACA]);

  useEffect(() => {
    if (clientACALastYear && Object.keys(clientACALastYear).length) {
      const lastYearAca = {
        ...convToFormObject(clientACALastYear, ClientACA.convProps),
      } as ClientACA;
      setValue(
        'offerEmployeesInsurance',
        lastYearAca.offerEmployeesInsurance,
      );
      setValue('offerSpouseInsurance', lastYearAca.offerSpouseInsurance);
      setValue('selfInsured', lastYearAca.selfInsured);
      setValue('offerMinCoverage', lastYearAca.offerMinCoverage);
      setValue(
        'needInsuranceReferral',
        lastYearAca.needInsuranceReferral,
      );
    }
  }, [clientACALastYear]);

  const onSave = async (data: any) => {
    const aca = {
      ...clientACA,
      ...data,
    };
    const updateACA = new ClientACA(aca);

    const response = await dispatch(updateClientACA({ ...updateACA }));
    appDispatch(setDataKey({
      key: 'states',
      value: response.payload,
    }));
  };

  const onLastYearClick = () => {
    const lastYear = DateTime.local().minus({ years: 1 }).year;
    dispatch(loadClientACALastYear({ year: lastYear }));
  };

  const hasChanges = () => {
    const updateACA = {
      ...clientACA,
      ...new ClientACA(getValues()),
      monthlyPremiums: [],
    };
    const formData = {
      ...clientACA,
      ...updateACA,
    };
    const originalData = {
      ...clientACA,
      monthlyPremiums: [],
    };
    const hasChanges = !isEqual(formData, originalData);
    return hasChanges;
  };

  const closeModal = () => {
    if (hasChanges() || questionTabRef?.current?.checkGridHasChanges()) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };

  const childCloseModal = () => {
    closeModal();
  };

  const confirmClose = (confirmed: boolean) => {
    confirmed && onHide();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="xl"
        title="ACA Code Info"
      >

        <div className="dm-panel dm-panel-border">
          <p>
            DM Payroll Solutions will be providing all its
            clients assistance with the preparation and filing
            of all necessary forms and information required by
            the IRS with respect to the Affordable Care Act
            (ACA). Please visit our{' '}
            <a
              href={
                'http://www.dmpayroll.com/?s=Affordable+Care+Act'
              }
              target={'_blank'}
              rel="noreferrer"
            >
              http://www.dmpayroll.com/?s=Affordable+Care+Act{' '}
            </a>
            for more information.
          </p>

          <p>
            Starting in January 2016, annually, your
            organization may be required to provide employees
            with tax statements (Form 1095-C or Form 1095-B)
            about the health coverage offered by your company to
            all of its employees for the calendar year 2015.
          </p>

          <p>
            These tax forms will include information about
            offers of health coverage and enrollment in health
            coverage for all your company employees. It may also
            be necessary to include spouse and dependent
            coverage information on this form as well.
          </p>

          <p>
            DM Payroll Solutions will offer to file forms 1095-C
            (or 1095-B if applicable) on behalf of its clients.
            To start this process, please answer the following
            initial questions and a representative will get back
            with you to discuss what your company needs to do:
          </p>
        </div>
        <form onSubmit={handleSubmit(onSave)}>
          <div>
            <strong className="mr-2">
              Skip these questions for today. I will answer
              them the next time I log in.
            </strong>
            <button
              type="button"
              className="btn btn-primary orange-button ml-auto mr-2"
              onClick={closeModal}
            >
              Skip Questions
            </button>
            <button
              type="button"
              className="btn btn-primary orange-outline-button ml-auto mr-2"
              onClick={onLastYearClick}
            >
              Copy Last Year Info
            </button>
            <button
              type="submit"
              className="btn btn-primary orange-button ml-auto mr-2"
            >
              Submit
            </button>
          </div>
          <Tabs
            defaultActiveKey="aca-general-questions"
            id="aca-codes"
            onSelect={(k: any) => { return setKey(k); }}
          >
            <Tab
              eventKey="aca-general-questions"
              title="General Questions"
            >
              {clientACA && (
                <div className="m-2">
                  <input
                    type="hidden"
                    name={'clientNo'}
                    defaultValue={clientACA.clientNo}
                    ref={formMethods.register()}
                  />
                  <input
                    type="hidden"
                    name={'year'}
                    defaultValue={clientACA.year}
                    ref={formMethods.register()}
                  />
                  <input
                    type="hidden"
                    name={'protectedClientNo'}
                    defaultValue={
                      clientACA.protectedClientNo
                    }
                    ref={formMethods.register()}
                  />
                  <input
                    type="hidden"
                    name={'showAcaQuestionnaire'}
                    defaultValue={clientACA.showAcaQuestionnaire?.toString()}
                    ref={formMethods.register()}
                  />
                  <RadioGrpInLine
                    {...fs.offerEmployeesInsurance}
                    errors={
                      formMethods.errors
                        .offerEmployeesInsurance
                    }
                    ref={formMethods.register}
                  />
                  <RadioGrpInLine
                    {...fs.offerSpouseInsurance}
                    errors={
                      formMethods.errors
                        .offerSpouseInsurance
                    }
                    ref={formMethods.register}
                  />
                  <RadioGrpInLine
                    {...fs.selfInsured}
                    errors={formMethods.errors.selfInsured}
                    ref={formMethods.register}
                  />
                  <RadioGrpInLine
                    {...fs.offerMinCoverage}
                    errors={
                      formMethods.errors.offerMinCoverage
                    }
                    ref={formMethods.register}
                  />
                  <RadioGrpInLine
                    {...fs.needInsuranceReferral}
                    errors={
                      formMethods.errors
                        .needInsuranceReferral
                    }
                    ref={formMethods.register}
                  />
                  <TextareaGrp
                    {...fs.acaComment}
                    errors={formMethods.errors.acaComment}
                    ref={formMethods.register}
                  />
                </div>
              )}
            </Tab>
            <Tab
              eventKey="aca-part-2-questions"
              title="ACA Part II Questions"
            >
              <QuestionsIITab
                clientAca={clientACA}
                formMethods={formMethods}
                getValues={getValues}
                childCloseModal={childCloseModal}
                ref={questionTabRef}
              />
            </Tab>
          </Tabs>
        </form>
      </Modal>
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close the modal?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
    </>
  );
};

export default ACAQuestionsModal;
