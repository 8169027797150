import React from 'react';
import { get } from 'lodash';
import Select from 'react-select';

import Icon from 'core/components/shared/Icon';
import { StylesConfigType, compStyles } from 'utilities/styles';

interface InterViewer {
  id: string
  name: string
}

interface InterviewerOption {
  value: number
  label: string
}

interface InterviewTeamInterface {
  addInterviewer: () => void
  removeInterviewer: (id: string) => void
  selectedInterviewer: InterViewer[] | []
  interviewerOptions: InterviewerOption[]
  setSelectedInterviewer: React.Dispatch<React.SetStateAction<{
    id: string;
    name: string;
  }[]>>
}

const InterviewTeam = (props: InterviewTeamInterface) => {
  const { addInterviewer, removeInterviewer, selectedInterviewer, interviewerOptions, setSelectedInterviewer } = props;
  return (
    <>
      <h4 className="font-weight-bold">Select Interview Team</h4>
      <div className="d-flex justify-content-end mb-2">
        <button
          type="button"
          style={{
            border: 'none',
            background: 'transparent',
          }}
          onClick={addInterviewer}
        >
          Add Interviewer <Icon name="plus-circle" />
        </button>
      </div>
      <div 
        className="d-flex flex-column"
        style={{ gap: '0.5rem' }}
      >
        {selectedInterviewer.map((item, index) => {
          return (
            <div
              key={item.id}
              className="d-flex align-items-center justify-content-between"
              style={{ gap: '0.5rem' }}
            >
              <Select
                styles={compStyles as StylesConfigType}
                options={interviewerOptions}
                onChange={(option) => {
                  const temp = [...selectedInterviewer];
                  temp[index].name = get(option, 'label', '');
                  setSelectedInterviewer([...temp]);
                }}
              />
              <button
                type="button"
                style={{
                  border: 'none',
                  background: 'transparent',
                }}
                onClick={() => {
                  removeInterviewer(item.id);
                }}
              >
                <Icon name="minus-circle" />
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default InterviewTeam;