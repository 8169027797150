import React, { useContext } from 'react';
import '../time-card-styles.scss';
import { inputStyle } from '../InputSection';
import { InputGrp } from 'core/components/form-controls';
import ModalInputButton from '../ModalInputButton';
import { TimeSheetSummaryContext } from './TimeSheetSummary';

type Props = {
  name: string,
  value: string | number;
  modalName: string;
  groupStyle: React.CSSProperties;
  onClick: () => void;
};

const ModalInput = ({ name, value, modalName, onClick, groupStyle }: Props) => {
  const { updateTimeCardState } = useContext(TimeSheetSummaryContext);

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setValue(name, e.target.value);
  };
  
  return (
    <div className="modal-input-container-by-job">
      <InputGrp
        name={name}
        label={modalName}
        value={value}
        groupClass="modal-input"
        inputStyle={inputStyle}
        groupStyle={groupStyle}
        onChange={onValueChange}
      />      
      <ModalInputButton
        modalName={modalName}
        onClick={onClick}
      />
    </div>
  );
};

export default ModalInput;