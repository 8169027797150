import Icon from 'core/components/shared/Icon';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { InputGrp } from 'core/components/form-controls';
import MenuItem from 'core/components/form-controls/nested-accordion/MenuItem';
import MenuToggle from 'core/components/form-controls/nested-accordion/MenuToggle';
import NestedAccordion from 'core/components/form-controls/nested-accordion/NestedAccordion';
import EmployeeSearchGridResults from 'core/components/shared/EmployeeSearchGridResults';
import { loadAppEmpSearchNoModal } from 'core/store/actions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { createSearchEmployeeRequest } from 'utilities/search-request';
import { EmployeeMasterSection } from 'core/models/ModuleAccess.model';
import { getAccess } from 'utilities/utilities';

const toggleComponents: any[] = [MenuToggle, MenuItem];

type Form = {
  search: string;
};

type EmpDetailSidebarSubItem = {
  id: string;
  title: string;
  href: string;
  workItemId?: number;
};

type EmpDetailSidebarItem = {
  id: string;
  title: string;
  children: EmpDetailSidebarSubItem[];
};

type HrProfileSidebarProps = {
  sectionAccess: EmployeeMasterSection[] | undefined
};

const data: EmpDetailSidebarItem[] = [
  {
    id: '0',
    title: 'Employee Hire Info',
    children: [
      {
        id: '0b',
        title: 'ACA History',
        href: '/hr-profile/detail/{protectedEmpNo}/general-info-status',
        workItemId: 40,
      },
      {
        id: '0a',
        title: 'General',
        href: '/hr-profile/detail/{protectedEmpNo}/general-info',
      },
    ],
  },
  {
    id: '1',
    title: 'Employee Status',
    children: [
      {
        id: '1g',
        title: 'Company Property',
        href: '/hr-profile/detail/{protectedEmpNo}/company-property',
      },
      {
        id: '1f',
        title: 'Custom',
        href: '/hr-profile/detail/{protectedEmpNo}/custom',
      },
      {
        id: '1b',
        title: 'Dates',
        href: '/hr-profile/detail/{protectedEmpNo}/dates',
        workItemId: 7,
      },
      {
        id: '1h',
        title: 'Dependents',
        href: '/hr-profile/detail/{protectedEmpNo}/dependents',
      },
      {
        id: '1d',
        title: 'Emergency Contact',
        href: '/hr-profile/detail/{protectedEmpNo}/emergency-contact',
      },
      {
        id: '1a',
        title: 'General',
        href: '/hr-profile/detail/{protectedEmpNo}/general',
      },
      {
        id: '1e',
        title: 'Pay',
        href: '/hr-profile/detail/{protectedEmpNo}/pay',
      },
      {
        id: '1c',
        title: 'Status',
        href: '/hr-profile/detail/{protectedEmpNo}/status',
      },
    ],
  },
  {
    id: '2',
    title: 'Training',
    children: [
      {
        id: '2b',
        title: 'Education',
        href: '/hr-profile/detail/{protectedEmpNo}/training-education',
      },
      {
        id: '2a',
        title: 'Employment',
        href: '/hr-profile/detail/{protectedEmpNo}/training-employment',
      },
      {
        id: '2d',
        title: 'Licenses',
        href: '/hr-profile/detail/{protectedEmpNo}/training-licenses',
      },
      {
        id: '2e',
        title: 'Skills',
        href: '/hr-profile/detail/{protectedEmpNo}/training-skills',
      },
      {
        id: '2f',
        title: 'Sources and Fees',
        href: '/hr-profile/detail/{protectedEmpNo}/training-sources',
      },
      {
        id: '2c',
        title: 'Training Events',
        href: '/hr-profile/detail/{protectedEmpNo}/training-events',
      },
    ],
  },
  {
    id: '3',
    title: 'Performance Appraisal',
    children: [
      {
        id: '3a',
        title: 'Exit Interview',
        href: '/hr-profile/detail/{protectedEmpNo}/exit-interview',
      },
      {
        id: '3c',
        title: 'Increases',
        href: '/hr-profile/detail/{protectedEmpNo}/appraisal-increase',
      },
      {
        id: '3b',
        title: 'Reviews',
        href: '/hr-profile/detail/{protectedEmpNo}/appraisal-reviews',
      },
    ],
  },
  {
    id: '4',
    title: 'Vaccination',
    children: [
      {
        id: '4a',
        title: 'Vaccination Record',
        href: '/hr-profile/detail/{protectedEmpNo}/vaccination',
      },
    ],
  },
];

const BuildSidebar = ({ sectionAccess }: HrProfileSidebarProps) => {
  const securedData: EmpDetailSidebarItem[] = data.map((item) => {
    const modifiedChildren: EmpDetailSidebarSubItem[] = [];
    
    item.children.forEach((subItem) => {
      if (subItem?.workItemId) {
        const access = getAccess(sectionAccess, subItem.workItemId);
        if (access.visible) modifiedChildren.push(subItem);
      } else {
        modifiedChildren.push(subItem);
      }
    });
    
    return { ...item, children: modifiedChildren };
  });

  return (
    <>
      {securedData.map((item) => {
        return (
          <NestedAccordion
            key={item.id}
            level={1}
            item={item}
            toggleComponents={toggleComponents}
            contentStyle={{ margin: '0' }}
            toggleContainerStyle={{ marginTop: '3px' }}
            bodyContainerStyle={{ backgroundColor: 'white' }}
          />
        );
      })}
    </>
  );
};

const HrProfileSidebar = () => {
  const dispatch = useAppDispatch();
  const { handleSubmit, register } = useForm<Form>();
  const location = useLocation();

  const [searchModal, setSearchModal] = useState(false);
  const onSubmit = (data: Form) => {
    const request = createSearchEmployeeRequest(data.search);
    dispatch(loadAppEmpSearchNoModal(request));
    setSearchModal(true);
  };

  const currentPage = location.pathname.split('/');

  const employees = useAppSelector((state) => { return state.app.employeeSearch; });
  const sectionAccess = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections);
  
  const onHide = () => {
    setSearchModal(false);
  };
  return (
    <>
      <div className="sidebar">
        <div className="header">
          <Icon name="search" /> Search Employee
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="d-flex align-items-center"
        >
          <div className="flex-grow-1 mr-2">
            <InputGrp
              type="search"
              name={'search'}
              placeholder="Search"
              required
              ref={register}
            />
          </div>
          <div>
            <button className="btn orange-button-sm">
              <Icon name="search" />
              Search
            </button>
          </div>
        </form>
      </div>
      {BuildSidebar({ sectionAccess })}
      {searchModal && (
        <Modal
          show={searchModal}
          onHide={onHide}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Search Employee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EmployeeSearchGridResults
              url={`/hr-profile/detail/:protectedEmpNo/${currentPage[currentPage.length - 1]
              }`}
              employees={employees}
              onHide={onHide}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default HrProfileSidebar;
