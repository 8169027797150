import { AxiosResponse } from "axios";
import {
  AddPasswordToPDF,
  AppEmpSearch,
  Employee,
  UserPassword,
} from '../models';
import { API_CLIENT, API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { AppEndPoints } from './URL';

const postAppEmpSearch = (
  searchData: AppEmpSearch,
): Promise<AxiosResponse<Employee[]>> => {
  return API_EMPLOYEE_MASTER.post<Employee[]>(
    AppEndPoints.POST_APP_EMP_SEARCH(CommonService.getProtectedClientNo()),
    searchData,
  );
};

const getDefaultUserPassword = (
  secUserId: number,
): Promise<AxiosResponse<UserPassword>> => { return API_CLIENT.get<UserPassword>(AppEndPoints.DEFAULT_USER_PASSWORD(secUserId)); };

const getRelatedClients = () => {
  return API_CLIENT.get<any>(
    AppEndPoints.GET_CLIENTS_BY_ENTITY_ID(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const postDefaultUserPassword = (
  data: UserPassword,
): Promise<AxiosResponse<AddPasswordToPDF>> => {
  return API_CLIENT.post<AddPasswordToPDF>(
    AppEndPoints.DEFAULT_USER_PASSWORD(data.secUserId),
    data,
  );
};

const postEmailUserPDFPassword = (
  secUserId: number,
): Promise<AxiosResponse<any>> => {
  return API_CLIENT.post<any>(
    AppEndPoints.POST_EMAIL_PDF_PASSWORD(secUserId),
  );
};

export const AppService = {
  postAppEmpSearch,
  getRelatedClients,
  getDefaultUserPassword,
  postDefaultUserPassword,
  postEmailUserPDFPassword,
};
