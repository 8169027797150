import React, { useEffect, useState } from 'react';
import Icon from 'core/components/shared/Icon';
import { ArrayField, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SelectGrp } from 'core/components/form-controls';
import { EarningsCodeAutoCalculationResultComponent } from 'core/components/shared/EarningsCodeAutoCalculationResult';
import { EarningsCodeAutoCalculation, TransmittalSortOrder } from 'core/models';
import { updateAutoCalcs } from 'core/store/actions';
import {
  clearEarningsCodeAutoCalculationMessages,
  loadEarningCodes,
  loadEarningCodesAutoCalculations,
  updateEarningCodesAutoCalculations,
} from 'core/store/actions/earning-codes.action';
import {
  getEarningCodes,
  getEarningCodesAutoCalculations,
  getEarningCodesAutoCalculationsMessages,
} from 'core/store/selectors/earning-code.selector';
import { Modal } from 'react-bootstrap';
import { useAppSelector } from 'utilities/hooks';

type Props = {
  show: boolean;
  onHide: () => void;
  payrollHistoryId: number;
  controlTotalId: number;
  dirtyCallback: (isDirty: boolean) => void;
};

type FieldArray = {
  autoCalc: EarningsCodeAutoCalculation[];
};

export const AutoCalculations = ({ show, onHide, payrollHistoryId, controlTotalId, dirtyCallback }: Props) => {
  const [errorSourceEarningCodes, setErrorSourceEarningCodes] = useState<boolean[]>([]);

  const dispatch = useDispatch();
  
  const orderTransmittalBy = useAppSelector((state) => state.client.clientOptions.options?.[34]?.optionValue);
  const earningCodes = useSelector(getEarningCodes);
  const earningsCodeAutoCalculations = useSelector(
    getEarningCodesAutoCalculations,
  );
  const earningsCodeAutoCalculationMessages = useSelector(
    getEarningCodesAutoCalculationsMessages,
  );
  const {
    register,
    control,
    handleSubmit,
    trigger,
    setValue,
    errors,
    reset,
    formState: { isDirty },
  } = useForm<FieldArray>({
    defaultValues: {
      autoCalc: [],
    },
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'autoCalc',
  });

  useEffect(() => {
    dispatch(loadEarningCodesAutoCalculations());
    dispatch(loadEarningCodes());
    return () => {
      dispatch(clearEarningsCodeAutoCalculationMessages());
    };
  }, []);

  useEffect(() => {
    dirtyCallback(isDirty);
  }, [isDirty]);

  useEffect(() => {
    setValue('autoCalc', structuredClone(earningsCodeAutoCalculations));
    trigger();
  }, [earningCodes, earningsCodeAutoCalculations]);

  useEffect(() => {
    if (earningsCodeAutoCalculationMessages.length > 0) {
      if (
        window.confirm(
          'Auto Calculations have been updated. Do you want to update Auto Calculations in the current transmittal?',
        )
      ) {
        dispatch(
          updateAutoCalcs({
            payrollHistoryId: payrollHistoryId,
            controlTotalId: controlTotalId,
            earningsCodeAutoCalculations,
            orderBy: orderTransmittalBy as TransmittalSortOrder,
          }),
        );
      }
      onHide();
    }
  }, [earningsCodeAutoCalculationMessages]);

  const onSubmit = (data: FieldArray) => {
    const resultsUndefined = data.autoCalc.map(
      (result) => { return result.results === undefined; },
    );
    setErrorSourceEarningCodes(resultsUndefined);
    if (resultsUndefined.every((result) => { return !result; })) {
      dispatch(updateEarningCodesAutoCalculations(data.autoCalc));
      reset(data);
    }
  };

  const closeModal = () => {
    onHide();
  };

  return (
    <Modal
      show={show}
      backdrop="static"
      size="lg"
      onHide={onHide}
    >
      <Modal.Header className="border-0">
        <div className="d-flex flex-column w-100">
          <div className="d-flex w-100 justify-content-between">
            <h2 className="dm-page-title">Manage Auto Calculations</h2>
            <div className="d-flex align-items-end">
              <button
                className="btn btn-link dm-grid-action-title align-self-center"
                onClick={() => {
                  append({
                    autoCalcId: 0,
                    earningsCodeId: 0,
                  });
                }}
              >
                Add new Calculation{' '}
                <Icon
                  name="plus-circle"
                  className="fa-plus-circle"
                />
              </button>
              <button
                type="button"
                onClick={onHide}
                className="modal-close-btn mb-auto"
              >
                <Icon name="times" />
              </button>
            </div>
          </div>
          <hr className="hr-2" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((earningsCodeAutoCalculation: Partial<ArrayField<EarningsCodeAutoCalculation>>, index) => {
            return (
              <div
                key={earningsCodeAutoCalculation.id}
                data-id={earningsCodeAutoCalculation.autoCalcId}
                className={
                  'mb-3 ' +
                  (errorSourceEarningCodes[index]
                    ? 'border border-danger'
                    : 'border-bottom border-2')
                }
              >
                <h3
                  className={
                    'border-bottom border-3 ' +
                    (errorSourceEarningCodes[index]
                      ? 'text-danger'
                      : 'dm-grid-title')
                  }
                >
                  Source Earning Code
                </h3>
                <div className="row py-2">
                  <div className="col-md-6">
                    <SelectGrp
                      ref={register()}
                      name={`autoCalc[${index}].earningsCodeId`}
                      options={earningCodes}
                      valueField="earningsCodeId"
                      labelField="selectDescription"
                      key={`${earningsCodeAutoCalculation.autoCalcId}`}
                      defaultValue={
                        earningsCodeAutoCalculation.earningsCodeId
                      }
                    ></SelectGrp>
                    <input
                      type="hidden"
                      name={`autoCalc[${index}].autoCalcId`}
                      ref={register()}
                      defaultValue={
                        earningsCodeAutoCalculation.autoCalcId
                      }
                    />
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-sm-12">
                    <EarningsCodeAutoCalculationResultComponent
                      autoCalcIndex={index}
                      control={control}
                      register={register}
                      errors={errors}
                      setValue={setValue}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-link dm-grid-action-title"
                      onClick={() => {
                        remove(index);
                        trigger(); // Don't remove this line. For some reason the nested field array loses value until it's triggered.
                      }}
                    >
                      Delete Auto Calculation{' '}
                      <Icon
                        name="minus-circle"
                        className="fa-minus-circle"
                      />
                    </button>
                  </div>
                </div>
              </div>
            );
          },
          )}
          <div className="row justify-content-end">
            <div className="col-auto">
              <button
                type="button"
                className="btn btn-primary orange-outline-button mr-3"
                onClick={() => { return closeModal(); }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary orange-button"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AutoCalculations;
