import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ControlDatePickerGrp, InputGrp, SelectGrp } from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { AccrualRule } from 'core/models';
import { getAccrualRules, getPaidSickTimeEntities } from 'core/store/selectors';
import { convToFormObject } from 'utilities/classUtils';
import { toggleBlockNavigation, updateAccrualRule } from 'core/store/actions';
import { useAppSelector } from 'utilities/hooks';
import { getAccess } from 'utilities/utilities';
import { ControlIds, UNSAVED_MESSAGE } from 'core/constants';

const fs: FieldInputSettings = {
  ruleDescription: { name: 'ruleDescription',
    label: 'ACCRUAL TITLE',
    groupClass: 'gc15 mw150',
  },
  ltdBeginBalance: { name: 'ltdBeginBalance',
    label: 'BEGIN BALANCE',
    groupClass: 'gc12 mw125',
    inputStyle: { textAlign: 'right' } },
  ltdHours: { name: 'ltdHours',
    label: 'WORKED LIFE-TO-DATE',
    groupClass: 'gc12 mw150',
    inputStyle: { textAlign: 'right' } },
  total: { name: 'total',
    label: 'TOTAL',
    groupClass: 'gc10 mw100',
    inputStyle: { textAlign: 'right' } },
  creditedService: {
    name: 'creditedService',
    label: 'CREDIT SERVICE',
    groupClass: 'gc10 mw100',
    type: 'number',
    inputStyle: { textAlign: 'right' },
  },
  accrualHireDate: { name: 'accrualHireDate',
    label: 'ACCRUAL HIRE DATE',
    groupClass: 'gc11 mw125',
  },
  paidSickTimeEntityId: {
    name: 'paidSickTimeEntityId',
    label: 'PAID SICK TIME ENTITY',
    groupClass: 'gc15 mw150',
    noOption: true,
    addOptionText: 'paid sick time',
  },
};

type AccrualRuleForm = {
  ruleDescription: string,
  ltdBeginBalance: number,
  creditedService: number,
  accrualHireDate: Date | string | null,
  ltdHours: number,
  paidSickTimeEntityId: number | null
};

type PropTypes = {
  item: AccrualRule;
};

const AccrualRuleItem: React.FC<PropTypes> = ({ item }) => {
  const dispatch = useDispatch();

  const paidSickTimeOpts = useSelector(getPaidSickTimeEntities);
  const accrualRuleOpts = useSelector(getAccrualRules);
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });

  const { register, reset, handleSubmit, control, setValue, watch, formState } = useForm<AccrualRuleForm>({
    defaultValues: {
      ruleDescription: item?.ruleDescription ?? '',
      ltdBeginBalance: item?.ltdBeginBalance ?? 0,
      creditedService: item?.creditedService ?? 0,
      accrualHireDate: item?.accrualHireDate ?? null,
      ltdHours: item?.ltdHours ?? 0,
      paidSickTimeEntityId: item?.paidSickTimeEntityId ?? null,
    },
  });
  const accrualHireDate = watch('accrualHireDate');
  const { isDirty } = formState;

  useEffect(() => {
    reset(convToFormObject(item, AccrualRule.convProps));
  }, [item]);
  
  const onSave = (data: AccrualRuleForm) => {
    //It was not getting set on the item not sure why we just didnt take it from the form
    const hireDate = ((data?.accrualHireDate ?? '') !== '') ? new Date(data.accrualHireDate!).toISOString() : null;
    const accrualRule = new AccrualRule(item.clientNo, item.empId, item.empNo, item.protectedEmpNo, { ...data,
      accrualHireDate: hireDate });

    dispatch(updateAccrualRule(accrualRule));
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [isDirty]);

  return (
    <form onSubmit={handleSubmit(onSave)} >
      <div className="d-flex flex-row flex-wrap">
        <SelectGrp
          {...fs.ruleDescription}
          {...getAccess(sectionAccess, 24, ControlIds.ruleDescription)}
          ref={register}
          options={accrualRuleOpts}
        />
        <InputGrp
          {...fs.ltdBeginBalance}
          ref={register}
          readOnly={true}
        />
        <InputGrp 
          {...fs.ltdHours}
          ref={register}
          readOnly={true}
        />
        <InputGrp 
          {...fs.total}
          ref={register}
        />
        <InputGrp 
          {...fs.creditedService}
          {...getAccess(sectionAccess, 24)}
          ref={register}
        />
        <ControlDatePickerGrp
          {...fs.accrualHireDate}
          {...getAccess(sectionAccess, 24, ControlIds.accrualHireDate)}
          value={accrualHireDate}
          setValue={setValue}
          control={control}
        />
        <SelectGrp 
          {...fs.paidSickTimeEntityId}
          {...getAccess(sectionAccess, 24)}
          ref={register}
          options={paidSickTimeOpts}
        />
      </div>
      <div className="d-flex justify-content-end mt-2">
        <button
          className="btn orange-button-sm mr-3"
          type="submit"
          {...getAccess(sectionAccess, 24, undefined, { disabledDefault: !isDirty, disabledSameAsReadOnly: true })}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default AccrualRuleItem;
