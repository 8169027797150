import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { EmpGroupTerm, HttpResponse } from '../../models';
import { EmployeeGroupTermService } from '../../services';
import {
  handleError,
  handleSuccess,
  loadEmployeeGroupTerm,
  storeEmployeeGroupTerm,
  triggerEmFieldValidation,
  updateEmployeeGroupTerm,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadEmployeeGroupTerm$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmployeeGroupTerm.type),
    switchMap((action: { payload: { protectedEmpNo: string; }; }) => {
      return from(
        EmployeeGroupTermService.getEmployeeGroupTerm(
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: EmpGroupTerm) => { return storeEmployeeGroupTerm(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEmployeeGroupTerm$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateEmployeeGroupTerm.type),
    switchMap((action: { payload: EmpGroupTerm; }) => {
      return from(
        EmployeeGroupTermService.putEmployeeGroupTerm(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<any>) => {
          return [
            storeEmployeeGroupTerm(res.value),
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'grouptermlife',
              actionType: updateEmployeeGroupTerm.type,
              callerPayload: res.value,
            }),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [loadEmployeeGroupTerm$, updateEmployeeGroupTerm$];
