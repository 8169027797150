import { ofType } from 'redux-observable';
import { Observable, EMPTY, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  HttpResponse,
  PayrollAdjustmentEmployee,
  PayrollAdjustmentCheckRequest,
  PayrollAdjustmentCheckResponse,
  PayrollAdjustmentSummary,
  PayrollAdjustmentEmployeeVoid,
} from '../../models';
import { PayrollAdjustmentService } from '../../services';
import { handleError, handleSuccess } from '../actions/app.action';
import {
  createPayrollAdjustmentCheck,
  deletePayrollAdjustmentCheck,
  loadEmployeePayrollAdjustment,
  loadPayrollAdjustmentNewCheck,
  loadPayrollAdjustments,
  payrollAdjustmentCheckValidate,
  loadPayrollAdjustmentEmployeeVoids,
  storeEmployeePayrollAdjustment,
  storePayrollAdjustments,
  updatePayrollAdjustmentCheck,
  storePayrollAdjustmentNewCheck,
  storeValidatedCheck,
  loadPrintAdjustmentCheck,
  storePrintAdjustmentCheck,
  breakValidationChain,
} from '../actions/payroll-adjustment.action';

interface Actions<Type> {
  type: string;
  payload: Type;
}

const loadPayrollAdjustments$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadPayrollAdjustments.type),
    switchMap((action: { payload: { payrollHistoryId: number; }; }) => {
      return from(
        PayrollAdjustmentService.getPayrollAdjustments(
          action.payload.payrollHistoryId,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: PayrollAdjustmentEmployee[]) => { return storePayrollAdjustments(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadEmployeePayrollAdjustment$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadEmployeePayrollAdjustment.type),
    switchMap((action: { payload: { payrollHistoryId: number; empNo: string; }; }) => {
      return from(
        PayrollAdjustmentService.getEmployeePayrollAdjustment(
          action.payload.payrollHistoryId,
          action.payload.empNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: PayrollAdjustmentEmployee) => { return storeEmployeePayrollAdjustment(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadPayrollAdjustmentNewCheck$ = (
  action$: Observable<
  Actions<{
    payrollHistoryId: number;
    protectedEmpNo: string;
  }>
  >,
) => {
  return action$.pipe(
    ofType(loadPayrollAdjustmentNewCheck.type),
    switchMap((action: { payload: { payrollHistoryId: number; protectedEmpNo: string; }; }) => {
      return from(
        PayrollAdjustmentService.getPayrollAdjustmentNewCheck(
          action.payload.payrollHistoryId,
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: PayrollAdjustmentEmployee) => {
          return [
            storeEmployeePayrollAdjustment(res),
            storePayrollAdjustmentNewCheck(res.adjustmentChecks[0]),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const payrollAdjustmentCheckValidate$ = (
  action$: Observable<Actions<PayrollAdjustmentCheckRequest>>,
) => {
  return action$.pipe(
    ofType(payrollAdjustmentCheckValidate.type),
    switchMap((action: { payload: { data: PayrollAdjustmentSummary; payrollHistoryId: number; protectedEmpNo: string; }; }) => {
      return from(
        PayrollAdjustmentService.postPayrollAdjustmentCheckValidate(
          action.payload.data,
          action.payload.payrollHistoryId,
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap(
          (res: HttpResponse<PayrollAdjustmentCheckResponse>) => {
            return [
              breakValidationChain(false),
              storeValidatedCheck(res.value),
            ];
          },
        ),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            breakValidationChain(err?.statusCode !== 'OK'),
            storeValidatedCheck({
              adjustment: action.payload.data,
              originalAdjustment: action.payload.data,
              errors: err?.messages ?? [],
            }),
          ];
        }),
      );
    },
    ),
  );
};

const createPayrollAdjustmentCheck$ = (
  action$: Observable<Actions<PayrollAdjustmentCheckRequest>>,
) => {
  return action$.pipe(
    ofType(createPayrollAdjustmentCheck.type),
    switchMap((action: { payload: { data: PayrollAdjustmentSummary; payrollHistoryId: number; protectedEmpNo: string; }; }) => {
      return from(
        PayrollAdjustmentService.postPayrollAdjustmentCheck(
          action.payload.data,
          action.payload.payrollHistoryId,
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        mergeMap((res: any) => {
          return [
            loadPayrollAdjustments({
              payrollHistoryId: action.payload.payrollHistoryId,
            }),
            handleSuccess(res.data.messages),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updatePayrollAdjustmentCheck$ = (
  action$: Observable<Actions<PayrollAdjustmentCheckRequest>>,
) => {
  return action$.pipe(
    ofType(updatePayrollAdjustmentCheck.type),
    switchMap((action: { payload: PayrollAdjustmentCheckRequest }) => {
      return from(
        PayrollAdjustmentService.putPayrollAdjustmentCheck(
          action.payload,
        ),
      ).pipe(
        mergeMap((res: any) => {
          return [
            loadPayrollAdjustments({
              payrollHistoryId: action.payload.payrollHistoryId,
            }),
            handleSuccess(res.data.messages),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deletePayrollAdjustmentCheck$ = (
  action$: Observable<
  Actions<{
    payrollHistoryId: number;
    protectedEmpNo: string;
    empNo: number;
    adjustmentId: number;
  }>
  >,
) => {
  return action$.pipe(
    ofType(deletePayrollAdjustmentCheck.type),
    switchMap((action: { payload: { payrollHistoryId: number; protectedEmpNo: string; adjustmentId: number; empNo: number }; }) => {
      return from(
        PayrollAdjustmentService.deletePayrollAdjustmentChecks(
          action.payload.payrollHistoryId,
          action.payload.protectedEmpNo,
          action.payload.adjustmentId,
        ),
      ).pipe(
        mergeMap((res: any) => {
          return [
            loadPayrollAdjustments({
              payrollHistoryId: action.payload.payrollHistoryId,
            }),
            handleSuccess(res.data.messages),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const payrollAdjustmentEmployeeVoids$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadPayrollAdjustmentEmployeeVoids.type),
    switchMap((action: { payload: { data: PayrollAdjustmentEmployeeVoid; payrollHistoryId: number; empNo: string; }; }) => {
      return from(
        PayrollAdjustmentService.postPayrollAdjustmentEmployeeVoids(
          action.payload.data,
          action.payload.payrollHistoryId,
          action.payload.empNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return EMPTY; }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const printAdjustmentCheck$ = (
  action$: Observable<Actions<PayrollAdjustmentCheckRequest>>,
) => {
  return action$.pipe(
    ofType(loadPrintAdjustmentCheck.type),
    switchMap((action: { payload: PayrollAdjustmentCheckRequest; }) => {
      return from(
        PayrollAdjustmentService.postPrintAdjustmentCheck(
          action.payload,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<string>) => { return storePrintAdjustmentCheck(res.value); },
        ),
        catchError((err: any) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadPayrollAdjustments$,
  loadEmployeePayrollAdjustment$,
  loadPayrollAdjustmentNewCheck$,
  payrollAdjustmentCheckValidate$,
  createPayrollAdjustmentCheck$,
  updatePayrollAdjustmentCheck$,
  deletePayrollAdjustmentCheck$,
  payrollAdjustmentEmployeeVoids$,
  printAdjustmentCheck$,
];
