import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, GridOptions, ICellRendererParams, ModuleRegistry, ValueFormatterParams } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ChangeDetectionStrategyType } from '@ag-grid-community/react/lib/shared/changeDetectionService';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { AmountDetail, EarningsRecord, EarningsRecordQtr } from 'core/models';
import { agCurrencyRenderer, agDateRenderer } from 'utilities/ag-grid-renderers';
import { currencyFormatter, formatDecimal } from 'utilities/utilities';

interface EarningsRecordHours extends EarningsRecord {
  total: number;
}

interface EarningsRecordQtrHours extends EarningsRecordQtr {
  total: number;
}

const highlightCellStyle = (params: any) => {
  return params.data.quarter === ''
    ? { justifyContent: 'flex-end', backgroundColor: '#b7e4ff' }
    : { justifyContent: 'flex-end', backgroundColor: '' };
};

const getCol1Defs = (items: EarningsRecord[], type: string) => {
  const colDefs: ColDef[] = [
    {
      field: 'weekEnd',
      headerName: 'Week Ending',
      cellRenderer: 'dateRenderer',
      width: 110,
    },
    {
      field: 'checkDate',
      headerName: 'Check Date',
      cellRenderer: 'dateRenderer',
      width: 100,
    },
    {
      field: 'checkNo',
      headerName: 'Check No',
      width: 90,
    },
    {
      field: 'checkType',
      headerName: 'Check Type',
      width: 100,
    },
  ];

  const cd = new Set<string>();
  items.forEach((er: EarningsRecord) => {
    er.amounts.forEach((ad: AmountDetail) => {
      cd.add(ad.description);
    });
  });
  cd.forEach((x: string) => {
    return colDefs.push({
      field: x,
      headerName: x,
      cellRenderer: (e: ICellRendererParams) => {
        const amount: number = e.data?.[x] ?? 0;
        return <span>{type === 'hours' ? formatDecimal(amount) : currencyFormatter(amount)}</span>;
      },
      cellStyle: { justifyContent: 'flex-end' },
      width: 120,
    });
  },
  );

  return colDefs;
};

const getCol2Defs = (items: EarningsRecordQtr[]) => {
  const colDefs: ColDef[] = [
    {
      field: 'quarter',
      headerName: 'Quarter',
      cellStyle: highlightCellStyle,
      width: 75,
    },
    {
      field: 'year',
      headerName: 'Year',
      cellStyle: highlightCellStyle,
      width: 55,
    },
  ];

  const cd = new Set<string>();
  items.forEach((er: EarningsRecordQtr) => {
    er.amounts.forEach((ad: AmountDetail) => {
      cd.add(ad.description);
    });
  });
  cd.forEach((x: string) => {
    return colDefs.push({
      field: x,
      headerName: x,
      cellRenderer: 'currencyRenderer',
      cellStyle: highlightCellStyle,
      width: 120,
    });
  },
  );

  return colDefs;
};

const getGrid1Options: () => GridOptions = () => {
  return {
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    // @ts-ignore
    components: {
      dateRenderer: agDateRenderer,
      currencyRenderer: agCurrencyRenderer,
    },
  };
};

const getGrid2Options: () => GridOptions = () => {
  return {
    domLayout: 'autoHeight',
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    components: {
      dateRenderer: agDateRenderer,
      currencyRenderer: agCurrencyRenderer,
    },
  };
};

const grid1ContainerStyle: CSSProperties = {
  height: '515px',
};
const grid2ContainerStyle: CSSProperties = {
  height: '300px',
  overflow: 'auto',
};

ModuleRegistry.registerModules([ClientSideRowModelModule]);

type PropTypes = {
  items: EarningsRecord[];
  totals: EarningsRecordQtr[];
  type: string;
  visible: string;
};

const EarningsDetail: React.FC<PropTypes> = ({ items, totals, type, visible }) => {
  const [row1Data, setRow1Data] = useState<any[]>([]);
  const [row2Data, setRow2Data] = useState<any[]>([]);
  const [col1Defs, setCol1Defs] = useState<ColDef[]>([]);
  const [col2Defs, setCol2Defs] = useState<ColDef[]>([]);
  const [hoursAverage, setHoursAverage] = useState(0);

  const grid1Ref = useRef<AgGridReact>(null);
  const grid2Ref = useRef<AgGridReact>(null);
  const grid1Options = useRef<GridOptions>(getGrid1Options());
  const grid2Options = useRef<GridOptions>(getGrid2Options());

  useEffect(() => {
    let totalHours = 0;
    if (type === 'hours') {
      setRow1Data(
        items.map((er: EarningsRecord) => {
          const row: EarningsRecordHours = { ...er, total: 0 };
          row.total = er.amounts.reduce(
            (acc: number, cur: AmountDetail) => {return acc + cur.amount;},
            0,
          );
          totalHours += row.total;
          return row;
        }),
      );
    } else {
      setRow1Data(items.map((x) => ({ ...x, amounts: x.amounts.filter((y) => y.amount > 0) })));
    }
    setHoursAverage(totalHours / items.length);

    if (totals && totals.length) {
      const totalRow: any = { quarter: '', year: '' };
      totals.forEach((t: EarningsRecordQtr) => {
        return t.amounts.forEach((a: AmountDetail) => {
          return totalRow[a.description]
            ? (totalRow[a.description] += a.amount)
            : (totalRow[a.description] = a.amount);
        },
        );
      },
      );
      if (type === 'hours') {
        let total = 0;
        for (const i in totalRow) {
          if (typeof totalRow[i] === 'number') {
            total += totalRow[i];
          }
        }
        totalRow.total = total;
        setRow2Data([
          ...totals.map((erq: EarningsRecordQtr) => {
            const row: EarningsRecordQtrHours = {
              ...erq,
              total: 0,
            };
            row.total = erq.amounts.reduce(
              (acc: number, cur: AmountDetail) => {return acc + cur.amount;},
              0,
            );
            return row;
          }),
          totalRow,
        ]);
      } else {
        setRow2Data([...totals, totalRow]);
      }
    }
  }, [items]);

  useEffect(() => {
    if (row1Data && row1Data.length) {
      const colDefs = getCol1Defs(items, type);
      if (type === 'hours') {
        colDefs.forEach((element) => {
          if (element.cellRenderer === 'currencyRenderer') {
            element.cellRenderer = '';
            element.valueFormatter = (
              params: ValueFormatterParams,
            ) => {
              return params.value
                ? params.value.toFixed(2)
                : '0.00';
            };
          }
        });
        colDefs.push({
          field: 'total',
          cellStyle: { justifyContent: 'flex-end' },
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value.toFixed(2);
          },
        });
      }
      setCol1Defs(colDefs);
    }
  }, [row1Data]);

  useEffect(() => {
    if (row2Data && row2Data.length) {
      const colDefs = getCol2Defs(totals);
      if (type === 'hours') {
        colDefs.forEach((element) => {
          if (element.cellRenderer === 'currencyRenderer') {
            element.cellRenderer = '';
            element.valueFormatter = (
              params: ValueFormatterParams,
            ) => {
              return params.value !== undefined
                ? params.value.toFixed(2)
                : '0.00';
            };
          }
        });
        colDefs.push({
          field: 'total',
          cellStyle: highlightCellStyle,
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value ? params.value.toFixed(2) : '0.00';
          },
        });
      }
      setCol2Defs(colDefs);
    }
  }, [row2Data]);

  useEffect(() => {
    if (visible === type) {
      if (grid1Ref.current) {
        const allColumnIds = grid1Ref.current.columnApi.getAllColumns();
        grid1Ref.current.columnApi.autoSizeColumns(allColumnIds || [], false);
      }
      if (grid2Ref.current) {
        const allColumnIds = grid2Ref.current.columnApi.getAllColumns();
        grid2Ref.current.columnApi.autoSizeColumns(allColumnIds || [], false);
      }
    }
  }, [visible]);

  return (
    <div>
      <div className="mb-5">
        <div
          className="ag-theme-balham mb-2"
          style={grid1ContainerStyle}
        >
          <AgGridReact
            gridOptions={grid1Options.current}
            rowData={row1Data}
            columnDefs={col1Defs}
            rowDataChangeDetectionStrategy={
              ChangeDetectionStrategyType.IdentityCheck
            }
            ref={grid1Ref}
          />
        </div>
        {type === 'hours' && (
          <div className="d-flex justify-content-between border">
            <div className="m-2">Average</div>
            <div className="m-2">{hoursAverage.toFixed(2)}</div>
          </div>
        )}
      </div>

      <div>
        <div
          className="ag-theme-balham"
          style={grid2ContainerStyle}
        >
          <AgGridReact
            gridOptions={grid2Options.current}
            rowData={row2Data}
            columnDefs={col2Defs}
            rowDataChangeDetectionStrategy={
              ChangeDetectionStrategyType.IdentityCheck
            }
            ref={grid2Ref}
          />
        </div>
      </div>
    </div>
  );
};

export default EarningsDetail;
