import { createReducer } from '@reduxjs/toolkit';
import { Deduction, GarnishmentEntities } from '../../models';
import {
  createDeduction,
  loadCompanyBenefits,
  loadDeductions,
  loadDeductionsByType,
  loadDeductionsByTypeFail,
  loadDeductionsFail,
  loadGarnishmentEntities,
  setBlockNav,
  storeCompanyBenefits,
  storeDeductions,
  storeDeductionsByType,
  storeGarnishmentEntities,
  updateSavingState,
} from '../actions';

export interface State {
  loading: boolean;
  saving: 'success' | 'pending' | 'error';
  blockNav: boolean;
  error: any;
  deductions: Deduction[];
  companyBenefits: Deduction[];
  garnishmentEntities: GarnishmentEntities[];
}

export const INITIAL_STATE: State = {
  loading: false,
  saving: 'success',
  blockNav: false, // defining this in redux so that we only turn it off on resolved promise
  error: null,
  deductions: [],
  companyBenefits: [],
  garnishmentEntities: [],
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadDeductions, (state) => {
      state.loading = true;
    })
    .addCase(storeDeductions, (state, action) => {
      state.loading = false;
      state.saving = 'success';
      state.deductions = action.payload;
    })
    .addCase(loadDeductionsFail, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(loadDeductionsByType, (state) => {
      state.loading = true;
    })
    .addCase(storeDeductionsByType, (state, action) => {
      state.loading = false;
      state.saving = 'success';
      state.deductions = action.payload;
    })
    .addCase(loadDeductionsByTypeFail, (state, action) => {
      state.loading = false;
      state.saving = 'success';
      state.error = action.payload;
    })
    .addCase(loadCompanyBenefits, (state) => {
      state.loading = true;
    })  
    .addCase(storeCompanyBenefits, (state, action) => {
      state.loading = false;
      state.companyBenefits = action.payload;
    })
    .addCase(loadGarnishmentEntities, (state) => {
      state.loading = true;
    })
    .addCase(storeGarnishmentEntities, (state, action) => {
      state.loading = false;
      state.garnishmentEntities = action.payload;
    })
    .addCase(createDeduction, (state) => {
      state.loading = true;
      state.saving = 'pending';
    })
    .addCase(setBlockNav, (state, action) => {
      state.blockNav = action.payload;
    })
    .addCase(updateSavingState, (state, action) => {
      state.saving = action.payload;
    })
  ;
});