import { ClientDeduction, Dropdown } from "core/models";

export const getPercentageOpts = (category: number, percentOverride?: number) => {
  switch (+category) {
    //FOC
    case 1:
      return [
        {
          id: 40,
          description: '40%',
        },
        {
          id: 45,
          description: '45%',
        },
        {
          id: 50,
          description: '50%',
        },
        {
          id: 55,
          description: '55%',
        },
        {
          id: 60,
          description: '60%',
        },
        {
          id: 65,
          description: '65%',
        },
      ];
      //Creditor
    case 2:
      let creditorOpts = 
      [
        {
          id: 15,
          description: '15%',
        },
        {
          id: 20,
          description: '20%',
        },
        {
          id: 25,
          description: '25%',
        },
      ];
      if (percentOverride !== 0 && creditorOpts.find(x => x.id == (percentOverride ?? 0)) == null) {
        creditorOpts.push({
          id: percentOverride ?? 0,
          description: percentOverride?.toString() + '%',
        });
      }
      return creditorOpts.sort((x, y) => x.id - y.id);
      //Levy
    case 3:
      let levyOpts = 
      [
        {
          id: 0,
          description: '0%',
        },
        {
          id: 10,
          description: '10%',
        },
        {
          id: 15,
          description: '15%',
        },
        {
          id: 20,
          description: '20%',
        },
        {
          id: 25,
          description: '25%',
        },
        {
          id: 50,
          description: '50%',
        },
        {
          id: 55,
          description: '55%',
        },
        {
          id: 60,
          description: '60%',
        },
        {
          id: 65,
          description: '65%',
        },
      ];

      if (percentOverride !== 0 && levyOpts.find(x => x.id == (percentOverride ?? 0)) == null) {
        levyOpts.push({
          id: percentOverride ?? 0,
          description: percentOverride?.toString() + '%',
        });
      }
      return levyOpts.sort((x, y) => x.id - y.id);
      //Student Loan
    case 4:
      return [{
        id: 15,
        description: '15%',
      }];
      //Bankrupt
    case 5:
      return [{
        id: 0,
        description: '0%',
      }];
      //Court
    case 6:
      return [{
        id: 0,
        description: '0%',
      }];
    default:
      return [{
        id: 0,
        descripiton: '',
      }];
  }
};

export const validateDeductionNumber = (value: number, ageByEndOfYear: number, clientDeduction: ClientDeduction | undefined) => {
  const catchupDeductionIds = [4, 10, 36, 41, 66];
  if (ageByEndOfYear < 49 && catchupDeductionIds.includes(clientDeduction?.deductionTypeId ?? 0)) {
    return 'This employee is not eligible to have a Catch-Up. THey must be turning 50 years of age during this calendar year.';
  }
};

export const validateExpireDate = (value: Date, startDate: Date, expireDateRequireCheck: boolean = false) => {
  const startDateTime = startDate ? startDate.getTime() : null;
  const endDateTime = value ? new Date(value).getTime() : null;
  // PI-8298 Start date is not required if the expire date is provided.
  // if (!startDateTime && endDateTime) {
  //   return 'Start date is required if expire date is provided.';
  // }
  if (!endDateTime && startDateTime && expireDateRequireCheck) {
    return 'Expire date required.';
  }
  if (((startDateTime ?? 0) > (endDateTime ?? 0)) && endDateTime) {
    return 'Expire date cannot come before start date.';
  }
  return startDateTime && endDateTime && (startDateTime < endDateTime);
};