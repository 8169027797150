import React, { useEffect, useState } from 'react';
import { InputGrp } from 'core/components/form-controls';
import Modal from 'core/components/modals/Modal';
import { useSelector } from 'react-redux';
import { getLocalTaxEntity, getLocationDepartments } from 'core/store/selectors';
import { CodeType, Property } from '../TransmittalEarningsItem';
import { Department, Dropdown, SubDepartment, SubDepartment2 } from 'core/models';
import { ShiftPremium } from 'core/models/ShiftPremium.model';
import Icon from 'core/components/shared/Icon';
import DropdownAddEditModal from 'core/components/form-controls/select-modal/DropdownAddEditModal';
import DepartmentOptionForm from 'core/components/form-controls/select-modal/DepartmentOptionForm';
import SubDepartmentOptionForm from 'core/components/form-controls/select-modal/SubDepartmentOptionForm';
import SubDepartment2OptionForm from 'core/components/form-controls/select-modal/SubDepartment2OptionForm';

type Props = {
  type: CodeType;
  show: boolean;
  onSelectProp: (property: Property, newValue: any) => void;
  onHide: () => void;
};

type CastedKeysArray = [string | number | undefined, string | number | undefined, string];
type OptionType = Department | SubDepartment | SubDepartment2 | ShiftPremium | Dropdown;
type OptionId = 'deptCode' | 'subDeptCode' | 'sub2Code' | 'shiftCode' | 'id';
type OptionDescription = 'deptDesc' | 'subDeptDesc' | 'sub2Desc' | 'description';
type OptionInfo = {
  options: OptionType[];
  idField: OptionId;
  descField: OptionDescription;
  property: Property;
  formComponent: React.FunctionComponent<any> | null;
};

const ChangeCode = ({ type, show, onHide, onSelectProp }: Props) => {
  const { deptOpts, subdeptOpts, subdept2Opts } = useSelector(getLocationDepartments);
  const cityCodes: Dropdown[] = useSelector(getLocalTaxEntity);

  // made more sense to do this heavy lifting in the modal rather than add complexity to the earnings component
  const availableOptions: { [key in CodeType]: OptionInfo } = {
    'Department': {
      options: deptOpts,
      idField: 'deptCode',
      descField: 'deptDesc',
      property: 'dept',
      formComponent: DepartmentOptionForm,
    },
    'Sub Department': {
      options: subdeptOpts,
      idField: 'subDeptCode',
      descField: 'subDeptDesc',
      property: 'subDept',
      formComponent: SubDepartmentOptionForm,
    },
    'Sub Department 2': {
      options: subdept2Opts,
      idField: 'sub2Code',
      descField: 'sub2Desc',
      property: 'subDept2',
      formComponent: SubDepartment2OptionForm,
    },
    'City': {
      options: cityCodes,
      idField: 'id',
      descField: 'description',
      property: 'cityTaxEntityId',
      formComponent: null,
    },
    'Shift Code': {
      options: [],
      idField: 'shiftCode',
      descField: 'description',
      property: 'shiftPremiumId',
      formComponent: null,
    },
  };
  
  // will return an array of specified properties from each Option that're casted so we don't need to do this everywhere 
  const castedKeys = (option: OptionType): CastedKeysArray => {
    return [
      option[availableOptions[type].idField as keyof typeof option],
      option[availableOptions[type].descField as keyof typeof option],
      availableOptions[type].property,
    ];
  };
  
  const [filteredOpts, setFilteredOpts] = useState<OptionType[]>(availableOptions[type]?.options ?? []);
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);

  useEffect(() => {
    if (type === 'Shift Code') return;
    setFilteredOpts(availableOptions[type].options);
  }, [type]);
  
  const onSelect = (option: OptionType) => {
    const [optId, optDesc, optProperty] = castedKeys(option);
    if ([optId, optDesc, optProperty].includes(undefined)) return console.error('Undefined value');
    
    onSelectProp(String(optProperty) as Property, optId);
    onHide();
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    if (!query?.length) {
      setFilteredOpts(availableOptions[type].options);
      return;
    }
    
    setFilteredOpts(
      availableOptions[type].options?.filter((option: OptionType) => {
        const [optId, optDesc] = castedKeys(option);
        if (!(optId && optDesc)) return console.error('Could not find id or description');
        
        return String(optId).includes(query) || String(optDesc).toLowerCase().includes(query.toLowerCase());
      },
      ) ?? [],
    );
  };
  
  const HeaderSlot = () => !availableOptions[type]?.formComponent ? null : (
    <button
      className="btn btn-link ml-auto"
      onClick={() => { setShowAddEditModal(true); }}
    >
      Add {type}&nbsp;<Icon
        name="plus-circle"
        className="fa-plus-circle"
      />
    </button>
  );

  return (
    <>
      {/* TS isn't able to narrow this for some reason, but formComponent is not null at this point so we just cast. */}
      {showAddEditModal && !!availableOptions[type]?.formComponent ? (
        <DropdownAddEditModal
          header={`${type}s`}
          options={availableOptions[type].options}
          show={showAddEditModal}
          onHide={() => { setShowAddEditModal(false); }}
          formComponent={availableOptions[type].formComponent as React.FunctionComponent<any>}
          valueField={availableOptions[type].idField}
          idField={availableOptions[type].idField}
          labelField={availableOptions[type].descField}
        />
      ) : null}
      <Modal
        show={show}
        onHide={onHide}
        title={`Change ${type}`}
        headerSlot={<HeaderSlot />}
        size={type === 'Shift Code' ? 'xl' : 'sm'}
      >
        <InputGrp
          onChange={onChange}
          placeholder="Search"
          autoFocus
        />
        <div className="list-group">
          {filteredOpts?.map((option: OptionType) => {
            const [optId, optDesc] = castedKeys(option);
      
            return (
              <button
                key={optId}
                className="list-group-item list-group-item-action"
                type="button"
                onClick={() => { onSelect(option); }}
              >
                {optId + ' - ' + optDesc}
              </button>
            );
          },
          )}
        </div>
      </Modal>
    </>
  );
};

export default ChangeCode;
