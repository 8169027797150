import { AxiosResponse } from "axios";
import { MyInfoChangeLog, MyInfoChangeLogHistory } from '../models/MyInfoChangeLog';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getMyInfoChanges = (): Promise<AxiosResponse<MyInfoChangeLog[]>> => {return API_EMPLOYEE_MASTER.get<MyInfoChangeLog[]>(EmployeeEndPoints.MYINFO_CHANGES(CommonService.getProtectedClientNo()));};

const getMyInfoChangeLogHistory = (): Promise<AxiosResponse<MyInfoChangeLogHistory>> => {return API_EMPLOYEE_MASTER.get<MyInfoChangeLogHistory>(EmployeeEndPoints.MYINFO_CHANGES_HISTORY(CommonService.getProtectedClientNo()));};

export const MyInfoChangesService = {
  getMyInfoChangeLogHistory,
  getMyInfoChanges,
};
