import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Client, DtoOptions } from 'core/models';
import { useAppSelector } from 'utilities/hooks';
import InputReportDateSelector from 'core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { JobDetailRequest } from 'core/models/ContractorReports.model';
import {
  clearJobDetailReport,
  downloadJobDetailReport,
} from 'core/store/slices/contractorReports.slice';
import { useForm } from 'react-hook-form';

const JobDetailReport = () => {
  const dispatch = useDispatch();

  const childRef: React.RefObject<any> = useRef();

  const report = useAppSelector(
    (state) => { return state.contractorReports.jobDetailReport; },
  );
  const client = useAppSelector((state) => { return state.client.client; }) as Client;

  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [radioOption, setRadioOption] = useState('');

  const { handleSubmit } = useForm<JobDetailRequest>();

  const getDataFromInputDateSelector = (data: any) => {
    setRadioOption(data);
  };

  const downloadReport = () => {
    const submitData: JobDetailRequest = {
      clientNo: client.clientNo,
      dtoOption: reportDates[0],
      byWeekEndingOrCheckDate:
        radioOption === 'checkDate' ? 'ByCheckDate' : 'ByWeekEnding',
    };
    dispatch(downloadJobDetailReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearJobDetailReport());
  };

  return (
    <div className="dm-panel dm-panel-border">
      <form onSubmit={handleSubmit(downloadReport)}>
        <div className="dm-grid-title">Job Detail</div>
        <hr className="dm-panel-hr" />

        <div className="d-flex flex-row flex-grow-1">
          <div>
            <InputReportDateSelector
              ref={childRef}
              returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }
              }
              passDataFromInputDateSelector={
                getDataFromInputDateSelector
              }
            />
          </div>
          <div className="flex-grow-1 text-right">
            <button
              type="button"
              className="orange-outline-button mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="orange-button mr-2"
              disabled={!reportDates.length}
            >
              Run Report
            </button>
          </div>
        </div>
      </form>
      {report ? (
        <PDFViewerModal
          show={report.length > 0}
          pdfData={report}
          onHide={hidePdfViewer}
          reportName="JobDetailReport"
        />
      ) : null}
    </div>
  );
};
export default JobDetailReport;
