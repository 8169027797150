import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { InputGrp } from '../../core/components/form-controls/InputGrp';
import { File } from '../../core/models/File.model';
import { CommonService } from '../../core/services';
import { getUsers } from '../../core/store/slices/cmUsers.slice';
import {
  postFileFolder,
  putFileFolder,
} from '../../core/store/slices/file.slice';
import { useAppDispatch, useAppSelector } from '../../utilities/hooks';

type Props = {
  onHide: () => void;
  fileFolder: File.FolderRecord | null;
};

const AddFolder: React.FC<Props> = ({ onHide, fileFolder }) => {
  const dispatch = useAppDispatch();

  const { register, handleSubmit, errors } = useForm<File.ManageFolder>({
    defaultValues: {
      name: fileFolder && !fileFolder.readOnly ? fileFolder.name : '',
    },
  });

  const users = useAppSelector((state) => {return state.cmUsers.users;});
  const fileAccessLevel = useAppSelector(
    (state) => {return state.dropdown.fileAccessLevel;},
  );
  const clientNo = useAppSelector((state) => {return state.client.client?.clientNo;});

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const onSubmit = (data: File.ManageFolder) => {
    const mergedData = {
      ...data,
      clientNo,
      protectedClientNo: CommonService.getProtectedClientNo(),
      fileCategoryId: fileFolder!.fileCategoryId,
    } as File.ManageFolder;
    if (fileFolder && fileFolder.readOnly) {
      dispatch(postFileFolder(mergedData));
    } else {
      dispatch(putFileFolder(mergedData));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputGrp
        label="Folder Name"
        ref={register({
          required: {
            value: true,
            message: 'Folder Name is required',
          },
        })}
        name="name"
        errors={errors.name}
      />
      <table
        className="table table-bordered table-collapse table-hover"
        style={{ height: 'calc(100vh - 300px)' }}
      >
        <thead>
          <tr>
            <th>Access Level</th>
            <th>User Name</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => {
            return (
              <tr key={user.userName}>
                <td>
                  <input
                    type="hidden"
                    name={`userFolderAccess[${index}].secUserId`}
                    defaultValue={user.secUserId}
                    ref={register({ valueAsNumber: true })}
                  />
                  <select
                    className="form-control"
                    name={`userFolderAccess[${index}].fileAccessLevelId`}
                    ref={register({ valueAsNumber: true })}
                    defaultValue={
                      fileFolder?.userFolderAccess.find(
                        (a) => {return a.secUserId === user.secUserId;},
                      )?.fileAccessLevelId ?? 3
                    }
                  >
                    {fileAccessLevel.map((file) => {
                      return (
                        <option value={file.id} key={file.id}>
                          {file.description}
                        </option>
                      );
                    })}
                  </select>
                </td>
                <td>
                  {user.lastName}, {user.firstName},{' '}
                  {user.middleName} - {user.userName}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Row className="justify-content-end">
        <Col xs="auto">
          <button
            type="button"
            className="btn orange-outline-button mr-2"
            onClick={() => {return onHide();}}
          >
            Clear
          </button>
          <button type="submit" className="btn orange-button">
            Save
          </button>
        </Col>
      </Row>
    </form>
  );
};

export default AddFolder;
