import React, { ReactNode } from 'react';
import Modal from './Modal';

type PropTypes = {
  title?: string;
  message?: string | ReactNode;
  show: boolean;
  closeAfterConfirm?: boolean;
  onHide: () => void;
  onConfirmed: (confirmed: boolean, path?: string[], cancel?: boolean) => void;
  backdrop?: true | 'static';
  path?: string[];
  showCancel?: boolean;
  buttonLabels?: { onDeny: string; onConfirm: string; };
};

const ConfirmationModal: React.FC<PropTypes> = ({
  title,
  message,
  show,
  closeAfterConfirm = true,
  showCancel = false,
  onHide,
  onConfirmed,
  backdrop = true,
  path,
  buttonLabels = { onDeny: 'No', onConfirm: 'Yes' },
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      title={title}
      backdrop={backdrop}
    >
      {(closeModal) => {
        return (
          <>
            <div style={{ whiteSpace: 'pre-line' }}>
              {message}
            </div>
            <div className="d-flex justify-content-end mt-2">
              <div className="d-flex flex-row">
                {showCancel ? (
                  <button
                    type="button"
                    className="btn btn-primary orange-outline-button mr-2"
                    onClick={() => {
                      onConfirmed(false, path, true);
                      closeModal();
                    }}
                  >
                    Cancel
                  </button>
                ) : null}
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button mr-2"
                  onClick={() => {
                    onConfirmed(false, path, false);
                    closeModal();
                  }}
                >
                  {buttonLabels.onDeny}
                </button>
                <button
                  type="button"
                  className="btn btn-primary orange-button"
                  onClick={() => {
                    onConfirmed(true, path, false);
                    if (closeAfterConfirm) 
                      closeModal();
                  }}
                >
                  {buttonLabels.onConfirm}
                </button>
              </div>
            </div>
          </>
        );
      }}
    </Modal>
  );
};

export default ConfirmationModal;