import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkFlowTaskList } from 'core/models/WorkFlow.model';
import { getWorkFlowTaskList, postCreateDefaultWorkFlowLevelUser, postWorkFlowTask, putWorkFlowTaskStatus } from '../actions/work-flow.action';

export interface ApplicantTrackingState {
  loading: boolean;
  workFlowTaskList: WorkFlowTaskList[];
  myWorkFlowTaskList: WorkFlowTaskList[];
  showWorkTaskSuccessModal: boolean;
}

const initialState: ApplicantTrackingState = {
  loading: false,
  workFlowTaskList: [],
  myWorkFlowTaskList: [],
  showWorkTaskSuccessModal: false
};

export const workFlowSlice = createSlice({
  name: 'workFlow',
  initialState,
  reducers: {
    storeShowWorkTaskSuccessModal: (state, action: PayloadAction<boolean>) => {
        state.loading = false;
        state.showWorkTaskSuccessModal = action.payload;
      },
  },
  extraReducers(builder) {
    builder
      .addCase(getWorkFlowTaskList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWorkFlowTaskList.fulfilled, (state, action) => {
        state.loading = false;
        state.workFlowTaskList = action?.payload || [];
        state.myWorkFlowTaskList = action?.payload?.filter(x => x.canApprove) || []
      })
      .addCase(getWorkFlowTaskList.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(putWorkFlowTaskStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(putWorkFlowTaskStatus.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(putWorkFlowTaskStatus.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(postWorkFlowTask.pending, (state) => {
        state.loading = true;
      })
      .addCase(postWorkFlowTask.fulfilled, (state, action) => {
        state.loading = false;
        state.showWorkTaskSuccessModal = true;
      })
      .addCase(postWorkFlowTask.rejected, (state) => {
        state.loading = false;
      });

      builder
      .addCase(postCreateDefaultWorkFlowLevelUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(postCreateDefaultWorkFlowLevelUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postCreateDefaultWorkFlowLevelUser.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
    storeShowWorkTaskSuccessModal
} = workFlowSlice.actions;

export default workFlowSlice.reducer;
