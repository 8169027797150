import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { CorrectionsReportRequest, HttpResponse } from '../../models';
import {
  deleteMescEarnCorrection,
  getDeptAllocationCorrections,
  getEEOInfoCorrections,
  getWorkersCompCorrections,
  handleError, handleSuccess, handleWarning, loadDeptAllocationEmployees, loadDeptAllocationReport, postDeptAllocationCorrections, postMescEarnCorrection, putEEOInfoCorrections,
  putWorkersCompCorrections,
  storeDeptAllocationCorrections,
  storeDeptAllocationEmployees,
  storeDeptAllocationReport,
  storeDeptChangeCorrections,
  storeEEOInfoCorrections,
  storeWorkersCompCorrections,
} from '../actions';
import { Epic, Actions } from './types';
import { CorrectionService } from 'core/services';
import {
  AllocationEmployee,
  CorrectionsGetRequest,
  DeptAllocation,
  DeptAllocationCorrectionsGetRequest,
  DeptAllocationDTO,
  DeptAllocationWithOptionalId,
  EEOInfoCorrection,
  MescEarnDeptAllocDeleteRequest,
  MescEarnDeptAllocPostRequest,
  UpdateEEOInfoRequest,
  UpdateRequest,
  UpdateWorkersCompRequest,
  WorkersCompCorrection,
} from 'core/models';

const getEEOInfoCorrections$ = (action$: Observable<Actions<CorrectionsGetRequest>>) => {
  return action$.pipe(
    ofType(getEEOInfoCorrections.type),
    switchMap((action: { payload: CorrectionsGetRequest }) => {
      return from(CorrectionService.getEEOInfoCorrections(action.payload)).pipe(
        map((res) => { return storeEEOInfoCorrections(res.data); }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    }),
  );
};

const putEEOInfoCorrections$ = (action$: Observable<Actions<UpdateEEOInfoRequest>>) => {
  return action$.pipe(
    ofType(putEEOInfoCorrections.type),
    switchMap((action: { payload: UpdateEEOInfoRequest }) => {
      return from(CorrectionService.putEEOInfoCorrections(action.payload)).pipe(
        map((res) => { return res.data; }),
        mergeMap((res: HttpResponse<EEOInfoCorrection[]>) => {
          return [
            storeEEOInfoCorrections(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    }),
  );
};

const getWorkersCompCorrections$ = (action$: Observable<Actions<CorrectionsGetRequest>>) => {
  return action$.pipe(
    ofType(getWorkersCompCorrections.type),
    switchMap((action: { payload: CorrectionsGetRequest }) => {
      return from(CorrectionService.getWorkersCompCorrections(action.payload)).pipe(
        map((res) => { return storeWorkersCompCorrections(res.data); }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    }),
  );
};

const putWorkersCompCorrections$ = (action$: Observable<Actions<UpdateWorkersCompRequest>>) => {
  return action$.pipe(
    ofType(putWorkersCompCorrections.type),
    switchMap((action: { payload: UpdateWorkersCompRequest }) => {
      return from(CorrectionService.putWorkersCompCorrections(action.payload)).pipe(
        map((res) => { return res.data; }),
        mergeMap((res: HttpResponse<WorkersCompCorrection[]>) => {
          return [
            storeWorkersCompCorrections(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    }),
  );
};

const getDeptAllocationEmployees$ = (action$: Observable<Actions<DeptAllocationCorrectionsGetRequest>>) => {
  return action$.pipe(
    ofType(loadDeptAllocationEmployees.type),
    switchMap((_) => {
      return from(CorrectionService.getDeptAllocationEmployees()).pipe(
        map((res) => {
          const { data }: { data: AllocationEmployee[] } = res;
          return storeDeptAllocationEmployees(data);
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    }),
  );
};

const getDeptAllocationCorrections$ = (action$: Observable<Actions<DeptAllocationCorrectionsGetRequest>>) => {
  return action$.pipe(
    ofType(getDeptAllocationCorrections.type),
    switchMap((action: { payload: DeptAllocationCorrectionsGetRequest }) => {
      return from(CorrectionService.getDeptAllocationCorrections(action.payload)).pipe(
        map((res) => { return res.data; }),
        switchMap((res: DeptAllocationDTO) => { return [storeDeptAllocationCorrections(res)]; }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    }),
  );
};

const postDeptAllocationCorrections$ =
  (action$: Observable<Actions<UpdateRequest<DeptAllocationWithOptionalId[]> & {showSuccessMessage: boolean}>>) => {
    return action$.pipe(
      ofType(postDeptAllocationCorrections.type),
      switchMap((action: { payload: UpdateRequest<DeptAllocationWithOptionalId[]> & {showSuccessMessage: boolean} }) => {
        return from(CorrectionService.postDeptAllocationCorrections(action.payload)).pipe(
          map((res) => { return res.data; }),
          mergeMap((res: HttpResponse<DeptAllocation[]> ) => {
            return [
              storeDeptChangeCorrections(res.value),
              (action.payload.showSuccessMessage) ? handleSuccess(res.messages) : handleSuccess(res),
            ];
          }),
          catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
        );
      }),
    );
  };

const postMescEarnCorrection$ =
  (action$: Observable<Actions<UpdateRequest<MescEarnDeptAllocPostRequest> & { mescId: number }>>) => {
    return action$.pipe(
      ofType(postMescEarnCorrection.type),
      switchMap((action: { payload: UpdateRequest<MescEarnDeptAllocPostRequest> & { mescId: number } }) => {
        return from(CorrectionService.postMescEarnCorrection(action.payload)).pipe(
          map((res) => { return res.data; }),
          mergeMap((res: HttpResponse<DeptAllocationDTO>) => {
            return [
              storeDeptAllocationCorrections(res.value),
              handleSuccess(res.messages),
            ];
          }),
          catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
        );
      }),
    );
  };

const deleteMescEarnCorrection$ =
  (action$: Observable<Actions<MescEarnDeptAllocDeleteRequest>>) => {
    return action$.pipe(
      ofType(deleteMescEarnCorrection.type),
      switchMap((action: { payload: MescEarnDeptAllocDeleteRequest }) => {
        return from(CorrectionService.deleteMescEarnCorrection(action.payload)).pipe(
          map((res) => { return res.data; }),
          switchMap((res: HttpResponse<DeptAllocationDTO>) => {
            return [
              storeDeptAllocationCorrections(res.value),
              handleSuccess(res.messages),
            ];
          }),
          catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
        );
      }),
    );
  };

const loadDeptAllocationReport$ = (action$: Observable<Actions<CorrectionsReportRequest>>) => {
  return action$.pipe(
    ofType(loadDeptAllocationReport.type),
    switchMap((action: { payload: CorrectionsReportRequest }) => {
      return from(CorrectionService.generateCorrectionsReport(action.payload)).pipe(
        map((res) => { return res.data; }),
        switchMap((res: HttpResponse<string>) => {
          return [
            storeDeptAllocationReport(res.value),
          ];
        }),
        catchError((err: any) => {
          if (typeof err === 'string' && err?.includes('Sequence contains no elements')) {
            return [handleWarning('No report data found')];
          }
          return [handleError(err)];
        }),
      );
    }),
  );
};


export const epics: Epic[] = [
  getEEOInfoCorrections$,
  putEEOInfoCorrections$,
  getWorkersCompCorrections$,
  putWorkersCompCorrections$,
  getDeptAllocationEmployees$,
  getDeptAllocationCorrections$,
  postDeptAllocationCorrections$,
  postMescEarnCorrection$,
  deleteMescEarnCorrection$,
  loadDeptAllocationReport$,
];