import React, { useEffect } from 'react';
import styles from '../../styles.module.scss';
import { fieldSettings } from './JobDetailConfig';
import { FormMethods } from 'core/components/form-controls/types';
import { SelectGrp, RadioGrp, InputGrp, ControlDatePickerGrp, CheckboxGrpInLine } from 'core/components/form-controls';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';
import { Controller } from 'react-hook-form';
import { useAppSelector } from 'utilities/hooks';
import { useDispatch } from 'react-redux';
import { getTaxingCities } from 'core/store/actions';
import { TaxingCity } from 'core/models/Contractor.model';

const incAllRadioOptions: RadioOptions[] = [
  { value: 'true', label: 'Include all employees' },
  { value: 'false', label: 'Include only union employees' },
];

const groupByOptions: RadioOptions[] = [
  {
    value: 'false',
    label: 'no',
  },
  {
    value: 'true',
    label: 'yes',
  },
];

type Props = {
  addJob: boolean;
  control: FormMethods['control'];
  register: FormMethods['register'];
  setValue: FormMethods['setValue'];
  watch: FormMethods['watch'];
  setSelectedState: React.Dispatch<React.SetStateAction<string>>
  setSelectedTaxingCity: React.Dispatch<React.SetStateAction<TaxingCity | undefined>>
};

const JobDetailsTab = ({ addJob, control, register, setValue, watch, setSelectedState, setSelectedTaxingCity }: Props) => {
  const {
    startDate,
    closeDate,
    jobNo,
    useDavisBaconWage,
    brickLayerCementMasonRate,
    certIncAll,
    state,
  } = watch([
    'startDate',
    'closeDate',
    'jobNo',
    'useDavisBaconWage',
    'brickLayerCementMasonRate',
    'certIncAll',
    'state',
  ]); // how do we type this when it's passed as props?
  
  const stateDropDown = useAppSelector((state) => { return state.dropdown.state; });
  const taxingCityDropDown = useAppSelector(({ contractor }) => contractor.taxingCities);
  
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getTaxingCities());
  }, []);
  
  return (
    <div className={styles['section-flex']}>
      <div className={styles['detail-fields-col']}>
        <div className="d-flex">
          {addJob ? (
            <InputGrp
              {...fieldSettings.jobNo}
              label="Job Number"
              groupClass="mw75 mr-2"
              ref={register()}
            />
          ) : (
            <>
              <InputGrp
                {...fieldSettings.jobNo}
                label="Job Number"
                groupClass="mw75 mr-2"
                value={jobNo}
                disabled
              />
              <input
                name="jobNo"
                ref={register()}
                type="hidden"
              />
            </>
          )
          }
          <InputGrp
            {...fieldSettings.description}
            label="Description"
            groupClass="mw200 mr-2"
            ref={register()}
          />
          <InputGrp
            {...fieldSettings.projectNo}
            groupClass="mw75"
            ref={register()}
          />
        </div>
        <InputGrp
          {...fieldSettings.name}
          groupClass="mw150"
          ref={register()}
        />
        <div className="d-flex">
          <InputGrp
            {...fieldSettings.address1}
            groupClass="mw150 mr-2"
            ref={register()}
          />
          <InputGrp
            {...fieldSettings.address2}
            groupClass="mw150"
            ref={register()}
          />
        </div>
        <div className="d-flex">
          <InputGrp
            {...fieldSettings.city}
            groupClass="mw100 mr-2"
            ref={register()}
          />
          <SelectGrp
            name="state"
            label="State"
            groupClass="mw100 mr-2"
            defaultValue={state}
            options={stateDropDown}
            ref={register}
            onChange={(e: any) => {
              setSelectedState(e.target.value);
            }}
          />
          <div className="d-flex">
            <InputGrp
              {...fieldSettings.zip}
              inputClass="mw50"
              ref={register()}
            />
            <span
              className="mx-1"
              style={{ marginTop: '22px' }}
            >&nbsp;-&nbsp;</span>
            <InputGrp
              {...fieldSettings.zipPlus4}
              groupClass="mw50"
              ref={register()}
            />
          </div>
        </div>
        <div className="d-flex">
          <InputGrp
            {...fieldSettings.county}
            groupClass="mw100 mr-2"
            ref={register()}
          />
          <SelectGrp
            {...fieldSettings.taxCityCode}
            labelField="codeDesc"
            valueField="entityCode"
            groupClass="mw100"
            ref={register}
            options={taxingCityDropDown}
            onChange={(e: any) => {
              const matchingCity = taxingCityDropDown?.find((city) => city.entityCode === e.target.value);
              if (!matchingCity) return console.error('City not found');
              setSelectedTaxingCity(matchingCity);
            }}
          />
        </div>
        <div className="d-flex">
          <ControlDatePickerGrp
            {...fieldSettings.startDate}
            name="startDate" // why do I need to repeat this...?
            groupClass="mw100 mr-2"
            inputClass={styles['date-picker']}
            control={control}
            value={startDate ? new Date(startDate) : null}
          />
          <ControlDatePickerGrp
            {...fieldSettings.closeDate}
            name="closeDate"
            groupClass="mw100"
            inputClass={styles['date-picker']}
            control={control}        
            value={closeDate ? new Date(closeDate) : null}
          />
        </div>
        <InputGrp
          {...fieldSettings.bricklayerCementMasonRate}
          groupClass="mw100 mt-3"
          defaultValue={brickLayerCementMasonRate ?? ''}
          ref={register()}
          setValue={setValue}
        />
      </div>
      <div className="d-flex">
        <div className="d-flex flex-column w-50">
          <div className={styles['radio-grp-wrapper']}>
            <CheckboxGrpInLine
              name="certYN"
              label={'Job is Certified'}
              ref={register()}
            />
          </div>
          <div className={styles['radio-grp-wrapper']}>
            <CheckboxGrpInLine
              name="activeYN"
              label={'Job is Active'}
              ref={register()}
            />
          </div>
          <div className={styles['radio-grp-wrapper']}>
            <CheckboxGrpInLine
              name="excludeFromWC"
              label={'Exlucde job from W/C report'}
              ref={register()}
            />
          </div>
          <div className={`${styles['radio-grp-wrapper']} mt-3`}>
            <CheckboxGrpInLine
              name="showAddressOnCertifiedReport"
              label={'Display Emp. Address on Certified Report'}
              ref={register()}
            />
          </div>
          <div className={`${styles['radio-grp-wrapper']} mt-3`}>
            <CheckboxGrpInLine
              name="isOCIP"
              label={'Job uses owner-controlled insurance program'}
              ref={register()}
            />
          </div>
          <div className={styles['radio-grp-wrapper']}>
            <CheckboxGrpInLine
              name="isSubContractor"
              label={'Is Subcontractor'}
              ref={register()}
            />
          </div>
          <InputGrp
            {...fieldSettings.procoreCostCode}
            groupClass="mw125 ml-2"
            ref={register()}
          />
        </div>
        <div className="d-flex flex-column w-50">
          <div className={styles['radio-grp-wrapper']}>
            <CheckboxGrpInLine
              name="approvedForSpecialUnionRate"
              label={'Approved For Special Union Rate'}
              ref={register()}
            />
          </div>
          <div className={styles['radio-grp-wrapper']}>
            {/* you are ugly and i hate you, thanks */}
            <Controller
              name="certIncAll"
              control={control}
              render={() => (
                <RadioGrp
                  name="certIncAll"
                  label={'Employees to include on Certified Report'}
                  controlled
                  checked={!!certIncAll}
                  onChange={(e: any) => {
                    setValue('certIncAll', e.target.value === 'true');
                  }}
                  radioOptions={incAllRadioOptions}
                />
              )}
            />
          </div>
          <div className={`${styles['radio-grp-wrapper']} mt-3`}>
            <CheckboxGrpInLine
              name="useDavisBaconWage"
              label={'Use Davis-Bacon wage determination (prevailing wage)'}
              ref={register()}
              defaultChecked={!!useDavisBaconWage}
              radioOptions={groupByOptions}
              onChange={(e: any) => {
                if (!e.target.checked && !confirm('Unchecking this option will permanently delete the prevailing wage trade setup for this job. Continue?')) {
                  e.target.checked = true;
                  return;
                }
              }}
            />
          </div>
          <div className={styles['radio-grp-wrapper']}>
            <CheckboxGrpInLine
              name="subjectToExecutiveOrder"
              label={'Job subject to executive order'}
              ref={register()}
            />
          </div>
          <div className={styles['radio-grp-wrapper']}>
            <CheckboxGrpInLine
              name="isNationalJob"
              label={'Is National Job'}
              ref={register()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsTab;