import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, FirstDataRenderedEvent, ModuleRegistry, SelectionChangedEvent } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { useForm } from 'react-hook-form';
import { ControlDatePickerGrp, RadioGrp } from '../../form-controls';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { convToDateISOString, currencyFormatter } from 'utilities/utilities';
import { DateTime } from 'luxon';
import { radioOptions } from 'dropdowns/void';
import {
  clearEmployeeVoidSearch,
  postAdjustmentVoid,
  loadEmployeeVoidSearch,
  showVoidCheckWindow,
  handleError,
} from 'core/store/actions';
import {
  PayrollAdjustmentTransaction,
  PayrollAdjustmentType,
  PayrollAdjustmentVoidTransaction,
  PayrollVoidSearchParams,
} from 'core/models/Payroll.model';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const currentYear = new Date().getFullYear();

type PropTypes = {
  payrollHistoryId: number;
  weekEnd: Date;
  checkDate: Date;
};

const VoidCheck: React.FC<PropTypes> = ({ payrollHistoryId, weekEnd, checkDate }) => {
  const dispatch = useAppDispatch();
  
  const { activeYear } = useAppSelector(({ payroll }) => payroll);
  const calculatedBeginDate = new Date('01/01/' + activeYear);
  const calculatedEndDate = new Date('12/31/' + activeYear);
  
  const { handleSubmit, control, errors } = useForm({
    defaultValues: {
      beginDate: calculatedBeginDate,
      endDate: calculatedEndDate,
    },
  });

  const [rowData, setRowData] = useState<PayrollAdjustmentTransaction[]>([]);
  const [selectionLength, setSelectionLength] = useState<number>(0);
  const [columnDefs] = useState<ColDef[]>([
    {
      checkboxSelection: (params) => {return params.data.adjustmentId === null;},
      width: 80,
    },
    {
      headerName: 'Check Type',
      field: 'checkTypeDescription',
    },
    {
      field: 'empNo',
      width: 120,
    },
    {
      field: 'firstName',
    },
    {
      field: 'lastName',
    },
    {
      field: 'weekEnd',
      valueFormatter: (params) => {return DateTime.fromISO(params.data.weekEnd).toFormat('MM/dd/yyyy');},
    },
    {
      field: 'checkDate',
      valueFormatter: (params) => {return DateTime.fromISO(params.data.checkDate).toFormat('MM/dd/yyyy');},
    },
    {
      field: 'quarter',
      width: 120,
    },
    {
      field: 'checkNo',
    },
    {
      field: 'companyGross',
      cellClass: 'text-right',
      valueFormatter: (params) => {
        return currencyFormatter(params.value);
      },
    },
  ]);

  const [radioButtonValue, setRadioButtonValue] = useState<PayrollAdjustmentType>(PayrollAdjustmentType.VoidCash);
  const payrollAdjustmentTransactions = useAppSelector(({ payroll }) => payroll.employeeVoidSearch);
  const protectedEmpNo = useAppSelector(({ selEmployee }) => selEmployee.employee?.protectedEmpNo);
  const checkCalculation = useAppSelector(({ selEmployeeDetails }) => selEmployeeDetails.checkCalculator.checkCalculation);

  const gridRef = useRef<AgGridReact>(null);

  const onFirstDataRendered = useCallback((e: FirstDataRenderedEvent) => {
    e.api.sizeColumnsToFit();
    e.api.setDomLayout('autoHeight');
  }, []);

  const submitForm = (data: PayrollVoidSearchParams) => {
    if (!protectedEmpNo) return dispatch(handleError('Error with employee number'));
    
    dispatch(
      loadEmployeeVoidSearch({
        protectedEmpNo,
        beginDate: (data.beginDate as Date).toISOString(),
        endDate: (data.endDate as Date).toISOString(),
      }),
    );
  };

  const voidCheck = () => {
    if (!gridRef.current) return;
    if (!protectedEmpNo) return dispatch(handleError('Error with employee number'));
    
    const selectedRows: PayrollAdjustmentTransaction[] = [];
    gridRef.current.api.getSelectedNodes().forEach((rowNode) => {
      selectedRows.push({
        ...(rowNode.data as PayrollAdjustmentTransaction),
        void: true,
        addPrePaid: false,
      });
    });
    const voidTransaction: PayrollAdjustmentVoidTransaction = {
      clientNo: checkCalculation?.clientNo as number,
      adjustmentType: radioButtonValue,
      voidPrepayInfo: {
        clientNo: checkCalculation?.clientNo as number,
        empNo: checkCalculation?.empNo as number,
        weekEnd: convToDateISOString(weekEnd),
        checkDate: convToDateISOString(checkDate),
      },
      transactions: selectedRows,
    };

    dispatch(
      postAdjustmentVoid({
        payrollHistoryId,
        protectedEmpNo,
        params: voidTransaction,
      }),
    );
  };

  const cancelVoid = () => {
    dispatch(showVoidCheckWindow(false));
    dispatch(clearEmployeeVoidSearch());
  };

  useEffect(() => {
    setRowData(payrollAdjustmentTransactions);
  }, [payrollAdjustmentTransactions]);
  return (
    <>
      <form
        onSubmit={handleSubmit(submitForm)}
        className="mb-3"
      >
        <div className="row align-items-top">
          <div className="col-auto">
            <ControlDatePickerGrp
              minDate={calculatedBeginDate}
              maxDate={calculatedEndDate}
              name="beginDate"
              label="Begin Date"
              errors={errors.beginDate}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Begin Date is required',
                },
              }}
            />
          </div>
          <div className="col-auto">
            <ControlDatePickerGrp
              minDate={calculatedBeginDate}
              maxDate={calculatedEndDate}
              name="endDate"
              label="End Date"
              errors={errors.endDate}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'End Date is required',
                },
              }}
            />
          </div>
          <div className="col-auto align-self-center">
            <button className="orange-button-sm btn btn-primary btn-sm mb-2">
              Search
            </button>
          </div>
        </div>
      </form>

      <div>
        <div className="row">
          <div className="col-auto">
            <RadioGrp
              radioOptions={radioOptions}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement>,
              ) => {
                setRadioButtonValue(
                  PayrollAdjustmentType[
                    e.target.value as PayrollAdjustmentType
                  ],
                );
              }}
              name="radioButtonValue"
              defaultChecked={radioButtonValue}
              groupClass={'d-flex flex-column'}
            />
          </div>
          <div className="col-auto mb-3">
            <button
              className="orange-button btn btn-primary btn-sm mr-3"
              type="button"
              disabled={selectionLength === 0}
              onClick={voidCheck}
            >
              Ok
            </button>
            <button
              className="orange-outline-button btn btn-primary btn-sm"
              type="button"
              onClick={cancelVoid}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div style={{ height: '100%' }}>
        <div
          className="ag-theme-balham"
          style={{ height: '100%' }}
        >
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            rowData={rowData}
            onFirstDataRendered={onFirstDataRendered}
            rowSelection="multiple"
            onSelectionChanged={(e: SelectionChangedEvent) => {return setSelectionLength(e.api.getSelectedNodes().length);}
            }
          ></AgGridReact>
        </div>
      </div>
    </>
  );
};

export default VoidCheck;
