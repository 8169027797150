import { cloneDeep } from 'lodash';
import { createSelector } from 'reselect';
import { AodPayrollUploadMap, PayrollUpload, TimeCard } from '../../models/PayrollUpload.model';
import { payrollUpload } from '../reducers';
import { State as PayrollUploadState } from '../reducers/payroll-upload.reducer';

export const getPayrollUploadVM = createSelector(
  payrollUpload,
  (state: PayrollUploadState) => {
    if (!state.payrollUploadVM) return state;
    return state.payrollUploadVM;
  });

export const getPayrollUploadClientFile = createSelector(
  payrollUpload,
  (state: PayrollUploadState) => {
    return cloneDeep(state.selectedClientFile) as PayrollUpload;
  },
);

export const getPayrollUploadFileToolErrors = createSelector(
  payrollUpload,
  (state: PayrollUploadState) => {
    return state.payrollUploadFileToolErrors;
  },
);

export const getOnTheClockData = createSelector(
  payrollUpload,
  (state: PayrollUploadState) => {
    return cloneDeep(state.onTheClockData) as TimeCard[];
  },
);

export const getAodPayrollUploadMap = createSelector(
  payrollUpload,
  (state: PayrollUploadState) => {
    return cloneDeep(state.aodUploadMapOptions) as AodPayrollUploadMap;
  },
);