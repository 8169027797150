import React, { CSSProperties, Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import Icon from './Icon';
import { FormlessCheckboxGrp } from '../form-controls/FormlessCheckboxGrp';

const inputStyles: CSSProperties = {
  maxWidth: '600px',
};

type Props = {
  headerName: string;
  isSearching: boolean;
  isSelectedEmp: boolean;
  searchAll: boolean;
  onSearch: (data: SearchForm) => void;
  setSearchAllClients: Dispatch<SetStateAction<boolean>>
};

export interface SearchForm {
  searchEmp: string;
}

const EmployeeSearchHeader = (props: Props) => {
  const { register, handleSubmit } = useForm();

  return (
    <>
      <div className="row mx-2">
        <div className="col-sm-6 d-flex">
          <div className="dm-page-title">{props.headerName}</div>
        </div>
        <div className="col-sm-6 d-flex justify-content-end align-items-end">
          {(props.isSearching || props.isSelectedEmp) && (
            <form
              onSubmit={handleSubmit(props.onSearch)}
              className="w-100"
            >
              <div
                className="input-group mt-2"
                style={inputStyles}
              >
                <input
                  type="text"
                  className="form-control form-control-group"
                  placeholder="Search by Employee Number, SSN, or Last Name"
                  aria-label="Search by Employee Number, SSN, or Last Name"
                  name="searchEmp"
                  ref={register({ required: true })}
                  required
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    id="button-search"
                    title="Search"
                  >
                    <Icon name="search" />
                  </button>
                </div>
              </div>
              <FormlessCheckboxGrp
                name="searchAllClients"
                label="Search all clients I have access to"
                groupClass="mt-2"
                checked={props.searchAll}
                onChange={(e) => {
                  props.setSearchAllClients(e.target.checked);
                }}
              />
            </form>
          )}
        </div>
      </div>
      <hr className="dm-page-hr mx-4 mb-4 row" />
      <div className="row mx-2">
        <div className="col">
          {!props.isSearching && !props.isSelectedEmp && (
            <div className="alert alert-info">
              <form onSubmit={handleSubmit(props.onSearch)}>
                <h2 className="mb-3">
                  To begin, search employee by:{' '}
                </h2>
                <div className="d-flex flex-column">
                  <div
                    className="input-group mb-3"
                    style={inputStyles}
                  >
                    <input
                      type="text"
                      className="form-control form-control-group"
                      placeholder="Search by Employee Number, SSN, or Last Name"
                      aria-label="Search by Employee Number, SSN, or Last Name"
                      name="searchEmp"
                      ref={register({ required: true })}
                      required
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="submit"
                        title="Search"
                        id="search"
                      >
                        <Icon name="search" />
                      </button>
                    </div>
                  </div>
                  <FormlessCheckboxGrp
                    name="searchAllClients"
                    label="Search all clients I have access to"
                    checked={props.searchAll}
                    onChange={(e) => {
                      props.setSearchAllClients(e.target.checked);
                    }}
                  />
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EmployeeSearchHeader;
