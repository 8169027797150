import { Observable, catchError, forkJoin, map, mergeMap, tap } from 'rxjs';
import { EpicWithRootState, Actions, Epic } from './types';
import { RootState } from '../store';
import { StateObservable, ofType } from 'redux-observable';
import { EmFieldValidation as E } from 'types';
import { EmployeeSelfService, EmployeeService } from 'core/services';
import {
  toggleEmFieldValidationModal,
  storeValidationFromStream,
  triggerEmFieldValidation,
  handleError,
  enableBlockingEmValidate,
  beginValidateEmployee,
  storeMyInfoChanges,
  toggleMyInfoChangesModalBtn,
  toggleChangeEmpStatusModal,
} from '../actions';
import { getKeyByNumAndSection } from 'utilities/utilities';
import { ControlIdBySection, ControlIds } from 'core/constants';
import { State as SelfServiceState } from '../reducers/emp-self-service.reducer';
import { MyInfoChangeLog } from 'core/models/MyInfoChangeLog';

// PI-8595: handle special cases where certain fields should update together.
function handleLinkedFields(key: string, section: E.ValidateSection, validationObj: Partial<Record<E.ValidateSection, E.FieldError[]>>) {
  if (key === 'hireDate') {
    if (section === 'empinfo') {
      validationObj.dates = validationObj.dates?.filter((field) => field.controlId !== ControlIds.hireDate);
      if (!validationObj?.dates?.length) delete validationObj.dates;
    } else if (section === 'dates') {
      validationObj.empinfo = validationObj.empinfo?.filter((field) => field.controlId !== ControlIds.hireDate);
      if (!validationObj?.empinfo?.length) delete validationObj.empinfo;
    }
  }
}

function validateFields(
  section: E.ValidateSection,
  callerPayload: any,
  fieldErrors: E.EmSectionErrors | null,
  ids: (string | number)[] = [],
  idField?: string,
): E.EmSectionErrors | null {
  if (!callerPayload) {
    console.log('Skipping validation. No payload provided');
    return fieldErrors;
  }
  
  const validationObj = structuredClone(fieldErrors);
  if (!validationObj) return null;
  if (!validationObj[section]?.length) return fieldErrors;
  
  // any deleted things (payrate delete just gives the rateId)
  for (const id of ids) {
    const updatedSectionErrors: E.FieldError[] = validationObj[section]
      ?.filter((field) => field?.parentId !== String(id)) ?? []; 
    
    if (!updatedSectionErrors.length) {
      delete validationObj[section]; 
      return validationObj;
    } else {
      validationObj[section] = updatedSectionErrors;
    }
  }
    
  if (typeof callerPayload === 'object' && !(Array.isArray(callerPayload))) {
    for (const [key, value] of Object.entries(callerPayload)) {
      
      const matchingField = validationObj[section]
        ?.find((field) => getKeyByNumAndSection(ControlIdBySection, section, field.controlId) === key);
      if (!matchingField) continue;
      
      // the request object has a new value, so remove it from the validation object      
      if (value !== null && value !== undefined && String(value) !== '0' && String(value).trim()) {
        const updatedSectionErrors: E.FieldError[] = validationObj[section]
          ?.filter((field) => getKeyByNumAndSection(ControlIdBySection, section, field.controlId) !== key) ?? []; // remove field from errors
        
        handleLinkedFields(key, section, validationObj);
        
        if (!updatedSectionErrors.length) {
          delete validationObj[section]; // we've fixed all the errors, so remove this EM section from the errors object
        } else {
          validationObj[section] = updatedSectionErrors; // we still have some left, so just update it
        }
      }
    }
    // technically the type of null is "object", so we don't want to run into that here
  } else if (
    Array.isArray(callerPayload) // it's an array
    && typeof callerPayload?.[0] === 'object' // it's an array of objects
    && !Array.isArray(callerPayload?.[0]) // it's not an array of arrays
    && callerPayload?.[0] !== null // it's not an array of null "objects"
    && idField // we have an ID field to look up. Hooray!
  ) {
    const objIds = callerPayload.map((x) => x[idField]); // the IDs we care about from each array item
    const validationIds = validationObj[section]?.map((x) => x.parentId); // the ones that need to be (re)validated

    if (!(objIds.length && validationIds?.length)) return validationObj;
    
    // for deletes that happen in state and then are saved in a list update rather than calling a single delete endpoint
    for (const id of validationIds) {
      if (!callerPayload.find((x) => String(x[idField]) === id)) {
        const updatedSectionErrors: E.FieldError[] = validationObj[section]?.filter((field) => field?.parentId !== id) ?? []; 
        validationObj[section] = updatedSectionErrors;
      }
    }
    
    if (!validationObj?.[section]?.length) {
      delete validationObj[section];
      return validationObj;
    }
    
    for (const fieldError of validationObj[section]!) { // this will never be undefined at this point but compiler doesn't believe it.
      const payloadMatch = callerPayload.find((x) => String(x[idField]) === fieldError.parentId);
      if (!payloadMatch) continue;
      
      // instead of looping over every key and value of each object, just pull them out like this
      const key = getKeyByNumAndSection(ControlIdBySection, section, fieldError.controlId);
      if (!key) continue;
      
      const value = payloadMatch[key as keyof typeof payloadMatch];
      
      // similar to how it's done above. TODO: split these up into smaller functions
      if (value !== null && value !== undefined && String(value) !== '0') {
        // remove field(s) from errors
        const updatedSectionErrors: E.FieldError[] = validationObj[section]
          ?.filter((field) => field?.parentId !== String(payloadMatch[idField])) ?? []; 
          
        if (!updatedSectionErrors.length) {
          delete validationObj[section]; // we've fixed all the errors, so remove this EM section from the errors object
        } else {
          validationObj[section] = updatedSectionErrors; // we still have some left, so just update it
        }
      }
    }
  }
  
  return Object.keys(validationObj)?.length ? validationObj : null;
}

// uhhh ok you got me, not a lot going on here (but I wanna maintain this pattern just in case)
function handleMyInfo(myInfoChanges: MyInfoChangeLog | null): boolean {
  if (!myInfoChanges) return false;
  
  const { taxChanges, directDepositChange, employeeChanges } = myInfoChanges;
  if (!(taxChanges?.length || employeeChanges?.length || directDepositChange)) return false;
  
  return true;
}

function handleEmFields(payload: E.UpdateValidationRequest, currentErrors: Partial<Record<E.ValidateSection, E.FieldError[]>> | null) {
  // Get the validation object. If calling the validation endpoint it'll be the payload, else grab it from the store and validate it here.
  // Use the payload validation object if given one, else validate the payload and pass the current validation object from the store
  const validateFieldsResult = payload?.validationObj ?? validateFields(
    payload.section,
    payload?.callerPayload,
    currentErrors,
    payload?.ids ?? [],
    payload?.idField,
  );
  
  return validateFieldsResult;
}

/* only fired when we're loading this for the first time. The rest of it will be handled by the trigger stream below 
this until we switch employees. */
export function startEmpValidation$(action$: Observable<Actions<E.EmFieldValidationRequest>>) {
  return action$.pipe(
    ofType(beginValidateEmployee.type),
    mergeMap((action: { payload: E.EmFieldValidationRequest }) => {
      return forkJoin([
        // call these simultaneously
        EmployeeService.validateEmployee(action.payload.protectedEmpNo, action.payload.mandatoryControlIds),
        EmployeeSelfService.getMyInfoChanges(action.payload.protectedEmpNo),
      ]).pipe(
        /* store the MyInfo changes and trigger the other validation. We'll then grab the MyInfo changes from the store
          when triggering validation, and if we have any, we'll skip the fields until there aren't any MyInfo changes 
          left. This way, we only GET each once per employee and use a similar process to block navigation and force 
          user action. This also means we can keep triggerValidation() emitted in all of the other Epics and we don't
          need to pass the MyInfo changes there or add a separate call everywhere else. */
        mergeMap(([emFieldValidation, myInfoChanges]) => {
          return [
            triggerEmFieldValidation({
              section: '_blank_',
              actionType: beginValidateEmployee.type,
              validationObj: emFieldValidation.data.value,
            }),
            storeMyInfoChanges(myInfoChanges.data),
          ];
        }),
        catchError((error) => [handleError(error)]),
      );
    }),
  );
}

/**
 * Validates employee fields
 * @param action$ 
 * @param state$ 
 * @returns action stream
 */
function triggerEmFieldValidation$(action$: Observable<Actions<E.UpdateValidationRequest>>, state$: StateObservable<RootState>) {
  return action$.pipe(
    ofType(triggerEmFieldValidation.type),
    mergeMap(({ payload }) => {
      if (!window.location.pathname.includes('employee/detail')) return [toggleEmFieldValidationModal(false)];
      
      // Get the validation object. If calling the validation endpoint it'll be the payload, else grab it from the store and validate it here.
      const currentValidationObj = state$.value.emFieldValidation?.currentErrors;
      // Use the payload validation object if given one, else validate the payload and pass the current validation object from the store
      const validateFieldsResult = payload?.validationObj ?? handleEmFields(payload, currentValidationObj);
    
      //PI-8664 We will check to see if all required onboarding fields are set if so give them the option to update to Employee status.
      const empRequiredControlIds = [100, 102, 110, 111, 113, 116, 117, 120, 126, 129];
      const empInfoValidation = validateFieldsResult?.empinfo?.find(x => empRequiredControlIds.includes(x.controlId));
      const hasErrors = !!(validateFieldsResult && Object.keys(validateFieldsResult).length);

      //If they do not have any of the required onboarding fields that means they are all filled out and if they are not Employee status they are Onboarding so show the ChangeEmpStatusModal
      if (!empInfoValidation && state$.value.selEmployee.employee?.onboardingStatus !== 'Employee') return [
        toggleChangeEmpStatusModal(!empInfoValidation),
        storeValidationFromStream(hasErrors ? validateFieldsResult : null),
      ];

      if (state$.value.selEmployee.employee?.onboardingStatus !== 'Employee') return [storeValidationFromStream(hasErrors ? validateFieldsResult : null)]; // if they aren't employee yet (e.g., onboarding), don't validate
      
      /* check for any MyInfo changes. If we have some for this employee, enable the link to the modal in the main 
      validation modal. We'll first try to access the caller payload if we're storing the myinfo changes (updating them),
      else just use the one from the store. */
      const myInfoObj: MyInfoChangeLog | null = payload.actionType === storeMyInfoChanges.type
        ? (payload?.callerPayload ?? state$.value.selEmployeeDetails.empSelfService?.myInfoChanges)
        : state$.value.selEmployeeDetails.empSelfService?.myInfoChanges;
      const hasMyInfoChanges = handleMyInfo(myInfoObj);
      
      return [
        toggleMyInfoChangesModalBtn(hasMyInfoChanges),
        toggleEmFieldValidationModal(hasErrors || hasMyInfoChanges),
        enableBlockingEmValidate(hasErrors || hasMyInfoChanges),
        storeValidationFromStream(hasErrors ? validateFieldsResult : null),
      ];
    }),
    catchError((error) => [handleError(error)]),
  );
}

export const epics: (Epic | EpicWithRootState)[] = [
  startEmpValidation$,
  triggerEmFieldValidation$,
];
