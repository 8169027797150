import { NewFeature } from '../../../core/models';

const NewFeatureItem = (props: NewFeature) => {
  return (
    <>
      <div className="dm-dashboard-strong-text">
        <a href={props.uri} target="_blank" rel="noopener noreferrer">
          {props.subject}
        </a>
      </div>
      <div className="dm-dashboard-text mb-2">{props.body}</div>
    </>
  );
};

export default NewFeatureItem;
