import React, { useEffect, useState } from 'react';
import Modal from 'core/components/modals/Modal';
import Table from 'core/components/shared/dm-table/Table';
import { ColumnHeaderData, Row } from 'core/components/shared/dm-table/types';
import { PayrollTimeCardSubInfo, TimeCardDetail } from 'core/models';
import { getAllSubs } from 'core/store/actions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { InputGrp } from 'core/components/form-controls';
import styles from './styles.module.scss';

const buildSubNoRow = (sub: PayrollTimeCardSubInfo): Row => {
  return {
    id: sub.subNo,
    cells: [
      { children: sub.subNo, styleClass: 'td-20' },
      { children: sub.description, styleClass: 'td-80' },
    ],
  };
};

const subNoColumns: ColumnHeaderData[] = [
  { title: 'Sub No', styleClass: 'th-20' },
  { title: 'Description', styleClass: 'th-80' },
];

const matchQuery = (timeCard: PayrollTimeCardSubInfo, _query: string): boolean => {
  return timeCard?.description?.toLowerCase()?.includes(_query.toLowerCase())
         || timeCard?.subNo?.includes(_query);
};

type Props = {
  show: boolean;
  onHide: () => void;
  setControlledField: (property: keyof TimeCardDetail, newValue: any) => void;
};

const SubNoModal = ({ show, onHide, setControlledField }: Props) => {
  const { allSubs } = useAppSelector(({ contractor }) => contractor);
  const [filteredSubInfo, setFilteredSubInfo] = useState<PayrollTimeCardSubInfo[]>(allSubs); 
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    //If the subs are already loaded no reason to load them again
    if (allSubs) return;
    dispatch(getAllSubs());
  }, []);

  const onFilter = (search: string) => {
    const newState = structuredClone(allSubs)?.filter((subInfo: PayrollTimeCardSubInfo) => matchQuery(subInfo, search));
    setFilteredSubInfo(newState);
  };
  
  return (
    <Modal
      title="Change Sub Number"
      show={show}
      onHide={onHide}      
    >
      <div className="d-flex flex-column">
       <div className={styles['job-info-modal-top-container']}>
          <label
            htmlFor="job-info-modal-search"
            className={`mb-3 ${styles['job-info-modal-label']}`}
          >Search</label>
          <InputGrp
            placeholder="Enter sub number or description"
            groupClass="m-0"
            inputClass={`gc50 ml-2 mb-3 ${styles['job-info-modal-input-large']}`}
            name="job-info-modal-search"
            type="text"
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              onFilter(target.value);
            }}
          />
        </div>
        <Table
          columns={subNoColumns}
          rows={filteredSubInfo?.map((sub) => buildSubNoRow(sub))}
          onRowClick={(id) => {
            setControlledField('subNumber', id);
            onHide();
          }}
        />
      </div>
    </Modal>
  );
};

export default SubNoModal;