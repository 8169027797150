import { createSlice } from '@reduxjs/toolkit';
import { Applicant, CustomInterviewEmail, DesignWebsite, Interview, InterviewQuestionsReport, InterviewTemplate, PayrollUser } from 'core/models/ApplicantTracking.model';
import { getApplicant, getApplicantStatus, getApplicants, postApplicantNoteReport, postApplicantNotes, updateApplicantOffer, updateApplicantStatus, getPayrollUsers, getCustomInterviewEmails, deleteApplicationInterview, sendApplicantInterviewEmails, sendApplicantOfferEmail, getInterviewTemplates, createInterviewFromProcess, createApplicantInterviewFromTemplate, updateApplicantInterview, updateApplicantEmail, getInterviewQuestionsReport, getDesignWebsite, putDesignWebsite } from '../actions/applicant-tracking.action';
import { toast } from 'react-toastify';
import { ApplicantStatus } from 'enums/ApplicantStatus';

export interface ApplicantTrackingState {
  loading: boolean;
  isAddingNote: boolean;
  isNoteReportLoading: boolean;
  applicants: Applicant[];
  payrollUsers: PayrollUser[];
  customInterviewEmails: CustomInterviewEmail[];
  active: Applicant | null;
  interviews: Interview[] | null;
  offer: any;
  interviewTemplates: InterviewTemplate[];
  createdInterview: any;
  createdInterviewFromTemplate:any;
  interviewQuestionsReport: InterviewQuestionsReport | null;
  designWebsite: DesignWebsite | null;
}

const initialState: ApplicantTrackingState = {
  loading: false,
  isAddingNote: false,
  isNoteReportLoading: false,
  applicants: [],
  active: null,
  interviews: null,
  offer: null,
  payrollUsers: [],
  customInterviewEmails: [],
  interviewTemplates: [],
  createdInterview: null,
  createdInterviewFromTemplate: null,
  interviewQuestionsReport: null,
  designWebsite: null,
};

export const applicantTrackingSlice = createSlice({
  name: 'applicant-tracking-v2',
  initialState,
  reducers: {
    setApplicant(state, action) {
      state.active = action.payload;
    },
    setOfferValues(state, action) {
      state.offer = {
        ...state.offer,
        ...action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getApplicant.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApplicant.fulfilled, (state, action) => {
        state.loading = false;
        state.active = action.payload ?? state.active;
      })
      .addCase(getApplicant.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(getApplicants.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApplicants.fulfilled, (state, action) => {
        state.loading = false;
        state.applicants = action.payload ?? state.applicants;
      })
      .addCase(getApplicants.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(getPayrollUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPayrollUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.payrollUsers = action.payload ?? state.payrollUsers;
      })
      .addCase(getPayrollUsers.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(getDesignWebsite.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDesignWebsite.fulfilled, (state, action) => {
        state.loading = false;
        state.designWebsite = action.payload ?? state.designWebsite;
      })
      .addCase(getDesignWebsite.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(putDesignWebsite.pending, (state) => {
        state.loading = true;
      })
      .addCase(putDesignWebsite.fulfilled, (state, action) => {
        toast.success(action?.payload?.messages?.[0], { autoClose: 3000 });
        state.loading = false;
      })
      .addCase(putDesignWebsite.rejected, (state, action) => {
        toast.error('An error occurred, please try again later', { autoClose: 3000 });
        state.loading = false;
      });

    builder
      .addCase(deleteApplicationInterview.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteApplicationInterview.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteApplicationInterview.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(sendApplicantInterviewEmails.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendApplicantInterviewEmails.fulfilled, (state, action) => {
        // @ts-ignore
        toast.success(action?.payload?.messages?.[0], { autoClose: 3000 });
        state.loading = false;
      })
      .addCase(sendApplicantInterviewEmails.rejected, (state) => {
        toast.error('An error occurred, please try again later', { autoClose: 3000 });
        state.loading = false;
      });

    builder
      .addCase(sendApplicantOfferEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendApplicantOfferEmail.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendApplicantOfferEmail.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(createInterviewFromProcess.pending, (state) => {
        state.loading = true;
      })
      .addCase(createInterviewFromProcess.fulfilled, (state, action) => {
        state.loading = false;
        state.createdInterview = action.payload;
      })
      .addCase(createInterviewFromProcess.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(createApplicantInterviewFromTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(createApplicantInterviewFromTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.createdInterviewFromTemplate = action.payload?.value;
      })
      .addCase(createApplicantInterviewFromTemplate.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(updateApplicantInterview.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateApplicantInterview.fulfilled, (state, action) => {
        state.loading = false;
        toast.success(action?.payload?.messages?.[0], { autoClose: 3000 });
      })
      .addCase(updateApplicantInterview.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload, { autoClose: 3000 });
      });

    builder
      .addCase(updateApplicantEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateApplicantEmail.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateApplicantEmail.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(getCustomInterviewEmails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomInterviewEmails.fulfilled, (state, action) => {
        state.loading = false;
        state.customInterviewEmails = action.payload ?? state.customInterviewEmails;
      })
      .addCase(getCustomInterviewEmails.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(getInterviewTemplates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInterviewTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.interviewTemplates = action.payload ?? state.interviewTemplates;
      })
      .addCase(getInterviewTemplates.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(getInterviewQuestionsReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInterviewQuestionsReport.fulfilled, (state, action) => {
        state.loading = false;
        state.interviewQuestionsReport = action.payload ?? state.interviewQuestionsReport;
      })
      .addCase(getInterviewQuestionsReport.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(postApplicantNotes.pending, (state) => {
        state.isAddingNote = true;
      })
      .addCase(postApplicantNotes.fulfilled, (state, action: any) => {
        if (!state.active) {
          return;
        }
  
        state.active.notes = action.payload.value;
        state.isAddingNote = false;
      })
      .addCase(postApplicantNotes.rejected, (state) => {
        state.isAddingNote = true;
      });

    builder
      .addCase(postApplicantNoteReport.pending, (state) => {
        state.isNoteReportLoading = true;
      })
      .addCase(postApplicantNoteReport.fulfilled, (state, action: any) => {
        state.isNoteReportLoading = false;
      })
      .addCase(postApplicantNoteReport.rejected, (state) => {
        state.isNoteReportLoading = true;
      });

    builder.addCase(updateApplicantStatus.fulfilled, (state, action: any) => {
      toast.success(action?.payload?.messages?.[0], { autoClose: 3000 });
    });

    builder.addCase(getApplicantStatus.fulfilled, (state, action: any) => {
      if (state?.active?.status === ApplicantStatus.INTERVIEW) {
        state.interviews = action.payload;
      } else if (state?.active?.status === ApplicantStatus.HIRE) {
        state.offer = action.payload;
      }
    });

    builder.addCase(updateApplicantOffer.fulfilled, (state, action: any) => {
      state.offer = action.payload.value;
    });
  },
});

export const {
  setApplicant,
  setOfferValues,
} = applicantTrackingSlice.actions;

export default applicantTrackingSlice.reducer;
