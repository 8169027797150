import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import DateList from './DateList';
import DateDetails from './DateDetails';
import { useAppSelector } from 'utilities/hooks';
import { handleError } from 'core/store/actions';
import { ContractorService } from 'core/services';
import { TimeCardsByDayData } from 'core/models/Contractor.model';

const TimeByDay = () => {
  const { currentTimeCard } = useAppSelector(({ contractor }) => contractor);
  const [selectedDate, setSelectedDate] = useState<TimeCardsByDayData | null>(null);
  const [timeCards, setTimeCards] = useState<TimeCardsByDayData[]>([]);

  const onLoad = async () => {
    const payrollHistoryId = currentTimeCard?.payrollHistoryId;
    if(!payrollHistoryId) return console.log("Payroll History Id is undefined");
    
    try{
      const timeCardByDay = await ContractorService.getTimeCardsByDay(payrollHistoryId);
      setTimeCards(timeCardByDay.data as TimeCardsByDayData[]);
      setSelectedDate(timeCardByDay.data[0] as TimeCardsByDayData);
    }
    catch{
      (err: any) => { return handleError(err)};
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <main className={styles['main-content']}>
      <div className="d-flex justify-content-between align-items-end">
        <h1 className="dm-page-title">Time by Day</h1>
      </div>
      <hr className="dm-page-hr" />
      {selectedDate ? <div className={styles['page-body']}>
        <DateList
          dates={timeCards as TimeCardsByDayData[]}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <DateDetails
          selectedDate={selectedDate}
        />
      </div> : null} 
    </main>
  );
};

export default TimeByDay;