import React from 'react';
import { Employee } from 'core/models';
import { NavLink } from 'react-router-dom';
import SnapshotDirectDepositItem from './SnapshotDirectDepositItem';
import { useSelector } from 'react-redux';
import { getDirectDeposits } from 'core/store/selectors/deduction.selector';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import Icon from 'core/components/shared/Icon';

interface EmployeeDirectDepositProps {
  employee: Employee;
}

const SnapshotDirectDepositPage: React.FC<EmployeeDirectDepositProps> = ({
  employee,
}) => {
  const sectionHeadings = [
    'DEDUCTION',
    'AMOUNT',
    'UNIT',
    'FREQUENCY',
    'ROUTING NUMBER',
    'ACCOUNT NUMBER',
  ];

  const directDeposits = useSelector(getDirectDeposits);

  return !employee ? (
    <div></div>
  ) : (
    <div className="dm-panel dm-panel-border">
      <div className="row">
        <div className="col-10">
          <div className="dm-grid-title">Direct Deposit</div>
        </div>
        <div className="col-2 d-flex justify-content-end my-auto">
          <NavLink to="direct-deposit">
            <Icon
              name="pen"
              className="fa-pen"
            />
          </NavLink>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <hr className="dm-panel-hr" />
        </div>
      </div>
      <TableContainer>
        <Table
          sx={{ minWidth: 400 }}
          size="small"
          aria-label="test"
        >
          <TableHead>
            <TableRow>
              {sectionHeadings.map((heading) => {
                return (
                  <TableCell
                    sx={{
                      fontFamily: "Open Sans, 'sans-serif'",
                      fontSize: '10px',
                      fontWeight: '700',
                      lineHeight: '14px',
                      marginBottom: '0',
                      color: '#717171',
                      paddingLeft: '5px',
                      paddingBottom: '3px',
                      textTransform: 'uppercase',
                      margin: 'auto 0 0',
                    }}
                    key={heading}
                  >
                    {heading}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {directDeposits.map((d, key) => {
              return (
                <SnapshotDirectDepositItem
                  key={key}
                  directDeposit={d}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SnapshotDirectDepositPage;
