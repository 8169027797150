import React from 'react';
import CheckBoxForm from '../../Shared/CheckBoxForm';
import { Col, Row } from 'react-bootstrap';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for Checkbox and Grid select forms
const HrDoctorCompFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  return (
    <Row>
      {!!sectionFields?.length && (
        <Col sm={6}>
          <CheckBoxForm
            formMethods={formMethods}
            dFields={sectionFields.find(f => {
              return f.name === 'Doctor Comp Record';
            })?.fields}
            section="Doctor Comp Record"
            title="Doctor Comp Record"
          />
        </Col>
      )}
      {!!sectionFields?.length && (
        <Col sm={6}>
          <CheckBoxForm
            formMethods={formMethods}
            dFields={sectionFields.find(f => {
              return f.name === 'Doctor Comp';
            })?.fields}
            section="Doctor Comp"
            title="Doctor Comp"
          />
        </Col>
      )}
    </Row>
  );
};

export default HrDoctorCompFields;