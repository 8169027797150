import { RadioGrp } from 'core/components/form-controls';
import InputReportDateSelector from 'core/components/form-controls/InputReportDateSelector';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { DtoOptions, Client } from 'core/models';
import { JobToDateGrossSummaryReportRequest } from 'core/models/ContractorReports.model';
import { CommonService } from 'core/services';
import { clearJobToDateGrossSummaryReport, downloadJobToDateGrossSummaryReport } from 'core/store/slices/contractorReports.slice';
import { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const reportByOptions: RadioOptions[] = [
  {
    value: 'ByWeekEnding',
    label: 'By Week End',
  },
  {
    value: 'ByCheckDate',
    label: 'By Check Date',
  },
];
const JobToDateGrossSummaryReport = () => {


  const dispatch = useAppDispatch();
  const childRef: React.RefObject<any> = useRef();

  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [fileType, setFileType] = useState<string>('pdf');

  const client = useAppSelector((state) => {return state?.client?.client;}) as Client;

  const report = useAppSelector((state) => {return state.contractorReports.jobToDateGrossJobSummary;});

  const { register, handleSubmit, setValue } =
        useForm<JobToDateGrossSummaryReportRequest>({
          defaultValues: {
            reportByOption: 'ByWeekEnding',
          },
        });

  const downloadReport = (data: JobToDateGrossSummaryReportRequest) => {
    const submitData: JobToDateGrossSummaryReportRequest = {
      clientNo: client.clientNo,
      dtoOption: reportDates[0],
      reportByOption: data.reportByOption,
    };
    dispatch(downloadJobToDateGrossSummaryReport(submitData));
  };

  const hidePdfViewer = () => {
    dispatch(clearJobToDateGrossSummaryReport());
  };
  useEffect(() => {
    if (report && fileType === 'excel') {
      report &&
                CommonService.downloadBase64File(
                  'application/excel',
                  report,
                  'JobToDateGrossSummaryReport.xlsx',
                );
      dispatch(clearJobToDateGrossSummaryReport());
    }
  }, [report]);



  const onCancel = () => {
    childRef?.current?.onCancel();
  };
  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">
            Job-To-Date Gross Summary Report
          </div>

          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div>
              <InputReportDateSelector
                ref={childRef}
                returnDates={(dates: DtoOptions[]) => {return setReportDates(dates);}}
              />
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <div className=" text-right">
                <button
                  type="button"
                  className="orange-outline-button mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="orange-button mr-2"
                  onClick={() => {return setFileType('pdf');}}
                  disabled={!reportDates.length}
                >
                  Run Report
                </button>
              </div>

              <div
                className="dm-panel dm-panel-border"
                style={{ width: '20%', marginTop: '66px' }}
              >
                <div className="dm-grid-action-title mb-2">
                  Report Options
                </div>
                <div>
                  <div className="d-flex">
                    <div className="d-flex flex-row flex-grow-1">
                      <div className="d-flex flex-column flex-grow-1">
                        <RadioGrp
                          isVertical={true}
                          radioOptions={reportByOptions}
                          name="reportByOption"
                          onChange={(e: any) => { setValue('reportByOption', e.target.value); }}
                          ref={register}

                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {report ? (
          <PDFViewerModal
            show={report.length > 0}
            pdfData={report}
            onHide={hidePdfViewer}
            reportName="JobToDateGrossSummaryReport"
          />
        ) : null}
      </div>
    </div>
  );
};

export default JobToDateGrossSummaryReport;

