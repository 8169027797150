import Icon from 'core/components/shared/Icon';
import React, { useContext, useEffect, useState } from 'react';
import { Accordion, AccordionContext, Card, useAccordionToggle } from 'react-bootstrap';
import { AccrualBalanceRecord, AccrualEdit } from 'core/models';
import { convToDateString, currencyFormatter } from 'utilities/utilities';
import AccrualBeginBalanceModal from './AccrualBeginBalance.modal';
import AccrualRecordItem from './AccrualRecordItem';

const formatTo4Decimals = (value: number) => parseFloat('' + value).toFixed(4);

function ContextAwareToggle({ children, eventKey, callback }: any) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => { return callback && callback(eventKey); },
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <button
      type="button"
      className="mb-2 w-100"
      style={{ backgroundColor: '#bee7ff' }}
      onClick={decoratedOnClick}
    >
      <div
        className="d-flex flex-wrap align-items-center"
        style={{ height: '40px' }}
      >
        <Icon name={isCurrentEventKey ? 'minus' : 'plus'} />
        {children}
      </div>
    </button>
  );
}

type PropTypes = {
  balRec: AccrualBalanceRecord;
  balRecIds: string[];
  index: number;
};

const AccrualBalanceItem: React.FC<PropTypes> = ({ balRec, balRecIds, index }) => {
  const [show, setShow] = useState(false);
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [selectedItem, setSelectedItem] = useState<AccrualEdit | null>(null);

  const onEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setSelectedItem(new AccrualEdit(
      balRec.clientNo,
      balRec.empNo,
      balRec.protectedEmpNo,
      balRec.balanceId,
      0,
      'beginbalance',
      { amount: currencyFormatter(balRec.beginBalance) },
    ));
    setShow(true);
  };
  
  useEffect(() => {
    setActiveKey(balRecIds?.[0]);
  }, [balRecIds]);

  return (
    <>
      {/* Normally you wouldn't put each item in its own accordion, but react-bootstrap v1.4.0 requires it in order
      to expand multiple rows at once. So... TODO: upgrade react-bootstrap to next major version */}
      <Accordion
        activeKey={activeKey ?? '0'}
        onSelect={(k) => { setActiveKey(k); }}
      >
        <ContextAwareToggle eventKey={String(balRec.balanceId)}>
          <div
            className="font-weight-bold"
            style={{ width: '200px' }}
          >
            Balance as of:&nbsp;&nbsp;{convToDateString(balRec.asOfDate)}</div>
          <div
            className="font-weight-bold"
            style={{ width: '200px' }}
          >
            Begin Balance:&nbsp;&nbsp;{formatTo4Decimals(balRec.beginBalance)}
            <button
              className="btn btn-link p-0 m-0 pl-1"
              onClick={onEdit}
              title='Click here to change the beginning balance.'
            >
              <Icon
                name="pen"
                className="fa-pen"
              />
            </button>
          </div>
          <div
            className="font-weight-bold"
            style={{ width: '150px' }}
          >
            Earned:&nbsp;&nbsp;{formatTo4Decimals(balRec.earnedAmount)}</div>
          <div
            className="font-weight-bold"
            style={{ width: '150px' }}
          >
            Taken:&nbsp;&nbsp;{formatTo4Decimals(balRec.takenAmount)}</div>
          <div
            className="font-weight-bold"
            style={{ width: '150px' }}
          >
            Balance:&nbsp;&nbsp;{formatTo4Decimals(balRec.currentBalance)}</div>
        </ContextAwareToggle>
        <Accordion.Collapse eventKey={String(balRec.balanceId)}>
          <Card.Body className="testclass">
            {balRec?.accrualRecords.length > 0 &&
            <AccrualRecordItem
              accrualRecords={balRec.accrualRecords}
              type={balRec.earningsCode}
            />}
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>
      {show && selectedItem && (
        <AccrualBeginBalanceModal
          item={selectedItem}
          show={show}
          onHide={() => { setShow(false); }}
        />
      )}
    </>
  );
};

export default AccrualBalanceItem;

