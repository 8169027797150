import React, { useState } from 'react';
import { DMDropdownDataModel, DMDropdownItemModel } from './DMDropDown';
import { DMDropdownItem } from './DMDropdownItem';
import { useAppSelector } from 'utilities/hooks';
import { getGroupAccess, getAccess } from 'utilities/utilities';
import { AccessGroup, GroupItem } from 'core/models/ModuleAccess.model';
import { useSelector } from 'react-redux';
import { getClientOptionIsContractor } from 'core/store/selectors';

type LookupArgsTuple = [AccessGroup[] | GroupItem[] | undefined, number | undefined, number | undefined];
type DMDropdownMenuProps = {
  data: DMDropdownDataModel;
  containerStyle?: object;
  toggleClass?: string;
  preIcon?: any;
  stayOpen?: boolean;
  onShowModal?: any;
  onHideModal?: any;
  whiteChevron?: boolean;
  onSelect?: (...args: any[]) => void;
};

export const DMDropdownMenu: React.FC<DMDropdownMenuProps> = ({
  data: { id, title, groupId, items },
  toggleClass,
  containerStyle,
  stayOpen = false,
  onShowModal,
  onHideModal,
  onSelect,
  whiteChevron = false,
}) => {
  const isContractor = useSelector(getClientOptionIsContractor);
  // get groups and employee master security sections
  const [groups, emSections] = useAppSelector(({ app }) => [app.moduleAccess?.groups, app.moduleAccess?.employeeMasterSections]);
  const groupAccess = groups?.find((g) => {return g.groupId === groupId;});
  const { items: accessItems, visible: validAccess = true } = groupAccess || {};
  
  // filter the passed in children
  
  const [isVisible, setIsVisible] = useState(false);

  const show = (shouldShow: boolean) => {
    if (stayOpen) return;
    setIsVisible(shouldShow);
  };
  
  const toggle = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    setIsVisible(!isVisible);
  };

  return (
    <>
      {validAccess ? (
        <div
          className="dm1-dropdown-container user-select-none"
          style={containerStyle}
          id={id}
          onClick={toggle}
          onMouseEnter={() => {return show(true);}}
          onMouseLeave={() => {return show(false);}}
        >
          {whiteChevron ? (
            <div
              className={`dm1-dropdown-toggle-white ${toggleClass} ${isVisible ? 'show' : ''}`}
            >
              {title}
            </div>
          ) : (
            <div
              className={`dm1-dropdown-toggle ${toggleClass} ${isVisible ? 'show' : ''}`}
            >
              {title}
            </div>
          )}
          <div className={`dm1-dropdown ${isVisible ? 'd-block' : 'd-none'}`}>
            {items?.map((item: DMDropdownItemModel) => {
              const lookupArgs: LookupArgsTuple = item?.itemGroupId ? [groups, undefined, item.itemGroupId] : [accessItems, item?.itemId, undefined];
              const itemAccess = getGroupAccess(...lookupArgs);
              const itemData = item.href && item.href !== '' ? item : { ...item, href: '#' };
          
              if ((itemAccess && !itemAccess.visible) || (!isContractor && item?.isContractorOnly)) return;
              
              // PI-8694: Check if there's an EM section associated with this item. This section's visibility will dictate the visibility of this menu item too.
              if (item?.workItemId) {
                const emSectionVisible = emSections?.find((x) => x.workItemId === item.workItemId)?.visible ?? true; // default to visible if not defined
                if (!emSectionVisible) return;
              }
              
              // all of the subItems of this menu group are hidden, so don't render the menu group to begin with.
              if (itemData?.subItems && !itemData.subItems.filter((x) => emSections?.find((y) => x.workItemId === y.workItemId)?.visible ?? true).length) return;

              return (
                <DMDropdownItem
                  key={item.id}
                  data={itemData}
                  modal={item.modal}
                  onShowModal={onShowModal}
                  onHideModal={onHideModal}
                  onSelect={onSelect}
                />
              );
            })}
          </div>
        </div>

      ) : null
      }
    </>
  );
};
