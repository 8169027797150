import { createReducer } from '@reduxjs/toolkit';
import { DeductionsShortage, EmployeeChangeReport, EmployeeChangeReportField } from '../../models';
import { EmployeeMasterReport } from '../../models/EmployeeMaster.model';
import {
  clearEmployeeDeductionsShortageReport,
  storeEmployeeDeductionsShortageReport,
  storeEmployeeChangeReport,
  clearEmployeeChangeReport,
  storeEmployeeChangeReportFields,
  clearEmployeeChangeReportFields,
  setEmployeeChangeReportError,
  storeEmployeeMasterReport,
  clearEmployeeMasterReport,
  storePayCatgegoryHistoryReport,
  clearPayCategoryHIstoryReport,
  getEmployeeChangeReportFields,
  postEmployeeChangeReport,
  postEmployeeMasterReport,
  errorEmployeeChangeReport,
} from '../actions';


export interface State {
  loading: boolean;
  loadingChangeReport: boolean;
  loadingChangeReportFields: boolean;
  deductionsShortageReport: DeductionsShortage | null;
  employeeMasterReport: EmployeeMasterReport | null;
  employeeChangeReport: EmployeeChangeReport | null;
  employeeChangeReportFields: EmployeeChangeReportField[] | null;
  employeeChangeReportError: boolean;
  payCategoryHistoryReport: string;
}

export const INITIAL_STATE: State = {
  loading: false,
  loadingChangeReport: false,
  loadingChangeReportFields: false,
  deductionsShortageReport: null,
  employeeMasterReport: null,
  employeeChangeReport: null,
  employeeChangeReportFields: null,
  employeeChangeReportError: false,
  payCategoryHistoryReport: '',
};


export const reducer = createReducer(
  INITIAL_STATE,
  (builder) => {
    builder.addCase(storeEmployeeDeductionsShortageReport, (state, action) => {
      state.deductionsShortageReport = action.payload;
    })
      .addCase(clearEmployeeDeductionsShortageReport, (state) => {
        state.deductionsShortageReport = null;
      })
      .addCase(postEmployeeMasterReport, (state) => {
        state.loading = true;
      })
      .addCase(storeEmployeeMasterReport, (state, action) => {
        state.loading = false;
        state.employeeMasterReport = action.payload;
      })
      .addCase(clearEmployeeMasterReport, (state) => {
        state.employeeMasterReport = null;
      })
      .addCase(postEmployeeChangeReport, (state) => {
        state.loading = true;
        state.loadingChangeReport = true;
      })
      .addCase(storeEmployeeChangeReport, (state, action) => {
        state.loading = false;
        state.loadingChangeReport = false;
        state.employeeChangeReport = action.payload;
      })
      .addCase(errorEmployeeChangeReport, (state, action) => {
        state.loading = false;
        state.loadingChangeReport = false;
      })
      .addCase(clearEmployeeChangeReport, (state) => {
        state.employeeChangeReport = null;
        state.employeeChangeReportError = false;
      })
      .addCase(getEmployeeChangeReportFields, (state) => {
        state.loading = true;
        state.loadingChangeReportFields = true;
      })
      .addCase(storeEmployeeChangeReportFields, (state, action) => {
        state.loading = false;
        state.loadingChangeReportFields = false;
        state.employeeChangeReportFields = action.payload ? action.payload : null;
      })
      .addCase(clearEmployeeChangeReportFields, (state) => {
        state.employeeChangeReportFields = null;
        state.employeeChangeReportError = false;
      })
      .addCase(setEmployeeChangeReportError, (state) => {
        state.loading = false;
        state.loadingChangeReportFields = false;
        state.employeeChangeReportFields = [];
        state.employeeChangeReportError = true;
      })
      .addCase(storePayCatgegoryHistoryReport, (state, action) => {
        state.payCategoryHistoryReport = action.payload;
      })
      .addCase(clearPayCategoryHIstoryReport, (state) => {
        state.payCategoryHistoryReport = '';
      });
  },
);


