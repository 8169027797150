import { createReducer } from '@reduxjs/toolkit';
import {
  Payroll,
  PayrollOptions,
  PayrollControlTotal,
  PayrollMessage,
  TransmittalEmployee,
  PayrollHistoryDeductionStatus,
  PayrollAdjustmentTransaction,
  PayrollPreviewPdf,
  PayrollTimeOffRequests,
  DtoOptions,
  clientRecurringEarning,
  CanRecoverPayrollResponse,
  TransmittalCheck,
  AdjustmentStateInfo,
  TransmittalFuturePayRates,
  TransmittalCheckErrors,
  TransmittalSortOrder,
  PayrollPreviewResult,
  WireOnlyAgreement,
} from 'core/models';
import { PayrollControlTotalDetail } from '../../models/PayrollControlTotalDetail';
import {
  clearPrintedCheck,
  clearTransmittalAutofillMessages,
  loadPayroll,
  loadPayrollControlTotals,
  loadPayrollHistoryDeductionStatuses,
  loadPayrollMessages,
  loadTransmittalEmployee,
  loadTransmittalEmployees,
  storePayroll,
  storePayrollControlTotal,
  storePayrollControlTotals,
  updateControlTotalOnPayrollInReduxStore,
  storePayrollControlTotalDetail,
  storePayrollHistoryDeductionStatuses,
  storePayrollMessage,
  storePayrollMessages,
  storePayrolls,
  storePrintedCheck,
  storeTransmittalAutofillMessages,
  storeTransmittalEmployee,
  storeTransmittalEmployees,
  deletePayrollFromState,
  loadPayrollTransmittalReport,
  loadPayrollUnpaidEmployeesReport,
  storePayrollReport,
  clearPayrollReport,
  loadPayrollOptions,
  storePayrollOptions,
  storeTransmittalEmployeeCheck,
  selectedEmployeeModal,
  loadEmployeeVoidSearch,
  storeEmployeeVoidSearch,
  clearEmployeeVoidSearch,
  showVoidCheckWindow,
  storePayrollPreviewPdf,
  clearPayrollPreviewPdf,
  storePayrollReportDates,
  storePayrollType,
  postPayrollValidate,
  storePayrollValidate,
  loadPayrollTimeOffRequests,
  storePayrollTimeOffRequests,
  postPayrollCheckRegister,
  storePayrollCheckRegister,
  clearPayrollCheckRegister,
  postPendingAccrualReport,
  postApprovedAccrualReport,
  storePendingAccrualReport,
  storeApprovedAccrualReport,
  loadPayrollControlTotal,
  storeUserPDFEmailMessage,
  storeSelectedDates,
  clearPayrolls,
  clearTransmittalEmployees,
  loadClientRecurringEarnings,
  storeClientRecurringEarnings,
  putPayrollDateline,
  storePayrollDateline,
  canRecoverPayroll,
  storeCanRecoverPayroll,
  storePrevTransmittalLink,
  deleteTransmittalEmployeeCheck,
  clearTransmittalEmployee,
  clearTransmittalCheckFromStore,
  setPreviewTab,
  updateTransmittalEmployeeCheck,
  resetCheckIndex,
  storeCheckInState,
  updateCurrentCTIds,
  addBook,
  storeBook,
  loadAdjustmentStateInfo,
  storeAdjustmentStateInfo,
  storeLatestPayroll,
  loadPayrollPayRateValidate,
  storePayrollPayRateValidate,
  putPayrollPayRateValidate,
  storeActiveYear,
  clearPayrollTimeOffRequest,
  toggleTransmittalEmpLoadingState,
  storePayrollsForTimeOffRequestModal,
  toggleAccrualLoadingState,
  updateCurrentTransmittalPage,
  sortTransmittalBy,
  toggleWireOnlyAgreementLoadingState,
  loadWireOnlyAgreement,
  storeWireOnlyAgreement,
  clearWireOnlyAgreement,
  toggleWireOnlyAgreementPreviewSuccess,
  toggleWireOnlyAgreementSubmissionSuccess,
  storeWireOnlyAgreementReport,
  clearWireOnlyAgreementReport,
  storePayrollHasSignedWireOnlyAgreement,
  toggleSavingCheck,
  storeWeekBeginningDates,
  updatePayrollMessage,
  updatePayrollMessageOrder,
  createPayrollMessage,
  deletePayrollMessage,
  toggleSavingFromModal,
} from '../actions';
import DateObject from 'react-date-object';

export interface State {
  loading: boolean;
  loadingTimeOffRequests: boolean;
  loadingWireOnlyAgreement: boolean;
  timeOffLoaded: boolean;
  updatingControlTotal: boolean;
  transmittalEmpsLoading: 'loading' | 'success' | 'error';
  validating: boolean;
  hasDatelines: boolean;
  payroll: Payroll[];
  payrollsTimeOffDialog: Payroll[];
  payrollPreview: PayrollOptions | null;
  payrollControlTotals: PayrollControlTotal[];
  payrollControlTotal: PayrollControlTotal | null;
  payrollMessages: PayrollMessage[];
  autoFillMessages: string[];
  printedCheck: any;
  transmittalEmployees: TransmittalEmployee[];
  employeeShadowState: TransmittalEmployee[]; // this will help with delete bug PI-8948
  transmittalEmployee: TransmittalEmployee | null;
  payrollControlTotalDetails: PayrollControlTotalDetail[];
  payrollHistoryDeductionStatuses: PayrollHistoryDeductionStatus[];
  report: string;
  selectedEmployeeNo: number;
  selectedCheckIndex: number;
  employeeVoidSearch: PayrollAdjustmentTransaction[];
  showVoidModal: boolean | null;
  payrollPreviewResult: PayrollPreviewResult | null;
  payrollValidate: any | null;
  payrollTimeOffRequests: PayrollTimeOffRequests | null;
  payrollPayRateValidation: TransmittalFuturePayRates[] | [];
  payrollReportDates: DtoOptions[];
  payrollSelectedDates: DateObject[];
  payrollType: string;
  payrollHasSignedWireOnlyAgreement: boolean;
  payrollCheckRegister: PayrollPreviewPdf[];
  isPending: boolean;
  pendingAccrualReport: any;
  approvedAccrualReport: any;
  userPdfEmailMessage: string | null;
  clientRecurringEarnings: clientRecurringEarning[];
  updatedPayrollDateline: Payroll | null;
  canRecoverPayroll: CanRecoverPayrollResponse | null;
  prevTransmittalLink: { path: string; empNo: number; } | null;
  newCheckId: number;
  newCheckIndex: number;
  mostRecentlyUpdatedCheck: TransmittalCheck | null;
  previewTab: string | null;
  savingCheck: boolean;
  newTransmittalEmpNo: number | null;
  currentControlTotalIds: { payrollHistoryId: number, controlTotalId: number } | null;
  adjustmentStateInfo: AdjustmentStateInfo[];
  latestPayrollId: number | null;
  activeYear: number;
  hasCheckError: boolean | null;
  currentTransmittalPage: number;
  transmittalSortOrder: TransmittalSortOrder | null;
  wireOnlyAgreement: WireOnlyAgreement | null;
  wireOnlyAgreementPreviewSuccess: boolean;
  wireOnlyAgreementSubmissionSuccess: boolean;
  wireOnlyAgreementReport: string;
  loadingControlTotal: boolean;
  savingMessage: boolean;
  savingFromModal: boolean;
}

export const INITIAL_STATE: State = {
  loading: false,
  loadingTimeOffRequests: true,
  loadingWireOnlyAgreement: true,
  timeOffLoaded: false,
  updatingControlTotal: false,
  transmittalEmpsLoading: 'loading',
  validating: false,
  hasDatelines: false,
  payroll: [],
  payrollsTimeOffDialog: [],
  payrollPreview: null,
  payrollControlTotals: [],
  payrollControlTotal: null,
  payrollMessages: [],
  printedCheck: null,
  autoFillMessages: [],
  transmittalEmployees: [],
  employeeShadowState: [],
  transmittalEmployee: null,
  payrollControlTotalDetails: [],
  payrollHistoryDeductionStatuses: [],
  report: '',
  selectedEmployeeNo: 0,
  selectedCheckIndex: 0,
  employeeVoidSearch: [],
  showVoidModal: null,
  payrollPreviewResult: null,
  payrollReportDates: [],
  payrollSelectedDates: [],
  payrollValidate: null,
  payrollTimeOffRequests: null,
  payrollType: '',
  payrollHasSignedWireOnlyAgreement: false,
  payrollCheckRegister: [],
  isPending: false,
  pendingAccrualReport: null,
  approvedAccrualReport: null,
  userPdfEmailMessage: null,
  clientRecurringEarnings: [],
  updatedPayrollDateline: null,
  canRecoverPayroll: null,
  prevTransmittalLink: null,
  newCheckId: -1,
  newCheckIndex: -1,
  mostRecentlyUpdatedCheck: null,
  previewTab: null,
  savingCheck: false,
  newTransmittalEmpNo: null,
  currentControlTotalIds: null,
  adjustmentStateInfo: [],
  latestPayrollId: null,
  payrollPayRateValidation: [],
  activeYear: new Date().getFullYear(),
  hasCheckError: null,
  currentTransmittalPage: 1,
  transmittalSortOrder: null,
  wireOnlyAgreement: null,
  wireOnlyAgreementPreviewSuccess: false,
  wireOnlyAgreementSubmissionSuccess: false,
  wireOnlyAgreementReport: '',
  loadingControlTotal: false,
  savingMessage: false,
  savingFromModal: false,
};

const sortTransmittalEmployees = (employees: TransmittalEmployee[], orderBy: TransmittalSortOrder): TransmittalEmployee[] => {
  switch (orderBy) {
    case 'Alphabetically':
      employees.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.lastName));
      break;
    case 'Employee No':
      employees.sort((a, b) => a.empNo - b.empNo);
      break;
    case 'Loc Dept Alfa':
      // a logical OR here (like done above in abc sort) will sort by 1st condition, THEN by 2nd, etc.
      employees.sort((a, b) =>
        (a?.loc ?? -99) - (b?.loc ?? -99)
        || (a?.dept ?? -99) - (b?.dept ?? -99)
        || a.lastName.localeCompare(b.lastName)
        || a.firstName.localeCompare(b.firstName),
      );
      break;
    case 'Department':
      employees.sort((a, b) => (a?.dept ?? -99) - (b?.dept ?? -99));
      break;
    case 'Sub-Department':
      employees.sort((a, b) => (a?.subDept ?? -99) - (b?.subDept ?? -99));
      break;
    case 'Sub-Department 2':
      employees.sort((a, b) => (a?.subDept2 ?? -99) - (b?.subDept2 ?? -99));
      break;
      // similar to the backend, we just default to last name (which is just alphabetical sort)
    default:
      employees.sort((a, b) => a.lastName.localeCompare(b.lastName));
  }
  
  return employees;
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadPayroll, (state) => {
      state.loading = true;
    })
    .addCase(storePayroll, (state, action) => {
      state.loading = false;
      state.hasDatelines = true;
      state.payroll.splice(0, 0, action.payload);
      state.latestPayrollId = action.payload.payrollHistoryId;
    })
    .addCase(storePayrolls, (state, action) => {
      state.loading = false;
      state.hasDatelines = (action.payload?.length) ? true : false;
      if (!action.payload?.length) state.transmittalEmpsLoading = 'success';
      state.payroll = action.payload?.sort(
        (a, b) => {
          return new Date(b.checkDate).getTime() -
            new Date(a.checkDate).getTime() &&
            new Date(b.weekEnd).getTime() -
            new Date(a.weekEnd).getTime();
        },
      );
    })
    .addCase(storePayrollsForTimeOffRequestModal, (state, action) => {
      state.loading = false;
      state.payrollsTimeOffDialog = action.payload?.sort(
        (a, b) => {
          return new Date(b.checkDate).getTime() -
            new Date(a.checkDate).getTime() &&
            new Date(b.weekEnd).getTime() -
            new Date(a.weekEnd).getTime();
        },
      );
    })
    .addCase(updateControlTotalOnPayrollInReduxStore, (state, action) => {
      state.latestPayrollId = action.payload.payrollHistoryId;
      
      const updatedControlTotal = action.payload;
      const payrolls = state.payroll;
      const matchingPayroll = payrolls.find(x => { return x.payrollHistoryId === updatedControlTotal.payrollHistoryId; });
      if (!matchingPayroll) return state;

      const matchingControlTotal = matchingPayroll.controlTotals.find(x => { return x.controlTotalId === updatedControlTotal.controlTotalId; });
      if (!matchingControlTotal) return state;

      matchingControlTotal.payControlTotal = updatedControlTotal.payControlTotal;
    })
    .addCase(clearPayrolls, (state) => {
      state.loading = false;
      state.payroll = [];
    })
    .addCase(deletePayrollFromState, (state, action) => {
      state.loading = false;
      state.hasDatelines = state.payroll?.filter((w) => { return w.payrollHistoryId !== action.payload; }).length ? true : false;
      state.payroll = state.payroll?.filter(
        (w) => { return w.payrollHistoryId !== action.payload; },
      );
    })
    .addCase(storeSelectedDates, (state, action) => {
      state.loading = false;
      state.payrollSelectedDates = action.payload;
    })
    .addCase(storePayrollReportDates, (state, action) => {
      state.loading = false;
      const dates = action.payload?.map(p => {
        return {
          payrollHistoryId: p.payrollHistoryId,
          checkDate: p.checkDate,
          weekEnd: p.weekEnd,
          status: p.status,
        };
      });

      state.payrollReportDates = dates.sort(
        (a, b) => {
          return new Date(a.weekEnd).getTime() -
            new Date(b.weekEnd).getTime();
        });
    })
    .addCase(loadPayrollOptions, (state) => {
      state.loading = true;
    })
    .addCase(storePayrollOptions, (state, action) => {
      state.loading = false;
      state.payrollPreview = action.payload;
    })
    .addCase(storeWeekBeginningDates, (state, action) => {
      state.loading = false;
      if (state.payrollPreview) {
        state.payrollPreview.weekBeginDates = action.payload.weekBeginDates;
        state.payrollPreview.singleWeekBeginDate = action.payload.singleWeekBeginDate;
      }
    })
    .addCase(loadPayrollControlTotals, (state) => {
      state.loading = true;
    })
    .addCase(loadPayrollControlTotal, (state, action) => {
      state.latestPayrollId = action.payload.payrollHistoryId;
      state.updatingControlTotal = true;
      state.loading = true;
      state.loadingControlTotal = true;
    })
    .addCase(storePayrollControlTotal, (state, action) => {
      state.loading = false;
      state.updatingControlTotal = false;
      state.loadingControlTotal = false;
      state.payrollControlTotal = action.payload.controlTotals[0];
      state.latestPayrollId = action.payload.payrollHistoryId;
      
      if (action.payload.preventPayrollsUpdate) return;
      
      const updatedControlTotal = action.payload.controlTotals?.[0];
      if (!updatedControlTotal) return console.error('Could not find updated control total');
      
      const matchingPayroll = state.payroll.find(x => x.payrollHistoryId === updatedControlTotal.payrollHistoryId);
      if (!matchingPayroll) return state;

      const matchingControlTotal = matchingPayroll.controlTotals.find(x => x.controlTotalId === updatedControlTotal.controlTotalId);
      if (!matchingControlTotal) return state;
      
      const ctIndex = matchingPayroll.controlTotals.findIndex((controlTotal) => controlTotal.controlTotalId === updatedControlTotal.controlTotalId);
      const prIndex = state.payroll.findIndex((payroll) => payroll.payrollHistoryId === matchingPayroll.payrollHistoryId);

      matchingPayroll.controlTotals.splice(ctIndex, 1, updatedControlTotal);
      state.payroll.splice(prIndex, 1, matchingPayroll);
    })
    .addCase(storePayrollControlTotals, (state, action) => {
      state.loading = false;
      state.payrollControlTotals = action.payload;
      state.latestPayrollId = action.payload[0].payrollHistoryId;
    })
    .addCase(loadPayrollMessages, (state) => {
      state.loading = true;
    })
    .addCase(updatePayrollMessage, (state) => {
      state.savingMessage = true;
    })
    .addCase(updatePayrollMessageOrder, (state) => {
      state.savingMessage = true;
    })
    .addCase(createPayrollMessage, (state) => {
      state.savingMessage = true;
    })
    .addCase(deletePayrollMessage, (state) => {
      state.savingMessage = true;
    })
    .addCase(storePayrollMessage, (state, action) => {
      state.loading = false;
      state.savingMessage = false;
      state.payrollMessages = [
        ...state.payrollMessages.filter(
          (a) => { return a.payrollMessageId !== action.payload.payrollMessageId; },
        ),
        action.payload,
      ];
    })
    .addCase(storePayrollMessages, (state, action) => {
      state.loading = false;
      state.savingMessage = false;
      state.payrollMessages = action.payload;
    })
    .addCase(storePrintedCheck, (state, action) => {
      state.loading = false;
      state.printedCheck = action.payload;
    })
    .addCase(clearPrintedCheck, (state) => {
      state.printedCheck = null;
    })
    .addCase(storeTransmittalAutofillMessages, (state, action) => {
      state.loading = false;
      state.autoFillMessages = action.payload;
    })
    .addCase(clearTransmittalAutofillMessages, (state) => {
      state.loading = false;
      state.autoFillMessages = [];
    })
    .addCase(toggleSavingFromModal, (state, action) => {
      state.savingFromModal = action.payload;
    })
    .addCase(loadTransmittalEmployee, (state) => {
      state.loading = true;
    })
    .addCase(storeTransmittalEmployee, (state, action) => {
      const { employee, orderBy } = action.payload;
      
      state.loading = false;
      const existingEmps = state.transmittalEmployees?.map(({ empNo }) => empNo);
      if (!existingEmps.includes(employee.empNo)) state.newTransmittalEmpNo = employee.empNo;
      state.transmittalEmployee = employee;
      // pass a clone of transmittal employees since sort transforms the original array in place
      // hate that we have to use the old fashioned JSON deep clone, but structuredClone won't cut it (yet) because this state is a Proxy object
      state.transmittalEmployees = sortTransmittalEmployees(JSON.parse(JSON.stringify([...state.transmittalEmployees, employee])), state?.transmittalSortOrder ?? orderBy);
      state.employeeShadowState = sortTransmittalEmployees(JSON.parse(JSON.stringify([...state.employeeShadowState, employee])), state?.transmittalSortOrder ?? orderBy);
      state.transmittalSortOrder = orderBy;
    })
    .addCase(clearTransmittalEmployee, (state) => {
      state.newTransmittalEmpNo = null;
    })
    .addCase(loadTransmittalEmployees, (state) => {
      state.loading = true;
      state.transmittalEmpsLoading = 'loading';
    })
    .addCase(storeTransmittalEmployees, (state, action) => {
      const { employees, orderBy } = action.payload;
      
      const empToUpdate = employees?.find((emp) => emp.empNo === state.transmittalEmployee?.empNo);
      if (empToUpdate) state.transmittalEmployee = empToUpdate;
      
      state.transmittalEmployees = sortTransmittalEmployees(employees, state?.transmittalSortOrder ?? orderBy);
      state.employeeShadowState = sortTransmittalEmployees(employees, state?.transmittalSortOrder ?? orderBy);
      
      state.loading = false;
      state.transmittalEmpsLoading = 'success';
    })
    .addCase(clearTransmittalEmployees, (state) => {
      state.transmittalEmployees = [];
      state.employeeShadowState = [];
      state.hasCheckError = null;
    })
    .addCase(deleteTransmittalEmployeeCheck, (state, action) => {
      // TODO: We could really just handle this with the storeTransmittalEmployeeCheck action
      const employee = state.transmittalEmployee && state.transmittalEmployee.protectedEmpNo === action.payload.protectedEmpNo ? state.transmittalEmployee : state.transmittalEmployees.find(
        (d) => { return d.protectedEmpNo === action.payload.protectedEmpNo; },
      );
      if (!employee) return;
      
      const filteredChecks = [...employee.checks].filter((check) => {
        return check?.transmittalCheckId !== action.payload.transmittalCheckId && (check?.transmittalCheckId ?? 0) > 0;
      });
      const updatedEmp: TransmittalEmployee = { ...employee,
        checks: filteredChecks };
      const empIndex = state.transmittalEmployees.findIndex((employee) => {
        return employee.protectedEmpNo === action.payload.protectedEmpNo;
      });
      
      state.loading = false;
      state.transmittalEmployee = updatedEmp;
      
      if (!filteredChecks?.length) {
        state.employeeShadowState.splice(empIndex, 1);
        state.transmittalEmployees = state.employeeShadowState;
        state.newCheckIndex = -1;
      } else {
        state.employeeShadowState.splice(empIndex, 1, updatedEmp);
        state.transmittalEmployees = state.employeeShadowState;
        state.newCheckIndex = filteredChecks.length - 1;
      }
    })
    .addCase(toggleSavingCheck, (state, action) => {
      state.savingCheck = action.payload;
    })
    .addCase(storeCheckInState, (state, action) => {
      state.mostRecentlyUpdatedCheck = action.payload;
    })
    .addCase(storeTransmittalEmployeeCheck, (state, action) => {
      state.loading = false;
      state.savingCheck = false;

      //If there is no check data return 
      if (!action.payload.check) return console.log('No check data found');
      
      if (action.payload.fromModal) {
        state.savingFromModal = true;
      } else {
        state.savingFromModal = false;
      }

      const employee = state.transmittalEmployee && state.transmittalEmployee.protectedEmpNo === action.payload.protectedEmpNo
        ? state.transmittalEmployee
        : state.transmittalEmployees.find((d) => d.protectedEmpNo === action.payload.protectedEmpNo);
      if (!employee) return console.error('Could not find employee when adding/updating check');
      
      let checks = [...employee.checks];
      
      if (!(action.payload.blankCheck)) {
        state.mostRecentlyUpdatedCheck = action.payload.check;
        
        const checkIndex = checks.findIndex((check) => check.transmittalCheckId === action.payload.check.transmittalCheckId || 0);
          
        if (checkIndex > -1) {
          checks.splice(checkIndex, 1, action.payload.check);
          state.newCheckIndex = checkIndex;
        } else {
          // currently best way to handle blank checks. Maybe use a tempId and add/delete property from model/object before sending?
          checks = [...checks.filter((check) => check.transmittalCheckId !== 0), action.payload.check];
          state.newCheckIndex = checks.length - 1;
        }
      }

      //Sets if there is a check error. This is used for the balance button.
      state.hasCheckError = action.payload?.hasCheckError || false;
      
      if (action.payload.blankCheck) {
        state.mostRecentlyUpdatedCheck = action.payload.check; 
        checks.push(action.payload.check); 
        state.newCheckIndex = checks.length - 1;
      }

      if (action.payload.currentTab || action.payload.currentTab === 0) {
        state.newCheckIndex = action.payload.currentTab;
      }
      
      const empIndex = state.transmittalEmployees.findIndex((employee) => {
        return employee.protectedEmpNo === action.payload.protectedEmpNo;
      });
      const updatedEmp = {
        ...employee,
        checks,
      };
      
      state.transmittalEmployee = updatedEmp;
      
      if (empIndex > -1) {
        state.employeeShadowState.splice(empIndex, 1, updatedEmp);
      } else {
        state.employeeShadowState = [
          ...state.employeeShadowState.filter(
            (tm: TransmittalEmployee) => { return tm.protectedEmpNo !== action.payload.protectedEmpNo; },
          ),
          updatedEmp,
        ];
      }
      
      // so we don't refresh the list for each check update; only if they're adding a new check or updating from pay details
      if (!(action.payload.blankCheck || action.payload.fromModal)) return;
      //PI-8568 Updated to set the transmittalEmployees state to the employeeShadowState so the transmittal employee matches all the changes that were made before. 
      state.transmittalEmployees = state.employeeShadowState;
      // state.savingFromModal = true;
    })
    .addCase(clearTransmittalCheckFromStore, (state) => {
      state.mostRecentlyUpdatedCheck = null;
    })
    .addCase(storePayrollControlTotalDetail, (state, action) => {
      state.loading = false;
      state.payrollControlTotalDetails = action.payload;
    })
    .addCase(loadPayrollHistoryDeductionStatuses, (state) => {
      state.loading = true;
    })
    .addCase(storePayrollHistoryDeductionStatuses, (state, action) => {
      state.loading = false;
      state.payrollHistoryDeductionStatuses = action.payload;
    })
    .addCase(loadPayrollTransmittalReport, (state) => {
      state.loading = true;
    })
    .addCase(loadPayrollUnpaidEmployeesReport, (state) => {
      state.loading = true;
    })
    .addCase(storePayrollReport, (state, action) => {
      state.loading = false;
      state.report = action.payload;
    })
    .addCase(clearPayrollReport, (state) => {
      state.report = '';
    })
    .addCase(selectedEmployeeModal, (state, action) => {
      state.selectedEmployeeNo = action.payload.empNo;
      state.selectedCheckIndex = action.payload.checkIndex;
      state.newCheckIndex = action.payload.checkIndex;
    })
    .addCase(loadEmployeeVoidSearch, (state) => {
      state.loading = true;
    })
    .addCase(storeEmployeeVoidSearch, (state, action) => {
      state.loading = false;
      state.employeeVoidSearch = action.payload;
    })
    .addCase(clearEmployeeVoidSearch, (state) => {
      state.employeeVoidSearch = [];
      state.showVoidModal = null;
    })
    .addCase(showVoidCheckWindow, (state, action) => {
      state.showVoidModal = action.payload;
    })
    .addCase(storePayrollPreviewPdf, (state, action) => {
      state.payrollPreviewResult = action.payload;
    })
    .addCase(clearPayrollPreviewPdf, (state) => {
      state.payrollPreviewResult = null;
    })
    .addCase(postPayrollValidate, (state) => {
      state.loading = true;
      state.validating = true;
    })
    .addCase(storePayrollValidate, (state, action) => {
      state.loading = false;
      state.validating = false;
      state.payrollValidate = action.payload;
    })
    .addCase(loadPayrollPayRateValidate, (state) => {
      state.loading = true;
    })
    .addCase(putPayrollPayRateValidate, (state) => {
      state.loading = false;
    })
    .addCase(storePayrollPayRateValidate, (state, action) => {
      state.payrollPayRateValidation = action.payload;
    })
    .addCase(loadPayrollTimeOffRequests, (state) => {
      state.loadingTimeOffRequests = true;
    })
    .addCase(storePayrollTimeOffRequests, (state, action) => {
      state.payrollTimeOffRequests = action.payload;
      state.loadingTimeOffRequests = false;
    })
    .addCase(clearPayrollTimeOffRequest, (state) => {
      state.payrollTimeOffRequests = null;
    })
    .addCase(storeUserPDFEmailMessage, (state, action) => {
      state.userPdfEmailMessage = action.payload;
    })
    .addCase(storePayrollType, (state, action) => {
      state.payrollType = action.payload.tab;
    })
    .addCase(storePayrollHasSignedWireOnlyAgreement, (state, action) => {
      state.payrollHasSignedWireOnlyAgreement = action.payload;
    })
    .addCase(postPayrollCheckRegister, (state) => {
      state.loading = true;
    })
    .addCase(storePayrollCheckRegister, (state, action) => {
      state.loading = false;
      state.payrollCheckRegister = action.payload;
    })
    .addCase(clearPayrollCheckRegister, (state) => {
      state.payrollCheckRegister = [];
    })
    .addCase(postPendingAccrualReport, (state) => {
      state.loading = true;
      state.isPending = true;
    })
    .addCase(storePendingAccrualReport, (state, action) => {
      state.loading = false;
      state.pendingAccrualReport = action.payload;
    })
    .addCase(postApprovedAccrualReport, (state) => {
      state.loading = true;
      state.isPending = false;
    })
    .addCase(storeApprovedAccrualReport, (state, action) => {
      state.loading = false;
      state.approvedAccrualReport = action.payload;
    })
    .addCase(loadClientRecurringEarnings, (state) => {
      state.loading = true;
    })
    .addCase(storeClientRecurringEarnings, (state, action) => {
      state.loading = false;
      state.clientRecurringEarnings = action.payload;
    })
    .addCase(putPayrollDateline, (state) => {
      state.loading = true;
    })
    .addCase(storePayrollDateline, (state, action) => {
      const index = state.payroll.findIndex((payroll) => {
        return payroll.payrollHistoryId === action.payload.payrollHistoryId;
      });
      
      state.loading = false;
      state.updatedPayrollDateline = action.payload;
      state.payroll.splice(index, 1, action.payload);
      state.latestPayrollId = action.payload.payrollHistoryId;
    })
    .addCase(canRecoverPayroll, (state) => {
      state.loading = true;
    })
    .addCase(storeCanRecoverPayroll, (state, action) => {
      state.loading = false;
      state.canRecoverPayroll = action.payload;
    })
    .addCase(storePrevTransmittalLink, (state, action) => {
      state.prevTransmittalLink = action.payload;
    })
    .addCase(setPreviewTab, (state, action) => {
      state.previewTab = action.payload;
    })
    .addCase(resetCheckIndex, (state) => {
      state.newCheckIndex = -1;
    })
    .addCase(updateCurrentCTIds, (state, action) => {
      state.currentControlTotalIds = action.payload;
    })
    .addCase(addBook, (state) => {
      state.loading = true;
    })
    .addCase(storeBook, (state, action) => {
      const updateIndex = state.payroll.findIndex(({ payrollHistoryId }) => payrollHistoryId === action.payload.payrollHistoryId);
      if (updateIndex === -1) return console.error('Could not find payroll to update');
      
      state.payroll.splice(updateIndex, 1, action.payload);
      state.loading = false;
    })
    .addCase(loadAdjustmentStateInfo, (state) => {
      state.loading = true;
    })
    .addCase(storeAdjustmentStateInfo, (state, action) => {
      state.loading = false;
      state.adjustmentStateInfo = action.payload;
    })
    .addCase(storeLatestPayroll, (state, action) => {
      state.latestPayrollId = action.payload;
    })
    .addCase(storeActiveYear, (state, action) => {
      state.activeYear = action.payload;
    })
    .addCase(toggleTransmittalEmpLoadingState, (state, action) => {
      state.transmittalEmpsLoading = action.payload;
    })
    .addCase(toggleAccrualLoadingState, (state, action) => {
      state.loadingTimeOffRequests = action.payload;
    })
    .addCase(updateCurrentTransmittalPage, (state, action) => {
      state.currentTransmittalPage = action.payload;
      state.transmittalEmployees = state.employeeShadowState;
    })
    .addCase(sortTransmittalBy, (state, action) => {
      if (action?.payload) {
        state.transmittalEmployees = sortTransmittalEmployees(state.transmittalEmployees, action.payload);
        state.employeeShadowState = sortTransmittalEmployees(state.employeeShadowState, action.payload);
      }
      state.transmittalSortOrder = action.payload;
    })
    .addCase(toggleWireOnlyAgreementLoadingState, (state, action) => {
      state.loadingWireOnlyAgreement = action.payload;
    })
    .addCase(loadWireOnlyAgreement, (state) => {
      state.loadingWireOnlyAgreement = true;
    })
    .addCase(storeWireOnlyAgreement, (state, action) => {
      state.wireOnlyAgreement = action.payload;
      state.loadingWireOnlyAgreement = false;
    })
    .addCase(clearWireOnlyAgreement, (state) => {
      state.wireOnlyAgreement = null;
    })
    .addCase(toggleWireOnlyAgreementPreviewSuccess, (state, action) => {
      state.wireOnlyAgreementPreviewSuccess = action.payload;
    })
    .addCase(toggleWireOnlyAgreementSubmissionSuccess, (state, action) => {
      state.wireOnlyAgreementSubmissionSuccess = action.payload;
    })
    .addCase(storeWireOnlyAgreementReport, (state, action) => {
      state.wireOnlyAgreementReport = action.payload;
    })
    .addCase(clearWireOnlyAgreementReport, (state) => {
      state.wireOnlyAgreementReport = '';
    });
});

export const getPayrollReport = (state: State) => {
  return state.report;
};
