import { createReducer } from '@reduxjs/toolkit';
import { Client, ClientMasterDtoTaxEntitiesFIPS, ClientOptions, K401Options, Option } from '../../models';
import {
  load401KOptions,
  loadIsNOVAClient,
  setShowTermClientModal,
  store401KOptions,
  storeClient,
  storeClientDeductions,
  storeClientEnumsOptions,
  storeClientOption,
  storeClientOptions,
  storeClientTaxEntityFips,
  storeIsNOVAClient,
} from '../actions';
import { ClientDeduction } from 'core/models/ClientDeduction.model';

export interface ClientState {
  loading: boolean;
  isNovaClient: boolean;
  client: Client | null;
  clientOptions: ClientOptions;
  enumOptions: Option[];
  year: number;
  k401Options: K401Options | null;
  clientDeductions: ClientDeduction[];
  clientTaxEntityFips: ClientMasterDtoTaxEntitiesFIPS[]
  showTermClientModal: boolean;
}

const INITIAL_STATE: ClientState = {
  isNovaClient: false,
  loading: false,
  client: null,
  clientOptions: {} as ClientOptions,
  enumOptions: [] as Option[],
  year: 2020,
  k401Options: null,
  clientDeductions: [] as ClientDeduction[],
  clientTaxEntityFips: [] as ClientMasterDtoTaxEntitiesFIPS[],
  showTermClientModal: false,
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(storeClient, (state, action) => {
      state.loading = false;
      state.client = action.payload;
      state.year = 2020;
      state.showTermClientModal = new Date(action.payload?.termDate ?? new Date('01/01/1900')).getFullYear() > 1900;
    })
    .addCase(storeClientOptions, (state, action) => {
      state.loading = false;
      state.clientOptions = action.payload;
    })
    .addCase(storeClientOption, (state, action) => {
      state.loading = false;
      state.clientOptions.options[action.payload.optionId] =
                action.payload;
    })
    .addCase(storeClientEnumsOptions, (state, action) => {
      state.loading = false;
      state.enumOptions = action.payload;
    })
    .addCase(load401KOptions, (state) => {
      state.loading = true;
    })
    .addCase(store401KOptions, (state, action) => {
      state.loading = false;
      state.k401Options = action.payload;
    })
    .addCase(storeClientDeductions, (state, action) => {
      state.loading = false;
      state.clientDeductions = action.payload;
    })
    .addCase(storeClientTaxEntityFips, (state, action) => {
      state.loading = false;
      state.clientTaxEntityFips = action.payload;
    })
    .addCase(loadIsNOVAClient, (state) => {
      state.loading = true;
    })
    .addCase(storeIsNOVAClient, (state, action) => {
      state.loading = false;
      state.isNovaClient = action.payload;
    })
    .addCase(setShowTermClientModal, (state, action) => {
      state.showTermClientModal = action.payload;
    });
});
