/* eslint-disable @typescript-eslint/lines-between-class-members */
import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class PayRate {
  clientNo = 0;
  empNo = 0;
  protectedEmpNo = '';
  rateId = 0;
  hourlyRate = 0;
  salaryRate = 0;
  annualRate = 0;
  tranDigit = 0;
  location = 0;
  department = -1;
  subDepartment = -1;
  subDepartment2 = -1;
  description = '';
  notes = '';
  modifyDate: Date | string | null = null;
  payPeriod = '';
  clock = -1;
  area: string | null = null;
  trade: string | null = null;
  sub: string | null = null;
  overScale: number | null = null;
  hasRateOnOpenTransmittal = false;

  static readonly convProps: PropTypeLists = {
    dateProps: ['modifyDate'],
    numberProps: ['clientNo', 'empNo', 'rateId', 'tranDigit', 'location', 'department', 'subDepartment',
      'subDepartment2', 'clock'],
    currencyProps: ['hourlyRate', 'salaryRate', 'annualRate'],
    currencyPrecisionMap: { 'hourlyRate': 6 },
    boolProps: ['hasRateOnOpenTransmittal'],
  };

  constructor(
    clientNo: number | string,
    empNo: number | string,
    protectedEmpNo: string,
    rateId: number | string,
    props?: { [key: string]: any }) {

    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;
    this.rateId = (typeof rateId === 'string') ? parseInt(rateId) : rateId;

    props && convToClass(this, props, PayRate.convProps);
  }
}

export type EmployeePayRateScreen  = {
  empId: number;
  clientNo: number;
  empNo: number;
  protectedEmpNo: string;
  payPeriod: string;
  payRates: EmployeePayRate[];
}

export type EmployeePayRate =  {
  rateId: number;
  empId: number;
  clientNo: number;
  empNo: number;
  protectedEmpNo: string;
  hourlyRate: number;
  salaryRate: number;
  annualRate: number;
  tranDigit: number;
  location: number;
  department: number;
  subDepartment: number;
  subDepartment2: number;
  description: string;
  notes: string;
  effectiveDate: Date | null;
  area: string | null;
  trade: string | null;
  sub: string | null;
  overScale: number | null;
  createDate: Date;
  modifyDate: Date;
  clock: number;
  hasRateOnOpenTransmittal: boolean; //TODO: ADD THIS TO BACK END

  //These are properties I use to set the form so we have the unrounded values on each of the rates
  hourlyRateNotRounded: number;
  salaryRateNotRounded: number;
  annualRateNotRounded: number;
}

export type DeleteEmployeePayRateRequest  = {
  rateId: number;
  protectedEmpNo: string;
}