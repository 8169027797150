import React, { CSSProperties, DependencyList, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { NavLink, Route, Switch, useHistory, useParams } from 'react-router-dom';
import chevronRight from 'assets/images/chevron-right-nav.png';
import { useSelector } from 'react-redux';
import { loadEmployee, storeCurrentEmpNo, toggleBlockNavigation, toggleChangeEmpStatusModal, toggleEmFieldValidationModal,  updateEmployeePhoto, beginValidateEmployee, updateEmployeeStatus, loadOnboardingStepsStatus } from 'core/store/actions';
import { getMyInfoChanges, getNovatimeGroup5,  getNovatimeGroup6,  getNovatimeGroup7,  getNovatimeGroup8,  getNovatimeShifts,  getPayPolicy,   getSelectedEmp, getYesNoCMOption } from 'core/store/selectors';
import { getEmployees, getFilteredEmployees } from 'core/store/selectors/employees.selector';
import AddEmpPage from '../add-emp/AddEmpPage';
import EmpPhotoUpload from '../add-emp/information/EmpPhotoUpload';
import ACAPage from './aca/ACAPage';
import AccrualsPage from './accruals/AccrualsPage';
import AttendanceOnDemandPage from './attendance-on-demand/AttendanceOnDemandPage';
import CheckCalculatorPage from './check-calculator/CheckCalculatorPage';
import ClientRateRulesPage from './client-rate-rules/ClientRateRulesPage';
import CompanyBenefitsPage from './company-benefits/CompanyBenefitsPage';
import ContractorOptionsPage from './contractor-options/ContractorOptionsPage';
import DatesPage from './dates/DatesPage';
import EmpDeductionsPage from './deductions/EmpDeductionsPage';
import DeferredCompPage from './deferred-comp/DeferredCompPage';
import DirectDepositPage from './direct-deposit/DirectDepositPage';
import EarningsRecordsPage from './earnings-records/EarningsRecordsPage';
import ForeignAddressPage from './foreign-address/ForeignAddressPage';
import GroupTermLifePage from './group-term-life/GroupTermLifePage';
import HRDoctorCompPage from './hr-doctor-comp/HRDoctorCompPage';
import HRFtePage from './hr-fte/HRFtePage';
import PayRatePage from './pay-rate/PayRatePage';
import PayrollInfoPage from './payroll-info/PayrollInfoPage';
import RealignSupervisedEmployeesPage from './realign-supervised-employees/RealignSupervisedEmployeesPage';
import RecurringEarningsPage from './recurring-earnings/RecurringEarningsPage';
import SelfServicePage from './self-service/SelfServicePage';
import SnapshotPage from './snapshot/SnapshotPage';
import SpecialInfoPage from './special-info/SpecialInfoPage';
import TaxPage from './tax/TaxPage';
import VisaStatusPage from './visa-status/VisaStatusPage';
import YTDPage from './YTD/YTDPage';
import { storeEmpNos } from 'core/store/actions/employee-information.action';
import EmergencyContactPage from '../hr-profile/employee-status/EmergencyContactPage';
import Icon from 'core/components/shared/Icon';
import { IconTypes } from 'enums/IconTypes';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import EmployeeDeleteConfirmationModal from 'features/employee-master/EmployeeDeleteConfirmation.modal';
import EmFieldValidationModal from './EmFieldValidation.modal';
import PageSpinner from 'core/components/shared/PageSpinner';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import OnboardingProgressModal from './onboarding-progress/OnboardingProgress.modal';
import AlertModal from 'core/components/modals/alert-modal.modal';
import Modal from 'core/components/modals/Modal';
import { getAccess } from 'utilities/utilities';
import { ControlIds } from 'core/constants';

interface PropTypes {
  location: any;
}

type CustomPromptProps = {
  shouldBlock: boolean;
  message: string;
  dependencies?: DependencyList;
};

type BlockNavProps = {
  shouldBlock: boolean;
  handleBlock?: (newVal: boolean, newPath: string[]) => void;
  whiteList?: string[];
  dependencies?: DependencyList;
};

const pronounStyle: CSSProperties = { fontSize: 12 };
const nickNameStyle: CSSProperties = { fontStyle: 'italic' };
const nickNameWrapperStyle: CSSProperties = { fontSize: 12, color: '#00558c' };
const nickNameMargin: CSSProperties = { marginTop: -8 };
const hrStyle: CSSProperties = { marginTop: -2 };
const fontSize12: CSSProperties = { fontSize: 12 };
const empNoStyle: CSSProperties = { fontSize: 12, marginTop: -12 };
const spinnerStyle: CSSProperties = { display: 'block', marginLeft: 'auto', marginRight: 'auto' };
const changeStatusMessage = 'All required fields have been provided for this onboarding employee:\n Would you like to change their status from "Onboarding" to "Employee"?';

const EmpDetailPage: React.FC<PropTypes> = ({ location }) => {
  const [empPicData, setEmpPicData] = useState<any>('');
  const [empHireDate, setEmpHireDate] = useState<string>('');
  const [showHRButton, setShowHRButton] = useState<string | undefined>();
  const [showTransmittalButton, setShowTransmittalButton] = useState<string | undefined>();
  const [showTtimeCardButton, setShowTtimeCardButton] = useState<string | undefined>();
  const [showConfirmations, setShowConfirmations] = useState(false);
  const [empAge, setEmpAge] = useState<number>(999);
  const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false);
  const [path, setPath] = useState<string[]>([]);
  const [showIncompleteOnboardingSteps, setShowIncompleteOnboardingSteps] = useState<boolean>(false);
  
  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();

  const history = useHistory();
  
  const dispatch = useAppDispatch();
  
  const employee = useSelector(getSelectedEmp);
  const { currentEmpNo, blockForValidation, loadingEmp } = useAppSelector(({ selEmployee }) => selEmployee);
  const allEmployees = useSelector(getEmployees);
  const filteredEmployees = useSelector(getFilteredEmployees);    
  const prevTransmittalLink = useAppSelector((state) => { return state?.payroll?.prevTransmittalLink; });
  const prevHRLink = useAppSelector((state) => { return state?.employees?.prevHRLink; });
  const onboardingStepsStatusErrors = useAppSelector((state) => { return state?.onboard?.onboardingStepsStatus?.errors; });
  const sectionAccess = useAppSelector(({ app }) => app?.moduleAccess?.employeeMasterSections);
  const { prevTimeCardLink } = useAppSelector(({ contractor }) => contractor);
  const canDeleteEmployee = useSelector(getYesNoCMOption(1428));
  const {
    showEmFieldValidationModal,
    showChangeEmpStatusModal,
    currentErrors,
  } = useAppSelector(({ emFieldValidation }) => emFieldValidation);
  const emSecuritySections = useAppSelector((state) => state.app.moduleAccess?.employeeMasterSections) ?? [];
  // check this on the frontend (need to filter out the "<no " options)
  const isNovaClient = useAppSelector(({ client }) => client?.isNovaClient);
  const novatimeGroup5Opts = useSelector(getNovatimeGroup5)?.filter((x) => !!x.id);
  const novatimeGroup6Opts = useSelector(getNovatimeGroup6)?.filter((x) => !!x.id);
  const novatimeGroup7Opts = useSelector(getNovatimeGroup7)?.filter((x) => !!x.id);
  const novatimeGroup8Opts = useSelector(getNovatimeGroup8)?.filter((x) => !!x.id);
  const novatimeShiftOpts = useSelector(getNovatimeShifts)?.filter((x) => !!x.id);
  const payPolicyOpts = useSelector(getPayPolicy)?.filter((x) => !!x.id);
  const { blockNavigation } = useAppSelector((state) => state.app);
  const myInfoChanges = useSelector(getMyInfoChanges);
  
  const hireDateFieldAccess = getAccess(sectionAccess, 1, ControlIds.hireDate);

  const hasMyInfoChanges =
    myInfoChanges?.employeeChanges?.length
    || myInfoChanges?.taxChanges?.length
    || myInfoChanges?.directDepositChange;
  
  const mandatoryControlIds = emSecuritySections
    .flatMap((x) =>
      x.fields
        .filter(y => y.mandatory)
        .map(z => z.controlId))
    .filter(x => {
      const ignoreIds: number[] = [];
      
      if (!isNovaClient) {
        ignoreIds.push(179, 180, 181, 182, 183, 184);
        return !ignoreIds.includes(x);
      }
      if (!novatimeGroup5Opts?.length) ignoreIds.push(179);
      if (!novatimeGroup6Opts?.length) ignoreIds.push(180);
      if (!novatimeGroup7Opts?.length) ignoreIds.push(181);
      if (!novatimeGroup8Opts?.length) ignoreIds.push(182);
      if (!novatimeShiftOpts?.length) ignoreIds.push(183);
      if (!payPolicyOpts?.length) ignoreIds.push(184);
      
      return !ignoreIds.includes(x);
    });
  
  const currentEmpNos = allEmployees.map((x) => ({ protectedEmpNo: x.protectedEmpNo, empNo: x.empNo }));
  
  const lastEmpNoRef = useRef<number>(currentEmpNo ?? 0);
  
  const toggleShowConfirm = (newVal: boolean, newPath: string[]) => {
    setShowNotificationModal(newVal);
    setPath(newPath);
  };
  
  const unblock = (shouldBlock?: boolean) => history.block((_location, _action) => {
    if (shouldBlock) {
      toggleShowConfirm(true, _location.pathname.split('/'));
      return false;
    }
    
    unblock();
    
    return;
  });
  
  const unblockValidation = (shouldBlock?: boolean, handleBlock?: (newVal: boolean, newPath: string[]) => void, whiteList?: string[]) => history.block((_location, _action) => {
    if (!(whiteList?.length && whiteList.find((x) => _location.pathname.includes(x)))) {
      if (shouldBlock) {
        handleBlock?.(true, _location.pathname.split('/'));
        return false;
      }
      unblockValidation();
    }
    
    return;
  });
  
  const handleBlock = (newVal: boolean, newPath: string[]) => {
    if (newVal && !blockForValidation) {
      toggleShowConfirm(true, newPath);
      return;
    }
    dispatch(toggleEmFieldValidationModal(true)); 
  };
  
  const onConfirm = (confirmed: boolean, _path?: string[]) => {
    if (!confirmed) return;
    
    unblock();
    
    dispatch(toggleBlockNavigation({ block: false, message: undefined }));
    setShowNotificationModal(false);
   
    if (_path) {
      history.push(_path.join('/'));
      if (_path.includes('detail')) dispatch(loadEmployee(_path[3]));
    }
  };
  
  const NavBlock = ({ shouldBlock, handleBlock, whiteList, dependencies = [] }: BlockNavProps) => {
    useEffect(() => {
      unblockValidation(shouldBlock, handleBlock, whiteList);
      
      return () => {
        unblockValidation();
      };
    }, [shouldBlock, history, ...dependencies]);

    return null;
  };

  useEffect(() => {
    if (prevHRLink) {
      setShowHRButton(prevHRLink);
    }
    if (prevTransmittalLink) {
      setShowTransmittalButton(prevTransmittalLink.path);
    }
    if (prevTimeCardLink) {
      setShowTtimeCardButton(prevTimeCardLink);
    }
  }, [location]);
  
  useEffect(() => {
    // Adding a new employee - don't need to fetch it than.
    if (protectedEmpNo === '0') return;
    // TODO: figure out how this'll work coming from the transmittal, new tasks, etc.
    if (!employee || (currentEmpNos.find((x) => x.empNo === currentEmpNo))) {
      dispatch(loadEmployee(protectedEmpNo));
    }
  }, [!!protectedEmpNo]);

  useEffect(() => {
    if (!employee) return;
    setEmpPicData(employee?.employeePhoto);
    setEmpAge(Math.abs((new Date().getFullYear()) - (new Date(employee?.birthDate ?? new Date()).getFullYear())));
    
    dispatch(storeCurrentEmpNo(employee?.empNo ?? -1));
    if (!employee.hireDate) return;
    dispatch(storeEmpNos([employee?.empNo]));
    setEmpHireDate((employee?.hireDate as Date).toLocaleDateString());    
  }, [employee]);
  
  useEffect(() => {
    // I hate to handle this ONLY in here and not the next and previous emp handlers, but this will work for now.
    if (!(protectedEmpNo && currentEmpNo && currentEmpNo !== lastEmpNoRef.current)) return;
    //PI-8667 Load it here so if there are no steps needed to complete we dont show the button
    dispatch(loadOnboardingStepsStatus(protectedEmpNo));
    dispatch(beginValidateEmployee({
      protectedEmpNo,
      mandatoryControlIds,
    }));
    lastEmpNoRef.current = currentEmpNo;
  }, [currentEmpNo]);

  const updateEmpPhoto = (photoData: any) => {
    dispatch(updateEmployeePhoto({
      empNo: protectedEmpNo,
      photoData,
    }));
  };
  
  const onPrevEmp = () => {
    // TODO: this could probably just be handled in the middleware somewhere
    const definedEmps = (filteredEmployees?.length && !prevTransmittalLink) ? filteredEmployees : allEmployees;
    
    if (blockForValidation) return dispatch(toggleEmFieldValidationModal(true));
    if (!definedEmps?.length) return console.error('Error: no employees');
    if (!employee) return;
    
    const index = definedEmps.findIndex((e) => { return e.empNo === employee.empNo; });
    const _path = location.pathname.split('/');
    
    if (!(index > 0)) {
      if (!confirm('You have reached the beginning of the list. Continue to last employee?')) return;
      _path[3] = definedEmps[definedEmps.length - 1].protectedEmpNo;
    } else {
      _path[3] = definedEmps[index - 1].protectedEmpNo;
    }
    
    if (blockNavigation.block) {
      toggleShowConfirm(true, _path);
    } else {
      history.push(_path.join('/'));
      dispatch(loadEmployee(_path[3]));
    }
  };

  const onNextEmp = () => {
    const definedEmps = (filteredEmployees?.length && !prevTransmittalLink) ? filteredEmployees : allEmployees;
    
    if (blockForValidation) return dispatch(toggleEmFieldValidationModal(true));
    if (!definedEmps?.length) return console.error('Error: no employees');
    if (!employee) return;
    
    const index = definedEmps.findIndex((e) => { return e.empNo === employee.empNo; });
    const _path = location.pathname.split('/');
    
    if (!(index >= 0 && index < definedEmps.length - 1)) {
      if (!confirm('You have reached the end of the list. Continue to first employee?')) return;
      _path[3] = definedEmps[0].protectedEmpNo;
    } else {
      _path[3] = definedEmps[index + 1].protectedEmpNo;
    }
    
    if (blockNavigation.block) {
      toggleShowConfirm(true, _path);
    } else {
      history.push(_path.join('/'));
      dispatch(loadEmployee(_path[3]));
    }
  };

  const onHideChangeEmpStatusModal = () => {
    dispatch(toggleChangeEmpStatusModal(false));
  };

  const onConfirmChangeEmpStatusModal = (confirmed: boolean) => {
    if (confirmed) dispatch(updateEmployeeStatus({ protectedEmpNo: protectedEmpNo }));
  };
  
  const getNotificationModal = () => {
    switch (blockNavigation.type) {
      case 'notice':
        return (
          <AlertModal
            title="Notice"
            show={showNotificationModal && blockNavigation.block && !!blockNavigation?.message}
            onHide={() => { toggleShowConfirm(false, []); }}
            message={blockNavigation.message}
          />
        );
      case 'directDeposit': 
        return (
          <Modal
            show={showNotificationModal && blockNavigation.block && !!blockNavigation?.message}
            onHide={() => { toggleShowConfirm(false, []); }}
            title="Notice"
          >
            <div className="d-flex flex-column">
              <div>            
                Please edit direct deposit items so that &quot;% of NET&quot; deduction amounts sum to 100%. 
              </div>
              <div className="my-2">
                You may either:
                <ul className="m-0">
                  <li>Add new direct deposit items that make up this difference</li>
                  <li>Modify this employee&apos;s existing direct deposit items</li>
                </ul>
              </div>
              <strong>This must be done before saving.</strong>
            </div>
            <div className="w-100 d-flex justify-content-end">
              <button
                className="btn orange-button"
                onClick={() => { toggleShowConfirm(false, []); }}
              >
                Okay
              </button>
            </div>
          </Modal>
        );
      case 'confirmation': 
      default:
        return (
          <ConfirmationModal
            title="You have unsaved changes"
            show={showNotificationModal && blockNavigation.block && !!blockNavigation?.message}
            message={blockNavigation.message}
            onConfirmed={onConfirm}
            onHide={() => { toggleShowConfirm(false, []); }}
            path={path}
          /> 
        );
    }
  };

  return (
    <div
      className="d-flex flex-wrap p-2"
      style={{
        height: '250px',
        width: '100vw',
      }}
    >
      {/* block navigation anywhere but within this employee master while errors present */}
      <NavBlock
        shouldBlock={blockForValidation || blockNavigation.block}
        handleBlock={handleBlock}
        dependencies={[]}
        whiteList={blockForValidation ? [`/employee/detail/${protectedEmpNo}`] : undefined}
      />
      {showNotificationModal && blockNavigation.block && !!blockNavigation?.message && !!path.length && 
        getNotificationModal()
      }
      {loadingEmp ? (
        <div style={spinnerStyle}>
          <PageSpinner loadingMessage={'Loading Employee...'} />
        </div>
      ) : (
        <>
          {showEmFieldValidationModal && (
            <EmFieldValidationModal />
          )}
          {showIncompleteOnboardingSteps && !!onboardingStepsStatusErrors && (
            <OnboardingProgressModal
              show={showIncompleteOnboardingSteps && !!onboardingStepsStatusErrors}
              onHide={() => setShowIncompleteOnboardingSteps(false)}
              errors={onboardingStepsStatusErrors}
            />
          )}
          {showChangeEmpStatusModal && (
            <ConfirmationModal 
              show={showChangeEmpStatusModal}
              onHide={onHideChangeEmpStatusModal}
              onConfirmed={onConfirmChangeEmpStatusModal}
              title="Change Status?"
              message={changeStatusMessage}
            />
          )}
          {protectedEmpNo !== '0' && (
            <div
              className="d-flex pt-2"
              style={{
                flex: '60%',
                height: '140px',
              }}
            >
              <div
                className="mt-auto mb-2 ml-2 mr-2"
                style={{ width: '100px' }}
              >
                <EmpPhotoUpload
                  empPicData={empPicData}
                  setEmpPicData={setEmpPicData}
                  updateEmpPhoto={updateEmpPhoto}
                />
              </div>
              <div
                className="align-self-center"
                style={{ minWidth: '250px' }}
              >
                <div>
                  <div className="dm-grid-title">
                    {employee?.firstName + ' ' + employee?.lastName} <span style={pronounStyle}>{(employee?.preferredPronouns) ? `(${employee?.preferredPronouns})` : ''}</span>
                    <Button
                      variant="link"
                      disabled={!(canDeleteEmployee && employee)}
                      onClick={() => {return setShowConfirmations(true);}}
                      className="dm-grid-action-title mb-0 pb-1"
                    >
                      <Icon
                        name="trash"
                        title="Delete Employee"
                      />
                    </Button>
                  </div>
                  <div style={nickNameMargin}>
                    <span style={nickNameWrapperStyle}>
                      <span style={nickNameStyle}>{employee?.nickName}</span>
                    </span>
                    <hr style={hrStyle} />
                  </div>
                </div>
                <div style={empNoStyle}>
                  <span className="font-weight-bold">
                    Employee #:{' '}
                  </span>
                  <span>{employee?.empNo}</span>
                </div>
                {hireDateFieldAccess?.visible && (
                  <div style={fontSize12}>
                    <span className="font-weight-bold">
                      Hire Date:{' '}
                    </span>
                    <span className="mr-3">{empHireDate}</span>
                  </div>
                )}
              </div>
              {(currentErrors || hasMyInfoChanges) && employee?.onboardingStatus === 'Employee' ? (
                <button
                  className="btn orange-outline-button ml-2 mt-4"
                  style={{ height: 'fit-content' }}
                  onClick={() => { dispatch(toggleEmFieldValidationModal(true)); }}
                >
                  <Icon
                    name="warning"
                    color="#e22200"
                  />
                  View missing information/pending changes
                </button>
              ) : null}
              {employee?.onboardingStatus !== 'Employee' && onboardingStepsStatusErrors ? (
                <button
                  className="btn orange-outline-button ml-2 mt-4"
                  style={{ height: 'fit-content' }}
                  onClick={() => { setShowIncompleteOnboardingSteps(true); }}
                >
                  <Icon
                    name="warning"
                    color="#e22200"
                  />
                  View incomplete onboarding steps
                </button>
              ) : null}
            </div>
          )}
          {protectedEmpNo !== '0' && (
            <div style={{ flex: '40%' }}>
              <div className="ml-auto d-flex flex-column">
                <div className="align-self-end mb-3">
                  {showHRButton ? (
                    <NavLink to={showHRButton}>
                      <Button className="btn orange-outline-button mt-2">
                        Return to HR
                      </Button>
                    </NavLink>
                  ) : null}
                  {showTransmittalButton ? (
                    <NavLink to={showTransmittalButton}>
                      <Button className="btn orange-outline-button mt-2">
                        Return to transmittal
                      </Button>
                    </NavLink>
                  ) : null}
                </div>
                <div className="align-self-end">
                  {showTtimeCardButton ? (
                    <NavLink to={showTtimeCardButton}>
                      <Button className="btn orange-outline-button">
                        Return to time sheet
                      </Button>
                    </NavLink>
                  ) : (
                    <div className="d-flex justify-content-between">
                      <Icon
                        name="circle-arrow-left"
                        type={IconTypes.THIN}
                        fontSize={'1.5rem'}
                        onClick={onPrevEmp}
                      />
                      <span className="dm-grid-action-title2 px-2">
                        Employee
                      </span>
                      <Icon
                        name="circle-arrow-right"
                        fontSize={'1.5rem'}
                        type={IconTypes.THIN}
                        onClick={onNextEmp}
                      />
                    </div>
                  )}
                </div>
                <div className="align-self-end">
                  {location.state && location.state.prevPath === '/current-payroll' ? (
                    <NavLink to="/current-payroll">
                      <Button
                        variant="link"
                        className="pr-2 dm-grid-action-title2"
                      >
                        Back To Payroll
                      </Button>
                      <img
                        src={chevronRight}
                        alt=">"
                      />
                    </NavLink>
                  ) : (
                    <div className="pt-5"></div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div       
            style={{ flex: '100%' }}
          >
            <Switch>
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/add-employee"
                component={AddEmpPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/snapshot"
                component={SnapshotPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/emergency-contact"
                component={EmergencyContactPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/direct-deposit"
                component={DirectDepositPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/deductions"
                component={EmpDeductionsPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/foreign-address"
                component={ForeignAddressPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/payrate"
                component={PayRatePage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/taxes"
                component={TaxPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/deferred-comp"
                component={DeferredCompPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/payroll-info"
                component={PayrollInfoPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/visa-status"
                component={VisaStatusPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/special-info"
                component={SpecialInfoPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/dates"
                component={DatesPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/recurring-earnings"
                component={RecurringEarningsPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/client-rate-rules"
                component={ClientRateRulesPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/accruals"
              >
                <AccrualsPage protectedEmpNo={protectedEmpNo} />
              </Route>
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/ytd"
                component={YTDPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/contractor-options"
                component={ContractorOptionsPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/earnings-records"
              >
                <EarningsRecordsPage
                  protectedEmpNo={protectedEmpNo}
                  byCheckDate={true}
                />
              </Route>

              <Route
                exact
                path="/employee/detail/:protectedEmpNo/group-term-life"
                component={GroupTermLifePage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/company-benefits"
                component={CompanyBenefitsPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/hr-doc-comp"
                component={HRDoctorCompPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/hr-fte"
                component={HRFtePage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/attendance-on-demand"
                component={AttendanceOnDemandPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/aca"
                component={ACAPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/self-serve"
                component={SelfServicePage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/realign-supervised-employees"
                component={RealignSupervisedEmployeesPage}
              />
              <Route
                exact
                path="/employee/detail/:protectedEmpNo/check-calculator"
                component={CheckCalculatorPage}
              />
            </Switch>
          </div>
          {showConfirmations && employee && (
            <EmployeeDeleteConfirmationModal
              selectedEmployee={employee}
              setShowConfirmations = {setShowConfirmations}
              deleteFromDetail = {true}
            />
          )}
        </>
      ) }
    </div>
  );
};

export default EmpDetailPage;
