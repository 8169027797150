import { Modal } from 'react-bootstrap';
import Icon from 'core/components/shared/Icon';
import React from 'react';
import { RadioGrp, RadioGrpInLine } from 'core/components/form-controls';
import styles from './wizardModal.module.scss';

type PropTypes = {
  show: boolean;
  modalTitle: string;
  body: string
  question?: string;
  onHide: () => void;
  next?: () => void;
  previous?: () => void;
  submit?: () => void;
  radioButtons: {defaultValue: boolean, setValue: React.Dispatch<React.SetStateAction<boolean>>, label: string}[];
};

const WizardRadioButtonModal: React.FC<PropTypes> = ({
  show,
  modalTitle,
  body,
  question,
  onHide,
  next,
  previous,
  submit,
  radioButtons
}) => {

  const onHideAlert = () => {
      if (!confirm('Are you sure you would like to leave without saving? This will cancel all progress and not save any changes to the employee master.')) return;
      onHide();
    }  

  return (
  <Modal 
    show={show}
    animation={false}
    size='sm'
    onHide={onHideAlert}
  >
    <div className="modal-header mb-2">
      <div className="dm-card-title">
        {modalTitle}
      </div>
      <button type="button" className="modal-close-btn"
        onClick={onHideAlert}>
        <Icon name="times"
        />
      </button>
    </div>
    <Modal.Body className={styles['modalBody']}>
    <div className="d-flex flex-column">
      {question ? <p>{question}</p> : null}
      <p>{body}</p>
    </div>
    <div className="row text-center">
      {radioButtons.map((button, index) => {
        console.log(button);
      return (
        <div className="col">
          <RadioGrp
            name= {'yesOrNo' + index.toString()}
            label={button.label}
            defaultChecked= {button.defaultValue}
            radioOptions = {[
              {value: 'true', label: 'Yes'},
              {value: 'false',label: 'No'}
            ]}
            onChange={(e: any) => {
                button.setValue(e.target.value === 'true');
            }}
          />
        </div>
      )})}
    </div>
    </Modal.Body>
    <div className="row mt-2">
      <div className="col-12 text-right">
        {previous ? <button
          type="button"
          className="btn btn-link"
          style={{ fontSize: '14px' }}
          onClick={previous}
        >
        <Icon name="chevron-left" /> Previous
        </button> : null}
        {next ? <button
          type="button"
          className="btn btn-link"
          style={{ fontSize: '14px' }}
          onClick={next}
        >
          Next <Icon name="chevron-right" />
        </button> : null}
        {submit ? <button
            type="button"
            className="btn orange-button-sm mr-3"
            onClick={submit}
        >
            Submit
        </button> : null}
      </div>
    </div>
  </Modal>
  );
};

export default WizardRadioButtonModal;
