import { AxiosResponse } from "axios";
import { DeductionsShortage, EmployeeChangeReportField, HttpResponse, EmployeeChangeReport, EmployeeChangeReportRequest } from '../models';
import { EmployeeMasterReport } from '../models/EmployeeMaster.model';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeReportsEndPoints } from './URL';
import store from '../../core/store/store';
import { EmployeePayCategoryHistoryRequest } from 'core/models/EmployeeReports.model';


const getDeductionShortageReport = (reportType = 'Pdf', runReportBy = 'Byemployee'): Promise<AxiosResponse<DeductionsShortage>> => {
  return API_EMPLOYEE_MASTER.post<DeductionsShortage>(
    EmployeeReportsEndPoints.EMPLOYEE_DEDUCTIONS_SHORTAGE(CommonService.getProtectedClientNo()), {
      clientNo: CommonService.getProtectedClientNo(),
      reportType,
      runReportBy,
    });
};

const getEmployeeChangeReport = (beginDate: string, endDate: string): Promise<AxiosResponse<EmployeeChangeReportField[]>> => {
  return API_EMPLOYEE_MASTER.get<EmployeeChangeReportField[]>(
    EmployeeReportsEndPoints.GET_EMPLOYEE_CHANGE_REPORT(CommonService.getProtectedClientNo(), beginDate, endDate), {
    });
};

const postEmployeeChangeReport = (dto: EmployeeChangeReportRequest): Promise<AxiosResponse<EmployeeChangeReport>> => {
  return API_EMPLOYEE_MASTER.post<EmployeeChangeReport>(
    EmployeeReportsEndPoints.POST_EMPLOYEE_CHANGE_REPORT(CommonService.getProtectedClientNo()), dto);
};

const postEmployeeMasterReport = (clientNo: number, empNo: number, reportType = 'Pdf'): Promise<AxiosResponse<EmployeeMasterReport>> => {
  return API_EMPLOYEE_MASTER.post<EmployeeMasterReport>(
    EmployeeReportsEndPoints.POST_EMPLOYEE_MASTER_REPORT(CommonService.getProtectedClientNo(), store.getState().selEmployee.employee?.protectedEmpNo as string), {
      clientNo,
      empNo,
      reportType,
    });
};


const postEmpChangeReports = (beginDate: string, endDate: string, reportType = 'Pdf', runReportBy = 'Byemployee', selectedFields: string[]): Promise<AxiosResponse<EmployeeChangeReport>> => {
  return API_EMPLOYEE_MASTER.post<EmployeeChangeReport>(
    EmployeeReportsEndPoints.POST_EMPLOYEE_CHANGE_REPORT(CommonService.getProtectedClientNo()), {
      clientNo: CommonService.getProtectedClientNo(),
      beginDate,
      endDate,
      reportType,
      runReportBy,
      selectedFields,
    });
};


const postPayCategoryHistory = (data: EmployeePayCategoryHistoryRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeReportsEndPoints.POST_PAY_CATEGORY_HISTORY(CommonService.getProtectedClientNo()), data);
};




export const EmployeeReportsService = {
  getDeductionShortageReport,
  getEmployeeChangeReport,
  postEmployeeChangeReport,
  postEmployeeMasterReport,
  postEmpChangeReports,
  postPayCategoryHistory,
};
