import Icon from 'core/components/shared/Icon';
import React, { useEffect, useRef } from 'react';
import PanelHeader from '../../../core/components/shared/PanelHeader';
import { CommonService } from '../../../core/services';
import {
  changeHrEmployeeEmergencyContact,
  loadHrEmployeeEmergencyContacts,
  selectEmergencyContacts,
} from '../../../core/store/slices/hr.slice';
import { useAppDispatch, useAppSelector } from '../../../utilities/hooks';
import EmergencyContact from './EmergencyContact';

const HrEmployeeStatusEmergencyContactPage = () => {
  const dispatch = useAppDispatch();

  const refDiv = useRef<HTMLDivElement>(null);

  const selectedEmployee = useAppSelector(
    (state) => { return state.selEmployee.employee; },
  );

  const clientNo = useAppSelector((state) => { return state?.client?.client?.clientNo; });

  const emergencyContacts = useAppSelector(selectEmergencyContacts);

  useEffect(() => {
    if (selectedEmployee) {
      dispatch(
        loadHrEmployeeEmergencyContacts(selectedEmployee.protectedEmpNo),
      );
    }
  }, [selectedEmployee]);

  const addNewEmergencyContact = () => {
    if (selectedEmployee && clientNo) {
      dispatch(
        changeHrEmployeeEmergencyContact({
          clientNo,
          protectedClientNo: CommonService.getProtectedClientNo(),
          empNo: selectedEmployee.empNo,
          protectedEmpNo: selectedEmployee.protectedEmpNo,
          contactId: Math.random() * -1,
          contactOrder: 99,
          firstName: '',
          middleInitial: '',
          lastName: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          zip4: '',
          country: '',
          relationshipId: 0,
          homePhone: '',
          workPhone: '',
          cellPhone: '',
          homeEmail: '',
          workEmail: '',
          otherEmail: '',
          notes: '',
        }),
      );
      setTimeout(
        () => {
          return refDiv?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        },
        500,
      );
    }
  };
  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Emergency Contact">
        <button
          className="btn btn-link  dm-grid-action-title"
          type="button"
          onClick={addNewEmergencyContact}
        >
          Add New Contact{' '}
          <Icon name="plus-circle"
            className="fa-plus-circle" />
        </button>
      </PanelHeader>
      <div ref={refDiv}>
        {emergencyContacts.map((contact) => {
          return (
            <EmergencyContact
              emergencyContact={contact}
              key={contact.contactId}
            />
          );
        })}
      </div>
    </div>
  );
};

export default HrEmployeeStatusEmergencyContactPage;
