import { AgGridReact } from '@ag-grid-community/react';
import {
  AllModules,
  CellValueChangedEvent,
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
  RowNode,
} from '@ag-grid-enterprise/all-modules';
import Modal from 'core/components/modals/Modal';
import Icon from 'core/components/shared/Icon';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PayrollUploadFieldTranslation } from '../../../core/models/PayrollUpload.model';
import {
  createPayrollUploadTranslation,
  deletePayrollUploadTranslation,
  updatePayrollUploadTranslation,
} from '../../../core/store/actions';
import { agCheckboxEditor } from '../../../utilities/ag-grid-editors';
import { agCheckboxRenderer } from '../../../utilities/ag-grid-renderers';
import AGDeleteHeaderComponent from '../../../utilities/ag-grid-renderers/AGDeleteHeaderComponent';

const onCellValueChanged = (e: CellValueChangedEvent) => {
  e.oldValue !== e.newValue && (e.data.modified = true);
};

const columns: ColDef[] = [
  {
    field: 'originalValue',
    headerName: 'Original Value',
    sortable: true,
    editable: true,
    width: 200,
  },
  {
    field: 'translatedValue',
    headerName: 'Translated Value',
    sortable: true,
    editable: true,
    width: 200,
  },
  {
    field: 'delete',
    editable: true,
    headerComponentFramework: AGDeleteHeaderComponent,
    cellRenderer: 'checkboxRenderer',
    cellEditor: 'checkboxEditor',
    width: 45,
  },
];

const gridOptions: GridOptions = {
  domLayout: 'autoHeight',
  columnDefs: columns,
  defaultColDef: {
    suppressMenu: true,
    resizable: true,
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
  // @ts-ignore
  components: {
    checkboxEditor: agCheckboxEditor,
    checkboxRenderer: agCheckboxRenderer,
  },
  rowSelection: 'multiple',
  stopEditingWhenCellsLoseFocus: true,
};

interface RowDataType extends PayrollUploadFieldTranslation {
  modified: boolean;
}

type PropTypes = {
  customUploadMapId: number;
  mapId: number;
  translations: PayrollUploadFieldTranslation[];
  show: boolean;
  onHide: () => void;
};

const UploadPayrollTranslationsModal: React.FC<PropTypes> = ({
  customUploadMapId,
  mapId,
  translations,
  show,
  onHide,
}) => {
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [, setGridColumnApi] = useState<ColumnApi | null>(null);

  const [rowData, setRowData] = useState<RowDataType[]>(
    translations?.map((t) => {
      return {
        ...t,
        modified: false,
        delete: false,
      };
    }),
  );

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onAddTranslation = () => {
    setRowData((prev) => {
      return [
        ...prev,
        {
          ...prev[0],
          originalValue: '',
          translatedValue: '',
          translationId: 0,
          mapId,
          modified: false,
          delete: false,
        },
      ];
    });
  };

  const onSave = () => {
    if (gridApi) {
      gridApi.forEachNode((node: RowNode) => {
        const shouldDelete = node.data.delete;
        const modified = node.data.modified;
        delete node.data.modified;
        delete node.data.delete;
        delete node.data.mapId;

        if (shouldDelete) {
          // Delete this translation if it has already been saved.
          node.data.translationId &&
            dispatch(
              deletePayrollUploadTranslation({
                mapId,
                customUploadMapId,
                translation: node.data,
              }),
            );
        } else if (!node.data.translationId) {
          // This is a new translation - add it.
          dispatch(
            createPayrollUploadTranslation({
              mapId,
              customUploadMapId,
              translation: {
                ...node.data,
                translationId: 0,
                customUploadMapId,
              },
            }),
          );
        } else if (modified) {
          // This one already exists - update it.
          dispatch(
            updatePayrollUploadTranslation({
              mapId,
              customUploadMapId,
              translation: node.data,
            }),
          );
        }
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Manage Translations"
      headerSlot={(
        <div className="col-6 text-right mt-auto">
          <button
            type="button"
            className="btn btn-link dm-grid-action-title pb-0"
            onClick={onAddTranslation}
          >
            Add Translation
            <Icon name="plus-circle"
              className="fa-plus-circle" />
          </button>
        </div>
      )}
    >
      {(closeModal) => {
        return (
          <>
            <div className="row">
              <div className="col-12 table-wrapper-wrapper ag-theme-balham">
                <AgGridReact
                  gridOptions={gridOptions}
                  rowData={rowData}
                  modules={AllModules}
                  onGridReady={onGridReady}
                  onCellValueChanged={onCellValueChanged}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 text-right">
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button mr-2"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary orange-button"
                  onClick={() => {
                    onSave();
                    closeModal();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        );
      }}
    </Modal>
  );
};
export default UploadPayrollTranslationsModal;
