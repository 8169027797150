import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { DMDropdownDataModel } from 'core/components/form-controls/DMDropDown';
import { useAppSelector } from 'utilities/hooks';
import HamburgerMenu from 'core/components/shared/HamburgerMenu';

type EmployeeToolbarProps = {
  toggleSidebar: () => void;
  showSidebarMenuIcon: boolean;
  isMaster: boolean;
};

const EmployeeDocsToolbar: React.FC<EmployeeToolbarProps> = ({
  toggleSidebar,
  showSidebarMenuIcon,
  isMaster,
}) => {
  const history = useHistory();
  
  const groupAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.groups;
  });
  
  const empDocsSection = groupAccess?.find((group) => {
    return group.description.toLowerCase() === 'electronic documents';
  });
  const visible = empDocsSection?.visible;

  const navArray: {
    type: string;
    text?: string;
    href?: string;
    data?: DMDropdownDataModel;
  }[] = [
    {
      type: 'link',
      text: 'Employee Docs',
      href: '/employee-docs',
    },
  ];

  const onQuickOnboard = () => {return history.push('/quick-onboard');};

  const nav = () => {
    if (showSidebarMenuIcon) {
      return (
        <HamburgerMenu
          toggleSidebar={toggleSidebar}  
        />
      );
    } else {
      return navArray.map((n, i) => {
        switch (n.type) {
          case 'link':
            return n.href ? (
              <NavLink
                key={i}
                to={n.href}
              >
                {n.text}
              </NavLink>
            ) : null;
          default:
            return null;
        }
      });
    }
  };

  return (
    <div className="dm-toolbar">
      {visible === false ? null : (
        <>
          {nav()}
          <Button
            className="orange-button ml-auto mr-2"
            onClick={onQuickOnboard}
          >
            Quick Onboard
          </Button>
        </>
      )}
    </div>
  );
};

export default EmployeeDocsToolbar;
