import { AgGridReact } from '@ag-grid-community/react';
import { GridApi, ColDef, GridOptions } from '@ag-grid-enterprise/all-modules';
import { CheckboxGrpInLine, ControlDatePickerGrpInLine } from 'core/components/form-controls';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import Icon from 'core/components/shared/Icon';
import { Dropdown } from 'core/models';
import { EmployeePayCategoryHistoryRequest } from 'core/models/EmployeeReports.model';
import { CommonService } from 'core/services';
import { downloadPayCategoryHistoryReport, clearPayCategoryHIstoryReport as clearPayCategoryHistoryReport } from 'core/store/actions';
import { getPayCategories } from 'core/store/selectors';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const EmployeePayCategoryHistoryReport = () => {
  const dispatch = useAppDispatch();

  const report = useAppSelector((state) => { return state.employeeReports.payCategoryHistoryReport; });
  const client = useAppSelector((state) => { return state.client.client; });
  const payCategorires = useSelector(getPayCategories);

  const [fileType, setFileType] = useState<string>('excel');
  const reportName = 'EmployeePayCategoryHistoryReport';

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [, setSelectedCodes] = useState<Dropdown[]>();

  const { control, watch, errors, register, handleSubmit } =
    useForm<EmployeePayCategoryHistoryRequest>({});

  const startDate = watch('startDate');

  const onSubmit = (data: EmployeePayCategoryHistoryRequest) => {

    const submitData: EmployeePayCategoryHistoryRequest = {
      clientNo: client?.clientNo!,
      startDate: DateTime.fromJSDate(new Date(data.startDate)).toJSDate(),
      endDate: DateTime.fromJSDate(new Date(data.endDate)).toJSDate(),
      includeAllClientNosInEntity: data.includeAllClientNosInEntity,
      payCategories: gridApi?.getSelectedRows() ?? [],
    };
    dispatch(downloadPayCategoryHistoryReport(submitData));

  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const changePayCategoryHistory = () => {
    gridApi?.forEachNode((node) => {
      node.setSelected(true);
    });
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      selectedRows && setSelectedCodes(selectedRows);
    }
  };


  const hidePdfViewer = () => {
    dispatch(clearPayCategoryHistoryReport());
  };
  useEffect(() => {
    if (report && fileType === 'excel') {
      report && CommonService.downloadBase64File(
        'application/excel',
        report,
        `${reportName}.xlsx`,
      );
      dispatch(clearPayCategoryHistoryReport());
    }
  }, [report]);

  const columns: ColDef[] = [
    {
      field: 'select',
      headerName: 'Select',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 130,
    },
    {
      field: 'code',
      headerName: 'Code',
      width: 60,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 180,
    },
  ];

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    columnDefs: columns,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  return (
    <div className="dm-panel dm-panel-border">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="dm-grid-title">Employee Pay Category History Report</div>
        <div className="w-100 text-right">
          <button
            type="submit"
            className="btn btn-link dm-grid-action-title "
            onClick={() => { return setFileType('excel'); }}
          >
            Download Report  <Icon name="download"
              className="fa-download" />
          </button>
        </div>
        <hr className="dm-panel-hr" />

        <div className="d-flex flex-row flex-grow-1">
          <div className="d-flex flex-row flex-grow-1">
            <div>
              <ControlDatePickerGrpInLine
                name={'startDate'}
                label={'From'}
                labelWidth={40}
                groupClass={'groupClass50'}
                required={true}
                control={control}
                errors={errors?.startDate}
                rules={{
                  required: 'From Date is required',
                }}
              />
              <ControlDatePickerGrpInLine
                name={'endDate'}
                label={'To'}
                labelWidth={40}
                groupClass={'groupClass50'}
                required={true}
                control={control}
                minDate={DateTime.fromJSDate(new Date(startDate)).plus({ day: 1 }).toJSDate()}
                rules={{
                  required: 'To Date is required',
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex-grow-1"
          style={{ marginTop: 20 }}>
          <div className="dm-grid-action-title mb-2">Pay Categories</div>
          <div>
            <div className="d-flex flex-row">
              <CheckboxGrpInLine
                name="includeAllClientNosInEntity"
                label="Include All Client No's in Entity"
                ref={register}
              />
            </div>
          </div>
          <div className="ag-theme-balham mt-2">
            <div
              className="ag-theme-balham-wrap"
              style={{
                height: '100vh',
                width: '300px',
              }}
            >
              <AgGridReact
                gridOptions={gridOptions}
                rowData={payCategorires}
                onGridReady={onGridReady}
                onSelectionChanged={onSelectionChanged}
              />
            </div>
          </div>
        </div>
        {report && (
          <PDFViewerModal
            show={report.length > 0}
            pdfData={report}
            onHide={hidePdfViewer}
            reportName="EmployeePayCategoryHistoryReport"
          />
        )}
      </form>
    </div >
  );
};

export default EmployeePayCategoryHistoryReport;

