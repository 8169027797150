import React, { useEffect, useRef, useState } from 'react';
import { CellValueChangedEvent, ColDef, GridOptions } from '@ag-grid-community/core';
import InputDateSelector from 'core/components/form-controls/InputDateSelector';
import { InputGrp } from 'core/components/form-controls/InputGrp';
import { RadioGrp, RadioOptions } from 'core/components/form-controls/RadioGrp';
import { SelectGrp } from 'core/components/form-controls/SelectGrp';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { Client, DtoOptions } from 'core/models';
import {
  EEOReportRequest,
  DemographicAndTurnoverEmployee,
  EEOPayrollDate,
} from '../../../core/models/HumanCapitalReports.model';
import {
  getClientOptions, getEEOOccupGroups,
  getEEOUnitNos,
} from 'core/store/selectors';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { agSelectEditor } from 'utilities/ag-grid-editors';
import { agLookupRenderer } from 'utilities/ag-grid-renderers';
import AGEditButtonRendererComponent from 'utilities/ag-grid-renderers/AGEditButtonRendererComponent';
import { useAppSelector } from 'utilities/hooks';
import AssignEEOOccupationGroupModal from './AssignEEOOccupationalGroupModal';
import {
  clearEEOReport,
  downloadEEOReport,
  getDemographicAndTurnoverEmployees,
  updateDemographicAndTurnoverEmployee,
} from 'core/store/actions';
import AssignUnitModal from './AssignUnitModal';
import { AgGridReact } from '@ag-grid-community/react';
import EEOUnitModal from './EEOUnitModal';
import Icon from 'core/components/shared/Icon';
import EEOReport2 from './EEOReport2';
import { useForm } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { DateTime } from 'luxon';

const sOpts: RadioOptions[] = [
  {
    value: '0',
    label: 'Single-Establishment Employment Report',
  },
  {
    value: '1',
    label: 'Multi-establishment Employer',
  },
];

const selectOpts = [{
  label: '0',
  description: 'EEO1',
},
{
  label: '1',
  description: 'EEO2',
}];

const EEOReport = () => {
  const dispatch = useDispatch();
  const childRef: React.RefObject<any> = useRef();
  const report = useAppSelector((state) => { return state.eeo.eeoReport; });
  const client = useAppSelector((state) => { return state.client.client; }) as Client;
  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const eeoCodeDropDown = useAppSelector((state) => { return state.dropdown.eeoCode; });
  const eeoOccupationalGroups = useSelector(getEEOOccupGroups);
  const eeoUnitNos = useSelector(getEEOUnitNos);
  const [showAddModifyEEOUnitModal, setAddModifyEEOUnitModal] = useState<boolean>(false);
  const [showEEOOccupationalModal, setEEOOccupationlGroup] = useState<boolean>(false);
  const [showAssignUnitModal, setAssignUnitModal] = useState<boolean>(false);
  const employeesStore = useAppSelector((state) => { return state.humanCapitalReports.demographicAndTurnoverEmployees; });
  const [rowData, setRowData] = useState<DemographicAndTurnoverEmployee[]>([]);
  const [hqUnitNumber, setHQUnitNumber] = useState<string>('');
  const [showEEOReport2, setShowEEOReport2] = useState<boolean>(false);
  const [quarterData, setQuarterData] = useState<number>(0);
  const [yearData, setYearData] = useState<number>(DateTime.fromJSDate(new Date()).year);
  const [reportOptionsData, setReportOptionsData] = useState<string>('');
  const [showEmployeesGrid, setShowEmployeesGrid] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [submitData, setSubmitData] = useState<EEOReportRequest>();
  const [eeo2GridData, setEEO2GridData] = useState<EEOPayrollDate[]>([]);
  const clientOptions = useSelector(getClientOptions);
  const [disableSubmit, setDisabledSubmit] = useState(true);

  const getHQUnitNumber = () => {
    for (const i in clientOptions) {
      if (clientOptions[i].optionId === 388) {
        setHQUnitNumber('' + 388);
      } else if (clientOptions[i].optionId === null) {
        setHQUnitNumber('');
      }
    }
  };

  useEffect(() => {
    dispatch(getDemographicAndTurnoverEmployees());
  }, []);


  useEffect(() => {
    getHQUnitNumber();
  }, []);

  useEffect(() => {
    if (employeesStore.length) {
      setRowData(employeesStore);
    }
  }, [employeesStore]);

  useEffect(() => {
    setRowData([]);
  }, [yearData, quarterData]);

  const getQuartDataFromEEO2Screen = (quarter: number) => {
    setQuarterData(quarter);
  };

  const getYearDataFromEEO2Screen = (year: number) => {
    setYearData(year);
  };

  const getReportOptionsDataFromEEO2Screen = (reportType: string) => {
    setReportOptionsData(reportType);
  };

  const getEEO2ReportDataFromEEO2Screen = (reportData: EEOPayrollDate[]) => {
    setEEO2GridData(reportData);
  };

  const { register, handleSubmit } =
    useForm<EEOReportRequest>();

  const downloadReport = (data: EEOReportRequest) => {
    const dtoOptions = eeo2GridData.map(d => {
      return {
        weekEnd: d.weekEnd,
        checkDate: d.checkDate,
        payrollHistoryId: d.payrollHistoryId,
      };
    }) as DtoOptions[];

    const submit: EEOReportRequest = {
      clientNo: +client.clientNo,
      dateFrom: '' + reportDates[0],
      dateTo: '' + reportDates[1],
      quarter: quarterData,
      year: yearData === DateTime.fromJSDate(new Date()).year ? DateTime.fromJSDate(new Date()).year : yearData,
      establishmentType: data.establishmentType,
      eeoReportType: showEEOReport2 === false ? '0' : '1',
      eeoReportOptions: reportOptionsData === '0' ? '0' : '1',
      reportDTOOptions: dtoOptions,
    };

    if (employeesStore.length) {
      setRowData(cloneDeep(employeesStore));
      setShowConfirmationModal(true);
      setShowEmployeesGrid(true);
      setSubmitData(submit);
    } else {
      setRowData([]);
      setShowEmployeesGrid(false);
      dispatch(downloadEEOReport(submit));
    }
  };

  const loadScreen = (data: any) => {
    if (data?.target.value === 'EEO2') {
      setShowEEOReport2(true);
    } else if (data?.target.value === 'EEO1') {
      setShowEEOReport2(false);
    }
  };

  const onCellValueChanged = (e: CellValueChangedEvent) => {
    dispatch(updateDemographicAndTurnoverEmployee(e.data));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const confirmSubmit = (confirmed: boolean) => {
    if (confirmed && submitData) {
      dispatch(downloadEEOReport(submitData));
    }
  };

  const hidePdfViewer = () => {
    dispatch(clearEEOReport());
  };

  //#region AG grid cols
  const columns: ColDef[] = [
    {
      field: 'clientNo',
      headerName: 'Client',
      sortable: true,
      suppressMenu: false,
      editable: false,
      width: 90,
    }, {
      field: 'clientName',
      headerName: 'Client Name',
      sortable: true,
      suppressMenu: false,
      editable: false,
      width: 130,
    }, {
      field: 'empNo',
      headerName: 'Emp No',
      width: 90,
    }, {
      field: 'employeeName',
      headerName: 'Emp',
      width: 130,
    },
    {
      field: 'eeoRace',
      headerName: 'EEO Race',
      cellEditor: agSelectEditor,
      width: 130,
      editable: true,
      cellRenderer: agLookupRenderer,
      cellStyle: (params) => {
        return params.value?.toString().trim().length === 0 ?
          { backgroundColor: '#F9A7A7' }
          : null;
      },
      cellRendererParams: {
        options: eeoCodeDropDown,
        addEmptyValue: true,

      },
      cellEditorParams: {
        options: eeoCodeDropDown,
        addEmptyValue: true,
      },

    },
    {
      headerName: 'Occupational Group',
      field: 'eeoOccupationalGroup',
      cellEditor: agSelectEditor,
      width: 130,
      editable: true,
      cellRenderer: agLookupRenderer,
      cellStyle: (params) => {
        return params.value?.toString().trim().length === 0 ?
          { backgroundColor: '#F9A7A7' }
          : null;
      },
      cellRendererParams: {
        options: eeoOccupationalGroups,
      },
      cellEditorParams: {
        options: eeoOccupationalGroups,
        addEmptyValue: true,
      },
    },
    {
      field: 'eeoUnitNo',
      headerName: 'Unit No',
      cellEditor: agSelectEditor,
      width: 130,
      editable: true,
      cellRenderer: agLookupRenderer,
      cellStyle: (params) => {
        return params.value?.toString().trim().length === 0 ?
          { backgroundColor: '#F9A7A7' }
          : null;
      },
      cellRendererParams: {
        options: eeoUnitNos,
      },
      cellEditorParams: {
        options: eeoUnitNos,
        addEmptyValue: true,
      },
    },
    {
      field: 'location',
      headerName: 'Loc',
      sortable: true,
      suppressMenu: false,
      editable: false,
      width: 90,
    }, {
      field: 'department',
      headerName: 'Dept',
      sortable: true,
      suppressMenu: false,
      editable: false,
      width: 90,
    },
    {
      field: 'subDepartment',
      headerName: 'SubDept',
      sortable: true,
      suppressMenu: false,
      editable: false,
      width: 90,
    },
    {
      field: 'subDepartment',
      headerName: 'SubDept 2',
      sortable: true,
      suppressMenu: false,
      editable: false,
      width: 90,
    },
  ];

  const gridOptions: GridOptions = {
    columnDefs: columns,
    rowSelection: 'single',
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    components: {
      lookupRenderer: agLookupRenderer,
      selectEditor: agSelectEditor,
    },
    frameworkComponents: {
      editButtonRendererComponent: AGEditButtonRendererComponent,
    },
  };

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="d-flex flex-row">
            <p className="dm-grid-title">EEO Report</p>
            <div
              className="d-flex flex-row"
              style={{ marginLeft: '100px' }}
            >
              <div className="mt-auto pr-5 mb-1 mb-sm-0 text-right">
                <Button
                  variant="link"
                  className="dm-grid-action-title mb-0 pb-1"
                  onClick={() => { setAddModifyEEOUnitModal(true); }}
                >
                  Add/Modify EEO Unit
                  <Icon
                    name="plus-circle"
                    className="fa-plus-circle"
                  />
                </Button>
              </div>
              <div className="mt-auto pr-5 mb-1 mb-sm-0 text-right">
                <Button
                  variant="link"
                  className="dm-grid-action-title mb-0 pb-1"
                  onClick={() => { setAssignUnitModal(true); }}
                >
                  Assign Unit Number
                  <Icon
                    name="plus-circle"
                    className="fa-plus-circle"
                  />
                </Button>
              </div>
              <div
                className="mt-auto pr-5 mb-1 mb-sm-0 text-right"
                style={{ justifyContent: 'flex-end' }}
              >
                <Button
                  variant="link"
                  className="dm-grid-action-title mb-0 pb-1"
                  onClick={() => { setEEOOccupationlGroup(true); }}
                >
                  Assign EEO Occupational Group
                  <Icon
                    name="plus-circle"
                    className="fa-plus-circle"
                  />
                </Button>
              </div>
            </div>
          </div>
          <hr className="dm-panel-hr" />
          {/** Row for dropdown and datepicker */}
          <div className="d-flex flex-row flex-grow-1">
            <div className="d-flex ">
              <div style={{ display: showEEOReport2 ? 'none' : '' }}>
                <InputDateSelector
                  ref={childRef}
                  returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }}
                />
              </div>
              <div className="d-flex flex-row flex-grow-1">
                <SelectGrp
                  style={{ width: '190px' }}
                  label="EEO Type"
                  options={selectOpts}
                  onChange={(data: React.ChangeEvent<HTMLInputElement>) => { return loadScreen(data); }}
                  name={'eeoReportType'}
                  ref={register}
                />
              </div>
            </div>
            <div className="flex-grow-1 text-right">
              <button
                type="button"
                className="orange-outline-button mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="orange-button mr-2"
                disabled={showEEOReport2 && disableSubmit}
              >
                Run Report
              </button>
            </div>
          </div>
          <div className="d-flex flex-row">
            <div className="mr-3">
              <RadioGrp
                name={'establishmentType'}
                radioOptions={sOpts}
                ref={register}
                defaultChecked={hqUnitNumber === ' ' ? '0' : '1'}
              />
            </div>
            <div>
              <InputGrp
                label="Headquarters Unit No"
                value={hqUnitNumber}
                ref={register}
              />
            </div>
          </div>
          <hr className="dm-panel-hr" />
          <div className="d-flex flex-row">
            {showEEOReport2 ?
              <EEOReport2
                setDisabledSubmit={setDisabledSubmit}
                passQuarterDataFromEEO2={getQuartDataFromEEO2Screen}
                passYearDataFromEEO2={getYearDataFromEEO2Screen}
                passReportOptionsDataFromEEO2={getReportOptionsDataFromEEO2Screen}
                passEEO2ReportData={getEEO2ReportDataFromEEO2Screen}
              /> : null
            }
          </div>
          {showEmployeesGrid && rowData?.length > 0 &&
            <div className="d-flex flex-row flex-grow-1">
              <div className="d-flex flex-row row-wrap">
                <div
                  className="ag-theme-balham mt-5"
                  style={{
                    height: '200px',
                    width: '1100px',
                  }}
                >
                  <AgGridReact
                    gridOptions={gridOptions}
                    rowData={rowData}
                    onCellValueChanged={onCellValueChanged}
                    readOnlyEdit={true}
                  />
                </div>
              </div>
            </div>
          }
        </form>
      </div>
      {showAssignUnitModal &&
        <AssignUnitModal
          show={showAssignUnitModal}
          onHide={() => { return setAssignUnitModal(false); }}
        />
      }
      {showAddModifyEEOUnitModal &&
        <EEOUnitModal
          show={showAddModifyEEOUnitModal}
          onHide={() => { return setAddModifyEEOUnitModal(false); }}
        />
      }
      {showEEOOccupationalModal &&
        <AssignEEOOccupationGroupModal
          show={showEEOOccupationalModal}
          onHide={() => { return setEEOOccupationlGroup(false); }}
        />
      }
      {showConfirmationModal && (
        <ConfirmationModal
          title="Missing Information"
          message={`EEO information for Some Employees are missing status information (highlighted in red). 
                        Do you want to continue and run the report which will exclude these employees?`}
          show={showConfirmationModal}
          onConfirmed={confirmSubmit}
          onHide={() => {
            return setShowConfirmationModal(false);
          }}
        />
      )}
      {report &&
        <PDFViewerModal
          show={report.length > 0}
          pdfData={report}
          onHide={hidePdfViewer}
          reportName="EEOReport"
        />
      }
    </div>
  );
};

export default EEOReport;