import { convToClass, PropTypeLists } from '../../utilities/classUtils';
import { TimeOffRequestDetail } from './TimeOffRequestDetail.model';

export class TimeOffRequest {
  clientNo = 0;

  empId = 0;

  empNo = 0;

  protectedEmpNo = '';

  timeOffRequestId = 0;

  dateSubmitted: Date | string | null = null;

  status = '';

  earningsCode = '';

  requestComment = '';

  replyComment = '';

  details: TimeOffRequestDetail[] = [];

  static readonly convProps: PropTypeLists = {
    dateProps: ['dateSubmitted'],
    numberProps: ['clientNo', 'empId', 'empNo', 'timeOffRequestId'],
  };

  constructor(
    clientNo: number | string,
    empId: number | string,
    empNo: number | string,
    timeOffRequestId: number | string,
    props?: { [key: string]: any },
  ) {
    this.clientNo =
            typeof clientNo === 'string' ? parseInt(clientNo) : clientNo;
    this.empNo = typeof empNo === 'string' ? parseInt(empNo) : empNo;
    this.empId = typeof empId === 'string' ? parseInt(empId) : empId;
    this.timeOffRequestId =
            typeof timeOffRequestId === 'string'
              ? parseInt(timeOffRequestId)
              : timeOffRequestId;

    props && convToClass(this, props, TimeOffRequest.convProps);
  }
}
