import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import Modal from 'core/components/modals/Modal';
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { File } from '../../core/models/File.model';
import { putClientFile } from '../../core/store/slices/file.slice';
import { useAppDispatch, useAppSelector } from '../hooks';

const ViewEmployeeDocsCounterSignerRenderer = (props: ICellRendererParams) => {
  const dispatch = useAppDispatch();

  const fileAdmin = useAppSelector((state) => { return state.dropdown.fileAdminType; });
  const [showModal, setShowModal] = useState(false);

  const { register, handleSubmit } = useForm<File.FileRecord>({});

  const addRemove = () => {
    setShowModal(true);
  };

  const onSubmit = (data: File.FileRecord) => {
    const submitData = {
      ...props.data,
      fileAdminTypeIds: data.fileAdminTypeIds,
    };
    dispatch(putClientFile(submitData));
    setShowModal(false);
  };

  return (
    <>
      <div>
        {props.data.extension === '.pdf' && (
          <button onClick={addRemove}
            className="btn btn-link">
            Add/Remove
          </button>
        )}
      </div>
      <Modal
        backdrop="static"
        show={showModal}
        size="sm"
        onHide={() => { return setShowModal(false); }}
        title="Select Admin Counter-Signer"
      >
        {(closeModal) => {
          return (
            <form onSubmit={handleSubmit(onSubmit)}>
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>File Admin/Type</th>
                    <th>Selected</th>
                  </tr>
                </thead>
                <tbody>
                  {fileAdmin &&
                    fileAdmin.map((admin) => {
                      return (
                        <tr key={admin.id}>
                          <td>{admin.description}</td>
                          <td>
                            <input
                              type="checkbox"
                              ref={register({
                                valueAsNumber: true,
                              })}
                              name="fileAdminTypeIds"
                              defaultValue={admin.id}
                              defaultChecked={props.value.includes(
                                parseInt(admin.id),
                              )}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <Row className="justify-content-end">
                <button
                  type="button"
                  className="btn orange-outline-button mr-2"
                  onClick={closeModal}
                >
                  Clear
                </button>
                <button
                  type="submit"
                  className="btn orange-button"
                >
                  Save
                </button>
              </Row>
            </form>
          );
        }}
      </Modal>
    </>
  );
};

export default ViewEmployeeDocsCounterSignerRenderer;
