import React, { CSSProperties, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, ColDef, GridApi, GridOptions, GridReadyEvent, GridSizeChangedEvent } from '@ag-grid-enterprise/all-modules';
import { Col, Row } from 'react-bootstrap';
import { Employee } from 'core/models';

const gridWrapperStyle: CSSProperties = { height: '400px' };

const columns: ColDef[] = [{
  field: 'select',
  headerName: 'SELECT',
  checkboxSelection: true,
  width: 80,
}, {
  field: 'empNo',
  headerName: 'EMP #',
  sortable: true,
  suppressMenu: false,
  sort: 'asc',
  width: 80,
}, {
  field: 'firstName',
  headerName: 'FIRST NAME',
  sortable: true,
  suppressMenu: false,
  width: 130,
}, {
  field: 'lastName',
  headerName: 'LAST NAME',
  sortable: true,
  suppressMenu: false,
  width: 130,
}];

const gridOptions: GridOptions = {
  columnDefs: columns,
  defaultColDef: {
    suppressMenu: true,
    resizable: true,
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
  pagination: true,
  paginationPageSize: 10,
};

type EmpListProps = {
  employees: Employee[];
  setSelectedEmp: React.Dispatch<React.SetStateAction<Employee | null>>;
};

const EmpList = React.forwardRef<EmpListProps, any>((props: EmpListProps, ref: any) => {
  const { employees, setSelectedEmp } = props;
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };
  
  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit();
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      if (selectedRows?.length > 0) {
        setSelectedEmp(selectedRows[0]);
      }
    }
  };

  return (
    <div className="dm-panel dm-panel-border">
      <div className="dm-grid-title">Employee List</div>
      <div>Select employee to transfer</div>
      <Row>
        <Col
          xs={12}
          className="table-wrapper-wrapper ag-theme-balham"
          style={gridWrapperStyle}
        >
          <AgGridReact
            ref={ref}
            gridOptions={gridOptions}
            rowData={employees}
            modules={AllModules}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            onGridSizeChanged={onGridSizeChanged}
          />
        </Col>
      </Row>
    </div >
  );
});

EmpList.displayName = 'Emplist';

export default EmpList;