import { ControlDatePickerGrp, InputGrp, RadioGrpStacked } from 'core/components/form-controls';
import Modal from 'core/components/modals/Modal';
import { ThirdPartyEnum } from 'core/models/AccountingReports.model';
import React from 'react';
import { useForm } from 'react-hook-form';

type PropTypes = {
  selectedLink: string;
  show: boolean;
  onHide: () => void;
  addOptions: (options: any) => void;
};

const radioOpts = [
  {
    value: 'true',
    label: 'Check Date',
  },
  {
    value: 'false',
    label: 'Week End',
  },
];

const quickBookOptions = [
  {
    value: ThirdPartyEnum.QUICKEN2018ANDBEFORE,
    label: '2018 and Before',
  },
  {
    value: ThirdPartyEnum.QUICKBOOKS,
    label: '2019 and Later',
  },
];

const ThirdPartyExportModal: React.FC<PropTypes> = ({
  selectedLink,
  show,
  onHide,
  addOptions,
}) => {

  const { handleSubmit, reset, register, control, setValue, errors } = useForm();

  const hideModal = () => {
    onHide();
  };

  const onSubmit = (data: any, e?: React.BaseSyntheticEvent) => {
    if (!e?.target.isConnected) {
      return false;
    }
    addOptions(data);
    hideModal();
  };

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={hideModal}
        dialogClassName="modal-sm"
        title="Export Questions"
      >
        {(closeModal) => {
          return (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="d-flex flex-column">
                {selectedLink === ThirdPartyEnum.CSV_POWERCHURCH ?
                  <div>
                    <InputGrp
                      label="Journal Number"
                      name="powerChurchJournalNumber"
                      errors={errors?.powerChurchJournalNumber}
                      required={true}
                      ref={register({
                        maxLength: {
                          value: 8,
                          message: 'Journal Number must be 8 characters',
                        },
                        minLength: {
                          value: 8,
                          message: 'Journal Number must be 8 characters',
                        },
                      })}
                    />
                    <InputGrp
                      label="Memo Transaction"
                      name="powerChurchMemoTransaction"
                      ref={register()}
                    />
                  </div> : null}

                {selectedLink === ThirdPartyEnum.GREAT_PLAINS ?
                  <div className="text-center">
                    <strong>Please select the date you would like to use:</strong>
                    <div className="m-auto text-left w-25">
                      <RadioGrpStacked
                        name="useCheckDate"
                        radioOptions={radioOpts}
                        ref={register()}
                        stacked={true}
                      />
                    </div>
                  </div> : null}

                {selectedLink === ThirdPartyEnum.QUICKBOOKS || selectedLink == ThirdPartyEnum.QUICKEN2018ANDBEFORE ?
                  <div className="text-center">
                    <strong>Which version of QuickBooks are you importing into?</strong>
                    <div className="m-auto text-center w-30">
                      <RadioGrpStacked
                        name="quickBooksVersion"
                        radioOptions={quickBookOptions}
                        ref={register()}
                        stacked={false}
                        defaultChecked={quickBookOptions[0]}
                      />
                    </div>
                  </div> : null}

                {selectedLink === ThirdPartyEnum.CSV_SAGE500 || selectedLink === ThirdPartyEnum.CSV_SAGE_ACCPAC ?
                  <div>
                    <InputGrp
                      label="Fiscal Year"
                      name="fiscalYear"
                      ref={register({
                        valueAsNumber: true,
                        maxLength: {
                          value: 4,
                          message: 'Max length is 4 chars',
                        },
                        minLength: {
                          value: 4,
                          message: 'Min length is 4 chars',
                        },
                      })}
                    />
                    <InputGrp
                      type="number"
                      label="Fiscal Period"
                      name="fiscalPeriod"
                      ref={register()}
                    />
                    <InputGrp
                      label="Description"
                      name="description"
                      ref={register()}
                    />
                    <ControlDatePickerGrp
                      name="endDate"
                      label="End Date"
                      setValue={setValue}
                      control={control}
                    />

                  </div> : null}

                {selectedLink === ThirdPartyEnum.CSV_PLEX_CLASSIC || selectedLink === ThirdPartyEnum.CSV_PLEX_UX ?
                  <div>
                    <ControlDatePickerGrp
                      name="periodDate"
                      label="Period Date"
                      setValue={setValue}
                      control={control}
                    />
                    <ControlDatePickerGrp
                      name="postDate"
                      label="Post Date"
                      setValue={setValue}
                      control={control}
                    />
                  </div> : null}

              </div>
              <div style={{ display: 'flex' }}>
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button ml-auto mr-2"
                  onClick={() => { closeModal(); reset(); }}
                >
                  Cancel
                </button>
                <button type="submit"
                  className="orange-button mr-2">
                  OK
                </button>
              </div>
            </form>
          );
        }}

      </Modal>
    </div >
  );
};
export default ThirdPartyExportModal;
