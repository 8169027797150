import React, { FC, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { useAppSelector } from 'utilities/hooks';
import LegendLine from './LegendLine';
import styles from 'core/components/form-controls/form-controls.module.scss';
import { convToDateString, currencyFormatter } from 'utilities/utilities';
import { getLocationDepartmentsWithoutHome } from 'core/store/selectors';
import { useSelector } from 'react-redux';

const COLORS = [
  '#348ea9',
  '#52ba9b',
  '#62bed2',
  '#efbb47',
  '#D15C7A',
  '#6D8EBD',
  '#A0D07E',
  '#B76DB2',
  '#F08A5D',
  '#8E6CB8',
  '#5DB1B6',
  '#E7A2E2',
];

type Props = {
  visible: boolean;
};

const CurrentPayrollChart: FC<Props> = (props: Props) => {
  const { weekEnd, checkDate, payrollTotalsByLocDeptSub } = useAppSelector(({ dashboard }) => dashboard);
  const { locationOpts, deptOpts, subdeptOpts, subdept2Opts } = useSelector(getLocationDepartmentsWithoutHome);
  
  // sadly these are from two separate pieces of redux state so we can't do this in the
  const mapAndFilterOptions = (): Set<string> => {
    return new Set(payrollTotalsByLocDeptSub
      ?.map(({ key }) => key)
      ?.filter((key) => {
        return locationOpts?.length && key === 'Payroll by Loc.'
          || deptOpts?.length && key === 'Payroll by Dept.'
          || subdeptOpts?.length && key === 'Payroll by Sub Dept.'
          || subdept2Opts?.length && key === 'Payroll by Sub Dept2.';
      }),
    );
  };

  const [selected, setSelected] = useState<string>('');
  const [chartData, setChartData] = useState<{ name: string; value: number }[] | null>(null);
  const [selectData, setSelectData] = useState<Set<string>>(new Set());
  
  const setDataForChart = (data: string) => {
    const info = payrollTotalsByLocDeptSub?.filter(({ key }) => key === data) ?? null;
    if (!info?.length) return;
    
    setSelected(data);
    setChartData(info.map((a) => ({
      name: a.description,
      value: a.totalWage,
    })),
    );
  };
  
  useEffect(() => {
    if (payrollTotalsByLocDeptSub.length <= 0) return;
    const selectSet = mapAndFilterOptions();
    setSelectData(selectSet);
    setDataForChart([...selectSet]?.[0]);
  }, [payrollTotalsByLocDeptSub, locationOpts, deptOpts, subdeptOpts, subdept2Opts]);

  const timePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDataForChart(e.target.value);
  };
  
  const chartTotal = currencyFormatter(
    chartData
      ?.map((a) => a.value)
      ?.reduce((a, b) => a + b, 0) ?? 0,
  );
  
  if (!props.visible) return null;
  return (
    <div className="current-payroll-chart-page-container dm-card">
      <div className="dm-card-body">
        <div className="dm-card-body-title">
          <div className="mb-2 text-uppercase">Current Payroll</div>
        </div>
        {chartData ? (
          <>
            <Row className="mb-2">
              <Col>
                <select
                  onChange={timePeriodChange}
                  className={styles['dm-form-control']}
                  value={selected}
                >
                  {[...selectData]?.map((item) => {
                    return (
                      <option key={item}>{item}</option>
                    );
                  })}
                </select>
              </Col>
              <Col
                xs={'auto'}
                className="dm-grid-action-title"
              >
                {chartTotal}
              </Col>
            </Row>
            <hr />
            <div className="dm-card-content">
              <div className="dm-text-primary font-weight-bold text-center">
                {`Week Ending: ${convToDateString(weekEnd)} - Check Date: ${convToDateString(checkDate)}`}
              </div>
              <Row style={{ minHeight: '250px' }}>
                <Col
                  xs={5}
                  style={{ padding: '0' }}
                >
                  <ResponsiveContainer
                    width="100%"
                    height="100%"
                  >
                    <PieChart>
                      <Pie
                        data={chartData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        innerRadius="20%"
                        outerRadius="80%"
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {chartData.map((_entry, index) => {
                          return (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          );
                        },
                        )}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </Col>
                <Col
                  xs={7}
                  className={'my-auto text-left pl-2'}
                >
                  {chartData.map((item, index) => {
                    return (
                      <LegendLine
                        key={index}
                        dotColor={COLORS[index]}
                        legendText={item.name}
                        amount={item.value}
                      />
                    );
                  })}
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
};

export default CurrentPayrollChart;
