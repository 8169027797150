import { createReducer } from '@reduxjs/toolkit';
import { storeEmpNos, storeOrderByType } from '../actions/employee-information.action';
import { OrderByType } from 'core/models/AccrualReport.model';
export interface EmployeeInformationState {
  empNos: number[];
  orderByType: OrderByType;
}

const INITIAL_STATE: EmployeeInformationState = {
  empNos: [],
  orderByType: OrderByType.LastName,
};

export const employeeInformationReducer = createReducer(
  INITIAL_STATE,
  (builder) => {
    builder.addCase(storeEmpNos, (state, action) => {
      state.empNos = action.payload;
    });
    builder.addCase(storeOrderByType, (state, action) => {
      state.orderByType = action.payload;
    });
  },
);
