import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { UNSAVED_MESSAGE } from 'core/constants';
import { HrEmployeeCustomFields } from 'core/models/HrEmployeeCustomFields.model';
import {
  deleteEmpCustomField,
  postEmpCustomField,
  putEmpCustomField,
  removeEmpCustomField,
} from 'core/store/slices/hr.slice';
import { InputGrp, SelectModalGrp } from '../form-controls';
import DropdownOptionForm from '../form-controls/select-modal/DropdownOptionForm';
import Icon from './Icon';
import { toggleBlockNavigation } from 'core/store/actions';

type Props = {
  hrEmployeeCustomFields: HrEmployeeCustomFields;
};

type FormData = {
  entryData: string;
  empMasterCustomFieldsId: string;
};

const HrCustomField: React.FC<Props> = ({ hrEmployeeCustomFields }) => {
  const dispatch = useAppDispatch();
  const empMasterCustomFields = useAppSelector(
    (state) => { return state.dropdown.empMasterCustomFields; },
  );
  const { register, control, setValue, handleSubmit, formState, reset } =
    useForm<FormData>({
      defaultValues: {
        entryData: hrEmployeeCustomFields.entryData,
        empMasterCustomFieldsId:
          hrEmployeeCustomFields.empMasterCustomFieldsId.toString(),
      },
    });

  const { isDirty } = formState;

  const deleteCustomField = () => {
    dispatch(
      deleteEmpCustomField({
        protectedEmpNo: hrEmployeeCustomFields.protectedEmpNo,
        empCustomFieldsId: hrEmployeeCustomFields.empCustomFieldsId,
      }),
    );
  };

  const onSubmit = (data: FormData) => {
    const mergedData = {
      ...hrEmployeeCustomFields,
      entryData: data.entryData,
      empMasterCustomFieldsId: parseInt(data.empMasterCustomFieldsId),
    };
    if (hrEmployeeCustomFields.empCustomFieldsId > 0) {
      dispatch(putEmpCustomField(mergedData));
    } else {
      dispatch(removeEmpCustomField(mergedData.empCustomFieldsId));
      mergedData.empCustomFieldsId = 0;
      dispatch(postEmpCustomField(mergedData));
    }
    reset({ ...data });
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [isDirty]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xs="3">
          <SelectModalGrp
            name="empMasterCustomFieldsId"
            options={empMasterCustomFields}
            control={control}
            value={hrEmployeeCustomFields?.empMasterCustomFieldsId.toString()}
            setValue={setValue}
            label="Code"
            formComponent={DropdownOptionForm}
            modalTitle="Add New Code"
            dropdownName="EmpMasterCustomFields"
            addOptionText="Code"
          />
        </Col>

        <Col xs="3">
          <InputGrp
            label="Data"
            ref={register}
            name="entryData"
          />
        </Col>
        <Col
          xs="6"
          className="mt-2 text-right"
        >
          <button
            type="button"
            className="btn mr-2"
            onClick={deleteCustomField}
          >
            Delete Custom{' '}
            <Icon
              name="minus-circle"
              className="fa-minus-circle"
            />
          </button>
          <button
            className="btn orange-button"
            disabled={!isDirty}
          >
            Save
          </button>
        </Col>
      </Row>
    </form>
  );
};

export default HrCustomField;
