import { createReducer } from '@reduxjs/toolkit';
import {
  EmpGrossPayAnalysis,
  EmpPaidBreakdown,
  IssueToBeResolved,
  NewFeature,
  NewHireBreakdown,
  Reminder,
} from '../../models';
import { PayrollTotalByLocDeptSub } from '../../models/PayrollTotalByLocDeptSub';
import { PayrollTotalByTimePeriod } from '../../models/PayrollTotalByTimePeriod';
import {
  loadEmpGrossAnalysis,
  storeEmpGrossAnalysis,
  loadEmpPaidBreakdown,
  storeEmpPaidBreakdown,
  loadIssues,
  storeIssues,
  loadNewFeatures,
  storeNewFeatures,
  loadNewHireBreakdown,
  storeNewHireBreakdown,
  loadReminders,
  storeReminders,
  loadPayrollTotalsByTimePeriod,
  storePayrollTotalsByTimePeriod,
  loadPayrollTotalsByLocDeptSubSummary,
  storePayrollTotalsByLocDeptSubSummary,
} from '../actions/dashboard.action';

export interface State {
  loading: boolean;
  error: any;
  newFeatures: NewFeature[];
  newFeaturesLoading: boolean;
  reminders: Reminder[];
  remindersLoading: boolean;
  remindersError: any;
  empPaidBreakdown: EmpPaidBreakdown | null;
  empPaidBreakdownLoading: boolean;
  empPaidBreakdownError: any;
  issues: IssueToBeResolved[];
  issuesLoading: boolean;
  issuesError: any;
  empGrossAnalysis: EmpGrossPayAnalysis | null;
  empGrossAnalysisLoading: boolean;
  empGrossAnalysisError: any;
  newHireBreakdown: NewHireBreakdown | null;
  newHireBreakdownLoading: boolean;
  newHireBreakdownError: any;
  payrollTotalsByTimePeriod: PayrollTotalByTimePeriod[];
  payrollTotalsByLocDeptSub: PayrollTotalByLocDeptSub[];
  checkDate: Date | null;
  weekEnd: Date | null;
}

export const INITIAL_STATE: State = {
  loading: false,
  error: null,
  newFeaturesLoading: false,
  newFeatures: [] as NewFeature[],
  reminders: [] as Reminder[],
  remindersLoading: false,
  remindersError: null,
  empPaidBreakdown: null,
  empPaidBreakdownLoading: false,
  empPaidBreakdownError: null,
  issues: [] as IssueToBeResolved[],
  issuesLoading: false,
  issuesError: null,
  empGrossAnalysis: null,
  empGrossAnalysisLoading: false,
  empGrossAnalysisError: null,
  newHireBreakdown: null,
  newHireBreakdownLoading: false,
  newHireBreakdownError: null,
  payrollTotalsByTimePeriod: [] as PayrollTotalByTimePeriod[],
  payrollTotalsByLocDeptSub: [] as PayrollTotalByLocDeptSub[],
  checkDate: null,
  weekEnd: null,
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadNewFeatures, (state) => {
      state.newFeaturesLoading = true;
    })
    .addCase(storeNewFeatures, (state, action) => {
      state.newFeaturesLoading = false;
      state.newFeatures = action.payload;
    })
    .addCase(loadReminders, (state) => {
      state.remindersLoading = true;
    })
    .addCase(storeReminders, (state, action) => {
      state.remindersLoading = false;
      state.reminders = action.payload;
    })
    .addCase(loadNewHireBreakdown, (state) => {
      state.newHireBreakdownLoading = true;
    })
    .addCase(storeNewHireBreakdown, (state, action) => {
      state.newHireBreakdownLoading = false;
      state.newHireBreakdown = action.payload;
    })
    .addCase(loadEmpGrossAnalysis, (state) => {
      state.empGrossAnalysisLoading = true;
    })
    .addCase(storeEmpGrossAnalysis, (state, action) => {
      state.empGrossAnalysisLoading = false;
      state.empGrossAnalysis = action.payload;
    })
    .addCase(loadEmpPaidBreakdown, (state) => {
      state.empPaidBreakdownLoading = true;
    })
    .addCase(storeEmpPaidBreakdown, (state, action) => {
      state.empPaidBreakdownLoading = false;
      state.empPaidBreakdown = action.payload;
    })
    .addCase(loadIssues, (state) => {
      state.issuesLoading = true;
    })
    .addCase(storeIssues, (state, action) => {
      state.issuesLoading = false;
      state.issues = action.payload;
    })
    .addCase(loadPayrollTotalsByTimePeriod, (state) => {
      state.loading = true;
    })
    .addCase(storePayrollTotalsByTimePeriod, (state, action) => {
      state.loading = false;
      state.payrollTotalsByTimePeriod = action.payload.totalsByTimePeriod;
    })
    .addCase(loadPayrollTotalsByLocDeptSubSummary, (state) => {
      state.loading = true;
    })
    .addCase(storePayrollTotalsByLocDeptSubSummary, (state, action) => {
      state.loading = false;
      state.payrollTotalsByLocDeptSub = action.payload.totalsByLocDeptSub;
      state.weekEnd = action.payload.weekEnd;
      state.checkDate = action.payload.checkDate;
    });
});
