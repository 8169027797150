import { UserMapFormat, UserMapSelectedField } from 'core/models/Downloader.model';
import { EmployeeMasterSectionField, AccessGroup, GroupItem, EmployeeMasterSection } from 'core/models/ModuleAccess.model';
import { DateTime } from 'luxon';
import DateObject from 'react-date-object';
import { Dispatch, SetStateAction } from 'react';
import { ColDef } from '@ag-grid-community/core';
import { ControlIdBySection } from 'core/constants';
import { EmFieldValidation as E } from 'types';

export const ddLookup = (
  id: any,
  list: any[],
  valueField = 'id',
  labelField = 'description',
) => {
  const stringId = '' + id;
  const x = list.find((item) => { return '' + item[valueField] === stringId; });
  return x ? x[labelField] || x[valueField] : undefined;
};

export const currencyFormatter = (value: number | string, decimals = 2): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
  if (typeof value === 'number') {
    return formatter.format(value);
  }
  if (typeof value === 'string') {
    const num = parseFloat(value.replace(/[^0-9.+-]/g, ''));
    if (!isNaN(num)) {
      return formatter.format(num);
    }
  }

  return value;
};

export const fromCurrency = (value: string | number): number | undefined => {
  if (value === null || value === undefined) {
    console.error('no value given to fromCurrency');
    return;
  }
  
  const num = parseFloat(String(value).replace(/[^0-9.+-]/g, ''));
  if (!isNaN(num)) {
    return num;
  }
};

/**
 * Get string portion of a date string or Date.
 * @param value Timedate string or Date.
 * @returns Date portion of timedate string.
 */
export const convDateTimeToDate = (value: string | Date): string => {
  const stringDateTime = value instanceof Date ? value.toISOString() : value;
  const index = stringDateTime.indexOf('T');
  return index > 0
    ? stringDateTime.substr(0, stringDateTime.indexOf('T'))
    : stringDateTime;
};

//This will get the current date and if you pass in a year value it will give you the current date that many years out.
export function getCurrentDate(addYears = 0) {
  const date = new Date();
  const formattedDate = new Date(date);
  formattedDate.setFullYear(formattedDate.getFullYear() + addYears);
  return formattedDate;
}

/**
 * Converted Date or date string to 'mm/dd/yyyy' if possible.
 * @param value
 */
export const convToDateString = (value: Date | string | null) => {
  if (typeof value === 'string') {
    if (value.indexOf('T') === -1) {
      value += 'T00:00:00';
    }
    value = new Date(value);
  }
  //UGLY SORRY GOTTA BLAST WILL MAKE IT BETTER LATER :)
  if (value instanceof Date) {
    const dateString = value.toLocaleDateString();
    return (dateString === '1/1/1') ? '' : dateString;
  }
  return '';
};

export const convToDateISOString = (
  value: Date | string | null | DateTime,
): string => {
  if (typeof value === 'string') {
    if (value.indexOf('T') === -1) {
      value += 'T00:00:00';
    }
    value = new Date(value);
    value.setHours(0, 0, 0, 0);
  }
  if (value instanceof Date) return DateTime.fromJSDate(value).toISODate();
  if (value instanceof DateTime) return value.toISODate();
  return '';
};

export const determineGenderString = (gender: string) => {
  switch (gender) {
    case 'M':
      return 'Male';
    case 'F':
      return 'Female';
    case 'N':
      return 'Non-Binary';
  }
};

export const formatSSN = (val: string, caretIndex?: number): string => {
  let formattedSSN = val.replace(/\D/g, '');
  formattedSSN = formattedSSN
    .replace(/^(\d{3})/, '$1-')
    .replace(/-(\d{2})/, '-$1-')
    .replace(/(\d)-(\d{4}).*/, '$1-$2');
  
  // this is tricky... because this function is used for both display and change events
  if (caretIndex && formattedSSN[caretIndex] === '-') { // if we pass caret pos. and user clicks another position in the string to change a single digit
    formattedSSN = formattedSSN.substring(0, caretIndex) + formattedSSN.substring(caretIndex + 1); // remove the - and just return without it
  } else if (formattedSSN[formattedSSN?.length - 1] === '-') { // otherwise, if it's the last character, just slice it off
    formattedSSN = formattedSSN.slice(0, -1);
  }
  
  return formattedSSN;
};

export const formatFromSSN = (val: string): string => {
  return val.replace(/-/g, '');
};

export const formatPhone = (val: string, caretIndex?: number): string => {
  let formattedPhone = val.replace(/\D/g, '');
  formattedPhone = formattedPhone
    .replace(/^(\d{3})/, '($1) ')
    .replace(/\)\s(\d{3})/, ') $1-')
    .replace(/-(\d{4}).*/, '-$1');
  
  if (caretIndex && ![0, formattedPhone?.length].includes(caretIndex)) { //not at the beginning or end
    if (['-', '('].includes(formattedPhone[caretIndex])) {
      formattedPhone = formattedPhone.substring(0, caretIndex) + formattedPhone.substring(caretIndex + 1);
    }
    if (formattedPhone.substring(caretIndex - 1, caretIndex + 1) === ') ') {
      formattedPhone = formattedPhone.substring(0, caretIndex - 1) + formattedPhone.substring(caretIndex + 1); // -_-
    }
  } else {
    if (['-', '('].includes(formattedPhone[formattedPhone?.length - 1])) formattedPhone = formattedPhone.slice(0, -1);
    if (formattedPhone.substring(formattedPhone?.length - 2) === ') ') formattedPhone = formattedPhone.slice(0, -2); // -_-
  }
  
  return formattedPhone;
};

export const formatFromPhone = (val: string): string => {
  return val.replace(/\D/g, '');
};

export const dateDiff = (
  sd: string | Date | null,
  ed: string | Date | null,
) => {
  let end = typeof ed === 'string'
    ? DateTime.fromISO(ed)
    : DateTime.fromJSDate(ed ?? new Date());
  let start = typeof sd === 'string'
    ? DateTime.fromISO(sd)
    : DateTime.fromJSDate(sd ?? new Date());
  
  start = start.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  end = end.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  return end > start
    ? end.diff(start, ['years', 'months', 'days']).toObject()
    : start.diff(end, ['years', 'months', 'days']).toObject();
};

export const toFixed = (value: string, precision: number): string => {
  return (Number.isNaN(parseFloat(value)) ? 0 : parseFloat(value)).toFixed(
    precision,
  );
};

const decimalPlaces = (num: number): number => {
  return Number.isInteger(num) ? 0 : num.toString().split('.')[1]?.length;
};

export const formatDecimal = (value: string | number): string | number => {
  if (typeof value === 'string') value = parseInt(value);
  if (Number.isNaN(value)) value = 0;

  const places = decimalPlaces(value);

  if (places === 0) {
    return `${value}.00`;
  } else if (places === 1) {
    return `${value}0`;
  } else if (places === 2) {
    return value;
  }

  return value.toFixed(2);
};

export const toTitleCase = (value: string) => {
  return value
    .replace(/(_|-)/g, ' ')
    .trim()
    .replace(/\w\S*/g, function (val) {
      return val.charAt(0).toUpperCase() + val.substr(1);
    })
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
};

export const toCamelCase = (str: string) => {
  let newStr = '';
  if (str) {
    const wordArr = str.toLowerCase().split(/[' ']/g);
    for (const i in wordArr) {
      if (i > '0') {
        newStr += wordArr[i].charAt(0).toUpperCase() + wordArr[i].slice(1);
      } else {
        newStr += wordArr[i];
      }
    }
  } else {                            // remove trailing periods in string
    return newStr.replace('.', '').replace(/\.+$/, '');
  }
  return newStr.replace('.', '').replace(/\.+$/, '');
};

export const arrayMoveIndex = (arr: any[], oldIndex: number, newIndex: number) => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export type SectionAccessConfig = {
  visible: boolean;
  readOnly?: boolean;
  required?: boolean;
  disabled?: boolean;
};

export const getAccess = (
  sections: EmployeeMasterSection[] | undefined,
  workItemId: number,
  controlId?: number,
  defaults?: { readOnlyDefault?: boolean, visibleDefault?: boolean, disabledDefault?: boolean, disabledSameAsReadOnly?: boolean },
): SectionAccessConfig => {
  if (!sections) return { visible: true };

  const fieldSection = sections.find((section) => { return section.workItemId === workItemId; });
  if (!fieldSection) return { visible: true };

  let visible = fieldSection?.visible;
  let readOnly = fieldSection?.readOnly;
  let disabled = defaults?.disabledDefault ?? false;
  let required = false;

  const field = fieldSection?.fields?.find((f) => { return f.controlId === controlId; });
  if (field) {
    visible = (visible) ? field?.visible : visible; //If entire section is not visible, then field should not be visible
    readOnly = (readOnly) ? readOnly : field?.readOnly; //if entire section is readOnly, then field should be read only
    required = field?.mandatory;
  }

  if (defaults?.readOnlyDefault) {
    readOnly = true;
  }

  if (defaults?.disabledSameAsReadOnly) {
    disabled = disabled || readOnly;
  }

  if (visible) {
    visible = defaults?.visibleDefault ?? visible;
  }

  return {
    visible,
    readOnly,
    required,
    disabled,
  };
};

export type AccessMap = Record<string, SectionAccessConfig>;

/**
 * A function to get all access field objects for a given EM section. Returns key-value pairs of the controlId
  and that control's visible, readOnly, required, and disabled flags (AccessMap) or null if the section is not
  defined.
 * @param section EM section to grab field configurations for.
 * @returns AccessMap or null.
 */
export function getAllAccess(section: EmployeeMasterSection | undefined): AccessMap | null {
  if (!section) return null;
  
  const fields: AccessMap = {};
  
  section.fields.forEach((x) => {
    fields[`${x.controlId}`] = getAccess([section], section.workItemId, x.controlId);
  });  
  
  return fields;
}

type GroupAccessConfig = {
  visible: boolean;
  readOnly?: boolean;
};

export const getGroupAccess = (
  items: AccessGroup[] | GroupItem[] | undefined,
  itemId: number | undefined,
  groupId?: number | undefined,
): GroupAccessConfig | undefined => {
  if (!((items && items.length) && (itemId || groupId))) return;
  if (groupId && !itemId) {
    const group = (items as AccessGroup[]).find((group) => { return group?.groupId === groupId; });

    if (!group) return;

    return { visible: group.visible };
  }

  const item = (items as GroupItem[]).find((item) => { return item?.itemId === itemId; });

  if (!item) return { visible: true };

  return {
    visible: item.visible,
    readOnly: item.readOnly,
  };
};

export const numberWithCommas = (num: string | number): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const toReadableDate = (date: Date | string) => {
  if (!date) {
    return '';
  }

  return new DateObject(new Date(date)).format('MM/DD/YYYY');
};

export const toReadableTime = (date: Date | string) => {
  if (!date) {
    return '';
  }

  return new DateObject(new Date(date)).format('hh:mm a').toUpperCase();
};

export const createField = (name: string, section: string, params: any = null) => {

  //Need to add a 'Filter' record to any section === 'Federal' field we create
  if (section === 'Federal' && params === null) { 
    params = { filterId: 0,
      code: 'FD',
      description: 'Federal' };
  }

  const format: UserMapFormat = {
    order: 0,
    columnHeader: (params) ? `${params.description} ${name}` : name,
    columnHeaderSetter: true,
    justification: 'LeftJustify',
    style: 'Text',
    customFormatting: '',
    sortDirection: 'NotUsed',
    sortOrder: 0,
    sortFunction: '',
    filterCondition: '',
    filterValue: '',
    filterLogic: '',
    filterOverride: '',
    formula: '',
    groupBy: false,
    groupByHeaderDesc: '',
    groupBySubFooterDesc: '',
    groupByTotalFooterDesc: '',
    groupByFunction: 0,
    columnWidth: 50,
    showColumn: true,
    excelTextCasing: 'NotUsed',
    excelJustification: 'LeftJustify',
  };

  const nField: UserMapSelectedField = {
    selectedAreaId: 0,
    filter: params,
    format: format,
    font: {
      font: 'Calibri',
      fontSize: 11,
      fontStyle: 'Regular',
      underline: false,
      effects: false,
    },
    section: section,
    fieldName: name,
  };

  return nField;
};

export const coerceKey = (key: string, obj: object) => {
  return key as keyof typeof obj;
};

/**
 * Recursively search an object to determine if a key exists in it (may return the value at key). 
 *
 * @param obj {object | string} Object to look through
 * @param key Key to parse object with
 * @param depth Object depth counter so we don't end up in the object's prototype(s). Defaults to 0
 * @param maxDepth The maximum level we want to search in. Defaults to 10
 * @returns boolean
 */
export function keySearch(obj: object | string, key: string, depth = 0, maxDepth = 10) {
  let value;
  const keyArray = Object.keys(obj);

  if (depth > maxDepth) return false;
  if (keyArray.includes(key)) return true;
  if (typeof obj === 'string') return obj === key;

  keyArray.some((k) => {
    const typedNestedKey = coerceKey(k, obj);
    if (typedNestedKey === key) {
      value = obj[typedNestedKey];
      return true;
    }
    if (obj[typedNestedKey] && typeof obj[typedNestedKey] === 'object') {
      value = keySearch(obj[typedNestedKey], key, depth + 1);
      return value !== undefined;
    }
    return false;
  });

  return value;
}

//Will format a number based on a preference if the preference is greater than or equal to the decimal values
export const FormatOnPreferences = (amount: string | undefined, decimalPreference: number | undefined) =>{
  if (!amount) return 0.00;
  const [whole, dec] = amount.toString().split('.');
  const decLength = (!dec) ? 0 : dec.length;
  const numberAmount = +amount;

  //If they do not have any preference default it to 2 places
  if (!decimalPreference) return numberAmount.toFixed(2);
  //If they type a decimal just format that number to the desired amount of decimal places
  if (amount.includes('.')) return numberAmount.toFixed(decimalPreference);

  //If the decimal length is smaller than the preference handle the decimal place
  if (decLength <= decimalPreference) return (parseInt(whole)).toFixed(decimalPreference);
  else return numberAmount.toFixed(2);
};

export function addPaddingCharacters(base64Data: any) {
  const paddingNeeded = 4 - (base64Data.length % 4);
  if (paddingNeeded === 4) {
    return base64Data;
  }
  return base64Data + '==='.slice(0, paddingNeeded);
}

export function dataURIToBlob(dataURI: any): Blob {
  const base64Data = dataURI.split(',')[0];
  const paddedData = addPaddingCharacters(base64Data);
  const binaryData = atob(paddedData);
  const buffer = new ArrayBuffer(binaryData.length);
  const array = new Uint8Array(buffer);
  for (let i = 0; i < binaryData.length; i++) {
    array[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([array], { type: 'application/octet-stream' });

  return blob;
}

/**
 * Round a decimal to n places.
 *
 * @param value value we want rounded represented as a string
 * @param places how many places we want to round to
 * @returns rounded number as a string
 */
export const roundDecimal = (value: string, places = 2): string => {
  const valueArr = value.split(''); // split up the value
  const zeros = Array(places + 1).join('0'); // join with '0' on length + 1 since the 0s go 'between' each array value
  const allZeros = valueArr.every((val) => Number(val) === 0);
  
  if (allZeros) return zeros; // if it's just all 0s, return however many 0s places specifies
  
  const firstRealNumIndex = valueArr.findIndex((num) => Number(num) > 0); // account for numbers not in the one's place
  
  // if we don't have a number > 0 (or value doesn't start with it), return this. Otherwise, concatenate
  if (firstRealNumIndex < 1) { 
    return String(Math.round(Number(value) / 10 ** (value.length - places))).substring(0, places);
  } else { 
    return (value.slice(0, firstRealNumIndex) + String(Math.round(Number(value) / 10 ** (value.length - places)))).substring(0, places);
  }
};

/**
 * "Clean" a value that's been formatted with the below formatWithCommas function (or really any formatting function). 
 *
 * @param value the one that we'll be parsing and cleaning up
 * @returns the cleaned value as a string
 */
export const cleanAmount = (value: string | number): string => String(value).replace(/^(-)+|[^0-9.]|\.(?=.*\.)/g, '$1');
  
/**
 * Represent any large number with commas (pretty much as a locale string, but also adds on however many
 * decimals we want). 
 *
 * @param value value to be formatted; string or number
 * @param places the number of decimal places we want to format to
 * @returns the formatted number as a locale string to ```places``` decimal places
 */
export const formatWithCommas = (value: string | number, places = 2): string => {
  const valArr = String(value).split('.');
  const localeVal = Number(cleanAmount(valArr[0])); // take the whole number and clean it up
  places = Math.max(Math.abs(places), 2); // make sure this isn't negative and force-format to 2 OR "places" decimals (can change to 1 if we need it)
  const zeros = Array(places + 1).join('0'); // need the extra index because join puts join value in between arr values
  
  if (!valArr?.[1] || valArr[1] === zeros) return localeVal.toLocaleString() + `.${zeros}`; // whole number or we already have the 0s we need
  if (valArr[1].length === 1) return localeVal.toLocaleString() + `.${valArr[1]}${zeros.slice(0, -1)}`; // num in the one's place; add the remaining 0s
  if (valArr[1].length > 2) return localeVal.toLocaleString() + `.${roundDecimal(valArr[1], places)}`; // similar to above, but will pass places to roundDecimal
  
  return localeVal.toLocaleString() + `.${valArr[1]}${zeros.slice(0, -2)}`; // we have exactly two decimals
};

//We need to remove commas before parsing or it will not parse properly
export const parseFloatNumberWithCommas = (value: string): number => {
  const updatedValue = value.replace(',', '');
  return parseFloat(updatedValue);
};

/** 
  * Custom hook utility method for toggling booleans inside boolean state objects.
  * @template T The type of the state we're updating (typically `{ [key: string]: boolean }` or `Record<string, boolean>`)
  * @param setShow {Dispatch<SetStateAction<T>>} the state setter
  * @returns {(key: keyof T, newVal: boolean) => void} a function setting the new value by key with the passed in state setter.
  * @example 
  * ```tsx 
  * type ModalKey = { [key: string]: boolean };
  * const [showModal, setShowModal] = useState<ModalKey>({ showModal1: false, showModal2: false, showModal3: false });
  * const toggleShow = createToggleShow(setShowModal);
  * // somwhere in the component...
  * toggleShow('showModal2', true); // the type of the key will be narrowed to the keys of the state object
  * ```
*/
export function createToggleShow<T>(setShow: Dispatch<SetStateAction<T>>): (key: keyof T, newVal: boolean) => void {
  return (key: keyof T, newVal: boolean) => {
    setShow((prevState) => ({ ...prevState, [key]: newVal }));
  };
}

export const buildModuleClasses = (styleObj: { readonly [key: string]: string; }) =>
  (...classList: any[]) =>
    classList?.reduce((list, className) => {
      let output = list;
      if (styleObj[className]) {
        if (list) output += ' ';
        output += styleObj[className];
      }
      return output;
    }, '');

export type GenericGroup<T> = {
  [key: string]: T[];
};

/**
 * Groups array data into an object mapping based on a specific key from the array's type. For 
 * example, the key might be an employee number and the value an array of records associated with that employee.
 *
 * @template T - The type of the element to group
 * @param data {T[]} The array you're splitting/grouping
 * @param field The key from the array's type that become the key for that group in the new object
 * @returns An object with key from ```field``` and value as an array of ```T``` (almost definitely some objects)
 */
export function groupByField<T>(data: T[], field: keyof T): GenericGroup<T> {
  return data.reduce((r: GenericGroup<T>, v: T, _i, _a, k = v[field]) =>
    ((r[String(k)] || (r[String(k)] = [] as T[])).push(v), r), {} as GenericGroup<T>);
}

/**
 * Check if something is a function. Useful when rendering children
 * as a function in a component. Intended to replace Lodash's isFunction.
 * @template T - The type of ```func```
 * @param func {T} variable you're checking the type of
 * @returns a boolean indicating whether or not ```func``` is a function
 */
export function isFunction<T>(func: T): boolean {
  return typeof func === 'function';
}

/**
 * Will pad a number or string with a zero for the desired number of places
 * @param num variable that is the base number/string you want to add 0's in front of.
 * @param places variable that is the number of places you want the number to be
 * @returns a string that has x number of zeros in front of it until the number is ```places``` long.
 */
export function zeroPad(num: number | string, places: number) {
  const numberOfZeros = places - num.toString().length + 1;
  return Array(+(numberOfZeros > 0 && numberOfZeros)).join('0') + num;
}

export const getFirstDayOfMonth = (year: number, month: number): number => {
  return new Date(Math.abs(year), Math.abs(month), 1).getDate();
};

export const getLastDayOfMonth = (year: number, month: number): number => {
  return new Date(Math.abs(year), Math.abs(month), 0).getDate();
};

export const getMondayAndFriday = (date: Date): [Date, Date] => {
  const mondayDate = structuredClone(date); const fridayDate = structuredClone(date);
  const today = date.getDay();
  const monday = date.getDate() - today + (today === 0 ? -6 : 1); // adjust for Sunday
  const friday = monday + 4;

  mondayDate.setDate(monday); 
  fridayDate.setDate(friday);

  return [mondayDate, fridayDate];
};

export type ShortCutKey =
  'thisWeek'
  | 'lastWeek'
  | 'thisMonth'
  | 'lastMonth'
  | 'nextMonth'
  | 'last30Days'
  | 'last60Days'
  | 'last90Days'
  | 'last12mnths'
  | 'thisYear'
  | 'lastYear'
  | 'nextYear';

export const handleShortcutSelection = (option: ShortCutKey, now = new Date()): [Date, Date] => {
  switch (option) {
    case 'thisWeek': {
      const [monday, friday] = getMondayAndFriday(new Date());
      return [monday, friday];
    }
    case 'lastWeek': {
      const lastWeekToday = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
      const [monday, friday] = getMondayAndFriday(lastWeekToday);
      return [monday, friday];
    }
    case 'thisMonth': {
      // we don't need to addd or subtract here because of the zero-indexed months
      const firstDate = new Date(now.getFullYear(), now.getMonth(), 1);
      const lastDate = new Date(now.getFullYear(), now.getMonth(), getLastDayOfMonth(now.getFullYear(), now.getMonth() + 1));  
      return [firstDate, lastDate];
    }
    case 'lastMonth': {
      // we don't need to add or subtract here because of the zero-indexed months
      const firstDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const lastDate = new Date(now.getFullYear(), now.getMonth() - 1, getLastDayOfMonth(now.getFullYear(), now.getMonth())); 
      return [firstDate, lastDate];
    }
    case 'nextMonth': {
      // we don't need to addd or subtract here because of the zero-indexed months
      const firstDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
      const lastDate = new Date(now.getFullYear(), now.getMonth() + 1, getLastDayOfMonth(now.getFullYear(), now.getMonth() + 2)); 
      return [firstDate, lastDate];
    }
    case 'thisYear': {
      return [new Date(`01/01/${now.getFullYear()}`), new Date(`12/31/${now.getFullYear()}`)];
    }
    case 'lastYear': {
      return [new Date(`01/01/${now.getFullYear() - 1}`), new Date(`12/31/${now.getFullYear() - 1}`)];
    }
    case 'nextYear': {
      return [new Date(`01/01/${now.getFullYear() + 1}`), new Date(`12/31/${now.getFullYear() + 1}`)];
    }
    case 'last12mnths': {
      // getMonth is zero-indexed so we add 1
      return [new Date(`${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear() - 1}`), now];
    }
    case 'last30Days': {
      const thirtyDaysAgo = new Date(new Date().setDate(now.getDate() - 30));
      return [thirtyDaysAgo, now];
    }
    case 'last60Days': {
      const sixtyDaysAgo = new Date(new Date().setDate(now.getDate() - 60));
      return [sixtyDaysAgo, now];
    }
    case 'last90Days': {
      const ninetyDaysAgo = new Date(new Date().setDate(now.getDate() - 90));
      return [ninetyDaysAgo, now];
    }
    default:
      return [new Date(), new Date()];
  }
};

/**
 * Performs deep equality comparison of two objects. 
 *
 * @param object1 the first object to compare (this and object2 will probably be a primitive when recursing)
 * @param object2 compared to object1
 * @returns a boolean indicating if the two objects are truly equal
 */
export function deepEqualityComparison(object1: any, object2: any): boolean { // a rare case where the values really CAN be "any" since we do type comparisons in the body and recurse (sometimes)
  if (typeof object1 !== typeof object2) return false; // will also handle if just one is null
  if (object1 === null && object2 === null) return true;
  if (typeof object1 !== 'object' && typeof object2 !== 'object') return Object.is(object1, object2); // compare two primitives; this static method will avoid NaN comparison issues
  if (object1 === object2) return true;
  
  if (Array.isArray(object1) && Array.isArray(object2)) {
    if (object1?.length !== object2?.length) return false;
    
    for (let i = 0; i < object1.length; i++) {
      if (!deepEqualityComparison(object1[i], object2[i])) return false; // probably want to avoid the for-loop recursion if we can
    }
    // all elements are equal, so the objects are too
    return true;
  }
  
  if (Array.isArray(object1) || Array.isArray(object2)) return false; // I think this will cover the case of array literals and constructor-created arrays
  if (Object.keys(object1).length !== Object.keys(object2).length) return false; // they both must be objects at this point, so start with this
  
  // at this point, we know we have two objects with the same number of keys. But we need to loop this way because if they keys are in different orders it won't work.
  for (const [key, val] of Object.entries(object1)) {
    if (!(key in object2)) return false; // object2 doesn't have this key, so they can't be the same objects
    if (!deepEqualityComparison(val, object2[key])) return false; // recursion case 2 (so it'll just be checked by the above cases)
  }
  
  return true; // both are objects and equal by value
}

/**
 * Limits calls to function ```func``` to once every ```interval```.
 * @param func function that we are calling once every ```interval```
 * @param interval time in ms to wait before calling ```func``` again
 * @returns {(...args: any[]) => void} callback to execute ```func``` on ```interval```
 * @example
 * ```tsx
 * const logHello = throttle(() => { console.log('hello'); }, 4000); // only log 'hello' at most once every 4 seconds
 * setInterval(() => { logHello(); }, 2000); // logHello will be called every 2 seconds but only executed every other time
 * ```
 */
export function throttle(func: (...args: any[]) => void, interval: number): (...args: any[]) => void {
  let lastTime = 0;
  return function (...args: any[]) {
    const now = new Date().getTime();
    if (now - lastTime >= interval) {
      func(...args);
      lastTime = now;
    }
  };
}

//Uploader Shared Methods (I know it could use a lot more love im very behind due to change report I will be back to make this cleaner I promise (╥﹝╥))
export const buildHeaders = (headerStartingLine: number, fileRows: string[]): string[] => {
  let headers = [];
  if (headerStartingLine > 1) {
    headers = fileRows[headerStartingLine - 2]
      .split(',')
      .map((h: any) => { return h.replace(/"/g, '').replaceAll('.', ''); });
  } else {
    const fileSplit = fileRows[0].split(',');
    headers = fileSplit.map((x, index) => {return String(index);});
  }

  return headers;
};

export const buildParsedFile = (headerStartingLine: number, fileRows: string[], headers: string[]): string[] => {
  const slicePosition = (headerStartingLine > 1) ? headerStartingLine - 1 : 0;

  const parsedFile = fileRows.slice(slicePosition).map((line: string) => {
    // will handle quoted fields (e.g., "Doe, John" as well as sequential empty ones (",,,"))
    const y = line.match(/"([^"]|"")*"|[^",]+|(?<=,|^)(?=,|$)/g)?.map((field: string) => {
      return field.replace(/^"|"$/g, '').replace(/""/g, '"');
    }) || [];

    const obj = headers.reduce((acc: any, header: string, i: number) => {
      acc[header] = y[i] ?? ' ';
      return acc;
    }, {});

    return obj;
  });

  return parsedFile;
};

export const generateColumnDef = (headerStartingLine: number, headers: string[]): ColDef[] => {
  if (headers?.length > 0) {
    return headers.map((h: string, index: number) => {
      return {
        field: h,
        headerName: (headerStartingLine > 1) ? index + ' - ' + h : index.toString(),
        fieldOrdinal: index,
        width: 150,
      };
    });
  }
  return [];
};

/**
 * Checks if an object `obj` conforms to a specific type `T` based on an array of keys `objKeys`.
 * @template T - Object type tha we want to match
 * @param obj the object we want to compare
 * @param objKeys the array of keys unique to `T` that we want to check against `obj`
 * @returns boolean indicating we can narrow type of `obj` to `T`
 */
export function isOfType<T extends object>(obj: object, objKeys: string[]): obj is T {
  if (!obj) return false;
  
  for (const key of objKeys) {
    if (!(key in obj)) return false; 
  }
  
  return true;
}

/**
 * Pulls a key from an enum based on its value `value`, which is a number. This lookup is otherwise impossible.
 * @param enumObj Enum to perform lookup on
 * @param value value that corresponds to a certain key in enum
 * @returns the key matching the value or null if not found
 * @example
 * ```
  enum Things {
    thingOne = 1,
    thingTwo = 88,
    thingThree = 4
  }
  
  const keyFor1 = getEnumKeyByNumValue(Things, 1); // "thingOne"
  const keyFor999 = getEnumKeyByNumValue(Things, 999); // null
 * ```
 */
export function getEnumKeyByNumValue(enumObj: any, value: number): string | null {
  const enumKey = Object.keys(enumObj)?.find(key => enumObj[key] === value) ?? null;
  return enumKey;
}

export function getKeyByNumAndSection(
  constObj: typeof ControlIdBySection,
  section: E.ValidateSection,
  value: any,
): string | null {
  const sectionObj = constObj[section] ?? {};
  const key = Object.keys(sectionObj)
    ?.find(_key => sectionObj[_key as keyof typeof sectionObj] === value) ?? null;
  
  return key;
}

export function capitalizeFirstLetter(word: string): string {
  if (!word?.length) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function generateNegativeId(multiplier = -1000) {
  return Math.floor(Math.random() * multiplier);
} 