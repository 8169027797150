import {
  ControlDatePickerGrp,
  ControlTimePickerGrp,
  InputGrp,
  Label,
} from 'core/components/form-controls';
import Modal from 'core/components/modals/Modal';
import Icon from 'core/components/shared/Icon';
import {
  getPayrollUsers,
  getCustomInterviewEmails,
  sendApplicantInterviewEmails,
  getInterviewTemplates,
  createApplicantInterviewFromTemplate,
  updateApplicantInterview,
} from 'core/store/actions/applicant-tracking.action';
import { compact, get, uniqueId } from 'lodash';
import { DateTime } from 'luxon';
import { useState, useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { StylesConfigType, compStyles } from 'utilities/styles';
import AddInterviewConfirmationModal from './AddInterviewConfirmationModal';
import InterviewEmailsModal from './InterviewEmailsModal';
import InterviewTeam from './InterviewTeam';
import InterviewLocationsDropDown from './InterviewLocations';

const AddInterviewModal = () => {
  const defaultTime = DateTime.local().toJSDate();
  const defaultInterviewer = [
    {
      id: uniqueId(),
      name: '',
    },
  ];
  const [isAddingInterview, setIsAddingInterview] = useState(false);
  const [interviewEmailModal, setInterviewEmailModal] = useState(false);
  const [saveInterview, setSaveInterview] = useState(false);
  const [selectedInterviewer, setSelectedInterviewer] = useState<{ id: string; name: string; }[]>(defaultInterviewer);
  const { control, errors, register, handleSubmit } = useForm({
    defaultValues: {
      date: defaultTime,
      time: defaultTime,
    },
  });
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [location, setLocation] = useState<string | null>(null);
  const [date, setDate] = useState<Date>(defaultTime);
  const [time, setTime] = useState<Date>(defaultTime);

  const [emailType, setEmailType] = useState('');
  const addInterviewer = () => {
    setSelectedInterviewer([
      ...selectedInterviewer,
      {
        id: uniqueId(),
        name: '',
      },
    ]);
  };

  const appDispatch = useAppDispatch();

  const {
    payrollUsers,
    customInterviewEmails,
    interviewTemplates,
    active,
    createdInterviewFromTemplate,
  } = useAppSelector((state) => {
    return state.applicantTrackingV2;
  });
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  useEffect(() => {
    appDispatch(getPayrollUsers());
    appDispatch(getCustomInterviewEmails());
    appDispatch(getInterviewTemplates());
  }, []);
  const removeInterviewer = (id: string) => {
    setSelectedInterviewer(
      selectedInterviewer.filter((item) => {
        return item.id !== id;
      }),
    );
  };

  const clearStateValue = () => {
    setName('');
    setDescription('');
    setType('');
    setLocation(null);
    setEmailType('');
    setDate(defaultTime);
    setTime(defaultTime);
    setSelectedInterviewer(defaultInterviewer);
    setSendToApplicant(false);
    setSendToInterviewers(false);
    setAttachQuestions(false);
    setAttachResume(false);
    setSelectedTemplate(null);
  };

  const onHide = () => {
    setIsAddingInterview(false);
    clearStateValue();
  };
  const interviewerOptions = useMemo(() => {
    return (
      payrollUsers
        ?.map((user) => {
          return {
            value: user.secUserId,
            label: user.name,
          };
        })
        ?.filter((user) => {
          return !selectedInterviewer.find((item) => {
            return item.name === user.label;
          });
        }) || []
    );
  }, [payrollUsers, selectedInterviewer]);

  const emailTypeOptions = useMemo(() => {
    return (
      customInterviewEmails?.map((email) => {
        return {
          value: email.customEmailId,
          label: email.emailSubject,
        };
      }) || []
    );
  }, [customInterviewEmails]);

  const interviewTemplateOptions = useMemo(() => {
    return (
      interviewTemplates?.map((template) => {
        return {
          value: template.interviewTemplateId,
          label: template.title,
        };
      }) || []
    );
  }, [interviewTemplates]);
  const [sendToApplicant, setSendToApplicant] = useState(false);
  const [sendToInterviewers, setSendToInterviewers] = useState(false);
  const [attachResume, setAttachResume] = useState(false);
  const [attachQuestions, setAttachQuestions] = useState(false);
  const isSpecificationsDisabled = useMemo(() => {
    return !(
      sendToApplicant ||
      sendToInterviewers ||
      attachQuestions ||
      attachResume
    );
  }, [sendToApplicant, sendToInterviewers, attachQuestions, attachResume]);

  const wait = (ms: number) => {
    return new Promise((resolve) => {
      return setTimeout(resolve, ms, {});
    });
  };

  const sendInvitations = async () => {
    await submitInterview();

    await wait(1000);

    appDispatch(
      sendApplicantInterviewEmails({
        applicantId: active?.applicantId || 0,
        interviewId: createdInterviewFromTemplate?.interviewId || 0,
        params: {
          applicantId: active?.applicantId,
          interviewId: createdInterviewFromTemplate?.interviewId || 0,
          customEmailId: emailType,
          sendToApplicant,
          sendToInterviewers,
          attachResume,
          attachQuestions,
          date: date || '',
          time: time || '',
        },
      }),
    );
  };
  const submitInterview = async () => {
    const tempInterviewers = await Promise.all(selectedInterviewer.map((item) => {
      let tempUser: any = payrollUsers.filter((user) => {
        return user.name === item.name;
      })?.[0] || null;
      if (tempUser) {
        tempUser = {
          ...tempUser,
          applicantId: active?.applicantId || 0,
          interviewId: createdInterviewFromTemplate?.interviewId || 0,
          notes: tempUser?.notes || description || '',
          interviewerScorecards: tempUser?.interviewerScorecards || [],
        };
      }
      return tempUser;
    }));
    const interviewers = compact(tempInterviewers);
    if (!date || !time) {
      return null;
    }
    await appDispatch(
      updateApplicantInterview({
        applicantId: active?.applicantId || 0,
        interviewId: createdInterviewFromTemplate?.interviewId || 0,
        params: {
          interviewId: createdInterviewFromTemplate?.interviewId || 0,
          applicantId: active?.applicantId,
          ...createdInterviewFromTemplate,
          description: name || '',
          type: type || '',
          date: date || '',
          time: time || '',
          interviewers,
        },
      }),
    );
    setSaveInterview(false);
    setIsAddingInterview(false);
    clearStateValue();
  };

  if (!selectedTemplate && isAddingInterview) {
    return (
      <div className="d-flex justify-content-end mb-2">
        <Modal
          centered
          show
          onHide={onHide}
          title="Add Interview - Select a template"
          size="lg"
        >
          <Select
            styles={compStyles as StylesConfigType}
            options={interviewTemplateOptions}
            onChange={(option) => {
              const temp = interviewTemplates?.find((item: any) => {
                return item.interviewTemplateId === option.value;
              });
              if (temp?.interviewTypeId) {
                appDispatch(
                  createApplicantInterviewFromTemplate({
                    applicantId: active?.applicantId || 0,
                    interviewId: option.value,
                    params: { ...temp },
                  }),
                );
              }
              setSelectedTemplate(option);
              setSelectedInterviewer([
                {
                  id: uniqueId(),
                  name: '',
                },
              ]);
            }}
          />
        </Modal>
      </div>
    );
  }

  const onClikcSave = (e: any) => {
    e.preventDefault();
    setIsAddingInterview(false);
    setSaveInterview(true);
  };

  const disabledClick = !emailType?.toString()?.length ||
    !date ||
    !time ||
    !emailType ||
    isSpecificationsDisabled;

  return (
    <div className="d-flex justify-content-end mb-2">
      <AddInterviewConfirmationModal
        show={saveInterview}
        onAccept={() => {
          submitInterview();
        }}
        onHide={() => {
          return setSaveInterview(false);
        }}
      />
      <InterviewEmailsModal
        show={interviewEmailModal}
        onAccept={() => {
          return setInterviewEmailModal(false);
        }}
        onHide={() => {
          return setInterviewEmailModal(false);
        }}
      />
      <button
        type="button"
        style={{
          background: 'transparent',
          border: 'none',
        }}
        onClick={() => {
          setIsAddingInterview(true);
        }}
      >
        Add Interview <Icon name="plus-circle" />
      </button>
      <Modal
        centered
        show={isAddingInterview}
        onHide={onHide}
        title="Add Interview"
        size="lg"
      >
        <form
          onSubmit={onClikcSave}
        >
          <Row>
            <Col sm={8}>
              <h4 className="font-weight-bold">Details</h4>
              <Row>
                <Col sm={6}>
                  <InputGrp
                    label="Name"
                    name="name"
                    ref={register}
                    placeholder="Phone Interview"
                    value={name}
                    onChange={(e: any) => {
                      setName(e.target.value);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <InputGrp
                    label="Description"
                    name="description"
                    ref={register}
                    placeholder="Conference call"
                    value={description}
                    onChange={(e: any) => {
                      setDescription(e.target.value);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <InputGrp
                    label="Type"
                    name="type"
                    ref={register}
                    placeholder="Zoom Call"
                    value={type}
                    onChange={(e: any) => {
                      setType(e.target.value);
                    }}
                  />
                </Col>
                <Col
                  sm={6}
                  className="pt-3"
                >
                  <Label
                    id={'location'}
                    hasError={false}
                    text={'Location'}
                    required={true}
                  />
                  <InterviewLocationsDropDown
                    onChange={(e: any) => {
                      setLocation(e.value);
                    }}
                    value={location}
                  />
                </Col>
                <Col sm={3}>
                  <ControlDatePickerGrp
                    label="Date *"
                    name="date"
                    control={control}
                    errors={errors.date}
                    value={date}
                    onChange={(value: any) => {
                      setDate(value);
                    }}
                    rules={{
                      required: {
                        value: true,
                        message: 'Required',
                      },
                    }}
                  />
                </Col>
                <Col sm={3}>
                  <ControlTimePickerGrp
                    label="Time *"
                    name="time"
                    control={control}
                    errors={errors.date}
                    value={time}
                    onChange={(value: any) => {
                      setTime(value);
                    }}
                    rules={{
                      required: {
                        value: true,
                        message: 'Required',
                      },
                    }}
                  />
                </Col>
                <Col
                  sm={6}
                  className="d-flex align-items-center"
                >
                  <span>(*Date and time must be set)</span>
                </Col>
                <Col sm={12}>
                  <Label
                    text="Interview Email Specifications"
                    hasError={false}
                  />
                </Col>
                <Col sm={5}>
                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="sendToApplicant"
                      checked={sendToApplicant}
                      onChange={(e) => {
                        setSendToApplicant(e.target.checked);
                      }}
                      id="sendToApplicant"
                    />
                    <label
                      htmlFor="sendToApplicant"
                      className="mb-0 ml-1"
                    >
                      Send to Applicant
                    </label>
                  </div>
                </Col>
                <Col sm={7}>
                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="attachQuestion"
                      id="attachQuestion"
                      checked={attachQuestions}
                      onChange={(e) => {
                        setAttachQuestions(e.target.checked);
                      }}
                    />
                    <label
                      htmlFor="attachQuestion"
                      className="mb-0 ml-1"
                    >
                      Attach Question List for Interviewers
                    </label>
                  </div>
                </Col>
                <Col sm={5}>
                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="sendToInterviewer"
                      id="sendToInterviewer"
                      checked={sendToInterviewers}
                      onChange={(e) => {
                        setSendToInterviewers(e.target.checked);
                      }}
                    />
                    <label
                      htmlFor="sendToInterviewer"
                      className="mb-0 ml-1"
                    >
                      Send to Interviewers
                    </label>
                  </div>
                </Col>
                <Col sm={7}>
                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="attachResume"
                      id="attachResume"
                      checked={attachResume}
                      onChange={(e) => {
                        setAttachResume(e.target.checked);
                      }}
                    />
                    <label
                      htmlFor="attachResume"
                      className="mb-0 ml-1"
                    >
                      Attach Resume for Interviewers
                    </label>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={6}>
                  <Select
                    styles={compStyles as StylesConfigType}
                    placeholder="Email Type"
                    options={emailTypeOptions}
                    onChange={(option) => {
                      setEmailType(get(option, 'value', ''));
                    }}
                    ref={register}
                  />
                </Col>
                <Col
                  sm={6}
                  className="d-flex justify-content-end"
                >
                  <button
                    type="button"
                    disabled={disabledClick}
                    onClick={() => {
                      sendInvitations();
                      setInterviewEmailModal(true);
                    }}
                    className="btn btn-primary orange-outline-button"
                  >
                    Send Invitation Email
                  </button>
                </Col>
              </Row>
            </Col>
            <Col sm={4}>
              <InterviewTeam
                addInterviewer={addInterviewer}
                removeInterviewer={removeInterviewer}
                selectedInterviewer={selectedInterviewer}
                interviewerOptions={interviewerOptions}
                setSelectedInterviewer={setSelectedInterviewer}
              />
            </Col>
          </Row>
          <hr />
          <div className="d-flex align-items-center justify-content-end">
            <button
              type="button"
              className="btn btn-primary orange-outline-button mr-2 mt-2"
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              disabled={disabledClick}
              type="submit"
              className="btn btn-primary orange-button mr-2 mt-2"
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default AddInterviewModal;
