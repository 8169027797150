import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputDateSelector from 'core/components/form-controls/InputDateSelector';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { ClientAccess } from 'core/models/ClientAccess';
import { TaxReportRequest } from 'core/models/PayrollReports.model';
import { clearTaxReport, downloadTaxReport } from 'core/store/actions/payoll-reports.action';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import _ from 'lodash';
import NestedCheckboxList, { LeafNode, RootNode } from 'core/components/shared/NestedCheckboxList';

interface OptionNode extends LeafNode {
  federalId: string;
}
interface ParentOption extends RootNode {
  leafNodes: OptionNode[];
}

const TaxReport = () => {
  const dispatch = useAppDispatch();
  
  const childRef: React.RefObject<any> = useRef();

  const [reportDates, setReportDates] = useState<string[]>([]);
  const [clientOpts, setClientOpts] = useState<ParentOption[]>();
  const [selectedClients, setSelectedClients] = useState<ParentOption[]>();

  const clientEntityId = useAppSelector((state) => {return state?.client?.client?.clientEntityID;});
  const report = useAppSelector((state) => {return state?.payrollReports?.taxReport;});
  const clients = useAppSelector((state) => {return state?.auth?.availableClients;});

  const { handleSubmit } = useForm<TaxReportRequest>();
  
  // we'll probably want this to be a reusable utility function at some point.
  const transformClientOptionData = () => {
    const options: OptionNode[] = clients
      ?.filter((x: ClientAccess) => x.clientEntityId === clientEntityId)
      ?.sort((a: ClientAccess, b: ClientAccess) => a.clientNo > b.clientNo ? 1 : -1)
      ?.map((c: ClientAccess) => ({
        id: c.clientNo,
        description: `${c.clientNo} - ${c.clientName}`,
        federalId: `Federal ID - ${c.federalID.substring(0, 2)}-${c.federalID.substring(2)}`,
        checked: false,
      }));
    
    if (!options?.length) return console.error('No client options to generate');
    // TODO: JS's native Object.groupBy() is still experimental. Replace this once it's standard
    const groupByOpts: _.Dictionary<OptionNode[]> = _.groupBy(options, 'federalId');
    const optArray: ParentOption[] = [];
    
    for (const [k, v] of Object.entries(groupByOpts)) {
      optArray.push({
        id: k,
        leafNodes: v,
        checked: false,
      });
    }
    
    setClientOpts(optArray);
  };
  
  useEffect(() => {
    transformClientOptionData();
  }, [clients]);

  const hidePdfViewer = () => {
    dispatch(clearTaxReport());
  };

  const downloadReport = (_data: TaxReportRequest) => {
    if (!selectedClients?.length) return;
    
    const mergedIds = selectedClients.flatMap(({ leafNodes }) =>
      leafNodes
        .filter(({ checked }) => checked)
        .map(({ id }) => id),
    );
    const submitData: TaxReportRequest = {
      clientNos: mergedIds,
      dateFrom: reportDates[0],
      dateTo: reportDates[1],
    };
    dispatch(downloadTaxReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  return (
    <div className="dm-panel dm-panel-border">
      <form onSubmit={handleSubmit(downloadReport)}>
        <div className="dm-grid-title">Tax Report</div>
        <hr className="dm-panel-hr" />
        <div className="d-flex flex-row flex-grow-1">
          <InputDateSelector
            ref={childRef}
            returnDates={(dates: string[]) => {return setReportDates(dates);}}
            hideDateTypes={true}
          />
          <div className="flex-grow-1 text-right">
            <button
              type="button"
              className="orange-outline-button mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="orange-button mr-2"
              disabled={!reportDates.length}
            >
              Run Report
            </button>
          </div>
        </div>
        <div className="d-flex flex-column flex-grow-1">
          <div className="dm-grid-action-title mb-2">Report Options</div>
          {clientOpts?.length ? (
            <NestedCheckboxList<ParentOption>
              options={clientOpts}
              onSelect={(selectedList) => {return setSelectedClients(selectedList);}}
            />
          ) : null}
        </div>
      </form>
      {
        report ? <PDFViewerModal
          show={report.length > 0
          }
          pdfData={report}
          onHide={hidePdfViewer}
          reportName="TaxReport"
        /> : null
      }
    </div>
  );
};

export default TaxReport;