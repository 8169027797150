import React, { useState, useEffect, CSSProperties } from 'react';
import { orderBy } from 'lodash';
import { ProgressBar } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PanelHeader from 'core/components/shared/PanelHeader';
import { EmployeeMasterDtoTaxTaxEntity, EmployeeTaxInfo } from 'core/models';
import { clearTaxMessages, loadTaxes, updateTaxes } from 'core/store/actions/tax.action';
import { getTaxes, getTaxMessages } from 'core/store/selectors/tax.selector';
import TaxComponent from './TaxComponent';
import { RadioGrp } from 'core/components/form-controls';
import { UNSAVED_MESSAGE } from 'core/constants';
import { TaxState } from 'core/store/reducers/tax.reducer';
import { loadPayrollInfo, loadYearToDate, toggleBlockNavigation } from 'core/store/actions';
import { getPayrollInfoRaw, getYTD } from 'core/store/selectors';
import { getAccess } from 'utilities/utilities';
import { useAppSelector } from 'utilities/hooks';

const currentYear = new Date().getFullYear(); 

export type FieldArray = {
  taxes: EmployeeMasterDtoTaxTaxEntity[];
};

type AlertProps = {
  field: string;
  handleReset: () => void;
};

type RadioButtonVals = 'hasFICA' | 'hasFUTA' | 'hasSUI';

export const radioStyles: CSSProperties = {
  margin: '5px 10px',
};

const resetButtonStyles: CSSProperties = {
  fontSize: '14px',
};

// currently just for SUI and W/H
const CheckboxAlert = ({ field, handleReset }: AlertProps) => {
  return (
    <div className="alert mt-2 alert-danger d-flex align-items-center">
      No {field} state is currently selected.
      <strong>&nbsp;Please select a {field} state&nbsp;</strong> to save your changes 
      <strong>&nbsp;or click &quot;Reset&quot;&nbsp;</strong>to revert all unsaved changes.
      <button
        className="btn btn-link py-0"
        style={resetButtonStyles}
        onClick={handleReset}
      >
        <strong>Reset</strong>
      </button>
    </div>
  );
};

const TaxPage = () => {
  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();

  const taxes: EmployeeTaxInfo | null = useSelector(getTaxes);
  const taxMessages: TaxState = useSelector(getTaxMessages);
  const payrollInfo = useSelector(getPayrollInfoRaw);
  const ytd = useSelector(getYTD);
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  
  const empHasYtdEarnings = (ytd?.ytdEarnings
    ?.map((earning) => earning.earnings)
    ?.reduce((prev, current) => prev + current, 0) ?? 0) > 0;
  
  const empHasYtdFedTaxes = (ytd?.ytdTaxes
    ?.find((tax) => tax.type === 'Federal')?.wages ?? 0) > 0; 

  const [zeroSUI, setZeroSUI] = useState(false);
  const [zeroWH, setZeroWH] = useState(false);
  const [submittedData, setSubmittedData] = useState<EmployeeTaxInfo>();
  const [taxItems, setTaxItems] = useState<EmployeeMasterDtoTaxTaxEntity[]>([]);
  const [radioButton, setRadioButton] = useState<Record<RadioButtonVals, boolean>>({
    hasFICA: !!taxes?.hasFICA,
    hasSUI: !!taxes?.hasSUI,
    hasFUTA: !!taxes?.hasFUTA,
  });

  const dispatch = useDispatch();

  const {
    control,
    register,
    errors,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { isDirty, dirtyFields, isSubmitSuccessful, errors: formStateErrors },
  } = useForm<FieldArray>({
    defaultValues: {
      taxes: [],
    },
  });
  
  useEffect(() => {
    return () => {
      dispatch(clearTaxMessages());
    };
  }, []);
  
  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(loadTaxes({ protectedEmpNo }));
      dispatch(loadPayrollInfo(protectedEmpNo));
      dispatch(clearTaxMessages());
      setZeroSUI(false);
      setZeroWH(false);
      dispatch(
        loadYearToDate({
          empNo: protectedEmpNo,
          prYear: currentYear,
        }),
      );
    }
  }, [protectedEmpNo]);

  useEffect(() => {
    if (taxes) {
      setRadioButton({
        hasFICA: taxes.hasFICA,
        hasSUI: taxes.hasSUI,
        hasFUTA: taxes.hasFUTA,
      });
    }
  }, [taxes, protectedEmpNo]);

  useEffect(() => {
    if (!taxes?.taxEntityDetails) return;
    const { taxEntityDetails } = taxes;
    setTaxItems([...taxEntityDetails].sort((a, b) => {return b.year - a.year;}));
  }, [taxes]);

  useEffect(() => {
    reset({
      taxes: orderBy(taxItems, ['entityId', 'year'], ['asc', 'desc']),
    });
  }, [taxItems]);

  useEffect(() => {
    if (taxMessages.messages.length > 0 && taxMessages.isSuccess) {
      setTimeout(() => {
        dispatch(clearTaxMessages());
      }, 5000);
    }
  }, [taxMessages]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        taxes: submittedData?.taxEntityDetails,
      });
    } else {
      reset({
        taxes: orderBy(taxItems, ['entityId', 'year'], ['asc', 'desc']),
      });
    }
  }, [isSubmitSuccessful, reset, submittedData]);

  const handleChange = (e: React.BaseSyntheticEvent) => {
    setRadioButton((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value === 'true',
      };
    });
  };

  const onSubmit = (data: any) => {
    setSubmittedData(data);
    dispatch(
      updateTaxes({
        protectedEmpNo,
        employeeMasterDtoTaxTaxEntity: {
          ...data,
          hasFICA: data.hasFICA === 'true',
          hasFUTA: data.hasFUTA === 'true',
          hasSUI: data.hasSUI === 'true',
          taxEntityDetails: data.taxes,
        },
      }),
    );
  };
  
  const handleStateReset = () => {
    setZeroSUI(false);
    setZeroWH(false);
    reset({
      taxes: orderBy(taxItems, ['entityId', 'year'], ['asc', 'desc']),
    });
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: (dirtyFields?.taxes?.length ?? 0) > 0,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [(dirtyFields?.taxes?.length ?? 0) > 0]);

  return (
    <div className="dm-panel dm-panel-border">
      {taxMessages.loading && (
        <>
          <h1>Loading...</h1>
          <ProgressBar
            animated
            now={100}
          />
        </>
      )}
      <PanelHeader title="Tax Information"></PanelHeader>
      <div className=" dm-panel dm-panel-border d-flex flex-row flex-wrap">
        <RadioGrp
          {...getAccess(sectionAccess, 6, undefined, { disabledDefault: payrollInfo?.is1099 || empHasYtdEarnings || empHasYtdFedTaxes, disabledSameAsReadOnly: true })}
          name="hasFICA"
          label="FICA"
          checked={radioButton?.hasFICA}
          controlled={true}
          onChange={handleChange}
          groupStyle={radioStyles}
          ref={register}
        />
        <RadioGrp
          {...getAccess(sectionAccess, 6, undefined, { disabledDefault: payrollInfo?.is1099 || empHasYtdEarnings || empHasYtdFedTaxes, disabledSameAsReadOnly: true })}
          name="hasSUI"
          label="SUI"
          checked={radioButton?.hasSUI}
          controlled={true}
          onChange={handleChange}
          groupStyle={radioStyles}
          ref={register}
        />
        <RadioGrp
          {...getAccess(sectionAccess, 6, undefined, { disabledDefault: payrollInfo?.is1099 || empHasYtdEarnings || empHasYtdFedTaxes, disabledSameAsReadOnly: true })}
          name="hasFUTA"
          label="FUTA"
          checked={radioButton?.hasFUTA}
          controlled={true}
          onChange={handleChange}
          groupStyle={radioStyles}
          ref={register}
        />
      </div>
      {taxMessages.messages.length > 0 && (
        <div
          className={`alert mt-2 ${
            taxMessages.isSuccess ? 'alert-success' : 'alert-danger'
          }`}
        >
          <ul>
            {taxMessages.messages.map((a, index) => {
              return (
                <li key={index}>{a}</li>
              );
            })}
          </ul>
        </div>
      )}
      {zeroSUI && radioButton.hasSUI ? (
        <CheckboxAlert
          field="SUI"
          handleReset={handleStateReset}
        />
      ) : null}
      {zeroWH ? (
        <CheckboxAlert
          field="withholding"
          handleReset={handleStateReset}
        />
      ) : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <TaxComponent
          setZeroSUI={setZeroSUI}
          setZeroWH={setZeroWH}
          hasSUI={radioButton?.hasSUI}
          control={control}
          errors={errors}
          formStateErrors={formStateErrors}
          register={register}
          getValues={getValues}
          setValue={setValue}
          taxes={taxes}
        />
        <div className="row justify-content-end">
          <div className="col-auto">
            <button
              {...getAccess(sectionAccess, 4, undefined, { disabledDefault: taxMessages.loading || !isDirty || (zeroSUI && radioButton.hasSUI) || zeroWH, disabledSameAsReadOnly: true })}
              type="submit"
              className="btn orange-button-sm m-3"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TaxPage;
