import { TransmittalDeduction } from 'core/models/Payroll.model';
import { useSelector } from 'react-redux';
import React, { useContext, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import {  getEmployeeFromEmpNo } from 'core/store/selectors';
import { useFieldArray } from 'react-hook-form';
import { useAppDispatch } from 'utilities/hooks';
import { selectedEmployeeModal } from 'core/store/actions';
import Icon from 'core/components/shared/Icon';
import { FormMethods } from 'core/components/form-controls/types';
import { ManualChangeArgs } from './CheckItem';
import { FormContext } from './FormContext';
import TransmittalDeductionsItem from './TransmittalDeductionsItem';

type PropTypes = {
  checkIndex: number;
  empNo: number;
  errors: FormMethods['errors'];
  register: FormMethods['register'];
  setValue: FormMethods['setValue'];
  watch: FormMethods['watch'];
  isDirty: boolean;
  isReadOnly: boolean;
  control: any;
  saveChanges: (unregisteredInput?: boolean, args?: ManualChangeArgs) => Promise<any> | undefined;
  addingDeduction: boolean;
};

const TransmittalDeductionsItemList: React.FC<PropTypes> = ({
  checkIndex,
  empNo,
  errors,
  register,
  setValue,
  isDirty,
  isReadOnly,
  control,
  saveChanges,
  addingDeduction,
  watch,
}) => {
  const dispatch = useAppDispatch();
  
  const {
    dirtyCheck,
    manualSaveTriggerCallback,
    updateActiveElement,
  } = useContext(FormContext);
  
  const employee = useSelector(getEmployeeFromEmpNo(empNo));
  
  const [deductionIndexEdit, setDeductionIndexEdit] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  
  const { append, fields, remove } = useFieldArray<TransmittalDeduction>({
    control,
    name: 'deductions',
  });
  
  const appendDeduction = () => {
    setOpen(true);
    append({
      transmittalDeductionId: -1,
      dedId: 0,
      dedAmount: 0,
      dept: employee?.dept ?? 0,
      subDept: employee?.subDept ?? 0,
      subDept2: employee?.subDept2 ?? 0,
    });
    
    // set active element to new deduction's dropdown
    setTimeout(() => {
      updateActiveElement('dedNo-0');
      document.getElementById('dedNo-0')?.focus();
    }, 0);
  };
  
  const onAddDeduction = () => {
    // if there's a dirty check save it before updating the local state, else just update local state as normal
    if (dirtyCheck) {
      saveChanges(true)
        ?.then(() => {
          // do this last (in case there's a timing issue with the reset function)
          setTimeout(() => {
            appendDeduction();
          }, 0);
        });
    } else {
      appendDeduction();
    }
  };
  
  const curriedRemove = (id: string | undefined) => {
    if (!id) return console.error('ID undefined');
    
    const matchedFieldIndex = fields?.findIndex((field) => { return field.id === id; });
    
    if (matchedFieldIndex < 0) return console.error('Could not delete. FIeld not found');
    
    remove(matchedFieldIndex);
    updateActiveElement(null);
    
    setTimeout(() => {
      saveChanges(true);
    }, 0);
  };

  const setSelectedEmployee = () => {
    if (isDirty) {
      manualSaveTriggerCallback(true);
    }
    dispatch(
      selectedEmployeeModal({
        empNo,
        checkIndex,
      }),
    );
  };

  return (
    <>
      <div className="row py-0 w-80">
        <div className="col mx-0">
          <button
            type="button"
            className="btn btn-link"
            style={{ fontSize: '14px' }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            View Scheduled Deductions
          </button>
        </div>
        {!isReadOnly ? <div className="col mx-0">
          <button
            type="button"
            className="btn btn-link dm-grid-action-title"
            disabled={isReadOnly}
            onClick={onAddDeduction}
          >
            Add Deduction{' '}
            <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </div> : null}
        <div className="col mx-0">
          <button
            type="button"
            className="btn btn-link"
            style={{ fontSize: '14px' }}
            onClick={setSelectedEmployee}
          >
            Pay Details
          </button>
        </div>
      </div>
      <Collapse in={open}>
        <div className="row">
          <div className="offset-3 col-sm-3">Deduction Type</div>
          <div className="col-sm-3">Amount</div>
          <div className="offset-3 col">
            {fields.map((deduction, deductionIndex) => {
              const editableDeductionIndex = !!(deductionIndexEdit.find(x => { return x === deduction.id; }));
              deduction.overridedDeduction = editableDeductionIndex;

              return (
                <TransmittalDeductionsItem
                  key={deduction.id}
                  deduction={deduction}
                  deductionIndex={deductionIndex}
                  editableDeductionIndex={editableDeductionIndex}
                  isReadOnly={isReadOnly}
                  addingDeduction={addingDeduction}
                  deductionIndexEdit={deductionIndexEdit}
                  setDeductionIndexEdit={setDeductionIndexEdit}
                  curriedRemove={curriedRemove}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  saveChanges={saveChanges}
                  watch={watch}
                />
              );
            })}
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default TransmittalDeductionsItemList;
