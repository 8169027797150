import { createAction } from '@reduxjs/toolkit';
import {
  CorrectionsGetRequest,
  EEOInfoCorrection,
  DeptAllocationCorrectionsGetRequest,
  UpdateEEOInfoRequest,
  UpdateWorkersCompRequest,
  WorkersCompCorrection,
  DeptAllocationDTO,
  DeptAllocation,
  DeptAllocationWithOptionalId,
  MescEarnDeptAllocPostRequest,
  MescEarnDeptAllocDeleteRequest,
  UpdateRequest,
  AllocationEmployee,
  CorrectionsReportRequest,
} from 'core/models';

export const getEEOInfoCorrections = createAction<CorrectionsGetRequest>('[corrections] GET_EEO_INFO_CORRECTIONS');
export const storeEEOInfoCorrections = createAction<EEOInfoCorrection[]>('[corrections] STORE_EEO_INFO_CORRECTIONS');
export const putEEOInfoCorrections = createAction<UpdateEEOInfoRequest>('[corrections] PUT_EEO_INFO_CORRECTIONS');

export const getWorkersCompCorrections = createAction<CorrectionsGetRequest>('[corrections] GET_WORKERS_COMP_CORRECTIONS');
export const storeWorkersCompCorrections = createAction<WorkersCompCorrection[]>('[corrections] STORE_WORKERS_COMP_CORRECTIONS');
export const putWorkersCompCorrections = createAction<UpdateWorkersCompRequest>('[corrections] PUT_WORKERS_COMP_CORRECTIONS');

export const postMescEarnCorrection = createAction<UpdateRequest<MescEarnDeptAllocPostRequest> & { mescId: number }>('[corrections] POST_MESC_EARN_CORRECTION');
export const deleteMescEarnCorrection = createAction<MescEarnDeptAllocDeleteRequest>('[corrections] DELETE_MESC_EARN_CORRECTION');

export const loadDeptAllocationEmployees = createAction('[corrections] LOAD_DEPT_ALLOCATION_EMPLOYEES');
export const storeDeptAllocationEmployees = createAction<AllocationEmployee[]>('[corrections] STORE_DEPT_ALLOCATION_EMPLOYEES');

export const getDeptAllocationCorrections = createAction<DeptAllocationCorrectionsGetRequest>(
  '[corrections] GET_DEPT_ALLOCATION_CORRECTIONS',
);
export const postDeptAllocationCorrections = createAction<UpdateRequest<DeptAllocationWithOptionalId[]> & { showSuccessMessage: boolean }>(
  '[corrections] POST_DEPT_ALLOCATION_CORRECTIONS',
);
export const storeDeptAllocationCorrections = createAction<DeptAllocationDTO>(
  '[corrections] STORE_DEPT_ALLOCATION_CORRECTIONS',
);
export const storeDeptChangeCorrections = createAction<DeptAllocation[]>(
  '[corrections] STORE_DEPT_CHANGE_CORRECTION',
);

export const loadDeptAllocationReport = createAction<CorrectionsReportRequest>(
  '[corrections] LOAD_ALLOCATION_REPORT',
);
export const storeDeptAllocationReport = createAction<string>('[corrections] STORE_ALLOCATION_REPORT');

export const storeReportRequestData = createAction<Omit<CorrectionsReportRequest, 'allocationReportType'> | null>(
  '[corrections] STORE_REPORT_REQUEST_DATA',
);

export const clearCorrections = createAction('[corrections] CLEAR_CORRECTIONS');