import React, { createContext, Reducer, useEffect, useReducer, useState } from 'react';
import SummaryActionBar from './SummaryActionBar';
import styles from './styles.module.scss';
import '../time-card-styles.scss';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { addUpdateTimeCards, getAllCostCodes, getAllSubs, getHomeAtsInfo, getTimeCardCrewSheetColumns, handleError, loadEmployees } from 'core/store/actions';
import SummaryGroup from './SummaryGroup';
import Icon from 'core/components/shared/Icon';
import AddTimeSheetModal from './AddTimeSheet.modal';
import ColumnSettingsModal from './ColumnSettings.modal';
import { TimeCard, TimeCardDeduction, TimeCardDetail, TimeCardTotals } from 'core/models/Contractor.model';
import { getCurrentCheckCodes, getNextCheckCode, getTimeCardsTotals, validTimeCard } from '../utilities';
import { ArrayField, FieldValues, UseFieldArrayMethods, UseFormMethods, useFieldArray, useForm } from 'react-hook-form';
import { Employee, HttpResponse } from 'core/models';
import { cleanAmount, generateNegativeId } from 'utilities/utilities';
import { UNSAVED_MESSAGE } from 'core/constants';
import { TimeCardSummaryAction, summaryReducer } from './summary.reducer';
import { Prompt } from 'react-router-dom';

// #region Types
export type TimeSheetSummaryFilter = {
  dayOfWeek: number;
  jobNo: string;
  subNo: string;
  foExtra: string;
  phase: string;
  costCode: string;
  triggerReRender: boolean;
  query: string;
};

export type FieldArrayMethods<T extends FieldValues> = UseFieldArrayMethods<T, 'id'>;
export type TimeCardArrayField = Partial<ArrayField<TimeCard, 'id'>>;

type FormType = {
  timeCardItems: TimeCard[];
};

type TimeCardContextType = {
  updateTimeCardState: (action: TimeCardSummaryAction) => void;
};
// #endregion

export const TimeSheetSummaryContext = createContext<TimeCardContextType>({
  updateTimeCardState: (_action: TimeCardSummaryAction) => {},
});

const TimeSheetSummary = () => {
  // #region Hooks
  //Selected Information From State
  const employees = useAppSelector(({ employees }) => employees.employees);
  const clientNo = useAppSelector(({ client }) => client.client?.clientNo);
  const { timeCards, timeCardDates, currentTimeCard, timeCardCrewSheetColumns, payrollInfo } = useAppSelector(({ contractor }) => contractor);
  const controlTotalFromStore = useAppSelector((state) => { return state.payroll.payrollControlTotal; });

  //Local State Variables
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [showColSettings, setShowColSettings] = useState<boolean>(false);
  const [showAddTimeSheet, setShowAddTimeSheet] = useState<boolean>(false);
  const [timeSheetSummaryFilters, setTimeSheetSummaryFilters] = useState<TimeSheetSummaryFilter>({
    dayOfWeek: timeCardDates[0]?.dateId ?? -1,
    jobNo: '<All>',
    subNo: '<All>',
    foExtra: '<All>',
    phase: '<All>',
    costCode: '<All>',
    triggerReRender: false,
    query: '',
  });
  const [totals, setTotals] = useState<TimeCardTotals>({ regularTotal: 0, timeAndAHalfTotal: 0, doubleTotal: 0, otherEarningsHoursTotal: 0 });
  
  // using a reducer since this state is layered like an onion (or ogre)
  const [timeCardState, dispatchTimeCardUpdate] = useReducer<Reducer<TimeCard[], TimeCardSummaryAction>>(summaryReducer, timeCards);

  const dispatch = useAppDispatch();

  //How is a null record getting stored?!?!?!
  const timeCardCopy = structuredClone(timeCards);
  const extraTimeCardCheck = timeCardCopy?.filter(x => x !== null) || [];
  //We want to sort by the employee # then the Check Code before default setting the form
  const sortedTimeCard = structuredClone(extraTimeCardCheck)?.sort((a, b) => (a?.empNo - b?.empNo || +a?.checkCode - +b?.checkCode) ?? []);
  
  useEffect(() => {
    dispatch(loadEmployees());
    dispatch(getTimeCardCrewSheetColumns());
    dispatch(getAllSubs());
    dispatch(getAllCostCodes());
  }, []);

  //Gets the totals for the employee header total line.
  useEffect(() => {
    setTotals(getTimeCardsTotals(timeCardState.filter(filterByQuery(timeSheetSummaryFilters.query)) as TimeCard[], timeSheetSummaryFilters));
  }, [timeSheetSummaryFilters, timeCardState]);
  // #endregion

  // #region Functions
  const updateTimeCardState = (action: TimeCardSummaryAction) => {
    dispatchTimeCardUpdate(action);
    setIsDirty(true);
  };
  
  const updateFilters = (newFilters: TimeSheetSummaryFilter) => {
    setTimeSheetSummaryFilters(newFilters);
  };
  
  const addTimeCard = (employee: Employee) => {
    if (!payrollInfo) return dispatch(handleError('Error adding new time card'));

    const currentCheckCodes = getCurrentCheckCodes(timeCardState, employee.empNo)?.map((x) => isNaN(+x) ? 0 : +x);
    if (currentCheckCodes?.length >= 10) return dispatch(handleError('Employee already has 10 time cards on this payroll'));
    
    const nextCheckCode = getNextCheckCode(currentCheckCodes);
    if (nextCheckCode === '-1') return dispatch(handleError('Employee already has 10 time cards on this payroll'));

    dispatch(getHomeAtsInfo({ protectedEmpNo: employee.protectedEmpNo, effectiveDate: payrollInfo.weekEnd }));

    const newTimeCard: TimeCard = {
      clientNo: employee.clientNo,
      transmittalTimeCardId: generateNegativeId(),
      payrollHistoryId: currentTimeCard?.payrollHistoryId || 0,
      weekEnd: currentTimeCard?.weekEnd || new Date(),
      checkDate: currentTimeCard?.checkDate || new Date(),
      empId: employee.empId,
      empNo: employee.empNo,
      firstName: employee.firstName,
      midName: employee.midName,
      lastName: employee.lastName,
      suffix: employee.suffix,
      checkCode: nextCheckCode.toString(),
      blockFica: false,
      frequency: '',
      approved: false,
      approvedBy: '',
      approvedDate: new Date(),
      deductions: [],
      details: [],
      withholdings: [],
    };
    
    let insertIndex = timeCardState.findIndex(x => (x?.empNo ?? 0) >= newTimeCard.empNo);
    insertIndex = insertIndex + (+nextCheckCode);
    
    updateTimeCardState({ type: 'ADD_TIME_CARD', newTimeCard, insertIndex });
  };
  
  const addEmployees = (employeesToAdd: Employee[]) => {
    employeesToAdd.forEach(x => addTimeCard(x));
  };
  
  const removeTimeCard = (timeCardId: number) => {
    updateTimeCardState({ type: 'DELETE_TIME_CARD', timeCardId });
  };
  
  //This will format the formData to ensure it is formatted properly for the request
  const saveTimeCard = (timeCard: TimeCard): TimeCard | null => {
    const employee = employees.find(x => x.empNo === timeCard.empNo);
    if (!(payrollInfo && employee && clientNo)) {
      dispatch(handleError('Cannot save time card. Please try again'));
      return null;
    }

    const deductions: TimeCardDeduction[] = timeCard?.deductions?.map((deduction) => ({
      ...deduction,
      transmittalTimeCardDeductionId: +(deduction?.transmittalTimeCardDeductionId ?? 0),
      amount: parseFloat(cleanAmount(deduction?.amount ?? '0')),
    }));

    const details: TimeCardDetail[] = timeCard?.details?.map((detail) => ({
      ...detail,
      additionalRate: parseFloat(cleanAmount(detail?.additionalRate ?? '0')),
      cityEntityId: +detail?.cityEntityId,
      hours: detail?.hours?.filter(x => x.hours > 0)?.map((hour) => ({
        ...hour,
        hours: parseFloat(cleanAmount(hour?.hours ?? '0')),
        earningsCode: detail?.hours[0]?.earningsCode,
      })),
      otherEarnings: detail?.otherEarnings?.map((earning) => ({
        ...earning,
        transmittalTimeCardDetailOtherEarningsId: earning.transmittalTimeCardDetailOtherEarningsId < 0 ? 0 : earning.transmittalTimeCardDetailOtherEarningsId,
        amount: parseFloat(cleanAmount(earning?.amount ?? '0')),
        recurringEarningId: String(earning?.recurringEarningId) === '' || !earning?.recurringEarningId ? null : +(earning.recurringEarningId),
      })),
    }));

    const submitData: TimeCard = {
      ...timeCard,
      payrollHistoryId: payrollInfo.payrollHistoryId,
      clientNo: clientNo,
      weekEnd: new Date(payrollInfo.weekEnd),
      checkDate: new Date(payrollInfo.checkDate),
      empId: employee.empId,
      empNo: employee.empNo,
      firstName: employee.firstName,
      midName: employee.midName,
      lastName: employee.lastName,
      suffix: employee.suffix,
      approved: true,
      approvedBy: '',
      approvedDate: new Date(),
      details: details ?? [],
      deductions: deductions ?? [],
      withholdings: timeCard.withholdings ?? [],
    };
    
    return submitData;
  };

  const onSave = () => {
    //1. Get the form data and make sure the payrollInfo is set (This is stored when selecting a payroll and has the PayrollHistoryId, checkDate and weekEnd)
    if (!(payrollInfo && controlTotalFromStore)) return dispatch(handleError('Cannot save time card. Please try again'));

    //2. For each timeCard format it to the proper DTO and then add it to the list of timeCardsToSave
    const timeCardsToSave: TimeCard[] = [];
    for (let i = 0; i < timeCardState.length; i++) {
      const timeCard = saveTimeCard(timeCardState[i]);
      if (timeCard) timeCardsToSave.push(timeCard);
    }

    //3. Save all timecards at once including any deleted ones that were excluded and then reset the state.
    dispatch(addUpdateTimeCards({
      controlTotalId: controlTotalFromStore.controlTotalId,
      payrollHistoryId: payrollInfo.payrollHistoryId,
      data: timeCardsToSave,
    })).then((res) => {
      const data = res.payload as HttpResponse<TimeCard[]>;
      const _sortedTimeCard = structuredClone(data.value)?.sort((a, b) => (a?.empNo - b?.empNo || +a?.checkCode - +b?.checkCode)) ?? [];
      
      updateTimeCardState({ type: 'RESET_STATE', newState: _sortedTimeCard });
      setTimeSheetSummaryFilters({
        dayOfWeek: timeCardDates[0]?.dateId ?? -1,
        jobNo: '<All>',
        subNo: '<All>',
        foExtra: '<All>',
        phase: '<All>',
        costCode: '<All>',
        triggerReRender: false, // wtf does this do?
        query: '',
      });
      setIsDirty(false);
    });
  };
  
  const filterByQuery = (query: string) => (timeCard: TimeCardArrayField): boolean => {
    return !!(timeCard.firstName?.toLowerCase()?.includes(query.toLowerCase().trim())
      || timeCard.lastName?.toLowerCase()?.includes(query.toLowerCase().trim())
      || String(timeCard.empNo).startsWith(query.trim()));
  };
  // #endregion
  
  // #region Render
  return (
    <>
      <Prompt
        when={isDirty}
        message={UNSAVED_MESSAGE}
      />
      {showColSettings && (
        <ColumnSettingsModal
          show={showColSettings}
          onHide={() => { setShowColSettings(false); }}
          timeCardCrewSheetColumns={timeCardCrewSheetColumns}
        />
      )}
      {showAddTimeSheet && (
        <AddTimeSheetModal
          emps={employees}
          show={showAddTimeSheet}
          addEmployees={addEmployees}
          onHide={() => { setShowAddTimeSheet(false); }}
        />
      )}
      <main className={styles['main-content']}>
        <TimeSheetSummaryContext.Provider value={{ updateTimeCardState }}>
          <div className="d-flex justify-content-between align-items-end flex-wrap">
            <h1 className="dm-page-title mb-auto">Time Sheet Summary</h1>
          </div>
          <hr className="dm-page-hr" />
          <div className="d-flex">
            <div
              className="d-flex flex-wrap"
              style={{ gap: '4px' }}
            >
              <SummaryActionBar
                filters={timeSheetSummaryFilters}
                setFilters={updateFilters}
              />
              <div className="head-section mr-2">
                <strong>Totals</strong>
                <div>
                  <strong>Regular:&nbsp;</strong>{totals?.regularTotal?.toFixed(2)}
                </div>
                <div>
                  <strong>Time and A Half:&nbsp;</strong>{totals?.timeAndAHalfTotal?.toFixed(2)}
                </div>
                <div>
                  <strong>Double:&nbsp;</strong>{totals?.doubleTotal?.toFixed(2)}
                </div>
                <div>
                  <strong>Other hours:&nbsp;</strong>{totals?.otherEarningsHoursTotal?.toFixed(2)}
                </div>
              </div>
            </div>
            <div
              className="d-flex w-25 justify-content-end"
              style={{ minWidth: '300px' }}
            >
              <div className={styles['action-bar-item']}>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => { setShowColSettings(true); }}
                >
                  Hide Columns&nbsp;
                  <Icon name="eye-slash" />
                </button>
              </div>
              <div className={styles['action-bar-item']}>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => { setShowAddTimeSheet(true); }}
                >
                  Add Time Sheet&nbsp;
                  <Icon
                    name="plus-circle"
                    className="fa-plus-circle"
                  />
                </button>
              </div>
              <div className="text-right">
                <button
                  disabled={!isDirty}
                  className="btn orange-button mr-3"
                  onClick={onSave}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div
            className="dm-panel-border"
            style={{
              height: 'calc(75vh - 50px)',
              overflowY: 'auto',
            }}
          >
            <ul className={styles['page-body-list']}>
              {employees && timeCardState
                .filter(filterByQuery(timeSheetSummaryFilters.query))
                .map((timeCard: TimeCard, index) => {
                  return (
                    <SummaryGroup 
                      key={timeCard.transmittalTimeCardId}
                      employee={employees.find(x => x.empNo === timeCard?.empNo || 0) }
                      timeCard={timeCard}
                      timeCardIndex={index}
                      filters={timeSheetSummaryFilters}
                      removeTimeCard={removeTimeCard}
                      hidden={!validTimeCard(timeCardState[index], timeSheetSummaryFilters)}
                      setFilters={setTimeSheetSummaryFilters}
                    />
                  );
                })}
            </ul>
          </div>
        </TimeSheetSummaryContext.Provider>
      </main>
    </>
  );
  // #endregion
};

export default TimeSheetSummary;