import { InputGrp } from 'core/components/form-controls';
import PanelHeader from 'core/components/shared/PanelHeader';
import { WireOnlyAgreement } from 'core/models/Payroll.model';
import { clearWireOnlyAgreementReport, deleteWireOnlyAgreement, downloadWireOnlyAgreementReport, toggleWireOnlyAgreementPreviewSuccess, updateWireOnlyAgreement } from 'core/store/actions';
import React, { Dispatch, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector, } from 'utilities/hooks';
import { useSelector } from 'react-redux';
import { currencyFormatter } from 'utilities/utilities';
import './payroll-styles.scss';
import { getWireOnlyAgreementReport } from 'core/store/selectors';
import { CommonService } from 'core/services';

type Props = {
  id: number;
  showWireAgreement: () => void;
  closePreview: () => void;
};

const WireOnlyAgreementStep = ({ id, showWireAgreement, closePreview }: Props) => {
  const {
    watch,
    register,
    handleSubmit,
  } = useForm<WireOnlyAgreement>();
  const dispatch = useAppDispatch();
  const mustWireAgreement = useAppSelector(({ payroll }) => { return payroll.payrollPreviewResult?.mustWireAgreement; });
  const wireOnlyAgreementReport = useSelector(getWireOnlyAgreementReport);
  const wireOnlyAgreementPreviewSuccess = useAppSelector(({ payroll }) => { return payroll.wireOnlyAgreementPreviewSuccess; });

  const [submitType, setSubmitType] = useState<string>('submit');

  const downloadReport = (data: WireOnlyAgreement) => {
    dispatch(downloadWireOnlyAgreementReport(data));
  };

  const onSave = (data: WireOnlyAgreement) => {
    if (submitType === 'print') {
      downloadReport(data);
      return;
    }
    
    if (submitType === 'decline') {
      dispatch(deleteWireOnlyAgreement(id));
      return;
    }

    dispatch(updateWireOnlyAgreement(data));
  };


  useEffect(() => {
    if (!wireOnlyAgreementPreviewSuccess) return;

    dispatch(toggleWireOnlyAgreementPreviewSuccess(false));
    dispatch(clearWireOnlyAgreementReport());
    closePreview();
  }, [wireOnlyAgreementPreviewSuccess]);

  useEffect(() => {
    if (wireOnlyAgreementReport) {
      CommonService.downloadBase64File(
        'application/pdf',
        wireOnlyAgreementReport,
        'WireOnlyAgreementReport.pdf',
      );
      dispatch(clearWireOnlyAgreementReport());
    }
  }, [wireOnlyAgreementReport]);

  return (
    <div className="dm-panel dm-panel-border">
      <div>
        <div style={{ display: 'flex' }}>
          <button
            type="button"
            className="btn btn-primary orange-button"
            onClick={showWireAgreement}
          >
            Back
          </button>
        </div>
        <PanelHeader title="IN ORDER TO PROCESS YOUR CURRENT PAYROLL, YOU MUST WIRE FUNDS" />
        <p>
          Due to your company having Non-Sufficient Funds on one or more previous payrolls,
          you are now required to <b>WIRE</b> all funds due to us from your financial institution.
          Please review carefully the instructions below in order to receive your payroll timely as you expect. 
        </p>
        <p>
          (1) Your wire must be received by our bank JP Morgan Chase by 3:00 pm EST in order for your payroll
          to be processed today.
        </p>
        <p>
          <i>
            *NOTE* - Allow yourself adequate time for the wire to reach us by the required time. If the wire arrives
            after the time shown above, your payroll will be processed the next banking day with the payroll check date
            being moved forward by one day. This will more than likely cause your employees to receive their net pay
            one business day later.
          </i>
        </p>
        <p>
          (2) When wiring funds, you must include the name "DM Payroll Services, L.L.C." on the request. Otherwise,
          the funds will not be wired into the bank account listed below. If you have any questions, please call
          Customer Service at (248) 244-3293.
        </p>

        <PanelHeader title="WIRING INSTRUCTIONS" />
        <p>
          Please wire the following funds to: <b>DM Payroll Services, L.L.C.</b>
        </p>

        <div className="d-flex flex-row">
          <div className="p-2 flex-fill">
            Dollar Amount: <b>{currencyFormatter(mustWireAgreement?.pullAmount ?? 0)}</b>
          </div>
          <div className="p-2 flex-fill">
            Amount Wired: <b>{currencyFormatter(mustWireAgreement?.wiredAmount ?? 0)}</b>
          </div>
          <div className="p-2 flex-fill">
            Balance Due: <b>{currencyFormatter((mustWireAgreement?.pullAmount ?? 0) - (mustWireAgreement?.wiredAmount ?? 0))}</b>
          </div>
        </div>

        <div className="d-flex flex-column">
          <div className="p-2">
            Bank Name: <b>J.P. Morgan Chase</b>
          </div>
          <div className="p-2">
            Account/Beneficiary Name: <b>DM Payroll Services, L.L.C.</b>
          </div>
          <div className="p-2">
            Account Number: <b>789801586</b>
          </div>
          <div className="p-2">
            Routing/ABA#: <b>021000021</b>
          </div>
        </div>

        <PanelHeader title="" />
        <form onSubmit={handleSubmit(onSave)}>
          {/* Hidden fields */}
          <input
            name="mustWireId"
            value={mustWireAgreement?.mustWireId ?? 0}
            ref={register}
            type="hidden"
          />
          <input
            name="payrollHistoryId"
            value={mustWireAgreement?.payrollHistoryId ?? 0}
            ref={register}
            type="hidden"
          />
          <input
            name="pullAmount"
            value={mustWireAgreement?.pullAmount ?? 0}
            ref={register}
            type="hidden"
          />
          <input
            name="wiredAmount"
            value={mustWireAgreement?.wiredAmount ?? 0}
            ref={register}
            type="hidden"
          />
            
          <div className="wire-only-main-section">
            <div className="wire-only-input-section">
              <div>
                <span>Agreed (enter your name):</span>
                <InputGrp
                  name="agreedName"
                  defaultValue={mustWireAgreement?.agreedName}
                  ref={register}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary orange-button"
                onClick={(() => {return setSubmitType('submit');})}
              >
                Submit
              </button>
            </div>
            <div className="wire-only-input-section">
              <div>
                <span>Phone Number:</span>  
                <InputGrp
                  name="phone"
                  defaultValue={mustWireAgreement?.phone}
                  ref={register}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary orange-button"
                onClick={(() => {return setSubmitType('decline');})}
              >
                Decline
              </button>
            </div>
            <div className="wire-only-input-section">
              <div>
                <span>Email:</span>
                <InputGrp
                  name="email"
                  defaultValue={mustWireAgreement?.email}
                  ref={register}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary orange-button"
                onClick={(() => {return setSubmitType('print');})}
              >
                Print
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WireOnlyAgreementStep;
