import React, { CSSProperties, useContext, useState, ChangeEvent, FocusEvent } from 'react';
import Icon from 'core/components/shared/Icon';
import './time-card-styles.scss';
import { InputGrp, SelectGrp } from 'core/components/form-controls';
import { useSelector } from 'react-redux';
import { getDropdownOptions } from 'core/store/selectors';
import { ddLookup } from 'utilities/utilities';
import { DeductionArrayField } from 'core/models';
import { TimeCardContext } from './TimeCardContext';

const infoGrpStyle: CSSProperties = {
  marginBottom: '0',
  width: '125px',
  minWidth: '125px',
  marginLeft: '5%',
};

type Props = {
  deduction: DeductionArrayField;
  index: number;
  showLabel?: boolean;
  canEdit?: boolean;
};

const TimeCardDeductionItem = ({ deduction, index, canEdit = true }: Props) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  
  const { deductionCodeOptions } = useSelector(getDropdownOptions);
  
  const {
    formMethods,
    deductionFieldArrayMethods,
    timeCardId,
    updateDirtyState,
    updateOverrideDeleted,
  } = useContext(TimeCardContext);
  const { register, setValue, watch } = formMethods;
  const { remove } = deductionFieldArrayMethods;
  
  const originalVal = deduction?.amount;
  
  const watchedAmount: string = watch(`deductions[${index}].amount`);
  const watchedDedNo: number = watch(`deductions[${index}].deductionNumber`);

  const updateDedNo = (e: ChangeEvent<HTMLSelectElement>) => {
    setValue(`deductions[${index}].deductionNumber`, e.target.value);
    updateDirtyState(true);
  };
  
  const updateAmount = (e: FocusEvent<HTMLInputElement, Element>) => {
    setValue(`deductions[${index}].amount`, e.target.value);
    setValue(`deductions[${index}].isOverride`, true);
    e.target.value = parseFloat(e.target.value).toFixed(2);
    updateDirtyState(true);
  };
  
  const handleCancelEdit = () => {
    if (editMode) {
      setValue(`deductions[${index}].amount`, (originalVal ?? 0).toFixed(2));
      setValue(`deductions[${index}].isOverride`, false);
      setEditMode(false);
      return;
    }

    setEditMode(true);    
  };
  
  const handleDeleteDeduction = () => {
    remove(index);
    updateDirtyState(true);
    if (deduction?.deductionId !== 0 && (deduction?.transmittalTimeCardDeductionId ?? 0) > 0) updateOverrideDeleted(true);
  };
  
  return (
    <div className="earning-item">
      <div
        className="d-flex flex-column"
        style={{ width: '20%' }}
      >
        <>
          <input
            name={`deductions[${index}].transmittalTimeCardId`}
            type="hidden"
            ref={register({ valueAsNumber: true })}
            defaultValue={timeCardId}
          />
          <input
            name={`deductions[${index}].transmittalTimeCardDeductionId`}
            type="hidden"
            ref={register({ valueAsNumber: true })}
            defaultValue={deduction?.transmittalTimeCardDeductionId ?? 0}
          />
          <input
            name={`deductions[${index}].deductionNumber`}
            type="hidden"
            ref={register()}
            defaultValue={watchedDedNo ?? 0}
          />
          <input
            name={`deductions[${index}].deductionId`}
            type="hidden"
            ref={register({ valueAsNumber: true })}
            defaultValue={deduction?.deductionId ?? 0}
          />   
          <input
            name={`deductions[${index}].amount`}
            type="hidden"
            ref={register()}
            value={watchedAmount ?? 0}
          />   
        </>
        {(deduction?.transmittalTimeCardDeductionId ?? 0) > 0 || (deduction?.deductionId ?? 0) > 0 ? (
          <strong className="input-label">{deduction.deductionNumber} - {ddLookup(deduction.deductionNumber, deductionCodeOptions)}</strong>
        ) : (
          <SelectGrp
            name={`deductions[${index}].deductionNumber`}
            options={deductionCodeOptions}
            ref={register()}
            defaultValue={watchedDedNo}
            onChange={updateDedNo}
            selectStyles={{ height: '30px' }}
            groupStyle={{ margin: '0' }}
          />
        )}
      </div>
      <div
        style={{ marginLeft: editMode ? '10px' : '30px', width: 'fit-content' }}
      >
        <div className="d-flex align-items-center">
          {canEdit && editMode && (
            <button
              className="section-action btn btn-link dm-grid-action-title p-0 m-0"
              onClick={() => { setEditMode(false); }}
            >
              <Icon
                name="check"
                style={{ color: 'green',
                  padding: '0', marginRight: '3px' }}
              />
            </button>
          )}
          {+(deduction?.transmittalTimeCardDeductionId ?? 0) === 0 ? (
            <>
              {canEdit && (
                <button
                  className="section-action btn btn-link dm-grid-action-title p-0 m-0"
                >
                  <Icon
                    name={editMode ? 'cancel' : 'pen'}
                    style={editMode ? { color: 'red' } : { color: 'blue' }}
                    className="fa-pen"
                    title={editMode ? 'cancel' : 'edit'}
                    onClick={handleCancelEdit}
                  />
                </button>
              )}
              <InputGrp
                name={`deductions[${index}].amount`}
                groupStyle={infoGrpStyle}
                ref={register()}
                defaultValue={(parseFloat(watchedAmount) ?? 0).toFixed(2)}
                disabled={!canEdit || !editMode}
                onBlur={updateAmount}
                style={{ height: '30px' }}
              />
              <span
                className="mx-2 font-weight-bold"
                style={{ whiteSpace: 'nowrap' }}
              >
                
              </span>
            </>
          ) : (
            <>
              <InputGrp
                name={`deductions[${index}].amount`}
                ref={register()}
                groupClass="number-underline"
                groupStyle={infoGrpStyle}
                defaultValue={(parseFloat(watchedAmount) ?? 0).toFixed(2)}
                onBlur={updateAmount}
                style={{ height: '30px', marginLeft: '30px' }}
              />
              {(deduction?.transmittalTimeCardDeductionId ?? 0) === 0 && (
                <span
                  className="mx-2 font-weight-bold"
                  style={{ whiteSpace: 'nowrap' }}
                > </span>
              )}
            </>
          )}
        </div>
      </div>
      {(deduction?.transmittalTimeCardDeductionId ?? 0) !== 0 && (
        <div
          className="d-flex align-items-center justify-content-end"
          style={{ marginLeft: 'auto' }}
        >
          <button
            className="section-action btn btn-link dm-grid-action-title p-0 m-0"
            onClick={handleDeleteDeduction}
          >
            {deduction?.deductionId !== 0 && (deduction?.transmittalTimeCardDeductionId ?? 0) > 0 ? 'Delete override' : 'Delete'}&nbsp;<Icon
              name="minus-circle"
              className="fa-minus-circle"
              title="delete deduction"
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default TimeCardDeductionItem;