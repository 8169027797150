import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpResponse, SpecialInfo } from '../../models';
import { SpecialInfoService } from '../../services';
import {
  handleError,
  handleSuccess,
  loadSpecialInfo,
  storeSpecialInfo,
  triggerEmFieldValidation,
  updateSpecialInfo,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadSpecialInfo$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadSpecialInfo.type),
    switchMap((action: { payload: string; }) => {
      return from(SpecialInfoService.getSpecialInfo(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: SpecialInfo) => { return storeSpecialInfo(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateSpecialInfo$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateSpecialInfo.type),
    switchMap((action: { payload: SpecialInfo; }) => {
      return from(SpecialInfoService.putSpecialInfo(action.payload)).pipe(
        map((res) => { return res.data; }),
        mergeMap((res: HttpResponse<SpecialInfo>) => {
          return [
            storeSpecialInfo(res.value),
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'specialinfo',
              actionType: updateSpecialInfo.type,
              callerPayload: res.value,
            }),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [loadSpecialInfo$, updateSpecialInfo$];
