import React from 'react';
import { CircularProgress } from '@mui/material';
import { useAppSelector } from 'utilities/hooks';
import Icon from './Icon';

type Props = {
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
  label?: string;
  onClick?: any;
};

const ExcelReportDownloadButton = ({
  disabled = false,
  type = 'submit',
  label = 'Download Report',
  onClick = null,
}: Props) => {
  const isReportDownloading = useAppSelector((state) => { return state.reportDownloader.isDownloading; });

  return (    
    <button
      type={type}
      className="btn btn-link dm-grid-action-title d-flex align-items-center float-right"
      disabled={disabled || isReportDownloading}
      onClick={onClick}
    >
      {label} {' '}
      <Icon name="download"
        fontSize={16}
        className="fa-download" />
      {isReportDownloading && (
        <CircularProgress color="success"
          size="1rem"
          className="ml-2" />
      )}
    </button>   
  );
};

export default ExcelReportDownloadButton;