import React, { useState, useEffect, useContext } from 'react';
import { Applicant, CreateOnboardEmployee, PageConfig } from 'core/models';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  createOnboardEmployee,
  loadEmployees,
  loadOnboardDocs,
  loadOnboardPageConfigs,
  setOnboardEmployeeStatus,
  updateOnboardPageConfigs,
} from 'core/store/actions';
import { getOnboardDocs, getOnboardPageConfigs, getOnboardStatus } from 'core/store/selectors';
import OnboardConfirmModal from 'features/onboard/OnboardConfirm.modal';
import OnboardSelectEmpNoModal from 'features/onboard/OnboardSelectEmpNo.modal';
import OnboardSuccessModal from 'features/onboard/OnboardSuccess.modal';
import OnboardEmailsModal from 'features/onboard/OnboardEmails.modal';
import OnboardErrorModal from 'features/onboard/OnboardError.modal';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { PageConfigsContext, PageConfigsContextType, PageConfigContextObj } from 'features/onboard/pageConfigContext';
import OnboardFieldConfigItem from 'features/onboard/OnboardFieldConfigItem';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { resendApplicantOnboardingEmail } from 'core/store/slices/applicantTracking.slice';
import OnboardDocuments from 'features/onboard/OnboardDocuments';
import './applicant-status.scss';
import { Prompt } from 'react-router-dom';
import { UNSAVED_MESSAGE } from 'core/constants';

type OnboardingComponentProps = {
  activeApplicant: Applicant | null,
  onSubmit: () => void,
  onResend: () => void
};

const OnboardingComponent = ({ activeApplicant, onSubmit, onResend }: OnboardingComponentProps) => {
  const dispatch = useAppDispatch();
  
  const onboardDocs = useSelector(getOnboardDocs);
  
  // TODO: might just wanna handle this in the context
  const [isDirty, setIsDirty] = useState(false);
  
  const pageConfigContext = useContext(PageConfigsContext);
  const firstTabKey = Array.isArray(pageConfigContext.pageConfigs)
    ? pageConfigContext.pageConfigs?.find((pc: PageConfig) => { return pc.fieldConfigs?.length; })
    : undefined;
  
  useEffect(() => {
    dispatch(loadOnboardDocs());
  }, []);
  
  const handleSavePageConfig = () => {
    dispatch(updateOnboardPageConfigs(pageConfigContext.pageConfigs));
    setIsDirty(false);
  };
  
  const handleSubmit = () => {
    if (isDirty && !confirm('You have unsaved field configurations. Continue?')) return;
    onSubmit();
    setIsDirty(false);
  };
  
  return (
    <>
      <Prompt
        when={isDirty}
        message={UNSAVED_MESSAGE}
      />
      <div className="d-flex justify-content-end mb-2">
        {!activeApplicant?.infoSent ? ( 
          <button
            className="orange-button mr-2"
            onClick={handleSubmit}
          >
            Send Onboarding Credentials
          </button>
        ) : (
          <button
            className="orange-button mr-2"
            onClick={()=>{return onResend();}}
          >
            Re-Send Onboarding Credentials
          </button>
        )}
      </div>
      <div className="onboarding-status-container">
        <div className="border p-3 onboarding-config">
          <h4 className="font-weight-bold">Onboarding Field Configurations:</h4>
          <p>Fields that are marked &apos;Required&apos; here must be filled out by onboarding employees</p>
          <Tabs
            defaultActiveKey={firstTabKey?.pageID.toString()}
            id="onboard-field-configs"
          >
            {pageConfigContext.pageConfigs && Array.isArray(pageConfigContext.pageConfigs) ? pageConfigContext.pageConfigs?.map((pc: PageConfig) => {
              if (!(pc.visible && pc.fieldConfigs?.length || pc.title.toLowerCase() === 'i9')) return null;
              return (
                <Tab
                  key={pc.pageID}
                  eventKey={pc.pageID.toString()}
                  title={pc.title}
                >
                  <OnboardFieldConfigItem
                    key={pc.pageID}
                    pageConfig={pc}
                    setIsDirty={setIsDirty}
                  />
                </Tab>
              );
            }) : undefined}
          </Tabs>
          <div className="d-flex justify-content-end mt-4">
            <button
              className="orange-button mr-2"
              onClick={handleSavePageConfig}
              disabled={!isDirty}
              aria-disabled={!isDirty}
            >
              Save Configuration
            </button>
          </div>
        </div>
        {onboardDocs && !activeApplicant?.infoSent && (
          <div className="onboarding-docs">
            <OnboardDocuments onboardDocs={onboardDocs} />
          </div>
        )}
      </div>
    </>
  );
};

const Onboarding = () => {
  const [pageConfigContext, setPageConfigContext] = useState<PageConfigsContextType>(PageConfigContextObj);

  const dispatch = useDispatch();
  
  const onboardStatus = useSelector(getOnboardStatus);
  const clientNo = useAppSelector(({ client }) => client.client?.clientNo || '');
  const activeApplicant = useAppSelector(({ applicantTrackingV2 }) => applicantTrackingV2.active);
  const pageConfigs = useSelector(getOnboardPageConfigs);

  const [onboardEmp, setOnboardEmp] = useState<CreateOnboardEmployee | null>(null);
  const [showSelectEmpNoModal, setShowSelectEmpNoModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showEmailsModal, setShowEmailsModal] = useState(false);
  const [showErrorModal, setShowErrorModalModal] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false);
  

  useEffect(() => {
    dispatch(setOnboardEmployeeStatus('starting'));
    dispatch(loadOnboardPageConfigs());
  }, []);

  useEffect(() => {
    setPageConfigContext((prev: PageConfigsContextType) => {
      return {
        documentIds: [],
        pageConfigs,
        updateDocumentIds: prev.updateDocumentIds,
        setPageConfigs: prev.setPageConfigs,
        updatePageConfig: prev.updatePageConfig,
        updatePageConfigRequired: prev.updatePageConfigRequired,
        setPageConfigContext,
      };
    });
  }, [pageConfigs]);

  useEffect(() => {
    if (onboardStatus) {
      if (onboardStatus === 'success') {
        setShowSuccessModal(true);
      } else if (onboardStatus.error) {
        setShowErrorModalModal(true);
      }
    }
  }, [onboardStatus]);

  const onSubmit = () => {
    const data: any = activeApplicant || {};
    setOnboardEmp(
      new CreateOnboardEmployee(clientNo, 0, {
        ...data,
        birthDate: data.birthDate || null,
        selectedOnboardDocIDs: [],
      }),
    );
    setShowConfirmModal(true);
  };

  const onResend = () => {   
    setShowResendModal(true);
  };

  const onContinueShowConfirmModal = () => {
    setShowConfirmModal(false);
    setShowSelectEmpNoModal(true);
  };
 
  const onConfirmResend = (confirmed:boolean) => {
    setShowResendModal(false);
    if (confirmed && activeApplicant) {      
      dispatch(
        resendApplicantOnboardingEmail(
          { applicantId: activeApplicant.applicantId },
        ),
      );
    }
  };

  const onContinueSelectEmpNoModal = (empNo: number) => {
    setShowSelectEmpNoModal(false);
    if (onboardEmp) {
      onboardEmp.empNo = empNo;
      onboardEmp.gender = onboardEmp?.gender ? onboardEmp.gender : '';
      dispatch(
        createOnboardEmployee(
          CreateOnboardEmployee.toObject(onboardEmp),
        ),
      );
      dispatch(loadEmployees());
    }
  };

  const onContinueShowSuccessModal = () => {
    setShowSuccessModal(false);
    setShowEmailsModal(true);
  };

  return (
    <PageConfigsContext.Provider value={pageConfigContext}>
      <div className="onboarding-content">
        <OnboardingComponent
          activeApplicant={activeApplicant}
          onSubmit={onSubmit}
          onResend={onResend}
        />
        {showConfirmModal && (
          <OnboardConfirmModal
            show={showConfirmModal}
            onContinue={onContinueShowConfirmModal}
            onHide={() => { setShowConfirmModal(false); }}
            confirmMessage={'This will create an employee master record and email the applicant their onboarding information.'}
          />
        )}
        {showSelectEmpNoModal && (
          <OnboardSelectEmpNoModal
            show={showSelectEmpNoModal}
            onContinue={onContinueSelectEmpNoModal}
            onHide={() => { setShowSelectEmpNoModal(false); }}
          />
        )}
        {showSuccessModal && (
          <OnboardSuccessModal
            show={showSuccessModal}
            onContinue={onContinueShowSuccessModal}
            onHide={() => { setShowSuccessModal(false); }}
          />
        )}
        {showEmailsModal && (
          <OnboardEmailsModal
            show={showEmailsModal}
            onHide={() => { setShowEmailsModal(false); }}
          />
        )}
        {showErrorModal && (
          <OnboardErrorModal
            errorMsg={onboardStatus?.error?.message}
            show={showErrorModal}
            onHide={() => { setShowErrorModalModal(false); }}
          />
        )}
        {showResendModal && (
          <ConfirmationModal
            show={showResendModal}
            title="Confirm"
            message={'This will resend the applicant their onboarding information: \n Continue with this action?'}
            onHide={() => { setShowResendModal(false); } } 
            onConfirmed={onConfirmResend}
          />
        )}
      </div>
    </PageConfigsContext.Provider>
  );
};

export default Onboarding;