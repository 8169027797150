import React, { useEffect, useState } from 'react';
import { SelectGrp } from '../../../core/components/form-controls';
import { FieldInputSettings } from '../../../core/components/form-controls/types';
import { Dropdown, OfferOfCoverage } from '../../../core/models';

const fs: FieldInputSettings = {
  offerOfCoverageAll12Months: {
    name: 'offerOfCoverage.offerOfCoverageAll12Months',
    noOption: true,
    addOptionText: 'selection',
    label: 'ALL 12 MONTHS',
    groupClass: 'gc08 mw100 pr-1',
  },
  offerOfCoverageJanuary: {
    name: 'offerOfCoverage.offerOfCoverageJanuary',
    label: 'JAN',
    groupClass: 'gc06 mw50 pr-1',
  },
  offerOfCoverageFebruary: {
    name: 'offerOfCoverage.offerOfCoverageFebruary',
    label: 'FEB',
    groupClass: 'gc06 mw50 pr-1',
  },
  offerOfCoverageMarch: {
    name: 'offerOfCoverage.offerOfCoverageMarch',
    label: 'MAR',
    groupClass: 'gc06 mw50 pr-1',
  },
  offerOfCoverageApril: {
    name: 'offerOfCoverage.offerOfCoverageApril',
    label: 'APR',
    groupClass: 'gc06 mw50 pr-1',
  },
  offerOfCoverageMay: {
    name: 'offerOfCoverage.offerOfCoverageMay',
    label: 'MAY',
    groupClass: 'gc06 mw50 pr-1',
  },
  offerOfCoverageJune: {
    name: 'offerOfCoverage.offerOfCoverageJune',
    label: 'JUN',
    groupClass: 'gc06 mw50 pr-1',
  },
  offerOfCoverageJuly: {
    name: 'offerOfCoverage.offerOfCoverageJuly',
    label: 'JUL',
    groupClass: 'gc06 mw50 pr-1',
  },
  offerOfCoverageAugust: {
    name: 'offerOfCoverage.offerOfCoverageAugust',
    label: 'AUG',
    groupClass: 'gc06 mw50 pr-1',
  },
  offerOfCoverageSeptember: {
    name: 'offerOfCoverage.offerOfCoverageSeptember',
    label: 'SEP',
    groupClass: 'gc06 mw50 pr-1',
  },
  offerOfCoverageOctober: {
    name: 'offerOfCoverage.offerOfCoverageOctober',
    label: 'OCT',
    groupClass: 'gc06 mw50 pr-1',
  },
  offerOfCoverageNovember: {
    name: 'offerOfCoverage.offerOfCoverageNovember',
    label: 'NOV',
    groupClass: 'gc06 mw50 pr-1',
  },
  offerOfCoverageDecember: {
    name: 'offerOfCoverage.offerOfCoverageDecember',
    label: 'DEC',
    groupClass: 'gc06 mw50 pr-1',
  },
};

type PropTypes = {
  formMethods: any;
  item: OfferOfCoverage;
  overrideOfferOfCoverageOpt: Dropdown[];
  offerValues(values: OfferOfCoverage): void;
};

const ACAOfferCoverage: React.FC<any> = React.forwardRef(
  (
    {
      formMethods: { errors, register, getValues, setValue },
      item,
      overrideOfferOfCoverageOpt,
      offerValues,
    }: PropTypes,
    ref: any,
  ) => {
    const overrideClass = item.overridden
      ? 'text-primary '
      : 'text-success';

    useEffect(() => {
      item && offerValues(item);
    }, [item]);

    const [monthReadOnly, setMonthReadOnly] = useState(
      item?.offerOfCoverageAll12Months !== '',
    );

    const on12MonthChange = (e: any) => {
      if (e.target.value !== '') {
        setMonthReadOnly(true);
        setValue('offerOfCoverage.offerOfCoverageJanuary', '');
        setValue('offerOfCoverage.offerOfCoverageFebruary', '');
        setValue('offerOfCoverage.offerOfCoverageMarch', '');
        setValue('offerOfCoverage.offerOfCoverageApril', '');
        setValue('offerOfCoverage.offerOfCoverageMay', '');
        setValue('offerOfCoverage.offerOfCoverageJune', '');
        setValue('offerOfCoverage.offerOfCoverageJuly', '');
        setValue('offerOfCoverage.offerOfCoverageAugust', '');
        setValue('offerOfCoverage.offerOfCoverageSeptember', '');
        setValue('offerOfCoverage.offerOfCoverageOctober', '');
        setValue('offerOfCoverage.offerOfCoverageNovember', '');
        setValue('offerOfCoverage.offerOfCoverageDecember', '');
      } else {
        setValue('offerOfCoverage.offerOfCoverageAll12Months', '');
        setMonthReadOnly(false);
      }
      offerValues(getValues().offerOfCoverage);
    };

    const onMonthChange = () => {
      offerValues(getValues().offerOfCoverage);
    };

    return (
      <div className="d-flex flex-row flex-wrap">
        <div
          className="gc15 font-weight-bold pt-2"
          style={{ lineHeight: '12px', fontSize: '10px' }}
        >
          14 Offer of Coverage (enter required code)
        </div>
        <SelectGrp
          {...fs.offerOfCoverageAll12Months}
          errors={errors?.offerOfCoverage?.offerOfCoverageAll12Months}
          ref={register()}
          options={overrideOfferOfCoverageOpt}
          onChange={on12MonthChange}
          selectClass={overrideClass}
          labelClass="text-center pl-0"
        />
        <SelectGrp
          {...fs.offerOfCoverageJanuary}
          errors={errors?.offerOfCoverage?.offerOfCoverageJanuary}
          ref={register()}
          options={overrideOfferOfCoverageOpt}
          onChange={onMonthChange}
          disabled={monthReadOnly}
          selectClass={overrideClass}
          labelClass="text-center pl-0"
        />
        <SelectGrp
          {...fs.offerOfCoverageFebruary}
          errors={errors?.offerOfCoverage?.offerOfCoverageFebruary}
          ref={register()}
          options={overrideOfferOfCoverageOpt}
          onChange={onMonthChange}
          disabled={monthReadOnly}
          selectClass={overrideClass}
          labelClass="text-center pl-0"
        />
        <SelectGrp
          {...fs.offerOfCoverageMarch}
          errors={errors?.offerOfCoverage?.offerOfCoverageMarch}
          ref={register()}
          options={overrideOfferOfCoverageOpt}
          onChange={onMonthChange}
          disabled={monthReadOnly}
          selectClass={overrideClass}
          labelClass="text-center pl-0"
        />
        <SelectGrp
          {...fs.offerOfCoverageApril}
          errors={errors?.offerOfCoverage?.offerOfCoverageApril}
          ref={register()}
          options={overrideOfferOfCoverageOpt}
          onChange={onMonthChange}
          disabled={monthReadOnly}
          selectClass={overrideClass}
          labelClass="text-center pl-0"
        />
        <SelectGrp
          {...fs.offerOfCoverageMay}
          errors={errors?.offerOfCoverage?.offerOfCoverageMay}
          ref={register()}
          options={overrideOfferOfCoverageOpt}
          onChange={onMonthChange}
          disabled={monthReadOnly}
          selectClass={overrideClass}
          labelClass="text-center pl-0"
        />
        <SelectGrp
          {...fs.offerOfCoverageJune}
          errors={errors?.offerOfCoverage?.offerOfCoverageJune}
          ref={register()}
          options={overrideOfferOfCoverageOpt}
          onChange={onMonthChange}
          disabled={monthReadOnly}
          selectClass={overrideClass}
          labelClass="text-center pl-0"
        />
        <SelectGrp
          {...fs.offerOfCoverageJuly}
          errors={errors?.offerOfCoverage?.offerOfCoverageJuly}
          ref={register()}
          options={overrideOfferOfCoverageOpt}
          onChange={onMonthChange}
          disabled={monthReadOnly}
          selectClass={overrideClass}
          labelClass="text-center pl-0"
        />
        <SelectGrp
          {...fs.offerOfCoverageAugust}
          errors={errors?.offerOfCoverage?.offerOfCoverageAugust}
          ref={register()}
          options={overrideOfferOfCoverageOpt}
          onChange={onMonthChange}
          disabled={monthReadOnly}
          selectClass={overrideClass}
          labelClass="text-center pl-0"
        />
        <SelectGrp
          {...fs.offerOfCoverageSeptember}
          errors={errors?.offerOfCoverage?.offerOfCoverageSeptember}
          ref={register()}
          options={overrideOfferOfCoverageOpt}
          onChange={onMonthChange}
          disabled={monthReadOnly}
          selectClass={overrideClass}
          labelClass="text-center pl-0"
        />
        <SelectGrp
          {...fs.offerOfCoverageOctober}
          errors={errors?.offerOfCoverage?.offerOfCoverageOctober}
          ref={register()}
          options={overrideOfferOfCoverageOpt}
          onChange={onMonthChange}
          disabled={monthReadOnly}
          selectClass={overrideClass}
          labelClass="text-center pl-0"
        />
        <SelectGrp
          {...fs.offerOfCoverageNovember}
          errors={errors?.offerOfCoverage?.offerOfCoverageNovember}
          ref={register()}
          options={overrideOfferOfCoverageOpt}
          onChange={onMonthChange}
          disabled={monthReadOnly}
          selectClass={overrideClass}
          labelClass="text-center pl-0"
        />
        <SelectGrp
          {...fs.offerOfCoverageDecember}
          errors={errors?.offerOfCoverage?.offerOfCoverageDecember}
          ref={register()}
          options={overrideOfferOfCoverageOpt}
          onChange={onMonthChange}
          disabled={monthReadOnly}
          selectClass={overrideClass}
          labelClass="text-center pl-0"
        />
      </div>
    );
  },
);

export default ACAOfferCoverage;
