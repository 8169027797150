import React, { useContext, useState } from 'react';
import { toReadableDate } from 'utilities/utilities';
import ApplicationHistoryModal from './ApplicationHistoryModal';
import { ApplicationHistory } from 'core/models';
import { useHistory } from 'react-router-dom';
import './application-history.scss';
import { ApplicantContext } from '../Applicant';

type Props = {
  dateOfApplication: string;
  status: string;
  positionAppliedFor: string;
  historyItem: ApplicationHistory;
};

const HistoryItem = ({ dateOfApplication, status, positionAppliedFor, historyItem }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  
  const { setTab } = useContext(ApplicantContext);
  
  const history = useHistory();
  
  const handleRowDoubleClick = () => {
    setTab('status');
    history.push(`/manage-applicants/${historyItem.applicantId}`);
  };
  
  return (
    <>
      <tr
        key={dateOfApplication}
        onDoubleClick={handleRowDoubleClick}
      >
        <td className="history-table-cell">{toReadableDate(dateOfApplication)}</td>
        <td className="history-table-cell">{status}</td>
        <td className="history-table-cell">{positionAppliedFor}</td>
      </tr>
      {openModal && (
        <ApplicationHistoryModal
          hide={() => { setOpenModal(false); }}
          dateOfApplication={dateOfApplication}
          status={status}
          positionAppliedFor={positionAppliedFor}
        />
      )}
    </>
  );
};

export default HistoryItem;
