import React, { useEffect, useState } from 'react';
import { Prompt, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBenefitClassCodes,
  getEPrescribe,
  getSpecialInfo,
  getStaffingReasons,
  getSelectedEmp,
  getCineTrainJobTitle,
  getReportOptions,
} from 'core/store/selectors';
import { FieldInputSettings } from 'core/components/form-controls/types';
import {
  ControlDatePickerGrp,
  InputGrp,
  InputGrpCurrency,
  RadioGrp,
  SelectGrp,
  SelectModalGrp,
} from 'core/components/form-controls';
import { useForm } from 'react-hook-form';
import PanelHeader from 'core/components/shared/PanelHeader';
import { loadSpecialInfo, toggleBlockNavigation, updateSpecialInfo } from 'core/store/actions';
import { SpecialInfo } from 'core/models';
import { CURRENCY_VALIDATION, UNSAVED_MESSAGE, ControlIds } from 'core/constants';
import { useRadioButtonChange, useAppSelector } from 'utilities/hooks';
import DropdownOptionForm from 'core/components/form-controls/select-modal/DropdownOptionForm';
import { wotcOptions } from 'dropdowns/specialInfoOptions';
import { getAccess } from 'utilities/utilities';

const SpecialInfoPage = () => {
  const dispatch = useDispatch();
  
  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();

  const [averageRateOfPayDisabled, setAverageRateOfPayDisabled] = useState(false);

  const clientNo = useAppSelector((state) => {return state?.client?.client?.clientNo;});
  const specialInfo = useSelector(getSpecialInfo);
  const ePrescribeOpts = useSelector(getEPrescribe);
  const benefitClassCodeOpts = useSelector(getBenefitClassCodes);
  const getStaffingReasonOpts = useSelector(getStaffingReasons);
  const selectedEmployee = useSelector(getSelectedEmp);
  const showEmpNavIntegrationFields = useSelector(getReportOptions('EmployeeNavigatorReport'))?.showReport;
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  const visible = getAccess(sectionAccess, 39)?.visible;

  const { setValue, errors, register, control, handleSubmit, reset, watch, formState: { isDirty, dirtyFields } } =
        useForm<SpecialInfo>({
          defaultValues: new SpecialInfo(
            0,
            clientNo ?? 0,
            selectedEmployee?.empNo ?? 0,
            protectedEmpNo,
          ),
        });

  const {
    dateImportedFromiCIMS,
    offerAcceptedDate,
    eesDate,
    credentialNotifiedDate,
    onboardBeginDate,
    malpracticeAppSubmitted,
    malpracticeCertReceived,
  } = watch([
    'dateImportedFromiCIMS',
    'offerAcceptedDate',
    'eesDate',
    'credentialNotifiedDate',
    'onboardBeginDate',
    'malpracticeAppSubmitted',
    'malpracticeCertReceived',
  ]);

  useEffect(() => {
    dispatch(loadSpecialInfo(protectedEmpNo));
  }, [protectedEmpNo]);

  useEffect(() => {
    if (specialInfo) {
      reset(specialInfo);
      setAverageRateOfPayDisabled(specialInfo.averageRateOfPayOverride === 'false' ? true : false);
      const currentState = { ...fs };
      currentState.confidentialPosition.defaultChecked = specialInfo.confidentialPosition;
      currentState.textAuthorized.defaultChecked = specialInfo.textAuthorized;
      currentState.emailAuthorized.defaultChecked = specialInfo.emailAuthorized;
      currentState.dea.defaultChecked = specialInfo.dea;
      currentState.averageRateOfPayOverride.defaultChecked = specialInfo.averageRateOfPayOverride;
      currentState.exemptRetention.defaultChecked = specialInfo.exemptRetention;
      currentState.employeeSocialSecurityDeferral.defaultChecked = specialInfo.employeeSocialSecurityDeferral;
      setFs(currentState);
    }
  }, [specialInfo]);

  const onAverageRateOfPayOverride = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'true') {
      setAverageRateOfPayDisabled(false);
    } else {
      setAverageRateOfPayDisabled(true);
      setValue('averageRateOfPay', 0);
    }
    handleRadioButtonChange(e);
  };

  const onSave = (data: SpecialInfo) => {
    if (!(clientNo && selectedEmployee?.empNo)) {
      return console.error('Error: no employee and/or client number was found');
    }
    
    const newSpecialInfo = new SpecialInfo(
      specialInfo?.empId,
      clientNo,
      selectedEmployee.empNo,
      protectedEmpNo,
      data,
    );
    dispatch(updateSpecialInfo(newSpecialInfo));
  };

  const [fs, setFs] = useState<FieldInputSettings>({
    staffingReason: {
      name: 'staffingReason',
      label: 'STAFFING REASON',
      groupClass: 'groupClass15',
    },
    cineTrainJobTitleID: {
      name: 'cineTrainJobTitleID',
      label: 'CINETRAIN JOB TITLE',
      groupClass: 'groupClass15',
    },
    wotc: {
      name: 'wotc',
      label: 'WOTC',
      groupClass: 'groupClass15',
    },
    confidentialPosition: {
      name: 'confidentialPosition',
      label: 'CONFIDENTIAL',
      groupClass: 'groupClass10',
    },
    textAuthorized: {
      name: 'textAuthorized',
      label: 'TEXT AUTHORIZED',
      groupClass: 'groupClass10',
    },
    emailAuthorized: {
      name: 'emailAuthorized',
      label: 'EMAIL AUTHORIZED',
      groupClass: 'groupClass10',
    },
    employeeNavigatorFullTime: {
      name: 'employeeNavigatorFullTime',
      label: 'EMPLOYEE NAVIGATOR FULL TIME STATUS',
      groupClass: 'groupClass10',
      controlId: 191,
    },
    blockEmployeeNavigator: {
      name: 'blockEmployeeNavigator',
      label: 'BLOCK EMPLOYEE NAVIGATOR DEMOGRAPHIC SYNC',
      groupClass: 'groupClass10',
      controlId: 192,
    },
    dateImportedFromiCIMS: {
      name: 'dateImportedFromiCIMS',
      label: ['DATE IMPORTED', 'FROM ICIMS'],
      groupClass: 'groupClass10',
    },
    ePrescribeOption: {
      name: 'ePrescribeOption',
      label: 'EPRESCRIBE',
      groupClass: 'groupClass15',
    },
    dea: { name: 'dea',
      label: 'DEA',
      groupClass: 'groupClass10' },
    benefitClassClode: {
      name: 'benefitClassClode',
      label: 'BENEFIT CODE',
      groupClass: 'groupClass15',
      controlId: 196,
    },
    offerAcceptedDate: {
      name: 'offerAcceptedDate',
      label: 'OFFER ACCEPTED',
      groupClass: 'groupClass15',
    },
    eesDate: {
      name: 'eesDate',
      label: 'EES NOTIFIED',
      groupClass: 'groupClass15',
    },
    credentialNotifiedDate: {
      name: 'credentialNotifiedDate',
      label: 'CREDENTIALING NOTIFIED',
      groupClass: 'groupClass15',
    },
    onboardBeginDate: {
      name: 'onboardBeginDate',
      label: 'ONBOARDING BEGAN',
      groupClass: 'groupClass15',
    },
    malpracticeAppSubmitted: {
      name: 'malpracticeAppSubmitted',
      label: 'MALPRACTIVE APP SUBMITTED',
      groupClass: 'groupClass15 mw150',
    },
    malpracticeCertReceived: {
      name: 'malpracticeCertReceived',
      label: 'MALPRACTIVE CERTIFICATE RECEIVED',
      groupClass: 'groupClass15 mw150',
    },
    averageRateOfPay: {
      name: 'averageRateOfPay',
      label: 'AVERAGE RATE OF PAY',
      groupClass: 'gc15 mw150',
    },
    averageRateOfPayOverride: {
      name: 'averageRateOfPayOverride',
      label: ['MANUALLY ENTER AVERAGE', 'RATE OF PAY'],
      groupClass: 'gc15 mw150',
    },
    weeklyScheduledHours: {
      name: 'weeklyScheduledHours',
      label: 'SCHEDULED HOURS BY WEEK',
      groupClass: 'gc15 mw150',
    },
    exemptRetention: {
      name: 'exemptRetention',
      label: ['EXEMPT FROM', 'RETENTION CREDIT'],
      groupClass: 'gc15 mw150',
    },
    employeeSocialSecurityDeferral: {
      name: 'employeeSocialSecurityDeferral',
      label: 'EMPLOYEE SOCIAL SECURITY DEFERRAL',
      groupClass: 'groupClass15',
    },
    
  });

  const [handleRadioButtonChange] = useRadioButtonChange(fs);
  
  useEffect(() => {
    if (!visible) return;
    dispatch(toggleBlockNavigation({
      block: Object.keys(dirtyFields).length > 0,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [Object.keys(dirtyFields).length > 0, visible]);

  return (
    <div
      className="dm-panel dm-panel-border"
      style={{ minHeight: '300px' }}
    >
      {visible && (
        <>
          <form onSubmit={handleSubmit(onSave)}>
            <PanelHeader title="Special Info" />
            <div className="d-flex flex-wrap mt-3">
              <SelectGrp
                {...fs.staffingReason}
                {...getAccess(sectionAccess, 39)}
                errors={errors.staffingReason}
                ref={register}
                options={getStaffingReasonOpts}
              />
              <RadioGrp
                {...fs.confidentialPosition}
                {...getAccess(sectionAccess, 39, undefined, { disabledSameAsReadOnly: true })}
                errors={errors.confidentialPosition}
                ref={register}
                onChange={handleRadioButtonChange}
              />
              <RadioGrp
                {...fs.textAuthorized}
                {...getAccess(sectionAccess, 39, undefined, { disabledSameAsReadOnly: true })}
                errors={errors.textAuthorized}
                ref={register}
                onChange={handleRadioButtonChange}
              />
              <RadioGrp
                {...fs.emailAuthorized}
                {...getAccess(sectionAccess, 39, undefined, { disabledSameAsReadOnly: true })}
                errors={errors.emailAuthorized}
                ref={register}
                onChange={handleRadioButtonChange}
              />
              <ControlDatePickerGrp
                {...fs.dateImportedFromiCIMS}
                {...getAccess(sectionAccess, 39)}
                value={dateImportedFromiCIMS}
                setValue={setValue}
                control={control}
                disabled
              />
              <SelectGrp
                {...fs.ePrescribeOption}
                {...getAccess(sectionAccess, 39)}
                errors={errors.ePrescribeOption}
                ref={register}
                options={ePrescribeOpts}
              />
              <RadioGrp
                {...fs.dea}
                {...getAccess(sectionAccess, 39, undefined, { disabledSameAsReadOnly: true })}
                errors={errors.dea}
                ref={register}
                onChange={handleRadioButtonChange}
              />
              <SelectGrp
                {...fs.benefitClassClode}
                {...getAccess(sectionAccess, 39, ControlIds.benefitClassClode)}
                errors={errors.benefitClassClode}
                ref={register}
                options={benefitClassCodeOpts}
              />
            </div>
            <div className="d-flex flex-wrap mt-3">
              <SelectGrp
                {...fs.wotc}
                {...getAccess(sectionAccess, 39)}
                errors={errors.wotc}
                ref={register}
                options={wotcOptions}
              />
              <RadioGrp
                {...fs.employeeNavigatorFullTime}
                {...getAccess(sectionAccess, 39, ControlIds.employeeNavigatorFullTime, { disabledSameAsReadOnly: true, visibleDefault: showEmpNavIntegrationFields })}
                groupClass="groupClassAuto"
                errors={errors.employeeNavigatorFullTime}
                ref={register}
                onChange={handleRadioButtonChange}
              />
              <RadioGrp
                {...fs.blockEmployeeNavigator}
                {...getAccess(sectionAccess, 39, ControlIds.blockEmployeeNavigator, { disabledSameAsReadOnly: true, visibleDefault: showEmpNavIntegrationFields })}
                groupClass="groupClassAuto"
                visible={showEmpNavIntegrationFields}
                errors={errors.blockEmployeeNavigator}
                ref={register}
                onChange={handleRadioButtonChange}
              />
            </div>
            <PanelHeader title="Special Dates" />
            <div className="d-flex flex-wrap mt-3">
              <ControlDatePickerGrp
                {...fs.offerAcceptedDate}
                {...getAccess(sectionAccess, 39)}
                value={offerAcceptedDate}
                setValue={setValue}
                control={control}
              />
              <ControlDatePickerGrp
                {...fs.eesDate}
                {...getAccess(sectionAccess, 39)}
                value={eesDate}
                setValue={setValue}
                control={control}
              />
              <ControlDatePickerGrp
                {...fs.credentialNotifiedDate}
                {...getAccess(sectionAccess, 39)}
                value={credentialNotifiedDate}
                setValue={setValue}
                control={control}
              />
              <ControlDatePickerGrp
                {...fs.onboardBeginDate}
                {...getAccess(sectionAccess, 39)}
                value={onboardBeginDate}
                setValue={setValue}
                control={control}
              />
              <ControlDatePickerGrp
                {...fs.malpracticeAppSubmitted}
                {...getAccess(sectionAccess, 39)}
                groupStyle={{ minWidth: '130px' }}
                value={malpracticeAppSubmitted}
                setValue={setValue}
                control={control}
              />
              <ControlDatePickerGrp
                {...fs.malpracticeCertReceived}
                {...getAccess(sectionAccess, 39)}
                groupStyle={{ minWidth: '130px' }}
                value={malpracticeCertReceived}
                setValue={setValue}
                control={control}
              />
            </div>
            <PanelHeader title="Cares Act" />
            <div className="d-flex flex-wrap mt-3">
              <InputGrpCurrency
                {...fs.averageRateOfPay}
                {...getAccess(sectionAccess, 39)}
                setValue={setValue}
                disabled={averageRateOfPayDisabled}
                style={{
                  backgroundColor: averageRateOfPayDisabled
                    ? '#e9ecef'
                    : '#fff',
                }}
                errors={errors.averageRateOfPay}
                ref={register(CURRENCY_VALIDATION)}
              />
              <RadioGrp
                {...fs.averageRateOfPayOverride}
                {...getAccess(sectionAccess, 39, undefined, { disabledSameAsReadOnly: true })}
                errors={errors.averageRateOfPayOverride}
                onChange={onAverageRateOfPayOverride}
                ref={register}
              />
              <InputGrp
                {...fs.weeklyScheduledHours}
                {...getAccess(sectionAccess, 39, undefined, { disabledSameAsReadOnly: true })}
                errors={errors.weeklyScheduledHours}
                ref={register}
              />
              <RadioGrp
                {...fs.exemptRetention}
                {...getAccess(sectionAccess, 39, undefined, { disabledSameAsReadOnly: true })}
                errors={errors.exemptRetention}
                ref={register}
                onChange={handleRadioButtonChange}
              />
            </div>
            <PanelHeader title="COVID-19" />
            <div className="d-flex flex-wrap mt-3">
              <RadioGrp
                {...fs.employeeSocialSecurityDeferral}
                {...getAccess(sectionAccess, 39, undefined, { disabledSameAsReadOnly: true })}
                errors={errors.employeeSocialSecurityDeferral}
                ref={register}
                onChange={handleRadioButtonChange}
              />
            </div>

            <div className="row mt-4">
              <div className="col-12 text-right">
                <button
                  {...getAccess(sectionAccess, 39, undefined, { disabledDefault: !isDirty, disabledSameAsReadOnly: true })}
                  type="submit"
                  className="orange-button ml-2"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default SpecialInfoPage;
