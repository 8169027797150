import PanelHeader from 'core/components/shared/PanelHeader';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, Prompt } from 'react-router-dom';
import Icon from 'core/components/shared/Icon';
import {
  putDesignWebsite,
} from 'core/store/actions/applicant-tracking.action';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import { UNSAVED_MESSAGE } from 'core/constants';

const defaultValues = {
  website_title: '',
  website_address: '',
  company_website_url: '',
  employment_history: false,
  personal_information: false,
  education_history: false,
  training_history: false,
};

const SiteSettingsPage = () => {
  
  const dispatch = useAppDispatch();
  
  const { designWebsite } = useAppSelector((state) => {
    return state.applicantTrackingV2;
  });
  
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, isSubmitSuccessful },
  } = useForm({
    defaultValues,
  });
  
  const submitHandler = (data: typeof defaultValues) => {
    const payload = {
      pageId: 1,
      webSiteConfigId: designWebsite?.webSiteConfigId,
      hrEntityId: designWebsite?.hrEntityId,
      webSiteAddress: designWebsite?.webSiteAddress,
      webSiteTitle: data.website_title,
      webSiteURL: data.company_website_url,
      signatureDescription: designWebsite?.signatureDescription,
      webSiteImages: [],
      webSiteWaivers: [],
      webSiteCustomWaivers: [],
      personalInformation: {
        visible: data?.personal_information,
        pageFields: [],
        pageCustomFields: [],
      },
      employmentHistory: {
        visible: data?.employment_history,
        pageFields: [],
        pageCustomFields: [],
      },
      educationHistory: {
        visible: data?.education_history,
        pageFields: [],
        pageCustomFields: [],
      },
      trainingHistory: {
        visible: data?.training_history,
        pageFields: [],
        pageCustomFields: [],
      },
    };
    dispatch(putDesignWebsite(payload));
  };
  
  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    const confirmationMessage =
      'It looks like you have been editing something. ' +
      'If you leave before saving, your changes will be lost.';

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  };
  
  useEffect(() => {
    if (isDirty && !isSubmitSuccessful) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty, isSubmitSuccessful]);
 
  useEffect(() => {
    reset({
      company_website_url: designWebsite?.webSiteURL || '',
      education_history: designWebsite?.educationHistory?.visible || false,
      employment_history: designWebsite?.employmentHistory?.visible || false,
      personal_information:
        designWebsite?.personalInformation?.visible || false,
      training_history: designWebsite?.trainingHistory?.visible || false,
      website_address: designWebsite?.webSiteAddress || '',
      website_title: designWebsite?.webSiteTitle || '',
    });
  }, [designWebsite]);
  
  const [isCopied, setIsCopied] = useState(false);
  
  const copyWebsiteAddress = () => {
    const textField = document.createElement('textarea');
    textField.innerText = `https://ats.dmpayroll.com/#/${designWebsite?.webSiteAddress}/joblist`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    document.body.removeChild(textField);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2500);
  };
  
  const toolTipStyles: CSSProperties = { fontSize: '0.75rem' };
  
  const renderToolTip = (props: OverlayInjectedProps) => {
    return (
      <Tooltip
        id="info-tooltip"
        {...props}
      >
        <div style={toolTipStyles}>{isCopied ? 'Copied' : 'Copy URL'}</div>
      </Tooltip>
    );
  };
  
  return (
    <Row className="no-gutters p-0">
      <Prompt
        when={isDirty && !isSubmitSuccessful}
        message={UNSAVED_MESSAGE}
      />
      <form
        onSubmit={handleSubmit(submitHandler)}
        className="w-100"
      >
        <Col className="mt-1 shadow p-3 border">
          <PanelHeader
            title="Design ATS Website"
            iconName="wand-magic-sparkles"
          ></PanelHeader>
          <div className="bg-light mt-3 p-3">
            <h2 className="font-weight-bold">Configure Website</h2>
            <div className="w-100 d-flex justify-content-between">
              <div style={{ width: '47%' }}>
                <label>Website Title</label>
                <input
                  type="text"
                  className="w-100 px-1 py-1"
                  name="website_title"
                  ref={register({
                    required: 'Website Title is required',
                  })}
                />
              </div>
              <div style={{ width: '47%' }}>
                <label>
                  Website Address
                  <OverlayTrigger
                    placement="top"
                    overlay={renderToolTip}
                  >
                    <span>
                      <Icon
                        name={'link-horizontal'}
                        color={'#00558c'}
                        fontSize={12}
                        onClick={copyWebsiteAddress}
                        style={{
                          padding: 0,
                          marginLeft: '12px',
                          cursor: 'pointer',
                        }}
                      />
                    </span>
                  </OverlayTrigger>
                </label>
                <input
                  type="text"
                  className="w-100 px-1 py-1"
                  readOnly
                  disabled
                  name="website_address"
                  defaultValue={`https://ats.dmpayroll.com/#/${designWebsite?.webSiteAddress}/joblist`}
                  // ref={register({
                  //   required: 'Website Address is required',
                  // })}
                />
              </div>
            </div>
            <div className="w-100 d-flex justify-content-between mt-4">
              <div style={{ width: '47%' }}>
                <label>Company Website URL</label>
                <input
                  type="text"
                  className="w-100 px-1 py-1"
                  name="company_website_url"
                  ref={register({
                    required: 'Company Website URL is required',
                  })}
                />
              </div>
            </div>
            <hr />
            <div className="mt-3">
              <h2 className="font-weight-bold">
                Select pages to appear on website
              </h2>
            </div>
            <div className="mt-2 d-flex justify-content-between align-items-center w-100">
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  name="employment_history"
                  ref={register()}
                />
                <p className="m-0 ml-2">Employment History</p>
              </div>
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  name="personal_information"
                  ref={register()}
                />
                <p className="m-0 ml-2">Personal Information</p>
              </div>
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  name="education_history"
                  ref={register()}
                />
                <p className="m-0 ml-2">Education History</p>
              </div>
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  name="training_history"
                  ref={register()}
                />
                <p className="m-0 ml-2">Training History</p>
              </div>
            </div>
            <div className="mt-4">
              <Link to="/design-ats-website/web-pages">
                Review/Modify Page Form Requirements
              </Link>
            </div>
            <div className="mt-5 d-flex justify-content-end align-items-center w-100">
              <button
                type="button"
                onClick={() => {
                  return reset({
                    company_website_url: designWebsite?.webSiteURL || '',
                    education_history:
                      designWebsite?.educationHistory?.visible || false,
                    employment_history:
                      designWebsite?.employmentHistory?.visible || false,
                    personal_information:
                      designWebsite?.personalInformation?.visible || false,
                    training_history:
                      designWebsite?.trainingHistory?.visible || false,
                    website_address: designWebsite?.webSiteAddress || '',
                    website_title: designWebsite?.webSiteTitle || '',
                  });
                }}
                className="btn btn-light"
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`${
                  isDirty
                    ? 'button orange-button'
                    : 'btn btn-primary orange-outline-button'
                } ml-2`}
              >
                Save
              </button>
            </div>
          </div>
        </Col>
      </form>
    </Row>
  );
};

export default SiteSettingsPage;
