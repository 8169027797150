import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  Route,
  Switch,
} from 'react-router-dom';
import DesignInterviewSidebar from './DesignInterviewSidebar';
import InterviewProcessPage from './interview-process/InterviewProcess';
import InterviewTemplatesPage from './interview-templates/InterviewTemplates';
import InterviewQuestionsPage from './interview-questions/InterviewQuestions';
import InterviewLocationsPage from './interview-locations/InterviewLocations';
import CustomEmailsPage from './custom-emails/CustomEmails';
import ScorecardCategoriesPage from './scorecard-categories/ScorecardCategories';
import ScorecardTraitsPage from './scorecard-traits/ScorecardTraits';

const DesignInterviewPage = () => {
  return (
    <Col>
      <Row>      
        <Col sm={2}>
          <DesignInterviewSidebar />
        </Col>
        <Col className="pl-0">
          <Switch>
            <Route
              exact
              path="/design-interview"
              component={InterviewProcessPage}
            />  
            <Route
              exact
              path="/design-interview/process"
              component={InterviewProcessPage}
            />  
            <Route
              exact
              path="/design-interview/templates"
              component={InterviewTemplatesPage}
            />  
            <Route
              exact
              path="/design-interview/questions"
              component={InterviewQuestionsPage}
            />  
            <Route
              exact
              path="/design-interview/locations"
              component={InterviewLocationsPage}
            />            
            <Route
              exact
              path="/design-interview/custom-emails"
              component={CustomEmailsPage}
            />  
            <Route
              exact
              path="/design-interview/scorecard-categories"
              component={ScorecardCategoriesPage}
            />  
            <Route
              exact
              path="/design-interview/scorecard-traits"
              component={ScorecardTraitsPage}
            />              
          </Switch>
        
        </Col>
      </Row>
    </Col>
  );
};

export default DesignInterviewPage;
