import React, { useEffect, useState } from 'react';
import PanelHeader from 'core/components/shared/PanelHeader';
import ManageWorkgroupsModal from './ManageWorkgroupsModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadAttendanceOnDemand,
  loadClientAttendanceOnDemand,
  toggleBlockNavigation,
  updateAttendanceOnDemand,
} from 'core/store/actions';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AttendanceOnDemand, Employee } from 'core/models';
import {
  getAOD,
  getClientAOD,
  getSelectedEmp,
} from 'core/store/selectors';
import AttendanceOnDemandFields from './AttendanceOnDemandFields';
import AttendanceOnDemandWorkgroups from './AttendanceOnDemandWorkGroups';
import AttendanceOnDemandView from './AttendanceOnDemandView';
import { useAppSelector } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';
import { UNSAVED_MESSAGE } from 'core/constants';

type RouteParams = {
  protectedEmpNo: string;
};

const AttendanceOnDemandPage: React.FC = () => {
  const dispatch = useDispatch();
  const { protectedEmpNo } = useParams<RouteParams>();

  const [show, setShow] = useState(false);
  
  const selEmp = useSelector(getSelectedEmp) as Employee;
  const clientNo = useAppSelector((state) => { return state.client.client?.clientNo; });
  const clientAod = useAppSelector(({ selEmployeeDetails }) => selEmployeeDetails.attendanceOnDemand.clientAttendanceOnDemand);
    
  const { handleSubmit, reset, ...formMethods } = useForm<AttendanceOnDemand>(
    {
      defaultValues: new AttendanceOnDemand(
        '' + clientNo,
        protectedEmpNo,
        0,
      ),
    },
  );

  const AOD = useSelector(getAOD);
  const clientAOD = useSelector(getClientAOD);

  useEffect(() => {
    dispatch(loadClientAttendanceOnDemand({ suppressError: false }));
  }, []);

  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(loadAttendanceOnDemand(protectedEmpNo));
    }
  }, [protectedEmpNo]);

  useEffect(() => {
    if (!AOD) return;
    reset(AOD);
  }, [AOD]);

  const onAddWorkgroup = () => {
    setShow(true);
  };

  const onSave = (data: AttendanceOnDemand) => {
    const newAttendanceOnDemand = new AttendanceOnDemand(
      '' + clientNo,
      protectedEmpNo,
      selEmp.empId,
      {
        ...data,
        workgroupAssignmentDate: data?.workgroupAssignmentDate ?? AOD?.workgroupAssignmentDate, // doesn't look like this is actually nullable. CHECK THE TABLE BEFORE YOU PR THIS FELIX
      },
    );
    dispatch(updateAttendanceOnDemand(newAttendanceOnDemand));
    
    useEffect(() => {
      dispatch(toggleBlockNavigation({
        block: formMethods.formState.isDirty,
        message: UNSAVED_MESSAGE,
        type: 'confirmation',
      }));
    }, [formMethods.formState.isDirty]);
  };

  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Attendance On Demand">
        <button
          className="btn btn-link dm-grid-action-title p-0 mr-2"
          onClick={onAddWorkgroup}
        >
          Add/Edit Workgroup Levels{' '}
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button>
      </PanelHeader>

      <form onSubmit={handleSubmit(onSave)}>
        <div className="d-flex flex-row flex-wrap mt-3">
          <div className="d-flex flex-column mr-1">
            <AttendanceOnDemandView
              formMethods={formMethods}
              selEmp={selEmp}
            />
          </div>
          <div className="d-flex flex-column ml-1">
            <AttendanceOnDemandWorkgroups
              formMethods={formMethods}
              AOD={AOD}
            />
          </div>
          <div className="d-flex flex-column ml-1">
            <AttendanceOnDemandFields
              formMethods={formMethods}
              AOD={AOD}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <button
              type="submit"
              className="btn orange-button-sm mr-3"
              disabled={!formMethods.formState.isDirty}
            >
              Save
            </button>
          </div>
        </div>
      </form>

      {show && (
        <ManageWorkgroupsModal
          show={show}
          onHide={() => { return setShow(false); }}
          workgroups={clientAOD?.dropdowns?.workgroups || []}
        />
      )}
    </div>
  );
};

export default AttendanceOnDemandPage;
