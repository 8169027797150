import { loadEEOCertifyOfficials } from 'core/store/actions';
import { getLookupEEOCertifyOfficial } from 'core/store/selectors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Button } from 'react-bootstrap';
import CertifyingOfficerModal from './CertifyingOfficerModal';
import { LookupEEOCertifyOfficial } from 'core/models';

const CertifyingOfficer = () => {
  const dispatch = useDispatch();
  const certifyingOfficersRecordsData = useSelector(getLookupEEOCertifyOfficial);
  const [data, setData] = useState(certifyingOfficersRecordsData);
  const [isModalShowns, setIsModalShown] = useState<boolean>(false);
  const [selectedOfficer, setSelectedOfficer] = useState<LookupEEOCertifyOfficial>();

  useEffect(() => {
    dispatch(loadEEOCertifyOfficials());
  }, []);

  useEffect(() => {
    setData(cloneDeep(certifyingOfficersRecordsData));    
  }, [certifyingOfficersRecordsData]); 
  
  useEffect(() => {
    data.length && setSelectedOfficer(data[0]);
  }, [data]);

  return (
    <div>
      <div
        id="certifyingOfficers"
        className="p-2"
        style={{ backgroundColor: '#F0F6FC' }}
      >
        <div className="dm-grid-title mb-2">Certifying Officer</div>
        <div
          className="d-flex align-items-center"
          style={{ columnGap: '1rem' }}
        >
          <Button
            type="button"
            onClick={() => {
              setIsModalShown(true);
            }}
          >
            Add/Edit
          </Button>
          {!!selectedOfficer && (
            <div
              className="d-flex align-items-center"
              style={{ columnGap: '1rem' }}
            >
              <p className="mb-0"><strong>Name</strong>: {selectedOfficer?.name}</p>
              <p className="mb-0"><strong>Phone</strong>: {selectedOfficer?.telphone}</p>
              <p className="mb-0"><strong>Email</strong>: {selectedOfficer?.emailAddress}</p>
              <p className="mb-0"><strong>Title</strong>: {selectedOfficer?.title}</p>
            </div>
          )}
        </div>
      </div>
      {isModalShowns &&
        <CertifyingOfficerModal
          show={isModalShowns}
          onHide={() => {
            return setIsModalShown(false);
          }}
          data={data}
          selectUnit={(unit:LookupEEOCertifyOfficial)=>{return setSelectedOfficer(unit);}}
        />
      }
    </div>
  );
};

export default CertifyingOfficer;