import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EmpDocument, HttpResponse, TaxDocument } from '../../models';
import { EmployeeService } from '../../services';
import {
  handleError,
  loadEmpDocumentPDF,
  loadEmpDocuments,
  loadTaxDocumentPDF,
  loadTaxDocuments,
  storeEmpDocumentPDF,
  storeEmpDocuments,
  storeTaxDocumentPDF,
  storeTaxDocuments,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadTaxDocuments$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadTaxDocuments.type),
    switchMap((action: { payload: string; }) => {
      return from(EmployeeService.getTaxDocuments(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map((res: TaxDocument) => {return storeTaxDocuments(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadTaxDocumentPDF$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadTaxDocumentPDF.type),
    switchMap((action: { payload: string; }) => {
      return from(EmployeeService.getDocumentPDF(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map((res: any) => {return storeTaxDocumentPDF(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadEmpDocuments$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmpDocuments.type),
    switchMap((action: { payload: string; }) => {
      return from(EmployeeService.getEmpDocuments(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map((res: EmpDocument) => {return storeEmpDocuments(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadEmpDocumentPDF$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmpDocumentPDF.type),
    switchMap((action: { payload: string; }) => {
      return from(EmployeeService.getDocumentPDF(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map((res: any) => {return storeEmpDocumentPDF(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadTaxDocuments$,
  loadTaxDocumentPDF$,

  loadEmpDocuments$,
  loadEmpDocumentPDF$,
];
