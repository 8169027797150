import React from 'react';
import { InputGrp } from 'core/components/form-controls';
import { SelectGrp } from 'core/components/form-controls/SelectGrp';
import { formatWithCommas } from 'utilities/utilities';

const deductions = [1];

type Props = {
  index: number;
  errors: any;
};

const AdjustmentDeductions = ({ index, errors }: Props) => {
  return (
    <>
      {deductions.map((deduction, di) => {
        return (
          <div
            className="d-flex flex-row"
            key={deduction}
          >
            <SelectGrp
              groupClass="gc03 mw150 mt-4"
              options={[]}
              name={`items[${index}].deductions[${di}].dedNo`}
              addEmptyValue={true}
              errorMsg="Required"
              errors={errors.dedNo}
              defaultValue={''}
              readOnly={true}
              showId={true}
            />
            <InputGrp
              groupClass="gc03 number-underline mw125"
              label="Amount"
              type="text"
              name={`items[${index}].deductions[${di}].amount`}
              defaultValue={formatWithCommas(0)}
              readOnly={true}
            />
          </div>
        );
      })}
    </>
  );
};

export default AdjustmentDeductions;
