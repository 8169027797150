import { createSelector } from 'reselect';
import { humanCapitalReportState } from '../reducers';
import { State } from '../reducers/human-capital.reducer';
import { ClientReportOptionType } from 'core/models/HumanCapitalReports.model';

export const getCumulativeSeniorityReport = createSelector(
  humanCapitalReportState,
  (state: State) => { return state?.cumulativeSeniorityReport; },
);
export const getEmployeeNavigatorAuditReport = createSelector(
  humanCapitalReportState,
  (state:
  State) => { return state?.employeeAuditNavigatorReport; },
);
export const getFullTimeEquivalentReport = createSelector(
  humanCapitalReportState,
  (state: State) => { return state?.fulltimeEquivalentReport; },
);
export const getDemogrpahicAndTurnoverReport = createSelector(
  humanCapitalReportState,
  (state: State) => { return state?.demographicAndTurnoverEmployees; },
);

export const getDepartmentofLaborReport = createSelector(
  humanCapitalReportState,
  (state: State) => { return state?.departmentofLaborReport; },
);

export const getACAProofListReport = createSelector(
  humanCapitalReportState,
  (state: State) => { return state?.acaProofListReport; },
);

export const getReportOptions = (reportType: ClientReportOptionType) => {
  return createSelector(
    humanCapitalReportState,
    (state: State) => {
      return state?.clientReportOptions?.find((reportOptionState) => {
        return reportOptionState?.clientReportOptionType === reportType;
      });
    },
  );
};
