import { ICellRendererParams } from '@ag-grid-community/core';

export function agCurrencyRenderer(): any {}

agCurrencyRenderer.prototype.init = function (params: ICellRendererParams) {
  this.divWrapper = document.createElement('div');
  this.divWrapper.innerHTML = this.formatter(params.value);
};

agCurrencyRenderer.prototype.formatter = function (value: any) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  if (value) {
    return formatter.format(+value);
  } else {
    return formatter.format(0);
  }
};
agCurrencyRenderer.prototype.getGui = function (params: ICellRendererParams) {
  return this.divWrapper;
};

agCurrencyRenderer.prototype.refresh = function (params: ICellRendererParams) {
  this.divWrapper.innerHTML = this.formatter(params.value);
  return true;
};

agCurrencyRenderer.prototype.destroy = function () {};
