import { ofType } from 'redux-observable';
import { catchError, map,  mergeMap,  switchMap } from 'rxjs/operators';
import { handleError, handleSuccess, handleWarning } from '../actions/app.action';
import { EEOService } from '../../services';
import { EEONoSetting, EEOUnitSetting, HttpResponse, LookupEEOCertifyOfficial, LookupEEOUnit } from '../../models';
import { Observable, from } from 'rxjs';
import { EEOPayrollDate } from 'core/models/HumanCapitalReports.model';
import { AxiosResponse } from 'axios';
import {
  createEEOCertifyOfficial,
  deleteEEOCertifyOfficial,
  deleteEEONoSettings,
  deleteEEOUnitSettings,
  deleteLookupEEOUnit,
  downloadEEOReport,
  getEEOPayrollDates,
  loadEEOCertifyOfficial,
  loadEEOCertifyOfficials,
  loadEEONoSettings,
  loadEEOSettings,
  loadEEOUnitSettings,
  loadLookupEEOUnit,
  loadLookupEEOUnits,
  postEEONoSettings,
  postEEOUnitSettings,
  putEEONoSettings,
  putEEOUnitSettings,
  storeEEOCertifyOfficial,
  storeEEOCertifyOfficials,
  storeEEONoSettings,
  storeEEOPayrollDates,
  storeEEOReport,
  storeEEOUnitSettings,
  storeLookupEEOUnit,
  storeLookupEEOUnits,
  updateEEOCertifyOfficial,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadEEOUnitSettings$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEEOUnitSettings.type),
    switchMap(() => {
      return from(EEOService.getEEOUnitSettings()).pipe(
        map((res) => {return res.data;}),
        map((res: EEOUnitSetting[]) => {return storeEEOUnitSettings(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};


const postEEOUnitSettings$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(postEEOUnitSettings.type),
    switchMap((action) => {
      return from(EEOService.postEEOUnitSettings(action.payload)).pipe(
        map((res) => {return res.data;}),
        map(() => { return loadEEOSettings(); }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const putEEOUnitSettings$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(putEEOUnitSettings.type),
    switchMap((action) => {
      return from(EEOService.putEEOUnitSettings(action.payload)).pipe(
        map((res) => {return res.data;}),
        map(() => { return loadEEOUnitSettings(); }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const deleteEEOUnitSettings$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteEEOUnitSettings.type),
    switchMap((action) => {
      return from(EEOService.deleteEEOUnitSettings(action.payload)).pipe(
        map((res) => {return res.data;}),
        map(() => { return loadEEOUnitSettings(); }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};


const deleteEEONoSettings$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteEEONoSettings.type),
    switchMap((action) => {
      return from(EEOService.deleteEEONoSettings(action.payload)).pipe(
        map((res:any) => {return res.data;}),
        map(() => {return loadEEONoSettings();},
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};


const loadEEONoSettings$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEEONoSettings.type),
    switchMap(() => {
      return from(EEOService.getEEONoSettings()).pipe(
        map((res) => {return res.data;}),
        map((res: EEONoSetting[]) => {return storeEEONoSettings(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const putEEONoSettings$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(putEEONoSettings.type),
    switchMap((action) => {
      return from(EEOService.putEEONoSettings(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map(() => { return loadEEONoSettings(); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postEEONoSettings$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(postEEONoSettings.type),
    switchMap((action) => {
      return from(EEOService.postEEONoSettings(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map(() => { return loadEEONoSettings(); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadLookupEEOUnits$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadLookupEEOUnits.type),
    switchMap(() => {
      return from(EEOService.getLookupEEOUnits()).pipe(
        map((res: any) => { return res.data; }),
        map((res: LookupEEOUnit[]) => { return storeLookupEEOUnits(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadLookupEEOUnit$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadLookupEEOUnit.type),
    switchMap((action: { payload: number; }) => {
      return from(EEOService.getLookupEEOUnit(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: LookupEEOUnit) => { return storeLookupEEOUnit(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteLookupEEOUnit$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteLookupEEOUnit.type),
    switchMap((action: { payload: LookupEEOUnit; }) => {
      return from(EEOService.deleteLookupEEOUnit(action.payload)).pipe(
        map(() => { return loadLookupEEOUnits(); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadEEOCertifyOfficials$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEEOCertifyOfficials.type),
    switchMap(() => {
      return from(EEOService.getEEOCertifyOfficials()).pipe(
        map((res: any) => { return res.data; }),
        map((res: LookupEEOCertifyOfficial[]) => { return storeEEOCertifyOfficials(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadEEOCertifyOfficial$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEEOCertifyOfficial.type),
    switchMap((action: { payload: number; }) => {
      return from(EEOService.getEEOCertifyOfficial(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: LookupEEOCertifyOfficial) => { return storeEEOCertifyOfficial(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createEEOCertifyOfficial$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createEEOCertifyOfficial.type),
    switchMap((action: { payload: LookupEEOCertifyOfficial; }) => {
      return from(EEOService.postEEOCertifyOfficial(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map(() => { 
          return loadEEOCertifyOfficials(); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEEOCertifyOfficial$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateEEOCertifyOfficial.type),
    switchMap((action: { payload: LookupEEOCertifyOfficial; }) => {
      return from(EEOService.putEEOCertifyOfficial(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map(() => { 
          return loadEEOCertifyOfficials(); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteEEOCertifyOfficial$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteEEOCertifyOfficial.type),
    switchMap((action: { payload: LookupEEOCertifyOfficial; }) => {
      return from(EEOService.deleteEEOCertifyOfficial(action.payload)).pipe(
        map(() => { 
          return loadEEOCertifyOfficials();
        }),
        catchError((err: AxiosResponse<HttpResponse<any>>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};



const downloadEEOReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadEEOReport.type),
    switchMap((action) => {
      return from(EEOService.postEEOReport(action.payload)).pipe(
        map((res) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeEEOReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getEEOPayrollDates$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(getEEOPayrollDates.type),
    switchMap((action) => {
      return from(EEOService.getEEOPayrollDates(action.payload)).pipe(
        map((res) => { return res.data; }),
        map((res: EEOPayrollDate[]) => { return storeEEOPayrollDates(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};


export const epics: any[] = [
  loadEEONoSettings$,
  postEEONoSettings$,
  putEEONoSettings$,
  deleteEEONoSettings$,

  loadEEOUnitSettings$,
  postEEOUnitSettings$,
  putEEOUnitSettings$,
  deleteEEOUnitSettings$,
  

  loadLookupEEOUnits$,
  loadLookupEEOUnit$,
  deleteLookupEEOUnit$,

  loadEEOCertifyOfficials$,
  loadEEOCertifyOfficial$,
  createEEOCertifyOfficial$,
  updateEEOCertifyOfficial$,
  deleteEEOCertifyOfficial$,

  downloadEEOReport$,
  getEEOPayrollDates$,
];
