import React from 'react';
import styles from './styles.module.scss';
import sharedStyles from 'core/components/shared/shared.module.scss';
import { convToDateString } from 'utilities/utilities';
import { colorScheme, getStatusIcon } from './constants';
import Icon from 'core/components/shared/Icon';
import { WorkFlowListDetails, WorkFlowStatus, WorkFlowTaskList } from 'core/models/WorkFlow.model';

type Props = {
  workFlowTask: WorkFlowTaskList;
  status: WorkFlowStatus;
};

const ChangeRequestDetails = ({ workFlowTask, status }: Props) => {
  return (
    <div className={styles['change-request-details']}>
      <div className={sharedStyles['section-title-blue']}>
        Details
      </div>
      <hr className="dm-panel-hr" />
        <table className={`base-table-white sortable ${styles['workflow-table']} mt-1`}>
            <tbody>
            <tr key = {-1}>
              <td>
                <span className="font-weight-bold">Initiated</span> by <span className="font-weight-bold">{workFlowTask.initiator}</span>&nbsp;for employee&nbsp;
                <span className="font-weight-bold">{workFlowTask.targetEmployeeName} #({workFlowTask.targetEmpNo})</span>&nbsp;on&nbsp;
                <span className="font-weight-bold">{convToDateString(workFlowTask.startDate)}</span>
              </td>
            </tr>
            {workFlowTask.details.map((detail) => {
              if(detail.status === 'Pending'){
                return (<tr key = {detail.workFlowLevelUserId}>
                  <td>
                    <span className="mr-2">{getStatusIcon(detail.status)}</span>&nbsp;
                    <span className="font-weight-bold">Currently {detail.status}</span> as of <span className="font-weight-bold">{convToDateString(detail.createDate)} </span>
                    waiting for someone in level <span className="font-weight-bold">({detail.levelDescription})</span> to view request.
                  </td>
                </tr>);
              }
              else{
                return (<tr key = {detail.workFlowLevelUserId}>
                  <td>
                    <span className="mr-2">{getStatusIcon(detail.status)}</span>&nbsp;
                    <span className="font-weight-bold">{detail.status}</span> by <span className="font-weight-bold">{detail.user}</span> ({detail.levelDescription})
                     on <span className="font-weight-bold">{convToDateString(detail.changeDate)}</span>
                  </td>
                </tr>);
              }
              
            })}
            </tbody>
          </table>
    </div>
  );
};

export default ChangeRequestDetails;