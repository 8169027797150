import React, { useState, useEffect } from 'react';

import {
  ControlDatePickerGrp,
  SelectGrp,
  SelectModalGrp,
  TextareaGrp,
} from '../../../core/components/form-controls';
import { HRReview } from '../../../core/models/HREmployee.model';
import { ArrayField } from 'react-hook-form';
import { useSelector } from 'react-redux';
import DropdownOptionForm from '../../../core/components/form-controls/select-modal/DropdownOptionForm';
import {
  getEmployees,
  getPerformanceActionCodes,
  getPerformanceRatingCodes,
  getPerformanceReviewCodes,
} from '../../../core/store/selectors';
import { Employee } from '../../../core/models';
import Icon from 'core/components/shared/Icon';

type PropTypes = {
  item: Partial<ArrayField<HRReview>>;
  index: number;
  control: any;
  formMethods: any,
  onDelete: (index: number) => void;
};

const ReviewsItem: React.FC<PropTypes> = ({
  item,
  index,
  control,
  formMethods: { setValue, register },
  onDelete,
}) => {

  const performanceReviewCodes = useSelector(getPerformanceReviewCodes);
  const performanceActionCodes = useSelector(getPerformanceActionCodes);
  const performanceRatingCodes = useSelector(getPerformanceRatingCodes);
  const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([]);

  const employees = useSelector(getEmployees);

  useEffect(() => {
    setFilteredEmployees(
      employees
        .filter((e: Employee) => { return !e.termDate; })
        .map((x: Employee) => {
          return {
            ...x,
            evaluatorEmpId: x.empId,
            description: x.lastName + ', ' + x.firstName,
          };
        }),
    );
  }, [employees]);


  return (

    <div className="d-flex flex-column">
      <div className="d-flex flex-wrap">
        <input
          type="hidden"
          name={`performanceReviews[${index}].empReviewId`}
          defaultValue={item.empReviewId}
          ref={register({ valueAsNumber: true })}
        />
        <input
          type="hidden"
          name={`performanceReviews[${index}].evaluatorId`}
          defaultValue={item.evaluatorId}
          ref={register({ valueAsNumber: true })}
        />
        <SelectModalGrp
          noOption={true}
          groupClass="gc12 mw125"
          modalTitle="REVIEW TYPE"
          label={'REVIEW TYPE'}
          formComponent={DropdownOptionForm}
          addOptionText="Review Type"
          name={`performanceReviews[${index}].reviewTypeId`}
          dropdownName="PerformanceReviewCodes"
          options={performanceReviewCodes}
          control={control}
          value={item.reviewTypeId}
          setValue={setValue}
        />

        <SelectModalGrp
          noOption={true}
          groupClass="gc12 mw125"
          modalTitle="ACTION"
          label={'ACTION'}
          formComponent={DropdownOptionForm}
          addOptionText="Action"
          name={`performanceReviews[${index}].actionCodeId`}
          dropdownName="PerformanceActionCodes"
          options={performanceActionCodes}
          control={control}
          value={item.actionCodeId}
          setValue={setValue}
        />

        <SelectModalGrp
          noOption={true}
          groupClass="gc12 mw125"
          modalTitle="RATING CODE"
          label={'RATING CODE'}
          formComponent={DropdownOptionForm}
          addOptionText="Rating Code"
          name={`performanceReviews[${index}].ratingCodeId`}
          dropdownName="PerformanceRatingCodes"
          options={performanceRatingCodes}
          control={control}
          value={item.ratingCodeId}
          setValue={setValue}
        />
        {filteredEmployees.length > 0 ? <SelectGrp
          groupClass="gc12 mw125"
          label={'EVALUATOR'}
          addEmptyValue={true}
          name={`performanceReviews[${index}].evaluatorEmpId`}
          options={filteredEmployees}
          valueField="evaluatorEmpId"
          control={control}
          ref={register({ valueAsNumber: true })}
          defaultValue={item.evaluatorEmpId}
        /> : null}
        <ControlDatePickerGrp
          name={`performanceReviews[${index}].dateOfEvaluation`}
          label="EVALUATION DATE"
          groupClass="gc12 mw125"
          value={item.dateOfEvaluation}
          setValue={setValue}
          control={control}
        />
        <ControlDatePickerGrp
          name={`performanceReviews[${index}].nextEvaluateDate`}
          label="NEXT EVALUATION"
          groupClass="gc12 mw125"
          value={item.nextEvaluateDate}
          setValue={setValue}
          control={control}
        />

      </div>
      <div className="d-flex flex-grow-1">
        <TextareaGrp
          label="Comments"
          name={`performanceReviews[${index}].comments`}
          defaultValue={item.comments}
          groupClass="gc12"
          ref={register()} />
      </div>
      <div className="text-right">
        <button
          type="button"
          className="btn btn-link dm-grid-action-title"
          onClick={() => { return onDelete(index); }}
        >
          Delete Review
          <Icon name="minus-circle"
            className="fa-minus-circle" />
        </button>
      </div>
    </div>
  );
};

export default ReviewsItem;
