import { DependencyList, useEffect, useRef, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RadioYesNoProps } from '../core/components/form-controls/RadioGrp';
import { FieldInputSettings } from '../core/components/form-controls/types';
import { AppDispatch, RootState } from '../core/store/store';

export const useRadioButtonChange = (state: FieldInputSettings): [any] => {
  const [fs, setFs] = useState(state);
  const handleRadioButtonChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const currentState = { ...fs };
    currentState[ev.target.name].defaultChecked = ev.target.value;
    setFs(currentState);
  };
  return [handleRadioButtonChange];
};

export const useRadioButtonChangeYesNoProps = (state: RadioYesNoProps[]): [any] => {
  const [fs, setFs] = useState<RadioYesNoProps[]>(state);
  const handleRadioButtonChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentState = [...fs];
    currentState.find(({ name }) => name === ev.target.name)!.defaultChecked = ev.target.value;
    setFs(currentState);
  };
  return [handleRadioButtonChange];
};

/**
 * Similar to a regular effect, but designed to run in the same phase that the componentDidUpdate lifecycle method did,
 * which is only when any of the dependencies change and not the first render.
 * @param fn The function to run on second render and beyond, contingent on the dependencies.
 * @param deps What will make the effect run again (just like a regular effect)
 */
export const useEffectOnMount = <T extends unknown>(fn: () => T, deps: DependencyList) => {
  const componentDidMount = useRef(false);

  useEffect(() => {
    if (componentDidMount.current) {
      fn();
    } else {
      componentDidMount.current = true;
    }
  }, deps); // dependencies are required in this case, otherwise the effect will never run
};

export const useDebounce = (delay = 500) => {
  const [data, setData] = useState<any>(null);
  const [dataQuery, setDataQuery] = useState<any>(null);

  useEffect(() => {
    const delayFn = setTimeout(() => {return setData(dataQuery);}, delay);

    return () => {return clearTimeout(delayFn);};
  }, [dataQuery, delay]);

  return [data, setDataQuery];
};

export const useAppDispatch = () => {return useDispatch<AppDispatch>();};
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
