import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { EEONoSetting, LookupEEOCertifyOfficial, LookupEEOUnit } from '../../models/EEO.model';
import { EEOPayrollDate, EEOReportRequest, EEOSettingsRequest, EEOUnitSetting, IEEONoSetting } from 'core/models/HumanCapitalReports.model';
import { handleError, handleSuccess } from './app.action';
import { EEOService } from 'core/services';

export const getEEOPayrollDates = createAction<number>('[eeo] LOAD_EEO_PAYROLL_DATES');
export const clearEEOPayrollDates = createAction('[eeo] CLEAR_EEO_PAYROLL_DATES');
export const storeEEOPayrollDates = createAction<EEOPayrollDate[]>('[eeo] STORE_EEO_PAYROLL_DATES');


export const downloadEEOReport = createAction<EEOReportRequest>('[eeo] DOWNLOAD_EEO_REPORT');
export const clearEEOReport = createAction('[eeo] CLEAR_EEO_REPORT');
export const storeEEOReport = createAction<string>('[eeo] STORE_EEO_REPORT');

export const loadEEOSettings = createAction('[eeo] LOAD_EEO_SETTINGS');
export const storeEEOSettings = createAction<EEOSettingsRequest>('[eeo] STORE_EEO_SETTINGS');
export const createEEOSettings = createAction<EEOSettingsRequest>('[eeo] CREATE_EEO_SETTINGS');
export const updateEEOSettings = createAction<EEOSettingsRequest>('[eeo] UPDATE_EEO_SETTINGS');
export const deleteEEOSettings = createAction<EEOSettingsRequest>('[eeo] DELETE_EEO_SETTINGS');

export const loadEEONoSettings = createAction('[eeo] LOAD_EEO_NO_SETTINGS');
export const storeEEONoSettings = createAction<EEONoSetting[]>('[eeo] STORE_EEO_NO_SETTINGS');
export const storeEEONoSetting = createAction<EEONoSetting[]>('[eeo] STORE_EEO_NO_SETTING');
export const putEEONoSettings = createAction<EEONoSetting | IEEONoSetting>('[eeo] PUT_EEO_NO_SETTINGS');
export const deleteEEONoSettings = createAction<EEONoSetting | IEEONoSetting>('[eeo] DELETE_EEO_NO_SETTINGS');
export const postEEONoSettings = createAction<EEONoSetting | IEEONoSetting>('[eeo] POST_NO_SETTINGS');


export const loadEEOUnitSettings = createAction('[eeo] LOAD_UNIT_SETTINGS');
export const putEEOUnitSettings = createAction<EEOUnitSetting>('[eeo] PUT_UNIT_SETTINGS');
export const deleteEEOUnitSettings = createAction<EEOUnitSetting>('[eeo] DELETE_UNIT_SETTINGS');
export const postEEOUnitSettings = createAction<EEOUnitSetting>('[eeo] POST_UNIT_SETTINGS');
export const storeEEOUnitSettings = createAction<EEOUnitSetting[]>('[eeo] STORE_UNIT_SETTINGS');
export const storeEEOUnitSetting = createAction<EEOUnitSetting>('[eeo] STORE_UNIT_SETTING');


export const loadLookupEEOUnits = createAction('[eeo] LOAD_LOOKUP_UNITS');
export const storeLookupEEOUnits = createAction<LookupEEOUnit[]>('[eeo] STORE_LOOKUP_UNITS');
export const loadLookupEEOUnit = createAction<number>('[eeo] LOAD_UNIT');
export const storeLookupEEOUnit = createAction<LookupEEOUnit>('[eeo] STORE_LOOKUP_UNIT');
export const deleteLookupEEOUnit = createAction<LookupEEOUnit>('[eeo] DELETE_LOOKUP_UNIT');

export const loadEEOCertifyOfficials = createAction('[eeo] LOAD_CERTIFY_OFFICIALS');
export const storeEEOCertifyOfficials = createAction<LookupEEOCertifyOfficial[]>('[eeo] STORE_CERTIFY_OFFICIALS');
export const loadEEOCertifyOfficial = createAction<number>('[eeo] LOAD_CERTIFY_OFFICIAL');
export const createEEOCertifyOfficial = createAction<LookupEEOCertifyOfficial>('[eeo] CREATE_CERTIFY_OFFICIAL');
export const updateEEOCertifyOfficial = createAction<LookupEEOCertifyOfficial>('[eeo] UPDATE_CERTIFY_OFFICIAL');
export const storeEEOCertifyOfficial = createAction<LookupEEOCertifyOfficial>('[eeo] STORE_CERTIFY_OFFICIAL');
export const deleteEEOCertifyOfficial = createAction<LookupEEOCertifyOfficial>('[eeo] DELETE_CERTIFY_OFFICIAL');

export const upsertEEOCertifyOfficialUnit =
	createAction<{ certOff: LookupEEOCertifyOfficial, unit: LookupEEOUnit }>('[eeo] UPSERT_CERTIFY_OFFICIAL_UNIT');

// thunks
export const createLookupEEOUnit = createAsyncThunk(
  '[eeo] UPDATE_LOOKUP_UNIT',
  async (request: LookupEEOUnit, { dispatch }) => {
    try {
      const { data } = await EEOService.postLookupEEOUnit(request);
      
      dispatch(handleSuccess(data.messages));
      dispatch(loadLookupEEOUnits()); // ...why?
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const updateLookupEEOUnit = createAsyncThunk(
  '[eeo] UPDATE_LOOKUP_UNIT',
  async (request: LookupEEOUnit, { dispatch }) => {
    try {
      const { data } = await EEOService.putLookupEEOUnit(request);
      
      dispatch(handleSuccess(data.messages));
      dispatch(loadLookupEEOUnits()); // ...why? 2
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);