import { createReducer } from '@reduxjs/toolkit';
import {
  storeBeginDate,
  storeCheckDate,
  storeStandardReports,
  storeEndDate,
  storeEmployeeWageDetailDownloadReport,
  clearEmployeeWageDetailDownloadReport,
} from '../actions/earnings-reports.action';

export interface EarningsReportsState {
  checkDate: boolean;
  beginDate: string;
  endDate: string;
  report: string;
  employeeWageDetailDownloadReport: string;
}

const INITIAL_STATE: EarningsReportsState = {
  beginDate: '',
  endDate: '',
  checkDate: true,
  report: '',
  employeeWageDetailDownloadReport: '',
};

export const earningsReportsReducer = createReducer(
  INITIAL_STATE,
  (builder) => {
    builder
      .addCase(storeBeginDate, (state, action) => {
        state.beginDate = action.payload;
      })
      .addCase(storeEndDate, (state, action) => {
        state.endDate = action.payload;
      })
      .addCase(storeCheckDate, (state, action) => {
        state.checkDate = action.payload;
      })
      .addCase(storeStandardReports, (state, action) => {
        state.report = action.payload;
      })
      .addCase(storeEmployeeWageDetailDownloadReport, (state, action) => {
        state.employeeWageDetailDownloadReport = action.payload;
      })
      .addCase(clearEmployeeWageDetailDownloadReport, (state) => {
        state.employeeWageDetailDownloadReport = '';
      });

  },
);
