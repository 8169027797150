import { createReducer } from '@reduxjs/toolkit';
import { IntegrationLog } from '../../models';
import {
  loadIntegrationLogs,
  storeIntegrationLogs
} from '../actions';

export interface IntegrationState {
  loading: boolean;
  status: any;
  integrationLogs: IntegrationLog[]
}

export const INITIAL_STATE: IntegrationState = {
  loading: false,
  status: null,
  integrationLogs: [],
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadIntegrationLogs.type]: (state) => {
    return { ...state,
      loading: true };
  },
  [storeIntegrationLogs.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      integrationLogs: action.payload,
    };
  },
});
