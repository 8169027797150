import Icon from 'core/components/shared/Icon';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AccrualEdit, AccrualRecord } from 'core/models';
import { getSelectedEmp } from 'core/store/selectors';
import { convToFormObject } from 'utilities/classUtils';
import { convToDateString, getAccess } from 'utilities/utilities';
import AccrualRecordItemModal from './AccrualRecordItem.modal';
import { useAppSelector } from 'utilities/hooks';

const formatTo4Decimals = (value: number) => { return parseFloat('' + value).toFixed(4); };

type PropTypes = {
  accrualRecords: AccrualRecord[];
  type: string;
};

const AccrualRecordItem: React.FC<PropTypes> = ({ accrualRecords, type }) => {
  const selectedEmp = useSelector(getSelectedEmp);
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(
    new AccrualEdit(
      selectedEmp?.clientNo ?? 0,
      selectedEmp?.empNo ?? 0,
      selectedEmp?.protectedEmpNo ?? '',
      0,
      0,
      type,
    ),
  );

  const onEdit = (data: AccrualRecord) => {
    setSelectedItem(
      convToFormObject(
        new AccrualEdit(
          selectedEmp?.clientNo ?? 0,
          selectedEmp?.empNo ?? 0,
          selectedEmp?.protectedEmpNo ?? '',
          data.balanceId,
          data.recordId,
          data.type,
          {
            amount:
              data.type === 'Earned' ? data.earned : data.taken,
            note: data.note,
          },
        ),
        AccrualRecord.convProps,
      ),
    );
    setShow(true);
  };

  return (
    <div>
      <table className="table table-bordered table-hover w-100">
        <colgroup>
          <col style={{ width: '10%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '50%' }} />
          <col style={{ width: '5%' }} />
        </colgroup>
        <thead className="thead-light">
          <tr>
            <th>Week End</th>
            <th>Check Date</th>
            <th>Date Entered</th>
            <th>Earned</th>
            <th>Taken</th>
            <th>Balance</th>
            <th>Notes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {accrualRecords.map((ar: AccrualRecord) => {
            return (
              <tr key={ar.recordId}>
                <td className="text-right">
                  {convToDateString(ar.weekEnd)}
                </td>
                <td className="text-right">
                  {convToDateString(ar.checkDate)}
                </td>
                <td className="text-right">
                  {convToDateString(ar.dateEntered)}
                </td>
                <td
                  className={`text-right ${ar.type.includes('Earned') ? '' : 'bg-light'}`}
                >
                  {ar.type === 'Earned' ? formatTo4Decimals(ar.earned) : ' '}
                </td>
                <td
                  className={`text-right ${ar.type.includes('Taken') ? '' : 'bg-light'}`}
                >
                  {ar.type.includes('Taken') ? formatTo4Decimals(ar.taken) : ' '}
                </td>
                <td className="text-right">
                  {formatTo4Decimals(ar.balance)}
                </td>
                <td>{ar.note}</td>
                <td className="text-center">
                  <button
                    className="btn btn-link p-0 m-0"
                    onClick={() => { onEdit(ar); }}
                    {...getAccess(sectionAccess, 24, undefined, { disabledSameAsReadOnly: true })}
                  >
                    <Icon
                      name="pen"
                      className="fa-pen"
                    />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {show && (
        <AccrualRecordItemModal
          item={selectedItem}
          show={show}
          onHide={() => { return setShow(false); }}
        />
      )}
    </div>
  );
};

export default AccrualRecordItem;
