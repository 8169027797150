import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import closeIcon from '../../../assets/images/times-circle.png';
import { RadioGrp } from '../../../core/components/form-controls';
import { RadioOptions } from '../../../core/components/form-controls/RadioGrp';
import { HRVaccinationReportRequest } from '../../../core/models/HREmployee.model';
import { CommonService } from '../../../core/services';
import { clearHRVaccinationReport, downloadHRVaccinationReport } from '../../../core/store/actions/hr-employee.action';
import { getVaccinationReport } from '../../../core/store/selectors/hr-employee.selector';

interface Props {
  show: boolean;
  onHide: () => void;
}

const employeeRadioOptions: RadioOptions[] = [
  {
    value: 'All',
    label: 'All',
  },
  {
    value: 'Active',
    label: 'Active Employees',
  },
  {
    value: 'Terminated',
    label: 'Terminated Employees',
  },
];

const statusRadioOptions: RadioOptions[] = [
  {
    value: 'All',
    label: 'All',
  },
  {
    value: 'Vaccinated',
    label: 'Vaccinated',
  },
  {
    value: 'PartialVaccinated',
    label: 'Partially Vaccinated',
  },
  {
    value: 'Exempt',
    label: 'Exempt',
  },
  {
    value: 'Unvaccinated',
    label: 'Unvaccinated',
  },
];

const VaccinationReportModal: React.FC<Props> = ({ show, onHide }) => {
  const dispatch = useDispatch();

  const { register, handleSubmit, formState } =
        useForm<HRVaccinationReportRequest>({ defaultValues: { employeeStatus: 'All', vaccinationStatus: 'All' } });

  const report = useSelector(getVaccinationReport);

  const onSubmit = (data: HRVaccinationReportRequest) => {
    data.beginDate = DateTime.now().startOf('year').toISODate();
    dispatch(downloadHRVaccinationReport(data));
  };

  const hideModal = () => {
    if (report && show) {
      report && CommonService.downloadBase64File(
        'application/excel',
        report,
        `VaccinationReport${DateTime.now().year}.xlsx`,
      );
      dispatch(clearHRVaccinationReport());
    }
    onHide();
  };

  useEffect(() => {
    report && hideModal();
  }, [report]);


  return (
    <div onClick={e => {return e.stopPropagation();}}>
      <Modal show={show} onHide={onHide} size="sm" animation={false}>
        <Modal.Header closeButton={false}>
          <Modal.Title>
            <div className="dm-grid-title">Vaccination Report</div>
          </Modal.Title>
          <img src={closeIcon} alt="close" onClick={hideModal} />
        </Modal.Header>
        <Modal.Body>
          {formState.isSubmitted && report === null && (
            <Alert variant="warning">
              No data came back from the server.
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="dm-panel dm-panel-border">
              <div className="row p-2">
                <div className="d-flex flex-grow-1">
                  <RadioGrp
                    groupClass="gc12"
                    label={'Employee Options'}
                    radioOptions={employeeRadioOptions}
                    isVertical={true}
                    name="employeeStatus"
                    ref={register}
                  />
                </div>
                <div className="d-flex flex-grow-1">
                  <RadioGrp
                    groupClass="gc12"
                    label={'Employee Vaccination Status'}
                    radioOptions={statusRadioOptions}
                    isVertical={true}
                    name="vaccinationStatus"
                    ref={register}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-right">
                  <button
                    type="button"
                    className="btn btn-primary orange-button mr-2 mt-2"
                    onClick={hideModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary orange-button mr-2 mt-2"
                  >
                    Download Report
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default VaccinationReportModal;
