import React from 'react';
import { GridSection } from './CustomGrid';
import ChangeAllocationsGrid from './ChangeAllocations/ChangeAllocationsGrid';
import EarnAllocationsGrid from './EarnAllocations/EarnAllocationsGrid';
import WageAllocationsResultGrid from './WageAllocationsResultGrid';
import { useAppSelector } from 'utilities/hooks';
import '../corrections.scss';
import PageSpinner from 'core/components/shared/PageSpinner';
import { AllocationEmployee } from 'core/models';

type Props = {
  selectedEmp: AllocationEmployee | undefined;
  protectedEmpNo: string | undefined;
};

const AllocationGrids = ({ selectedEmp, protectedEmpNo }: Props) => {
  const deptAllocationCorrections = useAppSelector((state) => {
    return state?.corrections?.deptAllocationCorrections;
  });
  
  const fullName = `${selectedEmp?.firstName} ${selectedEmp?.lastName} - `;
  
  return (
    <div className="border shadow p-1 d-flex flex-column h-100">
      {!(selectedEmp && protectedEmpNo) ? <PageSpinner /> : (
        <>
          <div className="grid-section-header border">
            {fullName} Change: Department Allocations (applied 1st)
          </div>
          <GridSection flex={3}>
            <ChangeAllocationsGrid
              selectedEmp={selectedEmp}
            />
          </GridSection>
          <div className="grid-section-header border">
            {fullName} Change: Department Allocations (applied 1st)
          </div>
          <GridSection flex={1}>
            <EarnAllocationsGrid
              earnAllocations={deptAllocationCorrections?.earnAllocations ?? []}
              protectedEmpNo={protectedEmpNo}
            />
          </GridSection>
          <div className="grid-section-header border">
            {fullName} Wages will be allocated as follows
          </div>
          <GridSection flex={1}>
            <WageAllocationsResultGrid
              selectedEmp={selectedEmp}
              wageAllocations={deptAllocationCorrections?.allocatedWages ?? []}
            />
          </GridSection>
        </>
      )}
    </div>
  );
};

export default AllocationGrids;