import { createAction } from '@reduxjs/toolkit';
import {
  EmpHistory,
  HireGeneral,
  HireGeneralRequest,
  HireInfoCustomField,
  HistoryRequest,
  HRDependent,
  HRDependentRequest,
  HREducation,
  HREducationRequest,
  HREmployment,
  HREmploymentRequest,
  HRExitInterview,
  HRExitInterviewRequest,
  HRIncrease,
  HRIncreaseRequest,
  HRLicense,
  HRLicenseRequest,
  HRPerformanceReview,
  HRReviewRequest,
  HRSkills,
  HRSkillsRequest,
  HRSource,
  HRSourceRequest,
  HRTrainingEvent,
  HRTrainingEventRequest,
  HRVaccination,
  HRVaccinationFileRequest,
  HRVaccinationReportRequest,
  HRVaccinationRequest,
} from '../../models/HREmployee.model';

export const loadEmployeeFieldHistory = createAction<HistoryRequest>(
  '[hr-employee] EMPLOYEE_FIELD_HISTORY',
);
export const storeEmployeeFieldHistory = createAction<EmpHistory>(
  '[hr-employee] STORE_EMPLOYEE_FIELD_HISTORY',
);
export const clearEmployeeFieldHistory = createAction(
  '[hr-employee] CLEAR_EMPLOYEE_FIELD_HISTORY',
);


export const loadEmployeeHireGeneral = createAction<HireGeneralRequest>(
  '[hr-employee] EMPLOYEE_HIRE_GENERAL',
);
export const storeEmployeeHireGeneral = createAction<HireGeneral>(
  '[hr-employee] STORE_EMPLOYEE_HIRE_GENERAL',
);
export const updateEmployeeHireGeneral = createAction<HireGeneralRequest>(
  '[hr-employee] UPDATE_EMPLOYEE_HIRE_GENERAL',
);


export const loadHireInfoCustomFields = createAction(
  '[hr-employee] HIRE_INFO__CUSTOM_FIELD',
);
export const storeHireInfoCustomFields = createAction<HireInfoCustomField[]>(
  '[hr-employee] STORE_HIRE_INFO_CUSTOM_FIELD',
);
export const updateHireInfoCustomFields = createAction<HireInfoCustomField[]>(
  '[hr-employee] UPDATE_HIRE_INFO_CUSTOM_FIELD',
);


export const loadHREmployment = createAction<string>(
  '[hr-employee] HR_EMPLOYMENT',
);
export const storeHREmployments = createAction<HREmployment[]>(
  '[hr-employee] STORE_EMPLOYMENTS',
);
export const storeHREmployment = createAction<HREmployment>(
  '[hr-employee] STORE_EMPLOYMENT',
);
export const createHREmployment = createAction<HREmploymentRequest>(
  '[hr-employee] CREATE_EMPLOYMENT',
);
export const updateHREmployment = createAction<HREmploymentRequest>(
  '[hr-employee] UPDATE_EMPLOYMENT',
);
export const deleteHREmployment = createAction<HREmploymentRequest>(
  '[hr-employee] DELETE_EMPLOYMENT',
);


export const loadHREducation = createAction<string>(
  '[hr-employee] HR_EDUCATION',
);
export const storeHREducations = createAction<HREducation[]>(
  '[hr-employee] STORE_EDUCATIONS',
);
export const storeHREducation = createAction<HREducation>(
  '[hr-employee] STORE_EDUCATION',
);
export const createHREducation = createAction<HREducationRequest>(
  '[hr-employee] CREATE_EDUCATION',
);
export const updateHREducation = createAction<HREducationRequest>(
  '[hr-employee] UPDATE_EDUCATION',
);
export const deleteHREducation = createAction<HREducationRequest>(
  '[hr-employee] DELETE_EDUCATION',
);


export const loadHRLicense = createAction<string>(
  '[hr-employee] HR_LICENSE',
);
export const storeHRLicenses = createAction<HRLicense[]>(
  '[hr-employee] STORE_LICENSES',
);
export const storeHRLicense = createAction<HRLicense>(
  '[hr-employee] STORE_LICENSE',
);
export const createHRLicense = createAction<HRLicenseRequest>(
  '[hr-employee] CREATE_LICENSE',
);
export const updateHRLicense = createAction<HRLicenseRequest>(
  '[hr-employee] UPDATE_LICENSE',
);
export const deleteHRLicense = createAction<HRLicenseRequest>(
  '[hr-employee] DELETE_LICENSE',
);


export const loadHRTrainingEvent = createAction<string>(
  '[hr-employee] HR_TRAINING_EVENT',
);
export const storeHRTrainingEvents = createAction<HRTrainingEvent[]>(
  '[hr-employee] STORE_TRAINING_EVENTS',
);
export const storeHRTrainingEvent = createAction<HRTrainingEvent>(
  '[hr-employee] STORE_TRAINING_EVENT',
);
export const createHRTrainingEvent = createAction<HRTrainingEventRequest>(
  '[hr-employee] CREATE_TRAINING_EVENT',
);
export const updateHRTrainingEvent = createAction<HRTrainingEventRequest>(
  '[hr-employee] UPDATE_TRAINING_EVENT',
);
export const deleteHRTrainingEvent = createAction<HRTrainingEventRequest>(
  '[hr-employee] DELETE_TRAINING_EVENT',
);


export const loadHRSkills = createAction<string>(
  '[hr-employee] HR_SKILLS',
);
export const storeHRSkills = createAction<HRSkills[]>(
  '[hr-employee] STORE_SKILLS',
);
export const storeHRSkill = createAction<HRSkills>(
  '[hr-employee] STORE_SKILL',
);
export const createHRSkills = createAction<HRSkillsRequest>(
  '[hr-employee] CREATE_SKILLS',
);
export const updateHRSkills = createAction<HRSkillsRequest>(
  '[hr-employee] UPDATE_SKILLS',
);
export const deleteHRSkills = createAction<HRSkillsRequest>(
  '[hr-employee] DELETE_SKILLS',
);


export const loadHRSource = createAction<string>(
  '[hr-employee] HR_SOURCE',
);
export const storeHRSources = createAction<HRSource[]>(
  '[hr-employee] STORE_SOURCES',
);
export const storeHRSource = createAction<HRSource>(
  '[hr-employee] STORE_SOURCE',
);
export const createHRSource = createAction<HRSourceRequest>(
  '[hr-employee] CREATE_SOURCE',
);
export const updateHRSource = createAction<HRSourceRequest>(
  '[hr-employee] UPDATE_SOURCE',
);
export const deleteHRSource = createAction<HRSourceRequest>(
  '[hr-employee] DELETE_SOURCE',
);


export const loadHRExitInterview = createAction<string>(
  '[hr-employee] HR_EXIT_INTERVIEW',
);
export const storeHRExitInterview = createAction<HRExitInterview>(
  '[hr-employee] STORE_EXIT_INTERVIEW',
);
export const createHRExitInterview = createAction<HRExitInterviewRequest>(
  '[hr-employee] CREATE_EXIT_INTERVIEW',
);
export const updateHRExitInterview = createAction<HRExitInterviewRequest>(
  '[hr-employee] UPDATE_EXIT_INTERVIEW',
);



export const loadHRIncrease = createAction<string>(
  '[hr-employee] HR_INCREASE',
);
export const storeHRIncrease = createAction<HRIncrease>(
  '[hr-employee] STORE_INCREASE',
);
export const updateHRIncrease = createAction<HRIncreaseRequest>(
  '[hr-employee] UPDATE_INCREASE',
);


export const loadHRReview = createAction<string>(
  '[hr-employee] HR_REVIEW',
);
export const storeHRReview = createAction<HRPerformanceReview>(
  '[hr-employee] STORE_REVIEW',
);
export const updateHRReview = createAction<HRReviewRequest>(
  '[hr-employee] UPDATE_REVIEW',
);


export const loadHRDependent = createAction<string>(
  '[hr-employee] HR_DEPENDENT',
);
export const storeHRDependents = createAction<HRDependent[]>(
  '[hr-employee] STORE_DEPENDENTS',
);
export const storeHRDependent = createAction<HRDependent>(
  '[hr-employee] STORE_DEPENDENT',
);
export const createHRDependent = createAction<HRDependentRequest>(
  '[hr-employee] CREATE_DEPENDENT',
);
export const updateHRDependent = createAction<HRDependentRequest>(
  '[hr-employee] UPDATE_DEPENDENT',
);
export const deleteHRDependent = createAction<HRDependentRequest>(
  '[hr-employee] DELETE_DEPENDENT',
);


export const loadHRVaccination = createAction<string>(
  '[hr-employee] HR_VACCINATION',
);
export const storeHRVaccinations = createAction<HRVaccination[]>(
  '[hr-employee] STORE_VACCINATIONS',
);
export const storeHRVaccination = createAction<HRVaccination>(
  '[hr-employee] STORE_VACCINATION',
);
export const createHRVaccination = createAction<HRVaccinationRequest>(
  '[hr-employee] CREATE_VACCINATION',
);
export const updateHRVaccination = createAction<HRVaccinationRequest>(
  '[hr-employee] UPDATE_VACCINATION',
);
export const deleteHRVaccination = createAction<HRVaccinationRequest>(
  '[hr-employee] DELETE_VACCINATION',
);

export const downloadHRVaccinationReport = createAction<HRVaccinationReportRequest>(
  '[hr-employee] DOWNLOAD_HR_VACCINATION_REPORT',
);
export const storeHRVaccinationReport = createAction<string>(
  '[hr-employee] STORE_HR_VACCINATION_REPORT',
);
export const clearHRVaccinationReport = createAction(
  '[hr-employee] CLEAR_HR_VACCINATION_REPORT',
);

export const createHRVaccinationFile = createAction<HRVaccinationFileRequest>(
  '[hr-employee] CREATE_VACCINATION_FILE',
);
export const deleteHRVaccinationFile = createAction<HRVaccinationFileRequest>(
  '[hr-employee] DELETE_VACCINATION_FILE',
);

export const storeHREmployeeList = createAction<number[]>(
  '[hr-employee] STORE_HR_EMPLOYEE_LIST',
);