import { createReducer } from '@reduxjs/toolkit';
import { WCEffectiveDate } from '../../models';
import { storeWCEffectiveDate, storeWCEffectiveDates } from '../actions';

export interface State {
  loading: boolean;
  workerComp: WCEffectiveDate[];
}

const INITIAL_STATE = {
  loading: false,
  workerComp: [],
};

export const reducer = createReducer(INITIAL_STATE, {
  [storeWCEffectiveDates.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      workerComp: action.payload.sort((a: WCEffectiveDate, b: WCEffectiveDate) => {return a.stateOfficerLimitID - b.stateOfficerLimitID;}),
    };
  },

  [storeWCEffectiveDate.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      workerComp: [
        ...state.workerComp.filter((w: WCEffectiveDate) => {return w.wcPolicyID !== action.payload.wcPolicyID;}),
        action.payload,
      ].sort((a: WCEffectiveDate, b: WCEffectiveDate) => {return a.stateOfficerLimitID - b.stateOfficerLimitID;}),
    };
  },
});
