import React, { useContext } from 'react';
import { OnboardDoc } from '../../core/models';
import { PageConfigsContext } from './pageConfigContext';

type PropTypes = {
  onboardDocs: OnboardDoc[];
};

const OnboardDocuments: React.FC<PropTypes> = ({ onboardDocs }) => {
  const pageConfigContext = useContext(PageConfigsContext);

  const onChangeHandler = (value: any) => {
    pageConfigContext.updateDocumentIds(+value.currentTarget.name, value.currentTarget.checked);
    pageConfigContext.setPageConfigContext({ ...pageConfigContext });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="dm-panel dm-panel-border">
          <div className="dm-card-title">Onboarding Documents</div>
          <div className="dm-card-subtitle">Choose documents for the onboarding employee to view and sign in MyInfo website.</div>
          <div className="row mt-3">
            <div className="col-12">
              <table style={{ width: '100%' }}>
                <colgroup>
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '85%' }} />
                </colgroup>
                <thead>
                  <tr style={{ background: 'lightgray', lineHeight: '30px', fontSize: '12px', fontWeight: 600 }}>
                    <th style={{ paddingLeft: '16px' }}>Select</th>
                    <th className="d-flex justify-content-center">Document Name</th>
                  </tr>
                </thead>
                <tbody>
                  {onboardDocs?.map((item: OnboardDoc) => {
                    return (
                      <tr
                        key={item.onboardDocID}
                        style={{ borderBottom: '1px solid lightgray', paddingTop: '5px' }}
                      >
                        <td className="d-flex justify-content-center">
                          <input
                            style={{ margin: '9px 0' }}
                            type="checkbox"
                            id={item.onboardDocID.toString()}
                            name={item.onboardDocID.toString()}
                            onChange={onChangeHandler}
                          />
                        </td>
                        <td>
                          <label
                            style={{ paddingTop: '6px', paddingLeft: '16px', marginBottom: '6px' }}
                            htmlFor={item.onboardDocID.toString()}
                          >
                            {item.name}
                          </label>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default OnboardDocuments;