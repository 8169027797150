import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpResponse, RateRule } from '../../models';
import { RateRuleService } from '../../services';
import {
  createRateRule,
  deleteRateRule,
  handleError,
  handleSuccess,
  loadRateRule,
  loadRateRules,
  storeRateRule,
  storeRateRules,
  updateRateRule,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadRateRules$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadRateRules.type),
    switchMap(() => {
      return from(RateRuleService.getRateRules()).pipe(
        map((res: any) => { return res.data; }),
        map((res: RateRule[]) => { return storeRateRules(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadRateRule$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadRateRule.type),
    switchMap((action: { payload: number; }) => {
      return from(RateRuleService.getRateRule(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: RateRule) => { return storeRateRule(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createRateRule$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createRateRule.type),
    switchMap((action: { payload: RateRule; }) => {
      return from(RateRuleService.postRateRule(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        switchMap((res: HttpResponse<RateRule>) => {
          return [
            storeRateRule(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateRateRule$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateRateRule.type),
    switchMap((action: { payload: RateRule; }) => {
      return from(RateRuleService.putRateRule(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        switchMap((res: HttpResponse<RateRule>) => {
          return [
            storeRateRule(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteRateRule$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteRateRule.type),
    switchMap((action: { payload: RateRule; }) => {
      return from(RateRuleService.deleteRateRule(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        switchMap((res: HttpResponse<any>) => { 
          return [
            loadRateRules(),
            handleSuccess('Client Rate Rule Deleted Successfully'),
        ]; 
      }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadRateRules$,
  loadRateRule$,
  createRateRule$,
  updateRateRule$,
  deleteRateRule$,
];
