import React, { ReactElement, useEffect, forwardRef, useImperativeHandle, useState, CSSProperties } from 'react';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { useForm } from 'react-hook-form';
import { InputGrp } from 'core/components/form-controls';
import { Payroll, PayrollControlTotal, PayrollsUserOptions } from 'core/models';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { getPayrollsUserOptions, getPayrollType } from 'core/store/selectors';
import { Modal } from 'react-bootstrap';
import { ControlTotalDetail } from '../current-payroll/ControlTotalDetail';
import { PayrollControlTotalDetailType } from 'core/models/PayrollControlTotalDetail';
import Icon from 'core/components/shared/Icon';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { deepEqualityComparison, formatWithCommas } from 'utilities/utilities';
import { handleError, loadPayrollControlTotal, updatePayrollControlTotal } from 'core/store/actions';
import { formatNegativeNumbers } from '../shared/payrollUtilities';

const fs: FieldInputSettings = {
  controlTotalRegular: {
    name: 'controlTotalRegular',
    groupClass: 'group-class',
  },
  controlTotalTimeHalf: {
    name: 'controlTotalTimeHalf',
    groupClass: 'group-class',
  },
  controlTotalDouble: {
    name: 'controlTotalDouble',
    groupClass: 'group-class',
  },
  controlTotalOtherHours: {
    name: 'controlTotalOtherHours',
    groupClass: 'group-class',
  },
  controlTotalOtherEarnings: {
    name: 'controlTotalOtherEarnings',
    groupClass: 'group-class',
  },
  controlTotalSpecialDeductions: {
    name: 'controlTotalSpecialDeductions',
    groupClass: 'group-class',
  },
};

const inputStyle: CSSProperties = { textAlign: 'right' };

export interface TransmittalControlRefObject {
  getFormValues: () => PayrollControlTotal;
}

type PropTypes = {
  currentControlTotal: PayrollControlTotal;
  currentPayroll: Payroll;
  isReadOnly: boolean;
};

const TransmittalControlTotals = forwardRef<
TransmittalControlRefObject,
PropTypes
>(({ currentControlTotal, currentPayroll, isReadOnly }, ref) => {
  const [showControlTotal, setShowControlTotal] = useState(false);
  const [controlTotalType, setControlTotalType] = useState<PayrollControlTotalDetailType>(PayrollControlTotalDetailType.All);
  const [openState, setOpenState] = useState<{ [key: string]: boolean }>({
    controlTotals: true,
  });
  
  const payrollsUserOptions: PayrollsUserOptions | null = useSelector(getPayrollsUserOptions);
  const controlTotalFromStore = useAppSelector((state) => { return state.payroll.payrollControlTotal; });
  const loadingControlTotal = useAppSelector((state) => { return state.payroll.loadingControlTotal; });
  const clientIncludeSalaryOpts = useAppSelector(({ client }) => { return client?.clientOptions?.options?.[161]; });
  const includeSalaryEmps = clientIncludeSalaryOpts?.optionValue?.toLowerCase() === 'yes';
  
  const dispatch = useAppDispatch();
  
  const { register, watch, errors, setValue, getValues, reset } =
    useForm<PayrollControlTotal>({
      defaultValues: {
        controlTotalRegular: controlTotalFromStore?.controlTotalRegular,
        controlTotalTimeHalf: controlTotalFromStore?.controlTotalTimeHalf,
        controlTotalDouble: controlTotalFromStore?.controlTotalDouble,
        controlTotalOtherHours: controlTotalFromStore?.controlTotalOtherHours,
        controlTotalOtherEarnings: controlTotalFromStore?.controlTotalOtherEarnings,
        controlTotalSpecialDeductions: controlTotalFromStore?.controlTotalSpecialDeductions,
      },
    });
  
  const {
    controlTotalRegular,
    controlTotalTimeHalf,
    controlTotalDouble,
    controlTotalOtherHours,
    controlTotalOtherEarnings,
    controlTotalSpecialDeductions,
  } = watch([
    'controlTotalRegular',
    'controlTotalTimeHalf',
    'controlTotalDouble',
    'controlTotalOtherHours',
    'controlTotalOtherEarnings',
    'controlTotalSpecialDeductions',
  ]);
  
  useEffect(() => {
    if (!controlTotalFromStore) return;
    reset({
      ...currentControlTotal,
      controlTotalRegular: controlTotalFromStore?.controlTotalRegular,
      controlTotalTimeHalf: controlTotalFromStore?.controlTotalTimeHalf,
      controlTotalDouble: controlTotalFromStore?.controlTotalDouble,
      controlTotalOtherHours: controlTotalFromStore?.controlTotalOtherHours,
      controlTotalOtherEarnings: controlTotalFromStore?.controlTotalOtherEarnings,
      controlTotalSpecialDeductions: controlTotalFromStore?.controlTotalSpecialDeductions,
    });
  }, [controlTotalFromStore]);

  useEffect(() => {
    if (!loadingControlTotal && !controlTotalFromStore) {
      dispatch(loadPayrollControlTotal({payrollHistoryId: currentPayroll?.payrollHistoryId, controlTotalId: currentPayroll?.controlTotals?.[0]?.controlTotalId}))
    }
  }, [loadingControlTotal, !!controlTotalFromStore]);

  const toggleInfo = (type: string) => {
    setOpenState({
      ...openState,
      [type]: !openState[type],
    });
  };

  useImperativeHandle(ref, () => {
    return {
      getFormValues() {
        return getValues();
      },
    };
  });

  const handleShowControlModal = () => { return setShowControlTotal(false); };

  const handleChange = (value: string, field: string) => {
    setValue(fs[field].name as keyof PayrollControlTotal, value);
  };

  // PI-8420: Control totals need to save if a user manually changes them. In the abscence of a save button, this is how we try to handle it gracefully.
  const handleSaveOnBlur = () => {
    if (!(currentPayroll?.payrollHistoryId && controlTotalFromStore?.controlTotalId)) return dispatch(handleError('Error saving control totals')); // hmm, should probably be something more specific

    // grab and merge the form (this form is JUST the control total fields) with the control total from the store. The effects in OpenCurrentTransmittal will take care of updating the information there.
    const formVals = getValues();
    const currentControlTotalValues: Partial<PayrollControlTotal> = {
      controlTotalRegular: controlTotalFromStore.controlTotalRegular,
      controlTotalTimeHalf: controlTotalFromStore.controlTotalTimeHalf,
      controlTotalDouble: controlTotalFromStore.controlTotalDouble,
      controlTotalOtherHours: controlTotalFromStore.controlTotalOtherHours,
      controlTotalOtherEarnings: controlTotalFromStore.controlTotalOtherEarnings,
      controlTotalSpecialDeductions: controlTotalFromStore.controlTotalSpecialDeductions,
    };
    
    if (deepEqualityComparison(currentControlTotalValues, formVals)) return;
    
    const updatedControlTotal: PayrollControlTotal = { ...controlTotalFromStore, ...formVals };
    
    dispatch(
      updatePayrollControlTotal({
        payrollHistoryId: currentPayroll.payrollHistoryId,
        controlTotalId: controlTotalFromStore.controlTotalId,
        data: updatedControlTotal,
        preventPayrollsUpdate: true,
      }),
    );
  };
  
  return (
    <div>
      <div className="row">
        <div className="col mr-auto">
          <div
            role="button"
            className="dm-grid-title mb-2"
            onClick={() => { return toggleInfo('controlTotals'); }}
          >
            <Icon
              name={openState.controlTotals ? 'chevron-down' : 'chevron-right'}
              className="mr-2"
            />
            Transmittal Control Totals
          </div>
        </div>
      </div>
      <div className={openState.controlTotals ? 'animate' : 'animate-reverse'}>
        <div className="control-total-container">
          <div className="d-flex flex-column flex-wrap">
            <div className="d-flex flex-row mb-4 mr-4">
              <strong>
                Week Ending:
                <br />
                {currentPayroll?.weekEnd && DateTime.fromISO(currentPayroll.weekEnd).toLocaleString()}
              </strong>
            </div>
            <div className="d-flex flex-row">
              <strong>
                Check Date:
                <br />
                {currentPayroll?.checkDate && DateTime.fromISO(currentPayroll.checkDate).toLocaleString()}
              </strong>
            </div>
          </div>
          <form>
            <div className="control-totals-table">
              {/* column labels */}
              <div className="control-total-row">
                <div className="dm-grid-column-head">&nbsp;</div>
                <label className="dm-grid-column-head text-center">
                  Regular Hours {includeSalaryEmps === false && 'w/o Salary Emps'}
                </label>
                <label className="dm-grid-column-head text-center">
                  1 1/2 Hours
                </label>
                <label className="dm-grid-column-head text-center">
                  Double Hours
                </label>
                <label className="dm-grid-column-head text-center">
                  <button
                    type="button"
                    className="btn-link link-button font-weight-bold"
                    onClick={() => {
                      setShowControlTotal(true);
                      setControlTotalType(
                        PayrollControlTotalDetailType.OtherHours,
                      );
                    }}
                  >
                    Other Hours
                  </button>
                </label>
                <label className="dm-grid-column-head text-center">
                  <button
                    type="button"
                    className="btn-link link-button font-weight-bold"
                    onClick={() => {
                      setShowControlTotal(true);
                      setControlTotalType(PayrollControlTotalDetailType.OtherEarnings);
                    }}
                  >
                    Other Earnings
                  </button>
                </label>
                <label className="dm-grid-column-head text-center">
                  <button
                    type="button"
                    className="btn-link link-button font-weight-bold"
                    onClick={() => {
                      setShowControlTotal(true);
                      setControlTotalType(PayrollControlTotalDetailType.SpecialDeductions);
                    }}
                  >
                    Special Deds
                  </button>
                </label>
              </div>
              {/* CT inputs */}
              <div className="control-total-row">
                <div className="dm-grid-column-head">Control Totals</div>
                <InputGrp
                  {...fs.controlTotalRegular}
                  groupClass="m-0 ct-input-wrapper"
                  errors={errors.controlTotalRegular}
                  type="text"
                  onChange={(e: any) => { handleChange(e.target.value, 'controlTotalRegular'); }}
                  readOnly={isReadOnly}
                  defaultValue={formatWithCommas(controlTotalRegular)}
                  inputStyle={inputStyle}
                  setValue={setValue}
                  formatNumber
                  hiddenValue={controlTotalRegular ?? 0}
                  hiddenRef={register({
                    valueAsNumber: true,
                  })}
                  onBlur={handleSaveOnBlur}
                />
                <InputGrp
                  {...fs.controlTotalTimeHalf}
                  groupClass="m-0 ct-input-wrapper"
                  errors={errors.controlTotalTimeHalf}
                  onChange={(e: any) => {handleChange(e.target.value, 'controlTotalTimeHalf');}}
                  readOnly={isReadOnly}
                  defaultValue={formatWithCommas(controlTotalTimeHalf)}
                  inputStyle={inputStyle}
                  setValue={setValue}
                  formatNumber
                  hiddenValue={controlTotalTimeHalf ?? 0}
                  hiddenRef={register({
                    valueAsNumber: true,
                  })}
                  onBlur={handleSaveOnBlur}
                />
                <InputGrp
                  {...fs.controlTotalDouble}
                  groupClass="m-0 ct-input-wrapper"
                  errors={errors.controlTotalDouble}
                  onChange={(e: any) => {handleChange(e.target.value, 'controlTotalDouble');}}
                  readOnly={isReadOnly}
                  defaultValue={formatWithCommas(controlTotalDouble)}
                  inputStyle={inputStyle}
                  setValue={setValue}
                  formatNumber
                  hiddenValue={controlTotalDouble ?? 0}
                  hiddenRef={register({
                    valueAsNumber: true,
                  })}
                  onBlur={handleSaveOnBlur}
                />
                <InputGrp
                  {...fs.controlTotalOtherHours}
                  groupClass="m-0 ct-input-wrapper"
                  errors={errors.controlTotalOtherHours}
                  onChange={(e: any) => {handleChange(e.target.value, 'controlTotalOtherHours');}}
                  readOnly={isReadOnly}
                  defaultValue={formatWithCommas(controlTotalOtherHours)}
                  inputStyle={inputStyle}
                  setValue={setValue}
                  formatNumber
                  hiddenValue={controlTotalOtherHours ?? 0}
                  hiddenRef={register({
                    valueAsNumber: true,
                  })}
                  onBlur={handleSaveOnBlur}
                />
                <InputGrp
                  {...fs.controlTotalOtherEarnings}
                  groupClass="m-0 ct-input-wrapper"
                  errors={errors.controlTotalOtherEarnings}
                  onChange={(e: any) => {handleChange(e.target.value, 'controlTotalOtherEarnings');}}
                  readOnly={isReadOnly}
                  defaultValue={formatWithCommas(controlTotalOtherEarnings)}
                  inputStyle={inputStyle}
                  setValue={setValue}
                  formatNumber
                  hiddenValue={controlTotalOtherEarnings ?? 0}
                  hiddenRef={register({
                    valueAsNumber: true,
                  })}
                  onBlur={handleSaveOnBlur}
                />
                <InputGrp
                  {...fs.controlTotalSpecialDeductions}
                  groupClass="m-0 ct-input-wrapper"
                  errors={errors.controlTotalSpecialDeductions}
                  onChange={(e: any) => {handleChange(e.target.value, 'controlTotalSpecialDeductions');}}
                  readOnly={isReadOnly}
                  defaultValue={formatWithCommas(controlTotalSpecialDeductions)}
                  inputStyle={inputStyle}
                  setValue={setValue}
                  formatNumber
                  hiddenValue={controlTotalSpecialDeductions ?? 0}
                  hiddenRef={register({
                    valueAsNumber: true,
                  })}
                  onBlur={handleSaveOnBlur}
                />
              </div>
              {/* transmittal totals */}
              <div className="control-total-row">
                <div className="dm-grid-column-head">Transmittal</div>
                <label className="dm-grid-column-head-right">
                  {formatWithCommas(controlTotalFromStore?.transmittalRegular ?? 0)}
                </label>
                <label className="dm-grid-column-head-right">
                  {formatWithCommas(controlTotalFromStore?.transmittalTimeHalf ?? 0)}
                </label>
                <label className="dm-grid-column-head-right">
                  {formatWithCommas(controlTotalFromStore?.transmittalDouble ?? 0)}
                </label>
                <label className="dm-grid-column-head-right">
                  {formatWithCommas(controlTotalFromStore?.transmittalOtherHours ?? 0)}
                </label>
                <label className="dm-grid-column-head-right">
                  {formatWithCommas(controlTotalFromStore?.transmittalOtherEarnings ?? 0)}
                </label>
                <label className="dm-grid-column-head-right">
                  {formatWithCommas(controlTotalFromStore?.transmittalSpecialDeductions ?? 0)}
                </label>
              </div>
              {/* difference totals */}
              <div className="control-total-row">
                <div className="dm-grid-column-head">Difference</div>
                <label className="dm-grid-column-head-right">
                  {formatNegativeNumbers((controlTotalFromStore?.transmittalRegular || 0) - (controlTotalRegular || 0), payrollsUserOptions)}
                </label>
                <label className="dm-grid-column-head-right">
                  {formatNegativeNumbers((controlTotalFromStore?.transmittalTimeHalf || 0) - (controlTotalTimeHalf || 0), payrollsUserOptions)}
                </label>
                <label className="dm-grid-column-head-right">
                  {formatNegativeNumbers((controlTotalFromStore?.transmittalDouble || 0) - (controlTotalDouble || 0), payrollsUserOptions)}
                </label>
                <label className="dm-grid-column-head-right">
                  {formatNegativeNumbers((controlTotalFromStore?.transmittalOtherHours || 0) - (controlTotalOtherHours || 0), payrollsUserOptions)}
                </label>
                <label className="dm-grid-column-head-right">
                  {formatNegativeNumbers((controlTotalFromStore?.transmittalOtherEarnings || 0) - (controlTotalOtherEarnings || 0), payrollsUserOptions)}
                </label>
                <label className="dm-grid-column-head-right">
                  {formatNegativeNumbers((controlTotalFromStore?.transmittalSpecialDeductions || 0) - (controlTotalSpecialDeductions || 0), payrollsUserOptions)}
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal
        show={showControlTotal}
        backdrop="static"
        centered={true}
        onHide={() => { return handleShowControlModal(); }}
        size="sm"
      >
        <Modal.Header className="border-0">
          <button
            type="button"
            className="modal-close-btn"
            onClick={handleShowControlModal}
          >
            <Icon name="times" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <ControlTotalDetail
            request={{
              type: controlTotalType,
              payrollHistoryId: currentPayroll?.payrollHistoryId ?? 0,
              controlTotalId: controlTotalFromStore?.controlTotalId ?? 0,
            }}
            onHide={handleShowControlModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
});

TransmittalControlTotals.displayName = 'TransmittalControlTotals';

export default TransmittalControlTotals;
