import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteInterviewRequest,  SendApplicantInterviewEmails,  StatusRequest } from 'core/models';
import { ApplicantService } from 'core/services/applicant-tracking.service';
import { ApplicantStatus } from 'enums/ApplicantStatus';
import { ApplicantTrackingState } from '../slices/applicant-tracking-v2.slice';
import { handleError, handleSuccess } from './app.action';

export const getApplicant = createAsyncThunk(
  'hr/getApplicant',
  async (id: number, { dispatch }) => {
    try {
      const response = await ApplicantService.getApplicant(id);

      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));
    }
  },
);

export const getApplicants = createAsyncThunk('hr/getApplicants', async (_params, { dispatch }) => {
  try {
    const response = await ApplicantService.getApplicants();
    
    return response?.data || response;
  } catch (err) {
    dispatch(handleError(err));
  }
});

export const getApplicantStatus = createAsyncThunk(
  'hr/getApplicantStatus',
  async (_params, { getState, dispatch }) => {
    try {
      const { applicantTrackingV2 } = getState() as {
        applicantTrackingV2: ApplicantTrackingState;
      };
      let response: any = null;

      if (applicantTrackingV2?.active?.status === ApplicantStatus.INTERVIEW) {
        response = await ApplicantService.getApplicantInterviews(
          applicantTrackingV2?.active?.applicantId,
        );
      }

      if (applicantTrackingV2?.active?.status === ApplicantStatus.HIRE) {
        response = await ApplicantService.getApplicantOffer(
          applicantTrackingV2?.active?.applicantId,
        );
      }

      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));
    }
  },
);

export const updateApplicantOffer = createAsyncThunk(
  'hr/updateApplicantOffer',
  async (_params, { getState, dispatch }) => {
    try {
      const { applicantTrackingV2 } = getState() as {
        applicantTrackingV2: ApplicantTrackingState;
      };
      const response = await ApplicantService.putApplicantOffer(
        applicantTrackingV2.offer,
      );
      
      dispatch(handleSuccess('Offer settings saved successfully!'));
      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));
    }
  },
);

export const postApplicantNotes = createAsyncThunk(
  'hr/postApplicantNotes',
  async ({ applicantId, notes }: any, { dispatch }) => {
    try {
      const response = await ApplicantService.postApplicantNotes(
        applicantId,
        notes,
      );

      dispatch(handleSuccess('Notes saved'));
      
      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));
    }
  },
);

export const postApplicantNoteReport = createAsyncThunk(
  'hr/postApplicantNoteReport',
  async (applicantId: number, { dispatch }) => {
    try {
      const response = await ApplicantService.postApplicantNoteReport(
        applicantId,
      );

      dispatch(handleSuccess('Applicant note report generated'));
      
      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));
    }
  },
);

/* 
Not handling with toast messages because this POST is currently set up like a GET request. Until that changes, we don't
wanna show failing messages for every applicant without a resume (whcih is a lot of them on some clients).
*/
export const postApplicantResume = createAsyncThunk(
  'hr/postApplicantResume',
  async (applicantId: number, { dispatch }) => {
    try {
      const response = await ApplicantService.postApplicantResume(applicantId);

      console.log('%cApplicant resume POST success', 'color: green;');

      return response?.data || response;
    } catch (err) {
      console.error('Error sending resume request');
    }
  },
);

export const updateApplicantStatus = createAsyncThunk(
  'hr/putApplicantStatus',
  async (request: StatusRequest, { dispatch }) => {
    try {
      const response = await ApplicantService.putApplicantStatus(request);
    
      dispatch(handleSuccess('Updated applicant status successfully'));
      dispatch(getApplicants());

      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));
    }
  },
);

export const getPayrollUsers = createAsyncThunk(
  'hr/getPayrollUsers',
  async (_params, { dispatch }) => {
    try {
      const response = await ApplicantService.getPayrollUsers();

      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));    
    }
  },
);

export const getDesignWebsite = createAsyncThunk(
  'hr/getDesignWebsite',
  async (_params, { dispatch }) => {
    try {
      const response = await ApplicantService.getDesignWebsite();

      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));    
    }
  },
);

export const putDesignWebsite = createAsyncThunk(
  'hr/putDesignWebsite',
  async (request: any, { dispatch }) => {
    try {
      const response = await ApplicantService.putDesignWebsite(request);
      
      dispatch(handleSuccess('Design website updated'));
      dispatch(getDesignWebsite());
      
      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));
    }
  },
);

export const getCustomInterviewEmails = createAsyncThunk(
  'hr/getCustomInterviewEmails',
  async (_params, { dispatch }) => {
    try {
      const response = await ApplicantService.getCustomInterviewEmails();

      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));    
    }
  },
);

export const getInterviewTemplates = createAsyncThunk(
  'hr/getInterviewTempaltes',
  async (_params, { dispatch }) => {
    try {
      const response = await ApplicantService.getInterviewTemplates();

      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));    
    }
  },
);

export const getInterviewQuestionsReport = createAsyncThunk(
  'hr/getInterviewQuestionsReport',
  async (request: any, { dispatch }) => {
    try {
      const response = await ApplicantService.getInterviewQuestionsReport(request.applicantId, request.interviewId);

      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));    
    }
  },
);

export const deleteApplicationInterview = createAsyncThunk(
  'hr/deleteApplicationInterview',
  async (request: DeleteInterviewRequest, { dispatch }) => {
    try {
      const response = await ApplicantService.deleteApplicationInterview(
        request.applicantId,
        request.interviewId,
      );
      
      dispatch(handleSuccess('Application interview deleted'));
      dispatch(getApplicantStatus());
      dispatch(getApplicants());
      
      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));
    }
  },
);

export const sendApplicantInterviewEmails = createAsyncThunk(
  'hr/sendApplicantInterviewEmails',
  async (request: SendApplicantInterviewEmails, { dispatch }) => {
    try {
      const response = await ApplicantService.sendApplicantInterviewEmails(
        request.applicantId,
        request.interviewId,
        request.params,
      );

      dispatch(handleSuccess('Interview email sent'));

      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));
    }
  },
);

export const sendApplicantOfferEmail = createAsyncThunk(
  'hr/sendApplicantOfferEmail',
  async (request: any, { dispatch }) => {
    try {
      const response = await ApplicantService.sendApplicantOfferEmail(
        request.applicantId,
        request.params,
      );

      dispatch(handleSuccess('Offer email sent'));
      
      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));
    }
    
  },
);

export const createInterviewFromProcess = createAsyncThunk(
  'hr/createInterviewFromProcess',
  async (request: SendApplicantInterviewEmails, { dispatch }) => {
    try {
      const response = await ApplicantService.createInterviewFromProcess(
        request.applicantId,
        request.interviewId,
      );

      dispatch(handleSuccess('Interview successfully created'));

      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));
    }
  },
);

export const createApplicantInterviewFromTemplate = createAsyncThunk(
  'hr/createApplicantInterviewFromTemplate',
  async (request: SendApplicantInterviewEmails, { dispatch }) => {
    try {
      const response = await ApplicantService.createApplicantInterviewFromTemplate(
        request.applicantId,
        request.params,
      );

      dispatch(handleSuccess('Applicant interview successfully created'));
      
      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));
    }
  },
);

export const updateApplicantInterview = createAsyncThunk(
  'hr/updateApplicantInterview',
  async (request: SendApplicantInterviewEmails, { dispatch }) => {
    try {
      const response = await ApplicantService.updateApplicantInterview(
        request.applicantId,
        request.interviewId,
        request.params,
      );

      dispatch(getApplicantStatus());
      dispatch(getApplicants());
      
      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));
    }
  },
);

export const updateApplicantEmail = createAsyncThunk(
  'hr/updateApplicantEmail',
  async (request: any, { dispatch }) => {
    try {
      const response = await ApplicantService.updateApplicantEmail(
        request.applicantId,
        request.params,
      );

      dispatch(getApplicantStatus());
      dispatch(getApplicant(request.applicantId));
      dispatch(handleSuccess('Applicant email updated successfully'));
      
      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));
    }
  },
);
