import React, { useEffect, useRef, useState } from 'react';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { ValueGetterParams, GridApi, GridReadyEvent, SelectionChangedEvent } from '@ag-grid-community/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDefaultPayrollUploadFile,
  handleError,
  loadAodPayrollUploadMaps,
  loadPayroll,
  loadPayrollUpload,
  payrollUploadFileCheckTool,
  payrollUploadFileTool,
  storeAodPayrollUploadMaps,
  storePayrollUploadFileToolCheckErrors,
  storePayrollUploadFileToolErrors,
  storeSelectedPayrollUploadFile,
} from 'core/store/actions';
import { payrollFrequencies } from 'dropdowns/payrollFrequencies';
import { CheckPayroll, Payroll } from 'core/models';
import { DateTime } from 'luxon';
import { RadioGrp, SelectGrp } from 'core/components/form-controls';
import {
  getAodPayrollUploadMap,
  getPayrollUploadClientFile,
  getPayrollUploadFileToolErrors,
  getPayrollUploadVM,
} from 'core/store/selectors/payroll-upload.selector';
import {
  PayrollUpload,
  PayrollUploadDefault,
  PayrollUploadErrors,
  PayrollUploadFile,
  PayrollUploadFileCheck,
  PayrollUploadType,
  PayrollUploadVM,
} from 'core/models/PayrollUpload.model';
import { getAvailableToUploadPayrolls, getControlTotalsByOption, getLocationDepartmentsStopValueNo, getReportOptions } from 'core/store/selectors';
import UploadPayrollStep2Modal from './UploadPayrollStep2.modal';
import UploadPayrollStep1Modal from './UploadPayrollStep1.modal';
import UploadPayrollMissingFile from './MissingFile.modal';
import FileToolErrorsModal from './FileToolErrors.modal';
import Icon from 'core/components/shared/Icon';
import OnTheClockModal from './OnTheClock.modal';
import AttendanceOnDemandModal from './AttendanceOnDemand.modal';
import { useAppSelector } from 'utilities/hooks';
import { Modal, ProgressBar } from 'react-bootstrap';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import ManageClientUploadMapsModal from './ManageClientUploadMaps.modal';

const radioOptions = [
  {
    value: 'false',
    label: 'Append',
  },
  {
    value: 'true',
    label: 'Overwrite',
  },
];

const DMFILETYPES = ['.dm1', '.dm2', '.dm4'];

const UploadPayrollPage = () => {
  const dispatch = useDispatch();

  const payrollFrequencyOptions = payrollFrequencies;
  const isSuperUser = useAppSelector((state) => { return state.auth.userAccess?.superUser ?? false; });

  const [fileToolErrors, setFileToolErrors] = useState<PayrollUploadErrors[] | null>(null);
  const [rowData, setRowData] = useState<Payroll[]>([]);
  const [selectedPayroll, setSelectedPayroll] = useState<Payroll>();
  const [showStep1Modal, setShowStep1Modal] = useState(false);
  const [showStep2Modal, setShowStep2Modal] = useState(false);
  const [showOnTheClockModal, setShowOnTheClockModal] = useState(false);
  const [showAttendanceOnDemandModal, setShowAttendanceOnDemandModal] = useState(false);
  const [csvFile, setCSVFile] = useState<string>('');
  const [fileRows, setFileRows] = useState<string[]>([]);
  const [showLoadingBar, setShowLoadingBar] = useState<boolean>(false);
  const [empNosToTrack, setEmpNosToTrack] = useState<string[]>([]);
  const [payrollFile, setFile] = useState<PayrollUpload>();
  const [activeClientMaps, setActiveClientMaps] = useState<PayrollUpload[]>([]);
  const [fileName, setFileName] = useState<string>('');
  const [selectedLocation, setSelectedLocation] = useState<number>(-1);
  const [selectedDepartment, setSelectedDepartment] = useState<number>(-1);
  const [reUploadFile, setReUploadFile] = useState<boolean>(true);
  const [showMissingFile, setShowMissingFile] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState({ show: false, message: '' });
  const [showFileToolErrors, setShowFileToolErrors] = useState(false);
  const [showManageClientMaps, setShowManageClientMaps] = useState(false);
  const [payrollUploadFile, setPayrollUploadFile] = useState<PayrollUploadFile | null>(null);
  const [overWrite, setOverWrite] = useState('false');
  
  const payrollUploadClientFile = useSelector(getPayrollUploadClientFile);
  const [headerStartingLine, setHeaderStartingLine] = useState(payrollUploadClientFile?.clients?.[0]?.startOnLine || 1);
  
  const payrollUploadVM = useSelector(getPayrollUploadVM) as PayrollUploadVM;
  const openPayrollStore = useSelector(getAvailableToUploadPayrolls);
  const fileToolErrorsStore = useSelector(getPayrollUploadFileToolErrors);
  const onTheClockOption = useSelector(getReportOptions('OnTheClock'));
  const attendanceOnDemandOption = useSelector(getReportOptions('AttendanceOnDemand'));
  const controlTotalBy = useSelector(getControlTotalsByOption);
  const { locationOpts, deptOpts } = useSelector(getLocationDepartmentsStopValueNo);
  const aodPayrollUploadMap = useSelector(getAodPayrollUploadMap);
  const uploadingData = useAppSelector(state => state.payrollUpload.uploadingData);
  const creatingDefaultMap = useAppSelector(state => state.payrollUpload.creatingDefaultMap);
  const fileAlreadyUploaded = useAppSelector(state => state.payrollUpload.fileAlreadyUploaded);
  const clientNo = useAppSelector(({ client }) => client.client?.clientNo);

  const integrationButtonText = (onTheClockOption?.showReport || false) ? 'Upload From On the Clock to Selected Payroll' : 'Upload From Attendance On Demand to Selected Payroll';
  
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const beginDate = DateTime
      .local()
      .startOf('year')
      .toFormat('MM-dd-yyyy');
    const endDate = DateTime
      .local()
      .endOf('year')
      .toFormat('MM-dd-yyyy');
    
    dispatch(loadPayroll({
      beginDate,
      endDate,
      byCheckDate : true, //Was throwing error with this being set to true. -JS
    }));
    dispatch(loadPayrollUpload());
    dispatch(storePayrollUploadFileToolErrors(null));
    if (attendanceOnDemandOption?.showReport || false) dispatch(loadAodPayrollUploadMaps());
    else dispatch(storeAodPayrollUploadMaps(null));
  }, []);

  //Gets the default map on load.
  useEffect(() => {
    if (!payrollUploadVM.clientMaps) return;

    //PI-8565: Don't hardcode these maps now. Either it will be automatically selected because this is the only map set up for them, or they have to select the correct map before clicking the button (might be a custom version of the OTC or AOD map)

    // //If they use on the clock choose the on the clock Map which is 724 (If they dont have it use the first map in the list)
    // if (onTheClockOption?.showReport || false) {
    //   let onTheClockMap = payrollUploadVM.clientMaps.find(x => x.mapId === 724);
    //   onTheClockMap = (onTheClockMap) ? onTheClockMap : payrollUploadVM.clientMaps[0];
    //   setFile(onTheClockMap);
    //   dispatch(storeSelectedPayrollUploadFile(onTheClockMap));
    // }
    // //Else if they are aod integration use that mapId which is 181 (If they dont have it use the first map in the list) 
    // else if (attendanceOnDemandOption?.showReport || false) {
    //   let aodMap = payrollUploadVM.clientMaps.find(x => x.mapId === 181);
    //   aodMap = (aodMap) ? aodMap : payrollUploadVM.clientMaps[0];
    //   setFile(aodMap);
    //   dispatch(storeSelectedPayrollUploadFile(aodMap));
    // }
    // //Else just set the default map to the first map.
    // else {
    const activeMaps = payrollUploadVM.clientMaps.filter(x => x.clients.some(y => y.active));
    if (!activeMaps || activeMaps.length == 0) return;

    //PI-8709 if the payrollFile is already set keep it as that else choose the first map. This is so when we re-load after a payroll is complete we stay on the same map.
    const activeMap = (payrollFile) ? payrollFile : activeMaps[0];
    setFile(activeMap);
    setActiveClientMaps(activeMaps);
    dispatch(storeSelectedPayrollUploadFile(activeMap));
    // }
  }, [payrollUploadVM]);

  useEffect(() => {
    if (!openPayrollStore) return;
    setRowData(openPayrollStore);
  }, [openPayrollStore]);

  useEffect(() => {
    if (!(fileToolErrorsStore && selectedPayroll)) return;
    setFileToolErrors(fileToolErrorsStore);
  }, [fileToolErrorsStore]);

  useEffect(() => {
    if (!fileToolErrors) return;
    setShowFileToolErrors(true);
  }, [fileToolErrors]);

  useEffect(() => {
    setShowLoadingBar(uploadingData || creatingDefaultMap);
  }, [uploadingData, creatingDefaultMap]);

  useEffect(() => {
    if (!csvFile || !payrollFile?.mapId) return;

    const uploadFile: PayrollUploadFileCheck = {
      mapId: payrollFile.mapId,
      fileName,
      file: csvFile,
    };
    setReUploadFile(true);
    dispatch(payrollUploadFileCheckTool(uploadFile));
  }, [csvFile, payrollFile?.mapId]);

  const onGridReady = (e: GridReadyEvent) => {
    e.api.sizeColumnsToFit();   
  };

  const onSelectionChanged = (e: SelectionChangedEvent) => {
    if (e.api) {
      const selectedRows = e.api.getSelectedRows();
      if (selectedRows.length > 0) setSelectedPayroll(selectedRows[0]);
      else setSelectedPayroll(undefined);
    }
  };

  const handleClick = () => {
    fileInputRef?.current?.click();
  };

  const onSelectAllEmployees = (selectAll: boolean) => {
    if (!fileToolErrors) return;
    const allHighValidationErrors = structuredClone(fileToolErrors.filter(x => x.isHighValidationError)).map(x => x.empNo);
    if (selectAll) {
      setEmpNosToTrack([...allHighValidationErrors]);
    } else {
      setEmpNosToTrack([]);
    }
  };

  const onTrackedEmployee = (empNo: string) => {
    if (empNosToTrack.includes(empNo)) {
      setEmpNosToTrack([...empNosToTrack.filter(x => x !== empNo)]);
    } else {
      setEmpNosToTrack([...empNosToTrack, empNo]);
    }
  };

  const onFileSelected = (selectEvent: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = selectEvent.target.files?.[0];
    if (!fileObj) return dispatch(handleError('Could not read selected file'));
    
    setFileName(fileObj.name);
    setFile((prev: any) => {
      const updatedFile = {
        ...prev,
        fileName: fileObj.name,
        filePath: '',
        startOnLine: 1,
      };
      
      if (!updatedFile?.mapId) {
        dispatch(handleError('MapId not found'));
        return prev;
      } 
      dispatch(storeSelectedPayrollUploadFile(updatedFile));
      return updatedFile;
    });
    
    const reader: FileReader = new FileReader();

    const fileLoaded = (loadEvent: ProgressEvent<FileReader>) => {
      if (!loadEvent?.target?.result) return dispatch(handleError('Error loading selected file'));
      
      const loadedFileRows = String(loadEvent.target.result).split(/\n/);
      const fileRowsHandleQuotes = loadedFileRows.map((line) => line.replace(/\s+(?=([^"]*"[^"]*")*[^"]*$)/g, ''),
      );
      
      setFileRows(fileRowsHandleQuotes);
      setCSVFile(String(loadEvent.target.result));
    };
    
    reader.onload = fileLoaded;
    reader.readAsText(fileObj);

    setFileName(fileObj.name);
  };

  const onSelectUploadFile = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const payrollUpload = activeClientMaps.find((o) => o.mapId === +e.target.value);

    if (payrollUpload) {
      setFile(payrollUpload);
      dispatch(storeSelectedPayrollUploadFile(payrollUpload));
    } else {
      setFile(undefined);
      const newUploadFile = {
        description: fileName,
      } as PayrollUpload;
      dispatch(storeSelectedPayrollUploadFile(newUploadFile));
    }
  };

  const onCreate = () => {
    if (!csvFile) {
      setShowMissingFile(true);
    } else {
      const fileEnding = fileName.substring(fileName.length - 4);
      
      if (!activeClientMaps.length && DMFILETYPES.includes(fileEnding.toLowerCase())) {
        const message = `The selected file is in the ${fileEnding} format.\nDo you want to use the ${fileEnding} upload map to upload this file?`;
        return setShowConfirmationModal({ show: true, message: message });
      }
      
      const newUploadFile = {
        description: fileName,
      } as PayrollUpload;
      
      setFile(newUploadFile);
      setShowStep1Modal(true);
      
      dispatch(storeSelectedPayrollUploadFile(newUploadFile));
    }
  };

  const handleConfirm = (confirm: boolean) => {
    setShowConfirmationModal({ show: false, message: '' });

    const fileEnding = fileName.substring(fileName.length - 4);
    if (confirm) {
      return dispatch(createDefaultPayrollUploadFile({ clientNo: clientNo, fileExtension: fileEnding } as PayrollUploadDefault));
    }

    const newUploadFile = { description: fileName } as PayrollUpload;
    setFile(newUploadFile);
    dispatch(storeSelectedPayrollUploadFile(newUploadFile));
    setShowStep1Modal(true);
  };

  const onEdit = () => {
    if (!csvFile) {
      setShowMissingFile(true);
    } else {
      setFile((prev: any) => {
        return {
          ...prev,
          fileName,
        };
      });
      setShowStep1Modal(true);
    }
  };

  //When the user clicks the integration button we will make sure they have the correct map selected for the integration then start the integration process with the modals. 
  const onUploadDataIntegration = () => {
    if ((onTheClockOption?.showReport || false) && payrollFile?.mapId !== 724)
      return alert('Wrong Map Type Selected for On The Clock Upload.\nPlease select "OnTheClockUpload" Map.');
    else if ((attendanceOnDemandOption?.showReport || false) && payrollFile?.mapId !== 181)
      return alert('Wrong Map Type Selected for Attendance On Demand Upload.\nPlease select "DMPS CSV" Map.');

    if (onTheClockOption?.showReport || false)
      setShowOnTheClockModal(true);
    else if (attendanceOnDemandOption?.showReport || false) {
      if (aodPayrollUploadMap.useMultipleMaps) setShowAttendanceOnDemandModal(true);
      else onUploadStandardAndAod(false, PayrollUploadType.AttendanceOnDemand);
    }
  };

  const onUpload = (skipValidationErrors: boolean) => {
    if (!payrollUploadFile) return;
    const uploadFileCopy = structuredClone(payrollUploadFile);
    uploadFileCopy.skipValidationErrors = skipValidationErrors;
    uploadFileCopy.empNosToTrack = empNosToTrack;
    uploadFileCopy.uploadFile = false; //Set it to false as it got uploaded the first time before the error.

    dispatch(payrollUploadFileTool(uploadFileCopy));
    setShowFileToolErrors(false);
  };

  const onUploadStandardAndAod = (skipValidationErrors: boolean, uploadType: PayrollUploadType) => {
    const uploadFile: PayrollUploadFile = {
      mapId: payrollFile?.mapId,
      weekEnd: selectedPayroll?.weekEnd || '',
      checkDate: selectedPayroll?.checkDate || '',
      locationCode: selectedLocation,
      departmentCode: selectedDepartment,
      fileName,
      file: csvFile,
      skipValidationErrors,
      overwrite: overWrite === 'true',
      empNosToTrack: empNosToTrack,
      uploadType,
      frequency: '',
      aodMapName: '',
      fromDate: null,
      toDate: null,
      uploadFile: reUploadFile,
      onTheClockData: [],
    };

    //Once we upload it set re-upload to false as we dont want to keep uploading the same file over and over.
    setReUploadFile(false);
    dispatch(payrollUploadFileTool(uploadFile));
    setShowFileToolErrors(false);
    setPayrollUploadFile(uploadFile);
  };

  const hideFileToolErrors = () => {
    setShowFileToolErrors(false);
  };

  const onUploadTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOverWrite(e.target.value);
  };

  const formatDate = (date: string): string => {
    return DateTime.fromISO(date).toFormat('MM/dd/yyyy');
  };

  return (
    <div className="container-fluid shadow-lg pb-3">
      <div className="row mx-4">
        <div className="col">
          <div className="dm-page-title">Available Payrolls</div>
        </div>
        <div className="col align-items-end d-flex justify-content-end">
          {isSuperUser ? (
            <div>
              <button
                type="button"
                onClick={() => { setShowManageClientMaps(true); }}
                className="btn btn-link dm-grid-action-title p-0 mr-3"
              >
                Manage Client Upload Maps
                <Icon name="bars-progress" />
              </button>
            </div>
          ) : null}

          {(onTheClockOption?.showReport || false) || (attendanceOnDemandOption?.showReport || false) ? <button
            type="button"
            onClick={onUploadDataIntegration}
            disabled={!payrollFile?.mapId || !selectedLocation || !selectedDepartment || !selectedPayroll}
            className="btn btn-link dm-grid-action-title p-0 mr-3"
          >
            {integrationButtonText}&nbsp;
            <Icon name="clock" />
          </button> : null}
          <button
            type="button"
            onClick={onEdit}
            //Do not let them edit the OnTheClockMap
            disabled={!payrollFile?.clients?.length || payrollFile.mapId === 724}
            className="btn btn-link dm-grid-action-title p-0 mr-3"
          >
            Modify Payroll Upload Map&nbsp;
            <Icon name="pencil-alt" />
          </button>
          <button
            type="button"
            onClick={onCreate}
            className="btn btn-link dm-grid-action-title p-0"
          >
            Create a New Payroll Upload Map&nbsp;
            <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </div>
      </div>
      <hr className="dm-page-hr mx-4 mb-4 row" />
      <div className="row mx-4 mb-4">
        <div
          className="ag-theme-balham col"
          style={{
            height: 400,
            width: '100%',
          }}
        >
          <AgGridReact
            rowData={rowData}
            defaultColDef={{
              resizable: true,
              sortable: true,
            }}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            rowSelection="single"
          >
            <AgGridColumn
              field="weekEnd"
              headerName="Week Ending"
              valueGetter={(params: ValueGetterParams) => { return formatDate(params.data.weekEnd); }
              }
            />
            <AgGridColumn
              field="checkDate"
              valueGetter={(params: ValueGetterParams) => { return formatDate(params.data.checkDate); }
              }
            />
            <AgGridColumn
              field="nextWeekEnd"
              headerName="New Week End"
              valueGetter={(params: ValueGetterParams) => { return formatDate(params.data.nextWeekEnd); }
              }
            />
            <AgGridColumn
              field="nextCheckDate"
              headerName="Next Check Date"
              valueGetter={(params: ValueGetterParams) => { return formatDate(params.data.nextCheckDate); }
              }
            />
            <AgGridColumn
              field="prFrequency"
              headerName="Frequency"
              valueGetter={(params: ValueGetterParams) => {
                const data: CheckPayroll = params.data;
                return payrollFrequencyOptions.find((a) => a.value === data.prFrequency)?.description;
              }}
            />
            <AgGridColumn
              field="status"
              headerName="Status"
            />
            <AgGridColumn
              headerName="Select Payroll"
              checkboxSelection={true}
            />
          </AgGridReact>
        </div>
      </div>
      <div className="row mx-5">
        <div className="col-4">
          <form onSubmit={() => { }}>
            {payrollUploadVM?.clientMaps && (
              <SelectGrp
                name="uploadMap"
                label="Selected Upload Map: "
                options={activeClientMaps}
                onChange={onSelectUploadFile}
                value = {payrollFile?.mapId || activeClientMaps[0]}
                required
                valueField="mapId"
                labelField="description"
              />
            )}
            {(controlTotalBy === 'Location' || controlTotalBy === 'Department') && (
              <SelectGrp
                name="location"
                label="Selected Location: "
                options={locationOpts}
                labelField={'locationDesc'}
                valueField={'locationCode'}
                defaultValue={selectedLocation}
                addHomeOption={'Use home loc.'}
                required
                onChange={(e: React.BaseSyntheticEvent) => { 
                  return setSelectedLocation(+e.target.value); 
                }}
              />
            )}
            {controlTotalBy === 'Department'  && (
              <SelectGrp
                name="department"
                label="Select Department: "
                options={deptOpts}
                labelField={'deptDesc'}
                valueField={'deptCode'}
                addHomeOption={'Use home dept.'}
                defaultValue={selectedDepartment}
                required
                onChange={(e: React.BaseSyntheticEvent) => { 
                  return setSelectedDepartment(+e.target.value); 
                }}
              />
            )}
          </form>
        </div>
        <div className="col-1">&nbsp;</div>
        <div className="col-3">
          <RadioGrp
            label={'Append or Overwrite the payroll upload file'}
            radioOptions={radioOptions}
            defaultChecked={overWrite}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onUploadTypeChange(e); }}
            name="overWrite"
          />
        </div>
      </div>
      <div className="row mx-5">
        <div className="col-md-4">
          <input
            type="file"
            name="datafile"
            ref={fileInputRef}
            onChange={onFileSelected}
            style={{ display: 'none' }}
          />
          <input
            type="text"
            name="displayDataFile"
            readOnly
            defaultValue={fileName}
            style={{ width: '100%' }}
          />
        </div>
        <div className="col-md-1 pl-1">
          <button
            type="button"
            className="btn btn-primary orange-button"
            onClick={handleClick}
          >
            Browse
          </button>
        </div>
        <div className="col-md-3 flex-col">
          <button
            type="button"
            className="btn btn-primary orange-button"
            onClick={() => { onUploadStandardAndAod(false, PayrollUploadType.Standard); }}
            disabled={!selectedPayroll || !fileName || !payrollFile}
          >
            Upload to Selected Payroll
          </button>
        </div>
      </div>
      {showStep1Modal && payrollFile && (
        <UploadPayrollStep1Modal
          csvFile={csvFile}
          fileName={fileName}
          show={showStep1Modal}
          onHide={() => { setShowStep1Modal(false); }}
          next={() => { setShowStep2Modal(true); }}
          setHeaderLine={setHeaderStartingLine}
          payrollUploadClientFile={payrollUploadClientFile}
        />
      )}
      {showStep2Modal && (
        <UploadPayrollStep2Modal
          csvFile={csvFile}
          fileRows={fileRows}
          show={showStep2Modal}
          onHide={() => { setShowStep2Modal(false); }}
          prev={() => { setShowStep1Modal(true); }}
          headerStartingLine={headerStartingLine}
        />
      )}
      {showMissingFile && (
        <UploadPayrollMissingFile
          show={showMissingFile}
          onHide={() => { setShowMissingFile(false); }}
        />
      )}
      {showConfirmationModal.show && showConfirmationModal.message && (
        <ConfirmationModal
          show={showConfirmationModal.show}
          title={'Default Upload Map'}
          message={showConfirmationModal.message}
          onConfirmed={(confirm: boolean) => { handleConfirm(confirm); }}
          onHide={() => { setShowConfirmationModal({ show: false, message: '' }); }}
        />
      )}
      {fileAlreadyUploaded.alreadyUploded && fileAlreadyUploaded.message && (
        <ConfirmationModal
          show={fileAlreadyUploaded.alreadyUploded}
          title={'Payroll Upload'}
          message={fileAlreadyUploaded.message}
          onConfirmed={(confirm: boolean) => { setReUploadFile(confirm); }}
          onHide={() => { dispatch(storePayrollUploadFileToolCheckErrors({ alreadyUploaded: false, message: '' })); }}
        />
      )}
      {showFileToolErrors && fileToolErrors && (
        <FileToolErrorsModal
          show={showFileToolErrors}
          fileToolErrors={fileToolErrors}
          onConfirm={(confirm: boolean) => { onUpload(confirm); }}
          onHide={() => { hideFileToolErrors(); }}
          onTrackedEmployee={onTrackedEmployee}
          onSelectAllEmployees={onSelectAllEmployees}
          trackedEmployees={empNosToTrack}
        />
      )}
      {showOnTheClockModal && (
        <OnTheClockModal
          mapId={payrollFile?.mapId || 0}
          weekEnd={selectedPayroll?.weekEnd}
          checkDate={selectedPayroll?.checkDate}
          show={showOnTheClockModal}
          selectedLocation={selectedLocation}
          selectedDepartment={selectedDepartment}
          setPayrollUploadFile={setPayrollUploadFile}
          onHide={() => { setShowOnTheClockModal(false); }}
        />
      )}
      {showAttendanceOnDemandModal && (
        <AttendanceOnDemandModal
          mapId={payrollFile?.mapId || 0}
          weekEnd={selectedPayroll?.weekEnd}
          checkDate={selectedPayroll?.checkDate}
          aodMapNames={aodPayrollUploadMap.maps}
          selectedLocation={selectedLocation}
          selectedDepartment={selectedDepartment}
          show={showAttendanceOnDemandModal}
          setPayrollUploadFile={setPayrollUploadFile}
          onHide={() => { setShowAttendanceOnDemandModal(false); }}
        />
      )}
      {showManageClientMaps && (
        <ManageClientUploadMapsModal
          show={showManageClientMaps}
          onHide={() => { setShowManageClientMaps(false); }}
        />
      )}
      <Modal
        backdrop="static"
        keyboard={false}
        show={showLoadingBar}
        centered={true}
        fullscreen={true}
      >
        <Modal.Header>
          <Modal.Title>
            {(creatingDefaultMap) ? 'Creating Default Map...' : 'Uploading Payroll...'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProgressBar
            animated
            now={100}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UploadPayrollPage;
