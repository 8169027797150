import { createAction } from '@reduxjs/toolkit';
import { OtherDate } from '../../models';

export const OTHER_DATE_EM_VALIDATION_ACTIONS = {
  CREATE_OTHER_DATE: '[hire-history] CREATE_OTHER_DATE',
  UPDATE_OTHER_DATE: '[hire-history] UPDATE_OTHER_DATE',
  DELETE_OTHER_DATE: '[hire-history] DELETE_OTHER_DATE',
} as const;

export const loadOtherDates = createAction<string>('[hire-history] LOAD_OTHER_DATES');
export const storeOtherDates = createAction<OtherDate[]>('[hire-history] STORE_OTHER_DATES');

export const loadOtherDate = createAction<string>('[hire-history] LOAD_OTHER_DATE');
export const storeOtherDate = createAction<OtherDate>('[hire-history] STORE_OTHER_DATE');

export const createOtherDate = createAction<OtherDate>(OTHER_DATE_EM_VALIDATION_ACTIONS.CREATE_OTHER_DATE);
export const updateOtherDate = createAction<OtherDate>(OTHER_DATE_EM_VALIDATION_ACTIONS.UPDATE_OTHER_DATE);
export const deleteOtherDate = createAction<OtherDate>(OTHER_DATE_EM_VALIDATION_ACTIONS.DELETE_OTHER_DATE);
