import Modal from 'core/components/modals/Modal';
import React from 'react';
import { useAppSelector } from 'utilities/hooks';

type Props = {
  ssn: string;
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
};

const DuplicatSsnNoticeModal = ({ ssn, show, onHide, onConfirm }: Props) => {
  const { employees } = useAppSelector(({ employees }) => employees);
  const empMatch = employees?.find((emp) => emp.ssn === ssn);
  const empNameAndNumber = `Employee #${empMatch?.empNo ?? ''} - ${empMatch?.firstName ?? ''} ${empMatch?.lastName ?? ''}`;
  return (
    <Modal
      title="Duplicate SSN found"
      show={show}
      onHide={onHide}
    >
      <div className="d-flex flex-column">
        <span>Duplicate Social Security Number found: {empNameAndNumber}</span>
        <span>Is this employee a 1099M employee? If so, then the duplicate Social Security Number will be ignored.</span>
        <div className="d-flex justify-content-end">
          <button
            className="btn orange-outline-button-sm mr-2"
            onClick={(e) => {
              e.preventDefault(); onHide();
            }}
          >
            No
          </button>
          <button
            className="btn orange-button-sm"
            onClick={(e) => {
              e.preventDefault();
              onConfirm();
            }}
          >
            Yes
          </button>
        </div>      
      </div>
    </Modal>
  );
};

export default DuplicatSsnNoticeModal;