import React, { useState } from 'react';

import {
  CheckboxGrpInLine,
  ControlDatePickerGrp,
  InputGrp,
  InputGrpDecimal,
  SelectGrp,
  TextareaGrp,
} from '../../../core/components/form-controls';
import { HRDependent } from '../../../core/models/HREmployee.model';
import { useSelector } from 'react-redux';
import { Collapse } from 'react-bootstrap';
import { getStates, getForeignCountries } from '../../../core/store/selectors';
import { useAppSelector } from '../../../utilities/hooks';
import { ArrayField } from 'react-hook-form';
import { PHONE_VALIDATION, ZIP_VALIDATION } from '../../../core/constants';
import { formatPhone } from '../../../utilities/utilities';
import Icon from 'core/components/shared/Icon';

type PropTypes = {
  item: Partial<ArrayField<HRDependent>>;
  index: number;
  control: any;
  formMethods: any;
  onDelete: (item: HRDependent) => void;
};

const DependentsItem: React.FC<PropTypes> = ({
  item,
  index,
  control,
  formMethods: { register, errors },
  onDelete,
}) => {


  const [openState, setOpenState] = useState<{ [key: string]: boolean }>({
    dependents: false,
  });

  const contactRelationshipDropDown = useAppSelector(
    (state) => { return state.dropdown.contactRelationship; },
  );

  const stateOpts = useSelector(getStates);
  const foreignCountryOpts = useSelector(getForeignCountries);

  const toggleInfo = (type: string) => {
    setOpenState({
      ...openState,
      [type]: !openState[type],
    });
  };


  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-wrap">
        <input
          type="hidden"
          name={`dependentItems[${index}].contactId`}
          defaultValue={item.contactId}
          ref={register({ valueAsNumber: true })}
        />
        <InputGrp
          name={`dependentItems[${index}].firstName`}
          defaultValue={item.firstName}
          label="FIRST NAME"
          groupClass="gc12"
          ref={register()} />

        <InputGrp
          name={`dependentItems[${index}].midName`}
          defaultValue={item.midName}
          label="MIDDLE INITIAL"
          groupClass="gc12"
          ref={register()} />

        <InputGrp
          name={`dependentItems[${index}].lastName`}
          defaultValue={item.lastName}
          label="LAST NAME"
          groupClass="gc12"
          ref={register()} />

        <InputGrp
          name={`dependentItems[${index}].suffix`}
          defaultValue={item.suffix}
          label="SUFFIX"
          groupClass="gc12"
          ref={register()} />

        <SelectGrp
          label="Relationship"
          ref={register({
            valueAsNumber: true,
          })}
          addOptionText="Relationship"
          name={`dependentItems[${index}].relationshipId`}
          options={contactRelationshipDropDown}
          defaultValue={item.relationshipId}
          groupClass="gc12"
        />

        <InputGrp
          name={`dependentItems[${index}].homePhone`}
          defaultValue={item.homePhone}
          label="HOME PHONE"
          groupClass="gc12"
          errors={errors.dependentItems?.[index]?.homePhone}
          onChange={(e: any) => {
            const { value } = e?.target;
            e.target.value = formatPhone(value);
          }}
          ref={register({
            pattern: {
              value: PHONE_VALIDATION,
              message: 'Invalid phone number',
            },
          })} />

        <InputGrp
          name={`dependentItems[${index}].workPhone`}
          defaultValue={item.workPhone}
          label="WORK PHONE"
          groupClass="gc12"
          errors={errors.dependentItems?.[index]?.workPhone}
          onChange={(e: any) => {
            const { value } = e?.target;
            e.target.value = formatPhone(value);
          }}
          ref={register({
            pattern: {
              value: PHONE_VALIDATION,
              message: 'Invalid phone number',
            },
          })} />

        <InputGrp
          name={`dependentItems[${index}].cellPhone`}
          defaultValue={item.cellPhone}
          label="CELL PHONE"
          groupClass="gc12"
          errors={errors.dependentItems?.[index]?.cellPhone}
          onChange={(e: any) => {
            const { value } = e?.target;
            e.target.value = formatPhone(value);
          }}
          ref={register({
            pattern: {
              value: PHONE_VALIDATION,
              message: 'Invalid phone number',
            },
          })} />

      </div>
      <div className="d-flex flex-column">
        <div
          role="button"
          className="dm-card-subtitle2 mb-2"
          onClick={() => {
            return toggleInfo(
              'dependents',
            );
          }}
        >
          <Icon name={openState.dependents ? 'chevron-down' : 'chevron-right'}
            className="mr-2" />
          Dependent Details
        </div>

        <Collapse in={openState.dependents}>
          <div className="dm-panel dm-panel-border mt-1">
            <div className="d-flex flex-row">

              <div className="d-flex flex-column flex-grow-1 mr-3">
                <div className="dm-card-subtitle2 mb-2">Dependent Information</div>
                <hr className="dm-panel-hr" />
                <div className="d-flex flex-row">
                  <InputGrp
                    name={`dependentItems[${index}].address`}
                    label="STREET ADDRESS"
                    defaultValue={item.address}
                    groupClass="gc12"
                    ref={register()} />
                  <InputGrp
                    name={`dependentItems[${index}].city`}
                    label="CITY"
                    defaultValue={item.city}
                    groupClass="gc12"
                    ref={register()} />
                </div>
                <div className="d-flex flex-row">
                  <SelectGrp
                    noOption={true}
                    noOptionText="STATE"
                    name={`dependentItems[${index}].state`}
                    defaultValue={item.state}
                    label="STATE"
                    groupClass="gc12"
                    ref={register()}
                    options={stateOpts}
                  />
                  <InputGrp
                    name={`dependentItems[${index}].zip`}
                    label="ZIP"
                    type="number"
                    defaultValue={item.zip}
                    groupClass="gc12"
                    errors={errors.dependentItems?.[index]?.zip}
                    ref={register({
                      pattern: {
                        value: ZIP_VALIDATION,
                        message: 'Invalid zip',
                      },
                    })}
                  />
                  <InputGrp
                    name={`dependentItems[${index}].zip4`}
                    label="&nbsp;"
                    defaultValue={item.zip4}
                    groupClass="gc12 mw50"
                    ref={register()} />
                  <SelectGrp
                    noOption={true}
                    noOptionText="COUNTRY"
                    name={`dependentItems[${index}].country`}
                    defaultValue={item.country}
                    label="COUNTRY"
                    groupClass="gc12"
                    ref={register()}
                    options={foreignCountryOpts}
                  />
                </div>
                <div className="d-flex flex-row">

                  <ControlDatePickerGrp
                    name={`dependentItems[${index}].birthDate`}
                    label="BIRTH DATE"
                    groupClass="gc12"
                    value={item.birthDate}
                    control={control}
                  />
                  <ControlDatePickerGrp
                    name={`dependentItems[${index}].marriageDate`}
                    label="MARRIAGE DATE"
                    groupClass="gc12"
                    value={item.marriageDate}
                    control={control}
                  />
                </div>
              </div>


              <div className="d-flex flex-column flex-grow-1 mr-3">
                <div className="dm-card-subtitle2 mb-2">Email</div>
                <hr className="dm-panel-hr" />

                <div className="d-flex flex-column">
                  <InputGrp
                    name={`dependentItems[${index}].homeEmail`}
                    defaultValue={item.homeEmail}
                    label="HOME EMAIL"
                    groupClass="gc12"
                    ref={register()} />
                  <InputGrp
                    name={`dependentItems[${index}].workEmail`}
                    defaultValue={item.workEmail}
                    label="WORK EMAIL"
                    groupClass="gc12"
                    ref={register()} />
                  <InputGrp
                    name={`dependentItems[${index}].otherEmail`}
                    defaultValue={item.otherEmail}
                    label="OTHER EMAIL"
                    groupClass="gc12"
                    ref={register()} />
                </div>

              </div>

              <div className="d-flex flex-column  flex-grow-1">
                <div className="dm-card-subtitle2 mb-2">Scholarship</div>
                <hr className="dm-panel-hr" />
                <div className="d-flex flex-row">
                  <InputGrp
                    name={`dependentItems[${index}].hsGradYear`}
                    type="number"
                    required={true}
                    defaultValue={item.hsGradYear}
                    label="HIGH SCHOOL GRADUATION YEAR"
                    groupClass="gc12"
                    ref={register({
                      valueAsNumber: true,
                      maxLength: {
                        value: 4,
                        message: 'Max length is 4 chars',
                      },
                      minLength: {
                        value: 4,
                        message: 'Min length is 4 chars',
                      },
                    })}
                    errors={errors.dependentItems?.[index]?.hsGradYear}
                  />
                </div>
                <div className="d-flex flex-row">
                  <InputGrpDecimal
                    name={`dependentItems[${index}].scholarAmount`}
                    defaultValue={item.scholarAmount || 0}
                    label="AMOUNT"
                    groupClass="gc12"
                    ref={register({ valueAsNumber: true })} />
                  <ControlDatePickerGrp
                    name={`dependentItems[${index}].scholarPaidDate`}
                    label="SCHOLARSHIP PAID DATE"
                    groupClass="gc12"
                    value={item.scholarPaidDate}
                    control={control}
                  />
                </div>
                <div className="d-flex flex-row">
                  <CheckboxGrpInLine
                    label="Scholarship Recipient"
                    groupClass="gc12 mw150"
                    name={`dependentItems[${index}].scholarRecipient`}
                    defaultChecked={item.scholarRecipient}
                    ref={register()}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-column">
              <div className="dm-card-subtitle2">Other Information</div>
              <hr className="dm-panel-hr" />
              <div className="d-flex flex-row">
                <CheckboxGrpInLine
                  labelFirst={false}
                  label="Authorize to Share Private Info"
                  groupClass="gc12 mw175"
                  name={`dependentItems[${index}].authorizedToShare`}
                  defaultChecked={item.authorizedToShare}
                  ref={register()}
                />
                <CheckboxGrpInLine
                  labelFirst={false}
                  label="Emergency Contact"
                  groupClass="gc12 mw150"
                  name={`dependentItems[${index}].isEmergencyContact`}
                  defaultChecked={item.isEmergencyContact}
                  ref={register()}
                />
              </div>
              <TextareaGrp
                label="NOTES"
                name={`dependentItems[${index}].note`}
                defaultValue={item.note}
                groupClass="gc12"
                ref={register()} />
            </div>
          </div>
        </Collapse>
      </div>
      <div className="text-right">
        <button
          type="button"
          className="btn btn-link dm-grid-action-title mt-2"
          onClick={() => { return onDelete(item as HRDependent); }}
        >
          Delete Dependent
          <Icon name="minus-circle"
            className="fa-minus-circle" />
        </button>
      </div>
    </div>
  );
};

export default DependentsItem;
