import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom';
import { UNSAVED_MESSAGE } from '../../../core/constants';
import { HireGeneral, HireInfoCustomField } from '../../../core/models/HREmployee.model';
import { loadEmployeeHireGeneral } from '../../../core/store/actions/hr-employee.action';
import { getEmpHireGeneral, getHireInfoCustomFields } from '../../../core/store/selectors/hr-employee.selector';
import HireGeneralComponent from './HireGeneral';

const HrGeneralPage: React.FC<any> = () => {
  const dispatch = useDispatch();
  const params = useParams<any>();
  const employeeHireGeneral = useSelector(getEmpHireGeneral) as HireGeneral;
  const [protectedEmpNo, setProtectedEmpNo] = useState('');
  const [hireGeneral, setHireGeneral] = useState<HireGeneral>();
  const hireInfoCustomFields = useSelector(getHireInfoCustomFields) as HireInfoCustomField[];

  const { formState, ...formMethods } = useForm<HireGeneral>({
    defaultValues: hireGeneral,
  });

  useEffect(() => {
    params &&
            params.protectedEmpNo &&
            setProtectedEmpNo(params.protectedEmpNo);
  }, [params]);

  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(
        loadEmployeeHireGeneral({
          protectedEmpNo,
        }),
      );
    }
  }, [protectedEmpNo]);

  useEffect(() => {
    employeeHireGeneral && setHireGeneral(employeeHireGeneral);
  }, [employeeHireGeneral]);

  useEffect(() => {
    hireGeneral && formMethods.reset({
      ...hireGeneral,
      townHallInvite: '' + hireGeneral.townHallInvite,
      coachEmailDistributionList: '' + hireGeneral.coachEmailDistributionList,
      textAuthorized: '' + hireGeneral.textAuthorized,
      emailAuthorized: '' + hireGeneral.emailAuthorized,

    });
  }, [hireGeneral]);

  return (
    <Row className="no-gutters">
      <Col className="m-3 shadow p-3 border">
        {protectedEmpNo && hireGeneral ? (
          <HireGeneralComponent
            protectedEmpNo={protectedEmpNo}
            hireInfoCustomFields={hireInfoCustomFields}
            hireGeneral={hireGeneral}
            formMethods={formMethods}
            formState={formState}
          />
        ) : null}
      </Col>
    </Row>
  );
};

export default HrGeneralPage;