import { AxiosResponse } from "axios";
import { ContractorOption, HttpResponse } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getContractorOptions = (empNo: string): Promise<AxiosResponse<ContractorOption[]>> => { return API_EMPLOYEE_MASTER.get<ContractorOption[]>(EmployeeEndPoints.GET_CONTRACTOR_OPTIONS(CommonService.getProtectedClientNo(), empNo)); };

const putContractorOptions = (data: ContractorOption[]): Promise<AxiosResponse<HttpResponse<ContractorOption[]>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<ContractorOption[]>>(EmployeeEndPoints.PUT_CONTRACTOR_OPTIONS(CommonService.getProtectedClientNo(), '' + data[0].protectedEmpNo), data); };

export const ContractorOptionsService = {
  getContractorOptions,
  putContractorOptions,
};
