import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { InputGrp } from 'core/components/form-controls';
import { SelectGrp } from 'core/components/form-controls/SelectGrp';
import { PayrollLocalWithholding } from 'core/models/PayrollAdjustment.model';
import { getIsFinishedPayroll, getLocalTaxEntity } from 'core/store/selectors';
import { useAppSelector } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';
import { formatWithCommas } from 'utilities/utilities';
import { FormMethods } from 'core/components/form-controls/types';

type Props = {
  index: number;
  control: any;
  errors: FormMethods['errors'];
  register: FormMethods['register'];
  setValue: FormMethods['setValue'];
  watch: FormMethods['watch'];
  setOpenState: React.Dispatch<React.SetStateAction<boolean>>;
};

const AdjustmentLocalWithholding: React.FC<Props> = ({
  control,
  register,
  errors,
  watch,
  index,
  setValue,
  setOpenState,
}) => {
  const { fields, append, remove } = useFieldArray<PayrollLocalWithholding>({
    control,
    name: `items[${index}].localWithholdings`,
  });

  const newAdjustmentCheck = useAppSelector((state) => { return state.payrollAdjustment.adjustmentCheckDefaults; });
  const localTaxEntityOpts = useSelector(getLocalTaxEntity);
  const isFinishedPayroll =  useSelector(getIsFinishedPayroll);
  const disabled = (control.getValues(`items[${index}].adjustmentType`)?.startsWith('Void') ?? false) || isFinishedPayroll;
  const localWh = watch(`items[${index}].localWithholdings`) as PayrollLocalWithholding[];

  const taxEntityError = (index: number, li: number) => {
    if (!errors.items) return undefined;
    if (!errors.items[index]?.localWithholdings) return undefined;

    return errors?.items[index]?.localWithholdings[li]?.taxEntityId;
  };

  const onAdd = () => {
    if (
      newAdjustmentCheck &&
      newAdjustmentCheck.localWithholdings.length > 0
    ) {
      append(newAdjustmentCheck.localWithholdings[0]);
    } else {
      append({
        gross: 0,
        adjustmentLocalTaxId: 0,
        taxEntityId: 0,
        withholding: 0,
        entity: '',
        employerTax: 0,
      });
    }
  };

  return (
    <>
      <div className="text-right mt-2 mb-2">
        {!isFinishedPayroll ? <button
          type="button"
          className="btn btn-link dm-grid-action-title p-0"
          onClick={onAdd}
          disabled={disabled}
        >
          Add Withholding
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button> : null}
      </div>
      <hr />
      {fields.map((localtax, li) => {
        const amountFormState = watch(`items[${index}].localWithholdings[${li}].withholding`);
        const erTaxFormState = watch(`items[${index}].localWithholdings[${li}].employerTax`);
        const grossFormState = watch(`items[${index}].localWithholdings[${li}].gross`);
        const localTaxEntityId = watch(`items[${index}].localWithholdings[${li}].taxEntityId`);
        const localTaxEntity = watch(`items[${index}].localWithholdings[${li}].entity`);

        return (
          <div
            className="d-flex align-items-end"
            key={li}
          >
            <input
              type="hidden"
              name={`items[${index}].localWithholdings[${li}].adjustmentLocalTaxId`}
              defaultValue={localtax.adjustmentLocalTaxId}
              ref={register({
                valueAsNumber: true,
              })}
              disabled={disabled}
            />
            <input
              type="hidden"
              name={`items[${index}].localWithholdings[${li}].entity`}
              value={localTaxEntity}
              ref={register()}
            />
            <div className="mr-2">
              <SelectGrp
                groupClass={''}
                options={localTaxEntityOpts}
                name={`items[${index}].localWithholdings[${li}].taxEntityId`}
                id="taxEntity"
                errors={taxEntityError(index, li)}
                ref={register({
                  valueAsNumber: true,
                  validate: (value) => {
                    if (localWh.filter(x => x.taxEntityId == +value).length > 1) {
                      setOpenState(true);
                      return 'Duplicate Local Taxes';
                    }
                  },
                })}
                errorMsg= "Duplicate Local Taxes"
                value={localTaxEntityId}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const matchedEntity = localTaxEntityOpts.find((entity) => +entity.id === +e.target.value);
                  if (!matchedEntity) return console.error('Could not match entity');
                  setValue(`items[${index}].localWithholdings[${li}].entity`, matchedEntity.description);
                }}
                disabled={disabled}
              />
            </div>
            <div className="mr-2">
              <InputGrp
                groupClass="number-underline"
                label="Amount"
                type="text"
                name={`items[${index}].localWithholdings[${li}].withholding`}
                ref={register({
                  valueAsNumber: true,
                })}
                defaultValue={formatWithCommas(amountFormState ?? 0)}
                setValue={setValue}
                disabled={disabled}
                formatNumber
                hiddenValue={amountFormState ?? 0}
                hiddenRef={register({
                  valueAsNumber: true,
                })}
              />
            </div>
            <div className="mr-2">
              <InputGrp
                groupClass="number-underline"
                label="ERTax"
                type="text"
                name={`items[${index}].localWithholdings[${li}].employerTax`}
                ref={register({
                  valueAsNumber: true,
                })}
                defaultValue={formatWithCommas(erTaxFormState ?? 0)}
                setValue={setValue}
                disabled={true}
                formatNumber
                hiddenValue={erTaxFormState ?? 0}
                hiddenRef={register({
                  valueAsNumber: true,
                })}
              />
            </div>
            <div>
              <InputGrp
                groupClass="number-underline"
                label="Gross"
                type="text"
                name={`items[${index}].localWithholdings[${li}].gross`}
                ref={register({
                  valueAsNumber: true,
                })}
                defaultValue={formatWithCommas(grossFormState ?? 0)}
                setValue={setValue}
                disabled={true}
                formatNumber
                hiddenValue={grossFormState ?? 0}
                hiddenRef={register({
                  valueAsNumber: true,
                })}
              />
            </div>
            {(
              <button
                type="button"
                className="btn btn-link dm-grid-action-title"
                onClick={() => { return remove(li); }}
              >
                <Icon
                  name="minus-circle"
                  className="fa-minus-circle"
                />
              </button>
            )}
          </div>
        );
      })}
    </>
  );
};

export default AdjustmentLocalWithholding;
