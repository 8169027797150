import { ofType, StateObservable } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpResponse } from '../../models';
import { File } from '../../models/File.model';
import { FileService } from '../../services/file.service';
import { handleError, handleSuccess } from '../actions';
import {
  addFileRecordId,
  deleteClientFile,
  deleteFileFolder,
  getFile,
  getUserFiles,
  postClientFileUpload,
  postFileFolder,
  postMoveClientH2R,
  putAssignFile,
  putClientFile,
  putFileFolder,
  resetLoading,
  storeFile,
  storeUserFiles,
} from '../slices/file.slice';
import { RootState } from '../store';

interface Actions<T> {
  type: string;
  payload: T;
}

const getFile$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getFile.type),
    switchMap((action: { payload: string; }) => {
      return from(FileService.getFile(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeFile(res); }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const postClientFileUpload$ = (
  action$: Observable<Actions<File.FileUpload>>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    ofType(postClientFileUpload.type),
    switchMap((action: { payload: File.FileUpload; }) => {
      return from(FileService.postClientFileUpload(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getUserFiles(state$.value.file.displayType),
            addFileRecordId(res.value.fileRecordId),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const putClientFile$ = (
  action$: Observable<Actions<File.FileRecord>>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    ofType(putClientFile.type),
    switchMap((action: { payload: File.FileRecord; }) => {
      return from(FileService.putClientFile(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getUserFiles(state$.value.file.displayType),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const deleteClientFile$ = (
  action$: Observable<Actions<number>>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    ofType(deleteClientFile.type),
    switchMap((action: { payload: number; }) => {
      return from(FileService.deleteClientFile(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getUserFiles(state$.value.file.displayType),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const getUserFiles$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getUserFiles.type),
    switchMap((action: { payload: string; }) => {
      return from(FileService.getUserFiles(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeUserFiles(res); }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const postFileFolder$ = (
  action$: Observable<Actions<File.ManageFolder>>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    ofType(postFileFolder.type),
    switchMap((action: { payload: File.ManageFolder; }) => {
      return from(FileService.postFileFolder(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getUserFiles(state$.value.file.displayType),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const putFileFolder$ = (
  action$: Observable<Actions<File.ManageFolder>>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    ofType(putFileFolder.type),
    switchMap((action: { payload: File.ManageFolder; }) => {
      return from(FileService.putFileFolder(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getUserFiles(state$.value.file.displayType),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const deleteFileFolder$ = (
  action$: Observable<Actions<number>>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    ofType(deleteFileFolder.type),
    switchMap((action: { payload: number; }) => {
      return from(FileService.deleteFileFolder(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getUserFiles(state$.value.file.displayType),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const putAssignFile$ = (
  action$: Observable<Actions<File.AssignFile>>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    ofType(putAssignFile.type),
    switchMap((action: { payload: File.AssignFile; }) => {
      return from(FileService.putAssignFile(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getUserFiles(state$.value.file.displayType),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const postMoveClientH2R$ = (
  action$: Observable<Actions<any>>,
) => {
  return action$.pipe(
    ofType(postMoveClientH2R.type),
    switchMap(() => {
      return from(FileService.moveClientH2R()).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getUserFiles('Folder'),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  getUserFiles$,
  getFile$,
  putClientFile$,
  deleteClientFile$,
  postClientFileUpload$,
  postFileFolder$,
  deleteFileFolder$,
  putFileFolder$,
  putAssignFile$,
  postMoveClientH2R$,
];
