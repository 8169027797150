import { TableModel as Tm } from './types';

export const filterModelOpts: { description: Tm.FilterType }[] = [
  { description: 'Contains' },
  { description: 'Not contains' },
  { description: 'Blank' },
  { description: 'Not blank' },
  { description: 'Equals' },
  { description: 'Not equal' },
  { description: 'Starts with' },
  { description: 'Ends with' },
  { description: 'After' },
  { description: 'Before' },
  { description: 'In range' },
];