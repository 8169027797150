import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpResponse, PayrollInfo } from '../../models';
import { PayrollInfoService } from '../../services';
import {
  loadPayrollInfo,
  storePayrollInfo,
  triggerEmFieldValidation,
  updatePayrollInfo,
} from '../actions';
import { handleError, handleSuccess } from '../actions/app.action';

interface Actions {
  type: string;
  payload: any;
}

const loadPayrollInfo$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadPayrollInfo.type),
    switchMap((action: { payload: string; }) => {
      return from(PayrollInfoService.getPayrollInfo(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: PayrollInfo) => { return storePayrollInfo(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updatePayrollInfo$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updatePayrollInfo.type),
    switchMap((action: { payload: PayrollInfo; }) => {
      return from(PayrollInfoService.putPayrollInfo(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<PayrollInfo>) => {
          return [
            storePayrollInfo(res.value),
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'payrollinfo',
              actionType: updatePayrollInfo.type,
              callerPayload: res.value,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [loadPayrollInfo$, updatePayrollInfo$];
