import React, { Dispatch, SetStateAction, useRef } from 'react';
import { SelectGrp } from 'core/components/form-controls';
import { FormlessCheckboxGrp } from 'core/components/form-controls/FormlessCheckboxGrp';
import { FieldInputSettings } from 'core/components/form-controls/types';
import Icon from 'core/components/shared/Icon';
import { Payroll, PayrollControlTotal, TransmittalEmployee, TransmittalSortOrder } from 'core/models';
import { getFeedRequestsIntoTransmittal, getLocationDepartmentsWithoutHome } from 'core/store/selectors';
import { useSelector } from 'react-redux';
import searchIcon from 'assets/images/search.png';
import { useAppDispatch, useAppSelector, useEffectOnMount } from 'utilities/hooks';
import { handleError, postApprovedAccrualReport, postPendingAccrualReport, sortTransmittalBy } from 'core/store/actions';
import OutstandingTimeOffModal from '../process-payroll/modals/PendingAccrualRequests.modal';

const fs: FieldInputSettings = {
  filterEmployee: {
    name: 'filterEmployee',
    addEmptyText: 'Remove Filters',
    label: 'Filter',
    groupClass: 'gc03 mw200 m-0',
  },
  sortEmployees: {
    name: 'sortEmployees',
    label: 'Sort',
    groupClass: 'gc03 mw125 m-0',
  },
  earningsCode: {
    name: 'earningsCode',
    groupClass: 'gc03 mw250',
  },
  amount: {
    name: 'amount',
    groupClass: 'number-underline mw75',
  },
  earnings: {
    name: 'earnings',
    groupClass: 'number-underline mw75',
  },
  tracked: {
    name: 'tracked',
    groupClass: 'gc03 mw75',
  },
  rate: {
    name: 'rate',
    groupClass: 'number-underline mw75',
  },
};

const empFilterOpts = [
  {
    id: 'U',
    description: 'Unpaid Employees',
  },
  {
    id: 'R',
    description: 'Unpaid Employees w/ Recurring Earnings',
  },
  {
    id: 'E',
    description: 'Earnings Only Employees',
  },
  {
    id: 'CE',
    description: 'Employees with Errors',
  },
];

const buildEmpSortOpts = (usesDept: boolean, usesSub: boolean, usesSub2: boolean): { description: TransmittalSortOrder }[] => {
  const baseOpts: { description: TransmittalSortOrder }[]  = [
    { description: 'Loc Dept Alfa' },
    { description: 'Alphabetically' },
    { description: 'Employee No' },
  ];
  
  if (usesDept) baseOpts.push({ description: 'Department' });
  if (usesSub) baseOpts.push({ description: 'Sub-Department' });
  if (usesSub2) baseOpts.push({ description: 'Sub-Department 2' });
  
  return baseOpts;
};

type FilterKey = 'U' | 'R' | 'E' | 'CE' | null;

type ShowOpts = {
  transmittalModal: boolean;
  statusModal: boolean;
  addEmployee: boolean;
  controlTotals: boolean;
  payrollInsert: boolean;
  confirmation: boolean;
  timeOff: boolean;
};

type ToggleKey =
  'transmittalModal'
  | 'statusModal' 
  | 'addEmployee' 
  | 'controlTotals' 
  | 'payrollInsert' 
  | 'confirmation'
  | 'timeOff'; 

type Props = {
  currentPayroll: Payroll | undefined;
  currentControlTotal: PayrollControlTotal | undefined;
  canReset: boolean;
  setCanReset: Dispatch<SetStateAction<boolean>>;
  transmittalFilter: FilterKey;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void; // the change handler for the input
  onSearchSubmit: (emps: TransmittalEmployee[], exactMatch: boolean, resetOverride?: boolean) => void; // the search button click handler
  resetSearch: (emps: TransmittalEmployee[]) => void;
  onFilterEmployeeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  show: ShowOpts;
  toggleShow: (key: ToggleKey, newVal: boolean) => void;
  exactMatch: boolean;
  setExactMatch: Dispatch<SetStateAction<boolean>>;
  isReadOnly: boolean;
};

const TransmittalActionBar = ({
  currentPayroll,
  currentControlTotal,
  canReset = false,
  setCanReset,
  transmittalFilter,
  onSearchChange,
  onSearchSubmit,
  resetSearch, // pass this in so that we can reset the ref in here and not do some useImperativeHandle stuff
  onFilterEmployeeChange,
  show,
  toggleShow,
  exactMatch,
  setExactMatch,
  isReadOnly,
}: Props) => {
  const dispatch = useAppDispatch();
  
  const showPtoButton = useSelector(getFeedRequestsIntoTransmittal);
  const { deptOpts, subdeptOpts, subdept2Opts } = useSelector(getLocationDepartmentsWithoutHome);
  const { transmittalEmployees, transmittalSortOrder } = useAppSelector(({ payroll }) => payroll);
    
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  
  useEffectOnMount(() => {
    const focusInput = setTimeout(() => {
      searchInputRef.current?.focus();
    }, 500);
    
    return () => { clearTimeout(focusInput); };
  }, [canReset]);
  
  const onClickApprovedTimeOff = () => {
    if (!currentPayroll) {
      dispatch(handleError('Error approving time off'));
      console.error('Missing currentPayroll');
      return;
    }
    
    toggleShow('timeOff', true);
  };
  
  const onHideApprovedTimeOff = () => {
    if (!currentPayroll) {
      dispatch(handleError('Error closing time off'));
      console.error('Missing currentPayroll');
      return;
    }

    dispatch(postPendingAccrualReport({ payrollHistoryId: currentPayroll.payrollHistoryId }));
    dispatch(postApprovedAccrualReport({ payrollHistoryId: currentPayroll.payrollHistoryId }));

    toggleShow('timeOff', false);
  };
  
  return (
    <div className="d-flex flex-wrap">
      {!isReadOnly ? <>
        <button
          type="button"
          className="btn btn-link dm-grid-action-title py-0"
          disabled = {isReadOnly}
          onClick={() => { toggleShow('addEmployee', true); }}
        >
          Add Employee to Transmittal{' '}
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button>
        <button
          type="button"
          className="btn btn-link"
          disabled = {isReadOnly}
          onClick={() => { toggleShow('statusModal', true); }}
        >
          <strong>Edit Deductions for this P/R</strong>
        </button>
        {showPtoButton === 'Yes' ? <button
          type="button"
          className="btn btn-link dm-grid-action-title py-0"
          disabled = {isReadOnly}
          onClick={onClickApprovedTimeOff}
        >
          Detect Approved PTO{' '}
          <Icon
            name="calendar-xmark"
            className="fa-calendar-xmark"
          />
        </button> : null}
      </> : null}
      <div className="d-flex align-items-center">
        <label className="pr-1 m-0 d-flex align-items-center">
          <img
            src={searchIcon}
            alt="search"
            width={20}
            height="auto"
          />
          <input
            type="text"
            name="mainSearch"
            placeholder="Employee Search"
            ref={searchInputRef}
            size={18}
            onChange={onSearchChange}
            className="app-search-label"
            onKeyDown={(e) => {
              if (e.key !== 'Enter') return;
              onSearchSubmit(transmittalEmployees, exactMatch);
            }}
          />
        </label>
        <FormlessCheckboxGrp
          name="exactMatch"
          label="Exact"
          checked={exactMatch}
          groupClass="col-auto p-0 mr-3 text-center"
          labelClass="mr-2"
          onChange={(e) => {
            setExactMatch(e.target.checked);
            setCanReset(false);
          }}
        />
        <button
          className="orange-button-sm mr-3"
          style={{ maxHeight: '35px' }}
          onClick={() => {
            if (canReset && searchInputRef?.current) {
              searchInputRef.current.value = '';
              searchInputRef.current?.focus();
              resetSearch(transmittalEmployees);
            } else {
              onSearchSubmit(transmittalEmployees, exactMatch);
            }
          }}
        >
          {canReset ? 'Reset' : 'Search'}
        </button>
        <div className="d-flex align-items-center">
          <SelectGrp
            {...fs.filterEmployee}
            labelClass="mr-auto"
            placeholder="Filter Employees"
            title="Filter Employees"
            value={transmittalFilter}
            options={empFilterOpts}
            onChange={onFilterEmployeeChange}
          />
        </div>
        <div className="d-flex align-items-center">
          <SelectGrp
            {...fs.sortEmployees}
            placeholder="Sort Employees"
            labelClass="mr-auto"
            title="Sort Employees"
            value={transmittalSortOrder}
            options={buildEmpSortOpts(!!deptOpts?.length, !!subdeptOpts?.length, !!subdept2Opts?.length)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(sortTransmittalBy(e.target.value as TransmittalSortOrder));
            }}
          />
        </div>
      </div>
      {show.timeOff && (
        <OutstandingTimeOffModal
          id = {currentPayroll?.payrollHistoryId || 0}
          controlTotalId={currentControlTotal?.controlTotalId || 0}
          onUpdateItems={() => {}}
          show={show.timeOff}
          onHide={() => onHideApprovedTimeOff()}
          modalFromPreviewScreen = {false}
        />
      )}
    </div>
  );
};

export default TransmittalActionBar;