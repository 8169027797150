import { AxiosResponse } from "axios";
import { HttpResponse } from '../models';
import { VisaStatus } from '../models/VisaStatus.model';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getVisaStatus = (protectedEmpNo: string): Promise<AxiosResponse<VisaStatus>> => { return API_EMPLOYEE_MASTER.get<VisaStatus>(EmployeeEndPoints.GET_VISA_STATUS(CommonService.getProtectedClientNo(), protectedEmpNo)); };

const putVisaStatus = (vs: VisaStatus): Promise<AxiosResponse<HttpResponse<VisaStatus>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<VisaStatus>>(EmployeeEndPoints.PUT_VISA_STATUS(CommonService.getProtectedClientNo(), vs.protectedEmpNo), vs); };

const deleteVisaStatus = (empNo: number | string): Promise<AxiosResponse<any>> => { return API_EMPLOYEE_MASTER.get<any>(EmployeeEndPoints.DELETE_VISA_STATUS(CommonService.getProtectedClientNo(), empNo)); };

export const VisaStatusService = {
  getVisaStatus,
  putVisaStatus,
  deleteVisaStatus,
};