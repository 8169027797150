import React from 'react';
import { InputGrp } from '../../../core/components/form-controls';
import { FieldInputSettings } from '../../../core/components/form-controls/types';

const fs: FieldInputSettings = {
  firstName: {
    name: 'employeeInformationOverride.firstName',
    label: 'FIRST NAME',
    groupClass: 'gc10 mw100',
  },
  middleName: {
    name: 'employeeInformationOverride.middleName',
    label: 'MIDDLE INIT',
    groupClass: 'gc08 mw75',
  },
  lastName: {
    name: 'employeeInformationOverride.lastName',
    label: 'LAST NAME',
    groupClass: 'gc10 mw100',
  },
};

type PropTypes = {
  formMethods: any;
};

const ACAEmployeeInformationOverride: React.FC<PropTypes> = ({
  formMethods: { errors, register },
}) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="col-5">
      <div className="d-flex flex-row">
        <span className="dm-card-subtitle2">
          Employee Information Override (As of December 31st{' '}
          {currentYear})
        </span>
      </div>
      <div className="d-flex flex-row">
        Enter information if there needs to be a correction,
        <br />
        leave boxes blank to use current information.
      </div>
      <div className="d-flex flex-row">
        <InputGrp
          {...fs.firstName}
          errors={errors?.employeeInformationOverride?.firstName}
          ref={register()}
        />
        <InputGrp
          {...fs.middleName}
          errors={errors?.employeeInformationOverride?.middleName}
          ref={register()}
        />
        <InputGrp
          {...fs.lastName}
          errors={errors?.employeeInformationOverride?.lastName}
          ref={register()}
        />
      </div>
    </div>
  );
};
export default ACAEmployeeInformationOverride;
