import { createAction } from '@reduxjs/toolkit';
import { VisaStatus } from 'core/models';

export const VISASTATUS_EM_VALIDATION_ACTIONS = {
  UPDATE_VISA_STATUS: '[visa-status] UPDATE_VISA_STATUS',
  DELETE_VISA_STATUS: '[visa-status] DELETE_VISA_STATUS', // pretty sure there isn't a way to delete the visa status, but I guess we'll keep this action right now
} as const;

export const loadVisaStatus = createAction<string>('[visa-status] LOAD_VISA_STATUS');
export const storeVisaStatus = createAction<VisaStatus>('[visa-status] STORE_VISA_STATUS');
export const updateVisaStatus = createAction<VisaStatus>(VISASTATUS_EM_VALIDATION_ACTIONS.UPDATE_VISA_STATUS);
export const deleteVisaStatus = createAction<number>(VISASTATUS_EM_VALIDATION_ACTIONS.DELETE_VISA_STATUS);
