export interface PayrollControlTotalRequest {
  controlTotalId: number;
  payrollHistoryId: number;
  type: PayrollControlTotalDetailType;
}

export interface PayrollControlTotalDetail {
  code: string;
  amount: number;
  type: PayrollControlTotalDetailType;
  employees: PayrollControlTotalEmployeeDetail[];
}

export interface PayrollControlTotalEmployeeDetail {
  empNo: number;
  name: string;
  amount: number;
}

export enum PayrollControlTotalDetailType {
  All = 'All',
  OtherHours = 'OtherHours',
  OtherEarnings = 'OtherEarnings',
  SpecialDeductions = 'SpecialDeductions',
}
