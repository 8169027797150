
import { AxiosResponse } from 'axios';
import { HttpResponse, RateRule } from '../models';
import { API_CLIENT } from './API';
import { CommonService } from './common.service';
import { ClientMasterEndPoints } from './URL';

const getRateRules = (): Promise<AxiosResponse<RateRule[]>> => {return API_CLIENT.get<RateRule[]>(ClientMasterEndPoints.GET_RATE_RULES(CommonService.getProtectedClientNo()));};

const getRateRule = (rateId: number): Promise<AxiosResponse<RateRule>> => {return API_CLIENT.get<RateRule>(ClientMasterEndPoints.GET_RATE_RULE(CommonService.getProtectedClientNo(), rateId));};

const postRateRule = (data: RateRule): Promise<AxiosResponse<HttpResponse<RateRule>>> => {return API_CLIENT.post<HttpResponse<RateRule>>(ClientMasterEndPoints.POST_RATE_RULE(CommonService.getProtectedClientNo()), data);};

const putRateRule = (data: RateRule): Promise<AxiosResponse<HttpResponse<RateRule>>> => {return API_CLIENT.put<HttpResponse<RateRule>>(ClientMasterEndPoints.PUT_RATE_RULE(CommonService.getProtectedClientNo(), data.rateId), data);};

const deleteRateRule = (data: RateRule): Promise<AxiosResponse<any>> => {return API_CLIENT.delete<any>(ClientMasterEndPoints.DELETE_RATE_RULE(CommonService.getProtectedClientNo(), data.rateId));};

export const RateRuleService = {
  getRateRules,
  getRateRule,
  postRateRule,
  putRateRule,
  deleteRateRule,
};
