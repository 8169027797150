import React, { useRef } from 'react';
import { SelectGrpUncontrolled } from 'core/components/form-controls/SelectGrpUncontrolled';
import Popover from 'core/components/shared/Popover';
import Icon from 'core/components/shared/Icon';
import { DatePickerGrp } from 'core/components/form-controls/DatePickerGrp';
import { TableModel as Tm } from './types';
import styles from './tableStyles.module.scss';

type FilterProps<T> = {
  column: keyof T;
  filterData: Tm.FilterData | null,
  filterOptions: { description: Tm.FilterType }[];
  leftBuffer?: number;
  // trying to decide if these should just be handled here and optionally passed in for any additional things we wanna do :thinking_face:
  onSearch: (query: string, column: keyof T) => void;
  onFilterTypeChange: (filter: Tm.FilterType, column: keyof T) => void;
  onUpdateDateRange: (newDate: Date | null, property: keyof Pick<Tm.FilterData, 'dateFrom' | 'dateTo'>, column: keyof T) => void;
  onUpdateCompareDate: (newDate: Date | null, column: keyof T) => void;
};

function BaseColumnFilter<T>({
  column,
  filterData,
  filterOptions,
  leftBuffer = 0,
  onSearch,
  onFilterTypeChange,
  onUpdateDateRange,
  onUpdateCompareDate,
}: FilterProps<T>) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  
  const onShow = () => {
    inputRef?.current?.focus();
  };
  
  const filterTypeOrDefault: Tm.FilterType = filterData?.filterType || 'Contains';
  
  return (
    <Popover
      name={String(column)}
      onShow={onShow}
      leftBuffer={leftBuffer}
    >
      <Popover.ToggleButton>
        <button className="naked-button ml-auto align-self-center">
          <Icon
            name="bars"
            fontSize="0.8rem"
          />
        </button>
      </Popover.ToggleButton>
      <Popover.ContentWrapper>
        <div className="d-flex flex-column">
          <SelectGrpUncontrolled
            name={`select-${String(column)}`}
            options={filterOptions}
            valueField="description"
            value={filterTypeOrDefault}
            onChange={(e) => {
              onFilterTypeChange(e.target.value as Tm.FilterType, column);
            }}
          />
          {/* Determine filter inputs based on type, wrapped in an IIFE so we can use a switch in here */}
          {(() => {
            switch (filterTypeOrDefault) {
              case 'After':
              case 'Before':
                return (
                  <DatePickerGrp
                    name="floorDate"
                    value={filterData?.compareDate}
                    onChange={(newDate) => {
                      onUpdateCompareDate(newDate, column);
                    }}
                  />
                );
              case 'In range':
                return (
                  <div className="d-flex flex-column mt-1">
                    <DatePickerGrp
                      name="fromDate"
                      value={filterData?.dateFrom}
                      onChange={(newDate) => {
                        onUpdateDateRange(newDate, 'dateFrom', column);
                      }}
                    />
                    <DatePickerGrp
                      name="toDate"
                      value={filterData?.dateTo}
                      onChange={(newDate) => {
                        onUpdateDateRange(newDate, 'dateTo', column);
                      }}
                    />
                  </div>
                );
              default: 
                return (
                  <input
                    name={`search-${String(column)}-1`}
                    className={styles['filter-input']}
                    ref={inputRef}
                    type="text"
                    value={filterData?.query || ''}
                    disabled={['Blank', 'Not blank'].includes(filterTypeOrDefault)}
                    aria-disabled={['Blank', 'Not blank'].includes(filterTypeOrDefault)}
                    onChange={(e) => { onSearch(e.target.value.toLowerCase().trim(), column); }}
                    placeholder={'Filter...'}
                  />
                );
            }
          })()}
        </div>
      </Popover.ContentWrapper>
    </Popover >
  );
}

export default BaseColumnFilter;