import React from 'react';
import MenuItem from 'core/components/form-controls/nested-accordion/MenuItem';
import MenuToggle from 'core/components/form-controls/nested-accordion/MenuToggle';
import NestedAccordion from 'core/components/form-controls/nested-accordion/NestedAccordion';
import { useParams } from 'react-router-dom';

type SidebarSubItem = {
  id: string;
  title: string;
  href: string;
  workItemId?: number;
};

type SidebarItem = {
  id: string;
  title: string;
  children: SidebarSubItem[];
};

const toggleComponents: any[] = [MenuToggle, MenuItem];


const EmpMasterMapsSidebar = () => {
  const { downloaderType, userMapId } = useParams<any>();
  const data: SidebarItem[] = [
    {
      id: '1',
      title: 'EMPLOYEE INFORMATION',
      children: [
        {
          id: '1a',
          title: 'Employee Information',
          href: `/user-map/${downloaderType}/${userMapId}/snapshot`,
          workItemId: 1,
        },
        {
          id: '1b',
          title: 'Emergency Contact',
          href: `/user-map/${downloaderType}/${userMapId}/emergency-contact`,
        },
        {
          id: '1d',
          title: 'Employee Deductions',
          href: `/user-map/${downloaderType}/${userMapId}/deductions`,
          workItemId: 5,
        },
      ],
    },
    {
      id: '3',
      title: 'TAX INFO',
      children: [
        {
          id: '3a',
          title: 'Taxes',
          href: `/user-map/${downloaderType}/${userMapId}/taxes`,
          workItemId: 4,
        },
      ],
    },
    {
      id: '4',
      title: 'ADDITIONAL INFORMATION',
      children: [
        {
          id: '4q',
          title: 'ACA',
          href: `/user-map/${downloaderType}/${userMapId}/aca`,
          workItemId: 40,
        },
        {
          id: '4g',
          title: 'Accruals',
          href: `/user-map/${downloaderType}/${userMapId}/accruals`,
          workItemId: 24,
        },
        {
          id: '4r',
          title: 'Company Benefits',
          href: `/user-map/${downloaderType}/${userMapId}/company-benefits`,
          workItemId: 16,
        },
        {
          id: '4e',
          title: 'Dates',
          href: `/user-map/${downloaderType}/${userMapId}/dates`,
          workItemId: 7,
        },
        {
          id: '4a',
          title: 'Deferred Comp',
          href: `/user-map/${downloaderType}/${userMapId}/deferred-comp`,
          workItemId: 30,
        },        
        {
          id: '4k',
          title: 'Group Term Life',
          href: `/user-map/${downloaderType}/${userMapId}/group-term-life`,
          workItemId: 32,
        },
        {
          id: '4o',
          title: 'HR Doctor Comp',
          href: `/user-map/${downloaderType}/${userMapId}/hr-doc-comp`,
          workItemId: 27,
        },
        {
          id: '4p',
          title: 'HR FTE',
          href: `/user-map/${downloaderType}/${userMapId}/hr-fte`,
          workItemId: 26,
        },
        {
          id: '4b',
          title: 'Payroll Information',
          href: `/user-map/${downloaderType}/${userMapId}/payroll-info`,
          workItemId: 6,
        },       
        {
          id: '4f',
          title: 'Recurring Earnings',
          href: `/user-map/${downloaderType}/${userMapId}/recurring-earnings`,
          workItemId: 22,
        },
        {
          id: '4n',
          title: 'Self Service',
          href: `/user-map/${downloaderType}/${userMapId}/self-serve`,
          workItemId: 34,
        },
        {
          id: '4d',
          title: 'Special Info',
          href: `/user-map/${downloaderType}/${userMapId}/special-info`,
        },
        {
          id: '4c',
          title: 'Visa Status',
          href: `/user-map/${downloaderType}/${userMapId}/visa-status`,
          workItemId: 43,
        },
        {
          id: '4h',
          title: 'YTD',
          href: `/user-map/${downloaderType}/${userMapId}/ytd`,
          workItemId: 8,
        },
      ],
    },
  ];

  return (
    <>
      {data.map((item) => {
        return (
          <NestedAccordion
            key={item.id}
            level={1}
            item={item}
            toggleComponents={toggleComponents}
            contentStyle={{ margin: '0' }}
            toggleContainerStyle={{ marginTop: '3px' }}
            bodyContainerStyle={{ backgroundColor: 'white' }}
          />
        );
      })}
    </>
  );
};

export default EmpMasterMapsSidebar;
