import { createReducer } from '@reduxjs/toolkit';
import { AodPayrollUploadMap, PayrollUpload, PayrollUploadErrors, PayrollUploadMap, PayrollUploadVM, TimeCard } from '../../models/PayrollUpload.model';
import {
  createDefaultPayrollUploadFile,
  loadPayrollUpload, payrollUploadFileTool, resetPayrollUploadVM, storeAodPayrollUploadMaps, storeOnTheClockData, storePayrollUpload, storePayrollUploadField,
  storePayrollUploadFileToolCheckErrors,
  storePayrollUploadFileToolErrors,
  storePayrollUploadVM, storeSelectedPayrollUploadFile,
} from '../actions';

export interface State {
  uploadingData: boolean;
  creatingDefaultMap: boolean;
  loading: boolean;
  payrollUploadVM: PayrollUploadVM | null;
  payrollUpload: PayrollUpload | null;
  selectedClientFile: PayrollUpload | null;
  payrollUploadField: PayrollUploadMap | null;
  payrollUploadFileToolErrors: PayrollUploadErrors[] | null;
  onTheClockData: TimeCard[] | null;
  aodUploadMapOptions: AodPayrollUploadMap | null;
  fileAlreadyUploaded: {alreadyUploded: boolean, message: string};
}

export const INITIAL_STATE: State = {
  loading: false,
  creatingDefaultMap: false,
  uploadingData: false,
  payrollUploadVM: null,
  payrollUpload: null,
  selectedClientFile: null,
  payrollUploadField: null,
  payrollUploadFileToolErrors: null,
  onTheClockData: null,
  aodUploadMapOptions: null,
  fileAlreadyUploaded: {alreadyUploded: false, message: ''},
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadPayrollUpload, (state) => {
      state.loading = true;
      state.uploadingData = false;
      state.creatingDefaultMap = false;
    })
    .addCase(createDefaultPayrollUploadFile, (state) => {
      state.loading = true;
      state.creatingDefaultMap = true;
    })
    .addCase(resetPayrollUploadVM, (state) => {
      //I tried doing state = INITIAL_STATE but that did not work so I am setting them manually which works.
      state.payrollUploadVM = null;
      state.onTheClockData = null;
      state.payrollUploadFileToolErrors = null;
      state.selectedClientFile = null;
    }).addCase(storePayrollUploadVM, (state, action) => {
      state.payrollUploadVM = action.payload;
      state.loading = false;
    }).addCase(payrollUploadFileTool, (state) => {
      state.uploadingData = true;
    }).addCase(storePayrollUpload, (state, action) => {
      state.payrollUpload = action.payload;
      state.loading = false;
    }).addCase(storeSelectedPayrollUploadFile, (state, action) => {
      state.selectedClientFile = action.payload;
      state.loading = false;
    }).addCase(storePayrollUploadField, (state, action) => {
      state.payrollUploadField = action.payload;
      state.loading = false;
    }).addCase(storeOnTheClockData, (state, action) => {
      state.onTheClockData = action.payload;
      state.loading = false;})
    .addCase(storeAodPayrollUploadMaps, (state, action) => {
      state.aodUploadMapOptions = action.payload;
      state.loading = false;
    }).addCase(storePayrollUploadFileToolErrors, (state, action) => {
      state.payrollUploadFileToolErrors = action.payload;
      state.loading = false;
      state.uploadingData = false;
    })
    .addCase(storePayrollUploadFileToolCheckErrors, (state, action) => {
      state.fileAlreadyUploaded.alreadyUploded = action.payload.alreadyUploaded;
      state.fileAlreadyUploaded.message = action.payload.message;
    });
});