import Icon from 'core/components/shared/Icon';
import { EmploymentHistory } from 'core/models';
import { TO_NAME, convertRegion } from 'core/states';
import React, { useState } from 'react';
import { Accordion, Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { toReadableDate } from 'utilities/utilities';

type Props = {
  histories: EmploymentHistory[]
};

const EmploymentHistoryComponent = ({
  histories,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header className="p-3">
          <Accordion.Toggle
            eventKey="2"
            style={{
              background: 'transparent',
              border: 'none',
            }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <Icon
              name={isOpen ? 'chevron-up' : 'chevron-down'}
              className="mr-1"
            />
            Employment History
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body
            className="p-3"
            style={{ gap: '4rem' }}
          >
            <Tabs defaultActiveKey={histories?.[0]?.nameOfEmployer}>
              {histories.map(history => {
                return (
                  <Tab
                    key={history?.employmentHistoryId}
                    eventKey={history?.nameOfEmployer}
                    title={history?.nameOfEmployer}
                    className="py-4"
                  >
                    <Row>
                      {history.nameOfEmployer && (
                        <Col sm={2}>
                          <p className="font-weight-bold mb-0">Name of Employer</p>
                          <p className="mb-0">{history.nameOfEmployer}</p>
                        </Col>
                      )}
                      {history.typeOfBusiness && (
                        <Col sm={2}>
                          <p className="font-weight-bold mb-0">Type of Business</p>
                          <p className="mb-0">{history.typeOfBusiness}</p>
                        </Col>
                      )}
                      {history.positionTitle && (
                        <Col sm={2}>
                          <p className="font-weight-bold mb-0">Position</p>
                          <p className="mb-0">{history.positionTitle}</p>
                        </Col>
                      )}
                      {history.nameOfSupervisor && (
                        <Col sm={2}>
                          <p className="font-weight-bold mb-0">Name of Supervisor</p>
                          <p className="mb-0">{history.nameOfSupervisor}</p>
                        </Col>
                      )}
                      <Col sm={2}>
                        <p className="font-weight-bold mb-0">Permission to Contact</p>
                        <p className="mb-0">{history.mayContactEmployer === true ? 'Yes' : 'No'}</p>
                      </Col>
                      <Col sm={2}>
                        <p className="font-weight-bold mb-0">Phone</p>
                        <p className="mb-0">{history.telephoneNumber}</p>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={2}>
                        <p className="font-weight-bold mb-0">Address</p>
                        {history.streetAddress1 && (
                          <p className="mb-0">{history.streetAddress1}</p>
                        )}
                        {history.streetAddress2 && (
                          <p className="mb-0">{history.streetAddress2}</p>
                        )}
                        {(history.city || history.state) && (
                          <p className="mb-0">{history.city} {history.state && `, ${convertRegion(history.state, TO_NAME)}`}</p>
                        )}
                        {history.zip && (
                          <p className="mb-0">{history.zip}</p>
                        )}
                      </Col>
                      <Col sm={2}>
                        <p className="font-weight-bold mb-0">Wage</p>
                        <p className="mb-0">Starting Wage Type: {history.startingWageType}</p>
                        <p className="mb-0">Starting Wage: {history.startingWage.toFixed(2)}</p>
                        <p className="mb-0">Ending Wage Type: {history.endingWageType}</p>
                        <p className="mb-0">Ending Wage: {history.endingWage.toFixed(2)}</p>
                      </Col>
                      <Col sm={2}>
                        <p className="font-weight-bold mb-0">Employment</p>
                        <p className="mb-0">Start Date: {toReadableDate(history.firstDateOfEmployment)}</p>
                        <p className="mb-0">Last Date: {toReadableDate(history.lastDateOfEmployment)}</p>
                        <p className="mb-0">Still Employed: {history.lastDateOfEmployment ? 'False' : 'True'}</p>
                      </Col>
                      <Col sm={3}>
                        <p className="font-weight-bold mb-0">Reasons for Leaving</p>
                        <p className="mb-0">{history.reasonForLeaving}</p>
                      </Col>
                      <Col sm={3}>
                        <p className="font-weight-bold mb-0">Responsibilities</p>
                        <p className="mb-0">{history.responsibilities}</p>
                      </Col>
                    </Row>
                  </Tab>
                );
              })}
            </Tabs>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default EmploymentHistoryComponent;