import React from 'react';
import Modal from 'core/components/modals/Modal';
import { Note } from 'core/models';

type Props = {
  show: boolean;
  onHide: () => void;
  onDelete: () => void;
};

const DeleteNoteModal = ({
  show,
  onHide,
  onDelete,
}: Props) => {
  return (
    <Modal
      title="Delete Note"
      show={show}
      onHide={onHide}
    >
      {(closeModal) => {
        return (
          <>
            <p>Do you want to delete the current note?</p>
            <div
              className="d-flex align-items-center justify-content-end"
              style={{ gap: '0.5rem' }}
            >
              <button
                type="button"
                className="btn btn-primary orange-outline-button"
                onClick={closeModal}
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary orange-button"
                onClick={onDelete}
              >
                Yes
              </button>
            </div>
          </>
        );
      }}
    </Modal>
  );
};

export default DeleteNoteModal;