import Modal from 'core/components/modals/Modal';
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';


type PropTypes = {
  show: boolean,
  onHide: any
};

const ACAInfoModal: React.FC<PropTypes> = ({ show, onHide }) => {
  const [, setKey] = useState('aca-coverage-codes');

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      title="ACA Code Info"
    >
      <Tabs
        defaultActiveKey="aca-coverage-codes"
        id="aca-codes"
        onSelect={(k: any) => { return setKey(k); }} >
        <Tab eventKey="aca-coverage-codes"
          title="Line 14: Offer of Coverage Codes">
          <div className="m-2">
            <p><span className="font-weight-bold">1A:</span> Qualifying Offer: Minimum essential coverage providing minimum value offered to full-time employee with Employee Required Contribution equal to or less than 9.5% (as adjusted) of mainland single federal poverty line and at least minimum essential coverage offered to spouse and dependent(s).</p>
            <p><span className="font-weight-bold">1B:</span> Minimum essential coverage providing minimum value offered to employee only.</p>
            <p><span className="font-weight-bold">1C:</span> Minimum essential coverage providing minimum value offered to employee and at least minimum essential coverage offered to dependent(s) (not spouse).</p>
            <p><span className="font-weight-bold">1D:</span> Minimum essential coverage providing minimum value offered to employee and at least minimum essential coverage offered to spouse (not dependent(s)). Do not use code 1D if the coverage for the spouse was offered conditionally. Instead use code 1J.</p>
            <p><span className="font-weight-bold">1E:</span> Minimum essential coverage providing minimum value offered to employee and at least minimum essential coverage offered to dependent(s) and spouse. Do not use code 1E if the coverage for the spouse was offered conditionally. Instead use code 1K.</p>
            <p><span className="font-weight-bold">1F:</span> Minimum essential coverage NOT providing minimum value offered to employee; employee and spouse or dependent(s); or employee, spouse and dependents.</p>
            <p><span className="font-weight-bold">1G:</span> Offer of coverage to an individual who was not an employee for any month of the calendar year or to an employee who was not a full-time employee for any month of the calendar year (which may include one or more months in which the individual was not an employee) and who enrolled in self-insured coverage for one or more months of the calendar year.</p>
            <p><span className="font-weight-bold">1H:</span> No offer of coverage (employee not offered any health coverage or employee offered coverage that is not minimum essential coverage, which may include one or more months in which the individual was not an employee).</p>
            <p><span className="font-weight-bold">1I:</span> Reserved.</p>
            <p><span className="font-weight-bold">1J:</span> Minimum essential coverage providing minimum value offered to employee and at least minimum essential coverage conditionally offered to spouse; minimum essential coverage NOT offered to dependent(s).</p>
            <p><span className="font-weight-bold">1K:</span> Minimum essential coverage providing minimum value offered to employee; at least minimum essential coverage offered to dependents; and at least minimum essential coverage conditionally offered to spouse.</p>
            <p><span className="font-weight-bold">1L:</span> Individual coverage health reimbursement arrangement (HRA) offered to you only with affordability determined by using employee’s primary residence location ZIP Code.</p>
            <p><span className="font-weight-bold">1M:</span> Individual coverage HRA offered to you and dependent(s) (not spouse) with affordability determined by using employee’s primary residence location ZIP Code.</p>
            <p><span className="font-weight-bold">1N:</span> Individual coverage HRA offered to you, spouse and dependent(s) with affordability determined by using employee’s primary residence location ZIP Code.</p>
            <p><span className="font-weight-bold">1O:</span> Individual coverage HRA offered to you only using the employee’s primary employment site ZIP Code affordability safe harbor.</p>
            <p><span className="font-weight-bold">1P:</span> Individual coverage HRA offered to you and dependent(s) (not spouse) using the employee’s primary employment site ZIP Code affordability safe harbor.</p>
            <p><span className="font-weight-bold">1Q:</span> Individual coverage HRA offered to you, spouse and dependent(s) using the employee’s primary employment site ZIP Code affordability safe harbor.</p>
            <p><span className="font-weight-bold">1R:</span> Individual coverage HRA that is NOT affordable offered to you; employee and spouse or dependent(s); or employee, spouse, and dependents.</p>
            <p><span className="font-weight-bold">1S:</span> Individual coverage HRA offered to an individual who was not a full-time employee.</p>
          </div>
        </Tab>
        <Tab eventKey="aca-safe-harbor-codes"
          title="Line 16: Safe Harbor Codes">
          <div className="m-2">
            <p><span className="font-weight-bold">{'<blank>:'}</span> If none of the codes apply for a calendar month, leave the line blank for that month.</p>
            <p><span className="font-weight-bold">2A:</span> Employee not employed during the month. Enter code 2A if the employee was not employed on any day of the calendar month. Do not use code 2A for a month if the individual was an employee of the ALE Member on any day of the calendar month. Do not use code 2A for the month during which an employee terminates employment with the ALE Member.</p>
            <p><span className="font-weight-bold">2B:</span> Employee not a full-time employee. Enter code 2B if the employee is not a full-time employee for the month and did not enroll in minimum essential coverage, if offered for the month. Enter code 2B also if the employee is a full-time employee for the month and whose offer of coverage (or coverage if the employee was enrolled) ended before the last day of the month solely because the employee terminated employment during the month (so that the offer of coverage or coverage would have continued if the employee had not terminated employment during the month).</p>
            <p><span className="font-weight-bold">2C:</span> Employee enrolled in coverage offered. Enter code 2C for any month in which the employee enrolled for each day of the month in health coverage offered by the ALE Member, regardless of whether any other code in Code Series 2 might also apply (for example, the code for a section 4980H affordability safe harbor) except as provided below. Do not enter code 2C in line 16 for any month in which the multiemployer interim rule relief applies (enter code 2E). Do not enter code 2C in line 16 if code 1G is entered in the “All 12 Months” box in line 14 because the employee was not a full-time employee for any month of the calendar year. Do not enter code 2C in line 16 for any month in which a terminated employee is enrolled in COBRA continuation coverage or other post-employment coverage (enter code 2A).</p>
            <p><span className="font-weight-bold">2D:</span> Employee in a section 4980H(b) Limited Non-Assessment Period. Enter code 2D for any month during which an employee is in a section 4980H(b) Limited Non-Assessment Period. If an employee is in an initial measurement period, enter code 2D (employee in a section 4980H(b) Limited Non-Assessment Period) for the month, and not code 2B (employee not a full-time employee). For an employee in a section 4980H(b) Limited Non-Assessment Period for whom the ALE Member is also eligible for the multiemployer interim rule relief for the month, enter code 2E (multiemployer interim rule relief) and not code 2D (employee in a section 4980H(b) Limited Non-Assessment Period).</p>
            <p><span className="font-weight-bold">2E:</span> Multiemployer interim rule relief. Enter code 2E for any month for which the multiemployer arrangement interim guidance applies for that employee, regardless of whether any other code in Code Series 2 (including code 2C) might also apply.</p>
            <p><span className="font-weight-bold">2F:</span> Section 4980H affordability Form W-2 safe harbor. Enter code 2F if the ALE Member used the section 4980H Form W-2 safe harbor to determine affordability for purposes of section 4980H(b) for this employee for the year. If an ALE Member uses this safe harbor for an employee, it must be used for all months of the calendar year for which the employee is offered health coverage.</p>
            <p><span className="font-weight-bold">2G:</span> Section 4980H affordability federal poverty line safe harbor. Enter code 2G if the ALE Member used the section 4980H federal poverty line safe harbor to determine affordability for purposes of section 4980H(b) for this employee for any month(s).</p>
            <p><span className="font-weight-bold">2H:</span> Section 4980H affordability rate of pay safe harbor. Enter code 2H if the ALE Member used the section 4980H rate of pay safe harbor to determine affordability for purposes of section 4980H(b) for this employee for any month(s).</p>
            <p><span className="font-weight-bold">2I:</span> Reserved</p>
          </div>
        </Tab>
      </Tabs>
    </Modal>
  );
};

export default ACAInfoModal;