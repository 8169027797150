import React, { useState } from 'react';
import Icon from './Icon';

const StarRating = (rating:number) => {
  return (
    <div>
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1;

        return (
          <span key={i}>
            <Icon              
              name="star"
              color={ratingValue <= rating ? '#D6AF45' : '#e4e5e9'}
            />
          </span>
        );
      })}

    </div>
  );
};

export default StarRating;