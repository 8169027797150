import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { InputGrp, SelectGrp } from '../../../core/components/form-controls';
import { FieldInputSettings } from '../../../core/components/form-controls/types';
import PanelHeader from '../../../core/components/shared/PanelHeader';
import { getForeignCountries, getStates } from '../../../core/store/selectors';

const fs: FieldInputSettings = {
  address: {
    name: 'address',
    label: 'STREET ADDRESS',
    groupClass: 'groupClass30',
    required: true,
  },
  city: { name: 'city', label: 'CITY', groupClass: 'groupClass20' },
  state: {
    name: 'state',
    label: 'STATE/PROVINCE',
    groupClass: 'groupClass15',
    required: true,
  },
  zip: { name: 'zip', label: 'ZIP/POSTAL', groupClass: 'groupClass10' },
  country: { name: 'country', label: 'COUNTRY', groupClass: 'groupClass20' },
};

const ForeignAddressPage: React.FC<any> = () => {
  const { errors, register, handleSubmit } = useForm();

  const stateOpts = useSelector(getStates);
  const foreignCountryOpts = useSelector(getForeignCountries);

  const onSave = (data: any) => {};

  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Foreign Address" />
      <form onSubmit={handleSubmit(onSave)}>
        <div className="d-flex flex-wrap mt-3">
          <InputGrp
            {...fs.address}
            errors={errors.address}
            ref={register}
          />
          <InputGrp
            {...fs.city}
            errors={errors.city}
            ref={register}
          />
          <SelectGrp
            {...fs.state}
            errors={errors.state}
            ref={register}
            options={stateOpts}
          />
          <InputGrp {...fs.zip} errors={errors.zip} ref={register} />
          <SelectGrp
            {...fs.country}
            errors={errors.country}
            ref={register}
            options={foreignCountryOpts}
          />
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <button
              type="submit"
              className="btn orange-button-sm mr-3"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForeignAddressPage;
