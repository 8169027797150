import { DetailHour, TimeCardDeduction, AtsInfo, HomeAtsInfo, TimeCard, TimeCardDetail, TimeCardTotals, Employee, TimeCardTotalsDetails, TimeCardMainTotals, DetailHourMap } from 'core/models';
import { cleanAmount, generateNegativeId } from 'utilities/utilities';
import { handleError, handleWarning, storeTimeCard } from 'core/store/actions';
import { TimeSheetSummaryFilter } from './time-sheet-summary/TimeSheetSummary';

export const createNewTimeSheet = (transmittalTimeCardId: number, homeAtsInfo: HomeAtsInfo | null, empRate: number): TimeCardDetail => ({
  transmittalTimeCardId: transmittalTimeCardId,
  transmittalTimeCardDetailId: generateNegativeId(),
  jobNumber: '',
  subNumber: '',
  phase: '',
  costCode: '',
  foExtra: '',
  area: homeAtsInfo?.area ?? '',
  trade: homeAtsInfo?.trade ?? '',
  sub: homeAtsInfo?.sub ?? '',
  additionalRate: 0,
  empRate: empRate,
  tradeRate: homeAtsInfo?.baseRate ?? 0,
  stateEntityId: 0,
  cityEntityId: 0,
  classCode: '',
  hours: [],
  otherEarnings: [],
});

/**
 * Creates a map-like object of detail hour records, where the key is the detail ID
 * and the value is the array of hours associated with that ID.
 * @param details time card detail array to transform
 * @returns DetailHourMap object ([key: string]: DetailHour[])
 */
export const createDetailHourMap = (details: TimeCardDetail[]): DetailHourMap => {
  const result: DetailHourMap = {};
  
  if (!details?.length) return result;
  
  details?.forEach((detail) => {
    if (!detail) return;
    result[String(detail.transmittalTimeCardDetailId)] = detail?.hours ?? [];
  });
  
  return result;
};

export const transformDeductions = (deductions: TimeCardDeduction[]): TimeCardDeduction[] => {
  return deductions?.map((deduction) => ({
    ...deduction,
    transmittalTimeCardDeductionId: +(deduction?.transmittalTimeCardDeductionId ?? 0),
    amount: parseFloat(cleanAmount(deduction?.amount ?? '0')),
  }));
};

export const transformDetailHours = (hourMap: DetailHourMap, id: number): DetailHour[] => {
  const matchedHoursFromMap: DetailHour[] = hourMap?.[String(id)] ?? [];
  if (!matchedHoursFromMap?.length) return [];
  
  return structuredClone(matchedHoursFromMap)?.filter((hour) =>
    !!hour && (hour.hours > 0 || hour.notes?.length) && (hour.dateId > 0))
    ?? [];
};

export const transformDetails = (details: TimeCardDetail[], hourMap: DetailHourMap): TimeCardDetail[] => {
  return details?.map((detail) => ({
    ...detail,
    additionalRate: parseFloat(cleanAmount(detail?.additionalRate ?? '0')),
    cityEntityId: +detail?.cityEntityId,
    hours: transformDetailHours(hourMap, detail.transmittalTimeCardDetailId),
    otherEarnings: detail?.otherEarnings?.map((earning) => ({
      ...earning,
      amount: parseFloat(cleanAmount(earning?.amount ?? '0')),
      recurringEarningId: String(earning?.recurringEarningId) === '' || !earning?.recurringEarningId ? null : +(earning.recurringEarningId),
    })),
  }));
};

export const getCurrentCheckCodes = (timeCards: TimeCard[], empNo: number | undefined): string[] => {
  return timeCards
    ?.filter((timeCard) => timeCard.empNo === (empNo ?? -1))
    ?.map(({ checkCode }) => checkCode?.trim()) ?? [];
};

export const getNextCheckCode = (sequence: number[]): string => {
  const sortedSequence = structuredClone(sequence).sort((a, b) => a - b);

  if (!sequence?.length) return ' ';
  if (sequence.length >= 10) return '-1';  

  for (let i = 0; i < sortedSequence.length - 1; i++) {
    const current = sortedSequence[i];
    const next = sortedSequence[i + 1];

    if (next - current > 1) {
      return String(current + 1);
    }
  }

  if (sortedSequence[sortedSequence.length - 1] + 1 > 10) return '-1';
  return String(sortedSequence[sortedSequence.length - 1] + 1);
};
//Will get the unique fo extra values off a list of timecards
export const getTimeCardsTotals = (timeCards: TimeCard[], summaryFilter: TimeSheetSummaryFilter) => {      
  let regularTotal = 0;
  let timeAndAHalfTotal = 0;
  let doubleTotal = 0;
  let otherEarningsHoursTotal = 0;
   
  timeCards?.map(timeCard => {
    timeCard?.details?.map(detail => {
      //Sum up the hours ensuring they are the hours for the current date
      if (!validDetail(detail, summaryFilter)) return;
      detail?.hours?.map(hour => {
        // -1 used for "All" option
        if (hour.dateId !== summaryFilter.dayOfWeek && summaryFilter.dayOfWeek !== -1) return;
        else if (hour.earningsCode === '1') regularTotal = regularTotal + +hour.hours;
        else if (hour.earningsCode === '2') timeAndAHalfTotal = timeAndAHalfTotal + +hour.hours;
        else if (hour.earningsCode === '3') doubleTotal = doubleTotal + +hour.hours;
        else otherEarningsHoursTotal = otherEarningsHoursTotal + +hour.hours;
      });
    });
  });
  return { regularTotal, timeAndAHalfTotal, doubleTotal, otherEarningsHoursTotal } as TimeCardTotals;
};

//Will get the unique fo extra values off a single timecard
export const getTimeCardDetailTotals = (timeCardDetails: TimeCardDetail[], summaryFilter: TimeSheetSummaryFilter) => {      
  let regularTotal = 0;
  let timeAndAHalfTotal = 0;
  let doubleTotal = 0;
  let otherEarningsHoursTotal = 0;
   
  timeCardDetails?.map(detail => {
    //Sum up the hours ensuring they are the hours for the current date
    if (!validDetail(detail, summaryFilter)) return;
    detail?.hours?.map(hour => {
      // -1 used for "All" option
      if (hour.dateId !== summaryFilter.dayOfWeek && summaryFilter.dayOfWeek !== -1) return;
      else if (hour.earningsCode === '1') regularTotal = regularTotal + +hour.hours;
      else if (hour.earningsCode === '2') timeAndAHalfTotal = timeAndAHalfTotal + +hour.hours;
      else if (hour.earningsCode === '3') doubleTotal = doubleTotal + +hour.hours;
      else otherEarningsHoursTotal = otherEarningsHoursTotal + +hour.hours;
    });
  });
  return { regularTotal, timeAndAHalfTotal, doubleTotal, otherEarningsHoursTotal } as TimeCardTotals;
};

//Will get the unique fo extra values off a single timecard
export const getTimeCardDetailTotalsTimeSheet = (timeCardDetails: TimeCardDetail[]) => {    
  const totalDetails : TimeCardTotalsDetails[] = [];
  let hoursTotal = 0;

  timeCardDetails?.map(detail => {
    //Sum up the hours ensuring they are the hours for the current date
    detail?.hours?.map(hour => {
      const detailRecord = totalDetails.findIndex(x => x.earningsCode === hour.earningsCode);

      if (detailRecord !== -1) totalDetails[detailRecord].hours = totalDetails[detailRecord].hours + +hour.hours;
      else totalDetails.push({ hours: +hour.hours, earningsCode: hour.earningsCode });

      hoursTotal += hour.hours;
    });
  });

  return { hoursTotal: hoursTotal, details: totalDetails.sort(sortByEarningsCode) } as TimeCardMainTotals;
};

export function sortByEarningsCode<T extends (TimeCardTotalsDetails)>(a: T, b: T) {
  const aEarningsCode = a.earningsCode?.toLowerCase();
  const bEarningsCode = b.earningsCode?.toLowerCase();
  
  if (aEarningsCode < bEarningsCode) return -1;
  if (aEarningsCode > bEarningsCode) return 1;

  return 0;
}

//Not sure if this is a no no or not. If it is I can move it in each file I just saw it was used in 3 locations and thought it made sense to have them here.
export const onCellDetailClick = (transmittalTimeCardId: number, timeCards: TimeCard[], history: any, dispatch: any) => {

  //Off the current record the child row (which will always be 1 row) has the transmittalTimeCardId as the parentId
  if (!transmittalTimeCardId) return dispatch(handleWarning('Please save to open time sheet for new records.'));
  
  //If the timecard is not found for some reason throw an error for the user so they know there was an error
  const empTimeCard = timeCards.find(x => x.transmittalTimeCardId == transmittalTimeCardId);
  if (!empTimeCard) return dispatch(handleError('Error finding employee time card'));
  
  //If all is good store the selected timecard as the current timecard and open the entry sheet.
  dispatch(storeTimeCard(empTimeCard));
  history.push(`/contractor-time-sheets?payrollHistoryId=${empTimeCard.payrollHistoryId}`);
};

//Will check to see if we should show the timeCard details based on the fitlers that were applied. 
export const validDetail = (timeCardDetails: TimeCardDetail, timeByJobFilter: TimeSheetSummaryFilter): boolean => {
  if (!timeCardDetails) return false;
  if (timeCardDetails.subNumber !== timeByJobFilter.subNo && timeByJobFilter.subNo !== '<All>') return false;
  else if (timeCardDetails.jobNumber !== timeByJobFilter.jobNo && timeByJobFilter.jobNo !== '<All>') return false;
  else if (timeCardDetails.foExtra !== timeByJobFilter.foExtra && timeByJobFilter.foExtra !== '<All>') return false;
  else if (timeCardDetails.phase !== timeByJobFilter.phase && timeByJobFilter.phase !== '<All>') return false;
  else if (timeCardDetails.costCode !== timeByJobFilter.costCode && timeByJobFilter.costCode !== '<All>') return false;
  else return true;
};

//Will check to see if we should show the timeCard based on the filters that were applied.
export const validTimeCard = (timeCard: TimeCard, timeByJobFilter: TimeSheetSummaryFilter) => {
  if (timeCard?.details?.filter(x => x.subNumber === timeByJobFilter.subNo)?.length === 0 && timeByJobFilter.subNo !== '<All>') return false;
  else if (timeCard?.details?.filter(x => x.jobNumber === timeByJobFilter.jobNo)?.length === 0 && timeByJobFilter.jobNo !== '<All>') return false;
  else if (timeCard?.details?.filter(x => x.foExtra === timeByJobFilter.foExtra)?.length === 0 && timeByJobFilter.foExtra !== '<All>') return false;
  else if (timeCard?.details?.filter(x => x.phase === timeByJobFilter.phase)?.length === 0 && timeByJobFilter.phase !== '<All>') return false;
  else if (timeCard?.details?.filter(x => x.costCode === timeByJobFilter.costCode)?.length === 0 && timeByJobFilter.costCode !== '<All>') return false;
  else return true;
};

export function sortByLastName<T extends (Employee | TimeCard)>(a: T, b: T) {
  const aName = a.lastName?.toLowerCase();
  const bName = b.lastName?.toLowerCase();
  
  if (aName < bName) return -1;
  if (aName > bName) return 1;

  return 0;
}