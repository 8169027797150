import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi, GridOptions } from '@ag-grid-enterprise/all-modules';
import InputReportDateSelector from 'core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { Client, DtoOptions } from 'core/models';
import { DepartmentofLaborRequest, DepartmentofLaborSetting } from 'core/models/HumanCapitalReports.model';
import {
  clearDeparmentofLaborReport,
  downloadDeparmentofLaborReport,
  loadDepartmentofLaborSettings,
  loadEarningCodesDropdown,
} from 'core/store/actions';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import AGEditButtonRendererComponent from 'utilities/ag-grid-renderers/AGEditButtonRendererComponent';
import { useAppSelector } from 'utilities/hooks';
import DepartmentofLaborModal from './DepartmentofLaborModal';
import { CommonService } from 'core/services';
import Icon from 'core/components/shared/Icon';

const DepartmentofLaborReportPage = () => {
  const dispatch = useDispatch();
  const childRef: React.RefObject<any> = useRef();

  const report = useAppSelector((state) => { return state.humanCapitalReports.departmentofLaborReport; });
  const client = useAppSelector((state) => { return state.client.client; }) as Client;
  const deptOfLaborSettings = useAppSelector((state) => { return state.humanCapitalReports.departmentofLaborSettings; });
  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedSetting, setSelectedSetting] = useState<DepartmentofLaborSetting | undefined>(undefined);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [selectedSettingId, setSelectedSettingId] = useState<number | null>();
  const [fileType, setFileType] = useState<string | undefined>(undefined);
  const reportName = 'DepartmentofLaborReport';

  useEffect(() => {
    dispatch(loadEarningCodesDropdown());
    dispatch(loadDepartmentofLaborSettings());
  }, []);

  useEffect(() => {
    selectedSetting && setShowModal(true);
  }, [selectedSetting]);

  useEffect(() => {
    if (report && fileType === 'excel') {
      report && CommonService.downloadBase64File(
        'application/excel',
        report,
        `${reportName}.xlsx`,
      );
      dispatch(clearDeparmentofLaborReport());
    }
  }, [report]);

  const downloadReport = (reportType: string) => {
    if (selectedSettingId && reportType) {
      const submitData: DepartmentofLaborRequest = {
        clientNo: client.clientNo,
        settingID: selectedSettingId,
        dtoOptions: reportDates,
        reportType: reportType,
      };
      setFileType(reportType);
      dispatch(downloadDeparmentofLaborReport(submitData));
    }
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSettingEdit = (rowData: DepartmentofLaborSetting) => {
    rowData && setSelectedSetting({ ...rowData });
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      selectedRows.length > 0 ?
        setSelectedSettingId(selectedRows[0].settingID) : setSelectedSettingId(undefined);
    }
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearDeparmentofLaborReport());
  };

  const columns: ColDef[] = [
    {
      field: 'settingID',
      hide: true,
    },
    {
      field: 'select',
      headerName: '',
      editable: false,
      checkboxSelection: true,
      width: 40,
    },
    {
      field: 'settingName',
      headerName: 'Setting Name',
      sortable: true,
      width: 300,
      flex: 1,
      cellStyle: {
        textAlign: 'left',
        fontSize: 14,
        color: '#00558c',
      },
    },
    {
      field: 'edit',
      editable: false,
      cellRendererParams: { clickHandler: onSettingEdit },
      cellRenderer: 'editButtonRendererComponent',
      width: 60,
    },
  ];

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    columnDefs: columns,
    rowSelection: 'single',
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    frameworkComponents: {
      editButtonRendererComponent: AGEditButtonRendererComponent,
    },
  };

  return (
    <div className="dm-panel dm-panel-border">
      <div className="dm-grid-title">Department of Labor Report</div>
      <div className="w-100 text-right">
        <button
          type="button"
          className="btn btn-link dm-grid-action-title "
          onClick={() => downloadReport('excel')}
          disabled={reportDates.length === 0 || !selectedSettingId}>
          Download Report  <Icon name="download"
            className="fa-download" />
        </button>
      </div>
      <hr className="dm-panel-hr" />
      <div className="d-flex flex-row flex-grow-1">
        <InputReportDateSelector
          ref={childRef}
          returnDates={(dates: DtoOptions[]) => {
            setReportDates(dates);
          }}
        />
        <div className="flex-grow-1 text-right">
          <button
            type="button"
            className="orange-outline-button mr-2"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="orange-button mr-2"
            onClick={() => downloadReport('pdf')}
            disabled={(reportDates?.length === 0 || !selectedSettingId)}
          >
            Run Report
          </button>

          <div className="flex-grow-1"
            style={{ marginTop: 30 }}>
            <div className="d-flex flex-row">
              <div className="dm-card-subtitle2 mb-2">Choose Setting Name</div>
              <div className=" flex-grow-1 text-right">
                <button className="btn btn-link dm-grid-action-title p-0 mr-2"
                  type="button"
                  onClick={() => {
                    setSelectedSetting(undefined); setShowModal(true);
                  }}>
                  Add New Setting	<Icon name="plus-circle"
                    className="fa-plus-circle" />
                </button>
              </div>
            </div>
            <div className="ag-theme-balham mt-2"
              style={{ height: '100%' }}>
              <AgGridReact
                gridOptions={gridOptions}
                rowData={deptOfLaborSettings}
                onGridReady={onGridReady}
                onSelectionChanged={onSelectionChanged} />
            </div>
          </div>
        </div>
      </div>
      {showModal ?
        <DepartmentofLaborModal
          allSettings={deptOfLaborSettings}
          setting={selectedSetting}
          show={showModal}
          onHide={() => { return setShowModal(false); }}
        /> : null
      }
      {report ? <PDFViewerModal
        show={report.length > 0}
        pdfData={report}
        onHide={hidePdfViewer}
        reportName="DepartmentofLaborReport"
      /> : null
      }
    </div >
  );
};

export default DepartmentofLaborReportPage;