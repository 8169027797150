import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Employee, PayrollInfo, ResendOnboardingEmailRequest } from '../../models';
import { handleError, handlePending, handleSuccess, storeNext, toggleBlockNavigation } from './app.action';
import { EmployeeService } from 'core/services';
import { updatePayrollInfo } from './payroll-info.action';
import { triggerEmFieldValidation } from './em-field-validation.action';
import { createDefaultAccrualRecords } from './accrual.action';
import { convToDateString } from 'utilities/utilities';
import { TransmittalDefaultSort } from 'features/employee/constants';
import { RootState } from '../store';

// PI-8481: Breaking the actions out that are used for the new EM validation so we can export/parse these in emFieldValidation$
export const MAIN_EM_VALIDATION_ACTIONS = {
  STORE_SELECTED_EMPLOYEE: '[employee] STORE_SELECTED_EMPLOYEE',
  UPDATE_EMPLOYEE: '[employee] UPDATE_EMPLOYEE',
  UPDATE_EMP_PHOTO: '[employee] UPDATE_EMPLOYEE_PHOTO',
} as const;

export const loadEmployee = createAction<string>('[employee] LOAD_EMPLOYEE');

export type StoreEmpRequest = {
  employee: Employee,
  /** 
    This will tell the reducer where to put this employee in the list relative to the transmittal sort order (CM option 34).
    We need to pass this in because it isn't accessible state in the employee reducer.
    @example ```
    "Loc Dept Alfa" | "Department" | "Alphabetically" | "Last Name" | "Employee No" | "Sub-Department" | "Sub-Department 2"
      const defaultSortOption = useAppSelector(({ client }) => client?.clientOptions?.options?.[34]?.optionValue);
    ```
*/
  sortBy: TransmittalDefaultSort,
  preventEmpsUpdate?: boolean
};

export const storeEmployee = createAction<StoreEmpRequest>('[employee] STORE_EMPLOYEE');

export const toggleEmpLoadingState = createAction<boolean>('[employee] TOGGLE_LOADING_STATE');

export type CreateEmployeeRequest = {
  employee: Employee;
  next?: string;
};

export const enableBlockingEmValidate = createAction<boolean>('[employee] ENABLE_BLOCK_VALIDATE');
export const enableBlockingPayRates = createAction<boolean>('[employee] ENABLE_BLOCK_PAYRATES');

// I anticipate that we'll want to block navigation somewhere other than EM in the future... but we can handle that then. Shouldn't be too different
export const unblockAll = createAction('[employee] UNBLOCK_ALL');

export const createEmployee = createAsyncThunk(
  '[employee] CREATE_EMPLOYEE',
  async (request: CreateEmployeeRequest, { dispatch, getState }) => {
    try {
      const { data } = await EmployeeService.postEmployee(request.employee);
      const newEmp = data.value;
      
      // gross
      const state: RootState = getState() as RootState;
      const clientSortOption = state.client.clientOptions.options[34].optionValue as TransmittalDefaultSort ?? 'Alphabetically';
      
      dispatch(storeEmployee({ employee: newEmp, sortBy: clientSortOption }));
      dispatch(toggleBlockNavigation({ block: true, type: 'notice', message: 'You must enter a payrate before leaving.' })); // don't let the user leave the payrates page until they enter a payrate. PI-8475 specifies this should happen when adding an employee
      if (request?.next?.length) dispatch(storeNext(request.next.replace('{empNo}', newEmp.protectedEmpNo)));
      
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const createEmployeeSuccess = createAction<Employee>(
  '[employee] CREATE_EMPLOYEE_SUCCESS',
);
export const createEmployeeFail = createAction<Employee>(
  '[employee] CREATE_EMPLOYEE_FAIL',
);

// export const updateEmployee = createAction<Employee>(MAIN_EM_VALIDATION_ACTIONS.UPDATE_EMPLOYEE);
export const updateEmployee = createAsyncThunk(
  MAIN_EM_VALIDATION_ACTIONS.UPDATE_EMPLOYEE,
  async (employee: Employee, { dispatch, getState }) => {
    try {
      dispatch(handlePending(`Updating ${employee.firstName} ${employee.lastName}...`));
      
      const { data } = await EmployeeService.putEmployee(employee);
      const updatedEmp = data.value;
      const messages = data.messages;
      
      // gross
      const state: RootState = getState() as RootState;
      const clientSortOption = state.client.clientOptions.options[34].optionValue as TransmittalDefaultSort ?? 'Alphabetically';

      dispatch(storeEmployee({ employee: updatedEmp, sortBy: clientSortOption }));
      dispatch(handleSuccess(messages));
      dispatch(triggerEmFieldValidation({
        section: 'empinfo',
        actionType: typeof(Employee),
        callerPayload: updatedEmp,
      }));
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(handlePending(null));
    }
  },
);

export const deleteEmployee = createAction<{ protectedEmpNo: string, empNo: number }>(
  '[employee] DELETE_EMPLOYEE',
);
export const storeEmployeeDeleteResult = createAction<{ isError: boolean, message: string } | null>(
  '[employee] STORE_EMPLOYEE_DELETE_RESULT',
);
export const updateEmployeePhoto = createAction<{
  empNo: string;
  photoData: any;
}>(MAIN_EM_VALIDATION_ACTIONS.UPDATE_EMP_PHOTO);
export const storeSelectedEmployee = createAction<Employee | null>(
  MAIN_EM_VALIDATION_ACTIONS.STORE_SELECTED_EMPLOYEE,
);
export const resendOnboardingEmail = createAction<ResendOnboardingEmailRequest>(
  '[employee] RESEND_ONBOARDING_EMAIL',
);

export const storeCurrentEmpNo = createAction<number>('[employee] STORE_CURRENT_EMP_NO');
export const updateEmployeeStatus = createAction<{ protectedEmpNo: string }>('[employee] STORE_UPDATE_EMPSTATUS');