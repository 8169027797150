import { CellValueChangedEvent, GridOptions } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi } from '@ag-grid-enterprise/all-modules';
import Modal from 'core/components/modals/Modal';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { UserAccessHR360 } from 'core/models/UserAccess';
import { putHR360User } from 'core/store/slices/cmUsers.slice';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { agCheckboxEditor } from 'utilities/ag-grid-editors';
import { agCheckboxRenderer } from 'utilities/ag-grid-renderers';
import CheckboxRenderer from 'utilities/ag-grid-renderers/CheckboxRenderer';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

type PropTypes = {
  show: boolean;
  onHide: () => void;
};

const LibraryAccessModal: React.FC<PropTypes> = ({
  show,
  onHide,
}) => {
    
  const dispatch = useAppDispatch();

  const hr360Users = useAppSelector(
    (state) => { return state.cmUsers.hr360Users; },
  );
  
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [rowData, setRowData] = useState<UserAccessHR360[]>([]);
  const [changedUsers, setChangedUsers] = useState<number[]>([]);
  const [gridDirty, setGridDirty] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  useEffect(()=>{
    setGridDirty(false);
    hr360Users && setRowData(cloneDeep(hr360Users));
  }, [hr360Users]);

  const columns: ColDef[] = [    
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'dmpsUserName',
      headerName: 'User Name',
      width: 200,
    },
    {
      field: 'hasAccess', 
      cellRenderer: 'checkboxRenderer',
      cellEditor: 'checkboxEditor',
      cellStyle: { paddingLeft: '16px' },
      width: 85,
    },
  ];

  const gridOptions: GridOptions = {
    columnDefs: columns,
    domLayout: 'autoHeight',
    defaultColDef: {
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
      singleClickEdit: true,
    },
    stopEditingWhenCellsLoseFocus: true,   
    components: {
      checkboxRenderer: CheckboxRenderer,
      checkboxEditor: agCheckboxEditor,
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

 
  const hideModal = () => {  
    setChangedUsers([]);
    if (gridDirty) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };

  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };

  const getGridData = () => {
    const items: any[] = [];
    gridApi?.forEachNode((node) => {
      items.push(node.data);
    });
    return items;
  };

  const onSubmit = (e?: React.BaseSyntheticEvent) => {
    if (!e?.target.isConnected) {
      return false;
    }

    const data = getGridData() as UserAccessHR360[];
    const dataFilter = structuredClone(data).filter(x => changedUsers.includes(x.secUserId));

    dataFilter.forEach((item:UserAccessHR360)=> {
      dispatch(putHR360User(item));
    });
    
  };

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={hideModal}
        dialogClassName="modal-sm"
        isDirty={gridDirty}     
        backdrop={'static'}
        title="Assign Library Access"
      >
       
        <div className="table-wrapper-wrapper ag-theme-balham">
          <AgGridReact
            gridOptions={gridOptions}
            rowData={rowData}           
            onGridReady={onGridReady}
            onCellValueChanged={(e: CellValueChangedEvent) => {  

              //This sets which users actually got changed. This will be used so we only send users who changed to get updated.
              const user = e.data.secUserId as number;
              if(changedUsers.includes(user)) setChangedUsers([...changedUsers.filter(x => x === user)]);
              else setChangedUsers([...changedUsers, user]);

              if (e.oldValue !== e.newValue) {
                setGridDirty(true);
              }
            }}
          />
        </div>
       
        <div className="d-flex mt-3">
          <button
            type="button"
            className="btn btn-primary orange-outline-button ml-auto mr-2"
            onClick={hideModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="orange-button mr-2"
            onClick={onSubmit}
            disabled={!changedUsers.length}
          >
            Save
          </button>
        </div>
      </Modal>
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
    </div>
  );
};
export default LibraryAccessModal;
