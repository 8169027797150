import React, { useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import EmployeeInformation from '../../core/components/shared/EmployeeInformation';
import AccrualsPage from '../emp-detail/accruals/AccrualsPage';
import EmployeeInformationSidebar from './Sidebar';

const EmployeeInformationAccruals = () => {
  const [protectedEmpNo, setProtectedEmpNo] = useState('');

  const onSelect = (_protectedEmpNo: string) => {
    setProtectedEmpNo(_protectedEmpNo);
  };

  return (
    <Row className="no-gutters">
      <EmployeeInformationSidebar
        onSelect={onSelect}
        accrualScreen={true}
      />
      <Col className="m-3 shadow p-3 border">
        {protectedEmpNo ? (
          <>
            <EmployeeInformation protectedEmpNo={protectedEmpNo} />
            <AccrualsPage protectedEmpNo={protectedEmpNo} />
          </>
        ) : (
          <Alert variant="warning">
            Please select an employee to view their accruals.
          </Alert>
        )}
      </Col>
    </Row>
  );
};

export default EmployeeInformationAccruals;
