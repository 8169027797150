import { createReducer } from '@reduxjs/toolkit';
import { ClientAccess } from 'core/models/ClientAccess';
import { User } from '../../models/User.model';
import { UserAccess } from '../../models/UserAccess';

import {
  changeClient,
  createToken,
  getUser,
  loadUserAccess,
  storeAvailableClients,
  storeToken,
  storeUser,
  storeUserAccess,
  storeUserDefaultClient,
} from '../actions';

export interface AuthState {
  loading: boolean;
  loadingUserAccess: boolean;
  token: string | null;
  expiresIn: Date | null;
  user: User | null;
  userAccess: UserAccess | null;
  currentProtectedClientNo: string;
  availableClients: ClientAccess[];
  userDefaultClient: number | null;
  error: string;
}

const INITIAL_STATE: AuthState = {
  loading: false,
  loadingUserAccess: false,
  token: null,
  expiresIn: null,
  user: null,
  userAccess: null,
  currentProtectedClientNo: '',
  availableClients: [],
  userDefaultClient: null,
  error: '',
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getUser, (state) => {
      state.loading = true;
    })
    .addCase(loadUserAccess, (state) => {
      state.loadingUserAccess = true;
    })
    .addCase(storeUser, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    })
    .addCase(createToken, (state) => {
      state.loading = true;
    })
    .addCase(storeToken, (state, action) => {
      state.loading = false;
    })
    .addCase(storeUserAccess, (state, action) => {
      state.loading = false;
      state.loadingUserAccess = false;
      state.userAccess = action.payload;
      state.availableClients = action.payload.availableClients;
      
      const currentClientNo = sessionStorage.getItem('currentClientNo');
      
      if (!action.payload.nextClientNo) {
        /* Attempt to restore client from session storage clientNo OR grab the default client (which will happen on login). If both are undefined, grab the first
        non-terminated client. If ALL else fails, just get load the first client in the list. */
        const sortedClients = action.payload.availableClients.map((x) => ({ ...x }));
        
        sortedClients.sort((a, b) => a.clientNo - b.clientNo);
        
        const matchingClient = action.payload.availableClients
          .find((x) => x.clientNo === parseInt(currentClientNo ?? '0'))
          ?? sortedClients?.find((x) => x.isDefaultClient)
          ?? sortedClients?.find((x) => !x.isTerminated)
          ?? sortedClients?.[0];

        state.currentProtectedClientNo = matchingClient.protectedClientNo;
        sessionStorage.setItem('currentClientNo', String(matchingClient.clientNo));
      } else {
        const nextProtectedClient = action.payload.availableClients.find((client) => client.clientNo === action.payload.nextClientNo);
        if (!nextProtectedClient) {
          return console.error('Error when loading next client');
        }
        
        state.currentProtectedClientNo = nextProtectedClient.protectedClientNo;
        sessionStorage.setItem('currentClientNo', String(nextProtectedClient.clientNo));
      }
      
      const defaultClient = action.payload.availableClients
        .find((client) => client.isDefaultClient);
      
      state.userDefaultClient = defaultClient?.clientNo || null;
    })
    .addCase(storeUserDefaultClient, (state, action) => {
      state.userDefaultClient = action.payload.defaultClientNo;
      
      state.availableClients = state.availableClients.map((x) => {
        return { ...x, isDefaultClient: x.clientNo === (action.payload.defaultClientNo ?? 0) };
      });
    })
    .addCase(storeAvailableClients, (state, action) => {
      state.availableClients = action.payload;
    })
    .addCase(changeClient, (state, action) => {
      state.currentProtectedClientNo = action.payload;
    });
});
