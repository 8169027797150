import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { UserMapItem, UserMapSelectedField } from 'core/models/Downloader.model';
import { getUserMapById, saveUserMap } from '../actions/employee-earnings-download.action';
import { toast } from 'react-toastify';

export interface EmployeeEarningsDownloadState {
  loading: boolean;
  saving: boolean;
  userMapId?: number | null;
  selectedFields: UserMapSelectedField[];
}

const initialState: EmployeeEarningsDownloadState = {
  loading: false,
  saving: false,
  userMapId: null,
  selectedFields: [],
};

export const employeeEarningsDownloadSlice = createSlice({
  name: 'employeeEarningsDownload',
  initialState,
  reducers: {
    addSelectedField(state, action) {
      state.selectedFields = [...state.selectedFields, action.payload];
    },
    setSelectedFields(state, action) {
      state.selectedFields = action.payload;
    },
    removeSelectedField(state, action) {
      const data = action.payload;
      
      state.selectedFields = [...state.selectedFields].filter((item) => {
        return !(item?.filter?.code === data.code && item?.filter?.description === data.description && item.fieldName === data.fieldName);
      });
    },
    removeSelectedFieldBySection(state, action) {
      const data = action.payload;
      
      state.selectedFields = [...state.selectedFields].filter((item) => {
        return !(item?.section === data.section && item.fieldName === data.fieldName);
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserMapById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserMapById.fulfilled, (state, action: any) => {
        const data = action?.payload;
        
        if (data?.selectedFields) {
          state.selectedFields = data?.selectedFields || [];
        }

        state.userMapId = data?.userMapId;
        state.loading = false;
      })
      .addCase(getUserMapById.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(saveUserMap.pending, (state) => {
        state.saving = true;
      })
      .addCase(saveUserMap.fulfilled, (state, action: any) => {
        const data = action.payload;
        
        if (data?.value?.selectedFields) {
          state.selectedFields = data?.value?.selectedFields || [];
        }

        state.saving = false;
        state.userMapId = data.value.userMapId;
        
        toast.success(data?.messages?.[0] || 'Updated user map successfully', { autoClose: 3000 });
      })
      .addCase(saveUserMap.rejected, (state, action) => {
        state.saving = false;
        toast.error(action.payload, { autoClose: 3000 });
      });
  },
});

export const {
  addSelectedField,
  setSelectedFields,
  removeSelectedField,
  removeSelectedFieldBySection,
} = employeeEarningsDownloadSlice.actions;

export default employeeEarningsDownloadSlice.reducer;
