import { AxiosResponse } from "axios";
import { HttpResponse, WCEffectiveDate } from '../models';
import { API_CLIENT } from './API';
import { CommonService } from './common.service';
import { ClientMasterEndPoints } from './URL';

const getWCEffectiveDates = (): Promise<AxiosResponse<WCEffectiveDate[]>> => { return API_CLIENT.get<WCEffectiveDate[]>(ClientMasterEndPoints.GET_WC_EFFECTIVE_DATES(CommonService.getProtectedClientNo())); };

const getWCEffectiveDate = (stateOfficerLimitID: number): Promise<AxiosResponse<WCEffectiveDate>> => { return API_CLIENT.get<WCEffectiveDate>(ClientMasterEndPoints.GET_WC_EFFECTIVE_DATE(CommonService.getProtectedClientNo(), stateOfficerLimitID)); };

const postWCEffectiveDate = (data: WCEffectiveDate): Promise<AxiosResponse<HttpResponse<WCEffectiveDate>>> => { return API_CLIENT.post<HttpResponse<WCEffectiveDate>>(ClientMasterEndPoints.POST_WC_EFFECTIVE_DATE(CommonService.getProtectedClientNo()), data); };

const putWCEffectiveDate = (data: WCEffectiveDate): Promise<AxiosResponse<HttpResponse<WCEffectiveDate>>> => { return API_CLIENT.put<HttpResponse<WCEffectiveDate>>(ClientMasterEndPoints.PUT_WC_EFFECTIVE_DATE(CommonService.getProtectedClientNo(), data.stateOfficerLimitID), data); };

const deleteWCEffectiveDate = (data: WCEffectiveDate): Promise<AxiosResponse<HttpResponse<any>>> => { return API_CLIENT.delete<HttpResponse<any>>(ClientMasterEndPoints.DELETE_WC_EFFECTIVE_DATE(CommonService.getProtectedClientNo(), data.stateOfficerLimitID)); };

const deleteWCRate = (wcRuleID: number): Promise<AxiosResponse<HttpResponse<any>>> => { return API_CLIENT.delete<HttpResponse<any>>(ClientMasterEndPoints.DELETE_WC_RATE(CommonService.getProtectedClientNo(), wcRuleID)); };

export const WorkerCompService = {
  getWCEffectiveDates,
  getWCEffectiveDate,
  postWCEffectiveDate,
  putWCEffectiveDate,
  deleteWCEffectiveDate,
  deleteWCRate,
};
