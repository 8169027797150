import { createSelector } from 'reselect';
import { convToFormObject } from '../../../utilities/classUtils';
import { DeferredCompensation } from '../../models';
import { deferredCompState } from '../reducers';
import { State as DeferredComp } from '../reducers/deferred-compensation.reducer';

export const getDeferredCompensation = createSelector(
  deferredCompState,
  (state: DeferredComp) => {
    if (!state.deferredComp) return state;

    return convToFormObject(state.deferredComp, DeferredCompensation.convProps);
  });
