import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CheckboxGrpInLine, RadioGrp } from 'core/components/form-controls';
import InputReportDateSelector from 'core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { Client, DtoOptions } from 'core/models';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';

import { CommonService } from 'core/services';
import { FringeReportRequest } from 'core/models/ContractorReports.model';
import {
  clearFringeReport,
  downloadFringeReport,
} from 'core/store/slices/contractorReports.slice';
import Icon from 'core/components/shared/Icon';

const payrollOptions: RadioOptions[] = [
  {
    value: 'ByWeekEnding',
    label: 'By Week Ending',
  },
  {
    value: 'ByCheckDate',
    label: 'By Check Date',
  },
];

const options: RadioOptions[] = [
  {
    value: 'ByAreaTrade',
    label: 'By Area/Trade',
  },
  {
    value: 'ByAreaTradeSub',
    label: 'By Area/Trade/Sub',
  },
  {
    value: 'ByJob',
    label: 'By Job',
  },
];

const FringeReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const childRef: React.RefObject<any> = useRef();

  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [fileType, setFileType] = useState<string>('pdf');

  const client = useAppSelector((state) => { return state?.client?.client; }) as Client;

  const report = useAppSelector(
    (state) => { return state.contractorReports.fringeReport; },
  );
  const hidePdfViewer = () => {
    dispatch(clearFringeReport());
  };

  const { register, handleSubmit } = useForm<FringeReportRequest>({
    defaultValues: {
      dateOption: 'ByWeekEnding',
      reportByOption: 'ByAreaTrade',
    },
  });

  useEffect(() => {
    if (report && fileType === 'excel') {
      CommonService.downloadBase64File(
        'application/excel',
        report,
        'FringeReport.xlsx',
      );
      dispatch(clearFringeReport());
    }
  }, [report]);

  const downloadReport = (data: FringeReportRequest) => {
    const submitData: FringeReportRequest = {
      clientNo: client.clientNo,
      dateOption: data.dateOption,
      reportByOption: data.reportByOption,
      runForNationalJobsOnly: data.runForNationalJobsOnly,
      includeCity: data.includeCity,
      includeJobName: data.includeJobName,
      includeCounty: data.includeCounty,
      includeState: data.includeState,
      dtoOptions: reportDates,
      reportType: fileType,
    };
    dispatch(downloadFringeReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">Fringe Report</div>
          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title "
              onClick={() => { return setFileType('excel'); }}
              disabled={!reportDates.length}
            >
              Download Report{' '}
              <Icon name="download"
                className="fa-download" />
            </button>
          </div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div>
              <InputReportDateSelector
                ref={childRef}
                returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }
                }
              />
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <div className=" text-right">
                <button
                  type="button"
                  className="orange-outline-button mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="orange-button mr-2"
                  onClick={() => { return setFileType('pdf'); }}
                  disabled={!reportDates.length}
                >
                  Run Report
                </button>
              </div>

              <div
                className="dm-panel dm-panel-border d-flex flex-row flex-wrap"
                style={{
                  marginTop: '66px',
                  width: '40%'
                }}
              >
                <div
                  className="d-flex flex-row flex-grow-1"
                  style={{ justifyContent: 'space-around' }}
                >
                  <div
                    style={{ width: '100%' }}
                    className="dm-grid-action-title mb-2"
                  >
                    Options
                  </div>
                  <div
                    style={{
                      width: '100%',
                      marginLeft: '80px',
                    }}
                    className="dm-grid-action-title mb-2"
                  >
                    Miscellaneous Options
                  </div>
                </div>

                <div className="d-flex flex-row row-wrap flex-grow-1">
                  <div
                    id="container-radio-btn-row"
                    className="d-flex flex-row row-wrap flex-grow-1"
                  >
                    <div
                      id=" radio-btns"
                      style={{ marginTop: '10px' }}
                      className="d-flex flex-row row-wrap flex-grow-1"
                    >
                      <div>
                        <RadioGrp
                          isVertical={true}
                          radioOptions={
                            payrollOptions
                          }
                          name="dateOption"
                          ref={register}
                        />
                      </div>
                      <div>
                        <RadioGrp
                          isVertical={true}
                          name="reportByOption"
                          ref={register}
                          radioOptions={options}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="container-for-checkboxes"
                    className="d-flex flex-row row-wrap flex-grow-1 pt-1 pl-1"
                  >
                    <div className="d-flex flex-column">
                      <div
                        id="row-1st-half"
                        className="d-flex flex-row row-wrap"
                      >
                        <div
                          id="1st2"
                          className="d-flex flex-column flex-grow-1 pt-1 pl-1"
                        >
                          <div id="JobName">
                            <CheckboxGrpInLine
                              ref={register}
                              name="includeJobName"
                              labelFirst={false}
                              label="Job Name"
                            />
                          </div>
                          <div id="City">
                            <CheckboxGrpInLine
                              ref={register}
                              name="includeCity"
                              labelFirst={false}
                              label="City"
                            />
                          </div>
                        </div>
                        {/** div for checkboxes*/}
                        <div
                          id="next2"
                          className="d-flex flex-column 
                                                flex-grow-1 pt-1 pl-1"
                        >
                          <div id="County">
                            <CheckboxGrpInLine
                              ref={register}
                              name="includeCounty"
                              labelFirst={false}
                              label="County"
                            />
                          </div>
                          <div id="State">
                            <CheckboxGrpInLine
                              ref={register}
                              name="includeState"
                              labelFirst={false}
                              label="State"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        id="row-2nd-half"
                        className="d-flex flex-column "
                        style={{
                          width: '70%',
                        }}
                      >
                        <div
                          id="hr"
                          style={{
                            marginLeft: '10px',
                            height: '40px',
                          }}
                        >
                          <hr
                            style={{
                              marginLeft: 'auto',
                              marginRight: 'auto',
                            }}
                          />
                        </div>

                        <div
                          id="jobsonly"
                          style={{
                            marginLeft: '10px',
                          }}
                        >
                          <CheckboxGrpInLine
                            ref={register}
                            name="runForNationalJobsOnly"
                            labelFirst={false}
                            label="Run for National Jobs Only"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {report ? (
          <PDFViewerModal
            show={report.length > 0}
            pdfData={report}
            onHide={hidePdfViewer}
            reportName="FringeRpeort"
          />
        ) : null}
      </div>
    </div>
  );
};
export default FringeReport;
