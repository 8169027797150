import { createAction } from '@reduxjs/toolkit';
import { AodPayrollUploadMap, OnTheClockRequest, PayrollUpload, PayrollUploadDefault, PayrollUploadEarnCodeExceptionMap, PayrollUploadEarnCodeRateMap, PayrollUploadErrors, PayrollUploadFieldTranslation, PayrollUploadFile, PayrollUploadFileCheck, PayrollUploadManageFiles, PayrollUploadMap, PayrollUploadVM, TimeCard } from '../../models/PayrollUpload.model';

export const loadPayrollUpload = createAction('[payroll-upload] LOAD_UPLOAD');
export const storePayrollUploadVM = createAction<PayrollUploadVM>('[payroll-upload] STORE_UPLOAD_VM');
export const storePayrollUpload = createAction<PayrollUpload>('[payroll-upload] STORE_UPLOAD');
export const resetPayrollUploadVM = createAction('[payroll-upload] RESET_UPLOAD_VM');

export const createPayrollUploadFile = createAction<PayrollUpload>('[payroll-upload] CREATE_UPLOAD_FILE');
export const createDefaultPayrollUploadFile = createAction<PayrollUploadDefault>('[payroll-upload] CREATE_DEFAULT_UPLOAD_FILE');
export const updatePayrollUploadFile = createAction<PayrollUpload>('[payroll-upload] UPDATE_UPLOAD_FILE');
export const storeSelectedPayrollUploadFile = createAction<PayrollUpload>('[payroll-upload] STORE_SELECTED_UPLOAD_FILE');

export const createPayrollUploadField = createAction<PayrollUploadMap>('[payroll-upload] CREATE_UPLOAD_FIELD');
export const updatePayrollUploadField = createAction<PayrollUploadMap>('[payroll-upload] UPDATE_UPLOAD_FIELD');
export const storePayrollUploadField = createAction<PayrollUploadMap>('[payroll-upload] STORE_UPLOAD_FIELD');
export const deletePayrollUploadFields = createAction<PayrollUploadMap[]>('[payroll-upload] DELETE_UPLOAD_FIELDS');

export const createPayrollUploadTranslation = createAction<{ mapId: number, customUploadMapId: number, translation: PayrollUploadFieldTranslation }>
('[payroll-upload] CREATE_UPLOAD_TRANSLATION');
export const updatePayrollUploadTranslation = createAction<{ mapId: number, customUploadMapId: number, translation: PayrollUploadFieldTranslation }>
('[payroll-upload] UPDATE_UPLOAD_TRANSLATION');
export const storePayrollUploadTranslation = createAction<{ mapId: number, customUploadMapId: number, translation: PayrollUploadFieldTranslation }>
('[payroll-upload] STORE_UPLOAD_TRANSLATION');
export const deletePayrollUploadTranslation = createAction<{ mapId: number, customUploadMapId: number, translation: PayrollUploadFieldTranslation }>
('[payroll-upload] DELETE_UPLOAD_TRANSLATION');

export const createPayrollUploadException = createAction<{ mapId: number, customUploadMapId: number, exception: PayrollUploadEarnCodeExceptionMap }>
('[payroll-upload] CREATE_UPLOAD_EXCEPTION');
export const updatePayrollUploadException = createAction<{ mapId: number, customUploadMapId: number, exception: PayrollUploadEarnCodeExceptionMap }>
('[payroll-upload] UPDATE_UPLOAD_EXCEPTION');
export const storePayrollUploadException = createAction<{ mapId: number, customUploadMapId: number, exception: PayrollUploadEarnCodeExceptionMap }>
('[payroll-upload] STORE_UPLOAD_EXCEPTION');
export const deletePayrollUploadException = createAction<{ mapId: number, customUploadMapId: number, exception: PayrollUploadEarnCodeExceptionMap }>
('[payroll-upload] DELETE_UPLOAD_EXCEPTION');

export const createPayrollUploadRate = createAction<{ mapId: number, customUploadMapId: number, rate: PayrollUploadEarnCodeRateMap }>
('[payroll-upload] CREATE_UPLOAD_RATE');
export const updatePayrollUploadRate = createAction<{ mapId: number, customUploadMapId: number, rate: PayrollUploadEarnCodeRateMap }>
('[payroll-upload] UPDATE_UPLOAD_RATE');
export const storePayrollUploadRate = createAction<{ mapId: number, customUploadMapId: number, rate: PayrollUploadEarnCodeRateMap }>
('[payroll-upload] STORE_UPLOAD_RATE');
export const deletePayrollUploadRate = createAction<{ mapId: number, customUploadMapId: number, rate: PayrollUploadEarnCodeRateMap }>
('[payroll-upload] DELETE_UPLOAD_RATE');

export const payrollUploadFileTool = createAction<PayrollUploadFile>
('[payroll-upload] UPDATE_UPLOAD_FILE_TOOL');

export const payrollUploadFileCheckTool = createAction<PayrollUploadFileCheck>
('[payroll-upload] UPDATE_UPLOAD_FILE_CHECK_TOOL');

export const storePayrollUploadFileToolErrors = createAction<PayrollUploadErrors[] | null>
('[payroll-upload] UPDATE_UPLOAD_FILE_TOOL_STORE_ERRORS');

export const storePayrollUploadFileToolCheckErrors = createAction<{alreadyUploaded: boolean, message: string}>
('[payroll-upload] UPDATE_UPLOAD_FILE_TOOL_STORE_CHECK_ERRORS');

export const loadOnTheClockData = createAction<OnTheClockRequest>('[payroll-upload] LOAD_ON_THE_CLOCK_DATA');
export const storeOnTheClockData = createAction<TimeCard[]>('[payroll-upload] STORE_ON_THE_CLOCK_DATA');

export const loadAodPayrollUploadMaps = createAction('[payroll-upload] LOAD_AOD_Maps');
export const storeAodPayrollUploadMaps = createAction<AodPayrollUploadMap | null>('[payroll-upload] STORE_AOD_MAPS');

export const savePayrollUploadMapActiveStatuses = createAction<PayrollUploadManageFiles[]>('[payroll-upload] SAVE_PAYROLL_UPLOAD_MAP_ACTIVE_STATUSES');
export const copyPayrollUploadMap = createAction<number>('[payroll-upload] COPY_PAYROLL_UPLOAD_MAP');