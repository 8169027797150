import { AxiosResponse } from "axios";
import { HttpResponse } from '../models';
import { DeferredCompensation } from '../models/DeferredCompensation.model';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getDeferredCompensation = (protectedEmpNo: string): Promise<AxiosResponse<DeferredCompensation>> => {return API_EMPLOYEE_MASTER.get<DeferredCompensation>(EmployeeEndPoints.GET_DEFERRED_COMPENSATION(CommonService.getProtectedClientNo(), protectedEmpNo));};

const putDeferredCompensation = (defComp: DeferredCompensation): Promise<AxiosResponse<HttpResponse<DeferredCompensation>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<DeferredCompensation>>(EmployeeEndPoints.PUT_DEFERRED_COMPENSATION(CommonService.getProtectedClientNo(), defComp.protectedEmpNo), defComp);};

export const DeferredCompensationService = {
  getDeferredCompensation,
  putDeferredCompensation,
};
