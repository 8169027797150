import { createSelector } from 'reselect';
import { convToFormObject } from 'utilities/classUtils';
import { RecurringEarnings } from '../../models';
import { recurringEarningsState } from '../reducers';
import { State as RecurringEarningsState } from '../reducers/recurring-earnings.reducer';

export const getRecurringEarnings = createSelector(
  recurringEarningsState,
  (state: RecurringEarningsState) => {
    if (!state?.recurringEarnings) return [];

    return state.recurringEarnings.map((r: RecurringEarnings) => {return convToFormObject(r, RecurringEarnings.convProps);}) as RecurringEarnings[];
  },
);
