import React, { useEffect, useState } from 'react';
import PayrollCard from './PayrollCard';
import { Payroll } from 'core/models';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { prFrequencyOpts } from 'features/payroll/constants';
import {
  canRecoverPayroll,
  clearPayrolls,
  deletePayroll,
  handleError,
  loadPayroll,
  loadPayrollMessages,
  loadPayrollOptions,
  loadPayrollPayRateValidate,
  postPayrollCheckRegister,
  storeSelectedDates,
} from 'core/store/actions';
import { Modal } from 'react-bootstrap';
import InsertPayrollModal from '../modals/InsertPayroll.modal';
import PayrollMessageModal from '../modals/PayrollMessage.modal';
import PayrollOptionsModal from '../modals/PayrollOptions.modal';
import PayrollPreviewPdfModal from '../modals/PayrollPreviewPdf';
import { BalanceConditions, CardListProps, ShowState } from './types';
import RecoveryModal from '../modals/RecoveryModal';
import RecoverySubmittedModal from '../modals/RecoverySubmitted.modal';
import { getControlTotalsByOption } from 'core/store/selectors';
import { useSelector } from 'react-redux';
import PayrollCardListActionBar from './PayrollCardListActionBar';
import { checkIfBalanced, sumControlTotalData } from './utilities';
import DateObject from 'react-date-object';

const initShowState: ShowState = {
  submitPayroll: false,
  payrollPreview: false,
  payrollInsert: false,
  payrollMessageModal: false,
  registerReports: false,
  recovery: false,
  recoverySubmitted: false,
};

const CardList = ({ payrollItems, sortBy, type, reportDates, passSelectedPayroll }: CardListProps) => {
  const [payrollItemsState, setpayrollItemsState] = useState<Payroll[]>(payrollItems); // defined for sorting
  const [selectedCard, setSelectedCard] = useState<Payroll | null>(null);
  const [enablePreview, setEnablePreview] = useState(true);
  const [openIndex, setOpenIndex] = useState<number | null>();
  const [show, setShow] = useState(initShowState);
  const [viewType, setViewType] = useState<string>();
  // TODO: Finish media query hook/context, replace this
  const [mobileView, setMobileView] = useState(false);
  
  const dispatch = useAppDispatch();
  
  const client = useAppSelector((state) => { return state.client.client; });
  const { latestPayrollId } = useAppSelector(({ payroll }) => payroll);
  const controlTotalsByOptions = useSelector(getControlTotalsByOption) as string;
 
  const showAuxInfo = ['Location', 'Department'].includes(controlTotalsByOptions);
  const adjustmentTotal = selectedCard?.adjustmentTotals?.[0];
  const [thirdPartySickCount, prePayCount, voidCount] =
    [adjustmentTotal?.thirdPartySickCount, adjustmentTotal?.prePayCount, adjustmentTotal?.voidCount];
  const unbalancedControlTotals = selectedCard?.controlTotals
    ?.filter((controlTotal) => !controlTotal?.balanced && sumControlTotalData(controlTotal) > 0);
  const hasAdjustmentData = (thirdPartySickCount ?? 0) + (prePayCount ?? 0) + (voidCount ?? 0) > 0;
  const conditions: BalanceConditions = {
    showAuxInfo,
    hasAdjustmentData,
    hasUnbalancedCTs: !!unbalancedControlTotals?.length,
  };
  const isBalanced = checkIfBalanced(selectedCard, conditions);
  const hasWaitingDateline = !!payrollItemsState?.find(x => { return x.status === 'W' || x.status === 'E' || x.status === 'P'; }); 
  
  if (payrollItemsState !== payrollItems) {
    setpayrollItemsState(payrollItems);
  }

  useEffect(() => {
    // standard mobile breakpoint
    const mediaQuery = window.matchMedia('(max-width: 767px)');
    setMobileView(mediaQuery.matches);
    const handleQueryChange = () => {
      setMobileView(mediaQuery.matches);
    };
    mediaQuery.addEventListener('change', handleQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleQueryChange);
    };
  }, []);
  
  useEffect(() => {
    if (!latestPayrollId) return;
    handleCardSelect(latestPayrollId);
  }, [latestPayrollId, payrollItems]);
  
  useEffect(() => {
    setEnablePreview(!!selectedCard && !!isBalanced);
    if (!selectedCard || selectedCard.status !== 'F') return;
    dispatch(canRecoverPayroll(selectedCard.payrollHistoryId));
  }, [selectedCard, isBalanced]);
  
  useEffect(() => {
    if (viewType === 'Preview' && show.submitPayroll) {
      setShow((prevState) => {
        return {
          ...prevState,
          submitPayroll: false,
          payrollPreview: true,
        };
      });
    }
  }, [viewType]);
  
  const handleCardSelect = (id: number) => {
    if (!payrollItems?.length) return;
    
    const selectedPayroll = payrollItems.find((payroll: Payroll) => { return payroll.payrollHistoryId === id; });
    if (!selectedPayroll) return;

    setSelectedCard(selectedPayroll);
    passSelectedPayroll(selectedPayroll);
    setOpenIndex(selectedPayroll.payrollHistoryId);
  };
  
  const toggleExpanded = (id: number | null) => {
    if (!id) {
      setOpenIndex(null);
      setSelectedCard(null);
      return;
    } 
    handleCardSelect(id);
  };
  
  const handleDelete = () => {
    if (selectedCard?.adjustmentTotals?.length && !window.confirm('WARNING: This payroll has adjustments. If you continue, the adjustments will be deleted. Continue?')) {
      return;
    } else if (!(selectedCard && window.confirm('Are you sure you want to delete this dateline?'))) {
      return;
    }
    
    dispatch(deletePayroll(selectedCard.payrollHistoryId));
    setSelectedCard(null);
  };
  
  const onCheckRegister = () => {
    if (!selectedCard) return;

    dispatch(
      postPayrollCheckRegister({
        payrollHistoryId: selectedCard.payrollHistoryId,
        isPayrollSubmission: false,
      }),
    );
  };

  const reloadPayrolls = () => {
    if (!(reportDates && reportDates.length > 1)) return;

    dispatch(clearPayrolls());

    dispatch(
      loadPayroll({
        beginDate: reportDates[0],
        endDate: reportDates[1],
        byCheckDate: sortBy === 'checkDate',
      }),
    );
    dispatch(storeSelectedDates([new DateObject(reportDates[0]), new DateObject(reportDates[1])]));
    dispatch(loadPayrollMessages());
    setSelectedCard(null);
    setOpenIndex(null);
  };
  
  const listData = payrollItemsState?.map((payroll: Payroll) => {
    return (
      <PayrollCard
        key={payroll.payrollHistoryId}
        setEnablePreview={setEnablePreview}
        mobileView={mobileView}
        payrollDateLine={payroll}
        selected={selectedCard?.payrollHistoryId === payroll.payrollHistoryId}
        expanded={openIndex === payroll.payrollHistoryId}
        toggleExpanded={toggleExpanded}
        showMessageModal={() => {
          setShow((prevState) => {
            return {
              ...prevState,
              payrollMessageModal: true,
            };
          });
        }}
        selectCard={handleCardSelect}
      />
    );
  });
  
  const showPayrollModal = (passedViewType: 'Preview' | 'Submit') => {
    if (!selectedCard) return;
    
    const hasUnbalancedTransmittal = selectedCard.controlTotals?.find((controlTotal) => !controlTotal.balanced);
    if (hasUnbalancedTransmittal && !confirm(`One or more of this payroll's transmittals is out of balance. Continue to ${passedViewType?.toLowerCase()}?`)) return;

    return [
      dispatch(
        loadPayrollOptions(
          selectedCard.payrollHistoryId,
        ),
      ),
      dispatch(loadPayrollPayRateValidate(selectedCard.payrollHistoryId)),
      setViewType(passedViewType),
      setShow((prevState) => {
        return {
          ...prevState,
          submitPayroll: passedViewType === 'Submit',
          payrollPreview: passedViewType === 'Preview',
        };
      }),
    ];
  };
  
  return (
    <div className="payroll-card-list-wrapper">
      <div className={`action-button-row justify-content-${mobileView ? 'around' : 'between'}`}>
        <PayrollCardListActionBar
          type={type}
          selectedCard={selectedCard}
          hasWaitingDateline={hasWaitingDateline}
          isBalanced={!!isBalanced}
          enablePreview={enablePreview}
          onCheckRegister={onCheckRegister}
          setShow={setShow}
          reloadPayrolls={reloadPayrolls}
          showPayrollModal={showPayrollModal}
          handleDelete={handleDelete}
        />
      </div>
      <div className="process-payroll-card-list">
        {listData}
      </div>
      {show.payrollMessageModal && selectedCard  && client ? (
        <PayrollMessageModal
          payroll={selectedCard}
          client={client}
          show={show.payrollMessageModal}
          onHide={() => {
            return setShow((prevState) => {
              return {
                ...prevState,
                payrollMessageModal: false,
              };
            });
          }
          }
        />
      ) : null}
      {show.payrollInsert && client ? (
        <InsertPayrollModal
          client={client}
          prFrequencyOpts={prFrequencyOpts}
          show={show.payrollInsert}
          onHide={() => {
            return setShow((prevState) => {
              return {
                ...prevState,
                payrollInsert: false,
              };
            });
          }
          }
        />
      ) : null}
      {viewType && selectedCard && client && (show.payrollPreview || show.submitPayroll) ? (
        <PayrollOptionsModal
          type={viewType}
          show={
            viewType === 'Preview'
              ? show.payrollPreview
              : show.submitPayroll
          }
          onHide={() => {
            if (viewType === 'Preview') {
              setShow((prevState) => {
                return {
                  ...prevState,
                  payrollPreview: false,
                };
              });
            } else if (viewType === 'Submit') {
              setShow((prevState) => {
                return {
                  ...prevState,
                  submitPayroll: false,
                };
              });
            }
          }}
          client={client}
          selectedPayroll={selectedCard}
          changeViewType={setViewType}
          reloadPayrolls={reloadPayrolls}
        />
      ) : null}
      {show.registerReports ? (
        <Modal
          show={show.registerReports}
          onHide={() => {
            setShow((prevState) => {
              return { ...prevState,
                registerReports: false };
            }); 
          }}
          size="xl"
          backdrop="static"
          dialogClassName="h-100"
        >
          <Modal.Header closeButton>
            <Modal.Title>Check Register Reports</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: 'calc(100vh - 200px)' }}>
            <PayrollPreviewPdfModal checkRegister={true} />
          </Modal.Body>
        </Modal>
      ) : null}
      {show.recovery && selectedCard && client ? (
        <RecoveryModal
          show={show.recovery}
          onHide={() => {
            setShow((prevState) => {
              return {
                ...prevState,
                recovery: false,
              };
            }); 
          }}
          onSubmitRecovery={() => {
            setShow((prevState) => {
              return {
                ...prevState,
                recoverySubmitted: true,
              };
            });
            //Remove payroll from finished list to prevent user from clicking recover again. Not perfect since they can possibly reload page and have it show up again.
            //The recover dialog maybe should be synchronous if we find the recovery process not taking that long. Waiting 10s for internal users should be fine
            setpayrollItemsState((prevState) => { return prevState?.filter(x => { return x.payrollHistoryId !== selectedCard?.payrollHistoryId; }); });
          }}
          payrollHistoryId={selectedCard.payrollHistoryId}
          weekEnd={selectedCard.weekEnd}
          checkDate={selectedCard.checkDate}
          clientNo={client.clientNo}
        />
      ) : null }
      {show.recoverySubmitted && selectedCard && client ? (
        <RecoverySubmittedModal
          show={show.recoverySubmitted}
          onHide={() => {
            setShow((prevState) => {
              return {
                ...prevState,
                recoverySubmitted: false,
              };
            }); 
          }}
        />
      ) : null }
    </div>
  );
};

export default CardList;