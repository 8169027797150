import React, { useEffect, useRef, useState } from 'react';
import { EmployeeNavigatorAuditReportRequest } from 'core/models/HumanCapitalReports.model';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RadioGrp, RadioOptions } from 'core/components/form-controls/RadioGrp';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { Client } from 'core/models';
import { CommonService } from 'core/services';
import { clearEmployeeNavigatorAuditReport, downloadEmployeeNavigatorAuditReport } from 'core/store/actions';
import { useAppSelector } from 'utilities/hooks';
import { getReportOptions } from 'core/store/selectors';

const auditTypesOpts: RadioOptions[] = [
  {
    value: '0',
    label: 'Deduction Audit',
  },
  {
    value: '1',
    label: 'Demographic Audit',
  },
];

const EmployeeNavigatorAuditReport = () => {
  const dispatch = useDispatch();

  const childRef: React.RefObject<any> = useRef();

  const report = useAppSelector((state) => {return state.humanCapitalReports.employeeAuditNavigatorReport;});
  const client = useAppSelector((state) => { return state.client.client; }) as Client;
  const empNavReportOptions = useSelector(getReportOptions('EmployeeNavigatorReport'));
  
  const [fileType, setFileType] = useState<string>('excel');
  const [selected, setSelected] = useState('Deduction Audit');

  const { getValues, register } =
        useForm<EmployeeNavigatorAuditReportRequest>({
          defaultValues: {
            auditTypes: '0',
          },
        });

  const downloadReport = () => {
    const submitData: EmployeeNavigatorAuditReportRequest = {
      clientNo: client.clientNo,
      auditTypes: getValues('auditTypes'),
      reportType: 'pdf',
    };
    dispatch(downloadEmployeeNavigatorAuditReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearEmployeeNavigatorAuditReport());
  };

  useEffect(() => {
    if (empNavReportOptions?.showReport && report && fileType === 'excel') {
      CommonService.downloadBase64File(
        'application/excel',
        report,
        `EmpNavigator${selected.replace(/\s+/g, '')}.xlsx`,
      );
      dispatch(clearEmployeeNavigatorAuditReport());
    }
  }, [report]);

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <div className="dm-grid-title">
          Employee Navigator Audit Report
        </div>
        <hr className="dm-panel-hr" />

        <div className="d-flex flex-row flex-grow-1">
          <div className="d-flex ">
            <div>
              <RadioGrp
                radioOptions={auditTypesOpts}
                name="auditTypes"
                ref={register}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ) => {
                  const option = auditTypesOpts
                    .filter(
                      (option) => {
                        return option.value ===
                                                event.target.value;
                      },
                    )
                    .map((option) => {return option.label;});
                  setSelected(option[0] ?? '');
                }}
              />
            </div>
          </div>
          <div className="flex-grow-1 text-right">
            <button
              type="button"
              className="orange-outline-button mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="orange-button mr-2"
              onClick={downloadReport}
              disabled={!empNavReportOptions?.showReport}
            >
              Run Report
            </button>
          </div>
        </div>
      </div>
      {report ? (
        <PDFViewerModal
          show={report.length > 0}
          pdfData={report}
          onHide={hidePdfViewer}
          reportName="EmployeeNavigatorAuditReport"
        />
      ) : null}
    </div>
  );
};

export default EmployeeNavigatorAuditReport;
