import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { getACAProofListReport } from 'core/store/selectors';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { clearACAProofListReport, downloadACAProofListReport } from 'core/store/actions';
import { useAppDispatch } from 'utilities/hooks';
import { useForm } from 'react-hook-form';
import { InputGrp } from 'core/components/form-controls';
import { DateTime } from 'luxon';

const ACAProofListReport = () => {
  const dispatch = useAppDispatch();

  const report = useSelector(getACAProofListReport);
  const [year, setYear] = useState<number>(DateTime.local().year);

  const hidePdfViewer = () => {
    dispatch(clearACAProofListReport());
  };

  const { register, handleSubmit, errors } =
        useForm({
          defaultValues: {
            payrollYear: year,
          },
        });

  const changeSelection = (e: any) => {
    setYear(+e.target.value);
  };

  const downloadReport = (data: any) => {
    dispatch(downloadACAProofListReport(data.payrollYear));
  };

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)} >
          <div className="dm-grid-title">
            ACA Proof List
          </div>

          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div className="d-flex flex-column flex-grow-1">
              <div>
                <InputGrp
                  label="Proof List Year"
                  type="number"
                  className="mw150"
                  name="payrollYear"
                  ref={register({
                    required: true,
                    minLength: {
                      value: 4,
                      message: 'Year must be 4 numbers',
                    },
                    maxLength: {
                      value: 4,
                      message: 'Year must be 4 numbers',
                    },
                    valueAsNumber: true,
                  })}
                  onChange={changeSelection}
                  errors={errors.payrollYear}
                />
              </div>
              <div className=" text-left">

                <button
                  type="submit"
                  className="orange-button mr-2"
                >
                  Create ACA Proof
                </button>
              </div>


            </div>
          </div>
        </form>
        {report ? (
          <PDFViewerModal
            show={report.length > 0}
            pdfData={report}
            onHide={hidePdfViewer}
            reportName="ACAProofListReport"
          />
        ) : null}
      </div>
    </div>
  );
};


export default ACAProofListReport;