import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { InputGrp, SelectGrp } from '../../core/components/form-controls';
import { FieldInputSettings } from '../../core/components/form-controls/types';
import { EmployeeService } from '../../core/services';
import { loadNextEmployeeNumber } from '../../core/store/actions';
import { getNextEmpNo } from '../../core/store/selectors';

const fs: FieldInputSettings = {
  assignedEmpNo: {
    name: 'assignedEmpNo',
    label: 'ASSIGNED EMPLOYEE NUMBER',
    groupClass: 'groupClass40',
  },
  empNoOption: {
    name: 'empNoOption',
    label: 'EMPLOYEE NUMBER OPTIONS',
    groupClass: 'groupClass40',
  },
};

const empNoOptions = [
  {
    id: '1',
    description: 'Use Generated Emp No.',
  },
  {
    id: '2',
    description: 'Enter new Emp No.',
  },
];

type PropTypes = {
  show: boolean;
  onContinue: any;
  onHide: any;
};

const OnboardSelectEmpNoModal: React.FC<PropTypes> = ({ show, onContinue, onHide }) => {
  const [validated, setValidated] = useState(true);

  const dispatch = useDispatch();
  const { setValue, register, errors, watch, getValues } = useForm();
  const empNoOption = watch('empNoOption');
  const nextEmpNo = useSelector(getNextEmpNo);

  useEffect(() => {
    dispatch(loadNextEmployeeNumber());
  }, [dispatch]);

  useEffect(() => {
    if (nextEmpNo) {
      setValue('assignedEmpNo', nextEmpNo);
      setValidated(true);
    }

  }, [nextEmpNo]);

  const onValidate = () => {
    const employeeNumber = +getValues('assignedEmpNo');
    EmployeeService.getValidateEmployeeNo({
      employeeNumber,
      isValid: false,
    })
      .then((res: any) => {
        res?.data?.isValid ? setValidated(true) : setValidated(false);
      });
  };

  const onChangeEmpNoOptions = (e: any) => {
    if (e.target.value === '1') {
      dispatch(loadNextEmployeeNumber());
    } else {
      setValue('assignedEmpNo', 0);
    }
  };

  const closeModal = (e: any) => {
    e.stopPropagation();
    onHide();
  };

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        size="sm"
        animation={false}
      >
        <div className="modal-header" >
          <div className="dm-card-title">Select Employee Number</div>
          <button
            className="modal-close-btn" 
            onClick={closeModal}
          >
            <Icon name="times" />
          </button>
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <p>The current applicant would be assigned the following employee number:</p>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <InputGrp
              {...fs.assignedEmpNo}
              errors={errors.assignedEmpNo}
              ref={register}
            />
            <SelectGrp
              {...fs.empNoOption}
              errors={errors.empNoOption}
              options={empNoOptions}
              ref={register}
              onChange={onChangeEmpNoOptions}
            />
            {empNoOption === '2' && (
              <div className="groupClass20 pt-3">
                <button
                  type="button"
                  className="btn btn-primary orange-button ml-2"
                  onClick={onValidate}
                >Validate</button>
              </div>
            )}
          </div>
          {!validated && (<div
            className="alert alert-danger"
            role="alert"
            style={{
              padding: '5px 7px',
              marginBottom: 0,
            }}
          >
            Seems like you entered an invalid number. Please try again.
          </div>)}
          <div className="row mt-4">
            <div className="col-12 text-right">
              <button
                type="button"
                className="btn btn-primary orange-outline-button"
                onClick={closeModal}
              >Cancel</button>
              <button
                type="button"
                className="btn btn-primary orange-button ml-2"
                disabled={empNoOption === '2' && !validated}
                onClick={() => { return onContinue(+getValues('assignedEmpNo')); }}
              >Continue
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div >
  );
};
export default OnboardSelectEmpNoModal;
