import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../providers/authProvider';
import { persistor } from '../../store/store';
import RedirectSpinner from './RedirectSpinner';
import { handleError } from '../../store/actions';

export const LogoutCallback: React.FC = () => {
  const auth = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    async function logOutCallBackAsync() {
      await auth.signoutRedirectCallback();
    }
    persistor.pause();
    persistor.flush()
      .then(() => {return persistor.purge();})
      .catch((e: any) => {return handleError(e);});
    logOutCallBackAsync();
  }, [dispatch]);

  return <RedirectSpinner />;
};

export default LogoutCallback;
