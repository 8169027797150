import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { startCase } from 'lodash';
import { Button, Modal } from 'react-bootstrap';
import { DMDropdownMenu } from 'core/components/form-controls';
import { DMDropdownDataModel } from 'core/components/form-controls/DMDropDown';
import PdfViewer from 'core/components/shared/PDFViewer/PdfViewer';
import {
  AccrualReportType,
  ReportType,
} from 'core/models/AccrualReport.model';
import { clearReport } from 'core/store/actions/accrual-reports.action';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import AccrualReports from '../accrual-reports/accrual-reports';
import HamburgerMenu from 'core/components/shared/HamburgerMenu';

const reportsDD: DMDropdownDataModel = {
  id: 'reports-dd',
  title: 'Reports',
  items: [
    {
      id: 'reports-nav-1',
      text: 'Detail Report',
      href: '/',
      modal: true,
    },
    {
      id: 'reports-nav-2',
      text: 'Summary',
      href: '/',
      modal: true,
    },
    {
      id: 'reports-nav-3',
      text: 'Earn/Taken Detail',
      href: '/',
      modal: true,
    },
    {
      id: 'reports-nav-4',
      text: 'Earn/Taken Summary',
      href: '/',
      modal: true,
    },
    {
      id: 'reports-nav-5',
      text: 'Rollover Summary',
      href: '/',
      modal: true,
    },
    {
      id: 'reports-nav-6',
      text: 'Earn/Taken Balance',
      href: '/',
      modal: true,
    },
    {
      id: 'reports-nav-7',
      text: 'Summary for Selected Period',
      href: '/',
      modal: true,
    },
  ],
};

type EmployeeToolbarProps = {
  toggleSidebar: () => void;
  showSidebarMenuIcon: boolean;
  isMaster: boolean;
};

const EmployeeInformationAccrualsToolbar: React.FC<EmployeeToolbarProps> = ({
  toggleSidebar,
  showSidebarMenuIcon,
  isMaster,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [showAccrualReport, setShowAccrualReport] = useState(false);
  const [accrualReportType, setAccrualReportType] =
        useState<AccrualReportType | null>(null);

  const reportType = useAppSelector(
    (state) => {return state.accrualReports.reportType;},
  );
  const accrualReport = useAppSelector(
    (state) => {return state.accrualReports.report;},
  );

  const navArray: {
    type: string;
    text?: string;
    href?: string;
    data?: DMDropdownDataModel;
  }[] = [
    {
      type: 'link',
      text: 'Employee Earnings',
      href: '/employee-information/earnings',
    },
    {
      type: 'link',
      text: 'Employee Accruals',
      href: '/employee-information/accruals',
    },
    {
      type: 'dropdown',
      text: 'Reports',
      href: '/employee-upload',
      data: reportsDD,
    },
  ];

  useEffect(() => {
    if (accrualReportType) {
      setShowAccrualReport(accrualReportType.length > 0);
    }
  }, [accrualReportType]);

  if (isMaster) {
    navArray.push({
      type: 'dropdown',
      data: reportsDD,
    });
  }

  const onQuickOnboard = () => {return history.push('/quick-onboard');};

  const onMenuSelect = (menuId: string) => {
    switch (menuId) {
      case 'reports-nav-1':
        setAccrualReportType(AccrualReportType.DetailReport);
        break;
      case 'reports-nav-2':
        setAccrualReportType(AccrualReportType.SummaryReport);
        break;
      case 'reports-nav-3':
        setAccrualReportType(AccrualReportType.EarnTakenDetailReport);
        break;
      case 'reports-nav-4':
        setAccrualReportType(AccrualReportType.EarnTakenSummaryReport);
        break;
      case 'reports-nav-5':
        setAccrualReportType(AccrualReportType.RolloverSummaryReport);
        break;
      case 'reports-nav-6':
        setAccrualReportType(AccrualReportType.EarnTakenBalanceReport);
        break;
      case 'reports-nav-7':
        setAccrualReportType(
          AccrualReportType.SummaryForSelectedPeriodReport,
        );
        break;
    }
  };

  const nav = () => {
    if (showSidebarMenuIcon) {
      return (
        <HamburgerMenu
          toggleSidebar={toggleSidebar}  
        />
      );
    } else {
      return navArray.map((n, i) => {
        switch (n.type) {
          case 'link':
            return n.href ? (
              <NavLink
                key={i}
                to={n.href}
              >
                {n.text}
              </NavLink>
            ) : null;
          case 'dropdown':
            return n.data ? (
              <DMDropdownMenu
                key={i}
                data={n.data}
                toggleClass="dm-toolbar-nav"
                whiteChevron={true}
                onSelect={onMenuSelect}
              />
            ) : null;
          default:
            return null;
        }
      });
    }
  };

  const hideShowAccrualReportModal = () => {
    setAccrualReportType(null);
    dispatch(clearReport());
  };

  return (
    <div className="dm-toolbar">
      {nav()}
      <Button
        className="orange-button ml-auto mr-2"
        onClick={onQuickOnboard}
      >
        Quick Onboard
      </Button>
      {showAccrualReport && accrualReportType && (
        <Modal
          show={showAccrualReport}
          onHide={hideShowAccrualReportModal}
          size="sm"
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>
              Employee {startCase(accrualReportType)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AccrualReports accrualReportType={accrualReportType} />
          </Modal.Body>
        </Modal>
      )}
      {reportType &&
                accrualReportType &&
                reportType === ReportType.Pdf &&
                accrualReport.length > 0 && (
                <Modal
          show={
            reportType &&
                            reportType === ReportType.Pdf &&
                            accrualReport.length > 0
          }
          onHide={hideShowAccrualReportModal}
        >
          <Modal.Header closeButton={true}>
                    <Modal.Title>
              View/Download Employee{' '}
              {startCase(accrualReportType)}
            </Modal.Title>
                  </Modal.Header>
          <Modal.Body>
                    <PdfViewer
              pdfData={accrualReport}
              reportName="AccrualReport"
            />
                  </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default EmployeeInformationAccrualsToolbar;
