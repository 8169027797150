import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, ColDef, GridApi, GridOptions, SelectionChangedEvent } from '@ag-grid-enterprise/all-modules';
import { agCurrencyRenderer, agLookupRenderer } from '../../utilities/ag-grid-renderers';
import LinkRendererComponent from '../../utilities/ag-grid-renderers/LinkRendererComponent';
import { GlobalDeductionEmployee } from '../../core/models';

const buildColumnDefs = (dedFreqOpts: any, dedUnitOpts: any): ColDef[] => {
  return [
    {
      headerCheckboxSelection: true,
      headerTooltip: 'Select All',
      checkboxSelection: true,
      width: 40,
    },
    {
      field: 'empNo',
      headerName: 'Emp No',
      sortable: true,
      width: 75,
    },
    {
      field: 'name',
      headerName: 'Employee Name',
      sortable: true,
      cellRendererParams: { href: '/employee/detail/{protectedEmpNo}/snapshot', idProperty: 'protectedEmpNo' },
      cellRenderer: 'linkRendererComponent',
      width: 170,
    },
    {
      field: 'amount',
      headerName: 'Ded. Amount',
      sortable: true,
      cellRenderer: 'currencyRenderer',
      width: 105,
    },
    {
      field: 'freq',
      headerName: 'Frequency',
      sortable: true,
      cellRendererParams: { options: dedFreqOpts },
      cellRenderer: 'lookupRenderer',
      width: 100,
    },
    {
      field: 'unit',
      headerName: 'Unit',
      sortable: true,
      cellRendererParams: { options: dedUnitOpts },
      cellRenderer: 'lookupRenderer',
      width: 80,
    },
  ];
};

const gridOptions: GridOptions = {
  rowSelection: 'multiple',
  defaultColDef: {
    suppressMenu: true,
    resizable: true,
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
  // @ts-ignore
  components: {
    lookupRenderer: agLookupRenderer,
    currencyRenderer: agCurrencyRenderer,
  },
  // @ts-ignore
  frameworkComponents: {
    linkRendererComponent: LinkRendererComponent,
  },
};

type PropTypes = {
  employees: GlobalDeductionEmployee[],
  dedFreqOpts: any[],
  dedUnitOpts: any[]
};

const GlobalMasterWithDeductions: React.FC<PropTypes> = ({ employees, dedFreqOpts, dedUnitOpts }) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  useEffect(() => {
    gridApi && gridApi.setColumnDefs(buildColumnDefs(dedFreqOpts, dedUnitOpts));
  }, [gridApi]);

  const onGridReady = (params: any) => {return setGridApi(params.api);};

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    let selectedRows: any = [];
    if (gridApi) {
      selectedRows = gridApi.getSelectedRows();
      employees.forEach((e: any) => {
        e.selected = selectedRows?.find((r: any) => {return r.empNo === e.empNo;}) ? true : false;
      });
    }
  };

  return (
    <div className="dm-panel dm-panel-border" style={{ maxWidth: '700px' }}>
      <Row className="mb-2">
        <Col>
          <div className="dm-grid-title">Employees who have selected deduction</div>
          <div className="dm-grid-title-subtext">Mark boxes to apply changes to employee(s)</div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="table-wrapper-wrapper ag-theme-balham" style={{ height: '500px' }}>
          <AgGridReact
            gridOptions={gridOptions}
            rowData={employees}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            modules={AllModules} />
        </Col>
      </Row>
    </div>
  );
};

export default GlobalMasterWithDeductions;
