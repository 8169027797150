import React from 'react';
import { useSelector } from 'react-redux';
import { getDisplayOnTransmittal, getLocationDepartmentsWithoutHome, getNovatimeClockSettings } from 'core/store/selectors';
import { PayRate } from 'core/models';
import { currencyFormatter, ddLookup, getAllAccess } from 'utilities/utilities';
import { useAppSelector } from 'utilities/hooks';
import { ControlIds } from 'core/constants';

type PropTypes = {
  payRate: PayRate;
};

const tdStyles = {
  textAlign: 'right' as const,
  height: '25px',
  width: '100%',
};

const SnapshotPayRateItem: React.FC<PropTypes> = ({ payRate }) => {
  const { deptOpts, subdeptOpts, subdept2Opts } = useSelector(getLocationDepartmentsWithoutHome);
  const displayOnTransmittalOpts = useSelector(getDisplayOnTransmittal);
  const novatimeClockSettingOpts = useSelector(getNovatimeClockSettings);
  const sectionAccess = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections);
  
  const allPayRateAccessMap = getAllAccess(sectionAccess?.find((x) => x.workItemId === 2));

  const getDropdownValue = (locDeptSubType: string) => {
    if (locDeptSubType === 'Dept')
      return (!!payRate?.department && payRate?.department !== -1) ? ddLookup(payRate?.department, deptOpts, 'deptCode', 'deptDesc') : 'Use Home Department';
    else if (locDeptSubType === 'Sub')
      return (!!payRate?.subDepartment && payRate?.subDepartment !== -1) ? ddLookup(payRate.subDepartment, subdeptOpts, 'subDeptCode', 'subDeptDesc') : 'Use Home Sub Department';
    else
      return (!!payRate?.subDepartment2 && payRate?.subDepartment2 !== -1) ? ddLookup(payRate.subDepartment2, subdept2Opts, 'sub2Code', 'sub2Desc') : 'Use Home Sub Department 2';
  };

  return (
    <table className="w-100">
      <colgroup>
        <col style={{ width: '50%' }} />
        <col style={{ width: '35%' }} />
        <col style={{ width: '15%' }} />
      </colgroup>
      <tbody>
        <tr>
          <td className="dm-form-label-lg">DESCRIPTION</td>
          <td style={tdStyles}>
            {payRate?.description}
          </td>
        </tr>
        {(allPayRateAccessMap?.[ControlIds.annualRate]?.visible ?? true) && (
          <tr>
            <td className="dm-form-label-lg">ANNUAL</td>
            <td style={tdStyles}>
              {currencyFormatter(payRate?.annualRate)}
            </td>
          </tr>
        )}
        {(allPayRateAccessMap?.[ControlIds.salaryRate]?.visible ?? true) && (
          <tr>
            <td className="dm-form-label-lg">SALARY</td>
            <td style={tdStyles}>
              {currencyFormatter(payRate?.salaryRate)}
            </td>
          </tr>
        )}
        {(allPayRateAccessMap?.[ControlIds.hourlyRate]?.visible ?? true) && (
          <tr>
            <td className="dm-form-label-lg">HOURLY</td>
            <td style={tdStyles}>
              {currencyFormatter(payRate?.hourlyRate)}
            </td>
          </tr>
        )}
        <tr>
          <td className="dm-form-label-lg">DISPLAY </td>
          <td
            colSpan={2}
            style={tdStyles}
          >
            {ddLookup(payRate?.tranDigit, displayOnTransmittalOpts)}
          </td>
        </tr>
        <tr>
          <td className="dm-form-label-lg">CLOCK </td>
          <td
            colSpan={2}
            style={tdStyles}
          >
            {ddLookup(payRate?.clock, novatimeClockSettingOpts)}
          </td>
        </tr>
        {(allPayRateAccessMap?.[ControlIds.dept]?.visible ?? true) && deptOpts?.length > 0 && (
          <tr>
            <td className="dm-form-label-lg">DEPARTMENT </td>
            <td
              colSpan={2}
              style={tdStyles}
            >
              {getDropdownValue('Dept')}
            </td>
          </tr>
        )}
        {(allPayRateAccessMap?.[ControlIds.subDept]?.visible ?? true) && subdeptOpts?.length > 0 && (
          <tr>
            <td className="dm-form-label-lg">SUB DEPARTMENT </td>
            <td
              colSpan={2}
              style={tdStyles}
            >
              {getDropdownValue('Sub')}
            </td>
          </tr>
        )}
        {(allPayRateAccessMap?.[ControlIds.subDept2]?.visible ?? true) && subdept2Opts?.length > 0 && (
          <tr>
            <td className="dm-form-label-lg">SUB DEPARTMENT 2 </td>
            <td
              colSpan={2}
              style={tdStyles}
            >
              {getDropdownValue('Sub2')}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default SnapshotPayRateItem;
