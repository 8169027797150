import { createAction } from '@reduxjs/toolkit';
import { ClientMasterDtoTaxEntitiesClientTaxEntity, EmployeeTaxInfo, W4Info } from 'core/models';
import { TaxRequest } from 'core/services/tax.service';

export const TAX_EM_VALIDATION_ACTIONS = {
  UPDATE_TAXES: '[tax] UPDATE_TAXES',
} as const;

export const loadTaxes = createAction<TaxRequest>('[tax] LOAD_TAXES');
export const updateTaxes = createAction<TaxRequest>(TAX_EM_VALIDATION_ACTIONS.UPDATE_TAXES);
export const storeTaxes = createAction<EmployeeTaxInfo>('[tax] STORE_TAXES');

type TaxMessageResponse = {
  messages: string[];
  isSuccess: boolean;
};

export const storeTaxMessages = createAction<TaxMessageResponse>('[tax] STORE_TAX_MESSAGES');
export const clearTaxMessages = createAction('[tax] CLEAR_TAX_MESSAGES');

export const loadW4Info = createAction<TaxRequest>('[tax] LOAD_W4INFO');
export const storeW4Info = createAction<W4Info[]>('[tax] STORE_W4INFO');

export const loadCmTaxEntities = createAction('[tax] LOAD_CM_TAX_ENTITIES');
export const storeCmTaxEntities = createAction<ClientMasterDtoTaxEntitiesClientTaxEntity[]>('[tax] STORE_CM_TAX_ENTITIES');
