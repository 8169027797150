
import React, { Dispatch, SetStateAction } from 'react';
import { ColGroupDef, ValueSetterParams, ValueGetterParams, ColDef, ICellRendererParams, GridOptions, GetDetailRowDataParams, CellClassParams, GetRowIdParams } from '@ag-grid-enterprise/all-modules';
import Icon from 'core/components/shared/Icon';
import { Location, Department, SubDepartment, SubDepartment2, DeptAllocation, AllocationDetail } from 'core/models';
import { customAggregator, updateDetailRowValue, updateMasterRowValue } from 'features/corrections/utilities';
import { agDateEditor, agSelectEditor } from 'utilities/ag-grid-editors';
import { agDateRenderer, agLookupRenderer } from 'utilities/ag-grid-renderers';
import { ddLookup, convToDateString } from 'utilities/utilities';
import '../../corrections.scss';

export const defaultColumnDef: ColDef = {
  flex: 1,
  autoHeight: true,
  resizable: true,
  singleClickEdit: true,
  cellClass: 'ag-cell-left-border',
  headerClass: 'grid-header',
};

const generateSharedColumnsDefs = (
  locationOpts: Location[],
  deptOpts: Department[],
  subdeptOpts: SubDepartment[],
  subdept2Opts: SubDepartment2[],
): ColDef[] => {
  return [
    {
      field: 'transmittalLocation',
      headerName: 'Loc.',
      hide: !locationOpts.length,
      valueGetter: (params: ValueGetterParams) => {
        return ddLookup(+params.data.transmittalLocation, locationOpts, 'locationCode', 'locationDescWithCode');
      },
    },
    {
      field: 'transmittalDept',
      headerName: 'Dept.',
      hide: !deptOpts.length,
      valueGetter: (params: ValueGetterParams) => {
        return ddLookup(+params.data.transmittalDept, deptOpts, 'deptCode', 'departmentDescWithCode');
      },
    },
    {
      field: 'transmittalSub',
      headerName: 'Sub.',
      hide: !subdeptOpts.length,
      valueGetter: (params: ValueGetterParams) => {
        return ddLookup(+params.data.transmittalSub, subdeptOpts, 'subDeptCode', 'subDepartmentDescWithCode');
      },
    },
    {
      field: 'transmittalSub2',
      headerName: 'Sub. 2',
      hide: !subdept2Opts.length,
      valueGetter: (params: ValueGetterParams) => {
        return ddLookup(+params.data.transmittalSub2, subdept2Opts, 'sub2Code', 'subDepartment2DescWithCode');
      },
    },
  ];
};

export const generateMasterColumnGroupDefs = (
  locationOpts: Location[],
  deptOpts: Department[],
  subdeptOpts: SubDepartment[],
  subdept2Opts: SubDepartment2[],
  setRowData: Dispatch<SetStateAction<DeptAllocation[]>>,
  triggerDetailRowAdd: (deptAllocationId: number) => void,
  triggerMasterRowDelete: (deptAllocationId: number) => void,
): ColGroupDef[] => {
  return [
    {
      headerName: 'Effective Dates',
      children: [
        {
          field: 'beginDate',
          editable: true,
          cellRenderer: 'agGroupCellRenderer', // different from end date for detail row toggle
          cellEditor: 'dateEditor',
          valueGetter: (params: ValueGetterParams) => { // ... so we need this (see above comment)
            const { data }: { data: DeptAllocation } = params;
            if (!data.beginDate) {
              return 'Enter a Begin Date';
            }
            return convToDateString(new Date(data.beginDate));
          },
          valueSetter: (params: ValueSetterParams) => {
            if (params.newValue === '') return false;
            return updateMasterRowValue<DeptAllocation>(params, 'deptAllocationId', 'beginDate', setRowData);
          },
        },
        { field: 'endDate',
          editable: true,
          cellRenderer: 'agCellRenderer',
          cellEditor: 'dateEditor',
          valueGetter: (params: ValueGetterParams) => {
            const { data }: { data: DeptAllocation } = params;
            if (!data.endDate) {
              return 'Enter an End Date';
            }
            return convToDateString(new Date(data.endDate));
          },
          valueSetter: (params: ValueSetterParams) => {
            if (params.newValue === '') return false;
            return updateMasterRowValue<DeptAllocation>(params, 'deptAllocationId', 'endDate', setRowData);
          },
        },
      ],
    },
    {
      headerName: 'If Transmittal Entry is:',
      children: [...generateSharedColumnsDefs(locationOpts, deptOpts, subdeptOpts, subdept2Opts),
        {
          field: 'percent',
          headerName: 'Total %',
          aggFunc: customAggregator,
          valueGetter: (params: ValueGetterParams) => {
            //PI-8798 The backend will now send the percent as a percent to the front end. So we dont need the check to multiply it by 100 as it was causing issues.
            const { data: { allocationDetails } }: { data: DeptAllocation } = params;
            const percentSum: number = allocationDetails
              .map((detail) => {
                return detail.percent;
              })
              .reduce((accumulator, current) => {
                return accumulator + +current.toFixed(4);
              }, 0);
            
            return percentSum.toFixed(4);
          },
          cellStyle: (params: CellClassParams) => {
            return (+params.value !== 100) ? { backgroundColor: '#F9A7A7' } : null;
          },
        },
        {
          field: '',
          headerName: 'Delete',
          maxWidth: 100,
          cellRenderer: (params: ICellRendererParams) => {
            const { data }: { data: DeptAllocation } = params;
              
            const removeRecord = (_: React.BaseSyntheticEvent) => {
              if (!(data?.deptAllocationId && confirm('Delete allocation?'))) return;
              triggerMasterRowDelete(data.deptAllocationId);
            };
            
            return (
              <div className="ag-custom-button-cell">
                <button
                  className="custom-cell-icon remove-item"
                  onClick={removeRecord}
                >
                  <Icon name="minus-circle"/>
                </button>
                <span className="sr-only">Delete record</span>
              </div>
            );
          },
        },
        {
          field: '',
          headerName: 'Add detail',
          maxWidth: 100,
          cellRenderer: (params: ICellRendererParams) => {
            const { data }: { data: AllocationDetail } = params;
            
            const addAllocationDetail = (_: React.BaseSyntheticEvent) => {
              triggerDetailRowAdd(data.deptAllocationId);
            };
            
            return (
              <div className="ag-custom-button-cell">
                <button
                  className="custom-cell-icon fix"
                  onClick={addAllocationDetail}
                >
                  <Icon name="plus-circle"/>
                </button>
                <span className="sr-only">Add detail</span>
              </div>
            );
          },
        },
      ],
    },
  ];
};

export const generateDetailColumnGroupDefs = (
  locationOpts: Location[],
  deptOpts: Department[],
  subdeptOpts: SubDepartment[],
  subdept2Opts: SubDepartment2[],
  setRowData: Dispatch<SetStateAction<DeptAllocation[]>>,
  setIsDirty: Dispatch<SetStateAction<boolean>>,
  triggerDetailRowDelete: (deptAllocationId: number, detailId: number) => void,
): ColGroupDef[] => {
  return [
    {
      headerName: 'Allocation',
      children: [    
        {
          field: 'loc',
          headerName: 'Loc.',
          cellRenderer: 'lookupRenderer',
          hide: !locationOpts.length,
          cellRendererParams: {
            options: [
              {
                locationCode: '',
                locationDescWithCode: '',
              }, ...locationOpts],
            valueField: 'locationCode',
            labelField: 'locationDescWithCode',
          },
          cellEditor: 'selectEditor',
          valueSetter: (params: ValueSetterParams) => {
            if (params.newValue !== params.oldValue) setIsDirty(true);
            return updateDetailRowValue<DeptAllocation, AllocationDetail>(params, 'loc', setRowData);
          },
          editable: true,
          cellEditorParams: {
            field: 'loc',
            options: [
              {
                locationCode: '',
                locationDescWithCode: '',
              }, ...locationOpts],
            valueField: 'locationCode',
            labelField: 'locationDescWithCode',
          },
        },
        { field: 'dept',
          headerName: 'Dept.',
          cellRenderer: 'lookupRenderer',
          hide: !deptOpts.length,
          cellRendererParams: {
            options: [
              {
                deptCode: '',
                departmentDescWithCode: '',
              }, ...deptOpts],
            valueField: 'deptCode',
            labelField: 'departmentDescWithCode',
          },
          cellEditor: 'selectEditor',
          editable: true,
          cellEditorParams: { 
            options: [
              {
                deptCode: '',
                departmentDescWithCode: '',
              }, ...deptOpts],
            valueField: 'deptCode',
            labelField: 'departmentDescWithCode',
          },
          valueSetter: (params: ValueSetterParams) => {
            if (params.newValue !== params.oldValue) setIsDirty(true);
            return updateDetailRowValue<DeptAllocation, AllocationDetail>(params, 'dept', setRowData);
          },
        },
        {
          field: 'sub',
          headerName: 'Sub.',
          editable: true,
          cellRenderer: 'lookupRenderer',
          hide: !subdeptOpts.length,
          cellRendererParams: {
            options: [
              {
                subDeptCode: '',
                subDepartmentDescWithCode: '',
              }, ...subdeptOpts],
            valueField: 'subDeptCode',
            labelField: 'subDepartmentDescWithCode',
          },
          cellEditor: 'selectEditor',
          cellEditorParams: {
            options: [
              {
                subDeptCode: '',
                subDepartmentDescWithCode: '',
              }, ...subdeptOpts],
            valueField: 'subDeptCode',
            labelField: 'subDepartmentDescWithCode',
          },
          valueSetter: (params: ValueSetterParams) => {
            if (params.newValue !== params.oldValue) setIsDirty(true);
            return updateDetailRowValue<DeptAllocation, AllocationDetail>(params, 'sub', setRowData);
          },
        },
        {
          field: 'sub2',
          headerName: 'Sub. 2',
          editable: true,
          cellRenderer: 'lookupRenderer',
          hide: !subdept2Opts.length,
          cellRendererParams: {
            options: [
              {
                sub2Code: '',
                subDepartment2DescWithCode: '',
              }, ...subdept2Opts],
            valueField: 'sub2Code',
            labelField: 'subDepartment2DescWithCode',
          },
          cellEditor: 'selectEditor',
          cellEditorParams: {
            options: [
              {
                sub2Code: '',
                subDepartment2DescWithCode: '',
              }, ...subdept2Opts],
            valueField: 'sub2Code',
            labelField: 'subDepartment2DescWithCode',
          },
          valueSetter: (params: ValueSetterParams) => {
            if (params.newValue !== params.oldValue) setIsDirty(true);
            return updateDetailRowValue<DeptAllocation, AllocationDetail>(params, 'sub2', setRowData);
          },
        },
        {
          field: 'percent',
          headerName: '%',
          editable: true,
          valueGetter: (params: ValueGetterParams) => {
            const { data: { percent } }: { data: AllocationDetail } = params;
            const result = percent.toFixed(4);
            
            return result;
          },
          valueSetter: (params: ValueSetterParams) => {
            if (params.newValue !== params.oldValue) setIsDirty(true);
            return updateDetailRowValue<DeptAllocation, AllocationDetail>(params, 'percent', setRowData);
          },
        },
        {
          field: '',
          headerName: 'Delete',
          maxWidth: 100,
          cellRenderer: (params: ICellRendererParams) => {
            const { data }: { data: AllocationDetail } = params;
            
            const removeAllocationDetail = (_: React.BaseSyntheticEvent) => {
              if (!( data?.detailId && confirm('Delete allocation detail?'))) return;
              triggerDetailRowDelete(data.deptAllocationId, data.detailId);
            };
            
            return (
              <div className="ag-custom-button-cell">
                <button
                  className="custom-cell-icon remove-item"
                  onClick={removeAllocationDetail}
                >
                  <Icon name="minus-circle"/>
                </button>
                <span className="sr-only">Delete record</span>
              </div>
            );
          },
        },
      ],
    },
  ];
};

export const buildGridOptions = (masterColumns: ColGroupDef[], detailColumns: ColGroupDef[]): GridOptions => {
  return {
    suppressChangeDetection: false,
    immutableData: true,
    domLayout: 'autoHeight',
    columnDefs: masterColumns,
    masterDetail: true,
    defaultColDef: defaultColumnDef,
    components: {
      dateRenderer: agDateRenderer,
      dateEditor: agDateEditor,
    },
    getRowId: (params: GetRowIdParams) => {
      const { data }: { data: DeptAllocation } = params;
      return `${data?.deptAllocationId ?? ''}`;
    },
    detailCellRendererParams: {
      detailGridOptions: {
        domLayout: 'autoHeight',
        defaultColDef: defaultColumnDef,
        stopEditingWhenCellsLoseFocus: true,
        columnDefs: detailColumns,
        components: {
          lookupRenderer: agLookupRenderer,
          selectEditor: agSelectEditor,
        },
      },
      getDetailRowData(params: GetDetailRowDataParams) {
        const data: DeptAllocation = params?.data;
        params.successCallback(data?.allocationDetails ?? []);
      },
    },
  };
};