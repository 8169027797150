import React, { useContext } from 'react';
import { AccordionContext, useAccordionToggle } from 'react-bootstrap';
import Icon from './Icon';

export default function ContextAwareToggle({
  children,
  eventKey,
  callback,
  backgroundColor = '#bee7ff',
  className = 'mb-2 w-100 p-2 border border-dark',
}: any) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => { return callback && callback(eventKey); },
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      className={`${className}`}
      style={{ backgroundColor }}
      onClick={decoratedOnClick}
      role="button"
    >
      <div className="row no-gutters align-items-center">
        <div className="col-auto mx-2">
          <Icon name={isCurrentEventKey ? 'minus' : 'plus'} />
        </div>
        <div className="col">{children}</div>
      </div>
    </div>
  );
}
