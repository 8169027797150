import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getLocationDepartments } from '../../../core/store/selectors';
import ClientRateRuleItem from './ClientRateRuleItem';
import { deleteRateRule, loadRateRules } from '../../../core/store/actions';
import { getRateRules } from '../../../core/store/selectors/rate-rule.selector';
import { RateRule } from '../../../core/models';
import { useAppSelector } from '../../../utilities/hooks';
import Icon from 'core/components/shared/Icon';
import { useParams } from 'react-router-dom';
import { getAccess } from 'utilities/utilities';

const ClientRateRulesPage: React.FC<any> = () => {
  const dispatch = useDispatch();
  const clientNo = useAppSelector((state) => { return state.client.client!.clientNo; });
  const [rateRules, setRateRules] = useState<RateRule[]>([]);
  const [locDeptOpts, setLocDeptOpts] = useState({});
  const { protectedEmpNo } = useParams<any>();
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });

  const rateRulesFromStore = useSelector(getRateRules);
  const locationDepartmentOpts = useSelector(getLocationDepartments);
  const locationOptions = structuredClone(locationDepartmentOpts.locationOpts)?.filter(x => x.clientNo !== 0)
  const departmentOptions = structuredClone(locationDepartmentOpts.deptOpts)?.filter(x => x.clientNo !== 0)
  const subDeptOptions = structuredClone(locationDepartmentOpts.subdeptOpts)?.filter(x => x.clientNo !== 0)
  const subDept2Options = structuredClone(locationDepartmentOpts.subdept2Opts)?.filter(x => x.clientNo !== 0)

  useEffect(() => {
    dispatch(loadRateRules());
  }, [protectedEmpNo]);

  useEffect(() => {
    setLocDeptOpts({
      locationOpts: [
        {
          locationID: -1,
          locationDesc: '<All>'
        },
        {
          locationID: -2,
          locationDesc: '<No Location>'
        },
        ...locationOptions,
      ],
      deptOpts: [
        {
          deptId: -1,
          deptDesc: '<All>'
        },
        {
          deptId: -2,
          deptDesc: '<No Department>'
        },
        ...departmentOptions,
      ],
      subdeptOpts: [
        {
          subDeptCode: -1,
          subDeptDesc: '<All>'
        },
        {
          subDeptCode: -2,
          subDeptDesc: '<No Sub Department>'
        },
        ...subDeptOptions,
      ],
      subdept2Opts: [
        {
          sub2Code: -1,
          sub2Desc: '<All>'
        },
        {
          sub2Code: -2,
          sub2Desc: '<No Sub Department 2>'
        },
        ...subDept2Options,
      ],
    });
  }, [locationDepartmentOpts]);

  useEffect(() => {
    setRateRules(rateRulesFromStore);
  }, [rateRulesFromStore]);

  const addRateRule = () => {
    const newRateRule: RateRule = {clientNo: clientNo, locationId: '', deptId: '', subDeptId: '', subDept2Id: '',
                                  subId: 0, areaId: 0, rateId: parseInt((Math.random() * -10000).toString()), tradeId: 0, payRate: 0,};
    setRateRules((prev: RateRule[]) => {
      return [
        ...prev,
        newRateRule
      ];
    });
  };

  const onDelete = (data: RateRule) => {
    if (!confirm(`Are you sure you want to delete this Rate Rule? This action cannot be undone.`)) return;
    if(data.rateId > 0) dispatch(deleteRateRule(data))
    else setRateRules((prev: RateRule[]) => {
      return [
        ...prev.filter(x => x.rateId !== data.rateId),
      ];
    });;
  };

  return (
    <div
      className="dm-panel dm-panel-border"
      style={{ minHeight: '300px' }}
    >
      <div className="d-flex flex-wrap mt-4">
        <div className="dm-grid-title mr-3">Client Rate Rules</div>
        <div className="d-flex flex-wrap ml-auto mt-auto">
          <button
            {...getAccess(sectionAccess, 9, undefined, { disabledSameAsReadOnly: true })}
            type="button"
            className="btn btn-link dm-grid-action-title pb-0"
            onClick={addRateRule}
          >
            Add Client Rate Rule
            <Icon 
              name="plus-circle"
              className="fa-plus-circle" />
          </button>
        </div>
      </div>
      <hr className="dm-subpage-hr" />
      {rateRules.map((item: RateRule) => {
        return (
          <div key={item.rateId}>
            <ClientRateRuleItem
              item={item}
              locationDepartmentOpts={locDeptOpts}
              onDelete={onDelete}
            />
            <hr className="dm-panel-hr mt-0" />
          </div>
        );
      })}
    </div>
  );
};

export default ClientRateRulesPage;
