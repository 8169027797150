import { createReducer } from '@reduxjs/toolkit';
import {
  addUpdateDiagnosticRule,
  clearUploadErrors,
  deleteCostCodeInfo,
  deleteJobInfo,
  deleteSubInfo,
  deleteTimeCard,
  getAllJobs,
  getDiagnosticRule,
  getJobInfo,
  getTaxingCities,
  storeAllCostCodes,
  storeAllJobs,
  storeAllSubs,
  storeAllTimeCards,
  storeCostCodeInfo,
  storeDiagnosticRule,
  storeJobInfo,
  storeSubInfo,
  storeTaxingCities,
  storeTimeCard,
  storeUploadErrors,
  storeTimeCardDates,
  storeHomeAtsInfo,
  getAllTimeCards,
  storePrevTimeCardLink,
  storeSelectedPayrollInfo,
  storeTimeCardCrewSheetColumns,
  storeExpandedEmployees,
  storeTimeCardControlTotalDetail,
} from '../actions';
import { ExpandedEmployees, HomeAtsInfo, JobUploadError, PayrollTimeCardCostCodeInfo, PayrollTimeCardDiagnosticRules, PayrollTimeCardJobInfo, PayrollTimeCardSubInfo, TaxingCity, TimeCard, TimeCardCrewSheetColumns, TimeCardDate } from 'core/models/Contractor.model';
import { PayrollControlTotalDetail } from 'core/models/PayrollControlTotalDetail';

export type ContractorState = {
  loading: boolean;
  loadingTimeCards: boolean;
  uploadingFile: boolean;
  allJobs: PayrollTimeCardJobInfo[];
  currentJobInfo: PayrollTimeCardJobInfo | null;
  allSubs: PayrollTimeCardSubInfo[];
  currentSubInfo: PayrollTimeCardSubInfo | null;
  allCostCodes: PayrollTimeCardCostCodeInfo[];
  currentCostCodeInfo: PayrollTimeCardCostCodeInfo | null;
  diagnosticRules: PayrollTimeCardDiagnosticRules | null;
  jobUploadErrors: JobUploadError[];
  taxingCities: TaxingCity[];
  timeCards: TimeCard[];
  currentTimeCard: TimeCard | null;
  timeCardDates: TimeCardDate[];
  homeAtsInfo: HomeAtsInfo | null;
  prevTimeCardLink: string | null;
  timeCardCrewSheetColumns: TimeCardCrewSheetColumns | null;
  payrollInfo: { payrollHistoryId: number, weekEnd: string, checkDate: string } | null;
  expandedEmployees: ExpandedEmployees[];
  timeCardControlTotalDetails: PayrollControlTotalDetail[]
};

export const INITIAL_STATE: ContractorState = {
  loading: false,
  loadingTimeCards: false,
  uploadingFile: false,
  allJobs: [],
  currentJobInfo: null,
  allSubs: [],
  currentSubInfo: null,
  allCostCodes: [],
  currentCostCodeInfo: null,
  diagnosticRules: null,
  jobUploadErrors: [],
  taxingCities: [],
  timeCards: [],
  currentTimeCard: null,
  timeCardDates: [],
  homeAtsInfo: null,
  prevTimeCardLink: null,
  timeCardCrewSheetColumns: null,
  payrollInfo: null,
  expandedEmployees: [],
  timeCardControlTotalDetails: [],
};

const sortJobs = (job1: PayrollTimeCardJobInfo, job2: PayrollTimeCardJobInfo) => {
  if (job1.jobNo < job2.jobNo) return -1;
  if (job1.jobNo > job2.jobNo) return 1;
  return 0;
};

const sortSubs = (sub1: PayrollTimeCardSubInfo, sub2: PayrollTimeCardSubInfo) => {
  if (sub1.subNo < sub2.subNo) return -1;
  if (sub1.subNo > sub2.subNo) return 1;
  return 0;
};

const sortCostCodes = (sub1: PayrollTimeCardCostCodeInfo, sub2: PayrollTimeCardCostCodeInfo) => {
  if (sub1.costCode < sub2.costCode) return -1;
  if (sub1.costCode > sub2.costCode) return 1;
  return 0;
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAllJobs, (state) => {
      state.loading = true;
    })
    .addCase(storeAllJobs, (state, action) => {
      state.loading = false;
      state.allJobs = structuredClone(action.payload)?.sort(sortJobs);
    })
    .addCase(getJobInfo, (state) => {
      state.loading = true;
    })
    .addCase(storeJobInfo, (state, action) => {
      state.loading = false;
      state.currentJobInfo = action.payload;
      
      if (action.payload === null) return;
      
      const jobIndex = state.allJobs?.findIndex((job) => String(job.jobNo) === String(action?.payload?.jobNo));
      
      state.allJobs.splice(jobIndex, jobIndex > -1 ? 1 : 0, action.payload);
      
      const sortedJobs = state.allJobs?.sort(sortJobs);
      
      state.allJobs = sortedJobs;
    })
    .addCase(storeAllSubs, (state, action) => {
      state.loading = false;
      state.allSubs = structuredClone(action.payload)?.sort(sortSubs);
    })
    .addCase(storeSubInfo, (state, action) => {
      state.loading = false;
      state.currentSubInfo = action.payload;
      
      if (action.payload === null) return;
      
      const subIndex = state.allSubs?.findIndex((sub) => String(sub.subNo) === String(action?.payload?.subNo));
      
      state.allSubs.splice(subIndex, subIndex > -1 ? 1 : 0, action.payload);
      
      const sortedSubs = state.allSubs?.sort(sortSubs);
      
      state.allSubs = sortedSubs;
    })
    .addCase(deleteSubInfo.fulfilled, (state, action) => {
      state.allSubs = state.allSubs?.filter((sub) => sub.subNo !== action?.meta?.arg?.subNo);
    })
    .addCase(storeAllCostCodes, (state, action) => {
      state.allCostCodes = action.payload;
    })
    .addCase(storeCostCodeInfo, (state, action) => {
      state.currentCostCodeInfo = action.payload;
      
      if (action.payload === null) return;
      
      const costCodeIndex = state.allCostCodes?.findIndex((costCode) => String(costCode.costCode) === String(action?.payload?.costCode));
      
      state.allCostCodes.splice(costCodeIndex, costCodeIndex > -1 ? 1 : 0, action.payload);
      
      const sortedCostCodes = state.allCostCodes?.sort(sortCostCodes);
      
      state.allCostCodes = sortedCostCodes;
    })
    .addCase(deleteCostCodeInfo.fulfilled, (state, action) => {
      state.allCostCodes = state.allCostCodes?.filter((costCode) => costCode.costCode !== action?.meta?.arg?.costCode);
    })
    .addCase(getDiagnosticRule, (state) => {
      state.loading = true;
    })
    .addCase(addUpdateDiagnosticRule, (state) => {
      state.loading = true;
    })
    .addCase(storeDiagnosticRule, (state, action) => {
      state.loading = false;
      state.diagnosticRules = action.payload;
    })
    .addCase(storeUploadErrors, (state, action) => {
      state.loading = false;
      state.uploadingFile = false;
      state.jobUploadErrors = action.payload;
    })
    .addCase(deleteJobInfo, (state, action) => {
      state.allJobs = state.allJobs?.filter((job) => String(job.jobNo) !== String(action.payload.jobNo));
    })
    .addCase(getTaxingCities, (state) => {
      state.loading = true;
    })
    .addCase(storeTaxingCities, (state, action) => {
      state.loading = false;
      state.taxingCities = action.payload;
    })
    .addCase(clearUploadErrors, (state) => {
      state.jobUploadErrors = [];
    })
    .addCase(getAllTimeCards.pending, (state) => {
      state.loadingTimeCards = true;
    })
    .addCase(storeAllTimeCards, (state, action) => {
      state.loading = false;
      state.loadingTimeCards = false;
      state.timeCards = action.payload ?? [];
      state.currentTimeCard = action.payload[0];
    })
    .addCase(storeTimeCard, (state, action) => {
      state.loading = false;
      
      const timeCardIndex = state.timeCards?.findIndex((timeCard) => timeCard.transmittalTimeCardId === action?.payload?.transmittalTimeCardId);
      
      state.currentTimeCard = action?.payload ?? null;
      
      if (!action?.payload) return;
      if (timeCardIndex > -1) {
        state.timeCards.splice(timeCardIndex, 1, action.payload);
      } else {
        state.timeCards = [...(state.timeCards ?? []), action.payload];
      }
    })
    .addCase(storeTimeCardControlTotalDetail, (state, action) => {
      state.loading = false;      
      state.timeCardControlTotalDetails = action?.payload ?? [];
    })
    .addCase(deleteTimeCard.fulfilled, (state, action) => {
      state.loading = false;
      state.timeCards = state.timeCards?.filter((timeCard) => timeCard.transmittalTimeCardId !== action.meta?.arg?.transmittalTimeCardId) ?? [];
    })
    .addCase(storeTimeCardDates, (state, action) => {
      state.timeCardDates = action.payload;
    })
    .addCase(storeHomeAtsInfo, (state, action) => {
      state.homeAtsInfo = action.payload;
    })
    .addCase(storePrevTimeCardLink, (state, action) => {
      state.prevTimeCardLink = action.payload;
    })
    .addCase(storeSelectedPayrollInfo, (state, action) => {
      state.payrollInfo = action.payload;
    })
    .addCase(storeTimeCardCrewSheetColumns, (state, action) => {
      state.timeCardCrewSheetColumns = action.payload;
    })
    .addCase(storeExpandedEmployees, (state, action) => {
      state.expandedEmployees = action.payload;
    })
  ;
});