import { createReducer } from '@reduxjs/toolkit';
import { EmpDocument, TaxDocument } from '../../models';
import {
  loadEmpDocuments,
  loadTaxDocuments,
  storeEmpDocumentPDF,
  storeEmpDocuments,
  storeTaxDocumentPDF,
  clearTaxDocumentPDF,
  storeTaxDocuments,
  clearEmpDocumentPDF,
} from '../actions';

export interface State {
  loading: boolean;
  taxDocuments: TaxDocument;
  taxDocumentPDF: any;
  empDocuments: EmpDocument;
  empDocumentPDF: any;
}

export const INITIAL_STATE: State = {
  loading: false,
  taxDocuments: {
    i9Files: [],
    w2Files: [],
    w4Files: [],
  },
  taxDocumentPDF: null,
  empDocuments: {
    i9Files: [],
    hireToRetireFiles: [],
    onboardFiles: [],
    directDepositForms: [],
    employeeFiles: [],
  },
  empDocumentPDF: null,
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadTaxDocuments, (state) => {
      state.loading = true;
    })
    .addCase(storeTaxDocuments, (state, action) => {
      state.loading = false;
      state.taxDocuments = action.payload;
    })
    .addCase(storeTaxDocumentPDF, (state, action) => {
      state.taxDocumentPDF = action.payload;
    })
    .addCase(clearTaxDocumentPDF, (state) => {
      state.taxDocumentPDF = null;
    })
    .addCase(loadEmpDocuments, (state) => {
      state.loading = true;
    })
    .addCase(storeEmpDocuments, (state, action) => {
      state.loading = false;
      state.empDocuments = action.payload;
    })
    .addCase(storeEmpDocumentPDF, (state, action) => {
      state.empDocumentPDF = action.payload;
    })
    .addCase(clearEmpDocumentPDF, (state) => {
      state.empDocumentPDF = null;
    });
});
