import { createAction } from '@reduxjs/toolkit';
import { PayStub } from '../../models';
import { AddPasswordToPDF } from '../../models/AddPasswordToPdf.model';

export const loadPayStubs = createAction<string>('[payStub] LOAD_PAY_STUBS');
export const storePayStubs = createAction<PayStub[]>(
  '[payStub] STORE_PAY_STUBS',
);
export const loadPayStubPDF = createAction<PayStub>(
  '[payStub] LOAD_PAY_STUB_PDF',
);
export const storePayStubPDF = createAction<any>(
  '[payStub] STORE_PAY_STUB_PDF',
);
export const clearPayStubPDF = createAction(
  '[payStub] CLEAR_PAY_STUB_PDF',
);

export const updateAddPasswordToPDF = createAction<AddPasswordToPDF>(
  '[payStub] UPDATE_ADD_PASSWORD_TO_PDF',
);
export const storeAddPasswordToPDF = createAction<AddPasswordToPDF>(
  '[payStub] STORE_ADD_PASSWORD_TO_PDF',
);