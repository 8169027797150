import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OverviewSummarizedOptions } from 'core/enums/contractor-reports/overview';
import { IContractorOverviewDownload } from 'core/models/ContractorOverviewDownload.model';
import { CommonService } from 'core/services';
import { getContractorOverviewData, downloadContractorOverviewData } from 'core/store/actions/ contractor-reports/overview.action';
import { isArray, uniqueId } from 'lodash';

type SummarizeOptionValue = OverviewSummarizedOptions.JOB_NUMBER | OverviewSummarizedOptions.SUB_NUMBER | OverviewSummarizedOptions.PHASE | OverviewSummarizedOptions.FO_EXTRA | OverviewSummarizedOptions.COST_CODE;

export type Option = {
  label: string;
  value: SummarizeOptionValue;
};

const summarizeOptions: Option[] = [
  {
    label: 'Job Number',
    value: OverviewSummarizedOptions.JOB_NUMBER,
  },
  {
    label: 'Sub Number',
    value: OverviewSummarizedOptions.SUB_NUMBER,
  },
  {
    label: 'Phase',
    value: OverviewSummarizedOptions.PHASE,
  },
  {
    label: 'FO Extra',
    value: OverviewSummarizedOptions.FO_EXTRA,
  },
  {
    label: 'Cost Code',
    value: OverviewSummarizedOptions.COST_CODE,
  },
];

type FileTypes = 'csv' | 'xls';

export interface ContractorOverviewState {
  isLoading: boolean,
  isDownloading: boolean;
  summarizeOptions: Option[];
  summarizedBy: SummarizeOptionValue;
  showDetail: boolean;
  data?: IContractorOverviewDownload[];
  beginDate?: string | null;
  endDate?: string | null;
  byWeekend?: boolean;
  fileType: FileTypes;
}

const initialState: ContractorOverviewState = {
  isLoading: false,
  isDownloading: false,
  summarizeOptions,
  summarizedBy: summarizeOptions[0].value,
  showDetail: true,
  data: [],
  beginDate: null,
  endDate: null,
  byWeekend: true,
  fileType: 'xls',
};

export const contractorReportsOverviewSlice = createSlice({
  name: 'contractor-reports-overview',
  initialState,
  reducers: {
    toggleShowDetail(state: ContractorOverviewState) {
      state.showDetail = !state.showDetail;
    },
    setSummarizedBy(state: ContractorOverviewState, action: PayloadAction<SummarizeOptionValue>) {
      state.summarizedBy = action.payload;
    },
    setContractorOverviewDownloadDates(state: ContractorOverviewState, action: PayloadAction<string[]>) {
      const [beginDate, endDate] = action.payload;
      state.beginDate = beginDate;
      state.endDate = endDate;
    },
    setByWeekendOption(state: ContractorOverviewState, action: PayloadAction<boolean>) {
      state.byWeekend = action.payload;
    },
    setFileType(state: ContractorOverviewState, action: PayloadAction<FileTypes>) {
      state.fileType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContractorOverviewData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContractorOverviewData.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload && isArray(action.payload)) {

          state.data = action.payload.map(item => {
            item.id = uniqueId();
            return item;
          });
        }
      })
      .addCase(getContractorOverviewData.rejected, (state) => {
        state.isLoading = false;
        // create notification here
      });

    builder
      .addCase(downloadContractorOverviewData.pending, (state) => {
        state.isDownloading = true;
      })
      .addCase(downloadContractorOverviewData.fulfilled, (state, action) => {
        state.isDownloading = false;

        if (!action?.payload?.value) {
          return;
        }

        CommonService.downloadBase64File(
          `application/${state.fileType}`,
          action?.payload?.value,
          `contractor-overview-download${state.fileType === 'xls' ? '.xlsx' : '.csv'}`,
        );
      })
      .addCase(downloadContractorOverviewData.rejected, (state) => {
        state.isDownloading = false;
        // create notification here
      });
  },
});

export const { toggleShowDetail, setSummarizedBy, setByWeekendOption, setContractorOverviewDownloadDates, setFileType } = contractorReportsOverviewSlice.actions;

export default contractorReportsOverviewSlice.reducer;