import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InterviewProcess, InterviewTemplate, ProcessTemplate } from 'core/models';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams } from '@ag-grid-community/core';
import { InputGrp, SelectGrp, TextareaGrp } from 'core/components/form-controls';
import { agDateRenderer } from 'utilities/ag-grid-renderers';
import { postInterviewProcess, storeInterviewProcess } from 'core/store/slices/applicantTracking.slice';
import Modal from 'core/components/modals/Modal';
import ConfirmationModal from 'core/components/modals/confirmation.modal';

type Props = {
  show:boolean;  
  item: InterviewProcess | undefined;
  onHide: ()=> void;
};

const InterviewProcessModal: React.FC<Props> = ({
  show,
  item,
  onHide,
}) => {

  const dispatch = useAppDispatch();
  const [client, interviewTemplates] = useAppSelector(
    (state) => {
      return [
        state.client.client,
        state.applicantTracking.interviewTemplates];
    },
  );
  const [rowData, setRowData] = useState<ProcessTemplate[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridDirty, setGridDirty] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const { register, reset, handleSubmit, getValues, formState:{ isDirty } } = useForm<InterviewProcess>({
    defaultValues: { ...item,
      interviewProcessId: item?.interviewProcessId ?? 0,
      processTemplates: item?.processTemplates },
  });

  useEffect(() => {    
    item && reset(item);
    item && item.processTemplates && setRowData(item.processTemplates);
    setGridDirty(false);
  }, [item]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const getGridData = () => {
    const items: any[] = [];
    gridApi?.forEachNode((node) => {
      items.push(node.data);
    });
    return items;
  };

  const onAdd = () =>{
    const { templateId } = getValues() as any;
    const template = interviewTemplates.find(t => {return t.interviewTemplateId === +templateId;});
    const pTemplate: ProcessTemplate = {
      hrEntityId: client?.hrEntityId ?? 0,
      interviewProcessId: item?.interviewProcessId ?? 0,
      interviewTemplateId: templateId,
      templateTitle: template?.title ?? '',
      templateDescription: template?.description ?? '',
      interviewTypeId: template?.interviewTypeId ?? 0,
      interviewTypeDescription: template?.interviewTypeDescription ?? '',
      isDefault: false,
    };
    
    setRowData((prevState) => {
      return [ ...prevState,
        pTemplate,
      ];
    });
    setGridDirty(true);
  };

  const onDeleteTemplate = (params: ICellRendererParams) =>{
    setRowData((prevState) => {
      return prevState.filter(t => {return t.interviewTemplateId !== params.data.interviewTemplateId;});
    });
    setGridDirty(true);
  };

  const hideModal = () => {  
    if (isDirty || gridDirty) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
    dispatch(storeInterviewProcess(null));
  };

  const onSubmit = (data: any) => {
   
    const ip:InterviewProcess = item ? {
      ...item,        
      title: data.title,
      description: data.description,
      processTemplates: getGridData() as ProcessTemplate[],
    } : {
      interviewProcessId: 0,
      hrEntityId: client?.hrEntityId ?? 0,
      title: data.title,
      description: data.description,
      processTemplates: getGridData() as ProcessTemplate[],
      isDefault: false,
    };

    dispatch(postInterviewProcess(ip));

  };
  
  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
      dispatch(storeInterviewProcess(null));
    }
  };
  
  const columns = useMemo<ColDef[]>(
    () => {
      return [
        {
          field: 'stepNo',
          headerName: 'Step',  
          cellRenderer: (params: ICellRendererParams) => {
            return (<div>{params.rowIndex + 1}</div>);
          },    
          width: 60,    
        },
        {
          field: 'templateTitle',
          headerName: 'Template Name',      
          width: 150,    
        },
        {
          field: 'templateDescription',
          headerName: 'Template Description',
          width: 190,
        },
        {
          field: 'delete',
          cellRenderer: (params: ICellRendererParams) => {
            return (
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={() => { return onDeleteTemplate(params); }
                }
              >
                <Icon
                  name="minus-circle"
                  className="fa-minus-circle"
                />
              </button>             
            );
        
          },
          width: 70,
        },
      ];
    },
    [],
  );

  const gridOptions: GridOptions = {
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  return (
    
    <Modal
      show={show}
      onHide={hideModal}
      dialogClassName="lg" 
      isDirty={isDirty || gridDirty}     
      backdrop={'static'}
      title="Design Interview Process"
    >     
     
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="d-flex flex-column flex-grow-1"
        >  
          <input
            type="hidden"
            name={'interviewProcessId'}          
            ref={register({ valueAsNumber: true })}
          />      
          <div>
            <InputGrp
              label={'Process Name'}
              name={'title'}
              defaultValue={item?.title}
              ref={register}
            />
          </div>             
          <div>
            <TextareaGrp
              label={'Process Description'}
              name={'description'}
              defaultValue={item?.description}
              ref={register}
            />
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <SelectGrp
            name={'templateId'}
            label="Available Interview Templates"           
            groupStyle={{ width: '350px' }}
            options={interviewTemplates?.map((t:InterviewTemplate)=>{
              return { id:t.interviewTemplateId,
                description:t.title };
            })} 
            ref={register}          
          />
          <button
            type="button"
            className="btn btn-link dm-grid-action-title pb-0 mr-2"
            onClick={onAdd}
          >
            Add Template{' '}
            <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </div>
        <div className="d-flex mb-3">             
          <div className="table-wrapper-wrapper ag-theme-balham">
            <AgGridReact
              gridOptions={gridOptions}
              rowData={rowData}
              columnDefs={columns}
              onGridReady={onGridReady}
            />
          </div>
        </div>
        <div className="d-flex mt-3">
          <button
            type="button"
            className="orange-outline-button ml-auto mr-2"
            onClick={hideModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="orange-button mr-2"
            disabled={!(isDirty || gridDirty)}
          >
            Save
          </button>
        </div>
      </form>
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
    </Modal>
  );
};
export default InterviewProcessModal;
