import { createReducer } from '@reduxjs/toolkit';
import { Client, Employee, UserPassword } from '../../models';

import {
  hideRootModal,
  loadModuleAccess,
  storeModuleAccess,
  clearModuleAccess,
  loadAppData,
  loadRelatedClients,
  setHrButtonLink,
  showRootModal,
  storeAppEmpSearch,
  storeNext,
  storeRelatedClients,
  storeLoadState,
  loadAppEmpSearch,
  storeDefaultUserPassword,
  toggleBlockNavigation,
} from '../actions';
import { ModuleAccess } from 'core/models/ModuleAccess.model';

export interface State {
  loading: boolean;
  empSearchLoading: boolean;
  firstLoad: boolean;
  moduleAccess: ModuleAccess | null;
  moduleAccessLoading: boolean;
  appDataLoaded: boolean;
  employeeSearch: Employee[];
  modalType: string | null;
  modalProps: Object;
  next: string;
  hrButtonLink: string;
  relatedClients: Client[];
  defaultUserPassword: UserPassword | undefined;
  blockNavigation: { block: boolean, type?: 'confirmation' | 'notice' | 'directDeposit', message?: string };
}

const INITIAL_STATE: State = {
  loading: false,
  empSearchLoading: false,
  firstLoad: true,
  moduleAccessLoading: false,
  moduleAccess: null,
  appDataLoaded: false,
  employeeSearch: [] as Employee[],
  modalType: null,
  modalProps: {},
  next: '',
  hrButtonLink: '',
  relatedClients: [] as Client[],
  defaultUserPassword: undefined,
  blockNavigation: { block: false },
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(storeNext, (state, action) => {
      state.next = action.payload;
    })
    .addCase(loadAppEmpSearch, (state) => {
      state.empSearchLoading = true;
    })
    .addCase(storeAppEmpSearch, (state, action) => {
      state.empSearchLoading = false;
      state.employeeSearch = action.payload;
    })
    .addCase(storeDefaultUserPassword, (state, action) => {
      state.defaultUserPassword = action.payload;
    })
    .addCase(showRootModal, (state, action) => {
      state.modalType = action.payload.modalType;
      state.modalProps = action.payload.modalProps;
    })
    .addCase(hideRootModal, (state) => {
      state.modalType = null;
      state.modalProps = {};
    })
    .addCase(loadModuleAccess, (state) => {
      state.moduleAccessLoading = true;
    })
    .addCase(storeModuleAccess, (state, action) => {
      state.moduleAccessLoading = false;
      state.moduleAccess = action.payload;
    })
    .addCase(clearModuleAccess, (state) => {
      state.moduleAccess = null;
    })
    .addCase(storeLoadState, (state, action) => {
      state.firstLoad = action.payload;
    })
    .addCase(loadAppData, (state) => {
      state.appDataLoaded = true;
    })
    .addCase(setHrButtonLink, (state, action) => {
      state.hrButtonLink = action.payload;
    })
    .addCase(loadRelatedClients, (state) => {
      state.loading = true;
    })
    .addCase(storeRelatedClients, (state, action) => {
      state.loading = false;
      state.relatedClients = action.payload;
    })
    .addCase(toggleBlockNavigation, (state, action) => {
      state.blockNavigation = action.payload;
    })
  ;
});
