import React, { useState } from 'react';
import Modal from 'core/components/modals/Modal';
import { toggleEmFieldValidationModal } from 'core/store/actions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { useHistory, useParams } from 'react-router-dom';
import styles from './emp-detail.module.scss';
import { GET_EM_ROUTES } from 'core/constants';
import EmSectionErrorCard from './EmSectionErrorCard';
import { getMyInfoChanges } from 'core/store/selectors';
import { useSelector } from 'react-redux';
import MyInfoChangesModal from 'core/components/modals/MyInfoChanges.modal';

const sectionNameMap = {
  'EMPINFO': 'General Employee Information',
  'EMERGENCYCONTACT': 'Emergency Contacts',
  'DIRECTDEPOSIT': 'Direct Deposit',
  'DEDUCTIONS': 'Deductions',
  'PAYRATE': 'Pay Rates',
  'TAXES': 'Tax Information',
  'EMPACA': 'Employee ACA',
  'ACCRUAL': 'Accruals',
  'ATTENDANCEONDEMAND': 'Attendance On Demand',
  'RATERULES': 'Client Rate Rules',
  'COMPANYBENEFITS': 'Copmpany Benefits',
  'DATES': 'Dates',
  'DEFERREDCOMPENSATION': 'Deferred Compensation',
  'GROUPTERMLIFE': 'Group Term Life',
  'PAYROLLINFO': 'Payroll Information',
  'RECURRINGEARNINGS': 'Recurring Earnings',
  'SELFSERVICE': 'Self Service',
  'SPECIALINFO': 'Special Information',
  'VISASTATUS': 'Visa Status',
  'YTD': 'Year To Date',
};

const EmFieldValidationModal = () => {
  const {
    showEmFieldValidationModal,
    showMyInfoChangesModalBtn,
    currentErrors,
  } = useAppSelector(({ emFieldValidation }) => emFieldValidation);
  const myInfoChange = useSelector(getMyInfoChanges);
  
  const dispatch = useAppDispatch();
  
  const [showMyInfoChangesModal, setShowMyInfoChangesModal] = useState<boolean>(false);
  
  const history = useHistory();
  
  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();
  const emRoutes = GET_EM_ROUTES(protectedEmpNo);
  
  const hideModal = () => {
    dispatch(toggleEmFieldValidationModal(false));
  };
  
  const clickRoute = (path: string) => {
    history.push(path);
    dispatch(toggleEmFieldValidationModal(false));
  };
  
  return (
    <Modal
      show={showEmFieldValidationModal}
      onHide={hideModal}
      title="Missing Information/Pending Changes"
      size="sm"
      backdrop="static"
    >
      <div
        className={styles['emv-modal-body']}
        onKeyDown={(e) => {
          if (e.key === 'Tab') {
            e.preventDefault();
            e.stopPropagation();
            return;
          }
        }}
      >
        <p className={styles['emv-modal-notice-msg']}>
          <strong>Notice:</strong> The following information for this employee is missing or requires review.
        </p>
        {showMyInfoChangesModalBtn && (
          <>
            <EmSectionErrorCard
              section="MyInfo Changes"
              fields={undefined}
              path="#"
              clickRoute={() => { setShowMyInfoChangesModal(true); }}
            />
            {showMyInfoChangesModal && myInfoChange && (
              <MyInfoChangesModal
                validating
                myInfoChange={myInfoChange}
                show={showMyInfoChangesModal}
                onHide={() => { setShowMyInfoChangesModal(false); }}
              />
            )}
          </>
        )}
        <ul className={styles['emv-section-card-list']}>
          {currentErrors && Object.keys(currentErrors).map((key) => {
            if (!currentErrors?.[key as keyof typeof currentErrors]?.length) return null;
            
            return (
              <EmSectionErrorCard
                section={sectionNameMap[key.toUpperCase()  as keyof typeof sectionNameMap]}
                fields={currentErrors[key as keyof typeof currentErrors]}
                path={emRoutes[key.toUpperCase() as keyof typeof emRoutes]}
                clickRoute={clickRoute}
                key={key}
              />
            );
          })}
        </ul>
      </div>
    </Modal>
  );
};

export default EmFieldValidationModal;