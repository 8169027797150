import React, { useState, useContext } from 'react';
import { CheckboxGrpInLine } from 'core/components/form-controls';
import { FieldConfig, PageConfig } from 'core/models';
import { PageConfigsContext } from './pageConfigContext';

type PropTypes = {
  pageConfig: PageConfig;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
};

const OnboardFieldConfigItem: React.FC<PropTypes> = ({ pageConfig, setIsDirty }) => {
  const pageConfigContext = useContext(PageConfigsContext);
  const [required, setRequired] = useState(pageConfig.required);
  const [requiredI9, setRequiredI9] = useState(pageConfig.required);
  
  const I9PageId = 3;
  const EmergencyContactPageId = 6;
  
  const onChangeHandler = (value: any) => {
    pageConfigContext.updatePageConfig(pageConfig.pageID, +value.currentTarget.name, value.currentTarget.checked);
    pageConfigContext.setPageConfigContext({ ...pageConfigContext });
    setIsDirty(true);
  };

  const onRequiredChange = (e: any) => {
    pageConfigContext.updatePageConfigRequired(pageConfig.pageID, e.nativeEvent.target.checked);
    setRequired(e.nativeEvent.target.checked);
    setIsDirty(true);
  };
  
  const onRequiredI9FormChange = (e: any) => {
    pageConfigContext.updatePageConfigRequired(pageConfig.pageID, e.nativeEvent.target.checked);
    setRequiredI9(e.nativeEvent.target.checked);
    setIsDirty(true);
  };
  
  return (<>
    {pageConfig.pageID === EmergencyContactPageId && (
      <div className="pl-3 mt-3 mb-3">
        <CheckboxGrpInLine
          name="required"
          labelFirst={false}
          lgLabel={true}
          label="Require Emergency Contact"
          labelWidth={400}
          labelWidthUnit="px"
          checked={required}
          onChange={onRequiredChange}
        />
      </div>
    )}
    {pageConfig.pageID === I9PageId && (
      <div className="pl-3 mt-3 mb-3">
        <CheckboxGrpInLine
          name="requiredI9"
          labelFirst={false}
          lgLabel={true}
          label="Require I9 Form"
          labelWidth = {300}
          labelWidthUnit="px"
          checked={requiredI9}
          onChange={onRequiredI9FormChange}
        />
      </div>
    )}
    <div style={{ position: 'relative',
      height: '307px',
      overflow: 'auto' }}
    >
      <table style={{ width: '100%' }}>
        <colgroup>
          <col style={{ width: '80%' }} />
          <col style={{ width: '20%' }} />
        </colgroup>
        <thead>
          <tr style={{ background: 'lightgray',
            lineHeight: '30px',
            fontSize: '12px',
            fontWeight: 600 }}
          >
            <th style={{ paddingLeft: '16px' }}>Description</th>
            <th className="d-flex justify-content-center">Required</th>
          </tr>
        </thead>
        <tbody style={{ width: '100%' }}>
          {pageConfig.fieldConfigs.map((fc: FieldConfig) => {
            if (!fc.visible) return <></>;
            return (
              <tr
                key={fc.fieldID}
                style={{ borderBottom: '1px solid lightgray',
                  paddingTop: '5px' }}
              >
                <td>
                  <label
                    style={{ paddingTop: '6px',
                      paddingLeft: '16px',
                      marginBottom: '6px' }}
                    htmlFor={fc.fieldID.toString()}
                  >
                    {fc.description}
                  </label>
                </td>
                <td className="d-flex justify-content-center">
                  <input
                    style={{ margin: '9px 0' }}
                    type="checkbox"
                    id={fc.fieldID.toString()}
                    name={fc.fieldID.toString()}
                    checked={fc.required}
                    onChange={onChangeHandler}
                    readOnly = {(pageConfig.pageID === I9PageId) ? requiredI9 : false}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  </>);
};

export default OnboardFieldConfigItem;