import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { HttpResponse } from '../../models';
import { BankDisbursementsReportRequest, CARES_SBA_PPP_Request, EmployeeAverageHoursReportRequest, EmployeeAverageHoursWithStatusReportRequest, GeneralLedgerDetailReportRequest, GeneralLedgerExportReportRequest, GeneralLedgerReport, NewJobTrainingCreditReportRequest, HoursEarningsReportRequest, GeneralLedgerReportRequest, OverTimeSetting, GeneralLedgerExportResult } from '../../models/AccountingReports.model';
import { AccountingService } from '../../services/accounting-reports.service';

import { handleError, handleSuccess, handleWarning } from '../actions';
import {
  createOverTimeSettings,
  deleteOverTimeSettings,
  downloadBankDisbursementsReport,
  downloadHoursAndEarningsReport,
  loadOverTimeSettings,
  storeBankDisbursementsReport,
  storeOverTimeSettings,
  updateOverTimeSettings,
  downloadNewJobTrainingCreditReport,
  storeNewJobTrainingCreditReport,
  storeEmployeeAverageHoursWithStatusReport,
  downloadEmployeeAverageHoursWithStatusReport,
  storeEmployeeAverageHoursReport,
  downloadEmployeeAverageHoursReport,
  storeHoursAndEarningsReport,
  downloadGeneralLedgerReport,
  storeGeneralLedgerReport,
  downloadGeneralLedgerReportErrors,
  storeGeneralLedgerReportErrors,
  storeGeneralLedgerDetailReport,
  downloadGeneralLedgerExportReport,
  storeGeneralLedgerExportReport,
  downloadGeneralLedgerDetailReport,
  download_CARES_SBA_PPP_Report,
  store_CARES_SBA_PPP_Report,

} from '../actions/accounting-reports.action';

interface Actions {
  type: string;
  payload: any;
}

const downloadBankDisbursementReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadBankDisbursementsReport.type),
    switchMap((action: { payload: BankDisbursementsReportRequest; }) => {
      return from(AccountingService.postBankDisbursementsReportsTab(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeBankDisbursementsReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadEmployeeAverageHoursReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadEmployeeAverageHoursReport.type),
    switchMap((action: { payload: EmployeeAverageHoursReportRequest; }) => {
      return from(AccountingService.postEmployeeAverageHoursReportTab(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeEmployeeAverageHoursReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};


const downloadEmployeeAverageHoursWithStatusReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadEmployeeAverageHoursWithStatusReport.type),
    switchMap((action: { payload: EmployeeAverageHoursWithStatusReportRequest; }) => {
      return from(AccountingService.postEmployeeAverageHoursWithStatusReportTab(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeEmployeeAverageHoursWithStatusReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadNewJobTrainingCreditReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadNewJobTrainingCreditReport.type),
    switchMap((action: { payload: NewJobTrainingCreditReportRequest; }) => {
      return from(AccountingService.postNewJobTrainingCreditReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeNewJobTrainingCreditReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadHoursAndEarningsReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadHoursAndEarningsReport.type),
    switchMap((action: { payload: HoursEarningsReportRequest; }) => {
      return from(AccountingService.postHoursEarningsReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeHoursAndEarningsReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadOverTimeSettings$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadOverTimeSettings.type),
    switchMap((action: any) => {
      return from(AccountingService.getOverTimeSettings()).pipe(
        map((res: any) => { return res.data; }),
        map((res: OverTimeSetting[]) => { return storeOverTimeSettings(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createOverTimeSettings$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createOverTimeSettings.type),
    switchMap((action: { payload: OverTimeSetting; }) => {
      return from(AccountingService.postOverTimeSettings(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<OverTimeSetting>) => {
          return [
            loadOverTimeSettings(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateOverTimeSettings$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateOverTimeSettings.type),
    switchMap((action: { payload: OverTimeSetting; }) => {
      return from(AccountingService.putOverTimeSettings(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<OverTimeSetting>) => {
          return [
            loadOverTimeSettings(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteOverTimeSettings$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteOverTimeSettings.type),
    switchMap((action: { payload: OverTimeSetting; }) => {
      return from(AccountingService.deleteOverTimeSettings(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<OverTimeSetting>) => {
          return [
            loadOverTimeSettings(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadGeneralLedgerReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadGeneralLedgerReport.type),
    switchMap((action: { payload: GeneralLedgerReportRequest; }) => {
      return from(AccountingService.postGeneralLedgerReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<GeneralLedgerReport>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeGeneralLedgerReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadGeneralLedgerReportErrors$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadGeneralLedgerReportErrors.type),
    switchMap((action: { payload: GeneralLedgerReportRequest; }) => {
      return from(AccountingService.postGeneralLedgerReportErrors(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          return [
            storeGeneralLedgerReportErrors(res.value),
          ];
        },
        ),
      );
    },
    ),
  );
};

const downloadGeneralLedgerDetailReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadGeneralLedgerDetailReport.type),
    switchMap((action: { payload: GeneralLedgerDetailReportRequest; }) => {
      return from(AccountingService.postGeneralLedgerDetailReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeGeneralLedgerDetailReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadGeneralLedgerExportReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadGeneralLedgerExportReport.type),
    switchMap((action: { payload: GeneralLedgerExportReportRequest; }) => {
      return from(AccountingService.postGeneralLedgerExportReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<GeneralLedgerExportResult[]>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeGeneralLedgerExportReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const download_CARES_SBA_PPP_Report$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(download_CARES_SBA_PPP_Report.type),
    switchMap((action: { payload: CARES_SBA_PPP_Request; }) => {
      return from(AccountingService.post_CARES_SBA_PPP_Report(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            store_CARES_SBA_PPP_Report(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  downloadBankDisbursementReport$,
  downloadHoursAndEarningsReport$,
  downloadEmployeeAverageHoursReport$,
  downloadEmployeeAverageHoursWithStatusReport$,
  loadOverTimeSettings$,
  createOverTimeSettings$,
  updateOverTimeSettings$,
  deleteOverTimeSettings$,
  downloadNewJobTrainingCreditReport$,
  downloadGeneralLedgerReport$,
  downloadGeneralLedgerReportErrors$,
  downloadGeneralLedgerDetailReport$,
  downloadGeneralLedgerExportReport$,
  download_CARES_SBA_PPP_Report$,
];
