import React from 'react';
import { BambooHRComparison } from 'core/models/HumanCapitalReports.model';
import { IHeaderParams, RowNode } from '@ag-grid-enterprise/all-modules';

const AgSelectAllBambooHeader = (params: IHeaderParams, rowData:BambooHRComparison[]) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    params.api.forEachNode((node: RowNode) => {      
      const row = rowData.find(r => {return r.bambooId === node.data.bambooId;}) as BambooHRComparison;
      const canShow = Object.entries(row).find(r=> {return r[0]  === params.column.getColId();})?.[1];
      canShow ? node.setDataValue(params.column.getColId(), e.target.checked) : null;
    });
  };

  return (
    <div>
      <input
        type="checkbox"
        onChange={onChange}
        id={`check${params.column.getColId()}`}
        className="mr-1"
        defaultChecked={true}
      />
      <label htmlFor={`check${params.column.getColId()}`}>
        {params.displayName}&nbsp;
      </label>
    </div>
  );
};

export default AgSelectAllBambooHeader;
