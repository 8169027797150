import { createContext } from 'react';

export type FormInfo = {
  triggerSave: boolean;
};

export type EarningsErrorInfo = {
  checkIndex: number;
  empNo: number;
  earningsItemId: number;
};

/* The name of this is slightly misleading. Really this is just for managing all context related to the transmittal page. 
A lot of the more complex logic in here (and the transmittal in general) is because of our "autosave" feature. */
export type FormContextType = {
  /* triggerSave essentially indicates if a save has been manually triggered. Other components can then read this and 
  do whatever they need to do (for example, disable something while the "autosave" is happening) */
  formInfo: FormInfo;
  /* Any active earnings error on the transmittal (e.g., saving a check with a missing earnings code) */
  earningsError: EarningsErrorInfo;
  /* indicates whether or not we have an unsaved check */
  dirtyCheck: boolean;
  /* Ref to ID of active element, if any */
  activeElement: string | null;
  /* Setter for active element */
  updateActiveElement: (id: string | null) => void;
  /* Setter for the above property */
  setEarningsErrorCallback: (newValue: EarningsErrorInfo) => void;
  /* Setter to indicate that there is a check with unsaved changes in the transmittal */
  setDirtyCheck: (newValue: boolean) => void;
  /* Callback to set triggerSave; this will kick off an "autosave" (not to be confused with our regular save, which
  relies on blur events on the check inputs). */
  manualSaveTriggerCallback: (newValue: boolean) => void;
};

export const FormContext = createContext<FormContextType>({
  formInfo: {
    triggerSave: false,
  },
  earningsError: {
    empNo: -1,
    checkIndex: -1,
    earningsItemId: 0,
  }, 
  dirtyCheck: false,
  activeElement: null,
  setEarningsErrorCallback: (_newValue: EarningsErrorInfo) => { },
  setDirtyCheck: (_newValue: boolean) => { },
  manualSaveTriggerCallback: (_newValue: boolean) => { },
  updateActiveElement: (_id: string | null) => { },
});
