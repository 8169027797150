import { AnyAction, CombinedState, Reducer, combineReducers } from 'redux';

import * as fromDownloader from '../slices/downloader.slice';
import * as fromCmUsers from '../slices/cmUsers.slice';
import * as fromFile from '../slices/file.slice';
import * as fromHr from '../slices/hr.slice';
import * as fromPayRateReports from '../slices/payRateReports.slice';
import * as fromContractorReports from '../slices/contractorReports.slice';
import * as fromContractorReportsOverview from '../slices/contractor-reports/overview.slice';
import * as fromACAFullTimeReportsOverview from '../slices/human-capital-management/aca-full-time-calculation.slice';
import * as fromEmployeeEarningsDownload from '../slices/employee-earnings-download.slice';
import * as fromContractorFringeDownload from '../slices/contractor-reports/fringe.slice';
import * as fromReportDownloader from '../slices/reportDownloader.slice';
import * as fromBambooHR from '../slices/human-capital-reports/bambooHr.slice';
import * as fromAccrualReports from './accrual-reports.reducer';
import * as fromAccrual from './accrual.reducer';
import * as fromApClientVendor from './ap-client-vendor.reducer';
import * as fromApp from './app.reducer';
import * as fromAttendanceOnDemand from './attendance-on-demand.reducer';
import * as fromAuth from './auth.reducer';
import * as fromCheckCalculator from './check-calculator.reducer';
import * as fromCheckCode from './check-code.reducer';
import * as fromClient from './client.reducer';
import * as fromContact from './contact.reducer';
import * as fromContractorOptions from './contractor-options.reducer';
import * as fromDashboard from './dashboard.reducer';
import * as fromDeduction from './deduction.reducer';
import * as fromDeferredComp from './deferred-compensation.reducer';
import * as fromDocument from './document.reducer';
import * as fromDropdown from './dropdown.reducer';
import * as fromEarningCode from './earning-code.reducer';
import * as fromEarningsRecords from './earnings-records.reducer';
import * as fromEarningsReports from './earnings-reports.reducer';
import * as fromEEO from './eeo.reducer';
import * as fromEmpACA from './emp-aca.reducer';
import * as fromEmpDocComp from './emp-doc-comp.reducer';
import * as fromEmpFte from './emp-fte.reducer';
import * as fromEmpGroupTerm from './emp-group-term.reducer';
import * as fromEmpI9 from './emp-i9.reducer';
import * as fromEmpSelfService from './emp-self-service.reducer';
import * as fromEmployeeInformation from './employee-information.reducer';
import * as fromEmployeeReports from './employee-reports.reducer';
import * as fromAccountingReports from './accounting-reports.reducer';
import * as fromHumanCapitalReports from './human-capital.reducer';
import * as fromEmployeeUpload from './employee-upload.reducer';
import * as fromEmployee from './employee.reducer';
import * as fromEmployees from './employees.reducer';
import * as fromGlobalDeduction from './global-deduction.reducer';
import * as fromHireHistory from './hire-history.reducer';
import * as fromHrEmployeeinfo from './hr-employee.reducer';
import * as fromMyInfoChanges from './my-info-changes.reducer';
import * as fromOnboard from './onboard.reducer';
import * as fromOtherDate from './other-date.reducer';
import * as fromPayRate from './pay-rate.reducer';
import * as fromPayStub from './pay-stub.reducer';
import * as fromPayrollAdjustment from './payroll-adjustment.reducer';
import * as fromPayrollInfo from './payroll-info.reducer';
import * as fromPayrollUpload from './payroll-upload.reducer';
import * as fromPayroll from './payroll.reducer';
import * as fromPayrollReports from './payroll-reports.reducer';
import * as fromPayrollsUSerOptions from './payrolls-user-options.reducer';
import * as fromRateRule from './rate-rule.reducer';
import * as fromRecurringEarnings from './recurring-earnings.reducer';
import * as fromSalesTaxTransmittal from './sales-tax-transmittal.reducer';
import * as fromSpecialInfo from './special-info.reducer';
import * as fromSso from './sso.reducer';
import * as fromSupervisedEmployee from './supervised-employee.reducer';
import * as fromTax from './tax.reducer';
import * as fromTimeOff from './time-off.reducer';
import * as fromTransfer from './transfer.reducer';
import * as fromVisaStatus from './visa-status.reducer';
import * as fromWorkerComp from './workerComp.reducer';
import * as fromYearToDate from './year-to-date.reducer';
import * as fromClientLibrary from './client-library.reducer';
import * as fromReferenceGuide from './help-reference-guide.reducer';
import * as fromApplicantTracking from '../slices/applicantTracking.slice';
import * as fromApplicantTrackingV2 from '../slices/applicant-tracking-v2.slice';
import * as fromCorrections from './corrections.reducer'; // make this a slice
import * as fromIntegration from './integration.reducer';
import * as fromContractors from './contractor.reducer';
import * as fromIntuit from './intuit/intuit.reducer';
import * as fromEmFieldValidation from './em-field-validation.reducer';
import * as fromWorkFlow from '../slices/work-flow.slice';

export const selEmployeeDetailsReducer = combineReducers({
  accruals: fromAccrual.reducer,
  attendanceOnDemand: fromAttendanceOnDemand.reducer,
  checkCalculator: fromCheckCalculator.reducer,
  checkCode: fromCheckCode.reducer,
  contact: fromContact.reducer,
  contractorOptions: fromContractorOptions.reducer,
  deduction: fromDeduction.reducer,
  deferredComp: fromDeferredComp.reducer,
  document: fromDocument.reducer,
  earningsRecords: fromEarningsRecords.reducer,
  empACA: fromEmpACA.reducer,
  empDocComp: fromEmpDocComp.reducer,
  empFte: fromEmpFte.reducer,
  empGroupTerm: fromEmpGroupTerm.reducer,
  empI9: fromEmpI9.reducer,
  empSelfService: fromEmpSelfService.reducer,
  hireHistory: fromHireHistory.reducer,
  otherDate: fromOtherDate.reducer,
  payRate: fromPayRate.reducer,
  PayrollAdjustment: fromPayrollAdjustment.reducer,
  payrollInfo: fromPayrollInfo.reducer,
  payrollsUserOptions: fromPayrollsUSerOptions.reducer,
  payStub: fromPayStub.reducer,
  recurringEarnings: fromRecurringEarnings.reducer,
  specialInfo: fromSpecialInfo.reducer,
  supervisedEmployee: fromSupervisedEmployee.reducer,
  visaStatus: fromVisaStatus.reducer,
  ytd: fromYearToDate.reducer,
  integration: fromIntegration.reducer,
  contractor: fromContractors.reducer,
  intuit: fromIntuit.reducer,
  emFieldValidation: fromEmFieldValidation.reducer,
});

export interface CoreState {
  app: fromApp.State;
  applicantTracking: fromApplicantTracking.ApplicantTrackingState,
  auth: fromAuth.AuthState;
  client: fromClient.ClientState;
  corrections: fromCorrections.CorrectionState;
  apClientVendor: fromApClientVendor.State;
  dashboard: fromDashboard.State;
  dropdown: fromDropdown.State;
  transfer: fromTransfer.State;
  onboard: fromOnboard.State;
  empUpload: fromEmployeeUpload.State;
  globalDeduction: fromGlobalDeduction.State;
  eeo: fromEEO.State;
  workerComp: fromWorkerComp.State;
  rateRule: fromRateRule.State;
  earningCode: fromEarningCode.EarningCodeState;
  payrollUpload: fromPayrollUpload.State;
  employees: fromEmployees.State;
  selEmployee: fromEmployee.State;
  employeeReports: fromEmployeeReports.State;
  accountingReports: fromAccountingReports.State;
  humanCapitalReports: fromHumanCapitalReports.State;
  payroll: fromPayroll.State;
  payrollAdjustment: fromPayrollAdjustment.State;
  payrollReports: fromPayrollReports.State;
  clientLibrary: fromClientLibrary.State;
  referenceGuide: fromReferenceGuide.State;
  selEmployeeDetails: {
    accruals: fromAccrual.State;
    attendanceOnDemand: fromAttendanceOnDemand.State;
    checkCalculator: fromCheckCalculator.State;
    checkCode: fromCheckCode.State;
    contact: fromContact.State;
    contractorOptions: fromContractorOptions.State;
    deduction: fromDeduction.State;
    deferredComp: fromDeferredComp.State;
    document: fromDocument.State;
    earningsRecords: fromEarningsRecords.State;
    empACA: fromEmpACA.State;
    empDocComp: fromEmpDocComp.State;
    empFte: fromEmpFte.State;
    empGroupTerm: fromEmpGroupTerm.State;
    empI9: fromEmpI9.State;
    empSelfService: fromEmpSelfService.State;
    hireHistory: fromHireHistory.State;
    payRate: fromPayRate.State;
    otherDate: fromOtherDate.State;
    payrollInfo: fromPayrollInfo.State;
    payrollsUserOptions: fromPayrollsUSerOptions.State;
    payStub: fromPayStub.State;
    recurringEarnings: fromRecurringEarnings.State;
    specialInfo: fromSpecialInfo.State;
    supervisedEmployee: fromSupervisedEmployee.State;
    visaStatus: fromVisaStatus.State;
    ytd: fromYearToDate.State;
  };
  myInfoChanges: fromMyInfoChanges.MyInfoChangeLogState;
  tax: fromTax.TaxState;
  salesTaxTransmittal: fromSalesTaxTransmittal.State;
  sso: fromSso.SsoState;
  timeOff: fromTimeOff.TimeOffState;
  earningsReports: fromEarningsReports.EarningsReportsState;
  hrEmployeeInfo: fromHrEmployeeinfo.HrEmployeeInfoState;
  hr: fromHr.HrState;
  payRateReports: fromPayRateReports.PayRateReportsState;
  file: fromFile.FileState;
  cmUsers: fromCmUsers.CmUsersState;
  reportDownloader: fromReportDownloader.ReportsDownloaderState,
  contractReports: fromContractorReports.ContractorReportsState;
  downloader: fromDownloader.DownloaderState;
  bambooHrReports: fromBambooHR.BambooHRState;
  integration: fromIntegration.IntegrationState;
  contractor: fromContractors.ContractorState;
  intuit: fromIntuit.IntuitState;
  emFieldValidation: fromEmFieldValidation.EmFieldValidationState;
}

export const appState = (state: CoreState) => { return state.app; };
export const authState = (state: CoreState) => { return state.auth; };
export const clientState = (state: CoreState) => { return state.client; };
export const apClientVendorState = (state: CoreState) => { return state.apClientVendor; };
export const applicantTrackingState = (state: CoreState) => { return state.applicantTracking; };

export const dashboardFeatureState = (state: CoreState) => { return state.dashboard; };
export const dropdownFeatureState = (state: CoreState) => { return state.dropdown; };
export const transferState = (state: CoreState) => { return state.transfer; };
export const onboardState = (state: CoreState) => { return state.onboard; };
export const employeeUploadState = (state: CoreState) => { return state.empUpload; };
export const globalDeductionState = (state: CoreState) => { return state.globalDeduction; };
export const eeoState = (state: CoreState) => { return state.eeo; };
export const workerCompState = (state: CoreState) => { return state.workerComp; };
export const rateRuleState = (state: CoreState) => { return state.rateRule; };
export const employeesFeatureState = (state: CoreState) => { return state.employees; };
export const hrEmployeeInfoState = (state: CoreState) => { return state.hrEmployeeInfo; };
export const accountingReportState = (state: CoreState) => { return state.accountingReports; };
export const earningsReportState = (state: CoreState) => { return state.earningsReports; };
export const humanCapitalReportState = (state: CoreState) => { return state.humanCapitalReports; };
export const payRateReportState = (state: CoreState) => { return state.payRateReports; };
export const clientLibraryState = (state: CoreState) => { return state.clientLibrary; };
export const referenceGuideState = (state: CoreState) => { return state.referenceGuide; };

export const selEmployeeFeatureState = (state: CoreState) => { return state.selEmployee; };

export const employeeReportsFeatureState = (state: CoreState) => { return state.employeeReports; };

export const selEmployeeDeductionsShortageReportState = (state: CoreState) => { return state.selEmployee.deductionsShortageReport; };
export const payrollReportReportState = (state: CoreState) => { return state.payrollReports; };

export const accrualState = (state: CoreState) => { return state.selEmployeeDetails.accruals; };
export const attendanceOnDemandState = (state: CoreState) => { return state.selEmployeeDetails.attendanceOnDemand; };

export const checkCalculatorState = (state: CoreState) => { return state.selEmployeeDetails.checkCalculator; };
export const checkCodeState = (state: CoreState) => { return state.selEmployeeDetails.checkCode; };
export const contactState = (state: CoreState) => { return state.selEmployeeDetails.contact; };
export const contractorOptionsState = (state: CoreState) => { return state.selEmployeeDetails.contractorOptions; };
export const deductionState = (state: CoreState) => { return state.selEmployeeDetails.deduction; };
export const deferredCompState = (state: CoreState) => { return state.selEmployeeDetails.deferredComp; };
export const documentState = (state: CoreState) => { return state.selEmployeeDetails.document; };
export const earningsRecordsState = (state: CoreState) => { return state.selEmployeeDetails.earningsRecords; };
export const empACAState = (state: CoreState) => { return state.selEmployeeDetails.empACA; };
export const empDocCompState = (state: CoreState) => { return state.selEmployeeDetails.empDocComp; };
export const empFteState = (state: CoreState) => { return state.selEmployeeDetails.empFte; };
export const empGroupTermState = (state: CoreState) => { return state.selEmployeeDetails.empGroupTerm; };
export const empI9State = (state: CoreState) => { return state.selEmployeeDetails.empI9; };
export const empSelfServiceState = (state: CoreState) => { return state.selEmployeeDetails.empSelfService; };
export const hireHistoryState = (state: CoreState) => { return state.selEmployeeDetails.hireHistory; };
export const otherDateState = (state: CoreState) => { return state.selEmployeeDetails.otherDate; };
export const payRateState = (state: CoreState) => { return state.selEmployeeDetails.payRate; };
export const payrollState = (state: CoreState) => { return state.payroll; };
export const payrollInfoState = (state: CoreState) => { return state.selEmployeeDetails.payrollInfo; };
export const payrollsUserOptionsState = (state: CoreState) => { return state.selEmployeeDetails.payrollsUserOptions; };
export const payStubState = (state: CoreState) => { return state.selEmployeeDetails.payStub; };
export const recurringEarningsState = (state: CoreState) => { return state.selEmployeeDetails.recurringEarnings; };
export const specialInfoState = (state: CoreState) => { return state.selEmployeeDetails.specialInfo; };
export const supervisedEmployeeState = (state: CoreState) => { return state.selEmployeeDetails.supervisedEmployee; };
export const visaStatusState = (state: CoreState) => { return state.selEmployeeDetails.visaStatus; };
export const yearToDateState = (state: CoreState) => { return state.selEmployeeDetails.ytd; };
export const myInfoChangesState = (state: CoreState) => { return state.myInfoChanges; };
export const taxState = (state: CoreState) => { return state.tax; };
export const salesTaxTransmittalState = (state: CoreState) => { return state.salesTaxTransmittal; };
export const earningCodeState = (state: CoreState) => { return state.earningCode; };
export const ssoState = (state: CoreState) => { return state.sso; };
export const timeOffState = (state: CoreState) => { return state.timeOff; };
export const payrollUpload = (state: CoreState) => { return state.payrollUpload; };
export const payrollAdjustmentState = (state: CoreState) => { return state.payrollAdjustment; };
export const downloaderState = (state: CoreState) => { return state.downloader; };
export const correctionState = (state: CoreState) => { return state.corrections; };
export const integrationState = (state: CoreState) => { return state.integration; };
export const contractorState = (state: CoreState) => { return state.contractor; };
export const intuitState = (state: CoreState) => state.intuit;
export const emFieldValidationState = (state: CoreState) => state.emFieldValidation;
export const contractReportsState = (state: CoreState) => state.contractReports;
export const bambooHrReportsState = (state: CoreState) => state.bambooHrReports;

const reducerMap = {
  accountingReports: fromAccountingReports.reducer,
  app: fromApp.reducer,
  applicantTracking: fromApplicantTracking.applicantTrackingSlice.reducer,
  applicantTrackingV2: fromApplicantTrackingV2.applicantTrackingSlice.reducer,
  workFlow: fromWorkFlow.workFlowSlice.reducer,
  auth: fromAuth.reducer,
  client: fromClient.reducer,
  apClientVendor: fromApClientVendor.reducer,
  dashboard: fromDashboard.reducer,
  dropdown: fromDropdown.reducer,
  transfer: fromTransfer.reducer,
  onboard: fromOnboard.reducer,
  empUpload: fromEmployeeUpload.reducer,
  globalDeduction: fromGlobalDeduction.reducer,
  eeo: fromEEO.reducer,
  workerComp: fromWorkerComp.reducer,
  rateRule: fromRateRule.reducer,
  employees: fromEmployees.reducer,
  payroll: fromPayroll.reducer,
  selEmployee: fromEmployee.reducer,
  employeeReports: fromEmployeeReports.reducer,
  humanCapitalReports: fromHumanCapitalReports.reducer,
  payrollReports: fromPayrollReports.reducer,
  selEmployeeDetails: selEmployeeDetailsReducer,
  myInfoChanges: fromMyInfoChanges.myInfoChangesReducer,
  tax: fromTax.taxReducer,
  salesTaxTransmittal: fromSalesTaxTransmittal.reducer,
  earningCode: fromEarningCode.earningCodeReducer,
  sso: fromSso.ssoReducer,
  timeOff: fromTimeOff.timeOffReducer,
  payrollUpload: fromPayrollUpload.reducer,
  payrollAdjustment: fromPayrollAdjustment.reducer,
  earningsReports: fromEarningsReports.earningsReportsReducer,
  hrEmployeeInfo: fromHrEmployeeinfo.reducer,
  accrualReports: fromAccrualReports.accrualReportsReducer,
  employeeInfomation: fromEmployeeInformation.employeeInformationReducer,
  hr: fromHr.hrSlice.reducer,
  payRateReports: fromPayRateReports.payRateReportsSlice.reducer,
  file: fromFile.fileSlice.reducer,
  reportDownloader: fromReportDownloader.downloadReportFileSlice.reducer,
  cmUsers: fromCmUsers.cmUsersSlice.reducer,
  contractorReports: fromContractorReports.contractorReportsSlice.reducer,
  downloader: fromDownloader.downloaderSlice.reducer,
  contractorReportsOverview: fromContractorReportsOverview.contractorReportsOverviewSlice.reducer,
  acaFullTimeReport: fromACAFullTimeReportsOverview.ACAFullTimeCalculationReportSlice.reducer,
  employeeEarningsDownload: fromEmployeeEarningsDownload.employeeEarningsDownloadSlice.reducer,
  contractorFringeDownload: fromContractorFringeDownload.contractorFringeOverviewSlice.reducer,
  bambooHRReport: fromBambooHR.bambooHrSlice.reducer,
  clientLibrary: fromClientLibrary.reducer,
  referenceGuide: fromReferenceGuide.reducer,
  corrections: fromCorrections.reducer,
  integration: fromIntegration.reducer,
  contractor: fromContractors.reducer,
  intuit: fromIntuit.reducer,
  emFieldValidation: fromEmFieldValidation.reducer,
};

// TODO: type should be something like Reducer<CombinedState<CoreState> | undefined, AnyAction>, sort of...
const combinedReducer = combineReducers(reducerMap);
const rootReducer = (state: any, action: any) => { // we'll want to get this type 100% too
  if (action.type === 'resetStore') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;