import { createSelector } from 'reselect';
import { CoreState, payrollState } from '../reducers';
import { State } from '../reducers/payroll.reducer';

const payroll = (state: CoreState) => {return state.payroll.payroll;};
const payrollMessages = (state: CoreState) => {return state.payroll.payrollMessages;};
const payrollType = (state: CoreState) => {return state.payroll.payrollType;};
const isFinishedPayroll = (state: CoreState) => {return state.payroll.payrollType;};
const hasSignedWireOnlyAgreement = (state: CoreState) => {return state.payroll.payrollHasSignedWireOnlyAgreement}

export const getOpenPayrolls = createSelector(payroll, (state) => {return state.filter((a) => {return a.status !== 'F';});},
);

export const getAvailableToUploadPayrolls = createSelector(payroll, (state) => {return state.filter((a) => {return a.status === 'O' && a.useNewCheckRegister && !a.hasSignedWireOnlyAgreement ;});},
);

export const getCurrentPayroll = createSelector(payroll, (state) => {return state?.find((p) => p.useNewCheckRegister && !p.hasSignedWireOnlyAgreement);},
);

export const getCurrentProcessedPayroll = createSelector(payroll, (state) => {return state.filter((p) => {return p.useNewCheckRegister && p.status === 'F';})[0];},
);

export const getProcessedPayrolls = createSelector(payroll, (state) => {return state.filter((p) => {return p.status === 'F';}).sort((prev, next) => {return Date.parse(next.finishDate) - Date.parse(prev.finishDate);});},
);

export const getPayrollMessages = createSelector(payrollMessages, (state) => {return state.slice().sort((a, b) => {return a.messageId - b.messageId;});},
);

export const getPayrollReportDates = createSelector(
  payrollState,
  (state: State) => {return state.payrollReportDates;},
);

export const getPayrollValidate = createSelector(payroll, (state: any) => {
  return state.payrollUploadFileToolErrors;
});

export const getPayrollTimeOffRequests = createSelector(payroll, (state: any) => {
  return state.payrollTimeOffRequests;
});

export const getPayrollType = createSelector(payrollType, (state) => {return state;});

export const getPayrollOptions = createSelector(payroll, (state: any) => {return state.PayrollOptions;});

export const getIsFinishedPayroll = createSelector(isFinishedPayroll, (state) => {return state === 'F';});
export const getPayrollHasSignedWireOnlyAgreement = createSelector(hasSignedWireOnlyAgreement, (state) => {return state;});

export const getWireOnlyAgreement = createSelector(
  payrollState,
  (state: State) => {return state.wireOnlyAgreement;},
)
export const getWireOnlyAgreementReport = createSelector(
  payrollState,
  (state: State) => {return state.wireOnlyAgreementReport;},
);

