import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  AccrualBalanceRecord,
  AccrualEarningsCode,
  AccrualEdit,
  AccrualManualEntry,
  AccrualRule,
  AccrualRuleRequest,
  AccrualTimeOffSummary,
  HttpResponse,
  MarkRequestTaken,
  TimeOffRequest,
} from '../../models';
import { AccrualService } from '../../services';
import {
  handleError,
  createAccrualManualEntry,
  loadAccrualRule,
  loadAccruals,
  storeAccrual,
  storeAccrualRule,
  storeAccruals,
  updateAccrual,
  updateAccrualRule,
  loadTimeOffRequests,
  storeTimeOffRequests,
  loadTimeOffRequest,
  storeTimeOffRequest,
  updateMarkTaken,
  loadAccrualTimeOffSummary,
  storeAccrualTimeOffSummary,
  loadAccrualEarningsCodes,
  storeAccrualEarningsCodes,
  updateAccrualBeginBalance,
  handleSuccess,
  triggerEmFieldValidation,
  createDefaultAccrualRecords,
  CreateDefaultAccrualRequest,
} from '../actions';

interface Actions<T> {
  type: string;
  payload: T;
}

const loadAccruals$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(loadAccruals.type),
    switchMap((action: { payload: string; }) => {
      return from(AccrualService.GetAccruals(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: AccrualBalanceRecord[]) => { return storeAccruals(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateAccrual$ = (action$: Observable<Actions<AccrualEdit>>) => {
  return action$.pipe(
    ofType(updateAccrual.type),
    switchMap((action: { payload: AccrualEdit; }) => {
      return from(AccrualService.PutAccrual(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<AccrualEdit>) => {
          return [
            storeAccrual(res.value),
            loadAccruals(action.payload.protectedEmpNo),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateAccrualBeginBalance$ = (action$: Observable<Actions<AccrualEdit>>) => {
  return action$.pipe(
    ofType(updateAccrualBeginBalance.type),
    switchMap((action: { payload: AccrualEdit; }) => {
      return from(AccrualService.PutAccrualBeginBalance(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap(() => {
          return [
            handleSuccess('Balance updated'),
            loadAccruals(action.payload.protectedEmpNo),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createAccrualManualEntry$ = (action$: Observable<Actions<AccrualManualEntry>>) => {
  return action$.pipe(
    ofType(createAccrualManualEntry.type),
    switchMap((action: { payload: AccrualManualEntry; }) => {
      return from(AccrualService.PostAccrualManualEntry(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<AccrualManualEntry>) => { return loadAccruals(action.payload.protectedEmpNo); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadAccrualRule$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(loadAccrualRule.type),
    switchMap((action: { payload: string }) => {
      return from(
        AccrualService.GetAccrualRule(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: AccrualRule) => { return storeAccrualRule(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateAccrualRule$ = (action$: Observable<Actions<AccrualRule>>) => {
  return action$.pipe(
    ofType(updateAccrualRule.type),
    switchMap((action: { payload: AccrualRule; }) => {
      return from(AccrualService.PutAccrualRule(action.payload)).pipe(
        map((res) => {return res.data;}),
        mergeMap((res: HttpResponse<AccrualRule>) => {
          return [
            storeAccrualRule(res.value),
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'accrual',
              actionType: updateAccrualRule.type,
              callerPayload: res.value,
            }),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadTimeOffRequests$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(loadTimeOffRequests.type),
    switchMap((action: { payload: string; }) => {
      return from(AccrualService.GetTimeOffRequests(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: TimeOffRequest[]) => { return storeTimeOffRequests(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadTimeOffRequest$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadTimeOffRequest.type),
    switchMap((action: { payload: { protectedEmpNo: string; timeOffRequestId: number; }; }) => {
      return from(
        AccrualService.GetTimeOffRequest(
          action.payload.protectedEmpNo,
          action.payload.timeOffRequestId,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: TimeOffRequest) => { return storeTimeOffRequest(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateMarkTaken$ = (action$: Observable<Actions<MarkRequestTaken>>) => {
  return action$.pipe(
    ofType(updateMarkTaken.type),
    switchMap((action: { payload: MarkRequestTaken; }) => {
      return from(AccrualService.PutMarkTaken(action.payload)).pipe(
        map(() => { return loadTimeOffRequests(action.payload.protectedEmpNo); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadAccrualTimeOffSummary$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(loadAccrualTimeOffSummary.type),
    switchMap((action: { payload: string; }) => {
      return from(AccrualService.GetAccrualTimeOffSummary(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: AccrualTimeOffSummary[]) => { return storeAccrualTimeOffSummary(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadAccrualEarningsCodes$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadAccrualEarningsCodes.type),
    switchMap(() => {
      return from(AccrualService.GetAccrualEarningsCodes()).pipe(
        map((res: any) => { return res.data; }),
        map((res: AccrualEarningsCode[]) => { return storeAccrualEarningsCodes(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createDefaultAccrualRecords$ = (action$: Observable<Actions<any>>) =>
  action$.pipe(
    ofType(createDefaultAccrualRecords.type),
    switchMap((action: { payload: CreateDefaultAccrualRequest }) => 
      from(AccrualService.CreateDefaultAccrualRecords(action.payload)).pipe(
        map((res: any) => res.data),
        mergeMap((res: any) => [handleSuccess(res.messages)]),
        catchError((err) => [handleError(err)]),
      ),
    ),
  );

export const epics: any[] = [
  loadAccruals$,
  updateAccrual$,
  updateAccrualBeginBalance$,
  createAccrualManualEntry$,
  loadAccrualRule$,
  updateAccrualRule$,
  loadTimeOffRequests$,
  loadTimeOffRequest$,
  updateMarkTaken$,
  loadAccrualTimeOffSummary$,
  loadAccrualEarningsCodes$,
  createDefaultAccrualRecords$,
];
