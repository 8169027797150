import Modal from 'core/components/modals/Modal';
import React from 'react';

const PayrollUpdateInvalidColumnNumbers: React.FC<any> = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="INVALID COLUMN NUMBERS"
    >
      {(closeModal) => {
        return (
          <>
            <div>Columns Numbers must be consecutive starting with 0 and no duplicates.</div>
            <div className="row mt-4">
              <div className="col-12 text-right">
                <button type="button"
                  className="btn btn-primary orange-outline-button"
                  onClick={closeModal} >Continue</button>
              </div>
            </div>
          </>
        );
      }}
    </Modal>
  );

};

export default PayrollUpdateInvalidColumnNumbers;
