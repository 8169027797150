import { GridOptions } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { RowNode, ColDef, GridApi, MasterDetailModule } from '@ag-grid-enterprise/all-modules';
import Icon from 'core/components/shared/Icon';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { HireInfoCustomField, HireInfoFieldDescription } from '../../../core/models/HREmployee.model';
import { updateHireInfoCustomFields } from '../../../core/store/actions/hr-employee.action';
import { getHireInfoCustomFields } from '../../../core/store/selectors/hr-employee.selector';
import AGAddItemHeaderComponent from '../../../utilities/ag-grid-renderers/AGAddItemHeaderComponent';
import AGDeleteHeaderComponent from '../../../utilities/ag-grid-renderers/AGDeleteHeaderComponent';
import AGEditButtonRendererComponent from '../../../utilities/ag-grid-renderers/AGEditButtonRendererComponent';
import CheckboxRenderer from '../../../utilities/ag-grid-renderers/CheckboxRenderer';
import { useAppDispatch, useAppSelector } from '../../../utilities/hooks';


let expandedRow: HireInfoCustomField | undefined = undefined;

type PropTypes = {
  show: boolean;
  onHide: () => void;
};

const HrCustomizedFieldModal: React.FC<PropTypes> = ({
  show,
  onHide,
}) => {
  const dispatch = useAppDispatch();

  const client = useAppSelector(
    (state) => { return state.client; },
  );
  const hireInfoCustomFields = useSelector(getHireInfoCustomFields) as HireInfoCustomField[];
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [rowData, setRowData] = useState<HireInfoCustomField[]>([]);


  const { handleSubmit, reset } = useForm<HireInfoCustomField[]>({
    defaultValues: hireInfoCustomFields,
  });

  useEffect(() => {
    if (hireInfoCustomFields) {
      setRowData(cloneDeep(hireInfoCustomFields));
    }
  }, [hireInfoCustomFields]);

  const columns: ColDef[] = [
    {
      field: 'fieldName',
      headerName: '',
      cellRenderer: 'agGroupCellRenderer',
      width: 30,
    },
    {
      field: 'fieldName',
      headerName: 'Name',
      editable: true,
      width: 450,
    },
    {
      field: 'delete',
      headerComponentFramework: AGDeleteHeaderComponent,
      editable: false,
      cellRenderer: 'checkBoxRenderer',
      cellEditor: 'checkboxEditor',
      cellStyle: { paddingLeft: '16px' },
      width: 45,
    },
  ];

  const gridOptions: GridOptions = {
    columnDefs: columns,
    domLayout: 'autoHeight',
    defaultColDef: {
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
      singleClickEdit: true,
    },
    stopEditingWhenCellsLoseFocus: true,
    getRowNodeId: (data: any) => { return data.customFieldId; },
    frameworkComponents: {
      editButtonRendererComponent: AGEditButtonRendererComponent,
      checkBoxRenderer: CheckboxRenderer,
    },
    masterDetail: true,
    detailRowAutoHeight: true,
    detailCellRendererParams: {
      detailGridOptions: {
        domLayout: 'autoHeight',
        defaultColDef: {
          suppressMenu: true,
          resizable: true,
          cellClass: 'ag-cell-left-border',
          singleClickEdit: true,
        },
        stopEditingWhenCellsLoseFocus: true,
        getRowNodeId: (data: HireInfoFieldDescription) => { return data.customFieldDescriptionId; },
        columnDefs: [
          {
            field: 'description',
            editable: true,
            width: 420,
            headerComponentParams: () => {
              return {
                clickHandler: onAddDescription,
                label: 'Add Description',
                item: expandedRow,
                headerName: 'Description',
              };
            },
            headerComponentFramework: AGAddItemHeaderComponent,
          },
          {
            field: 'delete',
            headerComponentFramework: AGDeleteHeaderComponent,
            editable: false,
            cellRenderer: 'checkBoxRenderer',
            cellEditor: 'checkboxEditor',
            cellStyle: { paddingLeft: '16px' },
            width: 45,
          },
        ],
        frameworkComponents: {
          editButtonRendererComponent: AGEditButtonRendererComponent,
          checkBoxRenderer: CheckboxRenderer,
        },
      },
      getDetailRowData: function (params: any) {
        params.successCallback(params.data.descriptions);
      },
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onExpandClick = (params: any) => {
    if (params.node.expanded) {
      params?.api?.forEachNode((node: RowNode) => {
        if (
          node.expanded &&
          node.id !== params.node.id &&
          node.uiLevel === params.node.uiLevel
        ) {
          node.setExpanded(false);
        }
      });
      expandedRow = params.data;
    } else {
      expandedRow = undefined;
    }
  };

  const onAddField = () => {
    const field: HireInfoCustomField =
    {
      fieldName: '',
      customFieldId: 0,
      descriptions: [{
        customFieldId: 0,
        customFieldDescriptionId: 0,
        description: '',
      }],
    };

    gridApi?.applyTransaction({
      add: [field],
      addIndex: 0,
    });
  };

  const onAddDescription = () => {

    const detailGrid = gridOptions?.api?.getDetailGridInfo(
      'detail_' + expandedRow?.customFieldId,
    );
    const detailGridApi = detailGrid?.api;

    const desc: HireInfoFieldDescription =
    {
      customFieldId: expandedRow?.customFieldId,
      customFieldDescriptionId: 0,
      description: '',
    };

    detailGridApi?.applyTransaction({
      add: [desc],
      addIndex: 0,
    });
  };

  const onDeleteSelected = () => {
    if (gridApi) {
      const newNodes: HireInfoCustomField[] = [];
      gridApi.forEachNode((node: RowNode) => {
        const descriptions = node.data.descriptions.filter((d: any) => { return !d.delete; });
        !node.data.delete && newNodes.push({
          ...node.data,
          descriptions: descriptions,
        });
      });

      dispatch(updateHireInfoCustomFields(newNodes));
    }
  };

  const closeModal = (e: any) => {
    e.stopPropagation();
    hideModal();
  };

  const hideModal = () => {
    onHide();
  };

  const onSubmit = (data: any, e?: React.BaseSyntheticEvent) => {
    if (!e?.target.isConnected) {
      return false;
    }

    const items: HireInfoCustomField[] = [];
    gridApi?.forEachNode((node) => {
      const item = node.data as HireInfoCustomField;
      item.clientNo = client.client?.clientNo;
      gridApi?.forEachDetailGridInfo((detailGridInfo, index) => {
        const detailData = detailGridInfo.api?.getRowNode(
          '' + index,
        )?.data;
        if (
          detailData &&
          detailGridInfo.id ===
          'detail_' + item.customFieldId
        ) {
          const description = detailData;
          item?.descriptions?.push({ ...description });
        }
      });
      items.push(item);
    });

    dispatch(updateHireInfoCustomFields(items));
  };

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={hideModal}
        dialogClassName="modal-sm"
        animation={false}
      >
        <div className="modal-header">
          <div className="dm-card-title">
            Custom Field Information
          </div>
          <button type="button"
            onClick={closeModal}>
            <Icon name="times"
              className="modal-close-btn" />
          </button>
        </div>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>

            <div className="d-flex">
              <div className="ml-auto btn-link"
                onClick={onAddField}>
                <span className="dm-grid-action-title">
                  Add Custom Field
                </span>
                <Icon name="plus-circle"
                  className="fa-plus-circle" />
              </div>
            </div>

            <div className="table-wrapper-wrapper ag-theme-balham">
              <AgGridReact
                gridOptions={gridOptions}
                rowData={rowData}
                modules={[MasterDetailModule]}
                onRowGroupOpened={onExpandClick}
                onGridReady={onGridReady}
              />
            </div>
            <div className="text-right">
              <button
                type="button"
                className="btn btn-link dm-grid-action-title"
                onClick={() => { return onDeleteSelected(); }}
              >
                Delete Selected{' '}
                <Icon name="minus-circle"
                  className="fa-plus-circle" />
              </button>
            </div>

            <div style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn btn-primary orange-outline-button ml-auto mr-2"
                onClick={() => { onHide(); reset(); }}
              >
                Cancel
              </button>
              <button type="submit"
                className="orange-button mr-2">
                Save
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default HrCustomizedFieldModal;
