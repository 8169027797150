import React, { useContext, useEffect, useState } from 'react';
import InputSection, { inputStyle } from './InputSection';
import './time-card-styles.scss';
import { InputGrp } from 'core/components/form-controls';
import { inputGrpStyle } from './ContractorTimeSheet';
import AreaTradeSubModal from './modals/AreaTradeSub.modal';
import { AtsInfo, DetailArrayField } from 'core/models';
import { useAppSelector } from 'utilities/hooks';
import { currencyFormatter, fromCurrency } from 'utilities/utilities';
import ModalInput from './ModalInput';
import { TimeCardContext } from './TimeCardContext';

const timeSheetOrDefault = (timeSheetVal: string | undefined, homeAtsVal: string | undefined): string =>
  (timeSheetVal ?? '') === '' ? (homeAtsVal ?? '') : '';

type Props = {
  timeSheet: DetailArrayField;
  index: number;
};

const RateEarnings = ({ timeSheet, index }: Props) => {
  const { formMethods, updateDirtyState } = useContext(TimeCardContext);
  const { register, watch, setValue } = formMethods;
  
  const watchedArea: string = watch(`details[${index}].area`);
  const watchedTrade: string = watch(`details[${index}].trade`);
  const watchedSub: string = watch(`details[${index}].sub`);
  const watchedTradeRate: number = watch(`details[${index}].tradeRate`);
  const watchedAddtlRate: number = watch(`details[${index}].additionalRate`);
  const watchedEmpRate: number = watch(`details[${index}].empRate`);
  
  const [showAtsModal, setShowAtsModal] = useState<boolean>(false);
  const [subDesc, setSubDesc] = useState<string>('');
  const [totalRate, setTotalRate] = useState<number>(
    (fromCurrency(String(watchedTradeRate)) ?? 0)
      + (fromCurrency(String(watchedEmpRate)) ?? 0)
      + (fromCurrency(String(watchedAddtlRate)) ?? 0),
  );
  
  const { rateMaster } = useAppSelector(({ contractorReports }) => contractorReports);
  const { homeAtsInfo } = useAppSelector(({ contractor }) => contractor);
  
  useEffect(() => {
    setTotalRate(
      (fromCurrency(String(watchedTradeRate)) ?? 0)
      + (fromCurrency(String(watchedEmpRate)) ?? 0)
      + (fromCurrency(String(watchedAddtlRate)) ?? 0));
  }, [watchedTradeRate, watchedAddtlRate]);
  
  useEffect(() => {
    if (!rateMaster?.length) return console.error('Unable to set sub desc.: missing rate master information');
    
    const matchingRecord = rateMaster?.find((x) => x.sub === watchedSub);
    if (!matchingRecord) return;
    
    setSubDesc(matchingRecord?.subDescription ?? '');
  }, [rateMaster]);
  
  const defaultArea = timeSheetOrDefault(timeSheet?.area, homeAtsInfo?.area);
  const defaultTrade = timeSheetOrDefault(timeSheet?.area, homeAtsInfo?.area);
  const defaultSub = timeSheetOrDefault(timeSheet?.area, homeAtsInfo?.area);
  
  const toggleShowModal = () => {
    setShowAtsModal(true);
  };
  
  const updateAddtlRate = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setValue(`details[${index}].additionalRate`, fromCurrency(e.target.value));
    updateDirtyState(true);
    e.target.value = currencyFormatter(e.target.value, 4);
  };
  
  const updateAtsInfo = (newInfo: AtsInfo) => {
    // set form values
    setValue(`details[${index}].area`, newInfo?.area ?? '');
    setValue(`details[${index}].trade`, newInfo?.trade ?? '');
    setValue(`details[${index}].sub`, newInfo?.sub ?? '');
    setValue(`details[${index}].tradeRate`, newInfo.baseRate ?? 0);
    
    // set local state not directly related to form object
    setSubDesc(newInfo.subDescription);
    setShowAtsModal(false);
    updateDirtyState(true);
  };
  
  const currentAtsData: AtsInfo = {
    area: watchedArea ?? '',
    trade: watchedTrade ?? '',
    sub: watchedSub ?? '',
    subDescription: subDesc,
    baseRate: watchedTradeRate,
  };
  
  return (
    <div className="main-section">
      <div className="section-title">Rate/Earnings</div>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column w-100">
          <div className="input-section">
            <input
              type="hidden"
              name={`details[${index}].area`}
              defaultValue={defaultArea}
              ref={register()}
            />
            <span className="input-label">Area</span>
            <ModalInput
              index={index}
              property="area"
              value={watchedArea}
              modalName="area/trade/sub"
              onClick={toggleShowModal}
            />
          </div>
          <div className="input-section">
            <input
              type="hidden"
              name={`details[${index}].trade`}
              defaultValue={defaultTrade}
              ref={register()}
            />
            <span className="input-label">Trade</span>    
            <ModalInput
              index={index}
              property="trade"
              value={watchedTrade}
              modalName="area/trade/sub"
              onClick={toggleShowModal}
            />            
          </div>
          <div className="input-section">
            <input
              type="hidden"
              name={`details[${index}].sub`}
              defaultValue={defaultSub}
              ref={register()}
            />
            <span className="input-label">Sub</span>
            <ModalInput
              index={index}
              property="sub"
              value={watchedSub}
              modalName="area/trade/sub"
              onClick={toggleShowModal}
            />    
          </div>
          <InputSection
            label="Sub Desc."
            value={subDesc}
            addtlInputStyles={{ textAlign: 'left' }}
            readOnly
          />
          <div className="input-section">
            <span className="input-label">Addtl. Rate</span>
            <InputGrp
              name={`details[${index}].additionalRate`}
              defaultValue={currencyFormatter(watchedAddtlRate, 4)}
              ref={register()}
              onBlur={updateAddtlRate}
              groupStyle={inputGrpStyle}
              inputStyle={{ ...inputStyle, textAlign: 'right' }}
            />        
          </div>
          <InputSection
            label="Trade Rate"
            value={currencyFormatter((watchedTradeRate ?? 0), 4)}
            addtlInputStyles={{ textAlign: 'right' }}
            readOnly
          />
          <InputSection
            label="Over Scale"
            value={currencyFormatter((timeSheet?.empRate ?? 0), 4)}
            addtlInputStyles={{ textAlign: 'right' }}
            readOnly
          />
          <InputSection
            label="Total Rate"
            labelStyles={{ fontWeight: 'bold' }}
            value={currencyFormatter(totalRate, 4)}
            addtlInputStyles={{ textAlign: 'right' }}
            readOnly
          />
        </div>
      </div>
      {showAtsModal && (
        <AreaTradeSubModal
          show={showAtsModal}
          onHide={() => setShowAtsModal(false)}
          updateAtsInfo={updateAtsInfo}
          currentData={currentAtsData}
          index={index}
        />
      )}
    </div>
  );
};

export default RateEarnings;