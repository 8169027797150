import { AxiosResponse } from "axios";
import { HttpResponse, PayrollInfo } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getPayrollInfo = (empNo: string): Promise<AxiosResponse<PayrollInfo>> => {return API_EMPLOYEE_MASTER.get<PayrollInfo>(EmployeeEndPoints.GET_PAYROLL_INFO(CommonService.getProtectedClientNo(), empNo));};

const putPayrollInfo = (data: PayrollInfo): Promise<AxiosResponse<HttpResponse<PayrollInfo>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<PayrollInfo>>(EmployeeEndPoints.PUT_PAYROLL_INFO(CommonService.getProtectedClientNo(), data.protectedEmpNo), data);};

export const PayrollInfoService = {
  getPayrollInfo,
  putPayrollInfo,
};
