import { createSelector } from 'reselect';
import { convToFormObject } from '../../../utilities/classUtils';
import { VisaStatus } from '../../models';
import { visaStatusState } from '../reducers';
import { State as VisaStatusState } from '../reducers/visa-status.reducer';

export const getVisaStatus = createSelector(
  visaStatusState,
  (state: VisaStatusState) => {
    if (!state?.visaStatus) return null;

    return convToFormObject(state.visaStatus, VisaStatus.convProps);
  });
