import Modal from 'core/components/modals/Modal';
import { useHistory } from 'react-router-dom';

type Props = {
  show: boolean;
  bodyMessage: string;
  title: string;
  question: string;
  navigationPath: string;
  buttonText: string;
  onHide: () => void;
};

const WizardConfirmationModal = ({ show, title, onHide, bodyMessage, question, navigationPath, buttonText }: Props) => {
  
  const history = useHistory();

  const onGoToDifferentPage = () => {
    onHide();
    return history.push(navigationPath);
  }

  const onClick = (goToDifferentPage: boolean) => {
    if(goToDifferentPage) return onGoToDifferentPage();
    else return onHide();
  }
    
  return (
    <>
      <Modal
        title={title}
        animation
        size="sm"
        show={show}
        onHide={onHide}
      >
        <div style={{ whiteSpace: 'pre-line' }}>
            <p>{bodyMessage}</p>
            <p>{question}</p>
            </div>
            <br/>
            <div className="d-flex justify-content-end mt-2">
              <div className="d-flex flex-row">
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button mr-2"
                  onClick={() => {
                    onClick(false);
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button mr-2"
                  onClick={() => {
                    onClick(true);
                  }}
                >
                  {buttonText}
                </button>
              </div>
            </div>
      </Modal>
    </>
  );
};

export default WizardConfirmationModal;