import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class ShiftPremiumEarnings {
  shiftPremiumDetailId = 0;

  shiftPremiumId = 0;

  earningsCodeId = 0;

  constructor(shiftPremiumId: number | string, shiftPremiumDetailId: number | string, earningsCodeId: number | string) {
    this.shiftPremiumId = (typeof shiftPremiumId === 'string') ? parseInt(shiftPremiumId) : shiftPremiumId;
    this.shiftPremiumDetailId = (typeof shiftPremiumDetailId === 'string') ? parseInt(shiftPremiumDetailId) : shiftPremiumDetailId;
    this.earningsCodeId = (typeof earningsCodeId === 'string') ? parseInt(earningsCodeId) : earningsCodeId;
  }
}

export class ShiftPremium {

  shiftPremiumId = 0;

  clientId = 0;

  clientNo = 0;

  shiftCode = '';

  shiftCodeLabel = '';

  earningsCodeId: number | null = null;
  
  earningsCode = '';
  
  shortDescription = '';

  description = '';

  isPercent = false;

  amount = 0;

  applyToAllEarnCodes = false;

  includeInControlTotals = false;

  paysAtStraightTime = false;

  internalComment = '';

  earningsCodes: ShiftPremiumEarnings[] = [];

  static readonly convProps: PropTypeLists = {
    numberProps: ['shiftPremiumId', 'clientId', 'clientNo'],
    currencyProps: ['amount'],
  };

  constructor(clientId: string | number, clientNo: string | number, props?: { [key: string]: any }) {
    this.clientId = (typeof clientId === 'string') ? parseInt(clientId) : clientId;
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;

    props && convToClass(this, props, ShiftPremium.convProps);
  }

  // static toObject(obj: ShiftPremium): ShiftPremium {
  // 	const output = {};
  // 	ShiftPremium.convProps(output, obj);
  // 	return output as ShiftPremium;
  // }
}