import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InterviewTemplate, InterviewQuestion, ScorecardCategory, InterviewType } from 'core/models';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams, ValueSetterParams } from '@ag-grid-community/core';
import { InputGrp, SelectGrp, TextareaGrp } from 'core/components/form-controls';
import { agLookupRenderer } from 'utilities/ag-grid-renderers';
import { postInterviewTemplate } from 'core/store/slices/applicantTracking.slice';
import Modal from 'core/components/modals/Modal';
import { agSelectEditor } from 'utilities/ag-grid-editors';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { cloneDeep } from 'lodash';

type Props = {
  show: boolean;
  item: InterviewTemplate | undefined;
  onHide: () => void;
};

const InterviewTemplateModal: React.FC<Props> = ({
  show,
  item,
  onHide,
}) => {

  const dispatch = useAppDispatch();
  
  const [client, interviewTypes, interviewQuestions, scorecardCategories, interviewTemplates] = useAppSelector(
    (state) => {
      return [
        state.client.client,
        state.applicantTracking.interviewTypes,
        state.applicantTracking.interviewQuestions,
        state.applicantTracking.scorecardCategories,
        state.applicantTracking.interviewTemplates,
      ];
    },
  );

  const [questionRowData, setQuestionRowData] = useState<InterviewQuestion[]>([]);
  const [scoreCardRowData, setScoreCardRowData] = useState<ScorecardCategory[]>([]);
  const [questionGridApi, setQuestionGridApi] = useState<GridApi | null>(null);
  const [scoreCardGridApi, setScoreCardGridApi] = useState<GridApi | null>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [gridDirty, setGridDirty] = useState<boolean>(false);

  const { register, reset, handleSubmit, getValues, formState: { isDirty } } = useForm<InterviewTemplate>({
    defaultValues: { ...item },
  });
  
  const mappedQuestionOpts = interviewQuestions.map(q => ({
    ...q,
    id: q.interviewQuestionId,
    description: q.description,
  }));
  const mappedScoreCardOpts = scorecardCategories.map(q => {
    return {
      ...q,
      id: q.scorecardCategoryId,
      description: q.category,
    };
  });
  
  const [selectedQuestionOption, setSelectedQuestionOption] = useState<number>(mappedQuestionOpts?.[0]?.interviewQuestionId);
  const [selectedScoreCardOption, setSelectedScoreCardOption] = useState<number>(mappedScoreCardOpts?.[0]?.scorecardCategoryId);

  useEffect(() => {
    if (item) {
      reset(item);
      if (item?.questions) setQuestionRowData(cloneDeep(item.questions));
      if (item?.scorecardCategories) setScoreCardRowData(cloneDeep(item.scorecardCategories));
    }
    setGridDirty(false);
  }, [item, interviewTemplates]);

  const onQuestionGridReady = (params: GridReadyEvent) => {
    setQuestionGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const onScorecardGridReady = (params: GridReadyEvent) => {
    setScoreCardGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const getQuestionGridData = () => {
    const items: any[] = [];
    questionGridApi?.forEachNode((node) => {
      items.push(node.data);
    });
    return items;
  };

  const getScorecardGridData = () => {
    const items: any[] = [];
    scoreCardGridApi?.forEachNode((node) => {
      items.push(node.data);
    });
    return items;
  };

  const onAddQuestion = () => {
    if (!selectedQuestionOption) return;
    const question: InterviewQuestion | undefined = mappedQuestionOpts?.find((q) => q.interviewQuestionId === selectedQuestionOption);
    
    if (!question) return;
    question.hrEntityId = client?.hrEntityId ?? 0;
    question.interviewTemplateId = client?.hrEntityId ?? 0;
    
    setQuestionRowData((prevState) => {
      return [...prevState,
        question,
      ];
    });
    setGridDirty(true);
  };

  const onDeleteQuestion = (params: ICellRendererParams) => {
    setQuestionRowData((prevState) => {
      return prevState.filter(t => {return t.interviewQuestionId !== params.data.interviewQuestionId;});
    });
   
    setGridDirty(true);
  };

  const onAddScorecard = () => {
    if (!selectedScoreCardOption) return;
    const category: ScorecardCategory | undefined = mappedScoreCardOpts?.find((c) => c.scorecardCategoryId === selectedScoreCardOption);
    
    if (!category) return;
    category.hrEntityId = client?.hrEntityId ?? 0;

    setScoreCardRowData((prevState) => {
      return [...prevState,
        category,
      ];
    });
    setGridDirty(true);
  };

  const onDeleteScorecard = (params: ICellRendererParams) => {
    setScoreCardRowData((prevState) => {
      return prevState.filter(t => {return t.scorecardCategoryId !== params.data.scorecardCategoryId;});
    });
   
    setGridDirty(true);
  };

  const hideModal = () => {
    if (isDirty || gridDirty) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };

  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };

  const onSubmit = (data: any) => {
    const questions =  getQuestionGridData().map(s=> {
      return { ...s,
        interviewQuestionId: +s.interviewQuestionId };
    }) as InterviewQuestion[];
    const scorecardCats =  getScorecardGridData().map(s=> {
      return { ...s,
        scorecardCategoryId: +s.scorecardCategoryId };
    }) as ScorecardCategory[];
    const ip: InterviewTemplate = item ? {
      ...item,
      interviewTemplateId: +item.interviewTemplateId,
      title: data.title,
      description: data.description,
      questions:questions.filter(q => {return q.interviewQuestionId !== 0;}),
      interviewTypeDescription: data.interviewTypeDescription,
      interviewTypeId: +data.interviewTypeId ?? 0,
      scorecardCategories:scorecardCats.filter(q => {return q.scorecardCategoryId !== 0;}),
    } : {
      interviewTemplateId: 0,
      hrEntityId: client?.hrEntityId ?? 0,
      title: data.title,
      description: data.description,
      questions: questions.filter(q => {return q.interviewQuestionId !== 0;}),
      interviewTypeId: +data.interviewTypeId ?? 0,
      interviewTypeDescription: data.interviewTypeDescription,
      scorecardCategories: scorecardCats.filter(q => {return q.scorecardCategoryId !== 0;}),
      isDefault: false,
    };

    dispatch(postInterviewTemplate(ip));

  };


  const questionColDefs = useMemo<ColDef[]>(
    () => {
      return [
        {
          field: 'title',
          headerName: 'Question Title',
        },
        {
          field: 'description',
          headerName: 'Question Description',
        },
        {
          field: 'delete',          
          cellRenderer: (params: ICellRendererParams) => {           
            return (
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={() => { return onDeleteQuestion(params); }
                }
              >
                <Icon
                  name="minus-circle"
                  className="fa-minus-circle"
                />
              </button>
            );
          },
          width: 70,
        },
      ];
    },
    [],
  );

  const scoreCardColDefs = useMemo<ColDef[]>(
    () => {
      return [
        {
          field: 'category',
          headerName: 'Category',
        },
        {
          field: 'delete',
          cellRenderer: (params: ICellRendererParams) => {
            return (
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={() => { return onDeleteScorecard(params); }
                }
              >
                <Icon
                  name="minus-circle"
                  className="fa-minus-circle"
                />
              </button>
            );
          },
          width: 70,
        },
      ];
    },
    [],
  );

  const gridOptionsQuestions: GridOptions = {
    stopEditingWhenCellsLoseFocus:true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    components: {
      lookupRenderer: agLookupRenderer,
      selectEditor: agSelectEditor,
    },
  };

  const gridOptionsScorecard: GridOptions = {
    stopEditingWhenCellsLoseFocus:true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    components: {
      lookupRenderer: agLookupRenderer,
      selectEditor: agSelectEditor,
    },
  };

  return (
    <Modal
      show={show}
      onHide={hideModal}
      isDirty={isDirty || gridDirty}
      dialogClassName="lg"
      backdrop={'static'}
      title="Design Interview Template"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-column flex-grow-1">
          <input
            type="hidden"
            name={'interviewTemplateId'}
            ref={register({ valueAsNumber: true })}
          />
          <div>
            <InputGrp
              label={'Template Name'}
              name={'title'}
              defaultValue={item?.title}
              ref={register}
            />
          </div>
          <div>
            <SelectGrp
              name={'interviewTypeId'}
              label="Type"
              options={interviewTypes?.map((t: InterviewType) => {
                return {
                  id: t.interviewTypeId,
                  description: t.description,
                };
              })}
              ref={register}
              disabled={item?.isDefault}
            />
          </div>
          <div>
            <TextareaGrp
              label={'Template Description'}
              name={'description'}
              defaultValue={item?.description}
              ref={register}
            />
          </div>
        </div>
        <div className="d-flex flex-row">
          <span><strong>Question Name and Description</strong></span>
        </div>
        {!item?.isDefault && (
          <div className="d-flex flex-row justify-content-between">
            <SelectGrp
              name={'interviewQuestionId'}
              label="Available Interview Questions"           
              groupStyle={{ width: '350px' }}
              value={selectedQuestionOption}
              onChange={(e: any) => { setSelectedQuestionOption(+e.target.value); }}
              options={mappedQuestionOpts}
            />
            <button
              type="button"
              className="btn btn-link dm-grid-action-title pb-0 mr-2"
              onClick={onAddQuestion}
            >
              Add Question{' '}
              <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          </div>
        )}
        <div className="d-flex mb-3">
          <div className="table-wrapper-wrapper ag-theme-balham">
            <AgGridReact
              gridOptions={gridOptionsQuestions}
              rowData={questionRowData}
              columnDefs={questionColDefs}
              onGridReady={onQuestionGridReady}
              onCellValueChanged={()=> {return setGridDirty(true);}}
            />
          </div>
        </div>
        <div className="d-flex flex-row">
          <span><strong>Scorecard Categories</strong></span>
        </div>
        {!item?.isDefault && (
          <>
            <div className="d-flex flex-row justify-content-between">
              <SelectGrp
                name={'scorecardCategoryId'}
                label="Available Categories"           
                groupStyle={{ width: '350px' }}
                value={selectedScoreCardOption}
                onChange={(e: any) => { setSelectedScoreCardOption(+e.target.value); }}
                options={mappedScoreCardOpts}
              />
              <button
                type="button"
                className="btn btn-link dm-grid-action-title pb-0 mr-2"
                onClick={onAddScorecard}
              >
                Add Scorecard{' '}
                <Icon
                  name="plus-circle"
                  className="fa-plus-circle"
                />
              </button>
            </div>
          </>          
        )}
        <div className="d-flex mb-3">
          <div className="table-wrapper-wrapper ag-theme-balham">
            <AgGridReact
              gridOptions={gridOptionsScorecard}
              rowData={scoreCardRowData}
              columnDefs={scoreCardColDefs}
              onGridReady={onScorecardGridReady}
              onCellValueChanged={()=> {return setGridDirty(true);}}
            />
          </div>
        </div>
        <div className="d-flex mt-3">
          <button
            className="orange-outline-button ml-auto mr-2"
            onClick={hideModal}
            type="button"
          >
            Cancel
          </button>
          {!item?.isDefault && (<button
            type="submit"
            className="orange-button mr-2"
            disabled={!(isDirty || gridDirty)}
          >
            Save
          </button>)}
        </div>
        {showConfirmationModal && (
          <ConfirmationModal
            title="You Have Unsaved Changes"
            message={'Would you still like to close?'}
            show={showConfirmationModal}
            onConfirmed={confirmClose}
            onHide={() => { return setShowConfirmationModal(false); }}
          />
        )}
      </form>
    </Modal>
  );
};
export default InterviewTemplateModal;
