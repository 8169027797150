import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import editIcon from '../../assets/images/edit.png';
import { SelectGrp } from '../../core/components/form-controls';
import {
  EmployeeUploadClientFile,
  EmployeeUploadClientLibrary,
} from '../../core/models';
import {
  createEmpUploadClientLibrary,
  loadEmpUploads,
  storeSelectedEmpUploadClientFile,
  storeSelectedEmpUploadClientLibrary,
} from '../../core/store/actions';
import { getEmpUploadClientFiles, getEmpUploadTables, getSelectedEmpUploadClientFile } from '../../core/store/selectors/employee-upload.selector';
import MissingFile from './MissingFile.modal';
import UploadEmpStep1Modal from './UploadEmpStep1.modal';
import UploadEmpStep2Modal from './UploadEmpStep2.modal';
import { useAppSelector } from '../../utilities/hooks';
import Icon from 'core/components/shared/Icon';
import { useHistory } from 'react-router-dom';
import UploadErrorModal from './UploadError.modal';

const UploadEmpPage: React.FC<any> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const clientNo = useAppSelector((state) => { return state.client.client!.clientNo; });

  const [showMissingFile, setShowMissingFile] = useState(false);
  const [showStep1Modal, setShowStep1Modal] = useState(false);
  const [showStep2Modal, setShowStep2Modal] = useState(false);
  const [errorInfo, setErrorInfo] = useState<{errorMessage: string, hasError: boolean}>({errorMessage: '', hasError: false});
  const [clientFile, setClientFile] = useState<EmployeeUploadClientFile>(
    new EmployeeUploadClientFile(clientNo, 0),
  );
  const [csvFile, setCSVFile] = useState<string>('');
  const [fileRows, setFileRows] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string>('');
  const fileInputRef = useRef<any>(null);
  const [showLoadingBar, setShowLoadingBar] = useState<boolean>(false);
  const invalidFieldIds = [152,153,154,155];
  
  const clientFileOpts = useSelector(getEmpUploadClientFiles);
  const uploadingData = useAppSelector(state => state.empUpload.uploadingFile);
  const empUploadClientFile = useSelector(getSelectedEmpUploadClientFile);
  const [headerStartingLine, setHeaderStartingLine] = useState<number>(empUploadClientFile?.startOnLine || 1);

  useEffect(() => {
    dispatch(loadEmpUploads());
    dispatch(
      storeSelectedEmpUploadClientFile(
        new EmployeeUploadClientFile(clientNo, 0),
      ),
    );
  }, []);

  useEffect(() => {
    setShowLoadingBar(uploadingData);
  }, [uploadingData])

  const onSelectUploadClientFile = (e: any) => {
    const selectedClientFile = clientFileOpts.find(
      (o) => { return o.clientFileId === +e.target.value; },
    );

    if (selectedClientFile) {
      setClientFile(selectedClientFile);
      dispatch(storeSelectedEmpUploadClientFile(selectedClientFile));
    } else {
      setClientFile(new EmployeeUploadClientFile(clientNo, 0));
      dispatch(
        storeSelectedEmpUploadClientFile(
          new EmployeeUploadClientFile(clientNo, 0),
        ),
      );
    }
  };

  const handleClick = (e: any) => {
    fileInputRef?.current?.click();
  };

  const onFileSelected = (e: any) => {
    const fileObj = e.target.files[0];
    setFileName(fileObj.name);
    setClientFile((prev) => {
      const updatedClientFile = {
        ...prev,
        fileName: fileObj.name,
        filePath: '',
      };
      dispatch(storeSelectedEmpUploadClientFile(updatedClientFile));
      return updatedClientFile;
    });
    const reader: FileReader = new FileReader();

    const fileLoaded = (e: any) => {
      const fileRows = e.target.result.split(/\n/);
      const fileRowsHandleQuotes = fileRows.map((line: any) => { return line.replace(/\s+(?=([^"]*"[^"]*")*[^"]*$)/g, ''); },
      ) as string[];
      setFileRows(fileRowsHandleQuotes);
      setCSVFile(e.target.result);
    };
    reader.onload = fileLoaded;
    reader.readAsText(fileObj);

    setFileName(fileObj.name);
  };

  const onCreate = () => {
    if (!csvFile) {
      setShowMissingFile(true);
    } else {
      const newUploadClientFile = new EmployeeUploadClientFile(
        clientNo,
        0,
      );
      setClientFile({
        ...newUploadClientFile,
        fileName,
      });
      dispatch(storeSelectedEmpUploadClientFile(newUploadClientFile));
      setShowStep1Modal(true);
    }
  };

  const onEdit = () => {
    if (!csvFile) {
      setShowMissingFile(true);
    }
    else{
      setClientFile((prev) => {
        return {
          ...prev,
          fileName,
        };
      });
      setShowStep1Modal(true);
    }
  };

  const onUpload = () => {
    const invalidFieldCheck = empUploadClientFile.fields.filter(x => invalidFieldIds.includes(x.fieldId))
    if(invalidFieldCheck.length > 0) return setErrorInfo({errorMessage: 'There is an issue in your upload map. Please update your upload map before uploading.', hasError: true})
    const y = csvFile.replace(/"/g, '');

    const newClientLib = new EmployeeUploadClientLibrary(
      clientFile.clientFileId ?? 0,
    );
    newClientLib.fileName = fileName;
    newClientLib.file = y;
    newClientLib.empCount = y.split('\r').length - 1;
    dispatch(storeSelectedEmpUploadClientLibrary(newClientLib));
    newClientLib.clientFileId &&
      dispatch(createEmpUploadClientLibrary(newClientLib));
  };

  const onCancel = () => {
    history.push('/employee-master-list');
  };

  return (
    <div className="col">
      <div className="row">
        <div className="col-12">
          <div className="d-flex flex-wrap">
            <div className="pr-5">
              <div className="dm-page-title">
                Employee Upload CSV
              </div>
            </div>

            <div
              className="mt-auto pr-5 mb-1 mb-sm-0"
            >
              <Button
                disabled={(empUploadClientFile?.clientFileId || 0) !== 0}
                variant="link"
                className="dm-grid-action-title mb-0 pb-1"
                onClick={onCreate}
              >
                Create Upload Map{' '}
                <Icon
                  name="plus-circle"
                  className="fa-plus-circle"
                />
              </Button>
            </div>

            <div className="mt-auto pr-5 mb-1 mb-sm-0">
              <Button
                disabled={(empUploadClientFile?.clientFileId || 0) === 0}
                variant="link"
                onClick={onEdit}
                className="dm-grid-action-title mb-0 pb-1"
              >
                Edit Upload Map{' '}
                <img
                  className="pl-2"
                  src={editIcon}
                  alt="edit upload map"
                />
              </Button>
            </div>
          </div>
          <hr className="dm-page-hr" />
          <div className="row">
            <div className="col-5">
              <SelectGrp
                name="selectedMap"
                label="SELECT UPLOAD MAP"
                options={clientFileOpts}
                addEmptyValue={true}
                value={empUploadClientFile?.clientFileId || 0}
                valueField="clientFileId"
                labelField="description"
                onChange={onSelectUploadClientFile}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-4">
              <input
                type="file"
                name="datafile"
                ref={fileInputRef}
                onChange={onFileSelected}
                style={{ display: 'none' }}
              />
              <input
                type="text"
                name="displayDataFile"
                readOnly
                defaultValue={fileName}
                style={{ width: '100%' }}
              />
            </div>
            <div className="col-2 pl-1">
              <button
                type="button"
                className="btn btn-primary orange-button"
                onClick={handleClick}
              >
                Browse
              </button>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-5 text-right">
              <button
                type="button"
                className="btn btn-primary orange-outline-button"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary orange-button ml-2"
                disabled={!csvFile || ((empUploadClientFile?.clientFileId || 0) === 0)}
                onClick={onUpload}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
      {showStep1Modal && (
        <UploadEmpStep1Modal
          csvFile={csvFile}
          fileName={fileName}
          show={showStep1Modal}
          setHeaderStartingLine={setHeaderStartingLine}
          onHide={() => { return setShowStep1Modal(false); }}
          next={() => { return setShowStep2Modal(true); }}
        />
      )}
      {showStep2Modal && (
        <UploadEmpStep2Modal
          csvFile={csvFile}
          fileRows={fileRows}
          show={showStep2Modal}
          headerStartingLine={headerStartingLine}
          onHide={() => { return setShowStep2Modal(false); }}
          prev={() => { return setShowStep1Modal(true); }}
        />
      )}
      {showMissingFile && (
        <MissingFile
          show={showMissingFile}
          onHide={() => { return setShowMissingFile(false); }}
        />
      )}
      <Modal
        backdrop="static"
        keyboard={false}
        show={showLoadingBar}
        centered={true}
        fullscreen={true}
      >
        <Modal.Header>
          <Modal.Title>
            Uploading File...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProgressBar
            animated
            now={100}
          />
        </Modal.Body>
      </Modal>
      <UploadErrorModal
        errorMsg={errorInfo.errorMessage}
        show={errorInfo.hasError}
        onHide={() => {return setErrorInfo({errorMessage: '', hasError: false})}}
      />
    </div>
  );
};

export default UploadEmpPage;
