import { AxiosResponse } from "axios";
import { HttpResponse, SpecialInfo } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getSpecialInfo = (protectedEmpNo: string): Promise<AxiosResponse<SpecialInfo>> => { return API_EMPLOYEE_MASTER.get<SpecialInfo>(EmployeeEndPoints.GET_SPECIAL_INFO(CommonService.getProtectedClientNo(), protectedEmpNo)); };

const putSpecialInfo = (data: SpecialInfo): Promise<AxiosResponse<HttpResponse<SpecialInfo>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<SpecialInfo>>(EmployeeEndPoints.PUT_SPECIAL_INFO(CommonService.getProtectedClientNo(), data.protectedEmpNo), data); };

export const SpecialInfoService = {
  getSpecialInfo,
  putSpecialInfo,
};
