import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  InputGrp,
  SelectGrp,
  RadioGrp,
  SelectModalGrp,
} from 'core/components/form-controls';
import DropdownOptionForm from 'core/components/form-controls/select-modal/DropdownOptionForm';
import { FieldInputSettings } from 'core/components/form-controls/types';
import PanelHeader from 'core/components/shared/PanelHeader';
import {
  getEmployeeStatus,
  getExemptStatuses,
  getJobTitles,
  getNewJobTrainingProgram,
} from 'core/store/selectors';
import { getSupervisors } from 'core/store/selectors/employees.selector';
import { useRadioButtonChange } from 'utilities/hooks';
import { getAccess } from 'utilities/utilities';
import { BaseSectionProps } from './PayrollInfoPage';
import { ControlIds } from 'core/constants';

const PayrollInfoMiscInformation: React.FC<BaseSectionProps> = ({
  methods: { setValue, register, errors, control, watch },
  sectionAccess,
}) => {
  const jobTitleOpts = useSelector(getJobTitles);
  const exemptStatusOpts = useSelector(getExemptStatuses);
  const employeeStatusOpts = useSelector(getEmployeeStatus);
  const supervisorOpts = useSelector(getSupervisors);
  const jobTrainingCodeOpts = useSelector(getNewJobTrainingProgram);

  const {
    jobTitleId,
    employeeStatus,
    isSupervisor,
    eligibleBenefits,
    eligibleVacation,
    tippedEmployee,
    isOfficer,
    hasUnionDue,
    usesSpecialEarnings,
  } = watch([
    'jobTitleId',
    'employeeStatus',
    'isSupervisor',
    'eligibleBenefits',
    'eligibleVacation',
    'tippedEmployee',
    'isOfficer',
    'hasUnionDue',
    'usesSpecialEarnings',
  ]);

  const [fs, setFs] = useState<FieldInputSettings>({
    driversLicense: {
      name: 'driversLicense',
      label: 'DRIVER LICENSE #',
      groupClass: 'groupClass14',
    },
    jobTitleId: {
      name: 'jobTitleId',
      label: 'JOB TITLE',
      noOption: true,
      modalTitle: 'JOB TITLES',
      formComponent: DropdownOptionForm,
      addOptionText: 'Job Title',
      groupClass: 'groupClass14',
    },
    exemptStatus: {
      name: 'exemptStatus',
      label: 'EXEMPT STATUS',
      groupClass: 'groupClass14',
    },
    employeeStatus: {
      name: 'employeeStatus',
      label: 'EMPLOYEE STATUS',
      noOption: true,
      modalTitle: 'EMPLOYEE STATUSES',
      formComponent: DropdownOptionForm,
      addOptionText: 'Emp Status',
      groupClass: 'groupClass14',
    },
    supervisorEmpId: {
      name: 'supervisorEmpId',
      label: 'SUPERVISOR',
      noOption: true,
      noOptionText: 'supervisor',
      groupClass: 'groupClass14',
      labelField: 'name',
      valueField: 'empId',
    },
    secondSupervisorEmpId: {
      name: 'secondSupervisorEmpId',
      label: '2ND SUPERVISOR',
      noOption: true,
      noOptionText: 'supervisor 2',
      groupClass: 'groupClass14',
      labelField: 'name',
      valueField: 'empId',
    },
    isSupervisor: {
      name: 'isSupervisor',
      label: ['IS THIS PERSON', 'A SUPERVISOR?'],
      tallLabel: true,
      groupClass: 'groupClass15',
    },

    eligibleBenefits: {
      name: 'eligibleBenefits',
      label: ['ELIGIBLE FOR', 'BENEFITS?'],
      tallLabel: true,
      groupClass: 'groupClass14',
    },
    eligibleVacation: {
      name: 'eligibleVacation',
      label: ['ELIGIBLE FOR', 'VACATION?'],
      tallLabel: true,
      groupClass: 'groupClass14',
    },
    tippedEmployee: {
      name: 'tippedEmployee',
      label: 'TIPPED EMPLOYEE',
      tallLabel: true,
      groupClass: 'groupClass14',
    },
    isOfficer: {
      name: 'isOfficer',
      label: 'IS OFFICER',
      tallLabel: true,
      groupClass: 'groupClass14',
    },
    hasUnionDue: {
      name: 'hasUnionDue',
      label: 'HAS UNION DUE',
      tallLabel: true,
      groupClass: 'groupClass14',
    },
    usesSpecialEarnings: {
      name: 'usesSpecialEarnings',
      label: ['USE SPECIAL', 'EARNINGS'],
      tallLabel: true,
      groupClass: 'groupClass14',
    },
    jobTrainingCodeId: {
      name: 'jobTrainingCodeId',
      label: ['NEW JOB', 'TRAINING CODE'],
      tallLabel: true,
      groupClass: 'groupClass15',
      addEmptyValue: true,
    },
  });

  useEffect(() => {
    const currentState = { ...fs };
    
    for (const [key, value] of Object.entries({
      isSupervisor,
      eligibleBenefits,
      eligibleVacation,
      tippedEmployee,
      isOfficer,
      hasUnionDue,
      usesSpecialEarnings,
    })) {
      currentState[key as keyof typeof currentState].defaultChecked = value;
    }

    setFs(currentState);
  }, [jobTitleId]);

  const [handleRadioButtonChange] = useRadioButtonChange(fs);

  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Misc Information" />
      <div className="d-flex flex-row flex-wrap">
        <InputGrp
          {...fs.driversLicense}
          {...getAccess(sectionAccess, 6, ControlIds.driversLicense)}
          errors={errors.driversLicense}
          ref={register}
        />
        <SelectModalGrp
          {...fs.jobTitleId}
          {...getAccess(sectionAccess, 6, ControlIds.jobTitleId)}
          errors={errors.jobTitleId}
          control={control}
          value={jobTitleId}
          options={jobTitleOpts}
          dropdownName="JobTitle"
          setValue={setValue}
        />
        <SelectGrp
          {...fs.exemptStatus}
          {...getAccess(sectionAccess, 6, ControlIds.exemptStatus)}
          errors={errors.exemptStatus}
          ref={register}
          options={exemptStatusOpts}
        />
        <SelectModalGrp
          {...fs.employeeStatus}
          {...getAccess(sectionAccess, 6, ControlIds.employeeStatus)}
          errors={errors.employeeStatus}
          control={control}
          value={employeeStatus}
          options={employeeStatusOpts}
          dropdownName="EmployeeStatus"
          setValue={setValue}
        />
        <SelectGrp
          {...fs.supervisorEmpId}
          {...getAccess(sectionAccess, 6, ControlIds.supervisorEmpId)}
          errors={errors.supervisorEmpId}
          ref={register}
          options={supervisorOpts}
        />
        <SelectGrp
          {...fs.secondSupervisorEmpId}
          {...getAccess(sectionAccess, 6, ControlIds.secondSupervisorEmpId)}
          errors={errors.secondSupervisorEmpId}
          ref={register}
          options={supervisorOpts}
        />
        <RadioGrp
          {...fs.isSupervisor}
          {...getAccess(sectionAccess, 6, undefined, { disabledSameAsReadOnly: true })}
          errors={errors.isSupervisor}
          ref={register}
          onChange={handleRadioButtonChange}
        />

        <RadioGrp
          {...fs.eligibleBenefits}
          {...getAccess(sectionAccess, 6, undefined, { disabledSameAsReadOnly: true })}
          errors={errors.eligibleBenefits}
          ref={register}
          onChange={handleRadioButtonChange}
        />
        <RadioGrp
          {...fs.eligibleVacation}
          {...getAccess(sectionAccess, 6, undefined, { disabledSameAsReadOnly: true })}
          errors={errors.eligibleVacation}
          ref={register}
          onChange={handleRadioButtonChange}
        />
        <RadioGrp
          {...fs.tippedEmployee}
          {...getAccess(sectionAccess, 6, undefined, { disabledSameAsReadOnly: true })}
          errors={errors.tippedEmployee}
          ref={register}
          onChange={handleRadioButtonChange}
        />
        <RadioGrp
          {...fs.isOfficer}
          {...getAccess(sectionAccess, 6, ControlIds.isOfficer, { disabledSameAsReadOnly: true })}
          errors={errors.isOfficer}
          ref={register}
          onChange={handleRadioButtonChange}
        />
        <RadioGrp
          {...fs.hasUnionDue}
          {...getAccess(sectionAccess, 6, undefined, { disabledSameAsReadOnly: true })}
          errors={errors.hasUnionDue}
          ref={register}
          onChange={handleRadioButtonChange}
        />
        <RadioGrp
          {...fs.usesSpecialEarnings}
          {...getAccess(sectionAccess, 6, undefined, { disabledSameAsReadOnly: true })}
          errors={errors.usesSpecialEarnings}
          ref={register}
          onChange={handleRadioButtonChange}
        />
        <SelectGrp
          {...fs.jobTrainingCodeId}
          {...getAccess(sectionAccess, 6)}
          errors={errors.jobTrainingCodeId}
          ref={register}
          options={jobTrainingCodeOpts}
        />
      </div>
    </div>
  );
};

export default PayrollInfoMiscInformation;
