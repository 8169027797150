import React, { useContext, FocusEvent, CSSProperties, useMemo } from 'react';
import Modal from 'core/components/modals/Modal';
import { DetailHour, DetailHourMap, TimeCardDate } from 'core/models';
import { useAppSelector } from 'utilities/hooks';
import { InputGrp } from 'core/components/form-controls';
import '../time-card-styles.scss';
import { TimeCardContext } from '../TimeCardContext';
import styles from './styles.module.scss';

const stylingProps: { [key: string]: string | CSSProperties } = {
  inputStyle: { height: '30px', width: '350px' },
  groupClass: 'mb-0',
};

type Props = {
  show: boolean;
  onHide: () => void;
};

const DetailHoursNotesModal = (({ show, onHide }: Props) => {
  const { updateDirtyState, updateDetailHourState, defaultDetailHoursState } = useContext(TimeCardContext);
  
  const { timeCardDates, currentTimeCard } = useAppSelector(({ contractor }) => contractor);
  
  const detailHourMap: DetailHourMap = useMemo(() => {
    return structuredClone(defaultDetailHoursState);
  }, [defaultDetailHoursState]);

  const closeModal = () => {
    updateDetailHourState(detailHourMap);
    
    onHide();
  };
  
  return (
    <Modal
      title="Notes"
      show={show}
      onHide={closeModal}
    >
      <div
        className="d-flex flex-column"
        style={{ maxHeight: '80vh', overflow: 'auto' }}
      >
        {currentTimeCard?.details?.map((timeSheet, index) => {
          const detailMapRecord = detailHourMap?.[String(timeSheet.transmittalTimeCardDetailId)] ?? [];
          
          const modifyTimeEntryRecord = (day: TimeCardDate, nestedIndex: number) => (e: FocusEvent<HTMLInputElement, Element>) => {
            
            const match = detailMapRecord?.find((detailHour) => detailHour.dateId === day.dateId);
            if (!match) {
              const newRecord: DetailHour = {
                transmittalTimeCardDetailId: timeSheet.transmittalTimeCardDetailId ?? 0,
                transmittalTimeCardDetailHoursId: 0,
                dateId: day.dateId,
                hours: 0,
                calendarDate: new Date(day.fullDate),
                earningsCode: detailMapRecord?.[nestedIndex]?.earningsCode ?? '', // these are all the same
                notes: e.target.value,
              };
              const result = [...(detailMapRecord ?? []), newRecord];
      
              detailHourMap[String(timeSheet.transmittalTimeCardDetailId ?? 0)] = result;
              updateDetailHourState(detailHourMap);
              updateDirtyState(true);
      
              return;
            }
    
            const updatedHour: DetailHour = { ...match, notes: e.target.value };
            const clone = structuredClone(detailMapRecord);
            const hourIndex = detailMapRecord?.findIndex((hour) => hour.dateId === match?.dateId);
            clone.splice(hourIndex, 1, updatedHour);
    
            detailHourMap[String(timeSheet.transmittalTimeCardDetailId ?? 0)] = clone;
    
            updateDetailHourState(detailHourMap);
            updateDirtyState(true);
          };
          
          return (
            <div
              className="main-section w-100 mb-2"
              key={timeSheet.transmittalTimeCardDetailId}
            >
              <div className="section-title">Time sheet {index + 1} notes</div>
              <div
                key={timeSheet.transmittalTimeCardDetailId}
                className="d-flex flex-column"
              >
                {timeCardDates?.map((day, dayIndex) => {
                  const matchingField = detailMapRecord?.find((hour) => hour.dateId === day.dateId);

                  return (
                    <div
                      className={styles['time-sheet-notes']}
                      key={`${index}-${dayIndex}`}
                    >
                      <strong style={{marginRight: '15px'}}>{day.dayOfWeek}, {day.fullDate}:&nbsp;</strong>
                      <div>
                      <InputGrp
                        name={`details[${index}].hours[${dayIndex}].notes`}
                        defaultValue={matchingField?.notes ?? ''}
                        onBlur={modifyTimeEntryRecord(day, dayIndex)}
                        {...stylingProps}
                      />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="d-flex justify-content-end mt-2">
        <button
          className="btn orange-button-sm"
          onClick={closeModal}
        >
          Close
        </button>
      </div>
    </Modal>
  );
});

export default DetailHoursNotesModal;