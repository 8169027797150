import React, { useState } from 'react';
import styles from './error-card.module.scss';
import Icon from 'core/components/shared/Icon';

type Props = {
  section: string;
  errors: string[] | undefined;
};

const ErrorCard = ({ section, errors }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  
  return (
    <li
      className={styles['ec-section-card']}
    >
      <div className={styles['ec-card-header']}>
        <div className={styles['ec-card-title-container']}>
          <Icon
            name="warning"
            color="#e22200"
            className={styles['ec-warning-icon']}
          />
          <h3>{section.toUpperCase()} ({errors?.length ?? 0}&nbsp;Incompletion{errors?.length === 1 ? '' : 's'})</h3>
        </div>
        <div className={styles['ec-card-title-button-container']}>
          <button
            className="btn btn-link"
            onClick={() => { setExpanded(!expanded); }}
            title="Expand/collapse errors"
          >
            <span className="sr-only">{expanded ? 'Collapse errors' : 'Expand errors'}</span>
            <Icon
              name={expanded ? 'chevron-circle-up' : 'chevron-circle-down'}
              className={expanded ? 'roster-chevron flipped' : 'roster-chevron'}
            />
          </button>
        </div>
      </div>
      <div className={expanded ? styles['ec-card-expand'] : styles['ec-card-collapse']}>
        {expanded && (
          <div className={styles['expand-body']}>
            <ul>
              {errors && errors?.map((error, index) => {
                return (
                  <li
                    className={styles['field-error-li']}                 
                    key={index}
                  >
                    <strong>{error}</strong>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </li>
  );
};

export default ErrorCard;
