import { DtoOptions } from './Payroll.model';

export interface BankDisbursementsReportRequest {
  clientNo: number;
  dtoOptions: DtoOptions[];
  reportType: string;
}

export interface OverTimeSetting {
  clientNo: number;
  protectedClientNo?: string;
  otid?: number;
  clientID: number;
  settingName?: string;
  lowColor?: number;
  lowFrom: number;
  lowTo: number;
  midColor?: number;
  midFrom: number;
  midTo: number;
  highColor?: number;
  highFrom: number;
  highTo: number;
  allEarnings: boolean | string;
  isHours: boolean | string;
  usePremiumOnly: boolean | string;
  locationFrom?: string;
  locationTo?: string;
  deptFrom?: string;
  deptTo?: string;
  subDeptFrom?: string;
  subDeptTo?: string;
  subDept2From?: string;
  subDept2To?: string;
  pageBreakBy?: string;
  reportColorSettings?: ReportColorSetting[];
  overTimeEarnings?: OverTimeEarning[];
}

export interface OverTimeEarning {
  otEarnID: number;
  otid: number;
  earnCode: string;
}

export interface ReportColorSetting {
  r: number;
  g: number;
  b: number;
  a: number;
  assignedColorType: string;
}

export interface HoursEarningsReportRequest {
  clientNo: number;
  otid: number;
  dtoOptions: DtoOptions[];
  reportType: string;
}

export interface NewJobTrainingCreditReportRequest {
  clientNo: number;
  month: number | undefined;
  year: number | undefined;
  byPayroll: boolean | string;
  isEmployeeDetail: boolean | string;
  useSelectedDates: boolean | string;
  dtoOptions?: DtoOptions[];
  reportType: string;
}

export interface EmployeeAverageHoursWithStatusReportRequest {
  clientNo: number;
  loc: number[];
  dept: number[];
  subDept: number[];
  subDept2: number[];
  excludeTermEmp: boolean | string;
  dtoOptions: DtoOptions[];
  reportType: string;
  reportOption: string;
}

export interface EmployeeAverageHoursReportRequest {
  clientNo: number;
  loc: number[],
  dept: number[],
  subDept: number[],
  subDept2: number[],
  excludeTermEmp: boolean | string;
  dtoOptions: DtoOptions[];
  reportType: string;
  reportOption: string;
}

export interface GeneralLedgerReportRequest {
  clientNo: number;
  showEmployees: boolean | string;
  groupByLoc: boolean | string;
  byWeekendCheckDate: boolean | string;
  exportByCostCenter: boolean | string;
  dtoOptions: DtoOptions[];
  reportType: string;
}

export interface GeneralLedgerDetailReportRequest {
  glDescription: string;
  debitOrCredit: string;
  accountNumber: string;
  accountDesc: string;
  gl: number;
  glCreditAmount: number;
  glDebitAmount: number;
  clientNo: number;
  dtoOptions: DtoOptions[];
}

export interface GeneralLedgerExportReportRequest {
  clientNo: number;
  groupByLocation: boolean;
  groupByEmployee: boolean;
  combineDates: boolean;
  useCheckDate: boolean;
  generalLedgerType: string;
  powerChurchExportOptions?: PowerChurchExportOptions;
  sageExportOptions?: SageExportOptions;
  plexOptions?: PlexOptions;
  dtoOptions?: DtoOptions[];
}

export interface GeneralLedgerReport {
  reportData: string;
  generalLedgerDetailReportList: LedgerDetail[];
  dtoOptions: DtoOptions[];
  groupByLocation: boolean;
}

export interface GeneralLedgerExportResult {
  reportData: string;
  reportName: LedgerDetail;
}

export interface LedgerDetail {
  glDescription: string;
  debitOrCredit: string;
  accountNumber: string;
  accountDesc: string;
  gl: number;
  glCreditAmount: number;
  glDebitAmount: number;
  clientNo: number;
  dtoOptions?: any;
}

export interface PlexOptions {
  periodDate: string;
  postDate: string;
}

export interface SageExportOptions {
  fiscalYear: number;
  fiscalPeriod: number;
  description: string;
  endDate: string;
  companyID: string;
}

export interface PowerChurchExportOptions {
  powerChurchJournalNumber: string;
  powerChurchMemoTransaction: string;
}

export interface CARES_SBA_PPP_Request {
  clientNo: number;
  type: string;
}
export enum ThirdPartyEnum {
  CSV_SAGE100C = 'CSV_SAGE100C',
  CSV_SAGE300 = 'CSV_SAGE300',
  CSV_SAGE500 = 'CSV_SAGE500',
  CSV_SAGE_ACCPAC = 'ACCPAC',
  SAGE_INTACCTII = 'SAGE_INTACCTII',
  SAGE_INTACCT = 'SAGE_INTACCT',
  SAGE_HRMS = 'SAGE_HRMS',
  CSV_SAGE_X3 = 'CSV_SAGE_X3',

  CSV_PLEX = 'CSV_PLEX',
  CSV_PLEX_CLASSIC = 'CSV_PLEX_CLASSIC',
  CSV_PLEX_UX = 'CSV_PLEX_UX',

  CSV_POWERCHURCH = 'CSV_POWERCHURCH',

  CSV = 'CSV',
  QUICKEN2018ANDBEFORE = 'QUICKEN2018ANDBEFORE',
  QUICKBOOKS = 'QUICKBOOKS',
  SAP = 'SAP',
  DATA = 'DATA',
  GREAT_PLAINS = 'GREAT_PLAINS',
  GREAT_PLAINS_LABOR_COST = 'GREAT_PLAINS_LABOR_COST',
  CSV_GEDC = 'CSV_GEDC',
  CSV_CLOUDSUITE = 'CSV_CLOUDSUITE',
  CSV_ODOO = 'CSV_ODOO',
  CSV_IDEA = 'CSV_IDEA',
  CSV_CONTRACTOR = 'CSV_CONTRACTOR',
  CSV_GLOBALSHOP = 'CSV_GLOBALSHOP',
  CSV_CDK = 'CSV_CDK',
  CSV_GLRA = 'CSV_GLRA',
  BSA = 'BSA',
  QUICKBOOK_ONLINE = 'QUICKBOOK_ONLINE',
  EPICORPRO21_CSV = 'EPICORPRO21_CSV',
  MEDSTAR = 'MEDSTAR',
  TRANSACTION_PRO = 'TRANSACTION_PRO',
  SAMPro = 'SAMPro'
}

export interface CARES_SBA_PPP_Request {
  clientNo: number;
  type: string;
}