import React from 'react';
import InputSection from '../InputSection';
import '../time-card-styles.scss';
import { TimeCardJobRosterRateEarnings } from 'core/models/Contractor.model';
import { zeroPad } from 'utilities/utilities';

type Props = {
  rateEarnings: TimeCardJobRosterRateEarnings,
};

const JobRosterRateEarnings = ({ rateEarnings }: Props) => {
  return (
    <div className="main-section">
      <div className="section-title">Rate/Earnings</div>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column w-100">
          <InputSection
            label="Area"
            defaultValue={zeroPad(rateEarnings?.area || '', 5)}
            addtlInputStyles={{ textAlign: 'center' }}
            readOnly={true}
          />
          <InputSection
            label="Trade"
            defaultValue={zeroPad(rateEarnings?.trade || '', 5)}
            addtlInputStyles={{ textAlign: 'center' }}
            readOnly={true}
          />
          <InputSection
            label="Sub"
            defaultValue={zeroPad(rateEarnings?.sub || '', 5)}
            addtlInputStyles={{ textAlign: 'center' }}
            readOnly={true}
          />
          <InputSection
            label="Sub Desc."
            defaultValue={rateEarnings.subDesc}
            addtlInputStyles={{ textAlign: 'center' }}
            readOnly={true}
          />
          <InputSection
            label="Addtl. Rate"
            defaultValue={rateEarnings.addtlRate.toFixed(4)}
            addtlInputStyles={{ textAlign: 'right' }}
            readOnly={true}
          />
          <InputSection
            label="Trade Rate"
            defaultValue={rateEarnings.tradeRate.toFixed(4)}
            addtlInputStyles={{ textAlign: 'right' }}
            readOnly={true}
          />
          <InputSection
            label="Over Scale"
            defaultValue={rateEarnings.empPay.toFixed(4)}
            addtlInputStyles={{ textAlign: 'right' }}
            readOnly={true}
          />
          <InputSection
            label="Total Rate"
            labelStyles={{ fontWeight: 'bold' }}
            addtlInputStyles={{ textAlign: 'right' }}
            defaultValue={rateEarnings.totalRate.toFixed(4)}
            readOnly={true}
          />
        </div>
      </div>
    </div>
  );
};

export default JobRosterRateEarnings;