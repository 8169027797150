import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import RenderToolTip from './RenderToolTip';
import Icon from 'core/components/shared/Icon';

type Props = {
  modalName: string;
  onClick: () => void;
};

const ModalInputButton = ({ modalName, onClick }: Props) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={RenderToolTip({ message: `Open ${modalName} selection menu` })}
    >                
      <button
        className="btn open-modal-button"
        type="button"
        onClick={onClick}
      >
        <Icon
          name="arrow-up-right-from-square"
          className="fa-arrow-up-right-from-square p-0"
        />  
        <span className="sr-only">Open {modalName} selection menu</span>
      </button>
    </OverlayTrigger>
  );
};

export default ModalInputButton;