import React, { useContext } from 'react';
import Modal from 'core/components/modals/Modal';
import { DetailOtherEarning } from 'core/models';
import SummaryEarningsItem from '../time-sheet-summary/SummaryEarningsItem';
import Icon from 'core/components/shared/Icon';
import { TimeSheetSummaryContext } from '../time-sheet-summary/TimeSheetSummary';
import { generateNegativeId } from 'utilities/utilities';

type Props = {
  timeCardId: number;
  timeCardDetailId: number;
  otherEarnings: DetailOtherEarning[];
  timeCardIndex: number;
  detailIndex: number;
  showLabel?: boolean;
  canEdit?: boolean;
  show: boolean;
  onHide: () => void;
};

const SummaryEarningsModal = ({
  show,
  onHide,
  timeCardId,
  timeCardDetailId,
  otherEarnings,
  timeCardIndex,
  detailIndex,
  showLabel,
  canEdit,
}: Props) => {
  const { updateTimeCardState } = useContext(TimeSheetSummaryContext);
  
  const addEarnings = () => {
    const newEarning: DetailOtherEarning = {
      transmittalTimeCardDetailId: timeCardDetailId,
      transmittalTimeCardDetailOtherEarningsId: generateNegativeId(),
      earningsCode: '',
      recurringEarningId: null,
      amount: 0,
    };
    
    updateTimeCardState({ type: 'ADD_EARNING', newEarning, timeCardId });
  };

  const removeEarnings = (_timeCardId: number, _timeCardDetailId: number, timeCardEarningId: number) => {
    updateTimeCardState({
      type: 'DELETE_EARNING',
      timeCardId: _timeCardId,
      timeCardDetailId: _timeCardDetailId,
      timeCardEarningId,
    });
  };
  
  return (
    <Modal
      title="Earnings"
      show={show}
      onHide={onHide}      
    >
      <div className="d-flex justify-content-end">
        <button
          className="section-action btn btn-link dm-grid-action-title p-0 d-flex no-wrap"
          style={{ whiteSpace: 'nowrap' }}
          onClick={addEarnings}
        >
          Add Earnings 
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button>
      </div>
      {!otherEarnings?.find(x => x.transmittalTimeCardDetailId !== undefined) ? (
        <div className="add-entry">
          Click &ldquo;Add Earnings&rdquo;
        </div>
      ) :
        otherEarnings?.map((earning, earningIndex) => {    
          if (earning.transmittalTimeCardDetailId === undefined) return;
          return (
            <SummaryEarningsItem
              timeCardId={timeCardId}
              key={earning.transmittalTimeCardDetailOtherEarningsId}
              otherEarning={earning}
              timeCardIndex={timeCardIndex}
              detailIndex={detailIndex}
              earningIndex={earningIndex}    
              showLabel={showLabel}
              removeEarnings={removeEarnings}
              canEdit={canEdit}
            />
          );
        },
        )}
    </Modal>
  );
};

export default SummaryEarningsModal;