import React, { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  Dropdown,
  EarningCode,
  Employee,
  PayrollsUserOptions,
  TransmittalCheck,
  TransmittalEarning,
} from 'core/models';
import TransmittalModalEarningsItem from './TransmittalModalEarningsItem';
import Icon from 'core/components/shared/Icon';
import { useSelector } from 'react-redux';
import { getIsFinishedPayroll, getPayrollHasSignedWireOnlyAgreement } from 'core/store/selectors';
import { FormMethods } from 'core/components/form-controls/types';

type Props = {
  control: any; // TODO: type control; seems to work slightly differently than the other form types.
  check: TransmittalCheck;
  checkIndex: number;
  earningsCode: Dropdown[];
  isReadOnly: boolean;
  payrollsUserOptions: PayrollsUserOptions | null;
  register: FormMethods['register'];
  errors: FormMethods['errors'];
  employee: Employee;
  earningCodeSettings: EarningCode[];
  setValue: FormMethods['setValue'];
  watch: FormMethods['watch'];
};
const TransmittalModalEarnings = ({
  control,
  check,
  checkIndex,
  earningsCode,
  isReadOnly,
  payrollsUserOptions,
  register,
  errors,
  employee,
  earningCodeSettings,
  setValue,
  watch,
}: Props) => {
  const { fields, remove, append } = useFieldArray<TransmittalEarning>({
    control,
    name: `checks[${checkIndex}].earnings`,
    keyName: 'id',
  });
  
  const inputFields =
    Array.from(
      document.querySelectorAll(
        "input[class*='form-controls_dm-form-control'][id^='checks'][type='text']",
      ),
    ) || [];

  const handleKeyPress = (key: string) => {
    const activeInput = document?.activeElement;
    const navKeys = ['ArrowDown', 'ArrowUp'];
    if (!activeInput || !navKeys.includes(key)) return;

    const activeIndex = inputFields.indexOf(activeInput);
    const increment = key === 'ArrowDown' ? 1 : key === 'ArrowUp' ? -1 : 0;
    const target = inputFields[activeIndex + increment];

    if (!target) return;
    (target as HTMLElement).focus();
  };

  const onClick = () => {
    const earningsToAdd: Partial<TransmittalEarning>[] = [];

    //PI-8701 Will see if the employee's Tran Digit is 3 (Include both salary and hourly) if so we want to add both hourly and salary when adding an earnings line.
    check.payRateDropdown.filter((payRate) => {
      if (payRate.empTranDigit === -1) return;
      earningsToAdd.push({
        amount: 0,
        tracked: false,
        altRate: 0,
        autoCalcResultId: null,
        shiftPremiumId: null,
        dept: employee?.dept ?? 0,
        subDept: employee?.subDept ?? 0,
        subDept2: employee?.subDept2 ?? 0,
        rateId: payRate.rateId,
        tranDigit: payRate.tranDigit,
        transmittalEarningsId: parseInt((Math.random() * -10000).toString()),
      });
    });

    append(earningsToAdd);
  };

  return (
    <>
      <div className="text-right mt-2 mb-2">
        {!isReadOnly ? <button
          type="button"
          className="btn btn-link dm-grid-action-title p-0"
          onClick={onClick}
        >
          Add Earnings
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button> : null}
      </div>
      <div onKeyDown={(e) => { return handleKeyPress(e.key); }}>
        {fields.map((earning, earningIndex) => {
          return (
            <TransmittalModalEarningsItem
              key={earning.id}
              earning={earning}
              checkIndex={checkIndex}
              earningIndex={earningIndex}
              register={register}
              earningsCode={earningsCode}
              isReadOnly={isReadOnly}
              errors={errors}
              payrollsUserOptions={payrollsUserOptions}
              check={check}
              remove={remove}
              employee={employee}
              setValue={setValue}
              watch={watch}
              earningCodeSettings={earningCodeSettings}
              control={control}
            />
          );
        })}
      </div>
    </>
  );
};

export default TransmittalModalEarnings;
