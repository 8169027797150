export type BaseCorrection = {
  mescId: number;
  clientNo: number;
  empNo: number;
  checkDate: string;
  weekEnd: string;
  checkNo: number;
  firstName: string;
  midName: string;
  lastName: string;
};

export type CorrectionsGetRequest = {
  empNos: string;
  beginDate: string;
  endDate: string;
};

export type DeptAllocationCorrectionsGetRequest = {
  empNo: string;
  weekEnd: string;
  checkDate: string;
};

export type MescEarnDeptAllocPostRequest = Pick<EarningAllocation, 'earnId' | 'correctionLoc' | 'correctionDept' | 'correctionSub' | 'correctionSub2'>;

export type MescEarnDeptAllocDeleteRequest = {
  empNo: string;
  mescId: number;
  earnId: number;
};

export type UpdateRequest<T> = { empNo: string, allocations: T };

export type MescIdsArray = number[];

export type EEOInfoCorrection = BaseCorrection & {
  eeoRace: string;
  eeoOccupGrp: string;
  eeoUnitNo: string;
};

export type UpdateEEOInfoRequest = {
  newEEORace: string | null;
  newEEOOccupGroup: string | null;
  newEEOUnitNo: string | null;
  mescIdsToUpdate: MescIdsArray;
};

export type WorkersCompCorrection = BaseCorrection & {
  wcCode: string;
  isOfficer: string;
};

export type UpdateWorkersCompRequest = {
  newWcCode: string | null;
  newIsOfficer: string | null;
  mescIdsToUpdate: MescIdsArray;
};

export type EarningAllocation = {
  mescId: number;
  earnId: number;
  transmittalLoc: number;
  transmittalDept: number;
  transmittalSub: number;
  transmittalSub2: number;
  rate: number;
  payType: string;
  hours: number;
  earnings: number;
  correctionLoc: number;
  correctionDept: number;
  correctionSub: number;
  correctionSub2: number;
  correctionDeptDesc: string;
  correctionSubDesc: string;
  correctionSub2Desc: string;
};

export type AllocationDetail = {
  detailId?: number;
  deptAllocationId: number;
  loc: number;
  dept: number;
  sub: number;
  sub2: number;
  percent: number;
};

export type DeptAllocation = {
  deptAllocationId?: number;
  beginDate: string | Date | null;
  endDate: string | Date | null;
  transmittalLocation: number;
  transmittalDept: number;
  transmittalSub: number;
  transmittalSub2: number;
  allocationDetails: AllocationDetail[];
};

export type DeptAllocationWithOptionalId = DeptAllocation & { deptAllocationId?: number | undefined };

export type WageAllocation = {
  loc: number;
  dept: number;
  sub: number;
  sub2: number;
  payType: string;
  checkType: string | null;
  checkNo: number | null;
  percent: number;
  earnings: number;
  locDesc: string;
  deptDesc: string;
  subDesc: string;
  sub2Desc: string;
  locWithDesc: string;
  deptWithDesc: string;
  subWithDesc: string;
  sub2WithDesc: string;
};

export type DeptAllocationDTO = {
  empNo: number;
  clientNo: number;
  deptAllocations: DeptAllocation[];
  earnAllocations: EarningAllocation[];
  allocatedWages: WageAllocation[];
};

export type AllocationEmployee = {
  empNo: number;
  protectedEmpNo: string;
  clientNo: number;
  firstName: string;
  middleName: string;
  lastName: string;
  isTerminated: boolean;
  hasAllocations: boolean;
};

export enum CorrectionsReportType {
  CurrentAllocationAllEmployee = 'CurrentAllocationAllEmployee',
  CurrentAllocationSelectedEmployee = 'CurrentAllocationSelectedEmployee',
  CurrentAllocationActiveEmployee = 'CurrentAllocationActiveEmployee',
}

export type CorrectionsReportRequest = {
  beginDate: string;
  endDate: string;
  clientNo: number;
  selectedEmpNo: number;
  reportType: 'pdf' | 'excel';
  allocationReportType: CorrectionsReportType;
};
