import { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Client } from '../../core/models';
import { useAppSelector } from '../../utilities/hooks';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import { CumulativeSeniorityReportRequest } from '../../core/models/HumanCapitalReports.model';
import { ControlDatePickerGrpInLine, RadioGrp } from '../../core/components/form-controls';
import { RadioOptions } from '../../core/components/form-controls/RadioGrp';
import { clearCumulativeSeniorityReport, downloadCumulativeSeniorityReport } from '../../core/store/actions';
import { getCumulativeSeniorityReport } from '../../core/store/selectors';
import { useForm } from 'react-hook-form';

const status: RadioOptions[] = [
  {
    value: 'All',
    label: 'All Employees',
  },
  {
    value: 'Active',
    label: 'Active Employees',
  },
  {
    value: 'Terminated',
    label: 'Terminated Employees',
  },
];

const CumulativeSeniorityReport = () => {

  const dispatch = useDispatch();

  const childRef: React.RefObject<any> = useRef();

  const report = useSelector(getCumulativeSeniorityReport);

  const client = useAppSelector((state) => {return state.client.client;}) as Client;

  const { getValues, register, control } =
        useForm<CumulativeSeniorityReportRequest>({
          defaultValues: {
            status: 'All',
          },
        });

  const downloadReport = () => {
    const submitData: CumulativeSeniorityReportRequest = {
      clientNo: client.clientNo,
      asOfDate: getValues('asOfDate'),
      status: getValues('status'),
      reportType: 'pdf',
    };
    dispatch(downloadCumulativeSeniorityReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearCumulativeSeniorityReport());
  };

  return (<div>
    <div className="dm-panel dm-panel-border">
      <div className="dm-grid-title">Cumulative Seniority Report</div>
      <hr className="dm-panel-hr" />

      <div className="d-flex flex-row flex-grow-1">
        <div className="d-flex ">
          <div>

            <ControlDatePickerGrpInLine
              name={'asOfDate'}
              label={'Select Date'}
              labelWidth={40}
              groupClass={'groupClass50'}
              required={true}
              control={control}
              maxDate={new Date()}

              rules={{
                required: ' Date is required',
              }}
            />

          </div>
          <div>
            <RadioGrp
              radioOptions={status}
              name="status"
              ref={register}
            />
          </div>
        </div>
        <div className="flex-grow-1 text-right">
          <button
            type="button"
            className="orange-outline-button mr-2"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="orange-button mr-2"
            onClick={downloadReport}
          >
            Run Report
          </button>
        </div>
      </div>
    </div>
    {report ? <PDFViewerModal
      show={report.length > 0}
      pdfData={report}
      onHide={hidePdfViewer}
      reportName="CumulativeSeniorityReport"
    /> : null}
  </div>
  );



};
export default CumulativeSeniorityReport;


