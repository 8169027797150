import React from 'react';
import { useSelector } from 'react-redux';
import { InputGrp } from 'core/components/form-controls';
import { SelectGrp } from 'core/components/form-controls/SelectGrp';
import { getIsFinishedPayroll } from 'core/store/selectors';
import Icon from 'core/components/shared/Icon';
import { formatWithCommas } from 'utilities/utilities';

const localWithholdings = [1];

type Props = {
  index: number;
  errors: any;
};

const LocalWithholdings: React.FC<Props> = ({
  index,
  errors,
}) => {
  const isFinishedPayroll =  useSelector(getIsFinishedPayroll);

  const onAdd = () => { };
  
  const onDelete = (id: number) => { };

  return (
    <>
      <div className="text-right mt-2 mb-2">
        {!isFinishedPayroll ? <button
          type="button"
          className="btn btn-link dm-grid-action-title p-0"
          onClick={onAdd}
          disabled={false}
        >
          Add Withholding
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button> : null}
      </div>
      <hr />
      {localWithholdings.map((localtax, li) => {
        return (
          <div
            className="d-flex align-items-end"
            key={li}
          >
            <div className="mr-2">
              <SelectGrp
                groupClass={'mw100'}
                options={[]}
                name={`items[${index}].localWithholdings[${li}].taxEntityId`}
                id="taxEntity"
                value={''}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                }}
                disabled={false}
              />
            </div>
            <div className="mr-2">
              <InputGrp
                groupClass="number-underline"
                label="Amount"
                name={`items[${index}].localWithholdings[${li}].withholding`}
                defaultValue={formatWithCommas(0)}
                disabled={false}
              />
            </div>
            <div className="mr-2">
              <InputGrp
                groupClass="number-underline"
                label="ERTax"
                name={`items[${index}].localWithholdings[${li}].employerTax`}
                defaultValue={formatWithCommas(0)}
                disabled={true}
              />
            </div>
            <div>
              <InputGrp
                groupClass="number-underline"
                label="Gross"
                type="text"
                name={`items[${index}].localWithholdings[${li}].gross`}
                defaultValue={formatWithCommas(0)}
                disabled={true}
              />
            </div>
            {(
              <button
                type="button"
                className="btn btn-link dm-grid-action-title"
                onClick={() => { onDelete(li); }}
              >
                <Icon
                  name="minus-circle"
                  className="fa-minus-circle"
                />
              </button>
            )}
          </div>
        );
      })}
    </>
  );
};

export default LocalWithholdings;
