import { EmFieldValidation as Em } from 'types';
import { Dropdown } from './models';

export const SSN_VALIDATION_NO_DASH = /^(?!000|666|111-11-1111|333-33-3333|123-45-6789)[0-8][0-9]{2}(?!00)[0-9]{2}(?!0000)[0-9]{4}$/;
export const SSN_VALIDATION = /^(?!000|666|111-11-1111|333-33-3333|123-45-6789)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
export const PHONE_VALIDATION_UNFORMATTED = /^\d{10}$/;
export const PHONE_VALIDATION = /^\(\d{3}\)\s?\d{3}[\s-]\d{4}$/;

export const ZIP_VALIDATION = /^[0-9]{5,5}$/;

export const CURRENCY_REGEX = /^[+-]?\$?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{0,2})?$/;
export const CURRENCY_VALIDATION = {
  pattern: {
    value: CURRENCY_REGEX,
    message: 'Invalid Currency Value',
  },
};

export const CURRENCY_REGEX2 = /^[+]?\$?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{0,2})?$/;
export const CURRENCY_VALIDATION2 = {
  pattern: {
    value: CURRENCY_REGEX2,
    message: 'Invalid Currency Value, Must be greater than or equal to 0',
  },
};

// variable number of decimal places
export const CURRENCY_REGEX_VARIABLE = (decimals = 2) => new RegExp(`^[+-]?\\$?[0-9]{1,3}(?:,?[0-9]{3})*(?:\\.[0-9]{0,${decimals}})?$`);
export const CURRENCY_VALIDATION_VARIABLE = (decimals = 2) => ({
  pattern: {
    value: CURRENCY_REGEX_VARIABLE(decimals),
    message: 'Invalid Currency Value',
  },
});

export const EMAIL_VALIDATION = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const UNSAVED_MESSAGE = 'You have unsaved changes. Are you sure you want to leave this page?';
export const CATEGORY_CHANGE_MESSAGE = 'You are about to change the category which will change some fields. Are you sure you want to change categories?';
export const ACTIVATION_NOTICE_MESSAGE = 'Please contact Customer Service at 248.244.3293 or email support@dmpayroll.com to activate this option.';

export const SSN_INFO_MESSAGE = 'SSNs cannot start with 9xx, 666, or 000. Neither the group number (middle two digits) nor the' +
  'serial number (last four digits) can be all zeros. Additionally, SSN cannot be 111-11-1111, 333-33-3333 or 123-45-6789.';

import {
  MAIN_EM_VALIDATION_ACTIONS,
  DED_EM_VALIDATION_ACTIONS,
  PAYRATE_EM_VALIDATION_ACTIONS,
  TAX_EM_VALIDATION_ACTIONS,
  ACA_EM_VALIDATION_ACTIONS,
  RATERULES_EM_VALIDATION_ACTIONS,
  OTHER_DATE_EM_VALIDATION_ACTIONS,
  HIREHISTORY_EM_VALIDATION_ACTIONS,
  EMPS_EM_VALIDATION_ACTIONS,
  DEFCOMP_EM_VALIDATION_ACTIONS,
  GTL_EM_VALIDATION_ACTIONS,
  PAYROLLINFO_EM_VALIDATION_ACTIONS,
  RECURRINGEARNINGS_EM_VALIDATION_ACTIONS,
  SELFSERVICE_EM_VALIDATION_ACTIONS,
  SPECIALINFO_EM_VALIDATION_ACTIONS,
  VISASTATUS_EM_VALIDATION_ACTIONS,
} from './store/actions';

// combine all of the "update" acitons we want to actually run validation with
// on all of these we also specify "as const" to tell TS that we do NOT want to broaden this type to just string[]. See below comment for more info.
export const EM_ACTIONS = [
  ...Object.values(MAIN_EM_VALIDATION_ACTIONS),
  ...Object.values(DED_EM_VALIDATION_ACTIONS),
  ...Object.values(PAYRATE_EM_VALIDATION_ACTIONS),
  ...Object.values(TAX_EM_VALIDATION_ACTIONS),
  ...Object.values(ACA_EM_VALIDATION_ACTIONS),
  ...Object.values(RATERULES_EM_VALIDATION_ACTIONS),
  ...Object.values(OTHER_DATE_EM_VALIDATION_ACTIONS),
  ...Object.values(HIREHISTORY_EM_VALIDATION_ACTIONS),
  ...Object.values(EMPS_EM_VALIDATION_ACTIONS),
  ...Object.values(DEFCOMP_EM_VALIDATION_ACTIONS),
  ...Object.values(GTL_EM_VALIDATION_ACTIONS),
  ...Object.values(PAYROLLINFO_EM_VALIDATION_ACTIONS),
  ...Object.values(RECURRINGEARNINGS_EM_VALIDATION_ACTIONS),
  ...Object.values(SELFSERVICE_EM_VALIDATION_ACTIONS),
  ...Object.values(SPECIALINFO_EM_VALIDATION_ACTIONS),
  ...Object.values(VISASTATUS_EM_VALIDATION_ACTIONS),
] as const; // we add the const assertion so we can build a union type from this array (see validate function for use)

// I guess this isn't exactly a constant... the prefix will change
export const GET_EM_ROUTES = (protectedEmpNo: string) => {
  const prefix = `/employee/detail/${protectedEmpNo}/`;
  
  return {
    'EMPINFO': `${prefix}snapshot`,
    'EMERGENCYCONTACT': `${prefix}emergency-contact`,
    'DIRECTDEPOSIT': `${prefix}direct-deposit`,
    'DEDUCTIONS': `${prefix}deductions`,
    'PAYRATE': `${prefix}payrate`,
    'TAXES': `${prefix}taxes`,
    'EMPACA': `${prefix}aca`,
    'ACCRUAL': `${prefix}accruals`,
    'ATTENDANCEONDEMAND': `${prefix}attendance-on-demand`,
    'RATERULES': `${prefix}client-rate-rules`,
    'COMPANYBENEFITS': `${prefix}company-benefits`, // hmmm, what should this actually be?
    'DATES': `${prefix}dates`,
    'DEFERREDCOMPENSATION': `${prefix}deferred-comp`,
    'GROUPTERMLIFE': `${prefix}group-term-life`,
    'PAYROLLINFO': `${prefix}payroll-info`,
    'RECURRINGEARNINGS': `${prefix}recurring-earnings`,
    'SELFSERVICE': `${prefix}self-serve`,
    'SPECIALINFO': `${prefix}special-info`,
    'VISASTATUS': `${prefix}visa-status`,
    'YTD': `${prefix}ytd`,
  };
};

// bridges object property names with CM controlIds
export enum ControlIds {
  // #region EMPINFO
  firstName = 116,
  midName = 121,
  lastName = 120,
  suffix = 127,
  sex = 100,
  address = 110,
  city = 113,
  state = 102,
  zip = 129,
  zip4 = 130,
  ssn = 126,
  birthDate = 111,
  hireDate = 117,
  hireDate2 = 118,
  loc = 101,
  dept = 99,
  subDept = 103,
  subDept2 = 153,
  email = 114,
  email2 = 171,
  nickName = 193,
  preferredPronouns = 195,
  homePhone = 119,
  cellPhone = 112,
  maritalStatus = 170,
  // #endregion
  // #region PAYRATE
  hourlyRate = 160,
  salaryRate = 161,
  annualRate = 162,
  tranDigit = 163,
  payPeriod = 141,
  // #endregion
  // #region TAXES
  taxGrid = 109,
  // #endregion
  // #region DEDUCTIONS
  withholdingsGrid = 106, 
  // #endregion
  // #region PAYROLLINFO
  // special reporting
  workersCompCode = 143,
  eeo = 145,
  eeoOccupGroup = 157,
  eeoUnitNo = 185,
  pensionEligible = 132,
  federalBlsSocCodeId = 210,
  // timekeeping
  eicCode = 142,
  clockNo = 190,
  shiftCode = 144,
  payCategoryId = 172,
  payGroupId = 176,
  // time and attendance (NOVA only)
  shiftNumber = 183,
  payPolicyId = 184,
  group5LookUpId = 179,
  group6LookUpId = 180,
  group7LookUpId = 181,
  group8LookUpId = 182,
  // misc info
  driversLicense = 174,
  jobTitleId = 159,
  exemptStatus = 146,
  employeeStatus = 158,
  supervisorEmpId = 177,
  secondSupervisorEmpId = 178,
  isOfficer = 136,
  // tax info
  nonResidentAlien = 165,
  hasE945 = 147,
  // payroll info controls
  highGross = 139,
  highHours = 137,
  fixedHours = 140,
  // #endregion
  // #region DATES
  reviewDate = 125,
  termDate = 128,
  raiseDate = 124,
  otherDate = 123,
  // #endregion
  // some others in here that don't do anything, so moving on to accruals
  // #region ACCRUAL
  ruleDescription = 188,
  accrualHireDate = 173,
  // #endregion
  // #region DEFERREDCOMPENSATION
  eligible401KDate = 186,
  eligibleDate401KMatch = 187,
  divisionId = 194,
  // #endregion
  // #region GROUPTERMLIFE
  startDate = 200,
  multiplier = 201,
  amountOfIns = 202,
  age = 203,
  monthlyCostPerThousand = 204,
  excessIns = 205,
  annualCost = 206,
  perPayAmount = 207,
  earningsCode = 208,
  autoAddToRecurringEarnings = 209,
  // #endregion
  // #region SPECIALINFO
  employeeNavigatorFullTime = 191,
  blockEmployeeNavigator = 192,
  benefitClassClode = 196,
  // #endregion
  // #region EMPACA
  status = 189,
  // #endregion
  // #region VISASTATUS
  visaTypeId = 197,
  issueDate = 198,
  expireDate = 199,
  // #endregion
}

export enum DisplayNames {
  'First name' = 116,
  'Middle name' = 121,
  'Last name' = 120,
  'Suffix' = 127,
  'Sex' = 100,
  'Address' = 110,
  'City' = 113,
  'State' = 102,
  'ZIP code' = 129,
  'ZIP code + 4' = 130,
  'Social security number' = 126,
  'Birth date' = 111,
  'Hire date' = 117,
  'Hire date 2' = 118,
  'Location' = 101,
  'Department' = 99,
  'Subdepartment' = 103,
  'Subdepartment 2' = 153,
  'Email' = 114,
  'Email 2' = 171,
  'Preferred name' = 193,
  'Preferred pronouns' = 195,
  'Home phone' = 119,
  'Cell phone' = 112,
  'Marital status' = 170,
  'Hourly rate' = 160,
  'Salary rate' = 161,
  'Annual rate' = 162,
  'Trandigit' = 163,
  'Pay period' = 141,
  // special reporting
  "Workers' comp. code" = 143,
  'EEO race' = 145,
  'EEO occupational group' = 157,
  'EEO unit number' = 185,
  'Pension eligible' = 132,
  // timekeeping
  'EIC code' = 142,
  'Clock number' = 190,
  'Shift code' = 144,
  'Pay category' = 172,
  'Pay group' = 176,
  // time and attendance (NOVA only)
  'Shift number' = 183,
  'Pay policy' = 184,
  'Group 5 lookup ID' = 179,
  'Group 6 lookup ID' = 180,
  'Group 7 lookup ID' = 181,
  'Group 8 lookup ID' = 182,
  // misc info
  'Drivers license' = 174,
  'Job title' = 159,
  'Exemption status' = 146,
  'Employee status' = 158,
  'Supervisor emp. ID' = 177,
  'Second supervisor emp. ID' = 178,
  'Is officer' = 136,
  // tax info
  'Is non-resident alien' = 165,
  'Has E945' = 147,
  // payroll info controls
  'High gross' = 139,
  'High hours' = 137,
  'Fixed hours' = 140,
  'Review date' = 125,
  'Term date' = 128,
  'Raise date' = 124,
  'Other date' = 123,
  'Accrual title' = 188,
  'Accrual hire date' = 173,
  '401k eligible date' = 186,
  '401k eligible date match' = 187,
  'Division ID' = 194,
  'Start date' = 200,
  'Multiple of salary' = 201,
  'Amount of group term life insurance' = 202,
  'Age' = 203,
  'Monthly cost per thousand' = 204,
  'Excess insurance' = 205,
  'Annual cost' = 206,
  'Per pay amount' = 207,
  'Earnings code' = 208,
  'Auto add to recurring earnings' = 209,
  'Employee navigator fulltime' = 191,
  'Block employee navigator' = 192,
  'Benefit class code' = 196,
  'ACA status' = 189,
  'Visa type' = 197,
  'Issue date' = 198,
  'Expire date' = 199,
}

export const ControlIdBySection: Partial<Record<Em.ValidateSection, Record<string, number>>> = {
  // #region EMPINFO
  empinfo: {
    firstName: 116,
    midName: 121,
    lastName: 120,
    suffix: 127,
    sex: 100,
    address: 110,
    city: 113,
    state: 102,
    zip: 129,
    zip4: 130,
    ssn: 126,
    birthDate: 111,
    hireDate: 117,
    loc: 101,
    dept: 99,
    subDept: 103,
    subDept2: 153,
    email: 114,
    email2: 171,
    nickName: 193,
    preferredPronouns: 195,
    homePhone: 119,
    cellPhone: 112,
    maritalStatus: 170,
  },
  // #endregion
  // #region PAYRATE
  payrate: {
    hourlyRate: 160,
    salaryRate: 161,
    annualRate: 162,
    tranDigit: 163,
    payPeriod: 141,
  },
  // #endregion
  // #region PAYROLLINFO
  payrollinfo: {
    // special reporting
    workersCompCode: 143,
    eeo: 145,
    eeoOccupGroup: 157,
    eeoUnitNo: 185,
    pensionEligible: 132,
    // timekeeping
    eicCode: 142,
    clockNo: 190,
    shiftCode: 144,
    payCategoryId: 172,
    payGroupId: 176,
    // time and attendance (NOVA only)
    shiftNumber: 183,
    payPolicyId: 184,
    group5LookUpId: 179,
    group6LookUpId: 180,
    group7LookUpId: 181,
    group8LookUpId: 182,
    // misc info
    driversLicense: 174,
    jobTitleId: 159,
    exemptStatus: 146,
    employeeStatus: 158,
    supervisorEmpId: 177,
    secondSupervisorEmpId: 178,
    isOfficer: 136,
    // tax info
    nonResidentAlien: 165,
    hasE945: 147,
    // payroll info controls
    highGross: 139,
    highHours: 137,
    fixedHours: 140,
  },
  // #endregion
  // #region DATES
  dates: {
    reviewDate: 125,
    hireDate: 117,
    hireDate2: 118,
    termDate: 128,
    raiseDate: 124,
    otherDate: 123,
  },
  // #endregion
  // some others in here that don't do anything, so moving on to accruals
  // #region ACCRUAL
  accrual: {
    ruleDescription: 188,
    accrualHireDate: 173,
  },
  // #endregion
  // #region DEFERREDCOMPENSATION
  deferredcompensation: {
    eligible401KDate: 186,
    eligibleDate401KMatch: 187,
    divisionId: 194,
  },
  // #endregion
  // #region GROUPTERMLIFE
  grouptermlife: {
    startDate: 200,
    multiplier: 201,
    amountOfIns: 202,
    age: 203,
    monthlyCostPerThousand: 204,
    excessIns: 205,
    annualCost: 206,
    perPayAmount: 207,
    earningsCode: 208,
    autoAddToRecurringEarnings: 209,
  },
  // #endregion
  // #region SPECIALINFO
  specialinfo: {
    employeeNavigatorFullTime: 191,
    blockEmployeeNavigator: 192,
    benefitClassClode: 196,
  },
  // #endregion
  // #region EMPACA
  empaca: {
    status: 189,
  },
  // #endregion
  // #region VISASTATUS
  visastatus: {
    visaTypeId: 197,
    issueDate: 198,
    expireDate: 199,
  },
  // #endregion
} as const;

export const isTestEnvironment = process.env.NODE_ENV === 'test';

export const adjustmentCodeOpts = [
  { id: 'ThirdPartySick', description: 'Third Party Sick' },
  { id: 'PrePaid', description: 'Pre Paid' },
  { id: 'VoidCash', description: 'Void - Cash' },
  { id: 'VoidCorrection', description: 'Void - Correction' },
] as Dropdown[];

