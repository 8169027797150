import React from 'react';
import Modal from 'core/components/modals/Modal';

type Props = {
  show: boolean;
  onHide(): void;
  onAccept(): void;
};

const InterviewEmailsModal = ({ show, onHide, onAccept }: Props) => {
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      title="Interview Emails"
      size="sm"
    >
      <p>Email(s) sent successfully</p>
      <div className="d-flex align-items-center justify-content-end">
        <button
          type="button"
          className="btn btn-primary orange-button mr-2 mt-2"
          onClick={onAccept}
        >
          Okay
        </button>
      </div>
    </Modal>
  );
};

export default InterviewEmailsModal;
