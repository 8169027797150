import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import DownloadEmployeeDocsRenderer from 'utilities/ag-grid-renderers/downloadEmployeeDocsRenderer';
import ViewEmployeeDocsRenderer from 'utilities/ag-grid-renderers/ViewEmployeeDocsRenderer';
import { ColDef } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { loadReferenceGuide } from 'core/store/actions/help-reference-guide.action';
import { getReferenceGuide } from 'core/store/selectors/help-reference-guide.selector';
import FolderList from 'features/LibraryShared/FolderList';

 const HelpReferenceGuide = () => {
   const dispatch = useDispatch();

   useEffect(() => {
       dispatch(
         loadReferenceGuide(),
       );
   }, []);

  const folder = useSelector(getReferenceGuide);
  const [activeFolder, setActiveFolder] = useState<string>('');
  const [activeParent, setActiveParent] = useState<string>('');
  const [activeFolderName, setActiveFolderName] = useState<string>('');
  const filesFromPath = folder?.folders.find(x => x.name === activeParent)?.folders.find(x => x.path === activeFolder)?.files;

  const columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'File Name',
      sortable: true,
      editable: false,
      resizable: true,
      suppressSizeToFit: false,
      suppressAutoSize: false,
    },
    {
      field: 'fileSize',
      headerName: 'File Size',
      sortable: true,
    },
    {
      field: 'modifyDate',
      headerName: 'Modify Date',
      sortable: true,
    },
    {
      field: 'path',
      headerName: 'Download',
      cellRenderer: DownloadEmployeeDocsRenderer,
    },
    {
      field: 'path',
      headerName: 'View',
      cellRenderer: ViewEmployeeDocsRenderer,
    },
  ];

  return (
    <div className="dm-panel dm-panel-border">
      <p className="dm-grid-title">Help Center Reference Guide</p>
      <hr className="dm-panel-hr" />
      <div
        id="layout"
        className="d-flex flex-row justify-content-space-around"
      >
        <div style={{ width: '500px' }}>
          <div className="d-flex flex-column flex-grow-1">
            <FolderList
              folders={[
                {
                  name: folder?.name ?? "",
                  folders: folder?.folders ?? [],
                  path: '',
                  noMargin: true,
                  parentName: folder?.parentName ?? "",
                },
              ]}
              activeFolder={activeFolder}
              setActiveFolder={setActiveFolder}
              setActiveParent={setActiveParent}
              setActiveFolderName={setActiveFolderName}
              defaultActiveKey={folder?.name}
            />
          </div>
        </div>
        <div
          className="ag-theme-balham"
          style={{
            maxWidth: 1000,
            minHeight: 200,
            width: '100%',
          }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={filesFromPath || []}
            overlayNoRowsTemplate={
              '<span>No Changes to Display</span>'
            }
          />
        </div>
      </div>
    </div>
  );
};

export default HelpReferenceGuide;