import { createSelector } from 'reselect';
import { hrEmployeeInfoState } from '../reducers';
import { HrEmployeeInfoState } from '../reducers/hr-employee.reducer';

export const getEmpHireGeneral = createSelector(
  hrEmployeeInfoState,
  (state: HrEmployeeInfoState) => {return state.employeeHireGeneral;},
);

export const getHireInfoCustomFields = createSelector(
  hrEmployeeInfoState,
  (state: HrEmployeeInfoState) => {return state.hireInfoCustomerFields;},
);

export const getHireInfoEmployment = createSelector(
  hrEmployeeInfoState,
  (state: HrEmployeeInfoState) => {return state.employments;},
);

export const getHireInfoEducation = createSelector(
  hrEmployeeInfoState,
  (state: HrEmployeeInfoState) => {return state.educations;},
);

export const getHireInfoLicense = createSelector(
  hrEmployeeInfoState,
  (state: HrEmployeeInfoState) => {return state.licenses;},
);

export const getHireInfoTrainingEvent = createSelector(
  hrEmployeeInfoState,
  (state: HrEmployeeInfoState) => {return state.trainingEvents;},
);

export const getHireInfoSkills = createSelector(
  hrEmployeeInfoState,
  (state: HrEmployeeInfoState) => {return state.skills;},
);

export const getHireInfoSource = createSelector(
  hrEmployeeInfoState,
  (state: HrEmployeeInfoState) => {return state.sources;},
);

export const getExitInterview = createSelector(
  hrEmployeeInfoState,
  (state: HrEmployeeInfoState) => {return state.exitInterview;},
);

export const getIncrease = createSelector(
  hrEmployeeInfoState,
  (state: HrEmployeeInfoState) => {return state.increase;},
);

export const getReviews = createSelector(
  hrEmployeeInfoState,
  (state: HrEmployeeInfoState) => {return state.review;},
);

export const getDependents = createSelector(
  hrEmployeeInfoState,
  (state: HrEmployeeInfoState) => {return state.dependents;},
);

export const getVaccinations = createSelector(
  hrEmployeeInfoState,
  (state: HrEmployeeInfoState) => {return state.vaccinations;},
);

export const getVaccinationReport = createSelector(
  hrEmployeeInfoState,
  (state: HrEmployeeInfoState) => {return state.vaccinationReport;},
);

export const getHREmployeeListIds = createSelector(
  hrEmployeeInfoState,
  (state: HrEmployeeInfoState) => {return state.employeeListIds;},
);