import React from 'react';
import { Col, Row } from 'react-bootstrap';
import GridSelect from '../../Shared/GridSelect';
import { useAppSelector } from 'utilities/hooks';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for Checkbox and Grid select forms
const TrainingEducationFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  const educationSchoolsOptions = useAppSelector((state) => { return state?.dropdown.educationSchools; });

  const options = educationSchoolsOptions?.map((item: any) => {
    return { ...item,
      id: item.schoolId,
      description: item.name,
    };
  });


  return (
    <Row>
      {!!sectionFields?.length && (
        <Col>
          <GridSelect
            section="Education History"
            title="Education History"
            dFields={sectionFields.find(f => {
              return f.name === 'Education History';
            })?.fields}
            options={options}
            hasCodeColumn={false}
            formMethods={formMethods}
            isCodeField={false}
          />
        </Col>
      )}
    </Row>
  );
};

export default TrainingEducationFields;