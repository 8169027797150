import { ICellRendererParams } from '@ag-grid-community/core';

interface AGPercentRenderer extends ICellRendererParams {
  decimalPlaces?: number;
}

export function agPercentRenderer(): any { }

agPercentRenderer.prototype.init = function (params: AGPercentRenderer) {
  this.divWrapper = document.createElement('div');
  this.divWrapper.innerHTML = this.formatter(params.value, params?.decimalPlaces || 2);
};

agPercentRenderer.prototype.formatter = function (value: any, decimalPlaces: number) {
  return typeof value === 'string' ? value : value?.toFixed(decimalPlaces) + '%';
};

agPercentRenderer.prototype.getGui = function (params: ICellRendererParams) {
  return this.divWrapper;
};

agPercentRenderer.prototype.refresh = function (params: ICellRendererParams) {
  return this.formatter(params.value);
};

agPercentRenderer.prototype.destroy = function () { };
