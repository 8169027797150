import { AgGridReact } from '@ag-grid-community/react';
import { CellClassParams, ColDef, GridOptions, MasterDetailModule, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { getACADate, getFullTimeCalculationReport, getFullTimeCalculationStates, updateACADates } from 'core/store/actions/human-capital-management/aca-full-time-calculation.action';
import { AcaFullTimeCalcSortOptions, EmployeeYearMonthHourTotals, EmployeeYearTotals, IACAEmployee, setKey } from 'core/store/slices/human-capital-management/aca-full-time-calculation.slice';
import { IOption } from 'interfaces/IOption';
import { cloneDeep, uniq } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dropdown, Form, FormGroup, Spinner, Tab, Tabs } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import ReactSelect, { GroupBase, StylesConfig } from 'react-select';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import ACAStates from './ACAStates';
import ACAEmp from './ACAEmp';
import EmployeeStatusModal from '../../core/components/modals/EmployeeStatusModal';
import ACAQuestionsModal from 'features/emp-detail/aca/ACAQuestionsModal';
import { numberWithCommas, toReadableDate } from 'utilities/utilities';
import ACAReportPreview from './ACAReportPreview';
import { InputGrp } from 'core/components/form-controls';
import DateObject from 'react-date-object';
import { useSelector } from 'react-redux';
import { getReportOptions } from 'core/store/selectors';

type StylesConfigType = StylesConfig<IOption, boolean, GroupBase<IOption>> | undefined;

const compStyles: StylesConfigType = {
  control: (base) => {
    return {
      ...base,
      minHeight: '22px',
      minWidth: '200px',
      width: '100%',
      borderRadius: '0.25rem',
      fontSize: '12px',
      color: 'black',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#0074D9',
      },
    };
  },
  valueContainer: (base) => {
    return {
      ...base,
      padding: '0',
      paddingLeft: '2px',
      top: '0',
      position: 'relative',
      overflow: 'hidden',
      width: 'fit-content',
      color: 'black',
      boxSizing: 'border-box',
    };
  },
  indicatorSeparator: (base) => {
    return {
      ...base,
      display: 'none',
    };
  },
  dropdownIndicator: (base) => {
    return {
      ...base,
      padding: '0px',
      paddingLeft: '5px',
      color: '#3a3a3a',
      '&:hover': {
        color: 'black',
      },
    };
  },
};

interface IDatePicker {
  disabled?: boolean;
  name: string;
  onChange: (date: Date | null) => void;
  defaultValue?: string | null;
  isMeasurementPeriod: boolean;
  isStabilityPeriod: boolean;
}

const DatePicker = ({
  disabled,
  name,
  onChange,
  defaultValue = null,
  isMeasurementPeriod = false,
  isStabilityPeriod = false,
}: IDatePicker) => {
  const [value, setValue] = useState<Date>(defaultValue ? new Date(defaultValue) : new Date());

  useEffect(() => {
    if (defaultValue) {
      setValue(new Date(defaultValue));
    }
  }, [defaultValue]);

  return (
    <ReactDatePicker
      autoComplete="off"
      disabled={disabled}
      preventOpenOnFocus={true}
      name={name}
      className= {(isMeasurementPeriod) ? 'react-measurement-period' : (isStabilityPeriod) ? 'react-stability-period' : ''}
      onChange={(selected) => {
        if (selected) {
          setValue(selected);
        }

        onChange(selected);
      }}
      selected={value}
      customInput={<Form.Control
        placeholder="Date"
        className="py-1"
        style={{
          height: 'auto',
          width: '6rem',
        }}
      />}
    />
  );
};

const defaultStatusFilters: string[] = [
  'AllEmp',
  'FullTime',
  'PartTime',
  'Seasonal',
  'Blank',
  'Others',
  'EmployeesOutOfMeasurementPeriod',
  //'TerminatedBeforeMeasurementStarts',
];

const ACAFullTimeCalculation = () => {
  const [showQuestionsModal, setShowQuestionsModal] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<IACAEmployee | null>(null);
  const [empStatusShown, setEmpStatusShown] = useState<boolean>(false);
  const [tabKey, setTabKey] = useState('filtered');
  const gridRef = useRef<AgGridReact>(null);
  const [quickHide, setQuickHide] = useState<boolean>(false);
  const [statusFilters, setStatusFilters] = useState<string[]>(defaultStatusFilters.filter(x => x !== 'AllEmp'));
  const [prYear, setPrYear] = useState<number>(new Date().getFullYear());
  const [invalidYear, setInvalidYear] = useState<boolean>(false);
  const [acaOptionDate, setAcaOptionDate] = useState<string>();
  const acaFullTimeCalcOption = useSelector(getReportOptions('AcaFullTimeReport'));
  const acaFullTimeReport = useAppSelector((state) => { return state?.acaFullTimeReport; });
 
  const dispatch = useAppDispatch();

  const runReport = async () => {
    // await dispatch(postFullTimeCalculationReport());
    dispatch(getFullTimeCalculationReport(prYear));
    setAcaOptionDate(new DateObject(new Date(acaFullTimeReport?.measureTo || '')).format('MM/DD/YYYY'));
  };

  const runGetACADates = async () => {
    if (prYear.toString().length !== 4) {
      setInvalidYear(true);
    } else {
      setInvalidYear(false);
      dispatch(getACADate(prYear));
      dispatch(getFullTimeCalculationStates(prYear));
    }
  };

  const setACADate = async (e: any) => {
    setPrYear(+e.target.value);
  };

  const invalidMeasurementPeriod = () => {
    if (!acaFullTimeReport.measureTo || !acaFullTimeReport.measureFrom) return true;
    const yearDifference = new Date(acaFullTimeReport.measureTo).getFullYear() - new Date(acaFullTimeReport.measureFrom).getFullYear();
    const monthDifference = new Date(acaFullTimeReport.measureTo).getMonth() - new Date(acaFullTimeReport.measureFrom).getMonth();
    
    if (yearDifference !== 0)
      return (yearDifference === 1 && monthDifference === 0) ? false : true;
    else
      return (monthDifference >= 3 && monthDifference <= 12) ? false : true;
  };

  const onStatusCheckboxChange = (checked: boolean, key: string) => {
    const newValues = checked ? [...statusFilters, key] : statusFilters.filter(item => { return item !== key; });

    setStatusFilters(
      key !== defaultStatusFilters[0]
        ? newValues.filter(item => {
          return item !== defaultStatusFilters[0];
        })
        : [defaultStatusFilters[0]],
    );
  };

  const onSaveDefaultMeasurementPeriods = () => {
    dispatch(updateACADates(prYear));
  };

  const formatDate = (item: { value: string }) => {
    return toReadableDate(item.value);
  };

  const columns: ColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'Index',
      sortable: true,
      width: 100,
    },
    {
      field: 'clientNo',
      headerName: 'Client No',
      sortable: true,
      width: 100,
    },
    {
      field: 'empNo',
      headerName: 'Emp No',
      sortable: true,
      width: 100,
    },
    {
      field: 'empName',
      headerName: 'Employee Name',
      valueFormatter: (item: ValueFormatterParams) => {
        const data = item?.data;

        return `${data?.firstName} ${data?.lastName}`;
      },
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: true,
      width: 160,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: true,
      width: 160,
    },
    {
      field: 'location',
      headerName: 'Loc',
      sortable: true,
      width: 60,
    },
    {
      field: 'department',
      headerName: 'Dept',
      sortable: true,
      width: 90,
    },
    {
      field: 'subDepartment',
      headerName: 'SubDept',
      sortable: true,
      width: 90,
    },
    {
      field: 'subDepartment2',
      headerName: 'SubDept2',
      sortable: true,
      width: 90,
    },
    {
      field: 'previousHireDate',
      headerName: 'Prev Hire',
      sortable: true,
      valueFormatter: formatDate,
      width: 100,
    },
    {
      field: 'previousTermDate',
      headerName: 'Prev Term',
      sortable: true,
      valueFormatter: formatDate,
      width: 100,
    },
    {
      field: 'currentHireDate',
      headerName: 'Curr Hire',
      sortable: true,
      valueFormatter: formatDate,
      width: 100,
    },
    {
      field: 'currentTermDate',
      headerName: 'Curr Term',
      sortable: true,
      valueFormatter: formatDate,
      width: 100,
    },
    {
      field: 'acaStatus',
      headerName: 'ACA',
      initialSort: 'asc',
      sortable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        switch (params.data.acaStatus) {
          case 'F': {
            return 'Full Time';
          }
          case 'P': {
            return 'Part Time';
          }
          case 'S': {
            return 'Seasonal';
          }
          default: {
            return '';
          }
        }
      },
      width: 100,
    },
    {
      field: 'acaHistory',
      headerName: 'ACA History',
      sortable: true,
      width: 120,
      valueFormatter: () => {
        return 'ACA History';
      },
      onCellClicked: (selected: {
        data: IACAEmployee
      }) => {
        setSelectedEmployee(selected.data);
        setEmpStatusShown(true);
      },
    },
  ].map((item) => {
    const itemConvert = item as ColDef;
    if (itemConvert.field === 'acaHistory') {
      return itemConvert;
    }

    itemConvert.onCellDoubleClicked = (selected: {
      data: IACAEmployee
    }) => {
      setSelectedEmployee(selected.data);
    };

    return itemConvert;
  });

  const [showHideDropdown, setShowHideDropdown] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns?.map((item: ColDef): string => {
      return item.field || '';
    }),
  );

  const onToggleHandler = (isOpen: boolean, e: any, metadata: { source: string }) => {
    if (metadata.source !== 'select') {
      setShowHideDropdown(isOpen);
    }
  };

  const otherColumns = useMemo(() => {
    const cols: ColDef[] = [];

    cols.push({
      field: 'eligibleStart',
      headerName: 'Eligible Start',
      sortable: true,
      valueFormatter: formatDate,
      width: 100,
    });

    acaFullTimeReport.data.employees?.[0]?.yearTotals.forEach((yearTotals: EmployeeYearTotals) => {
      if (!cols.find(item => {
        return item.headerName === yearTotals.label;
      })) {
        cols.push({
          field: yearTotals.label,
          headerName: yearTotals.label,
          valueFormatter: (item: ValueFormatterParams) => {
            const value = item.data.yearTotals.find((d: { label: string }) => { return d.label === yearTotals.label; });
            return numberWithCommas(value?.amount?.toFixed(3));
          },
          sortable: true,
          headerClass: 'ag-right-aligned-header',
          cellClass: 'ag-cell-left-border text-right',
          width: 80,
    
        });
      }
    });

    acaFullTimeReport.data.employees?.[0]?.acaYearMonthHourTotals.forEach((ymhTotals: EmployeeYearMonthHourTotals) => {
      if (!cols.find(item => {
        return item.headerName === ymhTotals.label;
      })) {
        cols.push({
          field: ymhTotals.label,
          headerName: ymhTotals.label,
          sortable: true,
          valueFormatter: (item: ValueFormatterParams) => {
            const value = item.data.acaYearMonthHourTotals.find((d: { label: string }) => { return d.label === ymhTotals.label; });
            return numberWithCommas(value?.total?.toFixed(3));
          },
          headerClass: 'ag-right-aligned-header',
          cellClass: 'ag-cell-left-border text-right',
          width: 80,
          //Will set the color for the specific cell
          cellStyle: params => {
            const color = otherColumnsColor(params, ymhTotals);
            return { backgroundColor: color };
          },
        });
      }
    });

    //Will return the cell color based on the value that is passed in the ACA Year Month Hour Totals DTO from the backend
    const otherColumnsColor = (params : CellClassParams, ymhtotals: EmployeeYearMonthHourTotals) => {
      const currentACAMonthHourRecord = params.data.acaYearMonthHourTotals.find((x: { label: string }) => {return x.label === ymhtotals.label;} );
      switch (currentACAMonthHourRecord.color) {
        case 'RED':
          return '#f08080';

        case 'LIGHT_RED':
          return '#ffcccc';

        case 'YELLOW':
          return '#ffffe0';

        case 'GREEN':
          return '#90EE90';

        default:
          return '';
      }
    };

    return cols;
  }, [acaFullTimeReport.data.employees]);

  const parsedColumns = useMemo(() => {
    const cloneColumns = cloneDeep(columns);

    let nColumns = [...cloneColumns.splice(0, cloneColumns.length - 1), ...otherColumns, ...cloneColumns.splice(cloneColumns.length - 1)];

    if (selectedColumns.length) {
      nColumns = nColumns.filter(item => {
        if (!item.field) {
          return false;
        }
        return selectedColumns.includes(item.field);
      });
    }

    if (quickHide) {
      nColumns = nColumns.filter((item) => {
        return !['location', 'department', 'subDepartment', 'subDepartment2'].includes(item.field as string);
      });
    }

    return nColumns;
  }, [columns, quickHide, selectedColumns]);

  useEffect(() => {
    if (tabKey === 'display-hours') {
      setSelectedColumns(
        uniq(
          [
            ...selectedColumns,
            ...otherColumns.map((item: ColDef) => {
              return item.field;
            }),
          ],
        ) as string[],
      );
    } else {
      setSelectedColumns(uniq(selectedColumns.filter((item: string) => {
        return !otherColumns.find((otherCol: ColDef) => { return otherCol.field === item; });
      })));
    }
  }, [tabKey]);

  useEffect(() => {
    if (!gridRef?.current?.api?.setColumnDefs) {
      return;
    }

    gridRef.current.api.setColumnDefs(parsedColumns);
  }, [parsedColumns]);

  const statuses = {
    FullTime: 'F',
    PartTime: 'P',
    Blank: ' ',
    Seasonal: 'S',
    Others: 'O',
  };

  const parsedData = useMemo(() => {
    let data = [];

    if (statusFilters.includes('AllEmp')) {
      data = acaFullTimeReport.data.employees;
    } else {
      const isEmployeesOutOfMeasurementPeriodSelected = statusFilters.includes('EmployeesOutOfMeasurementPeriod');
      const isTerminatedBeforeMeasurementStartsSelected = statusFilters.includes('TerminatedBeforeMeasurementStarts');

      data = acaFullTimeReport.data.employees.filter((emp: IACAEmployee) => {
        let state = false;
        const hireDate = new Date(emp.currentHireDate || '');
        const termDate = new Date(emp.currentTermDate || '');
        const measureFrom = new Date(acaFullTimeReport.measureFrom || '');
        const measureTo = new Date(acaFullTimeReport.measureTo || '');

        if (statusFilters.length) {
          state = !!(statusFilters.filter((item: string) => {
            return item !== 'EmployeesOutOfMeasurementPeriod' && item !== 'TerminatedBeforeMeasurementStarts';
          }).find((item: string) => {
            // @ts-ignore
            const status = statuses[item];
            return status === emp?.acaStatus;
          }));
        }

        if (!state) {
          if (isEmployeesOutOfMeasurementPeriodSelected) {
            state = hireDate > measureTo && termDate > measureFrom;
          }

          if (isTerminatedBeforeMeasurementStartsSelected) {
            state = termDate < measureFrom || termDate === null;
          }
        }

        return state;
      });
    }

    return data;
  }, [
    acaFullTimeReport.data.employees,
    acaFullTimeReport.measureFrom,
    acaFullTimeReport.measureTo,
    statusFilters,
  ]);

  useEffect(() => {
    if (statusFilters.length === 0) {
      setStatusFilters([defaultStatusFilters[0]]);
    }
  }, [statusFilters]);

  const gridOptions: GridOptions = {
    columnDefs: parsedColumns,
    domLayout: 'autoHeight',
    defaultColDef: {
      autoHeight: true,
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    pagination: true,
    paginationPageSize: 10,
  };

  useEffect(() => {
    dispatch(getACADate(prYear)).then(() => { runReport()});
    dispatch(getFullTimeCalculationStates(prYear));
  }, []);

  return (
    <>
      {(acaFullTimeCalcOption?.showReport ?? false) ? (
        <div className="dm-panel dm-panel-border">
          <div className="dm-grid-title">ACA Full-Time Calculation Report</div>
          <hr className="dm-panel-hr" />
          <div
            className="d-flex align-items-start justify-content-between"
            style={{
              columnGap: '1rem',
            }}
          >
            <div
              className="d-flex align-items-start justify-content-between flex-grow-1 mt-2 mb-3"
              style={{
                gap: '1rem',
              }}
            >
              <div className="bg-light rounded-lg p-3">
                <p className="mb-2">Select ACA Report(s) To Run</p>
                <div
                  className="d-flex align-items-center flex-wrap"
                  style={{
                    gap: '1rem',
                  }}
                >
                  <FormGroup
                    className="d-flex align-items-center mb-0"
                    style={{ gap: '1rem' }}
                  >
                    <span>Sort</span>
                    <ReactSelect
                      styles={compStyles as StylesConfigType}
                      components={{
                        Option: (props: any) => {
                          const { innerProps, innerRef } = props;

                          return (
                            <div
                              {...innerProps}
                              ref={innerRef}
                              className="px-2 py-1"
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <span
                                  style={{
                                    width: 150,
                                  }}
                                >
                                  {props.data.label}
                                </span>
                                <span
                                  style={{
                                    width: 60,
                                  }}
                                >
                                  {props.data.value}
                                </span>
                              </div>
                            </div>
                          );
                        },
                        GroupHeading: () => {
                          return (
                            <div className="px-2 py-1">
                              <div className="d-flex align-items-center">
                                <strong
                                  style={{
                                    width: 150,
                                  }}
                                >
                                  Description
                                </strong>
                                <strong
                                  style={{
                                    width: 60,
                                  }}
                                >
                                  Sort By
                                </strong>
                              </div>
                            </div>
                          );
                        },
                      }}
                      options={[{
                        options: AcaFullTimeCalcSortOptions,
                      }]}
                      value={AcaFullTimeCalcSortOptions.find((item: IOption) => {
                        return item.label === acaFullTimeReport.sortBy;
                      })}
                      onChange={(selected: any) => {
                        dispatch(setKey({
                          key: 'sortBy',
                          value: selected.label,
                        }));
                      }}
                    />
                  </FormGroup>
                  <div
                    className="d-flex align-items-center flex-wrap"
                    style={{
                      gap: '1rem',
                    }}
                  >
                    <FormGroup className="d-flex align-items-center mb-0">
                      <input
                        type="radio"
                        name="eligibility"
                        id="full-time-equivalent"
                        className="mr-1"
                        checked={acaFullTimeReport.eligibility === 'FullTimeEquivalent'}
                        onChange={() => {
                          dispatch(setKey({
                            key: 'eligibility',
                            value: 'FullTimeEquivalent',
                          }));
                        }}
                      />
                      <label
                        htmlFor="full-time-equivalent"
                        className="mb-0"
                      >Full Time Equivalent</label>
                    </FormGroup>
                    <FormGroup className="d-flex align-items-center mb-0">
                      <input
                        type="radio"
                        name="eligibility"
                        id="eligibility-with-all-employees"
                        className="mr-1"
                        checked={acaFullTimeReport.eligibility === 'EligibilityWithAllEmployees'}
                        onChange={() => {
                          dispatch(setKey({
                            key: 'eligibility',
                            value: 'EligibilityWithAllEmployees',
                          }));
                        }}
                      />
                      <label
                        htmlFor="eligibility-with-all-employees"
                        className="mb-0"
                      >Eligibility With All Employees</label>
                    </FormGroup>
                    <FormGroup className="d-flex align-items-center mb-0">
                      <input
                        type="radio"
                        name="eligibility"
                        id="eligibility-with-eligible-employees-only"
                        className="mr-1"
                        checked={acaFullTimeReport.eligibility === 'EligibilityWithAllEmployeesOnly'}
                        onChange={() => {
                          dispatch(setKey({
                            key: 'eligibility',
                            value: 'EligibilityWithAllEmployeesOnly',
                          }));
                        }}
                      />
                      <label
                        htmlFor="eligibility-with-eligible-employees-only"
                        className="mb-0"
                      >Eligibility With Eligible Employees Only</label>
                    </FormGroup>
                    <FormGroup className="d-flex align-items-center mb-0">
                      <input
                        type="radio"
                        name="eligibility"
                        id="eligibility-with-non-full-time-employees"
                        className="mr-1"
                        checked={acaFullTimeReport.eligibility === 'EligibilityWithNonFullTimeEmployeesOnly'}
                        onChange={() => {
                          dispatch(setKey({
                            key: 'eligibility',
                            value: 'EligibilityWithNonFullTimeEmployeesOnly',
                          }));
                        }}
                      />
                      <label
                        htmlFor="eligibility-with-non-full-time-employees"
                        className="mb-0"
                      >Eligibility With Non Full Time Employees</label>
                    </FormGroup>
                    <FormGroup className="d-flex align-items-center mb-0">
                      <button
                        style={{
                          textDecoration: 'underline',
                          border: 'none',
                          background: 'transparent',
                          color: 'blue',
                        }}
                        onClick={() => {
                          setShowQuestionsModal(true);
                        }}
                      >
                        Show ACA Options
                      </button>
                      {acaFullTimeReport?.data?.states && (
                        <ACAQuestionsModal
                          clientACA={acaFullTimeReport?.data?.states}
                          show={showQuestionsModal}
                          onHide={() => {
                            setShowQuestionsModal(false);
                          }}
                        />
                      )}
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
            <ACAReportPreview />
          </div>
          <div
            className="d-flex flex-wrap align-items-center"
            style={{ gap: '1rem' }}
          >
            <p className="mb-2">ACA Option Year</p>
            <InputGrp
              label=""
              type="number"
              name="payrollYear"
              defaultValue = {new Date().getFullYear()}
              onChange = {setACADate}
              errors = {invalidYear}
              errorMsg = {'Invalid Year'}
            />
            <div
              className="d-flex mb-2 flex-wrap align-items-center"
              style={{ gap: '1rem' }}
            >
              <Button
                type="button"
                variant="primary"
                onClick={runGetACADates}
                size="sm"
              >
                Refresh Options
                {acaFullTimeReport.areDatesLoading && (
                  <Spinner
                    animation="border"
                    size="sm"
                    className="ml-2"
                  />
                )}
              </Button>
            </div>
          </div>
          <div
            className="d-flex mb-3 flex-wrap align-items-center"
            style={{ gap: '1rem' }}
          >
            <div className="bg-light rounded-lg p-3">
              <p className="mb-2">Measurement Period</p>
              <div
                className="d-flex align-items-center"
                style={{ columnGap: '1rem' }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ columnGap: '1rem' }}
                >
                  <p className="mb-0">From</p>
                  <DatePicker
                    name="measurement-period--from"
                    defaultValue={acaFullTimeReport.measureFrom}
                    disabled={acaFullTimeReport.areReportsLoading}
                    isMeasurementPeriod = {true}
                    isStabilityPeriod = {false}
                    onChange={(value) => {
                      dispatch(
                        setKey({
                          key: 'measureFrom',
                          value,
                        }),
                      );
                    }}
                  />
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ columnGap: '1rem' }}
                >
                  <p className="mb-0">To</p>
                  <DatePicker
                    name="measurement-period--to"
                    defaultValue={acaFullTimeReport.measureTo}
                    disabled={acaFullTimeReport.areReportsLoading}
                    isMeasurementPeriod = {true}
                    isStabilityPeriod = {false}
                    onChange={(value) => {
                      dispatch(
                        setKey({
                          key: 'measureTo',
                          value,
                        }),
                      );
                    }}
                  />
                </div>
                <Button
                  type="button"
                  variant="primary"
                  onClick={runReport}
                  disabled={acaFullTimeReport.areReportsLoading || invalidMeasurementPeriod()}
                >
                  Refresh Data
                  {acaFullTimeReport.areReportsLoading && (
                    <Spinner
                      animation="border"
                      size="sm"
                      className="ml-2"
                    />
                  )}
                </Button>
              </div>
              <p className="mb-0">
                {invalidMeasurementPeriod() ? (
                  <small className="text-danger d-flex">{'Measurement Period cannot be larger than a year or less than 5 months.'}</small>
                ) : null}
              </p>
            </div>
            <div className="bg-light rounded-lg p-3">
              <p className="mb-2">Administration Period</p>
              <div
                className="d-flex align-items-center"
                style={{ columnGap: '1rem' }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ columnGap: '1rem' }}
                >
                  <p className="mb-0">From</p>
                  <DatePicker
                    name="measurement-admin--from"
                    defaultValue={acaFullTimeReport.adminFrom}
                    disabled={acaFullTimeReport.areReportsLoading}
                    isMeasurementPeriod = {false}
                    isStabilityPeriod = {false}
                    onChange={(value) => {
                      dispatch(
                        setKey({
                          key: 'adminFrom',
                          value,
                        }),
                      );
                    }}
                  />
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ columnGap: '1rem' }}
                >
                  <p className="mb-0">To</p>
                  <DatePicker
                    name="measurement-admin--to"
                    defaultValue={acaFullTimeReport.adminTo}
                    disabled={acaFullTimeReport.areReportsLoading}
                    isMeasurementPeriod = {false}
                    isStabilityPeriod = {false}
                    onChange={(value) => {
                      dispatch(
                        setKey({
                          key: 'adminTo',
                          value,
                        }),
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="bg-light rounded-lg p-3">
              <p className="mb-2">Stability Period</p>
              <div
                className="d-flex align-items-center"
                style={{ columnGap: '1rem' }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ columnGap: '1rem' }}
                >
                  <p className="mb-0">From</p>
                  <DatePicker
                    name="measurement-stability--from"
                    defaultValue={acaFullTimeReport.stabilityFrom}
                    disabled={acaFullTimeReport.areReportsLoading}
                    isMeasurementPeriod = {false}
                    isStabilityPeriod = {true}
                    onChange={(value) => {
                      dispatch(
                        setKey({
                          key: 'stabilityFrom',
                          value,
                        }),
                      );
                    }}
                  />
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ columnGap: '1rem' }}
                >
                  <p className="mb-0">To</p>
                  <DatePicker
                    name="measurement-stability--to"
                    defaultValue={acaFullTimeReport.stabilityTo}
                    disabled={acaFullTimeReport.areReportsLoading}
                    isMeasurementPeriod = {false}
                    isStabilityPeriod = {true}
                    onChange={(value) => {
                      dispatch(
                        setKey({
                          key: 'stabilityTo',
                          value,
                        }),
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <Button
              variant="light"
              disabled = {invalidMeasurementPeriod()}
              onClick={onSaveDefaultMeasurementPeriods}
            >
              Save Default Measurement Periods
              {acaFullTimeReport.isUpdatingDates && (
                <Spinner
                  animation="border"
                  size="sm"
                  className="ml-2"
                />
              )}
            </Button>
          </div>
          <div className="d-inline-flex mb-3 bg-light rounded-lg p-3">
            <div className="w-100">
              <div className="d-flex align-items-center">
                <p
                  className="mb-0"
                  style={{ width: '7rem' }}
                >ACA Analysis:</p>
                <span>For 12 months ending {acaOptionDate} shows the following for your company:</span>
              </div>
              <div
                className="d-flex align-items-start justify-content-between flex-wrap"
                style={{
                  columnGap: '10rem',
                  rowGap: '1rem',
                }}
              >
                <div
                  className="d-flex align-items-start"
                  style={{
                    columnGap: '1rem',
                  }}
                >
                  <div className="d-flex align-items-center">
                    <p className="mb-0 mr-2">Full Time:</p>
                    <strong>{acaFullTimeReport.count?.fullTime || 0}</strong>
                  </div>
                  <div className="d-flex align-items-center">
                    <p className="mb-0 mr-2">Part Time:</p>
                    <strong>{acaFullTimeReport.count?.partTime || 0}</strong>
                  </div>
                  <div className="d-flex align-items-center">
                    <p className="mb-0 mr-2">Seasonal:</p>
                    <strong>{acaFullTimeReport.count?.seasonal || 0}</strong>
                  </div>
                  <div className="d-flex align-items-center">
                    <p className="mb-0 mr-2">Blank:</p>
                    <strong>{acaFullTimeReport.count?.blank || 0}</strong>
                  </div>
                  <div className="d-flex align-items-center">
                    <p className="mb-0 mr-2">Full Time Eq.:</p>
                    <strong>{acaFullTimeReport.count?.fullTimeEquivalent || 0}</strong>
                  </div>
                </div>
                <strong className="mb-0">
                  ***Note: Click &quot;Run Report&quot; above for the further details
                </strong>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center mb-3 px-3">
            <span>Show My Configured Locations & Dates</span>
            <ACAStates year={prYear} />
          </div>
          <div
            className="d-flex align-items-center flex-wrap mb-3"
            style={{ gap: '1rem' }}
          >
            <div className="bg-light rounded-lg p-3">
              <p className="mb-2">ACA Status Filter</p>
              <div
                className="d-flex align-items-start flex-wrap"
                style={{
                  gap: '1rem',
                }}
              >
                <div
                  className="d-flex align-items-start flex-wrap"
                  style={{ gap: '1rem' }}
                >
                  <FormGroup className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="status-filter--all-emp"
                      id="status-filter--all-emp"
                      className="mr-1"
                      checked={statusFilters.includes('AllEmp')}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onStatusCheckboxChange(e.target.checked, 'AllEmp');
                      }}
                    />
                    <label
                      htmlFor="status-filter--all-emp"
                      className="mb-0"
                    >
                      <strong>All Emp</strong>
                    </label>
                  </FormGroup>
                  <div
                    className="d-flex flex-wrap"
                    style={{ gap: '1rem' }}
                  >
                    {['Full Time', 'Part Time', 'Seasonal', 'Blank', 'Others'].map((item) => {
                      const appendId = item.toLowerCase().replaceAll(' ', '-');
                      const key = item.replace(' ', '');

                      return (
                        <FormGroup
                          className="d-flex align-items-center"
                          key={appendId}
                        >
                          <input
                            type="checkbox"
                            name={`status-filter--${appendId}`}
                            id={`status-filter--${appendId}`}
                            className="mr-1"
                            checked={statusFilters.includes(key)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              onStatusCheckboxChange(e.target.checked, key);
                            }}
                          />
                          <label
                            htmlFor={`status-filter--${appendId}`}
                            className="mb-0"
                          >
                            {item}
                          </label>
                        </FormGroup>
                      );
                    })}
                  </div>
                  <div className="d-flex flex-column">
                    {['Employees Out Of Measurement Period', 'Terminated Before Measurement Starts'].map((item: string, index: number) => {
                      const appendId = item.toLowerCase().replaceAll(' ', '-');
                      const key = item.replaceAll(' ', '');

                      return (
                        <FormGroup
                          className={`d-flex align-items-center ${index !== 0 ? 'mb-0' : ''}`}
                          key={appendId}
                        >
                          <input
                            type="checkbox"
                            name={`status-filter--${appendId}`}
                            id={`status-filter--${appendId}`}
                            className="mr-1"
                            checked={statusFilters.includes(key)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              onStatusCheckboxChange(e.target.checked, key);
                            }}
                          />
                          <label
                            htmlFor={`status-filter--${appendId}`}
                            className="mb-0"
                          >
                            {item}
                          </label>
                        </FormGroup>
                      );
                    })}
                  </div>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{
                    gap: '0.5rem',
                  }}
                >
                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="waitingPeriodDays"
                      defaultChecked={true}
                      id="waitingPeriodDays"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        dispatch(setKey({
                          key: 'employeeIsEligible',
                          value: e.target.checked,
                        }));
                      }}
                    />
                    <label
                      className="mb-0 ml-1"
                      htmlFor="waitingPeriodDays"
                    >Employee is eligible beginning of the month following the waiting period of</label>
                  </div>
                  <input
                    type="number"
                    value={acaFullTimeReport.waitingPeriodDays}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'waitingPeriodDays',
                        value: e.target.value,
                      }));
                    }}
                    className="border rounded pr-1"
                    style={{
                      textAlign: 'right',
                      width: 60,
                    }}
                  />
                  <span>days</span>
                </div>
              </div>
            </div>
          </div>
          <Tabs
            activeKey={tabKey}
            onSelect={(key) => {
              setTabKey(key as string);
            }}
          >
            <Tab
              eventKey="filtered"
              title="ACA Filtered"
            >
            </Tab>
            <Tab
              eventKey="display-hours"
              title="Display Hours"
            >
            </Tab>
          </Tabs>
          <div className="d-flex align-items-center justify-content-between mt-3">
            <strong>*** Double Click The Row To Show Employee Hour Detail</strong>
            <div
              className="d-flex align-items-center flex-shrink-1 flex-wrap"
              style={{
                columnGap: '1rem',
                rowGap: '1rem',
              }}
            >
              <Button
                type="button"
                variant="light"
                onClick={() => {
                  setQuickHide(!quickHide);
                }}
              >
                Quick {quickHide ? 'Show' : 'Hide'}
              </Button>
              <Dropdown
                show={showHideDropdown}
                onToggle={(isOpen, e, metadata) => {
                  return onToggleHandler(isOpen, e, metadata);
                }}
              >
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-basic"
                >
                  Hide/Show Columns
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[...columns, ...otherColumns].map((item: ColDef) => {
                    const id = `show-hide-column-${item.field}`;
                    const isSelected = selectedColumns.includes(item?.field || '');

                    return (
                      <Dropdown.Item
                        key={id}
                        onClick={() => {
                          const newColumns = isSelected
                            ? selectedColumns.filter((itemCol: string) => {
                              return itemCol !== item.field;
                            })
                            : [...selectedColumns, item.field];

                          setSelectedColumns(newColumns as string[]);
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            checked={isSelected}
                            onChange={() => { }}
                            id={id}
                          />
                          <label
                            htmlFor={id}
                            className="mb-0 ml-2"
                          >
                            {item.headerName}
                          </label>
                        </div>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {!!acaFullTimeReport.data.employees?.length && (
            <div className="mt-3 ag-theme-balham">
              <AgGridReact
                ref={gridRef}
                masterDetail
                gridOptions={gridOptions}
                rowData={parsedData}
                overlayNoRowsTemplate={
                  '<span>No Changes to Display</span>'
                }
                modules={[MasterDetailModule]}
              />
              {!empStatusShown && (
                <ACAEmp
                  employee={selectedEmployee}
                  onClose={() => {
                    setSelectedEmployee(null);
                  }}
                />
              )}
            </div>
          )}
          {empStatusShown && selectedEmployee && (
            <EmployeeStatusModal
              data={selectedEmployee}
              show={empStatusShown}
              onHide={() => {
                setSelectedEmployee(null);
                setEmpStatusShown(false);
              }}
            />
          )}
        </div>
      ) : <div></div>}
    </>
  );
};

export default ACAFullTimeCalculation;