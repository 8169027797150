import React, { useEffect, useState } from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import EmpPhotoUpload from 'features/add-emp/information/EmpPhotoUpload';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { convToDateString, getAccess, getAllAccess } from 'utilities/utilities';
import { Employee } from 'core/models/Employee.model';
import {
  loadEmployee,
  loadPayrollInfo,
  updateEmployeePhoto,
} from 'core/store/actions';
import { ControlIds } from 'core/constants';

type Props = {
  protectedEmpNo: string;
};

const EmployeeInformation: React.FC<Props> = ({ protectedEmpNo }) => {
  const dispatch = useAppDispatch();

  const employee = useAppSelector((state) => state.selEmployee.employee) as Employee;
  const payrollInfo = useAppSelector((state) => state.selEmployeeDetails.payrollInfo.payrollInfo);
  const jobTitles = useAppSelector((state) => state.dropdown.jobTitle);
  const sectionAccess = useAppSelector((state) => state.app.moduleAccess?.employeeMasterSections);
  
  const allEmpInfoFieldsAccess = getAllAccess(sectionAccess?.find((x) => x.workItemId === 1));
  const datesSectionAccess = getAccess(sectionAccess, 7);

  const [, setEmpPicData] = useState('');
  const [jobTitle, setJobTitle] = useState('');

  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(loadPayrollInfo(protectedEmpNo));
      if (!employee || employee?.protectedEmpNo !== protectedEmpNo) {
        dispatch(loadEmployee(protectedEmpNo));
      }
    }
  }, [protectedEmpNo]);

  useEffect(() => {
    if (payrollInfo && employee && payrollInfo.empNo === employee.empNo) {
      setJobTitle(
        jobTitles.find(
          (a) => { return a.id === payrollInfo.jobTitleId?.toString(); },
        )?.description ?? '',
      );
    }
  }, [payrollInfo]);

  const updateEmpPhoto = (photoData: any) => {
    dispatch(updateEmployeePhoto({ empNo: protectedEmpNo, photoData }));
  };
  
  const getEmpName = () => {
    let name = '';
    
    if (allEmpInfoFieldsAccess?.[ControlIds.firstName]?.visible ?? true) name += employee.firstName + ' ';
    if (allEmpInfoFieldsAccess?.[ControlIds.lastName]?.visible ?? true) name += employee.lastName;
    
    return name;
  };

  return (
    <Row className="mb-3">
      {employee ? (
        <>
          <Col xs="auto">
            <EmpPhotoUpload
              empPicData={employee.employeePhoto}
              setEmpPicData={setEmpPicData}
              updateEmpPhoto={updateEmpPhoto}
            />
          </Col>
          <Col>
            <div className="dm-grid-title">
              {getEmpName()}
            </div>
            <div>{jobTitle}</div>
            <div>
              {datesSectionAccess.visible && (
                <>
                  <span className="font-weight-bold">
                    Hire Date:{' '}
                  </span>
                  <span className="mr-3">
                    {convToDateString(employee.hireDate)}
                  </span>
                </>
              )}
              {(allEmpInfoFieldsAccess?.[ControlIds.firstName]?.visible ?? true) && (
                <>
                  <span className="font-weight-bold">
                    Employee #:{' '}
                  </span>
                  <span>{employee?.empNo}</span>
                </>
              )}
            </div>
          </Col>
        </>
      ) : (
        <ProgressBar />
      )}
    </Row>
  );
};

export default EmployeeInformation;
