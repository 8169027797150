import { AxiosResponse } from "axios";
import { EarningsRecords, EarningsRecordsRequest } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getEarningsRecords = (
  request: EarningsRecordsRequest,
): Promise<AxiosResponse<EarningsRecords>> => {
  return API_EMPLOYEE_MASTER.get<EarningsRecords>(
    EmployeeEndPoints.GET_EARNINGS_RECORDS(
      CommonService.getProtectedClientNo(),
      request,
    ),
  );
};

export const EarningsRecordsService = {
  getEarningsRecords,
};
