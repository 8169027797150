import Icon from 'core/components/shared/Icon';
import { CheckboxGrp } from 'core/components/form-controls';
import React, { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useFieldArray } from 'react-hook-form';

interface CustomFieldProps {
  fieldIndex: number;
  formMethods: any;
  f: any;
  section:string;
}
  
const CustomFieldAnswer = ({ fieldIndex, formMethods, f, section }: CustomFieldProps) => {
    
  const [openState, setOpenState] = useState<{ id: number, value: boolean }[]>([]);
  
  const { fields } = useFieldArray({
    control: formMethods.control,
    name: `${section}.pageCustomFields[${fieldIndex}].customAnswers`,
    keyName: 'id',
  });
  
  return (
    <div className="d-flex flex-row">     
      <Accordion
        defaultActiveKey="0"
        className="d-flex flex-grow-1"
      >
        <Card className="d-flex flex-grow-1">
          <Card.Header className="p-3">
            <Accordion.Toggle
              eventKey={'4'}
              style={{
                background: 'transparent',
                border: 'none',
              }}
              onClick={() => {
                const value = openState?.find(s => { return s.id === f.customFieldId; })?.value;
                const prop = {
                  id: f.customFieldId,
                  value: !value,
                };
                setOpenState((prevState) => {
                  return [...prevState.filter(s => { return s.id !== f.customFieldId; }),
                    prop,
                  ];
                });
              }}
            >
              <Icon
                name={openState?.find(s => {
                  return s.id === f.customFieldId;
                })?.value ?
                  'chevron-up' : 'chevron-down'}
                className="mr-1"
              />
              Predefined Answers
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={'4'}>
            <Card.Body
              className="p-3"
              style={{ gap: '4rem' }}
            >
              <div className="d-flex flex-column">
                <div className="d-flex flex-row col-6 pr-5">
                  <div className="d-flex justify-content-start">
  
                  </div>
                  <div className="d-flex justify-content-end flex-grow-1 mt-2">
                    <div
                      className="d-flex flex-column text-center"
                      style={{ width: 75 }}
                    >
                      <span><u>Visible</u></span>
                    </div>
                    <div
                      className="d-flex flex-column text-center"
                      style={{ width: 75 }}
                    >
                      <span><u>Required</u></span>
                    </div>
                  </div>
                </div>
                {
                  fields.map((field, index) => {
                    return (
                      <div
                        key={field.id}
                        className="d-flex flex-column col-6 pr-5"
                      >
                        <div>
                          <input
                            type="hidden"                            
                            name={`${section}.pageCustomFields[${fieldIndex}].customAnswers[${index}].customAnswerId`}                           
                            defaultValue={field.customAnswerId}
                            ref={formMethods.register({ valueAsNumber:true })}
                          />
                          <input
                            type="hidden"                            
                            name={`${section}.pageCustomFields[${fieldIndex}].customAnswers[${index}].customFieldId`}                           
                            defaultValue={field.customFieldId}
                            ref={formMethods.register({ valueAsNumber:true })}
                          />
                          <input
                            type="hidden"                            
                            name={`${section}.pageCustomFields[${fieldIndex}].customAnswers[${index}].label`}                           
                            defaultValue={field.label}
                            ref={formMethods.register()}
                          />
                          <input
                            type="hidden" 
                            name={`${section}.pageCustomFields[${fieldIndex}].customAnswers[${index}].name`}                           
                            defaultValue={field.name}
                            ref={formMethods.register()}
                          />
                          <input
                            type="hidden"                            
                            name={`${section}.pageCustomFields[${fieldIndex}].customAnswers[${index}].description`}                           
                            defaultValue={field.description}
                            ref={formMethods.register()}
                          />                         
                          <div className="d-flex justify-content-start">
                            {field.description}
                          </div>
                          <div className="d-flex justify-content-end flex-grow-1">
                            <div
                              className="d-flex flex-column text-center"
                              style={{ width: 75 }}
                            >
                              <CheckboxGrp
                                label=""  
                                name={`${section}.pageCustomFields[${fieldIndex}].customAnswers[${index}].visible` }                                  
                                defaultChecked={field.visible}
                                ref={formMethods.register()}
                              />
                            </div>
                            <div
                              className="d-flex flex-column text-center"
                              style={{ width: 75 }}
                            >
                              <CheckboxGrp
                                label=""    
                                name={`${section}.pageCustomFields[${fieldIndex}].customAnswers[${index}].required` }                           
                                defaultChecked={field.required}
                                ref={formMethods.register()}                                
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};  

export default CustomFieldAnswer;