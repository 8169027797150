
import Icon from 'core/components/shared/Icon';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  ControlDatePickerGrp,
  SelectModalGrp,
} from 'core/components/form-controls';
import DropdownOptionForm from 'core/components/form-controls/select-modal/DropdownOptionForm';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { UNSAVED_MESSAGE } from 'core/constants';
import { Employee, OtherDate } from 'core/models';
import { createOtherDate, toggleBlockNavigation, updateOtherDate } from 'core/store/actions';
import { getOtherDateDescription } from 'core/store/selectors';
import { getAccess } from 'utilities/utilities';
import { useAppSelector } from 'utilities/hooks';

const fs: FieldInputSettings = {
  date: {
    name: 'date',
    label: 'DATE',
    groupClass: 'groupClass15',
    required: true,
  },
  otherDateDescriptionID: {
    name: 'otherDateDescriptionID',
    label: 'DESCRIPTION',
    groupClass: 'groupClass50',
    noOption: true,
    addOptionText: 'other date desc.',
    modalTitle: 'OTHER DATES',
    formComponent: DropdownOptionForm,
    dropdownName: 'OtherDateDescription',
  },
};

type PropTypes = {
  item: OtherDate;
  selEmp: Employee;
  onDelete: (data: OtherDate) => void;
};

const CustomDate: React.FC<PropTypes> = ({ item, selEmp, onDelete }) => {
  const dispatch = useDispatch();
  const otherDescOpts = useSelector(getOtherDateDescription);
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  
  const { setValue, errors, handleSubmit, control, watch, formState, reset } =
    useForm<any>({ defaultValues: item });

  const { isDirty, dirtyFields } = formState;

  const { date, otherDateDescriptionID } = watch([
    'date',
    'otherDateDescriptionID',
  ]);

  const onSave = (data: any) => {
    const newOtherDate = new OtherDate(
      selEmp.empId,
      selEmp.clientNo,
      selEmp.empNo,
      selEmp.protectedEmpNo,
      data,
    );
    newOtherDate.otherDateID = item.otherDateID;
    newOtherDate.otherDateID
      ? dispatch(updateOtherDate(newOtherDate))
      : dispatch(createOtherDate(newOtherDate));
    reset({ ...newOtherDate });
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: Object.keys(dirtyFields).length > 0,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [Object.keys(dirtyFields).length > 0]);

  return (
    <>
      <form onSubmit={handleSubmit(onSave)}>
        <div className="d-flex flex-wrap mt-3">
          <ControlDatePickerGrp
            {...fs.date}
            {...getAccess(sectionAccess, 7)}
            errors={errors.date}
            value={date}
            setValue={setValue}
            control={control}
            rules={{ required: 'Date is require' }}
          />
          <SelectModalGrp
            {...fs.otherDateDescriptionID}
            {...getAccess(sectionAccess, 7)}
            errors={errors.otherDateDescriptionID}
            value={otherDateDescriptionID}
            control={control}
            setValue={setValue}
            options={otherDescOpts}
          />
        </div>
        <div className="row pb-2">
          <div className="col-12 text-right">
            <button
              {...getAccess(sectionAccess, 7, undefined, { disabledSameAsReadOnly: true })}
              type="button"
              className="btn btn-link dm-grid-action-title mr-4"
              onClick={() => { return onDelete(item); }}
            >
              Delete Custom Date
              <Icon
                name="minus-circle"
                className="fa-minus-circle"
              />
            </button>
            <button
              type="submit"
              className="btn orange-button-sm mr-3"
              {...getAccess(sectionAccess, 7, undefined, { disabledDefault: !isDirty, disabledSameAsReadOnly: true })}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CustomDate;
