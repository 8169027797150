import { AxiosResponse } from "axios";
import { HttpResponse } from '../models';
import { EmpDocComp } from '../models/EmpDocComp.model';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getEmployeeDocComp = (protectedEmpNo: string): Promise<AxiosResponse<EmpDocComp>> => {return API_EMPLOYEE_MASTER.get<EmpDocComp>(EmployeeEndPoints.EMPLOYEES_DOC_COMP(CommonService.getProtectedClientNo(), protectedEmpNo));};

const postEmployeeDocComp = (data: EmpDocComp): Promise<AxiosResponse<HttpResponse<EmpDocComp>>> => {
  const item = {
    ...data,
    protectedClientNo: CommonService.getProtectedClientNo(),
  };
  return API_EMPLOYEE_MASTER.post<HttpResponse<EmpDocComp>>(EmployeeEndPoints.EMPLOYEES_DOC_COMP(CommonService.getProtectedClientNo(), data.protectedEmpNo), item);
};

const putEmployeeDocComp = (data: EmpDocComp): Promise<AxiosResponse<HttpResponse<EmpDocComp>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<EmpDocComp>>(EmployeeEndPoints.EMPLOYEES_DOC_COMP_ID(CommonService.getProtectedClientNo(), data.protectedEmpNo, data.docCompHeaderId), data);};

const deleteEmployeeDocComp = (data: EmpDocComp): Promise<AxiosResponse<HttpResponse<EmpDocComp>>> => {return API_EMPLOYEE_MASTER.delete<HttpResponse<EmpDocComp>>(EmployeeEndPoints.EMPLOYEES_DOC_COMP_ID(CommonService.getProtectedClientNo(), data.protectedEmpNo, data.docCompHeaderId));};

export const EmployeeDocCompService = {
  getEmployeeDocComp,
  postEmployeeDocComp,
  putEmployeeDocComp,
  deleteEmployeeDocComp,
};