import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class RecurringEarnings {
  empId = 0;

  clientNo = 0;

  empNo = 0;

  protectedEmpNo = '';

  recurringId = 0;

  startDate: Date | string | null = null;

  endDate: Date | string | null = null;

  frequency = '';

  payType = '';

  amount = 0;

  jobNo = '';

  checkCode = '';

  costCode = '';

  static readonly convProps: PropTypeLists = {
    dateProps: ['startDate', 'endDate'],
    numberProps: ['empId', 'clientNo', 'empNo', 'recurringId'],
    currencyProps: ['amount'],
  };

  constructor(empId: string | number, clientNo: string | number, empNo: string | number, protectedEmpNo: string, props?: { [key: string]: any }) {
    this.empId = (typeof empId === 'string') ? parseInt(empId) : empId;
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;

    props && convToClass(this, props, RecurringEarnings.convProps);
  }
}

export interface RecurringEarningsRequest {
  protectedEmpNo: string
  Data: RecurringEarnings[]
}  