import React, { useEffect, useRef, useState } from 'react';
import DateRangeSelector from 'core/components/form-controls/DateRangeSelector';
import Modal from 'core/components/modals/Modal';
import { Client } from 'core/models';
import { UserMapItem } from 'core/models/Downloader.model';
import { DownloaderService } from 'core/services/downloader.service';
import { downloadReportThunk } from 'core/store/actions/download-report-file.action';
import { resetExportState, setReportFileName } from 'core/store/slices/reportDownloader.slice';
import { Files } from 'enums/Files';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { IOption } from 'interfaces/IOption';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import DateObject from 'react-date-object';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { getMondayAndFriday } from 'utilities/utilities';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.scss';

const now = new Date();

type Props = {
  show: boolean;
  onHide: () => void;
  isEmpEarningDownload: boolean;
  isEarningsDownloadSummary: boolean;
  selectedUserMap: UserMapItem | null;
  downloadToClientLibrary: boolean;
  selectedClients: Client[];
  writeToSelectedClientLibraries: Client[];
};

const ExportModal = ({
  show,
  onHide,
  selectedUserMap,
  isEmpEarningDownload,
  isEarningsDownloadSummary,
  downloadToClientLibrary,
  selectedClients,
  writeToSelectedClientLibraries,
}: Props) => {
  const dispatch = useAppDispatch();
  
  const location = useLocation();
  const isEmDownload = location.pathname.includes('/em/');
  
  const calendarRef = useRef<any>(null);
  const calendarContainerRef = useRef<HTMLDivElement>(null);
  
  const [downloadEmployees, setDownloadEmployees] = useState<number>(0);
  const [dates, setDates] = useState<[Date | null, Date | null]>(isEmpEarningDownload ? getMondayAndFriday(now) : [null, null]);
  const [fileTypes, setFileTypes] = useState<string[]>([Files.XLS.toUpperCase()]);
  const [prYear, setPrYear] = useState<number>(new Date().getFullYear());
  
  const client = useAppSelector((state) => { return state?.client; });
  const { exporting: exportingReport, exportingFulfilled } = useAppSelector(({ reportDownloader }) => reportDownloader);

  const clientNo: any = client?.client?.clientNo || '';
  
  const getYears = () => {
    if (client?.client && client.client?.startDate) {
      const startYear = new Date(client.client.startDate).getFullYear();
      const lastYear = new Date().getFullYear() + 1;

      return Array(lastYear - (startYear - 1))
        .fill('')
        .map((_, i) => {return lastYear - i;}) as Array<number>;
    }
  };
  
  const years = getYears() || [];
  const selectOptions = years.map((year) => {
    return (
      <option key={year}>{year}</option>
    );
  });
  
  useEffect(() => {
    // when export succeeds, reset the store state and hide the modal. Will only hide when request is fulfilled
    if (!exportingFulfilled) return;
    dispatch(resetExportState(false));
    onHide();
  }, [exportingFulfilled]);

  useOnClickOutside(calendarContainerRef, () => {
    calendarRef?.current?.closeCalendar?.();
  });

  const exportReport = async () => {
    if (!selectedUserMap?.userMapId) {
      return;
    }
    
    const [defaultBegin, defaultEnd] = getMondayAndFriday(new Date(now.setFullYear(prYear)));
    
    dispatch(setReportFileName(selectedUserMap.description || 'Download'));
    dispatch(downloadReportThunk(() => {
      return DownloaderService.postDownloader({
        userMapId: selectedUserMap.userMapId,
        clientNo: +clientNo,
        prYear: !isEmpEarningDownload ? prYear : now.getFullYear(),
        filePath: '',
        isEarningsDownload: isEmpEarningDownload,
        isEarningsDownloadSummary: isEarningsDownloadSummary,
        isHRDownload: false,
        beginDate: new DateObject(new Date(dates?.[0] ?? defaultBegin)).format('YYYY-MM-DD'),
        endDate: new DateObject(new Date(dates?.[1] ?? defaultEnd)).format('YYYY-MM-DD'),
        downloadFileTypeAs: fileTypes,
        employeesOption: downloadEmployees,
        printToClientLibrary: downloadToClientLibrary,
        clientNumbers: selectedClients.map(x => x.clientNo.toString()),
        writeToSelectedClientLibraries: writeToSelectedClientLibraries.map(x => x.clientNo.toString()),
      });
    })).then(() => {
      dispatch(setPrYear(new Date().getFullYear()));
    });
  };
  
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      title="Export Settings"
    >
      <Row>
        <Col sm={6}>
          <p className="mb-1 font-weight-bold">Choose Employee Type</p>
          {[
            {
              label: 'All Employees',
              value: 0,
            },
            {
              label: 'Active Employees',
              value: 1,
            },
            {
              label: 'Terminated Employees',
              value: 2,
            },
          ].map((item: IOption) => {
            const id = (item?.label as string).replaceAll(' ', '-').toLowerCase();

            return (
              <div
                key={id}
                className="d-flex align-items-center"
                style={{ columnGap: '0.3rem' }}
              >
                <input
                  type="radio"
                  name="employee-type"
                  id={id}
                  value={item?.value}
                  checked={downloadEmployees === item?.value}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDownloadEmployees(item?.value as number);
                    }
                  }}
                />
                <label
                  htmlFor={id}
                  className="mb-0"
                >
                  {item?.label}
                </label>
              </div>
            );
          })}
        </Col>
        {isEmpEarningDownload ?
          <Col
            sm={6}
            ref={calendarContainerRef}
          >
            <p className="mb-1 font-weight-bold">Set Date Range</p>
            <DateRangeSelector
              name="fromDate"
              date1={new Date(dates?.[0] ?? new Date())}
              date2={new Date(dates?.[1] ?? new Date())}
              returnDates={(_dates) => { setDates(_dates); }}
            />
          </Col>
          :
          <Col sm={6}>
            <div>
              <p className="mb-1 font-weight-bold">PR Year</p>
              <select
                className={styles['ytd-year-select']}
                value={prYear}
                onChange={(e) => {
                  setPrYear(parseInt(e.target.value));
                }
                }
              >
                {selectOptions}
              </select>
            </div>
          </Col>
        }
        <Col
          xs={12}
          className="mt-4"
        >
          <div className="d-flex align-items-end justify-content-between">
            <div>
              <p className="mb-1 font-weight-bold">File Type</p>
              <div
                className="d-flex align-items-center"
                style={{ columnGap: '1rem' }}
              >
                {
                  [
                    {
                      label: 'EXCEL',
                      value: 'XLS',
                    },
                    {
                      label: 'CSV',
                      value: 'CSV',
                    },
                    {
                      label: 'PDF',
                      value: 'PDF',
                    },
                  ].map((item: IOption) => {
                    const id = (item?.value as string).toLowerCase();

                    return (
                      <div
                        key={id}
                        className="d-flex align-items-center"
                        style={{ columnGap: '0.3rem' }}
                      >
                        <input
                          type="checkbox"
                          name="file-type"
                          id={id}
                          value={item?.value}
                          checked={fileTypes.includes(item?.value as string)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFileTypes([...fileTypes, item?.value as string]);
                            } else {
                              const filter = structuredClone(fileTypes).filter(x => x !== item?.value as string);
                              setFileTypes([...filter]);
                            }
                          }}
                        />
                        <label
                          htmlFor={id}
                          className="mb-0"
                        >
                          {item?.label}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div
              className="d-flex"
              style={{ gap: '0.5rem' }}
            >
              <Button
                variant="outline-primary"
                style={{ borderRadius: 999 }}
                onClick={() => {
                  setDownloadEmployees(0);
                  setFileTypes([Files.XLS.toUpperCase()]);
                }}
              >
                Clear
              </Button>
              <Button
                variant="primary"
                style={{ borderRadius: 999 }}
                onClick={exportReport}
                disabled={exportingReport}
              >
                {exportingReport ? (
                  <div className="d-flex">
                    <span className="mr-2">Exporting</span>
                    <Spinner
                      animation="border"
                      size="sm"
                    />
                  </div>
                ) : 'Export'}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default ExportModal;