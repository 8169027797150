import Icon from 'core/components/shared/Icon';
import PanelHeader from 'core/components/shared/PanelHeader';
import { InterviewLocation } from 'core/models';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'utilities/hooks';
import { Row, Col } from 'react-bootstrap';
import InterviewLocationModal from './InterviewLocation.modal';
import { GridApi, ICellRendererParams, ColDef, GridOptions } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { deleteInterviewLocation, getInterviewLocations } from 'core/store/slices/applicantTracking.slice';
import { useDispatch } from 'react-redux';

const InterviewLocationsPage = () => {
   
  const dispatch = useDispatch();
  const [interviewLocations] = useAppSelector(
    (state) => {
      return [state.applicantTracking.interviewLocations];
    },
  );

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [rowData, setRowData] = useState<InterviewLocation[]>([]);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<InterviewLocation>();

  useEffect(()=>{
    dispatch(getInterviewLocations());
  }, []);

  useEffect(()=>{
    interviewLocations && setRowData(interviewLocations);
  }, [interviewLocations]);
  
  const onAdd = () => {
    setSelectedLocation(undefined);
    setShowDetailModal(true);
  };

  const openDetailModal = (params: ICellRendererParams) =>{
    setSelectedLocation(params.data);
    setShowDetailModal(true);
  };
  
  const onDeleteLocation = (params: ICellRendererParams) =>{
    dispatch(deleteInterviewLocation(params.data.interviewLocationId));
  };

  const columns = useMemo<ColDef[]>(
    () => {
      return [
        {
          field: 'description',
          headerName: 'Location',
          flex:1,         
          cellStyle: { textAlign: 'left' },
          cellRenderer: (params: ICellRendererParams) => {         
            return (
              <button
                type="button"
                className="btn btn-link"
                style={{ textAlign:'left' }}
                onClick={() => { return openDetailModal(params); }
                }
              >
                {params.data.description}
              </button>             
            );
          },        
        },       
        {
          field: 'delete',
          cellRenderer: (params: ICellRendererParams) => {
            if (params.data.isDefault) {
              return (<span></span>);
            } else {               
              return (
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => { return onDeleteLocation(params); }
                  }
                >
                  <Icon
                    name="minus-circle"
                    className="fa-minus-circle"
                  />
                </button>             
              );
            }
          },
          width: 70,
        },     
      ];
    },
    [],
  );

  const gridOptions: GridOptions = {   
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  return (
    <Row className="no-gutters p-0">
      <Col className="mt-1 shadow p-3 border">
        <PanelHeader
          title="Interview Location"
          iconName="compass-drafting"         
        > 
          <button
            type="button"
            className="btn btn-link dm-grid-action-title pb-0 mr-2"
            onClick={onAdd}
          >
            Add New Interview Location <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <div
          className="table-wrapper-wrapper ag-theme-balham mt-3"         
        >
          <AgGridReact
            gridOptions={gridOptions}
            rowData={rowData}
            columnDefs={columns}
            onGridReady={onGridReady}
          />
        </div>
        {showDetailModal && (
          <InterviewLocationModal
            item={selectedLocation}
            show={showDetailModal}
            onHide={() => { return setShowDetailModal(false); } }            
          />
        )}
      </Col>
    </Row>
  );
};

export default InterviewLocationsPage;
