import React, { CSSProperties } from 'react';
import { FieldInputSettings  } from 'core/components/form-controls/types';
import {
  InputGrp,
  SelectModalGrp,
} from 'core/components/form-controls';
import PanelHeader from 'core/components/shared/PanelHeader';
import { useSelector } from 'react-redux';
import {
  getNovatimeGroup1,
  getNovatimeGroup2,
  getNovatimeGroup3,
  getNovatimeGroup4,
  getNovatimeGroup5,
  getNovatimeGroup6,
  getNovatimeGroup7,
  getNovatimeGroup8,
  getNovatimeShifts,
  getPayPolicy,
} from 'core/store/selectors';
import DropdownOptionForm from 'core/components/form-controls/select-modal/DropdownOptionForm';
import { getAccess } from 'utilities/utilities';
import { BaseSectionProps } from './PayrollInfoPage';
import { ControlIds } from 'core/constants';

const fs: FieldInputSettings = {
  novAtimeScheduleSupervisorId: {
    name: 'novAtimeScheduleSupervisorId',
    label: ['NOVATIME SCHEDULE', "SUPERVISOR'S USER ID"],
    tallLabel: true,
    groupClass: 'groupClass20',
  },
  shiftNumber: {
    name: 'shiftNumber',
    label: 'SHIFT NUMBER',
    modalTitle: 'SHIFT NUMBERS',
    dropdownName: 'ShiftCode',
    formComponent: DropdownOptionForm,
    tallLabel: true,
    groupClass: 'groupClass25',
    noOption: true,
    addOptionText: 'NOVAtime Shift No',
    controlId: 183,
  },
  payPolicyId: {
    name: 'payPolicyId',
    label: 'PAY POLICY',
    tallLabel: true,
    groupClass: 'groupClass20',
    modalTitle: 'PAY POLICIES',
    dropdownName: 'PayPolicy',
    formComponent: DropdownOptionForm,
    addOptionText: 'Pay Policy',
    noOption: true,
    idEditable: true,
    controlId: 184,
  },
  group1LookUpId: {
    name: 'group1LookUpId',
    label: 'NOVATIME GROUP 1',
    groupClass: 'groupClass20',
    modalTitle: 'NOVATIME GROUP 1',
    dropdownName: 'NOVAtimeGroup1',
    formComponent: DropdownOptionForm,
    addOptionText: 'NOVATIME GROUP',
  },
  group2LookUpId: {
    name: 'group2LookUpId',
    label: 'NOVATIME GROUP 2',
    groupClass: 'groupClass20',
    modalTitle: 'NOVATIME GROUP 2',
    dropdownName: 'NOVAtimeGroup2',
    formComponent: DropdownOptionForm,
    addOptionText: 'NOVATIME GROUP',
  },
  group3LookUpId: {
    name: 'group3LookUpId',
    label: 'NOVATIME GROUP 3',
    groupClass: 'groupClass20',
    modalTitle: 'NOVATIME GROUP 3',
    dropdownName: 'NOVAtimeGroup3',
    formComponent: DropdownOptionForm,
    addOptionText: 'NOVATIME GROUP',
  },
  group4LookUpId: {
    name: 'group4LookUpId',
    label: 'NOVATIME GROUP 4',
    groupClass: 'groupClass20',
    modalTitle: 'NOVATIME GROUP 4',
    dropdownName: 'NOVAtimeGroup4',
    formComponent: DropdownOptionForm,
    addOptionText: 'NOVATIME GROUP',
  },
  group5LookUpId: {
    name: 'group5LookUpId',
    label: 'NOVATIME GROUP 5',
    groupClass: 'groupClass20',
    modalTitle: 'NOVATIME GROUP 5',
    dropdownName: 'NOVAtimeGroup5',
    formComponent: DropdownOptionForm,
    addOptionText: 'NOVATIME GROUP',
    controlId: 179,
  },
  group6LookUpId: {
    name: 'group6LookUpId',
    label: 'NOVATIME GROUP 6',
    groupClass: 'groupClass20',
    modalTitle: 'NOVATIME GROUP 6',
    dropdownName: 'NOVAtimeGroup6',
    formComponent: DropdownOptionForm,
    addOptionText: 'NOVATIME GROUP',
    controlId: 180,
  },
  group7LookUpId: {
    name: 'group7LookUpId',
    label: 'NOVATIME GROUP 7',
    groupClass: 'groupClass20',
    modalTitle: 'NOVATIME GROUP 7',
    dropdownName: 'NOVAtimeGroup7',
    formComponent: DropdownOptionForm,
    addOptionText: 'NOVATIME GROUP',
    controlId: 181,
  },
  group8LookUpId: {
    name: 'group8LookUpId',
    label: 'NOVATIME GROUP 8',
    groupClass: 'groupClass20',
    modalTitle: 'NOVATIME GROUP 8',
    dropdownName: 'NOVAtimeGroup8',
    formComponent: DropdownOptionForm,
    addOptionText: 'NOVATIME GROUP',
    controlId: 182,
  },
};

const styles: { [key: string]: CSSProperties } = {
  novaTimeTitle: {
    fontSize: '16px',
    fontWeight: 700,
    marginTop: '8px',
  },
  novaTimeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  groupClass20Override: {
    width: 'auto',
  },
};

const PayrollInfoTimeAndAttendance: React.FC<BaseSectionProps> = ({
  methods: { register, setValue, errors, control, watch },
  sectionAccess,
}) => {
  const payPolicyOpts = useSelector(getPayPolicy);
  const novatimeGroup1Opts = useSelector(getNovatimeGroup1);
  const novatimeGroup2Opts = useSelector(getNovatimeGroup2);
  const novatimeGroup3Opts = useSelector(getNovatimeGroup3);
  const novatimeGroup4Opts = useSelector(getNovatimeGroup4);
  const novatimeGroup5Opts = useSelector(getNovatimeGroup5);
  const novatimeGroup6Opts = useSelector(getNovatimeGroup6);
  const novatimeGroup7Opts = useSelector(getNovatimeGroup7);
  const novatimeGroup8Opts = useSelector(getNovatimeGroup8);
  const novatimeShiftOpts = useSelector(getNovatimeShifts);

  const {
    shiftNumber,
    payPolicyId,
    group1LookUpId,
    group2LookUpId,
    group3LookUpId,
    group4LookUpId,
    group5LookUpId,
    group6LookUpId,
    group7LookUpId,
    group8LookUpId,
  } = watch([
    'shiftNumber',
    'payPolicyId',
    'group1LookUpId',
    'group2LookUpId',
    'group3LookUpId',
    'group4LookUpId',
    'group5LookUpId',
    'group6LookUpId',
    'group7LookUpId',
    'group8LookUpId',
  ]);

  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Time &amp; Attendance" />
      <div
        className="mt-2"
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <InputGrp
          {...fs.novAtimeScheduleSupervisorId}
          {...getAccess(sectionAccess, 6)}
          groupStyle={styles.groupClass20Override}
          errors={errors.novAtimeScheduleSupervisorId}
          ref={register()}
        />
        <SelectModalGrp
          {...fs.shiftNumber}
          {...getAccess(sectionAccess, 6, ControlIds.shiftNumber)}
          errors={errors.shiftNumber}
          control={control}
          value={shiftNumber}
          options={novatimeShiftOpts}
          setValue={setValue}
        />
        <SelectModalGrp
          {...fs.payPolicyId}
          {...getAccess(sectionAccess, 6, ControlIds.payPolicyId)}
          errors={errors.payPolicyId}
          control={control}
          value={payPolicyId}
          options={payPolicyOpts}
          setValue={setValue}
        />
      </div>
      <div
        style={styles.novaTimeTitle}
      >
        Novatime Groups
      </div>
      <div
        style={styles.novaTimeWrapper}
      >
        {novatimeGroup1Opts?.length > 1 && (
          <SelectModalGrp
            {...fs.group1LookUpId}
            {...getAccess(sectionAccess, 6, fs.group1LookUpId.controlId)}
            groupStyle={styles.groupClass20Override}
            errors={errors.group1LookUpId}
            control={control}
            value={group1LookUpId}
            options={novatimeGroup1Opts}
            setValue={setValue}
          />
        )}
        {novatimeGroup2Opts?.length > 1 && (
          <SelectModalGrp
            {...fs.group2LookUpId}
            {...getAccess(sectionAccess, 6, fs.group2LookUpId.controlId)}
            groupStyle={styles.groupClass20Override}
            errors={errors.group2LookUpId}
            control={control}
            value={group2LookUpId}
            options={novatimeGroup2Opts}
            setValue={setValue}
          />
        )}
        {novatimeGroup3Opts?.length > 1 && (
          <SelectModalGrp
            {...fs.group3LookUpId}
            {...getAccess(sectionAccess, 6, fs.group3LookUpId.controlId)}
            groupStyle={styles.groupClass20Override}
            errors={errors.group3LookUpId}
            control={control}
            value={group3LookUpId}
            options={novatimeGroup3Opts}
            setValue={setValue}
          />
        )}
        {novatimeGroup4Opts?.length > 1 && (
          <SelectModalGrp
            {...fs.group4LookUpId}
            {...getAccess(sectionAccess, 6, fs.group4LookUpId.controlId)}
            groupStyle={styles.groupClass20Override}
            errors={errors.group4LookUpId}
            control={control}
            value={group4LookUpId}
            options={novatimeGroup4Opts}
            setValue={setValue}
          />
        )}
        {novatimeGroup5Opts?.length > 1 && (
          <SelectModalGrp
            {...fs.group5LookUpId}
            {...getAccess(sectionAccess, 6, ControlIds.group5LookUpId)}
            groupStyle={styles.groupClass20Override}
            errors={errors.group5LookUpId}
            control={control}
            value={group5LookUpId}
            options={novatimeGroup5Opts}
            setValue={setValue}
          />
        )}
        {novatimeGroup6Opts?.length > 1 && (
          <SelectModalGrp
            {...fs.group6LookUpId}
            {...getAccess(sectionAccess, 6, ControlIds.group6LookUpId)}
            groupStyle={styles.groupClass20Override}
            errors={errors.group6LookUpId}
            control={control}
            value={group6LookUpId}
            options={novatimeGroup6Opts}
            setValue={setValue}
          />
        )}
        {novatimeGroup7Opts?.length > 1 && (
          <SelectModalGrp
            {...fs.group7LookUpId}
            {...getAccess(sectionAccess, 6, ControlIds.group7LookUpId)}
            groupStyle={styles.groupClass20Override}
            errors={errors.group7LookUpId}
            control={control}
            value={group7LookUpId}
            options={novatimeGroup7Opts}
            setValue={setValue}
          />
        )}
        {novatimeGroup8Opts?.length > 1 && (
          <SelectModalGrp
            {...fs.group8LookUpId}
            {...getAccess(sectionAccess, 6, ControlIds.group8LookUpId)}
            groupStyle={styles.groupClass20Override}
            errors={errors.group8LookUpId}
            control={control}
            value={group8LookUpId}
            options={novatimeGroup8Opts}
            setValue={setValue}
          />
        )}
      </div>
    </div>
  );
};

export default PayrollInfoTimeAndAttendance;
