import { AxiosResponse } from "axios";
import { HttpResponse } from '../models';
import { EmpCustomFieldRequest } from '../models/HREmployee.model';
import {
  HrEmployeeCompanyIssuedProperty,
  HrEmployeeCompanyIssuedPropertyRequest,
} from '../models/HrEmployeeCompanyIssuedProperty.model';
import { HrEmployeeCustomFields } from '../models/HrEmployeeCustomFields.model';
import {
  HrEmployeeEmergencyContact,
  HrEmployeeStatusEmergencyContactIdRequest,
} from '../models/HrEmployeeEmergencyContact.model';
import { HrEmployeeStatus } from '../models/HrEmployeeStatus';
import { API_EMPLOYEE_MASTER } from './API';
import { HrEndpoints } from './URL';

const getHrEmployeeStatus = (
  protectedEmpNo: string,
): Promise<AxiosResponse<HrEmployeeStatus>> => {
  return API_EMPLOYEE_MASTER.get<HrEmployeeStatus>(
    HrEndpoints.HR_EMPLOYEE_STATUS(protectedEmpNo),
  );
};

const putHrEmployeeStatus = (
  hrEmployeeStatus: HrEmployeeStatus,
): Promise<AxiosResponse<HttpResponse<HrEmployeeStatus>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<HrEmployeeStatus>>(
    HrEndpoints.HR_EMPLOYEE_STATUS(hrEmployeeStatus.protectedEmpNo),
    hrEmployeeStatus,
  );
};

const getHrEmployeeEmergencyContacts = (
  protectedEmpNo: string,
): Promise<AxiosResponse<HrEmployeeEmergencyContact[]>> => {
  return API_EMPLOYEE_MASTER.get<HrEmployeeEmergencyContact[]>(
    HrEndpoints.HR_EMPLOYEE_EMERGENCY_CONTACTS(protectedEmpNo),
  );
};

const postHrEmployeeEmergencyContacts = (
  request: HrEmployeeEmergencyContact,
): Promise<AxiosResponse<HttpResponse<HrEmployeeEmergencyContact>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<HrEmployeeEmergencyContact>>(
    HrEndpoints.HR_EMPLOYEE_EMERGENCY_CONTACTS(request.protectedEmpNo),
    request,
  );
};

const putHrEmployeeEmergencyContacts = (
  request: HrEmployeeEmergencyContact,
): Promise<AxiosResponse<HttpResponse<HrEmployeeEmergencyContact>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<HrEmployeeEmergencyContact>>(
    HrEndpoints.HR_EMPLOYEE_EMERGENCY_CONTACTS_CONTACT_ID({
      protectedEmpNo: request.protectedEmpNo,
      contactId: request.contactId,
    }),
    request,
  );
};
const deleteHrEmployeeEmergencyContacts = (
  request: HrEmployeeStatusEmergencyContactIdRequest,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    HrEndpoints.HR_EMPLOYEE_EMERGENCY_CONTACTS_CONTACT_ID(request),
  );
};

const getHrEmpCustomFields = (
  protectedEmpNo: string,
): Promise<AxiosResponse<HrEmployeeCustomFields[]>> => {
  return API_EMPLOYEE_MASTER.get<HrEmployeeCustomFields[]>(
    HrEndpoints.HR_EMP_CUSTOM_FIELDS(protectedEmpNo),
  );
};

const postHrEmpCustomField = (
  request: HrEmployeeCustomFields,
): Promise<AxiosResponse<HttpResponse<HrEmployeeCustomFields>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<HrEmployeeCustomFields>>(
    HrEndpoints.HR_EMP_CUSTOM_FIELDS(request.protectedEmpNo),
    request,
  );
};

const putHrEmpCustomField = (
  request: HrEmployeeCustomFields,
): Promise<AxiosResponse<HttpResponse<HrEmployeeCustomFields>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<HrEmployeeCustomFields>>(
    HrEndpoints.HR_EMP_CUSTOM_FIELDS_ID(
      request.protectedEmpNo,
      request.empCustomFieldsId,
    ),
    request,
  );
};

const deleteHrEmpCustomFields = (
  request: EmpCustomFieldRequest,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    HrEndpoints.HR_EMP_CUSTOM_FIELDS_ID(
      request.protectedEmpNo,
      request.empCustomFieldsId,
    ),
  );
};

const getHrEmployeeCompanyProperty = (
  protectedEmpNo: string,
): Promise<AxiosResponse<HrEmployeeCompanyIssuedProperty[]>> => {
  return API_EMPLOYEE_MASTER.get<HrEmployeeCompanyIssuedProperty[]>(
    HrEndpoints.HR_EMPLOYEE_COMPANY_PROPERTY(protectedEmpNo),
  );
};

const postHrEmployeeCompanyProperty = (
  request: HrEmployeeCompanyIssuedPropertyRequest,
): Promise<AxiosResponse<HttpResponse<HrEmployeeCompanyIssuedProperty>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<HrEmployeeCompanyIssuedProperty>>(
    HrEndpoints.HR_EMPLOYEE_COMPANY_PROPERTY(request.protectedEmpNo),
    request,
  );
};

const putHrEmployeeCompanyProperty = (
  request: HrEmployeeCompanyIssuedPropertyRequest,
): Promise<AxiosResponse<HttpResponse<HrEmployeeCompanyIssuedProperty>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<HrEmployeeCompanyIssuedProperty>>(
    HrEndpoints.HR_EMPLOYEE_COMPANY_PROPERTY_ID(
      request.protectedEmpNo,
      request.propertyId,
    ),
    request,
  );
};

const deleteHrEmployeeCompanyProperty = (
  request: HrEmployeeCompanyIssuedPropertyRequest,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    HrEndpoints.HR_EMPLOYEE_COMPANY_PROPERTY_ID(
      request.protectedEmpNo,
      request.propertyId,
    ),
  );
};

export const HrService = {
  getHrEmployeeStatus,
  putHrEmployeeStatus,
  getHrEmployeeEmergencyContacts,
  postHrEmployeeEmergencyContacts,
  putHrEmployeeEmergencyContacts,
  deleteHrEmployeeEmergencyContacts,
  getHrEmpCustomFields,
  postHrEmpCustomField,
  putHrEmpCustomField,
  deleteHrEmpCustomFields,
  getHrEmployeeCompanyProperty,
  deleteHrEmployeeCompanyProperty,
  postHrEmployeeCompanyProperty,
  putHrEmployeeCompanyProperty,
};
