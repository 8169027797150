import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class EmpDocComp {
  empId = 0;

  //clientNo: number = 0;
  //empNo: number = 0;
  protectedClientNo = '';

  protectedEmpNo = '';

  docCompHeaderId = 0;

  effectiveDate: Date | string | null = null;

  endDate: Date | string | null = null;

  careerLevelId = 0;

  multiStepId = 0;

  contractVersionId = 0;

  firstName = '';

  lastName = '';

  contracts: DocCompContract[] = [];

  static readonly convProps: PropTypeLists = {
    numberProps: ['empId', 'docCompHeaderId', 'careerLevelId', 'multiStepId', 'contractVersionId'],
    dateProps: ['effectiveDate', 'endDate'],
  };

  constructor(empId: string | number, protectedEmpNo: string, contracts: DocCompContract[], props?: { [key: string]: any }) {
    this.empId = (typeof empId === 'string') ? parseInt(empId) : empId;
    //this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    //this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;
    this.contracts = contracts;

    props && convToClass(this, props, EmpDocComp.convProps);
  }
}

export class DocCompContract {
  docCompId = 0;

  docCompHeaderId = 0;

  compensationIndex = 0;

  compensationType = '';

  capPercentPerContract = 0;

  percentPerContract = 0;

  estimateCollectionPercent = 0;

  deliveryMethod = '';

  deliveryEmail = '';

  inVisalign = false;

  orientationPeriod = '';

  maximumCap = 0;

  autoCalc = false;

  initialEmploymentTerm = 0;

  worksDaysPerWeek = 0;

  timeOffDays = 0;

  ceAnnual = 0;

  duesAnnual = 0;

  labsPaid = false;

  annualDues = 0;

  financeCharges = false;

  invisalignExpress = false;

  dsoMembership = false;

  advanceAmendment = false;

  negativeForgiveness = false;

  perDiem = 0;
}