import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridOptions, GridReadyEvent, ValueGetterParams } from '@ag-grid-enterprise/all-modules';
import Modal from 'core/components/modals/Modal';
import { OnTheClockRequest, PayrollUpload, PayrollUploadManageFiles, PayrollUploadFile, PayrollUploadType, PayrollUploadVM, TimeCard } from 'core/models/PayrollUpload.model';
import { getOnTheClockData, getPayrollUploadVM } from 'core/store/selectors/payroll-upload.selector';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ControlDatePickerGrp } from 'core/components/form-controls';
import { copyPayrollUploadMap, loadOnTheClockData, payrollUploadFileTool, savePayrollUploadMapActiveStatuses, storeOnTheClockData } from 'core/store/actions';
import { Col, Row } from 'react-bootstrap';
import CheckboxRenderer from 'utilities/ag-grid-renderers/CheckboxRenderer';
import agButtonRendererComponent from 'utilities/ag-grid-renderers/agButtonRendererComponent';
import ConfirmationModal from 'core/components/modals/confirmation.modal';

type PropTypes = {
  show: boolean;
  onHide: () => void;
};
type FormProps = {
  fromDate: Date;
  toDate: Date;
};

const ManageClientUploadMapsModal: React.FC<PropTypes> = ({
  show,
  onHide,
}) => {
  const dispatch = useDispatch();

  const [rowData, setRowData] = useState<PayrollUploadManageFiles[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [actionAfterSaveConfirmation, setActionAfterSaveConfirmation] = useState('None');
  const [copyUploadMap, setCopyUploadMap] = useState<PayrollUploadManageFiles | undefined>();
  const payrollUploadVM = useSelector(getPayrollUploadVM) as PayrollUploadVM;
  const isDirtyRef = useRef(false);
  const copyUploadMapRef = useRef<PayrollUploadManageFiles | undefined>();
  
  const cellValueChanged = () => {
    setIsDirty(true);
    isDirtyRef.current = true;
  };

  const checkIsDirty = (isDirtyValue: boolean) : boolean => {
    if (!isDirtyValue) return false;

    setShowConfirmationModal(true);
    return true;
  };

  const CopyUploadMap = (selectedRow: PayrollUploadManageFiles | undefined) => {
    if (!selectedRow) return;

    if (checkIsDirty(isDirtyRef.current)) {
      setCopyUploadMap(selectedRow);
      copyUploadMapRef.current = selectedRow;
      setActionAfterSaveConfirmation('Copy Map');
      return;
    }

    dispatch(copyPayrollUploadMap(selectedRow.mapId));
  };

  const columns: ColDef[] = [
    {
      field: 'description',
      headerName: 'Description',
      sortable: true,
      width: 150,
    },
    {
      field: 'active',
      headerName: 'Active',
      cellRenderer: 'checkBoxRenderer',
      cellStyle: { paddingLeft: '16px'},
      width: 35,
    },
    {
      field: 'copyMap',
      headerName: 'Copy Map',
      cellRendererParams: { label: 'Copy Map', clickHandler: CopyUploadMap },
      cellRenderer: 'buttonRenderer',
      cellClass: 'ag-cell-border',
      width: 50,
    },
  ];

  const gridOptions: GridOptions = {
    columnDefs: columns,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    components: {
      checkBoxRenderer: CheckboxRenderer,
      buttonRenderer: agButtonRendererComponent,
    },
    pagination: true,
    paginationPageSize: 10,
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.sizeColumnsToFit();
  };

  const hideModal = (performIsDirtyCheck: boolean) => {
    if (performIsDirtyCheck && checkIsDirty(isDirty)) {
      setActionAfterSaveConfirmation('Close');
      return;
    }

    setActionAfterSaveConfirmation('None');
    setCopyUploadMap(undefined);
    setIsDirty(false);
    onHide();
  };

  const onSave = () => {
    dispatch(savePayrollUploadMapActiveStatuses(rowData));
  };

  //Close function for confirmation modal
  const confirmationChoice = (confirm: boolean, path: string[] | undefined, cancel: boolean | undefined) => {
    if (cancel) return;
    
    if (confirm) {
      onSave();
    }

    setIsDirty(false);
    isDirtyRef.current = false;

    if (actionAfterSaveConfirmation === 'Close') {
      hideModal(false);
    } else if (actionAfterSaveConfirmation === 'Copy Map') {
      CopyUploadMap(copyUploadMapRef.current ?? copyUploadMap);
    }
  };

  useEffect(() => {
    if (!payrollUploadVM || !payrollUploadVM.clientMaps) return;

    let clientMapsCopy = [...payrollUploadVM?.clientMaps ?? []];
    const sortedMaps = clientMapsCopy.sort((a, b) => a.description.localeCompare(b.description))
      .map(x => ({
        mapId: x.mapId,
        clientNo: x.clients[0].clientNo,
        description: x.description,
        active: x.clients[0].active,
      })) as PayrollUploadManageFiles[];

    setRowData(sortedMaps);
    setIsDirty(false);
  }, [payrollUploadVM]);

  return (
    <Modal
      show={show}
      onHide={() => { return hideModal(true); }}
      size="sm"
      animation={false}
      isDirty={isDirty}
      title="Manage Client Upload Maps"
    >
      <Row>
        <Col
          xs={12}
          className="table-wrapper-wrapper ag-theme-balham"
          style={{ height: '400px' }}
        >
          <AgGridReact
            gridOptions={gridOptions}
            rowData={rowData}
            onCellValueChanged={cellValueChanged}
            onGridReady={onGridReady}
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          className="text-right"
        >
          <button
            type="submit"
            className="btn orange-button-sm mr-3"
            style={{ marginTop: '5px' }}
            onClick={onSave}
            disabled={!isDirty}
          >
            Save
          </button>
        </Col>
      </Row>
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you like to save?'}
          show={showConfirmationModal}
          showCancel={true}
          onConfirmed={confirmationChoice}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
    </Modal>
  );
};
export default ManageClientUploadMapsModal;
