import { ofType } from 'redux-observable';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  Department,
  Dropdown,
  DropdownDeleteRequest,
  HttpResponse,
  Location,
  NOVATime,
  NOVATimeRule,
  NOVATimeSettings,
  NOVATimeShiftNumber,
  PaygradeDropdown,
  SchoolDropdown,
  SubDepartment,
  SubDepartment2,
  TaxEntity,
} from '../../models';
import {
  ShiftPremium,
  ShiftPremiumEarnings,
} from '../../models/ShiftPremium.model';
import { ClientService } from '../../services/client.service';
import { DropdownService } from '../../services/dropdown.service';
import { handleError, handleSuccess } from '../actions';
import {
  createDepartment,
  createDropdown,
  createLocation,
  createShiftPremium,
  createShiftPremiumEarningsCode,
  createShiftPremiumEarningsCodes,
  createSubdepartment,
  createSubdepartment2,
  deleteDepartment,
  deleteDropdown,
  deleteShiftPremium,
  deleteShiftPremiumEarningsCode,
  deleteSubdepartment,
  deleteSubdepartment2,
  loadAllDropdowns,
  loadDepartment,
  loadDepartments,
  loadLocation,
  loadLocations,
  loadNOVATime,
  loadNOVATimeRules,
  loadNOVATimeSettings,
  loadNOVATimeShiftNumbers,
  loadShiftPremiums,
  loadSubdepartment,
  loadSubdepartment2,
  loadSubdepartments,
  loadSubdepartments2,
  loadTaxEntities,
  removeDepartment,
  removeSubDepartment,
  removeSubDepartment2,
  storeAllDropdowns,
  storeDepartment,
  storeDepartments,
  storeLocation,
  storeLocations,
  storeNOVATime,
  storeNOVATimeRules,
  storeNOVATimeSettings,
  storeNOVATimeShiftNumbers,
  storeShiftPremiums,
  storeSubdepartment,
  storeSubdepartment2,
  storeSubdepartments,
  storeSubdepartments2,
  storeTaxEntities,
  updateDepartment,
  updateDropdown,
  updateLocation,
  updatePaygradeDropdown,
  updateShiftPremium,
  updateSubdepartment,
  updateSubdepartment2,
  loadPaygradeDropdowns,
  createPaygradeDropdown,
  storePaygradeDropdowns,
  loadSchoolDropdowns,
  createSchoolDropdown,
  storeSchoolDropdowns,
  updateSchoolDropdown,
  removeLocation,
  deleteLocation,
} from '../actions/dropdown.action';

interface Actions<Type> {
  type: string;
  payload: Type;
}

const loadAllDropdowns$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadAllDropdowns.type),
    switchMap(() => {
      return from(DropdownService.getAllDropdowns()).pipe(
        map((res: any) => {
          return res.data;
        }),
        map((res: Dropdown[]) => {return storeAllDropdowns(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const createDropdown$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(createDropdown.type),
    switchMap((action: { payload: Dropdown; }) => {
      return from(DropdownService.createDropdown(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res) => {
          // TODO: do we really need to load all of them again? most probably return the updated dropdown...
          return [
            loadAllDropdowns(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};
const updateDropdown$ = (action$: Observable<Actions<Dropdown>>) => {
  return action$.pipe(
    ofType(updateDropdown.type),
    switchMap((action: { payload: Dropdown; }) => {
      return from(DropdownService.updateDropdown(action.payload)).pipe(
        map((res: any) => {
          return res.data;
        }),
        mergeMap((res) => {
          // TODO: do we really need to load all of them again? most probably return the updated dropdown...
          return [
            loadAllDropdowns(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const deleteDropdown$ = (action$: Observable<Actions<DropdownDeleteRequest[]>>) => {
  return action$.pipe(
    ofType(deleteDropdown.type),
    switchMap((action: { payload: any[]; }) => {
      return forkJoin(
        action.payload.map((x: { id: string; dropdownName: string; }) => {
          handleSuccess('Option successfully deleted');
          return from(DropdownService.deleteDropdown(x.id, x.dropdownName));
        },
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
        ),
      ).pipe(
        switchMap(() => {
          switch (action.payload[0].dropdownName) {
            case 'payGrade':
              return of(loadPaygradeDropdowns());
            default:
              return of(loadAllDropdowns());
          }
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadLocations$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadLocations.type),
    switchMap(() => {
      return from(DropdownService.getLocations()).pipe(
        map((res: any) => {return res.data;}),
        map((res: Location[]) => {return storeLocations(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const createLocation$ = (action$: Observable<Actions<Location>>) => {
  return action$.pipe(
    ofType(createLocation.type),
    switchMap((action: { payload: Location; }) => {
      return from(DropdownService.createLocation(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map((res: HttpResponse<Location>) => {return storeLocation(res.value);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};
const updateLocation$ = (action$: Observable<Actions<Location>>) => {
  return action$.pipe(
    ofType(updateLocation.type),
    switchMap((action: { payload: Location; }) => {
      return from(DropdownService.updateLocation(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map((res: HttpResponse<Location>) => {return storeLocation(res.value);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};
const deleteLocation$ = (action$: Observable<Actions<number>>) => {
  return action$.pipe(
    ofType(deleteLocation.type),
    mergeMap((action:{ payload:number }) => {
      return from(DropdownService.deleteLocation(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<Department>) => {
          return [
            removeLocation(action.payload),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};
const loadLocation$ = (action$: Observable<Actions<number>>) => {
  return action$.pipe(
    ofType(loadLocation.type),
    switchMap((action: { payload: number; }) => {
      return from(DropdownService.getLocation(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map((res: Location) => {return storeLocation(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadDepartments$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadDepartments.type),
    switchMap((action: any) => {
      return from(DropdownService.getDepartments()).pipe(
        map((res: any) => {return res.data;}),
        map((res: Department[]) => {return storeDepartments(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};
const createDepartment$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(createDepartment.type),
    switchMap((action: { payload: Department; }) => {
      return from(DropdownService.createDepartment(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<Department>) => {
          return [
            storeDepartment(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};
const updateDepartment$ = (action$: Observable<Actions<Department>>) => {
  return action$.pipe(
    ofType(updateDepartment.type),
    mergeMap((action: { payload:Department }) => {
      return from(DropdownService.updateDepartment(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<Department>) => {
          return [
            storeDepartment(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const deleteDepartment$ = (action$: Observable<Actions<number>>) => {
  return action$.pipe(
    ofType(deleteDepartment.type),
    mergeMap((action:{ payload:number }) => {
      return from(DropdownService.deleteDepartment(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<Department>) => {
          return [
            removeDepartment(action.payload),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};
const loadDepartment$ = (action$: Observable<Actions<number>>) => {
  return action$.pipe(
    ofType(loadDepartment.type),
    switchMap((action: { payload: number; }) => {
      return from(DropdownService.getDepartment(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map((res: Department) => {return storeDepartment(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadSubdepartments$ = (action$: Observable<Actions<SubDepartment>>) => {
  return action$.pipe(
    ofType(loadSubdepartments.type),
    switchMap(() => {
      return from(DropdownService.getSubdepartments()).pipe(
        map((res: any) => {return res.data;}),
        map((res: SubDepartment[]) => {return storeSubdepartments(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const createSubdepartment$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(createSubdepartment.type),
    switchMap((action: { payload: SubDepartment; }) => {
      return from(DropdownService.createSubdepartment(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<SubDepartment>) => {
          return [
            storeSubdepartment(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const updateSubdepartment$ = (action$: Observable<Actions<SubDepartment>>) => {
  return action$.pipe(
    ofType(updateSubdepartment.type),
    mergeMap((action: { payload: SubDepartment; }) => {
      return from(DropdownService.updateSubdepartment(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<SubDepartment>) => {
          return [
            storeSubdepartment(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const deleteSubDepartment$ = (action$: Observable<Actions<number>>) => {
  return action$.pipe(
    ofType(deleteSubdepartment.type),
    mergeMap((action: { payload: number; }) => {
      return from(DropdownService.deleteSubDepartment(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<SubDepartment>) => {
          return [
            removeSubDepartment(action.payload),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadSubdepartment$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadSubdepartment.type),
    switchMap((action: { payload: number; }) => {
      return from(DropdownService.getSubdepartment(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map((res: SubDepartment) => {return storeSubdepartment(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadSubdepartments2$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadSubdepartments2.type),
    switchMap((action: any) => {
      return from(DropdownService.getSubdepartments2()).pipe(
        map((res: any) => {return res.data;}),
        map((res: SubDepartment2[]) => {return storeSubdepartments2(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};
const createSubdepartment2$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(createSubdepartment2.type),
    switchMap((action: { payload: SubDepartment2; }) => {
      return from(DropdownService.createSubdepartment2(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<SubDepartment2>) => {
          return [
            storeSubdepartment2(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const updateSubdepartment2$ = (action$: Observable<Actions<SubDepartment2>>) => {
  return action$.pipe(
    ofType(updateSubdepartment2.type),
    mergeMap((action: { payload: SubDepartment2; }) => {
      return from(DropdownService.updateSubdepartment2(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<SubDepartment2>) => {
          return [
            storeSubdepartment2(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const deleteSubDepartment2$ = (action$: Observable<Actions<number>>) => {
  return action$.pipe(
    ofType(deleteSubdepartment2.type),
    mergeMap((action: { payload: number; }) => {
      return from(DropdownService.deleteSubDepartment2(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<SubDepartment2>) => {
          return [
            removeSubDepartment2(action.payload),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadSubdepartment2$ = (action$: Observable<Actions<number>>) => {
  return action$.pipe(
    ofType(loadSubdepartment2.type),
    switchMap((action: { payload: number; }) => {
      return from(DropdownService.getSubdepartment2(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map((res: SubDepartment2) => {return storeSubdepartment2(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadNOVATimeSettings$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadNOVATimeSettings.type),
    switchMap((action: any) => {
      return from(ClientService.getNOVATimeSettings()).pipe(
        map((res: any) => { return res.data; }),
        map((res: NOVATimeSettings) => {return storeNOVATimeSettings(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadNOVATime$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadNOVATime.type),
    switchMap((action: any) => {
      return from(ClientService.getNOVATime()).pipe(
        map((res: any) => {return res.data;}),
        map((res: NOVATime) => {return storeNOVATime(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadNOVATimeShiftNumbers$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadNOVATimeShiftNumbers.type),
    switchMap((action: any) => {
      return from(ClientService.getNOVATimeShiftNumbers()).pipe(
        map((res: any) => {return res.data;}),
        map((res: NOVATimeShiftNumber[]) => {return storeNOVATimeShiftNumbers(res);},
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadNOVATimeRules$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadNOVATimeRules.type),
    switchMap(() => {
      return from(ClientService.getNOVATimeRules()).pipe(
        map((res: any) => {return res.data;}),
        map((res: NOVATimeRule[]) => {return storeNOVATimeRules(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadShiftPremiums$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadShiftPremiums.type),
    switchMap(() => {
      return from(DropdownService.getShiftPremiums()).pipe(
        map((res: any) => {return res.data;}),
        map((res: ShiftPremium[]) => {return storeShiftPremiums(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const createShiftPremium$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(createShiftPremium.type),
    switchMap((action: { payload: ShiftPremium; }) => {
      return from(DropdownService.postShiftPremium(action.payload)).pipe(
        map((res: any) => {
          return action.payload.earningsCodes.map(
            (ec: ShiftPremiumEarnings) => {
              return {
                ...ec,
                shiftPremiumId: res.data.value?.shiftPremiumId ?? 0,
              };
            },
          );
        },
        ),
        mergeMap((earnings: ShiftPremiumEarnings[]) => {
          const outStream = [
            handleSuccess('Shift premium created successfully'),
          ];
          
          // let earnings code logic handle reloading if we have any, else just do it now.
          if (earnings?.length) {
            outStream.push(createShiftPremiumEarningsCodes(earnings));
          } else {
            outStream.push(loadShiftPremiums());
          }
          
          return outStream;
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const updateShiftPremiums$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(updateShiftPremium.type),
    switchMap((action: { payload: ShiftPremium; }) => {
      return from(DropdownService.putShiftPremium(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map(() => {return loadShiftPremiums();}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const deleteShiftPremium$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(deleteShiftPremium.type),
    switchMap((action: { payload: ShiftPremium; }) => {
      return from(DropdownService.deleteShiftPremium(action.payload)).pipe(
        mergeMap(() => {
          return [
            loadShiftPremiums(),
            handleSuccess('Shift premium deleted successfully'),
          ];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const createShiftPremiumEarningsCode$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(createShiftPremiumEarningsCode.type),
    switchMap((action: { payload: ShiftPremiumEarnings; }) => {
      return from(
        DropdownService.postShiftPremiumEarningsCode(action.payload),
      ).pipe(
        map(() => {return loadShiftPremiums();}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const createShiftPremiumEarningsCodes$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(createShiftPremiumEarningsCodes.type),
    switchMap((action: { payload: ShiftPremiumEarnings[]; }) => {
      return forkJoin(
        action.payload.map((x: ShiftPremiumEarnings) => {return from(DropdownService.postShiftPremiumEarningsCode(x));},
        ),
      ).pipe(
        switchMap(() => {return [loadShiftPremiums()];}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const deleteShiftPremiumEarningsCode$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(deleteShiftPremiumEarningsCode.type),
    switchMap((action: { payload: ShiftPremiumEarnings; }) => {
      return from(
        DropdownService.deleteShiftPremiumEarningsCode(action.payload),
      ).pipe(
        map(() => {return loadShiftPremiums();}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadTaxEntities$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadTaxEntities.type),
    switchMap((action: { payload: { country: any; state: any; type: any; }; }) => {
      const { country, state, type } = action.payload;
      return from(
        DropdownService.getTaxEntities(country, state, type),
      ).pipe(
        map((res: any) => {return res.data;}),
        map((res: TaxEntity[]) => {return storeTaxEntities(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    }),
  );
};

const loadPaygradeDropdowns$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadPaygradeDropdowns.type),
    switchMap((action: any) => {
      return from(DropdownService.getPaygradeDropdowns()).pipe(
        map((res: any) => {return res.data;}),
        map((res: PaygradeDropdown[]) => {return storePaygradeDropdowns(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const createPaygradeDropdown$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(createPaygradeDropdown.type),
    switchMap((action: { payload: PaygradeDropdown; }) => {
      return from(DropdownService.createPaygradeDropdown(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map(() => {return loadPaygradeDropdowns();}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};
const updatePaygradeDropdown$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(updatePaygradeDropdown.type),
    switchMap((action: { payload: PaygradeDropdown; }) => {
      return from(DropdownService.updatePaygradeDropdown(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map(() => {return loadPaygradeDropdowns();}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadSchoolDropdowns$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadSchoolDropdowns.type),
    switchMap((action: any) => {
      return from(DropdownService.getEducationSchools()).pipe(
        map((res: any) => {return res.data;}),
        map((res: SchoolDropdown[]) => {return storeSchoolDropdowns(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const createSchoolDropdown$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(createSchoolDropdown.type),
    switchMap((action: { payload: SchoolDropdown; }) => {
      return from(DropdownService.createEducationSchools(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map(() => {return loadSchoolDropdowns();}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const updateSchoolDropdown$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(updateSchoolDropdown.type),
    switchMap((action: { payload: SchoolDropdown; }) => {
      return from(DropdownService.updateEducationSchools(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map(() => {return loadSchoolDropdowns();}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadAllDropdowns$,
  createDropdown$,
  updateDropdown$,
  deleteDropdown$,

  loadLocations$,
  createLocation$,
  updateLocation$,
  loadLocation$,
  deleteLocation$,

  loadDepartments$,
  createDepartment$,
  updateDepartment$,
  loadDepartment$,
  deleteDepartment$,

  loadSubdepartments$,
  createSubdepartment$,
  updateSubdepartment$,
  loadSubdepartment$,
  deleteSubDepartment$,

  loadSubdepartments2$,
  createSubdepartment2$,
  updateSubdepartment2$,
  loadSubdepartment2$,
  deleteSubDepartment2$,

  loadNOVATimeSettings$,
  loadNOVATime$,
  loadNOVATimeShiftNumbers$,
  loadNOVATimeRules$,

  loadShiftPremiums$,
  createShiftPremium$,
  updateShiftPremiums$,
  deleteShiftPremium$,
  createShiftPremiumEarningsCode$,
  createShiftPremiumEarningsCodes$,
  deleteShiftPremiumEarningsCode$,

  loadTaxEntities$,

  loadPaygradeDropdowns$,
  createPaygradeDropdown$,
  updatePaygradeDropdown$,

  loadSchoolDropdowns$,
  createSchoolDropdown$,
  updateSchoolDropdown$,
];
