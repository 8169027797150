import React, { CSSProperties, useState, useMemo, useEffect } from 'react';
import Icon from 'core/components/shared/Icon';
import DisclosureIndicator from './DisclosureIndicator';
import { convToDateString } from 'utilities/utilities';
import { payrollFrequencies } from 'dropdowns/payrollFrequencies';
import ControlTotalPreview from './ControlTotalPreview';
import { BalanceConditions, BodyField, CardProps } from './types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getClientOptionIsContractor, getControlTotalsByOption } from 'core/store/selectors';
import { PayrollControlTotal } from 'core/models';
import { UpdatePayContext } from './CardContext';
import { checkIfBalanced, sumControlTotalData } from './utilities';
import CardView from './CardView';
import CardEdit from './CardEdit';
import './card-styles.scss';
import { storeActiveYear } from 'core/store/actions';
import { useAppDispatch } from 'utilities/hooks';

// TODO: utility function for different shades
const colorScheme = {
  baseBalancedColor: '#e8f8e0',
  baseUnbalancedColor: '#fce3df',
  selectedBalancedColor: '#d0fdba',
  selectedUnbalancedColor: '#ffb4a7',
  baseFinishedColor: '#e8f5fc',
  selectedFinishedColor: '#c8e6f6',
  disabled: '#c5c5c5',
  baseWaitingColor: '#ffffe0',
  selectedWaitingColor: '#fafaa2',
};

const PayrollCard = ({
  payrollDateLine,
  mobileView,
  selected,
  expanded,
  toggleExpanded,
  showMessageModal,
  selectCard,
  setEnablePreview,
}: CardProps) => {
  const [editMode, setEditMode] = useState(false);
  // for updating preview state if control totals by options
  const [controlTotalState, setControlTotalState] = useState<PayrollControlTotal[]>(structuredClone(payrollDateLine.controlTotals));
  const hasNextDatesSet = (payrollDateLine?.nextCheckDate !== '0001-01-01T00:00:00' && payrollDateLine?.nextWeekEnd !== '0001-01-01T00:00:00') ? true : false;

  const history = useHistory();
  
  const dispatch = useAppDispatch();
  
  const controlTotalsByOptions = useSelector(getControlTotalsByOption) as string;
  const isContractor = useSelector(getClientOptionIsContractor);
  
  const showAuxInfo = ['Location', 'Department'].includes(controlTotalsByOptions);
  const usingNewCheckRegister = payrollDateLine.useNewCheckRegister;
  const hasSignedWireOnlyAgreement = payrollDateLine.hasSignedWireOnlyAgreement;
  const unbalancedControlTotals = payrollDateLine.controlTotals?.filter((controlTotal) =>
    !controlTotal?.balanced && sumControlTotalData(controlTotal) > 0);
  const adjustmentTotal = payrollDateLine.adjustmentTotals?.[0];
  const [thirdPartySickCount, prePayCount, voidCount] = [
    adjustmentTotal?.thirdPartySickCount,
    adjustmentTotal?.prePayCount,
    adjustmentTotal?.voidCount,
  ]; // null-friendly destructuring
  const hasAdjustmentData = thirdPartySickCount + prePayCount + voidCount > 0;
  const conditions: BalanceConditions = {
    showAuxInfo,
    hasAdjustmentData,
    hasUnbalancedCTs: !!unbalancedControlTotals?.length,
  };
  const isBalanced = checkIfBalanced(payrollDateLine, conditions);
  const isProcessed = payrollDateLine.status === 'F';
  const isWaiting = payrollDateLine.status === 'W' || payrollDateLine.status === 'E' || payrollDateLine.status === 'P';
  const processMessage = isProcessed ? 'Finished' : (isWaiting) ? 'Waiting to be processed' : 'Ready to be processed';
  const finishedWithSubmitData = payrollDateLine.status === 'F' && payrollDateLine?.submitDate && payrollDateLine?.submitUser?.length;
  
  const date = new Date(payrollDateLine?.submitDate);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are 0-based
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? 'PM' : 'AM';
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  
  if (hours > 12) {
    hours -= 12;
  }

  const formattedDate = `${month}/${day}/${year} at ${hours}:${formattedMinutes} ${amOrPm}`;
  
  if (controlTotalState !== payrollDateLine.controlTotals) {
    setControlTotalState(payrollDateLine.controlTotals);
  }
  
  useEffect(() => {
    if (!showAuxInfo) return;
    const match = controlTotalState.find((controlTotal) => {
      return controlTotal?.payControlTotal && controlTotal?.balanced;
    });
    setEnablePreview(!!match);
  }, [controlTotalState]);
  
  // dude...
  const colorizeBackground = (): string => {
    if (!usingNewCheckRegister) {
      return colorScheme.disabled;
    } else if (isBalanced && !isProcessed && !isWaiting) {
      return colorScheme.baseBalancedColor;
    } else if (!isBalanced && !isProcessed && !isWaiting) {
      return colorScheme.baseUnbalancedColor;
    } else if (isProcessed) {
      return colorScheme.baseFinishedColor;
    } else if (isWaiting) {
      return colorScheme.baseWaitingColor;
    }
    return colorScheme.disabled;
  };
  
  // conditional classes and styling
  const cardClass = `payroll-card-view main-card-view 
${selected ? 'payroll-card-selected' : ''} 
${!usingNewCheckRegister ? 'payroll-card-disabled' : ''}`;
  const cardStyle: CSSProperties = {
    backgroundColor: colorizeBackground(),
  };
  const selectedStyle: CSSProperties = {
    backgroundColor: (isBalanced && !isProcessed && !isWaiting)
      ? colorScheme.selectedBalancedColor
      : (isProcessed)
        ? colorScheme.selectedFinishedColor
        : (isWaiting)
          ? colorScheme.selectedWaitingColor
          : colorScheme.selectedUnbalancedColor,
  };
  
  const payrollFrequencyOptions = payrollFrequencies;
  const frequencyDescription = payrollFrequencyOptions.find((a) => { return a.value === payrollDateLine.prFrequency; })?.description;
  
  const bodyFields: Record<string, BodyField[]> = useMemo(() => {
    return {
      main: [
        {
          title: 'Week End',
          field: 'weekEnd',
          detail: convToDateString(new Date(payrollDateLine?.weekEnd)) },
        {
          title: 'Check Date',
          field: 'checkDate',
          detail: convToDateString(new Date(payrollDateLine?.checkDate)) },
        {
          title: 'Next Week End',
          field: 'nextWeekEnd',
          detail: convToDateString(new Date(payrollDateLine?.nextWeekEnd)) },
        { title: 'Next Check Date',
          field: 'nextCheckDate',
          detail: convToDateString(new Date(payrollDateLine?.nextCheckDate)) },
        { title: 'Frequency',
          field: 'prFrequency',
          detail: frequencyDescription ?? '' },
      ],
      adjustments: [
        {
          title: 'Number of third party sick pays',
          field: 'thirdPartySickCount',
          detail: thirdPartySickCount,
        },
        {
          title: 'Number of prepaid checks',
          field: 'prePayCount',
          detail: prePayCount,
        },
        {
          title: 'Number of voids',
          field: 'voidCount',
          detail: voidCount,
        },
      ],
    };
  }, [payrollDateLine]);
  
  const openAdjustment = () => {
    dispatch(storeActiveYear(new Date(payrollDateLine.checkDate).getFullYear()));
    if (isContractor) {
      history.push(`/timecard-adjust?payrollHistoryId=${payrollDateLine.payrollHistoryId}`);
    } else {
      history.push(`/employee-adjust?payrollHistoryId=${payrollDateLine.payrollHistoryId}`);
    }
  };
  
  const updateControlTotalArray = (id: number, payValue: boolean) => {
    const copy = structuredClone(controlTotalState);
    const matchIndex = copy.findIndex((controlTotal) => { return controlTotal.controlTotalId === id; });
    
    if (matchIndex < 0) return;
    
    copy.splice(matchIndex, 1, { ...copy[matchIndex],
      payControlTotal: payValue });
    setControlTotalState(copy);
  };
  
  return (
    <div
      className={cardClass}
      style={selected ? selectedStyle : cardStyle}  
    >
      {selected ? (
        <span className="sr-only">Selected payroll dateline</span>
      ) : null}
      <div className="d-flex flex-column w-100">   
        {/* Preventing expand event on detail */}
        <div onClick={!usingNewCheckRegister ? undefined : () => { selectCard(payrollDateLine.payrollHistoryId, !editMode); }}>
          <div className="payroll-card-header main-card-header">
            <div className="d-flex justify-content-start">
              <div className="payroll-header-info">            
                <Icon
                  name={isWaiting ? 'clock' : (isBalanced && !isProcessed) ? 'circle-check' : isProcessed ? 'lock' : 'circle-exclamation'}
                  color={isWaiting ? '#c7c700' : (isBalanced && !isProcessed) ? '#4A9125' : isProcessed ? '#00558c' : '#D73C28'}
                  fontSize={'1.25rem'} 
                  className="mr-2"              
                />
                <strong>{mobileView ? null : isBalanced ? processMessage : 'Unbalanced'}</strong>
                <span className="sr-only">{isBalanced ? processMessage : 'Unbalanced'}</span>
              </div>            
            </div>
            {!isProcessed && usingNewCheckRegister && !hasSignedWireOnlyAgreement ? (
              <div className="card-button-container">
                {isContractor && (
                  <button
                    className="btn base-button btn-link"
                  >
                    Unlock Remote Entry
                  </button>
                )}
                <button
                  className="btn base-button btn-link"
                  onClick={openAdjustment}
                  disabled = {isWaiting}
                >Add Adjustment</button>
                <button
                  className="btn base-button btn-link"
                  onClick={showMessageModal}
                  disabled = {isWaiting}
                >Message</button>
                {!isWaiting ? <div
                  role="button"
                  onClick={() => {return setEditMode(!editMode);}}
                >
                  <Icon
                    name="pen"
                    className="fa-pen"
                  />
                </div> : null}
              </div>
            ) : null}
          </div>
          {!editMode ? (
            <CardView
              id={payrollDateLine.payrollHistoryId}
              mobileView={mobileView}
              bodyFields={bodyFields.main}
              isProcessed={isProcessed}
              hasSignedWireOnlyAgreement={hasSignedWireOnlyAgreement}
              isWaiting={isWaiting}
            />
          ) : (
            <CardEdit
              payroll={payrollDateLine}
              mobileView={mobileView}
              exit={() => {return setEditMode(false);}}
            />
          )}
          <div className="payroll-card-footer">
            <div>
              {finishedWithSubmitData && (
                <span className="ml-4">Submitted on {formattedDate} by {payrollDateLine.submitUser}</span>
              )}
            </div>
            <div
              className="d-flex"
              onClick={(e) => {
                e.stopPropagation();
                toggleExpanded(expanded ? null : payrollDateLine.payrollHistoryId);
              }}
            >
              <span className="mr-2">Details</span>
              <DisclosureIndicator expanded={expanded} />            
            </div>
          </div>
        </div>
        {expanded ? (
          <div className="payroll-card-details">
            {!hasNextDatesSet ? <small
              className="text-danger font-weight-bold"
              style={{ textDecorationLine:'underline' }}
            >
              <Icon
                name="triangle-exclamation"
              />
              {'Next Week End and Next Check Date are required to preview.'} 
                
            </small> : null}
            {payrollDateLine.adjustmentTotals?.length ? (
              <div className="payroll-card-detail-row adjustments">
                <span className="font-weight-bold">Adjustment Information</span>
                <div className="payroll-card-view sub-card-view justify-content-between">
                  <CardView
                    id={payrollDateLine.adjustmentTotals[0].adjustmentTotalId}
                    mobileView={mobileView}
                    bodyFields={bodyFields.adjustments}
                    isProcessed={isProcessed}
                    hasSignedWireOnlyAgreement={hasSignedWireOnlyAgreement}
                    isWaiting={isWaiting}
                  />
                  <div className="d-flex mr-2">
                    <button
                      className="btn base-button btn-link"
                      onClick={openAdjustment}
                      disabled = {isWaiting}
                    >Open Adjustment</button>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="payroll-card-detail-row control-totals-info">
              <div className="d-flex align-items-center">
                <span className="font-weight-bold">Control Totals</span>
              </div>
              <div className="d-flex flex-column">
                {controlTotalState.map((controlTotal) => {
                  return (
                    <UpdatePayContext.Provider
                      key={controlTotal.controlTotalId}
                      value={updateControlTotalArray}
                    >
                      <ControlTotalPreview
                        showAuxInfo={showAuxInfo}
                        hasSignedWireOnlyAgreement={hasSignedWireOnlyAgreement}
                        isProcessed={isProcessed}
                        controlTotal={controlTotal}
                        mobileView={mobileView}
                        isWaiting={isWaiting}
                        weekEnd={payrollDateLine.weekEnd}
                        checkDate={payrollDateLine.checkDate}
                      />
                    </UpdatePayContext.Provider>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null }
      </div>
    </div>
  );
};

export default PayrollCard;