import React from 'react';
import { Label } from '.';
import { currencyFormatter } from '../../../utilities/utilities';
import styles from './form-controls.module.scss';

interface PropTypes {
  id?: string;
  name: string;
  label?: string;
  labelWidth?: number;
  tallLabel?: boolean;
  groupClass?: string;
  groupStyle?: object;
  errors?: any;
  errorMsg?: string;
  required?: boolean;
  setValue: any;
  onBlur?: any;
}

/**
 * @property {string} id?
 * @property {string} name
 * @property {string | string[]} label?
 * @property {boolean} tallLabel?
 * @property {string} groupClass?
 * @property {object} groupStyle?
 * @property {any} errors?
 * @property {boolean} required?
 */
export const InputGrpCurrencyInLine: React.FC<any> = React.forwardRef(({
  id,
  name,
  label,
  labelWidth = 60,
  tallLabel = false,
  groupClass,
  groupStyle,
  required = false,
  errors,
  errorMsg,
  setValue,
  onBlur = (e: any) => {return setValue(name, currencyFormatter(e?.target.value));},
  ...extraProps
}: PropTypes, ref: any) => {

  const groupClass2 = styles['dm-form-group-inline'] + ' ' + (groupClass ?? '');
  id = id ?? name;
  const inputWidthString = '' + (100 - labelWidth) + '%';
  const labelWidthString = '' + labelWidth + '%';

  const errMsg = errors ? (errors.type === 'validate' ? errorMsg : errors.message) : '';

  return (
    <div className={groupClass2} style={groupStyle}>
      {label && (
        <div style={{ width: labelWidthString }}>
          <Label id={id} hasError={errors} text={label} required={required} />
        </div>
      )}
      <input
        ref={ref}
        style={{ textAlign: 'right', width: inputWidthString }}
        className={styles['dm-form-control'] + (errors ? ' ' + styles['dm-is-invalid'] : '')}
        id={id}
        name={name}
        onBlur={onBlur}
        {...extraProps} />
      <small className="text-danger">{errMsg} </small>
    </div>
  );
});
