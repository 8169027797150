import React, { useState } from 'react';
import styles from './emp-detail.module.scss';
import Icon from 'core/components/shared/Icon';
import { Link } from 'react-router-dom';
import { EmFieldValidation as E } from 'types';
import { getEnumKeyByNumValue } from 'utilities/utilities';
import { DisplayNames } from 'core/constants';

type Props = {
  section: string;
  fields: E.FieldError[] | undefined;
  path: string;
  clickRoute: (path: string) => void;
};

const EmSectionErrorCard = ({ section, fields, path, clickRoute }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(!!fields?.length);
  
  const fieldCount = fields?.length ? `(${fields.length} field${fields?.length === 1 ? '' : 's'})` : '';
  
  return (
    <li
      className={styles['emv-section-card']}
    >
      <div className={styles['emv-card-header']}>
        <div className={styles['emv-card-title-container']}>
          <Icon
            name="warning"
            color="#e22200"
            className={styles['emv-warning-icon']}
          />
          <h3>{section}&nbsp;{fieldCount}</h3>
          <Link
            to={path}
            onClick={() => { clickRoute(path); }}
            className="ml-2"
          >
            Open <Icon name="up-right-from-square" />
          </Link>
        </div>
        {fields?.length ? (
          <div className={styles['emv-card-title-button-container']}>
            <button
              className="btn btn-link"
              onClick={() => { setExpanded(!expanded); }}
              title="Expand/collapse errors"
            >
              <span className="sr-only">{expanded ? 'Collapse errors' : 'Expand errors'}</span>
              <Icon
                name={expanded ? 'chevron-circle-up' : 'chevron-circle-down'}
                className={expanded ? 'roster-chevron flipped' : 'roster-chevron'}
              />
            </button>
          </div>
        ) : null}
      </div>
      <div className={expanded ? styles['emv-card-expand'] : styles['emv-card-collapse']}>
        {expanded && (
          <div className={styles['expand-body']}>
            <ul>
              {fields && fields?.map((field, index) => {
                return (
                  <li
                    className={styles['field-error-li']}                 
                    key={`${field.controlId}-${index}`} // just in case we have a shared controlId (doesn't look super common)
                  >
                    <strong>{getEnumKeyByNumValue(DisplayNames, field.controlId)}</strong> is missing
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </li>
  );
};

export default EmSectionErrorCard;
