import { AxiosResponse } from "axios";
import {
  PayrollCheck,
  CheckCalculator,
  CheckCalculateRequest,
  HttpResponse,
  PrintCheck,
  PrintCheckVerify,
  RecordPrepay,
} from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { EmployeeEndPoints } from './URL';
import { CommonService } from './common.service';

const getCheckCalculator = (
  protectedEmpNo: string,
): Promise<AxiosResponse<CheckCalculator[]>> => {
  return API_EMPLOYEE_MASTER.get<CheckCalculator[]>(
    EmployeeEndPoints.CHECK_CALCULATOR(
      CommonService.getProtectedClientNo(),
      protectedEmpNo,
    ),
  );
};

const calculateCheck = (
  calculateCheck: CheckCalculateRequest,
  protectedEmpNo: string,
): Promise<AxiosResponse<HttpResponse<PayrollCheck>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<PayrollCheck>>(
    EmployeeEndPoints.CHECK_CALCULATOR(
      CommonService.getProtectedClientNo(),
      protectedEmpNo,
    ),
    calculateCheck,
  );
};

const calculateCheckReport = (
  printCheck: PrintCheck,
  protectedEmpNo: string,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.CHECK_CALCULATOR_REPORT(
      CommonService.getProtectedClientNo(),
      protectedEmpNo,
    ),
    printCheck,
  );
};

const calculateCheckRecordPrepay = (
  recordPrepay: RecordPrepay,
  protectedEmpNo: string,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.CHECK_CALCULATOR_RECORDPREPAY(
      CommonService.getProtectedClientNo(),
      protectedEmpNo,
    ),
    recordPrepay,
  );
};

const calculateCheckPrint = (
  printCheck: PrintCheck,
  protectedEmpNo: string,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.CHECK_CALCULATOR_PRINT(
      CommonService.getProtectedClientNo(),
      protectedEmpNo,
    ),
    printCheck,
  );
};

const calculateCheckPrintVerify = (
  printCheckVerify: PrintCheckVerify,
  protectedEmpNo: string,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.CHECK_CALCULATOR_PRINT_VERIFY(
      CommonService.getProtectedClientNo(),
      protectedEmpNo,
    ),
    printCheckVerify,
  );
};

export const CheckCalculatorService = {
  getCheckCalculator,
  calculateCheck,
  calculateCheckReport,
  calculateCheckRecordPrepay,
  calculateCheckPrint,
  calculateCheckPrintVerify,
};
