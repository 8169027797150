import React, { useEffect, useState, createContext } from 'react';
import PanelHeader from 'core/components/shared/PanelHeader';
import { getDownloaderFields, getUserMaps } from 'core/store/slices/downloader.slice';
import { Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import HrProfileMapsPage from './HRInfoPages/HrProfileMapsPage';
import EmpMasterMapsPage from './EmployeeMasterPages/EmpMasterMapsPage';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import EarningsMapsPage from './EarningsPages/EarningsMapsPage';
import ExportModal from './ExportModal';
import { getUserMapById, saveUserMap } from 'core/store/actions/employee-earnings-download.action';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { UserMapItem } from 'core/models/Downloader.model';
import { UNSAVED_MESSAGE } from 'core/constants';
import DownloadToLibraryModal from './DownloadToLibraryModal';
import { Client } from 'core/models';

type DownloaderContextType = {
  isDirty: boolean;
  setIsDirty: (newVal: boolean) => void;
};

export const DownloaderContext = createContext<DownloaderContextType>({
  isDirty: false,
  setIsDirty: (_newVal: boolean) => {},
});

const UserMapPage = () => {
  const history = useHistory();
  
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  
  const { downloaderType, userMapId } = useParams<any>();
  
  const [isExportShown, setIsExportShown] = useState<boolean>(false);
  const [showMultiClientModal, setShowMultiClientModal] = useState<boolean>(false);
  const [writeToSelectedClientLibraries, setWriteToSelectedClientLibraries] = useState<Client[]>([]);
  const [downloadToClientLibrary, setDownloadToClientLibrary] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [IsHrDownload, setIsHrDownload] = useState<boolean>(false);
  const [IsEmpEarnDownload, setIsEmpEarnDownload] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [eventKey, setEventKey] = useState<'EMP' | 'EMMP' | 'HRMP' | null>((downloaderType === 'ee') ? 'EMP' : (downloaderType === 'em') ? 'EMMP' : 'HRMP');
  
  const contextValue: DownloaderContextType = {
    isDirty,
    setIsDirty: (newVal: boolean) => { setIsDirty(newVal); },
  };
  
  const selectedUserMapStore = useAppSelector((state) => { return state.downloader.selectedUserMap; });
  const selectedClients = useAppSelector((state) => { return state.downloader.selectedClients; });
  const client = useAppSelector((state) => { return state.client.client; });
  const userMap = useAppSelector((state) => { return state.downloader.selectedUserMap; });
  const gridSelectedFields = useAppSelector((state) => {
    return state.employeeEarningsDownload.selectedFields;
  });
  const isSaving = useAppSelector(({ employeeEarningsDownload }) => employeeEarningsDownload.saving);

  const onExportClient = () => {
    if((selectedClients?.length ?? 0) > 1)setShowMultiClientModal(true);
    else setIsExportShown(true); 
  }

  useEffect(() => {
    dispatch(getUserMaps());
    dispatch(getDownloaderFields());
  }, []);
  
  useEffect(() => {
    if (userMapId && userMapId !== '0') {
      appDispatch(getUserMapById(+userMapId));
    }
  }, [userMapId]);

  useEffect(() => {
    switch (downloaderType) {
      case 'ee':
        setTitle('Employee Earnings Download');
        setEventKey('EMP');
        setIsEmpEarnDownload(true);
        setIsHrDownload(false);
        break;
      case 'em':
        setTitle('Employee Master Download');
        setEventKey('EMMP');
        setIsEmpEarnDownload(false);
        setIsHrDownload(false);
        break;
      case 'hr':
        setTitle('Human Resources Download');
        setEventKey('HRMP');
        setIsHrDownload(true);
        setIsEmpEarnDownload(false);
        break;
    }
  }, [downloaderType]);
  
  const onSave = () => {
    //Adds any grid selected fields to the selected fields.
    const selectedFields = [...gridSelectedFields];

    const request = {
      ...userMap,
      selectedFields,
    } as UserMapItem;
    
    //Set the two boolean values so we can send what type of map it is. This way not all maps are Employee Master maps.
    request.isEarningsDownload = IsEmpEarnDownload;
    request.isHrDownload = IsHrDownload;

    //Added it so if it is an earnings download we sent the earnings map type value. This was causing no maps to be added as an earnings map
    if (IsEmpEarnDownload) {
      if (selectedFields.find(x => {return x.fieldName === 'Summarize by Employee';}))
        request.earningsMapType = 'EmployeeEarningsSummary';
      else if (selectedFields.find(x => {return x.fieldName === 'Transactional by Employee';}))
        request.earningsMapType = 'EmployeeEarningsTransaction';
      else
        request.earningsMapType = 'EmployeeEarningsDetail';
    } else {
      request.earningsMapType = null;
    }

    appDispatch(saveUserMap(request));
    setIsDirty(false);
  };
  
  const onCancel = () => {
    history.push(`/report-downloader/${downloaderType}`);
  };

  return (
    <DownloaderContext.Provider value={contextValue}>
      <Prompt
        when={isDirty}
        message={(location, action) => {
          // don't prompt user if we're navigating to somewhere else in the field selection screens.
          return location.pathname.includes(`user-map/${downloaderType}`) ? true : UNSAVED_MESSAGE;
        }}
      />
      <Row className="w-100">
        <Col>
          <div className="dm-panel dm-panel-border">
            <PanelHeader title={title}>
            </PanelHeader>
            <div className="mt-3">
              <Tab.Container
                defaultActiveKey={eventKey ?? ''}
                activeKey={eventKey ?? ''}
                onSelect={(key) => { setEventKey(key as 'EMP' | 'EMMP' | 'HRMP' | null); }}
              >
                <Nav variant="tabs">
                  {IsEmpEarnDownload && <Nav.Item>
                    <Nav.Link eventKey="EMP">
                      Earnings 
                    </Nav.Link>
                  </Nav.Item>}
                  {(IsEmpEarnDownload || !IsHrDownload) && <Nav.Item>
                    <Nav.Link
                      eventKey="EMMP"
                      onClick={() => {
                        history.push(`/user-map/${downloaderType}/${userMapId}/snapshot`);
                      }}
                    >
                      Employee Master
                    </Nav.Link>
                  </Nav.Item>}
                  {(IsEmpEarnDownload || IsHrDownload) && <Nav.Item>
                    <Nav.Link
                      eventKey="HRMP"
                      onClick={() => {
                        history.push(`/user-map/${downloaderType}/${userMapId}/general-info`);
                      }}
                    >
                      Employee HR Info
                    </Nav.Link>
                  </Nav.Item>}
                  <div className="flex-grow-1 text-right my-1">
                    <button
                      className="btn-sm orange-outline-button mx-1"
                      onClick={onCancel}
                    >
                      Return to Maps
                    </button>
                    <button
                      className="btn-sm orange-button mx-1"
                      onClick={onSave}
                      disabled={isSaving}
                    >
                      {isSaving ? (
                        <div className="d-flex">
                          <span className="mr-2">Saving</span>
                          <Spinner
                            animation="border"
                            size="sm"
                          />
                        </div>
                      ) : 'Save Map'}
                    </button>
                    <button
                      type="button"
                      className="btn-sm orange-button mx-1"
                      onClick={onExportClient}
                    >
                      Export
                    </button>
                  </div>
                </Nav>
                <div className="mt-3">
                  <Tab.Content>
                    <Tab.Pane eventKey="EMP">
                      <EarningsMapsPage key={'EMP'} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="EMMP">
                      <EmpMasterMapsPage key={'EMMP'} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="HRMP">
                      <HrProfileMapsPage key={'HRMP'} />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
            <ExportModal
              show={isExportShown}
              onHide={() => { setIsExportShown(false);}}
              isEmpEarningDownload = {IsEmpEarnDownload}
              isEarningsDownloadSummary= {userMap?.earningsMapType === 'EmployeeEarningsSummary'}
              selectedUserMap = {selectedUserMapStore}
              selectedClients={selectedClients}
              writeToSelectedClientLibraries={writeToSelectedClientLibraries}
              downloadToClientLibrary={downloadToClientLibrary}
            />
            <DownloadToLibraryModal
            show={showMultiClientModal}
            onHide={() => {setShowMultiClientModal(false);}}
            clients={selectedClients || []}
            currentClient={client}
            setWriteToSelectedClientLibraries={setWriteToSelectedClientLibraries}
            setDownloadToClientLibrary={setDownloadToClientLibrary}
            setIsExportShown={setIsExportShown}
          />
          </div>
        </Col>
      </Row>
    </DownloaderContext.Provider>
  );
};

export default UserMapPage;