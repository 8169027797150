import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { CellValueChangedEvent, ColDef, GridApi, GridOptions, ICellRendererParams } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { useHistory } from 'react-router-dom';
import { getApplicants, updateApplicantStatus } from 'core/store/actions/applicant-tracking.action';
import { setApplicant } from 'core/store/slices/applicant-tracking-v2.slice';
import { Applicant, ApplicantStatus, StatusRequest, applicantStatusOpts } from 'core/models';
import ApplicantDrop from './ApplicantDrop';
import { DragDropContext } from 'react-beautiful-dnd';
import Icon from 'core/components/shared/Icon';
import ApplicantSearch from './ApplicantSearch';
import { agDateRenderer } from 'utilities/ag-grid-renderers';
import { SelectGrpInLine } from 'core/components/form-controls';
import { agSelectEditor } from 'utilities/ag-grid-editors';
import { cloneDeep } from 'lodash';
import FiveStar from 'core/components/shared/FiveStar';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { getJobs, setShowApplicantsWaiting } from 'core/store/slices/applicantTracking.slice';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';

const toolTipStyles: CSSProperties = { fontSize: '0.75rem' };

// TODO: Move these
const RenderToolTip = (props: OverlayInjectedProps) => (
  <Tooltip
    id="info-tooltip"
    {...props}
  >
    <div style={toolTipStyles}>
      Applicants highlighted in green have accepted their offer,
      and those highlighted in red have rejected it.
    </div>
  </Tooltip>
);

const RenderOverlay = () => (
  <OverlayTrigger
    placement="top"
    overlay={RenderToolTip}
  >
    <div className="d-flex align-items-center">
      <Icon
        name="circle-info"
        fontSize={'0.75rem'}
      />
    </div>
  </OverlayTrigger>
);

const CustomHeader: React.FC<{ field: string }> = ({ field }) => (
  <div className="d-flex">
    <span className="mr-2">{field}</span>
    <RenderOverlay />
  </div>   
);

const ManageApplicants = () => {
  const history = useHistory();
  
  const dispatch = useAppDispatch();
  
  const [loading, applicantsStore, jobs, showApplicantsWaiting] = useAppSelector((state) => {
    return [
      state?.applicantTrackingV2.loading,
      state?.applicantTrackingV2.applicants,
      state?.applicantTracking?.jobs?.availableJobs,
      state?.applicantTracking?.showApplicantsWaiting,
    ];
  });
  
  const [applicantsOriginal, setApplicantsOriginal] = useState<Applicant[]>([]);
  const [applicants, setApplicants] = useState<Applicant[]>([]);
  const [showGrid, setShowGrid] = useState<boolean>(false);
  const [jobTitleOpts, setJobTitleOpts] = useState<{ id: number, description: string }[]>([]);
  const [appliedYearOpts, setAppliedYearOpts] = useState<{ id: number, description: string }[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updatedApplicant, setUpdatedApplicant] = useState<Applicant>();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [selectedJobOption, setSelectedJobOption] = useState<string>(showApplicantsWaiting ? '-99' : '');
  const [selectedYearOption, setSelectedYearOption] = useState<string>('');


  useEffect(() => {
    dispatch(getApplicants());
    dispatch(getJobs());
  }, []);
  
  useEffect(() => {
    return () => { dispatch(setShowApplicantsWaiting(false)); };
  }, []);

  useEffect(() => {
    if (loading) return;
    const filteredApplicants = cloneDeep(
      applicantsStore.filter(a => {
        return a.status !== ApplicantStatus.Employee;
      }));
    
    if (!filteredApplicants) return;
    if (showApplicantsWaiting) {
      const applicantsWaitingForAction = filteredApplicants?.filter(({ status, offerStatus }) =>
        status === 2 && ['Accepted'].includes(offerStatus));
      setApplicants(applicantsWaitingForAction);
    } else {
      setApplicants(filteredApplicants);
    }
    setApplicantsOriginal(filteredApplicants);

    const uniqueYears = filteredApplicants.map((j) => new Date(j.dateApplied).getFullYear()).filter((value, index, current_value) => current_value.indexOf(value) === index);
    setAppliedYearOpts(
      [      
        ...uniqueYears.map((year) => {
          return {
            id: year,
            description: year.toString(),
          };
        }),
      ],
    );
  }, [applicantsStore, showApplicantsWaiting]);
  
  useEffect(() => {
    if (!jobs) return;
    setJobTitleOpts(
      [      
        {
          id: -99,
          description: 'Applicants who are awaiting company action',
        },
        ...jobs.map(j => {
          return {
            id: j.jobId,
            description: j.jobTitle,
          };
        }),
      ],
    );
  }, [jobs]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onStatusChange = (params: CellValueChangedEvent) => {
    if (params.newValue !== params.oldValue) {
      setUpdatedApplicant(params.data);
      setShowConfirmationModal(true);
    } 
  };

  const columns = useMemo<ColDef[]>(
    () => {
      return [
        {
          headerName: 'Name',
          width: 200,
          cellRenderer: (params: ICellRendererParams) => {
            return (
              <span>{params.data.firstName} {params.data.lastName}</span>
            );
          },
          onCellDoubleClicked: (selected: {
            data: Applicant
          }) => {
            dispatch(setApplicant(selected.data));
            history.push(`/manage-applicants/${selected?.data?.applicantId}`);
          },
        },
        {
          field: 'status',
          headerName: 'Status',
          headerComponent: () => (
            <>
              <span className="mr-2">Status</span>
              <RenderOverlay />
            </>
          ),
          cellEditorParams: {
            options: applicantStatusOpts,
          },
          cellEditor: agSelectEditor,
          cellRendererParams: {
            options: applicantStatusOpts,
            valueField: 'id',
            labelField: 'description',
          },
          cellRenderer: (params: any) => {
            const option = params?.options?.find((o: any) => {
              return o.id === params.data.status;
            });
            return (
              <div className="d-flex flex-row">
                {option && <Icon
                  name={option?.iconName}
                  style={{
                    padding: 5,
                    color: option?.color,
                    marginRight: 20,
                  }}
                />}
                {ApplicantStatus[params.data.status].replace('_', ' ')}
              </div>
            );
          },
          width: 200,
          editable: true,
        },
        {
          field: 'jobTitle',
          headerName: 'Position',
          width: 130,
        },
        {
          field: 'dateApplied',
          headerName: 'Applied',
          cellRenderer: agDateRenderer,
          width: 130,
          cellStyle: { textAlign: 'right' },
        },
        {
          field: 'dateAvailable',
          headerName: 'Available',
          cellRenderer: agDateRenderer,
          width: 130,
          cellStyle: { textAlign: 'right' },
        },
        {
          field: 'overallRating',
          headerName: 'Overall Rating',
          cellRenderer: (params: any) => {
            return FiveStar(params.data.overallRating);
          },
          width: 130,
        },
        {
          field: 'scorecardRating ',
          headerName: 'Scorecard Rating ',
          cellRenderer: (params: any) => {
            return params.data.scorecardRating;
          },
          cellStyle: { textAlign: 'right' },
          width: 130,
        },
      ];
    },
    [],
  );

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    stopEditingWhenCellsLoseFocus:true,
    undoRedoCellEditing:true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    getRowClass: (params) => {
      if (params.data.status !== 2) return;
      if (params.data.offerStatus === 'Accepted') {
        return 'offer-accepted';
      } else if (params.data.offerStatus === 'Denied') {
        return 'offer-rejected';
      }
    },
  };


  const onDragEnd = (result: any) => {

    const { destination, draggableId } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const request: StatusRequest = {
      applicantId: draggableId,
      status: destination.droppableId,
    };
    dispatch(updateApplicantStatus(request));
    dispatch(setShowApplicantsWaiting(false));
    setSelectedJobOption('');
  };

  const onJobSearchResults = (searchText: string | null, yearSelected: string | null) => {
    let applicant = structuredClone(applicantsOriginal);

    switch (searchText) {
      case null:
      case '':
        setSelectedJobOption('');
        break;
      case '-99':
        applicant = applicant.filter(({ status, offerStatus }) => status === 2 && ['Accepted'].includes(offerStatus));
        setSelectedJobOption('-99');
        break;
      default:
        applicant = applicantsOriginal.filter(({ jobId }) => jobId === +searchText);
        setSelectedJobOption(searchText);
    }

    switch (yearSelected){
      case null:
      case '':
        setSelectedYearOption('');
        break;
      default:
          applicant = applicant.filter(({ dateApplied }) => new Date(dateApplied).getFullYear() === +yearSelected);
          setSelectedYearOption(yearSelected);
    }

    setApplicants(applicant);
  };

  const onSearchResults = (searchText: string | null) => {
    if (searchText) {
      setApplicants(applicantsOriginal.filter(a => {
        return a.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
          a.lastName?.toLowerCase().includes(searchText.toLowerCase());
      }));
    } else {
      setApplicants(applicantsOriginal);
    }
  };

  const updateConfirm = (confirmed: boolean) => {
    if (confirmed && updatedApplicant) {
      const request: StatusRequest = {
        applicantId: updatedApplicant.applicantId,
        status: '' + updatedApplicant.status,
      };
      dispatch(updateApplicantStatus(request));
      dispatch(setShowApplicantsWaiting(false));
      setSelectedJobOption('');
    } else {    
      gridApi?.undoCellEditing();       
      setTimeout(()=>{
        setShowConfirmationModal(false);
      }, 200);     
    }
  };

  return (
    <div className="dm-panel dm-panel-border">
      <div className="d-flex flex-row">
        <div className="dm-grid-title  flex-grow-1 justify-content-start">Manage Applicants</div>
        <div
          className="justify-content-center"
          style={{ width: '30%' }}
        >
          <SelectGrpInLine
            label="Position"
            labelWidth="15"
            options={jobTitleOpts}
            addEmptyText="All Available Jobs"
            value={selectedJobOption}
            onChange={(e: any) => {
              onJobSearchResults(e.target.value, selectedYearOption);
            }}
          />
        </div>
        <div
          className="justify-content-center pl-5 pr-5"
          style={{ width: '25%' }}
        >
          <SelectGrpInLine
            label="Applied Year  "
            labelWidth="25"
            options={appliedYearOpts}
            addEmptyText="All Years"
            value={selectedYearOption}
            onChange={(e: any) => {
              onJobSearchResults(selectedJobOption, e.target.value);
            }}
          />
        </div>
        <div className="flex-grow-1 justify-content-end text-right">
          <span
            style={{
              fontSize: '14px',
              padding: 10,
              fontWeight: 600,
              color: '#00558c',
              textDecorationLine: 'underline',
              backgroundColor: '#eff4fb',
            }}
          >Total Applicants {applicantsStore.filter(a => {
            return a.status !== ApplicantStatus.Employee;
          }).length}</span>
        </div>
      </div>

      <hr className="dm-panel-hr" />
      <div className="d-flex flex-row flex-grow-1 justify-content-end">
        <button
          type="button"
          className="btn btn-link"
          onClick={() => { return setShowGrid(true); }}
        >        
          <Icon
            name={'list'}
            style={{
              padding: 0,
              marginRight: 10,
            }}
          /> Grid View</button>
        <button
          type="button"
          className="btn btn-link"
          onClick={() => { return setShowGrid(false); }}
        >
          <Icon
            name={'grid'}
            style={{
              padding: 0,
              marginRight: 10,
            }}
          /> List View</button>
        <ApplicantSearch
          setSearchText={(results: string | null) => {
            return onSearchResults(results);
          }}
        />
      </div>
      {!loading && !showGrid && (

        <DragDropContext onDragEnd={onDragEnd}>
          <div className="d-flex flex-column flex-grow-1">
            <div className="d-flex flex-row">
              <ApplicantDrop
                columnId={ApplicantStatus.New_Applicant}
                applicants={applicants.filter(a => { return a.status === ApplicantStatus.New_Applicant; })}
              />
              <ApplicantDrop
                columnId={ApplicantStatus.Interview}
                applicants={applicants.filter(a => { return a.status === ApplicantStatus.Interview; })}
              />
              <ApplicantDrop
                columnId={ApplicantStatus.Offer}
                applicants={applicants.filter(a => { return a.status === ApplicantStatus.Offer; })}
                CustomHeader={() => <CustomHeader field="Offer" />}        
              />
              <ApplicantDrop
                columnId={ApplicantStatus.Onboard}
                applicants={applicants.filter(a => { return a.status === ApplicantStatus.Onboard; })}
              />
              <ApplicantDrop
                columnId={ApplicantStatus.Reject}
                applicants={applicants.filter(a => { return a.status === ApplicantStatus.Reject; })}
              />
            </div>
          </div>
        </DragDropContext>

      )}
      {!loading && showGrid && (
        <div className="table-wrapper-wrapper ag-theme-balham">
          <AgGridReact
            gridOptions={gridOptions}
            rowData={applicants}
            columnDefs={columns}
            onGridReady={onGridReady}
            onCellValueChanged={onStatusChange}
          />
        </div>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          title="Update Applicant"
          message={'Are you sure you would like to update this applicants status?'}
          show={showConfirmationModal}
          onConfirmed={updateConfirm}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
    </div>
  );
};

export default ManageApplicants;