import { GenericFieldInputSettings } from 'core/components/form-controls/types';
import { PayrollTimeCardSubInfo } from 'core/models/Contractor.model';

// Form config
export const fieldSettings: GenericFieldInputSettings<Partial<PayrollTimeCardSubInfo>> = {
  subNo: {
    name: 'subNo',
    label: 'Sub Number',
  },
  description: {
    name: 'description',
    label: 'Description',
  },
  excludeFromWH347: {
    name: 'excludeFromWH347',
  },
};
