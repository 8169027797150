import React, { useEffect, useMemo, useState } from 'react';
import { GridOptions, ICellRendererParams, ValueSetterParams } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from '@ag-grid-community/react';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { postMescEarnCorrection, deleteMescEarnCorrection } from 'core/store/actions';
import { EarningAllocation } from 'core/models';
import { buildGridOptions, generateColumnGroupDefs } from './EarnGridConfig';

type Props = {
  earnAllocations: EarningAllocation[];
  protectedEmpNo: string;
};

const EarnAllocationsGrid = ({ earnAllocations, protectedEmpNo }: Props) => {  
  const locationOpts = useAppSelector((state) => {return state.dropdown.location;});
  const deptOpts = useAppSelector((state) => {return state.dropdown.department;});
  const subdeptOpts = useAppSelector((state) => {return state.dropdown.subDepartment;});
  const subdept2Opts = useAppSelector((state) => {return state.dropdown.subDepartment2;});
  
  const [rowData, setRowData] = useState<EarningAllocation[]>(earnAllocations);
  
  useEffect(() => {
    setRowData(earnAllocations);
  }, [earnAllocations]);
  
  const dispatch = useAppDispatch();
  
  const updateValue = (params: ValueSetterParams, field: string): boolean => {
    setRowData((prevState) => {
      const updatedData: EarningAllocation[] = prevState.map((row: EarningAllocation) => {
        if (row.earnId === params.data.earnId) {
          return {
            ...row,
            [field]: +params.newValue,
          };
        } else {
          return row;
        }
      });
      
      return updatedData;
    });
            
    return true;
  };
  
  const fixRecord = (params: ICellRendererParams) => { // TODO: move this stuff out of de way
    const { data }: { data: EarningAllocation } = params;
    dispatch(postMescEarnCorrection({
      mescId: data.mescId,
      empNo: protectedEmpNo,
      allocations: {
        earnId: data.earnId,
        correctionLoc: data?.correctionLoc ?? data.transmittalLoc,
        correctionDept: data?.correctionDept ?? data.transmittalDept,
        correctionSub: data?.correctionSub ?? data.transmittalSub,
        correctionSub2: data?.correctionSub2 ?? data.transmittalSub2,
      },
    }));
  };
  
  const removeRecord = (mescId: number, earnId: number) => {
    if (!confirm('Remove fix?')) return;
    
    dispatch(deleteMescEarnCorrection({
      empNo: protectedEmpNo,
      mescId: mescId,
      earnId: earnId,
    }));
  };
  
  const columnGroupDefs = useMemo(() => {
    return generateColumnGroupDefs(locationOpts, deptOpts, subdeptOpts, subdept2Opts, updateValue, fixRecord, removeRecord);
  }, []);
  const gridOptions: GridOptions = useMemo(() => { return buildGridOptions(); }, []);
  
  return (
    <div className={`table-wrapper-wrapper ag-theme-balham ${!rowData?.length ? 'empty-allocation-row-group' : ''}`}>
      <AgGridReact
        detailRowAutoHeight
        columnDefs={columnGroupDefs}
        gridOptions={gridOptions}
        rowData={rowData}
      />
    </div>
  );
};

export default EarnAllocationsGrid;