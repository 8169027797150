import { AxiosResponse } from 'axios';
import { ClientUser } from '../models/User.model';
import { API_CLIENT } from './API';
import { ClientMasterEndPoints } from './URL';
import { UserAccessHR360, UserDefaultClient } from 'core/models/UserAccess';
import { HttpResponse } from 'core/models';

const getUsers = (): Promise<AxiosResponse<ClientUser[]>> => {
  return API_CLIENT.get<ClientUser[]>(ClientMasterEndPoints.USERS());
};

const getHR360Users = (): Promise<AxiosResponse<UserAccessHR360[]>> => {
  return API_CLIENT.get<UserAccessHR360[]>(ClientMasterEndPoints.HR360_USERS());
};

const putHR360User = (user:UserAccessHR360): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_CLIENT.put<HttpResponse<string>>(
    ClientMasterEndPoints.HR360_USER_ID(user.secUserId), user,
  );
};

const putUserDefaultClient = (defaultClient: UserDefaultClient) => {
  return API_CLIENT.put<HttpResponse<UserDefaultClient>>(
    ClientMasterEndPoints.PUT_DEFAULT_CLIENT(),
    defaultClient,
  );
};

export const cmUsersService = {
  getUsers,
  getHR360Users,
  putHR360User,
  putUserDefaultClient,
};
