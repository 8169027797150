import { AgGridReact } from '@ag-grid-community/react';
import { GridApi, ColDef, GridOptions, RowSelectedEvent, SelectionChangedEvent } from '@ag-grid-enterprise/all-modules';
import Modal from 'core/components/modals/Modal';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CheckboxGrpInLine, InputGrp, InputGrpDecimal } from 'core/components/form-controls';
import { Dropdown } from 'core/models';
import { PensionSetting } from 'core/models/PayrollReports.model';
import { createPensionSettings, deletePensionSettings, handleError, updatePensionSettings } from 'core/store/actions';
import { useAppSelector } from 'utilities/hooks';

const columns: ColDef[] = [
  {
    field: 'select',
    headerName: '',
    editable: false,
    checkboxSelection: true,
    width: 40,
  },
  {
    field: 'id',
    headerName: 'Code',
    width: 60,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 180,
  },
];

const gridOptions: GridOptions = {
  rowSelection: 'multiple',
  rowMultiSelectWithClick: true,
  columnDefs: columns,
  defaultColDef: {
    suppressMenu: true,
    resizable: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
};

type PropTypes = {
  setting?: PensionSetting;
  show: boolean;
  onHide: () => void;
};

const calculate = (percent: number) => {
  if (percent < 1) return percent * 100;
  return percent;
};

const PensionSettingsModal: React.FC<PropTypes> = ({ setting, show, onHide }) => {
  const dispatch = useDispatch();
  
  const earningCodes = useAppSelector((state) => { return state.dropdown.earningsCode; });
  const client = useAppSelector((state) => { return state.client.client; });
  const user = useAppSelector((state) => { return state.auth.userAccess; });
  
  const correctedDp = calculate(setting?.depositPercent ?? 0);
  
  const [selectedSetting, setSelectedSetting] = useState<PensionSetting>();
  const [selectedCodes, setSelectedCodes] = useState<Dropdown[]>();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [depositPercentState, setDepositPercentState] = useState((correctedDp).toFixed(6));

  const { register, handleSubmit, errors, setValue, watch } = useForm<PensionSetting>({
    defaultValues: setting,
  });
  
  const depositPercent = watch('depositPercent');

  useEffect(() => {
    setting && setSelectedSetting(setting);
  }, [setting]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (selectedSetting && gridApi) {
      gridApi?.forEachNode((node) => {
        if (selectedSetting.earnCodeList?.split(',').find(id => { return id === node.data.id; })) {
          node.setSelected(true);
        }
      });
    }
  }, [selectedSetting, gridApi]);

  const onSubmit = (data: PensionSetting) => {
    if (!client?.clientID) return dispatch(handleError('No client ID found'));
    
    const depositPercentConvert = Number(data.depositPercent);
    
    const newSetting: PensionSetting = {
      settingID: data.settingID || 0,
      settingName: data.settingName,
      clientID: client.clientID,
      depositPercent: depositPercentConvert > 1 ? depositPercentConvert / 100 : depositPercentConvert,
      earnCodeList: selectedCodes?.map(c => { return c.id; })?.join(',') || '',
      modifyDate: DateTime.local().toISODate(),
      modifyUser: user?.dmUserName || '',
    };
    
    if (newSetting?.settingID) {
      dispatch(updatePensionSettings(newSetting));
    } else {
      dispatch(createPensionSettings(newSetting));
    }
  };

  const onDelete = () => {
    if (!selectedSetting) return dispatch(handleError('No setting selected'));
    dispatch(deletePensionSettings(selectedSetting));
  };

  const onSelectionChanged = (e: SelectionChangedEvent) => {
    const selectedRows = e.api.getSelectedRows();
    if (!selectedRows) return;
    
    setSelectedCodes(selectedRows);
  };

  const onClearSelections = () => {
    gridApi?.forEachNode((node) => {
      node.setSelected(false);
    });
  };

  const changeAllEarnings = () => {
    gridApi?.forEachNode((node) => {
      node.setSelected(true);
    });
  };

  const onRowClicked = (e: RowSelectedEvent) => {
    e.node.setSelected(e.node.isSelected() === true ? true : false);
  };

  const formatDepositPercent = (num: string | number) => {
    if (typeof num === 'string') num = Number(num);
    if (isNaN(num)) {
      console.error('percent is NaN');
      return num;
    }
    // fixes floating point precision (if you're curious, try 0.1 + 0.2 and be disgusted)
    if (num < 1) return Number((num * (10 ** 2)).toFixed(6)); 
    return Number(num.toFixed(6));
  };
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Setting Options"
    >
      {(closeModal) => {
        return (
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="hidden"
              name={'settingID'}
              ref={register()}
              defaultValue={selectedSetting?.settingID}
            />
            <div className="d-flex mt-2">
              <div className="d-flex flex-row flex-grow-1 ">
                <div className="d-flex flex-column mr-4">
                  <InputGrp
                    label="Setting Name"
                    name="settingName"
                    ref={register()}
                  />
                  <div className="dm-grid-action-title mb-2">Report Options</div>
                  <div className="d-flex flex-row flex-grow-1">
                    <span
                      className="form-label"
                      style={{ margin: '5px 5px 0 0' }}
                    >
                      EMPLOYER DEPOSIT
                    </span>
                    {depositPercentState !== undefined && (
                      <InputGrpDecimal
                        label=""
                        groupClass="gc12 mw50"
                        name="depositPercentState"
                        fixed={6}
                        errors={errors?.depositPercent}
                        value={depositPercentState}
                        onChange={(e: any) => {
                          setDepositPercentState(e.target.value);
                        }}
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          const num = formatDepositPercent(Number(e.target.value)) ;
                          setDepositPercentState(String(num.toFixed(6)));
                          setValue('depositPercent', num);
                        }}
                      />
                    )}
                    <input
                      type="hidden"
                      name="depositPercent"
                      ref={register({ valueAsNumber: true })}
                      value={depositPercent}
                    />
                    <span>%</span>
                  </div>
                </div>
                <div className="d-flex flex-column flex-grow-1">
                  <div className="dm-grid-action-title mb-2">Earnings</div>
                  <div>
                    <div className="d-flex flex-row">
                      <CheckboxGrpInLine
                        name="allEarnings"
                        label="All Earnings"
                        onChange={changeAllEarnings}
                        ref={register()}
                      />
                      <button
                        type="button"
                        className="btn btn-link mb-2"
                        onClick={onClearSelections}
                      >Clear Selections</button>
                    </div>
                  </div>
                  <div
                    className="ag-theme-balham mt-2"
                    style={{ height: '220px' }}
                  >
                    <AgGridReact
                      gridOptions={gridOptions}
                      rowData={earningCodes}
                      onGridReady={onGridReady}
                      onRowClicked={onRowClicked}
                      onSelectionChanged={onSelectionChanged}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-grow-1 text-right mt-4">
              <button
                type="button"
                className="btn btn-primary orange-outline-button ml-auto mr-2"
                onClick={() => {
                  onDelete();
                  closeModal();
                }}
                disabled={!selectedSetting?.settingID}
              >
                Delete
              </button>
              <button
                type="submit"
                className="orange-button mr-2"
              >
                Save
              </button>
            </div>
          </form>
        );
      }}
    </Modal>
  );
};

export default PensionSettingsModal;