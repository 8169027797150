import React, { CSSProperties, useState, useEffect, useRef } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Dropdown, PayrollAdjustmentTransaction, PayrollAdjustmentType, PayrollAdjustmentVoidTransaction } from 'core/models';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ControlDatePickerGrp, InputGrp, RadioGrp, SelectGrp } from 'core/components/form-controls';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { getOpenPayrolls } from 'core/store/selectors';
import { useSelector } from 'react-redux';
import { convToDateString,  formatWithCommas } from 'utilities/utilities';
import { radioOptions } from 'dropdowns/void';
import AppChangeClient from '../../../features/app/AppChangeClient';
import { DateTime } from 'luxon';
import Icon from 'core/components/shared/Icon';
import {
  ColDef,
  GridReadyEvent,
  GridSizeChangedEvent,
  ICellRendererParams,
  ModuleRegistry,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import {
  clearEmployeeVoidSearch,
  loadEmployeeVoidSearch,
  loadPayroll,
  loadPayrollAdjustments,
  postAdjustmentVoid,
  showVoidCheckWindow,
} from 'core/store/actions';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import UpdateCheckNoModal from './UpdateCheckNo.modal';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const inputStyles: CSSProperties = {
  maxWidth: '250px',
};
const voidDateStyles: CSSProperties = {
  maxWidth: '110px',
  margin: '0',
};

type PropTypes = {
  protectedEmpNo: string;
  empNo: number;
  show: boolean;
  payrollHistoryId: number;
  voidType: string;
  onHide: () => void;
  reset: () => void;
};

const toolTipStyles: CSSProperties = { fontSize: '0.75rem' };

const renderToolTip = (props: OverlayInjectedProps, message: string) => {
  return (
    <Tooltip
      id="info-tooltip"
      {...props}
    >
      <div style={toolTipStyles}>
        {message}
      </div>
    </Tooltip>
  );
};

const VoidSearchModal: React.FC<PropTypes> = ({
  protectedEmpNo,
  empNo,
  show,
  payrollHistoryId,
  voidType,
  onHide,
  reset,
}) => {
  const dispatch = useAppDispatch();
  const { employeeVoidSearch: employeeVoidSearchResults, showVoidModal: showModal } = useAppSelector(({ payroll }) => payroll);
  const clientNo = useAppSelector((state) => { return state.client.client!.clientNo; });
  const openPayrolls = useSelector(getOpenPayrolls);
  const userClients = useAppSelector(({ auth }) => auth.userAccess);
  const isSuperUser = !!userClients?.superUser;
  const { activeYear } = useAppSelector(({ payroll }) => payroll);
  
  const currentPayroll = openPayrolls?.find((dateline) => dateline?.payrollHistoryId === payrollHistoryId);
  const calculatedBeginDate = new Date('01/01/' + activeYear);
  const calculatedEndDate = new Date('12/31/' + activeYear);

  const [rowData, setRowData] = useState<PayrollAdjustmentTransaction[]>([]);
  const [weekEndDropDown, setWeekEndDropDown] = useState<Dropdown[]>([]);
  const [prepaidRequired, setPrepaidRequired] = useState(false);
  const [showClientModal, setShowClientModal] = useState(false);
  const [checkNoToEdit, setCheckNoToEdit] = useState<{ checkNo: number, mescId: number }>({ checkNo: 0, mescId: 0 });
  const [radioButtonValue, setRadioButtonValue] = useState<PayrollAdjustmentType>(
    voidType === 'VoidCash'
      ? PayrollAdjustmentType.VoidCash
      : PayrollAdjustmentType.VoidCorrection,
  );

  const gridRef = useRef<AgGridReact>(null);
  
  const clientSearchInput = document.getElementById('client-input');

  const {
    errors,
    control,
    getValues,
    register,
    trigger,
    clearErrors,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      beginDate: calculatedBeginDate,
      endDate: calculatedEndDate,
      weekEnd: '',
      voidClient: clientNo,
      voidEmpNo: empNo,
    },
  });

  const voidClient = watch('voidClient');
  
  const updateCellValue = (
    params: ICellRendererParams,
    newVal: boolean,
    key1: 'void' | 'addPrePaid',
    key2: 'void' | 'addPrePaid',
    updateComplement: boolean,
  ) => {
    checkForPrepaid(params);
          
    params.data[key1] = newVal;
    if (updateComplement) params.data[key2] = newVal;
    params.api.refreshCells({ columns: ['void', 'addPrePaid'] });
    params.api.sizeColumnsToFit();
          
    const matchOnId = (row: PayrollAdjustmentTransaction) => row.mescId === params.data.mescId;  
            
    setRowData((prevState) => {
      const tempState = structuredClone(prevState);
      const matchRow = prevState?.find(matchOnId);
      if (!matchRow) {
        console.error('No row');
        return prevState;
      }
      const matchRowIndex = prevState?.findIndex(matchOnId);
      tempState.splice(matchRowIndex, 1, params.data);
      
      return tempState;
    });
  };
  
  const checkForPrepaid = (params: ICellRendererParams) => {
    if (+voidClient === +clientNo) return;
    let atLeastOne = false;
    params.api.forEachNode((rowNode) => {
      if ((rowNode.data as PayrollAdjustmentTransaction).addPrePaid) {
        atLeastOne = true;
        return; // can't really return from a forEach but we need it to iterate over the grid nodes
      }
    });
    setPrepaidRequired(atLeastOne);
  };

  const columnDefs: ColDef[] = [
    {
      field: 'checkTypeDescription',
      headerName: 'Check Type',
      maxWidth: 100,
    },
    {
      field: 'empNo',
      maxWidth: 100,
    },
    {
      headerName: 'Emp Name',
      valueFormatter: (params: ValueFormatterParams) => {
        return `${params.data.firstName} ${params.data.lastName}`;
      },
    },
    {
      field: 'weekEnd',
      maxWidth: 125,
      valueFormatter: (params: ValueFormatterParams) => {
        return new Date(params.data.weekEnd).toLocaleDateString();
      },
    },
    {
      field: 'checkDate',
      maxWidth: 125,
      valueFormatter: (params: ValueFormatterParams) => {
        return new Date(params.data.checkDate).toLocaleDateString();
      },
    },
    {
      field: 'quarter',
      headerName: 'QTR',
      maxWidth: 100,
    },
    { field: 'checkNo',
      width: 100,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <div className="col-12 text-right">
            {params.value}
            {isSuperUser ? <OverlayTrigger
              placement="top"
              overlay={(props) => renderToolTip(props, 'Update Check No')}
            >   
              <button
                type="button"
                className="btn btn-link dm-grid-action-title mr-4"
                onClick={() => {setCheckNoToEdit({ checkNo: params.data?.checkNo || 0, mescId: params.data?.mescId || 0 });}}
              >
                <Icon
                  name="pencil"
                  className="fa-minus-circle"
                />
              </button>             
            </OverlayTrigger> : null}
          </div>
        );
      },
    },
    {
      field: 'companyGross',
      cellStyle: { textAlign: 'right' },
      valueFormatter: (params: ValueFormatterParams) => {
        return formatWithCommas(params.data?.companyGross ?? 0);
      },
    },
    {
      field: 'adjustmentId',
      hide: true,
    },
    {
      field: 'void',
      headerName: 'Void',
      maxWidth: 75,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <div className="checkbox-renderer-wrapper">
            <input
              type="checkbox"
              checked={params.data.void}
              onChange={(e) => { updateCellValue(params, e.target.checked, 'void', 'addPrePaid', e.target.checked === false); }}
              disabled={params.data.adjustmentId !== null}
            />
          </div>
        );
      },
    },
  ];
  
  if (isSuperUser) {
    columnDefs.push(
      {
        field: 'addPrePaid',
        headerName: 'Voided Prepaid',
        maxWidth: 100,
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <div className="checkbox-renderer-wrapper">
              <input
                type="checkbox"
                checked={params.data.addPrePaid}
                onChange={(e) => { updateCellValue(params, e.target.checked, 'addPrePaid', 'void', e.target.checked === true); }}
                disabled={params.data.adjustmentId !== null}
              />
            </div>
          );
        },
      });
  }

  const filterVoid = () => {
    dispatch(
      loadEmployeeVoidSearch({
        protectedEmpNo,
        beginDate: getValues('beginDate').toISOString(),
        endDate: getValues('endDate').toISOString(),
      }),
    );
  };
  
  useEffect(() => {
    if (!rowData?.length) return;
    setPrepaidRequired((+voidClient !== +clientNo) && !!rowData.find((row) => row.addPrePaid));
  }, [rowData, voidClient]);

  useEffect(() => {
    if (!voidClient) return;
    dispatch(
      loadPayroll({
        beginDate: convToDateString(calculatedBeginDate),
        endDate: convToDateString(calculatedEndDate),
        byCheckDate: false,
        protectedClientNo: userClients?.availableClients.find(
          (c) => { return c.clientNo === voidClient; },
        )?.protectedClientNo,
      }),
    );
  }, [voidClient]);

  useEffect(() => {
    dispatch(showVoidCheckWindow(true));
    filterVoid();
  }, []);
  
  useEffect(() => {
    if (showModal === false && rowData.length > 0) {
      dispatch(loadPayrollAdjustments({ payrollHistoryId }));
    }
    if (showModal === false) closeModal();
  }, [showModal]);
  
  useEffect(() => {
    if (showClientModal) return;
    clientSearchInput?.blur();
  }, [showClientModal]);

  useEffect(() => {
    if (!employeeVoidSearchResults?.length) return;
    setRowData(
      employeeVoidSearchResults.map((a) => {
        return {
          ...a,
        };
      }),
    );
  }, [employeeVoidSearchResults]);

  useEffect(() => {
    setWeekEndDropDown(
      openPayrolls.map((a) => {
        return {
          id: `${a.weekEnd}!${a.checkDate}`,
          description: `${convToDateString(
            a.weekEnd,
          )} - ${convToDateString(a.checkDate)}`,
        } as Dropdown;
      }),
    );
  }, [openPayrolls]);

  const onGridReady = (event: GridReadyEvent) => {
    event.api.sizeColumnsToFit();
  };
  
  const onGridSizeChanged = (event: GridSizeChangedEvent) => {
    event.api.sizeColumnsToFit();
  };
  
  const onSave = async () => {
    if (!gridRef.current) return;
    
    const dataToSend: PayrollAdjustmentTransaction[] = [];
    gridRef.current.api.forEachNode((node) => {
      const data = node.data as PayrollAdjustmentTransaction;
      if (data.adjustmentId === null && (data.void || data.addPrePaid)) {
        dataToSend.push(data);
      }
    });

    if (dataToSend.length > 0) {
      const voids = dataToSend.filter((a) => { return a.void; });
      
      if (voids.length === 0) {
        alert('No record is selected');
        clearErrors();
        return;
      }
    }

    const result = await trigger();
    if (!result) return;
    
    const formWeekEnd = getValues('weekEnd')?.split('!')?.[0];
    const formCheckDate = getValues('weekEnd')?.split('!')?.[1];

    const voidTransaction: PayrollAdjustmentVoidTransaction = {
      clientNo,
      adjustmentType: radioButtonValue,
      voidPrepayInfo: {
        clientNo,
        empNo: dataToSend[0]?.empNo,
        weekEnd: formWeekEnd && formWeekEnd !== '' ? formWeekEnd : currentPayroll?.weekEnd ?? null,
        checkDate: formCheckDate && formCheckDate !== '' ? formCheckDate : currentPayroll?.checkDate ?? null,
      },
      transactions: dataToSend.filter((a) => { return a.void && !a.addPrePaid; }),
    };

    const voidPrepayTransaction: PayrollAdjustmentVoidTransaction = {
      clientNo,
      adjustmentType: radioButtonValue,
      voidPrepayInfo: {
        clientNo: getValues('voidClient') ?? clientNo,
        empNo: getValues('voidEmpNo') ?? empNo,
        weekEnd: formWeekEnd && formWeekEnd !== '' ? formWeekEnd : currentPayroll?.weekEnd ?? null,
        checkDate: formCheckDate && formCheckDate !== '' ? formCheckDate : currentPayroll?.checkDate ?? null,
      },
      transactions: dataToSend.filter((a) => { return a.addPrePaid && a.void; }),
    };

    if (voidTransaction.transactions.length > 0) {
      dispatch(
        postAdjustmentVoid({
          payrollHistoryId,
          protectedEmpNo,
          params: voidTransaction,
        }),
      );
    }

    if (voidPrepayTransaction.transactions.length > 0) {
      dispatch(
        postAdjustmentVoid({
          payrollHistoryId,
          protectedEmpNo,
          params: voidPrepayTransaction,
        }),
      );
    }
  };

  const clientSelected = (protectedClientNo: string) => {
    setValue(
      'voidClient',
      userClients?.availableClients.find(
        (a) => { return a.protectedClientNo === protectedClientNo; },
      )?.clientNo,
    );
    setShowClientModal(false);
  };

  const closeModal = () => {
    dispatch(clearEmployeeVoidSearch());
    dispatch(showVoidCheckWindow(null));
    reset();
    onHide();
  };

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={closeModal}
        size="xl"
        animation={false}
      >
        <div className="modal-header">
          <div className="dm-card-title">Void Search</div>
          <button
            type="button"
            onClick={closeModal}
            className="modal-close-btn"
          >
            <Icon name="times" />
          </button>
        </div>
        <Modal.Body>
          <Row>
            <Col xs={4}>
              <div className="void-dates-container">
                <div className="void-date-wrapper">
                  <span className="mr-1">Between</span>
                  <ControlDatePickerGrp
                    minDate={calculatedBeginDate}
                    maxDate={calculatedEndDate}
                    name="beginDate"
                    groupStyle={voidDateStyles}
                    errors={errors.beginDate}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message:
                          'Begin Date is required',
                      },
                    }}
                  />
                </div>
                <div className="void-date-wrapper">
                  <span className="mr-2">and</span>
                  <ControlDatePickerGrp
                    minDate={calculatedBeginDate}
                    maxDate={calculatedEndDate}
                    name="endDate"
                    groupStyle={voidDateStyles}
                    errors={errors.endDate}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'End Date is required',
                      },
                    }}
                  />
                </div>
                <button
                  type="button"
                  className="btn orange-button-sm"
                  onClick={filterVoid}
                >
                  Search
                </button>
              </div>
              <Row>
                <Col>
                  <RadioGrp
                    radioOptions={radioOptions}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setRadioButtonValue(PayrollAdjustmentType[e.target.value as PayrollAdjustmentType]);
                    }}
                    ref={register}
                    name="adjustmentType"
                    defaultChecked={radioButtonValue}
                    groupClass={'d-flex flex-column'}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={4}>
              <Row className="align-items-end no-gutters">
                <div>
                  <InputGrp
                    label="Voided Prepay Client"
                    id="client-input"
                    name="voidClient"
                    groupStyle={inputStyles}
                    errors={errors.voidClient}
                    ref={register({
                      valueAsNumber: true,
                    })}
                    onClick={() => { setShowClientModal(true); }}
                  />
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-link mb-1"
                    onClick={() => { setShowClientModal(true); }}
                  >
                    <Icon name="search" />
                  </button>
                </div>
              </Row>
              <InputGrp
                label="Voided Prepay Emp"
                name="voidEmpNo"
                groupStyle={inputStyles}
                errors={errors.voidEmpNo}
                ref={register({
                  valueAsNumber: true,
                })}
              />
            </Col>
            <Col xs={4}>
              <SelectGrp
                name="weekEnd"
                required={prepaidRequired}
                placeholder={'Week End - Check Date'}
                errors={errors.weekEnd}
                errorMsg="Week End - Check Date is required."
                ref={register({
                  required: {
                    value: prepaidRequired,
                    message: 'Week End - Check Date is required',
                  },
                })}
                options={weekEndDropDown}
                label={'Week End - Check Date'}
              />
            </Col>
          </Row>
          <div className="w-100">
            <div
              className="ag-theme-balham w-100"
              style={{ height: 'calc(100vh - 400px)' }}
            >
              <AgGridReact
                rowData={rowData}
                suppressMenuHide={true}
                gridOptions={{
                  rowSelection: 'multiple',
                  enableCellTextSelection: true,
                }}
                defaultColDef={{
                  editable: false,
                  sortable: true,
                  resizable: true,
                  singleClickEdit: true,
                  suppressMenu: true,
                }}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                onGridSizeChanged={onGridSizeChanged}
                ref={gridRef}
              ></AgGridReact>
            </div>
            <div className="d-flex mt-3">
              <button
                className="orange-outline-button ml-auto mr-2"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="orange-button mr-2"
                onClick={onSave}
              >
                Okay
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showClientModal && (
        <Modal
          show={showClientModal}
          onHide={() => { return setShowClientModal(false); }}
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Client</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AppChangeClient
              onHide={clientSelected}
              onSelectProp={() => { }}
            />
          </Modal.Body>
        </Modal>
      )}
      {checkNoToEdit.checkNo && checkNoToEdit.mescId && (
        <UpdateCheckNoModal
          show={(checkNoToEdit.checkNo !== 0 && checkNoToEdit.mescId !== 0)}
          onHide={() => { return setCheckNoToEdit({ checkNo: 0, mescId: 0 }); }}
          mescId={checkNoToEdit.mescId}
          checkNo={checkNoToEdit.checkNo}
          clientNo={clientNo}
          protectedEmpNo={protectedEmpNo}
          beginDate={getValues('beginDate').toISOString()}
          endDate={getValues('endDate').toISOString()}
          empNo={getValues('voidEmpNo') ?? empNo}
        />
      )}
    </div>
  );
};

export default VoidSearchModal;
