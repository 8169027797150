import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { HttpResponse, SignInUserObject } from '../../models';
import { SessionStorageService } from '../../services';
import { handleError, signinRedirectCallback, storeToken } from '../actions';

export const storeIsAuthenticatedToSessionStorage = (res: any) => {

  if (res) {
    SessionStorageService.setIsAuthenticated(res !== null);
  } else {
    SessionStorageService.clearIsAuthenticated();
  }
  return [res];
};

const RedirectToHome = () => {
  console.log('REDIRECT');
  window.location.replace('/');
};

interface Actions {
  type: string;
  payload: any;
}

const signinRedirectCallback$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(signinRedirectCallback.type),
    switchMap((action: { payload: SignInUserObject; }) => {
      return from(storeIsAuthenticatedToSessionStorage(action.payload)).pipe(
        map((res: any) => { return res; }),
        concatMap((res) => [storeToken(res)]),
        tap(() => {
          RedirectToHome();
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [signinRedirectCallback$];
