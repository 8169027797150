import { Deduction } from '.';
import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class PayrollCheckRegisterDtoDeduction extends Deduction {
  dedID = 0;

  empID = 0;

  categoryID: number | null = null;

  entityID: number | null = null;

  caseID = '';

  parentID: number | null = null;

  originalDedID: number | null = null;

  skipAlwayDedID: number | null = null;

  static readonly convProps: PropTypeLists = {
    dateProps: ['dateStart', 'dateExpire', 'arrearWeekEnd', 'arrearCheckDate'],
    numberProps: ['empId', 'clientNo', 'empNo', 'dedId', 'dedNo', 'garnSeq'],
    nullNumberProps: ['categoryId', 'entityId', 'parentId', 'apid', 'originalDedId', 'skipAlwayDedId'],
    floatProps: ['supportPct'],
    currencyProps: ['exemptAmount', 'begAmount', 'currAmount', 'dedAmount'],
    boolProps: ['active', 'skipping', 'isPrenote'],
  };

  constructor(
    clientNo: number | string,
    empNo: number | string,
    protectedEmpNo: string,
    dedID: number | string,
    deductionType: string,
    props?: { [key: string]: any }) {

    super(clientNo, empNo, protectedEmpNo, dedID, deductionType, props);
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;
    this.dedID = (typeof dedID === 'string') ? parseInt(dedID) : dedID;
    this.deductionType = deductionType;
    props && convToClass(this, props, Deduction.convProps);
  }
}