import { ColDef, GridApi, GridOptions, RowNode } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import Modal from 'core/components/modals/Modal';
import Icon from 'core/components/shared/Icon';
import { Client } from 'core/models';
import React, { useEffect, useState } from 'react';

type Props = {
  show: boolean;
  onHide: () => void;
  clients: Client[];
  currentClient: Client | null;
  setWriteToSelectedClientLibraries: React.Dispatch<React.SetStateAction<Client[]>>;
  setDownloadToClientLibrary: React.Dispatch<React.SetStateAction<boolean>>;
  setIsExportShown: React.Dispatch<React.SetStateAction<boolean>>;
};

const columnsForSmallGrid: ColDef[] = [{
    field: 'Select',
    headerName: 'Selected Clients',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    width: 140,
  },
  {
    field: 'clientNo',
    headerName: 'Client No',
    sortable: true,
    editable: false,
    width: 130,
  },
  {
    field: 'clientName',
    headerName: 'Description',
    sortable: true,
    suppressMenu: false,
    editable: false,
    width: 220,
  }];


const gridOptionsSmall: GridOptions = {
    columnDefs: columnsForSmallGrid,
    rowMultiSelectWithClick: true,
    suppressCellFocus: true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

const DownloadToLibraryModal = ({
  show,
  onHide,
  clients,
  currentClient,
  setWriteToSelectedClientLibraries,
  setDownloadToClientLibrary,
  setIsExportShown,
}: Props) => {
  const [smallGridApi, setSmallGridApi] = useState<GridApi | null>(null);

  const onSmallGridReady = (params: any) => {
    setSmallGridApi(params.api);
  };

  const onClientSelectionChanged = () => {
    if (!smallGridApi) return;
    
    const selectedRows = smallGridApi.getSelectedRows();
    if (!selectedRows) return;
    
    setWriteToSelectedClientLibraries(selectedRows);
  };

  const onClick = (downloadToLibrary: boolean) => {
    setDownloadToClientLibrary(downloadToLibrary);
    setIsExportShown(true);
    onHide();
  }

  const selectCurrentClient = () => {
    if (!smallGridApi) return;
    // should just be the first index
    const clientIndex = clients?.findIndex((innerClient) => +(innerClient?.clientNo ?? 0) === +(currentClient?.clientNo ?? -1)) ?? -1;
    const currentClientRowNode: RowNode | undefined = smallGridApi.getDisplayedRowAtIndex(clientIndex);
    if (!currentClientRowNode) return;
    
    currentClientRowNode.setSelected(true);
  };

  useEffect(() => {
    selectCurrentClient();
  }, [smallGridApi, clients]);

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      title="Select Client Library"
    >
        <div className="col align-items-begin d-flex justify-content-begin">
          <button
            type="button"
            onClick={() => onClick(true)}
            className="btn btn-link dm-grid-action-title p-0 mr-3"
          >
            <p><Icon name="arrow-right" />
            Continue&nbsp;</p>
          </button>
          <button
            type="button"
            onClick={() => onClick(false)}
            className="btn btn-link dm-grid-action-title p-0"
          >
            <p style={{color:'red'}}><Icon name="arrow-right" />
            Download to Local Drive Only</p>
          </button>
        </div>
      <div
        className="ag-theme-balham mb-4"
        style={{
          width: '500px',
          height: '300px',
        }}
      >
        <AgGridReact
          rowData={clients}
          gridOptions={gridOptionsSmall}
          columnDefs={columnsForSmallGrid}
          onGridReady={onSmallGridReady}
          onSelectionChanged={onClientSelectionChanged}
          rowSelection={'multiple'}
        />
        <small>Select the clients you want to include downloaded results in library</small>
      </div>
    </Modal>
  );
};

export default DownloadToLibraryModal;