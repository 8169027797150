import React, { ComponentType, useState, forwardRef, ReactNode } from 'react';
import { PayrollAdjustmentSummary } from 'core/models';
import { Collapse } from 'react-bootstrap';
import Icon from 'core/components/shared/Icon';
import { FormMethods } from 'core/components/form-controls/types';

type ListProps = {
  index: number;
  payrollHistoryId: number;
  control: any; // TODO: can't fully type this for some reason
  errors: FormMethods['errors'];
  register: FormMethods['register'];
  setValue: FormMethods['setValue'];
  getValues: FormMethods['getValues'];
  watch: FormMethods['watch'];
  setOpenState: React.Dispatch<React.SetStateAction<boolean>>;
  voidDisabled?: boolean;
  check?: PayrollAdjustmentSummary;
};

type FormProps = Omit<ListProps, 'setOpenState'>;

type Props = {
  children?: ReactNode;
  List: ComponentType<ListProps>;
  check?: PayrollAdjustmentSummary;
  tabKey: number;
  type: string;
  isOpen?: boolean;
  isDirty: boolean;
  formObj: FormProps;
  handleKeyPress: (key: string, step: number) => void;
};

const AdjustmentModalTable = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      List,
      check,
      isOpen,
      tabKey,
      type,
      formObj,
      handleKeyPress,
    },
    ref,
  ) => {
    const [openState, setOpenState] = useState(!!isOpen);
    const [active, setActive] = useState(false);

    const { payrollHistoryId, control, register, errors, index, voidDisabled, setValue, getValues, watch } = formObj;
    const step = type.toLowerCase() === 'earnings' ? 2 : 1;
    const id = `${type.toLowerCase()}${tabKey}`;

    /* TODO: Expand these functions to limit renders. */
    const handleEnter = () => {
      setActive(true);
    };

    const handleLeave = () => {
      setActive(false);
    };

    return (
      <div
        className={`dm-panel dm-panel-border mt-1 ${id}`}
        onMouseEnter={handleEnter}
        onKeyDown={(e) => { return handleKeyPress(e.key, step); }}
        onMouseLeave={handleLeave}
      >
        <div className="d-flex justify-content-between">
          <div>
            <div
              role="button"
              className="dm-card-subtitle4"
              onClick={() => { return setOpenState(!openState); }}
            >
              <Icon
                name={openState ? 'chevron-down' : 'chevron-right'}
                className="mr-2"
              />
              {type}
            </div>
          </div>
          {children}
        </div>
        <Collapse in={openState}>
          <div ref={active ? ref : undefined}>
            <List
              getValues={getValues}
              control={control}
              index={index}
              register={register}
              watch={watch}
              errors={errors}
              voidDisabled={voidDisabled}
              check={check}
              setValue={setValue}
              payrollHistoryId={payrollHistoryId}
              setOpenState={setOpenState}
            />
          </div>
        </Collapse>
      </div>
    );
  },
);

AdjustmentModalTable.displayName = 'AdjustmentModalTable';

export default AdjustmentModalTable;
