import React, { useEffect, useMemo, useReducer, useState } from 'react';
import CheckBoxForm from '../../Shared/CheckBoxForm';
import { PageSection, UserMapSelectedField } from 'core/models/Downloader.model';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import SelectField from '../../Shared/SelectField';
import { Col, Row } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import { setSelectedFields } from 'core/store/slices/employee-earnings-download.slice';
import { TO_ABBREVIATED, convertRegion } from 'core/states';
import RadioForm from '../../Shared/RadioForm';
import { IOption } from 'interfaces/IOption';
import GridSelect from '../../Shared/GridSelect';
import { MapDropdownOption, MapNonDropdown } from '../../Shared/AddSelectedFields';
import { getAllDeductionCodes } from 'core/store/selectors';

type Props = {
  formMethods: any;
  sectionFields: PageSection[];
};

// Custom Layout for Checkbox and Grid select forms
const EarningsFields = ({ formMethods, sectionFields }: Props) => {
  const appDispatch = useAppDispatch();
  const earningOptions = useAppSelector((state) => { return state?.dropdown.earningsCode; });
  const stateOptions = useAppSelector((state) => { return state?.dropdown.stateTaxEntity; });
  const stateAddOptions = useAppSelector((state) => { return state?.dropdown.stateAdditionalTaxEntity; });
  const cityOptions = useAppSelector((state) => { return state?.dropdown.localTaxEntity; });
  const fedOptions = useAppSelector((state) => { return state?.dropdown.federalTaxes; });
  const deductionCodes = useAppSelector(getAllDeductionCodes); //PI-8680 We want all deduction codes in this dropdown including client deductions

  const selectedFields = useAppSelector((state) => { return state.employeeEarningsDownload.selectedFields; });
  const [grossToNetChecked, setGrossToNetChecked] = useState<boolean>();
  const [summarizeByEmployee, setSummarizeByEmployee] = useState<boolean>(false);
  const [transactionalByEmployee, setTransactionalByEmployee] = useState<boolean>(false);
  const [count, setCount] = useState(-1);

  const earningsInfoSectionFields = sectionFields.find(f => {
    return f.name === 'Earnings Information';
  })?.fields;

  const [gridOptions, checkboxes] = useMemo(() => {
    return [
      earningsInfoSectionFields?.filter((item) => {
        return item.name !== 'Total Selected Hours' &&
          item.name !== 'Total Individual Hours' &&
          item.name !== 'Total Selected Earnings' &&
          item.name !== 'Total Individual Earnings';
      }),
      [
        'Total Selected Hours',
        'Total Individual Hours',
        'Total Selected Earnings',
        'Total Individual Earnings',
      ].map((item) => {
        return {
          name: item,
        };
      }),
    ];
  }, [earningsInfoSectionFields]);

  //Will check to see which of the three radio button options are selected since they will enable/disable fields
  //Will also check to see fi the gross to net option is selected since that will auto select fields
  useEffect(() => {
    if (selectedFields) {
      const hasGrossToNet = selectedFields.find(x => x.fieldName === 'Gross to Net');
      (hasGrossToNet) ? setGrossToNetChecked(true) : setGrossToNetChecked(false);

      const hasSummarizedByEmployee = selectedFields.find(x => x.fieldName === 'Summarize by Employee');
      (hasSummarizedByEmployee) ? setSummarizeByEmployee(true) : setSummarizeByEmployee(false);

      const hasTransactionalbyEmployee = selectedFields.find(x => x.fieldName === 'Transactional by Employee');
      (hasTransactionalbyEmployee) ? setTransactionalByEmployee(true) : setTransactionalByEmployee(false);
    }
  }, [selectedFields]);

  //Will check to see if gross to net is checked and do one of the following:
  //A. If Gross to Net is checked, Filter out all the default options and add them in so all the default fields are added to state
  //B. If it is not checked then, Remove all those selected fields from state.
  useEffect(() => {
    //This is so we skip the inital render so we dont delete any fields that were saved before //TODO THINK OF A BETTER SOLUTION
    if (count !== 1) return setCount(count + 1);
    if (grossToNetChecked) {
      const filteredSelectedFields = selectedFields.filter(x => x.section !== 'Earnings Deductions' 
      && (x.section !== 'Earnings Information' && x.fieldName !== 'Earnings')
      && (x.section !== 'Earnings State Taxes' && x.fieldName !== 'Withholding')
      && (x.section !== 'Earnings State Additional Taxes' && x.fieldName !== 'Withholding')
      && (x.section !== 'Earnings City Taxes' && x.fieldName !== 'Withholding')
      && (x.section !== 'Earnings Federal Taxes' && x.fieldName !== 'Federal Withholding')
      && (x.section !== 'Earnings Federal Taxes' && x.fieldName !== 'FICA-M Withholding')
      && (x.section !== 'Earnings Federal Taxes' && x.fieldName !== 'FICA-R Withholding')
      && (x.section !== 'Earnings Federal Taxes' && x.fieldName !== 'EIC Withholding')
      && (x.section !== 'Earnings Federal Taxes' && x.fieldName !== 'FICA-M2 Withholding')
      && (x.section !== 'Earnings Miscellaneous'));

      appDispatch(setSelectedFields([
        ...filteredSelectedFields,
        ...MapDropdownOption('Earnings Deductions', 'Description', deductionCodes, true),
        ...MapDropdownOption('Earnings Information', 'Earnings', earningOptions, false),
        ...MapDropdownOption('Earnings State Taxes', 'Withholding', stateOptions, true),
        ...MapDropdownOption('Earnings State Additional Taxes', 'Withholding', stateAddOptions, true),
        ...MapDropdownOption('Earnings City Taxes', 'Withholding', cityOptions, true),
        MapNonDropdown('Earnings Federal Taxes', 'Federal Withholding', 'Federal', true),
        MapNonDropdown('Earnings Federal Taxes', 'FICA-M Withholding', 'FICA-M', true),
        MapNonDropdown('Earnings Federal Taxes', 'FICA-R Withholding', 'FICA-R', true),
        MapNonDropdown('Earnings Federal Taxes', 'Federal Withholding', 'Federal', true),
        MapNonDropdown('Earnings Federal Taxes', 'EIC Withholding', 'EIC', true),
        MapNonDropdown('Earnings Federal Taxes', 'FICA-M2 Withholding', 'FICA-M2', true),
        MapNonDropdown('Earnings Miscellaneous', 'Check Number', '', false ),
        MapNonDropdown('Earnings Miscellaneous', 'Check Type', '', false ),
        MapNonDropdown('Earnings Miscellaneous', 'Net Pay', '', false ),
      ] as UserMapSelectedField[]));
    } else {
      const removeDeductionFields = selectedFields.filter(x => x.section !== 'Earnings Deductions' 
      && (x.section !== 'Earnings Information' && x.fieldName !== 'Earnings')
      && (x.section !== 'Earnings State Taxes' && x.fieldName !== 'Withholding')
      && (x.section !== 'Earnings State Additional Taxes' && x.fieldName !== 'Withholding')
      && (x.section !== 'Earnings City Taxes' && x.fieldName !== 'Withholding')
      && (x.section !== 'Earnings Federal Taxes' && x.fieldName !== 'Federal Withholding')
      && (x.section !== 'Earnings Federal Taxes' && x.fieldName !== 'FICA-M Withholding')
      && (x.section !== 'Earnings Federal Taxes' && x.fieldName !== 'FICA-R Withholding')
      && (x.section !== 'Earnings Federal Taxes' && x.fieldName !== 'EIC Withholding')
      && (x.section !== 'Earnings Federal Taxes' && x.fieldName !== 'FICA-M2 Withholding')
      && (x.section !== 'Earnings Miscellaneous'));
      appDispatch(setSelectedFields([
        ...removeDeductionFields]));
    }
  }, [grossToNetChecked]);

  //Gets all the selected fields for each of the sections
  const earningStateTaxes = sectionFields.find(f => {
    return f.name === 'Earnings State Taxes';
  })?.fields || [];

  const earningStateAdditionalTaxes = sectionFields.find(f => {
    return f.name === 'Earnings State Additional Taxes';
  })?.fields || [];

  const cityInformation = sectionFields.find(f => {
    return f.name === 'Earnings City Taxes';
  })?.fields || [];

  const earningsFormattingRadios = sectionFields.find(f => {
    return f.name === 'Earnings Formatting';
  })?.fields?.filter(f => {
    return f.name !== 'Gross to Net';
  });

  const earningsDeductionsCheckBoxes = sectionFields.find(f => {
    return f.name === 'Earnings Deductions';
  })?.fields?.filter(f => {
    return f.name !== 'Description';
  });

  const parsedFedOptions = fedOptions.map(item => {
    let grossKey = '';
    let withHoldingKey = '';

    if (item.description === 'Federal') {
      grossKey = 'Federal Gross';
      withHoldingKey = 'Federal Withholding';
    } else if (item.description === 'FICA-M') {
      grossKey = 'FICA-M Gross';
      withHoldingKey = 'FICA-M Withholding';
    } else if (item.description === 'FICA-M2') {
      grossKey = 'FICA-M2 Gross';
      withHoldingKey = 'FICA-M2 Withholding';
    } else if (item.description === 'FICA-R') {
      grossKey = 'FICA-R Gross';
      withHoldingKey = 'FICA-R Withholding';
    } else if (item.description === 'EIC') {
      withHoldingKey = 'EIC Withholding';
    } else if (item.description === 'FUTA') {
      grossKey = 'FUTA Gross';
    }

    return {
      ...item,
      grossKey,
      withHoldingKey,
    };
  });

  //This will handle each section for the Earnings page
  return (
    <Row>
      <Col sm={6}>
        <GridSelect
          section="Earnings Information"
          title="Earnings Information"
          dFields={gridOptions}
          options={earningOptions}
          checkboxes={checkboxes.filter(x => x.name === 'Total Selected Hours' || x.name === 'Total Selected Earnings')}
          checkboxesSecondSet={checkboxes.filter(x => x.name === 'Total Individual Hours' || x.name === 'Total Individual Earnings')}
          disableCheckBox = {transactionalByEmployee}
          disableSecondSetCheckBox = {transactionalByEmployee || summarizeByEmployee}
          formMethods={formMethods}
          grossToNetChecked = {grossToNetChecked}
          hasCodeColumn={true}
          isCodeField = {false}
        />
        <Row>
          <Col md={6}>
            <GridSelect
              section="Earnings State Taxes"
              title="State Information"
              dFields={earningStateTaxes?.filter(item => { return item.name !== 'Individual State Totals'; })}
              checkboxes={earningStateTaxes?.filter(item => { return item.name === 'Individual State Totals'; })}
              disableCheckBox = {transactionalByEmployee || summarizeByEmployee}
              options={stateOptions.map((item) => {
                return {
                  ...item,
                  code: convertRegion(item.description, TO_ABBREVIATED),
                };
              })}
              formMethods={formMethods}
              grossToNetChecked = {grossToNetChecked}
              hasCodeColumn={true}
              isCodeField = {true}
            />
          </Col>
          <Col md={6}>
            <GridSelect
              section={'Earnings State Additional Taxes'}
              title={'Additional State Information'}
              dFields={earningStateAdditionalTaxes?.filter(item => { return item.name !== 'Individual State Additional Totals'; })}
              checkboxes={earningStateAdditionalTaxes?.filter(item => { return item.name === 'Individual State Additional Totals'; })}
              options={stateAddOptions}
              disableCheckBox = {transactionalByEmployee || summarizeByEmployee}
              formMethods={formMethods}
              grossToNetChecked = {grossToNetChecked}
              hasCodeColumn={false}
              isCodeField={false}
            />
          </Col>
          <Col md={6}>
            <GridSelect
              section="Earnings City Taxes"
              title="City Information"
              dFields={cityInformation?.filter(item => { return item.name !== 'Individual City Totals'; })}
              checkboxes={cityInformation?.filter(item => { return item.name === 'Individual City Totals'; })}
              options={cityOptions}
              disableCheckBox = {transactionalByEmployee || summarizeByEmployee}
              formMethods={formMethods}
              grossToNetChecked = {grossToNetChecked}
              hasCodeColumn={true}
              isCodeField = {true}
            />
          </Col>
          <Col md={6}>
            <GridSelect
              section="Earnings Federal Taxes"
              title="Federal Information"
              dFields={[
                {
                  name: 'Gross',
                  key: 'grossKey',
                },
                {
                  name: 'Withholding',
                  key: 'withHoldingKey',
                },
              ]}
              options={parsedFedOptions}
              hasCodeColumn={false}
              formMethods={formMethods}
              isMultiSelect = {true}
              grossToNetChecked = {grossToNetChecked}
            />
          </Col>
        </Row>
      </Col>
      <Col sm={6}>
        {!!sectionFields?.length &&
          <>
            <SelectField
              options={deductionCodes.map(item => {
                return {
                  ...item,
                  value: item.id,
                  label: item.description,
                };
              })}
              grossToNetChecked = {grossToNetChecked}
              onChange={(selected) => {
                const sFields = cloneDeep(selectedFields).filter(item => {
                  return item.section !== 'Earnings Deductions' ||
                    (item.section === 'Earnings Deductions' &&
                      (item.fieldName === 'Total Selected Deductions'
                        || item.fieldName === 'Total Individual Deductions'));
                });

                type SelectedItem = UserMapSelectedField & IOption;

                appDispatch(setSelectedFields([
                  ...sFields,
                  // @ts-ignore
                  ...selected.map((item: SelectedItem) => {
                    return {
                      section: 'Earnings Deductions',
                      selectedAreaId: item?.selectedAreaId || 0,
                      fieldName: 'Description',
                      format: item?.format || null,
                      font: item?.font || null,
                      filter: {
                        filterId: item?.filter?.filterId || 0,
                        code: '' + item.value,
                        description: item.label as string,
                      },
                    };
                  }),
                ] as UserMapSelectedField[]));
              }}
              section="Earnings Deductions"
              title="Deductions"
              formMethods={formMethods}
            />
            <CheckBoxForm
              key="Earnings Deductions"
              formMethods={formMethods}
              dFields={earningsDeductionsCheckBoxes?.filter(x => x.name === 'Total Selected Deductions')}
              section="Earnings Deductions"
              title=""
              disabled = {transactionalByEmployee}
              isSelectAll={false}
            />
            <CheckBoxForm
              key="Earnings Deductions 2"
              formMethods={formMethods}
              dFields={earningsDeductionsCheckBoxes?.filter(x => x.name === 'Total Individual Deductions')}
              section="Earnings Deductions"
              title=""
              disabled = {transactionalByEmployee || summarizeByEmployee}
              isSelectAll={false}
            />
            <CheckBoxForm
              key="Earnings Company Benefits"
              formMethods={formMethods}
              dFields={sectionFields.find(f => {
                return f.name === 'Earnings Company Benefits';
              })?.fields}
              section={'Earnings Company Benefits'}
              title={'Company Benefits'}
              isSelectAll={false}
            />
            <CheckBoxForm
              key="Earnings Miscellaneous"
              formMethods={formMethods}
              dFields={sectionFields.find(f => {
                return f.name === 'Earnings Miscellaneous';
              })?.fields}
              disabled = {summarizeByEmployee}
              section={'Earnings Miscellaneous'}
              title={'Miscellaneous'}
              isSelectAll={false}
              grossToNetChecked = {grossToNetChecked}
            />
            <RadioForm
              key={'Earnings Formatting'}
              formMethods={formMethods}
              dFields={earningsFormattingRadios}
              title="Formatting"
              section="Earnings Formatting"
            >
              <CheckBoxForm
                key="Earnings Formatting Gross"
                formMethods={formMethods}
                dFields={[
                  { name: 'Gross to Net' },
                ]}
                section="Earnings Formatting"
                isSelectAll={false}
                disabled = {transactionalByEmployee || summarizeByEmployee}
              />
            </RadioForm>
          </>
        }
      </Col>
    </Row>
  );
};

export default EarningsFields;