import React, { useState } from 'react';
import { WeekBeginDate } from '../../../../core/models';
import Modal from 'core/components/modals/Modal';
import { DatePickerGrp } from 'core/components/form-controls/DatePickerGrp';
import Table from 'core/components/shared/dm-table/Table';
import { ColumnHeaderData, Row } from 'core/components/shared/dm-table/types';
import { useAppDispatch } from 'utilities/hooks';
import { updateWeekBeginningDates } from 'core/store/actions';
import { useSelector } from 'react-redux';
import { getControlTotalsByOption } from 'core/store/selectors';
import { CellProps } from 'recharts';

type PropTypes = {
  weekBeginningDates: WeekBeginDate[];
  payrollHistoryId: number;
  show: boolean;
  onHideDates: () => void;
};

const WeekBeginningDatesModal: React.FC<PropTypes> = ({ weekBeginningDates, show, onHideDates: onHide, payrollHistoryId }) => {
  const dispatch = useAppDispatch();
  const [weekBeginDates, setWeekBeginningDates] = useState<WeekBeginDate[]>(weekBeginningDates);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const controlTotalBy = useSelector(getControlTotalsByOption);

  const updateFieldOnWeekBeginDate = (updatedFields: WeekBeginDate) => {
    const clone = structuredClone(weekBeginDates);
    
    clone.map((date: WeekBeginDate) =>{
      if (date.loc === updatedFields.loc && date.dept === updatedFields.dept)
        (date as WeekBeginDate)['weekBeginDate'] = updatedFields.weekBeginDate;
    });
    
    setIsDirty(true);
    setWeekBeginningDates(clone);
  };

  const datePicker = (date: WeekBeginDate) => {
    return (
      <DatePickerGrp
        groupStyle={{width: '100%', height:'10px'}}
        value={date.weekBeginDate as Date}
        name={'weekBeginDate'} 
        onChange={(newVal) => {updateFieldOnWeekBeginDate({ weekBeginDate: newVal, loc: date.loc, dept: date.dept });}}
        />
    )
  }

  const weekBeginningDatesColumns: ColumnHeaderData[] = controlTotalBy === 'Location' ?
  [
    { title: 'Loc', styleClass: 'th-40' },
    { title: 'Week Beginning Date', styleClass: 'th-60' },
  ]:
  [
    { title: 'Loc', styleClass: 'th-30' },
    { title: 'Dept', styleClass: 'th-30' },
    { title: 'Week Beginning Date', styleClass: 'th-40' },
  ]; 
  
  const buildBeginningDatesRows = (date: WeekBeginDate, index: number): Row => {
    return {
      id: index,
      cells: (controlTotalBy === 'Location') ?
      [
        { children: date.loc, styleClass: 'td-40' },
        { children: datePicker(date), styleClass: 'td-60' },
      ]:
      [
        { children: date.loc, styleClass: 'td-30' },
        { children: date.dept, styleClass: 'td-30' },
        { children: datePicker(date), styleClass: 'td-40' },
      ],
    };
  };

  const onSave = () => {
    dispatch(
      updateWeekBeginningDates({
        data: weekBeginDates,
        payrollHistoryId,
      }),
    );
    onHide();
  }

  const onHideAlert = () => {
    if(isDirty)
      if (!confirm('Are you sure you would like to leave without saving?')) return;
    onHide();
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Week Beginning Dates"
    >
      {(closeModal) => {
        return (
          <>
            <Table
              bodyClass="tbody-fit"
              columns={weekBeginningDatesColumns}
              rows={weekBeginDates?.map((date, index) => buildBeginningDatesRows(date, index))}
            />
            <div className="row mt-3">
              <div className="col-12 text-center">
                You must press save before attempting to submit payroll if using different Week Beginning Dates
              </div>
              <div className="col-12 text-right">
                <button type="button"
                  className="btn btn-primary orange-outline-button mr-2"
                  onClick={onHideAlert}>Cancel</button>
                <button type="button"
                  className="btn btn-primary orange-button"
                  disabled={!isDirty}
                  onClick={onSave}>Save</button>
              </div>
            </div>
          </>
        );
      }}
    </Modal>
  );
};
export default WeekBeginningDatesModal;
