import React, { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CheckboxGrp, InputGrp, Labels } from 'core/components/form-controls';
import { SelectGrp } from 'core/components/form-controls/SelectGrp';
import { PayrollStateWithholding } from 'core/models/PayrollAdjustment.model';
import { getIsFinishedPayroll, getStateTaxEntity } from 'core/store/selectors';
import { useAppSelector } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';
import { formatWithCommas } from 'utilities/utilities';
import { FormMethods } from 'core/components/form-controls/types';

type Props = {
  index: number; 
  control: any;
  errors: FormMethods['errors'];
  getValues: FormMethods['getValues'];
  register: FormMethods['register'];
  setValue: FormMethods['setValue'];
  watch: FormMethods['watch'];
  setOpenState: React.Dispatch<React.SetStateAction<boolean>>;
  payrollHistoryId: number;
};

const AdjustmentStateWithholding: React.FC<Props> = ({
  control,
  register,
  setValue,
  watch,
  setOpenState,
  errors,
  index,
}) => {
  const { fields, append, remove } = useFieldArray<PayrollStateWithholding>({
    control,
    name: `items[${index}].stateWithholdings`,
  });
  
  const newAdjustmentCheck = useAppSelector((state) => { return state.payrollAdjustment.adjustmentCheckDefaults; });
  const adjustmentStateInfo = useAppSelector(({ payroll }) => payroll.adjustmentStateInfo);
  const stateTaxEntityOpts = useSelector(getStateTaxEntity);  

  const onAdd = () => {
    if (newAdjustmentCheck) {
      append(newAdjustmentCheck.stateWithholdings[0]);
    }
  };
  
  const isFinishedPayroll = useSelector(getIsFinishedPayroll);
  const isVoid = control.getValues(`items[${index}].adjustmentType`);
  const disabled = (isVoid?.startsWith('Void') ?? false) || isFinishedPayroll;
  const stateWh = watch(`items[${index}].stateWithholdings`) as PayrollStateWithholding[];

  const taxEntityError = (index: number, si: number) => {
    if(!errors.items) return undefined;
    if(!errors.items[index]?.stateWithholdings) return undefined;

    return errors?.items[index]?.stateWithholdings[si]?.taxEntityId;
  }

  return (
    <>
      <div className="text-right mt-2 mb-2">
        {!isFinishedPayroll ? <button
          type="button"
          className="btn btn-link dm-grid-action-title p-0"
          onClick={onAdd}
          disabled={disabled}
        >
          Add Withholding
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button> : null}
      </div>
      <hr />
      {fields.map((sw, si) => {
        const amountFormState = watch(`items[${index}].stateWithholdings[${si}].withholding`);
        const grossFormState = watch(`items[${index}].stateWithholdings[${si}].gross`);
        const currentEntity = watch(`items[${index}].stateWithholdings[${si}].taxEntityId`);
        const matchingEntity = adjustmentStateInfo?.find((state) => +state.taxEntityId === +currentEntity) ?? sw;
        const isEmpWHState = newAdjustmentCheck?.stateWithholdings?.find(x => x.taxEntityId === +currentEntity)?.isWHState ?? false;
        const isEmpSuiState = newAdjustmentCheck?.stateWithholdings?.find(x => x.taxEntityId === +currentEntity)?.isSuiState ?? false;   

        //PI-8088: Only add additional withholdings with 'TaxFollowed == SUI or WH" when employee has matching WH or SUI tax setup; 
        const stateAddTaxes = matchingEntity.addtlWithholdings?.filter(x => ((x.taxFollowed == 'SUI' && isEmpSuiState) || x.taxFollowed != 'SUI') && 
                                                                          ((x.taxFollowed == 'WH' && isEmpWHState) || x.taxFollowed != 'WH')); 
        
        return (
          <div key={sw.id}>
            <div className="d-flex flex-row">
              <input
                type="hidden"
                name={`items[${index}].stateWithholdings[${si}].adjustmentStateTaxId`}
                defaultValue={sw.adjustmentStateTaxId}
                ref={register({
                  valueAsNumber: true,
                })}
              />
              <SelectGrp
                groupClass="gc04 mw175 mt-4"
                options={stateTaxEntityOpts}
                errors={taxEntityError(index, si)}
                name={`items[${index}].stateWithholdings[${si}].taxEntityId`}
                ref={register({
                  valueAsNumber: true,
                  validate: (value) => {
                    if(stateWh.filter(x => x.taxEntityId == +value).length > 1){
                      setOpenState(true);
                      return "Duplicate State Taxes";
                    }
                  },
                })}
                errorMsg= "Duplicate State Taxes"
                defaultValue={currentEntity}
                disabled={disabled}
              />
              <InputGrp
                groupClass="gc03 number-underline mw75"
                label="Amount"
                type="text"
                name={`items[${index}].stateWithholdings[${si}].withholding`}
                ref={register({
                  valueAsNumber: true,
                })}
                defaultValue={formatWithCommas(amountFormState ?? 0)}
                disabled={disabled}
                setValue={setValue}
                formatNumber
                hiddenValue={amountFormState ?? 0}
                hiddenRef={register({
                  valueAsNumber: true,
                })}
              />
              <InputGrp
                groupClass="gc03 number-underline mw75"
                label="Gross"
                type="text"
                name={`items[${index}].stateWithholdings[${si}].gross`}
                ref={register({
                  valueAsNumber: true,
                })}
                defaultValue={formatWithCommas(grossFormState ?? 0)}
                disabled={true}
                setValue={setValue}
                formatNumber
                hiddenValue={grossFormState ?? 0}
                hiddenRef={register({
                  valueAsNumber: true,
                })}
              />
              {(
                <button
                  type="button"
                  className="btn btn-link dm-grid-action-title"
                  onClick={() => { return remove(si); }}
                  disabled={sw?.isSuiOrWhState && sw.adjustmentStateTaxId !== 0}
                >
                  <Icon
                    name="minus-circle"
                    className="fa-minus-circle"
                  />
                </button>
              )}
            </div>
            {matchingEntity !== undefined && stateAddTaxes && stateAddTaxes.length > 0 && (
              <div>
                <table className="table table-striped table-hover w-100">
                  <thead>
                    <tr>
                      <th style={{ width: '125px' }}>Description</th>
                      <th style={{ width: '125px' }}>Amount</th>
                      <th style={{ width: '125px' }}>Gross</th>
                      <th style={{ width: '50px' }}>EE Tax</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stateAddTaxes?.map(
                      (aw, awi) => {
                        const addtlAmtFormState = watch(`items[${index}].stateWithholdings[${si}].addtlWithholdings[${awi}].withholding`);
                        const addtlGrossFormState = watch(`items[${index}].stateWithholdings[${si}].addtlWithholdings[${awi}].gross`);
                        
                        return (
                          <tr key={aw.additionTaxId}>
                            <td style={{ padding: '0.4rem' }}>
                              <input
                                type="hidden"
                                name={`items[${index}].stateWithholdings[${si}].addtlWithholdings[${awi}].additionTaxId`}
                                defaultValue={aw.additionTaxId}
                                ref={register({
                                  valueAsNumber: true,
                                })}
                              />
                              <input
                                type="hidden"
                                name={`items[${index}].stateWithholdings[${si}].addtlWithholdings[${awi}].description`}
                                defaultValue={aw.description}
                                ref={register()}
                              />
                              <input
                                type="hidden"
                                name={`items[${index}].stateWithholdings[${si}].addtlWithholdings[${awi}].categoryId`}
                                defaultValue={+aw.categoryId}
                                ref={register({
                                  valueAsNumber: true,
                                })}
                              />
                              <Labels
                                label={aw.description}
                                hasError={false}
                              />
                            </td>
                            <td>
                              <InputGrp
                                groupClass="number-underline"
                                type="text"
                                name={`items[${index}].stateWithholdings[${si}].addtlWithholdings[${awi}].withholding`}
                                ref={register({
                                  valueAsNumber: true,
                                })}
                                defaultValue={formatWithCommas(addtlAmtFormState ?? 0)}
                                disabled={disabled || !aw.isEmployeeTax}
                                setValue={setValue}
                                formatNumber
                                hiddenValue={addtlAmtFormState ?? 0}
                                hiddenRef={register({
                                  valueAsNumber: true,
                                })}
                              />
                            </td>
                            <td>
                              <InputGrp
                                groupClass="number-underline"
                                type="text"
                                name={`items[${index}].stateWithholdings[${si}].addtlWithholdings[${awi}].gross`}
                                ref={register({
                                  valueAsNumber: true,
                                })}
                                defaultValue={formatWithCommas(addtlGrossFormState ?? 0)}
                                disabled={true}
                                setValue={setValue}
                                formatNumber
                                hiddenValue={addtlGrossFormState ?? 0}
                                hiddenRef={register({
                                  valueAsNumber: true,
                                })}
                              />
                            </td>
                            <td>
                              <CheckboxGrp
                                name={`items[${index}].stateWithholdings[${si}].addtlWithholdings[${awi}].isEmployeeTax`}
                                ref={register()}
                                checked={aw.isEmployeeTax}
                                disabled={true}
                              />
                            </td>
                          </tr>
                        );
                      },
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default AdjustmentStateWithholding;
