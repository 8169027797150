import { AxiosResponse } from 'axios';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { CorrectionEndpoints } from './URL';
import {
  CorrectionsGetRequest,
  CorrectionsReportRequest,
  DeptAllocationCorrectionsGetRequest,
  DeptAllocationWithOptionalId,
  EEOInfoCorrection,
  MescEarnDeptAllocDeleteRequest,
  MescEarnDeptAllocPostRequest,
  UpdateEEOInfoRequest,
  UpdateRequest,
  UpdateWorkersCompRequest,
  WorkersCompCorrection,
} from 'core/models';

const getEEOInfoCorrections = (requestParams: CorrectionsGetRequest): Promise<AxiosResponse<EEOInfoCorrection[]>> => {
  return API_EMPLOYEE_MASTER.get(
    CorrectionEndpoints.GET_EEO_INFO_CORRECTIONS(
      CommonService.getProtectedClientNo(),
      requestParams.empNos,
      requestParams.beginDate,
      requestParams.endDate,
    ),
  );
};

const putEEOInfoCorrections = (requestData: UpdateEEOInfoRequest) => {
  return API_EMPLOYEE_MASTER.put(
    CorrectionEndpoints.PUT_EEO_INFO_CORRECTIONS(
      CommonService.getProtectedClientNo(),
    ),
    requestData,
  );
};

const getWorkersCompCorrections = (requestParams: CorrectionsGetRequest): Promise<AxiosResponse<WorkersCompCorrection[]>> => {
  return API_EMPLOYEE_MASTER.get(
    CorrectionEndpoints.GET_WORKERS_COMP_CORRECTIONS(
      CommonService.getProtectedClientNo(),
      requestParams.empNos,
      requestParams.beginDate,
      requestParams.endDate,
    ),
  );
};

const putWorkersCompCorrections = (requestData: UpdateWorkersCompRequest) => {
  return API_EMPLOYEE_MASTER.put(
    CorrectionEndpoints.PUT_WORKERS_COMP_CORRECTIONS(
      CommonService.getProtectedClientNo(),
    ),
    requestData,
  );
};

const getDeptAllocationEmployees = () => {
  return API_EMPLOYEE_MASTER.get(
    CorrectionEndpoints.GET_DEPT_ALLOCATION_EMPLOYEES(CommonService.getProtectedClientNo()),
  );
};

const getDeptAllocationCorrections = (requestParams: DeptAllocationCorrectionsGetRequest) => {
  return API_EMPLOYEE_MASTER.get(
    CorrectionEndpoints.GET_DEPT_ALLOCATION_CORRECTIONS(
      CommonService.getProtectedClientNo(),
      requestParams.empNo,
      requestParams.weekEnd,
      requestParams.checkDate,
    ),
  );
};

const postDeptAllocationCorrections = (requestData: UpdateRequest<DeptAllocationWithOptionalId[]>) => {
  return API_EMPLOYEE_MASTER.post(
    CorrectionEndpoints.POST_DEPT_ALLOCATION_CORRECTIONS(
      CommonService.getProtectedClientNo(),
      requestData.empNo,
    ),
    requestData.allocations,
  );
};

const postMescEarnCorrection = (requestParams: UpdateRequest<MescEarnDeptAllocPostRequest> & { mescId: number }) => {
  return API_EMPLOYEE_MASTER.post(
    CorrectionEndpoints.POST_MESC_EARN_CORRECTION(
      CommonService.getProtectedClientNo(),
      requestParams.empNo,
      requestParams.mescId,
    ),
    requestParams.allocations,
  );
};

const deleteMescEarnCorrection = (requestParams: MescEarnDeptAllocDeleteRequest) => {
  return API_EMPLOYEE_MASTER.delete(
    CorrectionEndpoints.DELETE_MESC_EARN_CORRECTION(
      CommonService.getProtectedClientNo(),
      requestParams.empNo,
      requestParams.mescId,
      requestParams.earnId,
    ),
  );
};

const generateCorrectionsReport = (requestData: CorrectionsReportRequest) => {
  return API_EMPLOYEE_MASTER.post(
    CorrectionEndpoints.POST_CORRECTIONS_REPORT(CommonService.getProtectedClientNo()),
    requestData,
  );
};

export const CorrectionService = {
  getEEOInfoCorrections,
  putEEOInfoCorrections,
  getWorkersCompCorrections,
  putWorkersCompCorrections,
  getDeptAllocationEmployees,
  getDeptAllocationCorrections,
  postDeptAllocationCorrections,
  postMescEarnCorrection,
  deleteMescEarnCorrection,
  generateCorrectionsReport,
};