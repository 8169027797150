import React from 'react';
import { FtePercentage } from '../../../core/models';
import { useSelector } from 'react-redux';
import { getLocationDepartmentsWithoutHome } from '../../../core/store/selectors';
import { useFieldArray, useWatch } from 'react-hook-form';
import { FieldInputSettings } from '../../../core/components/form-controls/types';
import { InputGrp, SelectGrp } from '../../../core/components/form-controls';
import Icon from 'core/components/shared/Icon';

const fs: FieldInputSettings = {
  percent: {
    name: 'percent',
    label: ''
  },
  loc: {
    name: 'loc',
    label: '',
    labelField: 'locationDesc',
    valueField: 'locationCode',
  },
  dept: {
    name: 'dept',
    label: '',
    labelField: 'deptDesc',
    valueField: 'deptCode',
  },
  subDept: {
    name: 'subDept',
    label: '',
    labelField: 'subDeptDesc',
    valueField: 'subDeptCode',
  },
  subDept2: {
    name: 'subDept2',
    label: '',
    labelField: 'sub2Desc',
    valueField: 'sub2Code',
  },
  promotion: {
    name: 'promotion',
    label: ''
  },
};

type PropTypes = {
  nestIndex: any;
  control: any;
  register: any;
  errors: any;
  fteHeadId: any;
};

const HRFtePercentage: React.FC<PropTypes> = ({
  nestIndex,
  control,
  register,
  errors,
  fteHeadId,
}) => {
  const value = useWatch<any[]>({
    control,
    name: `items[${nestIndex}].percentages`,
  });

  const maxPercent = 100;

  const TotalPercentage = () => {
    const percentTotal =
      (value?.length &&
        value
          .map((item) => { return item.percent || 0; })
          .reduce((prev, next) => { return +prev + +next; })) ||
      0;
    const isPercentInValid = percentTotal > maxPercent;
    return (
      <tr>
        <td>
          <span style={{ color: isPercentInValid ? 'Red' : 'Green' }}>
            <strong>
              {parseFloat(percentTotal.toString()).toFixed(6)}{' '}
              {isPercentInValid && 'Invalid'}
            </strong>
          </span>
        </td>
      </tr>
    );
  };

  const { locationOpts, deptOpts, subdeptOpts, subdept2Opts } = useSelector(
    getLocationDepartmentsWithoutHome,
  );
  const { fields, append } = useFieldArray({
    control,
    name: `items[${nestIndex}].percentages`,
  });

  const onAddPercentage = () => {
    const percentage: FtePercentage = {
      fteId: 0,
      fteHeadId,
      loc: 0,
      dept: 0,
      subDept: 0,
      subDept2: 0,
      percent: 0,
      homeFte: true,
      promotion: false,
    };
    append(percentage, true);
  };

  return (
    <div className="col-12 text-right">
      <div>
        <button
          type="button"
          className="btn btn-link dm-grid-action-title p-0 mr-2"
          onClick={onAddPercentage}
        >
          Add New Percentage{' '}
          <Icon name="plus-circle"
            className="fa-plus-circle" />
        </button>
      </div>

      <div className="float-right">
        <table
          className="table table-bordered table-hover"
          style={{ overflow: 'hidden' }}
        >
          <thead className="thead-light">
            <tr>
              <th className="text-left">Percent</th>
              <th className="text-left">Location</th>
              <th className="text-left">Dept</th>
              <th className="text-left">SubDept</th>
              <th className="text-left">SubDept2</th>
              <th className="text-left">Promotion</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((field, i) => {
              return (
                <tr key={field.id}>
                  <td>
                    <input
                      type="hidden"
                      name={`items[${nestIndex}].percentages[${i}].fteId`}
                      defaultValue={field.fteId}
                      ref={register()}
                    />
                    <input
                      type="hidden"
                      name={`items[${nestIndex}].percentages[${i}].fteHeadId`}
                      defaultValue={field.fteHeadId}
                      ref={register()}
                    />
                    <InputGrp
                      {...fs.percent}
                      name={`items[${nestIndex}].percentages[${i}].percent`}
                      defaultValue={field.percent}
                      errors={errors.percent}
                      ref={register()}
                    />
                  </td>
                  <td>
                    <SelectGrp
                      {...fs.loc}
                      name={`items[${nestIndex}].percentages[${i}].loc`}
                      errors={errors.loc}
                      defaultValue={field.loc.toString()}
                      ref={register()}
                      options={locationOpts}
                    />
                  </td>
                  <td>
                    <SelectGrp
                      {...fs.dept}
                      name={`items[${nestIndex}].percentages[${i}].dept`}
                      defaultValue={field.dept.toString()}
                      errors={errors.dept}
                      ref={register()}
                      options={deptOpts}
                    />
                  </td>
                  <td>
                    <SelectGrp
                      {...fs.subDept}
                      name={`items[${nestIndex}].percentages[${i}].subDept`}
                      defaultValue={field.subDept.toString()}
                      errors={errors.subDept}
                      ref={register()}
                      options={subdeptOpts}
                    />
                  </td>
                  <td>
                    <SelectGrp
                      {...fs.subDept2}
                      name={`items[${nestIndex}].percentages[${i}].subDept2`}
                      defaultValue={field.subDept2.toString()}
                      errors={errors.subDept2}
                      ref={register()}
                      options={subdept2Opts}
                    />
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      verticalAlign: 'middle',
                    }}
                  >
                    <input
                      type="checkbox"
                      name={`items[${nestIndex}].percentages[${i}].promotion`}
                      defaultChecked={field.promotion}
                      ref={register()}
                    />
                  </td>
                </tr>
              );
            })}
            <TotalPercentage />
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HRFtePercentage;
