import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PanelHeader from 'core/components/shared/PanelHeader';
import { HRVaccination, HRVaccinationRequest } from 'core/models/HREmployee.model';
import {
  createHRVaccination,
  deleteHRVaccination,
  loadHRVaccination,
  updateHRVaccination,
} from 'core/store/actions/hr-employee.action';
import { getVaccinations } from 'core/store/selectors/hr-employee.selector';
import VaccinationItem from './VaccinationItem';
import { useForm, useFieldArray } from 'react-hook-form';
import { UNSAVED_MESSAGE } from 'core/constants';
import { getClient, getSelectedEmp } from 'core/store/selectors';
import Icon from 'core/components/shared/Icon';
import { toggleBlockNavigation } from 'core/store/actions';

type FormType = {
  vaccinationItems: HRVaccination[];
};

const VaccinationPage = () => {
  const dispatch = useDispatch();

  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();
  const selEmp = useSelector(getSelectedEmp);
  const client = useSelector(getClient);
  const [vaccinationItems, setVaccinationItems] = useState<HRVaccination[]>([]);
  const vaccinationsStore = useSelector(getVaccinations);

  const {
    reset,
    control,
    handleSubmit,
    formState,
    ...formMethods
  } = useForm<FormType>({
    defaultValues: {
      vaccinationItems,
    },
  });

  const { fields, prepend } =
    useFieldArray({
      control,
      name: 'vaccinationItems',
      keyName: 'id',
    });


  useEffect(() => {
    protectedEmpNo && dispatch(loadHRVaccination(protectedEmpNo));
  }, [protectedEmpNo]);

  useEffect(() => {
    vaccinationsStore && setVaccinationItems(vaccinationsStore);
  }, [vaccinationsStore]);

  useEffect(() => {
    vaccinationItems && reset({ vaccinationItems });
  }, [vaccinationItems]);

  const addVaccination = () => {
    const vaccination: HRVaccination = {
      vaccinationId: 0,
      protectedEmpNo,
    };
    prepend(vaccination);
  };

  const deleteVaccination = (item: HRVaccination) => {
    if (item.vaccinationId) {
      dispatch(deleteHRVaccination({
        data: item,
        protectedEmpNo,
      }));
    } else {
      setVaccinationItems((prev: HRVaccination[]) => { return prev.filter((cb: HRVaccination) => { return cb.vaccinationId; }); });
    }
  };

  const onSubmit = (data: FormType, e?: React.BaseSyntheticEvent) => {
    if (!e?.target.isConnected) {
      return false;
    }
    data && data.vaccinationItems.forEach((item: HRVaccination) => {
      const request: HRVaccinationRequest = {
        protectedEmpNo,
        data: {
          ...item,
          vaccinationId: +item.vaccinationId,
          clientNo: client?.clientNo,
          protectedEmpNo,
          empNo: selEmp?.empNo,
          empId: selEmp?.empId,
          lastTalkDate: item.lastTalkDate !== '' ? new Date(item.lastTalkDate!).toISOString() : null,
          details: item.details?.length ? item.details.map((d) => {
            return {
              ...d,
              date: d.date !== '' ? new Date(d.date!).toISOString() : null,
            };
          }) :
            [],
        },
      };
      request.data.vaccinationId !== 0
        ? dispatch(updateHRVaccination(request))
        : dispatch(createHRVaccination(request));

    });
  };

  const onCancel = () => {
    reset();
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: formState.isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [formState.isDirty]);

  return (
    <div>
      <div className="dm-panel dm-panel-border" >
        <PanelHeader title="Record" >
          <button
            className="btn btn-link dm-grid-action-title p-0 mr-2"
            type="button"
            onClick={addVaccination}
          >
            Add Record	<Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields?.map((item, index: number) => {
            return (
              <div key={item.id}>
                <VaccinationItem
                  item={item}
                  index={index}
                  control={control}
                  formMethods={formMethods}
                  protectedEmpNo={protectedEmpNo}
                  onDelete={deleteVaccination}
                />

                <hr className="dm-panel-hr mt-2" />
              </div>
            );
          })}

          <div className="text-right mt-3">
            <button
              type="button"
              className="btn btn-primary orange-outline-button ml-auto mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              disabled={!formState.isDirty}
              type="submit"
              className="btn orange-button-sm mr-3"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VaccinationPage;
