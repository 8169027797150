import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { EmployeeReportsService } from '../../services/employee-reports.service';
import {
  clearEmployeeChangeReportFields,
  downloadPayCategoryHistoryReport,
  errorEmployeeChangeReport,
  getEmployeeChangeReportFields,
  getEmployeeDeductionsShortage,
  handleError,
  handleSuccess,
  handleWarning,
  postEmployeeChangeReport,
  postEmployeeMasterReport,
  setEmployeeChangeReportError,
  storeEmployeeChangeReport,
  storeEmployeeChangeReportFields,
  storeEmployeeDeductionsShortageReport,
  storeEmployeeMasterReport,
  storePayCatgegoryHistoryReport,
} from '../actions';
import { EmployeeChangeReportField, EmployeeChangeReportRequest, HttpResponse } from '../../models';
import { EmployeePayCategoryHistoryRequest } from 'core/models/EmployeeReports.model';

interface Actions {
  type: string;
  payload: any;
}

const employeeDeductionsShortage$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(getEmployeeDeductionsShortage.type),
    switchMap((action: { payload: { fileType: string | undefined; runReportBy: string | undefined; }; }) => {
      return from(
        EmployeeReportsService.getDeductionShortageReport(
          action?.payload?.fileType,
          action?.payload?.runReportBy,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return storeEmployeeDeductionsShortageReport(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getEmployeeChangeReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(getEmployeeChangeReportFields.type),
    switchMap((action: { payload: { beginDate: string; endDate: string; }; }) => {
      return from(
        EmployeeReportsService.getEmployeeChangeReport(
          action?.payload?.beginDate,
          action?.payload?.endDate,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: EmployeeChangeReportField[]) => { return storeEmployeeChangeReportFields(res); },
        ),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            setEmployeeChangeReportError(),
          ];
        }),
        takeUntil(action$.pipe(
          ofType(clearEmployeeChangeReportFields.type),
        )),
      );
    },
    ),
  );
};

const postEmployeeChangeReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(postEmployeeChangeReport.type),
    switchMap((action: { payload: EmployeeChangeReportRequest; }) => {
      return from(
        EmployeeReportsService.postEmployeeChangeReport(action?.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeEmployeeChangeReport(res),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [
          errorEmployeeChangeReport(),
          handleError(err)]; 
        }),
      );
    },
    ),
  );
};

const postEmployeeMasterReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(postEmployeeMasterReport.type),
    switchMap((action: { payload: { clientNo: number; empNo: number; reportType: string | undefined; }; }) => {
      return from(
        EmployeeReportsService.postEmployeeMasterReport(
          action?.payload?.clientNo,
          action?.payload?.empNo,
          action?.payload?.reportType,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return storeEmployeeMasterReport(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postPayCategoryHistoryReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadPayCategoryHistoryReport.type),
    switchMap((action: { payload: EmployeePayCategoryHistoryRequest; }) => {
      return from(EmployeeReportsService.postPayCategoryHistory(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storePayCatgegoryHistoryReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  employeeDeductionsShortage$,
  getEmployeeChangeReport$,
  postEmployeeChangeReport$,
  postEmployeeMasterReport$,
  postPayCategoryHistoryReport$,
];
