import React, { useState } from 'react';
import styles from '../time-card-styles.module.scss';
import adjustmentStyles from './styles.module.scss';
import AdjustmentDetailHeader from './AdjustmentDetailHeader';
import { useAppSelector } from 'utilities/hooks';
import { DetailHourMap, TimeCardAdjustment, TimeCardContextType, TimeCardDeduction, TimeCardDetail } from 'core/models';
import { createNewTimeSheet } from '../utilities';
import { useFieldArray, useForm } from 'react-hook-form';
import { TimeCardContext } from '../TimeCardContext';
import TimeEntryList from '../TimeEntryList';
import StateWhTotalsTable from './StateWhTotalsTable';
import LocalWhTotalsTable from './LocalWhTotalsTable';
import EarningsHoursTotalsTable from './EarningsHoursTotalsTable';
import DeductionTotalsTable from './DeductionTotalsTable';
import TimeCardAdjustmentSummaryModal from './TimeCardAdjustmentSummary.modal';
import TimeCardPageTools from '../TimeCardPageTools';

const TimeCardAdjustmentDetails = () => {
  const [testTimeEntries, setTestTimeEntries] = useState<TimeCardDetail[]>([]);
  const [detailHoursState, setDetailHoursState] = useState<DetailHourMap>({} as DetailHourMap);
  const [dirtyState, setDirtyState] = useState<boolean>(false);
  const [showAdjustmentSummary, setShowAdjustmentSummary] = useState<boolean>(false);
  
  const formMethods = useForm<TimeCardAdjustment>({ shouldUnregister: false });
  
  const deductionFieldArrayMethods = useFieldArray<TimeCardDeduction>({ control: formMethods.control, name: 'deductions' });
  
  const updateDetailHourState = (newVal: DetailHourMap) => {
    setDetailHoursState(newVal);
  };
  
  const updateDirtyState = (newVal: boolean) => {
    setDirtyState(newVal);
  };
  
  const contextValue: TimeCardContextType = {
    formMethods,
    deductionFieldArrayMethods,
    defaultDetailHoursState: detailHoursState,
    updateDetailHourState,
    updateDirtyState,
    timeCardId: 0,
    isSaving: false,
    isDirty: dirtyState,
    overrideDeleted: false,
    updateOverrideDeleted: (_newVal: boolean) => {},
  };
  
  const onShowAdjustmentSummary = () => {
    setShowAdjustmentSummary(true);
  };
  
  const onAddTimeEntry = () => {
    const newTimeSheet: TimeCardDetail = createNewTimeSheet(1, null, 12);
    setTestTimeEntries((prevState) => [...prevState, newTimeSheet]);
  };
  
  const onDeleteTimeEntry = (id: string | undefined) => {
    if (!id) return;
    setTestTimeEntries((prevState) => prevState.filter((x) => String(x.transmittalTimeCardDetailId) !== id));
  };
  
  return (
    <main
      className={styles['time-card-page']}
      role="main"
    >
      <TimeCardContext.Provider value={contextValue}>
        {showAdjustmentSummary && 999999 && (
          <TimeCardAdjustmentSummaryModal
            payrollHistoryId={999999}
            show={showAdjustmentSummary}
            onHide={() => { setShowAdjustmentSummary(false); }}
          />
        )}
        <div className="d-flex align-items-start justify-content-between">
          <div className="dm-page-title-sm">Adjustment Details</div>
          <div className="d-flex mt-auto">
            <TimeCardPageTools
              type="Adjustment"
              changeTimeCard={(direction: 'previous' | 'next') => { }}
              setShowAddModal={(newVal: boolean) => { }}
            />
          </div>
        </div>
        <hr className="dm-page-hr" />
        <div className="dm-panel dm-panel-border p-0">
          <AdjustmentDetailHeader
            onAddTimeEntry={onAddTimeEntry}
            onShowAdjustmentSummary={onShowAdjustmentSummary}
          />
          <TimeEntryList
            timeEntries={testTimeEntries}
            onDeleteTimeEntry={onDeleteTimeEntry}
          />
        </div>
        <div className={adjustmentStyles['totals-tables-wrapper']}>
          <StateWhTotalsTable />
          <LocalWhTotalsTable />
          <EarningsHoursTotalsTable />
          <DeductionTotalsTable />
        </div>
      </TimeCardContext.Provider>
    </main>
  );
};

export default TimeCardAdjustmentDetails;