import { createSelector } from 'reselect';
import { authState } from '../reducers';
import { AuthState } from '../reducers/auth.reducer';

export const getUser = createSelector(
  authState,
  (state: AuthState) => {return state.user;},
);

export const getUserAccess = createSelector(
  authState,
  (state: AuthState) => {return state.userAccess;},
);
