import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class ClientRateRule {
  rateRuleId = 0;

  location = 0;

  department = 0;

  subDepartment = 0;

  subDepartment2 = 0;

  amount = 0;

  static readonly convProps: PropTypeLists = {
    numberProps: ['rateRuleId', 'location', 'department', 'subDepartment', 'subDepartment2'],
    floatProps: ['amount'],
  };

  constructor(rateRuleId: string | number, props?: { [key: string]: any }) {
    this.rateRuleId = (typeof rateRuleId === 'string') ? parseInt(rateRuleId) : rateRuleId;

    props && convToClass(this, props, ClientRateRule.convProps);
  }
}