import Icon from 'core/components/shared/Icon';
import PageButton from 'core/components/shared/PageButton';
import React from 'react';

type Props = {
  title?: string;
  listType?: string;
  getPrevEmp: () => void;
  getNextEmp: () => void;
  closeModal: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const ModalHeader = ({
  getPrevEmp,
  getNextEmp,
  closeModal,
  title = 'Employee Details',
  listType = 'Employee',
}: Props) => {
  return (
    <div className="modal-header">
      <div className="dm-card-title">{title}</div>
      <div className="d-flex dm-card-subtitle font-weight-bold align-items-center">
        <PageButton
          title="Previous page"
          icon="angle-left"
          onPageChange={getPrevEmp}
          disabled={false}
        />
        <span>{listType}</span>
        <PageButton
          title="Next page"
          icon="angle-right"
          onPageChange={getNextEmp}
          disabled={false}
        />
      </div>
      <button
        type="button"
        onClick={closeModal}
        className="modal-close-btn ml-0"
      >
        <Icon name="times" />
      </button>
    </div>
  );
};

export default ModalHeader;
