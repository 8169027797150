import React from 'react';
import '../time-card-styles.scss';
import { useAppSelector } from 'utilities/hooks';
import { InputGrp } from 'core/components/form-controls';
import { TimeCardJobRosterHours } from 'core/models/Contractor.model';

type Props = {
    hours: TimeCardJobRosterHours[],
};

const printDay = (hour: TimeCardJobRosterHours) => {
    return <span className="time-entry-day">
            <span className="day">{hour.day}</span><span className="day">{hour.fullDate}</span> 
           </span>
}

const printHours = (hour: TimeCardJobRosterHours) => {
    return <InputGrp inputStyle={{ height: '26px', textAlign: 'right' }} defaultValue = {hour.amount?.toFixed(2)} disabled={true} />
}

const JobRosterTimeEntry = ({ hours }: Props) => {
  const payFrequency = useAppSelector(({ client }) => client.client?.clientPayrollFreq);
  const biWeeklyClient = payFrequency?.toLowerCase() === 'bi-weekly';
  const hoursList1 = hours.slice(0,7);
  const hoursList2 = (biWeeklyClient) ? hours.slice(8,14) : null;
  const hoursList1Total = hoursList1.reduce((a,v) => a = a + v.amount, 0);
  const hoursList2Total = (hoursList2) ? hoursList2.reduce((a,v) => a = a + v.amount, 0) : 0;

  return (
    <div className={`main-section ${biWeeklyClient && 'wide-section-3'}`}>
      <div className={`d-flex ${!biWeeklyClient && 'justify-content-between'}`}>
        <div className="section-title">Time Entry</div>
          <div
            className="section-title"
            style={biWeeklyClient ? { marginLeft: '22%' } : undefined}
          >
            {hoursList1Total?.toFixed(2)}
          </div>
         {biWeeklyClient && hoursList2 && 
          <div
          className="section-title"
          style={{ marginLeft: '40%' }}
          >{hoursList2Total.toFixed(2)}
          </div>}
      </div>
      <div className="column-wrapper">
        <div
          className="d-flex"
          style={{ gap: '4px' }}
        >
          <div className="dates-column">
            <span className="dm-form-label mb-2 mt-0">Day of Week</span>
            {hoursList1.map(x => printDay(x))}
            Pay Code
          </div>
          <div
            className="hours-column"
            style={biWeeklyClient ? {flex: '1', paddingRight: '10px', borderRight: '1px solid rgb(214, 214, 214)', marginRight: '4px'} : { flex: '1' }}
          >
            <span className="dm-form-label mb-2 mt-0">Hours</span>
            {hoursList1.map(x => printHours(x))}
            <InputGrp inputStyle={{ height: '26px', textAlign: 'center' }} defaultValue = {hoursList1[0]?.earningsCode} disabled={true} />
          </div>
        </div>
        {biWeeklyClient && hoursList2 && (
          <div
            className="d-flex"
            style={{ gap: '4px' }}
          >
            <div className="dates-column">
              <span className="dm-form-label mb-2 mt-0">Day of Week</span>
              {hoursList2.map(x => printDay(x))}
            </div>
            <div className="hours-column">
              <span className="dm-form-label mb-2 mt-0">Hours</span>
              {hoursList2.map(x => printHours(x))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobRosterTimeEntry;