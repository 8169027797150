import React from 'react';
import EmpPhoto from 'core/components/shared/EmpPhoto';
import { Employee } from 'core/models';
import Icon from 'core/components/shared/Icon';
import { useSelector } from 'react-redux';
import { getIsFinishedPayroll } from 'core/store/selectors/payroll.selector';
import { formatSSN } from 'utilities/utilities';

type Props = {
  currentEmployee: Employee;
  ssn?: string;
  onAdd: () => void;
  addType?: string;
  disabled?: boolean;
};

const ModalEmpDetails = ({
  currentEmployee,
  onAdd,
  ssn,
  addType = '',
  disabled,
}: Props) => {
  const isFinishedPayroll =  useSelector(getIsFinishedPayroll);

  return (
    <>
      <div className="d-flex flex-row">
        <div
          className="mt-auto ml-2 mr-2"
          style={{ width: '100px' }}
        >
          <EmpPhoto
            empPicData={currentEmployee?.employeePhoto}
            empName={
              currentEmployee?.firstName +
              ' ' +
              currentEmployee?.lastName
            }
          />
        </div>
        <div
          className="align-self-center"
          style={{ minWidth: '200px' }}
        >
          <div className="dm-card-subtitle">
            <strong>
              {currentEmployee?.firstName +
                ' ' +
                currentEmployee?.lastName}
            </strong>
          </div>
          <div>
            <span>Employee #: </span>
            <span>{currentEmployee?.empNo}</span>
          </div>
          {ssn ? (
            <div>
              <span>SSN #: </span>
              <span>{formatSSN(ssn)}</span>
            </div>
          ) : null}
        </div>
      </div>
      <div>
        {!isFinishedPayroll ? <button
          type="button"
          className="btn btn-link dm-grid-action-title"
          onClick={() => { return onAdd(); }}
          disabled={disabled}
        >
          Add {addType} <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button> : null}
      </div>
    </>
  );
};

export default ModalEmpDetails;
