import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getRecurringEarnings, getSelectedEmp } from 'core/store/selectors';
import PanelHeader from 'core/components/shared/PanelHeader';
import { createRecurringEarnings, deleteRecurringEarning, handleError, loadCheckCodes, loadRecurringEarnings, toggleBlockNavigation } from 'core/store/actions';
import RecurringEarningsItem from './RecurringEarningsItem';
import { RecurringEarnings, RecurringEarningsRequest } from 'core/models';
import Icon from 'core/components/shared/Icon';
import { ArrayField, useFieldArray, useForm } from 'react-hook-form';
import { UNSAVED_MESSAGE } from 'core/constants';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { Spinner } from 'react-bootstrap';
import { getAccess } from 'utilities/utilities';

type UrlParams = {
  protectedEmpNo: string;
};

type FormType = {
  recurringEarnings: RecurringEarnings[];
};

const RecurringEarningsPage: FC = () => {
  const dispatch = useAppDispatch();
  
  const { protectedEmpNo } = useParams<UrlParams>();
  
  const selEmp = useSelector(getSelectedEmp);
  const recurringEarningStore = useSelector(getRecurringEarnings);
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  
  const [recurringEarningItems, setRecurringEarnings] = useState<RecurringEarnings[]>([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDirtySavedEarning, setIsDirtySavedEarning] = useState<boolean>(false);

  const { control, ...formMethods } = useForm<FormType>({
    defaultValues: {
      recurringEarnings: recurringEarningItems,
    },
  });

  const { fields: recurringEarningsFields, append: appendRecurringEarnings, remove: removeEarnings } =
    useFieldArray<RecurringEarnings>({
      control,
      name: 'recurringEarnings',
      keyName: 'id',
    });

  useEffect(() => {
    dispatch(loadRecurringEarnings(protectedEmpNo));
    dispatch(loadCheckCodes());
    setIsDirty(false);
  }, [protectedEmpNo]);

  useEffect(() => {
    if (!recurringEarningStore) return;
    setRecurringEarnings(recurringEarningStore);
  }, [recurringEarningStore]);

  useEffect(() => {
    if (!recurringEarningItems) return;
    formMethods.reset({
      recurringEarnings: recurringEarningItems.map((e: RecurringEarnings) => {
        return {
          ...e,
        };
      }) });
  }, [recurringEarningItems]);
  
  const updateDirtyState = (newValue: boolean, id: number) => {
    if (id > 0) {
      setIsDirtySavedEarning(newValue);
    }
    setIsDirty(newValue);
  };

  const onAdd = () => {
    if (!selEmp) return dispatch(handleError('Error updating employee')); // how can we make this more informative?
    appendRecurringEarnings({
      protectedEmpNo: protectedEmpNo,
      empNo: selEmp.empNo,
      empId: selEmp.empId,
      recurringId: 0,
    });
    setIsDirty(true);
  };

  const onDelete = (data: Partial<ArrayField<RecurringEarnings, 'id'>>) => {
    if (!confirm('Delete recurring earning? This action cannot be undone.\nYou will need to delete corresponding earnings from transmittal.')) return;
    if (data.recurringId) {
      dispatch(deleteRecurringEarning(data as RecurringEarnings));
    } else {
      const earningIndex = recurringEarningsFields?.findIndex((earning) => earning.id === data.id);
      if (earningIndex === -1) return dispatch(handleError('Cannot find earnings item to delete'));
      
      removeEarnings(earningIndex);
    }
    setIsDirty(isDirtySavedEarning || !!recurringEarningsFields?.filter((earning) => earning.id !== data.id && earning.recurringId === 0)?.length);
  };

  const onSubmit = (data: FormType, e?: React.BaseSyntheticEvent) => {
    if (!e?.target.isConnected) {
      return false;
    }
    
    if (!data || !selEmp) return;
    const recurringEarnings : RecurringEarnings[] = [];
    data.recurringEarnings.forEach(earn => {
      const earningModel = new RecurringEarnings(selEmp.empId, selEmp.clientNo, selEmp.empNo, selEmp.protectedEmpNo, earn);
      earningModel.startDate = (earn.startDate === '') ? null : earn.startDate;
      earningModel.endDate = (earn.endDate === '') ? null : earn.endDate;
      recurringEarnings.push(earningModel);
    }); 

    const request: RecurringEarningsRequest = {
      protectedEmpNo,
      Data: [
        ...recurringEarnings,
      ],
    };
    
    setIsSaving(true);
    dispatch(createRecurringEarnings(request)).then((_res) => {
      setIsDirty(false);
      setIsSaving(false);
    });
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [isDirty]);

  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Recurring Earnings">
        <button
          {...getAccess(sectionAccess, 22, undefined, { disabledSameAsReadOnly: true })}
          type="button"
          className="btn btn-link dm-grid-action-title pb-0"
          onClick={onAdd}
        >
          Add Recurring Earnings{' '}
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button>
      </PanelHeader>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className="d-flex flex-column-reverse">
          {recurringEarningsFields?.map((item, index) => {
            return (
              <div key={item.id} >
                {index + 1 < recurringEarningsFields.length && <hr className="dm-panel-hr mt-2" />}
                <RecurringEarningsItem
                  key = {item.id}
                  item={item}
                  formMethods={formMethods}
                  control = {control}
                  index = {index}
                  onDelete={onDelete}
                  updateDirtyState={updateDirtyState}
                />
              </div>
            );
          })}
        </div>
        <div className="text-right mt-3">
          <button
            {...getAccess(sectionAccess, 22, undefined, { disabledSameAsReadOnly: true, disabledDefault: !isDirty || isSaving })}
            type="submit"
            className="btn orange-button mr-3"
          >
            {isSaving ? (
              <>
                <span className="mr-1">Saving&nbsp;</span>
                <Spinner
                  animation={'border'}
                  size="sm"
                />
              </>
            ) : 'Save'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default RecurringEarningsPage;
