import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class Deduction {
  clientNo = 0;

  empNo = 0;

  protectedEmpNo = '';

  dedId = 0;

  // Not required
  empId = 0;

  dedNo = 0;

  // 'DirectDeposit', 'Deduction', 'Garnishment', 'CompanyBenefit', 'GenericEFT'
  deductionType = '';

  dateStart: Date | string | null = null;

  dateExpire: Date | string | null = null;

  categoryId: number | null = null;

  entityId: number | null = null;

  entityDesc = '';

  caseId = '';

  active = true;

  exemptAmount = 0;

  supportPct = 0;

  begAmount = 0;

  currAmount = 0;

  garnSeq = 0;

  skipping = false;

  dedAmount = 0;

  unit = '';

  freq = '';

  routingNo = '';

  bankAcctNo = '';

  parentId: number | null = null;

  fips = '';

  stateCode = '';

  medicalIns = 'N';

  apid: number | null = null;

  isPrenote = false;

  arrearWeekEnd: Date | string | null = null;

  arrearCheckDate: Date | string | null = null;

  _401kloanNumber = '';

  originalDedId: number | null = null;

  checkCode: string | null = null;

  skipAlwayDedId: number | null = null;

  employeeBenefitDeductionGUID: string | null = null;

  employerBenefitDeductionGUID: string | null = null;

  nickname = '';

  financialInstitution = '';

  status = ''

  static readonly convProps: PropTypeLists = {
    dateProps: ['dateStart', 'dateExpire', 'arrearWeekEnd', 'arrearCheckDate'],
    numberProps: ['empId', 'clientNo', 'empNo', 'dedId', 'dedNo', 'garnSeq', 'dedAmt'],
    nullNumberProps: ['categoryId', 'entityId', 'parentId', 'apid', 'originalDedId', 'skipAlwayDedId'],
    floatProps: ['supportPct', 'dedAmount'],
    currencyProps: ['exemptAmount', 'begAmount', 'currAmount'],
    boolProps: ['active', 'skipping', 'isPrenote'],
  };

  constructor(
    clientNo: number | string,
    empNo: number | string,
    protectedEmpNo: string,
    dedId: number | string,
    deductionType: string,
    props?: { [key: string]: any }) {

    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;
    this.dedId = (typeof dedId === 'string') ? parseInt(dedId) : dedId;
    this.deductionType = deductionType;
    props && convToClass(this, props, Deduction.convProps);
  }
}

export interface DeductionRequest {
  protectedEmpNo: string
  Data: Deduction[]
}  