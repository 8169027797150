import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { WebsiteCustomWaiver, WebsiteWaiver } from 'core/models';
import { InputGrp, TextareaGrp } from 'core/components/form-controls';
import Modal from 'core/components/modals/Modal';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import QuillCustomToolbar from 'core/components/form-controls/QuillCustomToolbar';
import ReactQuill from 'react-quill';
import { postCustomWaiver } from 'core/store/slices/applicantTracking.slice';

type Props = {
  show: boolean;
  customWaiver: WebsiteCustomWaiver | undefined;
  waiver: WebsiteWaiver | undefined;
  webSiteConfigId:number;
  onHide: () => void;
};

const CustomWaiverModal: React.FC<Props> = ({
  show,
  customWaiver,
  waiver,
  webSiteConfigId,
  onHide,
}) => {

  const dispatch = useAppDispatch();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [description, setDescription] = useState(customWaiver?.description);
  const [parsedHtml, setParsedHtml] = useState<string>('');
  const [quillIsDirty, setQuillIsDirty] = useState<boolean>(false);
  
  const { register, reset, handleSubmit, formState: { isDirty } } = useForm<WebsiteCustomWaiver>({
    defaultValues: {
      ...customWaiver,
    },
  });

  const modules = {
    toolbar: {
      container: '#toolbar',
    },
  };
  
  useEffect(() => {
    if (waiver) {
      let desc = waiver.waiverDescription;
      if ( waiver.waiverDescription.includes('<HTML>')) {
        desc = waiver.waiverDescription.substring(waiver.waiverDescription.indexOf('<P>'));
      }      
      desc && setParsedHtml(desc);
    }
  }, [waiver]);

  useEffect(() => {   
    customWaiver && reset({ ...customWaiver });
  }, [customWaiver]);


  const hideModal = () => {
    if (isDirty || quillIsDirty) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };

  const onSubmit = (data: WebsiteCustomWaiver) => {
    if (!(description && webSiteConfigId)) {
      return console.error('cannot make request without description and websiteConfigId');
    }
    const cw: WebsiteCustomWaiver = customWaiver ? {
      ...customWaiver,
      title: data.title,
      description: description,
    } : {
      customWaiverId: 0,
      title: data.title,
      description: description,
      webSiteConfigId: webSiteConfigId,
      visible: false,
      required: false,
      archived: false,
    };

    dispatch(postCustomWaiver(cw));
    reset(cw);
    setQuillIsDirty(false);
  };

  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };

  return (
    <Modal
      show={show}
      onHide={hideModal}
      dialogClassName="lg"
      isDirty={(quillIsDirty || isDirty)}
      backdrop={'static'}
      title={customWaiver ? 'Custom Waiver' : 'Waiver'}
    >
      {customWaiver || (!(customWaiver || waiver)) ?  
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className="d-flex flex-column flex-grow-1"
          >
            <input
              type="hidden"
              name={'customWaiverId'}
              defaultValue={customWaiver?.customWaiverId ?? 0}
              ref={register({ valueAsNumber: true })}
            />
            <input
              type="hidden"
              name={'webSiteConfigId'}
              defaultValue={webSiteConfigId}
              ref={register({ valueAsNumber: true })}
            />
            <div>
              <InputGrp
                label={'Title'}
                name={'title'}
                defaultValue={customWaiver?.title}
                ref={register}
              />
            </div>
            <div className="dm-panel dm-panel-border">
              <div className="dm-subtitle2">Description</div>
              <QuillCustomToolbar />
              <ReactQuill
                theme="snow"
                value={description}
                modules={modules}
                onChange={(input: string) => { setDescription(input); }}
                onFocus={() => { setQuillIsDirty(true); }}
              />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-end mt-3">
            <button
              type="button"
              className="orange-outline-button mr-2"
              onClick={hideModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="orange-button mr-2"
            >
              Save
            </button>
          </div>
        </form>
        : waiver ?
          <div>          
            <div>
              <InputGrp
                label={'Title'}
                defaultValue={waiver.waiverTitle}
                readOnly
              />
            </div>
            <div>
              <QuillCustomToolbar />
              <ReactQuill
                theme="snow"
                readOnly
                value={parsedHtml}
                modules={modules}              
              />
            </div>              
          </div>
          : null}
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
    </Modal>
  );
};
export default CustomWaiverModal;
