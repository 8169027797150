import { createReducer } from '@reduxjs/toolkit';
import { DeferredCompensation } from '../../models';
import { loadDeferredCompensation, storeDeferredCompensation } from '../actions';

export interface State {
  loading: boolean;
  deferredComp: DeferredCompensation;
}

const INITIAL_STATE = {
  loading: false,
  deferredComp: null,
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadDeferredCompensation.type]: (state) => {return { ...state, loading: true };},

  [storeDeferredCompensation.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      deferredComp: action.payload,
    };
  },

});
