import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const data = [
  {
    id: '0',
    title: 'Site Settings',
    href: '/design-ats-website',
  },
  {
    id: '1',
    title: 'Cover Images',
    href: '/design-ats-website/cover-images',
  },
  {
    id: '2',
    title: 'Web Pages',
    href: '/design-ats-website/web-pages',
  },
  {
    id: '3',
    title: 'Waivers',
    href: '/design-ats-website/waivers',
  },
];

const DesignAtsWebsiteSidebar = () => {
  const history = useHistory();

  const [activeLink, setActiveLink] = useState<string>();

  useEffect(() => {
    const d = data.find((l) => {
      return l.href === history.location.pathname;
    });
    setActiveLink(d?.title ?? 'Site Settings');
  }, []);

  const onLinkClick = (href: string) => {
    history.push(href);
  };

  return (
    <>
      {data.map((item) => {
        return (
          <div
            key={item.id}
            className="d-flex flex-column"
          >
            <Button
              variant="link"
              className="dm-sidebar-link"
              style={{
                backgroundColor: activeLink === item.title ? '#fff' : '#f0f0f0',
              }}
              onClick={() => {
                return onLinkClick(item.href);
              }}
            >
              {item.title}
            </Button>
          </div>
        );
      })}
    </>
  );
};

export default DesignAtsWebsiteSidebar;
