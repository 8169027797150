import { createReducer } from '@reduxjs/toolkit';
import { EmployeeUploadClientFile, EmployeeUploadClientLibrary, EmployeeUploadFields, EmployeeUploadMap, EmployeeUploadTranslation } from '../../models';
import {
  createEmpUploadClientLibrary,
  loadEmpUploads,
  storeEmpUpload,
  storeEmpUploadClientLibrary,
  storeEmpUploadField,
  storeEmpUploads,
  storeEmpUploadTranslation,
  storeEmpUploadTranslations,
  storeSelectedEmpUploadClientFile,
  storeSelectedEmpUploadClientLibrary,
} from '../actions';

export interface State {
  loading: boolean;
  uploadingFile: boolean;
  clientFiles: EmployeeUploadClientFile[];
  selectedClientFile: EmployeeUploadClientFile | null;
  selectedClientLibrary: EmployeeUploadClientLibrary | null;
  tables: any[];
  fields: { [key: string]: EmployeeUploadFields[] };
  fieldMap: { [key: number]: EmployeeUploadFields[] }
}

export const INITIAL_STATE: State = {
  loading: false,
  uploadingFile: false,
  clientFiles: [],
  selectedClientFile: null,
  selectedClientLibrary: null,
  tables: [],
  fields: {},
  fieldMap: {},
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadEmpUploads.type]: (state) => {return { ...state, loading: true };},
  [createEmpUploadClientLibrary.type]: (state) => {return { ...state, loading: true, uploadingFile: true };},
  [storeEmpUploadClientLibrary.type]: (state, action) => {return { ...state, loading: true, uploadingFile: false  };},
  [storeEmpUploads.type]: (state, action) => {
    const fields: { [key: string]: EmployeeUploadFields[] } = {};
    action.payload.fields.forEach((f: EmployeeUploadFields) => {
      f.visible && (fields[f.tableName] ? fields[f.tableName].push(f) : fields[f.tableName] = [f]);
    });
    const selectedClientFile = (state.selectedClientFile && state.selectedClientFile.clientFileId)
      ? action.payload.clientMaps.find((m: EmployeeUploadClientFile) => {return m.clientFileId === state.selectedClientFile?.clientFileId;})
      : null;
    return {
      ...state,
      loading: false,
      clientFiles: action.payload.clientMaps,
      selectedClientFile,
      tables: Object.values(fields).map((f: any) => {return { id: f[0].tableName, description: f[0].displayTableName };}),
      fields,
      fieldMap: action.payload.fields
        .reduce((map: any, f: EmployeeUploadFields) => {
          map[f.fieldId] = f;
          return map;
        }, {}),
    };
  },
  [storeSelectedEmpUploadClientFile.type]: (state, action) => {
    return {
      ...state,
      selectedClientFile: action.payload,
    };
  },
  [storeSelectedEmpUploadClientLibrary.type]: (state, action) => {
    return {
      ...state,
      selectedClientLibrary: action.payload,
    };
  },

  [storeEmpUpload.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      clientFiles: [
        ...state.clientFiles.filter((cm: EmployeeUploadClientFile) => {return cm.clientFileId !== action.payload.value.clientFileId;}),
        action.payload.value,
      ],
      selectedClientFile: action.payload.value,
    };
  },

  [storeEmpUploadField.type]: (state, action) => {
    return {
      ...state,
      loading: false,
    // TODO
    };
  },

  [storeEmpUploadTranslation.type]: (state, action) => {
    return {
      ...state,
      loading: false,
    // TODO
    };
  },

  // [storeEmpUploadTranslations.type]: (state, action) => {
  //   const clientFileCopy = structuredClone(state.selectedClientFile as EmployeeUploadClientFile);
  //   const currentMap = clientFileCopy.fields.find(x => x.mapId === action.payload.mapId);
  //   if(!currentMap) return {...state,loading: false,};
  //   currentMap.translations = [...action.payload.translations];

  //   return {
  //     ...state,
  //     loading: false,
  //     clientFiles: [
  //       ...state.clientFiles.filter((cm: EmployeeUploadClientFile) => {return cm.clientFileId !== action.payload.clientFileId;}),
  //       clientFileCopy,
  //     ],
  //     selectedClientFile: clientFileCopy
  //   };
  // },
});
