import React, { useEffect, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import {
  AllModules,
  ColDef,
  GridApi,
  GridOptions,
} from '@ag-grid-enterprise/all-modules';
import { Col, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getLocationDepartmentsWithoutHome } from '../../../../core/store/selectors';
import { MessageSelection, PayrollMessage } from '../../../../core/models';
import Icon from 'core/components/shared/Icon';

const columns: ColDef[] = [
  {
    field: 'select',
    headerName: 'Selected',
    checkboxSelection: true,
    width: 80,
  },
  {
    field: 'deptCode',
    headerName: 'Dept',
    sortable: true,
    width: 80,
  },
  {
    field: 'deptDesc',
    headerName: 'Description',
    sortable: true,
    minWidth: 140,
  },
];

const gridOptions: GridOptions = {
  domLayout: 'autoHeight',
  columnDefs: columns,
  rowSelection: 'multiple',
  enableCellTextSelection: true,
  defaultColDef: {
    suppressMenu: true,
    resizable: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
};

type PropTypes = {
  message: PayrollMessage | undefined;
  show: boolean;
  onHide: () => void;
  selectedItems: (items: MessageSelection[]) => void;
};

const MessageSelectDeptModal: React.FC<PropTypes> = ({
  message,
  show,
  onHide,
  selectedItems,
}) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [, setGridColumnApi] = useState(null);
  const [depts, setDepts] = useState<any[]>([]);
  const [selectedDepts, setSelectedDepts] = useState<MessageSelection[]>();
  const { deptOpts } = useSelector(getLocationDepartmentsWithoutHome);

  useEffect(() => {
    setDepts(deptOpts);
  }, [deptOpts]);

  const onGridReady = (params: any) => {
    gridOptions?.api?.forEachNode((node) => {
      if (message?.departmentSelections) {
        return message.departmentSelections.some(
          (s: MessageSelection) => { return +s.value === node.data.deptCode; },
        )
          ? node.setSelected(true)
          : 0;
      }
    });

    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      selectedRows.length > 0 &&
        setSelectedDepts(
          selectedRows.map((r) => {
            return {
              messageDetailId: r.messageDetailId || 0,
              value: '' + r.deptCode,
            };
          }),
        );
    }
  };

  const onSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (selectedDepts) {
      selectedItems(selectedDepts);
    }
    closeModal(e);
  };

  const closeModal = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.stopPropagation();
    onHide();
  };

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        size="sm"
        animation={false}
      >
        <div className="modal-header">
          <div className="dm-card-title">Select Department</div>
          <button
            type="button"
            onClick={closeModal}
          >
            <Icon
              name="times"
              className="modal-close-btn"
            />
          </button>
        </div>
        <Modal.Body>
          <Row>
            <Col
              xs={12}
              className="table-wrapper-wrapper ag-theme-balham"
            >
              <div
                className="p-2"
                style={{
                  height: '400px',
                  overflowY: 'scroll',
                }}
              >
                <AgGridReact
                  gridOptions={gridOptions}
                  rowData={depts}
                  modules={AllModules}
                  onFirstDataRendered={onGridReady}
                  onSelectionChanged={onSelectionChanged}
                />
              </div>
              <div className="d-flex mt-3">
                <button
                  className="orange-outline-button ml-auto mr-2"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="orange-button mr-2"
                  onClick={onSave}
                >
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MessageSelectDeptModal;
