import { SSN_VALIDATION, SSN_VALIDATION_NO_DASH } from '../core/constants';
import { IAppEmpSearch } from '../core/models/AppEmpSearch.model';

export const createSearchEmployeeRequest = (search: string): IAppEmpSearch => {
  const searchEmp: IAppEmpSearch = {} as IAppEmpSearch;
  if (SSN_VALIDATION.test(search) || SSN_VALIDATION_NO_DASH.test(search)) {
    searchEmp.ssn = search.replace(/-/g, '');
  } else if (/\d+/.test(search)) {
    searchEmp.empNo = parseInt(search);
  } else {
    searchEmp.lastName = search;
  }
  return searchEmp;
};
