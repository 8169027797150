import { ICellRendererParams } from '@ag-grid-community/core';
import { formatWithCommas } from 'utilities/utilities';

export function FormattedAmountRenderer(): any {}

FormattedAmountRenderer.prototype.init = function (params: ICellRendererParams) {
  this.divWrapper = document.createElement('div');
  this.divWrapper.innerHTML = this.formatter(params.value);
};

FormattedAmountRenderer.prototype.formatter = function (value: number | string) {
  return formatWithCommas(value);
};
FormattedAmountRenderer.prototype.getGui = function (_: ICellRendererParams) {
  return this.divWrapper;
};

FormattedAmountRenderer.prototype.refresh = function (params: ICellRendererParams) {
  this.divWrapper.innerHTML = this.formatter(params.value);
  return true;
};

FormattedAmountRenderer.prototype.destroy = function () {};
