import { createAction } from '@reduxjs/toolkit';
import { EmFieldValidation as E } from 'types';

export const beginValidateEmployee = createAction<E.EmFieldValidationRequest>('[validation] START_VALIDATION');

export const storeValidationFromEndpoint = createAction<E.EmSectionErrors | null>('[emFieldValidation] STORE_FIELDS_FROM_ENDPOINT');
export const storeValidationFromStream = createAction<E.EmSectionErrors | null>('[emFieldValidation] STORE_FIELDS_FROM_STREAM');

export const triggerEmFieldValidation = createAction<E.UpdateValidationRequest>('[emFieldValidation] TRIGGER_EM_VALIDATION');

export const toggleEmFieldValidationModal = createAction<boolean>('[emFieldValidation] TOGGLE_SHOW_FIELD_MODAL');
export const toggleMyInfoChangesModalBtn = createAction<boolean>('[emFieldValidation] TOGGLE_SHOW_MYINFO_MODAL');
export const toggleChangeEmpStatusModal = createAction<boolean>('[changeEmpStatusModal] TOGGLE_SHOW_EMPSTATUS_MODAL');
