import React, { CSSProperties } from 'react';

type GridSectionProps = {
  flex?: number;
};

const GridSection: React.FC<GridSectionProps> = ({ flex = 1, children }) => {
  const baseStyles: CSSProperties = {
    flex,
  };
  
  return (
    <div
      className="allocation-grid-wrapper border w-100 p-1"
      style={baseStyles}
    >
      {children}
    </div>
  );
};

export default GridSection;