import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAppSelector } from 'utilities/hooks';
import GridSelect from '../../Shared/GridSelect';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for Checkbox and Grid select forms
const AppraisalReviewsFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  const performanceReviewCodesOptions = useAppSelector((state) => { return state?.dropdown.performanceReviewCodes; });

  const reviewFields = sectionFields.find(f => {
    return f.name === 'Employee Reviews';
  })?.fields;

  return (
    <Row>
      {!!sectionFields?.length && (
        <Col xs={12}>
          <GridSelect
            section="Employee Reviews"
            title="Employee Reviews"
            dFields={reviewFields?.filter(f => { return f.name !== 'Job Title'; })}
            checkboxes={reviewFields?.filter(f => { return f.name === 'Job Title'; })}
            options={performanceReviewCodesOptions}
            hasCodeColumn={false}
            isCodeField={false}
            formMethods={formMethods}
          />
        </Col>
      )}
    </Row>
  );
};

export default AppraisalReviewsFields;