import React, { useEffect, useState } from 'react';
import { InputGrpDecimal } from '../../../core/components/form-controls';
import { FieldInputSettings } from '../../../core/components/form-controls/types';
import { EmployeeShare, OfferOfCoverage } from '../../../core/models';

const offerEligibleValues = ['1B', '1C', '1D', '1E', '1J', '1K', '1L', '1M', '1N', '1O', '1P', '1Q', '1R', '1S'];


const fs: FieldInputSettings = {
  employeeShareAll12Months: {
    name: 'employeeShare.employeeShareAll12Months',
    label: '',
    groupClass: 'gc08 mw100 pr-1',
  },
  employeeShareJanuary: {
    name: 'employeeShare.employeeShareJanuary',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  employeeShareFebruary: {
    name: 'employeeShare.employeeShareFebruary',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  employeeShareMarch: {
    name: 'employeeShare.employeeShareMarch',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  employeeShareApril: {
    name: 'employeeShare.employeeShareApril',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  employeeShareMay: {
    name: 'employeeShare.employeeShareMay',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  employeeShareJune: {
    name: 'employeeShare.employeeShareJune',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  employeeShareJuly: {
    name: 'employeeShare.employeeShareJuly',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  employeeShareAugust: {
    name: 'employeeShare.employeeShareAugust',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  employeeShareSeptember: {
    name: 'employeeShare.employeeShareSeptember',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  employeeShareOctober: {
    name: 'employeeShare.employeeShareOctober',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  employeeShareNovember: {
    name: 'employeeShare.employeeShareNovember',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  employeeShareDecember: {
    name: 'employeeShare.employeeShareDecember',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
};

type PropTypes = {
  formMethods: any;
  item: EmployeeShare;
  offerOfCoverage: OfferOfCoverage;
};

const ACAEmpShare: React.FC<PropTypes> = ({
  formMethods: { errors, register, setValue },
  item,
  offerOfCoverage,
}) => {

  useEffect(() => {
    setMonthReadOnly(offerOfCoverage.offerOfCoverageAll12Months !== '');
  }, [offerOfCoverage]);

  const overrideClass = item?.overridden ? 'text-primary ' : 'text-success ';

  const [monthReadOnly, setMonthReadOnly] = useState(false);

  useEffect(() => {
    setValue('employeeShare.employeeShareJanuary', monthReadOnly ? 0.00 : item?.employeeShareJanuary.toFixed(2));
    setValue('employeeShare.employeeShareFebruary', monthReadOnly ? 0.00 : item?.employeeShareFebruary.toFixed(2));
    setValue('employeeShare.employeeShareMarch', monthReadOnly ? 0.00 : item?.employeeShareMarch.toFixed(2));
    setValue('employeeShare.employeeShareApril', monthReadOnly ? 0.00 : item?.employeeShareApril.toFixed(2));
    setValue('employeeShare.employeeShareMay', monthReadOnly ? 0.00 : item?.employeeShareMay.toFixed(2));
    setValue('employeeShare.employeeShareJune', monthReadOnly ? 0.00 : item?.employeeShareJune.toFixed(2));
    setValue('employeeShare.employeeShareJuly', monthReadOnly ? 0.00 : item?.employeeShareJuly.toFixed(2));
    setValue('employeeShare.employeeShareAugust', monthReadOnly ? 0.00 : item?.employeeShareAugust.toFixed(2));
    setValue('employeeShare.employeeShareSeptember', monthReadOnly ? 0.00 : item?.employeeShareSeptember.toFixed(2));
    setValue('employeeShare.employeeShareOctober', monthReadOnly ? 0.00 : item?.employeeShareOctober.toFixed(2));
    setValue('employeeShare.employeeShareNovember', monthReadOnly ? 0.00 : item?.employeeShareNovember.toFixed(2));
    setValue('employeeShare.employeeShareDecember', monthReadOnly ? 0.00 : item?.employeeShareDecember.toFixed(2));
    setValue('employeeShare.employeeShareAll12Months', !monthReadOnly ? 0.00 : item?.employeeShareAll12Months.toFixed(2));
  }, [monthReadOnly]);

  return (
    <>
      {offerOfCoverage && (
        <div className="d-flex flex-row flex-wrap">
          <div
            className="gc15 font-weight-bold"
            style={{ lineHeight: '12px', fontSize: '10px' }}
          >
            15 Employee Share of Lowest Cost Monthly Premium, for
            Self-Only Min Value Coverage
          </div>
          <InputGrpDecimal
            {...fs.employeeShareAll12Months}
            errors={errors?.employeeShare?.employeeShareAll12Months}
            ref={register({
              valueAsNumber: true,
              required: {
                value: offerEligibleValues.includes(
                  offerOfCoverage.offerOfCoverageAll12Months,
                ),
                message: 'Required',
              },
            })}
            disabled={
              !offerEligibleValues.includes(
                offerOfCoverage.offerOfCoverageAll12Months,
              )
            }
            inputClass={overrideClass}
            defaultValue={item.employeeShareAll12Months}
          />
          <InputGrpDecimal
            {...fs.employeeShareJanuary}
            errors={errors?.employeeShare?.employeeShareJanuary}
            ref={register({
              valueAsNumber: true,
              required: {
                value: offerEligibleValues.includes(
                  offerOfCoverage.offerOfCoverageJanuary,
                ),
                message: 'Required',
              },
            })}
            disabled={
              monthReadOnly ||
                            !offerEligibleValues.includes(
                              offerOfCoverage?.offerOfCoverageJanuary,
                            )
            }
            inputClass={overrideClass}
            defaultValue={item.employeeShareJanuary}
          />
          <InputGrpDecimal
            {...fs.employeeShareFebruary}
            errors={errors?.employeeShare?.employeeShareFebruary}
            ref={register({
              valueAsNumber: true,
              required: {
                value: offerEligibleValues.includes(
                  offerOfCoverage.offerOfCoverageFebruary,
                ),
                message: 'Required',
              },
            })}
            disabled={
              !offerEligibleValues.includes(
                offerOfCoverage.offerOfCoverageFebruary,
              )
            }
            inputClass={overrideClass}
            defaultValue={item.employeeShareFebruary}
          />
          <InputGrpDecimal
            {...fs.employeeShareMarch}
            errors={errors?.employeeShare?.employeeShareMarch}
            ref={register({
              valueAsNumber: true,
              required: {
                value: offerEligibleValues.includes(
                  offerOfCoverage.offerOfCoverageMarch,
                ),
                message: 'Required',
              },
            })}
            disabled={
              monthReadOnly ||
                            !offerEligibleValues.includes(
                              offerOfCoverage?.offerOfCoverageMarch,
                            )
            }
            inputClass={overrideClass}
            defaultValue={item.employeeShareMarch}
          />
          <InputGrpDecimal
            {...fs.employeeShareApril}
            errors={errors?.employeeShare?.employeeShareApril}
            ref={register({
              valueAsNumber: true,
              required: {
                value: offerEligibleValues.includes(
                  offerOfCoverage.offerOfCoverageApril,
                ),
                message: 'Required',
              },
            })}
            disabled={
              monthReadOnly ||
                            !offerEligibleValues.includes(
                              offerOfCoverage?.offerOfCoverageApril,
                            )
            }
            inputClass={overrideClass}
            defaultValue={item.employeeShareApril}
          />
          <InputGrpDecimal
            {...fs.employeeShareMay}
            errors={errors?.employeeShare?.employeeShareMay}
            ref={register({
              valueAsNumber: true,
              required: {
                value: offerEligibleValues.includes(
                  offerOfCoverage.offerOfCoverageMay,
                ),
                message: 'Required',
              },
            })}
            disabled={
              monthReadOnly ||
                            !offerEligibleValues.includes(
                              offerOfCoverage?.offerOfCoverageMay,
                            )
            }
            inputClass={overrideClass}
            defaultValue={item.employeeShareMay}
          />
          <InputGrpDecimal
            {...fs.employeeShareJune}
            errors={errors?.employeeShare?.employeeShareJune}
            ref={register({
              valueAsNumber: true,
              required: {
                value: offerEligibleValues.includes(
                  offerOfCoverage.offerOfCoverageJune,
                ),
                message: 'Required',
              },
            })}
            disabled={
              monthReadOnly ||
                            !offerEligibleValues.includes(
                              offerOfCoverage?.offerOfCoverageJune,
                            )
            }
            inputClass={overrideClass}
            defaultValue={item.employeeShareJune}
          />
          <InputGrpDecimal
            {...fs.employeeShareJuly}
            errors={errors?.employeeShare?.employeeShareJuly}
            ref={register({
              valueAsNumber: true,
              required: {
                value: offerEligibleValues.includes(
                  offerOfCoverage.offerOfCoverageJuly,
                ),
                message: 'Required',
              },
            })}
            disabled={
              monthReadOnly ||
                            !offerEligibleValues.includes(
                              offerOfCoverage?.offerOfCoverageJuly,
                            )
            }
            inputClass={overrideClass}
            defaultValue={item.employeeShareJuly}
          />
          <InputGrpDecimal
            {...fs.employeeShareAugust}
            errors={errors?.employeeShare?.employeeShareAugust}
            ref={register({
              valueAsNumber: true,
              required: {
                value: offerEligibleValues.includes(
                  offerOfCoverage.offerOfCoverageAugust,
                ),
                message: 'Required',
              },
            })}
            disabled={
              monthReadOnly ||
                            !offerEligibleValues.includes(
                              offerOfCoverage?.offerOfCoverageAugust,
                            )
            }
            inputClass={overrideClass}
            defaultValue={item.employeeShareAugust}
          />
          <InputGrpDecimal
            {...fs.employeeShareSeptember}
            errors={errors?.employeeShare?.employeeShareSeptember}
            ref={register({
              valueAsNumber: true,
              required: {
                value: offerEligibleValues.includes(
                  offerOfCoverage.offerOfCoverageSeptember,
                ),
                message: 'Required',
              },
            })}
            disabled={
              monthReadOnly ||
                            !offerEligibleValues.includes(
                              offerOfCoverage?.offerOfCoverageSeptember,
                            )
            }
            inputClass={overrideClass}
            defaultValue={item.employeeShareSeptember}
          />
          <InputGrpDecimal
            {...fs.employeeShareOctober}
            errors={errors?.employeeShare?.employeeShareOctober}
            ref={register({
              valueAsNumber: true,
              required: {
                value: offerEligibleValues.includes(
                  offerOfCoverage.offerOfCoverageOctober,
                ),
                message: 'Required',
              },
            })}
            disabled={
              monthReadOnly ||
                            !offerEligibleValues.includes(
                              offerOfCoverage?.offerOfCoverageOctober,
                            )
            }
            inputClass={overrideClass}
            defaultValue={item.employeeShareOctober}
          />
          <InputGrpDecimal
            {...fs.employeeShareNovember}
            errors={errors?.employeeShare?.employeeShareNovember}
            ref={register({
              valueAsNumber: true,
              required: {
                value: offerEligibleValues.includes(
                  offerOfCoverage.offerOfCoverageNovember,
                ),
                message: 'Required',
              },
            })}
            disabled={
              monthReadOnly ||
                            !offerEligibleValues.includes(
                              offerOfCoverage?.offerOfCoverageNovember,
                            )
            }
            inputClass={overrideClass}
            defaultValue={item.employeeShareNovember}
          />
          <InputGrpDecimal
            {...fs.employeeShareDecember}
            errors={errors?.employeeShare?.employeeShareDecember}
            ref={register({
              valueAsNumber: true,
              required: {
                value: offerEligibleValues.includes(
                  offerOfCoverage.offerOfCoverageDecember,
                ),
                message: 'Required',
              },
            })}
            disabled={
              monthReadOnly ||
                            !offerEligibleValues.includes(
                              offerOfCoverage?.offerOfCoverageDecember,
                            )
            }
            inputClass={overrideClass}
            defaultValue={item.employeeShareDecember}
          />
        </div>
      )}
    </>
  );
};

export default ACAEmpShare;
