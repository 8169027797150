import { createAction } from '@reduxjs/toolkit';
import { SpecialInfo } from 'core/models';

export const SPECIALINFO_EM_VALIDATION_ACTIONS = {
  UPDATE_SPECIAL_INFO: '[special-info] UPDATE_SPECIAL_INFO',
} as const;

export const loadSpecialInfo = createAction<string>('[special-info] LOAD_SPECIAL_INFO');
export const storeSpecialInfo = createAction<SpecialInfo>('[special-info] STORE_SPECIAL_INFO');
export const updateSpecialInfo = createAction<SpecialInfo>(SPECIALINFO_EM_VALIDATION_ACTIONS.UPDATE_SPECIAL_INFO);
