import { AgGridReact } from '@ag-grid-community/react';
import { GridApi, ColDef, GridOptions } from '@ag-grid-enterprise/all-modules';
import { CheckboxGrpInLine } from 'core/components/form-controls';
import InputDateSelector from 'core/components/form-controls/InputDateSelector';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { Dropdown } from 'core/models';
import { ApprovedJobsReportRequest } from 'core/models/ContractorReports.model';
import { clearApprovedJobsReport, downloadApprovedJobsReport, getBrickLayerRates } from 'core/store/slices/contractorReports.slice';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const ApprovedJobsReports = () => {
  const dispatch = useAppDispatch();
  const childRef: React.RefObject<any> = useRef();
  const [reportDates, setReportDates] = useState<string[]>([]);
  const report = useAppSelector((state) => { return state.contractorReports.approvedJobsReport; });
  const brickLayerCementMasonRate = useAppSelector((state) => { return state.contractorReports.brickLayerCementMasonRate; });
  const [selectedRates, setSelectedRates] = useState<Dropdown[]>([]);
  const [brickLayerData, setBrickLayerData] = useState<Dropdown[]>([]);
  const client = useAppSelector((state) => { return state.client.client; });

  const { register, handleSubmit } =
    useForm<ApprovedJobsReportRequest>({
      defaultValues: {
        approvedJobsOnly: true,
      },
    });


  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  useEffect(() => {
    dispatch(getBrickLayerRates());
  }, []);

  useEffect(() => {
    if (brickLayerCementMasonRate) {
      const rates = brickLayerCementMasonRate.bricklayerRates;
      rates && setBrickLayerData(rates.map(r => {
        return {
          id: r,
          description: r
        } as Dropdown;
      }));
    }
  }, [brickLayerCementMasonRate]);


  const onSubmit = (data: ApprovedJobsReportRequest) => {

    const submitData: ApprovedJobsReportRequest = {
      clientNo: client?.clientNo!,
      dateTo: reportDates[1],
      dateFrom: reportDates[0],
      approvedJobsOnly: data.approvedJobsOnly,
      bricklayerCementMasonRate: selectedRates?.map((item: any) => {
        return item.id;
      }),

    };
    dispatch(downloadApprovedJobsReport(submitData));

  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      selectedRows && setSelectedRates(selectedRows);
    }
  };

  const hidePdfViewer = () => {
    dispatch(clearApprovedJobsReport());
  };
  const onCancel = () => {
    childRef?.current?.onCancel();
  };


  const columns: ColDef[] = [
    {
      field: 'select',
      headerName: 'Select',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 90,
    },
    {
      field: 'id',
      headerName: 'Code',
      width: 100,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 130,
    },
  ];

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    columnDefs: columns,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };


  return (

    <div className="dm-panel dm-panel-border">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="dm-grid-title">Approved Jobs Report</div>
        <hr className="dm-panel-hr" />

        <div className="d-flex flex-row flex-grow-1">
          <div className="d-flex flex-row flex-grow-1">
            <div>
              <InputDateSelector
                ref={childRef}
                returnDates={(dates: string[]) => { return setReportDates(dates); }}
                hideDateTypes={true}
              />
            </div>
          </div>

        </div>
        <div className="d-flex flex-column flex-grow-1">
          <div className=" text-right">
            <button
              type="button"
              className="orange-outline-button mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="orange-button mr-2"
              disabled={!reportDates.length}
            >
              Run Report
            </button>
          </div>
        </div>
        <div className="flex-grow-1"
          style={{ marginTop: 20 }}>
          <div className="dm-grid-action-title mb-2">Approved Jobs Only</div>
          <div>
            <div className="d-flex flex-row">
              <CheckboxGrpInLine
                name="approvedJobsOnly"
                label="Approved Jobs Only"
                ref={register}
              />
            </div>
          </div>
          <div className="ag-theme-balham mt-2">

            <div
              className="ag-theme-balham-wrap"
              style={{
                height: '100vh',
                width: '330px'
              }}
            >
              <AgGridReact
                gridOptions={gridOptions}
                rowData={brickLayerData}
                onGridReady={onGridReady}
                onSelectionChanged={onSelectionChanged}
              />
            </div>




          </div>

        </div>



        {
          report ? <PDFViewerModal
            show={report.length > 0}
            pdfData={report}
            onHide={hidePdfViewer}
            reportName="ApprovedJobsReport"
          /> : null
        }
      </form>
    </div >
  );
};

export default ApprovedJobsReports;

