import { createAction } from '@reduxjs/toolkit';
import { ApClientVendor } from '../../models';

export const loadApClientVendors = createAction('[ap-vendor] LOAD_AP_VENDORS');

export const storeApClientVendors = createAction<ApClientVendor[]>('[ap-vendor] STORE_AP_VENDORS');

export const createApClientVendor = createAction<ApClientVendor>('[ap-vendor] CREATE_AP_VENDOR');
export const updateApClientVendor = createAction<ApClientVendor>('[ap-vendor] UPDATE_AP_VENDOR');
export const storeApClientVendor = createAction<{ vendor: ApClientVendor, isNewVendor: boolean }>('[ap-vendor] STORE_AP_VENDOR');
