import React, { useContext, MouseEvent } from 'react';
import TimeCardEarningItem from './TimeCardEarningItem';
import Icon from 'core/components/shared/Icon';
import './time-card-styles.scss';
import { useAppSelector } from 'utilities/hooks';
import { DetailArrayField, DetailOtherEarning } from 'core/models';
import { useFieldArray } from 'react-hook-form';
import { TimeCardContext } from './TimeCardContext';

type Props = {
  timeSheet: DetailArrayField;
  index: number;
  canEdit?: boolean;
};

const Earnings = ({ timeSheet, index, canEdit = true }: Props) => {
  const { formMethods, updateDirtyState } = useContext(TimeCardContext);
  const { control } = formMethods;
  
  const { fields, append, remove } = useFieldArray<DetailOtherEarning>({
    control,
    name: `details[${index}].otherEarnings`,
  });
  
  const addEarnings = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    
    const newEarningsItem: DetailOtherEarning = {
      transmittalTimeCardDetailId: timeSheet?.transmittalTimeCardDetailId ?? 0,
      transmittalTimeCardDetailOtherEarningsId: 0,
      earningsCode: '1',
      recurringEarningId: null,
      amount: 0,
    };
    
    append(newEarningsItem);
    updateDirtyState(true);
    
    e.stopPropagation();
  };
  
  const removeEarnings = (id: number) => {
    remove(id);
    updateDirtyState(true);
  };
  
  return (
    <div className="deductions-earnings-column">
      <div className="sub-section">
        <div className="d-flex">
          <div className="earning-code-col">
            <span className="section-title">Earnings</span>
          </div>
          <div className={canEdit ? 'earning-amount-col' : ''}>
            <span
              className="dm-form-label"
              style={{ marginLeft: canEdit ? 'unset' : '45%' }}
            >
              Amount
            </span>
          </div>
          {canEdit && (
            <div className="earning-desc-col">
              <div className="d-flex justify-content-end">
                <button
                  className="section-action btn btn-link dm-grid-action-title p-0 d-flex no-wrap"
                  style={{ whiteSpace: 'nowrap' }}
                  onClick={addEarnings}
                >
                  Add Earnings <Icon
                    name="plus-circle"
                    className="fa-plus-circle"
                  />
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex flex-column">
          {!fields?.length ? (
            <div className="add-entry">
              Click &ldquo;Add Earnings&rdquo;
            </div>
          ) : fields?.map((earning, nestedIndex) => {
            return (
              <TimeCardEarningItem
                key={earning.id}
                earning={earning}
                index={index}
                removeEarnings={removeEarnings}
                nestedIndex={nestedIndex}
                canEdit={canEdit}
              />
            );
          })
          
          }
        </div>
      </div>
    </div>
  );
};

export default Earnings;