import { AxiosResponse } from "axios";
import { API_EMPLOYEE_MASTER } from './API';
import { EmployeeEndPoints } from './URL';
import { CommonService } from './common.service';
import { TimeOffRequest } from '../models';

const getTimeOffPastDue = (): Promise<AxiosResponse<TimeOffRequest[]>> => {
  return API_EMPLOYEE_MASTER.get<TimeOffRequest[]>(
    EmployeeEndPoints.GET_TIME_OFF_PAST_DUE(
      CommonService.getProtectedClientNo(),
    ),
  );
};

export const TimeOffService = {
  getTimeOffPastDue,
};
