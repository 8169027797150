import React, { useState } from 'react';
import Modal from 'core/components/modals/Modal';
import { Employee } from 'core/models';
import { ColumnHeaderData, Row } from 'core/components/shared/dm-table/types';
import timeCardStyles from '../time-card-styles.module.scss';
import { InputGrp } from 'core/components/form-controls';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import Table from 'core/components/shared/dm-table/Table';
import { addTimeCardAdjustment, handleError } from 'core/store/actions';
import { useHistory } from 'react-router-dom';

const buildEmpRow = (employee: Employee): Row => {
  return {
    id: employee.empNo,
    cells: [
      { children: employee.empNo, styleClass: 'td-10' },
      { children: employee.ssn, styleClass: 'td-20' },
      { children: employee.firstName, styleClass: 'td-30' },
      { children: employee.midName, styleClass: 'td-10' },
      { children: employee.lastName, styleClass: 'td-30' },
    ],
  };
};

const jobNoColumns: ColumnHeaderData[] = [
  { title: 'Emp. No.', styleClass: 'td-10' },
  { title: 'SSN', styleClass: 'td-20' },
  { title: 'First Name', styleClass: 'td-30' },
  { title: 'M.I.', styleClass: 'td-10' },
  { title: 'Last Name', styleClass: 'td-30' },
];

const matchQuery = (emp: Employee, _query: string): boolean => {
  return !!(emp?.firstName?.toLowerCase()?.includes(_query.toLowerCase())
            || emp?.midName?.toLowerCase()?.includes(_query.toLowerCase())
            || emp?.lastName?.toLowerCase()?.includes(_query.toLowerCase()))
      || String(emp?.empNo)?.includes(_query);
};

type Props = {
  show: boolean;
  onHide: () => void;
};

const EmployeeSearch = ({ show, onHide }: Props) => {
  const { employees } = useAppSelector((state) => state.employees);
  
  const dispatch = useAppDispatch();
  
  const history = useHistory();
  
  const [filteredEmps, setFilteredEmps] = useState<Employee[]>(employees);
  
  const handleSearch = (newQuery: string) => {
    if (!newQuery.trim().length) {
      setFilteredEmps(employees);
      return;
    }
    
    const newState = structuredClone(employees)?.filter((emp: Employee) => matchQuery(emp, newQuery));
    
    setFilteredEmps(newState);
  };
  
  const handleRowClick = (id: string | number) => {
    const matchTimeCard = filteredEmps?.find((emp) => emp.empNo === +id);
    if (!matchTimeCard) return dispatch(handleError('Time Card could not be loaded'));
    
    dispatch(addTimeCardAdjustment({ payrollHistoryId: 454014 }))
      .then((res) => {
        setTimeout(() => {
          history.push(res.payload as string);
        }, 2000);
      });
    onHide();
  };
  
  return (
    <Modal
      title="Search Employees"
      size="xl"
      show={show}
      onHide={onHide}
      animation
    >
      <div className="d-flex flex-column">
        <div className="d-flex flex-column w-25">
          <label
            htmlFor="search-input"
            className={timeCardStyles['search-top-label']}
          >Search</label>
          <InputGrp
            placeholder="Search"
            groupClass="m-0"
            inputClass={`gc20 ${timeCardStyles['search-input']}`}
            name="search-input"
            type="text"
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              handleSearch(target.value);
            }}
          />
        </div>
        <Table
          columns={jobNoColumns}
          rows={filteredEmps?.map((emp) => buildEmpRow(emp))}
          onRowClick={handleRowClick}
        />
      </div>
    </Modal>
  );
};

export default EmployeeSearch;
