import React, { CSSProperties } from 'react';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { InputGrp, InputGrpCurrency, SelectGrp } from 'core/components/form-controls';
import PanelHeader from 'core/components/shared/PanelHeader';
import { CURRENCY_VALIDATION2, ControlIds } from 'core/constants';
import { useSelector } from 'react-redux';
import { getClientOptionDefaultPayPeriod, getPayPeriods } from 'core/store/selectors';
import { getAccess } from 'utilities/utilities';
import { BaseSectionProps } from './PayrollInfoPage';

const fs: FieldInputSettings = {
  payPeriod: { name: 'payPeriod',
    label: 'PAY PERIOD',
    groupClass: 'gc20 mw150' },
  highGross: { name: 'highGross',
    label: 'HIGH GROSS',
    groupClass: 'groupClass15',
    errorMsg: 'help',
  },
  fixedHours: { name: 'fixedHours',
    label: 'FIXED HOURS',
    groupClass: 'groupClass15',
    type: 'number',
    inputStyle: { textAlign: 'right' } },
  highHours: { name: 'highHours',
    label: 'HIGH HOURS',
    groupClass: 'groupClass15',
    type: 'number',
    inputStyle: { textAlign: 'right' } },
};

const styles: { [key: string]: CSSProperties } = {
  controlsContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
};

const PayrollIinfoControls: React.FC<BaseSectionProps> = ({
  methods: { register, setValue, errors },
  sectionAccess,
}) => {
  const payPeriodOpts = useSelector(getPayPeriods);
  const defaultPayPeriod = useSelector(getClientOptionDefaultPayPeriod);
  const payPeriodAccess = getAccess(sectionAccess, 2, ControlIds.payPeriod);
  
  const payrollInfoSectionAccess = sectionAccess?.find((x) => x.workItemId === 6);
  
  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Payroll Controls" />
      <div style={styles.controlsContainer}>
        <SelectGrp
          {...fs.payPeriod}
          {...payPeriodAccess}
          visible={payPeriodAccess.visible && (payrollInfoSectionAccess?.visible ?? true)}
          errors={errors.payPeriod}
          ref={register}
          options={[defaultPayPeriod, ...payPeriodOpts]}
        />
        <InputGrpCurrency
          {...fs.highGross}
          {...getAccess(sectionAccess, 6, ControlIds.highGross)}
          errors={errors.highGross}
          setValue={setValue}
          ref={register(CURRENCY_VALIDATION2)}
        />
        <InputGrp
          {...fs.highHours}
          {...getAccess(sectionAccess, 6, ControlIds.highHours)}
          errors={errors.highHours}
          ref={register({ min: { value: 0,
            message: 'Must be greater than or equal to 0' } })}
        />
        <InputGrp
          {...fs.fixedHours}
          {...getAccess(sectionAccess, 6, ControlIds.fixedHours)}
          errors={errors.fixedHours}
          ref={register({ min: { value: 0,
            message: 'Must be greater than or equal to 0' } })}
        />
      </div>
    </div>
  );
};

export default PayrollIinfoControls;