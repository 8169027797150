import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import { EarningsReportType } from '../../core/models/EarningsReports.model';
import { loadStandardReports } from '../../core/store/actions/earnings-reports.action';
import { useAppDispatch, useAppSelector } from '../../utilities/hooks';

type PropTypes = {
  earningsReportType: EarningsReportType;
  show: boolean;
  onHide: () => void;
};

const EarningsReports: React.FC<PropTypes> = ({
  earningsReportType,
  show,
  onHide,
}) => {
  const dispatch = useAppDispatch();
  const {empNos, orderByType} = useAppSelector((state) => {return state.employeeInfomation;});
  const beginDate = useAppSelector(
    (state) => {return state.earningsReports.beginDate;},
  );
  const endDate = useAppSelector((state) => {return state.earningsReports.endDate;});
  const checkDate = useAppSelector(
    (state) => {return state.earningsReports.checkDate;},
  );
  const report = useAppSelector((state) => {return state.earningsReports.report;});

  const [canRunReport, setCanRunReport] = useState<boolean>(false);
  const [alertInfo, setAlertInfo] = useState({
    variant: 'primary',
    message: 'Generating report....',
  });

  useEffect(() => {
    if (empNos.length > 0 && beginDate && endDate) {
      setCanRunReport(true);
      dispatch(
        loadStandardReports({
          empNos,
          beginDate,
          endDate,
          earningsReportType,
          checkDate,
          orderByType,
        }),
      );
    } else {
      setCanRunReport(false);
      setAlertInfo({
        variant: 'danger',
        message: 'Please select an employee and/or date range.',
      });
    }
  }, []);

  useEffect(() => {
    if (report?.length === undefined) {
      setCanRunReport(false);
      setAlertInfo({
        variant: 'danger',
        message: 'Report cannot be generated',
      });
    }
  }, [report]);

  return (
    <>
      {canRunReport && report?.length > 0 ? (
        <PDFViewerModal
          show={show}
          onHide={onHide}
          pdfData={report}
          reportName="EarningsReport"
        />
      ) : (
        <Alert variant={alertInfo.variant}>{alertInfo.message}</Alert>
      )}
    </>
  );
};

export default EarningsReports;
