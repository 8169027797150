import Modal from 'core/components/modals/Modal';
import { useHistory } from 'react-router-dom';

type Props = {
  show: boolean;
  onHide: () => void;
};

const WorkFlowTaskSuccessModal = ({ show, onHide }: Props) => {
  
  const history = useHistory();

  const onGoToHistoryWorkTab = () => {
    onHide();
    return history.push('/workflows');
  }

  const onClick = (goToWorkTaskTab: boolean) => {
    if(goToWorkTaskTab) return onGoToHistoryWorkTab();
    else return onHide();
  }
    
  return (
    <>
      <Modal
        title={`Work Flow Task Created`}
        animation
        size="sm"
        show={show}
        onHide={onHide}
      >
        <div style={{ whiteSpace: 'pre-line' }}>
            Work Flow Task Created Successfully. Changes will not take effect until they are approved. Pending approvals can be found in the Work Flow Tasks tab.
            </div>
            <br/>
            <div className="d-flex justify-content-end mt-2">
              <div className="d-flex flex-row">
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button mr-2"
                  onClick={() => {
                    onClick(false);
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button mr-2"
                  onClick={() => {
                    onClick(true);
                  }}
                >
                  Go To Work Flow Tab
                </button>
              </div>
            </div>
      </Modal>
    </>
  );
};

export default WorkFlowTaskSuccessModal;