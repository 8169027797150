import Icon from 'core/components/shared/Icon';
import PanelHeader from 'core/components/shared/PanelHeader';
import { InterviewTemplate, ScorecardCategory } from 'core/models';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'utilities/hooks';
import { Row, Col } from 'react-bootstrap';
import { GridApi, ICellRendererParams, ColDef, GridOptions } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import {
  getInterviewTemplates, 
  getInterviewQuestions, deleteInterviewTemplate, 
  getScorecardCategories, getInterviewTypes, storeInterviewTemplate, 
} from 'core/store/slices/applicantTracking.slice';
import { useDispatch } from 'react-redux';
import InterviewTemplateModal from './InterviewTemplate.modal';
import InterviewTemplateTypesModal from './InterviewTemplateTypes.modal';

const InterviewTemplatesPage = () => {
  
  const dispatch = useDispatch();
  const [interviewTemplates, interviewTemplate] = useAppSelector(
    (state) => {
      return [
        state.applicantTracking.interviewTemplates,
        state.applicantTracking.interviewTemplate,
      ];
    },
  );

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [rowData, setRowData] = useState<InterviewTemplate[]>([]);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [showTypesModal, setShowTypesModal] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<InterviewTemplate>();

  useEffect(()=>{
    dispatch(getInterviewTemplates());
    dispatch(getInterviewQuestions());
    dispatch(getInterviewTypes());
    dispatch(getScorecardCategories());
  }, []);
  
  useEffect(() => {
    if (!interviewTemplate) return;
    setSelectedTemplate(interviewTemplate);
  }, [interviewTemplate]);

  useEffect(()=>{
    selectedTemplate && setSelectedTemplate(interviewTemplates.find(t => {
      return t.interviewTemplateId === selectedTemplate.interviewTemplateId;
    }));
    interviewTemplates && setRowData(interviewTemplates);
  }, [interviewTemplates]);
  
  const onAddTemplate = () => {
    setSelectedTemplate(undefined);
    setShowDetailModal(true);
  };

  const onAddType = () => {
    setSelectedTemplate(undefined);
    setShowTypesModal(true);
  };

  const openDetailModal = (params: ICellRendererParams) =>{
    setSelectedTemplate(params.data);
    setShowDetailModal(true);
  };
  
  const onDeleteTemplate = (params: ICellRendererParams) => {
    dispatch(deleteInterviewTemplate({
      interviewTemplateId: params.data.interviewTemplateId,
      interviewTypeId: params.data.interviewTypeId,
    }));
  };

  const columns = useMemo<ColDef[]>(
    () => {
      return [
        {
          field: 'title',
          headerName: 'Template Name',          
          cellStyle: { textAlign: 'left'  },
          cellRenderer: (params: ICellRendererParams) => {
            if (params.data.isDefault) {
              return (<div style={{ width: 280 }}>{params.data.title}</div>);
            } else {   
              return (
                <button
                  type="button"
                  className="btn btn-link"                  
                  style={{ textAlign: 'left' }}
                  onClick={() => { return openDetailModal(params); }
                  }
                >
                  {params.data.title}
                </button>             
              );
            }
          },
          width: 280,
        },
        {
          field: 'interviewTypeDescription',
          headerName: 'Template Type',         
          width: 190,         
        },  
        {
          field: 'description',
          headerName: 'Template Description',         
          width: 350,         
        },  
        {
          field: 'questions',
          headerName: 'Template Questions', 
          cellRenderer: (params: ICellRendererParams) => {
            return ( <button
              type="button"
              className="btn btn-link"
              onClick={() => { return openDetailModal(params); }
              }
            >{params.data.questions?.length} Questions</button>);
          },         
          width: 160,         
        },  
        {
          field: 'scorecardCategories',
          headerName: 'Scorecard Categories', 
          cellRenderer: (params: ICellRendererParams) => {
            const categories = params.data.scorecardCategories?.map((t:ScorecardCategory)=>{return t.category;});
            return (
              <div>
                {categories?.join(', ')}
              </div>);
          },         
          width: 160,         
        },  
        {
          field: 'delete',
          cellRenderer: (params: ICellRendererParams) => {
            if (params.data.isDefault) {
              return (<span></span>);
            } else {               
              return (
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => { return onDeleteTemplate(params); }
                  }
                >
                  <Icon
                    name="minus-circle"
                    className="fa-minus-circle"
                  />
                </button>             
              );
            }
          },
          width: 70,
        },     
      ];
    },
    [],
  );

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    stopEditingWhenCellsLoseFocus:true,
    undoRedoCellEditing:true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onHideChange  = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      setRowData([ ...interviewTemplates.filter((i:InterviewTemplate) => {return  i.isDefault !== true;}) ]);
    } else {
      setRowData([ ...interviewTemplates ]);
    }    
  };

  return (
    <Row className="no-gutters p-0">
      <Col className="mt-1 shadow p-3 border">
        <PanelHeader
          title="Interview Templates"
          iconName="compass-drafting"
          hideDefault={true}
          onHideChange={(e:any) => {return onHideChange(e);}}
        > 
          <button
            type="button"
            className="btn btn-link dm-grid-action-title pb-0 mr-2"
            onClick={onAddType}
          >
            Manage Interview Types
          </button>
          <button
            type="button"
            className="btn btn-link dm-grid-action-title pb-0 mr-2"
            onClick={onAddTemplate}
          >
            Add New Interview Template<Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <div className="table-wrapper-wrapper ag-theme-balham mt-3">
          <AgGridReact
            gridOptions={gridOptions}
            rowData={rowData}
            columnDefs={columns}
            onGridReady={onGridReady}
          />
        </div>
        {showDetailModal && (
          <InterviewTemplateModal
            item={selectedTemplate}
            show={showDetailModal}
            onHide={() => {
              setShowDetailModal(false);
              dispatch(storeInterviewTemplate(null));
            }}            
          />
        )}
        {showTypesModal && (
          <InterviewTemplateTypesModal
            show={showTypesModal}
            onHide={() => { return setShowTypesModal(false); } }
          />
        )}
      </Col>
    </Row>
  );
};

export default InterviewTemplatesPage;
