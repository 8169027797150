import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResendOnboardingEmail } from 'core/models';
import { Applicant, AvailableJob, ClientJobs, CustomEmail, DesignWebsite, InterviewLocation, 
  InterviewProcess, InterviewQuestion, InterviewTemplate, InterviewType, PageCustomField, ScorecardCategory, 
  ScorecardTrait, 
  TemplateRequest, 
  TestEmail, 
  WebsiteCustomWaiver,
  WebsiteImage } from 'core/models/ApplicantTracking.model';
export interface ApplicantTrackingState {
  loading: boolean;
  showApplicantsWaiting: boolean;
  applicants: Applicant[];
  applicant: Applicant | null;
  jobs: ClientJobs | null;
  job: AvailableJob | null;
  selectedJob: AvailableJob | null;
  interviewProcesses: InterviewProcess[];
  interviewProcess: InterviewProcess | null;
  interviewTemplates: InterviewTemplate[];
  interviewTemplate: InterviewTemplate | null;
  interviewQuestions: InterviewQuestion[];
  interviewQuestion: InterviewQuestion | null;
  interviewLocations: InterviewLocation[];
  interviewLocation:InterviewLocation | null;
  scorecardCategories: ScorecardCategory[];
  scorecardCategory:ScorecardCategory | null;
  scorecardTraits: ScorecardTrait[];
  scorecardTrait:ScorecardTrait | null;
  interviewTypes: InterviewType[];
  customEmails: CustomEmail[];
  customEmail: CustomEmail | null;
  customFields: PageCustomField[];
  customField: PageCustomField | null;
  checkForConfig: string | null;
}

const initialState: ApplicantTrackingState = {
  loading: false,
  showApplicantsWaiting: false,
  applicants: [],
  applicant: null,
  jobs:null,
  job:null,
  selectedJob:null,
  interviewProcesses:[],
  interviewProcess: null,
  interviewTemplates:[],
  interviewTemplate:null,
  interviewQuestions:[],
  interviewQuestion:null,
  interviewLocations:[],
  interviewLocation:null,
  scorecardCategories:[],
  scorecardCategory:null,
  scorecardTraits: [],
  scorecardTrait: null,
  interviewTypes:[],
  customEmails: [],
  customEmail: null,
  customFields: [],
  customField: null,
  checkForConfig: null,
};

export const applicantTrackingSlice = createSlice({
  name: 'applicantTracking',
  initialState,
  reducers: {
    resetLoading: (state) => {
      state.loading = false;
    },
    setShowApplicantsWaiting: (state, action) => {
      state.showApplicantsWaiting = action.payload;
    },
    getApplicants: (state) => {
      state.loading = true;
    },
    getPayrollUsers: (state) => {
      state.loading = true;
    },   
    getCustomInterviewEmails: (state) => {
      state.loading = true;
    },
    getInterviewTemplates: (state) => {
      state.loading = true;
    },
    storeInterviewTemplates: (state, action: PayloadAction<InterviewTemplate[]>) => {
      state.loading = false;
      state.interviewTemplates = action.payload;
    },
    getInterviewTemplate: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: TemplateRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeInterviewTemplate: (state, action: PayloadAction<InterviewTemplate | null>) => {
      state.loading = false;
      state.interviewTemplate = action.payload;
    },
    postInterviewTemplate: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: TemplateRequest) => {
        return {
          payload: request,
        };
      },
    },
    deleteInterviewTemplate: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: TemplateRequest) => {
        return {
          payload: request,
        };
      },
    },
    getInterviewQuestions: (state) => {
      state.loading = true;
    },
    storeInterviewQuestions: (state, action: PayloadAction<InterviewQuestion[]>) => {
      state.loading = false;
      state.interviewQuestions = action.payload;
    },
    getInterviewQuestion: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (interviewQuestionId: number) => {
        return {
          payload: interviewQuestionId,
        };
      },
    },  
    storeInterviewQuestion: (state, action: PayloadAction<InterviewQuestion>) => {
      state.loading = false;
      state.interviewQuestion = action.payload;
    },
    postInterviewQuestion: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (interviewQuestion: InterviewQuestion) => {
        return {
          payload: interviewQuestion,
        };
      },
    },
    deleteInterviewQuestion: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (interviewQuestionId: number) => {
        return {
          payload: interviewQuestionId,
        };
      },
    },
    getInterviewQuestionsReport: (state) => {
      state.loading = true;
    },
    getInterviewLocations: (state) => {
      state.loading = true;
    },
    storeInterviewLocations: (state, action: PayloadAction<InterviewLocation[]>) => {
      state.loading = false;
      state.interviewLocations = action.payload;
    },
    getInterviewLocation: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (interviewQuestionId: number) => {
        return {
          payload: interviewQuestionId,
        };
      },
    },
    storeInterviewLocation: (state, action: PayloadAction<InterviewLocation>) => {
      state.loading = false;
      state.interviewLocation = action.payload;
    },
    postInterviewLocation: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (interviewQuestion: InterviewLocation) => {
        return {
          payload: interviewQuestion,
        };
      },
    },
    deleteInterviewLocation: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (interviewQuestionId: number) => {
        return {
          payload: interviewQuestionId,
        };
      },
    },
    getScorecardCategories: (state) => {
      state.loading = true;
    },
    storeScorecardCategories: (state, action: PayloadAction<ScorecardCategory[]>) => {
      state.loading = false;
      state.scorecardCategories = action.payload;
    },
    getScorecardCategory: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (scorecardCategoryId: number) => {
        return {
          payload: scorecardCategoryId,
        };
      },
    },
    storeScorecardCategory: (state, action: PayloadAction<ScorecardCategory | null>) => {
      state.loading = false;
      state.scorecardCategory = action.payload;
    },
    postScorecardCategory: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: ScorecardCategory) => {
        return {
          payload: request,
        };
      },
    },
    deleteScorecardCategory: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (scorecardCategoryId: number) => {
        return {
          payload: scorecardCategoryId,
        };
      },
    },
    getScorecardTraits: (state) => {
      state.loading = true;
    },
    storeScorecardTraits: (state, action: PayloadAction<ScorecardTrait[]>) => {
      state.loading = false;
      state.scorecardTraits = action.payload;
    },
    getScorecardTrait: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (scorecardTraitId: number) => {
        return {
          payload: scorecardTraitId,
        };
      },
    },
    storeScorecardTrait: (state, action: PayloadAction<ScorecardTrait | null>) => {
      state.loading = false;
      state.scorecardTrait = action.payload;
    },
    postScorecardTrait: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: ScorecardTrait) => {
        return {
          payload: request,
        };
      },
    },
    deleteScorecardTrait: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (scorecardTraitId: number) => {
        return {
          payload: scorecardTraitId,
        };
      },
    },
    getInterviewTypes: (state) => {
      state.loading = true;
    },
    storeInterviewTypes: (state, action: PayloadAction<InterviewType[]>) => {
      state.loading = false;
      state.interviewTypes = action.payload;
    },
    postInterviewType: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: InterviewType) => {
        return {
          payload: request,
        };
      },
    },
    deleteInterviewType: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: InterviewType) => {
        return {
          payload: request,
        };
      },
    },
    getCustomEmails: (state) => {
      state.loading = true;
    },
    storeCustomEmails: (state, action: PayloadAction<CustomEmail[]>) => {
      state.loading = false;
      state.customEmails = action.payload;
    },
    getCustomEmail: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (customEmailId: number) => {
        return {
          payload: customEmailId,
        };
      },
    },
    storeCustomEmail: (state, action: PayloadAction<CustomEmail | null>) => {
      state.loading = false;
      state.customEmail = action.payload;
    },
    postCustomEmail: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: CustomEmail) => {
        return {
          payload: request,
        };
      },
    },
    deleteCustomEmail: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (customEmailId: number) => {
        return {
          payload: customEmailId,
        };
      },
    },
    testCustomEmail: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: TestEmail) => {
        return {
          payload: request,
        };
      },
    },
    deleteApplicationInterview: (state) => {
      state.loading = true;
    },
    sendApplicantInterviewEmails: (state) => {
      state.loading = true;
    },
    sendApplicantOfferEmail: (state) => {
      state.loading = true;
    },
    createInterviewFromProcess: (state) => {
      state.loading = true;
    },
    createApplicantInterviewFromTemplate: (state) => {
      state.loading = true;
    },
    updateApplicantInterview: (state) => {
      state.loading = true;
    },
    updateApplicantEmail: (state) => {
      state.loading = true;
    },
    storeApplicants: (state, action: PayloadAction<Applicant[]>) => {
      state.loading = false;
      state.applicants = action.payload.sort((a, b) => {return (a.dateApplied > b.dateApplied ? 1 : -1);});
    },
    getApplicant: (state) => {
      state.loading = true;
    },
    storeApplicant: (state, action: PayloadAction<Applicant>) => {
      state.loading = false;
      state.applicant = action.payload;
    },
    getJobs: (state) => {
      state.loading = true;
    },
    storeJobs: (state, action: PayloadAction<ClientJobs>) => {
      state.loading = false;
      state.jobs = action.payload;
    },
    getJob: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (jobId: number) => {
        return {
          payload: jobId,
        };
      },
    },
    storeJob: (state, action: PayloadAction<AvailableJob>) => {
      state.loading = false;
      state.job = action.payload;
    },
    createJob: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: AvailableJob) => {
        return {
          payload: request,
        };
      },
    },     
    deleteJob: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (jobId: number) => {
        return {
          payload: jobId,
        };
      },
    },
    updateJobStatus: (state, action: PayloadAction<ClientJobs>) => {
      state.loading = true;
    },
    storeSelectedJob: (state, action: PayloadAction<AvailableJob>) => {
      state.loading = false;
      state.selectedJob = action.payload;
    },
    getInterviewProcesses: (state) => {
      state.loading = true;
    },
    storeInterviewProcesses: (state, action: PayloadAction<InterviewProcess[]>) => {
      state.loading = false;
      state.interviewProcesses = action.payload;
    },
    postInterviewProcess: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (interViewProcess: InterviewProcess) => {
        return {
          payload: interViewProcess,
        };
      },
    },
    storeInterviewProcess: (state, action: PayloadAction<InterviewProcess | null>) => {
      state.loading = false;
      state.interviewProcess = action.payload;
    },
    deleteInterviewProcess: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (interviewProcessId: number) => {
        return {
          payload: interviewProcessId,
        };
      },
    },
    resendApplicantOnboardingEmail: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: ResendOnboardingEmail) => {
        return {
          payload: request,
        };
      },
    }, 
    getATSCustomFields: (state) => {
      state.loading = true;
    },
    storeATSCustomFields: (state, action: PayloadAction<PageCustomField[]>) => {
      state.loading = false;
      state.customFields = action.payload;
    },
    postATSCustomField: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (customField: PageCustomField) => {
        return {
          payload: customField,
        };
      },
    },
    deleteATSCustomField: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (customField: PageCustomField) => {
        return {
          payload: customField,
        };
      },
    },
    putApplicantPagesField: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (designWebsite: DesignWebsite) => {
        return {
          payload: designWebsite,
        };
      },
    },
    putApplicantWaivers: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (designWebsite: DesignWebsite) => {
        return {
          payload: designWebsite,
        };
      },
    },
    putWebsiteImages: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (designWebsite: WebsiteImage) => {
        return {
          payload: designWebsite,
        };
      },
    },
    putSignatureDescription: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (designWebsite: DesignWebsite) => {
        return {
          payload: designWebsite,
        };
      },
    },
    postCustomWaiver: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (customWaiver: WebsiteCustomWaiver) => {
        return {
          payload: customWaiver,
        };
      },
    },
    postWebsiteImage: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (websiteImage: WebsiteImage) => {
        return {
          payload: websiteImage,
        };
      },
    },
    postCheckForConfig: (state) => {
      state.loading = true;
    },
    storeCheckForConfig: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.checkForConfig = action.payload;
    },
    deleteCustomWaiver: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (customWaiver: WebsiteCustomWaiver) => {
        return {
          payload: customWaiver,
        };
      },
    },
    deleteWebsiteImage: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (websiteImage: WebsiteImage) => {
        return {
          payload: websiteImage,
        };
      },
    },
  },
});

export const {
  resetLoading,
  setShowApplicantsWaiting,
  getApplicants,
  getApplicant,  
  storeApplicants,
  storeApplicant,
  getJobs,
  getJob,
  storeJobs,
  storeJob,
  createJob,
  deleteJob,
  updateJobStatus,
  storeSelectedJob,
  getPayrollUsers,
  getCustomInterviewEmails,
  deleteApplicationInterview,
  sendApplicantInterviewEmails,
  sendApplicantOfferEmail,
  getInterviewTemplates,
  storeInterviewTemplates,
  getInterviewTemplate,
  storeInterviewTemplate,
  createInterviewFromProcess,
  createApplicantInterviewFromTemplate,
  updateApplicantInterview,
  updateApplicantEmail,
  getInterviewProcesses,
  storeInterviewProcesses,
  storeInterviewProcess,
  getInterviewQuestionsReport,
  getInterviewQuestions,
  getInterviewQuestion,
  storeInterviewQuestions,
  storeInterviewQuestion,
  postInterviewQuestion,
  deleteInterviewQuestion,
  getInterviewLocations,
  storeInterviewLocations,
  getInterviewLocation,
  storeInterviewLocation,
  postInterviewLocation,
  deleteInterviewLocation,
  getScorecardCategories,
  storeScorecardCategories,
  getScorecardCategory,
  storeScorecardCategory,
  postScorecardCategory,
  deleteScorecardCategory,
  getScorecardTraits,
  storeScorecardTraits,
  getScorecardTrait,
  storeScorecardTrait,
  postScorecardTrait,
  deleteScorecardTrait,
  getInterviewTypes,
  storeInterviewTypes,
  postInterviewType,
  deleteInterviewType,
  getCustomEmails,
  storeCustomEmails,
  getCustomEmail,
  storeCustomEmail,
  postCustomEmail,
  deleteCustomEmail,
  testCustomEmail,
  postInterviewProcess,
  resendApplicantOnboardingEmail,
  postInterviewTemplate,
  deleteInterviewTemplate,
  deleteInterviewProcess,
  getATSCustomFields,
  postATSCustomField,
  deleteATSCustomField,
  putApplicantPagesField,
  putApplicantWaivers,
  putSignatureDescription,
  putWebsiteImages,
  postCheckForConfig,
  postCustomWaiver,
  postWebsiteImage,
  deleteCustomWaiver,
  deleteWebsiteImage,
} = applicantTrackingSlice.actions;
export default applicantTrackingSlice.reducer;
