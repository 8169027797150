import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';
import { Employee, Payroll, PayrollControlTotal, PayrollsUserOptions, TransmittalEmployee, TransmittalSortOrder } from 'core/models';
import { getControlTotalsByOption, getEmployees, getIsFinishedPayroll, getPayrollHasSignedWireOnlyAgreement, getPayrollsUserOptions, getPayrollType } from 'core/store/selectors';
import { getEarningsCodes } from 'core/store/selectors/dropdown.selector';
import CurrentTransmittalModal from './CurrentTransmittal.modal';
import DeductionStatusModal from './DeductionStatus.modal';
import TransmittalControlTotals, { TransmittalControlRefObject } from './TransmittalControlTotals';
import AddTransmittalEmployee from './AddTransmittalEmployee.modal';
import { loadCmTaxEntities } from 'core/store/actions/tax.action';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { getEarningCodes } from 'core/store/selectors/earning-code.selector';
import { loadEarningCodes } from 'core/store/actions/earning-codes.action';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import TransmittalEmployeeItems from './TransmittalEmployeeItemList';
import {
  clearTransmittalCheckFromStore,
  clearTransmittalEmployees,
  handleError,
  loadCheckCodes,
  loadPayroll,
  loadPayrollControlTotal,
  loadPayrollsUserOptions,
  loadTransmittalEmployees,
  postApprovedAccrualReport,
  postPendingAccrualReport,
  resetCheckIndex,
  selectedEmployeeModal,
  sortTransmittalBy,
  storeLatestPayroll,
  storePayrollControlTotal,
  storePayrollType,
  toggleSavingFromModal,
  updateCurrentCTIds,
  updateCurrentTransmittalPage,
  updatePayrollControlTotal,
} from 'core/store/actions';
import { checkIfCheckErrors, handleTransmittalFilter } from '../process-payroll/payroll-card/utilities';
import { prFrequencyOpts } from '../constants';
import InsertPayrollModal from '../process-payroll/modals/InsertPayroll.modal';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { EarningsErrorInfo } from './FormContext';
import TransmittalActionBar from './TransmittalActionBar';
import { createToggleShow } from 'utilities/utilities';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => { return new URLSearchParams(search); }, [search]);
};

const OpenCurrentTransmittal = () => {
  const dispatch = useAppDispatch();
  
  const searchParams = useQuery();
    
  const {
    transmittalEmpsLoading,
    hasDatelines,
    payrollControlTotal: controlTotalFromStore,
  } = useAppSelector(({ payroll }) => payroll);
  const userAccess = useAppSelector((state) => { return state.auth.userAccess; });
  const client = useAppSelector((state) => { return state.client.client; });
  const latestPayroll = useAppSelector(({ payroll }) => payroll.payroll?.find((x) => x.useNewCheckRegister && !x.hasSignedWireOnlyAgreement), shallowEqual);
  const openPayrollStore = useAppSelector((state) => {
    return state.payroll.payroll.filter((a) => a.status !== 'F');
  }, shallowEqual);
  const processedPayrollStore = useAppSelector((state) => {
    return state.payroll.payroll.filter((a) => a.status === 'F');
  }, shallowEqual);
  const payrollTab = useSelector(getPayrollType);
  const employees = useSelector(getEmployees);
  const earningsCode = useSelector(getEarningsCodes);
  const earningCodeSettings = useSelector(getEarningCodes);
  const isFinishedPayroll = useSelector(getIsFinishedPayroll);
  const hasSignedWireOnlyAgreement = useSelector(getPayrollHasSignedWireOnlyAgreement);
  const payrollsUserOptions: PayrollsUserOptions | null = useSelector(getPayrollsUserOptions);
  const orderTransmittalBy = useAppSelector((state) => state.client.clientOptions.options?.[34]?.optionValue);
  // non-nullable store values
  const {
    selectedEmployeeNo,
    selectedCheckIndex,
    payrollSelectedDates: dates,
    transmittalEmployees,
    updatingControlTotal,
    savingCheck,
    hasCheckError,
  } = useAppSelector(({ payroll }) => payroll);

  const [canReset, setCanReset] = useState<boolean>(false);
  const [transEmpState, setTransEmpState] = useState<TransmittalEmployee[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const [currentPayroll, setCurrentPayroll] = useState<Payroll | undefined>();
  const [currentControlTotal, setCurrentControlTotal] = useState<PayrollControlTotal>();
  const [checkIndex, setCheckIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [shouldClose, setShouldClose] = useState(false);
  const [onCloseTransmittal, setOnCloseTransmittal] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const controlTotalBy = useSelector(getControlTotalsByOption);
  const hasMultipleControlTotal = (controlTotalBy === 'Location' || controlTotalBy === 'Department') ? true : false;

  const [show, setShow] = useState({
    transmittalModal: false,
    statusModal: false,
    addEmployee: false,
    controlTotals: false,
    payrollInsert: false,
    confirmation: false,
    timeOff: false,
  });
  
  const toggleShow = createToggleShow(setShow);
  
  const [dirtyCheck, setDirtyCheck] = useState(false);
  const [triggerSave, setTriggerSave] = useState(false);
  const [earningsError, setEarningsError] = useState<EarningsErrorInfo>({ empNo: -1,
    checkIndex: -1,
    earningsItemId: 0,
  }); // can make this more extensible if needed
  const [hasCheckErrors, setHasCheckErrors] = useState<boolean>(false);
  const [transmittalFilter, setTransmittalFilter] = useState<'U' | 'R' | 'E' | 'CE' | null>(null);
  const [exactMatch, setExactMatch] = useState<boolean>(false);  
  
  const missingEarningsCodeMessage =
    `Missing earnings code on check #${earningsError?.checkIndex + 1} for employee ${earningsError?.empNo}. Click "cancel" to correct this and keep your changes on this check.`;
  
  const transmittalControlTotalRef = useRef<TransmittalControlRefObject>(null);
  const searchQueryRef = useRef<string | null>(null);

  const history = useHistory();
  
  useEffect(() => {
    /*PI-8617 We want to load this on inital load not date change because the date object is not set when clicking the open transmittal from home screen.
      These are needed to display the transmittal checks properly.*/ 
    dispatch(loadCheckCodes());
    dispatch(loadCmTaxEntities());
    dispatch(loadEarningCodes());
    dispatch(loadPayrollsUserOptions());
    
    return () => {
      dispatch(clearTransmittalCheckFromStore());
      dispatch(toggleSavingFromModal(false));
    }; // clear the check to avoid reusing on a different transmittal
  }, []);
  
  useEffect(() => {
    onSearchSubmit(handleTransmittalFilter(transmittalEmployees, transmittalFilter), exactMatch, true);
  }, [transmittalFilter, transmittalEmployees]);

  useEffect(() => {
    const hasErrors = checkIfCheckErrors(transmittalEmployees);
    if (hasCheckError === null) return setHasCheckErrors(hasErrors);
    setHasCheckErrors(hasCheckError);
  }, [hasCheckError, transmittalEmployees]);
  
  useEffect(() => {
    if (!shouldClose) return;
    history.push('/process-payroll');
  }, [shouldClose]);
  
  useEffect(() => {
    if (dates?.length < 2) return;

    dispatch(
      loadPayroll({
        beginDate: dates[0].toString(),
        endDate: dates[1].toString(),
        byCheckDate: true,
      }),
    );
    
    return () => {
      dispatch(clearTransmittalEmployees());
      dispatch(sortTransmittalBy(null));
    };
  }, [dates]);

  useEffect(() => {
    setIsReadOnly(isFinishedPayroll || hasSignedWireOnlyAgreement);
  }, [isFinishedPayroll, hasSignedWireOnlyAgreement]);

  useEffect(() => {
    const payrollHistoryId = +(searchParams?.get('payrollHistoryId') || 0);
    const controlTotalId = +(searchParams?.get('controlTotalId') || 0);
    
    if (payrollHistoryId > 0 && controlTotalId > 0) {
      dispatch(updateCurrentCTIds({ payrollHistoryId, controlTotalId }));
      
      switch (payrollTab) {
        case 'O': {
          const selectedOpenPayroll = openPayrollStore
            .find((payroll: Payroll) => { return payroll.useNewCheckRegister && payroll.payrollHistoryId === payrollHistoryId; });
          const selectedOpenControlTotal = selectedOpenPayroll?.controlTotals
            .find((controlTotal: PayrollControlTotal) => { return controlTotal.controlTotalId === controlTotalId; });
          setCurrentPayroll(selectedOpenPayroll);
          setCurrentControlTotal(selectedOpenControlTotal);
          break;
        }
        case 'F': {
          const selectedFinishedPayroll = processedPayrollStore
            .find((payroll: Payroll) => { return payroll.useNewCheckRegister && payroll.payrollHistoryId === payrollHistoryId; });
          const selectedFinishedControlTotal = selectedFinishedPayroll?.controlTotals
            .find((controlTotal: PayrollControlTotal) => { return controlTotal.controlTotalId === controlTotalId; });
          setCurrentPayroll(selectedFinishedPayroll);
          setCurrentControlTotal(selectedFinishedControlTotal);
          break;
        }
      }
    } else if (latestPayroll) {
      setCurrentPayroll(latestPayroll);
      setCurrentControlTotal(latestPayroll.controlTotals[0]);
      //PI-8704 Later on in TransmittalControlTotal we use the stores state not the local state passed in. So we need to make sure the local state matches the store state.
      //Note: The above Open and Finished tabs load the control totals when the Open Transmittal button is clicked but we cant here as we dont have the Ids since they just click the Open Current Transmittal Button.
      dispatch(storePayrollControlTotal({ controlTotals: latestPayroll.controlTotals, payrollHistoryId: latestPayroll.payrollHistoryId }));
      //PI-8337 If the Id is not in the route they clicked Open Current Transmittal and state should be open (Since it always just opens the first open payroll)
      dispatch(storePayrollType({ tab: 'O' }));
    }
  }, [latestPayroll, processedPayrollStore, openPayrollStore]);

  useEffect(() => {
    if (selectedEmployeeNo > 0) {
      const emp = employees?.find((e) => { return e.empNo === selectedEmployeeNo; });
      
      if (emp) {
        setSelectedEmployee(emp);
      }

      setCheckIndex(selectedCheckIndex);
      setShow((prev) => {
        return {
          ...prev,
          transmittalModal: true,
        };
      });
    }
  }, [selectedEmployeeNo]);

  useEffect(() => {
    setIsLoaded(!!(currentPayroll && currentControlTotal));
  }, [currentControlTotal, currentPayroll]);

  useEffect(() => {
    if (isLoaded) {
      dispatch(loadTransmittalEmployees({
        params: {
          payrollHistoryId: currentPayroll?.payrollHistoryId ?? 0,
          controlTotalId: currentControlTotal?.controlTotalId ?? 0,
        },
        orderBy: orderTransmittalBy as TransmittalSortOrder,
      }));
    }
  }, [isLoaded]);

  useEffect(() => {
    if (!currentPayroll?.payrollHistoryId) return;
    dispatch(storeLatestPayroll(currentPayroll.payrollHistoryId));
    dispatch(postPendingAccrualReport({ payrollHistoryId: currentPayroll.payrollHistoryId }));
    dispatch(postApprovedAccrualReport({ payrollHistoryId: currentPayroll.payrollHistoryId }));
  }, [currentPayroll?.payrollHistoryId]);

  useEffect(() => {
    if (isReadOnly || !isLoaded || !transmittalEmployees?.length) return;
    //Need to set the 'current user'.......
    const currentUser = userAccess?.dmUserName;
    const currentUserOpenedDate = new Date().toISOString();
    const updatedControlTotal = {
      ...currentControlTotal,
      currentUser,
      currentUserOpenedDate,
      empCount: transmittalEmployees?.length,
    } as PayrollControlTotal;

    dispatch(
      updatePayrollControlTotal({
        payrollHistoryId: currentPayroll?.payrollHistoryId || 0,
        controlTotalId: currentControlTotal?.controlTotalId || 0,
        data: updatedControlTotal,
        preventPayrollsUpdate: true,
      }),
    );
  }, [isLoaded]);
  
  useEffect(() => {
    if (hasDatelines || transmittalEmpsLoading) return;
    setShow((prevState) => ({ ...prevState, confirmation: true }));
  }, [hasDatelines, transmittalEmpsLoading]);
  
  useEffect(() => {
    if (!(updatingControlTotal || savingCheck)) {
      setTimeout(() => {
        setTriggerSave(false);
      }, 200);
    }
  }, [updatingControlTotal, savingCheck]);
  
  const onBalance = (close: boolean) => { 
    if (isReadOnly) return; //PI-8454: don't think we need to alert the user that they can't balance, this should be a soft deny

    //PI-8423 if there are any check errors then we do not want them to be able to balance the payroll.
    if (!controlTotalFromStore) return;
    if (!(currentControlTotal && currentPayroll)) {
      dispatch(handleError('Error balancing transmittal'));
      console.error('Missing currentControlTotal, controlTotalFromStore, or currentPayroll');
      return;
    }
    if (hasCheckErrors) {
      dispatch(handleError('Transmittal has checks with errors. Please fix these to balance the transmittal.'));
      return;
    }
    const lastUser = userAccess?.dmUserName;
    const lastUserClosedDate = new Date().toISOString();
    const currentControlTotalClone = structuredClone(currentControlTotal);
    currentControlTotalClone.controlTotalDouble = controlTotalFromStore.transmittalDouble;
    currentControlTotalClone.controlTotalOtherEarnings = controlTotalFromStore.transmittalOtherEarnings;
    currentControlTotalClone.controlTotalOtherHours = controlTotalFromStore.transmittalOtherHours;
    currentControlTotalClone.controlTotalRegular = controlTotalFromStore.transmittalRegular;
    currentControlTotalClone.controlTotalSpecialDeductions = controlTotalFromStore.transmittalSpecialDeductions;
    currentControlTotalClone.controlTotalTimeHalf = controlTotalFromStore.transmittalTimeHalf;
    currentControlTotalClone.empCount = transmittalEmployees?.length ?? 0;
    currentControlTotalClone.lastUser = lastUser ?? '';
    currentControlTotalClone.lastUserClosedDate = lastUserClosedDate;
    currentControlTotalClone.balanced = true;

    //PI-8973 if they do not have multiple control totals make sure the pay control total flag is true. 
    if(!hasMultipleControlTotal)
      currentControlTotalClone.payControlTotal = true;

    dispatch(
      updatePayrollControlTotal({
        payrollHistoryId: currentPayroll?.payrollHistoryId,
        controlTotalId: currentControlTotal?.controlTotalId,
        data: currentControlTotalClone,
        preventPayrollsUpdate: false,
      }),
    );

    setCurrentControlTotal(currentControlTotalClone);
    setDirtyCheck(false);
    setTriggerSave(false);
    
    if (close) {
      if (earningsError.checkIndex > -1 && !confirm(missingEarningsCodeMessage)) {
        return setOnCloseTransmittal(false);
      }
      setTimeout(() => {
        return history.push('/process-payroll');
      }, 0);
    }
  };
  
  const resetSearch = (emps: TransmittalEmployee[]) => {
    setTransEmpState(emps);
    setCanReset(false);
    searchQueryRef.current = '';
  };

  const onSearchSubmit = (emps: TransmittalEmployee[], exactMatch: boolean, resetOverride?: boolean) => {
    const search = searchQueryRef.current?.toLowerCase();

    if (search?.length) {
      if (Number.isNaN(Number(search))) {
        if (exactMatch) {
          setTransEmpState(emps.filter((x) =>
            [x.firstName.toLowerCase(), x.lastName.toLowerCase()].includes(search),
          ));
        } else {
          setTransEmpState(emps?.filter((x) =>
            x.firstName.toLowerCase().includes(search) || x.lastName.toLowerCase().includes(search),
          ));
        }
      } else {
        if (exactMatch) {
          setTransEmpState(emps?.filter((x) => String(x.empNo) === search));
        } else {
          setTransEmpState(emps?.filter((x) => String(x.empNo).includes(search)));
        }
      }
      dispatch(updateCurrentTransmittalPage(1));
      setCanReset(true);
    } else {
      setTransEmpState(emps);
      setCanReset(false);
    }
  };

  const onFilterEmployeeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!currentPayroll || !currentControlTotal) {
      dispatch(handleError('Error filtering transmittal'));
      console.error('Missing currentControlTotal or currentPayroll');
      return;
    }
    
    //We want to re-load the employees on filter change so any changes will be picked up.
    dispatch(loadTransmittalEmployees({
      params: {
        payrollHistoryId: currentPayroll?.payrollHistoryId ?? 0,
        controlTotalId: currentControlTotal?.controlTotalId ?? 0,
      },
      orderBy: orderTransmittalBy as TransmittalSortOrder,
    }));

    if (['U', 'R', 'E', 'CE'].includes(e.target.value)) {
      setTransmittalFilter(e.target.value as 'U' | 'R' | 'E' | 'CE');
    } else {
      setTransmittalFilter(null);
    }
  };

  const onClose = () => {
    setOnCloseTransmittal(true);
    if (isReadOnly) return setShouldClose(true);
    if (!currentControlTotal) return setShouldClose(true); //If they have no datelines and click close just close dont try to save.
    if (earningsError.checkIndex > -1 && !confirm(missingEarningsCodeMessage)) return setOnCloseTransmittal(false);

    const formValues = transmittalControlTotalRef.current?.getFormValues() as PayrollControlTotal;

    //clear our current user data and updated last user.... -_- 
    const lastUser = userAccess?.dmUserName;
    const lastUserClosedDate = new Date().toISOString();
    const updatedControlTotal = {
      ...currentControlTotal,
      currentUser: '',
      currentUserOpenedDate: null,
      lastUser,
      lastUserClosedDate,
      controlTotalRegular: formValues?.controlTotalRegular,
      controlTotalTimeHalf: formValues?.controlTotalTimeHalf,
      controlTotalDouble: formValues?.controlTotalDouble,
      controlTotalOtherHours: formValues?.controlTotalOtherHours,
      controlTotalOtherEarnings: formValues?.controlTotalOtherEarnings,
      controlTotalSpecialDeductions: formValues?.controlTotalSpecialDeductions,
      empCount: transmittalEmployees?.length,
    } as PayrollControlTotal;

    dispatch(
      updatePayrollControlTotal({
        payrollHistoryId: currentPayroll?.payrollHistoryId || 0,
        controlTotalId: currentControlTotal?.controlTotalId || 0,
        data: updatedControlTotal,
        preventPayrollsUpdate: false,
      }),
    );
    dispatch(updateCurrentCTIds(null));
    dispatch(updateCurrentTransmittalPage(1));
    setShouldClose(true);
  };

  const onSearchChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (!target.value.length) {
      setCanReset(true);
      searchQueryRef.current = target.value;
      return;
    }
    searchQueryRef.current = target.value;
    setCanReset(false);
  };
  
  const onConfirm = (confirm: boolean) => {
    //If they deny adding a new dateline back to the process payroll screen
    if (confirm) history.push('/process-payroll'); 
    setShow((prevState) => {
      return { ...prevState, confirmation: false, payrollInsert: confirm };
    });
  };
  
  const onHideTransmittalModal = () => {
    toggleShow('transmittalModal', false);
    dispatch(resetCheckIndex());
    dispatch(
      selectedEmployeeModal({
        empNo: 0,
        checkIndex,
      }),
    );
  };
  
  const SaveMessage = (): JSX.Element => {
    if (triggerSave || savingCheck) {
      return (
        <>
          <span className="mr-1">Autosaving</span>
          <Spinner
            animation={'border'}
            size="sm"
          />
        </>
      );
    }
    return <></>;
  };

  return (
    <Col>
      <Prompt
        when={!onCloseTransmittal && earningsError.checkIndex > -1}
        message={missingEarningsCodeMessage}
      />
      <Row>
        <Col xs={12}>
          <Row>
            <Col
              xs={12}
              sm={3}
              style={{ maxWidth: '325px' }}
            >
              <div className="dm-page-title">Current Transmittal</div>
            </Col>
            <Col
              xs={12}
              sm={9}
              className="mt-auto pb-2 text-right"
            >
              <TransmittalActionBar
                currentPayroll={currentPayroll}
                currentControlTotal={currentControlTotal}
                canReset={canReset}
                setCanReset={setCanReset}
                transmittalFilter={transmittalFilter}
                onSearchChange={onSearchChange}
                onSearchSubmit={onSearchSubmit}
                resetSearch={resetSearch}
                onFilterEmployeeChange={onFilterEmployeeChange}
                show={show}
                toggleShow={toggleShow}
                exactMatch={exactMatch}
                setExactMatch={setExactMatch}
                isReadOnly={isReadOnly}
              />
            </Col>
          </Row>
          <Col>
            <Row className="d-flex justify-items-center">
              <Col
                xs={12}
                style={{
                  margin: '0 auto',
                  boxSizing: 'border-box',
                  padding: '0',
                }}
              >
                <hr className="dm-page-hr" />
                <div className="dm-panel dm-panel-border px-0">
                  <div
                    className="d-flex flex-row mb-3 w-100 justify-content-between align-items-center"
                    onMouseEnter={() => {
                      setTriggerSave(dirtyCheck);
                    }}
                  >
                    <div className="dm-subtitle2 col-auto mb-0">
                      All Employees
                    </div>
                    <div
                      className="d-flex flex-row float-right"
                    >
                      {!isReadOnly && (
                        <>
                          <div
                            className="d-flex align-items-center mr-3"
                            style={{ color: '#00558c' }}
                          >
                            <SaveMessage />
                          </div>
                          <button
                            className="orange-outline-button mx-1"
                            onClick={() => { onBalance(false); }}
                            disabled={(triggerSave) || savingCheck || !(currentControlTotal && currentPayroll)}
                          >
                            Balance
                          </button>
                          <button
                            className="orange-outline-button mx-1"
                            onClick={() => {
                              onBalance(true);
                              setOnCloseTransmittal(true);
                            }}
                            disabled={(triggerSave) || savingCheck || !(currentControlTotal && currentPayroll)}
                          >
                            Balance &amp; Close
                          </button>
                        </>
                      )}
                      <button
                        type="button"
                        className="btn orange-button mx-1"
                        onClick={onClose}
                        disabled={triggerSave || savingCheck}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                  <TransmittalEmployeeItems
                    payrollHistoryId={currentPayroll?.payrollHistoryId as number}
                    controlTotalId={currentControlTotal?.controlTotalId as number}
                    employees={transEmpState}
                    earningsError={earningsError}
                    setEarningsError={setEarningsError}
                    triggerSave={triggerSave}
                    setTriggerSave={setTriggerSave}
                    dirtyCheck={dirtyCheck}
                    setDirtyCheckState={setDirtyCheck}
                    isReadOnly={isReadOnly}
                  />
                  {currentControlTotal && currentPayroll ? (
                    <TransmittalControlTotals
                      currentControlTotal={currentControlTotal}
                      currentPayroll={currentPayroll}
                      ref={transmittalControlTotalRef}
                      isReadOnly={isReadOnly}
                    />
                  ) : null}
                  {show.transmittalModal && (
                    <CurrentTransmittalModal
                      controlTotalId={controlTotalFromStore?.controlTotalId || 0}
                      payrollHistoryId={currentPayroll?.payrollHistoryId || 0}
                      employee={selectedEmployee as Employee}
                      employees={employees}
                      transmittalParams={{
                        payrollHistoryId: currentPayroll?.payrollHistoryId || 0,
                        controlTotalId: currentControlTotal?.controlTotalId || 0,
                      }}
                      earningsCode={earningsCode}
                      earningCodeSettings={earningCodeSettings}
                      payrollsUserOptions={payrollsUserOptions}
                      checkIndex={checkIndex}
                      show={show.transmittalModal}
                      onHide={onHideTransmittalModal}
                      isReadOnly={isReadOnly}
                    />
                  )}
                  {show.statusModal && (
                    <DeductionStatusModal
                      payrollHistoryId={
                        currentPayroll?.payrollHistoryId || 0
                      }
                      show={show.statusModal}
                      onHide={() => { toggleShow('statusModal', false); }
                      }
                    />
                  )}

                  {show.addEmployee && (
                    <AddTransmittalEmployee
                      emps={employees.filter(
                        (e) => {
                          return transmittalEmployees
                            ?.map((x) => { return x.empNo; })
                            ?.indexOf(e.empNo) === -1;
                        },
                      )}
                      transmittalParams={{
                        payrollHistoryId:
                          currentPayroll?.payrollHistoryId ||
                          0,
                        controlTotalId:
                          currentControlTotal?.controlTotalId ||
                          0,
                      }}
                      show={show.addEmployee}
                      onHide={() => { toggleShow('addEmployee', false); }}
                    />
                  )}
                  {/*PI-8337 if they click current transmittal and there is no datelines prompt them to add one.*/}
                  {show.confirmation && (<ConfirmationModal
                    title="DM Payroll"
                    message={'You do not have an open Payroll.\bDo you want to insert a new payroll?'}
                    show={show.confirmation}
                    backdrop={'static'}
                    onConfirmed={onConfirm}
                    onHide={() => { toggleShow('confirmation', false); }}
                  />
                  )}
                  {client && show.payrollInsert ? <InsertPayrollModal
                    client={client}
                    prFrequencyOpts={prFrequencyOpts}
                    show={show.payrollInsert}
                    onHide={() => { toggleShow('payrollInsert', false); }}
                  /> : null}
                </div>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </Col>
  );
};

export default OpenCurrentTransmittal;
