import { createSelector } from 'reselect';
import {
  Department,
  Dropdown,
  Location,
  PayRate,
  SubDepartment,
  SubDepartment2,
} from '../../models';
import { dropdownFeatureState } from '../reducers';
import { State as DropdownState } from '../reducers/dropdown.reducer';
import { RootState } from '../store';
import { IOption, ISelectMultipleOption } from 'interfaces/IOption';

export const companyBenefitDeductionState = (state: RootState) => {return state.dropdown.companyBenefitDeductionCode;};

export const getAca1095BOriginOfPolicy = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.aca1095BOriginOfPolicy;},
);
export const getAcaOverrideOfferOfCoverage = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.acaOverrideOfferOfCoverage;},
);
export const getAcaOverrideSafeHarbor = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.acaOverrideSafeHarbor;},
);

export const getSafeHarborWaiveCoverage = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.acaSafeHarborWaiveCoverage;},
);

export const getAccrualRules = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.accrualRule;},
);
export const getActiveStatusConditions = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.activeStatusConditions;},
);

export const getBenefitClassCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.benefitClassCode;},
);

export const getBenefitsEligibilityGroups = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.benefitEligibilityGroup.map((pt) => {
      return { ...pt,
        id: +pt.id };
    });
  },
);

export const getCareerLevels = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.careerLevel;},
);
export const getChangeReasons = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.changeReasons;},
);
export const getClockGroups = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.clockGroups;},
);

export const getCommunicationPreferences = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.communicationPreference.map((pt) => {
      return { ...pt,
        id: +pt.id };
    });
  },
);

export const getCompanyBenefitDeductionCodes = createSelector(
  companyBenefitDeductionState,
  (state) => {return state;},
);

export const getDeferredCompCatchUpMethod = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.deferredCompCatchUpMethod;},
);
export const getDeferredCompClassExclusion = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.deferredCompClassExclusion;},
);

export const getDepartments = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.department;},
);
export const getDepartmentsWithoutHome = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.department.filter((d: Department) => {return d.deptCode > -1;});},
);

const deductionCodeState = (state: RootState) => {return state.dropdown.deductionCode;};

export const getDeductionCodes = createSelector(
  [deductionCodeState],
  (state) => {
    return [
      ...state
        .map((d) => {
          return {
            ...d,
            id: d.id,
          };
        })
        .sort((a, b) => {return +a.id - +b.id;}),
    ];
  },
);

export const getAllDeductionCodes = createSelector(
  [deductionCodeState, getCompanyBenefitDeductionCodes],
  (deductionCode, companyBenefitDeductionCode) => {
    return [...deductionCode, ...companyBenefitDeductionCode]
      .map((d) => {
        return { ...d,
          id: d.id };
      })
      .sort((a, b) => {return +a.id - +b.id;});
  },
);

export const getDeductionFrequencies = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.deductionFreq;},
);
export const getDeductionStatuses = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.deductionStatus;},
);
export const getDeductionUnits = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.deductionUnit;},
);
export const getDeductionUnitsWithRates = (rates: PayRate[], clientNo: number | undefined) => {
  return createSelector(dropdownFeatureState, (state: DropdownState) => {
    if (!clientNo) {
      console.error('Error: Client number is undefined when building unit options');
      return state.deductionUnit;
    }
    
    const additionalOpts: Dropdown[] = [];
    
    for (let i = 0; i < Math.min(rates.length, 3); i++) {
      additionalOpts.push({
        clientNo: `${clientNo}`,
        dropdownName: 'DeductionUnit',
        id: `${i + 1}`,
        description: `% Times Rate ${i + 1}`,
        allowChanges: false,
        usesCode: false,
        code: '',
      });
    }
    
    return [...state.deductionUnit, ...additionalOpts];
  });
};
export const getDisplayOnTransmittal = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.displayOnTransmittal;},
); //.map(t => ({ ...t, id: +t.id })));
export const getDivisionCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.divisionCode;},
);

export const getEarningsCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.earningsCode
      .slice(0)
      .sort((e1, e2) => {return (e1.id < e2.id ? -1 : e1.id > e2.id ? 1 : 0);});
  },
);
export const getEducationDegreeCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.educationDegreeCodes.map((pt) => {
      return { ...pt,
        id: +pt.id };
    });
  },
);
export const getEducationMajorCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.educationMajorCodes.map((pt) => {
      return { ...pt,
        id: +pt.id };
    });
  },
);
export const getEducationSchools = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.educationSchools.map((pt) => {
      return { ...pt,
        id: +pt.schoolId,
        schoolId: +pt.schoolId };
    });
  },
);
export const getEEOCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.eeoCode;},
);
export const getEEOOccupGroups = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.eeoOccupationalGroup;},
);
export const getEEOUnitNos = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.eeoUnitNo;},
);
export const getFederalBlsSocCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.federalBlsSocCodes;},
)
export const getEmployeeStatus = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.employeeStatus;},
);
export const getEmploymentTypes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.employmentType?.map((jt) => {
      return { ...jt,
        id: +jt.id };
    });
  },
);
export const getEmpMasterCustomFields = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.empMasterCustomFields;},
);
export const getExitInterviewQuestions = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.exitInterviewQuestion?.map((jt) => {
      return { ...jt,
        id: +jt.id };
    });
  },
);
export const getSkillSourceCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.empSkillSourceCodes?.map((jt) => {
      return { ...jt,
        id: +jt.id };
    });
  },
);
export const getSkillTypes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.empSkillTypes?.map((jt) => {
      return { ...jt,
        id: +jt.id };
    });
  },
);
export const getEPrescribe = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.ePrescribe;},
);
export const getExemptStatuses = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.exemptStatus;},
);

export const getFederalTaxEntity = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.federalTaxEntity;},
);
export const getForeignCountries = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.foreignCountry;},
);

export const getGarnishmentFIPsCode = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.garnishmentFIPsCode;},
);
export const getGarnishmentEntity = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return [
      {
        dropdownName: 'GarnishmentEntity',
        id: '0',
        description: 'No Entity',
        code: '0',
      },
      ...state.garnishmentEntity,
    ];
  },
);
export const getFilteredGarnishmentEntity = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    const firstItem = state.garnishmentEntity.filter((item) => {return item.description === 'FD';});
    const lastItem = state.garnishmentEntity.filter((item) => {return item.description === 'PR';});
    const filteredEntities = state.garnishmentEntity.filter((item) => {return item.description !== 'FD' && item.description !== 'PR';});

    return ([...firstItem, ...filteredEntities, ...lastItem]);
  },
);
export const getGenders = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.gender;},
);
export const getGeographicLevel = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.geographicLevel;},
);
export const getGEDCMultiSteps = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.gEDCMultiStep;},
);
export const getGEDCDocCompCareerLevels = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.gEDCDocCompCareerLevel;},
);
export const getGEDCDocCompContractVersion = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.gEDCDocCompContractVersion;},
);
export const getGEDCAnnualDues = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.gEDCAnnualDues;},
);
export const getGEDCCompensationType = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.gEDCCompensationType;},
);

export const getGroupTermLifeEarningsCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.groupTermLifeEarningsCode
      .slice(0)
      .sort((e1, e2) => {return (e1.id < e2.id ? -1 : e1.id > e2.id ? 1 : 0);});
  },
);

export const getHireReferralSource = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.hireReferralSource.map((pt) => {
      return { ...pt,
        id: +pt.id };
    });
  },
);

export const getHourlyStatuses = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.hourlyStatuses;},
);

export const getI9DocumentTitles = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.i9DocumentTitle;},
);
export const getInactiveStatusConditions = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.inactiveStatusConditions;},
);

export const getJobFamilies = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.jobFamily.map((jf) => {
      return { ...jf,
        id: +jf.id };
    });
  },
);
export const getJobNumbers = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.jobNumbers;},
);
export const getCostCode = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.costCode;},
);
export const getJobTitles = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.jobTitle.map((jt) => {
      return { ...jt,
        id: +jt.id };
    });
  },
);

export const getLayoffCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return [
      { id: null,
        description: '<no Layoff Reason Code>' },
      ...state.layoffCode.map((l: any) => {
        return { ...l,
          id: +l.id };
      }),
    ];
  },
);
export const getLicenseRenewalTypes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.licenseRenewalTypes.map((pt) => {
      return { ...pt,
        id: +pt.id };
    });
  },
);
export const getLocalTaxEntity = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.localTaxEntity;},
);
export const getLocalTaxEntityQuickOnboard = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.localTaxEntityQuickOnboard;},
);
export const getLocalTaxEntityWithCode = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.localTaxEntityWithCode;},
);

export const getLocationOptions = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.location;},
);
export const getLocationOptionsWithoutHome = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.location.filter((l: Location) => {return l.locationCode > -1;});},
);

export const getMaritalStatuses = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.maritalStatus;},
);

export const getNewJobTrainingProgram = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.newJobTrainingProgram;},
);

export const getNonCompete = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.nonCompete.map((pt) => {
      return { ...pt,
        id: +pt.id };
    });
  },
);

export const getNovatimeSettings = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.novatimeSettings;},
);
export const getNovatimeGroup1 = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return [
      { id: null,
        description: '<no NOVAtime Group' },
      ...state.novatimeGroup1.map((ng1) => {
        return { ...ng1,
          id: +ng1.id };
      }),
    ];
  },
);
export const getNovatimeGroup2 = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return [
      { id: null,
        description: '<no NOVAtime Group' },
      ...state.novatimeGroup2.map((ng2) => {
        return { ...ng2,
          id: +ng2.id };
      }),
    ];
  },
);
export const getNovatimeGroup3 = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return [
      { id: null,
        description: '<no NOVAtime Group' },
      ...state.novatimeGroup3.map((ng3) => {
        return { ...ng3,
          id: +ng3.id };
      }),
    ];
  },
);
export const getNovatimeGroup4 = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return [
      { id: null,
        description: '<no NOVAtime Group' },
      ...state.novatimeGroup4.map((ng4) => {
        return { ...ng4,
          id: +ng4.id };
      }),
    ];
  },
);
export const getNovatimeGroup5 = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return [
      { id: null,
        description: '<no NOVAtime Group' },
      ...state.novatimeGroup5.map((ng5) => {
        return { ...ng5,
          id: +ng5.id };
      }),
    ];
  },
);
export const getNovatimeGroup6 = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return [
      { id: null,
        description: '<no NOVAtime Group' },
      ...state.novatimeGroup6.map((ng6) => {
        return { ...ng6,
          id: +ng6.id };
      }),
    ];
  },
);
export const getNovatimeGroup7 = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return [
      { id: null,
        description: '<no NOVAtime Group' },
      ...state.novatimeGroup7.map((ng7) => {
        return { ...ng7,
          id: +ng7.id };
      }),
    ];
  },
);
export const getNovatimeGroup8 = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return [
      { id: null,
        description: '<no NOVAtime Group' },
      ...state.novatimeGroup8.map((ng8) => {
        return { ...ng8,
          id: +ng8.id };
      }),
    ];
  },
);
export const getNovatimeShifts = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.novatimeShift.map((s) => {
      return { ...s,
        id: +s.id };
    });
  },
);
export const getNovatimeClockSettings = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.novaTimeClockSetting;},
);

export const getOtherDateDescription = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return [
      { id: 0,
        description: '<no Other Reason Code>' },
      ...state.otherDateDescription.map((o) => {
        return { ...o,
          id: +o.id };
      }),
    ];
  },
);

export const getCineTrainJobTitle = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.cineTrainJobTitle.map((ops) => {
      return { ...ops,
        id: +ops.id };
    });
  },
);

export const getPaidSickTimeEntities = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.paidSickTimeEntity;},
);
export const getEicCode = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.payrollInfoEicCode;},
);
export const getPayPolicy = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.payPolicy.map((pp) => {
      return { ...pp,
        id: +pp.id };
    });
  },
);
export const getPayPeriods = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.payPeriod;},
);
export const getPayGrades = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.payGrade.map((pg) => {
      return { ...pg,
        id: +pg.id };
    });
  },
);
export const getPayGroups = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.payGroup.map((pg) => {
      return { ...pg,
        id: +pg.id };
    });
  },
);
export const getPayCategories = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.payCategory.map((pc) => {
      return { ...pc,
        id: pc.id };
    });
  },
);
export const getPayClasses = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.payClasses;},
);
export const getPayTypes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.payTypes;},
);
export const getPerformanceActionCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.performanceActionCodes.map((ops) => {
      return { ...ops,
        id: +ops.id };
    });
  },
);
export const getPerformanceIncreaseTypes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.performanceIncreaseTypes.map((ops) => {
      return { ...ops,
        id: +ops.id };
    });
  },
);
export const getPerformanceRatingCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.performanceRatingCodes.map((ops) => {
      return { ...ops,
        id: +ops.id };
    });
  },
);
export const getPerformanceReviewCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.performanceReviewCodes.map((ops) => {
      return { ...ops,
        id: +ops.id };
    });
  },
);
export const getPublicTitles = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.publicTitle.map((pt) => {
      return { ...pt,
        id: +pt.id };
    });
  },
);

export const getSalaryGrades = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.salaryGrade;},
);
export const getSchedulePatterns = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.schedulePatterns;},
);
export const getSchoolTypeCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.schoolTypeCode.map((pt) => {
      return { ...pt,
        id: +pt.id };
    });
  },
);
export const getShiftCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.shiftCode;},
);
export const getShiftPremiums = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.shiftPremium;},
);
export const getShiftPremiumEarningsCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.shiftPremiumEarningsCode;},
);
export const getStaffingReasons = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.staffingReason;},
);
export const getStates = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.state;},
);
export const getStateTaxEntity = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.stateTaxEntity;},
);

export const getSubdepartments = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.subDepartment;},
);
export const getSubdepartmentsWithoutHome = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.subDepartment.filter((d: SubDepartment) => {return d.subDeptCode > -1;});},
);
export const getSubdepartments2 = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.subDepartment2;},
);
export const getSubdepartments2WithoutHome = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.subDepartment2.filter((d: SubDepartment2) => {return d.sub2Code > -1;});},
);

export const getTaxEntities = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.taxEntity;},
);
export const getTen99 = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.ten99;},
);
export const getTermCode = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => { return state.termCode; },
);
export const getTrainingStatusTypes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.trainingStatusTypes.map((jt) => {
      return { ...jt,
        id: +jt.id };
    });
  },
);
export const fanPercentages = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.fanPercentage;},
);
export const taxTitles = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.taxTitle;},
);

export const getVaccinationBrand = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.vaccinationBrand.map((ops) => {
      return { ...ops,
        id: +ops.id };
    });
  },
);

export const getVaccinationDosageTypes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.vaccinationDosageType.map((ops) => {
      return { ...ops,
        id: +ops.id };
    });
  },
);

export const getVaccinationStatus = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.vaccinationStatus.map((ops) => {
      return { ...ops,
        id: +ops.id };
    });
  },
);

export const getVaccinationTypes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {
    return state.vaccinationType.map((ops) => {
      return { ...ops,
        id: +ops.id };
    });
  },
);

export const getVisaStatuses = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.visaType;},
);

export const getWorkerCompCodes = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.workersCompensation;},
);
export const getWorkgroupLevels = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.workgroupLevels;},
);
export const getWorkgroupLocations = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.wgLocations;},
);
export const getWorkgroupDepartments = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.wgDepartments;},
);
export const getWorkgroupTasks = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.wgTasks;},
);
export const getWorkgroupPositions = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.wgPositions;},
);
export const getWorkgroupSupervisors = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.wgSupervisors;},
);

export const filingStatuses = createSelector(
  dropdownFeatureState,
  (state: DropdownState) => {return state.filingStatus;},
);


export const getDropdownOptions = createSelector(
  getDepartments,
  getSubdepartments,
  getSubdepartments2,
  getLocationOptions,
  getStates,
  getPayPeriods,
  getDeductionFrequencies,
  getDeductionCodes,
  getEarningsCodes,
  getDeductionUnits,
  fanPercentages,
  taxTitles,
  filingStatuses,
  getJobTitles,
  (
    depts,
    subdepts,
    subdept2s,
    locations,
    states,
    payPeriods,
    dedFreqs,
    dedCodes,
    earnCodes,
    units,
    fanPercentages,
    taxTitles,
    filingStatuses,
    jobTitles,
  ) => {
    return {
      deptOpts: depts,
      subdeptOpts: subdepts,
      subdept2Opts: subdept2s,
      locationOpts: locations,
      stateOpts: states,
      payPeriodOptions: payPeriods,
      deductionFrequencyOpts: dedFreqs,
      deductionCodeOptions: dedCodes,
      earningsCodesOptions: earnCodes,
      unitOpts: units,
      fanPercentageOptions: fanPercentages,
      taxTitleOptions: taxTitles,
      filingStatusOptions: filingStatuses,
      jobTitlesOpts: jobTitles,
    };
  },
);

export const getDeductionsIOption = createSelector(
  getDeductionCodes,
  (dedCodes) => {
    return dedCodes.map(e => {
      return {label: `${e.id} - ${e.description}`, value: e.id, displayValue: e.id}
    }) as ISelectMultipleOption[]
  }
);

export const getEarningsCodesIOption = createSelector(
  getEarningsCodes,
  (earnCodes) => {
    return earnCodes.map(e => {
      return {label: `${e.id} - ${e.description}`, value: e.id, displayValue: e.id}
    }) as ISelectMultipleOption[]
  }
);

export const getLocationDepartments = createSelector(
  getLocationOptions,
  getDepartments,
  getSubdepartments,
  getSubdepartments2,
  (locations, depts, subdepts, subdept2s) => {
    const defaultLoc = new Location(0, 0, -999);
    const defaultDept = new Department(0, 0, -999);
    const defaultSubDept = new SubDepartment(0, 0, -999);
    const defaultSubDept2 : SubDepartment2 = {
      clientNo: 0,
      subDept2ID: 0,
      clientID: 0,
      sub2Code: -999,
      sub2Desc: '',
      subDepartment2DescWithCode: '',
      deptID: 0,
      subDeptID: 0,
      glAccount: '',
      alphaCode: '',
      quickenClass: '',
    };
    
    return {
      locationOpts: [defaultLoc, ...locations],
      deptOpts: [defaultDept, ...depts],
      subdeptOpts: [defaultSubDept, ...subdepts],
      subdept2Opts: [defaultSubDept2, ...subdept2s],
    };
  },
);

export const getLocationDepartmentsWithoutHome = createSelector(
  getLocationOptionsWithoutHome,
  getDepartmentsWithoutHome,
  getSubdepartmentsWithoutHome,
  getSubdepartments2WithoutHome,
  (locations, depts, subdepts, subdept2s) => {
    return {
      locationOpts: locations,
      deptOpts: depts,
      subdeptOpts: subdepts,
      subdept2Opts: subdept2s,
    };
  },
);

export const getLocationDepartmentsStopValueNo = createSelector(
  getLocationOptionsWithoutHome,
  getDepartmentsWithoutHome,
  getSubdepartmentsWithoutHome,
  getSubdepartments2WithoutHome,
  (locations, depts, subdepts, subdept2s) => {
    return {
      locationOpts: locations,
      deptOpts: structuredClone(depts).filter(x => !x.stop || false),
      subdeptOpts: subdepts,
      subdept2Opts: subdept2s,
    };
  },
);
