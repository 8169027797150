import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class AccrualEdit {
  clientNo = 0;

  empNo = 0;

  protectedEmpNo = '';

  balanceId = 0;

  recordId = 0;

  type = '';

  amount = 0;

  note = '';

  static readonly convProps: PropTypeLists = {
    numberProps: ['clientNo', 'empNo', 'balanceId', 'recordId', 'amount'],
  };

  constructor(
    clientNo: number | string,
    empNo: number | string,
    protectedEmpNo: string,
    balanceId: number | string,
    recordId: number | string,
    type: string,
    props?: { [key: string]: any },
  ) {
    this.clientNo =
            typeof clientNo === 'string' ? parseInt(clientNo) : clientNo;
    this.empNo = typeof empNo === 'string' ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;
    this.balanceId =
            typeof balanceId === 'string' ? parseInt(balanceId) : balanceId;
    this.recordId =
            typeof recordId === 'string' ? parseInt(recordId) : recordId;
    this.type = type;

    props && convToClass(this, props, AccrualEdit.convProps);
  }
}
