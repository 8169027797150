import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  useAppSelector,
  useRadioButtonChangeYesNoProps,
} from '../../../utilities/hooks';
import { ReportInformation } from '../../models/ReportInformation';
import { CommonService } from '../../services';
import { RadioGrp } from '../form-controls';
import { RadioYesNoProps } from '../form-controls/RadioGrp';
import Modal from '../modals/Modal';
import PDFViewerModal from '../modals/pdf-viewer.modal';

interface ReportModalProps extends ReportInformation {
  onHide: () => void;
}

interface ReportForm {
  reportType: string;
}

const ReportModal = (props: ReportModalProps) => {
  const dispatch = useDispatch();

  const report = useAppSelector((state) => { return props.selector(state.payroll); });

  const [fs] = useState<RadioYesNoProps[]>([
    {
      name: 'reportType',
      label: 'Report file type :',
      defaultChecked: 'Pdf',
      groupClass: 'gc95',
      radioOptions: [
        {
          value: 'Pdf',
          label: 'Pdf',
        },
        {
          value: 'Excel',
          label: 'Excel',
        },
      ],
    },
  ]);

  const [handleRadioButtonChange] = useRadioButtonChangeYesNoProps(fs);
  const { register, handleSubmit, watch, formState } = useForm<ReportForm>(
    {},
  );

  const reportType = watch('reportType');

  useEffect(() => {
    if(reportType === "Excel") 
          report && hideModal();
    else 
          report;
  }, [report]);

  //UPDATE: PDF option will now use viewer instead of download instantly 
  //excel will still download instantly. This change was made in here since this should always be the case.
  const hideModal = () => {
    if (report && props.show) {
      if ((reportType || 'Excel') === 'Excel')
       CommonService.downloadBase64File(
          'application/excel',
          report,
          `${props.downloadName}.xlsx`,
        );
    }
    dispatch(props.clearDispatch.apply(null));
    props.onHide();
  };

  const onSubmit = (data: ReportForm) => {
    const payload = {
      ...props.action.payload,
      reportType: data.reportType,
    };
    dispatch({
      ...props.action,
      payload,
    });
  };
  return (
    <Modal
      show={props.show}
      backdrop="static"
      title={props.title}
      onHide={hideModal}
    >
      {(closeModal) => {
        return (
          <>
            {formState.isSubmitted && report === null && (
              <Alert variant="warning">
                No data came back from the server.
              </Alert>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <RadioGrp
                ref={register}
                {...fs.find((a) => { return a.name === 'reportType'; })}
                onChange={handleRadioButtonChange}
                name="reportType"
              />
              <div className="row mt-3">
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn btn-primary orange-outline-button"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-auto">
                  <button className="btn btn-primary orange-button">
                    Run
                  </button>
                </div>
              </div>
            </form>
            {
            <PDFViewerModal
              show={report?.length > 0 && reportType === "Pdf"}
              pdfData={report}
              onHide={hideModal}
              reportName={props.downloadName}
            />
      }
          </>
        );
      }}
    </Modal>
  );
};

export default ReportModal;
