import Icon from 'core/components/shared/Icon';
import React from 'react';
import { Modal } from 'react-bootstrap';

type PropTypes = {
  show: boolean;
  onContinue: () => void;
  onHide: () => void;
};
const OnboardSuccessModal: React.FC<PropTypes> = ({ show, onContinue, onHide }) => {
  const closeModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onHide();
  };

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        size="sm"
        animation={false}
      >
        <div className="modal-header" >
          <div className="dm-card-title">Success!</div>
          <button
            onClick={closeModal}
            className="modal-close-btn"
          >
            <Icon name="times" />
          </button>
        </div>
        <Modal.Body>
          <p>The employee master record has been successfully created and onboarding credentials have been sent.</p>
          <p className="mt-3">Would you like to select employees to receive 'Onboarding Status' updates via email?</p>

          <div className="row mt-4">
            <div className="col-12 text-right">
              <button
                className="orange-outline-button"
                onClick={closeModal}
              >No</button>
              <button
                className="orange-button ml-2"
                onClick={onContinue}
              >Yes</button>

            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div >
  );
};
export default OnboardSuccessModal;
