import { createAsyncThunk } from "@reduxjs/toolkit";
import { WorkFlowTask, WorkFlowTaskStatusRequest } from "core/models/WorkFlow.model";
import { WorkFlowService } from "core/services/work-flow.service";
import { handleError, handleSuccess } from "./app.action";
import { loadEmployee } from "./employee.action";
import { loadEmployees } from "./employees.action";

export const getWorkFlowTaskList = createAsyncThunk(
    'workFlow/getWorkFlowTasks',
    async (_payload, { dispatch }) => {
      try {
        const response = await WorkFlowService.getWorkFlowTaskList();
        
        return response?.data || response;
      } catch (err) {
        dispatch(handleError(err));
      }
    },
  );

export const postWorkFlowTask = createAsyncThunk(
  'workFlow/postWorkFlowTask',
  async (workFlowRequest: WorkFlowTask, { dispatch }) => {
    try {
      const response = await WorkFlowService.postWorkFlowTask(workFlowRequest);
      
      dispatch(getWorkFlowTaskList());
      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));
    }
  },
);  

export const postCreateDefaultWorkFlowLevelUser = createAsyncThunk(
  'workFlow/postCreateDefaultWorkFlowLevelUser',
  async (_payload, { }) => {
    try {
      const response = await WorkFlowService.postCreateDefaultWorkFlowLevelUser();
      
      return response?.data || response;
    } catch (err) {
      //Log the error but do not show it.
      console.log(err);
    }
  },
);  
  
export const putWorkFlowTaskStatus = createAsyncThunk(
  'workFlow/putWorkFlowTasksStatus',
  async (workFlowRequest: WorkFlowTaskStatusRequest, { dispatch }) => {
    try {
      const response = await WorkFlowService.putWorkFlowTaskStatus(workFlowRequest);
     
      dispatch(handleSuccess('Work Flow Status Updated Successfully.'));
      dispatch(getWorkFlowTaskList());
      dispatch(loadEmployees()) //TODO: Im going to wait until the new Grid is done. But having the store in the loadEmployee refreshes the grid every time an employee is clicked.
      return response?.data || response;
    } catch (err) {
      dispatch(handleError(err));
    }
  },
);

