import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  ResponsiveContainer,
  Text,
} from 'recharts';
import { useBreakpoint } from 'utilities/BreakpointProvider';
import { useAppSelector } from 'utilities/hooks';
import { convToDateString } from 'utilities/utilities';

type Props = {
  visible: boolean;
};

const CustomizedAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        dy={16}
        fill="#666"
        className="dm-x-axis-tick-value"
      >
        {payload.value}
      </text>
    </g>
  );
};

const GrossPayAnalysisChart = (props: Props) => {
  const [xTickMargin, setXTickMargin] = useState({
    top: 15,
    right: 10,
    left: 10,
    bottom: 5,
  });
  const [colStyle, setColStyle] = useState({ width: '100%',
    height: 300 });

  const breakpoints: any = useBreakpoint();
  useEffect(() => {
    if (breakpoints.md2) {
      setXTickMargin({ top: 15,
        right: 10,
        left: 10,
        bottom: 50 });
      setColStyle({ width: '100%',
        height: 350 });
    } else {
      setXTickMargin({ top: 15,
        right: 10,
        left: 10,
        bottom: 5 });
      setColStyle({ width: '100%',
        height: 300 });
    }
  }, [breakpoints]);

  const empGrossAnalysis = useAppSelector(
    (state) => {return state.dashboard.empGrossAnalysis;},
  );

  const CustomizedLabelB = () => {
    return (
      <Text
        x={0}
        y={0}
        dx={-210}
        dy={10}
        textAnchor="start"
        width={180}
        transform="rotate(-90)"
      >
        Employee Count
      </Text>
    );
  };

  if (!props.visible) return null;
  return (
    <div className="gross-pay-ayalysis-chart-page dm-card">
      {empGrossAnalysis ? (
        <div className="dm-card-body">
          <div className="dm-card-body-title">
            <div>GROSS PAY ANALYSIS</div>
          </div>

          <hr />

          <div className="dm-card-content">
            <Row>
              <Col style={colStyle}>
                <div className="dm-text-primary font-weight-bold text-center">{`Week Ending: ${convToDateString(
                  empGrossAnalysis.weekEnd,
                )} - Check Date: ${convToDateString(
                  empGrossAnalysis.checkDate,
                )}`}</div>
                <ResponsiveContainer
                  width="99%"
                  height="99%"
                >
                  <BarChart
                    margin={xTickMargin}
                    data={
                      empGrossAnalysis.grossPayAnalysisSummaries
                    }
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="ranges"
                      interval={0}
                      tick={CustomizedAxisTick}
                    />
                    <YAxis
                      label={<CustomizedLabelB />}
                      tickFormatter={(value) => {return value;}}
                    />                   
                    <Tooltip />
                    <Bar
                      dataKey="count" 
                      fill="#62bed2"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Col>
            </Row>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default GrossPayAnalysisChart;
