import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CheckboxGrpInLine, RadioGrp } from 'core/components/form-controls';
import InputReportDateSelector from 'core/components/form-controls/InputReportDateSelector';
import { Client, DtoOptions } from 'core/models';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';
import { GeneralLedgerReport, GeneralLedgerReportRequest } from 'core/models/AccountingReports.model';
import { clearGeneralLedgerDetailReport, clearGeneralLedgerExportReport, clearGeneralLedgerReport, clearGeneralLedgerReportErrors, downloadGeneralLedgerReport, downloadGeneralLedgerReportErrors } from 'core/store/actions';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import AlertModal from 'core/components/modals/alert-modal.modal';

const groupByOptions: RadioOptions[] = [
  {
    value: 'false',
    label: 'Company',
  },
  {
    value: 'true',
    label: 'Location',
  },
];

const GeneralLedgerReportPage = () => {
  const dispatch = useAppDispatch();
  
  const history = useHistory();
  
  const childRef: React.RefObject<any> = useRef();

  useEffect(() => {
    dispatch(clearGeneralLedgerReport());
    dispatch(clearGeneralLedgerReportErrors());
    dispatch(clearGeneralLedgerDetailReport());
    dispatch(clearGeneralLedgerExportReport());
  }, []);

  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [fileType, setFileType] = useState<string>('pdf');
  const client = useAppSelector((state) => {return state?.client?.client;}) as Client;
  const errors = useAppSelector((state) => {return state?.accountingReports?.generalLedgerReportErrors;}) as string;
  const reportData = useAppSelector((state) => {return state?.accountingReports?.generalLedgerReport;}) as GeneralLedgerReport;
  const [disableMultiDateSelect, setDisableMultiDateSelect] = useState<boolean>(false);

  const { register, handleSubmit } = useForm<GeneralLedgerReportRequest>({
    defaultValues: {
      groupByLoc: 'false',
    },
  });

  //Will show the error if there is one if not will display the report if there is one. 
  useEffect(() => {
    if(reportData && !!!errors){
      history.push('/general-ledger-report-viewer');
    }
  }, [reportData]);

  const downloadReport = (data: GeneralLedgerReportRequest) => {  
    const submitData: GeneralLedgerReportRequest = {
      clientNo: client.clientNo,
      dtoOptions: reportDates,
      reportType: fileType,
      showEmployees: data.showEmployees,
      groupByLoc: data.groupByLoc === 'true' ? true : false,
      byWeekendCheckDate: data.byWeekendCheckDate,
      exportByCostCenter: data.exportByCostCenter,
    };
    dispatch(downloadGeneralLedgerReportErrors(submitData));
    dispatch(downloadGeneralLedgerReport(submitData));
  };

  //If the user confirms the error then we will still show the report. 
  const confirmError = () => {
    dispatch(clearGeneralLedgerReportErrors());
    history.push('/general-ledger-report-viewer');
  }

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  return (
    <Row>
      <Col>
        <div className="dm-panel dm-panel-border">
          <form onSubmit={handleSubmit(downloadReport)}>
            <div className="dm-grid-title">General Ledger Report</div>
            <hr className="dm-panel-hr" />
            <div className="d-flex flex-row flex-grow-1">
              <div>
                <InputReportDateSelector
                  ref={childRef}
                  returnDates={(dates: DtoOptions[]) => {return setReportDates(dates);}}
                  disableMultiDateSelect={disableMultiDateSelect}
                />
              </div>
              <div className="d-flex flex-column flex-grow-1">
                <div className="text-right">
                  <button
                    type="button"
                    className="orange-outline-button mr-2"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="orange-button mr-2"
                    onClick={() => {return setFileType('pdf');}}
                    disabled={reportDates.length === 0}
                  >
                    Run Report
                  </button>
                </div>
                <div
                  className="w-50 dm-panel-border p-2"
                  style={{ marginTop: '66px' }}
                >
                  <div className="d-flex flex-column flex-grow-1">
                    <div className="dm-grid-action-title mb-2">Group By</div>
                    <RadioGrp
                      radioOptions={groupByOptions}
                      name="groupByLoc"
                      ref={register()}
                      defaultChecked={true}
                    />
                    <div className="dm-grid-action-title mb-2">Other Options</div>
                    <CheckboxGrpInLine
                      labelFirst={false}
                      name="showEmployees"
                      label="Show Employees"
                      ref={register()}
                    />
                    <CheckboxGrpInLine
                      labelFirst={false}
                      name="byWeekendCheckDate"
                      label="By Week End and Check Date"
                      onChange={(e: any) => {
                        return setDisableMultiDateSelect(e.target.checked);
                      }}
                      ref={register()}
                    />
                    <CheckboxGrpInLine
                      labelFirst={false}
                      name="exportByCostCenter"
                      label="Export by Cost Center"
                      ref={register()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
          {!!errors && (
          <AlertModal
            title="General Ledger Rule Error"
            message= {errors}
            btnText="OK"
            show={!!errors}
            onHide={() => { return confirmError(); }}
          />
           )}
        </div>
      </Col>
    </Row>
  );
};
export default GeneralLedgerReportPage;


