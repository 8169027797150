import React, { useState } from 'react';
import Modal from 'core/components/modals/Modal';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import QuillCustomToolbar from 'core/components/form-controls/QuillCustomToolbar';
import ReactQuill, { Range } from 'react-quill';
import { putSignatureDescription } from 'core/store/slices/applicantTracking.slice';

type Props = {
  show: boolean;  
  onHide: () => void;
};

const modules = {
  toolbar: {
    container: '#toolbar',
  },
};

const SignatureDescriptionModal: React.FC<Props> = ({ show, onHide })=> {
  const designWebsite = useAppSelector((state) => { return state.applicantTrackingV2.designWebsite; });

  const dispatch = useAppDispatch();

  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [signatureDescription, setSignatureDescription] = useState(designWebsite?.signatureDescription);
  const [quillIsDirty, setQuillIsDirty] = useState<boolean>(false);

  const hideModal = () => {
    if (quillIsDirty) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };

  const onSubmit = () => {
    if (signatureDescription && designWebsite) {
      const dw =  { ...designWebsite, 
        signatureDescription: signatureDescription,
      };

      dispatch(putSignatureDescription(dw));  
      setQuillIsDirty(false); 
    }
  };

  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };

  return (
    <Modal
      show={show}
      onHide={hideModal}
      dialogClassName="lg"
      isDirty={quillIsDirty}
      backdrop={'static'}
      title={'Signature Description'}
    >
      <div className="d-flex flex-column flex-grow-1">
        <div className="dm-panel dm-panel-border">           
          <QuillCustomToolbar />
          <ReactQuill
            theme="snow"
            value={signatureDescription}
            modules={modules}
            onChange={(input: string) => { setSignatureDescription(input); }}
            onFocus={() => { setQuillIsDirty(true); }} // change event runs on render... so only mark dirty if they actually change something
          />
        </div>
      </div>
      <div className="d-flex flex-row justify-content-end mt-3">
        <button
          type="button"
          className="orange-outline-button mr-2"
          onClick={hideModal}
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={onSubmit}
          className="orange-button mr-2"
        >
          Save
        </button>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
    </Modal>
  );
};
export default SignatureDescriptionModal;
