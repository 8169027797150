import React from 'react';
import { Labels } from '.';
import styles from './form-controls.module.scss';
interface PropTypes {
  id?: string;
  name: string;
  label?: string;
  tallLabel?: boolean;
  groupClass?: string;
  groupStyle?: object;
  required?: boolean;
  errors?: any;
  disabled?: boolean;
  onChange?: (e: any) => void;
}

export const CheckboxGrp: React.FC<any> = React.forwardRef(
  (
    {
      id,
      name,
      label,
      tallLabel = false,
      groupClass,
      groupStyle,
      required = false,
      errors,
      disabled = false,
      onChange,
      ...extraProps
    }: PropTypes,
    ref: any,
  ) => {
    const groupClass2 = groupClass
      ? 'form-check ' + groupClass
      : 'form-group';
    id = id ?? name;

    return (
      <fieldset
        disabled={disabled}
        className={groupClass2}
        style={groupStyle}
      >
        <Labels
          label={label}
          tallLabel={tallLabel}
          id={id}
          hasError={!!errors}
          required={required}
        />
        <input
          className={
            styles['dm-form-check-input'] +
                        (errors ? ' ' + styles['dm-is-invalid'] : '')
          }
          ref={ref}
          id={id}
          name={name}
          type="checkbox"
          onChange={onChange}
          {...extraProps}
        />
        <small className="text-danger">{errors?.message} </small>
      </fieldset>
    );
  },
);
