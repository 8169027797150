import { createReducer } from '@reduxjs/toolkit';
import { AccrualBalanceRecord, AccrualEarningsCode, AccrualEdit, AccrualManualEntry, AccrualRule, AccrualTimeOffSummary, MarkRequestTaken, TimeOffRequest } from 'core/models';
import {
  loadAccruals,
  storeAccrual,
  storeAccrualEarningsCodes,
  storeAccrualManualEntry,
  storeAccrualRule,
  storeAccruals,
  storeAccrualTimeOffSummary,
  storeMarkTaken,
  storeTimeOffRequests,
} from 'core/store/actions';

export interface State {
  loading: boolean;
  accruals: AccrualBalanceRecord[];
  accrualEdit: AccrualEdit | null;
  accrualManualEntry: AccrualManualEntry | null;
  accrualRule: AccrualRule | null;
  timeOffRequest: TimeOffRequest[];
  markTaken: MarkRequestTaken | null;
  accrualTimeOffSummary: AccrualTimeOffSummary[];
  accrualEarningsCode: AccrualEarningsCode[];
}

export const INITIAL_STATE: State = {
  loading: false,
  accruals: [],
  accrualEdit: null,
  accrualManualEntry: null,
  accrualRule: null,
  timeOffRequest: [],
  markTaken: null,
  accrualTimeOffSummary: [],
  accrualEarningsCode: [],
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadAccruals, (state) => {
      state.loading = true;
    })
    .addCase(storeAccruals, (state, action) => {
      state.loading = false;
      state.accruals = action.payload;
    })
    .addCase(storeAccrual, (state, action) => {
      state.loading = false;
      state.accrualEdit = action.payload;
    })
    .addCase(storeAccrualManualEntry, (state, action) => {
      state.loading = false;
      state.accrualManualEntry = action.payload;
    })
    .addCase(storeAccrualRule, (state, action) => {
      state.loading = false;
      state.accrualRule = action.payload;
    })
    .addCase(storeTimeOffRequests, (state, action) => {
      state.loading = false;
      state.timeOffRequest = action.payload;
    })
    .addCase(storeMarkTaken, (state, action) => {
      state.loading = false;
      state.markTaken = action.payload;
    })
    .addCase(storeAccrualTimeOffSummary, (state, action) => {
      state.loading = false;
      state.accrualTimeOffSummary = action.payload;
    })
    .addCase(storeAccrualEarningsCodes, (state, action) => {
      state.loading = false;
      state.accrualEarningsCode = action.payload;
    })
    .addDefaultCase((state) => state)
  ;
});
