import { AxiosResponse } from "axios";
import { HttpResponse } from '../models';
import { EarningsReportsStandardReportsRequest, EmployeeWageDetailDownloadReportRequest } from '../models/EarningsReports.model';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getStandardReports = (
  request: EarningsReportsStandardReportsRequest,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.POST_EARNINGS_REPORTS_STANDARD_REPORTS(
      CommonService.getProtectedClientNo(),
    ),
    request,
  );
};

const getEmployeeWageDetailDownloadReport = (
  request: EmployeeWageDetailDownloadReportRequest,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.POST_EMPLOYEE_WAGE_DETAIL_REPORT(
      CommonService.getProtectedClientNo(),
    ),
    request,
  );
};

export const EarningsReportsService = {
  getStandardReports,
  getEmployeeWageDetailDownloadReport,
};
