import React, { useEffect, useState } from 'react';
import { ColDef, GridApi, GridOptions } from '@ag-grid-enterprise/all-modules';
import { InputGrp, SelectGrp } from 'core/components/form-controls';
import { RadioGrp, RadioOptions } from 'core/components/form-controls/RadioGrp';
import { getEEOPayrollDates } from 'core/store/actions';
import { DateTime } from 'luxon';
import { agSelectEditor } from 'utilities/ag-grid-editors';
import { agDateRenderer, agLookupRenderer } from 'utilities/ag-grid-renderers';
import AGEditButtonRendererComponent from 'utilities/ag-grid-renderers/AGEditButtonRendererComponent';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { EEOPayrollDate } from 'core/models/HumanCapitalReports.model';
import { AgGridReact } from '@ag-grid-community/react';

type PropTypes = {
  passQuarterDataFromEEO2: (quarter: number) => void,
  passYearDataFromEEO2: (year: number) => void,
  passReportOptionsDataFromEEO2: (rT: string) => void,
  passEEO2ReportData: (reportData: EEOPayrollDate[]) => void
  setDisabledSubmit: React.Dispatch<React.SetStateAction<boolean>>;
};

const eeoReportOptions: RadioOptions[] = [
  {
    value: '0',
    label: 'SeparateReports',
  },
  {
    value: '1',
    label: 'Consolidated Reports',
  },
];

const quarters = [
  {
    id: '1',
    description: '1',
  },
  {
    id: '2',
    description: '2',
  },
  {
    id: '3',
    description: '3',
  },
  {
    id: '4',
    description: '4',
  },

];

const EEOReport2: React.FC<PropTypes> = ({ 
  passQuarterDataFromEEO2, 
  passYearDataFromEEO2, 
  passReportOptionsDataFromEEO2, 
  setDisabledSubmit,
  passEEO2ReportData }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => { return state.auth.userAccess?.secUserId; });
  const eeoPayrollDatesStore = useAppSelector((state) => {return state.eeo.eeoPayrollDates;}) as EEOPayrollDate[];
  const [yearData, setYearData] = useState<number>(new Date().getFullYear());
  const [quarterData, setQuarterData] = useState<number>();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [eeoPayrollDates, setEEOPayrollDatesGridData] = useState<EEOPayrollDate[]>([]);
  const [dates, setDates] = useState([{ label: '',
    description: '' }]);
  const employeesStore = useAppSelector((state) => { return state.humanCapitalReports.demographicAndTurnoverEmployees; });

    
  const getQuarter = () => {
    const today = new Date();
    const quarter = Math.floor((today.getMonth() + 3) / 3);
    return quarter;
  };
    
  useEffect(() => {
    //Populate Dropdown with dates
    const selectDates = [];
    for (let i = 0; i <= 23; i++) {
      selectDates.push({
        label: `${DateTime.fromJSDate(new Date()).year - i}`,
        description: `${DateTime.fromJSDate(new Date()).year - i}`,
      });
    }
    setDates(selectDates);
  

    const currentYear = DateTime.fromJSDate(new Date()).year;
    dispatch(getEEOPayrollDates(currentYear));
    setYearData(currentYear);
    setQuarterData(getQuarter());
  }, []);

  useEffect(() => {  
    eeoPayrollDatesStore.length &&  getQuarterData(getQuarter());
  }, [ eeoPayrollDatesStore]);
  
  useEffect(() => {
    dispatch(getEEOPayrollDates(yearData));    
  }, [yearData]);

    
  const getQuarterData = (data: number) => {
    passQuarterDataFromEEO2(data);
    setQuarterData(data);
    let beginDate:Date;
    let endDate:Date;
    switch (data) {
      case 1:
        beginDate = DateTime.fromObject({ year:yearData,
          month:1 }).startOf('month').toJSDate();       
        endDate = DateTime.fromObject({ year:yearData,
          month:3 }).startOf('month').toJSDate();
        break;
      case 2:
        beginDate = DateTime.fromObject({ year:yearData,
          month:4 }).startOf('month').toJSDate();
        endDate = DateTime.fromObject({ year:yearData,
          month:6 }).startOf('month').toJSDate();
        break;
      case 3:
        beginDate = DateTime.fromObject({ year:yearData,
          month:7 }).startOf('month').toJSDate();
        endDate = DateTime.fromObject({ year:yearData,
          month:9 }).startOf('month').toJSDate();
        break;
      case 4:
        beginDate = DateTime.fromObject({ year:yearData,
          month:10 }).startOf('month').toJSDate();
        endDate = DateTime.fromObject({ year:yearData,
          month:12 }).startOf('month').toJSDate();
        break;
    }

    const cloned = [...eeoPayrollDatesStore];
    const filteredData =  cloned?.filter((d:EEOPayrollDate) => {
      return new Date(d.weekEnd) >= beginDate && new Date(d.checkDate) <= endDate;
    })  ;
    setEEOPayrollDatesGridData(filteredData as EEOPayrollDate[]); 
  };

  const getYearData = (data: number) => {
    passYearDataFromEEO2(data);
    setYearData(data);
    setQuarterData(1); 
  };

  //#region AG grid cols & grid options
  const columnsForLrgGrid: ColDef[] = [{
    field: 'einNumber',
    headerName: 'EIN',
    sortable: true,
    suppressMenu: false,
    editable: false,
    width: 130,
  },
  {
    field: 'einClient',
    headerName: 'EIN Client',
    sortable: true,
    suppressMenu: false,
    editable: false,
    width: 130,
  }, {
    field: 'clientNo',
    headerName: 'Client',
    sortable: true,
    suppressMenu: false,
    editable: false,
    width: 130,
  }, {
    field: 'weekEnd',
    headerName: 'Week End',
    cellRenderer: 'dateRenderer',
    width: 130,
  }, {
    field: 'checkDate',
    headerName: 'Check Date',
    cellRenderer: 'dateRenderer',
    width: 130,
  },
  {

    field: 'select',
    headerName: 'Select',
    editable: false,
    checkboxSelection: true,
    width: 100,
    maxWidth: 130,
  },
  ];

  const gridOptions: GridOptions = {
    columnDefs: columnsForLrgGrid,
    rowSelection: 'multiple',
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    components: {
      lookupRenderer: agLookupRenderer,
      selectEditor: agSelectEditor,
      dateRenderer: agDateRenderer,
    },
    frameworkComponents: {
      editButtonRendererComponent: AGEditButtonRendererComponent,
    },

  };
  //#endregion
  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };


  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      
      if (selectedRows?.length > 0) {
        setDisabledSubmit(false);
      } else {
        setDisabledSubmit(true);
      }
      
      passEEO2ReportData(selectedRows);
    }
  };

  return (
    <div className="d-flex flex-row row-wrap">
      <div>
        <div className="dm-grid-action-title mb-2 mt-3"> WorkForce Snapshot</div>
        <div className="d-flex flex-row">
          <div
            id="selectGrps"
            className="d-flex flex-column"
          >
            <SelectGrp
              name="year"
              label={'Year'}
              options={dates}
              onChange={(data: React.ChangeEvent<HTMLInputElement>) => {return getYearData(+data.target.value);}}
            />
            <SelectGrp
              name="quarter"
              label={'Quarter'}
              options={quarters}
              defaultValue={getQuarter()}
              onChange={(data: React.ChangeEvent<HTMLInputElement>) => {return getQuarterData(+data.target.value);}}
            />           
            <div>
              <div>
                <InputGrp
                  name={'userID'}
                  defaultValue={employeesStore[0].employeeName}
                  label={'User ID'}
                />
              </div>
            </div>
          </div>
          <div
            id="radioBtns"
            className="d-flex"
          >
            <RadioGrp
              groupStyle={{ marginTop: '20px' }}
              radioOptions={eeoReportOptions}
              isVertical={true}
              name={'eeoReportOptions'}
              onChange={(data: React.ChangeEvent<HTMLInputElement>) => {return passReportOptionsDataFromEEO2(data.target.value);}}
              defaultChecked={'0'}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-row flex-grow-1 ">
        <div className="d-flex flex-row row-wrap">
          <div
            className="ag-theme-balham mt-5"
            style={{
              height: '200px',
              width: '750px',
              marginLeft: '60px',
              paddingLeft: '20px',
            }}
          >
            <AgGridReact
              gridOptions={gridOptions}
              rowData={eeoPayrollDates}
              onGridReady={onGridReady}
              onSelectionChanged={onSelectionChanged}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EEOReport2;