import { createReducer } from '@reduxjs/toolkit';
import { ContractorOption } from '../../models';
import { loadContractorOptions, storeContractorOptions } from '../actions';

export interface State {
  loading: boolean;
  contractorOptions: ContractorOption[];
}

export const INITIAL_STATE: State = {
  loading: false,
  contractorOptions: [],
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadContractorOptions.type]: (state) => {return { ...state, loading: true };},
  [storeContractorOptions.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      contractorOptions: action.payload,
    };
  },
});
