import React from 'react';
import { FieldInputSettings  } from 'core/components/form-controls/types';
import {
  InputGrp,
  SelectGrp,
  SelectModalGrp,
} from 'core/components/form-controls';
import PanelHeader from 'core/components/shared/PanelHeader';
import { useSelector } from 'react-redux';
import {
  getEicCode,
  getPayCategories,
  getPayGroups,
  getShiftCodes,
  getShiftPremiums,
} from 'core/store/selectors';
import DropdownOptionForm from 'core/components/form-controls/select-modal/DropdownOptionForm';
import ManageShiftPremiumModal from 'core/components/modals/shift-premium/ManageShiftPremium.modal';
import { getAccess } from 'utilities/utilities';
import { BaseSectionProps } from './PayrollInfoPage';
import { ControlIds } from 'core/constants';


const panelHeaderStyling = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
} as const;

const fs: FieldInputSettings = {
  eicCode: {
    name: 'eicCode',
    label: 'EIC CODE',
    groupClass: 'groupClass20',
    addEmptyValue: true,
  },
  clockNo: {
    name: 'clockNo',
    label: 'CLOCK NUMBER',
    groupClass: 'groupClass15',
  },
  shiftCode: {
    name: 'shiftCode',
    label: 'SHIFT CODE',
    groupClass: 'groupClass20',
    valueField: 'shiftCode',
    labelField: 'shiftCodeLabel',
    noOption: true,
    noOptionValue: ' ',
    addOptionText: 'Shift Code',
    customFormComponent: ManageShiftPremiumModal,
  },
  payCategoryId: {
    name: 'payCategoryId',
    label: 'PAY CATEGORY',
    modalTitle: 'PAY CATEGORIES',
    noOption: true,
    required: true,
    idEditable: true,
    dropdownName: 'PayCategory',
    formComponent: DropdownOptionForm,
    groupClass: 'groupClass20',
    addOptionText: 'Pay Category',
  },
  payGroupId: {
    name: 'payGroupId',
    label: 'PAY GROUP',
    modalTitle: 'PAY GROUPS',
    noOption: true,
    required: true,
    idEditable: true,
    dropdownName: 'PayGroup',
    formComponent: DropdownOptionForm,
    groupClass: 'groupClass20',
    addOptionText: 'Pay Group',
  },
};

const PayrollInfoTimeKeeping: React.FC<BaseSectionProps> = ({
  methods: { register, setValue, errors, control, watch },
  sectionAccess,
}) => {
  const shiftCodeOpts = useSelector(getShiftPremiums);
  const payCategoryOpts = useSelector(getPayCategories);
  const payGroupOpts = useSelector(getPayGroups);
  const eicCodeOpts = useSelector(getEicCode);

  const { shiftCode, shiftPremiumId, payCategoryId, payGroupId } = watch([
    'shiftCode',
    'shiftPremiumId',
    'payCategoryId',
    'payGroupId',
  ]);

  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Time Keeping" />
      <div
        style={panelHeaderStyling}
      >
        <SelectGrp
          {...fs.eicCode}
          {...getAccess(sectionAccess, 6, ControlIds.eicCode)}
          errors={errors.eicCode}
          ref={register}
          options={eicCodeOpts}
        />
        <InputGrp
          {...fs.clockNo}
          {...getAccess(sectionAccess, 6, ControlIds.clockNo)}
          errors={errors.clockNo}
          ref={register}
        />
        <SelectModalGrp
          {...fs.shiftCode}
          {...getAccess(sectionAccess, 6, ControlIds.shiftCode)}
          errors={errors.shiftCode}
          control={control}
          value={shiftCode}
          options={shiftCodeOpts}
          setValue={setValue}
          onChange={(newVal: string | null) => {
            const newShiftPremium = shiftCodeOpts?.find((opt) => opt.shiftCode === newVal)?.shiftPremiumId;
            if (!newShiftPremium) return setValue('shiftPremiumId', '0');
            setValue('shiftPremiumId', newShiftPremium);
          }}
        />
        <input
          name="shiftPremiumId"
          type="hidden"
          value={shiftPremiumId}
          ref={register}
        />
        <SelectModalGrp
          {...fs.payCategoryId}
          {...getAccess(sectionAccess, 6, ControlIds.payCategoryId)}
          errors={errors.payCategoryId}
          control={control}
          value={payCategoryId}
          options={payCategoryOpts}
          setValue={setValue}
        />
        <SelectModalGrp
          {...fs.payGroupId}
          {...getAccess(sectionAccess, 6, ControlIds.payGroupId)}
          errors={errors.payGroupId}
          control={control}
          value={payGroupId}
          options={payGroupOpts}
          setValue={setValue}
        />
      </div>
    </div>
  );
};

export default PayrollInfoTimeKeeping;
