import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { InputGrp, InputGrpCurrency, InputGrpDecimal, SelectGrp } from 'core/components/form-controls';
import DepartmentOptionForm from 'core/components/form-controls/select-modal/DepartmentOptionForm';
import SubDepartment2OptionForm from 'core/components/form-controls/select-modal/SubDepartment2OptionForm';
import SubDepartmentOptionForm from 'core/components/form-controls/select-modal/SubDepartmentOptionForm';
import { FieldInputSettings } from 'core/components/form-controls/types';
import PayRateHistoryModal from 'core/components/modals/PayRateHistory.modal';
import { ControlIds } from 'core/constants';
import { EmployeePayRate } from 'core/models';
import { getClientOptionIsContractor, getDisplayOnTransmittal, getDropdownOptions, getNovatimeClockSettings } from 'core/store/selectors';
import { currencyFormatter, fromCurrency, getAccess, getAllAccess } from 'utilities/utilities';
import { useAppSelector, useEffectOnMount } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';
import TradeSubModal from './TradeSub.modal';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import FuturePayRateModal2 from 'core/components/modals/FuturePayRate2.modal';
import { SelectModalGrpUncontrolled } from 'core/components/form-controls/select-modal/SelectModalGrpUncontrolled';
import InputGrpUncontrolled from 'core/components/form-controls/InputGrpUncontrolled';
import { calculateOneHourSalary, convertRate } from 'utilities/payRateUtilities';

const fs: FieldInputSettings = {
  areaTradeSub: {
    name: '',
  },
  description: {
    name: '',
    label: 'DESCRIPTION',
    groupClass: 'gc20 mw150',
  },
  annualRate: {
    name: '',
    label: 'ANNUAL',
    groupClass: 'gc20 mw150',
    controlId: 162,
  },
  salaryRate: {
    name: '',
    label: 'SALARY',
    groupClass: 'gc20 mw150',
    controlId: 161,
  },
  hourlyRate: {
    name: '',
    label: 'HOURLY',
    groupClass: 'gc20 mw150',
    controlId: 160,
  },
  tranDigit: {
    name: '',
    label: 'DISPLAY',
    groupClass: 'gc20 mw150',
  },
  clock: {
    name: '',
    label: 'CLOCK',
    groupClass: 'gc20 mw150',
  },
  department: {
    name: '',
    label: 'DEPARTMENT',
    groupClass: 'gc20 mw150',
    labelField: 'deptDesc',
    showId: true,
    valueField: 'deptCode',
    modalTitle: 'DEPARTMENTS',
    formComponent: DepartmentOptionForm,
    addOptionText: 'Department',
  },
  subDepartment: {
    name: '',
    label: 'SUB DEPARTMENT',
    groupClass: 'gc20 mw150',
    labelField: 'subDeptDesc',
    showId: true,
    valueField: 'subDeptCode',
    modalTitle: 'SUB DEPARTMENTS',
    formComponent: SubDepartmentOptionForm,
    addOptionText: 'Sub Dept',
  },
  subDepartment2: {
    name: '',
    label: 'SUB DEPARTMENT 2',
    groupClass: 'gc20 mw150',
    labelField: 'sub2Desc',
    showId: true,
    valueField: 'sub2Code',
    modalTitle: 'SUB DEPARTMENTS 2',
    formComponent: SubDepartment2OptionForm,
    addOptionText: 'Sub Dept 2',
  },
  notes: {
    name: '',
    label: 'NOTES',
    groupClass: 'gc100',
  },
};

const salaryPayIntervals = {
  W: 52,
  B: 26,
  S: 24,
  M: 12,
};

const isNanOrNull = (value: any) => {
  return isNaN(value) || value === null; 
};

export type AtsInfo = {
  area: string;
  trade: string;
  sub: string;
  baseRate: number;
};

type PropTypes = {
  payPeriodState: string | null;
  baseValue: 'annualRate' | 'salaryRate' | undefined;
  payRate: EmployeePayRate;
  index: number;
  onDelete: (item: EmployeePayRate) => void;
  updatePayRate: (item: EmployeePayRate) => void;
  updateDirtyState: (newVal: boolean) => void;
  setSelectedPayRate: React.Dispatch<React.SetStateAction<EmployeePayRate | null>>;
  setShowPayRateWorkFlow: (value: React.SetStateAction<boolean>) => void;
  usesPayRateWorkFlow?: boolean;
  openFromModal?: boolean
};

const PayRateItem: React.FC<PropTypes> = ({
  payPeriodState,
  baseValue,
  payRate,
  index,
  onDelete,
  updatePayRate,
  updateDirtyState,
  setSelectedPayRate,
  setShowPayRateWorkFlow,
  usesPayRateWorkFlow = false,
  openFromModal = false
}) => {
  const clientOneHourRate = useAppSelector(state => {return state?.client?.clientOptions?.options?.[274]?.optionValue;});
  const clientFrequency = useAppSelector(state => {return state?.client?.clientOptions?.options?.[10]?.optionValue;});
  const isNovaClient = useAppSelector(({ client }) => client.isNovaClient);

  const { deptOpts, subdeptOpts, subdept2Opts } = useSelector(getDropdownOptions);
  const displayOnTransmittalOpts = useSelector(getDisplayOnTransmittal);
  const novatimeClockSettingOpts = useSelector(getNovatimeClockSettings);
  const isContractor = useSelector(getClientOptionIsContractor);
  const sectionAccess = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections);
  
  const payRateSectionAccess = getAccess(sectionAccess, 2);
  
  // get access map for all fields
  const allPayRateAccessMap = getAllAccess(sectionAccess?.find((x) => x.workItemId === 2));
  
  const [showATSModal, setShowATSModal] = useState<boolean>(false);
  const [selectedATS, setSelectedATS] = useState<AtsInfo>();
  const [showPayRateHistoryModal, setShowPayRateHistoryModal] = useState<boolean>(false);
  const [showFuturePayRateModal, setShowFuturePayRateModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [atsRate, setAtsRate] = useState<string>((fromCurrency(String(payRate.hourlyRate ?? 0)) ?? 0).toFixed(2));
  const [overScale, setOverScale] = useState<number | null>(!isContractor ? null : fromCurrency(String(payRate.overScale ?? 0)) ?? 0);

  /* 
    PI-8608: Since we're no longer using forms in this screen, we can't use the form value to get around a controlled
    input that's formatted. We can't format these input values onChange because the user will have to fight against it
    as they type. Instead, we give the inputs default values and format via references. If we were to just give default
    values without any reference, it wouldn't be possible to update the other fields when one changes (for instance,
    we'd have a hard time changing the hourlyRate field when the user is typing in the annualRate). On top of that, we
    have a lot more control over what happens in the different states of the input (focused --> changed --> blurred).
  */
  const annualInputRef = useRef<HTMLInputElement | null>(null);
  const salaryInputRef = useRef<HTMLInputElement | null>(null);
  const hourlyInputRef = useRef<HTMLInputElement | null>(null);
  // contractor only
  const atsRateInputRef = useRef<HTMLInputElement | null>(null);
  const overScaleInputRef = useRef<HTMLInputElement | null>(null);

  const currentInterval = salaryPayIntervals[(payPeriodState ?? clientFrequency.charAt(0)) as keyof typeof salaryPayIntervals];
  const oneHourSalary = calculateOneHourSalary(clientOneHourRate, clientFrequency, currentInterval);

  const updateFieldsOnRate = (updatedFields: Partial<EmployeePayRate>) => {
    const clone = structuredClone(payRate);
    
    for (const [key, val] of Object.entries(updatedFields)) {
      if (key && key in clone)
        (clone as any)[key] = val as EmployeePayRate[keyof EmployeePayRate];
    }
    
    updatePayRate(clone);
    updateDirtyState(true);
  };
  
  const shouldUpdate = (rate: keyof EmployeePayRate, amount: number): boolean => {
    if (!amount) return false;
    return amount !== payRate[rate];
  };

  const onEditPayRate = () => {
    setSelectedPayRate(payRate);
    setShowPayRateWorkFlow(true)
  }
  
  const onPayPeriodChange = () => {
    if (!(baseValue && payRate?.annualRate && payRate?.salaryRate)) return;
    
    const clone = structuredClone(payRate);
    
    switch (baseValue) {
      case 'annualRate': {
        const dPerHour = convertRate(payRate.annualRate.toString()) / oneHourSalary / currentInterval;
        const newSalary = dPerHour * oneHourSalary;
        
        // update the state we submit
        clone.hourlyRate = dPerHour;
        clone.salaryRate = newSalary;
        clone.hourlyRateNotRounded = dPerHour;
        clone.salaryRateNotRounded = newSalary;
        
        // update the formatted input values we display
        if (hourlyInputRef?.current) hourlyInputRef.current.value = currencyFormatter(dPerHour, 6);
        if (salaryInputRef?.current) salaryInputRef.current.value = currencyFormatter(newSalary);
        
        break;
      }
      case 'salaryRate': {
        const dPerHour = convertRate(payRate.salaryRate.toString()) / oneHourSalary;
        const newAnnual = dPerHour * oneHourSalary * currentInterval;
        
        clone.hourlyRate = dPerHour;
        clone.annualRate = newAnnual;
        clone.hourlyRateNotRounded = dPerHour;
        clone.annualRateNotRounded = newAnnual;
        
        if (hourlyInputRef?.current) hourlyInputRef.current.value = currencyFormatter(dPerHour, 6);
        if (annualInputRef?.current) annualInputRef.current.value = currencyFormatter(newAnnual);
        
        break;
      }
      default:
        break;
    }
    
    updatePayRate(clone);
  };
  
  useEffectOnMount(() => {
    if (!payPeriodState) return;
    onPayPeriodChange();
  }, [baseValue, payPeriodState]);
  
  useEffect(() => {
    if (!isContractor) return;
    setAtsRate(parseFloat(String(convertRate(String(payRate.hourlyRate)) - (payRate.overScale ?? 0))).toFixed(2) ?? '0.00');
    setSelectedATS({
      area: payRate.area ?? '',
      trade: payRate.trade ?? '',
      sub: payRate.sub ?? '',
      baseRate: payRate?.hourlyRate ?? 0,
    });
  }, []);
  
  useEffect(() => {
    if (!isContractor) return;
    const atsRateOverScaleSum = String(+atsRate + +((isNanOrNull(overScale) ? 0 : overScale) ?? 0)); // i hate you
    updateFieldsOnRate({ overScale: overScale });
    updateHourly(atsRateOverScaleSum);
  }, [atsRate, overScale]);

  useEffect(() => {
    if (!selectedATS) return;
    updateFieldsOnRate({
      area: selectedATS?.area,
      trade: selectedATS?.trade,
      sub: selectedATS?.sub,
    });
  }, [selectedATS]);

  // TODO: Reduce to one handler
  //When Annual Rate is typed in 
  const updateAnnual = (newVal: string) => {
    if (!shouldUpdate('annualRate', fromCurrency(newVal) ?? 0)) return;
    if (allPayRateAccessMap?.[ControlIds.annualRate]?.readOnly ?? false) return;

    const dPerHour = convertRate(newVal) / oneHourSalary / currentInterval;
    const updateObj: Partial<EmployeePayRate> = {
      salaryRateNotRounded: dPerHour * oneHourSalary,
      hourlyRateNotRounded: dPerHour,
      annualRateNotRounded: convertRate(newVal),
      salaryRate: dPerHour * oneHourSalary,
      hourlyRate: dPerHour,
      annualRate: convertRate(newVal),
      overScale: overScale //PI-8872 Added it here so when the overscale updates and hits one of these the updated overscale is still sent
    };
    
    if (payRate?.tranDigit === 0) updateObj.tranDigit = 1;
    
    // See comment above definition of these refs. Basically, we're updating the displayed value here without disrupting
    // anything in the data or what the user is doing while still just giving default values to the inputs.
    if (hourlyInputRef?.current) hourlyInputRef.current.value = currencyFormatter(dPerHour, 6);
    if (salaryInputRef?.current) salaryInputRef.current.value = currencyFormatter(dPerHour * oneHourSalary);
    
    updateFieldsOnRate(updateObj);
  };

  //When Salary rate is typed in 
  const updateSalary = (newVal: string) => {
    if (!shouldUpdate('salaryRate', fromCurrency(newVal) ?? 0)) return;
    if (allPayRateAccessMap?.[ControlIds.salaryRate]?.readOnly ?? false) return;

    const dPerHour = convertRate(newVal) / oneHourSalary;
    const updateObj: Partial<EmployeePayRate> = {
      salaryRateNotRounded: convertRate(newVal),
      hourlyRateNotRounded: dPerHour,
      annualRateNotRounded: dPerHour * oneHourSalary * currentInterval,
      salaryRate: convertRate(newVal),
      hourlyRate: dPerHour,
      annualRate: dPerHour * oneHourSalary * currentInterval,
      overScale: overScale //PI-8872 Added it here so when the overscale updates and hits one of these the updated overscale is still sent
    };
    
    if (payRate?.tranDigit === 0) updateObj.tranDigit = 1;
    
    if (hourlyInputRef?.current) hourlyInputRef.current.value = currencyFormatter(dPerHour, 6);
    if (annualInputRef?.current) annualInputRef.current.value = currencyFormatter(dPerHour * oneHourSalary * currentInterval);
    
    updateFieldsOnRate(updateObj);
  };

  //When hourly rate is typed in
  const updateHourly = (newVal: string) => {
    if (!shouldUpdate('hourlyRate', fromCurrency(newVal) ?? 0) && !isContractor) return; // all amount fields disabled for contractors
    if (allPayRateAccessMap?.[ControlIds.hourlyRate]?.readOnly ?? false) return;
    
    const dPerHour = convertRate(newVal);
    const updateObj: Partial<EmployeePayRate> = {
      salaryRateNotRounded: dPerHour * oneHourSalary,
      hourlyRateNotRounded: dPerHour,
      annualRateNotRounded: dPerHour * oneHourSalary * currentInterval,
      salaryRate: dPerHour * oneHourSalary,
      hourlyRate: dPerHour,
      annualRate: dPerHour * oneHourSalary * currentInterval,
      overScale: overScale //PI-8872 Added it here so when the overscale updates and hits one of these the updated overscale is still sent
    };
    
    if (payRate?.tranDigit === 0) updateObj.tranDigit = 2;
    
    if (salaryInputRef?.current) salaryInputRef.current.value = currencyFormatter(dPerHour * oneHourSalary);
    if (annualInputRef?.current) annualInputRef.current.value = currencyFormatter(dPerHour * oneHourSalary * currentInterval);
    
    updateFieldsOnRate(updateObj);
  };

  const onDeleteCheckForExistingRate = (item: EmployeePayRate) => {
    if (item.hasRateOnOpenTransmittal) {
      return setShowConfirmationModal(true);
    } else {
      return onDelete(item);
    }
  };
  
  const confirmClose = (confirmed: boolean) => {
    setShowConfirmationModal(false);
    if (confirmed) {
      onDelete(payRate as EmployeePayRate);
    }
  };
  
  const onAtsRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value.length || isNanOrNull(e.target.value)) {
      setAtsRate('0.00');
    } else {
      setAtsRate(e.target.value.replace(/[^0-9.+-]/g, ''));
    }
    updateDirtyState(true);
  };
  
  const onAtsRateBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const newVal = parseFloat(e.target.value).toFixed(2);
    if (!newVal.length || isNanOrNull(newVal)) {
      e.target.value = '0.00';
      setAtsRate('0.00');
    }
    e.target.value = newVal;
    setAtsRate(newVal);
  };
  
  const updateSelectedTradeSub = (newATS: AtsInfo) => {
    updateDirtyState(true);
    setSelectedATS(newATS);
    setAtsRate(parseFloat(String(convertRate(String(newATS.baseRate)))).toFixed(2) ?? '0.00');
    if (atsRateInputRef?.current) atsRateInputRef.current.value = parseFloat(String(convertRate(String(newATS.baseRate)))).toFixed(2) ?? '0.00';
  };
  
  return (
    <div>
      {showPayRateHistoryModal && (
        <PayRateHistoryModal
          show={showPayRateHistoryModal}
          onHide={() => { setShowPayRateHistoryModal(false); }}
        />
      )}
      {showFuturePayRateModal && payRate.clientNo && payRate.empNo && payRate.protectedEmpNo && payRate.rateId && (
        <FuturePayRateModal2
          clientNo={payRate.clientNo}
          empNo={payRate.empNo}
          protectedEmpNo={payRate.protectedEmpNo}
          rateId={payRate.rateId}
          show={showFuturePayRateModal}
          onHide={() => { setShowFuturePayRateModal(false); }}
          usesPayRateWorkFlow={usesPayRateWorkFlow}
          openFromModal={openFromModal}
        />
      )}
      {showATSModal && selectedATS && (
        <TradeSubModal
          show={showATSModal}
          onHide={() => { setShowATSModal(false); }}
          currentData={selectedATS}
          updateSelectedTradeSub={updateSelectedTradeSub}
        />
      )}
      <div className="d-flex flex-row flex-wrap align-items-center my-2">
        <div
          className="dm-card-subtitle mr-4"
          style={{ fontSize: '18px' }}
        >
          Pay Rate {index + 1}
        </div>
        <button
          type="button"
          className="btn btn-link dm-grid-action-title p-0"
          onClick={() => { return setShowFuturePayRateModal(true); }}
          disabled={payRate.rateId <= 0 || usesPayRateWorkFlow}
        >
          Set Future Pay Rate
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button>
      </div>
      <div className="d-flex flex-wrap">
        <InputGrp
          {...fs.description}
          {...payRateSectionAccess}
          name={`payRates[${index}].description`}
          value={payRate?.description}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            updateFieldsOnRate({ description: e.target.value });
          }}
          readOnly={usesPayRateWorkFlow}
        />
        <InputGrpCurrency
          {...fs.annualRate}
          {...allPayRateAccessMap?.[ControlIds.annualRate]}
          name={`payRates[${index}].annualRate`}
          ref={annualInputRef}
          defaultValue={payRate?.annualRate ?? 0}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { 
            if (!(allPayRateAccessMap?.[ControlIds.annualRate]?.readOnly ?? false))
              updateAnnual(e.target.value); 
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            e.target.value = currencyFormatter(e.target.value);
          }}
          disabled={isContractor || usesPayRateWorkFlow}
        />
        <InputGrpCurrency
          {...fs.salaryRate}
          {...allPayRateAccessMap?.[ControlIds.salaryRate]}
          name={`payRates[${index}].salaryRate`}
          ref={salaryInputRef}
          defaultValue={currencyFormatter(payRate?.salaryRate ?? 0)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { 
            if (!(allPayRateAccessMap?.[ControlIds.salaryRate]?.readOnly ?? false))
              updateSalary(e.target.value); 
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            e.target.value = currencyFormatter(e.target.value);
          }}
          disabled={isContractor || usesPayRateWorkFlow}
        />
        <InputGrpCurrency
          {...fs.hourlyRate}
          decimals={6}
          {...allPayRateAccessMap?.[ControlIds.hourlyRate]}
          name={`payRates[${index}].hourlyRate`}
          ref={hourlyInputRef}
          defaultValue={currencyFormatter(payRate?.hourlyRate ?? 0, 6)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {  
            if (!(allPayRateAccessMap?.[ControlIds.hourlyRate]?.readOnly ?? false))
              updateHourly(e.target.value); 
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            e.target.value = currencyFormatter(e.target.value, 6);
          }}
          disabled={isContractor || usesPayRateWorkFlow}
        />
        {isContractor && (
          <>
            <InputGrpUncontrolled
              {...payRateSectionAccess}
              name={`payRates[${index}].atsRate`}
              label="ATS Rate"
              defaultValue={atsRate}
              ref={atsRateInputRef}
              onChange={onAtsRateChange}
              onBlur={onAtsRateBlur}
            />
            <InputGrpDecimal
              {...payRateSectionAccess}
              name={`payRates[${index}].overScale`}
              label="Over scale"
              groupClass="gc20 mw150"
              ref={overScaleInputRef}
              strValue={(isNanOrNull(overScale) ? '0.00' : String((overScale ?? 0).toFixed(2)))} /* once more, an unfriendly reminder that I seethe at the sight of you */
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setOverScale(+e.target.value);
                updateDirtyState(true);
              }}
              fixed={2}
            />
            <InputGrp
              {...payRateSectionAccess}
              name={`payRates[${index}].ATS`}
              label="AREA-TRADE-SUB"
              value={`${selectedATS?.area ?? payRate.area}-${selectedATS?.trade ?? payRate.trade}-${selectedATS?.sub ?? payRate.sub}`}
              groupClass="gc20 mw150"
              onClick={() => { setShowATSModal(true); }}
            />
          </>
        )}
        <SelectGrp
          {...fs.tranDigit}
          {...payRateSectionAccess}
          name={`payRates[${index}].tranDigit`}
          value={payRate.tranDigit}
          options={displayOnTransmittalOpts}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            updateFieldsOnRate({ tranDigit: parseInt(e.target.value) });
          }}
          readOnly={usesPayRateWorkFlow}
        />
        {isNovaClient && (
          <SelectGrp
            {...fs.clock}
            {...payRateSectionAccess}
            name={`payRates[${index}].clock`}
            value={payRate?.clock}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              updateFieldsOnRate({ clock: parseInt(e.target.value) });
            }}
            options={novatimeClockSettingOpts}
            readOnly={usesPayRateWorkFlow}
          />
        )}
        {deptOpts?.length > 0 && (
          <SelectModalGrpUncontrolled
            {...fs.department}
            {...payRateSectionAccess}
            name={`payRates[${index}].department`}
            addEmptyValue
            prependedOption={{ label: 'Use home dept.',
              value: '-1' }}
            options={deptOpts}
            value={payRate.department}
            onChange={(newValue: any) => {
              updateFieldsOnRate({ department: parseInt(newValue) });
            }}
            portalTargetId={(openFromModal) ? 'payRate-dept-modal' : 'payRate-dept'}
            readOnly={usesPayRateWorkFlow}
          />
        )}
        {subdeptOpts?.length > 0 && (
          <SelectModalGrpUncontrolled
            {...fs.subDepartment}
            {...payRateSectionAccess}
            name={`payRates[${index}].subDepartment`}
            addEmptyValue
            prependedOption={{ label: 'Use home sub dept.',
              value: '-1' }}
            options={subdeptOpts}
            value={payRate.subDepartment}
            onChange={(newValue: any) => {
              updateFieldsOnRate({ subDepartment: parseInt(newValue) });
            }}
            portalTargetId={(openFromModal) ? 'payRate-subDept-modal' : 'payRate-subDept'}
            readOnly={usesPayRateWorkFlow}
          />
        )}
        {subdept2Opts?.length > 0 && (
          <SelectModalGrpUncontrolled
            {...fs.subDepartment2}
            {...payRateSectionAccess}
            name={`payRates[${index}].subDepartment2`}
            addEmptyValue
            prependedOption={{ label: 'Use home sub dept. 2',
              value: '-1' }}
            options={subdept2Opts}
            value={payRate.subDepartment2}
            onChange={(newValue: any) => {
              updateFieldsOnRate({ subDepartment2: parseInt(newValue) });
            }}
            readOnly={usesPayRateWorkFlow}
            portalTargetId={(openFromModal) ? 'payRate-subDept2-modal' : 'payRate-subDept2'}
          />
        )}
        <InputGrp
          {...fs.notes}
          {...payRateSectionAccess}
          name={`payRates[${index}].notes`}
          value={payRate?.notes}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            updateFieldsOnRate({ notes: e.target.value });
          }}
          readOnly={usesPayRateWorkFlow}
        />
      </div>
      <div className="row">
        <div className="col-12 text-right">
        {usesPayRateWorkFlow ?  
          <button
            type="button"
            className="btn btn-link dm-grid-action-title mr-4"
            onClick={() => onEditPayRate()}
          >
            Edit Pay Rate{' '}
            <Icon
              name="pencil"
              className="fa-minus-circle"
            />
          </button> :   
          <button
            type="button"
            className="btn btn-link dm-grid-action-title mr-4"
            onClick={() => { onDeleteCheckForExistingRate(payRate as EmployeePayRate); }}
          >
            Delete this Rate{' '}
            <Icon
              name="minus-circle"
              className="fa-minus-circle"
            />
          </button>
        }
        </div>
      </div>
      {showConfirmationModal ? (
        <ConfirmationModal
          title="Pay Rate on Open Transmittal"
          message={'This pay rate is currently on an open transmittal. Any transmittal records with this pay rate will be removed.\n\nDo you wish to continue?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { setShowConfirmationModal(false); }}
        />
      ) : null}
    </div>
  );
};

export default PayRateItem;
