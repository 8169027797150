import { ClientLibraryEndpoints } from 'core/services/URL';
import { getClientLibrary, getClientFolder, storeCLientLibrary, storeClientFolder } from '../actions/client-library.action';
import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { handleWarning, handleSuccess, handleError } from '../actions';
import { HttpResponse } from '../../models';
import { ClientFolderRequest, ClientLibraryRequest } from 'core/models/ClientLibrary.model';
import { ClientLibraryService } from 'core/services/client-lirbary.service';

interface Actions {
  type: string;
  payload: any;
}

const getClientLibrary$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(getClientLibrary.type),
    switchMap((action) => {
      return from(ClientLibraryService.getClientLibrary(action.payload.year)).pipe(
        map((res) => {return res.data;}),
        map((res: ClientLibraryRequest[]) => {return storeCLientLibrary(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};


// const loadOverTimeSettings$ = (action$: Observable<Actions>) => {
//   return action$.pipe(
//     ofType(loadOverTimeSettings.type),
//     switchMap((action) => {
//       return from(AccountingService.getOverTimeSettings()).pipe(
//         map((res) => {return res.data;}),
//         map((res: OverTimeSetting[]) => {return storeOverTimeSettings(res);}),
//         catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
//       );
//     },
//     ),
//   );
// };

const getClientFolder$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(getClientFolder.type),
    switchMap((action) => {
      return from(ClientLibraryService.getClientFolder(action.payload.folderPath)).pipe(
        map((res) => {return res.data;}),
        map((res: string) => {return storeClientFolder(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};
  

  
export const epics: any[] = [
  getClientLibrary$,
  getClientFolder$,
];