import React from 'react';
import { Employee } from 'core/models';
import EmpSnapshotRow from '../EmpSnapshotRow';
import JobRosterJobInfoSection from './JobRosterJobInfoSection';
import JobRosterTimeEntry from './JobRosterTimeEntry';
import JobRosterRateEarnings from './JobRosterRateEarnings';
import JobRosterEarnings from './JobRosterEarnings';
import { TimeCardJobRosterDetailEmpInfo } from 'core/models/Contractor.model';
import { currencyFormatter } from 'utilities/utilities';

type Props = {
  employee: Employee;
  detailEmployee: TimeCardJobRosterDetailEmpInfo;
  expandAll: boolean;
};

const EmployeeRosterGroup = ({ employee, detailEmployee, expandAll }: Props) => {

  const { details } = detailEmployee;

  //If there is no total show blank instead of 0 to make it easier to see if they have a value or not. -PI-8261
  const totalHours = (detailEmployee.hoursTotal === 0) ? '' : detailEmployee.hoursTotal?.toFixed(2)?.toString();
  const totalEarnings = (detailEmployee.earningsTotal === 0) ? '' : currencyFormatter(detailEmployee.earningsTotal);
  const totalDeductions = (detailEmployee.deductionTotal === 0) ? '' : currencyFormatter(detailEmployee.deductionTotal);

  const totals = [
    { field: 'Check Code', data: detailEmployee.checkCode },
    { field: 'Total Hours', data: totalHours },
    { field: 'Total Earnings', data: totalEarnings },
    { field: 'Total Deductions', data: totalDeductions },
  ];
  
  return (
    <EmpSnapshotRow
      employee={employee}
      viewDeductions={true}
      expandAll={expandAll}
      headerInfo={totals}
      deductions={detailEmployee.deductions}
    >
      <ul style={{ listStyle: 'none', padding: '0', height: '40vh', overflow: 'auto' }}>
        {details?.map((detail) => {
          return (
            <li
              key={detail.transmittalTimeCardId}
              className="time-card"
            >
              <div
                className="time-card-body dm-panel dm-panel-border"
              >
                <JobRosterJobInfoSection jobInfo={detail.jobInfo} />
                <JobRosterTimeEntry hours={detail.hours}/>
                <JobRosterRateEarnings rateEarnings={detail.rateEarnings}/>
                <JobRosterEarnings
                  earnings={detail.earnings}
                  transmittalTimeCardId={detail.transmittalTimeCardId}
                />
              </div>
            </li>
          ); 
        })
        }
      </ul>
    </EmpSnapshotRow>
  );
};

export default EmployeeRosterGroup;