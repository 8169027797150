import React, { useState, useEffect, useRef } from 'react';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { useFieldArray, useForm } from 'react-hook-form';
import PanelHeader from 'core/components/shared/PanelHeader';
import { SelectGrpInLine } from 'core/components/form-controls';
import ACAInfoModal from './ACAInfoModal';
import ACAQuestionsModal from './ACAQuestionsModal';
import ACAStatus from './ACAStatus';
import ACACoveredIndividualItem from './ACACoveredIndividualItem';
import ACAEmployeeInformationOverride from './ACAEmployeeInformationOverride';
import ACAOfferCoverage from './ACAOfferCoverage';
import ACASafeHarbor from './ACASafeHarbor';
import ACAEmpShare from './ACAEmpShare';
import ACAZipCode from './ACAZipCode';
import ACA1095BSection from './ACA1095BSection';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEmpACATabData,
  getEmpACA1095b,
  getAcaOverrideOfferOfCoverage,
  getAcaOverrideSafeHarbor,
  getSelectedEmp,
  getClientACA,
  getClient,
  getSafeHarborWaiveCoverage,
} from 'core/store/selectors';
import {
  deleteACAOverride,
  loadClientACA,
  loadEmployeeACA1095B,
  loadEmployeeACATabData,
  createEmployeeACACoveredIndividuals,
  updateEmployeeACACoveredIndividuals,
  updateACAOverrideEmployeeInfo,
  updateACAOverrideOfferOfCoverage,
  updateACAOverrideEmployeeShare,
  updateACAOverrideSafeHarbor,
  updateACAOverrideOfferZipCode,
  deleteEmployeeACACoveredIndividuals,
  updateEmployeeACAWaiveCoverage,
  toggleBlockNavigation,
  handleError,
} from 'core/store/actions';
import { useParams } from 'react-router-dom';
import {
  ACA,
  ACAWaiveCoverage,
  AcaStatus,
  CoveredIndividual,
  OfferOfCoverage,
} from 'core/models/ACA.model';
import { Client, ClientACA, Employee } from 'core/models';
import { useAppSelector } from 'utilities/hooks';
import styles from 'core/components/form-controls/form-controls.module.scss';
import Icon from 'core/components/shared/Icon';
import { getAccess } from 'utilities/utilities';
import { UNSAVED_MESSAGE } from 'core/constants';

const fs: FieldInputSettings = {
  choose: {
    name: 'choose',
    label: 'Choose the Safe Harbor code you wish to change all instances of 2C to:',
    labelWidth: 80,
    groupClass: 'gc40 mw400',
  },
};

const ACAPage = () => {
  const dispatch = useDispatch();
  const acaStatusRef: React.RefObject<any> = useRef();
  const acaSave1095Ref: React.RefObject<any> = useRef();
  const waiveCoverageRef = useRef<HTMLSelectElement>();
  const { protectedEmpNo } = useParams<{ protectedEmpNo: string; }>();
  const protectedClientNo = useAppSelector(
    (state) => { return state.auth.currentProtectedClientNo; },
  );

  const client = useSelector(getClient);
  const acaTabData = useSelector(getEmpACATabData);
  const aca1095b = useSelector(getEmpACA1095b);
  const clientACAStore = useSelector(getClientACA);
  const selEmp = useSelector(getSelectedEmp);
  const overrideOfferOfCoverageOpts = useSelector(
    getAcaOverrideOfferOfCoverage,
  );
  const overrideSafeHarborOpts = useSelector(getAcaOverrideSafeHarbor);
  const safeHarborWaiveCoverage = useSelector(getSafeHarborWaiveCoverage);

  const [showACAOptions, setShowACAOptions] = useState(false);
  const [showACACodeHelp, setShowACACodeHelp] = useState(false);
  const [clientACA, setClientACA] = useState<ClientACA>();
  const [offerValues, setOfferValues] = useState<OfferOfCoverage | null>(acaTabData?.offerOfCoverage ?? null);
  const [isEligible, setIsEligible] = useState(false);
  const [isFormB, setIsFormB] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  
  //Added a default status so if there are no records we still can display the screen without it crashing.
  const defaultStatus: AcaStatus = {
    clientNo: client?.clientNo ?? 0,
    protectedClientNo: protectedClientNo,
    empNo: selEmp?.empNo ?? 0,
    empId: selEmp?.empId ?? 0,
    protectedEmpNo: selEmp?.protectedEmpNo ?? '',
    effectiveDate: '',
    status: '',
  };

  const { handleSubmit, control, reset, ...formMethods } = useForm<ACA>({
    defaultValues: {
      ...acaTabData,
      employeeShare: acaTabData?.employeeShare,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'coveredIndividuals',
    keyName: 'id',
  });

  useEffect(() => {
    dispatch(loadClientACA({ year: selectedYear }));
  }, [selectedYear]);

  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(
        loadEmployeeACATabData({
          protectedEmpNo,
          prYear: selectedYear,
        }),
      );
      dispatch(
        loadEmployeeACA1095B({
          protectedEmpNo,
          prYear: selectedYear,
        }),
      );
    }
  }, [protectedEmpNo, selectedYear]);

  useEffect(() => {
    if (acaTabData) {
      reset({
        ...acaTabData,
        employeeShare: acaTabData.employeeShare,
        coveredIndividuals: acaTabData.coveredIndividuals,
      });
      setOfferValues(acaTabData.offerOfCoverage);
    }
    
    setIsEligible(acaTabData?.eligible ?? false);
  }, [acaTabData, selectedYear]);

  useEffect(() => {
    if (formMethods.formState) {
      setFormIsDirty(
        Object.keys(formMethods.formState.dirtyFields).length !== 0,
      );
    }
  }, [formMethods.formState]);

  useEffect(() => {
    if (clientACAStore) setClientACA(clientACAStore);
    setIsFormB(clientACAStore?.formBorC === 'FORMB');
  }, [clientACAStore]);

  const getYears = () => {
    if (client && client.startDate) {
      const startYear = new Date().getFullYear() - 1;
      const lastYear = new Date().getFullYear();
      return Array(lastYear - (startYear - 1))
        .fill('')
        .map((_, i) => { return lastYear - i; }) as Array<number>;
    }
  };
  const years = getYears() || [];
  const selectOptions = years.map((year) => {
    return (
      <option key={year}>{year}</option>
    );
  });

  const onViewACAOptions = () => { return setShowACAOptions(true); };

  const onACACodeHelp = () => { return setShowACACodeHelp(true); };

  const onRemoveOverride = () => {
    if (window.confirm('Are you sure you want to remove ACA overrides for this employee in the current payroll year?')) {
      dispatch(
        deleteACAOverride({
          protectedEmpNo,
          prYear: selectedYear,
        }),
      );
    }
  };

  const onAddDependent = () => {
    const dependent = new CoveredIndividual(
      0,
      selEmp?.empId ?? 0,
      selEmp?.empNo ?? 0,
      protectedEmpNo,
    );
    dependent.birthDate = new Date();
    dependent.coveredAll12Months = true;

    append(dependent);
  };

  const onWaiveCoverage = () => {
    if (!acaTabData?.safeHarbor) {
      console.error('acaTabData.safeHarbor is not defined', acaTabData);
      dispatch(handleError('Error with safe harbor'));
      return;
    }
    
    for (const property in acaTabData.safeHarbor) {
      if (property.indexOf('safeHarbor') === 0) {
        if (formMethods.getValues(`safeHarbor.${property}`) === '2C') {
          formMethods.setValue(`safeHarbor.${property}`, waiveCoverageRef.current?.value, { shouldDirty: true });
        }
      }
    }
  };

  const onDeleteCI = (item: CoveredIndividual, index: number) => {
    remove(index);
    if (item.dependentId) {
      dispatch(
        deleteEmployeeACACoveredIndividuals({
          ...item,
          protectedEmpNo,
        }),
      );
    }
  };

  const onSave = () => {
    const values = formMethods.getValues();
    const dirtyFields = formMethods.formState.dirtyFields;
    
    if (!acaTabData) return;
    if (dirtyFields.employeeInformationOverride) {
      dispatch(
        updateACAOverrideEmployeeInfo({
          ...acaTabData.employeeInformationOverride,
          ...values.employeeInformationOverride,
          protectedEmpNo,
        }),
      );
    }
    if (!isFormB) {
      /* PI-8748: Waive coverage call has been removed because this was running concurrently with the safe harbor endpoint and 
      ran into issues trying to access the safe harbor record that was actively being created. Now, "Waive coverage" will simply
      update the necessary form values and the safe harbor endpoint will take care of the rest. */
      if (dirtyFields.offerOfCoverage) {
        dispatch(
          updateACAOverrideOfferOfCoverage({
            ...acaTabData.offerOfCoverage,
            ...values.offerOfCoverage,
            protectedEmpNo,
          }),
        );
      }
      if (dirtyFields.employeeShare) {
        dispatch(
          updateACAOverrideEmployeeShare({
            ...acaTabData.employeeShare,
            ...values.employeeShare,
            protectedEmpNo,
          }),
        );      
      }
      if (dirtyFields.safeHarbor) {
        dispatch(
          updateACAOverrideSafeHarbor({
            ...acaTabData.safeHarbor,
            ...values.safeHarbor,
            protectedEmpNo,
          }),
        );
      }
      if (dirtyFields.zipCode) {
        dispatch(
          updateACAOverrideOfferZipCode({
            ...acaTabData.zipCode,
            ...values.zipCode,
            protectedEmpNo,
          }),
        );
      }
    }
    if (
      dirtyFields.coveredIndividuals &&
      dirtyFields.coveredIndividuals.length > 0
    ) {
      values?.coveredIndividuals?.forEach((c: CoveredIndividual) => {
        const newCI = {
          ...new CoveredIndividual(
            client?.clientNo ?? 0,
            selEmp?.empId ?? 0,
            selEmp?.empNo ?? 0,
            protectedEmpNo,
            c,
          ),
        };
        newCI.year = newCI.year > 0 ? newCI.year : new Date().getFullYear();

        if (newCI.dependentId) {
          dispatch(updateEmployeeACACoveredIndividuals(newCI));
        } else {
          dispatch(createEmployeeACACoveredIndividuals(newCI));
        }
      });
    }
    dispatch(
      loadEmployeeACATabData({
        protectedEmpNo,
        prYear: selectedYear,
      }),
    );

    // use AcaStatus.tsx's onSave function on each field array item.
    acaStatusRef?.current?.onSaveAcaStatuses();
  };

  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: formIsDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [formIsDirty]);
  
  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Employee ACA">
        <div className="dm-grid-action-title pb-0 d-flex">
          <label
            className="dm-grid-action-title mb-0 mr-2"
            htmlFor={'year-select'}
          >
            Select year:{' '}
          </label>
          <select
            id="year-select"
            className={`dm-grid-action-title ${styles['dm-form-control']}`}
            style={{ width: '100px' }}
            defaultValue={selectedYear}
            onChange={(e) => { return setSelectedYear(parseInt(e.target.value)); }
            }
          >
            {selectOptions}
          </select>
        </div>
        <button
          type="button"
          className="btn btn-link dm-grid-action-title pb-0"
          onClick={onViewACAOptions}
        >
          View ACA Options
          <Icon
            name="laptop-medical"
            className="fa-download"
          />
        </button>
        <button
          type="button"
          className="btn btn-link dm-grid-action-title pb-0"
          onClick={onACACodeHelp}
        >
          ACA Code Help
          <Icon
            name="question-circle"
            className="fa-question-circle"
          />
        </button>
        <button
          {...getAccess(sectionAccess, 40, undefined, { disabledSameAsReadOnly: true })}
          type="button"
          className="btn btn-link dm-grid-action-title pb-0"
          onClick={() => {
            setFormIsDirty(true);
            acaStatusRef?.current?.onAddACAStatus();
          }}
        >
          Add ACA Status
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button>
        <button
          {...getAccess(sectionAccess, 40, undefined, { disabledSameAsReadOnly: true })}
          type="button"
          className="btn btn-link dm-grid-action-title pb-0"
          onClick={onRemoveOverride}
        >
          Remove Override
          <Icon
            name="minus-circle"
            className="fa-minus-circle"
          />
        </button>
      </PanelHeader>
      <form onSubmit={handleSubmit(onSave)}>
        <div className="dm-panel dm-panel-border mt-2">
          <div className="d-flex">
            {isEligible && !isFormB && (
              <ACAEmployeeInformationOverride
                formMethods={formMethods}
              />
            )}

            {(
              <ACAStatus
                formMethods={formMethods}
                protectedEmpNo={protectedEmpNo}
                protectedClientNo={protectedClientNo}
                clientNo={client?.clientNo ?? 0}
                selEmp={selEmp}
                control={control}
                eligible={acaTabData?.eligible ?? false}
                acaStatus={acaTabData?.acaStatus ?? [defaultStatus]}
                ref={acaStatusRef}
              />
            )}
          </div>
        </div>
        {isEligible && !isFormB && acaTabData && offerValues && (
          <div className="dm-panel dm-panel-border">
            <div className="dm-card-subtitle2 mb-2">
              Part II Employee Offer and Coverage
            </div>
            <ACAOfferCoverage
              formMethods={formMethods}
              item={acaTabData.offerOfCoverage}
              protectedEmpNo={protectedEmpNo}
              overrideOfferOfCoverageOpt={
                overrideOfferOfCoverageOpts
              }
              offerValues={setOfferValues}
            />
            <ACAEmpShare
              formMethods={formMethods}
              item={acaTabData.employeeShare}
              offerOfCoverage={offerValues}
            />
            <ACASafeHarbor
              formMethods={formMethods}
              item={acaTabData.safeHarbor}
              safeHarborOpts={overrideSafeHarborOpts}
              offerOfCoverage={offerValues}
            />
            <ACAZipCode
              formMethods={formMethods}
              item={acaTabData.zipCode}
              offerOfCoverage={offerValues}
            />
            <div className="d-flex flex-row flex-wrap">
              <div className="gc30 mw200"></div>
              <SelectGrpInLine
                {...fs.choose}
                options={safeHarborWaiveCoverage}
                ref={waiveCoverageRef}
              />
              <button
                type="button"
                className="btn btn-primary orange-outline-button-sm float-left"
                onClick={onWaiveCoverage}
              >
                Waive Coverage
              </button>
            </div>
          </div>
        )}
        {isEligible && isFormB && (
          <ACA1095BSection
            aca1095b={aca1095b}
            ref={acaSave1095Ref}
          />
        )}

        {isEligible && (
          <div className="dm-panel dm-panel-border">
            <PanelHeader title="Covered Individuals">
              <button
                type="button"
                className="btn btn-link dm-grid-action-title p-0 mr-2"
                onClick={onAddDependent}
              >
                Add New Dependent{' '}
                <Icon
                  name="plus-circle"
                  className="fa-plus-circle"
                />
              </button>
            </PanelHeader>
            <div className="d-flex flex-wrap">
              {fields?.map((item, index) => {
                return (
                  <div key={item.id}>
                    <ACACoveredIndividualItem
                      formMethods={formMethods}
                      control={control}
                      item={item as CoveredIndividual}
                      index={index}
                      onDeleteCI={(item, index) => {
                        onDeleteCI(item, index);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {isEligible && (
          <div className="row mt-2">
            <div className="col-12 text-right">
              <button
                type="submit"
                className="orange-button ml-2"
                onSubmit={onSave}
                onClick={() => {
                  if (isFormB) acaSave1095Ref?.current?.onSave();
                }}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </form>
      {showACAOptions && clientACA && (
        <ACAQuestionsModal
          clientACA={clientACA}
          show={showACAOptions}
          onHide={() => { return setShowACAOptions(false); }}
        />
      )}
      {showACACodeHelp && (
        <ACAInfoModal
          show={showACACodeHelp}
          onHide={() => { return setShowACACodeHelp(false); }}
        />
      )}
    </div>
  );
};

export default ACAPage;
