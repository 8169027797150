import { createSelector } from 'reselect';
import { NestedAccordionItem } from '../../components/form-controls/nested-accordion/NestedAccordionItem';
import { PayStub } from '../../models';
import { payStubState } from '../reducers';
import { State as PayStubState } from '../reducers/pay-stub.reducer';

export const getPayStubs = createSelector(
  payStubState,
  (state: PayStubState) => {return state.payStubs;},
);

export const getPayStubsForAccordion = createSelector(
  payStubState,
  (state: PayStubState) => {return state.payStubs && convertPayStubs(state.payStubs);},
);

export const getPayStubPDF = createSelector(
  payStubState,
  (state: PayStubState) => {
    if (!state?.payStubPDF) return null;

    const binary_string = atob(state.payStubPDF.data);
    return { ...state.payStubPDF, bdata: binary_string };
  },
);

export const getPayStubPDFGBinary = createSelector(
  payStubState,
  (state: PayStubState) => {
    if (!state?.payStubPDF) return null;

    const binary_string = atob(state.payStubPDF.data);
    return { ...state.payStubPDF, bdata: binary_string };
  },
);

export const getPayStubPDFWithPW = createSelector(
  payStubState,
  (state: PayStubState) => {return state.payStubPDFWithPW;},
);

const months: { [key: number]: string } = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

const convertPayStubs = (payStubs: PayStub[]) => {
  const result: NestedAccordionItem[] = [];

  payStubs.forEach((ps: PayStub) => {
    const date = ps.checkDate ? new Date(ps.checkDate) : new Date();
    const year = '' + date.getFullYear();
    const month = months[date.getMonth()];
    const yearMatch = result.find((t: NestedAccordionItem) => {return t.title === year;});
    if (yearMatch) {
      const monthMatch = yearMatch.children?.find((z: NestedAccordionItem) => {return z.title === month;});
      if (monthMatch) {
        monthMatch.children?.push({ title: ps.checkDate.slice(0, ps.checkDate.indexOf('T')), id: 'D' + ps.mescID, data: ps });
      } else {
        yearMatch.children?.push({
          title: month,
          id: 'M' + ps.mescID,
          children: [{
            title: ps.checkDate.slice(0, ps.checkDate.indexOf('T')),
            id: 'D' + ps.mescID,
            data: ps,
          }],
        });
      }
    } else {
      result.push({
        title: year,
        id: 'Y' + ps.mescID,
        children: [{
          title: month,
          id: 'M' + ps.mescID,
          children: [{
            title: ps.checkDate.slice(0, ps.checkDate.indexOf('T')),
            id: 'D' + ps.mescID,
            data: ps,
          }],
        }],
      });
    }
  });

  return result;
};