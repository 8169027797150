import React, { useState } from 'react';
import Modal from 'core/components/modals/Modal';
import { UNSAVED_MESSAGE } from 'core/constants';
import { PayrollTimeCardSubInfo } from 'core/models/Contractor.model';
import { useForm } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import styles from '../../styles.module.scss';
import { CheckboxGrpInLine, InputGrp } from 'core/components/form-controls';
import { fieldSettings } from './SubDetailConfig';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { addUpdateSubInfo } from 'core/store/actions';
import ConfirmationModal from 'core/components/modals/confirmation.modal';

const newSub: PayrollTimeCardSubInfo = {
  clientNo: -9999,
  subNo: '',
  description: '',
  excludeFromWH347: false,
};

type Props = {
  subInfo?: PayrollTimeCardSubInfo;
  addSub: boolean;
  show: boolean;
  onHide: () => void;
};

const SubDetailModal = ({ subInfo = newSub, addSub, show, onHide }: Props) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  
  const clientNo = useAppSelector(({ client }) => client.client?.clientNo);
  
  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<PayrollTimeCardSubInfo>({
    defaultValues: { ...subInfo, clientNo: clientNo ?? -9999 },
  });
  
  const dispatch = useAppDispatch();
  
  const handleSave = async (updatedSubInfo: PayrollTimeCardSubInfo) => {
    dispatch(addUpdateSubInfo(updatedSubInfo))
      .then((_res) => {
        reset({ ...updatedSubInfo });
      });
  };
  
  const hideModal = () => {
    if (isDirty) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };
  
  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };
  
  return (
    <Modal
      title="Sub Details"
      show={show}
      onHide={hideModal}
      isDirty={isDirty}
    >
      <Prompt
        when={isDirty}
        message={UNSAVED_MESSAGE}
      />
      <form onSubmit={handleSubmit(handleSave)}>
        <input
          type="hidden"
          name="clientNo"
          ref={register({
            valueAsNumber: true,
          })}
        />
        <div className={styles['section-flex']}>
          {!addSub ? (
            <>
              <input
                type="hidden"
                name="subNo"
                ref={register()}
              />
              <InputGrp
                {...fieldSettings.subNo}
                defaultValue={subInfo.subNo}
                groupClass="mw150 mr-2"
                disabled
              />
            </>
          ) : (
            <InputGrp
              {...fieldSettings.subNo}
              groupClass="mw150 mr-2"
              ref={register()}
            />
          )}
          <InputGrp
            {...fieldSettings.description}
            groupClass="mw200 mr-2"
            ref={register()}
          />
          <CheckboxGrpInLine
            name="excludeFromWH347"
            label={'Exclude from WH347 Report'}
            ref={register()}
          />
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            className="orange-outline-button-sm mr-2"
            onClick={(e) => {
              e.preventDefault();
              hideModal();
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={!isDirty}
            className="orange-button-sm"
          >
            Save
          </button>
        </div>
      </form>
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { setShowConfirmationModal(false); }}
        />
      )
      }
    </Modal>
  );
};

export default SubDetailModal;