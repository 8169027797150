import { createAction } from '@reduxjs/toolkit';
import {
  EmpGrossPayAnalysis,
  EmpPaidBreakdown,
  IssueToBeResolved,
  NewFeature,
  NewHireBreakdown,
  Reminder,
} from '../../models';
import { PayrollTotalsByLocDeptSubSummary } from '../../models/PayrollTotalsByLocDeptSubSummary';
import { PayrollTotalsByTimePeriodSummary } from '../../models/PayrollTotalsByTimePeriodSummary';

export const loadNewFeatures = createAction('[dashboard] LOAD_NEWFEATURES');
export const storeNewFeatures = createAction<NewFeature[]>(
  '[dashboard] STORE_NEWFEATURES',
);

export const loadReminders = createAction('[dashboard] LOAD_REMINDERS');
export const storeReminders = createAction<Reminder[]>(
  '[dashboard] STORE_REMINDERS',
);

export const loadEmpPaidBreakdown = createAction(
  '[dashboard] LOAD_EMPPAIDBREAKDOWN',
);
export const storeEmpPaidBreakdown = createAction<EmpPaidBreakdown>(
  '[dashboard] STORE_EMPPAIDBREAKDOWN',
);

export const loadIssues = createAction('[dashboard] LOAD_ISSUES');
export const storeIssues = createAction<IssueToBeResolved[]>(
  '[dashboard] STORE_ISSUES',
);

export const loadEmpGrossAnalysis = createAction(
  '[dashboard] LOAD_EMPGROSSANALYSIS',
);
export const storeEmpGrossAnalysis = createAction<EmpGrossPayAnalysis>(
  '[dashboard] STORE_EMPGROSSANALYSIS',
);

export const loadNewHireBreakdown = createAction(
  '[dashboard] LOAD_NEWHIREBREAKDOWN',
);
export const storeNewHireBreakdown = createAction<NewHireBreakdown>(
  '[dashboard] STORE_NEWHIREBREAKDOWN',
);

export const loadPayrollTotalsByTimePeriod = createAction(
  '[dashboard] LOAD_PAYROLL_TOTALS_BY_TIME_PERIOD',
);
export const storePayrollTotalsByTimePeriod =
    createAction<PayrollTotalsByTimePeriodSummary>(
      '[dashboard] STORE_PAYROLL_TOTALS_BY_TIME_PERIOD',
    );

export const loadPayrollTotalsByLocDeptSubSummary = createAction(
  '[dashboard] LOAD_PAYROLL_TOTALS_BY_LOC_DEPT_SUB_SUMMARY',
);
export const storePayrollTotalsByLocDeptSubSummary =
    createAction<PayrollTotalsByLocDeptSubSummary>(
      '[dashboard] STORE_PAYROLL_TOTALS_BY_LOC_DEPT_SUB_SUMMARY',
    );

export const loadDashboardData = createAction('[dashboard] LOAD_DASHBOARD_DATA');