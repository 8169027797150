import { TimeCardJobRosterEarnings } from 'core/models/Contractor.model';
import JobRosterEarningItem from './JobRosterEarningsItem';
import '../time-card-styles.scss';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { useHistory } from 'react-router-dom';
import { onCellDetailClick } from '../utilities';

type Props = {
  earnings: TimeCardJobRosterEarnings[],
  transmittalTimeCardId: number,
};

const JobRosterEarnings = ({ earnings, transmittalTimeCardId }: Props) => {

  const { timeCards } = useAppSelector(({ contractor }) => contractor);
  const dispatch = useAppDispatch();
  const history = useHistory();

  return (
    <div className="deductions-earnings-column">
      <div className="sub-section">
        <div className="d-flex">
          <div className="earning-code-col">
            <span className="section-title">Earnings</span>
          </div>
          <div>
            <span className="dm-form-label" style={{ marginLeft: '45%'}}>
              Amount
            </span>
          </div>
        </div>
        <div className="d-flex flex-column">
            {earnings.map(earning => {
                return <JobRosterEarningItem earning = {earning} />
            })}
        </div>
        <button
          className="btn btn-link dm-grid-action-title py-0 mr-3"
          onClick={() => onCellDetailClick(transmittalTimeCardId, timeCards, history, dispatch)}
        >
          Open Time Sheet
        </button>
      </div>
    </div>
  );
};

export default JobRosterEarnings;