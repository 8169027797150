import { createAction } from '@reduxjs/toolkit';
import { EmpDate, Employee, Rehire, Supervisor } from '../../models';
import { StreamedUserAccessObject } from '../epics/app.epic';
import { TableModel as Tm } from 'core/components/shared/table/types';

export const EMPS_EM_VALIDATION_ACTIONS = {
  UPDATE_EMP_DATE: '[employee] UPDATE_EMP_DATE',
  CREATE_REHIRE: '[employee] CREATE_REHIRE',
} as const;

export const loadEmployees = createAction<StreamedUserAccessObject | undefined>('[employee] LOAD_EMPLOYEES');
export const storeEmployees = createAction<Employee[]>('[employee] STORE_EMPLOYEES');
export const storeEmployeesAfterDelete = createAction<number>('[employee] STORE_EMPLOYEES_AFTER_DELETE');
export const storeEmployeesAfterAdd = createAction<Employee>('[employee] STORE_EMPLOYEES_AFTER_Add');

export const loadFilteredEmployees = createAction('[employee] LOAD_FILTERED_EMPLOYEES');
export const storeFilteredEmployees = createAction<Employee[]>('[employee] STORE_FILTERED_EMPLOYEES');
export const resetFilteredEmployees = createAction('[employee] RESET_FILTERED_EMPLOYEES');

export const loadFilterModel = createAction('[employee] LOAD_FILTER_MODEL');
export const storeFilterModel = createAction<{ filterModel: Tm.FilterModel<Employee>, radioSelection: 'All' | 'Active' | 'Terminated' }>('[employee] STORE_FILTER_MODEL');

export const loadSupervisors = createAction('[employee] LOAD_SUPERVISORS');
export const storeSupervisors = createAction<Supervisor[]>('[employee] STORE_SUPERVISORS');

export const storePrevHRLink = createAction<string | null>('[employee] STORE_PREV_HR_LINK');

// moving these here since the employees aren't loaded when going to emp list so this isn't updated in state otherwise.
export const loadEmpDate = createAction<{ protectedEmpNo: string, updateKeys: string[] }>('[employee] LOAD_EMP_DATE');
export const storeEmpDate = createAction<{ empDate: EmpDate, updateKeys: string[] }>('[employee] STORE_EMP_DATE');
export const updateEmpDate = createAction<{ empDate: EmpDate, updateKeys: string[] }>(EMPS_EM_VALIDATION_ACTIONS.UPDATE_EMP_DATE);

export const createEmpRehire = createAction<{
  protectedEmpNo: string;
  rehire: Rehire;
}>(EMPS_EM_VALIDATION_ACTIONS.CREATE_REHIRE);

export const storeEmpFromPreviousClient = createAction<Employee[] | null>('employee/storeEmpFromPreviousClient');

export const updateListQuery = createAction<string>('[employee] UPDATE_LIST_QUERY');

export const toggleMissingInactiveStatusMessage = createAction<boolean>('[employee] HANDLE_MISSING_AOD_INACTIVE_STATUS');