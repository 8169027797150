import React from 'react';
import Modal from 'core/components/modals/Modal';
import { useHistory } from 'react-router-dom';
import { ACTIVATION_NOTICE_MESSAGE } from 'core/constants';

type Props = {
  show: boolean;
  onHide: () => void;
  message?: string;
};

const ActivationNoticeModal = ({ message = ACTIVATION_NOTICE_MESSAGE, show, onHide }: Props) => {
  const history = useHistory();
  
  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        history.push('/');
      }}
      title="Activation Required"
    >
      {(closeModal) => {
        return (
          <div className="d-flex w-100 flex-wrap">
            <div>{message}</div>
            <div className="d-flex w-100 justify-content-end">
              <button
                className="orange-button mr-2"
                onClick={closeModal}
              >
                Okay
              </button>
            </div>
          </div>
        );
      }}
    </Modal>
  );
};

export default ActivationNoticeModal;