import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import SiteSettingsPage from './site-settings/SiteSettingsPage';
import DesignAtsWebsiteSidebar from './DesignAtsWebsiteSidebar';
import DesignAtsWebPages from './web-page/DesignAtsWebPages';
import WaiversPage from './waivers/WaiversPage';
import { getDesignWebsite } from 'core/store/actions/applicant-tracking.action';
import { useAppDispatch } from 'utilities/hooks';
import CoverImagesPage from './cover-images/CoverImagesPage';

const DesignAtsWebsitePage = () => {
  const dispatch = useAppDispatch();
    
  useEffect(()=> {
    dispatch(getDesignWebsite());
  }, []);

  return (
    <Col>
      <Row>
        <Col sm={2}>
          <DesignAtsWebsiteSidebar />
        </Col>
        <Col
          className="pl-0"
          sm={10}
        >
          <Switch>
            <Route
              exact
              path="/design-ats-website"
              component={SiteSettingsPage}
            />
            <Route
              exact
              path="/design-ats-website/cover-images"
              component={CoverImagesPage}
            />
            <Route
              exact
              path="/design-ats-website/web-pages"
              component={DesignAtsWebPages}
            />
            <Route
              exact
              path="/design-ats-website/waivers"
              component={WaiversPage}
            />
          </Switch>
        </Col>
      </Row>
    </Col>
  );
};

export default DesignAtsWebsitePage;
