/* eslint-disable @typescript-eslint/no-unused-expressions */
import { AgGridReact } from '@ag-grid-community/react';
import {
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  ValueSetterParams,
} from '@ag-grid-enterprise/all-modules';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { agSelectEditor } from 'utilities/ag-grid-editors';
import AGEditButtonRendererComponent from 'utilities/ag-grid-renderers/AGEditButtonRendererComponent';
import {
  ControlDatePickerGrp,
  RadioGrpInLine,
  SelectGrp,
} from '../../core/components/form-controls';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import {
  Department,
  Dropdown,
  Location,
  SubDepartment,
  SubDepartment2,
} from '../../core/models';
import {
  ClientReportOptions,
  DemographicAndTurnoverEmployee,
  DemographicAndTurnoverReportRequest,
} from '../../core/models/HumanCapitalReports.model';
import {
  clearDemographicAndTurnoverReport,
  downloadDemographicAndTurnoverReport,
  getDemographicAndTurnoverEmployees,
  updateDemographicAndTurnoverEmployee,
} from '../../core/store/actions/human-capital.action';
import { getDropdownOptions } from '../../core/store/selectors';
import { agDateRenderer, agLookupRenderer } from '../../utilities/ag-grid-renderers';
import { useAppSelector } from '../../utilities/hooks';
import EmployeeStatusModal from '../../core/components/modals/EmployeeStatusModal';
import InputDateSelector from 'core/components/form-controls/InputDateSelector';
import { ClientAccess } from 'core/models/ClientAccess';
import { DateTime } from 'luxon';
import ConfirmationModal from 'core/components/modals/confirmation.modal';

const quarterlyRadioOpts = [
  {
    value: 'Quarter',
    label: '',
  },
];
const anuallyRadioOpts = [
  {
    value: 'Year',
    label: '',
  },
];
const customRadioOpts = [
  {
    value: 'Custom',
    label: '',
  },
];
const selectionFilterOpts = [
  {
    id: 'ClientNo',
    description: 'ClientNo',
  },
  {
    id: 'Location',
    description: 'Location',
  },
  {
    id: 'Department',
    description: 'Department',
  },
  {
    id: 'SubDepartment',
    description: 'SubDepartment',
  },
  {
    id: 'SubDepartment2',
    description: 'SubDepartment2',
  },
  {
    id: 'JobTitle',
    description: 'JobTitle',
  },
];

const cellErrorColor = '#F9A7A7';

const DemographicAndTurnoverReport = () => {
  const dispatch = useDispatch();

  const childRef: React.RefObject<any> = useRef();
  const reportName = 'Demographic And Turnover Report';
  const report = useAppSelector((state) => { return state.humanCapitalReports.demographicAndTurnoverReport; });
  const eeoCodeDropDown = useAppSelector((state) => { return state.dropdown.eeoCode; });
  const client = useAppSelector((state) => { return state.client.client; });
  const availableClients = useAppSelector((state) => { return state.auth.availableClients; });
  const employeesStore = useAppSelector((state) => { return state.humanCapitalReports.demographicAndTurnoverEmployees; });
  const { locationOpts, deptOpts, subdeptOpts, subdept2Opts, jobTitlesOpts } =
    useSelector(getDropdownOptions);

  const { control, handleSubmit, register, errors, setError, clearErrors } = useForm<DemographicAndTurnoverReportRequest>(
    { defaultValues: { dateOption: 'Custom' } },
  );
  
  const [filterHeaderText, setFilterHeaderText] = useState<string>('ClientNo');
  const [rowData, setRowData] = useState<DemographicAndTurnoverEmployee[]>([]);
  const [reportDates, setReportDates] = useState<string[]>([]);
  const [showEmpStatusModal, setShowEmpStatusModal] = useState<boolean>(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [selectedRow, setSelectedRow] = useState<DemographicAndTurnoverEmployee | null>(null);
  const [filterOptions, setFilterOptions] = useState<Dropdown[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Dropdown[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [submitData, setSubmitData] = useState<DemographicAndTurnoverReportRequest>();
  const [showEmployeeGrid, setShowGrid] = useState(false);
  const [employees, setEmployees] = useState<DemographicAndTurnoverEmployee[]>([]);
  const [selectionFilter, setSelectionFilter] = useState<{ id: string, description: string }>();

  useEffect(() => {
    dispatch(getDemographicAndTurnoverEmployees());
  }, []);

  useEffect(() => {
    let storeData = employeesStore;
    if (employees.length) {
      storeData = employeesStore.filter(e => { return employees.map(e => { return e.empNo; }).includes(e.empNo);});
    }
    
    if (!storeData) return;
    setEmployees(storeData);
    setRowData(filterRowData(storeData, filterHeaderText)?.filter((emp) => emp.acaEmployeeStatus.trim() === '' || emp.eeoRace.trim() === ''));
  }, [employeesStore]);

  useEffect(() => {
    selectedRow && setShowEmpStatusModal(true);
  }, [selectedRow]);
  
  useEffect(() => {
    if (!selectionFilterOpts?.length) return;
    setSelectionFilter(selectionFilterOpts.find((opt) => opt.description === 'ClientNo'));
    setFilterOptions(
      availableClients
        .filter((c: { clientEntityId: number | undefined; }) => { return c.clientEntityId === client?.clientEntityID; })
        .map((c: ClientAccess) => {
          return {
            id: c.clientNo,
            description: c.clientName,
          };
        }) as unknown as Dropdown[]);
  }, [selectionFilterOpts]);

  const onGridReady = (e: GridReadyEvent) => {
    setGridApi(e.api);
  };
  
  const onFirstDataRendered = (_?: FirstDataRenderedEvent) => {
    if (!gridApi) return;
    const clientIndex = filterOptions?.findIndex((opt) => +opt.id === +(client?.clientNo ?? '0'));
    if (clientIndex < 0) return;
    const rowNode = gridApi.getDisplayedRowAtIndex(0);
    if (!rowNode) return;
    rowNode.setSelected(true, true);
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      selectedRows && setSelectedOptions(selectedRows);
    }
  };

  const handleSelectChange = (e: any) => {
    setFilterHeaderText(e.target.value);
    if (e.target.value === 'ClientNo') {
      setFilterOptions(availableClients
        .filter((c: { clientEntityId: number | undefined; }) => { return c.clientEntityId === client?.clientEntityID; })
        .map((c: ClientAccess) => {
          return {
            id: c.clientNo,
            description: c.clientName,
          };
        }) as unknown as Dropdown[]);
    } else if (e.target.value === 'Location') {
      setFilterOptions(locationOpts.map((l: Location) => {
        return {
          id: l.locationCode,
          description: l.locationDesc,
        };
      }) as unknown as Dropdown[]);
    } else if (e.target.value === 'Department') {
      setFilterOptions(deptOpts.map((l: Department) => {
        return {
          id: l.deptCode,
          description: l.deptDesc,
        };
      }) as unknown as Dropdown[]);
    } else if (e.target.value === 'SubDepartment') {
      setFilterOptions(subdeptOpts.map((l: SubDepartment) => {
        return {
          id: l.subDeptCode,
          description: l.subDeptDesc,
        };
      }) as unknown as Dropdown[]);
    } else if (e.target.value === 'SubDepartment2') {
      setFilterOptions(subdept2Opts.map((l: SubDepartment2) => {
        return {
          id: l.sub2Code,
          description: l.sub2Desc,
        };
      }) as unknown as Dropdown[]);
    } else if (e.target.value === 'JobTitle') {
      setFilterOptions(jobTitlesOpts as unknown as Dropdown[]);
    }
  };

  const onSettingEdit = (rowData: any) => {
    rowData && setSelectedRow({ ...rowData });
  };

  const onHandleEEORaceChange = (e: any) => {
    dispatch(updateDemographicAndTurnoverEmployee(e));
  };

  const onReset = () => {
    dispatch(clearDemographicAndTurnoverReport());
    setRowData([]);
  };
  
  const filterRowData = (emps: DemographicAndTurnoverEmployee[], clientByOption: string): DemographicAndTurnoverEmployee[] => {
    let turnOverRowData = structuredClone(emps) ?? [];

    switch (clientByOption) {
      case 'ClientNo':
        turnOverRowData = turnOverRowData.filter(d => { return selectedOptions?.map(c => { return +c.id; }).includes(d.clientNo); });
        break;
      case 'Department':
        turnOverRowData = turnOverRowData.filter(d => { return selectedOptions?.map(c => { return +c.id; }).includes(d.department); });
        break;
      case 'SubDepartment':
        turnOverRowData = turnOverRowData.filter(d => { return selectedOptions?.map(c => { return +c.id; }).includes(d.subDepartment); });
        break;
      case 'SubDepartment2':
        turnOverRowData = turnOverRowData.filter(d => { return selectedOptions?.map(c => { return +c.id; }).includes(d.subDepartment2); });
        break;
      case 'JobTitle':
        turnOverRowData = turnOverRowData.filter(d => { return selectedOptions?.map(c => { return +c.id; }).includes(d.jobTitleId); });
        break;
    }
    
    return turnOverRowData;
  };

  const downloadReport = (data: DemographicAndTurnoverReportRequest) => {
    clearErrors('dateOption');
    const startDate = data.dateOption === 'Custom' ? reportDates[0] : DateTime.local().startOf('year').toISODate();
    const endDate = data.dateOption === 'Custom' ? reportDates[1] :
      data.dateOption === 'Quarter' ? DateTime.fromJSDate(new Date('' + data.quarterlyEnd)).toISODate() :
        data.dateOption === 'Year' ? DateTime.fromJSDate(new Date('' + data.annuallyEnd)).toISODate() : undefined;

    if (!data.dateOption) {
      setError('dateOption', {
        type: 'required',
        message: 'Select a Date Type',
      });
      return;
    }

    if (data.dateOption === 'Quarter' && !endDate) {
      setError('quarterlyEnd', {
        type: 'required',
        message: 'Required',
      });
      return;
    }

    if (data.dateOption === 'Year' && !endDate) {
      setError('annuallyEnd', {
        type: 'required',
        message: 'Required',
      });
      return;
    }

    if (!data.clientByOption) {
      setError('clientByOption', {
        type: 'required',
        message: 'Select a Client Option',
      });
      return;
    }

    const clientReportOptions: ClientReportOptions = {
      loc: data.clientByOption === 'Location' ? selectedOptions?.map(c => { return +c.id; }) : [],
      dept: data.clientByOption === 'Department' ? selectedOptions?.map(c => { return +c.id; }) : [],
      sub: data.clientByOption === 'SubDepartment' ? selectedOptions?.map(c => { return +c.id; }) : [],
      sub2: data.clientByOption === 'SubDepartment2' ? selectedOptions?.map(c => { return +c.id; }) : [],
      jobId: data.clientByOption === 'JobTitle' ? selectedOptions?.map(c => { return +c.id; }) : [],
    };

    const _submitData: DemographicAndTurnoverReportRequest = {
      clientNo: data.clientByOption === 'ClientNo' ? selectedOptions?.map(c => { return +c.id; }) : client ? [client?.clientNo] : [],
      clientReportOptions,
      startDate,
      endDate,
      dateOption: data.dateOption,
      clientByOption: data.clientByOption,
    };

    const turnOverRowData: DemographicAndTurnoverEmployee[] = filterRowData(employees, data.clientByOption)
      ?.filter((emp) => emp.acaEmployeeStatus.trim() === '' || emp.eeoRace.trim() === '');
    
    if (turnOverRowData.length) {
      setRowData(turnOverRowData);
      setShowConfirmationModal(true);
      setShowGrid(true);
      setSubmitData(_submitData);
    } else {
      setRowData([]);
      setShowGrid(false);
      dispatch(downloadDemographicAndTurnoverReport(_submitData));
    }
  };

  const confirmSubmit = (confirmed: boolean) => {
    if (confirmed && submitData) {
      dispatch(downloadDemographicAndTurnoverReport(submitData));
    }
  };

  const onCancel = () => {
    onReset();
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearDemographicAndTurnoverReport());
  };

  //#region AgGrid
  const columnsForSmallGrid: ColDef[] = [{
    field: 'Select',
    headerName: 'Select',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    width: 130,
  },
  {
    field: 'id',
    headerName: filterHeaderText ?? 'Client No',
    sortable: true,
    editable: false,
    width: 130,
  },
  {
    field: 'description',
    headerName: 'Description',
    sortable: true,
    suppressMenu: false,
    editable: false,
    width: 130,
  }];

  const columnsForLrgGrid: ColDef[] = [
    {
      field: 'protectedEmpNo',
      hide: true,
    },
    {
      field: 'clientNo',
      headerName: 'Client No',
      sortable: true,
      suppressMenu: false,
      editable: false,
      width: 130,
    }, {
      field: 'clientName',
      headerName: 'Client Name',
      sortable: true,
      suppressMenu: false,
      editable: false,
      width: 150,
    }, {
      field: 'empNo',
      headerName: 'Emp No',
      width: 130,
    },
    {
      field: 'employeeName',
      headerName: 'Emp',
      width: 150,
    },
    {
      field: 'hireDate',
      headerName: 'Hire Date',
      cellRenderer: 'dateRenderer',
      width: 90,
    },
    {
      field: 'termDate',
      headerName: 'Term Date',
      cellRenderer: 'dateRenderer',
      width: 90,
    },
    {
      field: 'acaEmployeeStatus',
      headerName: 'Emp Status',
      cellRendererSelector: (params: ICellRendererParams) => {
        if (params.value.trim()) {
          return {
            frameworkComponent: (params: any) => {
              return <button
                type="button"
                className="btn btn-link"
                onClick={() => { onSettingEdit(params.data); }}
              >
                {params.value}
              </button>;
            },
          };
        } else {
          return {
            component: 'editButtonRendererComponent',
            params: { clickHandler: onSettingEdit },
          };
        }
      },
      editable: false,
      width: 120,
      cellStyle: (params) => {
        return params.value.toString().trim().length === 0 ?
          { backgroundColor: cellErrorColor }
          : null;
      },
    },
    {
      field: 'eeoRace',
      headerName: 'EEO Race',
      cellEditor: agSelectEditor,
      width: 170,
      editable: true,
      cellRenderer: agLookupRenderer,
      cellRendererParams: {
        options: eeoCodeDropDown,
        addEmptyValue: true,
      },
      cellStyle: (params) => {
        return params.value?.toString().trim().length === 0 ?
          { backgroundColor: cellErrorColor }
          : null;
      },
      cellEditorParams: {
        options: eeoCodeDropDown,
        addEmptyValue: true,
      },
      valueSetter: (params: ValueSetterParams) => {
        if (params.newValue === '') {
          params.data.eeoRace = null;
          return false;
        } else if (params.data) {          
          const data = { ...params.data,
            eeoRace: params.newValue };
          onHandleEEORaceChange(data);
        }
        return false;
      },
    }];

  const gridOptionsSmall: GridOptions = {
    columnDefs: columnsForSmallGrid,
    rowMultiSelectWithClick: true,
    suppressCellFocus: true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const gridOptionsLrg: GridOptions = {
    columnDefs: columnsForLrgGrid,
    rowSelection: 'single',
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    stopEditingWhenCellsLoseFocus: true,
    components: {
      lookupRenderer: agLookupRenderer,
      selectEditor: agSelectEditor,
      dateRenderer: agDateRenderer,
    },
    frameworkComponents: {
      editButtonRendererComponent: AGEditButtonRendererComponent,
    },
  };
  //#endregion

  return (
    <div>
      <div className="dm-panel dm-panel-border">

        <div className="dm-grid-title">{reportName}</div>
        <hr className="dm-panel-hr" />

        <div className="d-flex flex-column flex-grow-1">
          <form onSubmit={handleSubmit(downloadReport)}>
            <div className="flex-grow-1 text-right">
              <button
                type="button"
                className="orange-outline-button mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="orange-button mr-2"
              >
                Run Report
              </button>
            </div>
            <div className="d-flex flex-row mb-3">
              <RadioGrpInLine
                name={'dateOption'}
                label={'Customized from'}
                labelWidth={80}
                radioOptions={customRadioOpts}
                errors={errors?.dateOption}
                ref={register()}
              />
              <InputDateSelector
                ref={childRef}
                returnDates={(dates: string[]) => { return setReportDates(dates); }}
                hideDateTypes={true}
              />
            </div>

            <div className="d-flex flex-row">
              <RadioGrpInLine
                name={'dateOption'}
                label={'Quarterly End On '}
                labelWidth={80}
                radioOptions={quarterlyRadioOpts}
                errors={errors?.dateOption}
                ref={register()}
              />
              <ControlDatePickerGrp
                name="quarterlyEnd"
                label={''}
                groupClass={'wm150 mr-3'}
                errors={errors?.quarterlyEnd}
                control={control}
              />

              <RadioGrpInLine
                name={'dateOption'}
                label={'Annually End On '}
                labelWidth={80}
                radioOptions={anuallyRadioOpts}
                errors={errors?.dateOption}
                ref={register()}
              />
              <ControlDatePickerGrp
                name="annuallyEnd"
                label={''}
                groupClass={'wm150'}
                errors={errors?.annuallyEnd}
                control={control}
              />
            </div>
            <hr className="dm-sub-panel-hr" />
            <div className="font-weight-bold mb-2">Selection Filter</div>
            <div className="d-flex flex-row">
              <div className="d-flex flex-column mw400 mr-4">
                <SelectGrp
                  name="clientByOption"
                  defaultValue={selectionFilter}
                  onChange={handleSelectChange}
                  options={selectionFilterOpts}
                  errors={errors?.clientByOption}
                  ref={register()}
                />
                <div className="ag-theme-balham" >
                  <div style={{ height: '370px' }}>
                    <AgGridReact
                      rowData={filterOptions}
                      gridOptions={gridOptionsSmall}
                      columnDefs={columnsForSmallGrid}
                      onGridReady={onGridReady}
                      onFirstDataRendered={onFirstDataRendered}
                      onSelectionChanged={onSelectionChanged}
                      rowSelection={'multiple'}
                    /></div>
                </div>
              </div>
              {showEmployeeGrid && rowData?.length > 0 ? <div>
                <div className="table-wrapper-wrapper ag-theme-balham">
                  <div style={{ height: '400px' }}>
                    <AgGridReact
                      rowData={rowData}
                      columnDefs={columnsForLrgGrid}
                      gridOptions={gridOptionsLrg}
                      rowSelection={'multiple'}
                    />
                  </div>
                </div>
              </div> : null}
            </div>
          </form>
        </div>
        {
          report ? <PDFViewerModal
            show={report.length > 0}
            pdfData={report}
            onHide={hidePdfViewer}
            reportName="DemographicAndTurnoverReport"
          /> : null
        }

        {
          showEmpStatusModal && selectedRow ?
            <EmployeeStatusModal
              data={selectedRow}
              show={showEmpStatusModal}
              onHide={() => { setShowEmpStatusModal(false); }}
            /> : null
        }
        {showConfirmationModal && (
          <ConfirmationModal
            title="Missing Information"
            message={`Some employees are missing status information (highlighted in red). 
                        Do you want to continue and run the report which will exclude these employees?`}
            show={showConfirmationModal}
            onConfirmed={confirmSubmit}
            onHide={() => { return setShowConfirmationModal(false); }}
          />
        )}
      </div>
    </div>
  );
};

export default DemographicAndTurnoverReport;

