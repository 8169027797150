import { createReducer } from '@reduxjs/toolkit';
import { EmFieldValidation as E } from 'types';
import { beginValidateEmployee, storeValidationFromEndpoint, storeValidationFromStream, toggleChangeEmpStatusModal, toggleEmFieldValidationModal, toggleMyInfoChangesModalBtn } from '../actions';

export type EmFieldValidationState = {
  loading: boolean;
  currentErrors: E.EmSectionErrors | null;
  showEmFieldValidationModal: boolean;
  showMyInfoChangesModalBtn: boolean;
  showChangeEmpStatusModal: boolean;
};

const INITIAL_STATE: EmFieldValidationState = {
  loading: false,
  currentErrors: null,
  showEmFieldValidationModal: false,
  showMyInfoChangesModalBtn: false,
  showChangeEmpStatusModal: false,
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(beginValidateEmployee, (state) => {
      state.loading = true;
    })
    .addCase(storeValidationFromEndpoint, (state, action) => {
      state.loading = false;
      state.currentErrors = action.payload;
    })
    .addCase(storeValidationFromStream, (state, action) => {
      state.loading = false;
      state.currentErrors = action.payload;
    })
    .addCase(toggleEmFieldValidationModal, (state, action) => {
      state.showEmFieldValidationModal = action.payload;
    })
    .addCase(toggleMyInfoChangesModalBtn, (state, action) => {
      state.showMyInfoChangesModalBtn = action.payload;
    })
    .addCase(toggleChangeEmpStatusModal, (state, action) => {
      state.showChangeEmpStatusModal = action.payload;
    })
  ;
});