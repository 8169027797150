import React, { useState } from 'react';

import { Tab, Tabs } from 'react-bootstrap';
import EmployeeChangeHistory from './EmployeeChangeHistory';
import TaxChangeHistory from './TaxChangeHistory';
import DirectDepositChangeHistory from './DirectDepositChangeHistory';
import { EmpMyInfoChanges } from '../../../models';

type PropTypes = {
  data: EmpMyInfoChanges;
};

const ChangeHistoryTabs: React.FC<PropTypes> = ({ data }) => {
  const [, setKey] = useState('ECH');

  return (
    <div>
      <Tabs
        defaultActiveKey="ECH"
        id="change-history-tabs"
        onSelect={(k: any) => {return setKey(k);}}>
        <Tab eventKey="ECH" title="EMPLOYEE CHANGE HISTORY">
          <EmployeeChangeHistory items={data.employeeChanges} />
        </Tab>
        <Tab eventKey="TCH" title="TAX CHANGE HISTORY">
          <TaxChangeHistory items={data.taxChanges} />
        </Tab>
        <Tab eventKey="DDCH" title="DIRECT DEPOSIT CHANGE HISTORY">
          <DirectDepositChangeHistory items={data.directDepositChanges} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ChangeHistoryTabs;