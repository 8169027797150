import React, { useState } from 'react';
import Modal from 'core/components/modals/Modal';
import Table from 'core/components/shared/dm-table/Table';
import { ColumnHeaderData, Row } from 'core/components/shared/dm-table/types';
import { Employee } from 'core/models';
import { useAppSelector } from 'utilities/hooks';
import { convToDateString } from 'utilities/utilities';
import styles from './styles.module.scss';
import { InputGrp } from 'core/components/form-controls';
import { RadioGrp, RadioOptions } from 'core/components/form-controls/RadioGrp';
import { sortByLastName } from '../utilities';

type SelectionOpt = 'All' | 'Active' | 'Terminated';

const timeCardRadioOpts: RadioOptions[] = [
  { value: 'All', label: 'All' },
  { value: 'Active', label: 'Active' },
  { value: 'Terminated', label: 'Terminated' },
];

const buildEmpRow = (emp: Employee): Row => {
  return {
    id: emp.empNo,
    cells: [
      { children: emp.empNo, styleClass: 'td-10' },
      { children: emp.firstName, styleClass: 'td-30' },
      { children: emp.midName, styleClass: 'td-10' },
      { children: emp.lastName, styleClass: 'td-30' },
      { children: emp?.termDate ? convToDateString(emp.termDate) : null, styleClass: 'td-20' },
    ],
  };
};

const jobNoColumns: ColumnHeaderData[] = [
  { title: 'Emp. No.', styleClass: 'th-10' },
  { title: 'First Name', styleClass: 'th-30' },
  { title: 'M.I.', styleClass: 'th-10' },
  { title: 'Last Name', styleClass: 'th-30' },
  { title: 'Term. Date', styleClass: 'th-20' },
];

const matchQuery = (employee: Employee, _query: string, filter: SelectionOpt): boolean => {
  switch (filter) {
    case 'Active': {
      return !!((employee?.firstName?.toLowerCase()?.includes(_query.toLowerCase())
      || employee?.midName?.toLowerCase()?.includes(_query.toLowerCase())
      || employee?.lastName?.toLowerCase()?.includes(_query.toLowerCase())
      || String(employee?.empNo)?.includes(_query)) && !employee?.termDate);
    }
    case 'Terminated': {
      return !!((employee?.firstName?.toLowerCase()?.includes(_query.toLowerCase())
      || employee?.midName?.toLowerCase()?.includes(_query.toLowerCase())
      || employee?.lastName?.toLowerCase()?.includes(_query.toLowerCase())
      || String(employee?.empNo)?.includes(_query)) && !!employee?.termDate);
    }
    case 'All':
    default:
      return !!(employee?.firstName?.toLowerCase()?.includes(_query.toLowerCase())
      || employee?.midName?.toLowerCase()?.includes(_query.toLowerCase())
      || employee?.lastName?.toLowerCase()?.includes(_query.toLowerCase())
      || String(employee?.empNo)?.includes(_query));
  }
};

type Props = {
  show: boolean;
  onHide: () => void;
  addTimeCard: (id: string | number) => void;
};

const AddTimeCardModal = ({ show, onHide, addTimeCard }: Props) => {
  const { employees } = useAppSelector(({ employees }) => employees);
  const sortedEmps = structuredClone(employees)?.sort(sortByLastName<Employee>);
  
  const [filteredEmps, setFilteredEmps] = useState<Employee[]>(sortedEmps?.filter((emp) => !emp.termDate)?.sort(sortByLastName<Employee>));
  const [selection, setSelection] = useState<SelectionOpt>('Active');
  const [query, setQuery] = useState<string>('');
  
  const handleSearch = (newQuery: string) => {
    if (!newQuery.trim().length) {
      setQuery(newQuery);
      setFilteredEmps(sortedEmps?.filter((emp) => {
        if (selection === 'Active') {
          return !emp.termDate;
        } else if (selection === 'Terminated') {
          return !!emp.termDate;
        } 
        return true;
      })?.sort(sortByLastName<Employee>));
      return;
    }
    
    const newState = structuredClone(sortedEmps)?.filter((employee: Employee) => matchQuery(employee, newQuery, selection));
    
    setQuery(newQuery);
    setFilteredEmps(newState);
  };
  
  const onRadioChange = (
    e: React.ChangeEvent<HTMLInputElement> | undefined,
  ) => {
    switch (e?.target.value as SelectionOpt) {
      case 'Active':{
        const newEmps = sortedEmps
          ?.filter((emp) => !emp?.termDate && (query.length ? matchQuery(emp, query, e?.target.value as SelectionOpt) : true))
          ?.sort(sortByLastName<Employee>);
        
        setFilteredEmps(newEmps);
        setSelection('Active');
        break;
      }
      case 'Terminated': {
        const newEmps = sortedEmps
          ?.filter((emp) => !!emp?.termDate && (query.length ? matchQuery(emp, query, e?.target.value as SelectionOpt) : true))
          ?.sort(sortByLastName<Employee>);

        setFilteredEmps(newEmps);
        setSelection('Terminated');
        break;
      }
      default: {
        setFilteredEmps(sortedEmps
          ?.filter((emp) => query.length ? matchQuery(emp, query, e?.target.value as SelectionOpt) : true)
          ?.sort(sortByLastName<Employee>));
        setSelection('All');
        break;
      }
    }
  };
  
  return (
    <Modal
      title="Add Time Card"
      size="xl"
      show={show}
      onHide={onHide}
    >
      <div className="d-flex flex-column">
        <div className="d-flex flex-column w-25">
          <label
            htmlFor="trade-sub-search"
            className={styles['trade-sub-top-label']}
          >Search</label>
          <InputGrp
            placeholder="Enter employee name or number"
            groupClass="m-0"
            inputClass={`gc20 ${styles['trade-sub-input']}`}
            name="trade-sub-search"
            type="text"
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              handleSearch(target.value);
            }}
          />
        </div>
        <RadioGrp
          groupClass="gc12 mw450"
          radioOptions={timeCardRadioOpts}
          name="employeeStatus"
          checked={selection}
          onChange={onRadioChange}
          controlled
        />
        <Table
          columns={jobNoColumns}
          rows={filteredEmps?.map((emp) => buildEmpRow(emp))}
          onRowDoubleClick={addTimeCard}
        />
      </div>
    </Modal>
  );
};

export default AddTimeCardModal;