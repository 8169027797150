import React, { useEffect, useMemo } from 'react';
import { DownloaderField, PageSection } from 'core/models/Downloader.model';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'utilities/hooks';
import EmployeeInfoFields from '../EmployeeMasterPages/components/EmployeeInfoFields';
import EarningsFields from '../EarningsPages/components/EarningsFields';
import TaxInfoFields from '../EmployeeMasterPages/components/TaxInfoField';
import EmployeeStatusGeneralFields from '../HRInfoPages/components/EmployeeStatusGeneralFields';
import DatesFields from '../EmployeeMasterPages/components/DatesFields';
import EmployeeStatusStatusFields from '../HRInfoPages/components/EmployeeStatusStatusFields';
import EmployeeStatusEmergencyContactFields from '../HRInfoPages/components/EmployeeStatusEmergencyContactFields';
import EmployeeStatusPayFields from '../HRInfoPages/components/EmployeeStatusPayFields';
import EmployeeStatusCompanyPropertyFields from '../HRInfoPages/components/EmployeeStatusCompanyPropertyFields';
import EmployeeStatusDependentsFields from '../HRInfoPages/components/EmployeeStatusDependentsFields';
import TrainingEmploymentFields from '../HRInfoPages/components/TrainingEmploymentFields';
import TrainingEducationFields from '../HRInfoPages/components/TrainingEducationFields';
import TrainingEventsFields from '../HRInfoPages/components/TrainingEventsFields';
import TrainingLicencesFields from '../HRInfoPages/components/TrainingLicensesFields';
import TrainingSkillsFields from '../HRInfoPages/components/TrainingSkillsFields';
import TrainingSourcesFeesFields from '../HRInfoPages/components/TrainingSourcesFeesFields';
import AppraisalExitInterviewFields from '../HRInfoPages/components/AppraisalExitInterviewFields';
import AppraisalReviewsFields from '../HRInfoPages/components/AppraisalReviewsFields';
import AppraisalIncreaseFields from '../HRInfoPages/components/AppraisalIncreaseFields';
import VaccinationFields from '../HRInfoPages/components/VaccinationFields';
import EmergencyContactFeilds from '../EmployeeMasterPages/components/EmergencyContactFields';
import DeductionsFields from '../EmployeeMasterPages/components/DeductionsFields';
import EmployeeStatusCustomFields from '../HRInfoPages/components/EmployeeStatusCustomFields';
import AccrualsFields from '../EmployeeMasterPages/components/AccrualsFields';
import CompanyBenefitsFields from '../EmployeeMasterPages/components/CompanyBenefitsFields';
import DeferredCompFields from '../EmployeeMasterPages/components/DeferredCompFields';
import GroupTermLifeFields from '../EmployeeMasterPages/components/GroupTermLifeFields';
import HrDoctorCompFields from '../EmployeeMasterPages/components/HRDoctorCompFields';
import HrFteFields from '../EmployeeMasterPages/components/HRFTEFields';
import PayrollInformationFields from '../EmployeeMasterPages/components/PayrollInformationFields';
import RecurringEarningsFields from '../EmployeeMasterPages/components/RecurringEarningsFields';
import SelfServiceFields from '../EmployeeMasterPages/components/SelfServiceFields';
import SpecialInformationFields from '../EmployeeMasterPages/components/SpecialInformationFields';
import VisaStatusFields from '../EmployeeMasterPages/components/VisaStatusFields';
import YearToDateFields from '../EmployeeMasterPages/components/YearToDateFields';
import HireGeneralFields from '../HRInfoPages/components/HireGeneralFields';
import EmployeeStatusDatesFields from '../HRInfoPages/components/EmployeeStatusDates';
import AcaFieldsHr from '../HRInfoPages/components/ACAFieldsHr';
import AcaFields from '../EmployeeMasterPages/components/ACAFields';

type Props = {
  pageName: string,
  title: string,
};

const HandleInfoFields = ({ pageName, title }: Props) => {
  const pageSections = useAppSelector((state) => { return state.downloader.pageSectionFields; });
  const isLoading = useAppSelector(({ employeeEarningsDownload }) => employeeEarningsDownload.loading );

  const { handleSubmit, reset, control, ...formMethods } = useForm<{ selectedFields: DownloaderField[], }>({
    defaultValues: {
      selectedFields: [],
    },
  });
  
  const pageSectionFields = useMemo(() => {
    const sections = pageSections?.find((p) => { return p.pageName === pageName; })?.sections as PageSection[];

    return sections ?? [];
  }, [pageSections, pageName]);

  useEffect(() => {
    const sFields = pageSectionFields?.map(f => { return f.fields; }).flat();
    reset({ selectedFields: sFields });
  }, [pageSectionFields]);

  const DEFAULT_PROPS = {
    formMethods,
    sectionFields: pageSectionFields,
  };

  return (
    <div className="shadow border p-3 dm-panel dm-panel-border">
      <div className="dm-grid-title pb-2">
        {title}
      </div>
      <div>
        Select fields to include in the report map
      </div>
      {!isLoading && (
        <>
          {/* Switch Field Forms By component */}
          {pageName === 'Employee Earnings Download' &&
            <EarningsFields {...DEFAULT_PROPS} />
          }
          {pageName === 'Employee Information' && (
            <EmployeeInfoFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Tax Information' && (
            <TaxInfoFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Emergency Contact' && (
            <EmergencyContactFeilds {...DEFAULT_PROPS} />
          )}
          {pageName === 'Employee Deductions' && (
            <DeductionsFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'ACA' &&
            <AcaFields {...DEFAULT_PROPS} />
          }
          {pageName === 'Accruals' && (
            <AccrualsFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Company Benefits' && (
            <CompanyBenefitsFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Deferred Comp' && (
            <DeferredCompFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Group Term Life' && (
            <GroupTermLifeFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'HR Doctor Compensation' && (
            <HrDoctorCompFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'HR FTE' && (
            <HrFteFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Payroll Information' && (
            <PayrollInformationFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Recurring Earnings' && (
            <RecurringEarningsFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Self Service' && (
            <SelfServiceFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Special Information' && (
            <SpecialInformationFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Visa Status' && (
            <VisaStatusFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Year To Date' && (
            <YearToDateFields {...DEFAULT_PROPS} />
          )}

          {/* HR Info */}
          {pageName === 'Hire General' && (
            <HireGeneralFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'ACA Status History' &&
            <AcaFieldsHr {...DEFAULT_PROPS} />
          }
          {pageName === 'Employee General' && (
            <EmployeeStatusGeneralFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Employee Dates' && (
            <DatesFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Employee Dates HR' && (
            <EmployeeStatusDatesFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Employee Status' && (
            <EmployeeStatusStatusFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Emergency Contact HR' && (
            <EmployeeStatusEmergencyContactFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Pay' && (
            <EmployeeStatusPayFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'HR Custom' && (
            <EmployeeStatusCustomFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Company Property' && (
            <EmployeeStatusCompanyPropertyFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Dependents' && (
            <EmployeeStatusDependentsFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Employment History' && (
            <TrainingEmploymentFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Education' && (
            <TrainingEducationFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Training Events' && (
            <TrainingEventsFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Licenses' && (
            <TrainingLicencesFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Employee Skills' && (
            <TrainingSkillsFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Source and Fees' && (
            <TrainingSourcesFeesFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Exit Interview' && (
            <AppraisalExitInterviewFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Reviews' && (
            <AppraisalReviewsFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Increase' && (
            <AppraisalIncreaseFields {...DEFAULT_PROPS} />
          )}
          {pageName === 'Vaccination' && (
            <VaccinationFields {...DEFAULT_PROPS} />
          )}
        </>
      )}
    </div>
  );
};

HandleInfoFields.displayName = 'HandleInfoFields';

export default HandleInfoFields;