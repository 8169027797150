import { currencyFormatter } from './utilities';

export type PropType = {
  [key: string]: any;
};

export type GenericPropType<T> = Record<keyof T, any>;

/* 
  For converting numbers to a certain precision. At the moment this is a little redundant because we need keys in here
  AND currencyProps, but it's safer for the moment.
 */
export type CurrencyPrecisionMap = { [key: string]: number };

export type GenericCurrencyPrecisionMap<T> = Record<keyof T, number>;

export type PropTypeLists = {
  dateProps?: string[];
  numberProps?: string[];
  nullNumberProps?: string[];
  floatProps?: string[];
  currencyProps?: string[];
  boolProps?: string[];
  radioProps?: string[];
  currencyPrecisionMap?: CurrencyPrecisionMap;
};

export type GenericPropTypeLists<T> = {
  dateProps?: string[];
  numberProps?: string[];
  nullNumberProps?: string[];
  floatProps?: string[];
  currencyProps?: string[];
  boolProps?: string[];
  radioProps?: string[];
  currencyPrecisionMap?: GenericCurrencyPrecisionMap<T>;
};

export const stringToInt = (i: any) => {return typeof i === 'string' ? (i ? parseInt(i.replace(/[^0-9.+-]/g, '')) : 0) : i;};
export const stringToNullInt = (i: any) => {
  return typeof i === 'string'
    ? i
      ? parseInt(i.replace(/[^0-9.+-]/g, ''))
      : null
    : i;
};

export const dateToString = (d: Date | string | null) => {return d instanceof Date ? d.toISOString() : d;};
export const stringToDate = (d: Date | string | null) => {return typeof d === 'string' ? new Date(d) : d;};

export const stringToFloat = (i: number | string | null) => {
  return typeof i === 'string'
    ? i
      ? parseFloat(i.replace(/[^0-9.+-]/g, ''))
      : 0
    : i;
};

export const stringToBool = (i: boolean | string | null) => {return typeof i === 'string' ? (i.toLowerCase() === 'true' ? true : false) : i;};
export const boolToString = (b: boolean | string | null) => {return typeof b === 'boolean' ? b.toString() : b;};
export const boolToRadio = (b: boolean | null) => {return b === true ? 'yes' : b === false ? 'no' : 'unknown';};
export const radioToBool = (b: string | null) => {return b === 'yes' ? true : b === 'no' ? false : null;};

export const convToClass = (
  that: PropType,
  props: PropType,
  {
    dateProps,
    numberProps,
    nullNumberProps,
    floatProps,
    currencyProps,
    boolProps,
    radioProps,
  }: PropTypeLists,
) => {
  Object.keys(props).forEach((prop: string) => {
    if (dateProps?.includes(prop)) {
      that[prop] = dateToString(props[prop]);
    } else if (numberProps?.includes(prop)) {
      that[prop] = stringToInt(props[prop]);
    } else if (nullNumberProps?.includes(prop)) {
      that[prop] = stringToNullInt(props[prop]);
    } else if (floatProps?.includes(prop) || currencyProps?.includes(prop)) {
      that[prop] = stringToFloat(props[prop]);
    } else if (boolProps?.includes(prop)) {
      that[prop] = stringToBool(props[prop]);
    } else if (radioProps?.includes(prop)) {
      that[prop] = radioToBool(props[prop]);
    // eslint-disable-next-line no-extra-boolean-cast
    } else if (Boolean(Object.getOwnPropertyDescriptor(that, prop))) {
      that[prop] = props[prop];
    }
  });
};

// TODO add ssn & phone
export const convToFormObject = (
  props: PropType,
  { dateProps, boolProps, currencyProps, radioProps, currencyPrecisionMap = {} }: PropTypeLists,
): PropType => {
  const formObject: PropType = {};

  Object.keys(props).forEach((prop: string) => {
    if (dateProps?.includes(prop)) {
      formObject[prop] = stringToDate(props[prop]);
    } else if (boolProps?.includes(prop)) {
      formObject[prop] = boolToString(props[prop]);
    } else if (currencyProps?.includes(prop)) {
      formObject[prop] = currencyFormatter(props[prop], currencyPrecisionMap?.[prop] ?? 2);
    } else if (radioProps?.includes(prop)) {
      formObject[prop] = boolToRadio(props[prop]);
    } else {
      formObject[prop] = props[prop];
    }
  });
  return formObject;
};

export function convToFormObjectGeneric<T>(
  props: GenericPropType<T>,
  { dateProps, boolProps, currencyProps, radioProps, currencyPrecisionMap }: GenericPropTypeLists<T>,
): Partial<GenericPropType<T>> {
  const formObject: Partial<GenericPropType<T>> = {};

  Object.keys(props).forEach((prop) => {
    const key = prop as keyof Partial<GenericPropType<T>>;
    
    if (dateProps?.includes(prop)) {
      formObject[key] = stringToDate(props[key]);
    } else if (boolProps?.includes(String(key))) {
      formObject[key] = boolToString(props[key]);
    } else if (currencyProps?.includes(String(key))) {
      formObject[key] = currencyFormatter(props[key], currencyPrecisionMap?.[key] ?? 2);
    } else if (radioProps?.includes(String(key))) {
      formObject[key] = boolToRadio(props[key]);
    } else {
      formObject[key] = props[key];
    }
  });
  return formObject;
}