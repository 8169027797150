import React from 'react';
import Modal from './Modal';
import MyInfoChanges from 'features/emp-detail/my-info-changes/MyInfoChanges';
import { MyInfoChangeLog } from 'core/models/MyInfoChangeLog';

type Props = {
  myInfoChange: MyInfoChangeLog;
  show: boolean;
  onHide: () => void;
  validating?: boolean;
};

const MyInfoChangesModal = ({ myInfoChange, show, onHide, validating: validate = false }: Props) => {
  return (
    <Modal
      title="MyInfo Pending Changes"
      size="lg"
      show={show}
      onHide={onHide}
    >
      <div className="d-flex flex-column">
        {validate && (
          <p className="notice-msg">
            <strong>Notice:</strong> The following MyInfo changes must be approved or denied before continuing.
          </p>
        )}
        {myInfoChange && (
          <MyInfoChanges myInfoChange={myInfoChange} />
        )}
      </div>
    </Modal>
  );
};

export default MyInfoChangesModal;