import { createAction } from '@reduxjs/toolkit';
import { ClientRateRule } from 'core/models/ClientRateRule.model';

export const RATERULES_EM_VALIDATION_ACTIONS = {
  CREATE_CLIENT_RATE_RULE: '[client-rate-rules] CREATE_CLIENT_RATE_RULE',
  DELETE_CLIENT_RATE_RULE: '[client-rate-rules] DELETE_CLIENT_RATE_RULE',
} as const;

export const loadClientRateRule = createAction<number>('[client-rate-rules] LOAD_CLIENT_RATE_RULE');
export const storeClientRateRule = createAction<ClientRateRule>('[client-rate-rules] STORE_CLIENT_RATE_RULE');
export const createClientRateRule = createAction<ClientRateRule>(RATERULES_EM_VALIDATION_ACTIONS.CREATE_CLIENT_RATE_RULE);
export const deleteClientRateRule = createAction<ClientRateRule>(RATERULES_EM_VALIDATION_ACTIONS.DELETE_CLIENT_RATE_RULE);



