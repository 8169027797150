import React, { useEffect, useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import PdfViewer from 'core/components/shared/PDFViewer/PdfViewer';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import chevronLeft from '../../../assets/images/chevron-left-nav.png';
import { NavLink, useHistory } from 'react-router-dom';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { GridReadyEvent, RowDoubleClickedEvent } from '@ag-grid-community/core';

import { GeneralLedgerDetailReportRequest, LedgerDetail } from 'core/models/AccountingReports.model';
import { clearGeneralLedgerDetailReport, clearGeneralLedgerExportReport, clearGeneralLedgerReport, clearGeneralLedgerReportErrors, downloadGeneralLedgerDetailReport } from 'core/store/actions';
import { DtoOptions } from 'core/models';
import ThirdPartyExport from './ThirdPartyExport';
import { agCurrencyRenderer } from 'utilities/ag-grid-renderers';

const GeneralLedgerPdfViewer: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const client = useAppSelector((state) => {return state?.client.client;});
  const report = useAppSelector((state) => {return state?.accountingReports.generalLedgerReport;});
  const detailReportStore = useAppSelector((state) => {return state?.accountingReports.generalLedgerDetailReport;});
  const [detailReport, setDetailReport] = useState('');
  const [rowData, setRowData] = useState<LedgerDetail[]>([]);
  const [dtoOptions, setDtoOptions] = useState<DtoOptions[]>([]);
  const [groupByLocation, setGroupByLocation] = useState<boolean>();

  useEffect(() => {
    dispatch(clearGeneralLedgerDetailReport());
    dispatch(clearGeneralLedgerExportReport());
    setDetailReport('');
  }, []);

  useEffect(() => {
    if (report) {
      report.generalLedgerDetailReportList && setRowData(report.generalLedgerDetailReportList);
      report.dtoOptions && setDtoOptions(report.dtoOptions);
      setGroupByLocation(report.groupByLocation);
    }
    else{
      history.push('/general-ledger-report');
    }
  }, [report]);

  useEffect(() => {
    detailReportStore && setDetailReport(detailReportStore);
  }, [detailReportStore]);

  const onGridReady = (params: GridReadyEvent) => {
    params.api.sizeColumnsToFit();
  };

  const setSelectedRow = (row: LedgerDetail): void => {
    const request: GeneralLedgerDetailReportRequest = {
      glDescription: row.glDescription,
      debitOrCredit: row.debitOrCredit,
      accountNumber: row.accountNumber,
      accountDesc: row.accountDesc,
      gl: row.gl,
      glDebitAmount: row.glDebitAmount,
      glCreditAmount: row.glCreditAmount,
      clientNo: client?.clientNo ? +client.clientNo : 0,
      dtoOptions: dtoOptions ?? [],
    };

    dispatch(downloadGeneralLedgerDetailReport(request));
  };

  const onRowDoubleClick = (e: RowDoubleClickedEvent) => {
    if (e.api) {
      const selectedRows = e.api.getSelectedRows();
      selectedRows.length > 0 && setSelectedRow(selectedRows[0]);
    }
  };

  const onClick = () => {
    dispatch(clearGeneralLedgerReportErrors());
    dispatch(clearGeneralLedgerReport());
    dispatch(clearGeneralLedgerExportReport());
    setDetailReport('');
  }

  return (
    <div
      className="dm-panel dm-panel-border"
      style={{ minHeight: '500px' }}
    >
      <div className="w-100">
        <NavLink to="/general-ledger-report">
          <img
            src={chevronLeft}
            alt="<"
          />
          <Button
            variant="link"
            className="pr-2 dm-grid-action-title2"
            onClick={onClick}
          >
            Back To Report Generator
          </Button>
        </NavLink>
      </div>
      <div>
        <div>
          <div className="dm-grid-title">General Ledger Report</div>
        </div>
        <div className="text-right">
          {groupByLocation !== undefined ?
            <ThirdPartyExport
              groupByLocation={groupByLocation}
              dtoOptions={dtoOptions}
            />
            : null}
        </div>
        <hr className="dm-panel-hr" />
        <Tabs>
          <Tab
            key={0}
            eventKey={0}
            title={'General Ledger'}
          >
            {report?.reportData ? <div>
              <PdfViewer
                pdfData={report?.reportData ?? ''}
                reportName={'General Ledger Report'}
              />
            </div> : null}
          </Tab>
          {rowData.length ? <Tab
            key={1}
            eventKey={1}
            title={'Report Detail'}
          >
            <div className="d-flex mt-3">
              <div className="d-flex flex-row flex-grow-1">

                <div className="d-flex flex-grow-1">
                  <div className="ag-theme-balham w-100">
                    <AgGridReact
                      className="w-100"
                      rowData={rowData}
                      defaultColDef={{ resizable: true,
                        sortable: true }}
                      onGridReady={onGridReady}
                      onRowDoubleClicked={onRowDoubleClick}
                      rowSelection="single"
                      components={{ agCurrencyRenderer }}
                    >
                      <AgGridColumn
                        field="glDescription"
                        headerName="GL Description"
                      />
                      <AgGridColumn
                        field="debitOrCredit"
                        headerName="DB/CR"
                        width="80"
                      />
                      <AgGridColumn
                        field="accountNumber"
                        headerName="Acc No"
                        width="100"
                      />
                      <AgGridColumn
                        field="accountDesc"
                        headerName="Acc Desc"
                      />
                      <AgGridColumn
                        field="glDebitAmount"
                        headerName="Debit"
                        cellRenderer="agCurrencyRenderer"
                        cellStyle={{ textAlign: 'right' }}
                        width="80"
                      />
                      <AgGridColumn
                        field="glCreditAmount"
                        headerName="Credit"
                        cellRenderer="agCurrencyRenderer"
                        cellStyle={{ textAlign: 'right' }}
                        width="80"
                      />
                    </AgGridReact>
                  </div>
                </div>

                <div
                  className="d-flex  mx-3"
                  style={{ borderRight: 'solid 1px #dee2e6' }}
                ></div>
                {detailReport ?
                  <div
                    className="d-flex flex-grow-1"
                    style={{ width: '45%' }}
                  >
                    <PdfViewer
                      pdfData={(detailReport) ?? ''}
                      reportName={'Report Detail'}
                    />
                  </div>
                  : null}
              </div>
            </div>
          </Tab>
            : null}
        </Tabs>
      </div>
    </div>
  );
};

export default GeneralLedgerPdfViewer;