import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EmpFte, HttpResponse } from '../../models';
import { EmployeeFteService } from '../../services';
import {
  createEmployeeFte,
  deleteEmployeeFte,
  handleError,
  loadEmployeeFte,
  storeEmployeeFte,
  storeEmployeeFtes,
  updateEmployeeFte,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadEmployeeFte$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmployeeFte.type),
    switchMap((action: { payload: { protectedEmpNo: string; }; }) => {
      return from(
        EmployeeFteService.getEmployeeFte(
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: EmpFte) => { return storeEmployeeFtes(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createEmployeeFte$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createEmployeeFte.type),
    switchMap((action: { payload: EmpFte; }) => {
      return from(EmployeeFteService.postEmployeeFte(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => {
          return storeEmployeeFte(res.value);
        }),
        catchError((err: HttpResponse<any>) => {
          return [handleError(err)];
        }),
      );
    },
    ),
  );
};

const updateEmployeeFte$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateEmployeeFte.type),
    switchMap((action: { payload: EmpFte; }) => {
      return from(EmployeeFteService.putEmployeeFte(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => {
          return storeEmployeeFte(res.value);
        }),
        catchError((err: HttpResponse<any>) => {
          return [handleError(err)];
        }),
      );
    },
    ),
  );
};

const deleteEmployeeFte$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteEmployeeFte.type),
    switchMap((action: { payload: { protectedEmpNo: any; }; }) => {
      return from(EmployeeFteService.deleteEmployeeFte(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map(() => {
          return loadEmployeeFte({
            protectedEmpNo: action.payload.protectedEmpNo ?? '',
          });
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadEmployeeFte$,
  createEmployeeFte$,
  updateEmployeeFte$,
  deleteEmployeeFte$,
];
