import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import '../time-card-styles.scss';
import EmployeeRosterGroup from './EmployeeRosterGroup';
import { useSelector } from 'react-redux';
import { getEmployees } from 'core/store/selectors';
import { Employee } from 'core/models';
import { InputGrp } from 'core/components/form-controls';
import { currencyFormatter } from 'utilities/utilities';
import { TimeCardJobRoster, TimeCardJobRosterDetailEmpInfo, TimeCardJobRosterHours } from 'core/models/Contractor.model';
import Icon from 'core/components/shared/Icon';
import { useAppSelector } from 'utilities/hooks';

type Props = {
  selectedJob: TimeCardJobRoster;
};

const filterEmployees = (details: TimeCardJobRosterDetailEmpInfo[], query?: string): TimeCardJobRosterDetailEmpInfo[] => {
  return details.filter((detail) => query ? detail.empNo.toString().includes(query) || detail.employeeName.toLowerCase().includes(query) : true);
};

const printCurrentHour = (hourRecord: TimeCardJobRosterHours) => {
  return <div><strong>{hourRecord.day}, {hourRecord.fullDate}:&nbsp;</strong>{hourRecord.amount.toFixed(2)}</div>
}

const JobRosterDetails = ({ selectedJob }: Props) => {
  const employees = useSelector(getEmployees); 
  const isBiWeeklyClient = useAppSelector(({ client }) => client.client?.clientPayrollFreq)?.toLowerCase() === 'bi-weekly';
  const [groupState, setGroupState] = useState<TimeCardJobRosterDetailEmpInfo[]>(selectedJob.employees);
  const [expandAll, setExpandAll] = useState<boolean>(false);

  const hoursTotalList1 = selectedJob.hoursTotalByDay.slice(0,7);
  const hoursTotalList2 = (isBiWeeklyClient) ? selectedJob.hoursTotalByDay.slice(7,14) : [];

  useEffect(() => {
    setGroupState(selectedJob.employees);
    setExpandAll(false);
  }, [selectedJob]);
   
  const handleEmployeeSearch = (query: string) => {
    if (!query.trim().length) {
      setGroupState(selectedJob.employees);
      return;
    }
    
    setGroupState(filterEmployees(selectedJob.employees, query));
  };
  
  return (
    <div className={styles['detail-column']}>
      <div className={styles['job-info-tools']}>
        <div>
          <label
            htmlFor="emp-search"
            className={styles['top-label']}
          >Filter employees</label>
          <InputGrp
            placeholder="Search"
            inputClass={`gc20 ${styles.input}`}
            name="emp-search"
            type="text"
            onChange={(e: any) => {
              handleEmployeeSearch(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="d-flex flex-wrap">
        <div className="head-section mr-2">
          <strong>Job Totals</strong>
          <div><strong>Hours:&nbsp;</strong>{selectedJob.hoursTotal?.toFixed(2)}</div>
          <div><strong>Earnings:&nbsp;</strong>{currencyFormatter(selectedJob.earningsTotal)}</div>
          <div><strong>Deductions:&nbsp;</strong>{currencyFormatter(selectedJob.deductionTotal)}</div>
        </div>
        <div className="head-section">
          <strong>{(isBiWeeklyClient) ? 'Hour Totals Week 1' : 'Hours Totals'}</strong>
          {hoursTotalList1?.map(x => printCurrentHour(x))}
        </div>
        {isBiWeeklyClient ? <div className="head-section">
          <strong>{'Hour Totals Week 2'}</strong>
          {hoursTotalList2?.map(x => printCurrentHour(x))}
        </div> : null}
        <button
            className="btn btn-link"
            onClick={() => { setExpandAll(!expandAll); }}
          >
            {expandAll ? 'Collapse' : 'Expand'} all&nbsp;<Icon name={expandAll ? 'chevron-circle-up' : 'chevron-circle-down'} />
        </button>
      </div>
      <div
        className="dm-panel-border w-100"
        style={{ overflowY: 'auto' }}
      >
        <div className={styles['job-details']}>
          {groupState?.map((empDetail) => {
            const employee: Employee | undefined = employees.find((emp) => emp.empNo === empDetail.empNo);
        
            return employee ? (
              <EmployeeRosterGroup
                employee={employee}
                detailEmployee={empDetail}
                expandAll={expandAll}
              />
            ) : null;
          })}
        </div>
      </div>
    </div>

  );
};

export default JobRosterDetails;