import { AxiosResponse } from 'axios';
import { ApClientVendor } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getApClientVendors = (): Promise<AxiosResponse<ApClientVendor[]>> => { return API_EMPLOYEE_MASTER.get<ApClientVendor[]>(EmployeeEndPoints.GET_AP_CLIENT_VENDORS(CommonService.getProtectedClientNo())); };

const postApClientVendor = (data: ApClientVendor): Promise<AxiosResponse<ApClientVendor>> => { return API_EMPLOYEE_MASTER.post<ApClientVendor>(EmployeeEndPoints.POST_AP_CLIENT_VENDOR(), data); };

const putApClientVendor = (data: ApClientVendor): Promise<AxiosResponse<ApClientVendor>> => { return API_EMPLOYEE_MASTER.put<ApClientVendor>(EmployeeEndPoints.PUT_AP_CLIENT_VENDOR(data.apVendorId), data); };

export const ApClientVendorService = {
  getApClientVendors,
  postApClientVendor,
  putApClientVendor,
};