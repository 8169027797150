import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideRootModal } from '../../core/store/actions';
import { appModal } from '../../core/store/selectors';
import AppSearchListingModal from './AppSearchListing.modal';

const MODAL_COMPONENTS: { [key: string]: React.FunctionComponent<any> } = {
  'APP_SEARCH_LISTING_MODAL': AppSearchListingModal,
};

const RootModal: React.FC<any> = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const modal = useSelector(appModal);
  
  useEffect(() => {
    setShow(!!modal?.modalType);
  }, [modal]);


  const onHide = () => {
    dispatch(hideRootModal());
    setShow(false);
  };

  if (!modal.modalType) {
    return null;
  }

  const ModalComponent = MODAL_COMPONENTS[modal.modalType];
  if (!ModalComponent) {
    return null;
  }

  return <ModalComponent
    show={show}
    onHide={onHide}
    {...modal.modalProps}
  />;
};

export default RootModal;