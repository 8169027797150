import { Observable, from, map, tap, mergeMap, switchMap, catchError } from 'rxjs';
import { Actions, Epic } from './types';
import { ofType } from 'redux-observable';
import { /* addJobInfoFromFile, */ addUpdateDiagnosticRule, addUpdateJobInfo, deleteJobInfo, getAllJobs, getDiagnosticRule, getJobInfo, getTaxingCities, handleError, handleSuccess, storeAllJobs, storeDiagnosticRule, storeJobInfo, storeTaxingCities } from '../actions';
import { ContractorService } from 'core/services';
import { JobInfoFromFileRequest, JobInfoRequest, PayrollTimeCardDiagnosticRules, PayrollTimeCardJobInfo } from 'core/models/Contractor.model';
import { HttpResponse } from 'core/models';

const getAllJobs$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(getAllJobs.type),
    switchMap(() => {
      return from(ContractorService.getAllJobs())
        .pipe(
          map((res: any) => { return storeAllJobs(res.data); }),
          catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
        );
    }),
  );
};

const getJobInfo$ = (action$: Observable<Actions<JobInfoRequest>>) => {
  return action$.pipe(
    ofType(getJobInfo.type),
    switchMap((action: { payload: JobInfoRequest }) => {
      return from(ContractorService.getJobInfo(action.payload))
        .pipe(
          map((res: any) => { return storeJobInfo(res.data[0]); }),
          catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
        );
    }),
  );
};

const addUpdateJobInfo$ = (action$: Observable<Actions<PayrollTimeCardJobInfo>>) => {
  return action$.pipe(
    ofType(addUpdateJobInfo.type),
    switchMap((action: { payload: PayrollTimeCardJobInfo }) => {
      return from(ContractorService.addUpdateJobInfo(action.payload))
        .pipe(
          map((res: any) => { return res.data; }),
          mergeMap((res: any) => {
            return [
              storeJobInfo(res.value),
              handleSuccess(res.messages),
            ];
          }),
          catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
        );
    }),
  );
};

const deleteJobInfo$ = (action$: Observable<Actions<JobInfoRequest>>) => {
  return action$.pipe(
    ofType(deleteJobInfo.type),
    switchMap((action: { payload: JobInfoRequest }) => {
      return from(ContractorService.deleteJobInfo(action.payload))
        .pipe(
          map((res: any) => { return res.data; }),
          mergeMap((res: any) => {
            return [
              handleSuccess(res.messages),
            ];
          }),
          catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
        );
    }),
  );
};

const getDiagnosticRule$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(getDiagnosticRule.type),
    switchMap(() => {
      return from(ContractorService.getDiagnosticRule())
        .pipe(
          map((res: any) => { return storeDiagnosticRule(res.data); }),
          catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
        );
    }),
  );
};

const addUpdateDiagnosticRule$ = (action$: Observable<Actions<PayrollTimeCardDiagnosticRules>>) => {
  return action$.pipe(
    ofType(addUpdateDiagnosticRule.type),
    switchMap((action: { payload: PayrollTimeCardDiagnosticRules }) => {
      return from(ContractorService.addUpdateDiagnosticRule(action.payload))
        .pipe(
          map((res: any) => { return res.data; }),
          mergeMap((res: any) => {
            return [
              storeDiagnosticRule(res.value),
              handleSuccess(res.messages),
            ];
          }),
          catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
        );
    }),
  );
};

const getTaxingCities$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(getTaxingCities.type),
    switchMap(() => {
      return from(ContractorService.getTaxingCities()).pipe(
        map((res: any) => { return storeTaxingCities(res.data); }),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    }),
  );
};

export const epics: Epic[] = [
  getAllJobs$,
  getJobInfo$,
  addUpdateJobInfo$,
  deleteJobInfo$,
  getDiagnosticRule$,
  addUpdateDiagnosticRule$,
  getTaxingCities$,
];