import { createReducer } from '@reduxjs/toolkit';
import { EmailTemplate, FieldConfig, JazzHRApplicantDetail, JazzHRReturnData, OnboardDoc, OnboardingUpdate, OnboardPayrollUser, PageConfig, OnboardingSteps } from '../../models';
import {
  createOnboardEmployee,
  createOnboardEmployeeSuccess,
  loadNextEmployeeNumber,
  loadOnboardPayrollUsers,
  loadOnboardDocs,
  loadOnboardEmailTemplates,
  loadOnboardPageConfigs,
  setOnboardEmployeeStatus,
  storeNextEmployeeNumber,
  storeOnboardDocs,
  storeOnboardEmailTemplates,
  storeOnboardPageConfigs,
  storeOnboardPayrollUsers,
  storeOnboardUpdates,
  updateOnboardPageConfig,
  loadJazzHrIntegration,
  storeJazzHrIntegration,
  clearNextEmpNo,
  loadOnboardingStepsStatus,
  storeOnboardingStepsStatus,
} from '../actions';

export interface State {
  loading: boolean;
  status: any;
  nextEmpNo: number;
  onboardDocs: OnboardDoc[];
  pageConfigs: PageConfig[];
  payrollUsers: OnboardPayrollUser[];
  emailTemplates: EmailTemplate[];
  onboardUpdates: OnboardingUpdate[];
  jazzHRApplicants: JazzHRReturnData | null;
  selectedJazzHRApplicant: JazzHRApplicantDetail | null;
  onboardingStepsStatus: OnboardingSteps | null;
}

export const INITIAL_STATE: State = {
  loading: false,
  status: null,
  nextEmpNo: 0,
  onboardDocs: [],
  pageConfigs: [],
  payrollUsers: [],
  emailTemplates: [],
  onboardUpdates: [],
  jazzHRApplicants: null,
  selectedJazzHRApplicant: null,
  onboardingStepsStatus: null,
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(createOnboardEmployee, (state) => {
      state.loading = true;
      state.status = 'posting';
    })
    .addCase(createOnboardEmployeeSuccess, (state) => {
      state.loading = false;
      state.status = 'success';
    })
    .addCase(setOnboardEmployeeStatus, (state, action) => {
      state.loading = false;
      state.status = action.payload;
    })
    .addCase(loadNextEmployeeNumber, (state) => {
      state.loading = true;
    })
    .addCase(storeNextEmployeeNumber, (state, action) => {
      state.loading = false;
      state.nextEmpNo = action.payload;
    })
    .addCase(clearNextEmpNo, (state) => {
      state.nextEmpNo = INITIAL_STATE.nextEmpNo;
    })
    .addCase(loadOnboardPayrollUsers, (state) => {
      state.loading = true;
    })
    .addCase(storeOnboardPayrollUsers, (state, action) => {
      state.loading = false;
      state.payrollUsers = action.payload;
    })
    .addCase(loadOnboardDocs, (state) => {
      state.loading = false;
    })
    .addCase(storeOnboardDocs, (state, action) => {
      state.loading = false;
      state.onboardDocs = action.payload;
    })
    .addCase(loadJazzHrIntegration, (state) => {
      state.loading = true;
    })
    .addCase(storeJazzHrIntegration, (state, action) => {
      state.loading = false;
      state.jazzHRApplicants = action.payload;
    })
    .addCase(loadOnboardPageConfigs, (state) => {
      state.loading = true;
    })
    .addCase(storeOnboardPageConfigs, (state, action) => {
      state.loading = false;
      state.pageConfigs = action.payload;
    })
    .addCase(updateOnboardPageConfig, (state, action) => {
      const { pageID, fieldID, required } = action.payload;
      const pageConfigToUpdate = state.pageConfigs.find((pc: PageConfig) => pc.pageID === pageID);
      const fieldConfigToUpdate = pageConfigToUpdate?.fieldConfigs.find((fc: FieldConfig) => fc.fieldID === fieldID);
      if (!pageConfigToUpdate || !fieldConfigToUpdate) return console.error('Could not resolve page and field configs to update');

      const newPageConfig: PageConfig = {
        ...pageConfigToUpdate,
        fieldConfigs: [
          ...pageConfigToUpdate.fieldConfigs.filter((fc: FieldConfig) => fc.fieldID !== fieldID),
          { ...fieldConfigToUpdate,
            required },
        ],
      };
      
      state.loading = false;
      state.pageConfigs = [...state.pageConfigs.filter((pc: PageConfig) => pc.pageID !== pageID), newPageConfig]; // do we want this appended?
    })
    .addCase(loadOnboardEmailTemplates, (state) => {
      state.loading = true;
    })
    .addCase(storeOnboardEmailTemplates, (state, action) => {
      state.loading = false;
      state.emailTemplates = action.payload;
    })
    .addCase(storeOnboardUpdates, (state, action) => {
      state.loading = false;
      state.onboardUpdates = action.payload;
    })
    .addCase(loadOnboardingStepsStatus, (state) => {
      state.loading = true;
    })
    .addCase(storeOnboardingStepsStatus, (state, action) => {
      state.loading = false;
      state.onboardingStepsStatus = action.payload;
    })
  ;
});