import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { IntegrationLog, IntegrationLogDetailRequest } from '../../models/IntegrationLog.model';
import { IntegrationService } from 'core/services';
import { handleError } from './app.action';

export const loadIntegrationLogs = createAction('[integration] LOAD_INTEGRATION_LOGS');
export const storeIntegrationLogs = createAction<IntegrationLog[]>('[my-info-changes] STORE_INTEGRATION_LOGS');

export const getIntegrationLogDetails = createAsyncThunk(
    'workFlow/getWorkFlowTasks',
    async (request: IntegrationLogDetailRequest, { dispatch }) => {
      try {
        const response = await IntegrationService.getClientIntegrationLogDetails(request);
        
        return response?.data || response;
      } catch (err) {
        dispatch(handleError(err));
      }
    },
  );