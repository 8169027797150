import { React, Component, createContext, useContext } from 'react';
import UserAuthService from '../services/userAuth.service';

const AuthContext = createContext({
  getUser: async () => {return {}; },
  logout: async () => { return {}; },
  signoutRedirectCallback: async () => { return {}; },
  isAuthenticated: () => { return {}; },
  signInRedirect: () => { return {}; },
});

// For class components;
export const AuthConsumer = AuthContext.Consumer;

// For functional components;
export const useAuth = () => {
  return useContext(AuthContext);
};

export class AuthProvider extends Component {
  userAuthService;

  constructor(props) {
    super(props);
    this.userAuthService = new UserAuthService();
  }

  render() {
    return (
      <AuthContext.Provider value={this.userAuthService}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
