import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  ControlDatePickerGrp,
  InputGrp,
  InputGrpInLine,
  RadioGrpInLine,
  SelectGrp,
} from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { SSN_VALIDATION } from 'core/constants';
import { EmpI9 } from 'core/models';
import {
  clearEmployeeI9DataReport,
  loadEmployee,
  loadEmployeeI9DataReport,
  loadEmployeesI9,
  updateEmployeeI9,
} from 'core/store/actions';
import {
  getAllEmpI9,
  getEmpI9DataReport,
  getI9DocumentTitles,
  getSelectedEmp,
  getStates,
} from 'core/store/selectors';
import {
  formatFromSSN,
  formatSSN,
} from 'utilities/utilities';
import searchIcon from 'assets/images/search.png';
import { Prompt } from 'react-router-dom';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import EmpPhoto from 'core/components/shared/EmpPhoto';
import { CommonService } from 'core/services';
import Icon from 'core/components/shared/Icon';

const citizenRadioOptions = [
  {
    value: '1',
    label: '1. A citizen of the United States',
  },
  {
    value: '2',
    label: '2. A noncitizen national of the United States',
  },
  {
    value: '3',
    label: '3. A lawful permanent resident (Alien Registration Number/ USCIS Number):',
  },
  {
    value: '4',
    label: '4. An alien authorized to work until (expiration date, if applicable):',
  },
];

const preparerRadioOptions = [
  {
    value: 'false',
    label: 'I did not use a preparer or translator',
  },
  {
    value: 'true',
    label: 'A preparer assisted in completing section one',
  },
];

const fs: FieldInputSettings = {
  pendingI9: {
    name: 'pendingI9',
    addEmptyText: 'Pending Employees',
    label: '',
    groupClass: 'gc03 mw250',
  },
  completedI9: {
    name: 'completedI9',
    addEmptyText: 'Completed Employees',
    label: '',
    groupClass: 'gc03 mw250',
  },

  // section 1
  firstName: {
    name: 'sectionOne.firstName',
    label: 'FIRST NAME (GIVEN NAME)',
    groupClass: 'gc03 mw250',
  },
  lastName: {
    name: 'sectionOne.lastName',
    label: 'LAST NAME (FAMILY NAME)',
    groupClass: 'gc03 mw250',
  },
  middleInitial: {
    name: 'sectionOne.middleInitial',
    label: 'MIDDLE INITIAL',
    groupClass: 'gc03 mw100',
  },
  otherLastNamesUsed: {
    name: 'sectionOne.otherLastNamesUsed',
    label: 'OTHER LAST NAMES USED',
    groupClass: 'gc03 mw250',
  },
  address: {
    name: 'sectionOne.address',
    label: 'STREET ADDRESS',
    groupClass: 'gc03 mw250',
  },
  aptNumber: {
    name: 'sectionOne.aptNumber',
    label: 'APT. NUMBER',
    groupClass: 'gc03 mw100',
  },
  city: {
    name: 'sectionOne.city',
    label: 'CITY / TOWN',
    groupClass: 'gc03 mw250',
  },
  state: {
    name: 'sectionOne.state',
    label: 'STATE',
    groupClass: 'gc03 mw100',
  },
  zip: {
    name: 'sectionOne.zip',
    label: 'ZIP CODE',
    groupClass: 'gc03 mw100',
  },
  dateOfBirth: {
    name: 'sectionOne.dateOfBirth',
    label: 'DATE OF BIRTH',
    groupClass: 'gc03 mw250',
  },
  ssn: {
    name: 'sectionOne.ssn',
    label: 'SSN',
    groupClass: 'gc03 mw250',
  },
  email: {
    name: 'sectionOne.email',
    label: 'EMPLOYEES EMAIL',
    groupClass: 'gc03 mw150',
  },
  phoneNumber: {
    name: 'sectionOne.phoneNumber',
    label: 'EMPLOYEES PHONE NMBER',
    groupClass: 'gc03 mw250',
  },
  citizenOption: {
    name: 'sectionOne.citizenOption',
    labelWidth: 0,
    radioOptions: citizenRadioOptions,
    required: true,
  },
  alienAuthorizedUntilDate: {
    name: 'sectionOne.alienAuthorizedUntilDate',
    label: '',
    groupClass: 'gc03 mw200',
  },
  alienRegistrationNumberUSCISOne: {
    name: 'sectionOne.alienRegistrationNumberUSCISOne',
    label: '',
    groupClass: 'gc03 mw200',
  },
  alienRegistrationNumberUSCISTwo: {
    name: 'sectionOne.alienRegistrationNumberUSCISTwo',
    label: '1. Alien Registration Number/USCIS Number',
    groupClass: 'gc100 mw500',
  },
  formI94AdmissionNumber: {
    name: 'sectionOne.formI94AdmissionNumber',
    label: '2. Form I-94 Admission Number',
    groupClass: 'gc100 mw500',
  },
  foreignPassportNumber: {
    name: 'sectionOne.foreignPassportNumber',
    label: '3. Foreign Passport Number',
    groupClass: 'gc100 mw500',
  },
  countryOfIssuance: {
    name: 'sectionOne.countryOfIssuance',
    label: 'Country of Issuance:',
    groupClass: 'gc100 mw500',
  },
  employeeSignature: {
    name: 'sectionOne.employeeSignature',
    label: 'EMPLOYEE SIGNATURE',
    groupClass: 'gc03 mw250',
  },
  employeeDateSigned: {
    name: 'sectionOne.employeeDateSigned',
    label: 'DATE SIGNED (MM/DD/YYYY)',
    groupClass: 'gc03 mw250',
  },

  // preparer
  usedPreparer: {
    name: 'preparerDetail.usedPreparer',
    labelWidth: 0,
    radioOptions: preparerRadioOptions,
    groupClass: 'gc100 mw500',
  },
  preparerSignature: {
    name: 'preparerDetail.preparerSignature',
    label: 'SIGNATURE OF PREPARER OR TRANSLATOR',
    groupClass: 'gc03 mw250',
  },
  dateSigned: {
    name: 'preparerDetail.dateSigned',
    label: 'DATE SIGNED (MM/DD/YYYY)',
    groupClass: 'gc03 mw250',
  },
  preparerLastName: {
    name: 'preparerDetail.lastName',
    label: 'LAST NAME (FAMILY NAME)',
    groupClass: 'gc03 mw250',
  },
  preparerFirstName: {
    name: 'preparerDetail.firstName',
    label: 'FIRST NAME (GIVEN NAME)',
    groupClass: 'gc03 mw250',
  },
  preparerAddress: {
    name: 'preparerDetail.address',
    label: 'STREET ADDRESS',
    groupClass: 'gc03 mw250',
  },
  preparerCity: {
    name: 'preparerDetail.city',
    label: 'CITY / TOWN',
    groupClass: 'gc03 mw250',
  },
  preparerZip: {
    name: 'preparerDetail.zip',
    label: 'ZIP CODE',
    groupClass: 'gc03 mw100',
  },

  // section 2
  listADocumentID: {
    name: 'sectionTwo.listADocumentID',
    label: 'DOCUMENT TITLE',
    groupClass: 'gc03 mw250',
  },
  listAIssuingAuthority: {
    name: 'sectionTwo.listAIssuingAuthority',
    label: 'ISSUING AUTHORITY',
    groupClass: 'gc03 mw250',
  },
  listADocumentNumber: {
    name: 'sectionTwo.listADocumentNumber',
    label: 'DOCUMENT NUMBER',
    groupClass: 'gc03 mw250',
  },
  listAExpireDate: {
    name: 'sectionTwo.listAExpireDate',
    label: 'EXPIRATION DATE',
    groupClass: 'gc03 mw250',
  },

  listBDocumentID: {
    name: 'sectionTwo.listBDocumentID',
    label: 'DOCUMENT TITLE',
    groupClass: 'gc03 mw250',
  },
  listBIssuingAuthority: {
    name: 'sectionTwo.listBIssuingAuthority',
    label: 'ISSUING AUTHORITY',
    groupClass: 'gc03 mw250',
  },
  listBDocumentNumber: {
    name: 'sectionTwo.listBDocumentNumber',
    label: 'DOCUMENT NUMBER',
    groupClass: 'gc03 mw250',
  },
  listBExpireDate: {
    name: 'sectionTwo.listBExpireDate',
    label: 'EXPIRATION DATE',
    groupClass: 'gc03 mw250',
  },

  listCDocumentID: {
    name: 'sectionTwo.listCDocumentID',
    label: 'DOCUMENT TITLE',
    groupClass: 'gc03 mw250',
  },
  listCIssuingAuthority: {
    name: 'sectionTwo.listCIssuingAuthority',
    label: 'ISSUING AUTHORITY',
    groupClass: 'gc03 mw250',
  },
  listCDocumentNumber: {
    name: 'sectionTwo.listCDocumentNumber',
    label: 'DOCUMENT NUMBER',
    groupClass: 'gc03 mw250',
  },
  listCExpireDate: {
    name: 'sectionTwo.listCExpireDate',
    label: 'EXPIRATION DATE',
    groupClass: 'gc03 mw250',
  },

  hireDate: {
    name: 'sectionTwo.hireDate',
    label: 'MM/DD/YYYY',
    groupClass: 'gc03 mw250',
  },

  employerSignature: {
    name: 'sectionTwo.employerSignature',
    label: 'SIGNATURE OF EMPLOYER',
    groupClass: 'gc03 mw250',
  },
  employerDateSigned: {
    name: 'sectionTwo.employerDateSigned',
    label: 'DATE SIGNED (MM/DD/YYYY)',
    groupClass: 'gc03 mw250',
  },
  employerTitle: {
    name: 'sectionTwo.employerTitle',
    label: 'TITLE OF EMPLOYER',
    groupClass: 'gc03 mw250',
  },
  employerFirstName: {
    name: 'sectionTwo.employerFirstName',
    label: 'FIRST NAME OF EMPLOYER',
    groupClass: 'gc03 mw250',
  },
  employerLastName: {
    name: 'sectionTwo.employerLastName',
    label: 'LAST NAME OF EMPLOYER',
    groupClass: 'gc03 mw250',
  },
  employerBusinessName: {
    name: 'sectionTwo.employerBusinessName',
    label: "EMPLOYER'S BUSINESS NAME",
    groupClass: 'gc03 mw250',
  },
  employerBusinessAddress: {
    name: 'sectionTwo.employerBusinessAddress',
    label: "EMPLOYER'S BUSINESS ADDRESS",
    groupClass: 'gc03 mw250',
  },
  employerBusinessCity: {
    name: 'sectionTwo.employerBusinessCity',
    label: 'CITY / TOWN',
    groupClass: 'gc03 mw250',
  },
  employerBusinessState: {
    name: 'sectionTwo.employerBusinessState',
    label: 'STATE',
    groupClass: 'gc03 mw250',
  },
  employerBusinessZip: {
    name: 'sectionTwo.employerBusinessZip',
    label: 'ZIP CODE',
    groupClass: 'gc03 mw250',
  },

  // section three
  newFirstName: {
    name: 'sectionThree.newFirstName',
    label: 'FIRST NAME (GIVEN NAME)',
    groupClass: 'gc03 mw250',
  },
  newMiddleInitial: {
    name: 'sectionThree.newMiddleInitial',
    label: 'MIDDLE INITIAL',
    groupClass: 'gc03 mw250',
  },
  newLastName: {
    name: 'sectionThree.newLastName',
    label: 'LAST NAME (FAMILY NAME)',
    groupClass: 'gc03 mw250',
  },
  dateOfRehire: {
    name: 'sectionThree.dateOfRehire',
    label: 'DATE OF REHIRE',
    groupClass: 'gc03 mw250',
  },
  documentTitle: {
    name: 'sectionThree.documentTitle',
    label: 'DOCUMENT TITLE',
    groupClass: 'gc03 mw250',
  },
  documentNumber: {
    name: 'sectionThree.documentNumber',
    label: 'DOCUMENT NUMBER',
    groupClass: 'gc03 mw250',
  },
  expirationDate: {
    name: 'sectionThree.expirationDate',
    label: 'EXPIRATION DATE',
    groupClass: 'gc03 mw250',
  },
  rehireSignature: {
    name: 'sectionThree.rehireSignature',
    label: 'SIGNATURE OF EMPLOYER',
    groupClass: 'gc03 mw250',
  },
  rehireDateSigned: {
    name: 'sectionThree.rehireDateSigned',
    label: 'DATE SIGNED (MM/DD/YYYY)',
    groupClass: 'gc03 mw250',
  },
  rehireEmployer: {
    name: 'sectionThree.rehireEmployer',
    label: 'NAME OF EMPLOYER',
    groupClass: 'gc03 mw250',
  },
};

const checkForNameMatch = (first: string, last: string, match: string) => {
  if (!match) return true;
  const l = match.length;
  const m = match.toLowerCase();
  return (
    (first && first.substring(0, l).toLowerCase() === m) ||
    (last && last.substring(0, l).toLowerCase() === m)
  );
};

const checkForEmpNoMatch = (empNo: number, match: string) => {
  const empNoString = empNo?.toString();
  const l = match.length;
  return empNoString && empNoString.substring(0, l).toLowerCase() === match;
};

const ManageI9Documents: React.FC<any> = () => {
  const dispatch = useDispatch();
  const stateOpts = useSelector(getStates);
  const employee = useSelector(getSelectedEmp);
  const allEmpI9Store = useSelector(getAllEmpI9);
  const i9DocTitleOpts = useSelector(getI9DocumentTitles);
  const report = useSelector(getEmpI9DataReport);
  const [pendingEmpI9Opts, setPendingEmpI9] = useState<{ id: number, description: string }[]>([]);
  const [completedEmpI9Opts, setCompletedEmpI9] = useState<{ id: number, description: string }[]>();
  const [selectedEmpI9, setSelectedEmpI9] = useState<EmpI9>();
  const [empPicData, setEmpPicData] = useState<any>('');
  const [searchText, setSearchText] = useState('');
  const [citizenOption, setCitizenOption] = useState('');
  const [pendingI9, setPendingI9] = useState<string>('');
  const [completedI9, setCompletedI9] = useState<string>('');
  const [preparerDisabled, setPreparerDisabled] = useState<boolean>(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [onConfirmI9, setOnConfirmI9] = useState<EmpI9>();

  const {
    reset,
    formState,
    setValue,
    getValues,
    control,
    register,
    handleSubmit,
    errors,
  } = useForm<EmpI9>();

  useEffect(() => {
    dispatch(loadEmployeesI9());
  }, []);

  useEffect(() => {
    if (allEmpI9Store) {
      setPendingEmpI9(
        allEmpI9Store
          .filter((a) => { return a.pending; })
          .map((m) => {
            return {
              id: m.empNo,
              description:
                m.sectionOne.firstName +
                ' ' +
                m.sectionOne.lastName,
            };
          }) || [],
      );
      setCompletedEmpI9(
        allEmpI9Store
          .filter((a) => { return !a.pending; })
          .map((m) => {
            return {
              id: m.empNo,
              description:
                m.sectionOne.firstName +
                ' ' +
                m.sectionOne.lastName,
            };
          }) || [],
      );
      if (selectedEmpI9) {
        const selected = {
          ...selectedEmpI9,
          ...allEmpI9Store.find(e => { return e.empNo === selectedEmpI9.empNo; }),
        };
        selected && setSelectedEmpI9(selected);
      }
    }
  }, [allEmpI9Store]);

  useEffect(() => {
    if (report) {
      CommonService.downloadBase64File(
        'application/xlsx',
        report,
        'i9_data_report.xlsx',
      );
      dispatch(clearEmployeeI9DataReport());
    }
  }, [report]);

  useEffect(() => {
    if (selectedEmpI9) {
      dispatch(loadEmployee(selectedEmpI9.protectedEmpNo));

      setCitizenOption('' + selectedEmpI9.sectionOne?.citizenOption);
      setPreparerDisabled(
        !selectedEmpI9.preparerDetail.usedPreparer,
      );
      const setEmpI9 = {
        ...selectedEmpI9,
        sectionOne: {
          ...selectedEmpI9.sectionOne,
          dateOfBirth: selectedEmpI9.sectionOne?.dateOfBirth,
          ssn: formatSSN(selectedEmpI9.sectionOne?.ssn),
          middleInitial: selectedEmpI9.sectionOne.middleInitial === 'N/A'
            ? null
            : selectedEmpI9.sectionOne.middleInitial,
          citizenOption: selectedEmpI9.sectionOne?.citizenOption
            ? selectedEmpI9.sectionOne?.citizenOption.toString()
            : '1',
          employeeDateSigned: selectedEmpI9.sectionOne?.employeeDateSigned,
          alienAuthorizedUntilDate: selectedEmpI9.sectionOne?.alienAuthorizedUntilDate,
        },
        preparerDetail: {
          ...selectedEmpI9.preparerDetail,
          usedPreparer: '' + selectedEmpI9.preparerDetail?.usedPreparer,
          dateSigned: selectedEmpI9.preparerDetail?.dateSigned,
        },
        sectionTwo: {
          ...selectedEmpI9.sectionTwo,
          listAExpireDate: selectedEmpI9.sectionTwo?.listAExpireDate,
          listBExpireDate: selectedEmpI9.sectionTwo?.listBExpireDate,
          listCExpireDate: selectedEmpI9.sectionTwo?.listCExpireDate,
          hireDate: selectedEmpI9.sectionTwo?.hireDate,
          employerDateSigned: selectedEmpI9.sectionTwo?.employerDateSigned,
        },
        sectionThree: {
          dateOfRehire: selectedEmpI9.sectionThree?.dateOfRehire,
          expirationDate: selectedEmpI9.sectionThree?.expirationDate,
          rehireDateSigned: selectedEmpI9.sectionThree?.rehireDateSigned,
        },
      } as unknown as EmpI9;
      if (setEmpI9) {
        reset(setEmpI9);
      }
    }
  }, [selectedEmpI9]);

  useEffect(() => {
    if (employee) {
      setEmpPicData(employee?.employeePhoto);
    }
  }, [employee]);

  useEffect(() => {
    if (searchText) {
      const x = parseInt(searchText);
      const empI9 = allEmpI9Store.find((e: EmpI9) => {
        return isNaN(x)
          ? checkForNameMatch(
            e.sectionOne.firstName,
            e.sectionOne.lastName,
            searchText,
          )
          : checkForEmpNoMatch(e.empNo, searchText);
      },
      );
      if (empI9) {
        if (
          Object.keys(formState.touched).length !== 0 ||
          formState.isDirty
        ) {
          setOnConfirmI9(empI9);
          setShowConfirmationModal(true);
        } else {
          setSelectedEmpI9(empI9);
          setPendingI9(empI9.pending ? '' + empI9.empNo : '');
          setCompletedI9(!empI9.pending ? '' + empI9.empNo : '');
        }
      }
    }
  }, [allEmpI9Store, searchText]);

  const onI9Download = () => { return dispatch(loadEmployeeI9DataReport()); };

  const onPendingChange = (e: any) => {
    const empI9 = allEmpI9Store?.find((i) => { return i.empNo === +e.target.value; });
    e.target.value !== undefined && setCompletedI9('');
    setSearchText('');
    if (Object.keys(formState.touched).length !== 0 || formState.isDirty) {
      setOnConfirmI9(empI9);
      setShowConfirmationModal(true);
    } else {
      setSelectedEmpI9(empI9);
      setPendingI9(e.target.value);
    }
  };

  const onCompletedChange = (e: any) => {
    const empI9 = allEmpI9Store?.find((i) => { return i.empNo === +e.target.value; });
    e.target.value !== undefined && setPendingI9('');
    setSearchText('');
    if (Object.keys(formState.touched).length !== 0 || formState.isDirty) {
      setOnConfirmI9(empI9);
      setShowConfirmationModal(true);
    } else {
      setCompletedI9(e.target.value);
      setSelectedEmpI9(empI9);
    }
  };

  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      setSelectedEmpI9(onConfirmI9);
      setCompletedI9('' + onConfirmI9?.empNo);
    }
  };

  const onDocTitleChange = (e: any) => {
    const sectionTwo = getValues().sectionTwo;
    if (sectionTwo) {
      if (e.target.id === 'sectionTwo.listADocumentID') {
        sectionTwo.listBDocumentID === e.target.value &&
          setValue('sectionTwo.listBDocumentID', null);
        sectionTwo.listCDocumentID === e.target.value &&
          setValue('sectionTwo.listCDocumentID', null);
      } else if (e.target.id === 'sectionTwo.listBDocumentID') {
        sectionTwo.listADocumentID === e.target.value &&
          setValue('sectionTwo.listADocumentID', null);
        sectionTwo.listCDocumentID === e.target.value &&
          setValue('sectionTwo.listCDocumentID', null);
      } else if (e.target.id === 'sectionTwo.listCDocumentID') {
        sectionTwo.listADocumentID === e.target.value &&
          setValue('sectionTwo.listADocumentID', null);
        sectionTwo.listBDocumentID === e.target.value &&
          setValue('sectionTwo.listBDocumentID', null);
      }
    }
  };

  const cancelButton = () => {
    selectedEmpI9 && reset({
      ...selectedEmpI9,
      sectionOne: {
        ...selectedEmpI9.sectionOne,
        citizenOption: selectedEmpI9.sectionOne?.citizenOption
          ? '' + selectedEmpI9.sectionOne?.citizenOption
          : '1',
      },
      preparerDetail: {
        ...selectedEmpI9.preparerDetail,
        usedPreparer: '' + selectedEmpI9.preparerDetail?.usedPreparer,
      },
    } as unknown as EmpI9);
    setCitizenOption('' + selectedEmpI9?.sectionOne?.citizenOption);
    setPreparerDisabled(
      !selectedEmpI9?.preparerDetail.usedPreparer,
    );
  };

  const GetI9TitleOptions = (listLetter: string) => {
    const titleCopy = structuredClone(i9DocTitleOpts);
    //Sorted it here because it would not send sorted from the employee master dropdown.
    return titleCopy.filter(x => x.code?.includes(listLetter)).sort((a, b) => (a.code && b.code) ? ((a?.code > b?.code) ? 1 : -1) : -1);
  };

  const onSave = (data: EmpI9) => {
    const ssn = data.sectionOne.ssn && formatFromSSN(data.sectionOne.ssn);
    const updatedForm: EmpI9 = {
      ...selectedEmpI9,
      ...data,
      sectionOne: {
        ...data.sectionOne,
        ssn,
        alienAuthorizedUntilDate: data.sectionOne.alienAuthorizedUntilDate !== '' ? new Date(data.sectionOne.alienAuthorizedUntilDate!).toISOString() : null,
        employeeDateSigned: data.sectionOne.employeeDateSigned !== '' ? new Date(data.sectionOne.employeeDateSigned!).toISOString() : null,
      },
      preparerDetail: {
        ...data.preparerDetail,
        usedPreparer: '' + data.preparerDetail.usedPreparer === 'true',
      },
      sectionTwo: {
        ...data.sectionTwo,
        listADocumentID: +data.sectionTwo.listADocumentID,
        listBDocumentID: +data.sectionTwo.listBDocumentID,
        listCDocumentID: +data.sectionTwo.listCDocumentID,
        listAExpireDate: data.sectionTwo.listAExpireDate !== '' ? new Date(data.sectionTwo.listAExpireDate!).toISOString() : null,
        listBExpireDate: data.sectionTwo.listBExpireDate !== '' ? new Date(data.sectionTwo.listBExpireDate!).toISOString() : null,
        listCExpireDate: data.sectionTwo.listCExpireDate !== '' ? new Date(data.sectionTwo.listCExpireDate!).toISOString() : null,
        hireDate: data.sectionTwo.hireDate !== '' ? new Date(data.sectionTwo.hireDate!).toISOString() : null,
        employerDateSigned: data.sectionTwo.employerDateSigned !== '' ? new Date(data.sectionTwo.employerDateSigned!).toISOString() : null,
      },
      sectionThree: {
        ...data.sectionThree,
        dateOfRehire: data.sectionThree.dateOfRehire !== '' ? new Date(data.sectionThree.dateOfRehire!).toISOString() : null,
        expirationDate: data.sectionThree.expirationDate !== '' ? new Date(data.sectionThree.expirationDate!).toISOString() : null,
        rehireDateSigned: data.sectionThree.rehireDateSigned !== '' ? new Date(data.sectionThree.rehireDateSigned!).toISOString() : null,
      },
    };
    dispatch(updateEmployeeI9(updatedForm));
  };

  return (
    <Col>
      <Row>
        <Col xs={12}>
          <Row>
            <Col
              xs={12}
              sm={3}
              style={{ minWidth: '350px' }}
            >
              <div className="dm-page-title">Manage I9 Documents</div>
            </Col>
            <Col
              xs={12}
              sm={9}
              className="mt-auto pb-2 text-right"
            >
              <div className="d-flex flex-wrap">
                <button
                  type="button"
                  className="btn btn-link dm-grid-action-title py-0"
                  onClick={() => { return onI9Download(); }}
                >
                  Download All I-9s{' '}
                  <Icon
                    name="download"
                    className="fa-check"
                  />
                </button>
                {pendingEmpI9Opts && (
                  <SelectGrp
                    {...fs.pendingI9}
                    value={pendingI9}
                    options={pendingEmpI9Opts}
                    onChange={onPendingChange}
                  />
                )}
                {completedEmpI9Opts && (
                  <SelectGrp
                    {...fs.completedI9}
                    value={completedI9}
                    options={completedEmpI9Opts}
                    onChange={onCompletedChange}
                  />
                )}

                <label className="mt-auto pr-3 mb-3 mb-sm-2">
                  <img
                    src={searchIcon}
                    alt="search"
                    width={20}
                    height="auto"
                  />
                  <input
                    type="text"
                    name="mainSearch"
                    placeholder="Employee Search"
                    value={searchText}
                    size={18}
                    onChange={(e: any) => { return setSearchText(e.target.value); }
                    }
                    className="app-search-label"
                  />
                </label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <hr className="dm-page-hr" />
              <div className="d-flex flex-wrap pl-2">
                {selectedEmpI9 && (
                  <div
                    className="d-flex flex-wrap"
                    style={{ flex: '60%' }}
                  >
                    <div
                      className="mt-auto ml-2 mr-2 mb-2"
                      style={{ width: '100px' }}
                    >
                      <EmpPhoto
                        empPicData={empPicData}
                        empName={
                          employee?.firstName +
                        ' ' +
                        employee?.lastName
                        }
                      />
                    </div>
                    <div
                      className="align-self-center"
                      style={{ minWidth: '200px' }}
                    >
                      {employee && (
                        <div className="dm-grid-action-title font-weight-bold">
                          {employee?.firstName +
                          ' ' +
                          employee?.lastName}
                        </div>
                      )}
                      <div>
                        <span>Employee #: </span>
                        <span>{employee?.empNo}</span>
                      </div>
                    </div>
                    <div className="dm-card-title pt-4">
                      I9 Form
                    </div>
                  </div>
                )}
              </div>
              <div style={{ width: '80%' }}>
                <Prompt
                  when={formState.isDirty}
                  message={() => { return 'You have unsaved changes. Are you sure you want to leave this page?'; }
                  }
                />
                <div className="dm-panel dm-panel-border">
                  <form onSubmit={handleSubmit(onSave)}>
                    <fieldset disabled={!selectedEmpI9}>
                      <div className="d-flex flex-row mb-4">
                        <span className="dm-grid-action-title2">
                          Section 1. Employee Information
                          and Attestation
                        </span>
                      </div>
                      <div className="dm-panel dm-panel-border">
                        <div className="d-flex flex-wrap">
                          <InputGrp
                            {...fs.lastName}
                            errors={
                              errors.sectionOne
                                ?.lastName
                            }
                            ref={register}
                            disabled={true}
                          />
                          <InputGrp
                            {...fs.firstName}
                            errors={
                              errors.sectionOne
                                ?.firstName
                            }
                            ref={register}
                            disabled={true}
                          />
                          <InputGrp
                            {...fs.middleInitial}
                            errors={
                              errors.sectionOne
                                ?.middleInitial
                            }
                            ref={register({
                              maxLength: {
                                value: 1,
                                message:
                                'Maximum length is 1 character',
                              },
                            })}
                            disabled={true}
                          />
                          <InputGrp
                            {...fs.otherLastNamesUsed}
                            errors={
                              errors.sectionOne
                                ?.otherLastNamesUsed
                            }
                            ref={register}
                            disabled={true}
                          />
                          <InputGrp
                            {...fs.address}
                            errors={
                              errors.sectionOne
                                ?.address
                            }
                            ref={register}
                            disabled={true}
                          />
                          <InputGrp
                            {...fs.aptNumber}
                            errors={
                              errors.sectionOne
                                ?.aptNumber
                            }
                            ref={register}
                            disabled={true}
                          />
                          <InputGrp
                            {...fs.city}
                            errors={
                              errors.sectionOne?.city
                            }
                            ref={register}
                            disabled={true}
                          />
                          <SelectGrp
                            {...fs.state}
                            errors={
                              errors.sectionOne?.state
                            }
                            ref={register}
                            options={stateOpts}
                            disabled={true}
                          />
                          <InputGrp
                            {...fs.zip}
                            errors={
                              errors.sectionOne?.zip
                            }
                            ref={register}
                            disabled={true}
                          />

                          <ControlDatePickerGrp
                            {...fs.dateOfBirth}
                            errors={
                              errors.sectionOne
                                ?.dateOfBirth
                            }
                            value={
                              selectedEmpI9
                                ?.sectionOne
                                .dateOfBirth
                            }
                            control={control}
                            disabled={true}
                          />

                          <InputGrp
                            {...fs.ssn}
                            errors={
                              errors.sectionOne?.ssn
                            }
                            onChange={(e: any) => {
                              const { value } =
                              e?.target;
                              e.target.value =
                              formatSSN(value);
                            }}
                            ref={register({
                              pattern: SSN_VALIDATION,
                            })}
                            disabled={true}
                          />
                          <InputGrp
                            {...fs.email}
                            errors={
                              errors.sectionOne?.email
                            }
                            ref={register}
                            disabled={true}
                          />
                          <InputGrp
                            {...fs.phoneNumber}
                            errors={
                              errors.sectionOne
                                ?.phoneNumber
                            }
                            ref={register}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="dm-panel dm-panel-border">
                        <div className="dm-subtitle3">
                          Select an Option
                        </div>
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-row">
                            <div className="col-5 col-lg-3">
                              <RadioGrpInLine
                                {...fs.citizenOption}
                                radioOptions={citizenRadioOptions}
                                required={true}
                                labelWidth={0}
                                errors={
                                  errors
                                    .sectionOne
                                    ?.citizenOption
                                }
                                onChange={(
                                  e: any,
                                ) => {
                                  return setCitizenOption(
                                    e.target
                                      .value,
                                  );
                                }
                                }
                                ref={register({
                                  valueAsNumber: true,
                                  required:
                                  'Citizen Option is required',
                                })}
                              />
                            </div>
                            <div
                              className="col-7"
                              style={{
                                marginTop: 'Auto',
                              }}
                            >
                              <InputGrp
                                {...fs.alienRegistrationNumberUSCISOne}
                                placeholder="Alien Registration Number/USCIS Number"
                                errors={
                                  errors
                                    .sectionOne
                                    ?.alienRegistrationNumberUSCISOne
                                }
                                ref={register}
                                disabled={
                                  citizenOption !==
                                '3'
                                }
                              />
                              <ControlDatePickerGrp
                                {...fs.alienAuthorizedUntilDate}
                                errors={
                                  errors
                                    .sectionOne
                                    ?.alienAuthorizedUntilDate
                                }
                                value={
                                  selectedEmpI9
                                    ?.sectionOne
                                    .alienAuthorizedUntilDate
                                }
                                control={control}
                                disabled={
                                  citizenOption !==
                                '4'
                                }
                              />
                            </div>
                          </div>

                          <div className="mt-3 mb-3">
                            <i>
                              Aliens authorized to
                              work must provide{' '}
                              <span
                                style={{
                                  color: 'red',
                                }}
                              >
                                only one
                              </span>{' '}
                              of the following
                              document numbers to
                              complete the Form I-9
                              <br />
                              Alien Registration
                              Number/USCIS Number OR
                              Form I-94 Admission
                              Number OR Foreign
                              Passport Number
                            </i>
                          </div>
                          <InputGrpInLine
                            {...fs.alienRegistrationNumberUSCISTwo}
                            errors={
                              errors.sectionOne
                                ?.alienRegistrationNumberUSCISTwo
                            }
                            ref={register}
                            disabled={
                              citizenOption !== '4'
                            }
                          />
                          <InputGrpInLine
                            {...fs.formI94AdmissionNumber}
                            errors={
                              errors.sectionOne
                                ?.formI94AdmissionNumber
                            }
                            ref={register}
                            disabled={
                              citizenOption !== '4'
                            }
                          />
                          <InputGrpInLine
                            {...fs.foreignPassportNumber}
                            errors={
                              errors.sectionOne
                                ?.foreignPassportNumber
                            }
                            ref={register}
                            disabled={
                              citizenOption !== '4'
                            }
                          />
                          <InputGrpInLine
                            {...fs.countryOfIssuance}
                            errors={
                              errors.sectionOne
                                ?.countryOfIssuance
                            }
                            ref={register}
                            disabled={
                              citizenOption !== '4'
                            }
                          />
                        </div>
                      </div>
                      <div className="dm-panel dm-panel-border">
                        <div className="dm-subtitle3">
                          Employee Signature
                        </div>
                        <div className="d-flex flex-row flex-wrap">
                          <InputGrp
                            {...fs.employeeSignature}
                            errors={
                              errors.sectionOne
                                ?.employeeSignature
                            }
                            ref={register}
                          />
                          <ControlDatePickerGrp
                            {...fs.employeeDateSigned}
                            errors={
                              errors.sectionOne
                                ?.employeeDateSigned
                            }
                            value={
                              selectedEmpI9
                                ?.sectionOne
                                .employeeDateSigned
                            }
                            control={control}
                          />
                        </div>
                      </div>
                      <div className="dm-panel dm-panel-border">
                        <div className="dm-subtitle3">
                          Preparer and/or Translator
                          Certification
                        </div>
                        <div className="d-flex flex-row flex-wrap">
                          <div className="w-100">
                            <RadioGrpInLine
                              {...fs.usedPreparer}
                              errors={
                                errors
                                  .preparerDetail
                                  ?.usedPreparer
                              }
                              ref={register}
                              onChange={(e: any) => {
                                return setPreparerDisabled(
                                  e.target
                                    .value ===
                                'false',
                                );
                              }
                              }
                            />
                          </div>
                          <InputGrp
                            {...fs.preparerSignature}
                            errors={
                              errors.preparerDetail
                                ?.preparerSignature
                            }
                            ref={register}
                            disabled={preparerDisabled}
                          />

                          <ControlDatePickerGrp
                            {...fs.dateSigned}
                            errors={
                              errors.preparerDetail
                                ?.dateSigned
                            }
                            value={
                              selectedEmpI9
                                ?.preparerDetail
                                .dateSigned
                            }
                            control={control}
                            disabled={preparerDisabled}
                          />
                          <InputGrp
                            {...fs.preparerLastName}
                            errors={
                              errors.preparerDetail
                                ?.lastName
                            }
                            ref={register}
                            disabled={preparerDisabled}
                          />
                          <InputGrp
                            {...fs.preparerFirstName}
                            errors={
                              errors.preparerDetail
                                ?.firstName
                            }
                            ref={register}
                            disabled={preparerDisabled}
                          />
                          <InputGrp
                            {...fs.preparerAddress}
                            errors={
                              errors.preparerDetail
                                ?.address
                            }
                            ref={register}
                            disabled={preparerDisabled}
                          />
                          <SelectGrp
                            {...fs.preparerCity}
                            errors={
                              errors.preparerDetail
                                ?.city
                            }
                            ref={register}
                            options={stateOpts}
                            disabled={preparerDisabled}
                          />
                          <InputGrp
                            {...fs.preparerZip}
                            errors={
                              errors.preparerDetail
                                ?.zip
                            }
                            ref={register}
                            disabled={preparerDisabled}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row mb-4">
                        <span className="dm-grid-action-title2">
                          Section 2. Employer or
                          Authorized Representative Review
                          and Verification
                        </span>
                      </div>
                      <div className="dm-panel dm-panel-border">
                        <div className="d-flex flex-row flex-wrap">
                          <div className="d-flex flex-column">
                            <div
                              className="dm-subtitle3"
                              style={{ textAlign:'center' }}
                            >
                              List A
                              <span className="float-right mr-3">
                                OR
                              </span>
                            </div>
                            <div
                              className="dm-subtitle3"
                              style={{ textAlign:'center' }}
                            >
                              Identity and Employment Authorization
                            </div>
                            <SelectGrp
                              {...fs.listADocumentID}
                              errors={
                                errors.sectionTwo
                                  ?.listADocumentID
                              }
                              ref={register}
                              options={GetI9TitleOptions('A')}
                              addEmptyValue = {true}
                              onChange={
                                onDocTitleChange
                              }
                            />
                            <InputGrp
                              {...fs.listAIssuingAuthority}
                              errors={
                                errors.sectionTwo
                                  ?.listAIssuingAuthority
                              }
                              ref={register}
                            />
                            <InputGrp
                              {...fs.listADocumentNumber}
                              errors={
                                errors.sectionTwo
                                  ?.listADocumentNumber
                              }
                              ref={register}
                            />

                            <ControlDatePickerGrp
                              {...fs.listAExpireDate}
                              errors={
                                errors.sectionTwo
                                  ?.listAExpireDate
                              }
                              value={
                                selectedEmpI9
                                  ?.sectionTwo
                                  ?.listAExpireDate
                              }
                              control={control}
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <div
                              className="dm-subtitle3"
                              style={{ textAlign:'center' }}
                            >
                              List B
                            </div>
                            <div
                              className="dm-subtitle3"
                              style={{ textAlign:'center' }}
                            >
                              Identity
                              <span className="float-right mr-3">
                              </span>
                            </div>
                            <SelectGrp
                              {...fs.listBDocumentID}
                              errors={
                                errors.sectionTwo
                                  ?.listBDocumentID
                              }
                              ref={register}
                              options={GetI9TitleOptions('B')}
                              addEmptyValue = {true}
                              onChange={
                                onDocTitleChange
                              }
                            />
                            <InputGrp
                              {...fs.listBIssuingAuthority}
                              errors={
                                errors.sectionTwo
                                  ?.listBIssuingAuthority
                              }
                              ref={register}
                            />
                            <InputGrp
                              {...fs.listBDocumentNumber}
                              errors={
                                errors.sectionTwo
                                  ?.listBDocumentNumber
                              }
                              ref={register}
                            />

                            <ControlDatePickerGrp
                              {...fs.listBExpireDate}
                              errors={
                                errors.sectionTwo
                                  ?.listBExpireDate
                              }
                              value={
                                selectedEmpI9
                                  ?.sectionTwo
                                  ?.listBExpireDate
                              }
                              control={control}
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <div
                              className="dm-subtitle3"
                              style={{ textAlign:'center' }}
                            >
                              <span className="float-left">
                                AND
                              </span>
                              List C
                            </div>
                            <div
                              className="dm-subtitle3"
                              style={{ marginLeft: 30, textAlign:'center' }}
                            >
                              Employment Authorization
                            </div>
                            <SelectGrp
                              {...fs.listCDocumentID}
                              errors={
                                errors.sectionTwo
                                  ?.listCDocumentID
                              }
                              ref={register}
                              options={GetI9TitleOptions('C')}
                              addEmptyValue = {true}
                              onChange={
                                onDocTitleChange
                              }
                            />
                            <InputGrp
                              {...fs.listCIssuingAuthority}
                              errors={
                                errors.sectionTwo
                                  ?.listCIssuingAuthority
                              }
                              ref={register}
                            />
                            <InputGrp
                              {...fs.listCDocumentNumber}
                              errors={
                                errors.sectionTwo
                                  ?.listCDocumentNumber
                              }
                              ref={register}
                            />

                            <ControlDatePickerGrp
                              {...fs.listCExpireDate}
                              errors={
                                errors.sectionTwo
                                  ?.listCExpireDate
                              }
                              value={
                                selectedEmpI9
                                  ?.sectionTwo
                                  ?.listCExpireDate
                              }
                              control={control}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="dm-panel dm-panel-border">
                        <div className="dm-subtitle3">
                          Employees First Day of
                          Employment
                        </div>
                        <div className="d-flex flex-row flex-wrap">
                          <ControlDatePickerGrp
                            {...fs.hireDate}
                            errors={
                              errors.sectionTwo
                                ?.hireDate
                            }
                            value={
                              selectedEmpI9
                                ?.sectionTwo
                                .hireDate
                            }
                            control={control}
                          />
                        </div>
                      </div>
                      <div className="dm-panel dm-panel-border">
                        <div className="dm-subtitle3">
                          Employer Signature
                        </div>
                        <div className="d-flex flex-row flex-wrap">
                          <InputGrp
                            {...fs.employerSignature}
                            errors={
                              errors.sectionTwo
                                ?.employerSignature
                            }
                            ref={register}
                          />

                          <ControlDatePickerGrp
                            {...fs.employerDateSigned}
                            errors={
                              errors.sectionTwo
                                ?.employerDateSigned
                            }
                            value={
                              selectedEmpI9
                                ?.sectionTwo
                                .employerDateSigned
                            }
                            control={control}
                          />
                          <InputGrp
                            {...fs.employerTitle}
                            errors={
                              errors.sectionTwo
                                ?.employerTitle
                            }
                            ref={register}
                          />
                          <InputGrp
                            {...fs.employerFirstName}
                            errors={
                              errors.sectionTwo
                                ?.employerFirstName
                            }
                            ref={register}
                          />
                          <InputGrp
                            {...fs.employerLastName}
                            errors={
                              errors.sectionTwo
                                ?.employerLastName
                            }
                            ref={register}
                          />
                          <InputGrp
                            {...fs.employerBusinessName}
                            errors={
                              errors.sectionTwo
                                ?.employerBusinessName
                            }
                            ref={register}
                          />
                          <InputGrp
                            {...fs.employerBusinessAddress}
                            errors={
                              errors.sectionTwo
                                ?.employerBusinessAddress
                            }
                            ref={register}
                          />
                          <InputGrp
                            {...fs.employerBusinessCity}
                            errors={
                              errors.sectionTwo
                                ?.employerBusinessCity
                            }
                            ref={register}
                          />
                          <SelectGrp
                            {...fs.employerBusinessState}
                            errors={
                              errors.sectionTwo
                                ?.employerBusinessState
                            }
                            ref={register}
                            options={stateOpts}
                          />
                          <InputGrp
                            {...fs.employerBusinessZip}
                            errors={
                              errors.sectionTwo
                                ?.employerBusinessZip
                            }
                            ref={register}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row mb-4">
                        <span className="dm-grid-action-title2">
                          Section 3. Reverification and
                          Rehires
                        </span>
                      </div>
                      <div className="dm-panel dm-panel-border">
                        <div className="dm-subtitle3">
                          A. New Name (if applicable) AND
                          B. Date of rehire (if
                          applicable)
                        </div>
                        <div className="d-flex flex-row flex-wrap">
                          <InputGrp
                            {...fs.newFirstName}
                            errors={
                              errors.sectionThree
                                ?.newFirstName
                            }
                            ref={register}
                          />
                          <InputGrp
                            {...fs.newMiddleInitial}
                            errors={
                              errors.sectionThree
                                ?.newMiddleInitial
                            }
                            ref={register}
                          />
                          <InputGrp
                            {...fs.newLastName}
                            errors={
                              errors.sectionThree
                                ?.newLastName
                            }
                            ref={register}
                          />

                          <ControlDatePickerGrp
                            {...fs.dateOfRehire}
                            errors={
                              errors.sectionThree
                                ?.dateOfRehire
                            }
                            value={
                              selectedEmpI9
                                ?.sectionThree
                                ?.dateOfRehire
                            }
                            control={control}
                          />
                        </div>
                        <div className="dm-subtitle3">
                          C. New Name (if applicable)
                        </div>
                        <div className="d-flex flex-row flex-wrap">
                          <InputGrp
                            {...fs.documentTitle}
                            errors={
                              errors.sectionThree
                                ?.documentTitle
                            }
                            ref={register}
                          />
                          <InputGrp
                            {...fs.documentNumber}
                            errors={
                              errors.sectionThree
                                ?.documentNumber
                            }
                            ref={register}
                          />

                          <ControlDatePickerGrp
                            {...fs.expirationDate}
                            errors={
                              errors.sectionThree
                                ?.expirationDate
                            }
                            value={
                              selectedEmpI9
                                ?.sectionThree
                                ?.expirationDate
                            }
                            control={control}
                          />
                        </div>
                        <div className="dm-subtitle3">
                          I attest, under penalty of
                          perjury, that to the best of my
                          knowledge, this employee is
                          authorized to work in the United
                          States, and if the employee
                          presented document(s) I examined
                          paper to be genuine and to
                          relate to the individual.
                        </div>
                        <div className="d-flex flex-row flex-wrap">
                          <InputGrp
                            {...fs.rehireSignature}
                            errors={
                              errors.sectionThree
                                ?.rehireSignature
                            }
                            ref={register}
                          />

                          <ControlDatePickerGrp
                            {...fs.rehireDateSigned}
                            errors={
                              errors.sectionThree
                                ?.rehireDateSigned
                            }
                            control={control}
                            value={
                              selectedEmpI9
                                ?.sectionThree
                                ?.rehireDateSigned
                            }
                          />
                          <InputGrp
                            {...fs.rehireEmployer}
                            errors={
                              errors.sectionThree
                                ?.rehireEmployer
                            }
                            ref={register}
                          />
                        </div>
                      </div>
                      <div className="d-flex mt-3">
                        <button
                          className="orange-outline-button ml-auto mr-2"
                          type="button"
                          onClick={cancelButton}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="orange-button mr-2"
                        >
                          Save I-9
                        </button>
                      </div>
                    </fieldset>
                  </form>
                </div>
                {showConfirmationModal && (
                  <ConfirmationModal
                    title="You Have Unsaved Changes"
                    message={
                      'Would you still like to switch employees?'
                    }
                    show={showConfirmationModal}
                    onConfirmed={confirmClose}
                    onHide={() => { return setShowConfirmationModal(false); }
                    }
                  />
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default ManageI9Documents;
