import { createReducer } from '@reduxjs/toolkit';
import {
  EmpHistory,
  HireGeneral,
  HireInfoCustomField,
  HRDependent,
  HREducation,
  HREmployment,
  HRExitInterview,
  HRIncrease,
  HRLicense,
  HRPerformanceReview,
  HRSkills,
  HRSource,
  HRTrainingEvent,
  HRVaccination,
} from '../../models/HREmployee.model';
import {
  storeEmployeeHireGeneral,
  storeEmployeeFieldHistory,
  storeHireInfoCustomFields,
  storeHREmployment,
  storeHREducation,
  storeHRLicense,
  storeHRTrainingEvent,
  storeHRSkills,
  storeHRSource,
  storeHREducations,
  storeHRLicenses,
  storeHRSkill,
  storeHRSources,
  storeHRTrainingEvents,
  storeHREmployments,
  storeHRExitInterview,
  storeHRReview,
  storeHRIncrease,
  storeHRDependent,
  storeHRDependents,
  storeHRVaccinations,
  storeHRVaccination,
  storeHRVaccinationReport,
  clearHRVaccinationReport,
  storeHREmployeeList,
} from '../actions/hr-employee.action';

export interface HrEmployeeInfoState {
  loading: boolean;
  employeeFieldHistory: EmpHistory | null;
  employeeHireGeneral: HireGeneral | null;
  hireInfoCustomerFields: HireInfoCustomField[];
  employments: HREmployment[];
  educations: HREducation[];
  licenses: HRLicense[];
  trainingEvents: HRTrainingEvent[];
  skills: HRSkills[];
  sources: HRSource[];
  exitInterview: HRExitInterview | null;
  increase: HRIncrease | null;
  review: HRPerformanceReview | null;
  dependents: HRDependent[];
  vaccinations: HRVaccination[];
  vaccinationReport: string | null;
  employeeListIds: number[];
}

const INITIAL_STATE: HrEmployeeInfoState = {
  loading: false,
  employeeFieldHistory: null,
  employeeHireGeneral: null,
  hireInfoCustomerFields: [],
  employments: [],
  educations: [],
  licenses: [],
  trainingEvents: [],
  skills: [],
  sources: [],
  exitInterview: null,
  increase: null,
  review: null,
  dependents: [],
  vaccinations: [],
  vaccinationReport: null,
  employeeListIds: [],
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(storeEmployeeFieldHistory, (state, action) => {
    state.loading = false;
    state.employeeFieldHistory = action.payload;
  }).addCase(storeEmployeeHireGeneral, (state, action) => {
    state.loading = false;
    state.employeeHireGeneral = action.payload;
  }).addCase(storeHireInfoCustomFields, (state, action) => {
    state.loading = false;
    state.hireInfoCustomerFields = action.payload;
  }).addCase(storeHREmployments, (state, action) => {
    state.loading = false;
    state.employments = action.payload;
  }).addCase(storeHREmployment, (state, action) => {
    state.loading = false;
    state.employments = [
      ...state.employments.filter(
        (w) => {return w.employmentId !== action.payload.employmentId;},
      ),
      action.payload,
    ];
  }).addCase(storeHREducations, (state, action) => {
    state.loading = false;
    state.educations = action.payload;
  }).addCase(storeHREducation, (state, action) => {
    state.loading = false;
    state.educations = [
      ...state.educations.filter(
        (w) => {return w.educationId !== action.payload.educationId;},
      ),
      action.payload,
    ];
  }).addCase(storeHRLicenses, (state, action) => {
    state.loading = false;
    state.licenses = action.payload;
  }).addCase(storeHRLicense, (state, action) => {
    state.loading = false;
    state.licenses = [
      ...state.licenses.filter(
        (w) => {return w.empLicenseId !== action.payload.empLicenseId;},
      ),
      action.payload,
    ];
  }).addCase(storeHRTrainingEvents, (state, action) => {
    state.loading = false;
    state.trainingEvents = action.payload;
  }).addCase(storeHRTrainingEvent, (state, action) => {
    state.loading = false;
    state.trainingEvents = [
      ...state.trainingEvents.filter(
        (w) => {return w.empTrainingId !== action.payload.empTrainingId;},
      ),
      action.payload,
    ];
  }).addCase(storeHRSkills, (state, action) => {
    state.loading = false;
    state.skills = action.payload;
  }).addCase(storeHRSkill, (state, action) => {
    state.loading = false;
    state.skills = [
      ...state.skills.filter(
        (w) => {return w.empSkillId !== action.payload.empSkillId;},
      ),
      action.payload,
    ];
  }).addCase(storeHRSources, (state, action) => {
    state.loading = false;
    state.sources = action.payload;
  }).addCase(storeHRSource, (state, action) => {
    state.loading = false;
    state.sources = [
      ...state.sources.filter(
        (w) => {return w.sourceId !== action.payload.sourceId;},
      ),
      action.payload,
    ];
  }).addCase(storeHRExitInterview, (state, action) => {
    state.loading = false;
    state.exitInterview = action.payload;
  }).addCase(storeHRIncrease, (state, action) => {
    state.loading = false;
    state.increase = action.payload;
  }).addCase(storeHRReview, (state, action) => {
    state.loading = false;
    state.review = action.payload;
  }).addCase(storeHRDependents, (state, action) => {
    state.loading = false;
    state.dependents = action.payload;
  }).addCase(storeHRDependent, (state, action) => {
    state.loading = false;
    state.dependents = [
      ...state.dependents.filter(
        (w) => {return w.contactId !== action.payload.contactId;},
      ),
      action.payload,
    ];
  }).addCase(storeHRVaccinations, (state, action) => {
    state.loading = false;
    state.vaccinations = action.payload;
  }).addCase(storeHRVaccination, (state, action) => {
    state.loading = false;
    state.vaccinations = [
      ...state.vaccinations.filter(
        (w) => {return w.vaccinationId !== action.payload.vaccinationId;},
      ),
      action.payload,
    ];
  }).addCase(storeHRVaccinationReport, (state, action) => {
    state.loading = false;
    state.vaccinationReport = action.payload;
  }).addCase(clearHRVaccinationReport, (state, action) => {
    state.loading = false;
    state.vaccinationReport = null;
  }).addCase(storeHREmployeeList, (state, action) => {
    state.loading = false;
    state.employeeListIds = action.payload;
  });
});
