import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { ControlDatePickerGrp, RadioGrp, SelectGrp } from 'core/components/form-controls';
import { Form } from 'react-bootstrap';
import { FieldInputSettings } from 'core/components/form-controls/types';
import PanelHeader from 'core/components/shared/PanelHeader';
import { useSelector } from 'react-redux';
import { getSelectedEmp, getTen99, getYTD } from 'core/store/selectors';
import { useAppDispatch, useAppSelector, useRadioButtonChange } from 'utilities/hooks';
import { getAccess } from 'utilities/utilities';
import { BaseSectionProps } from './PayrollInfoPage';
import { loadYearToDate } from 'core/store/actions';

const currentYaer = new Date().getFullYear();

const styles: { [key: string]: CSSProperties } = {
  nrwhGroupStyle: {
    width: '200px',
  },
};

const PayrollInfoTaxInfo: React.FC<BaseSectionProps> = ({
  methods: { register, setValue, getValues, errors, watch, control },
  sectionAccess,
}) => {
  const ten99Opts = useSelector(getTen99);
  const ten99TaxIdOpts = useAppSelector(({ dropdown }) => dropdown.ten99TaxpayerIdType);
  const selectedEmp = useSelector(getSelectedEmp); 
  const ytd = useSelector(getYTD);
    
  const ytdFicaWages = useMemo(() => ytd?.ytdTaxes
    ?.filter(({ type }) => type.toLowerCase().includes('fica'))
    ?.map(({ wages }) => wages)
    ?.reduce((prev, current) => prev + current, 0) ?? 0, [ytd]);
  
  const dispatch = useAppDispatch();
  
  const {
    h1BStartDate,
    nonResidentAlien,
    is1099,
    isPoliceOfficerOrFireFighter,
    hasE945,
    nrwhAdjust,
    nrwhFica,
    retirement,
    statutoryEmployee,
  } = watch([
    'h1BStartDate',
    'nonResidentAlien',
    'is1099',
    'isPoliceOfficerOrFireFighter',
    'hasE945',
    'nrwhAdjust',
    'nrwhFica',
    'retirement',
    'statutoryEmployee',
  ]);

  const [fs, setFs] = useState<FieldInputSettings>({
    h1BStartDate: {
      name: 'h1BStartDate',
      label: 'H1B START DATE',
      groupClass: 'groupClass11',
    },
    nonResidentAlien: {
      name: 'nonResidentAlien',
      label: 'NON RES. ALIEN',
      groupClass: 'groupClass11',
    },
    is1099: {
      name: 'is1099',
      label: '1099/K1',
      groupClass: 'groupClass11',
    },
    ten99LookUpId: {
      name: 'ten99LookUpId',
      label: '1099 / K1 BOX #',
      groupClass: 'groupClass11 mb-auto',
      addEmptyValue: true,
      errorMsg: '1099 Box must be selected if employee is 1099',
    },
    ten99TaxpayerIdType: {
      name: 'ten99TaxpayerIdType',
      label: '1099 Taxpayer ID Type',
      addEmptyValue: true,
      groupClass: 'groupClass11',
    },
    hasE945: { name: 'hasE945',
      label: 'E945',
      groupClass: 'groupClass11' },
    isPoliceOfficerOrFireFighter: {
      name: 'isPoliceOfficerOrFireFighter',
      label: 'Police/Firefighter',
      groupClass: 'groupClass11',
    },
    nrwhAdjust: {
      name: 'nrwhAdjust',
      label: ['INVOKE CALCULATION OF', "ADD'TL W/H ADJUSTMENT PUB 515"],
      tallLabel: true,
      groupClass: 'groupClass20',
    },
    nrwhFica: {
      name: 'nrwhFica',
      label: [
        'DOES THIS NR ALIEN HAVE',
        'A VALID SSN AND NEEDS FICA W/H',
      ],
      tallLabel: true,
      groupClass: 'groupClass20',
    },
    retirement: {
      name: 'retirement',
      label: 'RETIREMENT',
      tallLabel: true,
      groupClass: 'groupClass20',
    },
    statutoryEmployee: {
      name: 'statutoryEmployee',
      label: 'STATUTORY EMPLOYEE',
      tallLabel: true,
      groupClass: 'groupClass20',
    },
  });

  useEffect(() => {
    const currentState = { ...fs };
    currentState.nonResidentAlien.defaultChecked = nonResidentAlien;
    currentState.is1099.defaultChecked = is1099;
    currentState.isPoliceOfficerOrFireFighter.defaultChecked = isPoliceOfficerOrFireFighter;
    currentState.hasE945.defaultChecked = hasE945;
    currentState.nrwhAdjust.defaultChecked = nrwhAdjust;
    currentState.nrwhFica.defaultChecked = nrwhFica;
    currentState.retirement.defaultChecked = retirement;
    currentState.statutoryEmployee.defaultChecked = statutoryEmployee;
    setFs(currentState);
  }, [h1BStartDate]);
  
  useEffect(() => {
    if (!selectedEmp) return;
    dispatch(
      loadYearToDate({
        empNo: selectedEmp.protectedEmpNo,
        prYear: currentYaer,
      }),
    );
  }, [selectedEmp]);

  const [handleRadioButtonChange] = useRadioButtonChange(fs);

  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Payroll: W2/Tax Info" />
      <div className="d-flex flex-row flex-wrap justify-content-between pt-2">
        <div className="d-flex flex-column justify-content-around">
          <ControlDatePickerGrp
            {...fs.h1BStartDate}
            {...getAccess(sectionAccess, 6)}
            value={h1BStartDate}
            setValue={setValue}
            control={control}
            errors={errors.h1BStartDate}
          />
          <RadioGrp
            {...fs.isPoliceOfficerOrFireFighter}
            {...getAccess(sectionAccess, 6, undefined, { disabledSameAsReadOnly: true })}
            errors={errors.isPoliceOfficerOrFireFighter}
            ref={register}
            onChange={handleRadioButtonChange}
          />
        </div>
        <div className="d-flex flex-column justify-content-around">
          <RadioGrp
            {...fs.nonResidentAlien}
            {...getAccess(sectionAccess, 6, fs.nonResidentAlien.controlId, { disabledSameAsReadOnly: true })}
            errors={errors.nonResidentAlien}
            ref={register}
          />
          <RadioGrp
            {...fs.hasE945}
            {...getAccess(sectionAccess, 6, fs.hasE945.controlId, { disabledSameAsReadOnly: true })}
            errors={errors.hasE945}
            ref={register}
            onChange={handleRadioButtonChange}
          />
        </div>
        <div className="d-flex flex-column justify-content-around">
          <RadioGrp
            {...fs.nrwhFica}
            {...getAccess(sectionAccess, 6, undefined, { disabledSameAsReadOnly: true })}
            errors={errors.nrwhFica}
            ref={register}
            onChange={handleRadioButtonChange}
            groupStyle={styles.nrwhGroupStyle}
          />
          <RadioGrp
            {...fs.nrwhAdjust}
            {...getAccess(sectionAccess, 6, undefined, { disabledSameAsReadOnly: true })}
            errors={errors.nrwhAdjust}
            ref={register}
            onChange={handleRadioButtonChange}
            groupStyle={styles.nrwhGroupStyle}
          />
        </div>
        <div className="d-flex flex-column justify-content-around">
          <RadioGrp
            {...fs.is1099}
            {...getAccess(sectionAccess, 6, undefined, { disabledSameAsReadOnly: true, disabledDefault: ytdFicaWages > 0 })}
            errors={errors.is1099}
            ref={register}
            onChange={handleRadioButtonChange}
          />
          <Form.Label className="dm-grid-action-title ml-3 mr-2">
            W2 Box 13:
          </Form.Label>
        </div>
        <div className="d-flex flex-column justify-content-around">
          <SelectGrp
            {...fs.ten99LookUpId}
            {...getAccess(sectionAccess, 6)}
            errors={errors.ten99LookUpId}
            ref={register({
              validate: (value: any) => {
                const is1099 = getValues('is1099');
                return (
                  (is1099 === 'true' && value !== '') ||
                                    (is1099 === 'false' && value === '')
                );
              },
            })}
            options={ten99Opts}
            groupStyle={{ width: '200px' }}
            disabled={ytdFicaWages > 0}
          />
          <div className="d-flex">
            <RadioGrp
              {...fs.retirement}
              {...getAccess(sectionAccess, 6, undefined, { disabledSameAsReadOnly: true })}
              errors={errors.retirement}
              ref={register}
              onChange={handleRadioButtonChange}
            />
            <RadioGrp
              {...fs.statutoryEmployee}
              {...getAccess(sectionAccess, 6, undefined, { disabledSameAsReadOnly: true })}
              errors={errors.statutoryEmployee}
              ref={register}
              onChange={handleRadioButtonChange}
            />
          </div>
        </div>
        <div className="d-flex flex-column justify-content-around align-self-start">
          <SelectGrp
            {...fs.ten99TaxpayerIdType}
            {...getAccess(sectionAccess, 6)}
            options={ten99TaxIdOpts}
            ref={register}
            groupStyle={{ width: '200px' }}
            disabled={ytdFicaWages > 0}
          />
        </div>
      </div>
    </div>
  );
};

export default PayrollInfoTaxInfo;
