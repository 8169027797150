import { ICellRendererParams } from '@ag-grid-community/core';
import checkmarkIcon from '../../assets/images/checkmark-16.png';

export function agCheckboxRenderer(): any {}

agCheckboxRenderer.prototype.init = function (params: ICellRendererParams) {
  this.imgWrapper = document.createElement('div');
  this.imgWrapper.style.cssText = 'display: flex; justify-content: center; width: 100%;';

  this.img = null;
  if (params.value) {
    this.img = document.createElement('img');
    this.img.src = params.value ? checkmarkIcon : null;
    this.img.alt = params.value ? 'true' : 'false';
    this.img.style = 'margin-top: 5px; height: 12px;';
    this.imgWrapper.appendChild(this.img);
  }
};

agCheckboxRenderer.prototype.getGui = function (params: ICellRendererParams) {
  return this.imgWrapper;
};

agCheckboxRenderer.prototype.refresh = function (params: ICellRendererParams) {
  this.imgWrapper.firstChild &&
        this.imgWrapper.removeChild(this.imgWrapper.firstChild);

  if (params.value) {
    this.img = document.createElement('img');
    this.img.src = params.value ? checkmarkIcon : '';
    this.img.alt = params.value ? 'true' : 'false';
    this.img.style = 'margin-top: 5px; height: 12px;';
    this.imgWrapper.appendChild(this.img);
  }

  return true;
};

agCheckboxRenderer.prototype.destroy = function () {};
