import React from 'react';
import Icon from './Icon';
import styles from './shared.module.scss';

type Props = {
  title: 'First page' | 'Previous page' | 'Next page' | 'Final page';
  icon: 'angles-left' | 'angle-left' | 'angle-right' | 'angles-right';
  disabled: boolean;
  onPageChange: () => void;
};  

const PageButton = ({ title, icon, disabled, onPageChange }: Props) => 
  <button
    title={title}
    className={styles['page-button']}
    disabled={disabled}
    aria-disabled={disabled}
    onMouseDown={(e) => {
      e.preventDefault();
      onPageChange();
    }}
  >
    <Icon name={icon} />
    <span className="sr-only">{title}</span>
  </button>;

export default PageButton;