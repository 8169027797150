import React, { useEffect, useState } from 'react';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { InputGrpDecimal, SelectGrp } from 'core/components/form-controls';
import { useForm } from 'react-hook-form';
import { RateRule } from 'core/models';
import { useDispatch } from 'react-redux';
import { createRateRule, toggleBlockNavigation, updateRateRule } from 'core/store/actions';
import Icon from 'core/components/shared/Icon';
import { UNSAVED_MESSAGE } from 'core/constants';
import { useParams } from 'react-router-dom';
import { formatDecimal, getAccess } from 'utilities/utilities';
import { useAppSelector } from 'utilities/hooks';

const fs: FieldInputSettings = {
  locationId: {
    name: 'locationId',
    label: 'LOCATION',
    groupClass: 'groupClass20',
    required: true,
    showId: true,
    labelField: 'locationDesc',
    valueField: 'locationID',
    addOptionText: 'Location',
  },
  deptId: {
    name: 'deptId',
    label: 'DEPARTMENT',
    groupClass: 'groupClass20',
    required: true,
    showId: true,
    labelField: 'deptDesc',
    valueField: 'deptId',
    addOptionText: 'Department',
  },
  subDeptId: {
    name: 'subDeptId',
    label: 'SUB DEPARTMENT',
    groupClass: 'groupClass20',
    required: true,
    showId: true,
    labelField: 'subDeptDesc',
    valueField: 'subDeptCode',
    addOptionText: 'Sub Dept',
  },
  subDept2Id: {
    name: 'subDept2Id',
    label: 'SUB DEPARTMENT 2',
    groupClass: 'groupClass20',
    required: true,
    showId: true,
    labelField: 'sub2Desc',
    valueField: 'sub2Code',
    addOptionText: 'Sub Dept 2',
  },
  payRate: {
    name: 'payRate',
    label: 'PAY RATE',
    groupClass: 'groupClass13',
    required: true,
  },
};

type PropTypes = {
  item: RateRule;
  locationDepartmentOpts: any;
  onDelete: (item: RateRule) => void;
};

const ClientRateRuleItem: React.FC<PropTypes> = ({
  item,
  locationDepartmentOpts: {
    locationOpts,
    deptOpts,
    subdeptOpts,
    subdept2Opts,
  },
  onDelete,
}) => {
  const dispatch = useDispatch();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { protectedEmpNo } = useParams<any>();
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  
  const { errors, register, handleSubmit, watch, reset, setValue } = useForm<RateRule>({
    defaultValues: item,
  });

  const { payRate } = watch([
    'payRate',
  ]);

  const onSubmit = (data: any) => {
    const rateIdNew = (item.rateId > 0) ? item.rateId : 0;
    const newRateRule: RateRule = { clientNo: item.clientNo, rateId: rateIdNew, ...data };

    rateIdNew
      ? dispatch(updateRateRule(newRateRule))
      : dispatch(createRateRule(newRateRule));
    setIsDirty(false);
  };

  //The Pay Rates wanted to be extra and not format on inital load...We aint having that 
  useEffect(() => {
    const itemCopy =  structuredClone(item);
    itemCopy.payRate = formatDecimal(+item.payRate);
    
    reset(itemCopy);
    setIsDirty(false);
    setValue('payRate', formatDecimal(+item.payRate));
  }, [protectedEmpNo]);

  const onPayRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (getAccess(sectionAccess, 9)?.readOnly ?? false) return;

    setValue('payRate', formatDecimal(+e.target.value));
    setIsDirty(true);
  };

  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [isDirty]);
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-wrap">
        {locationOpts?.length > 0 && (
          <SelectGrp
            {...fs.locationId}
            {...getAccess(sectionAccess, 9)}
            options={locationOpts}
            errors={errors.locationId}
            defaultValue={item.locationId}
            ref={register({ required: 'Location is required' })}
            onChange={() => setIsDirty(true)}
          />
        )}
        {deptOpts?.length > 0 && (
          <SelectGrp
            {...fs.deptId}
            {...getAccess(sectionAccess, 9)}
            options={deptOpts}
            errors={errors.deptId}
            defaultValue={item.deptId}
            ref={register({ required: 'Department is required' })}
            onChange={() => setIsDirty(true)}
          />
        )}
        {subdeptOpts?.length > 0 && (
          <SelectGrp
            {...fs.subDeptId}
            {...getAccess(sectionAccess, 9)}
            options={subdeptOpts}
            errors={errors.subDeptId}
            defaultValue={item.subDeptId}
            ref={register({ required: 'Sub Department is required' })}
            onChange={() => setIsDirty(true)}
          />
        )}
        {subdept2Opts?.length > 0 && (
          <SelectGrp
            {...fs.subDept2Id}
            {...getAccess(sectionAccess, 9)}
            options={subdept2Opts}
            errors={errors.subDept2Id}
            defaultValue={item.subDept2Id}
            ref={register({ required: 'Sub Department 2 is required' })}
            onChange={() => setIsDirty(true)}
          />
        )}
        <InputGrpDecimal 
          {...fs.payRate}
          {...getAccess(sectionAccess, 9)}
          errors={errors.payRate}
          value={+payRate}
          ref={register()}
          style={{ textAlign: 'right' }} 
          onBlur = {onPayRateChange}
        />
      </div>
      <div className="row">
        <div className="col-12 text-right">
          <button
            {...getAccess(sectionAccess, 9, undefined, { disabledSameAsReadOnly: true })}
            type="button"
            className="btn btn-link dm-grid-action-title mr-4"
            onClick={() => { return onDelete(item); }}
          >
            Delete this Rule
            <Icon
              name="minus-circle"
              className="fa-minus-circle"
            />
          </button>
          <button 
            {...getAccess(sectionAccess, 9, undefined, { disabledSameAsReadOnly: true, disabledDefault: !isDirty })}
            type="submit"
            className="btn orange-button-sm mr-3"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default ClientRateRuleItem;
