import { IOption } from 'interfaces/IOption';
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Select, { MultiValue } from 'react-select';
import { useAppSelector } from 'utilities/hooks';
import { StylesConfigType, compStyles } from 'utilities/styles';
import { DownloaderContext } from '../UserMapPage';

type Props = {
  formMethods?: any;
  title?: string;
  section?: string;
  section2?: string;
  options: IOption[];
  hasContainer?: boolean;
  children?: React.ReactNode;
  onChange?: (selected: IOption[]) => void | null;
  name?: string;
  grossToNetChecked?: boolean | null;
};

interface OptionModule extends IOption {
  module?: string;
}

const CustomOption = (props: any, selected: IOption[]) => {
  const { innerProps, innerRef } = props;

  const isSelected: boolean = useMemo(() => {
    return !!selected.find((item: IOption) => {
      return item.value === props?.data?.value;
    });
  }, [selected]);

  return (
    <div
      className="px-2 py-2 d-flex align-items-center"
      style={{
        gap: '0.5rem',
      }}
      ref={innerRef}
      {...innerProps}
    >
      <input
        type="checkbox"
        checked={isSelected}
      />
      <label
        className="h6 mb-0"
      >
        {props?.data?.label}
      </label>
    </div>
  );
};

const SELECT_ALL_TEXT = 'Select All';

const SelectField = ({
  title,
  options,
  hasContainer = true,
  children = null,
  onChange,
  section,
  section2 = '',
  name,
  grossToNetChecked = null,
}: Props) => {
  const selectedFields = useAppSelector((state) => { return state.employeeEarningsDownload.selectedFields; });
  let parsedOptions = cloneDeep(options);
  const [count, setCount] = useState(-1);

  if (options.length > 2) {
    parsedOptions = [
      {
        value: SELECT_ALL_TEXT,
        label: SELECT_ALL_TEXT,
      },
      ...parsedOptions,
    ];
  }

  const [selectedValues, setSelectedValues] = useState<OptionModule[]>([]);
  
  const { setIsDirty } = useContext(DownloaderContext);

  useEffect(() => {
    const sFields = selectedFields?.filter(item => {
      //Some dropdowns (Im looking at you YTD Company Benefits) will have 2 sections in them after saving so we need to handle both :(
      return item.section === section || item.section === section2;
    });

    const selected: any[] = [];

    sFields?.forEach((item) => {
      const CODE = item?.filter?.code;
      const DESCRIPTION = item?.filter?.description;

      const FOUND_OPTION = parsedOptions.find((fItem: any) => {
        return fItem.value === CODE && fItem.label === DESCRIPTION;
      });

      if (FOUND_OPTION) {
        selected.push({
          ...item,
          ...FOUND_OPTION,
        });
      }
    });

    setSelectedValues(selected);   
  }, []);

  //Will check to see if Gross to net is true. If so then it will select all of the options. In the Earnings Deductions Section.
  //If gross to net is unchecked then it will remove the deductions. 
  useEffect(() =>  {
    //Same as the gross to net need logic to skip first render //TODO BETTER THIS
    if (count !== 1) return setCount(count + 1);
    if (grossToNetChecked) {
      setSelectedValues(parsedOptions.map(item => {
        // @ts-ignore
        if (item.selectedAreaId) {
          return item;
        }
  
        return {
          ...item,
          selectedAreaId: 0,
        };
      }));
    } else {
      setSelectedValues([]);
    }
  }, [grossToNetChecked]);

  const Selectbox = (
    <>
      <Select
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        styles={compStyles as StylesConfigType}
        value={selectedValues}
        options={[{
          options: parsedOptions,
        }]}
        onChange={(s: MultiValue<IOption>, meta: any) => {
          if (meta?.action === 'deselect-option' && meta?.option?.value === SELECT_ALL_TEXT) {
            setSelectedValues([]);
            return;
          }

          const isSelectAll = s?.[s?.length - 1]?.value === SELECT_ALL_TEXT;

          const values = isSelectAll
            ? parsedOptions
            : s.filter(item => { return item.value !== SELECT_ALL_TEXT; });

          setSelectedValues(values.map(item => {
            // @ts-ignore
            if (item.selectedAreaId) {
              return item;
            }

            return {
              ...item,
              selectedAreaId: 0,
            };
          }));
          
          setIsDirty(true);
        }}
        components={{
          Option: (props: any) => { return CustomOption(props, selectedValues); },
        }}
      />
      {children && (
        <div>{children}</div>
      )}
    </>
  );

  useEffect(() => {
    if (onChange) {
      onChange?.(selectedValues.filter(item => {
        return item.value !== SELECT_ALL_TEXT;
      }));
    }
  }, [selectedValues]);

  if (!hasContainer) {
    return Selectbox;
  }

  return (
    <div
      className={title ? 'dm-panel-blue dm-panel-border' : ''}
    >
      {title && (
        <>
          <div className="d-flex flex-row">
            <div className="dm-grid-action-title-blue">{title}</div>
          </div>
          <hr className="dm-panel-hr" />
        </>
      )}
      <div className="d-flex flex-row flex-wrap">
        {Selectbox}
      </div>
    </div>
  );
};

export default SelectField;