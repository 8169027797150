import { Modal } from 'react-bootstrap';
import Icon from 'core/components/shared/Icon';
import React from 'react';
import DatePickerGrpInLine from '../DatePickerGrpInLine';
import styles from './wizardModal.module.scss';

type PropTypes = {
  show: boolean;
  modalTitle: string;
  body: string
  datePickerLabel: string
  onHide: () => void;
  next?: () => void;
  previous?: () => void;
  submit?: () => void;
  defaultValue: Date | string;
  setValue: React.Dispatch<React.SetStateAction<Date | string>>
};

const WizardDateSelectorModal: React.FC<PropTypes> = ({
  show,
  modalTitle,
  body,
  datePickerLabel,
  onHide,
  next,
  previous,
  submit,
  defaultValue,
  setValue,
}) => {

  const onHideAlert = () => {
      if (!confirm('Are you sure you would like to leave without saving? This will cancel all progress and not save any changes to the employee master.')) return;
      onHide();
    }

  return (
  <Modal 
    show={show}
    animation={false}
    size='sm'
    onHide={onHideAlert}
  >
    <div className="modal-header">
      <div className="dm-card-title">
        {modalTitle}
      </div>
      <button type="button" className="modal-close-btn"
        onClick={onHideAlert}>
        <Icon name="times"
        />
      </button>
    </div>
    <Modal.Body className={styles['modalBody']}>
    <div className="d-flex flex-column ml-3">
      <p>{body}</p>
    </div>
    <div className="d-flex flex-column text-center">
    <DatePickerGrpInLine
        name={'date'}
        label={datePickerLabel}
        labelWidth={40}
        groupClass={'groupClass50'}
        required={true}
        value={defaultValue}
        onChange={setValue}
    />
    </div>
    </Modal.Body>
    <div className="row mt-3">
      <div className="col-12 text-right">
      {previous ? <button
          type="button"
          className="btn btn-link"
          style={{ fontSize: '14px' }}
          onClick={previous}
        >
        <Icon name="chevron-left" /> Previous
        </button> : null}
        {next ? <button
          type="button"
          className="btn btn-link"
          style={{ fontSize: '14px' }}
          onClick={next}
        >
          Next <Icon name="chevron-right" />
        </button> : null}
        {submit ? <button
            type="button"
            className="btn orange-button-sm mr-3"
            onClick={submit}
        >
            Submit
        </button> : null}
      </div>
    </div>
  </Modal>
  );
};

export default WizardDateSelectorModal;
