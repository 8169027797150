import { AxiosResponse } from 'axios';
import { Deduction, GarnishmentEntities, HttpResponse } from '../models';
import { API_CLIENT, API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { ClientMasterEndPoints, EmployeeEndPoints } from './URL';

const getDeductions = (empNo: string): Promise<AxiosResponse<Deduction[]>> => { return API_EMPLOYEE_MASTER.get<Deduction[]>(EmployeeEndPoints.GET_DEDUCTIONS(CommonService.getProtectedClientNo(), empNo)); };

const getDeductionsByType = (empNo: number, type: string): Promise<AxiosResponse<Deduction[]>> => { return API_EMPLOYEE_MASTER.get<Deduction[]>(EmployeeEndPoints.GET_DEDUCTIONS_BY_TYPE(CommonService.getProtectedClientNo(), empNo, type)); };

const postDeduction = (protectedEmpNo: string, data: Deduction[]): Promise<AxiosResponse<HttpResponse<Deduction[]>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<Deduction[]>>(EmployeeEndPoints.POST_DEDUCTION(CommonService.getProtectedClientNo(), protectedEmpNo), data); };

const postDeductionShortage = (data: Deduction): Promise<AxiosResponse<HttpResponse<Deduction>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<Deduction>>(EmployeeEndPoints.POST_DEDUCTION_SHORTAGE(CommonService.getProtectedClientNo()), data); };

const deleteDeduction = (data: Deduction): Promise<AxiosResponse<Deduction>> => { return API_EMPLOYEE_MASTER.delete<Deduction>(EmployeeEndPoints.DELETE_DEDUCTION(CommonService.getProtectedClientNo(), data.protectedEmpNo, data.dedId)); };

const getCompanyBenefits = (empNo: string): Promise<AxiosResponse<Deduction[]>> => { return API_EMPLOYEE_MASTER.get<Deduction[]>(EmployeeEndPoints.GET_COMPANY_BENEFITS(CommonService.getProtectedClientNo(), empNo)); };

const getGarnishmentEntities = (): Promise<AxiosResponse<GarnishmentEntities[]>> => { return API_CLIENT.get<GarnishmentEntities[]>(ClientMasterEndPoints.GET_GARNISHMENT_ENTITIES(CommonService.getProtectedClientNo())); };

export const DeductionService = {
  getDeductions,
  getDeductionsByType,
  postDeduction,
  postDeductionShortage,
  deleteDeduction,
  getCompanyBenefits,
  getGarnishmentEntities,
};
