import React, { useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import EmpMasterMapsSidebar from './EmpMasterMapsSidebar';
import HandleInfoFields from '../Shared/HandleInfoFields';
import { Row, Col } from 'react-bootstrap';

const EmpMasterMapsPage = () => {
  const { downloaderType, userMapId } = useParams<any>();
  return (
    <Row>
      <Col sm={2}>
        <EmpMasterMapsSidebar />
      </Col>
      <Col sm={10}>
        <Switch>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}`}
          >
            <HandleInfoFields
              pageName="Employee Information"
              title="Employee Information"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/snapshot`}
          >
            <HandleInfoFields
              pageName={'Employee Information'}
              title={'Employee Information'}
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/emergency-contact`}
          >
            <HandleInfoFields
              pageName={'Emergency Contact'}
              title={'Emergency Contact'}
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/deductions`}
          >
            <HandleInfoFields
              pageName={'Employee Deductions'}
              title={'Employee Deductions'}
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/foreign-address`}
          >
            <HandleInfoFields
              pageName={''}
              title={'Employee Information'}
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/payrate`}
          >
            <HandleInfoFields
              pageName={''}
              title={'Employee Information'}
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/taxes`}
          >
            <HandleInfoFields
              pageName="Tax Information"
              title="Tax Information"
            />
          </Route>
          
          {/* Additional Information */}
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/aca`}
          >
            <HandleInfoFields
              pageName="ACA"
              title="ACA"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/accruals`}
          >
            <HandleInfoFields
              pageName="Accruals"
              title="Accruals"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/attendance-on-demand`}
          >
            <HandleInfoFields
              pageName="Attendance On Demand"
              title="Attendance On Demand"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/client-rate-rules`}
          >
            <HandleInfoFields
              pageName="Client Rate Rules"
              title="Client Rate Rules"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/company-benefits`}
          >
            <HandleInfoFields
              pageName="Company Benefits"
              title="Company Benefits"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/contractor-options`}
          >
            <HandleInfoFields
              pageName="Contractor Options"
              title="Contractor Options"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/dates`}
          >
            <HandleInfoFields
              pageName="Employee Dates"
              title="Employee Dates"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/deferred-comp`}
          >
            <HandleInfoFields
              pageName="Deferred Comp"
              title="Deferred Comp"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/earnings-records`}
          >
            <HandleInfoFields
              pageName="Earnings Records"
              title="Earnings Records"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/group-term-life`}
          >
            <HandleInfoFields
              pageName="Group Term Life"
              title="Group Term Life"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/hr-doc-comp`}
          >
            <HandleInfoFields
              pageName="HR Doctor Compensation"
              title="HR Doctor Compensation"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/hr-fte`}
          >
            <HandleInfoFields
              pageName="HR FTE"
              title="HR FTE"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/payroll-info`}
          >
            <HandleInfoFields
              pageName="Payroll Information"
              title="Payroll Information"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/realign-supervised-employees`}
          >
            <HandleInfoFields
              pageName="Realign Supervised Emp"
              title="Realign Supervised Emp"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/recurring-earnings`}
          >
            <HandleInfoFields
              pageName="Recurring Earnings"
              title="Recurring Earnings"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/self-serve`}
          >
            <HandleInfoFields
              pageName="Self Service"
              title="Self Service"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/special-info`}
          >
            <HandleInfoFields
              pageName="Special Information"
              title="Special Information"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/visa-status`}
          >
            <HandleInfoFields
              pageName="Visa Status"
              title="Visa Status"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/ytd`}
          >
            <HandleInfoFields
              pageName="Year To Date"
              title="Year To Date"
            />
          </Route>
        </Switch>
      </Col>
    </Row>
  );
};

EmpMasterMapsPage.displayName = 'EmpMasterMapsPage';

export default EmpMasterMapsPage;
