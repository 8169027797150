import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpResponse } from '../../models';
import {
  SalesTaxPayload,
  SalesTaxTransmittal,
  SalesTaxTransmittalEntity,
  SalesTaxTransmittalEntityPayload,
} from '../../models/SalesTaxTransmittal.model';
import { SalesTaxTransmittalService } from '../../services';
import {
  loadSalesTaxTransmittalByYear,
  loadSalesTaxTransmittalByEntity,
  storeSalesTaxTransmittal,
  storeSalesTaxTransmittalEntity,
  updateSalesTaxTransmittalEntity,
  closeSalesTaxTransmittal,
  loadSalesTaxTransmittalReport,
  storeSalesTaxTransmittalReport,
  toggleLoadingState,
} from '../actions';
import { handleError } from '../actions/app.action';

interface Actions {
  type: string;
  payload: any;
}

const loadSalesTaxTransmittal$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadSalesTaxTransmittalByYear.type),
    switchMap((action: { payload: number; }) => {
      return from(
        SalesTaxTransmittalService.fetchSalesTaxTransmittal(
          action.payload,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: SalesTaxTransmittal[]) => { return storeSalesTaxTransmittal(res); },
        ),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            toggleLoadingState(false),
          ];
        }),
      );
    },
    ),
  );
};

const loadSalesTaxTransmittalEntity$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadSalesTaxTransmittalByEntity.type),
    switchMap((action: { payload: SalesTaxTransmittalEntityPayload; }) => {
      return from(
        SalesTaxTransmittalService.fetchSalesTaxTransmittalByEntityAndMonthId(
          action.payload,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: SalesTaxTransmittalEntity[]) => { return storeSalesTaxTransmittalEntity(res); },
        ),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            toggleLoadingState(false),
          ];
        }),
      );
    },
    ),
  );
};

const updateSalesTaxTransmittalEntity$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateSalesTaxTransmittalEntity.type),
    switchMap((action: { payload: SalesTaxTransmittalEntityPayload; }) => {
      return from(
        SalesTaxTransmittalService.updateSalesTaxTransmittalByEntityId(
          action.payload,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<any>) => {
          return [
            loadSalesTaxTransmittalByEntity({
              salesTaxMonthId: action.payload.salesTaxMonthId, // not needed here though
              entityId: action.payload.entityId,
              month: action.payload.month,
              year: action.payload.year,
            }),
            loadSalesTaxTransmittalByYear(action.payload.year),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
          ];
        }),
      );
    },
    ),
  );
};

const closeSalesTaxTransmittal$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(closeSalesTaxTransmittal.type),
    switchMap((action: { payload: SalesTaxPayload; }) => {
      return from(
        SalesTaxTransmittalService.closeSalesTaxTransmittal(
          action.payload,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return loadSalesTaxTransmittalByYear(action.payload.year); },
        ),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
          ];
        }),
      );
    },
    ),
  );
};

const reportSalesTaxTransmittal$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadSalesTaxTransmittalReport.type),
    switchMap((action: { payload: SalesTaxTransmittalEntityPayload; }) => {
      return from(
        SalesTaxTransmittalService.reportSalesTaxTransmittalByEntityId(
          action.payload,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return storeSalesTaxTransmittalReport(res); },
        ),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            toggleLoadingState(false),
          ];
        }),
      );
    },
    ),
  );
};
export const epics: any[] = [
  loadSalesTaxTransmittal$,
  loadSalesTaxTransmittalEntity$,
  closeSalesTaxTransmittal$,
  updateSalesTaxTransmittalEntity$,
  reportSalesTaxTransmittal$,
];
