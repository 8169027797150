import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'core/models/Dropdown.model';
import {
  PayrollControlTotalDetail,
  PayrollControlTotalDetailType,
  PayrollControlTotalRequest,
} from 'core/models/PayrollControlTotalDetail';
import { getControlTotalTimeCardDetails } from 'core/store/actions';
import {
  getDeductionCodes,
  getEarningsCodes,
} from 'core/store/selectors';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { formatWithCommas } from 'utilities/utilities';

interface Naming {
  title: string;
  code: string;
  total: string;
  description: string;
}

type Props = {
  request: PayrollControlTotalRequest;
  onHide: () => void;
};

export const TimeCardControlTotalDetails = (props: Props) => {
  const dispatch = useAppDispatch();
  const [showControlTotalDetail, setShowControlTotalDetail] = useState(false);
  const [selectedDetail, setControlTotalDetail] = useState<PayrollControlTotalDetail | null | undefined>();
  
  const payrollControlTotalDetails = useAppSelector(
    (state) => {return state.contractor.timeCardControlTotalDetails;},
  );

  const earningsCodes = useSelector(getEarningsCodes);
  const deductionCodes = useSelector(getDeductionCodes);

  const toggleEmployeeDetail = (show: boolean, detail: PayrollControlTotalDetail | null) => {
    setShowControlTotalDetail(show);
    setControlTotalDetail(detail);
  };

  let codes: Dropdown[] = [];
  useEffect(() => {
    dispatch(getControlTotalTimeCardDetails(props.request));
  }, []);

  let naming: Naming = { title: '',
    code: '',
    total: '',
    description: '' };
  let padStart = 1;
  switch (props.request.type) {
    case PayrollControlTotalDetailType.OtherEarnings:
      naming = {
        title: 'Other Earnings',
        code: 'Earnings Code',
        total: 'Earnings Total',
        description: 'an earnings'
      };
      codes = earningsCodes;
      break;
    case PayrollControlTotalDetailType.OtherHours:
      naming = {
        title: 'Other Hours',
        code: 'Earnings Code',
        total: 'Other Hours Total',
        description: 'an hour'
      };
      codes = earningsCodes;
      break;
    case PayrollControlTotalDetailType.SpecialDeductions:
      naming = {
        title: 'Special Deductions',
        code: 'Special Ded Code',
        total: 'Special Deduction Total',
        description: 'a deduction'
      };
      codes = deductionCodes;
      padStart = 2;
      break;
  }

  return (
    <>
      {!showControlTotalDetail ?
        (
          <div>
            <div className="row mb-2">
              <h3 className="col dm-card-title">
                Control Total Details - {naming.title}
              </h3>
              <div className="col-sm-12">
                <hr className="hr-2" />
              </div>
            </div>

            <div className="row justify-content-center align-self-center">
              <b>Click on {naming.description} to see employee detail</b>
            </div>

            <div className="row">
              <table className="mx-3 table-wrapper-wrapper table table-striped table-sm table-hover table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th>{naming.code}</th>
                    <th className="text-right">{naming.total}</th>
                  </tr>
                </thead>
                <tbody>
                  {payrollControlTotalDetails.map((detail, index) => {
                    return (
                      <tr 
                        key={index}
                        onClick={() => { return toggleEmployeeDetail(true, detail); }}
                      >
                        <td>
                          {detail.code.padStart(padStart, '0')} -{' '}
                          {codes.find((a) => a.id === detail.code)?.description
                          }
                        </td>
                        <td className="text-right">
                          {formatWithCommas(detail.amount)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="row justify-content-end">
              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-primary orange-button"
                  onClick={() => {return props.onHide();}}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )
        :
        (
          <div>
            <div className="row mb-2">
              <h3 className="col dm-card-title">
                Control Total Details - {naming.title} - {selectedDetail?.code}
              </h3>
              <div className="col-sm-12">
                <hr className="hr-2" />
              </div>
            </div>

            <div className="row">
              <table className="mx-3 table-wrapper-wrapper table table-striped table-sm table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th>Employee</th>
                    <th className="text-right">{naming.total}</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedDetail?.employees.map((detail, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {detail.empNo} - {detail.name}
                        </td>
                        <td className="text-right">
                          {detail.amount.toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="row justify-content-end">
              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button mr-2"
                  onClick={() => {return toggleEmployeeDetail(false, null); }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};
