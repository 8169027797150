import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { HttpResponse } from '../../models';
import {
  EmpHistory,
  HireGeneral,
  HireGeneralRequest,
  HireInfoCustomField,
  HRDependent,
  HRDependentRequest,
  HREducation,
  HREducationRequest,
  HREmployment,
  HREmploymentRequest,
  HRExitInterview,
  HRExitInterviewRequest,
  HRIncrease,
  HRIncreaseRequest,
  HRLicense,
  HRLicenseRequest,
  HRPerformanceReview,
  HRReviewRequest,
  HRSkills,
  HRSkillsRequest,
  HRSource,
  HRSourceRequest,
  HRTrainingEvent,
  HRTrainingEventRequest,
  HRVaccination,
  HRVaccinationRequest,
  HRVaccinationReportRequest,
  HRVaccinationFileRequest,
} from '../../models/HREmployee.model';
import { HREmployeeService } from '../../services/hr-employee.service';
import { handleError, handleSuccess } from '../actions';
import {
  loadEmployeeFieldHistory,
  loadEmployeeHireGeneral,
  loadHireInfoCustomFields,
  storeEmployeeFieldHistory,
  storeEmployeeHireGeneral,
  storeHireInfoCustomFields,
  updateEmployeeHireGeneral,
  updateHireInfoCustomFields,
  loadHREmployment,
  createHREmployment,
  deleteHREmployment,
  updateHREmployment,
  storeHREmployment,
  loadHREducation,
  createHREducation,
  deleteHREducation,
  updateHREducation,
  storeHREducation,
  loadHRLicense,
  storeHRLicense,
  deleteHRLicense,
  createHRLicense,
  updateHRLicense,
  createHRTrainingEvent,
  deleteHRTrainingEvent,
  loadHRTrainingEvent,
  storeHRTrainingEvent,
  updateHRTrainingEvent,
  createHRSkills,
  deleteHRSkills,
  loadHRSkills,
  storeHRSkills,
  updateHRSkills,
  createHRSource,
  deleteHRSource,
  loadHRSource,
  storeHRSource,
  updateHRSource,
  storeHREducations,
  storeHREmployments,
  storeHRLicenses,
  storeHRTrainingEvents,
  storeHRSkill,
  storeHRSources,
  loadHRExitInterview,
  createHRExitInterview,
  storeHRExitInterview,
  updateHRExitInterview,
  loadHRIncrease,
  storeHRIncrease,
  updateHRIncrease,
  loadHRReview,
  storeHRReview,
  updateHRReview,
  createHRDependent,
  deleteHRDependent,
  loadHRDependent,
  storeHRDependent,
  storeHRDependents,
  updateHRDependent,
  createHRVaccination,
  deleteHRVaccination,
  loadHRVaccination,
  storeHRVaccination,
  storeHRVaccinations,
  updateHRVaccination,
  downloadHRVaccinationReport,
  storeHRVaccinationReport,
  createHRVaccinationFile,
  deleteHRVaccinationFile,
} from '../actions/hr-employee.action';

interface Actions {
  type: string;
  payload: any;
}

const loadEmployeeFieldHistory$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmployeeFieldHistory.type),
    switchMap((action: { payload: { protectedEmpNo: string; section: string; field: string; }; }) => {
      return from(
        HREmployeeService.getEmployeeFieldHistory(
          action.payload.protectedEmpNo,
          action.payload.section,
          action.payload.field,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: EmpHistory) => { return storeEmployeeFieldHistory(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadEmployeeHireGeneral$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmployeeHireGeneral.type),
    switchMap((action: { payload: { protectedEmpNo: string; }; }) => {
      return from(
        HREmployeeService.getHireGeneral(action.payload.protectedEmpNo),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: HireGeneral) => { return storeEmployeeHireGeneral(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEmployeeHireGeneral$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateEmployeeHireGeneral.type),
    switchMap((action: { payload: HireGeneralRequest; }) => {
      return from(HREmployeeService.putHireGeneral(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HireGeneral>) => {
          return [
            storeEmployeeHireGeneral(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadHireInfoCustomFields$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadHireInfoCustomFields.type),
    switchMap((action: any) => {
      return from(HREmployeeService.getHireInfoCustomFields()).pipe(
        map((res: any) => { return res.data; }),
        map((res: HireInfoCustomField[]) => { return storeHireInfoCustomFields(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateHireInfoCustomFields$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateHireInfoCustomFields.type),
    switchMap((action: { payload: HireInfoCustomField[]; }) => {
      return from(
        HREmployeeService.putHireInfoCustomFields(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HireInfoCustomField[]>) => {
          return [
            storeHireInfoCustomFields(res.value),
            handleSuccess(res.messages),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadEmployment$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadHREmployment.type),
    switchMap((action: { payload: string; }) => {
      return from(HREmployeeService.getEmployment(
        action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HREmployment[]) => { return storeHREmployments(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createEmployment$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createHREmployment.type),
    switchMap((action: { payload: HREmploymentRequest; }) => {
      return from(HREmployeeService.postEmployment(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HREmployment>) => {
          return [
            storeHREmployment(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEmployment$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateHREmployment.type),
    switchMap((action: { payload: HREmploymentRequest; }) => {
      return from(HREmployeeService.putEmployment(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HREmployment>) => {
          return [
            loadHREmployment(
              action.payload.protectedEmpNo,
            ),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteEmployment$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteHREmployment.type),
    switchMap((action: { payload: HREmploymentRequest; }) => {
      return from(HREmployeeService.deleteEmployment(action.payload)).pipe(
        map(() => { return loadHREmployment(action.payload.protectedEmpNo); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};


const loadEducation$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadHREducation.type),
    switchMap((action: { payload: string; }) => {
      return from(HREmployeeService.getEducation(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HREducation[]) => { return storeHREducations(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createEducation$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createHREducation.type),
    switchMap((action: { payload: HREducationRequest; }) => {
      return from(HREmployeeService.postEducation(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HREducation>) => {
          return [
            storeHREducation(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEducation$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateHREducation.type),
    switchMap((action: { payload: HREducationRequest; }) => {
      return from(HREmployeeService.putEducation(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HREducation>) => {
          return [
            loadHREducation(action.payload.protectedEmpNo),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteEducation$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteHREducation.type),
    switchMap((action: { payload: HREducationRequest; }) => {
      return from(HREmployeeService.deleteEducation(action.payload)).pipe(
        map(() => { return loadHREducation(action.payload.protectedEmpNo); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};


const loadLicense$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadHRLicense.type),
    switchMap((action: { payload: string; }) => {
      return from(HREmployeeService.getLicense(
        action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HRLicense[]) => { return storeHRLicenses(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createLicense$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createHRLicense.type),
    switchMap((action: { payload: HRLicenseRequest; }) => {
      return from(HREmployeeService.postLicense(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HRLicense>) => {
          return [
            storeHRLicense(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateLicense$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateHRLicense.type),
    switchMap((action: { payload: HRLicenseRequest; }) => {
      return from(HREmployeeService.putLicense(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HRLicense>) => {
          return [
            loadHRLicense(
              action.payload.protectedEmpNo,
            ),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteLicense$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteHRLicense.type),
    switchMap((action: { payload: HRLicenseRequest; }) => {
      return from(HREmployeeService.deleteLicense(action.payload)).pipe(
        map(() => { return loadHRLicense(action.payload.protectedEmpNo); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};


const loadTraining$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadHRTrainingEvent.type),
    switchMap((action: { payload: string; }) => {
      return from(HREmployeeService.getTraining(
        action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HRTrainingEvent[]) => { return storeHRTrainingEvents(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createTraining$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createHRTrainingEvent.type),
    switchMap((action: { payload: HRTrainingEventRequest; }) => {
      return from(HREmployeeService.postTraining(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HRTrainingEvent>) => {
          return [
            storeHRTrainingEvent(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateTraining$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateHRTrainingEvent.type),
    switchMap((action: { payload: HRTrainingEventRequest; }) => {
      return from(HREmployeeService.putTraining(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HRTrainingEvent>) => {
          return [
            loadHRTrainingEvent(
              action.payload.protectedEmpNo,
            ),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteTraining$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteHRTrainingEvent.type),
    switchMap((action: { payload: HRTrainingEventRequest; }) => {
      return from(HREmployeeService.deleteTraining(action.payload)).pipe(
        map(() => { return loadHRTrainingEvent(action.payload.protectedEmpNo); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};




const loadSkills$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadHRSkills.type),
    switchMap((action: { payload: string; }) => {
      return from(HREmployeeService.getSkills(
        action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HRSkills[]) => { return storeHRSkills(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createSkills$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createHRSkills.type),
    switchMap((action: { payload: HRSkillsRequest; }) => {
      return from(HREmployeeService.postSkills(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HRSkills>) => {
          return [
            storeHRSkill(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateSkills$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateHRSkills.type),
    switchMap((action: { payload: HRSkillsRequest; }) => {
      return from(HREmployeeService.putSkills(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HRSkills>) => {
          return [
            loadHRSkills(
              action.payload.protectedEmpNo,
            ),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteSkills$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteHRSkills.type),
    switchMap((action: { payload: HRSkillsRequest; }) => {
      return from(HREmployeeService.deleteSkills(action.payload)).pipe(
        map(() => { return loadHRSkills(action.payload.protectedEmpNo); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};



const loadSource$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadHRSource.type),
    switchMap((action: { payload: string; }) => {
      return from(HREmployeeService.getSource(
        action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HRSource[]) => { return storeHRSources(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createSource$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createHRSource.type),
    switchMap((action: { payload: HRSourceRequest; }) => {
      return from(HREmployeeService.postSource(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HRSource>) => {
          return [
            storeHRSource(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateSource$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateHRSource.type),
    switchMap((action: { payload: HRSourceRequest; }) => {
      return from(HREmployeeService.putSource(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HRSource>) => {
          return [
            loadHRSource(
              action.payload.protectedEmpNo,
            ),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteSource$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteHRSource.type),
    switchMap((action: { payload: HRSourceRequest; }) => {
      return from(HREmployeeService.deleteSource(action.payload)).pipe(
        map(() => { return loadHRSource(action.payload.protectedEmpNo); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};


const loadExitInterview$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadHRExitInterview.type),
    switchMap((action: { payload: string; }) => {
      return from(HREmployeeService.getExitInterview(
        action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HRExitInterview) => { return storeHRExitInterview(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createExitInterview$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createHRExitInterview.type),
    switchMap((action: { payload: HRExitInterviewRequest; }) => {
      return from(HREmployeeService.postExitInterview(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HRExitInterview>) => {
          return [
            storeHRExitInterview(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateExitInterview$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateHRExitInterview.type),
    switchMap((action: { payload: HRExitInterviewRequest; }) => {
      return from(HREmployeeService.putExitInterview(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HRExitInterview>) => {
          return [
            storeHRExitInterview(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};


const loadIncrease$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadHRIncrease.type),
    switchMap((action: { payload: string; }) => {
      return from(HREmployeeService.getIncreases(
        action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HRIncrease) => { return storeHRIncrease(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateIncrease$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateHRIncrease.type),
    switchMap((action: { payload: HRIncreaseRequest; }) => {
      return from(HREmployeeService.putIncrease(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HRIncrease>) => {
          return [
            storeHRIncrease(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadReview$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadHRReview.type),
    switchMap((action: { payload: string; }) => {
      return from(HREmployeeService.getReview(
        action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HRPerformanceReview) => { return storeHRReview(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateReview$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateHRReview.type),
    switchMap((action: { payload: HRReviewRequest; }) => {
      return from(HREmployeeService.putReview(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HRPerformanceReview>) => {
          return [
            loadHRReview(
              action.payload.protectedEmpNo,
            ),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};



const loadDependent$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadHRDependent.type),
    switchMap((action: { payload: string; }) => {
      return from(HREmployeeService.getDependent(
        action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HRDependent[]) => { return storeHRDependents(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createDependent$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createHRDependent.type),
    switchMap((action: { payload: HRDependentRequest; }) => {
      return from(HREmployeeService.postDependent(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HRDependent>) => {
          return [
            storeHRDependent(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateDependent$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateHRDependent.type),
    switchMap((action: { payload: HRDependentRequest; }) => {
      return from(HREmployeeService.putDependent(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HRDependent>) => {
          return [
            loadHRDependent(
              action.payload.protectedEmpNo,
            ),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteDependent$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteHRDependent.type),
    switchMap((action: { payload: HRDependentRequest; }) => {
      return from(HREmployeeService.deleteDependent(action.payload)).pipe(
        map(() => { return loadHRDependent(action.payload.protectedEmpNo); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};


const loadVaccination$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadHRVaccination.type),
    switchMap((action: { payload: string; }) => {
      return from(HREmployeeService.getVaccination(
        action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HRVaccination[]) => { return storeHRVaccinations(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createVaccination$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createHRVaccination.type),
    switchMap((action: { payload: HRVaccinationRequest; }) => {
      return from(HREmployeeService.postVaccination(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HRVaccination>) => {
          return [
            storeHRVaccination(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateVaccination$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateHRVaccination.type),
    switchMap((action: { payload: HRVaccinationRequest; }) => {
      return from(HREmployeeService.putVaccination(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HRVaccination>) => {
          return [
            loadHRVaccination(
              action.payload.protectedEmpNo,
            ),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteVaccination$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteHRVaccination.type),
    switchMap((action: { payload: HRVaccinationRequest; }) => {
      return from(HREmployeeService.deleteVaccination(action.payload)).pipe(
        map(() => { return loadHRVaccination(action.payload.protectedEmpNo); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};


const downloadVaccinationReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(downloadHRVaccinationReport.type),
    switchMap((action: { payload: HRVaccinationReportRequest; }) => {
      return from(HREmployeeService.postVaccinationReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<string>) => { return storeHRVaccinationReport(res.value); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};


const createVaccinationFile$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createHRVaccinationFile.type),
    switchMap((action: { payload: HRVaccinationFileRequest; }) => {
      return from(HREmployeeService.postVaccinationFIle(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<any>) => {
          return [
            loadHRVaccination(action.payload.protectedEmpNo),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteVaccinationFile$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteHRVaccinationFile.type),
    switchMap((action: { payload: HRVaccinationFileRequest; }) => {
      return from(HREmployeeService.deleteVaccinationFile(action.payload)).pipe(
        map(() => { return loadHRVaccination(action.payload.protectedEmpNo); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadEmployeeFieldHistory$,
  loadEmployeeHireGeneral$,
  updateEmployeeHireGeneral$,
  loadHireInfoCustomFields$,
  updateHireInfoCustomFields$,
  loadEmployment$,
  createEmployment$,
  updateEmployment$,
  deleteEmployment$,
  loadEducation$,
  createEducation$,
  updateEducation$,
  deleteEducation$,
  loadLicense$,
  createLicense$,
  updateLicense$,
  deleteLicense$,
  loadTraining$,
  createTraining$,
  updateTraining$,
  deleteTraining$,
  loadSkills$,
  createSkills$,
  updateSkills$,
  deleteSkills$,
  loadSource$,
  createSource$,
  updateSource$,
  deleteSource$,
  loadExitInterview$,
  createExitInterview$,
  updateExitInterview$,
  loadIncrease$,
  updateIncrease$,
  loadReview$,
  updateReview$,
  loadDependent$,
  createDependent$,
  updateDependent$,
  deleteDependent$,
  loadVaccination$,
  createVaccination$,
  updateVaccination$,
  deleteVaccination$,
  downloadVaccinationReport$,
  createVaccinationFile$,
  deleteVaccinationFile$,
];