import React from 'react';
import Icon from 'core/components/shared/Icon';
import { Note } from 'core/models';
import { toReadableDate, toReadableTime } from 'utilities/utilities';
import NoteItem from './NoteItem';

type Props = {
  notes: Note[];
  onDelete: (note: Note) => void;
};

const NoteList = ({ notes, onDelete }: Props) => {
  return (
    <div
      className="d-flex flex-column p-3"
      style={{
        gap: '1rem',
      }}
    >
      {notes.length ? notes.map(note => {
        return (
          <NoteItem
            {...note}
            key={note.noteId}
            onDelete={onDelete}
          />
        );
      }) : (
        <p className="mt-4 mb-0">No notes found.</p>
      )}
    </div>
  );
};

export default NoteList;