import React from 'react';
import CheckBoxForm from '../../Shared/CheckBoxForm';
import { Col, Row } from 'react-bootstrap';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for Checkbox and Grid select forms
const EmployeeStatusStatusFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  return (
    <Row>
      <Col sm={6}>
        <Row>
          {!!sectionFields?.length && (
            <Col xs={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'Status';
                })?.fields}
                section="Status"
                title="Status"
              />
            </Col>
          )}
          {!!sectionFields?.length && (
            <Col xs={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'Veteran Status';
                })?.fields}
                section="Veteran Status"
                title="Veteran Status"
              />
            </Col>
          )}
        </Row>
      </Col>
      <Col sm={6}>
        <Row>
          {!!sectionFields?.length && (
            <Col xs={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'Visa';
                })?.fields}
                section="Visa"
                title="Visa"
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default EmployeeStatusStatusFields;