import { createAction } from '@reduxjs/toolkit';
import { TimeOffRequest } from '../../models';

export const loadTimeOffPastDue = createAction(
  '[timeoff] LOAD_TIME_OFF_PAST_DUE',
);

export const storeTimeOffPastDue = createAction<TimeOffRequest[]>(
  '[timeoff] STORE_TIME_OFF_PAST_DUE',
);
