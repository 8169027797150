import React, { CSSProperties } from 'react';
import { Labels } from '.';
import styles from './form-controls.module.scss';

type PropTypes = {
  id?: string;
  name: string;
  checked: boolean;
  label?: string;
  tallLabel?: boolean;
  groupClass?: string;
  labelClass?: string;
  groupStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  required?: boolean;
  errors?: any;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const FormlessCheckboxGrp = (
  {
    id,
    name,
    checked,
    label,
    tallLabel = false,
    groupClass,
    groupStyle,
    labelClass,
    labelStyle,
    required = false,
    errors,
    disabled = false,
    onChange,
    ...extraProps
  }: PropTypes,
) => {
  const groupClass2 = groupClass
    ? 'form-check ' + groupClass
    : 'form-group';
  id = id ?? name;

  return (
    <div
      className={`d-flex align-items-center ${groupClass2} pe-auto`}
      style={groupStyle}
    >
      <Labels
        label={label}
        tallLabel={tallLabel}
        id={id}
        hasError={!!errors}
        required={required}
        labelClass={`${labelClass} mr-2`}
        labelStyle={labelStyle}
      />
      <input
        className={styles['dm-form-check-input'] + (errors ? ' ' + styles['dm-is-invalid'] : '')}
        id={id}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...extraProps}
      />
      <small className="text-danger">{errors?.message} </small>
    </div>
  );
};