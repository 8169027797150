import { AgGridReact } from '@ag-grid-community/react';
import { GridApi, ColDef, GridOptions } from '@ag-grid-enterprise/all-modules';
import { ControlDatePickerGrpInLine } from 'core/components/form-controls';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import Icon from 'core/components/shared/Icon';
import { UnionDuesReportRequest, UnionDuesSettingsRequest } from 'core/models/PayrollReports.model';
import { loadEarningCodesDropdown, loadUnionDuesSettings, clearUnionDuesReport, downloadUnionDuesReport } from 'core/store/actions';
import { DateTime } from 'luxon';
import React, { useState, useEffect } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import AGEditButtonRendererComponent from 'utilities/ag-grid-renderers/AGEditButtonRendererComponent';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import UnionDuesSettingsModal from './UnionDuesSettingsModal';


const UnionDuesReportPage = () => {
  const dispatch = useAppDispatch();

  const report = useAppSelector((state) => { return state.payrollReports.unionDuesReport; });
  const client = useAppSelector((state) => { return state.client.client; });
  const { unionDueSettings, runningUnionDues } = useAppSelector((state) => { return state.payrollReports; });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedSetting, setSelectedSetting] = useState<UnionDuesSettingsRequest | undefined>(undefined);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [selectedSettingId, setSelectedSettingId] = useState<number | null>();

  const { control, getValues, watch, errors } =
    useForm<UnionDuesReportRequest>({});

  const fromDate = watch('dateFrom');
  const toDate = watch('dateTo');


  useEffect(() => {
    dispatch(loadEarningCodesDropdown());
    dispatch(loadUnionDuesSettings());
  }, []);

  useEffect(() => {
    selectedSetting && setShowModal(true);
  }, [selectedSetting]);

  const downloadReport = () => {
    if (selectedSettingId) {
      const submitData: UnionDuesReportRequest = {
        clientNo: client?.clientNo!,
        dateTo: new Date(getValues('dateTo')).toISOString(),
        dateFrom: new Date(getValues('dateFrom')).toISOString(),
        settingID: selectedSettingId,
      };
      dispatch(downloadUnionDuesReport(submitData));
    }
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSettingEdit = (rowData: UnionDuesSettingsRequest) => {
    rowData && setSelectedSetting({ ...rowData });
  };
  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      selectedRows.length > 0 &&
        setSelectedSettingId(selectedRows[0].settingID);
    }
  };

  const onCancel = () => {
  };

  const hidePdfViewer = () => {
    dispatch(clearUnionDuesReport());
  };

  const columns: ColDef[] = [
    {
      field: 'settingID',
      hide: true,
    },
    {
      field: 'select',
      headerName: '',
      editable: false,
      checkboxSelection: true,
      width: 40,
    },
    {
      field: 'settingName',
      headerName: 'Setting Name',
      sortable: true,
      width: 300,
      flex: 1,
      cellStyle: {
        textAlign: 'left',
        fontSize: 14,
        color: '#00558c',
      },
    },
    {
      field: 'edit',
      editable: false,
      cellRendererParams: { clickHandler: onSettingEdit },
      cellRenderer: 'editButtonRendererComponent',
      width: 60,
    },
  ];

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    columnDefs: columns,
    rowSelection: 'single',
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    frameworkComponents: {
      editButtonRendererComponent: AGEditButtonRendererComponent,
    },
  };

  return (
    <Row className="w-100">
      <Col>
        <div>
          <div className="dm-panel dm-panel-border">
            <div className="dm-grid-title">Union Dues Report</div>

            <hr className="dm-panel-hr" />

            <div className="d-flex flex-row flex-grow-1">
              <div className="d-flex flex-row flex-grow-1">
                <div>
                  <ControlDatePickerGrpInLine
                    name={'dateFrom'}
                    label={'From'}
                    labelWidth={40}
                    groupClass={'groupClass50'}
                    required={true}
                    control={control}
                    errors={errors?.dateFrom}
                    rules={{
                      required: 'From Date is required',
                    }}
                  />
                  <ControlDatePickerGrpInLine
                    name={'dateTo'}
                    label={'To'}
                    labelWidth={40}
                    groupClass={'groupClass50'}
                    required={true}
                    control={control}
                    minDate={DateTime.fromJSDate(new Date(fromDate)).plus({ day: 1 }).toJSDate()}
                    rules={{
                      required: 'To Date is required',
                    }}
                  />
                </div>
              </div>
              <div className="flex-grow-1 text-right">
                <button
                  type="button"
                  className="orange-outline-button mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="orange-button mr-2"
                  onClick={downloadReport}
                  disabled={(!fromDate || !toDate || !selectedSettingId || runningUnionDues === 'pending')}
                >
                  {runningUnionDues === 'pending' ? (
                    <span>Running&nbsp;<Spinner
                      animation={'border'}
                      size="sm"
                    /></span>
                  ) : 'Run Report'}
                </button>

              </div>
            </div>
            <div
              className="flex-grow-1"
              style={{ marginTop: 20 }}
            >
              <div className="d-flex flex-row">
                <div className="dm-card-subtitle2 mb-2">Choose Setting Name</div>
                <div className=" flex-grow-1 text-right">
                  <button
                    className="btn btn-link dm-grid-action-title p-0 mr-2"
                    type="button"
                    onClick={() => { setSelectedSetting(undefined); setShowModal(true); }}
                  >
                    Add New Setting	<Icon
                      name="plus-circle"
                      className="fa-plus-circle"
                    />
                  </button>
                </div>
              </div>
              <div className="ag-theme-balham mt-2">
                <AgGridReact
                  gridOptions={gridOptions}
                  rowData={unionDueSettings}
                  onGridReady={onGridReady}
                  onSelectionChanged={onSelectionChanged}
                />
              </div>
            </div>
            {showModal ?
              <UnionDuesSettingsModal
                setting={selectedSetting}
                show={showModal}
                onHide={() => { return setShowModal(false); }}
              /> : null}

            {report ? <PDFViewerModal
              show={report.length > 0}
              pdfData={report}
              onHide={hidePdfViewer}
              reportName="UnionDuesReport"
            /> : null}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default UnionDuesReportPage;