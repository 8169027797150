import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CreateOnboardEmployee, JazzHRIntegrationRequest } from '../../core/models';
import {
  createOnboardEmployee,
  handleError,
  handleWarning,
  loadJazzHrIntegration,
  loadOnboardDocs,
  loadOnboardEmailTemplates,
  loadOnboardPageConfigs,
  setOnboardEmployeeStatus,
} from '../../core/store/actions';
import {
  getOnboardDocs,
  getOnboardEmailTemplates,
  getOnboardPageConfigs,
  getOnboardStatus,
  getReportOptions,
  getSelectedEmp,
} from '../../core/store/selectors';
import OnboardConfirmModal from './OnboardConfirm.modal';
import {
  PageConfigContextObj,
  PageConfigsContext,
  PageConfigsContextType,
} from './pageConfigContext';
import OnboardDocuments from './OnboardDocuments';
import OnboardEmailsModal from './OnboardEmails.modal';
import OnboardEmailTemplateModal from './OnboardEmailTemplate.modal';
import OnboardEmpInfo from './OnboardEmpInfo';
import OnboardFieldConfig from './OnboardFieldConfig';
import OnboardSelectEmpNoModal from './OnboardSelectEmpNo.modal';
import OnboardSuccessModal from './OnboardSuccess.modal';
import OnboardErrorModal from './OnboardError.modal';
import { useAppSelector } from '../../utilities/hooks';
import Icon from 'core/components/shared/Icon';
import OnboardJazzHRIntegrationModal from './OnboardJazzHRIntegration.modal';
import { selectClientAgencyDropDown } from 'core/store/selectors/tax.selector';
import OnboardInvalidStateModal from './OnboardInvalidState.modal';
import { loadCmTaxEntities } from 'core/store/actions/tax.action';
import { useHistory } from 'react-router-dom';
import OnboardGoToEmployeeModal from './OnboardGoToEmployee.modal';

const OnboardPage = () => {
  const [pageConfigContext, setPageConfigContext] =
    useState<PageConfigsContextType>(PageConfigContextObj);

  const dispatch = useDispatch();
  const clientNo = useAppSelector(({ client }) => client.client!.clientNo);
  const activeApplicant = useAppSelector(({ applicantTrackingV2 }) => applicantTrackingV2?.active);

  const { setValue, control, register, handleSubmit, errors, watch } =
    useForm<CreateOnboardEmployee>({
      defaultValues: new CreateOnboardEmployee(clientNo, 0),
    });
  const [onboardEmp, setOnboardEmp] = useState<CreateOnboardEmployee | null>(null);

  const [showSelectEmpNoModal, setShowSelectEmpNoModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showGoToEmployeeModal, setShowGoToEmployeeModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showWHStateConfirmModal, setShowWHStateConfirmModal] = useState(false);
  const [showEmailsModal, setShowEmailsModal] = useState(false);
  const [showInvalidStateModal, setShowInvalidStateModal] = useState(false);
  const [showEmailTemplateModal, setShowEmailTemplateModal] = useState(false);
  const [showErrorModal, setShowErrorModalModal] = useState(false);
  const [showJazzHRModal, setShowJazzHRModal] = useState(false);
  const [withholdingState, setWithholdingState] = useState<{ code: string, description: string }>({ code: '', description: '' });
  const [initalLoad, setInitalLoad] = useState(true);

  const [selectedJazzApplicant, setSelectedJazzApplicant] = useState<JazzHRIntegrationRequest | null>(null);
  const jazzHROption = useSelector(getReportOptions('JazzHr'));
  const clientStateEntities = useAppSelector((state) => {return selectClientAgencyDropDown(state, 'State'!, new Date().getFullYear()!);});

  const emailTemplates = useSelector(getOnboardEmailTemplates);
  const onboardDocs = useSelector(getOnboardDocs);
  const pageConfigs = useSelector(getOnboardPageConfigs);
  const onboardStatus = useSelector(getOnboardStatus);
  const selectedEmp = useSelector(getSelectedEmp);
  const history = useHistory();
  
  useEffect(() => {
    dispatch(setOnboardEmployeeStatus('starting'));
    dispatch(loadOnboardEmailTemplates());
    dispatch(loadOnboardDocs());
    dispatch(loadOnboardPageConfigs());
    dispatch(loadCmTaxEntities());
    setInitalLoad(true);
    if ((jazzHROption?.showReport || false)) dispatch(loadJazzHrIntegration());
  }, []);

  useEffect(() => {
    setPageConfigContext((prev: PageConfigsContextType) => {
      return {
        documentIds: [],
        pageConfigs,
        updateDocumentIds: prev.updateDocumentIds,
        setPageConfigs: prev.setPageConfigs,
        updatePageConfig: prev.updatePageConfig,
        updatePageConfigRequired: prev.updatePageConfigRequired,
        setPageConfigContext,
      };
    });
  }, [pageConfigs]);

  useEffect(() => {
    if (onboardStatus) {
      if (initalLoad) setInitalLoad(false);
      else if (onboardStatus === 'success')setShowSuccessModal(true);
      else if (onboardStatus.error)setShowErrorModalModal(true);
    }
  }, [onboardStatus]);

  const onSubmit = (data: any) => {
    const onBoardEmployee = new CreateOnboardEmployee(clientNo, 0, { ...data, selectedOnboardDocIDs: pageConfigContext.documentIds });
    setOnboardEmp(onBoardEmployee);
    const validState = clientStateEntities.some(x => onBoardEmployee.state === x.code);
    if (!validState) setShowInvalidStateModal(true);
    else setShowConfirmModal(true);
  };

  const onContinueInvalidStateModal = () => {
    if (!withholdingState) return dispatch(handleWarning('Please select a new state before clicking continue.'));
    setShowInvalidStateModal(false);
    const onBoardEmployeeCopy = structuredClone({ ...onboardEmp, withHoldingState: withholdingState.code }) as CreateOnboardEmployee;
    
    //Set the onboard employee and state value to reflect any changes on the Emp Info Section
    setOnboardEmp(onBoardEmployeeCopy);
    
    const validState = clientStateEntities.some(x => onBoardEmployeeCopy.withHoldingState === x.code);
    if (!validState) dispatch(handleError('Error while selecting new state.'));
    else setShowWHStateConfirmModal(true);
  };

  const onContinueWHStateConfirmModal = () => {
    setShowWHStateConfirmModal(false);
    setShowConfirmModal(true);
  };

  const onContinueShowConfirmModal = () => {
    setShowConfirmModal(false);
    setShowSelectEmpNoModal(true);
  };

  const onContinueSelectEmpNoModal = (empNo: number) => {
    setShowSelectEmpNoModal(false);
    if (onboardEmp) {
      onboardEmp.empNo = empNo;
      dispatch(
        createOnboardEmployee(
          CreateOnboardEmployee.toObject(onboardEmp),
        ),
      );
      // TODO need to conditionally show the following if create is successful, otherwise show error.
      // setShowSuccessModal(true);
    }
  };

  const onContinueShowSuccessModal = () => {
    setShowSuccessModal(false);
    setShowEmailsModal(true);
  };

  const onHideSuccessModal = () => {
    setShowSuccessModal(false);
    setShowGoToEmployeeModal(true);
  };

  const onContinueGoToEmployeeModal = () => {
    setShowGoToEmployeeModal(false);
    if (!selectedEmp) return dispatch(handleError('There was an issue going to new employee.'));
    history.push(`/employee/detail/${selectedEmp?.protectedEmpNo}/snapshot`);
  };

  const onCancel = () => {
    dispatch(loadOnboardPageConfigs());
  };

  return (
    <div className="col">
      <PageConfigsContext.Provider value={pageConfigContext}>
        <div className="row">
          <div className="col-12">
            <div className="dm-page-title">
              Quick Onboard Employee
            </div>
          </div>
          <div className="col-6">
            <div className="dm-card-subtitle">
              Send an email invitation to onboard the employee into our system
              via the MyInfo website.
            </div>
          </div>
          <div className="col align-items-end d-flex justify-content-end">
            {(jazzHROption?.showReport || false) ? <button
              type="button"
              onClick={() => {return setShowJazzHRModal(true);}}
              className="btn btn-link dm-grid-action-title p-0 mr-3"
            >
              Import from Jazz HR&nbsp;
              <Icon name="clock" />
            </button> : null}
            <button
              type="button"
              onClick={() => { return setShowEmailTemplateModal(true); }}
              className="btn btn-link dm-grid-action-title p-0 mr-3"
            >
              Customize Credential Email Template&nbsp;
              <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          </div>
        </div>

        <hr className="dm-page-hr" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col
              xs={12}
              md={4}
            >
              <OnboardEmpInfo
                setValue={setValue}
                control={control}
                register={register}
                errors={errors}
                watch={watch}
                emailTemplates={emailTemplates}
                selectedJazzApplicant={selectedJazzApplicant}
              />
              <div className="col-12 text-right pr-0">
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary orange-button ml-2"
                >
                  Send Onboarding Credentials
                </button>
              </div>
            </Col>
            {(!!onboardDocs?.length && !activeApplicant?.infoSent) ? <Col
              xs={12}
              md={4}
            >
              <OnboardDocuments onboardDocs={onboardDocs} />
            </Col> : null}

            <Col
              xs={12}
              md={4}
            >
              {pageConfigContext?.pageConfigs && (
                <OnboardFieldConfig />
              )}
            </Col>
          </Row>
        </form>
        {showInvalidStateModal && (
          <OnboardInvalidStateModal
            show={showInvalidStateModal}
            onContinue={onContinueInvalidStateModal}
            onHide={setShowInvalidStateModal}
            clientStateEntities={clientStateEntities}
            setWithholdingState={setWithholdingState}
          />
        )}
        {showConfirmModal && (
          <OnboardConfirmModal
            show={showConfirmModal}
            onContinue={onContinueShowConfirmModal}
            onHide={setShowConfirmModal}
            confirmMessage={'This will create an employee master record and email the applicant their onboarding information.'}
          />
        )}
        {showWHStateConfirmModal && (
          <OnboardConfirmModal
            show={showWHStateConfirmModal}
            onContinue={onContinueWHStateConfirmModal}
            onHide={setShowWHStateConfirmModal}
            confirmMessage={`Continue using ${withholdingState.description} as this employee's withholding state?`}
          />
        )}
        {showSelectEmpNoModal && (
          <OnboardSelectEmpNoModal
            show={showSelectEmpNoModal}
            onContinue={onContinueSelectEmpNoModal}
            onHide={setShowSelectEmpNoModal}
          />
        )}
        {showSuccessModal && (
          <OnboardSuccessModal
            show={showSuccessModal}
            onContinue={onContinueShowSuccessModal}
            onHide={onHideSuccessModal}
          />
        )}
        {showGoToEmployeeModal && (
          <OnboardGoToEmployeeModal
            show={showGoToEmployeeModal}
            onContinue={onContinueGoToEmployeeModal}
            onHide={setShowGoToEmployeeModal}
          />
        )}
        {showEmailsModal && (
          <OnboardEmailsModal
            show={showEmailsModal}
            onHide={() => { return setShowEmailsModal(false); }}
          />
        )}
        {showEmailTemplateModal && (
          <OnboardEmailTemplateModal
            show={showEmailTemplateModal}
            onHide={() => { return setShowEmailTemplateModal(false); }}
          />
        )}
        {showErrorModal && onboardStatus.error && (
          <OnboardErrorModal
            errorMsg={onboardStatus.error}
            show={showErrorModal}
            onHide={() => { return setShowErrorModalModal(false); }}
          />
        )}
        {showJazzHRModal && (
          <OnboardJazzHRIntegrationModal
            show={showJazzHRModal}
            clientNo={clientNo}
            setSelectedJazzApplicant={setSelectedJazzApplicant}
            onHide={() => { return setShowJazzHRModal(false); }}
          />
        )}
      </PageConfigsContext.Provider>
    </div>
  );
};

export default OnboardPage;
