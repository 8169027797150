import { RadioOptions } from '../core/components/form-controls/RadioGrp';
import { PayrollAdjustmentType } from '../core/models/Payroll.model';

export const radioOptions: RadioOptions[] = [
  {
    value: PayrollAdjustmentType.VoidCash,
    label: 'Cash - Void Or Prepay transaction that will reduce cash requirement',
  },
  {
    value: PayrollAdjustmentType.VoidCorrection,
    label: 'Correction - This will correct any other data with no effect on cash',
  },
];
