import { AxiosResponse } from 'axios';
import {
  EmpDate,
  EmpDocument,
  Employee,
  EmployeeToTransfer,
  HttpResponse,
  MultiplePayStub,
  PayStub,
  Rehire,
  Supervisor,
  TaxDocument,
  Transfers,
  ValidateEmployeeNumber,
} from '../models';
import { AddPasswordToPDF } from '../models/AddPasswordToPdf.model';
import { RealignSupervisedEmployee, SupervisedEmployee } from 'core/models/SupervisedEmployee.model';
import { API_CLIENT, API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { ClientMasterEndPoints, EmployeeEndPoints } from './URL';
import { EmFieldValidation as E } from 'types';
import { FileResponse } from 'core/models/File.model';

const getEmployees = (): Promise<AxiosResponse<Employee[]>> => {
  return API_EMPLOYEE_MASTER.get<Employee[]>(
    EmployeeEndPoints.GET_EMPLOYEES(CommonService.getProtectedClientNo()),
  );
};

const getEmployee = (empNo: string): Promise<AxiosResponse<Employee>> => {
  return API_EMPLOYEE_MASTER.get<Employee>(
    EmployeeEndPoints.GET_EMPLOYEE(
      CommonService.getProtectedClientNo(),
      empNo,
    ),
  );
};

const validateEmployee = (empNo: string, mandatoryControlIds: number[]): Promise<AxiosResponse<HttpResponse<E.EmSectionErrors>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<E.EmSectionErrors>>(
    EmployeeEndPoints.VALIDATE_EMPLOYEE(
      CommonService.getProtectedClientNo(),
      empNo,
    ),
    mandatoryControlIds,
  );
};

const postEmployee = (
  emp: Employee,
): Promise<AxiosResponse<HttpResponse<Employee>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<Employee>>(
    EmployeeEndPoints.POST_EMPLOYEE(CommonService.getProtectedClientNo()),
    emp,
  );
};

const putEmployee = (
  emp: Employee,
): Promise<AxiosResponse<HttpResponse<Employee>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<Employee>>(
    EmployeeEndPoints.PUT_EMPLOYEE(
      CommonService.getProtectedClientNo(),
      emp.protectedEmpNo,
    ),
    emp,
  );
};

const putEmployeePhoto = (
  protectedEmpNo: string,
  photoData: Uint8Array,
): Promise<AxiosResponse<HttpResponse<Employee>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<Employee>>(
    EmployeeEndPoints.PUT_EMPLOYEE_PHOTO(
      CommonService.getProtectedClientNo(),
      protectedEmpNo,
    ),
    '"' + photoData + '"',
    { headers: { 'Content-Type': 'application/vnd.restful+json' } },
  );
};

const putEmployeeStatus = (
  protectedEmpNo: string,
): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<any>>(
    EmployeeEndPoints.PUT_EMPLOYEE_STATUS(
      CommonService.getProtectedClientNo(),
      protectedEmpNo,
    ),
  );
};

const getValidateEmployeeNo = (
  data: ValidateEmployeeNumber,
): Promise<ValidateEmployeeNumber> => {
  return API_EMPLOYEE_MASTER.post(
    EmployeeEndPoints.POST_VALIDATE_EMPLOYEE_NO(
      CommonService.getProtectedClientNo(),
    ),
    data,
  );
};

const getEmployeeTransfer = (): Promise<AxiosResponse<Transfers>> => {
  return API_EMPLOYEE_MASTER.get(
    EmployeeEndPoints.GET_EMPLOYEE_TRANSFER(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const putEmployeeTransferVerify = (
  empTransferObj: EmployeeToTransfer,
): Promise<AxiosResponse<any>> => {
  return API_EMPLOYEE_MASTER.put(
    EmployeeEndPoints.PUT_EMPLOYEE_TRANSFER_VERIFY(
      CommonService.getProtectedClientNo(),
    ),
    empTransferObj,
  );
};

const putEmployeeTransfer = (
  empTransferObj: EmployeeToTransfer,
): Promise<AxiosResponse<any>> => {
  return API_EMPLOYEE_MASTER.put(
    EmployeeEndPoints.PUT_EMPLOYEE_TRANSFER(
      CommonService.getProtectedClientNo(),
    ),
    empTransferObj,
  );
};

const getPayStubs = (
  protectedEmpNo: string,
): Promise<AxiosResponse<PayStub[]>> => {
  return API_EMPLOYEE_MASTER.get(
    EmployeeEndPoints.GET_PAY_STUBS(
      CommonService.getProtectedClientNo(),
      protectedEmpNo,
    ),
  );
};

const getPayStubPDF = (ps: PayStub): Promise<AxiosResponse<any>> => {
  const ctrlTotalBy = '';
  return API_EMPLOYEE_MASTER.get(
    EmployeeEndPoints.GET_PAY_STUB_PDF(
      CommonService.getProtectedClientNo(),
      ps.protectedEmpNo,
      ps.mescID.toString(),
      ps.checkDate,
      ps.weekEnd,
      ps.checkType,
      ps.netPay.toString(),
      ctrlTotalBy,
    ),
  );
};

const postMultiplePayStubs = (ps: MultiplePayStub): Promise<AxiosResponse<HttpResponse<FileResponse[]>>> => {
  return API_EMPLOYEE_MASTER.post(
    EmployeeEndPoints.POST_PAY_STUBS_PDF_MULTIPLE(
      CommonService.getProtectedClientNo(),
      ps.protectedEmpNo,
    ),
    ps
  );
};

const getTaxDocuments = (
  protectedEmpNo: string,
): Promise<AxiosResponse<TaxDocument>> => {
  return API_EMPLOYEE_MASTER.get(
    EmployeeEndPoints.GET_TAX_DOCS(
      CommonService.getProtectedClientNo(),
      protectedEmpNo,
    ),
  );
};

const getEmpDocuments = (protectedEmpNo: string): Promise<AxiosResponse<EmpDocument>> => {
  return API_EMPLOYEE_MASTER.get(
    EmployeeEndPoints.GET_EMP_DOCS(
      CommonService.getProtectedClientNo(),
      protectedEmpNo,
    ),
  );
};

// Retrieve PDF's for emp tax and emp documents.
const getDocumentPDF = (
  filePath: string,
): Promise<AxiosResponse<any>> => // return API_EMPLOYEE_MASTER.get(EmployeeEndPoints.GET_EMP_DOCS_PDF(encodeURIComponent(filePath)));
{ return API_EMPLOYEE_MASTER.get(EmployeeEndPoints.GET_EMP_DOCS_PDF(filePath)); };

const putAddPasswordToPDF = (
  req: AddPasswordToPDF,
): Promise<AxiosResponse<string>> => { return API_CLIENT.put<string>(ClientMasterEndPoints.PUT_ADD_PASSWORD_TO_PDF, req); };

const getSupervisors = (): Promise<AxiosResponse<Supervisor[]>> => {
  return API_EMPLOYEE_MASTER.get<Supervisor[]>(
    EmployeeEndPoints.GET_SUPERVISORS(CommonService.getProtectedClientNo()),
  );
};

const getSupervisedEmployees = (
  empNo: string,
): Promise<AxiosResponse<SupervisedEmployee[]>> => {
  return API_EMPLOYEE_MASTER.get<SupervisedEmployee[]>(
    EmployeeEndPoints.GET_SUPERVISED_EMPLOYEE(
      CommonService.getProtectedClientNo(),
      empNo,
    ),
  );
};

const putSupervisedEmployees = (
  realignSupervisedEmployee: RealignSupervisedEmployee,
): Promise<AxiosResponse<HttpResponse<RealignSupervisedEmployee>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<RealignSupervisedEmployee>>(
    EmployeeEndPoints.PUT_SUPERVISED_EMPLOYEE(
      CommonService.getProtectedClientNo(),
    ),
    realignSupervisedEmployee,
  );
};

const getEmpDate = (protectedEmpNo: string): Promise<AxiosResponse<EmpDate>> => {
  return API_EMPLOYEE_MASTER.get<EmpDate>(
    EmployeeEndPoints.GET_EMP_DATE(
      CommonService.getProtectedClientNo(),
      protectedEmpNo,
    ),
  );
};

const putEmpDate = (
  data: EmpDate,
): Promise<AxiosResponse<HttpResponse<EmpDate>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<EmpDate>>(
    EmployeeEndPoints.PUT_EMP_DATE(
      CommonService.getProtectedClientNo(),
      data.protectedEmpNo,
    ),
    data,
  );
};

const postEmpRehireDates = (
  protectedEmpNo: string,
  data: Rehire,
): Promise<AxiosResponse<HttpResponse<Rehire>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<Rehire>>(
    EmployeeEndPoints.POST_EMP_REHIRE_DATE(
      CommonService.getProtectedClientNo(),
      protectedEmpNo,
    ),
    data,
  );
};

const deleteEmployee = (
  protectedEmpNo: string,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    EmployeeEndPoints.DELETE_EMPLOYEE(
      CommonService.getProtectedClientNo(),
      protectedEmpNo,
    ),
  );
};

export const EmployeeService = {
  getEmployees,
  getEmployee,
  validateEmployee,
  postEmployee,
  putEmployee,
  putEmployeePhoto,
  getValidateEmployeeNo,
  getEmployeeTransfer,
  putEmployeeTransferVerify,
  putEmployeeTransfer,
  getPayStubs,
  getPayStubPDF,
  getTaxDocuments,
  getEmpDocuments,
  getDocumentPDF,
  putAddPasswordToPDF,
  getSupervisors,
  getSupervisedEmployees,
  putSupervisedEmployees,
  getEmpDate,
  putEmpDate,
  postEmpRehireDates,
  deleteEmployee,
  putEmployeeStatus,
  postMultiplePayStubs,
};
