import React, { CSSProperties } from 'react';
import '../time-card-styles.scss';
import { InputGrpCurrency } from 'core/components/form-controls';
import { TimeCardJobRosterDeductions } from 'core/models/Contractor.model';

const infoGrpStyle: CSSProperties = {
  marginBottom: '0',
  width: '125px',
  minWidth: '125px',
  marginLeft: '5%',
};

type Props = {
  deduction: TimeCardJobRosterDeductions;
  showLabel?: boolean;
};

const JobRosterDeductionItem = ({ deduction }: Props) => {
  const dedNoWithDesc = (deduction.dedNo === 0) ? '' : `${deduction.dedNo} - ${deduction.dedDescription}`;

  return (
    <div className="earning-item">
      <div className="d-flex flex-column" style={{  marginLeft: '20px', width: '20%' }}>
        <strong className="input-label">{dedNoWithDesc}</strong>
      </div>
      <div style={{ marginLeft: '20px', width: 'fit-content' }}>
        <div className="d-flex align-items-center">
            <>
              {deduction.dedNo !== 0 ? <InputGrpCurrency
                groupClass="number-underline"
                groupStyle={infoGrpStyle}
                defaultValue={deduction.dedAmount}
                style={{ height: '30px', marginLeft: '30px' }}
                disabled={true}
              />: null}
                <span
                  className="mx-2 font-weight-bold"
                  style={{ whiteSpace: 'nowrap' }}
                >{deduction.dedType}</span>
            </>
        </div>
      </div>
    </div>
  );
};

export default JobRosterDeductionItem;