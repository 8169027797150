import { createAction } from '@reduxjs/toolkit';
import { ChangeMyInfoAccountEmail, EmpMyInfoChanges, EmpSelfService } from 'core/models';
import { DirectDepositChangeLog, EmployeeChangeLog, MyInfoChangeLog, MyInfoChangeSelection, TaxChangeLog } from 'core/models/MyInfoChangeLog';

export const SELFSERVICE_EM_VALIDATION_ACTIONS = {
  UPDATE_EMPLOYEE_SELF_SERVICE: '[emp-self-service] UPDATE_EMPLOYEE_SELF_SERVICE',
  UNLOCK_MY_INFO_ACCOUNT: '[emp-self-service] UNLOCK_MY_INFO_ACCOUNT',
  RESET_MY_INFO_ACCOUNT_PW: '[emp-self-service]  RESET_MY_INFO_ACCOUNT_PW',
  CHANGE_MY_INFO_ACCOUNT_EMAIL: '[emp-self-service]  CHANGE_MY_INFO_ACCOUNT_EMAIL',
  DELETE_MY_INFO_ACCOUNT: '[emp-self-service]  DELETE_MY_INFO_ACCOUNT',
  UPDATE_MYINFO_CHANGES: '[emp-self-service] UPDATE_MYINFO_CHANGES',
} as const;

export const loadEmployeeSelfService = createAction<{ protectedEmpNo: string }>('[emp-self-service] LOAD_EMPLOYEE_SELF_SERVICE');
export const storeEmployeeSelfService = createAction<EmpSelfService>('[emp-self-service] STORE_EMPLOYEE_SELF_SERVICE');
export const updateEmployeeSelfService = createAction<EmpSelfService>(SELFSERVICE_EM_VALIDATION_ACTIONS.UPDATE_EMPLOYEE_SELF_SERVICE);

export const unlockMyInfoAccount = createAction<{ protectedEmpNo: string }>(SELFSERVICE_EM_VALIDATION_ACTIONS.UNLOCK_MY_INFO_ACCOUNT);
export const resetMyInfoAccountPW = createAction<{ protectedEmpNo: string }>(SELFSERVICE_EM_VALIDATION_ACTIONS.RESET_MY_INFO_ACCOUNT_PW);
export const changeMyInfoAccountEmail = createAction<ChangeMyInfoAccountEmail>(SELFSERVICE_EM_VALIDATION_ACTIONS.CHANGE_MY_INFO_ACCOUNT_EMAIL);
export const deleteMyInfoAccount = createAction<{ protectedEmpNo: string }>(SELFSERVICE_EM_VALIDATION_ACTIONS.DELETE_MY_INFO_ACCOUNT);
export const storeMyInfoAccountMessage = createAction<string | undefined>('[emp-self-service] STORE_MY_INFO_ACCOUNT_MESSAGE');

export const loadMyInfoChanges = createAction<{ protectedEmpNo: string }>('[emp-self-service] LOAD_MY_INFO_CHANGES');
export const storeMyInfoChanges = createAction<MyInfoChangeLog>('[emp-self-service] STORE_MY_INFO_CHANGES');
export const loadMyInfoChangesHistory = createAction<{ protectedEmpNo: string }>('[emp-self-service] LOAD_MY_INFO_CHANGES_HISTORY');
export const storeMyInfoChangesHistory = createAction<EmpMyInfoChanges>('[emp-self-service] STORE_MY_INFO_CHANGES_HISTORY');

export const storeSelectedEmployeeChanges = createAction<EmployeeChangeLog[]>('[emp-self-service] STORE_SELECTED_EMPLOYEE_CHANGES');
export const storeSelectedTaxChanges = createAction<TaxChangeLog[]>('[emp-self-service] STORE_SELECTED_TAX_CHANGES');
export const storeSelectedDirectDepositChange = createAction<DirectDepositChangeLog[]>('[emp-self-service] STORE_SELECTED_DIRECT_DEPOSIT_CHANGES');
export const clearSelectedChanges = createAction<MyInfoChangeSelection>('[emp-self-service] CLEAR_SELECTED_CHANGES');
export const updateMyInfoChanges = createAction<MyInfoChangeLog>(SELFSERVICE_EM_VALIDATION_ACTIONS.UPDATE_MYINFO_CHANGES);

export const interruptSelfService = createAction('[emp-self-service] CATCH_ERROR'); // TODO: Find a way to handle this in the handleError stream for all actions.