import { orderBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { ArrayField, Control, DeepMap, FieldError, FieldValues, useFieldArray } from 'react-hook-form';
import {
  CheckCalculateRequest,
  CheckCalculator,
  EmployeeMasterDtoTaxTaxEntity,
  EmployeeTaxInfo,
} from 'core/models';
import TaxItem from './TaxItem';
import { FieldArray } from './TaxPage';
import Icon from 'core/components/shared/Icon';
import { FormMethods } from 'core/components/form-controls/types';
import { useAppSelector } from 'utilities/hooks';
import { getAccess } from 'utilities/utilities';

type Props = {
  taxes?: EmployeeTaxInfo | null;
  hasSUI: boolean;
  control:
  | Control<FieldArray>
  | Control<CheckCalculator>
  | Control<CheckCalculateRequest>;
  errors: FormMethods['errors'];
  formStateErrors?: any;
  setShowErrors?: (showError: boolean) => void;
  register: FormMethods['register'];
  setValue: FormMethods['setValue'];
  getValues: FormMethods['getValues'];
  setZeroSUI?: React.Dispatch<React.SetStateAction<boolean>>;
  setZeroWH?: React.Dispatch<React.SetStateAction<boolean>>;
  updateNestedErrors?: (title: string, key?: string, propertyChain?: string) => void;
};

type ErrorStateFunction = (value: React.SetStateAction<boolean>) => void;
type MultipleEntitiesCallback = (...args: any[]) => void;

const TaxComponent = ({
  taxes,
  formStateErrors,
  hasSUI,
  control,
  register,
  setValue,
  errors,
  getValues,
  setZeroSUI,
  setZeroWH,
  updateNestedErrors,
}: Props) => {
  const [openState, setOpenState] = useState<{ [key: string]: boolean }>({
    federal: true,
    state: false,
    city: false,
  });
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  
  useEffect(() => {
    let errorLoop: any = [];
    if (errors.taxes) {
      errorLoop = [...errors.taxes];
    }
    if (errors.employee && errors.employee.taxes) {
      errorLoop = [...errors.employee.taxes];
    }
    if (errorLoop.length > 0) {
      errorLoop.forEach((_: any, index: number) => {
        switch (fields[index].agency?.toLowerCase()) {
          case 'federal':
            setOpenState({
              ...openState,
              federal: true,
            });
            break;
          case 'state':
            setOpenState({
              ...openState,
              state: true,
            });
            break;
          case 'city':
            setOpenState({
              ...openState,
              city: true,
            });
            break;
        }
      });
      
    }
    if (updateNestedErrors) {
      updateNestedErrors('taxes', undefined, 'employee.taxes');
    }
  }, [errors]);

  const nameControl =
    (control.defaultValuesRef.current as FieldArray).taxes !== undefined
      ? 'taxes'
      : 'employee.taxes';
  const { fields, remove, prepend } =
    useFieldArray<EmployeeMasterDtoTaxTaxEntity>({
      control,
      name: nameControl,
    });

  const appendTax: EmployeeMasterDtoTaxTaxEntity = {
    employeeTaxEntitiesId: 0,
    agency: '',
    year: new Date().getFullYear(),
    entityId: 0,
    fan: '',
    fanAmount: 0,
    filingStatus: '',
    residency: '',
    workZipCode: '',
    irsLockOut: false,
    rate: 0,
    exemptDependants: 0,
    creditDependants: 0,
    extraWH: 0,
    deductions: 0,
    otherIncome: 0,
    deductionCredit: 0,
    exemptions: 0,
    exemptSpouse: false,
    suiState: false,
    isWHState: false,
    exemptionAmount: 0,
    nY_MCTMT: false,
    exempT_FAMILY: false,
    exempT_MEDICAL: false,
    multipleJobs: false,
    firstTimeAdditionalExemption: 0,
    adoptedQualifyingDependent: 0,
    isClergy: false,
    wcfExempt: false,
  };

  const toggleInfo = (type: string) => {
    setOpenState({
      ...openState,
      [type]: !openState[type],
    });
  };

  let currentEntityId = 0;
  let previousEntityId = 0;
  
  function validateSelection<T>(
    key: keyof T, entities: T[], setError: ErrorStateFunction, multipleEntitiesCallback: MultipleEntitiesCallback,
  ) {
    const stateEntities = entities.filter((field) => { return field['agency' as keyof T] === 'State'; });
    
    if (stateEntities.length > 1) {
      multipleEntitiesCallback();
    } else {
      if (!stateEntities[0][key]) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }
  
  /*
    Update all state entities when SUI changes. See suiDisabled for more information.   
 */
  const updateSUI = (index: number, entityId: number | undefined) => {
    const updatedFields = fields.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          suiState: !item.suiState,
        };
      } else if (item.entityId !== entityId || (item.year ?? 0) > new Date().getFullYear()) {
        return {
          ...item,
          suiState: false,
        };
      } else {
        return { ...item };
      }
    });
      
    setValue(nameControl, updatedFields);
  
    if (updatedFields.filter((field) => { return field.suiState; }).length < 1) {
      setZeroSUI && setZeroSUI(true);
    } else {
      setZeroSUI && setZeroSUI(false);
    }
  };

  

  // Update any field with the new value and run additional checks for SUI and IRS lockout.
  function updateField<T>(id: string | undefined, index: number, key: string, newValue: T) {
    if (!id) throw new Error('No id provided');
    
    // create copy and init clone of entity being updated with value, then set new value for key
    const currentFields = [...fields];
    let itemToUpdate = currentFields.find((field) => { return field.id === id; });
    itemToUpdate = { ...itemToUpdate,
      [key]: newValue,
    };
    /*
      This is here because of a closure bug in the event handler that loses the target value. Will move this once I
      come up with a solution passing in an array of keys. If I don't come back to update this, throw a heavy object
      at me
    */
    if (key === 'fan' && (newValue === '' || newValue === 'N')) {
      itemToUpdate.fanAmount = 0;
    }
    
    // splice into fields clone at provided index, set field array to updated clone
    currentFields.splice(index, 1, itemToUpdate);
    setValue(nameControl, currentFields);

    // if updating SUI value and multiple states, run function to 'uncheck' any non-eligible entities
    if (key === 'suiState' && setZeroSUI) {
      validateSelection(key, currentFields, setZeroSUI, () => { updateSUI(index, itemToUpdate?.entityId); });
    }
    if (key === 'isWHState' && setZeroWH) {
      const callback = () => {
        if (currentFields.filter((field) => {
          return field?.isWHState && (field?.year ?? 0) <= new Date().getFullYear();
        }).length < 1) {
          setZeroWH(true);
        } else {
          setZeroWH(false);
        }
      };
      
      validateSelection(key, currentFields, setZeroWH, callback);
    } 
  }

  /*
    Only a single entity of the current year or prior and at most one from the next year can be checked. Any entity for
    the next year without a matching entity from the current will not be eligible as a SUI state and cannot be checked.
    When checking withholding, this ignores the entityId match and just makes sure there's a withholding state from the
    current year before allowing new ones for the future.
 */
  const checkDisableInput = (
    year: number | undefined,
    key: keyof Partial<ArrayField<EmployeeMasterDtoTaxTaxEntity, 'id'>>,
    entityId?: number | undefined,
  ) => {
    const currentYear = new Date().getFullYear();
    const keyMatches = fields.filter((field) => {
      return (
        (field?.employeeTaxEntitiesId ?? 0) > 0
        && (field?.year ?? 0) <= currentYear
        && field.agency === 'State'
        && (entityId ? field.entityId === entityId : true)
        && field[key]
      );
    });
    
    if (keyMatches.length < 1 && (year ?? 0) > currentYear) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col mr-auto">
            <div
              role="button"
              className="dm-grid-title mb-2"
              onClick={() => { return toggleInfo('federal'); }}
            >
              <Icon
                name={`chevron-${openState.federal ? 'down' : 'right'}`}
                className="mr-2"
              />
              Federal
            </div>
          </div>
          <div className="col-auto d-flex justify-content-end">
            <button
              {...getAccess(sectionAccess, 4, undefined, { disabledSameAsReadOnly: true, disabledDefault:  fields.findIndex(
                (a) => {
                  return a.entityId === 1 &&
                    a.year === new Date().getFullYear();
                },
              ) !== -1 })}
              type="button"
              className="btn btn-link dm-grid-action-title p-0"
              onClick={() => {
                prepend({
                  ...appendTax,
                  agency: 'Federal',
                  entityId: 1,
                });
                setOpenState({
                  ...openState,
                  federal: true,
                });
              }}
            >
              Add New Federal Tax{' '}
              <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          </div>
        </div>
        <div className={openState.federal ? 'animate' : 'animate-reverse'}>
          {fields.map((taxItem, index) => {
            if (taxItem.agency !== 'Federal') return null;
            currentEntityId = taxItem.entityId ?? 0;
            return (
              <div
                key={taxItem.id}
                className={`dm-panel dm-panel-border pb-1 ${previousEntityId === currentEntityId
                  ? 'd-none'
                  : ''
                }`}
              >
                <TaxItem
                  updateField={updateField}
                  hasSUI={hasSUI}
                  control={control}
                  taxItem={taxItem}
                  key={taxItem.id}
                  register={register}
                  errors={errors}
                  onDelete={() => { remove(index); }}
                  validate={
                    !(previousEntityId === currentEntityId)
                  }
                  name={nameControl}
                  index={index}
                  getValues={getValues}
                />
                {(() => {
                  previousEntityId = taxItem.entityId ?? 0;
                })()}
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col mr-auto">
            <div
              role="button"
              className="dm-grid-title mb-2"
              onClick={() => { return toggleInfo('state'); }}
            >
              <Icon
                name={openState.state ? 'chevron-down' : 'chevron-right'}
                className="mr-2"
              />
              State
            </div>
          </div>
          <div className="col-auto d-flex justify-content-end">
            <button
              {...getAccess(sectionAccess, 4, undefined, { disabledSameAsReadOnly: true })}
              type="button"
              className="btn btn-link dm-grid-action-title p-0"
              onClick={() => {
                prepend({
                  ...appendTax,
                  agency: 'State',
                });
                setOpenState({
                  ...openState,
                  state: true,
                });
              }}
            >
              Add New State Tax{' '}
              <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          </div>
        </div>
        <Collapse in={openState.state}>
          <div>
            {fields.map((taxItem, index) => {
              if (taxItem.agency !== 'State') return null;
              currentEntityId = taxItem.entityId ?? 0;
              return (
                <div
                  key={taxItem.id}
                  className={`dm-panel dm-panel-border pb-1 ${previousEntityId === currentEntityId
                    ? 'd-none'
                    : ''
                  }`}
                >
                  <TaxItem
                    updateField={updateField}
                    hasSUI={hasSUI}
                    control={control}
                    key={taxItem.id}
                    taxItem={taxItem}
                    register={register}
                    onDelete={() => {
                      if (taxItem.suiState && fields.filter((field) => {return field?.suiState;}).length <= 1) {
                        setZeroSUI && setZeroSUI(true); // needed on check calc? how would that be handled?
                      }
                      if (taxItem.isWHState && fields.filter((field) => {return field?.isWHState;}).length <= 1) {
                        setZeroWH && setZeroWH(true); // similar here
                      }
                      remove(index);
                    }}
                    validate={previousEntityId !== currentEntityId}
                    name={nameControl}
                    errors={errors}
                    index={index}
                    suiDisabled={() => { return checkDisableInput(taxItem.year, 'suiState', taxItem.entityId); }}
                    whDisabled={() => { return checkDisableInput(taxItem.year, 'isWHState', undefined); }}
                    getValues={getValues}
                  />
                  {(() => {
                    previousEntityId =
                      taxItem.entityId ?? 0;
                  })()}
                </div>
              );
            })}
          </div>
        </Collapse>
      </div>
      <div>
        <div className="row">
          <div className="col">
            <div className="dm-grid-title">
              <div
                role="button"
                className="dm-grid-title mb-2"
                onClick={() => { return toggleInfo('city'); }}
              >
                <Icon
                  name={openState.city ? 'chevron-down' : 'chevron-right'}
                  className="mr-2"
                />
                Local Taxes
              </div>
            </div>
          </div>
          <div className="col-auto d-flex justify-content-end">
            <button
              {...getAccess(sectionAccess, 4, undefined, { disabledSameAsReadOnly: true })}
              type="button"
              className="btn btn-link dm-grid-action-title p-0"
              onClick={() => {
                prepend({
                  ...appendTax,
                  agency: 'City',
                });
                setOpenState({
                  ...openState,
                  city: true,
                });
              }}
            >
              Add New Local Tax{' '}
              <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          </div>
        </div>
        <div className={openState.city ? 'animate' : 'animate-reverse'}>
          {fields.map((taxItem, index) => {
            if (taxItem.agency !== 'City' && taxItem.agency !== 'County' && taxItem.agency !== 'School') return null;
            
            currentEntityId = taxItem.entityId ?? 0;
            return (
              <div
                key={taxItem.id}
                className={`dm-panel dm-panel-border pb-1 ${previousEntityId === currentEntityId
                  ? 'd-none'
                  : ''
                }`}
              >
                <TaxItem
                  updateField={updateField}
                  control={control}
                  key={taxItem.id}
                  taxItem={taxItem}
                  register={register}
                  errors={errors}
                  index={index}
                  getValues={getValues}
                  onDelete={() => { remove(index); }}
                  validate={previousEntityId !== currentEntityId}
                  name={nameControl}
                />
                {(() => {
                  previousEntityId = taxItem.entityId ?? 0;
                })()}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TaxComponent;
