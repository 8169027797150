import { convToClass } from '../../utilities/classUtils';

export interface DropdownDeleteRequest {
  id: string;
  dropdownName: string;
}
export class Dropdown {
  clientNo?: string = '';

  id = '';

  description = '';

  allowChanges?: boolean = false;

  usesCode?: boolean = false;

  code?: string = '';

  wgLevel?: string = '';

  dropdownName?: string = '';
  
  usesActive?: boolean = false;
  
  active?: boolean = false;

  constructor(
    clientNo: string | number,
    id: string | number,
    props?: { [key: string]: any },
  ) {
    this.clientNo = typeof clientNo !== 'string' ? '' + clientNo : clientNo;
    this.id = typeof id !== 'string' ? '' + id : id;
    props && convToClass(this, props, {});
  }
}

export class DropdownRequest {
  clientNo = '';

  id = '';

  description = '';

  code = '';

  dropdownName?: string = '';
  
  active?: boolean = false;
  
  constructor(
    clientNo: string | number,
    id: string | number,
    description: string,
    code?: string,
    dropdownName?: string,
    active?: boolean,
  ) {
    this.clientNo = typeof clientNo !== 'string' ? '' + clientNo : clientNo;

    this.active = active;
    this.id = typeof id !== 'string' ? '' + id : id;
    this.description = description;
    this.code = code ?? '';
    this.dropdownName = dropdownName ?? '';
  }
}

export interface ClientMasterDtoCommonDropdown {
  id?: string;
  description?: string;
}

export interface PaygradeDropdown {
  clientNo: string;
  id: string;
  description: string;
  code: string;
  minAmount: number;
  midAmount: number;
  maxAmount: number;
}

export interface SchoolDropdown {
  schoolId: number;
  id?: number;
  dropdownName: string;
  clientId: number;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  schoolTypeId: number;
  schoolCodeId: number;
  schoolAlphaCode: string;
}

export interface ExitInterViewQuestionDropdown {
  exitInterViewQuestionId?: number;
  clientId: number;
  questionNumber: number;
  question: string;
}