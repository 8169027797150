import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import searchIcon from '../../../../assets/images/search.png';
import {
  InputGrp,
  SelectGrp,
} from 'core/components/form-controls';
import {
  Dropdown,
  Employee,
  PayrollAdjustmentEmployee,
  PayrollAdjustmentSummary,
  PayrollAdjustmentType,
} from 'core/models';
import {
  createAdjustmentType,
  createPayrollAdjustmentCheck,
  deleteBlankCheck,
  deletePayrollAdjustmentCheck,
  loadAdjustmentStateInfo,
  loadPayrollAdjustmentNewCheck,
  storeLatestPayroll,
} from 'core/store/actions';
import { getEmployees, getIsFinishedPayroll } from 'core/store/selectors';
import EmpPhoto from 'core/components/shared/EmpPhoto';
import { useHistory, useLocation } from 'react-router-dom';
import VoidSearchModal from 'core/components/modals/VoidSearch.modal';
import { formatSSN } from 'utilities/utilities';
import { getPayrollAdjustments } from 'core/store/selectors/payroll-adjustment.selector';
import { useAppSelector } from 'utilities/hooks';
import { cloneDeep } from 'lodash';
import Icon from 'core/components/shared/Icon';
import EmployeeSearch from './EmployeeSearch';
import { DatePickerGrp } from 'core/components/form-controls/DatePickerGrp';
import { adjustmentCodeOpts } from 'core/constants';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => { return new URLSearchParams(search); }, [search]);
};

const checkForNameMatch = (name: string, match: string) => {
  if (!match) return true;
  const l = match.length;
  const m = match.toLowerCase();
  const s = name.split(',');
  return (
    (s && s[0].substring(0, l).toLowerCase() === m) ||
    (s && s[1].substring(1, 2).toLowerCase() === m)
  );
};

const checkForEmpNoMatch = (empNo: number, match: string) => {
  const empNoString = empNo?.toString();
  const l = match.length;
  return empNoString && empNoString.substring(0, l).toLowerCase() === match;
};

const TimeCardAdjustmentsPage = () => {
  const dispatch = useDispatch();

  const searchParams = useQuery();

  const history = useHistory();

  const payrollAdjustments = useSelector(getPayrollAdjustments);
  const employees = useSelector(getEmployees);
  const newAdjustmentCheck = useAppSelector(({ payrollAdjustment }) => payrollAdjustment.adjustmentCheckDefaults);
  const isFinishedPayroll = useSelector(getIsFinishedPayroll);

  const [searchText, setSearchText] = useState('');
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showVoidSearchModal, setShowVoidSearchModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const [payrollHistoryId, setPayrollHistoryId] = useState<number>();
  const [addAdjustment, setAddAdjustment] = useState(false);
  const [selectedAdjustmentEmployee, setSelectedAdjustmentEmployee] = useState<PayrollAdjustmentEmployee>();
  const [voidType, setVoidType] = useState<string>('');
  const [showEmployeeSearch, setShowEmployeeSearch] = useState(false);
  
  useEffect(() => {
    // load
    return () => {
      // clear
    };
  }, []);

  useEffect(() => {
    if (!payrollHistoryId) return;
    dispatch(loadAdjustmentStateInfo(payrollHistoryId));
    dispatch(storeLatestPayroll(payrollHistoryId));
  }, [payrollHistoryId]);

  useEffect(() => {
    if (newAdjustmentCheck && addAdjustment) {
      setShowEmployeeSearch(false);
      setAddAdjustment(false);
      onAdd();
    }
  }, [newAdjustmentCheck, addAdjustment]);

  useEffect(() => {
    if (searchText) {
      const x = parseInt(searchText);
      const tEmp = payrollAdjustments?.filter(
        (e: PayrollAdjustmentEmployee) => {
          return isNaN(x)
            ? checkForNameMatch(e.name, searchText)
            : checkForEmpNoMatch(e.empNo, searchText);
        },
      );
      if (tEmp) {
        // reset, and then
        tEmp.length === 1 && setSelectedAdjustmentEmployee(tEmp[0]);
      }
    } else {
      // reset
    }
  }, [payrollAdjustments, searchText]);

  const setAdjustedEmployee = (empNo: number) => {
    const employeAdjustment = payrollAdjustments?.find(
      (a) => { return a.empNo === empNo; },
    ) as PayrollAdjustmentEmployee;
    setSelectedAdjustmentEmployee(employeAdjustment);
    setSelectedEmployee(employees?.find((a) => { return a.empNo === empNo; }));
  };

  const onAddEmployee = () => {
    setShowEmployeeSearch(true);
  };

  const onAdjustmentTypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    check: PayrollAdjustmentSummary,
  ) => {
    if (e.target.value.indexOf('Void') > -1) {
      setVoidType(e.target.value);
      setShowVoidSearchModal(true);
    }
  };

  const addAdjustmentType = (empNo: number) => {
    onSearchDoubleClick(empNo, true);
    setShowDetailModal(true);
  };

  const onDeleteAdjustment = (check: PayrollAdjustmentSummary) => {
    if (check && payrollHistoryId && selectedAdjustmentEmployee) {
      if (check.adjustmentId) {
        dispatch(
          deletePayrollAdjustmentCheck({
            payrollHistoryId,
            empNo: selectedAdjustmentEmployee?.empNo,
            protectedEmpNo: selectedAdjustmentEmployee?.protectedEmpNo,
            adjustmentId: check.adjustmentId,
          }),
        );
      } else {
        dispatch(
          deleteBlankCheck(selectedAdjustmentEmployee?.empNo ?? 0),
        );
      }
    }
  };

  const onClose = () => {
    if (payrollHistoryId) dispatch(storeLatestPayroll(payrollHistoryId));
    history.push('/process-payroll');
  };
  
  const onAdd = () => {
    if (!(newAdjustmentCheck && selectedEmployee)) return;
    dispatch(
      createAdjustmentType({
        empNo: selectedEmployee.empNo,
        blankCheck: newAdjustmentCheck,
      }),
    );
  };

  const onSearchDoubleClick = (empNo: number, addCheck: boolean) => {
    setShowEmployeeSearch(false);
    onOpenAdjustmentDetails(empNo, addCheck);
  };

  const onOpenAdjustmentDetails = (empNo: number,  addCheck: boolean) => {
    const newSelection = employees?.find((a) => a.empNo === empNo);
    if (!newSelection) return console.error(`Cannot find employee ${empNo}`);
    if (payrollHistoryId) {
      dispatch(
        loadPayrollAdjustmentNewCheck({
          payrollHistoryId,
          protectedEmpNo: newSelection.protectedEmpNo,
        }),
      );
    }
    
    setSelectedEmployee(newSelection);
    setShowDetailModal(true);
    
    if (!addCheck) return;
    setAddAdjustment(true);
  };

  const addVoidedPrepay = (index: number, checkNo: number) => {
    if (payrollAdjustments) {
      const check = cloneDeep(
        payrollAdjustments[index].adjustmentChecks[checkNo],
      );
      check.adjustmentId = 0;
      check.adjustmentType = PayrollAdjustmentType.PrePaid;
      check.deductions.map((a) => { return (a.adjustmentDeductionId = 0); });
      check.earnings.map((a) => { return (a.adjustmentEarningId = 0); });
      check.stateWithholdings.map((a) => { return (a.adjustmentStateTaxId = 0); });
      check.localWithholdings.map((a) => { return (a.adjustmentLocalTaxId = 0); });
      dispatch(
        createPayrollAdjustmentCheck({
          data: check,
          payrollHistoryId: payrollHistoryId ?? 0,
          protectedEmpNo: payrollAdjustments[index].protectedEmpNo,
          adjustmentId: check.adjustmentId,
        }),
      );
    }
  };

  return (
    <main>
      <Col>
        <Row className="w-100">
          <Col xs={12}>
            <Row>
              <Col
                xs={12}
                sm={3}
              >
                <div className="dm-page-title">Adjustments</div>
              </Col>
              <Col
                xs={12}
                sm={9}
                className="mt-auto pb-2 text-right"
              >
                <div className="d-flex flex-wrap">
                  <label className="ml-auto mt-3 mr-2 pr-3 mb-3 mb-sm-2">
                    <img
                      src={searchIcon}
                      alt="search"
                      width={20}
                      height="auto"
                    />
                    <input
                      type="text"
                      name="mainSearch"
                      placeholder="Employee Search"
                      size={18}
                      onChange={(e: any) => { return setSearchText(e.target.value); }
                      }
                      className="app-search-label"
                    />
                  </label>
                  {!isFinishedPayroll ? <button
                    type="button"
                    className="btn btn-link dm-grid-action-title py-0"
                    onClick={onAddEmployee}
                  >
                    Add Employee
                    <Icon
                      name="plus-circle"
                      className="fa-plus-circle"
                    />
                  </button> : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <hr className="dm-page-hr" />
                <div className="dm-panel dm-panel-border">
                  <div className="dm-subtitle2">Employee List</div>
                  <div
                    style={{
                      height: 'calc(100vh - 320px)',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                    }}
                  >
                    <div
                      className="mock-header row align-items-end"
                      style={{
                        top: 0,
                        position: 'sticky',
                        zIndex: 999,
                      }}
                    >
                      <div className="col-2">Name</div>
                      <div className="col">
                        <div className="row align-items-end">
                          <div className="col-5">Description</div>
                          <div className="col-1">Week Ending</div>
                          <div className="col-1">Check Date</div>
                          <div className="col-1">Check No</div>
                          <div className="col-1"> Gross</div>
                          <div className="col-1">Net Pay</div>
                          <div className="col-2">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                    {[1].length > 0 ? (
                      [1]?.map((emp, index) => {
                        return (
                          <div
                            key={index}
                            className={`row no-gutters py-3 ${index % 2 ? 'bg-info' : ''}`}
                          // onMouseDown={() => { setAdjustedEmployee(item.empNo); }}
                          >
                            <div className="col-sm-2 d-flex flex-column px-3">
                              <EmpPhoto
                                empPicData={''}
                                empName={''}
                              />
                              <div className="dm-card-subtitle font-weight-bold">{''}</div>
                              <div>
                                <span>Employee #:&nbsp;</span>
                                <span>{1}</span> {/* empNo */}
                              </div>
                              <div>
                                <span>SSN #:&nbsp;</span>
                                <span>{formatSSN('')}</span>
                              </div>
                            </div>
                            <div className="col-10">
                              {[1]?.map( /* adjustmentChecks summary */
                                (check, ci: number) => {
                                  return (
                                    <div
                                      className="row"
                                      key={ci}
                                      data-id={ci}
                                    >
                                      <div className="col-3">
                                        <SelectGrp
                                          options={adjustmentCodeOpts}
                                          name={`items[${index}].adjustmentChecks[${ci}].adjustmentType`}
                                          errorMsg="Required"
                                          disabled={true}
                                        />
                                      </div>
                                      <div className="col-2">
                                      </div>
                                      <div className="col-1">
                                        <DatePickerGrp
                                          name={`items[${index}].adjustmentChecks[${ci}].weekEnd`}
                                          /*                                           errors={errors.items?.[index]?.adjustmentChecks?.[ci]?.weekEnd}
                                          value={check.weekEnd}
                                          control={control} */
                                          onChange={(newVal: Date | null) => {  }}
                                          disabled={true}
                                          rules={{ required: 'Week Ending is required' }}
                                        />
                                      </div>
                                      <div className="col-1">
                                        <DatePickerGrp
                                          name={`items[${index}].adjustmentChecks[${ci}].checkDate`}
                                          /*                                           errors={errors.items?.[index]?.adjustmentChecks?.[ci]?.checkDate}
                                          value={check.checkDate}
                                          control={control} */
                                          onChange={(newVal: Date | null) => {  }}
                                          disabled={true}
                                          rules={{ required: 'Check Date is required' }}
                                        />
                                      </div>
                                      <div className="col-1">
                                        <InputGrp
                                          name={`items[${index}].adjustmentChecks[${ci}].checkNo`}
                                          /*                                           errors={errors.items?.[index]?.adjustmentChecks?.[ci]?.checkNo}
                                          defaultValue={check.checkNo}
                                          ref={register({
                                            valueAsNumber: true,
                                            min: {
                                              value: 1,
                                              message:'Minimum value of 1',
                                            },
                                          },
                                          )} */
                                          disabled={true}
                                        />
                                      </div>
                                      <div className="col-1 text-right font-weight-bold">
                                        {/* check gross */}
                                      </div>
                                      <div className="col-1 text-right font-weight-bold">
                                        {/* net pay */}
                                      </div>
                                      <div className="col-2">
                                        {!isFinishedPayroll ? (
                                          <button
                                            type="button"
                                            className="btn btn-link dm-grid-action-title"
                                            onClick={() => { /* onDeleteAdjustment(check); */ }}
                                          >
                                            Delete{' '}
                                            <Icon
                                              name="minus-circle"
                                              className="fa-minus-circle"
                                            />
                                          </button>
                                        ) : null}
                                      </div>
                                    </div>
                                  );
                                },
                              )}
                            </div>
                            <div className="col-12">
                              <div className="row">
                                <div className="col-auto offset-2">
                                  {!isFinishedPayroll ? <button
                                    type="button"
                                    className="btn btn-link dm-grid-action-title p-0"
                                    // onClick={() => { addAdjustmentType(item.empNo); }}
                                    onClick={() => {
                                      history.push('/timecard-adjust-detail');
                                    }}
                                  >
                                    Add Adjustment
                                    <Icon
                                      name="plus-circle"
                                      className="fa-plus-circle"
                                    />
                                  </button> : null}
                                </div>
                                <div className="col-auto ml-auto">
                                  <button
                                    type="button"
                                    className="btn btn-link"
                                    onClick={() => {
                                      history.push('/timecard-adjust-detail');
                                    }}
                                  >
                                    <strong className="dm-grid-action-title"><u>Adjustment Details</u></strong>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <Row>
                        <Col>No rows to show</Col>
                      </Row>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 text-right mt-3">
                      <button
                        type="submit"
                        className="orange-outline-button mr-2"
                      >
                        Close Adjustments
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          {showVoidSearchModal && selectedAdjustmentEmployee && (
            <VoidSearchModal
              protectedEmpNo={selectedAdjustmentEmployee.protectedEmpNo}
              empNo={selectedAdjustmentEmployee.empNo}
              voidType={voidType}
              payrollHistoryId={payrollHistoryId ?? 0}
              show={showVoidSearchModal}
              onHide={() => { setShowVoidSearchModal(false); }}
              reset={() => { /* reset(); */ }}
            />
          )}
          {showEmployeeSearch ? (
            <EmployeeSearch
              show={showEmployeeSearch}
              onHide={() => { setShowEmployeeSearch(false); }}
            />
          ) : null}
        </Row>
      </Col>
    </main>
  );
};

export default TimeCardAdjustmentsPage;
