import React, { useState, useContext, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { PageConfig } from 'core/models';
import { PageConfigsContext } from './pageConfigContext';
import OnboardFieldConfigItem from './OnboardFieldConfigItem';
import { useDispatch } from 'react-redux';
import { toggleBlockNavigation, updateOnboardPageConfigs } from 'core/store/actions';
import { Prompt } from 'react-router-dom';
import { UNSAVED_MESSAGE } from 'core/constants';

const OnboardFieldConfig = () => {
  const [isDirty, setIsDirty] = useState(false);
  
  const dispatch = useDispatch();
  
  const pageConfigContext = useContext(PageConfigsContext);
  
  //Note: Added isArray to ensure it does not blow up if the update returns in a diffrent form.
  const firstTabKey = Array.isArray(pageConfigContext.pageConfigs)
    ? pageConfigContext.pageConfigs?.find((pc: PageConfig) => { return pc.fieldConfigs?.length; })
    : undefined;

  const onSave = () => {
    dispatch(updateOnboardPageConfigs(pageConfigContext.pageConfigs));
    setIsDirty(false);
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [isDirty]);

  return (
    <div className="row">
      <Prompt
        when={isDirty}
        message={UNSAVED_MESSAGE}
      />
      <div className="col-12">
        <div className="dm-panel dm-panel-border">
          <div className="dm-card-title">Onboarding Field Configurations</div>
          <div className="dm-card-subtitle">Mark the fields that are &quot;Required&quot; by Onboarding Employees.</div>
          <div className="row mt-3">
            <div className="col-12">
              <Tabs
                defaultActiveKey={firstTabKey?.pageID.toString()}
                id="onboard-field-configs"
              >
                {pageConfigContext.pageConfigs && Array.isArray(pageConfigContext.pageConfigs) ? pageConfigContext.pageConfigs?.map((pc: PageConfig) => {
                  if (pc.fieldConfigs?.length === 0) return null;
                  return (
                    <Tab
                      key={pc.pageID}
                      eventKey={pc.pageID.toString()}
                      title={pc.title}
                    >
                      <OnboardFieldConfigItem
                        setIsDirty={setIsDirty}
                        key={pc.pageID}
                        pageConfig={pc}
                      />
                    </Tab>
                  );
                }) : null}
              </Tabs>
            </div>
            <div className="col-12 text-right mt-3">
              <button
                className="btn btn-primary orange-button ml-3"
                onClick={onSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardFieldConfig;