/* eslint-disable react/prop-types */
import React from 'react';
import adjustmentStyles from './styles.module.scss';
import timeCardStyles from '../time-card-styles.module.scss';
import { formatWithCommas } from 'utilities/utilities';

const arr = [0, 1, 2, 3];

type Props = {
};

const EarningsHoursTotalsTable = ({ }: Props) => {
  return (
    <div className={`${adjustmentStyles['totals-table-container']} dm-panel dm-panel-border p-2`}>
      <div className="d-flex justify-content-between">
        <div className={timeCardStyles['section-title-blue']}>Earnings/Hours</div>
        <div className="d-flex flex-column align-items-end">
          <div className="font-weight-bold">Total Earnings:&nbsp;{formatWithCommas(2603)}</div>
          <div className="font-weight-bold">Total Hours:&nbsp;{formatWithCommas(180)}</div>
        </div>
      </div>
      <table className={adjustmentStyles['simple-table']}>
        <thead>
          <tr>
            <th>Pay Type</th>
            <th>Earnings</th>
            <th>Hours</th>
          </tr>
        </thead>
        <tbody>
          {arr.map((x) => {
            return (
              <tr key={x}>
                <td>1 - REGULAR</td>
                <td className={adjustmentStyles['cell-right']}>{formatWithCommas(650.75)}</td>
                <td className={adjustmentStyles['cell-right']}>{formatWithCommas(45)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EarningsHoursTotalsTable;
