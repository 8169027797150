import React, {
  FC,
  ReactNode,
  createContext,
  cloneElement,
  useState,
  useContext,
  useRef,
  ReactElement,
  useLayoutEffect,
  CSSProperties,
  useEffect,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useImperativeHandle,
} from 'react';
import styles from './shared.module.scss';
import Icon from './Icon';

// #region Misc. Types and helpers
const defaultRect = {
  height: 0,
  width: 0,
  top: 0,
  left: 0,
};

type Rect = Pick<DOMRect, 'left' | 'top' | 'height' | 'width'>;

type Position = 'top' | 'bottom' | 'right' | 'left';

/**
 * Determines top and left coordinates (used in px) of the dialog. 
 * @param triggerRect (`Rect`) bounding rectangle of the toggle button
 * @param popoverRect (`Rect`) bounding rectangle of the dialog
 * @param position (`"left" | "top" | "bottom" | "right"`)
 * @returns top and left positions for the dialog
 */
const getPopoverCoordinates = (triggerRect: Rect, popoverRect: Rect, position: Position, align: 'center' | 'offset', leftBuffer = 0) => {
  switch (position) {
    case 'bottom':
    default: {
      // top of dialog = top of the toggle button + height of the toggle button
      let top = triggerRect.top + triggerRect.height; 
      // left of dialog = maximum of toggle left + toggle width - dialog width OR 10
      // if align is center, divide width diff by 2 so it's always at center of trigger. Else it's offset so divide by 1 (i.e., "don't" divide)
      const left = Math.max(triggerRect.left + (triggerRect.width - popoverRect.width) / (align === 'center' ? 2 : 1), 10) + leftBuffer;
      
      // if the top with the height is greater than the window heigh (with a buffer of 10), relocate the dialog
      if (top + popoverRect.height > window.innerHeight - 10) {
        top = triggerRect.top - 10 - popoverRect.height;
      }

      return {
        top,
        left,
      };
    }
  }
};

type Coordinates = {
  left: number;
  top: number;
};

/**
 * Function for positioning dialog based on results of `getPopoverCoordinates`
 * @param coords (`Coordinates`) The coordinates to use to determine where the dialog goes
 * @returns (`CSSProperties`) The style obejct with the positions in px
 */
const getPopoverStyle = (coords: Coordinates): CSSProperties => {
  return {
    left: `${coords.left}px`,
  };
};

/**
 * Curried function to merge together multiple refs for a single element.
 * @param refs The refs on an element
 * @returns Function that loops on refs and conditionally sets their 
 * `.current` value to the `element`.
 */
const mergeRefs = (...refs: any[]) =>  {
  return (element: any) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(element);
      } else {
        ref.current = element;
      }
    });
  };
};

type PopoverContextType = {
  show: boolean;
  toggleShow: (newVal: boolean) => void;
  triggerRect: Rect;
  updateTriggerRect: (newVal: Rect) => void;
  name: string;
  position?: Position;
  align?: 'center' | 'offset'
  showCloseButton?: boolean;
  calculateCoords?: boolean;
  leftBuffer?: number;
};

const PopoverContext = createContext<PopoverContextType>({
  show: false,
  toggleShow: (_newVal: boolean) => {
    throw new Error('PopoverContext inaccessible outside a provider');
  },
  triggerRect: defaultRect,
  updateTriggerRect: (_newVal: Rect) => {
    throw new Error('PopoverContext inaccessible outside a provider');
  },  
  name: '',
  position: 'bottom',
  align: 'offset',
  showCloseButton: true,
});

// children is a ReactElement and not ReactNode because we pass it to cloneElement
type ElementSlot = {
  children: ReactElement; 
};

type NodeSlot = {
  children: ReactNode;
};

type Slots = {
  ToggleButton: FC<ElementSlot>;
  ContentWrapper: FC<ElementSlot>;
  Content: FC<NodeSlot>; // this is not cloned, so we just use a ReactNode
};

type Props = {
  name: string;
  position?: Position; 
  align?: 'center' | 'offset'
  showCloseButton?: boolean;
  calculateCoords?: boolean;
  leftBuffer?: number;
  onShow?: () => void;
  children: ReactNode;
};
// #endregion

// TODO: type ref
/**
 * A compound dialog component that renders a toggle button and some kind of dialog content (could be anything from text to a
 * a collection of inputs to even another dialog). Wrapped in forwardRef so that we can expose methods to the parent using
 * useImperativeHandle.
 */
const Popover = forwardRef<any, Props>(({
  name,
  position = 'bottom',
  align = 'offset',
  showCloseButton = true,
  calculateCoords = true,
  leftBuffer = 0,
  onShow,
  children },
ref) => {
  const [show, setShow] = useState<boolean>(false);
  const [triggerRect, setTriggerRect] = useState<Rect>(defaultRect);
  
  const toggleShow = (newVal: boolean) => {
    setShow(newVal);
    if (newVal && onShow) onShow(); // handles showing in the parent component
  };
  
  const updateTriggerRect = (newVal: Rect) => {
    setTriggerRect(newVal);
  };
  
  const contextVal: PopoverContextType = {
    show,
    toggleShow,
    triggerRect,
    updateTriggerRect,
    name,
    position,
    align,
    showCloseButton,
    calculateCoords,
    leftBuffer,
  };
  
  // TODO: stronger typing of ref and callback
  // This will expose toggleShow to the parent so that we can open/close the dialog programmatically.
  useImperativeHandle<HTMLElement, any>(ref, () => {
    return {
      toggle(newVal: boolean) {
        toggleShow(newVal);
      },
    };
  }, []);
  
  return (
    <PopoverContext.Provider value={contextVal}>
      <div ref={ref}> {/* we just have this div wrapper to put the ref on. */}
        {children}
      </div>
    </PopoverContext.Provider>
  );
}) as ForwardRefExoticComponent<Props & RefAttributes<HTMLElement>> & Slots; // have to cast so TS knows we have the slots

Popover.displayName = 'Popover';

export default Popover;

// #region Slot definitions
// this is what opens the dialog
function PopoverToggleButton({ children }: ElementSlot) {
  const { show, toggleShow, updateTriggerRect } = useContext(PopoverContext);
  
  const ref = useRef<HTMLElement | null>(null);
  
  const onClick = (e: React.MouseEvent) => {
    if (!ref?.current) return;
    
    // get the bounding rectangle of the toggle button
    const boundingRect: DOMRect = ref.current.getBoundingClientRect();
    
    // set the trigger rectangle in the Popover state
    updateTriggerRect(boundingRect);
    toggleShow(!show);
    
    e.stopPropagation();
  };
  
  /* We clone here to modify the children by adding onClick and ref rather than rendering. Normally we want to avoid
  cloneElement because it can make tracing things a pain in the ass, but here we're just adding functionality to the 
  children rather than rendering something. */
  const clonedChildren = cloneElement(children, {
    onClick,
    ref,
  });
  
  return clonedChildren;
}

// Content inside the wrapper. This is what we're actually rendering inside of the dialog (and renders the dialog itself).
function PopoverContent({ children }: NodeSlot) {
  const {
    triggerRect,
    position,
    toggleShow,
    name,
    align,
    showCloseButton,
    calculateCoords,
    leftBuffer,
  } = useContext(PopoverContext);
  
  const ref = useRef<HTMLDialogElement | null>(null);
  
  const [coords, setCoords] = useState<Coordinates>({ left: 0, top: 0 });
  
  const updateCoords = () => {
    /* after using this Popover in FakeSelect, I noticed that we should not calculate the position in certain situations.
    More specifically, in the employee list it's calculating the left and top offsets relative to the page, whereas the 
    FakeSelect appears to go off of its own elements so there isn't a need for offsets. */
    if (!ref?.current || !calculateCoords) return;
    
    const boundingRect: DOMRect = ref.current.getBoundingClientRect();
    const popoverCoords = getPopoverCoordinates(triggerRect, boundingRect, position || 'bottom', align || 'offset', leftBuffer);
    
    setCoords(popoverCoords);
  };
  
  /* 
  We don't use this hook very much at all, so just going to add to the notes here: this will run
  BEFORE the browser repaints, which can make it tempting to use everywhere in place of useEffects.
  Only use this if you need to do something like layout calculations of an element, otherwise it can
  really hurt performance.

  In this case, we're using it because DOMRect of an element isn't available until it's in the DOM,
  so we want to figure out where to put it before the paint is done.
 */
  useLayoutEffect(() => {
    updateCoords();
  }, []);
  
  const toggleShowCallback = () => {
    toggleShow(false);
  };
  
  // reference to "outside" elements (i.e., not our dialog)
  const refClickOutside = useClickOutside(name, toggleShowCallback);
  
  // merge the refs
  const mergedRef = mergeRefs(ref, refClickOutside);
  
  return (
    <dialog
      className={styles['popover-dialog']}
      id={name}
      style={getPopoverStyle(coords)}
      open={true}
      ref={mergedRef}
    >
      {showCloseButton && (
        <div className="d-flex w-100 mb-2 justify-content-end">
          <Icon
            name="x"
            title="close"
            color="black"
            fontSize="0.6rem"
            onClick={() => { toggleShow(false); }}
          />
          <span className="sr-only">close dialog</span>
        </div>
      )}
      {children}
    </dialog>
  );
}

// wraps the `PopoverContent`
function PopoverContentWrapper({ children }: ElementSlot) {
  const { show } = useContext(PopoverContext);
  
  if (!show) return null;
  
  return (
    <PopoverContent>
      {children}
    </PopoverContent>
  );
}

// build compound component with subcomponents
Popover.ToggleButton = PopoverToggleButton;
Popover.Content = PopoverContent;
Popover.ContentWrapper = PopoverContentWrapper;
// #endregion

// #region Custom Hook(s)
/**
 * Hook to handle clicks anywhere BUT the dialog component so that we can close it.
 * @param name A unique identifier for THIS dialog component
 * @param callback What we want to do when we're clicking somewhere else (in this case, close it)
 * @returns The ref
 */
const useClickOutside = (name: string, callback: () => void) => {
  const ref = useRef<HTMLElement | null>(null);
  
  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (!ref?.current) return console.error('Could not set ref');
      if (!ref.current.contains(e.target as Node)) callback();
    };

    // delay it to avoid treating trigger click as click outside
    window.setTimeout(() => document.addEventListener('click', onClick), 0);
    return () => {
      window.setTimeout(() => document.removeEventListener('click', onClick), 0);
    };
  }, []);
  
  return ref;
};
// #endregion