import Icon from 'core/components/shared/Icon';
import { TO_NAME, convertRegion } from 'core/states';
import React, { useState } from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';

type Props = {
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  maidenName?: string | null;
  emailAddress?: string | null;
  driverLicenseNumber?: string | null;
  cellPhone?: string | null;
  homePhone?: string | null;
  otherPhone?: string | null;
  streetAddress1?: string | null;
  streetAddress2?: string | null;
  state?: string | null;
  city?: string | null;
  zip?: string | null;
  eeo?: string | null;
  gender?: string | null;
};

const PersonalInformation = ({
  firstName,
  lastName,
  middleName,
  maidenName,
  emailAddress,
  driverLicenseNumber,
  cellPhone,
  homePhone,
  otherPhone,
  streetAddress1,
  streetAddress2,
  state,
  city,
  zip,
  eeo,
  gender,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header className="p-3">
          <Accordion.Toggle
            eventKey="0"
            style={{
              background: 'transparent',
              border: 'none',
            }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <Icon
              name={isOpen ? 'chevron-up' : 'chevron-down'}
              className="mr-1"
            />
            Personal Information
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body
            className="p-3"
          >
            <Row>
              <Col sm={2}>
                <p className="font-weight-bold mb-0">Name</p>
                <p className="mb-0">{firstName}{!!middleName && ` ${middleName}.`} {lastName}</p>
                {!!maidenName && <p>Maiden Name: {maidenName}</p>}
              </Col>
              <Col sm={2}>
                <p className="font-weight-bold mb-0">Address</p>
                {streetAddress1 && (
                  <p className="mb-0">{streetAddress1}</p>
                )}
                {streetAddress2 && (
                  <p className="mb-0">{streetAddress2}</p>
                )}
                {(city || state) && (
                  <p className="mb-0">{city} {state && `, ${convertRegion(state, TO_NAME)}`}</p>
                )}
                {zip && (
                  <p className="mb-0">{zip}</p>
                )}
              </Col>
              <Col sm={2}>
                <p className="font-weight-bold mb-0">Contact</p>
                {homePhone && (
                  <p className="mb-0">Home: {homePhone}</p>
                )}
                {cellPhone && (
                  <p className="mb-0">Cell: {cellPhone}</p>
                )}
                {otherPhone && (
                  <p className="mb-0">Other: {otherPhone}</p>
                )}
              </Col>
              <Col sm={2}>
                <p className="font-weight-bold mb-0">Other Contact</p>
                {emailAddress && (
                  <p className="mb-0">Email: {emailAddress}</p>
                )}
              </Col>
              <Col sm={2}>
                <p className="font-weight-bold mb-0">Drivers License</p>
                <p className="mb-0">{driverLicenseNumber || ''}</p>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={2}>
                <p className="font-weight-bold mb-0">EEO Race</p>
                <p className="mb-0">{eeo || ''}</p>
              </Col>
              <Col sm={2}>
                <p className="font-weight-bold mb-0">Gender</p>
                <p className="mb-0">{gender || ''}</p>
              </Col>
            </Row>

          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default PersonalInformation;