import React, { Dispatch, SetStateAction } from 'react';
import { Row, Col } from 'react-bootstrap';
import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';
import { AllModules, GridOptions, ColDef, RowSelectedEvent } from '@ag-grid-enterprise/all-modules';
import { LookupEEOCertifyOfficial, LookupEEOUnit } from '../../core/models/EEO.model';

const columns: ColDef[] = [
  {
    field: 'number',
    headerName: 'NUMBER',
    width: 85,
  },
  {
    field: 'statusCode',
    headerName: 'STATUS CODE',
    width: 115,
  },
  {
    field: 'name',
    headerName: 'NAME',
    width: 150,
  },
  {
    field: 'address',
    headerName: 'ADDRESS 1',
    width: 150,
  },
  {
    field: 'address2',
    headerName: 'ADDRESS 2',
    width: 120,
  },
  {
    field: 'city',
    headerName: 'CITY',
    width: 110,
  },
  {
    field: 'state',
    headerName: 'STATE',
    width: 80,
  },
  {
    field: 'bradStreetNo',
    headerName: 'BRAD STREET NO',
    width: 140,
  },
  {
    field: 'county',
    headerName: 'COUNTY',
    width: 110,
  },
  {
    field: 'zip',
    headerName: 'ZIP',
    width: 90,
  },
];

const gridOptions: GridOptions = {
  columnDefs: columns,
  defaultColDef: {
    suppressMenu: true,
    resizable: true,
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
};

type PropTypes = {
  lookupEEOUnits: LookupEEOUnit[],
  lookupCertifyOfficials: LookupEEOCertifyOfficial[],
  setSelectedEEOUnit: Dispatch<SetStateAction<LookupEEOUnit>>;
  // setSelectedEEOCertOff: Dispatch<SetStateAction<LookupEEOCertifyOfficial>>;
};

const EEOUnitTable: React.FC<PropTypes> = ({ lookupEEOUnits, lookupCertifyOfficials, setSelectedEEOUnit }) => {

  const onRowClicked = (event: RowSelectedEvent) => {
    setSelectedEEOUnit(event.node.data);
    // const co = lookupCertifyOfficials.find(o => o.certifyOfficalID === event.node.data.certifyOfficalID);
    // co && setSelectedEEOCertOff(co);
  };

  return (
    <Row style={{ width: '100%' }}>
      <Col xs={12} className="table-wrapper-wrapper ag-theme-balham" style={{ height: '200px' }}>
        <AgGridReact
          gridOptions={gridOptions}
          rowData={lookupEEOUnits}
          modules={AllModules}
          onRowClicked={onRowClicked} />
      </Col>
    </Row>
  );
};

export default EEOUnitTable;
