import { RadioGrp } from 'core/components/form-controls';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import Icon from 'core/components/shared/Icon';
import { EmployeeLastCheckReportRequest } from 'core/models/PayrollReports.model';
import { CommonService } from 'core/services';
import { clearEmployeeLastCheckReport, downloadEmployeeLastCheckReport } from 'core/store/actions';
import { getEmployeeLastCheckReport } from 'core/store/selectors';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const status: RadioOptions[] = [
  {
    value: 'All',
    label: 'All Employees',
  },
  {
    value: 'Active',
    label: 'Active Employees',
  },
  {
    value: 'Terminated',
    label: 'Terminated Employees',
  },
];

const EmployeeLastCheckReport = () => {

  const dispatch = useDispatch();

  const childRef: React.RefObject<any> = useRef();
  const [fileType, setFileType] = useState<string>('pdf');
  const report = useSelector(getEmployeeLastCheckReport);

  const { register, handleSubmit, getValues } =
    useForm<EmployeeLastCheckReportRequest>({
      defaultValues: {
        employeeType: 'All',
      },
    });

  useEffect(() => {
    if (report && fileType === 'excel') {
      report &&
        CommonService.downloadBase64File(
          'application/excel',
          report,
          'EmployeeLastCheckReport.xlsx',
        );
      dispatch(clearEmployeeLastCheckReport());
    }
  }, [report]);

  const downloadReport = (data: EmployeeLastCheckReportRequest) => {
    const submitData: EmployeeLastCheckReportRequest = {
      employeeType: data.employeeType,
      reportType: fileType,
    };
    dispatch(downloadEmployeeLastCheckReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearEmployeeLastCheckReport());
  };

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">Employee Last Check Report</div>
          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title "
              onClick={() => { return setFileType('excel'); }}
            >
              Download Report{' '}
              <Icon name="download"
                className="fa-download" />
            </button>
          </div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div className="d-flex ">
              <div>
                <RadioGrp
                  radioOptions={status}
                  name="employeeType"
                  ref={register}
                />
              </div>
            </div>
            <div className="flex-grow-1 text-right">
              <button
                type="button"
                className="orange-outline-button mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="orange-button mr-2"
                onClick={() => { return setFileType('pdf'); }}
              >
                Run Report
              </button>
            </div>
          </div>
        </form>
      </div>

      {report ? <PDFViewerModal
        show={report.length > 0}
        pdfData={report}
        onHide={hidePdfViewer}
        reportName="EmployeeLastCheckReport"
      /> : null}
    </div>

  );
};

export default EmployeeLastCheckReport;