import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'utilities/hooks';
import { deleteJob, getJobs, storeSelectedJob, updateJobStatus } from 'core/store/slices/applicantTracking.slice';
import { ColDef, GridOptions, ICellRendererParams } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { AvailableJob, ClientJobs, Tag, newJob } from 'core/models';
import { Prompt, useHistory } from 'react-router-dom';
import PanelHeader from 'core/components/shared/PanelHeader';
import { agDateRenderer } from 'utilities/ag-grid-renderers';
import { agCheckboxRenderer2 } from 'utilities/ag-grid-renderers/agCheckboxRenderer2';
import JobApplicantsModal from './JobApplicants.modal';
import AGDeleteButtonRendererComponent from 'utilities/ag-grid-renderers/AGDeleteButtonRendererComponent';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { Button } from 'react-bootstrap';
import Icon from 'core/components/shared/Icon';
import { UNSAVED_MESSAGE } from 'core/constants';
import { toast } from 'react-toastify';

const ManageJobs = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const jobs = useAppSelector((state) => { return state?.applicantTracking?.jobs?.availableJobs; });
  const client = useAppSelector((state) => { return state?.client?.client; });

  const [rowData, setRowData] = useState<AvailableJob[]>([]);
  const [selectedJob, setSelectedJob] = useState<AvailableJob>();
  const [updatedDisplayJobs, setUpdatedDisplayJobs] = useState<AvailableJob[]>([])
  const [updatedDisplayJob, setUpdatedDisplayJob] = useState<AvailableJob>();
  const [showApplicantsModal, setShowApplicantsModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);

  useEffect(() => {
    dispatch(getJobs());
  }, []);

  useEffect(() => {
    jobs && setRowData(jobs);
  }, [jobs]);

  const onViewApplicants = (params: any) => {
    setSelectedJob(params.data);
    setShowApplicantsModal(true);
  };

  const onDelete = (params:AvailableJob)=>{
    setDeleteId(params.jobId);
    setShowConfirmationModal(true);
  };

  const onConfirmDelete = (confirmed:boolean)=>{
    confirmed && deleteId && dispatch(deleteJob(deleteId));    
   
  };

  useEffect(() => {
    if(updatedDisplayJob)
    {
      const currentJob = structuredClone(updatedDisplayJob) as AvailableJob;
      currentJob.isActive = !currentJob.isActive;

      if(updatedDisplayJobs.map(x => x.jobId).includes(currentJob.jobId))
      {
        const filteredJobs = updatedDisplayJobs.filter(x => x.jobId !== currentJob.jobId);
        setUpdatedDisplayJobs(filteredJobs);
      }
      else{
        setUpdatedDisplayJobs([...updatedDisplayJobs, currentJob]);
      }
    }
    //This is so if they check then uncheck the same button this will be set each time calling this useEffect
    setUpdatedDisplayJob(undefined);
  }, [updatedDisplayJob]);

  const onClick = (params:ICellRendererParams) => {
    if(params.data)
    {
      setUpdatedDisplayJob(params.data);
    }
  }

  const onSave = () => {
    const request : ClientJobs = {
      clientNo: client?.clientNo || 0,
      protectedClientNo: '',
      hrEntityId: client?.hrEntityId || 0,
      availableJobs: updatedDisplayJobs
    };

    dispatch(updateJobStatus(request));
    setUpdatedDisplayJobs([]);
    setUpdatedDisplayJob(undefined);
  }

  const columns = useMemo<ColDef[]>(
    () => {
      return [{
        field: 'jobTitle',
        headerName: 'Position',
        width: 130,
      },
      {
        field: 'isActive',
        headerName: 'Display on Web',
         cellRenderer: (params: ICellRendererParams) => {
           return (     
             <div className='dm-grid-centered-checkbox'>      
             <input
               type="checkbox"
               className="btn btn-link"
               defaultChecked={params.data.isActive}
               onClick={() => { return onClick(params); }
               }
             />
             </div>
           );
         },
        width: 100,
      },
      {
        field: 'company',
        headerName: 'Company Name',
        width: 130,
      },
      {
        field: 'city',
        headerName: 'City',
        width: 130,
      },
      {
        field: 'state',
        headerName: 'State',
        width: 130,
      },
      {
        field: 'createDate',
        headerName: 'Date Posted',
        cellRenderer: 'dateRenderer',
        width: 130,
        cellStyle: {
          textAlign: 'right',
        },
      },
      {
        field: 'tags',
        headerName: 'Tags',
        cellRenderer: (params: ICellRendererParams) => {
          const tags = params.data.tags.map((t:Tag) => {return t.tag;}).join(',');
          return tags; 
        },
        width: 130,
      },
      {
        field: 'view',
        headerName: 'Applicants',
        cellRenderer: (params: ICellRendererParams) => {
          return (           
            <button
              type="button"
              className="btn btn-link"
              onClick={() => { return onViewApplicants(params); }
              }
            >
              View
            </button>
          );
        },
        width: 130,
      },      
      {
        field: 'delete',
        headerName:'Delete',
        cellRendererParams: {
          clickHandler: onDelete,
        },
        cellRenderer: 'agDeleteButtonRendererComponent',
        width: 130,
      }];
    },
    [],
  );

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    defaultColDef: {
      suppressMenu: false,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
      sortable: true,
    },
    components: {
      dateRenderer: agDateRenderer,
      agDeleteButtonRendererComponent: AGDeleteButtonRendererComponent,
    },
  };

  const onJobSelect = (data:AvailableJob) => {
    dispatch(storeSelectedJob(data));
    history.push('manage-job');
  };

  return (
    <div className="dm-panel dm-panel-border" >
      <PanelHeader title="Manage Available Jobs" >
        <div style={{paddingBottom:'10px'}}>
        <Button
          variant="link"
          onClick={()=> {return onJobSelect(newJob);}}
        >
          Add New Job <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </Button>
        <Button
              type="submit"
              className="btn orange-button-sm mr-3"
              onClick={onSave}
              disabled={(updatedDisplayJobs?.length || 0) === 0}
            >
              Save
        </Button>
        </div>
      </PanelHeader>
      <div className="mt-3 col-12 table-wrapper-wrapper ag-theme-balham">
        <AgGridReact
          gridOptions={gridOptions}
          rowData={rowData}
          columnDefs={columns}
          onCellDoubleClicked={(e:any)=> {return onJobSelect(e.data);}}
        />
      </div>
      <Prompt
        when={updatedDisplayJobs.length > 0}
        message={UNSAVED_MESSAGE}
      />
      {showApplicantsModal && selectedJob ?
        <JobApplicantsModal
          show={showApplicantsModal}
          onHide={()=>{return setShowApplicantsModal(false);}}
          job={selectedJob}
        />
        : null}
      {showConfirmationModal ? (
        <ConfirmationModal
          title="Delete Available Job"
          message={'Are you sure you want to Delete this job?'}
          show={showConfirmationModal}
          onConfirmed={onConfirmDelete}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      ) : null}
    </div>
  );
};

export default ManageJobs;