import React from 'react';
import CheckBoxForm from '../../Shared/CheckBoxForm';
import { Col, Row } from 'react-bootstrap';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for Checkbox and Grid select forms
const DeferredCompFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  return (
    <Row>
      {!!sectionFields?.length && (
        <Col xs={12}>
          <CheckBoxForm
            key="ACA Status"
            formMethods={formMethods}
            dFields={sectionFields.find(f => {
              return f.name === 'Deferred Compensation';
            })?.fields}
            section={'Deferred Compensation'}
            title={'Deferred Compensation'}
          />
        </Col>
      )}
    </Row>
  );
};

export default DeferredCompFields;