import { ICellRendererParams } from '@ag-grid-community/core';
import editIcon from '../../assets/images/edit-small.png';

interface agEditLinkRendererParams extends ICellRendererParams {
  href: string;
}

export function agEditLinkRenderer(): any {}

agEditLinkRenderer.prototype.init = function (
  params: agEditLinkRendererParams,
) {
  this.linkWrapper = document.createElement('a');
  this.linkWrapper.href = params.href;

  this.img = document.createElement('img');
  this.img.src = editIcon;
  this.img.alt = 'edit';
  this.img.style = 'margin-top: 5px';

  this.linkWrapper.appendChild(this.img);
};

agEditLinkRenderer.prototype.getGui = function (
  params: agEditLinkRendererParams,
) {
  return this.linkWrapper;
};

agEditLinkRenderer.prototype.refresh = function (
  params: agEditLinkRendererParams,
) {
  return true;
};

agEditLinkRenderer.prototype.destroy = function () {};
