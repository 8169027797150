import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Labels } from '.';
import styles from './form-controls.module.scss';
import { FormMethods } from './types';

type PropTypes = {
  id?: string;
  name: string;
  label?: string | string[];
  tallLabel?: boolean;
  labelClass?: string;
  groupClass?: string;
  groupStyle?: Object;
  inputClass?: string;
  errors?: any;
  required?: boolean;
  value?: any;
  oldValue?: any;
  setValue?: any;
  control: any;
  rules?: object;
  errorMsg?: string;
  onChange?: any;
  innerChangeHandler?: (value?: string) => any;
  disabled?: boolean;
  minDate?: any;
  maxDate?: any;
  readOnly?: boolean;
  visible?: boolean;
  showLabel?: boolean;
  fastForward?: boolean;
};

export const ControlDatePickerGrp: React.FC<PropTypes> = ({
  id,
  name,
  label,
  tallLabel = false,
  labelClass,
  groupClass,
  groupStyle,
  inputClass,
  errors,
  required = false,
  onChange: onChangeHandler,
  innerChangeHandler,
  control,
  value,
  rules,
  errorMsg,
  disabled = false,
  minDate,
  maxDate,
  readOnly = false,
  visible = true,
  showLabel = true,
  fastForward = false,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const focusRef = useRef<any>();

  //Is there some shared place I can put this?
  const getErrorMsg = (_errors: FormMethods['errors']) : string => {
    if (!_errors) return '';
    if (_errors.type === 'validate') {
      return (errorMsg) ? errorMsg : _errors.message;
    } else return _errors.message;
  };

  const errMsg: string = getErrorMsg(errors);
  const groupClass2 = styles['dm-form-group'] + ' ' + (groupClass ?? '');
  id = id || name;
  value = value ?? '';
  
  const validateYear = (date: string) => {
    if (!date) {
      setErrorMessage(null);
      return;
    }

    const dateArray = date.split('/');
    const yearString = dateArray[2].replace(/\D/g, '');

    if (!yearString || yearString === '') {
      setErrorMessage(null);
      return;
    }
    if (yearString.length < 4) {
      setErrorMessage('Year must be 4 digits');
    } else {
      setErrorMessage(null);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Tab') {
      focusRef?.current?.setOpen(false);
    }
  };
  
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      onFocus={() => { setErrorMessage(null); }}
      defaultValue={value}
      render={({ onChange, value: _value }) => {
        return (
          <>
            {visible ? (
              <div
                className={groupClass2}
                style={groupStyle}
              >
                {showLabel && (
                  <Labels
                    label={label}
                    tallLabel={tallLabel}
                    labelClass={labelClass}
                    id={id}
                    hasError={!!errors}
                    required={required}
                  />
                )}
                <DatePicker
                  minDate={minDate}
                  maxDate={maxDate}
                  onCalendarOpen={() => {
                  // will really only happen on datelines generated after a monthly or semi-monthly client submits payroll
                    if (Number.isNaN(Date.parse(_value)) || _value === null || new Date(_value).getFullYear() === 1) {
                      focusRef?.current?.setSelected(new Date());
                    }
                  }}
                  ref={focusRef}
                  onKeyDown={onKeyDown}
                  enableTabLoop={false}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  autoComplete="off"
                  disabled={disabled}
                  readOnly={readOnly}
                  preventOpenOnFocus={true}
                  name={name}
                  selected={Number.isNaN(Date.parse(_value)) ? null : new Date(_value)}
                  id={id}
                  onChange={(e) => {
                  // library reads dates 00xx as 20xx, so 0001 --> 2001 --> current year.
                  // for now this feature is opt-in.
                    if (fastForward && e?.getFullYear() === 2001) {
                      focusRef?.current?.setSelected(new Date());
                      e = new Date();
                    }
                    if (onChangeHandler)
                      onChangeHandler(e);
                    onChange(e);
                  }}
                  onBlur={({ target }) => {
                    validateYear(target.value);
                    if (innerChangeHandler) { innerChangeHandler(target.value); }
                  }}
                  onChangeRaw={({ target }) => {
                    validateYear(target.value);
                    if (innerChangeHandler) { innerChangeHandler(target.value); }
                  }}
                  className={
                    styles['dm-form-control'] +
                  ' ' +
                  (errors
                    ? ' ' + styles['dm-is-invalid']
                    : inputClass)
                  }
                  customInput={<InputMask mask="99/99/9999" />}
                />
                {/* TODO: This is a bigger issue with how validation is set up for most inputs, will need a lot t ochange. */}
                {errMsg ? (
                  <small className="text-danger d-flex">{errMsg}</small>
                ) : null}
                {errorMessage ? (
                  <small className="text-danger d-flex">{errorMessage}</small>
                ) : null}
              </div>
            ) : null}
          </>
        );
      }}
    />
  );
};
