import React from 'react';
import {
  CheckCodeSelect,
  ControlDatePickerGrp,
  InputGrp,
  InputGrpDecimal,
  SelectGrp,
} from 'core/components/form-controls';
import { getCheckCodesForDropdown, getClientDeductions, getDropdownOptions } from 'core/store/selectors';
import { directDepositStatusOptions } from 'dropdowns/deductionStatusOptions';
import { ClientDeduction, Deduction } from 'core/models';
import { ArrayField } from 'react-hook-form';
import DropdownOptionForm from 'core/components/form-controls/select-modal/DropdownOptionForm';
import { useSelector } from 'react-redux';
import { formatDecimal, getAccess } from 'utilities/utilities';
import Icon from 'core/components/shared/Icon';
import { validateRoutingNo } from 'core/helper/routingNumberValidator';
import styles from 'core/components/form-controls/form-controls.module.scss';
import { useAppSelector } from 'utilities/hooks';

type PropTypes = {
  directDeposit: Partial<ArrayField<Deduction>>;
  index: number;
  formMethods: any;
  control: any;
  onDelete: (ded: Deduction) => void;
  updateDirtyState?: (newState: boolean, id: number) => void;
  isCheckCalculator?: boolean;
};

const DirectDepositItem: React.FC<PropTypes> = ({
  directDeposit,
  index,
  formMethods: { errors, setValue, register, watch, getValues },
  control,
  onDelete,
  updateDirtyState,
  isCheckCalculator = false,
}) => {

  const { deductionFrequencyOpts, deductionCodeOptions, unitOpts } =
    useSelector(getDropdownOptions);
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });

  const ddUnitOpts = unitOpts.filter((option) => { return ['', 'N'].includes(option.id); });

  const checkCodeOpts = useSelector(getCheckCodesForDropdown);
  const clientDeductions = useSelector(getClientDeductions) as ClientDeduction[];

  const dedNoName = `directDepositItems[${index}].dedNo`;
  const dedTypeName = `directDepositItems[${index}].deductionType`;
  const statusName = `directDepositItems[${index}].status`;
  const dedAmountName = `directDepositItems[${index}].dedAmount`;
  const unitName = `directDepositItems[${index}].unit`;
  const checkCodeName = `directDepositItems[${index}].checkCode`;
  const freqName = `directDepositItems[${index}].freq`;
  const dateStartName = `directDepositItems[${index}].dateStart`;
  const dateExpireName = `directDepositItems[${index}].dateExpire`;
  const routingNoName = `directDepositItems[${index}].routingNo`;
  const bankAcctName = `directDepositItems[${index}].bankAcctNo`;
  const financialInstitutionName = `directDepositItems[${index}].financialInstitution`;
  const entityDescName = `directDepositItems[${index}].entityDesc`;

  const watchSkipAlways = watch(`directDepositItems[${index}].skipAlwayDedId`);
  const readOnly = ((watchSkipAlways ?? -1) !== -1);
  const watchedCheckCode = watch(`directDepositItems[${index}].checkCode`);
  const watchedStatus = watch(`directDepositItems[${index}].status`);
  const watchedUnit = watch(`directDepositItems[${index}].unit`);
  
  const fs = ({
    dedNo: {
      name: dedNoName,
      label: 'DED NO',
      groupClass: 'groupClass12',
      required: true,
      addOptionText: 'Deduction Code',
      readOnly: readOnly,
    },
    status: {
      name: statusName,
      label: 'STATUS',
      modalTitle: 'DEDUCTION STATUSES',
      groupClass: 'groupClass12',
      readOnly: readOnly,
    },
    dedAmount: {
      name: dedAmountName,
      label: 'AMOUNT',
      groupClass: 'groupClass12',
      required: true,
      readOnly: readOnly,
    },
    unit: {
      name: unitName,
      label: 'UNIT (%,H)',
      groupClass: 'groupClass12',
      modalTitle: 'UNITS',
      addOptionText: 'Units',
      formComponent: DropdownOptionForm,
      readOnly: readOnly,
    },
    checkCode: {
      name: checkCodeName,
      label: 'CK CD',
      groupClass: 'groupClass12',
      readOnly: readOnly,
    },
    freq: {
      name: freqName,
      label: 'FREQUENCY',
      groupClass: 'groupClass12',
      required: true,
      modalTitle: 'FREQUENCIES',
      addOptionText: 'Frequency',
      formComponent: DropdownOptionForm,
      readOnly: readOnly,
    },
    dateStart: {
      name: dateStartName,
      label: 'START DATE',
      groupClass: 'groupClass12',
      readOnly: readOnly,
    },
    dateExpire: {
      name: dateExpireName,
      label: 'EXPIRE DATE',
      groupClass: 'groupClass12',
      errorMsg: 'Expire date must be after start date.',
      readOnly: readOnly,
    },
    routingNo: {
      name: routingNoName,
      label: 'ROUTING NUMBER',
      required: true,
      tallLabel: true,
      groupClass: 'groupClass20',
      type: 'number',
      errorMsg: 'If routing no. is provided, then bank acct no. must be provided.',
      readOnly: readOnly,
    },
    bankAcctNo: {
      name: bankAcctName,
      label: 'ACCOUNT NUMBER',
      required: true,
      tallLabel: true,
      groupClass: 'groupClass20',
      errorMsg: 'If routing no. is provided, then bank acct no. must be provided.',
      readOnly: readOnly,
    },
    financialInstitution: {
      name: financialInstitutionName,
      label: 'FINANCIAL INSTITUTION',
      tallLabel: true,
      groupClass: 'groupClass20',
      readOnly: readOnly,
    },
    entityDesc: {
      name: entityDescName,
      label: 'SUB DESC',
      groupClass: 'groupClass40',
      readOnly: readOnly,
    },
  });

  function clientMasterSpecialChecks(dedNo: number) {
    const matchDeduction = clientDeductions.find(x => { return x.deductionNumber === dedNo; });

    //If it is a newly added deduction set the frequency to the matchDeduction frequency
    if (directDeposit?.dedId ?? 0 < 0)
      setValue(freqName, matchDeduction?.frequencyCode ?? '0');
  }

  const deductionCodeOpts = deductionCodeOptions
    .filter((d) => {
      return (d.code === 'C' || d.code === 'S' || d.code == '@');
    })
    .map((d) => {
      return {
        ...d,
        id: +d.id,
        description: `${d.id.padStart(2, '0')} - ${d.description}`,
      };
    });
  
  return (
    <>
      <div className="d-flex flex-wrap">
        <input
          type="hidden"
          name={`directDepositItems[${index}].dedId`}
          defaultValue={directDeposit.dedId}
          ref={register({ valueAsNumber: true })}
        />
        <input
          type="hidden"
          name={`directDepositItems[${index}].skipAlwayDedId`}
          defaultValue={directDeposit?.skipAlwayDedId ?? -1}
          ref={register({ valueAsNumber: true })}
        />
        <input
          type="hidden"
          name={dedTypeName}
          defaultValue={directDeposit.deductionType}
          ref={register()}
        />
        <SelectGrp
          {...fs.dedNo}
          {...getAccess(sectionAccess, 5, undefined, { readOnlyDefault: isCheckCalculator || (directDeposit?.dedId ?? 0) > 0 || readOnly })}
          addEmptyValue
          options={deductionCodeOpts}
          selectClass={(directDeposit?.dedId ?? 0) < 1 ? styles['dm-form-control-highlight'] : ''}
          errors={errors.dedNo}
          defaultValue={directDeposit.dedNo}
          ref={register({
            valueAsNumber: true,
          })}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {           
            clientMasterSpecialChecks(parseInt(e.target.value));
            updateDirtyState?.(true, directDeposit?.dedId ?? 0);
          }}
        />
        <SelectGrp
          {...fs.status}
          {...getAccess(sectionAccess, 5)}
          options={directDepositStatusOptions}
          errors={errors.status}
          control={control}
          value={watchedStatus ?? 'ACTIVE'}
          ref={register()}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setValue(statusName, e.target.value);
            updateDirtyState?.(true, directDeposit?.dedId ?? 0);
          }}
        />
        <InputGrpDecimal
          {...fs.dedAmount}
          {...getAccess(sectionAccess, 5)}
          style={{ textAlign: 'right' }}
          type={'text'}
          setValue={setValue}
          errors={errors.dedAmount}
          ref={register({
            required: 'Deduction Amount is Required',
          })}
          strValue={formatDecimal(directDeposit?.dedAmount ?? 0)}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            e.target.value = formatDecimal(
              parseFloat(e?.target?.value),
            ) as string;
          }}
          onChange={() => {
            updateDirtyState?.(true, directDeposit?.dedId ?? 0);
          }}
        />
        <SelectGrp
          {...fs.unit}
          {...getAccess(sectionAccess, 5)}
          defaultValue={watchedUnit ?? directDeposit?.unit ?? ''}
          setValue={setValue}
          control={control}
          errors={errors.unit}
          ref={register}
          options={ddUnitOpts}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            updateDirtyState?.(true, directDeposit?.dedId ?? 0);
            setValue(unitName, e.target.value);
          }}
        />
        <CheckCodeSelect
          {...fs.checkCode}
          {...getAccess(sectionAccess, 5, undefined, { readOnlyDefault: readOnly })}
          errors={errors.checkCode}
          control={control}
          value={watchedCheckCode ?? ''}
          setValue={setValue}
          options={checkCodeOpts}
          onChange={() => {
            updateDirtyState?.(true, directDeposit?.dedId ?? 0);
          }}
        />
        {/*This is so we can remove the Frequency X from the dropdown but still show it if its a skip always.*/}
        {!readOnly ? 
          <SelectGrp
            {...fs.freq}
            {...getAccess(sectionAccess, 5)}
            errors={errors.freq}
            defaultValue={directDeposit?.freq || ''}
            setValue={setValue}
            ref={register({ required: 'Frequency is Required' })}
            options={deductionFrequencyOpts.filter(x => x.id !== 'X')}
            onChange={() => {
              updateDirtyState?.(true, directDeposit?.dedId ?? 0);
            }}
          /> :
          <SelectGrp
            {...fs.freq}
            {...getAccess(sectionAccess, 5)}
            errors={errors.freq}
            defaultValue={directDeposit?.freq || ''}
            setValue={setValue}
            ref={register({ required: 'Frequency is Required' })}
            options={deductionFrequencyOpts.filter(x => x.id === 'X')}
          />
        }
        <ControlDatePickerGrp
          {...fs.dateStart}
          {...getAccess(sectionAccess, 5, undefined, { readOnlyDefault: readOnly })}
          errors={errors.dateStart}
          value={directDeposit.dateStart}
          setValue={setValue}
          control={control}
          innerChangeHandler={() => {
            if (getAccess(sectionAccess, 5, undefined, { readOnlyDefault: readOnly })?.readOnly ?? false) return;
            updateDirtyState?.(true, directDeposit?.dedId ?? 0);
          }}
        />
        <ControlDatePickerGrp
          {...fs.dateExpire}
          {...getAccess(sectionAccess, 5, undefined, { readOnlyDefault: readOnly })}
          errors={errors.dateExpire}
          value={directDeposit.dateExpire}
          setValue={setValue}
          innerChangeHandler={() => {
            if (getAccess(sectionAccess, 5, undefined, { readOnlyDefault: readOnly })?.readOnly ?? false) return;
            updateDirtyState?.(true, directDeposit?.dedId ?? 0);
          }}
          rules={{
            validate: (value: Date) => {
              const startDate = new Date(getValues(dateStartName));
              const startDateTime = startDate
                ? startDate.getTime()
                : null;
              const endDateTime = value
                ? new Date(value).getTime()
                : null;
              return (
                startDateTime &&
                endDateTime &&
                startDateTime < endDateTime
              );
            },
          }}
          control={control}
        />
      </div>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
      >
        <InputGrp
          {...fs.routingNo}
          {...getAccess(sectionAccess, 5, undefined, { readOnlyDefault: isCheckCalculator && (directDeposit?.dedId ?? 0) > 0 || readOnly })}
          readOnly={directDeposit.deductionType?.toLowerCase() === 'genericeft'}
          errors={errors?.directDepositItems?.[index]?.routingNo}
          defaultValue={directDeposit.routingNo}
          ref={register({ validate: (value: string) => {return validateRoutingNo(value);} })}
          type={'text'}
          maxLength={9}
          onChange={() => {
            updateDirtyState?.(true, directDeposit?.dedId ?? 0);
          }}
        />
        <InputGrp
          {...fs.bankAcctNo}
          {...getAccess(sectionAccess, 5)}
          errors={errors?.directDepositItems?.[index]?.bankAcctNo}
          readOnly={directDeposit.deductionType?.toLowerCase() === 'genericeft'}
          defaultValue={directDeposit.bankAcctNo}
          ref={register({
            validate: (value: any) => {
              const routingNo = getValues(routingNoName);
              if (!routingNo) return true;
              const res = !!(value && routingNo);
              return res;
            },
          })}
          disabled={isCheckCalculator && (directDeposit?.dedId ?? 0) >= 0}
          onChange={() => {
            updateDirtyState?.(true, directDeposit?.dedId ?? 0);
          }}
        />
        <InputGrp
          {...fs.financialInstitution}
          {...getAccess(sectionAccess, 5)}
          errors={errors.financialInstitution}
          defaultValue={directDeposit?.financialInstitution || ''}
          setValue={setValue}
          ref={register}
          onChange={() => {
            updateDirtyState?.(true, directDeposit?.dedId ?? 0);
          }}
        />
      </div>
      <div className="d-flex flex-wrap">
        <InputGrp
          {...fs.entityDesc}
          {...getAccess(sectionAccess, 5)}
          errors={errors.entityDesc}
          defaultValue={directDeposit?.entityDesc || ''}
          ref={register({
            maxLength: {
              value: 100,
              message: 'Maximum length is 100 characters',
            },
          })}
          onChange={() => {
            updateDirtyState?.(true, directDeposit?.dedId ?? 0);
          }}
        />
      </div>
      <div className="row">
        <div className="col-12 text-right">
          <button
            {...getAccess(sectionAccess, 5, undefined, { disabledSameAsReadOnly: true })}
            type="button"
            className="btn btn-link dm-grid-action-title mr-4"
            onClick={() => {
              return onDelete(directDeposit as Deduction);
            }}
          >
            Delete this Direct Deposit
            <Icon
              name="minus-circle"
              className="fa-minus-circle"
            />
          </button>
        </div>
      </div>
    </>
  );
};

export default DirectDepositItem;
