import { createSelector } from 'reselect';
import { GlobalDeduction, GlobalDeductionEmployee } from '../../models';
import { globalDeductionState } from '../reducers';
import { State as GlobalDeductionState } from '../reducers/global-deduction.reducer';

export const getGlobalDeductions = createSelector(
  globalDeductionState,
  (state: GlobalDeductionState) => {return state.globalDeductions;},
);

export const getGlobalDeductionsForGrid = createSelector(
  globalDeductionState,
  (state: GlobalDeductionState) => {
    return state.globalDeductions.map((d: GlobalDeduction) => {
      return {
        ...d,
        employees: d.employees.map((e: GlobalDeductionEmployee) => {return { ...e, selected: false };}),
        newAmount: 0,
        newFrequency: '',
        newUnit: '',
        modified: false,
      };
    });
  },
);