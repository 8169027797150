import { createSelector } from 'reselect';
import { convToFormObject } from '../../../utilities/classUtils';
import { AttendanceOnDemand } from '../../models';
import { attendanceOnDemandState } from '../reducers';
import { State as AODState } from '../reducers/attendance-on-demand.reducer';


export const getAOD = createSelector(
  attendanceOnDemandState,
  (state: AODState) => {
    if (!state?.attendanceOnDemand) return null;
    return convToFormObject(state.attendanceOnDemand, AttendanceOnDemand.convProps) as AttendanceOnDemand;
  });

export const getClientAOD = createSelector(
  attendanceOnDemandState,
  (state: AODState) => {return state.clientAttendanceOnDemand;},
);
