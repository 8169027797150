import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccrualTimeOffSummary, Employee, TimeOffRequest } from 'core/models';
import {
  loadAccrualTimeOffSummary,
  loadTimeOffRequests,
} from 'core/store/actions';
import {
  getAccrualTimeOffSummary,
  getTimeOffRequests,
} from 'core/store/selectors';
import TimeOffRequestComponent from '../../timeoff/TimeOffRequestComponent';
import Modal from 'core/components/modals/Modal';

type PropTypes = {
  selectedEmp: Employee | null;
  show: boolean;
  onHide: () => void;
};

const ApprovedScheduledRequestsModal: React.FC<PropTypes> = ({
  selectedEmp,
  show,
  onHide,
}) => {
  const dispatch = useDispatch();
  const timeOffSummary = useSelector(getAccrualTimeOffSummary);
  const timeOffRequests = useSelector(getTimeOffRequests);

  const [requestState, setRequestState] = useState<TimeOffRequest[]>([]);

  useEffect(() => {
    if (selectedEmp) {
      dispatch(loadAccrualTimeOffSummary(selectedEmp.protectedEmpNo));
      dispatch(loadTimeOffRequests(selectedEmp.protectedEmpNo));
    }
  }, [selectedEmp]);

  useEffect(() => {
    if (timeOffRequests) {
      setRequestState(timeOffRequests);
    }
  }, [timeOffRequests]);

  const update = (tor: TimeOffRequest) => {
    const temp = structuredClone(requestState);
    const updatedTor = temp.find(
      (request) => { return request.timeOffRequestId === tor.timeOffRequestId; },
    );

    if (!updatedTor) return;

    updatedTor.details[0].taken = true;
    Object.assign(temp, updatedTor);
    setRequestState(temp);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      title="Approved/Scheduled Requests"
    >
      <div className="d-flex flex-row flex-wrap">
        <table className="table table-bordered table-sm table-hover w-100">
          <colgroup>
            <col style={{ width: '20%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>Description</th>
              <th>Beginning Balance</th>
              <th>Earned</th>
              <th>Taken</th>
              <th>Approved</th>
              <th>Balance</th>
              <th>Rollover</th>
            </tr>
          </thead>
          <tbody>
            {timeOffSummary.map(
              (tos: AccrualTimeOffSummary, index) => {
                return (
                  <tr key={index}>
                    <td>{tos.shortDescription}</td>
                    <td className="text-right">
                      {tos.beginBalance.toFixed(4)}
                    </td>
                    <td className="text-right">
                      {tos.earnedAmount.toFixed(4)}
                    </td>
                    <td className="text-right">
                      {tos.takenAmount.toFixed(4)}
                    </td>
                    <td className="text-right">
                      {tos.approvedAmount.toFixed(4)}
                    </td>
                    <td className="text-right">
                      {tos.balance.toFixed(4)}
                    </td>
                    <td>{tos.rollOverDate}</td>
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </div>


      {(requestState && requestState.length)
        ? requestState.map((tor => <TimeOffRequestComponent key={tor.timeOffRequestId} tor={tor} update={update} />))
        : null
      }
    </Modal>

  );
};

export default ApprovedScheduledRequestsModal;
