import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class AccrualEarningsCode {
  clientNo = 0;

  earningsCode = '';

  shortDescription = '';

  accrualEarnCodeID = 0;

  clientID = 0;

  earningsCodeID = 0;

  clientTracks = false;

  clientTracksWithAccrualEarnCodeID: number | null = null;

  showDetailOnCheck = 0;

  showOnAccrualGrid = false;

  showCurrentOrAccumulative = false;

  modifyDate: Date | string | null = null;

  modifyUser = '';

  rollOverFrequency = 0;

  rollOverDate: Date | string | null = null;

  useCalendarDate = false;

  calendareDateToUse: number | null = null;

  calculationFreq: number | null = null;

  calculatePRFreq = '';

  useLTD = false;

  beginAsOfDate: Date | string | null = null;

  isTestClient = false;

  gracePeriodEvalByDate = 0;

  gracePeriod = 0;

  gracePeriodEvalBy = '';

  gracePeriodPriorTo: Date | string | null = null;

  showForLocations = '';

  showForDepartments = '';

  showForSubDepartments = '';

  showForSubDepartment2s = '';

  gracePeriodBeginBalEvalBy = '';

  gracePeriodBeginBalance = 0;

  static readonly convProps: PropTypeLists = {
    dateProps: ['modifyDate', 'beginAsOfDate', 'rollOverDate', 'beginAsOfDate', 'gracePeriodPriorTo'],
    numberProps: ['clientNo', 'accrualEarnCodeID', 'clientID', 'earningsCodeID', 'clientTracksWithAccrualEarnCodeID',
      'showDetailOnCheck', 'rollOverFrequency', 'calendareDateToUse', 'calculationFreq', 'gracePeriodEvalByDate', 'gracePeriod'],
    floatProps: ['gracePeriodBeginBalance'],
    boolProps: ['clientTracks', 'showOnAccrualGrid', 'showCurrentOrAccumulative', 'useCalendarDate', 'useLTD',
      'ssTestClient'],
  };

  constructor(
    clientNo: number | string,
    empNo: number | string,
    balanceId: number | string,
    recordId: number | string,

    props?: { [key: string]: any },
  ) {
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;

    props && convToClass(this, props, AccrualEarningsCode.convProps);
  }
}