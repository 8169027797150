import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class OtherDate {
  empID = 0;

  otherDateID = 0;

  clientNo = 0;

  empNo = 0;

  protectedEmpNo = '';

  date: Date | string | null = null;

  otherDateDescriptionID = 0;

  static readonly convProps: PropTypeLists = {
    dateProps: ['date'],
    numberProps: ['empID', 'otherDateID', 'clientNo', 'empNo', 'otherDateDescriptionID'],
  };

  constructor(
    empID: number,
    clientNo: number | string,
    empNo: string | number,
    protectedEmpNo: string,
    props?: { [key: string]: any }) {

    this.empID = (typeof empID === 'string') ? parseInt(empID) : empID;
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;
    props && convToClass(this, props, OtherDate.convProps);
  }
}