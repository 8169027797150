import { AgGridReact } from '@ag-grid-community/react';
import {
  GridApi,
  ColDef,
  GridOptions,
  RowNode,
  RowSelectedEvent,
  RowClassParams,
} from '@ag-grid-enterprise/all-modules';
import React, { useImperativeHandle, useEffect, useState, CSSProperties, useRef, MutableRefObject } from 'react';
import DateObject from 'react-date-object';
import { useForm } from 'react-hook-form';
import Calendar from 'react-multi-date-picker';
import { useSelector } from 'react-redux';
import { agDateRenderer } from '../../../utilities/ag-grid-renderers';
import { useAppDispatch } from '../../../utilities/hooks';
import { DtoOptions } from '../../models';
import { loadPayroll } from '../../store/actions';
import { getPayrollReportDates } from '../../store/selectors';
import { RadioGrp, RadioOptions } from './RadioGrp';

const dateSelectOpts: RadioOptions[] = [
  {
    value: 'weekEnd',
    label: 'By Week Ending',
  },
  {
    value: 'checkDate',
    label: 'By Check Date',
  },
];

type PropTypes = {
  returnDates: (dates: DateObject[]) => void;
  toggleMenuRef: MutableRefObject<HTMLInputElement | null>;
  position?: string;
};

const gridWrapper: CSSProperties = { height: '400px',
  width: '320px' };

const QuickSelectPlugin: React.FC<PropTypes> = ({ returnDates, toggleMenuRef }) => {
  const onClick = (durationType: string) => {
    let startDate = new DateObject(new Date());
    let endDate = new DateObject(new Date());
    switch (durationType) {
      case 'NY':
        startDate = startDate.add(1, 'year').toFirstOfYear();
        endDate = endDate.add(1, 'year').toLastOfYear();
        break;
      case 'NM':
        startDate = startDate.add(1, 'month').toFirstOfMonth();
        endDate = endDate.add(1, 'month').toLastOfMonth();
        break;
      case 'TW':
        startDate = startDate.toFirstOfWeek();
        endDate = endDate.toLastOfWeek();
        break;
      case 'LW':
        startDate = startDate.subtract(7, 'days').toFirstOfWeek();
        endDate = endDate.subtract(7, 'days').toLastOfWeek();
        break;
      case 'TM':
        startDate = startDate.toFirstOfMonth();
        endDate = endDate.toLastOfMonth();
        break;
      case 'LM':
        startDate = startDate.subtract(1, 'month').toFirstOfMonth();
        endDate = endDate.subtract(1, 'month').toLastOfMonth();
        break;
      case 'L30':
        startDate = startDate.subtract(30, 'days');
        break;
      case 'L60':
        startDate = startDate.subtract(60, 'days');
        break;
      case 'L90':
        startDate = startDate.subtract(90, 'days');
        break;
      case 'L12':
        startDate = startDate.subtract(12, 'months');
        break;
      case 'TY':
        startDate = startDate.toFirstOfYear();
        endDate = endDate.toLastOfYear();
        break;
      case 'LY':
        startDate = startDate.subtract(1, 'year').toFirstOfYear();
        endDate = endDate.subtract(1, 'year').toLastOfYear();
        break;
    }
    returnDates([startDate, endDate]);
    toggleMenuRef?.current?.click();
  };

  return (
    <div className="d-flex flex-column">
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('TW'); }}
        >
          <b>This Week</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('LW'); }}
        >
          <b>Last Week</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('TM'); }}
        >
          <b>This Month</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('LM'); }}
        >
          <b>Last Month</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('NM'); }}
        >
          <b>Next Month</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('L30'); }}
        >
          <b>Last 30 days</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('L60'); }}
        >
          <b>Last 60 days</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('L90'); }}
        >
          <b>Last 90 days</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('L12'); }}
        >
          <b>Last 12 mths</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('TY'); }}
        >
          <b>This Year</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('LY'); }}
        >
          <b>Last Year</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('NY'); }}
        >
          <b>Next Year</b>
        </button>
      </div>
    </div>
  );
};

type SelectorPropTypes = {
  returnDates: (dates: DtoOptions[]) => void;
  disableGrid: boolean;
  disableAutoDateSelect?: boolean;
  isSingleSelect?: boolean;
  passDataFromInputDateSelector: (data: string) => void;
  hideSelectAllBtn?: boolean;
  greenHighlights?: boolean;
  showOpenDatelines?: boolean;
};

const InputReportDateSelector: React.FC<SelectorPropTypes | any> =
  React.forwardRef(
    (
      {
        returnDates,
        passDataFromInputDateSelector,
        disableGrid,
        hideSelectAllBtn,
        disableAutoDateSelect,
        isSingleSelect,
        greenHighlights,
        showOpenDatelines,
      }: SelectorPropTypes,
      ref: any,
    ) => {
      const dispatch = useAppDispatch();

      const dates = useSelector(getPayrollReportDates);

      const { register, setValue } = useForm({
        defaultValues: { dateType: 'checkDate' },
      });

      const [values, setValues] = useState([
        new DateObject(new Date()).toFirstOfYear(),
        new DateObject(new Date()).toLastOfYear(),
      ]);

      const [selectedOption, setSelectedOption] = useState<string>('checkDate');
      const [selectAll, setSelectAll] = useState<boolean>(false);

      const [gridApi, setGridApi] = useState<GridApi | null>(null);
      const [rowData, setRowData] = useState<DtoOptions[]>([]);
      const [isCancel, setIsCancel] = useState<boolean>(false);
      const [shouldClose, setShouldClose] = useState<boolean>(true);
    
      const toggleMenuRef = useRef<HTMLInputElement | null>(null);
      
      const columns: ColDef[] = [
        {
          field: 'select',
          headerName: '',
          checkboxSelection: true,
          headerCheckboxSelection: !hideSelectAllBtn,
          width: 40,
        },
        {
          field: 'weekEnd',
          headerName: 'Week End',
          cellRenderer: 'dateRenderer',
          sortable: true,
          suppressMenu: false,
          editable: false,
          width: 130,
        },
        {
          field: 'checkDate',
          headerName: 'Check Date',
          cellRenderer: 'dateRenderer',
          sortable: true,
          suppressMenu: false,
          editable: false,
          width: 130,
        },
      ];

      const getRowStyle = (params: RowClassParams) => {
        if (greenHighlights && params.data.status === 'O') {
          return { 'background-color': '#C8EDB5' };
        }
      };

      const gridOptions: GridOptions = {
        columnDefs: columns,
        rowMultiSelectWithClick: true,
        suppressCellFocus: true,
        defaultColDef: {
          suppressMenu: true,
          resizable: true,
          singleClickEdit: true,
          cellClass: 'ag-cell-left-border',
          headerClass: 'grid-header',
        },
        components: {
          dateRenderer: agDateRenderer,
        },
      };

      useEffect(() => {
        if (values && values?.length > 1 && !isCancel) {
          dispatch(
            loadPayroll({
              beginDate: values?.[0].format('MM/DD/YYYY'),
              endDate: values[1].format('MM/DD/YYYY'),
              byCheckDate:
                selectedOption === 'weekEnd' ? false : true,
            }),
          );
          const cds = values.map((d) => { return d.format('MM/DD/YYYY'); });
          const selectedDates: DtoOptions[] = [];
          if (gridApi) {
            const selectedRows = gridApi.getSelectedRows();
            if (selectedRows.length > 0) {
              selectedRows.forEach(() => {
                gridApi.forEachNode((node: RowNode) => {
                  const date =
                    selectedOption === 'weekEnd'
                      ? node.data.weekEnd
                      : node.data.checkDate;
                  if (
                    new Date(date) >= new Date(cds[0]) &&
                    new Date(date) <= new Date(cds[1])
                  ) {
                    node.setSelected(true);
                    selectedDates.push({
                      weekEnd: node.data.weekEnd,
                      checkDate: node.data.checkDate,
                      payrollHistoryId:
                        node.data.payrollHistoryId,
                    });
                  } else {
                    node.setSelected(false);
                  }
                });
              });
            }
            returnDates(selectedDates);
          }
        } else {
          setIsCancel(false);
        }
      }, [values]);

      useEffect(() => {
        const cds = values?.map((d) => { return d.format('MM/DD/YYYY'); });
        const filteredDates = dates
          .filter((d: DtoOptions) => {
            const date =
              selectedOption === 'weekEnd' ? d.weekEnd : d.checkDate;
            if (showOpenDatelines) {
              return new Date(date) >= new Date(cds[0]) && new Date(date) <= new Date(cds[1]);
            } else {
              return (d?.status === 'F' && new Date(date) >= new Date(cds[0]) && new Date(date) <= new Date(cds[1]));
            }
          });
        
        if (filteredDates) {
          filteredDates.sort((a, b) => {
            if (a.checkDate > b.checkDate) return -1;
            if (a.checkDate < b.checkDate) return 1;
            return 0;
          });
            
          setRowData(filteredDates);
        }
      }, [dates]);

      useEffect(() => {
        selectedOption && setValues([...values]);
      }, [selectedOption]);

      useEffect(() => {
        disableAutoDateSelect &&
          gridApi?.forEachNode((node: RowNode) => {
            node.setSelected(false);
          });
      }, [disableAutoDateSelect]);

      useImperativeHandle(ref, () => {
        return {
          onCancel() {
            setIsCancel(true);

            gridApi?.forEachNode((node: RowNode) => {
              node.setSelected(false);
            });

            setValue('dateType', 'checkDate');

            setValues([
              new DateObject(new Date()).toFirstOfYear(),
              new DateObject(new Date()).toLastOfYear(),
            ]);

            dispatch(
              loadPayroll({
                beginDate: new DateObject(new Date())
                  .toFirstOfYear()
                  .format('MM/DD/YYYY'),
                endDate: new DateObject(new Date())
                  .toLastOfYear()
                  .format('MM/DD/YYYY'),
                byCheckDate:
                  selectedOption === 'weekEnd' ? false : true,
              }),
            );
          },
        };
      });

      const onGridReady = (params: any) => {
        setGridApi(params.api);
      };

      const onCalendarChange = (selectedDates: DateObject[]) => {
        setValues(selectedDates);
      };

      const onSelectionChanged = (row: RowSelectedEvent) => {
        const selectedDates: DtoOptions[] = [];
        const rd = row.data;
        const isSelected = row.node.isSelected();
        if (gridApi) {
          if (
            disableAutoDateSelect === false &&
            isSingleSelect === false
          ) {
            gridApi.forEachNode((node: RowNode) => {
              const nd = node.data;
              const nodeDate =
                selectedOption === 'weekEnd'
                  ? nd.weekEnd
                  : nd.checkDate;
              const rowDate =
                selectedOption === 'weekEnd'
                  ? rd.weekEnd
                  : rd.checkDate;
              if (nodeDate === rowDate) {
                isSelected && node.setSelected(isSelected);
              }
            });
          }

          gridApi.getSelectedRows().map((r) => {
            return selectedDates.push({
              weekEnd: r.weekEnd,
              checkDate: r.checkDate,
              payrollHistoryId: r.payrollHistoryId,
            });
          },
          );
          
          setSelectAll(selectedDates.length === rowData.length);
          
          returnDates(selectedDates);
        }
      };
      
      const toggleSelectAll = (shouldSelectAll: boolean) => {
        if (!shouldSelectAll) {
          const selectedDates: DtoOptions[] = [];
          gridApi?.forEachNode((node: RowNode) => {
            node.setSelected(true);
            selectedDates.push({
              weekEnd: node.data.weekEnd,
              checkDate: node.data.checkDate,
              payrollHistoryId: node.data.payrollHistoryId,
            });
          });
          returnDates(selectedDates);
        } else {
          gridApi?.forEachNode((node: RowNode) => {
            node.setSelected(false);
          });
          returnDates([]);
        }
        
        setSelectAll(!selectAll);
      };
      
      return (
        <div className="d-flex flex-column mr-3">
          <div className="form-label mt-1">Selected Date Range</div>
          <input
            type="checkbox"
            ref={toggleMenuRef}
            style={{ display: 'none' }}
            onClick={() => { setShouldClose(true); }}
          />
          <Calendar
            value={values}
            numberOfMonths={2}
            format={'MM/DD/YYYY'}
            style={{ width: '55%' }}
            onChange={(selectedDates: DateObject[]) => {
              onCalendarChange(selectedDates);
            }}
            range
            plugins={[
              <QuickSelectPlugin
                key={'quick-select-plugin'}
                returnDates={(dates) => { return onCalendarChange(dates); }}
                position="left"
                toggleMenuRef={toggleMenuRef}
              />,
            ]}
          />
          <RadioGrp
            groupClass="mw420"
            name="dateType"
            ref={register}
            radioOptions={dateSelectOpts}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement>,
            ) => {
              setSelectedOption(event.target.value);
              if (passDataFromInputDateSelector) passDataFromInputDateSelector(event.target.value);
            }}
          />
          <div
            className={
              'ag-theme-balham mt-1 mb-2 ' +
              (disableGrid ? 'disable-grid' : ' ')
            }
          >
            <div className="form-label">
              Select date groupings to report on.
            </div>
            <div style={gridWrapper}>
              <AgGridReact
                onGridReady={onGridReady}
                rowData={rowData}
                columnDefs={columns}
                gridOptions={gridOptions}
                rowSelection={
                  isSingleSelect ? 'single' : 'multiple'
                }
                getRowStyle={getRowStyle}
                onRowSelected={(e) => { return onSelectionChanged(e); }}
              />
            </div>
            <button
              type="button"
              className="btn-sm orange-button mt-2"
              onClick={() => { toggleSelectAll(selectAll); }}
              hidden={hideSelectAllBtn}
            >
              {selectAll ? 'Deselect' : 'Select'} All Weeks
            </button>
          </div>
        </div>
      );
    },
  );

InputReportDateSelector.displayName = 'InputReportDateSelector';

export default InputReportDateSelector;
