import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpResponse } from '../../models';
import { cmUsersService } from '../../services/cmUsers.service';
import { handleError, handleSuccess, putUserDefaultClient, storeUserDefaultClient } from '../actions';
import { getHR360Users, getUsers, putHR360User, storeHR360Users, storeUsers } from '../slices/cmUsers.slice';
import { UserAccessHR360, UserDefaultClient } from 'core/models/UserAccess';

interface Actions<T> {
  type: string;
  payload: T;
}

const getUsers$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getUsers.type),
    switchMap(() => {
      return from(cmUsersService.getUsers()).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeUsers(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getHR360Users$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getHR360Users.type),
    switchMap(() => {
      return from(cmUsersService.getHR360Users()).pipe(
        map((res: any) => { return res.data; }),
        map((res: UserAccessHR360[]) => { return storeHR360Users(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postHR360User$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(putHR360User.type),
    switchMap((action: { payload: UserAccessHR360; }) => {
      return from(cmUsersService.putHR360User(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getHR360Users(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
          ];
        }),
      );
    },
    ),
  );
};

const putUserDefaultClient$ = (action$: Observable<Actions<UserDefaultClient>>) => 
  action$.pipe(
    ofType(putUserDefaultClient.type),
    switchMap((action: { payload: UserDefaultClient; }) => 
      from(cmUsersService.putUserDefaultClient(action.payload)).pipe(
        map((res) => res.data),
        mergeMap((res) => {
          return [
            storeUserDefaultClient(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err) => {
          return [handleError(err)];
        }),
      ),
    ),
  );

export const epics: any[] = [
  getUsers$,
  getHR360Users$,
  postHR360User$,
  putUserDefaultClient$,
];
