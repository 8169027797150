import { AxiosResponse } from "axios";
import { HttpResponse } from '../models';
import {
  EmpHistory,
  HireGeneral,
  HireGeneralRequest,
  HireInfoCustomField,
  HRDependent,
  HRDependentRequest,
  HREducation,
  HREducationRequest,
  HREmployment,
  HREmploymentRequest,
  HRExitInterview,
  HRExitInterviewRequest,
  HRIncrease,
  HRIncreaseRequest,
  HRLicense,
  HRLicenseRequest,
  HRPerformanceReview,
  HRReviewRequest,
  HRSkills,
  HRSkillsRequest,
  HRSource,
  HRSourceRequest,
  HRTrainingEvent,
  HRTrainingEventRequest,
  HRVaccination,
  HRVaccinationFileRequest,
  HRVaccinationReportRequest,
  HRVaccinationRequest,
} from '../models/HREmployee.model';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints, ReportsEndpoints } from './URL';

const getEmployeeFieldHistory = (empNo: string, section: string, field: string): Promise<AxiosResponse<EmpHistory>> => {return API_EMPLOYEE_MASTER.get<EmpHistory>(EmployeeEndPoints.EMPLOYEES_HR_FIELD_HISTORY(CommonService.getProtectedClientNo(), empNo, section, field));};

const getHireGeneral = (empNo: string): Promise<AxiosResponse<HireGeneral>> => {return API_EMPLOYEE_MASTER.get<HireGeneral>(EmployeeEndPoints.EMPLOYEES_HR_HIRE_GENERAL(CommonService.getProtectedClientNo(), empNo));};

const putHireGeneral = (request: HireGeneralRequest): Promise<AxiosResponse<HttpResponse<HireGeneral>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<HireGeneral>>(EmployeeEndPoints.EMPLOYEES_HR_HIRE_GENERAL(CommonService.getProtectedClientNo(), request.protectedEmpNo), request.data);};


const getHireInfoCustomFields = (): Promise<AxiosResponse<HireInfoCustomField[]>> => {return API_EMPLOYEE_MASTER.get<HireInfoCustomField[]>(EmployeeEndPoints.HIRE_INFO_CUSTOM_FIELDS(CommonService.getProtectedClientNo()));};

const putHireInfoCustomFields = (request: HireInfoCustomField[]): Promise<AxiosResponse<HttpResponse<HireInfoCustomField[]>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<HireInfoCustomField[]>>(EmployeeEndPoints.HIRE_INFO_CUSTOM_FIELDS(CommonService.getProtectedClientNo()), request);};


const getEmployment = (empNo: string): Promise<AxiosResponse<HREmployment[]>> => {
  return API_EMPLOYEE_MASTER.get<HREmployment[]>(EmployeeEndPoints.HR_HIRE_EMPLOYMENT(CommonService.getProtectedClientNo(), empNo));
};

const postEmployment = (request: HREmploymentRequest): Promise<AxiosResponse<HttpResponse<HREmployment>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<HREmployment>>(
    EmployeeEndPoints.HR_HIRE_EMPLOYMENT(
      CommonService.getProtectedClientNo(), request.protectedEmpNo,
    ), request.data);
};

const putEmployment = (request: HREmploymentRequest): Promise<AxiosResponse<HttpResponse<HREmployment>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<HREmployment>>(EmployeeEndPoints.HR_HIRE_EMPLOYMENT_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data?.employmentId), request.data);};

const deleteEmployment = (request: HREmploymentRequest): Promise<AxiosResponse<any>> => {return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.HR_HIRE_EMPLOYMENT_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data?.employmentId));};


const getEducation = (empNo: string): Promise<AxiosResponse<HREducation[]>> => {
  return API_EMPLOYEE_MASTER.get<HREducation[]>(EmployeeEndPoints.HR_HIRE_EDUCATION(CommonService.getProtectedClientNo(), empNo));
};

const postEducation = (request: HREducationRequest): Promise<AxiosResponse<HttpResponse<HREducation>>> => {return API_EMPLOYEE_MASTER.post<HttpResponse<HREducation>>(EmployeeEndPoints.HR_HIRE_EDUCATION(CommonService.getProtectedClientNo(), request.protectedEmpNo), request.data);};

const putEducation = (request: HREducationRequest): Promise<AxiosResponse<HttpResponse<HREducation>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<HREducation>>(EmployeeEndPoints.HR_HIRE_EDUCATION_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data?.educationId), request.data);};

const deleteEducation = (request: HREducationRequest): Promise<AxiosResponse<any>> => {return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.HR_HIRE_EDUCATION_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data?.educationId));};


const getLicense = (empNo: string): Promise<AxiosResponse<HRLicense[]>> => {return API_EMPLOYEE_MASTER.get<HRLicense[]>(EmployeeEndPoints.HR_HIRE_LICENSE(CommonService.getProtectedClientNo(), empNo));};

const postLicense = (request: HRLicenseRequest): Promise<AxiosResponse<HttpResponse<HRLicense>>> => {return API_EMPLOYEE_MASTER.post<HttpResponse<HRLicense>>(EmployeeEndPoints.HR_HIRE_LICENSE(CommonService.getProtectedClientNo(), request.protectedEmpNo), request.data);};

const putLicense = (request: HRLicenseRequest): Promise<AxiosResponse<HttpResponse<HRLicense>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<HRLicense>>(EmployeeEndPoints.HR_HIRE_LICENSE_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data.empLicenseId), request.data);};

const deleteLicense = (request: HRLicenseRequest): Promise<AxiosResponse<any>> => {return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.HR_HIRE_LICENSE_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data.empLicenseId));};


const getTraining = (empNo: string): Promise<AxiosResponse<HRTrainingEvent[]>> => {return API_EMPLOYEE_MASTER.get<HRTrainingEvent[]>(EmployeeEndPoints.HR_HIRE_TRAINING(CommonService.getProtectedClientNo(), empNo));};

const postTraining = (request: HRTrainingEventRequest): Promise<AxiosResponse<HttpResponse<HRTrainingEvent>>> => {return API_EMPLOYEE_MASTER.post<HttpResponse<HRTrainingEvent>>(EmployeeEndPoints.HR_HIRE_TRAINING(CommonService.getProtectedClientNo(), request.protectedEmpNo), request.data);};

const putTraining = (request: HRTrainingEventRequest): Promise<AxiosResponse<HttpResponse<HRTrainingEvent>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<HRTrainingEvent>>(EmployeeEndPoints.HR_HIRE_TRAINING_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data.empTrainingId), request.data);};

const deleteTraining = (request: HRTrainingEventRequest): Promise<AxiosResponse<any>> => {return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.HR_HIRE_TRAINING_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data.empTrainingId));};

const getSkills = (empNo: string): Promise<AxiosResponse<HRSkills[]>> => {return API_EMPLOYEE_MASTER.get<HRSkills[]>(EmployeeEndPoints.HR_HIRE_SKILLS(CommonService.getProtectedClientNo(), empNo));};

const postSkills = (request: HRSkillsRequest): Promise<AxiosResponse<HttpResponse<HRSkills>>> => {return API_EMPLOYEE_MASTER.post<HttpResponse<HRSkills>>(EmployeeEndPoints.HR_HIRE_SKILLS(CommonService.getProtectedClientNo(), request.protectedEmpNo), request.data);};

const putSkills = (request: HRSkillsRequest): Promise<AxiosResponse<HttpResponse<HRSkills>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<HRSkills>>(EmployeeEndPoints.HR_HIRE_SKILLS_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data.empSkillId), request.data);};

const deleteSkills = (request: HRSkillsRequest): Promise<AxiosResponse<any>> => {return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.HR_HIRE_SKILLS_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data.empSkillId));};

const getSource = (empNo: string): Promise<AxiosResponse<HRSource[]>> => {return API_EMPLOYEE_MASTER.get<HRSource[]>(EmployeeEndPoints.HR_HIRE_SOURCE(CommonService.getProtectedClientNo(), empNo));};

const postSource = (request: HRSourceRequest): Promise<AxiosResponse<HttpResponse<HRSource>>> => {return API_EMPLOYEE_MASTER.post<HttpResponse<HRSource>>(EmployeeEndPoints.HR_HIRE_SOURCE(CommonService.getProtectedClientNo(), request.protectedEmpNo), request.data);};

const putSource = (request: HRSourceRequest): Promise<AxiosResponse<HttpResponse<HRSource>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<HRSource>>(EmployeeEndPoints.HR_HIRE_SOURCE_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data.empSourceFeeId), request.data);};

const deleteSource = (request: HRSourceRequest): Promise<AxiosResponse<any>> => {return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.HR_HIRE_SOURCE_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data.empSourceFeeId));};

const getExitInterview = (empNo: string): Promise<AxiosResponse<HRExitInterview>> => {return API_EMPLOYEE_MASTER.get<HRExitInterview>(EmployeeEndPoints.HR_EXIT_INTERVIEW(CommonService.getProtectedClientNo(), empNo));};

const postExitInterview = (request: HRExitInterviewRequest): Promise<AxiosResponse<HttpResponse<HRExitInterview>>> => {return API_EMPLOYEE_MASTER.post<HttpResponse<HRExitInterview>>(EmployeeEndPoints.HR_EXIT_INTERVIEW(CommonService.getProtectedClientNo(), request.protectedEmpNo), request.data);};

const putExitInterview = (request: HRExitInterviewRequest): Promise<AxiosResponse<HttpResponse<HRExitInterview>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<HRExitInterview>>(EmployeeEndPoints.HR_EXIT_INTERVIEW_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data.empExitInterviewId), request.data);};

const getIncreases = (empNo: string): Promise<AxiosResponse<HRIncrease>> => {return API_EMPLOYEE_MASTER.get<HRIncrease>(EmployeeEndPoints.HR_INCREASE(CommonService.getProtectedClientNo(), empNo));};

const putIncrease = (request: HRIncreaseRequest): Promise<AxiosResponse<HttpResponse<HRIncrease>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<HRIncrease>>(EmployeeEndPoints.HR_INCREASE(CommonService.getProtectedClientNo(), request.protectedEmpNo), request.data);};

const getReview = (empNo: string): Promise<AxiosResponse<HRPerformanceReview>> => {return API_EMPLOYEE_MASTER.get<HRPerformanceReview>(EmployeeEndPoints.HR_REVIEW(CommonService.getProtectedClientNo(), empNo));};

const postReview = (request: HRReviewRequest): Promise<AxiosResponse<HttpResponse<HRPerformanceReview>>> => {return API_EMPLOYEE_MASTER.post<HttpResponse<HRPerformanceReview>>(EmployeeEndPoints.HR_REVIEW(CommonService.getProtectedClientNo(), request.protectedEmpNo), request.data);};

const putReview = (request: HRReviewRequest): Promise<AxiosResponse<HttpResponse<HRPerformanceReview>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<HRPerformanceReview>>(EmployeeEndPoints.HR_REVIEW(CommonService.getProtectedClientNo(), request.protectedEmpNo), request.data);};

const getDependent = (empNo: string): Promise<AxiosResponse<HRDependent[]>> => {return API_EMPLOYEE_MASTER.get<HRDependent[]>(EmployeeEndPoints.HR_DEPENDENT(CommonService.getProtectedClientNo(), empNo));};

const postDependent = (request: HRDependentRequest): Promise<AxiosResponse<HttpResponse<HRDependent>>> => {return API_EMPLOYEE_MASTER.post<HttpResponse<HRDependent>>(EmployeeEndPoints.HR_DEPENDENT(CommonService.getProtectedClientNo(), request.protectedEmpNo), request.data);};

const putDependent = (request: HRDependentRequest): Promise<AxiosResponse<HttpResponse<HRDependent>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<HRDependent>>(EmployeeEndPoints.HR_DEPENDENT_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data.contactId), request.data);};

const deleteDependent = (request: HRDependentRequest): Promise<AxiosResponse<any>> => {return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.HR_DEPENDENT_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data.contactId));};

const getVaccination = (empNo: string): Promise<AxiosResponse<HRVaccination[]>> => {return API_EMPLOYEE_MASTER.get<HRVaccination[]>(EmployeeEndPoints.HR_VACCINATION(CommonService.getProtectedClientNo(), empNo));};

const postVaccination = (request: HRVaccinationRequest): Promise<AxiosResponse<HttpResponse<HRVaccination>>> => {return API_EMPLOYEE_MASTER.post<HttpResponse<HRVaccination>>(EmployeeEndPoints.HR_VACCINATION(CommonService.getProtectedClientNo(), request.protectedEmpNo), request.data);};

const putVaccination = (request: HRVaccinationRequest): Promise<AxiosResponse<HttpResponse<HRVaccination>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<HRVaccination>>(EmployeeEndPoints.
    HR_VACCINATION_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data.vaccinationId), request.data);
};

const deleteVaccination = (request: HRVaccinationRequest): Promise<AxiosResponse<any>> => {return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.HR_VACCINATION_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.data.vaccinationId));};

const postVaccinationFIle = (request: HRVaccinationFileRequest): Promise<AxiosResponse<HttpResponse<any>>> => {return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(EmployeeEndPoints.HR_VACCINATION_FILE(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.vaccinationId), request);};

const deleteVaccinationFile = (request: HRVaccinationFileRequest): Promise<AxiosResponse<any>> => {return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.HR_VACCINATION_FILE_ID(CommonService.getProtectedClientNo(), request.protectedEmpNo, request.vaccinationId, request.employeeFileId!));};

const postVaccinationReport = (request: HRVaccinationReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(ReportsEndpoints.HR_VACCINATION_REPORT(), request);};


export const HREmployeeService = {
  getEmployeeFieldHistory,
  getHireGeneral,
  putHireGeneral,
  getHireInfoCustomFields,
  putHireInfoCustomFields,
  getEmployment,
  postEmployment,
  putEmployment,
  deleteEmployment,
  getEducation,
  postEducation,
  putEducation,
  deleteEducation,
  getLicense,
  postLicense,
  putLicense,
  deleteLicense,
  getTraining,
  postTraining,
  putTraining,
  deleteTraining,
  getSkills,
  postSkills,
  putSkills,
  deleteSkills,
  getSource,
  postSource,
  putSource,
  deleteSource,
  getExitInterview,
  postExitInterview,
  putExitInterview,
  getReview,
  postReview,
  putReview,
  getIncreases,
  putIncrease,
  getDependent,
  postDependent,
  putDependent,
  deleteDependent,
  getVaccination,
  postVaccination,
  putVaccination,
  deleteVaccination,
  postVaccinationFIle,
  deleteVaccinationFile,
  postVaccinationReport,
};