import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi, GridOptions } from '@ag-grid-enterprise/all-modules';
import Icon from 'core/components/shared/Icon';
import React, { useEffect, useRef, useState } from 'react';
import InputReportDateSelector from '../../../core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from '../../../core/components/modals/pdf-viewer.modal';
import { DtoOptions } from '../../../core/models';
import { HoursEarningsReportRequest, OverTimeSetting } from '../../../core/models/AccountingReports.model';
import {
  clearHoursAndEarningsReport,
  downloadHoursAndEarningsReport,
  loadEarningCodesDropdown,
  loadOverTimeSettings,
} from '../../../core/store/actions';
import AGEditButtonRendererComponent from '../../../utilities/ag-grid-renderers/AGEditButtonRendererComponent';
import { useAppDispatch, useAppSelector } from '../../../utilities/hooks';
import OverTimeSettingsModal from './OverTimeSettings.modal';

const HoursEarningsAnalysisPage = () => {
  const dispatch = useAppDispatch();
  const childRef: React.RefObject<any> = useRef();

  const report = useAppSelector((state) => { return state.accountingReports.hoursEarningReport; });
  const client = useAppSelector((state) => { return state.client.client; });
  const overTimeSettings = useAppSelector((state) => { return state.accountingReports.overTimeSettings; });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [selectedSetting, setSelectedSetting] = useState<OverTimeSetting | undefined>(undefined);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [selectedOtid, setSelectedOtid] = useState<number | null>();

  useEffect(() => {
    dispatch(loadEarningCodesDropdown());
    dispatch(loadOverTimeSettings());
  }, []);

  useEffect(() => {
    selectedSetting && setShowModal(true);
  }, [selectedSetting]);

  const downloadReport = () => {
    if (reportDates && selectedOtid) {
      const submitData: HoursEarningsReportRequest = {
        clientNo: client?.clientNo!,
        dtoOptions: reportDates,
        reportType: 'pdf',
        otid: selectedOtid,
      };
      dispatch(downloadHoursAndEarningsReport(submitData));
    }
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSettingEdit = (rowData: OverTimeSetting) => {
    rowData && setSelectedSetting({ ...rowData });
  };
  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      selectedRows.length > 0 &&
        setSelectedOtid(selectedRows[0].otid);
    }
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearHoursAndEarningsReport());
  };

  const columns: ColDef[] = [
    {
      field: 'otid',
      hide: true,
    },
    {
      field: 'select',
      headerName: '',
      editable: false,
      checkboxSelection: true,
      width: 40,
    },
    {
      field: 'settingName',
      headerName: 'Setting Name',
      sortable: true,
      width: 300,
      flex: 1,
      cellStyle: {
        textAlign: 'left',
        fontSize: 14,
        color: '#00558c'
      },
    },
    {
      field: 'edit',
      editable: false,
      cellRendererParams: { clickHandler: onSettingEdit },
      cellRenderer: 'editButtonRendererComponent',
      width: 60,
    },
  ];

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    columnDefs: columns,
    rowSelection: 'single',
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    frameworkComponents: {
      editButtonRendererComponent: AGEditButtonRendererComponent,
    },
  };

  return (<div className="dm-panel dm-panel-border">
    <div className="dm-grid-title">Hours/Dollars Analysis Report</div>

    <hr className="dm-panel-hr" />

    <div className="d-flex flex-row flex-grow-1">
      <div>
        <InputReportDateSelector
          ref={childRef}
          returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }}
        />
      </div>

      <div className="flex-grow-1 text-right">
        <button
          type="button"
          className="orange-outline-button mr-2"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className="orange-button mr-2"
          onClick={downloadReport}
          disabled={(!reportDates.length || !selectedOtid)}>
          Run Report
        </button>
        <div className="flex-grow-1"
          style={{ marginTop: 66 }}>
          <div className="d-flex flex-row">
            <div className="dm-card-subtitle2 mb-2">Choose Setting Name</div>
            <div className=" flex-grow-1 text-right">
              <button className="btn btn-link dm-grid-action-title p-0 mr-2"
                type="button"
                onClick={() => {
                  setSelectedSetting({
                    usePremiumOnly: false,
                    isHours: true
                  } as OverTimeSetting); setShowModal(true);
                }}>
                Add New Setting	<Icon name="plus-circle"
                  className="fa-plus-circle" />
              </button>
            </div>
          </div>
          <div className="ag-theme-balham mt-2">
            <AgGridReact
              gridOptions={gridOptions}
              rowData={overTimeSettings}
              onGridReady={onGridReady}
              onSelectionChanged={onSelectionChanged}
            />
          </div>
        </div>
      </div>
    </div>

    {showModal ?
      <OverTimeSettingsModal
        setting={selectedSetting}
        show={showModal}
        onHide={() => { return setShowModal(false); }}
      /> : null}

    {report ? <PDFViewerModal
      show={report.length > 0}
      pdfData={report}
      onHide={hidePdfViewer}
      reportName="HoursEarningsReport"
    /> : null}
  </div>
  );
};

export default HoursEarningsAnalysisPage;