import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class AttendanceOnDemand {
  clientNo = '';

  //empNo: number = 0;
  empId = 0;

  protectedEmpNo = '';

  aodEmployeeId: number | null = 0;

  activeStatusId = 0;

  activeStatusEffectiveDate: Date | string | null = null;

  inactiveStatusId = 0;

  hourlyStatusId = 0;

  hourlyStatusEffectiveDate: Date | string | null = null;

  payClassId = 0;

  payClassEffectiveDate: Date | string | null = null;

  payTypeId = 0;

  payTypeEffectiveDate: Date | string | null = null;

  clockGroupId = 0;

  schedulePatternId = 0;

  schedulePatternEffectiveDate: Date | string | null = null;

  workgroupAssignmentDate: Date | string | null = null;

  workgroup1 = 0;

  workgroup2 = 0;

  workgroup3 = 0;

  workgroup4 = 0;

  workgroup5 = 0;

  workgroup6 = 0;

  workgroup7 = 0;

  staticField1 = '';

  staticField2 = '';

  staticField3 = '';

  staticField4 = '';

  staticField5 = '';

  staticField6 = '';


  static readonly convProps: PropTypeLists = {
    dateProps: ['activeStatusEffectiveDate', 'hourlyStatusEffectiveDate', 'payClassEffectiveDate',
      'payTypeEffectiveDate', 'schedulePatternEffectiveDate', 'workgroupAssignmentDate'],
    numberProps: ['empId', 'activeStatusId', 'inactiveStatusId', 'hourlyStatusId', 'payClassId',
      'payTypeId', 'clockGroupId', 'schedulePatternId', 'workgroup1', 'workgroup2', 'workgroup3',
      'workgroup4', 'workgroup5', 'workgroup6', 'workgroup7'],
    nullNumberProps: ['aodEmployeeId'],
  };

  constructor(
    clientNo: string,
    //empNo: number,
    protectedEmpNo: string,
    empId: number | string,
    props?: { [key: string]: any }) {

    this.clientNo = clientNo;
    //this.empNo = empNo;
    this.protectedEmpNo = protectedEmpNo;
    this.empId = (typeof empId === 'string') ? parseInt(empId) : empId;

    props && convToClass(this, props, AttendanceOnDemand.convProps);
  }

}

export interface AODDropDownItems {
  activeStatusConditions?: any[],
  clockGroups?: any[],
  hourlyStatuses?: any[],
  inactiveStatusConditions?: any[],
  payClasses?: any[],
  payTypes?: any[],
  schedulePatterns?: any[],
  workgroups?: any[],
}

export type AodDropDownItem = {
  nameLabel: string;
  num: number;
};

export type ClientAodWorkGroupDropdownItem = {
  code: string;
  expireDate: string | Date;
  level: number;
  levelName: string;
  name: string;
  num: number;
  rate: number;
};

export type ClientAodDropdowns = {
  activeStatusConditions: AodDropDownItem[];
  clockGroups: AodDropDownItem[];
  hourlyStatuses: AodDropDownItem[];
  inactiveStatusConditions: AodDropDownItem[];
  payClasses: AodDropDownItem[];
  payTypes: AodDropDownItem[];
  schedulePatterns: AodDropDownItem[];
  workgroups: ClientAodWorkGroupDropdownItem[];
};

export type ClientAod = {
  clientCode: string;
  clientId: number; clientNo: number;
  clientURL: string;
  dropdowns: ClientAodDropdowns;
  inProductionMode: boolean;
  showWorkgroup1: boolean;
  showWorkgroup2: boolean;
  showWorkgroup3: boolean;
  showWorkgroup4: boolean;
  showWorkgroup5: boolean;
  showWorkgroup6: boolean;
  showWorkgroup7: boolean;
  staticCustom1: boolean;
  staticCustom2: boolean;
  staticCustom3: boolean;
  staticCustom4: boolean;
  staticCustom5: boolean;
  staticCustom6: boolean;
  workgroup1: number;
  workgroup2: number;
  workgroup3: number;
  workgroup4: number;
  workgroup5: number;
  workgroup6: number;
  workgroup7: number;
};