import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientUser } from '../../models/User.model';
import { UserAccessHR360, UserDefaultClient } from 'core/models/UserAccess';

export interface CmUsersState {
  loading: boolean;
  users: ClientUser[];
  hr360Users: UserAccessHR360[];
}

const initialState: CmUsersState = {
  loading: false,
  users: [],
  hr360Users: [],
};

export const cmUsersSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    getUsers: (state) => {
      state.loading = true;
    },
    storeUsers: (state, action: PayloadAction<ClientUser[]>) => {
      state.loading = false;
      state.users = action.payload.map((u: ClientUser) => {
        return {
          ...u,
          fullName: `${u.lastName && u.lastName + ','}${u.firstName && u.firstName + ' - '}${u.userName}`,
        };
      });
    },
    clearUsers: (state) => {
      state.users = [];
    },
    getHR360Users: (state) => {
      state.loading = true;
    },
    storeHR360Users: (state, action: PayloadAction<UserAccessHR360[]>) => {
      state.loading = false;
      state.hr360Users = action.payload;
    },
    putHR360User: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: UserAccessHR360) => {
        return {
          payload: request,
        };
      },
    },
  },
});

export const {
  getUsers,
  storeUsers,
  clearUsers,
  getHR360Users,
  storeHR360Users,
  putHR360User,
} = cmUsersSlice.actions;
export default cmUsersSlice.reducer;
