import React from 'react';
import CheckBoxForm from '../../Shared/CheckBoxForm';
import { Col, Row } from 'react-bootstrap';
import { EEDComponentProps } from 'types/eed.types';
import { useAppSelector } from 'utilities/hooks';
import GridSelect from '../../Shared/GridSelect';

// Custom Layout for Checkbox and Grid select forms
const EmployeeStatusDatesFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  const otherDateDescriptionOptions = useAppSelector((state) => { return state?.dropdown.otherDateDescription; });

  const datesFields = sectionFields.find(f => {
    return f.name === 'Custom Dates';
  })?.fields;

  return (
    <Row>
      <Col xs={6}>
        <CheckBoxForm
          formMethods={formMethods}
          dFields={sectionFields.find(f => {
            return f.name === 'Dates HR';
          })?.fields}
          section="Dates HR"
          title="Dates"
        />
        <CheckBoxForm
          formMethods={formMethods}
          dFields={sectionFields.find(f => {
            return f.name === 'Payroll Info';
          })?.fields}
          section="Payroll Info"
          title="Payroll Info"
        />
        <CheckBoxForm
          formMethods={formMethods}
          dFields={sectionFields.find(f => {
            return f.name === 'Seniority';
          })?.fields}
          section="Seniority"
          title="Seniority"
        />
      </Col>
      <Col xs={6}>
        <GridSelect
          formMethods={formMethods}
          dFields={[{
            name: 'Other Date Grid Description',
            fieldSectionKey: datesFields?.[0]?.name || '',
          }]}
          section="Custom Dates HR"
          title="Custom Dates"
          options={otherDateDescriptionOptions}
          hasCodeColumn={false}
          isCodeField={false}
          hasDescColumn={true}
        />
        <CheckBoxForm
          formMethods={formMethods}
          dFields={sectionFields.find(f => {
            return f.name === 'Hire History HR';
          })?.fields}
          section="Hire History"
          title="Hire History"
        />
      </Col>    
    </Row>
  );
};

export default EmployeeStatusDatesFields;