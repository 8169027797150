import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ACA, ACA1095B, AcaStatus, ACAWaiveCoverage, ClientACA, CoveredIndividual, EmployeeShare, EmployeeInformationOverride, HttpResponse, OfferOfCoverage, SafeHarbor, ZipCode } from '../../models';
import { CommonService, EmployeeACAService } from '../../services';
import {
  clearEmployeeACATabData,
  createEmployeeACACoveredIndividuals,
  createEmployeeACAStatus,
  deleteACAOverride,
  deleteEmployeeACACoveredIndividuals,
  deleteEmployeeACAStatus,
  getDemographicAndTurnoverEmployees,
  handleError,
  handleSuccess,
  loadClientACA,
  loadClientACALastYear,
  loadEmployeeACA1095B,
  loadEmployeeACATabData,
  storeClientACA,
  storeClientACALastYear,
  storeEmployeeACA1095B,
  storeEmployeeACATabData,
  triggerEmFieldValidation,
  updateACAOverrideEmployeeInfo,
  updateACAOverrideEmployeeShare,
  updateACAOverrideOfferOfCoverage,
  updateACAOverrideOfferZipCode,
  updateACAOverrideSafeHarbor,
  updateClientACA,
  updateEmployeeACA1095B,
  updateEmployeeACACoveredIndividuals,
  updateEmployeeACAStatus,
  updateEmployeeACAWaiveCoverage,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadEmployeeACATabData$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmployeeACATabData.type),
    switchMap((action: { payload: { protectedEmpNo: string; prYear: number; protectedClientNo?: string }; }) => {
      return from(
        EmployeeACAService.getEmployeeACATabData(
          action.payload.protectedEmpNo,
          action.payload.prYear,
          action.payload.protectedClientNo
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: ACA) => { return storeEmployeeACATabData(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err), clearEmployeeACATabData()]; }),
      );
    },
    ),
  );
};

const createEmployeeACACoveredIndividuals$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createEmployeeACACoveredIndividuals.type),
    switchMap((action: { payload: CoveredIndividual; }) => {
      return from(
        EmployeeACAService.postEmployeeACACoveredIndividuals(
          action.payload,
        ),
      ).pipe(
        mergeMap(() => {
          return [
            loadEmployeeACATabData({
              protectedEmpNo: action.payload.protectedEmpNo,
              prYear: action.payload.year,
            }),
            handleSuccess('Employee ACA has been successfully saved.'),
            triggerEmFieldValidation({
              section: 'empaca',
              actionType: createEmployeeACACoveredIndividuals.type,
              callerPayload: action.payload,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEmployeeACACoveredIndividuals$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateEmployeeACACoveredIndividuals.type),
    switchMap((action: { payload: CoveredIndividual; }) => {
      return from(
        EmployeeACAService.putEmployeeACACoveredIndividuals(
          action.payload,
        ),
      ).pipe(
        mergeMap(() => {
          return [
            loadEmployeeACATabData({
              protectedEmpNo: action.payload.protectedEmpNo,
              prYear: action.payload.year,
            }),
            handleSuccess('Employee ACA has been successfully saved.'),
            triggerEmFieldValidation({
              section: 'empaca',
              actionType: updateEmployeeACACoveredIndividuals.type,
              callerPayload: action.payload,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteEmployeeACACoveredIndividuals$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteEmployeeACACoveredIndividuals.type),
    switchMap((action: { payload: CoveredIndividual; }) => {
      return from(
        EmployeeACAService.deleteEmployeeACACoveredIndividuals(
          action.payload,
        ),
      ).pipe(
        mergeMap(() => {
          return [
            loadEmployeeACATabData({
              protectedEmpNo: action.payload.protectedEmpNo,
              prYear: action.payload.year,
            }),
            handleSuccess('Employee ACA has been successfully deleted.'),
            triggerEmFieldValidation({
              section: 'empaca',
              actionType: deleteEmployeeACACoveredIndividuals.type,
              callerPayload: action.payload,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateACAOfferOfCoverage$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateACAOverrideOfferOfCoverage.type),
    switchMap((action: { payload: OfferOfCoverage; }) => {
      return from(
        EmployeeACAService.putEmployeeACAOverridesOfferOfCoverage(
          action.payload,
        ),
      ).pipe(
        mergeMap(() => {
          return [
            loadEmployeeACATabData({
              protectedEmpNo: action.payload.protectedEmpNo,
              prYear: action.payload.year,
            }),
            handleSuccess('Employee ACA has been successfully saved.'),
            triggerEmFieldValidation({
              section: 'empaca',
              actionType: updateACAOverrideOfferOfCoverage.type,
              callerPayload: action.payload,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateACAEmployeeShare$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateACAOverrideEmployeeShare.type),
    switchMap((action: { payload: EmployeeShare; }) => {
      return from(
        EmployeeACAService.putEmployeeACAOverridesEmployeeShare(
          action.payload,
        ),
      ).pipe(
        mergeMap(() => {
          return [
            loadEmployeeACATabData({
              protectedEmpNo: action.payload.protectedEmpNo,
              prYear: action.payload.year,
            }),
            handleSuccess('Employee ACA has been successfully saved.'),
            triggerEmFieldValidation({
              section: 'empaca',
              actionType: updateACAOverrideEmployeeShare.type,
              callerPayload: action.payload,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateACASafeHarbor$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateACAOverrideSafeHarbor.type),
    switchMap((action: { payload: SafeHarbor; }) => {
      return from(
        EmployeeACAService.putEmployeeACAOverridesSafeHarbor(
          action.payload,
        ),
      ).pipe(
        mergeMap(() => {
          return [
            loadEmployeeACATabData({
              protectedEmpNo: action.payload.protectedEmpNo,
              prYear: action.payload.year,
            }),
            handleSuccess('Employee ACA has been successfully saved.'),
            triggerEmFieldValidation({
              section: 'empaca',
              actionType: updateACAOverrideSafeHarbor.type,
              callerPayload: action.payload,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateACAZipCode$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateACAOverrideOfferZipCode.type),
    switchMap((action: { payload: ZipCode; }) => {
      return from(
        EmployeeACAService.putEmployeeACAOverridesZipCode(
          action.payload,
        ),
      ).pipe(
        mergeMap(() => {
          return [
            loadEmployeeACATabData({
              protectedEmpNo: action.payload.protectedEmpNo,
              prYear: action.payload.year,
            }),
            handleSuccess('Employee ACA has been successfully saved.'),
            triggerEmFieldValidation({
              section: 'empaca',
              actionType: updateACAOverrideOfferZipCode.type,
              callerPayload: action.payload,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateACAEmployeeInfo$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateACAOverrideEmployeeInfo.type),
    switchMap((action: { payload: EmployeeInformationOverride; }) => {
      return from(
        EmployeeACAService.putEmployeeACAOverridesEmployeeInfo(
          action.payload,
        ),
      ).pipe(
        mergeMap(() => {
          return [
            loadEmployeeACATabData({
              protectedEmpNo: action.payload.protectedEmpNo,
              prYear: action.payload.year,
            }),
            handleSuccess('Employee ACA has been successfully saved.'),
            triggerEmFieldValidation({
              section: 'empaca',
              actionType: updateACAOverrideEmployeeInfo.type,
              callerPayload: action.payload,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteEmployeeACASOverrides$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteACAOverride.type),
    switchMap((action: { payload: { protectedEmpNo: string; prYear: number; year: any; }; }) => {
      return from(EmployeeACAService.deleteEmployeeACAOverrides(action.payload.protectedEmpNo, action.payload.prYear)).pipe(
        mergeMap(() => {
          return [
            loadEmployeeACATabData({ protectedEmpNo: action.payload.protectedEmpNo,
              prYear: action.payload.year }),
            handleSuccess('Employee ACA has been successfully saved.'),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createEmployeeACAStatus$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createEmployeeACAStatus.type),
    switchMap((action: { payload: { status: AcaStatus; prYear: any; }; }) => {
      return from(
        EmployeeACAService.postEmployeeACAStatus(action.payload.status),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<any>) => {
          return [
            loadEmployeeACATabData({
              protectedEmpNo: action.payload.status.protectedEmpNo,
              prYear: action.payload.prYear,
            }),
            getDemographicAndTurnoverEmployees(),
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'empaca',
              actionType: createEmployeeACAStatus.type,
              callerPayload: action.payload,
              idField: 'status',
            }),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEmployeeACAStatus$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateEmployeeACAStatus.type),
    switchMap((action: { payload: { status: AcaStatus; prYear: any; }; }) => {
      return from(
        EmployeeACAService.putEmployeeACAStatus(action.payload.status),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<any>) => {
          return [
            loadEmployeeACATabData({
              protectedEmpNo: action.payload.status.protectedEmpNo,
              prYear: action.payload.prYear,
            }),
            getDemographicAndTurnoverEmployees(),
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'empaca',
              actionType: updateEmployeeACAStatus.type,
              callerPayload: action.payload,
              idField: 'status',
            }),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteEmployeeACAStatus$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteEmployeeACAStatus.type),
    switchMap((action: { payload: { protectedEmpNo: string; effectiveDate: string; prYear: any; }; }) => {
      return from(
        EmployeeACAService.deleteEmployeeACAStatus(
          action.payload.protectedEmpNo,
          action.payload.effectiveDate,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => {
          return loadEmployeeACATabData({
            protectedEmpNo: action.payload.protectedEmpNo,
            prYear: action.payload.prYear,
          });
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadEmployeeACA1095B$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmployeeACA1095B.type),
    switchMap((action: { payload: { protectedEmpNo: string; prYear: number; }; }) => {
      return from(
        EmployeeACAService.getEmployeeACA1095B(
          action.payload.protectedEmpNo,
          action.payload.prYear,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: ACA1095B) => { return storeEmployeeACA1095B(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEmployeeACA1095B$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateEmployeeACA1095B.type),
    switchMap((action: { payload: ACA1095B; }) => {
      return from(EmployeeACAService.putEmployeeACA1095B(action.payload)).pipe(
        mergeMap(() => {
          return [
            loadEmployeeACATabData({ protectedEmpNo: action.payload.protectedEmpNo,
              prYear: action.payload.year }),
            handleSuccess('Employee ACA has been successfully saved.'),
            triggerEmFieldValidation({
              section: 'empaca',
              actionType: updateEmployeeACA1095B.type,
              callerPayload: action.payload,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEmployeeACAWaiveCoverage$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateEmployeeACAWaiveCoverage.type),
    switchMap((action: { payload: ACAWaiveCoverage; }) => {
      return from(EmployeeACAService.putEmployeeACAWaiveCoverage(action.payload)).pipe(
        mergeMap(() => {
          return [
            loadEmployeeACATabData({ protectedEmpNo: action.payload.protectedEmpNo!,
              prYear: action.payload.year! }),
            handleSuccess('Employee ACA has been successfully saved.'),
            triggerEmFieldValidation({
              section: 'empaca',
              actionType: updateEmployeeACA1095B.type,
              callerPayload: action.payload,
            }),
          ];
        }), catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadClientACA$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadClientACA.type),
    switchMap((action: { payload: { year: number; }; }) => {
      return from(EmployeeACAService.getClientACA(action.payload.year)).pipe(
        map((res: any) => { return res.data; }),
        map((res: ClientACA) => { return storeClientACA(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadClientACALastYear$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadClientACALastYear.type),
    switchMap((action: { payload: { year: number; }; }) => {
      return from(EmployeeACAService.getClientACA(action.payload.year)).pipe(
        map((res: any) => { return res.data; }),
        map((res: ClientACA) => { return storeClientACALastYear(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateClientACA$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateClientACA.type),
    switchMap((action: { payload: ClientACA; }) => {
      return from(EmployeeACAService.putClientACA(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<any>) => {
          return [
            loadClientACA({ year: action.payload.year }),
            triggerEmFieldValidation({
              section: 'empaca',
              actionType: updateClientACA.type,
              callerPayload: action.payload,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadEmployeeACATabData$,
  createEmployeeACACoveredIndividuals$,
  updateEmployeeACACoveredIndividuals$,
  deleteEmployeeACACoveredIndividuals$,
  updateACAOfferOfCoverage$,
  updateACAEmployeeShare$,
  updateACASafeHarbor$,
  updateACAZipCode$,
  updateACAEmployeeInfo$,
  deleteEmployeeACASOverrides$,
  createEmployeeACAStatus$,
  updateEmployeeACAStatus$,
  deleteEmployeeACAStatus$,
  loadEmployeeACA1095B$,
  updateEmployeeACA1095B$,
  updateEmployeeACAWaiveCoverage$,
  loadClientACA$,
  loadClientACALastYear$,
  updateClientACA$,
];
