import { createSelector } from 'reselect';
import { salesTaxTransmittalState } from '../reducers';
import { State as SalesTaxTransmittalState } from '../reducers/sales-tax-transmittal.reducer';

export const getSalesTaxTransmittal = createSelector(
  salesTaxTransmittalState,
  (state: SalesTaxTransmittalState) => {return state.salesTaxTransmittal;},
);
export const getSalesTaxTransmittalEntity = createSelector(
  salesTaxTransmittalState,
  (state: SalesTaxTransmittalState) => {return state.salesTaxTransmittalEntity;},
);

export const getSalesTaxTransmittalReport = createSelector(
  salesTaxTransmittalState,
  (state: any) => {return state.salesTaxTransmittalReport?.value;},
);
