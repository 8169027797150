import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDeductionFrequencies, getDeductionStatuses, getDeductionUnits, getSelectedEmp, getCompanyBenefitDeductionCodes } from '../../../core/store/selectors';
import CompanyBenfitItem from './CompanyBenefitItem';
import PanelHeader from 'core/components/shared/PanelHeader';
import { createDeduction, deleteDeduction, loadDeductions, toggleBlockNavigation } from 'core/store/actions';
import { getCompanyBenefits } from 'core/store/selectors/deduction.selector';
import { Deduction, DeductionRequest } from 'core/models';
import Icon from 'core/components/shared/Icon';
import { useFieldArray, useForm } from 'react-hook-form';
import { UNSAVED_MESSAGE } from 'core/constants';
import { useAppSelector } from 'utilities/hooks';
import { getAccess } from 'utilities/utilities';

const CompanyBenefitsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();
  const selEmp = useSelector(getSelectedEmp);
  const companyBenefitsStore = useSelector(getCompanyBenefits);
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  
  const [companyBenefitItems, setCompBenefitItems] = useState<Deduction[]>([]);
  type FormType = {
    companyBenefitItems: Deduction[];
  };

  const {
    control,
    reset,
    ...formMethods
  } = useForm<FormType>({
    defaultValues: {
      companyBenefitItems,
    },
  });

  const { fields: companyBenefitFields, prepend : prependCompanyBenefits } =
    useFieldArray<Deduction>({
      control,
      name: 'companyBenefitItems',
      keyName: 'id',
    });
  
  const dedCodeOpts = useSelector(getCompanyBenefitDeductionCodes);
  const dedCodeOptsWithDedNoInDescription = 
    dedCodeOpts
      .map((d) => { 
        return { 
          ...d,
          id: +d.id,
          description: `${d.id.padStart(2, '0')} - ${d.description}`, 
        }; 
      });
  const dedStatusOpts = useSelector(getDeductionStatuses);
  const dedFrequencyOpts = useSelector(getDeductionFrequencies);
  const dedUnitOpts = useSelector(getDeductionUnits);

  useEffect(() => {
    protectedEmpNo && dispatch(loadDeductions(protectedEmpNo));
  }, [protectedEmpNo]);

  useEffect(() => {
    companyBenefitsStore && setCompBenefitItems(companyBenefitsStore);
  }, [companyBenefitsStore]);
  
  useEffect(() => {
    if (!companyBenefitItems) return;
    reset({
      companyBenefitItems: companyBenefitItems.map((e: Deduction) => {
        return {
          ...e,
        };
      }) });
  }, [companyBenefitItems]);

  const onAddBenefit = () => {
    if (selEmp) {
      prependCompanyBenefits({
        protectedEmpNo: protectedEmpNo,
        dedId: 0,
        deductionType: 'CompanyBenefit',
        entityDesc: '',
      });
    }
  };

  const onDeleteBenefit = (item: Deduction) => {
    if (!confirm('Delete company benefit? This action cannot be undone.')) return;
    if (item.dedId) {
      dispatch(deleteDeduction(item));
    } else {
      // Remove all new Pay Rates that haven't already been saved.
      setCompBenefitItems((prev: Deduction[]) => { return prev.filter((d: Deduction) => { return d.dedId; });});
    }
  };

  const onSubmitCompanyBenefit = (data: FormType, e?: React.BaseSyntheticEvent) => {
    if (!e?.target.isConnected) {
      return false;
    }

    if (!data || !selEmp) return;
    const deductions : Deduction[] = [];
    data.companyBenefitItems.forEach(ded => {
      const deduction = new Deduction(selEmp.clientNo, selEmp.empNo, selEmp.protectedEmpNo, ded?.dedId ?? 0, 'CompanyBenefit', ded);
      deduction.dateStart = (ded.dateStart === '') ? null : ded.dateStart;
      deduction.dateExpire = (ded.dateExpire === '') ? null : ded.dateExpire;
      deductions.push(deduction);
    }); 

    const request: DeductionRequest = {
      protectedEmpNo,
      Data: [
        ...deductions,
      ],
    };
    
    dispatch(createDeduction(request));
  };

  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: formMethods.formState.isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [formMethods.formState.isDirty]);
  
  return (
    <>
      <div className="dm-panel dm-panel-border" >
        <PanelHeader title="Company Benefits" >
          <button
            {...getAccess(sectionAccess, 16, undefined, { disabledSameAsReadOnly: true })}
            className="btn btn-link dm-grid-action-title p-0 mr-2"
            onClick={onAddBenefit}
          >
            Add Benefit	<Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <form onSubmit={formMethods.handleSubmit(onSubmitCompanyBenefit)}>
          {companyBenefitFields?.map((item, index) => {
            return (
              <div key={item.id} >
                <CompanyBenfitItem
                  key = {item.id}
                  item={item}
                  dedCodeOpts={dedCodeOptsWithDedNoInDescription}
                  dedStatusOpts={dedStatusOpts}
                  dedFrequencyOpts={dedFrequencyOpts}
                  unitOpts={dedUnitOpts}
                  formMethods={formMethods}
                  control = {control}
                  index = {index}
                  onDelete={onDeleteBenefit}
                />
                {index + 1 < companyBenefitFields.length && <hr className="dm-panel-hr mt-2" />}
              </div>
            );
          })}
          <div className="text-right mt-3">
            <button
              {...getAccess(sectionAccess, 16, undefined, { disabledSameAsReadOnly: true, disabledDefault: !formMethods.formState.isDirty })}
              type="submit"
              className="btn orange-button mr-3"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CompanyBenefitsPage;
