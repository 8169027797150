import { ICellRendererParams } from '@ag-grid-community/core';
import DateObject from 'react-date-object';

export function agDateTimeRenderer(): any {}

agDateTimeRenderer.prototype.init = function (params: ICellRendererParams) {
  this.dateDiv = document.createElement('div');
  this.dateDiv.innerHTML = params.value ? this.formatDate(params.value) : '';
};

agDateTimeRenderer.prototype.formatDate = function (date: Date | string): string {
  let d;
  if (typeof date === 'string') {
    const i = date.indexOf('T');
    if (i >= 0) {
      // Save the time portion of date.
      this.timezone = date.substr(i);
      d = new Date(date);
    } else {
      d = new Date(this.timezone ? date + this.timezone : date);
    }
  } else {
    d = date;
  }

  return new DateObject(d).format('MM/DD/YYYY hh:mm:ss A');
};

agDateTimeRenderer.prototype.getGui = function (params: ICellRendererParams) {
  return this.dateDiv;
};

agDateTimeRenderer.prototype.refresh = function (params: ICellRendererParams) {
  this.dateDiv.innerHTML = params.value ? this.formatDate(params.value) : '';
  return true;
};

agDateTimeRenderer.prototype.destroy = function () {};
