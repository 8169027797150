import { createSelector } from 'reselect';
import { accountingReportState } from '../reducers';
import { State } from '../reducers/accounting-reports.reducer';

export const getBankDisbursementsReport = createSelector(
  accountingReportState,
  (state: State) => {return state.bankDisbursementReport;},
);

export const getEmployeeAverageHoursReport = createSelector(
  accountingReportState,
  (state:
  State) => {return state?.employeeAverageHoursReport;},
);

export const getHoursAndEarningsReport = createSelector(
  accountingReportState,
  (state:
  State) => {return state?.hoursEarningReport;},
);

export const getNewTrainingCreditReport = createSelector(
  accountingReportState,
  (state:
  State) => {return state?.newJobTrainingCreditReport;},
);

export const getEmployeeAverageHoursWithStatusReport = createSelector(
  accountingReportState,
  (state:
  State) => {return state?.employeeAverageHoursWithStatusReport;},
);

export const getCARES_SBA_PPP_Report = createSelector(
  accountingReportState,
  (state:
  State) => {return state?.caresSBAPPReport;},
);