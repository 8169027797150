/* eslint-disable react/display-name */
import React from 'react';
import styles from './form-controls.module.scss';
import SelectMultiple from './SelectMultiple';
import { ISelectMultipleOption } from 'interfaces/IOption';

interface PropTypes {
  id?: string;
  name: string;
  label?: string;
  labelFirst?: boolean;
  lgLabel?: boolean;
  labelWidth?: number | 'auto';
  labelWidthUnit?: string;
  fontSize?: string;
  alignment?: string;
  groupClass?: string;
  groupStyle?: object;
  errors?: any;
  value: boolean;
  disabled?: boolean;
  showMultiSelect?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  multiSelectOptions?: ISelectMultipleOption[];
  onChangeMultiSelect?: (selected: ISelectMultipleOption[]) => void | null;
}

export const SelectMultipleInLine: React.FC<any> = React.forwardRef(
  (
    {
      id,
      name,
      label,
      labelFirst = false,
      lgLabel = false,
      labelWidth = 'auto',
      labelWidthUnit = '',
      fontSize = '10px',
      groupClass,
      alignment = 'center',
      groupStyle,
      errors,
      disabled,
      value,
      onChange,
      multiSelectOptions = [],
      onChangeMultiSelect,
      showMultiSelect = true,
      ...extraProps
    }: PropTypes,
    ref: any,
  ) => {
    const groupClass2 = styles['dm-form-group'] + ' ' + (groupClass ?? '');
    id = id ?? name;

    const labelFirstCss = labelFirst ? 'order-0' : 'order-1';

    return (
      <div className={`d-flex align-items-${alignment} ${groupClass2} pe-auto`}
      style={groupStyle}>
      <fieldset
        disabled={disabled}
        className={`d-flex align-items-${alignment} ${groupClass2} pe-auto`}
        style={groupStyle}
      >
        {label && (
          <label
            style={{
              width: `${labelWidth}${labelWidthUnit}`,
              fontSize: fontSize,
            }}
            htmlFor={id}
            className={`${labelFirstCss} ${lgLabel
              ? styles['dm-lg-form-check-label']
              : styles['dm-form-check-label']}`}
          >
            {label}
          </label>
        )}
        <input
          id={id}
          name={name}
          checked={value}
          className={styles['dm-form-check-input'] +
            (errors ? ' ' + styles['dm-is-invalid'] : '')}
          ref={ref}
          onChange={onChange}
          type="checkbox"
          {...extraProps}
        />
        <small className="text-danger order-3"> {errors?.message} </small>
      </fieldset>
      {showMultiSelect ? <div className="row pl-1">
          <SelectMultiple 
            options={multiSelectOptions}
            selectAllDefault={true}
            onChange={onChangeMultiSelect}
          />
        </div> : null}
      </div>
    );
  },
);
