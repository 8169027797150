import React, { CSSProperties } from 'react';

type Page404Style = {
  [key: string]: CSSProperties
};

const page404Styles: Page404Style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
    userSelect: 'none',
  },
  title: {
    fontSize: '6rem',
  },
  subTitle: {
    fontSize: '2rem',
  },
};

const Page404 = () => {
  return (
    <div style={page404Styles.container}>
      <div style={page404Styles.title}>404</div>
      <div style={page404Styles.subTitle}>Page not found</div>
    </div>
  );
};

export default Page404;