import { convToClass, PropTypeLists } from '../../../utilities/classUtils';
import { QuckBooksConnectionOption } from '../../enums/intuit/quickBooksConnectionOption';

export default class ConnectionOption {
    public option: QuckBooksConnectionOption;


    constructor(option: QuckBooksConnectionOption)
    {
        this.option = option;
    }
}