import { createReducer } from '@reduxjs/toolkit';
import {
  getEEOInfoCorrections,
  getWorkersCompCorrections,
  storeWorkersCompCorrections,
  storeEEOInfoCorrections,
  clearCorrections,
  getDeptAllocationCorrections,
  storeDeptAllocationCorrections,
  postDeptAllocationCorrections,
  storeDeptChangeCorrections,
  postMescEarnCorrection,
  loadDeptAllocationEmployees,
  storeDeptAllocationEmployees,
  storeReportRequestData,
  storeDeptAllocationReport,
  loadDeptAllocationReport,
} from '../actions';
import {
  AllocationEmployee,
  CorrectionsReportRequest,
  DeptAllocation,
  DeptAllocationDTO,
  EEOInfoCorrection,
  WorkersCompCorrection,
} from 'core/models';

export type CorrectionState = {
  loading: boolean;
  eeoInfoCorrections: EEOInfoCorrection[];
  workersCompCorrections: WorkersCompCorrection[];
  deptAllocationCorrections: DeptAllocationDTO | null;
  deptChangeCorrections: DeptAllocation[];
  employees: AllocationEmployee[];
  reportRequestData: Omit<CorrectionsReportRequest, 'allocationReportType'> | null;
  allocationReport: string;
};

const INITIAL_STATE: CorrectionState = {
  loading: false,
  eeoInfoCorrections: [],
  workersCompCorrections: [],
  deptAllocationCorrections: null,
  deptChangeCorrections: [],
  employees: [],
  reportRequestData: null,
  allocationReport: '',
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getEEOInfoCorrections, (state) => {
      state.loading = true;
    })
    .addCase(storeEEOInfoCorrections, (state, action) => {
      state.loading = false;
      state.eeoInfoCorrections = action.payload;
    })
    .addCase(getWorkersCompCorrections, (state) => {
      state.loading = true;
    })
    .addCase(storeWorkersCompCorrections, (state, action) => {
      state.loading = false;
      state.workersCompCorrections = action.payload;
    })
    .addCase(getDeptAllocationCorrections, (state) => {
      state.loading = true;
    })
    .addCase(postDeptAllocationCorrections, (state) => {
      state.loading = true;
    })
    .addCase(postMescEarnCorrection, (state) => {
      state.loading = true;
    })
    .addCase(storeDeptAllocationCorrections, (state, action) => {
      state.loading = false;
      state.deptAllocationCorrections = action.payload;
      state.deptChangeCorrections = action.payload.deptAllocations;
    })
    .addCase(storeDeptChangeCorrections, (state, action) => {
      state.loading = false;
      state.deptChangeCorrections = action.payload;
      if (!state.deptAllocationCorrections) {
        return console.error('Unable to store dept. allocations. State is undefined');
      }
      state.deptAllocationCorrections = {
        ...state.deptAllocationCorrections,
        deptAllocations: action.payload,
      };
    })
    .addCase(clearCorrections, (state) => {
      state.loading = false;
      state.eeoInfoCorrections = [];
      state.workersCompCorrections = [];
    })
    .addCase(loadDeptAllocationEmployees, (state) => {
      state.loading = true;
    })
    .addCase(storeDeptAllocationEmployees, (state, action) => {
      state.loading = false;
      state.employees = action.payload;
    })
    .addCase(storeReportRequestData, (state, action) => {
      state.reportRequestData = action.payload;
    })
    .addCase(loadDeptAllocationReport, (state) => {
      state.loading = true;
    })
    .addCase(storeDeptAllocationReport, (state, action) => {
      state.loading = false;
      state.allocationReport = action.payload;
    })
  ;
});