import React, { useEffect, useState } from 'react';
import { ColDef, GridOptions, ValueGetterParams } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { AllocationEmployee, WageAllocation } from 'core/models';
import { currencyFormatter } from 'utilities/utilities';
import { useAppSelector } from 'utilities/hooks';

type Props = {
  selectedEmp: AllocationEmployee;
  wageAllocations: WageAllocation[];
};

// TODO: Grid info was just kept in here for this one because it's pretty minimal, but can change that for consistency.
const columnDefs = (hideLoc: boolean, hideDept: boolean, hideSub: boolean, hideSub2: boolean) : ColDef[] => [
  { field: 'loc',
    hide: hideLoc,
    headerName: 'Loc.' },
  { field: 'dept',
    hide: hideDept,
    headerName: 'Dept.' },
  { field: 'sub',
    hide: hideSub,
    headerName: 'Sub.' },
  { field: 'sub2',
    hide: hideSub2,
    headerName: 'Sub. 2' },
  { field: 'payType',
    headerName: 'Pay type' },
  { field: 'checkType',
    headerName: 'Check type' },
  { field: 'checkNo',
    headerName: 'Check no.' },
  { field: 'percent',
    headerName: '%',
    valueGetter: (params: ValueGetterParams) => {
      const { data }: { data: WageAllocation } = params;
      return (data.percent * 100).toFixed(2);
    },
  },
  { field: 'earnings',
    headerName: 'Earnings',
    valueGetter: (params: ValueGetterParams) => {
      const { data }: { data: WageAllocation } = params;
      return currencyFormatter(data.earnings);
    },
  },
  { field: 'deptDesc',
    hide: hideDept,
    headerName: 'Dept. desc' },
  { field: 'subDesc',
    hide: hideSub,
    headerName: 'Sub. desc.' },
  { field: 'sub2Desc',
    hide: hideSub2,
    headerName: 'Sub. desc. 2' },
];
const gridOptions: GridOptions = {
  domLayout: 'autoHeight',
  defaultColDef: {
    flex: 1,
    autoHeight: true,
    resizable: true,
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
};

const WageAllocationsResultGrid = ({ selectedEmp, wageAllocations }: Props) => {
  const [rowData, setRowData] = useState<WageAllocation[]>(wageAllocations);
  const locationOpts = useAppSelector((state) => {return state.dropdown.location;});
  const deptOpts = useAppSelector((state) => {return state.dropdown.department;});
  const subdeptOpts = useAppSelector((state) => {return state.dropdown.subDepartment;});
  const subdept2Opts = useAppSelector((state) => {return state.dropdown.subDepartment2;});
  
  useEffect(() => {
    if (!wageAllocations) return;
    setRowData(wageAllocations);
  }, [wageAllocations, selectedEmp.empNo]);
  
  return (
    <div className="table-wrapper-wrapper ag-theme-balham">
      <AgGridReact
        columnDefs={columnDefs(!locationOpts.length, !deptOpts.length, !subdeptOpts.length, !subdept2Opts.length)}
        gridOptions={gridOptions}
        rowData={rowData}
      />
    </div>
  );
};

export default WageAllocationsResultGrid;