import React, { CSSProperties } from 'react';
import styles from './form-controls.module.scss';

type PropTypes = {
  label?: string | string[];
  tallLabel?: boolean;
  labelClass?: string;
  labelStyle?: CSSProperties;
  id?: string;
  hasError: boolean;
  required?: boolean;
};

export const Labels: React.FC<PropTypes> = ({
  label,
  tallLabel = false,
  labelClass,
  labelStyle,
  id,
  hasError,
  required,
}) => {
  let classes = styles['dm-form-label'];
  required && (classes += ' ' + styles['dm-required']);
  hasError && (classes += ' text-danger');

  return (<>
    {label && (
      <div
        className={tallLabel ? styles.labelContainerTall : styles.labelContainer}
        style={labelStyle}
      >
        {Array.isArray(label)
          ? label.map((labelText: string, index: number) => {
            return (
              <label
                key={index}
                htmlFor={id}
                className={classes + ' ' + labelClass}
              >{labelText}</label>);
          })
          : (<label
              htmlFor={id}
            className={classes + ' ' + labelClass}
          >{label}</label>)
        }
      </div>
    )}
  </>);
};