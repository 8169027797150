import React from 'react';
import { Label } from '.';
import styles from './form-controls.module.scss';

interface PropTypes {
  id?: string;
  name: string;
  label?: string;
  labelWidth?: number;
  inputWidth?: number;
  groupClass?: string;
  inputClass?: string;
  groupStyle?: object;
  placeholder?: string;
  required?: boolean;
  type?: string;
  step?: string;
  errors?: any;
  errorMsg?: string;
  onChange?: (e: any) => void;
}

export const InputGrpInLine: React.FC<any> = React.forwardRef(
  (
    {
      id,
      label,
      labelWidth = 60,
      inputWidth,
      groupClass,
      inputClass = '',
      groupStyle,
      required = false,
      errors,
      errorMsg,
      onChange,
      ...extraProps
    }: PropTypes,
    ref: any,
  ) => {
    const groupClass2 =
            styles['dm-form-group-inline'] + ' ' + (groupClass ?? '');
    id = id ?? extraProps.name;
    const inputWidthString = inputWidth ? `${inputWidth}px` : ('' + (100 - labelWidth) + '%');
    const labelWidthString = '' + labelWidth + '%';
    const errMsg = errors
      ? errors.type === 'validate'
        ? errorMsg
        : errors.message
      : '';

    return (
      <div
        className={groupClass2}
        style={groupStyle}
      >
        {label && (
          <div style={{ width: labelWidthString }}>
            <Label
              id={id}
              hasError={errors}
              text={label}
              required={required}
            />
          </div>
        )}

        <div style={{ width: inputWidthString }}>
          <input
            className={
              inputClass +
                            ' ' +
                            styles['dm-form-control'] +
                            (errors ? ' ' + styles['dm-is-invalid'] : '')
            }
            ref={ref}
            id={id}
            onChange={onChange}
            {...extraProps}
          />
          <small className="text-danger">{errMsg}</small>
        </div>
      </div>
    );
  },
);

InputGrpInLine.displayName = 'InputGrpInLine';