import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Client, ACA } from 'core/models';
import { loadEmployeeACATabData } from 'core/store/actions';
import { getClient, getEmpACATabData } from 'core/store/selectors';
import EmployeeStatus from 'core/components/shared/EmployeeStatus';
import { useAppSelector } from 'utilities/hooks';
import Modal from './Modal';
import { DemographicAndTurnoverEmployee } from 'core/models/HumanCapitalReports.model';
import { IACAEmployee } from 'core/store/slices/human-capital-management/aca-full-time-calculation.slice';

type PropTypes = {
  data: DemographicAndTurnoverEmployee | IACAEmployee;
  show: boolean;
  onHide: () => void;
};

const EmployeeStatusModal: React.FC<PropTypes> = ({ data, show, onHide }) => {
  const dispatch = useDispatch();
  const client = useSelector(getClient) as Client;
  const acaTabData = useSelector(getEmpACATabData) as ACA;
  const protectedClientNo = useAppSelector(
    (state) => { return state.auth.currentProtectedClientNo; },
  );

  const currentYear = new Date().getFullYear();

  const { control, formState, ...formMethods } =
    useForm<ACA>({
      defaultValues: acaTabData,
    });

  //PI-8822 Updated this to just load on first  go because it was firing constantly and should only need to be loaded on inital load of modal. 
  useEffect(() => {
    if (data.protectedEmpNo) {
      dispatch(
        loadEmployeeACATabData({
          protectedEmpNo: data.protectedEmpNo,
          prYear: currentYear,
          protectedClientNo: data.protectedClientNo
        }),
      );
    }
  }, []);

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    formMethods.reset();
    onHide();
  };

  useEffect(() => {
    formMethods.reset(acaTabData);
  }, [acaTabData]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="modal-md"
    >
      <div className="p-3">
        <EmployeeStatus
          formMethods={formMethods}
          formState={formState}
          empId={data.empId}
          empNo={data.empNo}
          protectedEmpNo={data.protectedEmpNo}
          protectedClientNo={protectedClientNo}
          clientNo={client.clientNo}
          control={control}
          acaStatus={acaTabData?.acaStatus || []}
          onCancel={closeModal}
        />
      </div>
    </Modal>
  );
};

export default EmployeeStatusModal;
