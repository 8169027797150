import React, { Fragment } from 'react';
import styles from './shared.module.scss';
import { RadioOptions } from '../form-controls/RadioGrp';

export type RadioOptsWithCount = RadioOptions & {
  count?: number;
};

type Props = {
  name: string;
  id?: string;
  options: RadioOptsWithCount[];
  disabled?: boolean;
  checked: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const RadioTabs = ({
  name,
  options,
  disabled,
  checked,
  onChange,
}: Props) => {
  return (
    <div className={styles['radio-tabs']}>
      {options.map((option, index) => {
        return (
          <Fragment key={option.value}>
            <label
              className={styles['radio-tab']}
              htmlFor={`${option.value}-${index}`}
            >
              <input
                type="radio"
                name={name}
                id={`${option.value}-${index}`}
                onChange={onChange}
                value={option.value}
                checked={checked === option.value}
                disabled={disabled}
                aria-checked={checked === option.value}
                aria-disabled={disabled}
              />
              <span className={styles['record-count']}>
                <span className="font-weight-bold">{option.label}</span>
              </span>
            </label>
          </Fragment>
        );
      })}
    </div>
  );
};

export default RadioTabs;