import { createReducer } from '@reduxjs/toolkit';
import { ACA, ACA1095B, AcaStatus, ClientACA } from '../../models';
import {
  clearEmployeeACATabData,
  loadClientACA,
  loadClientACALastYear,
  loadEmployeeACA1095B,
  loadEmployeeACATabData,
  storeClientACA,
  storeClientACALastYear,
  storeEmployeeACA1095B,
  storeEmployeeACATabData,
} from '../actions';

export interface State {
  loading: boolean;
  acaTabData: ACA | null;
  aca1095b: ACA1095B | null;
  clientAca: ClientACA | null;
  clientAcaLastYear: ClientACA | null;
}

const INITIAL_STATE: State = {
  loading: false,
  acaTabData: null,
  aca1095b: null,
  clientAca: null,
  clientAcaLastYear: null,
};

const sortAcaStatuses = (a: AcaStatus, b: AcaStatus) =>
  a.effectiveDate < b.effectiveDate ? 1 :
    b.effectiveDate < a.effectiveDate ? -1
      : 0;

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadEmployeeACATabData, (state) => {
      state.loading = true;
    })
    .addCase(storeEmployeeACATabData, (state, action) => {
      state.loading = false;
      state.acaTabData = {
        ...action.payload,
        acaStatus: action.payload.acaStatus.sort(sortAcaStatuses),
      };
    })
    .addCase(clearEmployeeACATabData, (state) => {
      state.loading = false;
      state.acaTabData = null
    })
    .addCase(loadEmployeeACA1095B, (state) => {
      state.loading = true;
    })
    .addCase(storeEmployeeACA1095B, (state, action) => {
      state.loading = false;
      state.aca1095b = action.payload;
    })
    .addCase(loadClientACA, (state) => {
      state.loading = true;
    })
    .addCase(storeClientACA, (state, action) => {
      state.loading = false;
      state.clientAca = action.payload;
    })
    .addCase(loadClientACALastYear, (state) => {
      state.loading = true;
    })
    .addCase(storeClientACALastYear, (state, action) => {
      state.loading = true;
      state.clientAcaLastYear = action.payload;
    });
});