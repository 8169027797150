import React, { CSSProperties, useContext } from 'react';
import Modal from 'core/components/modals/Modal';
import TimeCardDeductionItem from '../TimeCardDeductionItem';
import Icon from 'core/components/shared/Icon';
import { TimeCardDeduction } from 'core/models';
import { TimeCardContext } from '../TimeCardContext';
import { generateNegativeId } from 'utilities/utilities';

const modalStyles: CSSProperties = {
  minWidth: '800px',
};

type Props = {
  show: boolean;
  onHide: () => void;
  canEdit?: boolean;
};

const DeductionsModal = ({ show, onHide, canEdit = true }: Props) => {
  
  const { deductionFieldArrayMethods, formMethods, overrideDeleted } = useContext(TimeCardContext);
  const { fields, append, remove } = deductionFieldArrayMethods;
  const { getValues } = formMethods;
  
  const addDeduction = () => {
    const newDeduction: TimeCardDeduction = {
      transmittalTimeCardId: getValues('transmittalTimeCardId'),
      transmittalTimeCardDeductionId: generateNegativeId(),
      deductionNumber: 0,
      amount: 0,
    };
    
    append(newDeduction);
  };
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Deductions"
      additionalStyles={modalStyles}
    >
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-end">
          {canEdit && (
            <button
              className="section-action btn btn-link dm-grid-action-title p-0 d-flex no-wrap"
              style={{ whiteSpace: 'nowrap' }}
              onClick={addDeduction}
            >
              Add Deduction <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          )}
        </div>
        <div className="d-flex flex-column mb-2">
          {canEdit && !fields?.length ? (
            <div className="add-entry">
              Click &ldquo;Add Deduction&rdquo;
            </div>
          ) : (
            <>
              <div className="d-flex ml-4">
                <div className="d-flex flex-column w-25">
                  <span>Deduction Type</span>
                </div>
                <span style={{ marginLeft: '40px' }}>Amount</span>
              </div>
              {fields?.map((deduction, index) => (
                <TimeCardDeductionItem
                  key={deduction.id}
                  deduction={deduction}
                  index={index}
                  canEdit={canEdit}
                />
              ))}
            </>
          )}
        </div>
        {overrideDeleted && (
          <div style={{ color: 'red' }}>
            <strong>NOTE: </strong> a deduction override has been deleted. The corresponding scheduled deduction will
            reappear once the time sheet has been saved.
          </div>
        )}
        <div className="d-flex justify-content-end mt-2">
          <button
            className="btn orange-button-sm"
            onClick={onHide}
          >
            Close
          </button>
        </div>

      </div>
    </Modal>    
  );
};

export default DeductionsModal;