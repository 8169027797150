import axios from "axios";
import { SessionStorageService } from './session-storage.service';

const Config = () => {
  return {
    timeout: 2*60*1000, //set a 2 minute timeout for all requests. This could be changed to a diffrent time.
    headers: {
      'Access-Control-Allow-Credentials': 'true',
      'x-csrf': '1',
    },
    withCredentials: true
  };
};

export const API_IDENTITY_SERVER = axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_SERVER_BASE_URL,
  ...Config(),
});
API_IDENTITY_SERVER.interceptors.request.use((req) => { return req; });
API_IDENTITY_SERVER.interceptors.response.use((req) => { return req; });

export const API_CLIENT = axios.create({
  baseURL: process.env.REACT_APP_CLIENT_BASE_URL,
  ...Config(),
});
API_CLIENT.interceptors.request.use((req) => { return req; });
API_CLIENT.interceptors.response.use((req) => { return req; });

export const API_EMPLOYEE_MASTER = axios.create({
  baseURL: process.env.REACT_APP_EMPLOYEE_BASE_URL,
  ...Config(),
});
API_EMPLOYEE_MASTER.interceptors.request.use(
  (req) => { return req; },
  (err) => { return Promise.reject(err.response.data.messages); },
);
API_EMPLOYEE_MASTER.interceptors.response.use(
  (res) => { return res; },
  // opened this up to send all error response data
  // in order to capture response values
  (err) => { return Promise.reject(err.response.data); },
);
