import React from 'react';
import { ArrayField } from 'react-hook-form';
import { TransmittalEmployee } from 'core/models';
import CheckItem from './CheckItem';

type Props = {
  index: number;
  employee: Partial<ArrayField<TransmittalEmployee, 'id'>>;
  empNo: number;
  payrollHistoryId: number;
  controlTotalId: number;
  isReadOnly: boolean;
};

const CheckItemList = ({
  index,
  empNo,
  payrollHistoryId,
  controlTotalId,
  employee,
  isReadOnly,
}: Props) => {
  return (
    <div>
      {employee?.checks?.map((check, checkIndex) => {
        return (
          <CheckItem
            key={check.transmittalCheckId}
            check={check}
            checkIndex={checkIndex}
            index={index}
            employee={employee}
            empNo={empNo}
            payrollHistoryId={payrollHistoryId}
            controlTotalId={controlTotalId}
            isReadOnly={isReadOnly}
          />
        );
      })}
    </div>
  );
};

export default CheckItemList;
