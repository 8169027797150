import React from 'react';
import EmpDetailPage from './EmpDetailPage';
import EmpDetailSidebar from './emp-detail-sidebar/EmpDetailSidebar';
import styles from './emp-detail.module.scss';

interface PropTypes {
  location: any;
}

const DetailPageContainer: React.FC<PropTypes> = ({ location }) => {
  return (
    <div className={styles['ed-page-container']}>
      <EmpDetailSidebar />
      <EmpDetailPage location={location}/>
    </div>
  );  
};

export default DetailPageContainer;