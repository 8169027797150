import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { EmpMyInfoChanges } from '../../../models';
import Modal from '../Modal';
import ChangeHistoryTabs from './ChangeHistoryTabs';

type PropTypes = {
  data: EmpMyInfoChanges;
  show: boolean;
  onHide: () => void;
};

const MyInfoChangeHistoryModal: React.FC<PropTypes> = ({
  data,
  show,
  onHide,
}) => {
  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        dialogClassName="modal-dialog-class"
        title="MyInfo Change History"
      >
        <ChangeHistoryTabs data={data} />
      </Modal>
    </div>
  );
};

export default MyInfoChangeHistoryModal;
