import React from 'react';
import Icon from 'core/components/shared/Icon';
import BaseColumnFilter from './BaseColumnFilter';
import { TableModel as Tm } from './types';

// #region Subcomponents
type SortButtonProps = {
  sortState: 'ASC' | 'DESC' | 'UNSORTED';
};

export const SortIcon = ({ sortState }: SortButtonProps) =>
  <button className="naked-button">
    <Icon
      className="m-0 p-0"
      name={sortState === 'ASC' ? 'sort-up' : 'sort-down'}
      style={sortState === 'UNSORTED' ? { display: 'none' } : undefined}
      fontSize="0.8rem"
      color="#00558c"
    />
  </button>;
// #endregion

type SortableHeaderProps<T> = {
  column: keyof T;
  filterData: Tm.FilterData | null;
  sortState: 'ASC' | 'DESC' | 'UNSORTED' | undefined;
  label: string;
  filterOptions: { description: Tm.FilterType }[];
  leftBuffer?: number;
  onColumnSort: (column: keyof T) => void;
  onColumnFilter: (query: string, column: keyof T) => void;
  onColumnFilterTypeChange: (filter: Tm.FilterType, column: keyof T) => void;
  onUpdateDateRange: (newDate: Date | null, property: keyof Pick<Tm.FilterData, 'dateFrom' | 'dateTo'>, column: keyof T) => void;
  onUpdateCompareDate: (newDate: Date | null, column: keyof T) => void;
};

function SortableHeader<T>({
  column,
  filterData,
  sortState = 'UNSORTED',
  label,
  filterOptions,
  leftBuffer = 0,
  onColumnSort,
  onColumnFilter,
  onColumnFilterTypeChange,
  onUpdateDateRange,
  onUpdateCompareDate,
}: SortableHeaderProps<T>) {
  return (
    <th>
      <div
        style={{ display: 'flex', flexWrap: 'nowrap', whiteSpace: 'nowrap', alignItems: 'center' }}
        onClick={() => { onColumnSort(column); }}
      >
        {filterData?.query || filterData?.dateFrom || filterData?.dateTo || filterData?.filterType ? (
          <Icon
            name="filter"
            color="#00558c"
            fontSize="0.6rem"
          />
        ) : null}
        {label}
        <SortIcon sortState={sortState} />
        <div
          className="ml-auto"
          onClick={(e) => { e.stopPropagation(); }}
        >
          <BaseColumnFilter<T>
            column={column}
            filterData={filterData}
            onSearch={onColumnFilter}
            onFilterTypeChange={onColumnFilterTypeChange}
            onUpdateDateRange={onUpdateDateRange}
            onUpdateCompareDate={onUpdateCompareDate}
            filterOptions={filterOptions}
            leftBuffer={leftBuffer}
          />
        </div>
      </div>
    </th>
  );
}

export default SortableHeader;