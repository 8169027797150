import React from 'react';
import { Accordion } from 'react-bootstrap';
import styles from './nested-accordion.module.scss';
import { NestedAccordionItem } from './NestedAccordionItem';

type PropTypes = {
  level: number;
  item: NestedAccordionItem;
  data?: any;
  toggleComponents: React.FunctionComponent<any>[];
  onClick?: any;
  onCheckBoxClick?: any;
  showCheckBox?: boolean;
  contentClass?: string;
  contentStyle?: object;
  toggleContainerClass?: string;
  toggleContainerStyle?: object;
  bodyContainerClass?: string;
  bodyContainerStyle?: object;
  defaultActiveKey?: string;
};

const NestedAccordion: React.FC<PropTypes> = ({
  level,
  item,
  data,
  toggleComponents,
  onClick,
  onCheckBoxClick,
  contentClass = styles['na-content'],
  contentStyle,
  toggleContainerClass = styles['na-toggle-container'],
  toggleContainerStyle,
  bodyContainerClass = styles['na-body-container'],
  bodyContainerStyle,
  defaultActiveKey = '',
  showCheckBox = false
}) => {
  const ToggleComponent = toggleComponents[level - 1];
  const LeafComponent = toggleComponents[toggleComponents.length - 1];
  return (
    <Accordion defaultActiveKey={defaultActiveKey}>
      <div
        className={contentClass}
        style={contentStyle}
        key={item.id}
      >
        <div
          className={toggleContainerClass}
          style={toggleContainerStyle}
        >
          <ToggleComponent
            eventKey={item.id}
            title={' ' + item.title}
          />
        </div>
        <Accordion.Collapse eventKey={item.id}>
          <div
            className={bodyContainerClass}
            style={{ ...bodyContainerStyle, whiteSpace: 'nowrap' }}
          >
            {item?.children?.map((subItem: NestedAccordionItem, subItemIndex: number) => {
              return (subItem?.children?.length ?? 0) > 0 ? (
                <NestedAccordion
                  key={subItem.id}
                  level={level + 1}
                  item={subItem}
                  data={data}
                  toggleComponents={toggleComponents}
                  toggleContainerClass={toggleContainerClass}
                  onClick={onClick}
                  onCheckBoxClick={onCheckBoxClick}
                  showCheckBox={showCheckBox}
                  defaultActiveKey={(subItemIndex === 0 && defaultActiveKey !== '') ? subItem.id : ''}
                />
              ) : (
                <LeafComponent
                  key={subItem.id}
                  item={subItem}
                  data={data}
                  onClick={onClick}
                  onCheckBoxClick={onCheckBoxClick}
                  showCheckBox={showCheckBox}
                />
              );
            },
            )}
          </div>
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
};

export default NestedAccordion;
