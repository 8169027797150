import { createReducer } from '@reduxjs/toolkit';
import { RateRule } from '../../models';
import { loadRateRule, storeRateRule, storeRateRules } from '../actions';

export interface State {
  loading: boolean;
  rateRule: RateRule[];
}

const INITIAL_STATE = {
  loading: false,
  rateRule: [],
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadRateRule.type]: (state) => {return { ...state, loading: true };},
  [storeRateRules.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      rateRule: action.payload,
    };
  },
  [storeRateRule.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      rateRule: [
        ...state.rateRule.filter((rr: RateRule) => {return rr.rateId !== action.payload.rateId;}),
        action.payload,
      ],
    };
  },

});
