import { createAction } from '@reduxjs/toolkit';
import { EmpDocument, TaxDocument } from '../../models';

export const loadTaxDocuments = createAction<string>(
  '[employee] LOAD_TAX_DOCUMENTS',
);
export const storeTaxDocuments = createAction<TaxDocument>(
  '[employee] STORE_TAX_DOCUMENTS',
);
export const loadTaxDocumentPDF = createAction<string>(
  '[employee] LOAD_TAX_DOCUMENT_PDF',
);
export const storeTaxDocumentPDF = createAction<any>(
  '[employee] STORE_TAX_DOCUMENT_PDF',
);
export const clearTaxDocumentPDF = createAction(
  '[employee] CLEAR_TAX_DOCUMENT_PDF',
);

export const loadEmpDocuments = createAction<string>(
  '[employee] LOAD_EMP_DOCUMENTS',
);
export const storeEmpDocuments = createAction<EmpDocument>(
  '[employee] STORE_EMP_DOCUMENTS',
);
export const loadEmpDocumentPDF = createAction<string>(
  '[employee] LOAD_EMP_DOCUMENT_PDF',
);
export const storeEmpDocumentPDF = createAction<any>(
  '[employee] STORE_EMP_DOCUMENT_PDF',
);
export const clearEmpDocumentPDF = createAction(
  '[employee] CLEAR_EMP_DOCUMENT_PDF)',
);