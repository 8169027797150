import { SelectGrp, InputGrp, SelectModalGrp } from 'core/components/form-controls';
import { FieldInputSettings, FormMethods } from 'core/components/form-controls/types';
import Icon from 'core/components/shared/Icon';
import {
  Dropdown,
  EarningCode,
  Employee,
  PayRateDropdown,
  PayrollsUserOptions,
  TransmittalCheck,
  TransmittalEarning,
} from 'core/models';
import {
  getLocationDepartmentsWithoutHome,
  getShiftCodes,
  getYesNoCMOption,
} from 'core/store/selectors';
import { selectClientTaxEntitiesByAgency } from 'core/store/selectors/tax.selector';
import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { ArrayField } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'utilities/hooks';
import { formatWithCommas, parseFloatNumberWithCommas } from 'utilities/utilities';
import { filteredRateOptions } from '../shared/payrollUtilities';
import DepartmentOptionForm from 'core/components/form-controls/select-modal/DepartmentOptionForm';

const empTrackedOpts = [
  {
    id: 'true',
    description: 'Tracked',
  },
  {
    id: 'false',
    description: '',
  },
];

const fs: FieldInputSettings = {
  dept: {
    name: 'dept',
    labelField: 'deptDesc',
    valueField: 'deptCode',
    groupClass: 'mw450',
  },
  subDept: {
    name: 'subDept',
    labelField: 'subDeptDesc',
    valueField: 'subDeptCode',
    groupClass: 'mw450',
  },
  subDept2: {
    name: 'subDept2',
    labelField: 'sub2Desc',
    valueField: 'sub2Code',
    groupClass: 'mw450',
  },
  checkIndexty: {
    name: 'checkIndexty',
    labelField: 'name',
    valueField: 'entityId',
    groupClass: 'mw450',
  },
  shiftPremiumId: {
    name: 'shiftPremiumId',
    groupClass: 'mw450',
  },
};

type Props = {
  earning: Partial<ArrayField<TransmittalEarning, 'id'>>;
  checkIndex: number;
  earningIndex: number;
  register: FormMethods['register'];
  earningsCode: Dropdown[];
  isReadOnly: boolean;
  errors: FormMethods['errors'];
  payrollsUserOptions: PayrollsUserOptions | null;
  check: TransmittalCheck;
  employee: Employee;
  earningCodeSettings: EarningCode[];
  setValue: FormMethods['setValue'];
  remove: (index?: number | number[] | undefined) => void;
  watch: FormMethods['watch'];
  control: any;
};

const TransmittalModalEarningsItem = ({
  earning,
  checkIndex,
  earningIndex,
  register,
  earningsCode,
  isReadOnly,
  errors,
  payrollsUserOptions,
  check,
  employee,
  earningCodeSettings,
  setValue,
  remove,
  watch,
  control,
}: Props) => {
  const shiftCodes = useSelector(getShiftCodes);
  const { deptOpts, subdeptOpts, subdept2Opts } = useSelector(getLocationDepartmentsWithoutHome);
  const taxEntities = useAppSelector((state) => { return selectClientTaxEntitiesByAgency(state, 'City', new Date().getFullYear()); });
  const showRatesOnTransmittal = useSelector(getYesNoCMOption(36));

  // watched for formatting with commas. Also needs to use InputGrp and pass formatValue/hiddenValue props
  const formAmount = watch(`checks[${checkIndex}].earnings[${earningIndex}].amount`);
  const formAltRate = watch(`checks[${checkIndex}].earnings[${earningIndex}].altRate`);
  const earningCode = watch(`checks[${checkIndex}].earnings[${earningIndex}].earningsCode`);
  // ldss2
  const watchedDept = watch(`checks[${checkIndex}].earnings[${earningIndex}].dept`);
  const watchedSubDept = watch(`checks[${checkIndex}].earnings[${earningIndex}].subDept`);
  const watchedSubDept2 = watch(`checks[${checkIndex}].earnings[${earningIndex}].subDept2`);
  
  const [showAlt, setShowAlt] = useState((earning?.altRate ?? 0) > 0);
  const [showAdditional, setShowAdditional] = useState(false);
  const [hideTracked, setHideTracked] = useState(!(earning.rateId === null) && earning.rateId === -1);

  //PI-8637 Updated it so we handle both masked and unmasked rates in this method so we can format the salaryDescription for earings code 2 and 3
  const payRateOptions = filteredRateOptions(check?.payRateDropdown ?? [], earningCodeSettings, earningCode, earning.tranDigit || 2, showRatesOnTransmittal);

  const renderedRates = payRateOptions?.map((item) => {
    return {
      ...item,
      hourlyDescription: !isNaN(parseFloatNumberWithCommas(item.hourlyDescription)) ? formatWithCommas(item.hourlyDescription) : item.hourlyDescription,
      salaryDescription: !isNaN(parseFloatNumberWithCommas(item.salaryDescription)) ? formatWithCommas(item.salaryDescription) : item.salaryDescription,
    };
  });
  
  const setTrackedFields = (value: boolean) => {
    earning.tracked = value;
  };

  const changeShowAlt = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setShowAlt(e.target.value === '0');
    setHideTracked(e.target.value === '-1');
    //Only clear the tracked value if changing to earnings only
    if (e.target.value === '-1') {
      setTrackedFields(false);
    }
  };
  
  const generateLabel = (_earning: Partial<ArrayField<TransmittalEarning, 'id'>>) => {
    if (_earning.tranDigit === 1) {
      return showRatesOnTransmittal || ((_earning?.rateId ?? 999) <= 0) ? 'salaryDescription' : 'salaryMask';
    }
    return showRatesOnTransmittal || ((_earning?.rateId ?? 999) <= 0) ? 'hourlyDescription' : 'hourlyMask';
  };
  
  return (
    <div
      key={earning.id}
      className="border-bottom mb-3"
      style={hideTracked ? { backgroundColor: '#fff8bd' } : { }}
    >
      <div className="row align-items-start">
        <input
          type="hidden"
          name={`checks[${checkIndex}].earnings[${earningIndex}].transmittalEarningsId`}
          defaultValue={earning.transmittalEarningsId}
          ref={register({
            valueAsNumber: true,
          })}
        />
        <input
          type="hidden"
          name={`checks[${checkIndex}].earnings[${earningIndex}].tranDigit`}
          defaultValue={earning.tranDigit}
          ref={register({
            valueAsNumber: true,
          })}
        />
        <SelectGrp
          groupClass="col"
          options={earningsCode}
          name={`checks[${checkIndex}].earnings[${earningIndex}].earningsCode`}
          id="earningsCode"
          showId={true}
          addEmptyValue={true}
          errorMsg="Required"
          errors={
            errors?.checks?.[checkIndex]?.earnings?.[earningIndex]
              ?.earningsCode
          }
          ref={register({
            required: (formAmount !== 0) ? 'Please choose an Earnings Code' : false,
          })}
          label={'Description'}
          defaultValue={earning.earningsCode}
          readOnly={isReadOnly}
        />
        <InputGrp
          groupClass="number-underline col"
          label="Amount"
          name={`checks[${checkIndex}].earnings[${earningIndex}].amount`}
          type={'text'}
          ref={register({
            valueAsNumber: true,
          })}
          places={payrollsUserOptions?.hoursDecimals}
          defaultValue={formatWithCommas(formAmount ?? 0)}
          readOnly={isReadOnly}
          setValue={setValue}
          formatNumber
          hiddenValue={formAmount ?? 0}
          hiddenRef={register({
            valueAsNumber: true,
          })}
        />
        <div className="col">
          <InputGrp
            groupClass={`col number-underline ${showAlt ? '' : 'd-none'}`}
            label="Rate/Earnings"
            name={`checks[${checkIndex}].earnings[${earningIndex}].altRate`}
            ref={register({ valueAsNumber: true })}
            defaultValue={formatWithCommas(formAltRate ?? 0, 4)}
            setValue={setValue}
            formatNumber
            places={4}
            hiddenValue={formAltRate ?? 0}
            hiddenRef={register({ valueAsNumber: true })}
            readOnly={isReadOnly}
          />
          <SelectGrp
            options={renderedRates.filter((v, i, a) => {
              return (
                a.findIndex((o) => {
                  return o.rateId === v.rateId;
                }) === i
              );
            })}
            name={`checks[${checkIndex}].earnings[${earningIndex}].rateId`}
            groupClass="col-auto"
            label={showAlt ? '' : 'Rate/Earnings'}
            valueField="rateId"
            labelField={generateLabel(earning)}
            errors={errors.rateId}
            ref={register({ valueAsNumber: true })}
            defaultValue={
              earning.rateId === null ? 0 : earning.rateId
            }
            onChange={changeShowAlt}
            readOnly={isReadOnly}
          />
        </div>
        <div className="col-auto mb-2">
          {!isReadOnly ? <button
            type="button"
            className="mt-3 btn btn-link dm-grid-action-title p-0"
            onClick={() => {
              remove(earningIndex);
            }}
          >
            Delete
            <Icon
              name="minus-circle"
              className="fa-minus-circle"
            />
          </button> : null}
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col mt-2">
            <div
              role="button"
              className="dm-card-subtitle5"
              onClick={() => { setShowAdditional(!showAdditional); }}
            >
              <Icon
                name={showAdditional ? 'chevron-down' : 'chevron-right'}
                className="mr-2"
              />
              Additional
            </div>
          </div>
        </div>
        <Collapse in={showAdditional}>
          <div>
            <div className="d-flex flex-column">
              {deptOpts.length !== 0 ? <SelectModalGrp
                name={`checks[${checkIndex}].earnings[${earningIndex}].dept`}
                label="Dept"
                modalTitle="Departments"
                addOptionText="Department"
                labelField="deptDesc"
                valueField="deptCode"
                idField="deptId"
                dropdownName="Department"
                formComponent={DepartmentOptionForm}
                value={watchedDept}
                setValue={setValue}
                disabled={isReadOnly}
                control={control}
                options={deptOpts}
                menuPortalZIndex={9999}
                searchable
                selectOnEnter
                showId
              /> : null}
              {subdeptOpts.length !== 0 ? <SelectModalGrp
                name={`checks[${checkIndex}].earnings[${earningIndex}].subDept`}
                label="Sub Dept"
                modalTitle="Sub Departments"
                addOptionText="Sub Department"
                labelField="subDeptDesc"
                valueField="subDeptCode"
                idField="subDeptID"
                dropdownName="SubDepartment"
                formComponent={DepartmentOptionForm}
                value={watchedSubDept}
                setValue={setValue}
                disabled={isReadOnly}
                control={control}
                options={subdeptOpts}
                menuPortalZIndex={9999}
                searchable
                selectOnEnter
                showId
              /> : null}
              {subdept2Opts.length !== 0 ? <SelectModalGrp
                name={`checks[${checkIndex}].earnings[${earningIndex}].subDept2`}
                label="Sub Dept 2"
                modalTitle="Sub Departments 2"
                addOptionText="Sub Department 2"
                labelField="sub2Desc"
                valueField="sub2Code"
                idField="subDept2ID"
                dropdownName="SubDepartment2"
                formComponent={DepartmentOptionForm}
                value={watchedSubDept2}
                setValue={setValue}
                disabled={isReadOnly}
                control={control}
                options={subdept2Opts}
                menuPortalZIndex={9999}
                searchable
                selectOnEnter
                showId
              /> : null}
              <SelectGrp
                {...fs.checkIndexty}
                options={taxEntities.filter(
                  (e) => { return e.agency === 'City'; },
                )}
                label="CITY CODE"
                name={`checks[${checkIndex}].earnings[${earningIndex}].cityTaxEntityId`}
                id="cityTaxEntityId"
                errors={errors.cityTaxEntityId}
                ref={register()}
                addEmptyValue={true}
                valueAsNumber={true}
                defaultValue={earning.cityTaxEntityId}
                readOnly={isReadOnly}
              />

              <SelectGrp
                {...fs.shiftPremiumId}
                options={shiftCodes}
                label="SHIFT"
                name={`checks[${checkIndex}].earnings[${earningIndex}].shiftPremiumId`}
                id="shiftPremiumId"
                errors={errors.shiftPremiumId}
                addEmptyValue={true}
                ref={register()}
                defaultValue={earning.shiftPremiumId}
                readOnly={isReadOnly}
              />

              {!hideTracked ?
                <SelectGrp
                  options={empTrackedOpts}
                  name={`checks[${checkIndex}].earnings[${earningIndex}].tracked`}
                  ref={register()}
                  label="Tracked"
                  defaultValue={earning.tracked}
                  readOnly={isReadOnly}
                /> : null
              }
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default TransmittalModalEarningsItem;
