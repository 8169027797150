import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { EmpDocComp, HttpResponse } from '../../models';
import { EmployeeDocCompService } from '../../services';
import {
  createEmployeeDocComp,
  deleteEmployeeDocComp,
  handleError,
  handleSuccess,
  loadEmployeeDocComp,
  storeEmployeeDocComp,
  storeEmployeeDocComps,
  updateEmployeeDocComp,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadEmployeeDocComp$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmployeeDocComp.type),
    switchMap((action: { payload: { protectedEmpNo: string; }; }) => {
      return from(
        EmployeeDocCompService.getEmployeeDocComp(
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: EmpDocComp) => { return storeEmployeeDocComps(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createEmployeeDocComp$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createEmployeeDocComp.type),
    switchMap((action: { payload: EmpDocComp; }) => {
      return from(
        EmployeeDocCompService.postEmployeeDocComp(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<any>) => {
          return [
            storeEmployeeDocComp(res.value),
            handleSuccess(res.messages[0]),
          ];
        }),

        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEmployeeDocComp$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateEmployeeDocComp.type),
    switchMap((action: { payload: EmpDocComp; }) => {
      return from(
        EmployeeDocCompService.putEmployeeDocComp(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<any>) => {
          return [
            storeEmployeeDocComp(res.value),
            handleSuccess(res.messages[0]),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteEmployeeDocComp$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteEmployeeDocComp.type),
    switchMap((action: { payload: EmpDocComp; }) => {
      return from(
        EmployeeDocCompService.deleteEmployeeDocComp(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        map(() => {
          return loadEmployeeDocComp({
            protectedEmpNo: action.payload.protectedEmpNo ?? '',
          });
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadEmployeeDocComp$,
  createEmployeeDocComp$,
  updateEmployeeDocComp$,
  deleteEmployeeDocComp$,
];
