import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDeductionCodes, getDeductionFrequencies, getDeductionUnits, getGlobalDeductionsForGrid } from 'core/store/selectors';
import GlobalMasterWithDeductions from './GlobalMasterWithDeductions';
import GlobalMasterDeductions from './GlobalMasterDeductions';
import { clearGlobalDeductions, loadGlobalDeductions, upsertGlobalDeductions } from 'core/store/actions';
import { GlobalDeductionEmployee, GlobalDeductionEmployeeForGrid, GlobalDeductionForGrid } from 'core/models';
import { SelectGrpInLine } from 'core/components/form-controls';

const GlobalMasterDeductionsPage: React.FC = () => {
  const dispatch = useDispatch();

  const [employees, setEmployees] = useState<GlobalDeductionEmployee[]>([]);
  const [selectedDeductionCode, setDeductionCode] = useState('');
  const [selectedDeductionName, setDeductionName] = useState('');

  const deductionCodeOpts = useSelector(getDeductionCodes);
  const dedFreqOpts = useSelector(getDeductionFrequencies);
  const dedUnitOpts = useSelector(getDeductionUnits);
  const globalDeds: any[] = useSelector(getGlobalDeductionsForGrid);

  useEffect(() => {
    selectedDeductionCode
      ? dispatch(loadGlobalDeductions(+selectedDeductionCode))
      : dispatch(clearGlobalDeductions());
    setEmployees([]);
  }, [selectedDeductionCode]);

  useEffect(() => {
    setEmployees([]);
  }, [globalDeds]);

  const deductionChangedHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDeductionCode(e.target.value);
    setDeductionName(e.target.options[e.target.selectedIndex].text);
  };

  const onSave = () => {
    let updates: GlobalDeductionEmployee[] = [];
    globalDeds.forEach((gd: GlobalDeductionForGrid) => {
      if (gd.newAmount || gd.newFrequency || gd.newUnit) {
        updates = updates.concat(gd.employees
          .filter((e: GlobalDeductionEmployeeForGrid) => {return e.selected;})
          .map((e: GlobalDeductionEmployeeForGrid) => {
            return {
              clientNo: e.clientNo,
              empID: e.empID,
              empNo: e.empNo,
              name: e.name,
              dedID: e.dedID,
              dedNo: e.dedNo,
              amount: gd.newAmount ? +gd.newAmount : e.amount,
              freq: gd.newFrequency ? gd.newFrequency : e.freq,
              unit: gd.newUnit ? gd.newUnit : e.unit,
            };
          }));
      }
    });
    dispatch(upsertGlobalDeductions({ dedNo: +selectedDeductionCode,
      data: updates }));
  };

  return (
    <div className="col">
      <div className="row">
        <div className="col-12">
          <div className="d-flex flex-direction-row">
            <div className="dm-page-head pr-3">Global Master Deductions</div>
            <SelectGrpInLine
              name="deduction-select"
              label=" "
              labelWidth="2"
              showId={true}
              addEmptyValue={true}
              groupClass="groupClass15"
              groupStyle={{ alignSelf: 'flex-end',
                paddingBottom: '6px' }}
              options={deductionCodeOpts}
              onChange={(e: any) => {return deductionChangedHandler(e);}}
            />
          </div>
        </div>
      </div>
      <hr className="dm-page-hr" />
      <div className="row">
        <div className="col-12 col-md-6 col-lg-5 pr-md-0">
          {dedFreqOpts?.length > 0 && dedUnitOpts?.length > 0 &&
          <GlobalMasterDeductions
            data={globalDeds}
            setEmployees={setEmployees}
            dedFreqOpts={dedFreqOpts}
            dedUnitOpts={dedUnitOpts}
            deductionCode={selectedDeductionCode}
            deductionName={selectedDeductionName}
          />}
        </div>
        <div className="col-12 col-md-6 col-lg-5" >
          {dedFreqOpts?.length > 0 && dedUnitOpts?.length > 0 &&
          <GlobalMasterWithDeductions
            employees={employees}
            dedFreqOpts={dedFreqOpts}
            dedUnitOpts={dedUnitOpts}
          />}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 text-right">
          <button
            type="button"
            className="btn btn-primary orange-outline-button"
          >Cancel</button>
          <button
            type="button"
            className="btn btn-primary orange-button ml-2"
            onClick={onSave}
          >Save</button>
        </div>
      </div>
    </div>
  );
};

export default GlobalMasterDeductionsPage;
