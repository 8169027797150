import React, { useState } from 'react';
import { Control } from 'react-hook-form';
import { CheckboxGrp, ControlDatePickerGrp, InputGrp } from '../../../core/components/form-controls';
import { FieldInputSettings } from '../../../core/components/form-controls/types';
import { SSN_VALIDATION } from '../../../core/constants';
import { formatSSN } from '../../../utilities/utilities';
import { CoveredIndividual } from '../../../core/models';
import Icon from 'core/components/shared/Icon';

const fs: FieldInputSettings = {
  firstName: {
    name: 'firstName',
    label: 'FIRST NAME',
    required: true,
    groupClass: 'gc10 mw100'
  },
  middleName: {
    name: 'middleName',
    label: 'MIDDLE INIT',
    groupClass: 'gc08 mw75'
  },
  lastName: {
    name: 'lastName',
    label: 'LAST NAME',
    required: true,
    groupClass: 'gc10 mw100'
  },
  suffix: {
    name: 'suffix',
    label: 'SUFFIX',
    groupClass: 'gc06 mw50'
  },
  ssn: {
    name: 'ssn',
    label: 'SSN',
    groupClass: 'gc10 mw100',
    required: true
  },
  birthDate: {
    name: 'birthDate',
    label: 'BIRTH DATE',
    groupClass: 'gc12 mw100'
  },
  coveredAll12Months: {
    name: 'coveredAll12Months',
    label: 'ALL 12 MONTHS',
    groupClass: 'gc10 mw100'
  },
  january: {
    name: 'january',
    label: 'JAN',
    groupClass: 'gc03 text-center pr-0 mr-0'
  },
  february: {
    name: 'february',
    label: 'FEB',
    groupClass: 'gc03 text-center pr-0 mr-0'
  },
  march: {
    name: 'march',
    label: 'MAR',
    groupClass: 'gc03 text-center pr-0 mr-0'
  },
  april: {
    name: 'april',
    label: 'APR',
    groupClass: 'gc03 text-center pr-0 mr-0'
  },
  may: {
    name: 'may',
    label: 'MAY',
    groupClass: 'gc03 text-center pr-0 mr-0'
  },
  june: {
    name: 'june',
    label: 'JUN',
    groupClass: 'gc03 text-center pr-0 mr-0'
  },
  july: {
    name: 'july',
    label: 'JUL',
    groupClass: 'gc03 text-center pr-0 mr-0'
  },
  august: {
    name: 'august',
    label: 'AUG',
    groupClass: 'gc03 text-center pr-0 mr-0'
  },
  september: {
    name: 'september',
    label: 'SEP',
    groupClass: 'gc03 text-center pr-0 mr-0'
  },
  october: {
    name: 'october',
    label: 'OCT',
    groupClass: 'gc03 text-center pr-0 mr-0'
  },
  november: {
    name: 'november',
    label: 'NOV',
    groupClass: 'gc03 text-center pr-0 mr-0'
  },
  december: {
    name: 'december',
    label: 'DEC',
    groupClass: 'gc03 text-center pr-0 mr-0'
  },
};

type PropTypes = {
  formMethods: any;
  control: Control;
  item: CoveredIndividual;
  index: number;
  onDeleteCI: (item: CoveredIndividual, index: number) => void;
};

const ACACoveredIndividualItem: React.FC<PropTypes> = ({ formMethods: { errors, register, setValue }, control, item, index, onDeleteCI }) => {

  const [monthReadOnly, setMonthReadOnly] = useState(item.coveredAll12Months);

  const onChangeCIAll12Months = (e: any) => {
    const checked = e.currentTarget.checked;
    if (checked) {
      setValue(`coveredIndividuals[${index}].january`, false);
      setValue(`coveredIndividuals[${index}].february`, false);
      setValue(`coveredIndividuals[${index}].march`, false);
      setValue(`coveredIndividuals[${index}].april`, false);
      setValue(`coveredIndividuals[${index}].may`, false);
      setValue(`coveredIndividuals[${index}].june`, false);
      setValue(`coveredIndividuals[${index}].july`, false);
      setValue(`coveredIndividuals[${index}].august`, false);
      setValue(`coveredIndividuals[${index}].september`, false);
      setValue(`coveredIndividuals[${index}].october`, false);
      setValue(`coveredIndividuals[${index}].november`, false);
      setValue(`coveredIndividuals[${index}].december`, false);
    }
    setMonthReadOnly(checked);
  };

  return (
    <div className="d-flex flex-row">
      <input type="hidden"
        name={`coveredIndividuals[${index}].dependentId`}
        value={item.dependentId}
        ref={register()} />
      <input type="hidden"
        name={`coveredIndividuals[${index}].year`}
        value={item.year}
        ref={register()} />
      <InputGrp {...fs.firstName}
        name={`coveredIndividuals[${index}].firstName`}
        errors={errors?.coveredIndividuals?.firstName}
        ref={register()}
        defaultValue={item.firstName} />
      <InputGrp {...fs.middleName}
        name={`coveredIndividuals[${index}].middleName`}
        errors={errors?.coveredIndividuals?.middleName}
        ref={register()}
        defaultValue={item.middleName} />
      <InputGrp {...fs.lastName}
        name={`coveredIndividuals[${index}].lastName`}
        errors={errors?.coveredIndividuals?.lastName}
        ref={register()}
        defaultValue={item.lastName} />
      <InputGrp {...fs.suffix}
        name={`coveredIndividuals[${index}].suffix`}
        errors={errors?.coveredIndividuals?.suffix}
        ref={register()}
        defaultValue={item.suffix} />
      <InputGrp
        {...fs.ssn}
        name={`coveredIndividuals[${index}].ssn`}
        errors={errors?.coveredIndividuals?.ssn}
        defaultValue={item.ssn && formatSSN(item.ssn)}
        onChange={(e: any) => {
          const { value } = e?.target;
          e.target.value = formatSSN(value);
        }}
        ref={register({ pattern: SSN_VALIDATION })} />

      <ControlDatePickerGrp
        {...fs.birthDate}
        inputClass="gc10 mw75"
        name={`coveredIndividuals[${index}].birthDate`}
        value={item.birthDate}
        control={control}
      />

      <CheckboxGrp {...fs.coveredAll12Months}
        name={`coveredIndividuals[${index}].coveredAll12Months`}
        ref={register()}
        defaultChecked={item.coveredAll12Months}
        onChange={onChangeCIAll12Months} />
      <CheckboxGrp {...fs.january}
        name={`coveredIndividuals[${index}].january`}
        ref={register()}
        defaultChecked={item.january}
        disabled={monthReadOnly} />
      <CheckboxGrp {...fs.february}
        name={`coveredIndividuals[${index}].february`}
        ref={register()}
        defaultChecked={item.february}
        disabled={monthReadOnly} />
      <CheckboxGrp {...fs.march}
        name={`coveredIndividuals[${index}].march`}
        ref={register()}
        defaultChecked={item.march}
        disabled={monthReadOnly} />
      <CheckboxGrp {...fs.april}
        name={`coveredIndividuals[${index}].april`}
        ref={register()}
        defaultChecked={item.april}
        disabled={monthReadOnly} />
      <CheckboxGrp {...fs.may}
        name={`coveredIndividuals[${index}].may`}
        ref={register()}
        defaultChecked={item.may}
        disabled={monthReadOnly} />
      <CheckboxGrp {...fs.june}
        name={`coveredIndividuals[${index}].june`}
        ref={register()}
        defaultChecked={item.june}
        disabled={monthReadOnly} />
      <CheckboxGrp {...fs.july}
        name={`coveredIndividuals[${index}].july`}
        ref={register()}
        defaultChecked={item.july}
        disabled={monthReadOnly} />
      <CheckboxGrp {...fs.august}
        name={`coveredIndividuals[${index}].august`}
        ref={register()}
        defaultChecked={item.august}
        disabled={monthReadOnly} />
      <CheckboxGrp {...fs.september}
        name={`coveredIndividuals[${index}].september`}
        ref={register()}
        defaultChecked={item.september}
        disabled={monthReadOnly} />
      <CheckboxGrp {...fs.october}
        name={`coveredIndividuals[${index}].october`}
        ref={register()}
        defaultChecked={item.october}
        disabled={monthReadOnly} />
      <CheckboxGrp {...fs.november}
        name={`coveredIndividuals[${index}].november`}
        ref={register()}
        defaultChecked={item.november}
        disabled={monthReadOnly} />
      <CheckboxGrp {...fs.december}
        name={`coveredIndividuals[${index}].december`}
        ref={register()}
        defaultChecked={item.december}
        disabled={monthReadOnly} />

      <div className="mt-3 ml-3"
        onClick={() => { return onDeleteCI(item, index); }}>
        <Icon name="trash"
          className="fa-save" />
      </div>
    </div>
  );

};

export default ACACoveredIndividualItem;