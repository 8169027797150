import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from '@ag-grid-enterprise/all-modules';
import Icon from 'core/components/shared/Icon';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ControlDatePickerGrpInLine } from 'core/components/form-controls';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { PensionReportRequest, PensionSetting } from 'core/models/PayrollReports.model';
import {
  clearPensionReport,
  downloadPensionReport,
  loadEarningCodesDropdown,
  loadPensionSettings,
} from 'core/store/actions';
import AGEditButtonRendererComponent from 'utilities/ag-grid-renderers/AGEditButtonRendererComponent';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import PensionSettingsModal from '../../payroll-reports/PensionReport/PensionSettings.modal';

const PensionReportPage = () => {
  const dispatch = useAppDispatch();

  const report = useAppSelector((state) => { return state.payrollReports.pensionReport; });
  const client = useAppSelector((state) => { return state.client.client; });
  const pensionSettings = useAppSelector((state) => { return state.payrollReports.pensionSettings; });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedSetting, setSelectedSetting] = useState<PensionSetting | undefined>(undefined);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [selectedSettingId, setSelectedSettingId] = useState<number | null>();

  const { control, getValues, watch, errors } = useForm<PensionReportRequest>({});

  const fromDate = watch('fromDate');
  const toDate = watch('toDate');

  useEffect(() => {
    dispatch(loadEarningCodesDropdown());
    dispatch(loadPensionSettings());
  }, []);

  useEffect(() => {
    if (!selectedSetting) return;
    setShowModal(true);
  }, [selectedSetting]);

  const downloadReport = () => {
    if (!(selectedSettingId && client?.clientNo)) return;
    const submitData: PensionReportRequest = {
      clientNo: client?.clientNo,
      fromDate: new Date(getValues('fromDate')).toISOString(),
      toDate: new Date(getValues('toDate')).toISOString(),
      settingID: selectedSettingId,
    };
    dispatch(downloadPensionReport(submitData));
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const onSettingEdit = (rowData: PensionSetting) => {
    if (!rowData) return;
    setSelectedSetting({ ...rowData });
  };
  const onSelectionChanged = () => {
    if (!gridApi) return;
    const selectedRows = gridApi.getSelectedRows();
    if (selectedRows?.length <= 0) return;
    setSelectedSettingId(selectedRows[0].settingID);
  };

  const onCancel = () => {
  };

  const hidePdfViewer = () => {
    dispatch(clearPensionReport());
  };

  const columns: ColDef[] = [
    {
      field: 'settingID',
      hide: true,
    },
    {
      field: 'select',
      headerName: '',
      editable: false,
      checkboxSelection: true,
      width: 40,
    },
    {
      field: 'settingName',
      headerName: 'Setting Name',
      sortable: true,
      width: 300,
      flex: 1,
      cellStyle: {
        textAlign: 'left',
        fontSize: 14,
        color: '#00558c',
      },
    },
    {
      field: 'edit',
      editable: false,
      cellRendererParams: { clickHandler: onSettingEdit },
      cellRenderer: 'editButtonRendererComponent',
      width: 60,
    },
  ];

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    columnDefs: columns,
    rowSelection: 'single',
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    frameworkComponents: {
      editButtonRendererComponent: AGEditButtonRendererComponent,
    },
  };

  return (
    <div className="dm-panel dm-panel-border">
      <div className="dm-grid-title">Pension Report</div>
      <hr className="dm-panel-hr" />
      <div className="d-flex flex-row flex-grow-1">
        <div className="d-flex flex-row flex-grow-1">
          <div>
            <ControlDatePickerGrpInLine
              name={'fromDate'}
              label={'From'}
              labelWidth={40}
              groupClass={'groupClass50'}
              required={true}
              control={control}
              errors={errors?.fromDate}
              rules={{
                required: 'From Date is required',
              }}
            />
            <ControlDatePickerGrpInLine
              name={'toDate'}
              label={'To'}
              labelWidth={40}
              groupClass={'groupClass50'}
              required={true}
              control={control}
              minDate={DateTime.fromJSDate(new Date(fromDate)).plus({ day: 1 }).toJSDate()}
              rules={{
                required: 'To Date is required',
              }}
            />
          </div>
        </div>
        <div className="flex-grow-1 text-right">
          <button
            type="button"
            className="orange-outline-button mr-2"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="orange-button mr-2"
            onClick={downloadReport}
            disabled={(!fromDate || !toDate || !selectedSettingId)}
          >
            Run Report
          </button>

        </div>
      </div>
      <div
        className="flex-grow-1"
        style={{ marginTop: 20 }}
      >
        <div className="d-flex flex-row">
          <div className="dm-card-subtitle2 mb-2">Choose Setting Name</div>
          <div className=" flex-grow-1 text-right">
            <button
              className="btn btn-link dm-grid-action-title p-0 mr-2"
              type="button"
              onClick={() => { setSelectedSetting(undefined); setShowModal(true); }}
            >
              Add New Setting	<Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          </div>
        </div>
        <div className="ag-theme-balham mt-2">
          <AgGridReact
            gridOptions={gridOptions}
            rowData={pensionSettings}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      </div>
      {showModal ?
        <PensionSettingsModal
          setting={selectedSetting}
          show={showModal}
          onHide={() => { return setShowModal(false); }}
        /> : null}

      {report ? <PDFViewerModal
        show={report.length > 0}
        pdfData={report}
        onHide={hidePdfViewer}
        reportName="PensionReport"
      /> : null}
    </div>
  );
};

export default PensionReportPage;