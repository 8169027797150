import React, { CSSProperties } from 'react';

type ErrorPageStyle = {
  [key: string]: CSSProperties
};

type Props = {
  title?: string;
  subTitle?: string;
};

const errorPageStyles: ErrorPageStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
    userSelect: 'none',
  },
  title: {
    fontSize: '6rem',
  },
  subTitle: {
    fontSize: '2rem',
  },
};

const ErrorPage = ({
  title = 'Error',
  subTitle = 'Something went wrong',
}: Props) => {
  return (
    <div style={errorPageStyles.container}>
      <div style={errorPageStyles.title}>{title}</div>
      <div style={errorPageStyles.subTitle}>{subTitle}</div>
    </div>
  );
};

export default ErrorPage;