import Modal from 'core/components/modals/Modal';
import React, { CSSProperties } from 'react';

type PropTypes = {
  show: boolean;
  title: string;
  body: string;
  onHide: () => void;
  onConfirm: (confirm: boolean) => void;
};

const bodyCSS: CSSProperties = {
  whiteSpace: 'pre-line'
};

const ConfirmModal: React.FC<PropTypes> = ({ show, title, body, onConfirm, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      title={title}
    >
      <div className="row">
        <div className="col-12">
          <div className="col-12">
            <div className="modal-error-panel">
              <div style={bodyCSS}>{body}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-9 text-right">
          <button
            type="button"
            className="btn btn-primary orange-outline-button"
            onClick={() => {
              onConfirm(false);
            }}
          >
            No
          </button>
        </div>
        <div className="col-3">
          <button
            type="button"
            className="btn btn-primary orange-button"
            onClick={() => {
              onConfirm(true);
            }}
          >
            Yes
          </button>
        </div>
      </div>

    </Modal>
  );
};

export default ConfirmModal;
