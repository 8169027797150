import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { DMDropdownMenu } from 'core/components/form-controls';
import { DMDropdownDataModel } from 'core/components/form-controls/DMDropDown';
import { EarningsReportType } from 'core/models/EarningsReports.model';
import { storeStandardReports } from 'core/store/actions/earnings-reports.action';
import { useAppDispatch } from 'utilities/hooks';
import EarningsReports from '../earnings-reports/earnings-reports';
import HamburgerMenu from 'core/components/shared/HamburgerMenu';

const reportsDD: DMDropdownDataModel = {
  id: 'reports-dd',
  title: 'Reports',
  items: [
    {
      id: 'reports-employee-deductions',
      text: 'Deductions',
      href: '#',
      modal: true,
    },
    {
      id: 'reports-employee-earnings-hours-total',
      text: 'Earnings/Hours Total',
      href: '#',
      modal: true,
    },
    {
      id: 'reports-employee-hours-only',
      text: 'Hours Only',
      href: '#',
      modal: true,
    },
    {
      id: 'reports-employee-gross-to-net',
      text: 'Gross to Net',
      href: '#',
      modal: true,
    },
    {
      id: 'reports-employee-company-paid-benefits',
      text: 'Company Paid Benefits',
      href: '#',
      modal: true,
    },
    {
      id: 'reports-employee-employee-gross',
      text: 'Employee Gross',
      href: '#',
      modal: true,
    },
    {
      id: 'reports-employee-deductions-earnings-taxes',
      text: 'Deductions/Earnings/Taxes',
      href: '#',
      modal: true,
    },
  ],
};

type EmployeeToolbarProps = {
  toggleSidebar: () => void;
  showSidebarMenuIcon: boolean;
  isMaster: boolean;
};

type NavArrayItem = {
  type: string;
  text?: string;
  href?: string;
  data?: DMDropdownDataModel;
};

const EmployeeInformationEarningsToolbar: React.FC<EmployeeToolbarProps> = ({
  toggleSidebar,
  showSidebarMenuIcon,
  isMaster,
}) => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const [showEarningsReport, setShowEarningsReport] = useState(false);
  const [earningsReportType, setEarningsReportType] = useState<EarningsReportType | null>(null);

  const navArray: NavArrayItem[] = [
    {
      type: 'link',
      text: 'Employee Earnings',
      href: '/employee-information/earnings',
    },
    {
      type: 'link',
      text: 'Employee Accruals',
      href: '/employee-information/accruals',
    },
    {
      type: 'dropdown',
      text: 'Reports',
      href: '/employee-upload',
      data: reportsDD,
    },
  ];

  if (isMaster) {
    navArray.push({
      type: 'dropdown',
      data: reportsDD,
    });
  }

  const onQuickOnboard = () => {return history.push('/quick-onboard');};

  const onMenuSelect = (menuId: string) => {
    switch (menuId) {
      case 'reports-employee-deductions':
        setEarningsReportType(EarningsReportType.DeductionReport);
        break;
      case 'reports-employee-earnings-hours-total':
        setEarningsReportType(
          EarningsReportType.EarningsAndHoursReport,
        );
        break;
      case 'reports-employee-hours-only':
        setEarningsReportType(EarningsReportType.HoursReport);
        break;
      case 'reports-employee-gross-to-net':
        setEarningsReportType(EarningsReportType.GrossToNetReport);
        break;
      case 'reports-employee-company-paid-benefits':
        setEarningsReportType(
          EarningsReportType.CompanyPaidBenefitsReport,
        );
        break;
      case 'reports-employee-employee-gross':
        setEarningsReportType(EarningsReportType.GrossReport);
        break;
      case 'reports-employee-deductions-earnings-taxes':
        setEarningsReportType(
          EarningsReportType.DeductionsEarningsTaxesReport,
        );
        break;
    }
  };

  const nav = () => {
    if (showSidebarMenuIcon) {
      return (
        <HamburgerMenu
          toggleSidebar={toggleSidebar}  
        />
      );
    } else {
      return navArray.map((n, i) => {
        switch (n.type) {
          case 'link':
            return n.href ? (
              <NavLink
                key={i}
                to={n.href}
              >
                {n.text}
              </NavLink>
            ) : null;
          case 'dropdown':
            return n.data ? (
              <DMDropdownMenu
                key={i}
                data={n.data}
                toggleClass="dm-toolbar-nav"
                whiteChevron={true}
                onSelect={onMenuSelect}
              />
            ) : null;
          default:
            return null;
        }
      });
    }
  };

  useEffect(() => {
    if (earningsReportType) {
      setShowEarningsReport(earningsReportType.length > 0);
    }
  }, [earningsReportType]);

  const hideShowEarningsReportModal = () => {
    setEarningsReportType(null);
    dispatch(storeStandardReports(''));
  };

  return (
    <div className="dm-toolbar">
      {nav()}
      <Button
        className="orange-button ml-auto mr-2"
        onClick={onQuickOnboard}
      >
        Quick Onboard
      </Button>
      {showEarningsReport && earningsReportType && (
        <Modal
          show={showEarningsReport}
          onHide={hideShowEarningsReportModal}
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>Earnings Reports</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EarningsReports
              earningsReportType={earningsReportType}
              show={showEarningsReport}
              onHide={hideShowEarningsReportModal}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default EmployeeInformationEarningsToolbar;
