import { AxiosResponse } from "axios";
import {
  OhioI29Request, WH347_Certified_Report_Request, Week_Month_JTD_Job_Cost_Report_Request,
  JobDetailRequest, ApprovedJobsReportRequest,
  BrickLayerCementMasonRate, FringeReportRequest, ElectricalFringeReportRequest, EEO_CC257Request,
  WorkersCompenationReportRequest,
  NationalAgreementRequest,
  JobLaborRequest,
  JobGrossSummaryReportRequest,
  JobToDateGrossSummaryReportRequest,
  EmployeeTimeCardReqest,
  RateMasterRequest,
  RateMaster,
  OCIPReportRequest,
  EmployeeGrossSummaryReportRequest,
} from 'core/models/ContractorReports.model';
import { ContractorReportEndpoints } from './URL';
import { API_EMPLOYEE_MASTER } from './API';
import { HttpResponse } from '../models';
import { CommonService } from './common.service';
import { IData } from 'core/store/slices/contractor-reports/fringe.slice';


const postOhioI29 = (request: OhioI29Request): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.POST_OHIO_I29(
      CommonService.getProtectedClientNo()), request);
};
const postWH347CertifiedReport = (request: WH347_Certified_Report_Request): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.POST_WH347_Certified_Report(
      CommonService.getProtectedClientNo()), request);
};

const postWeekMonthJTDJobCostReport = (request: Week_Month_JTD_Job_Cost_Report_Request): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.POST_WEEK_MONTH_JTD_Job_Cost_Report(
      CommonService.getProtectedClientNo()), request);
};

const postJobDetailReport = (request: JobDetailRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.POST_JOB_DETAIL_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postElectricalFringeReport = (request: ElectricalFringeReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.POST_ELECTRICAL_FRINGE_REPORT(
      CommonService.getProtectedClientNo()), request);
};
const postFringeReport = (request: FringeReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.POST_FRINGE_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postApprovedjobsReport = (request: ApprovedJobsReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.POST_APPROVED_JOB_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const getBrickLayerCementRates = (): Promise<AxiosResponse<BrickLayerCementMasonRate>> => {
  return API_EMPLOYEE_MASTER.get<BrickLayerCementMasonRate>
    (ContractorReportEndpoints.GET_BRICK_LAYER_RATES(CommonService.getProtectedClientNo()));
};

const postWorkersCompensationReport = (request: WorkersCompenationReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>
    (ContractorReportEndpoints.POST_WORKERS_COMPENSATION_REPORT(CommonService.getProtectedClientNo()), request);
};
const postEEOCC257Report = (request: EEO_CC257Request): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.POST_EEO_CC2257_REPORT(
      CommonService.getProtectedClientNo()), request);
};


const postNationalAgreementReport = (request: NationalAgreementRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.POST_NATIONAL_AGREEMENT_REPORT(
      CommonService.getProtectedClientNo()), request);
};


const postJobGrossSummaryReport = (request: JobGrossSummaryReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.POST_JOB_GROSS_SUMMARY_REPORT(
      CommonService.getProtectedClientNo()), request);
};


const postJobToDateGrossSummaryReport = (request: JobToDateGrossSummaryReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.POST_JOB_TO_DATE_GROSS_SUMMARY_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postJobLaborReport = (request: JobLaborRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.POST_JOB_LABOR_REPORT(
      CommonService.getProtectedClientNo()), request);
};


const postEmployeeTimeCardReport = (request: EmployeeTimeCardReqest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.POST_EMPLOYEE_TIME_CARD(
      CommonService.getProtectedClientNo()), request);
};

const getRateMaster = (request: RateMasterRequest): Promise<AxiosResponse<RateMaster[]>> => {
  return API_EMPLOYEE_MASTER.get<RateMaster[]>
    (
      `${ContractorReportEndpoints.RATE_MASTER(CommonService.getProtectedClientNo())}?includeFringes=${request.includeFringes}&onlyActive=${request.onlyActive}&onlyCurrent=${request?.onlyCurrent || false}`,
    );
};

const postRateMasterReport = (request: RateMasterRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.RATE_MASTER(
      CommonService.getProtectedClientNo()), request);
};

const postOCIPReport = (request: OCIPReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.POST_OCIP_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postEmployeeGrossSummaryReport = (request: EmployeeGrossSummaryReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.POST_EMPLOYEE_GROSS_SUMMARY_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const getContractorOverview = (request: any): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.get<HttpResponse<string>>(
    ContractorReportEndpoints.GET_CONTRACTOR_OVERVIEW(
      CommonService.getProtectedClientNo()), {
    params: request,
  },
  );
};

const postContractorOverview = (request: any): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ContractorReportEndpoints.POST_CONTRACTOR_OVERVIEW(
      CommonService.getProtectedClientNo()),
    request,
  );
};

const getContractorFringe = (request: any): Promise<AxiosResponse<HttpResponse<IData>>> => {
  return API_EMPLOYEE_MASTER.get<HttpResponse<IData>>(
    ContractorReportEndpoints.GET_CONTRACTOR_FRINGE(
      CommonService.getProtectedClientNo()), {
    params: request,
  },
  );
};

const postContractorFringe = (request: any): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(
    ContractorReportEndpoints.POST_CONTRACTOR_FRINGE(
      CommonService.getProtectedClientNo()),
    request,
  );
};

const updateContractorFringe = (request: any, mapKey: string): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<any>>(
    ContractorReportEndpoints.PUT_CONTRACTOR_FRINGE(
      CommonService.getProtectedClientNo(),
      mapKey,
    ),
    request,
  );
};

const downloadContractorFringe = (request: any): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<{
    areaTradeSubs: any[]
  }>>(
    ContractorReportEndpoints.POST_CONTRACTOR_FRINGE_DOWNLOAD(
      CommonService.getProtectedClientNo()),
    request,
  );
};

const deleteContractorFringe = (mapKey: string) => {
  return API_EMPLOYEE_MASTER.delete(ContractorReportEndpoints.DELETE_CONTRACTOR_FRINGE(CommonService.getProtectedClientNo(), mapKey));
};

export const ContractorReportService = {
  postOhioI29,
  postWH347CertifiedReport,
  postWeekMonthJTDJobCostReport,
  postElectricalFringeReport,
  postJobDetailReport,
  postApprovedjobsReport,
  getBrickLayerCementRates,
  postFringeReport,
  postWorkersCompensationReport,
  postNationalAgreementReport,
  postEEOCC257Report,
  postJobGrossSummaryReport,
  postJobLaborReport,
  postJobToDateGrossSummaryReport,
  postEmployeeTimeCardReport,
  getRateMaster,
  postRateMasterReport,
  postOCIPReport,
  postEmployeeGrossSummaryReport,
  getContractorOverview,
  postContractorOverview,
  getContractorFringe,
  postContractorFringe,
  updateContractorFringe,
  downloadContractorFringe,
  deleteContractorFringe,
};
