import React, { useEffect,useState } from 'react';
import Modal from 'core/components/modals/Modal';
import Table from 'core/components/shared/dm-table/Table';
import { ColumnHeaderData, Row } from 'core/components/shared/dm-table/types';
import { PayrollTimeCardCostCodeInfo, TimeCardDetail } from 'core/models';
import { getAllCostCodes } from 'core/store/actions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { InputGrp } from 'core/components/form-controls';
import styles from './styles.module.scss';

const buildCostCodeRow = (costCode: PayrollTimeCardCostCodeInfo): Row => {
  return {
    id: costCode.costCode,
    cells: [
      { children: costCode.costCode, styleClass: 'td-20' },
      { children: costCode.description, styleClass: 'td-80' },
    ],
  };
};

const costCodeColumns: ColumnHeaderData[] = [
  { title: 'Cost Code', styleClass: 'th-20' },
  { title: 'Description', styleClass: 'th-80' },
];

const matchQuery = (timeCard: PayrollTimeCardCostCodeInfo, _query: string): boolean => {
  return timeCard?.description?.toLowerCase()?.includes(_query.toLowerCase())
         || timeCard?.costCode?.includes(_query);
};

type Props = {
  show: boolean;
  onHide: () => void;
  setControlledField: (property: keyof TimeCardDetail, newValue: any) => void;
};

const CostCodeModal = ({ show, onHide, setControlledField }: Props) => {
  const { allCostCodes } = useAppSelector(({ contractor }) => contractor);
  const [filteredCostCodeInfo, setFilteredCostCodeInfo] = useState<PayrollTimeCardCostCodeInfo[]>(allCostCodes); 
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    //If cost codes already loaded no reason to load them again
    if (allCostCodes) return;
    dispatch(getAllCostCodes());
  }, []);

  const onFilter = (search: string) => {
    const newState = structuredClone(allCostCodes)?.filter((costCode: PayrollTimeCardCostCodeInfo) => matchQuery(costCode, search));
    setFilteredCostCodeInfo(newState);
  };
  
  
  return (
    <Modal
      title="Change Cost Code"
      show={show}
      onHide={onHide}      
    >
      <div className="d-flex flex-column">
        <div className={styles['job-info-modal-top-container']}>
            <label
              htmlFor="job-info-modal-search"
              className={`mb-3 ${styles['job-info-modal-label']}`}
            >Search</label>
            <InputGrp
              placeholder="Enter cost code or description"
              groupClass="m-0"
              inputClass={`gc50 ml-2 mb-3 ${styles['job-info-modal-input-large']}`}
              name="job-info-modal-search"
              type="text"
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                onFilter(target.value);
              }}
            />
          </div>
        <Table
          columns={costCodeColumns}
          rows={filteredCostCodeInfo?.map((costCode) => buildCostCodeRow(costCode))}
          onRowClick={(id) => {
            setControlledField('costCode', id);
            onHide();
          }}
        />
      </div>
    </Modal>
  );
};

export default CostCodeModal;