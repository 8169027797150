import React from 'react';
import MenuItem from 'core/components/form-controls/nested-accordion/MenuItem';
import MenuToggle from 'core/components/form-controls/nested-accordion/MenuToggle';
import NestedAccordion from 'core/components/form-controls/nested-accordion/NestedAccordion';
import { useParams } from 'react-router-dom';
import { EmployeeMasterSection } from 'core/models/ModuleAccess.model';
import { getAccess } from 'utilities/utilities';

const toggleComponents: any[] = [MenuToggle, MenuItem];

type EmpDetailSidebarSubItem = {
  id: string;
  title: string;
  href: string;
  workItemId?: number;
};

type EmpDetailSidebarItem = {
  id: string;
  title: string;
  children: EmpDetailSidebarSubItem[];
};

type HrProfileMapsSidebarProps = {
  sectionAccess: EmployeeMasterSection[] | undefined
};

const HrProfileMapsSidebar = ({ sectionAccess }: HrProfileMapsSidebarProps) => {
  const { downloaderType, userMapId } = useParams<any>();
  const data: EmpDetailSidebarItem[] = [
    {
      id: '0',
      title: 'Employee Hire Info',
      children: [
        {
          id: '0a',
          title: 'ACA History',
          href: `/user-map/${downloaderType}/${userMapId}/general-info-status`,
        },
        {
          id: '0b',
          title: 'General',
          href: `/user-map/${downloaderType}/${userMapId}/general-info`,
        },
      ],
    },
    {
      id: '1',
      title: 'Employee Status',
      children: [
        {
          id: '1a',
          title: 'Company Property',
          href: `/user-map/${downloaderType}/${userMapId}/company-property`,
        },
        {
          id: '1b',
          title: 'Custom',
          href: `/user-map/${downloaderType}/${userMapId}/custom`,
        },
        {
          id: '1c',
          title: 'Dates',
          href: `/user-map/${downloaderType}/${userMapId}/hrdates`,
          workItemId: 7,
        },
        {
          id: '1d',
          title: 'Dependents',
          href: `/user-map/${downloaderType}/${userMapId}/dependents`,
        },
        {
          id: '1e',
          title: 'Emergency Contact',
          href: `/user-map/${downloaderType}/${userMapId}/emergency-contact`,
        },
        {
          id: '1f',
          title: 'General',
          href: `/user-map/${downloaderType}/${userMapId}/general`,
        },
        {
          id: '1g',
          title: 'Pay',
          href: `/user-map/${downloaderType}/${userMapId}/pay`,
        },
        {
          id: '1h',
          title: 'Status',
          href: `/user-map/${downloaderType}/${userMapId}/status`,
        },
      ],
    },
    {
      id: '2',
      title: 'Training',
      children: [
        {
          id: '2a',
          title: 'Employment',
          href: `/user-map/${downloaderType}/${userMapId}/training-employment`,
        },
        {
          id: '2b',
          title: 'Education',
          href: `/user-map/${downloaderType}/${userMapId}/training-education`,
        },
        {
          id: '2c',
          title: 'Licenses',
          href: `/user-map/${downloaderType}/${userMapId}/training-licenses`,
        },
        {
          id: '2d',
          title: 'Skills',
          href: `/user-map/${downloaderType}/${userMapId}/training-skills`,
        },
        {
          id: '2e',
          title: 'Sources and Fees',
          href: `/user-map/${downloaderType}/${userMapId}/training-sources`,
        },
        {
          id: '2f',
          title: 'Training Events',
          href: `/user-map/${downloaderType}/${userMapId}/training-events`,
        },
      ],
    },
    {
      id: '3',
      title: 'Performance Appraisal',
      children: [
        {
          id: '3a',
          title: 'Exit Interview',
          href: `/user-map/${downloaderType}/${userMapId}/exit-interview`,
        },
        {
          id: '3b',
          title: 'Increases',
          href: `/user-map/${downloaderType}/${userMapId}/appraisal-increase`,
        },
        {
          id: '3c',
          title: 'Reviews',
          href: `/user-map/${downloaderType}/${userMapId}/appraisal-reviews`,
        },
      ],
    },
  ];
  
  const securedData: EmpDetailSidebarItem[] = data.map((item) => {
    const modifiedChildren: EmpDetailSidebarSubItem[] = [];
    
    item.children.forEach((subItem) => {
      if (subItem?.workItemId) {
        const access = getAccess(sectionAccess, subItem.workItemId);
        if (access.visible) modifiedChildren.push(subItem);
      } else {
        modifiedChildren.push(subItem);
      }
    });
    
    return { ...item, children: modifiedChildren };
  });

  return (
    <>
      {securedData.map((item) => {
        return (
          <NestedAccordion
            key={item.id}
            level={1}
            item={item}
            toggleComponents={toggleComponents}
            contentStyle={{ margin: '0' }}
            toggleContainerStyle={{ marginTop: '3px' }}
            bodyContainerStyle={{ backgroundColor: 'white' }}
          />
        );
      })}
    </>
  );
};

export default HrProfileMapsSidebar;
