import React, { Dispatch, SetStateAction, FC, lazy, Suspense, useState, ChangeEvent, useEffect } from 'react';
import TableHeader from './Header';
import TableRow from './Row';
import Cell from './Cell';
import styles from './_styles.module.scss';
import { InputGrp } from 'core/components/form-controls';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';
import { ModulePropsMap, ModuleProps, TableModuleType, TableProps, Row, CellProps, ColumnHeaderData } from './types';

export const matchQuery = (cell: CellProps, query: string): boolean => {
  return ['string', 'number'].includes(typeof cell.children) && (String(cell.children).toLowerCase().includes(query));
};

const emptyColumn: ColumnHeaderData = {
  title: '',
  styleClass: 'th',
};

const Table: FC<TableProps> = ({
  columns,
  rows,
  onRowClick,
  onRowDoubleClick,
  onRowDelete,
  bodyClass,
  modules = [],
  filterOpts,
  showDelete,
}) => {
  const [rowState, setRowState] = useState<Row[]>(rows); // local copy for filtering, etc.
  const [selectedRow, setSelectedRow] = useState<Row>();
  
  const passedColumns: ColumnHeaderData[] = showDelete ? [...columns, emptyColumn] : columns;
  
  const modulePropsMap: ModulePropsMap = {
    'FilterBy': {
      data: rowState,
      updateData: setRowState,
      name: '',
      label: '',
      radioOpts: filterOpts ?? [] as RadioOptions[],
    },
    'Search': {
      data: rowState,
      updateData: setRowState,
    },
    'AdditionalFilters': null,
  };
  
  useEffect(() => {
    setRowState(rows);
  }, [rows]);
  
  // ensure that the number of columns matches the number of cells in each row
  if (columns.length !== rows[0]?.cells.length) {
    console.error('Number of columns must match the number of cells in each row.');
  }
  
  const moduleProps: ModuleProps = { data: rowState, updateData: setRowState };
  
  const handleSearch = (query: string) => {
    if (!query.trim().length) {
      setRowState(rows);
      return;
    }
    
    const newState = structuredClone(rows)?.filter((row: Row) =>
      !!row.cells.find((cell: CellProps) =>
        matchQuery(cell, query)));
    
    setRowState(newState);
  };
  
  const handleRowClick = (row: Row) => {
    setSelectedRow(row);
    onRowClick?.(row.id);
  };

  const handleRowDoubleClick = (row: Row) => {
    setSelectedRow(row);
    onRowDoubleClick?.(row.id);
  };
  
  const handleRowDelete = (id: string | number) => {
    setRowState((prevState) => prevState?.filter((_row) => String(_row.id) !== String(id)));
    onRowDelete?.(id);
  };

  return (
    <div className={styles['table-container']}>
      <>
        {modules.length ? ( // in case we want to add search/filter features. Just pass an array of the modules you want
          <div className={styles['table-modules-container']}>
            {modules.map((_module: TableModuleType) => { // TODO: maybe just create the modules with compound components
              if (_module === 'Search') {
                return (
                  <div
                    className="d-flex flex-column"
                    key={_module}
                  >
                    <label
                      htmlFor="table-search"
                      className={styles['top-label']}
                    >
                      Search
                    </label>
                    <InputGrp
                      placeholder="Search"
                      groupClass="m-0"
                      inputClass={`gc20 ${styles.input}`}
                      name="table-search"
                      type="text"
                      onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                        handleSearch(target.value);
                      }}
                    />
                  </div>
                );
              }
              return null;
            })}
            {/* Well... search can't go in here because searching sets the data and resets the component... */}
            {modules
              ?.filter((_module: TableModuleType) => _module !== 'Search')
              ?.map((_module: TableModuleType) => { // lazy-load the module and pass it the rows to do something with
                const props = { ...moduleProps, ...modulePropsMap[_module] }; // find its props in the props map
                const TableModuleComponent = lazy(() => { return import(`./table-modules/${_module}.tsx`); });
              
                return (
                  <Suspense
                    key={_module}
                    fallback={<div>loading...</div>}
                  >
                    <TableModuleComponent {...props} />
                  </Suspense>
                );
              })}
          </div>
        ) : null}
        <div className={styles.table}>
          <TableHeader columns={passedColumns} />
          <div className={bodyClass ? styles[bodyClass] : styles.tbody}>
            {rowState?.map((row: Row) => (
              <TableRow
                id={row.id}
                isSelected={String(row.id) === String(selectedRow?.id)}
                key={row.id}
                onClick={onRowClick ? () => { handleRowClick(row); } : undefined}
                onDoubleClick={onRowDoubleClick ? () => { handleRowDoubleClick(row); } : undefined}
                showDelete={showDelete}
                deleteRow={showDelete ? () => { handleRowDelete(row.id); } : undefined}
              >
                {row.cells.map((cell: CellProps, cellIndex: number) => (
                  <Cell
                    key={`(${row.id},${cellIndex})`}
                    styleClass={cell.styleClass}
                    position={cell.position}
                  >
                    {cell.children}
                  </Cell>
                ))}
              </TableRow>
            ))}
          </div>
        </div>
      </>
    </div>
  );
};

export default Table;
