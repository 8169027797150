import React, { useState } from 'react';
import { Tabs, Tab, Modal, ProgressBar } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { MyInfoChangeLog } from 'core/models/MyInfoChangeLog';
import { getSelectedChanges, getSelectedEmployeeChanges, getMyInfoLoadingState } from 'core/store/selectors';
import DirectDepositChangeLogComponent from './DirectDepositChangeLogComponent';
import EmployeeChangeLogComponent from './EmployeeChangeLogComponent';
import TaxChangeLogComponent from './TaxChangeLogComponent';
import {
  updateMyInfoChanges,
  clearSelectedChanges,
  storeSelectedEmployeeChanges,
  storeSelectedTaxChanges,
  storeSelectedDirectDepositChange,
  handleError,
} from 'core/store/actions';

type Status = 'ACCEPTED' | 'DENIED' | 'PENDING';
export type SelectionChangeType = 'EMPLOYEE' | 'TAX' | 'DIRECTDEPOSIT';

type Props = {
  myInfoChange: MyInfoChangeLog;
};

const MyInfoChanges = ({ myInfoChange }: Props) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('allChanges');
  const [showError, setShowError] = useState(false);

  const selectedChanges = useSelector(getSelectedChanges);
  const selectedEmployeeChanges = useSelector(getSelectedEmployeeChanges);
  const myInfoLoadingState = useSelector(getMyInfoLoadingState);
  
  const handleSelect = (key: any) => {
    if (showError) {
      setShowError(false);
    }
    setActiveTab(key);
  };

  const updateStatus = (status: Status): MyInfoChangeLog | null => {
    if (!myInfoChange) {
      dispatch(handleError('Cannot update status'));
      return null;
    }
    const reqPayload: MyInfoChangeLog = structuredClone(myInfoChange);
    
    if (selectedChanges?.employeeChanges?.length > 0) {
      reqPayload?.employeeChanges?.forEach((ec) => {
        if (selectedChanges.employeeChanges.find(sec => { return sec.controlId === ec.controlId; })) {
          ec.status = status;
        }
      });
    }
    if (selectedChanges?.taxChanges?.length > 0) {
      reqPayload?.taxChanges?.forEach((tc) => {
        if (selectedChanges.taxChanges?.find(stc => { return stc.year === tc.year && stc.entityId === tc.entityId; })) { // match year AND entity.
          tc.status = status;
        }
      });
    }
    if (selectedChanges?.directDepositChange?.length && reqPayload?.directDepositChange?.status) {
      reqPayload.directDepositChange.status = status;
    }

    return reqPayload;
  };

  const updateChanges = (status: Status) => {
    if (!!selectedChanges?.employeeChanges?.length || !!selectedChanges?.taxChanges?.length || !!selectedChanges?.directDepositChange?.length) {
      if (showError) {
        setShowError(false);
      }
      const reqPayload = updateStatus(status);
      if (!reqPayload) return console.error('Error getting status info');
      dispatch(updateMyInfoChanges(reqPayload));
      dispatch(clearSelectedChanges({
        directDepositChange: [],
        employeeChanges: [],
        taxChanges: [],
      }));
    } else {
      setShowError(true);
    }
  };

  const onSelectionChanged = (type: SelectionChangeType, selection: any[]) => {
    if (showError) {
      setShowError(false);
    }
    if (type === 'EMPLOYEE') {
      dispatch(storeSelectedEmployeeChanges(selection));
    } else if (type === 'TAX') {
      dispatch(storeSelectedTaxChanges(selection));
    } else if (type === 'DIRECTDEPOSIT') {
      dispatch(storeSelectedDirectDepositChange(selection));
    }
  };

  return (
    <>
      <div>
        {(!!myInfoChange?.employeeChanges?.length || !!myInfoChange?.taxChanges?.length || !!myInfoChange?.directDepositChange?.status) &&
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-primary orange-button mr-3"
            disabled={selectedChanges?.employeeChanges?.length === 0 && selectedChanges?.taxChanges?.length === 0 && !selectedChanges?.directDepositChange?.length}
            onClick={() => { return updateChanges('ACCEPTED'); }}
          > Approve Selected Changes
          </button>
          <button
            type="button"
            className="btn btn-primary orange-button mr-1"
            disabled={selectedChanges?.employeeChanges?.length === 0 && selectedChanges?.taxChanges?.length === 0 && !selectedChanges?.directDepositChange?.length}
            onClick={() => { return updateChanges('DENIED'); }}
          > Deny Selected Changes
          </button>
        </div>
        }
        <Tabs
          defaultActiveKey="allChanges"
          style={{ marginBottom: 10 }}
          onSelect={handleSelect}
        >
          <Tab
            title="All Changes"
            eventKey="allChanges"
          >
            <h3>Employee Information</h3>
            <EmployeeChangeLogComponent
              employeeChanges={(myInfoChange?.employeeChanges) ?? []}
              selectedEmployeeChanges={selectedEmployeeChanges}
              activeTab={activeTab}
              onSelectionChanged={onSelectionChanged}
            />
            <div className="d-flex flex-column">
              <h3>Tax Changes</h3>
              <TaxChangeLogComponent
                taxChanges={(myInfoChange?.taxChanges) ?? []}
                onSelectionChanged={onSelectionChanged}
              />
            </div>
            {myInfoChange?.directDepositChange && (
              <div className="d-flex flex-column">
                <h3>Direct Deposits</h3>
                <DirectDepositChangeLogComponent
                  directDepositChange={myInfoChange.directDepositChange}
                  onSelectionChanged={onSelectionChanged}
                />
              </div>
            )}
          </Tab>
          <Tab
            title="Employee Info Changes"
            eventKey="employeeChanges"
          >
            <EmployeeChangeLogComponent
              employeeChanges={(myInfoChange?.employeeChanges) ?? []}
              selectedEmployeeChanges={selectedEmployeeChanges}
              activeTab={activeTab}
              onSelectionChanged={onSelectionChanged}
            />
          </Tab>
          <Tab
            title="Tax Changes"
            eventKey="taxChanges"
          >
            <TaxChangeLogComponent
              taxChanges={(myInfoChange?.taxChanges) ?? []}
              onSelectionChanged={onSelectionChanged}
            />
          </Tab>
          {myInfoChange?.directDepositChange && (
            <Tab
              title="Direct Deposit Changes"
              eventKey="directDepositChanges"
            >
              <DirectDepositChangeLogComponent
                directDepositChange={myInfoChange.directDepositChange}
                onSelectionChanged={onSelectionChanged}
              />
            </Tab>
          )}
        </Tabs>
      </div>
      {showError &&
        <div className="d-flex justify-content-start text-danger m-1">
          <span>No changes selected, please select a change before click.</span>
        </div>
      }
      <Modal
        backdrop="static"
        keyboard={false}
        show={myInfoLoadingState}
        centered={true}
        fullscreen={true}
      >
        <Modal.Header>
          <Modal.Title>Loading...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProgressBar
            animated
            now={100}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MyInfoChanges;