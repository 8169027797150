import HamburgerMenu from 'core/components/shared/HamburgerMenu';
import Icon from 'core/components/shared/Icon';
import React from 'react';
import { NavLink } from 'react-router-dom';

type W4I9ToolbarProps = {
  toggleSidebar: () => void;
  showSidebarMenuIcon: boolean;
};

const W4I9Toolbar: React.FC<W4I9ToolbarProps> = ({ toggleSidebar, showSidebarMenuIcon }) => {
  return (
    <div className="dm-toolbar">
      {showSidebarMenuIcon ? (
        <HamburgerMenu
          toggleSidebar={toggleSidebar}  
        />
      ) : (
        <>
          <NavLink to="/review-myinfo-changes">Review MyInfo Changes</NavLink>
          <NavLink to="/managei9">Manage Documents</NavLink>
          <a
            href="//e-verify.gov/employers"
            target="_blank"
            rel="noreferrer"
          >Federal E-Verify <Icon name="external-link-alt" /></a>
        </>
      )}
    </div>
  );
};

export default W4I9Toolbar;
