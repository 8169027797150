import Icon from 'core/components/shared/Icon';
import React from 'react';
import { Modal } from 'react-bootstrap';

type PropTypes = {
  show: boolean;
  onContinue: any;
  onHide: any;
  confirmMessage: string;
};
const OnboardConfirmModal: React.FC<PropTypes> = ({ show, onContinue, onHide, confirmMessage }) => {
  const closeModal = (e: any) => {
    e.stopPropagation();
    onHide();
  };

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        size="sm"
        animation={false}
      >
        <div className="modal-header" >
          <div className="dm-card-title">Confirm</div>
          <button
            className="modal-close-btn"
            onClick={closeModal}
          >
            <Icon name="times" />
          </button>
        </div>
        <Modal.Body>
          <p>{confirmMessage}</p>
          <div className="row mt-4">
            <div className="col-12 text-right">
              <button
                type="button"
                className="orange-outline-button"
                onClick={closeModal}
              >Cancel</button>
              <button
                type="button"
                className="orange-button ml-2"
                onClick={onContinue}
              >Continue</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div >
  );
};
export default OnboardConfirmModal;
