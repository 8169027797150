import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

// TODO: similar to main nav, change these subIds once they're cleared
const data = [
  {
    id: '0',
    title: 'Interview Process',
    href: '/design-interview/process',
  },
  {
    id: '1',
    title: 'Interview Templates',
    href: '/design-interview/templates',
  },
  {
    id: '2',
    title: 'Interview Questions',
    href: '/design-interview/questions',
  },
  {
    id: '3',
    title: 'Interview Locations',
    href: '/design-interview/locations',
  }, 
  {
    id: '4',
    title: 'Custom Emails',
    href: '/design-interview/custom-emails',
  },
  {
    id: '5',
    title: 'Scorecard Categories',
    href: '/design-interview/scorecard-categories',
  },
  {
    id: '6',
    title: 'Scorecard Traits',
    href: '/design-interview/scorecard-traits',
  },
];

const DesignInterviewSidebar = () => {

  const history = useHistory();

  const [activeLink, setActiveLink] = useState<string>();

  useEffect(() => {
    const d = data.find(l => {return l.href === history.location.pathname;});
    setActiveLink(d?.title ?? 'Interview Process');
  }, []);

  const onLinkClick = (href: string) => {   
    history.push(href);   
  };

  return (
    <>
      {data.map((item) => {
        return (
          <div
            key={item.id}
            className="d-flex flex-column"
          >
            <Button
              variant="link"
              className="dm-sidebar-link"
              style={{ backgroundColor: activeLink === item.title ? '#fff' : '#f0f0f0' }}
              onClick={() => { return onLinkClick(item.href); }}
            >
              {item.title}
            </Button>
          </div>
        );
      })}

    </>
  );
};

export default DesignInterviewSidebar;
