import React, { CSSProperties, useEffect, useState } from 'react';
import { Collapse, Modal, Tab, Tabs } from 'react-bootstrap';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  CheckCode,
  Dropdown,
  EarningCode,
  Employee,
  PayrollsUserOptions,
  TransmittalCheck,
  TransmittalEarning,
  TransmittalEmployee,
  TransmittalEmployeeCheckParams,
  TransmittalEmployeeParams,
  TransmittalParams,
} from 'core/models';
import { deleteTransmittalEmployeeCheck, getBlankTransmittalEmployeeCheck, updateTransmittalEmployeeCheck } from 'core/store/actions';
import { sum } from 'lodash';
import { InputGrpDecimal, SelectGrp } from 'core/components/form-controls';
import TransmittalModalEarnings from './TransmittalModalEarningsList';
import TransmittalModalDeductions from './TransmittalModalDeductions';
import { getCheckCodes, getYesNoCMOption } from 'core/store/selectors';
import { currencyFormatter, formatWithCommas, fromCurrency } from 'utilities/utilities';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import MemoItems from './MemoItems';
import ModalEmpDetails from '../shared/ModalEmpDetails';
import ModalHeader from '../shared/ModalHeader';
import Icon from 'core/components/shared/Icon';

const headerStyle: CSSProperties = {
  width: '90px',
};

// these values are "flipped" because technically yes means the blockFica flag should be false.
const ficaRadioOpts = [
  {
    value: 'false',
    label: 'Yes',
  },
  {
    value: 'true',
    label: 'No',
  },
];

const taxFrequencyOpts = [
  {
    id: '',
    description: '',
  },
  {
    id: 'W',
    description: 'Weekly',
  },
  {
    id: 'B',
    description: 'Bi-Weekly',
  },
  {
    id: 'S',
    description: 'Semi-Monthly',
  },
  {
    id: 'M',
    description: 'Monthly',
  },
  {
    id: 'A',
    description: 'Annual',
  },
  {
    id: 'X',
    description: 'Supplemental',
  },
];

const withholdingOpts = [
  {
    id: 'F',
    description: 'Fixed',
  },
  {
    id: 'A',
    description: 'Additional',
  },
  {
    id: 'N',
    description: 'No Taxes',
  },
  {
    id: '%',
    description: 'Percent',
  },
];

const navKeys = ['PageUp', 'PageDown', 'ArrowUp', 'ArrowDown'];

// TODO: isTrue is a horrible name Felix
const renderCheckCodeIcon = (isTrue: boolean) => {
  return (
    <>
      {isTrue ? (
        <Icon
          name="check"
          color="green"
        />
      ) : (
        <Icon
          name="times"
          color="red"
        />
      )}
    </>
  );
};

type PropTypes = {
  employee: Employee;
  employees: Employee[];
  controlTotalId: number;
  payrollHistoryId: number;
  transmittalParams: TransmittalParams;
  earningsCode: Dropdown[];
  earningCodeSettings: EarningCode[];
  payrollsUserOptions: PayrollsUserOptions | null;
  checkIndex: number;
  isReadOnly: boolean;
  show: boolean;
  onHide: () => void;
};

const CurrentTransmittalModal: React.FC<PropTypes> = ({
  employee,
  employees,
  transmittalParams,
  earningsCode,
  earningCodeSettings,
  payrollsUserOptions,
  checkIndex,
  isReadOnly,
  show,
  onHide,
}) => {
  const dispatch = useAppDispatch();
  
  const transmittalEmployees = useAppSelector((state) => { return state.payroll.transmittalEmployees; });
  const showRatesOnTransmittal = useSelector(getYesNoCMOption(36));
  const isLoading = useAppSelector((state) => {return state.payroll.transmittalEmpsLoading;});
  const { transmittalEmployee: transEmpFromStore, newCheckIndex } = useAppSelector(({ payroll }) => payroll);
  const checkCodeOpts = useSelector(getCheckCodes);
  
  const [transmittalEmployee, setTransmittalEmployee] = useState<TransmittalEmployee>();
  const [totalEarnings, setTotalEarnings] = useState<string>('$0.00');
  const [currentEmployee, setCurrentEmployee] = useState<Employee>(employee);
  const [tabKey, setTabKey] = useState(newCheckIndex > -1 ? newCheckIndex : checkIndex ? checkIndex : 0);
  
  const emp = transEmpFromStore && transEmpFromStore.empNo === employee.empNo ? transEmpFromStore : transmittalEmployees.find(
    (e) => { return e.empNo === employee?.empNo; },
  );

  const {
    control,
    reset,
    handleSubmit,
    register,
    errors,
    setValue,
    trigger,
    formState,
    watch,
  } = useForm<TransmittalEmployee>({ defaultValues: { ...emp } });

  const { fields } = useFieldArray<TransmittalCheck>({
    control,
    name: 'checks',
    keyName: 'id',
  });

  const { isDirty } = formState;

  const formValues = useWatch<TransmittalCheck[]>({
    name: 'checks',
    control,
  }) as TransmittalCheck[];
  
  const [openState, setOpenState] = useState<{ [key: string]: boolean }>({
    code: false,
    earning: true,
    deduction: false,
    federal: false,
    state: false,
    local: false,
    memo: false,
  });

  useEffect(() => {
    setCurrentEmployee(employee);
  }, []);

  useEffect(() => {
    const newEmp = transEmpFromStore && transEmpFromStore.empNo === employee.empNo
      ? transEmpFromStore
      : transmittalEmployees.find((e) => e.empNo === employee.empNo);
    if (!newEmp) return;
    setTransmittalEmployee(newEmp);
  }, [employee.empNo, isLoading, transEmpFromStore]);
  
  useEffect(() => {
    if (
      transmittalEmployee &&
      transmittalEmployee.empNo === currentEmployee.empNo
    ) {
      reset({ checks: transmittalEmployee.checks });
      setTimeout(() => { return trigger(); }, 1000);
      setTabKey(newCheckIndex > -1 ? newCheckIndex : 0);
    }
  }, [transmittalEmployee]);

  useEffect(() => {
    if (!formValues) return;
    getTotalAmount(formValues[tabKey]);
  }, [formValues]);

  const toggleInfo = (type: string) => {
    setOpenState({
      ...openState,
      [type]: !openState[type],
    });
  };

  const onSave = (data: TransmittalEmployee) => {
    if (isReadOnly) return;
    if (isDirty && data?.checks?.length) {
      const zeroEarningsIndex = data?.checks?.findIndex((check) => !check?.earnings || check.earnings.length === 0);
      if (zeroEarningsIndex > -1) return alert(`You must add some earnings to check ${zeroEarningsIndex + 1}`);
      
      data?.checks?.forEach((check, index) => {
        check.blockFica = String(check.blockFica) === 'true';
        check.earnings = check?.earnings?.map((a) => {
          a.tracked = String(a.tracked) === 'true';
          //These are all string overrides that need to be set to zero if not setup, before sending '' was causing error.
          a.dept = (String(a.dept) === '') ? 0 : a.dept;
          a.subDept = (String(a.subDept) === '') ? 0 : a.subDept;
          a.subDept2 = (String(a.subDept2) === '') ? 0 : a.subDept2;
          a.shiftPremiumId = (String(a.shiftPremiumId) === '') ? 0 : a.shiftPremiumId;
          a.cityTaxEntityId = (String(a.cityTaxEntityId) === '') ? 0 : a.cityTaxEntityId;
          a.altRate = a.rateId === 0 ? +a.altRate.toFixed(4) : 0;
          return a;
        });

        check.deductions = check?.deductions?.map((a) => {
          //These are all string overrides that need to be set to zero if not setup, before sending '' was causing error.
          a.dept = (String(a.dept) === '') ? 0 : a.dept;
          a.subDept = (String(a.subDept) === '') ? 0 : a.subDept;
          a.subDept2 = (String(a.subDept2) === '') ? 0 : a.subDept2;
          return a;
        });

        const lastCheck = (index === data.checks.length - 1) ? true : false;
        const checkParams: TransmittalEmployeeCheckParams = {
          payrollHistoryId: transmittalParams?.payrollHistoryId,
          controlTotalId: transmittalParams?.controlTotalId,
          protectedEmpNo: transmittalEmployee?.protectedEmpNo || '',
          transmittalCheckId: check.transmittalCheckId as number,
        };
        
        dispatch(
          updateTransmittalEmployeeCheck({
            protectedEmpNo: data.protectedEmpNo,
            params: checkParams,
            data: check,
            showSuccess: lastCheck,
            fromModal: true,
            currentTab: +tabKey,
          }),
        );
      });
    }
  }; 

  const onDeleteCheck = (check: TransmittalCheck) => {
    const confirmMessage = (transmittalEmployee?.checks.length === 1) ? 'employee' : 'check';
    if (!confirm(`Do you want to delete the selected ${confirmMessage}? This action cannot be undone.`)) return;

    const checkParams: TransmittalEmployeeCheckParams = {
      payrollHistoryId: transmittalParams?.payrollHistoryId,
      controlTotalId: transmittalParams?.controlTotalId,
      protectedEmpNo: transmittalEmployee?.protectedEmpNo || '',
      transmittalCheckId: check?.transmittalCheckId || 0,
    };
    dispatch(
      deleteTransmittalEmployeeCheck(checkParams),
    );
    if (transmittalEmployee?.checks.length === 1) {
      onHide();
    } else {
      setTabKey(0);
    }
  };

  const onAddCheck = () => {
    const checkParams: TransmittalEmployeeParams = {
      payrollHistoryId: transmittalParams?.payrollHistoryId,
      controlTotalId: transmittalParams?.controlTotalId,
      protectedEmpNo: transmittalEmployee?.protectedEmpNo || '',
    };
    dispatch(getBlankTransmittalEmployeeCheck(checkParams));
  };

  const getTotalAmount = (check: TransmittalCheck) => {
    if (!(check && showRatesOnTransmittal)) {
      return;
    }
    const amounts: number[] = [];
    const payRateDropdown = transmittalEmployee?.checks.find(
      (a) => { return a.transmittalCheckId === check.transmittalCheckId; },
    )?.payRateDropdown;
    
    check?.earnings?.forEach((e: TransmittalEarning) => {
      const rateId = '' + e?.rateId !== '' ? e?.rateId : null;
      if (rateId !== null) {
        const rate = payRateDropdown?.find((p) => { return p.rateId === +rateId; });
        const ecSetting = earningCodeSettings.find(
          (es) => { return es.earnCode === e.earningsCode; },
        ) as EarningCode;
        let amount = 0;
        const earningAmount = e.amount;

        if ((e?.rateId ?? 0) > 0) {
          //For earningsCode 2 or 3 we want to use the hourlyDescription even if the tranDigit is 1 -PI-8226
          if (e.tranDigit === 1 && e.earningsCode !== '2' && e.earningsCode !== '3') {
            amount = earningAmount * parseFloat(rate?.salaryDescription || '0');
          } else if (e.tranDigit === 2 || (e.earningsCode === '2' || e.earningsCode === '3')) {
            amount = earningAmount * parseFloat(rate?.hourlyDescription || '0');
          }
          // @ts-ignore
        } else if ((e?.rateId ?? 0) === -1) {
          amount += earningAmount;
        } else if ((e?.rateId ?? 0) === 0) {
          amount += earningAmount * e.altRate;
        }
        //We do not add the premium factor for earnings only amounts.
        if (ecSetting && e?.rateId !== -1) {
          amount *= ecSetting.premiumFactor as number;
        }
        (e.tracked?.toString() || 'false') === 'false' && amounts.push(amount);
      } else {
        amounts.push(e.earnings);
      }
    });
    
    const total = sum(amounts);
    setTotalEarnings(currencyFormatter(total));
  };

  const onBlockFicaChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    setValue(name, e.target.value);
  };

  const onFrequencyChange = (e: React.ChangeEvent<HTMLInputElement>, amountName: string, typeName: string) => {
    if (e.target.value === 'X') {
      setValue(amountName, 22.00.toFixed(2));
      setValue(typeName, '%');
    }
  };

  const closeModal = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.stopPropagation();
    onHide();
  };

  const getPrevEmp = () => {
    handleSubmit(onSave)();
    if (transmittalEmployee) {
      const currentIndex = transmittalEmployees.findIndex((a) => String(a.empNo) === String(currentEmployee.empNo));
      const tEmp = transmittalEmployees[currentIndex - 1];
      const newEmp = employees?.find((e) => e.empNo === tEmp?.empNo);
      
      if (tEmp) setTransmittalEmployee(tEmp);
      if (newEmp) setCurrentEmployee(newEmp);
    } else {
      setTransmittalEmployee(transmittalEmployees[0]);
    }
  };

  const getNextEmp = () => {
    handleSubmit(onSave)();
    if (transmittalEmployee) {
      const currentIndex = transmittalEmployees.findIndex((a) => String(a.empNo) === String(currentEmployee.empNo)) as number;
      const tEmp = transmittalEmployees[currentIndex + 1];
      const newEmp = employees?.find((e) => e.empNo === tEmp?.empNo);
      
      if (tEmp) setTransmittalEmployee(tEmp);
      if (newEmp) setCurrentEmployee(newEmp);
    } else {
      setTransmittalEmployee(transmittalEmployees[0]);
    }
  };

  const onCheckCodeClick = (opt: CheckCode, name: string) => {
    setValue(name, opt.checkCode, { shouldDirty: true });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'PageUp') {
      e.preventDefault();
      getPrevEmp();
    } else if (e.key === 'PageDown') {
      e.preventDefault();
      getNextEmp();
    }

    e.stopPropagation();
  };

  return (
    <div
      onClick={(e) => { return e.stopPropagation(); }}
      onKeyDown={(e) => {
        return navKeys.includes(e.key) ? handleKeyDown(e) : undefined;
      }}
    >
      <Modal
        show={show}
        onHide={onHide}
        size="sm"
        animation={false}
      >
        <ModalHeader
          getPrevEmp={getPrevEmp}
          getNextEmp={getNextEmp}
          closeModal={closeModal}
        />
        <Modal.Body>
          <div className="d-flex justify-content-between mb-3">
            <ModalEmpDetails
              currentEmployee={currentEmployee}
              onAdd={onAddCheck}
              addType={'Check'}
              disabled={isReadOnly || fields.length >= 10}
            />
          </div>
          <Tabs
            defaultActiveKey={tabKey}
            activeKey={tabKey}
            id="check-tabs"
            onSelect={(k) => { return setTabKey(Number(k ?? 0)); }}
          >
            {fields.map((item, ci) => {
              const check = item as TransmittalCheck;
              const watchedFedWhAmt = watch(`checks[${ci}].federalWithholding.amount`);
              const watchedStateWhAmt = watch(`checks[${ci}].stateWithholding.amount`);
              const watchedLocalWhAmt = watch(`checks[${ci}].localWithholding.amount`);
              
              // include current check's checkcode and exclude all other used ones.
              const filteredCheckCodeOpts = checkCodeOpts
                .filter((x) =>
                  !fields
                    .map((y) => y.checkCode)
                    ?.filter((y) => y !== item.checkCode)
                    ?.includes(x?.checkCode ?? ''));
              
              return (
                <Tab
                  key={item.id}
                  eventKey={ci}
                  title={`Check ${ci + 1}`}
                >
                  <form
                    onSubmit={handleSubmit(onSave)}
                    className="p-3 bg-info"
                  >
                    <input
                      type="hidden"
                      name={`checks[${ci}].transmittalCheckId`}
                      defaultValue={check.transmittalCheckId}
                      ref={register({ valueAsNumber: true })}
                    />
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-column w-100">
                        CHECK CODE
                        <div className="dm-panel dm-panel-border mt-1">
                          <div className="row">
                            <div className="col mr-auto">
                              <div
                                role="button"
                                className="dm-card-subtitle4"
                                onClick={() => {return toggleInfo('code');}}
                              >
                                <Icon
                                  name={openState.code ? 'chevron-down' : 'chevron-right'}
                                  className="mr-2"
                                />
                                Check Code
                              </div>
                            </div>
                          </div>
                          <Collapse in={openState.code}>
                            <div>
                              <SelectGrp
                                labelField="checkCode"
                                valueField="checkCode"
                                errors={errors?.checks?.[ci]?.checkCode}
                                options={filteredCheckCodeOpts}
                                name={`checks[${ci}].checkCode`}
                                id="checkCode"
                                ref={register}
                                readOnly={isReadOnly}
                                defaultValue={check.checkCode}
                              />
                              <table className="table table-striped table-hover">
                                <thead>
                                  <tr>
                                    <th style={headerStyle}>Check Code</th>
                                    <th style={headerStyle}>EFT</th>
                                    <th style={headerStyle}>Voluntary</th>
                                    <th style={headerStyle}>401K</th>
                                    <th style={headerStyle}>Garnishment</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredCheckCodeOpts.map((opt: CheckCode) => {
                                    const handleCheckCodeClick = () => {
                                      onCheckCodeClick(opt, `checks[${ci}].checkCode`);
                                    };
                                    
                                    return (
                                      <tr key={opt.checkCode}>
                                        <td onClick={handleCheckCodeClick}>
                                          {opt.checkCode}
                                        </td>
                                        <td onClick={handleCheckCodeClick}>
                                          {renderCheckCodeIcon(opt.eft)}
                                        </td>
                                        <td onClick={handleCheckCodeClick}>
                                          {renderCheckCodeIcon(opt.voluntary)}
                                        </td>
                                        <td onClick={handleCheckCodeClick}>
                                          {renderCheckCodeIcon(opt.four01K)}
                                        </td>
                                        <td onClick={handleCheckCodeClick}>
                                          {renderCheckCodeIcon(opt.garnishment)}
                                        </td>
                                      </tr>
                                    );
                                  },
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                      <div className="d-flex flex-column w-100">
                        EARNINGS &amp; DEDUCTIONS
                        <div className="dm-panel dm-panel-border mt-1">
                          <div className="d-flex justify-content-between">
                            <div>
                              <div
                                role="button"
                                className="dm-card-subtitle4"
                                onClick={() => {return toggleInfo('earning');}}
                              >
                                <Icon
                                  name={openState.earning ? 'chevron-down' : 'chevron-right'}
                                  className="mr-2"
                                />
                                Earnings
                              </div>
                            </div>
                            <div>
                              <strong>
                                {showRatesOnTransmittal && totalEarnings}
                              </strong>
                            </div>
                          </div>
                          <Collapse in={openState.earning}>
                            <div>
                              <TransmittalModalEarnings
                                control={control}
                                check={check}
                                checkIndex={ci}
                                register={register}
                                errors={errors}
                                earningsCode={earningsCode}
                                isReadOnly={isReadOnly}
                                payrollsUserOptions={payrollsUserOptions}
                                employee={employee}
                                setValue={setValue}
                                watch={watch}
                                earningCodeSettings={earningCodeSettings}
                              />
                            </div>
                          </Collapse>
                        </div>
                        <div className="dm-panel dm-panel-border mt-1">
                          <div className="d-flex justify-content-between">
                            <div>
                              <div
                                role="button"
                                className="dm-card-subtitle4"
                                onClick={() => { toggleInfo('deduction'); }}
                              >
                                <Icon
                                  name={openState.deduction ? 'chevron-down' : 'chevron-right'}
                                  className="mr-2"
                                />
                                Deductions
                              </div>
                            </div>
                          </div>
                          <Collapse
                            in={openState.deduction}
                          >
                            <div>
                              <TransmittalModalDeductions
                                register={register}
                                watch={watch}
                                errors={errors}
                                checkIndex={ci}
                                control={control}
                                isReadOnly={isReadOnly}
                                payrollsUserOptions={payrollsUserOptions}
                                employee={employee}
                                setValue={setValue}
                              />
                            </div>
                          </Collapse>
                        </div>
                      </div>
                      <div className="d-flex flex-column w-100">
                        TAXES &amp; WITHHOLDINGS
                        <div className="dm-panel dm-panel-border mt-1">
                          <div className="d-flex justify-content-between">
                            <div>Tax Frequency</div>
                            <div>
                              <SelectGrp
                                options={taxFrequencyOpts}
                                name={`checks[${ci}].taxFrequency`}
                                id="taxFrequency"
                                errors={errors.checks?.[ci]?.taxFrequency}
                                ref={register}
                                defaultValue={check.taxFrequency}
                                readOnly={isReadOnly}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  onFrequencyChange(e, `checks[${ci}].federalWithholding.amount`, `checks[${ci}].federalWithholding.fan`); 
                                }}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="d-flex flex-row">
                              <div className="mr-3">
                                <span>
                                  FICA?
                                </span>
                              </div>
                              <div>
                                {ficaRadioOpts.map((item, index: number) => {
                                  return (
                                    <label
                                      key={index}
                                      className="mr-3 radio-label"
                                    >
                                      <input
                                        type="radio"
                                        name={`checks[${ci}].blockFica`}
                                        ref={register}
                                        className={'radio-input'}
                                        defaultValue={item.value}
                                        disabled={isReadOnly}
                                        onChange={(e) => { onBlockFicaChange(e, `checks[${ci}].blockFica`); }}
                                        defaultChecked={'' + check.blockFica === item.value}
                                      />{' '}
                                      {item.label}
                                    </label>
                                  );
                                },
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dm-panel dm-panel-border">
                          <div className="row">
                            <div className="col mr-auto">
                              <div
                                role="button"
                                className="dm-card-subtitle4"
                                onClick={() => { toggleInfo('federal'); }}
                              >
                                <Icon
                                  name={openState.federal ? 'chevron-down' : 'chevron-right'}
                                  className="mr-2"
                                />
                                Federal Withholdings
                              </div>
                            </div>
                          </div>
                          <Collapse in={openState.federal}>
                            <div>
                              <div className="d-flex">
                                <input
                                  type="hidden"
                                  name={`checks[${ci}].federalWithholding.taxCode`}
                                  defaultValue={check.federalWithholding?.taxCode}
                                  ref={register}
                                />
                                <SelectGrp
                                  groupClass={'col'}
                                  options={withholdingOpts}
                                  addEmptyText={true}
                                  label="&nbsp;"
                                  name={`checks[${ci}].federalWithholding.fan`}
                                  readOnly={isReadOnly}
                                  errors={errors?.checks?.[ci]?.federalWithholding?.fan}
                                  ref={register({
                                    validate: (value) => {
                                      if (!(value === '' && (formValues[ci].federalWithholding?.amount as number) > 0)) return;
                                      return 'FAN% must be filled in if FAN% Amount is greater than 0';
                                    },
                                  })}
                                  errorMsg="FAN% must be filled in if FAN% Amount is greater than 0"
                                  defaultValue={check.federalWithholding?.fan}
                                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    if (e.target.value === '' || e.target.value === 'N') {
                                      setValue(`checks[${ci}].federalWithholding.amount`, (0).toFixed(2));
                                    }
                                  }}
                                />
                                <InputGrpDecimal
                                  label="Amount"
                                  groupClass="number-underline col"
                                  name={`checks[${ci}].federalWithholding.amount`}
                                  setValue={setValue}
                                  disabled={formValues?.[ci]?.federalWithholding?.fan === '' || formValues[ci].federalWithholding.fan === 'N'}
                                  type="text"                                  
                                  strValue={formatWithCommas(watchedFedWhAmt as any ?? 0)}
                                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                    e.target.value = formatWithCommas(e.target.value);
                                  }}
                                  readOnly={isReadOnly}
                                  ref={register({
                                    required: true,
                                    validate:(value) => {
                                      const fan = formValues[ci].federalWithholding.fan.trim();
                                      if (((fan === '' || fan === 'N') && (fromCurrency(value) ?? 0) > 0) || (fan !== '' && fan !== 'N' && (fromCurrency(value) ?? 0) <= 0)) {
                                        return 'FAN% must be filled in if FAN% Amount is greater than 0';
                                      }
                                    },
                                  },
                                  )}
                                  errors={errors?.checks?.[ci]?.federalWithholding?.amount}
                                  errorMsg="Amount must be greater than 0"
                                  formatNumber
                                  hiddenValue={watchedFedWhAmt ?? 0}
                                  hiddenRef={register({
                                    valueAsNumber: true,
                                  })}
                                />
                              </div>
                            </div>
                          </Collapse>
                        </div>
                        <div className="dm-panel dm-panel-border">
                          <div className="row">
                            <div className="col mr-auto">
                              <div
                                role="button"
                                className="dm-card-subtitle4"
                                onClick={() => {return toggleInfo('state');}}
                              >
                                <Icon
                                  name={openState.state ? 'chevron-down' : 'chevron-right'}
                                  className="mr-2"
                                />
                                State Withholdings
                              </div>
                            </div>
                          </div>
                          <Collapse in={openState.state}>
                            <div>
                              <div className="d-flex justify-content-between">
                                <input
                                  type="hidden"
                                  name={`checks[${ci}].stateWithholding.taxCode`}
                                  defaultValue={check.stateWithholding?.taxCode}
                                  ref={register}
                                />
                                <SelectGrp
                                  groupClass={'col'}
                                  options={withholdingOpts}
                                  addEmptyText={true}
                                  label="&nbsp;"
                                  name={`checks[${ci}].stateWithholding.fan`}
                                  readOnly={isReadOnly}
                                  errors={errors?.checks?.[ci]?.stateWithholding?.fan}
                                  ref={register(
                                    {
                                      validate:(value) => {
                                        if (value === '' && (formValues[ci].stateWithholding?.amount as number) > 0) {
                                          return 'FAN% must be filled in if FAN% Amount is greater than 0';
                                        }
                                      },
                                    },
                                  )}
                                  errorMsg="FAN% must be filled in if FAN% Amount is greater than 0"
                                  defaultValue={check.stateWithholding?.fan}
                                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    if (e.target.value === '' || e.target.value === 'N') {
                                      setValue(`checks[${ci}].stateWithholding.amount`, (0).toFixed(2));
                                    }
                                  }}
                                />
                                <InputGrpDecimal
                                  label="Amount"
                                  groupClass="col number-underline"
                                  name={`checks[${ci}].stateWithholding.amount`}
                                  ref={register(
                                    {
                                      required:true,
                                      validate:(value) => {
                                        const fan = formValues[ci].stateWithholding.fan.trim();
                                        if (((fan === '' || fan === 'N') && (fromCurrency(value) ?? 0) > 0) || (fan !== '' && fan !== 'N' && (fromCurrency(value) ?? 0) <= 0)) {
                                          return 'FAN% must be filled in if FAN% Amount is greater than 0';
                                        }
                                      },
                                    },
                                  )}
                                  errorMsg="Amount must be greater than 0"
                                  setValue={setValue}
                                  type="text"                                  
                                  strValue={formatWithCommas(watchedStateWhAmt as any ?? 0)}
                                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                    e.target.value = formatWithCommas(e.target.value);
                                  }}
                                  errors={errors?.checks?.[ci]?.stateWithholding?.amount}
                                  disabled={formValues?.[ci]?.stateWithholding?.fan === '' || formValues[ci].stateWithholding.fan === 'N'}
                                  formatNumber
                                  hiddenValue={watchedStateWhAmt ?? 0}
                                  hiddenRef={register({
                                    valueAsNumber: true,
                                  })}
                                />
                              </div>
                            </div>
                          </Collapse>
                        </div>
                        <div className="dm-panel dm-panel-border">
                          <div className="row">
                            <div className="col mr-auto">
                              <div
                                role="button"
                                className="dm-card-subtitle4"
                                onClick={() => {return toggleInfo('local');}}
                              >
                                <Icon
                                  name={openState.local ? 'chevron-down' : 'chevron-right'}
                                  className="mr-2"
                                />
                                Local Withholdings
                              </div>
                            </div>
                          </div>
                          <Collapse in={openState.local}>
                            <div>
                              <div className="d-flex justify-content-between">
                                <input
                                  type="hidden"
                                  name={`checks[${ci}].localWithholding.taxCode`}
                                  defaultValue={check.localWithholding?.taxCode}
                                  ref={register}
                                />
                                <SelectGrp
                                  groupClass={'col'}
                                  options={withholdingOpts}
                                  addEmptyText={true}
                                  label="&nbsp;"
                                  name={`checks[${ci}].localWithholding.fan`}
                                  readOnly={isReadOnly}
                                  errors={errors?.checks?.[ci]?.localWithholding?.fan}
                                  ref={register(
                                    {
                                      validate:(value) => {
                                        if (value === '' && (formValues[ci].localWithholding?.amount as number) > 0) {
                                          return 'FAN% must be filled in if FAN% Amount is greater than 0';
                                        }
                                      },
                                    },
                                  )}
                                  errorMsg="FAN% must be filled in if FAN% Amount is greater than 0"
                                  defaultValue={check.localWithholding?.fan}
                                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    if (e.target.value === '' || e.target.value === 'N') {
                                      setValue(`checks[${ci}].localWithholding.amount`, (0).toFixed(2));
                                    }
                                  }}
                                />
                                <InputGrpDecimal
                                  label="Amount"
                                  groupClass="col number-underline"
                                  name={`checks[${ci}].localWithholding.amount`}
                                  type="text"                                  
                                  strValue={formatWithCommas(watchedLocalWhAmt as any ?? 0)}
                                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                    e.target.value = formatWithCommas(e.target.value);
                                  }}
                                  disabled={formValues?.[ci]?.localWithholding?.fan === '' || formValues[ci].localWithholding.fan === 'N'}
                                  ref={register(
                                    {
                                      required:true,
                                      validate:(value) => {
                                        const fan = formValues[ci].localWithholding.fan.trim();
                                        if (((fan === '' || fan === 'N') && (fromCurrency(value) ?? 0) > 0) || (fan !== '' && fan !== 'N' && (fromCurrency(value) ?? 0) <= 0)) {
                                          return 'FAN% must be filled in if FAN% Amount is greater than 0';
                                        }
                                      },
                                    },
                                  )}
                                  errors={errors?.checks?.[ci]?.localWithholding?.amount}
                                  errorMsg="Amount must be greater than 0"
                                  formatNumber
                                  setValue={setValue}
                                  hiddenValue={watchedLocalWhAmt ?? 0}
                                  hiddenRef={register({ valueAsNumber: true })}
                                />
                              </div>
                            </div>
                          </Collapse>
                        </div>
                        {check.memoItems.length !== 0 ? <div className="d-flex flex-column w-100">
                          <div className="dm-panel dm-panel-border mt-1">
                            <div className="d-flex justify-content-between">
                              <div
                                role="button"
                                className="dm-card-subtitle4"
                                onClick={() => {return toggleInfo('memo');}}
                              >
                                <Icon
                                  name={openState.memo ? 'chevron-down' : 'chevron-right'}
                                  className="mr-2"
                                />
                                Memo Items
                              </div>
                            </div>
                            <Collapse
                              in={openState.memo}
                            >
                              <div>
                                <MemoItems
                                  memoItems={check.memoItems}
                                  register={register}
                                  watch={watch}
                                  checkIndex={ci}
                                  errors={errors}
                                  setValue={setValue}
                                />
                              </div>
                            </Collapse>
                          </div>
                        </div> : null}
                      </div>
                    </div>
                    <div className="d-flex mt-3">
                      <button
                        type="button"
                        className="orange-outline-button ml-auto mr-2"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                      {!isReadOnly ? <>
                        <button
                          type="button"
                          className="orange-outline-button mr-2"
                          onClick={() => { return onDeleteCheck(check); }
                          }
                        >
                          Delete
                        </button>
                        <button
                          type="submit"
                          className="orange-button mr-2"
                          disabled={!isDirty}
                        >
                          Save
                        </button>
                      </> : null}
                    </div>
                  </form>
                </Tab>
              );
            })}
          </Tabs>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CurrentTransmittalModal;
