import React, { ReactNode } from 'react';
import styles from './shared.module.scss';

type Props = {
  title: string;
  children: ReactNode;
  mainClass?: string;
  bodyClass?: string;
};

const DmPageWrapper = ({ title, children, mainClass = '', bodyClass = '' }: Props) => {
  return (
    <main className={`${styles['dm-page-wrapper']} ${mainClass}`}>
      <div className="dm-page-title">{title}</div>
      <hr className="dm-page-hr" />
      <div className={`dm-panel dm-panel-border ${bodyClass}`}>
        {children}
      </div>
    </main>
  );
};

export default DmPageWrapper;