import React, { useEffect, CSSProperties } from 'react';
import { useAuth } from '../../providers/authProvider';
import { Navbar, Button } from 'react-bootstrap';
import logo from 'assets/images/dm_payroll_horizontal_pms_logo_transparent.png';
import benefitsPhoto from 'assets/images/benefits-admin-thin.png';
import styles from './styles.module.scss';

export const Login: React.FC = () => {
  const auth = useAuth();

  const login = () => {
    auth.signInRedirect();
  };

  return (
    <main className={styles['login-page-container']}>

      {/*BAR AT TOP WITH OUR LOGO ON IT */}
      <Navbar
        bg="light"
        expand="sm"
        className="app-page-header-container"
      >
        <div className={styles['nav-brand-wrapper']}>
          <Navbar.Brand className={`p-0 ${styles['nav-brand-override']}`}>
            <img
              src={logo}
              className={styles['logo-style']}
              alt="logo"
            />
          </Navbar.Brand>
        </div>
      </Navbar>

      {/*ORANGE TOP BAR JUST FOR FILLER */}
      <div className={styles['orange-header-bar-style']}></div>

      {/*BLUE TOP BAR JUST FOR FILLER */}
      <div className={styles['blue-header-bar-style']}></div>
      
      {/* ORANGE BACKGROUND WITH ONE ENTRY FOR PHOTO AND ANOTHER FOR LOGIN SECTION */}
      <div className={styles['login-hero-container']}>

        {/*PHOTO THAT SHOULD RESIZE AND FLEX ABOVE IF NEEDED*/}
        <div className={styles['image-wrapper']}>
          <img
            src={benefitsPhoto}
            alt=""
            className={styles['image-style']}
          />
        </div>

        {/*WELCOME TEXT TO THE RIGHT OF PHOTO*/}
        <div className="p-2 flex-fill">
          <div className={styles['login-button-wrapper']}>
            <h3><b>Welcome to the DM Payroll Solutions Login Portal</b></h3>
          </div>
        </div>
      </div>

      {/*WELCOME TEXT BELOW PHOTO*/}
      <div className={styles['login-button-wrapper']}>
        <h2 className="p-2">Click Below to Sign In</h2>
        <div className="p-2">
          <Button 
            className={styles['login-button-style']}
            size="lg" 
            onClick={login}
          >
            Sign In As Employer
          </Button>
        </div>
      </div>

      {/*WRAPPER FOR CONTACT AND COPYRIGHT INFO, FIXED TO BOTTOM*/}
      <div className={styles['bottom-wrapper-style']}>
        
        {/*BLUE BOTTOM BAR WITH CONTACT INFORMATION */}
        <div className={styles['contact-style']}>
          <h2><u>Contact Us</u></h2>
          <span>(248)-244-3293</span>
          <span>support@dmpayroll.com</span>
        </div>

        {/*ORANGE BOTTOM BAR WITH COPYRIGHT INFORMATION */}
        <div className={styles['copyright-style']}>
          <span className="p-2">DM Payroll Solutions © 2024. All Rights Reserved</span>
        </div>
      </div>
    </main>
  );
};

export default Login;
