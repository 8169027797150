import { DetailHour, DetailOtherEarning, TimeCard, TimeCardDeduction, TimeCardDetail } from 'core/models';
import { generateNegativeId } from 'utilities/utilities';

export type TimeCardSummaryAction =
  | {
    type: 'UPDATE_FIELD';
    id: number;
    field: keyof TimeCard;
    value: any;
  }
  | {
    type: 'UPDATE_DETAIL';
    timeCardId: number;
    detailId: number;
    field: keyof TimeCardDetail;
    value: any;
  }
  | {
    type: 'UPDATE_DETAIL_HOUR';
    timeCardId: number;
    detailId: number;
    detailHourId: number;
    earningsCode: string;
    dateId: number;
    field: keyof DetailHour;
    value: any;
  }
  | {
    type: 'UPDATE_DETAIL_EARNING';
    timeCardId: number;
    detailId: number;
    detailEarningId: number;
    field: keyof DetailOtherEarning;
    value: any;
  }
  | {
    type: 'ADD_DETAIL';
    newTimeSheet: TimeCardDetail;
  }
  | {
    type: 'DELETE_DETAIL';
    timeCardId: number;
    timeCardDetailId: number;
  }
  | {
    type: 'RESET_STATE';
    newState: TimeCard[];
  }
  | {
    type: 'ADD_TIME_CARD';
    newTimeCard: TimeCard;
    insertIndex: number;
  }
  | {
    type: 'DELETE_TIME_CARD';
    timeCardId: number;
  }
  | {
    type: 'ADD_DEDUCTION';
    newDeduction: TimeCardDeduction;
  }
  | {
    type: 'DELETE_DEDUCTION';
    timeCardId: number;
    timeCardDeductionId: number;
  }
  | {
    type: 'ADD_EARNING';
    timeCardId: number;
    newEarning: DetailOtherEarning;
  }
  | {
    type: 'DELETE_EARNING';
    timeCardId: number;
    timeCardDetailId: number;
    timeCardEarningId: number;
  }
  | {
    type: 'UPDATE_DEDUCTION';
    timeCardId: number;
    timeCardDeductionId: number;
    field: keyof TimeCardDeduction;
    value: any;
  }
  | {
    type: 'UPDATE_EARNINGS_CODE',
    timeCardId: number;
    timeCardDetailId: number;
    dateId: number; // in this case will just be the first in the list because we're just applying to all anyway.
    value: string;
  }
  ;

/**
 * Reducer callback to update local timecard summary state. In an effort to remove RHF from our ecosystem while still
 * being able to manage complex local state, the reducer is a percect fit for this scenario. If you aren't familiar,
 * when used like this it works just about like useState but is more appropriate for complex nested state (like time cards!)
 * @param state 
 * @param action 
 * @returns 
 */
export function summaryReducer(state: TimeCard[], action: TimeCardSummaryAction): TimeCard[] {
  switch (action.type) {
    /* Updating a TimeCard field */
    case 'UPDATE_FIELD':
      return state.map((x) => 
        x.transmittalTimeCardId === action.id
          ? { ...x, [action.field]: action.value }
          : x,
      );
    /* Updating a TimeCardDetail field */
    case 'UPDATE_DETAIL': 
      return (state.map((x) => 
        x.transmittalTimeCardId === action.timeCardId 
          ? {
            ...x,
            details: x.details.map((y) => 
              y.transmittalTimeCardDetailId === action.detailId 
                ? {
                  ...y,
                  [action.field]: action.value,
                }
                : y,
            ),
          } as TimeCard
          : x,
      ));
    /* Updating a TimeCardDetail hour record */
    case 'UPDATE_DETAIL_HOUR': 
      // time card
      return (state.map((x) => 
        x.transmittalTimeCardId === action.timeCardId 
          ? {
            ...x,
            // time card details
            details: x.details.map((y) => {
              if (y.transmittalTimeCardDetailId !== action.detailId) return y; // not the record we want, so just return it without changes and continue
              // create a default if we don't have one already
              const defaultDetailHour: DetailHour = {
                transmittalTimeCardDetailId: action.detailId,
                transmittalTimeCardDetailHoursId: generateNegativeId(),
                dateId: action.dateId,
                hours: 0,
                calendarDate: new Date(),
                earningsCode: '',
                notes: '',
              };        
              
              (defaultDetailHour[action.field] as any) = action.value;
              
              if (!y.hours.length) {
                return {
                  ...y,
                  hours: [defaultDetailHour],
                };
              }
              
              const detailHours: DetailHour[] = structuredClone(y.hours);
              
              if (!detailHours.find((hour) => hour.dateId === action.dateId)) detailHours.push(defaultDetailHour);
              
              return {
                ...y,
                // time card detail hours
                hours: detailHours.map((z) => 
                  z.transmittalTimeCardDetailHoursId === action.detailHourId
                    ? {
                      ...z,
                      [action.field]: action.value,
                    } as DetailHour
                    : z,
                ),
              } as TimeCardDetail;
            }),
          } as TimeCard
          : x,
      ));
    case 'UPDATE_EARNINGS_CODE':
      return state.map((x) => 
        // step 1: find the time card
        x.transmittalTimeCardId === action.timeCardId
          ? {
            ...x,
            // step 2: find the detail record
            details: x.details.map((y) => {
              if (y.transmittalTimeCardDetailId !== action.timeCardDetailId) return y;
              
              // step 2.1: create a default if we don't have one already
              const defaultDetailHour: DetailHour = {
                transmittalTimeCardDetailId: action.timeCardDetailId,
                transmittalTimeCardDetailHoursId: 0,
                dateId: action.dateId,
                hours: 0,
                calendarDate: new Date(),
                earningsCode: '',
                notes: '',
              };        
              
              const detailHours: DetailHour[] = y.hours.length ? structuredClone(y.hours) : [defaultDetailHour];

              return {
                ...y,
                // step 3: update all hours with the new earnings code
                hours: detailHours.map((z) => ({
                  ...z,
                  earningsCode: action.value,
                })),
              };
            }),
          }
          : x,
      );
    /* Updating a TimeCardDetail "other earning" record */
    case 'ADD_EARNING':
      return (state.map((x) => 
        x.transmittalTimeCardId === action.timeCardId 
          ? {
            ...x,
            details: x.details.map((y) => 
              y.transmittalTimeCardDetailId === action.newEarning.transmittalTimeCardDetailId 
                ? {
                  ...y,
                  otherEarnings: [...y.otherEarnings, action.newEarning],
                } as TimeCardDetail
                : y,
            ),
          } as TimeCard
          : x,
      ));
    case 'UPDATE_DETAIL_EARNING':
      return (state.map((x) => 
        x.transmittalTimeCardId === action.timeCardId 
          ? {
            ...x,
            details: x.details.map((y) => 
              y.transmittalTimeCardDetailId === action.detailId 
                ? {
                  ...y,
                  otherEarnings: y.otherEarnings.map((z) => 
                    z.transmittalTimeCardDetailOtherEarningsId === action.detailEarningId
                      ? {
                        ...z,
                        [action.field]: action.value,
                      } as DetailOtherEarning
                      : z,
                  ),
                } as TimeCardDetail
                : y,
            ),
          } as TimeCard
          : x,
      ));
    case 'DELETE_EARNING':
      return (state.map((x) => 
        x.transmittalTimeCardId === action.timeCardId 
          ? {
            ...x,
            details: x.details.map((y) => 
              y.transmittalTimeCardDetailId === action.timeCardDetailId
                ? {
                  ...y,
                  otherEarnings: y.otherEarnings.filter((z) => z.transmittalTimeCardDetailOtherEarningsId !== action.timeCardEarningId),
                } as TimeCardDetail
                : y,
            ),
          } as TimeCard
          : x,
      ));
    case 'ADD_TIME_CARD':
      state.splice(action.insertIndex, 0, action.newTimeCard);
      return state;
    case 'DELETE_TIME_CARD':
      return state.filter((x) => x.transmittalTimeCardId !== action.timeCardId);
    case 'ADD_DETAIL': {
      return state.map((x) => 
        x.transmittalTimeCardId === action.newTimeSheet.transmittalTimeCardId
          ? {
            ...x,
            details: [
              ...x.details,
              action.newTimeSheet,
            ],
          } as TimeCard
          : x,
      );
    }
    /* Deleting a TimeCardDetail */
    case 'DELETE_DETAIL': 
      return state.map((x) => 
        x.transmittalTimeCardId === action.timeCardId
          ? {
            ...x,
            details: x.details.filter((y) => y.transmittalTimeCardDetailId !== action.timeCardDetailId),
          } as TimeCard
          : x,
      );
    case 'ADD_DEDUCTION':
      return state.map((x) =>
        x.transmittalTimeCardId === action.newDeduction.transmittalTimeCardId
          ? {
            ...x,
            deductions: [...x.deductions, action.newDeduction],
          } as TimeCard
          : x,
      );
    case 'UPDATE_DEDUCTION': 
      return (state.map((x) => 
        x.transmittalTimeCardId === action.timeCardId 
          ? {
            ...x,
            deductions: x.deductions.map((y) => 
              y.transmittalTimeCardDeductionId === action.timeCardDeductionId 
                ? {
                  ...y,
                  [action.field]: action.value,
                }
                : y,
            ),
          } as TimeCard
          : x,
      ));
    case 'DELETE_DEDUCTION':
      return state.map((x) => 
        x.transmittalTimeCardId === action.timeCardId
          ? {
            ...x,
            deductions: x.deductions.filter((y) => y.transmittalTimeCardDeductionId !== action.timeCardDeductionId),
          } as TimeCard
          : x,
      );
    /* Resetting everything */
    case 'RESET_STATE':
      return action.newState;
    default:
      return state;
  }
}