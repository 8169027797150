import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Deduction, DeductionRequest, GarnishmentEntities } from '../../models';
import { DeductionService } from 'core/services';
import { handleError, handleSuccess } from './app.action';

export const DED_EM_VALIDATION_ACTIONS = {
  CREATE_DEDUCTION: '[employee] CREATE_DEDUCTION',
  DELETE_DEDUCTION: '[employee] DELETE_DEDUCTION',
  //TODO: how to handle deleteDeductionAsync?
} as const;

export const loadDeductions = createAction<string>('[employee] LOAD_DEDUCTIONS');
export const storeDeductions = createAction<Deduction[]>('[employee] STORE_DEDUCTIONS');
export const loadDeductionsFail = createAction<any>('[employee] LOAD_DEDUCTIONS_FAIL');
export const createDeduction = createAction<DeductionRequest>(DED_EM_VALIDATION_ACTIONS.CREATE_DEDUCTION);
export const deleteDeduction = createAction<any>('[employee] DELETE_DEDUCTION');

export const deleteDeductionAsync = createAsyncThunk(
  '',
  async (request: Deduction, { dispatch }) => {
    try {
      const _res = await DeductionService.deleteDeduction(request);
        
      dispatch(handleSuccess('Deduction deleted successfully'));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const storeDeduction = createAction<any>('[employee] STORE_DEDUCTION');

export const loadDeductionsByType = createAction<number>('[employee] LOAD_DEDUCTIONS_BY_TYPE');
export const storeDeductionsByType = createAction<Deduction[]>('[employee] STORE_DEDUCTIONS_BY_TYPE');
export const loadDeductionsByTypeFail = createAction<any>('[employee] LOAD_DEDUCTIONS_BY_TYPE_FAIL');

export const loadGarnishmentEntities = createAction('[employee] LOAD_GARNISHMENT_ENTITIES');
export const storeGarnishmentEntities = createAction<GarnishmentEntities[]>('[employee] STORE_GARNISHMENT_ENTITIES');

//Updated it so we just grab the company benefits off the deduction state.
export const loadCompanyBenefits = createAction<string>('[employee] LOAD_COMPANY_BENEFITS');
export const storeCompanyBenefits = createAction<Deduction[]>('[employee] STORE_COMPANY_BENEFITS');

export const setBlockNav = createAction<boolean>('[employee] TOGGLE_BLOCK_NAV');

export const updateSavingState = createAction<'pending' | 'success' | 'error'>('[employee] UPDATE_SAVE');