import React, { useState } from 'react';
import Icon from 'core/components/shared/Icon';
import { Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { SelectGrp } from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { getLocationDepartmentsWithoutHome } from 'core/store/selectors';

const fs: FieldInputSettings = {
  dept: {
    name: 'dept',
    labelField: 'deptDesc',
    valueField: 'deptCode',
  },
  subDept: {
    name: 'subDept',
    labelField: 'subDeptDesc',
    valueField: 'subDeptCode',
  },
  subDept2: {
    name: 'subDept2',
    labelField: 'sub2Desc',
    valueField: 'sub2Code',
  },
};

type Props = {
  errors: any;
  index: number;
  earning: any;
  earningIndex: number;
};

const AdditionalEarningInfo = ({
  errors,
  index,
  earning,
  earningIndex,
}: Props) => {
  const { deptOpts, subdeptOpts, subdept2Opts } = useSelector(
    getLocationDepartmentsWithoutHome,
  );
  const [openState, setOpenState] = useState<boolean>(false);

  const toggleInfo = () => {
    setOpenState(!openState);
  };

  return (
    <>
      {earning && (
        <div>
          <div className="row">
            <div className="col mt-2">
              <div
                role="button"
                className="dm-card-subtitle5"
                onClick={toggleInfo}
              >
                <Icon
                  name={openState ? 'chevron-down' : 'chevron-right'}
                  className="mr-2"
                />
                Additional
              </div>
            </div>
          </div>
          <Collapse in={openState}>
            <div>
              <div className="d-flex flex-column">
                {deptOpts.length !== 0 ? (
                  <SelectGrp
                    {...fs.dept}
                    options={deptOpts}
                    label="DEPT NO"
                    name={`items[${index}].earnings[${earningIndex}].dept`}
                    errors={errors.dept}
                    defaultValue={earning.dept}
                    readOnly={true}
                    showId={true}
                  />
                ) : null}
                {subdeptOpts.length !== 0 ? (
                  <SelectGrp
                    {...fs.subDept}
                    options={subdeptOpts}
                    label="SUB DEPT NO"
                    name={`items[${index}].earnings[${earningIndex}].subDept`}
                    errors={errors.subDept}
                    defaultValue={earning.subDept}
                    readOnly={true}
                    showId={true}
                  />
                ) : null}
                {subdept2Opts.length !== 0 ? (
                  <SelectGrp
                    {...fs.subDept2}
                    options={subdept2Opts}
                    label="SUB DEPT 2 NO"
                    name={`items[${index}].earnings[${earningIndex}].subDept2`}
                    errors={errors.subDept2}
                    defaultValue={earning.subDept2}
                    readOnly={true}
                    showId={true}
                  />
                ) : null}
              </div>
            </div>
          </Collapse>
        </div>
      )}
    </>
  );
};

export default AdditionalEarningInfo;
