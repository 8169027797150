import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpResponse } from '../../models';

import {IntegrationLog} from '../../models/IntegrationLog.model';
import { IntegrationService } from '../../services/integration.service';
import { handleError } from '../actions';
import {loadIntegrationLogs,storeIntegrationLogs} from '../actions/integration.action';

interface Actions {
  type: string;
  payload: any;
}

const loadIntegrationLogs$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadIntegrationLogs.type),
    switchMap(() => {
      return from(IntegrationService.getClientIntegrationLogs()).pipe(
        map((res: any) => { return res.data; }),
        map((res: IntegrationLog[]) => { return storeIntegrationLogs(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [loadIntegrationLogs$];
