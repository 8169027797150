/* eslint-disable react/prop-types */
import React, { createContext, FC, ReactNode, useContext, useState } from 'react';
import styles from './shared.module.scss';
import Icon from './Icon';

type DetailRowContextType = {
  rowClick: boolean;
  expanded: boolean;
  toggleExpanded: () => void;
};

const DetailRowContext = createContext<DetailRowContextType>({
  rowClick: false,
  expanded: false,
  toggleExpanded: () => {},
});

type Slots = {
  SummaryContainer: FC<{ children: ReactNode }>;
  DetailsContainer: FC<{ children: ReactNode }>;
};

type Props = {
  rowClick?: boolean;
  onClick?: (openState: boolean) => void;
  expandByDefault?: boolean;
  children: ReactNode;
};

const DetailRow: FC<Props> & Slots = ({ rowClick = false, onClick, expandByDefault = false, children }) => {
  const [expanded, setExpanded] = useState<boolean>(expandByDefault);
  
  const toggleExpanded = () => {
    onClick?.(!expanded);
    setExpanded((prevState) => !prevState);
  };
  
  const contextVal: DetailRowContextType = {
    rowClick,
    expanded,
    toggleExpanded,
  };
  
  return (
    <details
      className={styles['detail-row']}
      open={expanded}
    >
      <DetailRowContext.Provider value={contextVal}>
        { children }
      </DetailRowContext.Provider>      
    </details>
  );
};

export default DetailRow;

// "master"
DetailRow.SummaryContainer = function SummaryContainer({ children }) {
  const { rowClick, expanded, toggleExpanded } = useContext(DetailRowContext);
  
  return (
    <summary onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      if (rowClick) toggleExpanded();
    }}
    >
      <div className={styles['summary-container']}>
        {children}
        <button
          className={styles['invisible-button']}
          onClick={!rowClick ? toggleExpanded : undefined}
        >
          <Icon
            name={expanded ? 'chevron-circle-up' : 'chevron-circle-down'}
            className={`roster-chevron ${expanded ? 'flipped' : ''}`}
            title={expanded ? 'Click to collapse' : 'Click to expand'}
          />
        </button>
      </div>
    </summary>
  );
};

// "detail" (shown when expanded)
DetailRow.DetailsContainer = function DetailsContainer({ children }) {
  return <div className={styles['details-container']}>
    <hr className="dm-panel-hr" />
    {children}
  </div>;
};