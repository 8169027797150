import { createContext } from 'react';
import {
  TimeCardDeduction,
  TimeCard,
  DetailHourMap,
  FieldArrayMethods,
  TimeCardContextType,
} from 'core/models';
import { UseFormMethods } from 'react-hook-form';

/*
  Note: only coercing these because they should NEVER be an empty object. If it is, something is broken.
  Instead of doing what's been done with most of our other forms (drilling down to the great-great-grandchildren),
  we're just passing all of the form methods through context. This should make it a lot easier to find where the form
  is defined and identify all the moving parts. I wrapped the context provider inside of the form, but I'm actually
  not sure if there's any convention for this kind of thing. It just seemed to make more sense rather than making the
  form a child of its own context.
*/
export const TimeCardContext = createContext<TimeCardContextType>({
  formMethods: {} as UseFormMethods<TimeCard>,
  deductionFieldArrayMethods: {} as FieldArrayMethods<TimeCardDeduction>,
  defaultDetailHoursState: {} as DetailHourMap,
  updateDetailHourState: (_newVal: DetailHourMap) => {},
  updateDirtyState: (_newVal: boolean) => {},
  timeCardId: 0,
  isSaving: false,
  isDirty: false,
  overrideDeleted: false,
  updateOverrideDeleted: (_newVal: boolean) => {},
});