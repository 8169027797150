import Icon from 'core/components/shared/Icon';
import PanelHeader from 'core/components/shared/PanelHeader';
import React, { useEffect, useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { UNSAVED_MESSAGE } from '../../../../core/constants';
import FieldHistoryTab from './FieldHistory';
import { deleteATSCustomField, putApplicantPagesField } from 'core/store/slices/applicantTracking.slice';
import CustomFieldModal from './CustomField.modal';
import { DesignWebsite, PageCustomField } from 'core/models';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { cloneDeep } from 'lodash';

const DesignAtsWebPages = () => {
  const dispatch = useAppDispatch();
  const designWebsite = useAppSelector((state) => { return state.applicantTrackingV2.designWebsite; });
  const [selectedTab, setSelectedTab] = useState('personal');
  const [selectedField, setSelectedField] = useState<PageCustomField>();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const { handleSubmit, reset, formState: { isDirty }, ...formMethods } = useForm<DesignWebsite>({ 
    defaultValues: { ...designWebsite },
  });

  useEffect(() => {
    designWebsite && reset(cloneDeep(designWebsite));
    
    if (designWebsite && selectedField) {
      const customFields = Object.values(designWebsite as DesignWebsite)
        .map((obj: any) => {
          return obj.pageCustomFields ? Object.values(obj.pageCustomFields) : [];
        })
        .reduce((acc, vals) => {
          return acc.concat(vals);
        }, []) as PageCustomField[];        

      setSelectedField(customFields.find((f:PageCustomField) => {return f.customFieldId === selectedField.customFieldId;}));
    }
  }, [designWebsite]);

  const onSubmit = (data: any) => {   
    const request = { ...designWebsite,
      ...data };
    dispatch(putApplicantPagesField(request));
  };

  const onAdd = () => {
    setSelectedField(undefined);
    setShowModal(true);
  };

  const onEdit = (sf:PageCustomField) => {
    setSelectedField(sf);
    setShowModal(true);
  };

  
  const onDelete = (sf:PageCustomField) => {
    setSelectedField(sf);
    setShowDeleteConfirmationModal(true);
  };

  const onDeleteField = () => {
    selectedField && dispatch(deleteATSCustomField(selectedField));
  };

  const confirmDelete = (confirm: boolean) => {
    if (confirm) {
      onDeleteField();
    }
  };

  const onCancel = ()=>{
    designWebsite && reset(designWebsite);
  };

  return (
    <Row className="no-gutters p-0">
      <Prompt
        when={isDirty}
        message={UNSAVED_MESSAGE}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-100"
      >
        <Col className="mt-1 shadow p-3 border">
          <PanelHeader
            title="Design ATS Website"
            iconName="wand-magic-sparkles"
          />
          <div className="bg-light mt-3 p-3">
            <div className=" d-flex flex-row">
              <h2 className="font-weight-bold">Web Pages</h2>
              <button
                type="button"
                className="btn btn-link dm-grid-action-title pb-0 mr-2 flex-grow-1 justify-content-end text-right"
                onClick={onAdd}
              >
                Add Custom Field <Icon
                  name="plus-circle"
                  className="fa-plus-circle"
                />
              </button>
            </div>
            <Tabs
              defaultActiveKey="personal"
              className="px-3 mt-3"
              activeKey={selectedTab}
              onSelect={(tab) => {
                setSelectedTab(tab || 'personal');
              }}
            >
              <Tab
                eventKey="personal"
                title="Personal Information"
              >
                <FieldHistoryTab 
                  key="personal"                   
                  section="personalInformation"
                  formMethods={formMethods}
                  openEdit={(sf:PageCustomField) => {onEdit(sf);}}
                  onDelete={(sf:PageCustomField) => {onDelete(sf);}}
                  personalInformation={designWebsite?.personalInformation}
                />
              </Tab>
              <Tab
                eventKey="employment"
                title="Employment History"
              >
                <FieldHistoryTab 
                  key="employment"   
                  section="employmentHistory"                    
                  formMethods={formMethods}
                  openEdit={(sf:PageCustomField) => {onEdit(sf);}}
                  onDelete={(sf:PageCustomField) => {onDelete(sf);}}
                />
              </Tab>
              <Tab
                eventKey="education"
                title="Education History"
              >
                <FieldHistoryTab 
                  key="education"   
                  section="educationHistory"                    
                  formMethods={formMethods}
                  openEdit={(sf:PageCustomField) => {onEdit(sf);}}
                  onDelete={(sf:PageCustomField) => {onDelete(sf);}}
                />
              </Tab>
              <Tab
                eventKey="training"
                title="Training History"
              >
                <FieldHistoryTab 
                  key="training" 
                  section="trainingHistory"                  
                  formMethods={formMethods}
                  openEdit={(sf:PageCustomField) => {onEdit(sf);}}
                  onDelete={(sf:PageCustomField) => {onDelete(sf);}}
                />
              </Tab>
            </Tabs>
            <div className="d-flex mt-3">
              <button
                type="button"
                className="orange-outline-button ml-auto mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                onSubmit={onSubmit}
                className="orange-button mr-2"
              >
                Save
              </button>
            </div>
          </div>
        </Col>     
      </form>
      {showModal && designWebsite?.webSiteConfigId && (
        <CustomFieldModal
          show={showModal}
          item={selectedField}
          webSiteConfigId={designWebsite.webSiteConfigId}
          onHide={()=>{return setShowModal(false);}}
        />
      )}
      {showDeleteConfirmationModal && (
        <ConfirmationModal
          title={'Delete Custom Field'}
          message={'Are you sure you want to Delete this field?'}
          show={showDeleteConfirmationModal}
          onConfirmed={confirmDelete}
          onHide={() => { return setShowDeleteConfirmationModal(false); }}
        />
      )}
    </Row>
  );
};

export default DesignAtsWebPages;
