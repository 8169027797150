import { createReducer } from '@reduxjs/toolkit';
import { RecurringEarnings } from '../../models';
import { loadRecurringEarnings, storeRecurringEarning, storeRecurringEarnings } from '../actions';
import { UnknownAsyncThunkAction } from '@reduxjs/toolkit/dist/matchers';

// check if we're done loading the earnings, whether it's an error or successful
const isLoadComplete = (action: UnknownAsyncThunkAction) => {
  return ['fulfilled', 'rejected'].includes(action?.meta?.requestStatus ?? '');
};

export interface State {
  loading: boolean;
  recurringEarnings: RecurringEarnings[];
}

export const INITIAL_STATE: State = {
  loading: false,
  recurringEarnings: [],
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadRecurringEarnings.pending, (state) => {
      state.loading = true;
    })
    .addCase(storeRecurringEarnings, (state, action) => {
      state.loading = false;
      state.recurringEarnings = action.payload;
    })
    .addCase(storeRecurringEarning, (state, action) => {
      return {
        ...state,
        loading: false,
        recurringEarnings: action.payload,
      };
    })
    .addMatcher(isLoadComplete, (state) => { // NOTE: this has to end the chain, it can't be in between two cases.
      state.loading = false;
    });
});