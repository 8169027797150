import { createReducer } from '@reduxjs/toolkit';
import { EmpGroupTerm } from '../../models';
import { loadEmployeeGroupTerm, storeEmployeeGroupTerm, updateEmployeeGroupTerm } from '../actions';

export interface State {
  loading: boolean;
  saving: boolean;
  employeeGroupTerm: EmpGroupTerm
}

const INITIAL_STATE = {
  loading: false,
  saving: false,
  employeeGroupTerm: {},
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadEmployeeGroupTerm, (state) => {
      state.loading = true;
    })
    .addCase(updateEmployeeGroupTerm, (state) => {
      state.loading = true;
      state.saving = true;
    })
    .addCase(storeEmployeeGroupTerm, (state, action) => {
      state.loading = false;
      state.saving = false;
      state.employeeGroupTerm = {
        ...action.payload,
        multiplier: parseFloat('' + action?.payload?.multiplier).toFixed(2),
      };
    });
});
