import { createSelector } from 'reselect';
import { employeeReportsFeatureState } from '../reducers';

export const getSelectedEmpDeductionsShortageReport = createSelector(
  employeeReportsFeatureState,
  (state: any) => {return state?.deductionsShortageReport?.value;},
);

export const getSelectedEmployeeChangesReport = createSelector(
  employeeReportsFeatureState,
  (state: any) => {return state?.employeeChangeReport?.value;},
);

export const getEmployeeChangesReportFields = createSelector(
  employeeReportsFeatureState,
  (state: any) => {return state?.employeeChangeReportFields;},
);

export const getEmployeeChangesReportError = createSelector(
  employeeReportsFeatureState,
  (state: any) => {return state?.employeeChangeReportError;},
);

export const getSelectedEmployeeMasterReport = createSelector(
  employeeReportsFeatureState,
  (state: any) => {return state?.employeeMasterReport?.value;},
);