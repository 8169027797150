import { FunctionComponent } from 'react';
import PayRateHistoryModal from 'core/components/modals/PayRateHistory.modal';
import PayStubsModal from 'core/components/modals/PayStubs.modal';
import ViewTaxDocsModal from 'core/components/modals/ViewTaxDocs.modal';

type EmpDetailSidebarSubItem = {
  id: string;
  title: string;
  href: string;
  workItemId?: number;
  modal?: FunctionComponent<any>;
};
type EmpDetailSidebarItem = {
  id: string;
  title: string;
  children: EmpDetailSidebarSubItem[];
};

export const data: EmpDetailSidebarItem[] = [
  {
    id: '1',
    title: 'EMPLOYEE INFORMATION',
    children: [
      {
        id: '1a',
        title: 'Employee Information',
        href: '/employee/detail/{protectedEmpNo}/snapshot',
        workItemId: 1,
      },
      {
        id: '1b',
        title: 'Emergency Contact',
        href: '/employee/detail/{protectedEmpNo}/emergency-contact',
      },
      {
        id: '1c',
        title: 'Direct Deposit',
        href: '/employee/detail/{protectedEmpNo}/direct-deposit',
        workItemId: 5,
      },
      {
        id: '1d',
        title: 'Employee Deductions',
        href: '/employee/detail/{protectedEmpNo}/deductions',
        workItemId: 5,
      },
    ],
  },
  {
    id: '2',
    title: 'PAY RATE',
    children: [
      {
        id: '2a',
        title: 'Current Rate',
        href: '/employee/detail/{protectedEmpNo}/payrate',
        workItemId: 2,
      },
      {
        id: '2b',
        title: 'History',
        href: '',
        modal: PayRateHistoryModal,
        workItemId: 2,
      },
      {
        id: '2c',
        title: 'Pay Stubs',
        href: '',
        modal: PayStubsModal,
        workItemId: 2,
      },
      {
        id: '2d',
        title: 'Check Calculator',
        href: '/employee/detail/{protectedEmpNo}/check-calculator',
        workItemId: 2,
      },
    ],
  },
  {
    id: '3',
    title: 'TAX INFO',
    children: [
      {
        id: '3a',
        title: 'Taxes',
        href: '/employee/detail/{protectedEmpNo}/taxes',
        workItemId: 4,
      },
      {
        id: '3b',
        title: 'Tax Documents/I-9',
        href: '',
        modal: ViewTaxDocsModal,
        workItemId: 4,
      },
    ],
  },
  {
    id: '4',
    title: 'ADDITIONAL INFORMATION',
    children: [
      {
        id: '4q',
        title: 'ACA',
        href: '/employee/detail/{protectedEmpNo}/aca',
        workItemId: 40,
      },
      {
        id: '4g',
        title: 'Accruals',
        href: '/employee/detail/{protectedEmpNo}/accruals',
        workItemId: 24,
      },
      {
        id: '4l',
        title: 'Attendance On Demand',
        href: '/employee/detail/{protectedEmpNo}/attendance-on-demand',
        workItemId: 44,
      },
      {
        id: '4i',
        title: 'Client Rate Rules',
        href: '/employee/detail/{protectedEmpNo}/client-rate-rules',
        workItemId: 9,
      },
      {
        id: '4r',
        title: 'Company Benefits',
        href: '/employee/detail/{protectedEmpNo}/company-benefits',
        workItemId: 16,
      },
      {
        id: '4m',
        title: 'Contractor Options',
        href: '/employee/detail/{protectedEmpNo}/contractor-options',
        workItemId: 42,
      },
      {
        id: '4e',
        title: 'Dates',
        href: '/employee/detail/{protectedEmpNo}/dates',
        workItemId: 7,
      },
      {
        id: '4a',
        title: 'Deferred Comp',
        href: '/employee/detail/{protectedEmpNo}/deferred-comp',
        workItemId: 30,
      },
      {
        id: '4j',
        title: 'Earnings Records',
        href: '/employee/detail/{protectedEmpNo}/earnings-records',
        workItemId: 10,
      },
      {
        id: '4k',
        title: 'Group Term Life',
        href: '/employee/detail/{protectedEmpNo}/group-term-life',
        workItemId: 32,
      },
      {
        id: '4o',
        title: 'HR Doctor Comp',
        href: '/employee/detail/{protectedEmpNo}/hr-doc-comp',
        workItemId: 27,
      },
      {
        id: '4p',
        title: 'HR FTE',
        href: '/employee/detail/{protectedEmpNo}/hr-fte',
        workItemId: 26,
      },
      {
        id: '4b',
        title: 'Payroll Information',
        href: '/employee/detail/{protectedEmpNo}/payroll-info',
        workItemId: 6,
      },
      {
        id: '4s',
        title: 'Realign Supervised Emp',
        href: '/employee/detail/{protectedEmpNo}/realign-supervised-employees',
      },
      {
        id: '4f',
        title: 'Recurring Earnings',
        href: '/employee/detail/{protectedEmpNo}/recurring-earnings',
        workItemId: 22,
      },
      {
        id: '4n',
        title: 'Self Service',
        href: '/employee/detail/{protectedEmpNo}/self-serve',
        workItemId: 34,
      },
      {
        id: '4d',
        title: 'Special Info',
        href: '/employee/detail/{protectedEmpNo}/special-info',
        workItemId: 39,
      },
      {
        id: '4c',
        title: 'Visa Status',
        href: '/employee/detail/{protectedEmpNo}/visa-status',
        workItemId: 43,
      },
      {
        id: '4h',
        title: 'YTD',
        href: '/employee/detail/{protectedEmpNo}/ytd',
        workItemId: 8,
      },
    ],
  },
];