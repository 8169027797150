import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { FieldInputSettings } from 'core/components/form-controls/types';
import {
  Labels,
  SelectGrp,
  SelectModalGrp,
} from 'core/components/form-controls';
import HRFtePercentage from './HRFtePercentage';
import {
  getEmpFte,
  getGEDCDocCompCareerLevels,
  getGeographicLevel,
  getSelectedEmp,
  getChangeReasons,
  getLocationDepartmentsWithoutHome,
  getSalaryGrades,
} from 'core/store/selectors';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { EmpFte, Employee, FtePercentage } from 'core/models';
import {
  createEmployeeFte,
  deleteEmployeeFte,
  loadEmployeeFte,
  updateEmployeeFte,
} from 'core/store/actions';
import PanelHeader from 'core/components/shared/PanelHeader';
import DropdownOptionForm from 'core/components/form-controls/select-modal/DropdownOptionForm';
import { convToDateString } from 'utilities/utilities';
import { toast } from 'react-toastify';

import Icon from 'core/components/shared/Icon';

const fs: FieldInputSettings = {
  selectEffectiveDate: {
    name: 'selectEffectiveDate',
    noOption: true,
    addOptionText: 'Filter',
    label: 'View Record Dates',
    groupClass: 'gc10 mw200',
  },
  endDate: {
    name: 'endDate',
    label: 'STOP DATE',
    groupClass: 'gc10 mw125',
  },
  reasonId: {
    name: 'reasonId',
    label: 'CHANGE REASON',
    groupClass: 'gc95 mw300',
    modalTitle: 'CHANGE REASON',
    formComponent: DropdownOptionForm,
    dropdownName: 'ChangeReasons',
    noOption: true,
    addOptionText: 'Change Reason',
    required: true,
  },
  homeBaseLocation: {
    name: 'homeBaseLocation',
    noOption: true,
    addOptionText: 'Location',
    label: 'HOME BASE LOCATION',
    labelField: 'deptDesc',
    valueField: 'deptCode',
    groupClass: 'gc95 mw300',
  },
  careerLevelId: {
    name: 'careerLevelId',
    label: 'CAREER LEVEL',
    groupClass: 'gc95 mw300',
    modalTitle: 'CAREER LEVEL',
    formComponent: DropdownOptionForm,
    dropdownName: 'GEDCDocCompCareerLevel',
    noOption: true,
    addOptionText: 'Career Level',
  },
  salaryGradeId: {
    name: 'salaryGradeId',
    label: 'SALARY GRADE',
    groupClass: 'gc95 mw300',
    modalTitle: 'SALARY GRADE',
    formComponent: DropdownOptionForm,
    dropdownName: 'SalaryGrade',
    noOption: true,
    addOptionText: 'Salary Grade',
  },
  geographicLevelId: {
    name: 'geographicLevelId',
    label: 'GEOGRAPHIC LEVEL',
    groupClass: 'gc95 mw300',
    modalTitle: 'GEOGRAPHIC LEVEL',
    formComponent: DropdownOptionForm,
    dropdownName: 'GeographicLevel',
    noOption: true,
    addOptionText: 'Geographic Level',
  },
};

const HRFtePage: React.FC<any> = () => {
  const dispatch = useDispatch();
  const { protectedEmpNo } = useParams<any>();
  const employeeFteStore = useSelector(getEmpFte);
  const changeReasonsOpts = useSelector(getChangeReasons);
  const careerLevelOpts = useSelector(getGEDCDocCompCareerLevels);
  const geographicLeveOpts = useSelector(getGeographicLevel);
  const salaryGradeOpts = useSelector(getSalaryGrades);
  const { deptOpts } = useSelector(getLocationDepartmentsWithoutHome);

  const selEmp = useSelector(getSelectedEmp) as Employee;
  const maxPercent = 100;

  const [effectiveDates, setEffectiveDates] = useState<any[]>([]);

  const { setValue, reset, errors, register, handleSubmit, control } =
    useForm<any>();

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'items',
  });

  useEffect(() => {
    protectedEmpNo &&
      dispatch(loadEmployeeFte({ protectedEmpNo }));
  }, [protectedEmpNo]);

  useEffect(() => {
    if (employeeFteStore.length) {
      const fte = employeeFteStore.map((f) => {
        return {
          ...f,
          effectiveDate: f.effectiveDate
            ? new Date(f.effectiveDate)
            : null,
          endDate: f.endDate ? new Date(f.endDate) : null,
        };
      });
      reset({
        items: fte,
        selectEffectiveDate: '',
      });
      const efDates = [
        ...new Set(
          employeeFteStore.map((f: any) => {
            return f.effectiveDate;
          }),
        ),
      ];
      setEffectiveDates(
        efDates.map((d, index) => {
          return {
            id: index,
            description: convToDateString(new Date(d)),
          };
        }),
      );
    } else {
      reset({
        items: [],
        selectEffectiveDate: '',
      });
    }
  }, [employeeFteStore]);

  const onEffDateChange = (e: any) => {
    const date = effectiveDates.find((d) => { return d.id === +e.target.value; });
    const fte = employeeFteStore.map((f) => {
      return {
        ...f,
        effectiveDate: f.effectiveDate
          ? new Date(f.effectiveDate)
          : null,
        endDate: f.endDate ? new Date(f.endDate) : null,
      };
    });
    if (e.target.value !== '') {
      reset({
        items: fte.filter((f: any) => {
          return (
            convToDateString(new Date(f.effectiveDate)) ===
            date.description
          );
        }),
      });
    } else {
      reset({
        items: fte,
      });
    }
  };

  const onAddFte = () => {
    const newfte = new EmpFte(selEmp?.empId, selEmp?.protectedEmpNo, []);
    newfte.effectiveDate = new Date();
    newfte.isNew = true;
    newfte.percentages.push({
      fteId: 0,
      fteHeadId: 0,
      loc: 0,
      dept: 0,
      subDept: 0,
      subDept2: 0,
      percent: 0,
      homeFte: true,
      promotion: false,
    });
    if (employeeFteStore.length) {
      prepend(newfte, true);
    } else {
      reset({
        items: [newfte],
      });
    }
  };

  const onDeleteFte = (id: number, isNew: boolean, index: number) => {
    if (!isNew) {
      dispatch(
        deleteEmployeeFte({
          protectedEmpNo: selEmp?.protectedEmpNo,
          fteHeadId: id,
        }),
      );
    } else {
      remove(index);
    }
  };

  const onSave = (data: any, item?: any) => {
    const fte = data.items.find((i: any) => { return i.id === item.id; });
    const percentTotal: number =
      fte.percentages
        ?.map((item: FtePercentage) => { return item.percent || 0; })
        ?.reduce(
          (prev: string | number, next: string | number) => { return +prev + +next; },
        ) || 0;
    const percentageCast = fte.percentages.map((p: FtePercentage) => {
      return {
        ...p,
        percent: +p.percent,
      };
    });

    const isPercentInValid = percentTotal > maxPercent;
    if (!isPercentInValid) {
      if (!item.isNew) {
        const updatedFte = {
          ...item,
          ...fte,
          percentages: percentageCast,
        };
        dispatch(
          updateEmployeeFte({
            ...new EmpFte(
              selEmp?.empId,
              selEmp?.protectedEmpNo,
              updatedFte,
            ),
          }),
        );
      } else {
        const newFte = {
          ...fte,
          percentages: percentageCast,
        };
        dispatch(
          createEmployeeFte({
            ...new EmpFte(
              selEmp?.empId,
              selEmp?.protectedEmpNo,
              newFte,
            ),
          }),
        );
      }
    } else {
      toast.error(
        'Error Saving. You need at least one Percent and less then 100% total.',
      );
    }
  };

  const handleDateChange = (dateName: string, dateValue: any) => {
    setValue(dateName, dateValue);
  };

  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="HR FTE">
        <button
          className="btn btn-link dm-grid-action-title p-0 mr-2"
          onClick={onAddFte}
        >
          Add New Full-time Equivalent Record{' '}
          <Icon name="plus-circle"
            className="fa-plus-circle" />
        </button>
        <SelectGrp
          {...fs.selectEffectiveDate}
          errors={errors.selectEffectiveDate}
          ref={register()}
          options={effectiveDates}
          onChange={onEffDateChange}
        />
      </PanelHeader>

      {fields?.map((field, index) => {
        return (
          <div
            key={field.id}
            className="dm-panel dm-panel-border mt-2"
          >
            <form
              onSubmit={handleSubmit((data) => { return onSave(data, field); },
              )}
            >
              <div className="d-flex flex-row">
                <div className="col-4 ">
                  <strong>
                    Record Date:{' '}
                    {convToDateString(
                      new Date(field.effectiveDate),
                    )}
                  </strong>
                </div>

                <div className="col-8 text-right">
                  <button
                    type="button"
                    className="btn btn-link dm-grid-action-title pb-0"
                    onClick={() => {
                      return onDeleteFte(
                        +(field.fteHeadId || 0),
                        field.isNew,
                        index,
                      );
                    }
                    }
                  >
                    Delete Record{' '}
                    <Icon name="minus-circle"
                      className="fa-minus-circle" />
                  </button>
                </div>
              </div>
              <div className="d-flex flex-wrap mt-3 mr-10">
                <input
                  type="hidden"
                  name={`items[${index}].id`}
                  defaultValue={field.id}
                  ref={register()}
                />
                <div
                  style={{
                    marginRight: '10px',
                  }}
                >
                  <Labels
                    label={'Effective Date'}
                    hasError={false}
                    required={true}
                  />
                  <Controller
                    name={`items[${index}].effectiveDate`}
                    control={control}
                    defaultValue={field.effectiveDate}
                    ref={register()}
                    render={({ value }) => {
                      return (
                        <div>
                          <DatePicker
                            enableTabLoop={false}
                            required={true}
                            selected={value}
                            onChange={(value: any) => {
                              return handleDateChange(
                                `items[${index}].effectiveDate`,
                                value,
                              );
                            }
                            }
                          />
                        </div>
                      );
                    }}
                  />
                </div>
                <SelectModalGrp
                  {...fs.reasonId}
                  name={`items[${index}].reasonId`}
                  options={changeReasonsOpts}
                  errors={errors.reasonId}
                  control={control}
                  value={field.reasonId?.toString()}
                  required={true}
                  rules={{ required: 'Reason is required' }}
                  setValue={setValue}
                />
                <SelectModalGrp
                  {...fs.careerLevelId}
                  name={`items[${index}].careerLevelId`}
                  errors={errors.careerLevelId}
                  control={control}
                  value={field.careerLevelId?.toString()}
                  setValue={setValue}
                  options={careerLevelOpts}
                />
                <SelectModalGrp
                  {...fs.salaryGradeId}
                  name={`items[${index}].salaryGradeId`}
                  errors={errors.salaryGradeId}
                  control={control}
                  value={field.salaryGradeId?.toString()}
                  setValue={setValue}
                  options={salaryGradeOpts}
                />
              </div>
              <div className="d-flex flex-wrap mt-3">
                <div
                  style={{
                    marginRight: '10px',
                  }}
                >
                  <Labels
                    label={'Stop Date'}
                    hasError={false}
                  />
                  <Controller
                    name={`items[${index}].endDate`}
                    control={control}
                    defaultValue={field.endDate}
                    ref={register()}
                    render={({ value }) => {
                      return (
                        <DatePicker
                          enableTabLoop={false}
                          selected={value}
                          minDate={
                            new Date(
                              field.effectiveDate,
                            )
                          }
                          onChange={(value: any) => {
                            return handleDateChange(
                              `items[${index}].endDate`,
                              value,
                            );
                          }
                          }
                        />
                      );
                    }}
                  />
                </div>
                <SelectGrp
                  {...fs.homeBaseLocation}
                  name={`items[${index}].homeBaseLocation`}
                  errors={errors.homeBaseLocation}
                  id={field.fteHeadId}
                  defaultValue={field.homeBaseLocation?.toString()}
                  ref={register()}
                  options={deptOpts}
                />
                <SelectModalGrp
                  {...fs.geographicLevelId}
                  name={`items[${index}].geographicLevelId`}
                  errors={errors.geographicLevelId}
                  control={control}
                  value={field.geographicLevelId?.toString()}
                  setValue={setValue}
                  options={geographicLeveOpts}
                />
              </div>
              <div className="d-flex flex-row">
                <HRFtePercentage
                  nestIndex={index}
                  {...{
                    control,
                    register,
                    errors,
                  }}
                  fteHeadId={field.fteHeadId}
                />
              </div>

              <div className="row mt-2">
                <div className="col-12 text-right">
                  <button
                    type="submit"
                    className="orange-button ml-2"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        );
      })}
    </div>
  );
};

export default HRFtePage;
