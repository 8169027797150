import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { DMDropdownDataModel } from '../../core/components/form-controls/DMDropDown';
import { DMDropdownMenu } from '../../core/components/form-controls';
import DeductionShortagesReportModal from '../employee-reports/DeductionShortagesReport.modal';
import EmployeeChangesReportModal from '../employee-reports/EmployeeChangesReport.modal';
import { EarningsReportType } from '../../core/models/EarningsReports.model';
import { storeStandardReports } from '../../core/store/actions/earnings-reports.action';
import DateRangeModal from '../employee-reports/DateRange.modal';
import EarningsModal from '../employee-reports/Earnings.modal';
import { useAppDispatch, useAppSelector } from '../../utilities/hooks';
import EmployeeMasterReportModal from '../employee-reports/EmployeeMasterReport.modal';
import PayRateHistoryModal from 'core/components/modals/PayRateHistory.modal';
import PayStubsModal from 'core/components/modals/PayStubs.modal';
import ViewTaxDocsModal from 'core/components/modals/ViewTaxDocs.modal';
import EmpDocsModal from 'core/components/modals/EmpDocs.modal';
import { getClient, getSelectedEmp } from 'core/store/selectors';
import { useSelector } from 'react-redux';
import { postEmployeeMasterReport } from 'core/store/actions';
import HamburgerMenu from 'core/components/shared/HamburgerMenu';

type EmployeeToolbarProps = {
  toggleSidebar: () => void;
  showSidebarMenuIcon: boolean;
  isMaster: boolean;
};

type DateType = Date | string;

export interface ModalProps {
  show: boolean;
  onHide: () => void;
}

export interface DateRange {
  beginDate: DateType;
  endDate: DateType;
}

const menuIDs = {
  // reports
  EMPLOYEE_MASTER_REPORT_MENU_ID: 'reports-nav-1',
  EMPLOYEE_MASTER_GROSS_TO_NET_MENU_ID: 'reports-nav-2',
  EMPLOYEE_EARNINGS_HOURS_TOTAL_MENU_ID: 'reports-nav-3',
  EMPLOYEE_HOURS_ONLY: 'reports-nav-4',
  EMPLOYEE_DEDUCTION_TOTALS: 'reports-nav-5',
  EMPLOYEE_DEDUCTIONS_EARNINGS_TAXES: 'reports-nav-6',
  EMPLOYEE_CHANGES_REPORT_MENU_ID: 'reports-nav-7',
  DEDUCTION_SHORTAGES_REPORT_MENU_ID: 'reports-nav-8',
  COMPANY_PAID_BENEFITS: 'reports-nav-9',
  EMPLOYEE_GROSS: 'reports-nav-10',
  // documents
  PAY_RATE_HISTORY: 'docs-nav-1',
  PAY_STUBS: 'docs-nav-2',
  TAX_DOCS: 'docs-nav-3',
  EMP_DOCS: 'docs-nav-4',
};

const reportsDD: DMDropdownDataModel = {
  id: 'reports-dd',
  title: 'Reports',
  groupId: 2,
  items: [
    {
      id: menuIDs.COMPANY_PAID_BENEFITS,
      text: 'Company Paid Benefits',
      href: '#',
    },
    {
      id: menuIDs.DEDUCTION_SHORTAGES_REPORT_MENU_ID,
      text: 'Deduction Shortages',
      href: '#',
    },
    {
      id: menuIDs.EMPLOYEE_CHANGES_REPORT_MENU_ID,
      text: 'Employee Changes Report',
      href: '#',
    },
    {
      id: menuIDs.EMPLOYEE_DEDUCTIONS_EARNINGS_TAXES,
      text: 'Employee Deductions/Earnings/Taxes',
      href: '#',
    },
    {
      id: menuIDs.EMPLOYEE_DEDUCTION_TOTALS,
      text: 'Employee Deduction Totals',
      href: '#',
    },
    {
      id: menuIDs.EMPLOYEE_EARNINGS_HOURS_TOTAL_MENU_ID,
      text: 'Employee Earnings/Hours Totals',
      href: '#',
    },
    {
      id: menuIDs.EMPLOYEE_GROSS,
      text: 'Employee Gross',
      href: '#',
    },
    {
      id: menuIDs.EMPLOYEE_MASTER_GROSS_TO_NET_MENU_ID,
      text: 'Employee Gross To Net',
      href: '#',
    },
    {
      id: menuIDs.EMPLOYEE_HOURS_ONLY,
      text: 'Employee Hours Only',
      href: '#',
    },
    {
      id: menuIDs.EMPLOYEE_MASTER_REPORT_MENU_ID,
      text: 'Employee Master',
      href: '#',
    },
  ],
};

const docsDD: DMDropdownDataModel = {
  id: 'docs-dd',
  title: 'View Documents',
  items: [
    {
      id: menuIDs.PAY_RATE_HISTORY,
      text: 'Pay Rate History',
      href: '#',
    },
    {
      id: menuIDs.PAY_STUBS,
      text: 'Pay Stubs',
      href: '#',
    },
    {
      id: menuIDs.TAX_DOCS,
      text: 'Tax Documents',
      href: '#',
    },
    {
      id: menuIDs.EMP_DOCS,
      text: 'Employee Documents',
      href: '#',
    },
  ],
};

const EmployeeToolbar: React.FC<EmployeeToolbarProps> = ({
  toggleSidebar,
  showSidebarMenuIcon,
  isMaster,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  //TODO: Throw these in a reducer; this doesn't make a lot of sense to do
  const [showEarningsReport, setShowEarningsReport] = useState(false);
  const [showDeductionReport, setShowDeductionReport] = useState(false);
  const [showMasterReport, setShowMasterReport] = useState(false);
  const [showPayRateHistory, setShowPayRateHistory] = useState(false);
  const [showPayStubs, setShowPayStubs] = useState(false);
  const [showTaxDocs, setShowTaxDocs] = useState(false);
  const [showEmpDocs, setShowEmpDocs] = useState(false);
  const [showEmployeeChangesReport, setShowEmployeeChangesReport] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [earningsReportType, setEarningsReportType] = useState<EarningsReportType | null>(null);
  
  const groupAccess = useAppSelector((state) => { return state.app.moduleAccess?.groups; });
  const currentClient = useSelector(getClient);
  const selEmp = useSelector(getSelectedEmp);
  
  const employeeMasterSection = groupAccess?.find((group) => {
    return group.description.toLowerCase() === 'employee master';
  });
  const visible = employeeMasterSection?.visible;

  const handleSubmit = (newVal: DateRange) => {
    setDateRange(newVal);
  };

  const navArray: {
    type: string;
    text?: string;
    href?: string;
    data?: DMDropdownDataModel;
  }[] = [
    {
      type: 'link',
      text: 'Employee Master List',
      href: '/employee-master-list',
    },
    {
      type: 'link',
      text: 'Add Employee',
      href: '/employee/detail/0/add-employee',
    },
    {
      type: 'link',
      text: 'Employee Upload CSV',
      href: '/employee-upload',
    },
  ];

  if (isMaster) {
    navArray.push({
      type: 'dropdown',
      data: reportsDD,
    });
    navArray.push({
      type: 'dropdown',
      data: docsDD,
    });
  }

  const onQuickOnboard = () => { return history.push('/quick-onboard'); };
  
  const generateEMReport = () => {
    if (!(currentClient && selEmp)) {
      throw new Error('Error with current client and/or selected employee');
    }
    
    dispatch(
      postEmployeeMasterReport({
        clientNo: currentClient.clientNo,
        empNo: selEmp.empNo,
        reportType: 'Pdf',
      }),
    );
    
    setShowMasterReport(true);
  };
  
  const onMenuSelect = (menuId: string) => {
    switch (menuId) {
      // reports
      case menuIDs.EMPLOYEE_MASTER_REPORT_MENU_ID:
        generateEMReport();
        break;
      case menuIDs.EMPLOYEE_MASTER_GROSS_TO_NET_MENU_ID:
        setEarningsReportType(EarningsReportType.GrossToNetReport);
        break;
      case menuIDs.EMPLOYEE_EARNINGS_HOURS_TOTAL_MENU_ID:
        setEarningsReportType(
          EarningsReportType.EarningsAndHoursReport,
        );
        break;
      case menuIDs.EMPLOYEE_HOURS_ONLY:
        setEarningsReportType(EarningsReportType.HoursReport);
        break;
      case menuIDs.EMPLOYEE_DEDUCTION_TOTALS:
        setEarningsReportType(EarningsReportType.DeductionReport);
        break;
      case menuIDs.EMPLOYEE_DEDUCTIONS_EARNINGS_TAXES:
        setEarningsReportType(
          EarningsReportType.DeductionsEarningsTaxesReport,
        );
        break;
      case menuIDs.EMPLOYEE_CHANGES_REPORT_MENU_ID:
        setShowEmployeeChangesReport(true);
        break;
      case menuIDs.DEDUCTION_SHORTAGES_REPORT_MENU_ID:
        setShowDeductionReport(true);
        break;
      case menuIDs.COMPANY_PAID_BENEFITS:
        setEarningsReportType(
          EarningsReportType.CompanyPaidBenefitsReport,
        );
        break;
      case menuIDs.EMPLOYEE_GROSS:
        setEarningsReportType(EarningsReportType.GrossReport);
        break;
      // documents
      case menuIDs.PAY_RATE_HISTORY:
        setShowPayRateHistory(true);
        break;
      case menuIDs.PAY_STUBS:
        setShowPayStubs(true);
        break;
      case menuIDs.TAX_DOCS:
        setShowTaxDocs(true);
        break;
      case menuIDs.EMP_DOCS:
        setShowEmpDocs(true);
        break;
      default:
        break;
    }
  };

  const showReportModal = () => {
    return showEarningsReport && earningsReportType;
  };

  const nav = () => {
    if (showSidebarMenuIcon) {
      return (
        <HamburgerMenu
          toggleSidebar={toggleSidebar}  
        />
      );
    } else {
      return navArray.map((n, i) => {
        switch (n.type) {
          case 'link':
            return n.href ? (
              <NavLink
                key={i}
                to={n.href}
              >
                {n.text}
              </NavLink>
            ) : null;
          case 'dropdown':
            return n.data ? (
              <DMDropdownMenu
                key={i}
                data={n.data}
                toggleClass="dm-toolbar-nav"
                whiteChevron={true}
                onSelect={onMenuSelect}
              />
            ) : null;
          default:
            return null;
        }
      });
    }
  };

  useEffect(() => {
    if (earningsReportType) {
      setShowEarningsReport(earningsReportType.length > 0);
      setDateRange(null);
    }
  }, [earningsReportType]);

  const hideModal = () => {
    setEarningsReportType(null);
    setDateRange(null);
    dispatch(storeStandardReports(''));
  };

  const hideReportModal = (
    setterFn: (value: React.SetStateAction<boolean>) => void,
  ) => {
    setterFn(false);
  };

  return (
    <div className="dm-toolbar">
      {visible === false ? null : (
        <>
          {nav()}
          <Button
            className="orange-button ml-auto mr-2"
            onClick={onQuickOnboard}
          >
            Quick Onboard
          </Button>
          {showDeductionReport && (
            <DeductionShortagesReportModal
              show={showDeductionReport}
              onHide={() => {return hideReportModal(setShowDeductionReport);}}
            />
          )}
          {showEmployeeChangesReport && (
            <EmployeeChangesReportModal
              show={showEmployeeChangesReport}
              onHide={() => {return hideReportModal(setShowEmployeeChangesReport);}}
            />
          )}
          {showMasterReport && (
            <EmployeeMasterReportModal
              show={showMasterReport}
              onHide={() => { return hideReportModal(setShowMasterReport); }}
            />
          )}
          {showPayRateHistory && (
            <PayRateHistoryModal
              show={showPayRateHistory}
              onHide={() => { setShowPayRateHistory(false); }}
            />
          )}
          {showPayStubs && (
            <PayStubsModal
              show={showPayStubs}
              onHide={() => { setShowPayStubs(false); }}
            />
          )}
          {showTaxDocs && (
            <ViewTaxDocsModal
              show={showTaxDocs}
              onHide={() => { setShowTaxDocs(false); }}
            />
          )}
          {showEmpDocs && (
            <EmpDocsModal
              show={showEmpDocs}
              onHide={() => { setShowEmpDocs(false); }}
            />
          )}
          {showReportModal() && (
            <>
              {!(dateRange?.beginDate && dateRange?.endDate) ? (
                <DateRangeModal
                  reportType={earningsReportType}
                  show={showEarningsReport}
                  onHide={hideModal}
                  onSubmit={handleSubmit}
                />
              ) : (
                <EarningsModal
                  reportType={earningsReportType}
                  show={showEarningsReport}
                  onHide={hideModal}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default EmployeeToolbar;
