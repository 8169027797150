import { isFunction } from 'lodash';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import Icon from '../shared/Icon';

type ModalSizing = 'sm' | 'lg' | 'xl';

type Props = {
  show?: boolean;
  onHide?: () => void;
  title?: string;
  children: ReactNode | ((closeModal: () => void) => ReactNode);
  size?: ModalSizing;
  headerSlot?: ReactNode;
  animation?: boolean;
  dialogClassName?: string;
  backdrop?: 'static' | boolean;
  centered?: boolean;
  isDirty?: boolean;
  className?: string;
  additionalStyles?: CSSProperties;
};

const Modal = ({
  show,
  onHide,
  title,
  children,
  size = 'sm',
  headerSlot = null,
  animation = false,
  dialogClassName,
  backdrop = true,
  centered = false,
  isDirty = false,
  className,
  additionalStyles,
}: Props) => {
  const [isShown, setIsShown] = useState(false);

  const closeModal = () => {
    !isDirty && setIsShown(false);
    if (onHide) {
      onHide();
    }
  };

  useEffect(() => {
    setIsShown(!!show);
  }, [show]);

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <BootstrapModal
        backdrop={backdrop}
        show={isShown}
        onHide={onHide}
        size={size}
        animation={animation}
        dialogClassName={dialogClassName}
        centered={centered}
        style={additionalStyles}
        className={className}
      >
        <div className="modal-header">
          {title && (
            <div className="dm-card-title">{title}</div>
          )}
          {headerSlot}
          {backdrop !== 'static' ? <button
            type="button"
            onClick={closeModal}
            className="btn-link"
            style={{
              background: 'transparent',
              border: 'none',
              outline: 'none',
            }}
          >
            <Icon
              name="times"
              className="modal-close-btn"
            />
          </button> : null}
        </div>
        <BootstrapModal.Body>
          {isFunction(children) ? children(closeModal) : children}
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

export default Modal;