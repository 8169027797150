import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';
import {
  AllModules,
  ColDef,
  GridApi,
  GridOptions,
  ICellEditorParams,
  ICellRendererParams,
  GridSizeChangedEvent,
  GridReadyEvent,
  RowNode,
} from '@ag-grid-enterprise/all-modules';
import Modal from 'core/components/modals/Modal';
import Icon from 'core/components/shared/Icon';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckboxGrpInLine,
  TextareaGrp,
} from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import {
  Client,
  MessageSelection,
  Payroll,
  PayrollMessage,
} from 'core/models';
import {
  createPayrollMessage,
  deletePayrollMessage,
  updatePayrollMessage,
  updatePayrollMessageOrder,
} from 'core/store/actions';
import { getPayrollMessages } from 'core/store/selectors';
import { agDateEditor } from 'utilities/ag-grid-editors';
import { agDateRenderer } from 'utilities/ag-grid-renderers';
import MessageSelectDeptModal from './MessageSelectDept.modal';
import MessageSelectEmployeesModal from './MessageSelectEmployees.modal';
import MessageSelectLocModal from './MessageSelectLoc.modal';
import MessageSelectSubDeptModal from './MessageSelectSubDept.modal';
import MessageSelectSubDept2Modal from './MessageSelectSubDept2.modal';
import { useAppSelector } from 'utilities/hooks';

const fs: FieldInputSettings = {
  checkMessage: {
    name: 'checkMessage',
    label: '',
  },
  individualMessage: {
    name: 'individualMessage',
    label: '',
    required: true,
  },
  activeOnly: {
    name: 'activeOnly',
    label: 'Show Active Only',
    labelFirst: false,
  },
};

type PropTypes = {
  payroll?: Payroll;
  client: Client;
  show: boolean;
  onHide: () => void;
};

const PayrollMessageModal: React.FC<PropTypes> = ({
  payroll,
  client,
  show,
  onHide,
}) => {
  const dispatch = useDispatch();
  const payrollMessages = useSelector(getPayrollMessages);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [rowData, setRowData] = useState<PayrollMessage[]>([]);
  const [messageLines, setMessageLines] = useState<any>();
  const [selectedMessage, setSelectedMessage] = useState<PayrollMessage>(Object);
  const [activeOnly, setActiveOnly] = useState(true);
  const [showLocModal, setShowLocModal] = useState(false);
  const [showDeptModal, setShowDeptModal] = useState(false);
  const [showSubDeptModal, setShowSubDeptModal] = useState(false);
  const [showSubDept2Modal, setShowSubDept2Modal] = useState(false);
  const [showEmpModal, setShowEmpModal] = useState(false);
  const isSaving = useAppSelector((state) => { return state.payroll.savingMessage; });

  const { setValue, register, errors } = useForm<any>();

  useEffect(() => {
    setValue('activeOnly', true);
  }, []);

  useEffect(() => {
    if (payrollMessages?.length) {
      let messages = [...payrollMessages];
      if (activeOnly) {
        messages = filteredMessages();
      }
      const currentMessage = getMessageLines(messages);
      setMessageLines(currentMessage);
      setValue('checkMessage', currentMessage);
      setRowData(cloneDeep(messages));
    }
  }, [payrollMessages]);

  useEffect(() => {
    gridApi?.setRowData(rowData);
    gridApi?.refreshCells({ force: true });
    //Make sure the row that is sselected stays selected.
    if(selectedMessage.payrollMessageId)
       gridApi?.getRowNode(selectedMessage.payrollMessageId.toString())?.setSelected(true);
  }, [rowData]);

  useEffect(() => {
    if (selectedMessage) {
      setValue('individualMessage', selectedMessage.message);
      gridApi?.forEachNode((node: RowNode) => {
        if (
          node.data.payrollMessageId ===
          selectedMessage.payrollMessageId
        ) {
          node.setSelected(true);
        }
      });
    }
  }, [selectedMessage]);

  const columns: ColDef[] = [
    {
      field: 'messageId',
      headerName: 'Message',
      width: 100,
      cellRendererFramework: (params: any) => {
        if (params.data.payrollMessageId !== -1) {
          return (
            <>
              {params.data.messageId}
              <Icon name="arrow-up" className='ml-5' onClick={() => { return onArrowUpClick(params); }} />
              <Icon name="arrow-down" className='ml-2' onClick={() => { return onArrowDownClick(params); }} />
            </>
          );
        } else {
          return '';
        }
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 170,
      editable: true,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        maxLength: 20,
        rows: 1,
        cols: 20,
      },
    },
    {
      field: 'loc',
      headerName: 'Loc',
      cellRendererFramework: (params: any) => {
        return (
          <>
            <select
              id="loc-select"
              className="form-control"
              defaultValue={params?.data?.loc}
              disabled={params?.data?.employee === 'S'}
              onClick={(e: any) => { return onSelectListChange('loc', e, params); }
              }
            >
              <option value="A">All</option>
              <option value="I">Ignore</option>
              <option value="S">Select</option>
            </select>
          </>
        );
      },
      width: 80,
    },
    {
      field: 'dept',
      headerName: 'Dept',
      cellRendererFramework: (params: any) => {
        return (
          <>
            <select
              className="form-control"
              defaultValue={params?.data?.dept}
              disabled={params?.data?.employee === 'S'}
              onClick={(e) => { return onSelectListChange('dept', e, params); }
              }
            >
              <option value="A">All</option>
              <option value="I">Ignore</option>
              <option value="S">Select</option>
            </select>
          </>
        );
      },
      width: 80,
    },
    {
      field: 'subDept',
      headerName: 'SubDept',
      cellRendererFramework: (params: any) => {
        return (
          <>
            <select
              className="form-control"
              defaultValue={params?.data?.subDept}
              disabled={params?.data?.employee === 'S'}
              onClick={(e) => { return onSelectListChange('subDept', e, params); }
              }
            >
              <option value="A">All</option>
              <option value="I">Ignore</option>
              <option value="S">Select</option>
            </select>
          </>
        );
      },
      width: 80,
    },
    {
      field: 'subDept2',
      headerName: 'SubDept2 ',
      cellRendererFramework: (params: any) => {
        return (
          <>
            <select
              className="form-control"
              defaultValue={params?.data?.subDept2}
              disabled={params?.data?.employee === 'S'}
              onClick={(e) => { return onSelectListChange('subDept2', e, params); }
              }
            >
              <option value="A">All</option>
              <option value="I">Ignore</option>
              <option value="S">Select</option>
            </select>
          </>
        );
      },
      width: 90,
    },
    {
      field: 'employee',
      headerName: 'Employee ',
      cellRendererFramework: (params: any) => {
        return (
          <>
            <select
              className="form-control"
              defaultValue={params?.data?.employee}
              onClick={(e) => { return onSelectListChange('employee', e, params); }
              }
            >
              <option value="A">All</option>
              <option value="I">Ignore</option>
              <option value="S">Select</option>
            </select>
          </>
        );
      },
      width: 90,
    },
    {
      field: 'dateStart',
      headerName: 'Date Start ',
      cellRenderer: 'dateRenderer',
      cellEditor: 'dateEditor',
      width: 115,
      editable: true,
    },
    {
      field: 'dateEnd',
      headerName: 'Date End ',
      cellRenderer: 'dateRenderer',
      cellEditor: 'dateEditor',
      width: 115,
      editable: true,
    },
    {
      field: '',
      headerName: 'Delete',
      maxWidth: 100,
      cellRendererFramework: (params: ICellRendererParams) => {
        const { data }: { data: PayrollMessage } = params;
          
        const removeRecord = (_: React.BaseSyntheticEvent) => {
         if (!(data?.messageId && confirm('Delete Message?'))) return;
         
         setRowData((prevState) => {
          if (!prevState?.length) return [];
            const filteredRows = prevState.filter((outerRow) => { return outerRow.messageId !== data.messageId; });
            return filteredRows; 
          });
          dispatch(deletePayrollMessage(data));
        };
        
        return (
          <div className="ag-custom-button-cell">
            <button
              className="custom-cell-icon remove-item"
              onClick={removeRecord}
              type='button'
            >
              <Icon name="minus-circle"/>
            </button>
            <span className="sr-only">Delete record</span>
          </div>
        );
      },
    },
  ];

  const gridOptions: GridOptions = {
    columnDefs: columns,
    domLayout: 'autoHeight',
    rowSelection: 'single',
    defaultColDef: {
      autoHeight: true,
      suppressMenu: true,
      singleClickEdit: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
      editable: false,
      sortable: false,
      filter: false,
    },
    pagination: true,
    paginationPageSize: 10,
    stopEditingWhenCellsLoseFocus: true,
    getRowNodeId: (data: any) => { return data?.payrollMessageId; },
    // @ts-ignore
    components: {
      dateRenderer: agDateRenderer,
      dateEditor: agDateEditor,
    },
  };

  const getMessageLines = (messages: PayrollMessage[]) => {
    let messageLines = '';
    messages?.forEach((m: PayrollMessage) => {
      messageLines += `${m.messageId + ' - ' + m.message}\n`;
    });
    return messageLines;
  };

  const onAddMessage = () => {
    let hasNew = false;
    gridApi?.forEachNode((node) => {
      if (!node.data.payrollMessageId) {
        hasNew = true;
      }
    });
    if (!hasNew && payrollMessages) {
      const messageIds = payrollMessages.map((m) => { return m?.messageId || 0; });
      const messageId = messageIds?.length ? Math.max(...messageIds) + 1 : 1;
      const message: PayrollMessage = {
        payrollMessageId: -1,
        clientNo: client.clientNo,
        messageId,
        message: 'New Message',
        description: 'New Description',
        loc: 'A',
        locationSelections: [],
        dept: 'A',
        departmentSelections: [],
        subDept: 'A',
        subDeptSelections: [],
        subDept2: 'A',
        subDept2Selections: [],
        employee: 'A',
        employeeSelections: [],
        dateStart: new Date(),
      };
      gridApi?.applyTransaction({
        add: [message],
        addIndex: messageIds?.length - 1,
      });
      gridApi?.forEachNode((node: RowNode) => {
        if (node.data.payrollMessageId === -1) {
          node.setSelected(true);
        }
      });
      setSelectedMessage(message);
    }
  };

  const onSelectListChange = (type: string, e: any, params: any) => {
    if (e.detail === 0) {
      const m = params?.data;
      const message = {
        ...m,
        loc: type === 'loc' ? e.target.value : m.loc,
        locationSelections:
          type === 'loc' && e.target.value !== 'S'
            ? []
            : m.locationSelections,
        dept: type === 'dept' ? e.target.value : m.dept,
        departmentSelections:
          type === 'dept' && e.target.value !== 'S'
            ? []
            : m.departmentSelections,
        subDept: type === 'subDept' ? e.target.value : m.subDept,
        subDeptSelections:
          type === 'subDept' && e.target.value !== 'S'
            ? []
            : m.subDeptSelections,
        subDept2: type === 'subDept2' ? e.target.value : m.subDept2,
        subDept2Selections:
          type === 'subDept2' && e.target.value !== 'S'
            ? []
            : m.subDept2Selections,
        employee: type === 'employee' ? e.target.value : m.employee,
        employeeSelections:
          type === 'employee' && e.target.value !== 'S'
            ? []
            : m.employeeSelections,
      };

      if (type === 'loc' && e.target.value === 'S') {
        setShowLocModal(true);
      } else if (type === 'dept' && e.target.value === 'S') {
        setShowDeptModal(true);
      } else if (type === 'subDept' && e.target.value === 'S') {
        setShowSubDeptModal(true);
      } else if (type === 'subDept2' && e.target.value === 'S') {
        setShowSubDept2Modal(true);
      } else if (type === 'employee' && e.target.value === 'S') {
        setShowEmpModal(true);
      } else {
        if (message.payrollMessageId !== -1) {
          dispatch(updatePayrollMessage(message));
        } else {
          delete message.payrollMessageId;
          dispatch(createPayrollMessage(message));
        }
      }
    }
  };

  const setSelectedLocations = (data: MessageSelection[]) => {
    if (selectedMessage.payrollMessageId !== -1) {
      dispatch(
        updatePayrollMessage({
          ...selectedMessage,
          loc: 'S',
          locationSelections: data,
        }),
      );
    } else {
      delete selectedMessage.payrollMessageId;
      return dispatch(
        createPayrollMessage({
          ...selectedMessage,
          loc: 'S',
          locationSelections: data,
        }),
      );
    }
    dispatch(
      updatePayrollMessage({
        ...selectedMessage,
        loc: 'S',
        locationSelections: data,
      }),
    );
  };

  const setSelectedDepts = (data: MessageSelection[]) => {
    dispatch(
      updatePayrollMessage({
        ...selectedMessage,
        dept: 'S',
        departmentSelections: data,
      }),
    );
  };

  const setSelectedSubDepts = (data: MessageSelection[]) => {
    dispatch(
      updatePayrollMessage({
        ...selectedMessage,
        subDept: 'S',
        subDeptSelections: data,
      }),
    );
  };

  const setSelectedSubDepts2 = (data: MessageSelection[]) => {
    dispatch(
      updatePayrollMessage({
        ...selectedMessage,
        subDept2: 'S',
        subDept2Selections: data,
      }),
    );
  };

  const setSelectedEmps = (data: MessageSelection[]) => {
    dispatch(
      updatePayrollMessage({
        ...selectedMessage,
        employee: 'S',
        loc: 'I',
        dept: 'I',
        subDept: 'I',
        subDept2: 'I',
        employeeSelections: data,
      }),
    );
  };

  const onSelectionChanged = (params: any) => {
    if (params.api) {
      const selectedRows = params.api.getSelectedRows();
      selectedRows.length > 0 && setSelectedMessage(selectedRows[0]);
    }
  };

  const onActiveOnlyChange = (e: any) => {
    if (e.nativeEvent.target.checked) {
      setActiveOnly(true);
      const data = filteredMessages();
      data && setRowData(cloneDeep(data));
      data && setMessageLines(getMessageLines(data));
    } else {
      setActiveOnly(false);
      payrollMessages && setRowData(cloneDeep(payrollMessages));
      payrollMessages &&
        setMessageLines(getMessageLines(payrollMessages));
    }
  };

  const filteredMessages = () => {
    return [...payrollMessages]?.filter(
      (m) => {
        return new Date(m.dateStart || '') <=
          new Date(payroll?.checkDate || 0) &&
          (m.dateEnd
            ? new Date(payroll?.checkDate || 0) <= new Date(m.dateEnd)
            : true);
      },
    );
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);

    params.api.sizeColumnsToFit();

    gridApi?.forEachNode((node: RowNode) => {
      if (node.rowIndex === 0) {
        node.setSelected(true);
      }
    });
  };
  
  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit();
  };

  const onArrowUpClick = (
    params: ICellEditorParams | ICellRendererParams,
  ) => {
    if (payrollMessages && params.rowIndex > 0) {
      const moved = params.api?.getDisplayedRowAtIndex(params.rowIndex)
        ?.data as PayrollMessage;

      const replaced = params.api?.getDisplayedRowAtIndex(
        params.rowIndex - 1,
      )?.data as PayrollMessage;

      const movedId = moved.messageId;
      const replacedId = replaced.messageId;

      moved.messageId = replacedId;
      replaced.messageId = movedId;
      replaced && dispatch(updatePayrollMessageOrder([moved, replaced]));
    }
  };

  const onArrowDownClick = (params: any) => {
    if (
      payrollMessages &&
      params.rowIndex < params?.api?.rowModel.getRowCount() - 1
    ) {
      const moved = params.api?.getDisplayedRowAtIndex(params.rowIndex)
        .data as PayrollMessage;

      const replaced = params.api?.getDisplayedRowAtIndex(
        params.rowIndex + 1,
      )?.data as PayrollMessage;

      const movedId = moved.messageId;
      const replacedId = replaced.messageId;

      moved.messageId = replacedId;
      replaced.messageId = movedId;
      replaced && dispatch(updatePayrollMessageOrder([moved, replaced]));
    }
  };

  const onBlurSave = (e: any) => {
    if (selectedMessage) {
      dispatch(
        updatePayrollMessage({
          ...selectedMessage,
          message: e.target.value,
        }),
      );
    }
  };

  const onCellValueChanged = (e: any) => {
    if (selectedMessage) {
      let message;
      if (e.column.colId === 'description') {
        message = {
          ...selectedMessage,
          description: e.newValue.substr(0, 20),
        };
      } else if (e.column.colId === 'dateStart') {
        message = {
          ...selectedMessage,
          dateStart: e.newValue,
        };
      } else if (e.column.colId === 'dateEnd') {
        message = {
          ...selectedMessage,
          dateEnd: e.newValue || null,
        };
      }
      if (message) {
        if (message.payrollMessageId !== -1) {
          dispatch(updatePayrollMessage(message));
        } else {
          delete message.payrollMessageId;
          return dispatch(createPayrollMessage(message));
        }
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      title="Message On Check"
      headerSlot={(
        <div
          className="d-flex justify-content-end"
          style={{ width: '70%' }}
        >
          {isSaving ? 
          <>
            <span className="ml-3 mt-2">{'Auto Saving'}&nbsp;</span>
            <Spinner
              animation={'border'}
              className="ml-1 mt-2 mr-2"
              size="sm"
            />
          </> : null}
          <button
            className="btn btn-link dm-grid-action-title d-flex align-items-center"
            onClick={onAddMessage}
          >
            <span className="dm-grid-action-title">
              Add Message
            </span>
            <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </div>
      )}
    >
      {(closeModal) => {
        return (
          <>
            <form>
              <div>
                <div className="d-flex">
                  <div className="col-6">
                    <div className="dm-card-subtitle2 pt-2 mb-2">
                      Message on Employee Check
                    </div>
                    <div>
                      <TextareaGrp
                        {...fs.checkMessage}
                        defaultValue={messageLines}
                        errors={errors.checkMessage}
                        ref={register}
                        style={{
                          height: '300px',
                          fontFamily: 'monospace',
                        }}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="dm-card-subtitle2 pt-2 mb-2">
                      Input Individual Message
                    </div>
                    <div>
                      <TextareaGrp
                        {...fs.individualMessage}
                        errors={errors.individualMessage}
                        disabled={!selectedMessage.payrollMessageId}
                        ref={register}
                        style={{
                          height: '300px',
                          fontFamily: 'monospace',
                        }}
                        onBlur={onBlurSave}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 mb-3">
                Instructions: Select the "Add Message" button.
                Select an individual cell to further limit who will
                receive the message.
                <br />
                Questions? Call 248-244-3293
              </div>
              <div className="text-right mr-3">
                <CheckboxGrpInLine
                  {...fs.activeOnly}
                  ref={register}
                  labelWidth={125}
                  labelWidthUnit={'px'}
                  defaultChecked={activeOnly}
                  onClick={onActiveOnlyChange}
                />
              </div>
              <Row>
                <Col
                  xs={12}
                  className="table-wrapper-wrapper ag-theme-balham"
                >
                  <AgGridReact
                    gridOptions={gridOptions}
                    rowData={rowData}
                    modules={AllModules}
                    onGridReady={onGridReady}
                    onSelectionChanged={onSelectionChanged}
                    onCellValueChanged={onCellValueChanged}
                    onGridSizeChanged={onGridSizeChanged}
                  ></AgGridReact>
                </Col>
              </Row>
              <div className="d-flex mt-3">
                <button
                  className="orange-outline-button ml-auto mr-2"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  className="orange-button mr-2"
                  onClick={closeModal}
                >
                  Okay
                </button>
              </div>
            </form>
            {showLocModal && (
              <MessageSelectLocModal
                message={{ ...selectedMessage }}
                show={showLocModal}
                onHide={() => { return setShowLocModal(false); }}
                selectedItems={(items: MessageSelection[]) => { return setSelectedLocations(items); }
                }
              />
            )}
            {showDeptModal && (
              <MessageSelectDeptModal
                message={{ ...selectedMessage }}
                show={showDeptModal}
                onHide={() => { return setShowDeptModal(false); }}
                selectedItems={(items: MessageSelection[]) => { return setSelectedDepts(items); }
                }
              />
            )}
            {showSubDeptModal && (
              <MessageSelectSubDeptModal
                message={{ ...selectedMessage }}
                show={showSubDeptModal}
                onHide={() => { return setShowSubDeptModal(false); }}
                selectedItems={(items: MessageSelection[]) => { return setSelectedSubDepts(items); }
                }
              />
            )}
            {showSubDept2Modal && (
              <MessageSelectSubDept2Modal
                message={{ ...selectedMessage }}
                show={showSubDept2Modal}
                onHide={() => { return setShowSubDept2Modal(false); }}
                selectedItems={(items: MessageSelection[]) => { return setSelectedSubDepts2(items); }
                }
              />
            )}
            {showEmpModal && (
              <MessageSelectEmployeesModal
                message={{ ...selectedMessage }}
                show={showEmpModal}
                onHide={() => { return setShowEmpModal(false); }}
                selectedItems={(items: MessageSelection[]) => { return setSelectedEmps(items); }
                }
              />
            )}
          </>
        );
      }}
    </Modal>
  );
};
export default PayrollMessageModal;
