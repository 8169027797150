import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { convToDateString, currencyFormatter } from 'utilities/utilities';
import { ControlDatePickerGrp, InputGrp, SelectGrp } from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import CCDeductions from './CCDeductions';
import CCEarnings from './CCEarnings';
import CCTaxes from './CCTaxes';
import CheckCalculatorPrintModal from './CheckCalculatorPrint';
import VoidCheck from './VoidCheck';
import PdfViewer from 'core/components/shared/PDFViewer/PdfViewer';
import Icon from 'core/components/shared/Icon';
import {
  CheckPayroll,
  DeductionPayrollCheckResult,
  EarningPayrollCheckResult,
  Employee,
  PayrollCheckResult,
  PrintCheck,
  PrintCheckVerify,
  RecordPrepay,
} from 'core/models';
import {
  clearCalculateCheckPdf,
  loadCalculateCheckPrint,
  loadCalculateRecordPrepay,
  loadCalculateCheckReport,
  showAdjustmentModal,
  showVoidCheckWindow,
  storeActiveYear,
} from 'core/store/actions';

const fs: FieldInputSettings = {
  weekEnd: {
    name: 'weekEnd',
    label: 'WEEK ENDING',
    groupClass: 'gc10',
  },
  checkDate: {
    name: 'checkDate',
    label: 'CHECK DATE',
    groupClass: 'gc10',
  },
  payrollDate: {
    name: 'payrollDate',
    label: '',
    groupClass: 'gc10',
  },
  checkNo: {
    name: 'check.checkNo',
    label: '',
    groupClass: 'gc10',
  },
};

type PropTypes = {
  openPayrolls: CheckPayroll[];
  protectedEmpNo: string;
  selEmp: Employee;
  show: boolean;
  onHide: () => void;
};

const CheckCalculatorModal: React.FC<PropTypes> = ({
  openPayrolls,
  protectedEmpNo,
  show,
  onHide,
}) => {
  const dispatch = useAppDispatch();
  
  const showVoidModal = useAppSelector((state) => !!state.payroll.showVoidModal);
  const {
    pdf: checkPdf,
    pdfType,
    checkCalculation,
    checkCalculator,
    showAdjustmentModal: selectRecordPrepay,
  } = useAppSelector(({ selEmployeeDetails }) => selEmployeeDetails.checkCalculator);

  const {
    setValue,
    errors,
    control,
    register,
    getValues,
    trigger,
    handleSubmit,
  } = useForm<PrintCheck>();

  const [allWithholdings, setAllWithholdings] = useState<
  PayrollCheckResult[]
  >([]);
  const [earnings, setEarnings] = useState<EarningPayrollCheckResult[]>([]);
  const [deductions, setDeductions] = useState<DeductionPayrollCheckResult[]>(
    [],
  );
  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [showPrintCheckError, setShowPrintCheckError] = useState(false);
  const [dateRangeOpts, setDateRangeOpts] = useState<{
    id: number;
    description: string;
  }[]>([]);
  const [selectedPayroll, setSelectedPayroll] = useState<CheckPayroll>();
  const [printCheckRecord, setPrintCheckRecord] = useState<PrintCheck>();

  const canPrint =
    useAppSelector(
      (state) => { return state.client?.clientOptions?.options?.[87]?.optionValue; },
    ) === 'Yes'
      ? true
      : false;

  useEffect(() => {
    dispatch(clearCalculateCheckPdf());
  }, []);

  useEffect(() => {
    if (!openPayrolls?.length) return;
    
    const payrollOpts = openPayrolls.map((p) => {
      return {
        id: p.payrollHistoryId,
        description: convToDateString(new Date(p.weekEnd)) + ' - ' + convToDateString(new Date(p.checkDate)),
      };
    });
    setDateRangeOpts(payrollOpts);
  }, [openPayrolls]);

  useEffect(() => {
    const checkCalcTaxes = checkCalculation?.taxes ?? [];
    const checkCalcFica = checkCalculation?.fica?.filter(({ description }) =>
      !description.toLowerCase().includes('employer') && !description.toLowerCase().includes('general gross'));
    if (!checkCalcFica) return;
    
    setAllWithholdings([...checkCalcTaxes, ...checkCalcFica] ?? []);
    setEarnings(checkCalculation?.earnings || []);
    setDeductions(checkCalculation?.deductions || []);
    setValue('check', checkCalculation);
  }, [checkCalculation]);

  const closeModal = (e?: React.MouseEvent<SVGElement, MouseEvent>) => {
    e?.stopPropagation();
    onHide();
  };

  const onPrintReport = async () => {
    const result = await trigger();
    if (!(result && checkCalculation?.clientNo)) return; 
    
    const checkInfo = structuredClone(checkCalculation);
    const printCheck = getValues();
    printCheck.check = { ...checkInfo };
    dispatch(loadCalculateCheckReport({
      printCheck,
      protectedEmpNo,
    }));
    setShowPdfViewer(true);
  };

  const onPrintCheck = (data: PrintCheck) => {
    if (!checkCalculation?.clientNo) return; 

    if (checkCalculation?.adjustmentOnOpenPayroll) {
      setShowPrintCheckError(true);
      return;
    }
    
    const checkInfo = structuredClone(checkCalculation);
    const printCheck = data;
    printCheck.check = {
      ...checkInfo,
      checkNo: printCheck.check.checkNo,
    };
    printCheck.payrollWeekEnd = selectedPayroll?.weekEnd as Date;
    printCheck.payrollCheckDate = selectedPayroll?.checkDate as Date;

    if (canPrint) {
      dispatch(loadCalculateCheckPrint({
        printCheck,
        protectedEmpNo,
      }));
    } else {
      const printCheckVerify = structuredClone(printCheck) as PrintCheckVerify;
      printCheckVerify.success = true;
      
      dispatch(
        loadCalculateRecordPrepay({
          recordPrepay: printCheck as RecordPrepay,
          protectedEmpNo,
          printCheckVerify: printCheckVerify,
        }),
      );
    }
    setPrintCheckRecord(printCheck);
  };

  const onPayrollDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const openPayroll = openPayrolls?.find(
      (p) => { return p.payrollHistoryId === parseInt(e.target.value); },
    );
    setSelectedPayroll(openPayroll);
  };

  const handleCheckPrintClose = () => {
    dispatch(clearCalculateCheckPdf());
  };

  return (
    <div onClick={(e) => { e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        size="xl"
        animation={false}
      >
        <div className="modal-header">
          <div className="dm-card-title">Check Calculator</div>
          <div
            className="col-8 text-right mt-auto"
            onClick={onPrintReport}
            role="button"
          >
            <span className="dm-grid-action-title">Print Report</span>
            <Icon
              name="print"
              className="fa-print"
            />
          </div>
          <button
            type="button"
            className="modal-close-btn m-0"
            onClick={() => { closeModal(); }}
          >
            <Icon name="times" />
          </button>
        </div>
        <Modal.Body>
          { checkCalculation?.adjustmentOnOpenPayroll && (
            <div 
              className="dm-subtitle3 text-center" 
              style={{ color: 'red' }}
            >
              ** RESULTS MAY NOT BE ACCURATE. THIS EMPLOYEE HAS AN ADJUSTMENT ON AN OPEN PAYROLL **
            </div>
          )}
          <div className="dm-subtitle2">
            Employee:{' '}
            {checkCalculator && checkCalculator.firstName + ' ' + checkCalculator.lastName}
          </div>
          <div className="d-flex">
            <div className="d-flex flex-column p-2">
              <div
                style={{
                  minHeight: '150px',
                  height: '150px',
                }}
              >
                <div className="dm-subtitle3">Earnings</div>
                {<CCEarnings items={earnings || null} />}
              </div>
              <div>
                <div className="dm-subtitle3 pt-5">Deductions</div>
                {<CCDeductions items={deductions || null} />}
              </div>
            </div>
            <div
              className="d-flex align-items-stretch p-2"
              style={{
                minWidth: '240px',
                width: '240px',
              }}
            >
              <div>
                <div className="dm-subtitle3">Tax Withholdings</div>
                {<CCTaxes items={allWithholdings || null} />}
              </div>
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-md-3 col-5">
              <div className="dm-subtitle2">Summary</div>
              <div className="border-left border-bottom border-right">
                <table className="table table-wrapper-wrapper table-hover m-0">
                  <tbody>
                    <tr>
                      <td>Gross</td>
                      <td className="text-right">
                        {currencyFormatter(checkCalculation?.grossPayCheck ?? 0)}
                      </td>
                    </tr>
                    <tr>
                      <td>Deduction</td>
                      <td className="text-right">
                        {checkCalculation?.deductions
                          ?.length &&
                          currencyFormatter(
                            checkCalculation.deductions
                              .map((item) => item.amount || 0)
                              .reduce((prev, next) => +prev + +next) || 0, 0)}
                      </td>
                    </tr>
                    <tr>
                      <td>Withholdings</td>
                      <td className="text-right">
                        {allWithholdings?.length > 0 &&
                          currencyFormatter(
                            allWithholdings
                              .map(
                                (item) => {
                                  return item.withholding ||
                                    0;
                                },
                              )
                              ?.reduce(
                                (prev, next) => {
                                  return +prev +
                                    +next;
                                },
                              ) || 0,
                          )}
                      </td>
                    </tr>
                    <tr className="font-weight-bold">
                      <td>Net Pay</td>
                      <td className="text-right">
                        {currencyFormatter(checkCalculation?.netPay ?? 0)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="bg-info col-sm-7">
              <form onSubmit={handleSubmit(onPrintCheck)}>
                <div className="row no-gutters align-items-end mb-3">
                  <div className="col-3 text-right form-group">
                    Dates for Check
                  </div>
                  <div className="col">
                    <div className="row no-gutters ml-2">
                      <div className="col">
                        <ControlDatePickerGrp
                          {...fs.weekEnd}
                          errors={errors.weekEnd}
                          control={control}
                          required={true}
                          rules={{
                            required: {
                              value: true,
                              message:
                                'Week End Date is required',
                            },
                          }}
                        />
                      </div>
                      <div className="col">
                        <ControlDatePickerGrp
                          {...fs.checkDate}
                          errors={errors.checkDate}
                          control={control}
                          required={true}
                          onChange={(e: Date | null) => {
                            if (!e) return;
                            dispatch(storeActiveYear(new Date(e).getFullYear()));
                          }}
                          rules={{
                            required: {
                              value: true,
                              message:
                                'Check Date is required',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters mb-3 align-items-end">
                  <div className="col-sm-3 form-group text-right">
                    Payroll Dates
                  </div>
                  <div className="col-auto ml-2">
                    <SelectGrp
                      {...fs.payrollDate}
                      placeholder={
                        'Week End - Check Date'
                      }
                      errors={errors.payrollDate}
                      required={true}
                      ref={register({
                        required: {
                          value: canPrint,
                          message:
                            'Week End is required',
                        },
                      })}
                      options={dateRangeOpts}
                      onChange={onPayrollDateChange}
                      label={'Week End - Check Date'}
                    />
                  </div>
                </div>
                <div className="row no-gutters align-items-middle">
                  <div className="col-sm-3 text-right">
                    Check Number
                  </div>
                  <div className="col-auto ml-2">
                    <InputGrp
                      {...fs.checkNo}
                      errors={errors.check?.checkNo}
                      ref={register({
                        required: {
                          value: true,
                          message:
                            'Check Number is required',
                        },
                      })}
                      readOnly={Boolean(
                        canPrint ?? 'false',
                      )}
                    />
                  </div>
                  <div className="col-auto">
                    <button className="orange-button-sm btn btn-primary btn-sm">
                      {canPrint ? 'Print Check' : 'Record Prepay'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {checkPdf && pdfType === 'check' && selectedPayroll ? (
        <Modal
          show={true}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title className="dm-card-title">
              Print Check
            </Modal.Title>
            <button
              className="modal-close-btn"
              type="button"
              onClick={() => {
                handleCheckPrintClose();
              }}
            >
              <Icon name="times" />
            </button>
          </Modal.Header>
          <Modal.Body style={{ height: 'calc(100vh - 150px' }}>
            <CheckCalculatorPrintModal
              weekEnd={getValues('weekEnd')}
              checkDate={getValues('checkDate')}
              payrollWeekEnd={selectedPayroll?.weekEnd as Date}
              payrollCheckDate={
                selectedPayroll?.checkDate as Date
              }
              printCheck = {printCheckRecord as RecordPrepay} 
            />
          </Modal.Body>
        </Modal>
      ) : null}
      {showPdfViewer && checkPdf ? (
        <Modal
          show={showPdfViewer}
          onHide={() => { return setShowPdfViewer(false); }}
        >
          <Modal.Header>
            <Modal.Title className="dm-card-title">
              Preview Check Report
            </Modal.Title>
            <button
              type="button"
              className="modal-close-btn"
              onClick={() => {
                setShowPdfViewer(false);
              }}
            >
              <Icon name="times" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <PdfViewer
              pdfData={checkPdf}
              reportName={'Payroll Check Report'}
            />
          </Modal.Body>
        </Modal>
      ) : null}
      {selectRecordPrepay ? (
        <Modal
          show={true}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title className="dm-card-title">
              Adjustment Record Added
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Adjustment updated.</p>
            <p>Do you want to void a check?</p>
            <button
              className="orange-outline-button btn btn-primary btn-sm mr-3"
              type="button"
              onClick={() => {
                dispatch(showAdjustmentModal(false));
                dispatch(showVoidCheckWindow(true));
              }}
            >
              Yes
            </button>
            <button
              className="orange-button btn btn-primary btn-sm"
              type="button"
              onClick={() => { return dispatch(showAdjustmentModal(false)); }}
            >
              No
            </button>
          </Modal.Body>
        </Modal>
      ) : null}
      {showVoidModal && selectedPayroll ? (
        <Modal
          show={true}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title className="dm-card-title">
              Voiding Check
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: 'calc(100vh - 150px' }}>
            <VoidCheck
              payrollHistoryId={
                selectedPayroll?.payrollHistoryId as number
              }
              weekEnd={selectedPayroll?.weekEnd as Date}
              checkDate={selectedPayroll?.checkDate as Date}
            />
          </Modal.Body>
        </Modal>
      ) : null}
      {showPrintCheckError && (
        <Modal
          show={showPrintCheckError}
          size="sm"
          onHide={() => { setShowPrintCheckError(false); }}
        >
          <Modal.Header>
            <Modal.Title className="dm-card-title">Print Check/Record Prepay</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Cannot print check or record prepay for this employee.</p>
            <p>Employee already has an open adjustment in the same payroll year.</p>
            <div className="text-center mt-auto">
              <button 
                type="button"
                className="orange-button-sm btn btn-primary btn-sm mr-3"
                onClick={() => { setShowPrintCheckError(false); }}
              >
                Ok
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default CheckCalculatorModal;
