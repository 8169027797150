import React, { useState } from 'react';

export type LeafNode = {
  id: number;
  description: string;
  checked: boolean;
};
export type RootNode = {
  id: string;
  checked: boolean;
  leafNodes: LeafNode[]
};
type Props<T extends RootNode> = {
  options: T[]; // for now, T should be an array type
  onSelect: (selectedList: T[]) => void;
};

function NestedCheckboxList<T extends RootNode>({ options, onSelect }: Props<T>) {
  const [nodes, setNodes] = useState<T[]>(options);
  
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string | number,
    parentId?: string | number,
  ) => {
    const currentState = structuredClone(nodes);
    
    if (!parentId) {
      const rootNodeIndex = currentState.findIndex((node) => node.id === id);
      if (rootNodeIndex < 0) return console.error('could not find parent in state');
      currentState[rootNodeIndex].checked = e.target.checked;
      const newLeafNodes = currentState[rootNodeIndex].leafNodes?.map((leafNode) => ({
        ...leafNode,
        checked: e.target.checked,
      }));
      
      const newRootNode: T = { ...currentState[rootNodeIndex], leafNodes: newLeafNodes };
      currentState.splice(rootNodeIndex, 1, newRootNode);

      setNodes(currentState);
      onSelect(currentState);

      return;
    }
    
    const rootNodeIndex = currentState.findIndex((node) => node.id === parentId);
    const leafIndex = currentState[rootNodeIndex].leafNodes.findIndex((leafNode) => leafNode.id === id);
    currentState[rootNodeIndex].leafNodes[leafIndex].checked = e.target.checked;
    
    const allChecked = currentState[rootNodeIndex].leafNodes.every((node) => node.checked);
    currentState[rootNodeIndex].checked = allChecked;
    
    setNodes(currentState);
    onSelect(currentState);
  };
  
  const renderLeafNode = (leafNode: LeafNode, parentId: string | number) => (
    <li key={String(leafNode.id)}>
      <input
        type="checkbox"
        name={String(leafNode.id)}
        checked={leafNode.checked}
        onChange={(e) => handleInputChange(e, leafNode.id, parentId)}
      />
      <label htmlFor={String(leafNode.id)}>{leafNode.description}</label>
    </li>
  );
  
  const renderParentNode = (parentNode: T) => {
    const { id, leafNodes } = parentNode;

    return (
      <li key={id}>
        <input
          type="checkbox"
          name={id}
          checked={leafNodes.every(node => node.checked) || parentNode.checked}
          onChange={(e) => handleInputChange(e, id, undefined)}
        />
        <label htmlFor={id}>{id}</label>
        <ul>{leafNodes.map(subNode => renderLeafNode(subNode, id))}</ul>
      </li>
    );
  };
  
  return (
    <ul className="nested-checkbox-list">
      {nodes?.map(renderParentNode)}
    </ul>
  );
}

export default NestedCheckboxList;