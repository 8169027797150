import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, startWith, switchMap } from 'rxjs/operators';
import {
  EmailTemplate,
  Employee,
  OnboardDoc,
  OnboardingUpdate,
  PageConfig,
  PageConfigUpdate,
  HttpResponse,
  CreateOnboardEmployee,
  ResendOnboardingEmail,
  JazzHRReturnData,
  JazzHRIntegrationRequest,
  JazzHRApplicantDetail,
  OnboardingSteps,
} from '../../models';
import { OnboardService } from '../../services';
import {
  addUpdateJazzHrIntegration,
  createOnboardEmployee,
  deleteOnboardEmailTemplate,
  handleError,
  loadNextEmployeeNumber,
  loadOnboardPayrollUsers,
  loadOnboardDocs,
  loadOnboardEmailTemplates,
  loadOnboardPageConfigs,
  loadJazzHrIntegration,
  storeNextEmployeeNumber,
  storeOnboardPayrollUsers,
  storeOnboardDocs,
  storeOnboardEmailTemplates,
  storeOnboardPageConfigs,
  storeJazzHrIntegration,
  updateOnboardPageConfigs,
  createOnboardEmployeeSuccess,
  setOnboardEmployeeStatus,
  upsertOnboardEmailTemplates,
  updateOnboardUpdates,
  storeOnboardUpdates,
  handleSuccess,
  loadEmployees,
  loadEmployee,
  handlePending,
  loadOnboardingStepsStatus,
  storeOnboardingStepsStatus,
  createDefaultAccrualRecords,
} from '../actions';
import { resendApplicantOnboardingEmail } from '../slices/applicantTracking.slice';
import { convToDateString } from 'utilities/utilities';

interface Actions {
  type: string;
  payload: any;
}

const getNextEmployeeNumber$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadNextEmployeeNumber.type),
    switchMap(() => {
      return from(OnboardService.getNextEmployeeNumber()).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeNextEmployeeNumber(res.empNo); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getOnboardPayrollUsers$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadOnboardPayrollUsers.type),
    switchMap(() => {
      return from(OnboardService.getOnboardPayrollUsers()).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeOnboardPayrollUsers(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createOnboardEmployee$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createOnboardEmployee.type),
    switchMap((action: { payload: CreateOnboardEmployee; }) => {
      return from(OnboardService.postOnboardEmployee(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<Employee>) => {
          return [
            createDefaultAccrualRecords({ protectedEmpNo: res.value.protectedEmpNo, hireDate: convToDateString(res.value?.hireDate ?? new Date()) }),
            //Since we do not send the full employee object we need to load these or else it will crash if they go to edit it right away.
            //TODO: Figure out a way to not have to reload all employees.
            loadEmployees(),
            loadEmployee(res?.value?.protectedEmpNo),
            createOnboardEmployeeSuccess(),
            handlePending(null),
          ];
        }),
        // map(() => createOnboardEmployeeSuccess()),
        catchError((err: HttpResponse<any>) => {
          return [
            setOnboardEmployeeStatus({ error: err }),
            //This gets handled by an error modal that shows the error message.
            //handleError(err),
          ];
        }),
        /* this will tell the observable to start with this when streaming these actions RIGHT on subscription
        (so it'll emit right before we start the request, which is what we want.) */
        startWith(handlePending(`Creating onboard employee ${action.payload.empNo}...`)),
      );
    },
    ),
  );
};

const loadOnboardDocs$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadOnboardDocs.type),
    switchMap(() => {
      return from(OnboardService.getOnboardDocuments()).pipe(
        map((res: any) => { return res.data; }),
        map((res: OnboardDoc[]) => { return storeOnboardDocs(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadJazzHrIntegration$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadJazzHrIntegration.type),
    switchMap(() => {
      return from(OnboardService.getJazzHRApplicants()).pipe(
        map((res: any) => { return res.data; }),
        map((res: JazzHRReturnData) => { return storeJazzHrIntegration(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const addUpdateJazzHrIntegration$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(addUpdateJazzHrIntegration.type),
    switchMap((action: { payload: JazzHRIntegrationRequest; }) => {
      return from(OnboardService.postJazzHRIntegration(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<JazzHRApplicantDetail>) => {
          return [
            loadJazzHrIntegration(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
          ];
        }),
      );
    },
    ),
  );
};

const loadOnboardPageConfigs$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadOnboardPageConfigs.type),
    switchMap(() => {
      return from(OnboardService.getOnboardPageConfigs()).pipe(
        map((res: any) => { return res.data; }),
        map((res: PageConfig[]) => { return storeOnboardPageConfigs(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateOnboardPageConfigs$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateOnboardPageConfigs.type),
    switchMap((action: { payload: PageConfig[]; }) => {
      return from(OnboardService.putOnboardPageConfigs(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: PageConfigUpdate) => {
          return [
            storeOnboardPageConfigs(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadOnboardEmailTemplates$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadOnboardEmailTemplates.type),
    switchMap((action: any) => {
      return from(OnboardService.getOnboardEmailTemplates()).pipe(
        map((res: any) => { return res.data; }),
        map((res: EmailTemplate[]) => { return storeOnboardEmailTemplates(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const upsertOnboardEmailTemplates$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(upsertOnboardEmailTemplates.type),
    switchMap((action: { payload: EmailTemplate; }) => {
      return from(OnboardService.postOnboardEmailTemplates(action.payload)).pipe(
        map(() => { return loadOnboardEmailTemplates(); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteOnboardEmailTemplate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteOnboardEmailTemplate.type),
    switchMap((action: { payload: number; }) => {
      return from(
        OnboardService.deleteOnboardEmailTemplate(action.payload),
      ).pipe(
        map(() => { return loadOnboardEmailTemplates(); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateOnboardUpdates$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateOnboardUpdates.type),
    switchMap((action: { payload: OnboardingUpdate[]; }) => {
      return from(OnboardService.postOnboardUpdates(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: OnboardingUpdate[]) => { return storeOnboardUpdates(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const onboardResendEmail$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(resendApplicantOnboardingEmail.type),
    switchMap((action: { payload: ResendOnboardingEmail; }) => {
      return from(OnboardService.postOnboardResend(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => {
          return  handleSuccess(res.messages);
        }),     
        catchError((err: HttpResponse<any>) => {
          return [           
            handleError(err),
          ];
        }),
      );
    },
    ),
  );
};

const loadOnboardingStepsStatus$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadOnboardingStepsStatus.type),
    switchMap((action: { payload: string }) => {
      return from(OnboardService.getOnboardingStepsStatus(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: OnboardingSteps) => { return storeOnboardingStepsStatus(res); }),
        catchError((err: HttpResponse<any>) => { return [storeOnboardingStepsStatus(null)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  getNextEmployeeNumber$,
  getOnboardPayrollUsers$,
  createOnboardEmployee$,
  loadOnboardDocs$,
  loadJazzHrIntegration$,
  addUpdateJazzHrIntegration$,
  loadOnboardPageConfigs$,
  updateOnboardPageConfigs$,
  loadOnboardEmailTemplates$,
  upsertOnboardEmailTemplates$,
  deleteOnboardEmailTemplate$,
  updateOnboardUpdates$,
  onboardResendEmail$,
  loadOnboardingStepsStatus$,
];
