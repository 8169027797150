import Icon from 'core/components/shared/Icon';
import React from 'react';
import { Modal } from 'react-bootstrap';

type PropTypes = {
  errorMsg?: any;
  show: boolean;
  onHide: any;
};
const OnboardErrorModal: React.FC<PropTypes> = ({ errorMsg, show, onHide }) => {
  const closeModal = (e: any) => {
    e.stopPropagation();
    onHide();
  };

  const message = (typeof errorMsg === 'string' || errorMsg instanceof String) ? errorMsg : (errorMsg?.messages[0] || "");
  
  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        size="sm"
        animation={false}
      >
        <div className="modal-header">
          <div className="dm-card-title">Error</div>
          <button
            className="modal-close-btn" 
            onClick={closeModal}
          >
            <Icon name="times"/>
          </button>
        </div>
        <Modal.Body>
          <p>ERROR: {message}</p>
          <div className="row mt-4">
            <div className="col-12 text-right">
              <button
                type="button"
                className="orange-outline-button"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default OnboardErrorModal;
