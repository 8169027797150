import React from 'react';
import { InputGrp } from 'core/components/form-controls';
import { PayrollControlTotal } from 'core/models';
import { useForm } from 'react-hook-form';
import { ControlTotalEditProps, Styles } from './types';
import { useAppDispatch } from 'utilities/hooks';
import { updatePayrollControlTotal } from 'core/store/actions';

const styles: Styles = {
  input: {
    width: '50%',
    minWidth: '100px',
  },
};

const ControlTotalEdit = ({ controlTotal, mobileView, description, updateDescription, exit }: ControlTotalEditProps) => {
  const dispatch = useAppDispatch();
  
  const { register, control, handleSubmit } = useForm<PayrollControlTotal>({
    defaultValues: { description: description },
  });
  
  const onSave = (data: Pick<PayrollControlTotal, 'description'>) => {
    const updatedControlTotal: PayrollControlTotal = { ...controlTotal,
      description: data.description,
    };
    
    dispatch(updatePayrollControlTotal({
      payrollHistoryId: controlTotal.payrollHistoryId,
      controlTotalId: controlTotal.controlTotalId,
      data: updatedControlTotal,
    }));
    updateDescription(data.description);
    exit();
  };
  
  return (
    <form onSubmit={handleSubmit(onSave)}>
      <div className={`payroll-card-body ${mobileView ? 'justify-content-around' : ''}`}>
        <div className="d-flex flex-column">
          <InputGrp
            inputClass="m-0"
            name="description"
            label="Description"
            labelWidth={25}
            inputStyles={styles.input}
            ref={register}
            control={control}
          />
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-sm orange-outline-button-sm"
              onClick={exit}
            >
              Cancel
            </button>
            <button
              className="btn btn-sm btn-primary orange-button-sm"
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ControlTotalEdit;