import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  ClientMasterDtoTaxEntitiesClientTaxEntity,
  EmployeeTaxInfo,
  HttpResponse,
  W4Info,
} from '../../models';
import { TaxRequest, TaxService } from '../../services/tax.service';
import { handleError, triggerEmFieldValidation } from '../actions';
import {
  loadCmTaxEntities,
  loadTaxes,
  loadW4Info,
  storeCmTaxEntities,
  storeTaxes,
  storeTaxMessages,
  storeW4Info,
  updateTaxes,
} from '../actions/tax.action';

interface Actions<Type> {
  type: string;
  payload: Type;
}

const getTaxes$ = (action$: Observable<Actions<TaxRequest>>) => {
  return action$.pipe(
    ofType(loadTaxes.type),
    switchMap((action: { payload: TaxRequest; }) => {
      return from(TaxService.getTaxes(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: EmployeeTaxInfo) => { return storeTaxes(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const putTaxes$ = (action$: Observable<Actions<TaxRequest>>) => {
  return action$.pipe(
    ofType(updateTaxes.type),
    switchMap((action: { payload: TaxRequest; }) => {
      return from(TaxService.putTaxes(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap(
          (res: HttpResponse<EmployeeTaxInfo>) => {
            return [
              storeTaxes(res.value),
              storeTaxMessages({
                messages: res.messages,
                isSuccess: true,
              }),
              triggerEmFieldValidation({
                section: 'taxes',
                actionType: updateTaxes.type,
                callerPayload: res.value,
              }),
            ];
          },
        ),
        catchError((err: string[]) => {
          return [
            handleError(err),
            storeTaxMessages({ messages: err, isSuccess: false }),
          ];
        }),
      );
    },
    ),
  );
};

const getW4Info$ = (action$: Observable<Actions<TaxRequest>>) => {
  return action$.pipe(
    ofType(loadW4Info.type),
    switchMap((action: { payload: TaxRequest; }) => {
      return from(TaxService.getW4Info(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: W4Info[]) => { return storeW4Info(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getTaxEntities$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(loadCmTaxEntities.type),
    switchMap(() => {
      return from(TaxService.getTaxEntities()).pipe(
        map((res: any) => { return res.data; }),
        map((res: ClientMasterDtoTaxEntitiesClientTaxEntity[]) => { return storeCmTaxEntities(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [getTaxes$, putTaxes$, getW4Info$, getTaxEntities$];
