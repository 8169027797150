import { useSelector } from 'react-redux';
import { Dropdown } from 'core/models/Dropdown.model';
import {
  getEarningsCodes,
} from 'core/store/selectors';
import { formatWithCommas } from 'utilities/utilities';
import { TimeCardMainTotals } from 'core/models';

interface Naming {
  title: string;
  code: string;
  total: string;
  description: string;
}

type Props = {
  details: TimeCardMainTotals;
  onHide: () => void;
};

export const TimeCardTotal = (props: Props) => {
  const earningsCodes = useSelector(getEarningsCodes);

  let codes: Dropdown[] = earningsCodes;
  let naming: Naming = { title: 'Hours',
    code: 'Earnings Code',
    total: 'Hours Total',
    description: 'an hour' };
  let padStart = 1;

  return (
    <div>
        <div className="row mb-2">
          <h3 className="col dm-card-title">
            Time Card Total Details - {naming.title}
          </h3>
          <div className="col-sm-12">
            <hr className="hr-2" />
          </div>
        </div>

        <div className="row">
            <table className="mx-3 table-wrapper-wrapper table table-striped table-sm table-hover table-bordered">
              <thead className="thead-light">
                <tr>
                  <th>{naming.code}</th>
                  <th className="text-right">{naming.total}</th>
                </tr>
              </thead>
              <tbody>
                {props.details.details.map((detail, index) => {
                    return (
                        <tr 
                          key={index}
                        >
                          <td>
                            {detail.earningsCode.padStart(padStart, '0')} -{' '}
                            {codes.find((a) => a.id === detail.earningsCode)?.description}
                          </td>
                          <td className="text-right">
                            {formatWithCommas(detail.hours)}
                          </td>
                        </tr>
                      );
                })}
              </tbody>
            </table>
            </div>
            <div className="row justify-content-end">
              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-primary orange-button"
                  onClick={() => {return props.onHide();}}
                >
                  Close
                </button>
            </div>
        </div>
    </div>
  );
};
