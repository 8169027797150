import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  Payroll,
  PayrollMessage,
  PayrollDateline,
  TransmittalParams,
  TransmittalAutoFill,
  TransmittalEmployee,
  TransmittalCheck,
  TransmittalEmployeeParams,
  TransmittalEmployeeCheckParams,
  PayrollControlTotal,
  PayrollHistoryDeductionStatus,
  PayrollOptions,
  PayrollControllTotalUpdateRequest,
  PayrollVoidSearchParams,
  PayrollAdjustmentTransaction,
  PayrollAdjustmentVoidTransaction,
  PayrollPreviewPdf,
  PayrollPreview,
  PayrollValidateRequest,
  PayrollTimeOffRequests,
  ApplyTimeOffRequest,
  clientRecurringEarning,
  RecoverPayrollRequest,
  CanRecoverPayrollResponse,
  CreateControlTotalBookRequest,
  AdjustmentStateInfo,
  TransmittalFuturePayRates,
  MoveTimeOffRequest,
  TransmittalSortOrder,
  AutoCalcParams,
  PayrollPreviewResult,
  WireOnlyAgreement,
  UpdateCheckNoRequest,
  WeekBeginDate,
  UpdateWeekBeginResult,
} from '../../models';

import {
  PayrollControlTotalDetail,
  PayrollControlTotalRequest,
} from '../../models/PayrollControlTotalDetail';
import DateObject from 'react-date-object';
import { handleError, handlePending, handleSuccess } from './app.action';
import { PayrollService } from 'core/services';

export const loadPayroll = createAction<{
  beginDate: string;
  endDate: string;
  byCheckDate?: boolean;
  protectedClientNo?: string;
}>('[payroll] LOAD_PAYROLL');

export const loadPayrollForTimeOffRequestModal = createAction<{
  beginDate: string;
  endDate: string;
  byCheckDate?: boolean;
  protectedClientNo?: string;
}>('[payroll] LOAD_PAYROLL_FOR_TIME_OFF_REQUEST_MODAL');

export const storePayrolls = createAction<Payroll[]>(
  '[payroll] STORE_PAYROLLS',
);
export const storePayrollsForTimeOffRequestModal = createAction<Payroll[]>('[payroll] STORE_PAYROLLS_FOR_TIME_OFF_REQUEST_MODAL');
export const clearPayrolls = createAction(
  '[payroll] CLEAR_PAYROLLS',
);
export const storePayroll = createAction<Payroll>('[payroll] STORE_PAYROLL');
export const createPayroll = createAction<PayrollDateline>(
  '[payroll] CREATE_PAYROLL',
);
export const deletePayroll = createAction<number>('[payroll] DELETE_PAYROLL');
export const deletePayrollFromState = createAction<number>(
  '[payroll] DELETE_PAYROLL_FROM_STATE',
);

export const loadClientRecurringEarnings = createAction(
  '[payroll] LOAD_CLIENT_RECURRING_EARNINGS',
);
export const storeClientRecurringEarnings = createAction<clientRecurringEarning[]>(
  '[payroll] STORE_CLIENT_RECURRING_EARNINGS',
);

export type PayrollUpdateRequest = {
  payrollHistoryId: number,
  data: Payroll,
  removeRecurringEarnings: boolean,
  addRecurringEarnings: boolean
};

export const putPayrollDateline = createAction<PayrollUpdateRequest>('[payroll] UPDATE_PAYROLL_DATELINE');
export const storePayrollDateline = createAction<Payroll>('[payroll] STORE_PAYROLL_DATELINE');

export const loadPayrollOptions = createAction<number>(
  '[payroll] LOAD_PAYROLL_OPTIONS',
);
export const storePayrollOptions = createAction<PayrollOptions>(
  '[payroll] STORE_PAYROLL_OPTIONS',
);
export const updatePayrollOptions = createAction<{ data: PayrollOptions, payrollHistoryId: number }>(
  '[payroll] UPDATE_PAYROLL_OPTIONS',
);
export const loadWireOnlyAgreement = createAction<string>(
  '[payroll] LOAD_WIRE_ONLY_AGREEMENT',
);
export const storeWireOnlyAgreement = createAction<WireOnlyAgreement>(
  '[payroll] STORE_WIRE_ONLY_AGREEMENT',
);
export const clearWireOnlyAgreement = createAction(
  '[payroll] CLEAR_WIRE_ONLY_AGREEMENT',
);
export const toggleWireOnlyAgreementPreviewSuccess = createAction<boolean>(
  '[payroll] TOGGLE_WIRE_ONLY_AGREEMENT_PREVIEW_SUCCESS',
);
export const toggleWireOnlyAgreementSubmissionSuccess = createAction<boolean>(
  '[payroll] TOGGLE_WIRE_ONLY_AGREEMENT_SUBMISSION_SUCCESS',
);
export const updateWireOnlyAgreement = createAction<WireOnlyAgreement>(
  '[payroll] UPDATE_WIRE_ONLY_AGREEMENT',
);
export const deleteWireOnlyAgreement = createAction<number>(
  '[payroll] DELETE_WIRE_ONLY_AGREEMENT',
);
export const submitWireOnlyAgreement = createAction<WireOnlyAgreement>(
  '[payroll] SUBMIT_WIRE_ONLY_AGREEMENT',
);
export const downloadWireOnlyAgreementReport = createAction<WireOnlyAgreement>(
  '[payroll] DOWNLOAD_WIRE_ONLY_AGREEMENT_REPORT',
);
export const storeWireOnlyAgreementReport = createAction<string>(
  '[payroll] STORE_WIRE_ONLY_AGREEMENT_REPORT',
);
export const clearWireOnlyAgreementReport = createAction(
  '[payroll] CLEAR_WIRE_ONLY_AGREEMENT_REPORT',
);
export const updatePayrollPreview = createAction<{ data: PayrollPreview, payrollHistoryId: number }>(
  '[payroll] UPDATE_PAYROLL_PREVIEW',
);
export const updateWeekBeginningDates = createAction<{ data: WeekBeginDate[], payrollHistoryId: number }>(
  '[payroll] UPDATE_PAYROLL_WEEK_BEGINNING_DATES',
);
export const storeWeekBeginningDates = createAction<UpdateWeekBeginResult>(
  '[payroll] STORE_PAYROLL_WEEK_BEGINNING_DATES',
);
export const postPayrollCheckRegister = createAction<{ payrollHistoryId: number, isPayrollSubmission: boolean }>(
  '[payroll] LOAD_PAYROLL_CHECK_REGISTER',
);

export const storePayrollCheckRegister = createAction<any>(
  '[payroll] STORE_PAYROLL_CHECK_REGISTER',
);

export const clearPayrollCheckRegister = createAction(
  '[payroll] CLEAR_PAYROLL_CHECK_REGISTER',
);

export const storePayrollReportDates = createAction<Payroll[]>(
  '[payroll] STORE_PAYROLL_REPORT_DATES',
);

export const storeSelectedDates = createAction<DateObject[]>(
  '[payroll] STORE_SELECTED_DATES',
);

export const loadPayrollControlTotals = createAction<{
  payrollHistoryId: number;
}>('[payroll] LOAD_PAYROLL_CONTROLTOTALS');

export const loadPayrollControlTotal = createAction<{
  payrollHistoryId: number;
  controlTotalId: number;
  preventPayrollsUpdate?: boolean;
}>('[payroll] LOAD_PAYROLL_CONTROLTOTAL');

export const storePayrollControlTotals = createAction<PayrollControlTotal[]>(
  '[payroll] STORE_PAYROLL_CONTROLTOTALS',
);

export const storePayrollControlTotal = createAction<{ controlTotals: PayrollControlTotal[], preventPayrollsUpdate?: boolean, payrollHistoryId: number }>(
  '[payroll] STORE_PAYROLL_CONTROLTOTAL',
);

export const updateControlTotalOnPayrollInReduxStore = createAction<PayrollControlTotal>(
  '[payroll] UPDATE_CONTROLTOTAL_ON_PAYROLL_IN_REDUX_STORE',
);

export const updatePayrollControlTotal =
  createAction<PayrollControllTotalUpdateRequest>(
    '[payroll] UPDATE_PAYROLL_CONTROLTOTAL',
  );

export const postTransmittalAutofill = createAction<{
  params: TransmittalParams;
  data: TransmittalAutoFill;
}>('[payroll] POST_TRANSMITTAL_AUTOFILL');
export const storeTransmittalAutofillMessages = createAction<string[]>(
  '[payroll] STORE_TRANSMITTAL_AUTOFILL_MESSAGES',
);
export const clearTransmittalAutofillMessages = createAction(
  '[payroll] CLEAR_TRANSMITTAL_AUTOFILL_MESSAGES',
);
export const toggleSavingFromModal = createAction<boolean>('[payroll] AUTOFILL_COMPLETE');

export const loadPayrollMessages = createAction(
  '[payroll] LOAD_PAYROLL_MESSAGES',
);
export const storePayrollMessages = createAction<PayrollMessage[]>(
  '[payroll] STORE_PAYROLL_MESSAGES',
);
export const storePayrollMessage = createAction<PayrollMessage>(
  '[payroll] STORE_PAYROLL_MESSAGE',
);
export const deletePayrollMessage = createAction<PayrollMessage>(
  '[payroll] DELETE_PAYROLL_MESSAGE',
);
export const createPayrollMessage = createAction<PayrollMessage>(
  '[payroll] CREATE_PAYROLL_MESSAGE',
);
export const updatePayrollMessage = createAction<PayrollMessage>(
  '[payroll] UPDATE_PAYROLL_MESSAGE',
);
export const updatePayrollMessageOrder = createAction<PayrollMessage[]>(
  '[payroll] UPDATE_PAYROLL_MESSAGE_ORDER',
);

export const printCheck = createAction<{
  weekEnding: string;
  checkDate: string;
}>('[payroll] PRINT_CHECK');
export const storePrintedCheck = createAction<any>(
  '[payroll] STORE_PRINT_CHECK',
);
export const clearPrintedCheck = createAction('[payroll] CLEAR_PRINT_CHECK');

export const loadTransmittalEmployee = createAction<{ params: TransmittalEmployeeParams, orderBy: TransmittalSortOrder }>(
  '[payroll] LOAD_PAYROLL_EMPLOYEE',
);
export const loadTransmittalEmployees = createAction<{ params: TransmittalParams, orderBy: TransmittalSortOrder }>(
  '[payroll] LOAD_PAYROLL_EMPLOYEES',
);
export const storeTransmittalEmployee = createAction<{ employee: TransmittalEmployee, orderBy: TransmittalSortOrder }>(
  '[payroll] STORE_TRANSMITTAL_EMPLOYEE',
);
export const clearTransmittalEmployee = createAction('[payroll] CLEAR_TRANSMITTAL_EMPLOYEE');
export const storeTransmittalEmployees = createAction < { employees: TransmittalEmployee[], orderBy: TransmittalSortOrder }>(
  '[payroll] STORE_TRANSMITTAL_EMPLOYEES',
);
export const clearTransmittalEmployees = createAction(
  '[payroll] CLEAR_TRANSMITTAL_EMPLOYEES',
);
export const createTransmittalEmployee = createAction<{
  params: TransmittalEmployeeParams;
  data: TransmittalEmployee;
  orderBy: TransmittalSortOrder;
}>('[payroll] CREATE_TRANSMITTAL_EMPLOYEE');
export const getBlankTransmittalEmployeeCheck =
  createAction<TransmittalEmployeeParams>(
    '[payroll] GET_BLANK_TRANSMITTAL_EMPLOYEE_CHECK',
  );

export type UpdateCheckParams = {
  protectedEmpNo: string;
  params: TransmittalEmployeeCheckParams;
  data: TransmittalCheck;
  showSuccess: boolean | null;
  fromModal?: boolean;
  blankCheck?: boolean;
  currentTab?: number;
};

export const toggleSavingCheck = createAction<boolean>('[payroll] TOGGLE_SAVE_CHECK');

export const storeTransmittalEmployeeCheck = createAction<{
  protectedEmpNo: string;
  check: TransmittalCheck;
  delete?: boolean;
  blankCheck?: boolean;
  fromModal?: boolean;
  currentTab?: number;
  hasCheckError?: boolean;
}>('[payroll] STORE_TRANSMITTAL_EMPLOYEE_CHECK');

export const updateTransmittalEmployeeCheck = createAsyncThunk(
  '[payroll] UPDATE_TRANSMITTAL_EMPLOYEE_CHECK',
  async (request: UpdateCheckParams, { dispatch }) => {
    dispatch(toggleSavingCheck(true));
    
    try {
      const res = await PayrollService.putTransmittalEmployeeCheck(request.params, request.data);
      
      dispatch(storeTransmittalEmployeeCheck({
        blankCheck: request.blankCheck,
        fromModal: request.fromModal,
        protectedEmpNo: request.params.protectedEmpNo,
        check: res.data.value,
        currentTab: request.currentTab,
        hasCheckError: false,
      }));
      dispatch(loadPayrollControlTotal({
        ...request.params,
        preventPayrollsUpdate: true,
      }));
      
      if (request.showSuccess) dispatch(handleSuccess(res.data.messages));
      
      return res.data;
    } catch (error) {
      dispatch(handleError(error));
      dispatch(toggleSavingCheck(false));
      return error;
    } finally {
      dispatch(handlePending(null));
    }
  },
);

export const deleteTransmittalEmployeeCheck = createAction<TransmittalEmployeeCheckParams>('[payroll] DELETE_TRANSMITTAL_EMPLOYEE_CHECK');
export const clearTransmittalCheckFromStore = createAction('[payroll] CLEAR_CHECK_FROM_STORE');

export const storeCheckError = createAction<{ check: TransmittalCheck }>('[payroll] STORE_TRANSMITTAL_CHECK_ERROR');
export const clearCheckError = createAction('[payroll] CLEAR_TRANSMITTAL_CHECK_ERROR');

export const loadPayrollControlTotalDetail =
  createAction<PayrollControlTotalRequest>(
    '[payroll] LOAD_PAYROLL_CONTROL_TOTAL_DETAIL',
  );
export const storePayrollControlTotalDetail = createAction<
PayrollControlTotalDetail[]
>('[payroll] STORE_PAYROLL_CONTROL_TOTAL_DETAIL');
export const loadPayrollHistoryDeductionStatuses = createAction<number>(
  '[payroll] LOAD_PAYROLL_HISTORY_DEDUCTION_STATUS',
);
export const storePayrollHistoryDeductionStatuses = createAction<
PayrollHistoryDeductionStatus[]
>('[payroll] STORE_PAYROLL_HISTORY_DEDUCTION_STATUS');
export const updatePayrollHistoryDeductionStatuses = createAction<{
  params: number;
  data: PayrollHistoryDeductionStatus[];
}>('[payroll] UPDATE_PAYROLL_HISTORY_DEDUCTION_STATUS');

export const loadPayrollTransmittalReport = createAction<{
  payrollHistoryId: number;
  controlTotalId: number;
  reportType: string;
  isFinishedPayroll: boolean;
}>('[payroll] LOAD_PAYROLL_TRANSMITTAL_REPORT');

export const loadPayrollUnpaidEmployeesReport = createAction<{
  payrollHistoryId: number;
  controlTotalId: number;
  reportType: string;
}>('[payroll] LOAD_PAYROLL_UNPAIDEMPLOYEES_REPORT');

export const storePayrollReport = createAction<string>(
  '[payroll] STORE_PAYROLL_REPORT',
);

export const clearPayrollReport = createAction(
  '[payroll] CLEAR_PAYROLL_REPORT',
);

export const selectedEmployeeModal = createAction<{
  empNo: number;
  checkIndex: number;
}>('[payroll] SELECTED_EMPLOYEE_MODAL');

export const loadEmployeeVoidSearch = createAction<PayrollVoidSearchParams>(
  '[payroll] LOAD_EMPLOYEE_VOID_SEARCH',
);

export const updateSqlMescMainCheckNo = createAction<{ checkNoRequest: UpdateCheckNoRequest, searchRequest: PayrollVoidSearchParams }>('[payroll] UPDATE_SQLMESCMAIN_CHECKNO');

export const storeEmployeeVoidSearch = createAction<
PayrollAdjustmentTransaction[]
>('[payroll] STORE_EMPLOYEE_VOID_SEARCH');

export const clearEmployeeVoidSearch = createAction(
  '[payroll] CLEAR_EMPLOYEE_VOID_SEARCH',
);

export const postAdjustmentVoid = createAction<{
  payrollHistoryId: number;
  protectedEmpNo: string;
  params: PayrollAdjustmentVoidTransaction;
}>('[payroll] LOAD_ADJUSTMENT_VOID');

export const updateAutoCalcs = createAction<AutoCalcParams>('[payroll] UPDATE_AUTO_CALCS');

export const showVoidCheckWindow = createAction<boolean | null>(
  '[payroll] SHOW_VOID_CHECK_WINDOW',
);

export const loadPayrollPreviewPdf = createAction<PayrollPreview>(
  '[payroll] PUT_PAYROLL_PREVIEW_PDF',
);

export const storePayrollPreviewPdf = createAction<PayrollPreviewResult>(
  '[payroll] STORE_PAYROLL_PREVIEW_PDF',
);
export const clearPayrollPreviewPdf = createAction(
  '[payroll] CLEAR_PAYROLL_PREVIEW_PDF',
);

export const sendUserPDFEmail = createAction<number>(
  '[payroll] SEND_USER_PDF_PASSWORD',
);

export const storeUserPDFEmailMessage = createAction<string>(
  '[payroll] STORE_EMAIL_MESSAGE',
);

export const postPayrollValidate = createAction<PayrollValidateRequest>(
  '[payroll] POST_PAYROLL_VALIDATE',
);

export const storePayrollValidate = createAction<any>(
  '[payroll] STORE_PAYROLL_VALIDATE',
);

export const loadPayrollPayRateValidate = createAction<number>(
  '[payroll] LOAD_PAYROLL_PAYRATE_VALIDATE',
);

export const putPayrollPayRateValidate = createAction<{ request: TransmittalFuturePayRates[], payrollHistoryId: number }>(
  '[payroll] PUT_PAYROLL_PAYRATE_VALIDATE',
);

export const storePayrollPayRateValidate = createAction<TransmittalFuturePayRates[]>(
  '[payroll] STORE_PAYROLL_PAYRATE_VALIDATE',
);

export const loadPayrollTimeOffRequests = createAction<number>(
  '[payroll] LOAD_PAYROLL_TIME_OFF_REQUESTS',
);

export const storePayrollTimeOffRequests = createAction<PayrollTimeOffRequests>(
  '[payroll] STORE_PAYROLL_TIME_OFF_REQUESTS',
);

export const applyPayrollTimeOffRequests = createAction<ApplyTimeOffRequest>(
  '[payroll] POST_PAYROLL_APPLY_TIMEOFF_REQUESTS',
);

export const movePayrollTimeOffRequest = createAction<MoveTimeOffRequest>('[payroll] POST_PAYROLL_MOVE_TIMEOFF_REQUEST');

export const clearPayrollTimeOffRequest = createAction('[payroll] CLEAR_PAYROLL_TIMEOFF_REQUEST');

export const postPayrollMarkAsSubmitted = createAction<{ payrollHistoryId: number; data: PayrollOptions }>(
  '[payroll] POST_PAYROLL_MARK_AS_SUBMITTED',
);
export const storePayrollType = createAction<{ tab: string }>('[payroll] STORE_PAYROLL_TYPE');
export const storePayrollHasSignedWireOnlyAgreement = createAction<boolean>('[payroll] STORE_PAYROLL_HAS_SIGNED_WIRE_ONLY_AGREEMENT');
export const postPendingAccrualReport = createAction<{ payrollHistoryId: number }>('[payroll] POST_PENDING_ACCRUAL_REPORT');
export const storePendingAccrualReport = createAction<any>('[payroll] STORE_PENDING_ACCRUAL_REPORT');
export const postApprovedAccrualReport = createAction<{ payrollHistoryId: number }>('[payroll] POST_APPROVED_ACCRUAL_REPORT');
export const storeApprovedAccrualReport = createAction<any>('[payroll] STORE_APPROVED_ACCRUAL_REPORT');

export const clearAccrualReports = createAction('[payroll] CLEAR_PENDING_ACCRUAL_REPORTS');

export const loadUnionDuesSettings = createAction(
  '[payroll-report] LOAD_UNION_DUES_SETTINGS',
);

export const canRecoverPayroll = createAction<number>('[payroll] CAN_RECOVER_PAYROLL');
export const storeCanRecoverPayroll = createAction<CanRecoverPayrollResponse>('[payroll] STORE_CAN_RECOVER_PAYROLL');
export const recoverProcessedPayroll = createAction<RecoverPayrollRequest>('[payroll] RECOVER_PROCESSED_PAYROLL');

export const storePrevTransmittalLink = createAction<{ path: string; empNo: number; } | null>('[payroll] STORE_PREV_TRANSMITTAL_LINK');

export const setPreviewTab = createAction<string | null>('[payroll] SET_PREVIEW_TAB');

export const resetCheckIndex = createAction('[payroll] RESET_MODAL_INDEX');

export const storeCheckInState = createAction<TransmittalCheck>('[payroll] STORE_CHECK_IN_STATE)');

export const updateCurrentCTIds = createAction<{ payrollHistoryId: number, controlTotalId: number } | null>('[payroll] UPDATE_CT_IDS');

export const addBook = createAction<CreateControlTotalBookRequest>('[payroll] ADD_CONTROL_TOTAL_BOOK');

export const storeBook = createAction<Payroll>('[payroll] STORE_BOOK');

export const loadAdjustmentStateInfo = createAction<number>('[payroll] LOAD_STATE_INFO');
export const storeAdjustmentStateInfo = createAction<AdjustmentStateInfo[]>('[payroll] STORE_STATE_INFO');

export const storeLatestPayroll = createAction<number | null>('[payroll] STORE_LATEST_PAYROLL');
export const storeActiveYear = createAction<number>('[payroll] STORE_ACTIVE_YEAR');

export const toggleTransmittalEmpLoadingState = createAction<'loading' | 'success' | 'error'>('[payroll] TOGGLE_TRANS_EMP_LOAD');

export const toggleAccrualLoadingState = createAction<boolean>('[payroll] TOGGLE_ACCRUAL_LOADING_STATE');
export const toggleWireOnlyAgreementLoadingState = createAction<boolean>('[payroll] TOGGLE_WIRE_ONLY_AGREEMENT_LOADING_STATE');

export const updateCurrentTransmittalPage = createAction<number>('[payroll] UPDATE_TRANSMITTAL_PAGE');

export const sortTransmittalBy = createAction<TransmittalSortOrder | null>('[payroll] SORT_TRANSMITTAL_BY');