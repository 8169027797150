import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class TimeOffRequestDetail {
  timeOffRequestId = 0;

  date: Date | string | null = null;

  payrollHistoryId: number | null = null;

  hours = 0;

  startTime = '';

  allDay = false;

  taken = false;

  static readonly convProps: PropTypeLists = {
    dateProps: ['date'],
    numberProps: ['timeOffRequestId', 'empId', 'empNo', 'timeOffRequestId'],
    floatProps: ['hours'],
    // let bool props flow thru as booleans.
    // boolProps: ['allDay', 'taken']
  };

  constructor(
    timeOffRequestId: number | string,
    props?: { [key: string]: any },
  ) {
    this.timeOffRequestId = (typeof timeOffRequestId === 'string') ? parseInt(timeOffRequestId) : timeOffRequestId;

    props && convToClass(this, props, TimeOffRequestDetail.convProps);
  }
}