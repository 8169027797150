import { createAction } from '@reduxjs/toolkit';
import {
  FullTimeEquivalentReportRequest,
  CumulativeSeniorityReportRequest,
  MyInfoDocStatusReportRequest,
  MyInfoDocStatusFile,
  DemographicAndTurnoverReportRequest,
  DemographicAndTurnoverEmployee,
  EmployeeNavigatorAuditReportRequest,
  DepartmentofLaborSetting,
  DepartmentofLaborRequest,
  ClientOptions,
} from '../../models/HumanCapitalReports.model';

export const downloadCumulativeSeniorityReport = createAction<CumulativeSeniorityReportRequest>(
  '[human-capital-reports] DOWNLOAD_CUMULATIVE_SENIORITY_REPORT',
);
export const storeCumulativeSeniorityReport = createAction<string>(
  '[human-capital-reports] STORE_CUMULATIVE_SENIORITY_REPORT',
);
export const clearCumulativeSeniorityReport = createAction(
  '[human-capital-reports] CLEAR_CUMULATIVE_SENIORITY_REPORT',
);

export const downloadFulltimeEquivalentReport = createAction<FullTimeEquivalentReportRequest>(
  '[human-capital-reports] DOWNLOAD_FULLTIIME_EQUIVALENT',
);
export const storeFulltimeEquivalentReport = createAction<string>(
  '[human-capital-reports] STORE_FULLTIIME_EQUIVALENT',
);
export const clearFulltimeEquivalentReport = createAction(
  '[human-capital-reports] CLEAR_FULLTIIME_EQUIVALENT',
);

export const downloadDeparmentofLaborReport = createAction<DepartmentofLaborRequest>(
  '[human-capital-reports] DOWNLOAD_DEPARTMENT_OF_LABORT_REPORT',
);
export const storeDeparmentofLaborReport = createAction<string>(
  '[human-capital-reports] STORE_DEPARTMENT_OF_LABORT_REPORT',
);
export const clearDeparmentofLaborReport = createAction(
  '[human-capital-reports] CLEAR_DEPARTMENT_OF_LABORT_REPORT',
);

export const loadDepartmentofLaborSettings = createAction(
  '[human-capital-reports] LOAD_DEPARTMENT_OF_LABOR_SETTINGS',
);
export const storeDepartmentofLaborSettings = createAction<DepartmentofLaborSetting[]>(
  '[human-capital-reports] STORE_DEPARTMENT_OF_LABORT_SETTINGS',
);
export const createDepartmentofLaborSettings = createAction<DepartmentofLaborSetting>(
  '[human-capital-reports] CREATE_DEPARTMENT_OF_LABOR_SETTINGS',
);
export const updateDepartmentofLaborSettings = createAction<DepartmentofLaborSetting>(
  '[human-capital-reports] UPDATE_DEPARTMENT_OF_LABOR_SETTINGS',
);
export const deleteDepartmentofLaborSettings = createAction<DepartmentofLaborSetting>(
  '[human-capital-reports] DELETE_DEPARTMENT_OF_LABOR_SETTINGS',
);

export const loadMyInfoDocStatusFiles = createAction(
  '[human-capital-reports] LOAD_MY_INFO_DOC_STATUS_FILES',
);

export const storeMyInfoDocStatusFiles = createAction<MyInfoDocStatusFile[]>(
  '[human-capital-reports] STORE_MY_INFO_DOC_STATUS_FILES',
);
export const downloadMyInfoDocStatusReport = createAction<MyInfoDocStatusReportRequest>(
  '[human-capital-reports] DOWNLOAD_MY_INFO_DOC_STATUS',
);
export const storeMyInfoDocStatusReport = createAction<string>(
  '[human-capital-reports] STORE_MY_INFO_DOC_STATUS_REPORT',
);
export const clearMyInfoDocStatusReport = createAction(
  '[human-capital-reports] CLEAR_MY_INFO_DOC_STATUS_REPORT',
);
export const downloadACAProofListReport = createAction<number>(
  '[human-capital-reports] DOWNLOAD_ACA_Proof_List_REPORT',
);
export const storeACAProofListReport = createAction<string>(
  '[human-capital-reports] STORE_ACA_Proof_List_REPORT',
);
export const clearACAProofListReport = createAction(
  '[human-capital-reports] CLEAR_ACA_Proof_List_REPORT',
);

export const downloadEmployeeNavigatorAuditReport = createAction<EmployeeNavigatorAuditReportRequest>(
  '[payroll-report] DOWNLOAD_EMPLOYEE_AUDIT_NAVIGATOR_REPORT',
);
export const storeEmployeeNavigatorAuditReport = createAction<string>(
  '[payroll-report] STORE_EMPLOYEE_AUDIT_NAVIGATOR_REPORT',
);
export const clearEmployeeNavigatorAuditReport = createAction(
  '[payroll-report] CLEAR_EMPLOYEE_AUDIT_NAVIGATOR_REPORT',
);

export const downloadDemographicAndTurnoverReport = createAction<DemographicAndTurnoverReportRequest>(
  '[human-capital-reports] DOWNLOAD_DEMOGRAPAHIC_AND_TURNOVER_REPORT',
);
export const storeDemographicAndTurnoverReport = createAction<string>(
  '[human-capital-reports] STORE_DEMOGRAPAHIC_AND_TURNOVER_REPORT',
);
export const clearDemographicAndTurnoverReport = createAction(
  '[human-capital-reports] CLEAR_DEMOGRAPAHIC_AND_TURNOVER_REPORT',
);

export const getDemographicAndTurnoverEmployees = createAction(
  '[human-capital-reports] GET_DEMOGRAPAHIC_AND_TURNOVER_EMPLOYEES',
);
export const storeDemographicAndTurnoverEmployees = createAction<DemographicAndTurnoverEmployee[]>(
  '[human-capital-reports] STORE_DEMOGRAPAHIC_AND_TURNOVER_EMPLOYEES',
);
export const updateDemographicAndTurnoverEmployee = createAction<DemographicAndTurnoverEmployee>(
  '[human-capital-reports] UPDATE_DEMOGRAPAHIC_AND_TURNOVER_EMPLOYEE',
);

export const loadClientReportOptions = createAction('[human-capital-reports] LOAD_CLIENT_REPORT_OPTIONS');
export const storeClientReportOptions = createAction<ClientOptions[]>('[human-capital-reports] STORE_CLIENT_REPORT_OPTIONS');