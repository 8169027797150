import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleError, handleWarning } from './app.action';
import { AxiosResponse } from 'axios';
import { HttpResponse } from 'core/models';
import { FileResponse } from 'core/models/File.model';

export const postDownloadMultipleFiles = createAsyncThunk(
    'files/downloadMultipleFiles',
    async (api: () => Promise<AxiosResponse<HttpResponse<FileResponse[]>>>, { dispatch }) => {
      try {
        const response = await api();

        return response?.data || response;
      } catch (err: any) {
        if(err?.statusCode === "NotFound") dispatch(handleWarning(err?.messages[0]));
        else dispatch(handleError(err));
      }
    },
  );
