import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EmployeeSearch from 'core/components/shared/EmployeeSearch';
import EmployeeSearchHeader, { SearchForm } from 'core/components/shared/EmployeeSearchHeader';
import { useAppSelector } from 'utilities/hooks';

const CheckCalculatorSearch = () => {
  const [searchForm, setSearchForm] = useState<string>('');
  const [isSelectedEmp, setIsSelectedEmp] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchAllClients, setSearchAllClients] = useState<boolean>(false);

  const onSearch = (search: SearchForm) => {
    setSearchForm(search.searchEmp);
    setIsSearching(true);
    setIsSelectedEmp(false);
  };

  const employeeSearch = useAppSelector((state) => {return state.app.employeeSearch;});

  useEffect(() => {
    if (isSearching && employeeSearch.length === 1) {
      setIsSelectedEmp(true);
      selectEmployee(employeeSearch[0].protectedEmpNo);
    }
  }, [employeeSearch]);

  const history = useHistory();

  const selectEmployee = (protectedEmpNo: string) => {
    history.push(`/employee/detail/${protectedEmpNo}/check-calculator`);
  };

  return (
    <div className="container-fluid shadow shadow-lg">
      <EmployeeSearchHeader
        headerName="Check Calculator Search"
        onSearch={onSearch}
        isSearching={isSearching}
        isSelectedEmp={isSelectedEmp}
        searchAll={searchAllClients}
        setSearchAllClients={setSearchAllClients}
      />
      <EmployeeSearch
        selectEmployee={selectEmployee}
        query={searchForm}
        isSearching={isSearching}
        searchAll={searchAllClients}
      />
    </div>
  );
};

export default CheckCalculatorSearch;
