import { createReducer } from '@reduxjs/toolkit';
import { PayStub } from '../../models';
import {
  clearPayStubPDF,
  loadPayStubs,
  storeAddPasswordToPDF,
  storePayStubPDF,
  storePayStubs,
} from '../actions';

export interface State {
  loading: boolean;
  payStubs: PayStub[];
  payStubPDF: any;
  payStubPDFWithPW: any;
}

export const INITIAL_STATE: State = {
  loading: false,
  payStubs: [],
  payStubPDF: null,
  payStubPDFWithPW: null,
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadPayStubs.type]: (state) => {
    return { ...state,
      loading: true };
  },
  [storePayStubs.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      payStubs: action.payload,
    };
  },
  [storePayStubPDF.type]: (state, action) => {
    return { ...state,
      payStubPDF: action.payload }; 
  },
  [clearPayStubPDF.type]: (state, action) => {
    return { ...state,
      payStubPDF: null }; 
  },
  [storeAddPasswordToPDF.type]: (state, action) => {
    return { ...state,
      payStubPDFWithPW: action.payload };
  },
});