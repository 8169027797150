import { createReducer } from '@reduxjs/toolkit';
import { EmpDate, Employee, Supervisor } from '../../models';
import {
  createEmployeeSuccess,
  loadEmpDate,
  loadEmployees,
  loadFilteredEmployees,
  loadFilterModel,
  storeEmpDate,
  storeEmployee,
  storeEmployees,
  storeEmployeesAfterAdd,
  storeEmployeesAfterDelete,
  storeFilteredEmployees,
  storeFilterModel,
  storePrevHRLink,
  storeSupervisors,
  updateListQuery,
  resetFilteredEmployees,
} from '../actions';
import { filterModelOpts } from 'core/components/shared/table/constants';
import { TableModel as Tm } from 'core/components/shared/table/types';
import { TransmittalDefaultSort } from 'features/employee/constants';

const sortEmployees = (employees: Employee[], sortBy: TransmittalDefaultSort): Employee[] => {
  const empsCopy: Employee[] = employees.map((x) => ({ ...x }));
  
  switch (sortBy) {
    case 'Loc Dept Alfa':
      empsCopy.sort((a, b) => {
        return (a?.loc ?? -999) - (b?.loc ?? -999)
          || (a?.dept ?? -999) - (b?.dept ?? -999)
          || a.lastName.localeCompare(b.lastName);
      });
      break;
    case 'Department':
      empsCopy.sort((a, b) =>
        (a?.dept ?? -999) - (b?.dept ?? -999)
        || a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase())
        || a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase())
        || a.midName.toLowerCase().localeCompare(b.midName.toLowerCase())
        || a.empNo - b.empNo,
      );
      break;
    case 'Sub-Department':
      empsCopy.sort((a, b) =>
        (a?.subDept ?? -999) - (b?.subDept ?? -999)
        || a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase())
        || a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase())
        || a.midName.toLowerCase().localeCompare(b.midName.toLowerCase())
        || a.empNo - b.empNo,
      );
      break;
    case 'Sub-Department 2':
      empsCopy.sort((a, b) =>
        (a?.subDept2 ?? -999) - (b?.subDept2 ?? -999)
        || a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase())
        || a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase())
        || a.midName.toLowerCase().localeCompare(b.midName.toLowerCase())
        || a.empNo - b.empNo,
      );
      break;
    case 'Employee No':
      empsCopy.sort((a, b) => a.empNo - b.empNo);
      break;
    case 'Alphabetically':
    case 'Last Name':
    default:
      empsCopy.sort((a, b) =>
        a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase())
        || a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase())
        || a.midName.toLowerCase().localeCompare(b.midName.toLowerCase())
        || a.empNo - b.empNo,
      );
  }
  
  return empsCopy;
};

export interface State {
  loading: boolean;
  employees: Employee[];
  filteredEmployees: Employee[];
  filterModel: Tm.FilterModel<Employee>;
  radioSelection: 'All' | 'Active' | 'Terminated';
  supervisors: Supervisor[];
  prevHRLink: string | null;
  empDate: EmpDate | null;
  filterModelOpts: { description: Tm.FilterType }[];
  listQuery: string;
}

export const INITIAL_STATE: State = {
  loading: false,
  employees: [],
  filteredEmployees: [],
  filterModel: {
    termDate: {
      sortOrder: 'UNSORTED',
      type: 'date',
      filterType: 'Blank',
    },
  },
  radioSelection: 'Active',
  supervisors: [],
  prevHRLink: null,
  empDate: null,
  filterModelOpts,
  listQuery: '',
};

//TODO need both storeEmployee & storeSelectedEmployee ???
export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadEmployees, (state) => {
      state.loading = true;
    })
    .addCase(storeEmployees, (state, action) => {
      state.loading = false;
      state.employees = action.payload;
      state.filteredEmployees = action.payload?.filter((emp) => !emp.termDate); // to match default "active" employees in list
      state.filterModel = INITIAL_STATE.filterModel;
      state.radioSelection = INITIAL_STATE.radioSelection;
    })
    .addCase(storeEmployeesAfterDelete, (state, action) => {
      state.loading = false;
      //Instead of calling the Employees GET again just store the current employees state without the deleted employee.
      const filteredEmployees = state.employees.filter(x => x.empNo !== action.payload);
      state.employees = filteredEmployees;
    })
    .addCase(storeEmployeesAfterAdd, (state, action) => {
      state.loading = false;
      //Instead of calling the Employees GET again just store the current employees state with the new employee
      const employees = [...state.employees, action.payload];
      state.employees = employees;
    })
    .addCase(loadFilteredEmployees, (state) => {
      state.loading = true;
    })
    .addCase(storeFilteredEmployees, (state, action) => {
      state.loading = false;
      state.filteredEmployees = action.payload;
    })
    .addCase(resetFilteredEmployees, (state) => {
      // reset to all active employees.
      state.filteredEmployees = state.employees.filter((x) => !x.termDate);
    })
    .addCase(loadFilterModel, (state) => {
      state.loading = true;
    })
    .addCase(storeFilterModel, (state, action) => {
      state.loading = false;
      state.filterModel = action.payload.filterModel;
      state.radioSelection = action.payload.radioSelection;
    })
    .addCase(storeEmployee, (state, action) => {
      const { employee, preventEmpsUpdate } = action.payload;
      const index = state.filteredEmployees.findIndex((emp) => {return emp.empNo === employee.empNo;});

      state.loading = false;
      
      if (preventEmpsUpdate) return;
      
      state.employees = sortEmployees([
        ...state.employees.filter(
          (e) => {return e.empNo !== employee.empNo;},
        ),
        employee,
      ], action.payload.sortBy);
      
      if (index > -1) {
        state.filteredEmployees.splice(index, 1, employee);
      } else {
        state.filteredEmployees = sortEmployees([
          ...state.employees.filter(
            (e) => {return e.empNo !== employee.empNo;},
          ),
          employee,
        ], action.payload.sortBy);
      }
    })
    .addCase(storeSupervisors, (state, action) => {
      state.loading = false;
      state.supervisors = action.payload;
    })
    .addCase(storePrevHRLink, (state, action) => {
      state.prevHRLink = action.payload;
    })
    .addCase(loadEmpDate, (state) => {
      state.loading = true; // but maybe use a flag specifically for emp dates?
    })
    .addCase(storeEmpDate, (state, action) => {
      state.loading = false;
      // since we don't call the employees GET when going to the list, we should update this in the store.
      const { empDate, updateKeys } = action.payload;
      let updatedEmp: Employee | undefined = [...state.employees].find((employee) => {
        return employee.empNo === empDate.empNo;
      });
      
      state.empDate = empDate;
      
      if (!updatedEmp) throw new Error('Employee not found');
      if (!updateKeys.length) return;
      
      const joinKeys = ['birthDate', 'hireDate', 'termDate', 'termCode', 'otherDate'];
      for (const key in empDate) {
        if (joinKeys.includes(key)) {
          updatedEmp = {
            ...updatedEmp,
            [key]: empDate[key as keyof EmpDate],
          };
        }
      }
      // TODO: this may be simplified if there isn't another way to the dates page
      const updateIndex = state.employees.findIndex((emp) => { return emp.empNo === updatedEmp?.empNo; });
      const updateFilteredIndex = state.filteredEmployees.findIndex((emp) => { return emp.empNo === updatedEmp?.empNo; });
      if (updateIndex < 0) throw new Error('Employee cannot be updated');
      state.employees.splice(updateIndex, 1, updatedEmp);
      if (updateFilteredIndex < 0) return;
      state.filteredEmployees.splice(updateFilteredIndex, 1, updatedEmp);      
    })
    .addCase(createEmployeeSuccess, (state, action) => {
      state.employees = [
        ...state.employees?.filter((emp) => emp.empNo !== action.payload.empNo),
        action.payload,
      ];
      state.filteredEmployees = [
        ...state.employees?.filter((emp) => emp.empNo !== action.payload.empNo),
        action.payload,
      ];
    })
    .addCase(updateListQuery, (state, action) => {
      state.listQuery = action.payload;
    })
  ;
});
