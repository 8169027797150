import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from 'utilities/hooks';
import { WCEffectiveDate } from 'core/models';
import { deleteWCEffectiveDate } from 'core/store/actions';
import { getWorkerComps } from 'core/store/selectors';
import Modal from '../Modal';
import WorkCompRuleItem from './WorkCompRuleItem';
interface PropTypes {
  show: boolean;
  onHide: () => void;
}

const WorkCompRulesModal: React.FC<PropTypes> = ({ show, onHide }) => {
  const dispatch = useDispatch();

  const wcEffectiveDates = useSelector(getWorkerComps);
  const clientNo = useAppSelector((state) => { return state.client.client!.clientNo; });

  const [rowData, setRowData] = useState<WCEffectiveDate[]>([
    new WCEffectiveDate(clientNo, 0),
  ]);

  useEffect(() => {
    setRowData(wcEffectiveDates);
  }, [wcEffectiveDates]);

  const onAddEffectiveDate = () => {
    setRowData((prev: WCEffectiveDate[]) => {
      return [
        ...prev,
        new WCEffectiveDate(clientNo, 0),
      ];
    });
  };

  const onDeleteEffectiveDate = (item: WCEffectiveDate) => {
    item.stateOfficerLimitID && dispatch(deleteWCEffectiveDate(item));
  };

  const closeModal = (e: any) => {
    e.stopPropagation();
    onHide();
  };

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        title="Work Comp Rules"
        headerSlot={(
          <button
            className="btn btn-link dm-grid-action-title pb-0"
            style={{ textDecoration: 'none' }}
            onClick={onAddEffectiveDate}
          >
            Add Effective Date{' '}
            <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        )}
      >
        <div className="dm-panel dm-panel-border">
          {rowData.map((item: WCEffectiveDate, index: number) => {
            return (
              <div key={item.stateOfficerLimitID}>
                <WorkCompRuleItem
                  item={item}
                  onDelete={onDeleteEffectiveDate}
                />
                {index + 1 < rowData.length && (
                  <hr className="dm-panel-hr" />
                )}
              </div>
            );
          })}
        </div>
      </Modal>
    </div>
  );
};
export default WorkCompRulesModal;
