import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { DashboardService } from '../../services';
import {
  storeNewFeatures,
  storeReminders,
  storeEmpPaidBreakdown,
  storeIssues,
  storeEmpGrossAnalysis,
  storeNewHireBreakdown,
  loadNewFeatures,
  loadReminders,
  loadEmpPaidBreakdown,
  loadIssues,
  loadEmpGrossAnalysis,
  loadNewHireBreakdown,
  storePayrollTotalsByTimePeriod,
  loadPayrollTotalsByTimePeriod,
  loadPayrollTotalsByLocDeptSubSummary,
  storePayrollTotalsByLocDeptSubSummary,
  loadDashboardData,
} from '../actions/dashboard.action';
import {
  EmpGrossPayAnalysis,
  EmpPaidBreakdown,
  HttpResponse,
  IssueToBeResolved,
  NewFeature,
  NewHireBreakdown,
  Reminder,
} from '../../models';
import { handleError } from '../actions';
import { PayrollTotalsByTimePeriodSummary } from '../../models/PayrollTotalsByTimePeriodSummary';
import { PayrollTotalsByLocDeptSubSummary } from '../../models/PayrollTotalsByLocDeptSubSummary';

interface Actions {
  type: string;
  payload: any;
}

const loadNewFeatures$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadNewFeatures.type),
    switchMap(() => {
      return from(DashboardService.getNewFeatures()).pipe(
        map((res: any) => {return res.data;}),
        map((res: NewFeature[]) => {return storeNewFeatures(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadReminders$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadReminders.type),
    switchMap(() => {
      return from(DashboardService.getReminders()).pipe(
        map((res: any) => {return res.data;}),
        map((res: Reminder[]) => {return storeReminders(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadEmpPaidBreakdown$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmpPaidBreakdown.type),
    switchMap(() => {
      return from(DashboardService.getEmpPaidBreakdown()).pipe(
        map((res: any) => {return res.data;}),
        map((res: EmpPaidBreakdown) => {return storeEmpPaidBreakdown(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadIssues$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadIssues.type),
    switchMap(() => {
      return from(DashboardService.getIssues()).pipe(
        map((res: any) => {return res.data;}),
        map((res: IssueToBeResolved[]) => {return storeIssues(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadEmpGrossAnalysis$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmpGrossAnalysis.type),
    switchMap(() => {
      return from(DashboardService.getEmpGrossAnalysis()).pipe(
        map((res: any) => {return res.data;}),
        map((res: EmpGrossPayAnalysis) => {return storeEmpGrossAnalysis(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadHireBreakdown$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadNewHireBreakdown.type),
    switchMap(() => {
      return from(DashboardService.getNewHireBreakdown()).pipe(
        map((res: any) => {return res.data;}),
        map((res: NewHireBreakdown) => {return storeNewHireBreakdown(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadPayrollTotalByTimePeriod$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadPayrollTotalsByTimePeriod.type),
    switchMap(() => {
      return from(DashboardService.getPayrollTotalByTimePeriod()).pipe(
        map((res: any) => {return res.data;}),
        map((res: PayrollTotalsByTimePeriodSummary) => {return storePayrollTotalsByTimePeriod(res);},
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadPayrollTotalsByLocDeptSummary$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadPayrollTotalsByLocDeptSubSummary.type),
    switchMap(() => {
      return from(DashboardService.getPayrollTotalsByLocDeptSub()).pipe(
        map((res: any) => {return res.data;}),
        map((res: PayrollTotalsByLocDeptSubSummary) => {return storePayrollTotalsByLocDeptSubSummary(res);},
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const loadDashboardData$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadDashboardData.type),
    mergeMap(() => {
      return [
        loadNewFeatures(),
        loadReminders(),
        loadEmpPaidBreakdown(),
        loadIssues(),
        loadEmpGrossAnalysis(),
        loadNewHireBreakdown(),
        loadPayrollTotalsByTimePeriod(),
        loadPayrollTotalsByLocDeptSubSummary(),
      ];
    }),
  );
};

export const epics: any[] = [
  loadNewFeatures$,
  loadReminders$,
  loadEmpPaidBreakdown$,
  loadIssues$,
  loadEmpGrossAnalysis$,
  loadHireBreakdown$,
  loadPayrollTotalByTimePeriod$,
  loadPayrollTotalsByLocDeptSummary$,
  loadDashboardData$,
];
