import { AxiosResponse } from "axios";
import { HireHistory, HireHistoryWithRehireInfo, HttpResponse } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getHireHistories = (protectedEmpNo: string): Promise<AxiosResponse<HireHistory[]>> => {return API_EMPLOYEE_MASTER.get<HireHistory[]>(EmployeeEndPoints.GET_HIRE_HISTORIES(CommonService.getProtectedClientNo(), protectedEmpNo));};

const getHireHistory = (data: HireHistory): Promise<AxiosResponse<HireHistory>> => {return API_EMPLOYEE_MASTER.get<HireHistory>(EmployeeEndPoints.GET_HIRE_HISTORY(CommonService.getProtectedClientNo(), data.protectedEmpNo, data.empDateID));};

const postHireHistoryWizard = (data: HireHistoryWithRehireInfo): Promise<AxiosResponse<HttpResponse<HireHistory>>> => {return API_EMPLOYEE_MASTER.post<HttpResponse<HireHistory>>(EmployeeEndPoints.POST_HIRE_HISTORY(CommonService.getProtectedClientNo(), data.protectedEmpNo), data);};

const putHireHistory = (data: HireHistory): Promise<AxiosResponse<HttpResponse<HireHistory>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<HireHistory>>(EmployeeEndPoints.PUT_HIRE_HISTORY(CommonService.getProtectedClientNo(), data.protectedEmpNo, data.empDateID), data);};

const deleteHireHistory = (data: HireHistory): Promise<AxiosResponse<any>> => {return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.DELETE_HIRE_HISTORY(CommonService.getProtectedClientNo(), data.protectedEmpNo, data.empDateID));};

export const HireHistoryService = {
  getHireHistories,
  getHireHistory,
  postHireHistoryWizard,
  putHireHistory,
  deleteHireHistory,
};
