import { createSelector } from 'reselect';
import { MyInfoChangeLog, MyInfoChangeLogHistory } from '../../models/MyInfoChangeLog';
import { myInfoChangesState } from '../reducers';
import { MyInfoChangeLogState } from '../reducers/my-info-changes.reducer';

export const getMyInfoChangeLogHistory = createSelector(
  myInfoChangesState,
  (state: MyInfoChangeLogState): MyInfoChangeLogHistory | null => {
    return state.myInfoChangeLogHistory;
  },
);

export const getMyInfoChanges = createSelector(
  myInfoChangesState,
  (state: MyInfoChangeLogState): MyInfoChangeLog[] => {
    return state.myInfoChangeLogs;
  },
);