import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { putClientOption } from 'core/store/actions/client.action';
import { SelectGrp } from '../form-controls';

const SeniorityComponent = () => {
  const dispatch = useAppDispatch();

  const [seniorityDuration, setSeniorityDuration] = useState<Duration>();
  const [age, setAge] = useState<number>(0);

  const empDate = useAppSelector((state) => { return state.employees.empDate; });
  const seniorityCalculationDropDown = useAppSelector(
    (state) => {return state.dropdown.seniorityCalculation;},
  );
  const seniorityOptions = useAppSelector((state) => { return state.client.clientOptions.options[279]; });

  useEffect(() => {
    if (empDate) {
      setAge(
        Math.abs(
          DateTime.fromISO(empDate.birthDate as string).diffNow([
            'years',
            'months',
          ]).years,
        ),
      );
      updateSeniorityDate();
    }
  }, [empDate]);

  useEffect(() => {
    updateSeniorityDate();
  }, [seniorityOptions]);

  const updateSeniorityDate = () => {
    if (seniorityOptions) {
      let diffNow = {} as Duration;
      switch (seniorityOptions.optionValue) {
        case '0':
          diffNow = DateTime.fromISO(
            empDate?.hireDate as string,
          ).diffNow(['years', 'months', 'days']);
          break;
        case '1':
          diffNow = DateTime.fromISO(
            empDate?.hireDate2 as string,
          ).diffNow(['years', 'months', 'days']);
          break;
        case '2':
          diffNow = DateTime.fromISO(
            empDate?.seniorityDate as string,
          ).diffNow(['years', 'months', 'days']);
          break;
      }
      setSeniorityDuration(diffNow);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const option = { ...seniorityOptions,
      optionValue: e.target.value };
    dispatch(putClientOption(option));
  };

  return (
    <div className="row">
      <div className="col-sm-6">
        <div className="form-label mt-1">Age</div>
        <div>{age}</div>
      </div>
      <div className="col-sm-6">
        <SelectGrp
          label="Seniority Calculation"
          name="seniorityCalculation"
          options={seniorityCalculationDropDown}
          onChange={onChange}
          defaultValue={seniorityOptions.optionValue}
        />
      </div>
      <div className="col-sm-6">
        <div className="form-label mt-1">Seniority Years</div>
        <div>
          {Number.isNaN(seniorityDuration?.years)
            ? ''
            : (seniorityDuration?.years ?? 0) * -1}
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-label mt-1">Seniority Months</div>
        <div>
          {Number.isNaN(seniorityDuration?.months)
            ? ''
            : (seniorityDuration?.months ?? 0) * -1}
        </div>
      </div>
    </div>
  );
};

export default SeniorityComponent;
