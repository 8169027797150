import React, { useState } from 'react';
import { ControlDatePickerGrpInLine, SelectGrpInLine } from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import Modal from 'core/components/modals/Modal';
import { DeptAllocation } from 'core/models';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'utilities/hooks';

type Props = {
  show: boolean;
  prepend: (newAllocation: DeptAllocation) => void;
  onHide: () => void;
};

const fs: FieldInputSettings = {
  beginDate: {
    name: 'beginDate',
  },
  endDate: {
    name: 'endDate',  
  },
  transmittalLocation: {
    name: 'transmittalLocation',
  },
  transmittalDept: {
    name: 'transmittalDept',
  },
  transmittalSub: {
    name: 'transmittalSub',
  },
  transmittalSub2: {
    name: 'transmittalSub2',
  },
};
const currentYear = new Date().getFullYear();

const defaultNewAllocation: DeptAllocation = {
  deptAllocationId: 0,
  beginDate: new Date('01/01/' + currentYear),
  endDate: new Date('12/31/' + currentYear),
  transmittalLocation: 0,
  transmittalDept: 0,
  transmittalSub: 0,
  transmittalSub2: 0,
  allocationDetails: [], // set in prepend function for grid callbacks
};

const DeptAllocationAddModal = ({ show, prepend, onHide }: Props) => {
  const locationOpts = useAppSelector((state) => {return state.dropdown.location;});
  const deptOpts = useAppSelector((state) => {return state.dropdown.department;});
  const subdeptOpts = useAppSelector((state) => {return state.dropdown.subDepartment;});
  const subdept2Opts = useAppSelector((state) => {return state.dropdown.subDepartment2;});
  
  const { register, control, errors, handleSubmit, watch } = useForm<DeptAllocation>({
    defaultValues: defaultNewAllocation,
  });
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Add Department Allocation"
    >
      {(closeModal) => {
        return (
          <div
            className="d-flex flex-column"
            onClick={(e) => {return e.stopPropagation();}}
          >
            <form
              onSubmit={handleSubmit(prepend)}
            >
              <div className="d-flex flex-wrap">
                <div className="d-flex w-50 p-1">
                  <div className="mr-2">Begin Date:&nbsp;</div>  
                  <ControlDatePickerGrpInLine
                    name="beginDate"
                    control={control}
                    errors={errors.beginDate}
                    required
                  />
                </div>
                <div className="d-flex w-50 p-1">
                  <div className="mr-2">End Date:&nbsp;</div>  
                  <ControlDatePickerGrpInLine
                    name="endDate"
                    control={control}
                    errors={errors.endDate}
                  />
                </div>
              </div>
              {locationOpts.length ? <div className="d-flex w-100 p-1">
                <div className="mr-2">Location:&nbsp;</div>  
                <SelectGrpInLine 
                  {...fs.transmittalLocation}
                  ref={register()}
                  options={[{ locationCode: 0,
                    locationDesc: 'All locations' }, ...locationOpts]}
                  valueField="locationCode"
                  labelField="locationDesc"
                  labelWidth={0}
                  errors={errors.transmittalLocation}
                />
              </div> : null}
              {deptOpts.length ? <div className="d-flex w-100 p-1">
                <div className="mr-2">Department:&nbsp;</div>  
                <SelectGrpInLine 
                  {...fs.transmittalDept}
                  ref={register()}
                  options={[{ deptCode: 0,
                    deptDesc: 'All departments' }, ...deptOpts]}
                  valueField="deptCode"
                  labelField="deptDesc"
                  labelWidth={0}
                  errors={errors.transmittalDept}
                />
              </div> : null}
              {subdeptOpts.length ? <div className="d-flex w-100 p-1">
                <div className="mr-2">Sub Department:&nbsp;</div>  
                <SelectGrpInLine 
                  {...fs.transmittalSub}
                  ref={register()}
                  options={[{ subDeptCode: 0,
                    subDeptDesc: 'All sub departments' }, ...subdeptOpts]}
                  valueField="subDeptCode"
                  labelField="subDeptDesc"
                  labelWidth={0}
                  errors={errors.transmittalSub}
                />
              </div> : null}
              {subdept2Opts.length ? <div className="d-flex w-100 p-1">
                <div className="mr-2">Sub Department 2:&nbsp;</div>  
                <SelectGrpInLine 
                  {...fs.transmittalSub2}
                  ref={register()}
                  options={[{ sub2Code: 0,
                    sub2Desc: 'All sub 2 departments' }, ...subdept2Opts]}
                  valueField="sub2Code"
                  labelField="sub2Desc"
                  labelWidth={0}
                  errors={errors.transmittalSub2}
                />
              </div> : null}
              <div className="d-flex w-100 justify-content-end mt-2">
                <button
                  className="btn btn-primary orange-button mr-2"
                  onClick={closeModal}
                  style={{zIndex: 'auto'}}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary orange-button"
                  style={{zIndex: 'auto'}}
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        );
      }}
    </Modal>
  );
};

export default DeptAllocationAddModal;