import React, { useMemo, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import {
  employeesDD,
  payrollDD,
  reportingDD,
  humanCapitalManagementDD,
  accountsPayableDD,
  toolsDD,
  manageDD,
  contractorDD,
} from '../../navigation/app-main-nav';
import { DMDropdownDataModel, DMDropdownItemModel } from '../../core/components/form-controls/DMDropDown';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { getClientOptionIsContractor, getReportOptions } from 'core/store/selectors';
import { useAppSelector } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';

const DivComponent: React.FC<any> = ({ className, style, children, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={className}
      style={style}
    >
      {children}
    </div>
  );
};

type AppSideNavProps = {
  closeSidebar: () => void;
};

const AppSideNav = ({ closeSidebar }: AppSideNavProps) => {
  const isContractor = useSelector(getClientOptionIsContractor);
  const client = useAppSelector((state) => state.client?.client);
  //TODO: If we get too many work flows just make this one "Uses Work Flow option"
  const payRateWorkFlow = useSelector(getReportOptions('PayRateWorkFlow'));
  const terminationWorkFlow = useSelector(getReportOptions('TerminationWorkFlow'));
  const usesIntegrationLogs = useSelector(getReportOptions('IntegrationLogs'));

  const [accordionOpen, setAccordionOpen] = useState<{ [key: string]: boolean }>({});
  const [, setShowModal] = useState(false);
  
  const parsedReportingDDItems = useMemo(() => {
    const items = cloneDeep(reportingDD.items).map(item => {
      if (isContractor) {
        return item;
      }

      if (item.id === 'reporting-4' && item?.subItems) {
        item.subItems = item?.subItems.filter(subItem => {
          return !subItem.isContractorOnly;
        });
      }

      return item;
    });

    if (isContractor) {
      return [...items, contractorDD];
    }

    return items;
  }, [isContractor, reportingDD?.items]);

  const allNavs = [
    employeesDD,
    payrollDD,
    {
      ...reportingDD,
      items: parsedReportingDDItems,
    },
    humanCapitalManagementDD((payRateWorkFlow?.showReport || false) || (terminationWorkFlow?.showReport || false)),
    accountsPayableDD,
    manageDD,
    toolsDD((usesIntegrationLogs?.showReport || false)),
  ];

  const toggleAccordion = (key: string) => {
    const newAccordionOpen = { ...accordionOpen };
    Object.keys(newAccordionOpen).map((k: string) => { return (newAccordionOpen[k] = false); });
    newAccordionOpen[key] = !accordionOpen[key];

    if (key.includes('-')) {
      const parentKey = key.substring(0, key.indexOf('-'));
      newAccordionOpen[parentKey] = true;
    }
    setAccordionOpen(newAccordionOpen);
  };
  
  const renderSubNav = (nav: DMDropdownDataModel, subNav: DMDropdownItemModel) => {
    if (subNav.subItems) {
      return (
        <Accordion key={subNav.id}>
          <Accordion.Toggle
            as={DivComponent}
            eventKey={subNav.id}
            onClick={() => { return toggleAccordion(subNav.id); }}
          >
            <div className={`dm-accordion-toggle dm-main-nav ${accordionOpen[subNav.id] ? 'open' : ''}`}>
              {nav.preIcon && (
                <img
                  src={require(`../../assets/images/${nav.preIcon}`)}
                  alt="check"
                  width="25px"
                  height="auto"
                  className="pr-1"
                />
              )}
              {subNav.text}
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={subNav.id}>
            <div>
              {subNav.subItems?.map((subNav2: any) => {
                return (
                  <NavLink
                    key={subNav2.id}
                    className="dm-accordion-sub-item"
                    to={subNav2.href}
                    onClick={closeSidebar}
                  >
                    {subNav2.preIcon && (
                      <img
                        src={require(`../../assets/images/${nav.preIcon}`)}
                        alt="check"
                        width="25px"
                        height="auto"
                        className="pr-1 pb-1"
                      />
                    )}
                    {subNav2.text}
                  </NavLink>
                );
              })}
            </div>
          </Accordion.Collapse>
        </Accordion>
      );
    }
    if (subNav.modal) {
      return (
        <NavLink
          key={subNav.id}
          to="#"
          className="dm-accordion-item dm-main-nav"
          style={{ color: '#64656a' }}
          onClick={() => { setShowModal(true); }}
        >
          {subNav.text}
        </NavLink>
      );
    }
    if (subNav.extLink) {
      return (
        <a
          key={subNav.id}
          className="dm-accordion-item dm-main-nav"
          style={{ color: '#64656a' }}
          href={subNav.href}
          target="_blank"
          rel="noreferrer"
          onClick={closeSidebar}
        >
          {subNav.text}&nbsp;
          <Icon
            name="up-right-from-square"
          />
        </a>
      );
    }
    
    return (
      <NavLink
        key={subNav.id}
        className="dm-accordion-item dm-main-nav"
        style={{ color: '#64656a' }}
        to={subNav.href}
        onClick={closeSidebar}
      >
        {subNav.preIcon && (
          <img
            src={require(`../../assets/images/${nav.preIcon}`)}
            alt="check"
            width="25px"
            height="auto"
            className="pr-1 pb-1"
          />
        )}
        {subNav.text}
      </NavLink>
    );
  };

  return (
    <Accordion>
      <NavLink
        to="/home"
        onClick={closeSidebar}
        className="mb-3 ml-2"
      >
        <label className="mt-2 mb-0">
          <span className="text-white font-weight-bold">Home</span>
        </label>
      </NavLink>
      {allNavs?.map((nav) => {
        return (
          <div
            key={nav.id}
            className="dm1-dropdown-container my-3 ml-2"
          >
            <Accordion.Toggle
              as={DivComponent}
              eventKey={nav.id}
              onClick={() => { return toggleAccordion(nav.id); }}
            >
              <div className={`dm-accordion-toggle dm-main-nav ${accordionOpen[nav.id] ? 'open' : ''}`}>
                {nav.preIcon && (
                  <img
                    src={require(`../../assets/images/${nav.preIcon}`)}
                    alt="check"
                    width="25px"
                    height="auto"
                    className="pr-1"
                  />
                )}
                {nav.title}
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={nav.id}>
              <div>
                {nav.items?.map((subNav: DMDropdownItemModel) => renderSubNav(nav, subNav))}
              </div>
            </Accordion.Collapse>
          </div>
        );
      })}
    </Accordion>
  );
};

export default AppSideNav;
