import React, { useContext, useEffect } from 'react';
import { CheckCode } from 'core/models';
import { getCheckCodes } from 'core/store/selectors';
import { useSelector } from 'react-redux';
import Icon from 'core/components/shared/Icon';
import Modal from 'core/components/modals/Modal';
import { useAppDispatch } from 'utilities/hooks';
import { handleError, loadCheckCodes } from 'core/store/actions';
import { TimeCardContext } from '../TimeCardContext';

type Props = {
  currentEmpCheckCodes: string[];
  show: boolean;
  onHide: () => void;
};

const CheckCodeModal = ({ currentEmpCheckCodes, show, onHide }: Props) => {
  const checkCodeOpts = useSelector(getCheckCodes);
  
  const { formMethods, updateDirtyState } = useContext(TimeCardContext);
  const { setValue } = formMethods;
  
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(loadCheckCodes());
  }, []);
  
  const onCheckCodeSelect = (opt: CheckCode) => {
    if (currentEmpCheckCodes?.includes(opt.checkCode)) {
      return dispatch(handleError(`Check code "${opt.checkCode}" already exists for employee on this payroll`));
    }
    
    updateDirtyState(true);
    setValue('checkCode', opt.checkCode);
    onHide();
  };
  
  return (
    <Modal
      title="Change Check Code"
      show={show}
      onHide={onHide}
    >
      <table
        className="table table-striped table-hover d-table"
      >
        <thead>
          <tr>
            <th>Check Code</th>
            <th>EFT</th>
            <th>Voluntary</th>
            <th>401K</th>
          </tr>
        </thead>
        <tbody>
          {checkCodeOpts.map((opt: CheckCode) => {
            return (
              <tr
                key={opt.checkCode}
                onClick={() => { onCheckCodeSelect(opt); }}
              >
                <td>{opt.checkCode}</td>
                <td>
                  {opt.eft ? (
                    <Icon
                      name="check"
                      color="green"
                    />
                  ) : (
                    <Icon
                      name="times"
                      color="red"
                    />
                  )}
                </td>
                <td>
                  {opt.voluntary ? (
                    <Icon
                      name="check"
                      color="green"
                    />
                  ) : (
                    <Icon
                      name="times"
                      color="red"
                    />
                  )}
                </td>
                <td>
                  {opt.four01K ? (
                    <Icon
                      name="check"
                      color="green"
                    />
                  ) : (
                    <Icon
                      name="times"
                      color="red"
                    />
                  )}
                </td>
              </tr>
            );
          },
          )}
        </tbody>
      </table>
    </Modal>
  );
};

export default CheckCodeModal;