import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'utilities/hooks';
import { updateDestination } from 'core/store/actions';

/**
 * Component for tracking app navigation. The creation of this was motivated by the need for a way of tracking user
 * paths/destinations to conditionally render buttons to return to other parts of the app (for example, returning to 
 * the HR profile or transmittal from employee master). Seeing that similar features will probably be requested in the
 * future, it made sense to just make something like this. The need for it may change when we upgrade to the next
 * react-router-dom version or change how we handle that altogether.
 * @returns null since it doesn't render anything. Mounted as a sibling of App in the root index file.
 */
const NavigationMonitor = () => {
  const history = useHistory();
  
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if (!(history && dispatch)) return;
    const cleanUpHistoryListener = history.listen((location, _) => {
      dispatch(updateDestination(location.pathname));
    });
    
    return () => {
      cleanUpHistoryListener();
    };
  }, [dispatch, history]);
  
  return null;
};

export default NavigationMonitor;