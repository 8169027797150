import { InputGrp } from 'core/components/form-controls';
import { RadioGrp, RadioOptions } from 'core/components/form-controls/RadioGrp';
import { DepartmentofLaborSetting, DeptOfLaborSettingsDetail } from 'core/models/HumanCapitalReports.model';
import {
  updateDepartmentofLaborSettings,
  createDepartmentofLaborSettings,
  deleteDepartmentofLaborSettings,
} from 'core/store/actions';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'utilities/hooks';
import LocationGrid from './LocationGrid';
import ComissionsGrid from './ComissionsGrid';
import DepartmentGrid from './DepartmentGrid';
import SubDepartments2Grid from './SubDepartments2Grid';
import SubDepartmentsGrid from './SubDepartmentsGrid';
import { DateTime } from 'luxon';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import Icon from 'core/components/shared/Icon';


type PropTypes = {
  allSettings?: DepartmentofLaborSetting[];
  setting?: DepartmentofLaborSetting;
  show: boolean;
  onHide: () => void;
};

const radioOpts: RadioOptions[] = [
  {
    value: 'Company',
    label: 'Company',
  },
  {
    value: 'Location',
    label: 'Location',
  },
  {
    value: 'Department',
    label: 'Department',
  },
  {
    value: 'SubDepartment',
    label: 'SubDepartment',
  },
  {
    value: 'SubDepartment2',
    label: 'SubDepartment2',
  },
];

const DepartmentOfLaborModal: React.FC<PropTypes> = ({ allSettings, setting, show, onHide }) => {
  const dispatch = useDispatch();
  const client = useAppSelector((state) => { return state.client.client; });
  const [selectedSetting, setSelectedSetting] = useState<DepartmentofLaborSetting>();
  const [locationData, setLocationData] = useState<string[]>(setting?.deptOfLaborSettingsDetails?.filter(s => { return s.divisionType === 'Location'; })?.map(s => { return s.code; }) ?? []);
  const [deptData, setDeptData] = useState<string[]>(setting?.deptOfLaborSettingsDetails?.filter(s => { return s.divisionType === 'Department'; })?.map(s => { return s.code; }) ?? []);
  const [subDeptData, setSubDeptData] = useState<string[]>(setting?.deptOfLaborSettingsDetails?.filter(s => { return s.divisionType === 'SubDepartment'; })?.map(s => { return s.code; }) ?? []);
  const [subDept2Data, setSubDept2Data] = useState<string[]>(setting?.deptOfLaborSettingsDetails?.filter(s => { return s.divisionType === 'SubDepartment2'; })?.map(s => { return s.code; }) ?? []);
  const [comissionsData, setComissionsData] = useState<string[]>(setting?.deptOfLaborSettingsDetails?.filter(s => { return s.divisionType === 'Commissions'; })?.map(s => { return s.code; }) ?? []);
  const [locCodesHaveChanged, setLocationCodes] = useState<boolean>(false);
  const [deptCodesHaveChanged, setDeptCodes] = useState<boolean>(false);
  const [subDeptCodesHaveChanged, setSubDeptCodes] = useState<boolean>(false);
  const [subDept2CodesHaveChanged, setSubDept2Codes] = useState<boolean>(false);
  const [comissionsCodesHaveChanged, setComissionCodes] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { register, handleSubmit, setError, errors, formState } = useForm<DepartmentofLaborSetting>({
    defaultValues: setting,
  });

  useEffect(() => {
    setting && setSelectedSetting(setting);
  }, [setting]);

  const getDataFromLocationGrid = (data: any, modified: boolean) => {
    setLocationData(data);
    setLocationCodes(modified);
  };
  const getDataFromDeptGrid = (data: any, modified: boolean) => {
    setDeptData(data);
    setDeptCodes(modified);
  };
  const getDataFromSubDeptGrid = (data: any, modified: boolean) => {
    setSubDeptData(data);
    setSubDeptCodes(modified);
  };
  const getDataFromSubDept2Grid = (data: any, modified: boolean) => {
    setSubDept2Data(data);
    setSubDept2Codes(modified);
  };
  const getDataFromComissionsGrid = (data: any, modified: boolean) => {
    setComissionsData(data);
    setComissionCodes(modified);
  };

  const buildDeptOfLaborSettingsDetailsArray = () => {
    const locations = locationData?.length ? [
      ...locationData.map(
        (i) => {
          const detail = selectedSetting?.deptOfLaborSettingsDetails?.find((s) => {
            return s.code === '' + i
              && s.divisionType === 'Location';
          });
          return {
            settingID: selectedSetting?.settingID,
            code: '' + i,
            settingDetailID: detail?.settingDetailID ?? 0,
            divisionType: 'Location',
          };

        }) as unknown as DeptOfLaborSettingsDetail[],
    ] : [];

    const depts = deptData?.length ? [
      ...deptData.map(
        (i) => {
          const detail = selectedSetting?.deptOfLaborSettingsDetails?.find((s) => {
            return s.code === '' + i &&
              s.divisionType === 'Department';
          });
          return {
            settingID: selectedSetting?.settingID,
            code: '' + i,
            settingDetailID: detail?.settingDetailID ?? 0,
            divisionType: 'Department',
          };

        }) as unknown as DeptOfLaborSettingsDetail[],
    ] : [];

    const subDepts = subDeptData?.length ? [
      ...subDeptData.map(
        (i) => {
          const detail = selectedSetting?.deptOfLaborSettingsDetails?.find((s) => {
            return s.code === '' + i &&
              s.divisionType === 'SubDepartment';
          });
          return {
            settingID: selectedSetting?.settingID,
            code: '' + i,
            settingDetailID: detail?.settingDetailID ?? 0,
            divisionType: 'SubDepartment',
          };

        }) as unknown as DeptOfLaborSettingsDetail[],
    ] : [];

    const subDepts2 = subDept2Data?.length ? [
      ...subDept2Data.map(
        (i) => {
          const detail = selectedSetting?.deptOfLaborSettingsDetails?.find((s) => {
            return s.code === '' + i &&
              s.divisionType === 'SubDepartment2';
          });
          return {
            settingID: selectedSetting?.settingID,
            code: '' + i,
            settingDetailID: detail?.settingDetailID ?? 0,
            divisionType: 'SubDepartment2',
          };

        }) as unknown as DeptOfLaborSettingsDetail[],
    ] : [];

    const comissions = comissionsData?.length ? [
      ...comissionsData.map(
        (i) => {
          const detail = selectedSetting?.deptOfLaborSettingsDetails?.find((s) => {
            return s.code === '' + i &&
              s.divisionType === 'Commissions';
          });
          return {
            settingID: selectedSetting?.settingID,
            code: '' + i,
            settingDetailID: detail?.settingDetailID ?? 0,
            divisionType: 'Commissions',
          };

        }) as unknown as DeptOfLaborSettingsDetail[],
    ] : [];


    const merged = [...locations, ...depts, ...subDepts, ...subDepts2, ...comissions];
    return merged;
  };

  const onSubmit = (data: DepartmentofLaborSetting) => {
    const findId = allSettings?.find(s => { return s.settingName === data.settingName; })?.settingID;
    if (findId && findId !== +data.settingID) {
      setError('settingName', {
        type: 'SameValueError',
        message: 'This Setting Title already exists.',
      });
      return;
    }
    const details = buildDeptOfLaborSettingsDetailsArray();

    const request: DepartmentofLaborSetting = {
      settingID: +data.settingID || 0,
      settingName: data.settingName,
      clientNo: client?.clientNo!,
      userID: setting?.userID ?? '',
      dateFrom: DateTime.local().toISO(),
      dateTo: DateTime.local().toISO(),
      groupBy: data.groupBy,
      deptOfLaborSettingsDetails: details,
    };

    request.settingID > 0 ?
      dispatch(updateDepartmentofLaborSettings(request)) :
      dispatch(createDepartmentofLaborSettings(request));
  };

  const onDelete = () => {
    selectedSetting && dispatch(deleteDepartmentofLaborSettings(selectedSetting));
  };

  const closeModal = (e?: any) => {
    e?.stopPropagation();

    if (locCodesHaveChanged === true || deptCodesHaveChanged === true || subDeptCodesHaveChanged === true
      || subDept2CodesHaveChanged === true || comissionsCodesHaveChanged === true || formState.isDirty) {
      setShowConfirmationModal(true);
    } else {
      setShowConfirmationModal(false);
      onHide();
    }
  };

  const confirmSubmit = (confirmed: boolean) => {
    if (confirmed) {
      onHide();
    }
  };


  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal show={show}
        onHide={onHide}
        size="lg"
        animation={false}>
        <div className="modal-header" >
          <div className="dm-grid-action-title mb-2">Configure Report Setting</div>
          <button type="button"
            onClick={closeModal}>
            <Icon name="times"
              className="modal-close-btn" />
          </button>
        </div>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="hidden"
              name={'settingID'}
              ref={register()}
              defaultValue={selectedSetting?.settingID}
            />
            <div className="d-flex mt-2">
              <div className="d-flex flex-row flex-grow-1 ">
                <div className="d-flex flex-column mr-4">
                  <InputGrp
                    style={{ width: '150px' }}
                    label="Setting Title"
                    name="settingName"
                    required={true}
                    ref={register({ required: 'Setting Title is Required' })}
                    errors={errors.settingName}
                    placeholder="Add Title of Setting Name"
                  />
                </div>
                <div className="d-flex flex-column flex-grow-1">
                  <div className="dm-grid-action-title mb-2">Group by</div>
                  <div className="d-flex flex-row flex-grow-1 w-50 justify-content-between">
                    <RadioGrp
                      radioOptions={radioOpts}
                      name="groupBy"
                      ref={register()}
                      defaultChecked={'Company'}
                    />
                  </div>
                  <div className="d-flex">
                    <div className="d-flex flex-row flex-wrap">
                      <div className="d-flex flex-row flex-wrap">
                        <div className="ag-theme-balham mr-3 mb-3"
                          style={{
                            height: '250px',
                            width: '200px',
                            marginTop: '17px',
                          }}>
                          <strong>Select Locations</strong>
                          <LocationGrid passDataFromLocationGrid={getDataFromLocationGrid}
                            codes={locationData}
                          />
                        </div>
                        <div className="ag-theme-balham mr-3 mb-3"
                          style={{
                            height: '250px',
                            width: '200px',
                            marginTop: '17px',
                          }}>
                          <strong>Select Departments</strong>
                          <DepartmentGrid passDataFromDeptGrid={getDataFromDeptGrid}
                            codes={deptData}
                          />
                        </div>
                        <div className="ag-theme-balham mr-3 mb-3"
                          style={{
                            height: '250px',
                            width: '200px',
                            marginTop: '17px',
                          }}>
                          <strong>Select Sub Departments</strong>
                          <SubDepartmentsGrid passDataFromSubDeptGrid={getDataFromSubDeptGrid}
                            codes={subDeptData}
                          />
                        </div>
                        <div className="ag-theme-balham mr-3 mt-3"
                          style={{
                            height: '250px',
                            width: '200px',
                            marginTop: '17px',
                          }}>
                          <strong>Select Sub Departments 2</strong>
                          <SubDepartments2Grid passDataFromSubDept2Grid={getDataFromSubDept2Grid}
                            codes={subDept2Data}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row flex-wrap">
                        <div className="ag-theme-balham mt-3"
                          style={{
                            height: '250px',
                            width: '200px',
                            marginTop: '17px',
                          }}>
                          <strong>Identify Code for Commissions</strong>
                          <ComissionsGrid passDataFromComissionsGrid={getDataFromComissionsGrid}
                            codes={comissionsData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-grow-1 text-right mt-4">
              <button
                type="button"
                className="btn btn-primary orange-outline-button ml-auto mr-2"
                onClick={() => { onDelete(); onHide(); }}
                disabled={!selectedSetting?.settingID}
              >
                Delete
              </button>
              <button type="submit"
                className="orange-button mr-2">
                Save
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {showConfirmationModal && (
        <ConfirmationModal
          title="Settings have changed"
          message={'You have made changes, are you sure you want to close?'}
          show={showConfirmationModal}
          onConfirmed={confirmSubmit}
          onHide={() => { return setShowConfirmationModal(false); }} />
      )}
    </div >




  );
};

export default DepartmentOfLaborModal;