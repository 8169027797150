import Icon from 'core/components/shared/Icon';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Overlay, OverlayTrigger, Popover } from 'react-bootstrap';

type PropTypes = {
  setSearchText: (searchText:string | null) => void;
};

const ApplicantSearch: React.FC<PropTypes> = ({ setSearchText }) => {

  return ( 
    <div>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={      
          <Popover id="popover-search">
            <Popover.Title
              bsPrefix="dm-primary-blue"
              style={{
                fontSize: '18px',
                fontWeight: 600,
                color: '#00558',
                padding: '10px 15px',
              }}
            >
              Search for an Applicant          
            </Popover.Title>
            <Popover.Content>
              <form>
                <div className="form-group">
                  <label className="form-label">
                    First or Last Name
                  </label>
                  <input
                    autoFocus
                    type="text"
                    className="form-control"
                    name="searchText"
                    onChange={(e: any) => {return setSearchText(e.target.value);}}
                  />
                </div> 
              </form>
            </Popover.Content>
          </Popover>
        }
      >
        <Button
          variant="link"
          onClick={(e: any) => {return setSearchText(null);}}
        > 
          <Icon
            name={'search'}
            style={{
              padding: 0,
              marginRight: 10,
            }}
          /> Search
        </Button>      
      </OverlayTrigger>
    </div>
  );
};

export default ApplicantSearch;
