import { AxiosResponse } from 'axios';
import { CreateOnboardEmployee, EmailTemplate, Employee, OnboardingUpdate, OnboardPayrollUser, HttpResponse, ResendOnboardingEmail, ResendOnboardingEmailRequest, JazzHRReturnData, JazzHRIntegrationRequest, JazzHRApplicantDetail, OnboardingSteps } from '../models';
import { OnboardDoc } from '../models/OnboardDoc.model';
import { PageConfig } from '../models/PageConfig.model';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getNextEmployeeNumber = (): Promise<AxiosResponse<any>> => { return API_EMPLOYEE_MASTER.get<any>(EmployeeEndPoints.GET_NEXT_EMPLOYEE_NUMBER(CommonService.getProtectedClientNo())); };

const getOnboardPayrollUsers = (): Promise<AxiosResponse<OnboardPayrollUser[]>> => { return API_EMPLOYEE_MASTER.get<OnboardPayrollUser[]>(EmployeeEndPoints.GET_ONBOARD_PAYROLL_USERS(CommonService.getProtectedClientNo())); };

const getJazzHRApplicants = (): Promise<AxiosResponse<JazzHRReturnData>> => { return API_EMPLOYEE_MASTER.get<JazzHRReturnData>(EmployeeEndPoints.GET_ONBOARD_JAZZHR_APPLICANTS(CommonService.getProtectedClientNo())); };

const postOnboardEmployee = (onboardEmp: CreateOnboardEmployee): Promise<AxiosResponse<HttpResponse<Employee>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<Employee>>(EmployeeEndPoints.POST_ONBOARD_EMPLOYEE(CommonService.getProtectedClientNo()), onboardEmp); };

const getOnboardDocuments = (): Promise<AxiosResponse<OnboardDoc[]>> => { return API_EMPLOYEE_MASTER.get<OnboardDoc[]>(EmployeeEndPoints.GET_ONBOARD_DOCS(CommonService.getProtectedClientNo())); };

const getOnboardPageConfigs = (): Promise<AxiosResponse<PageConfig[]>> => { return API_EMPLOYEE_MASTER.get<PageConfig[]>(EmployeeEndPoints.GET_ONBOARD_PAGE_CONFIGS(CommonService.getProtectedClientNo())); };

const putOnboardPageConfigs = (pageConfigs: PageConfig[]): Promise<AxiosResponse<PageConfig[]>> => { return API_EMPLOYEE_MASTER.put<PageConfig[]>(EmployeeEndPoints.PUT_ONBOARD_PAGE_CONFIGS(CommonService.getProtectedClientNo()), pageConfigs); };

const getOnboardEmailTemplates = (): Promise<AxiosResponse<EmailTemplate[]>> => { return API_EMPLOYEE_MASTER.get<EmailTemplate[]>(EmployeeEndPoints.GET_ONBOARD_EMAIL_TEMPLATES(CommonService.getProtectedClientNo())); };

const postOnboardEmailTemplates = (emailTemplateS: EmailTemplate): Promise<AxiosResponse<EmailTemplate[]>> => { return API_EMPLOYEE_MASTER.post<EmailTemplate[]>(EmployeeEndPoints.POST_ONBOARD_EMAIL_TEMPLATES(CommonService.getProtectedClientNo()), emailTemplateS); };

const deleteOnboardEmailTemplate = (credentialEmailID: number): Promise<AxiosResponse<any>> => { return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.DELETE_ONBOARD_EMAIL_TEMPLATE(CommonService.getProtectedClientNo(), credentialEmailID)); };

const postOnboardUpdates = (updates: OnboardingUpdate[]): Promise<AxiosResponse<OnboardingUpdate[]>> => { return API_EMPLOYEE_MASTER.post<OnboardingUpdate[]>(EmployeeEndPoints.POST_ONBOARD_UPDATES(CommonService.getProtectedClientNo()), updates); };

const postJazzHRIntegration = (data: JazzHRIntegrationRequest): Promise<AxiosResponse<JazzHRApplicantDetail>> => { return API_EMPLOYEE_MASTER.post<JazzHRApplicantDetail>(EmployeeEndPoints.POST_ONBOARD_JAZZHR_INTEGRATION(CommonService.getProtectedClientNo()), data); };

const postOnboardResend = (request: ResendOnboardingEmail): Promise<AxiosResponse<HttpResponse<any>>> => { 
  return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(
    EmployeeEndPoints.POST_ONBOARD_RESEND(CommonService.getProtectedClientNo(), request.applicantId)); 
};

const postResendOnboardEmailFromEM = (request: ResendOnboardingEmailRequest) => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(
    EmployeeEndPoints.POST_RESEND_ONBOARD_EMAIL(CommonService.getProtectedClientNo(), request.protectedEmpNo),
    request,
  );
};

const getOnboardingStepsStatus = (protectedEmpNo: string): Promise<AxiosResponse<OnboardingSteps>> => 
  { return API_EMPLOYEE_MASTER.get<OnboardingSteps>(EmployeeEndPoints.GET_ONBOARDING_STEPS(CommonService.getProtectedClientNo(), protectedEmpNo)); };


export const OnboardService = {
  getNextEmployeeNumber,
  getOnboardPayrollUsers,
  getJazzHRApplicants,
  postJazzHRIntegration,
  postOnboardEmployee,
  getOnboardDocuments,
  getOnboardPageConfigs,
  putOnboardPageConfigs,
  getOnboardEmailTemplates,
  postOnboardEmailTemplates,
  deleteOnboardEmailTemplate,
  postOnboardUpdates,
  postOnboardResend,
  postResendOnboardEmailFromEM,
  getOnboardingStepsStatus,
};
