import React from 'react';
import { useSelector } from 'react-redux';
import { ControlDatePickerGrp, SelectGrp } from 'core/components/form-controls';
import { FieldInputSettings, FormMethods } from 'core/components/form-controls/types';
import {
  getWorkgroupDepartments,
  getWorkgroupLocations,
  getWorkgroupPositions,
  getWorkgroupSupervisors,
  getWorkgroupTasks,
} from 'core/store/selectors';
import { useAppSelector } from 'utilities/hooks';
import { AttendanceOnDemand } from 'core/models';

const fs: FieldInputSettings = {
  workgroupAssignmentDate: { name: 'workgroupAssignmentDate',
    label: 'ASSIGNMENT DATE',
    groupClass: 'gc95 mw400' },
  workgroup1: { name: 'workgroup1',
    label: 'LOCATION',
    groupClass: 'gc95 mw400' },
  workgroup2: { name: 'workgroup2',
    label: 'DEPARTMENT',
    groupClass: 'gc95 mw400' },
  workgroup3: { name: 'workgroup3',
    label: 'LINE',
    groupClass: 'gc95 mw400' },
  workgroup4: { name: 'workgroup4',
    label: 'POSITION',
    groupClass: 'gc95 mw400' },
  workgroup5: { name: 'workgroup5',
    label: 'LEVEL 5',
    groupClass: 'gc95 mw400' },
  workgroup6: { name: 'workgroup6',
    label: 'LEVEL 6',
    groupClass: 'gc95 mw400' },
  workgroup7: { name: 'workgroup7',
    label: 'LEVEL 7',
    groupClass: 'gc95 mw400' },
};

type PropTypes = {
  AOD: AttendanceOnDemand  | null;
  formMethods: Omit<FormMethods, 'handleSubmit' | 'reset'>;
};

const AttendanceOnDemandWorkgroups: React.FC<PropTypes> = ({AOD, formMethods: { setValue, errors, register, watch, control } }) => {
  const { workgroupAssignmentDate } = watch(['workgroupAssignmentDate']);

  const clientAod = useAppSelector(({ selEmployeeDetails }) => selEmployeeDetails.attendanceOnDemand.clientAttendanceOnDemand);
  const locationOpts = useSelector(getWorkgroupLocations);
  const departmentOpts = useSelector(getWorkgroupDepartments);
  const supervisorOpts = useSelector(getWorkgroupSupervisors);
  const tasksOpts = useSelector(getWorkgroupTasks);
  const positionOpts = useSelector(getWorkgroupPositions);

  return (
    <div className="dm-panel dm-panel-border pb-4" >
      <div className="dm-card-subtitle2 mb-2">Workgroups</div>
      <div className="d-flex flex-row flex-wrap">
        <div className="d-flex flex-column">
          <ControlDatePickerGrp
            {...fs.workgroupAssignmentDate}
            errors={errors.workgroupAssignmentDate}
            value={workgroupAssignmentDate}
            setValue={setValue}
            control={control}
          />
          {clientAod?.showWorkgroup1 && locationOpts ? <SelectGrp
            {...fs.workgroup1}
            errors={errors.workgroup1}
            ref={register}
            options={locationOpts}
          />: 
          <input name={fs.workgroup1.name} type="hidden" ref={register} defaultValue={AOD?.workgroup1 || 0}/>}

          {clientAod?.showWorkgroup2 && departmentOpts ? <SelectGrp
            {...fs.workgroup2}
            errors={errors.workgroup2}
            ref={register}
            options={departmentOpts}
          /> : <input name={fs.workgroup2.name} type="hidden" ref={register} defaultValue={AOD?.workgroup2 || 0}/>}

          {clientAod?.showWorkgroup3 && tasksOpts ? <SelectGrp
            {...fs.workgroup3}
            errors={errors.workgroup3}
            ref={register}
            options={tasksOpts}
          /> : <input name={fs.workgroup3.name} type="hidden" ref={register} defaultValue={AOD?.workgroup3 || 0}/>}

          {clientAod?.showWorkgroup4 && positionOpts ? <SelectGrp
            {...fs.workgroup4}
            errors={errors.workgroup4}
            ref={register}
            options={positionOpts}
          /> : <input name={fs.workgroup4.name} type="hidden" ref={register} defaultValue={AOD?.workgroup4 || 0}/>}

          {clientAod?.showWorkgroup5 && supervisorOpts ? <SelectGrp
            {...fs.workgroup5}
            errors={errors.workgroup5}
            ref={register}
            options={supervisorOpts}
          /> : <input name={fs.workgroup5.name} type="hidden" ref={register} defaultValue={AOD?.workgroup5 || 0}/>}

          {clientAod?.showWorkgroup6 && supervisorOpts ? <SelectGrp
            {...fs.workgroup6}
            errors={errors.workgroup6}
            ref={register}
            options={supervisorOpts}
          /> : <input name={fs.workgroup6.name} type="hidden" ref={register} defaultValue={AOD?.workgroup6 || 0}/>}
          
          {clientAod?.showWorkgroup7 && supervisorOpts ? <SelectGrp
            {...fs.workgroup7}
            errors={errors.workgroup7}
            ref={register}
            options={supervisorOpts}
          /> : <input name={fs.workgroup7.name} type="hidden" ref={register} defaultValue={AOD?.workgroup7 || 0}/>}
        </div>
      </div>
    </div>
  );
};

export default AttendanceOnDemandWorkgroups;
