import Icon from 'core/components/shared/Icon';
import { TrainingHistory } from 'core/models';
import { TO_NAME, convertRegion } from 'core/states';
import React, { useState } from 'react';
import { Accordion, Card, Col, Row, Tab, Tabs } from 'react-bootstrap';

type Props = {
  histories: TrainingHistory[]
};

const CustomTrainingHistory = ({
  histories,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header className="p-3">
          <Accordion.Toggle
            eventKey="6"
            style={{
              background: 'transparent',
              border: 'none',
            }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <Icon
              name={isOpen ? 'chevron-up' : 'chevron-down'}
              className="mr-1"
            />
            Training History
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="6">
          <Card.Body
            className="p-3"
            style={{ gap: '4rem' }}
          >
            <Tabs defaultActiveKey={histories?.[0]?.nameOfInstitution}>
              {histories.map(history => {
                return (
                  <Tab
                    key={history?.trainingHistoryId}
                    eventKey={history?.nameOfInstitution}
                    title={history?.nameOfInstitution}
                    className="py-4"
                  >
                    <Row>
                      {history.nameOfInstitution && (
                        <Col sm={2}>
                          <p className="font-weight-bold mb-0">Name of Institution</p>
                          <p className="mb-0">{history.nameOfInstitution}</p>
                        </Col>
                      )}
                      <Col sm={2}>
                        <p className="font-weight-bold mb-0">Address</p>
                        {history.streetAddress1 && (
                          <p className="mb-0">{history.streetAddress1}</p>
                        )}
                        {history.streetAddress2 && (
                          <p className="mb-0">{history.streetAddress2}</p>
                        )}
                        {(history.city || history.state) && (
                          <p className="mb-0">{history.city} {history.state && `, ${convertRegion(history.state, TO_NAME)}`}</p>
                        )}
                        {history.zip && (
                          <p className="mb-0">{history.zip}</p>
                        )}
                      </Col>
                      <Col sm={2}>
                        <p className="font-weight-bold mb-0">Graduated</p>
                        <p className="mb-0">{history.graduated ? 'True' : 'False'}</p>
                      </Col>
                      <Col sm={2}>
                        <p className="font-weight-bold mb-0">Degree/Certificate</p>
                        <p className="mb-0">{history.degreesEarned}</p>
                      </Col>
                      <Col sm={2}>
                        <p className="font-weight-bold mb-0">Type of Training</p>
                        <p className="mb-0">{history.typeOfTraining || ''}</p>
                      </Col>
                      <Col sm={1}>
                        <p className="font-weight-bold mb-0">Courses Taken</p>
                        <p className="mb-0">{history.coursesTaken || ''}</p>
                      </Col>
                      <Col sm={1}>
                        <p className="font-weight-bold mb-0">Explanation (if necessary):</p>
                        <p className="mb-0">{history.explanation || ''}</p>
                      </Col>
                    </Row>
                  </Tab>
                );
              })}
            </Tabs>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default CustomTrainingHistory;