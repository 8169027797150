/* eslint-disable react/display-name */
import React from 'react';
import styles from './form-controls.module.scss';
import { CalendarContainerProps, CalendarContainer } from 'react-datepicker';
import { ShortCutKey } from 'utilities/utilities';

type CustomContainerProps = {
  shortcutOpt: ShortCutKey | null;
  handleShortcutClick: (option: ShortCutKey) => void;
};

const DateRangeShortcuts = ({ shortcutOpt, handleShortcutClick }: CustomContainerProps) =>
  ({ children }: CalendarContainerProps) => {
    
    const handleOptionClick = (option: ShortCutKey) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      handleShortcutClick(option);
      e.stopPropagation();
    };
    
    return (
      <div className={styles['date-range-popover']}>
        <div className={styles['date-range-shortcut-container']}>
          <button
            className="btn btn-link"
            onClick={handleOptionClick('thisWeek')}
            disabled={shortcutOpt === 'thisWeek'}
          >
            This week
          </button>
          <button
            className="btn btn-link"
            onClick={handleOptionClick('lastWeek')}
            disabled={shortcutOpt === 'lastWeek'}
          >
            Last week
          </button>
          <button
            className="btn btn-link"
            onClick={handleOptionClick('thisMonth')}
            disabled={shortcutOpt === 'thisMonth'}
          >
            This month
          </button>
          <button
            className="btn btn-link"
            onClick={handleOptionClick('lastMonth')}
            disabled={shortcutOpt === 'lastMonth'}
          >
            Last month
          </button>
          <button
            className="btn btn-link"
            onClick={handleOptionClick('nextMonth')}
            disabled={shortcutOpt === 'nextMonth'}
          >
            Next month
          </button>
          <button
            className="btn btn-link"
            onClick={handleOptionClick('last30Days')}
            disabled={shortcutOpt === 'last30Days'}
          >
            Last 30 days
          </button>
          <button
            className="btn btn-link"
            onClick={handleOptionClick('last60Days')}
            disabled={shortcutOpt === 'last60Days'}
          >
            Last 60 days
          </button>
          <button
            className="btn btn-link"
            onClick={handleOptionClick('last90Days')}
            disabled={shortcutOpt === 'last90Days'}
          >
            Last 90 days
          </button>
          <button
            className="btn btn-link"
            onClick={handleOptionClick('last12mnths')}
            disabled={shortcutOpt === 'last12mnths'}
          >
            Last 12 months
          </button>
          <button
            className="btn btn-link"
            onClick={handleOptionClick('thisYear')}
            disabled={shortcutOpt === 'thisYear'}
          >
            This year
          </button>
          <button
            className="btn btn-link"
            onClick={handleOptionClick('lastYear')}
            disabled={shortcutOpt === 'lastYear'}
          >
            Last year
          </button>
          <button
            className="btn btn-link"
            onClick={handleOptionClick('nextYear')}
            disabled={shortcutOpt === 'nextYear'}
          >
            Next year
          </button>
        </div>
        <CalendarContainer className="react-datepicker__month-container2">
          <div className={styles['datepicker-wrapper']}>
            {children}
          </div>
        </CalendarContainer>
      </div>
    );
  };

export default DateRangeShortcuts;
