import React, { useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import {
  AllModules,
  ColDef,
  GridApi,
  GridOptions,
  ValueFormatterParams,
} from '@ag-grid-enterprise/all-modules';
import { Col, Row } from 'react-bootstrap';
import { VerifyResult } from '../../../../core/models';
import Modal from 'core/components/modals/Modal';
import Icon from 'core/components/shared/Icon';
import { CommonService } from 'core/services';

const valueFormatter = (params: ValueFormatterParams) => {
  if (params.value) {
    return params.value.toFixed(2);
  }
  else return '0.00'
}

//Will return red if the total value is not equal to the value passed in
const otherColumnsColor = (totalValue: number, value: number) => {
  if(totalValue !== value) return '#f08080';
  else return '';
};

const columns: ColDef[] = [
  {
    field: 'employee',
    headerName: 'Employee',
    sortable: true,
    width: 140,
  },
  {
    field: 'totalEarnings',
    headerName: 'Total Earnings',
    sortable: true,
    width: 120,
    valueFormatter: valueFormatter,
    cellStyle: params => {
      const color = otherColumnsColor(params.data.totalEarnings, params.data.earnings);
      return { backgroundColor: color, 'justify-content': 'right' };
    },
  },
  {
    field: 'earnings',
    headerName: 'Earnings',
    sortable: true,
    width: 100,
    valueFormatter: valueFormatter,
    cellStyle: params => {
      const color = otherColumnsColor(params.data.totalEarnings, params.data.earnings);
      return { backgroundColor: color, 'justify-content': 'right' };
    },
  },
  {
    field: 'totalHours',
    headerName: 'Total Hours',
    sortable: true,
    width: 120,
    valueFormatter: valueFormatter,
    cellStyle: params => {
      const color = otherColumnsColor(params.data.totalHours, params.data.hours);
      return { backgroundColor: color, 'justify-content': 'right' };
    },
  },
  {
    field: 'hours',
    headerName: 'Hours',
    sortable: true,
    width: 100,
    valueFormatter: valueFormatter,
    cellStyle: params => {
      const color = otherColumnsColor(params.data.totalHours, params.data.hours);
      return { backgroundColor: color, 'justify-content': 'right' };
    },
  },
  {
    field: 'totalDeductions',
    headerName: 'Total Deduction',
    sortable: true,
    width: 130,
    valueFormatter: valueFormatter,
    cellStyle: params => {
      const color = otherColumnsColor(params.data.totalDeductions, params.data.dedAmount);
      return { backgroundColor: color, 'justify-content': 'right' };
    },
  },
  {
    field: 'dedAmount',
    headerName: 'Deduction Amount',
    sortable: true,
    width: 140,
    valueFormatter: valueFormatter,
    cellStyle: params => {
      const color = otherColumnsColor(params.data.totalDeductions, params.data.dedAmount);
      return { backgroundColor: color, 'justify-content': 'right' };
    },
  },
  {
    field: 'totalStateWh',
    headerName: 'Total State Wh',
    sortable: true,
    width: 120,
    valueFormatter: valueFormatter,
    cellStyle: params => {
      const color = otherColumnsColor(params.data.totalStateWh, params.data.stateWh);
      return { backgroundColor: color, 'justify-content': 'right' };
    },
  },
  {
    field: 'stateWh',
    headerName: 'State Wh',
    sortable: true,
    width: 110,
    valueFormatter: valueFormatter,
    cellStyle: params => {
      const color = otherColumnsColor(params.data.totalStateWh, params.data.stateWh);
      return { backgroundColor: color, 'justify-content': 'right' };
    },
  },
  {
    field: 'totalLocalWh',
    headerName: 'Total Local Wh',
    sortable: true,
    width: 120,
    valueFormatter: valueFormatter,
    cellStyle: params => {
      const color = otherColumnsColor(params.data.totalLocalWh, params.data.localWh);
      return { backgroundColor: color, 'justify-content': 'right' };
    },
  },
  {
    field: 'localWh',
    headerName: 'Local Wh',
    sortable: true,
    width: 120,
    valueFormatter: valueFormatter,
    cellStyle: params => {
      const color = otherColumnsColor(params.data.totalLocalWh, params.data.localWh);
      return { backgroundColor: color, 'justify-content': 'right' };
    },
  },
];

const gridOptions: GridOptions = {
  columnDefs: columns,
  defaultColDef: {
    suppressMenu: true,
    resizable: true,
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
};

type PropTypes = {
  show: boolean;
  onHide: () => void;
  data: VerifyResult
};

const ValidationAdjustmentErrorsModal: React.FC<PropTypes> = ({
  show,
  onHide,
  data
}) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [, setGridColumnApi] = useState(null);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onExport = () => {
    if (data && data.validationErrorReport) {
      CommonService.downloadBase64File(
        'application/excel',
        data.validationErrorReport,
        'AdjustmentValidationErrors.xlsx',
      );
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
      title="Validation Adjustment Errors"
    >
      {(closeModal) => {
        return (
          <>
            <div className="d-flex flex-row pb-2">
              <div>
                <button
                className="btn btn-link dm-grid-action-title py-0" 
                onClick={onExport}>
                  Export to Excel{' '}
                  <Icon name="file-excel"
                    className="fa-plus-circle" />
                </button>
              </div>
            </div>
            <Row>
              <Col
                xs={12}
                className="table-wrapper-wrapper ag-theme-balham"
              >
                <AgGridReact
                  gridOptions={gridOptions}
                  rowData={data.validationErrors}
                  modules={AllModules}
                  onGridReady={onGridReady}
                />
                <div className="d-flex mt-3">
                  <button
                    className="orange-outline-button ml-auto mr-2"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </Col>
            </Row>
          </>
        );
      }}

    </Modal>
  );
};

export default ValidationAdjustmentErrorsModal;
