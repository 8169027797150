import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { YTDEarning } from '../../../core/models';
import { getEarningsCodes } from '../../../core/store/selectors';
import { currencyFormatter, ddLookup } from '../../../utilities/utilities';

type PropTypes = {
  items: YTDEarning[] | undefined;
};

const YTDEarnings: React.FC<PropTypes> = ({ items }) => {
  const earningsCodeOpts = useSelector(getEarningsCodes);

  const [totalHours, setTotalHours] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);

  useEffect(() => {
    if (items) {
      setTotalHours(items.reduce((acc, item) => {return acc + item.hours;}, 0));
      setTotalEarnings(items.reduce((acc, item) => {return acc + item.earnings;}, 0));
    }
  }, [items]);

  return (<>
    <div className="mr-3" style={{ minWidth: '300px' }}>
      <h3>Earnings</h3>
      <table className="table table-sm w-100">
        <colgroup>
          <col style={{ width: '15%' }} />
          <col style={{ width: '35%' }} />
          <col style={{ width: '18%' }} />
          <col style={{ width: '32%' }} />
        </colgroup>
        <thead className="thead-light">
          <tr>
            <th>Pay Type</th>
            <th>Code Desc</th>
            <th>Hours</th>
            <th>Earnings</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item: YTDEarning, index: number) => {
            return (
              <tr key={index}>
                <td>{item.payType}</td>
                <td>{ddLookup(item.payType, earningsCodeOpts)}</td>
                <td className="text-right">{item.hours.toFixed(2)}</td>
                <td className="text-right">{currencyFormatter(item.earnings)}</td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={2} > Total Compensation:</td>
            <td className="text-right font-weight-bold">{currencyFormatter(totalHours)}</td>
            <td className="text-right font-weight-bold">{currencyFormatter(totalEarnings)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </>);
};

export default YTDEarnings;
