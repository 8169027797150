import React, { useState } from 'react';
import Modal from 'core/components/modals/Modal';
import { useAppDispatch } from 'utilities/hooks';
import { recoverProcessedPayroll } from 'core/store/actions';
import { convToDateString } from 'utilities/utilities';

type Props = {
  show: boolean;
  onHide: () => void;
  onSubmitRecovery: () => void;
  payrollHistoryId: number;
  weekEnd: string;
  checkDate: string;
  clientNo: number;
};

const RecoveryModal = ({ show, onHide, onSubmitRecovery, payrollHistoryId, weekEnd, checkDate, clientNo }: Props) => {
  const [shouldChargeClient, setShouldChargeClient] = useState(false); 
  
  const dispatch = useAppDispatch();

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };
  
  const onSubmit = () => {
    dispatch(recoverProcessedPayroll({
      payrollHistoryId,
      clientNo,
      chargeClientForRecovery: shouldChargeClient,
    }));
    onSubmitRecovery();
    closeModal();
  };
  
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        title="Recover Payroll"
      >
        <div className="d-flex flex-column">
          <div className="d-flex flex-column">
            <div className="d-flex">
              <span>Does this client get charged for re-running this payroll?&nbsp;</span>
              <span className="d-flex align-items-center ml-2">
                <input
                  type="checkbox"
                  checked={shouldChargeClient}
                  onChange={(e) => { setShouldChargeClient(e.target.checked); }}
                />
              </span>
            </div>
            <div>
              <strong>Note:&nbsp;</strong>Leaving this unchecked will not charge the client and will run a free payroll.
            </div>
          </div>
          <div className="pt-3">
            You are about to recover the payroll for <strong>
              Week Ending&nbsp;{convToDateString(new Date(weekEnd))},&nbsp;Check Date
              &nbsp;{convToDateString(new Date(checkDate))}.&nbsp;</strong>
            Click submit to continue or cancel to exit.
          </div>
          <div className="d-flex w-100 justify-content-end pt-3">
            <button
              className="btn orange-outline-button"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary orange-button ml-2"
              onClick={onSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RecoveryModal;