export type JobError = {
  id: number;
  jobNo: string;
  empNo: number;
  name: string;
  totalHours: number;
  regular: number;
  timeHalf: number;
  double: number;
  otherHours: number;
  dedAmount: number;
  otherEarnings: number;
  isReviewed: boolean;
  description: string;
  notes: string;
};

export const dummyErrorData: JobError[] = [
  {
    id: 0,
    jobNo: '100',
    empNo: 1,
    name: '',
    totalHours: 0,
    regular: 0,
    timeHalf: 0,
    double: 0,
    otherHours: 0,
    dedAmount: 0,
    otherEarnings: 0,
    isReviewed: true,
    description: 'Some other error',
    notes: '',
  },
  {
    id: 1,
    jobNo: '100',
    empNo: 1,
    name: '',
    totalHours: 0,
    regular: 0,
    timeHalf: 0,
    double: 0,
    otherHours: 0,
    dedAmount: 0,
    otherEarnings: 0,
    isReviewed: false,
    description: 'error2',
    notes: '',
  },
  {
    id: 2,
    jobNo: '100',
    empNo: 2,
    name: '',
    totalHours: 0,
    regular: 0,
    timeHalf: 0,
    double: 0,
    otherHours: 0,
    dedAmount: 0,
    otherEarnings: 0,
    isReviewed: false,
    description: 'error3',
    notes: '',
  },
  {
    id: 3,
    jobNo: '100',
    empNo: 3,
    name: '',
    totalHours: 0,
    regular: 0,
    timeHalf: 0,
    double: 0,
    otherHours: 0,
    dedAmount: 0,
    otherEarnings: 0,
    isReviewed: true,
    description: 'error3',
    notes: '',
  },
  {
    id: 4,
    jobNo: '100',
    empNo: 5,
    name: '',
    totalHours: 0,
    regular: 0,
    timeHalf: 0,
    double: 0,
    otherHours: 0,
    dedAmount: 0,
    otherEarnings: 0,
    isReviewed: false,
    description: 'error1',
    notes: '',
  },
];