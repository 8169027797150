import React, { CSSProperties } from 'react';
import { getSelectedEmp } from 'core/store/selectors';
import { useSelector } from 'react-redux';
import EmpInfoPage from './info/EmpInfoPage';
import SnapshotPayRatePage from './pay-rate/SnapshotPayRatePage';
import SnapshotDeductionsPage from './deductions/SnapshotDeductionsPage';
import SnapshotDirectDepositPage from './direct-deposit/SnapshotDirectDepositPage';
import { useAppSelector } from 'utilities/hooks';

const styles: { [key: string]: CSSProperties } = {
  infoSnapshotContainer: {
    flex: '60%',
    minWidth: '250px',
  },
  snapshotDDWrapper: {
    paddingBottom: '15px',
  },
  payRateDeductionsContainer: {
    flex: '40%',
  },
};

const SnapshotPage = () => {
  const employee = useSelector(getSelectedEmp);
  const empInfoSectionAccess = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections.find((x) => x.workItemId === 1));
  const payRateAccess = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections.find((x) => x.workItemId === 2));
  const deductionsAccess = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections.find((x) => x.workItemId === 5));

  return (
    <div className="d-flex flex-wrap p-2">
      {employee && (
        <>
          <div
            style={styles.infoSnapshotContainer}
            className="pr-0 pr-md-3"
          >
            {empInfoSectionAccess?.visible !== false && <EmpInfoPage employee={employee} />}
            <div style={styles.snapshotDDWrapper}>
              {deductionsAccess?.visible !== false && <SnapshotDirectDepositPage employee={employee} />}
            </div>
          </div>
          <div
            className="d-flex flex-column flex-nowrap"
            style={styles.payRateDeductionsContainer}
          >
            {payRateAccess?.visible !== false && <SnapshotPayRatePage />}
            {deductionsAccess?.visible !== false && <SnapshotDeductionsPage />}
          </div>
        </>
      )}
    </div>
  );
};

export default SnapshotPage;
