import { createAction } from '@reduxjs/toolkit';
import { ModuleAccess } from 'core/models/ModuleAccess.model';
import {
  Client,
  ClientMasterDtoTaxEntitiesFIPS,
  ClientOption,
  ClientOptions,
  K401Options,
  Option,
} from '../../models';
import { ClientDeduction } from 'core/models/ClientDeduction.model';
export const loadClient = createAction('[client] LOAD');
export const storeClient = createAction<Client>('[client] STORE');

export const loadIsNOVAClient = createAction('[client] LOAD_IS_NOVA_CLIENT');
export const storeIsNOVAClient = createAction<boolean>('[client] STORE_IS_NOVA_CLIENT');

export const loadClientOptions = createAction('[client] LOAD_CLIENT_OPTIONS');
export const storeClientOptions = createAction<ClientOptions>(
  '[client] STORE_CLIENT_OPTIONS',
);

export const loadClientOption = createAction<{
  clientNo: string;
  optionId: number;
}>('[client] LOAD_CLIENT_OPTION');
export const storeClientOption = createAction<ClientOption>(
  '[client] STORE_CLIENT_OPTION',
);

export const putClientOption = createAction<ClientOption>(
  '[client] PUT_CLIENT_OPTION',
);

export const loadClientEnumsOptions = createAction(
  '[client] LOAD_ENUMS_OPTIONS',
);
export const storeClientEnumsOptions = createAction<Option[]>(
  '[client] STORE_ENUMS_OPTIONS',
);

export const load401KOptions = createAction('[client] LOAD_401K_OPTIONS');
export const store401KOptions = createAction<K401Options>(
  '[client] STORE_401k_OPTIONS',
);

export const loadClientDeductions = createAction('[client] LOAD_CLIENT_DEDUCTIONS');
export const storeClientDeductions = createAction<ClientDeduction[]>('[client] STORE_CLIENT_DEDUCTIONS');

export const loadClientTaxEntityFips = createAction('[client] LOAD_CLIENT_TAX_ENTITY_FIPS');
export const storeClientTaxEntityFips = createAction<ClientMasterDtoTaxEntitiesFIPS[]>('[client] STORE_CLIENT_TAX_ENTITY_FIPS');

export const setShowTermClientModal = createAction<boolean>('[client] SET_SHOW_TERM_CLIENT_MODAL');