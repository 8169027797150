import React, { useEffect, useRef, useState } from 'react';

import {
  ControlDatePickerGrp,
  InputGrp,
  SelectGrp,
} from '../../../core/components/form-controls';
import { HRVaccination, HRVaccinationFileRequest, VaccinationDetail, VaccinationFile } from '../../../core/models/HREmployee.model';
import { useSelector } from 'react-redux';
import { Collapse } from 'react-bootstrap';
import { getClient, getSelectedEmp, getVaccinationBrand, getVaccinationDosageTypes, getVaccinationStatus, getVaccinationTypes } from '../../../core/store/selectors';
import { ArrayField, useFieldArray } from 'react-hook-form';
import PhotoUploadModal from '../../../core/components/modals/PhotoUpload.modal';
import { createHRVaccinationFile, deleteHRVaccinationFile } from '../../../core/store/actions/hr-employee.action';
import { DateTime } from 'luxon';
import { useAppDispatch } from '../../../utilities/hooks';
import { CommonService } from '../../../core/services';
import Icon from 'core/components/shared/Icon';

type PropTypes = {
  item: Partial<ArrayField<HRVaccination>>;
  index: number;
  control: any;
  formMethods: any;
  protectedEmpNo: string;
  onDelete: (item: HRVaccination) => void;
};

const VaccinationItem: React.FC<PropTypes> = ({
  item,
  index,
  control,
  formMethods: { register, setValue },
  protectedEmpNo,
  onDelete,
}) => {

  const dispatch = useAppDispatch();
  const { fields: details, prepend } = useFieldArray({
    control,
    name: `vaccinationItems[${index}].details`,
    keyName: 'detailId',
  });

  const { fields: files } = useFieldArray({
    control,
    name: `vaccinationItems[${index}].files`,
    keyName: 'fileId',
  });

  const client = useSelector(getClient);
  const selEmp = useSelector(getSelectedEmp);
  const [showUpload, setShowUpload] = useState(false);
  const [image, setImage] = useState<VaccinationFile | null>(null);
  const [canDelete, setCanDelete] = useState(false);
  const uploadedImage = useRef<any>(undefined);
  const vaccineDetailImage = null;

  const [openState, setOpenState] = useState<{ [key: string]: boolean }>({
    vaccinations: false,
  });

  const vaccinationBrandOpts = useSelector(getVaccinationBrand);
  const vaccinationDosageTypeOpts = useSelector(getVaccinationDosageTypes);
  const vaccinationStatusOpts = useSelector(getVaccinationStatus);
  const vaccinationTypeOpts = useSelector(getVaccinationTypes);

  useEffect(() => {
    if (vaccineDetailImage) {
      uploadedImage.current.src = 'data:image/png;base64,' + vaccineDetailImage;
    }
  }, [vaccineDetailImage]);


  const toggleInfo = (type: string) => {
    setOpenState({
      ...openState,
      [type]: !openState[type],
    });
  };

  const addDosage = () => {
    const dosage: VaccinationDetail = {
      vaccinationId: item.vaccinationId!,
      vaccinationDetailId: 0,
      protectedEmpNo,
    };
    prepend(dosage);
  };

  const uploadVaccinationImage = (item: HRVaccination, fileData: string) => {
    const request: HRVaccinationFileRequest = {
      clientNo: client?.clientNo,
      empNo: selEmp?.empNo,
      employeeFileId: 0,
      protectedEmpNo,
      vaccinationId: item.vaccinationId,
      extension: '.png',
      name: `VaccinationRecordImage_${selEmp?.empNo}_${DateTime.now().millisecond}`,
      data: 'data:image/png;base64,' + fileData,
    };

    dispatch(createHRVaccinationFile(request));
  };

  const onDownloadImage = (fileData: string) => {
    if (fileData && showUpload) {
      CommonService.downloadBase64File(
        'image/png',
        fileData.substring(22),
        `VaccinationRecordImage_${selEmp?.empNo}_${DateTime.now().millisecond}`,
      );
    }
  };

  const onDeleteVaccinationImage = (empFileId: number, item: HRVaccination) => {
    const request: HRVaccinationFileRequest = {
      protectedEmpNo,
      vaccinationId: item.vaccinationId,
      employeeFileId: empFileId,
    };
    dispatch(deleteHRVaccinationFile(request));
  };

  const onFileClick = (e: React.SyntheticEvent<HTMLImageElement>, f: VaccinationFile) => {
    e.preventDefault();
    setImage(f);
    setShowUpload(true);
    setCanDelete(false);
  };

  const onFileRightClick = (e: React.SyntheticEvent<HTMLImageElement>, f: VaccinationFile) => {
    e.preventDefault();
    setImage(f);
    setShowUpload(true);
    setCanDelete(true);
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-wrap">
        <input
          type="hidden"
          name={`vaccinationItems[${index}].vaccinationId`}
          defaultValue={item.vaccinationId}
          ref={register({ valueAsNumber: true })}
        />

        <SelectGrp
          label="Type"
          ref={register({
            valueAsNumber: true,
          })}
          addOptionText="Type"
          name="type"
          options={vaccinationTypeOpts}
          defaultValue={item.type}
          groupClass="gc12"
        />

        <ControlDatePickerGrp
          name={`vaccinationItems[${index}].lastTalkDate`}
          label="DATE RECEIVED"
          groupClass="gc12"
          value={item.lastTalkDate}
          setValue={setValue}
          control={control}
        />

        <SelectGrp
          label="Status"
          ref={register({
            valueAsNumber: true,
          })}
          addOptionText="Status"
          name={`vaccinationItems[${index}].status`}
          options={vaccinationStatusOpts}
          defaultValue={item.status}
          groupClass="gc12"
        />

        <InputGrp
          name={`vaccinationItems[${index}].additionalInfo`}
          defaultValue={item.additionalInfo}
          label="ADDITIONAL INFORMATION"
          groupClass="gc12 mw400"
          ref={register()} />
      </div>

      <div className="d-flex flex-column">
        <div
          role="button"
          className="dm-card-subtitle2 mb-2"
          onClick={() => {
            return toggleInfo(
              'vaccinations',
            );
          }}
        >
          <Icon name={openState.vaccinations ? 'chevron-down' : 'chevron-right'}
            className="mr-2" />
          Vaccine Details
        </div>

        <Collapse in={openState.vaccinations}>
          <div className="dm-panel dm-panel-border mt-1">
            <div className="d-flex flex-row">
              <div className="d-flex flex-column flex-grow-1 mr-3">
                <div className="d-flex flex-row">
                  <div className="dm-card-subtitle2 mb-2 flex-grow-1">Dosage</div>
                  <div>
                    <button className="btn btn-link dm-grid-action-title p-0 mr-2"
                      type="button"
                      onClick={addDosage}>
                      Add New Dose	<Icon name="plus-circle"
                        className="fa-plus-circle" />
                    </button>
                  </div>
                </div>
                <hr className="dm-panel-hr" />
                {details.map((field, di) => {
                  return (
                    <div key={field.detailId}>
                      <div className="d-flex flex-row">
                        <input
                          type="hidden"
                          name={`vaccinationItems[${index}].details[${di}].vaccinationId`}
                          defaultValue={item.vaccinationId}
                          ref={register({ valueAsNumber: true })}
                        />
                        <input
                          type="hidden"
                          name={`vaccinationItems[${index}].details[${di}].vaccinationDetailId`}
                          defaultValue={field.vaccinationDetailId}
                          ref={register({ valueAsNumber: true })}
                        />

                        <SelectGrp
                          name={`vaccinationItems[${index}].details[${di}].dosageType`}
                          defaultValue={field.dosageType}
                          label="DOSAGE TYPE"
                          groupClass="gc12"
                          ref={register({ valueAsNumber: true })}
                          options={vaccinationDosageTypeOpts} />

                        <SelectGrp
                          name={`vaccinationItems[${index}].details[${di}].brand`}
                          defaultValue={field.brand}
                          label="BRAND"
                          groupClass="gc12"
                          ref={register({ valueAsNumber: true })}
                          options={vaccinationBrandOpts} />

                        <ControlDatePickerGrp
                          name={`vaccinationItems[${index}].details[${di}].date`}
                          label="DATE RECEIVED"
                          groupClass="gc12"
                          value={field.date || new Date()}
                          setValue={setValue}
                          control={control} />

                        <InputGrp
                          name={`vaccinationItems[${index}].details[${di}].administeredBy`}
                          defaultValue={field.administeredBy}
                          label="HEALTH CARE PROFESSIONAL OR CLINIC"
                          groupClass="gc12"
                          ref={register()} />
                        <InputGrp
                          name={`vaccinationItems[${index}].details[${di}].location`}
                          defaultValue={field.location}
                          label="LOCATION"
                          groupClass="gc12"
                          ref={register()} />
                        <InputGrp
                          name={`vaccinationItems[${index}].details[${di}].description`}
                          defaultValue={field.description}
                          label="DESCRIPTION"
                          groupClass="gc12"
                          ref={register()} />
                      </div>
                      <div>
                        {showUpload &&
                          <PhotoUploadModal
                            show={showUpload}
                            onHide={() => { return setShowUpload(false); }}
                            onSave={(fileData: string) => { uploadVaccinationImage(field as HRVaccination, fileData); }}
                            currentImage={image?.data ? 'data:image/png;base64,' + image?.data : ''}
                            canDelete={canDelete}
                            deleteItemId={image?.employeeFileId}
                            onDelete={(id: number) => { return onDeleteVaccinationImage(id, field as HRVaccination); }}
                            onDownload={(fileData: string) => { onDownloadImage(fileData); }}
                          />
                        }
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {details.length > 0 ? <div className="dm-panel dm-panel-border mt-1">
              <div className="d-flex flex-column">
                <div className="d-flex flex-row">
                  <div className="mr-3">
                    <button
                      type="button"
                      onClick={() => { setShowUpload(true); setCanDelete(false); setImage(null); }}
                      className="dm-add-vaccinne-photo btn-link">
                      <span style={{
                        fontSize: '32px',
                        lineHeight: '30px',
                        textDecorationLine: 'none',
                        display: 'inline-block',
                        width: '50px',
                      }}>+</span>
                      Add Photo Record
                    </button>
                  </div>
                  {files?.map((f: any, index) => {
                    return (
                      <div key={f.fileId}
                        className="mr-3">
                        {f &&
                          <label title="Right Click to Delete or Download">
                            <img
                              onClick={(e: any) => { return onFileClick(e, f); }}
                              onContextMenu={(e: any) => { return onFileRightClick(e, f); }}
                              src={'data:image/png;base64,' + f.data}
                              alt="Vaccine Record"
                              width="85"
                              height="85"
                            />
                          </label>
                        }
                      </div>
                    );
                  })}
                </div>
              </div>
            </div> : null}
          </div>
        </Collapse>
      </div>

      <div className="text-right">
        <button
          type="button"
          className="btn btn-link dm-grid-action-title mt-2"
          onClick={() => { return onDelete(item as HRVaccination); }}
        >
          Delete Vaccination
          <Icon name="minus-circle"
            className="fa-minus-circle" />
        </button>
      </div>

    </div>
  );
};

export default VaccinationItem;
