import { createAsyncThunk } from '@reduxjs/toolkit';
import { DownloaderService } from '../../services/downloader.service';
import { UserMapItem } from 'core/models/Downloader.model';
import { EmployeeEarningsDownloadState } from '../slices/employee-earnings-download.slice';

export const getUserMapById = createAsyncThunk(
  'employeeEarningsDownload/getUserMapById',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await DownloaderService.getUserMapsId(id);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error?.messages?.[0]);
    }
  },
);

export const saveUserMap = createAsyncThunk(
  'employeeEarningsDownload/saveUserMap',
  async (payload: UserMapItem, { getState, rejectWithValue }) => {
    try {

      let response = null;
  
      // @ts-ignore
      if (parseInt(payload?.userMapId, 10) !== 0) {
        response = await DownloaderService.putUserMap({
          ...payload,
          userMapId: payload.userMapId,
        });
      } else {
        response = await DownloaderService.postUserMap({
          ...payload,
          userMapId: 0,
        });
      }
  
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error?.messages?.[0]);
    }
  },
);