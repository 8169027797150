import { AxiosResponse } from 'axios';
import { HttpResponse } from 'core/models';
import {
  AvailableFont,
  CopyMapRequest,
  DownloaderPage,
  DownloaderRequest,
  EmailRequest,
  FormatExportRequest,
  ScheduledDownloadRequest,
  UserMap,
  UserMapItem,
} from 'core/models/Downloader.model';
import { API_EMPLOYEE_MASTER } from './API';
import { DownloaderEndPoints } from './URL';

const postDownloader = (request: DownloaderRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(DownloaderEndPoints.DOWNLOADER(), request);
};

const getUserMaps = (): Promise<AxiosResponse<UserMap>> => { return API_EMPLOYEE_MASTER.get<UserMap>(DownloaderEndPoints.USERMAPS()); };

const getUserMapsId = (userMapId: number): Promise<AxiosResponse<UserMapItem>> => { return API_EMPLOYEE_MASTER.get<UserMapItem>(DownloaderEndPoints.USERMAPS_ID(userMapId)); };

const postUserMap = (userMap: UserMapItem): Promise<AxiosResponse<HttpResponse<string>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(DownloaderEndPoints.USERMAPS(), userMap); };

const putUserMap = (userMap: UserMapItem): Promise<AxiosResponse<any>> => { return API_EMPLOYEE_MASTER.put<any>(DownloaderEndPoints.USERMAPS_ID(userMap.userMapId), userMap); };

const deleteUserMap = (userMapId: number): Promise<AxiosResponse<any>> => { return API_EMPLOYEE_MASTER.delete<any>(DownloaderEndPoints.USERMAPS_ID(userMapId)); };

const putCopyMap = (request: CopyMapRequest): Promise<AxiosResponse<HttpResponse<string>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<string>>(DownloaderEndPoints.COPYMAP(request.userMapId), request); };

const putEmails = (request: EmailRequest): Promise<AxiosResponse<HttpResponse<string>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<string>>(DownloaderEndPoints.EMAILS(request.userMapId), request.emails); };

const putFormatExport = (request: FormatExportRequest): Promise<AxiosResponse<HttpResponse<string>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<string>>(DownloaderEndPoints.FORMAT_EXPORT(request.userMapId), request); };

const putScheduleDownload = (request: ScheduledDownloadRequest): Promise<AxiosResponse<HttpResponse<string>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<string>>(DownloaderEndPoints.SCHEDULE_DOWNLOAD(request.userMapId), request); };

const getAvailableFonts = (): Promise<AxiosResponse<AvailableFont[]>> => { return API_EMPLOYEE_MASTER.get<AvailableFont[]>(DownloaderEndPoints.AVAILABLE_FONTS()); };

const getDownloaderFields = (): Promise<AxiosResponse<DownloaderPage[]>> => { 
  return API_EMPLOYEE_MASTER.get<DownloaderPage[]>(DownloaderEndPoints.DOWNLOADER_FIELDS()); 
};


export const DownloaderService = {
  postDownloader,
  getUserMaps,
  getUserMapsId,
  putUserMap,
  postUserMap,
  deleteUserMap,
  putCopyMap,
  putEmails,
  putFormatExport,
  putScheduleDownload,
  getAvailableFonts,
  getDownloaderFields,
};