import { createAction } from '@reduxjs/toolkit';
import {
  AccrualReportsTabRequest,
  AccuralReportStandardRequest,
  ReportType,
} from '../../models/AccrualReport.model';

export const loadStandardReports = createAction<AccuralReportStandardRequest>(
  '[accrual-reports] LOAD_ACCRUAL_REPORTS',
);
export const storeStandardReports = createAction<string>(
  '[accrual-reports] STORE_ACCRUAL_REPORTS',
);

export const clearReport = createAction(
  '[accrual-reports] CLEAR_ACCRUAL_STANDARD_REPORTS',
);

export const storeReportType = createAction<ReportType>(
  '[accrual-reports] STORE_REPORT_TYPE',
);

export const loadAccuralTab = createAction<AccrualReportsTabRequest>(
  '[accrual-reports] LOAD_ACCRUAL_TAB',
);
