import { useEffect } from 'react';
import { useAppSelector } from 'utilities/hooks';
import QuickBooksConnection from 'core/models/intuit/quickBooksConnection.model';

const useQuickBooksConnection = (setConnected: (connected: boolean) => void, setTryToRedirect: (connected: boolean) => void, tryToRedirect: boolean): QuickBooksConnection | null => {
    const blockedPopUpMsg = 'Popup windows are currently blocked and need to be allowed on this site for this integration to work. \n\nPlease allow popups and try to connect again';
    const quickBooksConnection = useAppSelector((state) => state.intuit.quickBooksConnection);

    useEffect(() => {
        if (!quickBooksConnection) {
            setTryToRedirect(false); //this will get set again when button is clicked;
            return;
        }

        if (tryToRedirect && quickBooksConnection.ssoLauncherUri.length > 0) {
            let windowRes = window.open(quickBooksConnection.ssoLauncherUri, '_blank');
            if (!windowRes) {
                alert(blockedPopUpMsg);
                return;
            }

            windowRes?.focus();
            setConnected(true);
            setTryToRedirect(false);
        }

        if (quickBooksConnection.ssoLauncherUri.length == 0) {
            setConnected(false);
        }
    }, [quickBooksConnection]);

    return quickBooksConnection;
}

export default useQuickBooksConnection;