import React, { Dispatch, SetStateAction } from 'react';
import { IAggFuncParams, ValueGetterParams, ValueSetterParams } from '@ag-grid-enterprise/all-modules';
import { DeptAllocation, AllocationDetail, AllocationEmployee } from 'core/models';
import { ddLookup } from 'utilities/utilities';

// TODO: make these two compare fns a single generator w/ field argument
export function compareLastName(a: AllocationEmployee, b: AllocationEmployee) {
  if (!(a && b) || !(a.lastName && b.lastName)) return 0;
  if (a.lastName < b.lastName) return -1;
  if (a.lastName > b.lastName) return 1;
  return 0;
}

export function compareEmpNo(a: AllocationEmployee, b: AllocationEmployee) {
  if (!(a && b)) return 0;
  if (a.empNo > b.empNo) return 1;
  if (a.empNo < b.empNo) return -1;
  return 0;
}

export function customAggregator(params: IAggFuncParams) {
  let sum = 0;
  
  params.values.forEach((value: string) => {
    if (!isNaN(Number(value))) {
      sum += Number(value);
    }
  });
  
  return sum;
}

export function updateMasterRowValue<T>({ data, newValue }: ValueSetterParams, idKey: keyof T, fieldKey: keyof T, update: Dispatch<SetStateAction<T[]>>) {
  const formattedValue = ['beginDate', 'endDate'].includes(String(fieldKey)) ? `${newValue}T00:00:00` : newValue;
  
  update((prevState) => {
    const updatedData: T[] = prevState.map((row) => {
      if (row[idKey] === data[idKey]) {
        return {
          ...row,
          [fieldKey]: formattedValue,
        };
      } else {
        return row;
      }
    });
    return updatedData;
  });
  return true;
}

// this seems to only be necessary when updating select values in a detail row when using master/detail.
// TODO: Generics
export function updateDetailRowValue<T extends object, U>({ data, newValue }: ValueSetterParams, fieldKey: string, update: Dispatch<SetStateAction<DeptAllocation[]>>) {
  update((prevState) => {
    const updatedData: DeptAllocation[] = prevState.map((row: DeptAllocation) => {
      const updatedDetails = row.allocationDetails.map((detail: AllocationDetail) => {
        if (detail.detailId === data.detailId) {
          return {
            ...detail,
            [fieldKey]: +newValue,
          };
        } else {
          return detail;
        }
      });
      return {
        ...row,
        allocationDetails: updatedDetails,
      };
    });
    return updatedData;
  });
  return true;
}
