/* eslint-disable object-property-newline */
/* eslint-disable no-console */
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { CommonService } from 'core/services';
import { EmployeeService } from 'core/services/employee.service';
import { getClientOptionRequirePassword, getUserAccess } from 'core/store/selectors';
import PasswordModal from '../../modals/Password.modal';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Email';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { handleError, loadDefaultUserPassword, sendUserPDFEmail } from 'core/store/actions';
import PDFPasswordEmailModal from '../../modals/PDFPasswordEmail.modal';
import PSPDFKit, { StandaloneConfiguration, Instance, ToolbarItem } from 'pspdfkit';
import * as ReactDOMServer from 'react-dom/server';
import Browser from './browser';
import { dataURIToBlob } from 'utilities/utilities';

type PropTypes = {
  pdfData: string;
  reportName: string;
  excelData?: string | null;
  payrollPreview?: boolean;
  checkCalcTestPrint?: boolean;
  viewClass?: string;
  viewStyles?: any;
  fileType?: string;
};

type CustomToolbarItemData = {
  id: string;
  className: string;
  handler: () => void;
  node: JSX.Element;
};

type PdfActionType = { print: boolean; download: boolean; };

const toolbarButtonStyles: CSSProperties = {
  display: 'flex',
  fontSize: '14px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
  padding: '2px',
  margin: '0px 10px',
  border: 'none',
  background: 'transparent',
  color: '#00558c',
  cursor: 'pointer',
};

const toolbarButtonIconStyles: CSSProperties = {
  height: '24px',
  width: 'auto',
  fill: '#00558c',
};

const pdfViewStyle: CSSProperties = {
  height: 'calc(100vh - 260px)',
  overflow: 'auto',
};

const PdfViewer: React.FC<PropTypes> = ({
  pdfData,
  reportName,
  excelData,
  checkCalcTestPrint,
  viewClass = '',
  viewStyles,
  fileType = '.pdf',
}) => {
  const [showPWModal, setShowPWModal] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');
  const [pdfBlob, setPdfBlob] = useState('');
  const [showEmailMessage, setShowEmailMessage] = useState(false);
  const [message, setMessage] = useState<string>(); 
  const [action, setAction] = useState<PdfActionType>({
    print: false,
    download: false,
  });
  const hasPdfFileType = (fileType === '.pdf');
  const hasWordType = (fileType === '.docx');

  const dispatch = useAppDispatch();

  const convertToBlob = async (file: string): Promise<string> => {
    const base64Response = await fetch(`data:application/pdf;base64,${file}`);
    const blob = await base64Response.blob();
    return URL.createObjectURL(blob);
  };
  
  const userAccess = useSelector(getUserAccess);
  const requirePassword = useSelector(getClientOptionRequirePassword);
  const confirmMessage = useAppSelector(
    (state) => { return state.payroll.userPdfEmailMessage; },
  );
  const defaultPWData = useAppSelector((state) => { return state.app.defaultUserPassword; });

  const pdfInstanceRef = useRef<Instance | null>(null);
  const pdfContainerRef = useRef<HTMLDivElement | null>(null);
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  
  //Zoom excel files out further by default (Note: Why dont we use PSPDFKit.ZoomMode.AUTO?)
  const DEFAULT_ZOOM = (fileType === '.xlsx') ? PSPDFKit.ZoomMode.AUTO : .0009 * window.innerWidth;
  
  useEffect(() => {
    if (userAccess) {
      dispatch(loadDefaultUserPassword({ secUserId: userAccess.secUserId }));
    }
  }, []);

  useEffect(() => {
    if (!(pdfData && pdfData.length)) return;

    convertToBlob(pdfData)
      .then((a) => {
        setPdfBlob(a);
      })
      .catch((err) => { return dispatch(handleError(err)); });
  }, [pdfData]);
  
  useEffect(() => {
    if (!pdfBlob.length) return;
    
    const container = pdfContainerRef.current;
    let instance: Instance;
    
    (async function () {
      PSPDFKit.unload(container);

      const instanceLoadObject: StandaloneConfiguration = {
        container: container as HTMLElement,
        styleSheets: [
          './pdf-viewer.css', // Needs to be a vanilla CSS file in public directory to be on the webserver
        ],
        document: pdfBlob,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        initialViewState: new PSPDFKit.ViewState({ zoom: DEFAULT_ZOOM }),
        printOptions: { quality: 'HIGH' },
      };
      
      if (process.env.NODE_ENV === 'production') instanceLoadObject.licenseKey = process.env.REACT_APP_PSPDF_KEY;

      instance = await PSPDFKit.load(instanceLoadObject);
      
      const { toolbarItems, setToolbarItems, viewState, setViewState } = instance;
      const searchTool: ToolbarItem | undefined = toolbarItems.find(({ type }) => { return type === 'search'; });

      if (!searchTool) return console.error('ERROR: Cannot find native tool(s)');
      
      addEventListener('resize', (_) => {
        const newState = viewState.set('zoom', DEFAULT_ZOOM);
        setViewState(newState);
      });
      
      const customTools: ToolbarItem[] = customToolbar.map((item) => {
        const node = document.createElement('div');
        node.innerHTML = ReactDOMServer.renderToString(item.node);
        
        return {
          type: 'custom',
          id: item.id,
          node: node,
          className: item.className,
          onPress: (_) => { item.handler(); },
        };
      });
      
      pdfInstanceRef.current = instance;
      setToolbarItems([...customTools, searchTool]);
    })();

    return () => { PSPDFKit.unload(container); };
  }, [pdfBlob, defaultPWData]);

  useEffect(() => {
    if (confirmMessage) {
      setMessage(confirmMessage);
    }
  }, [confirmMessage]);

  const addPasswordToPdf = (password: string, overrideDownload = false) => {
    if (!(action && pdfData && pdfData.length)) return;

    EmployeeService.putAddPasswordToPDF({
      password,
      pdf: pdfData,
    })
      .then(async (res) => {
        if (action.download || overrideDownload) {
          setDownloadLink(await convertToBlob(res.data));
          downloadLinkRef?.current?.click();
        } else if (action.print) {
          if (!pdfInstanceRef?.current) return dispatch(handleError('Error when printing'));
          pdfInstanceRef.current.print({ excludeAnnotations: true });
        }
      })
      .catch((err: any) => {
        setAction({
          print: false,
          download: false,
        });
        return console.error(err);
      });
  };
  
  const printExternally = async () => {
    const _downloadLink = await convertToBlob(pdfData);
    window.open(_downloadLink, '_blank');
  };

  const onDownload = async () => {
    setAction({
      print: false,
      download: true,
    });
    if (requirePassword && !defaultPWData?.defaultPdfpassword) return setShowPWModal(true);
    else if (requirePassword && (defaultPWData?.defaultPdfpassword || defaultPWData?.defaultPdfpassword == '')) addPasswordToPdf(defaultPWData.defaultPdfpassword, true);
    else {
      const _downloadLink = await convertToBlob(pdfData);
      setDownloadLink(_downloadLink);
      downloadLinkRef?.current?.click();
    }
  };

  //I just yoinked this from the Applicant.tsx screen since we were just downloading .docx files right away.
  const onDownloadDocs = async () => {
    const blob = dataURIToBlob(pdfData);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url || '';
    link.download = `${reportName}${fileType}`;
    link.click();
  };

  const onPrint = async () => {
    if (Browser.isEdge() || Browser.isChrome()) return printExternally(); // PI-8574: Until we hear back from PSPDFKit on a fix, just open in a new tab so users can print from there.
    if (!pdfInstanceRef?.current) return dispatch(handleError('Could not print document'));
    pdfInstanceRef.current.print({ excludeAnnotations: true });
  };

  const emailPassword = () => {
    if (!userAccess) return;

    dispatch(sendUserPDFEmail(userAccess.secUserId));
    setShowEmailMessage(true);
  };

  const exportCheckRegister = () => {
    if (!excelData) return;

    CommonService.downloadBase64File(
      'application/excel',
      excelData,
      'CheckRegisterDetail.xlsx',
    );
  };

  const customToolbar: CustomToolbarItemData[] = [];
  
  if (!checkCalcTestPrint && hasPdfFileType) {
    customToolbar.push({
      id: 'pdf-download',
      className: 'btn btn-link',
      handler: onDownload,
      node: <button
        style={toolbarButtonStyles}
        key={'pdf-download'}
      >
        Download <DownloadIcon style={toolbarButtonIconStyles} />
      </button>,
    },
    {
      id: 'pdf-print',
      className: 'btn-link',
      handler: onPrint,
      node: <button
        style={toolbarButtonStyles}
        key={'pdf-print'}
      >
        Print <PrintIcon style={toolbarButtonIconStyles} />
      </button>,
    });
    if ((defaultPWData?.defaultPdfpassword || defaultPWData?.defaultPdfpassword === '') && hasPdfFileType && requirePassword) {
      customToolbar.push({
        id: 'pdf-email-password',
        handler: emailPassword,
        className: 'btn btn-link',
        node: <button
          style={toolbarButtonStyles}
          key={'pdf-email-password'}
        >
          Email PDF Password <EmailIcon style={toolbarButtonIconStyles} />
        </button>,
      });
    }
  }
  if (excelData) {
    customToolbar.push({
      id: 'pdf-export-cr',
      handler: exportCheckRegister,
      className: 'btn btn-link',
      node: <button
        style={toolbarButtonStyles}
        key={'pdf-export-cr'}
      >
        Export Check Register Detail to Excel <DownloadIcon style={toolbarButtonIconStyles} />
      </button>,
    });
  }
  if (hasWordType) {
    customToolbar.push({
      id: 'docs-download',
      className: 'btn-link',
      handler: onDownloadDocs,
      node: <button
        style={toolbarButtonStyles}
        key={'docs-download'}
      >
        Download <DownloadIcon style={toolbarButtonIconStyles} />
      </button>,
    });
  }
  
  return (
    <div className="w-100">
      {pdfBlob?.length > 0 ? (
        <div style={viewStyles || pdfViewStyle}>
          <div
            className={viewClass}
            ref={pdfContainerRef}
            style={{ height: '100%', width: '100%' }}
          ></div>
        </div>
      ) : null}
      {showPWModal ? (
        <PasswordModal
          show={showPWModal}
          onHide={() => {
            setShowPWModal(false);
            setAction({
              print: false,
              download: false,
            });
          }}
          defaultPasswordData={defaultPWData}
          addPasswordToPdf={addPasswordToPdf}
        />
      ) : null}
      {showEmailMessage && userAccess && message ? (
        <PDFPasswordEmailModal
          message={message}
          show={showEmailMessage}
          onHide={() => { return setShowEmailMessage(false); }}
        />
      ) : null}
      <a
        ref={downloadLinkRef}
        className="btn-sm btn-primary orange-button d-none"
        download={`${reportName}_${DateTime.now().millisecond}.pdf`}
        href={downloadLink}
      >
        Download
      </a>
    </div>
  );
};

export default PdfViewer;
