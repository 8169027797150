import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { SalesTaxTransmittalEntityPayload, SalesTaxTransmittal, SalesTaxTransmittalEntity, SalesTaxPayload } from '../../models/SalesTaxTransmittal.model';
import { SalesTaxTransmittalService } from 'core/services';
import { handleError } from './app.action';

type AddError = {
  statusCode: string;
  messages: string[];
  value: null;
};

// actions
export const loadSalesTaxTransmittalByYear = createAction<number>('[sales-tax-transmittal] LOAD_SALES_TAX_TRANSMITTAL');

export const loadSalesTaxTransmittalByEntity = createAction<SalesTaxTransmittalEntityPayload>('[sales-tax-transmittal] LOAD_SALES_TAX_TRANSMITTAL_ENTITY');

export const storeSalesTaxTransmittalEntity = createAction<SalesTaxTransmittalEntity[]>('[sales-tax-transmittal] STORE_SALES_TAX_TRANSMITTAL_ENTITY');

export const loadSalesTaxTransmittalReport = createAction<SalesTaxTransmittalEntityPayload>('[sales-tax-transmittal] FETCH_SALES_TAX_TRANSMITTAL_REPORT');

export const storeSalesTaxTransmittalReport = createAction<any>('[sales-tax-transmittal] STORE_SALES_TAX_TRANSMITTAL_REPORT');

export const clearSalesTaxTransmittalReport = createAction('[sales-tax-transmittal] CLEAR_SALES_TAX_TRANSMITTAL_REPORT');

export const closeSalesTaxTransmittal = createAction<SalesTaxPayload>('[sales-tax-transmittal] CLOSE_SALES_TAX_TRANSMITTAL');

export const updateSalesTaxTransmittalEntity = createAction<SalesTaxTransmittalEntityPayload>('[sales-tax-transmittal] UPDATE_SALES_TAX_TRANSMITTALL_ENTITY');

export const setShowActivationMessage = createAction<boolean>('[sales-tax-transmittal] SET_SHOW_ACTIVATION_MESSAGE');

export const storeSalesTaxTransmittal = createAction<SalesTaxTransmittal[]>('[sales-tax-transmittal] STORE_SALES_TAX_TRANSMITTAL');

export const toggleLoadingState = createAction<boolean>('[payroll] TOGGLE_LOADING_STATE');

// thunks 
export const addSalesTaxTransmittal = createAsyncThunk(
  '[sales-tax-transmittal] ADD_SALES_TAX_TRANSMITTAL', // keeping this old school to match the other actions in here for now
  async (request: SalesTaxPayload, { dispatch }) => {
    try {
      const _res = await SalesTaxTransmittalService.addSalesTaxTransmittal(request);
      dispatch(loadSalesTaxTransmittalByYear(request.year)); // why?
    } catch (error) {
      if (!!error && typeof error === 'object' && (error as AddError)?.messages?.[0]?.includes('No entities are set up for sales tax')) {
        dispatch(setShowActivationMessage(true));
      } else {
        dispatch(handleError(error));
      }
    }
  },
);