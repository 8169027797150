import React, { useEffect, useState } from 'react';
import { SelectGrp } from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { Dropdown, OfferOfCoverage, SafeHarbor } from 'core/models';

const fs: FieldInputSettings = {
  safeHarborAll12Months: {
    name: 'safeHarbor.safeHarborAll12Months',
    noOption: true,
    addOptionText: 'selection',
    label: '',
    groupClass: 'gc08 mw100 pr-1',
  },
  safeHarborJanuary: {
    name: 'safeHarbor.safeHarborJanuary',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  safeHarborFebruary: {
    name: 'safeHarbor.safeHarborFebruary',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  safeHarborMarch: {
    name: 'safeHarbor.safeHarborMarch',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  safeHarborApril: {
    name: 'safeHarbor.safeHarborApril',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  safeHarborMay: {
    name: 'safeHarbor.safeHarborMay',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  safeHarborJune: {
    name: 'safeHarbor.safeHarborJune',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  safeHarborJuly: {
    name: 'safeHarbor.safeHarborJuly',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  safeHarborAugust: {
    name: 'safeHarbor.safeHarborAugust',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  safeHarborSeptember: {
    name: 'safeHarbor.safeHarborSeptember',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  safeHarborOctober: {
    name: 'safeHarbor.safeHarborOctober',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  safeHarborNovember: {
    name: 'safeHarbor.safeHarborNovember',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
  safeHarborDecember: {
    name: 'safeHarbor.safeHarborDecember',
    label: '',
    groupClass: 'gc06 mw50 pr-1',
  },
};

type PropTypes = {
  formMethods: any;
  item: SafeHarbor;
  safeHarborOpts: Dropdown[];
  offerOfCoverage: OfferOfCoverage;
};

const ACASafeHarbor: React.FC<PropTypes> = ({
  formMethods: { errors, register, setValue },
  item,
  safeHarborOpts,
  offerOfCoverage,
}) => {
  const [monthReadOnly, setMonthReadOnly] = useState(false);

  const overrideClass = item.overridden ? 'text-primary ' : 'text-success';

  useEffect(() => {
    setMonthReadOnly(offerOfCoverage.offerOfCoverageAll12Months !== '');
  }, [offerOfCoverage]);

  useEffect(() => {
    setValue('safeHarbor.safeHarborJanuary', monthReadOnly ? null : item?.safeHarborJanuary);
    setValue('safeHarbor.safeHarborFebruary', monthReadOnly ? null : item?.safeHarborFebruary);
    setValue('safeHarbor.safeHarborMarch', monthReadOnly ? null : item?.safeHarborMarch);
    setValue('safeHarbor.safeHarborApril', monthReadOnly ? null : item?.safeHarborApril);
    setValue('safeHarbor.safeHarborMay', monthReadOnly ? null : item?.safeHarborMay);
    setValue('safeHarbor.safeHarborJune', monthReadOnly ? null : item?.safeHarborJune);
    setValue('safeHarbor.safeHarborJuly', monthReadOnly ? null : item?.safeHarborJuly);
    setValue('safeHarbor.safeHarborAugust', monthReadOnly ? null : item?.safeHarborAugust);
    setValue('safeHarbor.safeHarborSeptember', monthReadOnly ? null : item?.safeHarborSeptember);
    setValue('safeHarbor.safeHarborOctober', monthReadOnly ? null : item?.safeHarborOctober);
    setValue('safeHarbor.safeHarborNovember', monthReadOnly ? null : item?.safeHarborNovember);
    setValue('safeHarbor.safeHarborDecember', monthReadOnly ? null : item?.safeHarborDecember);
    setValue('safeHarbor.safeHarborAll12Months', !monthReadOnly ? null : item?.safeHarborAll12Months);
  }, [monthReadOnly]);

  return (
    <>
      {offerOfCoverage && (
        <div className="d-flex flex-row flex-wrap">
          <div
            className="gc15 font-weight-bold"
            style={{ lineHeight: '12px', fontSize: '10px' }}
          >
            16 Applicable Section 4980H Safe Harbor
          </div>
          <SelectGrp
            {...fs.safeHarborAll12Months}
            errors={errors?.safeHarbor?.safeHarborAll12Months}
            ref={register()}
            options={safeHarborOpts}
            selectClass={overrideClass}
            disabled={
              offerOfCoverage.offerOfCoverageAll12Months === ''
            }
          />
          <SelectGrp
            {...fs.safeHarborJanuary}
            errors={errors?.safeHarbor?.safeHarborJanuary}
            ref={register()}
            options={safeHarborOpts}
            disabled={monthReadOnly}
            selectClass={overrideClass}
          />
          <SelectGrp
            {...fs.safeHarborFebruary}
            errors={errors?.safeHarbor?.safeHarborFebruary}
            ref={register()}
            options={safeHarborOpts}
            disabled={monthReadOnly}
            selectClass={overrideClass}
          />
          <SelectGrp
            {...fs.safeHarborMarch}
            errors={errors?.safeHarbor?.safeHarborMarch}
            ref={register()}
            options={safeHarborOpts}
            disabled={monthReadOnly}
            selectClass={overrideClass}
          />
          <SelectGrp
            {...fs.safeHarborApril}
            errors={errors?.safeHarbor?.safeHarborApril}
            ref={register()}
            options={safeHarborOpts}
            disabled={monthReadOnly}
            selectClass={overrideClass}
          />
          <SelectGrp
            {...fs.safeHarborMay}
            errors={errors?.safeHarbor?.safeHarborMay}
            ref={register()}
            options={safeHarborOpts}
            disabled={monthReadOnly}
            selectClass={overrideClass}
          />
          <SelectGrp
            {...fs.safeHarborJune}
            errors={errors?.safeHarbor?.safeHarborJune}
            ref={register()}
            options={safeHarborOpts}
            disabled={monthReadOnly}
            selectClass={overrideClass}
          />
          <SelectGrp
            {...fs.safeHarborJuly}
            errors={errors?.safeHarbor?.safeHarborJuly}
            ref={register()}
            options={safeHarborOpts}
            disabled={monthReadOnly}
            selectClass={overrideClass}
          />
          <SelectGrp
            {...fs.safeHarborAugust}
            errors={errors?.safeHarbor?.safeHarborAugust}
            ref={register()}
            options={safeHarborOpts}
            disabled={monthReadOnly}
            selectClass={overrideClass}
          />
          <SelectGrp
            {...fs.safeHarborSeptember}
            errors={errors?.safeHarbor?.safeHarborSeptember}
            ref={register()}
            options={safeHarborOpts}
            disabled={monthReadOnly}
            selectClass={overrideClass}
          />
          <SelectGrp
            {...fs.safeHarborOctober}
            errors={errors?.safeHarbor?.safeHarborOctober}
            ref={register()}
            options={safeHarborOpts}
            disabled={monthReadOnly}
            selectClass={overrideClass}
          />
          <SelectGrp
            {...fs.safeHarborNovember}
            errors={errors?.safeHarbor?.safeHarborNovember}
            ref={register()}
            options={safeHarborOpts}
            disabled={monthReadOnly}
            selectClass={overrideClass}
          />
          <SelectGrp
            {...fs.safeHarborDecember}
            errors={errors?.safeHarbor?.safeHarborDecember}
            ref={register()}
            options={safeHarborOpts}
            disabled={monthReadOnly}
            selectClass={overrideClass}
          />
        </div>
      )}
    </>
  );
};

export default ACASafeHarbor;
