import React, { useState, useEffect, ChangeEvent, CSSProperties, useRef } from 'react';
import Modal from 'core/components/modals/Modal';
import Table, { matchQuery } from 'core/components/shared/dm-table/Table';
import { useDispatch } from 'react-redux';
import { addJobInfoFromFile, clearUploadErrors, deleteJobInfo, getAllJobs, storeJobInfo } from 'core/store/actions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import TableHeader from 'core/components/shared/dm-table/Header';
import { PayrollTimeCardJobInfo } from 'core/models/Contractor.model';
import JobDetail from './JobDetail.modal';
import { RadioGrp, RadioOptions } from 'core/components/form-controls/RadioGrp';
import tableStyles from 'core/components/shared/dm-table/_styles.module.scss';
import TableRow from 'core/components/shared/dm-table/Row';
import Cell from 'core/components/shared/dm-table/Cell';
import { InputGrp } from 'core/components/form-controls';
import Icon from 'core/components/shared/Icon';
import { CellProps, Row, ColumnHeaderData } from 'core/components/shared/dm-table/types';
import JobUploadErrorModal from './JobUploadError.modal';

type Props = {
  show: boolean;
  onHide: () => void;
};

const jobSetupColumns: ColumnHeaderData[] = [
  { title: 'Job No', styleClass: 'th' },
  { title: 'Description', styleClass: 'th-lg' },
  { title: 'Project No', styleClass: 'th' },
  { title: 'Name', styleClass: 'th' },
  { title: 'City', styleClass: 'th' },
  { title: 'State', styleClass: 'th' },
  { title: 'Zip', styleClass: 'th' },
];

const buildJobRow = (job: PayrollTimeCardJobInfo): Row => {
  return {
    id: job.jobNo,
    cells: [
      { children: job.jobNo, styleClass: 'td' },
      { children: job.description, styleClass: 'td-lg' },
      { children: job.projectNo, styleClass: 'td' },
      { children: job.name, styleClass: 'td' },
      { children: job.city, styleClass: 'td' },
      { children: job.state, styleClass: 'td' },
      { children: job.zip, styleClass: 'td' },
    ],
  };
};

const filterOpts: RadioOptions[] = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'inactive',
    label: 'Inactive',
  },
];

const fileInputStyle: CSSProperties = {
  visibility: 'hidden',
  opacity: 0,
  width: 0,
  height: 0,
};

const JobSetupModal = ({ show, onHide }: Props) => {
  const [rowData, setRowData] = useState<Row[]>([]);
  const [selectedJob, setSelectedJob] = useState<PayrollTimeCardJobInfo | null>(null);
  const [addNew, setAddNew] = useState<boolean>(false);
  const [selectedRadioOpt, setSelectedRadioOpt] = useState<'all' | 'active' | 'inactive'>('active');
  const [query, setQuery] = useState<string>('');
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [showUploadErrorModal, setShowUploadErrorModal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  
  const inputRef = useRef<HTMLInputElement>(null);
  
  const { allJobs, currentJobInfo, jobUploadErrors } = useAppSelector(({ contractor }) => contractor);
  
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(getAllJobs());
    setSelectedRadioOpt('active');
  }, []);
  
  useEffect(() => {
    if (!allJobs?.length) return;
    // this also sets the row state
    handleRadioChange(selectedRadioOpt);
  }, [allJobs]);
  
  useEffect(() => {
    if (!selectedFile) return;
    uploadJobInfoFile();
  }, [selectedFile]);
  
  useEffect(() => {
    setShowUploadErrorModal(!!jobUploadErrors?.length);
  }, [jobUploadErrors?.length]);
  
  useEffect(() => {
    if (!currentJobInfo) return;
    setSelectedJob(currentJobInfo);
  }, [currentJobInfo]);
  
  const handleSearch = (newQuery: string) => {
    if (!newQuery.trim().length) {
      setRowData(allJobs
        ?.filter((job) => {
          if (selectedRadioOpt === 'active') {
            return job?.activeYN;
          } else if (selectedRadioOpt === 'inactive') {
            return !job?.activeYN;
          }
          return true;
        })
        ?.map(buildJobRow));
      setQuery('');
      return;
    }
    
    const newState = structuredClone(allJobs
      ?.filter((row) => {
        if (selectedRadioOpt === 'active') {
          return row?.activeYN;
        } else if (selectedRadioOpt === 'inactive') {
          return !row?.activeYN;
        }
        return true;
      })
      ?.map(buildJobRow),
    )?.filter((row: Row) =>
      !!row.cells.find((cell: CellProps) =>
        matchQuery(cell, newQuery)));
    
    setQuery(newQuery);
    setRowData(newState);
  };
  
  const queryFilter = (job: PayrollTimeCardJobInfo, _query: string) => {
    return !!(
      String(job?.jobNo ?? '-99').includes(query)
      || (job?.description ?? 'empty').includes(query)
      || String(job?.projectNo ?? '-99').includes(query)
      || String(job?.name ?? 'empty').includes(query)
      || (job?.city ?? 'empty').includes(query)
      || (job?.state ?? 'empty').includes(query)
      || (job?.zip ?? '-99999').includes(query)
    );
  };
  
  const handleRadioChange = (value: string) => {
    switch (value) {
      case 'all':
        setRowData(allJobs
          ?.filter((job) => query.length ? queryFilter(job, query) : true)
          ?.map(buildJobRow));
        setSelectedRadioOpt('all');
        break;
      case 'active': {
        const activeJobs = allJobs
          ?.filter((job: PayrollTimeCardJobInfo) => (query.length ? queryFilter(job, query) : true) && job?.activeYN)
          ?.map(buildJobRow);
        
        setRowData(activeJobs);
        setSelectedRadioOpt('active');
        break;
      }
      case 'inactive': {
        const inactiveJobs = allJobs
          ?.filter((job: PayrollTimeCardJobInfo) => (query.length ? queryFilter(job, query) : true) && !job?.activeYN)
          ?.map(buildJobRow);
        
        setRowData(inactiveJobs);
        setSelectedRadioOpt('inactive');
        break;
      }
    }
  };
  
  const handleDeleteJob = () => {
    if (!selectedJob) return console.error('No job to delete');
    if (!confirm(`Delete job #${selectedJob.jobNo}?`)) return;
    dispatch(deleteJobInfo({ jobNo: selectedJob.jobNo }));
    setSelectedJob(null);
  };
  
  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };
  
  const uploadJobInfoFile = () => {
    if (!selectedFile) return console.error('No selected file passed');
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64String = reader.result as string;
        
      appDispatch(addJobInfoFromFile({ data: base64String.split(',')[1] }))
        .then((res) => {
          setSelectedFile(null);
        }).catch((_error) => {
          setSelectedFile(null);
        });
    };
    reader.readAsDataURL(selectedFile);
  };
  
  const hideModal = () => {
    dispatch(clearUploadErrors());
    dispatch(storeJobInfo(null));
    onHide();
  };
  
  return (
    <Modal
      size="xl"
      title="Job Setup"
      show={show}
      onHide={hideModal}
    >
      <div className="d-flex flex-column">
        <input
          type="file"
          style={fileInputStyle}
          ref={inputRef}
          onChange={(e) => {
            handleFileSelection(e);
          }}
        />
        <div className={tableStyles['table-container']}>
          <div className={tableStyles['table-modules-container']}>
            <div
              className="d-flex flex-column mr-3"
            >
              <label
                htmlFor="table-search"
                className={tableStyles['top-label']}
              >Search</label>
              <InputGrp
                placeholder="Search"
                groupClass="m-0"
                inputClass={`gc20 ${tableStyles.input}`}
                name="table-search"
                type="text"
                onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                  handleSearch(target.value);
                }}
              />
            </div>
            <div
              className="d-flex flex-column mr-3"
            >
              <label
                htmlFor="table-filter-by"
                className={tableStyles['top-label']}
              >Filter By</label>
              <RadioGrp
                name="table-filter-by"
                radioOptions={filterOpts}
                checked={selectedRadioOpt}
                onChange={(e) => { handleRadioChange(e.target.value); }}
                controlled
              />
            </div>
            <button
              className="btn btn-link p-0 mx-3"
              onClick={() => {
                setShowDetailModal(true);
              }}
              disabled={!selectedJob}
            >
              Update Selected&nbsp;<Icon 
                name="pen"
              />
            </button>
            <button
              className="btn btn-link p-0 mr-3"
              onClick={handleDeleteJob}
              disabled={!selectedJob}
            >
              Delete Selected&nbsp;<Icon 
                name="minus-circle"
              />
            </button>
            <button
              className="btn btn-link p-0 mr-3"
              onClick={() => {
                setSelectedJob(null);
                setShowDetailModal(true);
                setAddNew(true);
              }}
            >
              Add Job&nbsp;<Icon 
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
            <button
              className="btn btn-link p-0"
              onClick={() => {
                inputRef.current?.click();
              }}
            >
              Upload Job Info&nbsp;<Icon 
                name="file-upload"
              />
            </button>
          </div>
          <div className={tableStyles.table}>
            <TableHeader columns={jobSetupColumns} />
            <div className={tableStyles.tbody}>
              {rowData?.map((row: Row) => (
                <TableRow
                  id={row.id}
                  isSelected={String(row.id) === String(selectedJob?.jobNo)}
                  key={row.id}
                  onClick={() => {
                    setSelectedJob(allJobs?.find((job) => String(job.jobNo) === String(row.id)) ?? null);
                  }}
                  onDoubleClick={() => {
                    setSelectedJob(allJobs?.find((job) => String(job.jobNo) === String(row.id)) ?? null);
                    setShowDetailModal(true);
                  }}
                >
                  {row.cells.map((cell: CellProps, cellIndex: number) => (
                    <Cell
                      key={`(${row.id},${cellIndex})`}
                      styleClass={cell.styleClass}
                      position={cell.position}
                    >{cell.children}</Cell>
                  ))}
                </TableRow>
              ))}
            </div>
          </div>
        </div>
      </div>
      {showDetailModal && (selectedJob || addNew) && (
        <JobDetail
          show={showDetailModal}
          addJob={addNew}
          onHide={() => {
            setAddNew(false);
            setShowDetailModal(false);
          }}
          jobInfo={selectedJob ?? undefined}
        />
      )}
      {showUploadErrorModal && (
        <JobUploadErrorModal
          show={showUploadErrorModal}
          onHide={() => { setShowUploadErrorModal(false); }}
        />
      )}
    </Modal>
  );
};

export default JobSetupModal;