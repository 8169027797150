import React, { useState } from 'react';
import Modal from 'core/components/modals/Modal';
import { UNSAVED_MESSAGE } from 'core/constants';
import { PayrollTimeCardCostCodeInfo } from 'core/models/Contractor.model';
import { useForm } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import styles from '../../styles.module.scss';
import { CheckboxGrpInLine, InputGrp } from 'core/components/form-controls';
import { fieldSettings } from './CostDetailConfig';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { addUpdateCostCodeInfo } from 'core/store/actions';
import ConfirmationModal from 'core/components/modals/confirmation.modal';

const newCostCode: PayrollTimeCardCostCodeInfo = {
  clientNo: -9999,
  costCode: '',
  description: '',
  excludeFromWH347: false,
};

type Props = {
  costCodeInfo?: PayrollTimeCardCostCodeInfo;
  addCostCode: boolean;
  show: boolean;
  onHide: () => void;
};

const CostCodeDetailModal = ({ costCodeInfo = newCostCode, addCostCode, show, onHide }: Props) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  
  const clientNo = useAppSelector(({ client }) => client.client?.clientNo);
  
  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<PayrollTimeCardCostCodeInfo>({
    defaultValues: { ...costCodeInfo, clientNo: clientNo ?? -9999 },
  });
  
  const dispatch = useAppDispatch();
  
  const handleSave = async (updatedCostCodeInfo: PayrollTimeCardCostCodeInfo) => {
    dispatch(addUpdateCostCodeInfo(updatedCostCodeInfo))
      .then((_res) => {
        reset({ ...updatedCostCodeInfo });
      });
  };
  
  const hideModal = () => {
    if (isDirty) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };
  
  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };
  
  return (
    <Modal
      title="Cost Code Details"
      show={show}
      onHide={hideModal}
      isDirty={isDirty}
    >
      <Prompt
        when={isDirty}
        message={UNSAVED_MESSAGE}
      />
      <form onSubmit={handleSubmit(handleSave)}>
        <input
          type="hidden"
          name="clientNo"
          ref={register({
            valueAsNumber: true,
          })}
        />
        <div className={styles['section-flex']}>
          {!addCostCode ? (
            <>
              <input
                type="hidden"
                name="costCode"
                ref={register()}
              />
              <InputGrp
                {...fieldSettings.costCode}
                defaultValue={costCodeInfo.costCode}
                groupClass="mw150 mr-2"
                disabled
              />
            </>
          ) : (
            <InputGrp
              {...fieldSettings.costCode}
              groupClass="mw150 mr-2"
              ref={register()}
            />
          )}
          <InputGrp
            {...fieldSettings.description}
            groupClass="mw200 mr-2"
            ref={register()}
          />
          <CheckboxGrpInLine
            name="excludeFromWH347"
            label={'Exclude from WH347 Report'}
            ref={register()}
          />
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            className="orange-outline-button-sm mr-2"
            onClick={(e) => {
              e.preventDefault();
              hideModal();
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={!isDirty}
            className="orange-button-sm"
          >
            Save
          </button>
        </div>
      </form>
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { setShowConfirmationModal(false); }}
        />
      )
      }
    </Modal>
  );
};

export default CostCodeDetailModal;