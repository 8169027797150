import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RadioGrp } from '../../core/components/form-controls';
import { RadioYesNoProps } from '../../core/components/form-controls/RadioGrp';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import { CommonService } from '../../core/services';
import {
  clearEmployeeDeductionsShortageReport,
  getEmployeeDeductionsShortage,
} from '../../core/store/actions';
import { getSelectedEmpDeductionsShortageReport } from '../../core/store/selectors/employee-reports.selector';
import { useRadioButtonChangeYesNoProps } from '../../utilities/hooks';
import { ModalProps } from '../employee/EmployeeToolbar';

const DeductionShortagesReportModal: React.FC<ModalProps> = ({
  show,
  onHide,
}) => {
  const [showPdf, setShowPdf] = useState(false);

  const { watch, register } = useForm<any>({
    defaultValues: { rdFileType: 'Pdf' },
  });
  const { rdFileType, rdRunReportBy } = watch([
    'rdFileType',
    'rdRunReportBy',
  ]);
  const dispatch = useDispatch();
  const report = useSelector(getSelectedEmpDeductionsShortageReport);

  const downloadExcelFile = () => {
    CommonService.downloadBase64File(
      'application/excel',
      report,
      'deductions_shortage_report.xlsx',
    );
  };

  const getReport = () => {
    dispatch(
      getEmployeeDeductionsShortage({
        fileType: rdFileType,
        runReportBy: rdRunReportBy,
      }),
    );
  };

  useEffect(() => {
    show && getReport();
  }, [rdFileType, rdRunReportBy]);

  const closeModal = (e?: React.SyntheticEvent) => {
    e?.stopPropagation();
    if (report) {
      dispatch(clearEmployeeDeductionsShortageReport());
    }
    onHide();
  };

  const [fs, setFs] = useState<RadioYesNoProps[]>([
    {
      name: 'rdFileType',
      label: 'Report file type :',
      defaultChecked: 'Pdf',
      groupClass: 'gc95',
      radioOptions: [
        {
          value: 'Pdf',
          label: 'Pdf',
        },
        {
          value: 'Excel',
          label: 'Excel',
        },
      ],
    },
    {
      name: 'rdRunReportBy',
      label: 'Run report by :',
      defaultChecked: 'ByEmployee',
      groupClass: 'gc95 ',
      radioOptions: [
        {
          value: 'ByEmployee',
          label: 'Employee',
        },
        {
          value: 'ByDeduction',
          label: 'Deduction',
        },
      ],
    },
  ]);

  const handleFileTypeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const currentState = [...fs];
    const runReportFileType = currentState.find(
      (a) => { return a.name === 'rdFileType'; },
    );
    runReportFileType!.defaultChecked = ev.target.value;
    const runReportBy = currentState.find((a) => { return a.name === 'rdRunReportBy'; });
    if (ev.target.value === 'Excel') {
      runReportBy!.disabled = true;
      runReportBy!.defaultChecked = 'ByEmployee';
    } else {
      runReportBy!.disabled = false;
    }
    setFs(currentState);
  };

  const [handleRadioButtonChange] = useRadioButtonChangeYesNoProps(fs);

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      {!showPdf ? (
        <Modal
          show={show}
          onHide={onHide}
          size="sm"
          animation={false}
        >
          <div className="modal-header">
            <div className="dm-card-title">
              Deduction Shortages Report
            </div>
            <button
              type="button"
              className="modal-close-btn"
              onClick={closeModal}
            >
              <Icon name="times" />
            </button>
          </div>
          <Modal.Body>
            <div className="row pl-3">
              Select file type and report by :
            </div>
            <div className="row mt-2">
              <div className="col">
                <RadioGrp
                  {...fs.find((a) => { return a.name === 'rdFileType'; })}
                  onChange={handleFileTypeChange}
                  ref={register}
                  name="rdFileType"
                />
              </div>
              <div className="col">
                <RadioGrp
                  {...fs.find(
                    (a) => { return a.name === 'rdRunReportBy'; },
                  )}
                  onChange={handleRadioButtonChange}
                  ref={register}
                  name="rdRunReportBy"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-9 text-right">
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
              <div className="col-3">
                {rdFileType === 'Pdf' ? (
                  <button
                    type="button"
                    className="btn btn-primary orange-button"
                    onClick={() => {
                      setShowPdf(true);
                    }}
                  >
                    Preview
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary orange-button"
                    onClick={() => {
                      downloadExcelFile();
                    }}
                  >
                    Download
                  </button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <PDFViewerModal
          show={show}
          onHide={closeModal}
          pdfData={report}
          reportName="DeductionsShortagesReport"
        />
      )}
    </div>
  );
};

export default DeductionShortagesReportModal;
