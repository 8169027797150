import React from 'react';
import styles from './_styles.module.scss';
import { CellProps } from './types';
import { isFunction } from 'lodash';

const Cell = ({ children, styleClass, position = 'start', toolTipText = '', onClick }: CellProps) => (
  <div
    className={`${styles['dm-table-cell']} ${styles[styleClass]} justify-content-${position}`}
    onClick={onClick}
  >
    {/* May add this back in later, seems kinda annoying at the moment */}
    {/*     <span className={styles.tooltip}>
      {(typeof children === 'string' || typeof children === 'number') ? `${children}` : toolTipText}
    </span> */}
    {isFunction(children) ? children() : children}
  </div>
);

export default Cell;