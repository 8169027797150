import { CheckboxGrp } from 'core/components/form-controls';
import Icon from 'core/components/shared/Icon';
import { DesignHistory, PageCustomField, PageField } from 'core/models';
import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useFieldArray } from 'react-hook-form';
import CustomFieldAnswer from './CustomFieldAnswer';
import { toTitleCase } from 'utilities/utilities';
import { useAppSelector } from 'utilities/hooks';
import PageFieldAnswer from './PageFieldAnswer';

type Props = {
  section: string;
  formMethods: any;
  openEdit: (selectedField: PageCustomField) => void;
  onDelete: (selectedField: PageCustomField) => void;
  personalInformation?: DesignHistory;
};

const FieldHistoryTab = ({ section, formMethods, openEdit, onDelete, personalInformation }: Props) => {
  const modifyUser = useAppSelector((state) => { return state.auth.userAccess?.dmUserName; });  
  const [referralSource, setReferralSource] = useState<PageField>();
  const [referralSourceIndex, setReferralSourceIndex] = useState<number>(0);
  const [isEHOpen, setIsEHOpen] = useState(false);
  const [isRSOpen, setIsRSOpen] = useState(false);
  const [isCSOpen, setIsCSOpen] = useState(false);

  const { fields: pageFields } = useFieldArray({
    control: formMethods.control,
    name: `${section}.pageFields`,
    keyName: 'id',
  });

  const { fields: pageCustomFields } = useFieldArray({
    control: formMethods.control,
    name: `${section}.pageCustomFields`,
    keyName: 'id',
  });

  useEffect(() => {
    if (personalInformation?.pageFields) {
      const rs = {
        ...personalInformation?.pageFields.find((f: PageField) => {
          return f.fieldDescription === 'Referral Source';
        }),
      } as PageField;
      rs && setReferralSource(rs);

      rs && setReferralSourceIndex(
        personalInformation?.pageFields.findIndex((f: PageField) => {
          return f.fieldDescription === 'Referral Source';
        }));
    }
  }, [personalInformation]);

  const onEdit = (selectedField: PageCustomField) => {
    openEdit(selectedField);
  };

  const onDeleteClick = (selectedField: PageCustomField) => {
    onDelete(selectedField);
  };

  return (
    <div key={section}>
      <div className="dm-panel dm-panel-border p-2">
        <input
          type="hidden"
          name={`${section}.visible`}
          ref={formMethods.register( { 
            setValueAs: (value: string) => {return value === 'true';}, 
          },
          )}
        />           
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header className="p-3">
              <Accordion.Toggle
                eventKey={'4'}
                style={{
                  background: 'transparent',
                  border: 'none',
                }}
                onClick={() => {
                  setIsEHOpen(!isEHOpen);
                }}
              >
                <Icon
                  name={isEHOpen ? 'chevron-up' : 'chevron-down'}
                  className="mr-1"
                />
                {toTitleCase(section)}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={'4'}>
              <Card.Body
                className="p-3"
                style={{ gap: '4rem' }}
              >
                <div className="d-flex">
                  <div
                    className="d-flex flex-row flex-wrap flex-grow-1"
                  >
                    <div
                      className="d-flex flex-row flex-wrap pr-4"
                      style={{ width: '50%' }}
                    >
                      <div className="d-flex justify-content-start">

                      </div>
                      <div className="d-flex justify-content-end flex-grow-1 mt-2">
                        <div
                          className="d-flex flex-column text-center"
                          style={{ width: 75 }}
                        >
                          <span><u>Visible</u></span>
                        </div>
                        <div
                          className="d-flex flex-column text-center"
                          style={{ width: 75 }}
                        >
                          <span><u>Required</u></span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex flex-row flex-wrap pr-4"
                      style={{ width: '50%' }}
                    >
                      <div className="d-flex justify-content-start">

                      </div>
                      <div className="d-flex justify-content-end flex-grow-1 mt-2">
                        <div
                          className="d-flex flex-column text-center"
                          style={{ width: 75 }}
                        >
                          <span><u>Visible</u></span>
                        </div>
                        <div
                          className="d-flex flex-column text-center"
                          style={{ width: 75 }}
                        >
                          <span><u>Required</u></span>
                        </div>
                      </div>
                    </div>
                    {pageFields
                      .map((field, index) => {
                        if (field.fieldDescription === 'Referral Source') return;
                        
                        return (
                          <div
                            key={field.id}
                            className="d-flex flex-row flex-wrap pr-4"
                            style={{ width: '50%' }}
                          >
                            <input
                              type="hidden"
                              name={`${section}.pageFields[${index}].fieldId`}
                              defaultValue={field.fieldId}
                              ref={formMethods.register({ valueAsNumber: true })}
                            />                           
                            <input
                              type="hidden"
                              name={`${section}.pageFields[${index}].pageId`}
                              defaultValue={field.pageId}
                              ref={formMethods.register({ valueAsNumber: true })}
                            />                            
                            <input
                              type="hidden"
                              name={`${section}.pageFields[${index}].fieldDescription`}
                              defaultValue={field.fieldDescription}
                              ref={formMethods.register()}
                            />   
                            <input
                              type="hidden"
                              name={`${section}.pageFields[${index}].answers`}
                              defaultValue={[]}
                              ref={formMethods.register( { setValueAs: (value: string[]) => {return [];} })}
                            />                            
                            <div className="d-flex justify-content-start">
                              {field.fieldDescription}
                            </div>
                            <div className="d-flex justify-content-end flex-grow-1">
                              <div
                                className="d-flex flex-column text-center"
                                style={{ width: 75 }}
                              >
                                <CheckboxGrp
                                  label=""
                                  name={`${section}.pageFields[${index}].visible`}
                                  defaultChecked={field.visible}
                                  ref={formMethods.register()}
                                />
                              </div>
                              <div
                                className="d-flex flex-column text-center"
                                style={{ width: 75 }}
                              >
                                <CheckboxGrp
                                  label=""
                                  name={`${section}.pageFields[${index}].required`}
                                  defaultChecked={field.required}
                                  disabled={field.fieldDescription === 'Gender' || 
                                  field.fieldDescription === 'EEO Race'}
                                  ref={formMethods.register()}
                                />
                              </div>
                            </div>                          
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
      {section === 'personalInformation' && referralSource && (
        <div className="dm-panel dm-panel-border p-2">
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header className="p-3">
                <Accordion.Toggle
                  eventKey={'4'}
                  style={{
                    background: 'transparent',
                    border: 'none',
                  }}
                  onClick={() => {
                    setIsRSOpen(!isRSOpen);
                  }}
                >
                  <Icon
                    name={isRSOpen ? 'chevron-up' : 'chevron-down'}
                    className="mr-1"
                  />
                  Referral Source
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={'4'}>
                <Card.Body
                  className="p-3"
                  style={{ gap: '4rem' }}
                >
                  <div                    
                    className="d-flex flex-column"
                  >
                    <div
                      key={pageFields?.find((f:any) => {
                        return f.fieldDescription === 'Referral Source';
                      })?.id}
                      className="d-flex flex-row col-6"
                    >
                      <div className="d-flex justify-content-start pt-4">
                        <input
                          type="hidden"
                          name={`${section}.pageFields[${referralSourceIndex}].fieldId`}
                          defaultValue={referralSource.fieldId}
                          ref={formMethods.register({ valueAsNumber: true })}
                        />                           
                        <input
                          type="hidden"
                          name={`${section}.pageFields[${referralSourceIndex}].pageId`}
                          defaultValue={referralSource.pageId}
                          ref={formMethods.register({ valueAsNumber: true })}
                        />                            
                        <input
                          type="hidden"
                          name={`${section}.pageFields[${referralSourceIndex}].fieldDescription`}
                          defaultValue={referralSource.fieldDescription}
                          ref={formMethods.register()}
                        />   
                        {referralSource.fieldDescription}
                      </div>
                      <div
                        className="d-flex justify-content-end flex-grow-1 mt-2"
                      >
                        <div
                          className="d-flex flex-column text-center"
                          style={{ width: 75 }}
                        >
                          <span><u>Visible</u></span>
                          <div>                          
                            <CheckboxGrp
                              label=""
                              defaultChecked={referralSource.visible} 
                              name={`${section}.pageFields[${referralSourceIndex}].visible`}                             
                              ref={formMethods.register()}  
                            />
                          </div>
                        </div>
                        <div
                          className="d-flex flex-column text-center"
                          style={{ width: 75 }}
                        >
                          <span><u>Required</u></span>
                          <div>
                            <CheckboxGrp
                              label=""
                              defaultChecked={referralSource.required}
                              name={`${section}.pageFields[${referralSourceIndex}].required`}
                              ref={formMethods.register()}  
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <PageFieldAnswer                    
                      fieldIndex={referralSourceIndex}
                      formMethods={formMethods}
                    />
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      )}
      <div className="dm-panel dm-panel-border p-2">
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header className="p-3">
              <Accordion.Toggle
                eventKey={'4'}
                style={{
                  background: 'transparent',
                  border: 'none',
                }}
                onClick={() => {
                  setIsCSOpen(!isCSOpen);
                }}
              >
                <Icon
                  name={isCSOpen ? 'chevron-up' : 'chevron-down'}
                  className="mr-1"
                />
                Custom Fields
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={'4'}>
              <Card.Body
                className="p-3"
                style={{ gap: '4rem' }}
              >

                {pageCustomFields.map((field, index) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <div
                      key={field.id}
                      className="d-flex flex-row"
                    >
                      <div className="d-flex flex-column flex-grow-1">

                        <div className="d-flex flex-row justify-content-between">
                          <div style={{ width: 200 }}>
                            &nbsp;
                          </div>
                          <div className="d-flex flex-row">
                            <div
                              className="text-center"
                              style={{ width: 75 }}
                            >
                              <span><u>Visible</u></span>
                            </div>
                            <div
                              className="text-center"
                              style={{ width: 75 }}
                            >
                              <span><u>Required</u></span>
                            </div>

                          </div>
                          <div
                            className="text-center"
                            style={{ width: 125 }}
                          >
                            <span><u>Last Modified By</u></span>
                          </div>
                          <div style={{ width: 125 }}>
                            &nbsp;
                          </div>
                        </div>
                        <div                        
                          className="d-flex flex-column flex-grow-1"
                        >
                          <div className="d-flex flex-row justify-content-between">
                            <input
                              type="hidden"
                              name={`${section}.pageCustomFields[${index}].customFieldId`}
                              defaultValue={field.customFieldId}
                              ref={formMethods.register({ valueAsNumber: true })}
                            />
                            <input
                              type="hidden"
                              name={`${section}.pageCustomFields[${index}].webSiteConfigId`}
                              defaultValue={field.webSiteConfigId}
                              ref={formMethods.register({ valueAsNumber: true })}
                            />
                            
                            <input
                              type="hidden"
                              name={`${section}.pageCustomFields[${index}].pageId`}
                              defaultValue={field.pageId}
                              ref={formMethods.register({ valueAsNumber: true })}
                            />
                            <input
                              type="hidden"
                              name={`${section}.pageCustomFields[${index}].label`}
                              defaultValue={field.label}
                              ref={formMethods.register()}
                            />
                            <input
                              type="hidden"
                              name={`${section}.pageCustomFields[${index}].name`}
                              defaultValue={field.name}
                              ref={formMethods.register()}
                            />
                            <input
                              type="hidden"
                              name={`${section}.pageCustomFields[${index}].description`}
                              defaultValue={field.description}
                              ref={formMethods.register()}
                            />
                            <input
                              type="hidden"
                              name={`${section}.pageCustomFields[${index}].hasPredefinedAnswers`}
                              defaultValue={field.hasPredefinedAnswers}
                              ref={formMethods.register( { 
                                setValueAs: (value: string) => {return value === 'true';}, 
                              },
                              )}
                            />
                            <input
                              type="hidden"
                              name={`${section}.pageCustomFields[${index}].archived`}
                              defaultValue={field.archived}
                              ref={formMethods.register( { 
                                setValueAs: (value: string) => {return value === 'true';}, 
                              },
                              )}
                            />
                            <input
                              type="hidden"
                              name={`${section}.pageCustomFields[${index}].modifyUser`}
                              defaultValue={modifyUser}
                              ref={formMethods.register()}
                            />
                            <input
                              type="hidden"
                              name={`${section}.pageCustomFields[${index}].alreadyAnswered`}
                              defaultValue={field.alreadyAnswered}
                              ref={formMethods.register( { 
                                setValueAs: (value: string) => {return value === 'true';}, 
                              },
                              )}
                            />
                            <input
                              type="hidden"
                              name={`${section}.pageCustomFields[${index}].customFieldQA`}
                              defaultValue={[]}
                              ref={formMethods.register( { setValueAs: () => {return [];} })}
                            />

                            {!field.customAnswers?.length ? (
                              <input
                                type="hidden"
                                name={`${section}.pageCustomFields[${index}].customAnswers`}
                                defaultValue={[]}
                                ref={formMethods.register( { setValueAs: () => {return [];} })}
                              />) : null}

                            <div style={{ width: 200 }}>
                              {field.description}
                            </div>
                            <div className="d-flex flex-row">
                              <div
                                className="text-center"
                                style={{ width: 75 }}
                              >
                                <CheckboxGrp
                                  label=""
                                  name={`${section}.pageCustomFields[${index}].visible`}
                                  defaultChecked={field.visible}
                                  ref={formMethods.register()}
                                />
                              </div>
                              <div
                                className="text-center"
                                style={{ width: 75 }}
                              >
                                <CheckboxGrp
                                  label=""
                                  name={`${section}.pageCustomFields[${index}].required`}
                                  defaultChecked={field.required}
                                  ref={formMethods.register()}
                                />
                              </div>
                            </div>
                            <div
                              className="text-center"
                              style={{ width: 125 }}
                            >
                              {field.modifyUser}
                            </div>
                            <div style={{ width: 125 }}>
                              <button
                                type="button"
                                className="btn btn-link mr-3"
                                onClick={() => { return onEdit(field as PageCustomField); }}
                              >
                                <Icon
                                  name="edit"
                                  className="fa-edit"
                                />
                              </button>

                              <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => { return onDeleteClick(field as PageCustomField); }}
                              >
                                <Icon
                                  name="minus-circle"
                                  className="fa-minus-circle"
                                />
                              </button>
                            </div>
                          </div>
                          {field.customAnswers?.length ? (
                            <CustomFieldAnswer
                              f={field}
                              fieldIndex={index}
                              formMethods={formMethods}
                              section={section}
                            />) : null}

                          <hr className="dm-panel-hr" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  );
};

export default FieldHistoryTab;