import { InputGrp } from 'core/components/form-controls';
import { ShiftPremium } from 'core/models/ShiftPremium.model';
import { getShiftPremiumEarningsCodes, getShiftPremiums } from 'core/store/selectors';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Property } from '../TransmittalEarningsItem';
import Modal from 'core/components/modals/Modal';
import './shift-premium-table-styles.scss';
import { useAppDispatch } from 'utilities/hooks';
import { loadShiftPremiums } from 'core/store/actions';

type Props = {
  show: boolean;
  onSelectProp: (property: Property, newValue: any) => void;
  onHide: () => void;
  previousShiftCode: number;
};

const ShiftPremiumTable = ({ show, onSelectProp, onHide }: Props) => {
  const shiftPremiums = useSelector(getShiftPremiums);
  const shiftECs = useSelector(getShiftPremiumEarningsCodes);
  
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(loadShiftPremiums());
  }, []);
  
  const [filteredShiftCodes, setFilteredShiftCodes] = useState<ShiftPremium[]>(shiftPremiums);
  
  useEffect(() => {
    setFilteredShiftCodes(shiftPremiums);
  }, [shiftPremiums]);
  
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    if (!query?.length) {
      setFilteredShiftCodes(shiftPremiums);
      return;
    }
    
    setFilteredShiftCodes(
      shiftPremiums?.filter((shiftPremium: ShiftPremium) => String(shiftPremium.shiftCode).includes(query)) ?? [],
    );
  };
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      title={'Change Shift Code'}
      size="xl"
    >
      <div className="d-flex flex-column mb-3">
        <InputGrp
          onChange={onChange}
          placeholder={'Filter by Shift Code'}
          autoFocus
        />
        <table>
          <thead>
            <tr>
              <th className="premium-table-cell narrow-column table-head">Shift Code</th>
              <th className="premium-table-cell narrow-column table-head">Earnings Code</th>
              <th className="premium-table-cell narrow-column table-head">Short Description</th>
              <th className="premium-table-cell table-head">Description</th>
              <th className="premium-table-cell narrow-column table-head">Is Percent</th>
              <th className="premium-table-cell table-head">Amount</th>
              <th className="premium-table-cell narrow-column table-head">Apply to All Earn Codes</th>
              <th className="premium-table-cell narrow-column table-head">Include in Control Totals</th>
              <th className="premium-table-cell table-head">Internal Comment</th>
            </tr>
          </thead>
          <tbody className="premium-table-body">
            {filteredShiftCodes?.map((shiftPremium: ShiftPremium) => {
              const matchingEarningCode = shiftECs?.find((shiftEc) => +shiftEc.id === +(shiftPremium.earningsCodeId ?? 0));
              
              return (
                <tr
                  className="premium-table-row"
                  key={shiftPremium.shiftPremiumId}
                  onClick={() => {
                    onSelectProp('shiftPremiumId', shiftPremium.shiftCode);
                    onHide();
                  }}
                >
                  <td className="premium-table-cell">{shiftPremium.shiftCode}</td>
                  <td className="premium-table-cell">{shiftPremium?.earningsCode ?? 'N/A'}</td>
                  <td className="premium-table-cell">{shiftPremium?.shortDescription ?? 'N/A'}</td>
                  <td className="premium-table-cell">{shiftPremium.description}</td>
                  <td className="premium-table-cell">
                    <input
                      type="checkbox"
                      checked={shiftPremium.isPercent}
                      disabled
                    />
                  </td>
                  <td className="premium-table-cell">{shiftPremium.amount}</td>
                  <td className="premium-table-cell">
                    <input
                      type="checkbox"
                      checked={shiftPremium.applyToAllEarnCodes}
                      disabled
                    />
                  </td>
                  <td className="premium-table-cell">
                    <input
                      type="checkbox"
                      checked={shiftPremium.includeInControlTotals}
                      disabled
                    />
                  </td>
                  <td className="premium-table-cell">{shiftPremium.internalComment}</td>
                </tr>
              );
            },
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default ShiftPremiumTable;