import React from 'react';
import { EarningsModalProps } from './types';
import Modal from 'core/components/modals/Modal';
import { payrollFrequencies } from 'dropdowns/payrollFrequencies';
import { ddLookup } from 'utilities/utilities';

const RecurringEarningsPromptModal = ({ setIncludeEarnings, show, onHide, activeFrequencies }: EarningsModalProps) => {
  const currentDescription = ddLookup(activeFrequencies?.currentId, payrollFrequencies, 'value');
  const nextDescription = ddLookup(activeFrequencies?.nextId, payrollFrequencies, 'value');
  
  return (
    <Modal show={show}
      onHide={onHide}
      size="sm"
      title="Notice"
    >
      {(closeModal) => {
        return (
          <div className="d-flex w-100 flex-wrap">
            <div>
              There are employees scheduled to have earnings taken on
              {activeFrequencies?.current ? ` the current payroll frequency '${currentDescription.trim()}'` : null}
              {activeFrequencies?.current && activeFrequencies?.next ? ' and ' : null} {/* overkill? never */}
              {activeFrequencies?.next ? `the next payroll frequency '${nextDescription.trim()}'` : null}. What action(s)
              would you like to take with these earnings?
            </div>
            <div className="d-flex flex-column mt-4">
              {activeFrequencies?.next ? (
                <div className="d-flex mb-2">
                  <span className="mr-2">Add earnings for {`'${nextDescription}'`} to transmittal</span>
                  <span>
                    <input
                      type="checkbox"
                      onChange={() => {
                        setIncludeEarnings((prevState) => {
                          return { ...prevState,
                            add: true };
                        });
                      }}
                    />
                  </span>
                </div>
              ) : null}
              {activeFrequencies?.current ? (
                <div className="d-flex">
                  <span className="mr-2">Remove earnings for {`'${currentDescription}'`} from transmittal</span>
                  <span>
                    <input
                      type="checkbox"
                      onChange={() => {
                        setIncludeEarnings((prevState) => {
                          return { ...prevState,
                            remove: true };
                        });
                      }}
                    />
                  </span>
                </div>
              ) : null}
            </div>
            <div className="d-flex w-100 mt-4 justify-content-end">
              <button
                className="orange-button mr-2"
                onClick={closeModal}
              >
                Accept
              </button>
            </div>
          </div>
        );
      }} 
    </Modal>
  );
};

export default RecurringEarningsPromptModal;