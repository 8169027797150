import { createReducer } from '@reduxjs/toolkit';
import { CalculateHourlySalaryAnnualPayRates, EmployeePayRateScreen, FuturePayRate, HourlySalaryAnnualPayRates, PayRate, PayRateHistory } from '../../models';
import {
  loadFuturePayRates,
  loadPayRates,
  loadPayRatesHistory,
  storeFuturePayRate,
  storeFuturePayRates,
  storePayRate,
  storePayRates,
  storePayRatesHistory,
  calculatePayRates,
  storeCalculatedPayRates,
  loadEmployeePayRatesScreen,
  storeEmployeePayRatesScreen,
  addUpdateEmployeePayRatesScreen,
  errorPayRateScreen,
} from '../actions';

export interface State {
  loading: boolean;
  saving: boolean;
  payRates: PayRate[];
  employeePayRateScreen: EmployeePayRateScreen | null;
  payRatesHistory: PayRateHistory[];
  futurePayRates: FuturePayRate[];
  calculatePayRates: HourlySalaryAnnualPayRates | null;
}

export const INITIAL_STATE: State = {
  loading: false,
  saving: false,
  payRates: [],
  payRatesHistory: [],
  futurePayRates: [],
  calculatePayRates: null,
  employeePayRateScreen: null,
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadPayRates.type]: (state) => {return { ...state, loading: true };},
  [storePayRates.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      payRates: action?.payload?.length ? action.payload.sort(
        (a: PayRate, b: PayRate) => {return a.rateId - b.rateId;},
      ) : [],
    };
  },
  [storePayRate.type]: (state, action) => {
    const newPayRates = [
      ...state.payRates.filter(
        (r: PayRate) => {return r.rateId !== action.payload.rateId;},
      ),
      action.payload,
    ];

    return {
      ...state,
      loading: false,
      payRates: newPayRates.sort(
        (p1: PayRate, p2: PayRate) => {return +p1.rateId - +p2.rateId;},
      ),
    };
  },

  [loadEmployeePayRatesScreen.type]: (state) => {return { ...state, loading: true };},
  [addUpdateEmployeePayRatesScreen.type]: (state, action) => {return { ...state, saving: true };},
  [errorPayRateScreen.type]: (state, action) => {return { ...state, saving: false };},
  [storeEmployeePayRatesScreen.type]: (state, action) => {
    return {
      ...state,
      saving: false,
      loading: false,
      employeePayRateScreen: action.payload,
    };
  },

  [loadPayRatesHistory.type]: (state) => {return { ...state, loading: true };},
  [storePayRatesHistory.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      payRatesHistory: action.payload,
    };
  },
  [calculatePayRates.type]: (state) => {return { ...state, loading: true };},
  [storeCalculatedPayRates.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      calculatePayRates: action.payload,
    };
  },
  [loadFuturePayRates.type]: (state) => {return { ...state, loading: true };},
  [storeFuturePayRates.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      futurePayRates: action.payload.sort((a: any, b: any) => {
        return a.effectiveDate > b.effectiveDate
          ? 1
          : b.effectiveDate > a.effectiveDate
            ? -1
            : 0;
      },
      ),
    };
  },
  [storeFuturePayRate.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      futurePayRates: [
        ...state.futurePayRates.filter(
          (pr: FuturePayRate) => {return pr.rateId !== action.payload.rateId;},
        ),
        {
          ...action.payload,
        },
      ].sort((a: any, b: any) => {
        return a.effectiveDate > b.effectiveDate
          ? 1
          : b.effectiveDate > a.effectiveDate
            ? -1
            : 0;
      },
      ),
    };
  },
});
