import { createReducer } from '@reduxjs/toolkit';

import { downloadEEOReport, getEEOPayrollDates, storeEEOCertifyOfficial, storeEEOCertifyOfficials, storeEEONoSetting, storeEEONoSettings, storeEEOPayrollDates, storeEEOReport, storeEEOUnitSettings, storeLookupEEOUnit, storeLookupEEOUnits } from '../actions';
import { EEOPayrollDate, EEOReportRequest, EEOUnitSetting, IEEONoSetting } from 'core/models/HumanCapitalReports.model';
import { LookupEEOCertifyOfficial, LookupEEOUnit } from 'core/models/EEO.model';


export interface State {
  loading: boolean;
  eeoNoSettings: IEEONoSetting[];
  eeoUnitSettings: EEOUnitSetting[];
  lookupEEOUnit: LookupEEOUnit[];
  lookupCertifyOfficial: LookupEEOCertifyOfficial[];
  eeoReport: EEOReportRequest;
  eeoPayrollDates: EEOPayrollDate[];
}

const INITIAL_STATE = {
  loading: false,
  eeoNoSettings: [],
  eeoUnitSettings: [],
  lookupEEOUnit: [],
  lookupCertifyOfficial: [],
  eeoReport: '',
  eeoPayrollDates: [],
};

export const reducer = createReducer(INITIAL_STATE, {
  [getEEOPayrollDates.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      eeoPayrollDates: action.payload,
    };
  },
  [storeEEOPayrollDates.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      eeoPayrollDates: action.payload,
    };
  },
  [downloadEEOReport.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      eeoReport: action.payload,
    };
  },
  [storeEEOReport.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      eeoReport: action.payload,
    };
  },
  [storeEEONoSettings.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      eeoNoSettings: action.payload,
    };
  },
  [storeEEONoSetting.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      eeoNoSettings: [
        ...state.eeoNoSettings.filter((i: IEEONoSetting) => {return i.ruleID !== action.payload.ruleID;}),
        action.payload,
      ],
    };
  },

  [storeEEOUnitSettings.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      eeoUnitSettings: action.payload,
    };
  },

  [storeLookupEEOUnits.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      lookupEEOUnit: action.payload,
    };
  },
  [storeLookupEEOUnit.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      lookupEEOUnit: [
        ...state.lookupEEOUnit.filter((i: LookupEEOUnit) => { return i.eeoUnitID !== action.payload.eeoUnitID; }),
        action.payload,
      ],
    };
  },

  [storeEEOCertifyOfficials.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      lookupCertifyOfficial: action.payload,
    };
  },
  [storeEEOCertifyOfficial.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      lookupCertifyOfficial: [
        ...state.lookupCertifyOfficial.filter((i: LookupEEOCertifyOfficial) => { return i.certifyOfficalID !== action.payload.certifyOfficalID; }),
        action.payload,
      ],
    };
  },
});
