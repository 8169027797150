import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import EmpDeductionItem from './EmpDeductionItem';
import { Deduction, DeductionRequest } from 'core/models';
import PanelHeader from 'core/components/shared/PanelHeader';
import { useDispatch, useSelector } from 'react-redux';
import { loadCheckCodes, loadDeductions, createDeduction, loadGarnishmentEntities, toggleBlockNavigation } from 'core/store/actions';
import { getDeductions, getGarnishments } from 'core/store/selectors/deduction.selector';
import { getClientGarnishmentDedNo, getSelectedEmp } from 'core/store/selectors';
import EmpGarnishmentItem from './EmployeeGarnishmentItem';
import { useParams } from 'react-router-dom';
import Icon from 'core/components/shared/Icon';
import { useFieldArray, useForm } from 'react-hook-form';
import { UNSAVED_MESSAGE } from 'core/constants';
import { DeductionService } from 'core/services';
import SaveButton from 'core/components/shared/SaveButton';
import { getAccess } from 'utilities/utilities';
import { useAppSelector } from 'utilities/hooks';

const EmpDeductionsPage = () => {
  const dispatch = useDispatch();
  const selEmp = useSelector(getSelectedEmp);
  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();
  const deductionStore = useSelector(getDeductions);
  const garnishmentStore = useSelector(getGarnishments);
  const clientGarnishmentDedNo = useSelector(getClientGarnishmentDedNo) as number;
  const { saving } = useAppSelector(({ selEmployeeDetails }) => selEmployeeDetails.deduction);
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  
  const [activeKey, setActiveKey] = useState('deductionItems');
  const [deleteIdDeductions, setDeleteIdDeductions] = useState<number[]>([]);
  const [deleteIdGarnishments, setDeleteIdGarnishments] = useState<number[]>([]);

  type FormType = {
    deductionItems: Deduction[];
    garnishmentItems: Deduction[];
  };

  const {
    control,
    ...formMethods
  } = useForm<FormType>({
    defaultValues: {
      deductionItems: deductionStore,
      garnishmentItems: garnishmentStore,
    },
  });
  
  const { formState: { isDirty, dirtyFields } } = formMethods;

  //Create the Deduction Items Field Array
  const { fields: deductionFields, append: appendDeduction } =
    useFieldArray<Deduction>({
      control,
      name: 'deductionItems',
      keyName: 'id',
    });

  //Created the Garnishment Items Field Array
  const { fields: garnishmentFields, append: appendGarnishments } =
    useFieldArray<Deduction>({
      control,
      name: 'garnishmentItems',
      keyName: 'id',
    });
  
  //When we change employees load the deductions for those employees
  useEffect(() => {
    setDeleteIdDeductions([]);
    setDeleteIdGarnishments([]);
    dispatch(loadDeductions(protectedEmpNo));
    dispatch(loadCheckCodes());
    dispatch(loadGarnishmentEntities());
  }, [protectedEmpNo]);

  //This will see if the garnishment items and deduction items change (This will only be used on inital load to load the information from the GET)
  //If so we will reset the form without that record
  useEffect(() => {
    if (['pending', 'error'].includes(saving)) return; // don't want to set these to blank when saving or we errored
    formMethods.reset({
      garnishmentItems: garnishmentStore.map((e: Deduction) => {
        return {
          ...e,
        };
      }),
      deductionItems: deductionStore.map((e: Deduction) => {
        return {
          ...e,
        };
      }),
    });
  }, [deductionStore, garnishmentStore, saving]);

  //Adds a deduction to the Deduction Field
  const onAddDeduction = () => {
    setActiveKey('deductionItems');
    window.scrollTo(0, document.body.scrollHeight);
    if (selEmp) {
      appendDeduction({
        protectedEmpNo: protectedEmpNo,
        //We add a random id so we are able to delete the specific id and not show it. Gets converted to 0 on save
        dedId: parseInt((Math.random() * -10000).toString()),
        status: 'ACTIVE',
        apid: null,
        deductionType: 'Deduction',
        unit: '',
        freq: '0',
      });
    }
  };

  //Adds a garnishment to the Garnishment Field
  const onAddGarnishment = () => {
    setActiveKey('garnishmentItems');
    window.scrollTo(0, document.body.scrollHeight);
    if (selEmp) {
      appendGarnishments({
        clientNo: selEmp.clientNo,
        empNo: selEmp.empNo,
        protectedEmpNo: selEmp.protectedEmpNo,
        categoryId: -1,
        //We add a random id so we are able to delete the specific id and not show it. Gets converted to 0 on save
        dedId: parseInt((Math.random() * -10000).toString()),
        apid: null,
        entityId: 0,
        deductionType: 'Garnishment',
        status: 'ACTIVE',
        freq: '0',
      });
    }
  };

  //Will check to see if the deleted deduction is a standard or garnishment deduction and delete it and set the deduction item. 
  const onDeleteDeductionGarnishment = (deduction: Deduction) => {
    if (!confirm(`Delete ${deduction.deductionType.toLowerCase()}? This action cannot be undone.`)) return;
    
    if (deduction.dedId > 0) {
      DeductionService.deleteDeduction(deduction).catch((err: any) => { return alert(err); });
    } 
    
    switch (deduction.deductionType.toLowerCase()) {
      case 'deduction':
        setDeleteIdDeductions((prevState) => [...new Set([...(prevState ?? []), deduction.dedId])]);
        break;
      case 'garnishment':
        setDeleteIdGarnishments((prevState) => [...new Set([...(prevState ?? []), deduction.dedId])]);
        break;
    }
  };

  //Send back all the garnishments and deductions to be updated at once so we dont delete the garnishments when updating deductions.
  const onSubmitDeduction = (data: FormType) => {
    if (!data || !selEmp) return;
    const deductions: Deduction[] = [];

    //Will ensure that we do not try to send back any deleted items incase they are there since we do not update the items
    const nonDeletedDeductions = structuredClone(data.deductionItems)?.filter(x => !deleteIdDeductions.includes(x.dedId));
    nonDeletedDeductions?.forEach(ded => {
      const deduction = new Deduction(selEmp.clientNo, selEmp.empNo, selEmp.protectedEmpNo, ded?.dedId ?? 0, 'Deduction', ded);
      deduction.dateStart = (ded.dateStart === '') ? null : ded.dateStart;
      deduction.dateExpire = (ded.dateExpire === '') ? null : ded.dateExpire;
      deduction.arrearWeekEnd = (ded.arrearWeekEnd === '') ? null : ded.arrearWeekEnd;
      deduction.arrearCheckDate = (ded.arrearCheckDate === '') ? null : ded.arrearCheckDate;
      deduction.dedId = (ded.dedId > 0) ? ded.dedId : 0;
      deduction.active = ded.status !== 'DEACTIVE';
      deduction.skipping = ded.status === 'SKIPONCE';
      deduction.bankAcctNo = ded?.bankAcctNo ?? '';
      deduction.routingNo = ded?.routingNo ?? '';
      
      deductions.push(deduction);
    }); 

    //Will ensure that we do not try to send back any deleted items incase they are there since we do not update the items
    const nonDeletedGarnishments = structuredClone(data.garnishmentItems)?.filter(x => !deleteIdGarnishments.includes(x.dedId));
    nonDeletedGarnishments?.forEach(ded => {
      const deduction = new Deduction(selEmp.clientNo, selEmp.empNo, selEmp.protectedEmpNo, ded?.dedId ?? 0, 'Garnishment', ded);
      deduction.dedNo = clientGarnishmentDedNo;
      deduction.freq = '0';
      deduction.arrearWeekEnd = (ded.arrearWeekEnd === '') ? null : ded.arrearWeekEnd;
      deduction.arrearCheckDate = (ded.arrearCheckDate === '') ? null : ded.arrearCheckDate;
      deduction.dateStart = (ded.dateStart === '') ? null : ded.dateStart;
      deduction.dateExpire = (ded.dateExpire === '') ? null : ded.dateExpire;
      deduction.dedId = (ded.dedId > 0) ? ded.dedId : 0;
      deduction.bankAcctNo = ded?.bankAcctNo ?? '';
      deduction.routingNo = ded?.routingNo ?? '';

      deductions.push(deduction);
    }); 
    const request: DeductionRequest = {
      protectedEmpNo,
      Data: deductions,
    };
    dispatch(createDeduction(request));
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: (dirtyFields?.garnishmentItems?.length ?? 0) > 0 || (dirtyFields?.deductionItems?.length ?? 0) > 0,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [(dirtyFields?.garnishmentItems?.length ?? 0) > 0 || (dirtyFields?.deductionItems?.length ?? 0) > 0]);

  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Deductions">
        <button
          {...getAccess(sectionAccess, 5, undefined, { disabledSameAsReadOnly: true })}
          className="btn btn-link dm-grid-action-title pb-0 mr-2"
          onClick={onAddDeduction}
        >
          Add Deduction{' '}
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button>
        <button
          {...getAccess(sectionAccess, 5, undefined, { disabledSameAsReadOnly: true })}
          className="btn btn-link dm-grid-action-title pb-0 mr-2"
          onClick={onAddGarnishment}
        >
          Add Garnishment{' '}
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button>
      </PanelHeader>
      <Tabs
        className="mt-3"
        activeKey={activeKey}
        onSelect={(k) => {
          if (!k) return;
          setActiveKey(k);
        }}
        id="deduction-garnishment"
      >
        <Tab
          eventKey="deductionItems"
          title="Employee Deductions"
        >
          <form onSubmit={(e) => {
            e.preventDefault();
            formMethods.handleSubmit(onSubmitDeduction)(e);
            e.stopPropagation();
          }}
          >
            {deductionFields.map((item, index) => {
              //If the deduction is deleted do not show it
              if (deleteIdDeductions.includes(item?.dedId || 0)) return;
              return (
                <div key={item.id}>
                  <EmpDeductionItem
                    key={item.id}
                    deduction={item}
                    index={index}
                    control = {control}
                    formMethods={formMethods}
                    onDelete={onDeleteDeductionGarnishment}
                  />
                  {index + 1 < deductionFields.length && (
                    <hr className="dm-panel-hr mt-2" />
                  )}
                </div>
              );
            })}
            <div className="text-right mt-3">
              <button
                {...getAccess(sectionAccess, 5, undefined, { disabledSameAsReadOnly: true,  disabledDefault: !isDirty })}
                type="submit"
                className="btn orange-button mr-3"
              >
                Save
              </button>
            </div>
          </form>
        </Tab>
        <Tab
          eventKey="garnishmentItems"
          title="Garnishments"
        >
          <form onSubmit={(e) => {
            e.preventDefault();
            formMethods.handleSubmit(onSubmitDeduction)(e);
            e.stopPropagation();
          }}
          >
            {garnishmentFields.map((item, index) => {
              //If the garnishment is deleted do not show it
              if (deleteIdGarnishments.includes(item?.dedId || 0)) return;
              return (
                <div key={item.id}>
                  <EmpGarnishmentItem
                    key={item.id}
                    index={index}
                    deduction={item}
                    formMethods={formMethods}
                    control={control}
                    onDelete={onDeleteDeductionGarnishment}
                  />
                  {index + 1 < garnishmentFields.length && (
                    <hr className="dm-panel-hr mt-2" />
                  )}
                </div>
              );
            })}
            <div className="text-right mt-3">
              <SaveButton
                {...getAccess(sectionAccess, 5, undefined, { disabledSameAsReadOnly: true, disabledDefault: !isDirty || (saving === 'pending') })}
                classes="btn orange-button"
                saving={saving === 'pending'}
              />
            </div>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};

export default EmpDeductionsPage;
