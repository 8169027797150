import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ControlDatePickerGrp, InputGrpCurrency, InputGrpDecimal, SelectModalGrp } from 'core/components/form-controls';
import HistoryLabel from 'core/components/form-controls/HistoryLabel';
import DepartmentOptionForm from 'core/components/form-controls/select-modal/DepartmentOptionForm';
import DropdownOptionForm from 'core/components/form-controls/select-modal/DropdownOptionForm';
import GradeLevelOptionForm from 'core/components/form-controls/select-modal/GradeLevelOptionForm';
import LocationOptionForm from 'core/components/form-controls/select-modal/LocationOptionForm';
import SubDepartment2OptionForm from 'core/components/form-controls/select-modal/SubDepartment2OptionForm';
import SubDepartmentOptionForm from 'core/components/form-controls/select-modal/SubDepartmentOptionForm';
import { UNSAVED_MESSAGE } from 'core/constants';
import { HRIncrease, HRIncreaseRequest } from 'core/models/HREmployee.model';
import { updateHRIncrease } from 'core/store/actions/hr-employee.action';
import {
  getLocationDepartmentsWithoutHome,
  getPayGrades,
  getPerformanceIncreaseTypes,
} from 'core/store/selectors';
import { currencyFormatter } from 'utilities/utilities';
import { toggleBlockNavigation } from 'core/store/actions';

type PropTypes = {
  increase: HRIncrease,
  protectedEmpNo: string;
};

export const PayIncrease: React.FC<PropTypes> = ({
  increase,
  protectedEmpNo,
}) => {
  const dispatch = useDispatch();

  const [minPayGrade, setMinPayGrade] = useState(0);
  const [midPayGrade, setMidPayGrade] = useState(0);
  const [maxPayGrade, setMaxPayGrade] = useState(0);

  const payGradesOpts = useSelector(getPayGrades);
  const performanceIncreaseTypes = useSelector(getPerformanceIncreaseTypes);
  const { locationOpts, deptOpts, subdeptOpts, subdept2Opts } = useSelector(
    getLocationDepartmentsWithoutHome,
  );

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    reset,
    control,
    formState,
  } = useForm<HRIncrease>({
    defaultValues: increase,
  });

  const {
    errors,
  } = formState;

  const gradeLevel = watch('payGrade');


  useEffect(() => {
    const payGrade = payGradesOpts.find((pg) => { return pg.id === gradeLevel; });
    if (payGrade) {
      setMinPayGrade(payGrade.minAmount);
      setMidPayGrade(payGrade.midAmount);
      setMaxPayGrade(payGrade.maxAmount);
    }
  }, [gradeLevel]);

  useEffect(() => {
    increase && reset(increase);
  }, [increase]);

  const onSubmit = (data: HRIncrease, e?: React.BaseSyntheticEvent) => {
    if (!e?.target.isConnected) {
      return false;
    }

    const request: HRIncreaseRequest = {
      protectedEmpNo,
      data: {
        ...data,
        increaseId: +data.increaseId,

      },
    };

    dispatch(updateHRIncrease(request));
  };


  const onCancel = () => {
    reset();
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: formState.isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [formState.isDirty]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="hidden"
          name={'increaseId'}
          defaultValue={increase.increaseId}
          ref={register({ valueAsNumber: true })}
        />
        <Row>
          <Col>
            <div className="d-flex">
              <div className="d-flex flex-row flex-grow-1">
                {locationOpts?.length > 0 && (
                  <>
                    <div className="d-flex flex-column flex-grow-1 mr-1">
                      <HistoryLabel
                        labelTitle="Location"
                        section="payrollInfo"
                        field="loc"
                        protectedEmpNo={protectedEmpNo}
                      />
                      <SelectModalGrp
                        name="loc"
                        labelField="locationDesc"
                        valueField="locationCode"
                        modalTitle="LOCATION"
                        formComponent={LocationOptionForm}
                        required={true}
                        showId={true}
                        addOptionText="Location"
                        options={locationOpts}
                        errors={errors.loc}
                        control={control}
                        value={increase?.loc}
                        rules={{ required: 'Location is required' }}
                        setValue={setValue}
                      />
                    </div>
                  </>
                )}

                {deptOpts?.length > 0 && (
                  <>
                    <div className="d-flex flex-column flex-grow-1 mr-1">
                      <HistoryLabel
                        labelTitle="Department"
                        section="payrollInfo"
                        field="dept"
                        protectedEmpNo={protectedEmpNo}
                      />
                      <SelectModalGrp
                        name="dept"
                        labelField="deptDesc"
                        valueField="deptCode"
                        modalTitle="DEPARTMENTS"
                        formComponent={DepartmentOptionForm}
                        required={true}
                        showId={true}
                        addOptionText="Department"
                        options={deptOpts}
                        errors={errors.dept}
                        control={control}
                        value={increase?.dept}
                        rules={{
                          required: 'Department is required',
                        }}
                        setValue={setValue}
                      />
                    </div>
                  </>
                )}

                {subdeptOpts?.length > 0 && (
                  <>
                    <div className="d-flex flex-column flex-grow-1 mr-1">
                      <HistoryLabel
                        labelTitle="Sub Department"
                        section="payrollInfo"
                        field="subDept"
                        protectedEmpNo={protectedEmpNo}
                      />
                      <SelectModalGrp
                        name="subDept"
                        labelField="subDeptDesc"
                        valueField="subDeptCode"
                        modalTitle="SUB DEPARTMENTS"
                        formComponent={SubDepartmentOptionForm}
                        required={true}
                        showId={true}
                        addOptionText="Sub Dept"
                        options={subdeptOpts}
                        errors={errors.subDept}
                        control={control}
                        value={increase?.subDept}
                        rules={{
                          required: 'Sub Department is required',
                        }}
                        setValue={setValue}
                      />
                    </div>
                  </>
                )}

                {subdept2Opts?.length > 0 && (
                  <>
                    <div className="d-flex flex-column flex-grow-1">
                      <HistoryLabel
                        labelTitle="Sub Department 2"
                        section="payrollInfo"
                        field="subDept2"
                        protectedEmpNo={protectedEmpNo}
                      />
                      <SelectModalGrp
                        name="subDept2"
                        labelField="sub2Desc"
                        valueField="sub2Code"
                        modalTitle="SUB DEPARTMENTS 2"
                        formComponent={SubDepartment2OptionForm}
                        required={true}
                        showId={true}
                        addOptionText="Sub Dept 2"
                        options={subdept2Opts}
                        errors={errors.subDept2}
                        control={control}
                        value={increase?.subDept2}
                        rules={{
                          required:
                            'Sub Department 2 is required',
                        }}
                        setValue={setValue}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="px-3">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row">
              <InputGrpDecimal
                ref={register({
                  valueAsNumber: true,
                })}
                name="amount"
                defaultValue={increase.amount}
                label="AMOUNT"
                groupClass="gc12 mw150"
              />
              <InputGrpDecimal
                ref={register({
                  valueAsNumber: true,
                })}
                name="incrPercent"
                defaultValue={increase.incrPercent}
                label="PERCENT"
                groupClass="gc12 mw150"
              />
              <SelectModalGrp
                noOption={true}
                name="increaseTypeId"
                modalTitle="INCREASE TYPE"
                label="INCREASE TYPE"
                groupClass="gc12 mw150"
                formComponent={DropdownOptionForm}
                addOptionText="INCREASE TYPE"
                options={performanceIncreaseTypes}
                errors={errors.increaseTypeId}
                control={control}
                value={increase?.increaseTypeId}
                setValue={setValue}
              />
              <ControlDatePickerGrp
                name={'nextIncrDate'}
                label="NEXT INCREASE DATE"
                groupClass="gc12 mw150"
                errors={errors.nextIncrDate}
                value={increase.nextIncrDate}
                setValue={setValue}
                control={control}
              />

            </div>
          </div>
          <div className="d-flex flex-column px-0">
            <SelectModalGrp
              noOption={true}
              modalTitle="GRADE LEVEL MAINTENANCE"
              formComponent={GradeLevelOptionForm}
              addOptionText="GRADE LEVEL"
              name={'payGrade'}
              options={payGradesOpts}
              control={control}
              value={increase?.payGrade}
              setValue={setValue}
              label="Grade Level"
              dropdownName="payGrade"
            />
          </div>
          <div className="d-flex mx-3">
            <InputGrpCurrency
              groupClass="groupClass12"
              label="Min"
              value={currencyFormatter(minPayGrade)}
              readOnly
            />
            <InputGrpCurrency
              groupClass="groupClass12"
              label="Mid"
              value={currencyFormatter(midPayGrade)}
              readOnly
            />
            <InputGrpCurrency
              groupClass="groupClass12"
              label="Max"
              value={currencyFormatter(maxPayGrade)}
              readOnly
            />
          </div>
        </Row>
        <div className="text-right">
          <button
            type="button"
            className="btn button-sm btn-primary orange-outline-button-sm ml-auto mr-2"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            disabled={!formState.isDirty}
            type="submit"
            className="btn orange-button-sm mr-3"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default PayIncrease;