import { AxiosResponse } from 'axios';
import { Applicant, AvailableJob, ClientJobs, CustomInterviewEmail, InterviewTemplate, Note, PayrollUser, StatusRequest, InterviewProcess, InterviewQuestionsReport, TemplateRequest, InterviewType, InterviewQuestion, InterviewLocation, ScorecardCategory, ScorecardTrait, DesignWebsite, CustomEmail, TestEmail, PageCustomField, WebsiteCustomWaiver, WebsiteImage } from 'core/models/ApplicantTracking.model';
import { CommonService } from './common.service';
import { API_EMPLOYEE_MASTER } from './API';
import { ApplicantEndPoints } from './URL';
import { HttpResponse } from 'core/models';

const getApplicants = (): Promise<AxiosResponse<Applicant[]>> => {
  return API_EMPLOYEE_MASTER.get<Applicant[]>(
    ApplicantEndPoints.APPLICANT(CommonService.getProtectedClientNo()),
  );
};

const getApplicant = (applicantId:number): Promise<AxiosResponse<Applicant>> => {
  return API_EMPLOYEE_MASTER.get<Applicant>(
    ApplicantEndPoints.APPLICANT_ID(CommonService.getProtectedClientNo(), applicantId),
  );
};

const getApplicantInterviews = (applicantId: number): Promise<AxiosResponse<any>> => {
  return API_EMPLOYEE_MASTER.get(
    ApplicantEndPoints.APPLICANT_INTERVIEWS(CommonService.getProtectedClientNo(), applicantId),
  );
};

const getApplicantOffer = (applicantId: number) => {
  return API_EMPLOYEE_MASTER.get(
    ApplicantEndPoints.APPLICANT_OFFER(CommonService.getProtectedClientNo(), applicantId),
  );
};


const putApplicantOffer = (request: any) => {
  return API_EMPLOYEE_MASTER.post(
    ApplicantEndPoints.APPLICANT_OFFER(CommonService.getProtectedClientNo(), request.applicantId),
    request,
  );
};

const postApplicantNotes = (applicantId: number, request: Note[]): Promise<AxiosResponse<Note>> => {
  return API_EMPLOYEE_MASTER.post<Note>(
    ApplicantEndPoints.APPLICANT_NOTES(CommonService.getProtectedClientNo(), applicantId),
    request,
  );
};

const postApplicantNoteReport = (applicantId: number): Promise<AxiosResponse<Note>> => {
  return API_EMPLOYEE_MASTER.post<Note>(
    ApplicantEndPoints.APPLICANT_NOTE_REPORT(CommonService.getProtectedClientNo(), applicantId),
  );
};

const postApplicantResume = (applicantId: number): Promise<AxiosResponse<Note>> => {
  return API_EMPLOYEE_MASTER.post<Note>(
    ApplicantEndPoints.APPLICANT_SEND_RESUME(CommonService.getProtectedClientNo(), applicantId),
  );
};

const putApplicantStatus = (request: StatusRequest): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.put<HttpResponse<string>>(
    ApplicantEndPoints.APPLICANT_STATUS(CommonService.getProtectedClientNo(), request.applicantId), request,
  ); 
};

const getJobs = (): Promise<AxiosResponse<ClientJobs[]>> => {
  return API_EMPLOYEE_MASTER.get<ClientJobs[]>(
    ApplicantEndPoints.JOB(CommonService.getProtectedClientNo()),
  );
};

const putJobStatuses = (request: any): Promise<AxiosResponse<HttpResponse<ClientJobs>>> => { 
  return API_EMPLOYEE_MASTER.put<HttpResponse<ClientJobs>>(
    ApplicantEndPoints.PUT_JOB_STATUS(CommonService.getProtectedClientNo()),
    request,
  ); 
};

const getJob = (jobId: number): Promise<AxiosResponse<AvailableJob>> => {
  return API_EMPLOYEE_MASTER.get<AvailableJob>(
    ApplicantEndPoints.JOB_ID(CommonService.getProtectedClientNo(), jobId),
  );
};

const postJob = (request: AvailableJob): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ApplicantEndPoints.JOB_ID(CommonService.getProtectedClientNo(), request.jobId), request,
  ); 
};

const deleteJob = (jobId: number): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    ApplicantEndPoints.JOB_ID(CommonService.getProtectedClientNo(), jobId),
  ); 
};

const getPayrollUsers = (): Promise<AxiosResponse<PayrollUser[]>> => { 
  return API_EMPLOYEE_MASTER.get<PayrollUser[]>(
    ApplicantEndPoints.PAYROLL_USERS(CommonService.getProtectedClientNo()),
  ); 
};

const getDesignWebsite = (): Promise<AxiosResponse<DesignWebsite>> => { 
  return API_EMPLOYEE_MASTER.get<DesignWebsite>(
    ApplicantEndPoints.GET_DESIGN_WEBSITE(CommonService.getProtectedClientNo()),
  ); 
};

const putDesignWebsite = (request: any): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.put<HttpResponse<string>>(
    ApplicantEndPoints.PUT_DESIGN_WEBSITE(CommonService.getProtectedClientNo(), request.webSiteConfigId, request.pageId),
    request,
  ); 
};

const getCustomInterviewEmails = (): Promise<AxiosResponse<CustomInterviewEmail[]>> => { 
  return API_EMPLOYEE_MASTER.get<CustomInterviewEmail[]>(
    ApplicantEndPoints.CUSTOM_INTERVIEW_EMAILS(CommonService.getProtectedClientNo()),
  ); 
};

const getInterviewTemplates = (): Promise<AxiosResponse<InterviewTemplate[]>> => { 
  return API_EMPLOYEE_MASTER.get<InterviewTemplate[]>(
    ApplicantEndPoints.INTERVIEW_TEMPLATES(CommonService.getProtectedClientNo()),
  ); 
};

const getInterviewTemplate = (request:TemplateRequest): Promise<AxiosResponse<InterviewTemplate>> => { 
  return API_EMPLOYEE_MASTER.get<InterviewTemplate>(
    ApplicantEndPoints.TEMPLATE_ID(CommonService.getProtectedClientNo(), request.interviewTypeId, request.interviewTemplateId),
  ); 
};

const deleteApplicationInterview = (applicantId: number, interviewId: number): Promise<AxiosResponse<CustomInterviewEmail[]>> => { 
  return API_EMPLOYEE_MASTER.delete<CustomInterviewEmail[]>(
    ApplicantEndPoints.DELETE_APPLICANT_INTERVIEW(CommonService.getProtectedClientNo(), applicantId, interviewId),
  ); 
};

const sendApplicantInterviewEmails = (applicantId: number, interviewId: number, request: any): Promise<AxiosResponse<CustomInterviewEmail[]>> => { 
  return API_EMPLOYEE_MASTER.post<CustomInterviewEmail[]>(
    ApplicantEndPoints.SEND_APPLICANT_INTERVIEW_EMAILS(CommonService.getProtectedClientNo(), applicantId, interviewId), request,
  ); 
};

const sendApplicantOfferEmail = (applicantId: number, request: any): Promise<AxiosResponse<CustomInterviewEmail[]>> => { 
  return API_EMPLOYEE_MASTER.post<CustomInterviewEmail[]>(
    ApplicantEndPoints.SEND_APPLICANT_OFFER_EMAIL(CommonService.getProtectedClientNo(), applicantId), request,
  ); 
};

const createInterviewFromProcess = (applicantId: number, interviewId: number): Promise<AxiosResponse<any>> => { 
  return API_EMPLOYEE_MASTER.post<any>(
    ApplicantEndPoints.CREATE_INTERVIEW_FROM_PROCESS(CommonService.getProtectedClientNo(), applicantId, interviewId),
  ); 
};

const createApplicantInterviewFromTemplate = (applicantId: number, request: any): Promise<AxiosResponse<any>> => { 
  return API_EMPLOYEE_MASTER.post<any>(
    ApplicantEndPoints.CREATE_APPLICANT_INTERVIEW_FROM_TEMPLATE(CommonService.getProtectedClientNo(), applicantId), request,
  ); 
};

const updateApplicantInterview = (applicantId: number, interviewId: number, request: any): Promise<AxiosResponse<any>> => { 
  return API_EMPLOYEE_MASTER.put<any>(
    ApplicantEndPoints.UPDATE_APPLICANT_INTERVIEW(CommonService.getProtectedClientNo(), applicantId, interviewId), request,
  ); 
};

const updateApplicantEmail = (applicantId: number, request: any): Promise<AxiosResponse<any>> => { 
  return API_EMPLOYEE_MASTER.put<any>(
    ApplicantEndPoints.UPDATE_APPLICANT_EMAIL(CommonService.getProtectedClientNo(), applicantId), request,
  ); 
};

const getInterviewQuestionsReport = (applicantId: number, interviewId: number): Promise<AxiosResponse<InterviewQuestionsReport>> => { 
  return API_EMPLOYEE_MASTER.post<InterviewQuestionsReport>(
    ApplicantEndPoints.INTERVIEW_QUESTIONS_REPORT(CommonService.getProtectedClientNo(), applicantId, interviewId),
  ); 
};

const getInterviewProcesses = (): Promise<AxiosResponse<InterviewProcess[]>> => {
  return API_EMPLOYEE_MASTER.get<InterviewProcess[]>(
    ApplicantEndPoints.INTERVIEW_PROCESSES(CommonService.getProtectedClientNo()),
  );
};

const postTemplate = (request:TemplateRequest): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ApplicantEndPoints.TEMPLATE_ID(CommonService.getProtectedClientNo(), request.interviewTypeId, request.interviewTemplateId), request,
  ); 
};

const deleteTemplate = (request:TemplateRequest): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    ApplicantEndPoints.TEMPLATE_ID(CommonService.getProtectedClientNo(), request.interviewTypeId, request.interviewTemplateId),
  ); 
};

const postInterviewProcess = (interviewProcess: InterviewProcess): Promise<AxiosResponse<string>> => { 
  return API_EMPLOYEE_MASTER.post<string>(
    ApplicantEndPoints.INTERVIEW_PROCESS_ID(CommonService.getProtectedClientNo(), interviewProcess.interviewProcessId), interviewProcess,
  ); 
};

const deleteInterviewProcess = (interviewProcessId: number): Promise<AxiosResponse<string>> => { 
  return API_EMPLOYEE_MASTER.delete<string>(
    ApplicantEndPoints.INTERVIEW_PROCESS_ID(CommonService.getProtectedClientNo(), interviewProcessId),
  ); 
};


const getInterviewQuestions = (): Promise<AxiosResponse<InterviewQuestion[]>> => {
  return API_EMPLOYEE_MASTER.get<InterviewQuestion[]>(
    ApplicantEndPoints.INTERVIEW_QUESTIONS(CommonService.getProtectedClientNo()),
  );
};
const getInterviewQuestion = (interviewQuestionId:number): Promise<AxiosResponse<ScorecardCategory>> => {
  return API_EMPLOYEE_MASTER.get<ScorecardCategory>(
    ApplicantEndPoints.INTERVIEW_QUESTIONS_ID(CommonService.getProtectedClientNo(), interviewQuestionId),
  );
};
const postInterviewQuestion = (interviewQuestion: InterviewQuestion): Promise<AxiosResponse<string>> => { 
  return API_EMPLOYEE_MASTER.post<string>(
    ApplicantEndPoints.POST_INTERVIEW_QUESTIONS_ID(CommonService.getProtectedClientNo(), interviewQuestion.interviewQuestionId), interviewQuestion,
  ); 
};

const deleteInterviewQuestion = (interviewQuestionId: number): Promise<AxiosResponse<string>> => { 
  return API_EMPLOYEE_MASTER.delete<string>(
    ApplicantEndPoints.POST_INTERVIEW_QUESTIONS_ID(CommonService.getProtectedClientNo(), interviewQuestionId),
  ); 
};

const getInterviewLocations = (): Promise<AxiosResponse<InterviewLocation[]>> => {
  return API_EMPLOYEE_MASTER.get<InterviewLocation[]>(
    ApplicantEndPoints.INTERVIEW_LOCATIONS(CommonService.getProtectedClientNo()),
  );
};
const getInterviewLocation = (interviewLocationId: number): Promise<AxiosResponse<ScorecardCategory>> => {
  return API_EMPLOYEE_MASTER.get<ScorecardCategory>(
    ApplicantEndPoints.INTERVIEW_LOCATIONS_ID(CommonService.getProtectedClientNo(), interviewLocationId),
  );
};

const postInterviewLocation = (interviewLocation: InterviewLocation): Promise<AxiosResponse<string>> => { 
  return API_EMPLOYEE_MASTER.post<string>(
    ApplicantEndPoints.POST_INTERVIEW_LOCATIONS_ID(CommonService.getProtectedClientNo(), interviewLocation.interviewLocationId), interviewLocation,
  ); 
};

const deleteInterviewLocation = (interviewLocationId: number): Promise<AxiosResponse<string>> => { 
  return API_EMPLOYEE_MASTER.delete<string>(
    ApplicantEndPoints.POST_INTERVIEW_LOCATIONS_ID(CommonService.getProtectedClientNo(), interviewLocationId),
  ); 
};

const getScorecardCategories = (): Promise<AxiosResponse<ScorecardCategory[]>> => {
  return API_EMPLOYEE_MASTER.get<ScorecardCategory[]>(
    ApplicantEndPoints.SCORECARD_CATEGORIES(CommonService.getProtectedClientNo()),
  );
};

const getScorecardCategory = (scorecardCategoryId:number): Promise<AxiosResponse<ScorecardCategory>> => {
  return API_EMPLOYEE_MASTER.get<ScorecardCategory>(
    ApplicantEndPoints.SCORECARD_CATEGORIES_ID(CommonService.getProtectedClientNo(), scorecardCategoryId),
  );
};


const postScorecardCategory = (scorecardCategory:ScorecardCategory): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ApplicantEndPoints.POST_SCORECARD_CATEGORY_ID(CommonService.getProtectedClientNo(), scorecardCategory.scorecardCategoryId), scorecardCategory,
  );
};

const deleteScorecardCategory = (scorecardCategoryId:number): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    ApplicantEndPoints.POST_SCORECARD_CATEGORY_ID(CommonService.getProtectedClientNo(), scorecardCategoryId),
  );
};


const getScorecardTraits = (): Promise<AxiosResponse<ScorecardTrait[]>> => {
  return API_EMPLOYEE_MASTER.get<ScorecardTrait[]>(
    ApplicantEndPoints.SCORECARD_TRAITS(CommonService.getProtectedClientNo()),
  );
};

const getScorecardTrait = (scorecardTraitId:number): Promise<AxiosResponse<ScorecardTrait>> => {
  return API_EMPLOYEE_MASTER.get<ScorecardTrait>(
    ApplicantEndPoints.SCORECARD_TRAITS_ID(CommonService.getProtectedClientNo(), scorecardTraitId),
  );
};

const postScorecardTrait = (scorecardTrait:ScorecardTrait): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ApplicantEndPoints.POST_SCORECARD_TRAIT_ID(CommonService.getProtectedClientNo(), scorecardTrait.scorecardTraitId), scorecardTrait,
  );
};

const deleteScorecardTrait = (scorecardTraitId:number): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    ApplicantEndPoints.POST_SCORECARD_TRAIT_ID(CommonService.getProtectedClientNo(), scorecardTraitId),
  );
};

const getInterviewTypes = (): Promise<AxiosResponse<InterviewType[]>> => {
  return API_EMPLOYEE_MASTER.get<InterviewType[]>(
    ApplicantEndPoints.INTERVIEW_TYPES(CommonService.getProtectedClientNo()),
  );
};

const postInterviewType = (interviewType:InterviewType): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ApplicantEndPoints.INTERVIEW_TYPE_ID(CommonService.getProtectedClientNo(), interviewType.interviewTypeId), interviewType,
  );
};

const deleteInterviewType = (interviewTypeId:number): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    ApplicantEndPoints.INTERVIEW_TYPE_ID(CommonService.getProtectedClientNo(), interviewTypeId),
  );
};

const getCustomEmails = (): Promise<AxiosResponse<CustomEmail[]>> => {
  return API_EMPLOYEE_MASTER.get<CustomEmail[]>(
    ApplicantEndPoints.CUSTOM_EMAILS(CommonService.getProtectedClientNo()),
  );
};

const getCustomEmail = (customEmailId:number): Promise<AxiosResponse<CustomEmail>> => {
  return API_EMPLOYEE_MASTER.get<CustomEmail>(
    ApplicantEndPoints.CUSTOM_EMAILS_ID(CommonService.getProtectedClientNo(), customEmailId),
  );
};

const postCustomEmail = (customEmail:CustomEmail): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ApplicantEndPoints.POST_CUSTOM_EMAIL_ID(CommonService.getProtectedClientNo(), customEmail.customEmailId), customEmail,
  );
};

const deleteCustomEmail = (customEmailId:number): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    ApplicantEndPoints.POST_CUSTOM_EMAIL_ID(CommonService.getProtectedClientNo(), customEmailId),
  );
};

const testCustomEmail = (testEmail:TestEmail): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ApplicantEndPoints.TEST_CUSTOM_EMAIL(CommonService.getProtectedClientNo()), testEmail,
  );
};

const postCustomField = (customField:PageCustomField): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ApplicantEndPoints.POST_CUSTOM_FIELD(CommonService.getProtectedClientNo(), 
      customField.webSiteConfigId, customField.pageId, customField.customFieldId), customField,
  );
};

const deleteCustomField = (customField:PageCustomField): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    ApplicantEndPoints.DELETE_CUSTOM_FIELD(CommonService.getProtectedClientNo(), customField.webSiteConfigId, customField.customFieldId),
  );
};


const putApplicantPagesField = (designWebsite:DesignWebsite): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.put<HttpResponse<string>>(
    ApplicantEndPoints.PUT_APPLICANT_PAGES_FIELD(CommonService.getProtectedClientNo(), 
      designWebsite.webSiteConfigId), designWebsite,
  );
};

const putApplicantWaivers = (designWebsite:DesignWebsite): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.put<HttpResponse<string>>(
    ApplicantEndPoints.PUT_APPLICANT_APPLICANTWAIVERS(CommonService.getProtectedClientNo(), 
      designWebsite.webSiteConfigId), designWebsite,
  );
};

const putSignatureDescription = (designWebsite:DesignWebsite): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.put<HttpResponse<string>>(
    ApplicantEndPoints.PUT_WEBSITE_SIGNATURE(CommonService.getProtectedClientNo(), 
      designWebsite.webSiteConfigId), designWebsite,
  );
};

const putWebsiteImages = (webSiteImage:WebsiteImage): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.put<HttpResponse<string>>(
    ApplicantEndPoints.PUT_WEBSITE_IMAGES(CommonService.getProtectedClientNo(), 
      webSiteImage.webSiteConfigId), webSiteImage,
  );
};

const postCheckForConfig = (): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ApplicantEndPoints.WEBSITE_CHECK_CONFIG(CommonService.getProtectedClientNo()));
};

const postCustomWaiver = (customWaiver:WebsiteCustomWaiver): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ApplicantEndPoints.WEBSITE_CUSTOMWAIVER_ID(
      CommonService.getProtectedClientNo(), customWaiver.webSiteConfigId, customWaiver.customWaiverId), 
    customWaiver,
  );
};

const deleteCustomWaiver = (customWaiver:WebsiteCustomWaiver): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    ApplicantEndPoints.DELETE_CUSTOM_WAIVER(CommonService.getProtectedClientNo(), customWaiver.webSiteConfigId, customWaiver.customWaiverId),
  );
};

const postWebsiteImage = (websiteImage:WebsiteImage): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    ApplicantEndPoints.WEBSITE_IMAGES(
      CommonService.getProtectedClientNo(), websiteImage.webSiteConfigId), 
    websiteImage,
  );
};

const deleteWebsiteImage = (websiteImage:WebsiteImage): Promise<AxiosResponse<HttpResponse<string>>> => { 
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    ApplicantEndPoints.WEBSITE_IMAGES_ID(CommonService.getProtectedClientNo(), websiteImage.webSiteConfigId, websiteImage.webSiteImageId),
  );
};

export const ApplicantService = {
  getApplicants,
  getApplicant,
  getApplicantInterviews,
  getApplicantOffer,
  postApplicantNotes,
  postApplicantNoteReport,
  postApplicantResume,
  putApplicantOffer,
  putApplicantStatus,
  getJobs,
  getJob,
  putJobStatuses,
  postJob,
  deleteJob,
  getPayrollUsers,
  getCustomInterviewEmails,
  deleteApplicationInterview,
  sendApplicantInterviewEmails,
  getInterviewQuestionsReport,
  getInterviewTemplates,
  getInterviewTemplate,
  createInterviewFromProcess,
  createApplicantInterviewFromTemplate,
  updateApplicantInterview,
  updateApplicantEmail,
  getInterviewProcesses,
  sendApplicantOfferEmail,
  postTemplate,
  deleteTemplate,
  postInterviewProcess,
  deleteInterviewProcess,
  getInterviewLocations,
  getInterviewLocation,
  postInterviewLocation,
  deleteInterviewLocation,
  getInterviewQuestions,
  getInterviewQuestion,
  postInterviewQuestion,
  deleteInterviewQuestion,
  getScorecardCategories,
  getScorecardCategory,
  postScorecardCategory,
  deleteScorecardCategory,
  getScorecardTraits,
  getScorecardTrait,
  postScorecardTrait,
  deleteScorecardTrait,
  getInterviewTypes,
  getDesignWebsite,
  putDesignWebsite,
  postInterviewType,
  deleteInterviewType,
  getCustomEmails,
  getCustomEmail,
  postCustomEmail,
  deleteCustomEmail,
  testCustomEmail,
  postCustomField,
  deleteCustomField,
  putApplicantPagesField,
  putApplicantWaivers,
  putSignatureDescription,
  postCustomWaiver,
  postCheckForConfig,
  postWebsiteImage,
  putWebsiteImages,
  deleteCustomWaiver,
  deleteWebsiteImage,
};