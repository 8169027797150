import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class SpecialInfo {
  empId = 0;

  clientNo = 0;

  empNo = 0;

  protectedEmpNo = '';

  staffingReason = 0;

  confidentialPosition = false;

  textAuthorized = false;

  emailAuthorized = false;

  dateImportedFromiCIMS: Date | string | null = null;

  ePrescribeOption = 0;

  dea = false;

  benefitClassClode: number | null = 0;

  blockEmployeeNavigator = false;

  offerAcceptedDate: Date | string | null = null;

  eesDate: Date | string | null = null;

  credentialNotifiedDate: Date | string | null = null;

  onboardBeginDate: Date | string | null = null;

  malpracticeAppSubmitted: Date | string | null = null;

  malpracticeCertReceived: Date | string | null = null;

  averageRateOfPay = 0;

  averageRateOfPayOverride = false;

  weeklyScheduledHours = 0;

  exemptRetention = false;

  employeeSocialSecurityDeferral = false;

  employeeNavigatorFullTime = false;

  wotc: Number | null = null;

  cineTrainJobTitleID: Number | null = null;

  isEmpNavIntegration = false;

  static readonly convProps: PropTypeLists = {
    dateProps: ['dateImportedFromiCIMS', 'offerAcceptedDate', 'eesDate',
      'credentialNotifiedDate', 'onboardBeginDate', 'malpracticeAppSubmitted', 'malpracticeCertReceived'],
    numberProps: ['empId', 'clientNo', 'empNo', 'ePrescribeOption', 'weeklyScheduledHours'],
    nullNumberProps: ['staffingReason', 'benefitClassClode', 'wotc', 'cineTrainJobTitleID'],
    floatProps: ['weeklyScheduledHours'],
    currencyProps: ['averageRateOfPay'],
    boolProps: ['confidentialPosition', 'textAuthorized', 'emailAuthorized', 'dea',
      'blockEmployeeNavigator', 'averageRateOfPayOverride', 'exemptRetention', 'employeeSocialSecurityDeferral', 'employeeNavigatorFullTime', 'isEmpNavIntegration'],
  };

  constructor(empId: string | number, clientNo: string | number, empNo: string | number, protectedEmpNo: string, props?: { [key: string]: any }) {
    this.empId = (typeof empId === 'string') ? parseInt(empId) : empId;
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;

    props && convToClass(this, props, SpecialInfo.convProps);
  }
}