import { InputGrp } from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { PayrollControlTotal, PayrollsUserOptions, TimeCard } from 'core/models';
import { handleError, updateCurrentCTIds, updatePayrollControlTotal } from 'core/store/actions';
import { getIsFinishedPayroll, getPayrollsUserOptions } from 'core/store/selectors';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Tabs, Tab, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { deepEqualityComparison, formatWithCommas } from 'utilities/utilities';
import { formatNegativeNumbers } from '../shared/payrollUtilities';
import { useHistory } from 'react-router-dom';
import Icon from 'core/components/shared/Icon';
import { PayrollControlTotalDetailType } from 'core/models/PayrollControlTotalDetail';
import { TimeCardControlTotalDetails } from './TimeCardControlTotalDetails';

type Props = {
  currentTimeCard: TimeCard
  currentControlTotal: PayrollControlTotal
};

const fs: FieldInputSettings = {
  controlTotalRegular: {
    name: 'controlTotalRegular',
    groupClass: 'group-class',
  },
  controlTotalTimeHalf: {
    name: 'controlTotalTimeHalf',
    groupClass: 'group-class',
  },
  controlTotalDouble: {
    name: 'controlTotalDouble',
    groupClass: 'group-class',
  },
  controlTotalOtherHours: {
    name: 'controlTotalOtherHours',
    groupClass: 'group-class',
  },
  controlTotalOtherEarnings: {
    name: 'controlTotalOtherEarnings',
    groupClass: 'group-class',
  },
  controlTotalSpecialDeductions: {
    name: 'controlTotalSpecialDeductions',
    groupClass: 'group-class',
  },
};

const inputStyle: CSSProperties = { textAlign: 'right' };

const TimeCardControlTotals = ({ currentTimeCard, currentControlTotal }: Props) => {
  const [shouldClose, setShouldClose] = useState(false);
  const [showControlTotal, setShowControlTotal] = useState(false);
  const [controlTotalType, setControlTotalType] = useState<PayrollControlTotalDetailType>(PayrollControlTotalDetailType.All);
  const isFinishedPayroll = useSelector(getIsFinishedPayroll);
  const userAccess = useAppSelector((state) => { return state.auth.userAccess; });
  const payrollsUserOptions: PayrollsUserOptions | null = useSelector(getPayrollsUserOptions);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { register, watch, errors, setValue, getValues, reset } =
    useForm<PayrollControlTotal>({
      defaultValues: {
        ...currentControlTotal,
        controlTotalRegular: currentControlTotal?.controlTotalRegular,
        controlTotalTimeHalf: currentControlTotal?.controlTotalTimeHalf,
        controlTotalDouble: currentControlTotal?.controlTotalDouble,
        controlTotalOtherHours: currentControlTotal?.controlTotalOtherHours,
        controlTotalOtherEarnings: currentControlTotal?.controlTotalOtherEarnings,
        controlTotalSpecialDeductions: currentControlTotal?.controlTotalSpecialDeductions,
      },
    });
  
  const {
    controlTotalRegular,
    controlTotalTimeHalf,
    controlTotalDouble,
    controlTotalOtherHours,
    controlTotalOtherEarnings,
    controlTotalSpecialDeductions,
  } = watch([
    'controlTotalRegular',
    'controlTotalTimeHalf',
    'controlTotalDouble',
    'controlTotalOtherHours',
    'controlTotalOtherEarnings',
    'controlTotalSpecialDeductions',
  ]);

  useEffect(() => {
    if (!currentControlTotal) return;
    reset({
      ...currentControlTotal,
      controlTotalRegular: currentControlTotal?.controlTotalRegular,
      controlTotalTimeHalf: currentControlTotal?.controlTotalTimeHalf,
      controlTotalDouble: currentControlTotal?.controlTotalDouble,
      controlTotalOtherHours: currentControlTotal?.controlTotalOtherHours,
      controlTotalOtherEarnings: currentControlTotal?.controlTotalOtherEarnings,
      controlTotalSpecialDeductions: currentControlTotal?.controlTotalSpecialDeductions,
    });
  }, [currentControlTotal]);

  const handleChange = (value: string, field: string) => {
    setValue(fs[field].name as keyof PayrollControlTotal, value);
  };

  const handleSaveOnBlur = () => {
    if (!(currentTimeCard?.payrollHistoryId && currentControlTotal?.controlTotalId)) return dispatch(handleError('Error saving control totals')); // hmm, should probably be something more specific
  
    // grab and merge the form (this form is JUST the control total fields) with the control total from the store. The effects in OpenCurrentTransmittal will take care of updating the information there.
    const formVals = getValues();
    const currentControlTotalValues: Partial<PayrollControlTotal> = {
      controlTotalRegular: currentControlTotal.controlTotalRegular,
      controlTotalTimeHalf: currentControlTotal.controlTotalTimeHalf,
      controlTotalDouble: currentControlTotal.controlTotalDouble,
      controlTotalOtherHours: currentControlTotal.controlTotalOtherHours,
      controlTotalOtherEarnings: currentControlTotal.controlTotalOtherEarnings,
      controlTotalSpecialDeductions: currentControlTotal.controlTotalSpecialDeductions,
    };
      
    if (deepEqualityComparison(currentControlTotalValues, formVals)) return;
      
    const updatedControlTotal: PayrollControlTotal = { ...currentControlTotal, ...formVals };
    dispatch(
      updatePayrollControlTotal({
        payrollHistoryId: currentTimeCard.payrollHistoryId,
        controlTotalId: currentControlTotal.controlTotalId,
        data: updatedControlTotal,
        preventPayrollsUpdate: true,
      }),
    );
  };

  const onClose = () => {
    if (isFinishedPayroll) return setShouldClose(true);
    if (!currentControlTotal) return setShouldClose(true); //If they have no datelines and click close just close dont try to save.
    const formValues = getValues() as PayrollControlTotal;
  
    //clear our current user data and updated last user.... -_- 
    const lastUser = userAccess?.dmUserName;
    const lastUserClosedDate = new Date().toISOString();
    const updatedControlTotal = {
      ...currentControlTotal,
      currentUser: '',
      currentUserOpenedDate: null,
      lastUser,
      lastUserClosedDate,
      controlTotalRegular: formValues?.controlTotalRegular,
      controlTotalTimeHalf: formValues?.controlTotalTimeHalf,
      controlTotalDouble: formValues?.controlTotalDouble,
      controlTotalOtherHours: formValues?.controlTotalOtherHours,
      controlTotalOtherEarnings: formValues?.controlTotalOtherEarnings,
      controlTotalSpecialDeductions: formValues?.controlTotalSpecialDeductions,
    } as PayrollControlTotal;
  
    dispatch(
      updatePayrollControlTotal({
        payrollHistoryId: currentTimeCard?.payrollHistoryId || 0,
        controlTotalId: currentControlTotal?.controlTotalId || 0,
        data: updatedControlTotal,
        preventPayrollsUpdate: false,
      }),
    );
    dispatch(updateCurrentCTIds(null));
    setShouldClose(true);
  };

  const onBalance = () => { 
    //PI-8423 if there are any check errors then we do not want them to be able to balance the payroll.
    if (!(currentControlTotal && currentTimeCard)) {
      dispatch(handleError('Error balancing transmittal'));
      console.error('Missing currentTimeCard, currentControlTotal');
      return;
    }
    const lastUser = userAccess?.dmUserName;
    const lastUserClosedDate = new Date().toISOString();
    const currentControlTotalClone = structuredClone(currentControlTotal);
    currentControlTotalClone.controlTotalDouble = currentControlTotal.transmittalDouble;
    currentControlTotalClone.controlTotalOtherEarnings = currentControlTotal.transmittalOtherEarnings;
    currentControlTotalClone.controlTotalOtherHours = currentControlTotal.transmittalOtherHours;
    currentControlTotalClone.controlTotalRegular = currentControlTotal.transmittalRegular;
    currentControlTotalClone.controlTotalSpecialDeductions = currentControlTotal.transmittalSpecialDeductions;
    currentControlTotalClone.controlTotalTimeHalf = currentControlTotal.transmittalTimeHalf;
    currentControlTotalClone.lastUser = lastUser ?? '';
    currentControlTotalClone.lastUserClosedDate = lastUserClosedDate;
    currentControlTotalClone.balanced = true;
    currentControlTotal.payControlTotal = true; //PI-8973 I dont think contractors can have multiple control totals so make sure this is always true on balance. 
  
    dispatch(
      updatePayrollControlTotal({
        payrollHistoryId: currentTimeCard?.payrollHistoryId,
        controlTotalId: currentControlTotal?.controlTotalId,
        data: currentControlTotalClone,
        preventPayrollsUpdate: false,
      }),
    );
  };

  useEffect(() => {
    if (!shouldClose) return;
    history.push('/process-payroll');
  }, [shouldClose]);
  
  return (
    <div>
      <Tabs
        className="mt-auto"    
        defaultActiveKey="byClient"
      >
        <Tab
          eventKey="byClient"
          title="Totals by Client"
        >
          <div className="control-total-container">
            <div className="d-flex flex-column flex-wrap">
              <div className="d-flex flex-row mb-4 mr-4">
                <strong>
                  Week Ending:
                  <br />
                  {currentTimeCard?.weekEnd && new Date(currentTimeCard.weekEnd).toLocaleDateString()}
                </strong>
              </div>
              <div className="d-flex flex-row">
                <strong>
                  Check Date:
                  <br />
                  {currentTimeCard?.checkDate && new Date(currentTimeCard.checkDate).toLocaleDateString()}
                </strong>
              </div>
            </div>
            <form>
              <div className="control-totals-table">
                {/* column labels */}
                <div className="control-total-row">
                  <div className="dm-grid-column-head">&nbsp;</div>
                  <label className="dm-grid-column-head text-center">
                    Regular Hours
                  </label>
                  <label className="dm-grid-column-head text-center">
                    1 1/2 Hours
                  </label>
                  <label className="dm-grid-column-head text-center">
                    Double Hours
                  </label>
                  <label className="dm-grid-column-head text-center">
                    <button
                      type="button"
                      className="btn-link link-button font-weight-bold"
                      onClick={() => {
                        setShowControlTotal(true);
                        setControlTotalType(
                          PayrollControlTotalDetailType.OtherHours,
                        );
                      }}
                    >
                      Other Hours
                    </button>
                  </label>
                  <label className="dm-grid-column-head text-center">
                    <button
                      type="button"
                      className="btn-link link-button font-weight-bold"
                      onClick={() => {
                        setShowControlTotal(true);
                        setControlTotalType(
                          PayrollControlTotalDetailType.OtherEarnings,
                        );
                      }}
                    >
                      Other Earnings
                    </button>
                  </label>
                  <label className="dm-grid-column-head text-center">
                    <button
                      type="button"
                      className="btn-link link-button font-weight-bold"
                      onClick={() => {
                        setShowControlTotal(true);
                        setControlTotalType(
                          PayrollControlTotalDetailType.SpecialDeductions,
                        );
                      }}
                    >
                      Deductions
                    </button>
                  </label>
                </div>
                {/* CT inputs */}
                <div className="control-total-row">
                  <div className="dm-grid-column-head">Control Totals</div>
                  <InputGrp
                    {...fs.controlTotalRegular}
                    groupClass="m-0"
                    errors={errors.controlTotalRegular}
                    type="text"
                    onChange={(e: any) => { handleChange(e.target.value, 'controlTotalRegular'); }}
                    defaultValue={formatWithCommas(controlTotalRegular)}
                    inputStyle={inputStyle}
                    setValue={setValue}
                    formatNumber
                    hiddenValue={controlTotalRegular ?? 0}
                    hiddenRef={register({
                      valueAsNumber: true,
                    })}
                    onBlur={handleSaveOnBlur}
                  />
                  <InputGrp
                    {...fs.controlTotalTimeHalf}
                    groupClass="m-0"
                    errors={errors.controlTotalTimeHalf}
                    type="text"
                    onChange={(e: any) => { handleChange(e.target.value, 'controlTotalTimeHalf'); }}
                    defaultValue={formatWithCommas(controlTotalTimeHalf)}
                    inputStyle={inputStyle}
                    setValue={setValue}
                    formatNumber
                    hiddenValue={controlTotalTimeHalf ?? 0}
                    hiddenRef={register({
                      valueAsNumber: true,
                    })}
                    onBlur={handleSaveOnBlur}
                  />
                  <InputGrp
                    {...fs.controlTotalDouble}
                    groupClass="m-0"
                    errors={errors.controlTotalDouble}
                    type="text"
                    onChange={(e: any) => { handleChange(e.target.value, 'controlTotalDouble'); }}
                    defaultValue={formatWithCommas(controlTotalDouble)}
                    inputStyle={inputStyle}
                    setValue={setValue}
                    formatNumber
                    hiddenValue={controlTotalDouble ?? 0}
                    hiddenRef={register({
                      valueAsNumber: true,
                    })}
                    onBlur={handleSaveOnBlur}
                  />
                  <InputGrp
                    {...fs.controlTotalOtherHours}
                    groupClass="m-0"
                    errors={errors.controlTotalOtherHours}
                    type="text"
                    onChange={(e: any) => { handleChange(e.target.value, 'controlTotalOtherHours'); }}
                    defaultValue={formatWithCommas(controlTotalOtherHours)}
                    inputStyle={inputStyle}
                    setValue={setValue}
                    formatNumber
                    hiddenValue={controlTotalOtherHours ?? 0}
                    hiddenRef={register({
                      valueAsNumber: true,
                    })}
                    onBlur={handleSaveOnBlur}
                  />
                  <InputGrp
                    {...fs.controlTotalOtherEarnings}
                    groupClass="m-0"
                    errors={errors.controlTotalOtherEarnings}
                    type="text"
                    onChange={(e: any) => { handleChange(e.target.value, 'controlTotalOtherEarnings'); }}
                    defaultValue={formatWithCommas(controlTotalOtherEarnings)}
                    inputStyle={inputStyle}
                    setValue={setValue}
                    formatNumber
                    hiddenValue={controlTotalOtherEarnings ?? 0}
                    hiddenRef={register({
                      valueAsNumber: true,
                    })}
                    onBlur={handleSaveOnBlur}
                  />
                  <InputGrp
                    {...fs.controlTotalSpecialDeductions}
                    groupClass="m-0"
                    errors={errors.controlTotalSpecialDeductions}
                    type="text"
                    onChange={(e: any) => { handleChange(e.target.value, 'controlTotalSpecialDeductions'); }}
                    defaultValue={formatWithCommas(controlTotalSpecialDeductions)}
                    inputStyle={inputStyle}
                    setValue={setValue}
                    formatNumber
                    hiddenValue={controlTotalSpecialDeductions ?? 0}
                    hiddenRef={register({
                      valueAsNumber: true,
                    })}
                    onBlur={handleSaveOnBlur}
                  />
                </div>
                <div className="control-total-row">
                  <div className="dm-grid-column-head">Time Card Total</div>
                  <label className="dm-grid-column-head-right">
                    {formatWithCommas(currentControlTotal?.transmittalRegular ?? 0)}
                  </label>
                  <label className="dm-grid-column-head-right">
                    {formatWithCommas(currentControlTotal?.transmittalTimeHalf ?? 0)}
                  </label>
                  <label className="dm-grid-column-head-right">
                    {formatWithCommas(currentControlTotal?.transmittalDouble ?? 0)}
                  </label>
                  <label className="dm-grid-column-head-right">
                    {formatWithCommas(currentControlTotal?.transmittalOtherHours ?? 0)}
                  </label>
                  <label className="dm-grid-column-head-right">
                    {formatWithCommas(currentControlTotal?.transmittalOtherEarnings ?? 0)}
                  </label>
                  <label className="dm-grid-column-head-right">
                    {formatWithCommas(currentControlTotal?.transmittalSpecialDeductions ?? 0)}
                  </label>
                </div>
                {/* difference totals */}
                <div className="control-total-row">
                  <div className="dm-grid-column-head">Difference</div>
                  <label className="dm-grid-column-head-right">
                    {formatNegativeNumbers((currentControlTotal?.transmittalRegular || 0) - (controlTotalRegular || 0), payrollsUserOptions)}
                  </label>
                  <label className="dm-grid-column-head-right">
                    {formatNegativeNumbers((currentControlTotal?.transmittalTimeHalf || 0) - (controlTotalTimeHalf || 0), payrollsUserOptions)}
                  </label>
                  <label className="dm-grid-column-head-right">
                    {formatNegativeNumbers((currentControlTotal?.transmittalDouble || 0) - (controlTotalDouble || 0), payrollsUserOptions)}
                  </label>
                  <label className="dm-grid-column-head-right">
                    {formatNegativeNumbers((currentControlTotal?.transmittalOtherHours || 0) - (controlTotalOtherHours || 0), payrollsUserOptions)}
                  </label>
                  <label className="dm-grid-column-head-right">
                    {formatNegativeNumbers((currentControlTotal?.transmittalOtherEarnings || 0) - (controlTotalOtherEarnings || 0), payrollsUserOptions)}
                  </label>
                  <label className="dm-grid-column-head-right">
                    {formatNegativeNumbers((currentControlTotal?.transmittalSpecialDeductions || 0) - (controlTotalSpecialDeductions || 0), payrollsUserOptions)}
                  </label>
                </div>
              </div>
            </form>
            <div className="control-totals-button">
              <div className="control-total-button">
                <button
                  className="orange-outline-button mx-1"
                  type="submit"
                  onClick={onBalance}
                >
                  Balance
                </button>
                <button
                  type="button"
                  className="btn orange-button"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>
      <Modal
        show={showControlTotal}
        backdrop="static"
        centered={true}
        onHide={() => {return setShowControlTotal(false);}}
        size="sm"
      >
        <Modal.Header className="border-0">
          <button
            type="button"
            className="modal-close-btn"
            onClick={() => {return setShowControlTotal(false);}}
          >
            <Icon name="times" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <TimeCardControlTotalDetails
            request={{
              type: controlTotalType,
              payrollHistoryId: currentTimeCard?.payrollHistoryId ?? 0,
              controlTotalId: currentControlTotal?.controlTotalId ?? 0,
            }}
            onHide={() => {return setShowControlTotal(false);}}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TimeCardControlTotals;