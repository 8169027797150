import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadTimeOffPastDue } from 'core/store/actions/time-off.action';
import { useAppSelector } from 'utilities/hooks';
import TimeOffRequestComponent from './TimeOffRequestComponent';
import { TimeOffRequest } from 'core/models';
import Modal from 'core/components/modals/Modal';

type Props = {
  onHide: () => void;
  show: boolean;
  fromLanding?: boolean;
};

const PastDue = (props: Props) => {
  const dispatch = useDispatch();

  const pastDueTimeOffData = useAppSelector(
    (state) => { return state.timeOff.timeOffPastDues; },
  );

  const [pastDueState, setPastDueState] = useState<TimeOffRequest[]>();

  useEffect(() => {
    dispatch(loadTimeOffPastDue());
  }, []);

  useEffect(() => {
    setPastDueState(pastDueTimeOffData);
  }, [pastDueTimeOffData]);

  const update = (tor: TimeOffRequest) => {
    setPastDueState((prevState) => {
      return prevState?.filter(
        (otherTOR) => { return otherTOR.timeOffRequestId !== tor.timeOffRequestId; },
      );
    },
    );
  };

  return (
    <Modal show={props.show}
      onHide={props.onHide}
      size="xl"
      title="Approved/Scheduled Requests"
    >
      {pastDueState
        && pastDueState.map((timeOffPastDue) => {
          return (
            <TimeOffRequestComponent
              key={timeOffPastDue.timeOffRequestId}
              tor={timeOffPastDue}
              update={update}
              fromLandingPage={props.fromLanding}
            />
          );
        })
      }
    </Modal>
  );
};

export default PastDue;
