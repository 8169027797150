import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';
import { AllModules, ColDef, GridOptions } from '@ag-grid-enterprise/all-modules';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { EmployeeChange } from '../../../models';
import { agDateRenderer } from '../../../../utilities/ag-grid-renderers';

//#region AgGrid
const columns: ColDef[] = [{
  field: 'employeeName',
  headerName: 'Employee',
  sortable: true,
  width: 160,
}, {
  field: 'fieldName',
  headerName: 'Field Name',
  sortable: true,
  width: 120,
},
{
  field: 'value',
  headerName: 'Value',
  sortable: true,
  width: 120,
},
{
  field: 'dateSubmitted',
  headerName: 'Date Submitted',
  cellRenderer: 'dateRenderer',
  sortable: true,
  width: 120,
}, {
  field: 'dateManaged',
  headerName: 'Date Managed',
  cellRenderer: 'dateRenderer',
  sortable: true,
  width: 120,
}, {
  field: 'actionTaken',
  headerName: 'Action Taken',
  sortable: true,
  width: 120,
}, {
  field: 'managedBy',
  headerName: 'Managed By',
  sortable: true,
  width: 120,
}];

const gridOptions: GridOptions = {
  columnDefs: columns,
  domLayout: 'autoHeight',
  defaultColDef: {
    suppressMenu: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
  pagination: true,
  paginationPageSize: 10,
  // @ts-ignore
  components: {
    dateRenderer: agDateRenderer,
  },
};

type PropTypes = {
  items: EmployeeChange[];
};

const EmployeeChangeHistory: React.FC<PropTypes> = ({ items }) => {
  return (
    <div>
      <Row>
        <Col className="table-wrapper-wrapper ag-theme-balham">
          <AgGridReact
            gridOptions={gridOptions}
            rowData={items}
            overlayNoRowsTemplate={
              '<span>No Changes to Display</span>'
            }
            modules={AllModules} />
        </Col>
      </Row>
    </div>
  );
};

export default EmployeeChangeHistory;