import { baseFilter } from 'core/components/shared/table/utilities';
import { TableModel as Tm } from 'core/components/shared/table/types';
import { WorkFlowTaskList } from 'core/models/WorkFlow.model';

type SelectedType = 'In-Progress' | 'Approved' | 'Denied' | 'Canceled' | 'All';

export const workflowAggregateFilter = (workflowTasks: WorkFlowTaskList[], filterModel: Tm.FilterModel<WorkFlowTaskList>, selectedType?: SelectedType): WorkFlowTaskList[] => {
  const newWorkflowTasks = workflowTasks.filter((x) => {
    // Step 1: check column filters
    let match = baseFilter<WorkFlowTaskList>(x, filterModel);
    
    // Step 2: Check type selected from dropdown
    switch (selectedType) {
      case 'Approved':
        match = match && x.status === 'Approved';
        break;
      case 'Denied':
        match = match && x.status === 'Denied';
        break;
      case 'Canceled':
        match = match && x.status === 'Canceled';
        break;
      case 'In-Progress':
        match = match && x.status === 'Pending';
        break;
      case 'All':
      default:
        match = match && true;
        break;
    }
    
    return match;
  });
  
  return newWorkflowTasks;
};