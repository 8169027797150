import React from 'react';
import Icon from './Icon';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { CheckboxGrpInLine } from '../form-controls/CheckboxGrpInLine';

type PropTypes = {
  title: string;
  hrClass?: string;
  children?: any;
  iconName?:IconName;
  hideDefault?:boolean;
  onHideChange?:(e:any)=> void;
};

const PanelHeader: React.FC<PropTypes> = ({
  title,
  children,
  hrClass = 'hr-3',
  iconName,
  hideDefault,
  onHideChange,
}) => {
  return (
    <>
      <div className="d-flex flex-wrap">
        <div className="dm-grid-title pb-2 mr-3">
          {iconName ? <Icon
            name={iconName}
            fontSize={25}
            style={{             
              marginRight: 10,
            }}
          /> : null}{title}</div>
        { hideDefault ? <div style={{ paddingTop:15 }}>
          <CheckboxGrpInLine 
            label="Hide Default Processes"  
            labelFirst={false} 
            onChange={onHideChange}
          />     
        </div> : null}
        <div className="ml-auto d-flex flex-wrap">
          {React.Children.map(
            children,
            (child: any, index: number) => {
              return (
                <div
                  key={index}
                  className="align-self-end"
                >
                  {child}
                </div>
              );
            },
          )}
        </div>
      </div>
      <hr className={hrClass} />
    </>
  );
};

export default PanelHeader;
