import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { InputGrp } from 'core/components/form-controls';
import { SelectGrp } from 'core/components/form-controls/SelectGrp';
import { PayrollAdjustmentEarning } from 'core/models/PayrollAdjustment.model';
import { getEarningsCodes, getIsFinishedPayroll } from 'core/store/selectors';
import { useAppSelector } from 'utilities/hooks';
import AdjustmentModalEarnings from './AdjustmentModalEarnings';
import Icon from 'core/components/shared/Icon';
import { formatWithCommas } from 'utilities/utilities';
import { FormMethods } from 'core/components/form-controls/types';

type Props = {
  index: number;
  control: any;
  errors: FormMethods['errors'];
  register: FormMethods['register'];
  setValue: FormMethods['setValue'];
  watch: FormMethods['watch'];
};

const AdjustmentDeduction: React.FC<Props> = ({
  index,
  control,
  errors,
  register,
  setValue,
  watch,
}) => {
  const { fields, append, remove } = useFieldArray<PayrollAdjustmentEarning>({
    control,
    name: `items[${index}].earnings`,
  });
  
  const newAdjustmentCheck = useAppSelector((state) => { return state.payrollAdjustment.adjustmentCheckDefaults; });
  const earningsCode = useSelector(getEarningsCodes);
  const isFinishedPayroll =  useSelector(getIsFinishedPayroll);
  const disabled = (control.getValues(`items[${index}].adjustmentType`)?.startsWith('Void') ?? false) || isFinishedPayroll;

  const onAdd = () => {
    if (newAdjustmentCheck) {
      append(newAdjustmentCheck.earnings[0]);
    }
  };

  return (
    <>
      <div className="text-right mt-2 mb-2">
        {!isFinishedPayroll ? <button
          type="button"
          className="btn btn-link dm-grid-action-title p-0"
          onClick={onAdd}
          disabled={disabled}
        >
          Add Earnings
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button> : null}
      </div>
      <hr />
      {fields.map((earning, ei) => {
        const amount = watch(`items[${index}].earnings[${ei}].earnings`);
        const hours = watch(`items[${index}].earnings[${ei}].hours`);
        
        return (
          <div key={earning.id}>
            <div className="d-flex flex-row">
              <input
                type="hidden"
                name={`items[${index}].earnings[${ei}].adjustmentEarningId`}
                value={earning.adjustmentEarningId}
                ref={register({
                  valueAsNumber: true,
                })}
                disabled={disabled}
              />
              <SelectGrp
                groupClass="gc04 mw175 mt-4"
                options={earningsCode}
                name={`items[${index}].earnings[${ei}].earningsCode`}
                id="earningsCode"
                addEmptyValue={true}
                errorMsg="Required"
                errors={
                  errors?.items?.[index]?.earnings?.[ei]
                    ?.earningsCode
                }
                ref={register({
                  required: {
                    value: true,
                    message: 'Please choose an Earnings Code',
                  },
                })}
                defaultValue={earning.earningsCode}
                disabled={disabled}
                showId={true}
              />
              <InputGrp
                groupClass="gc03 number-underline mw75"
                label="Hours"
                type="text"
                name={`items[${index}].earnings[${ei}].hours`}
                ref={register()}
                defaultValue={formatWithCommas(hours ?? 0)}
                setValue={setValue}
                formatNumber
                hiddenValue={hours ?? 0}
                hiddenRef={register({
                  valueAsNumber: true,
                })}
                disabled={disabled}
              />
              <InputGrp
                groupClass="gc03 number-underline mw75"
                label="Amount"
                type="text"
                name={`items[${index}].earnings[${ei}].earnings`}
                ref={register()}
                defaultValue={formatWithCommas(amount ?? 0)}
                setValue={setValue}
                formatNumber
                hiddenValue={amount ?? 0}
                hiddenRef={register({
                  valueAsNumber: true,
                })}
              />
              <div className="col-auto mb-2">
                {!isFinishedPayroll ? <button
                  type="button"
                  className="mt-4 btn btn-link dm-grid-action-title p-0"
                  onClick={() => {
                    remove(ei);
                  }}
                >
                  Delete
                  <Icon
                    name="minus-circle"
                    className="fa-minus-circle"
                  />
                </button> : null}
              </div>
            </div>
            <AdjustmentModalEarnings
              index={index}
              register={register}
              errors={errors}
              earning={earning}
              earningIndex={ei}
              control={control}
            />
            <hr />
          </div>
        );
      })}
    </>
  );
};

export default AdjustmentDeduction;
