import React, { CSSProperties, BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { Id, toast } from 'react-toastify';
import { Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Client, Employee, ResendOnboardingEmailRequest } from 'core/models';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckboxGrpInLine,
  ControlDatePickerGrp,
  InputGrp,
  SelectGrp,
  SelectModalGrp,
} from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import {
  getClient,
  getGenders,
  getSelectedEmp,
  getLocationDepartmentsWithoutHome,
  getStates,
  getForeignCountries,
  getYesNoCMOption,
} from 'core/store/selectors';
import { resendOnboardingEmail, toggleBlockNavigation, updateEmployee, updateEmployeePhoto } from 'core/store/actions';
import { ControlIds, PHONE_VALIDATION, UNSAVED_MESSAGE } from 'core/constants';
import LocationOptionForm from 'core/components/form-controls/select-modal/LocationOptionForm';
import DepartmentOptionForm from 'core/components/form-controls/select-modal/DepartmentOptionForm';
import SubDepartmentOptionForm from 'core/components/form-controls/select-modal/SubDepartmentOptionForm';
import SubDepartment2OptionForm from 'core/components/form-controls/select-modal/SubDepartment2OptionForm';
import EmpPhotoUpload from '../add-emp/information/EmpPhotoUpload';
import { capitalizeFirstLetter, convToDateString, formatPhone, formatSSN, getAccess } from 'utilities/utilities';
import { useAppDispatch, useAppSelector, useEffectOnMount } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import HistoryLabel from 'core/components/form-controls/HistoryLabel';

const myInfoEmailLabelStyle: CSSProperties = {
  fontSize: '12px',
  minWidth: '165px',
  maxWidth: '185px',
  alignSelf: 'flex-end',
};

const toolTipStyles: CSSProperties = { fontSize: '0.75rem' };

const hireDateInfoMessage = 'Hire Date changes must be made in the Additional Information > Dates screen.';

const maritualStatusOpts = [
  {
    id: 'f',
    description: '',
  },
  {
    id: 'S',
    description: 'Single',
  },
  {
    id: 'M',
    description: 'Married',
  },
];

const fs: FieldInputSettings = {
  firstName: {
    name: 'firstName',
    label: 'FIRST NAME',
    groupStyle: { marginBottom: '0' },
    controlId: 116,
  },
  lastName: {
    name: 'lastName',
    label: 'LAST NAME',
    groupStyle: { marginBottom: '0' },
    controlId: 120,
  },
  midName: {
    name: 'midName',
    label: 'MIDDLE INITIAL',
    groupStyle: { marginBottom: '0' },
    controlId: 121,
  },
  suffix: {
    name: 'suffix',
    label: 'SUFFIX',
    groupStyle: { marginBottom: '0' },
    controlId: 127,
  },

  ssn: {
    name: 'ssn',
    label: 'SOCIAL SECURITY',
    groupClass: 'gc50 mw125',
    required: true,
    controlId: 126,
  },
  ssnisappliedFor: {
    name: 'ssnisappliedFor',
    label: 'SSN Applied For:',
    labelWidth: 100,
    groupClass: 'gc50 mw125 pt-3',
    controlId: 126,
  },
  birthDate: {
    name: 'birthDate',
    label: 'BIRTH DATE',
    groupClass: 'gc50 mw125',
    controlId: 111,
  },
  hireDate: {
    name: 'hireDate',
    label: 'HIRE DATE',
    groupClass: 'gc50 mw125',
    controlId: 117,
  },
  sex: {
    name: 'sex',
    label: 'GENDER',
    groupClass: 'gc50 mw125',
  },
  maritalStatus: {
    name: 'maritalStatus',
    label: 'MARITAL STATUS',
    groupClass: 'gc50 mw125',
    controlId: 170,
  },

  address: {
    name: 'address',
    label: 'STREET ADDRESS',
    groupClass: 'gc100',
    controlId: 110,
  },
  city: {
    name: 'city',
    label: 'CITY',
    groupClass: 'gc70 mw150',
    controlId: 113,
  },
  state: {
    name: 'state',
    label: 'STATE',
    groupClass: 'gc30 mw100',
    controlId: 102,
  },
  zip: {
    name: 'zip',
    label: 'ZIP',
    groupClass: 'gc50 p-0',
    controlId: 129,
  },
  zip4: {
    name: 'zip4',
    label: ' ',
    groupClass: 'gc20',
    controlId: 130,
  },
  country: {
    name: 'country',
    label: 'COUNTRY',
    groupClass: 'gc30 mw200',
  },
  foreignAddress: {
    name: 'foreignAddress',
    label: 'Foreign Address:',
    groupClass: 'gc50 pt-3',
  },
  email: {
    name: 'email',
    label: 'EMAIL',
    groupClass: 'gc100',
    controlId: 114,
  },
  email2: {
    name: 'email2',
    label: 'EMAIL 2',
    groupClass: 'gc100',
    controlId: 171,
  },
  homePhone: {
    name: 'homePhone',
    label: 'HOME PHONE',
    groupClass: 'gc50 mw125',
    controlId: 119,
  },
  cellPhone: {
    name: 'cellPhone',
    label: 'CELL PHONE',
    groupClass: 'gc50 mw125',
    controlId: 112,
  },

  loc: {
    name: 'loc',
    label: 'LOCATION',
    groupClass: 'gc50 mw125',
    // required: true,
    labelField: 'locationDesc',
    valueField: 'locationCode',
    idField: 'locationID',
    showId: true,
    addOptionText: 'Location',
    dropdownName: 'location',
    modalTitle: 'LOCATION',
    formComponent: LocationOptionForm,
    controlId: 101,
  },
  dept: {
    name: 'dept',
    label: 'DEPARTMENT',
    groupClass: 'gc50 mw125',
    // required: true,
    labelField: 'deptDesc',
    valueField: 'deptCode',
    idField: 'deptId',
    showId: true,
    addOptionText: 'Department',
    dropdownName: 'department',
    modalTitle: 'DEPARTMENTS',
    formComponent: DepartmentOptionForm,
    controlId: 99,
  },
  subDept: {
    name: 'subDept',
    label: 'SUB DEPARTMENT',
    groupClass: 'gc50 mw125',
    // required: true,
    labelField: 'subDeptDesc',
    valueField: 'subDeptCode',
    idField: 'subDeptID',
    showId: true,
    addOptionText: 'Sub Department',
    dropdownName: 'subDepartment',
    modalTitle: 'SUB DEPARTMENTS',
    formComponent: SubDepartmentOptionForm,
    controlId: 103,
  },
  subDept2: {
    name: 'subDept2',
    label: 'SUB DEPARTMENT2',
    groupClass: 'gc50 mw125',
    // required: true,
    labelField: 'sub2Desc',
    valueField: 'sub2Code',
    idField: 'subDept2ID',
    showId: true,
    addOptionText: 'Sub Deparment 2',
    dropdownName: 'subDepartment2',
    modalTitle: 'SUB DEPARTMENTS 2',
    formComponent: SubDepartment2OptionForm,
    controlId: 153,
  },
  isSupervisor: {
    name: 'isSupervisor',
    label: 'Employee is a supervisor',
    labelWidth: 175,
    lgLabel: true,
    groupClass: 'gc100',
  },
  myInfoEmail: {
    name: 'myInfoEmail',
    label: 'MyInfo Email',
  },
  lastMyInfoLogin: {
    name: 'lastMyInfoLogin',
    label: 'MyInfo Last Login',
  },
};

const ToastCloseButton = ({ closeToast }: { closeToast: () => void }) => {
  return (
    <button
      type="button"
      className="modal-close-btn"
      onClick={closeToast}
    >
      <Icon name="times" />
    </button>
  );
};

const renderToolTip = (props: OverlayInjectedProps, message: string) => {
  return (
    <Tooltip
      id="info-tooltip"
      {...props}
    >
      <div style={toolTipStyles}>
        {message}
      </div>
    </Tooltip>
  );
};

const EmpListDetailEdit = () => {
  const dispatch = useAppDispatch();

  const [empPicData, setEmpPicData] = useState<string>('');
  const [, setEmpHireDate] = useState<string>('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [isForeignAddress, setIsForeignAddress] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [usePrimary, setUsePrimary] = useState(true);
  
  const employee = useSelector(getSelectedEmp) as Employee;
  const foreignCountryOpts = useSelector(getForeignCountries);
  const client = useSelector(getClient) as Client;
  const clientNo = client?.clientNo ?? 0;
  const { locationOpts, deptOpts, subdeptOpts, subdept2Opts } = useSelector(getLocationDepartmentsWithoutHome);
  const stateOpts = useSelector(getStates);
  const genderOpts = useSelector(getGenders);
  const savingEmp = useAppSelector((state) => state.selEmployee.loading);
  const minEmpAge = useAppSelector(({ client }) => client.clientOptions?.options?.[2527]?.optionValue);
  const showFutureHireDateMsg = useSelector(getYesNoCMOption(2482));
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    errors,
    clearErrors,
    control,
    reset,
    formState,
  } = useForm<any>({
    defaultValues: { employee },
  });
  const { dirtyFields, isDirty } = formState;
  const {
    email,
    email2,
    birthDate,
    hireDate,
    loc,
    dept,
    subDept,
    subDept2,
    lastMyInfoLogin,
    ssn,
  } = watch([
    'email',
    'email2',
    'birthDate',
    'hireDate',
    'loc',
    'dept',
    'subDept',
    'subDept2',
    'lastMyInfoLogin',
    'ssn',
  ]);
  
  const toastId = useRef<Id | null>(null);

  useEffect(() => {
    if (!employee) return;
    setEmpPicData(employee?.employeePhoto);
    setIsForeignAddress(employee?.country !== 'US');
      
    if (employee?.hireDate) {
      setEmpHireDate(`${convToDateString(employee.hireDate)} ${convToDateString(employee.hireDate)}`);
    }

    reset({
      ...employee,
      ssn: formatSSN(employee.ssn),
      cellPhone: formatPhone(employee.cellPhone),
      homePhone: formatPhone(employee.homePhone),
      foreignAddress: employee?.country !== 'US',
    });

    clearErrors();
  }, [employee]);
  
  useEffect(() => {
    if (!employee) return;
    switch (employee?.onboardingStatus?.toLowerCase()) {
      case 'onboarding':
        setEmailMessage('Resend Onboarding Credentials');
        setConfirmMessage("Are you sure you want to resend this employee's onboarding credentials using this email address?");
        break;
      case 'employee':
        setEmailMessage('Send MyInfo Login Info');
        setConfirmMessage(`You are about to send login information for myinfo.dmpayroll.com to ${usePrimary ? email : email2}. Continue?`);
        break;
      default:
        break;
    }
  }, [employee?.onboardingStatus, email, email2, usePrimary]);

  useEffectOnMount(() => {
    setShowConfirm(false);

    if (toastId?.current) {
      toast.dismiss(toastId.current);
    }
  }, [employee]);
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: showConfirm,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [showConfirm]);
  
  const notify = () => {
    if (toast.isActive(toastId.current as Id) || savingEmp) return;
    toastId.current = toast.warn(
      `${employee?.firstName} ${employee?.lastName} has unsaved
                 changes. Click the Save button in the Employee Detail 
                 section to keep your changes.`,
      {
        autoClose: false,
        position: toast.POSITION.TOP_CENTER,
        style: { color: '#00558c' },
        closeButton: (
          <ToastCloseButton
            closeToast={() => {
              return (
                toastId?.current && toast.dismiss(toastId.current)
              );
            }}
          />
        ),
      },
    );
  };

  const updateEmpPhoto = (photoData: any) => {
    if (!employee) return;
    
    dispatch(
      updateEmployeePhoto({
        empNo: employee.protectedEmpNo,
        photoData,
      }),
    );
  };

  const onSsnIsAppliedFor = (e: any) => {
    setValue('ssn', e.nativeEvent.target.checked ? '000-00-0000' : formatSSN(employee.ssn));
    reset(getValues(), { isDirty });
  };

  const onCountryChange = (e: any) => {
    setIsForeignAddress(e.target.value !== 'US');
  };
  
  const onResend = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, isPrimaryEmail: boolean) => {
    e.preventDefault();
    setUsePrimary(isPrimaryEmail);
    setShowResendModal(true);
    e.stopPropagation();
  };
  
  const onConfirmResend = (confirmed: boolean) => {
    if (confirmed && clientNo) {
      const request: ResendOnboardingEmailRequest = {
        clientNo: clientNo,
        protectedEmpNo: employee.protectedEmpNo,
        empNo: employee.empNo,
        empId: employee.empId,
        emailAddress: usePrimary ? email : email2,
        isOnboardingEmailType: employee.onboardingStatus.toLowerCase() === 'onboarding',
      };
      dispatch(resendOnboardingEmail(request));
    }
    setShowResendModal(false);
  };

  const onSubmit = (data: Employee, e?: BaseSyntheticEvent) => {
    e?.stopPropagation();
    if (e?.target.id !== 'emp-list-detail-form') return;
    if (toastId?.current) {
      toast.dismiss(toastId.current);
    }
    toastId.current = null;
    setShowConfirm(false);
    const x = {
      ...employee,
      ...data,
    };
    const newEmp = new Employee(clientNo, x.empNo, x);
    const empAge = Math.abs((new Date().getFullYear()) - (new Date(newEmp?.birthDate ?? new Date()).getFullYear()));
    if (
      (!!newEmp?.birthDate)
      && empAge < Number(minEmpAge)
      && !confirm(`The birth date entered indicates the employee is below the minimum age of ${minEmpAge}. Continue?`)) {
      return;
    }
    if (
      showFutureHireDateMsg
      && (new Date(newEmp?.hireDate ?? new Date()) > new Date())
      && !confirm('Selected hire date is in the future. Continue?')
    ) return;
    
    dispatch(updateEmployee(newEmp));
  };
  
  const onLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!(isDirty && Object.keys(dirtyFields).length > 0)) return;

    setShowConfirm(true);
    notify();
  };
  
  // #region Section access fields
  // section(s)
  const empListSectionAccess = getAccess(sectionAccess, 1);
  const empDatesSectionAccess = getAccess(sectionAccess, 7);
  // fields
  const firstNameFieldAccess = getAccess(sectionAccess, 1, ControlIds.firstName);
  const midNameFieldAccess = getAccess(sectionAccess, 1, ControlIds.midName);
  const lastNameFieldAccess = getAccess(sectionAccess, 1, ControlIds.lastName);
  const suffixFieldAccess = getAccess(sectionAccess, 1, ControlIds.suffix);
  const ssnFieldAccess = getAccess(sectionAccess, 1, ControlIds.ssn);
  const ssnAppliedForFieldAccess = getAccess(sectionAccess, 1, ControlIds.ssn, { disabledSameAsReadOnly: true });
  const birthDateFieldAccess = getAccess(sectionAccess, 1, ControlIds.birthDate);
  const maritalStatusFieldAccess = getAccess(sectionAccess, 1, ControlIds.maritalStatus);
  const addressFieldAccess = getAccess(sectionAccess, 1, ControlIds.address);
  const cityFieldAccess = getAccess(sectionAccess, 1, ControlIds.city);
  const stateFieldAccess = getAccess(sectionAccess, 1, ControlIds.state);
  const zipFieldAccess = getAccess(sectionAccess, 1, ControlIds.zip);
  const zip4FieldAccess = getAccess(sectionAccess, 1, ControlIds.zip4);
  const emailFieldAccess = getAccess(sectionAccess, 1, ControlIds.email);
  const emailButtonAccess = getAccess(sectionAccess, 1, undefined, { disabledSameAsReadOnly: true, disabledDefault: !email?.length });
  const email2FieldAccess = getAccess(sectionAccess, 1, ControlIds.email2);
  const email2ButtonAccess = getAccess(sectionAccess, 1, undefined, { disabledSameAsReadOnly: true, disabledDefault: !email2?.length });
  const homePhoneFieldAccess = getAccess(sectionAccess, 1, ControlIds.homePhone);
  const cellPhoneFieldAccess = getAccess(sectionAccess, 1, ControlIds.cellPhone);
  const locationFieldAccess = getAccess(sectionAccess, 1, ControlIds.loc);
  const deptFieldAccess = getAccess(sectionAccess, 1, ControlIds.dept);
  const subDeptFieldAccess = getAccess(sectionAccess, 1, ControlIds.subDept);
  const subDept2FieldAccess = getAccess(sectionAccess, 1, ControlIds.subDept2);
  // #endregion
  
  const fullName = `${capitalizeFirstLetter(employee?.firstName ?? '')} ${employee?.midName?.length ? employee.midName + ' ' : ''}${capitalizeFirstLetter(employee?.lastName ?? '')}`;
  
  return !employee ? (
    <div>Missing Employee</div>
  ) : (
    <div
      className="dm-panel dm-panel-border"
      onMouseLeave={(e) => {
        onLeave(e);
      }}
    >
      <div className="dm-card-subtitle2 pb-2">Employee Detail</div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="emp-list-detail-form"
      >
        <div
          className="d-flex flex-row"
          style={{
            backgroundColor: 'lightgray',
            paddingBottom: '10px',
          }}
        >
          <div style={{ flex: '1' }}>
            <div className="pl-2 pt-2 pr-2">
              <EmpPhotoUpload
                empPicData={empPicData}
                setEmpPicData={setEmpPicData}
                updateEmpPhoto={updateEmpPhoto}
              />
            </div>
            {!empListSectionAccess?.visible ? (
              <div
                className="pl-2"
                style={{
                  fontSize: '14px',
                  fontWeight: 800,
                }}
              >
                {fullName}
              </div>
            ) : null}
            <div
              className="pl-2"
              style={{
                fontSize: '12px',
                fontWeight: 600,
              }}
            >
              Emp #: {employee.empNo}
            </div>
          </div>
          <div
            className="d-flex flex-row flex-wrap mt-3"
            style={{ flex: '2' }}
          >
            <InputGrp
              {...fs.firstName}
              {...firstNameFieldAccess}
              errors={errors.firstName}
              groupClass="gc50"
              groupStyle={{
                minWidth: '75px',
                maxWidth: '150px',
              }}
              ref={register()}
            />
            <InputGrp
              {...fs.midName}
              {...midNameFieldAccess}
              errors={errors.midName}
              groupClass="gc50 mw75"
              groupStyle={{
                minWidth: '75px',
                maxWidth: '150px',
              }}
              ref={register}
            />
            <InputGrp
              {...fs.lastName}
              {...lastNameFieldAccess}
              errors={errors.lastName}
              groupClass="gc50 mw75"
              groupStyle={{
                minWidth: '75px',
                maxWidth: '150px',
              }}
              ref={register}
            />
            <InputGrp
              {...fs.suffix}
              {...suffixFieldAccess}
              errors={errors.suffix}
              groupClass="gc50 mw75"
              groupStyle={{
                minWidth: '75px',
                maxWidth: '150px',
              }}
              ref={register}
            />
          </div>
        </div>
        {empListSectionAccess?.visible ? (
          <>
            <div className="form-row">
              <Col
                xs={12}
                style={{ padding: '0' }}
              >
                <hr className="dm-panel-hr" />
              </Col>
            </div>
            <div className="d-flex flex-wrap">
              <InputGrp
                {...fs.ssn}
                {...ssnFieldAccess}
                errors={errors.ssn}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { value } = e.target;
                  e.target.value = formatSSN(value);
                }}
                ref={register({ required: true })}
              />
              <CheckboxGrpInLine
                {...fs.ssnisappliedFor}
                {...ssnAppliedForFieldAccess}
                required={!ssn?.length}
                errors={errors.ssnisappliedFor}
                onChange={onSsnIsAppliedFor}
                ref={register}
              />
              <ControlDatePickerGrp
                {...fs.birthDate}
                {...birthDateFieldAccess}
                visible={empListSectionAccess?.visible && empDatesSectionAccess?.visible}
                value={birthDate}
                setValue={setValue}
                control={control}
              />
              <div className="d-flex flex-column mt-auto">
                <HistoryLabel
                  {...empListSectionAccess}
                  visible={empListSectionAccess?.visible && empDatesSectionAccess?.visible}
                  labelTitle="Hire Date"
                  section="date"
                  field="hireDate"
                  labelClasses="mt-0"
                  protectedEmpNo={employee.protectedEmpNo}
                  showHistory={false}
                  detailMessage={hireDateInfoMessage}
                />
                <ControlDatePickerGrp
                  {...fs.hireDate}
                  {...empListSectionAccess}
                  visible={empListSectionAccess?.visible && empDatesSectionAccess?.visible}
                  value={hireDate}
                  setValue={setValue}
                  control={control}
                  disabled={employee.hireDate !== null}
                  showLabel={false}
                />
              </div>
              <SelectGrp
                {...fs.sex}
                {...empListSectionAccess}
                errors={errors.sex}
                ref={register}
                options={genderOpts}
              />
              <SelectGrp
                {...fs.maritalStatus}
                {...maritalStatusFieldAccess}
                errors={errors.maritalStatus}
                ref={register}
                options={maritualStatusOpts}
              />
            </div>
            <hr style={{
              margin: '5px 0',
              borderTop: '2p solid blue',
            }}
            />
            <div className="d-flex flex-wrap">
              <InputGrp
                {...fs.address}
                {...addressFieldAccess}
                errors={errors.address}
                ref={register}
              />
              <InputGrp
                {...fs.city}
                {...cityFieldAccess}
                errors={errors.city}
                ref={register}
              />
              {isForeignAddress ? (
                <InputGrp
                  {...fs.state}
                  {...stateFieldAccess}
                  label={'State/Province'}
                  errors={errors.state}
                  ref={register}
                />
              ) : (
                <SelectGrp
                  {...fs.state}
                  {...stateFieldAccess}
                  errors={errors.state}
                  ref={register}
                  options={stateOpts}
                />
              )}
              {zipFieldAccess.visible && (
                <div className="d-flex flex-row gc50">
                  <InputGrp
                    {...fs.zip}
                    {...zipFieldAccess}
                    errors={errors.zip}
                    ref={register}
                  />
                  {!isForeignAddress && (
                    <>
                      <span
                        style={{
                          paddingTop: '22px',
                          paddingLeft: '3px',
                          paddingRight: '3px',
                        }}
                      >
                        -
                      </span>
                      <InputGrp
                        {...fs.zip4}
                        {...zip4FieldAccess}
                        errors={errors.zip4}
                        ref={register}
                      />
                    </>
                  )}
                </div>
              )}
              <SelectGrp
                {...fs.country}
                {...empListSectionAccess}
                errors={errors.country}
                ref={register}
                options={foreignCountryOpts}
                onChange={onCountryChange}
              />
            </div>
            <hr style={{
              margin: '5px 0',
              borderTop: '2p solid blue',
            }}
            />
            <div className="d-flex flex-wrap">
              <div className="dm-email-input-container-em">
                <InputGrp
                  {...fs.email}
                  {...emailFieldAccess}
                  groupClass="dm-email-input"                  
                  errors={errors.email}
                  ref={register}
                  type="email"
                />
                {/* we use the field access for the button's visibility since it shouldn't appaer without it, but use the button's access for the rest */}
                {emailFieldAccess.visible ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={(props) => renderToolTip(props, emailMessage)}
                  >                
                    <button
                      {...emailButtonAccess}
                      className="btn dm-email-button-em"
                      type="button"
                      onClick={(e) => { onResend(e, true); }}
                    >
                      <Icon
                        name="envelope"
                        className="fa-envelope p-0"
                      />  
                      <span className="sr-only">Send MyInfo Login Info</span>
                    </button>
                  </OverlayTrigger>
                ) : null}
              </div>
              <div className="dm-email-input-container-em">
                <InputGrp
                  {...fs.email2}
                  {...email2FieldAccess}
                  groupClass="dm-email-input"                  
                  errors={errors.email2}
                  ref={register}
                  type="email"
                />
                {email2FieldAccess.visible ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={(props) => renderToolTip(props, emailMessage)}
                  >                
                    <button
                      {...email2ButtonAccess}
                      className="btn dm-email-button-em"
                      type="button"
                      onClick={(e) => { onResend(e, false); }}
                    >
                      <Icon
                        name="envelope"
                        className="fa-envelope p-0"
                      />  
                      <span className="sr-only">Send MyInfo Login Info</span>
                    </button>
                  </OverlayTrigger>
                ) : null}
              </div>
              <InputGrp
                {...fs.homePhone}
                {...homePhoneFieldAccess}
                errors={errors.homePhone}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { value } = e.target;
                  e.target.value = formatPhone(value);
                }}
                ref={register({ pattern: PHONE_VALIDATION })}
              />
              <InputGrp
                {...fs.cellPhone}
                {...cellPhoneFieldAccess}
                errors={errors.cellPhone}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { value } = e.target;
                  e.target.value = formatPhone(value);
                }}
                ref={register({ pattern: PHONE_VALIDATION })}
              />
            </div>
            <hr style={{
              margin: '5px 0',
              borderTop: '2p solid blue',
            }}
            />
            <div className="d-flex flex-wrap">
              {locationOpts?.length > 0 && (
                <SelectModalGrp
                  {...fs.loc}
                  // {...locationFieldAccess}
                  options={locationOpts}
                  errors={errors.loc}
                  control={control}
                  dropdownName="Location"
                  value={loc}
                  // rules={{ required: 'Location is required' }}
                  setValue={setValue}
                />
              )}
              {deptOpts?.length > 0 && (
                <SelectModalGrp
                  {...fs.dept}
                  // {...deptFieldAccess}
                  options={deptOpts}
                  errors={errors.dept}
                  control={control}
                  dropdownName="Department"
                  value={dept}
                  // rules={{ required: 'Department is required' }}
                  setValue={setValue}
                />
              )}
              {subdeptOpts?.length > 0 && (
                <SelectModalGrp
                  {...fs.subDept}
                  // {...subDeptFieldAccess}
                  options={subdeptOpts}
                  errors={errors.subDept}
                  control={control}
                  value={subDept}
                  // rules={{ required: 'Sub Department is required' }}
                  setValue={setValue}
                />
              )}
              {subdept2Opts?.length > 0 && (
                <SelectModalGrp
                  {...fs.subDept2}
                  // {...subDept2FieldAccess}
                  options={subdept2Opts}
                  errors={errors.subDept2}
                  control={control}
                  value={subDept2}
                  // rules={{ required: 'Sub Department 2 is required' }}
                  setValue={setValue}
                />
              )}
              <CheckboxGrpInLine
                {...fs.isSupervisor}
                {...empListSectionAccess}
                disabled={empListSectionAccess?.disabled || empListSectionAccess?.readOnly}
                groupStyle={{
                  marginTop: '5px',
                  marginBottom: '5px',
                }}
                ref={register}
              />
              {employee.myInfoEmail?.length && (
                <div className="d-flex flex-wrap w-100 pr-0 mt-2">
                  <div
                    className="pr-0"
                    style={{ width: '100px' }}
                  >
                    <label className="dm-form-label">
                      MyInfo Email:
                    </label>
                  </div>
                  <div
                    className="pr-0"
                    style={myInfoEmailLabelStyle}
                  >
                    {employee.myInfoEmail ?? ''}
                  </div>
                </div>
              )}
              {lastMyInfoLogin && (
                <div className="d-flex flex-wrap w-100">
                  <div
                    className="pr-0"
                    style={{ width: '100px' }}
                  >
                    <label className="dm-form-label">
                      MyInfo Last Login:
                    </label>
                  </div>
                  <div
                    className="pr-0"
                    style={{
                      fontSize: '12px',
                      minWidth: '165px',
                      maxWidth: '185px',
                      alignSelf: 'flex-end',
                    }}
                  >
                    {lastMyInfoLogin
                      ? new Date(lastMyInfoLogin)?.toLocaleDateString() +
                ' ' +
                new Date(lastMyInfoLogin)?.toLocaleDateString()
                      : ''}
                  </div>
                </div>
              )
              }
            </div>
            <div className="row mt-2 mb-2">
              <div className="col-12 text-right">
                {empListSectionAccess?.visible ? (
                  <Button
                    {...empListSectionAccess}
                    disabled={empListSectionAccess?.disabled || empListSectionAccess?.readOnly}
                    className="orange-button"
                    id="submit-button"
                    type="submit"
                  >
                    Save
                  </Button>
                ) : null}
              </div>
            </div>
          </>
        ) : null}
      </form>
      {showResendModal ? (
        <ConfirmationModal            
          show={showResendModal}
          title="Confirm"
          message={confirmMessage}
          onHide={() => { return setShowResendModal(false); } } 
          onConfirmed={onConfirmResend}
        />
      ) : null}
    </div>
  );
};

export default EmpListDetailEdit;
