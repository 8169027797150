import React, { useState } from 'react';
import { CheckboxGrp } from 'core/components/form-controls';
import Icon from 'core/components/shared/Icon';
import { WebsiteCustomWaiver } from 'core/models';
import { useAppDispatch } from 'utilities/hooks';
import { postCustomWaiver } from 'core/store/slices/applicantTracking.slice';

type Props = {
  customWaiver: WebsiteCustomWaiver;
  onEdit: (cw: WebsiteCustomWaiver) => void;
  onDelete: (sf: WebsiteCustomWaiver) => void;
};

const CustomWaiver = ({ customWaiver, onEdit, onDelete }: Props) => {
  const [waiverState, setWaiverState] = useState(structuredClone(customWaiver));
  
  const dispatch = useAppDispatch();
  
  const onCheckboxChange = (newValue: boolean) => {
    const updatedWaiver: WebsiteCustomWaiver = {
      ...waiverState,
      required: newValue,
      visible: newValue,
    };
    
    dispatch(postCustomWaiver(updatedWaiver));
  };
  
  return (
    /* TODO: we probably don't need 100k divs in here */
    <div className="d-flex flex-row">
      <div className="d-flex flex-column flex-grow-1">
        <div className="d-flex flex-row justify-content-between">
          <div style={{ width: 200 }}>&nbsp;</div>
          <div className="d-flex flex-row">
            <div
              className="text-center"
              style={{ width: 150 }}
            >
              <span><u>Visible/Required</u></span>
            </div>
          </div>
          <div
            className="text-center"
            style={{ width: 125 }}
          >
            <span><u>Last Modified By</u></span>
          </div>
          <div style={{ width: 125 }}>&nbsp;</div>
        </div>
        <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex flex-row justify-content-between">
            <div style={{ width: 200 }}>{customWaiver.title}</div>
            <div className="d-flex flex-row">
              <div
                className="text-center"
                style={{ width: 150 }}
              >
                <CheckboxGrp
                  label=""                                     
                  defaultChecked={waiverState.visible && waiverState.required}
                  onChange={(e: any) => { onCheckboxChange(e.target.checked); }}
                />
              </div>
            </div>
            <div
              className="text-center"
              style={{ width: 125 }}
            >
              {customWaiver.modifiedUser}
            </div>
            <div style={{ width: 125 }}>
              <button
                type="button"
                className="btn btn-link mr-3"
                onClick={() => { onEdit(customWaiver as WebsiteCustomWaiver); }}
              >
                <Icon
                  name="edit"
                  className="fa-edit"
                />
              </button>
              <button
                type="button"
                className="btn btn-link"
                onClick={() => { onDelete(customWaiver as WebsiteCustomWaiver); }}
              >
                <Icon
                  name="minus-circle"
                  className="fa-minus-circle"
                />
              </button>
            </div>
          </div>
          <hr className="dm-panel-hr" />
        </div>
      </div>
    </div>
  );
};

export default CustomWaiver;