import React, { useEffect } from 'react';
import { Prompt, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getVisaStatus, getVisaStatuses, getSelectedEmp } from 'core/store/selectors';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { ControlDatePickerGrp, SelectGrp } from 'core/components/form-controls';
import { useForm } from 'react-hook-form';
import { VisaStatus } from '../../../core/models';
import { loadVisaStatus, toggleBlockNavigation, updateVisaStatus } from '../../../core/store/actions';
import PanelHeader from '../../../core/components/shared/PanelHeader';
import { useAppSelector } from '../../../utilities/hooks';
import { getAccess } from 'utilities/utilities';
import { ControlIds, UNSAVED_MESSAGE } from 'core/constants';

const fs: FieldInputSettings = {
  visaTypeId: {
    name: 'visaTypeId',
    label: 'VISA TYPE',
    groupClass: 'gc15 mw125',
    controlId: 197,
  },
  issueDate: {
    name: 'issueDate',
    label: 'ISSUE DATE',
    groupClass: 'gc15 mw125',
    controlId: 198
  },
  expireDate: {
    name: 'expireDate',
    label: 'EXPIRATION DATE',
    groupClass: 'gc15 mw125',
    controlId: 199
  },
};

const VisaStatusPage: React.FC<any> = () => {
  const dispatch = useDispatch();
  const { protectedEmpNo } = useParams<any>();
  const clientNo = useAppSelector(({ client }) => client.client?.clientNo) ?? 0;
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  const visible = getAccess(sectionAccess, 43)?.visible;
  
  const visaStatus = useSelector(getVisaStatus);
  const visaStatusOpts = useSelector(getVisaStatuses);
  const selectedEmployee = useSelector(getSelectedEmp);
  

  const { setValue, reset, errors, register, handleSubmit, control, watch, formState } =
        useForm<any>({
          defaultValues: new VisaStatus(
            clientNo,
            selectedEmployee?.empNo ?? 0,
            protectedEmpNo,
          ),
        });

  const issueDate = watch('issueDate');
  const expireDate = watch('expireDate');

  const handleBlockUnsavedChanges = (newVal: boolean) => {
    dispatch(toggleBlockNavigation({ block: newVal, message: UNSAVED_MESSAGE }));
  };

  useEffect(() => {
    protectedEmpNo && dispatch(loadVisaStatus(protectedEmpNo));
    handleBlockUnsavedChanges(false);
  }, [protectedEmpNo]);
  
  useEffect(() => {
    visaStatus && reset(visaStatus);
  }, [visaStatus]);

  useEffect(() => {
    handleBlockUnsavedChanges(formState.isDirty);
  }, [formState.isDirty]);

  const onSave = (data: any) => {
    const vs = new VisaStatus(
      clientNo,
      selectedEmployee?.empNo ?? 0,
      protectedEmpNo,
      data,
    );
    dispatch(updateVisaStatus(vs));
    handleBlockUnsavedChanges(false);
  };

  return (
    <div
      className="dm-panel dm-panel-border"
      style={{ minHeight: '200px' }}
    >
      {visible && (
        <>
          <PanelHeader title="Visa Status" />
          <form onSubmit={handleSubmit(onSave)}>
            <div className="d-flex flex-wrap mt-3">
              <SelectGrp
                {...fs.visaTypeId}
                {...getAccess(sectionAccess, 43, ControlIds.visaTypeId)}
                errors={errors.visaTypeId}
                ref={register}
                options={visaStatusOpts}
              />
              <ControlDatePickerGrp
                {...fs.issueDate}
                {...getAccess(sectionAccess, 43, ControlIds.issueDate)}
                value={issueDate}
                setValue={setValue}
                control={control}
              />
              <ControlDatePickerGrp
                {...fs.expireDate}
                {...getAccess(sectionAccess, 43, ControlIds.expireDate)}
                value={expireDate}
                setValue={setValue}
                control={control}
              />
            </div>
            <div className="row mt-2">
              <div className="col-12 text-right">
                <button 
                  {...getAccess(sectionAccess, 43, undefined, { disabledSameAsReadOnly: true })}
                  type="submit"
                  className="orange-button ml-2"
                  disabled={!formState.isDirty}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default VisaStatusPage;
