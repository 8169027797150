import React, { FC, useState } from 'react';
import Table from './Table';
import { Row, ColumnHeaderData } from './types';
import { Collapse } from 'react-bootstrap';
import Icon from '../Icon';
import { useAppSelector } from 'utilities/hooks';

type Props = {
  trade: string;
  rows: Row[];
  columns: ColumnHeaderData[];
  deleteRow: (id: string) => void;
};

const ExpandGroup: FC<Props> = ({ trade, rows, columns, deleteRow, children }) => {
  const [open, setOpen] = useState<boolean>(true);

  const toggleOpen = () => {
    setOpen(!open);
  };
  
  return (
    <>
      {!rows?.length ? null : (
        <div className="dm-panel dm-panel-border m-0 mt-1 p-1">
          <div className="d-flex justify-content-between">
            <div>
              <div
                role="button"
                className="dm-card-subtitle4"
                onClick={toggleOpen}
              >
                <Icon
                  name={open ? 'chevron-down' : 'chevron-right'}
                  className="mr-2"
                />
                Trade:&nbsp;{trade}&nbsp;({rows.length}{rows?.length > 1 ? ' items' : ' item'})
              </div>
            </div>
            {children}
          </div>
          <Collapse in={open}>
            <div>
              <Table
                rows={rows}
                columns={columns}
                bodyClass="tbody-fit"
                showDelete
                onRowDelete={(id: string | number) => {
                  deleteRow(String(id));
                }}
              />
            </div>
          </Collapse>
        </div>
      )}
    </>
  );
};

export default ExpandGroup;
