import React, { useState, useEffect, CSSProperties, DependencyList, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PanelHeader from 'core/components/shared/PanelHeader';
import { ClientDeduction, Deduction, DeductionRequest } from 'core/models';
import { getDirectDeposits } from 'core/store/selectors/deduction.selector';
import DirectDepositItem from './DirectDepositItem';
import { useParams } from 'react-router-dom';
import {
  loadDeductions,
  loadCheckCodes,
  createDeduction,
  deleteDeductionAsync,
  handleError,
  toggleBlockNavigation,
} from 'core/store/actions';
import { getClientDeductions, getSelectedEmp } from 'core/store/selectors';
import Icon from 'core/components/shared/Icon';
import { useFieldArray, useForm } from 'react-hook-form';
import { UNSAVED_MESSAGE } from 'core/constants';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import SkipAlwaysModal from './SkipAlways.modal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import SaveButton from 'core/components/shared/SaveButton';
import { getAccess } from 'utilities/utilities';

type FormType = {
  directDepositItems: Deduction[];
};

const toolTipStyles: CSSProperties = { fontSize: '0.65rem' };

const renderToolTip = (props: OverlayInjectedProps) => {
  return (
    <Tooltip
      id="info-tooltip"
      {...props}
    >
      <div style={toolTipStyles}>
        Click to add skip always override to any saved Direct Deposit Deductions. 
      </div>
    </Tooltip>
  );
};

const DirectDepositPage: React.FC = () => {
  const dispatch = useAppDispatch();
  
  const selEmp = useSelector(getSelectedEmp);
  const directDepositStore = useSelector(getDirectDeposits);
  const clientDeductions = useSelector(getClientDeductions) as ClientDeduction[];
  const { blockNavigation } = useAppSelector((state) => state.app);
  const saving = useAppSelector(({ selEmployeeDetails }) => selEmployeeDetails?.deduction?.saving);
  
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });

  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();
  
  const {
    control,
    ...formMethods
  } = useForm<FormType>({
    defaultValues: {
      directDepositItems: directDepositStore,
    },
    shouldUnregister: false,
  });
  
  const { directDepositItems: formDirectDepositState } = formMethods.getValues();
  
  const getSum = (): number => {
    // grab direct deposits
    const directDeposits = formDirectDepositState?.filter((x) => ['directdeposit', 'genericeft'].includes(x.deductionType.toLowerCase()) && x.unit === 'N');
    if (!directDeposits.length) return -1; // return -1 to indicate we don't have any of this type so the sum doesn't matter.
    
    return directDeposits
      ?.map(({ dedAmount }) => parseFloat(String(dedAmount)))
      ?.reduce((total, currentVal) => total + currentVal, 0);
  };
  
  const [showSkipAlwaysModal, setShowSkipAlwaysModal] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isDirtySavedDeduction, setIsDirtySavedDeduction] = useState<boolean>(false);
  const [sum, setSum] = useState<number>(getSum());
  
  const { fields: directDepositFields, append : appendDeduction, remove: removeDeduction } =
    useFieldArray<Deduction>({
      control,
      name: 'directDepositItems',
      keyName: 'id',
    });
  
  useEffect(() => {
    if (blockNavigation.block) {
      dispatch(toggleBlockNavigation({ block: false }));
    }
    
    return () => { 
      dispatch(toggleBlockNavigation({ block: false }));
    };
  }, []);

  useEffect(() => {
    dispatch(loadDeductions(protectedEmpNo));
    dispatch(loadCheckCodes());
    setIsDirty(false);
    setIsDirtySavedDeduction(false);
  }, [protectedEmpNo]);

  useEffect(() => {
    if (!directDepositStore || ['pending', 'error'].includes(saving)) return; // don't reset when still saving
    formMethods.reset({
      directDepositItems: directDepositStore.map((e: Deduction) => {
        return {
          ...e,
        };
      }),
    });
    setIsDirty(false);
    setIsDirtySavedDeduction(false);
    setSum(100);
  }, [directDepositStore, saving]);

  const updateDirtyState = (newValue: boolean, id: number) => {
    if (id > 0) {
      setIsDirtySavedDeduction(newValue);
    }
    setIsDirty(newValue);
    setSum(getSum());
  };

  const onAddDirectDeposit = () => {
    if (selEmp) {
      window.scrollTo(0, document.body.scrollHeight);
      appendDeduction({
        protectedEmpNo: protectedEmpNo,
        //We add a random id so we are able to delete the specific id and not show it. Gets converted to 0 on save
        dedId: parseInt((Math.random() * -10000).toString()),
        deductionType: 'DirectDeposit',
        freq: '0',
      });
      setIsDirty(true);
    }
  };

  const onAddSkipAlways = (deduction: Deduction, checkCode: string) => {
    if (selEmp) {
      window.scrollTo(0, document.body.scrollHeight);
      appendDeduction({
        protectedEmpNo: protectedEmpNo,
        //We add a random id so we are able to delete the specific id and not show it. Gets converted to 0 on save
        dedId: parseInt((Math.random() * -10000).toString()),
        deductionType: 'DirectDeposit',
        dedAmount: deduction.dedAmount,
        dedNo: deduction.dedNo,
        skipAlwayDedId: deduction.dedId,
        unit: deduction.unit,
        bankAcctNo: deduction.bankAcctNo,
        routingNo: deduction.routingNo,
        checkCode: checkCode,
        freq: 'X',
      });
      setIsDirty(true);
    }
  };
  
  const onDeleteDirectDeposit = (deduction: Deduction) => {
    if (!confirm('Delete direct deposit? This action cannot be undone.')) return;
    const deductionIndex = directDepositFields?.findIndex((ded) => ded.dedId === deduction.dedId);
    if (deductionIndex === -1) return dispatch(handleError('Cannot find deduction item to delete'));
    removeDeduction(deductionIndex);

    if (deduction.dedId > 0) dispatch(deleteDeductionAsync(deduction));
    const newDirectDeposits = structuredClone(directDepositFields)?.filter((ded) => (ded?.dedId || 0) < 0);
    setIsDirty(isDirtySavedDeduction || newDirectDeposits.filter(x => x.dedId !== deduction.dedId).length > 0);

    // the percent of net is not 0 or 100, so another DD item must exist with the remaining percentage.
    if ((!([0, 100].includes(+deduction.dedAmount)) && deduction.unit === 'N')) {
      dispatch(toggleBlockNavigation({ block: true, message: 'Error', type: 'directDeposit' }));
    } 
  };

  const onSubmitDirectDeposit = async (data: FormType) => {
    if (!data) return;
    const deductions: Deduction[] = [];
    const deductionBankInfo: string[] = [];
    
    let duplicateBankInfo = false;
    
    data.directDepositItems.forEach(ded => {
      const currentDeduction = clientDeductions.find(x => x.deductionNumber === ded.dedNo);
      const deductionType = (currentDeduction?.deductionTypeCode === '@') ? 'GenericEFT' : 'DirectDeposit';

      if (selEmp) {
        const deduction = new Deduction(selEmp.clientNo, selEmp.empNo, selEmp.protectedEmpNo, ded?.dedId ?? 0, deductionType, ded);
        deduction.dateStart = (ded.dateStart === '') ? null : ded.dateStart;
        deduction.dateExpire = (ded.dateExpire === '') ? null : ded.dateExpire;
        deduction.dedId = (ded.dedId > 0) ? ded.dedId : 0;
        deduction.skipAlwayDedId = ((ded?.skipAlwayDedId ?? -1) < 0) ? null : ded.skipAlwayDedId;
        deduction.checkCode = (ded.checkCode == '-1') ? '' : ded.checkCode;
        deduction.active = ded.status !== 'DEACTIVE';
        deduction.skipping = ded.status === 'SKIPONCE';
        deduction.deductionType = deductionType;
        
        deductions.push(deduction);
        
        const bankInfo = `${deduction.routingNo}${deduction.bankAcctNo}`;
        
        if (bankInfo?.length && deductionBankInfo.includes(bankInfo)) {
          duplicateBankInfo = true;
          return;
        } else {
          //PI-8658 only add bankInfo if they have one set. If it is blank do not set it since GenericEFT do not require routing/account No to be set.
          if (!bankInfo) 
            deductionBankInfo.push(bankInfo);
        }
      }
    }); 
    
    if (duplicateBankInfo && !confirm('The same routing number and account number are being used for two or more checking/savings accounts. Continue?')) return;

    const request: DeductionRequest = {
      protectedEmpNo,
      Data: [
        ...deductions,
      ],
    };
    
    dispatch(createDeduction(request));
  };

  useEffect(() => {
    if (sum === -1 || sum === 100) {
      dispatch(toggleBlockNavigation({
        block: isDirty,
        message: UNSAVED_MESSAGE,
        type: 'confirmation',
      }));
    } else {
      dispatch(toggleBlockNavigation({
        block: true,
        message: 'Error',
        type: 'directDeposit',
      }));
    } 
  }, [isDirty, sum]);
  
  return (
    <>
      <div className="dm-panel dm-panel-border">
        <PanelHeader title="Direct Deposit">
          <OverlayTrigger
            placement="top"
            overlay={renderToolTip}
          >
            <button
              {...getAccess(sectionAccess, 5, undefined, { disabledSameAsReadOnly: true, disabledDefault: (directDepositStore?.length || 0) === 0 })}
              className="btn btn-link dm-grid-action-title pb-0 mr-2"
              onClick={() => { setShowSkipAlwaysModal(true);} }
            >
              Add Skip Always <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          </OverlayTrigger>
          <button
            {...getAccess(sectionAccess, 5, undefined, { disabledSameAsReadOnly: true })}
            className="btn btn-link dm-grid-action-title pb-0 mr-2"
            onClick={onAddDirectDeposit}
          >
            Add New <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <form onSubmit={formMethods.handleSubmit(onSubmitDirectDeposit)}>
          {directDepositFields.map((item, index) => {
            
            return (
              <div key={item.id}>
                <DirectDepositItem
                  key={item.id}
                  directDeposit={item}
                  index={index}
                  control = {control}
                  formMethods={formMethods}
                  onDelete={onDeleteDirectDeposit}
                  updateDirtyState={updateDirtyState}
                />
                {index + 1 < directDepositFields.length && (
                  <hr className="dm-panel-hr mt-2" />
                )}
              </div>
            );
          })}
          <div className="text-right mt-3">
            <SaveButton
              {...getAccess(sectionAccess, 5, undefined, { disabledSameAsReadOnly: true, disabledDefault: !isDirty })}
              classes="btn orange-button mr-3"
              saving={saving === 'pending'}
            />
          </div>
        </form>
      </div>
      {showSkipAlwaysModal && (
        <SkipAlwaysModal
          show={showSkipAlwaysModal}
          inputName={`directDepositItems[${directDepositFields.length - 1}].checkCode`}
          onHide={() => {return setShowSkipAlwaysModal(false);}}
          control={control}
          setValue={formMethods.setValue}
          onAddSkipAlways={onAddSkipAlways}
        />
      )
      }
    </>
  );
};

export default DirectDepositPage;
