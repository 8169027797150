import React, { Dispatch, SetStateAction, FC, useEffect, useRef, useState } from 'react';
import addPhoto2 from '../../../assets/images/addphoto2.png';
import PhotoUploadModal from 'core/components/modals/PhotoUpload.modal';

type PropTypes = {
  empPicData: string;
  setEmpPicData: Dispatch<SetStateAction<string>>;
  updateEmpPhoto?: (photoData: string) => void;
};

const EmpUploadPhoto: FC<PropTypes> = ({ empPicData, setEmpPicData, updateEmpPhoto }) => {
  const uploadedImage = useRef<any>(null);
  const [showUpload, setShowUpload] = useState(false);
  const [image, setImage] = useState('');

  useEffect(() => {
    // I don't understand how this assignment even makes sense here. Will need to come back and consider again
    if (!uploadedImage?.current) return console.error('Could not update employee photo src to asset');
    uploadedImage.current.src = addPhoto2;
  }, []);

  useEffect(() => {
    const image = empPicData ? 'data:image/png;base64,' + empPicData : addPhoto2;
    setImage(image);
    uploadedImage.current.src = image;
  }, [empPicData]);


  return (<>

    <img
      style={{ marginBottom: 15 }}
      onClick={() => { return setShowUpload(true); }}
      ref={uploadedImage}
      className="rounded-circle"
      alt="add"
      width="85"
      height="85"
    />
    {showUpload && updateEmpPhoto &&
      <PhotoUploadModal
        show={showUpload}
        onHide={() => { return setShowUpload(false); }}
        onSave={(fileData: string) => { updateEmpPhoto(fileData); setEmpPicData(fileData); }}
        currentImage={image}
      />
    }
  </>);
};

export default EmpUploadPhoto;