import React, { useContext, useEffect, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import { createField } from 'utilities/utilities';
import { useAppDispatch } from 'utilities/hooks';
import { addSelectedField, removeSelectedField, removeSelectedFieldBySection } from 'core/store/slices/employee-earnings-download.slice';
import { Column } from './GridSelect';
import { DownloaderContext } from '../UserMapPage';

type Props = {
  column: Column;
  row: any;
  checkAll: boolean;
  section: string;
  isMultiSelect: boolean;
  disable: boolean;
  isCodeField: boolean;
  fieldAddition: string;
  shouldHideCell?: boolean;
  useKey?: boolean;
};

const SelectCell = ({ column, row, checkAll, section, isMultiSelect, disable, isCodeField, fieldAddition, shouldHideCell, useKey }: Props) => {
  const appDispatch = useAppDispatch();
  
  const [checkedState, setCheckedState] = useState(checkAll);
  
  const { setIsDirty } = useContext(DownloaderContext);
  
  const { id, align } = column;
  const { description } = row;
  const rowCode = (isCodeField) ? row.code : row.id;

  useEffect(() => {
    setCheckedState(checkAll);
  }, [checkAll]);

  //This will add or remove the fields to state based on the OnChange in the table cell.
  const addRemoveField = (checked: boolean, fieldName: string, description: string | null) =>{
    if (checked) {
      appDispatch(addSelectedField(createField((fieldAddition + fieldName), section, {
        filterId: 0,
        code: '' + rowCode,
        description: description,
      })));
    } else if (isMultiSelect) {
      appDispatch(removeSelectedFieldBySection({
        section,
        fieldName: (fieldAddition + fieldName),
      }));
    } else {
      appDispatch(removeSelectedField({
        code: String(rowCode),
        description: description,
        fieldName: fieldName,
      }));
    }
    
    setIsDirty(true);
  };
    
  //Handles the logic for the individual cells and if they should be checked or disabled. If they are manually checked we can add the selected field to state.
  return (
    <>
      <TableCell
        hidden={shouldHideCell}
        key={id}
        align={align}
        className="p-0 h-100 border"
      >
        {(id === 'code') ?
          <div>{rowCode}</div> :
          (id === 'description') ?
            <div style={{ whiteSpace: 'nowrap',
              padding: '0 10px 0 0' }}
            >{description}</div> :
            <div style={{ display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative' }}
            >
              <input
                className="CheckboxCustom"
                id={id}
                type="checkbox"
                checked={checkedState}
                disabled = {disable}
                onChange={(e) => {
                  const checked = e.target.checked;
                
                  if (isMultiSelect)
                    addRemoveField(checked, (description + ' ' + id), null);
                  else if (useKey && id === 'Gross')
                    addRemoveField(checked, row.grossKey, description);
                  else if (useKey && id === 'Withholding')
                    addRemoveField(checked, row.withHoldingKey, description);
                  else
                    addRemoveField(checked, id, description);

                  setCheckedState(checked);
                }}
              /></div>}

      </TableCell>
    </>
  );
};

export default SelectCell;