import React, { useContext, useState } from 'react';
import { CheckboxGrpInLine } from 'core/components/form-controls';
import { DownloaderField, UserMapSelectedField } from 'core/models/Downloader.model';
import { ArrayField } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { addSelectedField, removeSelectedFieldBySection, setSelectedFields } from 'core/store/slices/employee-earnings-download.slice';
import { createField } from 'utilities/utilities';
import { DownloaderContext } from '../UserMapPage';

type Props = {
  formMethods: any;
  section: string;
  selectedFields?: UserMapSelectedField[];
  title?: string;
  dFields?: Partial<ArrayField<DownloaderField>>[];
  isSelectAll?: boolean;
  children?: React.ReactNode;
  disabled?: boolean | null;
  pushSelectedFields?: any;
  grossToNetChecked?: boolean;
};

const CheckBoxForm = ({
  formMethods,
  section,
  title,
  dFields,
  isSelectAll,
  children = null,
  disabled = null,
  grossToNetChecked = false,
}: Props) => {
  const appDispatch = useAppDispatch();
  const selectedFields = useAppSelector((state) => { return state.employeeEarningsDownload.selectedFields; });
  const [applyAll, setApplyAll] = useState<boolean>(false);

  const onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApplyAll(e.target.checked);
  };
  
  const { setIsDirty } = useContext(DownloaderContext);

  return (
    <div
      key={section}
      className={title ? 'dm-panel-blue dm-panel-border' : 'mt-2'}
    >
      <div className="d-flex flex-row">
        <div className="dm-grid-action-title-blue">{title}</div>
        {!!isSelectAll && (
          <div className="flex-grow-1 text-right">
            <input
              type="checkbox"
              onChange={onSelectAll}
            /> Select All
          </div>
        )}
      </div>
      {title && (
        <hr className="dm-panel-hr" />
      )}
      <div className="d-flex flex-row flex-wrap">
        {dFields?.map((item, index) => {
          const sField = selectedFields?.find(s => {
            return s.section === section && s.fieldName === item.name;
          });
          const checked = sField || applyAll || grossToNetChecked ? true : false;
          const name = item.name;
          return (
            <div key={index}>
              <CheckboxGrpInLine
                labelWidth={250}
                labelWidthUnit="px"
                labelFirst={false}
                name={`selectedFields.${section}.${item.name}`}
                label={item.name}
                checked = {checked && !disabled}
                disabled = {disabled ?? false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.checked) {
                    appDispatch(addSelectedField(createField(name || '', section)));
                  } else {
                    appDispatch(removeSelectedFieldBySection({
                      section,
                      fieldName: name,
                    }));
                  }
                  
                  setIsDirty(true);
                }}
                ref={formMethods?.register()}
              />
            </div>
          );
        })}
      </div>
      {!!children && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
};

CheckBoxForm.displayName = 'CheckBoxForm';

export default CheckBoxForm;