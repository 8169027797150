import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { InputGrpInLine, SelectGrpInLine } from '..';
import { Department } from '../../../models';
import { createDepartment, updateDepartment } from '../../../store/actions';
import { getClient, getLocalTaxEntityWithCode } from '../../../store/selectors';
import { FieldInputSettings } from '../types';

const fs: FieldInputSettings = {
  deptCode: {
    name: 'deptCode',
    label: 'Dept Code',
    labelWidth: 25,
    type: 'number',
    required: true,
  },
  deptDesc: {
    name: 'deptDesc',
    label: 'Description',
    labelWidth: 25,
    required: true,
  },
  glAccount: { name: 'glAccount', label: 'GL Account', labelWidth: 25 },
  alphaCode: { name: 'alphaCode', label: 'Alpha Code', labelWidth: 25 },
  quickenClass: {
    name: 'quickenClass',
    label: 'Quickbooks Class',
    labelWidth: 25,
  },
  cityTaxCode: {
    name: 'cityTaxCode',
    label: 'City Tax Code',
    labelWidth: 25,
    type: 'number',
  },
};

type PropTypes = {
  item: any;
  options: any[];
  onHide: any;
};

const DepartmentOptionForm: React.FC<PropTypes> = ({
  item,
  options,
  onHide,
}) => {
  const dispatch = useDispatch();

  const currentClient = useSelector(getClient);
  const localTaxEntityOpts = useSelector(getLocalTaxEntityWithCode);

  const { register, errors, handleSubmit, setError, clearErrors } =
        useForm<any>({
          defaultValues: item
            ? { ...item }
            : new Department(
              currentClient?.clientNo ?? 0,
              currentClient?.clientID ?? 0,
            ),
        });

  const onDeptCodeChange = (e: any) => {
    options.find((l: Department) => {return l.deptCode === +e.target.value;})
      ? setError('deptCode', {
        type: 'BadDeptCode',
        message: 'Department Code already exists.',
      })
      : clearErrors('deptCode');
  };

  const onSubmit = (data: any, e?: React.BaseSyntheticEvent) => {
    const updatedDept = { ...item, ...data } as Department;
    updatedDept.deptCode = parseInt(data.deptCode);
    updatedDept.cityTaxCode = parseInt(data.cityTaxCode);
    if (updatedDept.deptId) {
      dispatch(updateDepartment(updatedDept));
    } else {
      updatedDept.clientNo = currentClient!.clientNo;
      updatedDept.clientId = currentClient!.clientID;
      updatedDept.deptId = 0;
      dispatch(createDepartment(updatedDept));
    }
    onHide(false);
  };

  return (
    <div className="dm-panel dm-panel-border mx-4 d-flex flex-column flex-nowrap">
      <div className="dm-grid-action-title">Add/Edit</div>
      <form onSubmit={handleSubmit(onSubmit)} id="department-option-form">
        <InputGrpInLine
          {...fs.deptCode}
          errors={errors.deptCode}
          onChange={onDeptCodeChange}
          ref={register}
        />
        <InputGrpInLine
          {...fs.deptDesc}
          errors={errors.deptDesc}
          ref={register({ required: 'Description is Required' })}
        />
        <InputGrpInLine
          {...fs.glAccount}
          errors={errors.glAccount}
          ref={register}
        />
        <InputGrpInLine
          {...fs.alphaCode}
          errors={errors.alphaCode}
          ref={register}
        />
        <InputGrpInLine
          {...fs.quickenClass}
          errors={errors.quickenClass}
          ref={register}
        />
        <SelectGrpInLine
          {...fs.cityTaxCode}
          errors={errors.cityTaxCode}
          options={[{}, ...localTaxEntityOpts]}
          ref={register}
        />

        <div style={{ display: 'flex' }}>
          <button
            type="button"
            className="btn btn-primary orange-outline-button ml-auto mr-2"
            onClick={() => {return onHide(false);}}
          >
            Cancel
          </button>
          <button type="submit" className="orange-button mr-2">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default DepartmentOptionForm;
