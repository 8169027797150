import React from 'react';
import CheckBoxForm from '../../Shared/CheckBoxForm';
import { Col, Row } from 'react-bootstrap';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for Checkbox and Grid select forms
const EmployeeStatusEmergencyContactFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  return (
    <Row>
      <Col sm={6}>
        <Row>
          {!!sectionFields?.length && (
            <Col xs={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'General Information';
                })?.fields}
                section="General Information"
                title="General Information"
              />
            </Col>
          )}
          {!!sectionFields?.length && (
            <Col xs={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'Other';
                })?.fields}
                section="Other"
                title="Other"
              />
            </Col>
          )}
        </Row>
      </Col>
      <Col sm={6}>
        <Row>
          {!!sectionFields?.length && (
            <Col xs={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'Contract Information';
                })?.fields}
                section="Contract Information"
                title="Contract Information"
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default EmployeeStatusEmergencyContactFields;