import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PanelHeader from 'core/components/shared/PanelHeader';
import { HRDependent, HRDependentRequest } from 'core/models/HREmployee.model';
import {
  createHRDependent,
  deleteHRDependent,
  loadHRDependent,
  updateHRDependent,
} from 'core/store/actions/hr-employee.action';
import { getDependents } from 'core/store/selectors/hr-employee.selector';
import DependentsItem from './DependentsItem';
import { useForm, useFieldArray } from 'react-hook-form';
import { UNSAVED_MESSAGE } from 'core/constants';
import { formatPhone } from 'utilities/utilities';
import Icon from 'core/components/shared/Icon';
import { toggleBlockNavigation } from 'core/store/actions';

type FormType = {
  dependentItems: HRDependent[];
};
const DependentsPage = () => {
  const dispatch = useDispatch();

  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();
  const [dependentItems, setDependentItems] = useState<HRDependent[]>([]);
  const dependentsStore = useSelector(getDependents);


  const {
    reset,
    control,
    handleSubmit,
    formState,
    ...formMethods
  } = useForm<FormType>({
    defaultValues: {
      dependentItems,
    },
  });

  const { fields, prepend } =
    useFieldArray({
      control,
      name: 'dependentItems',
      keyName: 'id',
    });


  useEffect(() => {
    protectedEmpNo && dispatch(loadHRDependent(protectedEmpNo));
  }, [protectedEmpNo]);

  useEffect(() => {
    dependentsStore && setDependentItems(dependentsStore);
  }, [dependentsStore]);

  useEffect(() => {
    dependentItems && reset({
      dependentItems: dependentItems?.map((d: HRDependent) => {
        return {
          ...d,
          homePhone: formatPhone(d.homePhone!),
          workPhone: formatPhone(d.workPhone!),
          cellPhone: formatPhone(d.cellPhone!),
        };
      }),
    });
  }, [dependentItems]);

  const addDependent = () => {
    const dependent: HRDependent = {
      contactId: 0,
      protectedEmpNo,
    };
    prepend(dependent);
  };

  const deleteDependent = (item: HRDependent) => {
    if (item.contactId) {
      dispatch(deleteHRDependent({
        data: item,
        protectedEmpNo,
      }));
    } else {
      setDependentItems((prev: HRDependent[]) => { return prev.filter((cb: HRDependent) => { return cb.contactId; }); });
    }
  };

  const onSubmit = (data: FormType, e?: React.BaseSyntheticEvent) => {
    if (!e?.target.isConnected) {
      return false;
    }
    data && data.dependentItems.forEach((item: HRDependent) => {
      const request: HRDependentRequest = {
        protectedEmpNo,
        data: {
          ...item,
          contactId: +item.contactId,
          birthDate: item.birthDate !== '' ? new Date(item.birthDate!).toISOString() : null,
          marriageDate: item.marriageDate !== '' ? new Date(item.marriageDate!).toISOString() : null,
          scholarPaidDate: item.scholarPaidDate !== '' ? new Date(item.scholarPaidDate!).toISOString() : null,
          isDependent: true,
        },
      };
      request.data.contactId !== 0
        ? dispatch(updateHRDependent(request))
        : dispatch(createHRDependent(request));

    });
  };

  const onCancel = () => {
    reset();
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: formState.isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [formState.isDirty]);

  return (
    <div>
      <div className="dm-panel dm-panel-border" >
        <PanelHeader title="Dependent" >
          <button
            className="btn btn-link dm-grid-action-title p-0 mr-2"
            type="button"
            onClick={addDependent}
          >
            Add Dependent	<Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields?.map((item, index: number) => {
            return (
              <div key={item.id} >
                <DependentsItem
                  key={item.id}
                  item={item}
                  index={index}
                  control={control}
                  formMethods={formMethods}
                  onDelete={deleteDependent}
                />
                <hr className="dm-panel-hr mt-2" />
              </div>
            );
          })}

          <div className="text-right mt-3">
            <button
              type="button"
              className="btn btn-primary orange-outline-button ml-auto mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              disabled={!formState.isDirty}
              type="submit"
              className="btn orange-button-sm mr-3"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DependentsPage;
