import { createAction } from '@reduxjs/toolkit';
import { HireHistory, HireHistoryWithRehireInfo } from '../../models';

export const HIREHISTORY_EM_VALIDATION_ACTIONS = {
  CREATE_HIRE_HISTORY: '[hire-history] CREATE_HIRE_HISTORY_WIZARD',
  UPDATE_HIRE_HISTORY: '[hire-history] UPDATE_HIRE_HISTORY',
  DELETE_HIRE_HISTORY: '[hire-history] DELETE_HIRE_HISTORY',
} as const;

export const loadHireHistories = createAction<string>('[hire-history] LOAD_HIRE_HISTORIES');
export const storeHireHistories = createAction<HireHistory[]>('[hire-history] STORE_HIRE_HISTORIES');

export const loadHireHistory = createAction<string>('[hire-history] LOAD_HIRE_HISTORY');
export const storeHireHistory = createAction<HireHistory>('[hire-history] STORE_HIRE_HISTORY');

export const addHireHistoryWizard = createAction<HireHistoryWithRehireInfo>(HIREHISTORY_EM_VALIDATION_ACTIONS.CREATE_HIRE_HISTORY);
export const storeHireHistoryWizardMessage = createAction<string>('[hire-history] STORE_HIRE_HISTORY_WIZARD_MESSAGE');
export const updateHireHistory = createAction<HireHistory>(HIREHISTORY_EM_VALIDATION_ACTIONS.UPDATE_HIRE_HISTORY);
export const deleteHireHistory = createAction<HireHistory>(HIREHISTORY_EM_VALIDATION_ACTIONS.DELETE_HIRE_HISTORY);
