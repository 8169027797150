import { createSelector } from 'reselect';
import { transferState } from '../reducers';
import { State as TransferState } from '../reducers/transfer.reducer';

export const getTransfers = createSelector(
  transferState,
  (state: TransferState) => {return state.transfers;},
);

export const getTransferStatus = createSelector(
  transferState,
  (state: TransferState) => {return state.status;},
);