import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from 'utilities/hooks';
import { createJob, getInterviewProcesses, getJobs, storeSelectedJob } from 'core/store/slices/applicantTracking.slice';
import { AvailableJob, ReviewerPayroll, Tag, employmentStatusOpts } from 'core/models';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import PanelHeader from 'core/components/shared/PanelHeader';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CheckboxGrpInLine, InputGrp, SelectGrp, SelectGrpInLine } from 'core/components/form-controls';
import { useFieldArray, useForm } from 'react-hook-form';
import { getStates } from 'core/store/selectors';
import Icon from 'core/components/shared/Icon';
import { WithContext as ReactTags, Tag as ControlTag } from 'react-tag-input';
import { getUsers } from 'core/store/slices/cmUsers.slice';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UNSAVED_MESSAGE } from 'core/constants';
import QuillCustomToolbar from 'core/components/form-controls/QuillCustomToolbar';

type DropdownSimple = {
  id: string, description: string
};

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const ManageJob = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const stateOpts = useSelector(getStates);
  const [selectedJob, jobs, users, localTaxFormOpts, interViewProcesses] = useAppSelector((state) => {
    return [
      state.applicantTracking?.selectedJob,
      state?.applicantTracking?.jobs?.availableJobs,
      state.cmUsers.users,
      state.dropdown.localTaxEntity,
      state.applicantTracking.interviewProcesses];
  });

  
  const modules = {
    toolbar: {
      container: '#toolbar',
    },
  };
  
  const [jobTitleOpts, setJobTitleOpts] = useState<DropdownSimple[]>([]);
  const [processesOpts, setProcessesOpts] = useState<DropdownSimple[]>([]);
  const [description, setDescription] = useState('');
  const [showLocalTax, setShowLocalTax] = useState<boolean | undefined>(selectedJob?.taxEntities?.length! > 0);
  const [tags, setTags] = useState<ControlTag[]>([]);
  const [quillIsDirty, setQuillIsDirty] = useState<boolean>(false);
  const [tagsAreDirty, setTagsAreDirty] = useState<boolean>(false);
  const [selectedJobState, setSelectedJobState] = useState<AvailableJob>();

  const { handleSubmit, reset, control, register, setValue, formState: { isDirty } } = useForm<AvailableJob>({
    defaultValues: {
      ...selectedJob,
      employmentStatus : selectedJob?.employmentStatus  ?? 0,
      interviewProcessId: selectedJob?.interviewProcessId ?? 0,
      reviewerPayrolls: selectedJob?.reviewerPayrolls,
    },
  });
  const { fields: reviewerPayrolls,
    append: reviewerAppend,
    remove: reviewerRemove } = useFieldArray({
    control,
    name: 'reviewerPayrolls',
  });

  useEffect(() => {
    dispatch(getInterviewProcesses());
    dispatch(getUsers());
    dispatch(getJobs());  
  }, []);

  useEffect(() => {     
    jobs && setJobTitleOpts(
      jobs.map(j => {
        return {
          id: '' + j.jobId,
          description: j.jobTitle,
        };
      }));
  
  }, [jobs]);

  useEffect(() => {
    interViewProcesses && setProcessesOpts(
      interViewProcesses.map(i => {
        return {
          id: '' + i.interviewProcessId,
          description: i.title,
        };
      }));
  }, [interViewProcesses]);

  useEffect(() => { 
    selectedJob && reset(selectedJob);
    selectedJob && setSelectedJobState(selectedJob);       
    setQuillIsDirty(false);
    setTagsAreDirty(false);   
    selectedJob?.tags.length && setTags(selectedJob?.tags?.map((t: Tag) => {
      return {
        id: '' + t.jobId,
        text: t.tag,
      };
    }) as unknown as ControlTag[]);
    selectedJob && setDescription(selectedJob.description);
   
  }, [selectedJob]);

  const onJobSearchResults = (searchText: string) => {
    const job = searchText && jobs?.find(a => {
      return a.jobId === +searchText;
    });
    job && dispatch(storeSelectedJob(job));
  };

  const onSubmit = (data: AvailableJob) => {
    if (selectedJobState) {
      const job: AvailableJob = {
        ...selectedJobState,
        jobId: data.jobId,
        company: data.company,
        jobTitle: data.jobTitle,
        description: description,
        city: data.city,
        state: data.state,
        interviewProcessId: data.interviewProcessId ?? 0,
        employmentStatus : data.employmentStatus  ?? 0,
        tags: tags.map((tag: ControlTag) => {
          return {
            jobId: data.jobId,
            tag: tag.text,
          };
        }) as unknown as Tag[],
        taxEntities: data.taxEntities ?? [],
        applicants: data.applicants ?? [],
        reviewerPayrolls: data.reviewerPayrolls ?? [],
      };

      dispatch(createJob(job)); 
    }
  };

  const onCancel = () => {
    history.push('/manage-available-jobs');
  };

  const onAddReviewer = () => {
    const reviewer: ReviewerPayroll = {
      jobId: selectedJob?.jobId ?? 0,
      secUserId: 0,
    };
    reviewerAppend(reviewer);
  };

  const onDeleteReviewer = (index: number) => {
    reviewerRemove(index);
  };

  const handleDelete = (i: number) => {
    setTags(tags.filter((tag, index) => { return index !== i; }));
  };

  const handleAddition = (tag: ControlTag) => {
    setTags((prev: ControlTag[]) => { return [...prev, tag]; });
  };

  const handleDrag = (tag: ControlTag, currPos: number, newPos: number) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
  };

  return (

    <div className="dm-panel dm-panel-border" >
      <Prompt
        when={isDirty || quillIsDirty || tagsAreDirty}
        message={UNSAVED_MESSAGE}
      />
      <PanelHeader
        title="Manage Job"
        iconName="wrench"
      >
        {selectedJob && (<div>
          <SelectGrpInLine
            label="Job Title"
            labelWidth="30"
            defaultValue={'' + selectedJob.jobId}
            options={jobTitleOpts}
            onChange={(e: any) => { return onJobSearchResults(e.target.value); }}
          />
        </div>)}
      </PanelHeader><form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-row flex-grow-1 mt-3">
          <div
            className="d-flex flex-column"
            style={{ width: '75%' }}
          >
            <div className="dm-panel dm-panel-border d-flex flex-row flex-wrap">
              <div className="dm-subtitle2 d-flex flex-grow-1 w-100">Job Details</div>
              <input
                type="hidden"
                name={'jobId'}
                defaultValue={selectedJob?.jobId}
                ref={register({ valueAsNumber: true })}
              />
              <InputGrp
                name={'company'}
                defaultValue={selectedJob?.company}
                label="COMPANY NAME"
                groupClass="gc12"
                ref={register()}
              />
              <InputGrp
                name={'jobTitle'}
                defaultValue={selectedJob?.jobTitle}
                label="JOB TITLE"
                groupClass="gc12"
                ref={register()}
              />

              <InputGrp
                name={'city'}
                defaultValue={selectedJob?.city}
                label="CITY"
                groupClass="gc12"
                ref={register()}
              />
              <SelectGrp
                name={'state'}
                defaultValue={selectedJob?.state}
                label="STATE"
                groupClass="gc12"
                ref={register()}
                options={stateOpts}
              />
              <SelectGrp
                name={'interviewProcessId'}
                defaultValue={selectedJob?.interviewProcessId}
                label="INTERVIEW PROCESS"
                groupClass="gc12"
                options={processesOpts}
                ref={register({ valueAsNumber: true })}
              />
              <SelectGrp
                name={'employmentStatus'}
                label="Employment Status"
                defaultValue={selectedJob?.employmentStatus  ?? 0}
                groupClass="gc12"
                options={employmentStatusOpts}
                ref={register({ valueAsNumber: true })}
              />
              {showLocalTax && (
                <SelectGrp
                  name={'entityId'}
                  label="LOCAL TAX FORM"
                  defaultValue={selectedJob?.taxEntities?.[0]?.entityId}
                  groupClass="gc12"
                  options={localTaxFormOpts}
                />
              )}
              <div style={{paddingTop:'25px'}}>
              <CheckboxGrpInLine
                defaultValue={showLocalTax}
                label={showLocalTax ? 'Remove Local Tax Form' : 'Add Local Tax Form'}
                onChange={((e: React.ChangeEvent<HTMLInputElement>) => {
                  setShowLocalTax(e.target.checked);
                })}
              />
              </div>
            </div>
            <div className="dm-panel dm-panel-border">
              <div className="dm-subtitle2">Job Description</div>
              <QuillCustomToolbar />
              <ReactQuill             
                theme="snow"
                value={description}                
                modules={modules}
                onChange={(e: any) => { return setDescription(e), setQuillIsDirty(true); }}
              />
            </div>
          </div>
          <div
            className="dm-panel dm-panel-border ml-3"
            style={{ width: '30%' }}
          >
            <div className="d-flex flex-column">
              <div className=" dm-panel dm-panel-border d-flex flex-column">
                <div className="dm-subtitle2">Application Reviewers</div>
                <hr className="dm-panel-hr" />
                <button
                  type="button"
                  className="btn btn-link dm-grid-action-title d-flex justify-content-end "
                  onClick={() => {
                    onAddReviewer();
                  }}
                >
                  Add Reviewer
                  <Icon
                    name="plus-circle"
                    className="fa-plus-circle"
                  />
                </button>
                {reviewerPayrolls?.map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      className="d-flex flex-row clear-all mt-3"
                    >
                      <input
                        type="hidden"                       
                        name={`reviewerPayrolls[${index}].jobId`}
                        defaultValue={selectedJob?.jobId}
                        ref={register({ valueAsNumber: true })}
                      />
                      <SelectGrp                       
                        name={`reviewerPayrolls[${index}].secUserId`}
                        defaultValue={item.secUserId}
                        groupClass="gc10 mw250"
                        ref={register({ valueAsNumber: true })}
                        options={users?.map(u => {
                          return {
                            id: u.secUserId,
                            description: u.fullName,
                          };
                        })}
                      />
                      <button
                        type="button"
                        className="btn btn-link dm-grid-action-title float-right"
                        onClick={() => {
                          onDeleteReviewer(index);
                        }}
                      >
                        <Icon
                          name="minus-circle"
                          className="fa-minus-circle"
                        />
                      </button>
                    </div>
                  );
                })}
              </div>
              <div className="dm-panel dm-panel-border">
                <div className="dm-subtitle2 d-flex flex-row">Add Tags
                  <OverlayTrigger
                    key={'tags-overlay'}
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={(<Tooltip
                      id="tags-tooltip"
                      placement="bottom"
                      style={{ fontSize: '10pt' }}
                    >
                      Here you can add custom &quot;Tags&quot; that will allow applicants to search for specific jobs easier.<br /> <br />
                      Tags are generally short keywords or phrases that help describe what a person is looking for.
                    </Tooltip>)}
                  >
                    <Button
                      variant="link"
                      className="p-0 pl-2"
                    >
                      <Icon
                        name="circle-info"
                        fontSize={'0.75rem'}
                      />
                    </Button>
                  </OverlayTrigger>

                </div>

                <ReactTags
                  tags={tags}
                  delimiters={delimiters}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  inputFieldPosition="bottom"
                  autocomplete
                  handleInputChange={()=>{return setTagsAreDirty(true);}}
                />
              </div>
            </div>
            <div className="d-flex flex-column">
              <button
                type="submit"
                className="orange-button"
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-primary orange-outline-button mt-2"
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

  );
};

export default ManageJob;