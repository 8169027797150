import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../providers/authProvider';
import { signinRedirectCallback, storeUser } from '../../store/actions';
import RedirectSpinner from './RedirectSpinner';
import { SignInUserObject } from 'core/models';
import { User } from 'core/models/User.model';

export const SignInCallBack: React.FC = () => {
  const auth = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    async function signInAsync() {
      const user: SignInUserObject = await auth.getUser() as SignInUserObject;
      // maybe we don't want the actual object data here... so we'll just keep this dummy for now
      const userForReduxStore: User = {
        secUserId: 0,
        username: 'loggedIn',
        isSuperUser: false,
      };
      
      dispatch(storeUser(userForReduxStore));
      dispatch(signinRedirectCallback(user));
    }
    signInAsync();
  }, [dispatch]);

  return <RedirectSpinner />;
};

export default SignInCallBack;
