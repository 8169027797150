import { Employee } from 'core/models';
import { SortReducerState } from 'hooks/useTableSort';
import { TableModel as Tm } from './types';

// #region Functions
/**
 * Checks if a number is in a specified range. In this case, it's used with pagination to ensure we're only rendering
 * elements in a given range of indexes.
 * @param index (`number`) The position in the list we want to check against `range`
 * @param range (`[number, number]`) Our range
 * @returns boolean indicating whether or not the index is in the range
 */
export const inRange = (index: number, range: [number, number]) => index >= range[0] && index <= range[1];

export function isMatch<T>(val: T, filterData: Tm.FilterData): boolean {
  let match = true;
  
  switch (typeof val) {
    case 'boolean':
      if (filterData.isTrue === false && val === true) match = false;
      break;
    case 'string':
    case 'number':
      switch (filterData.filterType) {
        case 'Contains':
        default:
          match = String(val).toLowerCase().trim().includes(filterData?.query || '');
          if (!match) return false;
          break;
        case 'Not contains':
          match = !String(val).toLowerCase().trim().includes(filterData?.query || '');
          if (!match) return false;
          break; 
        case 'Blank':
          match = !String(val).length;
          if (!match) return false;
          break;
        case 'Not blank':
          match = !!String(val).length;
          if (!match) return false;
          break;
        case 'Equals':
          match = String(val).toLowerCase().trim() === (filterData?.query || '');
          if (!match) return false;
          break;
        case 'Not equal':
          match = String(val).toLowerCase().trim() !== (filterData?.query || '');
          if (!match) return false;
          break;
        case 'Starts with':
          match = String(val).toLowerCase().startsWith(filterData?.query || ' ');
          if (!match) return false;
          break;
        case 'Ends with':
          match = String(val).toLowerCase().endsWith(filterData?.query || ' ');
          if (!match) return false;
          break;
        case 'After':
          if (!filterData?.compareDate) {
            match = true;
          } else if (!val) {
            match = false;
          } else {
            match = (new Date(String(val)) > new Date(filterData.compareDate));
          }
          if (!match) return false;
          break;
        case 'Before':
          if (!filterData?.compareDate) {
            match = true;
          } else if (!val) {
            match = false;
          } else {
            match = (new Date(String(val)) < new Date(filterData.compareDate));
          }
          if (!match) return false;
          break;
        case 'In range':
          // until we have both a from and to date, all values are considered "in range"
          if (!(filterData?.dateFrom && filterData?.dateTo)) {
            match = true;
          } else if (!val) {
            match = false;
          } else {
            match = (new Date(String(val)) >= new Date(filterData.dateFrom)) && (new Date(String(val)) <= new Date(filterData.dateTo));
          }
          if (!match) return false;
          break;
      }
      break;
    case 'undefined':
      match = false;
      return false;
    case 'object':
      if (val === null) {
        switch (filterData.filterType) {
          case 'Blank':
            match = true;
            break;
          case 'Not blank':
          case 'Contains':
          case 'Equals':
          case 'Starts with':
          case 'Ends with':
          case 'In range':
          case 'After':
          case 'Before':
            match = false;
            return false;
        }
      } else if (Array.isArray(val)) {
        const value = val as Array<any>; // just tp make the next part easier to read
        match = isMatch<T>(value?.[value.length - 1], filterData);
      }
      break;
  }
  
  return match;
}

/**
 * 
 * @template T
 * @param x 
 * @param currentFilterModel 
 * @returns boolean indicating if there's a match in the record
 */
export function baseFilter<T>(x: T, currentFilterModel: Tm.FilterModel<T>): boolean {
  // iterate over the filter model to consider all filters on all columns. 
  for (const [key, filterData] of Object.entries(currentFilterModel) as [keyof T, Tm.FilterData][]) {
    // switch on the type of x[key]; for example, x.empNo is a number.
    const match = isMatch(x[key], filterData);
    if (!match) return false;
  }
  
  return true;
}

/**
 * Builds initial render sort state for table based on current filter model.
 * @param filterModel The current filter model of the table
 * @returns `SortReducerState`
 */
export function buildInitialState<T>(filterModel: Tm.FilterModel<T>): SortReducerState {
  const initialState: SortReducerState = {};
  
  // grab each key and sort order for each value of the filter model
  for (const [key, value] of Object.entries(filterModel)) {
    initialState[key] = (value as Tm.FilterData).sortOrder || 'UNSORTED';
  }
  
  return initialState;
}
// #endregion