import React from 'react';
import { InputGrp } from 'core/components/form-controls';
import { FieldInputSettings, FormMethods } from 'core/components/form-controls/types';
import { useAppSelector } from 'utilities/hooks';
import { AttendanceOnDemand } from 'core/models';

const fs: FieldInputSettings = {
  staticField1: { name: 'staticField1',
    label: 'STATIC FIELD 1',
    groupClass: 'gc95 mw400' },
  staticField2: { name: 'staticField2',
    label: 'STATIC FIELD 2',
    groupClass: 'gc95 mw400' },
  staticField3: { name: 'staticField3',
    label: 'STATIC FIELD 3',
    groupClass: 'gc95 mw400' },
  staticField4: { name: 'staticField4',
    label: 'STATIC FIELD 4',
    groupClass: 'gc95 mw400' },
  staticField5: { name: 'staticField5',
    label: 'STATIC FIELD 5',
    groupClass: 'gc95 mw400' },
  staticField6: { name: 'staticField6',
    label: 'STATIC FIELD 6',
    groupClass: 'gc95 mw400' },
};

type PropTypes = {
  AOD: AttendanceOnDemand  | null;
  formMethods: Omit<FormMethods, 'handleSubmit' | 'reset'>;
};

const AttendanceOnDemandFields: React.FC<PropTypes> = ({ formMethods: { errors, register }, AOD }) => {
  const clientAod = useAppSelector(({ selEmployeeDetails }) => selEmployeeDetails.attendanceOnDemand.clientAttendanceOnDemand);
  return (
    <div className="dm-panel dm-panel-border pb-4" style={{minWidth: 400}}>
      <div className="dm-card-subtitle2 mb-2">Attendance On Demand Fields</div>
      <div className="d-flex flex-row flex-wrap">
        <div className="d-flex flex-column">
          {clientAod?.staticCustom1 ? <InputGrp
              {...fs.staticField1}
              errors={errors.staticField1}
              ref={register}
            />:
          <input name={fs.staticField1.name} type="hidden" ref={register} defaultValue={AOD?.staticField1 || ''}/>}

          {clientAod?.staticCustom2 ? <InputGrp
              {...fs.staticField2}
              errors={errors.staticField2}
              ref={register}
            />:
          <input name={fs.staticField2.name} type="hidden" ref={register} defaultValue={AOD?.staticField2 || ''}/>}

          {clientAod?.staticCustom3 ? <InputGrp
            {...fs.staticField3}
            errors={errors.staticField3}
            ref={register}
          />:
          <input name={fs.staticField3.name} type="hidden" ref={register} defaultValue={AOD?.staticField3 || ''}/>}

          {clientAod?.staticCustom4 ? <InputGrp
              {...fs.staticField4}
              errors={errors.staticField4}
              ref={register}
            />:
          <input name={fs.staticField4.name} type="hidden" ref={register} defaultValue={AOD?.staticField4 || ''}/>}

          {clientAod?.staticCustom5 ? <InputGrp
              {...fs.staticField5}
              errors={errors.staticField5}
              ref={register}
            />:
          <input name={fs.staticField5.name} type="hidden" ref={register} defaultValue={AOD?.staticField5 || ''}/>}

          {clientAod?.staticCustom6 ? <InputGrp
              {...fs.staticField6}
              errors={errors.staticField6}
              ref={register}
            />:
          <input name={fs.staticField6.name} type="hidden" ref={register} defaultValue={AOD?.staticField6 || ''}/>}

        </div>
      </div>
    </div>
  );
};

export default AttendanceOnDemandFields;
