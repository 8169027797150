import { useRef } from 'react';
import { Client } from '../../core/models';
import { useAppDispatch, useAppSelector } from '../../utilities/hooks';
import { WOTCReportRequest } from '../../core/models/HumanCapitalReports.model';
import { ControlDatePickerGrpInLine, RadioGrp } from '../../core/components/form-controls';
import { RadioOptions } from '../../core/components/form-controls/RadioGrp';
import { useForm } from 'react-hook-form';
import { setReportFileName, setReportFileType } from 'core/store/slices/reportDownloader.slice';
import { downloadReportThunk } from 'core/store/actions/download-report-file.action';
import { HumanCapitalReportService } from 'core/services';
import { Files } from 'enums/Files';
import ExcelReportDownloadButton from 'core/components/shared/ExcelReportDownloadButton';
import { convToDateISOString } from 'utilities/utilities';

const empOptions: RadioOptions[] = [
  {
    value: 'AllEmployees',
    label: 'All Employees',
  },
  {
    value: 'EligibleEmployeesOnly',
    label: 'Eligible Employees Only',
  },
];

const WOTCReport = () => {

  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => {return state.client.client;}) as Client;
  const currentYear = new Date().getFullYear();

  const { getValues, setValue, register, control } =
        useForm<WOTCReportRequest>({
          defaultValues: {
            empOptions: 'AllEmployees',
            dateFrom: new Date((currentYear - 2),0,1),
            dateTo: new Date(currentYear,11,31)
          },
        });

  const downloadReport = async () => {
    const submitData: WOTCReportRequest = {
      clientNo: +client.clientNo,
      federalId: +client.federalID,
      empOptions: getValues('empOptions'),
      dateFrom: convToDateISOString(getValues('dateFrom')),
      dateTo: convToDateISOString(getValues('dateTo')),
    };
    dispatch(setReportFileName('WOTCReport'));
    dispatch(setReportFileType(Files.EXCEL));
    dispatch(downloadReportThunk(() => { return HumanCapitalReportService.postWOTCReport(submitData); }));
  };

  return (<div>
    <div className="dm-panel dm-panel-border">
      <div className="dm-grid-title">WOTC Report</div>
      <hr className="dm-panel-hr" />

      <div className="d-flex flex-row flex-grow-1">
        <div className="d-flex ">
          <div>
            <ControlDatePickerGrpInLine
              name={'dateFrom'}
              label={'From Date'}
              labelWidth={40}
              groupClass={'groupClass50'}
              control={control}
              value={getValues('dateFrom')}
              setValue={setValue}
            />
            <ControlDatePickerGrpInLine
              name={'dateTo'}
              label={'To Date'}
              labelWidth={40}
              groupClass={'groupClass50'}
              control={control}
              value={getValues('dateTo')}
              setValue={setValue}
            />
          </div>
          <div>
            <RadioGrp
              radioOptions={empOptions}
              name="empOptions"
              ref={register}
            />
          </div>
        </div>
        <div className="flex-grow-1 text-right">
          <ExcelReportDownloadButton onClick={downloadReport} />
        </div>
      </div>
    </div>
  </div>
  );



};
export default WOTCReport;