import { createSelector } from 'reselect';
import { clientLibraryState } from '../reducers';
import { State } from '../reducers/client-library.reducer';

export const getFolders = createSelector(
  clientLibraryState,
  (state: State) => {
    return state?.clientLibrary || []; 
  },
);

export const fetchClientLibrary = createSelector(
  clientLibraryState,
  (state: State) => { 
    return state?.clientLibrary; 
  },
);

export const fetchClientFolder = createSelector(
  clientLibraryState,
  (state: State) => {
    return state.clientFolder;
  },
);