import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { loadSso } from '../../store/actions/sso.action';
import Icon from '../shared/Icon';
import { DMDropdownItemModel } from './DMDropDown';
import { DMDropdownSubItem } from './DMDropdownSubItem';
import { useAppSelector } from 'utilities/hooks';
import { getGroupAccess } from 'utilities/utilities';
import { AccessGroup } from 'core/models/ModuleAccess.model';

type DMDropdownItemProps = {
  data: DMDropdownItemModel;
  modal?: boolean;
  onShowModal?: any;
  onHideModal?: any;
  onSelect?: (...args: any[]) => void;
};

export const DMDropdownItem: React.FC<DMDropdownItemProps> = ({
  data: { id, text, href, preIcon, postIcon, modal, extLink, ...rest },
  onShowModal,
  onSelect,
}) => {
  const dispatch = useDispatch();
  
  const [isVisible, setIsVisible] = useState(false);
  const [leftOrRight, setLeftOrRight] = useState<number>(0);
  
  // get groups and employee master security sections
  const [groups, emSections] = useAppSelector(({ app }) => [app.moduleAccess?.groups, app.moduleAccess?.employeeMasterSections]);
  
  /* 
    Determines position of dropdown submenus. Uses the width of the current element, the window width, mouse position,
    and offset to determine whether the submenu should render to the left or right of the menu. Something werid to note:
    If the mouse is directly over the menu item name, the width is 255, but otherwise it's 275 (thus the extra check for
    this in the conditions). Then ratios and a couple of offsets are used to position it directly next to the main menu.
    (May come up with something better in the future. These ratios and offsets were determined after trying things out).
 */
  const show = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    const { width } = target.getBoundingClientRect();
    
    if ((window.innerWidth - (e.clientX + 200)) < target.offsetWidth) { // 200 used as a "buffer" here
      if (width === 275) {
        setLeftOrRight(-target.offsetWidth - (.5 * target.offsetWidth) + 22.5);
      } else {
        setLeftOrRight(-target.offsetWidth - (.53 * target.offsetWidth));
      }
    } else {
      if (width === 275) {
        setLeftOrRight(target.offsetWidth - 15);
      } else {
        setLeftOrRight(target.offsetWidth + 5);
      }
    }
    setIsVisible(true);
  };
  const hide = () => { return setIsVisible(false); };
  
  const onClick = () => {
    setIsVisible(false);
    if (!onSelect) return;
    onSelect(id);
  };
  
  const haveSubItems = rest?.subItems?.length ? true : false;
  let subGroupAccess: AccessGroup | undefined;
  
  if (haveSubItems && rest?.subGroupId) {
    subGroupAccess = groups?.find((g) => {return g.groupId === rest.subGroupId;});
  }
  const { items: accessItems, visible: validAccess = true } = subGroupAccess || {};

  return (
    <>
      {validAccess ? (
        <div
          id={id}
          className="dm1-dropdown-item"
          onClick={onClick}
          onMouseEnter={show}
          onMouseLeave={hide}
        >
          {preIcon !== undefined && (
            <img
              src={require(`../../../assets/${preIcon}`)}
              alt="done"
            ></img>
          )}
          {modal && !extLink && (
            <NavLink
              to="#"
              onClick={onShowModal}
            >
              {text}
            </NavLink>
          )}
          {href && !modal && !extLink && (
            <NavLink
              to={href}
              isActive={() => { return haveSubItems; }}
              activeClassName="show"
            >
              {text}
            </NavLink>
          )}
          {href && extLink && (
            <a
              href={href !== '' ? href : '#'}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                if (!(href === '#' || href === '')) {
                  window.open(href, '_blank');
                  if (href.startsWith('https://sso.dmpayroll.com/')) {
                    dispatch(loadSso());
                  }
                } else {
                  if (onSelect) {
                    onSelect('payroll-5');
                  }
                }
              }}
            >
              {text}
              <Icon
                name="external-link-alt"
                className="ml-1"
              />
            </a>
          )}
          {postIcon !== undefined ? (
            <img
              src={require(`../../../assets/images/${postIcon}`)}
              alt="done"
            ></img>
          ) : null}
          {haveSubItems ? (
            <div
              className={`dm1-dropdown-subitem-container ${isVisible ? 'd-block' : 'd-none'}`}
              style={{ left: leftOrRight }}
            >
              {rest.subItems?.map((subItem: DMDropdownItemModel) => {
                const itemAccess = getGroupAccess(accessItems ?? [], subItem.itemId);
                
                if (itemAccess && !itemAccess.visible) return;
                
                // PI-8694: Check if there's an EM section associated with this item. This section's visibility will dictate the visibility of this menu item too.
                if (subItem?.workItemId) {
                  const emSectionVisible = emSections?.find((x) => x.workItemId === subItem.workItemId)?.visible ?? true; // default to visible if not defined
                  if (!emSectionVisible) return;
                }
            
                return (
                  <DMDropdownSubItem
                    key={subItem.id}
                    data={subItem}
                    onSelect={onSelect}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};
