import { createAction } from '@reduxjs/toolkit';
import {
  AccrualBalanceRecord,
  AccrualEarningsCode,
  AccrualEdit,
  AccrualManualEntry,
  AccrualRule,
  AccrualTimeOffSummary,
  MarkRequestTaken,
  TimeOffRequest,
} from '../../models';

export const ACCRUAL_EM_VALIDATION_ACTIONS = {
  UPDATE_ACCRUAL_BEGIN_BALANCE: '[accrual] UPDATE_ACCRUAL_BEGIN_BALANCE',
  UPDATE_ACCRUAL: '[accrual] UPDATE_ACCRUAL',
  CREATE_ACCRUAL_MANUAL_ENTRY: '[accrual] CREATE_ACCRUAL_MANUAL_ENTRY',
  UPDATE_ACCRUAL_RULE: '[accrual] UPDATE_ACCRUAL_RULE',
};

export type CreateDefaultAccrualRequest = {
  protectedEmpNo: string;
  hireDate: string;
};

export const loadAccruals = createAction<string>('[accrual] LOAD_ACCRUALS');
export const storeAccruals = createAction<AccrualBalanceRecord[]>('[accrual] STORE_ACCRUALS');

export const updateAccrualBeginBalance = createAction<AccrualEdit>(ACCRUAL_EM_VALIDATION_ACTIONS.UPDATE_ACCRUAL_BEGIN_BALANCE);
export const storeAccrualBeginBalance = createAction<AccrualEdit>('[accrual] STORE_ACCRUAL_BEGIN_BALANCE');

export const updateAccrual = createAction<AccrualEdit>(ACCRUAL_EM_VALIDATION_ACTIONS.UPDATE_ACCRUAL);
export const storeAccrual = createAction<AccrualEdit>('[accrual] STORE_ACCRUAL');

export const createAccrualManualEntry = createAction<AccrualManualEntry>(ACCRUAL_EM_VALIDATION_ACTIONS.CREATE_ACCRUAL_MANUAL_ENTRY);
export const storeAccrualManualEntry = createAction<AccrualManualEntry>('[accrual] STORE_ACCRUAL_MANUAL_ENTRY');

export const loadAccrualRule = createAction<string>('[accrual] LOAD_ACCRUAL_RULE');
export const storeAccrualRule = createAction<AccrualRule>('[accrual] STORE_ACCRUAL_RULE');
export const updateAccrualRule = createAction<AccrualRule>(ACCRUAL_EM_VALIDATION_ACTIONS.UPDATE_ACCRUAL_RULE);


export const loadTimeOffRequests = createAction<string>('[accrual] LOAD_TIME_OFF_REQUESTS');
export const storeTimeOffRequests = createAction<TimeOffRequest[]>('[accrual] STORE_TIME_OFF_REQUESTS');

export const loadTimeOffRequest = createAction<{ empNo: number, timeOffRequestId: number }>('[accrual] LOAD_TIME_OFF_REQUEST');
export const storeTimeOffRequest = createAction<TimeOffRequest>('[accrual] STORE_TIME_OFF_REQUEST');

export const updateMarkTaken = createAction<MarkRequestTaken>('[accrual] UPDATE_MARK_TAKEN');
export const storeMarkTaken = createAction<MarkRequestTaken>('[accrual] STORE_MARK_TAKEN');

export const loadAccrualTimeOffSummary = createAction<string>('[accrual] LOAD_ACCRUAL_TIME_OFF_SUMMARY');
export const storeAccrualTimeOffSummary = createAction<AccrualTimeOffSummary[]>('[accrual] STORE_ACCRUAL_TIME_OFF_SUMMARY');

export const loadAccrualEarningsCodes = createAction('[accrual] LOAD_ACCRUAL_EARNINGS_CODES');
export const storeAccrualEarningsCodes = createAction<AccrualEarningsCode[]>('[accrual] STORE_ACCRUAL_EARNINGS_CODES');

export const createDefaultAccrualRecords = createAction<CreateDefaultAccrualRequest>('[accrual] CREATE_DEFAULT_ACCRUALS');