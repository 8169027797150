import { createAction } from '@reduxjs/toolkit';
import {
  CheckCalculator,
  CheckCalculateRequest,
  PrintCheck,
  PrintCheckVerify,
  RecordPrepay,
} from '../../models';

export const loadCheckCalculator = createAction<string>(
  '[check-calculator] LOAD_CHECK_CALCULATOR',
);
export const storeCheckCalculator = createAction<CheckCalculator>(
  '[check-calculator] STORE_CHECK_CALCULATOR',
);

export const calculateCheck = createAction<{
  calculateCheck: CheckCalculateRequest;
  protectedEmpNo: string;
}>('[check-calculator] CALCULATE_CHECK');
export const storeCheckCalculation = createAction<any>(
  '[check-calculator] STORE_CHECK_CALCULATION',
);

export const loadCalculateCheckReport = createAction<{
  printCheck: PrintCheck;
  protectedEmpNo: string;
}>('[check-calculator] LOAD_CALCULATE_CHECK_REPORT');

export const loadCalculateRecordPrepay = createAction<{
  recordPrepay: RecordPrepay;
  protectedEmpNo: string;
  printCheckVerify: PrintCheckVerify;
}>('[check-calculator] LOAD_CALCULATE_CHECK_RECORDPREPAY');

export const loadCalculateCheckPrint = createAction<{
  printCheck: PrintCheck;
  protectedEmpNo: string;
}>('[check-calculator] LOAD_CALCULATE_CHECK_PRINT');
export const calculateCheckPrintVerify = createAction<{
  printCheckVerify: PrintCheckVerify;
  protectedEmpNo: string;
}>('[check-calculator] CALCULATE_CHECK_PRINT');
export const storeCalculateCheckPdf = createAction<{
  pdf: string;
  pdfType: string;
}>('[check-calculator] STORE_CALCULATE_CHECK_PDF');

export const clearCalculateCheckPdf = createAction(
  '[check-calculator] CLEAR_CALCULATE_CHECK_PDF',
);

export const showAdjustmentModal = createAction<boolean>(
  '[check-calculator] STORE_RECORDPREPAY',
);

export const toggleCalculateFlag = createAction<boolean>('[check-calculator] TOGGLE_CALC_FLAG');