import Icon from 'core/components/shared/Icon';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PanelHeader from 'core/components/shared/PanelHeader';
import { loadPayRates, toggleBlockNavigation } from 'core/store/actions';
import {
  clearPayRateReport,
  getFuturePayRateReport,
} from 'core/store/slices/payRateReports.slice';
import { downloadBase64File } from 'utilities/base64';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { Pay } from './Pay';
import CurrentPayRates from 'core/components/shared/CurrentPayRates';
import { UNSAVED_MESSAGE } from 'core/constants';

const HrEmployeeStatusPayPage: React.FC = () => {
  const [dirtyState, setDirtyState] = useState<boolean>(false);
  
  const dispatch = useAppDispatch();

  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();

  const futurePayRateReport = useAppSelector(
    (state) => { return state.payRateReports.report; },
  );

  useEffect(() => {
    if (futurePayRateReport) {
      downloadBase64File(
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        futurePayRateReport,
        `futurePayRateReport${DateTime.local().toISO({
          includeOffset: false,
        })}.xlsx`,
      );
      dispatch(clearPayRateReport());
    }
  }, [futurePayRateReport]);

  useEffect(() => {
    dispatch(loadPayRates(protectedEmpNo));
  }, [protectedEmpNo]);

  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: dirtyState,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [dirtyState]);
  
  const downloadFuturePayRate = () => {
    dispatch(getFuturePayRateReport());
  };
  
  const updateDirtyState = (newVal: boolean) => {
    setDirtyState(newVal);
  };

  return (
    <>
      <div className="dm-panel dm-panel-border">
        <PanelHeader title="Pay">
          <button
            type="button"
            className="btn btn-link dm-grid-action-title pb-0 mr-2"
            onClick={downloadFuturePayRate}
          >
            Download Future Pay Rate <Icon name="download" />
          </button>
        </PanelHeader>
        <Pay updateDirtyState={updateDirtyState} />
        <CurrentPayRates
          dirtyState={dirtyState}
          updateDirtyState={updateDirtyState}
          protectedEmpNo={protectedEmpNo}
        />
      </div>
    </>
  );
};

export default HrEmployeeStatusPayPage;
