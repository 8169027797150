import Icon from 'core/components/shared/Icon';
import PanelHeader from 'core/components/shared/PanelHeader';
import React, { useState } from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { WebsiteImage } from 'core/models';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import CoverImageModal from './CoverImage.modal';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable, DroppableProvided, DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { deleteWebsiteImage, postWebsiteImage, putWebsiteImages } from 'core/store/slices/applicantTracking.slice';

const CoverImagesPage = () => {

  const dispatch = useAppDispatch();
  const designWebsite = useAppSelector((state) => { return state.applicantTrackingV2.designWebsite; });
  const [selectedImage, setSelectedImage] = useState<WebsiteImage>();
  const [showImageModal, setShowImageModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);

  const onAdd = () => {
    setSelectedImage(undefined);
    setShowImageModal(true);
  };

  const onDelete = (wi: WebsiteImage) => {
    setSelectedImage(wi);
    setShowDeleteConfirmationModal(true);
  };

  const onDeleteField = () => {
    selectedImage && dispatch(deleteWebsiteImage(selectedImage));
  };

  const onSetVisible = (wi: WebsiteImage) => {
    dispatch(putWebsiteImages({
      ...wi,
      visible: !wi.visible,
    }));
  };

  const confirmDelete = (confirm: boolean) => {
    if (confirm) {
      onDeleteField();
    }
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      width: '49%',
      // change background colour if dragging
      background: isDragging ? 'lightblue' : 'transparent',

      // styles we need to apply on draggables
      ...draggableStyle,
    };
  };

  const getListStyle = (isDraggingOver: boolean) => {
    return {
      background: isDraggingOver ? 'lightblue' : 'transparent',
    };
  };

  const onDragEnd = (result: any) => {

    const { destination, draggableId } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }


  };

  return (
    <Row className="no-gutters p-0">
      <Col className="mt-1 shadow p-3 border">
        <PanelHeader
          title="Design ATS Website"
          iconName="wand-magic-sparkles"
        />
        <div className="bg-light mt-3 p-3">
          <div className=" d-flex flex-row flex-grow-1 mb-3">
            <div className=" d-flex flex-column">
              <h2 className="font-weight-bold">Cover Images</h2>
              <div>Upload images that will be displayed at the top of your job listings on the Design ATS Website</div>
            </div>
            <div className=" flex-grow-1 justify-content-end text-right">
              <button
                type="button"
                className="btn btn-link dm-grid-action-title pb-0 mr-2"
                onClick={onAdd}
              >
                Add New Image <Icon
                  name="plus-circle"
                  className="fa-plus-circle"
                />
              </button>
            </div>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="dm-panel dm-panel-border p-2">
              <Droppable
                droppableId={'cover-images'}
              >
                {(providedDrop: DroppableProvided,
                  snapshotDrop: DroppableStateSnapshot) => {
                  return (
                    <div>
                      <div
                        {...providedDrop.droppableProps}
                        ref={providedDrop.innerRef}
                        style={getListStyle(snapshotDrop.isDraggingOver)}                                            
                      >
                        <div className="d-flex flex-row flex-wrap"   >
                          {designWebsite?.webSiteImages.map((item, index) => {
                            return (
                              <Draggable
                                key={item.webSiteImageId}
                                draggableId={'' + item.webSiteImageId}
                                index={index}                            
                              >

                                {(providedDrag: DraggableProvided, snapshotDrag: DraggableStateSnapshot) => {
                                  return (
                                    <div
                                      className="dm-drop-container mx-1"
                                      ref={providedDrag.innerRef}
                                      {...providedDrag.draggableProps}
                                      {...providedDrag.dragHandleProps}
                                      style={getItemStyle(
                                        snapshotDrag.isDragging,
                                        providedDrag.draggableProps.style,
                                      )}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          style={{
                                            width: 570,
                                            height: 120,
                                          }}
                                          src={'data:image/png;base64,' + item.image}
                                          alt={'CoverImage' + item.webSiteImageId}
                                        />
                                        <OverlayTrigger
                                          key={'ellipsis-overlay'}
                                          trigger={['click']}
                                          placement="left"
                                          rootClose={true}
                                          overlay={(
                                            <Tooltip
                                              id="ellipsis-tooltip"
                                              placement="left"
                                              style={{ fontSize: '10pt' }}
                                            >
                                              <div className="d-flex flex-column">
                                                <Button
                                                  variant="link"
                                                  className=""
                                                  style={{
                                                    width: 140,
                                                    color: '#fff',
                                                    textAlign: 'left',
                                                  }}
                                                  onClick={() => {document.body.click(), onSetVisible(item); }}
                                                >
                                                  <Icon
                                                    name="eye"
                                                    className="fa-ellipsis mr-2"
                                                    style={{ color: '#fff' }}
                                                  />
                                                  {item.visible ? 'Hide Image' : 'Show Image'}
                                                </Button>

                                                <Button
                                                  variant="link"
                                                  className=""
                                                  style={{
                                                    width: 140,
                                                    color: '#fff',
                                                    textAlign: 'left',
                                                  }}
                                                  onClick={() => { return onDelete(item); }}
                                                >
                                                  <Icon
                                                    name="trash"
                                                    className="fa-ellipsis mr-2"
                                                    style={{ color: '#fff' }}
                                                  />
                                                  Delete
                                                </Button>
                                              </div>
                                            </Tooltip>)}
                                        >
                                          <Button
                                            variant="link"
                                            style={{
                                              position: 'absolute',
                                              right: 0,
                                            }}
                                          >
                                            <Icon
                                              name="circle-ellipsis"
                                              className="fa-ellipsis"
                                              style={{
                                                color: item.visible ? '#4a9125' : '#d73c28',
                                                transition: 'color 0.5s ease',
                                              }}
                                            />
                                          </Button>
                                        </OverlayTrigger>
                                      </div>
                                    </div>
                                  );
                                }}

                              </Draggable>
                            );
                          })}
                        </div>
                      </div>
                      {providedDrop.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </div>
          </DragDropContext>
        </div>
      </Col>

      {showImageModal && designWebsite?.webSiteConfigId && (
        <CoverImageModal
          show={showImageModal}
          currentImage={selectedImage}
          webSiteConfigId={designWebsite.webSiteConfigId}
          onHide={() => { return setShowImageModal(false); }}
        />
      )}

      {showDeleteConfirmationModal && (
        <ConfirmationModal
          title={'Delete Image'}
          message={'Are you sure you want to Delete this Image?'}
          show={showDeleteConfirmationModal}
          onConfirmed={confirmDelete}
          onHide={() => { return setShowDeleteConfirmationModal(false); }}
        />
      )}
    </Row>
  );
};

export default CoverImagesPage;
