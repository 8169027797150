import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class FuturePayRate {
  clientNo = 0;

  empNo = 0;

  protectedEmpNo = '';

  rateId = 0;

  originalRateId = 0;

  effectiveDate: Date | string | null = null;

  hourlyRate = 0;

  salaryRate = 0;

  annualRate = 0;

  displayOnTrans = 0;

  location = 0;

  department = 0;

  subDepartment = 0;

  subDepartment2 = 0;

  description = '';

  notes = '';

  static readonly convProps: PropTypeLists = {
    dateProps: ['effectiveDate'],
    numberProps: ['clientNo', 'empNo', 'rateId', 'originalRateId', 'displayOnTrans',
      'location', 'department', 'subDepartment', 'subDepartment2'],
    currencyProps: ['hourlyRate', 'salaryRate', 'annualRate'],
  };

  constructor(
    clientNo: number | string,
    empNo: number | string,
    protectedEmpNo: string,
    rateId: number | string,
    props?: { [key: string]: any }) {

    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;
    this.rateId = (typeof rateId === 'string') ? parseInt(rateId) : rateId;

    props && convToClass(this, props, FuturePayRate.convProps);
  }
}