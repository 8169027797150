import { createReducer } from '@reduxjs/toolkit';
import QuickBooksConnection from 'core/models/intuit/quickBooksConnection.model';
import IntuitAuth from 'core/models/intuit/intuitAuth.model';
import ConnectionOption from 'core/models/intuit/connectionOption.model';
import QuickBooksExportResult from 'core/models/intuit/quickBooksExportResult.model';
import { 
    loadIntuitAuth, 
    storeIntuitAuth, 
    manageQuickBooksConnection, 
    storeQuickBooksConnection, 
    exportToQuickBooks, 
    storeQuickBooksExportResult,
    clearQuickBooksExportResultAndFileData,
    storeChartOfAccounts,
    handleExportToQuickBooksError,
    handleGenerateChartOfAccountsError,
    handleIntuitAuthError, 
    handleQuickBooksConnectionError
 } from 'core/store/actions/intuit/intuit.actions';

export interface IntuitState {
    loading: boolean,
    exporting: boolean,
    intuitAuth: IntuitAuth | null,
    quickBooksConnection: QuickBooksConnection | null
    quickBooksExportResult: QuickBooksExportResult | null
    chartOfAccountsData: string | null
}

export const INITIAL_STATE: IntuitState = {
    loading: false,
    exporting: false,
    intuitAuth: null,
    quickBooksConnection: null,
    quickBooksExportResult: null,
    chartOfAccountsData: null
}

export const reducer = createReducer(INITIAL_STATE, {
    [loadIntuitAuth.type]: (state) => {
        return {
            ...state,
            loading: true
        }
    },
    [storeIntuitAuth.type]: (state, action) => {
        return {
            ...state,
            loading: false,
            intuitAuth: action.payload
        }
    },
    [handleIntuitAuthError.type]: (state, action) => {
        return {
            ...state,
            loading: false,
            intuitAuth: null
        }
    },
    [manageQuickBooksConnection.type]: (state) => {
        return {
            ...state,
            loading: true
        }
    },
    [storeQuickBooksConnection.type]: (state, action) => {
        return {
            ...state,
            loading: false,
            quickBooksConnection: action.payload
        }
    },
    [handleQuickBooksConnectionError.type]: (state, action) => {
        return {
            ...state, 
            loading: false,
            quickBooksConnection: null
        }
    },
    [exportToQuickBooks.type]: (state, action) => {
        return {
            ...state,
            exporting: true
        }
    },
    [storeQuickBooksExportResult.type]: (state, action) => {
        return {
            ...state,
            exporting: false,
            quickBooksExportResult: action.payload
        }
    },
    [handleExportToQuickBooksError.type]: (state, action) => {
        return {
            ...state, 
            exporting: false,
            quickBooksExportResult: null
        }
    },
    [clearQuickBooksExportResultAndFileData.type]: (state, action) => {
        return {
            ...state, 
            quickBooksExportResult: null,
            chartOfAccountsData: null
        }
    },
    [storeChartOfAccounts.type]: (state, action) => {
        return {
            ...state, 
            chartOfAccountsData: action.payload
        }
    },
    [handleGenerateChartOfAccountsError.type]: (state, action) => {
        return {
            ...state,
            chartOfAccountsData: null
        }
    }
});