import { AgGridReact } from '@ag-grid-community/react';
import { PayrollEmployeeDeductionShortage } from 'core/models';
import React, { Dispatch, SetStateAction, useRef } from 'react';
import { BaseProps } from '../TransmittalError';
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  SelectionChangedEvent,
  ValueGetterParams,
} from '@ag-grid-enterprise/all-modules';
import Modal from 'core/components/modals/Modal';

interface Props {
  rowData: PayrollEmployeeDeductionShortage[];
  update: Dispatch<
  SetStateAction<
  | { data: PayrollEmployeeDeductionShortage[]; index: number }
  | undefined
  >
  >;
  id: number;
  index: number;
  submit: (
    index: number,
    process: boolean,
    copy: PayrollEmployeeDeductionShortage[]
  ) => void;
}

const ShortageGrid = ({
  show,
  onHide,
  rowData,
  update,
  id,
  index,
  submit,
}: BaseProps & Props) => {
  const paidEmployeeRows = rowData?.filter((item) => { return item.gettingPaid; });
  const updatedShortages = useRef<PayrollEmployeeDeductionShortage[]>(
    rowData?.map((row) => { return { ...row }; }),
  );

  const columns: ColDef[] = [
    {
      field: 'empNo',
      headerName: 'Emp No',
      width: 100,
    },
    {
      field: 'employeeName',
      headerName: 'Employee Name',
      cellStyle: { 'white-space': 'normal' },
    },
    {
      field: 'amount',
      headerName: 'Amount',
    },
    {
      field: 'dedNo',
      headerName: 'Ded No',
    },
    {
      field: 'process',
      headerName: 'Process',
      headerCheckboxSelection: true,
      checkboxSelection: (params: any) => {
        return !!params && params.data.gettingPaid;
      },
      width: 75,
      valueGetter: (params: ValueGetterParams) => {
        return '';
      },
    },
  ];

  const gridOptions: GridOptions = {
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    columnDefs: columns,
    domLayout: 'autoHeight',
    defaultColDef: {
      autoHeight: true,
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    masterDetail: true,
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.forEachNode((node) => {
      const {
        data: { gettingPaid, process },
      } = node;

      if (gettingPaid && process) {
        node.setSelected(true);
      }
    });
  };

  const onGridSizeChanged = (params: any) => {
    params.api.sizeColumnsToFit();
  };

  const onSelectionChanged = (params: SelectionChangedEvent) => {
    const copy = paidEmployeeRows?.map((row) => { return { ...row }; });

    params.api.forEachNode((node, index) => {
      if (node.isSelected()) {
        copy[index].process = true;
      } else {
        copy[index].process = false;
      }
    });

    updatedShortages.current = copy;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Paid Employees To Process"
    >
      {(closeModal) => {
        return (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          >
            <div className="table-wrapper-wrapper ag-theme-balham mb-2">
              <AgGridReact
                onGridReady={onGridReady}
                masterDetail={true}
                gridOptions={gridOptions}
                rowData={paidEmployeeRows}
                onGridSizeChanged={onGridSizeChanged}
                onSelectionChanged={onSelectionChanged}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <button
                className="btn btn-primary orange-button mt-2 mb-2"
                onClick={() => {
                  if (
                    updatedShortages.current &&
                    index > -1
                  ) {
                    update({
                      data: updatedShortages.current,
                      index,
                    });
                    submit(
                      index,
                      !updatedShortages.current[index],
                      updatedShortages.current,
                    );
                  }
                  closeModal();
                }}
                disabled={!rowData || rowData.length <= 0}
              >
                Save
              </button>
            </div>
          </div>
        );
      }}
    </Modal>
  );
};

export default ShortageGrid;
