import { AgGridReact } from '@ag-grid-community/react';
import {
  ColDef,
  GetDetailRowDataParams,
  GridOptions,
  ICellRendererParams,
  RowClickedEvent,
  MasterDetailModule,
} from '@ag-grid-enterprise/all-modules';
import React, {
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { ValidateDetails, Payroll, ModalsOnly } from 'core/models';
import TransmittalError from '../TransmittalError';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { loadPayrollTimeOffRequests, setPreviewTab } from 'core/store/actions';
import { useSelector } from 'react-redux';
import { getControlTotalsByOption } from 'core/store/selectors';
import OutstandingTimeOffModal from '../PendingAccrualRequests.modal';

type PropTypes = {
  selectedPayroll: Payroll;
  data: ValidateDetails[];
  update?: React.Dispatch<
  React.SetStateAction<ValidateDetails[] | undefined>
  >;
  errorType: boolean;
  changeViewType: Dispatch<SetStateAction<string | undefined>>;
};
const PayrollErrorsWarnings: React.FC<PropTypes> = ({
  selectedPayroll,
  data,
  update,
  errorType,
  changeViewType,
}) => {
  const [showActionModal, setShowActionModal] = useState<boolean>(false);
  const [showTorModal, setShowTorModal] = useState<boolean>(false);
  const [currentAction, setCurrentAction] = useState<any>();
  const [rowData, setRowData] = useState<ValidateDetails[]>([]);
  const controlTotalBy = useSelector(getControlTotalsByOption);
  const hasMultipleControlTotal = (controlTotalBy === 'Location' || controlTotalBy === 'Department') ? true : false;

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector((state) => {return state.payroll.loading;});
  //Before we were just setting the payrollHistoryId, leading to the route just directing to the first payroll instead of the last selected payroll. -PI-8226
  //This only ever got updated when the Open Transmittal button was set. Now we just use the selectedPayroll passed in and the controlTotalId passed form the validation endpoint.
  //const selectedPayrollIds = useAppSelector((state) => {return state.payroll.currentControlTotalIds;});
  const currentPayrollLink = (controlTotalId: number) => `/open-current-transmittal?payrollHistoryId=${selectedPayroll?.payrollHistoryId}&controlTotalId=${controlTotalId}`;

  useEffect(() => {
    if (data) {
      setRowData(data);
    }
  }, [data]);

  const type = errorType ? 'Error' : 'Warning';

  //#region AgGrid
  // TODO: Rewrite column defs to be dynamic and/or build custom cell renderer
  const columns: ColDef[] = useMemo(
    () => {
      return [
        {
          field: 'name',
          headerName: type + ' Type',
          cellRenderer: 'agGroupCellRenderer',
          sortable: true,
          width: 200,
          autoHeight: true,
          cellStyle: { 'white-space': 'normal' },
        },
        {
          field: 'description',
          headerName: 'Short Description',
          sortable: true,
          width: 400,
          autoHeight: true,
          cellStyle: { 'white-space': 'normal' },
        },
        {
          field: '',
          headerName: '',
          width: 150,
          cellStyle: { 'white-space': 'normal' },
          autoHeight: true,
          cellRendererFramework: (params: ICellRendererParams) => {
            const { data } = params;
            const action = data.name.replace(/\s+/g, '');
                  
            /* TODO: Consolidate into custom cell renderer component to handle these conditions.
                     This "works", but is becoming a ridiculous way to handle this. */
            if (errorType) {
              if (data?.name?.includes('Accrual Requests')) {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => {
                        setShowTorModal(true);
                      }}
                    >
                      Details
                    </button>
                  </div>
                );
              } else if (action === 'CrossFootError' || action === 'WireOnly') {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => {return changeViewType('Preview');}
                      }
                    >
                      Preview Payroll
                    </button>
                  </div>
                );
                {/*If they use only one control total then we can just use the control total id off the first control total. And show it at the top*/}
              } else {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    {[
                      'HighHours',
                      'HighGross',
                      'NegativeAccrualBalances',
                      'NoRateOnFile',
                    ].includes(action) ? 
                      (
                        ((!hasMultipleControlTotal) ?
                          <Link
                            to={currentPayrollLink(selectedPayroll?.controlTotals[0]?.controlTotalId || 0)}
                            className="btn btn-link"
                          >
                            Open Transmittal
                          </Link> 
                          : <div style={{ textAlign: 'center' }}>See details for more information on error</div>)
                      ) : null}
                  </div>
                );
              }
            } else {
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                  }}
                >
                  {Object.values(ModalsOnly).includes(action) ? (
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => {
                        setShowActionModal(true);
                      }}
                    >
                      Review
                    </button>
                  ) : null}
                  {/*PI-8778 it was updated to show Cross Foot Error as a warning message when it was previewed and they can accept it so they can actually preview.*/}
                  {[
                    'BankAccountChanges',
                    'PayRateChanges',
                  ].includes(action) ? 
                    (
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => {
                          dispatch(setPreviewTab(action.split(/(?=[A-Z])/).join(' ')));
                          return changeViewType('Preview');
                        }}
                      >
                        Preview changes
                      </button>
                    ) : null
                  }
                  {
                    ![
                      'FutureRatesUpdates',
                      'WireOnlySubmission',
                    ].includes(action) ? 
                      (
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => {
                            if (update) {
                              update((current) => {
                                return current?.filter(
                                  (item) => {
                                    return item.description !==
                                      data.description;
                                  },
                                );
                              });
                            }
                          }}
                        >
                          Accept
                        </button>
                      ) : null}
                </div>
              );
            }
          },
        },
      ];
    },
    [],
  );

  const employeeDetailColumnDef: ColDef[] = [
    {
      field: 'empNo',
      headerName: 'Emp No',
      width: 100,
    },
    {
      field: 'employeeName',
      headerName: 'Emp Name',
      cellRendererFramework: (
        params: ICellRendererParams,
      ) => {
        const {
          data: { employeeName, protectedEmpNo } = {
            employeeName: '',
            protectedEmpNo: '',
          },
        } = params;

        return (
          <Link
            to={`/employee/detail/${protectedEmpNo}/snapshot`}
          >
            {employeeName}
          </Link>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' },
    },
  ];

  const empOpenTransmittalDetail: ColDef = {
    field: '',
    headerName: '',
    width: 50,
    autoHeight: true,
    cellRendererFramework: (
      params: ICellRendererParams,
    ) => {
      const {
        data: { controlTotalId } = {
          controlTotalId: 0,
        },
      } = params;

      return (
        <div style={{ textAlign: 'center' }}>
          <Link
            to={currentPayrollLink(controlTotalId)}
          >
            Open Transmittal
          </Link>
        </div>
      );
    },
  };

  const gridOptions: GridOptions = {
    columnDefs: columns,
    domLayout: 'autoHeight',
    rowHeight: 50,
    defaultColDef: {
      flex: 1,
      suppressMenu: true,
      autoHeight: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header red',
      resizable: true,
    },
    masterDetail: true,
    detailRowAutoHeight: true,
    detailCellRendererParams: {
      detailGridOptions: {
        domLayout: 'autoHeight',
        rowHeight: 50,
        defaultColDef: {
          flex: 1,
          suppressMenu: true,
          resizable: true,
          autoHeight: true,
          cellClass: 'ag-cell-left-border',
        },
        columnDefs: (!hasMultipleControlTotal) ? employeeDetailColumnDef : [...employeeDetailColumnDef, empOpenTransmittalDetail],
      },
      getDetailRowData: (params: GetDetailRowDataParams) => {
        params.successCallback(params.data.detailItems || []);
      },
    },
  };

  //#endregion
  const onRowClick = (rowData: RowClickedEvent) => {
    const { data } = rowData;
    setCurrentAction(data.name.replace(/\s+/g, ''));
  };

  const updateData = (rowData: ValidateDetails[] | null) => {
    if (rowData && update) update(rowData);
    setShowActionModal(false);
  };

  return (
    <div onClick={(e) => {return e.stopPropagation();}}>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner
            className="ml-2"
            style={{ width: '2rem',
              height: '2rem' }}
            animation="border"
            variant="primary"
          ></Spinner>
          <br />
        </div>
      ) : (
        <div className="dm-border-panel">
          <div className="d-flex">
            <div className="dm-card-subtitle2 pt-2 mb-2">
              {`${type}s`}
            </div>
          </div>
          <Row>
            <Col
              xs={12}
              className="table-wrapper-wrapper ag-theme-balham"
            >
              <AgGridReact
                gridOptions={gridOptions}
                masterDetail={true}
                modules={[MasterDetailModule]}
                rowData={rowData}
                onRowClicked={onRowClick}
              ></AgGridReact>
            </Col>
          </Row>
        </div>
      )}
      {showActionModal && rowData ? (
        <TransmittalError
          show={showActionModal}
          onHide={() => {return setShowActionModal(false);}}
          onUpdateItems={updateData}
          type={currentAction}
          data={rowData}
          id={selectedPayroll.payrollHistoryId}
          controlTotalId={selectedPayroll?.controlTotals[0]?.controlTotalId || 0}
        />
      ) : null}
      {showTorModal && (
        <OutstandingTimeOffModal
          show={showTorModal}
          onHide={() => { setShowTorModal(false); }}
          id={selectedPayroll.payrollHistoryId}
          controlTotalId={selectedPayroll?.controlTotals[0]?.controlTotalId || 0}
          onUpdateItems={updateData}
        />
      )}
    </div>
  );
};
export default PayrollErrorsWarnings;
