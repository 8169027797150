import { createReducer } from '@reduxjs/toolkit';
import { PensionSetting, UnionDuesSettingsRequest } from '../../models/PayrollReports.model';
import {
  clearPayroll401kReport,
  clearPayroll4070TipReport,
  clearPensionReport,
  storePayroll401kReport,
  storePayroll4070TipReport,
  storePensionReport,
  storePensionSettings,
  clearAnnualFICATipCreditReport,
  storeAnnualFICATipCreditReport,
  clearCashFlowReport,
  storeCashFlowReport,
  storeScheduledDeductionsReport,
  clearScheduledDeductionsReport,
  clearWorkersCompensationReport,
  storeWorkersCompensationReport,
  clearDeductionOptionsReport,
  storeDeductionOptionsReport,
  clearTaxReport,
  storeTaxReport,
  storeMemoCashFlowReport,
  clearMemoCashFlowReport,
  storeEmployeeLastCheckReport,
  clearEmployeeLastCheckReport,
  storeGrossToNetReport,
  clearGrossToNetReport,
  loadGrossToNetReport,
  storeUnionDuesSettings,
  storeUnionDuesReport,
  clearUnionDuesReport,
  downloadDeductionShortagesReport,
  storeDeductionShortagesReport,
  clearDeductionShortagesReport,
  downloadMontlyCashFlowAnalysisDownloadReport,
  storeMontlyCashFlowAnalysisDownloadReport,
  clearMontlyCashFlowAnalysisDownloadReport,
  downloadCARESCreditReport,
  storeCARESCreditReport,
  clearCARESCreditReport,
  downloadUnionDuesReport,
} from '../actions/payoll-reports.action';

export interface State {
  loading: boolean;
  payroll401kReport: string;
  payroll4070TipReport: string;
  cashFlowReport: string;
  annualFICTipCreditReport: string;
  pensionReport: string;
  pensionSettings: PensionSetting[];
  caresCreditReport: string;
  scheduledDeductionsReport: string;
  workersCompensationReport: string;
  deductionOptions: string | undefined;
  taxReport: string;
  memoCashFlowReport: string;
  employeeLastCheckReport: string;
  grossToNetReport: string;
  unionDuesReport: string;
  runningUnionDues: 'pending' | 'success' | 'error';
  unionDueSettings: UnionDuesSettingsRequest[];
  deductionShortagesReport: string;
  monthlyCashFlowAnalysisDownloadReport: string;
}

const INITIAL_STATE: State = {
  loading: false,
  payroll401kReport: '',
  payroll4070TipReport: '',
  cashFlowReport: '',
  annualFICTipCreditReport: '',
  pensionReport: '',
  pensionSettings: [],
  runningUnionDues: 'success',
  scheduledDeductionsReport: '',
  workersCompensationReport: '',
  deductionOptions: undefined,
  taxReport: '',
  memoCashFlowReport: '',
  employeeLastCheckReport: '',
  grossToNetReport: '',
  unionDuesReport: '',
  unionDueSettings: [],
  deductionShortagesReport: '',
  monthlyCashFlowAnalysisDownloadReport: '',
  caresCreditReport: '',
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(storePayroll401kReport, (state, action) => {
      state.loading = false;
      state.payroll401kReport = action.payload;
    })
    .addCase(clearPayroll401kReport, (state) => {
      state.payroll401kReport = '';
    })
    .addCase(storePayroll4070TipReport, (state, action) => {
      state.loading = false;
      state.payroll4070TipReport = action.payload;
    })
    .addCase(clearPayroll4070TipReport, (state) => {
      state.payroll4070TipReport = '';
    })
    .addCase(storeCashFlowReport, (state, action) => {
      state.loading = false;
      state.cashFlowReport = action.payload;
    })
    .addCase(clearCashFlowReport, (state) => {
      state.cashFlowReport = '';
    })
    .addCase(storeAnnualFICATipCreditReport, (state, action) => {
      state.loading = false;
      state.annualFICTipCreditReport = action.payload;
    })
    .addCase(clearAnnualFICATipCreditReport, (state) => {
      state.annualFICTipCreditReport = '';
    })

    .addCase(storeScheduledDeductionsReport, (state, action) => {
      state.loading = false;
      state.scheduledDeductionsReport = action.payload;
    })
    .addCase(clearScheduledDeductionsReport, (state) => {
      state.scheduledDeductionsReport = '';
    })

    .addCase(storeWorkersCompensationReport, (state, action) => {
      state.loading = false;
      state.workersCompensationReport = action.payload;
    })
    .addCase(clearWorkersCompensationReport, (state) => {
      state.workersCompensationReport = '';
    })
    .addCase(storePensionReport, (state, action) => {
      state.pensionReport = action.payload;
    })
    .addCase(clearPensionReport, (state) => {
      state.pensionReport = '';
    })
    .addCase(storePensionSettings, (state, action) => {
      state.pensionSettings = action.payload;
    })
    .addCase(storeDeductionOptionsReport, (state, action) => {
      state.deductionOptions = action.payload;
    })
    .addCase(clearDeductionOptionsReport, (state) => {
      state.deductionOptions = undefined;
    })
    .addCase(storeTaxReport, (state, action) => {
      state.taxReport = action.payload;
    })
    .addCase(clearTaxReport, (state) => {
      state.taxReport = '';
    })
    .addCase(storeMemoCashFlowReport, (state, action) => {
      state.memoCashFlowReport = action.payload;
    })
    .addCase(clearMemoCashFlowReport, (state) => {
      state.memoCashFlowReport = '';
    })
    .addCase(storeEmployeeLastCheckReport, (state, action) => {
      state.employeeLastCheckReport = action.payload;
    })
    .addCase(clearEmployeeLastCheckReport, (state) => {
      state.employeeLastCheckReport = '';
    })
    .addCase(loadGrossToNetReport, (state) => {
      state.loading = true;
    })
    .addCase(storeGrossToNetReport, (state, action) => {
      state.loading = false;
      state.grossToNetReport = action.payload;
    })
    .addCase(clearGrossToNetReport, (state) => {
      state.grossToNetReport = '';
    })
    .addCase(downloadUnionDuesReport, (state, action) => {
      state.runningUnionDues = 'pending';
    })
    .addCase(storeUnionDuesReport, (state, action) => {
      state.unionDuesReport = action.payload;
      state.runningUnionDues = 'success';
    })
    .addCase(clearUnionDuesReport, (state) => {
      state.unionDuesReport = '';
    })
    .addCase(storeUnionDuesSettings, (state, action) => {
      state.unionDueSettings = action.payload;
    })
    .addCase(downloadDeductionShortagesReport, (state) => {
      state.loading = true;
    })
    .addCase(storeDeductionShortagesReport, (state, action) => {
      state.loading = false;
      state.deductionShortagesReport = action.payload;
    })
    .addCase(clearDeductionShortagesReport, (state) => {
      state.deductionShortagesReport = '';
    })
    .addCase(downloadMontlyCashFlowAnalysisDownloadReport, (state) => {
      state.loading = true;
    })
    .addCase(storeMontlyCashFlowAnalysisDownloadReport, (state, action) => {
      state.loading = false;
      state.monthlyCashFlowAnalysisDownloadReport = action.payload;
    })
    .addCase(clearMontlyCashFlowAnalysisDownloadReport, (state) => {
      state.monthlyCashFlowAnalysisDownloadReport = '';
    })
    .addCase(downloadCARESCreditReport, (state) => {
      state.loading = true;
    })
    .addCase(storeCARESCreditReport, (state, action) => {
      state.loading = false;
      state.caresCreditReport = action.payload;
    })
    .addCase(clearCARESCreditReport, (state) => {
      state.caresCreditReport = '';
    });
});