import React, { useEffect, useState } from 'react';
import { Labels } from '.';
import { CheckCode } from '../../models/Checkcode.model';
import { Control, Controller, FieldValues } from 'react-hook-form';
import Select, { GroupBase, MenuPlacement, StylesConfig } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import styles from './form-controls.module.scss';
import Icon from '../shared/Icon';
import { FormMethods } from './types';

type StylesConfigType = StylesConfig<any, boolean, GroupBase<unknown>> | undefined;
type CompsType = Partial<SelectComponents<any, boolean, GroupBase<unknown>>> | undefined;

const compStyles: StylesConfigType = {
  control: (base) => {
    return {
      ...base,
      minHeight: '22px',
      height: '22px',
      minWidth: '140px',
      width: '100%',
      borderRadius: '0.25rem',
      fontSize: '12px',
      color: 'black',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#0074D9',
      },
    };
  },
  valueContainer: (base) => {
    return {
      ...base,
      padding: '0',
      paddingLeft: '2px',
      top: '0',
      position: 'relative',
      overflow: 'hidden',
      width: 'fit-content',
      color: 'black',
      boxSizing: 'border-box',
    };
  },
  indicatorSeparator: (base) => {
    return {
      ...base,
      display: 'none',
    };
  },
  dropdownIndicator: (base) => {
    return {
      ...base,
      padding: '0px',
      paddingLeft: '5px',
      color: '#3a3a3a',
      '&:hover': {
        color: 'black',
      },
    };
  },
  menuPortal: (base) => {
    return {
      ...base,
      zIndex: 9999,
      width: 'fit-content',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 10px 0px',
      background: 'rgb(255, 255, 255)',
      cursor: 'pointer',
    };
  },
};

type PropTypes<T extends FieldValues = any> = {
  id?: string;
  name: string;
  value?: string | null;
  control: Control<T>;
  label?: string | string[];
  groupClass?: string;
  groupStyle?: object;
  options: CheckCode[];
  required?: boolean;
  menuPlacement?: MenuPlacement;
  errors: FormMethods['errors'] | any;
  setValue: FormMethods['setValue'] | any;
  onChange?: (newVal?: CheckCode) => void;
  readOnly?: boolean;
  portalTargetId?: string;
  setSelectedCostCode?: React.Dispatch<React.SetStateAction<string>> | undefined;
};

export const CheckCodeSelect: React.FC<PropTypes> = ({
  id,
  name,
  value,
  control,
  label,
  groupClass,
  options,
  required = false,
  menuPlacement = 'auto',
  errors,
  setValue,
  onChange,
  readOnly = false,
  portalTargetId = 'check-code-wrapper',
  setSelectedCostCode = undefined,
}) => {
  const groupClass2 = styles['dm-form-group'] + ' ' + (groupClass ?? '');
  id = id ?? name;

  const [renderedVal, setRenderedVal] = useState<{ label: string, value: string } | null>(null);

  useEffect(() => {
    if (!value) return;

    setRenderedVal({
      label: value,
      value: value,
    });
    setValue(name, value, {
      shouldDirty: false,
    });
  }, [value]);

  const handleChange = (newVal: CheckCode) => {
    const { checkCode } = newVal;

    setRenderedVal({
      label: checkCode,
      value: checkCode,
    });
    setValue(name, checkCode, {
      shouldDirty: true,
    });
    onChange?.(newVal);
    if (setSelectedCostCode) setSelectedCostCode(checkCode);
  };

  const CustomMenu = () => {
    const handleOptClick = (opt: CheckCode) => {
      handleChange(opt);
    };

    return (
      <table className="table table-striped table-hover table-sm">
        <thead>
          <tr>
            <th>Check Code</th>
            <th>EFT</th>
            <th>Voluntary</th>
            <th>401K</th>
            <th>Garnishment</th>
          </tr>
        </thead>
        <tbody>
          {options.map((opt: CheckCode) => {
            return (
              <tr
                key={opt.checkCode}
                onMouseDown={() => { return handleOptClick(opt); }}
              >
                <td>{opt.checkCode}</td>
                <td>
                  <Icon
                    name={opt.eft ? 'check' : 'times'}
                    color={opt.eft ? 'green' : 'red'}
                    fontSize={12}
                    style={{ padding: 0 }}
                  />
                </td>
                <td>
                  <Icon
                    name={opt.voluntary ? 'check' : 'times'}
                    color={opt.voluntary ? 'green' : 'red'}
                    fontSize={12}
                    style={{ padding: 0 }}
                  />
                </td>
                <td>
                  <Icon
                    name={opt.four01K ? 'check' : 'times'}
                    color={opt.four01K ? 'green' : 'red'}
                    fontSize={12}
                    style={{ padding: 0 }}
                  />
                </td>
                <td>
                  <Icon
                    name={opt.garnishment ? 'check' : 'times'}
                    color={opt.garnishment ? 'green' : 'red'}
                    fontSize={12}
                    style={{ padding: 0 }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const customComponents: CompsType = {
    Menu: CustomMenu,
  };

  return (
    <Controller
      control={control}
      name={name}
      render={() => {
        return (
          <div
            className={groupClass2}
            id={portalTargetId}
          >
            <Labels
              label={label}
              id={id}
              hasError={!!errors}
              required={required}
            />
            {/* 
				// @ts-ignore */}
            <Select
              styles={compStyles as StylesConfigType}
              components={customComponents}
              value={renderedVal}
              options={options}
              getOptionLabel={
                (optionData) => {
                  return renderedVal?.label ?? `${optionData?.checkCode}`;
                }
              }
              getOptionValue={
                (optionData) => {
                  return renderedVal?.value ?? `${optionData?.checkCode}`;
                }
              }
              onChange={handleChange}
              isDisabled={readOnly}
              isSearchable={false}
              isClearable={false}
              menuPlacement={menuPlacement}
              menuPortalTarget={document.getElementById(portalTargetId)}
              closeMenuOnSelect={false}
            />
          </div>
        );
      }}
    />
  );
};
