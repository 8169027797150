import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { EmpI9, HttpResponse } from '../../models';
import { EmployeeI9Service } from '../../services';
import {
  handleError,
  handleSuccess,
  loadEmployeeI9,
  loadEmployeeI9DataReport,
  loadEmployeesI9,
  storeEmployeeI9,
  storeEmployeeI9DataReport,
  storeEmployeesI9,
  updateEmployeeI9,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadEmployeesI9$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmployeesI9.type),
    switchMap(() => {
      return from(EmployeeI9Service.getEmployeesI9()).pipe(
        map((res: any) => { return res.data; }),
        map((res: EmpI9[]) => { return storeEmployeesI9(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadEmployeeI9$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmployeeI9.type),
    switchMap((action: { payload: { protectedEmpNo: string; }; }) => {
      return from(
        EmployeeI9Service.getEmployeeI9(action.payload.protectedEmpNo),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: EmpI9) => { return storeEmployeeI9(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadEmployeeI9DataReport$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmployeeI9DataReport.type),
    switchMap(() => {
      return from(EmployeeI9Service.postEmployeeI9DataReport()).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return storeEmployeeI9DataReport(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEmployeeI9$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateEmployeeI9.type),
    switchMap((action: { payload: EmpI9; }) => {
      return from(EmployeeI9Service.putEmployeeI9(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: EmpI9) => {
          return [
            handleSuccess(
              'I9 data has been successfully saved and a PDF copy has been generated and placed into your library.',
            ),
            loadEmployeesI9(),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [handleError(err)];
        }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadEmployeesI9$,
  loadEmployeeI9$,
  loadEmployeeI9DataReport$,
  updateEmployeeI9$,
];
