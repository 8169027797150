import { createSelector } from 'reselect';
import { convToFormObject } from '../../../utilities/classUtils';
import { AccrualBalanceRecord, AccrualRecord, AccrualRule, AccrualTimeOffSummary, TimeOffRequest, TimeOffRequestDetail } from '../../models';
import { accrualState } from '../reducers';
import { State as AccrualState } from '../reducers/accrual.reducer';

export const getAccruals = createSelector(
  accrualState,
  (state: AccrualState) => {
    if (!state.accruals) return [];

    const filteredAccruals = state.accruals; //.filter(a => a.earningsCode === 'V');

    return filteredAccruals.map((accrual: AccrualBalanceRecord) => {
      const x = convToFormObject(accrual, AccrualBalanceRecord.convProps) as AccrualBalanceRecord;
      x.accrualRecords = x.accrualRecords.map((ar: AccrualRecord) => {return convToFormObject(ar, AccrualRecord.convProps);}) as AccrualRecord[];
      return x;
    });
  });

export const getAccrualRule = createSelector(
  accrualState,
  (state: AccrualState) => {return state.accrualRule ? convToFormObject(state.accrualRule, AccrualRule.convProps) as AccrualRule : null;},
);

export const getAccrualTimeOffSummary = createSelector(
  accrualState,
  (state: AccrualState) => {
    if (!state.accrualTimeOffSummary) return [];

    return state.accrualTimeOffSummary.map((tor: AccrualTimeOffSummary) => {return convToFormObject(tor, AccrualTimeOffSummary.convProps);}) as AccrualTimeOffSummary[];
  });

export const getTimeOffRequests = createSelector(
  accrualState,
  (state: AccrualState) => {
    if (!state.timeOffRequest) return [];

    return state.timeOffRequest.map((tor: TimeOffRequest) => {
      const details = tor.details.map((d: TimeOffRequestDetail) => {return convToFormObject(d, TimeOffRequestDetail.convProps);}) as TimeOffRequestDetail[];
      return convToFormObject({ ...tor, details }, TimeOffRequest.convProps);
    }) as TimeOffRequest[];
  });

export const getAccrualEarningsCodes = createSelector(
  accrualState,
  (state: AccrualState) => {
    return state.accrualEarningsCode;
  });