import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CheckboxGrpInLine, InputGrpInLine } from '..';
import { Dropdown, DropdownRequest } from 'core/models';
import { createDropdown, updateDropdown } from 'core/store/actions';
import { GenericFieldInputSettings } from '../types';
import { useAppDispatch } from 'utilities/hooks';

const fs: GenericFieldInputSettings<Partial<Dropdown>> = {
  code: {
    name: 'code',
    label: 'Code: ',
    labelWidth: 25,
  },
  description: {
    name: 'description',
    label: 'Description',
    labelWidth: 25,
    required: true,
  },
  active: {
    name: 'active',
    label: 'Active',
  },
};

type PropTypes = {
  item: Dropdown;
  dropdownName: string;
  onHide: any;
};

const DropdownOptionForm: React.FC<PropTypes> = ({
  item,
  dropdownName,
  onHide,
}) => {
  const dispatch = useAppDispatch();
  
  const {
    register,
    errors,
    reset,
    getValues,
  } = useForm<Dropdown>({
    defaultValues: item,
  });

  useEffect(() => {
    reset({ ...item });
  }, [item, dropdownName]);

  const onSubmit = () => {
    const data: Partial<Dropdown> = getValues();
    
    const updatedItem = new DropdownRequest(
      item.clientNo ?? 0,
      String(item.id) === '' ? '0' : String(item.id),
      data?.description ?? '',
      data?.code,
      item.dropdownName ?? dropdownName,
      data?.active,
    );
    
    if (item?.id && String(item.id).trim() !== '') {
      dispatch(updateDropdown(updatedItem));
    } else {
      dispatch(createDropdown(updatedItem));
    }

    onHide(true);

    return false;
  };

  return (
    <div
      className="dm-panel dm-panel-border d-flex flex-column flex-nowrap"
      onClick={(e) => { e.stopPropagation(); }}
    >
      <div className="dm-grid-action-title">Add/Edit</div>
      <form>
        {item.usesCode && (
          <InputGrpInLine
            {...fs.code}
            errors={errors.code}
            ref={register(item.usesCode ? { required: 'Code is Required' } : {})}
          />
        )}
        <InputGrpInLine
          {...fs.description}
          errors={errors.description}
          ref={register({ required: 'Description is Required' })}
        />
        {item?.usesActive && (
          <CheckboxGrpInLine
            {...fs.active}
            ref={register()}
            labelFirst
          />
        )}
        <div style={{ display: 'flex' }}>
          <button
            type="button"
            className="btn btn-primary orange-outline-button ml-auto mr-2"
            onClick={() => {return onHide(false);}}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onSubmit}
            className="orange-button mr-2"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default DropdownOptionForm;
