import React from 'react';
import { YTDDeduction } from '../../../core/models/year-to-date.model';
import { currencyFormatter } from '../../../utilities/utilities';

type PropTypes = {
  items: YTDDeduction[] | undefined;
};

const YTDDeductions: React.FC<PropTypes> = ({ items }) => {

  return (<>
    <div className="mr-3" style={{ minWidth: '300px' }}>
      <h3>Employee Deductions</h3>
      <table className="table table-sm w-100" >
        <colgroup>
          <col style={{ width: '15%' }} />
          <col style={{ width: '35%' }} />
          <col style={{ width: '18%' }} />
          <col style={{ width: '32%' }} />
        </colgroup>
        <thead className="thead-light">
          <tr>
            <th>Code</th>
            <th>Code Desc</th>
            <th>Amount</th>
            <th>Case Desc</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item: YTDDeduction, index: number) => {
            return (
              <tr key={index}>
                <td>{item.dedNo}</td>
                <td>{item.shortDescription}</td>
                <td className="text-right">{currencyFormatter(item.dedAmount)}</td>
                <td className="text-right">{item.caseId}</td>
              </tr>
            );
          })}

        </tbody>
      </table>
    </div>
  </>);
};

export default YTDDeductions;
