import React, { CSSProperties, useContext, FocusEvent } from 'react';
import Icon from 'core/components/shared/Icon';
import './time-card-styles.scss';
import { InputGrp, SelectGrp } from 'core/components/form-controls';
import { ArrayField } from 'react-hook-form';
import { DetailOtherEarning } from 'core/models';
import { useAppSelector } from 'utilities/hooks';
import { currencyFormatter } from 'utilities/utilities';
import { TimeCardContext } from './TimeCardContext';

type Props = {
  earning: Partial<ArrayField<DetailOtherEarning, 'id'>>;
  index: number;
  nestedIndex: number;
  removeEarnings: (id: number) => void;
  showLabel?: boolean;
  canEdit?: boolean;
};

const TimeCardEarningItem = ({ earning, index, nestedIndex, removeEarnings, showLabel, canEdit = true }: Props) => {
  const infoGrpStyle: CSSProperties = {
    marginBottom: '0',
    maxWidth: canEdit ? '150px' : 'unset',
    marginLeft: '24px',
  };
  
  const { earningsCode } = useAppSelector(({ dropdown }) => dropdown);
  
  const { formMethods, updateDirtyState } = useContext(TimeCardContext);
  const { register } = formMethods;
  
  const formatAmount = (e: FocusEvent<HTMLInputElement, Element>) => {
    e.target.value = currencyFormatter(e.target.value);
    updateDirtyState(true);
  };
  
  return (
    <div className="earning-item">
      <input
        name={`details[${index}].otherEarnings[${nestedIndex}].transmittalTimeCardDetailId`}
        type="hidden"
        ref={register({ valueAsNumber: true })}
        defaultValue={earning?.transmittalTimeCardDetailId ?? 0}
      />
      <input
        name={`details[${index}].otherEarnings[${nestedIndex}].transmittalTimeCardDetailOtherEarningsId`}
        type="hidden"
        ref={register({ valueAsNumber: true })}
        defaultValue={earning?.transmittalTimeCardDetailOtherEarningsId ?? 0}
      />
      <input
        name={`details[${index}].otherEarnings[${nestedIndex}].recurringEarningId`}
        type="hidden"
        ref={register()}
        defaultValue={earning?.recurringEarningId ?? undefined}
      />
      <div style={{ width: canEdit ? '25%' : '33%' }}>
        <SelectGrp
          name={`details[${index}].otherEarnings[${nestedIndex}].earningsCode`}
          options={earningsCode}
          ref={register()}
          defaultValue={earning?.earningsCode}
          selectStyles={{ height: '26px' }}
          groupStyle={{ marginBottom: '0' }}
          onChange={() => { updateDirtyState(true); }}
          showId
        />
      </div>
      <div className={canEdit ? 'earning-code-col' : ''}>
        <div className="d-flex align-items-center">
          <InputGrp
            name={`details[${index}].otherEarnings[${nestedIndex}].amount`}
            ref={register()}
            defaultValue={currencyFormatter(earning?.amount ?? 0)}
            label={ showLabel ? 'Amount' : undefined}
            groupStyle={infoGrpStyle}
            inputStyle={{ height: '26px', textAlign: 'right' }}
            onBlur={formatAmount}
          />
        </div>
      </div>
      {canEdit && (
        <div
          className="d-flex flex-column"
          style={{ width: 'auto', flex: '1' }}
        >
          <div className="d-flex justify-content-end">
            <button
              className="section-action btn btn-link dm-grid-action-title p-0 m-0"
              onClick={() => { removeEarnings(nestedIndex); }}
            >
              Delete&nbsp;<Icon
                name="minus-circle"
                className="fa-minus-circle"
                title="delete earning"
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeCardEarningItem;