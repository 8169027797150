import React, { useEffect, useState } from 'react';
import Icon from 'core/components/shared/Icon';
import PanelHeader from 'core/components/shared/PanelHeader';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { WebsiteCustomWaiver, WebsiteWaiver } from 'core/models';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import SignatureDescriptionModal from './SignatureDescription.modal';
import CustomWaiverModal from './CustomWaiver.modal';
import Waiver from './Waiver';
import CustomWaiver from './CustomWaiver';
import { deleteCustomWaiver, putApplicantWaivers } from 'core/store/slices/applicantTracking.slice';

const DesignAtsWebPages = () => { 
  const [selectedWaiver, setSelectedWaiver] = useState<WebsiteWaiver>();
  const [selectedCustomWaiver, setSelectedCustomWaiver] = useState<WebsiteCustomWaiver>();
  const [showWaiverModal, setShowWaiverModal] = useState(false);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [isEHOpen, setIsEHOpen] = useState(false);
  const [isCSOpen, setIsCSOpen] = useState(false);

  const designWebsite = useAppSelector((state) => { return state.applicantTrackingV2.designWebsite; });
  
  const dispatch = useAppDispatch();

  const onAdd = () => {
    setSelectedWaiver(undefined);
    setSelectedCustomWaiver(undefined);
    setShowWaiverModal(true);
  };

  const onOpenWaiver = (w: WebsiteWaiver) => {
    setSelectedWaiver(w);
    setSelectedCustomWaiver(undefined);
    setShowWaiverModal(true);
  };

  const onEdit = (cw: WebsiteCustomWaiver) => {
    setSelectedWaiver(undefined);
    setSelectedCustomWaiver(cw);
    setShowWaiverModal(true);
  };
  
  const onDelete = (sf: WebsiteCustomWaiver) => {
    setSelectedCustomWaiver(sf);
    setShowDeleteConfirmationModal(true);
  };

  const onViewSignatureDescription = () => {
    setShowSignatureModal(true);
  };

  const onDeleteField = () => {
    selectedCustomWaiver && dispatch(deleteCustomWaiver(selectedCustomWaiver));
  };

  const confirmDelete = (confirm: boolean) => {
    if (confirm) {
      onDeleteField();
    }
  };
  
  const onUpdateWaiverCheckbox = (waiver: WebsiteWaiver) => {
    if (!designWebsite) return;
    const waiverIndex = designWebsite?.webSiteWaivers?.findIndex(({ waiverId }) => waiverId === waiver.waiverId);
    
    if (waiverIndex < 0) return console.error('waiver not found');
    const designWebSiteClone = structuredClone(designWebsite);
    designWebSiteClone.webSiteWaivers.splice(waiverIndex, 1, waiver);
    
    dispatch(putApplicantWaivers(designWebSiteClone));
  };

  return (
    <Row className="no-gutters p-0">     
      <Col className="mt-1 shadow p-3 border">
        <PanelHeader
          title="Design ATS Website"
          iconName="wand-magic-sparkles"
        />
        <div className="bg-light mt-3 p-3">
          <div className=" d-flex flex-row">
            <h2 className="font-weight-bold">Website Waivers</h2>
            <div className="flex-grow-1 justify-content-end text-right">
              <button
                type="button"
                className="btn btn-link ml-auto mr-2"
                onClick={onViewSignatureDescription}
              >
                View/Edit Signature Description
              </button>
              <button
                type="button"
                className="btn btn-link dm-grid-action-title pb-0 mr-2"
                onClick={onAdd}
              >
                Add Custom Waiver <Icon
                  name="plus-circle"
                  className="fa-plus-circle"
                />
              </button>
            </div>
          </div>
          <div className="dm-panel dm-panel-border p-2">           
            <Accordion defaultActiveKey="0">
              <Card>
                <Card.Header className="p-3">
                  <Accordion.Toggle
                    eventKey={'4'}
                    style={{
                      background: 'transparent',
                      border: 'none',
                    }}
                    onClick={() => {
                      setIsEHOpen(!isEHOpen);
                    }}
                  >
                    <Icon
                      name={isEHOpen ? 'chevron-up' : 'chevron-down'}
                      className="mr-1"
                    />
                    Waivers
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={'4'}>
                  <Card.Body
                    className="p-3"
                    style={{ gap: '4rem' }}
                  >
                    <div className="d-flex">
                      <div
                        className="d-flex flex-row flex-wrap flex-grow-1"
                      >
                        <div
                          className="d-flex flex-row flex-wrap pr-4"
                          style={{ width: '50%' }}
                        >
                          <div className="d-flex justify-content-start">

                          </div>
                          <div className="d-flex justify-content-end flex-grow-1 mt-2">
                            <div
                              className="d-flex flex-column text-center"
                              style={{ width: 150 }}
                            >
                              <span><u>Visible/Required</u></span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="d-flex flex-row flex-wrap pr-4"
                          style={{ width: '50%' }}
                        >
                          <div className="d-flex justify-content-end flex-grow-1 mt-2">
                            <div
                              className="d-flex flex-column text-center"
                              style={{ width: 150 }}
                            >
                              <span><u>Visible/Required</u></span>
                            </div>
                          </div>
                        </div>
                        {designWebsite?.webSiteWaivers
                          .map((waiver) => (
                            <Waiver
                              key={waiver.waiverId}
                              waiver={waiver}
                              onOpenWaiver={onOpenWaiver}
                              onUpdateWaiverCheckbox={onUpdateWaiverCheckbox}
                            />
                          ))}
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          <div className="dm-panel dm-panel-border p-2">
            <Accordion defaultActiveKey="0">
              <Card>
                <Card.Header className="p-3">
                  <Accordion.Toggle
                    eventKey={'4'}
                    style={{
                      background: 'transparent',
                      border: 'none',
                    }}
                    onClick={() => { setIsCSOpen(!isCSOpen); }}
                  >
                    <Icon
                      name={isCSOpen ? 'chevron-up' : 'chevron-down'}
                      className="mr-1"
                    />
                    Custom Waivers
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={'4'}>
                  <Card.Body
                    className="p-3"
                    style={{ gap: '4rem' }}
                  >
                    {designWebsite?.webSiteCustomWaivers.map((customWaiver) => (
                      <CustomWaiver
                        key={customWaiver.customWaiverId}
                        customWaiver={customWaiver}
                        onEdit={onEdit}
                        onDelete={onDelete}
                      />
                    ))}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      </Col>
      {showWaiverModal && designWebsite && (
        <CustomWaiverModal
          show={showWaiverModal}
          customWaiver={selectedCustomWaiver}
          waiver={selectedWaiver}
          webSiteConfigId={designWebsite?.webSiteConfigId}
          onHide={()=>{return setShowWaiverModal(false);}}
        />
      )}
      {showSignatureModal  && (
        <SignatureDescriptionModal
          show={showSignatureModal}
          onHide={()=>{return setShowSignatureModal(false);}}
        />
      )}
      {showDeleteConfirmationModal && (
        <ConfirmationModal
          title={'Delete Custom Waiver'}
          message={'Are you sure you want to Delete this Waiver?'}
          show={showDeleteConfirmationModal}
          onConfirmed={confirmDelete}
          onHide={() => { return setShowDeleteConfirmationModal(false); }}
        />
      )}
    </Row>
  );
};

export default DesignAtsWebPages;
