import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { InputGrp } from 'core/components/form-controls';
import { SelectGrp } from 'core/components/form-controls/SelectGrp';
import { PayrollAdjustmentDeduction } from 'core/models/PayrollAdjustment.model';
import { getAllDeductionCodes, getIsFinishedPayroll } from 'core/store/selectors';
import { useAppSelector } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';
import { formatWithCommas } from 'utilities/utilities';
import { FormMethods } from 'core/components/form-controls/types';

type Props = {
  index: number;
  control: any;
  errors: FormMethods['errors'];
  register: FormMethods['register'];
  setValue: FormMethods['setValue'];
  watch: FormMethods['watch'];
};

const AdjustmentDeduction = ({ setValue, control, register, errors, watch, index }: Props) => {
  const { fields, append, remove } = useFieldArray<PayrollAdjustmentDeduction>({
    control,
    name: `items[${index}].deductions`,
  });

  const newAdjustmentCheck = useAppSelector((state) => { return state.payrollAdjustment.adjustmentCheckDefaults; });
  const deductionCodeOpts = useSelector(getAllDeductionCodes);
  const isFinishedPayroll =  useSelector(getIsFinishedPayroll);
  const disabled = (control.getValues(`items[${index}].adjustmentType`)?.startsWith('Void') ?? false) || isFinishedPayroll;
  

  const onAdd = () => {
    if (newAdjustmentCheck) {
      append(newAdjustmentCheck.deductions[0]);
    }
  };

  return (
    <>
      <div className="text-right mt-2 mb-2">
        {!isFinishedPayroll ? <button
          type="button"
          className="btn btn-link dm-grid-action-title p-0"
          onClick={onAdd}
          disabled={disabled}
        >
          Add Deduction <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button> : null}
      </div>
      <hr />
      {fields.map((deduction, di) => {
        const amountFormState = watch(`items[${index}].deductions[${di}].amount`);
        
        return (
          <div
            className="d-flex flex-row"
            key={deduction.id}
          >
            <input
              type="hidden"
              name={`items[${index}].deductions[${di}].adjustmentDeductionId`}
              defaultValue={deduction.adjustmentDeductionId}
              ref={register({
                valueAsNumber: true,
              })}
              disabled={disabled}
            />
            <SelectGrp
              groupClass="gc03 mw150 mt-4"
              options={deductionCodeOpts}
              name={`items[${index}].deductions[${di}].dedNo`}
              addEmptyValue={true}
              errorMsg="Required"
              errors={errors.dedNo}
              ref={register({
                valueAsNumber: true,
              })}
              defaultValue={deduction.dedNo}
              disabled={disabled}
              showId = {true}
            />
            <InputGrp
              groupClass="gc03 number-underline"
              label="Amount"
              type="text"
              name={`items[${index}].deductions[${di}].amount`}
              ref={register()}
              defaultValue={formatWithCommas(amountFormState ?? 0)}
              setValue={setValue}
              formatNumber
              hiddenValue={amountFormState ?? 0}
              hiddenRef={register({
                valueAsNumber: true,
              })}
            />
            <div className="col-auto">
              {(
                <button
                  type="button"
                  className="mt-3 btn btn-link dm-grid-action-title"
                  onClick={() => { return remove(di); }}
                >
                  Delete
                  <Icon
                    name="minus-circle"
                    className="fa-minus-circle"
                  />
                </button>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default AdjustmentDeduction;
