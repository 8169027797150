import Icon from 'core/components/shared/Icon';
import React, { Component } from 'react';

interface AGAddItemHeaderComponentParams {
  clickHandler: (data: any) => void;
  label: string;
  item: any;
  headerName?: string;
}

export default class AGAddItemHeaderComponent extends Component {

  constructor(public props: AGAddItemHeaderComponentParams) {
    super(props);
  }

  render() {
    const onClick = () => {
      this.props.clickHandler(this.props.item);
    };
    return (
      <div className="d-flex flex-row flex-grow-1">
        <div className="flex-grow-1 font-weight-bold mt-1">{this.props.headerName}</div>
        <div className="text-right mt-auto">
          <button type="button"
            className="btn btn-link dm-grid-action-title py-0"
            onClick={onClick}>
            {this.props.label} <Icon name="plus-circle"
              className="fa-plus-circle" />
          </button>
        </div>
      </div>

    );
  }
}