
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  CheckboxGrpInLine,
  ControlDatePickerGrp,
  InputGrp,
  SelectGrp,
  SelectModalGrp,
  TextareaGrp,
} from 'core/components/form-controls';
import HistoryLabel from 'core/components/form-controls/HistoryLabel';
import DropdownOptionForm from 'core/components/form-controls/select-modal/DropdownOptionForm';
import { UNSAVED_MESSAGE } from 'core/constants';
import { HrEmployeeStatus } from 'core/models/HrEmployeeStatus';
import { loadSupervisors, toggleBlockNavigation } from 'core/store/actions';
import {
  loadHrEmployeeStatus,
  putHrEmployeeStatus,
} from 'core/store/slices/hr.slice';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
type Props = {
  clientNo: string;
};

const HrEmployeeStatusStatusPage: React.FC<Props> = () => {
  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();
  const dispatch = useAppDispatch();

  const hrStatus = useAppSelector(
    (state) => { return state.hr.hrEmployeeStatus; },
  ) as HrEmployeeStatus;

  const { register, reset, control, handleSubmit, formState, setValue } =
    useForm<HrEmployeeStatus>({
      defaultValues: hrStatus,
    });

  const { isDirty, dirtyFields } = formState;
  const empStatusDropdown = useAppSelector(
    (state) => { return state.dropdown.employeeStatus; },
  );

  const eeoCodeDropDown = useAppSelector((state) => { return state.dropdown.eeoCode; });
  const jobTitleDropDown = useAppSelector((state) => { return state.dropdown.jobTitle; });
  const activeStatusDropDown = useAppSelector(
    (state) => { return state.dropdown.activeStatus; },
  );
  const supervisorDropDown = useAppSelector(
    (state) => { return state.employees.supervisors; },
  );
  const eeoOccupGroupDropDown = useAppSelector(
    (state) => { return state.dropdown.eeoOccupationalGroup; },
  );

  const jobCategoryDropDown = useAppSelector(
    (state) => { return state.dropdown.jobCategory; },
  );

  const eeoUnitNoDropDown = useAppSelector(
    (state) => { return state.dropdown.eeoUnitNo; },
  );

  const exemptStatusDropDown = useAppSelector(
    (state) => { return state.dropdown.exemptStatus; },
  );

  const visaTypeDropDown = useAppSelector((state) => { return state.dropdown.visaType; });

  useEffect(() => {
    dispatch(loadHrEmployeeStatus(protectedEmpNo));
    dispatch(loadSupervisors());
  }, [protectedEmpNo]);

  useEffect(() => {
    reset(hrStatus);
  }, [hrStatus]);

  const onSubmit = (data: HrEmployeeStatus) => {
    const mergeData = { ...hrStatus,
      ...data };
    dispatch(putHrEmployeeStatus(mergeData));
  };

  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: !!Object.keys(dirtyFields).length,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [!!Object.keys(dirtyFields).length]);
  
  return (
    <>
      {hrStatus && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12}>
              <div className="dm-grid-title">Status</div>
              <hr className="dm-panel-hr" />
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <Row>
                <Col xs={3}>
                  <HistoryLabel
                    labelTitle="Employee Status"
                    section="hireStatus"
                    field="empStatus"
                    protectedEmpNo={protectedEmpNo}
                  />
                  <SelectModalGrp
                    name="empStatus"
                    options={empStatusDropdown}
                    control={control}
                    value={hrStatus.empStatus?.toString()}
                    setValue={setValue}
                    addOptionText="Employee Status"
                    noOption={true}
                    modalTitle="Employee Status"
                    formComponent={DropdownOptionForm}
                    dropdownName="EmployeeStatus"
                  />
                </Col>
                <Col xs={3}>
                  <HistoryLabel
                    labelTitle="EEO"
                    section="hireStatus"
                    field="eeo"
                    protectedEmpNo={protectedEmpNo}
                  />
                  <SelectGrp
                    name="eeo"
                    ref={register}
                    options={eeoCodeDropDown}
                    noOption={true}
                    noOptionValue=" "
                    noOptionText="eeo selected"
                  />
                </Col>
                <Col xs={3}>
                  <HistoryLabel
                    labelTitle="Job Title"
                    section="hireStatus"
                    field="jobTitleId"
                    protectedEmpNo={protectedEmpNo}
                  />
                  <SelectModalGrp
                    name="jobTitleId"
                    options={jobTitleDropDown}
                    control={control}
                    value={hrStatus.jobTitleId?.toString()}
                    setValue={setValue}
                    addOptionText="Job Title"
                    noOption={true}
                    modalTitle="Job Title"
                    formComponent={DropdownOptionForm}
                    dropdownName="JobTitle"
                  />
                </Col>
                <Col xs={3}>
                  <HistoryLabel
                    labelTitle="Supervisor"
                    section="payrollInfo"
                    field="supervisorEmpId"
                    protectedEmpNo={protectedEmpNo}
                  />
                  <SelectGrp
                    name="supervisorEmpId"
                    ref={register({
                      valueAsNumber: true,
                    })}
                    options={supervisorDropDown.map(
                      (s) => {
                        return {
                          id: s.empId,
                          description: s.name,
                        };
                      },
                    )}
                    noOption={true}
                    addOptionText="supervisor"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <HistoryLabel
                    labelTitle="Act Status"
                    section="hireStatusExt"
                    field="actingStatus"
                    protectedEmpNo={protectedEmpNo}
                  />
                  <SelectModalGrp
                    name="activeStatus"
                    options={activeStatusDropDown}
                    control={control}
                    value={hrStatus.activeStatus?.toString()}
                    setValue={setValue}
                    addOptionText="Active Status"
                    noOption={true}
                    modalTitle="Active Status"
                    formComponent={DropdownOptionForm}
                    dropdownName="ActiveStatus"
                  />
                </Col>
                <Col xs={3}>
                  <HistoryLabel
                    labelTitle="EEO Occup Group"
                    section="hireStatus"
                    field="eeoOccupGroup"
                    protectedEmpNo={protectedEmpNo}
                  />
                  <SelectGrp
                    name="eeoOccupGroup"
                    ref={register}
                    options={eeoOccupGroupDropDown}
                    addEmptyValue={true}
                  />
                </Col>
                <Col xs={3}>
                  <HistoryLabel
                    labelTitle="Job Category"
                    section="hireStatusExt"
                    field="jobCategory"
                    protectedEmpNo={protectedEmpNo}
                  />
                  <SelectModalGrp
                    name="jobCategory"
                    options={jobCategoryDropDown}
                    control={control}
                    value={hrStatus.jobCategory?.toString()}
                    setValue={setValue}
                    addOptionText="Job Category"
                    noOption={true}
                    modalTitle="Job Category"
                    formComponent={DropdownOptionForm}
                    dropdownName="JobCategory"
                  />
                </Col>
                <Col xs={3}>
                  <HistoryLabel
                    labelTitle="2nd Supervisor"
                    section="payrollInfo"
                    field="secondSupervisorEmpId"
                    protectedEmpNo={protectedEmpNo}
                  />
                  <SelectGrp
                    name="secondSupervisorEmpId"
                    ref={register({
                      valueAsNumber: true,
                    })}
                    options={supervisorDropDown.map(
                      (s) => {
                        return {
                          id: s.empId,
                          description: s.name,
                        };
                      },
                    )}
                    noOption={true}
                    addOptionText="supervisor"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <HistoryLabel
                    labelTitle="Exempt Status"
                    section="hireStatus"
                    field="exemptStatus"
                    protectedEmpNo={protectedEmpNo}
                  />
                  <SelectGrp
                    name="exemptStatus"
                    ref={register}
                    options={exemptStatusDropDown}
                  />
                </Col>
                <Col xs={3}>
                  <HistoryLabel
                    labelTitle="EEO Unit No"
                    section="hireStatus"
                    field="eeoUnitNo"
                    protectedEmpNo={protectedEmpNo}
                  />
                  <SelectGrp
                    name="eeoUnitNo"
                    ref={register}
                    options={eeoUnitNoDropDown}
                  />
                </Col>
                <Col xs={3}>
                  <HistoryLabel
                    labelTitle="Years In Position"
                    section="hireStatusExt"
                    field="yearsInPosition"
                    protectedEmpNo={protectedEmpNo}
                  />
                  <InputGrp
                    ref={register({
                      valueAsNumber: true,
                    })}
                    name="yearsInPosition"
                    type="number"
                  />
                </Col>
                <Col xs={3}>
                  <HistoryLabel
                    labelTitle="Maiden Name"
                    section="hireStatusI9"
                    field="maidenName"
                    protectedEmpNo={protectedEmpNo}
                  />
                  <InputGrp
                    ref={register}
                    name="maidenName"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <HistoryLabel
                    labelTitle="Comments"
                    section="hireStatus"
                    field="comments"
                    protectedEmpNo={protectedEmpNo}
                  />
                  <TextareaGrp
                    ref={register}
                    name="comments"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div className="dm-grid-title">
                    Visa Status
                  </div>
                  <hr className="dm-panel-hr" />
                </Col>
                <Col xs={4}>
                  <SelectGrp
                    label="Visa Type"
                    name="visaTypeId"
                    ref={register({
                      valueAsNumber: true,
                    })}
                    options={visaTypeDropDown}
                  />
                </Col>
                <Col xs={4}>
                  <ControlDatePickerGrp
                    name="visaIssueDate"
                    control={control}
                    label="Issue Date"
                  />
                </Col>
                <Col xs={4}>
                  <ControlDatePickerGrp
                    name="visaExpireDate"
                    control={control}
                    label="Expiration Date"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={3}>
              <h2 className="dm-grid-title">Veteran Status</h2>
              <Row className="no-gutters align-items-center">
                <Col
                  xs="auto"
                  className="mr-1"
                >
                  <CheckboxGrpInLine
                    ref={register}
                    labelFirst={false}
                    name="isVeteran"
                  />
                </Col>
                <Col>
                  <div className="form-group">
                    <HistoryLabel
                      labelTitle="Veteran"
                      section="hireStatusVeteran"
                      field="isVet"
                      protectedEmpNo={protectedEmpNo}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="no-gutters align-items-center">
                <Col
                  xs="auto"
                  className="mr-1"
                >
                  <CheckboxGrpInLine
                    ref={register}
                    labelFirst={false}
                    name="isDisabledVeteran"
                  />
                </Col>
                <Col>
                  <div className="form-group">
                    <HistoryLabel
                      labelTitle="Disabled Veteran"
                      section="hireStatusVeteran"
                      field="DisabledVet"
                      protectedEmpNo={protectedEmpNo}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="no-gutters align-items-center">
                <Col
                  xs="auto"
                  className="mr-1"
                >
                  <CheckboxGrpInLine
                    ref={register}
                    labelFirst={false}
                    name="isActiveDutyWartimeOrCampaignBadge"
                  />
                </Col>
                <Col>
                  <div className="form-group">
                    <HistoryLabel
                      labelTitle="Active Duty Wartime or Campaign Badge"
                      section="hireStatusVeteran"
                      field="activeDutyOrCampaignBadge"
                      protectedEmpNo={protectedEmpNo}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="no-gutters align-items-center">
                <Col
                  xs="auto"
                  className="mr-1"
                >
                  <CheckboxGrpInLine
                    ref={register}
                    labelFirst={false}
                    name="isArmedForcesServiceMedal"
                  />
                </Col>
                <Col>
                  <div className="form-group">
                    <HistoryLabel
                      labelTitle="Armed Forces Service Medal"
                      section="hireStatusVeteran"
                      field="armedForcesServiceMedal"
                      protectedEmpNo={protectedEmpNo}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="no-gutters align-items-center">
                <Col
                  xs="auto"
                  className="mr-1"
                >
                  <CheckboxGrpInLine
                    ref={register}
                    labelFirst={false}
                    name="isRecentlySeperatedVeteran"
                  />
                </Col>
                <Col>
                  <div className="form-group">
                    <HistoryLabel
                      labelTitle="Recently Separated Vet"
                      section="hireStatusVeteran"
                      field="recentlySeparated"
                      protectedEmpNo={protectedEmpNo}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="no-gutters align-items-center">
                <Col
                  xs="auto"
                  className="mr-1"
                >
                  <CheckboxGrpInLine
                    ref={register}
                    labelFirst={false}
                    name="isHandicapped"
                  />
                </Col>
                <Col>
                  <div className="form-group">
                    <HistoryLabel
                      labelTitle="Handicapped"
                      section="hireStatusExt"
                      field="Handicapped"
                      protectedEmpNo={protectedEmpNo}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <button
                className="btn orange-button"
                disabled={!Object.keys(dirtyFields).length}
              >
                Save
              </button>
            </Col>
          </Row>
        </form>
      )}
    </>
  );
};

export default HrEmployeeStatusStatusPage;
