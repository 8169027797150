import React, { useState, useEffect } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import {
  AllModules,
  ColDef,
  GridApi,
  GridOptions,
} from '@ag-grid-enterprise/all-modules';
import { Col, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getEmployees } from '../../../../core/store/selectors';
import {
  Employee,
  MessageSelection,
  PayrollMessage,
} from '../../../../core/models';
import Icon from 'core/components/shared/Icon';

const columns: ColDef[] = [
  {
    field: 'select',
    headerName: 'Selected',
    checkboxSelection: true,
    width: 80,
  },
  {
    field: 'empNo',
    headerName: 'Employee No',
    sortable: true,
    width: 120,
  },
  {
    field: 'name',
    headerName: 'Employee Name',
    sortable: true,
    minWidth: 140,
  },
];

const gridOptions: GridOptions = {
  domLayout: 'autoHeight',
  columnDefs: columns,
  rowSelection: 'multiple',
  enableCellTextSelection: true,
  defaultColDef: {
    suppressMenu: true,
    resizable: true,
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
};

type PropTypes = {
  message: PayrollMessage | undefined;
  show: boolean;
  onHide: () => void;
  selectedItems: (items: MessageSelection[]) => void;
};

const MessageSelectEmployeesModal: React.FC<PropTypes> = ({
  message,
  show,
  onHide,
  selectedItems,
}) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [, setGridColumnApi] = useState(null);
  const [filteredEmployees, setFilteredEmployees] = useState<any[]>([]);
  const [selectedEmployees, setSelectedEmployees] =
    useState<MessageSelection[]>();
  const employees = useSelector(getEmployees);

  useEffect(() => {
    setFilteredEmployees(
      employees
        .filter((e: Employee) => { return !e.termDate; })
        .map((x: Employee) => {
          return {
            ...x,
            name: x.lastName + ', ' + x.firstName,
          };
        }),
    );
  }, [employees]);

  const onGridReady = (params: any) => {
    gridOptions?.api?.forEachNode((node) => {
      if (message?.employeeSelections) {
        return message.employeeSelections.some(
          (s: MessageSelection) => { return +s.value === node.data.empNo; },
        )
          ? node.setSelected(true)
          : 0;
      }
    });

    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      selectedRows.length > 0 &&
        setSelectedEmployees(
          selectedRows.map((r) => {
            return {
              messageDetailId: r.messageDetailId || 0,
              value: '' + r.empNo,
            };
          }),
        );
    }
  };

  const onSave = () => {
    selectedEmployees && selectedItems(selectedEmployees);
    closeModal();
  };

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal show={show}
        onHide={onHide}
        size="sm"
        animation={false}>
        <div className="modal-header">
          <div className="dm-card-title">Select Employees</div>
          <button type="button"
            onClick={closeModal}>
            <Icon name="times"
              className="modal-close-btn"
            />
          </button>
        </div>
        <Modal.Body>
          <Row>
            <Col
              xs={12}
              className="table-wrapper-wrapper ag-theme-balham"
            >
              <div
                className="p-2"
                style={{
                  height: '400px',
                  overflowY: 'scroll',
                }}
              >
                <AgGridReact
                  gridOptions={gridOptions}
                  rowData={filteredEmployees}
                  modules={AllModules}
                  onFirstDataRendered={onGridReady}
                  onSelectionChanged={onSelectionChanged}
                />
              </div>
              <div className="d-flex mt-3">
                <button
                  className="orange-outline-button ml-auto mr-2"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="orange-button mr-2"
                  onClick={onSave}
                >
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MessageSelectEmployeesModal;
