import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { InputGrp, SelectGrp } from '..';
import { SchoolDropdown } from '../../../models';
import {
  createSchoolDropdown,
  updateSchoolDropdown,
} from '../../../store/actions';
import { getStates } from '../../../store/selectors';
import { FieldInputSettings } from '../types';

const fs: FieldInputSettings = {
  name: {
    name: 'name',
    label: 'School',
    labelWidth: 25,
    required: true,
  },
  schoolAlphaCode: {
    name: 'schoolAlphaCode',
    label: 'School / Alpha Code',
    labelWidth: 25,
    required: true,
  },
  address: {
    name: 'address',
    label: 'Address',
    labelWidth: 25,
    required: true,
  },
  city: {
    name: 'city',
    label: 'City',
    labelWidth: 25,
    required: true,
  },
  state: {
    name: 'state',
    label: 'State',
    labelWidth: 25,
    required: true,
  },
  zip: {
    name: 'zip',
    label: 'Zip',
    labelWidth: 25,
    required: true,
  },
};

type PropTypes = {
  item: SchoolDropdown;
  onHide: any;
};

const SchoolOptionForm: React.FC<PropTypes> = ({
  item,
  onHide,
}) => {
  const dispatch = useDispatch();

  const { register, errors, handleSubmit } = useForm({
    defaultValues: { ...item },
  });

  const stateOpts = useSelector(getStates);


  const onSubmit = (data: any, event?: React.BaseSyntheticEvent) => {
    event?.stopPropagation();
    const updatedSchool = { ...item, ...data } as SchoolDropdown;
    updatedSchool.schoolAlphaCode = data.schoolAlphaCode;
    updatedSchool.schoolId = item.schoolId;
    if (updatedSchool.schoolId) {
      dispatch(updateSchoolDropdown(updatedSchool));
    } else {
      updatedSchool.schoolId = 0;
      dispatch(createSchoolDropdown(updatedSchool));
    }
    onHide(false);
  };

  return (
    <div
      className="dm-panel dm-panel-border mx-4 d-flex flex-column"
    >
      <div className="dm-grid-action-title">Add/Edit</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGrp
          {...fs.name}
          errors={errors.name}
          ref={register}
        />
        <InputGrp
          {...fs.schoolAlphaCode}
          errors={errors.schoolAlphaCode}
          ref={register}
        />
        <InputGrp
          {...fs.address}
          errors={errors.address}
          ref={register}
        />
        <InputGrp
          {...fs.city}
          errors={errors.city}
          ref={register}
        />
        <SelectGrp
          {...fs.state}
          errors={errors.state}
          ref={register}
          options={stateOpts}
        />
        <InputGrp
          {...fs.zip}
          errors={errors.zip}
          ref={register}
        />

        <div className="d-flex">
          <button
            type="button"
            className="btn btn-primary orange-outline-button ml-auto mr-2"
            onClick={() => {return onHide(false);}}
          >
            Cancel
          </button>
          <button type="submit" className="orange-button mr-2">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default SchoolOptionForm;
