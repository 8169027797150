import React, { Dispatch, SetStateAction, useState } from 'react';
import Modal from 'core/components/modals/Modal';
import Table from 'core/components/shared/dm-table/Table';
import { ColumnHeaderData, Row } from 'core/components/shared/dm-table/types';
import { DetailArrayField, TaxingCity } from 'core/models';
import { useAppSelector } from 'utilities/hooks';
import { InputGrp } from 'core/components/form-controls';
import styles from './styles.module.scss';

const buildCityCodeRow = (city: TaxingCity): Row => {
  return {
    id: city.entityId ?? 0,
    cells: [
      { children: city.state, styleClass: 'td-20' },
      { children: city.codeDesc, styleClass: 'td-60' },
      { children: city.type, styleClass: 'td-20' },
    ],
  };
};

const cityCodeColumns: ColumnHeaderData[] = [
  { title: 'State', styleClass: 'th-20' },
  { title: 'City', styleClass: 'th-60' },
  { title: 'Type', styleClass: 'th-20' },
];

const matchQuery = (timeCard: TaxingCity, _query: string): boolean => {
  return timeCard?.codeDesc?.toLowerCase()?.includes(_query.toLowerCase())
         || timeCard?.state?.toLowerCase()?.includes(_query.toLowerCase())
         || timeCard?.type?.toLowerCase()?.includes(_query.toLowerCase());
};

type Props = {
  show: boolean;
  onHide: () => void;
  setControlledField: (property: keyof DetailArrayField, newValue: string | number | boolean) => void;
  setCityDesc: Dispatch<SetStateAction<string>>;
};

const CityCodeModal = ({ show, onHide, setControlledField, setCityDesc }: Props) => {
  const { taxingCities } = useAppSelector(({ contractor }) => contractor);
  const filteredTaxingCities = taxingCities?.filter((entity) => !['N/A', ''].includes(entity?.codeDesc.trim()));
  const [filteredCityCodes, setFilteredCityCodes] = useState<TaxingCity[]>(filteredTaxingCities); 

  const updateCityDesc = (id: number) => {
    const matchingCity = taxingCities?.find((city) => city.entityId === id);
    
    setCityDesc(matchingCity?.codeDesc ?? '');
  };

  const onFilter = (search: string) => {
    const newState = structuredClone(filteredTaxingCities)?.filter((cityCode: TaxingCity) => matchQuery(cityCode, search));
    setFilteredCityCodes(newState);
  };
  
  return (
    <Modal
      title="Change City Code"
      show={show}
      onHide={onHide}      
    >
      <div className="d-flex flex-column">
       <div className={styles['job-info-modal-top-container']}>
          <label
            htmlFor="job-info-modal-search"
            className={`mb-3 ${styles['job-info-modal-label']}`}
          >Search</label>
          <InputGrp
            placeholder="Enter state, city or type"
            groupClass="m-0"
            inputClass={`gc50 ml-2 mb-3 ${styles['job-info-modal-input-large']}`}
            name="job-info-modal-search"
            type="text"
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              onFilter(target.value);
            }}
          />
        </div>
        <Table
          columns={cityCodeColumns}
          rows={filteredCityCodes?.map((cityCode) => buildCityCodeRow(cityCode))}
          onRowClick={(id) => {
            setControlledField('cityEntityId', id);
            updateCityDesc(+id); // should be a number in this case
            onHide();
          }}
        />
      </div>
    </Modal>
  );
};

export default CityCodeModal;