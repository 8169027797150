import Icon from 'core/components/shared/Icon';
import { CustomPersonalField } from 'core/models';
import React, { useState } from 'react';
import { Accordion, Card, Col, Row, Tab, Tabs } from 'react-bootstrap';

type Props = {
  hasTabs?: boolean;
  fields: any[];
  label: string;
  eventKey: string;
  hideTabs?: boolean;
};

const CustomFields = ({
  hasTabs = true,
  fields,
  label,
  eventKey,
  hideTabs = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header className="p-3">
          <Accordion.Toggle
            eventKey={eventKey}
            style={{
              background: 'transparent',
              border: 'none',
            }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <Icon
              name={isOpen ? 'chevron-up' : 'chevron-down'}
              className="mr-1"
            />
            {label}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body
            className="p-3"
            style={{ gap: '4rem' }}
          >
            {hasTabs && !hideTabs ? (
              <Tabs defaultActiveKey={fields?.[0]?.eventKey}>
                {fields.map((field) => {
                  return (
                    <Tab
                      key={field?.eventKey}
                      eventKey={field?.eventKey}
                      title={field?.eventKey}
                      className="py-4"
                    >
                      {field.items.length ? (
                        <Row>
                          {field.items.map(
                            (customField: CustomPersonalField) => {
                              return (
                                <Col
                                  key={customField.question}
                                  sm={2}
                                >
                                  <p className="font-weight-bold mb-0">
                                    {customField.question}
                                  </p>
                                  <p className="mb-0">{customField.answer}</p>
                                </Col>
                              );
                            },
                          )}
                        </Row>
                      ) : (
                        'No custom field found!'
                      )}
                    </Tab>
                  );
                })}
              </Tabs>
            ) : fields.length ? (
              <Row>
                {fields.map((customField: any) => {
                  if (hideTabs) {
                    return customField.items.length ? (
                      <Row className="w-100 px-4">
                        {customField.items.map(
                          (customField2: CustomPersonalField) => {
                            return (
                              <Col
                                key={customField2.question}
                                sm={2}
                              >
                                <p className="font-weight-bold mb-0">
                                  {customField2.question}
                                </p>
                                <p className="mb-0">{customField2.answer}</p>
                              </Col>
                            );
                          },
                        )}
                      </Row>
                    ) : (
                      'No custom field found!'
                    );
                  }
                  return (
                    <Col
                      key={customField.question}
                      sm={2}
                    >
                      <p className="font-weight-bold mb-0">
                        {customField.question}
                      </p>
                      <p className="mb-0">{customField.answer}</p>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              'No custom field found!'
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default CustomFields;
