import React from 'react';
import Cell from './Cell';
import styles from './_styles.module.scss';
import { ColumnHeaderData } from './types';

type Props = {
  columns: ColumnHeaderData[];
};

const TableHeader = ({ columns }: Props) => {
  return (
    <div className={styles['table-header']}>
      {columns.map(({ title, styleClass, position = 'start' }: ColumnHeaderData, index: number) => (
        <Cell
          key={title + index}
          styleClass={styleClass}
          position={position}
        >
          {title}
        </Cell>
      ))
      }
    </div>
  );
};

export default TableHeader;