const IS_AUTHENTICATED_KEY = 'isAuthenticated';

const getItem = (key: string) => { return sessionStorage.getItem(key); };
const setItem = (key: string, value: string) => { return sessionStorage.setItem(key, value); };
const removeItem = (key: string) => { return sessionStorage.removeItem(key); };

const setIsAuthenticated = (isAuthenticated: boolean) => {
  if (isAuthenticated) {
    setItem(IS_AUTHENTICATED_KEY, 'true');
  }
};

const getIsAuthenticated = () => {
  return sessionStorage.getItem(IS_AUTHENTICATED_KEY);
};

const clearIsAuthenticated = () => {
  removeItem(IS_AUTHENTICATED_KEY);
};

export const SessionStorageService = {
  getItem,
  setItem,
  removeItem,
  setIsAuthenticated,
  getIsAuthenticated,
  clearIsAuthenticated,
};