import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { InputGrp } from 'core/components/form-controls';
import JobSnapshot from './JobSnapshot';
import { TimeCardJobRoster } from 'core/models/Contractor.model';

type Props = {
  jobList: TimeCardJobRoster[];
  selectedJob: TimeCardJobRoster;
  setSelectedJob:  Dispatch<SetStateAction<TimeCardJobRoster | null>>
};

const JobSnapshotList = ({ jobList, selectedJob, setSelectedJob }: Props) => {
  const [listState, setListState] = useState<TimeCardJobRoster[]>([]);
  
  useEffect(() => {
    setListState(jobList);
  }, [jobList]);
  
  const handleJobListSearch = (query: string) => {
    if (!query.trim().length) {
      setListState(jobList);
      return;
    }

    setListState(jobList?.filter((job) => 
      job.jobDescription.includes(query)
      || job.jobNo.toString().includes(query),
    ));
  };
  
  return (
    <div className={styles['snapshot-column']}>
      <label
        htmlFor="job-search"
        className={styles['top-label']}
      >Filter jobs</label>
      <InputGrp
        placeholder="Search"
        inputClass={`gc20 ${styles.input}`}
        name="job-search"
        type="text"
        onChange={(e: any) => {
          handleJobListSearch(e.target.value);
        }}
      />
      <ul className={styles['snapshot-list']}>
        {listState.map((x) => (
          <div
            onClick={() => { setSelectedJob(x); }}
          >
            <JobSnapshot
              job={x}
              isSelected={selectedJob?.jobNo === x.jobNo}
            />
          </div>
        ))}
      </ul>
    </div>
  );
};

export default JobSnapshotList;