import React, { useContext } from 'react';
import './time-card-styles.scss';
import { inputStyle } from './InputSection';
import { InputGrp } from 'core/components/form-controls';
import ModalInputButton from './ModalInputButton';
import { TimeCardContext } from './TimeCardContext';
import { DetailArrayField } from 'core/models';

type Props = {
  index: number;
  property: keyof DetailArrayField;
  value: string | number;
  modalName: string;
  onClick: () => void;
};

const ModalInput = ({ index, property, value, modalName, onClick }: Props) => {
  const { formMethods: { register }, updateDirtyState } = useContext(TimeCardContext);
  
  return (
    <div className="modal-input-container">
      <InputGrp
        name={`details[${index}].${property}`}
        ref={register()}
        value={value}
        groupClass="modal-input"
        inputStyle={inputStyle}
        onChange={(_: any) => { updateDirtyState(true); }}
      />      
      <ModalInputButton
        modalName={modalName}
        onClick={onClick}
      />
    </div>
  );
};

export default ModalInput;