import React, { useEffect, useState } from 'react';
import { DMDropdownDataModel } from 'core/components/form-controls/DMDropDown';
import { NavLink } from 'react-router-dom';
import { CorrectionsReportType } from 'core/models';
import { useAppDispatch } from 'utilities/hooks';
import { DMDropdownMenu } from 'core/components/form-controls';
import DeptAllocationReportModal from './DeptAllocationsPage/DeptAllocationReport.modal';
import HamburgerMenu from 'core/components/shared/HamburgerMenu';

const menuIds = {
  ALLOCATION_ALL_EMPS: 'corrections-nav-1',
  ALLOCATION_SELECTED_EMP: 'corrections-nav-2',
  ALLOCATION_ACTIVE_EMPS: 'corrections-nav-3',
};

type CorrectionsToolbarProps = {
  isDeptAllocations: boolean;
  toggleSidebar: () => void;
  showSidebarMenuIcon: boolean;
};

const CorrectionsToolbar = ({ isDeptAllocations, showSidebarMenuIcon, toggleSidebar }: CorrectionsToolbarProps) => {
  const [correctionsReportType, setCorrectionsReportType] = useState<CorrectionsReportType | null>(null);
  const [showCorrectionsReport, setShowCorrectionsReport] = useState(false);
  
  const dispatch = useAppDispatch();
  
  const navArray: {
    type: string;
    text?: string;
    href?: string;
    data?: DMDropdownDataModel;
  }[] = [
    {
      type: 'link',
      text: 'Department Allocations',
      href: '/dept-allocations',
    },
    {
      type: 'link',
      text: 'EEO Info Corrections',
      href: '/eeo-info',
    },
    {
      type: 'link',
      text: "Workers' Compensation Corrections",
      href: '/workers-comp',
    },
  ];

  const correctionsReportsDD: DMDropdownDataModel = {
    id: 'corrections-reports-dd',
    title: 'Download Reports',
    items: [
      {
        id: menuIds.ALLOCATION_ALL_EMPS,
        text: 'For All Employees',
        href: '#',
      },
      {
        id: menuIds.ALLOCATION_SELECTED_EMP,
        text: 'For Selected Employee',
        href: '#',
      },
      {
        id: menuIds.ALLOCATION_ACTIVE_EMPS,
        text: 'For Active Employees',
        href: '#',
      },
    ],
  };
  
  if (isDeptAllocations) {
    navArray.push({
      type: 'dropdown',
      data: correctionsReportsDD,
    });
  }
  
  const onMenuSelect = (menuId: string) => {
    switch (menuId) {
      case menuIds.ALLOCATION_ALL_EMPS:
        setCorrectionsReportType(CorrectionsReportType.CurrentAllocationAllEmployee);
        break;
      case menuIds.ALLOCATION_SELECTED_EMP:
        setCorrectionsReportType(CorrectionsReportType.CurrentAllocationSelectedEmployee);
        break;
      case menuIds.ALLOCATION_ACTIVE_EMPS:
        setCorrectionsReportType(CorrectionsReportType.CurrentAllocationActiveEmployee);
        break;
      default:
        console.error('Error: option not found');
        break;        
    }
  };
  
  const hideModal = () => {
    setCorrectionsReportType(null);
  };
  
  useEffect(() => {
    if (correctionsReportType) {
      setShowCorrectionsReport(!!correctionsReportType.length);
    }
  }, [correctionsReportType]);
  
  return (
    <div className="dm-toolbar">
      {showSidebarMenuIcon && (
        <HamburgerMenu
          toggleSidebar={toggleSidebar}
        />
      )}
      {navArray.map((navItem, i) => {
        switch (navItem.type) {
          case 'link':
            return (
              <NavLink
                key={i}
                to={navItem?.href ?? '#'}
              >
                {navItem?.text ?? ''}
              </NavLink>
            );
          case 'dropdown':
            return navItem?.data ? (
              <DMDropdownMenu
                key={i}
                data={navItem.data}
                toggleClass="dm-toolbar-nav"
                whiteChevron={true}
                onSelect={onMenuSelect}
              />
            ) : null;
          default:
            return null;
        }
      })}
      {showCorrectionsReport && correctionsReportType ? (
        <DeptAllocationReportModal
          title=""
          allocationReportType={correctionsReportType}
          show={showCorrectionsReport && !!correctionsReportType.length}
          onHide={hideModal}
        />
      ) : null}
    </div>
  );
};

export default CorrectionsToolbar;