import React from 'react';

const formats = [
  [
    {
      className: 'ql-size',
      options: ['small', 'false', 'large', 'huge'],
    },
  ],
  [
    { className: 'ql-bold' }, 
    { className: 'ql-italic' }, 
    { className: 'ql-underline' }, 
    { className: 'ql-strike' },
    { className: 'ql-link' },
  ],
  [
    {
      className: 'ql-list',
      value: 'ordered',
    },
    {
      className: 'ql-list',
      value: 'bullet',
    }, {
      className: 'ql-clean',
      value: 'scale',
    },
  ],
];


type FormatData = {
  className?: string;
  options?: string[];
  value?: string;
};
  
const renderOptions = (formatData:FormatData) => {
  const { className, options } = formatData;
  return (
    <select className={className}>
      <option selected></option>
      {
        options?.map((value) => {
          return (
            <option value={value}></option>
          );
        })
      }
    </select>
  );
};

const renderSingle = (formatData:FormatData) => {
  const { className, value } = formatData;
  return (
    <button
      className={className}
      value={value}
    ></button>
  );
};
const QuillCustomToolbar = () => {
  return (
    <div id="toolbar">
      {
        formats.map(classes => {
          return (
            // eslint-disable-next-line react/jsx-key
            <span className="ql-formats">
              {
                classes.map((formatData:FormatData) => {                    
                  return (formatData.options !== undefined) ? renderOptions(formatData) : renderSingle(formatData);
                })
              }
            </span>
          );
        })
      }
    </div>
  );
};
export default QuillCustomToolbar;