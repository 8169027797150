import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';
import { AllModules, ColDef, GetDetailRowDataParams, GridOptions, MasterDetailModule } from '@ag-grid-enterprise/all-modules';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { agDateRenderer } from 'utilities/ag-grid-renderers';
import { TaxChange } from 'core/models';

//#region AgGrid
const columns: ColDef[] = [
  {
    field: 'employeeName',
    headerName: 'Employee',
    sortable: true,
    width: 160,
    cellRenderer: 'agGroupCellRenderer',
  }, {
    field: 'taxForm',
    headerName: 'Tax Form',
    sortable: true,
    width: 100,
  },
  {
    field: 'year',
    headerName: 'Year',
    sortable: true,
    width: 100,
  },
  {
    field: 'dateSubmitted',
    headerName: 'Date Submitted',
    cellRenderer: 'dateRenderer',
    sortable: true,
    width: 120,
  }, {
    field: 'dateManaged',
    headerName: 'Date Managed',
    cellRenderer: 'dateRenderer',
    sortable: true,
    width: 120,
  }, {
    field: 'actionTaken',
    headerName: 'Action Taken',
    sortable: true,
    width: 130,
  }, {
    field: 'managedBy',
    headerName: 'Managed By',
    sortable: true,
    width: 150,
  }];

const gridOptions: GridOptions = {
  columnDefs: columns,
  domLayout: 'autoHeight',
  defaultColDef: {
    autoHeight: true,
    suppressMenu: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
  pagination: true,
  paginationPageSize: 10,
  masterDetail: true,
  detailRowAutoHeight: true,
  detailCellRendererParams: {
    detailGridOptions: {
      defaultColDef: {
        autoHeight: true,
        suppressMenu: true,
        resizable: true,
        singleCickEdit: true,
        cellClass: 'ag-cell-left-border',
      },
      columnDefs: [
        {
          field: 'fieldName',
          headerName: 'Field Name',
        },
        {
          field: 'value',
          headerName: 'Value',
        },
      ],
    },
    getDetailRowData: function (params: GetDetailRowDataParams) {
      params.successCallback(params.data.details);
    },
  },
  // @ts-ignore
  components: {
    dateRenderer: agDateRenderer,
  },
};

type PropTypes = {
  items: TaxChange[];
};

const TaxChangeHistory: React.FC<PropTypes> = ({ items }) => {
  return (
    <div>
      <Row>
        <Col
          xs={12}
          className="table-wrapper-wrapper ag-theme-balham"
        >
          <AgGridReact
            gridOptions={gridOptions}
            rowData={items}
            overlayNoRowsTemplate={
              '<span>No Changes to Display</span>'
            }
            modules={[MasterDetailModule]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TaxChangeHistory;