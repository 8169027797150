import React from 'react';
import CheckBoxForm from '../../Shared/CheckBoxForm';
import { PageSection, UserMapSelectedField } from 'core/models/Downloader.model';
import { Col, Row } from 'react-bootstrap';
import GridSelect from '../../Shared/GridSelect';
import { useAppSelector } from 'utilities/hooks';
import { EEDComponentProps, UserMapSelectedFieldTypeParams } from 'types/eed.types';
import { TO_ABBREVIATED, convertRegion } from 'core/states';

// Custom Layout for Checkbox and Grid select forms
const TaxInfoFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  const stateTaxEntityOptions = useAppSelector((state) => { return state.dropdown.stateTaxEntity; });
  const localTaxEntityOptions = useAppSelector((state) => { return state.dropdown.localTaxEntity; });

  const stateFields = sectionFields.find(f => {
    return f.name === 'State';
  })?.fields || [];

  return (
    <Row>
      {!!sectionFields?.length && (
        <>
          <Col xs={12}>
            <CheckBoxForm
              formMethods={formMethods}
              dFields={sectionFields.find(f => {
                return f.name === 'Other Tax Info';
              })?.fields}
              section="Other Tax Info"
              title="Other"
            />
          </Col>
          <Col xs={12}>
            <CheckBoxForm
              formMethods={formMethods}
              dFields={sectionFields.find(f => {
                return f.name === 'Federal';
              })?.fields}
              section="Federal"
              title="Federal"
            />
          </Col>
          <Col xs={12}>
            <GridSelect
              section="State"
              title="State"
              dFields={stateFields}
              options={stateTaxEntityOptions.map((item) => {
                return {
                  ...item,
                  code: convertRegion(item.description, TO_ABBREVIATED),
                };
              })}
              formMethods={formMethods}
            />
          </Col>
          <Col xs={12}>
            <GridSelect
              section="Local"
              title="Local"
              dFields={sectionFields.find(f => {
                return f.name === 'Local';
              })?.fields}
              options={localTaxEntityOptions}
              formMethods={formMethods}
            />
          </Col>
        </>
      )}
    </Row>
  );
};

export default TaxInfoFields;