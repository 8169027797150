import React, { useState } from 'react';

import {
  ControlDatePickerGrp,
  InputGrp,
  InputGrpCurrency,
  InputGrpDecimal,
  SelectGrp,
  TextareaGrp,
} from '../../../core/components/form-controls';
import { useSelector } from 'react-redux';
import { HREmployment } from '../../../core/models/HREmployee.model';
import { getEmploymentTypes, getStates } from '../../../core/store/selectors';
import { Collapse } from 'react-bootstrap';
import { ArrayField } from 'react-hook-form';
import { CURRENCY_VALIDATION, PHONE_VALIDATION, ZIP_VALIDATION } from '../../../core/constants';
import { formatPhone } from '../../../utilities/utilities';
import Icon from 'core/components/shared/Icon';

type PropTypes = {
  item: Partial<ArrayField<HREmployment>>;
  index: number;
  control: any;
  formMethods: any;
  onDelete: (item: HREmployment) => void;
};

const EmploymentItem: React.FC<PropTypes> = ({
  item,
  index,
  control,
  formMethods: { errors, setValue, register },
  onDelete,
}) => {

  const stateOpts = useSelector(getStates);

  const [openState, setOpenState] = useState<{ [key: string]: boolean }>({
    details: false,
  });

  const employmentTypes = useSelector(getEmploymentTypes);

  const toggleInfo = (type: string) => {
    setOpenState({
      ...openState,
      [type]: !openState[type]
    });
  };

  return (


    <div className="d-flex flex-column">
      <div className="d-flex flex-wrap">
        <input
          type="hidden"
          name={`employmentItems[${index}].employmentId`}
          defaultValue={item.employmentId}
          ref={register({ valueAsNumber: true })}
        />
        <InputGrp
          name={`employmentItems[${index}].employerName`}
          defaultValue={item.employerName}
          label="NAME"
          groupClass="gc12"
          ref={register()} />
        <InputGrp
          name={`employmentItems[${index}].employerStreet`}
          defaultValue={item.employerStreet}
          label="STREET"
          groupClass="gc12"
          ref={register()} />
        <InputGrp
          name={`employmentItems[${index}].employerCity`}
          defaultValue={item.employerCity}
          label="CITY"
          groupClass="gc12"
          ref={register()} />
        <SelectGrp
          name={`employmentItems[${index}].employerState`}
          defaultValue={item.employerState}
          label="STATE"
          groupClass="gc12"
          ref={register()}
          options={stateOpts} />

        <InputGrp
          name={`employmentItems[${index}].employerZip`}
          label="ZIP"
          type="number"
          defaultValue={item.employerZip}
          groupClass="gc12"
          errors={errors.employmentItems?.[index]?.employerZip}
          ref={register({
            pattern: {
              value: ZIP_VALIDATION,
              message: 'Invalid zip',
            },
          })}
        />
        <InputGrp
          name={`employmentItems[${index}].employerZip4`}
          defaultValue={item.employerZip4}
          label="ZIP +4"
          groupClass="gc12 mw100"
          errors={errors.employmentItems?.[index]?.employerZip4}
          ref={register({
            maxLength: {
              value: 4,
              message: 'Max length is 4 chars',
            },
          })} />

        <button
          type="button"
          className="btn btn-link dm-grid-action-title mt-2"
          onClick={() => { return onDelete(item as HREmployment); }}
        >
          Delete Employment
          <Icon name="minus-circle"
            className="fa-minus-circle" />
        </button>
      </div>

      <div className="d-flex flex-column">
        <div
          role="button"
          className="dm-card-subtitle2 mb-2"
          onClick={() => {
            return toggleInfo(
              'details',
            );
          }}
        >
          <Icon name={openState.details ? 'chevron-down' : 'chevron-right'}
            className="mr-2" />
          Employment Details
        </div>

        <Collapse in={openState.details}>
          <div className="dm-panel dm-panel-border mt-1">
            <div className="d-flex flex-row">

              <div className="d-flex flex-column  flex-grow-1 mr-3">
                <div className="dm-card-subtitle2 mb-2">Position Information</div>
                <hr className="dm-panel-hr" />
                <div className="d-flex flex-row">
                  <ControlDatePickerGrp
                    name={`employmentItems[${index}].empStartDate`}
                    label="EMPLOYED FROM"
                    groupClass="gc12"
                    value={item.empStartDate}
                    setValue={setValue}
                    control={control} />
                  <ControlDatePickerGrp
                    name={`employmentItems[${index}].empEndDate`}
                    label="THROUGH"
                    groupClass="gc12"
                    value={item.empEndDate}
                    setValue={setValue}
                    control={control} />
                </div>
                <div className="d-flex flex-row">
                  <InputGrp
                    name={`employmentItems[${index}].empTitle`}
                    label="JOB TITLE"
                    defaultValue={item.empTitle}
                    groupClass="gc12"
                    ref={register()} />
                  <SelectGrp
                    noOption={true}
                    noOptionText="EMPLOYMENT TYPE"
                    name={`employmentItems[${index}].employmentTypeId`}
                    defaultValue={item.employmentTypeId}
                    label="EMPLOYMENT TYPE"
                    groupClass="gc12"
                    ref={register({ valueAsNumber: true })}
                    options={employmentTypes}
                  />
                </div>
                <div className="d-flex flex-row">

                  <InputGrpDecimal
                    name={`employmentItems[${index}].empWorkHours`}
                    defaultValue={item.empWorkHours}
                    label="WORK HOURS/WK"
                    groupClass="gc12"
                    ref={register({
                      valueAsNumber: true,
                    })} />
                  <InputGrpCurrency
                    name={`employmentItems[${index}].empStartPay`}
                    defaultValue={item.empStartPay}
                    label="STARTING PAY"
                    groupClass="gc12"
                    errors={errors.empStartPay}
                    setValue={setValue}
                    ref={register({
                      CURRENCY_VALIDATION,
                    })}
                  />
                  <InputGrpCurrency
                    name={`employmentItems[${index}].empEndPay`}
                    defaultValue={item.empEndPay}
                    label="ENDING PAY"
                    groupClass="gc12"
                    errors={errors.empEndPay}
                    setValue={setValue}
                    ref={register({
                      CURRENCY_VALIDATION,
                    })}
                  />
                </div>
                <div className="d-flex flex-column">
                  <div className="dm-card-subtitle2">
                    Reason for Leaving</div>
                  <TextareaGrp
                    name={`employmentItems[${index}].reasonForLeave`}
                    defaultValue={item.reasonForLeave}
                    groupClass="gc12"
                    ref={register()} />
                </div>
              </div>

              <div className="d-flex flex-column  flex-grow-1">
                <div className="dm-card-subtitle2 mb-2">Supervisor</div>
                <hr className="dm-panel-hr" />
                <div className="d-flex flex-row">

                  <InputGrp
                    name={`employmentItems[${index}].supervisorTitle`}
                    defaultValue={item.supervisorTitle}
                    label="TITLE"
                    groupClass="gc12"
                    ref={register()} />
                </div>
                <div className="d-flex flex-row">
                  <InputGrp
                    name={`employmentItems[${index}].supervisorFirstName`}
                    defaultValue={item.supervisorFirstName}
                    label="FIRST NAME"
                    groupClass="gc12"
                    ref={register()} />
                  <InputGrp
                    name={`employmentItems[${index}].supervisorMidName`}
                    defaultValue={item.supervisorMidName}
                    label="MIDDLE INITIAL"
                    groupClass="gc12"
                    ref={register()} />
                  <InputGrp
                    name={`employmentItems[${index}].supervisorLastName`}
                    defaultValue={item.supervisorLastName}
                    label="LAST NAME"
                    groupClass="gc12"
                    ref={register()} />
                </div>
                <div className="d-flex flex-row">
                  <InputGrp
                    name={`employmentItems[${index}].supervisorPhone`}
                    defaultValue={item.supervisorPhone}
                    label="PHONE NUMBER"
                    groupClass="gc12"
                    onChange={(e: any) => {
                      const { value } = e?.target;
                      e.target.value = formatPhone(value);
                    }}
                    ref={register({
                      pattern: {
                        value: PHONE_VALIDATION,
                        message: 'Invalid phone number',
                      },
                    })} />
                  <InputGrp
                    name={`employmentItems[${index}].supervisorPhoneExt`}
                    defaultValue={item.supervisorPhoneExt}
                    label="EXTENSION"
                    groupClass="gc12"
                    ref={register()} />
                </div>
                <div className="d-flex flex-column">
                  <div className="dm-card-subtitle2">Comments</div>
                  <TextareaGrp
                    name={`employmentItems[${index}].comments`}
                    defaultValue={item.comments}
                    groupClass="gc12"
                    ref={register()} />
                </div>
              </div>

            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default EmploymentItem;
