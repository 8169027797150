import { createReducer } from '@reduxjs/toolkit';
import { EmpMyInfoChanges, EmpSelfService } from '../../models';
import { MyInfoChangeLog, MyInfoChangeSelection } from '../../models/MyInfoChangeLog';
import {
  interruptSelfService,
  clearSelectedChanges,
  loadEmployeeSelfService,
  loadMyInfoChanges,
  loadMyInfoChangesHistory,
  storeEmployeeSelfService,
  storeMyInfoAccountMessage,
  storeMyInfoChanges,
  storeMyInfoChangesHistory,
  storeSelectedDirectDepositChange,
  storeSelectedEmployeeChanges,
  storeSelectedTaxChanges,
  updateMyInfoChanges,
} from '../actions';

export interface State {
  loading: boolean;
  employeeSelfService: EmpSelfService,
  myInfoAccountMessage: string,
  myInfoChanges: MyInfoChangeLog | null,
  myInfoChangesHistory: EmpMyInfoChanges,
  selectedChanges: MyInfoChangeSelection
}

const INITIAL_STATE: State = {
  loading: false,
  employeeSelfService: {} as EmpSelfService,
  myInfoAccountMessage: '',
  myInfoChanges: null,
  myInfoChangesHistory: {} as EmpMyInfoChanges,
  selectedChanges: {} as MyInfoChangeSelection,
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadEmployeeSelfService, (state) => {
      state.loading = true;
    })
    .addCase(storeEmployeeSelfService, (state, action) => {
      state.loading = false;
      state.employeeSelfService = action.payload;
    })
    .addCase(storeMyInfoAccountMessage, (state, action) => {
      state.loading = false;
      state.myInfoAccountMessage = action?.payload ?? '';
    })
    .addCase(storeMyInfoChanges, (state, action) => {
      state.loading = false;
      state.myInfoChanges = action.payload;
    })
    .addCase(storeMyInfoChangesHistory, (state, action) => {
      state.loading = false;
      state.myInfoChangesHistory = action.payload;
    })
    .addCase(storeSelectedEmployeeChanges, (state, action) => {
      state.loading = false;
      state.selectedChanges = {
        ...state.selectedChanges,
        employeeChanges: action.payload,
      };
    })
    .addCase(storeSelectedTaxChanges, (state, action) => {
      state.loading = false;
      state.selectedChanges = {
        ...state.selectedChanges,
        taxChanges: action.payload,
      };
    })
    .addCase(storeSelectedDirectDepositChange, (state, action) => {
      state.loading = false;
      state.selectedChanges = {
        ...state.selectedChanges,
        directDepositChange: action.payload,
      };
    })
    .addCase(clearSelectedChanges, (state, action) => {
      state.selectedChanges = action.payload;
    })
    .addCase(loadMyInfoChanges, (state) => {
      state.loading = true;
    })
    .addCase(loadMyInfoChangesHistory, (state) => {
      state.loading = true;
    })
    .addCase(updateMyInfoChanges, (state) => {
      state.loading = true;
    })
    .addCase(interruptSelfService, (state) => {
      state.loading = false;
    });
});
