import React, { useEffect, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import {
  CellFocusedEvent,
  ColDef,
  GridApi,
  ColumnApi,
  GridOptions,
  GridReadyEvent,
  RowNode,
  GridSizeChangedEvent,
} from '@ag-grid-enterprise/all-modules';
import { OutstandingTimeOffRequest, PendingTimeOffRequest, UnpaidTimeOffRequest } from 'core/models';
import { useDispatch } from 'react-redux';
import { loadEmployee } from 'core/store/actions';
import { storeEmpNos } from 'core/store/actions/employee-information.action';

interface Props {
  requestData: OutstandingTimeOffRequest[] | PendingTimeOffRequest[] | UnpaidTimeOffRequest[];
  columnDefs: ColDef[];
  type: string;
  update?: (request: OutstandingTimeOffRequest | UnpaidTimeOffRequest) => void;
}

const TimeOffGrid = ({ requestData, columnDefs, type, update }: Props) => {
  const [gridApi, setGridApi] = useState<GridApi | null>();
  const [columnApi, setColumnApi] = useState<ColumnApi | null>();

  const dispatch = useDispatch();

  const baseGridOpts: GridOptions = {
    domLayout: 'autoHeight',
    defaultColDef: {
      autoHeight: true,
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    rowSelection: 'multiple',
    suppressRowClickSelection: type === 'pending',
    pagination: true,
    paginationPageSize: 20,
    overlayLoadingTemplate:
            '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
  };

  const timeOffGridOpts: GridOptions = {
    ...baseGridOpts,
    columnDefs,
  };

  let selRowIndex = -1;

  const onGridReady = (e: GridReadyEvent) => {
    e.api.setHeaderHeight(50);
    setGridApi(e.api);
    setColumnApi(e.columnApi);
  };

  const onGridSizeChanged = (e: GridSizeChangedEvent) => {
    e.api.sizeColumnsToFit();
  };

  const onCellFocused = (e: CellFocusedEvent) => {
    if (!(gridApi && update) || selRowIndex === e.rowIndex) return;
    
    selRowIndex = e.rowIndex || 0;
    const row: RowNode | undefined = gridApi.getDisplayedRowAtIndex(selRowIndex);
    
    if (!row) return console.error('Error: no row found');
    
    dispatch(loadEmployee(row.data.protectedEmpNo));
    dispatch(storeEmpNos([row.data.empNo]));
    update(row.data);
    row.setSelected(true, true);
  };

  useEffect(() => {
    if (type === 'outstanding' && gridApi && columnApi) {
      const firstCol = columnApi.getAllDisplayedColumns()[0];
      gridApi.ensureColumnVisible(firstCol);
      gridApi.setFocusedCell(0, firstCol);
    }
  }, [gridApi, columnApi]);

  return (
    <div className="table-wrapper-wrapper ag-theme-balham mb-2">
      <AgGridReact
        rowData={requestData}
        gridOptions={timeOffGridOpts}
        onGridReady={onGridReady}
        onGridSizeChanged={onGridSizeChanged}
        onCellFocused={onCellFocused}
      />
    </div>
  );
};

export default TimeOffGrid;
