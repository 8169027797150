import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContractorReportService } from 'core/services';
import { ContractorOverviewState } from 'core/store/slices/contractor-reports/overview.slice';
import DateObject from 'react-date-object';

export const getContractorOverviewData = createAsyncThunk(
  'reports/getContractorOverviewData',
  async (clientNo, { getState }) => {
    const { contractorReportsOverview } = getState() as { contractorReportsOverview: ContractorOverviewState };

    if (!contractorReportsOverview?.endDate || !contractorReportsOverview?.beginDate) {
      return;
    }

    const response = await ContractorReportService.getContractorOverview({
      groupBy: contractorReportsOverview.summarizedBy,
      showDetail: contractorReportsOverview.showDetail,
      beginDate: new DateObject(new Date(contractorReportsOverview?.beginDate)).format('YYYY-MM-DD'),
      endDate: new DateObject(new Date(contractorReportsOverview?.endDate)).format('YYYY-MM-DD'),
      byWeekEnd: contractorReportsOverview.byWeekend,
    });

    return response?.data || response;
  },
);

export const downloadContractorOverviewData = createAsyncThunk(
  'reports/postContractorOverviewData',
  async (clientNo, { getState }) => {
    const { contractorReportsOverview } = getState() as { contractorReportsOverview: ContractorOverviewState };

    if (!contractorReportsOverview?.endDate || !contractorReportsOverview?.beginDate) {
      return;
    }

    const response = await ContractorReportService.postContractorOverview({
      groupBy: contractorReportsOverview.summarizedBy,
      showDetail: contractorReportsOverview.showDetail,
      beginDate: new DateObject(new Date(contractorReportsOverview?.beginDate)).format('YYYY-MM-DD'),
      endDate: new DateObject(new Date(contractorReportsOverview?.endDate)).format('YYYY-MM-DD'),
      byWeekEnd: contractorReportsOverview.byWeekend,
      fileType: contractorReportsOverview.fileType,
    });

    return response?.data || response;
  },
);

