import Icon from 'core/components/shared/Icon';
import { CheckboxGrp } from 'core/components/form-controls';
import { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import React, { useFieldArray } from 'react-hook-form';

interface PageFieldProps {
  fieldIndex: number;
  formMethods: any;
}
  
const PageFieldAnswer = ({ fieldIndex, formMethods }: PageFieldProps) => {
    
  const [isRAOpen, setIsRAOpen] = useState(false);
    
  const { fields } = useFieldArray({
    control: formMethods.control,
    name: `personalInformation.pageFields[${fieldIndex}].answers`,
    keyName: 'id',
  });
  
  return (
    <div className="d-flex flex-row">        
      <Accordion
        defaultActiveKey="0"
        className=" flex-grow-1"
      >
        <Card>
          <Card.Header className="p-3">
            <Accordion.Toggle
              eventKey={'4'}
              style={{
                background: 'transparent',
                border: 'none',
              }}
              onClick={() => {
                setIsRAOpen(!isRAOpen);
              }}
            >
              <Icon
                name={isRAOpen ? 'chevron-up' : 'chevron-down'}
                className="mr-1"
              />
              Predefined Answers
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={'4'}>
            <Card.Body
              className="p-3"
              style={{ gap: '4rem' }}
            >
              <div className="d-flex flex-column col-6">
                <div className="d-flex flex-row">
                  <div className="d-flex justify-content-start">
  
                  </div>
                  <div className="d-flex justify-content-end flex-grow-1 mt-2">
                    <div
                      className="d-flex flex-column text-center"
                      style={{ width: 75 }}
                    >
                      <span><u>Visible</u></span>
                    </div>
                    <div
                      className="d-flex flex-column text-center"
                      style={{ width: 75 }}
                    >
                      <span><u>Required</u></span>
                    </div>
                  </div>
                </div>
               
                {fields?.map((field, index) => {                  
                  return (                   
                    <div
                      key={field.id}
                      className="d-flex flex-row"
                    >
                      <input
                        type="hidden"                            
                        name={`personalInformation.pageFields[${fieldIndex}].answers[${index}].answerId`}                           
                        defaultValue={field.answerId}
                        ref={formMethods.register({ valueAsNumber:true })}
                      />
                      <input
                        type="hidden"                            
                        name={`personalInformation.pageFields[${fieldIndex}].answers[${index}].fieldId`}                           
                        defaultValue={field.fieldId}
                        ref={formMethods.register({ valueAsNumber:true })}
                      />
                      <input
                        type="hidden"                            
                        name={`personalInformation.pageFields[${fieldIndex}].answers[${index}].description`}                           
                        defaultValue={field.description}
                        ref={formMethods.register()}
                      />
                      <div className="d-flex justify-content-start">
                        {field.description}
                      </div>
                      <div className="d-flex justify-content-end flex-grow-1">
                        <div
                          className="d-flex flex-column text-center"
                          style={{ width: 75 }}
                        >
                          <CheckboxGrp
                            label=""
                            defaultChecked={field.visible}
                            name={`personalInformation.pageFields[${fieldIndex}].answers[${index}].visible`}  
                            ref={formMethods.register()}
                          />
                        </div>
                        <div
                          className="d-flex flex-column text-center"
                          style={{ width: 75 }}
                        >
                          <CheckboxGrp
                            label=""
                            defaultChecked={field.required}
                            name={`personalInformation.pageFields[${fieldIndex}].answers[${index}].required`}  
                            ref={formMethods.register()}
                          />
                        </div>
                      </div>
                    </div>
                  );                    
                })}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
         
    </div>
  );
};

export default PageFieldAnswer;