import { ofType } from 'redux-observable';
import { catchError, from, map, Observable, switchMap } from 'rxjs';
import {ReferenceFolder} from '../../models/HelpReferenceGuide.model';
import { HelpReferenceGuideService } from '../../services/help-reference-guide.service';
import {
  loadReferenceGuide,
  storeReferenceGuide
} from '../actions/help-reference-guide.action';
import { handleError, handleSuccess } from '../actions/app.action';
import { HttpResponse } from 'core/models';

interface Actions<Type> {
  type: string;
  payload: Type;
}

const loadReferenceGuide$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadReferenceGuide.type),
    switchMap(() => {
      return from(HelpReferenceGuideService.getReferenceGuide()).pipe(
        map((res: any) => {return res.data;}),
        map((res: ReferenceFolder) => {return storeReferenceGuide(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

export const epics: any[] = [
    loadReferenceGuide$,
];
