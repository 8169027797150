import Icon from 'core/components/shared/Icon';
import PanelHeader from 'core/components/shared/PanelHeader';
import { InterviewProcess } from 'core/models';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import InterviewProcessModal from './InterviewProcess.modal';
import { Row, Col, Button } from 'react-bootstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi, GridOptions, ICellRendererParams } from '@ag-grid-community/core';
import { useDispatch } from 'react-redux';
import { deleteInterviewProcess, getInterviewProcesses, getInterviewTemplates } from 'core/store/slices/applicantTracking.slice';
import { CheckboxGrpInLine } from 'core/components/form-controls';

const InterviewProcessPage = () => {
  
  const dispatch = useDispatch();
  const [interviewProcesses, interviewProcess] = useAppSelector(
    (state) => {
      return [
        state.applicantTracking.interviewProcesses,
        state.applicantTracking.interviewProcess,
      ];
    },
  );

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [rowData, setRowData] = useState<InterviewProcess[]>([]);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [selectedProcess, setSelectedProcess] = useState<InterviewProcess>();

  useEffect(()=>{
    dispatch(getInterviewProcesses());
    dispatch(getInterviewTemplates());
  }, []);
  
  useEffect(() => {
    if (!interviewProcess) return;
    setSelectedProcess(interviewProcess);
  }, [interviewProcess]);

  useEffect(()=>{
    interviewProcesses && setRowData(interviewProcesses);
  }, [interviewProcesses]);
  
  const onAdd = () => {
    setSelectedProcess(undefined);
    setShowDetailModal(true);
  };

  const openDetailModal = (params: ICellRendererParams) =>{
    setSelectedProcess(params.data);
    setShowDetailModal(true);
  };
  
  const onDeleteProcess = (params: ICellRendererParams) =>{
    dispatch(deleteInterviewProcess(params.data.interviewProcessId));
  };

  const columns = useMemo<ColDef[]>(
    () => {
      return [
        {
          field: 'title',
          headerName: 'Process Name',
          cellStyle: { textAlign: 'left' },
          cellRenderer: (params: ICellRendererParams) => {
            if (params.data.isDefault) {
              return (<div style={{ width: 250 }}>{params.data.title}</div>);
            } else {
              return (
                <button
                  type="button"
                  className="btn btn-link"
                  style={{ textAlign:'left' }}
                  onClick={() => { return openDetailModal(params); }
                  }
                >
                  {params.data.title}
                </button>             
              );
            }
          },
          width: 250,
        },
        {
          field: 'description',
          headerName: 'Process Description',         
          width: 750,         
        },  
        {
          field: 'step',
          headerName: 'Steps',      
          cellRenderer: (params: ICellRendererParams) => {
            return (<div>
              {params.data.processTemplates?.length}
            </div>);
          }, 
          width: 75,         
        },  
        {
          field: 'delete',
          cellRenderer: (params: ICellRendererParams) => {
            if (params.data.isDefault) {
              return (<span></span>);
            } else {               
              return (
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => { return onDeleteProcess(params); }
                  }
                >
                  <Icon
                    name="minus-circle"
                    className="fa-minus-circle"
                  />
                </button>             
              );
            }
          },
          width: 70,
        },     
      ];
    },
    [],
  );

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    stopEditingWhenCellsLoseFocus:true,
    undoRedoCellEditing:true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onHideChange  = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      setRowData([ ...interviewProcesses.filter((i:InterviewProcess) => {return  i.isDefault !== true;}) ]);
    } else {
      setRowData([ ...interviewProcesses ]);
    }    
  };

  return (
    <Row className="no-gutters p-0">
      <Col className="mt-1 shadow p-3 border">
        <PanelHeader
          title="Interview Process"
          iconName="compass-drafting"
          hideDefault={true}
          onHideChange={(e:any) => {return onHideChange(e);}}
        > 
          <button
            type="button"
            className="btn btn-link dm-grid-action-title pb-0 mr-2"
            onClick={onAdd}
          >
            Add New Interview Process <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <div className="table-wrapper-wrapper ag-theme-balham mt-3">
          <AgGridReact
            gridOptions={gridOptions}
            rowData={rowData}
            columnDefs={columns}
            onGridReady={onGridReady}
          />
        </div>
        {showDetailModal && (
          <InterviewProcessModal
            item={selectedProcess}
            show={showDetailModal}
            onHide={() => { return setShowDetailModal(false); } }            
          />
        )}
      </Col>
    </Row>
  );
};

export default InterviewProcessPage;
