import { AgGridReact } from '@ag-grid-community/react';
import {
  AllModules,
  CellValueChangedEvent,
  ColDef,
  ColumnApi,
  GetMainMenuItemsParams,
  GridApi,
  GridOptions,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
} from '@ag-grid-enterprise/all-modules';
import Modal from 'core/components/modals/Modal';
import Icon from 'core/components/shared/Icon';
import uniq from 'lodash/uniq';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { TextareaGrp } from '../../core/components/form-controls/TextareaGrp';
import { EmployeeUploadFields, EmployeeUploadMap } from '../../core/models';
import {
  createEmpUploadClientFile,
  createEmpUploadField,
  deleteEmpUploadFields,
  updateEmpUploadClientFile,
  updateEmpUploadField,
} from '../../core/store/actions';
import { getDeductionFrequencies } from '../../core/store/selectors';
import {
  getEmpUploadFieldMap,
  getEmpUploadFields,
  getEmpUploadTables,
  getSelectedEmpUploadClientFile,
} from '../../core/store/selectors/employee-upload.selector';
import {
  agInputNumberEditor,
  agSelectEditor,
} from '../../utilities/ag-grid-editors';
import { agLookupRenderer } from '../../utilities/ag-grid-renderers';
import AGDeleteHeaderComponent from '../../utilities/ag-grid-renderers/AGDeleteHeaderComponent';
import AGEditButtonRendererComponent from '../../utilities/ag-grid-renderers/AGEditButtonRendererComponent';
import CheckboxRenderer from '../../utilities/ag-grid-renderers/CheckboxRenderer';
import InvalidColumnNumbers from './InvalidColumNumber.modal';
import TranslationsModal from './Translations.modal';
import { buildHeaders, generateColumnDef, buildParsedFile } from 'utilities/utilities';

const ignoreField: EmployeeUploadFields = {
  fieldId: 138,
  tableName: 'IGNORE',
  fieldName: 'IGNORE',
  displayTableName: 'Ignore Table',
  displayFieldName: 'Ignore Field',
  visiblePosition: 0,
  visible: true,
  mandatory: false,
};

const numericValueSetter = (params: ValueSetterParams) => {
  if (+params.oldValue !== +params.newValue) {
    params.data[params.colDef.field ?? ''] = +params.newValue;
    return true;
  }
  return false;
};

const onCellValueChanged = (e: CellValueChangedEvent) => {
  e.oldValue !== e.newValue && (e.data.modified = true);
};

const gridOptions2: GridOptions = {
  defaultColDef: {
    resizable: true,
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
  stopEditingWhenCellsLoseFocus: true,
  rowSelection: 'single',
};
interface DataField extends EmployeeUploadMap {
  field: EmployeeUploadFields;
  modified: boolean;
  delete: boolean;
}

type PropTypes = {
  csvFile: any;
  fileRows: string[],
  show: boolean;
  headerStartingLine: number;
  onHide: () => void;
  prev: () => void;
};

const UploadEmpStep2Modal: React.FC<PropTypes> = ({
  csvFile,
  fileRows,
  show,
  headerStartingLine,
  onHide,
  prev,
}) => {
  const dispatch = useDispatch();
  const fieldMap = useSelector(getEmpUploadFieldMap);

  const [rowData, setRowData] = useState<EmployeeUploadMap[]>([]);
  const [gridApi1, setGridApi1] = useState<GridApi | null>(null);
  const [gridApi2, setGridApi2] = useState<GridApi | null>(null);
  const [gridColumnApi1, setGridColumnApi1] = useState<ColumnApi | null>(
    null,
  );
  const [, setGridColumnApi2] = useState<ColumnApi | null>(null);
  const [grid2Data, setGrid2Data] = useState<any[]>([]);
  const [showTranslations, setShowTranslations] = useState(false);
  const [showInvalidColNo, setShowInvalidColNo] = useState(false);
  const [selectedField, setSelectedField] = useState<DataField | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const buildCol1Defs = (
    tableOpts: any,
    fieldOpts: any,
    fieldMap: any,
    dedFreqOpts: any,
    onTranslations: any,
  ): ColDef[] => {
    return [
      {
        field: 'tableName',
        headerName: 'Map to Table',
        editable: false,
        cellEditorParams: (params: any) => {
          return {
            options: tableOpts,
            labelField: 'description',
            valueField: 'id',
          };
        },
        cellRendererParams: (params: any) => {
          return {
              options: tableOpts,
              labelField: 'description',
              valueField: 'id',
          };
        },
        cellEditor: 'selectEditor',
        cellRenderer: 'selectEditor',
        valueGetter: (params: ValueGetterParams) => {
          return params.data.field.tableName;
        },
        valueSetter: (params: ValueSetterParams) => {
          if (params.oldValue !== params.newValue) {
            params.data.field = fieldOpts[params.newValue][0];
            return true;
          }
          return false;
        },
        width: 140,
        onCellClicked:(() => setIsDirty(true)),
      },
      {
        field: 'fieldId',
        headerName: 'Map to Field',
        editable: false,
        cellEditorParams: (params: any) => {
          return {
            options: params.data.field?.tableName
              ? fieldOpts[params.data.field.tableName]
              : [],
            labelField: 'fieldName',
            valueField: 'fieldId',
          };
        },
        cellEditor: 'selectEditor',
        cellRendererParams: (params: any) => {
          return {
            options: params.data.field?.tableName
              ? fieldOpts[params.data.field.tableName]
              : [],
            labelField: 'fieldName',
            valueField: 'fieldId',
          };
        },
        cellRenderer: 'selectEditor',
        valueGetter: (params: ValueGetterParams) => { return params.data.field?.fieldId ?? 0; },
        valueSetter: (params: ValueSetterParams) => {
          if (+params.oldValue !== +params.newValue) {
            params.data.field = fieldMap[+params.newValue];
            params.data.fieldId = +params.newValue;
            return true;
          }
          return false;
        },
        width: 210,
        onCellClicked:(() => setIsDirty(true)),
      },
      {
        field: 'startPosition',
        headerName: 'Column No.',
        editable: true,
        cellEditor: 'inputNumberEditor',
        valueSetter: numericValueSetter,
        width: 120,
        onCellClicked:(() => setIsDirty(true)),
      },
      {
        field: 'fieldOrdinal',
        headerName: 'Column No.',
        editable: true,
        sort: 'asc',
        cellEditor: 'inputNumberEditor',
        valueSetter: numericValueSetter,
        width: 120,
        onCellClicked:(() => setIsDirty(true)),
      },
      {
        field: 'length',
        headerName: 'Length',
        editable: true,
        cellEditor: 'inputNumberEditor',
        valueSetter: numericValueSetter,
        width: 90,
        onCellClicked:(() => setIsDirty(true)),
      },
      {
        field: 'decimals',
        headerName: 'Decimals',
        editable: true,
        cellEditor: 'inputNumberEditor',
        valueSetter: numericValueSetter,
        width: 100,
        onCellClicked:(() => setIsDirty(true)),
      },
      {
        field: 'dedFreq',
        headerName: 'Ded. Freq',
        editable: (params: any) => { return params.data.field.fieldName === 'DedNo'; },
        cellEditorParams: { options: dedFreqOpts },
        cellEditor: 'selectEditor',
        cellRendererParams: { options: dedFreqOpts },
        cellRenderer: 'lookupRenderer',
        cellClassRules: {
          'ag-cell-disabled': (params: any) => { return params.data.field.fieldName !== 'DedNo'; },
          'ag-cell-enabled': (params: any) => { return params.data.field.fieldName === 'DedNo'; },
        },
        width: 120,
        onCellClicked:(() => setIsDirty(true)),
      },
      {
        field: 'translate',
        headerName: 'Translate',
        editable: false,
        cellRendererParams: { clickHandler: onTranslations },
        cellRenderer: 'editButtonRendererComponent',
        width: 110,
      },
      {
        field: 'delete',
        headerComponentFramework: AGDeleteHeaderComponent,
        editable: false,
        cellRenderer: 'checkBoxRenderer',
        cellEditor: 'checkboxEditor',
        cellStyle: { paddingLeft: '16px' },
        width: 45,
      },
    ];
  };
  

  const gridOptions1: GridOptions = {
    defaultColDef: {
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    components: {
      inputNumberEditor: agInputNumberEditor,
      selectEditor: agSelectEditor,
      lookupRenderer: agLookupRenderer,
    },
    frameworkComponents: {
      editButtonRendererComponent: AGEditButtonRendererComponent,
      checkBoxRenderer: CheckboxRenderer,
    },
    stopEditingWhenCellsLoseFocus: true,
    rowSelection: 'single',
  };

  const empUploadClientFile = useSelector(getSelectedEmpUploadClientFile);

  const tableOpts = useSelector(getEmpUploadTables);
  const fieldOpts = useSelector(getEmpUploadFields);
  const dedFreqOpts = useSelector(getDeductionFrequencies);

  const initalizeFields = () => {
    setRowData(
      empUploadClientFile?.fields?.map((f: EmployeeUploadMap) => {
        return {
          ...f,
          field: { ...fieldMap[f.fieldId] }, // @ts-ignore
          modified: false,
          delete: false,
        };
      }),
    );
  }

  useEffect(() => {
    initalizeFields();
  }, [empUploadClientFile]);

  const onTranslations = (rowData: any) => {
    setSelectedField(rowData);
    setShowTranslations(true);
  };

  useEffect(() => {
    gridApi1 &&
      gridApi1.setColumnDefs(
        buildCol1Defs(
          tableOpts,
          fieldOpts,
          fieldMap,
          dedFreqOpts,
          onTranslations,
        ),
      );
  }, [gridApi1]);

  useEffect(() => {
    if (gridColumnApi1) {
      gridColumnApi1?.setColumnVisible(
        'fieldOrdinal',
        empUploadClientFile?.style === 'D' ? true : false,
      );

      gridColumnApi1?.setColumnVisible(
        'startPosition',
        empUploadClientFile?.style === 'D' ? false : true,
      );
      gridColumnApi1?.setColumnVisible(
        'decimals',
        empUploadClientFile?.style === 'D' ? false : true,
      );
      gridColumnApi1?.setColumnVisible(
        'length',
        empUploadClientFile?.style === 'D' ? false : true,
      );
    }
  }, [gridColumnApi1]);

  useEffect(() => {
    if (gridApi2) {
      const fileRowsCopy = structuredClone(fileRows).filter(x => x);
      const headers = buildHeaders(headerStartingLine, fileRowsCopy);
      const colDefs = generateColumnDef(headerStartingLine, headers);
      const parsedFile = buildParsedFile(headerStartingLine, fileRowsCopy, headers);
      gridApi2.setColumnDefs(colDefs);
      setGrid2Data(parsedFile);
    }
  }, [gridApi2, csvFile]);

  const onGridReady1 = (params: any) => {
    setGridApi1(params.api);
    setGridColumnApi1(params.columnApi);
  };

  const onGridReady2 = (params: any) => {
    setGridApi2(params.api);
    setGridColumnApi2(params.columnApi);
  };

  const closeModal = (isDirty: boolean) => {
    if(isDirty) {
      if(confirm('You have unsaved changes. Would you like to save the changes?')) onSave();
      else initalizeFields();
    }
    setIsDirty(false);
    onHide();
  };

  const onPrev = () => {
    closeModal(isDirty);
    prev();
  };

  const getNextColumnNo = () : number => {
    if (empUploadClientFile) {
      let usedOrdinals: number[] = [];
      gridApi1?.forEachNode((node: RowNode) => { return usedOrdinals.push(node.data.fieldOrdinal); },
      );
      usedOrdinals = uniq(usedOrdinals).sort((a, b) => {
        return a - b;
      });

      return (usedOrdinals?.slice(-1)[0] + 1) ?? 0;
    }

    return 0;
  }

  const onAddField = (fieldOrdinal: number, ignore: boolean) => {
    const newMap = new EmployeeUploadMap(
      empUploadClientFile ? empUploadClientFile.clientFileId : 0,
      0,
    );
    if (empUploadClientFile?.style === 'D') {
      if (fieldOrdinal) {
        newMap.fieldOrdinal = fieldOrdinal;
      } else {
        let maxOrdinal = 0;
        rowData.forEach(
          (f: EmployeeUploadMap) => {
            return f.fieldOrdinal > maxOrdinal &&
              (maxOrdinal = f.fieldOrdinal);
          },
        );
        newMap.fieldOrdinal = maxOrdinal;
      }
    }
    if (ignore) {
      newMap.fieldId = ignoreField.fieldId;
    }
    setRowData((prev) => {
      return [
        ...prev,
        {
          ...newMap,
          field: ignore ? ignoreField : new EmployeeUploadFields(),
          modified: ignore ? true : false,
          delete: false,
        },
      ];
    });
  };

  const getMainMenuItems = (params: GetMainMenuItemsParams) => {
    const colDef = params.column.getColDef() as any;
    const fieldOrdinal = colDef ? +colDef.fieldOrdinal : 0;

    const menuItems = [];
    menuItems.push({
      name: 'Add Column to Map',
      action: () => { return onAddField(fieldOrdinal, false); },
    });
    menuItems.push({
      name: 'Ignore Column',
      action: () => { return onAddField(fieldOrdinal, true); },
    });
    menuItems.push({
      name: 'Close Menu',
      action: () => { return gridApi2?.hidePopupMenu(); },
    });
    return menuItems;
  };

  const onDeleteSelected = () => {
    if (!confirm('Delete selected Fields? This action cannot be undone.')) return;
    if (gridApi1) {
      const fieldsToDelete: EmployeeUploadMap[] = [];

      gridApi1?.forEachNode((node: RowNode) => {
        node?.data?.delete &&
          node?.data?.mapId &&
          fieldsToDelete.push(node?.data);
      });
      setRowData((prev) => {
        const newNodes: any[] = [];
        gridApi1?.forEachNode((node: RowNode) => {
          !node?.data?.delete && newNodes.push(node?.data);
        });
        return newNodes;
      });
      fieldsToDelete.length &&
        dispatch(deleteEmpUploadFields(fieldsToDelete));
    }
  };

  const onSave = () => {
    if (empUploadClientFile) {
      let usedOrdinals: number[] = [];
      gridApi1?.forEachNode((node: RowNode) => { return usedOrdinals.push(node.data.fieldOrdinal); },
      );
      usedOrdinals = uniq(usedOrdinals).sort();

      let valid = true;
      valid =
        valid &&
        usedOrdinals.every((n: number, i: number) => { return n === i; });

      if (!valid) {
        setShowInvalidColNo(true);
        return;
      }

      empUploadClientFile.fields = [];
      gridApi1?.forEachNode((node: RowNode) => {
        const newEmpUploadField = new EmployeeUploadMap(
          empUploadClientFile.clientFileId,
          node.data.mapId,
          {
            ...node.data,
            fieldId: node.data.field.fieldId,
            clientFileId: empUploadClientFile.clientFileId,
          },
        );
        empUploadClientFile.fields.push(newEmpUploadField);
        // If we are saving a new ClientFile we can save the fields in the ClientFile.
        if (empUploadClientFile.clientFileId) {
          if (!node.data.mapId) {
            dispatch(createEmpUploadField(newEmpUploadField));
          } else if (node.data.modified) {
            dispatch(updateEmpUploadField(newEmpUploadField));
          }
        }
      });

      empUploadClientFile.clientFileId
        ? dispatch(
          updateEmpUploadClientFile({
            ...empUploadClientFile,
            fields: [],
          }),
        )
        : dispatch(createEmpUploadClientFile(empUploadClientFile));
    }
  };

  return (
    <div onClick={(e) => { return closeModal(isDirty) }}>
      <Modal
        show={show}
        onHide={() => closeModal(isDirty)}
        size="lg"
        title="Step 2 of 2: Upload Map"
      >
        <div
          className="d-flex justify-content-end mr-3 mb-1"
          onClick={() => { return onAddField(getNextColumnNo(), false); }}
        >
          <button
            type="button"
            className="btn btn-link dm-grid-action-title p-0 mr-2"
          >
            Add New Field{' '}
            <Icon name="plus-circle"
              className="fa-plus-circle" />
          </button>
        </div>
        <div className="row">
          <div
            className="col-12 table-wrapper-wrapper ag-theme-balham"
            style={{
              maxHeight: '300px',
              display: 'block',
            }}
          >
            <div style={{ height: '300px' }}>
            <AgGridReact
              gridOptions={gridOptions1}
              rowData={rowData}
              modules={AllModules}
              onGridReady={onGridReady1}
              onCellValueChanged={onCellValueChanged}
            />
            </div>
          </div>
          <div className="col-12 text-right">
            <button
              type="button"
              className="btn btn-link pb-0"
              onClick={onDeleteSelected}
            >
              Delete Selected Items
              <Icon name="minus-circle"
                className="fa-minus-circle" />
            </button>
          </div>
        </div>
        <div className="row mt-3">
          {empUploadClientFile?.style === 'F' ? (
            <div className="col-12">
              <TextareaGrp
                name="fileBody"
                label="FIXED"
                value={csvFile}
                style={{ fontFamily: 'monospace' }}
              />
            </div>
          ) : (
            <div
              className="col-12 table-wrapper-wrapper ag-theme-balham"
              style={{
                maxHeight: '300px'
              }}
            >
              <div style={{ height: '300px' }}>
              <AgGridReact
                gridOptions={gridOptions2}
                rowData={grid2Data}
                modules={AllModules}
                getMainMenuItems={getMainMenuItems}
                onGridReady={onGridReady2}
              />
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <button
              type="button"
              className="btn btn-link"
              style={{ fontSize: '14px' }}
              onClick={onPrev}
            >
              <Icon name="chevron-left" /> Previous
            </button>
            <button
              type="button"
              className="btn btn-link"
              style={{ fontSize: '14px' }}
              disabled={true}
            >
              Next <Icon name="chevron-right" />
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <Button className="orange-button"
              onClick={onSave}>
              Save
            </Button>
          </div>
        </div>
      </Modal>
      {show && selectedField && (
        <TranslationsModal
          clientFileId={selectedField.clientFileId}
          mapId={selectedField.mapId}
          translations={selectedField.translations}
          show={showTranslations}
          onHide={() => { return setShowTranslations(false); }}
        />
      )}
      {showInvalidColNo && (
        <InvalidColumnNumbers
          show={showInvalidColNo}
          onHide={() => { return setShowInvalidColNo(false); }}
        />
      )}
    </div>
  );
};
export default UploadEmpStep2Modal;
