import React, { useEffect, useState } from 'react';
import Modal from 'core/components/modals/Modal';
import DateObject from 'react-date-object';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { Note } from 'core/models';
import { postApplicantNotes } from 'core/store/actions/applicant-tracking.action';
import { Label } from 'core/components/form-controls';
import NoteSavedModal from './NoteSavedModal';

type Props = {
  show: boolean;
  onHide: () => void;
  notes: Note[];
  applicantId: number;
};

const AddNoteModal = ({
  show,
  onHide,
  applicantId,
  notes,
}: Props) => {
  const [isNoteSaved, setIsNoteSaved] = useState(false);
  const userClients = useAppSelector((state) => { return state.auth.userAccess; });
  const isAddingNote = useAppSelector((state) => {
    return state.applicantTrackingV2.isAddingNote;
  });
  const appDispatch = useAppDispatch();
  const { register, handleSubmit, errors, reset } = useForm<any>({
    defaultValues: {
      noteDescription: '',
    },
  });

  const onSubmit = (data: any) => {
    if (isAddingNote) {
      return;
    }

    appDispatch(postApplicantNotes({
      applicantId,
      notes: [
        ...notes,
        {
          noteId: 0,
          applicantId,
          noteDescription: data?.noteDescription,
          createDate: new Date(),
          createUser: userClients?.dmUserName,
        },
      ],
    })).then(() => {
      onHide();
      setIsNoteSaved(true);
    });
  };

  useEffect(() => {
    reset();
  }, []);

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={onHide}
        title="Add New Note"
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="department-option-form"
        >
          <div>
            <Label
              hasError={!!errors?.noteDescription?.message}
              text="Description"
              required
            />
            <textarea
              name="noteDescription"
              ref={register({ required: 'Description is Required' })}
              className="w-100 p-2"
              style={{
                height: 100,
              }}
            />
            {!!errors?.noteDescription?.message && (
              <small className="text-danger">{errors?.noteDescription?.message}</small>
            )}
          </div>
          <button
            type="submit"
            className="btn btn-primary orange-button mt-2"
            disabled={isAddingNote}
          >
            Save
          </button>
        </form>
      </Modal>
      <NoteSavedModal
        show={isNoteSaved}
        onHide={() => {
          setIsNoteSaved(false);
        }}
      />
    </>
  );
};

export default AddNoteModal;