import React, { useState } from 'react';
import Modal from 'core/components/modals/Modal';
import { RadioGrpStacked } from 'core/components/form-controls';
import { getDeferredCompCatchUpMethod } from 'core/store/selectors';
import { useSelector } from 'react-redux';
import { FormMethods } from 'core/components/form-controls/types';

type Props = {
  show: boolean;
  onHide: () => void;
  setValue: FormMethods['setValue'];
};

const CatchUpMethodNoticeModal = ({ show, onHide, setValue }: Props) => {
  const deferredCompCatchUpMethodOpts = useSelector(getDeferredCompCatchUpMethod);
  const radioGrpOpts = deferredCompCatchUpMethodOpts.map((opt) => ({ value: opt.id, label: opt.description }));
  
  const [catchUpMethod, setCatchUpMethod] = useState<number>(+(radioGrpOpts?.[0]?.value ?? -1));
  
  const updateCatchUpMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCatchUpMethod(+e.target.value);
  };
  
  const onSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setValue('catchUpMethodId', catchUpMethod, { shouldDirty: true });
    e.stopPropagation();
    onHide();
  };
  
  return (
    <Modal
      title="Please select a catch up method"
      show={show}
      onHide={onHide}
      backdrop="static"
    >
      <RadioGrpStacked
        name="selectCatchUpMethod"
        radioOptions={radioGrpOpts}
        defaultChecked={radioGrpOpts[0]}
        onChange={updateCatchUpMethod}
        stacked
      />
      <div className="d-flex w-100 justify-content-end">
        <button
          className="orange-button-sm"  
          onClick={onSave}
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export default CatchUpMethodNoticeModal;