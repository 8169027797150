import { ICellEditorParams } from '@ag-grid-community/core';

export function agLinkEditor(): any {}

agLinkEditor.prototype.init = function (params: ICellEditorParams) {
  this.link = document.createElement('a');
  this.link.src = params.value;
};

agLinkEditor.prototype.getGui = function () {
  return this.link;
};

agLinkEditor.prototype.getValue = function () {
  return this.link.value;
};

agLinkEditor.prototype.destroy = function () {};

agLinkEditor.prototype.isPopup = function () {
  return false;
};
