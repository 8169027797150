import React, { MutableRefObject, useCallback, useMemo, useRef } from 'react';
import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';
import {
  AllModules,
  ColDef,
  GridOptions,
} from '@ag-grid-enterprise/all-modules';
import { agCurrencyRenderer } from '../../../../utilities/ag-grid-renderers';
import { DeductionPayrollCheckResult } from '../../../models';

//#region AgGrid
const columns: ColDef[] = [
  {
    field: 'deductionNumber',
    headerName: 'Ded No',
    sortable: true,
    width: 70,
  },
  {
    field: 'description',
    headerName: 'Description',
    sortable: true,
    width: 100,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    sortable: true,
    width: 80,
    cellStyle: { 'text-align': 'right' },
    cellRenderer: 'currencyRenderer',
    aggFunc: 'sum',
  },
  {
    field: 'routingNumber',
    headerName: 'Routing No',
    sortable: true,
    width: 95,
  },
  {
    field: 'accountNumber',
    headerName: 'Bank Account No',
    sortable: true,
    width: 130,
  },
  {
    field: 'garnishmentCategoryDescription',
    headerName: 'Category',
    sortable: true,
    width: 100,
  },
  {
    field: 'caseId',
    headerName: 'Case ID',
    sortable: true,
    width: 70,
  },
  {
    field: 'entityDescription',
    headerName: 'Entity',
    sortable: true,
    width: 80,
  },
];

const gridOptions: GridOptions = {
  columnDefs: columns,
  domLayout: 'autoHeight',
  groupIncludeFooter: true,
  groupIncludeTotalFooter: true,
  defaultColDef: {
    suppressMenu: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
  components: {
    currencyRenderer: agCurrencyRenderer,
  },
  getRowClass: (params) => {
    if (params.node.footer) {
      return 'font-weight-bold';
    }
  },
};

type PropTypes = {
  items: DeductionPayrollCheckResult[];
};

const CCDeductions: React.FC<PropTypes> = ({ items }) => {
  const [gridOptionsMemo] = useMemo(() => {return [gridOptions];}, []) as [GridOptions];
  const gridRef = useRef() as MutableRefObject<AgGridReact>;

  const onFirstDataRendered = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);
  return (
    <div>
      <div className="mb-5">
        <div className="table-wrapper-wrapper ag-theme-balham">
          <AgGridReact
            ref={gridRef}
            gridOptions={gridOptionsMemo}
            rowData={items}
            modules={AllModules}
            onFirstDataRendered={onFirstDataRendered}
          />
        </div>
      </div>
    </div>
  );
};

export default CCDeductions;
