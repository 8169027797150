import { createReducer } from '@reduxjs/toolkit';
import { ReportType } from '../../models/AccrualReport.model';
import {
  clearReport,
  loadStandardReports,
  storeReportType,
  storeStandardReports,
} from '../actions/accrual-reports.action';

export interface AccrualReportsState {
  loading: boolean;
  report: string;
  reportType: ReportType | null;
}

const INITIAL_STATE: AccrualReportsState = {
  loading: true,
  report: '',
  reportType: null,
};

export const accrualReportsReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadStandardReports, (state) => {
      state.loading = true;
      state.report = '';
    })
    .addCase(storeStandardReports, (state, action) => {
      state.loading = false;
      state.report = action.payload;
    })
    .addCase(clearReport, (state) => {
      state.report = '';
    })
    .addCase(storeReportType, (state, action) => {
      state.reportType = action.payload;
    });
});
