import { AxiosResponse } from "axios";
import { Employee3YearPayHistoryRequest, EmployeePayRateDownloadRequest } from 'core/models/PayRateReports.model';
import { HttpResponse } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { PayRateReportEndpoints } from './URL';

const postFuturePayRateReport = (): Promise<
  AxiosResponse<HttpResponse<string>>
> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    PayRateReportEndpoints.FUTURE_PAY_RATE_REPORT(),
  );
};

const postEmployeePayRateDownloadReport = (request: EmployeePayRateDownloadRequest): Promise<
  AxiosResponse<HttpResponse<string>>
> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    PayRateReportEndpoints.POST_EMPLOYEE_PAY_RATE_DOWNLOAD(), request,
  );
};
const postEmployee3YearPayHistory = (request: Employee3YearPayHistoryRequest): Promise<
  AxiosResponse<HttpResponse<string>>
> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    PayRateReportEndpoints.POST_EMPLOYEE_3YEAR_PAY_HISTORY(), request,
  );
};

export const PayRateReportsService = {
  postFuturePayRateReport,
  postEmployeePayRateDownloadReport,
  postEmployee3YearPayHistory,
};
