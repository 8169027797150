import { AxiosResponse } from 'axios';
import { CommonService } from '.';
import { HttpResponse } from '../models';
import { SalesTaxTransmittal, SalesTaxTransmittalEntity, SalesTaxTransmittalEntityPayload, SalesTaxPayload } from '../models/SalesTaxTransmittal.model';
import { API_EMPLOYEE_MASTER } from './API';
import { SalesTaxTransmittalEndPoints } from './URL';

const fetchSalesTaxTransmittal = (year: number): Promise<AxiosResponse<SalesTaxTransmittal[]>> => {
  return API_EMPLOYEE_MASTER.get<SalesTaxTransmittal[]>(
    SalesTaxTransmittalEndPoints.GET_SALES_TAX_TRANSMITTAL(
      CommonService.getProtectedClientNo(),
      year),
  );
};

const fetchSalesTaxTransmittalByEntityAndMonthId = (payload: SalesTaxTransmittalEntityPayload): Promise<AxiosResponse<HttpResponse<SalesTaxTransmittalEntity[]>>> => {
  return API_EMPLOYEE_MASTER.get<HttpResponse<SalesTaxTransmittalEntity[]>>(
    SalesTaxTransmittalEndPoints.GET_SALES_TAX_TRANSMITTAL_ENTITY_AND_MONTH_ID(
      CommonService.getProtectedClientNo(),
      payload.month,
      payload.year,
      payload.entityId,
      payload.salesTaxMonthId,
    ),
  );
};

const updateSalesTaxTransmittalByEntityId = (payload: SalesTaxTransmittalEntityPayload): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<any>>(
    SalesTaxTransmittalEndPoints.PUT_SALES_TAX_TRANSMITTAL_ENTITY_ID(
      CommonService.getProtectedClientNo(),
      payload.month,
      payload.year,
      payload.entityId,
      payload.salesTaxMonthId,
    ),
    payload.data,
  );
};

const reportSalesTaxTransmittalByEntityId = (payload: SalesTaxTransmittalEntityPayload): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(
    SalesTaxTransmittalEndPoints.POST_SALES_TAX_TRANSMITTAL_ENTITY_ID_REPORT(
      CommonService.getProtectedClientNo(),
      payload.month,
      payload.year,
      payload.entityId),
  );
};

const addSalesTaxTransmittal = (payload: SalesTaxPayload): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(
    SalesTaxTransmittalEndPoints.POST_SALES_TAX_TRANSMITTAL_ADD(
      CommonService.getProtectedClientNo(),
      payload.month,
      payload.year),
  );
};

const closeSalesTaxTransmittal = (payload: SalesTaxPayload): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(
    SalesTaxTransmittalEndPoints.POST_SALES_TAX_TRANSMITTAL_CLOSE(
      CommonService.getProtectedClientNo(),
      payload.month,
      payload.year),
  );
};

export const SalesTaxTransmittalService = {
  fetchSalesTaxTransmittal,
  fetchSalesTaxTransmittalByEntityAndMonthId,
  updateSalesTaxTransmittalByEntityId,
  reportSalesTaxTransmittalByEntityId,
  addSalesTaxTransmittal,
  closeSalesTaxTransmittal,
};