import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEEOUnitNos, getLocationDepartments } from '../../../core/store/selectors';
import { useAppSelector } from '../../../utilities/hooks';
import { EEOUnitSetting } from 'core/models/HumanCapitalReports.model';
import { SelectGrp } from 'core/components/form-controls';
import Icon from 'core/components/shared/Icon';
import { postEEOUnitSettings, putEEOUnitSettings } from 'core/store/actions';
import { useForm } from 'react-hook-form';
import { Client } from 'core/models';

type PropTypes = {
  index: number;
  onDelete(item: EEOUnitSetting): void;
  setting: EEOUnitSetting;
};

const AssignUnitModalItem: React.FC<PropTypes> = ({ onDelete, setting }) => {
  const dispatch = useDispatch();
  const { locationOpts, deptOpts, subdeptOpts, subdept2Opts } = useSelector(getLocationDepartments);
  const eeoUnitNos = useSelector(getEEOUnitNos);
  const client = useAppSelector((state) => { return state.client.client; }) as Client;
  const { register, errors, handleSubmit } = useForm<EEOUnitSetting>({
    defaultValues: setting,
  });

  const onSubmit = (data: EEOUnitSetting) => {
    const request: EEOUnitSetting = {
      ruleID: setting?.ruleID ?? 0,
      entityID: client.clientEntityID,
      clientEntityID: client.clientID,
      clientNo: client.clientNo,
      loc: data?.loc,
      dept: data?.dept,
      sub: data?.sub,
      sub2: data?.sub2,
      eeoUnitID: data?.eeoUnitID || 0,
    };

    if (setting.ruleID === 0) {
      dispatch(postEEOUnitSettings(request));
      return;
    }

    dispatch(putEEOUnitSettings(request));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-wrap flex-grow-1">
          <SelectGrp
            name="loc"
            label="LOCATION"
            options={locationOpts}
            labelField="locationDesc"
            valueField="locationID"
            errors={errors?.loc}
            ref={register({
              valueAsNumber: true,
              required: 'Location is required',
            })}
            placeholder="-"
          />
          <SelectGrp
            name="dept"
            label="DEPARTMENT"
            labelField="deptDesc"
            valueField="deptCode"
            options={deptOpts}
            errors={errors?.dept}
            ref={register({
              valueAsNumber: true,
              required: 'Department is required',
            })}
            placeholder="-"
          />
          <SelectGrp
            name="sub"
            label="SUB DEPARTMENT"
            labelField="subDeptDesc"
            valueField="subDeptCode"
            options={subdeptOpts}
            errors={errors?.sub}
            ref={register({
              valueAsNumber: true,
              required: 'Sub Department is required',
            })}
            placeholder="-"
          />
          <SelectGrp
            name="sub2"
            label="SUB DEPARTMENT 2"
            labelField="sub2Desc"
            valueField="sub2Code"
            ref={register({
              valueAsNumber: true,
              required: 'Sub Department 2 is required',
            })}
            errors={errors?.sub2}
            options={subdept2Opts}
            noOption={true}
            addOptionText="SUB DEPARTMENT 2"
            placeholder="-"
          />
          <SelectGrp
            name="eeoUnitID"
            ref={register({
              valueAsNumber: true,
            })}
            label={'EEO Unit'}
            options={eeoUnitNos}
            noOption={true}
            showId={true}
            placeholder="-"
          />
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <button
              type="button"
              className="btn btn-link dm-grid-action-title mr-4"
              onClick={() => { return onDelete(setting); }}
            >
              Delete this Rule
              <Icon
                name="minus-circle"
                className="fa-minus-circle"
              />
            </button>
            <button
              type="submit"
              className="btn orange-button-sm mr-3"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AssignUnitModalItem;
