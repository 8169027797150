import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { InputGrpInLine } from '..';
import { SubDepartment2 } from '../../../models';
import {
  createSubdepartment2,
  updateSubdepartment2,
} from '../../../store/actions';
import { getClient } from '../../../store/selectors';
import { FieldInputSettings } from '../types';

const fs: FieldInputSettings = {
  sub2Code: {
    name: 'sub2Code',
    label: 'Sub Dept 2 Code',
    labelWidth: 25,
    type: 'number',
    required: true,
  },
  sub2Desc: {
    name: 'sub2Desc',
    label: 'Description',
    labelWidth: 25,
    required: true,
  },
  deptID: {
    name: 'deptID',
    label: 'Department',
    labelWidth: 25,
    required: true,
    labelField: 'deptDesc',
    valueField: 'deptId',
  },
  subDeptID: {
    name: 'subDeptID',
    label: 'Sub Department',
    labelWidth: 25,
    required: true,
    labelField: 'subDeptDesc',
    valueField: 'subDeptID',
  },
  glAccount: { name: 'glAccount', label: 'GL Account', labelWidth: 25 },
  alphaCode: { name: 'alphaCode', label: 'Alpha Code', labelWidth: 25 },
  quickenClass: {
    name: 'quickenClass',
    label: 'Quickbooks Class',
    labelWidth: 25,
  },
};

type PropTypes = {
  item: any;
  options: any[];
  onHide: any;
};

const SubDepartment2OptionForm: React.FC<PropTypes> = ({
  item,
  options,
  onHide,
}) => {
  const dispatch = useDispatch();
  const currentClient = useSelector(getClient);
  const { register, errors, handleSubmit, setError, clearErrors } =
        useForm<SubDepartment2>({ defaultValues: { ...item } });

  const onSub2CodeChange = (e: any) => {
    options.find((l: SubDepartment2) => {return l.sub2Code === +e.target.value;})
      ? setError('sub2Code', {
        type: 'BadSub2Code',
        message: 'Sub Department2 Code already exists.',
      })
      : clearErrors('sub2Code');
  };

  const onSubmit = (data: any) => {
    const updatedSubDept2 = { ...item, ...data } as SubDepartment2;
    updatedSubDept2.sub2Code = parseInt(data.sub2Code);
    if (updatedSubDept2.subDept2ID) {
      dispatch(updateSubdepartment2(updatedSubDept2));
    } else {
      updatedSubDept2.clientNo = currentClient!.clientNo;
      updatedSubDept2.clientID = currentClient!.clientID;
      updatedSubDept2.subDept2ID = 0;
      dispatch(createSubdepartment2(updatedSubDept2));
    }
    onHide(false);
  };

  return (
    <div className="dm-panel dm-panel-border mx-4 d-flex flex-column flex-nowrap">
      <div className="dm-grid-action-title">Add/Edit</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGrpInLine
          {...fs.sub2Code}
          errors={errors.sub2Code}
          onChange={onSub2CodeChange}
          ref={register}
        />
        <InputGrpInLine
          {...fs.sub2Desc}
          errors={errors.sub2Desc}
          ref={register({ required: 'Description is Required' })}
        />
        <InputGrpInLine
          {...fs.glAccount}
          errors={errors.glAccount}
          ref={register}
        />
        <InputGrpInLine
          {...fs.alphaCode}
          errors={errors.alphaCode}
          ref={register}
        />
        <InputGrpInLine
          {...fs.quickenClass}
          errors={errors.quickenClass}
          ref={register}
        />

        <div style={{ display: 'flex' }}>
          <button
            type="button"
            className="btn btn-primary orange-outline-button ml-auto mr-2"
            onClick={() => {return onHide(false);}}
          >
            Cancel
          </button>
          <button type="submit" className="orange-button mr-2">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default SubDepartment2OptionForm;
