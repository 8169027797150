import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { Employee } from 'core/models';
import { deleteEmployee } from 'core/store/actions';
import { useHistory } from 'react-router-dom';

type PropTypes = {
    selectedEmployee: Employee | null,
    deleteFromDetail: boolean;
    setShowConfirmations: React.Dispatch<React.SetStateAction<boolean>>,
  };

  const navigatorError = 'This employee’s information has been synchronized with Employee Navigator. If you proceed with deleting this ' +
                            'employee you will have to manually remove the employee’s information from your Employee Navigator portal. ' +
                            'Failing to do so will cause synchronization issues in the future when re-using this employee number.\n\n' +
                            'Click YES to proceed with deleting this employee.\n\nClick NO if you do not want to delete this employee.';
    
  const deleteConfirmationMessage = 'Are you sure you want to permanently delete employee @empNo\nand ALL data related to this employee?'

const EmployeeDeleteConfirmationModal: React.FC<PropTypes> = ({
    selectedEmployee,
    deleteFromDetail,
    setShowConfirmations,
  }) => {

  const dispatch = useDispatch();
  const hasNavigation = selectedEmployee?.hasNavigation;
  const empNo = selectedEmployee?.empNo?.toString() || '0';
  const [confirmEmpNavigation, setConfirmEmpNavigation] = useState(hasNavigation || false);
  const [confirmedDelete, setConfirmDelete] = useState(!hasNavigation);
  const history = useHistory();
  
  //If they have emp navigator info then the first confirm will be here. If they accept then set the confirm delete to true and confirm empNav to false.
  //If they click no or the X then it will set the confirm empNav to no and set the showConfirmations to false.
  const onConfirmNavigator = (confirm: boolean) => {
    if(confirm){
        setConfirmEmpNavigation(false);
        setConfirmDelete(true);
    }
    else{
        setConfirmEmpNavigation(false);
        setShowConfirmations(false);
    }
  }

  //If they either confirmed empNav or do not have empNav it will go to the standard confirm. If they confirm it then we will set the showConfirmDelete to false and call the delete endpoint
  //Else if they click no we will set showConfirmDelete to false and the showconfirmations to false. 
  const onConfirmDelete = (confirm: boolean) => {
    if(confirm){
        setConfirmDelete(false);
        onDelete()
    }
    else{
        setConfirmDelete(false);
        setShowConfirmations(false);
    }
  }

  const onDelete = () => {
    setShowConfirmations(false);
    if(selectedEmployee) dispatch(deleteEmployee({protectedEmpNo: selectedEmployee.protectedEmpNo, empNo: selectedEmployee.empNo}));
    if(deleteFromDetail) history.push('/employee-master-list');
  }

  return (
    <div>
      <ConfirmationModal
        title={'DM Payroll'}
        message={navigatorError}
        show={confirmEmpNavigation}
        closeAfterConfirm = {false}
        onConfirmed={onConfirmNavigator}
        onHide={() => {setShowConfirmations(false)}}
      />
      <ConfirmationModal
        title={'DM Payroll'}
        message={deleteConfirmationMessage.replace('@empNo', empNo)}
        show={confirmedDelete}
        onConfirmed={onConfirmDelete}
        closeAfterConfirm = {false}
        onHide={() => {setShowConfirmations(false)}}
      />
    </div>
  );
};

export default EmployeeDeleteConfirmationModal;
