import { createAction } from '@reduxjs/toolkit';
import { EmpI9 } from '../../models';

export const loadEmployeesI9 = createAction('[emp-i9] LOAD_EMPLOYEES_I9');
export const storeEmployeesI9 = createAction<EmpI9[]>('[emp-i9] STORE_EMPLOYEES_I9');
export const loadEmployeeI9 = createAction<{ protectedEmpNo: string }>('[emp-i9] LOAD_EMPLOYEE_I9');
export const loadEmployeeI9DataReport = createAction('[emp-i9] LOAD_EMPLOYEE_I9_DATAREPORT');
export const storeEmployeeI9DataReport = createAction<any>('[emp-i9] STORE_EMPLOYEE_I9_DATAREPORT');
export const clearEmployeeI9DataReport = createAction('[emp-i9] CLEAR_EMPLOYEE_I9_DATAREPORT');
export const storeEmployeeI9 = createAction<EmpI9>('[emp-i9] STORE_EMPLOYEE_I9');
export const updateEmployeeI9 = createAction<EmpI9>('[emp-i9] UPDATE_EMPLOYEE_I9');
