import React from 'react';
import styles from '../time-card-styles.module.scss';
import EmpPhoto from 'core/components/shared/EmpPhoto';
import { useAppDispatch } from 'utilities/hooks';
import { InputGrp, SelectGrp } from 'core/components/form-controls';
import { DatePickerGrp } from 'core/components/form-controls/DatePickerGrp';
import Icon from 'core/components/shared/Icon';

const testAdjTypeOpts = [
  { description: 'Pre Paid', id: '1' },
  { description: 'Third Party Sick', id: '2' },
  { description: 'Void', id: '3' },
];

type Props = {
  onAddTimeEntry: () => void;
  onShowAdjustmentSummary: () => void;
};

const AdjustmentDetailHeader = ({ onAddTimeEntry, onShowAdjustmentSummary }: Props) => {
  const dispatch = useAppDispatch();
  
  return (
    <div className={styles['time-card-header']}>
      <div className={styles['card-header-row']}>
        <div className={`${styles['emp-main-info']} w-100`}>
          <EmpPhoto
            empPicData=""
            empName=""
            scaleFactor="65"
          />
          <div className="d-flex flex-column w-100">
            <span className="dm-grid-title emp-name mb-1">{'FirstName'}&nbsp;{'LastName'}</span>
            <div className={styles['header-info-section']}>
              <span className="font-weight-bold">Emp No:&nbsp;{0}</span>
              <span className="font-weight-bold">Area:&nbsp;{'999' ?? 'N/A'}</span>
              <span className="font-weight-bold">Trade:&nbsp;{'999' ?? 'N/A'}</span>
              <span className="font-weight-bold mr-3">Sub:&nbsp;{'999' ?? 'N/A'}</span>
              <span className="font-weight-bold">{'Some A-T-S Description Here' ?? 'N/A'}</span>
              <div className="d-flex ml-auto">
                <button className="btn orange-button mr-3">
                  Save
                </button>
                <button className="btn orange-outline-button mr-4">
                  Delete
                </button>
                <button
                  className="btn btn-link dm-grid-action-title pb-0"
                  onClick={onAddTimeEntry}
                >
                  Add Time Entry <Icon
                    name="plus-circle"
                    className="fa-plus-circle"
                  />
                </button>
              </div>                    
            </div>
            <div className={styles['header-info-section']}>
              <SelectGrp
                label="Adjustment Type"
                options={testAdjTypeOpts}
                selectStyles={{ height: '26px' }}
                defaultValue={'1'}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                }}
              />
              <DatePickerGrp
                name="weekEnd"
                label="Week Ending"
                value={''}
                onChange={(newVal) => { }}
                inputStyle={{ height: '26px' }}
              />
              <DatePickerGrp
                name="checkDate"
                label="Check Date"
                value={''}
                onChange={(newVal) => { }}
                inputStyle={{ height: '26px' }}
              />
              <InputGrp
                name="checkNo"
                label="Check Number"
                value={''}
                inputStyle={{ height: '26px' }}
              />
              <div className="ml-auto mt-2">
                <button
                  className="btn btn-link dm-grid-action-title pb-0"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onShowAdjustmentSummary();
                  }}
                >
                  Adjustment Summary
                </button>
                <button
                  className="btn btn-link dm-grid-action-title pb-0 mx-3"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  View Deductions
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdjustmentDetailHeader;