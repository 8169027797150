import React, { CSSProperties } from 'react';

type Props = {
  styles?: CSSProperties;
};

const Spinner = ({ styles }: Props) => {
  return (
    <div
      className="dm-spinner-border"
      style={styles}
    >
    </div>
  );
};

export default Spinner;