import React, { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Deduction } from 'core/models';
import { getDeductionsAndGarnishments } from 'core/store/selectors/deduction.selector';
import { loadDeductions } from 'core/store/actions';
import SnapshotDeductionItem from './SnapshotDeductionItem';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Icon from 'core/components/shared/Icon';

const sectionHeadings = ['DEDUCTION', 'AMOUNT', 'UNIT', 'FREQUENCY'];

type UrlParams = {
  protectedEmpNo: string;
};

const SnapshotDeductionsPage = () => {
  const dispatch = useDispatch();
  const { protectedEmpNo } = useParams<UrlParams>();

  const deductions: Deduction[] = useSelector(getDeductionsAndGarnishments);

  useEffect(() => {
    dispatch(loadDeductions(protectedEmpNo));
  }, [dispatch, protectedEmpNo]);

  return (
    <div className="dm-panel dm-panel-border">
      <div className="row">
        <div className="col-10">
          <div className="dm-grid-title">Deductions</div>
        </div>
        <div className="col-2 d-flex justify-content-end my-auto">
          <NavLink to="deductions">
            <Icon
              name="pen"
              className="fa-pen"
            />
          </NavLink>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <hr className="dm-panel-hr" />
        </div>
      </div>
      <TableContainer>
        <Table
          sx={{ minWidth: 300 }}
          size="small"
          aria-label="test"
        >
          <TableHead>
            <TableRow>
              {sectionHeadings.map((heading) => {
                return (
                  <TableCell
                    sx={{
                      fontFamily: "Open Sans, 'sans-serif'",
                      fontSize: '10px',
                      fontWeight: '700',
                      lineHeight: '14px',
                      marginBottom: '0',
                      color: '#717171',
                      paddingLeft: '5px',
                      paddingBottom: '3px',
                      textTransform: 'uppercase',
                      margin: 'auto 0 0',
                    }}
                    key={heading}
                  >
                    {heading}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {deductions.map((d, key) => {
              return (
                <SnapshotDeductionItem
                  key={key}
                  deduction={d}
                  isGarnishment = {d.deductionType.toLocaleLowerCase() === 'garnishment'}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SnapshotDeductionsPage;
