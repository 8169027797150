import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PanelHeader from 'core/components/shared/PanelHeader';
import { loadEmployee } from 'core/store/actions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import EmpInfoEdit from '../../emp-detail/snapshot/info/EmpInfoEdit';
type Props = {
  clientNo: string;
};

type HrParams = {
  protectedEmpNo: string;
};

const HrEmployeeStatusGeneralPage: React.FC<Props> = () => {
  const { protectedEmpNo } = useParams<HrParams>();
  const dispatch = useAppDispatch();

  const selectedEmployee = useAppSelector(
    (state) => {return state.selEmployee.employee;},
  );

  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(loadEmployee(protectedEmpNo));
    }
  }, [protectedEmpNo]);

  return (
    <>
      <PanelHeader title="General Information"></PanelHeader>
      {selectedEmployee && (
        <EmpInfoEdit
          employee={selectedEmployee}
          onCancel={() => {}}
          showHistory={true}
        />
      )}
    </>
  );
};

export default HrEmployeeStatusGeneralPage;
