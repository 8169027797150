import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class WCRate {
  wcRuleID = 0;

  stateOfficerLimitID = 0;

  wcCode = '';

  description = '';

  rate = 0;

  static readonly convProps: PropTypeLists = {
    numberProps: ['wcRuleID', 'stateOfficerLimitID', 'officerLimit'],
    floatProps: ['rate'],
  };

  constructor(wcRuleID: number | string, stateOfficerLimitID: number | string, props?: { [key: string]: any }) {
    this.wcRuleID = (typeof wcRuleID === 'string') ? parseInt(wcRuleID) : wcRuleID;
    this.stateOfficerLimitID = (typeof stateOfficerLimitID === 'string') ? parseInt(stateOfficerLimitID) : stateOfficerLimitID;

    props && convToClass(this, props, WCRate.convProps);
  }
}

export class WCEffectiveDate {
  clientNo = 0;

  stateOfficerLimitID = 0;

  wcPolicyID = '';

  stateCode = '';

  effectiveDate: Date | string = '';

  officerLimit = 0;

  rates: WCRate[] = [];

  static readonly convProps: PropTypeLists = {
    dateProps: ['effectiveDate'],
    numberProps: ['clientNo', 'stateOfficerLimitID', 'officerLimit'],
  };

  constructor(
    clientNo: number | string,
    stateOfficerLimitID: number | string,
    props?: { [key: string]: any }) {

    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.stateOfficerLimitID = (typeof stateOfficerLimitID === 'string') ? parseInt(stateOfficerLimitID) : stateOfficerLimitID;

    props && convToClass(this, props, WCEffectiveDate.convProps);
    props && props.rates && (this.rates = props.rates.map((r: WCRate) => {return new WCRate(r.wcRuleID, r.stateOfficerLimitID, r);}));
  }
}