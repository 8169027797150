import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RecurringEarnings, RecurringEarningsRequest } from '../../models';
import { RecurringEarningsService } from 'core/services';
import { handleError, handleSuccess } from './app.action';
import { triggerEmFieldValidation } from './em-field-validation.action';

export const RECURRINGEARNINGS_EM_VALIDATION_ACTIONS = {
  CREATE_RECURRING_EARNINGS: '[recurring-earnings] CREATE_RECURRING_EARNINGS',
  DELETE_RECURRING_EARNINGS: '[recurring-earnings] DELETE_RECURRING_EARNINGS',
} as const;

export const loadRecurringEarnings = createAsyncThunk(
  '[recurring-earnings] LOAD_RECURRING_EARNINGS',
  async (request: string, { dispatch }) => {
    try {
      const { data } = await RecurringEarningsService.getRecurringEarnings(request);
      
      dispatch(storeRecurringEarnings(data));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const storeRecurringEarnings = createAction<RecurringEarnings[]>('[recurring-earnings] STORE_RECURRING_EARNINGS');

export const createRecurringEarnings = createAsyncThunk(
  RECURRINGEARNINGS_EM_VALIDATION_ACTIONS.CREATE_RECURRING_EARNINGS,
  async (request: RecurringEarningsRequest, { dispatch }) => {
    try {
      const { data } = await RecurringEarningsService.postRecurringEarning(request.protectedEmpNo, request.Data);
      
      dispatch(handleSuccess(data.messages));
      dispatch(storeRecurringEarning(data.value));
      dispatch(loadRecurringEarnings(request.protectedEmpNo)); // create, store, and then... load them again?
      dispatch(triggerEmFieldValidation({
        section: 'recurringearnings',
        actionType: RECURRINGEARNINGS_EM_VALIDATION_ACTIONS.CREATE_RECURRING_EARNINGS,
        callerPayload: data,
      }));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const deleteRecurringEarning = createAsyncThunk(
  RECURRINGEARNINGS_EM_VALIDATION_ACTIONS.DELETE_RECURRING_EARNINGS,
  async (request: RecurringEarnings, { dispatch }) => {
    try {
      const _res = await RecurringEarningsService.deleteRecurringEarning(request);
      
      dispatch(handleSuccess('Recurring earning deleted successfully'));
      dispatch(loadRecurringEarnings(request.protectedEmpNo));
      dispatch(triggerEmFieldValidation({
        section: 'recurringearnings',
        actionType: RECURRINGEARNINGS_EM_VALIDATION_ACTIONS.DELETE_RECURRING_EARNINGS,
        callerPayload: request,
      }));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const storeRecurringEarning = createAction<RecurringEarnings[]>('[recurring-earnings] STORE_RECURRING_EARNING');
