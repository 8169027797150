import { AxiosResponse } from "axios";
import { PayrollsUserOptions } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getPayrollsUserOptions = (): Promise<AxiosResponse<PayrollsUserOptions>> => { return API_EMPLOYEE_MASTER.get<PayrollsUserOptions>(EmployeeEndPoints.GET_PAYROLLS_USER_OPTIONS(CommonService.getProtectedClientNo())); };

const putPayrollsUserOptions = (data: PayrollsUserOptions): Promise<AxiosResponse<PayrollsUserOptions>> => { return API_EMPLOYEE_MASTER.put<PayrollsUserOptions>(EmployeeEndPoints.PUT_PAYROLLS_USER_OPTIONS(CommonService.getProtectedClientNo()), data); };

export const PayrollsUserOptionsService = {
  getPayrollsUserOptions,
  putPayrollsUserOptions,
};
