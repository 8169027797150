import { createSelector } from 'reselect';
import { EarningCode, EarningsCodeAutoCalculation } from '../../models';
import { earningCodeState } from '../reducers';
import { EarningCodeState } from '../reducers/earning-code.reducer';

export const getEarningCodes = createSelector(
  earningCodeState,
  (state: EarningCodeState): EarningCode[] => {
    return state.earningCodes;
  },
);

export const getEarningCodesAutoCalculations = createSelector(
  earningCodeState,
  (state: EarningCodeState): EarningsCodeAutoCalculation[] => {
    return state.earningCodeAutoCalculations;
  },
);

export const getEarningCodesAutoCalculationsMessages = createSelector(
  earningCodeState,
  (state: EarningCodeState): string[] => {
    return state.messages;
  },
);
