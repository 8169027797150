import { createAsyncThunk } from '@reduxjs/toolkit';
import { CommonService, ContractorReportService, DropdownService } from 'core/services';
import { ContractorFringeState } from 'core/store/slices/contractor-reports/fringe.slice';
import { IOption } from 'interfaces/IOption';
import DateObject from 'react-date-object';

const getValues = (arr: IOption[] = []) => {
  return arr?.filter((item: IOption) => {
    return !!item;
  })?.map((item: IOption) => {
    return item.value;
  });
};

export const getContractorFringeData = createAsyncThunk(
  'reports/getContractorFringeData',
  async (payload, { getState }) => {
    const { contractorFringeDownload } = getState() as { contractorFringeDownload: ContractorFringeState };

    if (!contractorFringeDownload?.endDate || !contractorFringeDownload?.beginDate) {
      return;
    }

    const response = await ContractorReportService.getContractorFringe({
      startDate: new DateObject(new Date(contractorFringeDownload?.beginDate)).format('YYYY-MM-DD'),
      endDate: new DateObject(new Date(contractorFringeDownload?.endDate)).format('YYYY-MM-DD'),
    });

    const data = response?.data || response;

    const dropdowns = await DropdownService.getAllDropdowns();

    interface IHoursWages {
      dropdownName: string;
      description: string;
      id: string;
    }

    return {
      ...data,
      hoursWagesOptions: dropdowns?.data?.filter((item: IHoursWages) => {
        return item.dropdownName === 'EarningsCode';
      }).map((item: IHoursWages) => {
        return {
          label: item.description,
          value: item.id,
        };
      }),
    };
  },
);

export const createContractorFringeMap = createAsyncThunk(
  'reports/createContractorFringeMap',
  async (payload, { getState }) => {
    const { contractorFringeDownload } = getState() as { contractorFringeDownload: ContractorFringeState };

    let AtsSelected = contractorFringeDownload.areaValue;

    if (contractorFringeDownload.areaSelected === 'areaTrades') {
      AtsSelected = contractorFringeDownload.areaTradeValue;
    }

    if (contractorFringeDownload.areaSelected === 'areaTradeSubs') {
      AtsSelected = contractorFringeDownload.areaTradeSubValue;
    }

    const response = await ContractorReportService.postContractorFringe({
      ClientNo: CommonService.getClientNo(),
      MapName: contractorFringeDownload?.mapDescription,
      ByWeekEndingOrCheckDate: contractorFringeDownload.byOption,
      HoursCodes: contractorFringeDownload.hours?.map((item: IOption) => {
        return item.value;
      }),
      HoursTotal: contractorFringeDownload.hoursTotal,
      HoursDetail: contractorFringeDownload.hoursDetail,
      WagesCodes: contractorFringeDownload.wages?.map((item: IOption) => {
        return item.value;
      }),
      WagesTotal: contractorFringeDownload.wagesTotal,
      WagesDetail: contractorFringeDownload.wagesDetail,
      PayRates: contractorFringeDownload.payRates?.map((item: IOption) => {
        return item.value;
      }),
      FringeEarningsCodes: contractorFringeDownload.fringeEarning?.map((item: IOption) => {
        return item.value;
      }),
      FringeEarningsTotal: contractorFringeDownload.fringeEarningsTotal,
      FringeEarningsDetail: contractorFringeDownload.fringeEarningsDetail,
      Area: contractorFringeDownload.areaSelected === 'areas',
      AreaTrade: contractorFringeDownload.areaSelected === 'areaTrades',
      AreaTradeSub: contractorFringeDownload.areaSelected === 'areaTradeSubs',
      AtsSelected: AtsSelected?.map((item: IOption) => {
        return item.value;
      }),
      ShowATSDescription: contractorFringeDownload.showDescription,
      EmployeePaidFringes: (contractorFringeDownload.employeeFringeEarning || [])?.map((item: IOption) => {
        return item.value;
      }),
      EmployeePaidFringesTotal: contractorFringeDownload.employeeFringeEarningTotal,
      EmployeePaidFringesDetail: contractorFringeDownload.employeeFringeEarningDetail,
      EmployerPaidFringes: contractorFringeDownload.employerFringeEarning?.map((item: IOption) => {
        return item.value;
      }),
      EmployerPaidFringesTotal: contractorFringeDownload.employerFringeEarningTotal,
      EmployerPaidFringesDetail: contractorFringeDownload.employerFringeEarningDetail,
      EmployerPaidTaxes: contractorFringeDownload.employerPaidTaxes,
      EmployerPaidTaxesTotal: contractorFringeDownload.employerPaidTaxesTotal,
      EmployerPaidTaxesDetail: contractorFringeDownload.employerPaidTaxesDetail,
      JobNumbers: contractorFringeDownload.jobNumbers?.map((item: IOption) => {
        return item.value;
      }),
      SubNumbers: contractorFringeDownload.subNumbers?.map((item: IOption) => {
        return item.value;
      }),
      ProjectNumber: contractorFringeDownload.projectNumber,
      Phase: contractorFringeDownload.phase,
      FoExtra: contractorFringeDownload.foExtra, 
      CostCode: contractorFringeDownload.costCode,
      WeekEndingDate: contractorFringeDownload.weekEndingDate,
      CheckDate: contractorFringeDownload.checkDate,
      DayWorked: contractorFringeDownload.dayWorked,
      JobSubtotals: contractorFringeDownload.jobSubTotals,
      EmployeeSubtotals: contractorFringeDownload.employeeSubTotals,
      JobName: contractorFringeDownload.jobName,
      City: contractorFringeDownload.city,
      County: contractorFringeDownload.country,
      State: contractorFringeDownload.state,
    });

    return response?.data || response;
  },
  
);

export const updateContractorFringeMap = createAsyncThunk(
  'reports/updateContractorFringeMap',
  async (payload, { getState }) => {
    const { contractorFringeDownload } = getState() as { contractorFringeDownload: ContractorFringeState };

    let AtsSelected = contractorFringeDownload.areaValue;

    if (contractorFringeDownload.areaSelected === 'areaTrades') {
      AtsSelected = contractorFringeDownload.areaTradeValue;
    }

    if (contractorFringeDownload.areaSelected === 'areaTradeSubs') {
      AtsSelected = contractorFringeDownload.areaTradeSubValue;
    }

    const response = await ContractorReportService.updateContractorFringe({
      MapKey: contractorFringeDownload?.mapKey,
      ClientNo: CommonService.getClientNo(),
      MapName: contractorFringeDownload?.mapDescription,
      ByWeekEndingOrCheckDate: contractorFringeDownload.byOption,
      HoursCodes: getValues(contractorFringeDownload.hours),
      HoursTotal: contractorFringeDownload.hoursTotal,
      HoursDetail: contractorFringeDownload.hoursDetail,
      WagesCodes: getValues(contractorFringeDownload.wages),
      WagesTotal: contractorFringeDownload.wagesTotal,
      WagesDetail: contractorFringeDownload.wagesDetail,
      PayRates: getValues(contractorFringeDownload.payRates),
      FringeEarningsCodes: getValues(contractorFringeDownload.fringeEarning),
      FringeEarningsTotal: contractorFringeDownload.fringeEarningsTotal,
      FringeEarningsDetail: contractorFringeDownload.fringeEarningsDetail,
      Area: contractorFringeDownload.areaSelected === 'areas',
      AreaTrade: contractorFringeDownload.areaSelected === 'areaTrades',
      AreaTradeSub: contractorFringeDownload.areaSelected === 'areaTradeSubs',
      AtsSelected: getValues(AtsSelected),
      ShowATSDescription: contractorFringeDownload.showDescription,
      EmployeePaidFringes: getValues(contractorFringeDownload.employeeFringeEarning),
      EmployeePaidFringesTotal: contractorFringeDownload.employeeFringeEarningTotal,
      EmployeePaidFringesDetail: contractorFringeDownload.employeeFringeEarningDetail,
      EmployerPaidFringes: getValues(contractorFringeDownload.employerFringeEarning),
      EmployerPaidFringesTotal: contractorFringeDownload.employerFringeEarningTotal,
      EmployerPaidFringesDetail: contractorFringeDownload.employerFringeEarningDetail,
      EmployerPaidTaxes: contractorFringeDownload.employerPaidTaxes,
      EmployerPaidTaxesTotal: contractorFringeDownload.employerPaidTaxesTotal,
      EmployerPaidTaxesDetail: contractorFringeDownload.employerPaidTaxesDetail,
      JobNumbers: getValues(contractorFringeDownload.jobNumbers),
      SubNumbers: getValues(contractorFringeDownload.subNumbers),
      ProjectNumber: contractorFringeDownload.projectNumber,
      Phase: contractorFringeDownload.phase,
      FoExtra: contractorFringeDownload.foExtra, 
      CostCode: contractorFringeDownload.costCode,
      WeekEndingDate: contractorFringeDownload.weekEndingDate,
      CheckDate: contractorFringeDownload.checkDate,
      DayWorked: contractorFringeDownload.dayWorked,
      JobSubtotals: contractorFringeDownload.jobSubTotals,
      EmployeeSubtotals: contractorFringeDownload.employeeSubTotals,
      JobName: contractorFringeDownload.jobName,
      City: contractorFringeDownload.city,
      County: contractorFringeDownload.country,
      State: contractorFringeDownload.state, 
    }, contractorFringeDownload?.mapKey as string);

    return response?.data || response;
  },
);

export const downloadContractorFringeData = createAsyncThunk(
  'reports/downloadContractorFringeData',
  async (data: any, { getState }) => {
    const { contractorFringeDownload } = getState() as { contractorFringeDownload: ContractorFringeState };

    if (!contractorFringeDownload?.endDate || !contractorFringeDownload?.beginDate) {
      return;
    }

    const response = await ContractorReportService.downloadContractorFringe({
      startDate: new DateObject(new Date(contractorFringeDownload?.beginDate)).format('YYYY-MM-DD'),
      endDate: new DateObject(new Date(contractorFringeDownload?.endDate)).format('YYYY-MM-DD'),
      map: data,
    });

    return response?.data || response;
  },
);

export const deleteContractorFringeMap = createAsyncThunk(
  'reports/deleteContractorFringeMap',
  async (mapKey: string, { dispatch }) => {
    const response = await ContractorReportService.deleteContractorFringe(mapKey);
    dispatch(getContractorFringeData());

    return response?.data || response;
  },
);