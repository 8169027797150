import { createReducer } from '@reduxjs/toolkit';
import { PayrollInfo } from '../../models';
import { loadPayrollInfo, storePayrollInfo } from '../actions';

export interface State {
  loading: boolean;
  payrollInfo: PayrollInfo | null;
}

export const INITIAL_STATE: State = {
  loading: false,
  payrollInfo: null,
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadPayrollInfo, (state) => {
      state.loading = true;
    })
    .addCase(storePayrollInfo, (state, action) => {
      state.loading = false;
      state.payrollInfo = action.payload;
    });
});
