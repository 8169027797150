import Modal from 'core/components/modals/Modal';
import React, { CSSProperties } from 'react';
import { TimeCardJobRosterDeductions } from 'core/models/Contractor.model';
import JobRosterDeductionItem from './JobRosterDeductionItem';

const modalStyles: CSSProperties = {
  minWidth: '800px',
};

type Props = {
  show: boolean;
  onHide: () => void;
  deductions: TimeCardJobRosterDeductions[];
};

const JobRosterDeductionsModal = ({ show, onHide, deductions }: Props) => {
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Deductions"
      additionalStyles={modalStyles}
    >
      <div className="d-flex flex-column">
        <div className="d-flex flex-column mb-2">
          <div className="d-flex ml-4">
            <div className="d-flex flex-column w-25">
              <span>Deduction Type</span>
            </div>
            <span style={{ marginLeft: '45px' }}>Amount</span>
          </div>
          {
            deductions?.map((deduction) => (
              <JobRosterDeductionItem
                deduction={deduction}
              />
            ))
          }
        </div>
      </div>
    </Modal>    
  );
};

export default JobRosterDeductionsModal;