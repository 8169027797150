import { Payroll, PayrollControlTotal, TransmittalEmployee } from 'core/models';
import { SelectFunctionProps, BalanceConditions } from './types';

// TODO: Implement for card, control totals, wherever necessary
export function select<T>({ property, id, state, items, handleMatch, toggleOpen, openIndex, setOpenIndex }: SelectFunctionProps<T>) {
  const selectionMatch = items.find((item) => { return item[property] === id; });
  
  if (!selectionMatch) return;
  if (state[property] === selectionMatch[property]) {
    handleMatch(null);
  } else {
    handleMatch(selectionMatch);
  }
  
  if (toggleOpen && setOpenIndex) {
    if (openIndex === selectionMatch[property]) {
      setOpenIndex(null);
    } else {
      setOpenIndex(selectionMatch[property] as React.SetStateAction<number | null | undefined>);
    }
  }
}

// Could probably just go in global utilities
// TODO: uhh... make this work
export function colorShift(color: string, percent: number): string {
  // HEX -> RGB
  const formattedColorString = color.replace('#', '');
  let [r, g, b] =
    [
      parseInt(formattedColorString.substring(0, 2), 16),
      parseInt(formattedColorString.substring(2, 4), 16),
      parseInt(formattedColorString.substring(4, 6), 16),
    ];
  
  // calculate new values by factor of percent
  const factor = 1 - percent / 100;
  r = Math.round(r * factor);
  g = Math.round(g * factor);
  b = Math.round(b * factor);

  const newColorString = `${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
  
  return `#${newColorString.substring(0, 6)}`;
}

export const sumControlTotalData = (controlTotal: PayrollControlTotal): number => {
  // There are 12 CT properties we want to sum, and 12 we don't... so I'd either exclude or include 12. It's ugly both ways
  const sumData = [
    controlTotal.transmittalRegular,
    controlTotal.transmittalDouble,
    controlTotal.transmittalTimeHalf,
    controlTotal.transmittalOtherHours,
    controlTotal.transmittalOtherEarnings,
    controlTotal.transmittalSpecialDeductions,
    controlTotal.controlTotalRegular,
    controlTotal.controlTotalDouble,
    controlTotal.controlTotalTimeHalf,
    controlTotal.controlTotalOtherHours,
    controlTotal.controlTotalOtherEarnings,
    controlTotal.controlTotalSpecialDeductions,
  ].reduce((accumulator, currentValue) => {return accumulator + currentValue;}, 0);
  
  return sumData;
};

// Logic from spPayroll_ControlTotalByYear
export const transmittalHasData = (controlTotal: PayrollControlTotal): boolean => {
  return (controlTotal.transmittalRegular + 
          controlTotal.transmittalTimeHalf + 
          controlTotal.transmittalDouble + 
          controlTotal.transmittalOtherHours +
          controlTotal.transmittalOtherEarnings +
          controlTotal.transmittalSpecialDeductions +
          controlTotal.transmittalTaxAmounts) != 0;
};

export const handleTransmittalFilter = (transmittalEmps: TransmittalEmployee[], type: 'E' | 'U' | 'R' | 'CE' | null): TransmittalEmployee[] => {
  if (!transmittalEmps?.length) return transmittalEmps;
  
  switch (type) {
    case 'E': {
      return transmittalEmps?.filter((emp) => {
        return emp?.checks.filter((check) => {
          return (
            check.earnings.some((a) => { return a.rateId === -1; })
          );
        }).length;
      });
    }
    case 'U': {
      return transmittalEmps?.filter((emp) => {
        return emp?.checks?.filter((check) => {
          return check.earnings.every((a) => { return a.amount === 0; });
        }).length;
      });
    }
    case 'R': {
      return transmittalEmps?.filter((emp) => {
        return emp?.checks?.filter((check) => {
          return (
            check.earnings.every((a) => { return a.amount === 0; }) &&
            check.earnings.some((a) => { return a.recurringEarningId; })
          );
        }).length;
      });
    }
    case 'CE': {
      return transmittalEmps?.filter((emp) => {
        return emp?.checks?.filter((check) => {
          return (
            check.checkErrors?.some((a) => { return a.transmittalCheckId; })
          );
        }).length;
      });
    }
    case null:
    default: {
      return transmittalEmps;
    }
  }
};

export const checkIfCheckErrors = (transmittalEmps: TransmittalEmployee[]) : boolean => {
  const errorChecks =  transmittalEmps?.map((emp) => {
    return emp?.checks?.filter((check) => {
      return (
        check.checkErrors?.some((a) => { return a.transmittalCheckId; })
      );
    }).length;
  });

  //Returns the empNos with error checks. If ones not found it returns 0 so filter that out.
  return errorChecks.filter(x => x !== 0).length > 0;
};

export const checkIfBalanced = (dateline: Payroll | null, conditions: BalanceConditions): boolean => {
  if (!dateline) return false; // for CardList
  if (dateline.adjustmentTotals?.length) return true; // PI-8444: if control total is unbalanced, but there are adjustments, it'll just process the adjustments.
  if (!dateline.controlTotals?.length) return true; // adjustment datelines don't have a "balance" state so just return true for them
  return !!(dateline?.controlTotals?.find((controlTotal) => {
   
    if (conditions.showAuxInfo) {
      return (controlTotal?.balanced && controlTotal.payControlTotal)
        || (conditions.hasAdjustmentData);
    }
    //PI-8917 Instead of checking the total just check if its balanced from that backend who basically does the total.
    return ((controlTotal?.balanced) || (conditions.hasAdjustmentData)); 
  }));
};