import { ICellEditorParams } from '@ag-grid-community/core';
import { ddLookup } from 'utilities/utilities';

interface agSelectEditorParams extends ICellEditorParams {
  options: any[];
  valueField?: string;
  labelField?: string;
  addEmptyValue?: boolean;
  showId?: boolean;
}

export function agSelectEditor(): any {}

agSelectEditor.prototype.init = function (params: agSelectEditorParams) {
  const vf = params.valueField ?? 'id';
  const df = params.labelField ?? 'description';

  this.select = document.createElement('select');
  (this.select as HTMLElement).className = 'ag-select-override';
  
  this.parentElement = (this.select as HTMLElement)?.parentElement;
  if (this?.parentElement) (this.parentElement as HTMLElement).style.padding = '0';

  this.select.addEventListener('change', () => {
    params.node.setDataValue(params.column.getColId(), this.select.value);
  });

  if (params.addEmptyValue) {
    const option = document.createElement('option');
    option.value = '';
    option.title = '';
    this.select.appendChild(option);
  }
  params?.options?.forEach((opt) => {
    const option = document.createElement('option');
    if (typeof opt === 'string') {
      option.value = opt;
      option.title = opt;
      params.value === opt && (option.selected = true);
      option.appendChild(document.createTextNode(opt));
    } else {
      option.value = opt[vf];
      if (params?.showId) {
        option.title = `${opt[vf]} - ${opt[df]}`;
      } else {
        option.title = opt[df];
      }
      if ((params.value ?? '').toString() === (opt[vf] ?? '').toString()) {
        option.selected = true;
      }
      option.appendChild(document.createTextNode(opt[df]));
    }
    this.select.appendChild(option);
  });
};

agSelectEditor.prototype.getGui = function () {
  return this.select;
};

agSelectEditor.prototype.getValue = function () {
  return this.select.value;
};

agSelectEditor.prototype.destroy = function () {
  this.select = null;
};

agSelectEditor.prototype.isPopup = function () {
  return false;
};
