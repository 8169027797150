import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ACA } from 'core/models';
import { getClient, getEmpACATabData } from 'core/store/selectors';
import { loadEmployeeACATabData } from 'core/store/actions';
import { useAppSelector } from 'utilities/hooks';
import { Col, Row } from 'react-bootstrap';
import EmployeeStatus from 'core/components/shared/EmployeeStatus';
import { useParams } from 'react-router-dom';

const EmployeeStatusPage = () => {
  const dispatch = useDispatch();
  const client = useSelector(getClient);
  const acaTabData = useSelector(getEmpACATabData);
  const params = useParams<{ protectedEmpNo?: string }>();
  const [protectedEmpNo, setProtectedEmpNo] = useState('');
  const protectedClientNo = useAppSelector(
    (state) => {return state.auth.currentProtectedClientNo;},
  );

  const currentYear = new Date().getFullYear();

  const { control, formState, ...formMethods } = useForm<ACA>({
    defaultValues: acaTabData ?? {} as ACA,
  });

  useEffect(() => {
    if (params && params?.protectedEmpNo) setProtectedEmpNo(params.protectedEmpNo); 
  }, [params]);

  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(
        loadEmployeeACATabData({
          protectedEmpNo,
          prYear: currentYear,
        }),
      );
    }
  }, [protectedEmpNo]);

  useEffect(() => {
    if (acaTabData) formMethods.reset(acaTabData);
  }, [acaTabData]);

  const onCancel = () => {
    formMethods.reset();
  };

  return (
    <Row className="no-gutters">
      <Col className="m-3 shadow p-3 border">
        <div className="col-12 p-3">
          <div>
            {acaTabData && client && (
              <EmployeeStatus
                formMethods={formMethods}
                formState={formState}
                protectedEmpNo={protectedEmpNo}
                protectedClientNo={protectedClientNo}
                clientNo={client.clientNo}
                control={control}
                acaStatus={acaTabData.acaStatus}
                onCancel={onCancel}
              />
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default EmployeeStatusPage;
