import React, { CSSProperties, useEffect } from 'react';
import styles from '../styles.module.scss';
import Modal from 'core/components/modals/Modal';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'utilities/hooks';
import PageSpinner from 'core/components/shared/PageSpinner';
import { addUpdateDiagnosticRule, getDiagnosticRule } from 'core/store/actions';
import { useForm } from 'react-hook-form';
import { PayrollTimeCardDiagnosticRules } from 'core/models/Contractor.model';
import { InputGrpDecimal } from 'core/components/form-controls';
import { formatDecimal } from 'utilities/utilities';

type Props = {
  show: boolean;
  onHide: () => void;
};

const columnHeaderStyles: CSSProperties = {
  marginBottom: '10px',
  fontWeight: 'bold',
  textAlign: 'center',
};

const formatAsString = (property: string | number | undefined) => {
  return formatDecimal(parseFloat(String(property) ?? '0') ?? 0);
};

const DiagnosticRulesModal = ({ show, onHide }: Props) => {
  const dispatch = useDispatch();
  
  const { diagnosticRules, loading } = useAppSelector(({ contractor }) => contractor);
  const clientNo = useAppSelector(({ client }) => client?.client?.clientNo) ?? -9999;
  
  const { register, handleSubmit, formState: { isDirty }, setValue, reset } = useForm<PayrollTimeCardDiagnosticRules>({
    defaultValues: diagnosticRules ?? {},
  });
  
  useEffect(() => {
    dispatch(getDiagnosticRule());
  }, []);
  
  useEffect(() => {
    if (!diagnosticRules) return;
    reset({
      ...diagnosticRules,
      totalHrLowLimit: formatAsString(diagnosticRules?.totalHrLowLimit),
      totalHrUpLimit: formatAsString(diagnosticRules?.totalHrUpLimit),
      regHrLimit: formatAsString(diagnosticRules?.regHrLimit),
      hlfHrLimit: formatAsString(diagnosticRules?.hlfHrLimit),
      dblHrLimit: formatAsString(diagnosticRules?.dblHrLimit),
      othHrLimit: formatAsString(diagnosticRules?.othHrLimit),
      dedAmtLimit: formatAsString(diagnosticRules?.dedAmtLimit),
      earnAmtLimit: formatAsString(diagnosticRules?.earnAmtLimit),
    });
  }, [diagnosticRules]);
  
  const saveDiagnosticRules = (submitData: PayrollTimeCardDiagnosticRules) => {
    dispatch(addUpdateDiagnosticRule({ ...submitData, clientNo: clientNo }));
    reset({ ...submitData });
  };
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Diagnostic Rules"
      size="lg"
    >
      <form onSubmit={handleSubmit(saveDiagnosticRules)}>
        <div className={styles['diagnostics-container']}>
          <div className={styles['diagnostics-section']}>
            <div className="d-flex flex-column">
              <div className="d-flex mb-3">
                <div className="d-flex mr-3 align-items-center">
                  <span className="mr-3">Total Hours Lower Limit</span>
                  <InputGrpDecimal
                    name="totalHrLowLimit"
                    groupClass="mr-3 mb-0"
                    style={{ height: '30px' }}
                    strValue={formatAsString(diagnosticRules?.totalHrLowLimit)}
                    fixed={2}
                    ref={register}
                  />
                </div>
                <div className="d-flex mr-3 align-items-center">
                  <span className="mr-3">Total Hours Upper Limit</span>
                  <InputGrpDecimal
                    name="totalHrUpLimit"
                    groupClass="mr-3 mb-0"
                    style={{ height: '30px' }}
                    strValue={formatAsString(diagnosticRules?.totalHrUpLimit)}
                    ref={register()}
                    fixed={2}
                  />
                </div>
              </div>
              <div
                className="d-flex flex-wrap"
                style={{ gap: '10px' }}
              >
                <div className={styles['c-300']}>
                  <span className="ml-auto mr-2">Cannot Exceed</span>
                  <div className="d-flex w-100 align-items-center mb-2">
                    <span className="mr-2">Regular hours</span>
                    <InputGrpDecimal
                      name="regHrLimit"
                      groupClass="ml-auto mb-0"
                      style={{ height: '30px' }}
                      strValue={formatAsString(diagnosticRules?.regHrLimit)}
                      ref={register()}
                      setValue={setValue}
                      fixed={2}
                    />
                  </div>
                  <div className="d-flex w-100 align-items-center mb-2">
                    <span className="mr-2">1 1/2 hours</span>
                    <InputGrpDecimal
                      name="hlfHrLimit"
                      groupClass="ml-auto mb-0"
                      style={{ height: '30px' }}
                      strValue={formatAsString(diagnosticRules?.hlfHrLimit)}
                      ref={register()}
                    />
                  </div>
                  <div className="d-flex w-100 align-items-center mb-2">
                    <span className="mr-2">Double hours</span>
                    <InputGrpDecimal
                      name="dblHrLimit"
                      groupClass="ml-auto mb-0"
                      style={{ height: '30px' }}
                      strValue={formatAsString(diagnosticRules?.dblHrLimit)}
                      ref={register()}
                    />
                  </div>
                </div>
                <div className={styles['c-300']}>
                  <span className="ml-auto mr-2">Cannot Exceed</span>
                  <div className="d-flex w-100 align-items-center mb-2">
                    <span className="mr-2">Other hours</span>
                    <InputGrpDecimal
                      name="othHrLimit"
                      groupClass="ml-auto mb-0"
                      style={{ height: '30px' }}
                      strValue={formatAsString(diagnosticRules?.othHrLimit)}
                      ref={register()}
                    />
                  </div>
                  <div className="d-flex w-100 align-items-center mb-2">
                    <span className="mr-2">Deduction amount</span>
                    <InputGrpDecimal
                      name="dedAmtLimit"
                      groupClass="ml-auto mb-0"
                      style={{ height: '30px' }}
                      strValue={formatAsString(diagnosticRules?.dedAmtLimit)}
                      ref={register()}
                    />
                  </div>
                  <div className="d-flex w-100 align-items-center mb-2">
                    <span className="mr-2">Other earnings</span>
                    <InputGrpDecimal
                      name="earnAmtLimit"
                      groupClass="ml-auto mb-0"
                      style={{ height: '30px' }}
                      strValue={formatAsString(diagnosticRules?.earnAmtLimit)}
                      ref={register()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className={styles['diagnostics-label-column']}>
            </div>
            <div
              className={styles['diagnostics-column']}
              style={{ justifyContent: 'end', width: '10%' }}
            >
              <span style={columnHeaderStyles}>Is Mandatory</span>
            </div>
            <div
              className={styles['diagnostics-column']}
              style={{ justifyContent: 'end' }}
            >
              <span style={columnHeaderStyles}>Carried forward on Time Card</span>
            </div>
            <div
              className={styles['diagnostics-column']}
              style={{ justifyContent: 'end' }}
            >
              <span style={columnHeaderStyles}>Validate if entered on Time Card</span>
            </div>
            <div
              className={styles['diagnostics-column']}
              style={{ justifyContent: 'end' }}
            >
              <span style={columnHeaderStyles}>Display error if not marked Active</span>
            </div>
          </div>
          <div className={styles['diagnostics-section']}>
            <div className={styles['diagnostics-label-column']}>
              <span style={{ marginBottom: '10px' }}>Job Number</span>
              <span style={{ marginBottom: '10px' }}>Sub Number</span>
              <span style={{ marginBottom: '10px' }}>FO/Extra</span>
              <span style={{ marginBottom: '10px' }}>Phase</span>
              <span style={{ marginBottom: '10px' }}>Cost Code</span>
            </div>
            <div
              className={styles['diagnostics-column']}
              style={{ width: '10%' }}
            >
              <input
                type="checkbox"
                name="jobMandatory"
                ref={register()}
                defaultChecked={diagnosticRules?.jobMandatory}
                style={{ marginBottom: '10px', height: '21px' }}
              />
              <input
                type="checkbox"
                name="subMandatory"
                ref={register()}
                defaultChecked={diagnosticRules?.subMandatory}
                style={{ marginBottom: '10px', height: '21px' }}
              />
              <input
                type="checkbox"
                name="foeMandatory"
                ref={register()}
                defaultChecked={diagnosticRules?.foeMandatory}
                style={{ marginBottom: '10px', height: '21px' }}
              />
              <input
                type="checkbox"
                name="phaseMandatory"
                ref={register()}
                defaultChecked={diagnosticRules?.phaseMandatory}
                style={{ marginBottom: '10px', height: '21px' }}
              />
              <input
                type="checkbox"
                name="costCodeMandatory"
                ref={register()}
                defaultChecked={diagnosticRules?.costCodeMandatory}
                style={{ marginBottom: '10px', height: '21px' }}
              />
            </div>
            <div className={styles['diagnostics-column']}>
              <input
                type="checkbox"
                name="jobCarryForward"
                ref={register()}
                defaultChecked={diagnosticRules?.jobCarryForward}
                style={{ marginBottom: '10px', height: '21px' }}
              />
              <input
                type="checkbox"
                name="subCarryForward"
                ref={register()}
                defaultChecked={diagnosticRules?.subCarryForward}
                style={{ marginBottom: '10px', height: '21px' }}
              />
              <input
                type="checkbox"
                name="foeCarryForward"
                ref={register()}
                defaultChecked={diagnosticRules?.foeCarryForward}
                style={{ marginBottom: '10px', height: '21px' }}
              />
              <input
                type="checkbox"
                name="phaseCarryForward"
                ref={register()}
                defaultChecked={diagnosticRules?.phaseCarryForward}
                style={{ marginBottom: '10px', height: '21px' }}
              />
              <input
                type="checkbox"
                name="costCodeCarryForward"
                ref={register()}
                defaultChecked={diagnosticRules?.costCodeCarryForward}
                style={{ marginBottom: '10px', height: '21px' }}
              />
            </div>
            <div className={styles['diagnostics-column']}>
              <input
                type="checkbox"
                name="jobValidated"
                ref={register()}
                defaultChecked={diagnosticRules?.jobValidated}
                style={{ marginBottom: '10px', height: '21px' }}
              />
              <input
                type="checkbox"
                name="subValidated"
                ref={register()}
                defaultChecked={diagnosticRules?.subValidated}
                style={{ marginBottom: '10px', height: '21px' }}
              />
              <span style={{ height: '31px' }}></span>
              <span style={{ height: '31px' }}></span>
              <input
                type="checkbox"
                name="costCodeValidated"
                ref={register()}
                defaultChecked={diagnosticRules?.costCodeValidated}
                style={{ marginBottom: '10px', height: '21px' }}
              />
            </div>
            <div className={styles['diagnostics-column']}>
              <input
                type="checkbox"
                name="jobIsActive"
                ref={register()}
                defaultChecked={diagnosticRules?.jobIsActive}
                style={{ marginBottom: '10px', height: '21px' }}
              />
            </div>
          </div>
          <div className={styles['diagnostics-section']}>
            <div className="d-flex my-3">
              <span className="mr-2">Display &quot;Time has been entered but has not been approved.&quot; errors</span>
              <input
                type="checkbox"
                name="approvalErrors"
                defaultChecked={diagnosticRules?.approvalErrors}
                ref={register()}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="orange-outline-button-sm mr-2"
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              className="orange-button-sm"
              type="submit"
              disabled={!isDirty}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default DiagnosticRulesModal;