import React, { useEffect } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridOptions, MasterDetailModule } from '@ag-grid-enterprise/all-modules';
import { CheckboxGrp, RadioGrp } from 'core/components/form-controls';
import InputDateSelector from 'core/components/form-controls/InputDateSelector';
import { downloadContractorOverviewData, getContractorOverviewData } from 'core/store/actions/ contractor-reports/overview.action';
import { Option, setContractorOverviewDownloadDates, setSummarizedBy, toggleShowDetail, setByWeekendOption, setFileType } from 'core/store/slices/contractor-reports/overview.slice';
import Select, { GroupBase, SingleValue, StylesConfig } from 'react-select';
import { agDateRenderer } from 'utilities/ag-grid-renderers';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import ExcelReportDownloadButton from 'core/components/shared/ExcelReportDownloadButton';
import { Dropdown } from 'react-bootstrap';

type StylesConfigType = StylesConfig<Option, boolean, GroupBase<Option>> | undefined;

const compStyles: StylesConfigType = {
  control: (base) => {
    return {
      ...base,
      minHeight: '22px',
      height: '22px',
      minWidth: '140px',
      width: '100%',
      borderRadius: '0.25rem',
      fontSize: '12px',
      color: 'black',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#0074D9',
      },
    };
  },
  valueContainer: (base) => {
    return {
      ...base,
      padding: '0',
      paddingLeft: '2px',
      top: '0',
      position: 'relative',
      overflow: 'hidden',
      width: 'fit-content',
      color: 'black',
      boxSizing: 'border-box',
    };
  },
  indicatorSeparator: (base) => {
    return {
      ...base,
      display: 'none',
    };
  },
  dropdownIndicator: (base) => {
    return {
      ...base,
      padding: '0px',
      paddingLeft: '5px',
      color: '#3a3a3a',
      '&:hover': {
        color: 'black',
      },
    };
  },
};

const ContractorOverviewDownload = () => {
  const dispatch = useAppDispatch();

  const columns: ColDef[] = [
    {
      field: 'group',
      headerName: 'Group Number',
      cellRenderer: 'agGroupCellRenderer',
      sortable: true,
    },
    {
      field: 'groupDescription',
      headerName: 'Group Description',
      sortable: true,
    },
    {
      field: 'jobNumber',
      headerName: 'Job No',
      filter: 'agTextColumnFilter',
      suppressMenu: false,
      sortable: true,
    },
    {
      field: 'projectNumber',
      headerName: 'Project No',
      sortable: true,
    },
    {
      field: 'subNumber',
      headerName: 'Sub No',
      filter: 'agTextColumnFilter',
      suppressMenu: false,
      sortable: true,
    },
    {
      field: 'phase',
      headerName: 'Phase',
      filter: 'agTextColumnFilter',
      suppressMenu: false,
      sortable: true,
    },
    {
      field: 'foExtra',
      headerName: 'FO/Extra',
      filter: 'agTextColumnFilter',
      suppressMenu: false,
      sortable: true,
    },
    {
      field: 'costCode',
      headerName: 'Cost Code',
      filter: 'agTextColumnFilter',
      suppressMenu: false,
      sortable: true,
    },
    {
      field: 'stHours',
      headerName: 'Straight Time Hours',
      sortable: true,
      headerClass: 'ag-right-aligned-header',
      cellClass: 'ag-cell-left-border text-right',
    },
    {
      field: 'stEarnings',
      headerName: 'Straight Time Earnings',
      sortable: true,
      headerClass: 'ag-right-aligned-header',
      cellClass: 'ag-cell-left-border text-right',
    },
    {
      field: 'premHours',
      headerName: 'Premium Hours',
      sortable: true,
      headerClass: 'ag-right-aligned-header',
      cellClass: 'ag-cell-left-border text-right',
    },
    {
      field: 'premEarnings',
      headerName: 'Premium Earnings',
      sortable: true,
      headerClass: 'ag-right-aligned-header',
      cellClass: 'ag-cell-left-border text-right',
    },
    {
      field: 'deductionAmount',
      headerName: 'Deduction Amount',
      sortable: true,
      headerClass: 'ag-right-aligned-header',
      cellClass: 'ag-cell-left-border text-right',
    },
    {
      field: 'earningsAmount',
      headerName: 'Earnings Amount',
      sortable: true,
      headerClass: 'ag-right-aligned-header',
      cellClass: 'ag-cell-left-border text-right',
    },
    {
      field: 'fringeVH',
      headerName: 'V&H Fringe',
      sortable: true,
      headerClass: 'ag-right-aligned-header',
      cellClass: 'ag-cell-left-border text-right',
    },
    {
      field: 'fringeDues',
      headerName: 'Dues Fringe',
      sortable: true,
      headerClass: 'ag-right-aligned-header',
      cellClass: 'ag-cell-left-border text-right',
    },
    {
      field: 'fringeHW',
      headerName: 'H&W Fringe',
      sortable: true,
      headerClass: 'ag-right-aligned-header',
      cellClass: 'ag-cell-left-border text-right',
    },
    {
      field: 'fringePension',
      headerName: 'Pension Fringe',
      sortable: true,
      headerClass: 'ag-right-aligned-header',
      cellClass: 'ag-cell-left-border text-right',
    },
    {
      field: 'fringeVH',
      headerName: 'Misc Fringe',
      sortable: true,
      headerClass: 'ag-right-aligned-header',
      cellClass: 'ag-cell-left-border text-right',
    },
  ];

  function formatNumber(n: string) {
    const parts = n.toString().split('.');
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? '.' + parts[1] : '');
  }

  const formatColumns = (cols: ColDef[]) => {
    return cols.map((item) => {
      item.valueFormatter = (data) => {
        const formatWithCommasAnd2Decimals = ['stHours', 'stEarnings', 'premHours', 'premEarnings', 'deductionAmount', 'earningsAmount'];

        const fringes = ['fringeDues', 'fringeHW', 'fringePension', 'fringeVH', 'fringeMisc'];

        if (!data?.value) {
          return '-';
        }

        if (item?.field && formatWithCommasAnd2Decimals.includes(item?.field)) {
          return formatNumber(parseFloat(data?.value).toFixed(2));
        }

        if (item?.field && fringes.includes(item?.field)) {
          return formatNumber(parseFloat(data?.value).toFixed(4));
        }

        return data?.value;
      };

      return item;
    });
  };

  const gridOptions: GridOptions = {
    columnDefs: formatColumns(columns),
    domLayout: 'autoHeight',
    defaultColDef: {
      autoHeight: true,
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    pagination: true,
    paginationPageSize: 10,
    // masterDetail: true,
    detailRowAutoHeight: true,
    detailCellRendererParams: {
      detailGridOptions: {
        defaultColDef: {
          autoHeight: true,
          suppressMenu: true,
          resizable: true,
          singleCickEdit: true,
          cellClass: 'ag-cell-left-border',
        },
        // commented code was not found in the response
        columnDefs: formatColumns([
          {
            field: 'empNo',
            headerName: 'Employee No',
            width: 100,
          },
          {
            field: 'firstName',
            headerName: 'First Name',
          },
          {
            field: 'middleInitial',
            headerName: 'M.I.',
            width: 60,
          },
          {
            field: 'lastName',
            headerName: 'Last Name',
          },
          // {
          //   field: '',
          //   headerName: 'Earn Code',
          // },
          {
            field: 'stHours',
            headerName: 'Straight Time Hours',
            headerClass: 'ag-right-aligned-header',
            cellClass: 'ag-cell-left-border text-right',
          },
          {
            field: 'stEarnings',
            headerName: 'Straight Time Earnings',
            headerClass: 'ag-right-aligned-header',
            cellClass: 'ag-cell-left-border text-right',
          },
          {
            field: 'premHours',
            headerName: 'Premium Hours',
            headerClass: 'ag-right-aligned-header',
            cellClass: 'ag-cell-left-border text-right',
          },
          {
            field: 'premEarnings',
            headerName: 'Premium Earnings',
            headerClass: 'ag-right-aligned-header',
            cellClass: 'ag-cell-left-border text-right',
          },
          // {
          //   field: '',
          //   headerName: 'Ded Code',
          // },
          {
            field: 'deductionAmount',
            headerName: 'Ded Amount',
            headerClass: 'ag-right-aligned-header',
            cellClass: 'ag-cell-left-border text-right',
          },
          // {
          //   field: '',
          //   headerName: 'Earn Code',
          // },
          {
            field: 'earningsAmount',
            headerName: 'Earn Amount',
            headerClass: 'ag-right-aligned-header',
            cellClass: 'ag-cell-left-border text-right',
          },
          {
            field: 'fringeVH',
            headerName: 'V&H Fringe',
            headerClass: 'ag-right-aligned-header',
            cellClass: 'ag-cell-left-border text-right',
          },
          {
            field: 'fringeDues',
            headerName: 'Dues Fringe',
            headerClass: 'ag-right-aligned-header',
            cellClass: 'ag-cell-left-border text-right',
          },
          {
            field: 'fringeHW',
            headerName: 'H&W Fringe',
            headerClass: 'ag-right-aligned-header',
            cellClass: 'ag-cell-left-border text-right',
          },
          {
            field: 'fringePension',
            headerName: 'Pension Fringe',
            headerClass: 'ag-right-aligned-header',
            cellClass: 'ag-cell-left-border text-right',
          },
          {
            field: 'fringeMisc',
            headerName: 'Misc Fringe',
            headerClass: 'ag-right-aligned-header',
            cellClass: 'ag-cell-left-border text-right',
          },
          // {
          //   field: '',
          //   headerName: 'Area',
          // },
          // {
          //   field: '',
          //   headerName: 'Trade',
          // },
          // {
          //   field: '',
          //   headerName: 'Sub',
          // },
          // {
          //   field: '',
          //   headerName: 'Addtl Rate',
          // },
          // {
          //   field: '',
          //   headerName: 'Trade Rate',
          // },
          // {
          //   field: '',
          //   headerName: 'Emp Master Rate',
          // },
          // {
          //   field: '',
          //   headerName: 'Day Worked',
          // },
          // {
          //   field: '',
          //   headerName: 'Week Ending',
          // },
          // {
          //   field: '',
          //   headerName: 'Check Date',
          // },
          // {
          //   field: '',
          //   headerName: 'Check Code',
          // },
        ]),
      },
      getDetailRowData: function (params: any) {
        params.successCallback(params.data.employeeSummaries);
      },
    },
    components: {
      dateRenderer: agDateRenderer,
    },
  };

  const {
    data,
    isLoading,
    beginDate,
    endDate,
    showDetail,
    summarizeOptions,
    summarizedBy,
    byWeekend,
  } = useAppSelector((state) => {
    return state.contractorReportsOverview;
  });
  const dropdownValue = summarizeOptions.find((opt) => { return summarizedBy === opt.value; });

  const onSelect = (option: SingleValue<Option>) => {
    if (!option) return;
    
    dispatch(setSummarizedBy(option?.value));
  };

  const isRunReportButtonDisabled = isLoading || !beginDate || !endDate;

  const runReport = () => {
    dispatch(getContractorOverviewData());
  };

  const processDownload = () => {
    dispatch(downloadContractorOverviewData());
  };

  useEffect(() => {
    runReport();
  }, []);

  return (
    <div className="dm-panel dm-panel-border">
      <div className="dm-grid-title">Contractor Overview Download</div>
      <hr className="dm-panel-hr" />
      <div className="d-flex flex-row justify-content-between align-items-start flex-grow-1">
        <div className="d-flex flex-column align-items-start">
          <div className="d-flex align-items-center">
            <InputDateSelector
              returnDates={(dates: string[]) => {
                dispatch(setContractorOverviewDownloadDates(dates));
              }}
              hideDateTypes={true}
            />
            <div className="d-flex flex-column ml-4">
              <div className="form-label mt-0">Summarized by:</div>
              <Select
                styles={compStyles as StylesConfigType}
                value={dropdownValue}
                options={summarizeOptions}
                getOptionLabel={(optionData) => {
                  return optionData.label;
                }}
                getOptionValue={(optionData) => {
                  return optionData.value;
                }}
                onChange={(option) => {
                  return onSelect(option as SingleValue<Option>);
                }}
                isSearchable={false}
                isClearable={false}
                menuPlacement="auto"
                menuPortalTarget={document.body}
                menuPosition="fixed"
              />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column">
              <div className="form-label mt-0">&nbsp;</div>
              <div className="d-flex align-items-center"
                style={{ height: 22 }}>
                <CheckboxGrp
                  groupClass="mb-0 mr-0"
                  groupStyle={{
                    marginTop: -2,
                  }}
                  checked={showDetail}
                  onChange={() => {
                    dispatch(toggleShowDetail());
                  }}
                />
                <p className="form-label mt-0">Show Detail</p>
              </div>
            </div>
            <div className="d-flex flex-column ml-4">
              <div className="form-label mt-0">&nbsp;</div>
              <div className="d-flex flex-column">
                <RadioGrp
                  name="byWeekend"
                  groupClass="mb-0 mr-0"
                  groupStyle={{
                    marginTop: -2,
                  }}
                  defaultChecked={byWeekend ? 'byWeekend' : 'byCheckDate'}
                  radioOptions={[
                    {
                      label: 'By Check Data',
                      value: 'byCheckDate',
                    },
                    {
                      label: 'By Week Ending',
                      value: 'byWeekend',
                    },
                  ]}
                  onChange={(e) => {
                    dispatch(setByWeekendOption(e.target.value === 'byWeekend'));
                  }}
                />
              </div>
            </div>
          </div>
          <button
            type="button"
            className="orange-button mt-2"
            onClick={runReport}
            disabled={isRunReportButtonDisabled}
          >
            Refresh
          </button>
        </div>
        <div className="d-flex align-items-center justify-content-end text-right">
          <Dropdown className="mr-2">
            <Dropdown.Toggle variant="link"
              id="dropdown-basic">
              Download options
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  dispatch(setFileType('csv'));
                }}
              >
                CSV
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  dispatch(setFileType('xls'));
                }}
              >
                Excel
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <ExcelReportDownloadButton
            type="button"
            disabled={!data?.length}
            onClick={processDownload}
          />
        </div>
      </div>
      {!!data?.length && (
        <div className="mt-4 ag-theme-balham">
          <AgGridReact
            masterDetail
            gridOptions={gridOptions}
            rowData={data}
            overlayNoRowsTemplate={
              '<span>No Changes to Display</span>'
            }
            modules={[MasterDetailModule]}
          />
        </div>
      )}
    </div>
  );
};

export default ContractorOverviewDownload;