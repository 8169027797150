import React from 'react';
import styles from './styles.module.scss';
import { currencyFormatter } from 'utilities/utilities';
import { TimeCardJobRoster } from 'core/models/Contractor.model';

type Props = {
  job: TimeCardJobRoster;
  isSelected: boolean;
};

const JobSnapshot = ({ job, isSelected }: Props) => {
  const jobTitle = (job.jobNo === '') ? '<blank>' : `${job.jobNo} - ${job.jobDescription}`;

  return (
    <li className={`${isSelected ? styles['selected-snapshot'] : styles['job-snapshot']}`}>
      <div className="d-flex flex-column w-100">
        <div className={styles.title}>
          <strong>{jobTitle}</strong>
        </div>
        <div className={styles['hours-details']}>
          <span>Hours:&nbsp;{job.hoursTotal?.toFixed(2)}</span>
          <span>Earnings:&nbsp;{currencyFormatter(job.earningsTotal)}</span>
          <span>Deductions:&nbsp;{currencyFormatter(job.deductionTotal)}</span>
        </div>
      </div>
    </li>
  );
};

export default JobSnapshot;