import Modal from 'core/components/modals/Modal';
import { UserMapItem } from 'core/models/Downloader.model';
import React, { useState } from 'react';
import FormatExcelForm from './FormatExcelForm';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { useAppSelector } from 'utilities/hooks';

type PropTypes = {
  show: boolean;
  onHide: () => void;
};

const FormatExcelModal: React.FC<PropTypes> = ({ show, onHide }) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const selectedUserMapStore = useAppSelector((state) => { return state.downloader.selectedUserMap; });

  const onCheckDirty = (prop: boolean) => {
    setIsDirty(prop);
  };

  const closeFormatModal = () => {
    if (isDirty) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };

  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };

  return (
    <Modal
      show={show}
      onHide={closeFormatModal}
      size="xl"
      title="Format Excel"
      isDirty={isDirty}
    >
    {selectedUserMapStore ? 
    <FormatExcelForm
        userMapItem={selectedUserMapStore}
        closeModal={closeFormatModal}
        onCheckDirty={onCheckDirty}
      /> : null}
      {showConfirmationModal &&
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={
            'Would you still like to close this modal?'
          }
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }
          }
        />
      }
    </Modal>

  );
};

export default FormatExcelModal;