import { OrderByType } from "./AccrualReport.model";

export enum EarningsReportType {
  DeductionReport = 'DeductionReport',
  EarningsReport = 'EarningsReport',
  EarningsAndHoursReport = 'EarningsAndHoursReport',
  HoursReport = 'HoursReport',
  GrossToNetReport = 'GrossToNetReport',
  CompanyPaidBenefitsReport = 'CompanyPaidBenefitsReport',
  GrossReport = 'GrossReport',
  DeductionsEarningsTaxesReport = 'DeductionsEarningsTaxesReport',
}

export interface EarningsReportsStandardReportsRequest {
  checkDate: boolean;
  beginDate: string;
  endDate: string;
  earningsReportType: EarningsReportType;
  empNos: number[];
  orderByType: OrderByType;
}

export interface EmployeeWageDetailDownloadReportRequest {
  clientNo: number;
  selectedYear: number;
}
