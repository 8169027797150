/* eslint-disable object-property-newline */
import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ApClientVendor, HttpResponse } from '../../models';
import { ApClientVendorService } from '../../services';
import {
  createApClientVendor,
  handleError,
  handleSuccess,
  loadApClientVendors,
  storeApClientVendor,
  storeApClientVendors,
  updateApClientVendor,
} from '../actions';

interface Actions<T> {
  type: string;
  payload: T;
}

const loadApClientVendors$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadApClientVendors.type),
    switchMap(() => {
      return from(ApClientVendorService.getApClientVendors()).pipe(
        map((res: any) => { return res.data; }),
        map((res: ApClientVendor[]) => { return storeApClientVendors(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createApClientVendor$ = (action$: Observable<Actions<ApClientVendor>>) => {
  return action$.pipe(
    ofType(createApClientVendor.type),
    switchMap((action: { payload: ApClientVendor; }) => {
      return from(ApClientVendorService.postApClientVendor(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res) => {
          return [
            storeApClientVendor({ vendor: res.value, isNewVendor: true }),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateApClientVendor$ = (action$: Observable<Actions<ApClientVendor>>) => {
  return action$.pipe(
    ofType(updateApClientVendor.type),
    switchMap((action: { payload: ApClientVendor; }) => {
      return from(ApClientVendorService.putApClientVendor(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res) => {
          return [
            storeApClientVendor({ vendor: res.value, isNewVendor: false }),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadApClientVendors$,
  createApClientVendor$,
  updateApClientVendor$,
];
