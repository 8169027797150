import { createSelector } from 'reselect';
import { empSelfServiceState } from '../reducers';
import { State as EmpSelfServiceState } from '../reducers/emp-self-service.reducer';

export const getEmpSelfService = createSelector(
  empSelfServiceState,
  (state: EmpSelfServiceState) => {return state.employeeSelfService;},
);

export const getMyInfoAccountResponseMessage = createSelector(
  empSelfServiceState,
  (state: EmpSelfServiceState) => {return state.myInfoAccountMessage;},
);

export const getMyInfoChanges = createSelector(
  empSelfServiceState,
  (state: EmpSelfServiceState) => {return state.myInfoChanges;},
);

export const getMyInfoChangesHistory = createSelector(
  empSelfServiceState,
  (state: EmpSelfServiceState) => {return state.myInfoChangesHistory;},
);

export const getSelectedChanges = createSelector(
  empSelfServiceState,
  (state: EmpSelfServiceState) => {return state.selectedChanges;},
);

export const getSelectedEmployeeChanges = createSelector(
  empSelfServiceState,
  (state: EmpSelfServiceState) => {return state.selectedChanges.employeeChanges;},
);

export const getSelectedTaxChanges = createSelector(
  empSelfServiceState,
  (state: EmpSelfServiceState) => {return state.selectedChanges.taxChanges;},
);

export const getSelectedDirectDepositChange = createSelector(
  empSelfServiceState,
  (state: EmpSelfServiceState) => {return state.selectedChanges.directDepositChange;},
);

export const getMyInfoLoadingState = createSelector(
  empSelfServiceState,
  (state: EmpSelfServiceState) => {return state.loading;},
);