import React from 'react';
import { useSelector } from 'react-redux';
import { Deduction } from 'core/models';
import { getDeductionCodes, getDeductionFrequencies, getDropdownOptions } from 'core/store/selectors';
import { currencyFormatter, ddLookup, formatWithCommas } from 'utilities/utilities';
import { TableRow, TableCell } from '@mui/material';
import { garnishmentCategoryOptions } from 'dropdowns/garnishmentCategoryOptions';

type Props = {
  deduction: Deduction;
  isGarnishment: boolean;
};

const SnapshotDeductionItem: React.FC<Props> = ({ deduction, isGarnishment }) => {
  const deductionCodeOpts = useSelector(getDeductionCodes);
  const frequencyOpts = useSelector(getDeductionFrequencies);
  const { unitOpts } = useSelector(getDropdownOptions);

  const getDeductionAmount = (categoryId: number) => {
    const unit = ddLookup(deduction.unit, unitOpts);
    switch (categoryId) {
      case 2: //Creditor
      case 4: //Student Loan
        return !unit ? `${formatWithCommas(deduction.supportPct)}%` : `${formatWithCommas(deduction.supportPct)}`;

      case 1: //FOC
      case 6: //COURT
      case 5: //Bankruptcy
        return `${currencyFormatter(deduction.dedAmount)}`;

      case 3: //Levy
        return `${deduction.exemptAmount}`;

      default:
        if (!unit) return `${currencyFormatter(deduction.dedAmount)}`;
        return (unit[0] === '%') ? `${formatWithCommas(deduction.dedAmount)}` : `${currencyFormatter(deduction.dedAmount)}`;
    }
  };

  return (
    <TableRow
      sx={{
        '& td': {
          fontFamily: "Open Sans, 'sans-serif'",
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '24px',
          paddingLeft: '5px',
          borderColor: '#e0e0e0',
        },
        '&:hover': {
          backgroundColor: '#f0f4fa',
        },
      }}
    >
      <TableCell>
        {isGarnishment ? ddLookup(deduction.categoryId, garnishmentCategoryOptions) : ddLookup(deduction.dedNo, deductionCodeOpts)}
      </TableCell>
      <TableCell
        sx={{ width: 80 }}
        align="right"
      >
        {getDeductionAmount((deduction?.categoryId || 0))}
      </TableCell>
      <TableCell>{isGarnishment ? '' : ddLookup(deduction.unit, unitOpts)}</TableCell>
      <TableCell>{isGarnishment ? '' : ddLookup(deduction.freq, frequencyOpts)}</TableCell>
    </TableRow>
  );
};

export default SnapshotDeductionItem;
