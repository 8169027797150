import React, { Component } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import Icon from 'core/components/shared/Icon';

interface ParamTypes extends ICellRendererParams {
  clickHandler: (e: any) => void;
}

export default class AGDeleteButtonRendererComponent extends Component {
  constructor(public props: ParamTypes) {
    super(props);
  }

  componentDidMount() { }

  refresh(params: ParamTypes) { }

  render() {
    const onClick = (e: any) => {
      e.stopPropagation();
      this.props.clickHandler(this.props.data);
    };
    return (
      <button
        type="button"
        className="btn btn-link"
        onClick={onClick}
      >
        Delete <Icon
          name="minus-circle"
          className="fa-minus-circle p-0"
        />
      </button>
    );
  }
}
