import React, { useEffect, useMemo, useState } from 'react';
import Select, { GroupBase, InputActionMeta, OptionsOrGroups, StylesConfig } from 'react-select';
import DropdownAddEditModal from './DropdownAddEditModal';
import { Labels } from '..';
import styles from '../form-controls.module.scss';
import { SelectModalGrpModel as S } from './types';
import { selectModalGrpStyles } from './compStyles';
import ConfirmationModal from 'core/components/modals/confirmation.modal';

export const SelectModalGrpUncontrolled: React.FC<S.PropTypes> = ({
  id,
  name,
  label,
  tallLabel = false,
  labelClass = '',
  groupClass,
  groupStyle,
  errors,
  required = false,
  visible = true,
  setValue,
  value,
  options,
  labelField = 'description',
  valueField = 'id',
  idField,
  addEmptyValue = false,
  noOption = false,
  addOptionText = '',
  showId = false,
  formComponent,
  idEditable = false,
  isEditable = true,
  dropdownName = '',
  modalTitle,
  disabled = false,
  readOnly,
  prependedOption = { label: '', value: '' },
  controlMinWidth = '140px',
  controlMaxWidth,
  searchable = false,
  shouldDirty = true,
  selectOnEnter = false,
  customFormComponent: CustomFormComponent,
  onChange,
  onFocus,
  portalTargetId,
  persistMenuOpen,
}) => {
  
  const compStyles: S.StylesConfigType = useMemo(() => selectModalGrpStyles(controlMinWidth, controlMaxWidth), []);
  
  const [query, setQuery] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [renderedVal, setRenderedVal] = useState<any>(null);
  const [saveOnClose, setSaveOnClose] = useState<boolean>(false);
  const [addMissingOption, setAddMissingOption] = useState<boolean>(false);
  const [showAddMissingConfirmation, setShowAddMissingConfirmation] = useState<boolean>(false);

  const opts = structuredClone(options);

  const groupClass2 = styles['dm-form-group dm-form-modal-grp'] + ' ' + (groupClass ?? '');
  id = id || name;

  const propName = `<no ${addOptionText.toLowerCase()}>`;
  const option = { ...opts.slice(0, 1).pop() };
  option.code = null;

  if (valueField) {
    option[valueField] = null;
    option[labelField] = propName;
  } else {
    option.id = null;
    option.description = propName;
  }

  const hasOption = opts.find(
    (op) => { return op.description === propName || op[labelField] === propName; },
  );

  if (noOption && !hasOption) {
    opts.unshift(option);
  }
  
  const generateList = () => {
    const renderedOpts = opts.map((opt) => {
      return {
        label: showId ? `${opt[valueField]} - ${opt[labelField]}` : `${opt[labelField]}`,
        value: opt[valueField],
      };
    });

    if (addOptionText.length) {
      renderedOpts.unshift({
        label: `<Add, change, or delete ${addOptionText.toLowerCase()}>`,
        value: 'openModal',
      });
    }
    if (addEmptyValue) {
      renderedOpts.unshift(prependedOption);
    }
    
    return renderedOpts;
  };

  const renderedOpts = generateList();
  
  useEffect(() => {
    if (value === null) return setRenderedVal(null);
    const newValue = renderedOpts.find((o) => {
      return `${o.value}`.trim() === `${value}`.trim();
    });
    setRenderedVal(newValue);
  }, [value]);

  const handleChange = (optionObject: { label: string, value: string | number }, _query: string) => {
    let newVal: string | null;
    
    if (optionObject?.value) {
      if (typeof optionObject.value === 'string' && optionObject.value === 'openModal' && !_query.length) {
        setShowModal(true);
        return;
      }
      if (optionObject.value === 'openModal') return; // at this point we're bypassing open modal so we don't want to continue
      
      newVal = `${optionObject.value}`.trim();
    } else {
      newVal = null;
    }
    setRenderedVal(renderedOpts.find((o) => {
      return `${o.value}`.trim() === newVal;
    }));
    setValue?.(name, newVal, {
      shouldDirty: shouldDirty,
    });
    
    if (onChange) onChange(optionObject.value);
  };  
  
  const filterOptions = (
    candidate: { label: string; value: string; data: any },
    input: string,
  ) => {
    if (input) {
      return candidate.value === 'openModal'
        || candidate.value.toLowerCase().trim().includes(input.toLowerCase().trim())
        || candidate.label.toLowerCase().trim().includes(input.toLowerCase().trim());
    }
    return true;
  };
  
  const handleInputChange = (newValue: string, _actionMeta: InputActionMeta, _query: string) => {
    setQuery(newValue);
    
    if (!newValue?.length) return;
    
    const filteredOpts = renderedOpts?.filter((opt) => opt.value !== 'openModal');
    const matchedVal = filteredOpts?.find((opt) =>
      String(opt.value).toLowerCase().trim().includes(newValue.toLowerCase().trim())
      || opt.label.toLowerCase().trim().includes(newValue.toLowerCase().trim()));
    
    if (!matchedVal || matchedVal.value === 'openModal') {
      setRenderedVal(filteredOpts?.find((opt) => opt.value === value) ?? filteredOpts?.[0]);
      setShowAddMissingConfirmation(true);
      setSaveOnClose(false);
     
      return;
    }
    
    setSaveOnClose(true);
    setRenderedVal(matchedVal);
  };
  
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, _query: string) => {
    // don't do anything if user isn't hitting enter OR if no query so we don't override default enter behavior.
    if (!(e.key === 'Enter' || e.key === 'Return') || !_query.length) return;
    
    /* if we have the "add" option, grab the first value that is not open modal whose value includes the query OR  */
    const newVal = addOptionText.length
      ? renderedOpts?.filter((opt) => opt.value !== 'openModal'
        && String(opt.value).toLowerCase().trim().includes(_query.toLowerCase().trim())
        || opt.label.toLowerCase().trim().includes(_query.toLowerCase().trim()))?.[0]
      : renderedOpts?.filter((opt) => opt.value !== 'openModal')?.[0];
    if (!newVal) {
      const valToRender = opts.find((opt) => String(opt[valueField]) === String(value));

      setShowAddMissingConfirmation(true);
      setRenderedVal({ label: valToRender[labelField], value: valToRender[valueField] }); // skip Add option
      
      return;
    }
    
    setRenderedVal(newVal); // skip Add option
    setValue?.(name, newVal.value, {
      shouldDirty: shouldDirty,
    });
    
    if (selectOnEnter && onChange) onChange(newVal.value);
  };
  
  const handleMenuClose = () => {
    if (!saveOnClose) return;
    setValue?.(name, renderedVal.value, {
      shouldDirty: shouldDirty,
    });
    onChange?.(renderedVal.value);
  };
  
  const handleConfirmAddNew = (confirm: boolean) => {
    setShowAddMissingConfirmation(false);
    
    if (!confirm) {
      setAddMissingOption(false);
      return;
    }
    
    setAddMissingOption(true);
    setShowModal(true);
  };
  
  return (
    <>
      {visible ? 
        (
          <>
            <div
              title={renderedOpts?.find((opt) => String(opt.value) === String(value))?.label}
              className={groupClass2}
              style={groupStyle}
              aria-disabled={disabled || readOnly}
              id={portalTargetId}
            >
              <Labels
                id={id}
                label={label}
                tallLabel={tallLabel}
                labelClass={labelClass}
                hasError={!!errors}
                required={required}
              />
              <Select
                inputId={id}
                styles={compStyles as S.StylesConfigType}
                options={renderedOpts as S.OptionGroupType}
                value={renderedVal}
                getOptionLabel={(optionData) => `${optionData?.label}`.trim()}
                getOptionValue={(optionData) => `${optionData?.value}`.trim()}
                onChange={(optObject) => { handleChange(optObject, query); }}
                closeMenuOnSelect={true}
                isSearchable={searchable}
                isDisabled={disabled || readOnly}
                isClearable={false}
                required={required}
                menuPlacement="auto"
                menuPortalTarget={(portalTargetId) ? document.getElementById(portalTargetId) : document.body}
                menuPosition="fixed"
                filterOption={filterOptions}
                onInputChange={(newValue, actionMeta) => { handleInputChange(newValue, actionMeta, query); }}
                onKeyDown={(e) => { handleKeyDown(e, query); }}
                onFocus={onFocus}
                menuIsOpen={persistMenuOpen}
                onMenuClose={handleMenuClose}
              />
              <small className="text-danger">{errors?.message}</small>
            </div>
            {showModal && formComponent && options ? (
              <DropdownAddEditModal
                options={opts.filter((o) => { return !o[labelField].includes('<no'); })}
                labelField={labelField}
                valueField={valueField}
                idField={idField ?? valueField}
                header={modalTitle}
                idEditable={idEditable}
                isEditable={isEditable}
                dropdownName={dropdownName}
                show={showModal}
                onHide={() => { return setShowModal(false); }}
                formComponent={formComponent}
                addFormOnRender={addMissingOption}
              />
            ) : null}
            {showModal && CustomFormComponent ? (
              <CustomFormComponent
                show={showModal}
                onHide={() => { return setShowModal(false); }}
              />
            ) : null}
            {showAddMissingConfirmation && (
              <ConfirmationModal
                title="Notice"
                show={showAddMissingConfirmation}
                onHide={() => {
                  setShowAddMissingConfirmation(false);
                }}
                message="An invalid option was entered. Do you want to add a new one?"
                onConfirmed={handleConfirmAddNew}
              />
            )}
          </>
        ) : null}
    </>
  );
};
