import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InterviewQuestion } from 'core/models';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { InputGrp, TextareaGrp } from 'core/components/form-controls';
import { postInterviewQuestion } from 'core/store/slices/applicantTracking.slice';
import Modal from 'core/components/modals/Modal';
import ConfirmationModal from 'core/components/modals/confirmation.modal';

type Props = {
  show:boolean;  
  item:InterviewQuestion | undefined,
  onHide: ()=> void;
};

const InterviewQuestionModal: React.FC<Props> = ({
  show,
  item,
  onHide,
}) => {

  const dispatch = useAppDispatch();
  const [client] = useAppSelector(
    (state) => {
      return [
        state.client.client];
    },
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const { register, reset, handleSubmit, formState:{ isDirty } } = useForm<InterviewQuestion>({
    defaultValues: { ...item,
      interviewQuestionId: item?.interviewQuestionId ?? 0 },
  });
  
  useEffect(() => {    
    item && reset(item);   
  }, [item]);

  const hideModal = () => {  
    if (isDirty) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };

  const onSubmit = (data: any) => {
   
    const ip:InterviewQuestion = item ? {
      ...item,        
      title: data.title,
      description: data.description,
    } : {
      interviewQuestionId: 0,
      interviewTemplateId:0,
      hrEntityId: client?.hrEntityId ?? 0,
      title: data.title,
      description: data.description,     
      isDefault: false,
    };

    dispatch(postInterviewQuestion(ip));
    reset(ip);
  };
  
  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };
  
  return (
    
    <Modal
      show={show}
      onHide={hideModal}
      isDirty={isDirty}
      dialogClassName="lg"      
      backdrop={'static'}
      title="Design Interview Question"
    >     
     
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="d-flex flex-column flex-grow-1"
        >  
          <input
            type="hidden"
            name={'interviewQuestionId'}          
            ref={register({ valueAsNumber: true })}
          />      
          <div>
            <InputGrp
              label={'Question Name'}
              name={'title'}
              defaultValue={item?.title}
              ref={register}
            />
          </div>             
          <div>
            <TextareaGrp
              label={'Question Description'}
              name={'description'}
              defaultValue={item?.description}
              ref={register}
            />
          </div>
        </div>     
        <div className="d-flex mt-3">
          <button
            type="button"
            className="orange-outline-button ml-auto mr-2"
            onClick={hideModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="orange-button mr-2"
          >
            Save
          </button>
        </div>
      </form>
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
    </Modal>
  );
};
export default InterviewQuestionModal;
