import React from 'react';
import CheckBoxForm from '../../Shared/CheckBoxForm';
import { Col, Row } from 'react-bootstrap';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for Checkbox and Grid select forms
const SpecialInformationFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  return (
    <Row>
      <Col sm={4}>
        <Row>
          {!!sectionFields?.length && (
            <Col sm={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'Special Info';
                })?.fields}
                section="Special Info"
                title="Special Info"
              />
            </Col>
          )}
        </Row>
      </Col>
      <Col sm={8}>
        <Row>
          {!!sectionFields?.length && (
            <Col sm={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'Special Dates';
                })?.fields}
                section="Special Dates"
                title="Special Dates"
              />
            </Col>
          )}
          {!!sectionFields?.length && (
            <Col sm={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'CARES Act';
                })?.fields}
                section="CARES Act"
                title="CARES Act"
              />
            </Col>
          )}
          {!!sectionFields?.length && (
            <Col sm={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'COVID-19';
                })?.fields}
                section="COVID-19"
                title="COVID-19"
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default SpecialInformationFields;