import React, { CSSProperties, useContext, useState } from 'react';
import { InputGrp, InputGrpDecimal, SelectGrp } from 'core/components/form-controls';
import styles from './styles.module.scss';
import { useHistory } from 'react-router-dom';
import Icon from 'core/components/shared/Icon';
import { TimeSheetSummaryContext, TimeSheetSummaryFilter } from './TimeSheetSummary';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { onCellDetailClick } from '../utilities';
import JobNoModal from '../modals/JobNo.modal';
import SubNoModal from '../modals/SubNo.modal';
import CostCodeModal from '../modals/CostCode.modal';
import { createToggleShow, formatWithCommas } from 'utilities/utilities';
import { DetailArrayField, DetailHour, TimeCardDetail } from 'core/models';
import { useSelector } from 'react-redux';
import { getEarningsCodes } from 'core/store/selectors';
import SummaryEarningsModal from '../modals/SummaryEarnings.modal';
import { inputStyle } from '../InputSection';
import ModalInput from './ModalInput';

const makeGrpStyle = (maxWidth = '100px') => {
  const infoGrpStyle: CSSProperties = {
    marginBottom: '0',
    maxWidth: maxWidth,
    minWidth: '70px',
  };

  return infoGrpStyle;
};

type Props = {
  detailIndex: number;
  timeCardIndex: number;
  timeCardDetail: DetailArrayField;
  transmittalTimeCardId: number;
  filters: TimeSheetSummaryFilter;
  removeDetail: (timeCardId: number, detailId: number) => void;
  setFilters: (newFilter: TimeSheetSummaryFilter) => void;
};

const SummaryItem = ({
  detailIndex,
  timeCardDetail,
  timeCardIndex,
  transmittalTimeCardId,
  filters,
  removeDetail,
  setFilters,
}: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { timeCards, timeCardCrewSheetColumns, timeCardDates } = useAppSelector(({ contractor }) => contractor);
  const { rateMaster } = useAppSelector(({ contractorReports }) => contractorReports);
  const earningsCodes = useSelector(getEarningsCodes);

  const [showModal, setShowModal] = useState<{ [key: string]: boolean }>({ jobNo: false, subNo: false, costCode: false, cityCode: false, earnings: false });
  const toggleShowModal = createToggleShow(setShowModal);

  const { updateTimeCardState } = useContext(TimeSheetSummaryContext);

  // TODO: really don't want to do this twice for each item...
  const currentHours = timeCardDetail?.hours?.find(x => x.dateId === filters.dayOfWeek);
  const currentHoursIndex = timeCardDetail?.hours?.findIndex(x => x.dateId === filters.dayOfWeek) ?? -1;
  
  function updateDetail<T extends keyof TimeCardDetail>(field: T, value: TimeCardDetail[T]) {
    updateTimeCardState({
      type: 'UPDATE_DETAIL',
      timeCardId: (timeCardDetail?.transmittalTimeCardId ?? 0),
      detailId: (timeCardDetail?.transmittalTimeCardDetailId ?? 0),
      field: field,
      value: value,
    });
  }
  
  function onChangeEarningsCode(e: React.ChangeEvent<HTMLSelectElement>) {
    setFilters({ ...filters, triggerReRender: !filters.triggerReRender });
    updateTimeCardState({
      type: 'UPDATE_EARNINGS_CODE',
      timeCardId: timeCardDetail?.transmittalTimeCardId ?? 0,
      timeCardDetailId: timeCardDetail?.transmittalTimeCardDetailId ?? 0,
      dateId: timeCardDates[0].dateId,
      value: e.target.value,
    });
  }
  
  function updateHoursRecord<T extends keyof DetailHour>(field: T, value: any /* DetailHour[T] */) {
    // this action will create a new DetailHour record if we need it. Look in summary.reducer.ts for implementation
    updateTimeCardState({
      type: 'UPDATE_DETAIL_HOUR',
      timeCardId: timeCardDetail?.transmittalTimeCardId ?? 0,
      detailId: timeCardDetail?.transmittalTimeCardDetailId ?? 0,
      detailHourId: currentHours?.transmittalTimeCardDetailHoursId ?? 0,
      dateId: filters.dayOfWeek === -1 ? timeCardDates[0].dateId : filters.dayOfWeek,
      earningsCode: timeCardDetail?.hours?.[0]?.earningsCode ?? earningsCodes[0].id,
      field,
      value,
    });
  }
  
  return (
    <li
      key={detailIndex}
      className="card-header-row justify-content-between"
    >
      {/*Note: The crew sheet column lets them hide or show specific columns so based on the selection we will hide or show it. I did it this way so the header is also hidden*/}
      <div className={styles['header-info-wrap']}>
        {!timeCardCrewSheetColumns?.hideArea || false ?
          <InputGrp
            label="Area"
            groupStyle={makeGrpStyle()}
            name={`timeCardItems[${timeCardIndex}].details[${detailIndex}].area`}
            value={timeCardDetail?.area}
            disabled={true}
            inputStyle={inputStyle}
            onChange={(e: any) => {
              updateDetail('area', e.target.value);
            }}
          /> : null
        } 
        {!timeCardCrewSheetColumns?.hideTrade || false ?
          <InputGrp
            label="Trade"
            groupStyle={makeGrpStyle()}
            name={`timeCardItems[${timeCardIndex}].details[${detailIndex}].trade`}
            value={timeCardDetail?.trade}
            inputStyle={inputStyle}
            disabled={true}
          /> : null
        }
        {!timeCardCrewSheetColumns?.hideSub || false ?
          <InputGrp
            label="Sub"
            groupStyle={makeGrpStyle()}
            name={`timeCardItems[${timeCardIndex}].details[${detailIndex}].sub`}
            value={timeCardDetail?.sub}
            inputStyle={inputStyle}
            disabled={true}
          /> : null
        }
        {!timeCardCrewSheetColumns?.hideSubDesc || false ?
          <InputGrp
            label="Sub Description"
            groupStyle={makeGrpStyle('200px')}
            value={rateMaster?.find(x => x.sub === timeCardDetail.sub)?.subDescription || ''}
            inputStyle={{ height: '26px' }}
            disabled={true}
          /> : null
        }
        {!timeCardCrewSheetColumns?.hideJobNo || false ?
          <ModalInput
            name={`timeCardItems[${timeCardIndex}].details[${detailIndex}].jobNumber`}
            modalName="Job No"
            groupStyle={makeGrpStyle()}
            value={timeCardDetail.jobNumber ?? ''}
            onClick={() => { toggleShowModal('jobNo', true); }}
          /> : null
        }
        {!timeCardCrewSheetColumns?.hideSubNo || false ?
          <ModalInput
            name={`timeCardItems[${timeCardIndex}].details[${detailIndex}].subNumber`}
            modalName="Sub No"
            groupStyle={makeGrpStyle()}
            value={timeCardDetail.subNumber ?? ''}
            onClick={() => { toggleShowModal('subNo', true); }}
          /> : null
        }
        {!timeCardCrewSheetColumns?.hideFoExtra || false ?
          <InputGrp
            label="FO/Extra"
            groupStyle={makeGrpStyle()}
            name={`timeCardItems[${timeCardIndex}].details[${detailIndex}].foExtra`}
            defaultValue={timeCardDetail?.foExtra ?? ''}
            onChange={(e: any) => {
              updateDetail('foExtra', e.target.value);
            }}
            inputStyle={inputStyle}
          /> : null
        }
        {!timeCardCrewSheetColumns?.hidePhase || false ?
          <InputGrp
            label="Phase"
            groupStyle={makeGrpStyle()}
            name={`timeCardItems[${timeCardIndex}].details[${detailIndex}].phase`}
            defaultValue={timeCardDetail?.phase ?? ''}
            onChange={(e: any) => {
              updateDetail('phase', e.target.value);
            }}
            inputStyle={inputStyle}
          /> : null
        }
        {!timeCardCrewSheetColumns?.hideArea || false ?
          <ModalInput
            name={`timeCardItems[${timeCardIndex}].details[${detailIndex}].costCode`}
            modalName="Cost Code"
            groupStyle={makeGrpStyle()}
            value={timeCardDetail.costCode ?? ''}
            onClick={() => { toggleShowModal('costCode', true); }}
          /> : null}
        {/* show if we aren't hiding hours and dayOfWeek isn't "ALL" (-1) */}
        {!(timeCardCrewSheetColumns?.hideHours || filters.dayOfWeek === -1) ?
          <InputGrp
            label="Hours"
            groupStyle={makeGrpStyle()}
            name={`timeCardItems[${timeCardIndex}].details[${detailIndex}].hours[${currentHoursIndex}].hours`}
            defaultValue={formatWithCommas(timeCardDetail?.hours?.[currentHoursIndex]?.hours ?? 0)}
            inputStyle={{ height: '26px', textAlign: 'right' }}
            onChange={(e: any) => {
              updateHoursRecord('hours', e.target.value);
            }}
          /> : null
        }
        {!timeCardCrewSheetColumns?.hideHours || false ?
          <SelectGrp
            label="Hours Code"
            options={earningsCodes}
            name={`timeCardItems[${timeCardIndex}].details[${detailIndex}].hours[${currentHoursIndex}].earningsCode`}
            groupStyle={makeGrpStyle()}
            showId={true}
            defaultValue={timeCardDetail?.hours?.[0]?.earningsCode}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onChangeEarningsCode(e);
            }}
          /> : null
        }
        {/* show if we aren't hiding notes and dayOfWeek isn't "ALL" (-1) */}
        {!(timeCardCrewSheetColumns?.hideNotes || filters.dayOfWeek === -1) ?
          <InputGrp
            label="Notes"
            name={`timeCardItems[${timeCardIndex}].details[${detailIndex}].hours[${currentHoursIndex}].notes`}
            defaultValue={(currentHoursIndex === -1) ? '' : timeCardDetail?.hours?.[currentHoursIndex]?.notes}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateHoursRecord('notes', e.target.value);
            }}
            groupStyle={makeGrpStyle('200px')}
            inputStyle={{ height: '26px' }}
          /> : null
        }
        <button
          type="button"
          className={'btn btn-link'}
          style={{ marginTop: '10px' }}
          onClick={() => toggleShowModal('earnings', true)}
          hidden={timeCardCrewSheetColumns?.hideEarnings || false}
        >
          View Earnings
        </button>
        <div className="d-flex ml-auto">
          <button
            type="button"
            className={'btn btn-link'}
            onClick={() => onCellDetailClick(transmittalTimeCardId, timeCards, history, dispatch)}
          >
            Open Time Sheet
          </button>
          <button
            type="button"
            className={`btn btn-link ml-auto ${styles['row-delete-btn']}`}
            onClick={() => {
              removeDetail((timeCardDetail?.transmittalTimeCardId ?? 0), (timeCardDetail?.transmittalTimeCardDetailId ?? 0));
            }}
          >
            Delete&nbsp;<Icon
              name="circle-minus"
              className="fa-minus-circle"
            />
          </button>
        </div>
        {showModal.jobNo && (
          <JobNoModal
            show={showModal.jobNo}
            onHide={() => { toggleShowModal('jobNo', false); }}
            setControlledField={updateDetail}
          />
        )}
        {showModal.subNo && (
          <SubNoModal
            show={showModal.subNo}
            onHide={() => { toggleShowModal('subNo', false); }}
            setControlledField={updateDetail}
          />
        )}
        {showModal.costCode && (
          <CostCodeModal
            show={showModal.costCode}
            onHide={() => { toggleShowModal('costCode', false); }}
            setControlledField={updateDetail}
          />
        )}
        {showModal.earnings && (
          <SummaryEarningsModal
            timeCardId={timeCardDetail?.transmittalTimeCardId ?? 0}
            otherEarnings={timeCardDetail?.otherEarnings ?? []}
            showLabel={true}
            detailIndex={detailIndex}
            timeCardIndex={timeCardIndex}
            show={showModal.earnings}
            canEdit={true}
            timeCardDetailId={timeCardDetail?.transmittalTimeCardDetailId ?? 0}
            onHide={() => {toggleShowModal('earnings', false);}}
          />
        )}
      </div>
    </li>
  );
};

export default SummaryItem;