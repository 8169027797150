import Icon from 'core/components/shared/Icon';
import { getInterviewLocation } from 'core/store/slices/applicantTracking.slice';
import { IconTypes } from 'enums/IconTypes';
import { get, noop } from 'lodash';
import React, { CSSProperties, useState, useMemo, useEffect } from 'react';
import { Accordion, Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import InterviewQuestionsReportModal from './InterviewQuestionsReportModal';
import { toReadableDate, toReadableTime } from 'utilities/utilities';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import ScoreCardRatingModal from './ScoreCardRatingModal';
import { ControlDatePickerGrp, ControlTimePickerGrp, InputGrp, Label } from 'core/components/form-controls';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { StylesConfigType, compStyles } from 'utilities/styles';
import InterviewLocationsDropDown from './InterviewLocations';
import { getInterviewQuestionsReport, updateApplicantInterview, sendApplicantInterviewEmails } from 'core/store/actions/applicant-tracking.action';
import { Interview, Interviewer, ScoreCard } from 'core/models';

type Props = {
  interview: Interview;
  onDelete: (id: { applicantId: number; interviewId: number }) => void;
};

const starsTooltipLabel: { [key: number]: string } = {
  1: 'Strong No',
  2: 'No',
  3: 'Undecided',
  4: 'Yes',
  5: 'Strong Yes',
};

const ATSInterview = ({
  interview,
  onDelete,
}: Props) => {
  const defaultDataTime = {
    date: interview.date || '',
    time: interview.time || '',
  };
  const [questionAnswersField, setQuestionAnswersField] = useState(
    interview?.questionAnswers || [],
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState(interview?.type);
  const [isCompleted, setIsCompleted] = useState(!!interview.completed);
  const appDispatch = useAppDispatch();
  const [selectedInterviewers, setSelectedInterviewer] = useState(interview.interviewers);
  const [title, setTitle] = useState(interview.title);
  const [description, setDescription] = useState(interview.description);
  const [sendToApplicant, setSendToApplicant] = useState(false);
  const [sendToInterviewers, setSendToInterviewers] = useState(false);
  const [attachResume, setAttachResume] = useState(false);
  const [attachQuestions, setAttachQuestions] = useState(false);
  const [emailType, setEmailType] = useState('');
  const [dataTime, setDateTime] = useState(defaultDataTime);
  
  useEffect(() => {
    setSelectedInterviewer(interview.interviewers);
    setQuestionAnswersField(interview?.questionAnswers || []);
  }, [interview]);
  
  const { interviewQuestionsReport, payrollUsers } = useAppSelector((state) => {
    return state.applicantTrackingV2;
  });
  const [interviewLocation] = useAppSelector(
    (state) => {
      return [state.applicantTracking.interviewLocation];
    },
  );
  const client = useAppSelector((state) => {
    return state.client.client;
  });
  const { active, customInterviewEmails } = useAppSelector((state) => {
    return state.applicantTrackingV2;
  });
  const interviewTemplates = useAppSelector(({ applicantTracking }) => applicantTracking.interviewTemplates);
  const currentTemplate = interviewTemplates?.find((template) => template.title === title);

  const emailTypeOptions = useMemo(() => {
    return (
      customInterviewEmails?.map((email) => {
        return {
          value: email.customEmailId,
          label: email.emailSubject,
        };
      }) || []
    );
  }, [customInterviewEmails]);

  const [questionReportModal, setQuestionReportModal] = useState(false);

  const { control, errors } = useForm({
    defaultValues: {
      date: interview.date || '',
      time: interview.time || '',
    },
  });
  const { date, time } = dataTime;

  const interviewerOptions = useMemo(() => {
    return (
      payrollUsers?.map((user) => {
        return {
          value: user.secUserId,
          label: user.name,
        };
      }) || []
    );
  }, [payrollUsers, selectedInterviewers]);

  const [location, setLocation] = useState(interview.interviewLocationId || '');
  
  const onReportRequest = () => {
    appDispatch(getInterviewQuestionsReport({
      applicantId: interview.applicantId,
      interviewId: interview.interviewId,
    }));
    appDispatch(getInterviewLocation(interview.interviewLocationId ?? 0));
    setQuestionReportModal(true);
  };

  useEffect(() => {
    if (interview.interviewLocationId)
      appDispatch(getInterviewLocation(interview.interviewLocationId));
  }, [interview.interviewLocationId]);

  const toolTipStyles: CSSProperties = {
    fontSize: '0.75rem',
    textAlign: 'left',
  };

  const questionAnswerHandler = (
    id: number,
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    const updatedValue = questionAnswersField.map((data) => {
      if (data.interviewQAId === id) {
        return {
          ...data,
          answer: event.currentTarget.value,
        };
      } else {
        return data;
      }
    });
    setQuestionAnswersField(updatedValue);
  };

  const wait = (ms: number) => {
    return new Promise((resolve) => {
      return setTimeout(resolve, ms, {});
    });
  };

  const disabledClick: boolean = !date || !time;

  const sendEmails = async () => {
    appDispatch(
      updateApplicantInterview({
        applicantId: active?.applicantId || 0,
        interviewId: interview.interviewId || 0,
        params: {
          ...interview,
          interviewId: interview.interviewId || 0,
          applicantId: active?.applicantId,
        },
      }),
    );
    await wait(1000);
    appDispatch(
      sendApplicantInterviewEmails({
        applicantId: interview.applicantId || active?.applicantId || 0,
        interviewId: interview.interviewId || 0,
        params: {
          applicantId: active?.applicantId,
          interviewId: interview.interviewId || 0,
          customEmailId: emailType || emailTypeOptions?.[0]?.value || 0,
          sendToApplicant,
          sendToInterviewers,
          attachResume,
          attachQuestions,
        },
      }),
    );
  };

  const saveHandler = () => {
    if (disabledClick) {
      toast.error(`${!date ? 'Date' : ''} ${!time ? 'Time' : ''} can't be empty`);
    } else {
      appDispatch(
        updateApplicantInterview({
          applicantId: active?.applicantId || 0,
          interviewId: interview.interviewId || 0,
          params: {
            ...interview,
            interviewId: interview.interviewId || 0,
            applicantId: active?.applicantId,
            completed: isCompleted,
            dateCompleted: isCompleted ? new Date() : null,
            questionAnswers: questionAnswersField,
            date: date || null,
            time: time || null,
            interviewers: selectedInterviewers || [],
            interviewLocationId: location || interview.interviewLocationId || null,
          },
        }),
      );
      setIsEditing(false);
    }
  };

  const onChangeHandler = (e: any, itemIndex: number) => {
    const updatedValue = selectedInterviewers?.map((data: any, index: number) => {
      const selectedValue = itemIndex === index;
      if (selectedValue) {
        return {
          ...data,
          name: e.label,
          secUserId: e.value,
        };
      } else {
        return data;
      }
    });
    setSelectedInterviewer(updatedValue);
  };

  const onClickEdit = () => {
    if (!isEditing) {
      setDateTime(defaultDataTime);
      setLocation(interview.interviewLocationId ?? 0);
    }
    setIsEditing(!isEditing);
    if (isEditing) {

    }
  };

  const addInterviewer = () => {
    const defaultScorecardsFromTemplate: ScoreCard[] = currentTemplate?.scorecardCategories?.flatMap((category) => {
      return category?.traits.map((trait) => ({
        secUserId: 0,
        interviewId: interview.interviewId,
        applicantId: interview.applicantId,
        interviewerScorecardId: 0,
        category: category.category,
        trait: trait.trait,
        rating: null,
      }));
    }) ?? [];
    const addedValue: Interviewer[] = [
      ...(selectedInterviewers?.length ? selectedInterviewers : []),
      {
        applicantId: interview.applicantId,
        hrEntityId: client?.hrEntityId ?? 0,
        interviewId: interview.interviewId,
        interviewerScorecards: defaultScorecardsFromTemplate,
        name: '',
        notes: '',
        rating: 0,
        scorecardRating: 0,
        secUserId: 0,
      },
    ];
    setSelectedInterviewer([...addedValue]);
  };

  const deleteInterviewer = (itemIndex: number) => {
    const updatedValue = selectedInterviewers?.filter((_, index) => {
      return index !== itemIndex;
    });
    setSelectedInterviewer(updatedValue);
  };

  const renderToolTip = (props: OverlayInjectedProps) => {
    return (
      <Tooltip
        id="info-tooltip"
        {...props}
      >
        <div style={toolTipStyles}>
          <div>
            <Icon
              name="star"
              type={IconTypes.SOLID}
              color="#e6ac29"
            />
            <span className="ml-2">{starsTooltipLabel[1]}</span>
          </div>
          <div>
            <Icon
              name="star"
              type={IconTypes.SOLID}
              color="#e6ac29"
            />
            <Icon
              name="star"
              type={IconTypes.SOLID}
              color="#e6ac29"
            />
            <span className="ml-2">{starsTooltipLabel[2]}</span>
          </div>
          <div>
            <Icon
              name="star"
              type={IconTypes.SOLID}
              color="#e6ac29"
            />
            <Icon
              name="star"
              type={IconTypes.SOLID}
              color="#e6ac29"
            />
            <Icon
              name="star"
              type={IconTypes.SOLID}
              color="#e6ac29"
            />
            <span className="ml-2">{starsTooltipLabel[3]}</span>
          </div>
          <div>
            <Icon
              name="star"
              type={IconTypes.SOLID}
              color="#e6ac29"
            />
            <Icon
              name="star"
              type={IconTypes.SOLID}
              color="#e6ac29"
            />
            <Icon
              name="star"
              type={IconTypes.SOLID}
              color="#e6ac29"
            />
            <Icon
              name="star"
              type={IconTypes.SOLID}
              color="#e6ac29"
            />
            <span className="ml-2">{starsTooltipLabel[4]}</span>
          </div>
          <div>
            <Icon
              name="star"
              type={IconTypes.SOLID}
              color="#e6ac29"
            />
            <Icon
              name="star"
              type={IconTypes.SOLID}
              color="#e6ac29"
            />
            <Icon
              name="star"
              type={IconTypes.SOLID}
              color="#e6ac29"
            />
            <Icon
              name="star"
              type={IconTypes.SOLID}
              color="#e6ac29"
            />
            <Icon
              name="star"
              type={IconTypes.SOLID}
              color="#e6ac29"
            />
            <span className="ml-2">{starsTooltipLabel[5]}</span>
          </div>
        </div>
      </Tooltip>
    );
  };
  
  const [selectedScoreCardRating, setSelectedScoreCardRating] = useState<{ item: Interviewer, itemIndex: number } | null>(null);
  
  const selectCard = (item: Interviewer, itemIndex: number) => {
    setSelectedScoreCardRating({
      item,
      itemIndex,
    });
  };

  return (
    <Accordion>
      <Card>
        <InterviewQuestionsReportModal
          show={questionReportModal}
          pdf={interviewQuestionsReport?.value}
          onHide={() => { setQuestionReportModal(false); }}
        />
        <ScoreCardRatingModal
          title={interview.title}
          show={!!selectedScoreCardRating?.item}
          selectedScoreCardRating={selectedScoreCardRating}
          setSelectedScoreCardRating={setSelectedScoreCardRating}
          setSelectedInterviewer={setSelectedInterviewer}
          selectedInterviewers={selectedInterviewers}
          isEditing={isEditing}
          onHide={() => { setSelectedScoreCardRating(null); }}
          onAccept={() => {
            toast.success('Score Card Rating has been saved');
          }}
        />
        <Card.Header className="p-3">
          <Row>
            <Col sm={6}>
              <Accordion.Toggle
                eventKey={String(interview.interviewId)}
                className="d-flex align-items-center"
                style={{
                  background: 'transparent',
                  border: 'none',
                }}
                onClick={() => { setIsOpen(!isOpen); }}
              >
                <Icon
                  name={isOpen ? 'chevron-up' : 'chevron-down'}
                  className="mr-1"
                />
                <p
                  style={{ fontSize: '1rem' }}
                  className="mb-0"
                >
                  {title}
                  <span
                    className="ml-2 display-inline-block"
                    style={{
                      color: '#afafaf',
                      fontSize: '0.8rem',
                    }}
                  >
                    {description}
                  </span>
                </p>
              </Accordion.Toggle>
            </Col>
            <Col sm={3}>
              <Row>
                <button
                  type="button"
                  onClick={onReportRequest}
                  className="d-flex align-items-center"
                  style={{
                    gap: '0.3rem',
                    background: 'transparent',
                    border: 'none',
                    color: '#00558c',
                  }}
                >
                  Questions Report
                </button>
                <button
                  type="button"
                  className="bg-transparent d-flex align-items-center"
                  style={{ border: 'none' }}
                  onClick={onClickEdit}
                >
                  <Icon
                    name={isEditing ? 'x' : 'edit'}
                    fontSize={12}
                    className="mr-1"
                  />
                  {isEditing ? 'Cancel' : 'Edit'}
                </button>
              </Row>
            </Col>
            <Col
              sm={3}
              className="d-flex align-items-center justify-content-end"
            >
              <button
                type="button"
                className="d-flex align-items-center justify-content-end p-0"
                style={{
                  border: 'none',
                  background: 'transparent',
                }}
                onClick={() => {
                  if (isEditing) {
                    setIsCompleted(!isCompleted);
                  }
                }}
              >
                <Icon
                  name={isCompleted ? 'square-check' : 'square'}
                  type={isCompleted ? IconTypes.SOLID : IconTypes.REGULAR}
                  className="mr-1"
                />
                Completed
              </button>
            </Col>
          </Row>
        </Card.Header>
        <Accordion.Collapse eventKey={String(interview.interviewId)}>
          <Card.Body
            className="p-3"
            style={{ gap: '4rem' }}
          >
            <Row>
              <Col sm={8}>
                <Row className="px-3 pt-3 pb-2">
                  {isEditing ? (
                    <InputGrp
                      label="Name"
                      name="title"
                      placeholder="Name"
                      value={title}
                      disabled
                      onChange={(e: any) => {
                        setTitle(e.target.value);
                      }}
                    />
                  ) : (
                    <p className="font-weight-bold mb-0">
                      Title:{' '}
                      <span className="font-weight-normal">
                        {title || interview.title || ''}
                      </span>
                    </p>
                  )}
                  <p className="ml-4">
                    {isEditing ? (
                      <InputGrp
                        label="Description"
                        name="description"
                        placeholder="Description"
                        disabled
                        value={description}
                        onChange={(e: any) => {
                          setDescription(e.target.value);
                        }}
                      />
                    ) : (
                      <span className="font-weight-bold mb-0 ml-4">
                        Description:{' '}
                        <span className="font-weight-normal">
                          {description || interview.description || ''}
                        </span>
                      </span>
                    )}
                  </p>
                </Row>
                <Row className="px-3 pb-3">
                  {isEditing ? (
                    <InputGrp
                      label="Type"
                      name="type"
                      placeholder="Phone"
                      disabled
                      value={type}
                      onChange={(e: any) => {
                        setType(e.target.value);
                      }}
                    />
                  ) : (
                    <p className="font-weight-bold mb-0">
                      Type:{' '}
                      <span className="font-weight-normal">
                        {type || interview.type || ''}
                      </span>
                    </p>
                  )}
                  <p className="font-weight-bold mb-0 ml-4">
                    {isEditing ? (
                      <ControlDatePickerGrp
                        label="Date *"
                        name="date"
                        control={control}
                        errors={errors.date}
                        value={date}
                        rules={{
                          required: {
                            value: true,
                            message: 'Required',
                          },
                        }}
                        onChange={(value: any) => {
                          setDateTime((prev) => ({ ...prev, 'date': value }));
                        }}
                      />
                    ) : (
                      <>
                        Date:{' '}
                        <span className="font-weight-normal">
                          {toReadableDate(interview?.date || date) || ''}
                        </span>
                      </>
                    )}
                  </p>
                  <p className="font-weight-bold mb-0 ml-4">
                    {isEditing ? (
                      <ControlTimePickerGrp
                        label="Time *"
                        name="time"
                        control={control}
                        onChange={(value: any) => {
                          setDateTime((prev) => ({ ...prev, 'time': value }));
                        }}
                        errors={errors.date}
                        value={time}
                        rules={{
                          required: {
                            value: true,
                            message: 'Required',
                          },
                        }}
                      />
                    ) : (
                      <>
                        Time :{' '}
                        <span className="font-weight-normal">
                          {toReadableTime(interview?.time || time) || ''}
                        </span>
                      </>
                    )}
                  </p>
                  <p>
                    {isEditing ? (
                      <p className="ml-4">
                        <Label
                          id={'location'}
                          hasError={false}
                          text={'Location'}
                          required={true}
                        />
                        <InterviewLocationsDropDown
                          onChange={(e: any) => { setLocation(e.value); }}
                          value={location || interview.interviewLocationId || null}
                        />
                      </p>
                    ) : (
                      <p className="font-weight-bold mb-0 ml-4">
                        Location:{' '}
                        <span className="font-weight-normal">
                          {interviewLocation?.description ?? ''}
                        </span>
                      </p>
                    )}
                  </p>
                </Row>
                <ol
                  className="m-0 py-3 pr-2 pl-4 d-flex flex-column mb-4"
                  style={{
                    background: '#fefae5',
                    gap: '1rem',
                  }}
                >
                  {questionAnswersField.map((qa: any) => {
                    return (
                      <li key={qa.interviewQAId}>
                        <p className="mb-1">{qa.question}</p>
                        <input
                          className="bg-white d-flex align-items-center px-2 w-100"
                          style={{
                            minHeight: '1.8rem',
                            borderRadius: '0.3rem',
                            border: 'none',
                            outline: 'none',
                          }}
                          value={qa.answer}
                          readOnly={!isEditing}
                          onChange={(e) => {
                            return questionAnswerHandler(qa.interviewQAId, e);
                          }}
                        />
                      </li>
                    );
                  })}
                </ol>
              </Col>
              <Col sm={4}>
                <div className="d-flex">
                  <button
                    type="button"
                    disabled={!isEditing}
                    style={{
                      border: 'none',
                      background: 'transparent',
                      padding: '0',
                    }}
                    onClick={isEditing ? addInterviewer : noop}
                  >
                    <span className="d-inline-flex align-items-center">
                      Add Interviewer{' '}
                      <Icon
                        name="plus-circle"
                        className="ml-1 ml-2"
                      />
                    </span>
                  </button>
                </div>
                <Row className="py-1">
                  <Col sm={4}>
                    <span className="font-weight-bold">Interview Team</span>
                  </Col>
                  <Col sm={4}>
                    <OverlayTrigger
                      placement="top"
                      overlay={renderToolTip}
                    >
                      <div className="d-flex align-items-start m-0 ml-1">
                        <span className="font-weight-bold">Overall Rating</span>
                        <Icon
                          name="circle-info"
                          fontSize={'0.75rem'}
                        />
                      </div>
                    </OverlayTrigger>
                  </Col>
                  <Col sm={4}>
                    <span className="font-weight-bold">Score Card Rating</span>
                  </Col>
                </Row>
                {selectedInterviewers?.length && selectedInterviewers?.map((item, itemIndex) => {
                  return (
                    <Row
                      className="py-1"
                      key={itemIndex}
                    >
                      <Col sm={4}>
                        <Row
                          className="py-1"
                          key={itemIndex}
                        >
                          <Col sm={12}>
                            <div
                              key={item.interviewId}
                              className="d-flex align-items-center justify-content-between"
                              style={{ gap: '0.5rem' }}
                            >
                              <Select
                                styles={compStyles as StylesConfigType}
                                options={interviewerOptions}
                                value={interviewerOptions.filter((data: any) => {
                                  return data.value === item.secUserId;
                                })}
                                isDisabled={!isEditing}
                                onChange={
                                  isEditing
                                    ? (e) => {
                                      return onChangeHandler(e, itemIndex);
                                    }
                                    : noop
                                }
                              />
                              <button
                                type="button"
                                style={{
                                  border: 'none',
                                  background: 'transparent',
                                }}
                                disabled={!isEditing}
                                onClick={
                                  isEditing
                                    ? () => {
                                      return deleteInterviewer(itemIndex);
                                    }
                                    : noop
                                }
                              >
                                <Icon name="minus-circle" />
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        sm={4}
                        className="d-flex"
                        style={{ gap: '0.25rem' }}
                      >
                        {[1, 2, 3, 4, 5].map((star) => {
                          return (
                            <OverlayTrigger
                              key={star}
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-${itemIndex}-${star}`}>
                                  {starsTooltipLabel?.[star] || ''}
                                </Tooltip>
                              }
                            >
                              <button
                                type="button"
                                className="p-0 m-0"
                                style={{
                                  background: 'transparent',
                                  border: 'none',
                                }}
                                onClick={() => {
                                  if (isEditing) {
                                    const tempInterviewers = [
                                      ...selectedInterviewers,
                                    ];
                                    const updatedInterviewer = {
                                      ...tempInterviewers[itemIndex],
                                      rating: star || 0,
                                    };
                                    tempInterviewers[itemIndex] =
                                      updatedInterviewer;
                                    setSelectedInterviewer(tempInterviewers);
                                  }
                                }}
                              >
                                <Icon
                                  name="star"
                                  type={
                                    item.rating >= star
                                      ? IconTypes.SOLID
                                      : IconTypes.REGULAR
                                  }
                                  color="#e6ac29"
                                />
                              </button>
                            </OverlayTrigger>
                          );
                        })}
                      </Col>
                      <Col sm={4}>
                        <div className="d-flex align-items-center justify-content-between">
                          <button
                            onClick={() => { selectCard(item, itemIndex); }}
                            type="button"
                            className="btn btn-link pb-0"
                          >
                            Score/View
                          </button>
                          <span>{item.scorecardRating || 0}</span>
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
            {isEditing && <Row className="mb-4">
              <Col sm={12}>
                <Label
                  text="Interview Email Specifications"
                  hasError={false}
                />
              </Col>
              <Col sm={5}>
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    name="sendToApplicant"
                    checked={sendToApplicant}
                    onChange={(e) => {
                      setSendToApplicant(e.target.checked);
                    }}
                    id="sendToApplicant"
                  />
                  <label
                    htmlFor="sendToApplicant"
                    className="mb-0 ml-1"
                  >
                    Send to Applicant
                  </label>
                </div>
              </Col>
              <Col sm={7}>
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    name="attachQuestion"
                    id="attachQuestion"
                    checked={attachQuestions}
                    onChange={(e) => {
                      setAttachQuestions(e.target.checked);
                    }}
                  />
                  <label
                    htmlFor="attachQuestion"
                    className="mb-0 ml-1"
                  >
                    Attach Question List for Interviewers
                  </label>
                </div>
              </Col>
              <Col sm={5}>
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    name="sendToInterviewer"
                    id="sendToInterviewer"
                    checked={sendToInterviewers}
                    onChange={(e) => {
                      setSendToInterviewers(e.target.checked);
                    }}
                  />
                  <label
                    htmlFor="sendToInterviewer"
                    className="mb-0 ml-1"
                  >
                    Send to Interviewers
                  </label>
                </div>
              </Col>
              <Col sm={7}>
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    name="attachResume"
                    id="attachResume"
                    checked={attachResume}
                    onChange={(e) => {
                      setAttachResume(e.target.checked);
                    }}
                  />
                  <label
                    htmlFor="attachResume"
                    className="mb-0 ml-1"
                  >
                    Attach Resume for Interviewers
                  </label>
                </div>
              </Col>
            </Row>}
            <div className="d-flex align-items-center justify-content-betewen">
              <Col sm={4}>
                <Select
                  styles={compStyles as StylesConfigType}
                  placeholder="Email Type"
                  isDisabled={!isEditing}
                  options={emailTypeOptions}
                  onChange={(option) => {
                    setEmailType(get(option, 'value', ''));
                  }}
                />
              </Col>
              <button
                type="button"
                onClick={sendEmails}
                disabled={!isEditing || !date || !time || !emailType}
                className="orange-button mr-2"
              >
                Send Emails
              </button>
            </div>
            <div className="d-flex align-items-center mt-4">
              <button
                type="submit"
                disabled={!isEditing}
                onClick={saveHandler}
                className="orange-button mr-2"
              >
                Save
              </button>
              <button
                type="button"
                disabled={!isEditing}
                style={{
                  background: 'none',
                  border: 'none',
                }}
                onClick={() => {
                  onDelete({
                    applicantId: interview.applicantId,
                    interviewId: interview.interviewId,
                  });
                }}
              >
                Delete
              </button>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default ATSInterview;
