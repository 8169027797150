import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Client, Employee, UserPassword } from '../../models';
import { AppEmpSearch } from '../../models/AppEmpSearch.model';
import { ModuleAccess } from 'core/models/ModuleAccess.model';
import { StreamedUserAccessObject } from '../epics/app.epic';
import { RootState } from '../store';

export const handlePending = createAction<string | null>('[app] HANDLE_PENDING');
export const handleError = createAction<any>('[app] HANDLE_ERROR');
export const handleSuccess = createAction<any>('[app] HANDLE_SUCCESS');
export const handleWarning = createAction<any>('[app] HANDLE_WARNING');

export const storeNext = createAction<string>('[app] STORE_NEXT');

export const loadEmployeeSearch = createAction<any>(
  '[app] LOAD_EMPLOYEE_SEARCH',
);
export const storeEmployeeSearch = createAction<any[]>(
  '[app] STORE_EMPLOYEE_SEARCH',
);

export const showRootModal = createAction<{
  modalType: string;
  modalProps: any;
}>('[app] SHOW_ROOT_MODAL');
export const hideRootModal = createAction('[app] HIDE_ROOT_MODAL');

export const loadAppEmpSearch = createAction<AppEmpSearch>(
  '[app] LOAD_APP_EMPLOYEE_SEARCH',
);
export const loadAppEmpSearchNoModal = createAction<AppEmpSearch>(
  '[app] LOAD_APP_EMPLOYEE_SEARCH_NO_MODAL',
);

export const storeAppEmpSearch = createAction<Employee[]>(
  '[app] STORE_APP_EMPLOYEE_SEARCH',
);

export const loadDefaultUserPassword = createAction<{ secUserId: number }>(
  '[app] LOAD_DEFAULT_USER_PASSWORD',
);

export const storeDefaultUserPassword = createAction<UserPassword>(
  '[app] STORE_DEFAULT_USER_PASSWORD',
);

export const updateDefaultUserPassword = createAction<UserPassword>(
  '[app] UPDATE_DEFAULT_USER_PASSWORD',
);

export const storeLoadState = createAction<boolean>('[app] STORE_FIRST');

export const loadModuleAccess = createAction<StreamedUserAccessObject | undefined>('[app] LOAD_MODULE_ACCESS');
export const loadModuleAccessWithoutLoadingAppData = createAction('[app] LOAD_MODULE_ACCESS_WITHOUT_LOADING_APP_DATA');
export const storeModuleAccess = createAction<ModuleAccess>(
  '[app] STORE_MODULE_ACCESS',
);
export const clearModuleAccess = createAction('[app] CLEAR_MODULE_ACCESS');

export const loadUserAccess = createAction<StreamedUserAccessObject | undefined>('[app] LOAD_USER_ACCESS');

export const loadAppData = createAction<StreamedUserAccessObject | undefined>('[app] LOAD_APP_DATA');

export const loadNOVAData = createAction('[app] LOAD_NOVA_DATA');

export const loadUserAccessAndAppDataSuccess = createAction(
  '[app] LOAD_USER_ACCESS_AND_APP_DATA_SUCCESS',
);

export const setHrButtonLink = createAction<string>('[app] SET_HR_BUTTON_LINK');

export const loadRelatedClients = createAction('[app] LOAD_RELATED_CLIENTS');
export const storeRelatedClients = createAction<Client[]>('[app] STORE_RELATED_CLIENTS');

export const updateDestination = createAction<string>('[app] UPDATE_DESTINATION');

export const runManualCleanup = createAction('[app] RUN_MANUAL_CLEANUP');

export const resetStore = createAction('resetStore');

export const toggleBlockNavigation = createAction<{ block: boolean, type?: 'confirmation' | 'notice' | 'directDeposit', message?: string }>('app/blockNavigation');
