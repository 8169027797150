import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FieldInputSettings } from '../../../core/components/form-controls/types';
import { WorkgroupItem } from '../../../core/models';
import { createClientAODWorkgroup, updateClientAODWorkgroup } from '../../../core/store/actions';
import { InputGrpInLine, SelectGrp } from '../../../core/components/form-controls';
import { getWorkgroupLevels } from '../../../core/store/selectors';

const fs: FieldInputSettings = {
  level: { name: 'level', label: 'Level' },
  name: { name: 'name', label: 'Name', labelWidth: 25 },
  code: { name: 'code', label: 'Code', labelWidth: 25 },
};

type PropTypes = {
  item: any;
  onHide: any;
  workgroups: any[];
  isEdit: boolean;
};

const WorkgroupForm: React.FC<PropTypes> = ({ item, onHide, workgroups, isEdit }) => {
  const dispatch = useDispatch();
  const workGroupLevelOpts = useSelector(getWorkgroupLevels);

  const { register, errors, handleSubmit, reset } = useForm<WorkgroupItem>();

  useEffect(() => {
    // @ts-ignore
    item && reset({ ...item, level: item.level.toString() });
  }, [item]);

  const onSubmit = (data: any) => {
    const wgItem = { ...item, ...data } as WorkgroupItem;
    if (item.level && item.num) {
      wgItem.level = item.level;
      dispatch(updateClientAODWorkgroup(wgItem));
    } else {
      const groups = workgroups.filter(w => {return w.level === +data.level;});
      wgItem.levelName = groups[0].levelName;
      wgItem.expireDate = new Date('12/31/2099');
      dispatch(createClientAODWorkgroup(wgItem));
    }
    onHide(false);
  };

  return (
    <div className="dm-panel dm-panel-border mx-4" style={{ display: 'flex', flexFlow: 'column nowrap' }}>
      <div className="dm-grid-action-title">Add/Edit</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SelectGrp {...fs.level} errors={errors.level} ref={register} options={workGroupLevelOpts} disabled={isEdit} />
        <InputGrpInLine {...fs.name} errors={errors.name} ref={register} />
        <InputGrpInLine {...fs.code} errors={errors.code} ref={register} />

        <div style={{ display: 'flex' }}>
          <button type="button" className="btn btn-primary orange-outline-button ml-auto mr-2" onClick={() => {return onHide(false);}}>Cancel</button>
          <button type="submit" className="orange-button mr-2" >Save</button>
        </div>
      </form>
    </div>
  );
};

export default WorkgroupForm;