import React, { useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearBankDisbursementsReport,
  downloadBankDisbursementsReport,
} from 'core/store/actions/accounting-reports.action';
import { Client, DtoOptions } from 'core/models';
import { BankDisbursementsReportRequest } from 'core/models/AccountingReports.model';
import { getBankDisbursementsReport } from 'core/store/selectors/accounting-reports.selector';
import { useAppSelector } from 'utilities/hooks';
import InputReportDateSelector from 'core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';

const BankDisbursementsReportsPage = () => {
  const dispatch = useDispatch();

  const childRef: React.RefObject<any> = useRef();

  const report = useSelector(getBankDisbursementsReport);
  const client = useAppSelector((state) => {return state.client.client;}) as Client;

  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);

  const downloadReport = () => {
    const submitData: BankDisbursementsReportRequest = {
      clientNo: client.clientNo,
      dtoOptions: reportDates,
      reportType: 'pdf',
    };
    dispatch(downloadBankDisbursementsReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearBankDisbursementsReport());
  };

  return (
    <Row className="w-100">
      <Col>
        <div className="dm-panel dm-panel-border">
          <div className="dm-grid-title">
            Bank Disbursements Reports
          </div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div>
              <InputReportDateSelector
                ref={childRef}
                returnDates={(dates: DtoOptions[]) => {return setReportDates(dates);}
                }
              />
            </div>
            <div className="flex-grow-1 text-right">
              <button
                type="button"
                className="orange-outline-button mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="orange-button mr-2"
                onClick={downloadReport}
                disabled={!reportDates.length}
              >
                Run Report
              </button>
            </div>
          </div>
        </div>
        {report ? (
          <PDFViewerModal
            show={report.length > 0}
            pdfData={report}
            onHide={hidePdfViewer}
            reportName="BankDisbursementsReports"
          />
        ) : null}
      </Col>
    </Row>
  );
};
export default BankDisbursementsReportsPage;
