import React, { CSSProperties } from 'react';
import { Labels } from '.';
import styles from './form-controls.module.scss';
import { FormatOnPreferences, fromCurrency } from 'utilities/utilities';

interface PropTypes {
  id?: string;
  name: string;
  label?: string;
  tallLabel?: boolean;
  labelClass?: string;
  groupClass?: string;
  groupStyle?: CSSProperties;
  inputClass?: string;
  style?: object;
  errors?: any;
  errorMsg?: string;
  required?: boolean;
  defaultValue: any;
  strValue?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onBlur?: (e?: React.FocusEvent<HTMLInputElement>) => any;
  validateSave?: () => any;
  setValue?: any;
  fixed?: number;
  type?: string;
  format?: boolean;
  decimalPlaces?: number;
  formClass?: string;
  labelStyles?: CSSProperties;
}

/**
 * @property {string} id?
 * @property {string} name
 * @property {string | string[]} label?
 * @property {boolean} tallLabel?
 * @property {string} groupClass?
 * @property {object} groupStyle?
 * @property {any} errors?
 * @property {boolean} required?
 */
export const InputGrpDecimal: React.FC<any> = React.forwardRef(
  (
    {
      id,
      name,
      label,
      tallLabel = false,
      labelClass = '',
      groupClass,
      inputClass = '',
      groupStyle,
      style = {},
      required = false,
      errors,
      errorMsg,
      defaultValue,
      strValue,
      disabled = false,
      fixed,
      type = 'number',
      format,
      decimalPlaces,
      validateSave,
      setValue,
      formClass = 'dm-form-group',
      labelStyles,
      ...extraProps
    }: PropTypes,
    ref: any,
  ) => {
    const groupClass2 = styles[formClass] + ' ' + (groupClass ?? '');
    id = id ?? name;
    const errMsg = errors
      ? errors.type === 'validate'
        ? errorMsg
        : errors.message
      : '';

    const formatOnBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
      if (ev.target.value.length === 0) {
        ev.target.value = '0';
      }
      if (ev.target.value !== null && !decimalPlaces) {
        ev.target.value = parseFloat(ev.target.value).toFixed(
          fixed ?? 2,
        );
      }
      if (decimalPlaces) {
        ev.target.value = FormatOnPreferences(ev.target.value, decimalPlaces).toString();
        if (setValue) {
          setValue(name, ev.target.value);
        }
      }
      if (validateSave) {
        validateSave();
      }
    };
    
    return (
      <fieldset
        className={groupClass2}
        style={groupStyle}
        disabled={disabled}
      >
        <Labels
          label={label}
          tallLabel={tallLabel}
          labelClass={labelClass}
          labelStyle={labelStyles}
          id={id}
          hasError={!!errors}
          required={required}
        />
        <input
          ref={ref}
          type={type}
          step={'any'}
          style={{ ...style,
            textAlign: 'right' }}
          className={
            inputClass +
            styles['dm-form-control'] +
            (errors ? ' ' + styles['dm-is-invalid'] : '')
          }
          id={id}
          name={name}
          defaultValue={strValue ? strValue : fromCurrency(defaultValue)}
          onBlur={type === 'number' || format ? formatOnBlur : extraProps?.onBlur}
          {...extraProps}
        />
        <small className="text-danger d-flex">{errMsg} </small>
      </fieldset>
    );
  },
);

InputGrpDecimal.displayName = 'InputGrpDecimal';