import React, { } from 'react';

import {
  InputGrp,
  InputGrpCurrency,
  SelectModalGrp,
} from '../../../core/components/form-controls';
import { HRSource } from '../../../core/models/HREmployee.model';
import DropdownOptionForm from '../../../core/components/form-controls/select-modal/DropdownOptionForm';
import { ArrayField } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getSkillSourceCodes } from '../../../core/store/selectors';
import { CURRENCY_VALIDATION } from '../../../core/constants';

import Icon from 'core/components/shared/Icon';

type PropTypes = {
  item: Partial<ArrayField<HRSource>>;
  index: number;
  control: any;
  formMethods: any;
  onDelete: (item: HRSource) => void;
};

const SourcesItem: React.FC<PropTypes> = ({
  item,
  index,
  control,
  formMethods: { setValue, register },
  onDelete,
}) => {

  const skillSourceCodes = useSelector(getSkillSourceCodes);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-wrap">
        <input
          type="hidden"
          name={`sourceItems[${index}].empSourceFeeId`}
          defaultValue={item.empSourceFeeId}
          ref={register({ valueAsNumber: true })}
        />
        <SelectModalGrp
          noOption={true}
          modalTitle="SOURCE"
          label="SOURCE"
          groupClass="gc12 mw125"
          formComponent={DropdownOptionForm}
          addOptionText="Source"
          name={`sourceItems[${index}].sourceId`}
          dropdownName="EmpSkillSourceCodes"
          options={skillSourceCodes}
          control={control}
          value={item.sourceId}
          setValue={setValue} />

        <InputGrpCurrency
          name={`sourceItems[${index}].fee`}
          defaultValue={item.fee}
          label="FEES"
          groupClass="gc12 mw125"
          setValue={setValue}
          ref={register({
            CURRENCY_VALIDATION,
          })}
        />

        <InputGrp
          name={`sourceItems[${index}].comment`}
          defaultValue={item.comment}
          label="COMMENT"
          groupClass="gc12"
          ref={register()} />

        <div className="text-right">
          <button
            type="button"
            className="btn btn-link dm-grid-action-title mt-2"
            onClick={() => { return onDelete(item as HRSource); }}
          >
            Delete Source
            <Icon name="minus-circle"
              className="fa-minus-circle" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SourcesItem;
