import { GridOptions } from '@ag-grid-community/all-modules';
import Icon from 'core/components/shared/Icon';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SelectGrp } from 'core/components/form-controls';
import { NestedAccordionItem } from 'core/components/form-controls/nested-accordion/NestedAccordionItem';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { CommonService } from 'core/services';
import { loadSalesTaxTransmittalByYear } from 'core/store/actions';
import { getSalesTaxTransmittal } from 'core/store/selectors';
import { agCurrencyRenderer } from 'utilities/ag-grid-renderers';
import SalesTaxAddModal from './SalesTaxAdd.modal';
import { SalesTaxState, SalesTaxTransmittal } from 'core/models/SalesTaxTransmittal.model';
import TaxSection from './TaxSection';

const gridOptions: GridOptions = {
  columnDefs: [
    {
      headerName: 'State',
      field: 'state',
      width: 105,
      cellStyle: { 'text-align': 'left' },
    },
    {
      headerName: 'Sales Totals',
      field: 'salestotals',
      cellRenderer: 'currencyRenderer',
    },
    {
      headerName: 'Sales Tax Totals',
      field: 'salestaxtotals',
      cellRenderer: 'currencyRenderer',
    },
    {
      headerName: 'Use Totals',
      field: 'usetotals',
      cellRenderer: 'currencyRenderer',
    },
    {
      headerName: 'Use Tax Totals',
      field: 'usetaxtotals',
      cellRenderer: 'currencyRenderer',
    },
    {
      headerName: 'Total Tax',
      field: 'totaltax',
      cellRenderer: 'currencyRenderer',
    },
    {
      headerName: '',
      cellRenderer: 'linkCellRenderer',
    },
  ],
  defaultColDef: {
    resizable: true,
    editable: false,
    headerClass: 'grid-header',
    suppressMenu: true,
    cellStyle: { 'text-align': 'right' },
  },
  stopEditingWhenCellsLoseFocus: true,
  components: {
    currencyRenderer: agCurrencyRenderer,
  },
};

const mapModel = (i: SalesTaxTransmittal) => {
  return {
    id: String(i.salesTaxHistoryId),
    title: `${CommonService.getMonthName(i.month)} ${i.year}`,
    children: [
      {
        id: String(i.salesTaxHistoryId),
        title: `${CommonService.getMonthName(i.month)} ${i.year}`,
        month: i.month,
        year: i.year,
        gridOptions: cloneDeep(gridOptions),
        masterDetail: i.monthClosed,
        data: i.states.map((j: SalesTaxState) => {
          return {
            salesTaxMonthId: j.salesTaxMonthId,
            entityId: j.entityId,
            state: j.name,
            salestaxtotals: j.salesTaxTotals,
            salestotals: j.salesTotals,
            usetotals: j.useTotals,
            usetaxtotals: j.useTaxTotals,
            totaltax: j.totalTax,
            closedBy: i.closedBy,
            closedByDate: i.closedByDate,
          };
        }),
      },
    ],
  };
};

const currentYear = new Date().getFullYear();

const fs: FieldInputSettings = {
  years: {
    name: 'year',
    label: 'Select the tax year to view :',
    options: [
      {
        id: currentYear,
        description: currentYear,
      },
      {
        id: currentYear - 1,
        description: currentYear - 1,
      },
    ],
  },
};

const SalesTaxTransmittalPage: React.FC = () => {
  const dispatch = useDispatch();
  
  const salesTax = useSelector(getSalesTaxTransmittal);
  
  const { register, setValue, watch } = useForm<any>();
  const { year } = watch(['year']);
  
  const onYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(fs.years.name, e.target.value);
    dispatch(loadSalesTaxTransmittalByYear(year));
  };

  const [salesTaxOpen, setSalesTaxOpen] = useState<NestedAccordionItem[]>(
    salesTax
      .filter((i) => !i.monthClosed)
      .map((i) => mapModel(i)) ?? [],
  );
  const [salesTaxClosed, setSalesTaxClosed] = useState<NestedAccordionItem[]>(
    salesTax
      .filter((i) => i.monthClosed)
      .map((i) => mapModel(i)) ?? [],
  );
  const [showSalesTaxAddModal, setShowSalesTaxAddModal] = useState(false);

  useEffect(() => {
    setValue(fs.years.name, currentYear);
    dispatch(loadSalesTaxTransmittalByYear(currentYear));
  }, []);

  useEffect(() => {
    if (!salesTax) return;
    
    const openData = salesTax
      .filter((i) => !i.monthClosed)
      .map((i) => mapModel(i));
    const closedData = salesTax
      .filter((i) => i.monthClosed)
      .map((i) => mapModel(i));

    setSalesTaxOpen(openData);
    setSalesTaxClosed(closedData);
  }, [salesTax]);

  return (
    <div className="col">
      <div
        className="row"
        style={{ width: '100%' }}
      >
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-end">
            <div className="dm-page-title">Sales Tax Transmittal</div>
            <div className="dm-grid-title">
              <SelectGrp
                {...fs.years}
                ref={register}
                onChange={onYearChange}
              />
            </div>
            <div className="dm-grid-title">
              <button
                type="button"
                className="btn btn-link dm-grid-action-title"
                onClick={() => {
                  setShowSalesTaxAddModal(true);
                }}
              >
                Add Month to Sales Tax Transmittal{' '}
                <Icon
                  name="plus-circle"
                  className="fa-plus-circle"
                />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr className="dm-page-hr"></hr>
            </div>
          </div>
          <div
            className="row"
            style={{ minHeight: '600px' }}
          >
            <div className="col-12">
              <TaxSection
                title={'Open Months'}
                data={salesTaxOpen}
              />
              <TaxSection
                title={'Closed Months'}
                data={salesTaxClosed}
              />
            </div>
          </div>
        </div>
        {showSalesTaxAddModal && (
          <SalesTaxAddModal
            show={showSalesTaxAddModal}
            year={year}
            onHide={() => { return setShowSalesTaxAddModal(false); }}
          />
        )}
      </div>
    </div>
  );
};

export default SalesTaxTransmittalPage;
