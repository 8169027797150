import React, { useEffect, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { InputGrp } from 'core/components/form-controls';
import Icon from 'core/components/shared/Icon';
import { Col, Modal, Row } from 'react-bootstrap';
import { ColDef, GridApi, GridOptions } from '@ag-grid-community/core';
import { Client, LookupEEOCertifyOfficial } from 'core/models';
import { deleteEEOCertifyOfficial, updateEEOCertifyOfficial } from 'core/store/actions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { useForm } from 'react-hook-form';
import { formatFromPhone, formatPhone } from 'utilities/utilities';
import { PHONE_VALIDATION } from 'core/constants';

type PropTypes = {
  show: boolean;
  onHide: () => void;
  data: any[];
  selectUnit: (unit: LookupEEOCertifyOfficial) => void;
};

const CertifyingOfficerModal: React.FC<PropTypes> = ({ show, onHide, data, selectUnit }) => {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state: any) => {
    return state.client.client;
  }) as Client;
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [selected, setSelected] = useState<LookupEEOCertifyOfficial | null>(null);

  const { register, getValues, reset, errors } = useForm<LookupEEOCertifyOfficial>({
    defaultValues: {
      certifyOfficalID:0,
      name:'',
      title: '',
      telphone:'',
      emailAddress:'',
    },
  });

  useEffect(() => {
    selected && reset({
      ...selected,
      telphone: formatPhone(selected.telphone),
    });
  }, [selected]);

  const onDelete = async () => {
    if (!selected) {
      return;
    }

    dispatch(deleteEEOCertifyOfficial(selected));
  };

  const onSelect = async () => {
    selected && selectUnit(selected);
    onHide();
  };


  const onAdd = async () => {
    reset({
      certifyOfficalID:0,
      name:'',
      title: '',
      telphone:'',
      emailAddress:'',
    });
  };

  const onUpdate = async () => {
    const values = getValues();
    const parseValues = {
      ...values,
      telphone: formatFromPhone(values.telphone),
      certifyOfficalID: values.certifyOfficalID,
    };

    dispatch(updateEEOCertifyOfficial(parseValues));
  };

  const cerfifyingOfficerColumns: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 1,
    },
    {
      field: 'telphone',
      headerName: 'Phone',
      editable: false,
      flex: 1,
    },
    {
      field: 'emailAddress',
      headerName: 'Email',
      editable: false,
      flex: 1,
    },
    {
      field: 'title',
      headerName: 'Title',
      editable: false,
      flex: 1,
    },
    {
      field: 'select',
      headerName: '',
      editable: false,
      checkboxSelection: true,
      width: 60,
    },
  ];

  const certifyingGridOptions: GridOptions = {
    rowSelection: 'single',
    columnDefs: cerfifyingOfficerColumns,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      if (selectedRows) {
        setSelected(selectedRows[0]);
      }
    }
  };

  const onCancel = () => {
    onHide();
  };

  useEffect(() => {
    reset();
    setSelected(null);
  }, [data]);

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="modal-sm"
        animation={false}
      >
        <div className="modal-header">
          <div className="d-flex flex-row w-100 justify-content-between">
            <div className="dm-grid-title flex-grow-1">Certifying Official</div>
            <button
              onClick={onAdd}
              className="btn btn-link dm-grid-action-title"
              style={{ columnGap: '0.3rem' }}
            >
              Add Official {' '}
              <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          </div>
          <button
            type="button"
            onClick={onHide}
            className="modal-close-btn ml-0"
          >
            <Icon name="times" />
          </button>
        </div>
        <Modal.Body>
          <form className="mb-4">
            <Row
              className="mt-2"
            >
              {[
                {
                  key: 'name',
                  label: 'Name',
                },
                {
                  key: 'telphone',
                  label: 'Phone',
                  type: 'number',
                },
                {
                  key: 'emailAddress',
                  label: 'Email',
                  type: 'email',
                },
                {
                  key: 'title',
                  label: 'Title',
                },
              ].map((item) => {
                return (
                  <Col
                    xs={6}
                    key={item.key}
                  >
                    <input
                      type="hidden"
                      name={'certifyOfficalID'}
                      ref={register({ valueAsNumber: true })}
                    />
                    {item.key === 'telphone' ?
                      <InputGrp
                        label={item.label}
                        name={'telphone'}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          const { value } = e.target;
                          e.target.value = formatPhone(value);
                        }}
                        errors={errors?.telphone}
                        ref={register({
                          pattern: {
                            value: PHONE_VALIDATION,
                            message: 'Invalid phone number',
                          },
                        })}
                      /> :
                      <InputGrp
                        type={item.type || 'text'}
                        name={item.key}
                        label={item.label}
                        ref={register}
                      />}
                  </Col>
                );
              })}
            </Row>

          </form>
          <div
            className="ag-theme-balham mt-2"
            style={{ height: '150px' }}
          >
            <AgGridReact
              gridOptions={certifyingGridOptions}
              rowData={data}
              onSelectionChanged={onSelectionChanged}
              onGridReady={onGridReady}
            />
          </div>
          <div
            className="d-flex flex-grow-1 justify-content-end mt-3"
          >
            {selected && (
              <button
                type="button"
                className="btn  orange-outline-button mr-2"
                onClick={onSelect}
              >
                Select Unit
              </button>
            )}

            {selected && (
              <button
                type="button"
                className="btn  orange-outline-button mr-2"
                onClick={onDelete}
              >
                Delete Selected
              </button>
            )}
            <button
              type="button"
              className="btn orange-outline-button mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                onUpdate();
              }}
              className="btn orange-button"
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>

  );

};

export default CertifyingOfficerModal;