import React, { } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationDepartments } from 'core/store/selectors';
import { postEEONoSettings, putEEONoSettings } from 'core/store/actions';
import { useAppSelector } from 'utilities/hooks';
import { IEEONoSetting } from 'core/models/HumanCapitalReports.model';
import { SelectGrp } from 'core/components/form-controls';
import Icon from 'core/components/shared/Icon';
import { Client } from 'core/models';
import { useForm } from 'react-hook-form';

type PropTypes = {
  onDelete(param: IEEONoSetting): void;
  setting: IEEONoSetting;
};
const AssignEEOOccupationalGroupItem: React.FC<PropTypes> = ({ onDelete, setting }) => {
  const dispatch = useDispatch();

  const { locationOpts, deptOpts, subdeptOpts, subdept2Opts } = useSelector(getLocationDepartments);
  const eeoOccupGroupDropDown = useAppSelector(
    (state) => { return state.dropdown.eeoOccupationalGroup; },
  );
  const client = useAppSelector((state) => { return state.client.client; }) as Client;

  const { handleSubmit, register } = useForm<IEEONoSetting>({
    defaultValues: setting,
  });

  const downloadReport = (data: IEEONoSetting) => {
    const getValue = (item: any) => {
      if (item) {
        return +item;
      }

      return 0;
    };

    const submitData: IEEONoSetting = {
      ruleID: setting.ruleID ?? 0,
      entityID: client.clientEntityID,
      clientID: client.clientID,
      clientNo: +client.clientNo,
      loc: getValue(data?.loc),
      dept: getValue(data?.dept),
      sub: getValue(data?.sub),
      sub2: getValue(data?.sub2),
      eeoid: getValue(data.eeoid),
    };

    if (setting.ruleID) {
      dispatch(putEEONoSettings(submitData));
      return;
    }

    dispatch(postEEONoSettings(submitData));
  };

  return (
    <form onSubmit={handleSubmit(downloadReport)}>
      <div className="d-flex flex-wrap flex-grow-1">
        <SelectGrp
          name="loc"
          label="LOCATION"
          options={locationOpts}
          labelField="locationDesc"
          valueField="locationID"
          ref={register({ required: 'Location is required' })}
          placeholder="-"
        />
        <SelectGrp
          name="dept"
          label="DEPARTMENT"
          labelField="deptDesc"
          valueField="deptId"
          options={deptOpts}
          ref={register({ required: 'Department is required' })}
          placeholder="-"
        />
        <SelectGrp
          name="sub"
          label="SUB DEPARTMENT"
          labelField="subDeptDesc"
          valueField="subDeptCode"
          options={subdeptOpts}
          ref={register({
            required: 'Sub Department is required',
          })}
          placeholder="-"
        />
        <SelectGrp
          name="sub2"
          label="SUB DEPARTMENT 2"
          labelField="sub2Desc"
          valueField="sub2Code"
          options={subdept2Opts}
          ref={register({
            required: 'Sub Department 2 is required',
          })}
          placeholder="-"
        />
        <SelectGrp
          name="eeoid"
          ref={register}
          options={eeoOccupGroupDropDown.map((item: any) => {
            return {
              ...item,
              value: +item?.id,
            };
          })}
          label="EEO Occupational Group"
          valueField="value"
          showId={true}
          placeholder="-"
        />
      </div>
      <div className="row">
        <div className="col-12 text-right">
          <button
            type="button"
            className="btn btn-link dm-grid-action-title mr-4"
            onClick={() => {
              onDelete(setting);
            }}
          >
            Delete this Rule
            <Icon
              name="minus-circle"
              className="fa-minus-circle"
            />
          </button>
          <button
            type="submit"
            className="btn orange-button-sm mr-3"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default AssignEEOOccupationalGroupItem;
