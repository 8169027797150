import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  RadioGrp,
} from '../../core/components/form-controls';
import InputReportDateSelector from '../../core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import { Client, DtoOptions } from '../../core/models';
import { useAppDispatch, useAppSelector } from '../../utilities/hooks';
import { RadioOptions } from '../../core/components/form-controls/RadioGrp';
import { CommonService } from '../../core/services';
import { Week_Month_JTD_Job_Cost_Report_Request } from 'core/models/ContractorReports.model';
import { clearWeekMonthJTDJobCostReport, downloadWeekMonthJTDJobCostReport } from 'core/store/slices/contractorReports.slice';
import Icon from 'core/components/shared/Icon';


const payrollOptions: RadioOptions[] = [
  {
    value: 'ByWeekEnding',
    label: 'By Week Ending',
  },
  {
    value: 'ByCheckDate',
    label: 'By Check Date',
  },
  {
    value: 'ByIndividualPayroll',
    label: 'By Individual Payroll',
  },
];

const WeekMonthJTDjobCostReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const childRef: React.RefObject<any> = useRef();

  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [fileType, setFileType] = useState<string>('pdf');

  const client = useAppSelector((state) => { return state?.client?.client; }) as Client;

  const report = useAppSelector((state) => { return state?.contractorReports.weekMonthJTDJobCostReport; });

  const hidePdfViewer = () => {
    dispatch(clearWeekMonthJTDJobCostReport());
  };

  const { register, handleSubmit } =
    useForm<Week_Month_JTD_Job_Cost_Report_Request>({
      defaultValues: {
        reportByOption: 'ByWeekEnding',
      },
    });

  useEffect(() => {
    if (report && fileType === 'excel') {
      report &&
        CommonService.downloadBase64File(
          'application/excel',
          report,
          'WeekMonthJTDJobCostReport.xlsx',
        );
      dispatch(clearWeekMonthJTDJobCostReport());
    }
  }, [report]);

  const downloadReport = (data: Week_Month_JTD_Job_Cost_Report_Request) => {
    const submitData: Week_Month_JTD_Job_Cost_Report_Request = {
      clientNo: client.clientNo,
      reportByOption: data.reportByOption,
      dtoOptions: reportDates,
      reportType: fileType,
    };
    dispatch(downloadWeekMonthJTDJobCostReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">
            Week Month JTD Job Cost Report
          </div>
          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title "
              onClick={() => { return setFileType('excel'); }}
              disabled={!reportDates.length}
            >
              Download Report{' '}
              <Icon name="download"
                className="fa-download" />
            </button>
          </div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div>
              <InputReportDateSelector
                ref={childRef}
                returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }
                }
              />
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <div className=" text-right">
                <button
                  type="button"
                  className="orange-outline-button mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="orange-button mr-2"
                  onClick={() => { return setFileType('pdf'); }}
                  disabled={!reportDates.length}
                >
                  Run Report
                </button>
              </div>

              <div
                className="dm-panel dm-panel-border"
                style={{
                  marginTop: '66px',
                  width: '20%',
                }}
              >
                <div className="dm-grid-action-title mb-2">
                  Report Options
                </div>
                <div>
                  <div className="d-flex">
                    <div className="d-flex flex-row flex-grow-1">
                      <div className="d-flex flex-column flex-grow-1">
                        <RadioGrp
                          isVertical={true}
                          radioOptions={payrollOptions}
                          name="reportByOption"
                          ref={register}
                        />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {report ? (
          <PDFViewerModal
            show={report.length > 0}
            pdfData={report}
            onHide={hidePdfViewer}
            reportName="WeekMonthJTDjobCostReport"
          />
        ) : null}
      </div>
    </div>
  );
};
export default WeekMonthJTDjobCostReport;

