import React from 'react';
import Modal from 'core/components/modals/Modal';
import { ColumnHeaderData, Row } from 'core/components/shared/dm-table/types';
import { JobUploadError } from 'core/models/Contractor.model';
import Table from 'core/components/shared/dm-table/Table';
import { useAppSelector } from 'utilities/hooks';

const jobUploadErrorColumns: ColumnHeaderData[] = [
  { title: 'Company Id', styleClass: 'th' },
  { title: 'Job No.', styleClass: 'th' },
  { title: 'Job Description', styleClass: 'th-lg' },
  { title: 'City', styleClass: 'th' },
  { title: 'State', styleClass: 'th' },
  { title: 'Error Message', styleClass: 'th-lg' },
];

const cityErrorMessage = 'No DMPS matching Tax City Code is found.';

const buildErrorRow = (error: JobUploadError, id: number): Row => {
  return {
    id: id,
    cells: [
      { children: error.companyId, styleClass: 'td' },
      { children: error.jobNo, styleClass: 'td' },
      { children: error.jobDescription, styleClass: 'td-lg' },
      { children: error.city, styleClass: 'td' },
      { children: error.state, styleClass: 'td' },
      { children: error.errorMessage, styleClass: 'td-lg' },
    ],
  };
};

type Props = {
  show: boolean;
  onHide: () => void;
};

const JobUploadErrorModal = ({ show, onHide }: Props) => {
  const { jobUploadErrors } = useAppSelector(({ contractor }) => contractor);
  const errorRows = jobUploadErrors?.map((error, index) => buildErrorRow(error, index));
  const hasCityError = jobUploadErrors?.map(({ errorMessage }) => errorMessage.trim())?.includes(cityErrorMessage);
  
  return (
    <Modal
      title="Upload Errors"
      size="lg"
      show={show}
      onHide={onHide}
    >
      <div className="d-flex flex-column">
        <strong className="mb-3">The following errors occurred while uploading your job info records.</strong>
        {hasCityError && (
          <strong
            className="mb-3"
            style={{ color: 'red' }}
          >
            Note: Job(s) being uploaded are in cities that do not have conversions set up. An email has been sent to Customer
            Service to do so. Until they are set up, you will not be able to allocate hours to these jobs and preview/
            submit payroll.
          </strong>
        )}
        <Table
          rows={errorRows}
          columns={jobUploadErrorColumns}
        />
        <div className="d-flex justify-content-end mt-3">
          <button
            className="orange-button"
            onClick={onHide}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default JobUploadErrorModal;