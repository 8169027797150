import Modal from 'core/components/modals/Modal';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { SelectGrp } from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { addSalesTaxTransmittal } from 'core/store/actions';
import { SalesTaxPayload } from 'core/models/SalesTaxTransmittal.model';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { getYesNoCMOption } from 'core/store/selectors';
import { ACTIVATION_NOTICE_MESSAGE } from 'core/constants';
import SaveButton from 'core/components/shared/SaveButton';

type PropTypes = {
  show: boolean;
  year: number;
  onHide: () => void;
};

const fs: FieldInputSettings = {
  monthNames: {
    name: 'monthName',
    label: 'Select the month you want to insert',
    options: [
      { id: 1, description: 'January' },
      { id: 2, description: 'February' },
      { id: 3, description: 'March' },
      { id: 4, description: 'April' },
      { id: 5, description: 'May' },
      { id: 6, description: 'June' },
      { id: 7, description: 'July' },
      { id: 8, description: 'August' },
      { id: 9, description: 'September' },
      { id: 10, description: 'October' },
      { id: 11, description: 'November' },
      { id: 12, description: 'December' },
    ],
  },
};

// why is this using form methods? it's not even a form 
const SalesTaxAddModal: React.FC<PropTypes> = ({ show, year, onHide }) => {
  const { errors, register, setValue, watch } = useForm<any>();
  const month = watch('monthName');
  
  const clientUsesSalesTaxTransmittal = useSelector(getYesNoCMOption(270));
  const { showActivationMessage: noEntitiesWithSalesTax, inserting } = useAppSelector(({ salesTaxTransmittal }) => salesTaxTransmittal);
  
  const dispatch = useAppDispatch();

  const onMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue('monthName', e.target.value);
  };

  const onInsert = () => {
    const submitData: SalesTaxPayload = {
      year: year,
      month: month ?? '1',
    };

    dispatch(addSalesTaxTransmittal(submitData));
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title={!clientUsesSalesTaxTransmittal || noEntitiesWithSalesTax ? 'Notice' : 'Insert Month Selection'}
    >
      {(closeModal) => {
        return !clientUsesSalesTaxTransmittal || noEntitiesWithSalesTax ? (
          <div className="d-flex flex-column">
            <p>
              {!clientUsesSalesTaxTransmittal
                ? 'This client is not set up to use sales tax.'
                : 'No entities on this client are currently set up with sales tax.'}
            </p>
            <p>{ACTIVATION_NOTICE_MESSAGE}</p>
            <button
              className="btn orange-button-sm align-self-end"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        ) : (
          <>
            <div className="row">
              <div
                className="col-8"
                style={{ height: '100px' }}
              >
                <div>
                  <SelectGrp
                    {...fs.monthNames}
                    ref={register}
                    errors={errors.monthName}
                    onChange={onMonthChange}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div className="mr-2">
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button-sm"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
              <SaveButton
                type="button"
                saving={inserting}
                labels={{ default: 'Insert', saving: 'Inserting' }}
                classes="btn-primary orange-button-sm"
                onClick={onInsert}
              />
            </div>
          </>
        );
      }}
    </Modal>
  );
};

export default SalesTaxAddModal;
