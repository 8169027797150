import React, { useState } from 'react';
import { DirectDepositChange } from 'core/models/MyInfoChangeLog';
import { convToDateString } from 'utilities/utilities';
import styles from './styles.module.scss';

type Props = {
  change: DirectDepositChange;
  index: number;
};

const DirectDepositChangeTable = ({ change, index }: Props) => {
  return (
    <div className="d-flex flex-column mb-4">
      <h4>Direct Deposit {index + 1}</h4>
      <table className={styles['dd-change-table']}>
        <thead>
          <tr className={styles['header-row']}>
            <th className={styles['header-cell']}>Field Name</th>
            <th className={styles['header-cell']}>Old Value</th>
            <th className={styles['header-cell']}>New Value</th>
            <th className={styles['header-cell']}>Date Made</th>
          </tr>
        </thead>
        <tbody>
          <tr className={styles['dd-row']}>
            <td className={styles['data-cell']}>Bank Account No.</td>
            <td className={styles['data-cell']}>{change.bankAccountNoOldValue}</td>
            <td className={styles['data-cell']}>{change.bankAccountNoNewValue}</td>
            <td className={styles['data-cell']}>{convToDateString(change.dateMade)}, {new Date(change.dateMade).toLocaleTimeString()}</td>
          </tr>
          <tr className={styles['dd-row']}>
            <td className={styles['data-cell']}>Routing No.</td>
            <td className={styles['data-cell']}>{change.routingNoOldValue}</td>
            <td className={styles['data-cell']}>{change.routingNoNewValue}</td>
            <td className={styles['data-cell']}>{convToDateString(change.dateMade)}, {new Date(change.dateMade).toLocaleTimeString()}</td>
          </tr>
          <tr className={styles['dd-row']}>
            <td className={styles['data-cell']}>Deduction Amount</td>
            <td className={styles['data-cell']}>{change.deductionAmountOldValue}</td>
            <td className={styles['data-cell']}>{change.deductionAmountNewValue}</td>
            <td className={styles['data-cell']}>{convToDateString(change.dateMade)}, {new Date(change.dateMade).toLocaleTimeString()}</td>
          </tr>
          <tr className={styles['dd-row']}>
            <td className={styles['data-cell']}>Active</td>
            <td className={styles['data-cell']}>{change.activeOldValue}</td>
            <td className={styles['data-cell']}>{change.activeNewValue}</td>
            <td className={styles['data-cell']}>{convToDateString(change.dateMade)}, {new Date(change.dateMade).toLocaleTimeString()}</td>
          </tr>
          <tr className={styles['dd-row']}>
            <td className={styles['data-cell']}>Nickname</td>
            <td className={styles['data-cell']}>{change.nicknameOldValue}</td>
            <td className={styles['data-cell']}>{change.nicknameNewValue}</td>
            <td className={styles['data-cell']}>{convToDateString(change.dateMade)}, {new Date(change.dateMade).toLocaleTimeString()}</td>
          </tr>
          <tr className={styles['dd-row']}>
            <td className={styles['data-cell']}>Unit</td>
            <td className={styles['data-cell']}>{change.unitOldValue}</td>
            <td className={styles['data-cell']}>{change.unitNewValue}</td>
            <td className={styles['data-cell']}>{convToDateString(change.dateMade)}, {new Date(change.dateMade).toLocaleTimeString()}</td>
          </tr>
          <tr className={styles['dd-row']}>
            <td className={styles['data-cell']}>Financial Institution</td>
            <td className={styles['data-cell']}>{change.financialInstitutionOldValue}</td>
            <td className={styles['data-cell']}>{change.financialInstitutionNewValue}</td>
            <td className={styles['data-cell']}>{convToDateString(change.dateMade)}, {new Date(change.dateMade).toLocaleTimeString()}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DirectDepositChangeTable;