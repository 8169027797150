import React, { useRef, useState, useEffect } from 'react';
import Icon from 'core/components/shared/Icon';
import { useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CheckboxGrpInLine, RadioGrp, SelectGrp } from 'core/components/form-controls';
import InputReportDateSelector from 'core/components/form-controls/InputReportDateSelector';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { DtoOptions, Client } from 'core/models';
import { WorkersCompensationReportRequest } from 'core/models/PayrollReports.model';
import { CommonService } from 'core/services';
import { clearWorkersCompensationReport, downloadWorkersCompensationReport } from 'core/store/actions/payoll-reports.action';
import { getWorkersCompnesationReport } from 'core/store/selectors/payroll-reports.selector';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { getClientOptions } from 'core/store/selectors';
import { convToDateString, getLastDayOfMonth } from 'utilities/utilities';
import { Spinner } from 'react-bootstrap';

const workerCompensationReportOption: RadioOptions[] = [
  {
    value: '0',
    label: 'By WC Code',
  },
  {
    value: '1',
    label: 'By Location Code',
  },
  {
    value: '2',
    label: 'By Department Code',
  },
  {
    value: '3',
    label: 'By Sub-Department Code',
  },
];

const currentYear = new Date().getFullYear();
const selectOptions = [0, 0, 0, 0, 0, 0].map((_, index) => {
  return { label: (currentYear - index).toString(), description: (currentYear - index).toString() };
},
);

const WorkersCompensationReport = () => {
  const dispatch = useAppDispatch();

  const childRef: React.RefObject<any> = useRef();

  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [fileType, setFileType] = useState<string>('pdf');
  const [isRunAnnual, setIsRunAnnual] = useState<boolean>(false);
  const [option, setOtpion] = useState<boolean>(true);
  const [beginDate, setBeginDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [reportIsRunning, setReportIsRunning] = useState<{pdfReport: boolean, excelReport: boolean}>({pdfReport: false, excelReport: false});

  const clientOptions = useSelector(getClientOptions);
  const wcAnnualMonth = +clientOptions[201]?.optionValue;

  const client = useAppSelector((state) => { return state?.client?.client; }) as Client;
  const report = useSelector(getWorkersCompnesationReport);

  const hidePdfViewer = () => {
    setReportIsRunning({pdfReport: false, excelReport: false});
    dispatch(clearWorkersCompensationReport());
  };

  useEffect(() => {
    setReportIsRunning({pdfReport: false, excelReport: reportIsRunning.excelReport});
    if (!(report && fileType === 'excel')) return;
    
    CommonService.downloadBase64File(
      'application/excel',
      report,
      'WorkersCompensationReport.xlsx',
    );
    dispatch(clearWorkersCompensationReport());
    setReportIsRunning({pdfReport: false, excelReport: false});
  }, [report]);

  const {
    getValues,
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
  } = useForm<WorkersCompensationReportRequest>({
    defaultValues: {
      runAnnualReport: false,
      annualYear: undefined,
      workersCompensationOption: '0',
      reportType: 'pdf',
    },
  });

  const downloadReport = (data: WorkersCompensationReportRequest) => {
    setReportIsRunning({pdfReport: fileType === 'pdf', excelReport: fileType === 'excel'});

    clearErrors('annualYear');
    if (data.runAnnualReport === 'true' && data.annualYear === undefined) {
      setError('annualYear', {
        type: 'invalidAnnualYear',
        message: 'Must enter in a date when checkbox is selected',
      });
      return;
    }
    
    const submitData: WorkersCompensationReportRequest = {
      clientNo: client.clientNo,
      dtoOptions: reportDates || getValues('annualYear'),
      runAnnualReport: getValues('runAnnualReport'),
      annualYear: getValues('annualYear'),
      workersCompensationOption: data.workersCompensationOption,
      reportType: fileType,
    };
    dispatch(downloadWorkersCompensationReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const handleChangeValue = (e: any) => {
    setIsRunAnnual(e.target.checked);
  };

  const setSelection = () => {
    return setOtpion(!option);
  };
  
  const watch = useWatch<{ runAnnualReport: boolean, annualYear: string }>({
    control,
  }) as { runAnnualReport: boolean, annualYear: string };

  const buttonDisabled = () => {
    let disabled = true;
    const annualEnabled = (watch.runAnnualReport && watch.annualYear !== '') ? true : false;
    disabled =
      (!reportDates?.length && !annualEnabled);

    return disabled;
  };

  useEffect(() => {
    if (watch.runAnnualReport && watch.annualYear) {
      const _beginDate = new Date(+watch.annualYear - 1, wcAnnualMonth, 1);
      const _endDate = new Date(+watch.annualYear, wcAnnualMonth - 1, getLastDayOfMonth(+watch.annualYear, wcAnnualMonth));
      setBeginDate(convToDateString(_beginDate));
      setEndDate(convToDateString(_endDate));
    } else {
      setBeginDate(null);
      setEndDate(null);
    }
  }, [watch.runAnnualReport, watch.annualYear]);
  
  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">
            Workers&apos; Compensation Report
          </div>
          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title "
              onClick={() => { return setFileType('excel'); }}
            >
              {!reportIsRunning.excelReport ? "Download Report" : "Downloading Report"}{' '}
              {!reportIsRunning.excelReport ? 
              <Icon
                name="download"
                className="fa-download"
              /> :
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="ml-1"
              />
              }
            </button>
          </div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div>
              <InputReportDateSelector
                ref={childRef}
                returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }
                }
                disableGrid={isRunAnnual}
              />
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <div className=" text-right">
                <button
                  type="button"
                  className="orange-outline-button mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="orange-button mr-2"
                  onClick={() => { return setFileType('pdf'); }}
                  disabled={buttonDisabled() || reportIsRunning.pdfReport}
                >
                  {!reportIsRunning.pdfReport ? "Run Report" : "Generating Report"}
                  {reportIsRunning.pdfReport && (
                    <Spinner
                      animation="border"
                      variant="light"
                      size="sm"
                      className="ml-1"
                    />
                  )}
                </button>
              </div>

              <div
                className="w-50 dm-panel dm-panel-border"
                style={{ marginTop: '66px' }}
              >
                <div className="dm-grid-action-title mb-2">
                  Report Type
                </div>
                <div>
                  <div className="d-flex">
                    <div className="d-flex flex-row flex-grow-1">
                      <div className="d-flex flex-column flex-grow-1">
                        <RadioGrp
                          isVertical={true}
                          radioOptions={workerCompensationReportOption}
                          name="workersCompensationOption"
                          ref={register}
                          required={true}
                        />

                      </div>
                      <div className="d-flex flex-column flex-grow-1 pt-1 pl-1">
                        <div
                          style={{ marginTop: '-36px' }}
                          className="dm-grid-action-title"
                        >
                          Annual Report Options
                        </div>
                        <CheckboxGrpInLine
                          name="runAnnualReport"
                          ref={register}
                          labelFirst={false}
                          label="Run Annual Report"
                          onChange={handleChangeValue}
                        />

                        <div>
                          <div className="d-flex flex-column">
                            <SelectGrp
                              ref={register}
                              name="annualYear"
                              label="Annual Year"
                              options={selectOptions}
                              labelFirst={true}
                              onChange={setSelection}
                            />
                          </div>
                          {(!!beginDate && !!endDate) ? <div>
                            From Date: {beginDate}
                          </div> : null }
                          {(!!beginDate && !!endDate) ? <div>
                            To Date: {endDate}
                          </div> : null }
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {report ? (
          <PDFViewerModal
            show={report.length > 0}
            pdfData={report}
            onHide={hidePdfViewer}
            reportName="WorkersCompensationReport"
          />
        ) : null}
      </div>
    </div>

  );
};


export default WorkersCompensationReport;

