import { createAction } from '@reduxjs/toolkit';
import { CalculateHourlySalaryAnnualPayRates, DeleteEmployeePayRateRequest, EmployeePayRateScreen, FuturePayRate, HourlySalaryAnnualPayRates, PayRate, PayRateHistory } from '../../models';

export const PAYRATE_EM_VALIDATION_ACTIONS = {
  CREATE_PAYRATE: '[employee] CREATE_PAYRATE',
  UPDATE_PAYRATE: '[employee] UPDATE_PAYRATE',
  ADD_UPDATE_EMPLOYEE_PAYRATES_SCREEN: '[employee] ADD_UPDATE_EMPLOYEE_PAYRATES_SCREEN',
  UPDATE_PAYRATES_HISTORY: '[employee] UPDATE_PAYRATES_HISTORY',
  CREATE_FUTURE_PAYRATE: '[employee] CREATE_FUTURE_PAYRATE',
  UPDATE_FUTURE_PAYRATE: '[employee] UPDATE_FUTURE_PAYRATE',
  DELETE_PAYRATE: '[employee] DELETE_PAYRATE',
  DELETE_FUTURE_PAYRATE: '[employee] DELETE_FUTURE_PAYRATE',
} as const;

export const loadPayRates = createAction<string>('[employee] LOAD_PAYRATES');
export const storePayRates = createAction<PayRate[]>('[employee] STORE_PAYRATES');
export const storePayRate = createAction<PayRate>('[employee] STORE_PAYRATE');
export const createPayRate = createAction<PayRate>(PAYRATE_EM_VALIDATION_ACTIONS.CREATE_PAYRATE);
export const updatePayRate = createAction<PayRate>(PAYRATE_EM_VALIDATION_ACTIONS.UPDATE_PAYRATE);

export const loadEmployeePayRatesScreen = createAction<string>('[employee] LOAD_EMPLOYEE_PAYRATES_SCREEN');
export const errorPayRateScreen = createAction('[employee] ERROR_EMPLOYEE_PAYRATES_SCREEN');
export const storeEmployeePayRatesScreen = createAction<EmployeePayRateScreen>('[employee] STORE_EMPLOYEE_PAYRATES_SCREEN');
export const addUpdateEmployeePayRatesScreen = createAction<EmployeePayRateScreen>(PAYRATE_EM_VALIDATION_ACTIONS.ADD_UPDATE_EMPLOYEE_PAYRATES_SCREEN);

export const deletePayRate = createAction<DeleteEmployeePayRateRequest>(PAYRATE_EM_VALIDATION_ACTIONS.DELETE_PAYRATE);

export const loadPayRatesHistory = createAction<string>(
  '[employee] LOAD_PAYRATES_HISTORY',
);
export const storePayRatesHistory = createAction<PayRateHistory[]>(
  '[employee] STORE_PAYRATES_HISTORY',
);

export type UpdatePayRateHistoryRequest = {
  empNo: string;
  payRateHistoryData: PayRateHistory[];
};

export const updatePayRatesHistory = createAction<UpdatePayRateHistoryRequest>(PAYRATE_EM_VALIDATION_ACTIONS.UPDATE_PAYRATES_HISTORY);

export const calculatePayRates = createAction<CalculateHourlySalaryAnnualPayRates>('[employee] CALCULATE_PAYRATES');
export const storeCalculatedPayRates = createAction<HourlySalaryAnnualPayRates>('[employee] STORE_CALCULATED_PAYRATES');

export const loadFuturePayRates = createAction<{
  empNo: string | number;
  rateId: number;
}>('[employee] LOAD_FUTURE_PAYRATES');
export const storeFuturePayRates = createAction<FuturePayRate[]>(
  '[employee] STORE_FUTURE_PAYRATES',
);
export const createFuturePayRate = createAction<FuturePayRate>(
  PAYRATE_EM_VALIDATION_ACTIONS.CREATE_FUTURE_PAYRATE,
);
export const updateFuturePayRate = createAction<FuturePayRate>(
  PAYRATE_EM_VALIDATION_ACTIONS.UPDATE_FUTURE_PAYRATE,
);
export const deleteFuturePayRate = createAction<FuturePayRate>(
  PAYRATE_EM_VALIDATION_ACTIONS.DELETE_FUTURE_PAYRATE,
);
export const storeFuturePayRate = createAction<FuturePayRate>(
  '[employee] STORE_FUTURE_PAYRATE',
);
