import {
  HttpResponse,
  Payroll,
  PayrollMessage,
  PayrollDateline,
  TransmittalAutoFill,
  TransmittalParams,
  UpdateAutoCalcsParams,
  TransmittalEmployee,
  TransmittalEmployeeParams,
  TransmittalCheck,
  TransmittalEmployeeCheckParams,
  PayrollControlTotal,
  PayrollOptions,
  PayrollAdjustmentTransaction,
  PayrollVoidSearchParams,
  PayrollAdjustmentVoidTransaction,
  PayrollPreview,
  PayrollTimeOffRequests,
  ApplyTimeOffRequest,
  PayrollValidateRequest,
  PayrollDatelineIndex,
  RecoverPayrollRequest,
  DeptSubSub2TransmittalUpdate,
  CreateControlTotalBookRequest,
  TransmittalFuturePayRates,
  MoveTimeOffRequest,
  WireOnlyAgreement,
  UpdateCheckNoRequest,
  WeekBeginDate,
  UpdateWeekBeginResult,
} from '../models';
import {
  PayrollControlTotalDetail,
  PayrollControlTotalRequest,
} from '../models/PayrollControlTotalDetail';
import { PayrollHistoryDeductionStatus } from '../models/PayrollHistoryDeductionStatus';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';
import store from '../../core/store/store';
import { AxiosResponse } from 'axios';

const getPayroll = (
  beginDate: string,
  endDate: string,
  byCheckDate?: boolean,
  protectedClientNo: string = CommonService.getProtectedClientNo(),
): Promise<AxiosResponse<Payroll[]>> => {
  return API_EMPLOYEE_MASTER.get<Payroll[]>(
    EmployeeEndPoints.PAYROLL(protectedClientNo, beginDate, endDate, byCheckDate),
  );
};

const postPayroll = (
  data: PayrollDateline,
): Promise<AxiosResponse<HttpResponse<Payroll>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<Payroll>>(
    EmployeeEndPoints.POST_PAYROLL(CommonService.getProtectedClientNo()),
    data,
  );
};

const postValidatePayroll = (
  data: PayrollDateline,
): Promise<AxiosResponse<HttpResponse<null>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<null>>(
    EmployeeEndPoints.POST_VALIDATE_PAYROLL_BEFORE_CREATE(CommonService.getProtectedClientNo()),
    data,
  );
};

const deletePayroll = (
  payrollHistoryId: number,
): Promise<AxiosResponse<HttpResponse<null>>> => {
  return API_EMPLOYEE_MASTER.delete<HttpResponse<null>>(
    EmployeeEndPoints.DELETE_PAYROLL(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
    ),
  );
};

const getClientRecurringEarnings = (): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.get<HttpResponse<any>>(
    EmployeeEndPoints.GET_CLIENT_RECURRING_EARNINGS(CommonService.getProtectedClientNo()),
  );
};

const putPayrollDateline = (
  payrollHistoryId: number,
  data: PayrollDatelineIndex,
  removeRecurringEarnings: boolean,
  addRecurringEarnings: boolean,
): Promise<AxiosResponse<HttpResponse<Payroll>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<Payroll>>(
    EmployeeEndPoints.UPDATE_PAYROLL_DATELINE(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
      removeRecurringEarnings,
      addRecurringEarnings,
    ),
    data,
  );
};

const getPayrollOptions = (
  payrollHistoryId: number,
): Promise<AxiosResponse<PayrollOptions>> => {
  return API_EMPLOYEE_MASTER.get<PayrollOptions>(
    EmployeeEndPoints.PAYROLL_OPTIONS(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
    ),
  );
};

const putPayrollOptions = (
  data: PayrollOptions,
  payrollHistoryId: number,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<string>>(
    EmployeeEndPoints.PAYROLL_OPTIONS(
      CommonService.getProtectedClientNo(),
      payrollHistoryId),
    data,
  );
};

const getWireOnlyAgreement = (
  payrollHistoryId: number,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.get<HttpResponse<string>>(
    EmployeeEndPoints.PAYROLL_WIRE_ONLY(
      CommonService.getProtectedClientNo(),
      payrollHistoryId),
  );
};

const putWireOnlyAgreement = (
  data: WireOnlyAgreement
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<string>>(
    EmployeeEndPoints.PAYROLL_WIRE_ONLY(
      CommonService.getProtectedClientNo(),
      data.payrollHistoryId),
    data,
  );
};

const deleteWireOnlyAgreement = (
  payrollHistoryId: number,
): Promise<AxiosResponse<HttpResponse<null>>> => {
  return API_EMPLOYEE_MASTER.delete<HttpResponse<null>>(
    EmployeeEndPoints.PAYROLL_WIRE_ONLY(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
    ),
  );
};

const postWireOnlyAgreementReport = (params: WireOnlyAgreement,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.PAYROLL_WIRE_ONLY_REPORT(
      CommonService.getProtectedClientNo(),
      params.payrollHistoryId),
    params,
  );
};

const putPayrollPreview = (
  data: PayrollPreview,
  payrollHistoryId: number,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<string>>(
    EmployeeEndPoints.PAYROLL_PREVIEW(
      CommonService.getProtectedClientNo(),
      payrollHistoryId),
    data,
  );
};

const putWeekBeginningDates = (data: WeekBeginDate[], payrollHistoryId: number): Promise<AxiosResponse<HttpResponse<UpdateWeekBeginResult>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<UpdateWeekBeginResult>>(
    EmployeeEndPoints.PUT_WEEK_BEGINNING_DATES(
      CommonService.getProtectedClientNo(),
      payrollHistoryId),
    data,
  );
};

const postPayrollCheckRegister = (payrollHistoryId: number, isPayrollSubmission: boolean): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.PAYROLL_CHECK_REGISTER(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
    ),
    { isPayrollSubmission },
  );
};

const getPayrollControlTotals = (
  payrollHistoryId: number,
): Promise<AxiosResponse<PayrollControlTotal[]>> => {
  return API_EMPLOYEE_MASTER.get<PayrollControlTotal[]>(
    EmployeeEndPoints.PAYROLL_CONTROLTOTALS(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
    ),
  );
};

const getPayrollControlTotal = (
  payrollHistoryId: number,
  controlTotalId: number,
): Promise<AxiosResponse<PayrollControlTotal[]>> => {
  return API_EMPLOYEE_MASTER.get<PayrollControlTotal[]>(
    EmployeeEndPoints.PAYROLL_CONTROLTOTALS_ID(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
      controlTotalId,
    ),
  );
};

const postPayrollControlTotal = (params: CreateControlTotalBookRequest) => {
  return API_EMPLOYEE_MASTER.post<any>(
    EmployeeEndPoints.ADD_CONTROL_TOTAL_BOOK(
      CommonService.getProtectedClientNo(),
      params.payrollHistoryId,
      params.controlTotalId,
    ),
    params,
  );
};

const putPayrollControlTotal = (
  payrollHistoryId: number,
  controlTotalId: number,
  data: PayrollControlTotal,
): Promise<AxiosResponse<PayrollControlTotal>> => {
  return API_EMPLOYEE_MASTER.put<PayrollControlTotal>(
    EmployeeEndPoints.PAYROLL_CONTROLTOTALS_ID(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
      controlTotalId,
    ),
    data,
  );
};

const postPayrollTransmittalAutofill = (
  params: TransmittalParams,
  data: TransmittalAutoFill,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.POST_PAYROLL_AUTOFILL(
      CommonService.getProtectedClientNo(),
      params.payrollHistoryId,
      params.controlTotalId,
    ),
    data,
  );
};

const getPayrollMessages = (): Promise<AxiosResponse<PayrollMessage[]>> => {
  return API_EMPLOYEE_MASTER.get<PayrollMessage[]>(
    EmployeeEndPoints.PAYROLL_MESSAGES(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const postPayrollMessage = (
  data: PayrollMessage,
): Promise<AxiosResponse<HttpResponse<PayrollMessage>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<PayrollMessage>>(
    EmployeeEndPoints.PAYROLL_MESSAGES(
      CommonService.getProtectedClientNo(),
    ),
    data,
  );
};

const deletePayrollMessage = (
  data: PayrollMessage,
): Promise<AxiosResponse<HttpResponse<null>>> => {
  return API_EMPLOYEE_MASTER.delete<HttpResponse<null>>(
    EmployeeEndPoints.DELETE_PAYROLL_MESSAGES(
      CommonService.getProtectedClientNo(),
      data.payrollMessageId || 0
    ),
  );
};

const putPayrollMessage = (
  data: PayrollMessage,
): Promise<AxiosResponse<HttpResponse<PayrollMessage>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<PayrollMessage>>(
    EmployeeEndPoints.PAYROLL_MESSAGES_ID(
      CommonService.getProtectedClientNo(),
      data.payrollMessageId,
    ),
    data,
  );
};

// ??? Placeholder until endpoint is done. ??? post or get
const getPrintPayrolls = (
  weekEnding: string,
  checkDate: string,
): Promise<AxiosResponse<any>> => {
  return API_EMPLOYEE_MASTER.get<any>(
    EmployeeEndPoints.PRINT_CHECK_PAYROLLS(
      CommonService.getProtectedClientNo(),
      weekEnding,
      checkDate,
    ),
  );
};

const putUpdateAutoCalcs = (
  params: UpdateAutoCalcsParams,
): Promise<AxiosResponse<HttpResponse<null>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<null>>(
    EmployeeEndPoints.PUT_UPDATE_AUTO_CALCS(
      CommonService.getProtectedClientNo(),
      params.payrollHistoryId,
      params.controlTotalId,
    ),
    params.earningsCodeAutoCalculations,
  );
};

const PutDeptSubSub2OnEmpChange = (protectedEmpNo: string, data: DeptSubSub2TransmittalUpdate): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<any>>(
    EmployeeEndPoints.PUT_DEPTSUBSUB2_ON_EMP_CHANGE(
      CommonService.getProtectedClientNo(),
      protectedEmpNo,
    ),
    data,
  );
};

const getTransmittalEmployees = (
  params: TransmittalParams,
): Promise<AxiosResponse<TransmittalEmployee[]>> => {
  return API_EMPLOYEE_MASTER.get<TransmittalEmployee[]>(
    EmployeeEndPoints.PAYROLL_TRANSMITTAL_EMPLOYEES(
      CommonService.getProtectedClientNo(),
      params.payrollHistoryId,
      params.controlTotalId,
    ),
  );
};

const getTransmittalEmployee = (
  params: TransmittalEmployeeParams,
): Promise<AxiosResponse<TransmittalEmployee>> => {
  return API_EMPLOYEE_MASTER.get<TransmittalEmployee>(
    EmployeeEndPoints.PAYROLL_TRANSMITTAL_EMPLOYEE(
      CommonService.getProtectedClientNo(),
      params.payrollHistoryId,
      params.controlTotalId,
      params.protectedEmpNo,
    ),
  );
};

const postTransmittalEmployee = (
  params: TransmittalEmployeeParams,
  data: TransmittalEmployee,
): Promise<AxiosResponse<HttpResponse<TransmittalEmployee>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<TransmittalEmployee>>(
    EmployeeEndPoints.PAYROLL_TRANSMITTAL_EMPLOYEE(
      CommonService.getProtectedClientNo(),
      params.payrollHistoryId,
      params.controlTotalId,
      params.protectedEmpNo,
    ),
    data,
  );
};

const postTransmittalEmployeeCheck = (
  params: TransmittalEmployeeParams,
  data: TransmittalCheck,
): Promise<AxiosResponse<HttpResponse<TransmittalCheck>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<TransmittalCheck>>(
    EmployeeEndPoints.PAYROLL_TRANSMITTAL_EMPLOYEE_CHECK(
      CommonService.getProtectedClientNo(),
      params.payrollHistoryId,
      params.controlTotalId,
      params.protectedEmpNo,
    ),
    data,
  );
};

const getBlankTransmittalEmployeeCheck = (
  params: TransmittalEmployeeParams,
): Promise<AxiosResponse<TransmittalCheck>> => {
  return API_EMPLOYEE_MASTER.get<TransmittalCheck>(
    EmployeeEndPoints.PAYROLL_TRANSMITTAL_EMPLOYEE_CHECK_BLANK(
      CommonService.getProtectedClientNo(),
      params.payrollHistoryId,
      params.controlTotalId,
      params.protectedEmpNo,
    ),
  );
};

const putTransmittalEmployeeCheck = (
  params: TransmittalEmployeeCheckParams,
  data: TransmittalCheck,
): Promise<AxiosResponse<HttpResponse<TransmittalCheck>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<TransmittalCheck>>(
    EmployeeEndPoints.PAYROLL_TRANSMITTAL_EMPLOYEE_CHECK_ID(
      CommonService.getProtectedClientNo(),
      params.payrollHistoryId,
      params.controlTotalId,
      params.protectedEmpNo,
      params.transmittalCheckId,
    ),
    data,
  );
};

const deleteTransmittalEmployeeCheck = (
  params: TransmittalEmployeeCheckParams,
): Promise<AxiosResponse<HttpResponse<TransmittalCheck>>> => {
  return API_EMPLOYEE_MASTER.delete<HttpResponse<TransmittalCheck>>(
    EmployeeEndPoints.PAYROLL_TRANSMITTAL_EMPLOYEE_CHECK_ID(
      CommonService.getProtectedClientNo(),
      params.payrollHistoryId,
      params.controlTotalId,
      params.protectedEmpNo,
      params.transmittalCheckId,
    ),
  );
};

const getPayrollHistoryDeductionStatuses = (
  payrollHistoryId: number,
): Promise<AxiosResponse<PayrollHistoryDeductionStatus[]>> => {
  return API_EMPLOYEE_MASTER.get<PayrollHistoryDeductionStatus[]>(
    EmployeeEndPoints.PAYROLL_DEDUCTION_STATUSES(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
    ),
  );
};

const putPayrollHistoryDeductionStatuses = (
  payrollHistoryId: number,
  deductionStatuses: PayrollHistoryDeductionStatus[],
): Promise<AxiosResponse<HttpResponse<PayrollHistoryDeductionStatus[]>>> => {
  return API_EMPLOYEE_MASTER.put<
  HttpResponse<PayrollHistoryDeductionStatus[]>
  >(
    EmployeeEndPoints.PAYROLL_DEDUCTION_STATUSES(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
    ),
    deductionStatuses,
  );
};

const getPayrollControlTotalDetail = (
  params: PayrollControlTotalRequest,
): Promise<AxiosResponse<PayrollControlTotalDetail>> => {
  return API_EMPLOYEE_MASTER.get<PayrollControlTotalDetail>(
    EmployeeEndPoints.PAYROLL_CONTROL_TOTAL_DETAIL(
      CommonService.getProtectedClientNo(),
      params.payrollHistoryId,
      params.controlTotalId,
      params.type,
    ),
  );
};

const postUnpaidEmployeesReport = (params: {
  payrollHistoryId: number;
  controlTotalId: number;
  reportType: string;
}): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.POST_PAYROLL_UNPAIDEMPLOYEES_REPORT(
      CommonService.getProtectedClientNo(),
      params.payrollHistoryId,
      params.controlTotalId,
    ),
    { reportType: params.reportType },
  );
};

const postTransmittalReport = (params: {
  payrollHistoryId: number;
  controlTotalId: number;
  reportType: string;
  isFinishedPayroll: boolean;
}): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.POST_PAYROLL_TRANSMITTAL_REPORT(
      CommonService.getProtectedClientNo(),
      params.payrollHistoryId,
      params.controlTotalId,
    ),
    { reportType: params.reportType, isFinishedPayroll: params.isFinishedPayroll },
  );
};

const getPayrollVoidSearch = (
  params: PayrollVoidSearchParams,
): Promise<AxiosResponse<PayrollAdjustmentTransaction[]>> => {
  return API_EMPLOYEE_MASTER.get<PayrollAdjustmentTransaction[]>(
    EmployeeEndPoints.GET_PAYROLL_VOID_SEARCH(
      CommonService.getProtectedClientNo(),
      params,
    ),
  );
};

const postAdjustVoid = (
  payrollHistoryId: number,
  protectedEmpNo: string,
  params: PayrollAdjustmentVoidTransaction,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.POST_PAYROLL_ADJUSTMENT_VOID(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
      protectedEmpNo,
    ),
    params,
  );
};

const postPayrollValidate = (
  request: PayrollValidateRequest,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.POST_PAYROLL_VALIDATE(
      CommonService.getProtectedClientNo(),
      request.payrollHistoryId,
    ),
    { registerType: request.registerType },
  );
};

const getPayrollPayRateValidation = (
  payrollHistoryId: number,
): Promise<AxiosResponse<TransmittalFuturePayRates[]>> => {
  return API_EMPLOYEE_MASTER.get<TransmittalFuturePayRates[]>(
    EmployeeEndPoints.GET_PAYROLL_PAYRATES_VALIDATION(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
    ),
  );
};

const putPayrollPayRateValidation = (
  payrollHistoryId: number,
  request: TransmittalFuturePayRates[],
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<string>>(
    EmployeeEndPoints.PUT_PAYROLL_PAYRATES_VALIDATION(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
    ),
    request,
  );
};

const getPayrollTimeOffRequests = (
  payrollHistoryId: number,
): Promise<AxiosResponse<PayrollTimeOffRequests>> => {
  return API_EMPLOYEE_MASTER.get<PayrollTimeOffRequests>(
    EmployeeEndPoints.PAYROLL_OUTSTANDING_TIMEOFF_REQUESTS(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
    ),
  );
};

const postPayrollApplyTimeOffRequests = (
  applyTimeOffRequest: ApplyTimeOffRequest,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.POST_PAYROLL_APPLY_TIMEOFF_REQUESTS(
      CommonService.getProtectedClientNo(),
      applyTimeOffRequest.payrollHistoryId,
      store.getState().selEmployee.employee?.protectedEmpNo as string,
      applyTimeOffRequest.timeOffRequestId,
    ),
  );
};

const postPayrollMoveTimeOffRequest = (
  moveTimeOffRequest: MoveTimeOffRequest,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.POST_PAYROLL_MOVE_TIMEOFF_REQUEST(
      CommonService.getProtectedClientNo(),
      moveTimeOffRequest.oldPayrollHistoryId,
      store.getState().selEmployee.employee?.protectedEmpNo as string,
      moveTimeOffRequest.timeOffRequestId,
      moveTimeOffRequest.newPayrollHistoryId,
    ),
  );
};

const postPayrollMarkedAsSubmitted = (
  data: {
    payrollHistoryId: number,
    data: PayrollOptions,
  },
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.POST_PAYROLL_MARK_SUBMITTED(
      CommonService.getProtectedClientNo(),
      data.payrollHistoryId,
    ),
    data.data,
  );
};

const postApprovedAccrualReport = (
  request: { payrollHistoryId: number },
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.POST_PAYROLL_ACRRUAL_REPORT(
      CommonService.getProtectedClientNo(),
    ),
    { payrollHistoryId: request.payrollHistoryId,
      reportType: 0 },
  );
};

const postPendingAccrualReport = (
  request: { payrollHistoryId: number },
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.POST_PAYROLL_ACRRUAL_REPORT(
      CommonService.getProtectedClientNo(),
    ),
    { payrollHistoryId: request.payrollHistoryId,
      reportType: 1 },
  );
};

const canRecoverPayroll = (payrollHistoryId: number): Promise<AxiosResponse<any, any>> => {
  return API_EMPLOYEE_MASTER.get<any>(
    EmployeeEndPoints.CAN_RECOVER_PAYROLL(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
    ),
  );
};

const recoverProcessedPayroll = (requestObject: RecoverPayrollRequest): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(
    EmployeeEndPoints.RECOVER_PROCESSED_PAYROLL(
      CommonService.getProtectedClientNo(),
      requestObject.payrollHistoryId,
    ),
    requestObject,
  );
};

const getAdjustmentStateInfo = (payrollHistoryId: number) => {
  return API_EMPLOYEE_MASTER.get(
    EmployeeEndPoints.PAYROLL_ADJUSTMENT_STATE_INFO(
      CommonService.getProtectedClientNo(),
      payrollHistoryId,
    ),
  );
};

const putSqlMescMainCheckNo = (
  mescId: number,
  request: UpdateCheckNoRequest,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<string>>(
    EmployeeEndPoints.PUT_SQLMESCMAIN_CHECKNO(
      CommonService.getProtectedClientNo(),
      mescId,
    ),
    request,
  );
};

export const PayrollService = {
  getPayroll,
  postPayroll,
  postValidatePayroll,
  deletePayroll,
  getClientRecurringEarnings,
  putPayrollDateline,
  getPayrollOptions,
  getPayrollControlTotals,
  getPayrollControlTotal,
  postPayrollControlTotal,
  putPayrollControlTotal,
  postPayrollTransmittalAutofill,
  getPayrollMessages,
  postPayrollMessage,
  putPayrollMessage,
  getPrintPayrolls,
  putUpdateAutoCalcs,
  PutDeptSubSub2OnEmpChange,
  getTransmittalEmployees,
  getTransmittalEmployee,
  postTransmittalEmployee,
  postTransmittalEmployeeCheck,
  putTransmittalEmployeeCheck,
  deleteTransmittalEmployeeCheck,
  getPayrollControlTotalDetail,
  getPayrollHistoryDeductionStatuses,
  putPayrollHistoryDeductionStatuses,
  postUnpaidEmployeesReport,
  postTransmittalReport,
  getBlankTransmittalEmployeeCheck,
  getPayrollVoidSearch,
  postAdjustVoid,
  putPayrollOptions,
  getWireOnlyAgreement,
  putWireOnlyAgreement,
  deleteWireOnlyAgreement,
  postWireOnlyAgreementReport,
  putPayrollPreview,
  postPayrollCheckRegister,
  postPayrollValidate,
  getPayrollTimeOffRequests,
  postPayrollApplyTimeOffRequests,
  postPayrollMoveTimeOffRequest,
  postPayrollMarkedAsSubmitted,
  postPendingAccrualReport,
  postApprovedAccrualReport,
  canRecoverPayroll,
  recoverProcessedPayroll,
  getAdjustmentStateInfo,
  getPayrollPayRateValidation,
  putPayrollPayRateValidation,
  putSqlMescMainCheckNo,
  putWeekBeginningDates,
  deletePayrollMessage,
};
