import { createAction } from '@reduxjs/toolkit';
import { WCEffectiveDate } from '../../models';

export const loadWCEffectiveDates = createAction('[workerComp] LOAD_WC_EFFECTIVE_DATES');
export const storeWCEffectiveDates = createAction<WCEffectiveDate[]>('[workerComp] STORE_WC_EFFECTIVE_DATES');
export const loadWCEffectiveDate = createAction<number>('[workerComp] LOAD_WC_EFFECTIVE_DATE');
export const createWCEffectiveDate = createAction<WCEffectiveDate>('[workerComp] CREATE_WC_EFFECTIVE_DATE');
export const updateWCEffectiveDate = createAction<WCEffectiveDate>('[workerComp] UPDATE_WC_EFFECTIVE_DATE');
export const storeWCEffectiveDate = createAction<WCEffectiveDate>('[workerComp] STORE_WC_EFFECTIVE_DATE');

export const deleteWCEffectiveDate = createAction<WCEffectiveDate>('[workerComp] DELETE_WC_EFFECTIVE_DATE');
export const deleteWCRate = createAction<number>('[workerComp] DELETE_WC_RATE');

