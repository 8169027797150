import { createReducer } from '@reduxjs/toolkit';
import {
  ClientOptions,
  DemographicAndTurnoverEmployee,
  DepartmentofLaborSetting,
  EEOSettingsRequest,
  MyInfoDocStatusFile,

} from '../../models/HumanCapitalReports.model';

import {
  storeCumulativeSeniorityReport,
  clearCumulativeSeniorityReport,
  storeFulltimeEquivalentReport,
  clearFulltimeEquivalentReport,
  storeMyInfoDocStatusFiles,
  storeMyInfoDocStatusReport,
  storeDemographicAndTurnoverReport,
  clearDemographicAndTurnoverReport,
  storeDemographicAndTurnoverEmployees,
  clearEmployeeNavigatorAuditReport,
  storeEmployeeNavigatorAuditReport,
  storeDeparmentofLaborReport,
  clearDeparmentofLaborReport,
  storeDepartmentofLaborSettings,
  storeACAProofListReport,
  clearACAProofListReport,
  clearMyInfoDocStatusReport,
  loadClientReportOptions,
  storeClientReportOptions,
} from '../actions/human-capital.action';

export interface State {
  loading: boolean;
  cumulativeSeniorityReport: string;
  fulltimeEquivalentReport: string;
  myInfoDocStatusFiles: MyInfoDocStatusFile[];
  myInfoDocStatusReport: string;
  employeeAuditNavigatorReport: string;
  demographicAndTurnoverReport: string;
  demographicAndTurnoverEmployees: DemographicAndTurnoverEmployee[];
  departmentofLaborReport: string;
  departmentofLaborSettings: DepartmentofLaborSetting[];
  acaProofListReport: string;
  clientReportOptions: ClientOptions[];
}

const INITIAL_STATE: State = {
  loading: false,
  cumulativeSeniorityReport: '',
  fulltimeEquivalentReport: '',
  myInfoDocStatusFiles: [],
  myInfoDocStatusReport: '',
  employeeAuditNavigatorReport: '',
  demographicAndTurnoverReport: '',
  demographicAndTurnoverEmployees: [],
  departmentofLaborReport: '',
  departmentofLaborSettings: [],
  acaProofListReport: '',
  clientReportOptions: [],
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(storeCumulativeSeniorityReport, (state, action) => {
      state.loading = false;
      state.cumulativeSeniorityReport = action.payload;
    })
    .addCase(clearCumulativeSeniorityReport, (state) => {
      state.cumulativeSeniorityReport = '';
    })
    .addCase(storeFulltimeEquivalentReport, (state, action) => {
      state.loading = false;
      state.fulltimeEquivalentReport = action.payload;
    })
    .addCase(clearFulltimeEquivalentReport, (state) => {
      state.fulltimeEquivalentReport = '';
    })
    .addCase(storeMyInfoDocStatusFiles, (state, action) => {
      state.loading = false;
      state.myInfoDocStatusFiles = action.payload;
    })
    .addCase(storeMyInfoDocStatusReport, (state, action) => {
      state.loading = false;
      state.myInfoDocStatusReport = action.payload;
    })
    .addCase(clearMyInfoDocStatusReport, (state) => {
      state.myInfoDocStatusReport = '';
    })
    .addCase(storeEmployeeNavigatorAuditReport, (state, action) => {
      state.loading = false;
      state.employeeAuditNavigatorReport = action.payload;
    })
    .addCase(clearEmployeeNavigatorAuditReport, (state) => {
      state.employeeAuditNavigatorReport = '';
    })
    .addCase(storeDemographicAndTurnoverReport, (state, action) => {
      state.loading = false;
      state.demographicAndTurnoverReport = action.payload;
    })
    .addCase(clearDemographicAndTurnoverReport, (state) => {
      state.demographicAndTurnoverReport = '';
    })
    .addCase(storeDemographicAndTurnoverEmployees, (state, action) => {
      state.loading = false;
      state.demographicAndTurnoverEmployees = action.payload;
    }).addCase(storeACAProofListReport, (state, action) => {
      state.loading = false;
      state.acaProofListReport = action.payload;
    })
    .addCase(clearACAProofListReport, (state) => {
      state.acaProofListReport = '';
    })
    .addCase(storeDeparmentofLaborReport, (state, action) => {
      state.departmentofLaborReport = action.payload;
    })
    .addCase(clearDeparmentofLaborReport, (state) => {
      state.departmentofLaborReport = '';
    })
    .addCase(storeDepartmentofLaborSettings, (state, action) => {
      state.departmentofLaborSettings = action.payload;
    })
    .addCase(loadClientReportOptions, (state) => {
      state.loading = true;
    })
    .addCase(storeClientReportOptions, (state, action) => {
      state.loading = false;
      state.clientReportOptions = action.payload;
    });
});