import { AgGridReact } from '@ag-grid-community/react';
import {
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  MasterDetailModule,
  RowSelectedEvent,
  ValueGetterParams,
} from '@ag-grid-enterprise/all-modules';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  PayrollDeductionShortage,
  PayrollEmployeeDeductionShortage,
  PayrollOptions,
} from 'core/models';
import { updatePayrollOptions } from 'core/store/actions';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'utilities/hooks';
import ShortageGrid from './ShortageGrid.modal';
import { currencyFormatter } from 'utilities/utilities';

type PropTypes = {
  data: PayrollDeductionShortage[];
  id: number;
  empCount: number;
  payrollOptsRef: React.MutableRefObject<PayrollOptions | null>
};
const OutstandingDedShortages = ({
  data,
  id,
  empCount,
  payrollOptsRef,
}: PropTypes) => {
  const [calcType, setCalcType] = useState<string>();
  const [, setGridApi] = useState<GridApi | null>(null);
  const [, setGridColumnApi] = useState<ColumnApi | null>(null);
  const [showShortageGrid, setShowShortageGrid] = useState(false);
  const [rowData, setRowData] = useState<PayrollDeductionShortage[]>([]);
  const [shortages, setShortages] = useState<{
    data: PayrollEmployeeDeductionShortage[];
    index: number;
  }>();
  const [calculateAllShortages, setCalculateAllShortages] = useState<boolean>(false);
    
  const selected = useRef<RowSelectedEvent>();

  const dispatch = useDispatch();

  const cascadeRows = (selection: string, params: ICellRendererParams) => {
    const { data, rowIndex } = params;
    
    if (selection !== 'All') setCalculateAllShortages(false);
    
    copyShortageData(data, rowIndex, selection === 'All');
  };

  const copyShortageData = (data: any, index: number, all: boolean) => {
    if (!data?.shortages) return;
    const shortagesCopy: PayrollEmployeeDeductionShortage[] = structuredClone(data.shortages);

    shortagesCopy.forEach(
      (shortage: PayrollEmployeeDeductionShortage, i) => {
        if (shortage.gettingPaid) {
          shortagesCopy[i].process = all;
        }
      },
    );

    modifyEntry(index, all, shortagesCopy);
  };

  const modifyEntry = (
    index: number,
    process: boolean,
    copy: PayrollEmployeeDeductionShortage[],
  ) => {
    if (payrollOptsRef.current) {
      const deductionShortages = payrollOptsRef.current?.deductionShortages;

      const dedShortCopy: PayrollDeductionShortage[] = structuredClone(deductionShortages);

      dedShortCopy.splice(index, 1, {
        ...deductionShortages[index],
        process,
        shortages: copy,
      });

      const updatedData = {
        data: {
          ...payrollOptsRef.current,
          deductionShortages: dedShortCopy,
        },
        payrollHistoryId: id,
      };

      dispatch(updatePayrollOptions(updatedData));
    }
  };

  const cellStyleRenderer = (data: any) => {
    let status = { color: '',
      text: '' };
    let processing = 0,
      paid = 0;

    data.shortages.forEach((shortage: PayrollEmployeeDeductionShortage) => {
      if (shortage.process) {
        processing++;
      }
      if (shortage.gettingPaid) {
        paid++;
      }
    });

    if (paid > processing && processing > 0) {
      status = { color: '#fff793',
        text: 'Some' };
    } else if ((paid > processing && processing === 0) || paid === 0) {
      status = { color: '#fce6e5',
        text: 'None' };
    } else {
      status = { color: '#e3fedb',
        text: 'All' };
    }

    return status;
  };

  //#region AgGrid
  const columns: ColDef[] = [
    {
      field: 'dedNo',
      headerName: 'Ded No',
      cellRenderer: 'agGroupCellRenderer',
      width: 70,
    },
    {
      field: 'shortDesc',
      headerName: 'Short Desc',
      width: 80,
    },
    {
      field: 'longDesc',
      headerName: 'Long Desc',
      width: 80,
      resizable: true,
    },
    {
      field: 'employeesWithShortages',
      headerName: 'Emps w/ Ded Shortages',
      width: 140,
    },
    {
      field: 'paidEmployeesWithShortages',
      headerName: 'Paid this Period w/ Ded Shortages',
      width: 210,
    },
    {
      field: 'process',
      headerName: 'Process',
      width: 125,
      cellRenderer: (params: ICellRendererParams) => {
        const disable = params.data.paidEmployeesWithShortages < 1;
        const selectOpts = ['All', 'None', 'Some'];
        const { data, rowIndex } = params;

        const handleCalcTypeChange = (
          e: React.ChangeEvent<HTMLSelectElement>,
        ) => {
          if (e.target.value === 'Some') {
            setShortages({
              data: data?.shortages,
              index: rowIndex,
            });
            setShowShortageGrid(true);
            setCalculateAllShortages(false);
          } else {
            setCalcType(e.target.value);
            cascadeRows(e.target.value, params);
          }
        };

        return (
          <div style={{ width: '100%' }}>
            <select
              value={calcType}
              onChange={handleCalcTypeChange}
              style={{
                appearance: 'none',
                width: '100%',
              }}
              disabled={disable}
            >
              <option
                value="none"
                selected
                disabled
                hidden
              >
                Select an action
              </option>
              {selectOpts.map((opt) => {
                return (
                  <option key={opt}>{opt}</option>
                );
              })}
            </select>
          </div>
        );
      },
    },
    {
      field: 'process',
      headerName: 'Status',
      width: 80,
      cellRenderer: (params: ICellRendererParams) => {
        const { text } = cellStyleRenderer(params.data);

        return <div>{text}</div>;
      },
      cellStyle: (params) => {
        const { color } = cellStyleRenderer(params.data);

        return { backgroundColor: color };
      },
    },
  ];

  const gridOptions: GridOptions = {
    columnDefs: columns,
    domLayout: 'autoHeight',
    defaultColDef: {
      flex: 1,
      autoHeight: true,
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    stopEditingWhenCellsLoseFocus: true,
    getRowNodeId: (data: any) => {return data.dedNo;},
    masterDetail: true,
    detailRowAutoHeight: true,
    detailCellRendererParams: {
      detailGridOptions: {
        domLayout: 'autoHeight',
        defaultColDef: {
          flex: 1,
          suppressMenu: true,
          resizable: true,
          cellClass: 'ag-cell-left-border',
          singleClickEdit: true,
        },
        stopEditingWhenCellsLoseFocus: true,
        getRowNodeId: (data: PayrollEmployeeDeductionShortage) => {return data.empNo;},
        columnDefs: [
          {
            field: 'empNo',
            label: 'Emp No',
            width: 100,
          },
          {
            field: 'employeeName',
            label: 'Employee Name',
            width: 100,
          },
          {
            field: 'amount',
            label: 'Amount',
            width: 100,
            cellStyle: { 'text-align': 'right' },
            valueGetter: (params: ValueGetterParams) => {
              return currencyFormatter(params.data.amount);
            },
          },
          {
            field: 'dedNo',
            label: 'Ded No',
            width: 100,
          },
          {
            field: 'gettingPaid',
            label: 'Being Paid This Payroll',
            width: 100,
            valueGetter: (params: ValueGetterParams) => {
              return params.data.gettingPaid ? 'Yes' : 'No';
            },
          },
          {
            field: 'process',
            label: 'Process',
            width: 100,
            valueSetter: (params: any) => {
              setTimeout(() => {
                params.data.process = true;
              }, 0);
              return true;
            },
            valueGetter: (params: ValueGetterParams) => {
              return params.data.process ? 'Yes' : 'No';
            },
          },
        ],
      },
      getDetailRowData(params: any) {
        params.successCallback(params.data.shortages);
      },
    },
  };

  //#endregion

  useEffect(() => {
    data && setRowData(data);
  }, [data]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onGridSizeChanged = (params: any) => {
    params.api.sizeColumnsToFit();
  };

  const onRowSelected = (params: RowSelectedEvent) => {
    selected.current = params;
  };
  
  const updateCalculateAll = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, calculateAll: boolean) => {
    setCalculateAllShortages(calculateAll);
              
    if (!payrollOptsRef.current) return;
              
    const updatedDedShortages: PayrollDeductionShortage[] = rowData?.map((x) => {
      return {
        ...x,
        process: calculateAll,
        shortages: x.shortages.map((y) => ({ ...y, process: y.gettingPaid && calculateAll })),
      };
    });   
    const submitData: { data: PayrollOptions, payrollHistoryId: number } = {
      data: {
        ...payrollOptsRef.current,
        deductionShortages: updatedDedShortages,
      },
      payrollHistoryId: id,
    };
              
    dispatch(updatePayrollOptions(submitData));
              
    setRowData((prevState) => {
      const updatedData: PayrollDeductionShortage[] = prevState?.map((x) => {
        return {
          ...x,
          process: calculateAll,
          shortages: x.shortages.map((y) => ({ ...y, process: y.gettingPaid && calculateAll })),
        };
      });                
                
      return updatedData ?? [];
    });
  };

  return (
    <div className="d-flex flex-column dm-panel-border p-2 mb-2">
      <div className="dm-card-subtitle2 pt-2 mb-2">
        Outstanding Employee Deduction Shortages
      </div>
      <div onClick={(e) => { return e.stopPropagation(); }}>
        <div className="d-flex flex-column dm-panel-border p-2 mb-2">
          <strong>Calculation of Deduction Shortages</strong>
          <p>For this P/R processing ONLY, do you wish to calculate and consider &ldquo;Deduction Shortages&rdquo; {empCount === 1 ? 'for 1 employee?' : `for all ${empCount} employees?`}</p>
          <div className="d-flex w-100 justify-content-center">
            <button
              className="orange-outline-button-sm mr-2"
              onClick={(e) => {
                e.preventDefault();
                updateCalculateAll(e, true);
              }}
            >
              All
            </button>
            <button
              className="orange-outline-button-sm"
              onClick={(e) => {
                e.preventDefault();
                updateCalculateAll(e, false);
              }}
            >
              None
            </button>
          </div>
        </div>
        <Row>
          <Col
            xs={12}
            className="table-wrapper-wrapper ag-theme-balham mb-2"
          >
            <AgGridReact
              gridOptions={gridOptions}
              rowData={rowData}
              onGridSizeChanged={onGridSizeChanged}
              modules={[MasterDetailModule]}
              onGridReady={onGridReady}
              onRowClicked={onRowSelected}
            />
          </Col>
        </Row>
        {showShortageGrid && shortages ? (
          <ShortageGrid
            show={showShortageGrid}
            onHide={() => {return setShowShortageGrid(false);}}
            rowData={shortages.data}
            update={setShortages}
            index={shortages.index}
            id={id}
            submit={modifyEntry}
          />
        ) : null}
      </div>
    </div>
  );
};
export default OutstandingDedShortages;
