import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { EarningCode, EarningsCodeAutoCalculation } from '../../models';
import {
  clearEarningsCodeAutoCalculationMessages,
  loadEarningCodes,
  loadEarningCodesAutoCalculations,
  storeEarningCodes,
  storeEarningCodesAutoCalculations,
  storeEarningCodesAutoCalculationsMessages,
} from '../actions/earning-codes.action';

export interface EarningCodeState {
  loading: boolean;
  earningCodes: EarningCode[];
  earningCodeAutoCalculations: EarningsCodeAutoCalculation[];
  messages: string[];
}

export const INITIAL_STATE: EarningCodeState = {
  loading: true,
  earningCodes: [],
  earningCodeAutoCalculations: [],
  messages: [],
};

export const earningCodeReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadEarningCodes, (state) => {
      state.loading = true;
    })
    .addCase(storeEarningCodes, (state, action) => {
      state.loading = false;
      state.earningCodes = [];
      action.payload.forEach((earningCode) => {
        earningCode.selectDescription = `${earningCode.earnCode} - ${earningCode.shortDescription}`;
        state.earningCodes.push(earningCode);
      });
    })
    .addCase(loadEarningCodesAutoCalculations, (state) => {
      state.loading = true;
    })
    .addCase(storeEarningCodesAutoCalculations, (state, action) => {
      state.loading = false;
      state.earningCodeAutoCalculations = action.payload;
    })
    .addCase(
      storeEarningCodesAutoCalculationsMessages,
      (state, action: PayloadAction<string[]>) => {
        state.messages = action.payload;
      },
    )
    .addCase(clearEarningsCodeAutoCalculationMessages, (state) => {
      state.messages = [];
    });
});
