import { AxiosResponse } from "axios";
import { EmployeeUploadClientFile, EmployeeUploadClientLibrary, EmployeeUploadMap, EmployeeUploadTranslation, EmployeeUploadVM } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getEmpUploads = (): Promise<AxiosResponse<EmployeeUploadVM>> => {return API_EMPLOYEE_MASTER.get<EmployeeUploadVM>(EmployeeEndPoints.GET_EMPLOYEE_UPLOADS(CommonService.getProtectedClientNo()));};

const postEmpUpload = (clientFile: EmployeeUploadClientFile): Promise<AxiosResponse<EmployeeUploadClientFile>> => {return API_EMPLOYEE_MASTER.post<EmployeeUploadClientFile>(EmployeeEndPoints.POST_EMPLOYEE_UPLOAD(CommonService.getProtectedClientNo()), clientFile);};

const putEmpUpload = (clientFile: EmployeeUploadClientFile): Promise<AxiosResponse<EmployeeUploadClientFile>> => {return API_EMPLOYEE_MASTER.put<EmployeeUploadClientFile>(EmployeeEndPoints.PUT_EMPLOYEE_UPLOAD(CommonService.getProtectedClientNo(), clientFile.clientFileId), clientFile);};

const putEmpUploadClientLibrary = (file: EmployeeUploadClientLibrary): Promise<AxiosResponse<EmployeeUploadClientLibrary>> => {return API_EMPLOYEE_MASTER.put<EmployeeUploadClientLibrary>(EmployeeEndPoints.PUT_EMPLOYEE_UPLOAD_CLIENT_LIBRARY(CommonService.getProtectedClientNo(), file.clientFileId), file);};

const postEmpUploadField = (field: EmployeeUploadMap): Promise<AxiosResponse<EmployeeUploadMap>> => {return API_EMPLOYEE_MASTER.post<EmployeeUploadMap>(EmployeeEndPoints.POST_EMPLOYEE_UPLOAD_FIELD(CommonService.getProtectedClientNo(), field.clientFileId), field);};

const putEmpUploadField = (field: EmployeeUploadMap): Promise<AxiosResponse<EmployeeUploadMap>> => {return API_EMPLOYEE_MASTER.put<EmployeeUploadMap>(EmployeeEndPoints.PUT_EMPLOYEE_UPLOAD_FIELD(CommonService.getProtectedClientNo(), field.clientFileId, field.mapId), field);};

const deleteEmpUploadField = (field: EmployeeUploadMap): Promise<AxiosResponse<EmployeeUploadMap>> => {return API_EMPLOYEE_MASTER.delete<EmployeeUploadMap>(EmployeeEndPoints.DELETE_EMPLOYEE_UPLOAD_FIELD(CommonService.getProtectedClientNo(), field.clientFileId, field.mapId));};

const postEmpUploadTranslation = (clientFileId: number, translation: EmployeeUploadTranslation): Promise<AxiosResponse<EmployeeUploadTranslation>> => {return API_EMPLOYEE_MASTER.post<EmployeeUploadTranslation>(EmployeeEndPoints.POST_EMPLOYEE_UPLOAD_TRANSLATION(CommonService.getProtectedClientNo(), clientFileId, translation.mapId), translation);};

const postAddUpdateEmployeeTranslation = (clientFileId: number, mapId: number, translations: EmployeeUploadTranslation[]): Promise<AxiosResponse<EmployeeUploadTranslation[]>> => {return API_EMPLOYEE_MASTER.post<EmployeeUploadTranslation[]>(EmployeeEndPoints.POST_ADD_UPDATE_EMPLOYEE_UPLOAD_TRANSLATION(CommonService.getProtectedClientNo(), clientFileId, mapId), translations);};

const putEmpUploadTranslation = (clientFileId: number, translation: EmployeeUploadTranslation): Promise<AxiosResponse<EmployeeUploadTranslation>> => {return API_EMPLOYEE_MASTER.put<EmployeeUploadTranslation>(EmployeeEndPoints.PUT_EMPLOYEE_UPLOAD_TRANSLATION(CommonService.getProtectedClientNo(), clientFileId, translation.mapId, translation.translationId), translation);};

const deleteEmpUploadTranslation = (clientFileId: number, translation: EmployeeUploadTranslation): Promise<AxiosResponse<EmployeeUploadTranslation>> => {return API_EMPLOYEE_MASTER.delete<EmployeeUploadTranslation>(EmployeeEndPoints.DELETE_EMPLOYEE_UPLOAD_TRANSLATION(CommonService.getProtectedClientNo(), clientFileId, translation.mapId, translation.translationId));};

export const EmployeeUploadService = {
  getEmpUploads,
  postEmpUpload,
  putEmpUpload,

  putEmpUploadClientLibrary,

  postEmpUploadField,
  putEmpUploadField,
  deleteEmpUploadField,

  postEmpUploadTranslation,
  postAddUpdateEmployeeTranslation,
  putEmpUploadTranslation,
  deleteEmpUploadTranslation,
};
