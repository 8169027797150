
import { createSelector } from 'reselect';
import { rateRuleState } from '../reducers';
import { State as RateRuleState } from '../reducers/rate-rule.reducer';

export const getRateRules = createSelector(
  rateRuleState,
  (state: RateRuleState) => {return state.rateRule;},
);


