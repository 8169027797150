import React from 'react';
import CheckBoxForm from '../../Shared/CheckBoxForm';
import GridSelect from '../../Shared/GridSelect';
import { useAppSelector } from 'utilities/hooks';
import { Col, Row } from 'react-bootstrap';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for Checkbox and Grid select forms
const DatesFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  const otherDateDescriptionOptions = useAppSelector((state) => { return state?.dropdown.otherDateDescription; });

  return (
    <div>
      {!!sectionFields?.length && (
        <Row>
          <Col xs={12}>
            <CheckBoxForm
              formMethods={formMethods}
              dFields={sectionFields.find(f => {
                return f.name === 'Dates';
              })?.fields}
              section="Dates"
              title="Dates"
            />
          </Col>
          <Col sm={6}>
            <Row>
              <Col xs={12}>
                <GridSelect
                  section="Custom Dates"
                  title="Custom Dates"
                  dFields={sectionFields.find(f => {
                    return f.name === 'Custom Dates';
                  })?.fields}
                  options={otherDateDescriptionOptions}
                  hiddenColumns={['code']}
                  formMethods={formMethods}
                  isCodeField = {false}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col xs={12}>
                <CheckBoxForm
                  formMethods={formMethods}
                  dFields={sectionFields.find(f => {
                    return f.name === 'Hire History';
                  })?.fields}
                  section="Hire History"
                  title="Hire History"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DatesFields;