import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Employee, EmployeePayRate, EmployeePayRateScreen } from 'core/models';
import { addUpdateEmployeePayRatesScreen, toggleBlockNavigation, deletePayRate, handleError } from 'core/store/actions';
import { SelectGrp } from '../form-controls';
import { FieldInputSettings } from '../form-controls/types';
import PanelHeader from './PanelHeader';
import { getEmployeePayRatesScreen, getPayPeriods, getReportOptions, getSelectedEmp } from 'core/store/selectors';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import PayRateHistoryModal from '../modals/PayRateHistory.modal';
import Icon from './Icon';
import { fromCurrency, getAccess } from 'utilities/utilities';
import UpdatePayPeriodModal from '../modals/UpdatePayPeriod.modal';
import PayRateItem from 'features/emp-detail/pay-rate/PayRateItem';
import SaveButton from './SaveButton';
import { UNSAVED_MESSAGE } from 'core/constants';
import { useHistory } from 'react-router-dom';
import PayRateWorkFlowModal from 'features/emp-detail/pay-rate/PayRateWorkFlow.modal';
import WorkFlowTaskSuccessModal from 'features/workflows/WorkFlowTaskSuccess.modal';
import { storeShowWorkTaskSuccessModal } from 'core/store/slices/work-flow.slice';

const getDefaultPayRate = (employee: Employee, clientNo: number): EmployeePayRate => ({
  tranDigit: 0,
  //We add a random id so we are able to delete the specific id and not show it. Gets converted to 0 on save
  rateId: parseInt((Math.random() * -10000).toString()),
  protectedEmpNo: employee.protectedEmpNo,
  empId: employee.empId,
  empNo: employee.empNo,
  clientNo: clientNo,
  hourlyRate: 0,
  salaryRate: 0,
  annualRate: 0,
  location: 0,
  department: 0,
  subDepartment: 0,
  subDepartment2: 0,
  description: '',
  notes: '',
  effectiveDate: null,
  area: null,
  trade: null,
  sub: null,
  overScale: null,
  createDate: new Date(),
  modifyDate: new Date(),
  clock: 0,
  hasRateOnOpenTransmittal: false,
  hourlyRateNotRounded: 0,
  salaryRateNotRounded: 0,
  annualRateNotRounded: 0,
});

const fs: FieldInputSettings = {
  payPeriod: {
    name: 'payPeriod',
    label: 'PAY PERIOD',
    groupClass: 'gc20 mw150',
    controlId: 141,
  },
};

type PropTypes = {
  protectedEmpNo: string;
  dirtyState: boolean;
  updateDirtyState: (newVal: boolean) => void;
};

const CurrentPayRates: React.FC<PropTypes> = ({ protectedEmpNo, dirtyState, updateDirtyState }) => {
  const dispatch = useAppDispatch();
  
  const selEmp = useSelector(getSelectedEmp);
  const payPeriodOptions = useSelector(getPayPeriods);
  const empPayRates = useSelector(getEmployeePayRatesScreen);
  const client = useAppSelector((state) => { return state.client.client; });
  const isSaving = useAppSelector((state) => { return state.selEmployeeDetails.payRate.saving; });
  const showWorkTaskSuccessModal = useAppSelector((state) => { return state.workFlow.showWorkTaskSuccessModal; });
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  const clientNo = client?.clientNo ?? 0;
  const usesPayRateWorkFlow = useSelector(getReportOptions('PayRateWorkFlow'));
  
  const [baseValue, setBaseValue] = useState<'annualRate' | 'salaryRate'>();
  const [showPayPeriodChangeModal, setShowPayPeriodChangeModal] = useState(false);
  const [showPayRateHistoryModal, setShowPayRateHistoryModal] = useState(false);
  const [payPeriodState, setPayPeriodState] = useState<string | null>(client?.clientPayrollFreq.charAt(0) ?? null);
  const [payRateState, setPayRateState] = useState<EmployeePayRate[]>(empPayRates?.payRates ?? []);
  const [selectedPayRate, setSelectedPayRate] = useState<EmployeePayRate | null>(null);
  const [showPayRateWorkFlow, setShowPayRateWorkFlow] = useState<boolean>(false);

  // Since the user might cancel updating the pay period, we want to be able to reset the state back to what it was before the change
  const payPeriodRef = useRef<string | null>(empPayRates?.payPeriod ?? null);
  
  const handleBlockUnsavedChanges = (newVal: boolean) => {
    dispatch(toggleBlockNavigation({
      block: newVal,
      type: 'confirmation',
      message: `${UNSAVED_MESSAGE} Note: If the employee's pay period has changed, this will affect their rates.`,
    }));
  };
  
  const handleUpdateDirtyState = (newVal: boolean) => {
    handleBlockUnsavedChanges(newVal);
    updateDirtyState(newVal);
  };
  
  useEffect(() => {
    setPayRateState(empPayRates?.payRates ?? []);
    handleUpdateDirtyState(false);
  }, [empPayRates?.payRates]);
  
  useEffect(() => {
    if (!empPayRates) return;
    setPayPeriodState(empPayRates.payPeriod);
  }, [empPayRates?.payPeriod]);
  
  useEffect(() => {
    setBaseValue(undefined);
    handleUpdateDirtyState(false);
  }, [selEmp?.empNo]);
  
  const onChangePayPeriod = (e: React.ChangeEvent<HTMLSelectElement>) => {
    payPeriodRef.current = e.target.value; // keep this for later because we might need to roll it back
    setShowPayPeriodChangeModal(true);
  };
  
  const onAddNewPayrate = () => {
    if (selEmp) {
      window.scrollTo(0, document.body.scrollHeight);
      setPayRateState((prevState) => [
        ...prevState,
        getDefaultPayRate(selEmp, clientNo),
      ]);
    }
  };

  const onDelete = (payRate: EmployeePayRate) => {
    if (!confirm('Delete pay rate? This action cannot be undone.')) return;
    setPayRateState((prevState) => [...(prevState?.filter((rate) => rate.rateId !== payRate.rateId) ?? [])]);
    if (payRate.rateId > 0) dispatch(deletePayRate({ rateId: payRate.rateId, protectedEmpNo: payRate.protectedEmpNo }));
  };

  const onSubmit = () => {
    if (showPayRateHistoryModal) return;
    if (!(selEmp && clientNo && client?.clientPayrollFreq)) return dispatch(handleError('Error with employee/client when saving'));
    
    const payRates: EmployeePayRate[] = [];
       
    payRateState.forEach(rate => {
      if (selEmp) {
        const payRate: EmployeePayRate = {
          ...rate,
          hourlyRate: (rate.hourlyRateNotRounded) ? +rate.hourlyRateNotRounded : fromCurrency(rate.hourlyRate?.toString()) ?? 0,
          salaryRate: (rate.salaryRateNotRounded) ? +rate.salaryRateNotRounded : fromCurrency(rate.salaryRate?.toString()) ?? 0,
          annualRate: (rate.annualRateNotRounded) ? +rate.annualRateNotRounded : fromCurrency(rate.annualRate?.toString()) ?? 0,
          rateId: (rate.rateId > 0) ? rate.rateId : 0,
        };
        if (!isNaN(payRate.hourlyRate) && !isNaN(payRate.salaryRate) && !isNaN(payRate.annualRate)) payRates.push(payRate);
      }
    }); 
    
    if (!payRates.length) return dispatch(handleError('Error saving pay rates'));

    const request: EmployeePayRateScreen = {
      protectedEmpNo,
      clientNo: clientNo,
      empId: selEmp?.empId,
      empNo: selEmp?.empNo,
      payPeriod: payPeriodState ?? client?.clientPayrollFreq,
      payRates: [
        ...payRates,
      ],
    };
    
    dispatch(addUpdateEmployeePayRatesScreen(request));
    handleUpdateDirtyState(false);
  };
  
  const onHidePayPeriodModal = (confirm: boolean, data: { baseValue: 'salaryRate' | 'annualRate' | undefined }) => {
    // user canceled, roll it back
    if (!(confirm && data?.baseValue)) {
      setBaseValue(undefined);
      setShowPayPeriodChangeModal(false);
      return;
    } 
    
    setPayPeriodState(payPeriodRef.current);
    setBaseValue(data.baseValue);
    setShowPayPeriodChangeModal(false);
  };
  
  const updatePayRate = (item: EmployeePayRate) => {
    const updateIndex = payRateState.findIndex((rate) => rate.rateId === item.rateId);
    if (updateIndex === -1) return dispatch(handleError('Could not update rate'));
    
    const clone = structuredClone(payRateState);
    clone.splice(updateIndex, 1, item);
    
    setPayRateState(clone);
    handleUpdateDirtyState(true);
  };

  return (
    <div>
      <PanelHeader title="Current Pay Rates">
        {!usesPayRateWorkFlow?.showReport ? <button
          {...getAccess(sectionAccess, 2, undefined, { disabledSameAsReadOnly: true })}
          className="btn btn-link dm-grid-action-title pb-0 mr-2"
          onClick={onAddNewPayrate}
        >
          Add Additional Rate{' '}
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button> :
        <button
          className="btn btn-link dm-grid-action-title pb-0 mr-2"
          onClick={() => setShowPayRateWorkFlow(true)}
        >
          Add New Pay Rate{' '}
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button>}
      </PanelHeader>
      <div>
        <div className="d-inline-flex py-2">
          <SelectGrp
            {...fs.payPeriod}
            {...getAccess(sectionAccess, 2, fs.payPeriod.controlId)}
            value={payPeriodState}
            onChange={onChangePayPeriod}
            options={payPeriodOptions}
          />
          <div className="d-flex mr-4 align-items-center">
            <button
              className="btn btn-link dm-grid-action-title"
              onClick={() => { setShowPayRateHistoryModal(true); }}
            >
              View Pay Rate History
            </button>
          </div>
          {showPayRateHistoryModal && (
            <PayRateHistoryModal
              show={showPayRateHistoryModal}
              onHide={() => { setShowPayRateHistoryModal(false); }}
            />
          )}

        </div>
        {payRateState.map((item: EmployeePayRate, index: number) => {
          return (
            <div key={item.rateId}>
              <PayRateItem
                baseValue={baseValue}
                payPeriodState={payPeriodState}
                index={index}
                onDelete={onDelete}
                payRate={item}
                updatePayRate={updatePayRate}
                updateDirtyState={handleUpdateDirtyState}
                usesPayRateWorkFlow={usesPayRateWorkFlow?.showReport || false}
                setSelectedPayRate={setSelectedPayRate}
                setShowPayRateWorkFlow={setShowPayRateWorkFlow}
              />
              {index + 1 < payRateState.length && (
                <hr className="dm-panel-hr mt-2" />
              )}
            </div>
          );
        })}
        {!usesPayRateWorkFlow?.showReport ?
        <div className="text-right mt-3">
          <SaveButton
            saving={isSaving}
            classes="btn orange-button mr-3"
            onClick={onSubmit}
            disabled={isSaving || !dirtyState}
          />
        </div> : null}
      </div>
      {showPayPeriodChangeModal && (
        <UpdatePayPeriodModal
          show={showPayPeriodChangeModal}
          onHide={(confirm: boolean, data: { baseValue: 'salaryRate' | 'annualRate' | undefined }) => {
            onHidePayPeriodModal(confirm, data);
          }}
        />
      )}
      {showPayRateWorkFlow && selEmp && (
        <PayRateWorkFlowModal
          show={showPayRateWorkFlow}
          onHide={() => {setShowPayRateWorkFlow(false), setSelectedPayRate(null)}}
          selectedPayRate={selectedPayRate ? selectedPayRate : getDefaultPayRate(selEmp, clientNo)} 
          handleUpdateDirtyState={handleUpdateDirtyState}  
          onDelete={onDelete}
        />
      )}
      {showWorkTaskSuccessModal ? 
        <WorkFlowTaskSuccessModal 
          show={showWorkTaskSuccessModal}
          onHide={() => {dispatch(storeShowWorkTaskSuccessModal(false))}}/>
      : null}

    </div>
  );
};

export default CurrentPayRates;