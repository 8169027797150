import { createReducer } from '@reduxjs/toolkit';
import { TimeOffRequest } from '../../models/TimeOffRequest.model';
import {
  loadTimeOffPastDue,
  storeTimeOffPastDue,
} from '../actions/time-off.action';

export interface TimeOffState {
  loading: boolean;
  timeOffPastDues: TimeOffRequest[];
}

export const INITIAL_STATE: TimeOffState = {
  loading: false,
  timeOffPastDues: [],
};

export const timeOffReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadTimeOffPastDue, (state) => {
      state.loading = true;
    })
    .addCase(storeTimeOffPastDue, (state, action) => {
      state.loading = false;
      state.timeOffPastDues = action.payload;
    });
});
