import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Employee } from 'core/models';
import { getLocationDepartmentsWithoutHome, getSelectedEmp } from 'core/store/selectors';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { loadEmployee, storeEmployee, storeFilteredEmployees, storeFilterModel, updateListQuery } from 'core/store/actions';
import EmpListDetailEdit from 'features/employee-master/EmpListDetailEdit';
import { storeEmpNos } from 'core/store/actions/employee-information.action';
import { AccessMap, convToDateString, ddLookup, formatSSN, getAccess, getAllAccess, SectionAccessConfig } from 'utilities/utilities';
import RadioTabs from 'core/components/shared/RadioTabs';
import Pagination from 'core/components/shared/Pagination';
import styles from './employee-styles.module.scss';
import usePagination from 'hooks/usePagination';
import useTableSort, { SortDirection } from 'hooks/useTableSort';
import Icon from 'core/components/shared/Icon';
import { useBreakpoint } from 'utilities/BreakpointProvider';
import { EmployeeMasterSection } from 'core/models/ModuleAccess.model';
import SearchBar from 'core/components/shared/SearchBar';
import EmployeeRow from './EmployeeRow';
import { aggregateFilter } from './utilities';
import { employeeRadioOptions, TransmittalDefaultSort, SortMapping } from './constants';
import { ControlIds } from 'core/constants';
import { buildInitialState, inRange } from 'core/components/shared/table/utilities';
import { TableModel as Tm } from 'core/components/shared/table/types';
import SortableHeader from 'core/components/shared/table/SortableHeader';

const EmployeeList = () => {
  // #region Hooks
  const selectedEmp: Employee | null = useSelector(getSelectedEmp);
  const { locationOpts, deptOpts, subdeptOpts, subdept2Opts } = useSelector(getLocationDepartmentsWithoutHome);
  const {
    employees,
    filteredEmployees: filteredEmps,
    radioSelection,
    filterModel,
    filterModelOpts,
    listQuery,
  } = useAppSelector(({ employees }) => employees);
  // use default transmittal sort CM option to for default sort of employee list
  const defaultSortOption = useAppSelector(({ client }) => client?.clientOptions?.options?.[34]?.optionValue) as TransmittalDefaultSort ?? 'Alphabetically';
  
  const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([]);
  const [activeCols, setActiveCols] = useState<Array<keyof Employee>>(SortMapping[defaultSortOption]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  
  const dispatch = useAppDispatch();
  
  const history = useHistory();
  const location = useLocation();
  
  const { currentRange, numPages } = usePagination(filteredEmployees.length, currentPage, 20);
  
  const { currentSortState, sortDispatch, sortRowsByColumn } = useTableSort(buildInitialState(filterModel));
  
  const breakpoints = useBreakpoint();
  
  const sectionAccess: EmployeeMasterSection[] | undefined = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections);
  // section
  const empListSectionAccess: SectionAccessConfig = getAccess(sectionAccess, 1);
  const empDatesAccess: SectionAccessConfig = getAccess(sectionAccess, 7);
  // fields
  const allEmpInfoAccess: AccessMap | null = getAllAccess(sectionAccess?.find((x) => x.workItemId === 1));
  
  useEffect(() => {
    setFilteredEmployees((prevState) => sortRowsByColumn<Employee>(prevState, activeCols, currentSortState[activeCols[0]], SortMapping[defaultSortOption]));
  }, [currentSortState]);
  
  useEffect(() => {
    const newEmps = aggregateFilter(employees, filterModel, listQuery, radioSelection);
    setFilteredEmployees(newEmps);
  }, [employees]);

  useEffect(() => {
    if (!filteredEmps || filteredEmps.length < 1) {
      setFilteredEmployees(employees.filter((e: Employee) => { return !e.termDate; }));
    } else {
      setFilteredEmployees(filteredEmps);
    }
  }, [filteredEmps]);
  
  useEffect(() => {
    // ensures we always select the first employee in the list when sorting/filtering/page changes
    const emps = aggregateFilter(employees, filterModel, listQuery, radioSelection);
    const filtered = sortRowsByColumn<Employee>(emps, activeCols, currentSortState?.[activeCols?.[0]] || 'ASC', SortMapping[defaultSortOption])
      ?.filter((_, i) => inRange(i, currentRange));
    if (!filtered?.[0]) return;
    
    dispatch(storeEmployee({ employee: filtered[0], preventEmpsUpdate: true, sortBy: defaultSortOption }));
  }, [currentRange, listQuery, radioSelection, filterModel, activeCols, currentSortState]);
  // #endregion

  // #region Functions
  const onColumnSort = (column: keyof Employee) => {
    sortDispatch({ type: 'TOGGLE_SORT', column });
    setActiveCols([column]);
    
    // TODO: This is really ugly. We want the next one but it's not yet available from the reducer so determine it here.
    const currentSort: SortDirection = currentSortState?.[column] ?? 'UNSORTED';
    const nextSort: SortDirection = currentSort === 'ASC' ? 'DESC' : currentSort === 'DESC' ? 'UNSORTED' : 'ASC';
    const sortedEmps = sortRowsByColumn<Employee>(filteredEmployees, [column], currentSortState[activeCols[0]], SortMapping[defaultSortOption]);

    const updatedFilterModel: Tm.FilterModel<Employee> = {
      ...structuredClone(filterModel),
      [column]: {
        ...(filterModel?.[column] ?? {}),
        sortOrder: nextSort,
      },
    };
    
    if (sortedEmps?.[0]) {
      dispatch(storeEmployee({
        employee: sortedEmps[0],
        preventEmpsUpdate: true,
        sortBy: defaultSortOption,
      }));
    }

    dispatch(storeFilterModel({
      filterModel: updatedFilterModel,
      radioSelection: radioSelection,
    }));
  };
  
  const onColumnFilter = (columnQuery: string, column: keyof Employee) => {
    const updatedFilterModel: Tm.FilterModel<Employee> = {
      ...structuredClone(filterModel),
      [column]: {
        ...(filterModel?.[column] ?? {}),
        sortOrder: filterModel?.[column]?.sortOrder ?? 'UNSORTED',
        query: columnQuery,
      },
    };
      
    const newEmps = aggregateFilter(employees, updatedFilterModel, listQuery, radioSelection);

    setFilteredEmployees(newEmps);
    setCurrentPage(1);
    
    dispatch(storeFilterModel({
      filterModel: updatedFilterModel,
      radioSelection: radioSelection,
    }));
  };
  
  const onColumnFilterTypeChange = (filter: Tm.FilterType, column: keyof Employee) => {
    const updatedFilterModel: Tm.FilterModel<Employee> = {
      ...structuredClone(filterModel),
      [column]: {
        ...(filterModel?.[column] ?? {}),
        sortOrder: filterModel?.[column]?.sortOrder ?? 'UNSORTED',
        query: ['Blank', 'Not blank'].includes(filter) ? '' : filterModel?.[column]?.query, // clear query if just checking for existence or absence of value
        filterType: filter,
      },
    };
    
    const newEmps = aggregateFilter(employees, updatedFilterModel, listQuery, radioSelection);

    setFilteredEmployees(newEmps);
    dispatch(storeFilterModel({
      filterModel: updatedFilterModel,
      radioSelection: radioSelection,
    }));
  };
  
  // TODO: hmmm, consolidate these change functions
  const onUpdateCompareDate = (newDate: Date | null, column: keyof Employee) => {
    const updatedFilterModel: Tm.FilterModel<Employee> = {
      ...structuredClone(filterModel),
      [column]: {
        ...(filterModel?.[column] ?? {}),
        sortOrder: filterModel?.[column]?.sortOrder ?? 'UNSORTED',
        query: '', // clear query since we're adding a specific date range
        compareDate: String(newDate),
      },
    };
      
    const newEmps = aggregateFilter(employees, updatedFilterModel, listQuery, radioSelection);

    setFilteredEmployees(newEmps);
    dispatch(storeFilterModel({
      filterModel: updatedFilterModel,
      radioSelection: radioSelection,
    }));
  };
  
  const onUpdateDateRange = (newDate: Date | null, property: keyof Pick<Tm.FilterData, 'dateFrom' | 'dateTo'>, column: keyof Employee) => {
    const updatedFilterModel: Tm.FilterModel<Employee> = {
      ...structuredClone(filterModel),
      [column]: {
        ...(filterModel?.[column] ?? {}),
        sortOrder: filterModel?.[column]?.sortOrder ?? 'UNSORTED',
        query: '', // clear query since we're adding a specific date range
        [property]: String(newDate),
      },
    };
    
    const newEmps = aggregateFilter(employees, updatedFilterModel, listQuery, radioSelection);

    setFilteredEmployees(newEmps);
    dispatch(storeFilterModel({
      filterModel: updatedFilterModel,
      radioSelection: radioSelection,
    }));
  };

  const onRowDoubleClick = (employee: Employee) => {
    dispatch(storeFilteredEmployees(filteredEmployees));
    dispatch(
      storeFilterModel({
        filterModel: filterModel,
        radioSelection: radioSelection,
      }),
    );

    if (location.pathname.includes('employee-master-list')) {
      history.push(`/employee/detail/${employee.protectedEmpNo}/snapshot`);
    } else if (location.pathname.includes('employee-list')) {
      history.push(`/hr-profile/detail/${employee.protectedEmpNo}/general`);
    }
  };

  const onRadioChange = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    switch (e?.target.value) {
      case 'Active': {
        const noTermFilterModel: Tm.FilterModel<Employee> = {
          ...filterModel,
          termDate: {
            ...(filterModel?.termDate || {}),
            sortOrder: filterModel?.termDate?.sortOrder || 'UNSORTED',
            type: 'date',
            filterType: 'Blank',
          },
        };
        
        const newEmps = aggregateFilter(employees, noTermFilterModel, listQuery, 'Active');
        
        setFilteredEmployees(newEmps);
        
        if (newEmps?.[0]) dispatch(loadEmployee(newEmps[0].protectedEmpNo));
        
        dispatch(storeFilterModel({
          filterModel: noTermFilterModel,
          radioSelection: 'Active',
        }));
        
        break;
      }
      case 'Terminated': {
        const termFilterModel: Tm.FilterModel<Employee> = {
          ...filterModel,
          termDate: {
            ...(filterModel?.termDate || {}),
            sortOrder: filterModel?.termDate?.sortOrder || 'UNSORTED',
            type: 'date',
            filterType: 'Not blank',
          },
        };
        
        const newEmps = aggregateFilter(employees, termFilterModel, listQuery, 'Terminated');
        
        setFilteredEmployees(newEmps);
        
        if (newEmps?.[0]) dispatch(loadEmployee(newEmps[0].protectedEmpNo));
        
        dispatch(storeFilterModel({
          filterModel: termFilterModel,
          radioSelection: 'Terminated',
        }));

        break;
      }
      default: {
        const filterModelCopy = structuredClone(filterModel);
        delete filterModelCopy.termDate;
        
        const newEmps = aggregateFilter(employees, filterModelCopy, listQuery, 'All');
        
        setFilteredEmployees(newEmps); 
        
        if (newEmps?.[0]) dispatch(loadEmployee(newEmps[0].protectedEmpNo));
        
        dispatch(storeFilterModel({
          filterModel: filterModelCopy,
          radioSelection: 'All',
        }));
        
        break;
      }
    }
    
    setCurrentPage(1);
  };
  
  const onSearch = (query: string) => {
    if (!query.trim().length) {
      setFilteredEmployees(aggregateFilter(employees, filterModel, query, radioSelection));
      dispatch(updateListQuery(query));
      return;
    }
    
    const newEmps = aggregateFilter(employees, filterModel, query, radioSelection);
    
    dispatch(updateListQuery(query));
    
    setFilteredEmployees(newEmps);
    setCurrentPage(1);
  };
  
  const onChangePages = (newPage: number) => {
    setCurrentPage(newPage);
    
    const firstEmpInList = filteredEmployees.find((_, index) => inRange(index, currentRange));
    if (!firstEmpInList) return console.error('failed to find employee');
    
    dispatch(storeEmployee({
      employee: firstEmpInList,
      preventEmpsUpdate: true,
      sortBy: defaultSortOption,
    }));
    dispatch(storeEmpNos([firstEmpInList.empNo]));
  };

  const onClearFilter = () => {
    const defaultFilterModel: Tm.FilterModel<Employee> = {
      termDate: {
        ...(filterModel?.termDate || {}),
        sortOrder: filterModel?.termDate?.sortOrder || 'UNSORTED',
        filterType: 'Blank',
        type: 'text',
      },
    };
    
    const activeEmployees = employees.filter((e: Employee) => { return !e.termDate; });
    
    dispatch(updateListQuery(''));
    dispatch(storeFilteredEmployees(activeEmployees));
    dispatch(storeEmployee({ employee: activeEmployees[0], sortBy: defaultSortOption }));
    dispatch(
      storeFilterModel({
        filterModel: defaultFilterModel,
        radioSelection: 'Active',
      }),
    );
    
    sortDispatch({ type: 'RESET', column: '' });
    
    setActiveCols(SortMapping[defaultSortOption]);
    setFilteredEmployees(activeEmployees);
    setCurrentPage(1);
  };
  // #endregion

  // #region Render
  return (
    <>
      <div className={styles['action-bar']}>
        {/* "Action" bar */}
        <RadioTabs
          name="master-list-filters"
          options={employeeRadioOptions}
          checked={radioSelection}
          onChange={onRadioChange}
        />
        <SearchBar
          showLabel={false}
          query={listQuery}
          onSearch={onSearch}
          placeholder="Search by name or emp #"
        />
        <button
          type="button"
          className="btn orange-button"
          onClick={onClearFilter}
          style={{
            height: 'fit-content',
          }}
        >
          Clear Filters
        </button>
      </div>
      <div className="d-flex">
        {breakpoints.md ? (
        /* Collapsible employee cards */
          <div className={styles['card-list-container']}>
            {filteredEmployees.map((x) => {
              return (
                <EmployeeRow
                  employee={x}
                  key={x.empNo}
                  updateList={() => {
                    onRowDoubleClick(x);
                  }}  
                />
              );
            })}
          </div>
        ) : (
          /* Custom table */
          <div
            className="table-wrapper-wrapper ag-theme-balham"
            style={{ height: '650px' }}
          >
            <div className={styles['emp-list-container']}>
              <table className={`base-table sortable ${styles['emp-list-table']}`}>
                <thead>
                  {/* Sortable/filterable columns */}
                  <tr>
                    {/* If the employee info is hidden, we just show basic info. Else handle each column individually. */}
                    <SortableHeader<Employee>
                      column="empNo"
                      label="Emp #"
                      sortState={currentSortState?.empNo ?? 'UNSORTED'}
                      onColumnSort={onColumnSort}
                      filterData={filterModel?.empNo ?? null}
                      onColumnFilter={onColumnFilter}
                      filterOptions={filterModelOpts}
                      onColumnFilterTypeChange={onColumnFilterTypeChange}
                      onUpdateDateRange={onUpdateDateRange}
                      onUpdateCompareDate={onUpdateCompareDate}
                    />
                    {!empListSectionAccess.visible ? (
                      <>
                        <SortableHeader<Employee>
                          column="firstName"
                          sortState={currentSortState?.firstName ?? 'UNSORTED'}
                          label="First Name"
                          onColumnSort={onColumnSort}
                          filterData={filterModel?.firstName ?? null}
                          onColumnFilter={onColumnFilter}
                          filterOptions={filterModelOpts}
                          onColumnFilterTypeChange={onColumnFilterTypeChange}
                          onUpdateDateRange={onUpdateDateRange}
                          onUpdateCompareDate={onUpdateCompareDate}
                        />
                        <SortableHeader<Employee>
                          column="midName"
                          sortState={currentSortState?.midName ?? 'UNSORTED'}
                          label="MI"
                          onColumnSort={onColumnSort}
                          filterData={filterModel?.midName ?? null}
                          onColumnFilter={onColumnFilter}
                          filterOptions={filterModelOpts}
                          onColumnFilterTypeChange={onColumnFilterTypeChange}
                          onUpdateDateRange={onUpdateDateRange}
                          onUpdateCompareDate={onUpdateCompareDate}
                        />
                        <SortableHeader<Employee>
                          column="lastName"
                          sortState={currentSortState?.lastName ?? 'UNSORTED'}
                          label="Last Name"
                          onColumnSort={onColumnSort}
                          filterData={filterModel?.lastName ?? null}
                          onColumnFilter={onColumnFilter}
                          filterOptions={filterModelOpts}
                          onColumnFilterTypeChange={onColumnFilterTypeChange}
                          onUpdateDateRange={onUpdateDateRange}
                          onUpdateCompareDate={onUpdateCompareDate}
                        />
                      </>
                    ) : (
                      <>
                        {(allEmpInfoAccess?.[ControlIds.ssn]?.visible ?? true) && (
                          <SortableHeader<Employee>
                            column="ssn"
                            sortState={currentSortState?.ssn ?? 'UNSORTED'}
                            label="SSN"
                            onColumnSort={onColumnSort}
                            filterData={filterModel?.ssn ?? null}
                            onColumnFilter={onColumnFilter}
                            filterOptions={filterModelOpts}
                            onColumnFilterTypeChange={onColumnFilterTypeChange}
                            onUpdateDateRange={onUpdateDateRange}
                            onUpdateCompareDate={onUpdateCompareDate}
                          />
                        )}
                        {(allEmpInfoAccess?.[ControlIds.firstName]?.visible ?? true) && (
                          <SortableHeader<Employee>
                            column="firstName"
                            sortState={currentSortState?.firstName ?? 'UNSORTED'}
                            label="First Name"
                            onColumnSort={onColumnSort}
                            filterData={filterModel?.firstName ?? null}
                            onColumnFilter={onColumnFilter}
                            filterOptions={filterModelOpts}
                            onColumnFilterTypeChange={onColumnFilterTypeChange}
                            onUpdateDateRange={onUpdateDateRange}
                            onUpdateCompareDate={onUpdateCompareDate}
                          />
                        )}
                        {(allEmpInfoAccess?.[ControlIds.midName]?.visible ?? true) && (
                          <SortableHeader<Employee>
                            column="midName"
                            sortState={currentSortState?.midName ?? 'UNSORTED'}
                            label="MI"
                            onColumnSort={onColumnSort}
                            filterData={filterModel?.midName ?? null}
                            onColumnFilter={onColumnFilter}
                            filterOptions={filterModelOpts}
                            onColumnFilterTypeChange={onColumnFilterTypeChange}
                            onUpdateDateRange={onUpdateDateRange}
                            onUpdateCompareDate={onUpdateCompareDate}
                          />
                        )}
                        {(allEmpInfoAccess?.[ControlIds.lastName]?.visible ?? true) && (
                          <SortableHeader<Employee>
                            column="lastName"
                            sortState={currentSortState?.lastName ?? 'UNSORTED'}
                            label="Last Name"
                            onColumnSort={onColumnSort}
                            filterData={filterModel?.lastName ?? null}
                            onColumnFilter={onColumnFilter}
                            filterOptions={filterModelOpts}
                            onColumnFilterTypeChange={onColumnFilterTypeChange}
                            onUpdateDateRange={onUpdateDateRange}
                            onUpdateCompareDate={onUpdateCompareDate}
                          />
                        )}
                        {(allEmpInfoAccess?.[ControlIds.loc]?.visible ?? true) && (
                          <SortableHeader<Employee>
                            column="loc"
                            sortState={currentSortState?.loc ?? 'UNSORTED'}
                            label="Loc"
                            onColumnSort={onColumnSort}
                            filterData={filterModel?.loc ?? null}
                            onColumnFilter={onColumnFilter}
                            filterOptions={filterModelOpts}
                            onColumnFilterTypeChange={onColumnFilterTypeChange}
                            onUpdateDateRange={onUpdateDateRange}
                            onUpdateCompareDate={onUpdateCompareDate}
                          />
                        )}
                        {(allEmpInfoAccess?.[ControlIds.dept]?.visible ?? true) && (
                          <SortableHeader<Employee>
                            column="dept"
                            sortState={currentSortState?.dept ?? 'UNSORTED'}
                            label="Dept"
                            onColumnSort={onColumnSort}
                            filterData={filterModel?.dept ?? null}
                            onColumnFilter={onColumnFilter}
                            filterOptions={filterModelOpts}
                            onColumnFilterTypeChange={onColumnFilterTypeChange}
                            onUpdateDateRange={onUpdateDateRange}
                            onUpdateCompareDate={onUpdateCompareDate}
                          />
                        )}
                        {(allEmpInfoAccess?.[ControlIds.subDept]?.visible ?? true) && (
                          <SortableHeader<Employee>
                            column="subDept"
                            sortState={currentSortState?.subDept ?? 'UNSORTED'}
                            label="Sub Dept"
                            onColumnSort={onColumnSort}
                            filterData={filterModel?.subDept ?? null}
                            onColumnFilter={onColumnFilter}
                            filterOptions={filterModelOpts}
                            onColumnFilterTypeChange={onColumnFilterTypeChange}
                            onUpdateDateRange={onUpdateDateRange}
                            onUpdateCompareDate={onUpdateCompareDate}
                          />
                        )}
                        {(allEmpInfoAccess?.[ControlIds.subDept2]?.visible ?? true) && (
                          <SortableHeader<Employee>
                            column="subDept2"
                            sortState={currentSortState?.subDept2 ?? 'UNSORTED'}
                            label="Sub Dept 2"
                            onColumnSort={onColumnSort}
                            filterData={filterModel?.subDept2 ?? null}
                            onColumnFilter={onColumnFilter}
                            filterOptions={filterModelOpts}
                            onColumnFilterTypeChange={onColumnFilterTypeChange}
                            onUpdateDateRange={onUpdateDateRange}
                            onUpdateCompareDate={onUpdateCompareDate}
                          />
                        )}
                        {(allEmpInfoAccess?.[ControlIds.hireDate]?.visible ?? true) && empDatesAccess.visible && (
                          <SortableHeader<Employee>
                            column="hireDate"
                            sortState={currentSortState?.hireDate ?? 'UNSORTED'}
                            label="Hire Date"
                            onColumnSort={onColumnSort}
                            filterData={filterModel?.hireDate ?? null}
                            onColumnFilter={onColumnFilter}
                            filterOptions={filterModelOpts}
                            onColumnFilterTypeChange={onColumnFilterTypeChange}
                            onUpdateDateRange={onUpdateDateRange}
                            onUpdateCompareDate={onUpdateCompareDate}
                          />
                        )}
                        {(allEmpInfoAccess?.[ControlIds.termDate]?.visible ?? true) && empDatesAccess.visible && (
                          <SortableHeader<Employee>
                            column="termDate"
                            sortState={currentSortState?.termDate ?? 'UNSORTED'}
                            label="Term Date"
                            onColumnSort={onColumnSort}
                            filterData={filterModel?.termDate ?? null}
                            onColumnFilter={onColumnFilter}
                            filterOptions={filterModelOpts}
                            onColumnFilterTypeChange={onColumnFilterTypeChange}
                            onUpdateDateRange={onUpdateDateRange}
                            onUpdateCompareDate={onUpdateCompareDate}
                          />
                        )}
                        {empListSectionAccess.visible && (
                          <>
                            <SortableHeader<Employee>
                              column="isSupervisor"
                              sortState={currentSortState?.isSupervisor ?? 'UNSORTED'}
                              label="Supervisor"
                              onColumnSort={onColumnSort}
                              filterData={filterModel?.isSupervisor ?? null}
                              onColumnFilter={onColumnFilter}
                              filterOptions={filterModelOpts}
                              onColumnFilterTypeChange={onColumnFilterTypeChange}
                              onUpdateDateRange={onUpdateDateRange}
                              onUpdateCompareDate={onUpdateCompareDate}
                            />
                            <SortableHeader<Employee>
                              column="onboardingStatus"
                              sortState={currentSortState?.onboardingStatus ?? 'UNSORTED'}
                              label="Onboarding Status"
                              onColumnSort={onColumnSort}
                              filterData={filterModel?.onboardingStatus ?? null}
                              onColumnFilter={onColumnFilter}
                              filterOptions={filterModelOpts}
                              onColumnFilterTypeChange={onColumnFilterTypeChange}
                              onUpdateDateRange={onUpdateDateRange}
                              onUpdateCompareDate={onUpdateCompareDate}
                            />
                          </>
                        )}
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {/* Rows */}
                  {filteredEmployees
                  // filter out emps not in pagination range
                    .filter((_, index) => inRange(index, currentRange))
                    .map((x, i) => {
                      return (
                        <tr
                          key={i}
                          style={{ cursor: 'pointer' }}
                          onMouseDown={() => {
                            dispatch(loadEmployee(x.protectedEmpNo));
                            dispatch(storeEmpNos([x.empNo]));
                          }}
                          onDoubleClick={() => { onRowDoubleClick(x); }}
                          className={selectedEmp?.empNo === x.empNo ? styles['selected-row'] : ''}
                        >
                          {/* If the employee info is hidden, we just show basic info. Else handle each column individually. */}
                          {!empListSectionAccess.visible ? (
                            <>
                              <td>{x.empNo}</td>
                              <td>{x.firstName}</td>
                              <td>{x.midName}</td>
                              <td>{x.lastName}</td>
                            </>
                          ) : (
                            <>
                              <td>{x.empNo}</td>
                              {(allEmpInfoAccess?.[ControlIds.ssn]?.visible ?? true) && <td>{formatSSN(x.ssn)}</td>}
                              {(allEmpInfoAccess?.[ControlIds.firstName]?.visible ?? true) && <td>{x.firstName}</td>}
                              {(allEmpInfoAccess?.[ControlIds.midName]?.visible ?? true) && <td>{x.midName}</td>}
                              {(allEmpInfoAccess?.[ControlIds.lastName]?.visible ?? true) && <td>{x.lastName}</td>}
                              {(allEmpInfoAccess?.[ControlIds.loc]?.visible ?? true) && (
                                <td>{ddLookup(x.loc, locationOpts, 'locationCode', 'locationDescWithCode')}</td>
                              )}
                              {(allEmpInfoAccess?.[ControlIds.dept]?.visible ?? true) && (
                                <td>{ddLookup(x.dept, deptOpts, 'deptCode', 'departmentDescWithCode')}</td>
                              )}
                              {(allEmpInfoAccess?.[ControlIds.subDept]?.visible ?? true) && (
                                <td>{ddLookup(x.subDept, subdeptOpts, 'subDeptCode', 'subDepartmentDescWithCode')}</td>
                              )}
                              {(allEmpInfoAccess?.[ControlIds.subDept2]?.visible ?? true) && (
                                <td>{ddLookup(x.subDept2, subdept2Opts, 'sub2Code', 'subDepartment2DescWithCode')}</td>
                              )}
                              {(allEmpInfoAccess?.[ControlIds.hireDate]?.visible ?? true) && empDatesAccess.visible && (
                                <td>{convToDateString(x.hireDate)}</td>
                              )}
                              {(allEmpInfoAccess?.[ControlIds.termDate]?.visible ?? true) && empDatesAccess.visible && (
                                <td>{convToDateString(x.termDate)}</td>
                              )}
                              <td>
                                {x.isSupervisor ? (
                                  <Icon
                                    className="m-0 p-0 justify-self-center"
                                    name="check"
                                    color="green"
                                    fontSize="1rem"
                                  />
                                ) : null}
                              </td>
                              <td>{x.onboardingStatus}</td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <Pagination
              loading={false}
              totalCount={filteredEmployees.length}
              currentPage={currentPage}
              pageSize={20}
              currentRange={currentRange}
              numPages={numPages}
              onPageChange={onChangePages}
              inputValue={currentPage}
              onPageInputChange={onChangePages}
              disabled={false}
            />
          </div>
        )
        }
        {/* Employee Detail panel */}
        {breakpoints.md ? null : (
          <Col
            xs={3}
            style={{ minWidth: '250px',
              maxWidth: '350px' }}
          >
            <EmpListDetailEdit />
          </Col>
        )}
      </div>
    </>
  );
  // #endregion
};

export default EmployeeList;
