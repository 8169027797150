import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { CheckboxGrp, CheckboxGrpInLine, SelectGrp } from 'core/components/form-controls';
import { DateTime } from 'luxon';
import { getClientLibrary, getClientFolder, clearClientFolder } from 'core/store/actions/client-library.action';
import { fetchClientFolder, fetchClientLibrary } from 'core/store/selectors/client-library.selector';
import DownloadEmployeeDocsRenderer from 'utilities/ag-grid-renderers/downloadEmployeeDocsRenderer';
import ViewEmployeeDocsRenderer from 'utilities/ag-grid-renderers/ViewEmployeeDocsRenderer';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import FolderList, { IFolder } from 'features/LibraryShared/FolderList';
import { agCheckboxRenderer, agDateTimeRenderer } from 'utilities/ag-grid-renderers';
import DownloadIcon from '@mui/icons-material/Download';
import { FileDTO } from 'core/models/ClientLibrary.model';
import { MultipleFileRequest } from 'core/models/File.model';
import { handleError, postDownloadMultipleFiles } from 'core/store/actions';
import { useAppDispatch } from 'utilities/hooks';
import PasswordModal from 'core/components/modals/Password.modal';
import { getClient, getClientOptionRequirePassword } from 'core/store/selectors';
import { FileService } from 'core/services/file.service';
import LoadingBar from 'core/components/shared/LoadingBar';

interface IDateOption {
  label: string;
  description: string;
}

const dates: IDateOption[] = [];

for (let i = 0; i <= 10; i++) {
  dates.push({
    label: `${DateTime.fromJSDate(new Date()).year - i}`,
    description: `${DateTime.fromJSDate(new Date()).year - i}`,
  });
}

const ReportLibrary = () => {
  const dispatch = useAppDispatch();
  const [selectedYear, setSelectedYear] = useState<number>(DateTime.fromJSDate(new Date()).year);
  const clientDataFromClientLibrary = useSelector(fetchClientLibrary) as any; // TODO: fix these types. Do NOT coerce this to any
  const currentClient = useSelector(getClient);
  const requirePassword = useSelector(getClientOptionRequirePassword);
  const filesFromPath: FileDTO[] = useSelector(fetchClientFolder);
  const folders: IFolder[] = clientDataFromClientLibrary?.folders?.find((item: IFolder) => {
    return item.clientNo === clientDataFromClientLibrary.clientNo;
  })?.folders || [];
  const [activeFolder, setActiveFolder] = useState<string>('');
  const [activeFolderName, setActiveFolderName] = useState<string>('');
  const [activeParent, setActiveParent] = useState<string>('');
  const [showLoadingBar, setShowLoadingBar] = useState<boolean>(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  // This is done to break folders out of the parent current year folder (PI-7824)
  const yearFolder = folders.filter((folder) => { return folder.name === selectedYear.toString(); });
  const restFolders = folders.filter((folder) => { return folder.name !== selectedYear.toString(); });

  const columnDefs: ColDef[] = [
    {
      field: 'select',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50
    },
    {
      field: 'name',
      headerName: 'File Name',
      sortable: true,
      editable: true,
      resizable: true,
      suppressSizeToFit: false,
      suppressAutoSize: false,
    },
    {
      field: 'extension',
      headerName: 'Extension',
      sortable: true,
    },
    {
      field: 'modifyDate',
      headerName: 'Modify Date',
      cellRenderer: 'dateRenderer',
      sortable: true,
    },
    {
      field: 'path',
      headerName: 'Download',
      cellRenderer: DownloadEmployeeDocsRenderer,
    },
    {
      field: 'path',
      headerName: 'View',
      cellRenderer: ViewEmployeeDocsRenderer,
    }
  ];

  useEffect(() => {
    dispatch(getClientLibrary({
      year: selectedYear,
    }));
    dispatch(clearClientFolder());
  }, [selectedYear]);

  const getFilesApi = () => {
    dispatch(getClientFolder({ folderPath: activeFolder }));
  };

  const gridOptions: GridOptions = {
    rowSelection: 'multiple',
    components: {
      dateRenderer: agDateTimeRenderer,
    },
  };

  useEffect(() => {
    if (!activeFolder) return;
    getFilesApi();
    setSelectedRows([]);
  }, [activeFolder]);

  const onClick = (selectedFiles: FileDTO[]) => {
    if(!currentClient || !selectedFiles) return dispatch(handleError('Error While Downloading File(s)'));
    if(requirePassword) return setShowPasswordModal(true);
    setShowLoadingBar(true);

    const paths = selectedFiles.map(x => x.path);
    const request: MultipleFileRequest = {
      clientNo: currentClient?.clientNo,
      usesFilePassword: false,
      filePassword: '',
      folderName: activeFolderName,
      paths: paths
    }
    dispatch(postDownloadMultipleFiles(() => {return FileService.postDownloadMultipleFiles(request);})).then(() => {dispatch(setShowLoadingBar(false));});
  }

  const addPasswordToPdf = (password: string) => {
    if(!currentClient || !gridApi?.getSelectedRows()) return dispatch(handleError('Error While Downloading File(s)'));
    setShowLoadingBar(true);

    const selectedFiles: FileDTO[] = gridApi.getSelectedRows();
    const paths = selectedFiles.map(x => x.path);
    const request: MultipleFileRequest = {
      clientNo: currentClient?.clientNo,
      usesFilePassword: true,
      filePassword: password,
      folderName: activeFolderName,
      paths: paths
    }
    dispatch(postDownloadMultipleFiles(() => {return FileService.postDownloadMultipleFiles(request);})).then(() => {dispatch(setShowLoadingBar(false));});  };

  const onSelectedRowChange = () => {
    if (gridApi) {
      const rowsSelected = gridApi?.getSelectedRows();
      rowsSelected && setSelectedRows(rowsSelected);
    }
  };
  
  return (
    <div className="dm-panel dm-panel-border">
      <p className="dm-grid-title">Report Library</p>
      <div className="col align-items-end d-flex justify-content-end">
        <button
          type="button"
          onClick={() => {onClick(gridApi?.getSelectedRows() || [])}}
          className="btn btn-link dm-grid-action-title p-0 mr-3"
          disabled={selectedRows.length === 0}
        >
          Download Selected Files&nbsp;
          <DownloadIcon />
        </button>
      </div>
      <hr className="dm-panel-hr" />
      <div
        id="layout"
        className="d-flex flex-row justify-content-space-around"
      >
        <div style={{ width: '500px' }}>
          <div
            className="d-flex flex-row flex-grow-1"
            style={{ columnGap: '1rem' }}
          >
            <p className="dm-card-subtitle2 mb-2">Year Available</p>
            <SelectGrp
              options={dates}
              addEmptyText={false}
              onChange={(data: any) => {
                setSelectedYear(data?.target?.value);
              }}
              value={selectedYear}
            />
          </div>
          <div className="d-flex flex-column flex-grow-1" style={{height:500, overflow: 'scroll'}}>
            <FolderList
              folders={[
                {
                  name: selectedYear.toString(),
                  folders: yearFolder?.[0]?.folders,
                  path: '',
                  noMargin: true,
                  parentName: '',
                },
              ]}
              activeFolder={activeFolder}
              setActiveFolder={setActiveFolder}
              setActiveParent={setActiveParent}
              setActiveFolderName={setActiveFolderName}
              defaultActiveKey={selectedYear.toString()}
            />
            {restFolders.map((folder: IFolder) => {
              return (
                <FolderList
                  key={folder.name}
                  folders={[
                    {
                      name: folder.name,
                      folders: folder.folders,
                      path: folder.path,
                      noMargin: true,
                      parentName: '',
                    },
                  ]}
                  activeFolder={activeFolder}
                  setActiveFolder={setActiveFolder}
                  setActiveParent={setActiveParent}
                  setActiveFolderName={setActiveFolderName}
                />
              );
            })}
          </div>
        </div>
        <div
          className="ag-theme-balham"
          style={{
            maxWidth: 1000,
            minHeight: 200,
            maxHeight: 530,
            width: '100%',
          }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            onSelectionChanged={onSelectedRowChange}
            onGridReady={(params: GridReadyEvent) => { setGridApi(params.api); }}
            rowData={filesFromPath || []}
            overlayNoRowsTemplate={
              '<span>No Changes to Display</span>'
            }
          />
        </div>
      </div>
      <PasswordModal
        show={showPasswordModal}
        onHide={() => {setShowPasswordModal(false);}}
        addPasswordToPdf={addPasswordToPdf}
      />
      <LoadingBar
        showLoadingBar={showLoadingBar}
        loadingMessage={'Downloading Files...'}  
      />
    </div>
  );
};

export default ReportLibrary;