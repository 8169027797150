import React from 'react';
import Modal from 'core/components/modals/Modal';
import PdfViewer from 'core/components/shared/PDFViewer/PdfViewer';

type Props = {
  pdf: string;
  onHide: () => void;
};

const NoteReportModal = ({
  pdf,
  onHide,
}: Props) => {
  return (
    <Modal
      centered
      show={!!pdf}
      onHide={onHide}
      title="DMPS Reporter View"
      size="lg"
    >
      {!!pdf && (
        <PdfViewer
          pdfData={pdf}
          reportName="Confirm Check Printing"
        />
      )}
    </Modal>
  );
};

export default NoteReportModal;