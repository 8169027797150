import React, { useEffect, useState } from 'react';
import LibraryAccessModal from './LibraryAccess.modal';
import { getHR360Users } from 'core/store/slices/cmUsers.slice';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';

const panelStyle = { 
  height:200,
  width: 250,
};

const HCMResourcesPage = () => {

  const dispatch = useAppDispatch();

  const hr360HotlineAccess = useAppSelector(
    (state) => { return state.client.clientOptions.options['2506'].optionValue === 'Yes'; },
  );

  const [userAccess, hr360Users] = useAppSelector(
    (state) => { return [state.auth.userAccess, state.cmUsers.hr360Users];},
  );

  const [userHasAccess, setUserHasAccess] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(()=>{
    dispatch(getHR360Users());
  }, []);

  useEffect(()=>{
    if (userAccess && hr360Users) {
      const hasAccess = hr360Users?.find(u => {return u.secUserId === userAccess.secUserId;})?.hasAccess ?? false;
      setUserHasAccess(hasAccess);
    }
  }, [userAccess, hr360Users]);

  const onAssignLibraryAccess = () => {
    setShowModal(true);
  };

  const onOpenHr = ()=>{
    window.open('https://dmpayroll.portal.zywave.com/Landing');
  };

  return (
    <div className="col px-5">
      <div className="row">
        <div className="col-12">
          <div className="dm-page-title">
            Human Resource Library Access
          </div>
        </div>
        <div className="col-6">
          <div className="dm-subtitle2">
            A Robust Library is waiting for you!
          </div>
          <div>
            <button
              className="orange-button mr-2"
              onClick={onOpenHr}
              disabled={!userHasAccess}
            >
              Open HR
            </button>
          </div>
        </div>
        <div
          className="col-6 text-right my-auto pr-4"
        >
          <button
            className="btn btn-link pb-0"
            onClick={onAssignLibraryAccess}
          >
            Assign Library Access <Icon
              name="lock"
              className="fa-plus-circle"
            />
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div
            className="d-flex flex-row justify-content-between mt-3"            
          >
            <div className="d-flex flex-column">
              <div className="dm-subtitle3">
                Human Resources
              </div>
              <div
                className="dm-panel dm-panel-border"
                style={panelStyle}
              >
                <ul style={{ paddingLeft: 15 }}>
                  <li>
                    Discrimination
                  </li>
                  <li>
                    Employee compensation
                  </li>
                  <li>       
                    Employee records and files	
                  </li>  
                  <li>       
                    Workforce planning	
                  </li> 
                  <li>       
                    Labor laws	
                  </li> 
                  <li>       
                    Performance reviews	
                  </li> 
                  <li>       
                    Social security	
                  </li>       
                </ul>
              </div>
            </div>

            <div className="d-flex flex-column">
              <div className="dm-subtitle3">
                Forms & Policies
              </div>
              <div
                className="dm-panel dm-panel-border"
                style={panelStyle}
              >
                <ul style={{ paddingLeft: 15 }}>
                  <li>
                    Cafeteria plans
                  </li>
                  <li>
                    COBRA
                  </li>                 
                  <li>       
                    FMLA
                  </li> 
                  <li>       
                    Health care reform	
                  </li> 
                  <li>       
                    HIPPA	
                  </li> 
                  <li>       
                    Retirement plans	
                  </li>    
                  <li>
                    HSAs, FSAs and more
                  </li>   
                </ul>
              </div>
            </div>

            <div className="d-flex flex-column">
              <div className="dm-subtitle3">
                Employee Benefits
              </div>
              <div
                className="dm-panel dm-panel-border"
                style={panelStyle}
              >
                <ul style={{ paddingLeft: 15 }}>
                  <li>
                    COBRA notices
                  </li>
                  <li>
                    New hire forms
                  </li>
                  <li>       
                    Performance review forms	
                  </li>  
                  <li>       
                    Employee handbooks	
                  </li>                  
                  <li>       
                    Termination forms	
                  </li> 
                  <li>       
                    Workplace safety posters	
                  </li>       
                </ul>
              </div>
            </div>

            <div className="d-flex flex-column">
              <div className="dm-subtitle3">
                Recruitment & Hiring
              </div>
              <div
                className="dm-panel dm-panel-border"
                style={panelStyle}
              >
                <ul style={{ paddingLeft: 15 }}>
                  <li>
                    Job descriptions
                  </li>
                  <li>
                    Background checks
                  </li>
                  <li>       
                    Employment taxes	
                  </li>  
                  <li>       
                    Onboarding	
                  </li> 
                  <li>       
                    Interviewing	
                  </li> 
                  <li>       
                    Hiring process
                  </li> 
                  <li>       
                    Recruitment process	
                  </li>       
                </ul>
              </div>
            </div>

            <div className="d-flex flex-column">
              <div className="dm-subtitle3">
                Termination
              </div>
              <div
                className="dm-panel dm-panel-border"
                style={panelStyle}
              >
                <ul style={{ paddingLeft: 15 }}>
                  <li>
                    Electronic tools and resources
                  </li>
                  <li>
                    Employer responsibilities
                  </li>
                  <li>       
                    Small business programs	
                  </li>  
                  <li>       
                    Recordkeeping	
                  </li> 
                  <li>       
                    Workers’ rights
                  </li> 
                  <li>       
                    Employee wellness
                  </li>     
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div
            className="hcm-hotline-container"
          >
            <div className="hcm-hotline-content">
              {hr360HotlineAccess ? (
                <div>
                  <h1>HR Hotline</h1>
                  <h3>Get expert HR advice now!</h3>
                  <h3>Dial (888) 373-4724 and Press Option 1</h3>
                  <h3>Mention your name, company name and that you're a DM Payroll Solutions client</h3>
                </div>
              ) :
                (
                  <div>
                    <h1>HR Hotline</h1>
                    <h3>Expert HR advice is just a call away!</h3>
                    <h3>Contact Customer Service today at (248) 244-3293 to learn more about the HR Hotline</h3>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div className="row">       
        <div className="col-6">
          <div className="d-flex flex-column">
            <div className="dm-subtitle3">
              <span className="hcm-orange-bullet"></span> Experienced HR Consultant backed by employment attorneys.
              <div>
                <ul style={{ paddingLeft: 45,
                  marginTop: 10 }}
                >
                  <li>
                    90% of inquiries answered same day
                  </li>
                  <li>
                    Guaranteed response within 1 business day
                  </li>                 
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="d-flex flex-column">
            <div className="dm-subtitle3">
              <span className="hcm-orange-bullet"></span>
              Gain Expert Advice Regarding:                    
              <div className="d-flex flex-row flex-grow-1">
                <ul style={{ paddingLeft: 45,
                  marginRight:50,
                  marginTop: 10 }}
                >
                  <li>
                    Hiring and termination
                  </li>
                  <li>
                    Job Descriptions
                  </li>
                  <li>       
                    Wage/hour issues
                  </li>  
                  <li>       
                    Leaves of absence	
                  </li>                     
                </ul>
                <ul style={{ paddingLeft: 45,
                  marginTop: 10  }}
                >
                  <li>
                    Audits and investigation
                  </li>
                  <li>
                    Workers' comp compliance
                  </li>
                  <li>       
                    Harassment and discrimination	
                  </li>  
                  <li>       
                    And much more
                  </li>                     
                </ul>
              </div>  
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="dm-subtitle2">
            {hr360HotlineAccess ? ( <p>Get Expert Advice Now! (888) 373-4724 - Option 1</p>) :
              (<p>Trusted Advisors are just a call away! Learn more at (248) 244-3293</p>)
            }
          </div>
        </div>
      </div>
      {showModal && (
        <LibraryAccessModal
          show={showModal}
          onHide={()=>{return setShowModal(false);}}
        />
      )}
    </div>

  );

};

export default HCMResourcesPage;