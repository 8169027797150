import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpResponse } from '../../models';

import {
  MyInfoChangeLog,
  MyInfoChangeLogHistory,
} from '../../models/MyInfoChangeLog';
import { MyInfoChangesService } from '../../services/my-info-changes.service';
import { handleError } from '../actions';
import {
  loadMyInfoChangeLog,
  loadMyInfoChangeLogHistory,
  storeMyInfoChangeLog,
  storeMyInfoChangeLogHistory,
} from '../actions/my-info-changes.action';

interface Actions {
  type: string;
  payload: any;
}

const loadMyInfoChangeLog$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadMyInfoChangeLog.type),
    switchMap(() => {
      return from(MyInfoChangesService.getMyInfoChanges()).pipe(
        map((res: any) => { return res.data; }),
        map((res: MyInfoChangeLog[]) => { return storeMyInfoChangeLog(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadMyInfoChangeLogHistory$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadMyInfoChangeLogHistory.type),
    switchMap(() => {
      return from(MyInfoChangesService.getMyInfoChangeLogHistory()).pipe(
        map((res: any) => { return res.data; }),
        map((res: MyInfoChangeLogHistory) => { return storeMyInfoChangeLogHistory(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [loadMyInfoChangeLogHistory$, loadMyInfoChangeLog$];
