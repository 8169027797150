import Modal from 'core/components/modals/Modal';
import { WebsiteImage } from 'core/models';
import { handleError } from 'core/store/actions';
import { postWebsiteImage } from 'core/store/slices/applicantTracking.slice';
import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, PercentCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { canvasPreview } from 'utilities/canvasPreview';
import { useAppDispatch } from 'utilities/hooks';
import { useDebounceEffect } from 'utilities/useDebounceEffect';

type PropTypes = {
  show: boolean;
  onHide: () => void;
  currentImage?: WebsiteImage;
  webSiteConfigId: number;
};

const CoverImageModal: React.FC<PropTypes> = ({ show, onHide, currentImage, webSiteConfigId }) => {
  const dispatch = useAppDispatch();
  const [postImage, setPostImage] = useState<string>('');
  const [postImageType, setPostImageType] = useState<string>('');
  const [postImageName, setPostImageName] = useState<string>('');
  const [previewUrl, setPreviewUrl] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<PixelCrop | PercentCrop>();
  const validFileTypes = ['image/png', 'image/bmp', 'image/jpeg'];

  useEffect(() => {
    if (!currentImage?.image) return;
    handleFile(currentImage.image);
  }, []);

  const handleFile = (file: any) => {
    if (file) {
      const type = file.type.toString();
      const fileNameExtension = file.name.toString().split('.');
      const fileName = fileNameExtension[0];
      const fileExtension = fileNameExtension[1];
      const size = file.size;

      //Validates to make sure the file is a valid type and that it is less than 5mb
      if (!validFileTypes.includes(type) || size > 5000000) {
        const errorMessage = (size > 5000000) ? 'Selected Image file cannot be larger than 5mb' : 'Selected Image was not a supported type';
        dispatch(handleError(errorMessage));
        return;
      }

      const reader = new FileReader();
      setCrop(undefined);
      reader.onload = e => {
        const result = e.target?.result?.toString();
        const photoData = result?.substring(result?.indexOf(',') + 1); // was not removing metadata before for JPGS
        if (photoData) {
          setPostImage(photoData);
          setPostImageType(fileExtension);
          setPostImageName(fileName);
        }
      };
      reader.onerror = (error) => {
        dispatch(handleError(`Error reading selected file "${fileName}"`));
        console.error(error);
      };
      reader.readAsDataURL(file);
    }
    setPreviewUrl(URL.createObjectURL(file));
  };

  const handleOnDragOver = (e?: React.DragEvent<HTMLDivElement>) => {
    e?.preventDefault();
  };

  const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const imageFile = e.dataTransfer.files[0];
    handleFile(imageFile);
  };

  const onImageLoad = () => {
    if (!crop) return;
    setCrop(centerCrop(crop as PercentCrop, 1150, 250));
  };

  const onSave = () => {
    const request: WebsiteImage = {
      image: postImage,
      webSiteImageId: 0,
      webSiteConfigId: webSiteConfigId,
      name: postImageName,
      size: postImage.length,
      extension: postImageType,
      visible: true,
    };
    dispatch(postWebsiteImage(request));
  };

  return (
    <Modal
      title="Photo"
      show={show}
      onHide={onHide}
    >
      {(closeModal) => {
        return (
          <>
            <div
              className="drop_zone d-flex flex-column"
              onDragOver={handleOnDragOver}
              onDrop={handleOnDrop}
              onClick={() => { inputRef?.current?.click(); }}
            >
              <div className="text-center">Click or Drag and drop image here....</div>   
              <input
                type="file"
                ref={inputRef}
                accept="image/png, image/bmp, image/jpeg"
                hidden
                onChange={(e: any) => { return handleFile(e?.target?.files?.[0]); }}
              />
              {((previewUrl !== '') || (currentImage?.image !== '')) && (
                <div>
                  {previewUrl || currentImage?.image ? <img
                    src={previewUrl || currentImage?.image}
                    ref={imgRef}
                    alt={'Cover Image'}
                    width="517"
                    height="112"
                    onLoad={onImageLoad}
                  /> : null}
                </div>
              )}
            </div>
            <div className="row">
              <div>
                For best results use a rectangle image with the following dimensions.<br />
                <strong>{'1150px (width) X 250px (height)'}</strong><br />
                Valid File Types:&nbsp;<strong>{'.jpeg, .png, .bmp'}</strong><br />
              </div>
              <div className="col-12 text-right">                
                <div>
                  <button
                    type="button"
                    className="btn btn-primary orange-outline-button mr-2"
                    onClick={closeModal}
                  >Cancel</button>
                  <button
                    type="button"
                    className="btn btn-primary orange-outline-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      onSave();
                      closeModal();
                    }}
                  >Save</button>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </Modal>
  );
};

export default CoverImageModal;