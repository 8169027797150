import { createAction } from '@reduxjs/toolkit';
import {
  BankDisbursementsReportRequest,
  EmployeeAverageHoursWithStatusReportRequest,
  HoursEarningsReportRequest,
  OverTimeSetting,
  EmployeeAverageHoursReportRequest,
  NewJobTrainingCreditReportRequest,
  GeneralLedgerReportRequest,
  GeneralLedgerDetailReportRequest,
  GeneralLedgerExportReportRequest,
  GeneralLedgerReport,
  CARES_SBA_PPP_Request,
  GeneralLedgerExportResult,
} from '../../models/AccountingReports.model';

export const downloadBankDisbursementsReport = createAction<BankDisbursementsReportRequest>(
  '[accounting-report] DOWNLOAD_BANK_DISBURSEMENTS_REPORT',
);
export const storeBankDisbursementsReport = createAction<string>(
  '[accounting-report] STORE_BANK_DISBURSEMENTS_REPORT',
);
export const clearBankDisbursementsReport = createAction(
  '[accounting-report] CLEAR_BANK_DISBURSEMENTS_REPORT',
);


export const downloadEmployeeAverageHoursReport = createAction<EmployeeAverageHoursReportRequest>(
  '[accounting-report] DOWNLOAD_EMPLOYEE_AVERAGE_HOURS_REPORT',
);
export const storeEmployeeAverageHoursReport = createAction<string>(
  '[accounting-report] STORE_EMPLOYEE_AVERAGE_HOURS_REPORT',
);
export const clearEmployeeAverageHoursReport = createAction(
  '[accounting-report] CLEAR_EMPLOYEE_AVERAGE_HOURS_REPORT',
);


export const downloadEmployeeAverageHoursWithStatusReport = createAction<EmployeeAverageHoursWithStatusReportRequest>(
  '[accounting-report] DOWNLOAD_EMPLOYEE_AVERAGE_HOURS_WITH_STATUS_REPORT',
);
export const storeEmployeeAverageHoursWithStatusReport = createAction<string>(
  '[accounting-report] STORE_EMPLOYEE_AVERAGE_HOURS_WITH_STATUS_REPORT',
);
export const clearEmployeeAverageHoursWithStatusReport = createAction(
  '[accounting-report] CLEAR_EMPLOYEE_AVERAGE_HOURS_WITH_STATUS_REPORT',
);


export const downloadHoursAndEarningsReport = createAction<HoursEarningsReportRequest>(
  '[accounting-report] DOWNLOAD_HOURS_AND_EARNINGS_REPORT',
);
export const storeHoursAndEarningsReport = createAction<string>(
  '[accounting-report] STORE_HOURS_AND_EARNINGS_REPORT',
);
export const clearHoursAndEarningsReport = createAction(
  '[accounting-report] CLEAR_HOURS_AND_EARNINGS_REPORT',
);

export const loadOverTimeSettings = createAction(
  '[accounting-report] LOAD_OVERTIME_SETTINGS',
);
export const storeOverTimeSettings = createAction<OverTimeSetting[]>(
  '[accounting-report] STORE_OVERTIME_SETTINGS',
);
export const createOverTimeSettings = createAction<OverTimeSetting>(
  '[accounting-report] CREATE_OVERTIME_SETTINGS',
);
export const updateOverTimeSettings = createAction<OverTimeSetting>(
  '[accounting-report] UPDATE_OVERTIME_SETTINGS',
);
export const deleteOverTimeSettings = createAction<OverTimeSetting>(
  '[accounting-report] DELETE_OVERTIME_SETTINGS',
);

export const downloadNewJobTrainingCreditReport = createAction<NewJobTrainingCreditReportRequest>(
  '[accounting-report] DOWNLOAD_NEW_JOB_TRAINING_CREDIT_REPORT',
);
export const storeNewJobTrainingCreditReport = createAction<string>(
  '[accounting-report] STORE_NEW_JOB_TRAINING_CREDIT_REPORT',
);
export const clearNewJobTrainingCreditReport = createAction(
  '[accounting-report] CLEAR_NEW_JOB_TRAINING_CREDIT_REPORT',
);

export const downloadGeneralLedgerReport = createAction<GeneralLedgerReportRequest>(
  '[accounting-report] DOWNLOAD_GENERAL_LEDGER_REPORT',
);
export const storeGeneralLedgerReport = createAction<GeneralLedgerReport>(
  '[accounting-report] STORE_GENERAL_LEDGER_REPORT',
);
export const clearGeneralLedgerReport = createAction(
  '[accounting-report] CLEAR_GENERAL_LEDGER_REPORT',
);

export const downloadGeneralLedgerReportErrors = createAction<GeneralLedgerReportRequest>(
  '[accounting-report] DOWNLOAD_GENERAL_LEDGER_REPORT_ERRORS',
);
export const storeGeneralLedgerReportErrors = createAction<string>(
  '[accounting-report] STORE_GENERAL_LEDGER_REPORT_ERRORS',
);
export const clearGeneralLedgerReportErrors = createAction(
  '[accounting-report] CLEAR_GENERAL_LEDGER_REPORT_ERRORS',
);

export const downloadGeneralLedgerDetailReport = createAction<GeneralLedgerDetailReportRequest>(
  '[accounting-report] DOWNLOAD_GENERAL_DETAIL_LEDGER_REPORT',
);
export const storeGeneralLedgerDetailReport = createAction<string>(
  '[accounting-report] STORE_GENERAL_DETAIL_LEDGER_REPORT',
);
export const clearGeneralLedgerDetailReport = createAction(
  '[accounting-report] CLEAR_GENERAL_DETAIL_LEDGER_REPORT',
);

export const downloadGeneralLedgerExportReport = createAction<GeneralLedgerExportReportRequest>(
  '[accounting-report] DOWNLOAD_GENERAL_EXPORT_LEDGER_REPORT',
);
export const storeGeneralLedgerExportReport = createAction<GeneralLedgerExportResult[]>(
  '[accounting-report] STORE_GENERAL_EXPORT_LEDGER_REPORT',
);
export const clearGeneralLedgerExportReport = createAction(
  '[accounting-report] CLEAR_GENERAL_EXPORT_LEDGER_REPORT',
);


export const download_CARES_SBA_PPP_Report = createAction<CARES_SBA_PPP_Request>(
  '[accounting-report] DOWNLOAD_CARES_SBA_PPP_REPORT',
);
export const store_CARES_SBA_PPP_Report = createAction<string>(
  '[accounting-report] STORE_CARES_SBA_PPP_REPORT',
);
export const clear_CARES_SBA_PPP_Report = createAction(
  '[accounting-report] CLEAR_CARES_SBA_PPP_REPORT',
);