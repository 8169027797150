import React, { createContext, useEffect, useMemo, useState } from 'react';
import Icon from 'core/components/shared/Icon';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { dataURIToBlob, toReadableDate } from 'utilities/utilities';
import NoteList from './notes/NoteList';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import {
  getApplicant,
  postApplicantNoteReport,
  postApplicantNotes,
  postApplicantResume,
  updateApplicantStatus,
  updateApplicantEmail,
} from 'core/store/actions/applicant-tracking.action';
import { Applicant as ApplicantCore, ApplicantStatus, Note } from 'core/models';
import AddNoteModal from './notes/AddNote.modal';
import NoteReportModal from './notes/NoteReport.modal';
import HistoryList from './history/HistoryList';
import Select from 'react-select';
import { compStyles } from 'utilities/styles';
import PersonalInformation from './details/PersonalInformation';
import CustomFields from './details/CustomFields';
import EmploymentHistory from './details/EmploymentHistory';
import EducationHistory from './details/EducationHistory';
import TrainingHistory from './details/TrainingHistory';
import Overview from './status/Overview';
import { statusOptions } from './status-options';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';

export type ApplicantContextType = {
  isDirty: boolean;
  tab: string;
  setTab: (tab: string) => void;
  setIsDirty: (newVal: boolean) => void;
};

export const ApplicantContext = createContext<ApplicantContextType>({
  isDirty: false,
  tab: '',
  setTab: (_tab: string) => { },
  setIsDirty: (_newVal: boolean) => { },
});

const Applicant = () => {
  const [isAddingNote, setIsAddingNote] = useState<boolean>(false);
  
  const params: any = useParams();
  
  const appDispatch = useAppDispatch();
  
  const { active: activeApplicant, isNoteReportLoading } = useAppSelector(({ applicantTrackingV2 }) => applicantTrackingV2);
  const [loading, applicantsStore, jobs] = useAppSelector(({ applicantTrackingV2, applicantTracking }) => {
    return [applicantTrackingV2?.loading,
      applicantTrackingV2?.applicants,
      applicantTracking?.jobs?.availableJobs];
  });
  
  const [noteReport, setNoteReport] = useState('');
  const [applicantResume, setApplicantResume] = useState(null);
  const [viewResume, setViewResume] = useState(false);
  const [status, setStatus] = useState(0);
  const [applicantsOriginal, setApplicantsOriginal] = useState<ApplicantCore[]>([]);
  const [applicants, setApplicants] = useState<ApplicantCore[]>([]);
  const [selectedTab, setSelectedTab] = useState('status');
  const [displayEmailSave, setDisplayEmailSave] = useState(false);
  const [changedEmail, setChangedEmail] = useState('');
  
  useEffect(() => {
    if (loading) return;
    const filteredApplicants = cloneDeep(
      applicantsStore.filter(a => {
        return a.status !== ApplicantStatus.Employee;
      }));
      
    if (!filteredApplicants) return; // do we want something else here? Will need to check later
    setApplicants(filteredApplicants);
    setApplicantsOriginal(filteredApplicants);
  }, [applicantsStore, loading]);

  const onHide = () => {
    setIsAddingNote(false);
  };

  const onPrint = () => {
    if (!activeApplicant?.applicantId) {
      return;
    }

    appDispatch(postApplicantNoteReport(activeApplicant?.applicantId)).then(
      (response: any) => {
        setNoteReport(response?.payload?.value);
      },
    );
  };

  const [isPdf, setIsPdf] = useState(false);
  
  const setTab = (tab: string) => {
    setSelectedTab(tab);
  };
  
  const setIsDirty = (newVal: boolean) => {
    setFormDirtyFields(newVal);
  };

  const onResumeView = () => {
    if (!activeApplicant?.applicantId) {
      return;
    }
    setViewResume(true);
  };

  useEffect(() => {
    appDispatch(getApplicant(parseInt(params?.id, 10)));
  }, [params?.id]);

  useEffect(() => {
    setStatus(activeApplicant?.status || 0);
    if (activeApplicant?.applicantId) {
      appDispatch(postApplicantResume(activeApplicant?.applicantId)).then(
        (response: any) => {
          if (response?.payload?.value?.extension?.includes('pdf')) {
            setIsPdf(true);
          } else {
            setIsPdf(false);
          }
          setApplicantResume(response?.payload?.value);
        },
      );
    }
  }, [activeApplicant]);

  const employmentHistoryCustomFields = useMemo(() => {
    let fields: any[] = [];

    activeApplicant?.employmentHistory?.forEach((history) => {
      fields = [
        ...fields,
        {
          eventKey: history?.nameOfEmployer,
          items: history?.customEmploymentFields || [],
        },
      ];
    });

    return fields;
  }, [activeApplicant?.employmentHistory]);

  const educationHistoryCustomFields = useMemo(() => {
    let fields: any[] = [];

    activeApplicant?.educationHistory?.forEach((history) => {
      fields = [
        ...fields,
        {
          eventKey: history?.schoolName,
          items: history?.customEducationFields || [],
        },
      ];
    });

    return fields;
  }, [activeApplicant?.educationHistory]);

  const trainingHistoryCustomFields = useMemo(() => {
    let fields: any[] = [];

    activeApplicant?.trainingHistory?.forEach((history) => {
      fields = [
        ...fields,
        {
          eventKey: history?.nameOfInstitution,
          items: history?.customTrainingFields || [],
        },
      ];
    });

    return fields;
  }, [activeApplicant?.trainingHistory]);

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    if (!changedEmail?.length) {
      return undefined;
    }

    const confirmationMessage =
      'It looks like you have been editing something. ' +
      'If you leave before saving, your changes will be lost.';

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  };
  useEffect(() => {
    if (!changedEmail?.length) {
      setDisplayEmailSave(false);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    } else if (changedEmail === activeApplicant?.emailAddress) {
      setDisplayEmailSave(false);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.addEventListener('beforeunload', handleBeforeUnload);
      setDisplayEmailSave(true);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [changedEmail]);

  const [formDirtyFields, setFormDirtyFields] = useState(false);
  
  const applicantContextValue: ApplicantContextType = {
    tab: selectedTab,
    isDirty: formDirtyFields,
    setTab,
    setIsDirty,
  };
  
  return (
    <ApplicantContext.Provider value={applicantContextValue}>
      <div className="dm-panel dm-panel-border">
        <div className="dm-grid-title">
          Applicant &gt; {activeApplicant?.firstName} {activeApplicant?.lastName}
        </div>
        <hr className="dm-panel-hr" />
        <div
          className="d-flex"
          style={{ gap: '2rem' }}
        >
          <div
            className="d-flex align-items-center"
            style={{ gap: '0.5rem' }}
          >
            <div
              className="d-flex align-items-center"
              style={{ gap: '0.25rem' }}
            >
              <Icon
                name="at"
                fontSize={12}
              />
              <span>Email</span>
            </div>
            <input
              autoFocus
              type="email"
              className="form-control"
              name="email"
              style={{ width: '200px' }}
              defaultValue={activeApplicant?.emailAddress || ''}
              onChange={(e) => {
                setChangedEmail(e.target.value);
              }}
            />
            {displayEmailSave && (
              <button
                type="button"
                onClick={async () => {
                  if (changedEmail?.length) {
                    setDisplayEmailSave(false);
                    setChangedEmail('');
                    await appDispatch(
                      updateApplicantEmail({
                        applicantId: activeApplicant?.applicantId,
                        params: {
                          email: changedEmail,
                          applicantId: activeApplicant?.applicantId,
                        },
                      }),
                    );
                    toast.success('Email updated successfully', {
                      autoClose: 3000,
                    });
                  }
                }}
                className="btn btn-primary orange-button ml-2 px-1"
              >
                Save
              </button>
            )}
          </div>
          <div
            className="d-flex align-items-center"
            style={{ gap: '0.5rem' }}
          >
            <div
              className="d-flex align-items-center"
              style={{ gap: '0.25rem' }}
            >
              <Icon
                name="phone"
                fontSize={12}
              />
              <span>Phone</span>
            </div>
            <a href={`tel:+${activeApplicant?.cellPhone}`}>
              {activeApplicant?.cellPhone}
            </a>
          </div>
          {!!(applicantResume as any)?.data && (
            <div
              className="d-flex align-items-center"
              style={{ gap: '0.5rem' }}
            >
              <button
                type="button"
                className="d-flex align-items-center p-0"
                disabled={selectedTab === 'notes' || selectedTab === 'history'}
                style={{
                  gap: '0.25rem',
                  background: 'none',
                  outline: 'none',
                  border: 'none',
                  cursor:
                  selectedTab === 'notes' || selectedTab === 'history'
                    ? 'not-allowed'
                    : 'pointer',
                  opacity:
                  selectedTab === 'notes' || selectedTab === 'history'
                    ? 0.5
                    : 1,
                }}
                onClick={onResumeView}
              >
                <Icon
                  name="eye"
                  fontSize={12}
                  className="p-0"
                />
                <span style={{ color: '#00558c' }}>View Resume</span>
              </button>
            </div>
          )}
          <div
            className="flex-grow-1 justify-content-end text-right"
          >
            <span
              style={{
                fontSize: '14px',
                padding: 10,
                fontWeight: 600,
                color: '#00558c',
                textDecorationLine: 'underline',
                backgroundColor: '#eff4fb',
              }}
            >Total Applicants {applicantsStore.filter(a => {
              return a.status !== ApplicantStatus.Employee;
            }).length}</span>
          </div>
        </div>
        <div
          className="d-flex align-items-center justify-content-between mt-2 p-2"
          style={{
            gap: '2rem',
            background: '#fbfbfd',
          }}
        >
          {!!activeApplicant?.dateApplied && (
            <div
              className="d-flex align-items-center"
              style={{
                gap: '2rem',
                color: '#848484',
              }}
            >
              <p className="font-weight-bold mb-0">
                Applied:{' '}
                <span className="font-weight-normal">
                  {toReadableDate(activeApplicant?.dateApplied || '')}
                </span>
              </p>
              <p className="font-weight-bold mb-0">
                Date Available:{' '}
                <span className="font-weight-normal">
                  {toReadableDate(activeApplicant?.dateAvailable || '')}
                </span>
              </p>
              <p className="font-weight-bold mb-0">
                Position:{' '}
                <span className="font-weight-normal">
                  {activeApplicant?.jobTitle || ''}
                </span>
              </p>
              <div className="ml-4 d-flex align-items-center">
                <p className="font-weight-bold mb-0 mr-2">Status:</p>
                <div style={{ width: 150 }}>
                  <Select
                    hideSelectedOptions={false}
                    isClearable={false}
                    styles={compStyles}
                    isDisabled={
                      selectedTab === 'notes' || selectedTab === 'history'
                    }
                    onChange={(selected) => {
                      setStatus(selected.value);
                      appDispatch(
                        updateApplicantStatus({
                          applicantId: activeApplicant.applicantId,
                          status: selected.value,
                        }),
                      );
                    }}
                    options={statusOptions}
                    value={statusOptions.find((item) => {
                      return item.value === status;
                    })}
                  />
                </div>
              </div>
            </div>
          )}
          {selectedTab !== 'status' && (
            <button
              type="button"
              className="d-flex align-items-center"
              style={{
                gap: '0.3rem',
                background: 'transparent',
                border: 'none',
                color: '#00558c',
              }}
              onClick={onPrint}
              disabled={isNoteReportLoading || selectedTab === 'history'}
            >
              <Icon
                name="print"
                className="p-0"
              />
              <span className="underline">Print Report</span>
            </button>
          )}
        </div>
        <div style={{ background: '#edf3f9' }}>
          <Tabs
            defaultActiveKey="status"
            className="px-3"
            activeKey={selectedTab}
            onSelect={(tab) => {
              if (formDirtyFields) {
                const response = window.confirm(
                  'It looks like you have been editing something. If you leave before saving, your changes will be lost.',
                );
                if (response) {
                  setSelectedTab(tab || 'status');
                }
                return null;
              }
              setSelectedTab(tab || 'status');
            }}
          >
            <Tab
              eventKey="status"
              title="Status"
            >
              <Overview status={activeApplicant?.status} />
            </Tab>
            <Tab
              eventKey="details"
              title="Details"
            >
              <div className="px-3 pb-3">
                <div
                  className="p-3"
                  style={{
                    background: 'white',
                  }}
                >
                  <PersonalInformation
                    firstName={activeApplicant?.firstName}
                    lastName={activeApplicant?.lastName}
                    middleName={activeApplicant?.middleName}
                    maidenName={activeApplicant?.maidenName}
                    emailAddress={activeApplicant?.emailAddress}
                    driverLicenseNumber={activeApplicant?.driverLicenseNumber}
                    cellPhone={activeApplicant?.cellPhone}
                    homePhone={activeApplicant?.homePhone}
                    otherPhone={activeApplicant?.otherPhone}
                    streetAddress1={activeApplicant?.streetAddress1}
                    streetAddress2={activeApplicant?.streetAddress2}
                    state={activeApplicant?.state}
                    city={activeApplicant?.city}
                    zip={activeApplicant?.zip}
                    eeo={activeApplicant?.eeo}
                    gender={activeApplicant?.gender}
                  />
                  <CustomFields
                    hasTabs={false}
                    label="Custom Personal Fields"
                    eventKey="1"
                    fields={activeApplicant?.customPersonalFields || []}
                  />
                  <EmploymentHistory
                    histories={activeApplicant?.employmentHistory || []}
                  />
                  <CustomFields
                    label="Custom Employment Fields"
                    eventKey="3"
                    hideTabs={true}
                    fields={employmentHistoryCustomFields || []}
                  />
                  <EducationHistory
                    histories={activeApplicant?.educationHistory || []}
                  />
                  <CustomFields
                    label="Custom Education Fields"
                    eventKey="5"
                    hideTabs={true}
                    fields={educationHistoryCustomFields || []}
                  />
                  <TrainingHistory
                    histories={activeApplicant?.trainingHistory || []}
                  />
                  <CustomFields
                    label="Custom Training Fields"
                    eventKey="7"
                    hideTabs={true}
                    fields={trainingHistoryCustomFields || []}
                  />
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="notes"
              title="Notes"
            >
              <div className="px-3 pb-3">
                <div
                  className="pt-3"
                  style={{
                    background: 'white',
                  }}
                >
                  <div className="d-flex align-items-center justify-content-end px-3">
                    <button
                      style={{
                        background: 'transparent',
                        border: 'none',
                        gap: '0.35rem',
                        color: 'rgb(100, 101, 106)',
                      }}
                      className="font-weight-bold d-flex align-items-center"
                      type="button"
                      onClick={() => {
                        setIsAddingNote(true);
                      }}
                    >
                      New Note
                      <Icon name="plus-circle" />
                    </button>
                  </div>
                  <AddNoteModal
                    show={isAddingNote}
                    onHide={onHide}
                    notes={activeApplicant?.notes || []}
                    applicantId={activeApplicant?.applicantId || 0}
                  />
                  <NoteList
                    notes={activeApplicant?.notes || []}
                    onDelete={(note: Note) => {
                      if (!activeApplicant?.applicantId) {
                        return;
                      }

                      appDispatch(
                        postApplicantNotes({
                          applicantId: activeApplicant?.applicantId,
                          notes: activeApplicant?.notes?.filter((item) => {
                            return item.noteId !== note.noteId;
                          }),
                        }),
                      );
                    }}
                  />
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="history"
              title="History"
            >
              <HistoryList
                setSelectedTab={setSelectedTab}
                histories={activeApplicant?.applicationHistory || []}
              />
            </Tab>
          </Tabs>
        </div>
        <NoteReportModal
          pdf={noteReport}
          onHide={() => {
            setNoteReport('');
          }}
        />
        {viewResume && (
          <PDFViewerModal
            pdfData={(applicantResume as any)?.data || ''}
            onHide={() => {
              setViewResume(false);
            }}
            show
            reportName="Resume"
            fileType={(applicantResume as any)?.extension || '.pdf'}
          />
        )}
      </div>
    </ApplicantContext.Provider>
  );
};

export default Applicant;
