import React, { useState, useMemo, useEffect } from 'react';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  RowDoubleClickedEvent,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { useForm } from 'react-hook-form';
import { InputGrp } from 'core/components/form-controls';
import { Employee } from 'core/models/Employee.model';
import { gridOptionsDefault } from 'utilities/ag-grid-options';
import { useAppSelector } from 'utilities/hooks';

type Form = {
  searchText: string;
};

type Props = {
  onSearchDoubleClick: (empNo: number, addCheck: boolean) => void;
};

const EmployeeSearch = ({ onSearchDoubleClick }: Props) => {
  const employees = useAppSelector((state) => {return state.employees.employees;});

  const [rowData, setRowData] = useState<Employee[]>([]);
  const [gridApi, setGridApi] = useState<GridApi>();

  const { register, handleSubmit } = useForm<Form>();

  const columnDefs = useMemo<ColDef[]>(
    () => {
      return [
        {
          field: 'empNo',
          headerName: 'Employee Number',
          filter: true,
          cellRenderer: 'lookupRenderer',
          suppressMenu: false,
        },
        {
          field: 'ssn',
          headerName: 'SSN',
          filter: true,
          cellRenderer: 'lookupRenderer',
          suppressMenu: false,
        },
        {
          field: 'firstName',
          filter: true,
          cellRenderer: 'lookupRenderer',
          suppressMenu: false,
        },
        {
          field: 'lastName',
          filter: true,
          cellRenderer: 'lookupRenderer',
          suppressMenu: false,
        },
        {
          field: 'midName',
          filter: true,
          cellRenderer: 'lookupRenderer',
          suppressMenu: false,
        },
      ];
    },
    [],
  );

  const gridOptions = {
    ...gridOptionsDefault,
    cacheQuickFilter: true,
    columnDefs,
  };

  useEffect(() => {
    if (employees && employees.length > 0) {
      setRowData(employees);
    }
  }, [employees]);

  const onGridReady = (e: GridReadyEvent) => {
    setGridApi(e.api);
  };

  const onSearch = (data: Form) => {
    if (gridApi) {
      gridApi.setQuickFilter(data.searchText);
    }
  };

  const onRowDoubleClicked = (event: RowDoubleClickedEvent) => {
    onSearchDoubleClick((event.data as Employee).empNo, true);
  };

  const onGridSizeChanged = (params: any) => {
    params.api.sizeColumnsToFit();
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSearch)}
        className="mb-3"
      >
        <InputGrp
          label="Search"
          name="searchText"
          ref={register}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            return onSearch({
              searchText: (e.target as HTMLInputElement).value,
            });
          }
          }
        />
      </form>
      <div
        className="ag-theme-balham"
        style={{ height: '70vh' }}
      >
        <AgGridReact
          onGridReady={onGridReady}
          rowData={rowData}
          gridOptions={gridOptions}
          onRowDoubleClicked={onRowDoubleClicked}
          onGridSizeChanged={onGridSizeChanged}
        />
      </div>
    </div>
  );
};

export default EmployeeSearch;
