import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Client } from '../../core/models';
import { useAppSelector } from '../../utilities/hooks';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import { CheckboxGrpInLine } from '../../core/components/form-controls';
import { MyInfoDocStatusFile, MyInfoDocStatusReportRequest } from '../../core/models/HumanCapitalReports.model';
import { useForm } from 'react-hook-form';
import { CommonService } from '../../core/services';
import {
  clearMyInfoDocStatusReport,
  downloadMyInfoDocStatusReport,
  loadMyInfoDocStatusFiles,
} from '../../core/store/actions';
import { ColDef, GridApi, GridOptions, RowSelectedEvent } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from '@ag-grid-community/react';
import Icon from 'core/components/shared/Icon';

const MyInfoDocStatusReport = () => {

  const dispatch = useDispatch();

  const childRef: React.RefObject<any> = useRef();
  const report = useAppSelector((state) => { return state.humanCapitalReports.myInfoDocStatusReport; });
  const client = useAppSelector((state) => { return state.client.client; }) as Client;
  const infoDocStatusFiles = useAppSelector((state) => { return state.humanCapitalReports.myInfoDocStatusFiles; });
  const [fileType, setFileType] = useState<string>('pdf');
  const [selectedFiles, setSelectedFiles] = useState<MyInfoDocStatusFile[]>();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const reportName = 'MyInfoDocStatusReport';

  const { register, handleSubmit } =
    useForm<MyInfoDocStatusReportRequest>({
      defaultValues: {
        activeEmployees: false,
      },
    });

  const columns: ColDef[] = [
    {
      field: 'select',
      headerName: '',
      editable: false,
      checkboxSelection: true,
      width: 40,
    },
    {
      field: 'clientFileID',
      hide: true,
    },
    {
      field: 'clientNo',
      headerName: 'Client No',
      width: 120,
    },
    {
      field: 'fileName',
      headerName: 'Description',
      width: 180,
    },
  ];

  const gridOptions: GridOptions = {
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    columnDefs: columns,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  useEffect(() => {
    dispatch(loadMyInfoDocStatusFiles());
  }, []);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const downloadReport = (data: MyInfoDocStatusReportRequest) => {
    const submitData: MyInfoDocStatusReportRequest = {
      clientNo: client.clientNo,
      clientFileID: selectedFiles?.map((f: MyInfoDocStatusFile) => { return f.clientFileID; }) ?? [],
      activeEmployees: data.activeEmployees,
      reportType: fileType,
    };
    dispatch(downloadMyInfoDocStatusReport(submitData));
  };

  useEffect(() => {
    if (report && fileType === 'excel') {
      report && CommonService.downloadBase64File(
        'application/excel',
        report,
        `${reportName}.xlsx`,
      );
      dispatch(clearMyInfoDocStatusReport());
    }
  }, [report]);

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      selectedRows && setSelectedFiles(selectedRows);
    }
  };

  const onRowClicked = (e: RowSelectedEvent) => {
    e && e.node.setSelected(e.node.isSelected() === true ? true : false);
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearMyInfoDocStatusReport());
  };

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">MyInfo Document Status Report</div>

          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title "
              onClick={() => { return setFileType('excel'); }}
            >
              Download Report  <Icon name="download"
                className="fa-download" />
            </button>
          </div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">

            <div className="d-flex flex-row flex-grow-1">

              <div className="d-flex flex-grow-1">
                <div className="d-flex flex-column mr-5">
                  <div className="dm-grid-action-title mb-2">Report Type</div>
                  <CheckboxGrpInLine
                    name="activeEmployees"
                    ref={register}
                    labelFirst={false}
                    label="Active Employees Only"
                  />

                  <div className="ag-theme-balham mt-2"
                    style={{
                      height: '220px',
                      width: '400px'
                    }}>
                    <AgGridReact
                      gridOptions={gridOptions}
                      rowData={infoDocStatusFiles}
                      onGridReady={onGridReady}
                      onRowClicked={onRowClicked}
                      onSelectionChanged={onSelectionChanged}
                    />
                  </div>
                </div>
              </div>
              <div className=" text-right">
                <button
                  type="button"
                  className="orange-outline-button mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="orange-button mr-2"
                  onClick={() => { return setFileType('pdf'); }}
                  disabled={!selectedFiles?.length}
                >
                  Run Report
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      {report ? <PDFViewerModal
        show={report.length > 0}
        pdfData={report}
        onHide={hidePdfViewer}
        reportName="MyInfoDocStatusReport"
      /> : null}
    </div>
  );



};
export default MyInfoDocStatusReport;