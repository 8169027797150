import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../providers/authProvider';
import { useAppSelector } from 'utilities/hooks';
import { AccessGroup, GroupItem, EmployeeMasterSection } from 'core/models/ModuleAccess.model';
import ForbiddenRoute from 'Forbidden';


type PropTypes = {
  path: string;
  exact: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
  groupId?: number | undefined;
  itemId?: number | undefined;
  workItemId?: number | undefined;
};

const AuthRoute: React.FC<PropTypes> = ({ component, groupId, itemId, workItemId, path, ...rest }) => {
  const auth = useAuth();
  
  let groupData: AccessGroup | undefined,
    itemData: GroupItem | undefined,
    sectionData: EmployeeMasterSection | undefined;
  
  const [groupAccess, sectionAccess] = useAppSelector(({ app }) => [app.moduleAccess?.groups, app.moduleAccess?.employeeMasterSections]);
  
  // only set this if we actually want to block the entire route. Else we handle it on the component level, not the router
  /* 
    1 = Employee Info,
 */
  if (workItemId && ![1].includes(workItemId)) {
    sectionData = sectionAccess?.find((section) => { return section.workItemId === workItemId; });
  }
  if (groupId) {
    groupData = groupAccess?.find((group) => { return group.groupId === groupId; });
    if (itemId && groupData?.visible) {
      itemData = groupData?.items.find((item) => { return item.itemId === itemId; });
    }
  }
  if (!auth.isAuthenticated()) {
    return <Redirect to={{ pathname: '/login' }} />;
  }
  if ((groupData && !groupData?.visible) || (itemData && !itemData?.visible) || (sectionData && !sectionData?.visible)) {
    return <Route component={ForbiddenRoute} />;
  } 
  
  return <Route
    {...rest}
    component={component}
  />;
};

export default AuthRoute;
