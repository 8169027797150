import { AxiosResponse } from 'axios';
import { HttpResponse, EmpSelfService, EmpMyInfoChanges, ChangeMyInfoAccountEmail } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';
import { MyInfoChangeLog } from 'core/models/MyInfoChangeLog';

const getSelfService = (empNo: string): Promise<AxiosResponse<EmpSelfService>> => { return API_EMPLOYEE_MASTER.get<EmpSelfService>(EmployeeEndPoints.EMPLOYEES_SELF_SERVICE(CommonService.getProtectedClientNo(), empNo)); };

const putSelfService = (data: EmpSelfService): Promise<AxiosResponse<HttpResponse<EmpSelfService>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<EmpSelfService>>(EmployeeEndPoints.EMPLOYEES_SELF_SERVICE(CommonService.getProtectedClientNo(), data.protectedEmpNo), data); };

const postMyInfoUnlock = (empNo: string): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(EmployeeEndPoints.EMPLOYEES_MYINFOACCOUNT_UNLOCK(CommonService.getProtectedClientNo(), empNo)); };

const postMyInfoResetPW = (empNo: string): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(EmployeeEndPoints.EMPLOYEES_MYINFOACCOUNT_RESETPW(CommonService.getProtectedClientNo(), empNo)); };

const postMyInfoChangeEmail = (data: ChangeMyInfoAccountEmail): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(EmployeeEndPoints.EMPLOYEES_MYINFOACCOUNT_CHANGEEMAIL(CommonService.getProtectedClientNo(), data.protectedEmpNo), { oldEmail: data.oldEmail, newEmail: data.newEmail }); };

const postMyInfoDelete = (empNo: string): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.delete<HttpResponse<any>>(EmployeeEndPoints.EMPLOYEES_MYINFOACCOUNT_DELETE(CommonService.getProtectedClientNo(), empNo)); };

const getMyInfoChanges = (empNo: string): Promise<AxiosResponse<MyInfoChangeLog>> => { return API_EMPLOYEE_MASTER.get<MyInfoChangeLog>(EmployeeEndPoints.EMPLOYEES_MYINFOCHANGES(CommonService.getProtectedClientNo(), empNo)); };

const getMyInfoChangesHistory = (empNo: string): Promise<AxiosResponse<EmpMyInfoChanges>> => { return API_EMPLOYEE_MASTER.get<EmpMyInfoChanges>(EmployeeEndPoints.EMPLOYEES_MYINFOCHANGES_HISTORY(CommonService.getProtectedClientNo(), empNo)); };

const updateMyInfochanges = (data: any): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<any>>(EmployeeEndPoints.EMPLOYEES_MYINFOCHANGES_UPDATE(CommonService.getProtectedClientNo(), data.protectedEmpNo), data); };

export const EmployeeSelfService = {
  getSelfService,
  putSelfService,
  postMyInfoUnlock,
  postMyInfoResetPW,
  postMyInfoChangeEmail,
  postMyInfoDelete,
  getMyInfoChanges,
  getMyInfoChangesHistory,
  updateMyInfochanges,
};