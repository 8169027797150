
import { createSelector } from 'reselect';
import { WCEffectiveDate } from '../../models';
import { workerCompState } from '../reducers';
import { State as WorkerCompState } from '../reducers/workerComp.reducer';
import { getYear } from './client.selector';

export const getWorkerComps = createSelector(
  workerCompState,
  (state: WorkerCompState) => {
    return [
      ...state.workerComp.map((wc: WCEffectiveDate) => {
        return {
          ...wc,
          effectiveDate: new Date(wc.effectiveDate),
          rates: wc.rates.map(r => {return { ...r };}),
        };
      }),
    ];
  },
);

export const getWorkerComps2 = createSelector(
  workerCompState,
  getYear,
  (state: WorkerCompState, year: number) => {
    const wc = state.workerComp.filter((wc: WCEffectiveDate) => {
      const wcEffYear = new Date(wc.effectiveDate).getFullYear();
      return wcEffYear === year;
    });
    return wc[0];
  },
);
