import React from 'react';

export class AGCheckboxEditorComponent extends React.Component {
  input = null;

  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  setInputRef = (input) => {
    this.input = input;
  };

  getValue() {
    const x = { [this.props.column.key]: this.input.checked };
    return x;
  }

  getInputNode() {
    return this.input;
  }

  handleChangeComplete = (value) => {
    this.setState({ value }, () => {return this.props.onCommit();});
  };

  render() {
    return (
      <div style={{ backgroundColor: 'lightblue' }}>
        <input
          ref={this.setInputRef}
          type="checkbox"
          onBlur={this.props.onBlur}
          defaultChecked={this.props.value}
        />
      </div>
    );
  }
}
