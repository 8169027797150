import cloneDeep from 'lodash/cloneDeep';
import { createSelector } from 'reselect';
import {
  EmployeeUploadClientFile,
  EmployeeUploadClientLibrary,
} from '../../models';
import { employeeUploadState } from '../reducers';
import { State as EmployeeUploadState } from '../reducers/employee-upload.reducer';

export const getEmpUploadClientFiles = createSelector(
  employeeUploadState,
  (state: EmployeeUploadState) => {
    if (!state?.clientFiles) return [];

    return state.clientFiles
      ?.slice()
      .sort((a, b) => {
        return a.description < b.description
          ? -1
          : b.description < a.description
            ? 1
            : 0;
      },
      );
  },
);

export const getSelectedEmpUploadClientFile = createSelector(
  employeeUploadState,
  (state: EmployeeUploadState) => {
    // const fields = state.selectedClientFile ? state.selectedClientFile.fields.map(f => ({ ...f })) : [];
    // return { ...state.selectedClientFile, fields } as EmployeeUploadClientFile
    return cloneDeep(state.selectedClientFile) as EmployeeUploadClientFile;
  },
);

export const getSelectedEmpUploadClientLibrary = createSelector(
  employeeUploadState,
  (state: EmployeeUploadState) => {return { ...state.selectedClientLibrary } as EmployeeUploadClientLibrary;},
);

export const getEmpUploadTables = createSelector(
  employeeUploadState,
  (state: EmployeeUploadState) => {return state.tables;},
);

export const getEmpUploadFields = createSelector(
  employeeUploadState,
  (state: EmployeeUploadState) => {return state.fields;},
);

export const getEmpUploadFieldMap = createSelector(
  employeeUploadState,
  (state: EmployeeUploadState) => {return state.fieldMap;},
);
