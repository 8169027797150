import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class Rehire {
  clientNo = 0;

  empId = 0;

  empNo = 0;

  rehireId = 0;

  priorHireDate: Date | string | null = null;

  rehireDate: Date | string | null = null;

  static readonly convProps: PropTypeLists = {
    numberProps: ['clientNo', 'empId', 'empNo', 'rehireId'],
    dateProps: ['priorHireDate', 'rehireDate'],
  };

  constructor(
    clientNo: number | string,
    empId: number | string,
    empNo: number | string,
    rehireId: number | string,
    props?: { [key: string]: any },
  ) {
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empId = (typeof empId === 'string') ? parseInt(empId) : empId;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.rehireId = (typeof rehireId === 'string') ? parseInt(rehireId) : rehireId;

    props && convToClass(this, props, Rehire.convProps);
  }
}