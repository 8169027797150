import React, { useState } from 'react';
import { Employee } from 'core/models';
import EmpInfoEdit from './EmpInfoEdit';
import EmpInfoView from './EmpInfoView';
import Icon from 'core/components/shared/Icon';

type PropTypes = {
  employee: Employee;
};

const EmpInfoPage: React.FC<PropTypes> = ({ employee }) => {
  const [showEdit, setShowEdit] = useState(false);
  
  return (
    <div className="dm-panel dm-panel-border">
      <div className="row">
        <div className="col-10">
          <div className="dm-grid-title">Employee Information</div>
        </div>
        {!showEdit && (
          <div
            className="col-2 text-right"
            role="button"
            onClick={() => { setShowEdit(true); }}
          >
            <Icon
              name="pen"
              className="fa-pen"
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-12">
          <hr className="dm-panel-hr" />
        </div>
      </div>
      {showEdit ? (
        <EmpInfoEdit
          showHistory={false}
          employee={employee}
          onCancel={() => { return setShowEdit(false); }}
        />
      ) : (
        <EmpInfoView employee={employee} />
      )}
    </div>
  );
};

export default EmpInfoPage;
