import React, { CSSProperties, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { SSN_VALIDATION, SSN_VALIDATION_NO_DASH } from 'core/constants';
import { IAppEmpSearch } from 'core/models/AppEmpSearch.model';
import { Employee } from 'core/models/Employee.model';
import { loadAppEmpSearchNoModal } from 'core/store/actions';
import EmpPhoto from './EmpPhoto';

const photoStyles: CSSProperties = {
  padding: '4px',
};

type Props = {
  isSearching: boolean;
  query: string;
  searchAll: boolean;
  selectEmployee: (protectedEmpNo: string) => void;
};

const EmployeeSearch = ({ query, isSearching, searchAll, selectEmployee }: Props) => {
  
  const dispatch = useAppDispatch();
  const employeeSearch = useAppSelector((state) => { return state.app.employeeSearch; });
  
  useEffect(() => {
    if (query.length > 0) {
      const searchEmp: IAppEmpSearch = {} as IAppEmpSearch;
      if (SSN_VALIDATION.test(query) || SSN_VALIDATION_NO_DASH.test(query)) {
        searchEmp.ssn = query.replace(/-/g, '');
      } else if (/\d+/.test(query)) {
        searchEmp.empNo = parseInt(query);
      } else {
        searchEmp.lastName = query;
      }
      
      searchEmp.allClients = searchAll;
      
      dispatch(loadAppEmpSearchNoModal(searchEmp));
    }
  }, [query]);

  return (
    <>
      {employeeSearch.length > 1 && isSearching && (
        <div className="row mx-2 animate">
          <div className="col-sm-12">
            <div className="table-responsive mb-4">
              <table className="table-wrapper-wrapper table-bordered table-hover">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      style={{ width: '79px' }}
                    ></th>
                    <th scope="col">Emp #</th>
                    <th scope="col">SSN</th>
                    <th scope="col">First Name</th>
                    <th scope="col">MI</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Loc</th>
                    <th scope="col">Dept</th>
                    <th scope="col">Hire Date</th>
                    <th scope="col">Term Date</th>
                    <th scope="col">Super</th>
                    <th scope="col">Onboarding Status</th>
                  </tr>
                </thead>
                <tbody>
                  {employeeSearch.map(
                    (employee: Employee, index: number) => {
                      return (
                        <tr
                          role="button"
                          key={index}
                          data-protectedEmpNo={
                            employee.protectedEmpNo
                          }
                          onClick={(
                            ev: React.MouseEvent<HTMLTableRowElement>,
                          ) => {
                            const cell = ev.target as HTMLTableCellElement;
                            selectEmployee(cell.parentElement?.getAttribute('data-protectedEmpNo') ?? '');
                          }}
                        >
                          <td>
                            <EmpPhoto
                              photoStyles={photoStyles}
                              scaleFactor="75"
                              empPicData={employee.employeePhoto}
                              empName={`${employee.firstName} ${employee.lastName}`}
                            />
                          </td>
                          <td>{employee.empNo}</td>
                          <td>{employee.ssn}</td>
                          <td>{employee.firstName}</td>
                          <td>{employee.midName}</td>
                          <td>{employee.lastName}</td>
                          <td>{employee.loc}</td>
                          <td>{employee.dept}</td>
                          <td>
                            {employee.hireDate
                              ? DateTime.fromISO(
                                employee.hireDate as string,
                              ).toLocaleString()
                              : ''}
                          </td>
                          <td>
                            {employee.termDate
                              ? DateTime.fromISO(
                                employee.termDate as string,
                              ).toLocaleString()
                              : ''}
                          </td>
                          <td>{employee.isSupervisor}</td>
                          <td>
                            {employee.onboardingStatus}
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeSearch;
