import { DateTime } from "luxon";

export const toDateLessThanValid = (fromDate: Date, toDate: Date) => {

    const fromDateTime = fromDate
      ? fromDate.getTime()
      : null;
    const toDateTime = toDate
      ? new Date(toDate).getTime()
      : null;

    const isValid = ((fromDateTime && toDateTime && fromDateTime <= toDateTime));
    return isValid;
  };

export const toDateLessThanYearValid = (fromDate: Date, toDate: Date) => {

    const dateDuration = DateTime.fromJSDate(toDate)
      .diff(DateTime.fromJSDate(fromDate), 'days')?.toObject().days;

    const isValid = (
      (
        (dateDuration || 0) <= DateTime.local().daysInYear
      )
    );
    return isValid;
  };