import Icon from 'core/components/shared/Icon';
import { Note } from 'core/models';
import React, { useState } from 'react';
import { toReadableDate, toReadableTime } from 'utilities/utilities';
import DeleteNoteModal from './DeleteNoteModal';

interface Props extends Note {
  onDelete: (note: Note) => void;
}

const NoteItem = (props: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    noteId,
    noteDescription,
    createUser,
    createDate,
    applicantId,
    onDelete,
  } = props;

  return (
    <>
      <div
        key={noteId}
        className="d-flex align-items-start justify-content-between p-2 rounded-lg"
        style={{
          background: '#fefae5',
          border: '1px solid #f1f0e7',
        }}
      >
        <div>
          <p className="mb-0">{noteDescription}</p>
          <div
            className="d-flex align-items-center font-weight-bold"
            style={{ gap: '1.5rem' }}
          >
            <p className="mb-0">{createUser}</p>
            <p className="mb-0">{toReadableDate(createDate)}</p>
            <p className="mb-0">{toReadableTime(createDate)}</p>
          </div>
        </div>
        <button
          type="button"
          style={{
            background: 'transparent',
            border: 'none',
            gap: '0.5rem',
            color: 'rgb(100, 101, 106)',
          }}
          className="font-weight-bold d-flex align-items-center"
          onClick={() => {
            setIsDeleting(true);
          }}
        >
          Delete
          <Icon name="minus-circle" />
        </button>
      </div>
      {isDeleting && (
        <DeleteNoteModal
          show={isDeleting}
          onHide={() => {
            setIsDeleting(false);
          }}
          onDelete={() => {
            onDelete({
              noteId,
              noteDescription,
              createUser,
              createDate,
              applicantId,
            });
          }}
        />
      )}
    </>
  );
};

export default NoteItem;