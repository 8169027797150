import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import { clearFile, getFile } from '../../core/store/slices/file.slice';
import { useAppDispatch, useAppSelector } from '../hooks';

const ViewEmployeeDocsRenderer = (props: ICellRendererParams) => {
  const dispatch = useAppDispatch();

  const fileInfo = useAppSelector((state) => { return state.file.file; });

  const [showModal, setShowModal] = useState(false);
  const [showFile, setShowFile] = useState(false);

  useEffect(() => {
    if (fileInfo && fileInfo.path === props.value && showFile) {
      setShowModal(true);
    }
  }, [fileInfo, showFile]);

  const viewFile = () => {
    dispatch(getFile(props.value));
    setShowFile(true);
  };

  const onHide = () => {
    setShowModal(false);
    setShowFile(false);
    dispatch(clearFile());
  };

  const fileIcon = (extension: string) => {
    switch(extension){
      case '.pdf': return "file-pdf";
      case '.docx': return "file-word";
      case '.xlsx': return "file-excel";
      default: return "file-pdf";
    }
  }

  const validExtensionTypeToView = (extension: string) => {
    return ['.pdf', '.docx', '.xlsx'].includes(extension);
  }

  return (
    <>
      <div>
        {validExtensionTypeToView(props.data.extension) && (
          <button onClick={viewFile}
            className="btn btn-link">
            <Icon name={fileIcon(props.data.extension)} />
          </button>
        )}
      </div>
      {showModal && (
        <PDFViewerModal
          show={showModal}
          onHide={onHide}
          pdfData={fileInfo?.data}
          fileType={fileInfo?.extension}
          reportName="EmployeeDocs"
        />
      )}
    </>
  );
};

export default ViewEmployeeDocsRenderer;
