export enum AccrualReportType {
  DetailReport = 'DetailReport',
  SummaryReport = 'SummaryReport',
  EarnTakenDetailReport = 'EarnTakenDetailReport',
  EarnTakenSummaryReport = 'EarnTakenSummaryReport',
  RolloverSummaryReport = 'RolloverSummaryReport',
  EarnTakenBalanceReport = 'EarnTakenBalanceReport',
  SummaryForSelectedPeriodReport = 'SummaryForSelectedPeriodReport',
}

export enum ReportType {
  Pdf = 'Pdf',
  Excel = 'Excel',
}

export enum OrderByType {
  LastName = 'LastName',
  EmpNo = 'EmpNo',
}
export interface AccuralReportStandardRequest {
  clientNo: number;
  groupByLoc: boolean;
  groupByDept: boolean;
  groupBySubDept: boolean;
  groupBySubDept2: boolean;
  includeTerminated: boolean;
  includeInactivity: boolean;
  withPayout: boolean;
  pageBreakAfterLoc: boolean;
  pageBreakAfterDept: boolean;
  pageBreakAfterSub: boolean;
  pageBreakAfterSub2: boolean;
  pageBreakAfterEarningCode: boolean;
  beginDate: string;
  endDate: string;
  accrualReportType: AccrualReportType;
  reportType: string;
  empNos: number[];
  orderByType: OrderByType;
}

export interface AccrualReportsTabRequest {
  clientNo: number;
  empNo: number;
  protectedEmpNo: string;
  earningsCode: string;
}
