import { createReducer } from '@reduxjs/toolkit';
import { Client, Employee } from '../../models';
import {
  employeeTransferClearStatus,
  employeeTransferSetErrorStatus,
  executeEmployeeTransferComplete,
  loadEmployeeTransfer,
  storeEmployeeTransfer,
  storeEmployeeTransferValidate,
} from '../actions';

export interface State {
  loading: boolean;
  status: string;
  transfers: { employees: Employee[], relatedClients: Client[], validateResponse: any };
}

export const INITIAL_STATE: State = {
  loading: false,
  status: '',
  transfers: { employees: [], relatedClients: [], validateResponse: null },
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadEmployeeTransfer.type]: (state, action) => {
    return {
      ...state,
      loading: true,
      status: '',
    };
  },
  [storeEmployeeTransfer.type]: (state, action) => {
    return {
      ...state,
      loading: true,
      status: '',
      transfers: {
        employees: action.payload.employees,
        relatedClients: action.payload.relatedClients,
        validateResponse: null,
      },
    };
  },
  [storeEmployeeTransferValidate.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      status: '',
      transfers: { ...state.transfers, validateResponse: action.payload },
    };
  },
  [executeEmployeeTransferComplete.type]: (state) => {
    return {
      ...state,
      loading: false,
      status: 'success',
    };
  },
  [employeeTransferClearStatus.type]: (state) => {
    return {
      ...state,
      status: '',
      transfers: { ...state.transfers, validateResponse: null },
    };
  },
  [employeeTransferSetErrorStatus.type]: (state) => {
    return {
      ...state,
      status: 'fail',
    };
  },
});