// based on printJS's outdated methods and https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browsers
// basically checking userAgent is more of a guessing game and it's also super easy for someone to misrepresent, so we're using a modified version of this duck-typing solution

/**
 * Object literal with all functions for determining browser type and version.
 */
const Browser = {
  isFirefox: () => 'InstallTrigger' in window,
  isOpera: () => ('opr' in window && 'addons' in window) || 'opera' in window || navigator.userAgent.indexOf(' OPR/') >= 0,
  isIE: () => false || 'documentMode' in document,
  isEdge: () => navigator.userAgent.indexOf('Edg') != -1,
  isChrome: () => 'chrome' in window && window.navigator.vendor === 'Google Inc.' && !(Browser.isOpera() || Browser.isEdge()) && window.navigator.userAgent.indexOf('Chrome') != -1,
};

export default Browser;