import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  ControlDatePickerGrp,
  InputGrp,
  SelectModalGrp,
} from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import AlertModal from 'core/components/modals/alert-modal.modal';
import { UNSAVED_MESSAGE } from 'core/constants';
import { Employee, HireHistory } from 'core/models';
import {
  toggleBlockNavigation,
  updateHireHistory,
} from 'core/store/actions';
import { getTermCode, getYesNoCMOption } from 'core/store/selectors';
import { dateDiff, getAccess } from 'utilities/utilities';
import DropdownOptionForm from 'core/components/form-controls/select-modal/DropdownOptionForm';
import { useAppSelector } from 'utilities/hooks';

const fs: FieldInputSettings = {
  hireDate: {
    name: 'hireDate',
    label: 'HIRE DATE',
    groupClass: 'groupClass12',
  },
  termDate: {
    name: 'termDate',
    label: 'TERM DATE',
    groupClass: 'groupClass12',
  },
  termCodeID: {
    name: 'termCodeID',
    label: 'TERM CODE',
    groupClass: 'groupClass30',
  },
  // BASED ON hiredate & now ???
  hireYears: {
    name: 'hireYears',
    label: 'YEARS',
    groupClass: 'groupClass06',
  },
  hireMonths: {
    name: 'hireMonths',
    label: 'MONTHS',
    groupClass: 'groupClass06',
  },
  hireDays: {
    name: 'hireDays',
    label: 'DAYS',
    groupClass: 'groupClass06',
  },
  notes: {
    name: 'notes',
    label: 'NOTES',
    groupClass: 'gc100',
  },
};

type HireHistoryForm = HireHistory & {
  hireYears: number;
  hireMonths: number;
  hireDays: number;
};

type PropTypes = {
  item: HireHistory;
  selEmp: Employee;
  mainHireDate: Date | string | null;
  onDelete: (data: HireHistory) => void;
  allowDelete: boolean;
  updateHireAndTermDate: (data: HireHistory) => void;
  setShowTermWorkFlowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedHireHistory: React.Dispatch<React.SetStateAction<HireHistory | null>>;
  usesTerminationWorkFlow: boolean;
};

const HireHistoryItem: React.FC<PropTypes> = ({
  item,
  selEmp,
  onDelete,
  updateHireAndTermDate,
  allowDelete,
  setShowTermWorkFlowModal,
  setSelectedHireHistory,
  usesTerminationWorkFlow,
}) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [hireDateDirty, setHireDateDirty] = useState(false);

  const termCodeOpts = useSelector(getTermCode);
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  
  const {
    setValue,
    errors,
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState,
  } = useForm<HireHistoryForm>({
    defaultValues: {
      ...item,
      hireYears: 0,
      hireMonths: 0,
      hireDays: 0,
    },
  });

  const { dirtyFields } = formState;
  const { hireDate, termDate, termCodeID, hireDays, hireMonths, hireYears, notes } = watch(['hireDate', 'termDate', 'termCodeID', 'hireDays', 'hireMonths', 'hireYears', 'notes']);
  
  const showFutureHireDateMsg = useSelector(getYesNoCMOption(2482));

  useEffect(() => {
    if (item) {
      const d = dateDiff(item.hireDate, item.termDate);
      reset({
        ...item,
        hireYears: d.years,
        hireMonths: d.months,
        hireDays: d.days,
      });
    }
  }, [item]);

  useEffect(() => {
    const d = dateDiff(item.hireDate, item.termDate);
    reset({
      ...item,
      hireDate,
      termDate,
      termCodeID,
      notes,
      hireYears: d.years,
      hireMonths: d.months,
      hireDays: d.days,
    });
  }, [hireDate]);

  const onSave = (data: Partial<HireHistory>) => {
    if (
      showFutureHireDateMsg
      && (new Date(data?.hireDate ?? new Date()) > new Date())
      && !confirm('Selected hire date is in the future. Continue?')
    ) return;
    
    updateHireAndTermDate({
      ...item,
      ...data,
    });

    const newHireHistory = new HireHistory(
      selEmp.empId,
      selEmp.clientNo,
      selEmp.empNo,
      selEmp.protectedEmpNo,
      data,
    );
    newHireHistory.empDateID = item.empDateID;

    if (newHireHistory.empDateID) {
      dispatch(updateHireHistory(newHireHistory));
    } 

    reset({ ...newHireHistory });
    setHireDateDirty(false);
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: Object.keys(dirtyFields).length > 0,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [Object.keys(dirtyFields).length > 0]);

  return (
    <>
      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSave)(e);
        e.stopPropagation();
      }}
      >
        <div className="d-flex flex-wrap mt-3">
          <ControlDatePickerGrp
            {...fs.hireDate}
            {...getAccess(sectionAccess, 7)}
            value={hireDate}
            setValue={setValue}
            errors={errors.hireDate}
            control={control}
            innerChangeHandler={() => { setHireDateDirty(true); }}
          />
          <ControlDatePickerGrp
            {...fs.termDate}
            {...getAccess(sectionAccess, 7)}
            value={termDate}
            setValue={setValue}
            errors={errors.termDate}
            control={control}
            disabled={usesTerminationWorkFlow}
          />
          <SelectModalGrp
            {...fs.termCodeID}
            {...getAccess(sectionAccess, 7)}
            groupClass="gc12 mw125"
            errors={errors.termCodeID}
            noOption={true}
            modalTitle="TERM CODE"
            label={'TERM CODE'}
            formComponent={DropdownOptionForm}
            addOptionText="TERM CODE"
            dropdownName="TermCode"
            options={termCodeOpts}
            control={control}
            value={termCodeID}
            setValue={setValue}
            disabled={usesTerminationWorkFlow}
          />
          <InputGrp
            {...fs.hireYears}
            {...getAccess(sectionAccess, 7, undefined, { readOnlyDefault: true })}
            errors={errors.hireYears}
            ref={register({ valueAsNumber: true })}
            value={hireYears}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              if (e.target.value === String(hireYears)) return;
              setValue('hireYears', parseInt(e.target.value));
            }}
          />
          <InputGrp
            {...fs.hireMonths}
            {...getAccess(sectionAccess, 7, undefined, { readOnlyDefault: true })}
            errors={errors.hireMonths}
            ref={register({ valueAsNumber: true })}
            value={hireMonths}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              if (e.target.value === String(hireMonths)) return;
              setValue('hireMonths', parseInt(e.target.value));
            }}
          />
          <InputGrp
            {...fs.hireDays}
            {...getAccess(sectionAccess, 7, undefined, { readOnlyDefault: true })}
            errors={errors.hireDays}
            ref={register({ valueAsNumber: true })}
            value={hireDays}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              if (e.target.value === String(hireDays)) return;
              setValue('hireDays', parseInt(e.target.value));
            }}
          />
          <InputGrp
            {...fs.notes}
            {...getAccess(sectionAccess, 7)}
            groupClass={'gc100'}
            ref={register}
            control={control}
            value={notes}
            setValue={setValue}
            readOnly={usesTerminationWorkFlow}
          />
        </div>
        <div className="row pb-2">
          <div className="col-12 text-right">
          {usesTerminationWorkFlow ?  
            <button
              type="button"
              className="btn btn-link dm-grid-action-title"
              onClick={() => {setSelectedHireHistory(item); setShowTermWorkFlowModal(true);}}
            >
              Add Termination Workflow{' '}
              <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button> : null}
            <button
              type="button"
              className="btn btn-link dm-grid-action-title mr-4"
              {...getAccess(sectionAccess, 7, undefined, { disabledDefault: !allowDelete, disabledSameAsReadOnly: true })}
              onClick={() => { return onDelete(item); }}
            >
              Delete Hire History
              <Icon
                name="minus-circle"
                className="fa-minus-circle"
              />
            </button>
            <button
              type="submit"
              className="btn orange-button-sm mr-3"
              {...getAccess(sectionAccess, 7, undefined, { disabledDefault: !(Object.keys(dirtyFields).length > 0 || hireDateDirty), disabledSameAsReadOnly: true })}
            >
              Save
            </button>
          </div>
        </div>
      </form>
      {show && (
        <AlertModal
          title="Data Error"
          message="Term Date Must Be After Hire Date: Change the Term Date, Hire Date, or Delete the date."
          btnText="OK"
          show={show}
          onHide={() => { return setShow(false); }}
        />
      )}
    </>
  );
};

export default HireHistoryItem;
