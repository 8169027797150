import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { HttpResponse } from '../../models';
import { BambooHR, BambooHRRequest, CumulativeSeniorityReportRequest, 
  DemographicAndTurnoverEmployee, 
  DemographicAndTurnoverReportRequest, 
  DepartmentofLaborRequest, 
  DepartmentofLaborSetting, 
  EmployeeNavigatorAuditReportRequest, 
  FullTimeEquivalentReportRequest, 
  GetBambooHRRequest, 
  MyInfoDocStatusFile, 
  MyInfoDocStatusReportRequest } from '../../models/HumanCapitalReports.model';
import { 
  handleError, 
  handleSuccess, 
  handleWarning, 
} from '../actions';
import {
  storeCumulativeSeniorityReport,
  downloadCumulativeSeniorityReport,
  storeFulltimeEquivalentReport,
  downloadFulltimeEquivalentReport,
  storeMyInfoDocStatusFiles,
  downloadMyInfoDocStatusReport,
  loadMyInfoDocStatusFiles,
  storeMyInfoDocStatusReport,
  downloadDemographicAndTurnoverReport,
  getDemographicAndTurnoverEmployees,
  storeDemographicAndTurnoverEmployees,
  storeDemographicAndTurnoverReport,
  downloadEmployeeNavigatorAuditReport,
  storeEmployeeNavigatorAuditReport,
  downloadDeparmentofLaborReport,
  storeDeparmentofLaborReport,
  storeDepartmentofLaborSettings,
  loadDepartmentofLaborSettings,
  downloadACAProofListReport,
  storeACAProofListReport,
  updateDemographicAndTurnoverEmployee,
  createDepartmentofLaborSettings,
  updateDepartmentofLaborSettings,
  deleteDepartmentofLaborSettings,
  loadClientReportOptions,
  storeClientReportOptions,
} from '../actions/human-capital.action';
import {  HumanCapitalReportService } from 'core/services';
import { downloadBambooHRReport, getBambooHRSettings, storeBambooHRReport, storeBambooHrSettings } from '../slices/human-capital-reports/bambooHr.slice';


interface Actions<Type> {
  type: string;
  payload: Type;
}


const downloadFullTimeEquivalentReport$ = (action$: Observable<Actions<FullTimeEquivalentReportRequest>>) => {
  return action$.pipe(
    ofType(downloadFulltimeEquivalentReport.type),
    switchMap((action: { payload: FullTimeEquivalentReportRequest; }) => {
      return from(HumanCapitalReportService.postFullTimeEquivalentReportsTab(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeFulltimeEquivalentReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};


const downloadCumulativeSeniorityReport$ = (action$: Observable<Actions<CumulativeSeniorityReportRequest>>) => {
  return action$.pipe(
    ofType(downloadCumulativeSeniorityReport.type),
    switchMap((action: { payload: CumulativeSeniorityReportRequest; }) => {
      return from(HumanCapitalReportService.postCumulativeSeniorityReportsTab(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<string>) => { return storeCumulativeSeniorityReport(res.value); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadMyInfoDocStatusFiles$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadMyInfoDocStatusFiles.type),
    switchMap(() => {
      return from(HumanCapitalReportService.getMyInfoDocStatusFiles()).pipe(
        map((res: any) => { return res.data; }),
        map((res: MyInfoDocStatusFile[]) => { return storeMyInfoDocStatusFiles(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadMyInfoDocStatusReport$ = (action$: Observable<Actions<MyInfoDocStatusReportRequest>>) => {
  return action$.pipe(
    ofType(downloadMyInfoDocStatusReport.type),
    switchMap((action: { payload: MyInfoDocStatusReportRequest; }) => {
      return from(HumanCapitalReportService.postMyInfoDocStatusReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<string>) => { return storeMyInfoDocStatusReport(res.value); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};
const downloadACAProofListReport$ = (action$: Observable<Actions<number>>) => {
  return action$.pipe(
    ofType(downloadACAProofListReport.type),
    switchMap((action: { payload: number; }) => {
      return from(HumanCapitalReportService.postACAProofList(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<string>) => { return storeACAProofListReport(res.value); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};



const downloadEmployeeAuditNavigatorReport$ = (action$: Observable<Actions<EmployeeNavigatorAuditReportRequest>>) => {
  return action$.pipe(
    ofType(downloadEmployeeNavigatorAuditReport.type),
    switchMap((action: { payload: EmployeeNavigatorAuditReportRequest; }) => {
      return from(HumanCapitalReportService.postEmployeeNavigatorAuditReportTab(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<string>) => { return storeEmployeeNavigatorAuditReport(res.value); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadDemographicAndTurnoverReport$ = (action$: Observable<Actions<DemographicAndTurnoverReportRequest>>) => {
  return action$.pipe(
    ofType(downloadDemographicAndTurnoverReport.type),
    switchMap((action: { payload: DemographicAndTurnoverReportRequest; }) => {
      return from(HumanCapitalReportService.postDemographicAndTurnoverReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<string>) => { return storeDemographicAndTurnoverReport(res.value); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getDemographicAndTurnoverEmployees$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(getDemographicAndTurnoverEmployees.type),
    switchMap(() => {
      return from(HumanCapitalReportService.getDemographicAndTurnoverEmployees()).pipe(
        map((res: any) => { return res.data; }),
        map((res: DemographicAndTurnoverEmployee[]) => { return storeDemographicAndTurnoverEmployees(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const putDemographicAndTurnoverEmployee$ = (action$: Observable<Actions<DemographicAndTurnoverEmployee>>) => {
  return action$.pipe(
    ofType(updateDemographicAndTurnoverEmployee.type),
    switchMap((action: { payload: DemographicAndTurnoverEmployee; }) => {
      return from(HumanCapitalReportService.putDemographicAndTurnoverEmployee(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getDemographicAndTurnoverEmployees(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const downloadDepartmentofLaborReport$ = (action$: Observable<Actions<DepartmentofLaborRequest>>) => {
  return action$.pipe(
    ofType(downloadDeparmentofLaborReport.type),
    switchMap((action: { payload: DepartmentofLaborRequest; }) => {
      return from(HumanCapitalReportService.postDepartmentofLaborReportsTab(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeDeparmentofLaborReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};
const loadDepartmentofLaborSettings$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadDepartmentofLaborSettings.type),
    switchMap(() => {
      return from(HumanCapitalReportService.getDepartmentofLaborSettings()).pipe(
        map((res: any) => { return res.data; }),
        map((res: DepartmentofLaborSetting[]) => { return storeDepartmentofLaborSettings(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createDepartmentofLaborSettings$ = (action$: Observable<Actions<DepartmentofLaborSetting>>) => {
  return action$.pipe(
    ofType(createDepartmentofLaborSettings.type),
    switchMap((action: { payload: DepartmentofLaborSetting; }) => {
      return from(HumanCapitalReportService.postDepartmentofLaborSettings(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<DepartmentofLaborSetting>) => {
          return [
            loadDepartmentofLaborSettings(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateDepartmentofLaborSettings$ = (action$: Observable<Actions<DepartmentofLaborSetting>>) => {
  return action$.pipe(
    ofType(updateDepartmentofLaborSettings.type),
    switchMap((action: { payload: DepartmentofLaborSetting; }) => {
      return from(HumanCapitalReportService.putDepartmentofLaborSettings(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<DepartmentofLaborSetting>) => {
          return [
            loadDepartmentofLaborSettings(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteDepartmentofLaborSettings$ = (action$: Observable<Actions<DepartmentofLaborSetting>>) => {
  return action$.pipe(
    ofType(deleteDepartmentofLaborSettings.type),
    switchMap((action: { payload: DepartmentofLaborSetting; }) => {
      return from(HumanCapitalReportService.deleteDepartmentofLaborSettings(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<DepartmentofLaborSetting>) => {
          return [
            loadDepartmentofLaborSettings(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadClientReportOptions$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadClientReportOptions.type),
    switchMap(() => {
      return from(HumanCapitalReportService.getClientReportOptions()).pipe(
        map((res) => {return res.data;}),
        map((res) => {return storeClientReportOptions(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const getBambooHRSettings$ = (action$: Observable<Actions<GetBambooHRRequest>>) => {
  return action$.pipe(
    ofType(getBambooHRSettings.type),
    switchMap((action:{ payload:GetBambooHRRequest }) => {
      return from(HumanCapitalReportService.getBambooHrReport(action.payload)).pipe(
        map((res) => {return res.data;}),
        map((res: BambooHR) => {return storeBambooHrSettings(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const downloadBabmooHRReport$ = (action$: Observable<Actions<BambooHRRequest>>) => {
  return action$.pipe(
    ofType(downloadBambooHRReport.type),
    switchMap((action:{ payload:BambooHRRequest }) => {
      return from(HumanCapitalReportService.postBambooHrReport(action.payload)).pipe(
        map((res) => {return res.data;}),
        mergeMap((res: HttpResponse<string>) => {
          const handle = (res.messages[0] === 'No report data found') ?
            handleWarning(res.messages) : handleSuccess(res.messages);
          return [
            storeBambooHRReport(res.value),
            handle,
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

export const epics: any[] = [
  downloadFullTimeEquivalentReport$,
  downloadCumulativeSeniorityReport$,
  loadMyInfoDocStatusFiles$,
  downloadMyInfoDocStatusReport$,
  downloadACAProofListReport$,
  downloadEmployeeAuditNavigatorReport$,
  downloadDemographicAndTurnoverReport$,
  getDemographicAndTurnoverEmployees$,
  putDemographicAndTurnoverEmployee$,
  downloadDepartmentofLaborReport$,
  loadDepartmentofLaborSettings$,
  createDepartmentofLaborSettings$,
  updateDepartmentofLaborSettings$,
  deleteDepartmentofLaborSettings$,
  getBambooHRSettings$,
  downloadBabmooHRReport$,
  loadClientReportOptions$,
];
