import { AgGridReact } from '@ag-grid-community/react';
import { GridApi, ColDef, GridOptions, RowSelectedEvent } from '@ag-grid-enterprise/all-modules';
import Modal from 'core/components/modals/Modal';
import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import { Popover } from 'react-bootstrap';
import { RGBColor, SketchPicker } from 'react-color';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CheckboxGrpInLine, InputGrp, InputGrpDecimal, RadioGrp, SelectGrp } from '../../../core/components/form-controls';
import { RadioOptions } from '../../../core/components/form-controls/RadioGrp';
import { Dropdown } from '../../../core/models';
import { OverTimeEarning, OverTimeSetting, ReportColorSetting } from '../../../core/models/AccountingReports.model';
import { createOverTimeSettings, deleteOverTimeSettings, updateOverTimeSettings } from '../../../core/store/actions';
import { useAppSelector } from '../../../utilities/hooks';

const overtimeOptions: RadioOptions[] = [
  {
    value: 'false',
    label: 'All Overtime Included',
  },
  {
    value: 'true',
    label: 'Premium Portion Only',
  },
];
const earningsOptions: RadioOptions[] = [
  {
    value: 'true',
    label: 'Hours',
  },
  {
    value: 'false',
    label: 'Earnings',
  },
];
const pageBreakOptions = [
  {
    id: 'L',
    description: 'Location',
  },
  {
    id: 'D',
    description: 'Department',
  },
  {
    id: 'S',
    description: 'Sub-Department',
  },
  {
    id: '2',
    description: 'Sub-Department2',
  },
];

type PropTypes = {
  setting?: OverTimeSetting;
  show: boolean;
  onHide: () => void;
};

const OverTimeSettingsModal: React.FC<PropTypes> = ({ setting, show, onHide }) => {
  const dispatch = useDispatch();
  const earningCodes = useAppSelector((state) => { return state.dropdown.earningsCode; });
  const client = useAppSelector((state) => { return state.client.client; });
  const [selectedSetting, setSelectedSetting] = useState<OverTimeSetting>();
  const [lowColor, setLowColor] = useState<ReportColorSetting>(
    selectedSetting?.reportColorSettings?.find(c => { return c.assignedColorType === 'lowColor'; })!,
  );
  const [midColor, setMidColor] = useState<ReportColorSetting>();
  const [highColor, setHighColor] = useState<ReportColorSetting>();
  const [showLowCP, setShowLowCP] = useState<boolean>(false);
  const [showMidCP, setShowMidCP] = useState<boolean>(false);
  const [showHighCP, setShowHighCP] = useState<boolean>(false);
  const [selectedCodes, setSelectedCodes] = useState<Dropdown[]>();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const { register, handleSubmit, setValue, getValues, errors,
  } = useForm<OverTimeSetting>({
    defaultValues: {
      ...setting,
      usePremiumOnly: '' + setting?.usePremiumOnly || 'false',
      isHours: '' + setting?.isHours || 'true',
    },
  });

  useEffect(() => {
    setting && setSelectedSetting(setting);
  }, [setting]);

  const columns: ColDef[] = [
    {
      field: 'select',
      headerName: '',
      editable: false,
      checkboxSelection: true,
      width: 40,
    },
    {
      field: 'id',
      headerName: 'Code',
      width: 120,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 180,
    },
  ];

  const gridOptions: GridOptions = {
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    columnDefs: columns,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (selectedSetting && gridApi) {
      const lowC = selectedSetting.reportColorSettings?.find(c => { return c.assignedColorType === 'LowColor'; });
      const lowM = selectedSetting.reportColorSettings?.find(c => { return c.assignedColorType === 'MidColor'; });
      const lowH = selectedSetting.reportColorSettings?.find(c => { return c.assignedColorType === 'HighColor'; });

      lowC && setLowColor(lowC);
      lowM && setMidColor(lowM);
      lowH && setHighColor(lowH);

      gridApi?.forEachNode((node) => {
        if (selectedSetting.overTimeEarnings?.find(e => { return e.earnCode === node.data.id; })) {
          node.setSelected(true);
        }
      });
    }
  }, [selectedSetting, gridApi]);

  const convertColor = (color?: RGBColor | ReportColorSetting) => {
    return color ? `rgb(${color.r},${color.g},${color.b})` : '#000';
  };

  const onSubmit = (data: OverTimeSetting) => {

    if (data && client) {
      const setting: OverTimeSetting = {
        clientNo: client?.clientNo,
        otid: data?.otid || 0,
        clientID: client?.clientID,
        settingName: data.settingName,
        lowFrom: data.lowFrom,
        lowTo: data.lowTo,
        midFrom: data.midFrom,
        midTo: data.midTo,
        highFrom: data.highFrom,
        highTo: data.highTo,
        allEarnings: data.allEarnings === 'true' ? true : false,
        isHours: data.isHours === 'true' ? true : false,
        usePremiumOnly: data.usePremiumOnly === 'true' ? true : false,
        locationFrom: '1',
        locationTo: '999999999',
        deptFrom: '1',
        deptTo: '999999999',
        subDeptFrom: '1',
        subDeptTo: '999999999',
        subDept2From: '1',
        subDept2To: '999999999',
        pageBreakBy: data.pageBreakBy,
        reportColorSettings: [lowColor!, midColor!, highColor!],
        overTimeEarnings: selectedCodes?.map(c => {
          const e = selectedSetting?.overTimeEarnings?.find(o => { return o.earnCode === c.id; });
          return {
            otEarnID: e?.otEarnID ? e.otEarnID : 0,
            earnCode: c.id,
            otid: selectedSetting?.otid,
          };
        }) as OverTimeEarning[],
      };

      if (setting) {
        setting.otid ?
          dispatch(updateOverTimeSettings(setting)) :
          dispatch(createOverTimeSettings(setting));
      }
    }
  };

  const onDelete = () => {
    selectedSetting && dispatch(deleteOverTimeSettings(selectedSetting));
  };

  const colorRow = {
    fontSize: '12px',
    lineHeight: '20px',
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      selectedRows && setSelectedCodes(selectedRows);
    }
  };

  const onClearSelections = () => {
    gridApi?.forEachNode((node) => {
      node.setSelected(false);
    });
    setValue('allEarnings', false);
  };

  const changeAllEarnings = () => {
    gridApi?.forEachNode((node) => {
      node.setSelected(true);
    });
  };

  const onRowClicked = (e: RowSelectedEvent) => {
    e.node && e.node.setSelected(e.node.isSelected() === true ? true : false);
  };

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="modal-md"
        title="Report Options"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="hidden"
            name={'otid'}
            ref={register()}
            defaultValue={selectedSetting?.otid}
          />
          <div className="d-flex mt-2">
            <div className="d-flex flex-row flex-grow-1 ">
              <div className="d-flex flex-column mr-4">
                <InputGrp
                  label="Setting Name"
                  name="settingName"
                  ref={register()}
                />
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row"
                    style={colorRow}>
                    <span style={{ width: '60px' }}>Low Color</span>
                    <div className="mx-2"
                      style={{
                        border: '1px solid #ccc',
                        backgroundColor: convertColor(lowColor),
                        height: 25,
                        width: 40,
                      }}
                      title="Choose Low Color"
                      onClick={() => { return setShowLowCP(!showLowCP); }}>
                    </div>

                    {showLowCP ?
                      <Popover id="low-color">
                        <Popover.Content>
                          <div className="w-100 text-right mb-2">
                            <button
                              onClick={() => {
                                setShowLowCP(false);
                              }}>
                              <Icon name="times"
                                className="modal-close-btn" />
                            </button>
                          </div>
                          <SketchPicker
                            color={lowColor}
                            onChangeComplete={(color) => {
                              const rgb = color.rgb;
                              rgb && setLowColor({
                                r: rgb.r,
                                g: rgb.g,
                                b: rgb.b,
                                a: rgb.a || 0,
                                assignedColorType: 'lowColor',
                              });
                            }}
                          />
                        </Popover.Content>
                      </Popover>
                      : null}

                    <span className="mx-2">From</span>
                    <InputGrpDecimal
                      label=""
                      name="lowFrom"
                      errors={errors?.lowFrom}
                      ref={register({
                        valueAsNumber: true,
                        max: {
                          value: getValues('lowTo'),
                          message: 'Value should be lower than To',
                        },
                      })}
                    />
                    <span className="mx-2">To</span>
                    <InputGrpDecimal
                      label=""
                      type="number"
                      name="lowTo"
                      errors={errors?.lowTo}
                      ref={register({
                        valueAsNumber: true,
                        min: {
                          value: getValues('lowFrom'),
                          message: 'Value should be higher than From',
                        },
                      })}
                    />
                  </div>
                  <div className="d-flex flex-row"
                    style={colorRow}>
                    <span style={{ width: '60px' }}>Mid Color</span>
                    <div className="mx-2"
                      style={{
                        border: '1px solid #ccc',
                        backgroundColor: convertColor(midColor),
                        height: 25,
                        width: 40,
                      }}
                      title="Choose Mid Color"
                      onClick={() => { return setShowMidCP(!showMidCP); }}>
                    </div>
                    {showMidCP ?
                      <Popover id="mid-color">
                        <Popover.Content>
                          <div className="w-100 text-right mb-2">
                            <button
                              onClick={() => {
                                setShowMidCP(false);
                              }}>
                              <Icon name="times"
                                className="modal-close-btn" />
                            </button>
                          </div>
                          <SketchPicker
                            color={midColor}
                            onChangeComplete={(color) => {
                              const rgb = color.rgb;
                              rgb && setMidColor({
                                r: rgb.r,
                                g: rgb.g,
                                b: rgb.b,
                                a: rgb.a || 0,
                                assignedColorType: 'midColor',
                              });
                            }} />
                        </Popover.Content>
                      </Popover>
                      : null}

                    <span className="mx-2">From</span>
                    <InputGrpDecimal
                      label=""
                      name="midFrom"
                      errors={errors?.midFrom}
                      ref={register({
                        valueAsNumber: true,
                        max: {
                          value: getValues('midTo'),
                          message: 'Value should be lower than To',
                        },
                      })}
                    />
                    <span className="mx-2">To</span>
                    <InputGrpDecimal
                      label=""
                      type="number"
                      name="midTo"
                      errors={errors?.midTo}
                      ref={register({
                        valueAsNumber: true,
                        min: {
                          value: getValues('midFrom'),
                          message: 'Value should be higher than From',
                        },
                      })}
                    />
                  </div>
                  <div className="d-flex flex-row"
                    style={colorRow}>
                    <span style={{ width: '60px' }}>High Color</span>
                    <div className="mx-2"
                      style={{
                        border: '1px solid #ccc',
                        backgroundColor: convertColor(highColor),
                        height: 25,
                        width: 40,
                      }}
                      title="Choose High Color"
                      onClick={() => { return setShowHighCP(!showHighCP); }}>
                    </div>
                    {showHighCP ?
                      <Popover id="high-color">
                        <Popover.Content>
                          <div className="w-100 text-right mb-2">
                            <button
                              onClick={() => {
                                setShowHighCP(false);
                              }}>
                              <Icon name="times"
                                className="modal-close-btn" />
                            </button>
                          </div>
                          <SketchPicker
                            color={highColor}
                            onChangeComplete={(color) => {
                              const rgb = color.rgb;
                              rgb && setHighColor({
                                r: rgb.r,
                                g: rgb.g,
                                b: rgb.b,
                                a: rgb.a || 0,
                                assignedColorType: 'highColor',
                              });
                            }} />
                        </Popover.Content>
                      </Popover>
                      : null}

                    <span className="mx-2">From</span>
                    <InputGrpDecimal
                      label=""
                      name="highFrom"
                      errors={errors?.highFrom}
                      ref={register({
                        valueAsNumber: true,
                        max: {
                          value: getValues('highTo'),
                          message: 'Value should be lower than To',
                        },
                      })}
                    />
                    <span className="mx-2">To</span>
                    <InputGrpDecimal
                      label=""
                      type="number"
                      name="highTo"
                      errors={errors?.highTo}
                      ref={register({
                        valueAsNumber: true,
                        min: {
                          value: getValues('highFrom'),
                          message: 'Value should be higher than From',
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="dm-grid-action-title mb-2">Overtime Options</div>
                <RadioGrp
                  label=""
                  isVertical={true}
                  name="usePremiumOnly"
                  radioOptions={overtimeOptions}
                  defaultChecked={'' + setting?.usePremiumOnly || 'false'}
                  ref={register()}
                />
                <SelectGrp
                  label="Page Break By"
                  groupClass="gc mw150"
                  name="pageBreakBy"
                  options={pageBreakOptions}
                  ref={register()}
                />
              </div>
              <div className="d-flex flex-column flex-grow-1">
                <div className="dm-grid-action-title mb-2">Earnings</div>
                <RadioGrp
                  label=""
                  name="isHours"
                  radioOptions={earningsOptions}
                  defaultChecked={'' + setting?.isHours || 'true'}
                  ref={register()}
                />
                <div>
                  <div className="d-flex flex-row">
                    <CheckboxGrpInLine
                      name="allEarnings"
                      label="All Earnings"
                      onChange={changeAllEarnings}
                      ref={register()}
                    />
                    <button
                      type="button"
                      className="btn btn-link mb-2"
                      onClick={onClearSelections}>Clear Selections</button>
                  </div>
                </div>
                <div className="ag-theme-balham mt-2"
                  style={{ height: '220px' }}>
                  <AgGridReact
                    gridOptions={gridOptions}
                    rowData={earningCodes}
                    onGridReady={onGridReady}
                    onRowClicked={onRowClicked}
                    onSelectionChanged={onSelectionChanged}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-grow-1 text-right mt-4">
            <button
              type="button"
              className="btn btn-primary orange-outline-button ml-auto mr-2"
              onClick={() => { onDelete(); onHide(); }}
              disabled={!selectedSetting?.otid}
            >
              Delete
            </button>
            <button type="submit"
              className="orange-button mr-2">
              Save
            </button>
          </div>
        </form>
      </Modal>
    </div >);
};

export default OverTimeSettingsModal;