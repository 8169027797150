import React, { useState, useEffect, createContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Button, Container, Modal } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { mainRoutes } from 'navigation/app-main-nav';
import { useBreakpoint } from 'utilities/BreakpointProvider';
import AppSideNav from './AppSideNav';
import AuthRoute from 'core/components/AuthRoute';
import Page404 from '404Page';
import RootModal from './RootModal';
import { useAuth } from 'core/providers/authProvider';
import AppTopNavBar from './AppTopNavBar';
import AppRouteBar from './AppRouteBar';
import { useIdleTimer } from 'react-idle-timer';
import { Login, Logout, SignInCallback, LogoutCallback } from 'core/components/auth';
import NavigationMonitor from './NavigationMonitor';
import * as signalR from '@microsoft/signalr';
import useSignalR from '../../hooks/useSignalR';
import { useAppSelector } from 'utilities/hooks';
import PageSpinner from 'core/components/shared/PageSpinner';

type SignalRContextType = signalR.HubConnection | null;
export const SignalRContext = createContext<SignalRContextType>(null);

const App: React.FC = () => {
  const auth = useAuth();
  const signalRConnection = useSignalR();
  
  const userAccessLoading = useAppSelector((state) => { return state.auth.loadingUserAccess; });
  const { moduleAccessLoading } = useAppSelector((state) => { return state.app; });
  const { employeeFromPreviousClient } = useAppSelector(({ selEmployee }) => selEmployee);
  
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarRef, setSidebarRef] = useState<HTMLElement | null>(null);
  const [showSidebarMenuIcon, setShowSidebarMenuIcon] = useState(false);
  const [showMainNavTop, setShowMainNavTop] = useState(true);
  const [idleModal, setIdleModal] = useState(false);

  let firstPass = true;
  let handleTimeoutReturn: NodeJS.Timeout;
  const breakpoints: any = useBreakpoint();
  const hideSidebarCSS = 'width: 0; opacity: 0';
  const showSidebarCSS = 'width: 300; opacity: 1';
  
  // resize observer non-errors on chrome and firefox; reference: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message.includes('ResizeObserver loop completed with undelivered notifications.')
        || e.message.includes('ResizeObserver loop limit exceeded')
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
          resizeObserverErr.remove();
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
          resizeObserverErrDiv.remove();
        }
      }
    });
  }, []);
  
  useEffect(() => {
    if (sidebarRef && firstPass) {
      firstPass = false;
      sidebarRef.style.cssText = hideSidebarCSS;
    }

    breakpoints.md
      ? setShowSidebarMenuIcon(true)
      : setShowSidebarMenuIcon(false);
    breakpoints.md2 ? setShowMainNavTop(false) : setShowMainNavTop(true);
  }, [breakpoints]);

  const handleOnIdle = () => {
    setIdleModal(true);
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const toggleSidebar = (e: any) => {
    e && e.stopPropagation();
    sidebarRef &&
      (sidebarRef.style.cssText = sidebarOpen
        ? hideSidebarCSS
        : showSidebarCSS);
    setSidebarOpen(!sidebarOpen);
  };

  const closeSidebar = () => {
    sidebarRef && (sidebarRef.style.cssText = hideSidebarCSS);
    setSidebarOpen(false);
  };

  const handleIdleModalClose = () => {
    clearTimeout(handleTimeoutReturn);
    setIdleModal(false);
  };

  const handleTokenRefreshAndModalClose = () => {
    clearTimeout(handleTimeoutReturn);
    setIdleModal(false);
  };

  const handleTimeout = () => {
    handleTimeoutReturn = setTimeout(() => {
      auth.logout();
    }, 1000 * 60 * 1);
  };

  return (
    <Router>
      <SignalRContext.Provider value={signalRConnection}>
        <NavigationMonitor />
        <Container
          fluid={true}
          style={{ position: 'relative' }}
        >
          <ToastContainer theme="colored" />
          <AppTopNavBar
            showSidebarMenuIcon={showSidebarMenuIcon}
            showMainNavTop={showMainNavTop}
          />
          <AppRouteBar
            toggleSidebar={toggleSidebar}
            showSidebarMenuIcon={showSidebarMenuIcon}
          />
          <div>
            {/* Don't show sidebar initially */}
            {(!firstPass || sidebarOpen) ? (
              <div
                id="dm-sidebar-col"
                ref={setSidebarRef}
              >
                <AppSideNav closeSidebar={closeSidebar} />
              </div>
            ) : null}
            <Switch>
              <Route
                exact={true}
                path="/login"
                component={Login}
              />
              <Route
                exact={true}
                path="/signin-oidc"
                component={SignInCallback}
              />
              <Route
                exact={true}
                path="/logout"
                component={Logout}
              />
              <Route
                exact={true}
                path="/logout/callback"
                component={LogoutCallback}
              />
              {mainRoutes.map((route, index: number) => {
                return (
                  <AuthRoute
                    component={route.component}
                    path={route.route}
                    key={index}
                    exact={route.exact}
                    groupId={route?.groupId}
                    itemId={route?.itemId}
                    workItemId={route?.workItemId}
                  />
                );
              })}
              <Route
                path="*"
                component={Page404}
              />
            </Switch>
          </div>
          <Modal
            show={idleModal}
            onHide={handleIdleModalClose}
            onShow={handleTimeout}
            size="sm"
          >
            <Modal.Header>
              <Modal.Title>Are you still there?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleTokenRefreshAndModalClose}
                className="orange-button"
              >
                Stay Logged In
              </Button>
              <Button
                variant="secondary"
                onClick={auth.logout}
                className="orange-outline-button"
              >
                Log Out
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
        <RootModal />
      </SignalRContext.Provider>
    </Router>
  );
};

export default App;
