import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { InputGrp, InputGrpDecimal, SelectGrp } from '../../../core/components/form-controls';
import { FieldInputSettings, FormMethods } from '../../../core/components/form-controls/types';
import { DocCompContract } from '../../../core/models';
import { getGEDCAnnualDues, getGEDCCompensationType } from '../../../core/store/selectors';

const fs: FieldInputSettings = {
  compensationIndex: {
    name: 'compensationIndex',
    label: 'COMP INDEX',
    groupClass: 'gc15 mw125',
  },
  compensationType: {
    name: 'compensationType',
    label: 'COMP TYPE',
    groupClass: 'gc25 mw200'
  },
  capPercentPerContract: {
    name: 'capPercentPerContract',
    label: 'CAP % PER CONTRACT',
    groupClass: 'gc15 mw125'
  },
  percentPerContract: {
    name: 'percentPerContract',
    label: '% PER CONTRACT',
    groupClass: 'gc15 mw125'
  },
  estimateCollectionPercent: {
    name: 'estimateCollectionPercent',
    label: 'EST. COLLECTIONS %',
    groupClass: 'gc15 mw125'
  },
  deliveryMethod: {
    name: 'deliveryMethod',
    label: 'DELIVERY METHOD',
    groupClass: 'gc15 mw125'
  },
  deliveryEmail: {
    name: 'deliveryEmail',
    label: 'DELIVERY EMAIL',
    groupClass: 'gc12 mw125'
  },
  inVisalign: {
    name: 'inVisalign',
    label: 'INVISALIGN',
    groupClass: 'gc15 mw125'
  },
  labsPaid: {
    name: 'labsPaid',
    label: 'LABS PAID',
    groupClass: 'gc15 mw125'
  },
  invisalignExpress: {
    name: 'invisalignExpress',
    label: 'INVISALIGN EXPRESS',
    groupClass: 'gc11 mw125'
  },
  financeCharges: {
    name: 'financeCharges',
    label: 'FINANCE CHARGES',
    groupClass: 'gc11 mw125'
  },
  orientationPeriod: {
    name: 'orientationPeriod',
    label: 'ORIENTATION PERIOD',
    groupClass: 'gc11 mw125'
  },
  maximumCapitation: {
    name: 'maximumCap',
    label: 'MAXIMUM CAPITATION',
    groupClass: 'gc11 mw125'
  },
  autoCalc: {
    name: 'autoCalc',
    label: 'AUTO CALC',
    groupClass: 'gc15 mw125'
  },
  dsoMembership: {
    name: 'dsoMembership',
    label: 'DSO MEMBERSHIP',
    groupClass: 'gc15 mw125'
  },
  worksDaysPerWeek: {
    name: 'worksDaysPerWeek',
    label: 'WORKS DAYS PER WEEK',
    groupClass: 'gc11 mw125'
  },
  perDiem: {
    name: 'perDiem',
    label: 'PER DIEM',
    groupClass: 'gc11 mw125'
  },
  timeOffDays: {
    name: 'timeOffDays',
    label: 'Time Off Days',
    groupClass: 'gc11 mw125'
  },
  ceAnnual: {
    name: 'ceAnnual',
    label: 'CE ANNUAL',
    groupClass: 'gc11 mw125'
  },
  annualDues: {
    name: 'annualDues',
    label: 'ANNUAL DUES',
    groupClass: 'gc15 mw125'
  },
  advanceAmendment: {
    name: 'advanceAmendment',
    label: 'ADVANCE AMENDMENT',
    groupClass: 'gc15 mw125'
  },
  negativeForgiveness: {
    name: 'negativeForgiveness',
    label: 'NEGATIVE FORGIVENESS',
    groupClass: 'gc15 mw125'
  },
};

const yesNoOpts = [{
  id: 'Y',
  description: 'Yes'
}, {
  id: 'N',
  description: 'No'
}];
const deliveryMethodOpts = [{
  id: 'M',
  description: 'Mail'
}, {
  id: 'E',
  description: 'Email'
}];

type PropTypes = {
  contract: DocCompContract;
  onDeleteCompChild: (item: DocCompContract) => void;
  onFormChange: (item: DocCompContract) => void;
  register: FormMethods['register'];
  errors: FormMethods['errors'];
  hasErrors: boolean;
  setHasErrors: React.Dispatch<React.SetStateAction<boolean>>
};

const HRDocComp: React.FC<PropTypes> = ({ contract, onDeleteCompChild, onFormChange, register, errors, hasErrors, setHasErrors }) => {
  const [stateContract, setContract] = useState<DocCompContract>(contract);
  const gEDCAnnualDues = useSelector(getGEDCAnnualDues);
  const gEDCCompType = useSelector(getGEDCCompensationType);

  const onDelete = () => {
    onDeleteCompChild(contract);
  };

  const validateIntegerValue = (value: number) => {
    if (!Number.isInteger(+value)) {
      setHasErrors(true);
      return '';
    }
  }

  const handleChange = (e: any) => {
    let value = e.target.value;
    if (value === 'Y' || value === 'N') {
      value = value === 'Y' ? true : false;
    }
    const contract = {
      ...stateContract,
      [e.target.name]: value,
    };
    setContract(contract);
    onFormChange(contract);
  };

  return (
    <form>
      <div className="d-flex flex-row flex-wrap mr-3">
        <InputGrp {...fs.compensationIndex}
          errors={errors.compensationIndex}
          defaultValue={contract.compensationIndex}
          ref={register({validate: validateIntegerValue})}
          onChange={handleChange} />
        <SelectGrp {...fs.compensationType}
          errors={errors.compensationType}
          defaultValue={contract.compensationType}
          ref={register}
          options={gEDCCompType}
          onChange={handleChange} />
        <InputGrp {...fs.capPercentPerContract}
          errors={errors.capPercentPerContract}
          defaultValue={contract.capPercentPerContract}
          ref={register({validate: validateIntegerValue})}
          onChange={handleChange} 
          style={{ textAlign: 'right' }} />
        <InputGrp {...fs.percentPerContract}
          errors={errors.percentPerContract}
          defaultValue={contract.percentPerContract}
          ref={register({validate: validateIntegerValue})}
          onChange={handleChange} 
          style={{ textAlign: 'right' }} />
        <InputGrp {...fs.estimateCollectionPercent}
          errors={errors.estimateCollectionPercent}
          defaultValue={contract.estimateCollectionPercent}
          ref={register({validate: validateIntegerValue})}
          onChange={handleChange} 
          style={{ textAlign: 'right' }} />
        <SelectGrp {...fs.deliveryMethod}
          errors={errors.deliveryMethod}
          defaultValue={contract.deliveryMethod}
          ref={register}
          options={deliveryMethodOpts}
          onChange={handleChange} />
        <InputGrp {...fs.deliveryEmail}
          errors={errors.deliveryEmail}
          defaultValue={contract.deliveryEmail}
          ref={register}
          onChange={handleChange} />
        <SelectGrp {...fs.inVisalign}
          errors={errors.inVisalign}
          defaultValue={contract?.inVisalign ? 'Y' : 'N'}
          ref={register}
          options={yesNoOpts}
          onChange={handleChange} />
        <SelectGrp {...fs.labsPaid}
          errors={errors.labsPaid}
          defaultValue={contract?.labsPaid ? 'Y' : 'N'}
          ref={register}
          options={yesNoOpts}
          onChange={handleChange} />
        <SelectGrp {...fs.invisalignExpress}
          errors={errors.invisalignExpress}
          defaultValue={contract?.invisalignExpress ? 'Y' : 'N'}
          ref={register}
          options={yesNoOpts}
          onChange={handleChange} />
        <SelectGrp {...fs.financeCharges}
          errors={errors.financeCharges}
          defaultValue={contract?.financeCharges ? 'Y' : 'N'}
          ref={register}
          options={yesNoOpts}
          onChange={handleChange} />
        <InputGrp {...fs.orientationPeriod}
          errors={errors.orientationPeriod}
          defaultValue={contract.orientationPeriod}
          ref={register}
          onChange={handleChange} 
          style={{ textAlign: 'right' }} />
        <InputGrp {...fs.maximumCapitation}
          errors={errors.maximumCapitation}
          defaultValue={contract.maximumCap}
          ref={register({validate: validateIntegerValue})}
          onChange={handleChange}
          style={{ textAlign: 'right' }} />
        <SelectGrp {...fs.autoCalc}
          errors={errors.autoCalc}
          defaultValue={contract?.autoCalc ? 'Y' : 'N'}
          ref={register}
          options={yesNoOpts}
          onChange={handleChange} />
        <SelectGrp {...fs.dsoMembership}
          errors={errors.dsoMembership}
          defaultValue={contract?.dsoMembership ? 'Y' : 'N'}
          ref={register}
          options={yesNoOpts}
          onChange={handleChange} />
        <InputGrpDecimal {...fs.worksDaysPerWeek}
          errors={errors.worksDaysPerWeek}
          defaultValue={contract.worksDaysPerWeek}
          ref={register}
          onChange={handleChange} />
        <InputGrp {...fs.perDiem}
          errors={errors.perDiem}
          defaultValue={contract.perDiem}
          ref={register({validate: validateIntegerValue})}
          onChange={handleChange} 
          style={{ textAlign: 'right' }} /> 
        <InputGrp {...fs.timeOffDays}
          errors={errors.timeOffDays}
          defaultValue={contract.timeOffDays}
          ref={register({validate: validateIntegerValue})}
          onChange={handleChange} 
          style={{ textAlign: 'right' }} />
        <InputGrpDecimal {...fs.ceAnnual}
          errors={errors.ceAnnual}
          defaultValue={contract.ceAnnual}
          ref={register}
          onChange={handleChange} />   
        <SelectGrp {...fs.annualDues}
          errors={errors.annualDues}
          defaultValue={contract.annualDues}
          ref={register}
          options={gEDCAnnualDues}
          onChange={handleChange} />
        <SelectGrp {...fs.advanceAmendment}
          errors={errors.advanceAmendment}
          defaultValue={contract?.advanceAmendment ? 'Y' : 'N'}
          ref={register}
          options={yesNoOpts}
          onChange={handleChange} />
        <SelectGrp {...fs.negativeForgiveness}
          errors={errors.negativeForgiveness}
          defaultValue={contract?.negativeForgiveness ? 'Y' : 'N'}
          ref={register}
          options={yesNoOpts}
          onChange={handleChange} />
      </div>
      { hasErrors ? <small className="text-danger d-flex">{'Values marked in red must be whole numbers'} </small> : null}
      <div className="row">
        <div className="col-12 text-right" >
          <button type="button"
            className="btn btn-link dm-grid-action-title mr-4"
            onClick={() => { return onDelete(); }} >Delete this Doc Comp
            <Icon name="minus-circle"
              className="fa-minus-circle" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default HRDocComp;