import React, { useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import InputReportDateSelector from '../../core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import { Client, DtoOptions } from '../../core/models';
import { Payroll4070TipReportRequest } from '../../core/models/PayrollReports.model';
import { downloadPayroll4070TipReport, clearPayroll4070TipReport } from '../../core/store/actions';
import { getPayroll4070TipReport } from '../../core/store/selectors/payroll-reports.selector';
import { useAppSelector } from '../../utilities/hooks';



const Payroll4070TipReport = () => {
  const dispatch = useDispatch();

  const childRef: React.RefObject<any> = useRef();

  const report = useSelector(getPayroll4070TipReport);
  const client = useAppSelector((state) => {return state.client.client;}) as Client;
  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);

  const downloadReport = () => {
    const submitData: Payroll4070TipReportRequest = {
      clientNo: client.clientNo,
      dtoOptions: reportDates,
      reportType: 'pdf',
    };
    dispatch(downloadPayroll4070TipReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearPayroll4070TipReport());
  };

  return (

    <Row className="w-100">
      <Col>
        <div className="dm-panel dm-panel-border">
          <div className="dm-grid-title">4070 Tip Report</div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div>
              <InputReportDateSelector
                ref={childRef}
                returnDates={(dates: DtoOptions[]) => {return setReportDates(dates);}}
              />
            </div>
            <div className="flex-grow-1 text-right">
              <button
                type="button"
                className="orange-outline-button mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="orange-button mr-2"
                onClick={downloadReport}
                disabled={!reportDates.length}>
                Run Report
              </button>
            </div>
          </div>
        </div>
        {report ? <PDFViewerModal
          show={report.length > 0}
          pdfData={report}
          onHide={hidePdfViewer}
          reportName="Payroll4070TipReport"
        /> : null}
      </Col>
    </Row>
  );

};


export default Payroll4070TipReport;