import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  InputGrp,
  InputGrpInLine,
  RadioGrp,
} from '../../core/components/form-controls';
import { TextareaGrp } from '../../core/components/form-controls/TextareaGrp';
import { FieldInputSettings } from '../../core/components/form-controls/types';
import { EmployeeUploadClientFile } from '../../core/models';
import { storeSelectedEmpUploadClientFile } from '../../core/store/actions';
import { getSelectedEmpUploadClientFile } from '../../core/store/selectors/employee-upload.selector';
import { useRadioButtonChange, useAppSelector } from '../../utilities/hooks';

const UnsavedData: React.FC<any> = ({ show, onHide }) => {
  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };
  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal show={show}
        onHide={onHide}
        size="sm"
        animation={false}>
        <div className="modal-header">
          <div className="dm-card-title">UNSAVED DATA</div>
          <button type="button"
            onClick={closeModal}>
            <Icon name="times"
              className="modal-close-btn" />
          </button>
        </div>
        <Modal.Body>
          <div>
            Please SAVE your changes to upload map before
            continuing.
          </div>
          <div className="row mt-4">
            <div className="col-12 text-right">
              <button
                type="button"
                className="btn btn-primary orange-outline-button"
                onClick={closeModal}
              >
                Continue
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

type PropTypes = {
  csvFile: any;
  fileName: string;
  show: boolean;
  onHide: () => void;
  next: () => void;
  setHeaderStartingLine: React.Dispatch<React.SetStateAction<number>>;
};

const UploadEmpStep1Modal: React.FC<PropTypes> = ({
  csvFile,
  fileName,
  show,
  onHide,
  next,
  setHeaderStartingLine,
}) => {
  const dispatch = useDispatch();

  const [showUnsavedData, setShowUnsavedData] = useState(false);

  const empUploadClientFile = useSelector(getSelectedEmpUploadClientFile);

  const clientNo = useAppSelector((state) => { return state.client.client!.clientNo; });

  const { register, errors, setValue, handleSubmit } = useForm<any>({
    defaultValues: {
      ...empUploadClientFile,
      clientNoInFile: empUploadClientFile?.clientNoInFile
        ? 'true'
        : 'false',
      useClientEntityId: empUploadClientFile?.useClientEntityId
        ? 'true'
        : 'false',
      appendOrOverwriteDeductions:
        '' + empUploadClientFile?.appendOrOverwriteDeductions,
    },
  });

  useEffect(() => {
    const startPosition = empUploadClientFile ? empUploadClientFile.startOnLine : 1;
    setFileHeaderAndBody(startPosition);
    setHeaderStartingLine(startPosition);
    const currentState = { ...fs };
    currentState.useClientEntityId.defaultChecked = empUploadClientFile.useClientEntityId;
    currentState.style.defaultChecked = empUploadClientFile.style;
    currentState.clientNoInFile.defaultChecked = empUploadClientFile.clientNoInFile;
    currentState.appendOrOverwriteDeductions.defaultChecked = empUploadClientFile.appendOrOverwriteDeductions;
    setFs(currentState);
  }, []);

  const onStartOnLine = (e: any) => {
    if (+e.target.value > -1) {
      setFileHeaderAndBody(+e.target.value)
      setHeaderStartingLine(+e.target.value)
    } else {
      setValue('startOnLine', 1);
      setHeaderStartingLine(1)
    }
  };

  const setFileHeaderAndBody = (startPosition: number) => {
    const splitCSVFile = csvFile.split(/\n/, 10);
      const filterArray = ['', ...splitCSVFile];
      const header = startPosition > 1 ? filterArray.slice(1, startPosition).join('\n') : '';
      const body = startPosition < 1 ? filterArray.slice(1) : filterArray.slice(startPosition);
      setValue('fileHeader', header);
      setValue('fileBody', body.join('\n'));
  }

  const onNext = (data: any) => {
    if (empUploadClientFile) {
      const newUploadClientFile = new EmployeeUploadClientFile(
        clientNo,
        empUploadClientFile.clientFileId,
        data,
      );
      newUploadClientFile.fileName = fileName;
      newUploadClientFile.fields = empUploadClientFile?.fields ?? [];
      dispatch(storeSelectedEmpUploadClientFile(newUploadClientFile));
    }
    onHide();
    next();
  };

  const [fs, setFs] = useState<FieldInputSettings>({
    description: {
      name: 'description',
      label: 'Upload Template Description',
      required: true,
    },
    useClientEntityId: {
      name: 'useClientEntityId',
      label: 'Upload Employees by company entity',
    },
    style: {
      name: 'style',
      label: 'Choose the file that best describes your upload file:',
      radioOptions: [
        {
          value: 'D',
          label: 'Delimited (Comma delimited)',
        },
        {
          value: 'F',
          label: 'Fixed width',
        },
      ],
    },
    clientNoInFile: {
      name: 'clientNoInFile',
      label: 'Client number in upload file',
    },
    startOnLine: {
      name: 'startOnLine',
      label: 'Start import at row',
      type: 'number',
      labelWidth: 35,
    },
    appendOrOverwriteDeductions: {
      name: 'appendOrOverwriteDeductions',
      label: 'Append or overwrite existing deductions',
      radioOptions: [
        {
          value: '1',
          label: 'Append',
        },
        {
          value: '2',
          label: 'Overwrite',
        },
      ],
    },
    fileHeader: {
      name: 'fileHeader',
      label: 'FILE HEADER',
      rows: 2,
    },
    fileBody: {
      name: 'fileBody',
      label: 'FILE BODY',
      rows: 8,
    },
  });
  const [handleRadioButtonChange] = useRadioButtonChange(fs);

  const closeModal = (e: any) => {
    e.stopPropagation();
    onHide();
  };

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        animation={false}
      >
        <div className="modal-header">
            <div className="dm-card-title">Step 1 of 2: Upload Map</div>
          <button
            type="button"
            onClick={closeModal}
            className="btn-link"
            style={{
              background: 'transparent',
              border: 'none',
              outline: 'none',
            }}
          >
            <Icon
              name="times"
              className="modal-close-btn"
            />
          </button>
        </div>
        <Modal.Body>
          <form onSubmit={handleSubmit(onNext)}>
            <div className="row">
              <div className="col-12">
                <div className="dm-card-subtitle">
                  Upload Options
                </div>
              </div>
              <div className="col-6">
                <InputGrp
                  {...fs.description}
                  errors={errors.description}
                  ref={register({
                    required: 'Description is required.',
                  })}
                />
              </div>
              <div className="col-6">
                <RadioGrp
                  {...fs.useClientEntityId}
                  errors={errors.useClientEntityId}
                  ref={register}
                  onChange={handleRadioButtonChange}
                />
              </div>
              <div className="col-6">
                <RadioGrp
                  {...fs.style}
                  errors={errors.style}
                  ref={register}
                  onChange={handleRadioButtonChange}
                />
              </div>
              <div className="col-6">
                <RadioGrp
                  {...fs.clientNoInFile}
                  errors={errors.clientNoInFile}
                  ref={register}
                  onChange={handleRadioButtonChange}
                />
              </div>
              <div className="col-6">
                <InputGrpInLine
                  {...fs.startOnLine}
                  errors={errors.startOnLine}
                  ref={register}
                  onChange={onStartOnLine}
                  groupClass="mb-1"
                />
                <h6 className="pl-1 mb-1">
                  If the data does not begin on the first line
                  of the upload
                </h6>
                <h6 className="pl-1">
                  file, then specify the first row of data in
                  the upload file.
                </h6>
              </div>
              <div className="col-6">
                <RadioGrp
                  {...fs.appendOrOverwriteDeductions}
                  errors={errors.appendOrOverwriteDeductions}
                  ref={register}
                  onChange={handleRadioButtonChange}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <TextareaGrp
                  {...fs.fileHeader}
                  ref={register}
                  style={{ fontFamily: 'monospace' }}
                />
              </div>
              <div className="col-12">
                <TextareaGrp
                  {...fs.fileBody}
                  ref={register}
                  style={{ fontFamily: 'monospace' }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-right">
                <button
                  type="button"
                  className="btn btn-link"
                  style={{ fontSize: '14px' }}
                  disabled={true}
                >
                  <Icon name="chevron-left" /> Previous
                </button>
                <button
                  type="submit"
                  className="btn btn-link"
                  style={{ fontSize: '14px' }}
                >
                  Next <Icon name="chevron-right" />
                </button>
              </div>
            </div>
            {/* <div className="row mt-3">
							<div className="col-12 text-right">
								<button
									type="submit"
									className="orange-button" >
									Save
								</button>
							</div>
						</div> */}
          </form>
        </Modal.Body>
      </Modal>

      <UnsavedData
        show={showUnsavedData}
        onHide={() => { return setShowUnsavedData(false); }}
      />
    </div>
  );
};
export default UploadEmpStep1Modal;
