import React, { useState } from 'react';
import Modal from 'core/components/modals/Modal';
import Table from 'core/components/shared/dm-table/Table';
import { ColumnHeaderData, Row } from 'core/components/shared/dm-table/types';
import { PayrollTimeCardJobInfo, TimeCardDetail } from 'core/models';
import { useAppSelector } from 'utilities/hooks';
import { RadioGrp, RadioOptions } from 'core/components/form-controls/RadioGrp';
import { InputGrp } from 'core/components/form-controls';
import styles from './styles.module.scss';

const jobNoRadioOpts: RadioOptions[] = [
  { value: 'All', label: 'All' },
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
];

type CheckboxProps = {
  name: string;
  checked: boolean;
};

const matchQuery = (timeCard: PayrollTimeCardJobInfo, _query: string): boolean => {
  return timeCard?.description?.toLowerCase()?.includes(_query.toLowerCase())
         || timeCard?.jobNo?.includes(_query);
};

const ActiveCellCheckbox = ({ name, checked }: CheckboxProps) => (
  <input
    name={name}
    type="checkbox"
    checked={checked}
    aria-readonly
  />
);

const buildJobNoRow = (job: PayrollTimeCardJobInfo): Row => {
  return {
    id: job.jobNo,
    cells: [
      { children: job.jobNo, styleClass: 'td-20' },
      { children: job.description, styleClass: 'td-60' },
      {
        children: <ActiveCellCheckbox
          name={`activeYN${job.jobNo}`}
          checked={!!job?.activeYN}
        />,
        styleClass: 'td-20',
        position: 'center',
      },
    ],
  };
};

const jobNoColumns: ColumnHeaderData[] = [
  { title: 'Job No', styleClass: 'th-20' },
  { title: 'Description', styleClass: 'th-60' },
  { title: 'Active', styleClass: 'th-20' },
];

type SelectionOpt = 'All' | 'Active' | 'Inactive';

type Props = {
  show: boolean;
  onHide: () => void;
  setControlledField: (property: keyof TimeCardDetail, newValue: any) => void;
};

const JobNoModal = ({ show, onHide, setControlledField }: Props) => {
  const { allJobs } = useAppSelector(({ contractor }) => contractor);
  
  const [filteredJobs, setFilteredJobs] = useState<PayrollTimeCardJobInfo[]>(allJobs?.filter((job) => !!job?.activeYN));
  const [filters, setFilters] = useState<{searchFilter: string, radioFilter: SelectionOpt}>({searchFilter: '', radioFilter: 'Active'});
  
  const onFilter = (
    selectOption: SelectionOpt,
    search: string,
  ) => {
    switch (selectOption as SelectionOpt) {
      case 'Active':{
        const newJobs = allJobs?.filter((job: PayrollTimeCardJobInfo) => !!job?.activeYN);
        const newState = structuredClone(newJobs)?.filter((timeCard: PayrollTimeCardJobInfo) => matchQuery(timeCard, search));

        setFilteredJobs(newState);
        setFilters({searchFilter: search, radioFilter: 'Active'});
        break;
      }
      case 'Inactive': {
        const newJobs = allJobs?.filter((job: PayrollTimeCardJobInfo) => !job?.activeYN);
        const newState = structuredClone(newJobs)?.filter((timeCard: PayrollTimeCardJobInfo) => matchQuery(timeCard, search));

        setFilteredJobs(newState);
        setFilters({searchFilter: search, radioFilter: 'Inactive'});
        break;
      }
      default: {
        const newState = structuredClone(allJobs)?.filter((timeCard: PayrollTimeCardJobInfo) => matchQuery(timeCard, search));

        setFilteredJobs(newState);
        setFilters({searchFilter: search, radioFilter: 'All'});
        break;
      }
    }
  };
  
  return (
    <Modal
      title="Change Job Number"
      show={show}
      onHide={onHide}      
    >
      
      <div className="d-flex flex-column">
        <div className={styles['job-info-modal-top-container']}>
          <RadioGrp
            groupClass="gc12 mw200"
            radioOptions={jobNoRadioOpts}
            name="employeeStatus"
            checked={filters.radioFilter}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFilter((e?.target?.value ?? 'All') as SelectionOpt, filters.searchFilter)}
            controlled
          />
          <label
            htmlFor="job-info-modal-search"
            className={`mb-3 ${styles['job-info-modal-label']}`}
          >Search</label>
          <InputGrp
            placeholder="Enter job number or description"
            groupClass="m-0"
            inputClass={`gc20 ml-2 mb-3 ${styles['job-info-modal-input']}`}
            name="job-info-modal-search"
            type="text"
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              onFilter(filters.radioFilter, target.value);
            }}
          />
        </div>
        <Table
          columns={jobNoColumns}
          rows={filteredJobs?.map((job) => buildJobNoRow(job))}
          onRowClick={(id) => {
            setControlledField('jobNumber', id);
            onHide();
          }}
        />
      </div>
    </Modal>
  );
};

export default JobNoModal;