import React, { useEffect, useState } from 'react';
import { RadioGrp, RadioOptions } from 'core/components/form-controls/RadioGrp';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { Client } from 'core/models';
import { CARES_SBA_PPP_Request } from 'core/models/AccountingReports.model';
import { CommonService } from 'core/services';
import { clear_CARES_SBA_PPP_Report, download_CARES_SBA_PPP_Report } from 'core/store/actions';
import { getCARES_SBA_PPP_Report } from 'core/store/selectors';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';


const typesOpts: RadioOptions[] = [
  {
    value: 'Wages2019',
    label: '2019 Wages',
  },
  {
    value: 'Wages2020',
    label: '2020 Wages',
  },
  {
    value: 'BusinessNew',
    label: 'New Business',
  },
  {
    value: 'BusinessSeasonal',
    label: 'Seasonal Business',
  },
];

const CARES_SBA_PPP_Report = () => {
  const dispatch = useDispatch();

  const client = useAppSelector((state) => { return state.client.client; }) as Client;
  const [fileType, setFileType] = useState<string>('pdf');
  const report = useSelector(getCARES_SBA_PPP_Report);




  const { register, handleSubmit } =
    useForm<CARES_SBA_PPP_Request>({
      defaultValues: {

      },
    });
  const downloadReport = (data: CARES_SBA_PPP_Request) => {
    const submitData: CARES_SBA_PPP_Request = {
      clientNo: client.clientNo,
      type: data.type,
    };
    dispatch(download_CARES_SBA_PPP_Report(submitData));
  };


  const hidePdfViewer = () => {
    dispatch(clear_CARES_SBA_PPP_Report());
  };

  useEffect(() => {
    if (report && fileType === 'excel') {
      report &&
        CommonService.downloadBase64File(
          'application/excel',
          report,
          'CARES_SBA_PPP_Report.xlsx',
        );
      dispatch(clear_CARES_SBA_PPP_Report());
    }
  }, [report]);
  return (

    <>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>

          <div className="dm-grid-title">CARES SBA PPP Report</div>
          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title "
              onClick={() => { return setFileType('excel'); }}
            >
              Download Report{' '}
              <Icon name="download"
                className="fa-download" />
            </button>
          </div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div className="d-flex fkex-row row-wrap">
              <div>
                <RadioGrp
                  name="type"
                  radioOptions={typesOpts}
                  ref={register} />
              </div>
            </div>
          </div>
        </form>
      </div>

      {report ? <PDFViewerModal
        show={report.length > 0}
        pdfData={report}
        onHide={hidePdfViewer}
        reportName="CARES-SBA-PPP-Report" /> : null}
    </>

  );


};

export default CARES_SBA_PPP_Report;