import React from 'react';
import { Col, Row } from 'react-bootstrap';
import GridSelect from '../../Shared/GridSelect';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import SelectField from '../../Shared/SelectField';
import { EEDComponentProps } from 'types/eed.types';
import { setSelectedFields } from 'core/store/slices/employee-earnings-download.slice';
import { UserMapSelectedField } from 'core/models/Downloader.model';
import { IOption } from 'interfaces/IOption';
import { Dropdown } from 'core/models/Dropdown.model';
import { useSelector } from 'react-redux';
import { get401KOptions, getClient } from 'core/store/selectors';

// Custom Layout for Checkbox and Grid select forms
const YearToDateFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  const appDispatch = useAppDispatch();
  
  const selectedFields = useAppSelector((state) => {return state.employeeEarningsDownload.selectedFields;});
  const four01kOptions = useSelector(get401KOptions);
  const deductionsOptions = useAppSelector((state) => { return state.dropdown.deductionCode; });
  let companyBenefitsOptions = useAppSelector((state) => { return state.dropdown.companyBenefitDeductionCode; });
  const earningsCodeOptions = useAppSelector((state) => {return state.dropdown.earningsCode;});
  const earningsCodeWithTotal = [...earningsCodeOptions, new Dropdown(99999, 'Totals')];
  const client = useSelector(getClient);

  const companyBenefitsWithMatchOptions = () => {
    if (four01kOptions?.hasMatch) companyBenefitsOptions = [...companyBenefitsOptions, new Dropdown(99999, -1, { description: '401K Company Match' })];
    if (four01kOptions?.hasContribution) companyBenefitsOptions = [...companyBenefitsOptions, new Dropdown(99999, -2, { description: '401K Company Contribution' })];
    return companyBenefitsOptions;
  };
  const taxesOptions = useAppSelector((state) => {
    return state.dropdown.taxes;
  });

  //They can have either of these section/field names since it gets changed after saving. PI-8161
  const companyBenefitSectionNames = ['YTD Company Benefits', 'YTD Employee Deductions'];
  const companyBenefitFieldNames = ['Ytd Company Benefits Description', 'Ytd Deductions Description'];

  const parsedTaxesOptions = taxesOptions.map(item => {
    let grossKey = '';
    let withHoldingKey = '';

    if (item.description.includes('State Addl')) {
      grossKey = 'Ytd Taxes State Additional Gross';
      withHoldingKey = 'Ytd Taxes State Additional Withholding';
    } else if (item.description.includes('State')) {
      grossKey = 'Ytd Taxes State Gross';
      withHoldingKey = 'Ytd Taxes State Withholding';
    } else if (item.description.includes('Local SUI')) {
      grossKey = 'Ytd Taxes SUI Gross';
      withHoldingKey = 'Ytd Taxes SUI Withholding';
    } else if (item.description.includes('Local')) {
      grossKey = 'Ytd Taxes Local Gross';
      withHoldingKey = 'Ytd Taxes Local Withholding';
    } else if (item.description.includes('Federal')) {
      grossKey = 'Ytd Taxes Federal Gross';
      withHoldingKey = 'Ytd Taxes Federal Withholding';
    } else if (item.description.includes('FICA-M2')) {
      grossKey = 'Ytd Taxes FICA-M2 Gross';
      withHoldingKey = 'Ytd Taxes FICA-M2 Withholding';
    } else if (item.description.includes('FICA-M')) {
      grossKey = 'Ytd Taxes FICA-M Gross';
      withHoldingKey = 'Ytd Taxes FICA-M Withholding';
    } else if (item.description.includes('FICA-R')) {
      grossKey = 'Ytd Taxes FICA-R Gross';
      withHoldingKey = 'Ytd Taxes FICA-R Withholding';
    } else if (item.description.includes('EIC')) {
      withHoldingKey = 'Ytd Taxes EIC Withholding';
    } else if (item.description.includes('FUTA')) {
      grossKey = 'Ytd Taxes FUTA Gross';
    }

    return {
      ...item,
      grossKey,
      withHoldingKey,
    };
  });


  type SelectedItem = UserMapSelectedField & IOption;

  return (
    <Row>
      <Col sm={6}>
        <Row>
          {!!sectionFields?.length && (
            <Col sm={12}>
              <GridSelect
                section="YTD Earnings Information"
                title="Earnings Information"
                dFields={sectionFields.find(f => {
                  return f.name === 'YTD Earnings Information';
                })?.fields}
                options={earningsCodeWithTotal}
                formMethods={formMethods}
                isCodeField = {false}
              />
            </Col>
          )}
          {!!sectionFields?.length && (
            <Col sm={12}>
              <SelectField
                title="YTD Employee Deductions"
                section="YTD Employee Deductions"
                options={deductionsOptions?.map(item => {
                  return {
                    value: item.id,
                    label: item.description,
                  };
                }) || []}
                onChange={(selected) => {
                  const sFields = structuredClone(selectedFields).filter(x => x.section !== 'YTD Employee Deductions' && x.fieldName !== 'Ytd Deductions Description');

                  appDispatch(setSelectedFields([
                    ...sFields,
                    // @ts-ignore
                    ...selected.map((item: SelectedItem) => {
                      return {
                        section: 'YTD Employee Deductions',
                        selectedAreaId: item?.selectedAreaId || 0,
                        fieldName: 'Ytd Deductions Description',
                        format: item?.format || null,
                        font: item?.font || null,
                        filter: {
                          filterId: item?.filter?.filterId || 0,
                          code: '' + item.value,
                          Description: item.label,
                        },
                      };
                    }),
                  ] as UserMapSelectedField[]));
                }}
              />
            </Col>
          )}
        </Row>
      </Col>
      <Col sm={6}>
        <Row>
          {!!sectionFields?.length && (
            <Col sm={12}>
              <GridSelect
                section="Taxes"
                title="Taxes"
                dFields={[
                  {
                    name: 'Gross',
                    key: 'grossKey',
                  },
                  {
                    name: 'Withholding',
                    key: 'withHoldingKey',
                  },
                ]}
                options={parsedTaxesOptions}
                formMethods={formMethods}
                isCodeField = {true}
                hasCodeColumn = {false}
                isMultiSelect = {false}
                useKey={true}
                grossToNetChecked = {false}
              />
            </Col>
          )}
          {!!sectionFields?.length && (
            <Col sm={12}>
              <SelectField
                title="Company Benefits"
                section="YTD Company Benefits"
                section2="YTD Employee Deductions"
                options={companyBenefitsWithMatchOptions().map(item => {
                  return {
                    value: item.id,
                    label: item.description,
                  };
                }) || []}
                onChange={(selected) => {
                  const sFields = structuredClone(selectedFields).filter(x => !companyBenefitSectionNames.includes(x.section || '') && !companyBenefitFieldNames.includes(x.fieldName));

                  appDispatch(setSelectedFields([
                    ...sFields,
                    // @ts-ignore
                    ...selected.map((item: SelectedItem) => {
                      return {
                        section: 'YTD Company Benefits',
                        selectedAreaId: item?.selectedAreaId || 0,
                        fieldName: 'Ytd Company Benefits Description',
                        format: item?.format || null,
                        font: item?.font || null,
                        filter: {
                          filterId: item?.filter?.filterId || 0,
                          code: '' + item.value,
                          Description: item.label,
                        },
                      };
                    }),
                  ] as UserMapSelectedField[]));
                }}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default YearToDateFields;