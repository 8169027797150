import { ICellEditorParams } from '@ag-grid-community/core';

export function agCheckboxEditor(): any {}

agCheckboxEditor.prototype.init = function (params: ICellEditorParams) {
  this.divWrapper = document.createElement('div');
  this.divWrapper.style.cssText = 'display: flex; justify-content: center';

  this.checkboxInput = document.createElement('input');
  this.checkboxInput.type = 'checkbox';
  this.checkboxInput.checked = params.value;
  this.checkboxInput.style = 'margin-top: 7px';
  this.divWrapper.appendChild(this.checkboxInput);
};

agCheckboxEditor.prototype.getGui = function () {
  return this.divWrapper;
};

agCheckboxEditor.prototype.getValue = function () {
  return this.checkboxInput.checked ? true : false;
};

agCheckboxEditor.prototype.destroy = function () {};

agCheckboxEditor.prototype.isPopup = function () {
  return false;
};
