import { AgGridReact } from '@ag-grid-community/react';
import {
  AllModules,
  CellValueChangedEvent,
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
  RowNode,
} from '@ag-grid-enterprise/all-modules';
import Modal from 'core/components/modals/Modal';
import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EmployeeUploadTranslation } from '../../core/models';
import {
  addUpdateEmpUploadTranslation, deleteEmpUploadTranslation,
} from '../../core/store/actions';
import AGDeleteHeaderComponent from '../../utilities/ag-grid-renderers/AGDeleteHeaderComponent';
import AGEditButtonRendererComponent from 'utilities/ag-grid-renderers/AGEditButtonRendererComponent';
import CheckboxRenderer from 'utilities/ag-grid-renderers/CheckboxRenderer';

const onCellValueChanged = (e: CellValueChangedEvent) => {
  if (e.oldValue !== e.newValue) {
    e.data.modified = true;
  }
};

  const columns: ColDef[] = [
    {
      field: 'originalValue',
      headerName: 'Original Value',
      sortable: true,
      editable: true,
      width: 200,
    },
    {
      field: 'translatedValue',
      headerName: 'Translated Value',
      sortable: true,
      editable: true,
      width: 200,
    },
    {
      field: 'delete',
      headerComponentFramework: AGDeleteHeaderComponent,
      editable: false,
      cellRenderer: 'checkBoxRenderer',
      cellEditor: 'checkboxEditor',
      cellStyle: { paddingLeft: '16px' },
      width: 45,
    },
  ];
interface RowDataType extends EmployeeUploadTranslation {
  modified: boolean;
}

type PropTypes = {
  clientFileId: number;
  mapId: number;
  translations: EmployeeUploadTranslation[];
  show: boolean;
  onHide: () => void;
};

const TranslationsModal: React.FC<PropTypes> = ({
  clientFileId,
  mapId,
  translations,
  show,
  onHide,
}) => {
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [, setGridColumnApi] = useState<ColumnApi | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [rowData, setRowData] = useState<RowDataType[]>([]);

  const gridOptions: GridOptions = {
    columnDefs: columns,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    frameworkComponents: {
      editButtonRendererComponent: AGEditButtonRendererComponent,
      checkBoxRenderer: CheckboxRenderer,
    },
    rowSelection: 'multiple',
    stopEditingWhenCellsLoseFocus: true,
    onCellClicked:(() => setIsDirty(true)),
  };

  useEffect(() => {
    initalizeTranslations();
  }, [translations]);

  const initalizeTranslations = () => {
    const currentTranslations = translations.map((t) => {
      return {
        ...t,
        modified: false,
        delete: false,
      };
    });
    setRowData(currentTranslations);
  }

  const gridHeight = rowData ? rowData.length * 20 + 100 : 100;

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onAddTranslation = () => {
    setRowData((prev) => {
      return [
        ...prev,
        {
          ...new EmployeeUploadTranslation(mapId),
          modified: false,
          delete: false,
        },
      ];
    });
  };

  const closeModal = (isDirty: boolean) => {
    if(isDirty) {
      if(confirm('You have unsaved changes. Would you like to save the changes?')) onSave();
      else initalizeTranslations();
    }
    setIsDirty(false);
    onHide();
  };

  const onDelete = () => {
    if (!confirm('Delete selected translations? This action cannot be undone.')) return;
    if (gridApi) {
      const fieldsToDelete: EmployeeUploadTranslation[] = [];

      gridApi.forEachNode((node: RowNode) => {
        node.data.delete &&
          node.data.translationId &&
          fieldsToDelete.push(node.data);
      });
      setRowData((prev) => {
        const newNodes: any[] = [];
        gridApi.forEachNode((node: RowNode) => {
          !node.data.delete && newNodes.push(node.data);
        });
        return newNodes;
      });

      fieldsToDelete.length && dispatch(deleteEmpUploadTranslation({clientFileId,
        translations: fieldsToDelete}));
    }
    setIsDirty(false);
  }

  const onSave = () => {
    if(clientFileId === 0 || mapId === 0) return closeModal(false);
    if (gridApi) {
      let rowNodes: EmployeeUploadTranslation[] = [];
      let hasDelete = false;

      //Add only the records that are not deleted (exluding delete will remove them in API). 
      gridApi.forEachNode((node: RowNode) => {
        if(node.data.delete === false) rowNodes = [...rowNodes, node.data];
        else hasDelete = true;
      });

      dispatch(
        addUpdateEmpUploadTranslation({
          clientFileId,
          mapId: mapId,
          translations: rowNodes,
        }),
      );
    }
    closeModal(false);
  };

  return (
    <div onClick={(e) => { return closeModal(isDirty); }}>
      <Modal
        show={show}
        onHide={() => {return closeModal(isDirty)}}
        headerSlot={(
          <div className="col-6 text-right mt-auto">
            <button
              type="button"
              className="btn btn-link dm-grid-action-title pb-0"
              onClick={onAddTranslation}
            >
              Add Translation
              <Icon name="plus-circle"
                className="fa-plus-circle" />
            </button>
          </div>
        )}
        title="Add Translation"
      >
        <div className="row">
          <div
            className="col-12 table-wrapper-wrapper ag-theme-balham"
            style={{ height: gridHeight }}
          >
            <AgGridReact
              gridOptions={gridOptions}
              rowData={rowData}
              modules={AllModules}
              onGridReady={onGridReady}
              onCellValueChanged={onCellValueChanged}
            />
          </div>
          <div className="col-12 text-right">
            <button
              type="button"
              className="btn btn-link pb-0"
              onClick={onDelete}
            >
              Delete Selected Items
              <Icon name="minus-circle"
                className="fa-minus-circle" />
            </button>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 text-right">
            <button
              type="button"
              className="btn btn-primary orange-outline-button mr-2"
              onClick={() => {closeModal(false), initalizeTranslations();}}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary orange-button"
              onClick={onSave}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TranslationsModal;
