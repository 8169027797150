import React, { lazy, Suspense } from 'react';
import { ValidateDetails } from 'core/models';

export interface BaseProps {
  show: boolean;
  onHide: () => void;
  items?: ValidateDetails[];
}
interface Props {
  data: ValidateDetails[];
  type: string;
  id: number;
  controlTotalId: number;
  onUpdateItems: (rowData: ValidateDetails[] | null) => void;
}

type IntersectionProps = BaseProps & Props;

const TransmittalError = ({
  show,
  data,
  type,
  onHide,
  onUpdateItems,
  id,
  controlTotalId,
}: IntersectionProps) => {
  const items = data.filter((d) => {return d.name === type;});
  const props: IntersectionProps = { show,
    onHide,
    onUpdateItems,
    items,
    data,
    type,
    id,
    controlTotalId,
  };
  // NOTE: Don't do this inside of the component unless you need a dynamic filepath
  const DynamicModal = lazy(() => { return import(`./${type}.modal`); });

  return (
    <div>
      {type ? (
        <Suspense fallback={<div>loading...</div>}>
          <DynamicModal {...props} />
        </Suspense>
      ) : null}
    </div>
  );
};

export default TransmittalError;
