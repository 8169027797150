import React from 'react';
import '../time-card-styles.scss';
import InputSection from '../InputSection';
import { TimeCardJobRosterJobInfo } from 'core/models/Contractor.model';

type Props = {
  jobInfo: TimeCardJobRosterJobInfo;
};

const JobRosterJobInfoSection = ({ jobInfo }: Props) => {
  return (
    <div className="main-section">
      <div className="section-title">Job Info</div>
      <InputSection
        label="Job No"
        defaultValue={jobInfo.jobNo}
        addtlInputStyles={{ textAlign: 'center' }}
        readOnly={true}
      />
      <InputSection
        label="Sub No"
        defaultValue={jobInfo.subNo}
        addtlInputStyles={{ textAlign: 'center' }}
        readOnly={true}
      />
      <InputSection
        label="Job Desc."
        defaultValue={jobInfo.jobDesc}
        addtlInputStyles={{ textAlign: 'center' }}
        readOnly={true}
      />
      <InputSection
        label="FO/Extra"
        defaultValue={jobInfo.foExtra}
        addtlInputStyles={{ textAlign: 'center' }}
        readOnly={true}
      />
      <InputSection
        label="Phase"
        defaultValue={jobInfo.phase}
        addtlInputStyles={{ textAlign: 'center' }}
        readOnly={true}
      />
      <InputSection
        label="Cost Code"
        defaultValue={jobInfo.costCode}
        addtlInputStyles={{ textAlign: 'center' }}
        readOnly={true}
      />
      <InputSection
        label="City Code"
        defaultValue={jobInfo.cityCode}
        addtlInputStyles={{ textAlign: 'center' }}
        readOnly={true}
      />
    </div>
  );
};

export default JobRosterJobInfoSection;