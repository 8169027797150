import React from 'react';
import CheckBoxForm from '../../Shared/CheckBoxForm';
import { Col, Row } from 'react-bootstrap';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for Checkbox and Grid select forms
const EmployeeStatusGeneralFields = ({ formMethods, sectionFields }: EEDComponentProps) => {

  return (
    <Row>
      <Col xs={12}>
        <CheckBoxForm
          formMethods={formMethods}
          dFields={sectionFields.find(f => {
            return f.name === 'Employee Information';
          })?.fields}
          section="Employee Information"
          title="Employee Information"
        />
      </Col>
      <Col xs={12}>
        <CheckBoxForm
          formMethods={formMethods}
          dFields={sectionFields.find(f => {
            return f.name === 'Location And Title HR';
          })?.fields}
          section="Location And Title HR"
          title="Location And Title"
        />
      </Col>
    </Row>
  );
};

export default EmployeeStatusGeneralFields;