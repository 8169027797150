import React, { MutableRefObject, useCallback, useMemo, useRef } from 'react';
import { agCurrencyRenderer } from '../../../../utilities/ag-grid-renderers';
import { EarningPayrollCheckResult } from '../../../models';
import {
  AllModules,
  ColDef,
  GridOptions,
} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';

//#region AgGrid
const columns: ColDef[] = [
  {
    field: 'loc',
    headerName: 'Loc',
    sortable: true,
    width: 60,
  },
  {
    field: 'dept',
    headerName: 'Dept',
    sortable: true,
    width: 80,
  },
  {
    field: 'subDept',
    headerName: 'Sub Dept',
    sortable: true,
    width: 80,
  },
  {
    field: 'subDept2',
    headerName: 'Sub Dept2',
    sortable: true,
    width: 80,
  },
  {
    field: 'earningsCode',
    headerName: 'Code',
    sortable: true,
    width: 60,
  },
  {
    field: 'rate',
    headerName: 'Rate',
    sortable: true,
    width: 120,
    cellClass: 'ag-cell-left-border text-right',
    valueFormatter: (params) => {
      if (params.value) {
        return params.value.toFixed(2);
      }
    },
  },
  {
    field: 'hours',
    headerName: 'Hours',
    sortable: true,
    width: 80,
    aggFunc: 'sum',
    cellClass: 'ag-cell-left-border text-right',
    valueFormatter: (params) => {
      if (params.value) {
        return params.value.toFixed(2);
      }
    },
  },
  {
    field: 'earnings',
    headerName: 'Earnings',
    sortable: true,
    width: 80,
    aggFunc: 'sum',
    cellClass: 'ag-cell-left-border text-right',
    cellRenderer: 'currencyRenderer',
  },
];

const gridOptions: GridOptions = {
  columnDefs: columns,
  domLayout: 'autoHeight',
  groupIncludeFooter: true,
  groupIncludeTotalFooter: true,
  defaultColDef: {
    suppressMenu: true,
    headerClass: 'grid-header',
    cellClass: 'ag-cell-left-border',
  },
  components: {
    currencyRenderer: agCurrencyRenderer,
  },
  getRowClass: (params) => {
    if (params.node.footer) {
      return 'font-weight-bold';
    }
  },
};

type PropTypes = {
  items: EarningPayrollCheckResult[];
};

const CCEarnings: React.FC<PropTypes> = ({ items }) => {
  const [gridOptionsMemo] = useMemo(() => {return [gridOptions];}, []) as [GridOptions];
  const gridRef = useRef() as MutableRefObject<AgGridReact>;

  const onFirstDataRendered = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  return (
    <div>
      <div className="mb-5">
        <div className="table-wrapper-wrapper ag-theme-balham">
          <AgGridReact
            ref={gridRef}
            gridOptions={gridOptionsMemo}
            rowData={items}
            onFirstDataRendered={onFirstDataRendered}
            modules={AllModules}
          />
        </div>
      </div>
    </div>
  );
};

export default CCEarnings;
