import { ofType } from 'redux-observable';
import { catchError, from, map, mergeMap, Observable, switchMap } from 'rxjs';
import { EmpCustomFieldRequest } from '../../models/HREmployee.model';
import { HrEmployeeCompanyIssuedPropertyRequest } from '../../models/HrEmployeeCompanyIssuedProperty.model';
import { HrEmployeeCustomFields } from '../../models/HrEmployeeCustomFields.model';
import {
  HrEmployeeEmergencyContact,
  HrEmployeeStatusEmergencyContactIdRequest,
} from '../../models/HrEmployeeEmergencyContact.model';
import { HrEmployeeStatus } from '../../models/HrEmployeeStatus';
import { HttpResponse } from '../../models/HttpResponse.model';
import { HrService } from '../../services/hr.service';
import { handleError, handleSuccess } from '../actions/app.action';
import {
  changeEmpCustomField,
  changeHrEmployeeCompanyProperty,
  changeHrEmployeeEmergencyContact,
  deleteEmpCustomField,
  deleteHrEmployeeCompanyProperty,
  deleteHrEmployeeEmergencyContacts,
  loadEmpCustomFields,
  loadHrEmployeeCompanyProperty,
  loadHrEmployeeEmergencyContacts,
  loadHrEmployeeStatus,
  postEmpCustomField,
  postHrEmployeeCompanyProperty,
  postHrEmployeeEmergencyContacts,
  putEmpCustomField,
  putHrEmployeeCompanyProperty,
  putHrEmployeeEmergencyContacts,
  putHrEmployeeStatus,
  removeEmpCustomField,
  removeHrEmployeeCompanyProperty,
  removeHrEmployeeEmergencyContact,
  storeEmpCustomFields,
  storeHrEmployeeCompanyProperty,
  storeHrEmployeeEmergencyContacts,
  storeHrEmployeeStatus,
} from '../slices/hr.slice';
interface Actions<Type> {
  type: string;
  payload: Type;
}

const loadHrEmployeeStatus$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(loadHrEmployeeStatus.type),
    switchMap((action: { payload: string; }) => {
      return from(HrService.getHrEmployeeStatus(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HrEmployeeStatus) => { return storeHrEmployeeStatus(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const putHrEmployeeStatus$ = (action$: Observable<Actions<HrEmployeeStatus>>) => {
  return action$.pipe(
    ofType(putHrEmployeeStatus.type),
    switchMap((action: { payload: HrEmployeeStatus; }) => {
      return from(HrService.putHrEmployeeStatus(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HrEmployeeStatus>) => {
          return [
            storeHrEmployeeStatus(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getHrEmployeeEmergencyContacts$ = (
  action$: Observable<Actions<string>>,
) => {
  return action$.pipe(
    ofType(loadHrEmployeeEmergencyContacts.type),
    switchMap((action: { payload: string; }) => {
      return from(HrService.getHrEmployeeEmergencyContacts(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HrEmployeeEmergencyContact[]) => { return storeHrEmployeeEmergencyContacts(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postHrEmployeeEmergencyContacts$ = (
  action$: Observable<Actions<HrEmployeeEmergencyContact>>,
) => {
  return action$.pipe(
    ofType(postHrEmployeeEmergencyContacts.type),
    switchMap((action: { payload: HrEmployeeEmergencyContact; }) => {
      return from(
        HrService.postHrEmployeeEmergencyContacts(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            changeHrEmployeeEmergencyContact(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const putHrEmployeeEmergencyContacts$ = (
  action$: Observable<Actions<HrEmployeeEmergencyContact>>,
) => {
  return action$.pipe(
    ofType(putHrEmployeeEmergencyContacts.type),
    switchMap((action: { payload: HrEmployeeEmergencyContact; }) => {
      return from(HrService.putHrEmployeeEmergencyContacts(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            changeHrEmployeeEmergencyContact(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteHrEmployeeEmergencyContacts$ = (
  action$: Observable<Actions<HrEmployeeStatusEmergencyContactIdRequest>>,
) => {
  return action$.pipe(
    ofType(deleteHrEmployeeEmergencyContacts.type),
    switchMap((action: { payload: HrEmployeeStatusEmergencyContactIdRequest; }) => {
      return from(
        HrService.deleteHrEmployeeEmergencyContacts(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          return [
            removeHrEmployeeEmergencyContact(action.payload.contactId),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getHrEmpCustomFields$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(loadEmpCustomFields.type),
    switchMap((action: { payload: string; }) => {
      return from(HrService.getHrEmpCustomFields(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeEmpCustomFields(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postHrEmpCustomFields$ = (
  action$: Observable<Actions<HrEmployeeCustomFields>>,
) => {
  return action$.pipe(
    ofType(postEmpCustomField.type),
    switchMap((action: { payload: HrEmployeeCustomFields; }) => {
      return from(HrService.postHrEmpCustomField(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            changeEmpCustomField(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const putHrEmpCustomFields$ = (
  action$: Observable<Actions<HrEmployeeCustomFields>>,
) => {
  return action$.pipe(
    ofType(putEmpCustomField.type),
    switchMap((action: { payload: HrEmployeeCustomFields; }) => {
      return from(HrService.putHrEmpCustomField(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            changeEmpCustomField(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteHrEmpCustomFields$ = (
  action$: Observable<Actions<EmpCustomFieldRequest>>,
) => {
  return action$.pipe(
    ofType(deleteEmpCustomField.type),
    switchMap((action: { payload: EmpCustomFieldRequest; }) => {
      return from(HrService.deleteHrEmpCustomFields(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            removeEmpCustomField(action.payload.empCustomFieldsId),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getHrEmpCompanyProperty$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(loadHrEmployeeCompanyProperty.type),
    switchMap((action: { payload: string; }) => {
      return from(HrService.getHrEmployeeCompanyProperty(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeHrEmployeeCompanyProperty(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postHrEmpCompanyProperty$ = (
  action$: Observable<Actions<HrEmployeeCompanyIssuedPropertyRequest>>,
) => {
  return action$.pipe(
    ofType(postHrEmployeeCompanyProperty.type),
    switchMap((action: { payload: HrEmployeeCompanyIssuedPropertyRequest; }) => {
      return from(HrService.postHrEmployeeCompanyProperty(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            changeHrEmployeeCompanyProperty(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const putHrEmpCompanyProperty$ = (
  action$: Observable<Actions<HrEmployeeCompanyIssuedPropertyRequest>>,
) => {
  return action$.pipe(
    ofType(putHrEmployeeCompanyProperty.type),
    switchMap((action: { payload: HrEmployeeCompanyIssuedPropertyRequest; }) => {
      return from(HrService.putHrEmployeeCompanyProperty(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            changeHrEmployeeCompanyProperty(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteHrEmpCompanyProperty$ = (
  action$: Observable<Actions<HrEmployeeCompanyIssuedPropertyRequest>>,
) => {
  return action$.pipe(
    ofType(deleteHrEmployeeCompanyProperty.type),
    switchMap((action: { payload: HrEmployeeCompanyIssuedPropertyRequest; }) => {
      return from(
        HrService.deleteHrEmployeeCompanyProperty(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            removeHrEmployeeCompanyProperty(action.payload.propertyId),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadHrEmployeeStatus$,
  putHrEmployeeStatus$,
  getHrEmployeeEmergencyContacts$,
  postHrEmployeeEmergencyContacts$,
  putHrEmployeeEmergencyContacts$,
  deleteHrEmployeeEmergencyContacts$,
  getHrEmpCustomFields$,
  postHrEmpCustomFields$,
  putHrEmpCustomFields$,
  deleteHrEmpCustomFields$,
  getHrEmpCompanyProperty$,
  deleteHrEmpCompanyProperty$,
  postHrEmpCompanyProperty$,
  putHrEmpCompanyProperty$,
];
