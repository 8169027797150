/* eslint-disable react/prop-types */
import React from 'react';
import adjustmentStyles from './styles.module.scss';
import timeCardStyles from '../time-card-styles.module.scss';
import { formatWithCommas } from 'utilities/utilities';

const arr = [0, 1, 2];

type Props = {
};

const LocalWhTotalsTable = ({ }: Props) => {
  return (
    <div className={`${adjustmentStyles['totals-table-container']} dm-panel dm-panel-border p-2`}>
      <div className="d-flex justify-content-between">
        <div className={timeCardStyles['section-title-blue']}>Local W/H</div>
        <div className="font-weight-bold">Total W/H:&nbsp;{formatWithCommas(241.5)}</div>
      </div>
      <table className={adjustmentStyles['simple-table']}>
        <thead>
          <tr>
            <th>Tax Entity</th>
            <th>W/H</th>
            <th>ER Tax</th>
            <th>Gross</th>
          </tr>
        </thead>
        <tbody>
          {arr.map((x) => {
            return (
              <tr key={x}>
                <td>227 - Detroit</td>
                <td className={adjustmentStyles['cell-right']}>{formatWithCommas(80.5)}</td>
                <td className={adjustmentStyles['cell-right']}>{formatWithCommas(0.25)}</td>
                <td className={adjustmentStyles['cell-right']}>{formatWithCommas(10802.25)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LocalWhTotalsTable;
