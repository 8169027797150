
import { AddTimeCardRequest, CostCodeInfoRequest, TimeCardRequest, JobInfoFromFileRequest, JobInfoRequest, JobUploadResponse, PayrollTimeCardCostCodeInfo, PayrollTimeCardDiagnosticRules, PayrollTimeCardJobInfo, PayrollTimeCardSubInfo, SubInfoRequest, TaxingCity, TimeCard, UpdateTimeCardRequest, TimeCardsByDayData, TimeCardJobRoster, TimeCardDate, AddUpdateTimeCardsRequest, AtsRequest, HomeAtsInfo, TimeCardCrewSheetColumns } from 'core/models/Contractor.model';
import { API_CLIENT, API_EMPLOYEE_MASTER } from './API';
import { HttpResponse } from 'core/models';
import { ContractorEndpoints } from './URL';
import { CommonService } from './common.service';
import { PayrollControlTotalDetail, PayrollControlTotalRequest } from 'core/models/PayrollControlTotalDetail';

const getAllJobs = () => {
  return API_EMPLOYEE_MASTER.get<HttpResponse<PayrollTimeCardJobInfo[]>>(
    ContractorEndpoints.GET_ALL_JOBS_INFO(CommonService.getProtectedClientNo()),
  );
};

const getJobInfo = (request: JobInfoRequest) => {
  return API_EMPLOYEE_MASTER.get<HttpResponse<PayrollTimeCardJobInfo>>(
    ContractorEndpoints.GET_JOB_INFO(CommonService.getProtectedClientNo(), request.jobNo));
};

const addUpdateJobInfo = (request: PayrollTimeCardJobInfo) => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<PayrollTimeCardJobInfo>>(
    ContractorEndpoints.ADD_UPDATE_JOB_INFO(CommonService.getProtectedClientNo(), request.jobNo), request);
};

const addJobInfoFromFile = (request: JobInfoFromFileRequest) => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<JobUploadResponse>>(
    ContractorEndpoints.ADD_JOB_INFO_FROM_FILE(CommonService.getProtectedClientNo()), request);
};

const deleteJobInfo = (request: JobInfoRequest) => {
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    ContractorEndpoints.DELETE_JOB_INFO(CommonService.getProtectedClientNo(), request.jobNo));
};

const getAllSubs = () => {
  return API_EMPLOYEE_MASTER.get<PayrollTimeCardSubInfo[]>(
    ContractorEndpoints.GET_ALL_SUBS_INFO(CommonService.getProtectedClientNo()),
  );
};

const getSubInfo = (request: SubInfoRequest) => {
  return API_EMPLOYEE_MASTER.get<PayrollTimeCardSubInfo>(
    ContractorEndpoints.GET_SUB_INFO(CommonService.getProtectedClientNo(), request.subNo));
};

const addUpdateSubInfo = (request: PayrollTimeCardSubInfo) => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<PayrollTimeCardSubInfo>>(
    ContractorEndpoints.ADD_UPDATE_SUB_INFO(CommonService.getProtectedClientNo(), request.subNo), request);
};

const deleteSubInfo = (request: SubInfoRequest) => {
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    ContractorEndpoints.DELETE_SUB_INFO(CommonService.getProtectedClientNo(), request.subNo));
};

const getAllCostCodes = () => {
  return API_EMPLOYEE_MASTER.get<PayrollTimeCardCostCodeInfo[]>(
    ContractorEndpoints.GET_ALL_COST_CODES(CommonService.getProtectedClientNo()),
  );
};

const getCostCodeInfo = (request: CostCodeInfoRequest) => {
  return API_EMPLOYEE_MASTER.get<PayrollTimeCardCostCodeInfo>(
    ContractorEndpoints.GET_COST_CODE_INFO(CommonService.getProtectedClientNo(), request.costCode));
};

const addUpdateCostCodeInfo = (request: PayrollTimeCardCostCodeInfo) => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<PayrollTimeCardCostCodeInfo>>(
    ContractorEndpoints.ADD_UPDATE_COST_CODE_INFO(CommonService.getProtectedClientNo(), request.costCode), request);
};

const deleteCostCodeInfo = (request: CostCodeInfoRequest) => {
  return API_EMPLOYEE_MASTER.delete<HttpResponse<string>>(
    ContractorEndpoints.DELETE_COST_CODE_INFO(CommonService.getProtectedClientNo(), request.costCode));
};

const getDiagnosticRule = () => {
  return API_EMPLOYEE_MASTER.get<HttpResponse<string>>(
    ContractorEndpoints.GET_DIAGNOSTIC_RULE(CommonService.getProtectedClientNo()),
  );
};

const addUpdateDiagnosticRule = (params: PayrollTimeCardDiagnosticRules) => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<PayrollTimeCardDiagnosticRules>>(
    ContractorEndpoints.POST_DIAGNOSTIC_RULE(CommonService.getProtectedClientNo()),
    params,
  );
};

const getTaxingCities = () => {
  return API_CLIENT.get<HttpResponse<TaxingCity[]>>(
    ContractorEndpoints.GET_LOCAL_TAX_ENTITIES(
      CommonService.getProtectedClientNo(),
      // Might need to change these eventually
      true,
      true,
    ),
  );
};

const getAllTimeCards = (payrollHistoryId: number) => {
  return API_EMPLOYEE_MASTER.get<TimeCard[]>(
    ContractorEndpoints.GET_ALL_TIME_CARDS(CommonService.getProtectedClientNo(), payrollHistoryId),
  );
};

const getTimeCard = ({ payrollHistoryId, transmittalTimeCardId }: TimeCardRequest) => {
  return API_EMPLOYEE_MASTER.get<TimeCard[]>(
    ContractorEndpoints.GET_TIME_CARD(CommonService.getProtectedClientNo(), payrollHistoryId, transmittalTimeCardId),
  );
};

const getTimeCardsByDay = (payrollHistoryId: number) => {
  return API_EMPLOYEE_MASTER.get<TimeCardsByDayData[]>(
    ContractorEndpoints.GET_TIME_CARDS_BY_DAY(CommonService.getProtectedClientNo(), payrollHistoryId),
  );
};

const getTimeCardDates = (weekEnd: string | Date) => {
  return API_EMPLOYEE_MASTER.get<TimeCardDate[]>(
    ContractorEndpoints.GET_TIME_CARD_DATES(CommonService.getProtectedClientNo(), weekEnd),
  );
};

const getTimeCardsByJobRoster = (payrollHistoryId: number) => {
  return API_EMPLOYEE_MASTER.get<TimeCardJobRoster[]>(
    ContractorEndpoints.GET_TIME_CARDS_BY_JobRoster(CommonService.getProtectedClientNo(), payrollHistoryId),
  );
};

const addTimeCard = ({ payrollHistoryId, empNo, nextCheckCode }: AddTimeCardRequest) => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<TimeCard>>(
    ContractorEndpoints.ADD_TIME_CARD(CommonService.getProtectedClientNo(), payrollHistoryId, empNo, nextCheckCode),
  );
};

const updateTimeCard = ({ payrollHistoryId, empNo, transmittalTimeCardId, data }: UpdateTimeCardRequest) => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<TimeCard>>(
    ContractorEndpoints.UPDATE_TIME_CARD(CommonService.getProtectedClientNo(), payrollHistoryId, empNo, transmittalTimeCardId),
    data,
  );
};

const addUpdateTimeCards = (request: AddUpdateTimeCardsRequest) => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<TimeCard[]>>(
    ContractorEndpoints.ADD_UPDATE_TIME_CARDS(CommonService.getProtectedClientNo(), request.payrollHistoryId ),
    request.data,
  );
};

const deleteTimeCard = ({ payrollHistoryId, transmittalTimeCardId }: TimeCardRequest) => {
  return API_EMPLOYEE_MASTER.delete(
    ContractorEndpoints.DELETE_TIME_CARD(CommonService.getProtectedClientNo(), payrollHistoryId, transmittalTimeCardId),
  );
};

const getTimeCardCrewSheetColumns = () => {
  return API_EMPLOYEE_MASTER.get<TimeCardCrewSheetColumns>(
    ContractorEndpoints.GET_TIME_CARD_CREW_SHEET_COLUMNS(CommonService.getProtectedClientNo()),
  );
};

const getControlTotalTimeCardDetails = (request: PayrollControlTotalRequest) => {
  return API_EMPLOYEE_MASTER.get<PayrollControlTotalDetail[]>(
    ContractorEndpoints.GET_TIME_CARD_CONTROL_TOTAL_DETAILS(CommonService.getProtectedClientNo(), request),
  );
};

const addUpdateTimeCardCrewSheetColumns = (data: TimeCardCrewSheetColumns) => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<TimeCardCrewSheetColumns>>(
    ContractorEndpoints.ADD_UPDATE_TIME_CARD_CREW_SHEET_COLUMNS(CommonService.getProtectedClientNo()),
    data,
  );
};

const getHomeAtsInfo = ({ protectedEmpNo, effectiveDate }: AtsRequest) => {
  return API_EMPLOYEE_MASTER.get<HomeAtsInfo>(
    ContractorEndpoints.GET_HOME_ATS_INFO(CommonService.getProtectedClientNo(), protectedEmpNo, effectiveDate),
  );
};

type TestData = {
  data: {
    adjustmentId: number;
  }
};

const addTimeCardAdjustment = (): Promise<TestData> => {
  return new Promise((resolve, _) => {
    setTimeout(() => {
      resolve({
        data: {
          adjustmentId: 123,
        },
      });
    });
  });
};

export const ContractorService = {
  getAllJobs,
  getJobInfo,
  addUpdateJobInfo,
  addJobInfoFromFile,
  deleteJobInfo,
  getAllSubs,
  getSubInfo,
  addUpdateSubInfo,
  deleteSubInfo,
  getAllCostCodes,
  getCostCodeInfo,
  addUpdateCostCodeInfo,
  deleteCostCodeInfo,
  getDiagnosticRule,
  addUpdateDiagnosticRule,
  getTaxingCities,
  getAllTimeCards,
  getTimeCard,
  getTimeCardsByDay,
  getTimeCardDates,
  getTimeCardsByJobRoster,
  addTimeCard,
  updateTimeCard,
  addUpdateTimeCards,
  deleteTimeCard,
  getHomeAtsInfo,
  getTimeCardCrewSheetColumns,
  addUpdateTimeCardCrewSheetColumns,
  getControlTotalTimeCardDetails,
  addTimeCardAdjustment,
};