import {
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { SelectGrpInLine } from '../../../core/components/form-controls';
import { PayrollHistoryDeductionStatus } from '../../../core/models/PayrollHistoryDeductionStatus';
import {
  loadPayrollHistoryDeductionStatuses,
  updatePayrollHistoryDeductionStatuses,
} from '../../../core/store/actions';
import { agSelectEditor } from '../../../utilities/ag-grid-editors';
import { agLookupRenderer } from '../../../utilities/ag-grid-renderers';
import { useAppSelector } from '../../../utilities/hooks';

const statuses = [
  {
    id: '',
    description: 'Active',
  },
  {
    id: 'S',
    description: 'S - Skip Once',
  },
  {
    id: 'D',
    description: 'D - Deactivate',
  },
  {
    id: 'P',
    description: 'P - Prenote',
  },
];

type PropTypes = {
  payrollHistoryId: number;
  show: boolean;
  onHide: () => void;
};
const DeductionStatusModal: React.FC<PropTypes> = (props: PropTypes) => {
  const dispatch = useDispatch();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const [rowData, setRowData] = useState<PayrollHistoryDeductionStatus[]>([]);

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: 'Deduction',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data.dedNo + ' - ' + params.data.description;
      },
    },
    {
      headerName: 'Status',
      editable: true,
      field: 'status',
      cellRenderer: agLookupRenderer,
      cellRendererParams: { options: statuses },
      cellEditor: 'agSelectEditor',
      cellEditorParams: { options: statuses },
    },
  ]);

  const [changed, setChanged] = useState(false);

  const payrollHistoryDeductionStatus = useAppSelector(
    (state) => { return state.payroll.payrollHistoryDeductionStatuses; },
  );

  useEffect(() => {
    dispatch(loadPayrollHistoryDeductionStatuses(props.payrollHistoryId));
  }, []);

  useEffect(() => {
    if (payrollHistoryDeductionStatus) {
      setRowData(
        payrollHistoryDeductionStatus.map((a) => {
          return { ...a };
        }),
      );
    }
  }, [payrollHistoryDeductionStatus]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const onSave = () => {
    const data: PayrollHistoryDeductionStatus[] = [];
    gridApi?.forEachNode((node) => { return data.push(node.data); });
    dispatch(
      updatePayrollHistoryDeductionStatuses({
        params: props.payrollHistoryId,
        data,
      }),
    );
    setChanged(false);
  };

  const closeModal = (e?: any) => {
    if (changed) {
      if (
        !window.confirm(
          'You have made changes, are you sure you want to close?',
        )
      ) {
        return false;
      }
    }
    e?.stopPropagation();
    props.onHide();
  };

  const onFirstDataRendered = (ev: FirstDataRenderedEvent) => {
    ev.api.sizeColumnsToFit();
  };

  const onCellValueChanged = () => {
    setChanged(true);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="sm"
      animation={false}
    >
      <div className="modal-header">
        <div className="dm-card-title">Deduction this P/R: Status</div>
        <button
            type="button"
            onClick={closeModal}
            className="btn-link-modal"
          >
            <Icon
              name="times"
              className="modal-close-btn"
            />
          </button>
      </div>
      <Modal.Body>
        <div className="row">
          <div
            className="ag-theme-balham col-7"
            style={{ height: '100%' }}
          >
            <SelectGrpInLine
              label="Apply Status to ALL Deductions"
              options={statuses}
              addEmptyValue={true}
              onChange={(
                e: React.ChangeEvent<HTMLSelectElement>,
              ) => {
                if (e.target.selectedIndex === 0) {
                  return;
                }
                if (gridApi) {
                  gridApi.forEachNode((node) => {
                    node.setDataValue(
                      'status',
                      e.target.value,
                    );
                  });
                }
              }}
            />
            <AgGridReact
              stopEditingWhenCellsLoseFocus={true}
              defaultColDef={{
                suppressMenu: true,
                resizable: true,
              }}
              columnDefs={columnDefs}
              singleClickEdit={true}
              rowData={rowData}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              onCellValueChanged={onCellValueChanged}
              components={{ agSelectEditor }}
            ></AgGridReact>
          </div>
          <div className="col-auto">
            <button
              type="button"
              className="btn btn-primary orange-outline-button mr-2"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary orange-button"
              onClick={onSave}
              disabled={!changed}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeductionStatusModal;
