import { AxiosResponse } from 'axios';
import { IntegrationLog, IntegrationLogDetail, IntegrationLogDetailRequest } from '../models/IntegrationLog.model';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { IntegrationEndpoints } from './URL';

const getClientIntegrationLogs = (): Promise<AxiosResponse<IntegrationLog[]>> => { return API_EMPLOYEE_MASTER.get<IntegrationLog[]>(IntegrationEndpoints.GET_CLIENT_INTEGRATION_LOGS(CommonService.getProtectedClientNo())); };
const getClientIntegrationLogDetails = (request: IntegrationLogDetailRequest): Promise<AxiosResponse<IntegrationLogDetail[]>> => { return API_EMPLOYEE_MASTER.get<IntegrationLogDetail[]>(IntegrationEndpoints.GET_CLIENT_INTEGRATION_LOG_Details(CommonService.getProtectedClientNo(), request)); };

export const IntegrationService = {
    getClientIntegrationLogs,
    getClientIntegrationLogDetails
};
