//How many pays in each pay period
const salaryPayIntervals = {
  W: 52,
  B: 26,
  S: 24,
  M: 12,
};

export const convertRate = (rate: string | number): number => {
  if (typeof rate === 'number') return rate;
  return parseFloat(rate.replace(/[^0-9.]/g, ''));
};

//clientSalaryPayInter
export const calculateOneHourSalary = (clientOneHourRate: string, clientPayFrequency: string, currentInterval: number): number => {
  const oneHourValue = +clientOneHourRate;
  const clientSalaryPayInterval = salaryPayIntervals[clientPayFrequency.charAt(0) as keyof typeof salaryPayIntervals];
 
  return ((oneHourValue * clientSalaryPayInterval) / currentInterval);
};

export const convertPayRate = (
  baseRate: string | number,
  baseRateType: 'hourlyRate' | 'salaryRate' | 'annualRate',
  convertRateTo: 'hourlyRate' | 'salaryRate' | 'annualRate',
  clientOneHourRate: string,
  clientPayFrequency: string,
  employeePayFrequency: string,
) => {
  const convertedEmpPayFrequency = (employeePayFrequency?.trim() ?? '') === '' ? clientPayFrequency.charAt(0) : employeePayFrequency?.trim();

  const currentInterval = salaryPayIntervals[(convertedEmpPayFrequency) as keyof typeof salaryPayIntervals];
  const oneHourSalary = calculateOneHourSalary(clientOneHourRate, clientPayFrequency, currentInterval);

  let hourlyRate = convertRate(baseRate);
  if (baseRateType === 'salaryRate') {
    hourlyRate = hourlyRate / oneHourSalary;
  } else if (baseRateType === 'annualRate') {
    hourlyRate = hourlyRate / oneHourSalary / currentInterval;
  }

  if (convertRateTo === 'salaryRate') return hourlyRate * oneHourSalary;
  if (convertRateTo === 'annualRate') return hourlyRate * oneHourSalary * currentInterval;

  return hourlyRate;
};