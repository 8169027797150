import { createReducer } from '@reduxjs/toolkit';
import { ApClientVendor } from '../../models';
import { loadApClientVendors, storeApClientVendor, storeApClientVendors } from '../actions';

export interface State {
  loading: boolean;
  apClientVendors: ApClientVendor[];
}

export const INITIAL_STATE: State = {
  loading: false,
  apClientVendors: [],
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadApClientVendors, (state) => {
      state.loading = true;
    })
    .addCase(storeApClientVendors, (state, action) => {
      state.loading = false;
      state.apClientVendors = action.payload;
    })
    .addCase(storeApClientVendor, (state, action) => {
      state.loading = false;
      
      if (action.payload.isNewVendor) {
        const restVendors = state.apClientVendors?.filter((vendor: ApClientVendor) => {
          return vendor.apVendorId !== action.payload.vendor.apVendorId;
        });
        state.apClientVendors = [action.payload.vendor, ...restVendors];
      } else {
        const matchIndex = state.apClientVendors.findIndex((vendor) => {return vendor.apVendorId === action.payload.vendor.apVendorId;});
        if (matchIndex < 0) return console.error('Error updating AP vendor in state');
        state.apClientVendors.splice(matchIndex, 1, action.payload.vendor);
      }
    });
});