import React, { useEffect, useMemo, useState } from 'react';
import InputDateSelector from 'core/components/form-controls/InputDateSelector';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { FileTypes, removeAreasSelected, reset, setAreaSelected, setAreaTradeSubValue, setAreaTradeValue, setAreaValue, setContractorFringeDownload, setEmployeeFringeEarning, setEmployerFringeEarning, setFileType, setFringeEarning, setFullDetails, setHoursSelected, setIsAdding, setIsUpdating, setJobNumbers, setKey, setMapDescription, setPayRates, setSubNumbers, setWagesSelected } from 'core/store/slices/contractor-reports/fringe.slice';
import { createContractorFringeMap, deleteContractorFringeMap, downloadContractorFringeData, getContractorFringeData, updateContractorFringeMap } from 'core/store/actions/ contractor-reports/fringe.action';
import Select, { GroupBase, StylesConfig } from 'react-select';
// import { RadioGrp } from 'core/components/form-controls';
import { useForm } from 'react-hook-form';
import { IAreaMap } from 'interfaces/IAreaMap';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridOptions, MasterDetailModule } from '@ag-grid-enterprise/all-modules';
import { agDateRenderer } from 'utilities/ag-grid-renderers';
import { Files } from 'enums/Files';
import { IOption } from 'interfaces/IOption';
// @ts-ignore
import { Spinner } from 'react-bootstrap';
import { sortBy } from 'lodash';

type StylesConfigType = StylesConfig<any, boolean, GroupBase<unknown>> | undefined;

const createOptions = (arr: IAreaMap[] = []) => {
  return arr?.map((item: IAreaMap) => {
    return {
      value: item.code,
      label: item.description || item.code,
    };
  });
};

const compStyles: StylesConfigType = {
  control: (base) => {
    return {
      ...base,
      minWidth: 350,
      width: 350,
      borderRadius: '0.25rem',
      fontSize: '12px',
      color: 'black',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#0074D9',
      },
    };
  },
  valueContainer: (base) => {
    return {
      ...base,
      padding: '0 4px',
      top: '0',
      position: 'relative',
      overflow: 'hidden',
      width: 'fit-content',
      color: 'black',
      boxSizing: 'border-box',
    };
  },
  indicatorSeparator: (base) => {
    return {
      ...base,
      display: 'none',
    };
  },
  clearIndicator: (base) => {
    return {
      ...base,
      padding: '0px',
      color: '#d6d6d6',
      '&:hover': {
        color: 'black',
      },
    };
  },
  dropdownIndicator: (base) => {
    return {
      ...base,
      padding: '0px 4px',
      color: '#d6d6d6',
      '&:hover': {
        color: 'black',
      },
    };
  },
  menuPortal: (base) => {
    return {
      ...base,
      zIndex: 9999,
      width: 'fit-content',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 10px 0px',
      background: 'rgb(255, 255, 255)',
      cursor: 'pointer',
    };
  },
};

interface IHoursWagesSelect {
  options: IOption[];
  value: IOption[];
  onChange: (item: any) => void;
  disabled: boolean;
}

const HoursWagesSelect = ({
  options,
  value,
  onChange,
  disabled,
}: IHoursWagesSelect) => {
  const components = {
    Option: (props: any) => {
      const { innerProps, innerRef } = props;

      return (
        <div
          ref={innerRef}
          className="px-2 py-1"
        >
          <div className="d-flex align-items-center">
            <span
              style={{
                width: 100,
              }}
            >
              {props.data.value}
            </span>
            <span
              style={{
                width: 180,
              }}
            >
              {props.data.label}
            </span>
            <input
              type="checkbox"
              {...innerProps}
              defaultChecked={!!value?.find(selectedItem => { return selectedItem.value === props.data.value; })}
            />
          </div>
        </div>
      );
    },
    GroupHeading: () => {
      return (
        <div className="px-2 py-1">
          <div className="d-flex align-items-center">
            <span
              style={{
                width: 100,
              }}
            >
              Earnings
            </span>
            <span
              style={{
                width: 180,
              }}
            >
              Description
            </span>
            <span>Select</span>
          </div>
        </div>
      );
    },
  };

  const sortedOptions = sortBy(options, ['value']);
  const parsedOptions = useMemo(() => {
    if (sortedOptions.find(item => { return item.value === '0'; })) {
      return [...sortedOptions.slice(1), sortedOptions[0]];
    }

    return sortedOptions;
  }, [sortedOptions]);


  return (
    <Select
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isClearable={false}
      isMulti
      styles={compStyles}
      value={value}
      onChange={onChange}
      components={components}
      options={[{
        options: parsedOptions,
      }]}
      isDisabled={disabled}
    />
  );
};

interface INormalSelect {
  isMulti?: boolean;
  options: any;
  value: IOption[];
  onChange: (item: any) => void;
  disabled: boolean;
}

const NormalSelect = ({
  isMulti = false,
  options,
  value,
  onChange,
  disabled,
}: INormalSelect) => {
  return (
    <Select
      closeMenuOnSelect={!isMulti}
      isClearable={false}
      isMulti={isMulti}
      styles={compStyles}
      value={value}
      onChange={onChange}
      options={options}
      isDisabled={disabled}
    />
  );
};

interface ISelectWithAll {
  isMulti?: boolean;
  options: IAreaMap[];
  selected: IOption[];
  fn: any,
  CustomOption?: any;
  GroupHeading?: any;
  disabled?: boolean;
}

const SelectWithAll = ({
  selected,
  fn,
  options,
  disabled,
  CustomOption,
  GroupHeading,
  isMulti = false,
}: ISelectWithAll) => {
  const dispatch = useAppDispatch();

  const components: any = {};

  if (CustomOption) {
    components.Option = (props: any) => {
      return CustomOption(props, selected);
    };
  }

  if (GroupHeading) {
    components.GroupHeading = GroupHeading;
  }

  return (
    <Select
      isMulti={isMulti}
      styles={compStyles}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      value={selected}
      components={Object.keys(components).length ? components : undefined}
      options={[{
        options: createOptions(options),
      }]}
      onChange={(values) => {
        if (!values || values?.[values.length - 1]?.value === '<All>') {
          dispatch(
            fn([{
              label: '<All>',
              value: '<All>',
            }]),
          );
          return;
        }


        if (values.length) {
          dispatch(
            fn(values.filter((item: any) => { return item.value !== '<All>'; })),
          );
          return;
        }

        dispatch(removeAreasSelected());
      }}
      isDisabled={disabled}
    />
  );
};

interface IWithCodeSelect {
  isMulti?: boolean;
  options: (IOption)[];
  selected: IOption[];
  fn: any,
  CustomOption?: any;
  GroupHeading?: any;
  disabled?: boolean;
}

const WithCodeSelect = ({
  selected,
  fn,
  options,
  disabled,
  CustomOption,
  GroupHeading,
  isMulti = false,
}: IWithCodeSelect) => {
  const dispatch = useAppDispatch();

  const components: any = {};

  if (CustomOption) {
    components.Option = (props: any) => {
      return CustomOption(props, selected);
    };
  }

  if (GroupHeading) {
    components.GroupHeading = GroupHeading;
  }

  return (
    <Select
      isMulti={isMulti}
      styles={compStyles}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      value={selected}
      components={Object.keys(components).length ? components : undefined}
      options={[{
        options,
      }]}
      onChange={(values) => {
        if (values?.[values.length - 1]?.value === '<All>') {
          dispatch(
            fn([{
              label: '<All>',
              value: '<All>',
            }]),
          );
          return;
        }

        dispatch(
          fn(values?.length ?
            values.filter((item: any) => { return item.value !== '<All>'; }) :
            [],
          ),
        );
      }}
      isDisabled={disabled}
    />
  );
};

const EditRenderer = (props: any) => {
  const dispatch = useAppDispatch();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onEdit = () => {
    dispatch(setIsUpdating(true));
    dispatch(setFullDetails(props?.data));
  };

  const onDelete = async () => {
    try {
      setIsDeleting(true);
      await dispatch(deleteContractorFringeMap(props?.data?.mapKey));
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center p-1"
      style={{ columnGap: '0.25rem' }}
    >
      <button
        className="btn btn-xs btn-primary"
        onClick={onEdit}
        type="button"
        disabled={isDeleting}
      >
        Edit
      </button>
      <button
        className="btn btn-xs btn-danger"
        onClick={onDelete}
        type="button"
        disabled={isDeleting}
      >
        Delete
        {isDeleting && (
          <Spinner
            animation="border"
            variant="light"
            size="sm"
            className="ml-1"
          />
        )}
      </button>
    </div>
  );
};

const DownloadRenderer = (props: any) => {
  const dispatch = useAppDispatch();
  const [fileFormat, setFileFormat] = useState<FileTypes | null>(null);

  const onDownload = async (type: FileTypes) => {

    dispatch(setFileType(type));
    setFileFormat(type);


    await dispatch(downloadContractorFringeData({
      ...props.data,
      fileFormat: type,
    }));

    setFileFormat(null);
  };


  return (
    <div
      className="d-flex align-items-center justify-content-center p-1"
      style={{ columnGap: '0.25rem' }}
    >
      <button
        className="btn btn-xs btn-light"
        onClick={() => {
          onDownload(Files.XLS);
        }}
        type="button"
      >
        Excel
        {fileFormat === Files.XLS && (
          <Spinner
            animation="border"
            variant="dark"
            size="sm"
            className="ml-1"
          />
        )}
      </button>
      <button
        className="btn btn-xs btn-light"
        onClick={() => {
          onDownload(Files.CSV);
        }}
        type="button"
      >
        CSV
        {fileFormat === Files.CSV && (
          <Spinner
            animation="border"
            variant="dark"
            size="sm"
            className="ml-1"
          />
        )}
      </button>
    </div>
  );
};

const ContractorFringeDownload = () => {
  const dispatch = useAppDispatch();

  const {
    isAdding,
    isUpdating,
    data,
    hoursSelected,
    hours,
    hoursDetail,
    hoursTotal,
    wagesSelected,
    wages,
    wagesDetail,
    wagesTotal,
    areaSelected,
    areaValue,
    areaTradeValue,
    areaTradeSubValue,
    subNumbers,
    jobNumbers,
    payRates,
    fringeEarning,
    employeeFringeEarning,
    employerFringeEarning,
    mapDescription,
    fringeEarningsSelected,
    fringeEarningsDetail,
    fringeEarningsTotal,
    showDescription,
    employeeFringeEarningSelected,
    employeeFringeEarningTotal,
    employeeFringeEarningDetail,
    employerFringeEarningSelected,
    employerFringeEarningTotal,
    employerFringeEarningDetail,
    employerPaidTaxes,
    employerPaidTaxesTotal,
    employerPaidTaxesDetail,
    projectNumber,
    phase,
    foExtra,
    costCode,
    payRatesSelected,
    checkDate,
    dayWorked,
    weekEndingDate,
    employeeSubTotals,
    jobName,
    city,
    country,
    state,
    jobSubTotals,
    isAddUpdateSending,
  } = useAppSelector((rootState) => {
    return rootState.contractorFringeDownload;
  });

  const createAreaOption = (type = 'areas') => {
    // eslint-disable-next-line react/display-name
    return (props: any, values: IOption[]) => {
      const { innerProps, innerRef } = props;
      const { value } = props.data;
      const itemValues = value.split('/');

      return (
        <div
          ref={innerRef}
          className="px-2 py-1"
        >
          <div className="d-flex">
            <span
              style={{
                width: 50,
              }}
            >
              {itemValues?.[0] !== '<All>' && itemValues?.[0]}
            </span>
            {(type === 'areaTrades' || type === 'areaTradeSubs') && (
              <span
                style={{
                  width: 50,
                }}
              >
                {itemValues?.[1] !== '<All>' && itemValues?.[1]}
              </span>
            )}
            {type === 'areaTradeSubs' && (
              <span
                style={{
                  width: 50,
                }}
              >
                {itemValues?.[2] !== '<All>' && itemValues?.[2]}
              </span>
            )}
            <span className="flex-grow-1">{props.data.label}</span>
            <input
              type="checkbox"
              {...innerProps}
              defaultChecked={!!values.find(selectedItem => { return selectedItem.value === props.data.value; })}
            />
          </div>
        </div>
      );
    };
  };

  const createAreaGroupHeading = (type = 'areas') => {
    // eslint-disable-next-line react/display-name
    return () => {
      return (
        <div
          className="px-2 py-1"
          style={{
            background: '#eaeaea',
          }}
        >
          <div className="d-flex align-items-center">
            <span
              style={{
                width: 50,
              }}
            >
              Area
            </span>
            {(type === 'areaTrades' || type === 'areaTradeSubs') && (
              <span
                style={{
                  width: 50,
                }}
              >
                Trade
              </span>
            )}
            {type === 'areaTradeSubs' && (
              <span
                style={{
                  width: 50,
                }}
              >
                Sub
              </span>
            )}
            <span className="flex-grow-1">Description</span>
            <span>Selected</span>
          </div>
        </div>
      );
    };
  };

  const createGenericOptions = (columns: { key: string; width: number; }[] = []) => {
    // eslint-disable-next-line react/display-name
    return (props: any, values: IOption[]) => {
      const { innerProps, innerRef } = props;

      return (
        <div
          ref={innerRef}
          className="px-2 py-1"
        >
          <div className="d-flex align-items-center">
            {columns.map(item => {
              return (
                <span
                  key={item.key}
                  style={{
                    width: item.width,
                  }}
                >
                  {props.data[item?.key]}
                </span>
              );
            })}
            <input
              type="checkbox"
              {...innerProps}
              defaultChecked={!!values.find(selectedItem => { return selectedItem.value === props.data.value; })}
            />
          </div>
        </div>
      );
    };
  };

  const createGenericGroupHeading = (columns: { label: string; width: number; }[] = []) => {
    // eslint-disable-next-line react/display-name
    return () => {
      return (
        <div
          className="px-2 py-1"
          style={{
            background: '#eaeaea',
          }}
        >
          <div className="d-flex align-items-center">
            {columns.map(item => {
              return (
                <span
                  key={item.label}
                  style={{
                    width: item.width,
                  }}
                >
                  {item.label}
                </span>
              );
            })}
          </div>
        </div>
      );
    };
  };

  const runReport = () => {
    dispatch(getContractorFringeData());
  };

  const { register, setValue, handleSubmit, clearErrors, formState: { errors } } = useForm();

  useEffect(() => {
    setTimeout(() => {
      setValue('mapName', mapDescription, { shouldValidate: true });
      clearErrors('mapName');
    }, 300);
  }, [mapDescription]);

  const onSubmit = () => {
    if (isAdding) {
      dispatch(createContractorFringeMap());
      return;
    }

    dispatch(updateContractorFringeMap());
  };

  const columns: ColDef[] = [
    {
      field: 'mapName',
      headerName: 'Map Description',
      flex: 1,
      sortable: true,
    },
    {
      headerName: 'Actions',
      headerClass: 'ag-center-aligned-header',
      width: 156,
      cellRenderer: 'EditRenderer',
    },
    {
      headerName: 'Download',
      headerClass: 'ag-center-aligned-header',
      width: 150,
      cellRenderer: 'DownloadRenderer',
    },
  ];

  const gridOptions: GridOptions = {
    columnDefs: columns,
    domLayout: 'autoHeight',
    defaultColDef: {
      autoHeight: true,
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    pagination: true,
    paginationPageSize: 10,
    // masterDetail: true,
    detailRowAutoHeight: true,
    detailCellRendererParams: {
      detailGridOptions: {
        defaultColDef: {
          autoHeight: true,
          suppressMenu: true,
          resizable: true,
          singleCickEdit: true,
          cellClass: 'ag-cell-left-border',
        },
      },
    },
    components: {
      dateRenderer: agDateRenderer,
      EditRenderer: EditRenderer,
      DownloadRenderer: DownloadRenderer,
    },
  };

  return (
    <form
      className="dm-panel dm-panel-border"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="dm-grid-title">Contractor Fringe Download</div>
        <div className="d-flex align-items-center">
          {!isAdding && !isUpdating ? (
            <button
              type="button"
              className="btn btn-primary ml-2"
              onClick={() => {
                dispatch(reset());
                dispatch(setIsAdding(true));
              }}
            >
              Create Map
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  dispatch(setIsAdding(false));
                  dispatch(setIsUpdating(false));
                }}
                disabled={isAddUpdateSending}
              >
                Cancel
              </button>
              <button
                className="btn btn-light ml-2"
                type="submit"
                disabled={isAddUpdateSending}
              >
                Save Map
                {isAddUpdateSending && (
                  <Spinner
                    animation="border"
                    variant="dark"
                    size="sm"
                    className="ml-1"
                  />
                )}
              </button>
            </>
          )}
        </div>
      </div>
      <hr className="dm-panel-hr" />
      {!isAdding && !isUpdating && (
        <>
          <div className="d-flex flex-row justify-content-between align-items-start flex-grow-1">
            <div className="d-flex flex-column align-items-start">
              <div className="d-flex align-items-center">
                <InputDateSelector
                  returnDates={async (dates: string[]) => {
                    await dispatch(setContractorFringeDownload(dates));
                    runReport();
                  }}
                  hideDateTypes={true}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 ag-theme-balham">
            <AgGridReact
              masterDetail
              gridOptions={gridOptions}
              rowData={data?.maps || []}
              overlayNoRowsTemplate={
                '<span>No Changes to Display</span>'
              }
              modules={[MasterDetailModule]}
            />
          </div>
        </>
      )}
      {(isAdding || isUpdating) && (
        <>
          <div
            className="mt-4 d-flex flex-column"
            style={{ rowGap: '0.75rem' }}
          >
            <div className="d-flex align-items-center">
              <div style={{ width: 12.99 }}></div>
              <label
                htmlFor="mapName"
                style={{
                  minWidth: 200,
                }}
                className="mb-0 ml-2"
              >
                Map Description
              </label>
              <input
                style={{
                  width: 350,
                  height: 38,
                  borderRadius: 4,
                  border: '1px solid hsl(0, 0%, 80%)',
                }}
                className="px-2"
                id="mapName"
                defaultValue={mapDescription}
                onChange={(e) => {
                  dispatch(setMapDescription(e.target.value));
                }}
                // @ts-ignore
                {...register('mapName', { required: 'Required' })}
              />
              {errors?.mapName?.message && (
                <small className="text-danger ml-2">{errors?.mapName?.message}</small>
              )}
            </div>
          </div>
          <div
            className="d-flex flex-wrap align-items-start"
            style={{ gap: '0.75rem' }}
          >
            <div
              className="mt-4 flex flex-column bg-light p-3 rounded"
              style={{ rowGap: '0.75rem' }}
            >
              <div
                className="d-flex flex-column"
                style={{ rowGap: '0.75rem' }}
              >
                <div className="d-flex align-items-center">
                  <p
                    style={{ minWidth: 583 }}
                    className="mb-0"
                  >
                    Select
                  </p>
                  <div />
                  <p className="mb-0">Total</p>
                  <p className="mb-0 ml-3">Detail</p>
                </div>
                <div className="d-flex align-items-center">
                  <input
                    id="hours"
                    type="checkbox"
                    name="hours"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setHoursSelected(e.target.checked));
                      dispatch(setKey({
                        key: 'hoursDetail',
                        value: e.target.checked,
                      }));

                      if (e.target.checked) {
                        return;
                      }

                      dispatch(setKey({
                        key: 'hours',
                        value: [],
                      }));
                      dispatch(setKey({
                        key: 'hoursTotal',
                        value: false,
                      }));
                    }}
                    checked={hoursSelected}
                  />
                  <label
                    htmlFor="hours"
                    style={{ minWidth: 200 }}
                    className="mb-0 ml-2"
                  >
                    Hours
                  </label>
                  <HoursWagesSelect
                    options={data?.hoursWagesOptions || []}
                    onChange={(selected: IOption[]) => {
                      dispatch(setKey({
                        key: 'hours',
                        value: selected,
                      }));

                      dispatch(setKey({
                        key: 'hoursTotal',
                        value: !!selected.length,
                      }));

                      dispatch(setKey({
                        key: 'hoursDetail',
                        value: selected.length ? hoursDetail : false,
                      }));
                    }}
                    value={hours}
                    disabled={!hoursSelected}
                  />
                  <input
                    id="hoursTotal"
                    type="checkbox"
                    name="hoursTotal"
                    className="ml-4"
                    disabled={!hoursSelected}
                    checked={hoursTotal}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'hoursTotal',
                        value: e.target.checked,
                      }));
                    }}
                  />
                  <input
                    id="hoursDetail"
                    type="checkbox"
                    name="hoursDetail"
                    style={{ marginLeft: '2.25rem' }}
                    disabled={!hoursSelected}
                    checked={hoursDetail}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'hoursDetail',
                        value: e.target.checked,
                      }));
                    }}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <input
                    id="wages"
                    type="checkbox"
                    name="wages"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setWagesSelected(e.target.checked));
                      dispatch(setKey({
                        key: 'wagesDetail',
                        value: e.target.checked,
                      }));

                      if (e.target.checked) {
                        return;
                      }

                      dispatch(setKey({
                        key: 'wages',
                        value: [],
                      }));
                      dispatch(setKey({
                        key: 'wagesTotal',
                        value: false,
                      }));
                    }}
                    checked={wagesSelected}
                  />
                  <label
                    htmlFor="wages"
                    style={{ minWidth: 200 }}
                    className="mb-0 ml-2"
                  >
                    Wages
                  </label>
                  <HoursWagesSelect
                    options={data?.hoursWagesOptions || []}
                    onChange={(selected: IOption[]) => {
                      dispatch(setKey({
                        key: 'wages',
                        value: selected,
                      }));

                      dispatch(setKey({
                        key: 'wagesTotal',
                        value: !!selected.length,
                      }));

                      dispatch(setKey({
                        key: 'wagesDetail',
                        value: selected.length ? wagesDetail : false,
                      }));
                    }}
                    value={wages}
                    disabled={!wagesSelected}
                  />
                  <input
                    id="wagesTotal"
                    type="checkbox"
                    name="wagesTotal"
                    className="ml-4"
                    disabled={!wagesSelected}
                    checked={wagesTotal}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'wagesTotal',
                        value: e.target.checked,
                      }));
                    }}
                  />
                  <input
                    id="wagesDetail"
                    type="checkbox"
                    name="wagesDetail"
                    style={{ marginLeft: '2.25rem' }}
                    disabled={!wagesSelected}
                    checked={wagesDetail}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'wagesDetail',
                        value: e.target.checked,
                      }));
                    }}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <input
                    id="payRates"
                    type="checkbox"
                    name="payRates"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'payRatesSelected',
                        value: e.target.checked,
                      }));
                    }}
                    checked={payRatesSelected}
                  />
                  <label
                    htmlFor="payRates"
                    style={{ minWidth: 200 }}
                    className="mb-0 ml-2"
                  >
                    Pay Rates
                  </label>
                  <NormalSelect
                    isMulti
                    options={(data?.payRates || [])?.map((item: string) => {
                      return {
                        label: item,
                        value: item,
                      };
                    })}
                    onChange={(selected: IOption) => {
                      dispatch(setPayRates(selected));
                    }}
                    value={payRates || []}
                    disabled={!payRatesSelected}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <input
                    id="fringeEarnings"
                    type="checkbox"
                    name="fringeEarnings"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'fringeEarningsSelected',
                        value: e.target.checked,
                      }));
                      dispatch(setKey({
                        key: 'fringeEarningsDetail',
                        value: e.target.checked,
                      }));

                      if (e.target.checked) {
                        return;
                      }

                      dispatch(setFringeEarning([]));
                      dispatch(setKey({
                        key: 'fringeEarningsTotal',
                        value: false,
                      }));
                    }}
                    checked={fringeEarningsSelected}
                  />
                  <label
                    htmlFor="fringeEarnings"
                    style={{ minWidth: 200 }}
                    className="mb-0 ml-2"
                  >
                    Fringe Earnings
                  </label>
                  <NormalSelect
                    isMulti
                    options={data?.fringeEarnings?.map(item => {
                      return {
                        label: item,
                        value: item,
                      };
                    })}
                    onChange={(selected: IOption[]) => {
                      dispatch(setFringeEarning(selected));

                      dispatch(setKey({
                        key: 'fringeEarningsTotal',
                        value: !!selected.length,
                      }));

                      dispatch(setKey({
                        key: 'fringeEarningsDetail',
                        value: selected.length ? fringeEarningsDetail : false,
                      }));
                    }}
                    value={fringeEarning}
                    disabled={!fringeEarningsSelected}
                  />
                  <input
                    id="fringeEarningsTotal"
                    type="checkbox"
                    name="fringeEarningsTotal"
                    className="ml-4"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'fringeEarningsTotal',
                        value: e.target.checked,
                      }));
                    }}
                    checked={fringeEarningsTotal}
                    disabled={!fringeEarningsSelected}
                  />
                  <input
                    id="fringeEarningsDetail"
                    type="checkbox"
                    name="fringeEarningsDetail"
                    style={{ marginLeft: '2.25rem' }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'fringeEarningsDetail',
                        value: e.target.checked,
                      }));
                    }}
                    checked={fringeEarningsDetail}
                    disabled={!fringeEarningsSelected}
                  />
                </div>
              </div>
              <div
                className="mt-4 d-flex flex-column mb-10"
                style={{ rowGap: '0.75rem' }}
              >
                <div className="d-flex align-items-center">
                  <input
                    id="area"
                    type="radio"
                    name="options"
                    value="areas"
                    onChange={() => {
                      dispatch(setAreaSelected('areas'));
                    }}
                    checked={areaSelected === 'areas'}
                  />
                  <label
                    htmlFor="area"
                    style={{ minWidth: 200 }}
                    className="mb-0 ml-2"
                  >
                    Area
                  </label>
                  <SelectWithAll
                    isMulti
                    selected={areaValue || []}
                    fn={setAreaValue}
                    options={data?.areas || []}
                    disabled={areaSelected !== 'areas'}
                    CustomOption={createAreaOption('areas')}
                    GroupHeading={createAreaGroupHeading('areas')}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <input
                    id="area-trade"
                    type="radio"
                    name="options"
                    value="areaTrades"
                    onChange={() => {
                      dispatch(setAreaSelected('areaTrades'));
                    }}
                    checked={areaSelected === 'areaTrades'}
                  />
                  <label
                    htmlFor="area-trade"
                    style={{ minWidth: 200 }}
                    className="mb-0 ml-2"
                  >
                    Area & Trade
                  </label>
                  <SelectWithAll
                    isMulti
                    selected={areaTradeValue || []}
                    fn={setAreaTradeValue}
                    options={data?.areaTrades || []}
                    disabled={areaSelected !== 'areaTrades'}
                    CustomOption={createAreaOption('areaTrades')}
                    GroupHeading={createAreaGroupHeading('areaTrades')}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <input
                    id="area-trade-sub"
                    type="radio"
                    name="options"
                    value="areaTradeSubs"
                    onChange={() => {
                      dispatch(setAreaSelected('areaTradeSubs'));
                    }}
                    checked={areaSelected === 'areaTradeSubs'}
                  />
                  <label
                    htmlFor="area-trade-sub"
                    style={{ minWidth: 200 }}
                    className="mb-0 ml-2"
                  >
                    Area & Trade & Sub
                  </label>
                  <SelectWithAll
                    isMulti
                    selected={areaTradeSubValue || []}
                    fn={setAreaTradeSubValue}
                    options={data?.areaTradeSubs || []}
                    disabled={areaSelected !== 'areaTradeSubs'}
                    CustomOption={createAreaOption('areaTradeSubs')}
                    GroupHeading={createAreaGroupHeading('areaTradeSubs')}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <input
                    id="showDescriptions"
                    type="checkbox"
                    name="showDescriptions"
                    onChange={(e) => {
                      dispatch(setKey({
                        key: 'showDescription',
                        value: e.target.checked,
                      }));
                    }}
                    checked={showDescription}
                  />
                  <label
                    htmlFor="showDescriptions"
                    style={{ minWidth: 200 }}
                    className="mb-0 ml-2"
                  >
                    Show Descriptions
                  </label>
                </div>
              </div>
              <div
                className="mt-4 d-flex flex-column"
                style={{ rowGap: '0.75rem' }}
              >
                <div className="d-flex align-items-center">
                  <input
                    id="employeeFringeEarnings"
                    type="checkbox"
                    name="employeeFringeEarnings"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'employeeFringeEarningSelected',
                        value: e.target.checked,
                      }));
                      dispatch(setKey({
                        key: 'employeeFringeEarningDetail',
                        value: e.target.checked,
                      }));

                      if (e.target.checked) {
                        return;
                      }

                      dispatch(setEmployeeFringeEarning([]));
                      dispatch(setKey({
                        key: 'employeeFringeEarningTotal',
                        value: false,
                      }));
                    }}
                    checked={employeeFringeEarningSelected}
                  />
                  <label
                    htmlFor="employeeFringeEarnings"
                    style={{ minWidth: 200 }}
                    className="mb-0 ml-2"
                  >
                    Employee Paid Fringes
                  </label>
                  <NormalSelect
                    isMulti
                    options={data?.employeePaidFringes?.map(item => {
                      return {
                        label: item,
                        value: item,
                      };
                    })}
                    onChange={(selected: IOption[]) => {
                      dispatch(setEmployeeFringeEarning(selected));

                      dispatch(setKey({
                        key: 'employeeFringeEarningTotal',
                        value: !!selected.length,
                      }));

                      dispatch(setKey({
                        key: 'employeeFringeEarningDetail',
                        value: selected.length ? employeeFringeEarningDetail : false,
                      }));
                    }}
                    value={employeeFringeEarning}
                    disabled={!employeeFringeEarningSelected}
                  />
                  <input
                    id="employeeFringeEarningsTotal"
                    type="checkbox"
                    name="employeeFringeEarningsTotal"
                    className="ml-4"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'employeeFringeEarningTotal',
                        value: e.target.checked,
                      }));
                    }}
                    checked={employeeFringeEarningTotal}
                    disabled={!employeeFringeEarningSelected}
                  />
                  <input
                    id="employeeFringeEarningsDetail"
                    type="checkbox"
                    name="employeeFringeEarningsDetail"
                    style={{ marginLeft: '2.25rem' }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'employeeFringeEarningDetail',
                        value: e.target.checked,
                      }));
                    }}
                    checked={employeeFringeEarningDetail}
                    disabled={!employeeFringeEarningSelected}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <input
                    id="employerFringeEarnings"
                    type="checkbox"
                    name="employerFringeEarnings"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'employerFringeEarningSelected',
                        value: e.target.checked,
                      }));
                      dispatch(setKey({
                        key: 'employerFringeEarningDetail',
                        value: e.target.checked,
                      }));

                      if (e.target.checked) {
                        return;
                      }

                      dispatch(setEmployerFringeEarning([]));
                      dispatch(setKey({
                        key: 'employerFringeEarningTotal',
                        value: false,
                      }));
                    }}
                    checked={employerFringeEarningSelected}
                  />
                  <label
                    htmlFor="employerFringeEarnings"
                    style={{ minWidth: 200 }}
                    className="mb-0 ml-2"
                  >
                    Employer Paid Fringes
                  </label>
                  <NormalSelect
                    isMulti
                    options={data?.employerPaidFringes?.map(item => {
                      return {
                        label: item,
                        value: item,
                      };
                    })}
                    onChange={(selected: IOption[]) => {
                      dispatch(setEmployerFringeEarning(selected));
                      dispatch(setKey({
                        key: 'employerFringeEarningTotal',
                        value: !!selected.length,
                      }));
                      dispatch(setKey({
                        key: 'employerFringeEarningDetail',
                        value: selected.length ? employerFringeEarningDetail : false,
                      }));
                    }}
                    value={employerFringeEarning}
                    disabled={!employerFringeEarningSelected}
                  />
                  <input
                    id="employerFringeEarningsTotal"
                    type="checkbox"
                    name="employerFringeEarningsTotal"
                    className="ml-4"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'employerFringeEarningTotal',
                        value: e.target.checked,
                      }));
                    }}
                    checked={employerFringeEarningTotal}
                    disabled={!employerFringeEarningSelected}
                  />
                  <input
                    id="employerFringeEarningsDetail"
                    type="checkbox"
                    name="employerFringeEarningsDetail"
                    style={{ marginLeft: '2.25rem' }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'employerFringeEarningDetail',
                        value: e.target.checked,
                      }));
                    }}
                    checked={employerFringeEarningDetail}
                    disabled={!employerFringeEarningSelected}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <input
                    id="employerPaidTaxes"
                    type="checkbox"
                    name="employerPaidTaxes"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'employerPaidTaxes',
                        value: e.target.checked,
                      }));
                      dispatch(setKey({
                        key: 'employerPaidTaxesDetail',
                        value: e.target.checked,
                      }));

                      if (e.target.checked) {
                        return;
                      }

                      dispatch(setKey({
                        key: 'employerPaidTaxesTotal',
                        value: false,
                      }));
                    }}
                    checked={employerPaidTaxes}
                  />
                  <label
                    htmlFor="employerPaidTaxes"
                    style={{ minWidth: 200 }}
                    className="mb-0 ml-2"
                  >
                    Employer Paid Taxes
                  </label>
                  <div style={{
                    width: 350,
                    height: 37.99,
                  }}
                  />
                  <input
                    id="employerFringeEarningsTotal"
                    type="checkbox"
                    name="employerFringeEarningsTotal"
                    className="ml-4"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'employerPaidTaxesTotal',
                        value: e.target.checked,
                      }));
                    }}
                    checked={employerPaidTaxesTotal}
                    disabled={!employerPaidTaxes}
                  />
                  <input
                    id="employerFringeEarningsDetail"
                    type="checkbox"
                    name="employerFringeEarningsDetail"
                    style={{ marginLeft: '2.25rem' }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setKey({
                        key: 'employerPaidTaxesDetail',
                        value: e.target.checked,
                      }));
                    }}
                    checked={employerPaidTaxesDetail}
                    disabled={!employerPaidTaxes}
                  />
                </div>
              </div>
            </div>
            <div
              className="d-flex flex-column"
              style={{ gap: '0.75rem' }}
            >
              <div
                className="mt-4 flex flex-column bg-light p-3 rounded"
                style={{ rowGap: '0.75rem' }}
              >
                <div
                  className="d-flex flex-column"
                  style={{ rowGap: '0.75rem' }}
                >
                  <div className="d-flex align-items-center">
                    <p
                      style={{ minWidth: 583 }}
                      className="mb-0"
                    >
                      Select
                    </p>
                    <div />
                  </div>
                  <div
                    className="d-flex flex-column"
                    style={{ rowGap: '0.75rem' }}
                  >

                    <div className="d-flex align-items-center">
                      <input
                        id="jobNumbers"
                        type="checkbox"
                        name="jobNumbers"
                        style={{ opacity: 0 }}
                      />
                      <label
                        htmlFor="jobNumbers"
                        style={{ minWidth: 200 }}
                        className="mb-0 ml-2"
                      >
                        Job Numbers
                      </label>
                      <WithCodeSelect
                        isMulti
                        selected={jobNumbers}
                        fn={setJobNumbers}
                        options={data?.jobNumbers.map((item) => {
                          return {
                            label: item?.description?.trim() || item.code,
                            value: item.code,
                          };
                        }) || []}
                        CustomOption={createGenericOptions([
                          {
                            key: 'value',
                            width: 80,
                          },
                          {
                            key: 'label',
                            width: 200,
                          },
                        ])}
                        GroupHeading={createGenericGroupHeading([
                          {
                            label: 'Job No.',
                            width: 80,
                          },
                          {
                            label: 'Description',
                            width: 200,
                          },
                        ])}
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        id="subNumbers"
                        type="checkbox"
                        name="subNumbers"
                        style={{ opacity: 0 }}
                      />
                      <label
                        htmlFor="subNumbers"
                        style={{ minWidth: 200 }}
                        className="mb-0 ml-2"
                      >
                        Sub Numbers
                      </label>
                      <WithCodeSelect
                        isMulti
                        selected={subNumbers}
                        fn={setSubNumbers}
                        options={data?.subNumbers.map((item) => {
                          return {
                            label: item?.description?.trim() || item.code,
                            value: item.code,
                          };
                        }) || []}
                        CustomOption={createGenericOptions([
                          {
                            key: 'value',
                            width: 80,
                          },
                          {
                            key: 'label',
                            width: 200,
                          },
                        ])}
                        GroupHeading={createGenericGroupHeading([
                          {
                            label: 'Sub No.',
                            width: 80,
                          },
                          {
                            label: 'Description',
                            width: 200,
                          },
                        ])}
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        id="projectNumber"
                        type="checkbox"
                        name="projectNumber"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setKey({
                            key: 'projectNumber',
                            value: e.target.checked,
                          }));
                        }}
                        checked={projectNumber}
                      />
                      <label
                        htmlFor="projectNumber"
                        style={{ minWidth: 200 }}
                        className="mb-0 ml-2"
                      >
                        Project Number
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        id="phase"
                        type="checkbox"
                        name="phase"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setKey({
                            key: 'phase',
                            value: e.target.checked,
                          }));
                        }}
                        checked={phase}
                      />
                      <label
                        htmlFor="phase"
                        style={{ minWidth: 200 }}
                        className="mb-0 ml-2"
                      >
                        Phase
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        id="foExtra"
                        type="checkbox"
                        name="foExtra"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setKey({
                            key: 'foExtra',
                            value: e.target.checked,
                          }));
                        }}
                        checked={foExtra}
                      />
                      <label
                        htmlFor="foExtra"
                        style={{ minWidth: 200 }}
                        className="mb-0 ml-2"
                      >
                        FO/Extra
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        id="costCode"
                        type="checkbox"
                        name="costCode"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setKey({
                            key: 'costCode',
                            value: e.target.checked,
                          }));
                        }}
                        checked={costCode}
                      />
                      <label
                        htmlFor="costCode"
                        style={{ minWidth: 200 }}
                        className="mb-0 ml-2"
                      >
                        Cost Code
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-column bg-light p-3 rounded"
                style={{ rowGap: '0.75rem' }}
              >
                <div className="d-flex flex-column">
                  <div className="form-label pl-0 mt-0">Report Options</div>
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <input
                        id="weekEndingDate"
                        type="checkbox"
                        name="weekEndingDate"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setKey({
                            key: 'weekEndingDate',
                            value: e.target.checked,
                          }));
                        }}
                        checked={weekEndingDate}
                      />
                      <label
                        htmlFor="weekEndingDate"
                        style={{ minWidth: 200 }}
                        className="mb-0 ml-2"
                      >
                        Week Ending Date
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        id="checkDate"
                        type="checkbox"
                        name="checkDate"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setKey({
                            key: 'checkDate',
                            value: e.target.checked,
                          }));
                        }}
                        checked={checkDate}
                      />
                      <label
                        htmlFor="checkDate"
                        style={{ minWidth: 200 }}
                        className="mb-0 ml-2"
                      >
                        Check Date
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        id="dayWorked"
                        type="checkbox"
                        name="dayWorked"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setKey({
                            key: 'dayWorked',
                            value: e.target.checked,
                          }));
                        }}
                        checked={dayWorked}
                      />
                      <label
                        htmlFor="dayWorked"
                        style={{ minWidth: 200 }}
                        className="mb-0 ml-2"
                      >
                        Day Worked
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mt-4 d-flex flex-column">
                  <div className="form-label pl-0 mt-0">Misc</div>
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <input
                        id="jobSubTotals"
                        type="checkbox"
                        name="jobSubTotals"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setKey({
                            key: 'jobSubTotals',
                            value: e.target.checked,
                          }));
                        }}
                        checked={jobSubTotals}
                      />
                      <label
                        htmlFor="jobSubTotals"
                        style={{ minWidth: 200 }}
                        className="mb-0 ml-2"
                      >
                        Job Subtotals
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        id="employeeSubTotals"
                        type="checkbox"
                        name="employeeSubTotals"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setKey({
                            key: 'employeeSubTotals',
                            value: e.target.checked,
                          }));
                        }}
                        checked={employeeSubTotals}
                      />
                      <label
                        htmlFor="employeeSubTotals"
                        style={{ minWidth: 200 }}
                        className="mb-0 ml-2"
                      >
                        Employee Subtotals
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        id="jobName"
                        type="checkbox"
                        name="jobName"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setKey({
                            key: 'jobName',
                            value: e.target.checked,
                          }));
                        }}
                        checked={jobName}
                      />
                      <label
                        htmlFor="jobName"
                        style={{ minWidth: 200 }}
                        className="mb-0 ml-2"
                      >
                        Job Name
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        id="city"
                        type="checkbox"
                        name="city"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setKey({
                            key: 'city',
                            value: e.target.checked,
                          }));
                        }}
                        checked={city}
                      />
                      <label
                        htmlFor="city"
                        style={{ minWidth: 200 }}
                        className="mb-0 ml-2"
                      >
                        City
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        id="country"
                        type="checkbox"
                        name="country"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setKey({
                            key: 'country',
                            value: e.target.checked,
                          }));
                        }}
                        checked={country}
                      />
                      <label
                        htmlFor="country"
                        style={{ minWidth: 200 }}
                        className="mb-0 ml-2"
                      >
                        County
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        id="state"
                        type="checkbox"
                        name="state"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setKey({
                            key: 'state',
                            value: e.target.checked,
                          }));
                        }}
                        checked={state}
                      />
                      <label
                        htmlFor="state"
                        style={{ minWidth: 200 }}
                        className="mb-0 ml-2"
                      >
                        State
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

    </form >
  );
};

export default ContractorFringeDownload;