import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Employee, HttpResponse, Supervisor } from '../../models';
import { EmployeeService } from '../../services';
import {
  handleError,
  loadEmployees,
  loadSupervisors,
  storeEmployees,
  storeSupervisors,
  storeEmpFromPreviousClient,
} from '../actions';
import { StreamedUserAccessObject } from './app.epic';

interface Actions {
  type: string;
  payload: any;
}

type ActionsWithType<T> = {
  type: string;
  payload: T
};

const loadEmployees$ = (action$: Observable<ActionsWithType<StreamedUserAccessObject | undefined>>) => {
  return action$.pipe(
    ofType(loadEmployees.type),
    switchMap((action) => {
      return from(EmployeeService.getEmployees()).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: Employee[]) => {
          const returnStream = [storeEmployees(res)];
          
          if (action.payload?.nextEmpNo) {
            const nextEmp = res.find(({ empNo }) => empNo === action.payload?.nextEmpNo);
            if (!nextEmp) {
              handleError('Could not load employee detail');
              return returnStream;
            }
            // HACK: we're storing this employee as Employee[] to satisfy this epic's return type. Apparently this action must match the other returned one's type.
            // Technically this action can store null, but this will never happen here (and if it does, you're entitled to all of my possessions).
            returnStream.push(storeEmpFromPreviousClient([nextEmp]) as {
              payload: Employee[];
              type: string;
            });
          }
          
          return returnStream;
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadSupervisors$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadSupervisors.type),
    switchMap(() => {
      return from(EmployeeService.getSupervisors()).pipe(
        map((res: any) => { return res.data; }),
        map((res: Supervisor[]) => { return storeSupervisors(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [loadEmployees$, loadSupervisors$];
