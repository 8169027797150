import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { DMDropdownMenu } from 'core/components/form-controls';
import { DMDropdownDataModel } from 'core/components/form-controls/DMDropDown';
import VaccinationReportModal from './vaccination-reporting/VaccinationReport.modal';
import { useAppSelector } from 'utilities/hooks';
import HamburgerMenu from 'core/components/shared/HamburgerMenu';

type EmployeeToolbarProps = {
  toggleSidebar: () => void;
  showSidebarMenuIcon: boolean;
};

const EmployeeHRProfileToolbar: React.FC<EmployeeToolbarProps> = ({
  toggleSidebar,
  showSidebarMenuIcon,
}) => {
  const [showVaccinationReportModal, setShowVaccinationReportModal] = useState(false);
  const navArray: {
    type: string;
    text?: string;
    href?: string;
    data?: DMDropdownDataModel;
  }[] = [
    {
      type: 'link',
      text: 'Employee List',
      href: '/hr-profile/detail/',
    },
    {
      type: 'vaccination-modal',
      text: 'Vaccination Reporting',
      href: '#',
    },
  ];
  
  const groupAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.groups;
  });
  
  const hrSection = groupAccess?.find((group) => {
    return group.description.toLowerCase() === 'employee master';
  });
  const visible = hrSection?.visible;

  const nav = () => {
    if (showSidebarMenuIcon) {
      return (
        <HamburgerMenu
          toggleSidebar={toggleSidebar}  
        />
      );
    } else {
      return navArray.map((n, i) => {
        switch (n.type) {
          case 'link':
            return n.href ? (
              <NavLink
                key={i}
                to={n.href}
              >
                {n.text}
              </NavLink>
            ) : null;
          case 'vaccination-modal':
            return (
              <a
                key={i}
                href={n.href}
                onClick={() => {return setShowVaccinationReportModal(true);}}
              >{n.text}</a>
            );
          case 'dropdown':
            return n.data ? (
              <DMDropdownMenu
                key={i}
                data={n.data}
                toggleClass="dm-toolbar-nav mr-4 pl-0"
                whiteChevron={true}
              />
            ) : null;
          default:
            return null;
        }
      });
    }
  };

  return <div className="dm-toolbar">
    {visible === false ? null : (
      <>      
        {nav()}
        {/* Modal for Vaccination Report */}
        {showVaccinationReportModal && (
          <VaccinationReportModal
            show={showVaccinationReportModal}
            onHide={() => {return setShowVaccinationReportModal(false);}}
          />
        )}
      </>
    )}
  </div>;
};

export default EmployeeHRProfileToolbar;
