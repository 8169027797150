import React, { FC, CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Payroll, VerifyResult } from 'core/models';
import { getProcessedPayrolls, getOpenPayrolls, getClientOptionIsContractor } from 'core/store/selectors';
import VerifyUploadedAdjustmentModal from './modals/VerifyUploadedAdjustment.modal';
import InputDateSelector from 'core/components/form-controls/InputDateSelector';
import { useAppSelector, useEffectOnMount } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';
import CardList from './payroll-card/CardList';
import { convToDateString } from 'utilities/utilities';
import { PayrollAdjustmentService } from 'core/services';
import ValidationAdjustmentErrorsModal from './modals/ValidationAdjustmentErrors.modal';
import { toast } from 'react-toastify';
import DateObject from 'react-date-object';
import {
  loadClientRecurringEarnings,
  loadPayroll,
  loadPayrollMessages,
  storePayrollHasSignedWireOnlyAgreement,
  storePayrollType,
  storeSelectedDates,
} from 'core/store/actions';

export interface CurrentPayrollModel {
  id: number;
  lastUser: string;
  lastUserClosed: string;
  weekEnd: string;
  checkDate: string;
  message: string;
  frequency: string;
  payrollDateLine: string;
  nextWeekEnd: string;
  nextCheckDate: string;
}

const listRowStyles: CSSProperties = { minHeight: '300px' };

const ProcessPayrollPage: FC = () => {
  const dispatch = useDispatch();
  const [tabKey, setTabKey] = useState('O');
  const openPayrollStore = useSelector(getOpenPayrolls);
  const processedPayrollStore = useSelector(getProcessedPayrolls);
  const isContractor = useSelector(getClientOptionIsContractor);
  const isSuperUser = useAppSelector((state) => { return state?.auth?.userAccess?.superUser; });

  const [reportDates, setReportDates] = useState<string[]>([]); // why do we call these report dates?
  const [selectedPayroll, setSelectedPayroll] = useState<Payroll | null>();
  const [showVerifyUploadedAdjustmentModal, setShowVerifyUploadedAdjustmentModal] = useState(false);
  const [showValidationAdjustmentErrorModal, setShowValidationAdjustmentErrorModal] = useState(false);
  const [validationAdjustmentErrors, setValidationAdjustmentErrors] = useState<VerifyResult | null>(null);
  const [selected, setSelected] = useState<'weekEnd' | 'checkDate'>('checkDate');

  const onSelectChange = (option: 'weekEnd' | 'checkDate') => {
    setSelected(option);
  };

  useEffect(() => {
    dispatch(loadClientRecurringEarnings());
  }, []);

  useEffect(() => {
    if (!tabKey) return;
    dispatch(storePayrollType({ tab: tabKey }));
  }, [tabKey]);

  // TODO: Type this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const childRef: React.RefObject<any> = useRef();

  useEffectOnMount(() => {
    if (!(reportDates && reportDates.length > 1)) return;

    dispatch(
      loadPayroll({
        beginDate: reportDates[0],
        endDate: reportDates[1],
        byCheckDate: selected === 'checkDate',
      }),
    );
    dispatch(storeSelectedDates([new DateObject(reportDates[0]), new DateObject(reportDates[1])]));
    dispatch(loadPayrollMessages());
  }, [reportDates, tabKey]);

  const getSelectedPayroll = useCallback((payroll: Payroll | null) => {
    setSelectedPayroll(payroll);
    dispatch(storePayrollHasSignedWireOnlyAgreement(payroll?.hasSignedWireOnlyAgreement ?? false));
  }, []);
  
  const confirmClose = async (confirmed: boolean) => {
    if (!confirmed || !selectedPayroll) return;
    const verifyPayroll = await PayrollAdjustmentService.postVerifyAdjustmentCheck(selectedPayroll.payrollHistoryId)
      .catch((err: any) => { return err; });
    if (verifyPayroll.status !== 200) return toast.error('ERROR: ' + verifyPayroll.errors[0]);

    if (verifyPayroll.data.value.hasValidationErrors) {
      setShowValidationAdjustmentErrorModal(true);
      setValidationAdjustmentErrors(verifyPayroll.data.value);
    } else {
      return toast.success(verifyPayroll.data.messages[0], { autoClose: 3000 });
    }
  };

  const returnDates = (dates: string[]) => {
    setReportDates(dates);
  };

  return (
    <div>
      <Col xs={12}>
        <Row>
          <Col
            xs={12}
            sm={3}
            style={{ minWidth: '250px' }}
          >
            <div className="dm-page-title">Process Payroll</div>
            <InputDateSelector
              ref={childRef}
              selected={selected}
              onSelectChange={onSelectChange}
              returnDates={returnDates}
              useDatesFromStore
            />
          </Col>
          {/*This check should only be checking if they are a super user or not. Email Change Glenn 2/1/2024*/}
          {isSuperUser && (
            <Col
              xs={12}
              sm={9}
              className="mt-auto pb-2 text-right"
            >
              <button
                type="button"
                className="btn btn-link dm-grid-action-title py-0"
                onClick={() => { return setShowVerifyUploadedAdjustmentModal(true); }
                }
                disabled={tabKey === 'F' || !selectedPayroll || !isSuperUser || ((selectedPayroll?.adjustmentTotals?.length || 0) === 0)}
              >
                Verify Uploaded Adjustment{' '}
                <Icon
                  name="clipboard-check"
                  className="fa-check"
                />
              </button>
            </Col>
          )}
        </Row>
        <Row style={listRowStyles}>
          <Col xs={12}>
            <hr className="dm-page-hr" />
            <Tabs
              defaultActiveKey="O"
              id="process-payroll-tabs"
              onSelect={(key) => {
                if (!key) return;
                return setTabKey(key);
              }}
            >
              <Tab
                eventKey="O"
                title="Open Payroll"
              >
                <CardList
                  type={'open'}
                  sortBy={selected}
                  payrollItems={openPayrollStore ?? []}
                  reportDates={reportDates}
                  passSelectedPayroll={getSelectedPayroll}
                />
              </Tab>
              <Tab
                eventKey="F"
                title="Processed Payroll"
              >
                <CardList
                  type={'processed'}
                  sortBy={selected}
                  payrollItems={processedPayrollStore ?? []}
                  reportDates={reportDates}
                  passSelectedPayroll={getSelectedPayroll}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Col>
      {showVerifyUploadedAdjustmentModal && selectedPayroll && (
        <VerifyUploadedAdjustmentModal
          weekEnd={convToDateString(new Date(selectedPayroll.weekEnd))}
          checkDate={convToDateString(new Date(selectedPayroll.checkDate))}
          show={showVerifyUploadedAdjustmentModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowVerifyUploadedAdjustmentModal(false); }}
        />
      )}
      {showValidationAdjustmentErrorModal && selectedPayroll && validationAdjustmentErrors && (
        <ValidationAdjustmentErrorsModal
          data={validationAdjustmentErrors}
          show = {showValidationAdjustmentErrorModal}
          onHide={() => { return setShowValidationAdjustmentErrorModal(false); }}
        />
      )}
    </div>
  );
};

export default ProcessPayrollPage;
