export class EmailTemplate {
  credentialEmailID = 0;

  clientNo = 0;

  templateName = '';

  emailVerbiage = '';

  constructor(clientNo: number) {
    this.clientNo = clientNo;
  }
}