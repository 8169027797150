import { createSelector } from 'reselect';
import { convToFormObject } from '../../../utilities/classUtils';
import { Employee } from '../../models';
import { employeesFeatureState, selEmployeeFeatureState } from '../reducers';
import { State as EmployeeState } from '../reducers/employee.reducer';
import { State as EmployeesState } from '../reducers/employees.reducer';

export const getEmployee = createSelector(
  selEmployeeFeatureState,
  (state: EmployeeState) => {
    if (!state?.employee) return null;

    return convToFormObject(state.employee, Employee.convProps) as Employee;
  },
);

export const getEmployeeFromEmpNo = (empNo: number) => createSelector(
  employeesFeatureState,
  (state: EmployeesState) => state.employees.find((employee) => employee.empNo === empNo));

export const getEmpOptions = createSelector(
  employeesFeatureState,
  (state: EmployeesState) => {
    return state.employees.map((e) => {
      return {
        empId: e.empId,
        fullName: e.firstName + ' ' + e.lastName,
        email: e.email,
      };
    });
  },
);

export const getSelectedEmp = createSelector(
  selEmployeeFeatureState,
  (state: EmployeeState) => {
    if (!state?.employee) return null;

    return convToFormObject(state.employee, Employee.convProps) as Employee;
  },
);
