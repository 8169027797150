import { InputGrp } from 'core/components/form-controls';
import Modal from 'core/components/modals/Modal';
import { UserMapItem } from 'core/models/Downloader.model';
import { storeUserMap } from 'core/store/slices/downloader.slice';
import { setSelectedFields } from 'core/store/slices/employee-earnings-download.slice';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'utilities/hooks';


type PropTypes = {
  show: boolean;
  onHide: () => void;
  downloaderType: string;
};

const NewMapDescriptionModal: React.FC<PropTypes> = ({
  show,
  onHide,
  downloaderType,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userMapStore = useAppSelector((state) => {
    return state.downloader.selectedUserMap;
  }) as UserMapItem;
  const { register, handleSubmit, errors } = useForm<{ description: string }>();

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };

  const onSave = (data: { description: string }) => {
    if (!data.description) {
      return;
    }

    const request = {
      ...userMapStore,
      description: data.description,
    };

    dispatch(storeUserMap(request));
    dispatch(setSelectedFields([]));
    history.push(`/user-map/${downloaderType}/0`);
    closeModal();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Map Description"
      size="sm"
    >
      <form
        onSubmit={handleSubmit(onSave)}
        className="mt-3"
      >
        <InputGrp
          name="description"
          label="Description"
          errors={errors.description}
          ref={register({
            required: {
              value: true,
              message: 'Description is required',
            },
          })}
        />

        <div className="d-flex mt-3">
          <button
            type="button"
            className="orange-outline-button ml-auto mr-2"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="orange-button mr-2"
          >
            Ok
          </button>
        </div>
      </form>
    </Modal>
  );
};
export default NewMapDescriptionModal;
