import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ControlDatePickerGrp, InputGrp, InputGrpCurrency, SelectGrp } from '../form-controls';
import { FieldInputSettings } from '../form-controls/types';
import { useSelector } from 'react-redux';
import { getDisplayOnTransmittal, getFuturePayRates, getSelectedEmp } from '../../store/selectors';
import { FuturePayRate } from '../../models';
import { createFuturePayRate, deleteFuturePayRate, handleError, loadFuturePayRates, updateFuturePayRate } from '../../store/actions';
import { convToDateString, currencyFormatter } from '../../../utilities/utilities';
import { CURRENCY_VALIDATION } from '../../constants';
import Modal from './Modal';
import Icon from '../shared/Icon';
import { EmpPayRateWorkFlow, WorkFlowTask } from 'core/models/WorkFlow.model';
import { postWorkFlowTask } from 'core/store/actions/work-flow.action';
import { useAppDispatch } from 'utilities/hooks';

const fs: FieldInputSettings = {
  effectiveDate: {
    name: 'effectiveDate',
    label: 'FUTURE EFF. DATE',
    groupClass: 'groupClass40',
  },
  annualRate: {
    name: 'annualRate',
    label: 'ANNUAL',
    groupClass: 'groupClass30',
    required: true,
  },
  salaryRate: {
    name: 'salaryRate',
    label: 'SALARY',
    groupClass: 'groupClass30 pr-0',
    required: true,
  },

  hourlyRate: {
    name: 'hourlyRate',
    label: 'HOURLY',
    groupClass: 'groupClass30',
    required: true,
  },
  displayOnTrans: {
    name: 'displayOnTrans',
    label: 'DISPLAY',
    groupClass: 'groupClass70 pr-0',
  },

  notes: {
    name: 'notes',
    label: 'NOTES',
    groupClass: 'groupClass100 pr-0',
  },
};

type PropTypes = {
  clientNo: number;
  empNo: number;
  protectedEmpNo: string;
  rateId: number;
  show: boolean;
  onHide: () => void;
  usesPayRateWorkFlow: boolean;
  openFromModal: boolean;
};

const FuturePayRateModal2: React.FC<PropTypes> = ({ clientNo, empNo, protectedEmpNo, rateId, show, onHide, usesPayRateWorkFlow, openFromModal }) => {
  const [selectedFutureRate, setSelectedFutureRate] = useState<FuturePayRate>(new FuturePayRate(clientNo, empNo, protectedEmpNo, rateId));
  const { reset, errors, setValue, handleSubmit, register, control, watch } = useForm<FuturePayRate>({
    defaultValues: selectedFutureRate,
  });
  const effectiveDate = watch('effectiveDate');

  const dispatch = useAppDispatch();
  const selEmp = useSelector(getSelectedEmp);
  const futurePayRates: FuturePayRate[] = useSelector(getFuturePayRates);
  const displayOnTransmittalOpts = useSelector(getDisplayOnTransmittal);

  useEffect(() => {
    dispatch(loadFuturePayRates({
      empNo,
      rateId,
    }));
  }, [dispatch]);

  const onEffDate = (e: React.MouseEvent, index: number) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedFutureRate(() => {
      reset(futurePayRates[index]);

      return futurePayRates[index];
    });
  };

  const onSave = (data: Partial<FuturePayRate>) => {
    if(openFromModal) return onSaveWorkFlow(data);
    else return onSaveStandard(data);
  }

  const onSaveWorkFlow = (data: Partial<FuturePayRate>) => {
    const isNewFuturePayRate = selectedFutureRate.effectiveDate !== data.effectiveDate;
    const updatedRate = new FuturePayRate(
      selectedFutureRate.clientNo,
      selectedFutureRate.empNo,
      selectedFutureRate.protectedEmpNo,
      isNewFuturePayRate ? 0 : selectedFutureRate.rateId,
      data,
    );
    updatedRate.originalRateId = rateId;

    if(!selEmp || selEmp.empNo !== empNo) return dispatch(handleError('Error getting employee'));

    const change: EmpPayRateWorkFlow = {
      RateId: updatedRate.rateId,
      EmpId: selEmp.empId,
      ClientNo: selEmp.clientNo,
      EmpNo: selEmp.empNo,
      HourlyRate: updatedRate.hourlyRate,
      SalaryRate: updatedRate.salaryRate,
      AnnualRate: updatedRate.annualRate,
      TranDigit: updatedRate.displayOnTrans,
      Location: updatedRate.location,
      Department: updatedRate.department,
      SubDepartment: updatedRate.subDepartment,
      SubDepartment2: updatedRate.subDepartment2,
      Description: updatedRate.description,
      Notes: updatedRate.notes,
      Area: null,
      Trade: null,
      Sub: null,
      OverScale: null,
      Clock: 0,
      OriginalRateId: updatedRate.originalRateId,
      EffectiveDate: updatedRate.effectiveDate,
    }
    const changeJson = JSON.stringify(change).toString();

    const request: WorkFlowTask = {
      workFlowType: 1,
      targetEmpId: selEmp.empId,
      change: changeJson,
      protectedEmpNo: selEmp.protectedEmpNo
    };
    
    dispatch(postWorkFlowTask(request));
  }

  const onSaveStandard = (data: Partial<FuturePayRate>) => {
    const isNewFuturePayRate = selectedFutureRate.effectiveDate !== data.effectiveDate;
    const updatedRate = new FuturePayRate(
      selectedFutureRate.clientNo,
      selectedFutureRate.empNo,
      selectedFutureRate.protectedEmpNo,
      isNewFuturePayRate ? 0 : selectedFutureRate.rateId,
      data,
    );
    updatedRate.originalRateId = rateId;
    if (isNewFuturePayRate) {
      dispatch(createFuturePayRate(updatedRate));
      return;
    }

    dispatch(updateFuturePayRate(updatedRate));
  };

  const onDelete = () => {
    if (selectedFutureRate.empNo && selectedFutureRate.rateId) {
      dispatch(deleteFuturePayRate(selectedFutureRate));
      const newPayRate = new FuturePayRate(clientNo, empNo, protectedEmpNo, 0);
      setSelectedFutureRate(newPayRate);
      reset(newPayRate);
    }
  };

  const onBlurAnnual = (e: any) => {
    const annualValue = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
    setValue('salaryRate', currencyFormatter(annualValue / 26));
    setValue('hourlyRate', currencyFormatter(annualValue / 2080));

    setValue('annualRate', currencyFormatter(annualValue));
  };

  const onBlurSalary = (e: any) => {
    const salaryValue = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
    setValue('annualRate', currencyFormatter(salaryValue * 26));
    setValue('hourlyRate', currencyFormatter(salaryValue / 80));

    setValue('salaryRate', currencyFormatter(salaryValue));
  };

  const onBlurHourly = (e: any) => {
    const hourlyValue = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
    setValue('annualRate', currencyFormatter(hourlyValue * 2080));
    setValue('salaryRate', currencyFormatter(hourlyValue * 80));
    setValue('hourlyRate', currencyFormatter(hourlyValue));
  };

  return (

    <Modal
      show={show}
      onHide={onHide}
      title="Future Pay Rate"
    >
      {(closeModal) => {
        return (
          <form onSubmit={handleSubmit(onSave)}>
            <div className="d-flex flex-row flex-wrap">
              <div
                className="d-flex flex-column"
                style={{
                  width: '30%',
                }}
              >
                <label className="form-label">EFFECTIVE DATE HISTORY</label>
                <div style={{
                  flex: '1',
                  padding: '8px',
                  border: '1px solid #e3e3e3',
                }}>
                  {futurePayRates?.map((item: FuturePayRate, index) => {
                    return (
                      <button
                        key={index}
                        type="button"
                        className="btn btn-link py-0"
                        style={{
                          textAlign: 'right',
                          width: '100px',
                        }}
                        onClick={(e: any) => { return onEffDate(e, index); }}>{convToDateString(item.effectiveDate)}
                      </button>
                    );
                  })}
                </div>
              </div>
              <div className="d-flex flex-row flex-wrap pl-3"
                style={{
                  width: '70%',
                  height: '180px',
                }}>
                <ControlDatePickerGrp {...fs.effectiveDate}
                  value={effectiveDate}
                  setValue={setValue}
                  control={control} 
                  readOnly={usesPayRateWorkFlow} />
                <InputGrpCurrency
                  {...fs.annualRate}
                  errors={errors.annualRate}
                  onBlur={onBlurAnnual}
                  setValue={setValue}
                  ref={register(CURRENCY_VALIDATION)}
                  readOnly={usesPayRateWorkFlow} />
                <InputGrpCurrency
                  {...fs.salaryRate}
                  errors={errors.salaryRate}
                  onBlur={onBlurSalary}
                  setValue={setValue}
                  ref={register(CURRENCY_VALIDATION)}
                  readOnly={usesPayRateWorkFlow} />
                <InputGrpCurrency
                  {...fs.hourlyRate}
                  errors={errors.hourlyRate}
                  onBlur={onBlurHourly}
                  setValue={setValue}
                  ref={register(CURRENCY_VALIDATION)} 
                  readOnly={usesPayRateWorkFlow}/>
                <SelectGrp {...fs.displayOnTrans}
                  errors={errors.displayOnTrans}
                  ref={register}
                  options={displayOnTransmittalOpts}
                  readOnly={usesPayRateWorkFlow} />
                <InputGrp {...fs.notes}
                  errors={errors.notes}
                  ref={register}
                  readOnly={usesPayRateWorkFlow} />
                {!openFromModal ? <div className="w-100 text-right mt-auto"
                  onClick={onDelete}>
                  <button type="button" hidden={usesPayRateWorkFlow}
                    className="btn btn-link dm-grid-action-title">Delete this Future Rate
                    <Icon name="minus-circle"
                      className="fa-minus-circle" />
                  </button>
                </div> : null}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 text-right">
                <button type="button" hidden={usesPayRateWorkFlow}
                  className="btn btn-primary orange-outline-button mr-2"
                  onClick={closeModal}>Cancel</button>
                <button type="submit" hidden={usesPayRateWorkFlow}
                  className="btn btn-primary orange-button" >Save</button>
              </div>
            </div>
          </form>
        );
      }}
    </Modal>
  );
};

export default FuturePayRateModal2;
