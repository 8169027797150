import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export enum AccrualRecordTypes {
  Earned = 1,
  Taken = 2,
  AdjustTaken = 3,
}

export class AccrualRecord {
  balanceId = 0;

  recordId = 0;

  type = '';

  weekEnd: Date | string | null = null;

  checkDate: Date | string | null = null;

  dateEntered: Date | string | null = null;

  beginBalance = 0;

  earned = 0;

  taken = 0;

  balance = 0;

  note = '';

  sortOrder = 0;

  static readonly convProps: PropTypeLists = {
    dateProps: ['weekEnd', 'checkDate', 'dateEntered'],
    numberProps: ['balanceId', 'recordId', 'sortOrder'],
    floatProps: ['beginBalance', 'earned', 'taken', 'balance'],
  };

  constructor(
    balanceId: number | string,
    recordId: number | string,
    props?: { [key: string]: any },
  ) {
    this.balanceId = (typeof balanceId === 'string') ? parseInt(balanceId) : balanceId;
    this.recordId = (typeof recordId === 'string') ? parseInt(recordId) : recordId;

    props && convToClass(this, props, AccrualRecord.convProps);
  }
}
