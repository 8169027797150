import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Label } from '.';
import styles from './form-controls.module.scss';

type PropTypes = {
  id?: string;
  name: string;
  label?: string | string[];
  labelWidth?: number;
  groupClass?: string;
  groupStyle?: Object;
  inputClass?: string;
  errors?: any;
  required?: boolean;
  value?: any;
  setValue?: any;
  control: any;
  rules?: object;
  errorMsg?: string;
  onChange?: any;
  disabled?: boolean;
  maxDate?: Date;
  minDate?: Date;
};

export const ControlDatePickerGrpInLine: React.FC<PropTypes> = ({
  id,
  name,
  label,
  labelWidth = 60,
  groupClass,
  groupStyle,
  inputClass,
  errors,
  required = false,
  setValue,
  control,
  value,
  rules,
  errorMsg,
  maxDate,
  minDate,
  onChange = (e: Date) => {
    setValue && setValue(name, e);
  },
  disabled = false,
}) => {
  const focusRef = useRef<any>(); // TODO: what would this type be?
  
  const groupClass2 = groupClass
    ? 'd-flex form-group ' + groupClass
    : 'd-flex form-group';
  const inputWidthString = '' + (100 - labelWidth) + '%';
  const labelWidthString = '' + labelWidth + '%';
  id = id || name;
  const errMsg = errors
    ? errors.type === 'validate'
      ? errorMsg
      : errors.message
    : '';

  const labelText = Array.isArray(label) ? label[0] : label ?? ' ';

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Tab') {
      focusRef?.current?.setOpen(false);
    }
  };
  
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={value}
      render={({ onChange, value }) => {
        return (
          <div
            className={groupClass2}
            style={groupStyle}
          >
            {label && (
              <div style={{ width: labelWidthString }}>
                <Label
                  id={id}
                  hasError={errors}
                  text={labelText}
                  required={required}
                />
              </div>
            )}
            <div style={{ width: inputWidthString }}>
              <DatePicker
                ref={focusRef}
                onKeyDown={onKeyDown}
                enableTabLoop={false}
                autoComplete="off"
                disabled={disabled}
                preventOpenOnFocus={true}
                name={name}
                selected={value}
                maxDate={maxDate}
                minDate={minDate}
                onChange={onChange}
                className={inputClass + (errors ? ' ' + styles['dm-is-invalid'] : '')}
                customInput={<InputMask mask="99/99/9999" />}
              />
              <div>
                <small className="text-danger">{errMsg}</small>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};
