import React, { ComponentType, ReactElement } from 'react';
import { 
  Draggable, 
  DraggableProvided, 
  DraggableStateSnapshot, 
  Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { Applicant, ApplicantStatus } from 'core/models';
import { toReadableDate } from 'utilities/utilities';
import { Link, useHistory } from 'react-router-dom';
import Icon from 'core/components/shared/Icon';
import { useAppDispatch } from 'utilities/hooks';
import { setApplicant } from 'core/store/slices/applicant-tracking-v2.slice';

type Props = {
  columnId:number;
  applicants: Applicant[];
  CustomHeader?: ComponentType;
};

const ApplicantDrop = ({ columnId, applicants, CustomHeader }:Props) => {

  const history = useHistory();
  const dispatch = useAppDispatch();
  
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',

      // change background colour if dragging
      background: isDragging ? 'lightblue' : 'transparent',

      // styles we need to apply on draggables
      ...draggableStyle,
    };
  };

  const getListStyle = (isDraggingOver: boolean) => {
    return {
      background: isDraggingOver ? 'lightblue' : 'transparent',
    };
  };

  return (
    <div className="mx-2 d-flex">  
      <div className="d-flex flex-column dm-panel dm-panel-drop"> 
        <div className="mb-2 font-weight-bold">
          {CustomHeader ? (
            <div className="d-flex w-100 justify-content-between">
              <CustomHeader />
              <span className="float-right mx-2">{applicants.length}</span>
            </div>
          ) : (
            <>
              {ApplicantStatus[columnId].replace('_', ' ')}
              <span className="float-right mx-2">{applicants.length}</span>
            </>
          )}
        </div>  
        <Droppable droppableId={ApplicantStatus[columnId]} >
          {(providedDrop:DroppableProvided, 
            snapshotDrop: DroppableStateSnapshot) => {
            return (
              <div
                {...providedDrop.droppableProps}
                ref={providedDrop.innerRef}
                style={getListStyle(snapshotDrop.isDraggingOver)}
              >
                {applicants.map((item, index) => {
                  const highlightClass = item.status === 2
                    ? (item.offerStatus === 'Accepted' ? 'offer-accepted' : item.offerStatus === 'Denied' ? 'offer-rejected' : '')
                    : '';
                  
                  return (
                    <Draggable
                      key={item.applicantId}
                      draggableId={'' + item.applicantId}
                      index={index}
                    >
                      {(providedDrag:DraggableProvided, snapshotDrag:DraggableStateSnapshot) => {
                        return (
                          <div
                            className={`dm-drop-container ${highlightClass}`}
                            ref={providedDrag.innerRef}
                            {...providedDrag.draggableProps}
                            {...providedDrag.dragHandleProps}
                            style={getItemStyle(
                              snapshotDrag.isDragging,
                              providedDrag.draggableProps.style,
                            )}
                          >
                            <div className="d-flex flex-column">
                              <div> 
                                <button
                                  className="btn btn-link p-0"
                                  onClick={()=>{
                                    dispatch(setApplicant(item));
                                    history.push(`/manage-applicants/${item.applicantId}`);
                                  }}
                                >
                                  {item.firstName + ' ' + item.lastName}
                                </button>
                              </div>
                              <div><strong>Applied:</strong> {toReadableDate(item.dateApplied)}</div>
                              <div><strong>Position:</strong> {item.jobTitle}</div>
                              <div><strong>Status:</strong> {ApplicantStatus[item.status].replace('_', ' ')}
                                { item.status === ApplicantStatus.New_Applicant && 
                                <Icon
                                  name={'file-import'}
                                  style={{ padding: 0,
                                    color:'#e6ac29',
                                    marginLeft: 20 }}
                                />
                                }
                                { item.status === ApplicantStatus.Interview && 
                                <Icon
                                  name={'message-captions'}
                                  style={{ padding: 0,
                                    color:'#3577ae',
                                    marginLeft: 20 }}
                                />
                                }
                                { item.status === ApplicantStatus.Offer && 
                                <Icon
                                  name={'handshake'}
                                  style={{ padding: 0,
                                    color:'#1c8e57',
                                    marginLeft: 20 }}
                                />
                                }
                                { item.status === ApplicantStatus.Onboard && 
                                <Icon
                                  name={'id-card'}
                                  style={{ padding: 0,
                                    color:'#22558c',
                                    marginLeft: 20 }}
                                />
                                }
                                {/* { item.status === ApplicantStatus.Employee && 
                                <Icon
                                  name={'file-import'}
                                  style={{ padding: 0,
                                    color:'#E6AC29',
                                    marginLeft: 20 }}
                                />
                                } */}
                                { item.status === ApplicantStatus.Reject && 
                                <Icon
                                  name={'cancel'}
                                  style={{ padding: 0,
                                    color:'#d64624',
                                    marginLeft: 20 }}
                                />
                                }
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {providedDrop.placeholder}
              </div>
            );
          }}
        </Droppable>
      </div>
    </div>
  );
};

export default ApplicantDrop;