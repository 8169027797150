import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class MarkRequestTaken {
  clientNo = 0;

  empNo = 0;

  timeOffRequestId = 0;

  protectedEmpNo = '';

  taken = false;

  static readonly convProps: PropTypeLists = {
    numberProps: ['clientNo', 'empNo', 'timeOffRequestId'],
    boolProps: ['taken'],
  };

  constructor(
    clientNo: number | string,
    empNo: number | string,
    protectedEmpNo: string,
    timeOffRequestId: number | string,
    props?: { [key: string]: any },
  ) {
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;
    this.timeOffRequestId = (typeof timeOffRequestId === 'string') ? parseInt(timeOffRequestId) : timeOffRequestId;

    props && convToClass(this, props, MarkRequestTaken.convProps);
  }
}