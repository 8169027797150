export const deductionStatusOptions = [
  { id: 'ACTIVE', description: 'ACTIVE' },
  { id: 'DEACTIVE', description: 'DEACTIVE' },
  { id: 'SKIPONCE', description: 'SKIPONCE' },
];

export const directDepositStatusOptions = [
  { id: 'ACTIVE', description: 'ACTIVE' },
  { id: 'DEACTIVE', description: 'DEACTIVE' },
  { id: 'SKIPONCE', description: 'SKIPONCE' },
  { id: 'PRENOTE', description: 'PRENOTE' },
];

export const companyBenefitsStatusOptions = [
  { id: 'TRACKED', description: 'TRACKED' },
  { id: 'DEACTIVE', description: 'DEACTIVE' },
  { id: 'SKIPONCE', description: 'SKIPONCE' },
];

export const garnishmentOptions = [
  { id: 'DEACTIVE', description: 'Yes' },
  { id: 'ACTIVE', description: 'No' },
];