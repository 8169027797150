import { DateTime } from 'luxon';
import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export interface ACA {
  clientNo: number;
  empNo: number;
  protectedClientNo: string;
  protectedEmpNo: string;
  eligible: boolean;
  acaStatus: AcaStatus[];
  employeeInformationOverride: EmployeeInformationOverride;
  offerOfCoverage: OfferOfCoverage;
  employeeShare: EmployeeShare;
  safeHarbor: SafeHarbor;
  zipCode: ZipCode;
  coveredIndividuals: CoveredIndividual[];
  errorMessage: string;
}

export class CoveredIndividual {
  clientNo = 0;

  empNo?: number = 0;

  protectedClientNo = '';

  protectedEmpNo = '';

  empId = 0;

  dependentId = 0;

  firstName = '';

  middleName = '';

  lastName = '';

  suffix = '';

  ssn = '';

  birthDate: Date | string | null = null;

  year = 0;

  coveredAll12Months = false;

  january = false;

  february = false;

  march = false;

  april = false;

  may = false;

  june = false;

  july = false;

  august = false;

  september = false;

  october = false;

  november = false;

  december = false;

  static readonly convProps: PropTypeLists = {
    numberProps: ['empId', 'dependentId', 'year'],
    boolProps: ['coveredAll12Months', 'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
  };

  constructor(clientNo: number, empId: string | number, empNo: number | string, protectedEmpNo: string, props?: { [key: string]: any }) {
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.empId = (typeof empId === 'string') ? parseInt(empId) : empId;
    this.protectedEmpNo = protectedEmpNo;

    props && convToClass(this, props, CoveredIndividual.convProps);
    this.birthDate = (typeof props?.birthDate === 'string') ? props?.birthDate : DateTime.fromJSDate(props?.birthDate).toLocal().toJSON();
    this.ssn = props?.ssn?.replace(/-/g, '');
  }
}

export interface ZipCode {
  clientNo: number;
  empNo: number;
  protectedClientNo: string;
  protectedEmpNo: string;
  year: number;
  zipCodeAll12Months: string;
  zipCodeJanuary: string;
  zipCodeFebruary: string;
  zipCodeMarch: string;
  zipCodeApril: string;
  zipCodeMay: string;
  zipCodeJune: string;
  zipCodeJuly: string;
  zipCodeAugust: string;
  zipCodeSeptember: string;
  zipCodeOctober: string;
  zipCodeNovember: string;
  zipCodeDecember: string;
  overridden: boolean;
  empId: number;
}

export interface SafeHarbor {
  clientNo: number;
  empNo: number;
  protectedClientNo: string;
  protectedEmpNo: string;
  year: number;
  safeHarborAll12Months: string;
  safeHarborJanuary: string;
  safeHarborFebruary: string;
  safeHarborMarch: string;
  safeHarborApril: string;
  safeHarborMay: string;
  safeHarborJune: string;
  safeHarborJuly: string;
  safeHarborAugust: string;
  safeHarborSeptember: string;
  safeHarborOctober: string;
  safeHarborNovember: string;
  safeHarborDecember: string;
  overridden: boolean;
  empId: number;
}

export class EmployeeShare {
  clientNo: number;

  empNo: number;

  protectedClientNo = '';

  protectedEmpNo = '';

  year = 0;

  employeeShareAll12Months = 0;

  employeeShareJanuary = 0;

  employeeShareFebruary = 0;

  employeeShareMarch = 0;

  employeeShareApril = 0;

  employeeShareMay = 0;

  employeeShareJune = 0;

  employeeShareJuly = 0;

  employeeShareAugust = 0;

  employeeShareSeptember = 0;

  employeeShareOctober = 0;

  employeeShareNovember = 0;

  employeeShareDecember = 0;

  overridden = false;

  empId = 0;

  static readonly convProps: PropTypeLists = {
    numberProps: ['clientNo', 'empNo', 'year'],
    currencyProps: ['employeeShareAll12Months', 'employeeShareJanuary', 'employeeShareFebruary', 'employeeShareMarch',
      'employeeShareApril', 'employeeShareMay', 'employeeShareJune', 'employeeShareJuly', 'employeeShareAugust',
      'employeeShareSeptember', 'employeeShareOctober', 'employeeShareNovember', 'employeeShareDecember'],
  };

  constructor(
    clientNo: number | string,
    empNo: number | string,
    props?: { [key: string]: any },
  ) {
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;

    props && convToClass(this, props, EmployeeShare.convProps);
  }
}

export interface OfferOfCoverage {
  clientNo: number;
  empNo: number;
  protectedClientNo: string;
  protectedEmpNo: string;
  year: number;
  offerOfCoverageAll12Months: string;
  offerOfCoverageJanuary: string;
  offerOfCoverageFebruary: string;
  offerOfCoverageMarch: string;
  offerOfCoverageApril: string;
  offerOfCoverageMay: string;
  offerOfCoverageJune: string;
  offerOfCoverageJuly: string;
  offerOfCoverageAugust: string;
  offerOfCoverageSeptember: string;
  offerOfCoverageOctober: string;
  offerOfCoverageNovember: string;
  offerOfCoverageDecember: string;
  overridden: boolean;
  empId: number;
}

export interface EmployeeInformationOverride {
  clientNo: number;
  empNo: number;
  protectedClientNo: string;
  protectedEmpNo: string;
  employeeOverrideId: number;
  empId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  year: number;
}

export interface AcaStatus {
  clientNo?: number;
  empNo: number;
  protectedClientNo: string;
  protectedEmpNo: string;
  effectiveDate: string;
  status: string;
  empId: number;
  isNew?: boolean;
}

export interface ACA1095B {
  clientNo: number;
  protectedClientNo: string;
  empNo: number;
  protectedEmpNo: string;
  year: number;
  originOfPolicy: string;
  partIICompanyName: string;
  partIICompanyEIN: string;
  partIICompanyAddress: string;
  partIICompanyCity: string;
  partIICompanyState: string;
  partIICompanyZip: string;
  partIIIName: string;
  partIIIEIN: string;
  partIIIContactPhone: string;
  partIIIAddress: string;
  partIIIAddress2: string;
  partIIICity: string;
  partIIIState: string;
  partIIIZip: string;
}

export interface ACAWaiveCoverage {
  clientNo?: number;
  protectedClientNo?: string;
  empNo?: number;
  protectedEmpNo?: string;
  year?: number;
  safeHarborCode?: string;
}