import { AxiosResponse } from "axios";
import { ContactInfo, HttpResponse } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getContactInfo = (empNo: number): Promise<AxiosResponse<ContactInfo>> => {return API_EMPLOYEE_MASTER.get<ContactInfo>(EmployeeEndPoints.GET_CONTACT_INFO(CommonService.getProtectedClientNo(), empNo));};

const putContactInfo = (contact: ContactInfo): Promise<AxiosResponse<HttpResponse<ContactInfo>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<ContactInfo>>(EmployeeEndPoints.GET_CONTACT_INFO(CommonService.getProtectedClientNo(), contact.empNo), contact);};

export const ContactService = {
  getContactInfo,
  putContactInfo,
};
