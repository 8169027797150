import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Id, toast } from 'react-toastify';
import { AgGridReact } from '@ag-grid-community/react';
import { CellEditingStoppedEvent, CellValueChangedEvent, ColGroupDef, GridApi, GridOptions, GridReadyEvent, MasterDetailModule, SortController } from '@ag-grid-enterprise/all-modules';
import { generateMasterColumnGroupDefs, generateDetailColumnGroupDefs, buildGridOptions } from './ChangeGridConfig';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { AllocationDetail, AllocationEmployee, DeptAllocation, DeptAllocationDTO } from 'core/models';
import Icon from 'core/components/shared/Icon';
import { handleError, postDeptAllocationCorrections } from 'core/store/actions';
import DeptAllocationAddModal from '../DeptAllocationAdd.modal';
import { Prompt } from 'react-router-dom';
import { UNSAVED_MESSAGE } from 'core/constants';

type Props = {
  selectedEmp: AllocationEmployee | undefined;
};

const ChangeAllocationsGrid = ({ selectedEmp }: Props) => {
  const deptAllocationCorrections = useAppSelector(({ corrections }) => { return corrections?.deptAllocationCorrections; });
  const deptAllocations = deptAllocationCorrections?.deptAllocations; // since this is nullable, not destructured from state like below
  const {
    location: locationOpts,
    department: deptOpts,
    subDepartment: subdeptOpts,
    subDepartment2: subdept2Opts,
  } = useAppSelector(({ dropdown }) => { return dropdown; });
  
  const [gridApi, setGridApi] = useState<GridApi>();
  const [rowData, setRowData] = useState<DeptAllocation[]>(deptAllocations ?? []);
  const [showNewAllocationModal, setShowNewAllocationmodal] = useState(false);
  const [deleteDetailId, setDeleteDetailId] = useState<number>();
  const [deleteDeptAllocationId, setDeleteDeptAllocationId] = useState<number>();
  const [isDirty, setIsDirty] = useState(false);
  const selectedEmpRef = useRef<AllocationEmployee | undefined>(selectedEmp);
  const deptAllocationCorrectionRef = useRef<DeptAllocationDTO | null>(deptAllocationCorrections);

  /* ^done in the absence of a form; in other situations you could use the id of 0, but adding a detail makes a call to
    the endpoint to save immediately, so it has a real id once added. Therefore, we need to use some stateful variable
    like this to set in the callbacks to the grid config and track here if a user has unsaved changes. */
  
  const dispatch = useAppDispatch();
  
  const toastIdRef = useRef<Id | null>(null);
  
  useEffect(() => {
    if (!deptAllocations) return;
    dismissToastMessage();
    setIsDirty(false);
    setRowData(deptAllocations);
    return () => { dismissToastMessage(); }; // used for component unmounting
  }, [deptAllocations, selectedEmp?.empNo]);
  
  useEffect(() => {
    selectedEmpRef.current = selectedEmp;
  }, [selectedEmp]);

  useEffect(() => {
    deptAllocationCorrectionRef.current = deptAllocationCorrections;
  }, [deptAllocationCorrections]);
  
  useEffect(() => {
    if (!(deleteDetailId && deleteDeptAllocationId)) return;
    modifyAllocationDetail('delete', deleteDeptAllocationId, deleteDetailId);
  }, [deleteDetailId]);
  
  const notify = () => {
    if (!toast.isActive(toastIdRef.current as Id)) {
      toastIdRef.current = toast.warn(
        'You have unsaved changes. Click "Save allocations" to keep your changes.',
        {
          autoClose: false,
          position: toast.POSITION.TOP_CENTER,
          className: 'toast-warning',
        },
      );
    }
  };

  const dismissToastMessage = () => {
    if (toastIdRef?.current) {
      toast.dismiss(toastIdRef.current);
      toastIdRef.current = null;
    } 
  };

  const addAllocation = (newAllocation: DeptAllocation) => {
    if (!selectedEmp) return console.error('No selected employee when adding allocations');
    
    /* Since some of these aren't registered inputs in the modal, they need to be set here. Alternatively,
      we could use hidden inputs in the modal, but this is cleaner and easier to understand/test. */
    newAllocation.deptAllocationId = 0;
    newAllocation.beginDate = newAllocation.beginDate === '' ? null : newAllocation.beginDate;
    newAllocation.endDate = newAllocation.endDate === '' ? null : newAllocation.endDate;
    newAllocation.transmittalLocation = newAllocation.transmittalLocation ? +newAllocation.transmittalLocation : 0;
    newAllocation.transmittalDept = newAllocation.transmittalDept ? +newAllocation.transmittalDept : 0;
    newAllocation.transmittalSub = newAllocation.transmittalSub ? +newAllocation.transmittalSub : 0;
    newAllocation.transmittalSub2 = newAllocation.transmittalSub2 ? +newAllocation.transmittalSub2 : 0;
    newAllocation.allocationDetails = [
      {
        detailId: 0,
        deptAllocationId: 0,
        loc: 0,
        dept: 0,
        sub: 0,
        sub2: 0,
        percent: 100,
      },
    ];

    saveAllocations([newAllocation, ...rowData]);
    setRowData((prevState) => {
      return [newAllocation, ...prevState];
    });
    setShowNewAllocationmodal(false);
  };
  
  const saveAllocations = (passedData?: DeptAllocation[], validatePercentage?: boolean, showSuccessMessage?: boolean) => {
    if (!(selectedEmpRef.current?.empNo || selectedEmpRef.current?.protectedEmpNo)) return console.error('No selected employee when saving allocations');
    
    let outOfRangeTotal = false;
    const tempRowData = structuredClone(passedData ?? rowData);
    const submit = tempRowData.map((row) => {
      //PI-8798 The backend will now send the percent as a percent to the front end. So we dont need the check to multiply it by 100 as it was causing issues.
      const updatedDetails = row.allocationDetails.map((detail) => {
          return {
          ...detail,
          percent: +(detail.percent / 100).toFixed(6),
        };
      });

      //This stuff WACKKKKK
      const percentSum: number = row.allocationDetails.map((detail) => {
          return detail.percent;}).reduce((accumulator, current) => {return (Math.round((accumulator + current) * 1e4) / 1e4)}, 0);

      //PI-8798 The total has to equal 100% otherwise it is an error
      if (percentSum !== 100) outOfRangeTotal = true;

      return {
        ...row,
        allocationDetails: updatedDetails,
      };
    });
    
    if (validatePercentage && outOfRangeTotal) return dispatch(handleError('Allocation total percentage must equal 100%.'));

    dispatch(postDeptAllocationCorrections({
      empNo: selectedEmpRef.current?.protectedEmpNo,
      allocations: submit,
      showSuccessMessage: showSuccessMessage ?? true
    }));
    
    dismissToastMessage();
    setIsDirty(false);
  };

  /**
   * Function to add or remove allocation details. Each case is handled pretty differently from one another to play well
   * with AG grid.
   * @param action the action being performed by the user (either adding or deleting a single detail record).
   * @param deptAllocationId The parent row id used to find the record
   * @param detailId The detail row id used to update the detail record
   */
  const modifyAllocationDetail = (action: 'add' | 'delete', deptAllocationId: number, detailId?: number) => {
    if (action === 'add') {
      setRowData((prevState) => { // all logic written inside setState callback to prevent stale state use
        const row = prevState.find((innerRow) => innerRow.deptAllocationId === deptAllocationId);
    
        if (!(row && row?.deptAllocationId)) {
          console.error('Error: no selected row to modify');
          return prevState;
        }
    
        const matchIndex = prevState.findIndex((innerRow) => innerRow.deptAllocationId === row.deptAllocationId);
        const newAllocationDetail: AllocationDetail = {
          detailId: 0,
          deptAllocationId: row.deptAllocationId,
          loc: 0,
          dept: 0,
          sub: 0,
          sub2: 0,
          percent: 0,
        };
        const tempState = structuredClone(prevState);
        const updatedAllocation: DeptAllocation = {
          ...row,
          allocationDetails: [...row.allocationDetails, newAllocationDetail],
        };
        tempState.splice(matchIndex, 1, updatedAllocation);
        saveAllocations(tempState, true, false);
      
        return tempState;
      });    
    } else if (action === 'delete' && detailId) {   
      if (deptAllocationCorrectionRef.current?.empNo !== selectedEmpRef.current?.empNo) return dispatch(handleError('Unable to delete allocation record'));

      setRowData((prevState) => { // all logic written inside setState callback to prevent stale state use
        const row = prevState.find((innerRow) => innerRow.deptAllocationId === deptAllocationId);
    
        if (!(row && row?.deptAllocationId)) {
          console.error('Error: no selected row to modify');
          return prevState;
        }
        
        const tempRows = structuredClone(prevState);
        const tempDetails = structuredClone(row.allocationDetails).filter((detailRow) => detailRow.detailId !== deleteDetailId);
        const updatedAllocation = { ...row, allocationDetails: tempDetails };
        /* Because of how AG grid redraws rows, we cannot splice here because it'll lose the rowId needed for maintaining
          the grid's expanded state and throw an error when mounting DOM nodes. So instead, just filter out the row being
          changed and then push it back in (since it gets auto-sorted after this anyway, there's no need to splice it). */
        const swappedData = tempRows.filter((innerRow) => innerRow.deptAllocationId !== deptAllocationId);
        swappedData.push(updatedAllocation);
        saveAllocations(swappedData);
        return swappedData;
      });
    }
  };
  
  // Callbacks needed for handling closure in grid config to get most up-tp-date component state
  const triggerMasterRowDelete = (deptAllocationId: number) => {
    if (deptAllocationCorrectionRef.current?.empNo !== selectedEmpRef.current?.empNo) return dispatch(handleError('Unable to delete allocation record'));
    
    setRowData((prevState) => {
      if (!prevState?.length) return [];
      const filteredRows = prevState.filter((outerRow) => { return outerRow.deptAllocationId !== deptAllocationId; });
      saveAllocations(filteredRows); // done in here to bridge ag grid closures and component state
      return filteredRows; // only necessary becasue the closure needs something returned
    });
  };
  
  const triggerDetailRowAdd = (deptAllocationId: number) => {
    modifyAllocationDetail('add', deptAllocationId);
  };
  
  const triggerDetailRowDelete = (deptAllocationId: number, detailId: number) => {
    setDeleteDetailId(detailId);
    setDeleteDeptAllocationId(deptAllocationId);
  };

  // Grid definitions and configurations
  const masterColumnGroupDefs: ColGroupDef[] = useMemo(() => { 
    return generateMasterColumnGroupDefs(locationOpts, deptOpts, subdeptOpts, subdept2Opts, setRowData, triggerDetailRowAdd, triggerMasterRowDelete); 
  }, []);
  const detailColumnGroupDefs: ColGroupDef[] = useMemo(() => { 
    return generateDetailColumnGroupDefs(locationOpts, deptOpts, subdeptOpts, subdept2Opts, setRowData, setIsDirty, triggerDetailRowDelete); 
  }, []);
  const gridOptions: GridOptions = useMemo(() => {
    return buildGridOptions(masterColumnGroupDefs, detailColumnGroupDefs);
  }, []);
  
  const toggleRow = (rowId: string) => {
    if (!gridApi) return;
    setTimeout(() => {    
      const rowToExpand = gridApi.getRowNode(rowId);
      if (!rowToExpand) return;
      rowToExpand.setExpanded(true);
    }, 0);
  };
  
  const onGridReady = (e: GridReadyEvent) => {
    setGridApi(e.api);
  };
  
  const onCellEditingStopped = (e: CellEditingStoppedEvent) => {
    const { data }: { data: DeptAllocation } = e;
    toggleRow(`${data.deptAllocationId}`);
  };
  
  const onCellValueChanged = (_: CellValueChangedEvent) => {
    setIsDirty(true); // this only handles master cells. Detail cells handled in separate callbacks/grid config file
  };
  
  function onMouseOut<T>(_: T) {
    if (!isDirty || toastIdRef?.current) return;
    notify();
  }
  
  return (
    <div
      className={`d-flex flex-column w-100 h-100 ${!rowData?.length ? 'empty-allocation-row-group' : ''}`}
      onMouseLeave={(_) => { onMouseOut(_); }}
    >
      <Prompt
        when={isDirty}
        message={UNSAVED_MESSAGE}
      />
      <div className="d-flex py-1">
        <button
          className="btn btn-link dm-grid-action-title corrections-button-link"
          disabled={!selectedEmp}
          aria-disabled={!selectedEmp}
          onClick={() => {return setShowNewAllocationmodal(true);}}
        >
          Insert allocation&nbsp;<Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button>
        <button
          className="btn btn-link dm-grid-action-title corrections-button-link"
          disabled={!(selectedEmp && isDirty)}
          aria-disabled={!(selectedEmp && isDirty)}
          onClick={() => { saveAllocations(undefined, true); }}
        >
          Save allocations&nbsp;<Icon name="save" />
        </button>
      </div>
      <div className="table-wrapper-wrapper ag-theme-balham">
        <AgGridReact
          detailRowAutoHeight
          masterDetail
          animateRows
          modules={[MasterDetailModule]}
          gridOptions={gridOptions}
          rowData={rowData}
          onGridReady={onGridReady}
          onCellEditingStopped={onCellEditingStopped}
          onCellValueChanged={onCellValueChanged}
          onCellMouseOut={onMouseOut}
        />
      </div>
      {showNewAllocationModal ? (
        <DeptAllocationAddModal
          show={showNewAllocationModal}
          onHide={() => { setShowNewAllocationmodal(false); }}
          prepend={(newAllocation: DeptAllocation) => { addAllocation(newAllocation); }}
        />
      ) : null}
    </div>
  );
};

export default ChangeAllocationsGrid;