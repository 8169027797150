import Icon from 'core/components/shared/Icon';
import React, { useEffect, useRef } from 'react';
import HrCustomFields from 'core/components/shared/HrCustomFields';
import PanelHeader from 'core/components/shared/PanelHeader';
import { CommonService } from 'core/services';
import {
  changeEmpCustomField,
  loadEmpCustomFields,
} from 'core/store/slices/hr.slice';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const HrEmployeeStatusCustomPage = () => {
  const dispatch = useAppDispatch();
  const refDiv = useRef<HTMLDivElement>(null);

  const selectedEmployee = useAppSelector(
    (state) => { return state.selEmployee.employee; },
  );
  const customFields = useAppSelector(
    (state) => { return state.hr.hrEmployeeCustomFields; },
  );

  useEffect(() => {
    if (selectedEmployee) {
      dispatch(loadEmpCustomFields(selectedEmployee.protectedEmpNo));
    }
  }, [selectedEmployee]);

  const addNewCustomField = () => {
    if (selectedEmployee) {
      dispatch(
        changeEmpCustomField({
          clientNo: selectedEmployee.clientNo,
          protectedClientNo: CommonService.getProtectedClientNo(),
          empNo: selectedEmployee.empNo,
          protectedEmpNo: selectedEmployee.protectedEmpNo,
          empId: selectedEmployee.empId,
          empCustomFieldsId: Math.random() * -1,
          empMasterCustomFieldsId: 0,
          entryData: '',
        }),
      );
      setTimeout(() => {
        refDiv.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }, 500);
    }
  };

  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Custom">
        <button
          type="button"
          className="btn btn-link dm-grid-action-title pb-0 mr-2"
          onClick={addNewCustomField}
        >
          Add New Row <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button>
      </PanelHeader>
      <div ref={refDiv}>
        {customFields &&
          customFields.map((customField) => {
            return (
              <HrCustomFields
                key={customField.empCustomFieldsId}
                hrEmployeeCustomFields={customField}
              />
            );
          })}
      </div>
    </div>
  );
};

export default HrEmployeeStatusCustomPage;
