import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmpCustomFieldRequest } from '../../models/HREmployee.model';
import {
  HrEmployeeCompanyIssuedProperty,
  HrEmployeeCompanyIssuedPropertyRequest,
} from '../../models/HrEmployeeCompanyIssuedProperty.model';
import { HrEmployeeCustomFields } from '../../models/HrEmployeeCustomFields.model';
import {
  HrEmployeeEmergencyContact,
  HrEmployeeStatusEmergencyContactIdRequest,
} from '../../models/HrEmployeeEmergencyContact.model';
import { HrEmployeeStatus } from '../../models/HrEmployeeStatus';
import { RootState } from '../store';

export interface HrState {
  loading: boolean;
  hrEmployeeStatus: HrEmployeeStatus | null;
  hrEmployeeEmergencyContacts: HrEmployeeEmergencyContact[];
  hrEmployeeCustomFields: HrEmployeeCustomFields[];
  hrEmployeeCompanyIssuedProperties: HrEmployeeCompanyIssuedProperty[];
}

const initialState: HrState = {
  loading: false,
  hrEmployeeStatus: null,
  hrEmployeeEmergencyContacts: [],
  hrEmployeeCustomFields: [],
  hrEmployeeCompanyIssuedProperties: [],
};

export const hrSlice = createSlice({
  name: 'hr',
  initialState,
  reducers: {
    loadHrEmployeeStatus: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (protectedEmpNo: string) =>{
        return {
          payload: protectedEmpNo,             
        };
      },
    },
    storeHrEmployeeStatus: (
      state,
      action: PayloadAction<HrEmployeeStatus>,
    ) => {
      state.loading = false;
      state.hrEmployeeStatus = action.payload;
    },
    putHrEmployeeStatus: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (hrEmployeeStatus: HrEmployeeStatus) => {
        return {
          payload: hrEmployeeStatus,
        };
      },
    },
    loadHrEmployeeEmergencyContacts: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (protectedEmpNo: string) => {
        return {
          payload: protectedEmpNo,
        };
      },
    },
    storeHrEmployeeEmergencyContacts: (
      state,
      action: PayloadAction<HrEmployeeEmergencyContact[]>,
    ) => {
      state.loading = false;
      state.hrEmployeeEmergencyContacts = action.payload;
    },
    postHrEmployeeEmergencyContacts: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: HrEmployeeEmergencyContact) => {
        return {
          payload: request,
        };
      },
    },
    putHrEmployeeEmergencyContacts: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: HrEmployeeEmergencyContact) => {
        return {
          payload: request,
        };
      },
    },
    deleteHrEmployeeEmergencyContacts: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: HrEmployeeStatusEmergencyContactIdRequest) => {
        return {
          payload: request,
        };
      },
    },
    removeHrEmployeeEmergencyContact: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.loading = false;
      state.hrEmployeeEmergencyContacts =
                state.hrEmployeeEmergencyContacts.filter(
                  (a) => {return a.contactId !== action.payload;},
                );
    },
    changeHrEmployeeEmergencyContact: (
      state,
      action: PayloadAction<HrEmployeeEmergencyContact>,
    ) => {
      state.loading = false;
      const emergencyContactIndex =
                state.hrEmployeeEmergencyContacts.findIndex(
                  (a) => {return a.contactId === action.payload.contactId;},
                );
      if (emergencyContactIndex === -1) {
        state.hrEmployeeEmergencyContacts =
                    state.hrEmployeeEmergencyContacts.filter(
                      (a) => {return a.contactId > 0;},
                    );
        state.hrEmployeeEmergencyContacts.push(action.payload);
      } else {
        state.hrEmployeeEmergencyContacts[emergencyContactIndex] =
                    action.payload;
      }
    },
    loadEmpCustomFields: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (protectedEmpNo: string) => {
        return {
          payload: protectedEmpNo,
        };
      },
    },
    storeEmpCustomFields: (
      state,
      action: PayloadAction<HrEmployeeCustomFields[]>,
    ) => {
      state.loading = false;
      state.hrEmployeeCustomFields = action.payload;
    },
    deleteEmpCustomField: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: EmpCustomFieldRequest) => {
        return {
          payload: request,
        };
      },
    },
    removeEmpCustomField: (state, action: PayloadAction<number>) => {
      state.loading = false;
      state.hrEmployeeCustomFields = state.hrEmployeeCustomFields.filter(
        (a) => {return a.empCustomFieldsId !== action.payload;},
      );
    },
    postEmpCustomField: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: HrEmployeeCustomFields) => {
        return {
          payload: request,
        };
      },
    },
    putEmpCustomField: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: HrEmployeeCustomFields) => {
        return {
          payload: request,
        };
      },
    },
    changeEmpCustomField: (
      state,
      action: PayloadAction<HrEmployeeCustomFields>,
    ) => {
      const customFieldIndex = state.hrEmployeeCustomFields.findIndex(
        (a) => {return a.empCustomFieldsId === action.payload.empCustomFieldsId;},
      );
      if (customFieldIndex === -1) {
        state.hrEmployeeCustomFields.push(action.payload);
      } else {
        state.hrEmployeeCustomFields[customFieldIndex] = action.payload;
      }
    },
    loadHrEmployeeCompanyProperty: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (protectedEmpNo: string) => {
        return {
          payload: protectedEmpNo,
        };
      },
    },
    storeHrEmployeeCompanyProperty: (
      state,
      action: PayloadAction<HrEmployeeCompanyIssuedProperty[]>,
    ) => {
      state.loading = false;
      state.hrEmployeeCompanyIssuedProperties = action.payload;
    },
    postHrEmployeeCompanyProperty: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: HrEmployeeCompanyIssuedProperty) => {
        return {
          payload: request,
        };
      },
    },
    putHrEmployeeCompanyProperty: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: HrEmployeeCompanyIssuedProperty) => {
        return {
          payload: request,
        };
      },
    },
    changeHrEmployeeCompanyProperty: (
      state,
      action: PayloadAction<HrEmployeeCompanyIssuedProperty>,
    ) => {
      const customFieldIndex =
                state.hrEmployeeCompanyIssuedProperties.findIndex(
                  (a) => {return a.propertyId === action.payload.propertyId;},
                );
      if (customFieldIndex === -1) {
        state.hrEmployeeCompanyIssuedProperties.push(action.payload);
      } else {
        state.hrEmployeeCompanyIssuedProperties[customFieldIndex] =
                    action.payload;
      }
    },
    deleteHrEmployeeCompanyProperty: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: HrEmployeeCompanyIssuedPropertyRequest) => {
        return {
          payload: request,
        };
      },
    },
    removeHrEmployeeCompanyProperty: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.loading = false;
      state.hrEmployeeCompanyIssuedProperties =
                state.hrEmployeeCompanyIssuedProperties.filter(
                  (a) => {return a.propertyId !== action.payload;},
                );
    },
  },
});

export const {
  loadHrEmployeeStatus,
  storeHrEmployeeStatus,
  putHrEmployeeStatus,
  loadHrEmployeeEmergencyContacts,
  storeHrEmployeeEmergencyContacts,
  deleteHrEmployeeEmergencyContacts,
  removeHrEmployeeEmergencyContact,
  postHrEmployeeEmergencyContacts,
  putHrEmployeeEmergencyContacts,
  changeHrEmployeeEmergencyContact,
  loadEmpCustomFields,
  storeEmpCustomFields,
  deleteEmpCustomField,
  removeEmpCustomField,
  postEmpCustomField,
  putEmpCustomField,
  changeEmpCustomField,
  loadHrEmployeeCompanyProperty,
  storeHrEmployeeCompanyProperty,
  deleteHrEmployeeCompanyProperty,
  removeHrEmployeeCompanyProperty,
  postHrEmployeeCompanyProperty,
  putHrEmployeeCompanyProperty,
  changeHrEmployeeCompanyProperty,
} = hrSlice.actions;

export const selectEmergencyContacts = (state: RootState) => {
  return (state?.hr?.hrEmployeeEmergencyContacts || [])
    .slice()
    .sort((a:any, b:any) => {return a.contactOrder - b.contactOrder;});
};

export default hrSlice.reducer;
