import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  CheckCalculateRequest,
  CheckCalculator,
  HttpResponse,
  PrintCheck,
  PrintCheckVerify,
  RecordPrepay,
} from '../../models';
import { CheckCalculatorService } from '../../services';
import { handleError, handleSuccess } from '../actions';
import {
  loadCheckCalculator,
  calculateCheck,
  storeCheckCalculator,
  storeCheckCalculation,
  storeCalculateCheckPdf,
  loadCalculateCheckPrint,
  loadCalculateCheckReport,
  calculateCheckPrintVerify,
  loadCalculateRecordPrepay,
  clearCalculateCheckPdf,
  showAdjustmentModal,
  toggleCalculateFlag,
} from '../actions/check-calculator.action';

interface Actions<Type> {
  type: string;
  payload: Type;
}

const loadCheckCalculator$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(loadCheckCalculator.type),
    switchMap((action: { payload: string; }) => {
      return from(
        CheckCalculatorService.getCheckCalculator(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: CheckCalculator) => { return storeCheckCalculator(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const calculateCheck$ = (
  action$: Observable<
  Actions<{
    calculateCheck: CheckCalculateRequest;
    protectedEmpNo: string;
  }>
  >,
) => {
  return action$.pipe(
    ofType(calculateCheck.type),
    switchMap((action: { payload: { calculateCheck: CheckCalculateRequest; protectedEmpNo: string; }; }) => {
      return from(
        CheckCalculatorService.calculateCheck(
          action.payload.calculateCheck,
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeCheckCalculation(res.value); }),
        catchError((err: HttpResponse<any>) => {
          return [
            toggleCalculateFlag(false),  
            handleError(err),
          ];
        }),
      );
    },
    ),
  );
};

const printCheck$ = (
  action$: Observable<
  Actions<{ printCheck: PrintCheck; protectedEmpNo: string }>
  >,
) => {
  return action$.pipe(
    ofType(loadCalculateCheckPrint.type),
    switchMap((action: { payload: { printCheck: PrintCheck; protectedEmpNo: string; }; }) => {
      return from(
        CheckCalculatorService.calculateCheckPrint(
          action.payload.printCheck,
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => {
          return storeCalculateCheckPdf({
            pdf: res.value,
            pdfType: 'check',
          });
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const recordPrepay$ = (
  action$: Observable<
  Actions<{ recordPrepay: RecordPrepay; protectedEmpNo: string; printCheckVerify: PrintCheckVerify; }>
  >,
) => {
  return action$.pipe(
    ofType(loadCalculateRecordPrepay.type),
    switchMap((action: { payload: { recordPrepay: RecordPrepay; protectedEmpNo: string; printCheckVerify: PrintCheckVerify; }; }) => {
      return from(
        CheckCalculatorService.calculateCheckRecordPrepay(
          action.payload.recordPrepay,
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => { 
          return [
            showAdjustmentModal(true),
            handleSuccess(res.messages),
            calculateCheckPrintVerify({ printCheckVerify: action.payload.printCheckVerify, protectedEmpNo: action.payload.protectedEmpNo }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const reportCheck$ = (
  action$: Observable<
  Actions<{ printCheck: PrintCheck; protectedEmpNo: string }>
  >,
) => {
  return action$.pipe(
    ofType(loadCalculateCheckReport.type),
    switchMap((action: { payload: { printCheck: PrintCheck; protectedEmpNo: string; }; }) => {
      return from(
        CheckCalculatorService.calculateCheckReport(
          action.payload.printCheck,
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => {
          return storeCalculateCheckPdf({
            pdf: res.value,
            pdfType: 'report',
          });
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const verifyCheck$ = (
  action$: Observable<
  Actions<{ printCheckVerify: PrintCheckVerify; protectedEmpNo: string }>
  >,
) => {
  return action$.pipe(
    ofType(calculateCheckPrintVerify.type),
    switchMap((action: { payload: { printCheckVerify: PrintCheckVerify; protectedEmpNo: string; }; }) => {
      return from(
        CheckCalculatorService.calculateCheckPrintVerify(
          action.payload.printCheckVerify,
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            clearCalculateCheckPdf(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadCheckCalculator$,
  calculateCheck$,
  printCheck$,
  reportCheck$,
  verifyCheck$,
  recordPrepay$,
];
