import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpResponse, VisaStatus } from '../../models';
import { VisaStatusService } from '../../services';
import {
  deleteVisaStatus,
  handleError,
  handleSuccess,
  loadVisaStatus,
  storeVisaStatus,
  triggerEmFieldValidation,
  updateVisaStatus,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadVisaStatus$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadVisaStatus.type),
    switchMap((action: { payload: string; }) => {
      return from(VisaStatusService.getVisaStatus(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: VisaStatus) => { return storeVisaStatus(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateVisaStatus$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateVisaStatus.type),
    switchMap((action: { payload: VisaStatus; }) => {
      return from(VisaStatusService.putVisaStatus(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<any>) => {
          return [
            storeVisaStatus(res.value),
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'visastatus',
              actionType: updateVisaStatus.type,
              callerPayload: res.value,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteVisaStatus$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteVisaStatus.type),
    switchMap((action: { payload: string | number }) => {
      return from(VisaStatusService.deleteVisaStatus(action.payload)).pipe(
        mergeMap(() => {
          return [
            loadVisaStatus('' + action.payload),
            triggerEmFieldValidation({
              section: 'visastatus',
              actionType: updateVisaStatus.type,
              ids: [action.payload],
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadVisaStatus$,
  updateVisaStatus$,
  deleteVisaStatus$,
];
