import { createAction } from '@reduxjs/toolkit';
import {
  AODDropDownItems,
  Department,
  EarningCode,
  Location,
  // DeductionCode,
  // DeductionCodes,
  // EarningCode,
  NOVATime,
  NOVATimeRule,
  NOVATimeSettings,
  NOVATimeShiftNumber,
  SubDepartment,
  SubDepartment2,
  TaxEntity,
} from '../../models';
import {
  Dropdown,
  DropdownRequest,
  ExitInterViewQuestionDropdown,
  PaygradeDropdown,
  SchoolDropdown,
} from '../../models/Dropdown.model';
import {
  ShiftPremium,
  ShiftPremiumEarnings,
} from '../../models/ShiftPremium.model';

export const loadAllDropdowns = createAction('[dropdown] LOAD_ALL_DROPDOWNS');
export const storeAllDropdowns = createAction<Dropdown[]>(
  '[dropdown] STORE_ALL_DROPDOWNS',
);

export const createDropdown = createAction<DropdownRequest>(
  '[dropdown] CREATE_DROPDOWN',
);
export const storeDropdown = createAction<Dropdown>(
  '[dropdown] STORE_DROPDOWN',
);
export const updateDropdown = createAction<DropdownRequest>(
  '[dropdown] UPDATE_DROPDOWN',
);
export const deleteDropdown = createAction<
{ id: string; dropdownName: string }[]
>('[dropdown] DELETE_DROPDOWN');

export const loadLocations = createAction('[dropdown] LOAD_LOCATIONS');
export const storeLocations = createAction<Location[]>(
  '[dropdown] STORE_LOCATIONS',
);
export const loadLocation = createAction<number>('[dropdown] LOAD_LOCATION');
export const createLocation = createAction<Location>(
  '[dropdown] CREATE_LOCATION',
);
export const updateLocation = createAction<Location>(
  '[dropdown] UPDATE_LOCATION',
);
export const storeLocation = createAction<Location>(
  '[dropdown] STORE_LOCATION',
);

export const deleteLocation = createAction<number>(
  '[dropdown] DELETE_LOCATION',
);

export const removeLocation = createAction<number>(
  '[dropdown] REMOVE_LOCATION',
);

export const loadDepartments = createAction('[dropdown] LOAD_DEPARTMENTS');
export const storeDepartments = createAction<Department[]>(
  '[dropdown] STORE_DEPARTMENTS',
);
export const loadDepartment = createAction<number>(
  '[dropdown] LOAD_DEPARTMENT',
);
export const createDepartment = createAction<Department>(
  '[dropdown] CREATE_DEPARTMENT',
);
export const updateDepartment = createAction<Department>(
  '[dropdown] UPDATE_DEPARTMENT',
);
export const storeDepartment = createAction<Department>(
  '[dropdown] STORE_DEPARTMENT',
);
export const deleteDepartment = createAction<number>(
  '[dropdown] DELETE_DEPARTMENT',
);

export const removeDepartment = createAction<number>(
  '[dropdown] REMOVE_DEPARTMENT',
);

export const loadSubdepartments = createAction(
  '[dropdown] LOAD_SUBDEPARTMENTS',
);
export const storeSubdepartments = createAction<SubDepartment[]>(
  '[dropdown] STORE_SUBDEPARTMENTS',
);
export const loadSubdepartment = createAction<number>(
  '[dropdown] LOAD_SUBDEPARTMENT',
);
export const createSubdepartment = createAction<SubDepartment>(
  '[dropdown] CREATE_SUBDEPARTMENT',
);
export const updateSubdepartment = createAction<SubDepartment>(
  '[dropdown] UPDATE_SUBDEPARTMENT',
);
export const deleteSubdepartment = createAction<number>(
  '[dropdown] DELETE_SUBDEPARTMENT',
);
export const storeSubdepartment = createAction<SubDepartment>(
  '[dropdown] STORE_SUBDEPARTMENT',
);
export const removeSubDepartment = createAction<number>(
  '[dropdown] REMOVE_SUBDEPARTMENT',
);

export const loadSubdepartments2 = createAction(
  '[dropdown] LOAD_SUBDEPARTMENTS2',
);
export const storeSubdepartments2 = createAction<SubDepartment2[]>(
  '[dropdown] STORE_SUBDEPARTMENTS2',
);
export const loadSubdepartment2 = createAction<number>(
  '[dropdown] LOAD_SUBDEPARTMENT2',
);
export const createSubdepartment2 = createAction<SubDepartment2>(
  '[dropdown] CREATE_SUBDEPARTMENT2',
);
export const updateSubdepartment2 = createAction<SubDepartment2>(
  '[dropdown] UPDATE_SUBDEPARTMENT2',
);
export const deleteSubdepartment2 = createAction<number>(
  '[dropdown] DELETE_SUBDEPARTMENT2',
);
export const storeSubdepartment2 = createAction<SubDepartment2>(
  '[dropdown] STORE_SUBDEPARTMENT2',
);
export const removeSubDepartment2 = createAction<number>(
  '[dropdown] REMOVE_SUBDEPARTMENT2',
);

export const loadShiftPremiums = createAction('[dropdown] LOAD_SHIFT_PREMIUMS');
export const storeShiftPremiums = createAction<ShiftPremium[]>(
  '[dropdown] STORE_SHIFT_PREMIUMS',
);
export const createShiftPremium = createAction<ShiftPremium>(
  '[dropdown] CREATE_SHIFT_PREMIUM',
);
export const updateShiftPremium = createAction<ShiftPremium>(
  '[dropdown] UPDATE_SHIFT_PREMIUM',
);
export const deleteShiftPremium = createAction<ShiftPremium>(
  '[dropdown] DELETE_SHIFT_PREMIUM',
);
export const storeShiftPremium = createAction<ShiftPremium>(
  '[dropdown] STORE_SHIFT_PREMIUM',
);
export const createShiftPremiumEarningsCode =
    createAction<ShiftPremiumEarnings>(
      '[dropdown] CREATE_SHIFT_PREMIUM_EARNINGS_CODE',
    );
export const createShiftPremiumEarningsCodes = createAction<
ShiftPremiumEarnings[]
>('[dropdown] CREATE_SHIFT_PREMIUM_EARNINGS_CODES');
export const deleteShiftPremiumEarningsCode =
    createAction<ShiftPremiumEarnings>(
      '[dropdown] DELETE_SHIFT_PREMIUM_EARNINGS_CODE',
    );

export const loadEarningCodesDropdown = createAction('[dropdown] LOAD_EARNING_CODES');
export const storeEarningCodesDropdown = createAction<EarningCode[]>(
  '[dropdown] STORE_EARNING_CODES',
);

export const loadNOVATimeSettings = createAction(
  '[dropdown] LOAD_NOVA_TIME_SETTINGS',
);
export const storeNOVATimeSettings = createAction<NOVATimeSettings>(
  '[dropdown] STORE_NOVA_TIME_SETTINGS',
);
export const loadNOVATime = createAction('[dropdown] LOAD_NOVA_TIME');
export const storeNOVATime = createAction<NOVATime>(
  '[dropdown] STORE_NOVA_TIME',
);
export const loadNOVATimeShiftNumbers = createAction(
  '[dropdown] LOAD_NOVA_TIME_SHIFTS_NUMBERS',
);
export const storeNOVATimeShiftNumbers = createAction<NOVATimeShiftNumber[]>(
  '[dropdown] STORE_NOVA_TIME_SHIFT_NUMBERS',
);
export const loadNOVATimeRules = createAction(
  '[dropdown] LOAD_NOVA_TIME_RULES',
);
export const storeNOVATimeRules = createAction<NOVATimeRule[]>(
  '[dropdown] STORE_NOVA_TIME_RULES',
);

export const loadTaxEntities = createAction<{
  country: string;
  state: string;
  type: string;
}>('[dropdown] LOAD_TAX_ENTITIES');
export const storeTaxEntities = createAction<TaxEntity[]>(
  '[dropdown] STORE_TAX_ENTITIES',
);

export const storeAODItems = createAction<AODDropDownItems>(
  '[dropdown] STORE_AOD_ITEMS',
);

export const loadPaygradeDropdowns = createAction(
  '[dropdown] LOAD_PAYGRADE_DROPDOWNS',
);
export const createPaygradeDropdown = createAction<PaygradeDropdown>(
  '[dropdown] CREATE_PAYGRADE_DROPDOWN',
);
export const storePaygradeDropdowns = createAction<PaygradeDropdown[]>(
  '[dropdown] STORE_PAYGRADE_DROPDOWNS',
);
export const updatePaygradeDropdown = createAction<PaygradeDropdown>(
  '[dropdown] UPDATE_PAYGRADE_DROPDOWN',
);

export const loadSchoolDropdowns = createAction(
  '[dropdown] LOAD_SCHOOL_DROPDOWNS',
);
export const createSchoolDropdown = createAction<SchoolDropdown>(
  '[dropdown] CREATE_SCHOOL_DROPDOWN',
);
export const storeSchoolDropdowns = createAction<SchoolDropdown[]>(
  '[dropdown] STORE_SCHOOL_DROPDOWNS',
);
export const updateSchoolDropdown = createAction<SchoolDropdown>(
  '[dropdown] UPDATE_SCHOOL_DROPDOWN',
);


export const loadExitInterviewQuestionsDropdowns = createAction(
  '[dropdown] LOAD_EXITINTERVIEWQUESTIONS_DROPDOWNS',
);
export const createExitInterviewQuestionsDropdown = createAction<ExitInterViewQuestionDropdown>(
  '[dropdown] CREATE_EXITINTERVIEWQUESTIONS_DROPDOWN',
);
export const storeExitInterviewQuestionsDropdowns = createAction<ExitInterViewQuestionDropdown[]>(
  '[dropdown] STORE_EXITINTERVIEWQUESTIONS_DROPDOWNS',
);
export const updateExitInterviewQuestionsDropdown = createAction<ExitInterViewQuestionDropdown>(
  '[dropdown] UPDATE_EXITINTERVIEWQUESTIONS_DROPDOWN',
);
