import React from 'react';
import styles from './styles.module.scss';
import { SelectGrp } from 'core/components/form-controls';
import '../time-card-styles.scss';
import { PayrollTimeCardCostCodeInfo, PayrollTimeCardJobInfo, PayrollTimeCardSubInfo, TimeCard, TimeCardDate } from 'core/models/Contractor.model';
import { useAppSelector } from 'utilities/hooks';
import { TimeSheetSummaryFilter } from './TimeSheetSummary';
import SearchBar from 'core/components/shared/SearchBar';

// #region Types
type DropDownValues = {
  id: string,
  description: string
};
// #endregion

// #region External functions
//Will get the unique jobs that are on the timecard
const getJobValues = (timeCards: TimeCard[], allJobs: PayrollTimeCardJobInfo[]) => {
  const uniqueJobs: DropDownValues[] = [];
  timeCards?.forEach(timeCard => {
    timeCard?.details?.forEach(detail => {
      if (uniqueJobs.findIndex(x => x.id === detail.jobNumber) === -1) {
        const description = allJobs.find(x => x.jobNo === detail.jobNumber)?.description || '';
        if (description !== '' || detail.jobNumber !== '')
          uniqueJobs.push({ id: detail.jobNumber, description: description });
      }
    });
  });
  return uniqueJobs;
};

//Will get the unique subs that are on the timecard
const getSubValues = (timeCards: TimeCard[], allSubs: PayrollTimeCardSubInfo[]) => {
  const uniqueSubs: DropDownValues[] = [];
  timeCards?.forEach(timeCard => {
    timeCard?.details?.forEach(detail => {
      if (uniqueSubs.findIndex(x => x.id === detail.subNumber) === -1) {
        const description = allSubs.find(x => x.subNo === detail.subNumber)?.description || '';
        if (description !== '' || detail.subNumber !== '')
          uniqueSubs.push({ id: detail.subNumber, description: description });
      }
    });
  });
  return uniqueSubs;
};

//Will get the unique subs that are on the timecard
const getCostCodeValues = (timeCards: TimeCard[], allCostCodes: PayrollTimeCardCostCodeInfo[]) => {
  const uniqueSubs: DropDownValues[] = [];
  timeCards?.forEach(timeCard => {
    timeCard?.details?.forEach(detail => {
      if (uniqueSubs.findIndex(x => x.id === detail.costCode) === -1) {
        const description = allCostCodes.find(x => x.costCode === detail.costCode)?.description || '';
        if (description !== '' || detail.costCode !== '')
          uniqueSubs.push({ id: detail.costCode, description: description });
      }
    });
  });
  return uniqueSubs;
};

//Will get the unique phase values off the timecard
const getPhaseValues = (timeCards: TimeCard[]) => {
  const uniquePhase: string[] = [];
  timeCards?.forEach(timeCard => {
    timeCard?.details?.forEach(detail => {
      if (uniquePhase.indexOf(detail.phase) === -1) {
        if (detail.phase !== '') 
          uniquePhase.push(detail.phase);
      }
    });
  });
  return uniquePhase;
};

//Will get the unique fo extra values off the timecard
const getFoExtraValues = (timeCards: TimeCard[]) => {
  const uniqueFoExtra: string[] = [];
  timeCards?.forEach(timeCard => {
    timeCard?.details?.forEach(detail => {
      if (uniqueFoExtra.indexOf(detail.foExtra) === -1) {
        if (detail.foExtra !== '')
          uniqueFoExtra.push(detail.foExtra);
      }
    });
  });
  return uniqueFoExtra;
};
// #endregion

type Props = {
  filters: TimeSheetSummaryFilter,
  setFilters: (newFilters: TimeSheetSummaryFilter) => void,
};

const SummaryActionBar = ({ filters, setFilters }: Props) => {
  // #region Hooks
  const { allJobs, allSubs, allCostCodes, timeCardDates, timeCards } = useAppSelector(({ contractor }) => contractor);
  const uniquePhase = getPhaseValues(timeCards);
  const uniqueFoExtra = getFoExtraValues(timeCards);
  const uniqueJobs = getJobValues(timeCards, allJobs);
  const uniqueSubs = getSubValues(timeCards, allSubs);
  const uniqueCostCodes = getCostCodeValues(timeCards, allCostCodes);
  // #endregion

  // #region Functions
  //This will set the top filter show to the proper value along with setting the state. This is used to calculate totals and show/hide timeCard and detail records. 
  const onChange = (value: string, option: keyof TimeSheetSummaryFilter) => {
    const filtersCopy: TimeSheetSummaryFilter = structuredClone(filters);

    switch (option) {
      case 'dayOfWeek':
        filtersCopy.dayOfWeek = +value;
        break;
      case 'jobNo':
        filtersCopy.jobNo = value;
        break;
      case 'subNo':
        filtersCopy.subNo = value;
        break;
      case 'costCode':
        filtersCopy.costCode = value;
        break;
      case 'foExtra':
        filtersCopy.foExtra = value;
        break;
      case 'phase':
        filtersCopy.phase = value;
        break;
      case 'query':
        filtersCopy.query = value;
        break;
    }

    setFilters(filtersCopy);
  };
  // #endregion
  
  const dayOfWeekOptions = [
    {
      id: -1,
      description: '<All>',
    },
    ...(timeCardDates?.map((x: TimeCardDate) => {
      return {
        id: x.dateId,
        description: `${x.dayOfWeek} ${x.fullDate}`,
      };
    }) ?? [])];
  
  // #region Render
  return (
    <>
      <div className="head-section">
        <strong>Filters</strong>
        <div className={styles['action-bar']}>
          <div className={styles['action-bar-item']}>
            <label htmlFor="dayOfWeek">Day of Week</label>
            <SelectGrp
              name="dayOfWeek"
              options={dayOfWeekOptions}
              groupClass="mw125"
              value={filters.dayOfWeek}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value, 'dayOfWeek')}
            />
          </div>
          <div className={styles['action-bar-item']}>
            <label htmlFor="job">Job</label>
            <SelectGrp
              name="job"
              options={uniqueJobs?.map((x: DropDownValues) => {
                return {
                  id: x.id,
                  description: x.description,
                };
              })}
              addEmptyValueText={'<All>'}
              addEmptyText
              showId
              groupClass="mw125"
              value={filters.jobNo}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value, 'jobNo')}
            />
          </div>
          <div className={styles['action-bar-item']}>
            <label htmlFor="sub">Sub</label>
            <SelectGrp
              name="sub"
              options={uniqueSubs?.map((x: DropDownValues) => {
                return {
                  id: x.id,
                  description: x.description,
                };
              })}
              addEmptyValueText={'<All>'}
              addEmptyText
              showId
              groupClass="mw125"
              value={filters.subNo}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value, 'subNo')}
            />
          </div>
          <div className={styles['action-bar-item']}>
            <label htmlFor="foe">FO/Extra</label>
            <SelectGrp
              name="foe"
              options={uniqueFoExtra?.map((x: string) => {
                return {
                  id: x,
                  description: x,
                };
              })}
              addEmptyValueText={'<All>'}
              addEmptyText
              groupClass="mw125"
              value={filters.foExtra}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value, 'foExtra')}
            />
          </div>
          <div className={styles['action-bar-item']}>
            <label htmlFor="phase">Phase</label>
            <SelectGrp
              name="phase"
              options={uniquePhase?.map((x: string) => {
                return {
                  id: x,
                  description: x,
                };
              })}
              addEmptyValueText={'<All>'}
              addEmptyText
              groupClass="mw125"
              value={filters.phase}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value, 'phase')}
            />
          </div>
          <div className={styles['action-bar-item']}>
            <label htmlFor="costCode">Cost Code</label>
            <SelectGrp
              name="costCode"
              options={uniqueCostCodes?.map((x: DropDownValues) => {
                return {
                  id: x.id,
                  description: x.description,
                };
              })}
              addEmptyValueText={'<All>'}
              addEmptyText
              showId
              groupClass="mw125"
              value={filters.costCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value, 'costCode')}
            />
          </div>
          <div className={styles['action-bar-item']}>
            <label htmlFor="table-search">Search</label>
            <SearchBar
              query={filters.query}
              onSearch={(newQuery: string) => {
                onChange(newQuery, 'query');
              }}
              placeholder="Search by name or emp #"
              showLabel={false}
            />
          </div>
        </div>
      </div>
    </>
  );
  // #endregion
};

export default SummaryActionBar;