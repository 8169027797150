import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  CheckboxGrpInLine,
} from '../../core/components/form-controls';
import InputReportDateSelector from '../../core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import { Client, DtoOptions } from '../../core/models';
import { useAppDispatch, useAppSelector } from '../../utilities/hooks';
import { CommonService } from '../../core/services';
import { OCIPReportRequest } from 'core/models/ContractorReports.model';
import { clearOCIPReport, downloadOCIPReport } from 'core/store/slices/contractorReports.slice';
import Icon from 'core/components/shared/Icon';

const OCIPReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const childRef: React.RefObject<any> = useRef();

  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [fileType, setFileType] = useState<string>('pdf');

  const client = useAppSelector((state) => { return state?.client?.client; }) as Client;

  const report = useAppSelector((state) => { return state?.contractorReports.ocipReport; });

  const hidePdfViewer = () => {
    dispatch(clearOCIPReport());
  };

  const { register, handleSubmit } =
    useForm<OCIPReportRequest>({
      defaultValues: {

      },
    });

  useEffect(() => {
    if (report && fileType === 'excel') {
      report &&
        CommonService.downloadBase64File(
          'application/excel',
          report,
          'OCIPReport.xlsx',
        );
      dispatch(clearOCIPReport());
    }
  }, [report]);

  const downloadReport = (data: OCIPReportRequest) => {
    const submitData: OCIPReportRequest = {
      clientNo: client.clientNo,
      dtoOptions: reportDates,
      byCheckDate: data.byCheckDate,
      reportType: fileType,
    };
    dispatch(downloadOCIPReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">
            OCIP Report
          </div>
          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title "
              onClick={() => { return setFileType('excel'); }}
              disabled={!reportDates.length}
            >
              Download Report{' '}
              <Icon name="download"
                className="fa-download" />
            </button>
          </div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div>
              <InputReportDateSelector
                ref={childRef}
                returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }
                }
              />
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <div className=" text-right">
                <button
                  type="button"
                  className="orange-outline-button mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="orange-button mr-2"
                  onClick={() => { return setFileType('pdf'); }}
                  disabled={!reportDates.length}
                >
                  Run Report
                </button>
              </div>

              <div
                className=" dm-panel dm-panel-border"
                style={{
                  marginTop: '66px',
                  width: '20%',
                }}
              >
                <div className="dm-grid-action-title mb-2">
                  Report Option
                </div>
                <div>
                  <div className="d-flex">
                    <div className="d-flex flex-row flex-grow-1">
                      <div className="d-flex flex-column flex-grow-1">

                        <CheckboxGrpInLine
                          name="byCheckDate"
                          ref={register}
                          labelFirst={false}
                          label="By Check Date"
                        />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {report ? (
          <PDFViewerModal
            show={report.length > 0}
            pdfData={report}
            onHide={hidePdfViewer}
            reportName="FullTimeEquivalentReport"
          />
        ) : null}
      </div>
    </div>
  );
};
export default OCIPReport;