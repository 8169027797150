import React, { useEffect, useState } from 'react';
import ATSInterview from './ATSInterview';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import AddInterviewModal from './AddInterviewModal';
import DeleteInterviewModal from './DeleteInterviewModal';
import { deleteApplicationInterview, getCustomInterviewEmails } from 'core/store/actions/applicant-tracking.action';
import { getInterviewTemplates, getScorecardCategories, getScorecardTraits } from 'core/store/slices/applicantTracking.slice';

type DeletePayload = {
  applicantId: number;
  interviewId: number;
};

const Interviews = () => {
  const [deleteModal, setDeleteModal] = useState<false | { applicantId: number; interviewId: number }>(false);
  
  const statusValues = useAppSelector((state) => {
    return state.applicantTrackingV2.interviews;
  });
  
  const appDispatch = useAppDispatch();
  
  useEffect(()=>{
    appDispatch(getScorecardCategories());
    appDispatch(getScorecardTraits());
    appDispatch(getInterviewTemplates());
  }, []);

  const onDelete = ({
    applicantId,
    interviewId,
  }: {
    applicantId: number;
    interviewId: number;
  }) => {
    appDispatch(deleteApplicationInterview({ applicantId,
      interviewId }));
    appDispatch(getCustomInterviewEmails());
  };
  return (
    <>
      <AddInterviewModal />
      <DeleteInterviewModal
        show={!!deleteModal}
        onAccept={() => {
          onDelete(
            deleteModal as {
              applicantId: number;
              interviewId: number;
            },
          );
          setDeleteModal(false);
        }}
        onHide={() => { setDeleteModal(false); }}
      />
      <div className="interviews">
        {statusValues?.length && statusValues.map((interview) => {
          return (
            <ATSInterview
              key={interview.interviewId}
              interview={interview}
              onDelete={(payload: DeletePayload) => { setDeleteModal(payload); }}
            />
          );
        })}
      </div>
    </>
  );
};

export default Interviews;
