import React, { FC, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules,  CellValueChangedEvent,  ColDef,  GridOptions, ValueSetterParams } from '@ag-grid-enterprise/all-modules';
import { useDispatch, useSelector } from 'react-redux';
import { agDateEditor } from 'utilities/ag-grid-editors';
import { agCurrencyRenderer, agDateRenderer } from 'utilities/ag-grid-renderers';
import { PayRateHistory } from 'core/models';
import { handleError, loadPayRatesHistory, updatePayRatesHistory } from 'core/store/actions';
import { getPayRatesHistory, getSelectedEmp } from 'core/store/selectors';
import Modal from './Modal';
import { AccessMap, getAccess, getAllAccess } from 'utilities/utilities';
import { useAppSelector } from 'utilities/hooks';
import { ControlIds } from 'core/constants';

const columns = (accessMap: AccessMap | null): ColDef[] => {
  const cols: ColDef[] = [
    {
      field: 'effectiveDate',
      headerName: 'EFFECTIVE DATE',
      sortable: true,
      editable: true,
      cellRenderer: 'dateRenderer',
      cellEditor: 'dateEditor',
      cellEditorParams: {
        minField: 'beginDateGuard',
        maxField: 'endDateGuard',
      },
      width: 125,
      cellStyle: {
        overflow: 'visible !important',
        'text-align': 'right',
      },
      valueSetter: (params: ValueSetterParams) => {
        const { oldValue, newValue } = params;
        if (!newValue) return false;
        const i = oldValue.indexOf('T');
        const time = i >= 0 ? oldValue.substr(i) : '';
        params.data.effectiveDate = newValue + time;
        return true;
      },
    },
  ];
  
  if (accessMap?.[ControlIds.hourlyRate]?.visible ?? true) {
    cols.push({
      field: 'hourlyRate',
      headerName: 'PAY RATE',
      sortable: true,
      cellRenderer: 'currencyRenderer',
      cellStyle: {
        'text-align': 'right',
      },
      width: 90,
    });
  }
  if (accessMap?.[ControlIds.annualRate]?.visible ?? true) {
    cols.push({
      field: 'annualRate',
      headerName: 'ANNUAL',
      sortable: true,
      cellRenderer: 'currencyRenderer',
      cellStyle: {
        'text-align': 'right',
      },
      width: 100,
    });
  }
  if (accessMap?.[ControlIds.salaryRate]?.visible ?? true) {
    cols.push({
      field: 'salaryRate',
      headerName: 'PER PAY',
      sortable: true,
      cellRenderer: 'currencyRenderer',
      cellStyle: {
        'text-align': 'right',
      },
      width: 100,
    });
  }
  
  cols.push({
    field: 'notes',
    headerName: 'NOTES',
    editable: true,
    width: 280,
  });
  
  return cols;
}; 

type PropTypes = {
  show: boolean;
  onHide: () => void;
};

const PayRateHistoryModal: FC<PropTypes> = ({ show, onHide }) => {
  const [rowData, setRowData] = useState<PayRateHistory[]>([]);

  const dispatch = useDispatch();
  
  const employee = useSelector(getSelectedEmp);
  const empPayRatesHistory: PayRateHistory[] = useSelector(getPayRatesHistory);
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  
  // get access map for all fields
  const allPayRateAccessMap = getAllAccess(sectionAccess?.find((x) => x.workItemId === 2));
  
  const gridOptions: GridOptions = useMemo(() => ({
    columnDefs: columns(allPayRateAccessMap),
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    components: {
      dateRenderer: agDateRenderer,
      dateEditor: agDateEditor,
      currencyRenderer: agCurrencyRenderer,
    },
    stopEditingWhenCellsLoseFocus: true,
  }), []);
  
  const gridHeight = (empPayRatesHistory?.length ?? 0) * 20 + 100;

  useEffect(() => {
    if (!employee) return;
    dispatch(loadPayRatesHistory(employee.protectedEmpNo));
  }, [dispatch, employee]);

  useEffect(() => {
    if (!empPayRatesHistory) return;
    setRowData(empPayRatesHistory);
  }, [empPayRatesHistory]);

  const onCellValueChanged = ({ data }: CellValueChangedEvent) => {
    const payRateHistory = rowData.find((h) => { return h.histId === data.histId; });
    if (!payRateHistory) return dispatch(handleError('No pay rate history record'));
    
    payRateHistory.effectiveDate = data.effectiveDate;
    payRateHistory.notes = data.notes;
  };

  const onSave = () => {
    if (!employee) return dispatch(handleError('Error finding employee'));
    
    const data = rowData.map((mappedPayRate: PayRateHistory) => {
      const newPayRateInstance = new PayRateHistory(
        mappedPayRate.clientNo,
        mappedPayRate.empNo,
        mappedPayRate.rateId,
        mappedPayRate,
      );

      // Hack  - date string of '9999-12-31T23:59:59.9999999' returns invalid date from toISOString()
      if (newPayRateInstance.endDateGuard?.toString().includes('+')) {
        newPayRateInstance.endDateGuard = new Date('9998-12-31T23:59:59.9999999');
      }

      return newPayRateInstance;
    });
      
    dispatch(
      updatePayRatesHistory({
        empNo: employee.protectedEmpNo,
        payRateHistoryData: data,
      }),
    );
  };

  const closeModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title="PAY RATE HISTORY"
    >
      <div className="row">
        <div
          className="col-12 table-wrapper-wrapper ag-theme-balham"
          style={{ height: gridHeight }}
        >
          <AgGridReact
            gridOptions={gridOptions}
            rowData={rowData}
            modules={AllModules}
            onCellValueChanged={onCellValueChanged}
          />
        </div>
        <div className="col-12 mb-1">
          <small>
            <strong>Disclaimer:</strong>
            The &ldquo;Effective Date&rdquo; is the date when the pay rate was changed in the Employee Master.&nbsp;
          </small>
          <small>
            Changing this will lose the history of when this pay rate was actually changed.
          </small>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-right">
          <button
            type="button"
            className="btn btn-primary orange-outline-button mr-2"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            {...getAccess(sectionAccess, 2, undefined, { disabledSameAsReadOnly: true })}
            type="button"
            className="btn btn-primary orange-button"
            onClick={onSave}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default PayRateHistoryModal;
