import React, { useState, CSSProperties, useEffect } from 'react';
import Icon from 'core/components/shared/Icon';
import { useHistory } from 'react-router-dom';
import { ControlTotalProps } from './types';
import ControlTotalView from './ControlTotalView';
import ControlTotalEdit from './ControlTotalEdit';
import { CreateControlTotalBookRequest, TransmittalEmployee, TransmittalParams } from 'core/models';
import { sumControlTotalData, transmittalHasData } from './utilities';
import { useAppDispatch } from 'utilities/hooks';
import { addBook, clearTransmittalEmployees, getAllTimeCards, getTimeCardDates, loadPayrollControlTotal, storeLatestPayroll, storeSelectedPayrollInfo } from 'core/store/actions';
import { useSelector } from 'react-redux';
import { getClientOptionIsContractor, getYesNoCMOption } from 'core/store/selectors';
import { PayrollService } from 'core/services';

type RenderValues = {
  iconName: 'circle-check' | 'circle-exclamation';
  iconColor: '#4a9125' | '#d73c28' | '#efbb47';
  iconLabel: 'Balanced' | 'Unbalanced' | 'No Data';
  renderClass: 'balanced' | 'unbalanced' | 'no-data';
};

// This can also be done in CSS (almost), but I think this is cleaner when adding it with the icon info 
const getRenderValues = (hasData: boolean, isBalanced: boolean): RenderValues => {
  if (!hasData) {
    return {
      iconName: 'circle-exclamation',
      iconColor: '#efbb47',
      iconLabel: 'No Data',
      renderClass: 'no-data',
    };
  }
  if (isBalanced) {
    return {
      iconName: 'circle-check',
      iconColor: '#4a9125',
      iconLabel: 'Balanced',
      renderClass: 'balanced',
    };
  }
  return {
    iconName: 'circle-exclamation',
    iconColor: '#d73c28',
    iconLabel: 'Unbalanced',
    renderClass: 'unbalanced',
  };
};

const getPaidEmps = (employees: TransmittalEmployee[] | undefined): number => {
  if (!employees?.length) return 0;
  
  const total: number = employees.flatMap((emp) =>
    emp.checks.flatMap((check) =>
      check.earnings.flatMap((earning) =>
        earning.amount,
      ),
    ),
  ).reduce((previous, current) => previous + current, 0);
  
  return total;
};

const ControlTotalPreview = ({
  controlTotal,
  mobileView,
  isProcessed,
  isWaiting,
  showAuxInfo,
  hasSignedWireOnlyAgreement,
  weekEnd,
  checkDate,
}: ControlTotalProps) => {
  const [editMode, setEditMode] = useState(false);
  const [description, setDescription] = useState(controlTotal.description); // update display without re-rendering entire list
  const [transmittalEmps, setTransmittalEmps] = useState<TransmittalEmployee[]>();
  const [reactiveStyles, setReactiveStyles] = useState<CSSProperties | undefined>({
    backgroundColor: controlTotal.payControlTotal ? '#d0fdba' : '#ffb4a7',
  }); 
  
  const canCreateBook = useSelector(getYesNoCMOption(295));
  const includeSalaryEmps = useSelector(getYesNoCMOption(161));
  const isContractor = useSelector(getClientOptionIsContractor);
  
  useEffect(() => {
    /* 
      we only want this data if we exclude salary employees from the control total. This is done so that the
      transmittal is shown as "balanced" rather than "no data" for these clients.   
    */
    if (includeSalaryEmps || sumControlTotalData(controlTotal) > 0) return;
    
    const fetchEmployees = async (params: TransmittalParams) => {
      const { data = [] } = await PayrollService.getTransmittalEmployees(params);
      setTransmittalEmps(data);
    };
    
    fetchEmployees({
      payrollHistoryId: controlTotal.payrollHistoryId,
      controlTotalId: controlTotal.controlTotalId,
    });
  }, []);
  
  const history = useHistory();
  
  const dispatch = useAppDispatch();
  
  /* 
    If we have negative special deductions on the transmittal we want to show it as having data.
    Additionally, if we exlclude salary employees from the total but are paying 1 or more on the
    transmittal, it should show that it has data.
 */
  const payingSalaryEmps = !includeSalaryEmps && (sumControlTotalData(controlTotal) === 0) && (getPaidEmps(transmittalEmps) > 0);
  const hasData = (transmittalHasData(controlTotal)) || payingSalaryEmps;
  const { iconName, iconColor, iconLabel, renderClass }: RenderValues = getRenderValues(hasData, controlTotal.balanced || payingSalaryEmps);
  
  const openTransmittal = () => {
    if (!isContractor) {
      dispatch(clearTransmittalEmployees()); //PI-8677 Added to fix bug that was caused by us needing to load transmittal employees in CardEdit.tsx and these do not have any earnings.
      dispatch(loadPayrollControlTotal({
        payrollHistoryId: controlTotal.payrollHistoryId,
        controlTotalId: controlTotal.controlTotalId,
      }));
      history.push(`/open-current-transmittal?payrollHistoryId=${controlTotal.payrollHistoryId}&controlTotalId=${controlTotal.controlTotalId}`);
    } else {
      dispatch(loadPayrollControlTotal({
        payrollHistoryId: controlTotal.payrollHistoryId,
        controlTotalId: controlTotal.controlTotalId,
      }));
      dispatch(getAllTimeCards(controlTotal.payrollHistoryId));
      dispatch(storeLatestPayroll(controlTotal.payrollHistoryId));
      dispatch(getTimeCardDates(weekEnd));
      dispatch(storeSelectedPayrollInfo({
        payrollHistoryId: controlTotal.payrollHistoryId,
        weekEnd: weekEnd,
        checkDate: checkDate,
      }));
      history.push(`/contractor-time-sheets?payrollHistoryId=${controlTotal.payrollHistoryId}`);
    }
  };
  
  // this is a bit silly, but will update displayed value
  const updateDescription = (newValue: string) => {
    setDescription(newValue);
  };
  
  return (
    <div
      className={`payroll-card-view sub-card-view ${renderClass}`}
      style={reactiveStyles && showAuxInfo ? reactiveStyles : undefined}
    >
      <div className="d-flex flex-column w-100">
        <div className="payroll-card-header px-2">
          <div className="payroll-header-info">            
            <Icon
              name={iconName}
              color={iconColor}
              className="mr-2"              
            />
            <span className="font-weight-bold">{mobileView ? null : iconLabel}</span>
            <span className="sr-only">{iconLabel}</span>
          </div>
          <div className="card-button-container">
            {!isContractor && canCreateBook && controlTotal.parentControlTotalId === null && (
              <button
                className="btn base-button btn-link"
                onClick={() => {
                  const bookParams: CreateControlTotalBookRequest = {
                    payrollHistoryId: controlTotal.payrollHistoryId,
                    controlTotalId: controlTotal.controlTotalId,
                    loc: controlTotal.loc,
                    dept: controlTotal.dept,
                    subDept: controlTotal.subDept,
                  };
                
                  dispatch(addBook(bookParams));
                }}
                disabled={isWaiting}
              >
                Add Book
              </button> 
            )}
            <button
              className="btn base-button btn-link"
              onClick={openTransmittal}
              disabled={isWaiting}
            >
              Open {isContractor ? 'Time Sheets' : 'Transmittal'}
            </button> 
            {!isProcessed && !isWaiting && !hasSignedWireOnlyAgreement ? (
              <div
                role="button"
                onClick={() => { setEditMode(!editMode); }}
              >
                <Icon
                  name="pen"
                  className="fa-pen"
                />
              </div>          
            ) : null}            
          </div>              
        </div>
        {!editMode ? (
          <ControlTotalView
            isProcessed={isProcessed}
            isWaiting={isWaiting}
            controlTotal={controlTotal}
            mobileView={mobileView}
            description={description}
            showAuxInfo={showAuxInfo}
            hasSignedWireOnlyAgreement={hasSignedWireOnlyAgreement}
            setReactiveStyles={setReactiveStyles}
          />
        ) : (
          <ControlTotalEdit
            controlTotal={controlTotal}
            mobileView={mobileView}
            description={description}
            updateDescription={updateDescription}
            exit={() => { return setEditMode(false); }}
          />
        )}
      </div>
    </div>
  );
};

export default ControlTotalPreview;