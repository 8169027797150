import { AgGridReact } from '@ag-grid-community/react';
import { ChangeDetectionStrategyType } from '@ag-grid-community/react/lib/shared/changeDetectionService';
import {
  AllModules,
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  RowSelectedEvent,
} from '@ag-grid-enterprise/all-modules';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { GlobalDeductionEmployee } from 'core/models';
import {
  agCheckboxEditor,
  agInputNumberEditor,
  agSelectEditor,
} from 'utilities/ag-grid-editors';
import {
  agCheckboxRenderer,
  agCurrencyRenderer,
  agLookupRenderer,
} from 'utilities/ag-grid-renderers';

const buildColumnDefs = (
  dedFreqOpts: any,
  dedUnitOpts: any,
): (ColDef | ColGroupDef)[] => {
  return [
    {
      field: 'process',
      headerName: 'Process',
      checkboxSelection: true,
      width: 72,
    },
    {
      headerName: 'From',
      groupId: 'From',
      children: [
        {
          field: 'amount',
          headerName: 'Ded. Amount',
          cellRenderer: 'currencyRenderer',
          width: 110,
        },
        {
          field: 'frequency',
          headerName: 'Frequency',
          cellRendererParams: { options: dedFreqOpts },
          cellRenderer: 'lookupRenderer',
          width: 90,
        },
        {
          field: 'unit',
          headerName: 'Unit',
          cellRendererParams: { options: dedUnitOpts },
          cellRenderer: 'lookupRenderer',
          width: 88,
        },
      ],
    },
    {
      headerName: 'To',
      groupId: 'To',
      children: [
        {
          field: 'newAmount',
          headerName: 'New Ded. A',
          editable: true,
          cellEditor: 'inputNumberEditor',
          cellRenderer: 'currencyRenderer',
          width: 100,
        },
        {
          field: 'newFrequency',
          headerName: 'New Freq.',
          editable: true,
          cellEditorParams: { options: dedFreqOpts },
          cellEditor: 'selectEditor',
          cellRendererParams: { options: dedFreqOpts },
          cellRenderer: 'lookupRenderer',
          width: 100,
        },
        {
          field: 'newUnit',
          headerName: 'New Unit',
          editable: true,
          cellEditorParams: { options: dedUnitOpts },
          cellEditor: 'selectEditor',
          cellRendererParams: { options: dedUnitOpts },
          cellRenderer: 'lookupRenderer',
          width: 88,
        },
      ],
    },
  ];
};

const gridOptions: GridOptions = {
  defaultColGroupDef: {
    headerClass: 'grid-header mx-auto',
    marryChildren: true,
    children: [],
  },
  defaultColDef: {
    suppressMenu: true,
    suppressMovable: true,
    resizable: true,
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
  // @ts-ignore
  components: {
    checkboxRenderer: agCheckboxRenderer,
    checkboxEditor: agCheckboxEditor,
    inputNumberEditor: agInputNumberEditor,
    currencyRenderer: agCurrencyRenderer,
    lookupRenderer: agLookupRenderer,
    selectEditor: agSelectEditor,
  },
  stopEditingWhenCellsLoseFocus: true,
};

type PropTypes = {
  data: any[];
  setEmployees: Dispatch<SetStateAction<GlobalDeductionEmployee[]>>;
  dedFreqOpts: any[];
  dedUnitOpts: any[];
  deductionCode: string;
  deductionName: string;
};

const GlobalMasterDeductions: React.FC<PropTypes> = ({
  data,
  setEmployees,
  dedFreqOpts,
  dedUnitOpts,
  deductionCode,
  deductionName,
}) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  useEffect(() => {
    gridApi &&
            gridApi.setColumnDefs(buildColumnDefs(dedFreqOpts, dedUnitOpts));
  }, [gridApi]);

  const onGridReady = (params: any) => {return setGridApi(params.api);};
  const onRowSelected = (event: RowSelectedEvent) => {return event.node.isSelected() && setEmployees(event.data.employees);};

  let titleBadge = null;
  if (deductionCode !== '') {
    titleBadge = <span className="dm-title-badge">{deductionCode}</span>;
  }

  return (
    <div
      className="dm-panel dm-panel-border"
      style={{ maxWidth: '700px' }}
    >
      <div className="row mb-2">
        <div className="col-12">
          <div className="dm-grid-title">
            {titleBadge}
            {deductionName} Deductions
          </div>
          <div className="dm-grid-title-subtext">
            Enter changes to Amount, Frequency and Unit (if
            applicable)
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-12 table-wrapper-wrapper ag-theme-balham"
          style={{ height: '500px' }}
        >
          <AgGridReact
            gridOptions={gridOptions}
            rowData={data}
            onGridReady={onGridReady}
            onRowSelected={onRowSelected}
            rowDataChangeDetectionStrategy={
              ChangeDetectionStrategyType.IdentityCheck
            }
            modules={AllModules}
          />
        </div>
      </div>
    </div>
  );
};

export default GlobalMasterDeductions;
