import React, { useState, useEffect } from 'react';
import {
  CheckCodeSelect,
  ControlDatePickerGrp,
  InputGrp,
  InputGrpCurrency,
  InputGrpDecimal,
  SelectGrp,
} from 'core/components/form-controls';
import { ArrayField } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getCheckCodes, getDropdownOptions, getClientDeductions, getDeductionUnitsWithRates, getPayRates, getClient, getSelectedEmp } from 'core/store/selectors';
import { CheckCode, Deduction } from 'core/models';
import { ClientDeduction } from 'core/models/ClientDeduction.model';
import DropdownOptionForm from 'core/components/form-controls/select-modal/DropdownOptionForm';
import { currencyFormatter, dateDiff, formatDecimal, getAccess } from 'utilities/utilities';
import { deductionStatusOptions } from 'dropdowns/deductionStatusOptions';
import APVendorsModal from './APVendors.modal';
import Icon from 'core/components/shared/Icon';
import { FormMethods } from 'core/components/form-controls/types';
import styles from 'core/components/form-controls/form-controls.module.scss';
import { validateRoutingNo } from 'core/helper/routingNumberValidator';
import { garnishmentCategoryApVendorOptions } from 'dropdowns/garnishmentCategoryOptions';
import { validateDeductionNumber, validateExpireDate } from './DeductionUtilites';
import { useAppSelector } from 'utilities/hooks';
import AlertModal from 'core/components/modals/alert-modal.modal';

type PropTypes = {
  deduction: Partial<ArrayField<Deduction>>;
  index: number;
  onDelete: (ded: Deduction) => void;
  isCheckCalculator?: boolean;
  formMethods: Omit<FormMethods, 'control'>;
  control: FormMethods['control'];
  onWatch?: (dedId: number, e: Deduction, valid: boolean) => void;
  updateNestedErrors?: (title: string, key?: string, propertyChain?: string) => void;
  onUpdateDeductionGarnishment?: (ded: Deduction) => void;
};

const EmpDeductionItem: React.FC<PropTypes> = ({
  deduction,
  onDelete,
  index,
  control,
  onWatch,
  updateNestedErrors,
  onUpdateDeductionGarnishment,
  formMethods: { errors, setValue, register, getValues, watch },
  isCheckCalculator = false,
}) => {

  const { deductionFrequencyOpts, deductionCodeOptions } = useSelector(getDropdownOptions);
  const checkCodeOpts = useSelector(getCheckCodes) as any;
  const [showApVendorModal, setShowApVendorModal] = useState(false);
  const clientDeductions = useSelector(getClientDeductions) as ClientDeduction[];
  const empPayRates = useSelector(getPayRates);
  const client = useSelector(getClient);
  const unitOptsWithRates = useSelector(getDeductionUnitsWithRates(empPayRates, client?.clientNo))?.filter(x => x.description.toLowerCase() !== '% of net');
  const employee = useSelector(getSelectedEmp);
  const [ageInYears, setAgeInYears] = useState(0);
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });

  //These will be to filter what is readOnly and what is not based on the client deduction thats selected that is selected
  // if it's a new deduction, these can all be enabled.
  const [booleanState, setBooleanState] = useState({
    readOnlyAccountNo: deduction.dedId !== 0,
    readOnlyRoutingNo: deduction.dedId !== 0,
    readOnly401KField: deduction.dedId !== 0,
    readOnlyAP: deduction.dedId !== 0,
  });
  const [currentDedNo, setCurrentDedNo] = useState(deduction?.dedNo || 0);
  const [showDeactivatedDedNoModal, setShowDeactivatedDedNoModal] = useState<boolean>(false);

  const statusOptionsWithShortage = [...deductionStatusOptions, { id: 'SHORTAGE', description: 'SHORTAGE' }];

  const deductionCodeOpts = deductionCodeOptions
    .filter((ded) => {
      return !(ded.code === 'S' || ded.code === 'C' || ded.code === '@');
    })
    .map((ded) => {
      return {
        ...ded,
        id: +ded.id,
        description: `${ded.id.padStart(2, '0')} - ${ded.description}`,
      };
    });

  useEffect(() => {
    if (updateNestedErrors) {
      updateNestedErrors('deductions', 'deductionItems');
    }
  }, [errors]);

  useEffect(() => {
    if (employee) {
      const currYear = new Date().getFullYear();
      // Only care if emp is going to turn 50 by year end.
      const x = dateDiff(employee.birthDate, new Date(currYear, 12, 31));
      setAgeInYears(x.years ?? 0);
    }
  }, [employee]);

  const dedNoName = `deductionItems[${index}].dedNo`;
  const statusName = `deductionItems[${index}].status`;
  const dedAmountName = `deductionItems[${index}].dedAmount`;
  const unitName = `deductionItems[${index}].unit`;
  const checkCodeName = `deductionItems[${index}].checkCode`;
  const freqName = `deductionItems[${index}].freq`;
  const dateStartName = `deductionItems[${index}].dateStart`;
  const dateExpireName = `deductionItems[${index}].dateExpire`;
  const begAmountName = `deductionItems[${index}].begAmount`;
  const currAmountName = `deductionItems[${index}].currAmount`;
  const routingNoName = `deductionItems[${index}].routingNo`;
  const bankAccountName = `deductionItems[${index}].bankAcctNo`;
  const apidName = `deductionItems[${index}].apid`;
  const shortageWeName = `deductionItems[${index}].arrearWeekEnd`;
  const shortageChName = `deductionItems[${index}].arrearCheckDate`;
  const _401kloanNumberName = `deductionItems[${index}]._401kloanNumber`;
  const entityTypeDescName = `deductionItems[${index}].entityDesc`;
  
  const watchedStatus = watch(`deductionItems[${index}].status`) ?? 'ACTIVE';
  const watchedFrequency = watch(`deductionItems[${index}].freq`) ?? (deduction?.freq ?? '0');
  const watchedApid = watch(`deductionItems[${index}].apid`);
  
  const onHideModal = (apid?: number) => {
    setShowApVendorModal(false);
    /*  PI-8454: according to new info in the RHF docs, we should be setting the entire array when using setValue with a field array.
    I also broke out the APID form field into a hidden input so that we don't have collisions between this and what the UI input
     is trying to do. */ 
    const _apid = apid || deduction.apid;
    const formDeductions: Partial<ArrayField<Deduction>>[] = getValues().deductionItems;
    const updatedDeduction: Partial<ArrayField<Deduction>> = formDeductions.find((x) => x.id === deduction.id) || deduction;
    
    updatedDeduction.apid = _apid ?? null;

    formDeductions.splice(index, 1, updatedDeduction);
    
    setValue('deductionItems', formDeductions, { shouldDirty: true });
  };
  
  const fs = {
    dedNo: {
      name: dedNoName,
      label: 'DED NO',
      groupClass: 'groupClass12',
      // NOTE: This is misleading. It's actually used to prevent default "required" behavior for the check calculator to track errors.
      required: !isCheckCalculator, 
      errorMessage: 'Deduction no. is required.',
      addOptionText: 'Deduction Code',
    },
    status: {
      name: statusName,
      label: 'STATUS',
      modalTitle: 'DEDUCTION STATUSES',
      groupClass: 'groupClass12',
      required: !isCheckCalculator,
      errorMsg: 'Status is required.',
    },
    dedAmount: {
      name: dedAmountName,
      label: 'AMOUNT',
      groupClass: 'groupClass12',
      required: !isCheckCalculator && watchedStatus !== 'DEACTIVE',
    },
    unit: {
      name: unitName,
      label: 'UNIT (%,H)',
      groupClass: 'groupClass12',
      modalTitle: 'UNITS',
      addOptionText: 'Units',
      formComponent: DropdownOptionForm,
    },
    checkCode: {
      name: checkCodeName,
      label: 'CHECK CODE',
      groupClass: 'groupClass12',
    },
    freq: {
      name: freqName,
      label: 'FREQUENCY',
      groupClass: 'groupClass12',
      modalTitle: 'FREQUENCIES',
      addOptionText: 'Frequency',
      formComponent: DropdownOptionForm,
      errorMsg: 'Frequency is required.',
    },
    dateStart: {
      name: dateStartName,
      label: 'START DATE',
      groupClass: 'groupClass12',
    },
    dateExpire: {
      name: dateExpireName,
      label: 'EXPIRE DATE',
      groupClass: 'groupClass12',
    },
    begAmount: {
      name: begAmountName,
      label: 'BEGIN AMOUNT',
      tallLabel: true,
      groupClass: 'groupClass12',
    },
    currAmount: {
      name: currAmountName,
      label: 'BALANCE',
      tallLabel: true,
      groupClass: 'groupClass12',
    },
    routingNo: {
      name: routingNoName,
      label: 'ROUTING NO',
      tallLabel: true,
      groupClass: 'groupClass12',
      type: 'number',
      errorMsg: 'If Routing No provided, Bank Acct No must be provided',
    },
    bankAcctNo: {
      name: bankAccountName,
      label: 'ACCOUNT NO',
      tallLabel: true,
      groupClass: 'groupClass12',
      errorMsg: 'If Bank Acct No provided, Routing No must be provided',
    },
    apid: {
      // this has a new name to distinguish it from the form field so we don't have any collisions
      name: 'apidUiInput',
      label: 'AP',
      tallLabel: true,
      groupClass: 'groupClass12',
    },
    shortageWe: {
      name: shortageWeName,
      label: 'SHORTAGE WEEK END',
      tallLabel: true,
      groupClass: 'groupClass12',
    },
    shortageCh: {
      name: shortageChName,
      label: 'SHORTAGE CHECK DATE',
      tallLabel: true,
      groupClass: 'groupClass12',
    },
    _401kloanNumber: {
      name: _401kloanNumberName,
      label: '401K LOAN NO',
      tallLabel: true,
      groupClass: 'groupClass12',
      groupStyle: { paddingRight: '0' },
    },
    entityDesc: {
      name: entityTypeDescName,
      label: 'SUB DESC',
      groupClass: 'groupClass40',
    },
  };

  function clientMasterSpecialChecks(dedNo: number) {
    const matchDeduction = clientDeductions.find(x => { return x.deductionNumber === dedNo; });
    if(matchDeduction?.deactivated) {
      setShowDeactivatedDedNoModal(true);
      return setValue(dedNoName, currentDedNo);
    } 

    setCurrentDedNo(dedNo);
    if (!matchDeduction) setBooleanState({
      readOnlyAP: true,
      readOnlyAccountNo: true,
      readOnlyRoutingNo: true,
      readOnly401KField: true,
    });
    else if (!matchDeduction?.loan_401k) setBooleanState((prevState) => {
      return {
        ...prevState,
        readOnly401KField: true,
      };
    });
    else setBooleanState((prevState) => {
      return {
        ...prevState,
        readOnly401KField: false,
      };
    });

    if (((matchDeduction?.paymentTypeCode ?? '') === 'E')) setBooleanState((prevState) => {
      return {
        ...prevState,
        readOnlyAP: false,
        readOnlyAccountNo: false,
        readOnlyRoutingNo: false,
      };
    });
    else setBooleanState((prevState) => {
      return {
        ...prevState,
        readOnlyAP: true,
        readOnlyAccountNo: true,
        readOnlyRoutingNo: true,
      };
    });

    //If it is a newly added deduction set the frequency to the matchDeduction frequency
    if ((deduction?.dedId ?? 0) < 1)
      setValue(freqName, matchDeduction?.frequencyCode ?? '0');
  }
  
  const apidAccess = getAccess(sectionAccess, 5, undefined, { readOnlyDefault: booleanState.readOnlyAP, disabledDefault: booleanState.readOnlyAP });

  useEffect(() =>{
    clientMasterSpecialChecks(getValues(dedNoName));
  }, []);

  const onFormClick = async () => {
    if (!onWatch) return;

    const formValues = getValues();
    const updatedDeduction = formValues.deductionItems.find((x: Deduction) => {return x.dedId === deduction.dedId;}) || deduction;
    onWatch(deduction.dedId || 0, updatedDeduction, true);
  };
  
  return (
    <div
      className="d-flex flex-column p-2"
      style={deduction.status === 'SHORTAGE' ? { backgroundColor: '#e8f8e0' } : undefined}
      onChange={onFormClick}
    >
      <div className="d-flex flex-wrap">
        <input
          type="hidden"
          name={`deductionItems[${index}].dedId`}
          defaultValue={deduction.dedId ?? 0}
          ref={register({ valueAsNumber: true })}
        />
        <input
          type="hidden"
          name={`deductionItems[${index}].id`}
          defaultValue={deduction?.id ?? ''}
          ref={register()}
        />
        <SelectGrp
          {...fs.dedNo}
          {...getAccess(sectionAccess, 5, undefined, { disabledDefault: ((deduction.dedId ?? 0) > 0) })}
          addEmptyValue
          options={deductionCodeOpts}
          errors={errors?.deductionItems?.[index]?.dedNo}
          labelClass={`${styles['dm-required']}`}
          selectClass={(deduction?.dedId ?? 0) < 1 ? styles['dm-form-control-highlight'] : ''}
          defaultValue={deduction.dedNo ?? 0}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            clientMasterSpecialChecks(parseInt(e.target.value));
            onUpdateDeductionGarnishment?.(deduction as Deduction);
          }}
          ref={register({
            valueAsNumber: true,
            validate: (value) => validateDeductionNumber(value, ageInYears, clientDeductions.find(x => x.deductionNumber === parseInt(value))),
          })}
          errorMsg="This employee is not eligible to have a Catch-Up. They must be turning 50 years of age during this calendar year."
        />
        <SelectGrp
          {...fs.status}
          {...getAccess(sectionAccess, 5, undefined, { disabledDefault: deduction.status === 'SHORTAGE' })}
          options={deduction.status === 'SHORTAGE' ? statusOptionsWithShortage : deductionStatusOptions}
          errors={errors?.deductionItems?.[index]?.status}
          labelClass={`${styles['dm-required']}`}
          value={watchedStatus}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (e.target.value === 'DEACTIVE') {
              setValue(freqName, ''); // clear frequency if deduction is deactivated
            }
            setValue(statusName, e.target.value);
            onUpdateDeductionGarnishment?.(deduction as Deduction);
          }}
          ref={register({
            required: {
              value: true,
              message: 'Status is required.',
            },
          })}
        />
        <InputGrpDecimal
          {...fs.dedAmount}
          {...getAccess(sectionAccess, 5, undefined, { disabledSameAsReadOnly: true })}
          labelClass={watchedStatus !== 'DEACTIVE' && `${styles['dm-required']}`}
          type={'text'}
          setValue={setValue}
          errors={errors?.deductionItems?.[index]?.dedAmount}
          ref={register({
            required: {
              value: watchedStatus !== 'DEACTIVE',
              message: 'Deduction Amount is Required' },
          })}
          strValue={formatDecimal(deduction?.dedAmount ?? 0)}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            e.target.value = formatDecimal(
              parseFloat(e?.target?.value),
            ) as string;
            onUpdateDeductionGarnishment?.(deduction as Deduction);
          }}
        />
        <SelectGrp
          {...fs.unit}
          {...getAccess(sectionAccess, 5)}
          defaultValue={deduction.unit ?? ''}
          errors={errors?.deductionItems?.[index]?.unit}
          ref={register}
          options={unitOptsWithRates}
          onChange={(_e: React.ChangeEvent<HTMLSelectElement>) => {
            onUpdateDeductionGarnishment?.(deduction as Deduction);
          }}
        />
        <CheckCodeSelect
          {...fs.checkCode}
          {...getAccess(sectionAccess, 5)}
          errors={errors?.deductionItems?.[index]?.checkCode}
          value={deduction.checkCode}
          setValue={setValue}
          options={checkCodeOpts}
          control={control}
          portalTargetId="ded-wrapper"
          onChange={(_e: CheckCode | undefined) => {
            onUpdateDeductionGarnishment?.(deduction as Deduction);
          }}
        />
        <SelectGrp
          {...fs.freq}
          {...getAccess(sectionAccess, 5)}
          errors={errors?.deductionItems?.[index]?.freq}
          labelClass={(!isCheckCalculator && watchedStatus !== 'DEACTIVE') && `${styles['dm-required']}`}
          value={watchedFrequency}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setValue(freqName, e.target.value);
            onUpdateDeductionGarnishment?.(deduction as Deduction);
          }}
          required={!isCheckCalculator && watchedStatus !== 'DEACTIVE'}
          ref={register({
            required: {
              value: !isCheckCalculator && watchedStatus !== 'DEACTIVE',
              message: 'Frequency is required.',
            },
          })}
          options={deductionFrequencyOpts.filter(x => x.id !== 'X')}
        />
        <ControlDatePickerGrp
          {...fs.dateStart}
          {...getAccess(sectionAccess, 5)}
          errors={errors?.deductionItems?.[index]?.dateStart}
          value={deduction?.dateStart ?? null}
          setValue={setValue}
          control={control}
          onChange={(_e: Date | null) => {
            onUpdateDeductionGarnishment?.(deduction as Deduction);
          }}
        />
        <ControlDatePickerGrp
          {...fs.dateExpire}
          {...getAccess(sectionAccess, 5)}
          errors={errors?.deductionItems?.[index]?.dateExpire}
          value={deduction?.dateExpire ?? null}
          setValue={setValue}
          rules={{
            validate: (value: Date) => { return validateExpireDate(value, new Date(getValues(dateStartName))); },
          }}
          control={control}
          onChange={(e: Date | null) => {
            onUpdateDeductionGarnishment?.(deduction as Deduction);
          }}
        />
      </div>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
      >
        <InputGrpCurrency
          {...fs.begAmount}
          {...getAccess(sectionAccess, 5)}
          defaultValue={deduction.begAmount ?? 0}
          setValue={setValue}
          errors={errors.begAmount}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            const value = parseFloat(
              e.target.value.replace(/[^0-9.+-]/g, ''),
            );
            const formattedValue = currencyFormatter(value);
            setValue(begAmountName, formattedValue);
            const cAmount = parseFloat(
              getValues(currAmountName).replace(
                /[^0-9.+-]/g,
                '',
              ),
            );
            if (cAmount === 0 || value === 0) setValue(currAmountName, formattedValue);
            onUpdateDeductionGarnishment?.(deduction as Deduction);
          }}
          ref={register}
          rules={{
            validate: () => {
            },
          }}
        />
        <InputGrpCurrency
          {...fs.currAmount}
          {...getAccess(sectionAccess, 5, undefined, { readOnlyDefault: true })}
          defaultValue={deduction.currAmount || 0}
          setValue={setValue}
          errors={errors.currAmount}
          ref={register}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onUpdateDeductionGarnishment?.(deduction as Deduction);
          }}
        />
        <InputGrp
          {...fs.routingNo}
          {...getAccess(sectionAccess, 5, undefined, { readOnlyDefault: deduction.dedNo !== 19 && deduction.dedNo !== 20 && booleanState.readOnlyRoutingNo, disabledDefault: isCheckCalculator && (deduction.dedId || 0) >= 0, disabledSameAsReadOnly: !isCheckCalculator })}
          errors={errors?.deductionItems?.[index]?.routingNo}
          defaultValue={deduction.routingNo}
          ref={register({ validate: (value: string) => validateRoutingNo(value) })}
          type={'text'}
          maxLength={9}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (getAccess(sectionAccess, 5)?.readOnly ?? false) return;
            onUpdateDeductionGarnishment?.(deduction as Deduction);
          }}
        />
        <InputGrp
          {...fs.bankAcctNo}
          {...getAccess(sectionAccess, 5, undefined, { readOnlyDefault: deduction.dedNo !== 19 && deduction.dedNo !== 20 && booleanState.readOnlyAccountNo, disabledDefault: isCheckCalculator && (deduction.dedId || 0) >= 0, disabledSameAsReadOnly: !isCheckCalculator })}
          errors={errors?.deductionItems?.[index]?.bankAcctNo}
          defaultValue={deduction.bankAcctNo}
          ref={register({
            validate: (value: any) => {
              const routingNo = getValues(routingNoName);
              if (!routingNo) return true;
              const res = !!(value && routingNo);
              return res;
            },
          })}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (getAccess(sectionAccess, 5)?.readOnly ?? false) return;
            onUpdateDeductionGarnishment?.(deduction as Deduction);
          }}
        />
        {/* 
            Two APID inputs so that the form state is decoupled from our UI since this is a special update. 
            The value can still be tracked by the form and the UI input won't cause any weird behavior in
            the form.            
        */}
        <input
          name={apidName}
          type="hidden"
          value={watchedApid}
          ref={register()}
        />
        <InputGrp
          {...fs.apid}
          {...apidAccess}
          errors={errors.apid}
          value={watchedApid}
          onClick={() => { 
            setShowApVendorModal(!booleanState.readOnlyAP && !(getAccess(sectionAccess, 5)?.readOnly ?? false)); 
          }}
        />
        <ControlDatePickerGrp
          {...fs.shortageWe}
          {...getAccess(sectionAccess, 5)}
          errors={errors.shortageWe}
          value={deduction?.arrearWeekEnd ?? null}
          setValue={setValue}
          control={control}
          onChange={(e: Date | null) => {
            onUpdateDeductionGarnishment?.(deduction as Deduction);
          }}
        />
        <ControlDatePickerGrp
          {...fs.shortageCh}
          {...getAccess(sectionAccess, 5)}
          errors={errors.shortageCh}
          value={deduction?.arrearCheckDate ?? null}
          setValue={setValue}
          control={control}
          onChange={(e: Date | null) => {
            onUpdateDeductionGarnishment?.(deduction as Deduction);
          }}
        />
        <InputGrp
          {...fs._401kloanNumber}
          {...getAccess(sectionAccess, 5, undefined, { readOnlyDefault: booleanState.readOnly401KField })}
          errors={errors._401kloanNumber}
          ref={register}
          defaultValue={deduction?._401kloanNumber}
          setValue={setValue}
          disabled={(isCheckCalculator && (deduction.dedId ?? 0) >= 0) || booleanState.readOnly401KField}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onUpdateDeductionGarnishment?.(deduction as Deduction);
          }}
        />
      </div>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
      >
        <InputGrp
          {...fs.entityDesc}
          {...getAccess(sectionAccess, 5)}
          errors={errors.entityDesc}
          defaultValue={deduction?.entityDesc || ''}
          ref={register({
            maxLength: {
              value: 100,
              message: 'Maximum length is 100 characters',
            },
          })}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onUpdateDeductionGarnishment?.(deduction as Deduction);
          }}
        />
      </div>
      <div className="d-flex justify-content-end">
        <div className="d-flex">
          <button
            {...getAccess(sectionAccess, 5, undefined, { disabledSameAsReadOnly: true })}
            type="button"
            className="btn btn-link dm-grid-action-title"
            onClick={() => { return onDelete(deduction as Deduction); }}
          >
            Remove Deduction{' '}
            <Icon
              name="minus-circle"
              className="fa-minus-circle"
            />
          </button>
        </div>
      </div>
      {showApVendorModal && (
        <APVendorsModal
          apid={watchedApid || 0}
          categoryId={0}
          entityId={+0}
          categoryOpts={garnishmentCategoryApVendorOptions}
          show={showApVendorModal}
          onHide={(apid?: number) => { onHideModal(apid); }}
        />
      )}
      {showDeactivatedDedNoModal && (
        <AlertModal
          title='Deactivated Deduction Number'
          message='You can not use a deactivated deduction number.'
          show={showDeactivatedDedNoModal}
          onHide={() => { setShowDeactivatedDedNoModal(false); }}
        />
      )}
    </div>
  );
};

export default EmpDeductionItem;
