import { AxiosResponse } from "axios";
import { GlobalDeduction, GlobalDeductionEmployee, HttpResponse } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getGlobalDeductions = (dedNo: number): Promise<AxiosResponse<GlobalDeduction>> => {return API_EMPLOYEE_MASTER.get<GlobalDeduction>(EmployeeEndPoints.GET_GLOBAL_DEDUCTIONS(CommonService.getProtectedClientNo(), dedNo));};

const postGlobalDeductions = (deds: GlobalDeductionEmployee[]): Promise<AxiosResponse<HttpResponse<GlobalDeductionEmployee[]>>> => {return API_EMPLOYEE_MASTER.post<HttpResponse<GlobalDeductionEmployee[]>>(EmployeeEndPoints.POST_GLOBAL_DEDUCTIONS(CommonService.getProtectedClientNo()), deds);};

export const GlobalDeductionService = {
  getGlobalDeductions,
  postGlobalDeductions,
};
