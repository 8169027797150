/* eslint-disable react/prop-types */
import React, { FC, ReactNode } from 'react';
import adjustmentStyles from './styles.module.scss';
import timeCardStyles from '../time-card-styles.module.scss';
import DetailRow from 'core/components/shared/DetailRow';
import { formatWithCommas } from 'utilities/utilities';

const arr = [0, 1, 2];
const nestedArr = [0, 1, 2, 3, 4];

type Props = {
};

const StateWhTotalsTable = ({ }: Props) => {
  return (
    <div className={`${adjustmentStyles['totals-table-container']} dm-panel dm-panel-border p-2`}>
      <div className="d-flex justify-content-between">
        <div className={timeCardStyles['section-title-blue']}>State W/H</div>
        <div className="font-weight-bold">Total W/H:&nbsp;{formatWithCommas(300)}</div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Tax Entity</th>
            <th>W/H</th>
            <th>Gross</th>
          </tr>
        </thead>
      </table>
      {arr.map((x) => {
        return (
          <DetailRow
            key={x}
            rowClick
          >
            <DetailRow.SummaryContainer>
              <table>
                <tbody>
                  <tr>
                    <td>48 - Michigan</td>
                    <td className={adjustmentStyles['cell-right']}>{formatWithCommas(100.00)}</td>
                    <td className={adjustmentStyles['cell-right']}>{formatWithCommas(10802.25)}</td>
                  </tr>
                </tbody>
              </table>
            </DetailRow.SummaryContainer>
            <DetailRow.DetailsContainer>
              <table className={adjustmentStyles['nested-table']}>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>W/H</th>
                    <th>Gross</th>
                    <th>Employee Tax</th>
                  </tr>
                </thead>
                <tbody>
                  {nestedArr.map((x) => {
                    return (
                      <tr key={x}>
                        <td>PFL ER</td>
                        <td className={adjustmentStyles['cell-right']}>{formatWithCommas(123.45)}</td>
                        <td className={adjustmentStyles['cell-right']}>{formatWithCommas(10802.25)}</td>
                        <td className={adjustmentStyles['cell-center']}>
                          <input
                            type="checkbox"
                            checked
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </DetailRow.DetailsContainer>
          </DetailRow>
        );
      })}
    </div>
  );
};

export default StateWhTotalsTable;
