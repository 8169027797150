import { AxiosResponse } from "axios";
import { EmpI9 } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getEmployeesI9 = (): Promise<AxiosResponse<EmpI9[]>> => {return API_EMPLOYEE_MASTER.get<EmpI9[]>(EmployeeEndPoints.EMPLOYEES_I9(CommonService.getProtectedClientNo()));};

const getEmployeeI9 = (protectedEmpNo: string): Promise<AxiosResponse<EmpI9>> => {return API_EMPLOYEE_MASTER.get<EmpI9>(EmployeeEndPoints.EMPLOYEE_I9(CommonService.getProtectedClientNo(), protectedEmpNo));};

const putEmployeeI9 = (data: EmpI9): Promise<AxiosResponse<EmpI9>> => {return API_EMPLOYEE_MASTER.put<EmpI9>(EmployeeEndPoints.EMPLOYEE_I9(CommonService.getProtectedClientNo(), data.protectedEmpNo), data);};

const postEmployeeI9DataReport = (): Promise<AxiosResponse<any>> => {return API_EMPLOYEE_MASTER.post<any>(EmployeeEndPoints.EMPLOYEE_I9_REPORT(CommonService.getProtectedClientNo()));};

export const EmployeeI9Service = {
  getEmployeesI9,
  getEmployeeI9,
  putEmployeeI9,
  postEmployeeI9DataReport,
};