import { createReducer } from '@reduxjs/toolkit';
import { HireHistory } from '../../models';
import { loadHireHistories, loadHireHistory, storeHireHistories, storeHireHistory, storeHireHistoryWizardMessage } from '../actions';

export interface State {
  loading: boolean;
  hireHistory: HireHistory[];
  hireHistoryWizardMessage: string;
}

export const INITIAL_STATE: State = {
  loading: false,
  hireHistory: [],
  hireHistoryWizardMessage: ''
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadHireHistories.type]: (state) => {return { ...state, loading: true };},
  [storeHireHistories.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      hireHistory: action.payload,
    };
  },

  [loadHireHistory.type]: (state) => {return { ...state, loading: true };},
  [storeHireHistory.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      hireHistory: [
        ...state.hireHistory.filter((h: HireHistory) => {return h.empDateID !== action.payload.empDateID;}),
        action.payload,
      ],
    };
  },

  [storeHireHistoryWizardMessage.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      hireHistoryWizardMessage: action.payload,
    };
  },
});