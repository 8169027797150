import { createAction } from '@reduxjs/toolkit';
import { ACA, ACA1095B, AcaStatus, ACAWaiveCoverage, CoveredIndividual, EmployeeInformationOverride, EmployeeShare, OfferOfCoverage, SafeHarbor, ZipCode } from 'core/models/ACA.model';
import { ClientACA } from 'core/models/ClientACA.model';

export const ACA_EM_VALIDATION_ACTIONS = {
  CREATE_EMPLOYEE_ACA_COVEREDINDIVIDUALS: '[emp-aca] CREATE_EMPLOYEE_ACA_COVEREDINDIVIDUALS',
  UPDATE_EMPLOYEE_ACA_COVEREDINDIVIDUALS: '[emp-aca] UPDATE_EMPLOYEE_ACA_COVEREDINDIVIDUALS',
  DELETE_EMPLOYEE_ACA_COVEREDINDIVIDUALS: '[emp-aca] DELETE_EMPLOYEE_ACA_COVEREDINDIVIDUALS',
  UPDATE_EMPLOYEE_ACA_OVERRIDE_OFFEROFCOVERAGE: '[emp-aca] UPDATE_EMPLOYEE_ACA_OVERRIDE_OFFEROFCOVERAGE',
  UPDATE_EMPLOYEE_ACA_OVERRIDE_EMPLOYEESHARE: '[emp-aca] UPDATE_EMPLOYEE_ACA_OVERRIDE_EMPLOYEESHARE',
  UPDATE_EMPLOYEE_ACA_OVERRIDE_SAFEHARBOR: '[emp-aca] UPDATE_EMPLOYEE_ACA_OVERRIDE_SAFEHARBOR',
  UPDATE_EMPLOYEE_ACA_OVERRIDE_ZIPCODE: '[emp-aca] UPDATE_EMPLOYEE_ACA_OVERRIDE_ZIPCODE',
  UPDATE_EMPLOYEE_ACA_OVERRIDE_EMPINFO: '[emp-aca] UPDATE_EMPLOYEE_ACA_OVERRIDE_EMPINFO',
  DELETE_EMPLOYEE_ACA_OVERRIDE: '[emp-aca] DELETE_EMPLOYEE_ACA_OVERRIDE',
  CREATE_EMPLOYEE_ACA_STATUS: '[emp-aca] CREATE_EMPLOYEE_ACA_STATUS',
  UPDATE_EMPLOYEE_ACA_STATUS: '[emp-aca] UPDATE_EMPLOYEE_ACA_STATUS',
  DELETE_EMPLOYEE_ACA_STATUS: '[emp-aca] DELETE_EMPLOYEE_ACA_STATUS',
  UPDATE_EMPLOYEE_ACA_1095B: '[emp-aca] UPDATE_EMPLOYEE_ACA_1095B',
  UPDATE_EMPLOYEE_ACA_WAIVE_COVERAGE: '[emp-aca] UPDATE_EMPLOYEE_ACA_WAIVE_COVERAGE',
  UPDATE_CLIENT_ACA: '[cn-aca] UPDATE_CLIENT_ACA',
} as const;

//PI-8822 Added the optional paramater because there are sometimes (Like the ACA Report) where you can have multiple clients so we want to send the proper clientNo.
export const loadEmployeeACATabData = createAction<{ protectedEmpNo: string, prYear: number, protectedClientNo?: string }>('[emp-aca] LOAD_EMPLOYEE_ACA_TAB_DATA');
export const storeEmployeeACATabData = createAction<ACA>('[emp-aca] STORE_EMPLOYEE_ACA_TAB_DATA');
export const clearEmployeeACATabData = createAction('[emp-aca] CLEAR_EMPLOYEE_ACA_TAB_DATA');

export const createEmployeeACACoveredIndividuals = createAction<CoveredIndividual>(ACA_EM_VALIDATION_ACTIONS.CREATE_EMPLOYEE_ACA_COVEREDINDIVIDUALS);
export const updateEmployeeACACoveredIndividuals = createAction<CoveredIndividual>(ACA_EM_VALIDATION_ACTIONS.UPDATE_EMPLOYEE_ACA_COVEREDINDIVIDUALS);
export const deleteEmployeeACACoveredIndividuals = createAction<CoveredIndividual>(ACA_EM_VALIDATION_ACTIONS.DELETE_EMPLOYEE_ACA_COVEREDINDIVIDUALS);

export const updateACAOverrideOfferOfCoverage = createAction<OfferOfCoverage>(ACA_EM_VALIDATION_ACTIONS.UPDATE_EMPLOYEE_ACA_OVERRIDE_OFFEROFCOVERAGE);
export const updateACAOverrideEmployeeShare = createAction<EmployeeShare>(ACA_EM_VALIDATION_ACTIONS.UPDATE_EMPLOYEE_ACA_OVERRIDE_EMPLOYEESHARE);
export const updateACAOverrideSafeHarbor = createAction<SafeHarbor>(ACA_EM_VALIDATION_ACTIONS.UPDATE_EMPLOYEE_ACA_OVERRIDE_SAFEHARBOR);
export const updateACAOverrideOfferZipCode = createAction<ZipCode>(ACA_EM_VALIDATION_ACTIONS.UPDATE_EMPLOYEE_ACA_OVERRIDE_ZIPCODE);
export const updateACAOverrideEmployeeInfo = createAction<EmployeeInformationOverride>(ACA_EM_VALIDATION_ACTIONS.UPDATE_EMPLOYEE_ACA_OVERRIDE_EMPINFO);
export const deleteACAOverride = createAction<{ protectedEmpNo: string, prYear: number }>(ACA_EM_VALIDATION_ACTIONS.DELETE_EMPLOYEE_ACA_OVERRIDE);

export const createEmployeeACAStatus = createAction<{ status: AcaStatus, prYear: number }>(ACA_EM_VALIDATION_ACTIONS.CREATE_EMPLOYEE_ACA_STATUS);
export const updateEmployeeACAStatus = createAction<{ status: AcaStatus, prYear: number }>(ACA_EM_VALIDATION_ACTIONS.UPDATE_EMPLOYEE_ACA_STATUS);
export const deleteEmployeeACAStatus = createAction<{ protectedEmpNo: string, effectiveDate: string, prYear: number }>(ACA_EM_VALIDATION_ACTIONS.DELETE_EMPLOYEE_ACA_STATUS);

export const loadEmployeeACA1095B = createAction<{ protectedEmpNo: string, prYear: number }>('[emp-aca] LOAD_EMPLOYEE_ACA_1095B');
export const storeEmployeeACA1095B = createAction<ACA1095B>('[emp-aca] STORE_EMPLOYEE_ACA_1095B');
export const updateEmployeeACA1095B = createAction<ACA1095B>(ACA_EM_VALIDATION_ACTIONS.UPDATE_EMPLOYEE_ACA_1095B);

export const updateEmployeeACAWaiveCoverage = createAction<ACAWaiveCoverage>(ACA_EM_VALIDATION_ACTIONS.UPDATE_EMPLOYEE_ACA_WAIVE_COVERAGE);

export const loadClientACA = createAction<{ year: number }>('[cn-aca] LOAD_CLIENT_ACA');
export const loadClientACALastYear = createAction<{ year: number }>('[cn-aca] LOAD_CLIENT_ACA_LASTYEAR');
export const storeClientACA = createAction<ClientACA>('[cn-aca] STORE_CLIENT_ACA');
export const storeClientACALastYear = createAction<ClientACA>('[cn-aca] STORE_CLIENT_ACA_LASTYEAR');
export const updateClientACA = createAction<ClientACA>(ACA_EM_VALIDATION_ACTIONS.UPDATE_CLIENT_ACA);