import { createReducer } from '@reduxjs/toolkit';
import { SupervisedEmployee } from '../../models';
import {
  loadSupervisorEmployees,
  storeSupervisorEmployees,
  reviseSupervisorEmployees,
  cleanupSupervisorEmployees,
} from '../actions';

export interface State {
  loading: boolean;
  supervisedEmployees: SupervisedEmployee[];
}

export const INITIAL_STATE: State = {
  loading: true,
  supervisedEmployees: [],
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadSupervisorEmployees, (state) => {
      state.loading = true;
    })
    .addCase(storeSupervisorEmployees, (state, action) => {
      state.loading = false;
      state.supervisedEmployees = action.payload;
    })
    .addCase(reviseSupervisorEmployees, (state, action) => {
      state.loading = false;
      const movedEmployees = action.payload.map(a => { return a.empId; });
      const currentList = state;
      const newList = currentList.supervisedEmployees.filter(a => {
        return !movedEmployees.includes(a.empId);
      });
      state.supervisedEmployees = newList;
    })
    .addCase(cleanupSupervisorEmployees, (state) => {
      state.loading = true;
      state.supervisedEmployees = [];
    });
});
