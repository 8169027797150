import { useForm } from 'react-hook-form';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { ACA1095B } from 'core/models';
import PanelHeader from 'core/components/shared/PanelHeader';
import { InputGrp, SelectGrp } from 'core/components/form-controls';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { updateEmployeeACA1095B } from 'core/store/actions';
import { getAca1095BOriginOfPolicy, getStates } from 'core/store/selectors';
import { Prompt } from 'react-router-dom';
import { useAppDispatch } from 'utilities/hooks';

const fs: FieldInputSettings = {
  originOfPolicy: { name: 'originOfPolicy', label: 'ENTER LETTER IDENTIFYING ORIGIN OF POLICY', groupClass: 'gc03' },
  partIICompanyName: { name: 'partIICompanyName', label: 'EMPLOYER NAME', groupClass: 'gc03' },
  partIICompanyEIN: { name: 'partIICompanyEIN', label: 'EMPLOYER IDENTIFICATION NUMBER (EIN)', groupClass: 'gc03' },
  partIICompanyAddress: { name: 'partIICompanyAddress', label: 'STREET ADDRESS (INCLUDING ROOM OR SUITE NO)', groupClass: 'gc03' },
  partIICompanyCity: { name: 'partIICompanyCity', label: 'CITY/TOWN', groupClass: 'gc03' },
  partIICompanyState: { name: 'partIICompanyState', label: 'STATE/PROVINCE', groupClass: 'gc03' },
  partIICompanyZip: { name: 'partIICompanyZip', label: 'COUNTRY AND ZIP OR FOREIGN POSTAL CODE', groupClass: 'gc03' },

  partIIIName: { name: 'partIIIName', label: 'Name', groupClass: 'gc03' },
  partIIIEIN: { name: 'partIIIEIN', label: 'EMPLOYER IDENTIFICATION NUMBER (EIN)', groupClass: 'gc03' },
  partIIIContactPhone: { name: 'partIIIContactPhone', label: 'CONTACT TELEPHONE NUMBER', groupClass: 'gc03' },
  partIIIAddress: { name: 'partIIIAddress', label: 'STREET ADDRESS', groupClass: 'gc03' },
  partIIIAddress2: { name: 'partIIIAddress2', label: 'ROOM OR SUITE NO.', groupClass: 'gc03 mw100' },
  partIIICity: { name: 'partIIICity', label: 'CITY/TOWN', groupClass: 'gc03' },
  partIIIState: { name: 'partIIIState', label: 'STATE/PROVINCE', groupClass: 'gc03' },
  partIIIZip: { name: 'partIIIZip', label: 'COUNTRY AND ZIP OR FOREIGN POSTAL CODE', groupClass: 'gc03' },
};

const spanStyle = {
  fontSize: 14,
  fontWeight: 600,
  paddingTop: '22px',
  paddingRight: '5px',
};

type PropTypes = {
  aca1095b: ACA1095B | null;
};

const ACA1095BSection = React.forwardRef(({ aca1095b }: PropTypes, ref: any) => {

  const dispatch = useAppDispatch();
  const stateOpts = useSelector(getStates);
  const acaOriginOfPolicyOpts = useSelector(getAca1095BOriginOfPolicy);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const { errors, formState, register, getValues, reset } = useForm<ACA1095B>();

  useEffect(() => {
    if (aca1095b) reset(aca1095b);
  }, [aca1095b]);

  useEffect(() => {
    if (formState) {
      setFormIsDirty(Object.keys(formState.touched).length !== 0 || formState.isDirty);
    }
  }, [formState]);

  useImperativeHandle(
    ref,
    () => {
      return {
        onSave() {
          const values = { ...aca1095b, ...getValues() };
          dispatch(updateEmployeeACA1095B(values));
        },
      };
    },
  );

  return (
    <form >
      <div>
        <Prompt
          when={formIsDirty}
          message={() => {return 'You have unsaved changes. Are you sure you want to leave this page?';}}
        />
        <div className="dm-panel dm-panel-border">
          <PanelHeader title="1095B Part II Employer Sponsored Coverage" >
          </PanelHeader>
          <div className="d-flex flex-row flex-wrap">
            <span style={spanStyle}>8. </span><SelectGrp
              {...fs.originOfPolicy}
              errors={errors.originOfPolicy}
              ref={register}
              options={acaOriginOfPolicyOpts}
            />
            <span style={spanStyle}>10. </span><InputGrp
              {...fs.partIICompanyName}
              errors={errors.partIICompanyName}
              ref={register}
            />
            <span style={spanStyle}>11. </span><InputGrp
              {...fs.partIICompanyEIN}
              errors={errors.partIICompanyEIN}
              ref={register}
            />
          </div>
          <div className="d-flex flex-row flex-wrap">
            <span style={spanStyle}>12. </span><InputGrp
              {...fs.partIICompanyAddress}
              errors={errors.partIICompanyAddress}
              ref={register}
            />
            <span style={spanStyle}>13. </span><InputGrp
              {...fs.partIICompanyCity}
              errors={errors.partIICompanyCity}
              ref={register}
            />
            <span style={spanStyle}>14. </span><SelectGrp
              {...fs.partIICompanyState}
              errors={errors.partIICompanyState}
              ref={register}
              options={stateOpts}
            />
            <span style={spanStyle}>15. </span><InputGrp
              {...fs.partIICompanyZip}
              errors={errors.partIICompanyZip}
              ref={register}
            />
          </div>
        </div>

        <div className="dm-panel dm-panel-border">
          <PanelHeader title="1095B Part III Issuer or Other Coverage Provider" >
          </PanelHeader>
          <div className="d-flex flex-row flex-wrap">
            <span style={spanStyle}>16. </span><InputGrp
              {...fs.partIIIName}
              errors={errors.partIIIName}
              ref={register}
            />
            <span style={spanStyle}>17. </span><InputGrp
              {...fs.partIIIEIN}
              errors={errors.partIIIEIN}
              ref={register}
            />
            <span style={spanStyle}>18. </span><InputGrp
              {...fs.partIIIContactPhone}
              errors={errors.partIIIContactPhone}
              ref={register}
            />
          </div>
          <div className="d-flex flex-row flex-wrap">
            <span style={spanStyle}>19. </span><InputGrp
              {...fs.partIIIAddress}
              errors={errors.partIIIAddress}
              ref={register}
            />
            <InputGrp
              {...fs.partIIIAddress2}
              errors={errors.partIIIAddress2}
              ref={register}
            />
            <span style={spanStyle}>20. </span><InputGrp
              {...fs.partIIICity}
              errors={errors.partIIICity}
              ref={register}
            />
            <span style={spanStyle}>21. </span><SelectGrp
              {...fs.partIIIState}
              errors={errors.partIIIState}
              ref={register}
              options={stateOpts}
            />
            <span style={spanStyle}>22. </span><InputGrp
              {...fs.partIIIZip}
              errors={errors.partIIIZip}
              ref={register}
            />
          </div>
        </div>
      </div>
    </form>
  );
});

ACA1095BSection.displayName = 'ACA1095BSection';

export default ACA1095BSection;