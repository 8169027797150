import React, { useState } from 'react';
import styles from './styles.module.scss';
import { InputGrp } from 'core/components/form-controls';
import AdjustmentTable from './AdjustmentTable';
import Icon from 'core/components/shared/Icon';
import AdjustmentSection from './AdjustmentSection';
import { currencyFormatter, formatWithCommas } from 'utilities/utilities';
import TimeCardSummaryFields from './TimeCardSummaryFields';
import AdjustmentEarnings from './AdjustmentEarnings';
import AdjustmentDeductions from './AdjustmentDeductions';
import StateWithholdings from './StateWithholdings';
import LocalWithholdings from './LocalWithholdings';
import VoidSearchModal from 'core/components/modals/VoidSearch.modal';

type Props = {
  payrollHistoryId: number;
  index: number;
  check: any;
  tabKey: number;
  isDirty: boolean
};

const SummaryTimeSheet = ({
  payrollHistoryId,
  index,
  check,
  tabKey,
  isDirty,
}: Props) => {
  const [showVoidSearchModal, setShowVoidSearchModal] = useState<boolean>(false);
  const [voidType, setVoidType] = useState<string>('');
  
  const onPrint = () => { };
  
  const onAdjustmentTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value.indexOf('Void') > -1) {
      setVoidType(e.target.value);
      setShowVoidSearchModal(true);
    }
  };
  
  return (
    <div className={styles['summary-sections-container']}>
      <div className="text-right">
        <button
          type="button"
          className="btn btn-link dm-grid-action-title"
          onClick={() => { onPrint(); }}
        >
          Print <Icon
            name="print"
            className="fa-print"
          />
        </button>
      </div>
      <div className={styles['summary-section-wrapper']}>
        <span className="font-weight-bold">Adjustments</span>
        <AdjustmentTable
          index={index}
          check={check}
          payrollHistoryId={payrollHistoryId}
          employeeAdjustment={null}
          errors={[]}
          adjustmentType={''}
          adjustmentCodeOpts={[]}
          disabled={false}
          voidDisabled={false}
          onAdjustmentTypeChange={onAdjustmentTypeChange}
        />
      </div>
      <div className={styles['summary-section-wrapper']}>
        <span className="font-weight-bold">Earnings &amp; Deductions</span>
        {/* Earnings */}
        <AdjustmentSection
          type="Earnings"
          tabKey={tabKey}
          isDirty={isDirty}
          isOpen
        >
          <AdjustmentSection.Summary>
            <span className="font-weight-bold">{currencyFormatter(1234.56)}</span>
          </AdjustmentSection.Summary>
          <AdjustmentSection.Details>
            <AdjustmentEarnings
              index={index}
              errors={[]}
            />
          </AdjustmentSection.Details>
        </AdjustmentSection>
        {/* Deductions */}
        <AdjustmentSection
          type="Deductions"
          tabKey={tabKey}
          isDirty={isDirty}
        >
          <AdjustmentSection.Summary>
            <span className="font-weight-bold">{currencyFormatter(321.45)}</span>
          </AdjustmentSection.Summary>
          <AdjustmentSection.Details>
            <AdjustmentDeductions
              index={index}
              errors={[]}
            />
          </AdjustmentSection.Details>
        </AdjustmentSection>
      </div>
      <div className={styles['summary-section-wrapper']}>
        <span className="font-weight-bold">Taxes &amp; Withholdings</span>
        {/* State Withholdings */}
        <AdjustmentSection
          type="State Withholdings"
          tabKey={tabKey}
          isDirty={isDirty}
        >
          <AdjustmentSection.Summary></AdjustmentSection.Summary>
          <AdjustmentSection.Details>
            <StateWithholdings
              payrollHistoryId={payrollHistoryId}
              index={index}
              errors={[]}
            />
          </AdjustmentSection.Details>
        </AdjustmentSection>
        {/* Local Withholdings */}
        <AdjustmentSection
          type="Local Withholdings"
          tabKey={tabKey}
          isDirty={isDirty}
        >
          <AdjustmentSection.Summary></AdjustmentSection.Summary>
          <AdjustmentSection.Details>
            <LocalWithholdings
              index={index}
              errors={[]}
            />
          </AdjustmentSection.Details>
        </AdjustmentSection>
      </div>
      <div className={styles['summary-section-wrapper']}>
        <div className="dm-panel dm-panel-border mt-1">
          <div className="d-flex justify-content-between">
            <div className="font-weight-bold">Net Pay</div>
            <div className="d-flex font-weight-bold">
              $
              <InputGrp
                name={'items[0].employeeTaxes[0].netPay'}
                groupClass="gc03 number-underline mw75 mb-0"
                value={formatWithCommas(18203.34)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles['summary-section-wrapper']}>
        <span className="font-weight-bold">Summary</span>
        <AdjustmentSection
          type="Adjustment Summary"
          tabKey={tabKey}
          isDirty={isDirty}
        >
          <AdjustmentSection.Summary></AdjustmentSection.Summary>
          <AdjustmentSection.Details>
            <TimeCardSummaryFields />
          </AdjustmentSection.Details>
        </AdjustmentSection>
      </div>
      <div className="d-flex mt-3">
        <button
          className="orange-outline-button ml-auto mr-2"
        >
          Cancel
        </button>
        <button
          className="orange-outline-button mr-2"
        >
          Delete
        </button>
        <button
          className="orange-button mr-2"
        >
          Save
        </button>
      </div>
      {showVoidSearchModal && (
        <VoidSearchModal
          payrollHistoryId={payrollHistoryId}
          protectedEmpNo=""
          empNo={1}
          show={showVoidSearchModal}
          onHide={() => { setShowVoidSearchModal(false); }}
          reset={() => { }}
          voidType={voidType}
        />
      )}
    </div>
  );
};

export default SummaryTimeSheet;