import { AxiosResponse } from "axios";
import { HttpResponse, OtherDate } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getOtherDates = (protectedEmpNo: string): Promise<AxiosResponse<OtherDate[]>> => { return API_EMPLOYEE_MASTER.get<OtherDate[]>(EmployeeEndPoints.GET_OTHER_DATES(CommonService.getProtectedClientNo(), protectedEmpNo)); };

const getOtherDate = (data: OtherDate): Promise<AxiosResponse<OtherDate>> => { return API_EMPLOYEE_MASTER.get<OtherDate>(EmployeeEndPoints.GET_OTHER_DATE(CommonService.getProtectedClientNo(), data.protectedEmpNo, data.otherDateID)); };

const postOtherDate = (data: OtherDate): Promise<AxiosResponse<HttpResponse<OtherDate>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<OtherDate>>(EmployeeEndPoints.POST_OTHER_DATE(CommonService.getProtectedClientNo(), data.protectedEmpNo), data); };

const putOtherDate = (data: OtherDate): Promise<AxiosResponse<HttpResponse<OtherDate>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<OtherDate>>(EmployeeEndPoints.PUT_OTHER_DATE(CommonService.getProtectedClientNo(), data.protectedEmpNo, data.otherDateID), data); };

const deleteOtherDate = (data: OtherDate): Promise<AxiosResponse<OtherDate>> => { return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.DELETE_OTHER_DATE(CommonService.getProtectedClientNo(), data.protectedEmpNo, data.otherDateID)); };

export const OtherDateService = {
  getOtherDates,
  getOtherDate,
  postOtherDate,
  putOtherDate,
  deleteOtherDate,
};
