import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IEEONoSetting } from '.../../core/models/HumanCapitalReports.model';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEEONoSettings, loadEEONoSettings } from 'core/store/actions';
import { getEEONoSettings } from 'core/store/selectors';
import AssignEEOOccupationalGroupItem from './AssignEEOOccupationalItem';
import { useAppSelector } from 'utilities/hooks';
import { Client } from 'core/models';
import Icon from 'core/components/shared/Icon';
import { uniqueId } from 'lodash';

type PropTypes = {
  show: boolean;
  onHide: () => void;
  setting?: IEEONoSetting;
};

const AssignEEOOccupationGroupModal: React.FC<PropTypes> = ({ show, onHide, setting }) => {

  const client = useAppSelector((state) => { return state.client.client; }) as Client;
  const eeoNoSeettingsFromStore = useSelector(getEEONoSettings) as IEEONoSetting[];
  const dispatch = useDispatch();
  const [eeoNoSettingsList, setEEONoSettingsList] = useState<IEEONoSetting[]>([]);

  useEffect(() => {
    dispatch(loadEEONoSettings());

  }, []);

  useEffect(() => {
    if (eeoNoSeettingsFromStore) {
      setEEONoSettingsList((eeoNoSeettingsFromStore || [] as IEEONoSetting[]).map(item => {
        return {
          ...item,
          uid: uniqueId(),
        };
      }));
    }
  }, [eeoNoSeettingsFromStore]);

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };

  const addEEONoSettings = () => {
    const addSetting: IEEONoSetting = {
      uid: uniqueId(),
      ruleID: 0,
      entityID: client.clientEntityID,
      clientID: client.clientID,
      clientNo: client.clientNo,
      loc: null,
      dept: null,
      sub: null,
      sub2: null,
      eeoid: null,
    };

    setEEONoSettingsList((prev: IEEONoSetting[]) => {
      return [
        ...prev,
        addSetting,
      ];
    });
  };

  const deleteAssignUnit = (item: IEEONoSetting) => {
    if (item.ruleID) {
      dispatch(deleteEEONoSettings(item));
    }

    setEEONoSettingsList(eeoNoSettingsList.filter((eeoUnit) => {
      return eeoUnit.uid !== item.uid;
    }));
  };

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        animation={false}
      >
        <div className="modal-header" >
          <div className="dm-grid-title">Assign Occupational Groups</div>
          <div className="d-flex flex-wrap ml-auto mt-auto">
            <button
              type="button"
              className="btn btn-link dm-grid-action-title pb-0"
              onClick={addEEONoSettings}
            >
              Add New Assignment
              <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          </div>
          <button
            type="button"
            onClick={closeModal}
            className="modal-close-btn ml-0"
          >
            <Icon name="times" />
          </button>
        </div>
        <Modal.Body>
          <div>
            {eeoNoSettingsList?.map((item) => {
              return (
                <div key={item.uid}>
                  <AssignEEOOccupationalGroupItem
                    onDelete={deleteAssignUnit}
                    setting={item}
                  />
                  <hr className="dm-panel-hr mt-0" />
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default AssignEEOOccupationGroupModal;



