import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { AxiosResponse } from 'axios';
import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import PasswordModal from '../../core/components/modals/Password.modal';
import { EmployeeService } from '../../core/services';
import { clearFile, getFile } from '../../core/store/slices/file.slice';
import { downloadBase64File } from '../base64';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getClientOptionRequirePassword } from 'core/store/selectors';
import { useSelector } from 'react-redux';

const DownloadEmployeeDocsRenderer = (props: ICellRendererParams) => {
  const dispatch = useAppDispatch();

  const fileInfo = useAppSelector((state) => { return state.file.file; });
  const requirePassword = useSelector(getClientOptionRequirePassword);

  const [download, setDownload] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  useEffect(() => {
    if (fileInfo && fileInfo.path === props.value && download) {
      let contentType = '';
      switch (fileInfo.extension) {
        case '.pdf':
          contentType = 'application/pdf';
          break;
        case '.docx':
          contentType =
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          break;
        case '.xlsx':
          contentType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          break;
      }
      if (fileInfo.extension === '.pdf' && requirePassword) {
        setShowPasswordModal(true);
      } else {
        downloadBase64File(contentType, fileInfo.data, fileInfo.name);
        dispatch(clearFile());
        setDownload(false);
      }
    }
  }, [fileInfo, download]);

  const downloadFile = () => {
    dispatch(getFile(props.value));
    setDownload(true);
  };

  const onHide = () => {
    setShowPasswordModal(false);
  };

  const addPasswordToPdf = (password: string) => {
    EmployeeService.putAddPasswordToPDF({
      password,
      pdf: fileInfo!.data,
    })
      .then((res: AxiosResponse<string>) => {
        downloadBase64File('application/pdf', res.data, fileInfo!.name);
        dispatch(clearFile());
        setDownload(false);
      })
      .catch((err: any) => { return console.error(err); });
  };

  return (
    <div>
      <button
        onClick={downloadFile}
        className="btn btn-link"
      >
        <Icon name="file-download" />
      </button>
      <PasswordModal
        show={showPasswordModal}
        onHide={onHide}
        addPasswordToPdf={addPasswordToPdf}
      />
    </div>
  );
};

export default DownloadEmployeeDocsRenderer;
