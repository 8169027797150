import { createReducer } from '@reduxjs/toolkit';
import {
  ClientMasterDtoTaxEntitiesClientTaxEntity,
  ClientMasterDtoTaxEntitiesFIPS,
  EmployeeTaxInfo,
  W4Info,
} from '../../models';
import {
  clearTaxMessages,
  loadCmTaxEntities,
  loadTaxes,
  loadW4Info,
  storeCmTaxEntities,
  storeTaxes,
  storeTaxMessages,
  storeW4Info,
  updateTaxes,
} from '../actions/tax.action';

export interface TaxState {
  loading: boolean;
  taxes: EmployeeTaxInfo | null;
  w4Info: W4Info[];
  clientMasterDtoTaxEntitiesClientTaxEntity: ClientMasterDtoTaxEntitiesClientTaxEntity[];
  messages: string[];
  isSuccess: boolean;
}

export const INITIAL_STATE: TaxState = {
  loading: false,
  taxes: null,
  w4Info: [],
  clientMasterDtoTaxEntitiesClientTaxEntity: [],
  messages: [],
  isSuccess: true,
};

export const taxReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadTaxes, (state) => {
      state.loading = true;
    })
    .addCase(storeTaxes, (state, action) => {
      state.loading = false;
      state.taxes = action.payload;
    })
    .addCase(updateTaxes, (state) => {
      state.loading = true;
    })
    .addCase(loadW4Info, (state) => {
      state.loading = true;
    })
    .addCase(storeW4Info, (state, action) => {
      state.loading = false;
      state.w4Info = action.payload;
    })
    .addCase(loadCmTaxEntities, (state) => {
      state.loading = true;
    })
    .addCase(storeCmTaxEntities, (state, action) => {
      state.loading = false;
      state.clientMasterDtoTaxEntitiesClientTaxEntity = action.payload;
    })
    .addCase(storeTaxMessages, (state, action) => {
      state.loading = false;
      state.messages = action.payload.messages;
      state.isSuccess = action.payload.isSuccess;
    })
    .addCase(clearTaxMessages, (state) => {
      state.loading = false;
      state.messages = [];
    });
});
