import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridOptions, MasterDetailModule } from '@ag-grid-enterprise/all-modules';
import { getMescAcaHours, updateMescAcaHours } from 'core/store/actions/human-capital-management/aca-full-time-calculation.action';
import { setDataKey } from 'core/store/slices/human-capital-management/aca-full-time-calculation.slice';
import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DateObject from 'react-date-object';
import { agDateRenderer } from 'utilities/ag-grid-renderers';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

type Props = {
  employee?: any;
  onClose?: () => void;
};

const formatDate = (value: string) => {
  if (!value) {
    return '';
  }

  return new DateObject(new Date(value)).format('MM/DD/YYYY');
};

const NewValueRenderer = (props: any) => {
  const dispatch = useAppDispatch();
  const mescData = useAppSelector((state) => {
    return state?.acaFullTimeReport?.data?.mescEmployee;
  });
  const mescDataUpdates = useAppSelector((state) => {
    return state?.acaFullTimeReport?.data?.mescEmployeeUpdates;
  });
  const data = props.data;

  let value = '';

  mescDataUpdates?.forEach((item => {
    item.details.forEach((detail: any) => {
      if (detail.acaId === data.acaId && detail.mescId === data.mescId) {
        value = detail.overrideHours;
      }
    });
  }));

  const onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const newData = cloneDeep(mescDataUpdates || mescData).map((item: any) => {
      item.details = item.details.map((detail: any) => {
        if (detail.acaId === data.acaId && detail.mescId === data.mescId) {
          return {
            ...detail,
            overrideHours: parseInt(e.target.value, 10),
          };
        }

        return detail;
      });

      return item;
    });

    dispatch(setDataKey({
      key: 'mescEmployeeUpdates',
      value: newData,
    }));
  };

  const onInputClick = (e: any) => {
    e.target.select();
  };

  return (
    <div className="p-1">
      <input
        type="number"
        className="rounded w-100 px-1 text-right"
        onClick={onInputClick}
        defaultValue={value || data?.overrideHours}
        style={{
          background: 'transparent',
          border: '1px solid rgb(176 175 175)',
          height: 24,
        }}
        onBlur={onBlur}
      />
    </div>
  );
};

const ACAEmp = ({
  employee,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const mescData = useAppSelector((state) => {
    return state?.acaFullTimeReport?.data?.mescEmployee;
  });
  const mescDataUpdates = useAppSelector((state) => {
    return state?.acaFullTimeReport?.data?.mescEmployeeUpdates;
  });

  useEffect(() => {
    if (!employee?.protectedEmpNo) {
      dispatch(setDataKey({
        key: 'mescEmployee',
        value: [],
      }));

      dispatch(setDataKey({
        key: 'mescEmployeeUpdates',
        value: null,
      }));

      return;
    }

    dispatch(getMescAcaHours(employee?.protectedEmpNo));
  }, [employee?.protectedEmpNo]);

  const columns: ColDef[] = [
    {
      field: 'checkDate',
      headerName: 'Check Date',
      cellRenderer: 'agGroupCellRenderer',
      sortable: true,
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        return formatDate(params.data.checkDate);
      },
    },
    {
      field: 'weekEnd',
      headerName: 'Week Ending',
      sortable: true,
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        return formatDate(params.data.weekEnd);
      },
    },
    {
      field: 'totalHours',
      headerName: 'Total Hours',
      sortable: true,
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        return params?.data?.totalHours?.toFixed(4);
      },
      cellClass: 'ag-cell-left-border text-right',
    },
    {
      field: 'regularHours',
      headerName: 'Regular',
      sortable: true,
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => {
        return params?.data?.regularHours?.toFixed(2);
      },
      cellClass: 'ag-cell-left-border text-right',
    },
    {
      field: 'timeAndHalfHours',
      headerName: 'One & Half',
      sortable: true,
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => {
        return params?.data?.timeAndHalfHours?.toFixed(2);
      },
      cellClass: 'ag-cell-left-border text-right',
    },
    {
      field: 'doubleHours',
      headerName: 'Double',
      sortable: true,
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => {
        return params?.data?.doubleHours?.toFixed(2);
      },
      cellClass: 'ag-cell-left-border text-right',
    },
    {
      field: 'otherHours',
      headerName: 'Other Hours',
      sortable: true,
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => {
        return params?.data?.otherHours?.toFixed(2);
      },
      cellClass: 'ag-cell-left-border text-right',
    },
  ];

  const gridOptions: GridOptions = {
    columnDefs: columns,
    domLayout: 'autoHeight',
    defaultColDef: {
      autoHeight: true,
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    pagination: true,
    paginationPageSize: 10,
    detailRowAutoHeight: true,
    detailCellRendererParams: {
      detailGridOptions: {
        frameworkComponents: {
          NewValueRenderer: NewValueRenderer,
        },
        defaultColDef: {
          autoHeight: true,
          suppressMenu: true,
          resizable: true,
          singleCickEdit: true,
          cellClass: 'ag-cell-left-border',
        },
        columnDefs: [
          {
            field: 'effectiveDate',
            headerName: 'Work Day',
            flex: 1,
            minWidth: 150,
            valueFormatter: (params: any) => {
              return formatDate(params.data.workDate);
            },
          },
          {
            field: 'dayOfWeek',
            headerName: 'Day of the Week',
            flex: 1,
            minWidth: 150,
          },
          {
            field: 'payrollHours',
            headerName: 'Payroll Hours',
            flex: 1,
            minWidth: 150,
            valueFormatter: (params: any) => {
              return params?.data?.payrollHours?.toFixed(6);
            },
            cellClass: 'ag-cell-left-border text-right',
          },
          {
            headerName: 'Enter New Value',
            flex: 1,
            minWidth: 150,
            cellRenderer: 'NewValueRenderer',
          },
        ],
      },
      getDetailRowData: function (params: any) {
        params.successCallback(params?.data?.details || []);
      },
    },
    components: {
      dateRenderer: agDateRenderer,
    },
  };

  const updateHours = () => {
    dispatch(updateMescAcaHours(employee.protectedEmpNo));
  };

  return (
    <Modal
      centered
      show={!!employee?.protectedEmpNo}
      onHide={() => {
        onClose?.();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Employee Hour Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-3 ag-theme-balham">
          <AgGridReact
            masterDetail
            gridOptions={gridOptions}
            rowData={mescData || []}
            overlayNoRowsTemplate={
              '<span>No Changes to Display</span>'
            }
            modules={[MasterDetailModule]}
          />
          <div className="d-flex justify-content-end">
            <Button
              className="mt-2"
              onClick={() => {
                updateHours();
              }}
              disabled={!mescDataUpdates}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ACAEmp;