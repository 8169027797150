import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import { Labels } from '.';
import styles from './form-controls.module.scss';

type PropTypes = {
  id?: string;
  name: string;
  label?: string | string[];
  tallLabel?: boolean;
  labelClass?: string;
  groupClass?: string;
  groupStyle?: Object;
  inputClass?: string;
  errors?: any;
  required?: boolean;
  value?: any;
  oldValue?: any;
  setValue?: any;
  control: any;
  rules?: object;
  errorMsg?: string;
  onChange?: any;
  disabled?: boolean;
  showTimeSelectOnly?: boolean;
};

export const ControlTimePickerGrp: React.FC<PropTypes> = ({
  id,
  name,
  label,
  tallLabel = false,
  labelClass,
  groupClass,
  groupStyle,
  inputClass,
  errors,
  required = false,
  setValue,
  control,
  value,
  rules,
  errorMsg,
  showTimeSelectOnly = false,
  disabled = false,
  onChange: onChangeHandler,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const focusRef = useRef<any>();

  const errMsg: string = errors
    ? errors.type === 'validate'
      ? errorMsg
      : errors.message
    : '';
  const groupClass2 = styles['dm-form-group'] + ' ' + (groupClass ?? '');
  id = id || name;
  value = value ?? '';

  const validateYear = (date: string) => {
    if (!date) {
      setErrorMessage(null);
      return;
    }

    const dateArray = date.split('/');
    const yearString = dateArray[2].replace(/\D/g, '');

    if (!yearString || yearString === '') {
      setErrorMessage(null);
      return;
    }
    if (yearString.length < 4) {
      setErrorMessage('Year must be 4 digits');
    } else {
      setErrorMessage(null);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Tab') {
      focusRef?.current?.setOpen(false);
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      onFocus={() => { setErrorMessage(null); }}
      defaultValue={value}
      render={({ onChange, value }) => {
        return (
          <div
            className={groupClass2}
            style={groupStyle}
          >
            <Labels
              label={label}
              tallLabel={tallLabel}
              labelClass={labelClass}
              id={id}
              hasError={!!errors}
              required={required}
            />
            <DatePicker
              showTimeSelect
              showTimeSelectOnly
              timeCaption="Time"
              dateFormat="hh:mm aa"
              timeIntervals={1}
              ref={focusRef}
              onKeyDown={onKeyDown}
              enableTabLoop={false}
              placeholderText={'HH:MM AM/PM'}
              autoComplete="off"
              disabled={disabled}
              preventOpenOnFocus={true}
              name={name}
              selected={
                Number.isNaN(Date.parse(value))
                  ? null
                  : new Date(value)
              }
              id={id}
              onChange={(e) => {
                if (onChangeHandler)
                  onChangeHandler(e);
                onChange(e);
              }}
              className={
                styles['dm-form-control'] +
                ' ' +
                (errors
                  ? ' ' + styles['dm-is-invalid']
                  : inputClass)
              }
            />
            {/* TODO: This is a bigger issue with how validation is set up for most inputs, will need a lot t ochange. */}
            <small className="text-danger d-flex">{errors?.message}</small>
            <small className="text-danger d-flex">{errMsg}</small>
            {errorMessage ? (
              <small className="text-danger d-flex">{errorMessage}</small>
            ) : null}
          </div>
        );
      }}
    />
  );
};
