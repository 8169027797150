import { createAction } from '@reduxjs/toolkit';
import { EmailTemplate, ICreateOnboardEmployee, JazzHRIntegrationRequest, JazzHRReturnData, OnboardDoc, OnboardingUpdate, OnboardPayrollUser, PageConfig, OnboardingSteps } from '../../models';

export const createOnboardEmployee = createAction<ICreateOnboardEmployee>('[onboarding] CREATE_ONBOARD_EMPLOYEE');
export const createOnboardEmployeeSuccess = createAction('[onboarding] CREATE_ONBOARD_EMPLOYEE_SUCCESS');
export const createOnboardEmployeeFail = createAction<any>('[onboarding] CREATE_ONBOARD_EMPLOYEE_FAIL');
export const setOnboardEmployeeStatus = createAction<any>('[onboarding] SET_ONBOARD_EMPLOYEE_STATUS');

export const loadOnboardPayrollUsers = createAction('[onboarding] LOAD_ONBOARD_PAYROLL_USERS');
export const storeOnboardPayrollUsers = createAction<OnboardPayrollUser[]>('[onboarding] STORE_ONBOARD_PAYROLL_USERS');

export const loadNextEmployeeNumber = createAction('[onboarding] LOAD_NEXT_EMPLOYEE_NUMBER');
export const storeNextEmployeeNumber = createAction<number>('[onboarding] STORE_NEXT_EMPLOYEE_NUMBER');
export const clearNextEmpNo = createAction('[onboarding] CLEAR_NEXT_EMP_NO');

export const loadOnboardDocs = createAction('[onboarding] LOAD_ONBOARD_DOCS');
export const storeOnboardDocs = createAction<OnboardDoc[]>('[onboarding] STORE_ONBOARD_createOnboardEmployeeOCS');

export const loadJazzHrIntegration = createAction('[onboarding] LOAD_JAZZHR_INTEGRATION');
export const storeJazzHrIntegration = createAction<JazzHRReturnData>('[onboarding] STORE_JAZZHR_INTEGRATION');
export const addUpdateJazzHrIntegration = createAction<JazzHRIntegrationRequest>('[onboarding] ADD_UPDATE_JAZZHR_INTEGRATION');

export const loadOnboardPageConfigs = createAction('[onboarding] LOAD_ONBOARD_PAGE_CONFIGS');
export const storeOnboardPageConfigs = createAction<PageConfig[]>('[onboarding] STORE_ONBOARD_PAGE_CONFIGS');
export const updateOnboardPageConfigs = createAction<PageConfig[]>('[onboarding] UPDATE_ONBOARD_PAGE_CONFIGS');
export const updateOnboardPageConfig = createAction<any>('[onboarding] UPDATE_ONBOARD_PAGE_CONFIG');

export const loadOnboardEmailTemplates = createAction('[onboarding] LOAD_ONBOARD_EMAIL_TEMPLATES');
export const storeOnboardEmailTemplates = createAction<EmailTemplate[]>('[onboarding] STORE_ONBOARD_EMAIL_TEMPLATES');
export const upsertOnboardEmailTemplates = createAction<EmailTemplate[]>('[onboarding] CREATE_ONBOARD_EMAIL_TEMPLATES');
export const deleteOnboardEmailTemplate = createAction<number>('[onboarding] DELETE_ONBOARD_EMAIL_TEMPLATE');

export const updateOnboardUpdates = createAction<OnboardingUpdate[]>('[onboarding] UPDATE_ONBOARD_UPDATES');
export const storeOnboardUpdates = createAction<OnboardingUpdate[]>('[onboarding] STORE_ONBOARD_UPDATES');

export const loadOnboardingStepsStatus = createAction<string>('[onboarding] LOAD_ONBOARDING_STEPS');
export const storeOnboardingStepsStatus = createAction<OnboardingSteps | null>('[onboarding] STORE_ONBOARDING_STEPS');
