import { AxiosResponse } from "axios";
import {
  EarningCode,
  EarningsCodeAutoCalculation,
  HttpResponse,
} from '../models';
import { API_CLIENT } from './API';
import { CommonService } from './common.service';
import { ClientMasterEndPoints } from './URL';

const getEarningCodes = (): Promise<AxiosResponse<EarningCode[]>> => {
  return API_CLIENT.get<EarningCode[]>(
    ClientMasterEndPoints.GET_EARNING_CODES(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getEarningCodesAutoCalculations = (): Promise<
AxiosResponse<EarningsCodeAutoCalculation[]>
> => {
  return API_CLIENT.get<EarningsCodeAutoCalculation[]>(
    ClientMasterEndPoints.EARNINGS_CODES_AUTOCALCULATIONS(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const putEarningCodesAutoCalculations = (
  data: EarningsCodeAutoCalculation[],
): Promise<AxiosResponse<HttpResponse<EarningsCodeAutoCalculation[]>>> => {
  return API_CLIENT.put<HttpResponse<EarningsCodeAutoCalculation[]>>(
    ClientMasterEndPoints.EARNINGS_CODES_AUTOCALCULATIONS(
      CommonService.getProtectedClientNo(),
    ),
    data,
  );
};

export const EarningCodeService = {
  getEarningCodes,
  getEarningCodesAutoCalculations,
  putEarningCodesAutoCalculations,
};
