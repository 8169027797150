import { createSelector } from 'reselect';
import { empFteState } from '../reducers';
import { State as EmpFteState } from '../reducers/emp-fte.reducer';

export const getEmpFte = createSelector(empFteState, (state: EmpFteState) => {
  return state.employeeFte
    ?.slice()
    .sort((a: any, b: any) => {
      return a.effectiveDate < b.effectiveDate
        ? 1
        : b.effectiveDate < a.effectiveDate
          ? -1
          : 0;
    },
    );
},
);
