import { SelectGrp } from 'core/components/form-controls';
import { RadioGrp, RadioOptions } from 'core/components/form-controls/RadioGrp';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import Icon from 'core/components/shared/Icon';
import { Client, JobTitles } from 'core/models';
import { EEO_CC257Request } from 'core/models/ContractorReports.model';
import { CommonService } from 'core/services';
import { getDropdownOptions } from 'core/store/selectors';
import { clearEEOC257Report, downloadEEOCC257Report } from 'core/store/slices/contractorReports.slice';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import Multiselect from 'wwagner4719-multiselect-react-dropdown';

const monthOptions = [
  {
    description: '',
    id: '0',
  },
  {
    description: 'January',
    id: '1',
  },
  {
    description: 'February',
    id: '2',
  },
  {
    description: 'March',
    id: '3',
  },
  {
    description: 'April',
    id: '4',
  },
  {
    description: 'May',
    id: '5',
  },
  {
    description: 'June',
    id: '6',
  },
  {
    description: 'July',
    id: '7',
  },
  {
    description: 'August',
    id: '8',
  },
  {
    description: 'September',
    id: '9',
  },
  {
    description: 'October',
    id: '10',
  },
  {
    description: 'November',
    id: '11',
  },
  {
    description: 'December',
    id: '12',
  },
];

const EEOCC257: React.FC = () => {
  const dispatch = useAppDispatch();

  const client = useAppSelector((state) => { return state?.client?.client; }) as Client;
  const report = useAppSelector((state) => { return state.contractorReports.eeoCC257; });
  const [jobs, setJobsData] = useState<JobTitles[]>([]);
  const [dates, setDates] = useState([{
    label: '',
    description: '',
  }]);
  const [filteredJobs, setFilteredJobs] = useState<string[]>([]);
  const [disbaleDropdown, setDisableDropdown] = useState<boolean>();

  const jobsOpts: RadioOptions[] = [
    {
      value: 'true',
      label: 'All Jobs',
    },
    {
      value: 'false',
      label: 'Filter Jobs',
    },

  ];

  const { jobTitlesOpts } =
    useSelector(getDropdownOptions);

  const hidePdfViewer = () => {
    dispatch(clearEEOC257Report());
  };


  const { register, handleSubmit, setValue } =
    useForm<EEO_CC257Request>({
      defaultValues: {
        month: DateTime.fromJSDate(new Date()).get('month'),
        year: DateTime.fromJSDate(new Date()).get('year'),
        includeAllJobs: 'true',

      },
    });


  const onRemove = (selectedList: any, removeItem: any) => {
    setJobsData(
      selectedList.filter((single: number) => { return single !== removeItem; }),
    );
    return selectedList;
  };


  useEffect(() => {
    //Populate Dropdown with dates
    const selectDates = [];
    for (let i = 0; i <= 10; i++) {
      selectDates.push({
        label: `${DateTime.fromJSDate(new Date()).year - i}`,
        description: `${DateTime.fromJSDate(new Date()).year - i}`,
      });
    }
    setDates(selectDates);
  }, []);

  useEffect(() => {
    if (report) {
      report &&
        CommonService.downloadBase64File(
          'application/excel',
          report,
          'EEOCC-257Report.xlsx',
        );
      dispatch(clearEEOC257Report());
    }
  }, [report]);

  const downloadReport = (data: EEO_CC257Request) => {
    const submitData: EEO_CC257Request = {

      clientNo: client.clientNo,
      month: data.month,
      year: data.year,
      includeAllJobs: data.includeAllJobs === 'true' ? true : false,
      filteredJobNos: data.includeAllJobs === 'true' ? [''] : getFilteredJobsArray(),
    };
    dispatch(downloadEEOCC257Report(submitData));
  };

  const getFilteredJobsArray = () => {
    const jobsFiltered = jobs?.map((item: any) => {
      return '' + item.id;
    });
    return jobsFiltered;
  };

  const changeRadioBtnVal = (e: any) => {

    if (e.target.value === 'true') {
      setValue('includeAllJobs', e.target.value);
      setDisableDropdown(true);
      setFilteredJobs(['']);
    } else if (e.target.value === 'false') {
      setDisableDropdown(false);
      setValue('includeAllJobs', e.target.value);
      getFilteredJobsArray();

    }

  };

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">
            EEO CC-257 Report
          </div>
          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title "

            >
              Download Report{' '}
              <Icon name="download"
                className="fa-download" />
            </button>
          </div>
          <hr className="dm-panel-hr" />
          <div className="d-flex flex-row row-wrap flex-grow-1">
            <div style={{ width: '10%' }}>
              <SelectGrp
                name="month"
                label="Month"
                tallLabel={true}
                options={monthOptions}
                ref={
                  register({
                    valueAsNumber: true,
                  })
                } />
            </div>
            <div style={{
              width: '10%',
              marginLeft: '30px',
            }}>
              <SelectGrp
                name="year"
                label="Year"
                tallLabel={true}
                options={dates}
                ref={
                  register({
                    valueAsNumber: true,
                  })
                } />
            </div>
          </div>

          <hr className="dm-panel-hr" />
          <div id="radioBtnsAndDropdown"
            className="d-flex flex-row row-wrap flex-grow-1">

            <div
              className="w-30 dm-panel dm-panel-border"
              style={{
                width: '30%',
                marginTop: '15px',
              }}

            >
              <div className="dm-grid-action-title mb-2">
                Job Options
              </div>

              <div className="d-flex flex-row">


                {/** 1st Row */}
                <div className="d-flex flex-row">


                  <div>
                    <RadioGrp
                      name="includeAllJobs"
                      ref={register}
                      radioOptions={jobsOpts}
                      isVertical={true}
                      onChange={changeRadioBtnVal}
                    />
                  </div>
                </div>

                {/** 2nd Row */}
                <div style={{
                  marginLeft: '70px',
                }}
                  className="d-flex flex-row">
                  <div style={{
                    marginLeft: '20px',
                    padding: '10px',
                  }}>
                    <label htmlFor="">Jobs</label>
                  </div>
                  <div >
                    <Multiselect
                      id="multiSelectEEOCC257"
                      onSelect={(selectedList) => { return setJobsData(selectedList); }}
                      options={jobTitlesOpts}
                      displayValue="description"
                      onRemove={onRemove}
                      groupBy="id"
                      showCheckbox={true}
                      selectedValueDecorator={(v) => {
                        return jobTitlesOpts.find(o => { return o.description === v; })?.code + ' - ' + v;
                      }}
                      disable={disbaleDropdown}
                    />
                  </div>

                </div>

              </div>
            </div>
          </div>
        </form>
        {
          report ? (
            <PDFViewerModal
              show={report.length > 0}
              pdfData={report}
              onHide={hidePdfViewer}
              reportName="EEOCC-257"
            />
          ) : null
        }
      </div >
    </div >
  );
};
export default EEOCC257;



