import React, { CSSProperties } from 'react';
import styles from './shared.module.scss';

type InputValue = string | number | readonly string[] | undefined;

type Props = {
  name: string;
  value?: InputValue;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  checked?: boolean;
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  showLabel?: boolean;
  topLabel?: boolean;
  label?: string;
  fieldsetClass?: string;
  inputClass?: string;
  labelClass?: string;
  readOnly?: boolean;
  disabled?: boolean;
  visible?: boolean;
  required?: boolean;
  hasError?: boolean;
};

function SimpleInput({
  name,
  value,
  onChange,
  checked = false,
  type = 'text',
  showLabel = true,
  topLabel = true,
  label = '',
  fieldsetClass = '',
  inputClass = '',
  labelClass = '',
  readOnly = false,
  visible = true,
  disabled = false,
  required = false,
  hasError = false,
}: Props) {
  let additionalLabelClasses = labelClass;
  
  if (required) (additionalLabelClasses += ' required');
  if (hasError) (additionalLabelClasses += ' text-danger');
  
  const inputStyle: CSSProperties = {
    height: '36px',
    borderRadius: '4px',
    borderColor: hasError ? 'red' : 'black',
  };
  
  return (
    <>
      {visible && (
        <fieldset className={`d-flex ${topLabel ? 'flex-column' : ''} ${fieldsetClass}`}>
          {showLabel && (
            <label
              htmlFor={name}
              className={`${styles['top-label']} ${additionalLabelClasses}`}
            >
              {label}
            </label>
          )}
          <input
            name={name}
            type={type}
            value={value}
            checked={checked}
            onChange={onChange}
            className={inputClass}
            style={inputStyle}
            readOnly={readOnly}
            aria-readonly={readOnly}
            disabled={disabled}
            aria-disabled={disabled}
            required={required}
            aria-required={required}
          />
        </fieldset>
      )}
    </>
  );
}

export default SimpleInput;