import React from 'react';
import Modal from 'core/components/modals/Modal';
import PdfViewer from 'core/components/shared/PDFViewer/PdfViewer';

const InterviewQuestionsReportModal = ({ show, onHide, pdf }: any) => {
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      title="Add Interview"
      size="lg"
    >
      <Modal
        centered
        show={show}
        onHide={onHide}
        title="DMPS Reporter View"
        size="lg"
      >
        {!!pdf && (
          <PdfViewer
            pdfData={pdf}
            reportName="Confirm Check Printing"
          />
        )}
      </Modal>
    </Modal>
  );
};

export default InterviewQuestionsReportModal;