import { Epic, ofType } from 'redux-observable';
import { catchError, map, switchMap, from, mergeMap } from 'rxjs';
import { handleError, handleSuccess } from '../actions/app.action';
import {
  loadDeferredCompensation,
  storeDeferredCompensation,
  triggerEmFieldValidation,
  updateDeferredCompensation,
} from '../actions';
import { DeferredCompensation } from '../../models/DeferredCompensation.model';
import { DeferredCompensationService } from '../../services';
import { HttpResponse } from '../../models';

type DCAction =
  | { type: typeof loadDeferredCompensation.type; payload: string }
  | { type: typeof storeDeferredCompensation.type; payload: DeferredCompensation }
  | { type: typeof updateDeferredCompensation.type; payload: DeferredCompensation };

type DCEpic = Epic<DCAction, DCAction>;

const loadDeferredCompensation$: DCEpic = (action$) => {
  return action$.pipe(
    ofType(loadDeferredCompensation.type),
    switchMap((action) => {
      return from(
        DeferredCompensationService.getDeferredCompensation(
          action.payload as string,
        ),
      ).pipe(
        map((res) => {return res.data;}),
        map((res: DeferredCompensation) => {return storeDeferredCompensation(res);},
        ),
        catchError((err) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

const updateDeferredCompensation$: DCEpic = (action$) => {
  return action$.pipe(
    ofType(updateDeferredCompensation.type),
    switchMap((action) => {
      return from(
        DeferredCompensationService.putDeferredCompensation(
          action.payload as DeferredCompensation,
        ),
      ).pipe(
        map((res) => {return res.data;}),
        mergeMap((res: HttpResponse<DeferredCompensation>) => {
          return [
            storeDeferredCompensation(res.value),
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'deferredcompensation',
              actionType: updateDeferredCompensation.type,
              callerPayload: res.value,
            }),
          ];
        },
        ),
        catchError((err) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

export const epics: DCEpic[] = [
  loadDeferredCompensation$,
  updateDeferredCompensation$,
];
