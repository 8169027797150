import React, { useEffect } from 'react';

import { InputGrp, SelectGrp, SelectModalGrp } from '../../core/components/form-controls';
import { FieldInputSettings } from '../../core/components/form-controls/types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getLookupEEOCertifyOfficial, getStates, getTaxEntities } from '../../core/store/selectors';
import { LookupEEOUnit } from '../../core/models';
import { createLookupEEOUnit, loadTaxEntities, updateLookupEEOUnit } from '../../core/store/actions';
import EEOCertifyingOfficialOptionForm from './EEOCertifyingOfficial';

import Icon from 'core/components/shared/Icon';
import { useAppDispatch } from 'utilities/hooks';

const statusOpts = [
  {
    id: 1,
    description: 'Single-Established Employer',
  },
  {
    id: 2,
    description: 'Consolidated Report',
  },
  {
    id: 3,
    description: 'Headquarters Report',
  },
  {
    id: 4,
    description: 'Establishment Report',
  },
  {
    id: 5,
    description: 'Special Reporting Procedure',
  },
  {
    id: 8,
    description: 'Less Than 50 Employees',
  },
  {
    id: 9,
    description: '50 Employees - First Time Reporting',
  },
];

const fs: FieldInputSettings = {
  number: {
    name: 'number',
    label: 'UNIT',
    groupClass: 'groupClass25',
  },
  nasics: {
    name: 'nasics',
    label: 'NAICS',
    groupClass: 'groupClass25',
  },
  statusCode: {
    name: 'statusCode',
    label: 'STATUS CODE',
    groupClass: 'groupClass25',
    showId: true,
  },

  name: {
    name: 'name',
    label: 'NAME',
    groupClass: 'groupClass50',
  },
  address: {
    name: 'address',
    label: 'ADDRESS 1',
    groupClass: 'groupClass50',
  },
  address2: {
    name: 'address2',
    label: 'ADDRESS 2',
    groupClass: 'groupClass50',
  },
  city: {
    name: 'city',
    label: 'CITY',
    groupClass: 'groupClass25',
  },
  state: {
    name: 'state',
    label: 'STATE',
    groupClass: 'groupClass25',
  },
  county: {
    name: 'county',
    label: 'COUNTY',
    groupClass: 'groupClass25',
    valueField: 'code',
    labelField: 'codeDesc',
  },
  zip: {
    name: 'zip',
    label: 'ZIP',
    groupClass: 'groupClass08',
    groupStyle: { paddingRight: '0' },
  },
  zip4: {
    name: 'zip4',
    label: ' ',
    groupClass: 'groupClass06',
  },
  bradStreetNo: {
    name: 'bradStreetNo',
    label: "DUN 'N BRADSTREET",
    groupClass: 'groupClass25',
  },
  certifyOfficerID: {
    name: 'certifyOfficerID',
    label: 'CERTIFYING OFFICIAL',
    groupClass: 'groupClass25',
    valueField: 'certifyOfficalID',
    labelField: 'name',
    modalTitle: 'CERTIFYING OFFICIAL',
    formComponent: EEOCertifyingOfficialOptionForm,
  },
};

type PropTypes = {
  selectedEEOUnit: LookupEEOUnit;
  onAdd: () => void;
  onDelete: () => void;
};

const EEOUnit: React.FC<PropTypes> = ({ selectedEEOUnit, onAdd, onDelete }) => {
  const dispatch = useAppDispatch();
  const stateOpts = useSelector(getStates);
  const certOfficalOpts = useSelector(getLookupEEOCertifyOfficial);
  const taxEntityOpts = useSelector(getTaxEntities);

  const { setValue, register, reset, errors, handleSubmit, watch, control } = useForm<any>({
    defaultValues: {
      ...selectedEEOUnit,
      zip4: '',
    },
  });
  const certifyOfficerID = watch('certifyOfficerID');

  useEffect(() => {
    if (selectedEEOUnit) {
      reset(selectedEEOUnit);
      selectedEEOUnit.state && dispatch(loadTaxEntities({
        country: 'US',
        state: selectedEEOUnit.state,
        type: 'CNT',
      }));
    }
  }, [selectedEEOUnit]);

  const onStateChange = (e: any) => {
    const state = e.target.value;
    dispatch(loadTaxEntities({
      country: 'US',
      state,
      type: 'CNT',
    }));
  };

  const onSave = (data: any) => {
    const x = {
      ...data,
      zip: data.zip + data.zip4,
    };
    delete x.zip4;
    const newEEOUnit = new LookupEEOUnit(selectedEEOUnit.eeoUnitID, selectedEEOUnit.clientEntityID, x);
    newEEOUnit.eeoUnitID
      ? dispatch(updateLookupEEOUnit(newEEOUnit))
      : dispatch(createLookupEEOUnit(newEEOUnit));
  };

  return (
    <form onSubmit={handleSubmit(onSave)} >
      <div className="row align-items-end">
        <div className="col-12 col-sm-6">
          <div className="dm-grid-title">Establishment</div>
        </div>
        <div className="col-12 col-sm-6 text-right">
          <button
            type="button"
            className="btn btn-link pb-0"
            onClick={onAdd}
          >
            <span className="dm-grid-action-title">Add EEO Unit</span> <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </div>
      </div>
      <hr className="dm-panel-hr" />
      <div className="d-flex flex-wrap">
        <InputGrp
          {...fs.number}
          errors={errors.number}
          ref={register}
        />
        <InputGrp
          {...fs.nasics}
          errors={errors.nasics}
          ref={register}
        />
        <SelectGrp
          {...fs.statusCode}
          errors={errors.statusCode}
          ref={register}
          options={statusOpts}
        />
        <InputGrp
          {...fs.name}
          errors={errors.name}
          ref={register}
        />
        <div className="groupClass50"></div>
        <InputGrp
          {...fs.address}
          errors={errors.address}
          ref={register}
        />
        <InputGrp
          {...fs.address2}
          errors={errors.address2}
          ref={register}
        />
        <InputGrp
          {...fs.city}
          errors={errors.city}
          ref={register}
        />
        <SelectGrp
          {...fs.state}
          errors={errors.state}
          ref={register}
          onChange={onStateChange}
          options={stateOpts}
        />
        <SelectGrp
          {...fs.county}
          errors={errors.county}
          ref={register}
          options={taxEntityOpts}
        />
        <InputGrp
          {...fs.zip}
          errors={errors.zip}
          ref={register}
        />
        <span style={{
          paddingTop: '22px',
          paddingLeft: '3px',
          paddingRight: '3px',
        }}
        >-</span>
        <InputGrp
          {...fs.zip4}
          errors={errors.zip4}
          ref={register}
        />
        <InputGrp
          {...fs.bradStreetNo}
          errors={errors.bradStreetNo}
          ref={register}
        />
        <SelectModalGrp
          {...fs.certifyOfficerID}
          options={certOfficalOpts}
          errors={errors.certifyOfficerID}
          control={control}
          value={certifyOfficerID}
          setValue={setValue}
        />
        <div className="col-12 text-right">
          <button
            type="button"
            className="orange-outline-button"
            onClick={onDelete}
          >Delete</button>
          <button
            type="submit"
            className="orange-button ml-2"
          >Save</button>
        </div>
      </div>
    </form>
  );
};

export default EEOUnit;
