import { AgGridReact } from '@ag-grid-community/react';
import {
  AllModules,
  ColDef,
  GridApi,
  GridOptions,
  RowNode,
} from '@ag-grid-enterprise/all-modules';
import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { EmailTemplate } from '../../core/models';
import {
  deleteOnboardEmailTemplate,
  loadOnboardEmailTemplates,
  upsertOnboardEmailTemplates,
} from '../../core/store/actions';
import { getOnboardEmailTemplates } from '../../core/store/selectors';
import AGDeleteHeaderComponent from '../../utilities/ag-grid-renderers/AGDeleteHeaderComponent';
import { useAppSelector } from '../../utilities/hooks';

const columns: ColDef[] = [
  {
    field: 'select',
    headerComponentFramework: AGDeleteHeaderComponent,
    checkboxSelection: true,
    cellStyle: { paddingLeft: '14px' },
    width: 45,
  },
  {
    field: 'templateName',
    headerName: 'TEMPLATE NAME',
    editable: (params: any) => { return params.data.templateName !== 'DEFAULT'; },
    width: 150,
  },
  {
    field: 'emailVerbiage',
    headerName: 'EMAIL BODY',
    editable: (params: any) => { return params.data.templateName !== 'DEFAULT'; },
    width: 500,
  },
];

const gridOptions: GridOptions = {
  columnDefs: columns,
  rowSelection: 'multiple',
  defaultColDef: {
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
  stopEditingWhenCellsLoseFocus: true,
};

type PropTypes = {
  show: boolean;
  onHide: any;
};
const OnboardEmailTemplateModal: React.FC<PropTypes> = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const clientNo = useAppSelector((state) => { return state.client.client!.clientNo; });

  const [gridHeight, setGridHeight] = useState<number>(100);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  let emailTemplates = useSelector(getOnboardEmailTemplates);

  useEffect(() => {
    dispatch(loadOnboardEmailTemplates());
  }, [dispatch]);

  useEffect(() => {
    emailTemplates && setGridHeight(emailTemplates.length * 26 + 80);
  }, [emailTemplates]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const addEmailTemplate = () => {
    emailTemplates.push(new EmailTemplate(clientNo));
    gridApi?.setRowData(emailTemplates);
    setGridHeight(emailTemplates.length * 26 + 80);
  };

  const onDeleteSelected = (e: any) => {
    if (gridApi !== null) {
      const selectedNodes: RowNode[] = gridApi.getSelectedNodes();
      emailTemplates = emailTemplates.filter((t) => {
        return selectedNodes.find(
          (node: RowNode) => { return node.data.credentialEmailID !== t.credentialEmailID; },
        );
      },
      );
      gridApi?.setRowData(emailTemplates);
      selectedNodes.forEach((rn: RowNode) => {
        return dispatch(
          deleteOnboardEmailTemplate(+rn.data.credentialEmailID),
        );
      },
      );
    }
  };

  const onSave = () => {
    const emailTemplates: EmailTemplate[] = [];
    if (gridApi) {
      gridApi.forEachNode((node: RowNode) => {
        // clientNo for the DEFAULT template is -1, don't save it.
        // node.data.clientNo > 0 && node.data.templateName && node.data.emailVerbiage && emailTemplates.push(node.data);
        node.data.templateName &&
          node.data.emailVerbiage &&
          emailTemplates.push(node.data);
      });
    }
    dispatch(upsertOnboardEmailTemplates(emailTemplates));
    closeModal();
  };

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        animation={false}
      >
        <div className="modal-header">
          <div className="dm-card-title">
            Credential Email Template
          </div>
          <button type="button"
            onClick={closeModal}>
            <Icon name="times"
              className="modal-close-btn" />
          </button>
        </div>
        <Modal.Body>
          <div className="row mt-3">
            <div
              className="col-12 text-right my-auto pr-4"
              onClick={() => { return addEmailTemplate(); }}
            >
              <span className="dm-grid-action-title">
                Create New Email Template
              </span>
              <Icon name="plus-circle"
                className="fa-plus-circle" />
            </div>
            <div
              className="col-12 table-wrapper-wrapper ag-theme-balham"
              style={{ height: gridHeight }}
            >
              <AgGridReact
                gridOptions={gridOptions}
                rowData={emailTemplates}
                modules={AllModules}
                onGridReady={onGridReady}
              />
            </div>
            <div className="col-12">
              <button
                type="button"
                className="btn btn-link"
                onClick={onDeleteSelected}
              >
                Delete Selected
              </button>
            </div>
            <div className="col-12 text-right mt-3">
              <button
                type="button"
                className="orange-outline-button"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="orange-button ml-2"
                onClick={onSave}
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default OnboardEmailTemplateModal;
