import { createSelector } from 'reselect';
import { IntegrationLog } from '../../models/IntegrationLog.model';
import { IntegrationState } from '../reducers/integration.reducer';
import { integrationState } from '../reducers';

export const getClientIntegrationLogs = createSelector(
    integrationState,
  (state: IntegrationState): IntegrationLog[] => {
    return state.integrationLogs;
  },
);