import React, { useEffect } from 'react';
import { Row, Col, Accordion } from 'react-bootstrap';
import newHireIcon from 'assets/images/applicants-new-hire.png';
import ContextAwareToggle from 'core/components/shared/ContextAwareToggle';
import { loadNewHireBreakdown } from 'core/store/actions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

type Props = {
  visible: boolean;
};

const NewHire = (props: Props) => {
  const newHireBreakdown = useAppSelector(
    (state) => {return state.dashboard.newHireBreakdown;},
  );
  const protectedClientNo = useAppSelector(
    (state) => {return state.auth.currentProtectedClientNo;},
  );
  const dispatch = useAppDispatch();

  if (!props.visible) return null;
  return (
    <>
      {newHireBreakdown !== null ? (
        <div className="dm-card">
          <div className="dm-card-body p-0">
            <div className="dm-card-body-title text-uppercase pl-2 pt-2">
              <img
                src={newHireIcon}
                alt="info"
              />
              <span>Applicants &amp; New Hires</span>
            </div>
            <Accordion defaultActiveKey={(newHireBreakdown?.newHiresOnLastPayroll ?? 0) > 0 ? '0' : undefined}>
              <ContextAwareToggle
                eventKey="0"
                className="blue-bar"
                backgroundColor=""
              >
                <Row className="blue-bar no-gutters">
                  <Col
                    xs={3}
                    className="my-auto pl-1 pr-0"
                  >
                    <div className="dm-dashboard-number mb-0 ">
                      {
                        newHireBreakdown.newHiresOnLastPayroll
                      }
                    </div>
                  </Col>
                  <Col
                    xs={9}
                    className="my-auto pl-0 pr-0"
                  >
                    <div>NEW HIRES</div>
                    <div
                      style={{
                        lineHeight: '18px',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      <span className="blue-bar-sub-title-label">
                        {
                          newHireBreakdown.lastPayrollDates
                        }
                      </span>
                    </div>
                  </Col>
                </Row>
              </ContextAwareToggle>
              <Accordion.Collapse eventKey="0">
                <Row>
                  <Col
                    xs={{ offset: 4,
                      span: 8 }}
                    md={{ offset: 3,
                      span: 9 }}
                    className="pl-0 pt-2"
                  >
                    <div className="dm-dashboard-strong-text">
                      Year Totals
                    </div>
                    <div className="dm-dashboard-text">
                      {newHireBreakdown.newHiresThisYear}{' '}
                      New Hires
                    </div>
                    <div className="dm-dashboard-text">
                      {
                        newHireBreakdown.currentYearApplicants
                      }{' '}
                      Applicants
                    </div>
                  </Col>
                </Row>
              </Accordion.Collapse>
            </Accordion>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default NewHire;
