import React from 'react';
import '@react-pdf-viewer/core/lib/styles/index.css';
import PdfViewer from '../shared/PDFViewer/PdfViewer';
import Modal from './Modal';

type PropTypes = {
  show: boolean;
  onHide: () => void;
  pdfData: any;
  reportName: string;
  fileType?: string;
};

const PDFViewerModal: React.FC<PropTypes> = ({
  show,
  onHide,
  pdfData,
  reportName,
  fileType,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      title="Report Preview"
    >
      <PdfViewer
        pdfData={pdfData}
        reportName={reportName}
        fileType={fileType}
      />
    </Modal>
  );
};
export default PDFViewerModal;
