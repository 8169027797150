
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { SelectModalGrp } from 'core/components/form-controls';
import GradeLevelOptionForm from 'core/components/form-controls/select-modal/GradeLevelOptionForm';
import { HireGeneralRequest } from 'core/models/HREmployee.model';
import {
  loadEmployeeHireGeneral,
  updateEmployeeHireGeneral,
} from 'core/store/actions/hr-employee.action';
import { getPayGrades } from 'core/store/selectors';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { currencyFormatter } from 'utilities/utilities';

type Form = {
  gradeLevel: number;
};

type Props = {
  updateDirtyState: (newVal: boolean) => void;
};

export const Pay: React.FC<Props> = ({ updateDirtyState }) => {
  const dispatch = useAppDispatch();

  const [minPayGrade, setMinPayGrade] = useState(0);
  const [midPayGrade, setMidPayGrade] = useState(0);
  const [maxPayGrade, setMaxPayGrade] = useState(0);

  const payGradesOpts = useSelector(getPayGrades);
  const protectedEmpNo = useAppSelector(
    (state) => {return state.selEmployee.employee?.protectedEmpNo;},
  );

  const hireGeneral = useAppSelector(
    (state) => {return state.hrEmployeeInfo.employeeHireGeneral;},
  );

  const { watch, setValue, control, errors } = useForm<Form>({
    defaultValues: {
      gradeLevel: 0,
    },
  });

  const gradeLevel = watch('gradeLevel');

  useEffect(() => {
    const payGrade = payGradesOpts.find((pg) => {return pg.id === gradeLevel;});
    if (payGrade) {
      setMinPayGrade(payGrade.minAmount);
      setMidPayGrade(payGrade.midAmount);
      setMaxPayGrade(payGrade.maxAmount);
    }
    if (payGrade && hireGeneral && payGrade.id !== hireGeneral.gradeLevel) {
      const data = { ...hireGeneral, gradeLevel };
      const payload: HireGeneralRequest = {
        protectedEmpNo: protectedEmpNo ?? '',
        data: data!,
      };
      dispatch(updateEmployeeHireGeneral(payload));
    }
  }, [gradeLevel]);

  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(
        loadEmployeeHireGeneral({
          protectedEmpNo,
        }),
      );
    }
  }, [protectedEmpNo]);

  useEffect(() => {
    if (hireGeneral) {
      setValue('gradeLevel', hireGeneral.gradeLevel);
    }
  }, [hireGeneral]);

  return (
    <>
      {hireGeneral && (
        <Row>
          <Col>
            <SelectModalGrp
              noOption={true}
              modalTitle="GRADE LEVEL MAINTENANCE"
              required={true}
              formComponent={GradeLevelOptionForm}
              addOptionText="GRADE LEVEL"
              name={'gradeLevel'}
              options={payGradesOpts}
              errors={errors.gradeLevel}
              control={control}
              value={hireGeneral.gradeLevel}
              setValue={setValue}
              label="Grade Level"
              dropdownName="payGrade"
              onChange={() => {
                updateDirtyState(true);
              }}
            />
          </Col>
          <Col>
            <div className="form-label mt-1">Min</div>
            <div>{currencyFormatter(minPayGrade)}</div>
          </Col>
          <Col>
            <div className="form-label mt-1">Mid</div>
            <div>{currencyFormatter(midPayGrade)}</div>
          </Col>
          <Col>
            <div className="form-label mt-1">Max</div>
            <div>{currencyFormatter(maxPayGrade)}</div>
          </Col>
        </Row>
      )}
    </>
  );
};
