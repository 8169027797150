import { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ContextAwareToggle from 'core/components/shared/ContextAwareToggle';
import { MarkRequestTaken } from 'core/models/MarkRequestTaken.model';
import { TimeOffRequest } from 'core/models/TimeOffRequest.model';
import { TimeOffRequestDetail } from 'core/models/TimeOffRequestDetail.model';
import { updateMarkTaken } from 'core/store/actions';
import { getEarningsCodes } from 'core/store/selectors';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { convToDateString } from 'utilities/utilities';

interface TimeOffRequestWithTotalHours extends TimeOffRequest {
  totalHours: number;
}

type Props = {
  tor: TimeOffRequest;
  update: (tor: TimeOffRequest) => void;
  fromLandingPage?: boolean;
};

const TimeOffRequestComponent = ({ tor, fromLandingPage, update }: Props) => {
  const dispatch = useAppDispatch();
  const [timeOffReq, setTimeOffReq] =
    useState<TimeOffRequestWithTotalHours>();
  const earningsCodeOpts = useSelector(getEarningsCodes);
  const employees = useAppSelector((state) => { return state.employees.employees; });
  const currentEmployee = employees.find(
    (e) => { return e.empNo === timeOffReq?.empNo; },
  );

  useEffect(() => {
    setTimeOffReq({
      ...tor,
      totalHours: tor.details.reduce((acc, det) => { return acc + det.hours; }, 0),
    });
  }, [tor]);

  const onClickMarkAsTaken = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tor: TimeOffRequest,
  ) => {
    e.stopPropagation();
    dispatch(
      updateMarkTaken(
        new MarkRequestTaken(
          tor.clientNo,
          tor.empNo,
          tor.protectedEmpNo,
          tor.timeOffRequestId,
          { taken: true },
        ),
      ),
    );
    update(tor);
  };

  return (
    <>
      {timeOffReq && currentEmployee ? (
        <Accordion key={timeOffReq.timeOffRequestId}>
          <ContextAwareToggle eventKey="0">
            <div className="d-flex">
              <div className="d-flex flex-column col-md">
                <div className="font-weight-bold">
                  Date
                  <br />
                  Created
                </div>
                <div>
                  {convToDateString(
                    timeOffReq.details[0]?.date,
                  )}
                </div>
              </div>
              {fromLandingPage ? (
                <div className="d-flex flex-column col-md">
                  <div className="font-weight-bold">
                    Employee
                    <br />
                    Name
                  </div>
                  <div>
                    {currentEmployee?.firstName}{' '}
                    {currentEmployee?.lastName}
                  </div>
                </div>
              ) : null}
              <div className="d-flex flex-column col-md">
                <div className="font-weight-bold">
                  Earnings
                  <br />
                  Code
                </div>
                <div>
                  {
                    earningsCodeOpts.find(
                      (a) => { return a.id === timeOffReq.earningsCode; },
                    )?.description
                  }
                </div>
              </div>
              <div className="d-flex flex-column col-md">
                <div className="font-weight-bold">
                  Total
                  <br />
                  Hours
                </div>
                <div>{timeOffReq.totalHours.toFixed(2)}</div>
              </div>
              <div className="d-flex flex-column col-md">
                <div className="font-weight-bold">
                  Request
                  <br />
                  Comment
                </div>
                <div>{timeOffReq.requestComment}</div>
              </div>
              <div className="d-flex flex-column col-md">
                <div className="font-weight-bold">
                  Reply
                  <br />
                  Comment
                </div>
                <div>{timeOffReq.replyComment}</div>
              </div>
              <div className="d-flex flex-column col-md">
                <div className="font-weight-bold">
                  Mark
                  <br />
                  Taken
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary font-weight-bold"
                    onClick={(e) => { return onClickMarkAsTaken(e, tor); }
                    }
                  >
                    Mark as Taken
                  </button>
                </div>
              </div>
            </div>
          </ContextAwareToggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="testclass ml-3">
              {tor?.details.length > 0 && (
                <table
                  className="table table-bordered table-sm table-hover"
                  style={{
                    display: 'table',
                    width: '600px',
                  }}
                >
                  <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                  </colgroup>
                  <thead className="thead-light">
                    <tr>
                      <th className="font-weight-bold">
                        Date
                      </th>
                      <th className="font-weight-bold">
                        Hours
                      </th>
                      <th className="font-weight-bold">
                        Start Time
                      </th>
                      <th className="font-weight-bold">
                        All Day
                      </th>
                      <th className="font-weight-bold">
                        Taken
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {timeOffReq.details.map(
                      (
                        tord: TimeOffRequestDetail,
                        index,
                      ) => {
                        return (
                          <tr
                            key={`${tord.timeOffRequestId}-${index}`}
                          >
                            <td
                              style={{
                                textAlign: 'right',
                              }}
                            >
                              {convToDateString(
                                tord.date,
                              )}
                            </td>
                            <td
                              style={{
                                textAlign: 'right',
                              }}
                            >
                              {tord.hours.toFixed(4)}
                            </td>
                            <td
                              style={{
                                textAlign: 'right',
                              }}
                            >
                              {tord.startTime}
                            </td>
                            <td
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <input
                                type="checkbox"
                                readOnly
                                checked={
                                  tord.allDay
                                }
                              />
                            </td>
                            <td
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <input
                                type="checkbox"
                                readOnly
                                checked={tord.taken}
                              />
                            </td>
                          </tr>
                        );
                      },
                    )}
                  </tbody>
                </table>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
      ) : null}
    </>
  );
};
export default TimeOffRequestComponent;
