import Modal from 'core/components/modals/Modal';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ControlDatePickerGrpInLine, InputGrpInLine, SelectGrpInLine } from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { AccrualManualEntry, Employee } from 'core/models';
import { createAccrualManualEntry } from 'core/store/actions';
import { getAccruals } from 'core/store/selectors';
import { convToDateISOString, convToDateString, toFixed } from 'utilities/utilities';

const fs: FieldInputSettings = {
  balanceId: {
    name: 'balanceId',
    label: 'SELECT BALANCE DATE',
    labelWidth: 50,
    groupClass: 'groupClass50',
  },
  dateEntered: {
    name: 'dateEntered',
    label: 'DATE ENTERED',
    labelWidth: 50,
    groupClass: 'groupClass50',
  },

  note: {
    name: 'note',
    label: 'NOTES',
    labelWidth: 24,
    groupClass: 'groupClass80',
  },
};

type PropTypes = {
  selectedEmp: Employee;
  earningsCode: string;
  shortDescription: string;
  type: string;
  show: boolean;
  onHide: () => void;
};

const AccrualAmountModal: React.FC<PropTypes> = ({
  selectedEmp,
  earningsCode,
  shortDescription,
  type,
  show,
  onHide,
}) => {
  const dispatch = useDispatch();
  const accruals = useSelector(getAccruals);

  const [dateOpts, setDateOpts] = useState<any[]>([]);

  const { register, handleSubmit, errors, control, setValue, watch } =
    useForm<AccrualManualEntry>({
      defaultValues: {
        clientNo: selectedEmp.clientNo,
        empNo: selectedEmp.empNo,
        protectedEmpNo: selectedEmp.protectedEmpNo,
        balanceId: 0,
        type,
        dateEntered: new Date(),
      },
    });
  const dateEntered = watch('dateEntered');

  useEffect(() => {
    if (accruals) {
      const dates: any[] = [];
      accruals
        .filter((a) => { return a.earningsCode === earningsCode; })
        .forEach((a) => {
          dates.push({
            id: a.balanceId,
            description: convToDateString(a.asOfDate),
          });
        });
      setDateOpts(dates);
    }
  }, [accruals]);

  const onSave = (data: AccrualManualEntry) => {
    const newAccrualEntry: AccrualManualEntry = {
      clientNo: selectedEmp.clientNo,
      empNo: selectedEmp.empNo,
      protectedEmpNo: selectedEmp.protectedEmpNo,
      balanceId: data.balanceId,
      type: data.type,
      amount: data.amount,
      dateEntered: convToDateISOString(data.dateEntered),
      note: data.note,
    };
    newAccrualEntry.type = type === 'Earned' ? 'Earned' : 'AdjustTaken';
    dispatch(createAccrualManualEntry(newAccrualEntry));
    closeModal();
  };

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title={`INSERT ${type.toUpperCase()} AMOUNT - ${shortDescription.toUpperCase()}`}
    >
      <form onSubmit={handleSubmit(onSave)}>
        <div className="d-flex flex-column">
          <SelectGrpInLine
            {...fs.balanceId}
            errors={errors.balanceId}
            ref={register}
            options={dateOpts}
          />
          <ControlDatePickerGrpInLine
            {...fs.dateEntered}
            value={dateEntered}
            control={control}
          />
          <InputGrpInLine
            name="amount"
            label="AMOUNT"
            labelWidth={50}
            groupClass="groupClass50"
            inputClass="text-right"
            onBlur={(
              e: React.ChangeEvent<HTMLInputElement>,
            ) => {
              setValue(
                'amount',
                toFixed(e.target.value, 4),
              );
            }}
            errors={errors.amount}
            ref={register({
              valueAsNumber: true,
              required: {
                value: true,
                message: 'Amount is required',
              },
            })}
          />
          <InputGrpInLine
            {...fs.note}
            errors={errors.note}
            ref={register}
          />
        </div>
        <div className="row mt-4">
          <div className="col-12 text-right">
            <button
              type="button"
              className="btn btn-primary orange-button mr-3"
              onClick={() => { return closeModal(); }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary orange-button"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AccrualAmountModal;
