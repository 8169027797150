export const garnishmentCategoryOptions = [
  { id: 1, description: 'FOC' },
  { id: 2, description: 'Creditor' },
  { id: 3, description: 'Levy' },
  { id: 5, description: 'Bankruptcy' },
  { id: 4, description: 'Stdnt Loan' },
  { id: 6, description: 'Court' },
];

export const garnishmentCategoryApVendorOptions = [
  { id: 0, description: 'No Category', garnishmentCategoryId: 0 },
  { id: 34, description: 'FOC', garnishmentCategoryId: 1 },
  { id: 35, description: 'Creditor', garnishmentCategoryId: 2 },
  { id: 36, description: 'Levy', garnishmentCategoryId: 3 },
  { id: 37, description: 'Bankruptcy', garnishmentCategoryId: 5 },
  { id: 38, description: 'Stdnt Loan', garnishmentCategoryId: 4 },
  { id: 50, description: 'Court', garnishmentCategoryId: 6 },
];