import React from 'react';
import HandleInfoFields from '../Shared/HandleInfoFields';

const EariningsMapsPage = () => {
  return (
    <HandleInfoFields
      pageName={'Employee Earnings Download'}
      title={'Employee Earnings Maps'}
    />
  );
};

EariningsMapsPage.displayName = 'EariningsMapsPage';

export default EariningsMapsPage;
