import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  AddTimeCardRequest, CostCodeInfoRequest, TimeCardRequest, JobInfoFromFileRequest, JobInfoRequest,
  JobUploadError, PayrollTimeCardCostCodeInfo, PayrollTimeCardDiagnosticRules, PayrollTimeCardJobInfo,
  PayrollTimeCardSubInfo, SubInfoRequest, TaxingCity, TimeCard, UpdateTimeCardRequest, TimeCardDate,
  AddUpdateTimeCardsRequest, TimeCardCrewSheetColumns, AtsRequest, HomeAtsInfo, ExpandedEmployees,
} from 'core/models/Contractor.model';
import { ContractorService } from 'core/services';
import { handleError, handlePending, handleSuccess, handleWarning } from './app.action';
import { loadPayrollControlTotal } from './payroll.action';
import { PayrollControlTotalDetail, PayrollControlTotalRequest } from 'core/models/PayrollControlTotalDetail';

export const getAllJobs = createAction('[contractor] GET_ALL_JOBS');
export const storeAllJobs = createAction<PayrollTimeCardJobInfo[]>('[contractor] STORE_ALL_JOBS');

export const getJobInfo = createAction<JobInfoRequest>('[contractor] GET_JOB_INFO');
export const storeJobInfo = createAction<PayrollTimeCardJobInfo | null>('[contractor] STORE_JOB_INFO');

export const addUpdateJobInfo = createAction<PayrollTimeCardJobInfo>('[contractor] ADD_UPDATE_JOB_INFO');
export const deleteJobInfo = createAction<JobInfoRequest>('[contractor] DELETE_JOB_INFO');

export const addUpdateJobInfo2 = createAsyncThunk(
  'contractor/addUpdateJobInfo',
  async (request: PayrollTimeCardJobInfo, { dispatch }) => {
    try {
      const { data } = await ContractorService.addUpdateJobInfo(request);
      
      dispatch(handleSuccess(data.messages));
      dispatch(storeJobInfo(data.value));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const storeUploadErrors = createAction<JobUploadError[]>('[contractor] STORE_UPLOAD_ERRORS');
export const clearUploadErrors = createAction('[contractor] CLEAR_UPLOAD_ERRORS');

export const addJobInfoFromFile = createAsyncThunk(
  'contractor/addJobInfoFromFile',
  async (request: JobInfoFromFileRequest, { dispatch }) => {  
    try {
      const { data } = await ContractorService.addJobInfoFromFile(request);
      
      dispatch(handleSuccess('Job successfully created'));
      dispatch(storeAllJobs(data.value.jobInfo));
      
      if (data.value.uploadErrors?.length) {
        dispatch(storeUploadErrors(data.value.uploadErrors));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const storeAllSubs = createAction<PayrollTimeCardSubInfo[]>('contractor/storeAllSubs');

export const getAllSubs = createAsyncThunk(
  'contractor/getAllSubs',
  async (_: never, { dispatch }) => {
    try {
      const { data } = await ContractorService.getAllSubs();
      
      dispatch(storeAllSubs(data));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const storeSubInfo = createAction<PayrollTimeCardSubInfo | null>('contractor/storeSubInfo');

export const getSubInfo = createAsyncThunk(
  'contractor/getSubInfo',
  async (request: SubInfoRequest, { dispatch }) => {
    try {
      const { data } = await ContractorService.getSubInfo(request);
      
      dispatch(storeSubInfo(data));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const addUpdateSubInfo = createAsyncThunk(
  'contractor/addUpdateSubInfo',
  async (request: PayrollTimeCardSubInfo, { dispatch }) => {
    try {
      const { data } = await ContractorService.addUpdateSubInfo(request);
      
      dispatch(handleSuccess(data.messages));
      dispatch(storeSubInfo(data.value));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const deleteSubInfo = createAsyncThunk(
  'contractor/deleteSubInfo',
  async (request: SubInfoRequest, { dispatch }) => {
    try {
      const response = await ContractorService.deleteSubInfo(request);
      
      dispatch(handleSuccess(response.data.messages));
      // on this one we return the response so we can handle the fulfilled case in the reducer
      return response?.data || response;
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const storeAllCostCodes = createAction<PayrollTimeCardCostCodeInfo[]>('contractor/storeCostCodes');

export const getAllCostCodes = createAsyncThunk(
  'contractor/getAllCostCodes',
  async (_: never, { dispatch }) => {
    try {
      const { data } = await ContractorService.getAllCostCodes();
      
      dispatch(storeAllCostCodes(data));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const storeCostCodeInfo = createAction<PayrollTimeCardCostCodeInfo>('contractor/storeCostCodeInfo');

export const getCostCodeInfo = createAsyncThunk(
  'contractor/getCostCodeInfo',
  async (request: CostCodeInfoRequest, { dispatch }) => {
    try {
      const { data } = await ContractorService.getCostCodeInfo(request);
      
      dispatch(storeCostCodeInfo(data));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const addUpdateCostCodeInfo = createAsyncThunk(
  'contractor/addUpdateCostCodeInfo',
  async (request: PayrollTimeCardCostCodeInfo, { dispatch }) => {
    try {
      const { data } = await ContractorService.addUpdateCostCodeInfo(request);
      
      dispatch(handleSuccess(data.messages));
      dispatch(storeCostCodeInfo(data.value));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const deleteCostCodeInfo = createAsyncThunk(
  'contractor/deleteCostCodeInfo',
  async (request: CostCodeInfoRequest, { dispatch }) => {
    try {
      const response = await ContractorService.deleteCostCodeInfo(request);
      
      dispatch(handleSuccess(response.data.messages));

      return response?.data || response;
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const getDiagnosticRule = createAction('[contractor] GET_DIAGNOSTIC_RULE');
export const storeDiagnosticRule = createAction<PayrollTimeCardDiagnosticRules>('[contractor] STORE_DIAGNOSTIC_RULE');

export const addUpdateDiagnosticRule = createAction<PayrollTimeCardDiagnosticRules>('[contractor] ADD_UPDATE_DIAGNOSTIC_RULE');

export const getTaxingCities = createAction('[contractor] GET_TAXING_CITIES');
export const storeTaxingCities = createAction<TaxingCity[]>('[contractor] STORE_TAXING_CITIES');

export const storeAllTimeCards = createAction<TimeCard[]>('contractor/storeAllTimeCards');

export const getAllTimeCards = createAsyncThunk(
  'contractor/getAllTimeCards',
  async (payrollHistoryId: number, { dispatch }) => {
    try {
      const { data } = await ContractorService.getAllTimeCards(payrollHistoryId);
      
      dispatch(storeAllTimeCards(data));
    } catch (error) {
      if (typeof error === 'string' && error.includes('No time cards found')) {
        dispatch(handleWarning('No time cards. Click "Add Time Card" to start.'));
        dispatch(storeAllTimeCards([]));
      } else {
        dispatch(handleError(error));
      }
    }
  },
);

export const storeTimeCard = createAction<TimeCard>('contractor/storeTimeCard');
  
export const getTimeCard = createAsyncThunk(
  'contractor/getTimeCard',
  async (request: TimeCardRequest, { dispatch }) => {
    try {
      const { data } = await ContractorService.getTimeCard(request);
      
      dispatch(storeTimeCard(data[0]));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const storeTimeCardControlTotalDetail = createAction<PayrollControlTotalDetail[]>('contractor/storeTimeCardControlTotalDetail');

export const getControlTotalTimeCardDetails = createAsyncThunk(
  'contractor/getControlTotalTimeCardDetails',
  async (request: PayrollControlTotalRequest, { dispatch }) => {
    try {
      const { data } = await ContractorService.getControlTotalTimeCardDetails(request);
      
      dispatch(storeTimeCardControlTotalDetail(data));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const addTimeCard = createAsyncThunk(
  'contractor/addTimeCard',
  async (request: AddTimeCardRequest, { dispatch }) => {
    try {
      const { data: timeCardData } = await ContractorService.addTimeCard(request);
      const { data: homeAtsData } = await ContractorService.getHomeAtsInfo({
        protectedEmpNo: request.empNo,
        effectiveDate: (new Date()).toISOString(),
      });
      
      const updatedNewTimeCard = structuredClone(timeCardData.value);
      updatedNewTimeCard.details = updatedNewTimeCard.details?.map((detail) => ({
        ...detail,
        area: homeAtsData.area,
        trade: homeAtsData.trade,
        sub: homeAtsData.sub,
        tradeRate: homeAtsData.baseRate,
      }));
      
      dispatch(loadPayrollControlTotal({ controlTotalId: request.controlTotalId, payrollHistoryId: timeCardData.value.payrollHistoryId }));
      dispatch(storeHomeAtsInfo(homeAtsData));
      dispatch(storeTimeCard(updatedNewTimeCard));
      dispatch(handleSuccess(timeCardData.messages));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const updateTimeCard = createAsyncThunk(
  'contractor/updateTimeCard',
  async (request: UpdateTimeCardRequest, { dispatch }) => {
    try {
      const { data } = await ContractorService.updateTimeCard(request);
      dispatch(storeTimeCard(data.value));
      dispatch(loadPayrollControlTotal({ controlTotalId: request.controlTotalId, payrollHistoryId: request.payrollHistoryId }));
      dispatch(handleSuccess(data.messages));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const deleteTimeCard = createAsyncThunk(
  'contractor/deleteTimeCard',
  async (request: TimeCardRequest, { dispatch }) => {
    try {
      const { data } = await ContractorService.deleteTimeCard(request);
      
      dispatch(handleSuccess(data.messages));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const addUpdateTimeCards = createAsyncThunk(
  'contractor/addUpdateTimeCards',
  async (request: AddUpdateTimeCardsRequest, { dispatch }) => {
    try {
      const { data } = await ContractorService.addUpdateTimeCards(request);
      dispatch(loadPayrollControlTotal({ controlTotalId: request.controlTotalId, payrollHistoryId: request.payrollHistoryId }));
      dispatch(storeAllTimeCards(data.value));
      dispatch(handleSuccess(data.messages));
      
      return data;
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const storeSelectedPayrollInfo = createAction<{ payrollHistoryId: number, weekEnd: string, checkDate: string }>('contractor/storePayrollInfo');

export const storeTimeCardDates = createAction<TimeCardDate[]>('contractor/storeTimeCardDates');

export const getTimeCardDates = createAsyncThunk(
  'contractor/getTimeCardDates',
  async (weekEnd: string | Date, { dispatch }) => {
    try {
      const { data } = await ContractorService.getTimeCardDates(weekEnd);
      
      dispatch(storeTimeCardDates(data));      
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const storeHomeAtsInfo = createAction<HomeAtsInfo>('contractor/storeHomeAtsInfo');

export const getHomeAtsInfo = createAsyncThunk(
  'contractor/getHomeAtsInfo',
  async (request: AtsRequest, { dispatch }) => {
    try {
      const { data } = await ContractorService.getHomeAtsInfo(request);
      
      dispatch(storeHomeAtsInfo(data));
    } catch (error) {
      if (typeof error === 'string' && error.toLowerCase().includes('could not find client ats records')) {
        dispatch(handleWarning('No home ATS info available for this employee'));
      } else {
        dispatch(handleError(error));
      }
      dispatch(storeHomeAtsInfo({
        area: 'N/A',
        trade: 'N/A',
        sub: 'N/A',
        description: 'N/A',
        baseRate: 0,
      }));
    }
  },
);

export const storePrevTimeCardLink = createAction<string | null>('contractor/storePrevTimeCardLink');

export const storeTimeCardCrewSheetColumns = createAction<TimeCardCrewSheetColumns>('contractor/storeTimeCardCrewSheetColumns');

export const getTimeCardCrewSheetColumns = createAsyncThunk(
  'contractor/getTimeCardCrewSheetColumns',
  async (_: never, { dispatch }) => {
    try {
      const { data } = await ContractorService.getTimeCardCrewSheetColumns();
      
      dispatch(storeTimeCardCrewSheetColumns(data));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const addUpdateTimeCardCrewSheetColumns = createAsyncThunk(
  'contractor/addUpdateTimeCardCrewSheetColumns',
  async (request: TimeCardCrewSheetColumns, { dispatch }) => {
    try {
      const { data } = await ContractorService.addUpdateTimeCardCrewSheetColumns(request);
      dispatch(storeTimeCardCrewSheetColumns(data.value));
      dispatch(handleSuccess(data.messages));
    } catch (error) {
      dispatch(handleError(error));
    }
  },
);

export const storeExpandedEmployees = createAction<ExpandedEmployees[]>('[contractor] STORE_EXPANDED_EMPLOYEES');

// adjustments
export const addTimeCardAdjustment = createAsyncThunk(
  'contractor/addTimeCardAdjustment',
  async (request: { payrollHistoryId: number }, { dispatch }) => {
    dispatch(handlePending('Adding time card adjustment...'));
    
    try {
      const { data } = await ContractorService.addTimeCardAdjustment();
      
      setTimeout(() => {
        dispatch(handleSuccess('Time card adjustment created successfully'));
      }, 2000);
      
      return '/timecard-adjust-detail'; //?payrollHistoryId=${request.payrollHistoryId}&adjustmentTimeCardId=${data.adjustmentId}`;
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      setTimeout(() => {
        dispatch(handlePending(null));
      }, 2000);
    }
  },
);
