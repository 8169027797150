import { PayrollFrequency } from '../core/models';


export const payrollFrequencies: PayrollFrequency[] = [
  { value: '1', description: '1st Check Date of the Month' },
  { value: '2', description: '2nd Check Date of the Month' },
  { value: '3', description: '3rd Check Date of the Month' },
  { value: '4', description: '4th Check Date of the Month' },
  { value: '5', description: '5th Check Date of the Month' },
  { value: '6', description: "Deducts EFT's and Taxes Only, No Accruals" },
  { value: '7', description: 'Deducts Fed, FICA, State, Local Taxes Only' },
  { value: '8', description: 'Deducts FICA Tax Only' },
  { value: '9', description: 'No Deductions, Employer Pays FICA for Employee' },
  { value: 'A', description: "Deducts EFT's and FICA taxes Only" },
  { value: 'B', description: "Deducts EFT's, 401K and Taxes Only, No Accruals" },
  { value: '0', description: 'Payroll Tax Adjustment Run' },
  { value: '+', description: 'SUI Rate Adjustment Run' },
  { value: 'Y', description: 'Employee Transfer' },
  { value: 'X', description: 'X', disabled: true },
];

export const payrollFrequenciesCheckCalculator: PayrollFrequency[] = [
  { value: '1', description: '1st Check Date of the Month' },
  { value: '2', description: '2nd Check Date of the Month' },
  { value: '3', description: '3rd Check Date of the Month' },
  { value: '4', description: '4th Check Date of the Month' },
  { value: '5', description: '5th Check Date of the Month' },
  { value: '6', description: "Deducts EFT's and Taxes Only, No Accruals" },
  { value: '7', description: 'Deduct Fed, FICA, State, Local Taxes Only' },
  { value: '8', description: 'Deduct FICA Only' },
  { value: '9', description: 'No Deductions, Employer Pays FICA for Employee' },
];
