import React from 'react';
import { useSelector } from 'react-redux';
import { InputGrp } from 'core/components/form-controls';
import { SelectGrp } from 'core/components/form-controls/SelectGrp';
import { getEarningsCodes } from 'core/store/selectors';
import AdditionalEarningInfo from './AdditionalEarningInfo';
import { formatWithCommas } from 'utilities/utilities';

const earnings = [1];

type Props = {
  index: number;
  errors: any;
};

const AdjustmentEarnings: React.FC<Props> = ({
  index,
  errors,
}) => {
  const earningsCode = useSelector(getEarningsCodes);

  return (
    <>
      {earnings.map((x, ei) => {
        return (
          <div key={x}>
            <div className="d-flex flex-row">
              <SelectGrp
                groupClass="gc04 mw175 mt-4"
                options={earningsCode}
                name={`items[${index}].earnings[${ei}].earningsCode`}
                id="earningsCode"
                addEmptyValue={true}
                errorMsg="Required"
                defaultValue={''}
                readOnly={true}
                showId={true}
              />
              <InputGrp
                groupClass="gc03 number-underline mw75"
                label="Hours"
                name={`items[${index}].earnings[${ei}].hours`}
                defaultValue={formatWithCommas(0)}
                readOnly={true}
              />
              <InputGrp
                groupClass="gc03 number-underline mw75"
                label="Amount"
                name={`items[${index}].earnings[${ei}].earnings`}
                defaultValue={formatWithCommas(0)}
                readOnly={true}
              />
            </div>
            <AdditionalEarningInfo
              index={index}
              errors={errors}
              earning={x}
              earningIndex={ei}
            />
            <hr />
          </div>
        );
      })}
    </>
  );
};

export default AdjustmentEarnings;
