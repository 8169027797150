import React, { useEffect, useState } from 'react';
import { useAuth } from 'core/providers/authProvider';
import * as signalR from '@microsoft/signalr';
import { toast } from 'react-toastify';
import { AuthUser } from '../types/auth.user';

/**
 * Custom hook that establishes a persistent connection to the server when a user logs in and subscribes to "Toast Message Hub" events.
 * @returns {signalR.HubConnection | null} signalR hub connection or null if the connection is not established.
 */
function useSignalR() : signalR.HubConnection | null {
  const URL = `${process.env.REACT_APP_SIGNALR_BASE_URL}`;
  const headers = { 'x-csrf': '1' };
  const auth = useAuth();
  const [signalRConnection, setSignalRConnection] = useState<signalR.HubConnection | null>(null);

  function catchStartErrors(err: any) {
    console.log(`Error: ${err}`);
  }

  function onMessageReceived(_username: string, message: string) {
    toast.info(message, {
      autoClose: false,
      closeOnClick: true,
      position: 'top-center',
    });
  }

  useEffect(() => {
    if (signalRConnection) return; 
    if (!auth.isAuthenticated()) return;

    auth.getUser().then((user: any) => {
      const authUser = user as AuthUser;
      const userName = authUser.data.find(x => x.type == 'dmUserName')?.value;

      const connection = new signalR.HubConnectionBuilder().withUrl(URL, { headers }).withAutomaticReconnect().build();
      connection.start().catch(catchStartErrors);

      //Add "on" handlers here; 
      connection.on(userName, onMessageReceived);

      setSignalRConnection(connection);

    }).catch((error: any) => {
      console.log(error);
    });
  }, []);

  return signalRConnection;
}

export default useSignalR;
