import React, { DependencyList, useEffect, useState } from 'react';
import DetailRow from 'core/components/shared/DetailRow';
import EmpPhoto from 'core/components/shared/EmpPhoto';
import { Employee } from 'core/models';
import styles from './employee-styles.module.scss';
import sharedStyles from 'core/components/shared/shared.module.scss';
import { NavLink, useHistory } from 'react-router-dom';
import SimpleInput from 'core/components/shared/SimpleInput';
import { DatePickerGrp } from 'core/components/form-controls/DatePickerGrp';
import { SelectGrpUncontrolled } from 'core/components/form-controls/SelectGrpUncontrolled';
import { AccessMap, formatPhone, formatSSN, getAccess, getAllAccess, SectionAccessConfig } from 'utilities/utilities';
import { useSelector } from 'react-redux';
import { getForeignCountries, getGenders, getLocationDepartmentsWithoutHome, getMaritalStatuses } from 'core/store/selectors';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { handleError, loadEmployee, toggleBlockNavigation, updateEmployee } from 'core/store/actions';
import { EmployeeMasterSection } from 'core/models/ModuleAccess.model';
import { ControlIds } from 'core/constants';
import ConfirmationModal from 'core/components/modals/confirmation.modal';

type BlockNavProps = {
  shouldBlock: boolean;
  handleBlock?: (newVal: boolean, newPath: string[]) => void;
  whiteList?: string[];
  dependencies?: DependencyList;
};

type Props = {
  employee: Employee;
  updateList: () => void; // similar to double-clicking a table row, update the list state to selected employees
};

const EmployeeRow = ({ employee, updateList }: Props) => {
  const foreignCountryOpts = useSelector(getForeignCountries);
  const maritalStatusOpts = useSelector(getMaritalStatuses);
  const genderOpts = useSelector(getGenders);
  const {
    locationOpts,
    deptOpts,
    subdeptOpts,
    subdept2Opts,
  } = useSelector(getLocationDepartmentsWithoutHome);
  const sectionAccess: EmployeeMasterSection[] | undefined = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections);
  const { blockNavigation } = useAppSelector((state) => state.app);
  
  // section
  const empListSectionAccess: SectionAccessConfig = getAccess(sectionAccess, 1);
  const empDatesAccess: SectionAccessConfig = getAccess(sectionAccess, 7);
  // fields
  const allEmpInfoAccess: AccessMap | null = getAllAccess(sectionAccess?.find((x) => x.workItemId === 1));
  
  const [empState, setEmpState] = useState<Employee>(employee);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<ControlIds[]>([]);
  const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false);
  const [path, setPath] = useState<string[]>([]);
  
  const empNameAndNo =  employee.firstName + ' ' + employee.lastName + ` - #${employee.empNo}`;
  
  const dispatch = useAppDispatch();
  
  const history = useHistory();
  
  /* TODO: This should really probably happen in App. Right now it's set up in EmpDetailPage AND here, and it'd be nice
  to make it generic so that we don't need to repeat anything and it's as close to the router setup as possible. I'm
  only doing it here for the moment because this is less likely to introduce new bugs for this issue that isn't really 
  related to routing. */
  const toggleShowConfirm = (newVal: boolean, newPath: string[]) => {
    setShowNotificationModal(newVal);
    setPath(newPath);
  };
  
  const unblock = (shouldBlock?: boolean) => history.block((_location, _action) => {
    if (shouldBlock) {
      toggleShowConfirm(true, _location.pathname.split('/'));
      return false;
    }
    
    unblock();
    
    return;
  });
  
  const handleBlockNavigation = (newVal: boolean, newPath: string[]) => {
    if (newVal) {
      toggleShowConfirm(true, newPath);
      return;
    }
  };
  
  const onConfirm = (confirmed: boolean, _path?: string[]) => {
    if (!confirmed) return;
    
    unblock();
    
    dispatch(toggleBlockNavigation({ block: false, message: undefined }));
    setShowNotificationModal(false);
   
    if (_path) {
      history.push(_path.join('/'));
      if (_path.includes('detail')) dispatch(loadEmployee(_path[3]));
    }
  };
  
  const unblockValidation = (shouldBlock?: boolean, handleBlock?: (newVal: boolean, newPath: string[]) => void, whiteList?: string[]) => history.block((_location, _action) => {
    if (!(whiteList?.length && whiteList.find((x) => _location.pathname.includes(x)))) {
      if (shouldBlock) {
        handleBlock?.(true, _location.pathname.split('/'));
        return false;
      }
      unblockValidation();
    }
    
    return;
  });
  
  const NavBlock = ({ shouldBlock, handleBlock, whiteList, dependencies = [] }: BlockNavProps) => {
    useEffect(() => {
      unblockValidation(shouldBlock, handleBlock, whiteList);
      
      return () => {
        unblockValidation();
      };
    }, [shouldBlock, history, ...dependencies]);

    return null;
  };
  
  useEffect(() => {
    setEmpState(employee);
  }, [employee]);
  
  useEffect(() => {
    handleBlockUnsavedChanges(isDirty);
  }, [isDirty]);
  
  function handleBlockUnsavedChanges(newVal: boolean) {
    dispatch(toggleBlockNavigation({
      block: newVal,
      type: 'confirmation',
      message: `${employee.firstName} ${employee.lastName} has unsaved changes. Click cancel to review and save these changes or leave to discard them.`,
    }));
  }
  
  /**
   * Function to update some property `key` on the employee state object to value `newValue`.
   * @param key Property name on employee
   * @param newValue The value we want to update to
   */
  function updateEmpState<T>(key: keyof Employee, newValue: T) {
    setIsDirty(true);
    setEmpState((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  }
  
  function onSave() {
    // validate
    // track the controlIds of the error fields.
    const errors: ControlIds[] = [];
    
    for (const [key, val] of Object.entries(empState)) {
      const coercedKey = key as keyof typeof ControlIds;
      
      // if the field is empty or null and it's required, add its controlId to the array
      if ((String(val) === '' || [null, undefined].includes(val)) && allEmpInfoAccess?.[ControlIds[coercedKey]]?.required) {
        errors.push(ControlIds[coercedKey]);
      }
    }
    
    // update the error state regardless
    setErrorState(errors);
    
    // if we do have some, give a generic message and exit. The fields should be highlighted.
    if (errors.length) {
      dispatch(handleError(`Please complete all fields for ${employee.firstName} ${employee.lastName}`));
      return;
    }
    
    unblock();
    
    setShowNotificationModal(false);
    
    dispatch(toggleBlockNavigation({ block: false, message: undefined }));
    dispatch(updateEmployee(empState));
  }
  
  return (
    <>
      <NavBlock
        shouldBlock={blockNavigation.block}
        handleBlock={handleBlockNavigation}
        dependencies={[]}
      />
      {true && (
        <ConfirmationModal
          title="You have unsaved changes"
          show={showNotificationModal && blockNavigation.block && !!blockNavigation?.message}
          message={blockNavigation.message}
          onConfirmed={onConfirm}
          onHide={() => { toggleShowConfirm(false, []); }}
          path={path}
          buttonLabels={{ onDeny: 'Cancel', onConfirm: 'Leave' }}
        /> 
      )}
      <DetailRow
        key={employee.empNo}
        rowClick
        onClick={(newVal: boolean) => {
          setIsExpanded(newVal);
        }}
      >
        <DetailRow.SummaryContainer>
          <div className="d-flex w-100 align-items-center">
            <div className="mr-3">
              <EmpPhoto
                empPicData={employee.employeePhoto}
                empName={empNameAndNo}
                scaleFactor="40"                          
              />
            </div>
            {/* Clicking this will navigate to the EM and save the list state */}
            <NavLink
              to={`employee/detail/${employee.protectedEmpNo}/snapshot`}
              style={{ textDecoration: 'underline' }}
              onClick={updateList}
            >
              <div
                className="font-weight-bold"
                style={{ fontSize: '18px' }}
              >
                {empNameAndNo}
              </div>
            </NavLink>
          </div>
        </DetailRow.SummaryContainer>
        <DetailRow.DetailsContainer>
          {isExpanded ? (
            <div className={styles['emp-details']}>
              {/* If the entire EM section is hidden, just render these 3 fields. Else handle individually. */}
              {!empListSectionAccess.visible ? (
                <>
                  <SimpleInput
                    name="firstName"
                    label="First Name"
                    value={empState.firstName}
                    readOnly
                    onChange={() => { }}
                    hasError={errorState.includes(ControlIds.firstName)}
                  />
                  <SimpleInput
                    name="midName"
                    label="Middle Initial"
                    value={empState.midName}
                    readOnly
                    onChange={() => { }}
                    hasError={errorState.includes(ControlIds.midName)}
                  />
                  <SimpleInput
                    name="lastName"
                    label="Last Name"
                    value={empState.lastName}
                    readOnly
                    onChange={() => { }}
                    hasError={errorState.includes(ControlIds.lastName)}
                  />
                </>
              ) : (
                <>
                  <SimpleInput
                    {...(allEmpInfoAccess?.[ControlIds.firstName] || {})}
                    name="firstName"
                    label="First Name"
                    value={empState.firstName}
                    onChange={(e) => { updateEmpState('firstName', e.target.value); }}
                    hasError={errorState.includes(ControlIds.firstName)}
                  />
                  <SimpleInput
                    {...(allEmpInfoAccess?.[ControlIds.midName] || {})}
                    name="midName"
                    label="Middle Initial"
                    value={empState.midName}
                    onChange={(e) => { updateEmpState('midName', e.target.value); }}
                    hasError={errorState.includes(ControlIds.midName)}
                  />
                  <SimpleInput
                    {...(allEmpInfoAccess?.[ControlIds.lastName] || {})}
                    name="lastName"
                    label="Last Name"
                    value={empState.lastName}
                    onChange={(e) => { updateEmpState('lastName', e.target.value); }}
                    hasError={errorState.includes(ControlIds.lastName)}
                  />
                  <SimpleInput
                    {...(allEmpInfoAccess?.[ControlIds.ssn] || {})}
                    name="ssn"
                    label="SSN"
                    value={formatSSN(empState.ssn)}
                    onChange={(e) => {
                      const { value } = e.target;
                      e.target.value = formatSSN(value);
              
                      updateEmpState('ssn', e.target.value);
                    }}
                    hasError={errorState.includes(ControlIds.ssn)}
                  />
                  <SimpleInput
                    {...(allEmpInfoAccess?.[ControlIds.ssn] || {})}
                    name="ssnIsAppliedFor"
                    label="SSN applied for"
                    labelClass="mr-2 align-self-center"
                    type="checkbox"
                    checked={empState.ssnisappliedFor}
                    onChange={(e) => { updateEmpState('ssnisappliedFor', e.target.checked); }}
                    topLabel={false}
                  />
                  {(allEmpInfoAccess?.[ControlIds.birthDate]?.visible ?? true) && empDatesAccess.visible && (
                    <div className="d-flex flex-column">
                      <label
                        htmlFor={'birthDate'}
                        className={sharedStyles['top-label'] + (allEmpInfoAccess?.[ControlIds.birthDate]?.required ? ' required' : '')}
                      >
                        Birth Date
                      </label>
                      <DatePickerGrp
                        {...(allEmpInfoAccess?.[ControlIds.birthDate] || {})}
                        name="birthDate"
                        groupClass="mb-0"
                        value={empState.birthDate}
                        onChange={(newDate) => { updateEmpState('birthDate', newDate); }}
                        inputClass={styles['date-picker-input']}
                      />
                    </div>
                  )}
                  {(allEmpInfoAccess?.[ControlIds.hireDate]?.visible ?? true) && empDatesAccess.visible && (
                    <div className="d-flex flex-column">
                      <label
                        htmlFor={'hireDate'}
                        className={sharedStyles['top-label'] + (allEmpInfoAccess?.[ControlIds.hireDate]?.required ? ' required' : '')}
                      >
                        Hire Date
                      </label>
                      <DatePickerGrp
                        {...(allEmpInfoAccess?.[ControlIds.hireDate] || {})}
                        name="hireDate"
                        groupClass="mb-0"
                        value={empState.hireDate}
                        onChange={(newDate) => { updateEmpState('hireDate', newDate); }}
                        inputClass={styles['date-picker-input']}
                        readOnly
                      />
                    </div>
                  )}
                  <div className="d-flex flex-column">
                    <label
                      htmlFor={'gender'}
                      className={sharedStyles['top-label'] + (empListSectionAccess?.required ? ' required' : '')}
                    >
                      Gender
                    </label>
                    <SelectGrpUncontrolled
                      {...empListSectionAccess}
                      name="gender"
                      groupClass="mb-0"
                      options={genderOpts}
                      selectStyles={{ height: '36px', borderRadius: '4px' }}
                    />
                  </div>
                  {(allEmpInfoAccess?.[ControlIds.maritalStatus]?.visible ?? true) && (
                    <div className="d-flex flex-column">
                      <label
                        htmlFor={'maritalStatus'}
                        className={sharedStyles['top-label'] + (allEmpInfoAccess?.[ControlIds.maritalStatus]?.required ? ' required' : '')}
                      >
                        Marital Status
                      </label>
                      <SelectGrpUncontrolled
                        {...(allEmpInfoAccess?.[ControlIds.maritalStatus] || {})}
                        name="maritalStatus"
                        groupClass="mb-0"
                        options={maritalStatusOpts}
                        selectStyles={{ height: '36px', borderRadius: '4px' }}
                        errors={errorState.includes(ControlIds.maritalStatus)}
                      />
                    </div>
                  )}
                  <hr style={{ borderTop: '1px solid #c1c1c1', margin: '4px 0' }}></hr>
                  <SimpleInput
                    {...(allEmpInfoAccess?.[ControlIds.address] || {})}
                    name="address"
                    label="Street Address"
                    value={empState.address}
                    onChange={(e) => { updateEmpState('address', e.target.value); }}
                    hasError={errorState.includes(ControlIds.address)}
                  />
                  <div className="d-flex align-items-end">
                    <SimpleInput
                      {...(allEmpInfoAccess?.[ControlIds.zip] || {})}
                      name="zip"
                      label="ZIP"
                      value={empState.zip}
                      onChange={(e) => { updateEmpState('zip', e.target.value); }}
                      hasError={errorState.includes(ControlIds.zip)}
                    />
                    <div className="mx-1 mb-1 font-weight-bold">-</div>
                    <SimpleInput
                      {...(allEmpInfoAccess?.[ControlIds.zip4] || {})}
                      name="zip4"
                      value={empState.zip4}
                      onChange={(e) => { updateEmpState('zip4', e.target.value); }}
                      inputClass="mt-auto"
                      hasError={errorState.includes(ControlIds.zip4)}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <label
                      htmlFor={'country'}
                      className={sharedStyles['top-label'] + (empListSectionAccess?.required ? ' required' : '')}
                    >
                      Country
                    </label>
                    <SelectGrpUncontrolled
                      {...empListSectionAccess}
                      name="country"
                      options={foreignCountryOpts}
                      selectStyles={{ height: '36px', borderRadius: '4px' }}
                    />
                  </div>
                  <hr style={{ borderTop: '1px solid #c1c1c1', margin: '4px 0' }}></hr>
                  <SimpleInput
                    {...(allEmpInfoAccess?.[ControlIds.email] || {})}
                    name="email"
                    label="Email"
                    value={empState.email}
                    onChange={(e) => { updateEmpState('email', e.target.value); }}
                    hasError={errorState.includes(ControlIds.email)}
                  />
                  <SimpleInput
                    {...(allEmpInfoAccess?.[ControlIds.email2] || {})}
                    name="email2"
                    label="Email 2"
                    value={empState.email2}
                    onChange={(e) => { updateEmpState('email2', e.target.value); }}
                    hasError={errorState.includes(ControlIds.email2)}
                  />
                  <SimpleInput
                    {...(allEmpInfoAccess?.[ControlIds.homePhone] || {})}
                    name="homePhone"
                    label="Home Phone"
                    value={formatPhone(empState.homePhone)}
                    onChange={(e) => {
                      e.target.value = formatPhone(e.target.value);
                      updateEmpState('homePhone', e.target.value);
                    }}
                    hasError={errorState.includes(ControlIds.homePhone)}
                  />
                  <SimpleInput
                    {...(allEmpInfoAccess?.[ControlIds.cellPhone] || {})}
                    name="cellPhone"
                    label="Cell Phone"
                    value={formatPhone(empState.cellPhone)}
                    onChange={(e) => {
                      e.target.value = formatPhone(e.target.value);
                      updateEmpState('cellPhone', e.target.value);
                    }}
                    hasError={errorState.includes(ControlIds.cellPhone)}
                  />
                  <hr style={{ borderTop: '1px solid #c1c1c1', margin: '4px 0' }}></hr>
                  {(allEmpInfoAccess?.[ControlIds.loc]?.visible ?? true) && (
                    <div className="d-flex flex-column">
                      <label
                        htmlFor={'loc'}
                        className={sharedStyles['top-label'] + (allEmpInfoAccess?.[ControlIds.loc]?.required ? ' required' : '')}
                      >
                        Location
                      </label>
                      <SelectGrpUncontrolled
                        {...(allEmpInfoAccess?.[ControlIds.loc] || {})}
                        showId
                        name="loc"
                        options={locationOpts}
                        selectStyles={{ height: '36px', borderRadius: '4px' }}
                        labelField="locationDesc"
                        valueField="locationCode"
                        errors={errorState.includes(ControlIds.loc)}
                      />
                    </div>
                  )}
                  {(allEmpInfoAccess?.[ControlIds.dept]?.visible ?? true) && (
                    <div className="d-flex flex-column">
                      <label
                        htmlFor={'dept'}
                        className={sharedStyles['top-label'] + (allEmpInfoAccess?.[ControlIds.dept]?.required ? ' required' : '')}
                      >
                        Department
                      </label>
                      <SelectGrpUncontrolled
                        {...(allEmpInfoAccess?.[ControlIds.dept] || {})}
                        showId
                        name="dept"
                        options={deptOpts}
                        selectStyles={{ height: '36px', borderRadius: '4px' }}
                        labelField="deptDesc"
                        valueField="deptCode"
                        errors={errorState.includes(ControlIds.dept)}
                      />
                    </div>
                  )}
                  {(allEmpInfoAccess?.[ControlIds.subDept]?.visible ?? true) && (
                    <div className="d-flex flex-column">
                      <label
                        htmlFor={'subDept'}
                        className={sharedStyles['top-label'] + (allEmpInfoAccess?.[ControlIds.subDept]?.required ? ' required' : '')}
                      >
                        Sub Department
                      </label>
                      <SelectGrpUncontrolled
                        {...(allEmpInfoAccess?.[ControlIds.subDept] || {})}
                        showId
                        name="subDept"
                        options={subdeptOpts}
                        selectStyles={{ height: '36px', borderRadius: '4px' }}
                        labelField="subDeptDesc"
                        valueField="subDeptCode"
                        errors={errorState.includes(ControlIds.subDept)}
                      />
                    </div>
                  )}
                  {(allEmpInfoAccess?.[ControlIds.subDept2]?.visible ?? true) && (
                    <div className="d-flex flex-column">
                      <label
                        htmlFor={'subDept2'}
                        className={sharedStyles['top-label'] + (allEmpInfoAccess?.[ControlIds.subDept2]?.required ? ' required' : '')}
                      >
                        Sub Department 2
                      </label>
                      <SelectGrpUncontrolled
                        {...(allEmpInfoAccess?.[ControlIds.subDept2] || {})}
                        showId
                        name="subDept2"
                        options={subdept2Opts}
                        selectStyles={{ height: '36px', borderRadius: '4px' }}
                        labelField="sub2Desc"
                        valueField="sub2Code"
                        errors={errorState.includes(ControlIds.subDept2)}
                      />
                    </div>
                  )}
                  <div className={styles['emp-detail-button-container']}>
                    <button
                      className="btn orange-button"
                      type="button"
                      onClick={onSave}
                    >
                      Save
                    </button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <></>
          )}
        </DetailRow.DetailsContainer>
      </DetailRow>
    </>
  );
};

export default EmployeeRow;