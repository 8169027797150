import React from 'react';
import { ActionBarProps } from './types';
import { useAppSelector } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';
import { Button } from 'react-bootstrap';
import { getPayrollHasSignedWireOnlyAgreement } from 'core/store/selectors';
import { useSelector } from 'react-redux';

const PayrollCardListActionBar = ({
  type,
  selectedCard,
  hasWaitingDateline,
  isBalanced,
  enablePreview,
  onCheckRegister,
  setShow,
  reloadPayrolls,
  showPayrollModal,
  handleDelete,
}: ActionBarProps) => {
  const isSuperUser = useAppSelector((state) => { return state?.auth?.userAccess?.superUser; });
  const canRecover = useAppSelector(({ payroll }) => { return payroll.canRecoverPayroll; });
  const hasNextDatesSet = (selectedCard?.nextCheckDate !== '0001-01-01T00:00:00' && selectedCard?.nextWeekEnd !== '0001-01-01T00:00:00') ? true : false;
  const allowedToPreviewPayrolls = useAppSelector((state) => {return state.app.moduleAccess?.allowedToPreviewPayrolls});
  const allowedToSubmitPayrolls = useAppSelector((state) => {return state.app.moduleAccess?.allowedToSubmitPayrolls});
  const allowedToReoverPayrolls = useAppSelector((state) => {return state.app.moduleAccess?.allowedToReoverPayrolls});
  const hasSignedWireOnlyAgreement = useSelector(getPayrollHasSignedWireOnlyAgreement);

  return (
    <>
      {type === 'processed' && (
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-link dm-grid-action-title py-1"
            onClick={() => {
              onCheckRegister();
              setShow((prevState) => ({ ...prevState, registerReports: true }));
            }}
            disabled={!selectedCard}
          >
            Check Register Reports{' '}
            <Icon
              name="file-alt"
              className="fa-plus-circle"
            />
          </button>
          <button
            type="button"
            className="btn btn-link dm-grid-action-title py-1"
            onClick={() => { setShow((prevState) => ({ ...prevState, recovery: true })); }}
            disabled={!(allowedToReoverPayrolls && selectedCard && canRecover?.payrollRecoverable)}
          >
            Recover Selected{' '}
            <Icon
              name="undo"
              className="fa-plus-circle"
            />
          </button>
        </div>
      )}
      {type === 'open' && (
        <>
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-link dm-grid-action-title py-0 d-flex align-items-center"
              onClick={() => { setShow((prevState) => ({ ...prevState, payrollInsert: true })); }}
            >
              Add Payroll Date Line{' '}
              <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
            <button
              type="button"
              className="btn btn-link dm-grid-action-title py-0 d-flex align-items-center"
              onClick={handleDelete}
              disabled={!selectedCard || hasSignedWireOnlyAgreement}
            >
              Delete Selected{' '}
              <Icon
                name="minus-circle"
                className="fa-plus-circle"
              />
            </button>
          </div>
          <div className="d-flex">    
            {hasWaitingDateline ? <Button
              className="orange-button  mr-2"
              onClick={reloadPayrolls}
            >
              Refresh Status </Button> : null}    
            <Button
              className="orange-outline-button mr-2"
              onClick={() => { showPayrollModal('Preview'); }}
              disabled={!(allowedToPreviewPayrolls && selectedCard && (isBalanced || enablePreview)) || hasWaitingDateline || !hasNextDatesSet}
            >
              Preview Payroll
            </Button>
            <Button
              className="orange-button"
              onClick={() => { showPayrollModal('Submit'); }}
              disabled={!(allowedToSubmitPayrolls && selectedCard && (isBalanced || enablePreview)) || hasWaitingDateline || !hasNextDatesSet}
            >
              Submit Payroll
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default PayrollCardListActionBar;