import { createReducer } from '@reduxjs/toolkit';
import { EmpDocComp } from '../../models';
import { loadEmployeeDocComp, storeEmployeeDocComp, storeEmployeeDocComps } from '../actions';

export interface State {
  loading: boolean;
  employeeDocComp: EmpDocComp[]
}

const INITIAL_STATE = {
  loading: false,
  employeeDocComp: [],
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadEmployeeDocComp.type]: (state) => {return { ...state, loading: true };},
  [storeEmployeeDocComps.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      employeeDocComp: action.payload,
    };
  },
  [storeEmployeeDocComp.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      employeeDocComp: [
        ...state.employeeDocComp.filter((rr: EmpDocComp) => {return rr.docCompHeaderId !== action.payload.docCompHeaderId;}),
        action.payload,
      ],
    };
  },
});
