import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridOptions, MasterDetailModule } from '@ag-grid-enterprise/all-modules';
import { TO_NAME, convertRegion } from 'core/states';
import { getFullTimeCalculationStates } from 'core/store/actions/human-capital-management/aca-full-time-calculation.action';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DateObject from 'react-date-object';
import { agDateRenderer } from 'utilities/ag-grid-renderers';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { numberWithCommas, toReadableDate } from 'utilities/utilities';

type Props = {
  year: number;
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const ACAStates = ({
  year,
}: Props) => {
  const dispatch = useAppDispatch();
  const statesData = useAppSelector((state) => {
    return state?.acaFullTimeReport?.data?.states;
  });
  const [showStates, setShowStates] = useState<boolean>(false);

  useEffect(() => {
    if (showStates) {
      dispatch(getFullTimeCalculationStates(year));
    }
  }, [showStates]);

  const columns: ColDef[] = [
    {
      field: 'state',
      headerName: 'State',
      cellRenderer: 'agGroupCellRenderer',
      sortable: true,
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        return convertRegion(params?.data?.state, TO_NAME) || '';
      },
    },
    {
      headerName: 'Start Month After Waiting Period',
      sortable: true,
      flex: 1,
      minWidth: 250,
      valueFormatter: (params) => {
        return params?.data?.startMonthAfterWaitingPeriod ? 'Yes' : 'No';
      },
    },
    {
      field: 'waitingPeriod',
      headerName: 'Waiting Period',
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'planStartMonth',
      headerName: 'Plan Start Month',
      sortable: true,
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        return months[params.data.planStartMonth - 1];
      },
    },
  ];

  const gridOptions: GridOptions = {
    columnDefs: columns,
    domLayout: 'autoHeight',
    defaultColDef: {
      autoHeight: true,
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    pagination: true,
    paginationPageSize: 10,
    detailRowAutoHeight: true,
    detailCellRendererParams: {
      detailGridOptions: {
        defaultColDef: {
          autoHeight: true,
          suppressMenu: true,
          resizable: true,
          singleCickEdit: true,
          cellClass: 'ag-cell-left-border',
        },
        columnDefs: [
          {
            field: 'effectiveDate',
            headerName: 'Effective Date',
            flex: 1,
            minWidth: 250,
            valueFormatter: (params: any) => {
              return toReadableDate(params.data.effectiveDate);
            },
          },
          {
            field: 'amount',
            headerName: 'Amount',
            flex: 1,
            minWidth: 250,
            valueFormatter: (params: any) => {
              return numberWithCommas(params.data.amount.toFixed(2));
            },
            headerClass: 'ag-right-aligned-header',
            cellClass: 'ag-cell-left-border text-right',
          },
        ],
      },
      getDetailRowData: function (params: any) {
        params.successCallback(params?.data?.amounts || []);
      },
    },
    components: {
      dateRenderer: agDateRenderer,
    },
  };

  return (
    <>
      <Button
        variant="outline-primary"
        className="ml-2"
        onClick={() => {
          setShowStates(true);
        }}
      >
        Show States
      </Button>
      <Modal
        centered
        show={showStates}
        onHide={() => {
          setShowStates(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            ACA States
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-3 ag-theme-balham">
            <AgGridReact
              masterDetail
              gridOptions={gridOptions}
              rowData={statesData?.monthlyPremiums || []}
              overlayNoRowsTemplate={
                '<span>No Changes to Display</span>'
              }
              modules={[MasterDetailModule]}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ACAStates;