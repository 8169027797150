import React, { useEffect, useState } from 'react';
import EmpPhoto from 'core/components/shared/EmpPhoto';
import { Employee, PayrollsUserOptions, TransmittalEmployee, TransmittalEmployeeParams } from 'core/models';
import { ArrayField } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import CheckItemList from './CheckItemList';
import { Link } from 'react-router-dom';
import { getBlankTransmittalEmployeeCheck, storePrevTransmittalLink } from 'core/store/actions';
import Icon from 'core/components/shared/Icon';
import { getShowAddtl } from 'core/store/selectors';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';

const formatEmployeeName = (employee: Employee, options: PayrollsUserOptions | null): string => {
  let name = `${employee?.firstName} ${employee?.lastName}`;
  switch (options?.employeeNameFormat) {
    case 0:
      name = `${employee?.lastName}, ${
        employee?.firstName
      } ${employee?.midName.substring(0, 1)}`;
      break;
    case 1:
      name = `${employee?.lastName} ${
        employee?.firstName
      } ${employee?.midName.substring(0, 1)}`;
      break;
    case 2:
      name = `${employee?.firstName} ${employee?.lastName}`;
      break;
    case 3:
      name = `${employee?.firstName.substring(
        0,
        1,
      )}  ${employee?.midName.substring(0, 1)} ${employee?.lastName}`;
      break;
    case 4:
      name = `${employee?.firstName}  ${employee?.midName.substring(
        0,
        1,
      )} ${employee?.lastName}`;
      break;
  }
  return name;
};

type Props = {
  employee: Partial<ArrayField<TransmittalEmployee, 'id'>>;
  employeeIndex: number;
  payrollHistoryId: number;
  controlTotalId: number;
  isReadOnly: boolean;
};

const TransmittalEmployeeItem = ({
  employee,
  employeeIndex,
  payrollHistoryId,
  controlTotalId,
  isReadOnly,
}: Props) => {
  const employeeList = useAppSelector((state) => { return state.employees.employees; });
  const { transmittalEmployee, prevTransmittalLink } = useAppSelector((state) => state.payroll); 
  const showAddtlFields = useSelector(getShowAddtl);
  const payrollsUserOptions = useAppSelector(({ selEmployeeDetails }) => selEmployeeDetails.payrollsUserOptions.payrollsUserOptions);
  
  const [empState, setEmpState] = useState(transmittalEmployee && transmittalEmployee?.empNo === employee.empNo ? transmittalEmployee : employee);
  const [empName, setEmpName] = useState<string>('');
  
  const listContainer = document.getElementById('emp-scroll-container');

  useEffect(() => {
    if (transmittalEmployee && transmittalEmployee.empNo == employee.empNo) {
      setEmpState(transmittalEmployee);
    } else {
      setEmpState(employee);
    }
  }, [transmittalEmployee, employee]);
  
  useEffect(() => {
    if (!empState?.empNo) return;
    setEmpName(formatEmployeeName(
      employeeList.find(
        (a) => {return a.empNo === empState.empNo;},
      ) as Employee,
      payrollsUserOptions,
    ));
  }, [empState, payrollsUserOptions, employeeList]);
  
  const dispatch = useAppDispatch();
  
  const onAddCheck = () => {
    if (!employee?.protectedEmpNo) return console.error('Error: no protectedEmpNo on employee');
    
    const checkParams: TransmittalEmployeeParams = {
      payrollHistoryId: payrollHistoryId,
      controlTotalId: controlTotalId,
      protectedEmpNo: employee.protectedEmpNo,
    };
    dispatch(getBlankTransmittalEmployeeCheck(checkParams));
  };
  
  /* An effect to scroll back to the employee in the transmittal if the user went out to the employee master and came back.
    The prevTransmittalLink object is managed by the NavigationMonitor which is used to update the user's target destination
    throughout the app. We have a reference to the list container and each list item and get the difference in each element's
    offset from the window's top to determine where to scroll to. */
  useEffect(() => {
    if (listContainer && prevTransmittalLink && prevTransmittalLink.empNo === empState.empNo) {
      const match = document.getElementById(`${prevTransmittalLink?.empNo}`);
      if (!match) return console.warn('Unable to find list element');
      
      // add a buffer of 25px to account for the list's column headers
      const calculatedOffset = (match.offsetTop - 25) - listContainer.offsetTop;
      
      listContainer.scrollTo({ top: calculatedOffset, behavior: 'smooth' });
    } 
  }, [!!empState]);
  
  return (
    <div
      className={`${styles['transmittal-emp-item']} ${employeeIndex % 2 ? 'bg-info' : ''}`}
      key={employee.id}
      data-index={employeeIndex}
      id={`${empState.empNo}`}
    >
      <div
        className="col-sm-2 d-flex flex-column px-3"
        style={showAddtlFields ? { maxWidth: '10%' } : undefined}
      >
        <div>
          <EmpPhoto
            empPicData={
              employeeList.find((a) => {return a.empNo === empState.empNo;})
                ?.employeePhoto
            }
            empName={empState?.firstName + ' ' + empState?.lastName}
          />
        </div>
        <div
          className="dm-card-subtitle font-weight-bold"
          style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        >
          {empState?.protectedEmpNo && empState?.empNo ? (
            <Link
              to={{
                pathname: `/employee/detail/${empState?.protectedEmpNo}/snapshot`,
                state: { prevPath: `/open-current-transmittal?payrollHistoryId=${payrollHistoryId}&controlTotalId=${controlTotalId}` },
              }}
              onClick={() => {
                dispatch(storePrevTransmittalLink({
                  path: `/open-current-transmittal?payrollHistoryId=${payrollHistoryId}&controlTotalId=${controlTotalId}`,
                  empNo: empState.empNo!,
                }));
              }}
              style={{ textDecoration: 'underline' }}
            >
              {empName}
            </Link>
          ) : (
            <>
              {empName}
            </>
          )}
        </div>
        <div>Employee #: {empState?.empNo}</div>
        <button
          type="button"
          className="btn btn-link dm-grid-action-title d-flex align-items-center px-0 mt-5"
          onClick={onAddCheck}
          disabled={isReadOnly || (empState?.checks?.length ?? 0) >= 10}
          aria-disabled={isReadOnly}
        >
          Add Check&nbsp;<Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button>
      </div>
      <div className="col">
        <CheckItemList
          index={employeeIndex}
          employee={empState}
          empNo={empState.empNo as number}
          payrollHistoryId={payrollHistoryId}
          controlTotalId={controlTotalId}
          isReadOnly={isReadOnly}
        />
      </div>
    </div>
  );
};

export default TransmittalEmployeeItem;
