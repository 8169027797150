import { createReducer } from '@reduxjs/toolkit';
import { DeductionsShortage, Employee } from '../../models';
import {
  storeEmpFromPreviousClient,
  loadEmployee,
  storeEmployee,
  storeEmployeeDeductionsShortageReport,
  storeEmployeeDeleteResult,
  storeSelectedEmployee,
  updateEmployee,
  storeCurrentEmpNo,
  enableBlockingPayRates,
  enableBlockingEmValidate,
  unblockAll,
  toggleEmpLoadingState,
  toggleMissingInactiveStatusMessage,
} from '../actions';

export interface State {
  loading: boolean;
  loadingEmp: boolean; // we want a more specific state for this so we don't run it on every save.
  blockingPayRates: boolean;
  blockForValidation: boolean; // adding this to avoid collisions
  employee: Employee | null;
  deductionsShortageReport: DeductionsShortage | null;
  deleteResult: { isError: boolean, message: string } | null;
  employeeFromPreviousClient: Employee[] | null;
  currentEmpNo: number;
  showMissingInactiveStatusMessage: boolean;
}

export const INITIAL_STATE: State = {
  loading: false,
  loadingEmp: false,
  blockingPayRates: false,
  blockForValidation: false,
  employee: null,
  deductionsShortageReport: null,
  deleteResult: null,
  employeeFromPreviousClient: null,
  currentEmpNo: -1,
  showMissingInactiveStatusMessage: false,
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadEmployee, (state) => {
      state.loading = true;
      // state.loadingEmp = true;
    })
    // .addCase(updateEmployee, (state) => {
    //   state.loading = true;
    // })
    .addCase(storeEmployee, (state, action) => {
      state.loading = false;
      state.loadingEmp = false;
      state.employee = action.payload.employee;
    })
    .addCase(storeSelectedEmployee, (state, action) => {
      state.loadingEmp = false;
      state.loading = false;
      state.employee = action.payload;
    })
    .addCase(storeEmployeeDeductionsShortageReport, (state, action) => {
      state.loading = false;
      state.deductionsShortageReport = action.payload;
    })
    .addCase(storeEmployeeDeleteResult, (state, action) => {
      state.loadingEmp = false;
      state.loading = false;
      state.deleteResult = action.payload;
    })
    .addCase(storeEmpFromPreviousClient, (state, action) => {
      state.employeeFromPreviousClient = action.payload;
    })
    .addCase(storeCurrentEmpNo, (state, action) => {
      state.currentEmpNo = action.payload;
    })
    .addCase(enableBlockingPayRates, (state, action) => {
      state.blockingPayRates = action.payload;
    })
    .addCase(enableBlockingEmValidate, (state, action) => {
      state.blockForValidation = action.payload;
    })
    .addCase(unblockAll, (state) => {
      state.blockForValidation = false;
      state.blockingPayRates = false;
    })
    .addCase(toggleMissingInactiveStatusMessage, (state, action) => {
      state.showMissingInactiveStatusMessage = action.payload;
    })
  ;
});
