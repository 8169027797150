export class AppEmpSearch {
  empNo = -1;

  ssn = '';

  lastName = '';

  orderBy = 'LastName ASC';

  pageNumber = 1;

  allClients = true;
}

export type IAppEmpSearch = AppEmpSearch;