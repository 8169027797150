import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class WorkgroupItem {
  level = 0;

  levelName = '';

  num = 0;

  name = '';

  code = '';

  rate = 0;

  expireDate: Date | string | null = null;

  static readonly convProps: PropTypeLists = {
    dateProps: ['expireDate'],
    numberProps: ['level', 'num', 'rate'],
  };

  constructor(
    level: number,
    props?: { [key: string]: any }) {

    this.level = (typeof level === 'string') ? parseInt(level) : level;

    props && convToClass(this, props, WorkgroupItem.convProps);
  }

}