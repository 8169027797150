import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  CheckboxGrpInLine,
  RadioGrp,
} from 'core/components/form-controls';
import InputReportDateSelector from 'core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { Client, DtoOptions } from 'core/models';
import { FullTimeEquivalentReportRequest } from 'core/models/HumanCapitalReports.model';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';
import {
  clearFulltimeEquivalentReport,
  downloadFulltimeEquivalentReport,
} from 'core/store/actions';
import { getFullTimeEquivalentReport } from 'core/store/selectors/human-capital.selector';
import { CommonService } from 'core/services';
import Icon from 'core/components/shared/Icon';

const clientnum: RadioOptions[] = [
  {
    value: 'true',
    label: 'Run By Client Number',
  },
  {
    value: 'false',
    label: 'Run By FEIN',
  },
];

const FullTimeEquivalentReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const childRef: React.RefObject<any> = useRef();

  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [fileType, setFileType] = useState<string>('pdf');
  const [FTEReportType, setFTEReportType] = useState<string>('FullTimeEquivalent');
  const [checkedReportType, setCheckedReportType] = useState<Record<string, boolean>>({ empRetentionCredit: false,
    shutteredVenueOps: false,
  });
  const [hoursType, setHoursType] = useState<string>('Default');
  const [checkedHoursType, setCheckedHoursType] = useState<Record<string, boolean>>({ includeAllTracked: false,
    regularOnly: false,
  });
  
  const client = useAppSelector((state) => { return state?.client?.client; }) as Client;

  const report = useSelector(getFullTimeEquivalentReport);

  const hidePdfViewer = () => {
    dispatch(clearFulltimeEquivalentReport());
  };

  const { getValues, register, handleSubmit } =
    useForm<FullTimeEquivalentReportRequest>({
      defaultValues: {
        runByClientNumber: 'true',
        hoursType: 'Default',
        fullTimeEquivalentReportType: 'FullTimeEquivalent',
      },
    });

  useEffect(() => {
    if (report && fileType === 'excel') {
      CommonService.downloadBase64File(
        'application/excel',
        report,
        'FullTimeEquivalentReport.xlsx',
      );
      dispatch(clearFulltimeEquivalentReport());
    }
  }, [report]);

  const downloadReport = (data: FullTimeEquivalentReportRequest) => {
    const submitData: FullTimeEquivalentReportRequest = {
      clientNo: client.clientNo,
      runByClientNumber: data.runByClientNumber === 'true' ? true : false,
      showLast4DigitsSSN: data.showLast4DigitsSSN,
      hoursType: hoursType,
      fullTimeEquivalentReportType: FTEReportType,
      dtoOptions: reportDates,
      reportType: fileType,
    };
    dispatch(downloadFulltimeEquivalentReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };
  
  const uncheckRelativeInputs = (
    target: string,
    newTargetVal: boolean,
    state: Record<string, boolean>,
    update: React.Dispatch<React.SetStateAction<Record<string, boolean>>>,
  ) => {
    const returnState = structuredClone(state);
    
    Object.keys(state).forEach((key) => {
      returnState[key] = ((key === target) && (newTargetVal));
    }); 
    
    update(returnState);
  }; 

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">
            FullTime Equivalent Report
          </div>
          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title "
              onClick={() => { return setFileType('excel'); }}
              disabled={!reportDates.length}
            >
              Download Report{' '}
              <Icon
                name="download"
                className="fa-download"
              />
            </button>
          </div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div>
              <InputReportDateSelector
                ref={childRef}
                returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }
                }
              />
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <div className=" text-right">
                <button
                  type="button"
                  className="orange-outline-button mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="orange-button mr-2"
                  onClick={() => { return setFileType('pdf'); }}
                  disabled={!reportDates.length}
                >
                  Run Report
                </button>
              </div>

              <div
                className="w-50 dm-panel dm-panel-border"
                style={{ marginTop: '66px' }}
              >
                <div className="dm-grid-action-title mb-2">
                  Report Options
                </div>
                <div>
                  <div className="d-flex">
                    <div className="d-flex flex-row flex-grow-1">
                      <div className="d-flex flex-column flex-grow-1">
                        <RadioGrp
                          isVertical={true}
                          radioOptions={clientnum}
                          name="runByClientNumber"
                          ref={register}
                        />
                        <CheckboxGrpInLine
                          name="showLast4DigitsSSN"
                          ref={register}
                          labelFirst={false}
                          labelWidth={150}
                          labelWidthUnit="px"
                          label="Show Last 4 Digits SSN"
                        />
                      </div>
                      <div className="d-flex flex-column flex-grow-1 pt-1 pl-1">
                        <div
                          className="mb-2"
                          style={{
                            backgroundColor:
                              '#eeeeee',
                          }}
                        >
                          <div className="px-2 pt-2">
                            <CheckboxGrpInLine
                              name="includeAllTracked"
                              ref={register}
                              labelFirst={false}
                              label="Include Tracked Hours"
                              checked={checkedHoursType.includeAllTracked}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.checked) {
                                  setHoursType('Tracked');
                                } else {
                                  setHoursType('Default');
                                }
                                uncheckRelativeInputs('includeAllTracked', e.target.checked, checkedHoursType, setCheckedHoursType);
                              }}
                            />
                          </div>
                          <div className="px-2">                          
                            <CheckboxGrpInLine
                              name="regularOnly"
                              ref={register}
                              labelFirst={false}
                              label="Include Tracked Hours - Regular Only"
                              checked={checkedHoursType.regularOnly}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.checked) {
                                  setHoursType('TrackedRegularOnly');
                                } else {
                                  setHoursType('Default');
                                }
                                uncheckRelativeInputs('regularOnly', e.target.checked, checkedHoursType, setCheckedHoursType);
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundColor:
                              '#eeeeee',
                          }}
                        >
                          <div className="px-2 pt-2">
                            <CheckboxGrpInLine
                              name="employeeRetentionCredit"
                              ref={register}
                              labelFirst={false}
                              label="Employee Retention Credit"
                              checked={checkedReportType.empRetentionCredit}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.checked) {
                                  setFTEReportType('EmployeeRetentionCredit');
                                } else {
                                  setFTEReportType('FullTimeEquivalent');
                                }
                                uncheckRelativeInputs('empRetentionCredit', e.target.checked, checkedReportType, setCheckedReportType);
                              }}
                            />
                          </div>
                          <div className="px-2">                          
                            <CheckboxGrpInLine
                              name="shutteredVenueOperators"
                              ref={register}
                              labelFirst={false}
                              label="Shuttered Venue Operators"
                              checked={checkedReportType.shutteredVenueOps}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.checked) {
                                  setFTEReportType('ShutteredVenueOperators');
                                } else {
                                  setFTEReportType('FullTimeEquivalent');
                                }
                                uncheckRelativeInputs('shutteredVenueOps', e.target.checked, checkedReportType, setCheckedReportType);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {report ? (
          <PDFViewerModal
            show={report.length > 0}
            pdfData={report}
            onHide={hidePdfViewer}
            reportName="FullTimeEquivalentReport"
          />
        ) : null}
      </div>
    </div>
  );
};
export default FullTimeEquivalentReport;
