import React, { useEffect } from 'react';
import { CardViewProps, Styles } from './types';
import { useUpdatePayContext } from './CardContext';

const styles: Styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
};

const CardView = ({
  id,
  mobileView,
  bodyFields,
  isProcessed,
  isWaiting,
  isBalanced = false,
  styleClass,
  showPayInput = false,
  hasSignedWireOnlyAgreement,
  defaultChecked,
  setReactiveStyles,
  updatePayOption,
}: CardViewProps) => {
  const updateArray = useUpdatePayContext();

  //PI-8931 This should not be needed anymore since we handle the pay/not pay default properly on the backend now.
  // useEffect(() => {
  //   // this could probably benefit from some polymorphism...
  //   if (isProcessed || !showPayInput || hasSignedWireOnlyAgreement) return;
  //   if (!isBalanced && showPayInput && defaultChecked) {
  //     if (updateArray && updatePayOption && setReactiveStyles) {
  //       //updatePayOption(false); PI-8931 I updated the create on the backend so if its not balanced the pay will be set to false so we dont have to send this PUT
  //       updateArray(id, false);
  //       setReactiveStyles((prevState) => ({ ...prevState, backgroundColor: '#ffb4a7' }));
  //     }
  //   }
  // }, []);
  
  const payChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!(updatePayOption && setReactiveStyles)) return;
    e.stopPropagation();
    const color = e.target.checked ? '#d0fdba' : '#ffb4a7';
    updatePayOption(e.target.checked);
    if (updateArray) {
      updateArray(id, e.target.checked);
    }
    setReactiveStyles((prevState) => {
      return { ...prevState,
        backgroundColor: color };
    });
  };
  
  return (
    <div
      className={`payroll-card-body ${styleClass ?? ''}`}
      style={mobileView ? styles.wrapper : undefined}
    >
      {bodyFields.map((field) => {
        return (
          <div
            key={field.title}
            className="d-flex"
          >
            <span className="d-flex align-items-start font-weight-bold">{field.title}:&nbsp;</span>
            <span>{field.detail}</span>
          </div>
        );
      })}
      {/* TODO: Make these more generic */}
      {!isProcessed && showPayInput ? (
        <div className={`d-flex ${!isBalanced ? 'disabled-input' : ''}`}>
          <span className="font-weight-bold">Pay&nbsp;</span>
          <span>
            <input
              type="checkbox"
              defaultChecked={defaultChecked && isBalanced}
              onChange={payChangeHandler}
              disabled={!isBalanced || hasSignedWireOnlyAgreement || isWaiting}
            />
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default CardView;