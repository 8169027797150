import React, { useState } from 'react';
import Modal from 'core/components/modals/Modal';
import { TimeCard } from 'core/models';
import { ColumnHeaderData, Row } from 'core/components/shared/dm-table/types';
import styles from './styles.module.scss';
import { InputGrp } from 'core/components/form-controls';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import Table from 'core/components/shared/dm-table/Table';
import { handleError, storeTimeCard } from 'core/store/actions';
import { sortByLastName } from '../utilities';

const buildEmpRow = (timeCard: TimeCard): Row => {
  return {
    id: timeCard.transmittalTimeCardId,
    cells: [
      { children: timeCard.empNo, styleClass: 'td-10' },
      { children: timeCard.firstName, styleClass: 'td-30' },
      { children: timeCard.midName, styleClass: 'td-10' },
      { children: timeCard.lastName, styleClass: 'td-30' },
      { children: timeCard.checkCode, styleClass: 'td-20' },
    ],
  };
};

const jobNoColumns: ColumnHeaderData[] = [
  { title: 'Emp. No.', styleClass: 'td-10' },
  { title: 'First Name', styleClass: 'td-30' },
  { title: 'M.I.', styleClass: 'td-10' },
  { title: 'Last Name', styleClass: 'td-30' },
  { title: 'Check Code', styleClass: 'td-20' },
];

const matchQuery = (timeCard: TimeCard, _query: string): boolean => {
  return !!(timeCard?.firstName?.toLowerCase()?.includes(_query.toLowerCase())
      || timeCard?.midName?.toLowerCase()?.includes(_query.toLowerCase())
      || timeCard?.lastName?.toLowerCase()?.includes(_query.toLowerCase())
      || timeCard?.checkCode === _query)
      || String(timeCard?.empNo)?.includes(_query);
};

type Props = {
  type: string;
  show: boolean;
  onHide: () => void;
};

const SearchTimeCardsModal = ({ type, show, onHide }: Props) => {
  const { timeCards } = useAppSelector(({ contractor }) => contractor);
  const sortedTimeCards = structuredClone(timeCards)?.sort(sortByLastName<TimeCard>);
  
  const dispatch = useAppDispatch();
  
  const [filteredTimeCards, setFilteredTimeCards] = useState<TimeCard[]>(sortedTimeCards);
  
  const handleSearch = (newQuery: string) => {
    if (!newQuery.trim().length) {
      setFilteredTimeCards(sortedTimeCards);
      return;
    }
    
    const newState = structuredClone(sortedTimeCards)?.filter((timeCard: TimeCard) => matchQuery(timeCard, newQuery));
    
    setFilteredTimeCards(newState);
  };
  
  const handleRowClick = (id: string | number) => {
    const matchTimeCard = filteredTimeCards?.find((timeCard) => timeCard.transmittalTimeCardId === +id);
    if (!matchTimeCard) return dispatch(handleError('Time Card could not be loaded'));
    
    dispatch(storeTimeCard(matchTimeCard));
    onHide();
  };
  
  return (
    <Modal
      title={`Search ${type}s`}
      size="xl"
      show={show}
      onHide={onHide}
    >
      <div className="d-flex flex-column">
        <div className="d-flex flex-column w-25">
          <label
            htmlFor="trade-sub-search"
            className={styles['trade-sub-top-label']}
          >Search</label>
          <InputGrp
            placeholder="Search"
            groupClass="m-0"
            inputClass={`gc20 ${styles['trade-sub-input']}`}
            name="trade-sub-search"
            type="text"
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              handleSearch(target.value);
            }}
          />
        </div>
        <Table
          columns={jobNoColumns}
          rows={filteredTimeCards?.map((emp) => buildEmpRow(emp))}
          onRowClick={handleRowClick}
        />
      </div>
    </Modal>
  );
};

export default SearchTimeCardsModal;
