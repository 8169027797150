import { FormState, useFieldArray } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { ControlDatePickerGrp, SelectGrp } from '../form-controls';
import { AcaStatus } from '../../models';
import { useDispatch } from 'react-redux';
import { createEmployeeACAStatus, deleteEmployeeACAStatus, toggleBlockNavigation, updateEmployeeACAStatus } from '../../store/actions';
import ConfirmationModal from '../modals/confirmation.modal';
import PanelHeader from './PanelHeader';
import { Prompt } from 'react-router-dom';
import { UNSAVED_MESSAGE } from '../../constants';
import { DateTime } from 'luxon';
import Icon from './Icon';

const acaStatusOpts = [
  {
    id: 'F',
    description: 'Full Time',
  },
  {
    id: 'P',
    description: 'Variable Hours',
  },
  {
    id: 'S',
    description: 'Seasonal',
  },
  {
    id: 'O',
    description: 'Owner',
  },
  {
    id: 'N',
    description: 'Not Applicable',
  },
];

type PropTypes = {
  formMethods: any;
  formState: FormState<AcaStatus>;
  empId?: number;
  empNo?: number;
  protectedEmpNo: string;
  protectedClientNo: string;
  clientNo: number;
  control: any;
  acaStatus: AcaStatus[];
  onCancel: () => void;
};

const EmployeeStatus: React.FC<PropTypes> = ({
  formMethods: {
    register,
    setValue,
    getValues,
    reset,
    handleSubmit },
  formState, empId, empNo, protectedEmpNo, protectedClientNo, clientNo, control, acaStatus, onCancel }) => {

  const dispatch = useDispatch();


  const [activeStatus, setActiveStatus] = useState<AcaStatus>();
  const currentYear = new Date().getFullYear();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState<AcaStatus>();

  const { fields, insert } = useFieldArray({
    control,
    name: 'acaStatus',
    keyName: 'id',
  });

  useEffect(() => {
    if (acaStatus?.length) {
      const statuses = [...acaStatus].sort((a: AcaStatus, b: AcaStatus) => {
        return new Date(b.effectiveDate).getDate() - new Date(a.effectiveDate).getDate();
      });
      statuses && setActiveStatus(statuses[0]);
    }
  }, [acaStatus]);

  const onDelete = (item: AcaStatus) => {
    setDeleteItem(item);
    setShowConfirmationModal(true);
  };

  const confirmClose = (confirmed: boolean) => {
    confirmed && deleteItem && dispatch(
      deleteEmployeeACAStatus({
        ...{
          protectedEmpNo,
          effectiveDate: deleteItem.effectiveDate.toString(),
          prYear: currentYear,
        },
      }));
  };

  const handleStatusChange = (statusName: string, e: any) => {
    setValue(statusName, e.target.value);
  };

  const isDisabled = (item: AcaStatus) => {
    return !item.isNew && activeStatus?.effectiveDate !== item.effectiveDate;
  };

  const onAddACAStatus = () => {
    const values = getValues();
    const hasNew = values?.acaStatus?.some((s: AcaStatus) => { return s.isNew; });
    const date = new Date(activeStatus?.effectiveDate!) > new Date() ? new Date(activeStatus?.effectiveDate!) : new Date();
    if (!hasNew) {
      const status = {
        status: 'F',
        effectiveDate: DateTime.fromJSDate(date).plus({ day: 1 }).toISO(),
        isNew: true,
      };
      insert(0, status);
    }
  };

  const onSubmit = () => {
    const values = getValues();

    values?.acaStatus.forEach((s: AcaStatus) => {
      const newStatus: AcaStatus = {
        ...s,
        empNo: empNo ?? 0,
        protectedEmpNo,
        protectedClientNo,
        clientNo,
        empId: empId ?? 0,
        effectiveDate: DateTime.fromJSDate(new Date(s.effectiveDate)).toISODate(),
      };

      if (s.isNew) {
        dispatch(createEmployeeACAStatus({
          status: newStatus,
          prYear: currentYear,
        }));
      } else {
        dispatch(updateEmployeeACAStatus({
          status: newStatus,
          prYear: currentYear,
        }));
      }
    });
    onCancel();
  };

  const onCancelClick = () => {
    reset();
    onCancel();
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: formState.isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [formState.isDirty]);

  return (
    <>

      <div>
        <PanelHeader title="ACA Status History">
          <button
            type="button"
            className="btn btn-link dm-grid-action-title pb-0"
            onClick={() => {
              onAddACAStatus();
            }}
          >
            Add ACA Status
            <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-2">

          {fields?.map((item, index) => {
            return (
              <div
                className="d-flex flex-row"
                key={item.id}
              >
                <input
                  type="hidden"
                  name={`acaStatus[${index}].isNew`}
                  defaultValue={item.isNew}
                  ref={register()}
                />

                <ControlDatePickerGrp
                  label="Effective Date"
                  name={`acaStatus[${index}].effectiveDate`}
                  value={item.effectiveDate}
                  groupClass="gc10 mw250"
                  control={control}
                  disabled={!item.isNew || isDisabled(item as AcaStatus)}
                />

                <SelectGrp
                  name={`acaStatus[${index}].status`}
                  label="Status"
                  defaultValue={item.status}
                  groupClass="gc10 mw250"
                  ref={register()}
                  onChange={(value: any) => { return handleStatusChange(`acaStatus[${index}].status`, value); }}
                  options={acaStatusOpts}
                />


                <div className="d-flex justify-content-end w-100">
                  {(!item.isNew && acaStatus?.length > 1) &&
                    <button
                      type="button"
                      className="btn btn-link dm-grid-action-title pb-0"
                      onClick={() => { return onDelete(item as AcaStatus); }}
                    >
                      Delete ACA Status
                      <Icon
                        name="minus-circle"
                        className="fa-minus-circle"
                      />
                    </button>
                  }
                </div>
              </div>
            );
          })}
          {showConfirmationModal && (
            <ConfirmationModal
              title="Delete Status"
              message={'Are you sure you would like to delete this status?'}
              show={showConfirmationModal}
              onConfirmed={confirmClose}
              onHide={() => { return setShowConfirmationModal(false); }}
            />
          )}
        </div>
        <div className="d-flex mt-3">
          <button
            type="button"
            className="btn btn-primary orange-outline-button ml-auto mr-2"
            onClick={onCancelClick}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn orange-button-sm mr-3"
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default EmployeeStatus;