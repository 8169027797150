import React, { useState } from 'react';
import ModalHeader from 'features/payroll/shared/ModalHeader';
import { Modal as BootstrapModal, Tab, Tabs } from 'react-bootstrap';
import ModalEmpDetails from 'features/payroll/shared/ModalEmpDetails';
import { Employee } from 'core/models';
import SummaryTimeSheet from './SummaryTimeSheet';

const arr = [1, 2, 3];

type PropTypes = {
  payrollHistoryId: number;
  show: boolean;
  onHide: () => void;
};

const TimeCardAdjustmentSummaryModal = ({
  payrollHistoryId,
  show,
  onHide,
}: PropTypes) => {
  const [tabKey, setTabKey] = useState<number>(1);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  
  const getPrevEmp = () => { };
  
  const getNextEmp = () => { };  
  
  const onAddTimeCardAdjustment = () => { };
  
  const onSave = () => { };
  
  return (
    <div onClick={(e) => {return e.stopPropagation();}}>
      <BootstrapModal
        show={show}
        onHide={onHide}
        size="sm"
      >
        <ModalHeader
          title="Adjustment Summary"
          listType="Time Card"
          getPrevEmp={getPrevEmp}
          getNextEmp={getNextEmp}
          closeModal={onHide}
        />
        <BootstrapModal.Body>
          <div className="d-flex justify-content-between mb-3">
            <ModalEmpDetails
              currentEmployee={new Employee(
                20102,
                1,
                {
                  firstName: 'FirstName',
                  lastName: 'LastName',
                },
              )}
              addType="Adjustment"
              onAdd={onAddTimeCardAdjustment}
            />
          </div>
          <Tabs
            id="adjustment-tabs"
            defaultActiveKey={tabKey}
            activeKey={tabKey}
            onSelect={(eventKey) => {
              onSave();
              setTabKey(parseInt(eventKey ?? '0'));
            }}
          >
            {arr.map((x) => {
              return (
                <Tab
                  key={x}
                  eventKey={x}
                  title={`${x} PP`}
                >
                  <SummaryTimeSheet
                    payrollHistoryId={payrollHistoryId}
                    index={x}
                    check={null}
                    tabKey={tabKey}
                    isDirty={isDirty}
                  />
                </Tab>
              );
            })}
          </Tabs>
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

export default TimeCardAdjustmentSummaryModal;
