import { ofType, StateObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, startWith, switchMap, tap, throttleTime } from 'rxjs/operators';
import { EmpDate, Employee, HttpResponse, Rehire, ResendOnboardingEmailRequest, UserPassword } from '../../models';
import { AppService, EmployeeService, OnboardService } from '../../services';
import {
  createEmpRehire,
  deleteEmployee,
  handleError,
  handlePending,
  handleSuccess,
  loadDefaultUserPassword,
  loadEmpDate,
  loadEmployee,
  loadEmployees,
  loadHireHistories,
  resendOnboardingEmail,
  toggleMissingInactiveStatusMessage,
  storeDefaultUserPassword,
  storeEmpDate,
  storeEmployee,
  storeEmployeeDeleteResult,
  storeEmployeesAfterDelete,
  storeSelectedEmployee,
  storeValidationFromStream,
  toggleChangeEmpStatusModal,
  triggerEmFieldValidation,
  updateDefaultUserPassword,
  updateEmpDate,
  // updateEmployee,
  updateEmployeePhoto,
  updateEmployeeStatus,
} from '../actions';
import { RootState } from '../store';
import { TransmittalDefaultSort } from 'features/employee/constants';

interface Actions<Type = any> { // TODO: type these properly
  type: string;
  payload: Type;
}

const loadEmployee$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmployee.type),
    /* PI-8410: I noticed the employee list was loading the first employee 4-5 times on render because of AG grid's lifecycle methods firing repeatedly. 
    This will mitigate that by throttling requests so it should only fire the first one (this should only be an issue on first render.) switchMap should 
    also help with this just because it should cancel previous in-flight requests.
    */
    throttleTime(500), 
    switchMap((action: { payload: string; }) => {
      return from(EmployeeService.getEmployee(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: Employee) => {
          return [
            storeSelectedEmployee(res),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEmployeePhoto$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateEmployeePhoto.type),
    switchMap((action: { payload: { empNo: string; photoData: Uint8Array; }; }) => {
      return from(
        EmployeeService.putEmployeePhoto(
          action.payload.empNo,
          action.payload.photoData,
        ),
      ).pipe(
        map(() => { return loadEmployee(action.payload.empNo); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEmployeeStatus$ = (action$: Observable<Actions>, state$: StateObservable<RootState>) => {
  return action$.pipe(
    ofType(updateEmployeeStatus.type),
    switchMap((action: { payload: { protectedEmpNo: string; }; }) => {
      return from(
        EmployeeService.putEmployeeStatus(
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<Employee>) => {
          // ugly
          const defaultTransmittalSortOrder = state$.value.client?.clientOptions?.options?.[34]?.optionValue ?? 'Alphabetically';
          
          return [
            handleSuccess(res.messages),
            toggleChangeEmpStatusModal(false),
            storeEmployee({ employee: res.value, sortBy: defaultTransmittalSortOrder as TransmittalDefaultSort }), //This updates the employee list
            storeSelectedEmployee(res.value), //This updates the selected employee
          ]; 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadDefaultUserPassword$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadDefaultUserPassword.type),
    switchMap((action: { payload: { secUserId: number; }; }) => {
      return from(AppService.getDefaultUserPassword(action.payload.secUserId)).pipe(
        map((res: any) => { return res.data; }),
        map((res: UserPassword) => {return storeDefaultUserPassword(res);}),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateDefaultUserPassword$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateDefaultUserPassword.type),
    switchMap((action: { payload: UserPassword; }) => {
      return from(AppService.postDefaultUserPassword(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: UserPassword) => {return storeDefaultUserPassword(res);}),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const resendOnboardingEmail$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(resendOnboardingEmail.type),
    switchMap((action: { payload: ResendOnboardingEmailRequest }) => {
      return from(OnboardService.postResendOnboardEmailFromEM(action.payload),
      ).pipe(
        map((res) => { return res.data; }),
        map((_) => { return handleSuccess('Email sent'); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    }),
  );
};

// these are technically hired in the other EMPLOYEES redux functions to update the employee list state, but putting them in the EMPLOYEE epic file since they're only related to one employee
const loadEmpDate$ = (action$: Observable<Actions<{ protectedEmpNo: string, updateKeys: string[] }>>) => {
  return action$.pipe(
    ofType(loadEmpDate.type),
    switchMap((action: { payload: { protectedEmpNo: string, updateKeys: string[] } }) => {
      return from(EmployeeService.getEmpDate(action.payload.protectedEmpNo)).pipe(
        map((res: any) => { return res.data; }),
        map((res: EmpDate) => {
          return storeEmpDate({ empDate: res,
            updateKeys: action.payload.updateKeys }); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEmpDate$ = (action$: Observable<Actions<{ empDate: EmpDate, updateKeys: string[] }>>) => {
  return action$.pipe(
    ofType(updateEmpDate.type),
    switchMap((action: { payload: { empDate: EmpDate, updateKeys: string[] } }) => {
      return from(EmployeeService.putEmpDate(action.payload.empDate)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<EmpDate>) => {
          const outputStream: any[] = [
            storeEmpDate({ empDate: action.payload.empDate,
              updateKeys: action.payload.updateKeys }),
            loadHireHistories(action.payload.empDate.protectedEmpNo),
            loadEmployee(action.payload.empDate.protectedEmpNo),
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'dates', // maybe?
              actionType: updateEmpDate.type,
              callerPayload: action.payload.empDate,
            }),
          ];
          
          if (res.value.missingAodInactiveStatus) outputStream.push(toggleMissingInactiveStatusMessage(true));
          
          return outputStream;
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createEmpRehire$ = (
  action$: Observable<Actions<{ protectedEmpNo: string; rehire: Rehire }>>,
) => {
  return action$.pipe(
    ofType(createEmpRehire.type),
    switchMap((action: { payload: { protectedEmpNo: string; rehire: Rehire; }; }) => {
      return from(
        EmployeeService.postEmpRehireDates(
          action.payload.protectedEmpNo,
          action.payload.rehire,
        ),
      ).pipe(
        mergeMap(() => {
          return [
            triggerEmFieldValidation({
              section: 'dates', // maybe?
              actionType: updateEmpDate.type,
              callerPayload: action.payload.rehire,
            }),
          ];
        }),
        // 	loadEmpDate(action.payload.empNo),
        // 	loadHireHistories(action.payload.empNo)
        // ]), // what was all this for?
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteEmployee$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteEmployee.type),
    mergeMap((action: { payload: { protectedEmpNo: string, empNo: number } }) => 
      from(EmployeeService.deleteEmployee(action.payload.protectedEmpNo)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<string>) => {
          return [
            handlePending(null),
            storeEmployeeDeleteResult({ isError: false, message: res.messages?.[0] }),
            storeEmployeesAfterDelete(action.payload.empNo),
            storeValidationFromStream(null), // deleting the employee so clear any validation object they had
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            storeEmployeeDeleteResult({ isError: true, message: err.messages?.[0] }),
            handlePending(null),
            handleError(err),
          ];
        }),
        /* this will tell the observable to start with this when streaming these actions RIGHT on subscription
        (so it'll emit right before we start the request, which is what we want.) */
        startWith(handlePending(`Deleting employee ${action.payload.empNo}...`)),
      ),
    ),
  );
};

export const epics: any[] = [
  loadEmployee$,
  // updateEmployee$,
  updateEmployeePhoto$,
  loadDefaultUserPassword$,
  updateDefaultUserPassword$,
  resendOnboardingEmail$,
  loadEmpDate$,
  updateEmpDate$,
  createEmpRehire$,
  deleteEmployee$,
  updateEmployeeStatus$,
];
