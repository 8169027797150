import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  EarningsRecords,
  EarningsRecordsRequest,
  HttpResponse,
} from '../../models';
import { EarningsRecordsService } from '../../services';
import {
  handleError,
  loadEarningsRecords,
  storeEarningsRecords,
} from '../actions';

interface Actions<T> {
  type: string;
  payload: T;
}

const loadEarningsRecords$ = (
  action$: Observable<Actions<EarningsRecordsRequest>>,
) => {
  return action$.pipe(
    ofType(loadEarningsRecords.type),
    switchMap((action: { payload: EarningsRecordsRequest; }) => {
      return from(
        EarningsRecordsService.getEarningsRecords(action.payload),
      ).pipe(
        map((res: any) => {return res.data;}),
        map((res: EarningsRecords) => {return storeEarningsRecords(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

export const epics: any[] = [loadEarningsRecords$];
