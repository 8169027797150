import React, { useEffect, useState } from 'react';
import '../time-card-styles.scss';
import styles from './styles.module.scss';
import { RateMaster, RateMasterRequest } from 'core/models/ContractorReports.model';
import Modal from 'core/components/modals/Modal';
import { currencyFormatter } from 'utilities/utilities';
import { InputGrp, SelectGrp } from 'core/components/form-controls';
import { getRateMaster } from 'core/store/slices/contractorReports.slice';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { AtsInfo } from 'core/models/Contractor.model';
import { ColumnHeaderData, Row } from 'core/components/shared/dm-table/types';
import Table from 'core/components/shared/dm-table/Table';

const buildAtsRow = (rateMaster: RateMaster): Row => {
  return {
    id: rateMaster.areaTradeSubId,
    cells: [
      { children: rateMaster.trade, styleClass: 'td-10' },
      { children: rateMaster.tradeDescription, styleClass: 'td-30' },
      { children: rateMaster.sub, styleClass: 'td-10' },
      { children: rateMaster.subDescription, styleClass: 'td-30' },
      { children: currencyFormatter(rateMaster.baseRate), styleClass: 'td-20' },
    ],
  };
};

const areaTradeSubColumns: ColumnHeaderData[] = [
  { title: 'Trade', styleClass: 'th-10' },
  { title: 'Trade Description', styleClass: 'th-30' },
  { title: 'Sub', styleClass: 'th-10' },
  { title: 'Sub Description', styleClass: 'th-30' },
  { title: 'Base Rate', styleClass: 'th-20' },
];

type Props = {
  currentData: AtsInfo;
  index: number;
  show: boolean;
  onHide: () => void;
  updateAtsInfo: (newInfo: AtsInfo) => void;
};

const matchQuery = (_rateMaster: RateMaster, _query: string): boolean => {
  return !!(_rateMaster?.trade?.includes(_query)
      || _rateMaster?.tradeDescription?.toLowerCase()?.includes(_query)
      || _rateMaster?.sub?.includes(_query)
      || _rateMaster?.subDescription?.toLowerCase()?.includes(_query));
};

const AreaTradeSubModal = ({ currentData, index, show, onHide, updateAtsInfo }: Props) => {
  const [areaOptions, setAreaOptions] = useState<{ id: string, description: string }[]>();
  const [selectedArea, setSelectedArea] = useState<{ id: string, description: string }>();
  const [tradeSubOpts, setTradeSubOpts] = useState<RateMaster[]>();
  const [filteredTradeSubOpts, setFilteredTradeSubOpts] = useState<RateMaster[]>();
  const [query, setQuery] = useState<string>('');
  
  const dispatch = useAppDispatch();
  
  const rateMaster = useAppSelector(({ contractorReports }) => contractorReports.rateMaster);
  const clientNo = useAppSelector(({ client }) => client.client?.clientNo);
  
  useEffect(() => {
    if (!clientNo || rateMaster?.length) return;
    const request: RateMasterRequest = {
      clientNo: clientNo,
      onlyCurrent: true,
      includeFringes: true,
      onlyActive: false,
    };
    
    dispatch(getRateMaster(request));
  }, [clientNo]);
  
  useEffect(() => {
    if (!rateMaster?.length) return;
    const areaOpts = rateMaster.map(({ area, areaDescription }: RateMaster) => ({
      id: area ?? '',
      description: areaDescription ?? '',
    }));
    const uniqueAreaOpts = [...new Map(areaOpts.map((_area) => [`${_area.id}:${_area.description}`, _area])).values()];
    setSelectedArea(uniqueAreaOpts?.find((_area) => _area.id === currentData.area) ?? uniqueAreaOpts[0]);
    setAreaOptions(uniqueAreaOpts);
  }, [rateMaster]);
  
  useEffect(() => {
    if (!selectedArea) return;
    const newFilteredTradeSubOpts = rateMaster
      .filter((_rateMaster: RateMaster) =>
        _rateMaster.area === selectedArea.id && (query.length ? matchQuery(_rateMaster, query) : true),
      )
      .map((_rateMaster: RateMaster) => ({
        ..._rateMaster,
        value: String(_rateMaster.areaTradeSubId),
        label: `${_rateMaster.trade}-${_rateMaster.tradeDescription} ${_rateMaster.sub}-${_rateMaster.subDescription} ${_rateMaster.baseRate}`,
      }));
    if (!newFilteredTradeSubOpts?.length) return;
    setTradeSubOpts(newFilteredTradeSubOpts);
  }, [selectedArea, query.length === 0]);
  
  useEffect(() => {
    setFilteredTradeSubOpts(tradeSubOpts ?? []);
  }, [tradeSubOpts]);
  
  const handleSearch = (newQuery: string) => {
    if (!newQuery.trim().length) {
      setQuery('');
      setFilteredTradeSubOpts(tradeSubOpts);
      return;
    }
    
    const newState = structuredClone(tradeSubOpts)?.filter((_rateMaster: RateMaster) => matchQuery(_rateMaster, newQuery));
    
    setQuery(newQuery);
    setFilteredTradeSubOpts(newState);
  };
  
  const handleRowClick = (id: string | number) => {
    const newSelection = filteredTradeSubOpts?.find((x) => x.areaTradeSubId === id);
    if (!newSelection) return;
          
    const newAtsInfo: AtsInfo = {
      area: newSelection.area ?? '',
      trade: newSelection.trade ?? '',
      sub: newSelection.sub ?? '',
      subDescription: newSelection?.subDescription ?? '',
      baseRate: newSelection.baseRate,
    };
          
    updateAtsInfo(newAtsInfo);
  };
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Trade-Sub Options"
      size="xl"
    >
      <div className={styles['trade-sub-top-container']}>
        <div className="d-flex flex-column">
          <label className={styles['trade-sub-top-label']}>Area</label>
          <SelectGrp
            name="area"
            options={areaOptions ?? []}
            defaultValue={selectedArea?.id}
            onChange={(e: any) => {
              setSelectedArea(areaOptions?.find((area) => area.id === e.target.value));
              setFilteredTradeSubOpts(tradeSubOpts?.filter((tradeSubOpt) => tradeSubOpt.areaTradeSubId === e.target.value));
            }}
            groupClass="gc20 mw150 m-0"
            selectClass={styles['trade-sub-input']}
            showId
          />
        </div>
        <div className="d-flex flex-column ml-3">
          <label
            htmlFor="trade-sub-search"
            className={styles['trade-sub-top-label']}
          >Search</label>
          <InputGrp
            placeholder="Enter trade, sub, or description"
            groupClass="m-0"
            inputClass={`gc20 ${styles['trade-sub-input']}`}
            name="trade-sub-search"
            type="text"
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              handleSearch(target.value);
            }}
          />
        </div>
        <div className="head-section ml-3 mb-0 mt-auto">
          <strong>Current Info</strong>
          <span><strong>Area:&nbsp;</strong>{currentData.area}</span>
          <span><strong>Trade:&nbsp;</strong>{currentData.trade}</span>
          <span><strong>Sub:&nbsp;</strong>{currentData.sub}</span>
          <span><strong>Base Rate:&nbsp;</strong>{currencyFormatter(currentData.baseRate)}</span>
        </div>
      </div>
      <Table
        columns={areaTradeSubColumns}
        rows={filteredTradeSubOpts?.map((x) => buildAtsRow(x)) ?? []}
        onRowClick={handleRowClick}
      />
    </Modal>
  );
};

export default AreaTradeSubModal;