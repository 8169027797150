import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { File, FileResponse } from '../../models/File.model';
import { postDownloadMultipleFiles } from '../actions';
import { CommonService } from 'core/services';

export interface FileState {
  loading: boolean;
  userFiles: File.UserFiles | null;
  file: FileResponse | null;
  displayType: string;
  addFileRecordId: number;
}

const initialState: FileState = {
  loading: false,
  userFiles: null,
  file: null,
  displayType: 'Folder',
  addFileRecordId: -1,
};

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    getUserFiles: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.displayType = action.payload;
    },
    storeUserFiles: (state, action: PayloadAction<File.UserFiles>) => {
      state.loading = false;
      state.userFiles = action.payload;
    },
    clearUserFiles: (state) => {
      state.userFiles = null;
    },
    storeFile: (state, action: PayloadAction<FileResponse>) => {
      state.loading = false;
      state.file = action.payload;
    },
    getFile: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (filePath: number) => {
        return {
          payload: filePath,
        };
      },
    },
    postClientFileUpload: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (fileUpload: File.FileUpload) => {
        return {
          payload: fileUpload,
        };
      },
    },
    putClientFile: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (file: File.FileRecord) => {
        return {
          payload: file,
        };
      },
    },
    deleteClientFile: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (fileRecordId: number) => {
        return {
          payload: fileRecordId,
        };
      },
    },
    clearFile: (state) => {
      state.file = null;
    },
    postFileFolder: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (folder: File.ManageFolder) => {
        return {
          payload: folder,
        };
      },
    },
    putFileFolder: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (folder: File.ManageFolder) => {
        return {
          payload: folder,
        };
      },
    },
    putAssignFile: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (file: File.AssignFile) => {
        return {
          payload: file,
        };
      },
    },
    resetLoading: (state) => {
      state.loading = false;
    },
    deleteFileFolder: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (fileCategoryId: number) => {
        return {
          payload: fileCategoryId,
        };
      },
    },
    addFileRecordId: (state, action: PayloadAction<number>) => {
      state.addFileRecordId = action.payload;
    },
    postMoveClientH2R: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (folder: any) => {
        return {
          payload: folder,
        };
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(postDownloadMultipleFiles.pending, (state) => {
        state.loading = true;
      })
      .addCase(postDownloadMultipleFiles.fulfilled, (state, action) => {
        state.loading = false;
        const fileRecords = action.payload?.value; 
        if(!fileRecords) return;

        for (let i = 0; i < fileRecords.length; i += 1) {
          setTimeout(
            () => {
              let file = fileRecords[i];
              let fileType = '';
              if (file.extension === '.csv') fileType = 'application/csv';
              else if (file.extension == '.pdf') fileType = 'application/pdf';
              else if (file.extension == '.zip') fileType = 'application/zip';
              else fileType = 'application/excel';
              
              const fileName = (file.name.includes(file.extension)) ? file.name : `${file.name}${file.extension}`;
              CommonService.downloadBase64File(
                fileType,
                file.data,
                fileName,
              );
            },
            i * 200 // Delay download every 200ms
          );
        }
      })
      .addCase(postDownloadMultipleFiles.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  getUserFiles,
  storeUserFiles,
  clearUserFiles,
  getFile,
  storeFile,
  clearFile,
  postClientFileUpload,
  putClientFile,
  deleteClientFile,
  postFileFolder,
  putFileFolder,
  putAssignFile,
  deleteFileFolder,
  resetLoading,
  addFileRecordId,
  postMoveClientH2R,
} = fileSlice.actions;
export default fileSlice.reducer;
