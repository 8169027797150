import React, { Component } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import editIcon from '../../assets/images/edit-small.png';

interface ParamTypes extends ICellRendererParams {
  label: string;
  clickHandler: (e: any) => void;
  title?: string;
}

export default class AGEditButtonRendererComponent extends Component {
  constructor(public props: ParamTypes) {
    super(props);
  }

  componentDidMount() { }

  refresh(params: ParamTypes) { }

  render() {
    const onClick = (e: any) => {
      e.stopPropagation();
      this.props.clickHandler(this.props.data);
    };
    return (
      <button
        type="button"
        title={this.props.title ? this.props.title : ''}
        className="btn btn-link btn-block p-0"
        onClick={onClick}
      >
        {this.props.label ? this.props.label + ' ' : ''}
        <img
          className="ml-2"
          src={editIcon}
          alt="edit"
        />
      </button>
    );
  }
}
