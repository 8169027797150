import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Employee3YearPayHistoryRequest, EmployeePayRateDownloadRequest } from 'core/models/PayRateReports.model';

export interface PayRateReportsState {
  loading: boolean;
  report: string;
  employeePayRateReportDownload: string;
  employee3YearPayHistory: string;

}

const initialState: PayRateReportsState = {
  loading: false,
  report: '',
  employeePayRateReportDownload: '',
  employee3YearPayHistory: '',
};

export const payRateReportsSlice = createSlice({
  name: 'payRateReports',
  initialState,
  reducers: {
    getFuturePayRateReport: (state) => {
      state.loading = true;
    },
    storePayRateReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.report = action.payload;
    },
    clearPayRateReport: (state) => {
      state.report = '';
    },
    downloadEmployeePayRateDownloadReport: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: EmployeePayRateDownloadRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeEmployeePayRateDownloadReport: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.employeePayRateReportDownload = action.payload;
    },
    clearEmployeePayRateDownloadReport: (state) => {
      state.employeePayRateReportDownload = '';
    },
    downloadEmployee3YearPayHistory: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare: (request: Employee3YearPayHistoryRequest) => {
        return {
          payload: request,
        };
      },
    },
    storeEmployee3YearPayHistory: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.employee3YearPayHistory = action.payload;
    },
    clearEmployee3YearPayHistory: (state) => {
      state.employee3YearPayHistory = '';
    },
  },
});

export const {
  getFuturePayRateReport,
  storePayRateReport,
  clearPayRateReport,
  downloadEmployeePayRateDownloadReport,
  storeEmployeePayRateDownloadReport,
  clearEmployeePayRateDownloadReport,
  downloadEmployee3YearPayHistory,
  clearEmployee3YearPayHistory,
  storeEmployee3YearPayHistory,
} = payRateReportsSlice.actions;
export default payRateReportsSlice.reducer;
