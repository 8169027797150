import React from 'react';
import { Dropdown } from 'core/models';
import { useSelector } from 'react-redux';
import { getIsFinishedPayroll } from 'core/store/selectors';
import { useAppSelector } from 'utilities/hooks';
import {
  ControlDatePickerGrp,
  InputGrp,
  SelectGrp,
} from 'core/components/form-controls';
import { DatePickerGrp } from 'core/components/form-controls/DatePickerGrp';
import { adjustmentCodeOpts } from 'core/constants';

type Props = {
  index: number;
  errors: any;
  payrollHistoryId: number;
  check: any;
  employeeAdjustment: any | null;
  adjustmentType: any | undefined;
  disabled: boolean;
  voidDisabled: boolean;
  adjustmentCodeOpts: Dropdown[];
  onAdjustmentTypeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const AdjustmentTable = ({
  index,
  errors,
  payrollHistoryId,
  check,
  employeeAdjustment,
  disabled,
  voidDisabled,
  adjustmentType,
  onAdjustmentTypeChange,
}: Props) => {
  const isFinishedPayroll = useSelector(getIsFinishedPayroll);
  const currentPayroll = useAppSelector(({ payroll }) => payroll.payroll.find((dateline) => dateline.payrollHistoryId === payrollHistoryId));

  return (
    <div className="dm-panel dm-panel-border mt-1">
      <div className="d-flex flex-row">
        <div>
          <DatePickerGrp
            label="Week Ending"
            groupClass="gc04 mw125"
            name={`items[${index}].weekEnd`}
            errors={errors?.items?.[index]?.weekEnd}
            value={check?.weekEnd ?? employeeAdjustment?.weekEnd ?? currentPayroll?.weekEnd}
            disabled={voidDisabled || isFinishedPayroll}
            required={true}
            rules={{
              required: 'Week ending required.',
            }}
            onChange={(newVal: Date | null) => {}}
          />
        </div>
        <div>
          <DatePickerGrp
            label="Check Date"
            groupClass="gc04 mw125"
            name={`items[${index}].checkDate`}
            errors={errors?.items?.[index]?.checkDate}
            value={check?.checkDate ?? employeeAdjustment?.checkDate ?? currentPayroll?.checkDate}
            disabled={voidDisabled || isFinishedPayroll}
            required={true}
            rules={{
              required: 'Check date required.',
            }}
            onChange={(newVal: Date | null) => {}}
          />
        </div>
        <div>
          <InputGrp
            label="Check Number"
            groupClass="gc04 mw125"
            name={`items[${index}].checkNo`}
            errors={errors?.items?.[index]?.checkNo}
            defaultValue={check?.checkNo ?? 0}
            disabled={voidDisabled || isFinishedPayroll}
          />
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="w-100 mt-2">
          <SelectGrp
            name={`items[${index}].adjustmentType`}
            id="adjustmentCode"
            options={adjustmentCodeOpts}
            errorMsg="Required"
            errors={errors?.items?.[index]?.adjustmentType}
            defaultValue={adjustmentType}
            disabled={disabled || voidDisabled || isFinishedPayroll}
            onChange={onAdjustmentTypeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default AdjustmentTable;
