import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PanelHeader from 'core/components/shared/PanelHeader';
import { HRSkills, HRSkillsRequest } from 'core/models/HREmployee.model';
import {
  createHRSkills,
  deleteHRSkills,
  loadHRSkills,
  updateHRSkills,
} from 'core/store/actions/hr-employee.action';
import { getHireInfoSkills } from 'core/store/selectors/hr-employee.selector';
import SkillsItem from './SkillsItem';
import { useForm, useFieldArray } from 'react-hook-form';
import { UNSAVED_MESSAGE } from 'core/constants';
import Icon from 'core/components/shared/Icon';
import { handleError, toggleBlockNavigation } from 'core/store/actions';

type FormType = {
  skillsItems: HRSkills[];
};
const SkillsPage: React.FC<any> = () => {
  const dispatch = useDispatch();

  const { protectedEmpNo } = useParams<any>();
  const [skillsItems, setSkills] = useState<HRSkills[]>([]);
  const skillsStore = useSelector(getHireInfoSkills);

  const {
    reset,
    control,
    handleSubmit,
    formState,
    ...formMethods
  } = useForm<FormType>({
    defaultValues: {
      skillsItems,
    },
  });

  const { fields, prepend } =
    useFieldArray<HRSkills>({
      control,
      name: 'skillsItems',
      keyName: 'id',
    });


  useEffect(() => {
    protectedEmpNo && dispatch(loadHRSkills(protectedEmpNo));
  }, [protectedEmpNo]);

  useEffect(() => {
    skillsStore && setSkills(skillsStore);
  }, [skillsStore]);

  useEffect(() => {
    skillsItems && reset({ skillsItems });
  }, [skillsItems]);

  const addSkills = () => {
    prepend({
      empSkillId: 0,
      protectedEmpNo,
    });
  };

  const deleteSkills = (item: HRSkills) => {
    if (item.empSkillId) {
      dispatch(deleteHRSkills({
        data: item,
        protectedEmpNo,
      }));
    } else {
      setSkills((prev: HRSkills[]) => { return prev.filter((cb: HRSkills) => { return cb.empSkillId; }); });
    }
  };

  const onSubmit = (data: FormType, e?: React.BaseSyntheticEvent) => {
    if (!e?.target.isConnected) {
      return false;
    }
    
    if (!data) return dispatch(handleError('Error saving'));
    
    data.skillsItems.forEach((item: HRSkills) => {
      const request: HRSkillsRequest = {
        protectedEmpNo,
        data: {
          ...item,
          empSkillId: +item.empSkillId,
        },
      };
      request.data.empSkillId !== 0
        ? dispatch(updateHRSkills(request))
        : dispatch(createHRSkills(request));

    });
  };

  const onCancel = () => {
    reset();
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: formState.isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [formState.isDirty]);

  return (
    <div>
      <div className="dm-panel dm-panel-border" >
        <PanelHeader title="Employee Skills" >
          <button
            className="btn btn-link dm-grid-action-title p-0 mr-2"
            onClick={addSkills}
          >
            Add Skill <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields?.map((item, index: number) => {
            return (
              <div key={item.empSkillId} >
                <SkillsItem
                  item={item}
                  index={index}
                  control={control}
                  formMethods={formMethods}
                  onDelete={deleteSkills}
                />
                <hr className="dm-panel-hr mt-2" />
              </div>
            );
          })}

          <div className="text-right mt-3">
            <button
              type="button"
              className="btn btn-primary orange-outline-button ml-auto mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              disabled={!formState.isDirty}
              type="submit"
              className="btn orange-button-sm mr-3"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SkillsPage;
