import React from 'react';
import ssoInfo from '../../../assets/images/time-attendance-ad.jpg';
import Modal from './Modal';

type Props = {
  show: boolean;
  onHide: () => void;
};

const MainNavMenuItemModal = ({ show, onHide }: Props) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Time &amp; Attendance"
      size="xl"
    >
      <div>
        <img
          src={ssoInfo}
          alt="Time and Attendance"
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      </div>
    </Modal>
  );
};

export default MainNavMenuItemModal;
