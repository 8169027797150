import { createReducer } from '@reduxjs/toolkit';
import { OtherDate } from '../../models';
import { loadOtherDate, loadOtherDates, storeOtherDate, storeOtherDates } from '../actions';

export interface State {
  loading: boolean;
  otherDate: OtherDate[];
}

export const INITIAL_STATE: State = {
  loading: false,
  otherDate: [],
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadOtherDates.type]: (state) => {return { ...state, loading: true };},
  [storeOtherDates.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      otherDate: action.payload,
    };
  },

  [loadOtherDate.type]: (state) => {return { ...state, loading: true };},
  [storeOtherDate.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      otherDate: [
        ...state.otherDate.filter((o: OtherDate) => {return o.otherDateID !== action.payload.otherDateID;}),
        action.payload,
      ],
    };
  },
});