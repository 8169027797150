import React, { forwardRef } from 'react';
import { Labels } from '.';
import styles from './form-controls.module.scss';

type Props = {
  name: string;
  label: string;
  defaultValue: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void; 
};

const InputGrpUncontrolled = forwardRef(({ name, label, defaultValue, onChange, onBlur }: Props, ref: any) => {
  return (
    <fieldset className={`${styles['dm-form-group']} gc20 mw150`}>
      <Labels
        label={label}
        hasError={false}
      />
      <input
        className={styles['dm-form-control']}
        style={{ textAlign: 'right' }}
        name={name}
        ref={ref}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
      />
    </fieldset>
  );
});

InputGrpUncontrolled.displayName = 'InputGrpUncontrolled';

export default InputGrpUncontrolled;