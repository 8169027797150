import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridOptions } from '@ag-grid-enterprise/all-modules';
import Modal from 'core/components/modals/Modal';
import { OnTheClockRequest, PayrollUploadFile, PayrollUploadType, TimeCard } from 'core/models/PayrollUpload.model';
import { getOnTheClockData } from 'core/store/selectors/payroll-upload.selector';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ControlDatePickerGrp } from 'core/components/form-controls';
import { loadOnTheClockData, payrollUploadFileTool, storeOnTheClockData } from 'core/store/actions';
import { Spinner } from 'react-bootstrap';

const columns: ColDef[] = [
  {
    field: 'employeeNumber',
    headerName: 'Employee Number',
    sortable: true,
    editable: false,
    width: 200,
  },
  {
    field: 'employeeName',
    headerName: 'Employee Name',
    sortable: true,
    editable: false,
    width: 200,
  },
  {
    field: 'regularHours',
    headerName: 'Regular Hours',
    sortable: true,
    editable: false,
    width: 200,
  },
  {
    field: 'overTime1Hours',
    headerName: 'Over Time 1 Hours',
    sortable: true,
    editable: false,
    width: 200,
  },
  {
    field: 'totalHours',
    headerName: 'Total Hours',
    sortable: true,
    editable: false,
    width: 200,
  },
];

const gridOptions: GridOptions = {
  domLayout: 'autoHeight',
  columnDefs: columns,
  defaultColDef: {
    suppressMenu: true,
    resizable: true,
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
};

type PropTypes = {
  mapId: number;
  show: boolean;
  weekEnd: string | undefined;
  checkDate: string | undefined;
  selectedLocation: number;
  selectedDepartment: number;
  setPayrollUploadFile: React.Dispatch<React.SetStateAction<PayrollUploadFile | null>>
  onHide: () => void;
};
type FormProps = {
  fromDate: Date;
  toDate: Date;
};

const OnTheClockModal: React.FC<PropTypes> = ({
  mapId,
  show,
  weekEnd,
  checkDate,
  selectedLocation,
  selectedDepartment,
  setPayrollUploadFile,
  onHide,
}) => {
  const dispatch = useDispatch();
  const onTheClockData = useSelector(getOnTheClockData) as TimeCard[] | [];

  const [rowData, setRowData] = useState<TimeCard[]>([]);
  const [getDataButtonText, setGetDataButtonText] = useState<string>('Get On The Clock Data');
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const { control, watch, errors } = useForm<FormProps>({
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
    },
  });
  const {
    fromDate: formBeginDate,
    toDate: formEndDate,
  } = watch();

  const onGetOnTheClockData = () => {
    const request: OnTheClockRequest = {
      toDate: new Date(formEndDate).toISOString().slice(0, -1),
      fromDate: new Date(formBeginDate).toISOString().slice(0, -1), 
    };
    dispatch(loadOnTheClockData(request));
    setLoadingData(true);
  };

  const uploadOnTheClockData = () => {
    const uploadFile: PayrollUploadFile = {
      mapId: mapId,
      weekEnd: weekEnd || '',
      checkDate: checkDate || '',
      fileName: '',
      file: '',
      locationCode: selectedLocation,
      departmentCode: selectedDepartment,
      skipValidationErrors: false,
      overwrite: true,
      empNosToTrack: [],
      uploadType: PayrollUploadType.OnTheClock,
      frequency: '',
      aodMapName: '',
      fromDate: new Date(formBeginDate).toISOString().slice(0, -1),
      toDate: new Date(formEndDate).toISOString().slice(0, -1),
      uploadFile: true,
      onTheClockData: rowData,
    };
    dispatch(payrollUploadFileTool(uploadFile));
    setPayrollUploadFile(uploadFile);
    onHide();
  };

  useEffect(() => {
    setRowData(onTheClockData);
    setLoadingData(false);
  }, [onTheClockData]);

  useEffect(() => {
    const buttonText = (loadingData) ? 'Getting Data...' : 'Get On The Clock Data';
    setGetDataButtonText(buttonText);
  }, [loadingData]);
  
  useEffect(() => {
    dispatch(storeOnTheClockData([]));
    setRowData([]);
    setLoadingData(false);
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      animation={false}
      title="On The Clock Upload"
    >
      {(closeModal) => {
        return (
          <>
            <div className="select-list-wrapper shadow border p-1">                 
              <div className="inputs-container selected-inputs m-2">
                <div className="d-flex flex-column w-25">
                  <span className="dm-grid-action-title">Date Range Options</span>
                  <div className="range-group">            
                    <ControlDatePickerGrp
                      groupClass="w-50"
                      name="fromDate"
                      label="From Date:"
                      control={control}
                      errors={errors.fromDate}
                    />
                    <ControlDatePickerGrp
                      groupClass="w-50"
                      name="toDate"
                      label="To Date:"
                      control={control}
                      errors={errors.toDate}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column w-50">
                  <div className="inputs-container selected-inputs align-items-center">
                    <button
                      className="btn btn-primary h-100 w-20 ml-0"
                      onClick={onGetOnTheClockData}
                      disabled={loadingData}
                    >
                      {getDataButtonText}&nbsp;
                      {loadingData ? <Spinner
                        animation="border"
                        size="sm"
                        className="dm-primary-blue"
                      /> : null}
                    </button>
                    <button
                      className="btn btn-primary h-100 w-20 ml-0"
                      onClick={uploadOnTheClockData}
                      disabled={!(rowData?.length)}
                    >
                      Upload On The Clock Data
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <span className="dm-grid-action-title">Results From On The Clock:</span>
            <div className="table-wrapper-wrapper ag-theme-balham">
              <AgGridReact
                gridOptions={gridOptions}
                rowData={rowData || []}
              />
            </div>
            <div className="row mt-3">
              <div className="col-12 text-right">
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button mr-2"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </>
        );
      }}
    </Modal>
  );
};
export default OnTheClockModal;
