import React, { useEffect, useState } from 'react';
import { deleteEmployeeDocComp, loadEmployeeDocComp } from '../../../core/store/actions';
import PanelHeader from '../../../core/components/shared/PanelHeader';
import { getEmpDocComp, getSelectedEmp } from '../../../core/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EmpDocComp, Employee } from '../../../core/models';
import HRDocCompRecord from './HRDocCompRecord';
import { DateTime } from 'luxon';
import Icon from 'core/components/shared/Icon';

const HRDoctorCompPage: React.FC<any> = () => {

  const dispatch = useDispatch();
  const employeeDocCompsFromStore = useSelector(getEmpDocComp);
  const [employeeDocComps, setDocComps] = useState<EmpDocComp[]>([]);
  const [initialDocComp, setInitialDocComp] = useState<any>();
  const selEmp = useSelector(getSelectedEmp) as Employee;
  const { protectedEmpNo } = useParams<any>();

  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(loadEmployeeDocComp({ protectedEmpNo }));
    }
  }, [protectedEmpNo]);

  useEffect(() => {
    setDocComps(employeeDocCompsFromStore);
    const comp = employeeDocCompsFromStore && employeeDocCompsFromStore[0];
    setInitialDocComp({
      ...employeeDocCompsFromStore[0],
      effectiveDate: comp?.effectiveDate && new Date(comp.effectiveDate),
      endDate: comp?.endDate && new Date(comp.endDate),
    });
  }, [employeeDocCompsFromStore]);

  const onAddDocCompRecord = () => {
    const newDocComp = new EmpDocComp(selEmp?.empId, selEmp.protectedEmpNo, []);
    newDocComp.effectiveDate = DateTime.fromJSDate(new Date()).toLocaleString();
    setDocComps((prev: EmpDocComp[]) => {
      return [
        ...prev, newDocComp,
      ];
    });
    setInitialDocComp(newDocComp);
  };

  const onDelete = (data: EmpDocComp) => {
    data.docCompHeaderId && dispatch(deleteEmployeeDocComp(data));
  };

  return (
    <div className="dm-panel dm-panel-border" >
      <PanelHeader title="Doc Comp Record" >
        <button className="btn btn-link dm-grid-action-title p-0 mr-2"
          onClick={onAddDocCompRecord}>
          Add New Doc Comp Record <Icon name="plus-circle"
            className="fa-plus-circle" />
        </button>
      </PanelHeader>
      <div className="dm-panel dm-panel-border" >
        {employeeDocComps.length ? <HRDocCompRecord initialDocComp={initialDocComp}
          employeeDocComps={employeeDocComps}
          onDelete={onDelete} /> : null}
      </div>
    </div>
  );
};

export default HRDoctorCompPage;
