import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import '../time-card-styles.scss';
import { InputGrp } from 'core/components/form-controls';
import { useSelector } from 'react-redux';
import { getEmployees } from 'core/store/selectors';
import { currencyFormatter, groupByField } from 'utilities/utilities';
import { Employee } from 'core/models';
import EmpSnapshotRow from '../EmpSnapshotRow';
import { ColumnHeaderData, Row, RowWithParent } from 'core/components/shared/dm-table/types';
import Table from 'core/components/shared/dm-table/Table';
import { TimeCardsByDayData, TimeCardsByDayDetailData } from 'core/models/Contractor.model';
import Icon from 'core/components/shared/Icon';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { useHistory } from 'react-router-dom';
import { onCellDetailClick } from '../utilities';

type Props = {
  selectedDate: TimeCardsByDayData;
};

type GenericGroup<T> = {
  [key: string]: T[];
};

const empDayInfoColumns: ColumnHeaderData[] = [
  { title: 'Job No.', styleClass: 'th-20', position: 'center' },
  { title: 'Check Code', styleClass: 'th-20', position: 'center' },
  { title: 'Hours', styleClass: 'th-20', position: 'center' },
  { title: 'Earnings', styleClass: 'th-20', position: 'center' },
  { title: 'Deductions', styleClass: 'th-20', position: 'center' },
  { title: '', styleClass: 'th-20', position: 'center' },
];

const groupByEmployee = (details: TimeCardsByDayDetailData[], query?: string): GenericGroup<TimeCardsByDayDetailData> => {
  return groupByField<TimeCardsByDayDetailData>(
    details.filter((detail) =>
      query ? detail.empNo.toString().includes(query) || detail.employeeName.toLowerCase().includes(query.toLowerCase()) : true),
    'empNo',
  );
};

const DateDetails = ({ selectedDate }: Props) => {
  const { details } = selectedDate;
  const groupedDetails = useMemo(() => groupByEmployee(details), [details]);
  const [groupState, setGroupState] = useState<GenericGroup<TimeCardsByDayDetailData>>(groupByEmployee(details));
  const [expandAll, setExpandAll] = useState<boolean>(false);
  const employees = useSelector(getEmployees); 

  const { timeCards } = useAppSelector(({ contractor }) => contractor);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const buildEmpDayInfoRow = (empyDayInfo: TimeCardsByDayDetailData, index: number): Row => {

    const openTimeEntrySheet = 
        <button
          className="btn btn-link dm-grid-action-title py-0 mr-3"
          onClick={() => onCellDetailClick(empyDayInfo.transmittalTimeCardId, timeCards, history, dispatch)}
        >
          Open Time Sheet
        </button>

    return {
      id: index,
      cells: [
        { children: empyDayInfo.jobNo, styleClass: 'td-20', position: 'center' },
        { children: empyDayInfo.checkCode, styleClass: 'td-20', position: 'center' },
        { children: empyDayInfo.hours.toFixed(2), styleClass: 'td-20', position: 'end' },
        { children: currencyFormatter(empyDayInfo.earnings), styleClass: 'td-20', position: 'end' },
        { children: currencyFormatter(empyDayInfo.deductions), styleClass: 'td-20', position: 'end' },
        { children: openTimeEntrySheet, styleClass: 'td-20', position: 'center' },
      ],
    };
  };
  
  useEffect(() => {
    setGroupState(groupedDetails);
  }, [details]);
  
  const handleEmployeeSearch = (query: string) => {
    if (!query.trim().length) {
      setGroupState(groupedDetails);
      return;
    }
    setGroupState(groupByEmployee(details, query));
  };
  
  const mapFromKey = useCallback((key: string): JSX.Element | null => {
    const employee: Employee | undefined = employees.find((emp) => emp.empNo === parseInt(key));
    if (!employee) return null; // skip those hefty calculations etc.
    
    const employeeDetails: Row[] = groupState[key].map((x: TimeCardsByDayDetailData, index: number) => buildEmpDayInfoRow(x, index));
    const totalData = groupState[key]
      .map(({ hours, earnings, deductions }) => ({ hours, earnings, deductions }))
      .reduce((totalObj, { hours, earnings, deductions }) => ({
        hours: totalObj.hours + hours,
        earnings: totalObj.earnings + earnings,
        deductions: totalObj.deductions + deductions,
      }),
      { hours: 0, earnings: 0, deductions: 0 },  
      );
    
    const totalHours = (totalData.hours === 0) ? '' : totalData.hours?.toFixed(2)?.toString();
    const totalEarnings = (totalData.earnings === 0) ? '' : currencyFormatter(totalData.earnings);
    const totalDeductions = (totalData.deductions === 0) ? '' : currencyFormatter(totalData.deductions);

    const totals = [
      { field: 'Total Hours', data: totalHours },
      { field: 'Total Earnings', data: totalEarnings },
      { field: 'Total Deductions', data: totalDeductions },
    ];
        
    return (
      <EmpSnapshotRow
        employee={employee}
        headerInfo={totals}
        expandAll={expandAll}
      >
        <Table
          rows={employeeDetails}
          columns={empDayInfoColumns}
          bodyClass="tbody-fit"
        />
      </EmpSnapshotRow>
    );
  }, [groupState, expandAll]);
  
  return (
    <div className={styles['detail-column']}>
      <div className={styles['job-info-tools']}>
        <label
          htmlFor="emp-search"
          className={styles['top-label']}
        >Filter employees</label>
        <InputGrp
          placeholder="Search"
          groupStyle={{ marginBottom: '0' }}
          inputClass={`gc20 ${styles.input}`}
          name="emp-search"
          type="text"
          onChange={(e: any) => {
            handleEmployeeSearch(e.target.value);
          }}
        />
        <div className="head-section">
          <strong>Totals</strong>
          <div><strong>Hours:&nbsp;</strong>{selectedDate.totalHours.toFixed(2)}</div>
          <div><strong>Earnings:&nbsp;</strong>{currencyFormatter(selectedDate.totalEarnings)}</div>
          <div><strong>Deductions:&nbsp;</strong>{currencyFormatter(selectedDate.totalDeductions)}</div>
        </div>
        <button
            className="btn btn-link"
            onClick={() => { setExpandAll(!expandAll); }}
          >
            {expandAll ? 'Collapse' : 'Expand'} all&nbsp;<Icon name={expandAll ? 'chevron-circle-up' : 'chevron-circle-down'} />
        </button>
      </div>
      <div
        className="dm-panel-border w-100"
        style={{ overflowY: 'auto' }}
      >
        <div className={styles['job-details']}>
          {/* Build employee groups from grouped data object (the keys of which are emp. #s) */}
          {Object.keys(groupState)?.map(mapFromKey)}
        </div>
      </div>
    </div>
  );
};

export default DateDetails;
