import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { RateMaster, RateMasterRequest } from 'core/models/ContractorReports.model';
import Modal from 'core/components/modals/Modal';
import { currencyFormatter } from 'utilities/utilities';
import { InputGrp, SelectGrp } from 'core/components/form-controls';
import { getRateMaster } from 'core/store/slices/contractorReports.slice';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { AtsInfo } from './PayRateItem';
import PageSpinner from 'core/components/shared/PageSpinner';

type Props = {
  currentData: AtsInfo;
  show: boolean;
  onHide: () => void;
  updateSelectedTradeSub: (newATS: AtsInfo) => void;
};
type StyleClass = 'trade-sub-th' | 'th-sm' | 'th-lg' | 'th-xl' | 'trade-sub-td' | 'td-sm' | 'td-lg' | 'td-xl'; 
type CellProps<T = string | undefined> = {
  data: T;
  styleClass: StyleClass;
  alignment?: 'start' | 'center' | 'end'
};
// the trailing comma is needed for arrow functions to tell it T is a generic type and not an unclosed element tag
// eslint-disable-next-line @typescript-eslint/comma-dangle
const Cell = <T,>({ data, styleClass, alignment = 'start' }: CellProps<T>) => (
  <div className={`${styles[styleClass]} d-flex justify-content-${alignment}`}>
    {data}
  </div>
);

const matchQuery = (_rateMaster: RateMaster, _query: string): boolean => {
  return !!(_rateMaster?.trade?.includes(_query)
      || _rateMaster?.tradeDescription?.toLowerCase()?.includes(_query)
      || _rateMaster?.sub?.includes(_query)
      || _rateMaster?.subDescription?.toLowerCase()?.includes(_query));
};

const TradeSubModal = ({ currentData, show, onHide, updateSelectedTradeSub }: Props) => {
  const [areaOptions, setAreaOptions] = useState<{ id: string, description: string }[]>();
  const [selectedArea, setSelectedArea] = useState<{ id: string, description: string }>();
  const [tradeSubOpts, setTradeSubOpts] = useState<RateMaster[]>();
  const [filteredTradeSubOpts, setFilteredTradeSubOpts] = useState<RateMaster[]>();
  const [query, setQuery] = useState<string>('');
  
  const dispatch = useAppDispatch();
  
  const { rateMaster, loading } = useAppSelector(({ contractorReports }) => contractorReports);
  const clientNo = useAppSelector(({ client }) => client.client?.clientNo);
  
  useEffect(() => {
    if (!clientNo) return;
    const request: RateMasterRequest = {
      clientNo: clientNo,
      onlyCurrent: true,
      includeFringes: true,
      onlyActive: false,
    };
    
    dispatch(getRateMaster(request));
  }, [clientNo]);
  
  useEffect(() => {
    if (!rateMaster?.length) return;
    const areaOpts = rateMaster.map(({ area, areaDescription }: RateMaster) => ({
      id: area ?? '',
      description: areaDescription ?? '',
    }));
    const uniqueAreaOpts = [...new Map(areaOpts.map((_area) => [`${_area.id}:${_area.description}`, _area])).values()];
    setSelectedArea(uniqueAreaOpts?.find((_area) => _area.id === currentData.area) ?? uniqueAreaOpts[0]);
    setAreaOptions(uniqueAreaOpts);
  }, [rateMaster]);
  
  useEffect(() => {
    if (!selectedArea) return;
    const newFilteredTradeSubOpts = rateMaster
      .filter((_rateMaster: RateMaster) =>
        _rateMaster.area === selectedArea.id && (query.length ? matchQuery(_rateMaster, query) : true),
      )
      .map((_rateMaster: RateMaster) => ({
        ..._rateMaster,
        value: String(_rateMaster.areaTradeSubId),
        label: `${_rateMaster.trade}-${_rateMaster.tradeDescription} ${_rateMaster.sub}-${_rateMaster.subDescription} ${_rateMaster.baseRate}`,
      }));
    if (!newFilteredTradeSubOpts?.length) return;
    setTradeSubOpts(newFilteredTradeSubOpts);
  }, [selectedArea, query.length === 0]);
  
  useEffect(() => {
    setFilteredTradeSubOpts(tradeSubOpts ?? []);
  }, [tradeSubOpts]);
  
  const handleSearch = (newQuery: string) => {
    if (!newQuery.trim().length) {
      setQuery('');
      setFilteredTradeSubOpts(tradeSubOpts);
      return;
    }
    
    const newState = structuredClone(tradeSubOpts)?.filter((_rateMaster: RateMaster) => matchQuery(_rateMaster, newQuery));
    
    setQuery(newQuery);
    setFilteredTradeSubOpts(newState);
  };
  
  const handleSelect = (newSelection: RateMaster) => {
    const updateAts: AtsInfo = {
      area: newSelection?.area ?? '',
      trade: newSelection?.trade ?? '',
      sub: newSelection?.sub ?? '',
      baseRate: newSelection?.baseRate ?? 0,
    };
    
    updateSelectedTradeSub(updateAts);
    onHide();
  };
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Trade-Sub Options"
      size="xl"
    >
      {loading ? <PageSpinner /> : (
        <>
          <div className={styles['trade-sub-top-container']}>
            <div className="d-flex flex-column">
              <label className={styles['trade-sub-top-label']}>Area</label>
              <SelectGrp
                name="area"
                options={areaOptions ?? []}
                defaultValue={selectedArea?.id}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setSelectedArea(areaOptions?.find((area) => area.id === e.target.value));
                  setFilteredTradeSubOpts(tradeSubOpts?.filter((tradeSubOpt) => String(tradeSubOpt.areaTradeSubId) === e.target.value));
                }}
                groupClass="gc20 mw150 m-0"
                selectClass={styles['trade-sub-input']}
                showId
              />
            </div>
            <div className="d-flex flex-column ml-3">
              <label
                htmlFor="trade-sub-search"
                className={styles['trade-sub-top-label']}
              >
                Search
              </label>
              <InputGrp
                placeholder="Enter trade, sub, or description"
                groupClass="m-0"
                inputClass={`gc20 ${styles['trade-sub-input']}`}
                name="trade-sub-search"
                type="text"
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                  handleSearch(target.value);
                }}
              />
            </div>
            <div className="d-flex flex-column ml-3">
              <label
                className={styles['trade-sub-top-label']}
              >Current Info</label>
              <div className={styles['current-info-container']}>
                <span className={styles.info}>Area:&nbsp;{currentData.area}</span>
                <span className={styles.info}>Trade:&nbsp;{currentData.trade}</span>
                <span className={styles.info}>Sub:&nbsp;{currentData.sub}</span>
                <span className={styles.info}>Base Rate:&nbsp;{currencyFormatter(currentData.baseRate)}</span>
              </div>
            </div>
          </div>
          <div className={styles['trade-sub-table-header']}>
            <Cell
              styleClass="th-sm"
              data={'Trade'}
            /> 
            <Cell
              styleClass="th-lg"
              data={'Trade Description'}
            />
            <Cell
              styleClass="th-sm"
              data={'Sub'}
            />
            <Cell
              styleClass="th-xl"
              data={'Sub Description'}
            />
            <Cell
              styleClass="th-sm"
              data={'Base Rate'}
            />
          </div>
          <div className={styles['trade-sub-tbody']}>
            {
              !filteredTradeSubOpts?.length ? (
                <span style={{ margin: '0 auto' }}>No results matching query</span>
              ) : (
                <>
                  {
                    filteredTradeSubOpts?.map((rateMasterOption: RateMaster) => {
                      return (
                        <div
                          className={styles['trade-sub-tr']}
                          key={rateMasterOption.areaTradeSubId}
                          onMouseDown={() => {
                            handleSelect(rateMasterOption);
                          }}
                        >
                          <Cell
                            styleClass="td-sm"
                            data={rateMasterOption.trade}
                            alignment="center"
                          />
                          <Cell
                            styleClass="td-lg"
                            data={rateMasterOption.tradeDescription}
                          />
                          <Cell
                            styleClass="td-sm"
                            data={rateMasterOption.sub}
                            alignment="center"
                          />
                          <Cell
                            styleClass="td-xl"
                            data={rateMasterOption.subDescription}
                          />
                          <Cell
                            styleClass="td-sm"
                            data={currencyFormatter(rateMasterOption.baseRate)}
                            alignment="center"
                          /> 
                        </div>
                      );
                    })
                  }
                </>
              ) 
            }
          </div>
        </>
      )}
    </Modal>
  );
};

export default TradeSubModal;