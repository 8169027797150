import { useReducer, Reducer } from 'react';

export type SortDirection = 'UNSORTED' | 'ASC' | 'DESC';

export type SortReducerState = Record<string, SortDirection>;

export type SortAction = {
  type: 'TOGGLE_SORT' | 'RESET';
  column: string;
};

/**
 * A function that returns a reducer acting as a finite state machine to get the "next" sort state. This top-level
 * function will take the initial sort state for the columns of data and the returned reducer will determine the 
 * next state for teh column. 
 *
 * This is passed as an argument to `useReducer` in the form of `sortStateMachine(initialState)`,
 * so that's all that's needed to use it.
 * @param initialState The initial sort state
 * @returns a reducer that updates the state
 */
export function sortStateMachine(initialState: SortReducerState) {
  return (state: SortReducerState, action: SortAction): SortReducerState => {
    switch (action.type) {
      // we're changing the sort order (action looks like { type: 'TOGGLE_SORT', column: 'someColumnName' })
      case 'TOGGLE_SORT': {
        // next after UNSORTED is ASC and so on
        switch (state[action.column]) {
          case 'UNSORTED':
          case undefined: // if the column doesn't exist in the sort state (can happen when it's built dynamically), on first click move it to ASC
            return { ...state, [action.column]: 'ASC' };
          case 'ASC':
            return { ...state, [action.column]: 'DESC' };
          case 'DESC':
          default:
            return { ...state, [action.column]: 'UNSORTED' };
        }
      }
      // we're resetting the state
      case 'RESET':
        return initialState;
    }
  };
}

/**
 * Function to create/return a fallback value if the taken one is null/undefined. If `value` is an array, the function
 * will call itself with the last item in the array.
 * @template T The type that the property is coming from
 * @param value The value we want to create a potential default for
 * @returns Original `value` or a default based on its type
 */
function getValueOrDefault<T>(value: NonNullable<T>[keyof T] | undefined): string | number | boolean {
  // if it's an array, call self w/ last value (TODO: maybe take an index argument?)
  if (Array.isArray(value)) return getValueOrDefault<T>(value?.[value.length - 1]);
  
  switch (typeof value) {
    case 'string': 
      // this will narrow the string value to a date if possible
      if (Object.prototype.toString.call(value) === '[object Date]') return value;
      return value?.toLowerCase() ?? '';
    case 'number':
      return Number(value ?? 0);
    case 'boolean':
      return Boolean(value ?? false);
    default:
      return ''; // TODO: What's the best default here?
  }
}

/**
 * Handles sorting `data` at `column` in `direction`. If UNSORTED, will sort by a default `defaultSortCol`.
 * @template T The type of `data`
 * @param data The data we want to sort
 * @param columns The column(s) we are sorting by
 * @param direction The direction in which we're sorting (ASC, DESC, or UNSORTED) 
 * @param defaultSortCols The default column(s) to sort by when in an UNSORTED state
 * @param defaultDirection The default sort direction (defaults to ASC)
 * @returns `data` sorted by factors above
 */
export function sortRowsByColumn<T>(data: T[], columns: Array<keyof T>, direction: SortDirection, defaultSortCols: Array<keyof T>, defaultDirection: 'ASC' | 'DESC' = 'ASC'): T[] {
  // if returning to an unsorted state, sort by the default column.
  const keys: Array<keyof T> = direction === 'UNSORTED' ? defaultSortCols : columns;
  const sortedData = structuredClone(data);
  
  sortedData.sort((a, b) => {
    // for loop inside comparator lets us sort by a variable number of columns
    for (const key of keys) {
      const keyA = getValueOrDefault<T>(a?.[key]);
      const keyB = getValueOrDefault<T>(b?.[key]);
      
      let aCompare = keyA < keyB;
      let bCompare = keyA > keyB;

      // if they aren't of the same type, don't sort
      if (typeof keyA !== typeof keyB) continue;
      if (typeof keyA === 'string' && typeof keyB === 'string') {
        aCompare = keyA.toLowerCase().trim().localeCompare(keyB.toLowerCase().trim()) === -1;
        bCompare = keyB.toLowerCase().trim().localeCompare(keyA.toLowerCase().trim()) === -1;
      }

      if (aCompare) {
        switch (direction) {
          case 'ASC':
            return -1;
          case 'DESC':
            return 1;
          case 'UNSORTED':
            return defaultDirection === 'ASC' ? -1 : 1;
        }
      } 
      if (bCompare) {
        switch (direction) {
          case 'ASC':
            return 1;
          case 'DESC':
            return -1;
          case 'UNSORTED':
            return defaultDirection === 'ASC' ? 1 : -1;
        }
      }
      // if values are equal, continue to the next key
    }
    return 0; // all keys were equal
  });
  
  return sortedData;
}

/**
 * Hook to handle sorting a table. Takes an initial state and uses a reducer as a finite state machine to cycle through
 * different sort states (ASC, DESC, or UNSORTED).
 * @param initialState The beginning state of type `SortReducerState`, key-value pairs of column names and their initial
 * sorts.
 * @returns the current sort state from the reducer, a dispatcher to update the state, and `sortRowsByColumn` to handle
 * the actual sorting.
 */
export default function useTableSort(initialState: SortReducerState) {
  const [currentSortState, sortDispatch] = useReducer<Reducer<SortReducerState, SortAction>>(sortStateMachine(initialState), initialState);
  
  return {
    /** the current sort model */
    currentSortState,
    /** dispatcher to update the sort model */
    sortDispatch,
    sortRowsByColumn,
  };
}
