import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { HireHistory, HireHistoryWithRehireInfo, HttpResponse } from '../../models';
import { HireHistoryService } from '../../services';
import {
  handleError,
  addHireHistoryWizard,
  deleteHireHistory,
  loadHireHistories,
  loadHireHistory,
  storeHireHistories,
  storeHireHistory,
  updateHireHistory,
  handleSuccess,
  loadEmpDate,
  loadEmployees,
  triggerEmFieldValidation,
  storeHireHistoryWizardMessage,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadHireHistories$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadHireHistories.type),
    switchMap((action: { payload: string; }) => {
      return from(HireHistoryService.getHireHistories(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HireHistory[]) => { return storeHireHistories(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadHireHistory$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadHireHistory.type),
    switchMap((action: { payload: HireHistory; }) => {
      return from(HireHistoryService.getHireHistory(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HireHistory) => { return storeHireHistory(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const addHireHistoryWizard$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(addHireHistoryWizard.type),
    switchMap((action: { payload: HireHistoryWithRehireInfo; }) => {
      return from(HireHistoryService.postHireHistoryWizard(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HireHistory>) => {
          return [
            loadEmpDate({ protectedEmpNo: action.payload.protectedEmpNo, updateKeys: [] }),
            loadHireHistories(action.payload.protectedEmpNo), 
            storeHireHistoryWizardMessage(res.messages[0]),
            triggerEmFieldValidation({
              section: 'dates',
              actionType: addHireHistoryWizard.type,
              callerPayload: res.value,
            }),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateHireHistory$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateHireHistory.type),
    switchMap((action: { payload: HireHistory; }) => {
      return from(HireHistoryService.putHireHistory(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<HireHistory>) => {
          return [
            storeHireHistory(res.value),
            handleSuccess(res.messages),
            loadEmployees(),
            triggerEmFieldValidation({
              section: 'dates',
              actionType: updateHireHistory.type,
              callerPayload: res.value,
            }),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteHireHistory$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteHireHistory.type),
    switchMap((action: { payload: HireHistory; }) => {
      return from(HireHistoryService.deleteHireHistory(action.payload)).pipe(
        map((res) => { return res.data; }),
        mergeMap(({ messages }: HttpResponse<any>) => {
          return [
            loadHireHistories(action.payload.protectedEmpNo),
            handleSuccess(messages),
            triggerEmFieldValidation({
              section: 'dates',
              actionType: updateHireHistory.type,
              callerPayload: action.payload,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};
export const epics: any[] = [
  loadHireHistories$,
  loadHireHistory$,
  addHireHistoryWizard$,
  updateHireHistory$,
  deleteHireHistory$,
];
