import { createReducer } from '@reduxjs/toolkit';
import { Sso } from '../../models/Sso.model';
import { loadSso, storeSso } from '../actions/sso.action';

export interface SsoState {
  loading: boolean;
  sso: Sso | null;
}

export const INITIAL_STATE: SsoState = {
  loading: false,
  sso: null,
};

export const ssoReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadSso, (state) => {
      state.loading = true;
      state.sso = null;
    })
    .addCase(storeSso, (state, action) => {
      state.loading = false;
      state.sso = action.payload;
    });
});
