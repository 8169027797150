
import React, { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CheckboxGrpInLine, InputGrp, RadioGrp, SelectGrp } from '../../core/components/form-controls';
import InputReportDateSelector from '../../core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import { Client, DtoOptions } from '../../core/models';
import { useAppDispatch, useAppSelector } from '../../utilities/hooks';
import { clearNewJobTrainingCreditReport, downloadNewJobTrainingCreditReport } from '../../core/store/actions/accounting-reports.action';
import { getNewTrainingCreditReport } from '../../core/store/selectors/accounting-reports.selector';
import { RadioOptions } from '../../core/components/form-controls/RadioGrp';
import { DateTime } from 'luxon';
import { NewJobTrainingCreditReportRequest } from '../../core/models/AccountingReports.model';
import { CommonService } from '../../core/services';
import Icon from 'core/components/shared/Icon';

const payrollDateOptions: RadioOptions[] = [
  {
    value: 'true',
    label: 'Use Selected Payroll Dates',
  },
  {
    value: 'false',
    label: 'Specific Month',
  },
];

const reportType: RadioOptions[] = [
  {
    value: 'true',
    label: 'Employee Detail',
  },
  {
    value: 'false',
    label: 'Summary',
  },
];

const NewJobTrainingCreditReport: React.FC = () => {

  const dispatch = useAppDispatch();
  const childRef: React.RefObject<any> = useRef();

  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [fileType, setFileType] = useState<string>('pdf');
  const client = useAppSelector((state) => { return state?.client?.client; }) as Client;

  const report = useSelector(getNewTrainingCreditReport);

  const [isUseSelectedDates, setIsUseSelectedDates] = useState<boolean>(true);

  const monthOptions = [
    {
      description: '',
      id: '0',
    },
    {
      description: '1',
      id: '1',
    },
    {
      description: '2',
      id: '2',
    },
    {
      description: '3',
      id: '3',
    },
    {
      description: '4',
      id: '4',
    },
    {
      description: '5',
      id: '5',
    },
    {
      description: '6',
      id: '6',
    },
    {
      description: '7',
      id: '7',
    },
    {
      description: '8',
      id: '8',
    },
    {
      description: '9',
      id: '9',
    },
    {
      description: '10',
      id: '10',
    },
    {
      description: '11',
      id: '11',
    },
    {
      description: '12',
      id: '12',
    },
  ];

  useEffect(() => {
    if (report && fileType === 'excel') {
      report && CommonService.downloadBase64File(
        'application/excel',
        report,
        'NewJobTrainingCreditReport.xlsx',
      );
      dispatch(clearNewJobTrainingCreditReport());
    }
  }, [report]);

  const hidePdfViewer = () => {
    dispatch(clearNewJobTrainingCreditReport());
  };

  const { register, errors, handleSubmit, control } =
    useForm<NewJobTrainingCreditReportRequest>({
      defaultValues: {
        isEmployeeDetail: 'true',
        useSelectedDates: 'true',
        month: undefined,
        year: undefined,
      },
    });

  const watch = useWatch<{ month: number, year: number }>({
    control,
  }) as { month: number, year: number };

  const downloadReport = (data: NewJobTrainingCreditReportRequest) => {

    const submitData: NewJobTrainingCreditReportRequest = {
      ...data,
      clientNo: client.clientNo,
      isEmployeeDetail: data.isEmployeeDetail === 'true' ? true : false,
      useSelectedDates: data.useSelectedDates === 'true' ? true : false,
      month: data.month,
      year: data.year,
      dtoOptions: reportDates,
      reportType: fileType,
    };
    dispatch(downloadNewJobTrainingCreditReport(submitData));
  };

  const buttonDisabled = () => {
    let disabled = true;
    disabled = (
      (!reportDates?.length) &&
      (!watch?.month && !watch?.year)
    );
    return disabled;
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  return (

    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">New Job Training Credit Report</div>
          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title "
              onClick={() => { return setFileType('excel'); }}
              disabled={buttonDisabled()}>
              Download Report  <Icon name="download"
                className="fa-download" />
            </button>
          </div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div>
              <InputReportDateSelector
                ref={childRef}
                returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }}
              />
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <div className="text-right">
                <button
                  type="button"
                  className="orange-outline-button mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="orange-button mr-2"
                  onClick={() => { return setFileType('pdf'); }}
                  disabled={buttonDisabled()}>
                  Run Report
                </button>
              </div>
              <div className="w-50 dm-panel-border p-2"
                style={{ marginTop: '66px' }}>
                <div className="d-flex">
                  <div className="d-flex flex-row flex-grow-1">
                    <div className="d-flex flex-column flex-grow-1">
                      <div className="dm-grid-action-title mb-2">Report Type</div>
                      <RadioGrp
                        isVertical={true}
                        radioOptions={reportType}
                        name="isEmployeeDetail"
                        ref={register}
                        defaultChecked={true}
                      />
                      <CheckboxGrpInLine
                        name="byPayroll"
                        label="By Payroll"
                        ref={register}
                      />
                    </div>
                    <div className="d-flex flex-column flex-grow-1">
                      <div className="dm-grid-action-title mb-2">Payroll Date Options</div>
                      <RadioGrp
                        isVertical={true}
                        radioOptions={payrollDateOptions}
                        name="useSelectedDates"
                        ref={register}
                        defaultChecked={true}
                        onChange={(onChange) => {
                          setIsUseSelectedDates(onChange.target.value === 'true');
                        }}
                      />
                      {!isUseSelectedDates ?
                        <div><SelectGrp
                          name="month"
                          label="Month"
                          options={monthOptions}
                          ref={
                            register({
                              valueAsNumber: true,
                            })
                          }
                        />
                          <InputGrp
                            label="Year"
                            type="number"
                            name="year"
                            ref={register({
                              valueAsNumber: true,
                              required: 'Please enter an amount',
                              min: {
                                value: DateTime.local().minus({ years: 20 }).year,
                                message: 'Enter a year greater than ' + DateTime.local().minus({ years: 20 }).year,
                              },
                              max: {
                                value: DateTime.local().year,
                                message: 'Enter a year less than ' + DateTime.local().plus({ year: 1 }).year,
                              },
                              minLength: {
                                value: 4,
                                message: 'Year must be 4 numbers',
                              },
                              maxLength: {
                                value: 4,
                                message: 'Year must be 4 numbers',
                              },
                            })
                            }
                            errors={errors?.year}
                          /></div>
                        : null}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </form>
        {
          report ? <PDFViewerModal
            show={report.length > 0}
            pdfData={report}
            onHide={hidePdfViewer}
            reportName="NewJobTrainingCreditReport"
          /> : null
        }
      </div >
    </div >

  );
};
export default NewJobTrainingCreditReport;


