import { createAction } from '@reduxjs/toolkit';
import { User } from '../../models/User.model';
import { UserAccess, UserDefaultClient } from '../../models/UserAccess';
import { ClientAccess } from 'core/models/ClientAccess';

export const login = createAction<string>('[auth] LOGIN');
export const loginSuccess = createAction<any>('[auth] LOGIN_SUCCESS');

export const createToken = createAction<string>('auth] CREATE_TOKEN');
export const storeToken = createAction<any>('[auth] STORE_TOKEN');

export const getUser = createAction<string>('[auth] GET_USER');
export const storeUser = createAction<User>('[auth] STORE_USER');

export const loginIdentityServer = createAction<any>(
  '[auth] LOGIN_IDENTITY_SERVER',
);
export const loginIdentityServerSuccess = createAction<any>(
  '[auth] LOGIN_IDENTITY_SERVER_SUCCESS',
);

export const storeIdentityServerToken = createAction<any>(
  '[auth] STORE_IDENTITY_SERVER_TOKEN',
);

export const getUserAccess = createAction('[auth] GET_USER_ACCESS');
export const storeUserAccess = createAction<UserAccess & { nextClientNo: number | null }>(
  '[auth] STORE_USER_ACCESS',
);

export const signinRedirectCallback = createAction<any>(
  '[auth] SIGNIN_REDIRECT_CALLBACK',
);

export const changeClient = createAction<string>('[auth] CHANGE_CLIENT');

export const storeAvailableClients = createAction<ClientAccess[]>('[auth] STORE_CLIENTS');

export const putUserDefaultClient = createAction<UserDefaultClient>('[auth] UPDATE_DEFAULT_CLIENT');

export const storeUserDefaultClient = createAction<UserDefaultClient>('[auth] STORE_DEFAULT_CLIENT');
