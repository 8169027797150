import { SelectGrp } from 'core/components/form-controls';
import Icon from 'core/components/shared/Icon';
import { Client, Dropdown } from 'core/models';
import { EmployeeWageDetailDownloadReportRequest } from 'core/models/EarningsReports.model';
import { CommonService } from 'core/services';
import { clearEmployeeWageDetailDownloadReport, downloadEmployeeWageDetailDownloadReport } from 'core/store/actions/earnings-reports.action';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';


import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const EmployeeWageDetailDownload = () => {

  const dispatch = useAppDispatch();
  const reportName = 'EmployeeWageDetailDownloadReport';
  const [years, setYears] = useState<Dropdown[]>();
  const { register, handleSubmit } = useForm();
  const report = useAppSelector((state) => { return state.earningsReports.employeeWageDetailDownloadReport; });
  const client = useAppSelector((state) => { return state.client.client; }) as Client;

  useEffect(() => {
    if (report) {
      report && CommonService.downloadBase64File(
        'application/excel',
        report,
        `${reportName}.xlsx`,
      );
      dispatch(clearEmployeeWageDetailDownloadReport());
    }
  }, [report]);

  useEffect(() => {

    setYears(generateArrayOfYears());

  }, []);

  const generateArrayOfYears = () => {
    const max = new Date().getFullYear();
    const min = max - 10;
    const years = [];

    for (let i = max; i >= min; i--) {
      years.push({
        id: i,
        description: i,
      });
    }
    return years as unknown as Dropdown[];
  };

  const downloadReport = (data: EmployeeWageDetailDownloadReportRequest) => {
    const submitData: EmployeeWageDetailDownloadReportRequest = {
      clientNo: client.clientNo,
      selectedYear: +data.selectedYear,
    };
    dispatch(downloadEmployeeWageDetailDownloadReport(submitData));
  };




  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">
            Employee Wage Detail Download Report
          </div>
          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title "
            >
              Download Report  <Icon name="download"
                className="fa-download" />
            </button>
          </div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div>

              {
                years?.length ?
                  <SelectGrp
                    ref={register}
                    name="selectedYear"
                    label="Annual Year"
                    options={years}

                  /> : null
              }



            </div>


          </div>
        </form>
      </div>


    </div>
  );

};

export default EmployeeWageDetailDownload;