import React, { ReactElement, FC, Children, isValidElement, cloneElement } from 'react';
import styles from './_styles.module.scss';
import { CellProps } from './types';
import Icon from '../Icon';

type ChildType = boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
type Props = {
  id: string | number;
  isSelected?: boolean;
  styleClasses?: (keyof typeof styles)[];
  showDelete?: boolean;
  deleteRow?: (id: string | number) => void;
  onClick?: () => void;
  onDoubleClick?: () => void;
  children: ReactElement<CellProps>[];
};

/*
  How this works:
  - Children.map takes two arguments: children, which are the child elements passed to the component; and a callback
    function that maps the current child.
  - If it's a valid React element (in this case specifically a Cell), return a "clone" of it (preserves identity and
    type but adds props). Otherwise, just return null.
 */
const TableRow: FC<Props> = ({
  id,
  isSelected,
  styleClasses,
  showDelete,
  deleteRow,
  onClick,
  onDoubleClick,
  children,
}) => {
  
  return (
    <div
      className={
        `${onClick ? styles.tr : styles.suppressed} ${isSelected ? styles['tr-selected'] : ''} ${styleClasses?.map((x) => styles[String(x)])}`
      }
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      {Children.map(children, (child: ChildType, index: number) => {
        if (isValidElement(child)) {
          return cloneElement(child, { key: index });
        }
        console.error('Not a valid element.', child);
        return null;
      })}
      {showDelete && (
        <div className={`${styles.td} justify-content-center`}>
          <button
            className={`btn btn-link p-0 ${styles['row-delete-btn']}`}
            onClick={() => { deleteRow?.(id); }}
          >
            Delete&nbsp;<Icon
              name="circle-minus"
              className="fa-minus-circle"
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default TableRow;