import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ContactInfo, HttpResponse } from '../../models';
import { ContactService } from '../../services';
import {
  handleError,
  loadContactInfo,
  storeContactInfo,
  updateContactInfo,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadContactInfo$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadContactInfo.type),
    switchMap((action: { payload: number; }) => {
      return from(ContactService.getContactInfo(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: ContactInfo) => { return storeContactInfo(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateContactInfo$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateContactInfo.type),
    switchMap((action: { payload: ContactInfo; }) => {
      return from(ContactService.putContactInfo(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<ContactInfo>) => { return storeContactInfo(res.value); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [loadContactInfo$, updateContactInfo$];
