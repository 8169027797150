import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getClient, getDepartments, getEmpSearch } from '../../core/store/selectors';
import Modal from 'core/components/modals/Modal';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { handleError, loadUserAccess, resetStore, storeSelectedEmployee } from 'core/store/actions';
import PageSpinner from 'core/components/shared/PageSpinner';
import { convToDateString } from 'utilities/utilities';
import { Employee } from 'core/models';

type PropTypes = {
  show: boolean;
  onHide: () => void;
};

const AppSearchListingModal: React.FC<PropTypes> = ({ show, onHide }) => {
  const client = useSelector(getClient);
  const employees = useSelector(getEmpSearch);
  const deptOpts = useSelector(getDepartments);
  const { empSearchLoading } = useAppSelector(({ app }) => app);
  
  const history = useHistory();
  
  const dispatch = useAppDispatch();

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };

  const onEmployeeSelected = (selectedEmp: Employee) => {
    // PI-8410: this means we're switching clients, so we want to store this employee as the current employee.
    if (!client) return dispatch(handleError('Could not load client data'));
    if (String(selectedEmp.clientNo) !== String(client.clientNo)) {
      if (!confirm(`Selecting this employee will load client ${selectedEmp.clientNo}. Continue?`)) return;
      history.push('/');
      dispatch(resetStore());
      // most of our service functions use getProtectedClientNo from CommonService. 
      // We need to pass the clientNo here so we can set it in state before it's used for loading the next client.
      dispatch(loadUserAccess({ nextEmpNo: selectedEmp.empNo, nextClientNo: selectedEmp.clientNo }));
    } else {
      dispatch(storeSelectedEmployee(selectedEmp));
      history.push(`/employee/detail/${selectedEmp.protectedEmpNo}/snapshot`);
    }
    
    closeModal();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      title="Employee Search Results"
    >
      {empSearchLoading ? (
        <PageSpinner
          loadingMessage="Loading employees..."
          overrideStyles={{ spinnerWrapper: { height: '50vh' } }}
        />
      ) : (
        <div className="row">
          <div className="dm-table-container">
            <table className="base-table">
              <thead>
                <tr>
                  <th>Client</th>
                  <th>Emp #</th>
                  <th>SSN</th>
                  <th>First Name</th>
                  <th>MI</th>
                  <th>Last Name</th>
                  <th>Loc</th>
                  <th>Dept</th>
                  <th>Hire Date</th>
                  <th>Term Date</th>
                  <th>Super</th>
                  <th>Onboarding Status</th>
                </tr>
              </thead>
              <tbody>
                {employees.map((x) => {
                  return (
                    <tr
                      key={`${x.clientNo}-${x.empNo}`}
                      onClick={() => { onEmployeeSelected(x); }}
                    >
                      <td>{x.clientNo}</td>
                      <td>{x.empNo}</td>
                      <td>{x.ssn}</td>
                      <td>{x.firstName}</td>
                      <td>{x.midName}</td>
                      <td>{x.lastName}</td>
                      <td>{x.loc}</td>
                      <td>{x.dept}</td>
                      <td>{convToDateString(x.hireDate)}</td>
                      <td>{convToDateString(x.termDate)}</td>
                      <td>{x.isSupervisor}</td>
                      <td>{x.onboardingStatus}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Modal>
  );
};
export default AppSearchListingModal;
