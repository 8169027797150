import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { downloadBambooHRReport, getBambooHRSettings } from 'core/store/slices/human-capital-reports/bambooHr.slice';
import { BambooHRComparison, BambooHRRequest, GetBambooHRRequest } from 'core/models/HumanCapitalReports.model';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import {
  ColDef,
  ExcelExportModule,
  FirstDataRenderedEvent,
  GridApi, GridOptions,
  ICellRendererParams, IHeaderParams,
  ValueFormatterParams,
} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from '@ag-grid-community/react';
import { Client } from 'core/models';
import Collapsible from 'react-collapsible';
import { CheckboxGrpInLine } from 'core/components/form-controls';
import { useForm } from 'react-hook-form';
import Icon from 'core/components/shared/Icon';
import CheckboxRenderer from 'utilities/ag-grid-renderers/CheckboxRenderer';
import AgSelectAllBambooHeader from 'utilities/ag-grid-renderers/agSelectAllBambooHeader';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { getReportOptions } from 'core/store/selectors';

const BambooHRReport = () => {
  const dispatch = useAppDispatch();
  const reportName = 'Bamboo HR Demographic Audit Report';
  const settings = useAppSelector((state) => { return state?.bambooHRReport.bambooHrSettings; });
  const client = useAppSelector((state) => { return state?.client.client; });
  const clientsStore = useAppSelector((state) => { return state?.auth.availableClients; });
  const bambooClientOptions = useSelector(getReportOptions('BambooReport'));
  
  const [clientGridApi, setClientGridApi] = useState<GridApi | null>(null);
  const [bambooGridApi, setBambooGridApi] = useState<GridApi | null>(null);
  const [selectedClients, setSelectedClients] = useState<Client[]>([]);
  const [originalData, setOriginalData] = useState<BambooHRComparison[]>([]);
  
  const [filter, setFilter] = useState<{ [key: string]: boolean }>({
    CannotSync: true,
    InSync: true,
    PartialMatch: true,
    CanPull: true,
    CanPush: true,
  });

  const {
    register,
    handleSubmit,
    reset,
  } =
    useForm({
      defaultValues: {
        duplicatesOnly: false,
        showTerminated: false,
      },
    });

  useEffect(() => {
    if (!(bambooClientOptions?.showReport && settings?.bambooHrEmployeeComparison.length)) {
      return;
    }

    setOriginalData(settings?.bambooHrEmployeeComparison);
  }, [settings]);

  const clients = useMemo(() => {
    return cloneDeep(clientsStore).filter(c => {
      return c.federalID === client?.federalID.toString();
    });
  }, [clientsStore]);

  const onGetBambooHRSettings = (data: GetBambooHRRequest) => {
    const clientNos = selectedClients?.map(c => { return c.clientNo; }) as number[];
    const request: GetBambooHRRequest = {
      showTerminated: data.showTerminated,
      duplicatesOnly: data.duplicatesOnly,
      clientNos: clientNos,
    };

    dispatch(getBambooHRSettings(request));
  };

  const getAction = (row: BambooHRComparison) => {
    if (row.canPush === true && row.canPull === false) return 'PushToBamboo';
    if (row.canPush === false && row.canPull === true) return 'PullFromBamboo';
    if (row.canPush === false && row.canPull === false) return 'NoAction';
    if (row.canPush === true && row.canPull === false) return 'PushToBamboo';
    return 'PushToBamboo';
  };

  const parseData = useMemo(() => {
    const filters = Object.entries(filter).map(e => { return e[1] === true && e[0]; });

    if (filters.length) {
      return cloneDeep(originalData).filter(o => { return filters.includes(o.demographicState); });
    }

    return [];
  }, [filter, originalData]);

  const processDownload = () => {
    const data = parseData.map((d: BambooHRComparison) => {
      return {
        ...d,
        action: getAction(d),
      };
    });
    const request: BambooHRRequest = {
      bambooHrEmployeeComparison: data,
    };

    dispatch(downloadBambooHRReport(request));
  };

  const onClientGridReady = (params: any) => {
    setClientGridApi(params.api);
  };

  const onClientSelectionChanged = () => {
    if (clientGridApi) {
      const selectedRows = clientGridApi.getSelectedRows();
      selectedRows && setSelectedClients(selectedRows);
    }
  };

  const onBambooGridReady = (params: any) => {
    setBambooGridApi(params.api);
  };

  const onCancel = () => {
    reset();
  };

  const columnsForClientGrid: ColDef[] = [{
    field: 'Select',
    headerName: 'Select',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    width: 100,
  },
  {
    field: 'clientNo',
    headerName: 'Client No',
    sortable: true,
    editable: false,
    width: 120,
  },
  {
    field: 'clientName',
    headerName: 'Description',
    sortable: true,
    suppressMenu: false,
    editable: false,
    width: 160,
  },
  {
    field: 'termDate',
    headerName: 'Term Date',
    sortable: true,
    suppressMenu: false,
    editable: false,
    width: 110,
  }];

  const gridOptionsClient: GridOptions = {
    columnDefs: columnsForClientGrid,
    rowMultiSelectWithClick: true,
    suppressCellFocus: true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const buildColumnDefs = (): any[] => {
    return [
      {
        headerName: 'Bamboo HR',
        children: [
          {
            field: 'demographicState',
            headerName: 'Status',
            sortable: true,
            editable: false,
            width: 150,
          },
          {
            field: 'bambooSsn',
            headerName: 'SSN',
            sortable: true,
            suppressMenu: false,
            editable: false,
            width: 130,
          },
          {
            field: 'bambooEmpNo',
            headerName: 'Emp No',
            sortable: true,
            suppressMenu: false,
            editable: false,
            width: 70,
          },
          {
            field: 'bambooFirstName',
            headerName: 'First Name',
            sortable: true,
            suppressMenu: false,
            editable: false,
            width: 150,
          },
          {
            field: 'bambooLastName',
            headerName: 'Last Name',
            sortable: true,
            suppressMenu: false,
            editable: false,
            width: 150,
          },
          {
            field: 'space',
            headerName: '',
            editable: false,
            width: 90,
          },
        ],
      },
      {
        headerName: 'DM Payroll',
        children: [
          {
            field: 'payrollClientNo',
            headerName: 'Client',
            sortable: true,
            editable: false,
            width: 70,
          },
          {
            field: 'payrollSsn',
            headerName: 'SSN',
            sortable: true,
            suppressMenu: false,
            editable: false,
            width: 70,
          },
          {
            field: 'payrollEmpNo',
            headerName: 'Emp No',
            sortable: true,
            suppressMenu: false,
            editable: false,
            width: 70,
          },
          {
            field: 'payrollFirstName',
            headerName: 'First Name',
            sortable: true,
            suppressMenu: false,
            editable: false,
            width: 130,
          },
          {
            field: 'payrollLastName',
            headerName: 'Last Name',
            sortable: true,
            suppressMenu: false,
            editable: false,
            width: 130,
          },
        ],
      },
      {
        headerName: '',
        headerClass: 'ag-header-group-cell-with-group-transparent',
        children: [
          {
            field: 'canPull',
            headerName: 'Pull',
            width: 85,
            valueFormatter: (params: ValueFormatterParams) => {
              return params.value === 'true';
            },
            cellRenderer: (params: ICellRendererParams) => {
              if (!originalData.length) return null;
              const row = originalData?.find(r => { return r.bambooId === params.data.bambooId; });
              return new CheckboxRenderer(params, !row?.canPull);
            },
            headerComponent: (params: IHeaderParams) => {
              return AgSelectAllBambooHeader(params, originalData);
            },
          },
          {
            field: 'canPush',
            headerName: 'Push',
            width: 85,
            valueFormatter: (params: ValueFormatterParams) => {
              return params.value === 'true';
            },
            cellRenderer: (params: ICellRendererParams) => {
              if (!originalData.length) return null;
              const row = originalData?.find(r => { return r.bambooId === params.data.bambooId; });
              return new CheckboxRenderer(params, !row?.canPush);
            },
            headerComponent: (params: IHeaderParams) => {
              return AgSelectAllBambooHeader(params, originalData);
            },
          },
          {
            field: 'note',
            headerName: 'Note',
            cellEditorPopup: true,
            editable: true,
            width: 120,
            cellEditor: 'agLargeTextCellEditor',
            cellRenderer: () => {
              return <button
                type="button"
                className="btn btn-link p-0"
              >Expand</button>;
            },
          },
        ],
      },
    ];
  };

  const gridOptionsBamboo: GridOptions = {
    suppressCellFocus: true,
    stopEditingWhenCellsLoseFocus: true,
    rowSelection: 'multiple',
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const onFirstDataRendered = useCallback(
    (params: FirstDataRenderedEvent) => {
      params.api.forEachNode((node) => {
        return node.setSelected(!!node.data && (node.data.canPull || node.data.canPush));
      });
    },
    [],
  );

  const onExportGrid = () => {
    bambooGridApi?.exportDataAsExcel();
  };

  useEffect(() => {
    if (!bambooGridApi || !originalData.length) {
      return;
    }

    bambooGridApi.setColumnDefs(buildColumnDefs());
  }, [bambooGridApi, originalData]);

  return (
    <form onSubmit={handleSubmit(onGetBambooHRSettings)}>
      <div className="dm-panel dm-panel-border">
        <div className="dm-grid-title">{reportName}</div>
        <hr className="dm-panel-hr" />
        <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex flex-row">
            <div className="dm-panel dm-panel-border mr-3 d-flex flex-row ">
              <div className="mr-3 flex-grow-1">
                <Collapsible trigger="1. Select Clients to Audit">
                  <div
                    className="ag-theme-balham mb-4"
                    style={{
                      width: '500px',
                      height: '300px',
                    }}
                  >
                    <AgGridReact
                      rowData={clients}
                      onGridReady={onClientGridReady}
                      gridOptions={gridOptionsClient}
                      onSelectionChanged={onClientSelectionChanged}
                      rowSelection={'multiple'}
                    />
                  </div>
                </Collapsible>
              </div>
              <div className="flex-grow-1">
                <div className="d-flex flex-column">
                  <CheckboxGrpInLine
                    name="showTerminated"
                    labelFirst={false}
                    label="Show Terminated Employees"
                    ref={register()}
                  />
                  <CheckboxGrpInLine
                    name="duplicatesOnly"
                    labelFirst={false}
                    label="Show Employees with same SSN"
                    ref={register()}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary orange-outline-button"
                    disabled={!(bambooClientOptions?.showReport && selectedClients?.length)}
                  >
                    Load and Compare
                  </button>
                </div>
              </div>
            </div>
            <div className="dm-panel dm-panel-border flex-grow-1">
              <p>{settings?.bambooEmpsNotInDM}</p>
              <p>{settings?.dmEmpsNotInBamboo}</p>
              <p>{settings?.bambooMatchDM}</p>
              <p>{settings?.bambooMatchDMSync}</p>
              <p>{settings?.cannotSyncEmps}</p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row mb-3">
          <div className="w-100">
            <div className="w-50">
              <Collapsible trigger="2. Select Status filters for employees">
                <CheckboxGrpInLine
                  ref={register}
                  name="CannotSync"
                  labelFirst={false}
                  label="[MNS] Employees Match, NOT SET UP to synchronize with Bamboo HR"
                  defaultChecked
                  labelWidth={300}
                  labelWidthUnit="px"
                  onChange={(e: any) => {
                    return setFilter((prev) => {
                      return {
                        ...prev,
                        CannotSync: e.target.checked,
                      };
                    });
                  }}
                />
                <CheckboxGrpInLine
                  ref={register}
                  name="InSync"
                  labelFirst={false}
                  label="[MR] Employees Match, Ready to synchronize with Bamboo HR"
                  defaultChecked
                  labelWidth={300}
                  labelWidthUnit="px"
                  onChange={(e: any) => {
                    return setFilter((prev) => {
                      return {
                        ...prev,
                        InSync: e.target.checked,
                      };
                    });
                  }}
                />
                <CheckboxGrpInLine
                  ref={register}
                  name="PartialMatch"
                  labelFirst={false}
                  label="[CSE] Cannot Synchronize Employee. There are employees with a Partial Match"
                  defaultChecked
                  labelWidth={300}
                  labelWidthUnit="px"
                  onChange={(e: any) => {
                    return setFilter((prev) => {
                      return {
                        ...prev,
                        PartialMatch: e.target.checked,
                      };
                    });
                  }}
                />
                <CheckboxGrpInLine
                  ref={register}
                  name="CanPush"
                  labelFirst={false}
                  label="[PUSH] Employees exist in Bamboo HR but not in DM Payroll. Ready to PUSH"
                  defaultChecked
                  labelWidth={300}
                  labelWidthUnit="px"
                  onChange={(e: any) => {
                    return setFilter((prev) => {
                      return {
                        ...prev,
                        CanPush: e.target.checked,
                      };
                    });
                  }}
                />
                <CheckboxGrpInLine
                  ref={register}
                  name="CanPull"
                  labelFirst={false}
                  label="[PULL] Employees exist in Bamboo HR but not in DM Payroll. Ready to PULL"
                  defaultChecked
                  labelWidth={300}
                  labelWidthUnit="px"
                  onChange={(e: any) => {
                    return setFilter((prev) => {
                      return {
                        ...prev,
                        CanPull: e.target.checked,
                      };
                    });
                  }}
                />
              </Collapsible>
            </div>
          </div>
          <div className="w-50">
            <button
              type="button"
              className="btn btn-link dm-grid-action-title py-0"
              onClick={() => { return onExportGrid(); }}
              disabled={!bambooClientOptions?.showReport}
            >
              Export Grid to Excel{' '}
              <Icon
                name="download"
                style={{
                  height: '15px',
                }}
              />
            </button>
          </div>
        </div>
        <div className="d-flex mb-3">
          {parseData && (
            <div
              className="ag-theme-balham mb-4 mr-3 flex-grow-1"
              style={{
                height: '300px',
              }}
            >
              <AgGridReact
                modules={[ExcelExportModule]}
                rowData={parseData}
                gridOptions={gridOptionsBamboo}
                onGridReady={onBambooGridReady}
                onFirstDataRendered={onFirstDataRendered}
              />
            </div>
          )}
        </div>
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-primary orange-outline-button ml-auto mr-2"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="orange-button mr-2"
            onClick={processDownload}
            disabled={!(bambooClientOptions?.showReport && parseData?.length)}
          >
            Sync Selected Employees
          </button>
        </div>
      </div>
    </form>
  );

};

export default BambooHRReport;