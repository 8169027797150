import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { SalesTaxTransmittal, SalesTaxTransmittalEntity } from '../../models/SalesTaxTransmittal.model';
import { addSalesTaxTransmittal, clearSalesTaxTransmittalReport, loadSalesTaxTransmittalByEntity, loadSalesTaxTransmittalByYear, setShowActivationMessage, storeSalesTaxTransmittal, storeSalesTaxTransmittalEntity, storeSalesTaxTransmittalReport, toggleLoadingState } from '../actions';
export interface State {
  loading: boolean;
  inserting: boolean;
  salesTaxTransmittal: SalesTaxTransmittal[];
  salesTaxTransmittalEntity: SalesTaxTransmittalEntity[];
  salesTaxTransmittalReport: any;
  showActivationMessage: boolean;
}

export const INITIAL_STATE: State = {
  loading: false,
  inserting: false,
  salesTaxTransmittal: [],
  salesTaxTransmittalEntity: [],
  salesTaxTransmittalReport: null,
  showActivationMessage: false,
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadSalesTaxTransmittalByYear, (state) => {
      state.loading = true;
    })
    .addCase(loadSalesTaxTransmittalByEntity, (state) => {
      state.loading = true;
    })
    .addCase(storeSalesTaxTransmittal, (state, action) => {
      state.loading = false;
      state.salesTaxTransmittal = action.payload;
    })
    .addCase(storeSalesTaxTransmittalEntity, (state, action) => {
      state.loading = false;
      state.salesTaxTransmittalEntity = action.payload;
    })
    .addCase(storeSalesTaxTransmittalReport, (state, action) => {
      state.loading = false;
      state.salesTaxTransmittalReport = action.payload;
    })
    .addCase(clearSalesTaxTransmittalReport, (state) => {
      state.salesTaxTransmittalReport = null;
    })
    .addCase(setShowActivationMessage, (state, action) => {
      state.showActivationMessage = action.payload;
    })
    .addCase(addSalesTaxTransmittal.pending, (state) => {
      state.inserting = true;
    })
    .addCase(toggleLoadingState, (state, action) => {
      state.loading = action.payload;
    })    
    .addMatcher(isAnyOf(addSalesTaxTransmittal.fulfilled, addSalesTaxTransmittal.rejected), (state) => {
      state.inserting = false;
    })
  ;
});