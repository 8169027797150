import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi, GridOptions, ICellRendererParams, RowNode, ValueSetterParams } from '@ag-grid-enterprise/all-modules';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { Client } from 'core/models';
import { CopyMapRequest, ScheduledDownloadRequest, scheduleEmpTypeOpts, scheduleFrequencyOpts, UserMap, UserMapItem } from 'core/models/Downloader.model';
import { deleteUserMap, getUserMap, putCopyMap, putScheduleDownload, storeSelectedClients } from 'core/store/slices/downloader.slice';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { agSelectEditor } from 'utilities/ag-grid-editors';
import { agLookupRenderer } from 'utilities/ag-grid-renderers';
import AGDeleteButtonRendererComponent from 'utilities/ag-grid-renderers/AGDeleteButtonRendererComponent';
import AGEditButtonRendererComponent from 'utilities/ag-grid-renderers/AGEditButtonRendererComponent';
import CheckboxRenderer from 'utilities/ag-grid-renderers/CheckboxRenderer';
import { useAppSelector } from 'utilities/hooks';
import AddEmailModal from './modals/AddEmail.modal';
import CopyMapUsersModal from './modals/CopyMapUsers.modal';
import FormatExcelModal from './FormatExcel/FormatExcel.modal';
import Icon from 'core/components/shared/Icon';
import { IconTypes } from 'enums/IconTypes';
import { useHistory } from 'react-router-dom';
import ExportModal from './UserMap/ExportModal';
import { Button } from 'react-bootstrap';
import { ClientAccess } from 'core/models/ClientAccess';
import DownloadToLibraryModal from './UserMap/DownloadToLibraryModal';


type Props = {
  downloaderType: string,
  tab: string,
  userMaps: UserMap,
  isHrDownload: boolean,
  isEmpEarnDownload: boolean,
};

const DownloaderMaps = ({ downloaderType, tab, userMaps, isHrDownload, isEmpEarnDownload }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const clients = useAppSelector((state) => { return state?.auth.availableClients; });
  const client = useAppSelector((state) => { return state.client.client; });
  const selectedUserMapStore = useAppSelector((state) => { return state.downloader.selectedUserMap; });
  const [selectedUserMapItem, setSelectedUserMapItem] = useState<UserMapItem | undefined>(undefined);
  const [rowData, setRowData] = useState<UserMapItem[]>();
  const [clientRowData, setClientRowData] = useState<ClientAccess[]>();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [smallGridApi, setSmallGridApi] = useState<GridApi | null>(null);
  const isClient = useAppSelector((state) => { return !state.auth?.userAccess?.superUser; });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showEmailConfirm, setShowEmailConfirm] = useState(false);
  const [showAddEmail, setShowAddEmail] = useState(false);
  const [showFormatExcel, setShowFormatExcel] = useState(false);
  const [showCopyUserMap, setShowCopyUserMap] = useState(false);
  const [selectedClients, setSelectedClients] = useState<Client[]>();
  const [deleteId, setDeleteId] = useState<number>();
  const [userMapIdRoute, setUserMapIdRoute] = useState<string>();
  const [selectedUserMap, setSelectedUserMap] = useState<UserMapItem | null>(null); 
  const [isExportShown, setIsExportShown] = useState<boolean>(false);
  const [showMultiClientModal, setShowMultiClientModal] = useState<boolean>(false);
  const [writeToSelectedClientLibraries, setWriteToSelectedClientLibraries] = useState<Client[]>([]);
  const [downloadToClientLibrary, setDownloadToClientLibrary] = useState<boolean>(false);
  
  useEffect(() => {
    if (!clients?.length) return;
    const currentClientInClients = clients.find((authClient) => authClient.clientNo === +(client?.clientNo ?? -1));
    if (!currentClientInClients) {
      setClientRowData(clients);
      return;
    }
    setClientRowData([
      currentClientInClients,
      ...(clients?.filter((authClient) => authClient.clientNo !== +(client?.clientNo ?? -1)) ?? []),
    
    ]);
  }, [clients, client]);

  function FilterMaps(maps: UserMapItem[] | null) {
    if (isHrDownload)
      return maps?.filter(x => x.isHrDownload);
    else if (isEmpEarnDownload)
      return maps?.filter(x => !x.isHrDownload && x.isEarningsDownload);
    else
      return maps?.filter(x => !x.isHrDownload && !x.isEarningsDownload);
  }

  useEffect(() => {
    let maps = FilterMaps(userMaps?.myDownloadMaps);
    if (tab === 'DMPS') {
      maps = FilterMaps(userMaps?.dmpsDownloadMaps);
    } else if (tab === 'CCM') {
      maps = FilterMaps(userMaps?.currentClientsUserMaps);
    }
    maps && setRowData(maps.map((m) => {
      return {
        ...m,
        'scheduleFrequency': m?.downloadSchedule?.scheduleFrequency ?? 'NotScheduled',
        'scheduleEmpType': m?.downloadSchedule?.filter ??'All',
      };
    }));
  }, [userMaps, isHrDownload, isEmpEarnDownload]);
  
  useEffect(() => {
    selectCurrentClient();
  }, [smallGridApi, clientRowData]);

  useEffect(() => {
    selectedUserMapStore && setSelectedUserMapItem(selectedUserMapStore);
  }, [selectedUserMapStore]);

  const onEditMap = () => {
    userMapIdRoute && history.push(userMapIdRoute);
  };

  const onAddEmail = () => {
    setShowEmailConfirm(true);
  };

  const onEditFormat = () => {
    setShowFormatExcel(true);
  };

  const onPublish = (data: any) => {
    const request: CopyMapRequest = {
      userMapId: data?.userMapId,
      clientNo: client?.clientNo ?? 0,
      userNames: ['DMPS'],
    };
    request && dispatch(putCopyMap(request));
  };

  const onCopy = () => {
    setShowCopyUserMap(true);
  };

  const onCopyMDM = (data: any) => {
    const request: CopyMapRequest = {
      userMapId: data?.userMapId,
      clientNo: client?.clientNo ?? 0,
    };
    request && dispatch(putCopyMap(request));
  };

  const onScheduleDownload = (data: any, updateEmpType: boolean) => {
    if(updateEmpType && data.scheduleFrequency === 'NotScheduled') return;
    const request: ScheduledDownloadRequest = {
      clientNo: client?.clientNo ?? 0,
      userMapId: data?.userMapId,
      scheduleFrequency: data.scheduleFrequency,
      filter: data.scheduleEmpType ?? 'All',
      selectedClients: selectedClients?.map(c => { return c.clientNo; }) ?? [],
    };
    dispatch(putScheduleDownload(request));
  };

  const selectionChanged = () => {
    const map = gridApi?.getSelectedRows()[0] as UserMapItem;
    
    //If they unselect and the map is undefined just set the values to default and make sure we dont send the GET with Id of undefined.
    if(!map)
    {
      setSelectedUserMap(null);
      return setUserMapIdRoute(undefined);
    }

    setSelectedUserMap(map);
    setUserMapIdRoute(`/user-map/${downloaderType}/${map?.userMapId}`);
    dispatch(getUserMap(map?.userMapId));
  };

  const onClientSelectionChanged = () => {
    if (!smallGridApi) return;
    
    const selectedRows = smallGridApi.getSelectedRows();
    if (!selectedRows) return;
    
    dispatch(storeSelectedClients(selectedRows));
    setSelectedClients(selectedRows);
  };
  
  const selectCurrentClient = () => {
    if (!smallGridApi) return;
    // should just be the first index
    const clientIndex = clientRowData?.findIndex((innerClient) => +(innerClient?.clientNo ?? 0) === +(client?.clientNo ?? -1)) ?? -1;
    const currentClientRowNode: RowNode | undefined = smallGridApi.getDisplayedRowAtIndex(clientIndex);
    if (!currentClientRowNode) return;
    
    currentClientRowNode.setSelected(true);
  };

  const onDelete = (e: any) => {
    setDeleteId(e.userMapId);
    setShowConfirmationModal(true);
  };

  const deleteConfirm = (confirmed: boolean) => {
    if (confirmed && deleteId) {
      gridOptions?.api?.forEachNode((node: RowNode) => {
        const item = node.data as UserMapItem;
        if (item.userMapId === deleteId) {
          setRowData((prev) => {
            return prev?.filter(
              (x) => {
                return x.userMapId !==
                  item.userMapId;
              },
            );
          },
          );
        }
      });
      dispatch(deleteUserMap(deleteId));
    }
  };

  const onEmailConfirm = (confirmed: boolean) => {
    setShowEmailConfirm(false);
    if (confirmed) {
      setShowAddEmail(confirmed);
    }
  };

  const columns: ColDef[] =
    [
      {
        field: 'select',
        checkboxSelection: true,
        width: 65,
      },
      {
        field: 'userName',
        headerName: 'User Name',
        width: 120,
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 140,
      },
      {
        field: 'scheduleFrequency',
        headerName: 'Schedule Download',
        width: 150,
        editable: true,
        cellEditorParams: {
          options: scheduleFrequencyOpts,
          valueField: 'id',
          labelField: 'description',
        },
        cellEditor: 'selectEditor',
        cellRendererParams: {
          options: scheduleFrequencyOpts,
          valueField: 'id',
          labelField: 'description',
        },
        cellRenderer: 'lookupRenderer',
        valueSetter: (params: ValueSetterParams) => {
          if (params.newValue === '') {
            params.data.scheduleFrequency = null;
            return false;
          } else if (params.data) {
            params.data.scheduleFrequency = params.newValue.toString();
            const data = {
              ...params.data,
              scheduleFrequency: params.newValue,
            };
            onScheduleDownload(data, false);
          }
          return false;
        },
        hide: tab !== 'MDM',
      },
      {
        field: 'scheduleEmpType',
        headerName: 'Schedule Emp Type',
        width: 150,
        editable: true,
        cellEditorParams: {
          options: scheduleEmpTypeOpts,
          valueField: 'id',
          labelField: 'description',
        },
        cellEditor: 'selectEditor',
        cellRendererParams: {
          options: scheduleEmpTypeOpts,
          valueField: 'id',
          labelField: 'description',
        },
        cellRenderer: 'lookupRenderer',
        valueSetter: (params: ValueSetterParams) => {
          if (params.newValue === '') {
            params.data.scheduleEmpType = null;
            return false;
          } else if (params.data) {
            params.data.scheduleEmpType = params.newValue.toString();
            const data = {
              ...params.data,
              scheduleEmpType: params.newValue,
            };
            onScheduleDownload(data, true);
          }
          return false;
        },
        hide: tab !== 'MDM',
      },
      {
        field: 'edit',
        headerName: 'Edit Map',
        cellRendererParams: {
          label: 'Edit',
          clickHandler: onEditMap,
          title:'Double Click to Edit Map',
        },
        cellRenderer: 'editButtonRendererComponent',
        cellClass: 'ag-cell-border',
        width: 70,
        hide: tab !== 'MDM' && isClient,
      },
      {
        field: 'addEmail',
        headerName: 'Emails for Export',
        cellRenderer: (_params: ICellRendererParams) => {
          return (
            <>
              <button
                type="button"
                className="btn btn-link btn-block p-0"
                onClick={() => { return onAddEmail(); }
                }
              >
                Add<Icon
                  name="envelope"
                  className="ml-2"
                  type={IconTypes.THIN}
                  style={{
                    padding: '0',
                    height: '15px',
                  }}
                />
              </button>
            </>
          );
        },
        cellClass: 'ag-cell-border',
        width: 90,
        hide: tab !== 'MDM' && isClient,
      },
      {
        field: 'formatEdit',
        headerName: 'Open Format Editor',
        cellRenderer: (_params: ICellRendererParams) => {
          return (
            <>
              <button
                type="button"               
                className="btn btn-link btn-block p-0"
                onClick={() => { return onEditFormat(); }
                }
              >
                Edit Format<Icon
                  name="message-pen"
                  className="ml-2"
                  style={{
                    height: '15px',
                  }}
                />
              </button>
            </>
          );
        },
        cellClass: 'ag-cell-border',
        width: 120,
        hide: tab !== 'MDM' && isClient,
      },
      {
        field: 'publish',
        headerName: 'Publish to DMPS',
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <>
              <button
                type="button"
                className="btn btn-link btn-block p-0"
                onClick={() => { return onPublish(params.data); }
                }
              >
                Publish<Icon
                  name="upload"
                  className="ml-2"
                  style={{
                    height: '15px',
                  }}
                />
              </button>
            </>
          );
        },
        cellClass: 'ag-cell-border',
        width: 120,
        hide: tab !== 'MDM' || isClient,
      },
      {
        field: 'copy',
        headerName: 'Copy to User(s)',
        cellRenderer: (_params: ICellRendererParams) => {
          return (
            <>
              <button
                type="button"
                className="btn btn-link btn-block p-0"
                onClick={() => { return onCopy(); }
                }
              >
                <Icon
                  name="copy"
                  style={{
                    height: '15px',
                  }}
                />
              </button>
            </>
          );
        },
        cellClass: 'ag-cell-border',
        width: 90,
        hide: isClient,
      },
      {
        field: 'copy',
        headerName: 'Copy to MDM',
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <>
              <button
                type="button"
                className="btn btn-link btn-block p-0"
                onClick={() => { return onCopyMDM(params.data); }
                }
              >
                <Icon
                  name="copy"
                  style={{
                    height: '15px',
                  }}
                />
              </button>
            </>
          );
        },
        cellClass: 'ag-cell-border',
        width: 90,
        hide: tab === 'MDM',
      },
      {
        field: 'delete',
        headerName: 'Delete Map',
        editable: false,
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <>
              <button
                type="button"
                className="btn btn-link btn-block p-0"
                onClick={() => { return onDelete(params.data); }
                }
              >
                <Icon
                  name="minus-circle"
                  style={{
                    height: '15px',
                  }}
                />
              </button>
            </>
          );
        },
        width: 90,
        hide: isClient,
      },
    ];

  const gridOptions: GridOptions = {
    defaultColDef: {
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
      singleClickEdit: true,
      resizable: true,
      sortable: true,
    },
    rowSelection: 'single',
    components: {
      lookupRenderer: agLookupRenderer,
      selectEditor: agSelectEditor,
      agDeleteButtonRendererComponent: AGDeleteButtonRendererComponent,
      checkBoxRenderer: CheckboxRenderer,
      editButtonRendererComponent: AGEditButtonRendererComponent,
    },
  };

  const columnsForSmallGrid: ColDef[] = [{
    field: 'Select',
    headerName: 'Select',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    width: 130,
  },
  {
    field: 'clientNo',
    headerName: 'Client No',
    sortable: true,
    editable: false,
    width: 130,
  },
  {
    field: 'clientName',
    headerName: 'Description',
    sortable: true,
    suppressMenu: false,
    editable: false,
    width: 220,
  }];

  const gridOptionsSmall: GridOptions = {
    columnDefs: columnsForSmallGrid,
    rowMultiSelectWithClick: true,
    suppressCellFocus: true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSmallGridReady = (params: any) => {
    setSmallGridApi(params.api);
  };

  const onExportClient = () => {
    if((selectedClients?.length ?? 0) > 1)setShowMultiClientModal(true);
    else setIsExportShown(true); 
  }

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-3 mb-1">
        <div className="dm-card-title">Select Map to Download</div>
        <div className="d-flex">
          <ExportModal
            show={isExportShown}
            onHide={() => {setIsExportShown(false); setWriteToSelectedClientLibraries([]); setDownloadToClientLibrary(false)}}
            isEmpEarningDownload = {isEmpEarnDownload}
            isEarningsDownloadSummary= {selectedUserMapItem?.earningsMapType === 'EmployeeEarningsSummary'}
            selectedUserMap = {selectedUserMap}
            selectedClients = {selectedClients || []}
            writeToSelectedClientLibraries = {writeToSelectedClientLibraries}
            downloadToClientLibrary = {downloadToClientLibrary}
          />
          <DownloadToLibraryModal
            show={showMultiClientModal}
            onHide={() => {setShowMultiClientModal(false);}}
            clients={selectedClients || []}
            currentClient={client}
            setWriteToSelectedClientLibraries={setWriteToSelectedClientLibraries}
            setDownloadToClientLibrary={setDownloadToClientLibrary}
            setIsExportShown={setIsExportShown}
          />
          <Button
            type="button"
            className="btn-sm orange-button mt-2"
            onClick={onExportClient}
            disabled={!selectedUserMap}
          >
            Export
          </Button>
        </div>
      </div>
      <div
        className="ag-theme-balham mb-4"
        style={{ height: '400px' }}
      >
        <AgGridReact
          columnDefs={columns}
          gridOptions={gridOptions}
          rowData={rowData}
          onGridReady={onGridReady}
          onSelectionChanged={selectionChanged}
        />
      </div>
      <div
        className="ag-theme-balham mb-4"
        style={{
          width: '500px',
          height: '300px',
        }}
      >
        <AgGridReact
          rowData={clientRowData}
          gridOptions={gridOptionsSmall}
          columnDefs={columnsForSmallGrid}
          onGridReady={onSmallGridReady}
          onSelectionChanged={onClientSelectionChanged}
          rowSelection={'multiple'}
        />
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          title="Delete Map"
          message={'Are you sure you would like to delete this map?'}
          show={showConfirmationModal}
          onConfirmed={deleteConfirm}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
      {showEmailConfirm && (
        <ConfirmationModal
          title="Email For Export"
          message={`You are accepting all responsibility for correctly
                    selecting the appropriate individuals that are to
                    receive downloaded information from DM Payroll Solutions 
                    via email. \n \n If you accept, select "Yes" to continue`}
          show={showEmailConfirm}
          onConfirmed={onEmailConfirm}
          onHide={() => { return setShowEmailConfirm(false); }}
        />
      )}
      {showAddEmail && selectedUserMapItem && (
        <AddEmailModal
          userMapItem={selectedUserMapItem}
          show={showAddEmail}
          onHide={() => { return setShowAddEmail(false); }}
        />
      )
      }
      {showFormatExcel && selectedUserMapItem && (
        <FormatExcelModal
          show={showFormatExcel}
          onHide={() => { return setShowFormatExcel(false); }}
        />
      )
      }
      {showCopyUserMap && selectedUserMapItem && (
        <CopyMapUsersModal
          userMapItem={selectedUserMapItem}
          show={showCopyUserMap}
          onHide={() => { return setShowCopyUserMap(false); }}
        />
      )}
    </div>
  );

};

DownloaderMaps.displayName = 'DownloaderMaps';

export default DownloaderMaps;