import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getACADate, getFullTimeCalculationReport, getFullTimeCalculationStates, getMescAcaHours, postFullTimeCalculationReport, updateACADates } from 'core/store/actions/human-capital-management/aca-full-time-calculation.action';
import { IOption } from 'interfaces/IOption';

export const AcaFullTimeCalcSortOptions: IOption[] = [
  {
    label: 'Name Alpha',
    value: 'A',
  },
  {
    label: 'Client No.',
    value: 'C',
  },
  {
    label: 'Location',
    value: 'L',
  },
  {
    label: 'Department',
    value: 'D',
  },
  {
    label: 'Sub Dept',
    value: 'S',
  },
  {
    label: 'Sub Dept2',
    value: '2',
  },
];

export interface ACAFullTimeCalculationReportCount {
  fullTime?: number;
  partTime?: number;
  seasonal?: number;
  blank?: number;
  fullTimeEquivalent?: number;
}

export interface EmployeeStatusHistory {
  clientNo: number;
  effectiveDate?: string;
  empId: number;
  empNo: number;
  protectedClientNo: string;
  protectedEmpNo: string;
  status: string;
  isNew: boolean;
}

export interface EmployeeYearMonthHourTotals {
  empNo: number;
  label: string;
  month: number;
  protectedEmpNo: string;
  total: number;
  year: number;
  color: string;
}

export interface EmployeeYearTotals {
  label: string;
  amount: number
}

export interface IACAEmployee {
  acaStatus: string;
  acaStatusHistory: EmployeeStatusHistory[];
  acaYearMonthHourTotals: EmployeeYearMonthHourTotals[];
  yearTotals: EmployeeYearTotals[];
  clientNo: number;
  currentHireDate?: string;
  currentTermDate?: string;
  department: number;
  eligibleStart?: string;
  empNo: number;
  empId: number;
  firstName: string;
  lastName: string;
  location: string;
  previousHireDate?: string;
  previousTermDate?: string;
  protectedClientNo: string;
  protectedEmpNo: string;
  subDepartment: number;
  subDepartment2: number;
  rowIndex: number;
}

export interface IStates {
  acaComment: string;
  clientNo: number;
  formBorC: string;
  monthlyPremiums: any[];
  needInsuranceReferral: boolean;
  offerEmployeesInsurance: boolean;
  offerMinCoverage: boolean;
  offerSpouseInsurance: boolean;
  planStartMonth: number;
  protectedClientNo: string;
  selfInsured: boolean;
  showAcaQuestionnaire: boolean;
  waitingPeriodDays: number;
  waitingPeriodFollowingMonth: boolean;
  year: number;
}

export interface ACAFullTimeCalculationReportData {
  employees: IACAEmployee[];
  pdf: string | null;
  states: IStates | null;
  mescEmployee: any[];
  mescEmployeeUpdates?: null | any[];
}

export interface ACAFullTimeCalculationReportState {
  areReportsLoading: boolean;
  areDatesLoading: boolean;
  isGettingReportsPdf: boolean;
  isGettingDates: boolean;
  isUpdatingDates: boolean;
  areReportsAvailable: boolean;
  measureFrom?: string | null;
  measureTo?: string | null;
  adminFrom?: string | null;
  adminTo?: string | null;
  stabilityFrom?: string | null;
  stabilityTo?: string | null;
  acaDecisionEffectiveDate?: string | null;
  activateAcaEffectiveDate?: string | null;
  count?: ACAFullTimeCalculationReportCount;
  data: ACAFullTimeCalculationReportData;
  employeeIsEligible: boolean;
  waitingPeriodDays: number;
  eligibility: string | null;
  sortBy: string | null;
}

const initialState: ACAFullTimeCalculationReportState = {
  areReportsLoading: false,
  areDatesLoading: false,
  isGettingReportsPdf: false,
  isGettingDates: false,
  isUpdatingDates: false,
  areReportsAvailable: false,
  measureFrom: null,
  measureTo: null,
  adminFrom: null,
  adminTo: null,
  stabilityFrom: null,
  stabilityTo: null,
  acaDecisionEffectiveDate: null,
  activateAcaEffectiveDate: null,
  count: {
    fullTime: 0,
    partTime: 0,
    seasonal: 0,
    blank: 0,
    fullTimeEquivalent: 0,
  },
  data: {
    employees: [],
    states: null,
    mescEmployee: [],
    mescEmployeeUpdates: null,
    pdf: null,
  },
  employeeIsEligible: false,
  waitingPeriodDays: 0,
  eligibility: 'FullTimeEquivalent',
  sortBy: 'Name Alpha',
};

export const ACAFullTimeCalculationReportSlice = createSlice({
  name: 'aca-full-time-calculation-report',
  initialState,
  reducers: {
    setKey(state, action: PayloadAction<{ key: string; value: any }>) {
      // @ts-ignore
      state[action.payload.key] = action.payload.value;
    },
    setDataKey(state, action: PayloadAction<{ key: string; value: any }>) {
      // @ts-ignore
      state.data[action.payload.key] = action.payload.value;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getACADate.pending, (state) => {
        state.areDatesLoading = true;
      })
      .addCase(getACADate.fulfilled, (state, action) => {
        const response = action.payload;

        state.areDatesLoading = false;
        state.measureFrom = response.measureFrom;
        state.measureTo = response.measureTo;
        state.adminFrom = response.adminFrom;
        state.adminTo = response.adminTo;
        state.stabilityFrom = response.stabilityFrom;
        state.stabilityTo = response.stabilityTo;
        state.acaDecisionEffectiveDate = response.acaDecisionEffectiveDate;
        state.activateAcaEffectiveDate = response.activateAcaEffectiveDate;
      })
      .addCase(getACADate.rejected, (state) => {
        state.areDatesLoading = false;
      });

    builder
      .addCase(postFullTimeCalculationReport.pending, (state) => {
        state.isGettingReportsPdf = true;
      })
      .addCase(postFullTimeCalculationReport.fulfilled, (state, action) => {
        state.data.pdf = action.payload.value;
        state.isGettingReportsPdf = false;
      })
      .addCase(postFullTimeCalculationReport.rejected, (state) => {
        state.isGettingReportsPdf = false;
      });

    builder
      .addCase(updateACADates.pending, (state) => {
        state.isUpdatingDates = true;
      })
      .addCase(updateACADates.fulfilled, (state) => {
        state.isUpdatingDates = false;
      })
      .addCase(updateACADates.rejected, (state) => {
        state.isUpdatingDates = false;
      });

    builder
      .addCase(getFullTimeCalculationReport.pending, (state) => {
        state.areReportsLoading = true;
      })
      .addCase(getFullTimeCalculationReport.fulfilled, (state, action) => {
        const response = action.payload;

        state.areReportsLoading = false;
        if(!response) return;
        state.employeeIsEligible = response?.employeeIsEligible || false;
        state.waitingPeriodDays = response.waitingPeriodDays;
        state.count = {
          fullTime: response.empsFullTimeCount,
          partTime: response.empsPartTimeCount,
          seasonal: response.empsSeasonalCount,
          blank: response.empsBlankCount,
          fullTimeEquivalent: response.empsFullTimeEquivalentCount,
        };
        state.data = {
          ...state.data,
          employees: response.employees || [],
        };
      })
      .addCase(getFullTimeCalculationReport.rejected, (state) => {
        state.areReportsLoading = false;
      });

    builder
      .addCase(getFullTimeCalculationStates.fulfilled, (state, action) => {
        state.data.states = action.payload;
      });

    builder
      .addCase(getMescAcaHours.fulfilled, (state, action) => {
        state.data.mescEmployee = action.payload;
      });
  },
});

export const { setKey, setDataKey } = ACAFullTimeCalculationReportSlice.actions;

export default ACAFullTimeCalculationReportSlice.reducer;