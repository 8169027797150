import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RadioGrp } from 'core/components/form-controls';
import InputReportDateSelector from 'core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { Client, DtoOptions } from 'core/models';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';

import { WH347_Certified_Report_Request } from 'core/models/ContractorReports.model';
import {
  clearWH347CertifiedReport,
  downloadWH347CertifiedReport,
} from 'core/store/slices/contractorReports.slice';

const weekendingOrCheckDate: RadioOptions[] = [
  {
    value: 'ByWeekEnding',
    label: 'By Week Ending',
  },
  {
    value: 'ByCheckDate',
    label: 'By Check Date',
  },
];

const WH347CertifiedReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const childRef: React.RefObject<any> = useRef();

  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);

  const client = useAppSelector((state) => { return state?.client?.client; }) as Client;

  const report = useAppSelector(
    (state) => { return state?.contractorReports.wh347CertifiedReport; },
  );

  const hidePdfViewer = () => {
    dispatch(clearWH347CertifiedReport());
  };

  const { register, handleSubmit } = useForm<WH347_Certified_Report_Request>({
    defaultValues: {
      byWeekEndingOrCheckDate: 'ByWeekEnding',
    },
  });

  const downloadReport = (data: WH347_Certified_Report_Request) => {
    const submitData: WH347_Certified_Report_Request = {
      clientNo: client.clientNo,
      byWeekEndingOrCheckDate: data.byWeekEndingOrCheckDate,
      dtoOption: reportDates[0],
    };
    dispatch(downloadWH347CertifiedReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">WH347 Certified Report</div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div>
              <InputReportDateSelector
                ref={childRef}
                returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }
                }
                isSingleSelect={true}
              />
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <div className="text-right">
                <button
                  type="button"
                  className="orange-outline-button mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="orange-button mr-2"
                  disabled={!reportDates.length}
                >
                  Run Report
                </button>
              </div>
              <div
                className="dm-panel dm-panel-border"
                style={{ marginTop: '66px', width: '20%' }}
              >
                <div className="dm-grid-action-title mb-2">
                  Options
                </div>
                <div>
                  <div className="d-flex">
                    <div className="d-flex flex-row flex-grow-1">
                      <div className="d-flex flex-column flex-grow-1">
                        <RadioGrp
                          isVertical={true}
                          radioOptions={
                            weekendingOrCheckDate
                          }
                          name="byWeekEndingOrCheckDate"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {report ? (
          <PDFViewerModal
            show={report.length > 0}
            pdfData={report}
            onHide={hidePdfViewer}
            reportName="WH347CertifiedReport"
          />
        ) : null}
      </div>
    </div>
  );
};
export default WH347CertifiedReport;
