import { createSelector } from 'reselect';
import { empACAState } from '../reducers';
import { State as EmpACAState } from '../reducers/emp-aca.reducer';

export const getEmpACATabData = createSelector(
  empACAState,
  (state: EmpACAState) => {return state.acaTabData;},
);

export const getEmpACA1095b = createSelector(
  empACAState,
  (state: EmpACAState) => {return state.aca1095b;},
);

export const getClientACA = createSelector(
  empACAState,
  (state: EmpACAState) => {return state.clientAca;},
);

export const getClientACALastYear = createSelector(
  empACAState,
  (state: EmpACAState) => {return state.clientAcaLastYear;},
);