import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SelectGrp } from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { PayrollsUserOptions } from 'core/models';
import {
  loadPayrollsUserOptions,
  putPayrollsUserOptions,
} from 'core/store/actions';
import { getPayrollsUserOptions, getShowAddtl } from 'core/store/selectors';
import Modal from 'core/components/modals/Modal';
import { useAppSelector } from 'utilities/hooks';

type PropTypes = {
  show: boolean;
  onHide: () => void;
};

const inputStyles = {
  maxWidth: '150px',
};

const accuracyInputStyles = {
  maxWidth: '100px',
};

const optionZeroToFour = [
  {
    id: 0,
    description: '0',
  },
  {
    id: 1,
    description: '1',
  },
  {
    id: 2,
    description: '2',
  },
  {
    id: 3,
    description: '3',
  },
  {
    id: 4,
    description: '4',
  },
];

const fs: FieldInputSettings = {
  employeeNameFormat: {
    name: 'employeeNameFormat',
    label: 'Employee Name',
    options: [
      {
        id: 0,
        description: 'Last, First I',
      },
      {
        id: 1,
        description: 'Last First I',
      },
      {
        id: 2,
        description: 'First Last',
      },
      {
        id: 3,
        description: 'F I Last',
      },
      {
        id: 4,
        description: 'First I Last',
      },
    ],
  },
  negativeNumbers: {
    name: 'negativeNumbers',
    label: 'Negative Numbers',
    options: [
      {
        id: 0,
        description: 'Normal',
      },
      {
        id: 1,
        description: 'Red',
        color: '#FF0000',
      },
      {
        id: 2,
        description: 'Parenthesis ()',
      },
      {
        id: 3,
        description: 'Red Parenthesis ()',
        color: '#FF0000',
      },
    ],
  },
  alternateRate: {
    name: 'alternateRate',
    label: 'Alternate Rate',
    options: [...optionZeroToFour],
  },
  hours: {
    name: 'hours',
    label: 'Hours',
    options: [
      {
        id: 0,
        description: '0',
      },
      {
        id: 1,
        description: '1',
      },
      {
        id: 2,
        description: '2',
      },
    ],
  },
  dollarAmount: {
    name: 'dollarAmount',
    label: 'Dollar Amount',
    options: [...optionZeroToFour],
  },
};

const TransmittalPreferencesModal: React.FC<PropTypes> = ({ show, onHide }) => {
  const dispatch = useDispatch();
  
  const payrollsUserOptions: PayrollsUserOptions | null = useSelector(getPayrollsUserOptions);
  const showAddtlFlag = useSelector(getShowAddtl);
  const { loading } = useAppSelector(({ selEmployeeDetails }) => selEmployeeDetails.payrollsUserOptions); // TODO: why is this part of selEmployeeDetails? 
  
  const [showAddtlFields, setShowAddtlFields] = useState<boolean>(false);

  const { errors, register, setValue, watch } = useForm<any>();
  const {
    employeeNameFormat,
    negativeNumbers,
    alternateRate,
    hours,
    dollarAmount,
  } = watch([
    'employeeNameFormat',
    'negativeNumbers',
    'alternateRate',
    'hours',
    'dollarAmount',
  ]);

  const onSave = () => {
    if (!payrollsUserOptions) return;
    const userOptionsCopy = structuredClone(payrollsUserOptions);
    
    userOptionsCopy.employeeNameFormat = Number.parseInt(employeeNameFormat);
    userOptionsCopy.negativeNumberFormat = Number.parseInt(negativeNumbers);
    userOptionsCopy.altRateDecimals = Number.parseInt(alternateRate);
    userOptionsCopy.hoursDecimals = Number.parseInt(hours);
    userOptionsCopy.amountDecimals = Number.parseInt(dollarAmount);
    userOptionsCopy.showExtraDetails = showAddtlFields;
    
    dispatch(putPayrollsUserOptions(userOptionsCopy));
  };

  useEffect(() => {
    if (payrollsUserOptions) {
      setValue(fs.employeeNameFormat.name, payrollsUserOptions?.employeeNameFormat);
      setValue(fs.negativeNumbers.name, payrollsUserOptions?.negativeNumberFormat);
      setValue(fs.alternateRate.name, payrollsUserOptions?.altRateDecimals);
      setValue(fs.hours.name, payrollsUserOptions?.hoursDecimals);
      setValue(fs.dollarAmount.name, payrollsUserOptions?.amountDecimals.toString());
    } else 
      dispatch(loadPayrollsUserOptions());
  }, [payrollsUserOptions]);
  
  useEffect(() => {
    setShowAddtlFields(!!showAddtlFlag);
  }, [showAddtlFlag]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      title="Transmittal Preferences"
    >
      {(closeModal) => {
        return (
          <>
            <div
              className="row w-100"
              aria-disabled={loading}
            >
              <div className="d-flex flex-column w-100">
                <div className="row">
                  <h4 className="ml-3 mb-3">Formatting</h4>
                </div>
                <div className="d-flex mb-4">
                  <div
                    className="w-50 mr-4"
                    style={inputStyles}
                  >
                    <SelectGrp
                      defaultValue = {payrollsUserOptions?.employeeNameFormat}
                      {...fs.employeeNameFormat}
                      errors={errors.employeeNameFormat}
                      ref={register}
                      disabled={loading}
                    />
                  </div>
                  <div
                    className="w-50"
                    style={inputStyles}
                  >
                    <SelectGrp
                      defaultValue = {payrollsUserOptions?.negativeNumberFormat}
                      {...fs.negativeNumbers}
                      errors={errors.negativeNumbers}
                      ref={register}
                      disabled={loading}
                    />
                  </div>
                </div>
                <div className="row">
                  <h4 className="ml-3 mb-3">
                    Accuracy (Decimal Places)
                  </h4>
                </div>
                <div className="d-flex pb-2">
                  <div
                    className="w-50 mr-3"
                    style={accuracyInputStyles}
                  >
                    <SelectGrp
                      defaultValue = {payrollsUserOptions?.altRateDecimals}
                      {...fs.alternateRate}
                      errors={errors.alternateRate}
                      ref={register}
                      disabled={loading}
                    />
                  </div>
                  <div
                    className="w-50 mr-3"
                    style={accuracyInputStyles}
                  >
                    <SelectGrp
                      defaultValue = {payrollsUserOptions?.hoursDecimals}
                      {...fs.hours}
                      errors={errors.hours}
                      ref={register}
                      disabled={loading}
                    />
                  </div>
                  <div
                    className="w-50 mr-3"
                    style={accuracyInputStyles}
                  >
                    <SelectGrp
                      defaultValue = {payrollsUserOptions?.amountDecimals}
                      {...fs.dollarAmount}
                      errors={errors.hours}
                      ref={register}
                      disabled={loading}
                    />
                  </div>
                </div>
                <div className="row">
                  <h4 className="ml-3 mb-3">
                    Other
                  </h4>
                </div>
                <div className="d-flex">
                  <span>Show additional fields on earnings</span>
                  <input
                    className="ml-2"
                    type="checkbox"
                    name="addtlFieldsToggle"
                    checked={showAddtlFields}
                    onChange={(e) => { setShowAddtlFields(e.target.checked); }}
                    disabled={loading}
                  />
                </div>        
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-primary orange-outline-button mr-2"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary orange-button"
                onClick={() => {
                  onSave();
                  closeModal();
                }}
                disabled={loading}
              >
                Save
              </button>
            </div>
          </>
        );
      }}
    </Modal>
  );
};

export default TransmittalPreferencesModal;
