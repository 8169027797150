import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PanelHeader from 'core/components/shared/PanelHeader';
import { useDispatch, useSelector } from 'react-redux';
import { loadYearToDate } from 'core/store/actions';
import { getClient, getYTD } from 'core/store/selectors';
import YTDEarnings from './YTD-earnings';
import YTDTaxes from './YTD-taxes';
import YTDDeductions from './YTD-deductions';
import YTDCompanyBenefits from './YTD-company-benefits';
import styles from 'core/components/form-controls/form-controls.module.scss';

const YTDPage: React.FC = () => {
  const dispatch = useDispatch();
  const { protectedEmpNo } = useParams<any>();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const ytd = useSelector(getYTD);
  const client = useSelector(getClient);

  const getYears = () => {
    if (client && client.startDate) {
      const startYear = new Date(client.startDate).getFullYear();
      const lastYear = new Date().getFullYear() + 1;

      return Array(lastYear - (startYear - 1))
        .fill('')
        .map((_, i) => {return lastYear - i;}) as Array<number>;
    }
  };

  const years = getYears() || [];
  const selectOptions = years.map((year) => {
    return (
      <option key={year}>{year}</option>
    );
  });

  useEffect(() => {
    if (!protectedEmpNo) return;
    dispatch(
      loadYearToDate({
        empNo: protectedEmpNo,
        prYear: selectedYear,
      }),
    );
  }, [protectedEmpNo, selectedYear]);

  return (
    <div
      className="dm-panel dm-panel-border"
      style={{ minHeight: '300px' }}
    >
      <div className="d-flex flex-row">
        <PanelHeader title="Y.T.D." />
      </div>
      <div>
        <div>Select year: </div>
        <select
          className={styles['dm-form-control']}
          style={{ width: '100px',
            marginBottom: '10px' }}
          defaultValue={selectedYear}
          onChange={(e) => { setSelectedYear(parseInt(e.target.value)); }
          }
        >
          {selectOptions}
        </select>
      </div>
      <div className="d-flex flex-row flex-wrap">
        <YTDEarnings items={ytd?.ytdEarnings} />
        <YTDTaxes items={ytd?.ytdTaxes} />
        <YTDDeductions items={ytd?.ytdDeductions} />
        <YTDCompanyBenefits items={ytd?.ytdCompanyBenefits} />
      </div>
    </div>
  );
};

export default YTDPage;
