import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class PayrollInfo {
  empId = 0;

  clientNo = 0;

  empNo = 0;

  protectedEmpNo = '';

  payPeriod = '';

  workersCompCode = '';

  eeo = '';

  eeoOccupGroup = '';

  eeoUnitNo = '';

  pensionEligible = false;

  clockNo = '';

  shiftCode = '';
  
  shiftPremiumId = 0;

  payCategoryId: number | null = null;

  payGroupId: number | null = null;

  novAtimeScheduleSupervisorId: string | null = null;

  shiftNumber: number | null = null;

  driversLicense = '';

  jobTitleId: number | null = null;

  eicCode = '';

  exemptStatus = '';

  employeeStatus = '';

  supervisorEmpId: number | null = null;

  secondSupervisorEmpId: number | null = null;

  eligibleBenefits = false;

  eligibleVacation = false;

  tippedEmployee = false;

  isOfficer = false;

  hasUnionDue = false;

  usesSpecialEarnings = false;

  isSupervisor = false;

  payPolicyId: number | null = null;

  jobTrainingCodeId: number | null = null;

  h1BStartDate: Date | string | null = null;

  noFica = false;

  hasFica = true;

  hasSui = true;

  hasFuta = true;

  nonResidentAlien = false;

  hasE945 = false;

  is1099 = false;

  isPoliceOfficerOrFireFighter = false;

  ten99LookUpId: number | null = null;

  ten99TaxpayerIdType: number | null = null;

  nrwhAdjust = false;

  nrwhFica = false;

  retirement = false;

  statutoryEmployee = false;

  highGross = 0;

  highHours = 0;

  fixedHours = 0;

  federalBlsSocCodeId = 0;

  group1LookUpId: number | null = null;

  group2LookUpId: number | null = null;

  group3LookUpId: number | null = null;

  group4LookUpId: number | null = null;

  group5LookUpId: number | null = null;

  group6LookUpId: number | null = null;

  group7LookUpId: number | null = null;

  group8LookUpId: number | null = null;

  static readonly convProps: PropTypeLists = {
    dateProps: ['h1BStartDate'],
    numberProps: [
      'empId',
      'clientNo',
      'empNo',
      'payGroupId',
      'jobTitleId',
      'supervisorEmpId',
      'secondSupervisorEmpId',
      'shiftPremiumId',
      'federalBlsSocCodeId',
    ],
    nullNumberProps: [
      'payCategoryId',
      'shiftNumber',
      'supervisorEmpId',
      'secondSupervisorEmpId',
      'payPolicyId',
      'jobTrainingCodeId',
      'ten99LookUpId',
      'ten99TaxpayerIdType',
      'group1LookUpId',
      'group2LookUpId',
      'group3LookUpId',
      'group4LookUpId',
      'group5LookUpId',
      'group6LookUpId',
      'group7LookUpId',
      'group8LookUpId',
    ],
    floatProps: ['highHours', 'fixedHours'],
    currencyProps: ['highGross'],
    boolProps: [
      'pensionEligible',
      'eligibleBenefits',
      'eligibleVacation',
      'tippedEmployee',
      'isOfficer',
      'hasUnionDue',
      'usesSpecialEarnings',
      'isSupervisor',
      'hasFica',
      'hasSui',
      'hasFuta',
      'nonResidentAlien',
      'hasE945',
      'is1099',
      'isPoliceOfficerOrFireFighter',
      'nrwhAdjust',
      'nrwhFica',
      'retirement',
      'statutoryEmployee',
    ],
  };

  constructor(
    empId: number | string,
    clientNo: number | string,
    empNo: number | string,
    protectedEmpNo: string,
    props?: { [key: string]: any },
  ) {
    this.empId = typeof empId === 'string' ? parseInt(empId) : empId;
    this.clientNo =
            typeof clientNo === 'string' ? parseInt(clientNo) : clientNo;
    this.empNo = typeof empNo === 'string' ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;
    props && convToClass(this, props, PayrollInfo.convProps);
  }
}
