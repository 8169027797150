export const prFrequencyOpts = [
  {
    id: '1',
    description: '1st Check Date of the Month',
  },
  {
    id: '2',
    description: '2nd Check Date of the Month',
  },
  {
    id: '3',
    description: '3rd Check Date of the Month',
  },
  {
    id: '4',
    description: '4th Check Date of the Month',
  },
  {
    id: '5',
    description: '5th Check Date of the Month',
  },
  {
    id: '6',
    description: "6 - Deducts EFT's and Taxes Only, No Accruals",
  },
  {
    id: '7',
    description: '7 - Deducts Fed, FICA, State, Local Taxes Only',
  },
  {
    id: '8',
    description: '8 - Deducts FICA Tax Only',
  },
  {
    id: '9',
    description: '9 - No Deductions, Employer Pays FICA for Employee',
  },
  {
    id: 'A',
    description: '10 - Deducts EFT\'s and FICA taxes Only',
  },
  {
    id: 'B',
    description: "11 - Deducts EFT's, 401K and Taxes Only, No Accruals",
  },
  {
    id: '0',
    description: '0 - Payroll Tax Adjustment Run',
  },
  {
    id: '+',
    description: '+ - SUI Rate Adjustment Run',
  },
  {
    id: 'Y',
    description: 'Y - Employee Transfer',
  },
];