import { AxiosResponse } from "axios";
import { HttpResponse } from '../models';
import {
  AccrualReportsTabRequest,
  AccuralReportStandardRequest,
} from '../models/AccrualReport.model';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getStandardReports = (
  request: AccuralReportStandardRequest,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.POST_ACCRUAL_REPORTS_STANDARD_REPORTS(
      CommonService.getProtectedClientNo(),
    ),
    request,
  );
};

const postAccrualReportsAccrualTab = (
  request: AccrualReportsTabRequest,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    EmployeeEndPoints.POST_ACCRUAL_REPORTS_ACCRUALTAB(
      CommonService.getProtectedClientNo(),
      request.protectedEmpNo,
    ),
    request,
  );
};

export const AccrualReportsService = {
  getStandardReports,
  postAccrualReportsAccrualTab,
};
