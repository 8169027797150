import React, { useEffect, useState } from 'react';
import Modal from 'core/components/modals/Modal';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ControlDatePickerGrpInLine } from 'core/components/form-controls';
import {
  clearEmployeeChangeReport,
  clearEmployeeChangeReportFields,
  handleError,
  postEmployeeChangeReport,
} from 'core/store/actions';
import {
  getSelectedEmployeeChangesReport,
  getDeductionsIOption,
  getEarningsCodesIOption,
  getEmployeesIOptions,
} from 'core/store/selectors';
import { ModalProps } from '../employee/EmployeeToolbar';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { SelectMultipleInLine } from 'core/components/form-controls/SelectMultipleInLine';
import { getTaxEntitiesIOption } from 'core/store/selectors/tax.selector';
import Icon from 'core/components/shared/Icon';
import { CommonService } from 'core/services';
import { IOption, ISelectMultipleOption } from 'interfaces/IOption';
import { DateTime } from 'luxon';
import SelectMultiple from 'core/components/form-controls/SelectMultiple';
import { toDateLessThanValid, toDateLessThanYearValid } from './EmployeeChangeReportShared';
import { useAppSelector } from 'utilities/hooks';
import { Spinner } from 'react-bootstrap';

type ChangeReportForm = {
  fromDate: Date;
  toDate: Date;
  includeAccrualRuleChanges: boolean;
  includeAodChanges: boolean;
  includeContactChanges: boolean;
  includeDateChanges: boolean;
  includeDeductionChanges: boolean;
  includeDeferredCompChanges: boolean;
  includeEmpInfoChanges: boolean;
  includePayRateChanges: boolean;
  includePayrollInfoChanges: boolean;
  includeRecurringEarnsChanges: boolean;
  includeTaxChanges: boolean;
  rdFileType: string;
};

const EmployeeChangesReportModal: React.FC<ModalProps> = ({ show, onHide }) => {
  const dispatch = useDispatch();
  
  const reportName = 'Employee Change Report';
  
  const report = useSelector(getSelectedEmployeeChangesReport);  
  const loadingReport = useAppSelector((state) => {return state.employeeReports.loadingChangeReport;});
  const [fileType, setFileType] = useState<string>('pdf');
  const [selectedEmpIds, setSelectedEmpIds] = useState<ISelectMultipleOption[]>([]);
  const [selectedDedNo, setSelectedDedNo] = useState<ISelectMultipleOption[]>([]);
  const [selectedTaxEntities, setSelectedTaxEntities] = useState<ISelectMultipleOption[]>([]);
  const [selectedRecEarnings, setSelectedRecEarnings] = useState<ISelectMultipleOption[]>([]);
  const [showPdf, setShowPdf] = useState(false);

  const empIds = useSelector(getEmployeesIOptions);  
  const dedNos = useSelector(getDeductionsIOption);  
  const taxEntities = useSelector(getTaxEntitiesIOption);  
  const earningsCodes = useSelector(getEarningsCodesIOption);  

  const { register, getValues, handleSubmit, control } =
        useForm<ChangeReportForm>({
          defaultValues: {
            fromDate: DateTime.local().minus({ year: 1 }).plus({ days: 1 }).toJSDate(),
            toDate: DateTime.local().toJSDate(),
            includeAccrualRuleChanges: true,
            includeAodChanges: true,
            includeContactChanges: true,
            includeDateChanges: true,
            includeDeductionChanges: true,
            includeDeferredCompChanges: true,
            includeEmpInfoChanges: true,
            includePayRateChanges: true,
            includePayrollInfoChanges: true,
            includeRecurringEarnsChanges: true,
            includeTaxChanges: true,
          },
        });

  const watch = useWatch<{ fromDate: string, toDate: string, empNos: ISelectMultipleOption[] }>({
    control,
  }) as { fromDate: string, toDate: string, empNos: ISelectMultipleOption[] };

  useEffect(() => {
    onReset();
  }, []);

  useEffect(() => {
    if (report && fileType === 'excel') {
      CommonService.downloadBase64File(
        'application/excel',
        report,
        `EmployeeChangeReport.xlsx`,
      );
      onReset();
    }
  }, [report]);

  const postReport = (data: ChangeReportForm) => {
    const fromDate = new Date(getValues('fromDate'));
    const toDate = new Date(getValues('toDate'));

    if (!toDateLessThanValid(fromDate, toDate)) return dispatch(handleError('To Date must be greater than From Date'));
    if (!toDateLessThanYearValid(fromDate, toDate)) return dispatch(handleError('Dates span must be less than a year apart'));
    if (!selectedEmpIds.length) return dispatch(handleError('At least one employee must be selected'));

    dispatch(
      postEmployeeChangeReport({
        beginDate: data.fromDate.toISOString(),
        endDate: data.toDate.toISOString(),
        reportType: fileType,
        accrualRule: data.includeAccrualRuleChanges,
        aODInfo: data.includeAodChanges,
        contactInfo: data.includeContactChanges,
        dateInfo: data.includeDateChanges,
        deductionInfo: data.includeDeductionChanges,
        defCompInfo: data.includeDeferredCompChanges,
        employeeInfo: data.includeEmpInfoChanges,
        payRatesInfo: data.includePayRateChanges,
        payrollInfo: data.includePayrollInfoChanges,
        recEarningsInfo: data.includeRecurringEarnsChanges,
        taxInfo: data.includeTaxChanges,
        filteredEmpIds: selectedEmpIds.map(x => x.value) as number[],
        filteredDeductionNumbers: selectedDedNo.map(x => x.value) as number[],
        filteredTaxEntities: selectedTaxEntities.map(x => x.value) as number[],
        filteredEarningsCodes: selectedRecEarnings.map(x => x.value) as string[]
      }),
    );
    if(fileType === 'pdf') setShowPdf(true);
  };

  const onReset = () => {
    dispatch(clearEmployeeChangeReport());
  };

  const closeModal = () => {
    if (report) {
      dispatch(clearEmployeeChangeReport());
    }
    dispatch(clearEmployeeChangeReportFields());
    onHide();
  };
  
  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      {!showPdf || !report ? (
        <Modal
          show={show}
          onHide={closeModal}
          animation={false}
          title="Employee Change Report"
        >
          {() => {
            return (
      <form onSubmit={handleSubmit(postReport)}>
      <div className="d-flex">
      <div className='mr-auto p-2'>
      <button
            type="submit"
            className="btn btn-link dm-grid-action-title "
            onClick={() => { return setFileType('excel'); }}
            disabled={loadingReport}
          >
            {loadingReport && fileType !== 'pdf' ? (
              <div className="d-flex">
                <span className="mr-2">Downloading...</span>
                <Spinner
                  animation="border"
                  size="sm"/>
              </div>):
              <div className="d-flex">
                <span className="mr-2">Download Report</span>
                <Icon name="download" className="fa-download"/>
              </div>}
          </button>
        </div>
          <div className='p-2'>
          <button
                type="submit"
                className="orange-button mr-2"
                disabled={loadingReport}
                onClick={() => { return setFileType('pdf'); }}>
                {loadingReport && fileType === 'pdf' ? (
                        <div className="d-flex">
                          <span className="mr-2">Generating...</span>
                          <Spinner
                            animation="border"
                            size="sm"
                          />
                        </div>
                      ) : 'Run Report'}
              </button>
        </div>
      </div>
      <hr className="dm-panel-hr" />
      <div className="d-flex">
        <div className="d-flex flex-row flex-grow-1">
            <div className="row mt-2">
              <div className="col d-flex flex-column flex-grow-1">
              <div className={'dm-panel-blue dm-panel-border'}>
                  <div className="row pl-3 pb-2">Select Employees</div>
                    <SelectMultiple
                      options={empIds}
                      selectAllDefault={true}
                      onChange={setSelectedEmpIds}
                    />
              </div>
              <div className={'dm-panel-blue dm-panel-border'}>
              <div className="row pl-3 pb-2">Select Dates</div>
                <div id="fromDate">
                  <ControlDatePickerGrpInLine
                    name="fromDate"
                    label={'FROM: '}
                    labelWidth={20}
                    groupClass={'gc12 wm150'}
                    required={true}
                    value={watch.fromDate}
                    control={control}
                  />
                </div>
                <div id="toDate">
                  <ControlDatePickerGrpInLine
                    name="toDate"
                    label={'TO: '}
                    labelWidth={20}
                    groupClass={'gc12 wm150'}
                    required={true}
                    value={watch.toDate}
                    control={control}
                  />
                </div>
                </div>
                <div className={'dm-panel-blue dm-panel-border'}>
                  <div className="row pl-3 pb-2">Sections</div>
                    <SelectMultipleInLine
                    name= {'includeDeductionChanges'}
                    label={'Deduction Information:'}
                    groupClass={'groupClass100'}
                    labelFirst={true}
                    labelWidth={73}
                    labelWidthUnit={'%'}
                    defaultChecked={true}
                    ref={register()}
                    multiSelectOptions={dedNos}
                    onChangeMultiSelect={setSelectedDedNo}
                    />
                    <SelectMultipleInLine
                    name= {'includeTaxChanges'}
                    label={'Tax Information:'}
                    groupClass={'groupClass100'}
                    labelFirst={true}
                    labelWidth={73}
                    labelWidthUnit={'%'}
                    defaultChecked={true}
                    ref={register()}
                    multiSelectOptions={taxEntities}
                    onChangeMultiSelect={setSelectedTaxEntities}
                    />
                    <SelectMultipleInLine
                    name= {'includeRecurringEarnsChanges'}
                    label={'Rec. Earnings Information:'}
                    groupClass={'groupClass100'}
                    labelFirst={true}
                    labelWidth={73}
                    labelWidthUnit={'%'}
                    defaultChecked={true}
                    ref={register()}
                    multiSelectOptions={earningsCodes}
                    onChangeMultiSelect={setSelectedRecEarnings}
                    />
                    <SelectMultipleInLine
                    name= {'includeEmpInfoChanges'}
                    label={'Employee Information:'}
                    groupClass={'groupClass100'}
                    labelFirst={true}
                    labelWidth={40}
                    labelWidthUnit={'%'}
                    showMultiSelect={false}
                    defaultChecked={true}
                    ref={register()}
                    />
                    <SelectMultipleInLine
                    name= {'includePayrollInfoChanges'}
                    label={'Payroll Information:'}
                    groupClass={'groupClass100'}
                    labelFirst={true}
                    labelWidth={40}
                    labelWidthUnit={'%'}
                    showMultiSelect={false}
                    defaultChecked={true}
                    ref={register()}
                    />
                    <SelectMultipleInLine
                    name= {'includeDateChanges'}
                    label={'Date Information:'}
                    groupClass={'groupClass100'}
                    labelFirst={true}
                    labelWidth={40}
                    labelWidthUnit={'%'}
                    showMultiSelect={false}
                    defaultChecked={true}
                    ref={register()}
                    />
                    <SelectMultipleInLine
                    name= {'includeContactChanges'}
                    label={'Contact Information:'}
                    groupClass={'groupClass100'}
                    labelFirst={true}
                    labelWidth={40}
                    labelWidthUnit={'%'}
                    showMultiSelect={false}
                    defaultChecked={true}
                    ref={register()}
                    />
                    <SelectMultipleInLine
                    name= {'includeDeferredCompChanges'}
                    label={'Def. Comp. Information:'}
                    groupClass={'groupClass100'}
                    labelFirst={true}
                    labelWidth={40}
                    labelWidthUnit={'%'}
                    showMultiSelect={false}
                    defaultChecked={true}
                    ref={register()}
                    />
                    <SelectMultipleInLine
                    name= {'includePayRateChanges'}
                    label={'Pay Rates Information:'}
                    groupClass={'groupClass100'}
                    labelFirst={true}
                    labelWidth={40}
                    labelWidthUnit={'%'}
                    showMultiSelect={false}
                    defaultChecked={true}
                    ref={register()}
                    />
                    <SelectMultipleInLine
                    name= {'includeAccrualRuleChanges'}
                    label={'Accrual Rule Information:'}
                    groupClass={'groupClass100'}
                    labelFirst={true}
                    labelWidth={40}
                    labelWidthUnit={'%'}
                    showMultiSelect={false}
                    defaultChecked={true}
                    ref={register()}
                    />
                    <SelectMultipleInLine
                    name= {'includeAodChanges'}
                    label={'Aod Information:'}
                    groupClass={'groupClass100'}
                    labelFirst={true}
                    labelWidth={40}
                    labelWidthUnit={'%'}
                    showMultiSelect={false}
                    defaultChecked={true}
                    ref={register()}
                    />
                  <small className="order-3">*Select sections to include on report*</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      </form>
            );
          }}
        </Modal>
      ) : (
        <PDFViewerModal
          show={show}
          onHide={closeModal}
          pdfData={report}
          reportName="EmployeeChangesReport"
        />
      )}
    </div>
  );
};

export default EmployeeChangesReportModal;
