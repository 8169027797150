import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GlobalDeductionEmployee, HttpResponse } from '../../models';
import { GlobalDeductionService } from '../../services';
import {
  handleError,
  loadGlobalDeductions,
  storeGlobalDeductions,
  upsertGlobalDeductions,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadGlobalDeductions$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadGlobalDeductions.type),
    switchMap((action: { payload: number; }) => {
      return from(
        GlobalDeductionService.getGlobalDeductions(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeGlobalDeductions(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const upsertGlobalDeductions$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(upsertGlobalDeductions.type),
    switchMap((action: { payload: { data: GlobalDeductionEmployee[]; dedNo: number; }; }) => {
      return from(
        GlobalDeductionService.postGlobalDeductions(
          action.payload.data,
        ),
      ).pipe(
        map((res: any) => { return loadGlobalDeductions(action.payload.dedNo); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [loadGlobalDeductions$, upsertGlobalDeductions$];
