import { baseFilter } from 'core/components/shared/table/utilities';
import { TableModel as Tm } from 'core/components/shared/table/types';
import { Employee } from 'core/models';

/**
 * Function to combine the queries/selections of the radio buttons, column filters, and search bar in the employee list.  
 * @param employees The employees we're filtering
 * @param currentFilterModel The current filter model set on the columns
 * @param listQuery Whatever is in the search bar
 * @param radioSelection The current radio button selection
 * @returns the filtered employees
 */
export const aggregateFilter = (employees: Employee[], currentFilterModel: Tm.FilterModel<Employee>, listQuery: string, radioSelection: 'All' | 'Active' | 'Terminated'): Employee[] => {
  const newEmps = employees.filter((x) => {
    // Step 1: check column filters
    // use a match flag to track any cells that don't match a given column
    let match = baseFilter<Employee>(x, currentFilterModel);
  
    // Step 2: account for any search bar value
    match = match && (listQuery
      ? x.firstName.toLowerCase().includes(listQuery.toLowerCase())
      || x.lastName.toLowerCase().includes(listQuery.toLowerCase())
      || String(x.empNo) === listQuery.toLowerCase()
      : true);
    
    // Step 3: account for radio button selection
    match = match && (radioSelection === 'Active' ? !x.termDate : radioSelection === 'Terminated' ? !!x.termDate : true);
    
    return match; // if match === false, then empoyee is filtered out.
  });
    
  return newEmps;
};