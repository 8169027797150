import React, { } from 'react';

import {
  ControlDatePickerGrp,
  InputGrp,
  InputGrpCurrency,
  InputGrpDecimal,
  SelectGrp,
  SelectModalGrp,
} from '../../../core/components/form-controls';
import { ArrayField } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { HRTrainingEvent } from '../../../core/models/HREmployee.model';
import DropdownOptionForm from '../../../core/components/form-controls/select-modal/DropdownOptionForm';
import { getJobTitles, getSchoolTypeCodes, getStates, getTrainingStatusTypes } from '../../../core/store/selectors';
import { CURRENCY_VALIDATION } from '../../../core/constants';

import Icon from 'core/components/shared/Icon';

type PropTypes = {
  item: Partial<ArrayField<HRTrainingEvent>>;
  index: number;
  control: any;
  formMethods: any;
  onDelete: (item: HRTrainingEvent) => void;
};

const TrainingEventItem: React.FC<PropTypes> = ({
  item,
  index,
  control,
  formMethods: { errors, setValue, register },
  onDelete,
}) => {

  const stateOpts = useSelector(getStates);
  const schoolTypes = useSelector(getSchoolTypeCodes);
  const trainingStatusTypes = useSelector(getTrainingStatusTypes);
  const jobTitles = useSelector(getJobTitles);

  return (

    <div className="d-flex flex-column">
      <div className="d-flex flex-wrap">
        <input
          type="hidden"
          name={`trainingEventItems[${index}].empTrainingId`}
          defaultValue={item.empTrainingId}
          ref={register({ valueAsNumber: true })}
        />
        <ControlDatePickerGrp
          name={`trainingEventItems[${index}].startDate`}
          label="START DATE"
          groupClass="gc12 mw125"
          value={item.startDate}
          setValue={setValue}
          control={control} />
        <ControlDatePickerGrp
          name={`trainingEventItems[${index}].endDate`}
          label="END DATE"
          groupClass="gc12 mw125"
          value={item.endDate}
          setValue={setValue}
          control={control} />
        <SelectModalGrp
          noOption={true}
          modalTitle="SCHOOL TYPE"
          label="SCHOOL TYPE"
          groupClass="gc12 mw125"
          formComponent={DropdownOptionForm}
          addOptionText="School Type"
          name={`trainingEventItems[${index}].schoolTypeId`}
          dropdownName="SchoolTypeCode"
          options={schoolTypes}
          control={control}
          value={item.schoolTypeId}
          setValue={setValue} />
        <SelectModalGrp
          noOption={true}
          modalTitle="STATUS TYPE"
          label="STATUS TYPE"
          groupClass="gc12 mw125"
          formComponent={DropdownOptionForm}
          addOptionText="Status Type"
          name={`trainingEventItems[${index}].statusTypeId`}
          dropdownName="TrainingStatusTypes"
          options={trainingStatusTypes}
          control={control}
          value={item.statusTypeId}
          setValue={setValue} />
        <SelectModalGrp
          noOption={true}
          modalTitle="TITLE"
          label="TITLE"
          groupClass="gc12 mw125"
          formComponent={DropdownOptionForm}
          addOptionText="Title"
          name={`trainingEventItems[${index}].title`}
          dropdownName="JobTitle"
          options={jobTitles}
          control={control}
          value={item.title}
          setValue={setValue} />
        <InputGrpDecimal
          name={`trainingEventItems[${index}].creditHours`}
          defaultValue={item.creditHours}
          label="CREDIT HOURS"
          groupClass="gc12 mw125"
          errors={errors.creditHours}
          ref={register({
            valueAsNumber: true,
          })} />
        <InputGrpCurrency
          name={`trainingEventItems[${index}].employeeFees`}
          defaultValue={item.employeeFees}
          label="EMPLOYEE FEES"
          groupClass="gc12 mw125"
          errors={errors.employeeFees}
          setValue={setValue}
          ref={register({
            CURRENCY_VALIDATION,
          })}
        />
        <InputGrpCurrency
          name={`trainingEventItems[${index}].companyFees`}
          defaultValue={item.companyFees}
          label="COMPANY FEES"
          groupClass="gc12 mw125"
          errors={errors.companyFees}
          setValue={setValue}
          ref={register({
            CURRENCY_VALIDATION,
          })}
        />
        <InputGrp
          name={`trainingEventItems[${index}].comment`}
          defaultValue={item.comment}
          label="COMMENT"
          groupClass="gc12"
          ref={register()} />
        <InputGrp
          name={`trainingEventItems[${index}].issuingCity`}
          defaultValue={item.issuingCity}
          label="ISSUING CITY"
          groupClass="gc12 mw125"
          ref={register()} />
        <SelectGrp
          name={`trainingEventItems[${index}].issuingState`}
          defaultValue={item.issuingState}
          label="ISSUING STATE"
          groupClass="gc12 mw125"
          options={stateOpts}
          ref={register()} />
        <InputGrp
          name={`trainingEventItems[${index}].licenseNumber`}
          defaultValue={item.licenseNumber}
          label="LICENSE NUMBER"
          groupClass="gc12 mw125"
          ref={register()} />
        <InputGrp
          name={`trainingEventItems[${index}].otherCertOrLicense`}
          defaultValue={item.otherCertOrLicense}
          label="OTHER CERTIFICATION OR LICENSE"
          groupClass="gc12 mw150"
          ref={register()} />
      </div>
      <div className="row">
        <div className="col-12 text-right">
          <button
            type="button"
            className="btn btn-link dm-grid-action-title"
            onClick={() => { return onDelete(item as HRTrainingEvent); }}
          >
            Delete Training Event
            <Icon name="minus-circle"
              className="fa-minus-circle" />
          </button>
        </div>
      </div>
    </div>

  );
};

export default TrainingEventItem;
