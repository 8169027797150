import { toast } from 'react-toastify';
import { ofType, StateObservable } from 'redux-observable';
import { from, Observable } from 'rxjs';
import {
  auditTime,
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { AppEmpSearch, Employee, HttpResponse } from '../../models';
import { UserAccess } from '../../models/UserAccess';
import { AuthService } from '../../services';
import { AppService } from '../../services/app.service';
import '../../../assets/scss/global.scss';

import {
  loadAllDropdowns,
  loadClient,
  loadClientEnumsOptions,
  loadClientOptions,
  loadDepartments,
  loadEmployees,
  loadLocations,
  loadNOVATime,
  loadNOVATimeRules,
  loadNOVATimeSettings,
  loadNOVATimeShiftNumbers,
  loadShiftPremiums,
  loadSubdepartments,
  loadSubdepartments2,
  storeUserAccess,
  loadPaygradeDropdowns,
  loadSchoolDropdowns,
  loadModuleAccess,
  loadModuleAccessWithoutLoadingAppData,
  storeModuleAccess,
  loadDashboardData,
  loadClientDeductions,
  load401KOptions,
  loadClientTaxEntityFips,
  loadIsNOVAClient,
  loadClientReportOptions,
  storeFilterModel,
  storeFilteredEmployees,
  storePrevTransmittalLink,
  storePrevHRLink,
  resetPayrollUploadVM,
  storeEmployees,
  storePrevTimeCardLink,
  loadClientAttendanceOnDemand,
  loadEmpPaidBreakdown,
  loadEmpGrossAnalysis,
  loadIssues,
  loadNewHireBreakdown,
  loadPayrollTotalsByTimePeriod,
  loadNewFeatures,
  loadPayrollTotalsByLocDeptSubSummary,
  loadCmTaxEntities,
  updateCurrentTransmittalPage,
  resetFilteredEmployees,
  updateListQuery,
} from '../actions';

import {
  clearModuleAccess,
  handleError,
  handlePending,
  handleSuccess,
  handleWarning,
  loadAppData,
  loadAppEmpSearch,
  loadAppEmpSearchNoModal,
  loadNOVAData,
  loadRelatedClients,
  loadUserAccess,
  resetStore,
  runManualCleanup,
  showRootModal,
  storeAppEmpSearch,
  storeLoadState,
  storeRelatedClients,
  updateDestination,
} from '../actions/app.action';
import { RootState } from '../store';
import { loadHireInfoCustomFields } from '../actions/hr-employee.action';
import { loadSso } from '../actions/sso.action';
import { AxiosResponse } from 'axios';
import { postCreateDefaultWorkFlowLevelUser } from '../actions/work-flow.action';
import { TableModel as Tm } from 'core/components/shared/table/types';

export type StreamedUserAccessObject = {
  nextEmpNo?: number;
  nextClientNo?: number;
};

interface Actions<Type> {
  type: string;
  payload: Type;
}

const resetStore$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(resetStore.type),
    mergeMap(() => [loadUserAccess()]),
  );
};

const handlePending$ = (action$: Observable<Actions<string | null>>) => {
  return action$.pipe(
    ofType(handlePending.type),
    tap((action: { payload: string | null }) => {
      if (action.payload) {
        toast.info(action.payload, {
          autoClose: false,
          closeOnClick: true,
          position: 'top-center',
          toastId: 'pending-notification',
        });
      } else {
        toast.dismiss('pending-notification');
      }
    }),
    mergeMap(() => []),
  );
};

const handleError$ = (action$: Observable<Actions<AxiosResponse<any>>>) => {
  return action$.pipe(
    ofType(handleError.type),
    tap((action: { payload: any }) => {
      let errMsg: string;
      
      if (typeof action.payload === 'string') {
        errMsg = action.payload;
      } else if (action.payload?.response?.data?.messages) {
        errMsg = Array.isArray(action.payload.response.data.messages)
          ? action.payload.response.data.messages.join('<br/>')
          : action.payload?.message;
      } else if (action.payload?.message) {
        errMsg = action.payload.message;
      } else if (action.payload?.messages) {
        errMsg = Array.isArray(action.payload.messages)
          ? action.payload.messages.join('<br/>')
          : action.payload;
      } else if (action.payload?.traceId) { // in the case of internal server errors, etc.
        errMsg = action.payload?.title; // just show the title rather than a long, confusing error message
      } else {
        errMsg = 'unspecified error'; // no message, or it's somehow not an object property or top-level string 
      }

      toast.error('ERROR: ' + errMsg);
    }),
    mergeMap(() => { return []; }),
  );
};

const handleSuccess$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(handleSuccess.type),
    tap((action: { payload: any[]; }) => {
      const successMessage = Array.isArray(action.payload)
        ? action.payload.join('<br/>')
        : action.payload;
      if (successMessage) toast.success(successMessage, { autoClose: 5000 });
    }),
    mergeMap(() => { return []; }),
  );
};

const handleWarning$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(handleWarning.type),
    tap((action: { payload: any[]; }) => {
      const warningMessage = Array.isArray(action.payload)
        ? action.payload.join('<br/>')
        : action.payload;
      toast.warning(warningMessage, { className: 'toast-warning' });
    }),
    mergeMap(() => { return []; }),
  );
};

const loadAppEmpSearch$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadAppEmpSearch.type),
    switchMap((action: { payload: AppEmpSearch; }) => {
      return from(AppService.postAppEmpSearch(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: Employee[]) => {
          return [storeAppEmpSearch(res)];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadAppEmpSearchNoModal$ = (action$: Observable<Actions<AppEmpSearch>>) => {
  return action$.pipe(
    ofType(loadAppEmpSearchNoModal.type),
    switchMap((action: { payload: AppEmpSearch; }) => {
      return from(AppService.postAppEmpSearch(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: Employee[]) => { return [storeAppEmpSearch(res)]; }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadModuleAccess$ = (action$: Observable<Actions<StreamedUserAccessObject | undefined>>) => {
  return action$.pipe(
    ofType(loadModuleAccess.type),
    switchMap((action) => {
      return from(AuthService.getModuleAccess()).pipe(
        map((res) => {
          return res.data;
        }),
        mergeMap((res) => {
          return [
            storeModuleAccess(res),
            loadAppData(action.payload),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    }),
  );
};

const loadModuleAccessWithoutLoadingAppData$  = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadModuleAccessWithoutLoadingAppData.type),
    switchMap((action) => {
      return from(AuthService.getModuleAccess()).pipe(
        map((res) => {
          return res.data;
        }),
        mergeMap((res) => {
          return [
            storeModuleAccess(res),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    }),
  );
};

const loadUserAccess$ = (action$: Observable<Actions<StreamedUserAccessObject | undefined>>) => {
  return action$.pipe(
    ofType(loadUserAccess.type),
    auditTime(1000),
    switchMap((action) => {
      return from(AuthService.getUserAccess()).pipe(
        map((res: any) => { return res.data; }),
        switchMap((res: UserAccess) => {
          return [
            storeUserAccess({ ...res, nextClientNo: action?.payload?.nextClientNo ?? null }),
            loadModuleAccess(action.payload),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadRelatedClients$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadRelatedClients.type),
    switchMap((action: any) => {
      return from(AppService.getRelatedClients()).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => { return [storeRelatedClients(res)]; }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadAppData$ = (action$: Observable<Actions<StreamedUserAccessObject | undefined>>) => {
  return action$.pipe(
    ofType(loadAppData.type),
    mergeMap((action) => {
      return [
        loadClient(),
        loadClientOptions(),
        loadClientAttendanceOnDemand({ suppressError: true }), // adding this here for AoD "In Production Mode" option for EM
        loadClientEnumsOptions(), //Not using CN currently
        loadClientDeductions(), //Maybe just call this when loading in the deductions?
        loadCmTaxEntities(), //Load them here instead of the tax page since I need them for the change report
        load401KOptions(), 
        loadClientTaxEntityFips(),
        loadAllDropdowns(),
        loadDepartments(),
        loadSubdepartments(),
        loadSubdepartments2(),
        loadLocations(),
        loadShiftPremiums(),
        loadEmployees(action.payload),
        loadNOVAData(),
        loadPaygradeDropdowns(),
        loadHireInfoCustomFields(),
        loadSchoolDropdowns(),
        loadDashboardData(),
        loadClientReportOptions(),
        loadIsNOVAClient(),
        loadEmpPaidBreakdown(),
        loadEmpGrossAnalysis(),
        loadIssues(),
        loadNewHireBreakdown(),
        loadPayrollTotalsByTimePeriod(),
        loadPayrollTotalsByLocDeptSubSummary(),
        loadNewFeatures(),
        postCreateDefaultWorkFlowLevelUser(),
      ];
    }),
  );
};

const loadNOVAData$ = (
  action$: Observable<Actions<any>>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    ofType(loadNOVAData.type),
    debounceTime(1000),
    withLatestFrom(state$),
    filter(
      ([, state]) => { return state.client.clientOptions.options?.[9999].optionValue === 'Yes'; },
    ),
    mergeMap(() => {
      return [
        loadNOVATimeSettings(),
        loadNOVATime(),
        loadNOVATimeShiftNumbers(),
        loadNOVATimeRules(),
        loadSso(),
      ];
    }),
  );
};

// TODO: Since this is logic we might use for other similar things, make this more general w/ more params
const updateDestination$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(updateDestination.type),
    mergeMap(({ payload }) => {
      const outputStream = [];
      const defaultFilterModel: Tm.FilterModel<Employee> = {
        termDate: {
          sortOrder: 'UNSORTED',
          filterType: 'Blank',
          type: 'text',
        },
      };
      const baseCondition = !payload.includes('employee/detail') || payload.includes('add-employee');
      // only store null if leaving EM or adding a new employee (because in this case they're doing more than just updating)
      if (baseCondition) {
        outputStream.push(
          storePrevHRLink(null),
          storePrevTimeCardLink(null),
        );
      }
      // put the transmittal back on page 1 if we aren't going to EM because we're done with the current one
      if (baseCondition && !payload.includes('open-current-transmittal')) {
        outputStream.push(
          updateCurrentTransmittalPage(1),
          storePrevTransmittalLink(null),
        );
      } 
      // PI-8829: reset employee list if we're going anywhere other than EM, the list, or HR profile.
      if (baseCondition && !(payload.includes('employee-master-list') || payload.includes('hr-profile/detail'))) {
        outputStream.push( 
          updateListQuery(''),
          resetFilteredEmployees(),
          storeFilterModel({
            filterModel: defaultFilterModel,
            radioSelection: 'Active',
          }));
      }
      
      return outputStream;
    }),
  );
};



// handle any special case state cleanup needed here
const runManualCleanup$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(runManualCleanup.type),
    mergeMap(() => {
      return [
        storeLoadState(true),
        clearModuleAccess(),
        storePrevTransmittalLink(null),
        storeEmployees([]),
        storeFilteredEmployees([]),
        resetPayrollUploadVM(),
        storeFilterModel({
          filterModel: {
            termDate: {
              sortOrder: 'UNSORTED',
              type: 'date',
              filterType: 'Blank',
            },
          },
          radioSelection: 'Active',
        }),
      ];
    }),
  );
};

export const epics = [
  resetStore$,
  handlePending$,
  handleError$,
  handleSuccess$,
  handleWarning$,
  loadAppEmpSearch$,
  loadAppEmpSearchNoModal$,
  loadUserAccess$,
  loadModuleAccess$,
  loadModuleAccessWithoutLoadingAppData$,
  loadRelatedClients$,
  loadAppData$,
  loadNOVAData$,
  runManualCleanup$,
  updateDestination$,
];
