import React, { ChangeEvent } from 'react';
import styles from './shared.module.scss';
import { InputGrp } from 'core/components/form-controls';

type SearchBarProps = {
  query: string;
  onSearch: (query: string) => void;
  showLabel?: boolean;
  placeholder?: string;
};

const SearchBar = ({ query, onSearch, showLabel = true, placeholder = 'Search' }: SearchBarProps) => {
  return (
    <div className="d-flex flex-column mr-3">
      {showLabel && (
        <label
          htmlFor="table-search"
          className={styles['top-label']}
        >
          Search
        </label>
      )}
      <InputGrp
        placeholder={placeholder}
        groupClass="m-0"
        inputClass={`gc20 ${styles['search-bar']}`}
        name="table-search"
        type="text"
        value={query}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
          onSearch(target.value);
        }}
      />
    </div>
  );
};

export default SearchBar;