import React from 'react';
import Modal from 'core/components/modals/Modal';
import { useAppSelector } from 'utilities/hooks';
import { PayrollValidationError } from './InsertPayroll.modal';

type Props = {
  show: boolean;
  onHide: (confirm: boolean) => void;
  validationError: PayrollValidationError;
};

const PayrollValidationErrorModal = ({ show, onHide, validationError }: Props) => {
  const isSuperUser = useAppSelector(({ auth }) => auth.userAccess?.superUser);

  return (
    <Modal
      title="Notice"
      show={show}
      onHide={() => onHide(false)}
    >
      <div className="d-flex flex-column">
        <span>{validationError.messages[0]}</span>
        <div className="d-flex justify-content-end">
          <button
            className={isSuperUser ? 'orange-outline-button-sm' : 'orange-button-sm' }           
            onClick={() => onHide(false)}
          >
            {isSuperUser ? 'Cancel' : 'Close'}
          </button>
          {isSuperUser && (
            <button
              className="orange-button-sm ml-2"            
              onClick={() => onHide(true)}
            >
              Okay
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PayrollValidationErrorModal;