import React from 'react';
import CheckBoxForm from '../../Shared/CheckBoxForm';
import { Col, Row } from 'react-bootstrap';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for Checkbox and Grid select forms
const EmployeeStatusPayFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  return (
    <Row>
      <Col sm={6}>
        <Row>
          {!!sectionFields?.length && (
            <Col xs={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'HR Pay Rates';
                })?.fields}
                section="HR Pay Rates"
                title="HR Pay Rates"
              />
            </Col>
          )}
        </Row>
      </Col>
      <Col sm={6}>
        <Row>
          {!!sectionFields?.length && (
            <Col xs={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'Pay Rates';
                })?.fields}
                section="Pay Rates"
                title="Pay Rates"
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default EmployeeStatusPayFields;