import { AgGridReact } from '@ag-grid-community/react';
import {
  AllModules,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowNode,
} from '@ag-grid-enterprise/all-modules';
import Icon from 'core/components/shared/Icon';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { agCheckboxEditor } from 'utilities/ag-grid-editors';
import { agPercentRenderer } from 'utilities/ag-grid-renderers';
import AGDeleteHeaderComponent from 'utilities/ag-grid-renderers/AGDeleteHeaderComponent';
import CheckboxRenderer from 'utilities/ag-grid-renderers/CheckboxRenderer';
import { useAppSelector } from 'utilities/hooks';
import { WCEffectiveDate, WCRate } from 'core/models';
import {
  createWCEffectiveDate,
  deleteWCRate,
  updateWCEffectiveDate,
} from 'core/store/actions';
import { getStates } from 'core/store/selectors';
import { ControlDatePickerGrp, InputGrp, SelectGrp } from 'core/components/form-controls';

const fs = {
  stateCode: {
    name: 'stateCode',
    label: 'STATE',
    groupClass: 'gc15 mw150',
  },
  effectiveDate: {
    name: 'effectiveDate',
    label: 'EFFECTIVE DATE',
    groupClass: 'gc12',
  },
  wcPolicyID: {
    name: 'wcPolicyID',
    label: 'WC POLICY ID',
    groupClass: 'gc11',
  },
  officerLimit: {
    name: 'officerLimit',
    label: 'OFFICER LIMIT',
    groupClass: 'gc10',
  },
};

type PropTypes = {
  item: WCEffectiveDate;
  onDelete: any;
};

const WorkCompRuleItem: React.FC<PropTypes> = ({ item, onDelete }) => {
  const dispatch = useDispatch();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [rates, setRates] = useState<WCRate[]>(
    item.rates.map((r) => {
      return {
        ...r,
        rate: r.rate * 100,
        delete: false,
      };
    }),
  );

  const { register, errors, setValue, handleSubmit, control, watch } =
    useForm<any>({ defaultValues: item });
  const effectiveDate = watch('effectiveDate');

  const clientNo = useAppSelector((state) => { return state.client.client!.clientNo; });
  const stateOpts = useSelector(getStates);

  const gridHeight =
    rates && rates.length ? 50 + rates.length * 26 + 'px' : '76px';

  const columns: ColDef[] = [
    {
      field: 'wcCode',
      headerName: 'WC CODE',
      editable: true,
      width: 90,
    },
    {
      field: 'description',
      headerName: 'DESCRIPTION',
      editable: true,
    },
    {
      field: 'rate',
      headerName: 'RATE',
      editable: true,
      cellRenderer: 'percentRenderer',
      cellRendererParams: {
        decimalPlaces: 4
      },
      cellStyle: { 'text-align': 'right' },
      width: 80,
    },
    {
      field: 'delete',
      headerComponentFramework: AGDeleteHeaderComponent,
      cellRenderer: 'checkBoxRenderer',
      cellStyle: { paddingLeft: '16px' },
      width: 60,
    },
  ];

  const gridOptions: GridOptions = {
    columnDefs: columns,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    // @ts-ignore
    components: {
      percentRenderer: agPercentRenderer,
      checkboxEditor: agCheckboxEditor,
    },
    // @ts-ignore
    frameworkComponents: {
      checkBoxRenderer: CheckboxRenderer,
    },
    stopEditingWhenCellsLoseFocus: true,
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const addWCCode = () => {
    setRates((prev: WCRate[]) => {
      return [
        ...prev,
        new WCRate(0, item.stateOfficerLimitID),
      ];
    });
  };

  const deleteWCCodes = () => {
    if (gridApi) {
      gridApi.forEachNode((node: RowNode) => {
        const rule = node.data;
        if (rule.delete) {
          rule.wcRuleID && dispatch(deleteWCRate(rule.wcRuleID));
          setRates((prev) => { return prev.filter((x) => { return x.wcRuleID !== rule.wcRuleID; }); },
          );
        }
      });
    }
  };

  const onSubmit = (data: any) => {
    const formattedRate = structuredClone(rates).map((r) => {
      return {
        ...r,
        rate: r.rate / 100,
        delete: false,
      };
    });

    const newWCEffectiveDate = new WCEffectiveDate(
      clientNo,
      item.stateOfficerLimitID,
      {
        ...data,
        rates: formattedRate,
      },
    );
    newWCEffectiveDate.stateOfficerLimitID
      ? dispatch(updateWCEffectiveDate(newWCEffectiveDate))
      : dispatch(createWCEffectiveDate(newWCEffectiveDate));
  };

  return (
    <>
      <div className="row">
        <div className="col-12 text-right">
          <button
            className="btn btn-link pb-0"
            onClick={addWCCode}
          >
            Add WC Code <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
          <button
            type="button"
            className="btn btn-link pb-0"
            onClick={deleteWCCodes}
          >
            Delete Selected WC Codes
            <Icon
              name="minus-circle"
              className="fa-minus-circle"
            />
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-row">
          <div
            className="d-flex flex-row flex-wrap"
            style={{ width: '50%' }}
          >
            <SelectGrp
              {...fs.stateCode}
              errors={errors.stateCode}
              options={stateOpts}
              ref={register}
            />
            <ControlDatePickerGrp
              {...fs.effectiveDate}
              value={effectiveDate}
              setValue={setValue}
              control={control}
            />
            <InputGrp
              {...fs.wcPolicyID}
              errors={errors.wcPolicyID}
              ref={register}
            />
            <InputGrp
              {...fs.officerLimit}
              errors={errors.officerLimit}
              ref={register}
            />
          </div>
          <div style={{
            width: '50%',
            height: gridHeight,
          }}
          >
            <div
              className="table-wrapper-wrapper ag-theme-balham"
              style={{ height: '100%' }}
            >
              <AgGridReact
                gridOptions={gridOptions}
                rowData={rates}
                modules={AllModules}
                onGridReady={onGridReady}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <button
              type="button"
              className="btn btn-link dm-grid-action-title mr-4"
              onClick={() => { return onDelete(item); }}
            >
              Delete this Rate{' '}
              <Icon
                name="minus-circle"
                className="fa-minus-circle"
              />
            </button>
            <button
              type="submit"
              className="btn btn-primary orange-button mt-2"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default WorkCompRuleItem;
