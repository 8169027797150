import { createReducer } from '@reduxjs/toolkit';
import { EmpI9 } from '../../models';
import { clearEmployeeI9DataReport, loadEmployeeI9, loadEmployeesI9, storeEmployeeI9, storeEmployeeI9DataReport, storeEmployeesI9 } from '../actions';

export interface State {
  loading: boolean;
  allEmployeeI9: EmpI9[];
  employeeI9: EmpI9;
  employeeI9DataReport: any;
}

const INITIAL_STATE = {
  loading: false,
  allEmployeeI9: [],
  employeeI9: null,
  employeeI9DataReport: null,
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadEmployeesI9.type]: (state) => {return { ...state, loading: true };},
  [storeEmployeesI9.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      allEmployeeI9: action.payload,
    };
  },
  [loadEmployeeI9.type]: (state) => {return { ...state, loading: true };},
  [storeEmployeeI9.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      employeeI9: action.payload,
    };
  },
  [storeEmployeeI9DataReport.type]:
		(state, action) => {
		  return {
		  ...state,
		  loading: false,
		  employeeI9DataReport: action.payload,
		  };
		},
  [clearEmployeeI9DataReport.type]:
		(state, action) => {
		  return {
		  ...state,
		  employeeI9DataReport: null,
		  };
		},
});
