import React from 'react';

type PropTypes = {
  text: string;
  id?: string;
  hasError: boolean;
  required?: boolean;
};

export const Label: React.FC<PropTypes> = ({ text, id, hasError, required }) => {
  let classes = 'form-label';
  required && (classes += ' required');
  hasError && (classes += ' text-danger');

  return (
    <label
      htmlFor={id}
      style={{
        display: 'block',
        marginTop: 'auto',
      }}
      className={classes}
    >
      {text}
    </label>
  );
};
