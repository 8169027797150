import { SelectGrp } from 'core/components/form-controls/SelectGrp';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { Client } from 'core/models';
import { OhioI29Request } from 'core/models/ContractorReports.model';
import { downloadBankDisbursementsReport } from 'core/store/actions';
import { clearOhioI29Report, downloadOhioI29Report } from 'core/store/slices/contractorReports.slice';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'utilities/hooks';

const monthOptions = [              //use name of month instead of numbers
  { description: '', id: '0' },
  { description: 'January', id: '1' },
  { description: 'February', id: '2' },
  { description: 'March', id: '3' },
  { description: 'April', id: '4' },
  { description: 'May', id: '5' },
  { description: 'June', id: '6' },
  { description: 'July', id: '7' },
  { description: 'August', id: '8' },
  { description: 'September', id: '9' },
  { description: 'October', id: '10' },
  { description: 'November', id: '11' },
  { description: 'December', id: '12' },
];

const OhioI29 = () => {

  const childRef: React.RefObject<any> = useRef();
  const dispatch = useDispatch();
  const client = useAppSelector((state) => {return state.client.client;}) as Client;
  const report = useAppSelector(
    (state) => {return state.contractorReports.ohioI29Report;},
  );
  const [years, setYears] = useState([{ label: '', description: '' }]);


  useEffect(() => {
    //Populate Dropdown with dates
    const yearDropdown = [];
    const currentYear = DateTime.fromJSDate(new Date()).year;

    for (let i = currentYear; i >= 2000; i--) {
      yearDropdown.push({
        label: `${i}`,
        description: `${i}`,
      });
    }
    setYears(yearDropdown);

  }, []);

  const { register, handleSubmit } =
        useForm<OhioI29Request>({
          defaultValues: {
            month: DateTime.fromJSDate(new Date()).month,
            year: DateTime.fromJSDate(new Date()).year,

          },
        });


  const downloadReport = (data: OhioI29Request) => {
    const submitData: OhioI29Request = {
      clientNo: client.clientNo,
      month: data.month,
      year: data.year,
    };
    dispatch(downloadOhioI29Report(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearOhioI29Report());
  };

  return (
    <>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title"> Ohio I-29 Report</div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div>
              <SelectGrp
                ref={register}
                name="month"
                options={monthOptions}
                label={'Month'}
              />

              <SelectGrp
                ref={register}
                name="year"
                options={years}
                label={'Year'}
              />
            </div>
            <div className="flex-grow-1 text-right">
              <button
                type="button"
                className="orange-outline-button mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="orange-button mr-2"

              >
                Run Report
              </button>
            </div>
          </div>
        </form>
      </div>
      {
        report ? <PDFViewerModal
          show={report.length > 0}
          pdfData={report}
          onHide={hidePdfViewer}
          reportName="OhioI-29"
        /> : null
      }
    </>

  );

};

export default OhioI29;