import React, { useState, CSSProperties, useContext } from 'react';
import tableStyles from 'core/components/shared/dm-table/_styles.module.scss';
import { CellProps } from 'core/components/shared/dm-table/types';
import { JobError } from './dummyData';
import Cell from 'core/components/shared/dm-table/Cell';
import { buildErrorRow } from './DiagnosticDetailItem';
import { DiagnosticContext } from './Diagnostics';
import { useEffectOnMount } from 'utilities/hooks';

type Props = {
  error: JobError;
  reviewAll: boolean;
};

const ErrorRow = ({ error, reviewAll }: Props) => {
  const [reviewed, setReviewed] = useState<boolean>(error.isReviewed);
  
  const { showReviewed } = useContext(DiagnosticContext);
  
  useEffectOnMount(() => {
    setReviewed(reviewAll);
  }, [reviewAll]);

  const row = buildErrorRow(error, { value: reviewed, update: setReviewed });
  
  // instead of worrying about re-rendering for this AND the error type change, just hide these rows with CSS!
  // TODO: update this to actually update the main state so it's not just local var check
  const hideToggleStyle: CSSProperties = {
    display: reviewed && !showReviewed ? 'none' : 'flex',
  };
  
  return (
    <div
      key={row.id}
      className={reviewed ? tableStyles['tr-reviewed'] : tableStyles.suppressed}
      style={hideToggleStyle}
      aria-hidden={reviewed && !showReviewed}
    >
      {row.cells.map((cell: CellProps, cellIndex: number) => (
        <Cell
          key={`(${row.id},${cellIndex})`}
          styleClass={cell.styleClass}
          position={cell.position}
          onClick={cell?.onClick}
        >
          {cell.children}
        </Cell>
      ))}
    </div>
  );
};

export default ErrorRow;