import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class EmpSelfService {
  empId = 0;

  protectedClientNo = '';

  protectedEmpNo = '';

  employerWantsSelfService = false;

  employeeWantsSelfService = false;

  printVoucher = false;

  employeeWantsVoucherNotification = false;

  myInfoUserName = '';

  myInfoEmail = '';

  myInfoLastLogin: Date | string | null = null;

  documents: Document[] = [];

  static readonly convProps: PropTypeLists = {
    numberProps: ['empId'],
    dateProps: ['myInfoLastLogin'],
    boolProps: ['employerWantsSelfService', 'employeeWantsSelfService', 'printVoucher', 'employeeWantsVoucherNotification'],
  };

  constructor(empId: string | number, protectedEmpNo: string, props?: { [key: string]: any }) {
    this.empId = (typeof empId === 'string') ? parseInt(empId) : empId;
    this.protectedEmpNo = protectedEmpNo;

    props && convToClass(this, props, EmpSelfService.convProps);
  }
}

export interface Document {
  docId: number;
  fileName: string;
  accepted: boolean;
  dateAccepted: string;
  docType: string;
}