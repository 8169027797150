import store from '../../core/store/store';

const getProtectedClientNo = (): string => {
  return store.getState().auth.currentProtectedClientNo;
};

const getClientNo = (): string => {
  // @ts-ignore
  return store.getState().client.client.clientNo;
};

const getMonthName = (month: number) => {
  let monthName = '';
  switch (month) {
    case 1:
      monthName = 'January';
      break;
    case 2:
      monthName = 'February';
      break;
    case 3:
      monthName = 'March';
      break;
    case 4:
      monthName = 'April';
      break;
    case 5:
      monthName = 'May';
      break;
    case 6:
      monthName = 'June';
      break;
    case 7:
      monthName = 'July';
      break;
    case 8:
      monthName = 'August';
      break;
    case 9:
      monthName = 'September';
      break;
    case 10:
      monthName = 'October';
      break;
    case 11:
      monthName = 'November';
      break;
    case 12:
      monthName = 'December';
      break;
    default:
      break;
  }
  return monthName;

}

//TODO: Add all types https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
const getMIMEType = (extension: string): string => {
  switch (extension) {
    case ".aac": return "audio/aac";
    case ".abw": return "application/x-abiword";
    case ".arc": return "application/octet-stream";
    case ".avi": return "video/x-msvideo";
    case ".bin": return "application/octet-stream";
    case ".css": return "text/css";
    case ".csv": return "text/csv";
    case ".doc": return "application/msword";
    case ".docx": return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case ".epub": return "application/epub+zip";
    case ".gif": return "image/gif";
    case ".html": return "text/html";
    case ".jpeg": return "image/jpeg";
    case ".jpg": return "image/jpeg";
    case ".json": return "application/json";
    case ".midi": return "audio/midi";
    case ".mpeg": return "video/mpeg";
    case ".odp": return "application/vnd.oasis.opendocument.presentation";
    case ".ods": return "application/vnd.oasis.opendocument.spreadsheet";
    case ".odt": return "application/vnd.oasis.opendocument.text";
    case ".png": return "image/png";
    case ".pdf": return "application/pdf";
    case ".ppt": return "application/vnd.ms-powerpoint";
    case ".pptx": return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    case ".rar": return "application/x-rar-compressed";
    case ".rtf": return "application/rtf";
    case ".svg": return "image/svg+xml";
    case ".tiff": return "image/tiff";
    case ".txt": return "text/plain";
    case ".vsd": return "application/vnd.visio";
    case ".xls": return "application/vnd.ms-excel";
    case ".xlsx": return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case ".xml": return "application/xml";
    case ".zip": return "application/zip";
    case ".7z": return "application/x-7z-compressed";

    default: return "N/A";
  }
}

const downloadBase64File = (
  contentType: string,
  fileContent: string,
  fileName: string,
) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = `data:${contentType};base64,${fileContent}`;
  downloadLink.download = fileName;
  downloadLink.click();
};

//Trying to improve on the above but do not want to break things;
const downloadFileFromBase64 = (fileName: string, fileContent: string) => {
  if (!fileName || !fileContent) return; 

  let extension = fileName.substring((fileName.lastIndexOf('.')));
  let mimeType = getMIMEType(extension);
  let downloadLink = document.createElement('a');
  downloadLink.href = `data:${mimeType};base64,${fileContent}`;
  downloadLink.download = fileName;
  downloadLink.click();
}

export const CommonService = {
  getProtectedClientNo,
  getClientNo,
  getMonthName,
  downloadBase64File,
  getMIMEType,
  downloadFileFromBase64
};
