import React from 'react';
import CheckBoxForm from '../../Shared/CheckBoxForm';
import { Col, Row } from 'react-bootstrap';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for Checkbox and Grid select forms
const EmployeeInfoFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  return (
    <Row>
      <Col sm={6}>
        <Row>
          {!!sectionFields?.length && (
            <Col sm={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'Employee Info';
                })?.fields?.filter(f => {
                  //TODO: Update this (and all other downloader sections) to read the 'Display' prop on the downloader field
                  return f.name !== 'Client Number' && f.name !== 'Employee Number' && f.name !== 'WeekEnd' && f.name !== 'CheckDate';
                })}
                section="Employee Info"
                title="Employee Information"
              />
            </Col>
          )}
          {!!sectionFields?.length && (
            <Col sm={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'Location and Title';
                })?.fields}
                section="Location and Title"
                title="Location and Title"
              />
            </Col>
          )}
        </Row>
      </Col>
      {!!sectionFields?.length && (
        <Col sm={6}>
          <CheckBoxForm
            formMethods={formMethods}
            dFields={sectionFields.find(f => {
              return f.name === 'Pay Rates';
            })?.fields}
            section="Pay Rates"
            title="Pay Rates"
          />
        </Col>
      )}
    </Row>
  );
};

export default EmployeeInfoFields;