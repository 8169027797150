import { createReducer } from '@reduxjs/toolkit';
import { VisaStatus } from '../../models';
import { loadVisaStatus, storeVisaStatus } from '../actions';

export interface State {
  loading: boolean;
  visaStatus: VisaStatus | null;
}

export const INITIAL_STATE: State = {
  loading: false,
  visaStatus: null,
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadVisaStatus.type]: (state, action) => {return { ...state, loading: true };},
  [storeVisaStatus.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      visaStatus: action.payload,
    };
  },
});