import React from 'react';
import Icon from './Icon';

type Props = {
  toggleSidebar: () => void;
};

const HamburgerMenu = ({ toggleSidebar }: Props) => {
  return (
    <button
      onClick={toggleSidebar}
      className="sidebar-nav-button"
      type="button"
    >
      <Icon
        name="bars"
        color="white"
      />
    </button>
  );
};

export default HamburgerMenu;