import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ScorecardCategory, ScorecardTrait } from 'core/models';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams } from '@ag-grid-community/core';
import { InputGrp } from 'core/components/form-controls';
import { postScorecardCategory } from 'core/store/slices/applicantTracking.slice';
import Modal from 'core/components/modals/Modal';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { cloneDeep } from 'lodash';
import { agSelectEditor } from 'utilities/ag-grid-editors';
import { agLookupRenderer } from 'utilities/ag-grid-renderers';

type Props = {
  show:boolean;  
  item: ScorecardCategory | undefined;
  onHide: ()=> void;
};

const ScorecardCategoryModal: React.FC<Props> = ({
  show,
  item,
  onHide,
}) => {

  const dispatch = useAppDispatch();
  const [client, scorecardTraits] = useAppSelector(
    (state) => {
      return [
        state.client.client,
        state.applicantTracking.scorecardTraits];
    },
  );
  const [rowData, setRowData] = useState<ScorecardTrait[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridDirty, setGridDirty] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const { register, reset, handleSubmit, getValues, formState:{ isDirty } } = useForm<ScorecardCategory>({
    defaultValues: { ...item,
      scorecardCategoryId: item?.scorecardCategoryId ?? 0,
      traits: item?.traits },
  });

  useEffect(() => {    
    item && reset(item);
    item && item.traits && setRowData(cloneDeep(item.traits));
    setGridDirty(false);
  }, [item]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const getGridData = () => {
    const items: any[] = [];
    gridApi?.forEachNode((node) => {
      items.push(node.data);
    });
    return items;
  };

  const onAdd = () =>{
    const { scorecardCategoryId } = getValues() as any;    
    const newScorecardTrait: ScorecardTrait = {
      hrEntityId: client?.hrEntityId ?? 0,
      scorecardTraitId: 0,
      scorecardCategoryId: scorecardCategoryId,
      trait: '',
      rates: [],
    };
    
    setRowData((prevState) => {
      return [ ...prevState,
        newScorecardTrait,
      ];
    });
    setGridDirty(true);
  };

  const onDeleteScorecardTrait = (params: ICellRendererParams) =>{
    setRowData((prevState) => {
      return prevState.filter(t => {return t.scorecardTraitId !== params.data.scorecardTraitId;});
    });
    setGridDirty(true);
  };

  const hideModal = () => {  
    if (isDirty || gridDirty) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };

  const onSubmit = (data: any) => {
   
    const sc:ScorecardCategory = item ? {
      ...item,        
      category: data.category,
      traits: getGridData() as ScorecardTrait[],
    } : {
      scorecardCategoryId: 0,
      hrEntityId: client?.hrEntityId ?? 0,
      category: data.category,
      traits: getGridData() as ScorecardTrait[],
    };

    dispatch(postScorecardCategory(sc));

  };
  
  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };
  
  const columns = useMemo<ColDef[]>(
    () => {
      return [
        {
          field: 'scorecardTraitId',
          headerName: '',
          editable: true,
          cellEditorParams: {
            options: scorecardTraits.map(t => {
              return {
                ...t,
                id: t.scorecardTraitId,
                description: t.trait,
              };
            }),
          },
          cellEditor: 'selectEditor',
          cellRendererParams: {
            options: scorecardTraits.map(t => {
              return {
                ...t,
                id: t.scorecardTraitId,
                description: t.trait,
              };
            }),
          },
          cellRenderer: 'lookupRenderer',
          width: 450,
        },
        {
          field: 'delete',
          cellRenderer: (params: ICellRendererParams) => {
             
            return (
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={() => { return onDeleteScorecardTrait(params); }
                }
              >
                <Icon
                  name="minus-circle"
                  className="fa-minus-circle"
                />
              </button>             
            );
        
          },
          width: 70,
        },
      ];
    },
    [],
  );

  const gridOptions: GridOptions = {
    stopEditingWhenCellsLoseFocus:true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },  
    components: {
      lookupRenderer: agLookupRenderer,
      selectEditor: agSelectEditor,
    },
  };

  return (
    
    <Modal
      show={show}
      onHide={hideModal}
      dialogClassName="lg" 
      isDirty={isDirty || gridDirty}     
      backdrop={'static'}
      title="Scorecard Category"
    >     
     
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="d-flex flex-column flex-grow-1"
        >  
          <input
            type="hidden"
            name={'scorecardCategoryId'}          
            ref={register({ valueAsNumber: true })}
          />      
          <div>
            <InputGrp
              label={'Category Name'}
              name={'category'}
              defaultValue={item?.category}
              ref={register}
            />
          </div>         
        </div>
        <div className="d-flex flex-row justify-content-end">          
          <button
            type="button"
            className="btn btn-link dm-grid-action-title pb-0 mr-2"
            onClick={onAdd}
          >
            Add Trait{' '}
            <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </div>
        <div className="d-flex mb-3">             
          <div className="table-wrapper-wrapper ag-theme-balham">
            <AgGridReact
              gridOptions={gridOptions}
              rowData={rowData}
              columnDefs={columns}
              onGridReady={onGridReady}
            />
          </div>
        </div>
        <div className="d-flex mt-3">
          <button
            type="button"
            className="orange-outline-button ml-auto mr-2"
            onClick={hideModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="orange-button mr-2"
            disabled={!(isDirty || gridDirty)}
          >
            Save
          </button>
        </div>
      </form>
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
    </Modal>
  );
};
export default ScorecardCategoryModal;
