import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonService } from 'core/services';
import { createContractorFringeMap, downloadContractorFringeData, getContractorFringeData, updateContractorFringeMap } from 'core/store/actions/ contractor-reports/fringe.action';
import { Files } from 'enums/Files';
import { ICodeDescOption, IOption } from 'interfaces/IOption';

export type FileTypes = 'csv' | 'xlsx' | 'xls';

export interface IData {
  payRates: string[];
  fringeEarnings: ICodeDescOption[];
  employeePaidFringes: ICodeDescOption[];
  employerPaidFringes: ICodeDescOption[];
  areas: ICodeDescOption[];
  areaTrades: ICodeDescOption[];
  areaTradeSubs: ICodeDescOption[];
  jobNumbers: ICodeDescOption[];
  subNumbers: ICodeDescOption[];
  maps: any[];
  hoursWagesOptions: IOption[];
}

export interface ContractorFringeState {
  isAdding: boolean;
  isUpdating: boolean;
  isAddUpdateSending: boolean;
  isLoading: boolean,
  isDownloading: boolean;
  data?: IData | null;
  areaSelected?: string | null;
  areaValue?: IOption[];
  areaTradeValue?: IOption[];
  areaTradeSubValue?: IOption[];
  subNumbers: IOption[];
  jobNumbers: IOption[];
  beginDate?: string | null;
  endDate?: string | null;
  payRatesSelected: boolean;
  payRates?: IOption[];
  fringeEarning: IOption[];
  fileType: FileTypes;
  byOption: string;
  hoursSelected: boolean;
  hours: IOption[];
  hoursTotal: boolean;
  hoursDetail: boolean;
  wagesSelected: boolean;
  wages: IOption[];
  wagesTotal: boolean;
  wagesDetail: boolean;
  mapDescription: string;
  mapKey?: string | null;
  showDescription: boolean;
  fringeEarningsSelected: boolean;
  fringeEarningsTotal: boolean;
  fringeEarningsDetail: boolean;
  employerFringeEarningSelected: boolean;
  employerFringeEarning: IOption[];
  employerFringeEarningTotal: boolean;
  employerFringeEarningDetail: boolean;
  employeeFringeEarningSelected: boolean;
  employeeFringeEarning: IOption[];
  employeeFringeEarningTotal: boolean;
  employeeFringeEarningDetail: boolean;
  employerPaidTaxes: boolean;
  employerPaidTaxesTotal: boolean;
  employerPaidTaxesDetail: boolean;
  projectNumber: boolean;
  phase: boolean;
  foExtra: boolean;
  costCode: boolean;
  jobSubTotals: boolean;
  employeeSubTotals: boolean;
  jobName: boolean;
  city: boolean;
  country: boolean;
  state: boolean;
  checkDate: boolean;
  weekEndingDate: boolean;
  dayWorked: boolean;
}

const initialState: ContractorFringeState = {
  isAdding: false,
  isUpdating: false,
  isAddUpdateSending: false,
  isLoading: false,
  isDownloading: false,
  showDescription: false,
  areaSelected: null,
  areaValue: [],
  areaTradeValue: [],
  areaTradeSubValue: [],
  subNumbers: [],
  jobNumbers: [],
  hoursSelected: false,
  hours: [],
  hoursTotal: false,
  hoursDetail: false,
  wagesSelected: false,
  wages: [],
  wagesTotal: false,
  wagesDetail: false,
  data: null,
  beginDate: null,
  endDate: null,
  payRatesSelected: false,
  payRates: [],
  fringeEarningsSelected: false,
  fringeEarning: [],
  fringeEarningsTotal: false,
  fringeEarningsDetail: false,
  employerFringeEarning: [],
  employerFringeEarningTotal: false,
  employerFringeEarningDetail: false,
  employerFringeEarningSelected: false,
  employeeFringeEarning: [],
  employeeFringeEarningTotal: false,
  employeeFringeEarningDetail: false,
  employeeFringeEarningSelected: false,
  employerPaidTaxes: false,
  employerPaidTaxesTotal: false,
  employerPaidTaxesDetail: false,
  projectNumber: false,
  phase: false,
  foExtra: false,
  costCode: false,
  jobSubTotals: false,
  employeeSubTotals: false,
  jobName: false,
  city: false,
  country: false,
  state: false,
  fileType: 'csv',
  byOption: 'byCheckDate',
  mapDescription: '',
  mapKey: null,
  checkDate: false,
  weekEndingDate: false,
  dayWorked: false,
};

export const contractorFringeOverviewSlice = createSlice({
  name: 'contractor-fringe-overview',
  initialState,
  reducers: {
    removeAreasSelected(state) {
      state.areaSelected = null;
      state.areaValue = [];
      state.areaTradeValue = [];
      state.areaTradeSubValue = [];
    },
    setFullDetails(state, action) {
      state.areaSelected = null;
      state.areaValue = [];
      state.areaTradeValue = [];
      state.areaTradeSubValue = [];

      const hoursValue = action.payload.hoursCodes.filter((item: string) => {
        return item;
      }).map((item: string) => {
        return state?.data?.hoursWagesOptions.find((option: IOption) => {
          return option.value === item;
        });
      });

      const wagesValue = action.payload.wagesCodes.filter((item: string) => {
        return item;
      }).map((item: string) => {
        return state?.data?.hoursWagesOptions.find((option: IOption) => {
          return option.value === item;
        });
      });

      const payRatesValue = action.payload.payRates.filter((item: string) => {
        return item;
      }).map((item: string) => {
        return {
          label: item,
          value: item,
        };
      });

      const areaValues = action.payload.atsSelected.filter((item: string) => {
        return item;
      });

      if (action.payload.area) {
        state.areaSelected = 'areas';
        state.areaValue = areaValues.map((item: string) => {
          const foundItem = state?.data?.areas.find((option: ICodeDescOption) => {
            return option.code === item;
          });

          return {
            label: foundItem?.description || foundItem?.code,
            value: foundItem?.code,
          };
        });
      }

      if (action.payload.areaTrade) {
        state.areaSelected = 'areaTrades';
        state.areaTradeValue = areaValues.map((item: string) => {
          const foundItem = state?.data?.areaTrades.find((option: ICodeDescOption) => {
            return option.code === item;
          });

          return {
            label: foundItem?.description || foundItem?.code,
            value: foundItem?.code,
          };
        });
      }

      if (action.payload.areaTradeSub) {
        state.areaSelected = 'areaTradeSubs';
        state.areaTradeSubValue = areaValues.map((item: string) => {
          const foundItem = state?.data?.areaTradeSubs.find((option: ICodeDescOption) => {
            return option.code === item;
          });

          return {
            label: foundItem?.description || foundItem?.code,
            value: foundItem?.code,
          };
        });
      }

      const subNumbersValue = action.payload.subNumbers.filter((item: string) => {
        return item;
      }).map((item: string) => {
        const foundItem = state?.data?.subNumbers.find((option: ICodeDescOption) => {
          return item === option.code;
        });

        return {
          label: foundItem?.description?.trim() || foundItem?.code,
          value: foundItem?.code,
        };
      });

      const jobNumbersValue = action.payload.jobNumbers.filter((item: string) => {
        return item;
      }).map((item: string) => {
        const foundItem = state?.data?.jobNumbers.find((option: ICodeDescOption) => {
          return item === option.code;
        });

        return {
          label: foundItem?.description?.trim() || foundItem?.code,
          value: foundItem?.code,
        };
      });

      const fringeEarningsValue = action.payload.fringeEarningsCodes.filter((item: string | ICodeDescOption) => {
        return item;
      }).map((item: string) => {
        return {
          label: item,
          value: item,
        };
      }).filter((item: IOption) => {
        return !!item.value;
      });

      const employerPaidFringesValue = action.payload.employerPaidFringes.filter((item: string) => {
        return item;
      }).map((item: string) => {
        return {
          label: item,
          value: item,
        };
      }).filter((item: IOption) => {
        return !!item.value;
      });

      const employeePaidFringesValue = action.payload.employeePaidFringes.filter((item: string) => {
        return item;
      }).map((item: string) => {
        return {
          label: item,
          value: item,
        };
      }).filter((item: IOption) => {
        return !!item.value;
      });

      state.mapKey = action.payload?.mapKey;
      state.mapDescription = action.payload?.mapName;
      state.showDescription = action.payload?.showATSDescription;
      state.subNumbers = subNumbersValue;
      state.jobNumbers = jobNumbersValue;
      state.hoursSelected = !!hoursValue.length;
      state.hours = hoursValue;
      state.hoursTotal = action.payload.hoursTotal;
      state.hoursDetail = action.payload.hoursDetail;
      state.wagesSelected = !!wagesValue.length;
      state.wages = wagesValue;
      state.wagesTotal = action.payload.wagesTotal;
      state.wagesDetail = action.payload.wagesDetail;
      state.payRatesSelected = !!payRatesValue.length;
      state.payRates = payRatesValue;
      state.fringeEarningsSelected = !!fringeEarningsValue.length;
      state.fringeEarning = fringeEarningsValue;
      state.fringeEarningsTotal = action.payload.fringeEarningsTotal;
      state.fringeEarningsDetail = action.payload.fringeEarningsDetail;
      state.employerFringeEarning = employerPaidFringesValue;
      state.employerFringeEarningTotal = action.payload.employerPaidFringesTotal;
      state.employerFringeEarningDetail = action.payload.employerPaidFringesTotal;
      state.employerFringeEarningSelected = !!employerPaidFringesValue.length;
      state.fringeEarningsSelected = !!fringeEarningsValue.length;
      state.fringeEarning = fringeEarningsValue;
      state.fringeEarningsTotal = action.payload.fringeEarningsTotal;
      state.fringeEarningsDetail = action.payload.fringeEarningsDetail;
      state.employeeFringeEarning = employeePaidFringesValue;
      state.employeeFringeEarningTotal = action.payload.employeePaidFringesTotal;
      state.employeeFringeEarningDetail = action.payload.employeePaidFringesDetail;
      state.employeeFringeEarningSelected = !!employeePaidFringesValue.length;
      state.employerPaidTaxes = action.payload.employerPaidTaxes;
      state.employerPaidTaxesTotal = action.payload.employerPaidTaxesTotal;
      state.employerPaidTaxesDetail = action.payload.employerPaidTaxesDetail;
      state.projectNumber = action.payload.projectNumber;
      state.phase = action.payload.phase;
      state.foExtra = action.payload.foExtra;
      state.costCode = action.payload.costCode;
      state.byOption = action.payload.byOption;
      state.weekEndingDate = action.payload.weekEndingDate;
      state.checkDate = action.payload.checkDate;
      state.dayWorked = action.payload.dayWorked;
      state.jobSubTotals = action.payload.jobSubtotals;
      state.employeeSubTotals = action.payload.employeeSubtotals;
    },
    setKey(state, action: PayloadAction<{ key: string; value: any }>) {
      // @ts-ignore
      state[action.payload.key] = action.payload.value;
    },
    setIsUpdating(state, action) {
      state.isUpdating = action.payload;
    },
    setHoursSelected(state, action) {
      state.hoursSelected = action.payload;
    },
    setHoursCode(state, action) {
      state.hours = action.payload;
    },
    setHoursTotal(state, action) {
      state.hoursTotal = action.payload;
    },
    setHoursDetail(state, action) {
      state.hoursDetail = action.payload;
    },
    setWagesSelected(state, action) {
      state.wagesSelected = action.payload;
    },
    setWagesCode(state, action) {
      state.wages = action.payload;
    },
    setWagesTotal(state, action) {
      state.wagesTotal = action.payload;
    },
    setWagesDetail(state, action) {
      state.wagesDetail = action.payload;
    },
    setContractorFringeDownload(state: ContractorFringeState, action: PayloadAction<string[]>) {
      const [beginDate, endDate] = action.payload;
      state.beginDate = beginDate;
      state.endDate = endDate;
    },
    setAreaSelected(state: ContractorFringeState, action: PayloadAction<string>) {
      state.areaSelected = action.payload;

      if (action.payload === 'areas') {
        state.areaTradeValue = [];
        state.areaTradeSubValue = [];
        state.areaValue = [{
          label: '<All>',
          value: '<All>',
        }];

        return;
      }

      if (action.payload === 'areaTrades') {
        state.areaValue = [];
        state.areaTradeSubValue = [];
        state.areaTradeValue = [{
          label: '<All>',
          value: '<All>',
        }];

        return;
      }

      state.areaValue = [];
      state.areaTradeValue = [];
      state.areaTradeSubValue = [{
        label: '<All>',
        value: '<All>',
      }];
    },
    setAreaValue(state, action) {
      state.areaValue = action.payload;
    },
    setIsAdding(state, action) {
      state.isAdding = action.payload;
    },
    setMapDescription(state, action) {
      state.mapDescription = action.payload;
    },
    setAreaTradeValue(state, action) {
      state.areaTradeValue = action.payload;
    },
    setAreaTradeSubValue(state, action) {
      state.areaTradeSubValue = action.payload;
    },
    setSubNumbers(state, action) {
      state.subNumbers = action.payload;
    },
    setJobNumbers(state, action) {
      state.jobNumbers = action.payload;
    },
    setPayRatesSelected(state, action) {
      state.payRatesSelected = action.payload;
    },
    setPayRates(state, action) {
      state.payRates = action.payload;
    },
    setFileType(state: ContractorFringeState, action: PayloadAction<FileTypes>) {
      state.fileType = action.payload;
    },
    setFringeEarning(state, action) {
      state.fringeEarning = action.payload;
    },
    setEmployerFringeEarning(state, action) {
      state.employerFringeEarning = action.payload;
    },
    setEmployeeFringeEarning(state, action) {
      state.employeeFringeEarning = action.payload;
    },
    setByOption(state, action) {
      state.byOption = action.payload;
    },
    setMapKey(state, action) {
      state.mapKey = action.payload;
    },
    reset(state) {
      return {
        ...initialState,
        data: state.data,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContractorFringeData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContractorFringeData.fulfilled, (state, action) => {
        state.isLoading = false;

        state.data = action.payload as any;
      })
      .addCase(getContractorFringeData.rejected, (state) => {
        state.isLoading = false;
        // create notification here
      });

    builder
      .addCase(downloadContractorFringeData.pending, (state) => {
        state.isDownloading = true;
      })
      .addCase(downloadContractorFringeData.fulfilled, (state, action) => {
        state.isDownloading = false;

        if (!action?.payload?.value) {
          return;
        }

        CommonService.downloadBase64File(
          `application/${state.fileType}`,
          action?.payload?.value,
          `contractor-overview-download${state.fileType === Files.XLS ? '.xlsx' : '.csv'}`,
        );
      })
      .addCase(downloadContractorFringeData.rejected, (state) => {
        state.isDownloading = false;
        // create notification here
      });

    
    builder
      .addCase(createContractorFringeMap.pending, (state) => {
        state.isAddUpdateSending = true;
      })
      .addCase(createContractorFringeMap.fulfilled, (state) => {
        state.isAddUpdateSending = false;
        state.isAdding = false;
      })
      .addCase(createContractorFringeMap.rejected, (state) => {
        state.isAddUpdateSending = false;
        // create notification here
      });

    
    builder
      .addCase(updateContractorFringeMap.pending, (state) => {
        state.isAddUpdateSending = true;
      })
      .addCase(updateContractorFringeMap.fulfilled, (state) => {
        state.isAddUpdateSending = false;
        state.isUpdating = false;
      })
      .addCase(updateContractorFringeMap.rejected, (state) => {
        state.isAddUpdateSending = false;
        // create notification here
      });
  },
});

export const { 
  removeAreasSelected,
  setHoursSelected,
  setHoursCode,
  setHoursTotal,
  setHoursDetail,
  setWagesSelected,
  setWagesCode,
  setWagesTotal,
  setWagesDetail,
  setAreaSelected, 
  setIsAdding, 
  setMapDescription, 
  setAreaValue, 
  setAreaTradeValue, 
  setAreaTradeSubValue, 
  setContractorFringeDownload, 
  setFileType, 
  setSubNumbers, 
  setJobNumbers, 
  setPayRatesSelected,
  setPayRates, 
  setFringeEarning, 
  setEmployerFringeEarning, 
  setEmployeeFringeEarning, 
  setByOption, 
  setMapKey,
  setIsUpdating,
  setFullDetails,
  setKey,
  reset,
} = contractorFringeOverviewSlice.actions;

export default contractorFringeOverviewSlice.reducer;