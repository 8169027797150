import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import EmployeeTransferModal from 'core/components/modals/emp-transfer/EmpTransfer.modal';
import { clearSelectedChanges, storeEmployeeDeleteResult } from 'core/store/actions';
import EmployeeList from '../employee/EmployeeList';
import { useAppSelector } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';
import EmployeeDeleteConfirmationModal from './EmployeeDeleteConfirmation.modal';
import AlertModal from 'core/components/modals/alert-modal.modal';
import { getYesNoCMOption } from 'core/store/selectors';
import { useBreakpoint } from 'utilities/BreakpointProvider';

const navigatorAfterSuccess = 'Employee Successfully Deleted\n\nThe employee’s information has been removed from the payroll system.\n' + 
                              'You must log into your Employee Navigator portal to manually remove the employee from their system.';

const EmployeeMasterListPage: React.FC = () => {
  const [show, setShow] = useState(false);
  const [showConfirmations, setShowConfirmations] = useState(false);

  const dispatch = useDispatch();

  const currentUser = useAppSelector((state) => { return state?.auth?.userAccess?.dmUserName; });
  const canTransferUsers = ['kp3286', 'gw3190', 'jr3103', 'mk3283', 'js3155'];
  const selectedEmployee = useAppSelector((state) => { return state?.selEmployee.employee; });
  const deleteResult = useAppSelector((state) => { return state?.selEmployee.deleteResult; });
  const canDeleteEmployee = useSelector(getYesNoCMOption(1428));
  
  const breakpoints = useBreakpoint();
  
  useEffect(() => {
    dispatch(
      clearSelectedChanges({
        directDepositChange: null,
        employeeChanges: [],
        taxChanges: [],
      }),
    );
  }, []);

  const onAlertHide = () => {
    dispatch(storeEmployeeDeleteResult(null));
  };

  return (
    <div className="col">     
      {show && (
        <EmployeeTransferModal
          show={show}
          onHide={() => {return setShow(false);}}
        />
      )}
      <Row>
        <Col xs={12}>
          <div className="d-flex flex-wrap">
            <div className="pr-5">
              <div className="dm-page-title">
                Employee Master List
              </div>
            </div>
            {canTransferUsers.includes(currentUser?.toLowerCase() || '') ? (
              <div className="mt-auto pr-3 mb-1 mb-sm-0">
                <Button
                  variant="link"
                  onClick={() => {return setShow(true);}}
                  className="dm-grid-action-title mb-0 pb-1"
                >
                  Transfer Employee
                  <Icon name="exchange" />
                </Button>
              </div>
            ) : null}
            {breakpoints.md ? null : (
              <div className="mt-auto pr-3 mb-1 mb-sm-0">
                <Button
                  variant="link"
                  disabled={!(canDeleteEmployee && selectedEmployee)}
                  onClick={() => { setShowConfirmations(true); }}
                  className="dm-grid-action-title mb-0 pb-1"
                >
                  Delete Employee
                  <Icon
                    name="minus-circle"
                    className="fa-minus-circle"
                  />
                </Button>
              </div>
            )}
            <div
              className="ml-0 ml-sm-auto pb-2"
              style={{ alignSelf: 'flex-end' }}
            ></div>
          </div>
          <hr className="dm-page-hr" />
        </Col>
        <Col xs={12}>
          <EmployeeList />
        </Col>
      </Row>
      {showConfirmations && (
        <EmployeeDeleteConfirmationModal
          selectedEmployee={selectedEmployee}
          deleteFromDetail={false}
          setShowConfirmations = {setShowConfirmations}
        />
      )}
      <AlertModal
        title = {(deleteResult?.isError) ? 'ERROR' : 'SUCCESS'}
        message = {(!deleteResult?.isError && (selectedEmployee?.hasNavigation || false)) ? navigatorAfterSuccess : deleteResult?.message}
        btnText = {'OK'}
        show = {!!deleteResult && (deleteResult.message !== undefined)}
        onHide={onAlertHide}
      />
    </div>
  );
};

export default EmployeeMasterListPage;
