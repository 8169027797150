import { ICellRendererParams } from '@ag-grid-community/core';
import Icon from 'core/components/shared/Icon';
import React, { Component } from 'react';

interface ParamTypes extends ICellRendererParams {
  label: string;
  clickHandler: (e: any) => void;
}

export default class agButtonRendererComponent extends Component {
  constructor(public props: ParamTypes) {
    super(props);
  }

  componentDidMount() { }

  refresh(params: ParamTypes) { }

  render() {
    const onClick = (e: any) => {
      e.stopPropagation();
      this.props.clickHandler(this.props.data);
    };
    return (
      <button
        type="button"
        className="btn btn-link btn-block p-0"
        onClick={onClick}
      >
        {this.props.label ? this.props.label + ' ' : ''}        
      </button>
    );
  }
}
