import React from 'react';
import { Accordion } from 'react-bootstrap';
import Icon from 'core/components/shared/Icon';

export interface IFolder {
  folders: IFolder[];
  noMargin?: boolean;
  clientNo?: number;
  fileCount?: number;
  name: string;
  parentName: string;
  path: string;
}

interface IFolderList {
  folders: IFolder[];
  activeFolder: string;
  setActiveFolder(path: string): void;
  setActiveParent(parentName: string): void;
  setActiveFolderName(name: string): void;
  defaultActiveKey?: string;
}

const FolderList = ({ folders, activeFolder, setActiveFolder, setActiveParent, setActiveFolderName, defaultActiveKey }: IFolderList) => {
  return (
    <div>
      {folders?.map((item: IFolder) => {
        return (
          <Accordion
            key={item.name}
            className={!item?.noMargin ? 'ml-4' : ''}
            defaultActiveKey={defaultActiveKey}
          >
            <Accordion.Toggle
              style={{
                background: 'none',
                border: 'none',
              }}
              className="text-left"
              onClick={() => {
                setActiveFolder(item.path);
                setActiveFolderName(item.name);
                setActiveParent(item.parentName);
              }}
              eventKey={item.name}
            >
              <div
                className="d-flex align-items-center"
                style={{
                  gap: '0.5rem',
                }}
              >
                <Icon name="folder" />
                {item.name}
              </div>
            </Accordion.Toggle>
            {!!item?.folders?.length && (
              <Accordion.Collapse eventKey={item.name}>
                <FolderList
                  folders={item?.folders}
                  activeFolder={activeFolder}
                  setActiveFolder={setActiveFolder}
                  setActiveParent={setActiveParent}
                  setActiveFolderName={setActiveFolderName}
                />
              </Accordion.Collapse>
            )}
          </Accordion>
        );
      })}
    </div>
  );
};

export default FolderList;