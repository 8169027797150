import React from 'react';
import { InputGrp } from 'core/components/form-controls';
import { currencyFormatter, formatWithCommas } from 'utilities/utilities';

const employeeEarningsDeductions = [0, 1, 2];
const employeeTaxes = [0, 1, 2, 3, 4, 5, 6];
const employerTaxes = [0, 1, 2, 3];

const TimeCardSummaryFields = () => {
  return (
    <>
      <div className="row font-weight-bold mb-3">
        <div className="col">Earnings/Deductions</div>    
        <div className="col-3">Amount</div>    
        <div className="col-3">Gross</div>    
      </div>
      {employeeEarningsDeductions.map((x) => {
        return (
          <div
            className="row font-weight-bold"
            key={x}
          >
            <div className="col">[Total description]</div>
            <div className="col-3 text-right">{currencyFormatter(0)}</div>
            <div className="col-3 text-right">{currencyFormatter(0)}</div>
          </div>
        );
      })}
      <div className="mt-3">
        <span className="font-weight-bold">W/H Taxes</span>
        {employeeTaxes.map((x) => {
          return (
            <div
              className="row font-weight-bold"
              key={x}
            >
              <div className="col ml-2">[Tax description]</div>
              <div className="col-3">
                <InputGrp
                  name={`tax-amount-${x}`}
                  groupClass="number-underline"
                  value={formatWithCommas(0)}
                />
              </div>
              <div className="col-3">
                <InputGrp
                  name={`tax-gross-${x}`}
                  groupClass="number-underline"
                  value={formatWithCommas(0)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-3">
        <span className="font-weight-bold">Employer Tax</span>
        {employerTaxes.map((x) => {
          return (
            <div
              className="row font-weight-bold"
              key={x}
            >
              <div className="col ml-2">[Tax description]</div>
              <div className="col-3">
                <InputGrp
                  name={`tax-amount-${x}`}
                  groupClass="number-underline"
                  value={formatWithCommas(0)}
                />
              </div>
              <div className="col-3">
                <InputGrp
                  name={`tax-gross-${x}`}
                  groupClass="number-underline"
                  value={formatWithCommas(0)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TimeCardSummaryFields;