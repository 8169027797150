import { PayRateDropdown, TransmittalCheck, TransmittalEarning, TransmittalEmployee } from 'core/models/Payroll.model';
import { useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import { getEarningCodes } from 'core/store/selectors/earning-code.selector';
import { EarningCode } from 'core/models/EarningCode.model';
import { DeepMap, FieldError, useFieldArray, useWatch } from 'react-hook-form';
import TransmittalEarningsItem from './TransmittalEarningsItem';
import Icon from 'core/components/shared/Icon';
import { getEmployeeFromEmpNo, getShiftPremiums, getYesNoCMOption } from 'core/store/selectors';
import { FormMethods } from 'core/components/form-controls/types';
import { useAppSelector } from 'utilities/hooks';
import { ManualChangeArgs } from './CheckItem';
import { cleanAmount, parseFloatNumberWithCommas } from 'utilities/utilities';
import { convertPayRate } from 'utilities/payRateUtilities';
import { FormContext } from './FormContext';
import { ShiftPremium } from 'core/models/ShiftPremium.model';
import { Employee } from 'core/models';

const buildNewEarning = (
  payRate: PayRateDropdown,
  autofillShiftCode: boolean,
  empShiftPremium: ShiftPremium | undefined,
  employee: Employee | undefined,
  id: number,
): Partial<TransmittalEarning> => {
  if (payRate.tranDigit === 2 && empShiftPremium) {
    const shiftPremiumId = autofillShiftCode && empShiftPremium?.shiftPremiumId ? empShiftPremium.shiftPremiumId : null;
    // if we have a shift premium without an earnings code, use the altRate
    const altRateAmt = (autofillShiftCode && !empShiftPremium?.earningsCode?.trim()?.length)
      ? (empShiftPremium?.amount ?? 0) + parseFloat(cleanAmount(payRate.hourlyDescription))
      : 0;
    
    return {
      amount: 0,
      tracked: false,
      altRate: altRateAmt,
      autoCalcResultId: null,
      shiftPremiumId: shiftPremiumId,
      dept: employee?.dept ?? 0,
      subDept: employee?.subDept ?? 0,
      subDept2: employee?.subDept2 ?? 0,
      rateId: altRateAmt > 0 ? 0 : payRate.rateId,
      tranDigit: payRate.tranDigit,
      transmittalEarningsId: id,
    };
  } else {
    return {
      amount: 0,
      tracked: false,
      altRate: 0,
      autoCalcResultId: null,
      shiftPremiumId: null,
      dept: employee?.dept ?? 0,
      subDept: employee?.subDept ?? 0,
      subDept2: employee?.subDept2 ?? 0,
      rateId: payRate.rateId,
      tranDigit: payRate.tranDigit,
      transmittalEarningsId: id,
    };
  }
};

type PropTypes = {
  checkIndex: number;
  checkId: number | undefined;
  empNo: number;
  index: number;
  errors: FormMethods['errors'];
  register: FormMethods['register'];
  watch: FormMethods['watch'];
  setValue: FormMethods['setValue'];
  getValues: FormMethods['getValues'];
  setError: FormMethods['setError'];
  formStateErrors: DeepMap<TransmittalCheck, FieldError>;
  control: any;
  payRateDropdown: PayRateDropdown[];
  isReadOnly: boolean;
  employeeShiftPremiumId?: number | null;
  saveChanges: (unregisteredInput?: boolean, args?: ManualChangeArgs) => Promise<any> | undefined;
};

const TransmittalEarningsItemsList: React.FC<PropTypes> = ({
  index,
  checkIndex,
  checkId,
  empNo,
  errors,
  register,
  watch,
  setValue,
  getValues,
  setError,
  formStateErrors,
  control,
  payRateDropdown,
  isReadOnly,
  employeeShiftPremiumId,
  saveChanges,
}) => {
  const clientOneHourRate = useAppSelector(state => {return state?.client?.clientOptions?.options?.[274]?.optionValue;});
  const clientFrequency = useAppSelector(state => {return state?.client?.clientOptions?.options?.[10]?.optionValue;});
  const employee =  useSelector(getEmployeeFromEmpNo(empNo));
  const earningCodeSettings = useSelector(getEarningCodes);
  const { mostRecentlyUpdatedCheck } = useAppSelector(({ payroll }) => payroll);
  const clientShiftPremiums = useSelector(getShiftPremiums);
  const clientAutoFillsShiftCodes = useSelector(getYesNoCMOption(357));
    
  const { dirtyCheck, updateActiveElement } = useContext(FormContext);
  
  const { fields, remove, append } = useFieldArray<TransmittalEarning>({
    control,
    name: 'earnings',
  });
  
  
  const [total, setTotal] = useState(0);

  const otherItems = useWatch<TransmittalEarning[]>({
    control,
    name: 'earnings',
  });

  const totalAmount = () => {
    if (otherItems) {
      let total = 0;
      otherItems?.forEach((e) => {
        if (e.tracked?.toString() === 'true') {
          total += 0;
          return;
        }
        const rate = payRateDropdown?.find((p) => {
          return p.rateId === e.rateId;
        }) as PayRateDropdown;
        const ecSetting = earningCodeSettings.find((es) => {
          return es.earnCode === e.earningsCode;
        }) as EarningCode;
        
        if (e?.altRate > 0) {
          //Salary 1 1/2 and double hours for an alt rate get translated to use an hourly rate. PI-8708
          if (e.tranDigit === 1 && (e.earningsCode === '2' || e.earningsCode === '3')) {
            const hourlyAmount = convertPayRate(e.altRate, 'salaryRate', 'hourlyRate', clientOneHourRate, clientFrequency, employee?.payPeriod ?? '');
            total += (e.amount || 0) * hourlyAmount * (ecSetting?.premiumFactor ?? 0);
          } else {
            total += (e.amount || 0) * e.altRate * (ecSetting?.premiumFactor ?? 0);
          }
        } else if ((e?.rateId ?? 0) > 0 && rate && rate.hourlyDescription) {
          //For earningsCode 2 or 3 we want to use the hourlyDescription even if the tranDigit is 1 -PI-8226
          if (e.tranDigit === 1 && e.earningsCode !== '2' && e.earningsCode !== '3') {
            total += (e.amount || 0) * parseFloatNumberWithCommas(rate.salaryDescription) * (ecSetting?.premiumFactor ?? 1);
          } else if (e.tranDigit === 2 || e.tranDigit === 0 || (e.earningsCode === '2' || e.earningsCode === '3')) {
            total += (e.amount || 0) * parseFloatNumberWithCommas(rate.hourlyDescription) * (ecSetting?.premiumFactor ?? 1);
          }
          //For earnings only we do not need to multiply by premium amount just add the amount -PI-8210
        } else if (e.rateId === -1) {
          total += (e.amount || 0);
        } 
      });
      setTotal(total);
    }
  };
  
  const curriedRemove = (id: string | undefined) => {
    if (!id) return console.error('ID undefined');
    
    const matchedFieldIndex = fields?.findIndex((field) => { return field.id === id; });
    
    if (matchedFieldIndex < 0) return console.error('Could not delete. FIeld not found');
    
    remove(matchedFieldIndex);
    updateActiveElement(null); // clear active element
    
    setTimeout(() => {
      saveChanges(true);
    }, 0);
  };
  
  useEffect(() => {
    totalAmount();
  }, []);

  useEffect(() => {
    if (mostRecentlyUpdatedCheck?.transmittalCheckId === (checkId ?? -1)) {
      totalAmount();
    }
  }, [otherItems, mostRecentlyUpdatedCheck, checkId]);

  const appendEarnings = () => {
    const earningsToAdd: Partial<TransmittalEarning>[] = [];
    const tempIds: number[] = [];

    const matchingShiftPremium = clientShiftPremiums.find((x) => x.shiftPremiumId === employeeShiftPremiumId);
    
    //PI-8701 Will see if the employee's Tran Digit is 3 (Include both salary and hourly) if so we want to add both hourly and salary when adding an earnings line.
    payRateDropdown.forEach((payRate) => {
      if (payRate.empTranDigit === -1) return;
      
      const tempId = Math.floor(Math.random() * -10000);
      const newEarning = buildNewEarning(payRate, clientAutoFillsShiftCodes, matchingShiftPremium, employee, tempId);
      
      tempIds.push(tempId);
      earningsToAdd.push(newEarning);
    });

    append(earningsToAdd);
    
    // focus the last new earning's code dropdown
    setTimeout(() => {
      const focusId = `earningsCode-${tempIds[tempIds.length - 1]}`;
      updateActiveElement(focusId);
      document.getElementById(focusId)?.focus();
    }, 0);
  };
  
  const onAddEarnings = () => {
    // if we have an unsaved check, save it and THEN append the earning in state. Else just append as normal.
    if (dirtyCheck) {
      saveChanges(true)
        ?.then(() => {
          setTimeout(() => {
            appendEarnings();
          }, 0);
        });
    } else {
      appendEarnings();
    }
  };

  return (
    <div>
      {fields.map((earning, earningIndex) => {
        return (
          <TransmittalEarningsItem
            formStateErrors={formStateErrors}
            empNo={empNo}
            getValues={getValues}
            watch={watch}
            setError={setError}
            key={earning.id}
            fieldArrayId={earning.id}
            errors={errors}
            register={register}
            setValue={setValue}
            payRateDropdown={payRateDropdown}
            earning={earning}
            index={index}
            earningIndex={earningIndex}
            checkIndex={checkIndex}
            total={total}
            totalAmount={totalAmount}
            remove={curriedRemove}
            saveChanges={saveChanges}
            isReadOnly={isReadOnly}
            earningCodeSettings={earningCodeSettings}
          />
        );
      })}
      {!isReadOnly ? <button
        type="button"
        disabled={isReadOnly}
        className="btn btn-link dm-grid-action-title pl-0"
        onClick={onAddEarnings}
      >
        Add Earnings
        <Icon
          name="plus-circle"
          className="fa-plus-circle"
        />
      </button> : null }
      <hr className="mb-0" />
    </div>
  );
};

export default TransmittalEarningsItemsList;
