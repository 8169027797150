import { createAction } from '@reduxjs/toolkit';
import { EarningCode, EarningsCodeAutoCalculation } from '../../models';

export const loadEarningCodes = createAction(
  '[earning-code] LOAD_EARNING_CODES',
);
export const storeEarningCodes = createAction<EarningCode[]>(
  '[earning-code] STORE_EARNING_CODES',
);

export const loadEarningCodesAutoCalculations = createAction(
  '[earning-code] LOAD_EARNING_CODES_AUTOCALCULATIONS',
);
export const storeEarningCodesAutoCalculations = createAction<
EarningsCodeAutoCalculation[]
>('[earning-code] STORE_EARNING_CODES_AUTOCALCULATIONS');

export const updateEarningCodesAutoCalculations = createAction<
EarningsCodeAutoCalculation[]
>('[earning-code] UPDATE_EARNING_CODES_AUTOCALCULATIONS');

export const storeEarningCodesAutoCalculationsMessages = createAction<string[]>(
  '[earning-code] STORE_EARNING_CODES_AUTOCALCULATIONS_MESSAGES',
);

export const clearEarningsCodeAutoCalculationMessages = createAction(
  '[earning-code] CLEAR_EARNING_CODES_AUTOCALCULATIONS_MESSAGES',
);
