import React, { useEffect, useState } from 'react';
import Modal from 'core/components/modals/Modal';
import tableStyles from 'core/components/shared/dm-table/_styles.module.scss';
import { CellProps, ColumnHeaderData, Row } from 'core/components/shared/dm-table/types';
import TableHeader from 'core/components/shared/dm-table/Header';
import TableRow from 'core/components/shared/dm-table/Row';
import Cell from 'core/components/shared/dm-table/Cell';
import Icon from 'core/components/shared/Icon';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { deleteCostCodeInfo, getAllCostCodes } from 'core/store/actions';
import { PayrollTimeCardCostCodeInfo } from 'core/models/Contractor.model';
import CostCodeDetailModal from './CostCodeDetail.modal';

type Props = {
  show: boolean;
  onHide: () => void;
};

const costCodeSetupColumns: ColumnHeaderData[] = [
  { title: 'Cost Code', styleClass: 'th-20' },
  { title: 'Description', styleClass: 'th-60' },
  { title: 'Exclude from WH347', styleClass: 'th-20' },
];

const CellCheckbox = ({ name, value }: { name: string; value: boolean;  }) => (
  <div className="d-flex align-items-center">
    <input
      name={name}
      type="checkbox"
      checked={value}
      aria-readonly
    />
  </div>
);

const buildCostCodeRow = (costCodeInfo: PayrollTimeCardCostCodeInfo): Row => {
  return {
    id: costCodeInfo.costCode,
    cells: [
      { children: costCodeInfo.costCode, styleClass: 'td-20' },
      { children: costCodeInfo.description, styleClass: 'td-60' },
      { children: <CellCheckbox
        name={costCodeInfo.costCode}
        value={costCodeInfo.excludeFromWH347}
      />,
      styleClass: 'td-20',
      position: 'center',
      },
    ],
  };
};

const SubSetupModal = ({ show, onHide }: Props) => {
  const [rowData, setRowData] = useState<Row[]>([]);
  const [selectedCostCode, setSelectedCostCode] = useState<PayrollTimeCardCostCodeInfo | null>(null);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [addNew, setAddNew] = useState<boolean>(false);
  
  const { allCostCodes } = useAppSelector(({ contractor }) => contractor);
  
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(getAllCostCodes());
  }, []);
  
  useEffect(() => {
    setRowData(allCostCodes?.map((costCode) => buildCostCodeRow(costCode)));
  }, [allCostCodes]);
  
  const handleDeleteSub = () => {
    if (!selectedCostCode) return console.error('No sub to delete');
    if (!confirm(`Delete job #${selectedCostCode.costCode}?`)) return;
    dispatch(deleteCostCodeInfo({ costCode: selectedCostCode.costCode }));
    setSelectedCostCode(null);
  };
  
  return (
    <Modal
      title="Cost Code Setup"
      show={show}
      onHide={onHide}
    >
      <div className={tableStyles['table-container']}>
        <div className={tableStyles['table-modules-container']}>
          <button
            className="btn btn-link p-0 mr-3"
            onClick={() => {
              setShowDetailModal(true);
            }}
            disabled={!selectedCostCode}
          >
            Update Selected&nbsp;<Icon 
              name="pen"
            />
          </button>
          <button
            className="btn btn-link p-0 mr-3"
            onClick={handleDeleteSub}
            disabled={!selectedCostCode}
          >
            Delete Selected&nbsp;<Icon 
              name="minus-circle"
            />
          </button>
          <button
            className="btn btn-link p-0 mr-3"
            onClick={() => {
              setSelectedCostCode(null);
              setShowDetailModal(true);
              setAddNew(true);
            }}
          >
            Add Cost Code&nbsp;<Icon 
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </div>
        <div className={tableStyles.table}>
          <TableHeader columns={costCodeSetupColumns} />
          <div className={tableStyles['tbody-no-scroll']}>
            {rowData?.map((row: Row) => (
              <TableRow
                id={row.id}
                isSelected={String(row.id) === String(selectedCostCode?.costCode)}
                key={row.id}
                onClick={() => {
                  setSelectedCostCode(allCostCodes?.find((costCode) => String(costCode.costCode) === String(row.id)) ?? null);
                }}
                onDoubleClick={() => {
                  setSelectedCostCode(allCostCodes?.find((costCode) => String(costCode.costCode) === String(row.id)) ?? null);
                  setShowDetailModal(true);
                }}
              >
                {row.cells.map((cell: CellProps, cellIndex: number) => (
                  <Cell
                    key={`(${row.id},${cellIndex})`}
                    styleClass={cell.styleClass}
                    position={cell.position}
                  >
                    {cell.children}
                  </Cell>
                  
                ))}
              </TableRow>
            ))}
          </div>
        </div>
      </div>
      {showDetailModal && (selectedCostCode || addNew) && (
        <CostCodeDetailModal
          show={showDetailModal}
          addCostCode={addNew}
          onHide={() => {
            setAddNew(false);
            setShowDetailModal(false);
          }}
          costCodeInfo={selectedCostCode ?? undefined}
        />
      )}
    </Modal>
  );
};

export default SubSetupModal;