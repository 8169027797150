import { ICellRendererParams } from '@ag-grid-community/core';
import Modal from 'core/components/modals/Modal';
import React, { useEffect, useState } from 'react';
import { Alert, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { TextareaGrp } from '../../core/components/form-controls';
import { File } from '../../core/models/File.model';
import { putClientFile } from '../../core/store/slices/file.slice';
import { useAppDispatch } from '../hooks';

const ViewEditAgreementRenderer = (props: ICellRendererParams) => {
  const dispatch = useAppDispatch();

  const [file] = useState<File.FileRecord>(props.data);

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const { register, handleSubmit, reset } = useForm<File.FileRecord>({
    defaultValues: file,
  });

  useEffect(() => {
    reset({ ...file });
  }, [file]);

  const onSubmit = (data: File.FileRecord) => {
    const submitData = {
      ...file,
      agreement: data.agreement,
    };
    dispatch(putClientFile(submitData));
    setShowModal(false);
  };

  return (
    <>
      <div>
        <button onClick={openModal}
          className="btn btn-link">
          Edit/View
        </button>
      </div>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        title="Edit/View Agreement"
      >
        <p>
          <strong>File Name: </strong>
          {file.name}
        </p>
        <div className="font-weight-bold">Description</div>
        <Alert variant="dark">
          The "Agreement" provided below will be the text that
          employees must acknowledge when signing the selected
          file.
        </Alert>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextareaGrp ref={register}
            name="agreement" />
          <Row className="justify-content-end">
            <button
              type="button"
              className="btn orange-outline-button mr-2"
              onClick={() => { return setShowModal(false); }}
            >
              Clear
            </button>
            <button
              type="submit"
              className="btn orange-button"
            >
              Okay
            </button>
          </Row>
        </form>
      </Modal>
    </>
  );
};

export default ViewEditAgreementRenderer;
