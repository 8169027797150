import { RadioGrp } from 'core/components/form-controls';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { Client, Dropdown } from 'core/models';
import { DeductionShortagesReportRequest } from 'core/models/PayrollReports.model';
import {
  clearDeductionShortagesReport,
  downloadDeductionShortagesReport,
  loadRelatedClients,
} from 'core/store/actions';
import { getDeductionShortagesReport } from 'core/store/selectors/payroll-reports.selector';

import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from 'utilities/hooks';
import Multiselect from 'wwagner4719-multiselect-react-dropdown';

const runReportByOpts: RadioOptions[] = [
  {
    value: 'ByEmployee',
    label: 'By Employee',
  },
  {
    value: 'ByDeduction',
    label: 'By Deduction',
  },
];

const DeductionShortagesReport = () => {
  const dispatch = useDispatch();
  const selectRef: React.RefObject<any> = useRef();
  const report = useSelector(getDeductionShortagesReport);
  const [fileType, setFileType] = useState<string>('pdf');
  const clients = useAppSelector((state) => {return state?.app.relatedClients;});
  const [clientOpts, setClientOpts] = useState<Dropdown[]>([]);
  const [selectedClients, setSelectedClients] = useState<Dropdown[]>([]);

  const { register, handleSubmit, reset, errors, setError, clearErrors } =
        useForm<DeductionShortagesReportRequest>({
          defaultValues: {
            runReportBy: 'ByEmployee',
          },
        });

  const downloadReport = (data: DeductionShortagesReportRequest) => {
    clearErrors('runReportBy');
    if (selectedClients?.length === 0) {
      //Validation for selectedClients
      setError('runReportBy', {
        type: 'required',
        message: 'You must select a client(s).',
      });
      return;
    }

    const submitData: DeductionShortagesReportRequest = {
      clientNo: data.clientNo,
      clientNos: selectedClients?.map((c) => {return +c.id;}),
      reportType: fileType,
      runReportBy: data.runReportBy,
    };
    dispatch(downloadDeductionShortagesReport(submitData));
  };

  const onCancel = () => {
    reset();
    setSelectedClients([]);
    selectRef?.current?.resetSelectedValues();
  };

  const hidePdfViewer = () => {
    dispatch(clearDeductionShortagesReport());
  };

  useEffect(() => {
    dispatch(loadRelatedClients());
  }, []);

  useEffect(() => {
    if (clients) {
      const options = clients.map((c: Client) => {
        return {
          id: c.clientNo,
          description: `${c.clientNo} - ${c.clientName}`,
          federalId: `Federal ID - ${c.federalID}`,
        };
      }) as unknown as Dropdown[];
      options && setClientOpts(options);
    }
  }, [clients]);

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">
            Deduction Shortages Report
          </div>
          <hr className="dm-panel-hr" />
          <div className="d-flex flex-row flex-grow-1">
            <div className="d-flex ">
              <div>
                <RadioGrp
                  radioOptions={runReportByOpts}
                  name="runReportBy"
                  ref={register}
                  errors={errors.runReportBy}
                />
              </div>
            </div>
            <div className="flex-grow-1 text-right">
              <button
                type="button"
                className="orange-outline-button mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="orange-button mr-2"
                onClick={() => {return setFileType('pdf');}}
              >
                Run Report
              </button>
            </div>
          </div>
          <div className="d-flex flex-column flex-grow-1">
            <Multiselect
              onSelect={(selectedList) => {
                setSelectedClients(selectedList);
                clearErrors('runReportBy');
              }}
              onRemove={(selectedList) => {
                setSelectedClients(selectedList);
                clearErrors('runReportBy');
              }}
              options={clientOpts}
              displayValue="description"
              showCheckbox={true}
              showGroupByCheckbox={true}
              groupBy="federalId"
              ref={selectRef}
            />
          </div>
        </form>
      </div>
      {report ? (
        <PDFViewerModal
          show={report.length > 0}
          pdfData={report}
          onHide={hidePdfViewer}
          reportName="DeductionShortagesReport"
        />
      ) : null}
    </div>
  );
};

export default DeductionShortagesReport;
