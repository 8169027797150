import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAppSelector } from 'utilities/hooks';
import GridSelect from '../../Shared/GridSelect';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for Checkbox and Grid select forms
const RecurringEarningsFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  const earningsCodeOptions = useAppSelector((state) => {
    return state.dropdown.earningsCode;
  });

  const recurringEarningsFields = sectionFields.find(f => {
    return f.name === 'Recurring Earnings';
  })?.fields;

  return (
    <Row>
      {!!sectionFields?.length && (
        <Col xs={12}>
          <GridSelect
            section="Recurring Earnings"
            title="Recurring Earnings"
            dFields={recurringEarningsFields?.filter(f => { return f.name !== 'Earnings Code'; })}
            checkboxes={recurringEarningsFields?.filter(f => { return f.name === 'Earnings Code'; })}
            options={earningsCodeOptions}
            formMethods={formMethods}
            isCodeField = {false}
          />
        </Col>
      )}
    </Row>
  );
};

export default RecurringEarningsFields;