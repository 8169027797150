import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PanelHeader from 'core/components/shared/PanelHeader';
import { HRTrainingEvent, HRTrainingEventRequest } from 'core/models/HREmployee.model';
import {
  createHRTrainingEvent,
  deleteHRTrainingEvent,
  loadHRTrainingEvent,
  updateHRTrainingEvent,
} from 'core/store/actions/hr-employee.action';
import { getHireInfoTrainingEvent } from 'core/store/selectors/hr-employee.selector';
import TrainingEventItem from './TrainingEventItem';
import { useForm, useFieldArray } from 'react-hook-form';
import { UNSAVED_MESSAGE } from 'core/constants';
import { currencyFormatter, fromCurrency } from 'utilities/utilities';
import Icon from 'core/components/shared/Icon';
import { toggleBlockNavigation } from 'core/store/actions';

type RouteParams = {
  protectedEmpNo: string;
};
type Clone = Omit<HRTrainingEvent, 'employeeFees' | 'companyFees'> &
{ employeeFees: string | number; companyFees: string | number };
type FormType = {
  trainingEventItems: Clone[];
};

const TrainingEventPage: React.FC = () => {
  const dispatch = useDispatch();

  const { protectedEmpNo } = useParams<RouteParams>();
  
  const [trainingEventItems, setTrainingEvents] = useState<HRTrainingEvent[]>([]);
  
  const trainingEventStore = useSelector(getHireInfoTrainingEvent);

  const {
    reset,
    control,
    handleSubmit,
    formState,
    ...formMethods
  } = useForm<FormType>({
    defaultValues: { trainingEventItems },
  });

  const { fields, prepend } =
    useFieldArray<HRTrainingEvent>({
      control,
      name: 'trainingEventItems',
      keyName: 'id',
    });

  useEffect(() => {
    protectedEmpNo && dispatch(loadHRTrainingEvent(protectedEmpNo));
  }, [protectedEmpNo]);

  useEffect(() => {
    trainingEventStore && setTrainingEvents(trainingEventStore);
  }, [trainingEventStore]);

  useEffect(() => {
    trainingEventItems && reset({
      trainingEventItems: trainingEventItems.map((e: HRTrainingEvent) => {
        return {
          ...e,
          employeeFees: e.employeeFees && currencyFormatter(e.employeeFees),
          companyFees: e.companyFees && currencyFormatter(e.companyFees),
        };
      }),
    });
  }, [trainingEventItems]);


  const addTrainingEvent = () => {
    prepend({
      empTrainingId: 0,
      protectedEmpNo,
    });
  };

  const deleteTrainingEvent = (item: HRTrainingEvent) => {
    if (item.empTrainingId) {
      dispatch(deleteHRTrainingEvent({
        data: item,
        protectedEmpNo,
      }));
    } else {
      setTrainingEvents((prev: HRTrainingEvent[]) => { return prev.filter((cb: HRTrainingEvent) => { return cb.empTrainingId; }); });
    }
  };

  const onSubmit = (data: FormType, e?: React.BaseSyntheticEvent) => {
    if (!e?.target.isConnected) {
      return false;
    }
    if (!data) return;
    
    data.trainingEventItems.forEach((item) => {
      const request: HRTrainingEventRequest = {
        protectedEmpNo,
        data: {
          ...item,
          empTrainingId: +item.empTrainingId || 0,
          startDate: item.startDate !== '' ? new Date(item.startDate!).toISOString() : null,
          endDate: item.endDate !== '' ? new Date(item.endDate!).toISOString() : null,
          employeeFees: (item.employeeFees && fromCurrency('' + item.employeeFees)) || 0,
          companyFees: (item.companyFees && fromCurrency('' + item.companyFees)) || 0,
        },
      };
      
      if (request.data.empTrainingId !== 0) {
        dispatch(updateHRTrainingEvent(request));
      } else {
        dispatch(createHRTrainingEvent(request));
      }
    });
  };

  const onCancel = () => {
    reset();
  };

  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: formState.isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [formState.isDirty]);
  
  return (
    <div>
      <div className="dm-panel dm-panel-border" >
        <PanelHeader title="Training Events" >
          <button
            className="btn btn-link dm-grid-action-title p-0 mr-2"
            onClick={addTrainingEvent}
          >
            Add Training Event	<Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields?.map((item, index: number) => {
            return (
              <div key={item.id} >
                <TrainingEventItem
                  item={item}
                  index={index}
                  control={control}
                  formMethods={formMethods}
                  onDelete={deleteTrainingEvent}
                />
                <hr className="dm-panel-hr mt-2" />
              </div>
            );
          })}
          <div className="text-right mt-3">
            <button
              type="button"
              className="btn btn-primary orange-outline-button ml-auto mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              disabled={!formState.isDirty}
              type="submit"
              className="btn orange-button-sm mr-3"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TrainingEventPage;
