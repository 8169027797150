import React, { useEffect, useRef, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CheckboxGrpInLine } from '../../core/components/form-controls';
import InputReportDateSelector from '../../core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import {
  Client,
  DtoOptions,
  Location,
  Department,
  SubDepartment,
  SubDepartment2,
} from '../../core/models';
import { EmployeeAverageHoursReportRequest } from '../../core/models/AccountingReports.model';
import {
  getEmployeeAverageHoursReport,
  getLocationDepartmentsWithoutHome,
} from '../../core/store/selectors';
import { useAppDispatch, useAppSelector } from '../../utilities/hooks';
import {
  clearEmployeeAverageHoursReport,
  downloadEmployeeAverageHoursReport,
} from '../../core/store/actions';
import { CommonService } from '../../core/services';
import Icon from 'core/components/shared/Icon';

type FormType = {
  loc: number[];
  dept: number[];
  subDept: number[];
  subDept2: number[];
  excludeTermEmp: boolean;
};

const EmployeeAverageHoursReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const childRef: React.RefObject<any> = useRef();
  const { locationOpts, deptOpts, subdeptOpts, subdept2Opts } = useSelector(
    getLocationDepartmentsWithoutHome,
  );
  const [fileType, setFileType] = useState<string>('pdf');
  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [subDepartments, setsubDepartments] = useState<SubDepartment[]>([]);
  const [subDepartments2, setsubDepartments2] = useState<SubDepartment2[]>(
    [],
  );

  const reportName = 'EmployeeAverageHoursReport';

  const report = useSelector(getEmployeeAverageHoursReport);

  const client = useAppSelector((state) => { return state.client.client; }) as Client;

  const hidePdfViewer = () => {
    dispatch(clearEmployeeAverageHoursReport());
  };

  const { register, handleSubmit } = useForm<FormType>();

  useEffect(() => {
    if (report && fileType === 'excel') {
      report && CommonService.downloadBase64File(
        'application/excel',
        report,
        `${reportName}.xlsx`,
      );
      dispatch(clearEmployeeAverageHoursReport());
    }
  }, [report]);

  const downloadReport = (data: EmployeeAverageHoursReportRequest) => {
    const submitData: EmployeeAverageHoursReportRequest = {
      clientNo: client.clientNo,
      loc: locations?.map((l) => { return l.locationCode; }),
      dept: departments?.map((d) => { return d.deptCode; }),
      subDept: subDepartments?.map((d) => { return d.subDeptCode; }),
      subDept2: subDepartments2?.map((d) => { return d.sub2Code; }),
      excludeTermEmp: data.excludeTermEmp === 'true' ? true : false,
      dtoOptions: reportDates,
      reportOption: 'ExcludeStatus',
      reportType: fileType,
    };
    dispatch(downloadEmployeeAverageHoursReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const onRemove = (selectedList: any, removeItem: any) => {
    setLocations(
      selectedList.filter((single: number) => { return single !== removeItem; }),
    );
    return selectedList;
  };

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">
            Employee Average Hours Report
          </div>
          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title "
              onClick={() => { return setFileType('excel'); }}
              disabled={!reportDates.length}>
              Download Report  <Icon name="download"
                className="fa-download" />
            </button>
          </div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div>
              <InputReportDateSelector
                ref={childRef}
                returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }
                }
              />
            </div>

            <div className="d-flex flex-column flex-grow-1">

              <div className="text-right">
                <button
                  type="button"
                  className="orange-outline-button mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="orange-button mr-2"
                  onClick={() => { return setFileType('pdf'); }}
                  disabled={!reportDates.length}
                >
                  Run Report
                </button>
              </div>
              <div
                className="w-50 dm-panel-border p-2"
                style={{ marginTop: '66px' }}
              >

                <div className="d-flex flex-column">
                  <div className="d-flex flex-column flex-grow-1">

                    <div className="dm-card-subtitle2 mb-2">
                      Report Filter
                    </div>
                    <hr className="dm-panel-hr" />
                  </div>
                  <div className="d-flex flex-column flex-grow-1">

                    <div>
                      {locationOpts.length ? (
                        <div className="d-flex flex-column">
                          <div className="form-label mt-1">
                            LOCATION
                          </div>
                          <Multiselect
                            onSelect={(selectedList) => { return setLocations(selectedList); }}
                            options={locationOpts}
                            displayValue="locationDesc"
                            onRemove={onRemove}
                            showCheckbox={true}
                            selectedValueDecorator={(v) => {
                              return locationOpts.find(o => { return o.locationDesc === v; })?.locationCode + ' - ' + v;
                            }}
                          />
                        </div>
                      ) : null}
                    </div>

                    <div>
                      {deptOpts.length ? (
                        <div>
                          <div className="form-label mt-1">
                            DEPT
                          </div>
                          <Multiselect
                            onSelect={(selectedList) => { return setDepartments(selectedList); }}
                            options={deptOpts}
                            displayValue="deptDesc"
                            onRemove={onRemove}
                            showCheckbox={true}
                            selectedValueDecorator={(v) => {
                              return deptOpts.find(o => { return o.deptDesc === v; })?.deptCode + ' - ' + v;
                            }}
                          />
                        </div>
                      ) : null}
                    </div>

                    <div>
                      {subdeptOpts.length ? (
                        <div>
                          <div className="form-label mt-1">
                            SUB DEPT
                          </div>
                          <Multiselect
                            onSelect={(selectedList) => { return setsubDepartments(selectedList); }}
                            options={subdeptOpts}
                            displayValue="subDeptDesc"
                            onRemove={onRemove}
                            showCheckbox={true}
                            selectedValueDecorator={(v) => {
                              return subdeptOpts.find(o => { return o.subDeptDesc === v; })?.subDeptCode + ' - ' + v;
                            }}
                          />
                        </div>
                      ) : null}
                    </div>

                    <div>
                      {subdept2Opts.length ? (
                        <div>
                          <div className="form-label mt-1">
                            Sub Dept 2
                          </div>
                          <Multiselect
                            onSelect={(selectedList) => { return setsubDepartments2(selectedList); }}
                            options={subdept2Opts}
                            displayValue="sub2Desc"
                            onRemove={onRemove}
                            showCheckbox={true}
                            selectedValueDecorator={(v) => {
                              return subdept2Opts.find(o => { return o.sub2Desc === v; })?.sub2Code + ' - ' + v;
                            }}
                          />
                        </div>
                      ) : null}
                    </div>

                    <div className="mt-2">
                      <CheckboxGrpInLine
                        name="terminated"
                        label="Exclude Terminated Employees"
                        ref={register}
                      />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </form>
      </div>

      {report ? (
        <PDFViewerModal
          show={report.length > 0}
          pdfData={report}
          onHide={hidePdfViewer}
          reportName="EmployeeAverageHoursReport"
        />
      ) : null}
    </div>
  );
};
export default EmployeeAverageHoursReport;
