import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  Client,
  Payroll,
  PayrollDeductionShortage,
  PayrollOptions,
  PayrollPreview,
  PayrollValidateRequest,
  ValidateDetails,
} from 'core/models';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { ControlDatePickerGrp, RadioGrp } from 'core/components/form-controls';
import {
  useAppDispatch,
  useAppSelector,
  useRadioButtonChange,
} from 'utilities/hooks';
import WeekBeginningDatesModal from './WeekBeginningDates.modal';
import { payrollFrequencies } from 'dropdowns/payrollFrequencies';
import {
  clearAccrualReports,
  clearPayrollPreviewPdf,
  loadPayrollTimeOffRequests,
  postApprovedAccrualReport,
  postPayrollMarkAsSubmitted,
  postPayrollValidate,
  postPendingAccrualReport,
  updatePayrollOptions,
  updatePayrollPreview,
  toggleAccrualLoadingState,
} from 'core/store/actions';
import PayrollErrorsWarnings from './submit-options/PayrollErrorsWarnings';
import OutstandingDedShortages from './submit-options/OutstandingDedShortages';
import PreviewReminderModal from './PreviewReminder.modal';
import { useSelector } from 'react-redux';
import { getClient } from 'core/store/selectors';
import PayrollPreviewPdfModal from './PayrollPreviewPdf';
import Icon from 'core/components/shared/Icon';
import { DateTime } from 'luxon';
import WireOnlyAgreementStep from './WireOnlyAgreement';

const weekBeginOpts = [
  {
    value: '0',
    label: 'Single',
  },
  {
    value: '1',
    label: 'By Control Total',
  },
];

const radioOptions = [
  {
    value: 'yes',
    label: 'Accept',
  },
  {
    value: 'no',
    label: 'Reject',
  },
];

const fs: FieldInputSettings = {
  weekBeginningType: {
    name: 'weekBeginningType',
    label: 'Week Beginning Type:',
    radioOptions: weekBeginOpts,
    labelWidth: 100,
    groupClass: 'gc12',
  },
  weekBegin: {
    name: 'weekBegin',
    label: 'Week Beginning:',
    labelWidth: 100,
    groupClass: 'gc12',
  },
  weekEnd: {
    name: 'weekEnd',
    label: 'Current Week End:',
    labelWidth: 100,
    groupClass: 'gc12',
  },
  checkDate: {
    name: 'checkDate',
    label: 'Current Check Date:',
    labelWidth: 100,
    groupClass: 'gc12',
  },
  payingEmployees: {
    name: 'payingEmployees',
    defaultChecked: 'true',
  },
  processingInfo: {
    name: 'processingInfo',
    label: '',
  },
  message: {
    name: 'message',
    label: '',
  },
  acceptMessage: {
    name: 'acceptMessage',
    label: '',
    defaultChecked: 'yes',
  },
  calcDedShortage: {
    name: 'calcDedShortage',
    label: '',
    defaultChecked: 'all',
  },
};

type PropTypes = {
  type: string;
  show: boolean;
  client: Client;
  selectedPayroll: Payroll;
  changeViewType: Dispatch<SetStateAction<string | undefined>>;
  onHide: () => void;
  reloadPayrolls: () => void;
};

const PayrollOptionsModal: React.FC<PropTypes> = ({
  type,
  show,
  selectedPayroll,
  changeViewType,
  onHide,
  reloadPayrolls,
}) => {
  const dispatch = useAppDispatch();

  const payrollFrequencyOpts = payrollFrequencies;

  const [deductionShortages, setDeductionShortages] = useState<PayrollDeductionShortage[]>();
  const [validateWarnings, setValidateWarnings] = useState<ValidateDetails[]>();
  const [validateErrors, setValidateErrors] = useState<ValidateDetails[]>();
  const [, setValidateMessages] = useState<{ errors: ValidateDetails[]; warnings: ValidateDetails[]; }>();
  const [showDates, setShowDates] = useState(false);
  const [singleWeekBeginDate, setSingleWeekBeginDate] = useState<string>('0');
  const [showPreviewPdf, setShowPreviewPdf] = useState(false);
  const [showWireOnlyAgreement, setShowWireOnlyAgreement] = useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const [showRunAlert, setShowRunAlert] = useState(new Date().getHours() >= 15); 
  const [handleRadioButtonChange] = useRadioButtonChange(fs);

  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
    errors,
    getValues,
  } = useForm<any>();

  const { weekBegin, weekBeginningType } = watch([
    'weekBegin',
    'weekBeginningType',
  ]);

  const calcDedShortage = getValues('calcDedShortage');
  const message = getValues('acceptMessage');
  const paidEmployee = getValues('payingEmployees');
  const inValidDates = ['0001-01-01', '2001-01-01'];

  const isLoading = useAppSelector((state) => { return state.payroll.loading; });
  const isValidating = useAppSelector((state) => { return state.payroll.validating; });
  const validateStore = useAppSelector((state) => { return state.payroll.payrollValidate; });
  const payrollPreview = useAppSelector((state) => { return state.payroll.payrollPreview; });
  const currentClient = useSelector(getClient);
  const payrollOptsRef = useRef<PayrollOptions | null>(payrollPreview);
  
  useEffect(() => {
    if (validateStore) {
      setValidateMessages({
        errors: validateStore.errors,
        warnings: validateStore.warnings,
      });
      setValidateErrors(validateStore.errors || []);
      setValidateWarnings(validateStore.warnings || []);
    }
  }, [validateStore, type, selectedPayroll]);

  useEffect(() => {
    // check every minute to see if it's past 3, and if so show run alert
    const payrollRunTimeInterval = setInterval(() => {
      if (new Date().getHours() >= 15) {
        setShowRunAlert(true);
      }
    }, 60000);

    const runReports = validateErrors?.find((error) => { return error.name.toLowerCase().includes('accrual requests'); },
    );
    const request: PayrollValidateRequest = {
      payrollHistoryId: selectedPayroll.payrollHistoryId,
      registerType: type,
    };

    dispatch(postPayrollValidate(request));
    
    //Run Reports would not work on first load if they have an error due to this one getting hit. 
    //TODO: Rethink how to call these reports so its not this :) Just trying to hit the 3:30 build
    dispatch(postPendingAccrualReport({ payrollHistoryId: selectedPayroll.payrollHistoryId }));
    dispatch(postApprovedAccrualReport({ payrollHistoryId: selectedPayroll.payrollHistoryId }));
    
    setValue('weekBegin', payrollPreview?.weekBegin);
    return () => { clearInterval(payrollRunTimeInterval); };
  }, [type, selectedPayroll]);

  useEffect(() => {
    if (payrollPreview) {
      reset(payrollPreview);
      payrollOptsRef.current = structuredClone(payrollPreview);
      setDeductionShortages(payrollPreview.deductionShortages);
      setSingleWeekBeginDate(
        payrollPreview.singleWeekBeginDate ? '0' : '1',
      );
    }
  }, [payrollPreview, type, selectedPayroll]);

  const save = async (data: PayrollOptions) => {
    if (selectedPayroll && currentClient) {
      const { payrollHistoryId } = selectedPayroll;
      if (type === 'Preview') {
        const {
          nextWeekEnd,
          nextCheckDate,
          prFrequency,
          status,
        } = selectedPayroll;

        const prFreqDescription = payrollFrequencyOpts.find(
          (item) => { return item.value === prFrequency; },
        )?.description;
        const prStatDescription = 'Blank';

        const previewData: PayrollPreview = {
          clientNo: +currentClient.clientNo,
          weekEnd: data.weekEnd,
          checkDate: data.checkDate,
          nextWeekEnd,
          nextCheckDate,
          payrollFrequency: {
            value: prFrequency,
            description: prFreqDescription as string,
          },
          payrollStatus: {
            value: status,
            description: prStatDescription,
          },
          payrollHistoryId,
          registerSource: 0,
          empNo: 0,
        };

        dispatch(
          updatePayrollPreview({
            data: previewData,
            payrollHistoryId,
          }),
        );
        setShowPreviewPdf(true);
      } else if (type === 'Submit') {
        dispatch(postPayrollMarkAsSubmitted({ payrollHistoryId, data: data }));
        closeModal();
      }
    }
  };

  const onSave = async (data: any) => {
    //It should never hit this again. But somehow it slipped through on a test so I shall ensure its a valid date.
    const weekBeginDate = new Date(getValues('weekBegin'))?.toISOString()?.split('T')[0];
    if (inValidDates.includes(weekBeginDate)) return alert('Please enter a valid Week Beginning date.');
    if (!payrollOptsRef.current) return;
    const submitData = structuredClone(payrollOptsRef.current);
    submitData.weekBegin = new Date(weekBegin)?.toISOString()?.split('T')[0];
    
    new Promise((resolve) => {
      resolve(dispatch(updatePayrollOptions({ data: submitData, payrollHistoryId: selectedPayroll.payrollHistoryId })),
      );
    }).then(() => {
      save(submitData)
        .then(() => {
          if (type !== 'Submit') return;
          setTimeout(() => {return reloadPayrolls();}, 1500);
        });
    });
  };

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    dispatch(clearAccrualReports());
    onHide();
  };

  const hidePreviewPdf = () => {
    setShowPreviewPdf(false);
    dispatch(clearPayrollPreviewPdf());
    onHide();
    setShowReminder(true);
  };

  const actionRequired =
    message === 'no' ||
    (validateErrors &&
      validateWarnings &&
      (validateErrors.length > 0 || validateWarnings.length > 0));

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={closeModal}
        dialogClassName="modal-md"
        animation={false}
      >
        <div className="modal-header">
          <div className="dm-card-title">{type} Payroll Options</div>
          <button
            type="button"
            onClick={closeModal}
            className="modal-close-btn"
          >
            <Icon name="times" />
          </button>
        </div>
        <Modal.Body>
          {!isLoading && !isValidating ? (
            <div>
              <form onSubmit={handleSubmit(onSave)}>
                <div
                  className="d-flex flex-row flex-grow-1 p-3"
                  style={{
                    backgroundColor: '#E3FEDB',
                    color: '#000',
                  }}
                >
                  <strong className="mr-auto align-self-center">{type} your P/R with your selected options</strong>
                  <div>
                    <button
                      className="btn orange-outline-button mr-2"
                      onClick={closeModal}
                    >
                      No
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary orange-button"
                      disabled={
                        actionRequired ||
                        isLoading ||
                        isValidating ||
                        !((paidEmployee === 'false') ? false : true)
                      }
                    >
                      Yes
                    </button>
                  </div>
                </div>
                <div className="d-flex flex-row mb-2">
                  <div className="col-6 d-flex flex-column dm-panel-border p-2">
                    <div>
                      <div className="dm-card-subtitle2 pt-2 mb-2">
                        P/R processing information
                      </div>
                    </div>
                    {(payrollOptsRef.current?.weekBeginDates?.length || 1) > 1 && (
                        <div className='d-flex'>
                        <RadioGrp
                          {...fs.weekBeginningType}
                          errors={errors.weekBeginningType}
                          defaultChecked={singleWeekBeginDate}
                          ref={register}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setValue('weekBeginningType', e.target.value);
                            return setSingleWeekBeginDate(e.target.value);
                          }}
                        />
                        </div>
                      )}
                    <div className="d-flex">
                      <div className="d-flex flex-column">
                      <ControlDatePickerGrp
                          {...fs.weekBegin}
                          errors={errors.weekBegin}
                          value={weekBegin}
                          setValue={setValue}
                          control={control}
                          disabled={type === 'Preview'}
                          rules={{ required: true }}
                        />
                      </div>
                      <div className="d-flex flex-column">
                      {(payrollOptsRef.current?.weekBeginDates?.length || 1) > 1 && (
                        <button
                          className="orange-button-sm ml-auto mt-3"
                          onClick={() => { return setShowDates(true); }
                          }
                          disabled={singleWeekBeginDate !== '1'}
                          type="button"
                        >
                          Set Week Beginning Dates
                        </button>
                      )}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="d-flex flex-column">
                        <ControlDatePickerGrp
                          {...fs.weekEnd}
                          errors={errors.weekEnd}
                          setValue={setValue}
                          value={
                            payrollPreview?.weekEnd
                          }
                          control={control}
                          disabled={true}
                        />
                        <ControlDatePickerGrp
                          {...fs.checkDate}
                          errors={errors.checkDate}
                          value={
                            payrollPreview?.checkDate
                          }
                          setValue={setValue}
                          control={control}
                          disabled={true}
                        />
                        <RadioGrp
                          {...fs.payingEmployees}
                          label={`You are paying ${payrollPreview?.paidEmployees} employees out of ${payrollPreview?.totalEmployees}`}
                          errors={
                            errors.payingEmployees
                          }
                          ref={register}
                          onChange={
                            handleRadioButtonChange
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 d-flex flex-column dm-panel-border p-2">
                    <div className="dm-card-subtitle2 pt-2 mb-2">
                      Message
                    </div>
                    <span>
                      <strong>(1)</strong> You must submit your P/R by 3:00 pm Eastern Time in order to receive your
                      P/R checks and vouchers by the next business day. All your other reports will be in your library
                      and available for your review and printing today.
                    </span>
                    {showRunAlert ? (
                      <span className="my-1">
                        <strong>(2)</strong> You are submitting your P/R after 3:00 pm. Any paper checks and/or check
                        vouchers will NOT be printed, packaged, or shipped until the next day.
                      </span>
                    ) : null }
                    <RadioGrp
                      {...fs.acceptMessage}
                      errors={errors.acceptMessage}
                      radioOptions={radioOptions}
                      ref={register()}
                      onChange={handleRadioButtonChange}
                      disabled={type === 'Preview'}
                    />
                    {message === 'no' ? (
                      <span>
                        <strong>Attention:</strong>{' '}
                        Please contact Customer Service
                        before the end of business today
                        to discuss your specific needs.
                      </span>
                    ) : null}
                  </div>
                </div>
                {deductionShortages?.length ? (
                  <OutstandingDedShortages
                    data={deductionShortages}
                    id={selectedPayroll.payrollHistoryId}
                    empCount={payrollPreview?.paidEmployees ?? 0}
                    payrollOptsRef={payrollOptsRef}
                  />
                ) : null}
              </form>
              {validateErrors?.length || validateWarnings?.length ? (              
                <div className="d-flex flex-column dm-panel-border p-2 mb-2">
                  {validateErrors?.length && !isLoading ? (
                    <PayrollErrorsWarnings
                      selectedPayroll={selectedPayroll}
                      data={validateErrors}
                      update={setValidateErrors}
                      errorType={true}
                      changeViewType={changeViewType}
                    />
                  ) : null}
                  {validateWarnings?.length && !isLoading ? (
                    <div className="d-flex flex-column">
                      <PayrollErrorsWarnings
                        selectedPayroll={selectedPayroll}
                        data={validateWarnings}
                        update={setValidateWarnings}
                        errorType={false}
                        changeViewType={changeViewType}
                      />
                    </div>
                  ) : null}
                  <div className="d-flex flex-column mt-2 mx-1">
                    <div>
                      <strong>Note:</strong>&nbsp;Errors and Warnings will prevent the payroll from being Previewed
                      or Submitted.
                    </div>
                    <ul className="m-0">
                      <li><strong>Errors</strong>&nbsp;must be corrected.</li>
                      <li>
                        <strong>Warnings</strong>&nbsp;should be reviewed and corrected. Clicking “Accept” will
                        remove the warning without making any changes.
                      </li>
                    </ul>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <Spinner
                className="ml-2"
                style={{
                  width: '4rem',
                  height: '4rem',
                }}
                animation="border"
                variant="primary"
              ></Spinner>
              <br />
            </div>
          )}
          {showDates && payrollPreview?.weekBeginDates && (
            <WeekBeginningDatesModal
              weekBeginningDates={payrollPreview?.weekBeginDates}
              payrollHistoryId={selectedPayroll.payrollHistoryId}
              show={showDates}
              onHideDates={() => { return setShowDates(false); }}
            />
          )}
        </Modal.Body>
      </Modal>
      {showPreviewPdf && type === 'Preview' ? (
        <Modal
          show={showPreviewPdf}
          onHide={hidePreviewPdf}
          size="xl"
          backdrop="static"
          dialogClassName="h-100 payroll-preview-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Payroll Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: 'auto' }}>
            {showWireOnlyAgreement ? (
              <WireOnlyAgreementStep
                id={selectedPayroll.payrollHistoryId}
                showWireAgreement={() => {
                  setShowWireOnlyAgreement(false);
                }}
                closePreview={() => {
                  setShowWireOnlyAgreement(false);
                  reloadPayrolls(); //This will reload with the updated flag used to lock the transmittal
                  closeModal();
                }}
              />
            ) :
              <PayrollPreviewPdfModal
                showWireAgreement={() => {
                  setShowWireOnlyAgreement(true);
                }}
              />
            }
            
          </Modal.Body>
        </Modal>
      ) : null}
      {showReminder ? (
        <PreviewReminderModal
          show={showReminder}
          onHide={() => { return setShowReminder(false); }}
        />
      ) : null}
    </div>
  );
};
export default PayrollOptionsModal;
