import { createSelector } from 'reselect';
import { taxState } from '../reducers';
import { TaxState } from '../reducers/tax.reducer';
import { RootState } from '../store';
import { ClientMasterDtoTaxEntitiesClientTaxEntity } from 'core/models/TaxEntity.model';
import { IOption, ISelectMultipleOption } from 'interfaces/IOption';

export const getTaxes = (state: RootState) => {return state.tax.taxes;};

export const getW4Info = (state: RootState) => {return state.tax.w4Info;};

export const selectClientTaxEntitiesByAgency = createSelector(
  [
    // Usual first input - extract value from `state`
    (state: RootState) => state.tax.clientMasterDtoTaxEntitiesClientTaxEntity,
    // Forward `agency` and `year` to output selector.
    (_a, agency: string) => agency,
    (_a, _b, year: number) => year,
  ],
  // Output selector gets `(items, agency, year)` as args
  (items, agency, year) => {
    const groupedMap = new Map<number, ClientMasterDtoTaxEntitiesClientTaxEntity[]>();
    
    //If the agency is a city also include County and School District as valid options and get those entities.
    const validAgencies = [agency];
    if (agency === 'City' || agency === 'School') validAgencies.push('County', 'School District');

    const agencyEntities = items.filter((entity) => { return validAgencies.includes(entity.agency ?? ''); });

    for (const item of agencyEntities) {
      if (!groupedMap.has(item?.entityId ?? -1)) groupedMap.set(item?.entityId ?? -1, []);
      groupedMap.get(item?.entityId ?? -1)?.push(item);
    }

    const returnArray = new Array<ClientMasterDtoTaxEntitiesClientTaxEntity>;

    for (const item of groupedMap.keys()) {
      const stateEntities = groupedMap.get(item);
      let filteredStates = stateEntities?.filter((entity) => { return entity.effectiveYear! <= year; });
      if ((filteredStates?.length ?? 0) > 0) {
        const maxTaxEntityYear = filteredStates?.reduce((prev, current) => { return (prev?.effectiveYear ?? 0) > (current?.effectiveYear ?? 0) ? prev : current; }).effectiveYear;

        filteredStates = filteredStates?.filter((entity) => {return entity.effectiveYear === maxTaxEntityYear;});
      }
      returnArray.push(...filteredStates ?? []);
    }

    return returnArray;
  },
);

function sortByName<T extends (ClientMasterDtoTaxEntitiesClientTaxEntity)>(a: T, b: T) {
  const aName = a.name?.toLowerCase().trim();
  const bName = b.name?.toLowerCase().trim();
  if (!aName || !bName) return 0;
  if (aName < bName) return -1;
  if (aName > bName) return 1;

  return 0;
}

export const selectClientAgencyDropDown = createSelector(
  [selectClientTaxEntitiesByAgency],
  (items) => {
    return items.sort(sortByName<ClientMasterDtoTaxEntitiesClientTaxEntity>).map((item) => {
      return {
        id: item.entityId,
        description: item.name,
        code: item.code,
        agency: item.agency,
      };
    });
  },
);

export const getTaxEntitiesIOption = createSelector(
  taxState,
  (state: TaxState) => {
    return state.clientMasterDtoTaxEntitiesClientTaxEntity.map(e => {
      return { label: `${e.name}`, value: e.entityId, displayValue: e.entityId };
    }) as ISelectMultipleOption[];
  },
);

export const selectClientTaxFilingStatus = createSelector(
  [selectClientTaxEntitiesByAgency],
  // Output selector gets (`items, category)` as args
  (items) => {return items.map((a) => {return a.filingStatuses;});},
);

export const getTaxMessages = createSelector(
  taxState,
  (state: TaxState): TaxState => {
    return {
      loading: state.loading,
      taxes: null,
      w4Info: [],
      clientMasterDtoTaxEntitiesClientTaxEntity: [],
      messages: state.messages,
      isSuccess: state.isSuccess,
    };
  },
);
