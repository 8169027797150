import React from 'react';
import Modal from './Modal';

type Props = {
  message: string;
  show: boolean;
  onHide: () => void;
};

const PDFPasswordEmailModal = ({ message, show, onHide }: Props) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Email sent"
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <div>{message}</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <button
            className="btn btn-primary orange-button mt-2 mb-2"
            onClick={onHide}
          >
            Okay
          </button>
        </div>
      </div>
    </Modal>

  );
};
export default PDFPasswordEmailModal;
