import React, { useEffect, useState } from 'react';
import { YTDTax } from 'core/models';
import { currencyFormatter } from 'utilities/utilities';

const getData = (data: YTDTax[]): any[] => {
  const result: any[] = [];
  data.forEach((item: YTDTax) => {
    const id = item.id.toString()[0];

    result.push({
      ...item,
      wages: (['5'].includes(id) || item.wages === 0) ? ' ' : currencyFormatter(item.wages),
      withholding: (['5', '6', '7'].includes(id) || item.withholding === 0) ? ' ' : currencyFormatter(item.withholding),
      companyPayrollTaxes: (['1', '3', '5'].includes(id) || item.companyPayrollTaxes === 0) ? ' ' : currencyFormatter(item.companyPayrollTaxes),
    });
  });

  return result;
};

type PropTypes = {
  items: YTDTax[] | undefined;
};

const YTDTaxes: React.FC<PropTypes> = ({ items }) => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if (!items) return;
    setData(getData(items));
  }, [items]);

  return (
    <div
      className="mr-3"
      style={{ minWidth: '400px' }}
    >
      <h3>Taxes</h3>
      <table className="table table-sm w-100" >
        <colgroup>
          <col style={{ width: '34%' }} />
          <col style={{ width: '22%' }} />
          <col style={{ width: '22%' }} />
          <col style={{ width: '22%' }} />
        </colgroup>
        <thead className="thead-light">
          <tr>
            <th>Type</th>
            <th>Wages</th>
            <th>W/H</th>
            <th>Co P/R Taxes</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item: YTDTax, index: number) => {
            return (
              <tr key={index} >
                {item.id.toString().includes('.')
                  ? (<td>&nbsp;&nbsp;&nbsp;&nbsp;{item.type}</td>)
                  :
                  (<td style={{ height: '29px',
                    backgroundColor: item.type === 'BLANK' ? '#ffffe6' : '#fff' }}
                  >
                    {item.type === 'BLANK' ? ' ' : item.type}
                  </td>)
                }
                <td 
                  className="text-right"
                  style={{
                    minWidth: '100px',
                    backgroundColor: '' + item.wages === ' ' ? '#ffffe6' : '#fff',
                  }}
                >{item.wages}</td>
                <td 
                  className="text-right"
                  style={{
                    minWidth: '100px',
                    backgroundColor: '' + item.withholding === ' ' ? '#ffffe6' : '#fff',
                  }}
                >{item.withholding}</td>
                <td 
                  className="text-right"
                  style={{ backgroundColor: '' + item.companyPayrollTaxes === ' ' ? '#ffffe6' : '#fff' }}
                >
                  {item.companyPayrollTaxes}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default YTDTaxes;
