

export interface PayrollUploadVM {
  clientMaps: PayrollUpload[];
  fields: PayrollUploadFields[];
}

export interface PayrollUploadMap {
  customUploadMapId: number;
  mapId: number;
  fieldOrdinal: number;
  uploadFieldId: number;
  startPosition: number;
  length: number;
  decimals: number;
  defaultValue: string;
  deductionCode: string;
  earningCode: string;
  otherPayrollInfoType: string;
  otherPayrollInfoDesc: string;
  transOtherInfoId?: number;
  translations: PayrollUploadFieldTranslation[];
  earningCodeExceptions: PayrollUploadEarnCodeExceptionMap[];
  earningCodeRates: PayrollUploadEarnCodeRateMap[];
}

export interface PayrollUploadDefault {
  clientNo: number;
  fileExtension: string;
}

export interface PayrollUpload {
  mapId: number;
  code: string;
  description: string;
  comments: string;
  style: string;
  delimiter: string;
  quotedStrings: boolean;
  clients: PayrollUploadClient[];
  fields: PayrollUploadMap[];
}

export interface PayrollUploadClient {
  clientUploadId: number;
  clientNo: number;
  mapId: number;
  active: boolean;
  startOnLine: number;
  clientNumberInFile: boolean;
  combineHours: boolean;
  useClockNumber: boolean;
  trackSalaryEmpOtherHours: boolean;
  defaultUploadPath: string;
  defaultUploadFileName: string;
  matchMultiRateByDept: boolean;
  matchMultiRateBySubDept: boolean;
  matchMultiRateBySubDept2: boolean;
  matchMultiRateByDescription: boolean;
}

export interface PayrollUploadFields {
  uploadFieldId: number;
  actualFieldname: string;
  visibleFieldName: string;
  visiblePosition: number;
  visible: boolean;
}

export interface PayrollUploadFieldTranslation {
  translationId: number;
  customUploadMapId: number;
  originalValue: string;
  translatedValue: string;
}

export interface PayrollUploadEarnCodeRateMap {
  earnCodeRateMapId: number;
  customUploadMapId: number;
  earnCode: string;
  rateField: string;
}

export interface PayrollUploadEarnCodeExceptionMap {
  exceptionMapId: number;
  custUploadMapId: number;
  earnCode: string;
  mapToField: string;
}

export interface PayrollUploadFile {
  mapId?: number | undefined;
  weekEnd: string;
  checkDate: string;
  locationCode?: number;
  departmentCode?: number;
  workWeek?: number;
  fileName: string;
  file: string;
  skipValidationErrors: boolean;
  overwrite: boolean;
  empNosToTrack: string[];
  uploadType: PayrollUploadType;
  //Properties for AOD Upload
  frequency: string;
  aodMapName: string;
  //Properties for On The Clock
  fromDate: Date | string | null;
  toDate: Date | string | null;
  onTheClockData: TimeCard[];
  uploadFile: boolean;
}

export interface PayrollUploadFileCheck {
  mapId: number,
  fileName: string,
  file: string
}

export enum PayrollUploadType {
  Standard = 'Standard',
  AttendanceOnDemand = 'AttendanceOnDemand',
  OnTheClock = 'OnTheClock',
}

export interface PayrollUploadErrors {
  clientNo: string,
  empNo: string,
  empName: string,
  error: string,
  isHighValidationError: boolean,
  isHighHourError: boolean
}

export interface PayrollUploadManageFiles {
  mapId: number,
  clientNo: number,
  description: string,
  active: boolean,
}

export interface OnTheClockRequest {
  toDate: Date | string,
  fromDate: Date | string
}

export interface TimeCard {
  accountID: string,
  timeCardEntityID: string, 
  employeeID: string,
  employeeNumber: string,
  timeCardEntryDay: string, 
  timeCardEntryDate: string,
  totalHours: number,
  regularHours: number,
  overTime1Hours: number,
  overTime2Hours: number,
  overTime3Hours: number,
  vacationHours: number,
  holidayHours: number,
  sickHours: number,
  personalHours: number,
  firstPunchInDateTime: string,
  lastPunchInDateTime: string,
  groupCount: number,
  timeAndAHalf: number
}

export interface AodPayrollUploadMap {
  clientNo: number,
  clientId: number,
  useMultipleMaps: boolean,
  maps: string[]
}