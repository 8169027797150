import { AxiosResponse } from "axios";
import { HttpResponse } from '../models';
import { AodPayrollUploadMap, OnTheClockRequest, PayrollUpload, PayrollUploadDefault, PayrollUploadEarnCodeExceptionMap, PayrollUploadEarnCodeRateMap, PayrollUploadFields, PayrollUploadFieldTranslation, PayrollUploadFile, PayrollUploadFileCheck, PayrollUploadManageFiles, PayrollUploadMap, PayrollUploadVM, TimeCard } from '../models/PayrollUpload.model';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getPayrollUpload = (): Promise<AxiosResponse<PayrollUploadVM>> => { return API_EMPLOYEE_MASTER.get<PayrollUploadVM>(EmployeeEndPoints.PAYROLL_UPLOAD(CommonService.getProtectedClientNo())); };

const postPayrollUpload = (data: PayrollUpload): Promise<AxiosResponse<PayrollUpload>> => { return API_EMPLOYEE_MASTER.post<PayrollUpload>(EmployeeEndPoints.PAYROLL_UPLOAD(CommonService.getProtectedClientNo()), data); };

const postDefaultPayrollUpload = (data: PayrollUploadDefault): Promise<AxiosResponse<PayrollUploadDefault>> => { return API_EMPLOYEE_MASTER.post<PayrollUploadDefault>(EmployeeEndPoints.PAYROLL_UPLOAD_MAP_DEFAULT(CommonService.getProtectedClientNo()), data); };

const putPayrollUpload = (data: PayrollUpload): Promise<AxiosResponse<PayrollUpload>> => { return API_EMPLOYEE_MASTER.put<PayrollUpload>(EmployeeEndPoints.PUT_PAYROLL_UPLOAD(CommonService.getProtectedClientNo(), data.mapId), data); };

const postPayrollUploadField = (data: PayrollUploadMap): Promise<AxiosResponse<PayrollUploadMap>> => { return API_EMPLOYEE_MASTER.post<PayrollUploadMap>(EmployeeEndPoints.PAYROLL_UPLOAD_FIELD(CommonService.getProtectedClientNo(), data.mapId), data); };

const putPayrollUploadField = (data: PayrollUploadMap): Promise<AxiosResponse<PayrollUploadMap>> => { return API_EMPLOYEE_MASTER.put<PayrollUploadMap>(EmployeeEndPoints.PAYROLL_UPLOAD_FIELD_ID(CommonService.getProtectedClientNo(), data.mapId, data.customUploadMapId), data); };

const deletePayrollUploadField = (data: PayrollUploadMap): Promise<AxiosResponse<any>> => { return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.PAYROLL_UPLOAD_FIELD_ID(CommonService.getProtectedClientNo(), data.mapId, data.customUploadMapId)); };

const postPayrollUploadFieldTranslation = (mapId: number, customUploadMapId: number, translation: PayrollUploadFieldTranslation): Promise<AxiosResponse<PayrollUploadFieldTranslation>> => { return API_EMPLOYEE_MASTER.post<PayrollUploadFieldTranslation>(EmployeeEndPoints.PAYROLL_UPLOAD_FIELD_TRANSLATIONS(CommonService.getProtectedClientNo(), mapId, customUploadMapId), translation); };

const putPayrollUploadFieldTranslation = (mapId: number, customUploadMapId: number, translation: PayrollUploadFieldTranslation): Promise<AxiosResponse<PayrollUploadFieldTranslation>> => { return API_EMPLOYEE_MASTER.put<PayrollUploadFieldTranslation>(EmployeeEndPoints.PAYROLL_UPLOAD_FIELD_TRANSLATIONS_ID(CommonService.getProtectedClientNo(), mapId, customUploadMapId, translation.translationId), translation); };

const deletePayrollUploadFieldTranslation = (mapId: number, customUploadMapId: number, translation: PayrollUploadFieldTranslation): Promise<AxiosResponse<any>> => { return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.PAYROLL_UPLOAD_FIELD_TRANSLATIONS_ID(CommonService.getProtectedClientNo(), mapId, customUploadMapId, translation.translationId)); };

const postPayrollUploadFieldException = (mapId: number, customUploadMapId: number, exception: PayrollUploadEarnCodeExceptionMap): Promise<AxiosResponse<PayrollUploadEarnCodeExceptionMap>> => { return API_EMPLOYEE_MASTER.post<PayrollUploadEarnCodeExceptionMap>(EmployeeEndPoints.PAYROLL_UPLOAD_FIELD_EXCEPTIONS(CommonService.getProtectedClientNo(), mapId, customUploadMapId), exception); };

const putPayrollUploadFieldException = (mapId: number, customUploadMapId: number, exception: PayrollUploadEarnCodeExceptionMap): Promise<AxiosResponse<PayrollUploadEarnCodeExceptionMap>> => { return API_EMPLOYEE_MASTER.put<PayrollUploadEarnCodeExceptionMap>(EmployeeEndPoints.PAYROLL_UPLOAD_FIELD_EXCEPTIONS_ID(CommonService.getProtectedClientNo(), mapId, customUploadMapId, exception.exceptionMapId), exception); };

const deletePayrollUploadFieldException = (mapId: number, customUploadMapId: number, exception: PayrollUploadEarnCodeExceptionMap): Promise<AxiosResponse<any>> => { return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.PAYROLL_UPLOAD_FIELD_EXCEPTIONS_ID(CommonService.getProtectedClientNo(), mapId, customUploadMapId, exception.exceptionMapId)); };

const postPayrollUploadFieldRate = (mapId: number, customUploadMapId: number, rate: PayrollUploadEarnCodeRateMap): Promise<AxiosResponse<PayrollUploadEarnCodeRateMap>> => { return API_EMPLOYEE_MASTER.post<PayrollUploadEarnCodeRateMap>(EmployeeEndPoints.PAYROLL_UPLOAD_FIELD_RATES(CommonService.getProtectedClientNo(), mapId, customUploadMapId), rate); };

const putPayrollUploadFieldRate = (mapId: number, customUploadMapId: number, rate: PayrollUploadEarnCodeRateMap): Promise<AxiosResponse<PayrollUploadEarnCodeRateMap>> => { return API_EMPLOYEE_MASTER.put<PayrollUploadEarnCodeRateMap>(EmployeeEndPoints.PAYROLL_UPLOAD_FIELD_RATES_ID(CommonService.getProtectedClientNo(), mapId, customUploadMapId, rate.earnCodeRateMapId), rate); };

const deletePayrollUploadFieldRate = (mapId: number, customUploadMapId: number, rate: PayrollUploadEarnCodeRateMap): Promise<AxiosResponse<any>> => { return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.PAYROLL_UPLOAD_FIELD_RATES_ID(CommonService.getProtectedClientNo(), mapId, customUploadMapId, rate.earnCodeRateMapId)); };

const putPayrollUploadFileTool = (data: PayrollUploadFile): Promise<AxiosResponse<PayrollUploadFile>> => { return API_EMPLOYEE_MASTER.put<PayrollUploadFile>(EmployeeEndPoints.PUT_PAYROLL_UPLOAD_FILE(CommonService.getProtectedClientNo()), data); };

const putPayrollUploadFileCheckTool = (data: PayrollUploadFileCheck): Promise<AxiosResponse<PayrollUploadFileCheck>> => { return API_EMPLOYEE_MASTER.put<PayrollUploadFileCheck>(EmployeeEndPoints.PUT_PAYROLL_UPLOAD_FILE_CHECK(CommonService.getProtectedClientNo()), data); };

const getOnTheClockData = (data: OnTheClockRequest): Promise<AxiosResponse<TimeCard[]>> => { return API_EMPLOYEE_MASTER.get<TimeCard[]>(EmployeeEndPoints.GET_ONTHECLOCK_DATA(CommonService.getProtectedClientNo(), data)); };

const getAodPayrollUploadMap = (): Promise<AxiosResponse<AodPayrollUploadMap>> => { return API_EMPLOYEE_MASTER.get<AodPayrollUploadMap>(EmployeeEndPoints.GET_AOD_MAPS(CommonService.getProtectedClientNo())); };

const savePayrollUploadMapActiveStatuses = (data: PayrollUploadManageFiles[]): Promise<AxiosResponse<any>> => { return API_EMPLOYEE_MASTER.put<PayrollUploadManageFiles[]>(EmployeeEndPoints.PUT_PAYROLL_UPLOAD_MAPS_UPDATE_ACTIVE_STATUS(CommonService.getProtectedClientNo()), data); };

const copyPayrollUploadMap = (mapId: number): Promise<AxiosResponse<any>> => { return API_EMPLOYEE_MASTER.post<PayrollUploadMap>(EmployeeEndPoints.PAYROLL_UPLOAD_COPY_MAP(CommonService.getProtectedClientNo(), mapId)); };

export const PayrollUploadService = {
  getPayrollUpload,
  postPayrollUpload,
  postDefaultPayrollUpload,
  putPayrollUpload,
  postPayrollUploadField,
  putPayrollUploadField,
  deletePayrollUploadField,
  postPayrollUploadFieldTranslation,
  putPayrollUploadFieldTranslation,
  deletePayrollUploadFieldTranslation,
  postPayrollUploadFieldException,
  putPayrollUploadFieldException,
  deletePayrollUploadFieldException,
  postPayrollUploadFieldRate,
  putPayrollUploadFieldRate,
  deletePayrollUploadFieldRate,
  putPayrollUploadFileTool,
  putPayrollUploadFileCheckTool,
  getOnTheClockData,
  getAodPayrollUploadMap,
  savePayrollUploadMapActiveStatuses,
  copyPayrollUploadMap,
};
