import { useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Client, DtoOptions } from 'core/models';
import { useAppSelector } from 'utilities/hooks';
import InputReportDateSelector from 'core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { JobLaborRequest } from 'core/models/ContractorReports.model';
import {
  clearJobLaborReport,
  downloadJobLaborReport,
} from 'core/store/slices/contractorReports.slice';

const JobLaborReport = () => {
  const dispatch = useDispatch();

  const childRef: React.RefObject<any> = useRef();

  const report = useAppSelector(
    (state) => { return state.contractorReports.jobLaborReport; },
  );
  const client = useAppSelector((state) => { return state.client.client; }) as Client;
  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [, setRadioOption] = useState('');

  const getDataFromInputDateSelector = (data: any) => {
    setRadioOption(data);
  };

  const downloadReport = () => {
    const submitData: JobLaborRequest = {
      clientNo: client.clientNo,
      dtoOption: reportDates[0],
      reportType: 'pdf',
    };
    dispatch(downloadJobLaborReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearJobLaborReport());
  };

  return (
    <Row className="w-100">
      <Col>
        <div className="dm-panel dm-panel-border">
          <div className="dm-grid-title">Job Labor Report</div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div>
              <InputReportDateSelector
                ref={childRef}
                returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }
                }
                passDataFromInputDateSelector={
                  getDataFromInputDateSelector
                }
              />
            </div>
            <div className="flex-grow-1 text-right">
              <button
                type="button"
                className="orange-outline-button mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="orange-button mr-2"
                onClick={downloadReport}
                disabled={!reportDates.length}
              >
                Run Report
              </button>
            </div>
          </div>
        </div>
        {report ? (
          <PDFViewerModal
            show={report.length > 0}
            pdfData={report}
            onHide={hidePdfViewer}
            reportName="JobLaborReport"
          />
        ) : null}
      </Col>
    </Row>
  );
};
export default JobLaborReport;
