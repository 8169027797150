import React, { CSSProperties, useContext } from 'react';
import './time-card-styles.scss'; // TODO: module
import Icon from 'core/components/shared/Icon';
import TimeEntrySection from './TimeEntrySection';
import JobInfoSection from './JobInfoSection';
import RateEarnings from './RateEarnings';
import Earnings from './Earnings';
import { DetailArrayField } from 'core/models';
import { TimeCardContext } from './TimeCardContext';

export const inputGrpStyle: CSSProperties = {
  width: '50%',
  marginBottom: '0',
};

type Props = {
  index: number;
  timeSheet: DetailArrayField;
  deleteTimeSheet: (id: string | undefined) => void;
};

const ContractorTimeSheet = ({ index, timeSheet, deleteTimeSheet }: Props) => {
  const { updateDirtyState } = useContext(TimeCardContext);
  
  return (
    <div className="time-card">
      <div className="time-card-body dm-panel dm-panel-border">
        <JobInfoSection
          timeSheet={timeSheet}
          index={index}
        />
        <TimeEntrySection
          timeSheet={timeSheet}
          index={index}
        />
        <RateEarnings
          timeSheet={timeSheet}
          index={index}
        />
        <div className="wide-section-2">
          <Earnings
            timeSheet={timeSheet}
            index={index}
          />
          <div className="delete-card-action-wrapper">
            <button
              className="section-action btn btn-link dm-grid-action-title p-0 my-1"
              onClick={() => {
                deleteTimeSheet(timeSheet.id);
                updateDirtyState(true);
              }}
            >
              Delete Entry Card&nbsp;<Icon
                name="minus-circle"
                className="fa-minus-circle"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractorTimeSheet;