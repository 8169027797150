import { Component } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { NavLink } from 'react-router-dom';

interface LinkRendererComponentParams extends ICellRendererParams {
  href: string;
  idProperty?: string;
  valueGetter?: (data: any) => string;
}

export default class LinkRendererComponent extends Component {
  constructor(public props: LinkRendererComponentParams) {
    super(props);
  }

  componentWillUnmount() {}

  render() {
    const value = this.props.valueGetter
      ? this.props.valueGetter(this.props.data)
      : this.props.value;
    const href = this.props.idProperty
      ? this.props.href.replace(
        /{protectedEmpNo}/,
        this.props.data[this.props.idProperty],
      )
      : this.props.href;

    return <NavLink to={href}>{value}</NavLink>;
  }
}
