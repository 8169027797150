import React from 'react';
import { YTDDeduction } from '../../../core/models/year-to-date.model';
import { currencyFormatter } from '../../../utilities/utilities';

type PropTypes = {
  items: YTDDeduction[] | undefined;
};

const YTDCompanyBenefits: React.FC<PropTypes> = ({ items }) => {

  const dedNoFilter = (dedNo: number) => {
    if(dedNo === -1 || dedNo === -2) return '';
    else return dedNo.toString();
  }

  return (<>
    <div style={{ minWidth: '300px' }}>
      <h3>Company Benefits</h3>
      <table className="table table-sm w-100">
        <colgroup>
          <col style={{ width: '20%' }} />
          <col style={{ width: '40%' }} />
          <col style={{ width: '40%' }} />
        </colgroup>
        <thead className="thead-light">
          <tr>
            <th>Code</th>
            <th>Code Desc</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item: YTDDeduction, index: number) => {
            return (
              <tr key={index}>
                <td>{dedNoFilter(item.dedNo)}</td>
                <td>{item.shortDescription}</td>
                <td className="text-right">{currencyFormatter(item.dedAmount)}</td>
              </tr>
            );
          })}

        </tbody>
      </table>
    </div>
  </>);
};

export default YTDCompanyBenefits;
