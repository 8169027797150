import React, { useState, useRef, CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { Client, DtoOptions } from '../../core/models';
import { useAppSelector } from '../../utilities/hooks';
import InputReportDateSelector from '../../core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import { EmployeeTimeCardReqest } from '../../core/models/ContractorReports.model';
import { clearEmployeeTimeCardReport, downloadEmployeeTimeCardReport } from '../../core/store/slices/contractorReports.slice';
import { useForm } from 'react-hook-form';

const panelStyle: CSSProperties = { marginTop: '66px',
  width: '20%' };

const EmployeeTimeCardReport = () => {
  const dispatch = useDispatch();

  const childRef: React.RefObject<any> = useRef();

  const report = useAppSelector((state) => {return state.contractorReports.employeeTimeCardReport;});
  const client = useAppSelector((state) => {return state.client.client;}) as Client;
  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);

  const { handleSubmit } = useForm<EmployeeTimeCardReqest>();

  const downloadReport = () => {
    const submitData: EmployeeTimeCardReqest = {
      clientNo: client.clientNo,
      dtoOption: reportDates[0],
      alreadyRanPayroll: true,
    };
    dispatch(downloadEmployeeTimeCardReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearEmployeeTimeCardReport());
  };

  return (

    <div className="dm-panel dm-panel-border" >
      <form onSubmit={handleSubmit(downloadReport)}>

        <div className="dm-grid-title">Employee Time Cards Report</div>
        <hr className="dm-panel-hr" />

        <div className="d-flex flex-row flex-grow-1" >
          <div>
            <InputReportDateSelector
              ref={childRef}
              isSingleSelect={true}
              hideSelectAllBtn={true}
              greenHighlights={true}
              returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }}
              showOpenDatelines={true}
            />
          </div>
          <div className="d-flex flex-column flex-grow-1">
            <div className="text-right" >
              <button
                type="button"
                className="orange-outline-button mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="orange-button mr-2"
                disabled={!reportDates.length}>
                Run Report
              </button>
            </div>
            <div
              className="dm-panel dm-panel-border"
              style={panelStyle}
            >
              <div className="dm-grid-action-title mb-2">
                Note:
              </div>
              <div>
                <p>
                  The dates highlighted in Green indicate payrolls
                  that have not run. Selecting one of the dates will
                  generate a blank Time Card that somebody must complete.</p>
              </div>
            </div>
          </div>
        </div>
      </form>
      {
        report ? <PDFViewerModal
          show={report.length > 0}
          pdfData={report}
          onHide={hidePdfViewer}
          reportName="EmployeeTimeCardReport"
        /> : null
      }

    </div>


  );

};
export default EmployeeTimeCardReport;


