import React, { useEffect, useState } from 'react';
import Level1Toggle from '../form-controls/nested-accordion/Level1Toggle';
import NestedAccordion from '../form-controls/nested-accordion/NestedAccordion';
import { useDispatch, useSelector } from 'react-redux';
import { clearEmpDocumentPDF, handleError, loadEmpDocumentPDF, loadEmpDocuments, postDownloadMultipleFiles } from 'core/store/actions';
import { empDocumentsForAccordion, getClientOptionRequirePassword, getEmpDocumentPDF, getSelectedEmp } from 'core/store/selectors';
import FileItem from '../form-controls/nested-accordion/FileItem';
import Level2Toggle from '../form-controls/nested-accordion/Level2Toggle';
import { NestedAccordionItem } from '../form-controls/nested-accordion/NestedAccordionItem';
import PDFViewerModal from './pdf-viewer.modal';
import Modal from './Modal';
import { MultipleFileRequest } from 'core/models/File.model';
import { FileService } from 'core/services/file.service';
import { useAppDispatch } from 'utilities/hooks';
import PasswordModal from './Password.modal';
import LoadingBar from '../shared/LoadingBar';
import { fileDownloadHeaderButton } from '../shared/FileDownload';

const toggleComponents: any[] = [Level1Toggle, Level2Toggle, FileItem];

type PropTypes = {
  show: boolean;
  onHide: () => void;
};

//TODO: this is NASTYYYYY come back and update backend to have the name as 'path' in all these so we can type this instead of any.
const getPath = (file: any) => 
      (!!Object.getOwnPropertyDescriptor(file, 'path') && file?.path) ||
      (!!Object.getOwnPropertyDescriptor(file, 'filePath') && file?.filePath) ||
      (!!Object.getOwnPropertyDescriptor(file, 'pathName') && file?.pathName) ||
      (!!Object.getOwnPropertyDescriptor(file, 'pathAndNameOnDisk') && file?.pathAndNameOnDisk);

const EmpDocsModal: React.FC<PropTypes> = ({ show, onHide }) => {
  const [showPDF, setShowPDF] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showLoadingBar, setShowLoadingBar] = useState<boolean>(false);
  const requirePassword = useSelector(getClientOptionRequirePassword);

  const dispatch = useAppDispatch();
  
  const employee = useSelector(getSelectedEmp);
  const empTaxDocs: NestedAccordionItem[] = useSelector(empDocumentsForAccordion);
  const pdfData: { name: string, data: string, bdata: string } = useSelector(getEmpDocumentPDF);

  useEffect(() => {
    if (!employee) return;
    dispatch(loadEmpDocuments(employee.protectedEmpNo));
  }, [dispatch, employee]);

  // doc is I9File | HireToRetireFile | OnboardFile | DirectDepositForm
  const onClick = (doc: any) => {
    const path = getPath(doc);    
    if (!path) return dispatch(handleError('Error loading file'));
    
    dispatch(loadEmpDocumentPDF(path));
    setShowPDF(true);
  };

  const onCheckFile = (file: any) => {
    const path = getPath(file);
    if(selectedFiles.includes(file)) setSelectedFiles(selectedFiles.filter(x => x.path !== path));
    else setSelectedFiles([...selectedFiles, file]);
  };

  const onClickDownload = () => {
    if(!selectedFiles || !employee) return dispatch(handleError('Error While Downloading File(s)'));
    if(requirePassword) return setShowPasswordModal(true);
    setShowLoadingBar(true);

    const paths = selectedFiles.map((file) => {
      const path: string = getPath(file);
      return path;
    });

    if(!paths) return dispatch(handleError('Error While Downloading File(s)'));

    const request : MultipleFileRequest = {
      usesFilePassword: false,
      filePassword: '',
      paths: paths,
      clientNo: employee.clientNo,
      folderName: 'Employee Docs'
    };

    dispatch(postDownloadMultipleFiles(() => {return FileService.postDownloadMultipleFiles(request);})).then(() => {dispatch(setShowLoadingBar(false));});
  };

  const addPasswordToPdf = (passWord: string) => {
    if(!selectedFiles || !employee) return dispatch(handleError('Error While Downloading File(s)'));
    setShowLoadingBar(true);

    const paths = selectedFiles.map((file) => {
      const path: string = file?.path || '';
      return path;
    });
    if(!paths) return dispatch(handleError('Error While Downloading File(s)'));

    const request : MultipleFileRequest = {
      usesFilePassword: true,
      filePassword: passWord,
      paths: paths,
      clientNo: employee.clientNo,
      folderName: 'Employee Docs'
    };
    
    dispatch(postDownloadMultipleFiles(() => {return FileService.postDownloadMultipleFiles(request);})).then(() => {dispatch(setShowLoadingBar(false));});
  };

  return (
  <>
    <Modal
      show={show}
      onHide={onHide}
      title="EMPLOYEE DOCUMENTS"
      headerSlot={fileDownloadHeaderButton(onClickDownload, selectedFiles)}
    >
      {empTaxDocs.map((item) => {
        return (
          <NestedAccordion
            key={item.id}
            level={1}
            item={item}
            toggleComponents={toggleComponents}
            onClick={onClick}
            onCheckBoxClick={onCheckFile}
            showCheckBox={true}
          />
        );
      })}
      {showPDF && pdfData && (
        <PDFViewerModal
          show={showPDF}
          onHide={() => {
            setShowPDF(false);
            dispatch(clearEmpDocumentPDF());
          }}
          pdfData={pdfData.data}
          reportName="EmployeeDocuments"
        />
      )}
    </Modal>
    <PasswordModal
      show={showPasswordModal}
      onHide={() => {setShowPasswordModal(false);}}
      addPasswordToPdf={addPasswordToPdf}
    />
    <LoadingBar
      showLoadingBar={showLoadingBar}
      loadingMessage={'Downloading Files...'}  
    />
  </>
  );
};
export default EmpDocsModal;
