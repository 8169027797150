import { createAction } from '@reduxjs/toolkit';
import { AttendanceOnDemand, ClientAod, WorkgroupItem } from '../../models';

export const loadAttendanceOnDemand = createAction<string>('[attendance-on-demand] LOAD_ATTENDANCE_ON_DEMAND');
export const storeAttendanceOnDemand = createAction<AttendanceOnDemand>('[attendance-on-demand] STORE_ATTENDANCE_ON_DEMAND');

export const updateAttendanceOnDemand = createAction<AttendanceOnDemand>('[attendance-on-demand] UPDATE_ATTENDANCE_ON_DEMAND');

export const loadClientAttendanceOnDemand = createAction<{ suppressError?: boolean }>('[attendance-on-demand] LOAD_CLIENT_ATTENDANCE_ON_DEMAND');
export const storeClientAttendanceOnDemand = createAction<ClientAod>('[attendance-on-demand] STORE_CLIENT_ATTENDANCE_ON_DEMAND');

// todo: i bet these might use the same type just created for the client aod workgroup dd
export const createClientAODWorkgroup = createAction<any>('[attendance-on-demand] CREATE_CLIENT_ATTENDANCE_ON_DEMAND_WORKGROUP');
export const updateClientAODWorkgroup = createAction<any>('[attendance-on-demand] UPDATE_CLIENT_ATTENDANCE_ON_DEMAND_WORKGROUP');
export const storeClientAODWorkgroup = createAction<WorkgroupItem[]>('[attendance-on-demand] STORE_CLIENT_ATTENDANCE_ON_DEMAND_WORKGROUP');