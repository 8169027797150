import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class VisaStatus {
  empId = 0;

  clientNo = 0;

  empNo = 0;

  protectedEmpNo = '';

  visaTypeId = 0;

  issueDate: Date | string | null = null;

  expireDate: Date | string | null = null;

  static readonly convProps: PropTypeLists = {
    dateProps: ['issueDate', 'expireDate'],
    numberProps: ['empID', 'visaTypeId'],
  };

  constructor(
    clientNo: number | string,
    empNo: string | number,
    protectedEmpNo: string,
    props?: { [key: string]: any }) {

    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;
    props && convToClass(this, props, VisaStatus.convProps);
  }
}