import { GenericFieldInputSettings } from 'core/components/form-controls/types';
import { PayrollTimeCardCostCodeInfo } from 'core/models/Contractor.model';

// Form config
export const fieldSettings: GenericFieldInputSettings<Partial<PayrollTimeCardCostCodeInfo>> = {
  costCode: {
    name: 'costCode',
    label: 'Cost Code',
  },
  description: {
    name: 'description',
    label: 'Description',
  },
  excludeFromWH347: {
    name: 'excludeFromWH347',
  },
};
