import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  InputGrp,
  RadioGrp,
  SelectGrp,
} from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import PanelHeader from 'core/components/shared/PanelHeader';
import { ControlIds, UNSAVED_MESSAGE } from 'core/constants';
import { EmpGroupTerm, Employee } from 'core/models';
import {
  loadEmployeeGroupTerm,
  toggleBlockNavigation,
  updateEmployeeGroupTerm,
} from 'core/store/actions';
import {
  getGroupTermLifeEarningsCodes,
  getEmpGroupTerm,
  getSelectedEmp,
} from 'core/store/selectors';
import { useAppSelector, useRadioButtonChange } from 'utilities/hooks';
import {  cleanAmount, currencyFormatter, dateDiff, formatWithCommas, getAccess } from 'utilities/utilities';
import SaveButton from 'core/components/shared/SaveButton';
import { DatePickerGrp } from 'core/components/form-controls/DatePickerGrp';

const EXCESS = 50000;

const GroupTermLifePage = () => {
  const [fs, setFs] = useState<FieldInputSettings>({
    startDate: {
      name: 'startDate',
      label: 'START DATE',
      groupClass: 'groupClass10',
      controlId: 200,
    },
    multiplier: {
      name: 'multiplier',
      type: 'number',
      step: '0.01',
      label: 'MULTIPLE OF SALARY',
      groupClass: 'groupClass12',
      inputStyle: { textAlign: 'right' },
      controlId: 201,
    },
    amountOfIns: {
      name: 'amountOfIns',
      label: 'AMOUNT OF GROUP TERM LIFE',
      groupClass: 'groupClass15',
      inputStyle: { textAlign: 'right' },
      controlId: 202,
    },
    age: {
      name: 'age',
      type: 'number',
      label: 'AGE AT END OF YEAR',
      groupClass: 'groupClass10',
      inputStyle: { textAlign: 'right' },
      controlId: 203,
    },
    monthlyCostPerThousand: {
      name: 'monthlyCostPerThousand',
      label: 'MONTHLY COST PER 1000',
      groupClass: 'groupClass12',
      inputStyle: { textAlign: 'right' },
      controlId: 204,
    },
    excessIns: {
      name: 'excessIns',
      label: 'EXCESS GROUP TERM LIFE INS',
      groupClass: 'groupClass12',
      inputStyle: { textAlign: 'right' },
      controlId: 205,
    },
    annualCost: {
      name: 'annualCost',
      label: 'ANNUAL COST',
      groupClass: 'groupClass12',
      inputStyle: { textAlign: 'right' },
      controlId: 206,
    },
    perPayAmount: {
      name: 'perPayAmount',
      label: 'DEDUCTION PER PAY',
      groupClass: 'groupClass15',
      inputStyle: { textAlign: 'right' },
      controlId: 207,
    },
    earningsCode: {
      name: 'earningsCode',
      label: 'EARNINGS CODE',
      groupClass: 'groupClass20',
      controlId: 208,
    },
    autoAddToRecurringEarns: {
      name: 'autoAddToRecurringEarns',
      label: 'AUTOMATICALLY ADD TO RECURRING EARNINGS',
      groupClass: 'groupClass20',
      controlId: 209,
    },
  });

  const [handleRadioButtonChange] = useRadioButtonChange(fs);

  const dispatch = useDispatch();
  
  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();
  
  const groupTermLifeFromStore = useSelector(getEmpGroupTerm);
  const selEmp = useSelector(getSelectedEmp) as Employee;
  const earningsCodeOpts = useSelector(getGroupTermLifeEarningsCodes);
  const { saving } = useAppSelector((state) => state.selEmployeeDetails.empGroupTerm);
  const sectionAccess = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections);
  
  const visible = getAccess(sectionAccess, 32)?.visible;
  
  const [localGtl, setLocalGtl] = useState<EmpGroupTerm>(groupTermLifeFromStore);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [addRecurringDisabled, setDisableAddRecurring] = useState<boolean>(true);
  
  const multiplierInputRef = useRef<HTMLInputElement | null>(null);
  const insuranceAmtInputRef = useRef<HTMLInputElement | null>(null);
  const dedPerPayInputRef = useRef<HTMLInputElement | null>(null);
  
  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(loadEmployeeGroupTerm({ protectedEmpNo }));
    }
  }, [protectedEmpNo]);
  
  useEffect(() => {
    // update state
    setLocalGtl(groupTermLifeFromStore);
    setDisableAddRecurring(!groupTermLifeFromStore?.earningsCode || groupTermLifeFromStore?.earningsCode?.trim() === '');
    setFs((prevState) => ({
      ...prevState,
      autoAddToRecurringEarns: {
        ...prevState.autoAddToRecurringEarns,
        defaultChecked: groupTermLifeFromStore.autoAddToRecurringEarns,
      },
    }));
    setIsDirty(false);
    
    // update refs
    if (multiplierInputRef?.current) multiplierInputRef.current.value = formatWithCommas(groupTermLifeFromStore.multiplier);
    if (insuranceAmtInputRef?.current) insuranceAmtInputRef.current.value = currencyFormatter(groupTermLifeFromStore.amountOfIns);
    if (dedPerPayInputRef?.current) dedPerPayInputRef.current.value = currencyFormatter(groupTermLifeFromStore.perPayAmount);
    
  }, [groupTermLifeFromStore]);
  
  const updateValue = (key: keyof EmpGroupTerm, value: any) => {
    setIsDirty(true);
    setLocalGtl((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  
  const calculateAnnualCost = (insuranceAmt: number) => {
    const annualAmt = (insuranceAmt - EXCESS) / 1000;
    return (annualAmt * localGtl.monthlyCostPerThousand) * 12;
  };
  
  const calculateDeductionPerPay = (annualCost: number) => {
    switch (localGtl.payPeriod) {
      case 'W':
        return parseFloat((annualCost / 52).toFixed(2));
      case 'B':
        return parseFloat((annualCost / 26).toFixed(2));
      case 'S':
        return parseFloat((annualCost / 24).toFixed(2));
      case 'M':
        return parseFloat((annualCost / 12).toFixed(2));
      default:
        return parseFloat((annualCost / 52).toFixed(2));
    }
  };
  
  const recalculate = (insuranceAmt: number) => {
    // if insuranceAMt > client's wage cap, reduce to wage cap
    const insurance = Math.min(insuranceAmt, localGtl.wageCap);
    // difference between insuranceAmt and excess (down to 0)
    const excessInsurance = Math.max(insurance - EXCESS, 0);
    const annualCost = calculateAnnualCost(insurance);
    const dedPerPay = calculateDeductionPerPay(annualCost);

    return {
      insurance,
      excessInsurance,
      annualCost,
      dedPerPay,
    };
  };

  const handleEarningChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const disabled = e.target.value === null || e.target.value === '';
    
    setDisableAddRecurring(disabled);
    
    if (disabled) {
      const currentState = { ...fs };
      currentState.autoAddToRecurringEarns.defaultChecked = 'false';
      setFs(currentState);
    }
    
    updateValue('earningsCode', e.target.value);
  };
  
  const handleMultiplierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const floatMultiplier = parseFloat(e.target.value);
    let amountOfInsurance = localGtl.amountOfIns;
    
    if (floatMultiplier > 0) {
      // if multiplier > 0, set amountOfIns to multiplier * emp's annual rate
      amountOfInsurance = floatMultiplier * localGtl.annualRate;
    }
    
    const { insurance, excessInsurance, annualCost, dedPerPay } = recalculate(amountOfInsurance);
    
    // update state with the raw values.
    setIsDirty(true);
    setLocalGtl((prevState) => ({
      ...prevState,
      multiplier: floatMultiplier,
      amountOfIns: insurance,
      excessIns: excessInsurance,
      annualCost: annualCost,
      perPayAmount: dedPerPay,
    }));
    
    // update ref(s) with the formatted values.
    if (insuranceAmtInputRef?.current) insuranceAmtInputRef.current.value = currencyFormatter(insurance);
    if (dedPerPayInputRef?.current) dedPerPayInputRef.current.value = currencyFormatter(dedPerPay);
  };
  
  const handleChangeAmountOfIns = (insuranceAmt: number) => {
    const { insurance, excessInsurance, annualCost, dedPerPay } = recalculate(insuranceAmt);
    // update state with raw values
    setLocalGtl((prevState) => ({
      ...prevState,
      amountOfIns: insurance,
      excessIns: excessInsurance,
      annualCost: annualCost,
      perPayAmount: dedPerPay,
    }));
    setIsDirty(true);
    
    // update ref(s) with formatted values (other ones don't matter because only this one is enabled)
    if (dedPerPayInputRef?.current) dedPerPayInputRef.current.value = currencyFormatter(dedPerPay);
  };
    
  const onSave = (data: EmpGroupTerm) => {
    const groupTermLife = new EmpGroupTerm(selEmp.empId, selEmp.protectedEmpNo, structuredClone(data));

    groupTermLife.annualRate = groupTermLifeFromStore.annualRate;
    groupTermLife.wageCap = groupTermLifeFromStore.wageCap;
    groupTermLife.rounding = groupTermLifeFromStore.rounding;
    groupTermLife.protectedClientNo = groupTermLifeFromStore.protectedClientNo;
    groupTermLife.multiplier = +groupTermLife.multiplier;

    dispatch(updateEmployeeGroupTerm(groupTermLife));
  };
  
  const getAmount = (newAmount: string): number => {
    if (!newAmount.length || isNaN(parseFloat(cleanAmount(newAmount)))) return 0;
    return parseFloat(cleanAmount(newAmount));
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [isDirty]);

  return (
    <div className="dm-panel dm-panel-border">
      {visible && (
        <>
          <PanelHeader title="Group Term Life" />
          <div className="d-flex flex-wrap mt-3">
            <DatePickerGrp
              {...fs.startDate}
              {...getAccess(sectionAccess, 32, ControlIds.startDate)}
              value={localGtl.startDate}
              onChange={(e) => {
                updateValue('startDate', e);
              }}
            />
            <InputGrp
              {...fs.multiplier}
              {...getAccess(sectionAccess, 32, ControlIds.multiplier)}
              ref={multiplierInputRef}
              defaultValue={formatWithCommas(localGtl.multiplier ?? 0)}
              onChange={handleMultiplierChange}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                if (!multiplierInputRef?.current) return console.error('amountofIns ref is not set');
                multiplierInputRef.current.value = formatWithCommas(getAmount(e.target.value));
              }}
            />
            <InputGrp
              {...fs.amountOfIns}
              {...getAccess(sectionAccess, 32, ControlIds.amountOfIns)}
              ref={insuranceAmtInputRef}
              defaultValue={currencyFormatter(localGtl.amountOfIns ?? 0)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const newInsuranceAmt = getAmount(e.target.value);
                handleChangeAmountOfIns(newInsuranceAmt);
              }}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                if (!insuranceAmtInputRef?.current) return console.error('amountofIns ref is not set');
                const newInsuranceAmt = getAmount(e.target.value);
                insuranceAmtInputRef.current.value = currencyFormatter(Math.min(newInsuranceAmt, localGtl.wageCap));
              }}
              readOnly={localGtl.multiplier > 0}
            />
            <InputGrp
              {...fs.age}
              {...getAccess(sectionAccess, 32, ControlIds.age, { readOnlyDefault: true })}
              value={localGtl.age}
            />
            <InputGrp
              {...fs.monthlyCostPerThousand}
              {...getAccess(sectionAccess, 32, ControlIds.monthlyCostPerThousand, { readOnlyDefault: true })}
              value={formatWithCommas(localGtl.monthlyCostPerThousand)}
            />
            <InputGrp
              {...fs.excessIns}
              {...getAccess(sectionAccess, 32, ControlIds.excessIns, { readOnlyDefault: true })}
              value={currencyFormatter(localGtl.excessIns)}
            />
            <InputGrp
              {...fs.annualCost}
              {...getAccess(sectionAccess, 32, ControlIds.annualCost, { readOnlyDefault: true })}
              value={currencyFormatter(localGtl.annualCost)}
            />
            <InputGrp
              {...fs.perPayAmount}
              {...getAccess(sectionAccess, 32, ControlIds.perPayAmount, { readOnlyDefault: true })}
              ref={dedPerPayInputRef}
              defaultValue={currencyFormatter(localGtl.perPayAmount ?? 0)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateValue('perPayAmount', e.target.value);
              }}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                if (!dedPerPayInputRef?.current) return console.error('amountofIns ref is not set');
                const dedPerPayAmount = getAmount(e.target.value);
                dedPerPayInputRef.current.value = currencyFormatter(dedPerPayAmount);
              }}
            />
          </div>
          <div className="d-flex flex-wrap mt-3">
            <SelectGrp
              {...fs.earningsCode}
              {...getAccess(sectionAccess, 32, ControlIds.earningsCode)}
              value={localGtl.earningsCode}
              onChange={handleEarningChange}
              options={earningsCodeOpts}
              addEmptyValue={true}
            />
            <RadioGrp
              {...fs.autoAddToRecurringEarns}
              {...getAccess(sectionAccess, 32, ControlIds.autoAddToRecurringEarnings)}
              checked={localGtl.autoAddToRecurringEarns}
              onChange={(e) => {
                handleRadioButtonChange(e);
                updateValue('autoAddToRecurringEarns', e.target.value);
              }}
              disabled={addRecurringDisabled}
              controlled
            />
          </div>
          <div className="row">
            <div className="col-12 text-right">
              <SaveButton
                {...getAccess(sectionAccess, 32, undefined, { disabledDefault: !isDirty, disabledSameAsReadOnly: true })}
                type="button"
                classes="btn orange-button-sm mr-3"   
                disabled={!isDirty}
                saving={saving}
                onClick={() => {
                  onSave(localGtl);
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GroupTermLifePage;
