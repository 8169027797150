import Icon from 'core/components/shared/Icon';
import PanelHeader from 'core/components/shared/PanelHeader';
import { CustomEmail } from 'core/models';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { Row, Col, Button } from 'react-bootstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi, GridOptions, ICellRendererParams } from '@ag-grid-community/core';
import { useDispatch } from 'react-redux';
import { deleteCustomEmail, getCustomEmails, storeCustomEmail } from 'core/store/slices/applicantTracking.slice';
import CustomEmailModal from './CustomEmail.modal';

const CustomEmailsPage = () => {
  
  const dispatch = useDispatch();
  const [customEmails, customEmail] = useAppSelector(
    (state) => {
      return [
        state.applicantTracking.customEmails,
        state.applicantTracking.customEmail,
      ];
    },
  );

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [rowData, setRowData] = useState<CustomEmail[]>([]);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [selectedEmail, setSelectedEmail] = useState<CustomEmail>();

  useEffect(()=>{
    dispatch(getCustomEmails());
  }, []);
  
  useEffect(() => {
    if (!customEmail) return;
    setSelectedEmail(customEmail);
  }, [customEmail]);

  useEffect(()=>{
    customEmails && setRowData(customEmails);
  }, [customEmails]);
  
  const onAdd = () => {
    setSelectedEmail(undefined);
    setShowDetailModal(true);
  };

  const openDetailModal = (params: ICellRendererParams) =>{
    setSelectedEmail(params.data);
    setShowDetailModal(true);
  };
  
  const onDeleteEmail = (params: ICellRendererParams) =>{
    dispatch(deleteCustomEmail(params.data.customEmailId));
  };

  const columns = useMemo<ColDef[]>(
    () => {
      return [
        {
          field: 'description',
          headerName: 'Description',
          cellStyle: { textAlign: 'left' },
          cellRenderer: (params: ICellRendererParams) => {          
            return (
              <button
                type="button"
                className="btn btn-link"
                style={{ textAlign:'left' }}
                onClick={() => { return openDetailModal(params); }
                }
              >
                {params.data.description}
              </button>             
            );            
          },
          width: 1200,
        },        
        {
          field: 'delete',
          cellRenderer: (params: ICellRendererParams) => {                    
            return (
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={() => { return onDeleteEmail(params); }
                }
              >
                <Icon
                  name="minus-circle"
                  className="fa-minus-circle"
                />
              </button>             
            );            
          },
          width: 70,
        },     
      ];
    },
    [],
  );

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    stopEditingWhenCellsLoseFocus:true,
    undoRedoCellEditing:true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  return (
    <Row className="no-gutters p-0">
      <Col className="mt-1 shadow p-3 border">
        <PanelHeader
          title="Custom Emails"
          iconName="compass-drafting"
         
        > 
          <button
            type="button"
            className="btn btn-link dm-grid-action-title pb-0 mr-2"
            onClick={onAdd}
          >
            Add New Custom Email <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <div className="table-wrapper-wrapper ag-theme-balham mt-3">
          <AgGridReact
            gridOptions={gridOptions}
            rowData={rowData}
            columnDefs={columns}
            onGridReady={onGridReady}
          />
        </div>
        {showDetailModal && (          
          <CustomEmailModal
            item={selectedEmail}
            show={showDetailModal}
            onHide={() => {
              setShowDetailModal(false);
              dispatch(storeCustomEmail(null));
            }}            
          />
        )}
      </Col>
    </Row>
  );
};

export default CustomEmailsPage;
