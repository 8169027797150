import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class EEONoSetting {
  ruleID = 0;

  clientID = 0;

  clientNo = 0;

  loc = 0;

  dept = 0;

  sub = 0;

  sub2 = 0;

  eeoID = 0;
}

export class EEOUnitSetting {
  ruleID = 0;

  entityID = 0;

  clientEntityID = 0;

  clientNo = 0;

  loc = 0;

  dept = 0;

  sub = 0;

  sub2 = 0;

  eeoUnitID = 0;
}

export class LookupEEOUnit {
  eeoUnitID = 0;

  clientEntityID = 0;

  number = '';

  statusCode = '';

  name = '';

  address = '';

  address2 = '';

  city = '';

  state = '';

  zip = '';

  bradStreetNo = '';

  county = '';

  nasics = '';

  certifyOfficerID?: number = 0;

  sectionB2C?: boolean;

  sectionC1?: boolean;

  sectionC2?: boolean;

  sectionC3?: boolean;

  sectoinD2?: boolean;

  static readonly convProps: PropTypeLists = {
    numberProps: ['eeoUnitID', 'clientEntityID', 'certifyOfficerID'],
    boolProps: ['sectionB2C', 'sectionC1', 'sectionC2', 'sectionC3', 'sectoinD2'],
  };

  constructor(eeoUnitID: number | string, clientEntityID: number | string, props?: { [key: string]: any }) {
    this.eeoUnitID = (typeof eeoUnitID === 'string') ? parseInt(eeoUnitID) : eeoUnitID;
    this.clientEntityID = (typeof clientEntityID === 'string') ? parseInt(clientEntityID) : clientEntityID;
    props && convToClass(this, props, LookupEEOUnit.convProps);
  }
}

export class LookupEEOCertifyOfficial {
  certifyOfficalID = 0;

  clientEntityID = 0;

  name = '';

  title = '';

  telphone = '';

  emailAddress = '';

  constructor(certifyOfficalID: number | string, clientEntityID: number | string, props?: { [key: string]: any }) {
    this.certifyOfficalID = (typeof certifyOfficalID === 'string') ? parseInt(certifyOfficalID) : certifyOfficalID;
    this.clientEntityID = (typeof clientEntityID === 'string') ? parseInt(clientEntityID) : clientEntityID;
  }
}