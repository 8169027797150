import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EmployeeToTransfer, HttpResponse, Transfers } from '../../models';
import { EmployeeService } from '../../services';
import {
  employeeTransferSetErrorStatus,
  executeEmployeeTransfer,
  executeEmployeeTransferComplete,
  handleError,
  loadEmployeeTransfer,
  loadEmployeeTransferValidate,
  storeEmployeeTransfer,
  storeEmployeeTransferValidate,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadEmployeeTransfer$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmployeeTransfer.type),
    switchMap(() => {
      return from(EmployeeService.getEmployeeTransfer()).pipe(
        map((res: any) => { return res.data; }),
        map((res: Transfers) => { return storeEmployeeTransfer(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadEmployeeTransferValidate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmployeeTransferValidate.type),
    switchMap((action: { payload: EmployeeToTransfer; }) => {
      return from(
        EmployeeService.putEmployeeTransferVerify(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeEmployeeTransferValidate(res); }),
        catchError((err: any) => { return [storeEmployeeTransferValidate(err)]; }),
      );
    },
    ),
  );
};

const executeEmployeeTransfer$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(executeEmployeeTransfer.type),
    switchMap((action: { payload: EmployeeToTransfer; }) => {
      return from(EmployeeService.putEmployeeTransfer(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return executeEmployeeTransferComplete(); }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            employeeTransferSetErrorStatus(),
          ];
        }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadEmployeeTransfer$,
  loadEmployeeTransferValidate$,
  executeEmployeeTransfer$,
];
