import React, { CSSProperties, useState } from 'react';
import HrFieldHistoryModal from '../modals/HrFieldHistory.modal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import Icon from 'core/components/shared/Icon';
import styles from './form-controls.module.scss';

const toolTipStyles: CSSProperties = { fontSize: '0.75rem' };

type PropTypes = {
  labelTitle: string;
  section: string;
  field: string;
  protectedEmpNo: string;
  showHistory?: boolean;
  detailMessage?: string;
  labelClasses?: string;
  required?: boolean;
  visible?: boolean;
  hasError?: boolean;
};

const HistoryLabel: React.FC<PropTypes> = ({
  labelTitle,
  section,
  field,
  protectedEmpNo,
  showHistory = true,
  detailMessage,
  labelClasses = '',
  required,
  visible = true,
  hasError,
}: PropTypes) => {
  required && (labelClasses += ' ' + styles['dm-required']);
  hasError && (labelClasses += ' text-danger');

  const [showModal, setShowModal] = useState(false);

  const onOpenHistory = () => {
    if (!showHistory) return;
    setShowModal(true);
  };
  
  const renderToolTip = (props: OverlayInjectedProps) => {
    return (
      <Tooltip
        id="info-tooltip"
        {...props}
      >
        <div style={toolTipStyles}>
          {detailMessage}
        </div>
      </Tooltip>
    );
  };
  
  return (
    <>
      {visible ? (
        <div className="d-flex">
          <label
            className={`${styles['dm-form-label']} ${labelClasses}`}
            onClick={onOpenHistory}
            role={showHistory ? 'button' : ''}
            data-section={section}
            data-field={field}
            title={showHistory ? 'View History' : ''}
          >
            {showHistory ?
              <>
                <span><u>{labelTitle}</u></span>
              </>
              :
              <span>{labelTitle}</span>
            }
          </label>
          {detailMessage ? (
            <OverlayTrigger
              placement="top"
              overlay={renderToolTip}
            >
              <div className="d-flex align-items-start m-0 ml-1">
                <Icon
                  name="circle-info"
                  fontSize={'0.75rem'}
                />
              </div>
            </OverlayTrigger>
          ) : null}
          {showModal ? (
            <div>
              <HrFieldHistoryModal
                show={showModal}
                onHide={() => {return setShowModal(false);}}
                protectedEmpNo={protectedEmpNo}
                section={section}
                field={field}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default HistoryLabel;