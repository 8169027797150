import { Axios, AxiosResponse } from 'axios';
import { ModuleAccess } from 'core/models/ModuleAccess.model';
import {
  Client,
  ClientMasterDtoTaxEntitiesFIPS,
  ClientOption,
  ClientOptions,
  HttpResponse,
  K401Options,
  NOVATime,
  NOVATimeRule,
  NOVATimeSettings,
  NOVATimeShiftNumber,
  Option,
} from '../models';
import { API_CLIENT, API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { ClientMasterEndPoints } from './URL';
import { ClientDeduction } from 'core/models/ClientDeduction.model';

const getClient = (): Promise<AxiosResponse<Client>> => {
  return API_CLIENT.get<Client>(
    ClientMasterEndPoints.GET_CLIENT(CommonService.getProtectedClientNo()),
  );
};

const getModuleAccess = (): Promise<AxiosResponse<ModuleAccess>> => {
  return API_EMPLOYEE_MASTER.get<ModuleAccess>(
    ClientMasterEndPoints.GET_MODULE_ACCESS(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getClientOptions = (): Promise<AxiosResponse<ClientOptions>> => {
  return API_CLIENT.get<ClientOptions>(
    ClientMasterEndPoints.GET_CLIENT_OPTIONS(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getClientOption = (
  optionId: number,
): Promise<AxiosResponse<ClientOption>> => {
  return API_CLIENT.get<ClientOption>(
    ClientMasterEndPoints.GET_CLIENT_OPTION(
      CommonService.getProtectedClientNo(),
      optionId,
    ),
  );
};

const putClientOption = (
  clientOption: ClientOption,
): Promise<AxiosResponse<HttpResponse<ClientOption>>> => {
  return API_CLIENT.put<HttpResponse<ClientOption>>(
    ClientMasterEndPoints.GET_CLIENT_OPTION(
      CommonService.getProtectedClientNo(),
      clientOption.optionId,
    ),
    clientOption,
  );
};

const get401KOptions = (): Promise<AxiosResponse<K401Options>> => {
  return API_CLIENT.get<K401Options>(
    ClientMasterEndPoints.GET_401K_OPTIONS(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getClientEnumsOptions = (): Promise<AxiosResponse<Option[]>> => { return API_CLIENT.get<Option[]>(ClientMasterEndPoints.GET_CLIENT_ENUM_OPTIONS); };

const getIsNOVAClient = (): Promise<AxiosResponse<{ clientNo: number; isNovaClient: boolean }>> => {
  return API_CLIENT.get<any>(ClientMasterEndPoints.GET_IS_NOVA_CLIENT(CommonService.getProtectedClientNo()));
};

const getNOVATimeSettings = (): Promise<AxiosResponse<NOVATimeSettings>> => {
  return API_CLIENT.get<NOVATimeSettings>(
    ClientMasterEndPoints.GET_NOVA_TIME_SETTINGS(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getNOVATime = (): Promise<AxiosResponse<NOVATime>> => {
  return API_CLIENT.get<NOVATime>(
    ClientMasterEndPoints.GET_NOVA_TIME_SETTINGS(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getNOVATimeShiftNumbers = (): Promise<
AxiosResponse<NOVATimeShiftNumber[]>
> => {
  return API_CLIENT.get<NOVATimeShiftNumber[]>(
    ClientMasterEndPoints.GET_NOVA_TIME_SHIFT_NUMBERS(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getNOVATimeRules = (): Promise<AxiosResponse<NOVATimeRule[]>> => {
  return API_CLIENT.get<NOVATimeRule[]>(
    ClientMasterEndPoints.GET_NOVA_TIME_RULES(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getClientDeductions = (): Promise<AxiosResponse<ClientDeduction>> => {
  return API_CLIENT.get<ClientDeduction>(
    ClientMasterEndPoints.GET_CLIENT_DEDUCTION(
      CommonService.getProtectedClientNo(),
    ),
  );
};

const getClientTaxFips = (): Promise<AxiosResponse<ClientMasterDtoTaxEntitiesFIPS[]>> => {
  return API_CLIENT.get<ClientMasterDtoTaxEntitiesFIPS[]>(
    ClientMasterEndPoints.TAX_ENTITIES_FIPS(
      CommonService.getProtectedClientNo(),
    ),
  );
};

export const ClientService = {
  getClient,
  getModuleAccess,
  getClientOptions,
  getClientOption,
  putClientOption,
  getClientEnumsOptions,
  get401KOptions,
  getIsNOVAClient,
  getNOVATimeSettings,
  getNOVATime,
  getNOVATimeShiftNumbers,
  getNOVATimeRules,
  getClientDeductions,
  getClientTaxFips,
};
