import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { PayRate } from 'core/models';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadPayRates } from 'core/store/actions';
import { getPayRates } from 'core/store/selectors';
import SnapshotPayRateItem from './SnapshotPayRateItem';
import Icon from 'core/components/shared/Icon';
import { useAppSelector } from 'utilities/hooks';

type Params = {
  protectedEmpNo: string
};

const SnapshotPayRate: React.FC = () => {
  const payRates: PayRate[] = useSelector(getPayRates);
  const payRateAccess = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections?.find((x) => x.workItemId === 2));
    
  const [chevronCollapsed, setChevronCollapsed] = useState(true);
  
  const dispatch = useDispatch();
  
  const { protectedEmpNo } = useParams<Params>();

  useEffect(() => {
    dispatch(loadPayRates(protectedEmpNo));
    
    return () => { setChevronCollapsed(true); };
  }, [dispatch, protectedEmpNo]);

  return (
    <>
      {(payRateAccess?.visible ?? true) ? (
        <div
          style={{ minWidth: '400px' }}
          className="dm-panel dm-panel-border"
        >
          <div className="row">
            <div className="col-10">
              <div className="dm-grid-title">Current Rate</div>
            </div>
            <div className="col-2 d-flex justify-content-end my-auto" >
              <NavLink to="payrate" >
                <Icon
                  name="pen"
                  className="fa-pen"
                />
              </NavLink>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr className="dm-panel-hr" />
            </div>
          </div>
          {payRates && <SnapshotPayRateItem payRate={payRates[0]} />}
          {payRates?.length > 1 ? (
            <Accordion>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="testclass">
                  {payRates.map(
                    (item: PayRate, index: number) => {
                      return index > 0 && (
                        <div key={item.rateId}>
                          <hr className="dm-panel-hr" />
                          <SnapshotPayRateItem payRate={item} />
                        </div>
                      );
                    },
                  )}
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                className="testclass"
                eventKey="0"
                onClick={() => {return setChevronCollapsed(!chevronCollapsed);}}
              >
                SHOW ADDITIONAL RATES <Icon name={`chevron-${chevronCollapsed ? 'right' : 'up'}`}/>
              </Accordion.Toggle>
            </Accordion>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default SnapshotPayRate;
