import { InputGrp } from 'core/components/form-controls';
import Icon from 'core/components/shared/Icon';
import { EMAIL_VALIDATION } from 'core/constants';
import { EmailRequest, UserMapEmail, UserMapItem } from 'core/models/Downloader.model';
import { putEmails } from 'core/store/slices/downloader.slice';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';


type FormType = {
  items: UserMapEmail[];
};

type PropTypes = {
  userMapItem: UserMapItem;
  show: boolean;
  onHide: () => void;
};

const AddEmailModal: React.FC<PropTypes> = ({ userMapItem, show, onHide }) => {

  const dispatch = useDispatch();

  const {
    reset,
    register,
    control,
    handleSubmit,
    errors,
  } = useForm<FormType>({
    defaultValues:
    {
      items: userMapItem.emails,
    },
  });
  const { fields, remove, prepend } =
    useFieldArray({
      control,
      name: 'items',
      keyName: 'id',
    });

  const onSubmit = (data: any) => {
    const request: EmailRequest = {
      userMapId: userMapItem.userMapId,
      emails: data.items ?? [],
    };
    request && dispatch(putEmails(request));
  };

  const onAdd = () => {
    prepend({
      emailId: userMapItem.emails?.[0]?.emailId ?? 0,
      emailAddressId: 0,
      email: '',
    });
  };

  const onDelete = (index: any) => {
    remove(index);
  };

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal show={show}
        onHide={onHide}
        size="sm"
        animation={false}>
        <div className="modal-header" >
          <div className="dm-card-title">Email Report to the following Email Address(es)</div>
          <button
            type="button"
            onClick={() => {
              closeModal();
            }}>
            <Icon name="times" />
          </button>
        </div>
        <Modal.Body>
          <div className="d-flex flex-row">
            <div className="dm-card-subtitle2 mb-2">Email Address(es)</div>
            <div className="flex-grow-1 text-right">
              <button
                type="button"
                className="btn btn-link dm-grid-action-title pb-0 content-justify-end"
                onClick={onAdd}
              >
                Add Email{' '}
                <Icon name="plus-circle"
                  className="fa-plus-circle" />
              </button>
            </div>
          </div>
          <hr className="dm-panel-hr" />
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields?.map((item: any, index: number) => {
              return (
                <div key={item.id}
                  className="d-flex flex-row mt-3">
                  <div className="flex-grow-1 mt-2">
                    <input
                      type="hidden"
                      name={`items[${index}].emailId`}
                      defaultValue={item.emailId}
                      ref={register({ valueAsNumber: true })}
                    />
                    <InputGrp
                      name={`items[${index}].email`}
                      defaultValue={item.email}
                      ref={register({
                        pattern: {
                          value: EMAIL_VALIDATION,
                          message: 'Invalid Email',
                        },
                      })}
                      errors={errors.items?.[index]?.email}
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-link dm-grid-action-title pb-0"
                      onClick={() => { return onDelete(index); }}
                    >
                      Delete
                      <Icon name="minus-circle"
                        className="fa-minus-circle" />
                    </button>
                  </div>
                </div>
              );
            })}
            <div className="d-flex justify-content-end mt-2">
              <div className="d-flex flex-row">
                <button
                  type="button"
                  className="btn btn-primary orange-outline-button mr-2"
                  onClick={() => { reset(); closeModal(); }}>Cancel</button>
                <button                  
                  type="submit"
                  className="btn orange-button-sm mr-3"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>);
};

export default AddEmailModal;