import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import Icon from 'core/components/shared/Icon';
import { IconTypes } from 'enums/IconTypes';
import SearchTimeCardsModal from './modals/SearchTimeCards.modal';
import { TimeCardContext } from './TimeCardContext';

type Props = {
  type: string;
  changeTimeCard: (direction: 'previous' | 'next') => void;
  setShowAddModal: (newVal: boolean) => void;
};

const TimeCardPageTools = ({ type, changeTimeCard, setShowAddModal }: Props) => {
  const { isDirty } = useContext(TimeCardContext);
  
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
  
  return (
    <>
      {showSearchModal && (
        <SearchTimeCardsModal
          type={type}
          show={showSearchModal}
          onHide={() => { setShowSearchModal(false); }}
        />
      )}
      <div className="d-flex">
        <button
          className="btn orange-button mr-3"
          onClick={(e) => {
            e.preventDefault();
            setShowSearchModal(true);
          }}
        >
          Search
        </button>
        <button
          className="btn orange-button mr-4"
          onClick={(e) => {
            e.preventDefault();
            if (isDirty && !confirm('You will lose unsaved changes. Continue?')) return;
            setShowAddModal(true);
          }}
        >
          Add {type}
        </button>
        <div className="d-flex align-items-center">
          <Icon
            name="circle-arrow-left"
            fontSize={'1.5rem'}
            type={IconTypes.THIN}
            onClick={() => changeTimeCard('previous')}
          />
          <span className="dm-grid-action-title2 mx-1">{type}</span>
          <Icon
            name="circle-arrow-right"
            fontSize={'1.5rem'}
            type={IconTypes.THIN}
            onClick={() => changeTimeCard('next')}
          />
        </div>
      </div>
    </>
  );
};

export default TimeCardPageTools;