import { createSelector } from 'reselect';
import { convToFormObject } from '../../../utilities/classUtils';
import { HireHistory } from '../../models';
import { hireHistoryState } from '../reducers';
import { State as HireHistoryState } from '../reducers/hire-history.reducer';

export const getHireHistories = createSelector(
  hireHistoryState,
  (state: HireHistoryState) => {
    if (!state?.hireHistory) return [];

    return state.hireHistory
      .map((h: HireHistory) => {return convToFormObject(h, HireHistory.convProps);})
      .sort((h1, h2) => {return h2.hireDate.getTime() - h1.hireDate.getTime();}) as HireHistory[];
  },
);
