import React from 'react';
import { Labels } from '.';
import { currencyFormatter } from 'utilities/utilities';
import styles from './form-controls.module.scss';
import { FormMethods } from './types';

interface PropTypes {
  id?: string;
  name: string;
  label?: string;
  tallLabel?: boolean;
  labelClass?: string;
  groupClass?: string;
  groupStyle?: object;
  style?: object;
  errors?: any;
  errorMsg?: string;
  required?: boolean;
  visible?: boolean;
  decimals?: number;
  setValue?: FormMethods['setValue'];
  disabled?: boolean;
  onBlur?: any;
  defaultValue?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * @property {string} id?
 * @property {string} name
 * @property {string | string[]} label?
 * @property {boolean} tallLabel?
 * @property {string} groupClass?
 * @property {object} groupStyle?
 * @property {any} errors?
 * @property {boolean} required?
 */
export const InputGrpCurrency: React.FC<any> = React.forwardRef(({
  id,
  name,
  label,
  tallLabel = false,
  labelClass = '',
  groupClass,
  groupStyle,
  style = {},
  required = false,
  visible = true,
  errors,
  errorMsg,
  decimals = 2,
  setValue,
  disabled = false,
  onBlur = (e: any) => {
    if (!setValue) return;
    setValue(name, currencyFormatter(e?.target?.value, decimals));
  },
  defaultValue,
  ...extraProps
}: PropTypes, ref: any) => {

  const groupClass2 = styles['dm-form-group'] + ' ' + (groupClass ?? '');
  id = id ?? name;
  const errMsg = errors ? (errors.type === 'validate' ? errorMsg : errors.message) : '';

  return (
    <>
      {visible ? (
        <div
          className={groupClass2}
          style={groupStyle}
        >
          <Labels
            label={label}
            tallLabel={tallLabel}
            labelClass={labelClass}
            id={id}
            hasError={!!errors}
            required={required}
          />
          <input
            ref={ref}
            style={{ ...style,
              textAlign: 'right' }}
            className={styles['dm-form-control'] + (errors ? ' ' + styles['dm-is-invalid'] : '')}
            id={id}
            name={name}
            disabled={disabled}
            onBlur={onBlur}
            defaultValue={currencyFormatter(defaultValue, decimals)}
            {...extraProps}
          />
          <small className="text-danger">{errMsg} </small>
        </div>
      ) : null}
    </>
  );
});

InputGrpCurrency.displayName = 'InputGrpCurrency';