import React from 'react';
import Modal from 'core/components/modals/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { InputGrpInLine } from '../../../core/components/form-controls';
import { AccrualEdit } from '../../../core/models';
import { updateAccrualBeginBalance } from '../../../core/store/actions';
import { toFixed } from '../../../utilities/utilities';

type Form = {
  amount: string;
};

type PropTypes = {
  item: AccrualEdit;
  show: boolean;
  onHide: () => void;
};

const AccrualBeginBalanceModal: React.FC<PropTypes> = ({
  item,
  show,
  onHide,
}) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue } = useForm<Form>({
    defaultValues: { amount: item.amount.toFixed(4) },
  });

  const onSave = (data: Form) => {
    dispatch(
      updateAccrualBeginBalance({
        ...item,
        amount: parseFloat(data.amount),
      }),
    );
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title="ACCRUAL BEGIN BALANCE"
    >
      <form onSubmit={handleSubmit(onSave)}>
        <div className="d-flex flex-column">
          <InputGrpInLine
            name="amount"
            label="BEGIN BALANCE"
            labelWidth={35}
            groupClass="groupClass50"
            onBlur={(
              e: React.ChangeEvent<HTMLInputElement>,
            ) => {
              setValue(
                'amount',
                toFixed(e.target.value, 4),
              );
            }}
            inputClass="text-right"
            errors={errors.amount}
            ref={register({
              valueAsNumber: true,
              required: {
                value: true,
                message: 'Amount is required',
              },
            })}
          />
        </div>
        <div className="row mt-4">
          <div className="col-12 text-right">
            <button
              type="button"
              className="btn btn-primary orange-outline-button mr-3"
              onClick={() => { return onHide(); }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary orange-button"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AccrualBeginBalanceModal;
