import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Client, PayrollDateline } from 'core/models';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { ControlDatePickerGrp, RadioGrp, SelectGrp } from 'core/components/form-controls';
import { createPayroll } from 'core/store/actions';
import { useRadioButtonChange } from 'utilities/hooks';
import { DateTime } from 'luxon';
import { CommonService, PayrollService } from 'core/services';
import Icon from 'core/components/shared/Icon';
import PayrollValidationErrorModal from './PayrollValidationErrorModal';

const currentYear = new Date().getFullYear();

const radioOptions = [
  {
    value: 'true',
    label: 'Create New Payroll from Employee Master',
  },
  {
    value: 'false',
    label: 'Create Blank Payroll',
  },
];

const fs: FieldInputSettings = {
  weekEnd: {
    name: 'weekEnd',
    label: 'Current Week End:',
    labelWidth: 25,
    required: true,
    groupClass: 'w-100',
  },
  checkDate: {
    name: 'checkDate',
    label: 'Current Check Date:',
    labelWidth: 25,
    required: true,
  },
  nextWeekEnd: {
    name: 'nextWeekEnd',
    label: 'Next Week End:',
    labelWidth: 25,
    required: true,
  },
  nextCheckDate: {
    name: 'nextCheckDate',
    label: 'Next Check Date:',
    labelWidth: 25,
    required: true,
  },
  prFrequency: {
    name: 'prFrequency',
    label: 'Pay Frequency:',
    labelWidth: 25,
    required: true,
  },
  populateTransmittal: {
    name: 'populateTransmittal',
    radioOptions,
    required: true,
  },
};

export type PayrollValidationError = {
  statusCode: string;
  messages: string[];
  value: null;
};

type PropTypes = {
  show: boolean;
  client: Client;
  prFrequencyOpts: { id: string, description: string }[];
  onHide: () => void;
};

const InsertPayrollModal: React.FC<PropTypes> = ({ show, client, prFrequencyOpts, onHide }) => {
  const dispatch = useDispatch();
  
  const [handleRadioButtonChange] = useRadioButtonChange(fs);
  
  const [validationError, setValidationError] = useState<PayrollValidationError | null>(null);
  const [validatedDateline, setValidatedDateline] = useState<PayrollDateline | null>(null);
  
  const {
    control,
    setValue,
    watch,
    getValues,
    register,
    handleSubmit,
    errors,
  } = useForm<PayrollDateline>({
    defaultValues: {
      populateTransmittal: radioOptions[0].value,
    },
  });
  const { weekEnd, checkDate, nextWeekEnd, nextCheckDate } = watch([
    'weekEnd',
    'checkDate',
    'nextWeekEnd',
    'nextCheckDate',
  ]);

  const closeModal = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.stopPropagation();
    onHide();
  };
  
  const onSave = async () => {
    const values = getValues();
    const payrollDateline: PayrollDateline = {
      clientNo: client.clientNo,
      protectedClientNo: CommonService.getProtectedClientNo(),
      weekEnd: values.weekEnd,
      checkDate: values.checkDate,
      nextWeekEnd: values.nextWeekEnd,
      nextCheckDate: values.nextCheckDate,
      prFrequency: values.prFrequency,
      populateTransmittal: values.populateTransmittal === 'true',
      useNewCheckRegister: true,
    };
    
    setValidatedDateline(payrollDateline);
    
    //This validation will happen before anything is created. If we need more validation can just add to this endpoint.
    const validationRes = await PayrollService.postValidatePayroll(payrollDateline).catch((err: any) => { return err; });
    if (validationRes?.status === 200) {
      dispatch(createPayroll(payrollDateline));
      closeModal();
      return;
    }
    
    setValidationError(validationRes);
  };

  const payrollFreq = {
    Weekly: 1,
    'Bi-Weekly': 2,
  };

  const handleDateChange = (field: keyof PayrollDateline, date: string | Date | null) => {
    if (!date || ['Monthly', 'Semi-Monthly'].includes(client.clientPayrollFreq)) return; // or... ?
    
    const frequency: number = payrollFreq[client.clientPayrollFreq as keyof typeof payrollFreq];
    const nextDate: DateTime = DateTime.fromJSDate(new Date(date)).plus({ week: frequency });
    setValue(field, nextDate);
  };
  
  const handleConfirmCheckDate = (confirm: boolean) => {
    if (!validatedDateline) return console.error('Dateline state not properly set');
    if (!confirm) {
      setValidationError(null);
      setValidatedDateline(null);
      return;
    }
    
    dispatch(createPayroll(validatedDateline));
    closeModal();
  };
  
  const validateNextWeekEnd = (value: string | Date | null) => {
    if (!value) return 'Please enter a next week end';
    if (!(new Date(value) > new Date(weekEnd))) return 'Must be after current week end';
    return true;
  };
  
  const validateNextCheckDate = (value: string | Date | null) => {
    if (!value) return 'Please enter a next check date';
    if (!(new Date(value) > new Date(checkDate))) return 'Must be after current check date';
    return true;
  };

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        animation={false}
      >
        <div className="modal-header">
          <div className="dm-card-title">Insert Payroll</div>
          <button
            type="button"
            className="modal-close-btn"
            onClick={closeModal}
          >
            <Icon name="times" />
          </button>
        </div>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(onSave)}
            className="mt-3"
          >
            <div className="row no-gutters">
              <div className="col">
                <ControlDatePickerGrp
                  {...fs.weekEnd}
                  errors={errors.weekEnd}
                  value={weekEnd}
                  setValue={setValue}
                  control={control}
                  rules={{ required: true }}
                  onChange={(_weekEnd: Date | null) => { handleDateChange('nextWeekEnd', _weekEnd); }}
                  minDate={new Date(`01/01/${currentYear - 1}`)}
                  maxDate={new Date(`12/31/${currentYear + 1}`)}
                />
              </div>
              <div className="col">
                <ControlDatePickerGrp
                  {...fs.checkDate}
                  errors={errors.checkDate}
                  value={checkDate}
                  setValue={setValue}
                  control={control}
                  rules={{ required: true }}
                  onChange={(_checkDate: Date | null) => { handleDateChange('nextCheckDate', _checkDate); }}
                  minDate={new Date(`01/01/${currentYear - 1}`)}
                  maxDate={new Date(`12/31/${currentYear + 1}`)}
                />
              </div>
              <div className="col">
                <SelectGrp
                  {...fs.prFrequency}
                  errors={errors.prFrequency}
                  ref={register}
                  options={prFrequencyOpts}
                  addEmptyValue={true}
                />
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col">
                <ControlDatePickerGrp
                  {...fs.nextWeekEnd}
                  errors={errors.nextWeekEnd}
                  value={nextWeekEnd}
                  setValue={setValue}
                  control={control}
                  rules={{
                    required: true,
                    validate: validateNextWeekEnd,
                  }}
                  minDate={new Date(`01/01/${currentYear - 1}`)}
                  maxDate={new Date(`12/31/${currentYear + 1}`)}
                />
              </div>
              <div className="col">
                <ControlDatePickerGrp
                  {...fs.nextCheckDate}
                  errors={errors.nextCheckDate}
                  value={nextCheckDate}
                  setValue={setValue}
                  control={control}
                  rules={{
                    required: true,
                    validate: validateNextCheckDate,
                  }}
                  minDate={new Date(`01/01/${currentYear - 1}`)}
                  maxDate={new Date(`12/31/${currentYear + 1}`)}
                />
              </div>
              <div className="col">
                <RadioGrp
                  {...fs.populateTransmittal}
                  errors={errors.populateTransmittal}
                  ref={register}
                  onChange={handleRadioButtonChange}
                />
              </div>
            </div>
            <div className="d-flex mt-3">
              <button
                className="orange-outline-button ml-auto mr-2"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="orange-button mr-2"
              >
                Okay
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {!!validationError && (
        <PayrollValidationErrorModal
          show={!!validationError}
          onHide={handleConfirmCheckDate}
          validationError={validationError}
        />
      )}
    </div>
  );
};

export default InsertPayrollModal;
