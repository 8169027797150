import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import GrossPayAnalysisChart from './GrossPayAnalsysChart';
import NewFeaturesPage from './new-features/NewFeaturesPage';
import ImportantReminder from './ImportantReminder';
import MyInfoTask from './MyInfoTask';
import NewHire from './NewHire';
import CurrentPayrollChart from './CurrentPayrollChart';
import PayrollBreakDownChart from './PayrollBreakDownChart';
import EmployeePayAnalysisChart from './EmployeePayAnalysisChart';
import { storeEmpFromPreviousClient, setShowTermClientModal, loadUserAccess } from '../../core/store/actions';
import { useAppDispatch, useAppSelector } from '../../utilities/hooks';
import PageSpinner from 'core/components/shared/PageSpinner';
import Modal from 'core/components/modals/Modal';
import { convToDateString } from 'utilities/utilities';
import { useHistory } from 'react-router-dom';
import { ModuleAccess } from 'core/models/ModuleAccess.model';

function DetermineIfUserCanSeeDashboardData(moduleAccess: ModuleAccess | null) : boolean {
  //PI-8725: if user doesn't have EM access they cannot see charts/graphs (See: PI-5241)
  if (moduleAccess == null) return false;

  const groupIdTwo = moduleAccess.groups.filter(x => x.groupId == 2);
  if (!groupIdTwo || groupIdTwo.length < 1) return false;

  const itemIdOne = groupIdTwo.flatMap(f => f.items).find(x => x.itemId == 1);
  if (itemIdOne == null) return false;

  return itemIdOne.visible;
}

const Home: React.FC = () => {
  const dispatch = useAppDispatch();
  
  const userAccessLoading = useAppSelector((state) => { return state.auth.loadingUserAccess; });
  const { moduleAccessLoading } = useAppSelector((state) => { return state.app; });
  const { showTermClientModal, client } = useAppSelector(({ client }) => client);
  const { employeeFromPreviousClient } = useAppSelector(({ selEmployee }) => selEmployee);
  const moduleAccess = useAppSelector((state) => state.app.moduleAccess);  
  const history = useHistory();
  const [showDashboardData, setShowDashboardData] = useState(true);  

  useEffect(() => {
    // have to wait for module access to be set in the store for the emp detail sidebar data to be set... 
    // also, the sidebar depends on the client being defined.
    if (employeeFromPreviousClient?.[0] && moduleAccess && client) {
      // perform some slight-of-hand and remove this employee from this slot since it's now just in the client's employees state
      dispatch(storeEmpFromPreviousClient(null)); 
      history.push(`/employee/detail/${employeeFromPreviousClient[0].protectedEmpNo}/snapshot`);
    }
  }, [employeeFromPreviousClient, moduleAccess, client]);

  useEffect(() => setShowDashboardData(DetermineIfUserCanSeeDashboardData(moduleAccess)), [moduleAccess]);
  
  const reloadAccess = () => {
    dispatch(loadUserAccess());
  };

  return (
    <div className="home-page-container">
      {(userAccessLoading || moduleAccessLoading || employeeFromPreviousClient?.[0]) ? (
        <PageSpinner
          showActionButton
          loadingMessage="Loading client..."
          errorMessage="Error loading client"
          actionMessage="Retry"
          onActionClick={reloadAccess}
        />
      ) : (
        <Row className="pt-3 mx-0">
          <Col
            xs={12}
            sm={4}
            md={4}
            className="pr-sm-0 pr-3"
          >
            <Row>
              <Col xs={12}>
                <MyInfoTask visible={showDashboardData} />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <NewHire visible={showDashboardData} />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <NewFeaturesPage visible={true}/>
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            sm={8}
            md={8}
          >
            <Row>
              <Col
                xs={12}
                md={6}
                className="pr-md-0"
              >
                <PayrollBreakDownChart visible={showDashboardData} />
              </Col>
              <Col
                xs={12}
                md={6}
              >
                <CurrentPayrollChart visible={showDashboardData} />
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={6}
                className="pr-md-0"
              >
                <GrossPayAnalysisChart visible={showDashboardData} />
              </Col>
              <Col
                xs={12}
                md={6}
              >
                <EmployeePayAnalysisChart visible={showDashboardData} />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <ImportantReminder visible={true} /> 
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {showTermClientModal && client && client?.termDate ? (
        <Modal
          title="Notice: Terminated Client"
          show={showTermClientModal}
          onHide={() => { dispatch(setShowTermClientModal(false)); }}
        >
          <p>Client {client.clientNo} terminated on {convToDateString(new Date(client.termDate))}.</p>
          <div className="d-flex justify-content-end">
            <button
              className="btn orange-button-sm"
              onClick={() => { dispatch(setShowTermClientModal(false)); }}
            >
              Close
            </button>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default Home;
