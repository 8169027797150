import React, { useEffect, useRef, useState } from 'react';
import { CheckboxGrpInLine } from 'core/components/form-controls';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from 'core/components/shared/Icon';
import { IconTypes } from 'enums/IconTypes';
import { InterviewTemplate, ScoreCard, ScorecardRate } from 'core/models';
import { useAppSelector } from 'utilities/hooks';

type Props = {
  item: ScoreCard;
  index: number;
  isEditing: boolean;
  defaultView: boolean;
  currentTemplate: InterviewTemplate | undefined;
  updateScoreCard: (newValue: ScoreCard) => void;
};

const generateRates = (rates: ScorecardRate[]) => {
  return rates?.map(({ rating, description }) => ({
    description,
    rating,
  })) ?? [];
};

const InterviewerScoreCard = ({
  item,
  index,
  isEditing,
  defaultView,
  currentTemplate,
  updateScoreCard,
}: Props) => {
  const [mouseDown, setMouseDown] = useState(false);
  
  const [scorecardCategories, scorecardTraits] = useAppSelector(
    (state) => {
      return [state.applicantTracking.scorecardCategories, state.applicantTracking.scorecardTraits,
      ];
    },
  );
  
  const prevRatingVal = useRef<number | null>(item.rating); // should never be null, though
  
  const rates = currentTemplate?.scorecardCategories
    ?.find((category) => category.category === item.category)?.traits
    ?.find((trait) => trait.trait === item.trait)?.rates; // :)
  
  const mappedRates = generateRates(rates ?? []);
  
  useEffect(() => {
    // pizazz
    const onMouseDown = (_event: MouseEvent) => { setMouseDown(true); };
    const onMouseUp = (_event: MouseEvent) => { setMouseDown(false); };
    
    window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('mouseup', onMouseUp);
    
    return () => {
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, []);
  
  const handleRatingChange = (newValue: number | null) => {
    if (newValue) prevRatingVal.current = newValue;
    const newScoreCard: ScoreCard = { ...item, rating: newValue };
    updateScoreCard(newScoreCard);
  };
  
  return (
    <div className="interviewer-scorecard-container border">
      <div className="d-flex mt-2 justify-content-between">
      </div>
      <div>
        <div className="d-flex mt-2 mb-1 align-items-center">
          <div className="mr-4 scorecard-category-subTitle">{item.trait}</div>
          <CheckboxGrpInLine
            label="N/A"
            labelFirst={false}
            groupStyle={{ margin: '0' }}
            value={item.rating === null}
            disabled={!isEditing}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked) {
                handleRatingChange(null);
              } else {
                handleRatingChange(prevRatingVal?.current);        
              }
            }}
          />
        </div>
        <div className={`d-flex ${defaultView ? 'flex-column' : ''} align-items-center`}>
          {defaultView ? mappedRates?.map((star) => {
            return (
              <div
                className="d-flex align-items-center w-100 justify-content-between mb-1"
                key={star.rating}
              >
                <p className="m-0 user-select-none">{`${star.rating}. ${star.description || ''}`}</p>
                <div className={`star-wrapper ${(item?.rating ?? 0) >= star.rating ? 'star-fill' : ''}`}>
                  <button
                    disabled={!isEditing}
                    aria-disabled={!isEditing}
                    onMouseEnter={() => {
                      if (!mouseDown) return;
                      handleRatingChange(star.rating);
                    }}
                    onMouseDown={() => { handleRatingChange(star.rating); }}
                  >
                    <Icon
                      name="star"
                      color="#e6ac29"
                      type={(item?.rating ?? 0) >= star.rating ? IconTypes.SOLID : IconTypes.REGULAR}
                    />
                  </button>
                </div>
              </div>
            );
          }) : mappedRates?.map((star) => {
            return (
              <OverlayTrigger
                key={star.rating}
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-${star}`}>
                    {star.description || ''}
                  </Tooltip>
                }
              >
                <div className={`star-wrapper ${(item?.rating ?? 0) >= star.rating ? 'star-fill' : ''}`}>
                  <button
                    disabled={!isEditing}
                    aria-disabled={!isEditing}
                    onMouseEnter={() => {
                      if (!mouseDown) return;
                      handleRatingChange(star.rating);
                    }}
                    onMouseDown={() => { handleRatingChange(star.rating); }}
                  >
                    <Icon
                      name="star"
                      color="#e6ac29"
                      type={(item?.rating ?? 0) >= star.rating ? IconTypes.SOLID : IconTypes.REGULAR}
                    />
                  </button>
                </div>
              </OverlayTrigger>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default InterviewerScoreCard;