import React from 'react';
import { NavLink } from 'react-router-dom';
import { DMDropdownItemModel } from './DMDropDown';

type DMDropdownSubItemProps = {
  data: DMDropdownItemModel;
  onSelect?: (...args: any[]) => void;
};

export const DMDropdownSubItem: React.FC<DMDropdownSubItemProps> = ({
  data: { id, text, href, ...rest },
  onSelect,
}) => {
  const onClick = () => {
    if (!onSelect) return;
    onSelect(id);
  };
  return (
    <div
      id={id}
      className="dm1-dropdown-subitem"
      onClick={onClick}
    >
      {rest.preIcon !== undefined && (
        <img
          src={rest.preIcon}
          alt="done"
          className="dm1-dropdown-icon"
        ></img>
      )}
      <NavLink
        to={href}
        className="w-100"
      >
        {text}
      </NavLink>
    </div>
  );
};
