import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import HrProfileMapsSidebar from './HrProfileMapsSidebar';
import HandleInfoFields from '../Shared/HandleInfoFields';
import { Col, Row } from 'react-bootstrap';
import { useAppSelector } from 'utilities/hooks';

const HrProfileMapsPage = () => {
  const { downloaderType, userMapId } = useParams<any>();
  
  const sectionAccess = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections);
  
  return (
    <Row>
      <Col sm={2}>
        <HrProfileMapsSidebar sectionAccess={sectionAccess} />
      </Col>
      <Col sm={10}>
        <Switch>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/`}
          >
            <HandleInfoFields
              pageName="Hire General"
              title="General Info"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/general-info`}
          >
            <HandleInfoFields
              pageName="Hire General"
              title="General Info"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/general-info-status`}
          >
            <HandleInfoFields
              pageName="ACA Status History"
              title="ACA Status History"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/general`}
          >
            <HandleInfoFields
              pageName="Employee General"
              title="Employee General"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/hrdates`}
          >
            <HandleInfoFields
              pageName="Employee Dates HR"
              title="Employee Dates HR"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/status`}
          >
            <HandleInfoFields
              pageName="Employee Status"
              title="Status"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/emergency-contact`}
          >
            <HandleInfoFields
              pageName="Emergency Contact HR"
              title="Emergency Contact"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/pay`}
          >
            <HandleInfoFields
              pageName="Pay"
              title="Pay"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/custom`}
          >
            <HandleInfoFields
              pageName="HR Custom"
              title="Custom"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/company-property`}
          >
            <HandleInfoFields
              pageName="Company Property"
              title="Company Property"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/dependents`}
          >
            <HandleInfoFields
              pageName="Dependents"
              title="Dependents"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/training-employment`}
          >
            <HandleInfoFields
              pageName="Employment History"
              title="Employment"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/training-education`}
          >
            <HandleInfoFields
              pageName="Education"
              title="Education"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/training-events`}
          >
            <HandleInfoFields
              pageName="Training Events"
              title="Events"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/training-licenses`}
          >
            <HandleInfoFields
              pageName="Licenses"
              title="Licenses"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/training-skills`}
          >
            <HandleInfoFields
              pageName="Employee Skills"
              title="Skills"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/training-sources`}
          >
            <HandleInfoFields
              pageName="Source and Fees"
              title="Sources and Fees"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/exit-interview`}
          >
            <HandleInfoFields
              pageName="Exit Interview"
              title="Exit Interview"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/appraisal-reviews`}
          >
            <HandleInfoFields
              pageName="Reviews"
              title="Reviews"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/appraisal-increase`}
          >
            <HandleInfoFields
              pageName="Increase"
              title="Increase"
            />
          </Route>
          <Route
            exact
            path={`/user-map/${downloaderType}/${userMapId}/vaccination`}
          >
            <HandleInfoFields
              pageName="Vaccination"
              title="Vaccination"
            />
          </Route>
        </Switch>
      </Col>
    </Row>
  );
};


HrProfileMapsPage.displayName = 'HrProfileMapsPage';

export default HrProfileMapsPage;
