import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';

export const RedirectSpinner: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col />
        <Col>
          <Row className="text-center" style={{ marginTop: '50%' }}>
            <Col />
            <Col>
              <p>Redirecting...</p>
              <Spinner animation="border" className="dm-primary-blue" />
            </Col>
            <Col />
          </Row>
        </Col>
        <Col />
      </Row>
    </Container>
  );
};

export default RedirectSpinner;