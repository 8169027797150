import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class ClientACA {
  clientNo = 0;

  year = 0;

  protectedClientNo = '';

  showAcaQuestionnaire = false;

  offerEmployeesInsurance = false;

  offerSpouseInsurance = false;

  selfInsured = false;

  offerMinCoverage = false;

  needInsuranceReferral = false;

  acaComment = '';

  planStartMonth = 0;

  waitingPeriodDays = 0;

  waitingPeriodFollowingMonth = false;

  monthlyPremiums: MonthlyPremium[] = [];

  formBorC = '';

  static readonly convProps: PropTypeLists = {
    numberProps: ['clientNo', 'year', 'planStartMonth', 'waitingPeriodDays'],
    boolProps: ['showAcaQuestionnaire'],
    radioProps: ['offerEmployeesInsurance', 'offerSpouseInsurance', 'selfInsured', 'offerMinCoverage', 'needInsuranceReferral', 'waitingPeriodFollowingMonth'],
  };

  constructor(props?: { [key: string]: any }) {
    props && convToClass(this, props, ClientACA.convProps);
  }
}

export class MonthlyPremium {
  acaMonthlyPremiumId?: number = 0;

  state = '';

  startMonthAfterWaitingPeriod = false;

  waitingPeriod = 0;

  planStartMonth = 0;

  amounts: PremiumAmount[] = [];

  editable?: boolean;

  static readonly convProps: PropTypeLists = {
    numberProps: ['acaMonthlyPremiumId', 'waitingPeriod', 'planStartMonth'],
    boolProps: ['startMonthAfterWaitingPeriod'],
  };

  constructor(props?: { [key: string]: any }) {
    props && convToClass(this, props, MonthlyPremium.convProps);
  }
}

export class PremiumAmount {
  acaMonthlyPremiumAmountId?: number = 0;

  effectiveDate: Date | string | null = null;

  amount = 0;

  static readonly convProps: PropTypeLists = {
    numberProps: ['acaMonthlyPremiumAmountId', 'amount'],
    dateProps: ['effectiveDate'],
  };

  constructor(props?: { [key: string]: any }) {
    props && convToClass(this, props, PremiumAmount.convProps);
  }
}