export const validateRoutingNo = (routingNo: string): string | boolean => {
  if (!routingNo) return true;

  //1. Ensure it is a valid number;
  if (!isValidNumberString(routingNo)) return 'Transit/ABA Number must be a valid number';

  //2. Ensure it is at least 8 numbers;
  if (routingNo.length < 8 || routingNo.length > 9) return 'Invalid Transit/ABA number';

  //3. Validate the first two digits of the routing no;
  if (!firstTwoDigitsAreValid(routingNo)) return 'The first two digits of the Transit/ABA number must be in the range 01-15, 21-32 or 61-72.';

  //4. Else Handle 9 digits;
  const sum = generateCheckDigit(routingNo);
  //If the resulting sum is an even multiple of ten (but not zero) the routing no is good;
  if ((sum !== 0) && (routingNo.length === 9 ? sum % 10 === 0 : true)) return true;
  
  //5. If we make it this far it is invalid: 
  return 'Invalid Transit/ABA number';
};


const isValidNumberString = (value: string): boolean => {
  //Check if the number string contains 'e' or is not a valid number;
  if ((value.indexOf('e') > -1) || isNaN(+value)) {
    // alert(errorMsg);
    return false;
  }
  return true;
};

const firstTwoDigitsAreValid = (routingNo: string): boolean => {
  const digits = +routingNo.substring(0, 2);

  if (!(
    (digits >= 1 && digits <= 15) ||
        (digits >= 21 && digits <= 32) ||
        (digits >= 61 && digits <= 72)
  )) {
    // alert("The first two digits of the Transit/ABA number must be in the range 01-15, 21-32 or 61-72.");
    return false;
  }
  return true;
};

export const generateCheckDigit = (routingNo: string): number => {
  let digit = 0;
  if (routingNo.length === 8) {
    for (let i = 0; i < routingNo.length; i += 3) {
      digit += +routingNo.charAt(i) * 3
                + +routingNo.charAt(i + 1) * 7
                + ((i === 6) ? 0 : +routingNo.charAt(i + 2));  // Do not calculate the last + when i is equal to 6.
    }

    let checkDigit = (10 - (digit % 10));
    checkDigit = checkDigit === 10 ? 0 : checkDigit;
    return checkDigit;
  }

  for (let i = 0; i < routingNo.length; i += 3) {
    digit += +routingNo.charAt(i) * 3
            + +routingNo.charAt(i + 1) * 7
            + +routingNo.charAt(i + 2);
  }
  return digit;
};