import { EarningCode, PayRateDropdown, PayRatesForDropDown, PayrollsUserOptions } from "core/models";
import { ReactElement } from "react";
import { formatWithCommas, parseFloatNumberWithCommas } from "utilities/utilities";

export const formatNegativeNumbers = (value: number, payrollsUserOptions: PayrollsUserOptions | null, fixedPoint = 2) => {
    const fixedPointValue = formatWithCommas(value, fixedPoint);
    let negativeValue: number | string | ReactElement = fixedPointValue;

    if (value < 0) {
      switch (payrollsUserOptions?.negativeNumberFormat) {
        case 0:
          negativeValue = fixedPointValue;
          break;
        case 1:
          negativeValue = <span style={{ color: '#ff0000' }}>{fixedPointValue}</span>;
          break;
        case 2:
          negativeValue = <span>({fixedPointValue})</span>;
          break;
        case 3:
          negativeValue = <span style={{ color: '#ff0000' }}>({fixedPointValue})</span>;
          break;
      }
    }
    return negativeValue;
  };

const isEmployeeRate = ({ rateId }: PayRateDropdown) => rateId > 0;

//PI-8637 moved to a shared method so we can get the rate options the same in both the main transmittal screen and the pay details screen.
export const filteredRateOptions = (payRateDropdown: PayRateDropdown[], earningCodeSettings: EarningCode[], earningCode: string, tranDigit: number, showRatesOnTransmittal: boolean) => {
  const filterPayRate = payRateDropdown.filter(x => x.tranDigit === tranDigit || x.tranDigit === -1);
  
  return filterPayRate?.map((option, optionIndex) => {
    const ecSetting = earningCodeSettings.find((es) => {return es.earnCode === earningCode;}) as EarningCode;
    //Will mask the rates if they have showRates on transmittal to false
    if (isEmployeeRate(option) && !showRatesOnTransmittal) { 
      return {
        ...option,
        hourlyMask: `Rate ${optionIndex + 1}`,
        salaryMask: `Rate ${optionIndex + 1}`,
      };
    }
  
    //If the earnings code is 2 or 3 then use the hourly description else use the salary description. PI-8637
    const salaryDescription = (['2', '3'].includes(earningCode)) ? option.hourlyDescription : option.salaryDescription;
    return {
      ...option,
      hourlyMask: option.hourlyDescription,
      salaryMask: option.salaryDescription,
      hourlyDescription: (!isNaN(parseFloatNumberWithCommas(option.hourlyDescription))) 
                                                            ? String((parseFloatNumberWithCommas(option.hourlyDescription) * (ecSetting?.premiumFactor ?? 1)).toFixed(6)) 
                                                            : option.hourlyDescription,
      salaryDescription: (!isNaN(parseFloatNumberWithCommas(salaryDescription))) 
                                                            ? String((parseFloatNumberWithCommas(salaryDescription) * (ecSetting?.premiumFactor ?? 1)).toFixed(6)) 
                                                            : option.salaryDescription,
    };
  }) ?? []; 
} 

//Note: This does not have the mask option as the old sytem did not mask the rates when in the check calc.
export const filteredRateOptionsCheckCalc = (payRateDropdown: PayRatesForDropDown[], earningCodeSettings: EarningCode[], earningCode: string, tranDigit: number) => {
  const filterPayRate = payRateDropdown.filter(x => x.tranDigit === tranDigit || x.tranDigit === -1);
  
  return filterPayRate?.map((option, optionIndex) => {
    const ecSetting = earningCodeSettings.find((es) => {return es.earnCode === earningCode;}) as EarningCode; 
    //If the earnings code is 2 or 3 then use the hourly description else use the salary description. PI-8637
    const salaryDescription = (['2', '3'].includes(earningCode)) ? option.hourlyDescription : option.salaryDescription;
    return {
      ...option,
      hourlyDescription: (!isNaN(parseFloatNumberWithCommas(option.hourlyDescription))) 
                                                            ? String((parseFloatNumberWithCommas(option.hourlyDescription) * (ecSetting?.premiumFactor ?? 1)).toFixed(6)) 
                                                            : option.hourlyDescription,
      salaryDescription: (!isNaN(parseFloatNumberWithCommas(salaryDescription))) 
                                                            ? String((parseFloatNumberWithCommas(salaryDescription) * (ecSetting?.premiumFactor ?? 1)).toFixed(6)) 
                                                            : option.salaryDescription,
    };
  }) ?? []; 
} 
   