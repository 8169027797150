import React, { useState } from 'react';
import { Row, Col, Accordion } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ContextAwareToggle from 'core/components/shared/ContextAwareToggle';
import { IssueToBeResolved } from 'core/models/IssueToBeResolved.model';
import {
  storeFilteredEmployees,
  storeFilterModel,
  loadEmployee,
} from 'core/store/actions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import PastDueModal from '../timeoff/PastDueModal';
import Icon from 'core/components/shared/Icon';
import { setShowApplicantsWaiting } from 'core/store/slices/applicantTracking.slice';

type Props = {
  visible: boolean;
};

const ButtonClickIssues : string[] = [
  'Applicants who are awaiting company action.',
  'MyInfo Changes',
  'My Workflow Tasks'
];

const MyInfoTask = (props: Props) => {
  const employees = useAppSelector((state) => state.employees.employees);

  const issues = useAppSelector(
    (state) => state.dashboard.issues,
  ) as IssueToBeResolved[];

  const dispatch = useAppDispatch();

  const [showTimeOffPastDue, setShowTimeOffPastDue] = useState(false);

  const handlePastDueClose = () => {
    setShowTimeOffPastDue(false);
  };

  const history = useHistory();

  const issueCommand = (ev: React.MouseEvent<HTMLButtonElement>) => {
    const currentIssue = issues.find(
      (issue) => { return issue.title === ev?.currentTarget?.value; },
    );
    const empNoArray = currentIssue?.empNos
      .split(',')
      .map((x) => { return +x; })
      .slice(0, -1);
    const effectedEmps = employees.filter((emp) => { return empNoArray?.includes(emp.empNo); },
    );
    let redirectToEmpList = true;

    switch (ev?.currentTarget?.value) {
      case 'Employees who are eligible for retirement Catch Up.':
        dispatch(storeFilteredEmployees(effectedEmps));
        dispatch(
          storeFilterModel({
            filterModel: {},
            radioSelection: 'All',
          }),
        );
        break;
      case 'MyInfo Changes':
        redirectToEmpList = false;
        history.push('/review-myinfo-changes');
        window.scrollTo(0, 0);
        break;
      case 'Employees who are locked out of MyInfo.DMPayroll.com.':
        redirectToEmpList = false;
        dispatch(storeFilteredEmployees(effectedEmps));
        dispatch(
          storeFilterModel({
            filterModel: {},
            radioSelection: 'All',
          }),
        );
        dispatch(loadEmployee(effectedEmps[0].protectedEmpNo)); //PI-8734
        history.push(
          `/employee/detail/${effectedEmps[0].protectedEmpNo}/self-serve`,
        );
        break;
      case 'Myinfo Accrual Requests':
        redirectToEmpList = false;
        setShowTimeOffPastDue(true);
        break;
      case 'Onboarding Employees':
        dispatch(storeFilteredEmployees(effectedEmps));
        dispatch(
          storeFilterModel({
            filterModel: {
              onboardingStatus: {
                sortOrder: 'UNSORTED',
                type: 'text',
                filterType: 'Contains',
              },
            },
            radioSelection: 'All',
          }),
        );
        break;
      case 'Duplicate entries with same Deduction Number':
        dispatch(storeFilteredEmployees(effectedEmps));
        dispatch(
          storeFilterModel({
            filterModel: {},
            radioSelection: 'All',
          }),
        );
        break;
      case 'Applicants who are awaiting company action.':
        redirectToEmpList = false;
        dispatch(setShowApplicantsWaiting(true));
        history.push('/manage-applicants');
        break;
      case 'My Workflow Tasks':
        redirectToEmpList = false;
        history.push('/workflow-tasks');
        break;
      default:
        redirectToEmpList = false;
    }
    if (redirectToEmpList) {
      history.push(
        `/employee-master-list?q=${issues
          .find((a) => { return a.title === ev?.currentTarget?.value; })
          ?.empNos.replace(/\s/g, '')}`,
      );
      window.scrollTo(0, 0);
    }
  };

  if (!props.visible) return null;
  return (
    <>
      <div className="dm-card">
        {issues ? (
          <div className="dm-card-body p-0">
            <div className="dm-card-body-title text-uppercase pl-2 pt-2">
              <Icon
                name="bell"
                fontSize="16px"
              />
              &nbsp;
              <span>Outstanding Tasks</span>
            </div>
            <Accordion defaultActiveKey={issues.length ? '0' : undefined}>
              <ContextAwareToggle
                eventKey="0"
                className="blue-bar"
                backgroundColor=""
              >
                <Row className="blue-bar m-0">
                  <Col
                    xs={4}
                    md={3}
                    className="my-auto pl-1 pr-0"
                  >
                    <div className="dm-dashboard-number mb-0">
                      {issues.length}
                    </div>
                  </Col>
                  <Col
                    xs={8}
                    md={9}
                    className="my-auto pl-0 pr-0"
                  >
                    <div>NEW TASKS</div>
                  </Col>
                </Row>
              </ContextAwareToggle>
              <Accordion.Collapse eventKey="0">
                <div className="list-group list-group-flush">
                  {issues.map((issue) => {
                    return (
                      <button
                        key={issue.idx}
                        className="list-group-item list-group-item-action"
                        disabled={(issue.empNos.length === 0) && !ButtonClickIssues.includes(issue.title)}
                        value={issue.title}
                        onClick={issueCommand}
                        type="button"
                      >
                        <h5 className="dm-dashboard-strong-text">
                          {issue.title}
                        </h5>
                        <p
                          className="dm-dashboard-text pre"
                          style={{
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {issue.body}
                        </p>
                      </button>
                    );
                  })}
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div>
        ) : null}
      </div>
      {showTimeOffPastDue && (
        <PastDueModal
          show={showTimeOffPastDue}
          onHide={handlePastDueClose}
          fromLanding={true}
        />
      )}
    </>
  );
};

export default MyInfoTask;
