/* eslint-disable react/jsx-no-undef */
import React from 'react';
import { Route } from 'react-router-dom';
import { useAuth } from 'core/providers/authProvider';
import { mainRoutes, ToolbarComponentName } from 'navigation/app-main-nav';

type PropTypes = {
  toggleSidebar: (e: React.BaseSyntheticEvent) => void,
  showSidebarMenuIcon: boolean,
};

const AppRouteBar: React.FC<PropTypes> = ({ toggleSidebar, showSidebarMenuIcon }) => {
  const auth = useAuth();

  if (!auth.isAuthenticated()) return null;

  return (
    <div>
      {mainRoutes
        .filter(route => {return route.toolbarComponent !== undefined;})
        .map((route: any, index: number) => {
          return (
            <Route
              key={index}
              path={route.route}
              exact={route.exact}
              render={(props) => {
                if (route.toolbarComponentName !== ToolbarComponentName.EMPLOYEE_TOOLBAR) {
                  return (
                    <route.toolbarComponent
                      toggleSidebar={toggleSidebar}
                      showSidebarMenuIcon={showSidebarMenuIcon}
                      isDeptAllocations={route?.isDeptAllocations}
                      {...props}
                    />
                  );
                } else {
                  return (
                    <route.toolbarComponent
                      toggleSidebar={toggleSidebar}
                      showSidebarMenuIcon={showSidebarMenuIcon}
                      isMaster={route?.isMaster}
                      {...props}
                    />
                  );
                }
              }}
            />
          );
        })}
    </div>
  );
};

export default AppRouteBar;