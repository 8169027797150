import { createReducer } from '@reduxjs/toolkit';
import {
  PayrollAdjustmentSummary,
  PayrollAdjustmentEmployee,
} from '../../models';
import {
  breakValidationChain,
  clearPayrollAdjustment,
  clearPrintAdjustmentCheck,
  clearValidatedCheck,
  createAdjustmentType,
  deleteBlankCheck,
  deletePayrollAdjustmentCheck,
  loadEmployeePayrollAdjustment,
  loadPayrollAdjustmentNewCheck,
  loadPayrollAdjustments,
  setCloseModal,
  storeEmployeePayrollAdjustment,
  storePayrollAdjustmentNewCheck,
  storePayrollAdjustments,
  storePrintAdjustmentCheck,
  storeValidatedCheck,
  payrollAdjustmentCheckValidate,
  createPayrollAdjustmentCheck,
  updatePayrollAdjustmentCheck,
} from '../actions';

export interface State {
  loading: boolean;
  closeModal: boolean;
  payrollAdjustments: PayrollAdjustmentEmployee[];
  adjustmentCheckDefaults: PayrollAdjustmentSummary | null;
  validateCheckErrors: string[];
  originalAdjustment: PayrollAdjustmentSummary | null;
  validatedCheck: PayrollAdjustmentSummary | null;
  printAdjustmentCheck: string;
  breakValidationChain: boolean;
  loadingValidation: boolean;
  savingAdjustmentCheck: boolean;
}

export const INITIAL_STATE: State = {
  loading: false,
  closeModal: false,
  payrollAdjustments: [],
  adjustmentCheckDefaults: null,
  validateCheckErrors: [],
  validatedCheck: null,
  originalAdjustment: null,
  printAdjustmentCheck: '',
  breakValidationChain: false,
  loadingValidation: false,
  savingAdjustmentCheck: false
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(loadPayrollAdjustments, (state) => {
      state.loading = true;
      state.savingAdjustmentCheck = false;
    })
    .addCase(storePayrollAdjustments, (state, action) => {
      state.loading = false;
      state.payrollAdjustments = action.payload;
    })
    .addCase(loadEmployeePayrollAdjustment, (state) => {
      state.loading = true;
    })
    .addCase(storeEmployeePayrollAdjustment, (state, action) => {
      state.loading = false;
      const employee = state.payrollAdjustments.find(
        (a) => {return a.empNo === action.payload.empNo;},
      );
      if (!employee) {
        state.payrollAdjustments.push(action.payload);
      }
    })
    .addCase(loadPayrollAdjustmentNewCheck, (state) => {
      state.loading = true;
    })
    .addCase(storePayrollAdjustmentNewCheck, (state, action) => {
      state.loading = false;
      state.adjustmentCheckDefaults = action.payload;
    })
    .addCase(clearPayrollAdjustment, (state) => {
      state.payrollAdjustments = [];
      state.adjustmentCheckDefaults = null;
    })
    .addCase(createAdjustmentType, (state, action) => {
      const employee = state.payrollAdjustments.find(
        (a) => {return a.empNo === action.payload.empNo;},
      );
      if (employee && (employee.adjustmentChecks?.filter((check) => check.adjustmentId === 0)?.length < 1)) {
        employee.adjustmentChecks.push(action.payload.blankCheck);
        const index = state.payrollAdjustments.findIndex(
          (a) => {return a.empNo === action.payload.empNo;},
        );
        if (index > -1) {
          state.payrollAdjustments[index].adjustmentChecks = [...employee.adjustmentChecks];
        }
      }
    })
    .addCase(payrollAdjustmentCheckValidate, (state, action) => {
      state.loadingValidation = true;
    })
    .addCase(storeValidatedCheck, (state, action) => {
      state.loadingValidation = false;
      state.validatedCheck = action.payload.adjustment;
      state.originalAdjustment = action.payload.originalAdjustment;
      state.validateCheckErrors = action.payload.errors;
    })
    .addCase(clearValidatedCheck, (state) => {
      state.validatedCheck = null;
      state.validateCheckErrors = [];
    })
    .addCase(createPayrollAdjustmentCheck, (state, action) => {
      state.savingAdjustmentCheck = true;
    })
    .addCase(updatePayrollAdjustmentCheck, (state, action) => {
      state.savingAdjustmentCheck = true;
    })
    .addCase(deletePayrollAdjustmentCheck, (state, action) => {
      const employeeIndex = state.payrollAdjustments.findIndex((a) => a.empNo === action.payload.empNo);
      if (employeeIndex < 0) return console.error('Employee adjustments not found');
      
      const remainingChecks = state.payrollAdjustments[employeeIndex]?.adjustmentChecks
        ?.filter(({ adjustmentId }) => adjustmentId !== action.payload.adjustmentId);
      
      if (!remainingChecks.length) {
        state.payrollAdjustments.splice(employeeIndex, 1);
        state.closeModal = true;
        return;
      }
      state.payrollAdjustments[employeeIndex].adjustmentChecks = remainingChecks;
    })
    .addCase(deleteBlankCheck, (state, action) => {
      const employeeIndex = state.payrollAdjustments.findIndex(
        (a) => {return a.empNo === action.payload;},
      );
      
      if (employeeIndex < 0) return console.error('Employee adjustments not found');
      
      const checks = state.payrollAdjustments[employeeIndex]?.adjustmentChecks
        ?.filter(({ adjustmentId }) => adjustmentId !== 0);
          
      if (!checks.length) {
        state.payrollAdjustments.splice(employeeIndex, 1);
        state.closeModal = true;
        return;
      }
      if (state.payrollAdjustments[employeeIndex]?.adjustmentChecks) {
        state.payrollAdjustments[employeeIndex].adjustmentChecks = checks;
      }
    })
    .addCase(storePrintAdjustmentCheck, (state, action) => {
      state.printAdjustmentCheck = action.payload;
    })
    .addCase(clearPrintAdjustmentCheck, (state) => {
      state.printAdjustmentCheck = '';
    })
    .addCase(setCloseModal, (state, action) => {
      state.closeModal = action.payload;
    })
    .addCase(breakValidationChain, (state, action) => {
      state.breakValidationChain = action.payload;
    });
});
