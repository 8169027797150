import React from 'react';
import styles from './shared.module.scss';
import ThreeDotLoader from './ThreeDotLoader';
import PageButton from './PageButton';

type PaginationProps = {
  loading: boolean;
  totalCount: number;
  currentPage: number;
  pageSize: number;
  currentRange: [number, number];
  numPages: number;
  disabled: boolean;
  onPageChange: (newPageNum: number) => void;
  inputValue?: number;
  onPageInputChange?: (newPageNum: number) => void;
  onMouseEnter?: () => void;
};

const Pagination = ({
  loading,
  totalCount,
  currentPage,
  currentRange,
  numPages,
  onPageChange,
  inputValue,
  onPageInputChange,
  onMouseEnter,
  disabled,
}: PaginationProps) => {  
  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newVal = parseInt(e.target.value);
              
    if (isNaN(newVal)) newVal = currentPage;
    if (newVal > numPages) newVal = numPages;
    if (newVal < 1) newVal = 1;

    // I did this because I wanted to debounce the input value for the transmittal screen so the user has some time to enter double digits.
    // I am very tired and this will probably change but this will work fine.
    if (onPageInputChange) {
      onPageInputChange(newVal);
    } else {
      onPageChange(newVal);
    }
  };
  
  return (
    <div
      className={styles['pagination-container']}
      onMouseEnter={onMouseEnter}
    >
      {loading ? <ThreeDotLoader /> : (
        <>
          <div className={styles['page-info']}>
            {(currentRange?.[0] ?? 0) + 1} - {(currentRange?.[1] ?? 19) + 1} of {totalCount}
          </div>
          <div className={styles['page-selector']}>
            <PageButton
              title="First page"
              icon="angles-left"
              disabled={currentPage === 1}
              onPageChange={() => { onPageChange(1); }}
            />
            <PageButton
              title="Previous page"
              icon="angle-left"
              disabled={currentPage === 1}
              onPageChange={() => { onPageChange(currentPage - 1); }}
            />
            <div className={styles['page-info']}>
              Page
              <input 
                type="number"
                className={styles['page-selector-input']}
                value={inputValue ?? currentPage}
                onChange={handlePageInputChange}
                onFocus={(e) => { e.target.select(); }}
              />
              of {numPages}
            </div>
            <PageButton
              title="Next page"
              icon="angle-right"
              disabled={currentPage === numPages}
              onPageChange={() => { onPageChange(currentPage + 1); }}
            />
            <PageButton
              title="Final page"
              icon="angles-right"
              disabled={currentPage === numPages}
              onPageChange={() => { onPageChange(numPages); }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Pagination;