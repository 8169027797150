
import React, { useEffect } from 'react';
import { CheckboxGrpInLine } from 'core/components/form-controls';
import Icon from 'core/components/shared/Icon';
import { Client } from 'core/models';
import { EmployeePayRateDownloadRequest } from 'core/models/PayRateReports.model';
import { CommonService } from 'core/services';
import { downloadEmployeePayRateDownloadReport, clearEmployeePayRateDownloadReport } from 'core/store/slices/payRateReports.slice';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'utilities/hooks';

const EmployeePayRateDownload = () => {
  
  const dispatch = useDispatch();

  const client = useAppSelector((state) => { return state.client.client; }) as Client;
  const report = useAppSelector((state) => { return state.payRateReports.employeePayRateReportDownload; });

  const { register, handleSubmit } = useForm();
  
  const reportName = 'EmployeePayRateDownload';

  useEffect(() => {
    if (report) {
      CommonService.downloadBase64File(
        'application/excel',
        report,
        `${reportName}.xlsx`,
      );
      dispatch(clearEmployeePayRateDownloadReport());
    }
  }, [report]);

  const downloadReport = (data: EmployeePayRateDownloadRequest) => {
    const submitData: EmployeePayRateDownloadRequest = {
      clientNo: client.clientNo,
      includeTermEmployees: data.includeTermEmployees,
    };
    dispatch(downloadEmployeePayRateDownloadReport(submitData));
  };

  return (
    <Row className="w-100">
      <Col>
        <div>
          <div className="dm-panel dm-panel-border">
            <div className="dm-grid-title">Employee Pay Rate Download</div>
            <form onSubmit={handleSubmit(downloadReport)}>
              <div className="w-100 text-right">
                <button
                  type="submit"
                  className="btn btn-link dm-grid-action-title"
                >
                  Download Report  <Icon
                    name="download"
                    className="fa-download"
                  />
                </button>
              </div>
              <hr className="dm-panel-hr" />
              <div className="mt-2">
                <CheckboxGrpInLine
                  name="includeTermEmployees"
                  label="Include Terminated Employees"
                  labelWidth={200}
                  labelWidthUnit="px"
                  ref={register}
                />
              </div>
            </form>
          </div>
        </div>
      </Col>
    </Row>
  );

};


export default EmployeePayRateDownload;