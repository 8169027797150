import React, { useMemo, useState } from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import Icon from 'core/components/shared/Icon';
import { ApplicationHistory } from 'core/models';
import { sortBy } from 'lodash';
import { toReadableDate } from 'utilities/utilities';
import HistoryItem from './HistoryItem';

type Props = {
  histories: ApplicationHistory[];
  setSelectedTab?: React.Dispatch<React.SetStateAction<string>>;
};

enum HeaderColumns {
  DATE_OF_APPLICATION = 'dateOfApplication',
  STATUS = 'status',
  POSITION = 'position',
}

const SortIcon = ({
  header,
  activeHeader,
}: {
  header: string;
  activeHeader: string;
}) => {
  let icon: IconName = 'sort';

  if (activeHeader.includes(header)) {
    if (activeHeader.includes('-')) {
      icon = 'sort-up';
    } else {
      icon = 'sort-down';
    }
  }

  return <Icon
    name={icon}
    className="ml-2"
    fontSize={13}
  />;
};

const HistoryList = ({ histories }: Props) => {
  const [activeHeader, setActiveHeader] = useState(`-${HeaderColumns.DATE_OF_APPLICATION}`);

  const onHeaderChange = (header: string) => {
    if (activeHeader.includes(header)) {
      if (!activeHeader.includes('-')) {
        setActiveHeader(`-${header}`);
        return;
      }
    }

    setActiveHeader(header);
  };

  const parsedHistories = useMemo(() => {
    if (activeHeader.includes(HeaderColumns.DATE_OF_APPLICATION)) {
      const sortedHistories = sortBy(histories, 'dateOfApplication');

      if (activeHeader.includes('-')) {
        sortedHistories.reverse();
      }

      return sortedHistories;
    } else if (activeHeader.includes(HeaderColumns.STATUS)) {
      const sortedHistories = sortBy(histories, 'status');

      if (activeHeader.includes('-')) {
        sortedHistories.reverse();
      }

      return sortedHistories;
    } else if (activeHeader.includes(HeaderColumns.POSITION)) {
      const sortedHistories = sortBy(histories, 'positionAppliedFor');

      if (activeHeader.includes('-')) {
        sortedHistories.reverse();
      }

      return sortedHistories;
    }

    return histories;
  }, [histories, activeHeader]);

  return (
    <div className="px-3 pb-3">
      <div
        className="p-3"
        style={{ background: 'white' }}
      >
        <p
          className="dm-grid-title text-sm mb-2"
          style={{
            fontSize: '1.2rem',
            lineHeight: '1.2rem',
          }}
        >
          Application History
        </p>
        <hr className="dm-panel-hr" />
        <table>
          <tbody>
            <tr>
              <th
                style={{
                  paddingRight: '3rem',
                  cursor: 'pointer',
                }}
                className="py-1"
                onClick={() => {
                  onHeaderChange(HeaderColumns.DATE_OF_APPLICATION);
                }}
              >
                Date Of Application
                <SortIcon
                  header={HeaderColumns.DATE_OF_APPLICATION}
                  activeHeader={activeHeader}
                />
              </th>
              <th
                style={{
                  paddingRight: '3rem',
                  cursor: 'pointer',
                }}
                className="py-1"
                onClick={() => {
                  onHeaderChange(HeaderColumns.STATUS);
                }}
              >
                Status
                <SortIcon
                  header={HeaderColumns.STATUS}
                  activeHeader={activeHeader}
                />
              </th>
              <th
                style={{
                  paddingRight: '3rem',
                  cursor: 'pointer',
                }}
                className="py-1"
                onClick={() => {
                  onHeaderChange(HeaderColumns.POSITION);
                }}
              >
                Position
                <SortIcon
                  header={HeaderColumns.POSITION}
                  activeHeader={activeHeader}
                />
              </th>
            </tr>
            {parsedHistories.map((item) => {
              return (
                <HistoryItem
                  key={item?.dateOfApplication}
                  dateOfApplication={item?.dateOfApplication}
                  status={item?.status}
                  positionAppliedFor={item?.positionAppliedFor}
                  historyItem={item}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HistoryList;
