import { ofType } from 'redux-observable';
import { Observable, catchError, map, switchMap, from, mergeMap } from 'rxjs';
import { handleError, handleSuccess } from '../actions/app.action';
import { WorkerCompService } from '../../services';
import { HttpResponse, WCEffectiveDate } from '../../models';
import {
  createWCEffectiveDate,
  deleteWCEffectiveDate,
  deleteWCRate,
  loadWCEffectiveDate,
  loadWCEffectiveDates,
  storeWCEffectiveDate,
  storeWCEffectiveDates,
  updateWCEffectiveDate,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadWCEffectiveDates$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadWCEffectiveDates.type),
    switchMap(() => {
      return from(WorkerCompService.getWCEffectiveDates()).pipe(
        map((res: any) => { return res.data; }),
        map((res: WCEffectiveDate[]) => { return storeWCEffectiveDates(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadWCEffectiveDate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadWCEffectiveDate.type),
    switchMap((action: { payload: number; }) => {
      return from(WorkerCompService.getWCEffectiveDate(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: WCEffectiveDate) => { return storeWCEffectiveDate(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createWCEffectiveDate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createWCEffectiveDate.type),
    switchMap((action: { payload: WCEffectiveDate; }) => {
      return from(WorkerCompService.postWCEffectiveDate(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<WCEffectiveDate>) => {
          return [
            handleSuccess(res.messages[0]),
            storeWCEffectiveDate(res.value),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateWCEffectiveDate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateWCEffectiveDate.type),
    switchMap((action: { payload: WCEffectiveDate; }) => {
      return from(WorkerCompService.putWCEffectiveDate(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<WCEffectiveDate>) => {
          return [
            handleSuccess(res.messages[0]),
            storeWCEffectiveDate(res.value),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteWCEffectiveDate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteWCEffectiveDate.type),
    switchMap((action: { payload: WCEffectiveDate; }) => {
      return from(WorkerCompService.deleteWCEffectiveDate(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<WCEffectiveDate>) => {
          return [
            handleSuccess(res.messages[0]),
            loadWCEffectiveDates(),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteWCRate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteWCRate.type),
    switchMap((action: { payload: number; }) => {
      return from(WorkerCompService.deleteWCRate(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<WCEffectiveDate>) => {
          return [
            handleSuccess(res.messages[0]),
            loadWCEffectiveDates(),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadWCEffectiveDates$,
  loadWCEffectiveDate$,
  createWCEffectiveDate$,
  updateWCEffectiveDate$,
  deleteWCEffectiveDate$,
  deleteWCRate$,
];
