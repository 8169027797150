import { AxiosResponse } from "axios";
import { ACA, ACA1095B, AcaStatus, ACAWaiveCoverage, ClientACA, CoveredIndividual, EmployeeInformationOverride, EmployeeShare, HttpResponse, OfferOfCoverage, SafeHarbor, ZipCode } from '../models';
import { API_CLIENT, API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { ClientMasterEndPoints, EmployeeEndPoints } from './URL';

const getEmployeeACATabData = (protectedEmpNo: string, prYear: number, protectedClientNo?: string): Promise<AxiosResponse<ACA>> => {return API_EMPLOYEE_MASTER.get<ACA>(EmployeeEndPoints.EMPLOYEES_ACA_TAB(protectedClientNo || CommonService.getProtectedClientNo(), protectedEmpNo, prYear));};

const postEmployeeACAStatus = (data: AcaStatus): Promise<AxiosResponse<HttpResponse<AcaStatus>>> => {
  const item = {
    ...data,
    protectedClientNo: CommonService.getProtectedClientNo(),
  };
  return API_EMPLOYEE_MASTER.post<HttpResponse<AcaStatus>>(EmployeeEndPoints.EMPLOYEES_ACA_STATUS(CommonService.getProtectedClientNo(), data.protectedEmpNo), item);
};

const putEmployeeACAOverridesOfferOfCoverage = (data: OfferOfCoverage): Promise<AxiosResponse<HttpResponse<OfferOfCoverage>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<OfferOfCoverage>>(EmployeeEndPoints.EMPLOYEES_ACA_OVERRIDES_OFFEROFCOVERAGE(CommonService.getProtectedClientNo(), data.protectedEmpNo, data?.year), data);};

const putEmployeeACAOverridesEmployeeShare = (data: EmployeeShare): Promise<AxiosResponse<HttpResponse<EmployeeShare>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<EmployeeShare>>(EmployeeEndPoints.EMPLOYEES_ACA_OVERRIDES_EMPLOYEESHARE(CommonService.getProtectedClientNo(), data.protectedEmpNo, data?.year), data);};

const putEmployeeACAOverridesSafeHarbor = (data: SafeHarbor): Promise<AxiosResponse<HttpResponse<SafeHarbor>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<SafeHarbor>>(EmployeeEndPoints.EMPLOYEES_ACA_OVERRIDES_SAFEHARBOR(CommonService.getProtectedClientNo(), data.protectedEmpNo, data?.year), data);};

const putEmployeeACAOverridesZipCode = (data: ZipCode): Promise<AxiosResponse<HttpResponse<ZipCode>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<ZipCode>>(EmployeeEndPoints.EMPLOYEES_ACA_OVERRIDES_ZIPCODE(CommonService.getProtectedClientNo(), data.protectedEmpNo, data?.year), data);};

const putEmployeeACAOverridesEmployeeInfo = (data: EmployeeInformationOverride): Promise<AxiosResponse<HttpResponse<EmployeeInformationOverride>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<EmployeeInformationOverride>>(EmployeeEndPoints.EMPLOYEES_ACA_OVERRIDES_EMPINFO(CommonService.getProtectedClientNo(), data.protectedEmpNo, data?.year), data);};

const deleteEmployeeACAOverrides = (protectedEmpNo: string, prYear: number): Promise<AxiosResponse<HttpResponse<any>>> => {return API_EMPLOYEE_MASTER.delete<HttpResponse<any>>(EmployeeEndPoints.EMPLOYEES_ACA_OVERRIDES(CommonService.getProtectedClientNo(), protectedEmpNo, prYear));};

const postEmployeeACACoveredIndividuals = (data: CoveredIndividual): Promise<AxiosResponse<HttpResponse<CoveredIndividual>>> => {return API_EMPLOYEE_MASTER.post<HttpResponse<CoveredIndividual>>(EmployeeEndPoints.EMPLOYEES_ACA_COVEREDINDIVIDUAL(CommonService.getProtectedClientNo(), data.protectedEmpNo, data?.year), data);};

const putEmployeeACACoveredIndividuals = (data: CoveredIndividual): Promise<AxiosResponse<HttpResponse<CoveredIndividual>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<CoveredIndividual>>(EmployeeEndPoints.EMPLOYEES_ACA_COVEREDINDIVIDUAL_ID(CommonService.getProtectedClientNo(), data.protectedEmpNo, data?.year, data?.dependentId), data);};

const deleteEmployeeACACoveredIndividuals = (data: CoveredIndividual): Promise<AxiosResponse<HttpResponse<any>>> => {return API_EMPLOYEE_MASTER.delete<HttpResponse<any>>(EmployeeEndPoints.EMPLOYEES_ACA_COVEREDINDIVIDUAL_ID(CommonService.getProtectedClientNo(), data.protectedEmpNo, data?.year, data?.dependentId));};


const putEmployeeACAStatus = (data: AcaStatus): Promise<AxiosResponse<HttpResponse<AcaStatus>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<AcaStatus>>(EmployeeEndPoints.PUT_EMPLOYEES_ACA_STATUS(CommonService.getProtectedClientNo(), data.protectedEmpNo, data?.effectiveDate || new Date().toLocaleDateString()), data);};

const deleteEmployeeACAStatus = (protectedEmpNo: string, effectiveDate: string): Promise<AxiosResponse<HttpResponse<any>>> => {return API_EMPLOYEE_MASTER.delete<HttpResponse<any>>(EmployeeEndPoints.DELETE_EMPLOYEES_ACA_STATUS(CommonService.getProtectedClientNo(), protectedEmpNo, effectiveDate));};

const getEmployeeACA1095B = (protectedEmpNo: string, prYear: number): Promise<AxiosResponse<ACA1095B>> => {return API_EMPLOYEE_MASTER.get<ACA1095B>(EmployeeEndPoints.EMPLOYEES_ACA_1095B(CommonService.getProtectedClientNo(), protectedEmpNo, prYear));};

const putEmployeeACA1095B = (data: ACA1095B): Promise<AxiosResponse<HttpResponse<ACA1095B>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<ACA1095B>>(EmployeeEndPoints.EMPLOYEES_ACA_1095B(CommonService.getProtectedClientNo(), data.protectedEmpNo, data.year), data);};

const putEmployeeACAWaiveCoverage = (data: ACAWaiveCoverage): Promise<AxiosResponse<HttpResponse<ACAWaiveCoverage>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<any>>(EmployeeEndPoints.EMPLOYEES_ACA_WAIVECOVERAGE(CommonService.getProtectedClientNo(), data.protectedEmpNo || '', data.year || 0), data);};

const getClientACA = (year: number): Promise<AxiosResponse<ClientACA>> => {return API_CLIENT.get<ClientACA>(ClientMasterEndPoints.CLIENT_ACA(CommonService.getProtectedClientNo(), year));};

const putClientACA = (data: ClientACA): Promise<AxiosResponse<HttpResponse<ClientACA>>> => {return API_CLIENT.put<HttpResponse<ClientACA>>(ClientMasterEndPoints.CLIENT_ACA(CommonService.getProtectedClientNo(), data.year), data);};

export const EmployeeACAService = {
  getEmployeeACATabData,
  putEmployeeACAOverridesEmployeeShare,
  putEmployeeACAOverridesOfferOfCoverage,
  putEmployeeACAOverridesSafeHarbor,
  putEmployeeACAOverridesZipCode,
  putEmployeeACAOverridesEmployeeInfo,
  deleteEmployeeACAOverrides,
  postEmployeeACACoveredIndividuals,
  putEmployeeACACoveredIndividuals,
  deleteEmployeeACACoveredIndividuals,
  postEmployeeACAStatus,
  putEmployeeACAStatus,
  deleteEmployeeACAStatus,
  getEmployeeACA1095B,
  putEmployeeACA1095B,
  putEmployeeACAWaiveCoverage,
  getClientACA,
  putClientACA,
};