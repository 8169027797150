import Modal from 'core/components/modals/Modal';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { InputGrpInLine } from '../../../core/components/form-controls';
import { AccrualEdit } from '../../../core/models';
import { updateAccrual } from '../../../core/store/actions';
import { toFixed } from '../../../utilities/utilities';
type PropTypes = {
  item: AccrualEdit;
  show: boolean;
  onHide: () => void;
};

type Form = {
  amount: string;
  note: string;
};

const AccrualRecordItemModal: React.FC<PropTypes> = ({
  item,
  show,
  onHide,
}) => {
  const dispatch = useDispatch();

  const { setValue, register, handleSubmit, errors } = useForm<Form>({
    defaultValues: {
      amount: item.amount.toFixed(4),
      note: item.note,
    },
  });

  const onSave = (data: any) => {
    const x = new AccrualEdit(
      item.clientNo,
      item.empNo,
      item.protectedEmpNo,
      item.balanceId,
      item.recordId,
      item.type,
      data,
    );
    dispatch(updateAccrual(x));
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title="ACCRUAL RECORD"
    >
      <form onSubmit={handleSubmit(onSave)}>
        <div className="d-flex flex-column">
          <InputGrpInLine
            name="amount"
            label="AMOUNT"
            labelWidth={35}
            groupClass="groupClass35"
            onBlur={(
              e: React.ChangeEvent<HTMLInputElement>,
            ) => {
              setValue(
                'amount',
                toFixed(e.target.value, 4),
              );
            }}
            inputClass="text-right"
            errors={errors.amount}
            ref={register({
              valueAsNumber: true,
              required: {
                value: true,
                message: 'Amount is required',
              },
            })}
          />
          <InputGrpInLine
            name="note"
            label="NOTES"
            labelWidth={17}
            groupClass="groupClass70"
            errors={errors.note}
            ref={register}
          />
        </div>
        <div className="row mt-4">
          <div className="col-12 text-right">
            <button
              type="button"
              className="btn btn-primary orange-outline-button mr-3"
              onClick={() => { return onHide(); }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary orange-button"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AccrualRecordItemModal;
