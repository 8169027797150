import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppEmpSearch } from 'core/models/AppEmpSearch.model';
import { loadAppEmpSearch, showRootModal } from 'core/store/actions';

type PropTypes = {
  onHide: () => void;
};

const AppSearch: React.FC<PropTypes> = ({ onHide }) => {
  const [searchText, setSearchText] = useState('');
  const [searchAllClients, setSearchAllClients] = useState(false);
  const dispatch = useDispatch();

  const onSearch = (e: any) => {
    e.preventDefault();
    const request = new AppEmpSearch();
    request.allClients = searchAllClients;

    const x = parseInt(searchText);
    if (isNaN(x)) {
      request.lastName = searchText;
    } else {
      searchText.length === 9
        ? (request.ssn = searchText)
        : (request.empNo = +searchText);
    }
    dispatch(loadAppEmpSearch(request));
    // PI-8410: moving this here. It's a good idea, but right now only being set AFTER the request is done, which isn't really useful for a loading state
    // unless we show something like a toast message (maybe when we do app - wide notifications).
    dispatch(showRootModal({
      modalType: 'APP_SEARCH_LISTING_MODAL',
      modalProps: {},
    }));
    onHide();
  };

  return (
    <form onSubmit={onSearch}>
      <div className="form-group">
        <label className="form-label">
          Search by SSN, Emp No. or Last Name
        </label>
        <input
          autoFocus
          type="text"
          className="form-control"
          name="searchText"
          defaultValue={searchText}
          onChange={(e: any) => {return setSearchText(e.target.value);}}
        />
      </div>
      <div className="form-group">
        <input
          type="checkbox"
          name="searchAllClients"
          defaultChecked={searchAllClients}
          onChange={(e: any) => {return setSearchAllClients((prev) => {return !prev;});}}
        />
        <label className="form-label">
          Search all clients I have access to.
        </label>
      </div>

      <div className="text-right pt-2">
        <button
          type="submit"
          className="btn btn-primary btn-sm orange-button"
        >
          Search
        </button>
      </div>
    </form>
  );
};

export default AppSearch;
