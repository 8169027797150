import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class PayRateHistory {
  clientNo = 0;

  empNo = 0;

  histId = 0;

  rateId = 0;

  hourlyRate = 0;

  salaryRate = 0;

  annualRate = 0;

  notes = '';

  effectiveDate: Date | null = null;

  historyDate: Date | null = null;

  beginDateGuard: Date | null = null;

  endDateGuard: Date | null = null;

  static readonly convProps: PropTypeLists = {
    dateProps: ['effectiveDate', 'historyDate', 'beginDateGuard', 'endDateGuard'],
    numberProps: ['clientNo', 'empNo', 'histId', 'rateId'],
    floatProps: ['hourlyRate', 'salaryRate', 'annualRate'],
  };

  constructor(
    clientNo: number | string,
    empNo: number | string,
    rateId: number | string,
    props?: { [key: string]: any }) {

    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.rateId = (typeof rateId === 'string') ? parseInt(rateId) : rateId;

    props && convToClass(this, props, PayRateHistory.convProps);
  }
}