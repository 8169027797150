import { createSelector } from 'reselect';
import { earningsRecordsState } from '../reducers';
import { State as EarningsRecordsState } from '../reducers/earnings-records.reducer';

export const getEarningsRecords = createSelector(
  earningsRecordsState,
  (state: EarningsRecordsState) => {
    if (!state?.earningsRecords) return null;

    // return convToFormObject(state.earningsRecords, EarningsRecords.convProps) as EmpDate;
    return state.earningsRecords;
  },
);
