import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useAppSelector } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';
import { AvailableJob, newJob } from 'core/models';
import { storeSelectedJob } from 'core/store/slices/applicantTracking.slice';
import { useDispatch } from 'react-redux';
import HamburgerMenu from 'core/components/shared/HamburgerMenu';

type ApplicantToolbarProps = {
  toggleSidebar: () => void;
  showSidebarMenuIcon: boolean;
};

const ApplicantToolbar: React.FC<ApplicantToolbarProps> = ({ toggleSidebar, showSidebarMenuIcon }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const groupAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.groups;
  });

  const manageApplicantSection = groupAccess?.find((group) => {
    return group.description.toLowerCase() === 'applicants';
  });

  const visible = manageApplicantSection?.visible;
  
  const onJobSelect = (data:AvailableJob) => {
    dispatch(storeSelectedJob(data));
    history.push('manage-job');
  };

  return (
    <div className="dm-toolbar">
      {visible === false ? null : (
        <>
          {showSidebarMenuIcon ? (
            <HamburgerMenu
              toggleSidebar={toggleSidebar}  
            />
          ) : (
            <>
              <div className="d-flex flex-row flex-grow-1">
                <div className="d-flex flex-row flex-grow-1 justify-content-start">
                  <NavLink to="/manage-applicants">
                    <Icon
                      name={'user'}
                      style={{
                        padding: 0,
                        marginRight: 10,
                      }}
                    />Manage Applicants
                  </NavLink>
                  <NavLink to="/manage-available-jobs">
                    <Icon
                      name={'wrench'}
                      style={{
                        padding: 0,
                        marginRight: 10,
                      }}
                    />Manage Available Jobs</NavLink>
                </div>
                <div className="d-flex flex-row flex-grow-1 justify-content-end">
                  <a     
                    href="#"            
                    onClick={()=> {return onJobSelect(newJob);}}
                  >
                    <Icon
                      name="plus-circle"
                      style={{
                        padding: 0,
                        marginRight: 5,
                      }}                     
                    /> New Job
                  </a>
                  <NavLink to="/design-interview">
                    <Icon
                      name={'compass-drafting'}
                      style={{
                        padding: 0,
                        marginRight: 10,
                      }}
                    />Design Interview</NavLink>
                  <NavLink to="/design-ats-website">
                    <Icon
                      name={'wand-magic-sparkles'}
                      style={{
                        padding: 0,
                        marginRight: 10,
                      }}
                    />Design ATS Website</NavLink>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ApplicantToolbar;
