import Icon from 'core/components/shared/Icon';
import PanelHeader from 'core/components/shared/PanelHeader';
import { ScorecardCategory, ScorecardTrait } from 'core/models';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'utilities/hooks';
import { Row, Col } from 'react-bootstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi, GridOptions, ICellRendererParams } from '@ag-grid-community/core';
import { useDispatch } from 'react-redux';
import { deleteScorecardCategory, getScorecardCategories, getScorecardTraits, storeScorecardCategory } from 'core/store/slices/applicantTracking.slice';
import ScorecardCategoryModal from './ScorecardCategory.modal';

const ScorecardCategoriesPage = () => {
  
  const dispatch = useDispatch();
  const [scorecardCategories, scorecardCategory] = useAppSelector(
    (state) => {
      return [
        state.applicantTracking.scorecardCategories,
        state.applicantTracking.scorecardCategory,
      ];
    },
  );

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [rowData, setRowData] = useState<ScorecardCategory[]>([]);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<ScorecardCategory>();

  useEffect(()=>{
    dispatch(getScorecardCategories());
    dispatch(getScorecardTraits());
  }, []);
  
  useEffect(() => {
    if (!scorecardCategory) return;
    setSelectedCategory(scorecardCategory);
  }, [scorecardCategory]);

  useEffect(()=>{
    selectedCategory && setSelectedCategory(scorecardCategories.find(c => {
      return c.scorecardCategoryId === selectedCategory.scorecardCategoryId;
    }));
    scorecardCategories && setRowData(scorecardCategories);
  }, [scorecardCategories]);
  
  const onAdd = () => {
    setSelectedCategory(undefined);
    setShowDetailModal(true);
  };

  const openDetailModal = (params: ICellRendererParams) =>{
    setSelectedCategory(params.data);
    setShowDetailModal(true);
  };
  
  const onDeleteCategory = (params: ICellRendererParams) =>{
    dispatch(deleteScorecardCategory(params.data.scorecardCategoryId));
  };

  const columns = useMemo<ColDef[]>(
    () => {
      return [
        {
          field: 'category',
          headerName: 'Category Name',
          cellStyle: { textAlign: 'left' },
          cellRenderer: (params: ICellRendererParams) => {           
            return (
              <button
                type="button"
                className="btn btn-link"
                style={{ textAlign:'left' }}
                onClick={() => { return openDetailModal(params); }
                }
              >
                {params.data.category}
              </button>             
            );
          },
          width: 450,
        },       
        {
          field: 'traits',
          headerName: 'Traits',      
          cellRenderer: (params: ICellRendererParams) => {           
            const traits = params.data.traits?.map((t:ScorecardTrait)=>{return t.trait;});
            return (
              <div>
                {traits?.join('    ')}
              </div>);
          },         
          width: 760,         
        },  
        {
          field: 'delete',
          cellRenderer: (params: ICellRendererParams) => {                  
            return (
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={() => { return onDeleteCategory(params); }
                }
              >
                <Icon
                  name="minus-circle"
                  className="fa-minus-circle"
                />
              </button>             
            );            
          },
          width: 70,
        },     
      ];
    },
    [],
  );

  const gridOptions: GridOptions = {
    stopEditingWhenCellsLoseFocus:true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  return (
    <Row className="no-gutters p-0">
      <Col className="mt-1 shadow p-3 border">
        <PanelHeader
          title="Scorecard Categories"
          iconName="compass-drafting"          
        > 
          <button
            type="button"
            className="btn btn-link dm-grid-action-title pb-0 mr-2"
            onClick={onAdd}
          >
            Add New Category <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <div className="table-wrapper-wrapper ag-theme-balham mt-3">
          <AgGridReact
            gridOptions={gridOptions}
            rowData={rowData}
            columnDefs={columns}
            onGridReady={onGridReady}
          />
        </div>
        {showDetailModal && (
          <ScorecardCategoryModal
            item={selectedCategory}
            show={showDetailModal}
            onHide={() => {
              setShowDetailModal(false);
              dispatch(storeScorecardCategory(null));
            }}            
          />
        )}
      </Col>
    </Row>
  );
};

export default ScorecardCategoriesPage;
