import { createReducer } from '@reduxjs/toolkit';
import { EarningsRecords } from '../../models';
import { storeEarningsRecords } from '../actions';

export interface State {
  loading: boolean;
  earningsRecords: EarningsRecords;
}

const INITIAL_STATE = {
  loading: false,
  earningsRecords: {},
};

export const reducer = createReducer(INITIAL_STATE, {
  [storeEarningsRecords.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      earningsRecords: action.payload,
    };
  },

});
