import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { ChangeMyInfoAccountEmail, EmpMyInfoChanges, EmpSelfService, HttpResponse } from '../../models';
import { EmployeeSelfService } from '../../services';
import {
  interruptSelfService,
  changeMyInfoAccountEmail,
  deleteMyInfoAccount,
  handleError,
  handleSuccess,
  loadEmployeeSelfService,
  loadMyInfoChanges,
  loadMyInfoChangesHistory,
  resetMyInfoAccountPW,
  storeEmployeeSelfService,
  storeMyInfoAccountMessage,
  storeMyInfoChanges,
  storeMyInfoChangesHistory,
  unlockMyInfoAccount,
  updateEmployeeSelfService,
  updateMyInfoChanges,
  triggerEmFieldValidation,
  handlePending,
  loadIssues
} from '../actions';
import { loadMyInfoChangeLog } from '../actions/my-info-changes.action';
import { MyInfoChangeLog } from 'core/models/MyInfoChangeLog';

interface Actions {
  type: string;
  payload: any;
}

const loadEmployeeSelfService$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmployeeSelfService.type),
    switchMap((action: { payload: { protectedEmpNo: string; }; }) => {
      return from(
        EmployeeSelfService.getSelfService(
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: EmpSelfService) => { return storeEmployeeSelfService(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateEmployeeSelfService$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateEmployeeSelfService.type),
    switchMap((action: { payload: EmpSelfService; }) => {
      return from(EmployeeSelfService.putSelfService(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<any>) => {
          return [
            storeEmployeeSelfService(res.value),
            triggerEmFieldValidation({
              section: 'selfservice',
              actionType: updateEmployeeSelfService.type,
              callerPayload: res.value,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const unlockMyInfoAccount$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(unlockMyInfoAccount.type),
    switchMap((action: { payload: { protectedEmpNo: string; }; }) => {
      return from(
        EmployeeSelfService.postMyInfoUnlock(
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((_res: HttpResponse<any>) => {
          return storeMyInfoAccountMessage(
            'Employee has been unlocked. Please click OK to continue, then check email.',
          );
        }),
        catchError((err: HttpResponse<any>) => { return [storeMyInfoAccountMessage(err.messages[0])]; }),
      );
    },
    ),
  );
};

const resetMyInfoAccountPW$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(resetMyInfoAccountPW.type),
    switchMap((action: { payload: { protectedEmpNo: string; }; }) => {
      return from(
        EmployeeSelfService.postMyInfoResetPW(
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<any>) => {
          return [
            storeMyInfoAccountMessage(
              'Password reset email has been sent to the Employee.',
            ),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [storeMyInfoAccountMessage(err.messages[0])]; }),
      );
    },
    ),
  );
};

const changeMyInfoAccountEmailPW$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(changeMyInfoAccountEmail.type),
    switchMap((action: { payload: ChangeMyInfoAccountEmail; }) => {
      return from(
        EmployeeSelfService.postMyInfoChangeEmail(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<any>) => {
          return [
            storeMyInfoAccountMessage(
              'Confirmation email has been sent to the Employee. They will need to confirm their new email before this change takes effect.',
            ),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err.messages)]; }),
      );
    },
    ),
  );
};

const deleteMyInfoAccount$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteMyInfoAccount.type),
    switchMap((action: { payload: { protectedEmpNo: string; }; }) => {
      return from(
        EmployeeSelfService.postMyInfoDelete(
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => {
          return storeMyInfoAccountMessage(
            'User account on myinfo.dmpayroll.com has been removed.',
          );
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            storeMyInfoAccountMessage(err.messages[0]),
            handleError(err.messages),
          ];
        }),
      );
    },
    ),
  );
};

const loadMyInfoChanges$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadMyInfoChanges.type),
    switchMap((action: { payload: { protectedEmpNo: string; }; }) => {
      return from(EmployeeSelfService.getMyInfoChanges(action.payload.protectedEmpNo))
        .pipe(
          map((res: any) => { return res.data; }),
          map((res: MyInfoChangeLog) => { return storeMyInfoChanges(res); }),
          catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
        );
    },
    ),
  );
};

const storeMyInfoChanges$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(storeMyInfoChanges.type),
    mergeMap((action: { payload: MyInfoChangeLog }) => {
      return [
        triggerEmFieldValidation({
          section: 'selfservice',
          actionType: storeMyInfoChanges.type,
          callerPayload: action.payload,
        }),
      ];
    },
    ),
  );
};

const loadMyInfoChangesHistory$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadMyInfoChangesHistory.type),
    switchMap((action: { payload: { protectedEmpNo: string; }; }) => {
      return from(
        EmployeeSelfService.getMyInfoChangesHistory(
          action.payload.protectedEmpNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: EmpMyInfoChanges) => {
          return storeMyInfoChangesHistory(res);
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateMyInfochanges$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateMyInfoChanges.type),
    switchMap((action: { payload: { protectedEmpNo: any; }; }) => {
      return from(EmployeeSelfService.updateMyInfochanges(action.payload)).pipe(
        mergeMap((res) => {
          return [
            loadMyInfoChanges({
              protectedEmpNo: action.payload?.protectedEmpNo,
            }),
            loadMyInfoChangesHistory({
              protectedEmpNo: action.payload?.protectedEmpNo,
            }),
            loadMyInfoChangeLog(),
            handleSuccess(res.data.messages),
            handlePending(null),
            loadIssues(),
            triggerEmFieldValidation({
              section: 'selfservice',
              actionType: updateMyInfoChanges.type,
              callerPayload: res.data.value,
            }),
          ];
        }),
        startWith(handlePending('Updating MyInfo changes...')),
        catchError((err: HttpResponse<any>) => {
          return [
            interruptSelfService(),
            handlePending(null),
            handleError(err)];
        }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadEmployeeSelfService$,
  updateEmployeeSelfService$,
  unlockMyInfoAccount$,
  resetMyInfoAccountPW$,
  changeMyInfoAccountEmailPW$,
  deleteMyInfoAccount$,
  loadMyInfoChanges$,
  storeMyInfoChanges$,
  loadMyInfoChangesHistory$,
  updateMyInfochanges$,
];
