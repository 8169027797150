import React, { CSSProperties } from 'react';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import intuitButton from 'assets/images/intuit/C2QB_green_btn_med_default.png';
import { Spinner } from 'react-bootstrap';
import ConnectionOption from 'core/models/intuit/connectionOption.model';
import { QuckBooksConnectionOption } from 'core/enums/intuit/quickBooksConnectionOption';
import { manageQuickBooksConnection } from 'core/store/actions';

const intuitButtonWrapperStyle: CSSProperties = {
    padding: 0,
    border: 'none',
    background: 'none',
    width: '100%'
};

const intuitButtonStyle: CSSProperties = {
    width: '100%',
    borderRadius: 5,
    backgroundColor: 'indianred'
};

const loadingButtonStyle: CSSProperties = {
    width: '100%',
    borderRadius: 5,
    backgroundColor: 'gray'
};

type QuickBooksButtonProps = {
    loading: boolean,
    connected: boolean,
    setTryToRedirect: (value: boolean) => void
}

export const QuickBooksButton = (props: QuickBooksButtonProps) => {
    const dispatch = useAppDispatch();

    const intuitButtonClick = () => {
        let connectionOption = new ConnectionOption(props.connected ? QuckBooksConnectionOption.Disconnect : QuckBooksConnectionOption.Connect);
        props.setTryToRedirect(!props.connected); //Only try to redirect if we are connecting; 
        dispatch(manageQuickBooksConnection(connectionOption));
    };

    if (props.loading) return <button style={loadingButtonStyle}><Spinner animation='border' size='sm' /></button>
    return (
        <button style={intuitButtonWrapperStyle} onClick={() => intuitButtonClick()}>
            {props.connected ? <button style={intuitButtonStyle}>Disconnect From QuickBooks</button> : <img src={intuitButton} />}
        </button>
    );
}