import React, { CSSProperties } from 'react';
import Modal from './Modal';

type PropTypes = {
  title?: string;
  message?: string;
  btnText?: string;
  show: boolean;
  onHide: () => void;
};

const messageCSS: CSSProperties = {
  whiteSpace: 'pre-line'
};

const AlertModal: React.FC<PropTypes> = ({
  title,
  message,
  btnText,
  show,
  onHide,
}) => {
  return (
    <Modal show={show}
      onHide={onHide}
      title={title}>
      {(closeModal) => {
        return (
          <div className="d-flex flex-column">
            <div style={messageCSS}>{message}</div>
            <div className="row pt-2 justify-content-end">
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-primary orange-button"
                  onClick={closeModal}
                >
                  {btnText ? btnText : 'Close'}
                </button>
              </div>
            </div>
          </div>
        );
      }}
    </Modal>
  );
};

export default AlertModal;
