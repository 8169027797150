import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  CalculateHourlySalaryAnnualPayRates,
  DeleteEmployeePayRateRequest,
  EmployeePayRateScreen,
  FuturePayRate,
  HourlySalaryAnnualPayRates,
  HttpResponse,
  PayRate,
  PayRateHistory,
} from '../../models';
import { PayRateService } from '../../services';
import {
  addUpdateEmployeePayRatesScreen,
  calculatePayRates,
  createFuturePayRate,
  createPayRate,
  deleteFuturePayRate,
  deletePayRate,
  enableBlockingPayRates,
  errorPayRateScreen,
  handleError,
  handleSuccess,
  loadEmployeePayRatesScreen,
  loadFuturePayRates,
  loadPayRates,
  loadPayRatesHistory,
  storeCalculatedPayRates,
  storeEmployeePayRatesScreen,
  storeFuturePayRate,
  storeFuturePayRates,
  storePayRate,
  storePayRates,
  storePayRatesHistory,
  toggleBlockNavigation,
  triggerEmFieldValidation,
  updateFuturePayRate,
  updatePayRate,
  UpdatePayRateHistoryRequest,
  updatePayRatesHistory,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadPayRates$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadPayRates.type),
    switchMap((action: { payload: string; }) => {
      return from(PayRateService.getPayRates(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: PayRate[]) => { return storePayRates(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadEmployeePayRatesScreen$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadEmployeePayRatesScreen.type),
    switchMap((action: { payload: string; }) => {
      return from(PayRateService.getEmployeePayRatesScreen(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: EmployeePayRateScreen) => { return storeEmployeePayRatesScreen(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const addUpdateEmployeePayRatesScreen$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(addUpdateEmployeePayRatesScreen.type),
    switchMap((action: { payload: EmployeePayRateScreen; }) => {
      return from(PayRateService.addUpdatePayRatesScreen(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        switchMap((res: HttpResponse<EmployeePayRateScreen>) => {
          return [
            storeEmployeePayRatesScreen(res.value),
            toggleBlockNavigation({ block: false }), // if we were just adding an employee, unblock navigation on success
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'payrate',
              actionType: addUpdateEmployeePayRatesScreen.type,
              callerPayload: res.value.payRates,
              idField: 'rateId',
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            errorPayRateScreen(),
            handleError(err),
          ]; 
        }),
      );
    },
    ),
  );
};

const createPayRate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createPayRate.type),
    switchMap((action: { payload: { payRate: PayRate; skipRaiseDate: boolean; } }) => {
      return from(PayRateService.postPayRate(action.payload.payRate, action.payload.skipRaiseDate)).pipe(
        map((res: any) => { return res.data; }),
        switchMap((res: HttpResponse<PayRate>) => {
          return [
            storePayRate(res.value),
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'payrate',
              actionType: createPayRate.type,
              callerPayload: res.value,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updatePayRate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updatePayRate.type),
    switchMap((action: { payload: { payRate: PayRate; skipRaiseDate: boolean; } }) => {
      return from(PayRateService.putPayRate(action.payload.payRate, action.payload.skipRaiseDate)).pipe(
        map((res: any) => { return res.data; }),
        switchMap((res: HttpResponse<PayRate>) => {
          return [
            storePayRate(res.value),
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'payrate',
              actionType: updatePayRate.type,
              callerPayload: res.value,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deletePayRate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deletePayRate.type),
    switchMap((action: { payload: DeleteEmployeePayRateRequest; }) => {
      return from(PayRateService.deletePayRate(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<any>) => {
          return [
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'payrate',
              actionType: deletePayRate.type,
              callerPayload: action.payload,
              ids: [action.payload.rateId],
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadPayRatesHistory$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadPayRatesHistory.type),
    switchMap((action: { payload: string; }) => {
      return from(PayRateService.getPayRatesHistory(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: PayRateHistory[]) => { return storePayRatesHistory(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updatePayRatesHistory$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updatePayRatesHistory.type),
    switchMap((action: { payload: UpdatePayRateHistoryRequest; }) => {
      return from(
        PayRateService.putPayRatesHistory(
          action.payload.empNo,
          action.payload.payRateHistoryData,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<PayRateHistory[]>) => {
          return [
            storePayRatesHistory(res.value),
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'payrate',
              actionType: updatePayRatesHistory.type,
              callerPayload: res.value,
            }),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const calculatePayRates$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(calculatePayRates.type),
    switchMap((action: { payload: CalculateHourlySalaryAnnualPayRates; }) => {
      return from(
        PayRateService.calculatePayRates(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<HourlySalaryAnnualPayRates>) => { return storeCalculatedPayRates(res.value); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadFuturePayRates$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadFuturePayRates.type),
    switchMap((action: { payload: { empNo: string; rateId: number; }; }) => {
      return from(
        PayRateService.getFuturePayRates(
          action.payload.empNo,
          action.payload.rateId,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: FuturePayRate[]) => { return storeFuturePayRates(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createFuturePayRate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createFuturePayRate.type),
    switchMap((action: { payload: FuturePayRate; }) => {
      return from(PayRateService.postFuturePayRate(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        switchMap((res: HttpResponse<FuturePayRate>) => { 
          return [
            storeFuturePayRate(res.value),
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'payrate',
              actionType: createFuturePayRate.type,
              callerPayload: res.value,
            }),
          ]; 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateFuturePayRate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateFuturePayRate.type),
    switchMap((action: { payload: FuturePayRate; }) => {
      return from(PayRateService.putFuturePayRate(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        switchMap((res: HttpResponse<FuturePayRate>) => { 
          return [
            storeFuturePayRate(res.value),
            handleSuccess(res.messages),
            triggerEmFieldValidation({
              section: 'payrate',
              actionType: updateFuturePayRate.type,
              callerPayload: res.value,
            }),
          ]; 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteFuturePayRate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteFuturePayRate.type),
    switchMap((action: { payload: { protectedEmpNo: string; rateId: number; originalRateId: any; }; }) => {
      return from(
        PayRateService.deleteFuturePayRate(
          action.payload.protectedEmpNo,
          action.payload.rateId,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map(() => {
          return loadFuturePayRates({
            empNo: action.payload.protectedEmpNo,
            rateId: action.payload.originalRateId,
          });
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadPayRates$,
  createPayRate$,
  updatePayRate$,
  deletePayRate$,
  
  loadEmployeePayRatesScreen$,
  addUpdateEmployeePayRatesScreen$,

  loadPayRatesHistory$,
  updatePayRatesHistory$,

  loadFuturePayRates$,
  createFuturePayRate$,
  updateFuturePayRate$,
  deleteFuturePayRate$,
  calculatePayRates$,
];
