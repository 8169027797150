import { createReducer } from '@reduxjs/toolkit';
import {  clearClientFolder, storeCLientLibrary, storeClientFolder } from '../actions/client-library.action';
import {  ClientLibraryRequest } from 'core/models/ClientLibrary.model';



export interface State {
  loading: boolean;
  clientLibrary: ClientLibraryRequest[];
  clientFolder: any[];
}

const INITIAL_STATE: State = {
  loading: false,
  clientLibrary: [],
  clientFolder: [],
 
};

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(storeCLientLibrary, (state, action) => {
      state.loading = false;
      state.clientLibrary = action.payload;
    })
    .addCase(storeClientFolder, (state, action) => {
      state.loading = false;
      state.clientFolder = action.payload as any;
    })
    .addCase(clearClientFolder, (state) => {
      state.clientFolder = [];
    });
    
});