import { AxiosResponse } from "axios";
import { AttendanceOnDemand, HttpResponse, WorkgroupItem } from '../models';
import { API_CLIENT, API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getAOD = (protectedEmpNo: string): Promise<AxiosResponse<AttendanceOnDemand>> => {return API_EMPLOYEE_MASTER.get<AttendanceOnDemand>(EmployeeEndPoints.GET_AOD(CommonService.getProtectedClientNo(), protectedEmpNo));};

const putAOD = (data: AttendanceOnDemand): Promise<AxiosResponse<HttpResponse<AttendanceOnDemand>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<AttendanceOnDemand>>(EmployeeEndPoints.PUT_AOD(CommonService.getProtectedClientNo(), data.protectedEmpNo), data);};

const getClientAOD = (): Promise<AxiosResponse<any>> => {return API_CLIENT.get<any>(EmployeeEndPoints.GET_CLIENT_AOD(CommonService.getProtectedClientNo()));};

const postAODWorkgroup = (data: WorkgroupItem): Promise<AxiosResponse<HttpResponse<WorkgroupItem>>> => {return API_CLIENT.post<HttpResponse<WorkgroupItem>>(EmployeeEndPoints.POST_AOD_WORKGROUP(CommonService.getProtectedClientNo()), data);};

const putAODWorkgroup = (data: WorkgroupItem): Promise<AxiosResponse<HttpResponse<WorkgroupItem>>> => {return API_CLIENT.put<HttpResponse<WorkgroupItem>>(EmployeeEndPoints.PUT_AOD_WORKGROUP(CommonService.getProtectedClientNo()), data);};

export const AttendanceOnDemandService = {
  getAOD,
  putAOD,
  getClientAOD,
  postAODWorkgroup,
  putAODWorkgroup,
};
