import { RadioOptsWithCount } from 'core/components/shared/RadioTabs';
import { Employee } from 'core/models';
import { SortReducerState } from 'hooks/useTableSort';

export const employeeRadioOptions: RadioOptsWithCount[] = [
  {
    value: 'All',
    label: 'All',
  },
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Terminated',
    label: 'Terminated',
  },
];

// sort state
export const INITIAL_STATE: SortReducerState = {
  empNo: 'ASC',
  ssn: 'UNSORTED',
  firstName: 'UNSORTED',
  midName: 'UNSORTED',
  lastName: 'UNSORTED',
  loc: 'UNSORTED',
  dept: 'UNSORTED',
  subDept: 'UNSORTED',
  subDept2: 'UNSORTED',
  hireDate: 'UNSORTED',
  termDate: 'UNSORTED',
  isSupervisor: 'UNSORTED',
  onboardingStatus: 'UNSORTED',
};

export type TransmittalDefaultSort =
  'Loc Dept Alfa'
  | 'Department'
  | 'Alphabetically'
  | 'Last Name' // treated tje same as alpha
  | 'Employee No'
  | 'Sub-Department'
  | 'Sub-Department 2';

/**
 * Mapping of the transmittal sort CM options to their related Employee model properties.
 * Follows what's done in the backend for the transmittal.
 */
export const SortMapping: Record<TransmittalDefaultSort, Array<keyof Employee>> = {
  'Loc Dept Alfa': ['loc', 'dept', 'lastName', 'firstName', 'midName', 'empNo'],
  'Department': ['dept', 'lastName', 'firstName', 'midName', 'empNo'],
  'Alphabetically': ['lastName', 'firstName', 'midName', 'empNo' ],
  'Sub-Department': ['subDept', 'lastName', 'firstName', 'midName', 'empNo'],
  'Sub-Department 2': ['subDept2', 'lastName', 'firstName', 'midName', 'empNo'],
  'Last Name': ['lastName', 'firstName', 'midName', 'empNo'],
  'Employee No': ['empNo'],
};