import React from 'react';
import { ColGroupDef, GridOptions, ICellRendererParams, ValueGetterParams, ValueSetterParams } from '@ag-grid-enterprise/all-modules';
import { Location, Department, SubDepartment, SubDepartment2, EarningAllocation } from 'core/models';
import Icon from 'core/components/shared/Icon';
import { agSelectEditor } from 'utilities/ag-grid-editors';
import { agLookupRenderer } from 'utilities/ag-grid-renderers';
import '../../corrections.scss';
import { currencyFormatter, ddLookup } from 'utilities/utilities';

export const generateColumnGroupDefs = (
  locationOpts: Location[],
  deptOpts: Department[],
  subdeptOpts: SubDepartment[],
  subdept2Opts: SubDepartment2[],
  updateValue: (params: ValueSetterParams, field: string) => boolean,
  fixRecord: (params: ICellRendererParams) => void,
  removeRecord: (mescId: number, earnId: number) => void,
): ColGroupDef[] => {
  return [
    {
      headerName: 'Transmittal Entry was:',
      children: [
        { field: 'transmittalLoc',
          headerName: 'Loc.',
          hide: !locationOpts.length,
          valueGetter: (params: ValueGetterParams) => {
            const { data }: { data: EarningAllocation } = params;
            return ddLookup(+data.transmittalLoc, locationOpts, 'locationCode', 'locationDescWithCode');
          },
        },
        { field: 'transmittalDept',
          headerName: 'Dept.',
          hide: !deptOpts.length,
          valueGetter: (params: ValueGetterParams) => {
            const { data }: { data: EarningAllocation } = params;
            return ddLookup(+data.transmittalDept, deptOpts, 'deptCode', 'departmentDescWithCode');
          },
        },
        { field: 'transmittalSub',
          headerName: 'Sub.',
          hide: !subdeptOpts.length,
          valueGetter: (params: ValueGetterParams) => {
            const { data }: { data: EarningAllocation } = params;
            return ddLookup(+data.transmittalSub, subdeptOpts, 'subDeptCode', 'subDepartmentDescWithCode');
          },
        },
        { field: 'transmittalSub2',
          headerName: 'Sub. 2',
          hide: !subdept2Opts.length,
          valueGetter: (params: ValueGetterParams) => {
            const { data }: { data: EarningAllocation } = params;
            return ddLookup(+data.transmittalSub2, subdept2Opts, 'sub2Code', 'subDepartment2DescWithCode');
          },
        },
        { field: 'payType',
          headerName: 'Pay type' },
        { field: 'rate',
          headerName: 'Rate',
          valueGetter: (params: ValueGetterParams) => {
            const { data }: { data: EarningAllocation } = params;
            return data.rate.toFixed(2);
          },
        },
        { field: 'hours',
          headerName: 'Hours',
          valueGetter: (params: ValueGetterParams) => {
            const { data }: { data: EarningAllocation } = params;
            return data.hours.toFixed(2);
          },
        },
        { field: 'earnings',
          headerName: 'Earnings',
          valueGetter: (params: ValueGetterParams) => {
            const { data }: { data: EarningAllocation } = params;
            return currencyFormatter(data.earnings);
          },
        },
      ],
    },
    {
      headerName: 'Corrections',
      children: [
        { field: 'correctionLoc',
          headerName: 'Loc.',
          editable: true,
          cellRenderer: 'lookupRenderer',
          hide: !locationOpts.length,
          cellRendererParams: {
            options: [
              {
                locationCode: '',
                locationDescWithCode: '',
              }, ...locationOpts],
            valueField: 'locationCode',
            labelField: 'locationDescWithCode',
          },
          cellEditor: 'selectEditor',
          cellEditorParams: {
            options: [
              {
                locationCode: '',
                locationDescWithCode: '',
              }, ...locationOpts],
            valueField: 'locationCode',
            labelField: 'locationDescWithCode',
          },
          valueSetter: (params: ValueSetterParams) => { return updateValue(params, 'correctionLoc'); },
        },
        { field: 'correctionDept',
          headerName: 'Dept.',
          editable: true,
          cellRenderer: 'lookupRenderer',
          hide: !deptOpts.length,
          cellRendererParams: {
            options: [
              {
                deptCode: '',
                departmentDescWithCode: '',
              }, ...deptOpts],
            valueField: 'deptCode',
            labelField: 'departmentDescWithCode',
          },
          cellEditor: 'selectEditor',
          cellEditorParams: {
            options: [
              {
                deptCode: '',
                departmentDescWithCode: '',
              }, ...deptOpts],
            valueField: 'deptCode',
            labelField: 'departmentDescWithCode',
          },
          valueSetter: (params: ValueSetterParams) => { return updateValue(params, 'correctionDept'); },
        },
        { field: 'correctionSub',
          headerName: 'Sub.',
          editable: true,
          cellRenderer: 'lookupRenderer',
          hide: !subdeptOpts.length,
          cellRendererParams: {
            options: [
              {
                subDeptCode: '',
                subDepartmentDescWithCode: '',
              }, ...subdeptOpts],
            valueField: 'subDeptCode',
            labelField: 'subDepartmentDescWithCode',
          },
          cellEditorParams: {
            options: [
              {
                subDeptCode: '',
                subDepartmentDescWithCode: '',
              }, ...subdeptOpts],
            valueField: 'subDeptCode',
            labelField: 'subDepartmentDescWithCode',
          },
          cellEditor: 'selectEditor',
          valueSetter: (params: ValueSetterParams) => { return updateValue(params, 'correctionSub'); },
        },
        { field: 'correctionSub2',
          headerName: 'Sub. 2',
          editable: true,
          cellRenderer: 'lookupRenderer',
          hide: !subdept2Opts.length,
          cellRendererParams: {
            options: [
              {
                sub2Code: '',
                subDepartment2DescWithCode: '',
              }, ...subdept2Opts],
            valueField: 'sub2Code',
            labelField: 'subDepartment2DescWithCode',
          },
          cellEditor: 'selectEditor',
          cellEditorParams: {
            options: [
              {
                sub2Code: '',
                subDepartment2DescWithCode: '',
              }, ...subdept2Opts],
            valueField: 'sub2Code',
            labelField: 'subDepartment2DescWithCode',
          },
          valueSetter: (params: ValueSetterParams) => { return updateValue(params, 'correctionSub2'); },
        },
        {
          field: '',
          headerName: 'Fix',
          cellRenderer: (params: ICellRendererParams) => {
            return (
              <div className="ag-custom-button-cell">
                <button
                  className="custom-cell-icon fix"
                  onClick={() => { fixRecord(params); }}
                >
                  <Icon name="circle-check"/>
                </button>
                <span className="sr-only">Fix</span>
              </div>
            );
          },
        },
        {
          field: '',
          headerName: 'Remove Fix',
          cellRenderer: (params: ICellRendererParams) => {
            const { data: { mescId, earnId } }: { data: EarningAllocation } = params;
            
            return (
              <div className="ag-custom-button-cell">
                <button
                  className="custom-cell-icon remove-item"
                  onClick={() => { removeRecord(mescId, earnId); }}
                >
                  <Icon name="minus-circle"/>
                </button>
                <span className="sr-only">Remove fix</span>
              </div>
            );
          },
        },
      ],
    },
  ];
};

export const buildGridOptions = (): GridOptions => {
  return {
    domLayout: 'autoHeight',
    defaultColDef: {
      flex: 1,
      autoHeight: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    components: {
      lookupRenderer: agLookupRenderer,
      selectEditor: agSelectEditor,
    },
  };
};