import React from 'react';
import CheckBoxForm from '../../Shared/CheckBoxForm';
import { Col, Row } from 'react-bootstrap';
import SelectField from '../../Shared/SelectField';
import { EEDComponentProps } from 'types/eed.types';
import { cloneDeep } from 'lodash';
import { UserMapSelectedField } from 'core/models/Downloader.model';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { setSelectedFields } from 'core/store/slices/employee-earnings-download.slice';
import { IOption } from 'interfaces/IOption';

// Custom Layout for Checkbox and Grid select forms
const PayrollInformationFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  const appDispatch = useAppDispatch();
  const selectedFields = useAppSelector((state) => {
    return state.employeeEarningsDownload.selectedFields;
  });
  const timeAndAttendanceOptions = sectionFields?.find(f => {
    return f.name === 'Time And Attendance';
  })?.fields?.map(item => {
    return {
      value: item.name,
      label: item.name,
    };
  }) || [];

  const taxInfoFields = sectionFields.find(f => {
    return f.name === 'W2/Tax Info';
  })?.fields;

  return (
    <Row>
      <Col sm={6}>
        <Row>
          {!!sectionFields?.length && (
            <Col sm={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'Special Reporting';
                })?.fields}
                section="Special Reporting"
                title="Special Reporting"
              />
            </Col>
          )}
          {!!sectionFields?.length && (
            <Col sm={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'Time Keeping';
                })?.fields}
                section="Time Keeping"
                title="Time Keeping"
              />
            </Col>
          )}
          {!!sectionFields?.length && (
            <Col sm={12}>
              <SelectField
                title="Time And Attendance"
                section="Time And Attendance"
                options={timeAndAttendanceOptions}
                onChange={(selected) => {
                  const sFields = cloneDeep(selectedFields);

                  type SelectedItem = UserMapSelectedField & IOption;

                  appDispatch(setSelectedFields([
                    ...sFields,
                    // @ts-ignore
                    ...selected.map((item: SelectedItem) => {
                      return {
                        section: 'Time And Attendance',
                        selectedAreaId: item?.selectedAreaId || 0,
                        fieldName: item.label,
                        format: item?.format || null,
                        font: item?.font || null,
                        filter: {
                          filterId: item?.filter?.filterId || 0,
                          code: '' + item.value,
                          description: item.label,
                        },
                      };
                    }),
                  ] as UserMapSelectedField[]));
                }}
              />
            </Col>
          )}
          {!!sectionFields?.length && (
            <Col sm={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'Payroll Controls';
                })?.fields}
                section="Payroll Controls"
                title="Payroll Controls"
              />
            </Col>
          )}
        </Row>
      </Col>
      <Col sm={6}>
        <Row>
          {!!sectionFields?.length && (
            <Col sm={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'Misc Information';
                })?.fields}
                section="Misc Information"
                title="Misc Information"
              />
            </Col>
          )}
          {!!sectionFields?.length && (
            <Col sm={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={taxInfoFields?.filter(f => { return !f.name.includes('W2 Box'); })}
                section="W2/Tax Info"
                title="W2/Tax Info"
              >
                <p className="font-weight-bold mb-0 mt-2">W2 Box 13:</p>
                <CheckBoxForm
                  formMethods={formMethods}
                  dFields={taxInfoFields?.filter(f => { return f.name.includes('W2 Box'); })}
                  section="W2/Tax Info"
                />
              </CheckBoxForm>
            </Col>
          )}
          {!!sectionFields?.length && (
            <Col sm={12}>
              <CheckBoxForm
                formMethods={formMethods}
                dFields={sectionFields.find(f => {
                  return f.name === 'ForeignAddress';
                })?.fields}
                section="ForeignAddress"
                title="Foreign Address"
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default PayrollInformationFields;