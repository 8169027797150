import { DownloaderField } from 'core/models/Downloader.model';
import { setSelectedFields } from 'core/store/slices/employee-earnings-download.slice';
import React from 'react';
import { ArrayField } from 'react-hook-form';
import styles from '../../../../core/components/form-controls/form-controls.module.scss';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { createField } from 'utilities/utilities';

type Props = {
  formMethods: any;
  section: string;
  title?: string;
  dFields?: Partial<ArrayField<DownloaderField>>[];
  children?: React.ReactNode;
};

const RadioForm = ({
  formMethods,
  section,
  title,
  dFields,
  children,
}: Props) => {
  const appDispatch = useAppDispatch();
  const selectedFields = useAppSelector((state) => {
    return state.employeeEarningsDownload.selectedFields;
  });

  const selectedField = selectedFields.find(item => {
    return (item.section === section && item.fieldName !== "Gross to Net");
  })?.fieldName || 'Detail by Employee';

  //These are constant arrays used to tell which check boxes we need to exclude from state based on the radio button option. 
  const transactionalUnselectFields: string[] =[
    "Total Selected Deductions",
    "Total Individual Deductions",
    "Total Selected Hours",
    "Total Individual Hours",
    "Total Selected Earnings",
    "Total Individual Earnings",
    "Individual State Totals",
    "Individual State Additional Totals",
    "Individual City Totals",
  ];

  const summarizeUnselectFields: string[] =[
    "Check Number",
    "Check Type",
    "Net Pay",
    "Total Individual Deductions",
    "Total Individual Hours",
    "Total Individual Earnings",
    "Individual State Totals",
    "Individual State Additional Totals",
    "Individual City Totals",
  ];

  const earningFormRadioOptions: string[] =[
    "Summarize by Employee",
    "Detail by Employee",
    "Transactional by Employee"
  ]

  return (
    <div
      key={section}
      className={title ? 'dm-panel-blue dm-panel-border' : 'mt-2'}
    >
      <div className="d-flex flex-row">
        <div className="dm-grid-action-title-blue">{title}</div>
      </div>
      {title && (
        <hr className="dm-panel-hr" />
      )}
      <div>
        {dFields?.map((item, index) => {
          const id = item.name?.replaceAll(' ', '-').toLowerCase();

          return (
            <div
              key={index}
              className="d-flex mb-2"
            >
              <input
                type="radio"
                name="section"
                id={id}
                checked={selectedField === item.name}
                onChange={(e) => {
                  const checked = e.target.checked;

                  if (checked) {
                    appDispatch(
                      setSelectedFields(
                        [
                          ...selectedFields.filter(i => {
                            return (i.section !== section) 
                            && ((item.name === ("Transactional by Employee") && !transactionalUnselectFields.includes(i.fieldName))
                            || (item.name === ("Summarize by Employee") && !summarizeUnselectFields.includes(i.fieldName))
                            || (item.name === ("Detail by Employee")));
                          }),
                          createField(item.name || '', section),
                        ],
                      ),
                    );
                  }
                }}
              />
              <label
                htmlFor={id}
                className={styles['dm-form-check-label']}
              >
                {item.name}
              </label>
            </div>
          );
        })}
      </div>
      {!!children && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
};

export default RadioForm;