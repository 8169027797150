import { ColDef, RowNode } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { GridApi } from '@ag-grid-enterprise/all-modules';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { InputGrp } from '../../core/components/form-controls';
import {
  RadioGrp,
  RadioOptions,
} from '../../core/components/form-controls/RadioGrp';
import { Employee } from '../../core/models';
import { File } from '../../core/models/File.model';
import { CommonService } from '../../core/services';
import { putAssignFile } from '../../core/store/slices/file.slice';
import { useAppDispatch, useAppSelector } from '../../utilities/hooks';

const radioOptions: RadioOptions[] = [
  {
    value: 'All',
    label: 'All Employees',
  },
  {
    value: 'Active',
    label: 'Active Employees',
  },
  {
    value: 'Terminated',
    label: 'Terminated Employees',
  },
];

type Props = {
  onHide: () => void;
  fileRecordId: number;
  fileCategoryId: number;
  assignedTo: number[];
};

const AssignFile: React.FC<Props> = ({
  onHide,
  fileRecordId,
  fileCategoryId,
  assignedTo,
}) => {
  const dispatch = useAppDispatch();
  const employeeListStore = useAppSelector((state: { employees: { employees: any; }; }) => {return state.employees.employees;});
  const [employeeList, setEmployeeList] = useState<Employee[]>();
  const clientNo = useAppSelector((state) => {return state.client.client?.clientNo;});

  const gridRef = useRef<AgGridReact>(null);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [filterChecked, setFilteredChecked] = useState('Active');
  const [rowsSelected, setRowsSelected] = useState(0);

  const colDef = useMemo(
    () => {
      return [
        { field: 'empNo', width: 100 },
        { field: 'firstName' },
        { field: 'lastName' },
        { field: 'ssn', hide: true },
        {
          field: '',
          headerName: 'Select',
          checkboxSelection: true,
          width: 65,
        },
      ] as ColDef[];
    },
    [],
  );

  useEffect(() => {
    setEmployeeList(employeeListStore?.map((e: { empNo: number; }) => {
      return {
        ...e,
        selected: assignedTo?.includes(e.empNo),
      };
    }));
  }, [employeeListStore, assignedTo]);

  const filterTextBox = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (!gridRef.current) {
        return;
      }
      gridRef.current.api.setQuickFilter(e.currentTarget.value);
    },
    [],
  );

  const onFirstDataRendered = () => {
    gridApi?.forEachNode((node: RowNode) => {
      if (node.data.selected) {
        node.setSelected(true);
      }
    });
  };

  const onRowDataChanged = () => {
    gridRef.current?.api.sizeColumnsToFit();
  };

  const filterChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredChecked(e.target.value);
  };

  const isExternalFilterPresent = (): boolean => {
    return filterChecked !== 'All';
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.onFilterChanged();
    }
  }, [filterChecked]);

  const doesExternalFilterPass = useCallback(
    (node: RowNode) => {
      if (node.data) {
        switch (filterChecked) {
        case 'Active':
          return (node.data as Employee).termDate === null;
        case 'Terminated':
          return (node.data as Employee).termDate !== null;
        }
      }
      return true;
    },
    [filterChecked],
  );

  const assignFile = () => {
    const selectedRows =
            gridRef.current?.api.getSelectedRows() as Employee[];
    const submitData: File.AssignFile = {
      clientNo: clientNo!,
      protectedClientNo: CommonService.getProtectedClientNo(),
      fileRecordId,
      fileCategoryId,
      empNos: selectedRows?.map((a) => {return a.empNo;}),
    };
    dispatch(putAssignFile(submitData));
  };

  const onSelectionChanged = () => {
    setRowsSelected(gridRef.current?.api.getSelectedRows().length ?? 0);
  };

  return (
    <>
      {employeeList && (
        <>
          <Row>
            <Col>
              <InputGrp
                label="Employee Search"
                placeholder="Search by SSN, Emp# or Name"
                onChange={filterTextBox}
              />
            </Col>
            <Col>
              <RadioGrp
                radioOptions={radioOptions}
                onChange={filterChanged}
                name="filter"
                groupClass="d-flex flex-column"
                defaultChecked="Active"
              />
            </Col>
          </Row>
          <div
            className="ag-theme-balham mb-3"
            style={{ height: 'calc(75vh - 175px)' }}
          >
            <AgGridReact
              columnDefs={colDef}
              rowData={employeeList}
              ref={gridRef}
              rowSelection="multiple"
              rowMultiSelectWithClick={true}
              onFirstDataRendered={onFirstDataRendered}
              isExternalFilterPresent={isExternalFilterPresent}
              doesExternalFilterPass={doesExternalFilterPass}
              onRowDataChanged={onRowDataChanged}
              onSelectionChanged={onSelectionChanged}
              onGridReady={(params: any) => {return setGridApi(params.api);}}
            />
          </div>
          <Row className="justify-content-end">
            <Col xs="auto">
              <button
                type="button"
                className="btn orange-outline-button mr-2"
                onClick={() => {return onHide();}}
              >
                Clear
              </button>
              <button
                type="button"
                className="btn orange-button"
                onClick={assignFile}
              >
                Save
              </button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default AssignFile;
