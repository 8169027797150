import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { DMDropdownDataModel } from 'core/components/form-controls/DMDropDown';
import { DMDropdownMenu } from 'core/components/form-controls';
import { AutoFill } from './tools/Autofill';
import { Modal } from 'react-bootstrap';
import { AutoCalculations } from './tools/AutoCalculations';
import TransmittalPreferencesModal from '../transmittal-preferences/TransmittalPreferences.modal';
import { ManageDepartments } from './tools/ManageDepartments';
import { ManageSubDepartments } from './tools/ManageSubDepartments';
import { ManageSubDepartments2 } from './tools/ManageSubDepartments2';
import ReportModal from 'core/components/shared/ReportModal';
import { getPayrollReport } from 'core/store/reducers/payroll.reducer';
import { ReportInformation } from 'core/models/ReportInformation';
import { getCurrentPayroll, getIsFinishedPayroll, getOpenPayrolls } from 'core/store/selectors';
import { useSelector } from 'react-redux';
import Icon from 'core/components/shared/Icon';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import {
  clearPayrollReport,
  clearTransmittalCheckFromStore,
  loadPayrollTransmittalReport,
  loadPayrollUnpaidEmployeesReport,
} from 'core/store/actions';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import HamburgerMenu from 'core/components/shared/HamburgerMenu';

// TODO: find a home for this and other export style objects
export const multipleOpenDatelinesLinkStyleOverride: CSSProperties = {
  color: 'white',
};

const OPEN_CURRENT_TRANSMITTAL_TOOLS_PREFERENCES_NAV_ID = 'tools-nav-2';

const buildToolsDD = (isAdjustments: boolean, payrollHistoryId?: number, controlTotalId?: number): DMDropdownDataModel => {
  const path = isAdjustments ? 'employee-adjust' : 'open-current-transmittal';
  let queryParams = '';
  
  if (payrollHistoryId && controlTotalId) {
    queryParams = `?payrollHistoryId=${payrollHistoryId}`;
    if (!isAdjustments) queryParams += `&controlTotalId=${controlTotalId}`;
  }
  
  const href = (payrollHistoryId && controlTotalId) ? path + queryParams : '#';
  
  const tools: DMDropdownDataModel = {
    id: 'tools-dd',
    title: 'Tools',
    items: [
      {
        id: 'tools-add-change-delete',
        text: 'Add/Change/Delete',
        href: href,
        subItems: [
          {
            id: 'tools-add-change-delete-dept',
            text: 'Department',
            href: href,
          },
          {
            id: 'tools-add-change-delete-subdept',
            text: 'Sub Department',
            href: href,
          },
          {
            id: 'tools-add-change-delete-subdept2',
            text: 'Sub Department 2',
            href: href,
          },
        ],
      },
    ],
  };
  
  // looks weird, but will add these in the right order
  if (payrollHistoryId && controlTotalId) {
    tools.items.unshift({
      id: 'tools-auto-calculations',
      text: 'Edit Auto Calculations',
      href: href,
    });
  }
  tools.items.unshift({
    id: OPEN_CURRENT_TRANSMITTAL_TOOLS_PREFERENCES_NAV_ID,
    text: 'Preferences',
    href: href,
  });
  if (payrollHistoryId && controlTotalId) {
    tools.items.unshift(            {
      id: 'tools-auto-fill',
      text: 'Auto Fill',
      href: href,
    });
  }
  
  return tools;
};

const reportsDD: DMDropdownDataModel = {
  id: 'reports-dd',
  title: 'Reports',
  items: [
    {
      id: 'reports-transmittal',
      text: 'Transmittal',
      href: '#',
    },
    {
      id: 'reports-unpaid-employees',
      text: 'Unpaid Employees',
      href: '#',
    },
  ],
};

type OpenCurrentTransmittalToolbarProps = {
  toggleSidebar: () => void;
  showSidebarMenuIcon: boolean;
};

const OpenCurrentTransmittalToolbar: React.FC<OpenCurrentTransmittalToolbarProps> = ({ toggleSidebar, showSidebarMenuIcon }) => {
  const [showPayrollModal, setShowPayrollModal] = useState<boolean>(false);
  const [showToolsAutoFillModal, setShowToolsAutoFillModal] = useState<boolean>(false);
  const [isAutoCalculationsDirty, setIsAutoCalculationsDirty] = useState<boolean>(false);
  const [showAutoCalculationsModal, setShowAutoCalculationsModal] = useState<boolean>(false);
  const [showManageDeptModal, setShowManageDeptModal] = useState<boolean>(false);
  const [showManageSubDeptModal, setShowManageSubDeptModal] = useState<boolean>(false);
  const [showManageSubDept2Modal, setShowManageSubDept2Modal] = useState<boolean>(false);
  // TODO: get report information from store
  const [payrollHistoryId, setPayrollHistoryId] = useState<number>(0);
  const [controlTotalId, setControlTotalId] = useState<number>(0);
  const [showTransmittalPreferencesModal, setShowTransmittalPreferencesModal] = useState<boolean>(false);
  const [reportInformation, setReportInformation] = useState<ReportInformation>({
    title: '',
    action: null,
    show: false,
    selector: () => { return ''; },
    downloadName: '',
    clearDispatch: () => { },
  });

  const openPayrolls = useSelector(getOpenPayrolls);
  const openNewSystemPayrolls = openPayrolls?.filter((payroll) => payroll.useNewCheckRegister && !payroll.hasSignedWireOnlyAgreement) ?? [];
  const currentPayroll = useSelector(getCurrentPayroll);
  const controlTotalIds = useAppSelector(({ payroll }) => payroll.currentControlTotalIds);
  const isFinishedPayroll =  useSelector(getIsFinishedPayroll);
  const dispatch = useAppDispatch();
  
  const location = useLocation();
  const isAdjustments = location.pathname.includes('employee-adjust');
  
  const history = useHistory();
  
  const toolsDD = buildToolsDD(isAdjustments, payrollHistoryId, controlTotalId);

  useEffect(() => {
    if (controlTotalIds) {
      setPayrollHistoryId(controlTotalIds?.payrollHistoryId ?? 0);
      setControlTotalId(controlTotalIds?.controlTotalId ?? 0);
    } else if (currentPayroll) {
      setPayrollHistoryId(currentPayroll.payrollHistoryId);
      if (currentPayroll?.controlTotals?.[0]?.controlTotalId) {
        // we won't have this w/ client upload
        setControlTotalId(currentPayroll.controlTotals[0].controlTotalId);
      }
    }
  }, [currentPayroll, controlTotalIds]);
  
  const onMenuSelect = (menuId: string) => {
    switch (menuId) {
      case 'tools-auto-fill':
        setShowToolsAutoFillModal(true);
        break;
      case 'tools-auto-calculations':
        setShowAutoCalculationsModal(true);
        break;
      case OPEN_CURRENT_TRANSMITTAL_TOOLS_PREFERENCES_NAV_ID:
        setShowTransmittalPreferencesModal(true);
        break;
      case 'tools-add-change-delete-dept':
        setShowManageDeptModal(true);
        break;
      case 'tools-add-change-delete-subdept':
        setShowManageSubDeptModal(true);
        break;
      case 'tools-add-change-delete-subdept2':
        setShowManageSubDept2Modal(true);
        break;
      case 'reports-transmittal':
        setReportInformation({
          title: 'Transmittal Report',
          action: loadPayrollTransmittalReport({
            payrollHistoryId,
            controlTotalId,
            reportType: 'pdf',
            isFinishedPayroll: isFinishedPayroll,
          }),
          selector: getPayrollReport,
          downloadName: 'transmittal-report',
          clearDispatch: clearPayrollReport,
          show: true,
        });
        break;
      case 'reports-unpaid-employees':
        setReportInformation({
          title: 'Unpaid Employees Report',
          action: loadPayrollUnpaidEmployeesReport({
            payrollHistoryId,
            controlTotalId,
            reportType: 'pdf',
          }),
          selector: getPayrollReport,
          downloadName: 'unpaid-employees-report',
          clearDispatch: clearPayrollReport,
          show: true,
        });
        break;
    }
    dispatch(clearTransmittalCheckFromStore());
  };

  const handleAutoFillClose = () => {
    setShowToolsAutoFillModal(false);
  };

  const handleAutoCalculationClose = () => {
    if (!isAutoCalculationsDirty) {
      setShowAutoCalculationsModal(false);
      return;
    }
    if (!window.confirm('You have unsaved changes. Are you sure you want to close?')) return;
    
    setShowAutoCalculationsModal(false);
  };

  const handleAutoCalulationDirty = useCallback((isDirty: boolean) => {
    setIsAutoCalculationsDirty(isDirty);
  }, []);

  const handleManageDepartmentDirty = useCallback((isDirty: boolean) => {
    setIsAutoCalculationsDirty(isDirty);
  }, []);

  const handleManageDeptClose = () => {
    if (isAutoCalculationsDirty) {
      if (
        window.confirm(
          'You have unsaved changes. Are you sure you want to close?',
        )
      ) {
        setShowManageDeptModal(false);
      }
    } else {
      setShowManageDeptModal(false);
    }
  };

  const handleManageSubDeptClose = () => {
    if (isAutoCalculationsDirty) {
      if (
        window.confirm(
          'You have unsaved changes. Are you sure you want to close?',
        )
      ) {
        setShowManageSubDeptModal(false);
      }
    } else {
      setShowManageSubDeptModal(false);
    }
  };

  const handleManageSubDept2Close = () => {
    if (isAutoCalculationsDirty) {
      if (
        window.confirm(
          'You have unsaved changes. Are you sure you want to close?',
        )
      ) {
        setShowManageSubDept2Modal(false);
      }
    } else {
      setShowManageSubDept2Modal(false);
    }
  };

  const handleReportModalClose = () => {
    setReportInformation({
      title: '',
      action: null,
      show: false,
      selector: () => { return ''; },
      downloadName: '',
      clearDispatch: () => { },
    });
  };

  return (
    <div className="dm-toolbar">
      {showPayrollModal && (<ConfirmationModal
        title="DM Payroll"
        message={'You do not have an open Payroll.\bDo you want to insert a new payroll?'}
        show={showPayrollModal}
        backdrop={'static'}
        onConfirmed={(confirm: boolean) => {
          //If they deny adding a new dateline back to the process payroll screen
          if (confirm) history.push('/process-payroll'); 
          setShowPayrollModal(false);
        }}
        onHide={() => { 
          setShowPayrollModal(false);
        }}
      />
      )}
      {showSidebarMenuIcon ? (
        <HamburgerMenu
          toggleSidebar={toggleSidebar}  
        />
      ) : (
        <>
          <NavLink to="/process-payroll">Process Payroll</NavLink>
          {payrollHistoryId && controlTotalId ? (
            <NavLink
              style={((openNewSystemPayrolls?.length ?? 0) > 1 || !openNewSystemPayrolls?.length) && !location.pathname.includes('open-current-transmittal') ? multipleOpenDatelinesLinkStyleOverride : undefined}
              to={((openNewSystemPayrolls?.length ?? 0) > 1 || !openNewSystemPayrolls?.length) ? '#' : '/open-current-transmittal'}
              onClick={() => {
                // PI-8420: Feels hacky, but will just "return" them to process payroll if they have more than one open dateline. Same is done in ProcessPayrollToolbar.tsx
                if ((openNewSystemPayrolls?.length ?? 0) > 1) {
                  alert('You have more than one open transmittal date line. Please choose the correct date line to process.');
                  history.push('/process-payroll');
                } else if (!openNewSystemPayrolls?.length) {
                  setShowPayrollModal(true);
                }
              }}
            >
              Open Current Transmittal
            </NavLink>
          ) : null}
          <NavLink to="/sales-tax-transmittal">
            Sales Tax Transmittal
          </NavLink>
          <NavLink to="upload-payroll">Payroll Upload</NavLink>
        </>
      )}
      {payrollHistoryId && controlTotalId ? (
        <div>
          <DMDropdownMenu
            data={reportsDD}
            toggleClass="dm-toolbar-nav"
            whiteChevron={true}
            onSelect={onMenuSelect}
          />
        </div>
      ) : null}
      <div>
        <DMDropdownMenu
          data={toolsDD}
          toggleClass="dm-toolbar-nav"
          whiteChevron={true}
          onSelect={onMenuSelect}
        />
      </div>

      {/* Modal for Autofill */}
      {showToolsAutoFillModal && controlTotalId && (
        <Modal
          show={showToolsAutoFillModal}
          backdrop="static"
          onHide={() => { return setShowToolsAutoFillModal(false); }}
        >
          <Modal.Header>
            <Modal.Title className="dm-card-title">
              Auto Fill
            </Modal.Title>
            <button
              type="button"
              className="modal-close-btn"
              onClick={handleAutoFillClose}
            >
              <Icon name="times" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <AutoFill
              params={{
                payrollHistoryId,
                controlTotalId,
              }}
              onHide={() => { return setShowToolsAutoFillModal(false); }}
            />
          </Modal.Body>
        </Modal>
      )}

      {/* Modal for Auto Calculations */}
      {showAutoCalculationsModal && (
        <AutoCalculations
          show={showAutoCalculationsModal}
          onHide={handleAutoCalculationClose}
          payrollHistoryId={payrollHistoryId}
          controlTotalId={controlTotalId}
          dirtyCallback={handleAutoCalulationDirty}
        />
      )}

      {/* Modal for Managing Departments */}
      <Modal
        show={showManageDeptModal}
        backdrop="static"
        size="lg"
        onHide={() => {
          handleManageDeptClose();
        }}
      >
        <Modal.Header className="border-0">
          <button
            type="button"
            onClick={handleManageDeptClose}
            className="modal-close-btn"
          >
            <Icon name="times" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <ManageDepartments
            onHide={handleManageDeptClose}
            dirtyCallback={handleManageDepartmentDirty}
          />
        </Modal.Body>
      </Modal>

      {/* Modal for Managing Sub Departments */}
      <Modal
        show={showManageSubDeptModal}
        backdrop="static"
        size="lg"
        onHide={() => {
          handleManageSubDeptClose();
        }}
      >
        <Modal.Header className="border-0">
          <button
            type="button"
            onClick={handleManageSubDeptClose}
            className="modal-close-btn"
          >
            <Icon name="times" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <ManageSubDepartments
            onHide={handleManageSubDeptClose}
            dirtyCallback={handleManageDepartmentDirty}
          />
        </Modal.Body>
      </Modal>

      {/* Modal for Managing Sub Departments */}
      <Modal
        show={showManageSubDept2Modal}
        backdrop="static"
        size="lg"
        onHide={() => {
          handleManageSubDeptClose();
        }}
      >
        <Modal.Header className="border-0">
          <button
            type="button"
            onClick={handleManageSubDept2Close}
            className="modal-close-btn"
          >
            <Icon name="times" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <ManageSubDepartments2
            onHide={handleManageSubDept2Close}
            dirtyCallback={handleManageDepartmentDirty}
          />
        </Modal.Body>
      </Modal>

      {/* Modal for Reports */}
      {reportInformation.show && (
        <ReportModal
          title={reportInformation.title}
          action={reportInformation.action}
          show={reportInformation.show}
          selector={reportInformation.selector}
          downloadName={reportInformation.downloadName}
          clearDispatch={reportInformation.clearDispatch}
          onHide={() => { return handleReportModalClose(); }}
        />
      )}

      {/* Modal for Trnsmitttal Preferences */}
      {showTransmittalPreferencesModal && (
        <TransmittalPreferencesModal
          show={showTransmittalPreferencesModal}
          onHide={() => { return setShowTransmittalPreferencesModal(false); }}
        />
      )}
    </div>
  );
};

export default OpenCurrentTransmittalToolbar;
