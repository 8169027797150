import axios, { AxiosResponse } from 'axios';
import { User } from '../models/User.model';
import { UserAccess } from '../models/UserAccess';
import { API_CLIENT, API_EMPLOYEE_MASTER } from './API';
import { AuthEndPoints, ClientMasterEndPoints } from './URL';
import { ModuleAccess } from 'core/models/ModuleAccess.model';
import { CommonService } from './common.service';

const postToken = (userName: string): Promise<AxiosResponse<any>> => { return API_CLIENT.post<any>(AuthEndPoints.POST_TOKEN, { userName }); };

const refreshToken = (userName: string): Promise<AxiosResponse<any>> => { return axios.post<any>(AuthEndPoints.REFRESH_TOKEN, { userName }); };

const getUser = (userName: string): Promise<AxiosResponse<User>> => { return API_CLIENT.get<User>(AuthEndPoints.GET_USER(userName)); };

const getUserAccess = (): Promise<AxiosResponse<UserAccess>> => { return API_CLIENT.get<UserAccess>(AuthEndPoints.GET_USER_ACESSS()); };

const getModuleAccess = (): Promise<AxiosResponse<ModuleAccess>> => {
  return API_EMPLOYEE_MASTER.get<ModuleAccess>(
    ClientMasterEndPoints.GET_MODULE_ACCESS(
      CommonService.getProtectedClientNo(),
    ),
  );
};

export const AuthService = {
  postToken,
  refreshToken,
  getUser,
  getUserAccess,
  getModuleAccess,
};
