export class SubDepartment {
  clientNo = 0;

  subDeptID = 0;

  clientID = 0;

  subDeptCode = 0;

  subDeptDesc = '';

  subDepartmentDescWithCode = '';

  locationID?: number = 0;

  deptID = 0;

  glAccount = '';

  alphaCode = '';

  quickenClass = '';

  addressId?: number = 0;

  constructor(clientNo: number, clientID: number, subDeptCode: number = 0) {
    this.clientNo = clientNo;
    this.clientID = clientID;
    this.subDeptCode = subDeptCode;
  }
}
