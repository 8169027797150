import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi, GridOptions } from '@ag-grid-enterprise/all-modules';
import { InputGrp } from 'core/components/form-controls';
import Modal from 'core/components/modals/Modal';
import { CopyMapRequest, UserMapItem } from 'core/models/Downloader.model';
import { ClientUser } from 'core/models/User.model';
import { putCopyMap } from 'core/store/slices/downloader.slice';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'utilities/hooks';

type PropTypes = {
  userMapItem: UserMapItem;
  show: boolean;
  onHide: () => void;
};

const CopyMapUsersModal: React.FC<PropTypes> = ({ userMapItem, show, onHide }) => {

  const dispatch = useDispatch();
  const client = useAppSelector((state) => { return state.client.client; });
  const users = useAppSelector((state) => { return state.cmUsers.users; });
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<ClientUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<ClientUser[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    users && setFilteredUsers(users);
  }, [users]);

  useEffect(() => {
    searchValue ?
      setFilteredUsers(
        filteredUsers?.filter((user: ClientUser) => {
          return (
            user.userName
              .toLowerCase()
              .startsWith(searchValue?.toLowerCase())
          );
        }),
      ) : setFilteredUsers(users);
  }, [searchValue]);

  const filterUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onSubmit = () => {
    const request: CopyMapRequest = {
      userMapId: userMapItem.userMapId,
      clientNo: client?.clientNo ?? 0,
      userNames: selectedUsers?.map(u => { return '' + u.userName; }) ?? [],
    };
    request && dispatch(putCopyMap(request));
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      selectedRows && setSelectedUsers(selectedRows);
    }
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const columns: ColDef[] =
    [
      {
        field: 'select',
        checkboxSelection: true,
        width: 65,
      },
      {
        field: 'userName',
        headerName: 'User Name',
      },
    ];

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    defaultColDef: {
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
      singleClickEdit: true,
      resizable: true,
    },
    rowSelection: 'multiple',

  };

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        title={`Copy map to User - ${userMapItem.description}`}
      >
        {(closeModal) => {
          return (
            <>
              <div className="d-flex flex-row">
                <div className="dm-card-subtitle2 mr-2">Find User</div>
                <div className="flex-grow-1 text-right">
                  <InputGrp onChange={(e: any) => { return filterUsers(e); }} />
                </div>
              </div>
              <hr className="dm-panel-hr" />
              <div className="ag-theme-balham mb-4">
                {filteredUsers.length ? <AgGridReact
                  rowData={filteredUsers}
                  gridOptions={gridOptions}
                  columnDefs={columns}
                  onGridReady={onGridReady}
                  onSelectionChanged={onSelectionChanged}
                  rowSelection={'multiple'}
                /> : null}
              </div>
              <div className="d-flex justify-content-end mt-2">
                <div className="d-flex flex-row">
                  <button
                    type="button"
                    className="btn btn-primary orange-outline-button mr-2"
                    onClick={() => { closeModal(); }}>Cancel</button>
                  <button
                    disabled={selectedUsers?.length === 0}
                    type="button"
                    onClick={() => { onSubmit(); }}
                    className="btn orange-button-sm mr-3"
                  >
                    Save
                  </button>
                </div>
              </div>
            </>
          );
        }}
      </Modal>
    </div>
  );
};

export default CopyMapUsersModal;