import React from 'react';
import { useDispatch } from 'react-redux';
import { Payroll } from 'core/models';
import { Modal } from 'react-bootstrap';
import { convToDateString } from 'utilities/utilities';

const formatTo4Decimals = (value: number) => { return parseFloat('' + value).toFixed(4); };

type PropTypes = {
  payrollDatelines: Payroll[];
  currentPayrollHistoryId: number;
  show: boolean;
  onHide: (value: boolean) => void;
  update?: (payroll: Payroll) => void;
};

const PayrollSingleSelection: React.FC<PropTypes> = ({ payrollDatelines, currentPayrollHistoryId, show, onHide, update }) => {
  const dispatch = useDispatch();
  
  return (
    <div>
      <Modal
        show={show}
        onHide={() => { return onHide(false); }}
        size="sm"
      >
        <div className="modal-header">
          <div className="dm-card-title">
            Select Payroll To Move Request To
          </div>
        </div>
        <Modal.Body>
          <table className="table table-bordered table-hover w-60">
            <colgroup>
              <col style={{ width: '10%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '10%' }} />
            </colgroup>
            <thead className="thead-light">
              <tr>
                <th>Week End</th>
                <th>Check Date</th>
                <th>Next Week End</th>
                <th>Next Check Date</th>
                <th>PR Freq.</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {payrollDatelines.map((ar: Payroll) => {
                return (
                  <tr key={ar.payrollHistoryId}>
                    <td className="text-right">
                      {convToDateString(ar.weekEnd)}
                    </td>
                    <td className="text-right">
                      {convToDateString(ar.checkDate)}
                    </td>
                    <td className="text-right">
                      {convToDateString(ar.nextWeekEnd)}
                    </td>
                    <td className="text-right">
                      {convToDateString(ar.nextCheckDate)}
                    </td>
                    <td className="text-right">
                      {ar.prFrequency}
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-link p-0 m-0"
                        disabled={currentPayrollHistoryId === ar.payrollHistoryId}
                        onClick={() => { if (update) update(ar); onHide(false); }}
                      >
                        Select
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </div>
  );
};
  
export default PayrollSingleSelection;
  