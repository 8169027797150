import React, { CSSProperties, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  InputGrp,
  RadioGrp,
  SelectGrp,
  SelectModalGrp,
} from 'core/components/form-controls';
import { FieldInputSettings  } from 'core/components/form-controls/types';
import WorkCompRulesModal from 'core/components/modals/work-comp-rules/WorkCompRules.modal';
import PanelHeader from 'core/components/shared/PanelHeader';
import { loadLookupEEOUnits } from 'core/store/actions';
import {
  getEEOCodes,
  getEEOOccupGroups,
  getFederalBlsSocCodes,
  getLookupEEOUnit2,
  getWorkerComps,
} from 'core/store/selectors';
import { useRadioButtonChange } from 'utilities/hooks';
import EEOModal from '../../eeo-unit/EEOModal';
import { getAccess } from 'utilities/utilities';
import { BaseSectionProps } from './PayrollInfoPage';
import { ControlIds } from 'core/constants';
import { SelectWithGridView } from 'core/components/form-controls/select-modal/SelectWithGridView';
import ModalInputButton from 'features/payroll/timecards/ModalInputButton';
import FederalBLSSOCCodeModal from './modals/FederalBLSSOCCode.modal';
import { inputStyle } from 'features/payroll/timecards/InputSection';
import { Dropdown, WCEffectiveDate, WCRate } from 'core/models';

const PayrollInfoSpecialReporting: React.FC<BaseSectionProps> = ({
  methods: { register, setValue, errors, control, watch },
  sectionAccess,
}) => {
  const dispatch = useDispatch();

  const wcEffectiveDates = useSelector(getWorkerComps);

  //PI-8770 Updated to use the Worker Comps state so we can add any newly added/updated values without having to re-load all dropdowns. 
  const workerCompCodeOpts = () => {
    const returnList: Dropdown[] = [];

    //PI-8858 Added a code that is the WcCode - Description to lower case as if the 
    wcEffectiveDates.forEach((wc) =>{
      wc.rates.forEach((rate) => {
        returnList.push(new Dropdown(wc.clientNo, rate.wcCode, { description: rate.description }));
      });
    });

    const filterList = returnList.filter((item, index) => {
      return returnList.map(x => x.id).indexOf(item.id) === index;
    }).sort((a, b) => (a?.description ?? '').localeCompare((b?.description ?? '')));

    return filterList;
  };
  
  const eeoCodeOpts = useSelector(getEEOCodes);
  const eeoOccupGroupOpts = useSelector(getEEOOccupGroups);
  const lookupEEOUnitOpts = useSelector(getLookupEEOUnit2);
  const federalBlsSocCodeOpts = useSelector(getFederalBlsSocCodes);

  const [socCodeDesc, setSocCodeDesc] = useState<string>('');
  const [fedBlsSocCodeId, setFedBlsSocCodeId] = useState<number>(0);
  const [showFedBlcSocCodeModal, toggleShowFedBlcSocCodeModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(loadLookupEEOUnits());
  }, []);

  const { workersCompCode, eeoUnitNo, pensionEligible, federalBlsSocCodeId } = watch([
    'workersCompCode',
    'eeoUnitNo',
    'pensionEligible',
    'federalBlsSocCodeId',
  ]);

  // TODO: the change to this groupClass probably affected other elements, so check all of them.
  const groupClass20Override: CSSProperties = { width: 'auto' }; 

  const [fs, setFs] = useState<FieldInputSettings>({
    workersCompCode: {
      name: 'workersCompCode',
      label: 'WORK COMP CODE',
      groupClass: 'groupClass20',
      modalTitle: 'WORKER COMPENSATION CODES',
      customFormComponent: WorkCompRulesModal,
      addOptionText: 'Workers Comp',
      noOption: true,
    },
    eeo: {
      name: 'eeo',
      label: 'EEO RACE',
      groupClass: 'groupClass20',
      noOption: true,
      noOptionValue: ' ',
      noOptionText: 'eeo selected',
    },
    eeoOccupGroup: {
      name: 'eeoOccupGroup',
      label: 'EEO OCCUP. GRP.',
      groupClass: 'groupClass25',
    },
    eeoUnitNo: {
      name: 'eeoUnitNo',
      label: 'EEO UNIT NUMBER',
      groupClass: 'groupClass20',
      valueField: 'eeoUnitID',
      labelField: 'number',
      addOptionText: 'EEO Unit',
      customFormComponent: EEOModal,
    },
    pensionEligible: {
      name: 'pensionEligible',
      label: 'PENSION ELIGIBLE',
      groupClass: 'groupClass15',
    },
    federalBlsSocCodeId: {
      name: 'federalBlsSocCodeId',
      label: 'FED BLS SOC CODE',
      groupClass: 'groupClass25',
    },
  });

  useEffect(() => {
    const currentState = { ...fs };
    currentState.pensionEligible.defaultChecked = pensionEligible;
    setFs(currentState);
  }, [workersCompCode]);

  useEffect(() => {
    const optionDescription = federalBlsSocCodeOpts.find(x => x.id == federalBlsSocCodeId)?.description ?? '';
    setSocCodeDesc(optionDescription);
  }, [federalBlsSocCodeId]);

  const setFederalBlsSocCodeId = (newValue: string | number) => {
    setValue('federalBlsSocCodeId', newValue, { shouldDirty: true });
  };

  const [handleRadioButtonChange] = useRadioButtonChange(fs);

  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Special Reporting" />
      <div className="d-flex flex-wrap">
        <SelectModalGrp
          {...fs.workersCompCode}
          groupStyle={groupClass20Override}
          {...getAccess(sectionAccess, 6, ControlIds.workersCompCode)}
          errors={errors.workersCompCode}
          control={control}
          value={workersCompCode}
          showId={true}
          options={workerCompCodeOpts()}
          setValue={setValue}
        />
        <SelectGrp
          {...fs.eeo}
          {...getAccess(sectionAccess, 6, ControlIds.eeo)}
          errors={errors.eeo}
          ref={register}
          options={eeoCodeOpts}
        />
        <SelectGrp
          {...fs.eeoOccupGroup}
          groupStyle={groupClass20Override}
          {...getAccess(sectionAccess, 6, ControlIds.eeoOccupGroup)}
          errors={errors.eeoOccupGroup}
          ref={register}
          options={eeoOccupGroupOpts}
        />
        <SelectModalGrp
          {...fs.eeoUnitNo}
          groupStyle={groupClass20Override}
          {...getAccess(sectionAccess, 6, ControlIds.eeoUnitNo)}
          errors={errors.eeoUnitNo}
          control={control}
          value={eeoUnitNo}
          options={lookupEEOUnitOpts}
          setValue={setValue}
        />
        <RadioGrp
          {...fs.pensionEligible}
          {...getAccess(sectionAccess, 6, ControlIds.pensionEligible, { disabledSameAsReadOnly: true })}
          errors={errors.pensionEligible}
          ref={register}
          onChange={handleRadioButtonChange}
        />
        <input
          type="hidden"
          name="federalBlsSocCodeId"
          defaultValue={federalBlsSocCodeId}
          ref={register()}
        />
        <InputGrp
          {...fs.federalBlsSocCodeId}
          name="federalBlsSocCodeDescription"
          value={socCodeDesc}
          ref={register}
          readOnly
          inputStyle={inputStyle}
        />
        <ModalInputButton
          modalName="Fed BLS SOC Code"
          onClick={() => { toggleShowFedBlcSocCodeModal(true); }}
        />
      </div>
      {showFedBlcSocCodeModal && (
        <FederalBLSSOCCodeModal
          show={showFedBlcSocCodeModal}
          onHide={() => { toggleShowFedBlcSocCodeModal(false); }}
          setControlledField={setFederalBlsSocCodeId}
        />
      )}
    </div>
  );
};

export default PayrollInfoSpecialReporting;
