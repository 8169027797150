/* eslint-disable react/prop-types */
import React, { useState, ReactNode, FC, createContext, useContext } from 'react';
import { Collapse } from 'react-bootstrap';
import Icon from 'core/components/shared/Icon';

type AdjustmentSectionContextType = {
  type: string;
  isOpen: boolean;
  toggleOpen: (newVal: boolean) => void;
};

const AdjustmentSectionContext = createContext<AdjustmentSectionContextType>({
  type: '',
  isOpen: false,
  toggleOpen: (_newVal: boolean) => { },
});

type Slots = {
  Summary: FC<{ children?: ReactNode }>; // children are optional here because it could just be the open button and name.
  Details: FC<{ children: ReactNode }>;
};

type Props = {
  children: ReactNode;
  check?: any;
  tabKey: number;
  type: string;
  isOpen?: boolean;
  isDirty: boolean;
};

const AdjustmentSection: FC<Props> & Slots = ({
  children,
  check,
  isOpen,
  tabKey,
  type,
}) => {
  const [openState, setOpenState] = useState(!!isOpen);
  const [active, setActive] = useState(false);

  const id = `${type.toLowerCase()}${tabKey}`;

  /* TODO: Expand these functions to limit renders. */
  const handleEnter = () => {
    setActive(true);
  };

  const handleLeave = () => {
    setActive(false);
  };
  
  const toggleOpen = (newVal: boolean) => {
    setOpenState(newVal);
  };
  
  const contextVal: AdjustmentSectionContextType = {
    type,
    isOpen: openState,
    toggleOpen,
  };

  return (
    <div
      className={`dm-panel dm-panel-border mt-1 ${id}`}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      <AdjustmentSectionContext.Provider value={contextVal}>
        {children}
      </AdjustmentSectionContext.Provider>
    </div>
  );
};

export default AdjustmentSection;

AdjustmentSection.displayName = 'AdjustmentSection';

AdjustmentSection.Summary = function Summary({ children }) {
  const { type, isOpen, toggleOpen } = useContext(AdjustmentSectionContext);
  
  return (
    <div className="d-flex justify-content-between">
      <div>
        <div
          role="button"
          className="dm-card-subtitle4"
          onClick={() => { toggleOpen(!isOpen); }}
        >
          <Icon
            name={isOpen ? 'chevron-down' : 'chevron-right'}
            className="mr-2"
          />
          {type}
        </div>
      </div>
      {children}
    </div>
  );
};

AdjustmentSection.Details = function Details({ children }) {
  const { isOpen } = useContext(AdjustmentSectionContext);
  
  return (
    <Collapse in={isOpen}>
      <div> {/* collapse requires topmost child to be a div for some reason I haven't figured out yet */}
        {children}
      </div>
    </Collapse>
  );
};
