import React from 'react';
import ReactDOM from 'react-dom';
// Redux Store
import { Provider } from 'react-redux';
import store from './core/store/store';
// global styling
import './assets/scss/global.scss';

import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

import App from './features/app/App';
import * as serviceWorker from './serviceWorker';
import { BreakpointProvider } from './utilities/BreakpointProvider';
import { AuthProvider } from './core/providers/authProvider';
import * as agGrid from '@ag-grid-enterprise/all-modules';

const queries = {
  xs: '(max-width: 480px)',
  sm: '(max-width: 768px)',
  md: '(max-width: 1100px)', // Sidebar collapse
  md2: '(max-width: 1530px)', // Show main nav on top or under logo.
  lg: '(max-width: 1700px)',
};

agGrid.LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY || '');

ReactDOM.render(
  <BreakpointProvider queries={queries}>
    <Provider store={store}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Provider>
  </BreakpointProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls..
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
