import { createReducer } from '@reduxjs/toolkit';
import { YTD } from '../../models';
import { loadYearToDate, storeYearToDate } from '../actions';

export interface State {
  loading: boolean;
  ytd: YTD | null;
}

export const INITIAL_STATE: State = {
  loading: false,
  ytd: null,
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadYearToDate.type]: (state) => {return { ...state, loading: true };},
  [storeYearToDate.type]: (state, action) => {
    return {
      ...state,
      loading: false,
      ytd: action.payload,
    };
  },
});