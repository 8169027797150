import { ISelectMultipleOption } from 'interfaces/IOption';
import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import Select, { MultiValue } from 'react-select';
import { multiSelectStyles } from 'utilities/multiSelectStyles';
import { StylesConfigType } from 'utilities/styles';

type Props = {
  title?: string;
  options: ISelectMultipleOption[];
  onChange?: (selected: ISelectMultipleOption[]) => void | null;
  children?: React.ReactNode;
  selectAllDefault?: boolean;
};

const CustomOption = (props: any, selected: ISelectMultipleOption[]) => {
  const { innerProps, innerRef } = props;

  const isSelected: boolean = useMemo(() => {
    return !!selected.find((item: ISelectMultipleOption) => {
      return item.value === props?.data?.value;
    });
  }, [selected]);

  return (
    <div
      className="px-2 py-2 d-flex align-items-center"
      style={{gap: '0.5rem'}}
      ref={innerRef}
      {...innerProps}
    >
      <input
        type="checkbox"
        checked={isSelected}
      />
      <label
        className="h6 mb-0"
      >
        {props?.data?.label}
      </label>
    </div>
  );
};

const SELECT_ALL_TEXT = 'Select All';

const SelectMultiple = ({
  title,
  options,
  onChange,
  children = null,
  selectAllDefault = false,
}: Props) => {
  let parsedOptions = cloneDeep(options);

  if (options.length > 2) {
    parsedOptions = [
      {
        value: SELECT_ALL_TEXT,
        label: SELECT_ALL_TEXT,
        displayValue: SELECT_ALL_TEXT,
      },
      ...parsedOptions,
    ];
  }

  const [selectedValues, setSelectedValues] = useState<ISelectMultipleOption[]>(selectAllDefault ? parsedOptions : []);
  
  const Selectbox = (
    <>
      <Select
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isSearchable={true}
        styles={multiSelectStyles  as StylesConfigType}
        value={selectedValues}
        formatOptionLabel={options => options.displayValue}
        options={[{
          options: parsedOptions,
        }]}
        onChange={(s: MultiValue<ISelectMultipleOption>, meta: any) => {
          if (meta?.action === 'deselect-option' && meta?.option?.value === SELECT_ALL_TEXT) {
            setSelectedValues([]);
            return;
          }

          const isSelectAll = s?.[s?.length - 1]?.value === SELECT_ALL_TEXT;

          const values = isSelectAll
            ? parsedOptions
            : s.filter(item => { return item.value !== SELECT_ALL_TEXT; });

          setSelectedValues(values.map(item => {return {...item};
          }));
        }}
        components={{
          Option: (props: any) => { return CustomOption(props, selectedValues); },
        }}
      />
    </>
  );

  useEffect(() => {
    if (onChange) {
      onChange?.(selectedValues.filter(item => {
        return item.value !== SELECT_ALL_TEXT;
      }));
    }
  }, [selectedValues]);

  return (
    <>
      {(title) ? <div className="row pl-3 pb-2">{title}</div> : null}
      <div style={{maxWidth:'400px', minWidth: '250px'}}>
        {Selectbox}
      </div>
    </>
  );
};

export default SelectMultiple;