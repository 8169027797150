import React, { useEffect, useState } from 'react';
import Modal from 'core/components/modals/Modal';
import tableStyles from 'core/components/shared/dm-table/_styles.module.scss';
import { CellProps, ColumnHeaderData, Row } from 'core/components/shared/dm-table/types';
import TableHeader from 'core/components/shared/dm-table/Header';
import TableRow from 'core/components/shared/dm-table/Row';
import Cell from 'core/components/shared/dm-table/Cell';
import Icon from 'core/components/shared/Icon';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { deleteSubInfo, getAllSubs } from 'core/store/actions';
import { PayrollTimeCardSubInfo } from 'core/models/Contractor.model';
import SubDetailModal from './SubDetail.modal';

type Props = {
  show: boolean;
  onHide: () => void;
};

const subSetupColumns: ColumnHeaderData[] = [
  { title: 'Sub No', styleClass: 'th-20' },
  { title: 'Description', styleClass: 'th-60' },
  { title: 'Exclude from WH347', styleClass: 'th-20' },
];

const CellCheckbox = ({ name, value }: { name: string; value: boolean;  }) => (
  <div className="d-flex align-items-center">
    <input
      name={name}
      type="checkbox"
      checked={value}
      aria-readonly
    />
  </div>
);

const buildSubRow = (sub: PayrollTimeCardSubInfo): Row => {
  return {
    id: sub.subNo,
    cells: [
      { children: sub.subNo, styleClass: 'td-20' },
      { children: sub.description, styleClass: 'td-60' },
      { children: <CellCheckbox
        name={sub.subNo}
        value={sub.excludeFromWH347}
      />,
      styleClass: 'td-20',
      position: 'center',
      },
    ],
  };
};

const SubSetupModal = ({ show, onHide }: Props) => {
  const [rowData, setRowData] = useState<Row[]>([]);
  const [selectedSub, setSelectedSub] = useState<PayrollTimeCardSubInfo | null>(null);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [addNew, setAddNew] = useState<boolean>(false);
  
  const { allSubs } = useAppSelector(({ contractor }) => contractor);
  
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(getAllSubs());
  }, []);
  
  useEffect(() => {
    setRowData(allSubs?.map((sub) => buildSubRow(sub)));
  }, [allSubs]);
  
  const handleDeleteSub = () => {
    if (!selectedSub) return console.error('No sub to delete');
    if (!confirm(`Delete job #${selectedSub.subNo}?`)) return;
    dispatch(deleteSubInfo({ subNo: selectedSub.subNo }));
    setSelectedSub(null);
  };
  
  return (
    <Modal
      title="Sub Setup"
      show={show}
      onHide={onHide}
    >
      <div className={tableStyles['table-container']}>
        <div className={tableStyles['table-modules-container']}>
          <button
            className="btn btn-link p-0 mr-3"
            onClick={() => {
              setShowDetailModal(true);
            }}
            disabled={!selectedSub}
          >
            Update Selected&nbsp;<Icon 
              name="pen"
            />
          </button>
          <button
            className="btn btn-link p-0 mr-3"
            onClick={handleDeleteSub}
            disabled={!selectedSub}
          >
            Delete Selected&nbsp;<Icon 
              name="minus-circle"
            />
          </button>
          <button
            className="btn btn-link p-0 mr-3"
            onClick={() => {
              setSelectedSub(null);
              setShowDetailModal(true);
              setAddNew(true);
            }}
          >
            Add Sub&nbsp;<Icon 
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </div>
        <div className={tableStyles.table}>
          <TableHeader columns={subSetupColumns} />
          <div className={tableStyles['tbody-no-scroll']}>
            {rowData?.map((row: Row) => (
              <TableRow
                id={row.id}
                isSelected={String(row.id) === String(selectedSub?.subNo)}
                key={row.id}
                onClick={() => {
                  setSelectedSub(allSubs?.find((sub) => String(sub.subNo) === String(row.id)) ?? null);
                }}
                onDoubleClick={() => {
                  setSelectedSub(allSubs?.find((sub) => String(sub.subNo) === String(row.id)) ?? null);
                  setShowDetailModal(true);
                }}
              >
                {row.cells.map((cell: CellProps, cellIndex: number) => (
                  <Cell
                    key={`(${row.id},${cellIndex})`}
                    styleClass={cell.styleClass}
                    position={cell.position}
                  >
                    {cell.children}
                  </Cell>
                ))}
              </TableRow>
            ))}
          </div>
        </div>
      </div>
      {showDetailModal && (selectedSub || addNew) && (
        <SubDetailModal
          show={showDetailModal}
          addSub={addNew}
          onHide={() => {
            setAddNew(false);
            setShowDetailModal(false);
          }}
          subInfo={selectedSub ?? undefined}
        />
      )}
    </Modal>
  );
};

export default SubSetupModal;