import React, { CSSProperties, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { DMDropdownMenu } from 'core/components/form-controls';
import { currentUserDD } from 'navigation/app-main-nav';
import searchIcon from 'assets/images/search.png';
import logo from 'assets/images/DMLogo50.png';
import PopoverComponent from 'core/components/modals/PopoverComponent';
import AppMainNav from './AppMainNav';
import AppSearch from './AppSearch';
import { useAuth } from 'core/providers/authProvider';
import EmployeeChangesReportModal from '../employee-reports/EmployeeChangesReport.modal';
import DeductionShortagesReportModal from '../employee-reports/DeductionShortagesReport.modal';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import AppChangeClient from './AppChangeClient';
import { Link, useHistory } from 'react-router-dom';
import { getClient } from 'core/store/selectors';
import { useSelector } from 'react-redux';
import Modal from 'core/components/modals/Modal';
import { loadUserAccess, resetStore, unblockAll } from 'core/store/actions';

type PropTypes = {
  showSidebarMenuIcon: boolean;
  showMainNavTop: boolean;
};

const navStyles: { [key: string]: CSSProperties } = {
  container: {
    display: 'flex',
    height: '25px',
    width: '100%',
    margin: '0',
    backgroundColor: '#d4d4d4',
    fontSize: '14px',
    fontWeight: '600',
    color: 'rgb(100, 101, 106)',
    padding: '4px',
  },
  clientInfoWrapper: {
    paddingLeft: '8px',
    boxSizing: 'border-box',
    height: '100%',
  },
};

const AppTopNavBar: React.FC<PropTypes> = ({
  showSidebarMenuIcon,
  showMainNavTop,
}) => {
  const auth = useAuth();
  
  const history = useHistory();
  
  const [showEmployeeChangesReport, setShowEmployeeChangesReport] = useState(false);
  const [showDeductionShortagesReport, setShowDeductionShortagesReport] = useState(false);
  const [showClientSelector, setShowClientSelector] = useState(false);
  
  const dispatch = useAppDispatch();

  const currentClient = useSelector(getClient);
  const appDataLoaded = useAppSelector((state) => { return state.app.appDataLoaded; });
  const userClients = useAppSelector((state) => { return state.auth.userAccess; });
  const { moduleAccess, moduleAccessLoading } = useAppSelector((state) => {
    return state.app;
  });
  const userAccessLoading = useAppSelector((state) => {return state.auth.loadingUserAccess;});
  const groupAccess = moduleAccess?.groups;
  const employeeMasterSection = groupAccess?.find((group) => {
    return group.description.toLowerCase() === 'employee master';
  });
  const visible = employeeMasterSection?.visible;

  if (userClients !== null) {
    currentUserDD.title = `Hello, ${userClients.dmUserName}`;
  }  
  if (!auth.isAuthenticated() || !appDataLoaded) return null;

  const onSelect = (menuId: string) => {
    if (menuId === 'current-change-client') {
      setShowClientSelector(true);
    }
  };
  
  const onSelectProp = (clientNo: number) => {
    dispatch(unblockAll()); // unblock any blocked navigation
    
    setTimeout(() => {
      history.push('/');
    
      dispatch(resetStore());
      dispatch(loadUserAccess({ nextClientNo: clientNo }));
    }, 150);
  };

  return (
    <>
      <div style={navStyles.container}>
        <div
          style={navStyles.clientInfoWrapper}
        >
          {currentClient?.clientNo} {currentClient?.clientName}
        </div>
      </div>
      <Navbar
        bg="light"
        expand="sm"
        className="app-page-header-container"
      >
        <div className="d-flex flex-row flex-wrap w-100">
          <Link to="/home">
            <Navbar.Brand className="p-0">
              <img
                src={logo}
                className="app-logo"
                alt="logo"
              />
            </Navbar.Brand>
          </Link>
          {!showSidebarMenuIcon && showMainNavTop && (
            <AppMainNav
              handleDeductionShortagesClick={() => { setShowDeductionShortagesReport(true); }
              }
            />
          )}
          <div className="d-flex flew-row flex-wrap ml-auto">
            {(userAccessLoading || moduleAccessLoading || visible === false) ? null : (
              <label className="my-auto ml-3 mr-3 ml-sm-auto pb-2 pb-sm-0 pt-2 pt-sm-0">
                <PopoverComponent
                  title="Search Employee"
                  component={AppSearch}
                >
                  <img
                    src={searchIcon}
                    alt="search"
                    width={20}
                    height="auto"
                  />
                </PopoverComponent>
              </label>
            )}
            <div
              style={{ minWidth: '200px' }}
              className="my-auto"
            >
              <DMDropdownMenu
                data={currentUserDD}
                toggleClass="dm-main-nav"
                preIcon={'my-info-task2.png'}
                onSelect={onSelect}
              />
            </div>
          </div>
        </div>
        {!showSidebarMenuIcon && !showMainNavTop && (
          <AppMainNav
            handleEmployeeChangeClick={() => { setShowEmployeeChangesReport(true); }
            }
            handleDeductionShortagesClick={() => { setShowDeductionShortagesReport(true); }
            }
          />
        )}
        {showEmployeeChangesReport && (
          <EmployeeChangesReportModal
            show={showEmployeeChangesReport}
            onHide={() => { setShowEmployeeChangesReport(false); }}
          />
        )}

        {showDeductionShortagesReport && (
          <DeductionShortagesReportModal
            show={showDeductionShortagesReport}
            onHide={() => { setShowDeductionShortagesReport(false); }}
          />
        )}
        {showClientSelector && (
          <Modal
            show={showClientSelector}
            onHide={() => { setShowClientSelector(false); }}
            title="Change Client"
          >
            <AppChangeClient
              onHide={() => { setShowClientSelector(false); }}
              onSelectProp={onSelectProp}
            />
          </Modal>
        )}
      </Navbar>
    </>
  );
};

export default AppTopNavBar;
