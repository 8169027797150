import React, { useEffect } from 'react';
import { CheckboxGrpInLine, InputGrp, SelectGrp } from 'core/components/form-controls';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getEarningsCodes } from 'core/store/selectors';
import { clearTransmittalAutofillMessages, loadPayrollControlTotal, loadTransmittalEmployees, postTransmittalAutofill } from 'core/store/actions';
import { TransmittalAutoFill, TransmittalParams, TransmittalSortOrder } from 'core/models';
import { Alert } from 'react-bootstrap';
import { useAppSelector } from 'utilities/hooks';

type Props = {
  params: TransmittalParams;
  onHide: () => void;
};

export const AutoFill = ({ params, onHide }: Props): JSX.Element => {
  const { register, handleSubmit, errors, reset, watch, trigger } = useForm<TransmittalAutoFill>(
    {
      mode: 'all',
      defaultValues: {
        hours: 0,
        earnings: 0,
      },
    },
  );
  const dispatch = useDispatch();
  
  const earningsCodeOpts = useSelector(getEarningsCodes);
  const autoFillMessages = useAppSelector(({ payroll }) => payroll.autoFillMessages);
  const orderTransmittalBy = useAppSelector((state) => state.client.clientOptions.options?.[34]?.optionValue);
  
  const watchFields = watch(['hours', 'earnings']);
  
  const onSubmit = (data: TransmittalAutoFill) => {
    dispatch(postTransmittalAutofill({ params: params, data }));
  };

  useEffect(() => {
    if (autoFillMessages.length > 0 && params?.controlTotalId) {
      reset();
      setTimeout(() => {
        dispatch(clearTransmittalAutofillMessages());
      }, 5000);
      dispatch(loadTransmittalEmployees({ params, orderBy: orderTransmittalBy as TransmittalSortOrder }));
      dispatch(loadPayrollControlTotal(params));
      onHide();
    }
  }, [autoFillMessages]);

  return (
    <div>
      <Alert
        variant="success"
        show={autoFillMessages.length > 0}
      >
        {autoFillMessages.map((msg, i) => {
          return (
            <div key={i}>{msg}</div>
          );
        })}
      </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SelectGrp
          options={earningsCodeOpts}
          name="earningsCode"
          id="earningsCode"
          addEmptyValue={true}
          label="Earnings Code"
          errorMsg="Required"
          errors={errors.earningsCode}
          ref={register({
            required: 'Please choose an Earnings Code',
          })}
          showId={true}
        />
        <InputGrp
          name="hours"
          id="hours"
          type="number"
          errors={errors.hours}
          step={0.01}
          ref={register({
            required: true,
            validate: {
              earnings: (value) => {
                return parseFloat(value) > 0 ||
                                watchFields.earnings > 0 ||
                                'Hours or Earnings Amount must be greater than 0';
              },
            },
          })}
          onChange={() => {
            trigger('earnings');
          }}
          label="Hours"
        />
        <InputGrp
          name="earnings"
          id="earnings"
          type="number"
          errors={errors.earnings}
          ref={register({
            required: true,
            validate: {
              earnings: (value) => {
                return parseFloat(value) > 0 ||
                                watchFields.hours > 0 ||
                                'Hours or Earnings Amount must be greater than 0';
              },
            },
          })}
          label="Earnings Amount"
          onChange={() => {
            trigger('hours');
          }}
          step={0.01}
        />
        <CheckboxGrpInLine
          name="overwriteExisting"
          id="overwriteExisting"
          labelFirst={false}
          type="checkbox"
          ref={register}
          label="Overwrite exisiting data?"
        />
        <CheckboxGrpInLine
          name="includeSalaried"
          id="includeSalaried"
          labelFirst={false}
          type="checkbox"
          ref={register}
          label="Include salaried employees?"
        />
        <CheckboxGrpInLine
          name="useAutoFillOnInsert"
          id="useAutoFillOnInsert"
          labelFirst={false}
          type="checkbox"
          ref={register}
          label="Use Auto Fill when inserting employees?"
        />
        <div className="d-flex">
          <div className="mr-auto">
            <button
              className="orange-outline-button"
              type="button"
              onClick={() => { reset(); }}
            >
              Clear Autofill
            </button>
          </div>
          <div className="">
            <button
              className="orange-outline-button mr-3"
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              className="orange-button"
              type="submit"
            >
              Okay
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
