import React, { useEffect, useState } from 'react';
import { Prompt, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PanelHeader from '../../../core/components/shared/PanelHeader';
import { HRSource, HRSourceRequest } from '../../../core/models/HREmployee.model';
import {
  createHRSource,
  deleteHRSource,
  loadHRSource,
  updateHRSource,
} from '../../../core/store/actions/hr-employee.action';
import { getHireInfoSource } from '../../../core/store/selectors/hr-employee.selector';
import SourcesItem from './SourcesItem';
import { useForm, useFieldArray } from 'react-hook-form';
import { UNSAVED_MESSAGE } from '../../../core/constants';
import { currencyFormatter, fromCurrency } from '../../../utilities/utilities';
import Icon from 'core/components/shared/Icon';
import { toggleBlockNavigation } from 'core/store/actions';

type Clone = Omit<HRSource, 'fee'> & { fee: string | number };
type FormType = {
  sourceItems: Clone[];
};

const SourcesPage: React.FC<any> = () => {
  const dispatch = useDispatch();

  const { protectedEmpNo } = useParams<any>();
  const [sourceItems, setSourceItems] = useState<HRSource[]>([]);
  const sourcesStore = useSelector(getHireInfoSource);

  const {
    reset,
    control,
    handleSubmit,
    formState,
    ...formMethods
  } = useForm<FormType>({
    defaultValues: {
      sourceItems,
    },
  });

  const { fields, prepend } =
    useFieldArray<HRSource>({
      control,
      name: 'sourceItems',
      keyName: 'id',
    });


  useEffect(() => {
    protectedEmpNo && dispatch(loadHRSource(protectedEmpNo));
  }, [protectedEmpNo]);

  useEffect(() => {
    sourcesStore && setSourceItems(sourcesStore);
  }, [sourcesStore]);

  useEffect(() => {
    sourceItems && reset({
      sourceItems: sourceItems.map(s => {
        return {
          ...s,
          fee: s?.fee && currencyFormatter(s.fee),
        };
      }),
    });
  }, [sourceItems]);

  const addSource = () => {
    prepend({
      empSourceFeeId: 0,
      protectedEmpNo,
    });
  };

  const deleteSource = (item: HRSource) => {
    if (item.empSourceFeeId) {
      dispatch(deleteHRSource({
        data: item,
        protectedEmpNo,
      }));
    } else {
      setSourceItems((prev: HRSource[]) => { return prev.filter((cb: HRSource) => { return cb.empSourceFeeId; }); });
    }
  };

  const onSubmit = (data: FormType, e?: React.BaseSyntheticEvent) => {
    if (!e?.target.isConnected) {
      return false;
    }
    if (!data) return;
    
    data.sourceItems.forEach((item) => {
      const request: HRSourceRequest = {
        protectedEmpNo,
        data: {
          ...item,
          empSourceFeeId: +item.empSourceFeeId,
          fee: (item.fee && fromCurrency('' + item.fee)) || 0,
        },
      };
      
      if (request.data.empSourceFeeId !== 0) {
        dispatch(updateHRSource(request));
      } else {
        dispatch(createHRSource(request));
      }
    });
  };

  const onCancel = () => {
    reset();
  };

  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: formState.isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [formState.isDirty]);
  
  return (
    <div>
      <div className="dm-panel dm-panel-border" >
        <PanelHeader title="Source and Fees" >
          <button
            className="btn btn-link dm-grid-action-title p-0 mr-2"
            onClick={addSource}
          >
            Add Source and Fees	<Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields?.map((item, index: number) => {
            return (
              <div key={item.empSourceFeeId} >
                <SourcesItem
                  item={item}
                  index={index}
                  control={control}
                  formMethods={formMethods}
                  onDelete={deleteSource}
                />
                <hr className="dm-panel-hr mt-2" />
              </div>
            );
          })}

          <div className="text-right mt-3">
            <button
              type="button"
              className="btn btn-primary orange-outline-button ml-auto mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              disabled={!formState.isDirty}
              type="submit"
              className="btn orange-button-sm mr-3"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SourcesPage;
