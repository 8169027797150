import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FieldInputSettings, FormMethods } from 'core/components/form-controls/types';
import { CheckCodeSelect, ControlDatePickerGrp, InputGrpCurrency, SelectGrp, SelectModalGrp } from 'core/components/form-controls';
import { ArrayField, useWatch } from 'react-hook-form';
import { RecurringEarnings } from 'core/models';
import { CURRENCY_VALIDATION } from 'core/constants';
import Icon from 'core/components/shared/Icon';
import styles from 'core/components/form-controls/form-controls.module.scss';
import { validateExpireDate } from '../deductions/DeductionUtilites';
import {
  getCheckCodes,
  getClientOptionIsContractor,
  getCostCode,
  getDeductionFrequencies,
  getEarningsCodes,
  getJobNumbers,
} from 'core/store/selectors';
import { useAppSelector } from 'utilities/hooks';
import { getAccess } from 'utilities/utilities';

type PropTypes = {
  item: Partial<ArrayField<RecurringEarnings>>;
  index: number;
  formMethods: Omit<FormMethods, 'control'>;
  control: FormMethods['control'];
  isCheckCalculator?: boolean;
  onDelete: (data: RecurringEarnings) => void;
  updateNestedErrors?: (title: string, key?: string, propertyChain?: string) => void;
  updateDirtyState?: (newState: boolean, id: number) => void;
  onUpdateRecurringEarning?: (rec: RecurringEarnings) => void;
  onWatch?: (recurringId: number, e: RecurringEarnings, valid: boolean) => void;
  formPrefix?: string;
};

const RecurringEarningsItem: React.FC<PropTypes> = ({
  item,
  index,
  control,
  isCheckCalculator,
  onDelete,
  updateNestedErrors,
  updateDirtyState,
  onUpdateRecurringEarning,
  formMethods: { errors, setValue, register, getValues, watch },
  onWatch,
  formPrefix,
}) => {
  const startDateName = `${formPrefix ?? ''}recurringEarnings[${index}].startDate`;
  const endDateName = `${formPrefix ?? ''}recurringEarnings[${index}].endDate`;
  const checkCodeName = `${formPrefix ?? ''}recurringEarnings[${index}].checkCode`;
  const payTypeName = `${formPrefix ?? ''}recurringEarnings[${index}].payType`;
  const freqName = `${formPrefix ?? ''}recurringEarnings[${index}].frequency`;
  const amountName = `${formPrefix ?? ''}recurringEarnings[${index}].amount`;
  const jobNoName = `${formPrefix ?? ''}recurringEarnings[${index}].jobNo`;
  const costCodeName = `${formPrefix ?? ''}recurringEarnings[${index}].costCode`;
  const watchedPayType = watch(payTypeName) ?? '';

  const fs: FieldInputSettings = {
    startDate: {
      name: startDateName,
      label: 'START DATE',
    },
    endDate: {
      name: endDateName,
      label: 'EXPIRE DATE',
    },
    checkCode: {
      name: checkCodeName,
      label: 'CHECK CODE',
    },
    payType: {
      name: payTypeName,
      label: 'EARNINGS CODE',
      showId: true,
      // required: !isCheckCalculator,
    },
    frequency: {
      name: freqName,
      label: 'FREQUENCY',
      required: !isCheckCalculator,
    },
    amount: {
      name: amountName,
      label: 'AMOUNT',
      required: !isCheckCalculator && watchedPayType !== '',
      errorMsg: 'Amount is required',
    },
    jobNo: {
      name: jobNoName,
      label: 'JOB NUMBER',
      groupClass: 'groupClass20',
    },
    costCode: {
      name: costCodeName,
      label: 'Cost Code',
      groupClass: 'groupClass20',
    },
  };

  const earningsCodeOpts = useSelector(getEarningsCodes);
  const dedFreqOpts = useSelector(getDeductionFrequencies);
  const jobNumberOpts = useSelector(getJobNumbers);
  const costCodeOpts = useSelector(getCostCode);
  const checkCodeOpts = useSelector(getCheckCodes) as any;
  const isContractor = useSelector(getClientOptionIsContractor);
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  
  useEffect(() => {
    if (updateNestedErrors) {
      updateNestedErrors('recurring earnings', 'recurringEarnings');
    }
  }, [errors]);
  
  const onFormClick = async () => {
    if (!onWatch) return;

    const formValues = getValues();
    const updateRecurringEarning = formValues.recurringEarnings.find((x: RecurringEarnings) => {return x.recurringId === item.recurringId;}) || item;
    onWatch(item.recurringId || 0, updateRecurringEarning, true);
  };

  return (
    <div
      className="d-flex flex-column"
      onChange={onFormClick}
    >
      <div className="d-flex flex-wrap">
        <input
          type="hidden"
          name={`${formPrefix ?? ''}recurringEarnings[${index}].recurringId`}
          defaultValue={item.recurringId ?? 0}
          ref={register({ valueAsNumber: true })}
        />
        <ControlDatePickerGrp
          {...fs.startDate}
          {...getAccess(sectionAccess, 22)}
          errors={errors?.recurringEarnings?.[index]?.startDate}
          value={item.startDate}
          setValue={setValue}
          control={control}
          groupClass="mr-3"
          innerChangeHandler={() => {
            if (!(getAccess(sectionAccess, 22)?.readOnly ?? false)) {
              updateDirtyState?.(true, item?.recurringId ?? 0);
              onUpdateRecurringEarning?.(item as RecurringEarnings);
            }
          }}
        />
        <ControlDatePickerGrp
          {...fs.endDate}
          {...getAccess(sectionAccess, 22)}
          errors={errors?.recurringEarnings?.[index]?.endDate}
          value={item.endDate ?? null}
          setValue={setValue}
          control={control}
          groupClass="mr-3"
          innerChangeHandler={() => {
            if (!(getAccess(sectionAccess, 22)?.readOnly ?? false)) {
              updateDirtyState?.(true, item?.recurringId ?? 0);
              onUpdateRecurringEarning?.(item as RecurringEarnings);
            }
          }}
          rules={{
            validate: (value: Date) => { return validateExpireDate(value, new Date(getValues(startDateName))); },
          }}
        />
        <CheckCodeSelect
          {...fs.checkCode}
          {...getAccess(sectionAccess, 22)}
          control={control}
          options={checkCodeOpts}
          value={item.checkCode}
          setValue={setValue}
          errors={errors?.recurringEarnings?.[index]?.checkCode ??  errors.checkCode}
          menuPlacement="auto"
          groupClass="mr-3"
          portalTargetId="re-wrapper"
          onChange={(e) => {
            const newEarning = { ...item, checkCode: e?.checkCode ?? item.checkCode }; 
            
            if (!(getAccess(sectionAccess, 22)?.readOnly ?? false)) {
              updateDirtyState?.(true, item?.recurringId ?? 0);
              onUpdateRecurringEarning?.(newEarning as RecurringEarnings);
            }
          }}
        />
        <SelectGrp
          {...fs.payType}
          {...getAccess(sectionAccess, 22)}
          value={watchedPayType}
          errors={errors?.recurringEarnings?.[index]?.payType ?? errors.payType}
          ref={register}
          // labelClass={`${styles['dm-required']}`}
          // ref={register({
          //   required: {
          //     value: getValues(payTypeName) != '',
          //     message: 'Earnings code is required.',
          //   },
          // })}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (!(getAccess(sectionAccess, 22)?.readOnly ?? false)) {
              updateDirtyState?.(true, item?.recurringId ?? 0);
              onUpdateRecurringEarning?.(item as RecurringEarnings);
              setValue(payTypeName, e.target.value);
            }
          }}
          options={earningsCodeOpts}
          addEmptyText={'< Blank Earnings Line >'}
          groupClass="mr-3"
        />
        <SelectGrp
          {...fs.frequency}
          {...getAccess(sectionAccess, 22)}
          labelClass={`${styles['dm-required']}`}
          defaultValue = {item.frequency}
          errors={errors?.recurringEarnings?.[index]?.frequency ?? errors.frequency}
          ref={register({
            required: {
              value: true,
              message: 'Frequency is required.',
            },
          })}
          options={dedFreqOpts}
          onChange={() => {
            if (!(getAccess(sectionAccess, 22)?.readOnly ?? false)) {
              updateDirtyState?.(true, item?.recurringId ?? 0);
              onUpdateRecurringEarning?.(item as RecurringEarnings);
            }
          }}
          groupClass="mr-3"
        />
        <InputGrpCurrency
          {...fs.amount}
          {...getAccess(sectionAccess, 22)}
          groupClass="mr-3"
          labelClass={watchedPayType !== '' && `${styles['dm-required']}`}
          defaultValue = {item.amount}
          errors={errors?.recurringEarnings?.[index]?.amount ?? errors.amount}
          setValue={setValue}
          onChange={() => {
            if (!(getAccess(sectionAccess, 22)?.readOnly ?? false)) {
              updateDirtyState?.(true, item?.recurringId ?? 0);
              onUpdateRecurringEarning?.(item as RecurringEarnings);
            }
          }}
          ref={register({
            ...CURRENCY_VALIDATION,
            required: {
              value: watchedPayType !== '',
              message: 'Amount is required.',
            },
            validate: (value: any) => {
              const x = parseFloat((value ?? '0')?.replace(/[^0-9.+-]/g, ''));
              return (x > 0 && watchedPayType !== '') || watchedPayType === '';
            },
          })}
        />
        {isContractor && (
          <SelectGrp
            groupClass="mr-3"
            {...fs.jobNo}
            {...getAccess(sectionAccess, 22)}
            errors={errors?.recurringEarnings?.[index]?.jobNo ?? errors.jobNo}
            ref={register}
            options={jobNumberOpts}
            defaultValue={item.jobNo}
            addEmptyText={true}
            onChange={() => {
              if (!(getAccess(sectionAccess, 22)?.readOnly ?? false)) {
                updateDirtyState?.(true, item?.recurringId ?? 0);
                onUpdateRecurringEarning?.(item as RecurringEarnings);
              }
            }}
          />
        )}
        {isContractor && (
          <SelectGrp
            groupClass="mr-3"
            {...fs.costCode}
            {...getAccess(sectionAccess, 22)}
            errors={errors?.recurringEarnings?.[index]?.costCode ?? errors.costCode}
            ref={register}
            options={costCodeOpts}
            defaultValue={item.costCode}
            addEmptyText={true}
            onChange={() => {
              if (!(getAccess(sectionAccess, 22)?.readOnly ?? false)) {
                updateDirtyState?.(true, item?.recurringId ?? 0);
                onUpdateRecurringEarning?.(item as RecurringEarnings);
              }
            }}
          />
        )}
      </div>

      <div className="row mt-3">
        <div className="col-12 text-right">
          <button
            {...getAccess(sectionAccess, 22, undefined, { disabledSameAsReadOnly: true })}
            type="button"
            className="btn btn-link dm-grid-action-title mr-4"
            onClick={() => {
              if (!item?.empId) return console.error('No item');
              onDelete(item as RecurringEarnings);
            }}
          >
            Delete Recurring Earnings{' '}
            <Icon
              name="minus-circle"
              className="fa-minus-circle"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecurringEarningsItem;
