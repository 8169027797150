import React from 'react';
import CheckBoxForm from '../../Shared/CheckBoxForm';
import { Col, Row } from 'react-bootstrap';
import { EEDComponentProps } from 'types/eed.types';
import GridSelect from '../../Shared/GridSelect';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import SelectField from '../../Shared/SelectField';
import { cloneDeep } from 'lodash';
import { UserMapSelectedField } from 'core/models/Downloader.model';
import { setSelectedFields } from 'core/store/slices/employee-earnings-download.slice';
import { IOption } from 'interfaces/IOption';
import { Dropdown } from 'core/models';

// Custom Layout for Checkbox and Grid select forms
const HireGeneralFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
 
  const hireInfoCustomerFields = useAppSelector((state) => {
    return state.hrEmployeeInfo.hireInfoCustomerFields;
  });

  const generalInfoOptions = sectionFields?.find(item => {
    return item.name === 'Hire General Information';
  })?.fields?.map(item => {
    return {
      ...item,
      value: item.name,
      label: item.name,
    };
  }) as IOption[];

  return (
    <Row>
      {!!sectionFields?.length && (
        <>
          <Col sm={6}>
            <Row>
              <Col xs={12}>
                <CheckBoxForm
                  formMethods={formMethods}
                  dFields={sectionFields.find(f => {
                    return f.name === 'Job';
                  })?.fields}
                  section="Job"
                  title="Job"
                />
              </Col>
              <Col xs={12}>
                <CheckBoxForm
                  formMethods={formMethods}
                  dFields={sectionFields.find(f => {
                    return f.name === 'Non Compete-Solicit';
                  })?.fields}
                  section="Non Compete-Solicit"
                  title="Non Compete-Solicit"
                />
              </Col>
              <Col xs={12}>
                <GridSelect
                  section="Customized Field Information"
                  title="Customized Field Information"
                  dFields={[
                    {
                      name: 'Custom Field Grid Description',
                    },
                  ]}                 
                  options={hireInfoCustomerFields?.map(item => {
                    return {
                      ...item,
                      id: item.customFieldId,
                      description: item.fieldName,
                    } as any;
                  })}
                  isCodeField={false}
                  hasCodeColumn={false}
                  formMethods={formMethods}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col xs={12}>
                <CheckBoxForm
                  formMethods={formMethods}
                  dFields={sectionFields.find(f => {
                    return f.name === 'Hire General Information';
                  })?.fields}
                  section="Hire General Information"
                  title="General Info"
                />
              </Col>
              <Col xs={12}>
                <CheckBoxForm
                  formMethods={formMethods}
                  dFields={sectionFields.find(f => {
                    return f.name === 'Annual Bonus Target';
                  })?.fields}
                  section="Annual Bonus Target"
                  title="Annual Bonus Target"
                />
              </Col>
              <Col xs={12}>
                <CheckBoxForm
                  formMethods={formMethods}
                  dFields={sectionFields.find(f => {
                    return f.name === 'Communication';
                  })?.fields}
                  section="Communication"
                  title="Communication"
                />
              </Col>
            </Row>
          </Col>
        </>
      )}
    </Row>
  );
};

export default HireGeneralFields;