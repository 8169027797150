import { AxiosResponse } from 'axios';
import { API_CLIENT, API_EMPLOYEE_MASTER } from './API';
import { HumanCapitalReportEndpoints } from './URL';
import { HttpResponse } from '../models';
import { EEONoSetting, EEOUnitSetting, LookupEEOCertifyOfficial, LookupEEOUnit } from '../models/EEO.model';
import { CommonService } from './common.service';
import { EEOPayrollDate, EEOReportRequest, EEOSettingsRequest, IEEONoSetting } from 'core/models/HumanCapitalReports.model';

const getEEONoSettings = (): Promise<AxiosResponse<EEONoSetting[]>> => {
  return API_CLIENT.get<EEONoSetting[]>(
    HumanCapitalReportEndpoints.GET_EEO_NO_SETTINGS(CommonService.getProtectedClientNo()));
};

const postEEONoSettings = (data: EEONoSetting | IEEONoSetting): Promise<AxiosResponse<HttpResponse<EEONoSetting>>> => {
  return API_CLIENT.post<HttpResponse<EEONoSetting>>(
    HumanCapitalReportEndpoints.POST_EEO_NO_SETTINGS(CommonService.getProtectedClientNo()), data);
};

const putEEONoSettings = (data: EEONoSetting | IEEONoSetting): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_CLIENT.put<HttpResponse<any>>(
    HumanCapitalReportEndpoints.PUT_EEO_NO_SETTINGS(CommonService.getProtectedClientNo(), data.ruleID), data);
};

const deleteEEONoSettings = (data: EEONoSetting | IEEONoSetting): Promise<AxiosResponse<HttpResponse<EEONoSetting>>> => {
  return API_CLIENT.delete<HttpResponse<EEONoSetting>>
  (
    HumanCapitalReportEndpoints.DELETE_EEO_NO_SETTINGS(CommonService.getProtectedClientNo(), data.ruleID));
};

const getEEOSettings = (): Promise<AxiosResponse<any>> => {
  return API_EMPLOYEE_MASTER.get<EEOSettingsRequest>(
    HumanCapitalReportEndpoints.GET_EEO_REPORT_SETTINGS(CommonService.getProtectedClientNo()));
};

const postEEOSettings = (data: EEOSettingsRequest): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(
    HumanCapitalReportEndpoints.GET_EEO_REPORT_SETTINGS(CommonService.getProtectedClientNo()), data);
};

const putEEOSettings = (data: EEOSettingsRequest): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<any>>(
    HumanCapitalReportEndpoints.PUT_EEO_REPORT_SETTINGS(CommonService.getProtectedClientNo(), data.settingID), data);
};

const deleteEEOSettings = (data: EEOSettingsRequest): Promise<AxiosResponse<HttpResponse<any>>> => {
  return API_EMPLOYEE_MASTER.delete<HttpResponse<any>>(
    HumanCapitalReportEndpoints.DELETE_EEO_UNIT_SETTINGS(CommonService.getProtectedClientNo(), data.settingID));
};

const getEEOUnitSettings = (): Promise<AxiosResponse<EEOUnitSetting[]>> => {
  return API_CLIENT.get<EEOUnitSetting[]>(
    HumanCapitalReportEndpoints.GET_EEO_UNIT_SETTINGS(CommonService.getProtectedClientNo()));
};

const postEEOUnitSettings = (data: EEOUnitSetting): Promise<AxiosResponse<HttpResponse<EEOUnitSetting>>> => {
  return API_CLIENT.post<HttpResponse<EEOUnitSetting>>(
    HumanCapitalReportEndpoints.POST_EEO_UNIT_SETTINGS(CommonService.getProtectedClientNo()), data);
};

const putEEOUnitSettings = (data: EEOUnitSetting): Promise<AxiosResponse<HttpResponse<EEOUnitSetting>>> => {
  return API_CLIENT.put<HttpResponse<EEOUnitSetting>>(
    HumanCapitalReportEndpoints.PUT_EEO_UNIT_SETTINGS(CommonService.getProtectedClientNo(), data.ruleID), data);
};

const deleteEEOUnitSettings = (data: EEOUnitSetting): Promise<AxiosResponse<HttpResponse<EEOUnitSetting>>> => {
  return API_CLIENT.delete<HttpResponse<EEOUnitSetting>>(
    HumanCapitalReportEndpoints.DELETE_EEO_UNIT_SETTINGS(CommonService.getProtectedClientNo(), data.ruleID));
};

const getLookupEEOUnits = (): Promise<AxiosResponse<LookupEEOUnit[]>> => {
  return API_CLIENT.get<LookupEEOUnit[]>(
    HumanCapitalReportEndpoints.GET_LOOKUP_EEO_UNITS(CommonService.getProtectedClientNo()));
};

const getLookupEEOUnit = (eeoUnitID: number): Promise<AxiosResponse<LookupEEOUnit>> => {
  return API_CLIENT.get<LookupEEOUnit>(
    HumanCapitalReportEndpoints.GET_LOOKUP_EEO_UNIT(CommonService.getProtectedClientNo(), eeoUnitID));
};

const postLookupEEOUnit = (data: LookupEEOUnit): Promise<AxiosResponse<HttpResponse<LookupEEOUnit>>> => { 
  return API_CLIENT.post<HttpResponse<LookupEEOUnit>>(
    HumanCapitalReportEndpoints.POST_LOOKUP_EEO_UNITS(CommonService.getProtectedClientNo()), data); 
};

const putLookupEEOUnit = (data: LookupEEOUnit): Promise<AxiosResponse<HttpResponse<LookupEEOUnit>>> => { 
  return API_CLIENT.put<HttpResponse<LookupEEOUnit>>(
    HumanCapitalReportEndpoints.PUT_LOOKUP_EEO_UNITS(CommonService.getProtectedClientNo(), data.eeoUnitID), data); 
};

const deleteLookupEEOUnit = (data: LookupEEOUnit): Promise<AxiosResponse<any>> => { 
  return API_CLIENT.delete<any>(
    HumanCapitalReportEndpoints.DELETE_LOOKUP_EEO_UNITS(CommonService.getProtectedClientNo(), data.eeoUnitID)); 
};

const getEEOCertifyOfficials = (): Promise<AxiosResponse<LookupEEOCertifyOfficial[]>> => { 
  return API_CLIENT.get<LookupEEOCertifyOfficial[]>(
    HumanCapitalReportEndpoints.GET_LOOKUP_EEO_CERTIFY_OFFICIALS(CommonService.getProtectedClientNo())); 
};

const getEEOCertifyOfficial = (certifyOfficalID: number): 
Promise<AxiosResponse<LookupEEOCertifyOfficial>> => { 
  return API_CLIENT.get<LookupEEOCertifyOfficial>(
    HumanCapitalReportEndpoints.GET_LOOKUP_EEO_CERTIFY_OFFICIAL(CommonService.getProtectedClientNo(), certifyOfficalID)); 
};

const postEEOCertifyOfficial = (data: LookupEEOCertifyOfficial): 
Promise<AxiosResponse<HttpResponse<LookupEEOCertifyOfficial>>> => { 
  return API_CLIENT.post<HttpResponse<LookupEEOCertifyOfficial>>(
    HumanCapitalReportEndpoints.POST_LOOKUP_EEO_CERTIFY_OFFICIAL(CommonService.getProtectedClientNo()), data); 
};

const putEEOCertifyOfficial = (data: LookupEEOCertifyOfficial): 
Promise<AxiosResponse<HttpResponse<LookupEEOCertifyOfficial>>> => { 
  return API_CLIENT.put<HttpResponse<LookupEEOCertifyOfficial>>
  (HumanCapitalReportEndpoints.PUT_LOOKUP_EEO_CERTIFY_OFFICIAL(CommonService.getProtectedClientNo(), data.certifyOfficalID), data); 
};

const deleteEEOCertifyOfficial = (data: LookupEEOCertifyOfficial): 
Promise<AxiosResponse<HttpResponse<any>>> => { 
  return API_CLIENT.delete<HttpResponse<any>>(
    HumanCapitalReportEndpoints.DELETE_LOOKUP_EEO_CERTIFY_OFFICIAL(CommonService.getProtectedClientNo(), data.certifyOfficalID)); 
};

const postEEOReport = (request: EEOReportRequest): 
Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    HumanCapitalReportEndpoints.POST_EEO_REPORT(CommonService.getProtectedClientNo()), request);
};

const getEEOPayrollDates = (payrollYear: number): Promise<AxiosResponse<EEOPayrollDate[]>> => {
  return API_EMPLOYEE_MASTER.get<EEOPayrollDate[]>(HumanCapitalReportEndpoints.GET_EEO_PAYROLL_DATES(CommonService.getProtectedClientNo(), payrollYear));
};
export const EEOService = {
  getEEONoSettings,
  postEEONoSettings,
  putEEONoSettings,
  deleteEEONoSettings,
  getEEOUnitSettings,
  postEEOUnitSettings,
  putEEOUnitSettings,
  deleteEEOUnitSettings,
  getLookupEEOUnits,
  getLookupEEOUnit,
  postLookupEEOUnit,
  putLookupEEOUnit,
  deleteLookupEEOUnit,
  getEEOCertifyOfficials,
  getEEOCertifyOfficial,
  postEEOCertifyOfficial,
  putEEOCertifyOfficial,
  deleteEEOCertifyOfficial,
  getEEOSettings,
  postEEOSettings,
  deleteEEOSettings,
  putEEOSettings,
  postEEOReport,
  getEEOPayrollDates,
};
