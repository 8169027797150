import React from 'react';
import noop from 'lodash/noop';
import uniq from 'lodash/uniq';
import { FieldConfig, PageConfig } from '../../core/models';

export type PageConfigsContextType = {
  documentIds: number[],
  pageConfigs: PageConfig[],
  updateDocumentIds: (onboardDocId: number, selected: boolean) => void,
  setPageConfigs: (pageConfigs: PageConfig[]) => void,
  updatePageConfig: (pageID: number, fieldID: number, required: boolean) => void,
  updatePageConfigRequired: (pageID: number, pageConfigRequired: boolean) => void,
  setPageConfigContext: React.Dispatch<React.SetStateAction<PageConfigsContextType>>
};

export const PageConfigContextObj: PageConfigsContextType = {
  documentIds: [],
  pageConfigs: [],
  updateDocumentIds(id: number, selected: boolean) {
    if (selected) {
      this.documentIds = uniq([...this.documentIds, id]);
    } else {
      this.documentIds = this.documentIds.filter((docId: number) => {return docId !== id;});
    }
  },
  setPageConfigs(pageConfigs: PageConfig[]) { this.pageConfigs = pageConfigs; },

  updatePageConfig(pageID: number, fieldID: number, required: boolean) {
    const pageConfigToUpdate = this.pageConfigs.find((pc: PageConfig) => {return pc.pageID === pageID;});
    const fieldConfigToUpdate = pageConfigToUpdate?.fieldConfigs.find((fc: FieldConfig) => {return fc.fieldID === fieldID;});
    fieldConfigToUpdate && (fieldConfigToUpdate.required = required);
  },

  updatePageConfigRequired(pageID: number, pageConfigRequired: boolean) {
    const pageConfigToUpdate = this.pageConfigs.find((pc: PageConfig) => {return pc.pageID === pageID;});
    pageConfigToUpdate && (pageConfigToUpdate.required = pageConfigRequired);
  },

  setPageConfigContext: noop,
};

export const PageConfigsContext = React.createContext<PageConfigsContextType>(PageConfigContextObj);
