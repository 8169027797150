import React, { useState, useEffect } from 'react';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi, GridReadyEvent, ICellRendererParams } from '@ag-grid-community/core';
import { EmployeeChangeLog } from 'core/models/MyInfoChangeLog';
import { ClientSideRowModelModule } from '@ag-grid-enterprise/all-modules';
import { SelectionChangeType } from './MyInfoChanges';
import { convToDateString } from 'utilities/utilities';

type PropTypes = {
  employeeChanges: EmployeeChangeLog[];
  activeTab: string;
  selectedEmployeeChanges: EmployeeChangeLog[];
  onSelectionChanged: (type: SelectionChangeType, selection: any[]) => void;
};

const EmployeeChangeLogComponent: React.FC<PropTypes> = ({
  employeeChanges,
  activeTab,
  selectedEmployeeChanges,
  onSelectionChanged,
}) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  useEffect(() => {
    if (
      employeeChanges?.length > 0 &&
            (gridApi?.getSelectedRows()?.length ?? 0) !==
                (selectedEmployeeChanges?.length ?? 0)
    ) {
      gridApi?.forEachNode((node) => {
        if (
          selectedEmployeeChanges?.some(
            (employeeChange) => {return employeeChange?.controlId === node.data.controlId;},
          )
        ) {
          if (node.isSelected() === false) {
            node.setSelected(true);
          }
        } else {
          if (node.isSelected() === true) {
            node.setSelected(false);
          }
        }
      });
    }
  }, [employeeChanges, activeTab, selectedEmployeeChanges, gridApi]);

  const selectionChanged = () => {return onSelectionChanged('EMPLOYEE', gridApi?.getSelectedRows() ?? []);};

  const columns: ColDef[] = [
    { field: 'fieldName' },
    {
      field: 'oldValue',
      minWidth: 250,
    },
    {
      field: 'newValue',
      minWidth: 250,
    },
    {
      field: 'dateMade',
      width: 150,
      cellRenderer: ({ data }: ICellRendererParams) => {
        return (
          <span>
            {convToDateString(data.dateMade)}, {new Date(data.dateMade).toLocaleTimeString()}
          </span>
        );
      },
    },
    {
      field: 'selectAll',
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
  ];
  
  return (
    <div
      className="ag-theme-balham"
      style={{ marginBottom: 10 }}
    >
      <div className="row m-1">
        <div
          className="table-wrapper-wrapper ag-theme-balham"
          style={{ height: '100px' }}
        >
          <AgGridReact
            rowData={employeeChanges}
            rowSelection={'multiple'}
            onGridReady={(params: GridReadyEvent) => { setGridApi(params.api); }}
            onSelectionChanged={selectionChanged}
            modules={[ClientSideRowModelModule]}
            columnDefs={columns}
            overlayNoRowsTemplate={
              '<span>No Changes to Review</span>'
            }
          >
            <AgGridColumn field="fieldName"></AgGridColumn>
            <AgGridColumn field="oldValue"></AgGridColumn>
            <AgGridColumn field="newValue"></AgGridColumn>
            <AgGridColumn field="dateMade" />
            <AgGridColumn
              field="SelectAll"
              headerCheckboxSelection={true}
              checkboxSelection={true}
            ></AgGridColumn>
          </AgGridReact>
        </div>
      </div>
    </div>
  );
};

export default EmployeeChangeLogComponent;
