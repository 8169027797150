import Icon from 'core/components/shared/Icon';
import PanelHeader from 'core/components/shared/PanelHeader';
import { ScorecardTrait } from 'core/models';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'utilities/hooks';
import { Row, Col } from 'react-bootstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridApi, GridOptions, ICellRendererParams } from '@ag-grid-community/core';
import { useDispatch } from 'react-redux';
import { deleteScorecardTrait, getScorecardTraits, storeScorecardTrait } from 'core/store/slices/applicantTracking.slice';
import ScorecardTraitModal from './ScorecardTrait.modal';

const ScorecardTraitPage = () => {
  
  const dispatch = useDispatch();
  const [scorecardTraits, scorecardTrait] = useAppSelector(
    (state) => {
      return [
        state.applicantTracking.scorecardTraits,
        state.applicantTracking.scorecardTrait,
      ];
    },
  );

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [rowData, setRowData] = useState<ScorecardTrait[]>([]);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [selectedTrait, setSelectedTrait] = useState<ScorecardTrait>();

  useEffect(()=>{
    dispatch(getScorecardTraits());
  }, []);
  
  useEffect(() => {
    if (!scorecardTrait) return;
    setSelectedTrait(scorecardTrait);
  }, [scorecardTrait]);

  useEffect(()=>{
    selectedTrait && setSelectedTrait(scorecardTraits.find(c => {
      return c.scorecardTraitId === selectedTrait.scorecardTraitId;
    }));
    scorecardTraits && setRowData(scorecardTraits.map(s => {
      return { ...s,
        rating1: s.rates[0].description,
        rating2: s.rates[1].description,
        rating3: s.rates[2].description,
        rating4: s.rates[3].description,
      };
    }));
  }, [scorecardTraits]);
  
  const onAdd = () => {
    setSelectedTrait(undefined);
    setShowDetailModal(true);
  };

  const openDetailModal = (params: ICellRendererParams) =>{
    setSelectedTrait(params.data);
    setShowDetailModal(true);
  };
  
  const onDeleteTrait = (params: ICellRendererParams) =>{
    dispatch(deleteScorecardTrait(params.data.scorecardTraitId));
  };

  const columns = useMemo<ColDef[]>(
    () => {
      return [
        {
          field: 'trait',
          headerName: 'Scorecard Trait',
          cellStyle: { textAlign: 'left' },
          cellRenderer: (params: ICellRendererParams) => {           
            return (
              <button
                type="button"
                className="btn btn-link"
                style={{ textAlign:'left' }}
                onClick={() => { return openDetailModal(params); }
                }
              >
                {params.data.trait}
              </button>             
            );
          },
          width: 210,
        },
        {
          field: 'rating1',
          headerName: 'Rating 1',         
          width: 250,         
        },  
        {
          field: 'rating2',
          headerName: 'Rating 2',         
          width: 250,         
        },  
        {
          field: 'rating3',
          headerName: 'Rating 3',         
          width: 250,         
        },  
        {
          field: 'rating4',
          headerName: 'Rating 4',         
          width: 250,         
        },  
        {
          field: 'delete',
          cellRenderer: (params: ICellRendererParams) => {                    
            return (
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={() => { return onDeleteTrait(params); }
                }
              >
                <Icon
                  name="minus-circle"
                  className="fa-minus-circle"
                />
              </button>             
            );            
          },
          width: 70,
        },     
      ];
    },
    [],
  );

  const gridOptions: GridOptions = {
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  return (
    <Row className="no-gutters p-0">
      <Col className="mt-1 shadow p-3 border">
        <PanelHeader
          title="Scorecard Trait"
          iconName="compass-drafting"         
        > 
          <button
            type="button"
            className="btn btn-link dm-grid-action-title pb-0 mr-2"
            onClick={onAdd}
          >
            Add New Trait <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <div className="table-wrapper-wrapper ag-theme-balham mt-3">
          <AgGridReact
            gridOptions={gridOptions}
            rowData={rowData}
            columnDefs={columns}
            onGridReady={onGridReady}
          />
        </div>
        {showDetailModal && (
          <ScorecardTraitModal
            item={selectedTrait}
            show={showDetailModal}
            onHide={() => {
              setShowDetailModal(false);
              dispatch(storeScorecardTrait(null));
            }}            
          />
        )}
      </Col>
    </Row>
  );
};

export default ScorecardTraitPage;
