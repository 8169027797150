import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, PercentCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { canvasPreview } from '../../../utilities/canvasPreview';
import { useDebounceEffect } from '../../../utilities/useDebounceEffect';
import addPhoto2 from '../../../assets/images/addphoto2.png';
import Modal from './Modal';

type PropTypes = {
  show: boolean;
  onHide: () => void;
  onSave: (fileData: any) => void;
  currentImage: string;
  onDelete?: (id: any) => void;
  canDelete?: boolean;
  deleteItemId?: number;
  onDownload?: (fileData: any) => void;
};


const PhotoUploadModal: React.FC<PropTypes> = ({ show, onHide, onSave, currentImage, onDelete, canDelete, deleteItemId, onDownload }) => {
  const [image, setImage] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [crop, setCrop] = useState<PixelCrop | PercentCrop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  useEffect(() => {
    if (imgRef?.current) {
      imgRef.current.src = currentImage ? currentImage : addPhoto2;
    }
  }, []);

  const handleFile = (file: any) => {
    if (file) {
      const reader = new FileReader();
      setCrop(undefined);
      reader.onload = e => {
        const photoData = e.target?.result?.toString().substring(22);
        if (photoData) {
          setImage(photoData);
        }
      };
      reader.readAsDataURL(file);

    }
    setPreviewUrl(URL.createObjectURL(file));
  };

  const handleOnDragOver = (e?: any) => {
    e.preventDefault();
  };

  const handleOnDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const imageFile = e.dataTransfer.files[0];
    handleFile(imageFile);
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    if (crop) {
      setCrop(centerCrop(crop as PercentCrop, width, height));
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0,
        );
      }

      const canvas = document.getElementById('canvas') as HTMLCanvasElement;
      const dataURL = canvas?.toDataURL('image/png', 'low');
      setImage(dataURL?.substring(22));
    },
    100,
    [completedCrop],
  );

  return (
    <Modal
      title="Photo"
      show={show}
      onHide={onHide}
    >
      {(closeModal) => {
        return (
          <>
            {!canDelete ? (
              <div>
                <div
                  className="drop_zone"
                  onDragOver={handleOnDragOver}
                  onDrop={handleOnDrop}
                  onClick={() => { return inputRef?.current?.click(); }}
                >
                  <p>Click or Drag and drop image here....</p>
                  <input
                    type="file"
                    ref={inputRef}
                    accept="image/png, image/gif, image/jpeg"
                    hidden
                    onChange={(e: any) => { return handleFile(e?.target?.files[0]); }}
                  />
                </div>
                {((previewUrl !== '') || (currentImage !== '')) && (
                  <div>
                    <p>Save or Select the area of the image to crop here....</p>
                    <div className="d-flex flex-row">
                      <ReactCrop crop={crop}
                        onChange={(_, percentCrop) => { return setCrop(percentCrop); }}
                        onComplete={(c) => { return setCompletedCrop(c); }}
                        aspect={1}>
                        <img
                          src={previewUrl || currentImage}
                          ref={imgRef}
                          alt={'Original'}
                          width="200"
                          height="200"
                          onLoad={onImageLoad} />
                      </ReactCrop>
                      <div className="ml-3">
                        {Boolean(completedCrop) && (
                          <canvas
                            id="canvas"
                            ref={previewCanvasRef}
                            width={completedCrop?.width}
                            height={completedCrop?.height}
                            style={{
                              objectFit: 'contain',
                              maxWidth: '200px',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {((previewUrl !== '') || (currentImage !== '')) &&
                  <img
                    src={previewUrl || currentImage}
                    ref={imgRef}
                    alt={'Original'}
                    width="200"
                    height="200"
                    onLoad={onImageLoad}
                  />
                }
              </div>
            )}
            <div className="row">
              <div className="col-12 text-right">
                {canDelete && onDelete && onDownload ?
                  <div>
                    <button type="button"
                      className="btn btn-primary orange-outline-button mr-2"
                      onClick={() => { onDelete(deleteItemId); }}>Delete</button>
                    <button type="button"
                      className="btn btn-primary orange-outline-button"
                      onClick={() => { onDownload(currentImage); closeModal(); }}>Download</button>
                  </div>
                  :
                  <div>
                    <button type="button"
                      className="btn btn-primary orange-outline-button mr-2"
                      onClick={() => { closeModal(); }}>Cancel</button>
                    <button type="button"
                      className="btn btn-primary orange-outline-button"
                      onClick={(e: any) => { e.stopPropagation(); onSave(image); closeModal(); }}>Save</button>
                  </div>
                }
              </div>
            </div>
          </>
        );
      }}
    </Modal>
  );
};

export default PhotoUploadModal;