import { Dropdown } from './Dropdown.model';

export interface DownloaderRequest {
  userMapId: number;
  clientNo: number | string;
  prYear: number;
  filePath: string;
  isEarningsDownload: boolean;
  isEarningsDownloadSummary: boolean;
  isHRDownload: boolean;
  beginDate: string | null;
  endDate: string | null;
  downloadFileTypeAs: string[] | number[];
  employeesOption: string | number;
  clientNumbers?: string[];
  writeToSelectedClientLibraries?: string[];
  printToClientLibrary: boolean;
}

export interface DownloaderResponse {
  clientNo: number,
  employeeOption: string,
  errors: string[],
  fileInfos: DownloaderFileInfoResponse[],
  hasErrors: boolean,
  userMapId: number
}

export interface DownloaderFileInfoResponse {
  fileData: string,
  filePath: string,
  fileType: string
}

export interface UserMap {
  currentClientsUserMaps: UserMapItem[];
  dmpsDownloadMaps: UserMapItem[];
  myDownloadMaps: UserMapItem[];
}

export interface UserMapItem {
  userMapId: number;
  mapId: number;
  userName?: string;
  description?: string;
  isEarningsDownload: boolean;
  isHrDownload: boolean;
  updateExportMapFields: boolean; //This should only be set from the FormatExcelForm dialog
  earningsMapType: string | null;
  font?: UserMapFont;
  headerFooter?: HeaderFooter;
  downloadSchedule?: DownloadSchedule;
  selectedFields?: UserMapSelectedField[];
  emails?: UserMapEmail[];
  availableFonts?: { name: string }[];
  excelFormula?:string;
}

export interface UserMapEmail {
  emailId: number;
  emailAddressId?: number;
  email?: string;
}

export interface EmailRequest {
  userMapId: number;
  emails: UserMapEmail[];
}

export interface CopyMapRequest {
  userMapId: number;
  clientNo: number;
  userNames?: string[];
}

export interface UserMapSelectedField {
  selectedAreaId: number;
  section?: string;
  fieldName: string;
  format?: UserMapFormat | null;
  font?: UserMapFont | null;
  filter?: UserMapFilter | null;
}

export interface UserMapFilter {
  filterId: number;
  code: string;
  description: string;
}

export interface AvailableFont {
  fontName:string;
}

export interface DownloaderPage {
  pageName: string;
  sections: PageSection[];
}

export interface PageSection {
  name: string;
  fields: DownloaderField[];
}

export interface DownloaderField {
  name: string;
  key?: string;
  fieldSectionKey?: string;
}

export interface UserMapFormat {
  description?: string;
  fieldName?: string;
  order: number;
  columnHeader?: string;
  columnHeaderSetter: boolean;
  justification: string;
  style: string;
  customFormatting: string;
  sortDirection: string;
  sortOrder: number;
  sortFunction: string;
  filterCondition: string;
  filterValue: string;
  filterLogic: string;
  filterOverride: string;
  formula: string;
  groupBy: boolean;
  groupByHeaderDesc: string;
  groupBySubFooterDesc: string;
  groupByTotalFooterDesc: string;
  groupByFunction: number;
  columnWidth: number;
  showColumn: boolean;
  excelTextCasing: string;
  excelJustification: string;
}

export interface FormatExportRequest {
  userMapId: number;
  format: UserMapFormat;
}

export interface HeaderFooter {
  headerLeft?: string;
  headerCenter?: string;
  headerRight?: string;
  footerLeft?: string;
  footerCenter?: string;
  footerRight?: string;
}

export interface UserMapFont {
  font?: string;
  fontStyle?: string;
  fontSize?: number;
  effects?: boolean;
  underline?: boolean;
  applyAll?: boolean;
}

export const fontStyleOpts = [
  {
    id: 'Regular',
    description: 'Regular',
  },
  {
    id: 'Bold',
    description: 'Bold',
  },
  {
    id: 'Italic',
    description: 'Italic',
  },
  {
    id: 'Underline',
    description: 'Underline',
  },
  {
    id: 'Strikeout',
    description: 'Strikeout',
  },
];

export const fontSizeeOpts = [
  {
    id: 8,
    description: '8',
  },
  {
    id: 9,
    description: '9',
  },
  {
    id: 10,
    description: '10',
  },
  {
    id: 11,
    description: '11',
  },
  {
    id: 12,
    description: '12',
  },
  {
    id: 14,
    description: '14',
  },
  {
    id: 16,
    description: '16',
  },
  {
    id: 18,
    description: '18',
  },
  {
    id: 20,
    description: '20',
  },
  {
    id: 22,
    description: '22',
  },
  {
    id: 24,
    description: '24',
  },
  {
    id: 26,
    description: '26',
  },
  {
    id: 28,
    description: '28',
  },
  {
    id: 36,
    description: '36',
  },
  {
    id: 48,
    description: '48',
  },
  {
    id: 72,
    description: '72',
  },
];

export interface DownloadSchedule {
  userMapId: number;
  scheduleFrequency: string;
  filter: string;
  selectedClients?: number[];
}

export interface ScheduledDownloadRequest {
  clientNo: number;
  userMapId: number;
  scheduleFrequency: string;
  filter: string;
  selectedClients: number[];
}

export const scheduleFrequencyOptsAll: Dropdown[] = [
  {
    id: 'NotScheduled',
    description: 'NotScheduled',
  },
  {
    id: 'PayrollComplete',
    description: 'PayrollComplete',
  },
  {
    id: 'EmpMasterSaved',
    description: 'EmpMasterSaved',
  },
  {
    id: 'TransmittalBalanced',
    description: 'TransmittalBalanced',
  },
  {
    id: 'EmpMasterDownload',
    description: 'EmpMasterDownload',
  },
  {
    id: 'EarningsDownload',
    description: 'EarningsDownload',
  },
  {
    id: 'PayrollFailed',
    description: 'PayrollFailed',
  },
  {
    id: 'ACHCorrection',
    description: 'ACHCorrection',
  },
  {
    id: 'EmpMasterRateChange',
    description: 'EmpMasterRateChange',
  },
  {
    id: 'BankPullNotification',
    description: 'BankPullNotification',
  },
  {
    id: 'PayrollNewsLetter',
    description: 'PayrollNewsLetter',
  },
  {
    id: 'TripMonthEnd',
    description: 'TripMonthEnd',
  },
  {
    id: 'TripQuaterEnd',
    description: 'TripQuaterEnd',
  },
  {
    id: 'TripYearEnd',
    description: 'TripYearEnd',
  },
  {
    id: 'NewPREnhancements',
    description: 'NewPREnhancements',
  },
  {
    id: 'ReminderEmailToProcessPayroll',
    description: 'ReminderEmailToProcessPayroll',
  },
  {
    id: 'EmailAddressOfPersonSigningPOA',
    description: 'EmailAddressOfPersonSigningPOA',
  },
  {
    id: 'W2ContactPerson',
    description: 'W2ContactPerson',
  },
  {
    id: 'SendEmail',
    description: 'SendEmail',
  },
  {
    id: 'IRSRevProcedureQrtlyNotification',
    description: 'IRSRevProcedureQrtlyNotification',
  },
  {
    id: 'BankAccountOrRoutingNumChange',
    description: 'BankAccountOrRoutingNumChange',
  },
  {
    id: 'ATSApplicationEmail',
    description: 'ATSApplicationEmail',
  },
  {
    id: 'MyInfoChangesEmail',
    description: 'MyInfoChangesEmail',
  },
  {
    id: 'DailyDownloader2am',
    description: 'DailyDownloader2am',
  },
  {
    id: 'ACAMonthlyNotification',
    description: 'ACAMonthlyNotification',
  },
];

export const earningsMapTypesOpts: Dropdown[] = [
  {
    id: 'EmployeeEarningsDetail',
    description: 'EmployeeEarningsDetail',
  },
  {
    id: 'EmployeeEarningsSummary',
    description: 'EmployeeEarningsSummary',
  },
  {
    id: 'EmployeeEarningsTransaction',
    description: 'EmployeeEarningsTransaction',
  },
];

export const justifyOpts: Dropdown[] = [
  {
    id: 'Center',
    description: 'Center',
  },
  {
    id: 'LeftJustify',
    description: 'Left',
  },
  {
    id: 'RightJustify',
    description: 'Right',
  },
];

export const styleOpts: Dropdown[] = [
  {
    id: 'Text',
    description: 'Text',
  },
  {
    id: 'Numeric',
    description: 'Numeric',
  },
  {
    id: 'Date',
    description: 'Date',
  },
  {
    id: 'Formula',
    description: 'Formula',
  },
];

export const formatNumericOpts: Dropdown[] = [
  {
    id: '###0.00',
    description: '###0.00',
  },
  {
    id: '#,##0.00',
    description: '#,##0.00',
  },
  {
    id: '###0.0000',
    description: '###0.0000',
  },
  {
    id: '#,##0.0000',
    description: '#,##0.0000',
  },
];

export const formatDateOpts: Dropdown[] = [
  {
    id: 'MM/dd/yy',
    description: 'MM/dd/yy',
  },
  {
    id: 'M/d/yyyy',
    description: 'M/d/yyyy',
  },
  {
    id: 'M/d/yy',
    description: 'M/d/yy',
  },
  {
    id: 'MM-dd-yyyy',
    description: 'MM-dd-yyyy',
  },
  {
    id: 'M-d-yyyy',
    description: 'M-d-yyyy',
  },
  {
    id: 'M-d-yy',
    description: 'M-d-yy',
  },
  {
    id: 'dd/MM/yyyy',
    description: 'dd/MM/yyyy',
  },
  {
    id: 'd/M/yyyy',
    description: 'd/M/yyyy',
  },
];

export const formatTextOpts: Dropdown[] = [
  {
    id: 'NotUsed',
    description: 'None',
  },
  {
    id: 'LowerCase',
    description: 'Lower Case',
  },
  {
    id: 'UpperCase',
    description: 'Upper Case',
  },
];

export const sortTypeOpts: Dropdown[] = [
  {
    id: 'NotUsed',
    description: ' ',
  },
  {
    id: 'Ascending',
    description: 'Ascending',
  },
  {
    id: 'Descending',
    description: 'Descending',
  },
];

export const logicOpts: Dropdown[] = [
  {
    id: 'and',
    description: 'and',
  },
  {
    id: 'or',
    description: 'or',
  },
];

export const pdfGroupByOpts = [
  {
    id: false,
    description: '',
  },
  {
    id: true,
    description: 'Yes',
  },
];

export const pdfGroupByFNOpts = [
  {
    id: 0,
    description: '',
  },
  {
    id: 1,
    description: 'Sum',
  },
  {
    id: 2,
    description: 'Count',
  },
];

export const pdfShowColumnOpts = [
  {
    id: true,
    description: 'Yes',
  },
  {
    id: false,
    description: 'No',
  },
];

export const filterConditionOpts: Dropdown[] = [
  {
    id: '=',
    description: 'Equal',
  },
  {
    id: '<>',
    description: 'Does not equal',
  },
  {
    id: '>',
    description: 'Is greater than',
  },
  {
    id: '>=',
    description: 'Is greater than or equal to',
  },
  {
    id: '<',
    description: 'Is less than',
  },
  {
    id: '<=',
    description: 'Is less than or equal to',
  },
  {
    id: '*%',
    description: 'Begins with',
  },
  {
    id: '!*%',
    description: 'Does not begin with',
  },
  {
    id: '%*',
    description: 'Ends with',
  },
  {
    id: '!%*',
    description: 'Does not end with',
  },
  {
    id: '%*%',
    description: 'Contains',
  },
  {
    id: '!%*%',
    description: 'Does not contain',
  },
  {
    id: 'IN',
    description: 'Include',
  },
  {
    id: 'NOT IN',
    description: 'Does not include',
  },
  {
    id: 'BETWEEN',
    description: 'From - To',
  },
];

export const downloadByOpts: Dropdown[] = [
  {
    id: 'All',
    description: 'All Employees',
  },
  {
    id: 'Active',
    description: 'Active Employees',
  },
  {
    id: 'Terminated',
    description: 'Terminated Employees',
  },
];

export const scheduleFrequencyOpts: Dropdown[] = [
  {
    id: 'NotScheduled',
    description: 'Not Scheduled',
  },
  {
    id: 'PayrollComplete',
    description: 'Payroll Complete',
  },
  {
    id: 'TripMonthEnd',
    description: 'Month End',
  },
  {
    id: 'TripQuarterEnd',
    description: 'Quarter End',
  },
  {
    id: 'TripYearEnd',
    description: 'Year End',
  },
  {
    id: 'Daily',
    description: 'Daily',
  },
];

export const scheduleEmpTypeOpts: Dropdown[] = [
  {
    id: 'All',
    description: 'All',
  },
  {
    id: 'Active',
    description: 'Active',
  },
  {
    id: 'Terminated',
    description: 'Terminated',
  },
];