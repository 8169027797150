import { AxiosResponse } from 'axios';
import { CalculateHourlySalaryAnnualPayRates, DeleteEmployeePayRateRequest, EmployeePayRateScreen, FuturePayRate, HourlySalaryAnnualPayRates, HttpResponse, PayRate, PayRateHistory } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getPayRates = (empNo: string): Promise<AxiosResponse<PayRate[]>> => {
  return API_EMPLOYEE_MASTER.get<PayRate[]>(EmployeeEndPoints.GET_PAY_RATES(CommonService.getProtectedClientNo(), empNo));
};

const getPayRate = (empNo: string, rateId: number): Promise<AxiosResponse<PayRate>> => {
  return API_EMPLOYEE_MASTER.get<PayRate>(EmployeeEndPoints.GET_PAY_RATE(CommonService.getProtectedClientNo(), empNo, rateId));
};

const getEmployeePayRatesScreen = (empNo: string): Promise<AxiosResponse<EmployeePayRateScreen>> => {return API_EMPLOYEE_MASTER.get<EmployeePayRateScreen>(EmployeeEndPoints.GET_EMPLOYEE_PAY_RATES_SCREEN(CommonService.getProtectedClientNo(), empNo));};

const addUpdatePayRatesScreen = (payRate: EmployeePayRateScreen): Promise<AxiosResponse<HttpResponse<EmployeePayRateScreen>>> => {return API_EMPLOYEE_MASTER.post<HttpResponse<EmployeePayRateScreen>>(EmployeeEndPoints.POST_EMPLOYEE_PAY_RATES_SCREEN(CommonService.getProtectedClientNo(), payRate.protectedEmpNo), payRate);};

const postPayRate = (payRate: PayRate, skipRaiseDate: boolean): Promise<AxiosResponse<HttpResponse<PayRate>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<PayRate>>(EmployeeEndPoints.POST_PAY_RATE(CommonService.getProtectedClientNo(), payRate.protectedEmpNo, skipRaiseDate), payRate);
};

const putPayRate = (payRate: PayRate, skipRaiseDate: boolean): Promise<AxiosResponse<HttpResponse<PayRate>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<PayRate>>(EmployeeEndPoints.PUT_PAY_RATE(CommonService.getProtectedClientNo(), payRate.protectedEmpNo, payRate.rateId, skipRaiseDate), payRate);
};

const calculatePayRates = (payRate: CalculateHourlySalaryAnnualPayRates): Promise<AxiosResponse<HttpResponse<HourlySalaryAnnualPayRates>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<HourlySalaryAnnualPayRates>>(EmployeeEndPoints.POST_CALCULATE_PAY_RATES(CommonService.getProtectedClientNo()), payRate);
};

const deletePayRate = (payRate: DeleteEmployeePayRateRequest): Promise<AxiosResponse<any>> => {return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.DELETE_PAY_RATE(CommonService.getProtectedClientNo(), payRate.protectedEmpNo, payRate.rateId));};

const getPayRatesHistory = (empNo: string): Promise<AxiosResponse<PayRateHistory[]>> => {
  return API_EMPLOYEE_MASTER.get<PayRateHistory[]>(EmployeeEndPoints.GET_PAY_RATES_HISTORY(CommonService.getProtectedClientNo(), empNo));
};

const putPayRatesHistory = (empNo: string, data: PayRateHistory[]): Promise<AxiosResponse<PayRateHistory[]>> => {
  return API_EMPLOYEE_MASTER.put<PayRateHistory[]>(EmployeeEndPoints.GET_PAY_RATES_HISTORY(CommonService.getProtectedClientNo(), empNo), data);
};

const getFuturePayRates = (empNo: string, rateId: number): Promise<AxiosResponse<any[]>> => {
  return API_EMPLOYEE_MASTER.get<any[]>(EmployeeEndPoints.GET_FUTURE_PAY_RATES(CommonService.getProtectedClientNo(), empNo, rateId));
};

const postFuturePayRate = (payRate: FuturePayRate): Promise<AxiosResponse<HttpResponse<FuturePayRate>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<FuturePayRate>>(EmployeeEndPoints.POST_FUTURE_PAY_RATE(CommonService.getProtectedClientNo(), payRate.protectedEmpNo), payRate);
};

const putFuturePayRate = (payRate: FuturePayRate): Promise<AxiosResponse<HttpResponse<FuturePayRate>>> => {
  return API_EMPLOYEE_MASTER.put<HttpResponse<FuturePayRate>>(EmployeeEndPoints.PUT_FUTURE_PAY_RATE(CommonService.getProtectedClientNo(), payRate.protectedEmpNo, payRate.rateId), payRate);
};

const deleteFuturePayRate = (empNo: string, rateId: number): Promise<AxiosResponse<any>> => {
  return API_EMPLOYEE_MASTER.delete<any>(EmployeeEndPoints.DELETE_FUTURE_PAY_RATE(CommonService.getProtectedClientNo(), empNo, rateId));
};

export const PayRateService = {
  getPayRates,
  getPayRate,
  getEmployeePayRatesScreen,
  addUpdatePayRatesScreen,
  postPayRate,
  putPayRate,
  deletePayRate,

  getPayRatesHistory,
  putPayRatesHistory,

  getFuturePayRates,
  postFuturePayRate,
  putFuturePayRate,
  deleteFuturePayRate,
  calculatePayRates,
};
