export enum UserRoles {
  'GRP_EMPMASTER',
  'GRP_TRANSMITTAL',
  'GRP_REPORTS',
  'GRP_CORRECTIONS',
  'GRP_HUMANRESOURCES',
  'GRP_LIBRARY',
  'GRP_EMPINFO',
  'GRP_DOWNLOAD',
  'GRP_ELETRONICDOCUMENT',
}

export enum EmpMasterAccesses {
  'LI_EMPLOYEELIST',
  'LI_EMPLOYEELOAD',
  'LI_EMPLOYEEADD',
  'LI_EMPLOYEEUPLOAD',
  'LI_EMPLOYEEEXPORT',
  'LI_EMPLOYEELISTGLOBALDEDCHANGE',
  'LI_EMPLOYEEQUICKONBOARD',
}

export enum TransmittalAccesses {
  'LI_PAYROLLHISTORY',
  'LI_UPLOAD',
  'LI_OPENCURRENTTRANSMITTAL',
  'LI_VENDORTRANSMITTAL',
  'LI_SALESTAXTRANSMITTAL',
  'LI_JOBCOSTTIMESHEETS',
}

export enum CorrectionsAccesses {
  'LI_DEPTALLOC',
  'LI_MANCODECOR',
  'LI_CHECKCALC',
  'LI_WORKERCOMPCORRECTION',
  'LI_EEOINFOCORRECTION',
}

export enum HumanRescourcesAccesses {
  'LI_HR_EMPLOYEELIST',
  'LI_HR_EMP_STATUS',
  'LI_HR_BENEFIT',
  'LI_HR_FMLA',
  'LI_HR_PERFORMANCE',
  'LI_HR_TRAINING',
  'LI_HR_DOWNLOAD',
  'LI_HR_EMP_HIRE',
}

export enum LibraryAccesses {
  'LI_LIBRARY',
}

export enum DownloadsAccesses {
  'LI_DOWNLOADEMPEARNINGS',
  'LI_DOWNLOADEMPMASTER',
  'TaskListItemDownloadStandard3YearPayrateHistory',
  'TaskListItemDownloadStandardMoCashFlowAnalysis',
  'LI_DOWNLOADEMPPAYRATE',
  'TaskListItemDownloadStandardContractorFringe',
  'TaskListItemDownloadEmpWageDetails',
  'TaskListItemDownloadContractorOverview',
  'LI_401KDOWNLOAD63934',
  'LI_DOWNLOADHR',
  'LI_RPT_CUSTOMPADNOSHSA',
  'LI_RPT_ADG_PAYCATEGORYHIST',
}

export enum ElectronicDocumentAccesses {
  'LI_MANAGEDOCLIBRARY',
}