import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ActiveFrequencies, CardEditProps } from './types';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { ControlDatePickerGrp, SelectGrp } from 'core/components/form-controls';
import { Payroll, PayrollDateline, TransmittalSortOrder } from 'core/models';
import { payrollFrequencies } from 'dropdowns/payrollFrequencies';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { UNSAVED_MESSAGE } from 'core/constants';
import RecurringEarningsPromptModal from './RecurringEarningsPrompt.modal';
import { handleError, loadTransmittalEmployees, PayrollUpdateRequest, putPayrollDateline, toggleBlockNavigation } from 'core/store/actions';
import { CommonService, PayrollService } from 'core/services';
import PayrollValidationErrorModal from '../modals/PayrollValidationErrorModal';

const currentYear = new Date().getFullYear();

const fs: FieldInputSettings = {
  weekEnd: {
    name: 'weekEnd',
    label: 'Week End',
    labelWidth: 25,
  },
  checkDate: {
    name: 'checkDate',
    label: 'Check Date',
    labelWidth: 25,
  },
  nextWeekEnd: {
    name: 'nextWeekEnd',
    label: 'Next Week End',
    labelWidth: 25,
  },
  nextCheckDate: {
    name: 'nextCheckDate',
    label: 'Next Check Date',
    labelWidth: 25,
  },
  prFrequency: {
    name: 'prFrequency',
    label: 'Frequency',
    labelWidth: 25,
  },
};

const payrollFrequencyOptions = payrollFrequencies;

// TODO: this should really be its own exported type + a service
type PayrollValidationError = {
  statusCode: string;
  messages: string[];
  value: null;
};

type AddRemove = {
  add: boolean;
  remove: boolean;
};

const CardEdit = ({ payroll, mobileView, exit }: CardEditProps) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [activeFrequencies, setActiveFrequencies] = useState<ActiveFrequencies>();
  const [includedEarnings, setIncludedEarnings] = useState<AddRemove>({ add: false, remove: false });
  const [validationError, setValidationError] = useState<PayrollValidationError | null>(null);
  const [validatedDateline, setValidatedDateline] = useState<PayrollUpdateRequest | null>(null);
  
  const orderTransmittalBy = useAppSelector((state) => state.client.clientOptions.options?.[34]?.optionValue);
  const { clientRecurringEarnings, transmittalEmployees } = useAppSelector(({ payroll }) => payroll);
  
  const { control, register, handleSubmit, formState } = useForm<Payroll>({
    defaultValues: {
      ...payroll,
      weekEnd: payroll.weekEnd,
      checkDate: payroll.checkDate,
      nextWeekEnd: payroll.nextWeekEnd,
      nextCheckDate: payroll.nextCheckDate,
      prFrequency: payroll.prFrequency,
    },
  });
  const { isDirty } = formState;
  
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if (!payroll) return;
    dispatch(
      loadTransmittalEmployees({ params: {
        payrollHistoryId: payroll?.payrollHistoryId,
        controlTotalId: 0,
      }, orderBy: orderTransmittalBy as TransmittalSortOrder }),
    );
  }, [payroll]);
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [isDirty]);
  
  // check current option
  const currentFrequencyMatch = clientRecurringEarnings.find((earning) => {
    return earning.frequency === payroll.prFrequency && transmittalEmployees.find(x => x.empNo === earning.empNo );
  });
  
  const onFrequencyChange = (e: BaseSyntheticEvent) => {
    // check the option selected
    const nextFrequencyMatch = clientRecurringEarnings.find((earning) => {
      return earning.frequency === e.target.value && transmittalEmployees.find(x => x.empNo === earning.empNo );
    });
    
    if ((currentFrequencyMatch || nextFrequencyMatch)) {
      setActiveFrequencies({
        current: !!currentFrequencyMatch,
        next: !!nextFrequencyMatch,
        currentId: payroll.prFrequency,
        nextId: e.target.value,
      });
      setShowPrompt(true);
    }
  };
  
  const onSave = (saveData: Payroll) => {
    const updatedPayroll: Payroll = {
      ...payroll,
      ...saveData,
    };
    
    const validateRequestObj: PayrollDateline = {
      clientNo: updatedPayroll.clientNo,
      protectedClientNo: CommonService.getProtectedClientNo(),
      weekEnd: updatedPayroll.weekEnd,
      checkDate: updatedPayroll.checkDate,
      nextWeekEnd: updatedPayroll.nextWeekEnd,
      nextCheckDate: updatedPayroll.nextCheckDate,
      prFrequency: updatedPayroll.prFrequency,
      populateTransmittal: false, // TODO: I don't think this matters here, but we'll see
      useNewCheckRegister: updatedPayroll.useNewCheckRegister,
    };
    
    const updateRequestObj: PayrollUpdateRequest = {
      payrollHistoryId: payroll.payrollHistoryId,
      data: updatedPayroll,
      removeRecurringEarnings: includedEarnings.remove,
      addRecurringEarnings: includedEarnings.add,
    };
    
    PayrollService.postValidatePayroll(validateRequestObj)
      .then((_res) => {
        dispatch(putPayrollDateline(updateRequestObj));
        exit();
      })
      .catch((err) => {
        setValidatedDateline(updateRequestObj);
        setValidationError(err);
      });
  };
  
  const handleConfirmCheckDate = (confirm: boolean) => {
    if (!validatedDateline) return; // TODO: what we returning though

    setValidationError(null);
    setValidatedDateline(null);

    if (confirm) {
      dispatch(putPayrollDateline(validatedDateline));
      exit();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSave)}>        
        <div className="payroll-card-body">
          <div className="d-flex flex-column">
            <div className={`d-flex ${mobileView ? 'flex-column' : ''}`}>
              <ControlDatePickerGrp
                {...fs.weekEnd}
                control={control}
                fastForward
                required
              />
              <ControlDatePickerGrp
                {...fs.checkDate}
                control={control}
                fastForward
                required
              />
              <ControlDatePickerGrp
                {...fs.nextWeekEnd}
                control={control}
                fastForward
                required
                minDate={new Date(`01/01/${currentYear - 1}`)}
                maxDate={new Date(`12/31/${currentYear + 1}`)}
              />
              <ControlDatePickerGrp
                {...fs.nextCheckDate}
                control={control}
                fastForward
                required
                minDate={new Date(`01/01/${currentYear - 1}`)}
                maxDate={new Date(`12/31/${currentYear + 1}`)}
              />
              <SelectGrp
                {...fs.prFrequency}
                valueField={'value'}
                options={payrollFrequencyOptions}
                onChange={onFrequencyChange}
                ref={register}
                control={control}
                required
              />
            </div>          
            <div className="d-flex justify-content-end">          
              <button
                className="btn btn-sm orange-outline-button-sm"
                onClick={exit}
              >
                Cancel
              </button>
              <button
                className="btn btn-sm btn-primary orange-button-sm"
                type="submit"
                disabled={!isDirty}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
      {showPrompt && activeFrequencies ? (
        <RecurringEarningsPromptModal
          setIncludeEarnings={setIncludedEarnings}
          show={showPrompt}
          onHide={() => {return setShowPrompt(false);}}
          activeFrequencies={activeFrequencies}
        />
      ) : null}
      {!!validationError && (
        <PayrollValidationErrorModal
          show={!!validationError}
          onHide={handleConfirmCheckDate}
          validationError={validationError}
        />
      )}
    </>
  );
};

export default CardEdit;