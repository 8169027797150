import { RadioGrp } from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import Modal from 'core/components/modals/Modal';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { CorrectionsReportRequest, CorrectionsReportType } from 'core/models';
import { CommonService } from 'core/services';
import { loadDeptAllocationReport, storeDeptAllocationReport, storeReportRequestData } from 'core/store/actions';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

type Props = {
  title: string;
  allocationReportType: CorrectionsReportType;
  show: boolean;
  onHide: () => void;
};

const fs: FieldInputSettings = {
  reportType: {
    name: 'reportType',
    label: 'Report file type:',
    defaultChecked: 'Pdf',
    groupClass: 'gc95',
    radioOptions: [
      {
        value: 'Pdf',
        label: 'Pdf',
      },
      {
        value: 'Excel',
        label: 'Excel',
      },
    ],
  },
};

const DeptAllocationReportModal = ({ title, allocationReportType, show, onHide }: Props) => {
  const [reportFileType, setReportFileType] = useState<'pdf' | 'excel'>('pdf');
  const [showReportPDF, setShowReportPDF] = useState(false);
  
  const { register } = useForm<any>({ defaultValues: { reportType: reportFileType } });
  
  const dispatch = useAppDispatch();
  
  const reportInfo = useAppSelector((state) => {return state.corrections.reportRequestData;});
  const masterEmployees = useAppSelector((state) => {return state.employees.employees;});
  const allocationReport = useAppSelector((state) => {return state.corrections.allocationReport;});
  
  const protectedEmpNo = useMemo(() => {
    const match = masterEmployees.find((employee) => {return employee.empNo === reportInfo?.selectedEmpNo;});
    
    return match?.protectedEmpNo;
  }, [reportInfo?.selectedEmpNo]);
  
  useEffect(() => {
    if (!(reportInfo && protectedEmpNo)) return;
    dispatch(loadDeptAllocationReport({
      beginDate: reportInfo.beginDate,
      endDate: reportInfo.endDate,
      clientNo: reportInfo.clientNo,
      selectedEmpNo: reportInfo.selectedEmpNo,
      reportType: reportFileType,
      allocationReportType: allocationReportType,
    }));
  }, [reportInfo, reportFileType, protectedEmpNo]);
  
  const close = () => {
    if (allocationReport?.length) {
      dispatch(storeDeptAllocationReport(''));
    }
    onHide();
  };
  
  const downloadExcelFile = () => {
    CommonService.downloadBase64File(
      'application/excel',
      allocationReport,
      `${allocationReportType}.xlsx`,
    );
  };

  return (
    <>
      <Modal
        size="sm"
        title={'Department Allocation Report'}
        show={show}
        onHide={close}
      >
        {() => {
          return (
            <div className="d-flex flex-column">
              <RadioGrp
                {...fs.reportType}
                onChange={(e: any) => {
                  setReportFileType(e.target.value.toLowerCase());
                }}
                ref={register}
                name="rdFileType"
              />
              <div className="d-flex w-100 justify-content-end">
                {reportFileType === 'pdf' ? (
                  <button
                    type="button"
                    className="btn btn-primary orange-button"
                    onClick={() => {
                      setShowReportPDF(true);
                    }}
                    disabled={!allocationReport?.length}
                  >
                    Preview
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary orange-button"
                    onClick={() => {
                      downloadExcelFile();
                    }}
                    disabled={!allocationReport?.length}
                  >
                    Download
                  </button>
                )}
              </div>
            </div>
          );
        }}
      </Modal>
      {showReportPDF && allocationReport.length ? (
        <PDFViewerModal
          show={showReportPDF}
          onHide={() => {return setShowReportPDF(false);}}
          pdfData={allocationReport}
          reportName=""
        />
      ) : null}
    </>
  );
};

export default DeptAllocationReportModal;