import Icon from 'core/components/shared/Icon';
import React, { useState } from 'react';
import { useAccordionToggle } from 'react-bootstrap';
import styles from './nested-accordion.module.scss';

type PropTypes = {
  eventKey: any;
  title: string;
  containerClass?: string;
  iconClass?: string;
};

const Level2Toggle: React.FC<PropTypes> = ({
  eventKey,
  title,
  containerClass = styles['l2toggle-container'],
  iconClass = styles['l2toggle-icon'],
}) => {
  const [open, setOpen] = useState(false);
  const onClick = useAccordionToggle(eventKey, () => {
    setOpen(prev => { return !prev; });
  });

  return (
    <div className={containerClass}
      onClick={onClick} >
      <Icon name={open ? 'caret-down' : 'caret-right'}
        className={iconClass} />{title}
    </div>
  );
};

export default Level2Toggle;
