/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICellRendererParams } from '@ag-grid-community/core';
import { ddLookup } from '../utilities';

interface AGLookupRendererProps extends ICellRendererParams {
  options: any[];
  valueField?: string;
  labelField?: string;
  showId?: boolean;
}

export function agLookupRenderer(): any {}

agLookupRenderer.prototype.init = function (params: AGLookupRendererProps) {
  const vf = params.valueField ?? 'id';
  const df = params.labelField ?? 'description';
  const showId = params.showId ?? false;

  this.div = document.createElement('div');
  const result = ddLookup(params.value, params.options, vf, df);

  //-999 is the placeholder so if a dropdown is ever this just show it
  if(result === -999) this.div.innerHTML = '-999';
  else{
    this.div.innerHTML = result
    ? showId
      ? params.value + ' - ' + result
      : result
    : '';
  }
};

agLookupRenderer.prototype.getGui = function (_: AGLookupRendererProps) {
  return this.div;
};

agLookupRenderer.prototype.refresh = function (params: AGLookupRendererProps) {
  const vf = params.valueField ?? 'id';
  const df = params.labelField ?? 'description';
  const showId = params.showId ?? false;

  const result = ddLookup(params.value, params.options, vf, df);

  //-999 is the placeholder so if a dropdown is ever this just show it
  if(result === -999) this.div.innerHTML = '-999';
  else{
    this.div.innerHTML = result
    ? showId
      ? params.value + ' ' + result
      : result
    : '';
  }
  
  return true;
};

agLookupRenderer.prototype.destroy = function () {};
