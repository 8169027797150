import { Tab, Tabs } from 'react-bootstrap';
import PdfViewer from 'core/components/shared/PDFViewer/PdfViewer';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { PayrollPreviewPdf } from 'core/models';
import React, { useEffect } from 'react';
import { setPreviewTab } from 'core/store/actions';
import { currencyFormatter } from 'utilities/utilities';

type Props = {
  checkRegister?: boolean;
  showWireAgreement?: () => void;
};

const PayrollPreviewPdfModal = ({ checkRegister, showWireAgreement }: Props) => {
  const payrollPreviewResult = useAppSelector(({ payroll }) => { return payroll.payrollPreviewResult; });
  const checkRegisterPdfs = useAppSelector(({ payroll }) => { return payroll.payrollCheckRegister; });
  const activeTab = useAppSelector(({ payroll }) => { return payroll?.previewTab; });
  
  const dispatch = useAppDispatch();

  const reports = checkRegister ? checkRegisterPdfs : (payrollPreviewResult?.payrollReportData ?? []);
  
  useEffect(() => {
    return () => { dispatch(setPreviewTab(null)); };
  }, []);

  return (
    <div className="h-100 overflow-hidden">
      {(payrollPreviewResult?.mustWireAgreement) ? (
        <div style={{ display: 'flex' }}>
          <button
            type="button"
            className="btn btn-primary orange-button"
            style={{ marginLeft: 'auto' }}
            onClick={showWireAgreement}
          >
            Sign Wire Only Agreement - {currencyFormatter(payrollPreviewResult?.mustWireAgreement?.pullAmount ?? 0)}
          </button>
        </div>
      ) : null}
      {reports ? (
        <Tabs defaultActiveKey={activeTab ?? 'Detail'}> 
          {/* Will there ever be a different first tab? hmmm */}
          {reports.map(
            (payrollPreviewPdf: PayrollPreviewPdf, index: number) => {
              return payrollPreviewPdf.data ? (
                <Tab
                  key={index}
                  eventKey={payrollPreviewPdf.name}
                  title={payrollPreviewPdf.name}
                  tabClassName="h-100"
                >
                  <PdfViewer
                    pdfData={payrollPreviewPdf.data}
                    reportName={payrollPreviewPdf.name}
                    excelData={payrollPreviewPdf.excelData}
                    payrollPreview={true}
                    viewClass="modal-viewer"
                  />
                </Tab>
              ) : null;
            },
          )}
        </Tabs>
      ) : null}
    </div>
  );
};

export default PayrollPreviewPdfModal;
