import { RadioGrp } from 'core/components/form-controls';
import InputReportDateSelector from 'core/components/form-controls/InputReportDateSelector';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import Icon from 'core/components/shared/Icon';
import { DtoOptions } from 'core/models';
import { MemoCashFlowReportRequest } from 'core/models/PayrollReports.model';
import { CommonService } from 'core/services';
import { downloadMemoCashFlowReport, clearMemoCashFlowReport } from 'core/store/actions';
import { getMemoCashFlowReport } from 'core/store/selectors';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const memoCashFlowReportOpts: RadioOptions[] = [

  {
    value: 'Location',
    label: 'Location',
  },
  {
    value: 'MainDeptInAllLocations',
    label: 'Main Department in All Locations',
  },
  {
    value: 'DeptSubInAllLocations',
    label: 'Dept Sub in All Locations',
  },
  {
    value: 'LocationDeptSub',
    label: 'Location - Dept - Sub',
  },
  {
    value: 'AllLocationMainDeptSub',
    label: 'All Location - Main Dept - Sub',
  },
];

const MemoCashFlowReport = () => {


  const dispatch = useDispatch();

  const childRef: React.RefObject<any> = useRef();

  const report = useSelector(getMemoCashFlowReport);
  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [fileType, setFileType] = useState<string>('pdf');

  const { register, handleSubmit } =
    useForm<MemoCashFlowReportRequest>({
      defaultValues: {
        memoCashFlowReport: 'Location',

      },
    });


  const downloadReport = (data: MemoCashFlowReportRequest) => {
    const submitData: MemoCashFlowReportRequest = {
      clientNo: data.clientNo,
      dtoOptions: reportDates,
      memoCashFlowReport: data.memoCashFlowReport,
      reportType: fileType,
    };
    dispatch(downloadMemoCashFlowReport(submitData));
  };

  useEffect(() => {
    if (report && fileType === 'excel') {
      report && CommonService.downloadBase64File(
        'application/excel',
        report,
        'MemoCashFlowReport.xlsx',
      );
      dispatch(clearMemoCashFlowReport());
    }
  }, [report]);

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearMemoCashFlowReport());
  };

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">Memo Cash Flow Report</div>
          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title"
              onClick={() => { return setFileType('excel'); }}
            >
              Download Report{' '}
              <Icon name="download"
                className="fa-download" />
            </button>
          </div>
          <hr className="dm-panel-hr" />

          <div className="d-flex flex-row flex-grow-1">
            <div >
              <InputReportDateSelector
                ref={childRef}
                returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }}
              />
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <div className=" text-right">
                <button
                  type="button"
                  className="orange-outline-button mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="orange-button mr-2"
                  disabled={!reportDates.length}>
                  Run Report
                </button>
              </div>

              <div className="w-50 dm-panel dm-panel-border"
                style={{ marginTop: '66px' }}>
                <div className="dm-grid-action-title mb-2">Report Type</div>
                <div>

                  <div className="d-flex">
                    <div className="d-flex flex-row flex-grow-1">
                      <div className="d-flex flex-column flex-grow-1">
                        <div style={{}}>
                          <RadioGrp
                            isVertical={true}
                            name="memoCashFlowReport"
                            ref={register}
                            radioOptions={memoCashFlowReportOpts}
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </form>
      </div >
      {report && <PDFViewerModal
        show={report.length > 0
        }
        pdfData={report}
        onHide={hidePdfViewer}
        reportName="MemCashFlowReport"
      />}
    </div>
  );
};

export default MemoCashFlowReport;