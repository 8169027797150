import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { EEDComponentProps } from 'types/eed.types';
import CheckBoxForm from '../../Shared/CheckBoxForm';

// Custom Layout for Checkbox and Grid select forms
const AcaFieldsHr = ({ formMethods, sectionFields }: EEDComponentProps) => {
  return (
    <Row>
      {!!sectionFields?.length && (
        <Col xs={12}>
          <CheckBoxForm
            formMethods={formMethods}
            dFields={sectionFields.find(f => {
              return f.name === 'ACA Status HR';
            })?.fields}
            section="ACA Status HR"
            title="ACA Status"
          />
        </Col>
      )}
    </Row>
  );
};

export default AcaFieldsHr;