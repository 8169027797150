import Icon from 'core/components/shared/Icon';
import React, { useState } from 'react';
import { useAccordionToggle } from 'react-bootstrap';
import styles from './nested-accordion.module.scss';

type PropTypes = {
  eventKey: any;
  title: any;
  containerClass?: string;
};

const MenuToggle: React.FC<PropTypes> = ({
  eventKey,
  title,
  containerClass = styles['mt-container'],
}) => {
  const [openState, setOpenState] = useState(0);
  const onClick = useAccordionToggle(eventKey, (e) => {
    setOpenState(eventKey === openState ? null : eventKey);
  });

  return (
    <div className={containerClass}
      onClick={onClick}
      role="button">
      <div className="text-uppercase">
        <Icon name={openState ? 'chevron-down' : 'chevron-right'} />{title}
      </div>
    </div>
  );
};

export default MenuToggle;
