import { AxiosResponse } from "axios";
import { EmpGroupTerm, HttpResponse } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getEmployeeGroupTerm = (protectedEmpNo: string): Promise<AxiosResponse<EmpGroupTerm>> => {return API_EMPLOYEE_MASTER.get<EmpGroupTerm>(EmployeeEndPoints.EMPLOYEES_GROUP_TERM(CommonService.getProtectedClientNo(), protectedEmpNo));};

const putEmployeeGroupTerm = (data: EmpGroupTerm): Promise<AxiosResponse<HttpResponse<EmpGroupTerm>>> => {return API_EMPLOYEE_MASTER.put<HttpResponse<EmpGroupTerm>>(EmployeeEndPoints.EMPLOYEES_GROUP_TERM(CommonService.getProtectedClientNo(), data.protectedEmpNo), data);};

export const EmployeeGroupTermService = {
  getEmployeeGroupTerm,
  putEmployeeGroupTerm,
};