/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpResponse } from 'core/models';
import { Applicant, AvailableJob, ClientJobs, CustomEmail, DesignWebsite, InterviewLocation, InterviewProcess, 
  InterviewQuestion, InterviewTemplate, InterviewType, PageCustomField, ScorecardCategory, ScorecardTrait, TemplateRequest, TestEmail, WebsiteCustomWaiver, WebsiteImage } 
  from 'core/models/ApplicantTracking.model';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { handleError, handleSuccess } from '../actions';
import { resetLoading } from '../slices/downloader.slice';
import { ApplicantService } from 'core/services/applicant-tracking.service';
import {
  getJobs, storeJobs, getJob, storeJob,
  createJob, getApplicants, getApplicant, storeApplicants,
  storeApplicant, deleteJob, getInterviewProcesses, storeInterviewProcesses, storeSelectedJob, 
  getInterviewTemplates, storeInterviewTemplates, postInterviewTemplate, deleteInterviewTemplate, 
  postInterviewProcess, deleteInterviewProcess, getInterviewQuestions, storeInterviewQuestions, 
  getScorecardCategories, getInterviewTypes, storeScorecardCategories, getInterviewLocations, 
  storeInterviewLocations, storeInterviewTypes, deleteInterviewQuestion, postInterviewQuestion, 
  getInterviewQuestion, storeInterviewQuestion, getScorecardCategory, storeScorecardCategory, 
  getInterviewLocation, storeInterviewLocation, postInterviewLocation, deleteInterviewLocation, 
  storeInterviewTemplate, getInterviewTemplate, deleteInterviewType, postInterviewType, deleteScorecardCategory, 
  postScorecardCategory, deleteScorecardTrait, getScorecardTrait, getScorecardTraits, postScorecardTrait, 
  storeScorecardTrait, storeScorecardTraits, deleteCustomEmail, getCustomEmail, postCustomEmail, storeCustomEmail, 
  storeCustomEmails, getCustomEmails, testCustomEmail, postATSCustomField, getATSCustomFields, 
  deleteATSCustomField, putApplicantPagesField, deleteCustomWaiver, deleteWebsiteImage, postCheckForConfig, putApplicantWaivers, putSignatureDescription, putWebsiteImages, postCustomWaiver, postWebsiteImage, storeInterviewProcess, updateJobStatus,
} from '../slices/applicantTracking.slice';
import { getDesignWebsite } from '../actions/applicant-tracking.action';

interface Actions<T> {
  type: string;
  payload: T;
}

const getApplicants$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getApplicants.type),
    switchMap(() => {
      return from(ApplicantService.getApplicants()).pipe(
        map((res: any) => { return res.data; }),
        map((res: Applicant[]) => { 
          return storeApplicants(res); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getApplicant$ = (action$: Observable<Actions<number>>) => {
  return action$.pipe(
    ofType(getApplicant.type),
    switchMap((action: { payload: number; }) => {
      return from(ApplicantService.getApplicant(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: Applicant) => { return storeApplicant(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const putJobStatuses$ = (action$: Observable<Actions<ClientJobs>>) => {
  return action$.pipe(
    ofType(updateJobStatus.type),
    switchMap((action: { payload: ClientJobs; }) => {
      return from(ApplicantService.putJobStatuses(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<any>) => {
          return [
            getJobs(),
            storeSelectedJob(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getJobs$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getJobs.type),
    switchMap(() => {
      return from(ApplicantService.getJobs()).pipe(
        map((res: any) => { return res.data; }),
        map((res: ClientJobs) => { return storeJobs(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getJob$ = (action$: Observable<Actions<number>>) => {
  return action$.pipe(
    ofType(getJob.type),
    switchMap((action: { payload: number; }) => {
      return from(ApplicantService.getJob(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: AvailableJob) => { return storeJob(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postJob$ = (
  action$: Observable<Actions<AvailableJob>>,
) => {
  return action$.pipe(
    ofType(createJob.type),
    switchMap((action: { payload: AvailableJob; }) => {
      return from(ApplicantService.postJob(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<any>) => {
          return [
            getJobs(),
            storeSelectedJob(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const deleteJob$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(deleteJob.type),
    switchMap((action: { payload: number; }) => {
      return from(ApplicantService.deleteJob(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getJobs(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const getInterviewProcesses$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getInterviewProcesses.type),
    switchMap(() => {
      return from(ApplicantService.getInterviewProcesses()).pipe(
        map((res: any) => { return res.data; }),
        map((res: InterviewProcess[]) => { 
          return storeInterviewProcesses(res); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postInterviewProcess$ = (
  action$: Observable<Actions<InterviewProcess>>,
) => {
  return action$.pipe(
    ofType(postInterviewProcess.type),
    switchMap((action: { payload: InterviewProcess; }) => {
      return from(ApplicantService.postInterviewProcess(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            storeInterviewProcess(res.value),
            getInterviewProcesses(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const deleteInterviewProcess$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(deleteInterviewProcess.type),
    switchMap((action: { payload: number; }) => {
      return from(ApplicantService.deleteInterviewProcess(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getInterviewProcesses(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const getInterviewTemplates$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getInterviewTemplates.type),
    switchMap(() => {
      return from(ApplicantService.getInterviewTemplates()).pipe(
        map((res: any) => { return res.data; }),
        map((res: InterviewTemplate[]) => { 
          return storeInterviewTemplates(res); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getInterviewTemplate$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getInterviewTemplate.type),
    switchMap((action:{ payload:TemplateRequest }) => {
      return from(ApplicantService.getInterviewTemplate(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: InterviewTemplate) => { 
          return storeInterviewTemplate(res); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postInterviewTemplate$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(postInterviewTemplate.type),
    switchMap((action: { payload: TemplateRequest; }) => {
      return from(ApplicantService.postTemplate(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            storeInterviewTemplate(res.value),
            getInterviewTemplates(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};


const deleteInterviewTemplate$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(deleteInterviewTemplate.type),
    switchMap((action: { payload: TemplateRequest; }) => {
      return from(ApplicantService.deleteTemplate(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getInterviewTemplates(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const getInterviewQuestions$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getInterviewQuestions.type),
    switchMap(() => {
      return from(ApplicantService.getInterviewQuestions()).pipe(
        map((res: any) => { return res.data; }),
        map((res: InterviewQuestion[]) => { 
          return storeInterviewQuestions(res); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getInterviewQuestion$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getInterviewQuestion.type),
    switchMap((action: { payload:number }) => {
      return from(ApplicantService.getInterviewQuestion(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: InterviewQuestion) => { 
          return storeInterviewQuestion(res); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postInterviewQuestion$ = (
  action$: Observable<Actions<InterviewQuestion>>,
) => {
  return action$.pipe(
    ofType(postInterviewQuestion.type),
    switchMap((action: { payload: InterviewQuestion; }) => {
      return from(ApplicantService.postInterviewQuestion(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getInterviewQuestions(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};


const deleteInterviewQuestion$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(deleteInterviewQuestion.type),
    switchMap((action: { payload: number; }) => {
      return from(ApplicantService.deleteInterviewQuestion(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getInterviewQuestions(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};


const getInterviewLocations$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getInterviewLocations.type),
    switchMap(() => {
      return from(ApplicantService.getInterviewLocations()).pipe(
        map((res: any) => { return res.data; }),
        map((res: InterviewLocation[]) => { 
          return storeInterviewLocations(res); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getInterviewLocation$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getInterviewLocation.type),
    switchMap((action:{ payload:number }) => {
      return from(ApplicantService.getInterviewLocation(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: InterviewLocation) => { 
          return storeInterviewLocation(res); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postInterviewLocation$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(postInterviewLocation.type),
    switchMap((action: { payload: InterviewLocation; }) => {
      return from(ApplicantService.postInterviewLocation(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getInterviewLocations(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const deleteInterviewLocation$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(deleteInterviewLocation.type),
    switchMap((action: { payload: number; }) => {
      return from(ApplicantService.deleteInterviewLocation(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getInterviewLocations(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};


const getScorecardCategories$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getScorecardCategories.type),
    switchMap(() => {
      return from(ApplicantService.getScorecardCategories()).pipe(
        map((res: any) => { return res.data; }),
        map((res: ScorecardCategory[]) => { 
          return storeScorecardCategories(res); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getScorecardCategory$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getScorecardCategory.type),
    switchMap((action: { payload:number }) => {
      return from(ApplicantService.getScorecardCategory(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: ScorecardCategory) => { 
          return storeScorecardCategory(res); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postScorecardCategory$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(postScorecardCategory.type),
    switchMap((action: { payload: ScorecardCategory; }) => {
      return from(ApplicantService.postScorecardCategory(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            storeScorecardCategory(res.value),
            getScorecardCategories(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const deleteScorecardCategory$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(deleteScorecardCategory.type),
    switchMap((action: { payload: number; }) => {
      return from(ApplicantService.deleteScorecardCategory(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getScorecardCategories(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};


const getScorecardTraits$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getScorecardTraits.type),
    switchMap(() => {
      return from(ApplicantService.getScorecardTraits()).pipe(
        map((res: any) => { return res.data; }),
        map((res: ScorecardTrait[]) => { 
          return storeScorecardTraits(res); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getScorecardTrait$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getScorecardTrait.type),
    switchMap((action: { payload:number }) => {
      return from(ApplicantService.getScorecardTrait(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: ScorecardTrait) => { 
          return storeScorecardTrait(res); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postScorecardTrait$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(postScorecardTrait.type),
    switchMap((action: { payload: ScorecardTrait; }) => {
      return from(ApplicantService.postScorecardTrait(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            storeScorecardTrait(res.value),
            getScorecardTraits(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const deleteScorecardTrait$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(deleteScorecardTrait.type),
    switchMap((action: { payload: number; }) => {
      return from(ApplicantService.deleteScorecardTrait(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getScorecardTraits(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};


const getInterviewTypes$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getInterviewTypes.type),
    switchMap(() => {
      return from(ApplicantService.getInterviewTypes()).pipe(
        map((res: any) => { return res.data; }),
        map((res: InterviewType[]) => { 
          return storeInterviewTypes(res); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postInterviewType$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(postInterviewType.type),
    switchMap((action: { payload: InterviewType; }) => {
      return from(ApplicantService.postInterviewType(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getInterviewTypes(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const deleteInterviewType$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(deleteInterviewType.type),
    switchMap((action: { payload: number; }) => {
      return from(ApplicantService.deleteInterviewType(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getInterviewTypes(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};


const getCustomEmails$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getCustomEmails.type),
    switchMap(() => {
      return from(ApplicantService.getCustomEmails()).pipe(
        map((res: any) => { return res.data; }),
        map((res: CustomEmail[]) => { 
          return storeCustomEmails(res); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getCustomEmail$ = (action$: Observable<Actions<string>>) => {
  return action$.pipe(
    ofType(getCustomEmail.type),
    switchMap((action: { payload:number }) => {
      return from(ApplicantService.getCustomEmail(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: CustomEmail) => { 
          return storeCustomEmail(res); 
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postCustomEmail$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(postCustomEmail.type),
    switchMap((action: { payload: CustomEmail; }) => {
      return from(ApplicantService.postCustomEmail(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            storeCustomEmail(res.value),
            getCustomEmails(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const deleteCustomEmail$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(deleteCustomEmail.type),
    switchMap((action: { payload: number; }) => {
      return from(ApplicantService.deleteCustomEmail(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getCustomEmails(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const testCustomEmail$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(testCustomEmail.type),
    switchMap((action: { payload: TestEmail; }) => {
      return from(ApplicantService.testCustomEmail(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const postATSCustomField$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(postATSCustomField.type),
    switchMap((action: { payload: PageCustomField; }) => {
      return from(ApplicantService.postCustomField(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getDesignWebsite(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const deleteATSCustomField$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(deleteATSCustomField.type),
    switchMap((action: { payload: PageCustomField; }) => {
      return from(ApplicantService.deleteCustomField(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getDesignWebsite(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const putApplicantPagesField$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(putApplicantPagesField.type),
    switchMap((action: { payload: DesignWebsite; }) => {
      return from(ApplicantService.putApplicantPagesField(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getDesignWebsite(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const putApplicantWaivers$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(putApplicantWaivers.type),
    switchMap((action: { payload: DesignWebsite; }) => {
      return from(ApplicantService.putApplicantWaivers(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getDesignWebsite(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const putSignatureDescription$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(putSignatureDescription.type),
    switchMap((action: { payload: DesignWebsite; }) => {
      return from(ApplicantService.putSignatureDescription(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getDesignWebsite(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const putWebsiteImages$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(putWebsiteImages.type),
    switchMap((action: { payload: WebsiteImage; }) => {
      return from(ApplicantService.putWebsiteImages(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getDesignWebsite(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const postCheckForConfig$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(postCheckForConfig.type),
    switchMap(() => {
      return from(ApplicantService.postCheckForConfig()).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            postCheckForConfig(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const postCustomWaiver$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(postCustomWaiver.type),
    switchMap((action: { payload: WebsiteCustomWaiver; }) => {
      return from(ApplicantService.postCustomWaiver(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getDesignWebsite(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const deleteCustomWaiver$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(deleteCustomWaiver.type),
    switchMap((action: { payload: WebsiteCustomWaiver; }) => {
      return from(ApplicantService.deleteCustomWaiver(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getDesignWebsite(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const postWebsiteImage$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(postWebsiteImage.type),
    switchMap((action: { payload: WebsiteImage; }) => {
      return from(ApplicantService.postWebsiteImage(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getDesignWebsite(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

const deleteWebsiteImage$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(deleteWebsiteImage.type),
    switchMap((action: { payload: WebsiteImage; }) => {
      return from(ApplicantService.deleteWebsiteImage(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            getDesignWebsite(),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            resetLoading(),
          ];
        }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  getApplicants$,
  getApplicant$,
  getJobs$,
  getJob$,
  postJob$,
  putJobStatuses$,
  deleteJob$,
  getInterviewProcesses$,
  postInterviewProcess$,
  deleteInterviewProcess$,
  getInterviewTemplates$,
  getInterviewTemplate$,
  postInterviewTemplate$,
  deleteInterviewTemplate$,
  getInterviewQuestions$,
  getInterviewQuestion$,
  postInterviewQuestion$,
  deleteInterviewQuestion$,
  getInterviewLocations$,
  getInterviewLocation$,
  postInterviewLocation$,
  deleteInterviewLocation$,
  getInterviewTypes$,
  postInterviewType$,
  deleteInterviewType$,
  getScorecardCategories$,
  getScorecardCategory$,
  postScorecardCategory$,
  deleteScorecardCategory$,
  getScorecardTraits$,
  getScorecardTrait$,
  postScorecardTrait$,
  deleteScorecardTrait$,
  getCustomEmails$,
  getCustomEmail$,
  postCustomEmail$,
  deleteCustomEmail$,
  testCustomEmail$,
  postATSCustomField$,
  deleteATSCustomField$,
  putApplicantPagesField$,
  putApplicantWaivers$,
  putSignatureDescription$,
  putWebsiteImages$,
  postCustomWaiver$,
  postWebsiteImage$,
  postCheckForConfig$,
  deleteCustomWaiver$,
  deleteWebsiteImage$,
];