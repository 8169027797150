import Modal from 'core/components/modals/Modal';
import { PayrollUploadFile, PayrollUploadType } from 'core/models/PayrollUpload.model';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { RadioGrp, SelectGrp } from 'core/components/form-controls';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';
import { payrollUploadFileTool, storePayrollUploadFileToolErrors } from 'core/store/actions';

export const radioOptions: RadioOptions[] = [
    {
      value: "W",
      label: 'Weekly',
    },
    {
      value: "B",
      label: 'Bi-Weekly',
    },
    {
      value: "S",
      label: 'Semi-Monthly',
    },
    {
      value: "M",
      label: 'Monthly',
    },
  ];

type PropTypes = {
  mapId: number;
  show: boolean;
  weekEnd: string | undefined;
  checkDate: string | undefined;
  selectedLocation: number;
  selectedDepartment: number;
  aodMapNames: string[];
  setPayrollUploadFile: React.Dispatch<React.SetStateAction<PayrollUploadFile | null>>
  onHide: () => void;
};
type FormattedMaps = { id: string; description: string; }[];

const AttendanceOnDemandModal: React.FC<PropTypes> = ({
  mapId,
  show,
  weekEnd,
  checkDate,
  aodMapNames,
  selectedLocation,
  selectedDepartment,
  setPayrollUploadFile,
  onHide,
}) => {
  const dispatch = useDispatch();
  const [frequency, setFrequency] = useState<string>('');
  const [mapName, setMapName] = useState<string>('');
  const mapsFormatted: FormattedMaps = structuredClone(aodMapNames).map((x) => ({ id: x, description: x }));

  const uploadAodData = () => {
    const uploadFile: PayrollUploadFile = {
        mapId: mapId,
        weekEnd: weekEnd || '',
        checkDate: checkDate || '',
        fileName: '',
        file: '',
        locationCode: selectedLocation,
        departmentCode: selectedDepartment,
        skipValidationErrors: false,
        overwrite: true,
        empNosToTrack: [],
        uploadType: PayrollUploadType.AttendanceOnDemand,
        frequency: frequency,
        aodMapName: mapName,
        fromDate: null,
        toDate: null,
        uploadFile: true,
        onTheClockData: []
      };
      dispatch(payrollUploadFileTool(uploadFile));
      setPayrollUploadFile(uploadFile);
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      animation={false}
      title="Attendance On Demand Upload"
    >
      {(closeModal) => {
        return (
        <>
            <span>Please select which export map from Attendance on Demand that you would like to use:</span>
            <div className="col-auto">
              <SelectGrp
                name="uploadMap"
                addEmptyValue={true}
                options={mapsFormatted}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setMapName(e.target.value as string);
                }}
              />
            </div>
            <span>Please select the period to grab the data from:</span>
            <div className="col-auto">
                <RadioGrp
                    radioOptions={radioOptions}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFrequency(e.target.value as string);
                    }}
                    defaultChecked={"W"}
                    name="radioButtonValue"
                    groupClass={'d-flex flex-column'}
                />
            </div>
            <div className="row mt-3">
                <div className="col-12 text-center">
                    <button
                        type="button"
                        className="btn btn-primary orange-button mr-2"
                        onClick={uploadAodData}
                        >
                        Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        type="button"
                        className="btn btn-primary orange-button mr-2"
                        onClick={closeModal}
                        >
                        Cancel
                    </button>
               </div>
            </div>
        </>
        );
      }}
    </Modal>
  );
};
export default AttendanceOnDemandModal;
