import React, { useState, useEffect } from 'react';
import { FieldInputSettings, FormMethodUnion, FormMethods } from 'core/components/form-controls/types';
import {
  ControlDatePickerGrp,
  InputGrp,
  InputGrpCurrency,
  InputGrpDecimal,
  SelectGrp,
} from 'core/components/form-controls';
import { ArrayField } from 'react-hook-form';
import { Deduction } from 'core/models';
import { CURRENCY_VALIDATION } from 'core/constants';
import { currencyFormatter, formatDecimal, getAccess } from 'utilities/utilities';
import Icon from 'core/components/shared/Icon';
import { companyBenefitsStatusOptions } from 'dropdowns/deductionStatusOptions';
import styles from 'core/components/form-controls/form-controls.module.scss';
import { validateExpireDate } from '../deductions/DeductionUtilites';
import { useAppSelector } from 'utilities/hooks';

type PropTypes = {
  item: Partial<ArrayField<Deduction>>;
  dedCodeOpts: any[];
  dedStatusOpts: any[];
  dedFrequencyOpts: any[];
  unitOpts: any[];
  showSaveButton?: boolean;
  fsMerge?: FieldInputSettings;
  isCheckCalculator?: boolean;
  formMethods: Pick<FormMethods, FormMethodUnion>;
  control: any;
  index: number;
  onDelete: (item: Deduction) => void;
  updateNestedErrors?: (title: string, key?: string, propertyChain?: string) => void;
  onWatch?: (dedId: number, e: Deduction, valid: boolean) => void;
};

const CompanyBenfitItem: React.FC<PropTypes> = ({
  item,
  dedCodeOpts,
  dedFrequencyOpts,
  unitOpts,
  isCheckCalculator = false,
  formMethods: { errors, setValue, register, watch, getValues },
  control,
  index,
  updateNestedErrors,
  onDelete,
  onWatch,
}) => {
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  
  const dedNoName = `companyBenefitItems[${index}].dedNo`;
  const statusName = `companyBenefitItems[${index}].status`;
  const dedAmountName = `companyBenefitItems[${index}].dedAmount`;
  const unitName = `companyBenefitItems[${index}].unit`;
  const freqName = `companyBenefitItems[${index}].freq`;
  const dateStartName = `companyBenefitItems[${index}].dateStart`;
  const dateExpireName = `companyBenefitItems[${index}].dateExpire`;
  const entityDescName = `companyBenefitItems[${index}].entityDesc`;
  const begAmountName = `companyBenefitItems[${index}].begAmount`;
  const balanceName = `companyBenefitItems[${index}].currAmount`;

  const fs = ({
    dedNo: {
      name: dedNoName,
      label: 'DED NO',
      groupClass: 'groupClass12',
      required: !isCheckCalculator,
      addOptionText: 'Deduction Code',
      errorMsg: 'Deduction no. is required.',
    },
    status: {
      name: statusName,
      label: 'STATUS',
      modalTitle: 'DEDUCTION STATUSES',
      groupClass: 'groupClass12',
      required: !isCheckCalculator,
      errorMsg: 'Status is required.',
    },
    dedAmount: {
      name: dedAmountName,
      label: 'AMOUNT',
      groupClass: 'gc12 mw150',
    },
    unit: {
      name: unitName,
      label: 'UNIT',
      groupClass: 'gc12 mw150',
    },
    freq: {
      name: freqName,
      label: 'FREQUENCY',
      groupClass: 'gc12 mw150',
    },
    dateStart: {
      name: dateStartName,
      label: 'START DATE',
      groupClass: 'gc10 mw125',
    },
    dateExpire: {
      name: dateExpireName,
      label: 'EXPIRE DATE',
      groupClass: 'gc10 mw125',
    },
    entityDesc: {
      name: entityDescName,
      label: 'DESCRIPTION',
      groupClass: 'gc30 mw300',
    },
    begAmount: {
      name: begAmountName,
      label: 'BEGIN AMOUNT',
      groupClass: 'gc10 mw125',
    },
    balance: {
      name: balanceName,
      label: 'BALANCE',
      groupClass: 'gc10 mw125',
    },
  });
 
  const dateStart = watch(dateStartName);
  const dateExpire = watch(dateExpireName);
  
  useEffect(() => {
    if (updateNestedErrors) {
      updateNestedErrors('company benefits', 'companyBenefitItems');
    }
  }, [errors]);
  
  const onFormClick = async () => {
    if (!onWatch) return;

    const formValues = getValues();
    const updateRecurringEarning = formValues.companyBenefitItems.find((x: Deduction) => {return x.dedId === item.dedId;}) || item;
    onWatch(item.dedId || 0, updateRecurringEarning, true);
  };
  
  return (
    <div
      className="d-flex flex-column"
      onChange={onFormClick}
    >
      <input
        type="hidden"
        name={`companyBenefitItems[${index}].dedId`}
        defaultValue={item.dedId}
        ref={register({ valueAsNumber: true })}
      />
      <div className="d-flex flex-wrap">
        <SelectGrp
          {...fs.dedNo}
          {...getAccess(sectionAccess, 16, undefined, { disabledDefault: (item.dedId ?? 0) > 0 })}
          addEmptyValue
          options={dedCodeOpts}
          errors={errors?.companyBenefitItems?.[index]?.dedNo}
          selectClass={(item?.dedId ?? 0) < 1 ? styles['dm-form-control-highlight'] : ''}
          labelClass={`${styles['dm-required']}`}
          defaultValue={item.dedNo ?? 0}
          ref={register({
            valueAsNumber: true,
          })}
        />
        <SelectGrp
          {...fs.status}
          {...getAccess(sectionAccess, 16)}
          options={companyBenefitsStatusOptions}
          errors={errors?.companyBenefitItems?.[index]?.status}
          labelClass={`${styles['dm-required']}`}
          defaultValue={item.status ?? 'TRACKED'}
          ref={register({
            required: {
              value: true,
              message: 'Status is required.',
            },
          })}
        />
        <InputGrpDecimal
          {...fs.dedAmount}
          {...getAccess(sectionAccess, 16, undefined, { disabledSameAsReadOnly: true })}
          labelClass={`${styles['dm-required']}`}
          type={'text'}
          errors={errors?.companyBenefitItems?.[index]?.dedAmount}
          setValue={setValue}
          ref={register({
            ...CURRENCY_VALIDATION,
            required: {
              value: true,
              message: 'Deduction amount is required.',
            } })}
          strValue={formatDecimal(item?.dedAmount ?? 0)}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            e.target.value = formatDecimal(
              parseFloat(e?.target?.value),
            ) as string;
          }}
        />
        <SelectGrp
          {...fs.unit}
          {...getAccess(sectionAccess, 16)}
          defaultValue={item.unit ?? ''}
          errors={errors?.deductionItems?.[index]?.unit}
          ref={register}
          options={unitOpts}
        />
        <SelectGrp
          {...fs.freq}
          {...getAccess(sectionAccess, 16)}
          labelClass={`${styles['dm-required']}`}
          errors={errors?.companyBenefitItems?.[index]?.freq}
          ref={register({
            required: {
              value: true,
              message: 'Frequency is required.', 
            } })}
          defaultValue = {item?.freq ?? 0}
          setValue = {setValue}
          options={dedFrequencyOpts.filter(x => x.id !== 'X')}
        />
        <ControlDatePickerGrp
          {...fs.dateStart}
          {...getAccess(sectionAccess, 16)}
          errors={errors?.companyBenefitItems?.[index]?.dateStart}
          value={dateStart}
          setValue={setValue}
          control={control}
        />
        <ControlDatePickerGrp
          {...fs.dateExpire}
          {...getAccess(sectionAccess, 16)}
          errors={errors?.companyBenefitItems?.[index]?.dateExpire}
          value={dateExpire}
          setValue={setValue}
          rules={{ validate: (value: Date) => { return validateExpireDate(value, new Date(getValues(dateStartName)), true); } }}
          control={control}
        />
        <InputGrp
          {...fs.entityDesc}
          {...getAccess(sectionAccess, 16, undefined, { disabledSameAsReadOnly: true })}
          errors={errors?.companyBenefitItems?.[index]?.entityDesc}
          defaultValue={item?.entityDesc ?? ''}
          ref={register({
            maxLength: {
              value: 100,
              message: 'Maximum length is 100 characters',
            },
          })}
        />
        <InputGrpCurrency
          {...fs.begAmount}
          {...getAccess(sectionAccess, 16, undefined, { disabledSameAsReadOnly: true })}
          errors={errors?.companyBenefitItems?.[index]?.begAmount}
          setValue={setValue}
          defaultValue={item?.begAmount ?? 0}
          ref={register(CURRENCY_VALIDATION)}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            if (getAccess(sectionAccess, 16)?.readOnly ?? false) return;

            const value = parseFloat(
              e.target.value.replace(/[^0-9.+-]/g, ''),
            );
            const formattedValue = currencyFormatter(value);
            setValue(begAmountName, formattedValue);
            const cAmount = parseFloat(
              getValues(balanceName).replace(
                /[^0-9.+-]/g,
                '',
              ),
            );
            if (cAmount === 0 || value === 0)
              setValue(balanceName, formattedValue);
          }}
        />
        <InputGrpCurrency
          {...fs.balance}
          {...getAccess(sectionAccess, 16, undefined, { readOnlyDefault: true, disabledSameAsReadOnly: true, disabledDefault: isCheckCalculator && (item.dedId ?? 0) >= 0 })}
          errors={errors?.companyBenefitItems?.[index]?.currAmount}
          setValue={setValue}
          ref={register(CURRENCY_VALIDATION)}
          defaultValue={item?.currAmount || 0}
        />
      </div>
      <div className="row">
        <div className="col-12 text-right">
          <button
            {...getAccess(sectionAccess, 16, undefined, { disabledSameAsReadOnly: true })}
            type="button"
            className="btn btn-link dm-grid-action-title mr-4"
            onClick={() => { return onDelete(item as Deduction); }}
          >
            Delete this Company Benefit
            <Icon
              name="minus-circle"
              className="fa-minus-circle"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompanyBenfitItem;
