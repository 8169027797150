import React, { useEffect, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GetDetailRowDataParams, GridApi, GridOptions, GridReadyEvent, ICellRendererParams } from '@ag-grid-community/core';
import { TaxChangeLog } from 'core/models/MyInfoChangeLog';
import { MasterDetailModule } from '@ag-grid-enterprise/all-modules';
import { SelectionChangeType } from './MyInfoChanges';
import { convToDateString } from 'utilities/utilities';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { loadCmTaxEntities } from 'core/store/actions/tax.action';

type PropTypes = {
  taxChanges: TaxChangeLog[];
  onSelectionChanged: (type: SelectionChangeType, selection: any[]) => void;
};

const TaxChangeLogComponent: React.FC<PropTypes> = ({
  taxChanges,
  onSelectionChanged,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadCmTaxEntities());
  }, []);
  
  const taxEntities = useAppSelector(({ tax }) => tax.clientMasterDtoTaxEntitiesClientTaxEntity);
  const taxEntityMap = new Map();
  taxEntities.forEach((entity) => {
    taxEntityMap.set(entity.entityId, entity.name);
  });
  
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const columns: ColDef[] = taxEntities?.length ? [
    {
      field: 'entityId',
      headerName: '',
      cellRenderer: 'agGroupCellRenderer',
      width: 50,
    },
    {
      field: 'year',
      headerName: 'Agency/Year',
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <span>{taxEntityMap?.get(params.data.entityId)} {params.data.year}</span>
        );
      },
      width: 200,
    },
    {
      field: 'selectAll',
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
  ] : [];
  
  const gridOptions: GridOptions = {
    columnDefs: columns,
    domLayout: 'autoHeight',
    defaultColDef: {
      autoHeight: true,
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    masterDetail: true,
    detailRowAutoHeight: true,
    detailCellRendererParams: {
      detailGridOptions: {
        defaultColDef: {
          cellClass: 'ag-cell-left-border',
        },
        columnDefs: [
          {
            field: 'fieldName',
            cellRenderer: (params: ICellRendererParams) => {
              return (
                <span>{params.data.fieldName.split(/(?<=[a-z])(?=[A-Z])/).join(' ')}</span>
              );
            },
          },
          { field: 'oldValue' },
          { field: 'newValue' },
          {
            field: 'dateMade',
            width: 150,
            cellRenderer: ({ data }: ICellRendererParams) => {
              return (
                <span>
                  {convToDateString(data.dateMade)}, {new Date(data.dateMade).toLocaleTimeString()}
                </span>
              );
            },
          },
        ],
      },
      getDetailRowData: (params: GetDetailRowDataParams) => {
        params.successCallback(params.data?.changes ?? []);
      },
    },
  };

  const selectionChanged = () => {
    onSelectionChanged('TAX', gridApi?.getSelectedRows() ?? []);
  };

  return (
    <div
      className="ag-theme-balham"
      style={{ marginBottom: 10 }}
    >
      <div className="row m-1">
        <div
          className="table-wrapper-wrapper ag-theme-balham"
          style={{ height: '100px' }}
        >
          {taxEntities?.length && (
            <AgGridReact
              rowData={taxChanges}
              rowSelection={'multiple'}
              masterDetail={true}
              gridOptions={gridOptions}
              onGridReady={(params: GridReadyEvent) => {
                setGridApi(params.api);
              }}
              onSelectionChanged={selectionChanged}
              overlayNoRowsTemplate={'<span>No Changes to Review</span>'}
              modules={[MasterDetailModule]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TaxChangeLogComponent;
