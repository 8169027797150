import Icon from 'core/components/shared/Icon';
import React from 'react';
import styles from './nested-accordion.module.scss';
import { CheckboxGrpInLine } from '../CheckboxGrpInLine';

type PropTypes = {
  item: any;
  onClick?: any;
  onCheckBoxClick?: any;
  containerClass?: string;
  iconClass?: string;
  showCheckBox?: boolean;
};

const FileItem: React.FC<PropTypes> = ({
  item,
  onClick,
  onCheckBoxClick,
  containerClass = styles['fi-container'],
  iconClass = styles['fi-icon'],
  showCheckBox = false
}) => {

  return (
    <div className={`d-flex ${containerClass}`}>
      <Icon
        name="file"
        className={iconClass}
      />
      <a
        href="javascript:void(0)"
        onClick={() => { return onClick(item.data); }}
      >{item.title}</a>
        {showCheckBox ? <CheckboxGrpInLine
          groupClass={'dm-form-group ml-3 mt-2'}
          onClick={() => {onCheckBoxClick(item.data)}}
        /> : null}
    </div>
  );
};

export default FileItem;