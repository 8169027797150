import Modal from 'core/components/modals/Modal';
import { CellProps, ColumnHeaderData, Row } from 'core/components/shared/dm-table/types';
import { Deduction } from 'core/models';
import React, { useState } from 'react';
import tableStyles from 'core/components/shared/dm-table/_styles.module.scss';
import TableHeader from 'core/components/shared/dm-table/Header';
import TableRow from 'core/components/shared/dm-table/Row';
import Cell from 'core/components/shared/dm-table/Cell';
import { useSelector } from 'react-redux';
import { getDirectDeposits } from 'core/store/selectors/deduction.selector';
import { Button } from 'react-bootstrap';
import Icon from 'core/components/shared/Icon';
import { CheckCodeSelect } from 'core/components/form-controls';
import { getCheckCodesForDropdown, getDropdownOptions } from 'core/store/selectors';
import { FormMethods } from 'core/components/form-controls/types';
import { CheckCodeSelect2 } from 'core/components/shared/CheckCodeSelect2';

const jobSetupColumns: ColumnHeaderData[] = [
  { title: 'Ded No', styleClass: 'th', position: 'center' },
  { title: 'Status', styleClass: 'th', position: 'center' },
  { title: 'Amount', styleClass: 'th', position: 'center' },
  { title: 'Unit(%.H)', styleClass: 'th', position: 'center' },
  { title: 'CK CD', styleClass: 'th', position: 'center' },
  { title: 'Freq', styleClass: 'th', position: 'center' },
  { title: 'Start Date', styleClass: 'th', position: 'center' },
  { title: 'Expire Date', styleClass: 'th', position: 'center' },
  { title: 'Routing Number', styleClass: 'th', position: 'center' },
  { title: 'Account Number', styleClass: 'th', position: 'center' },
];

type Props = {
  show: boolean;
  onHide: () => void;
  inputName: string;
  setValue: FormMethods['setValue'];
  control: any; // this gets funky when we do what I just did to setValue so I'll just leave this untyped for now
  onAddSkipAlways: (deduction: Deduction, checkCode: string) => void;
};
  
const SkipAlwaysModal = ({
  show,
  onHide,
  inputName,
  control,
  setValue,
  onAddSkipAlways,
}: Props) => {
  const directDepositStore = useSelector(getDirectDeposits);
  const checkCodeOpts = useSelector(getCheckCodesForDropdown);
  const { deductionFrequencyOpts, deductionCodeOptions, unitOpts } = useSelector(getDropdownOptions);
  
  const [selectedDeduction, setSelectedDeduction] = useState<Deduction | null>(null);
  const [selectedCheckCode, setSelectedCheckCode] = useState<string>('');
  
  const updateCheckCodeState = (newVal: string) => {
    setSelectedCheckCode(newVal);
  };

  const buildDeductionRow = (deduction: Deduction): Row => {
    const frequencyDesc = deductionFrequencyOpts.find(x => x.id === deduction.freq)?.description;
    const unitDesc = unitOpts.find(x => x.id === deduction.unit)?.description;
    const dedNoDesc = deductionCodeOptions.find(x => +x.id === deduction.dedNo)?.description;
    const dateStart = (deduction.dateStart) ? new Date(deduction.dateStart)?.toLocaleDateString() : '';
    const dateExpire = (deduction.dateExpire) ? new Date(deduction.dateExpire)?.toLocaleDateString() : '';

    return {
      id: deduction.dedId,
      cells: [
        { children: `${deduction.dedNo} - ${dedNoDesc}`, styleClass: 'td', position: 'center' },
        { children: deduction.status, styleClass: 'td', position: 'center' },
        { children: deduction?.dedAmount?.toFixed(2), styleClass: 'td', position: 'end' },
        { children: unitDesc, styleClass: 'td', position: 'center' },
        { children: deduction.checkCode, styleClass: 'td', position: 'center' },
        { children: frequencyDesc, styleClass: 'td', position: 'center' },
        { children: dateStart, styleClass: 'td', position: 'center' },
        { children: dateExpire, styleClass: 'td', position: 'center' },
        { children: deduction.routingNo, styleClass: 'td', position: 'center' },
        { children: deduction.bankAcctNo, styleClass: 'td', position: 'center' },
      ],
    };
  };

  const dedRow = directDepositStore?.filter(x => x.skipAlwayDedId === null)?.map(x => buildDeductionRow(x));

  const onSelect = () => {
    if (!selectedDeduction) return alert('Deduction selection required to continue.');
    if (!selectedCheckCode) return alert('Check Code selection required to continue.');;
    onAddSkipAlways(selectedDeduction, selectedCheckCode);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      title="Select Skip-Always Deduction"
    >
      <div className="d-flex flex-wrap">
        <CheckCodeSelect2
          name={inputName}
          label={'CK CD'}
          value={selectedCheckCode}
          onChange={updateCheckCodeState}
          options={checkCodeOpts}
          required={true}
        />
        <Button
          variant="link"
          onClick={onSelect}
          className="dm-grid-action-title mb-0 pb-1"
        >
          Select
          <Icon name="check" />
        </Button>
      </div>
      <div className="ag-theme-balham mb-4">
        <div className={tableStyles.table}>
          <TableHeader columns={jobSetupColumns} />
          <div className={tableStyles.tbody}>
            {dedRow?.map((row: Row) => (
              <TableRow
                id={row.id}
                isSelected={String(row.id) === String(selectedDeduction?.dedId)}
                key={row.id}
                onClick={() => {
                  setSelectedDeduction(directDepositStore?.find((deduction) => String(deduction?.dedId) === String(row.id)) ?? null);
                }}
              >
                {row.cells.map((cell: CellProps, cellIndex: number) => (
                  <Cell
                    key={`(${row.id},${cellIndex})`}
                    styleClass={cell.styleClass}
                    position={cell.position}
                  >{cell.children}</Cell>
                ))}
              </TableRow>
            ))}
            <small>Select the Deduction you would like to Skip Always</small>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SkipAlwaysModal;