import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'core/components/modals/Modal';
import { ColDef, ICellRendererParams, GridOptions } from '@ag-grid-community/core';
import { ApplicantStatus, applicantStatusOpts, Applicant, AvailableJob } from 'core/models';
import { setApplicant } from 'core/store/slices/applicant-tracking-v2.slice';
import { agSelectEditor } from 'utilities/ag-grid-editors';
import { AgGridReact } from '@ag-grid-community/react';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { cloneDeep } from 'lodash';
import Icon from 'core/components/shared/Icon';
import { useHistory } from 'react-router-dom';

type Props = {
  show: boolean;
  onHide: () => void;
  job: AvailableJob;
};


const JobApplicantsModal = ({ show, onHide, job }: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
        
  const [applicants, setApplicants] = useState<Applicant[]>([]);
  
  useEffect(() => {
    job && setApplicants(cloneDeep(
      job.applicants.filter(a => {
        return a.status !== ApplicantStatus.Employee;
      })));
  }, [job]);

  
  const columns = useMemo<ColDef[]>(
    () => {
      return [
        {
          headerName: 'Name',
          width: 200,
          cellRenderer: (params: ICellRendererParams) => {
            return (
              <span>{params.data.firstName} {params.data.lastName}</span>
            );
          },
          onCellDoubleClicked: (selected: {
            data: Applicant
          }) => {
            dispatch(setApplicant(selected.data));
            history.push(`/manage-applicants/${selected?.data?.applicantId}`);
          },
        },       
        {   
          field: 'status',
          headerName: 'Status',
          cellEditorParams: { 
            options: applicantStatusOpts,
          },
          cellEditor: agSelectEditor,
          cellRendererParams: { 
            options: applicantStatusOpts,
            valueField: 'id',
            labelField: 'description',
          },
          cellRenderer: (params: any) => {
            const option = params?.options?.find((o:any)=> {
              return o.id === params.data.status;
            });
            return ( 
              <div className="d-flex flex-row">
                {option  && <Icon
                  name={option?.iconName}
                  style={{ padding: 5,
                    color:option?.color,
                    marginRight: 20 }}
                />}
                {ApplicantStatus[params.data.status].replace('_', ' ')}
              </div>
            );
          },         
          width: 200,
          editable: false,
        },         
      ];
    },
    [],
  ); 
  
  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Applicants"
    >
      {(closeModal) => {
        return (
          <div className="d-flex w-100 flex-wrap">
            <div className="dm-subtitle2 justify-content-start">{`Job Tile: ${job.jobTitle}`}</div>
            <div className="table-wrapper-wrapper ag-theme-balham">
              <AgGridReact
                gridOptions={gridOptions}
                rowData={applicants}
                columnDefs={columns}
              />
            </div>            
          </div>
        );
      }}
    </Modal>
  );
};

export default JobApplicantsModal;