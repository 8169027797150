import React from 'react';

import AddEmpInformation from './information/EmpAddInformation';

const AddEmpPage = () => {

  return (<div className="col">
    <div className="row">
      <div className="col-12">
        <div className="dm-page-title pt-1">Add Employee</div>
      </div>
    </div>
    <hr className="dm-page-hr" />
    <div className="row">
      <div className="col-12">
        <AddEmpInformation />
      </div>
    </div>
  </div>);
};

export default AddEmpPage;
