import { createSelector } from 'reselect';
import { convToFormObject } from '../../../utilities/classUtils';
import { Deduction, GarnishmentEntities } from '../../models';
import { deductionState } from '../reducers';
import { State as DeductionState } from '../reducers/deduction.reducer';
import { IOption } from 'interfaces/IOption';

export const getDeductions = createSelector(
  deductionState,
  (state: DeductionState) => {
    return state.deductions
      .filter((ded: Deduction) => {return ded.deductionType.toLowerCase() === 'deduction'})
      .map((ded: Deduction) => {return convToFormObject(ded, Deduction.convProps);}) as Deduction[];
  },
);

export const getDirectDeposits = createSelector(
  deductionState,
  (state: DeductionState) => {
    return state.deductions
      .filter((ded: Deduction) => {return ded.deductionType.toLowerCase() === 'directdeposit' || ded.deductionType.toLocaleLowerCase() === 'genericeft';})
      .map((ded: Deduction) => {return convToFormObject(ded, Deduction.convProps);}) as Deduction[];
  },
);

export const getGarnishments = createSelector(
  deductionState,
  (state: DeductionState) => {
    return state.deductions
      .filter((ded: Deduction) => {return ded.deductionType.toLowerCase() === 'garnishment';})
      .map((ded: Deduction) => {return convToFormObject(ded, Deduction.convProps);}) as Deduction[];
  },
);

export const getCompanyBenefits = createSelector(
  deductionState,
  (state: DeductionState) => {
    return state.deductions
      .filter((ded: Deduction) => {return ded.deductionType.toLowerCase() === 'companybenefit';})
      .map((ded: Deduction) => {return convToFormObject(ded, Deduction.convProps);}) as Deduction[];
  },
);

export const getDeductionsAndGarnishments = createSelector(
  deductionState,
  (state: DeductionState) => {
    return state.deductions
      .filter((ded: Deduction) => {return ded.deductionType.toLowerCase() === 'deduction' || ded.deductionType.toLowerCase() === 'garnishment';})
      .map((ded: Deduction) => {return convToFormObject(ded, Deduction.convProps);}) as Deduction[];
  },
);

export const selectGarnishmentEntities = createSelector(
  deductionState,
  (state: DeductionState) => {return state.garnishmentEntities},
);