import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  CheckboxGrpInLine,
  ControlDatePickerGrp,
  Label,
  Labels,
} from '../../core/components/form-controls';
import { RadioOptions } from '../../core/components/form-controls/RadioGrp';
import { RadioGrpStacked } from '../../core/components/form-controls/RadioGrpStacked';
import {
  AccrualReportType,
  ReportType,
} from '../../core/models/AccrualReport.model';
import { CommonService } from '../../core/services';
import {
  loadStandardReports,
  storeReportType,
} from '../../core/store/actions/accrual-reports.action';
import { useAppDispatch, useAppSelector } from '../../utilities/hooks';

const includeTerminatedRadioOptions: RadioOptions[] = [
  {
    value: 'false',
    label: 'Exclude Terminated Employees',
  },
  {
    value: 'true',
    label: 'Include Terminated Employees',
  },
];

const includeInactivityRadioOptions: RadioOptions[] = [
  {
    value: 'false',
    label: 'Exclude Employees with no Activity',
  },
  {
    value: 'true',
    label: 'Include Employees with no Activity',
  },
];

const withPayoutRadioOptions: RadioOptions[] = [
  {
    value: 'false',
    label: 'Without Payout',
  },
  {
    value: 'true',
    label: 'With Payout',
  },
];

const reportTypeRadioOptions: RadioOptions[] = [
  {
    value: 'Excel',
    label: 'Excel',
  },
  {
    value: 'Pdf',
    label: 'Pdf',
  },
];

type PropTypes = {
  accrualReportType: AccrualReportType;
};

type FormTypes = {
  groupByLoc: boolean;
  groupByDept: boolean;
  groupBySubDept: boolean;
  groupBySubDept2: boolean;
  includeTerminated: string;
  includeInactivity: string;
  withPayout: string;
  pageBreakAfterLoc: boolean;
  pageBreakAfterDept: boolean;
  pageBreakAfterSub: boolean;
  pageBreakAfterSub2: boolean;
  pageBreakAfterEarningCode: boolean;
  beginDate: Date;
  endDate: Date;
  reportType: ReportType;
};

const AccrualReports: React.FC<PropTypes> = ({ accrualReportType }) => {
  const dispatch = useAppDispatch();

  const report = useAppSelector((state) => {return state.accrualReports.report;});
  const clientNo = useAppSelector((state) => {return state.client.client!.clientNo;});
  const {empNos, orderByType} = useAppSelector((state) => {return state.employeeInfomation;});

  const [reportType, setReportType] = useState<string>('');
  const [showReportOptions, setShowReportOptions] = useState(false);
  const [showPayout, setShowPayout] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);
  const [showBalances, setShowBalances] = useState(false);

  const { register, handleSubmit, errors, control, setValue, watch } =
        useForm<FormTypes>({
          defaultValues: {
            includeTerminated: 'false',
            includeInactivity: 'false',
            withPayout: 'false',
            reportType: ReportType.Pdf,
            beginDate: DateTime.local().startOf('year').toJSDate(),
            endDate: DateTime.local().endOf('year').toJSDate(),
            groupByDept: false,
            groupByLoc: false,
            groupBySubDept: false,
            groupBySubDept2: false,
            pageBreakAfterLoc: false,
            pageBreakAfterDept: false,
            pageBreakAfterSub: false,
            pageBreakAfterSub2: false,
            pageBreakAfterEarningCode: false,
          },
        });

  const watchFields = watch([
    'groupByLoc',
    'groupByDept',
    'groupBySubDept',
    'groupBySubDept2',
  ]);

  useEffect(() => {
    switch (accrualReportType) {
    case AccrualReportType.SummaryReport:
      setShowReportOptions(true);
      setShowPayout(true);
      break;
    case AccrualReportType.EarnTakenDetailReport:
      setShowDateRange(true);
      setShowReportOptions(true);
      break;
    case AccrualReportType.EarnTakenSummaryReport:
    case AccrualReportType.RolloverSummaryReport:
    case AccrualReportType.SummaryForSelectedPeriodReport:
      setShowDateRange(true);
      setShowReportOptions(true);
      setShowPayout(true);
      break;
    case AccrualReportType.EarnTakenBalanceReport:
      setShowPayout(true);
      setShowBalances(true);
      setShowReportOptions(true);
      break;
    default:
      setShowPayout(false);
      setShowReportOptions(false);
      setShowDateRange(false);
    }
  }, [accrualReportType]);

  useEffect(() => {
    if (report && report.length > 0 && reportType === 'Excel') {
      CommonService.downloadBase64File(
        'application/excel',
        report,
        `${accrualReportType}.xlsx`,
      );
    }
  }, [report]);

  const onSubmit = (data: FormTypes) => {
    dispatch(
      loadStandardReports({
        clientNo,
        groupByLoc: data.groupByLoc,
        groupByDept: data.groupByDept,
        groupBySubDept: data.groupBySubDept,
        groupBySubDept2: data.groupBySubDept2,
        includeTerminated: data.includeTerminated === 'true',
        includeInactivity: data.includeInactivity === 'true',
        withPayout: data.withPayout === 'true',
        pageBreakAfterLoc: data.pageBreakAfterLoc,
        pageBreakAfterDept: data.pageBreakAfterDept,
        pageBreakAfterSub: data.pageBreakAfterSub,
        pageBreakAfterSub2: data.pageBreakAfterSub2,
        pageBreakAfterEarningCode: data.pageBreakAfterEarningCode,
        beginDate: DateTime.fromJSDate(data.beginDate).toISODate(),
        endDate: DateTime.fromJSDate(data.endDate).toISODate(),
        accrualReportType,
        reportType: data.reportType,
        empNos: empNos,
        orderByType: orderByType
      }),
    );
    dispatch(storeReportType(data.reportType));
    setReportType(data.reportType);
  };

  const groupByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    switch (name) {
    case 'groupByLoc':
      setValue('pageBreakAfterLoc', checked);
      break;
    case 'groupByDept':
      setValue('pageBreakAfterDept', checked);
      break;
    case 'groupBySubDept':
      setValue('pageBreakAfterSub', checked);
      break;
    case 'groupBySubDept2':
      setValue('pageBreakAfterSub2', checked);
      break;
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <div className="card">
              <div className="card-body">
                <RadioGrpStacked
                  name="includeTerminated"
                  radioOptions={includeTerminatedRadioOptions}
                  ref={register}
                  stacked={true}
                />
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <RadioGrpStacked
                  name="includeInactivity"
                  radioOptions={includeInactivityRadioOptions}
                  ref={register}
                  stacked={true}
                />
              </div>
            </div>
          </Col>
          {(showPayout || showDateRange || showBalances) && (
            <Col xs="5">
              {showPayout && (
                <div className="card">
                  <div className="card-body">
                    <RadioGrpStacked
                      name="withPayout"
                      radioOptions={
                        withPayoutRadioOptions
                      }
                      ref={register}
                      stacked={true}
                    />
                  </div>
                </div>
              )}
              {showDateRange && (
                <div className="card bg-light">
                  <div className="card-body">
                    <p>Select Date Range</p>
                    <Row>
                      <Col xs="4">
                        <Labels
                          label="From:"
                          hasError={false}
                          id="beginDate"
                        />
                      </Col>
                      <Col>
                        <ControlDatePickerGrp
                          name="beginDate"
                          control={control}
                          groupClass="d-flex"
                          errors={errors.beginDate}
                          rules={{
                            required: {
                              value: true,
                              message: 'Required',
                            },
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="4">
                        <Labels
                          label="To:"
                          hasError={false}
                          id="endDate"
                          labelClass=""
                        />
                      </Col>
                      <Col>
                        <ControlDatePickerGrp
                          name="endDate"
                          control={control}
                          groupClass="d-flex"
                          errors={errors.endDate}
                          rules={{
                            required: {
                              value: true,
                              message: 'Required',
                            },
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              )}

              {showBalances && (
                <div className="card">
                  <div className="card-body bg-light">
                    <Row>
                      <Col xs="auto">
                        <Labels
                          label="Balances as of:"
                          hasError={false}
                          id="beginDate"
                        />
                      </Col>
                      <Col xs="auto">
                        <ControlDatePickerGrp
                          name="beginDate"
                          control={control}
                          groupClass="d-flex"
                          errors={errors.beginDate}
                          rules={{
                            required: {
                              value: true,
                              message: 'Required',
                            },
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </Col>
          )}
        </Row>

        {showReportOptions && (
          <div className="card">
            <div className="card-body bg-light">
              <Row>
                <Col xs="auto">
                  <CheckboxGrpInLine
                    name="groupByLoc"
                    label="Location"
                    ref={register}
                    labelFirst={false}
                    onChange={groupByChange}
                  />
                  <CheckboxGrpInLine
                    name="groupByDept"
                    label="Department"
                    ref={register}
                    labelFirst={false}
                    onChange={groupByChange}
                  />
                  <CheckboxGrpInLine
                    name="groupBySubDept"
                    label="Sub Department"
                    ref={register}
                    labelFirst={false}
                    onChange={groupByChange}
                  />
                  <CheckboxGrpInLine
                    name="groupBySubDept2"
                    label="Sub Department 2"
                    ref={register}
                    labelFirst={false}
                    onChange={groupByChange}
                  />
                </Col>
                <Col>
                  <CheckboxGrpInLine
                    name="pageBreakAfterLoc"
                    label="Page Break After Location"
                    ref={register}
                    labelFirst={false}
                    disabled={
                      watchFields.groupByLoc === false
                    }
                  />
                  <CheckboxGrpInLine
                    name="pageBreakAfterDept"
                    label="Page Break After Department"
                    ref={register}
                    labelFirst={false}
                    disabled={
                      watchFields.groupByDept === false
                    }
                  />
                  <CheckboxGrpInLine
                    name="pageBreakAfterSub"
                    label="Page Break After Sub Department"
                    ref={register}
                    labelFirst={false}
                    disabled={
                      watchFields.groupBySubDept === false
                    }
                  />
                  <CheckboxGrpInLine
                    name="pageBreakAfterSub2"
                    label="Page Break After Sub Department 2"
                    ref={register}
                    labelFirst={false}
                    disabled={
                      watchFields.groupBySubDept2 ===
                                            false
                    }
                  />
                  <CheckboxGrpInLine
                    name="pageBreakAfterEarningCode"
                    label="Page Break After Earnings Code"
                    ref={register}
                    labelFirst={false}
                  />
                </Col>
              </Row>
            </div>
          </div>
        )}

        <Label text={'Report File Type'} hasError={false}></Label>
        <RadioGrpStacked
          name="reportType"
          radioOptions={reportTypeRadioOptions}
          ref={register}
          stacked={false}
        />
        <hr />
        <button className="btn btn-primary orange-button-sm">
          Run Report
        </button>
      </form>
    </>
  );
};

export default AccrualReports;
