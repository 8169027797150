import React, { useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { DMDropdownDataModel } from 'core/components/form-controls/DMDropDown';
import { DMDropdownMenu } from 'core/components/form-controls';
import TransmittalPreferencesModal from '../transmittal-preferences/TransmittalPreferences.modal';
import AdjustTaxesModal from './AdjustTaxes.modal';
import { useAppSelector } from 'utilities/hooks';
import { useSelector } from 'react-redux';
import { getClientOptionIsContractor, getOpenPayrolls } from 'core/store/selectors';
import { createToggleShow } from 'utilities/utilities';
import JobSetupModal from 'features/contractor/modals/job-setup/JobSetup.modal';
import DiagnosticRulesModal from 'features/contractor/modals/DiagnosticRules.modal';
import SubSetupModal from 'features/contractor/modals/sub-and-cost-code-setup/SubSetup.modal';
import CostCodeSetupModal from 'features/contractor/modals/sub-and-cost-code-setup/CostCodeSetup.modal';
import { multipleOpenDatelinesLinkStyleOverride } from '../open-current-transmittal/OpenCurrentTransmittalToolbar';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import HamburgerMenu from 'core/components/shared/HamburgerMenu';

const privilagedUsers = ['gw3190', 'kp3286'];
const prefix = 'tools-nav';
const navIds = {
  ADD_BOOK_NAV_ID: `${prefix}-1`,
  ADJUST_TAXES_NAV_ID: `${prefix}-2`,
  PREFERENCES_NAV_ID: `${prefix}-6`,
};
const toolsDD: DMDropdownDataModel = {
  id: 'tools-dd',
  title: 'Tools',
  items: [
    {
      id: navIds.ADJUST_TAXES_NAV_ID,
      text: 'Adjust Taxes',
      href: '#',
      modal: true,
    },
    {
      id: navIds.PREFERENCES_NAV_ID,
      text: 'Preferences',
      href: '#',
      modal: true,
    },
  ],
};
const contractorPrefix = 'contractor-tools-nav';
const contractorNavIds = {
  COST_CODE_SETUP: `${contractorPrefix}-1`,
  DIAGNOSTIC_RULES: `${contractorPrefix}-2`,
  JOB_SETUP: `${contractorPrefix}-3`,
  SUB_SETUP: `${contractorPrefix}-4`,
};
const contractorToolsDD: DMDropdownDataModel = {
  id: 'contractor-tools-dd',
  title: 'Tools',
  items: [
    {
      id: contractorNavIds.COST_CODE_SETUP,
      text: 'Cost Code Setup',
      href: '#',
      modal: false,
    },
    {
      id: contractorNavIds.DIAGNOSTIC_RULES,
      text: 'Diagnostic Rules',
      href: '#',
      modal: false,
    },
    {
      id: contractorNavIds.JOB_SETUP,
      text: 'Job Setup',
      href: '#',
      modal: false,
    },
    {
      id: contractorNavIds.SUB_SETUP,
      text: 'Sub Setup',
      href: '#',
      modal: false,
    },
  ],
};

type ProcessPayrollToolbarProps = {
  toggleSidebar: () => void;
  showSidebarMenuIcon: boolean;
};

const ProcessPayrollToolbar: React.FC<ProcessPayrollToolbarProps> = ({ toggleSidebar, showSidebarMenuIcon }) => {
  const history = useHistory();
  
  const location = useLocation();
  
  const [showModal, setShowModal] = useState({
    adjustTaxes: false,
    transmittalPreferences: false,
    contractorJobSetup: false,
    contractorSubSetup: false,
    contractorCostCodeSetup: false,
    contractorDiagnosticRules: false,
    payrollModal: false,
  });
  const toggleShow = createToggleShow(setShowModal); 
  
  const openPayrolls = useSelector(getOpenPayrolls);
  const openNewSystemPayrolls = openPayrolls?.filter((payroll) => payroll.useNewCheckRegister) ?? [];
  const isContractor = useSelector(getClientOptionIsContractor);
  const currentUser = useAppSelector((state) => {return state?.auth?.userAccess?.dmUserName;});
  const groupAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.groups;
  });
  
  const processPayrollSection = groupAccess?.find((group) => {
    return group.description.toLowerCase() === 'transmittal';
  });
  const visible = processPayrollSection?.visible;
  
  // likely overkill for this, but will be easier to change with more items
  const filteredItems = toolsDD.items.filter((item) => {
    if (!currentUser) return;
  
    return (!privilagedUsers.includes(currentUser) &&
      item?.text.toLowerCase() !== 'adjust taxes');
  });
  
  const dropdownData: DMDropdownDataModel = {
    ...toolsDD,
    items: filteredItems.length ? filteredItems : toolsDD.items,
  };
  
  const onMenuSelect = (menuId: string) => {
    switch (menuId) {
      case navIds.ADJUST_TAXES_NAV_ID:
        toggleShow('adjustTaxes', true);
        break;
      case navIds.PREFERENCES_NAV_ID:
        toggleShow('transmittalPreferences', true);
        break;
      case contractorNavIds.JOB_SETUP:
        toggleShow('contractorJobSetup', true);
        break;
      case contractorNavIds.SUB_SETUP:
        toggleShow('contractorSubSetup', true);
        break;
      case contractorNavIds.COST_CODE_SETUP:
        toggleShow('contractorCostCodeSetup', true);
        break;
      case contractorNavIds.DIAGNOSTIC_RULES:
        toggleShow('contractorDiagnosticRules', true);
    }
  };

  return (
    <div className="dm-toolbar">
      {visible === false ? null : (
        <>
          {showModal.payrollModal && (<ConfirmationModal
            title="DM Payroll"
            message={'You do not have an open Payroll.\bDo you want to insert a new payroll?'}
            show={showModal.payrollModal}
            backdrop={'static'}
            onConfirmed={(confirm: boolean) => {
              //If they deny adding a new dateline back to the process payroll screen
              if (confirm) history.push('/process-payroll'); 
              toggleShow('payrollModal', false);
            }}
            onHide={() => { 
              toggleShow('payrollModal', false);
            }}
          />
          )}
          {showSidebarMenuIcon ? (
            <HamburgerMenu
              toggleSidebar={toggleSidebar}  
            />
          ) : (
            <>
              <NavLink to="/process-payroll">Process Payroll</NavLink>
              {isContractor && <NavLink to="/contractor-time-sheets">Open Current Time Sheets</NavLink>}
              {!isContractor && (
                <NavLink
                  style={((openNewSystemPayrolls?.length ?? 0) > 1 || !openNewSystemPayrolls?.length) && !location.pathname.includes('open-current-transmittal') ? multipleOpenDatelinesLinkStyleOverride : undefined}
                  to={((openNewSystemPayrolls?.length ?? 0) > 1 || !openNewSystemPayrolls?.length) ? '#' : '/open-current-transmittal'}
                  onClick={() => {
                    // PI-8420: Feels hacky, but will just "return" them to process payroll if they have more than one open dateline. Same is done in OpenCurrentTransmittalToolbar.tsx
                    if ((openNewSystemPayrolls?.length ?? 0) > 1) {
                      alert('You have more than one open transmittal date line. Please choose the correct date line to process.');
                      history.push('/process-payroll');  
                    } else if (!openNewSystemPayrolls?.length) {
                      toggleShow('payrollModal', true);
                    }
                  }}
                >
                  Open Current Transmittal
                </NavLink>)}
              <NavLink to="/sales-tax-transmittal">Sales Tax Transmittal</NavLink>
              <NavLink to="/upload-payroll">Payroll Upload</NavLink>
            </>
          )}

          <div>
            <DMDropdownMenu
              data={isContractor ? contractorToolsDD : dropdownData}
              toggleClass="dm-toolbar-nav"
              whiteChevron={true}
              onSelect={onMenuSelect}
            />
          </div>
          {showModal.transmittalPreferences &&
          <TransmittalPreferencesModal
            show={showModal.transmittalPreferences}
            onHide={() => { toggleShow('transmittalPreferences', false); }}
          />
          }
          {showModal.adjustTaxes &&
          <AdjustTaxesModal
            show={showModal.adjustTaxes}
            onHide={() => { toggleShow('adjustTaxes', false); }}
          />
          }
          {showModal.contractorJobSetup && (
            <JobSetupModal
              show={showModal.contractorJobSetup}
              onHide={() => { toggleShow('contractorJobSetup', false); }}
            />
          )
          }
          {showModal.contractorSubSetup && (
            <SubSetupModal
              show={showModal.contractorSubSetup}
              onHide={() => { toggleShow('contractorSubSetup', false); }}
            />
          )
          }
          {showModal.contractorCostCodeSetup && (
            <CostCodeSetupModal
              show={showModal.contractorCostCodeSetup}
              onHide={() => { toggleShow('contractorCostCodeSetup', false); }}
            />
          )
          }
          {showModal.contractorDiagnosticRules && (
            <DiagnosticRulesModal
              show={showModal.contractorDiagnosticRules}
              onHide={() => { toggleShow('contractorDiagnosticRules', false); }}
            />
          )
          }
        </>
      )}
    </div>
  );
};

export default ProcessPayrollToolbar;
