import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PanelHeader from 'core/components/shared/PanelHeader';
import { HREmployment, HREmploymentRequest } from 'core/models/HREmployee.model';
import {
  createHREmployment, deleteHREmployment,
  loadHREmployment, updateHREmployment,
} from 'core/store/actions/hr-employee.action';
import { getHireInfoEmployment } from 'core/store/selectors/hr-employee.selector';
import EmploymentItem from './EmploymentItem';
import { useFieldArray, useForm } from 'react-hook-form';
import { UNSAVED_MESSAGE } from 'core/constants';
import { currencyFormatter, formatPhone, fromCurrency } from 'utilities/utilities';
import Icon from 'core/components/shared/Icon';
import { toggleBlockNavigation } from 'core/store/actions';

type RouteParams = {
  protectedEmpNo: string;
};
type Clone = Omit<HREmployment, 'empStartPay' | 'empEndPay'> &
{ empStartPay: string | number; empEndPay: string | number; };
type FormType = {
  employmentItems: Clone[];
};

const EmploymentPage: React.FC = () => {
  const dispatch = useDispatch();

  const { protectedEmpNo } = useParams<RouteParams>();
  
  const [employmentItems, setEmploymentItems] = useState<HREmployment[]>([]);
  
  const employmentStore = useSelector(getHireInfoEmployment);

  const {
    reset,
    control,
    handleSubmit,
    formState,
    ...formMethods
  } = useForm<FormType>({
    defaultValues: { employmentItems },
  });

  const { fields, prepend } =
    useFieldArray<HREmployment>({
      control,
      name: 'employmentItems',
      keyName: 'id',
    });

  useEffect(() => {
    protectedEmpNo && dispatch(loadHREmployment(protectedEmpNo));
  }, [protectedEmpNo]);

  useEffect(() => {
    employmentStore && setEmploymentItems(employmentStore);
  }, [employmentStore]);

  useEffect(() => {
    if (!employmentItems) return;
    reset({
      employmentItems: employmentItems.map((e: HREmployment) => {
        return {
          ...e,
          supervisorPhone: e.supervisorPhone && formatPhone(e.supervisorPhone),
          empStartPay: (e.empStartPay && currencyFormatter(e.empStartPay)) || 0,
          empEndPay: (e.empEndPay && currencyFormatter(e.empEndPay)) || 0,
        };
      }),
    });
  }, [employmentItems]);

  const addEmployment = () => {
    prepend({
      employmentId: 0,
      protectedEmpNo,
      empStartPay: 0,
      empEndPay: 0,
    });
  };

  const deleteEmployment = (item: HREmployment) => {
    if (item.employmentId) {
      dispatch(deleteHREmployment({
        protectedEmpNo,
        data: item,
      }));
    } else {
      setEmploymentItems((prev: HREmployment[]) => { return prev.filter((cb: HREmployment) => { return cb.employmentId; }); });
    }
  };

  const onSubmit = (data: FormType) => {
    if (!data) return;
    
    data.employmentItems.forEach((item) => {
      const request: HREmploymentRequest = {
        protectedEmpNo,
        data: {
          ...item,
          employmentId: +item.employmentId,
          empStartDate: item?.empStartDate && item.empStartDate !== '' ? new Date(item.empStartDate).toISOString() : null,
          empEndDate: item?.empEndDate && item.empEndDate !== '' ? new Date(item.empEndDate).toISOString() : null,
          empStartPay: (item.empStartPay && fromCurrency('' + item.empStartPay)) || 0,
          empEndPay: (item.empEndPay && fromCurrency('' + item.empEndPay)) || 0,
        },
      };
      
      if (request.data.employmentId !== 0) {
        dispatch(updateHREmployment(request));
      } else {
        dispatch(createHREmployment(request));
      }
    });
  };

  const onCancel = () => {
    reset();
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: formState.isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [formState.isDirty]);

  return (

    <div className="dm-panel dm-panel-border" >
      <PanelHeader title="Employment" >
        <button
          className="btn btn-link dm-grid-action-title p-0 mr-2"
          onClick={addEmployment}
        >
          Add Employment	<Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button>
      </PanelHeader>
      <div>
        <form onSubmit={handleSubmit(onSubmit)} >
          {fields?.map((item, index: number) => {
            return (
              <div key={item.id} >
                <EmploymentItem
                  key={item.id}
                  item={item}
                  index={index}
                  control={control}
                  formMethods={formMethods}
                  onDelete={deleteEmployment}
                />
                <hr className="dm-panel-hr mt-2" />
              </div>
            );
          })}

          <div className="text-right mt-3">
            <button
              type="button"
              className="btn btn-primary orange-outline-button ml-auto mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              disabled={!formState.isDirty}
              type="submit"
              className="btn orange-button-sm mr-3"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmploymentPage;
