import React from 'react';
import Modal from './Modal';
import { FieldInputSettings } from '../form-controls/types';
import { useForm } from 'react-hook-form';
import { RadioGrp } from '../form-controls';

const fs: FieldInputSettings = {
  baseValue: {
    name: 'baseValue',
    label: 'Select rate to use for calculation:',
    defaultChecked: 'salaryRate',
    groupClass: 'gc95',
    radioOptions: [
      {
        value: 'annualRate',
        label: 'Use Annual',
      },
      {
        value: 'salaryRate',
        label: 'Use Salary',
      },
    ],
  },
};

type Props = {
  show: boolean;
  onHide: (confirm: boolean, data: { baseValue: 'salaryRate' | 'annualRate' | undefined }) => void;
};

const UpdatePayPeriodModal = ({ show, onHide }: Props) => {
  const { register, watch } = useForm<{ baseValue: 'annualRate' | 'salaryRate' }>({ defaultValues: { baseValue: 'salaryRate' } });
  const baseVal = watch('baseValue');
  
  return (
    <Modal
      show={show}
      onHide={() => { onHide(false, { baseValue: undefined }); }}
      title="Update Pay Rate"
    >
      {() => {
        return (
          <div className="d-flex flex-column">
            <RadioGrp
              {...fs.baseValue}
              ref={register}
            />
            <div className="d-flex w-100 justify-content-end">
              <button
                type="button"
                className="btn btn-primary orange-button mr-3"
                onClick={() => { onHide(false, { baseValue: undefined }); }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary orange-button"
                onClick={() => { onHide(true, { baseValue: baseVal }); }}
              >
                Okay
              </button>
            </div>
          </div>
        );        
      }}
    </Modal>
  );
};

export default UpdatePayPeriodModal;