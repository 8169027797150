import { createAction } from '@reduxjs/toolkit';
import { EmployeeUploadClientFile, EmployeeUploadClientLibrary, EmployeeUploadMap, EmployeeUploadTranslation, EmployeeUploadVM } from '../../models';

export const loadEmpUploads = createAction('[emp-upload] LOAD_UPLOADS');
export const storeEmpUploads = createAction<EmployeeUploadVM>('[emp-upload] STORE_UPLOADS');

export const createEmpUploadClientFile = createAction<EmployeeUploadClientFile>
('[emp-upload] CREATE_UPLOAD_CLIENT_FILE');
export const updateEmpUploadClientFile = createAction<EmployeeUploadClientFile>
('[emp-upload] UPDATE_UPLOAD_CLIENT_FILE');
export const storeEmpUpload = createAction<EmployeeUploadClientFile>('[emp-upload] STORE_UPLOAD');

export const createEmpUploadClientLibrary = createAction<EmployeeUploadClientLibrary>
('[emp-upload] CREATE_UPLOAD_CLIENT_LIBRARY');
export const storeEmpUploadClientLibrary = createAction<EmployeeUploadClientLibrary>
('[emp-upload] STORE_UPLOAD_CLIENT_LIBRARY');

export const storeSelectedEmpUploadClientFile = createAction<EmployeeUploadClientFile>
('[emp-upload] STORE_SELECTED_UPLOAD_CLIENT_FILE');
export const storeSelectedEmpUploadClientLibrary = createAction<EmployeeUploadClientLibrary>
('[emp-upload] STORE_SELECTED_UPLOAD_CLIENT_LIBRARY');

export const createEmpUploadField = createAction<EmployeeUploadMap>('[emp-upload] CREATE_UPLOAD_FIELD');
export const updateEmpUploadField = createAction<EmployeeUploadMap>('[emp-upload] UPDATE_UPLOAD_FIELD');
export const storeEmpUploadField = createAction<EmployeeUploadMap>('[emp-upload] STORE_UPLOAD_FIELD');
export const deleteEmpUploadFields = createAction<EmployeeUploadMap[]>('[emp-upload] DELETE_UPLOAD_FIELDS');

export const createEmpUploadTranslation = createAction<{ clientFileId: number, translation: EmployeeUploadTranslation }>
('[emp-upload] CREATE_UPLOAD_TRANSLATION');
export const addUpdateEmpUploadTranslation = createAction<{ clientFileId: number, mapId: number, translations: EmployeeUploadTranslation[] }>
('[emp-upload] ADD_UPDATE_UPLOAD_TRANSLATION');
export const updateEmpUploadTranslation = createAction<{ clientFileId: number, translation: EmployeeUploadTranslation }>
('[emp-upload] UPDATE_UPLOAD_TRANSLATION');
export const storeEmpUploadTranslations = createAction<{ clientFileId: number, mapId: number, translations: EmployeeUploadTranslation[] }>
('[emp-upload] STORE_UPLOAD_TRANSLATIONS');
export const storeEmpUploadTranslation = createAction<{ clientFileId: number, translation: EmployeeUploadTranslation }>
('[emp-upload] STORE_UPLOAD_TRANSLATION');
export const deleteEmpUploadTranslation = createAction<{ clientFileId: number, translations: EmployeeUploadTranslation[] }>
('[emp-upload] DELETE_UPLOAD_TRANSLATION');

