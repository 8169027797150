import React from 'react';
import Modal from 'core/components/modals/Modal';

type Props = {
  show: boolean;
  onHide(): void;
  onAccept(): void;
};

const AddInterviewConfirmationModal = ({
  show,
  onHide,
  onAccept,
}: Props) => {
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      title="Add Interview"
      size="sm"
    >
      <p>Are you sure you want to add this interview to the applicant&apos;s interview process?</p>
      <div className="d-flex align-items-center justify-content-end">
        <button
          type="button"
          className="btn btn-primary orange-outline-button mr-2 mt-2"
          onClick={onHide}
        >
          No
        </button>
        <button
          type="button"
          className="btn btn-primary orange-button mr-2 mt-2"
          onClick={onAccept}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
};

export default AddInterviewConfirmationModal;