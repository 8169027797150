import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ContractorOption, HttpResponse } from '../../models';
import { ContractorOptionsService } from '../../services';
import {
  handleError,
  handleSuccess,
  loadContractorOptions,
  storeContractorOptions,
  updateContractorOptions,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadContractorOptions$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadContractorOptions.type),
    switchMap((action: { payload: string; }) => {
      return from(
        ContractorOptionsService.getContractorOptions(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: ContractorOption[]) => { return storeContractorOptions(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateContractorOptions$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateContractorOptions.type),
    switchMap((action: { payload: ContractorOption[]; }) => {
      return from(
        ContractorOptionsService.putContractorOptions(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<ContractorOption[]>) => {
          return [
            storeContractorOptions(res.value),
            handleSuccess(res.messages),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [loadContractorOptions$, updateContractorOptions$];
