import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpResponse, YTD } from '../../models';
import { YearToDateService } from '../../services';
import { handleError, loadYearToDate, storeYearToDate } from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadYearToDate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadYearToDate.type),
    switchMap((action: { payload: { empNo: number; prYear: number; }; }) => {
      return from(
        YearToDateService.getYearToDate(
          action.payload.empNo,
          action.payload.prYear,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: YTD) => { return storeYearToDate(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [loadYearToDate$];
