import { createSelector } from 'reselect';
import { supervisedEmployeeState } from '../reducers';
import { State as SupervisedEmployeeState } from '../reducers/supervised-employee.reducer';

export const getSupervisedEmployees = createSelector(
  supervisedEmployeeState,
  (state: SupervisedEmployeeState): SupervisedEmployeeState => {
    return state;
  },
);
