import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Client, DtoOptions } from '../../core/models';
import { useAppSelector } from '../../utilities/hooks';
import InputReportDateSelector from '../../core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import { getCARESCreditReport } from 'core/store/selectors';
import { clearCARESCreditReport, downloadCARESCreditReport } from 'core/store/actions';
import { CARESCreditReportRequest } from 'core/models/PayrollReports.model';
import { CommonService } from 'core/services';
import { useForm } from 'react-hook-form';
import Icon from 'core/components/shared/Icon';

const CARESCreditReport = () => {

  const dispatch = useDispatch();

  const childRef: React.RefObject<any> = useRef();

  const report = useSelector(getCARESCreditReport);
  const client = useAppSelector((state) => { return state.client.client; }) as Client;
  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [fileType, setFileType] = useState<string>('pdf');

  const reportName = 'CARESCreditReport';

  const { handleSubmit } = useForm<CARESCreditReportRequest>();

  const downloadReport = () => {
    const submitData: CARESCreditReportRequest = {
      clientNo: client.clientNo,
      dtoOption: reportDates[0],
      reportType: fileType,
    };
    dispatch(downloadCARESCreditReport(submitData));
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearCARESCreditReport());
  };
  useEffect(() => {
    if (report && fileType === 'excel') {
      report && CommonService.downloadBase64File(
        'application/excel',
        report,
        `${reportName}.xlsx`,
      );
      dispatch(clearCARESCreditReport());
    }
  }, [report]);

  return (

    <div className="dm-panel dm-panel-border">
      <form onSubmit={handleSubmit(downloadReport)}>
        <div className="dm-grid-title">CARES Credit Report</div>
        <div className="w-100 text-right">
          <button
            type="submit"
            className="btn btn-link dm-grid-action-title "
            onClick={() => { return setFileType('excel'); }}
            disabled={!reportDates.length}>
            Download Report  <Icon name="download"
              className="fa-download" />
          </button>
        </div>
        <hr className="dm-panel-hr" />
        <div className="d-flex flex-row flex-grow-1">
          <div>
            <InputReportDateSelector
              ref={childRef}
              returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }}
              isSingleSelect={true}
              hideSelectAllBtn={true}
            />
          </div>
          <div className="flex-grow-1 text-right">
            <button
              type="button"
              className="orange-outline-button mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="orange-button mr-2"
              onClick={() => { return setFileType('pdf'); }}
              disabled={!reportDates.length}>
              Run Report
            </button>
          </div>
        </div>
      </form>
      {report ? <PDFViewerModal
        show={report.length > 0}
        pdfData={report}
        onHide={hidePdfViewer}
        reportName="CARESCreditReport"
      /> : null}
    </div>

  );



};
export default CARESCreditReport;


