import React from 'react';
import CheckBoxForm from '../../Shared/CheckBoxForm';
import { PageSection, UserMapSelectedField } from 'core/models/Downloader.model';
import { Col, Row } from 'react-bootstrap';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for Checkbox and Grid select forms
const HrFteFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  return (
    <Row>
      {!!sectionFields?.length && (
        <Col xs={12}>
          <CheckBoxForm
            formMethods={formMethods}
            dFields={sectionFields.find(f => {
              return f.name === 'FTE';
            })?.fields}
            section="FTE"
            title="FTE"
          />
        </Col>
      )}
    </Row>
  );
};

export default HrFteFields;