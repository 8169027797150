import React, { CSSProperties, useEffect, useState } from 'react';
import { InputGrp } from 'core/components/form-controls';
import { ClientAccess } from 'core/models/ClientAccess';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from 'core/components/shared/Icon';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import { putUserDefaultClient } from 'core/store/actions';

const toolTipStyles: CSSProperties = { fontSize: '0.75rem' };

type PropTypes = {
  onHide: (protectedClientNo: string) => void;
  onSelectProp: (clientNo: number) => void;
};

const AppChangeClient: React.FC<PropTypes> = ({ onHide, onSelectProp }) => {
  const { userAccess, userDefaultClient } = useAppSelector((state) => state.auth);
  
  const [availableClients, setAvailableClients] = useState<ClientAccess[]>(userAccess?.availableClients ?? []);
  const [query, setQuery] = useState<string>('');
  
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    setAvailableClients(userAccess?.availableClients?.filter((x) => {
      if (!(x?.clientName && x?.clientNo)) return false;
      return x?.clientName.toLowerCase().includes(query.toLowerCase())
            || x?.clientNo.toString().includes(query);
    }) ?? []);
  }, [userAccess?.availableClients, userDefaultClient]);
  
  // eslint-disable-next-line react/display-name
  const renderToolTip = (message: string) => (props: OverlayInjectedProps) => {
    return (
      <Tooltip
        id="info-tooltip"
        {...props}
      >
        <div style={toolTipStyles}>
          {message}
        </div>
      </Tooltip>
    );
  };
  
  const onSelect = (client: ClientAccess) => {
    onHide(client.protectedClientNo);
    onSelectProp(client.clientNo);
    
    sessionStorage.setItem('currentClientNo', String(client.clientNo));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setAvailableClients(
      userAccess?.availableClients?.filter(
        (a) => {
          if (!(a?.clientName && a?.clientNo)) return false;
          return a?.clientName.toLowerCase().includes(e.target.value.toLowerCase())
            || a?.clientNo.toString().includes(e.target.value);
        },
      ) ?? [],
    );
  };

  return (
    <>
      <div
        className=""
        onKeyDown={(e) => {
          if (e.key !== 'Enter' || availableClients?.length !== 1) return;
          onSelect(availableClients[0]);
        }}
      >
        <InputGrp
          onChange={onChange}
          placeholder={'Search'}
          autoFocus
          value={query}
        />
      </div>
      <div className="list-group">
        {[...availableClients]
          .sort((a, b) => {
            if (a.isDefaultClient === b.isDefaultClient) return a.clientNo - b.clientNo;
            return a.isDefaultClient ? -1 : 1;
          })
          .map((client) => {
            return (
              <button
                key={client.clientId}
                className="list-group-item list-group-item-action"
                type="button"
                onClick={() => { onSelect(client); }}
              >
                <div className="d-flex justify-content-between">
                  <div>{client.clientNo} - {client.clientName} {client.isTerminated ? <span style={{ color: 'red' }}>(Terminated)</span> : <></>}</div>
                  <div
                    className="d-flex"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {client.clientNo === userDefaultClient && (
                      <div className="d-flex font-weight-bold mr-2">
                        Default client&nbsp;
                        <OverlayTrigger
                          placement="top"
                          overlay={renderToolTip(`${client.clientNo} is the client you load into when you log in.`)}
                        >                
                          <div>
                            <Icon
                              name="question-circle"
                              className="p-0"
                              fontSize="0.75rem"
                            />  
                            <span className="sr-only">{client.clientNo} is the client you will load into when you log in.</span>
                          </div>
                        </OverlayTrigger>
                      </div>)}
                    <input
                      type="checkbox"
                      title="Set as default client"
                      alt="Set as default client"
                      checked={client.clientNo === userDefaultClient}
                      onChange={(e) => {
                      /* change this both locally and through the request. This way the checkbox will update instantly 
                      rather than waiting for the request, and if for some reason it fails it'll reset to the previous
                      value via the effect. */
                        if (userDefaultClient === client.clientNo) {
                          dispatch(putUserDefaultClient({ defaultClientNo: null }));
                        } else {
                          dispatch(putUserDefaultClient({ defaultClientNo: client.clientNo }));
                        }
                      }}
                    />
                  </div>
                </div>
              </button>
            );
          })}
      </div>
    </>
  );
};

export default AppChangeClient;
