import React, { useContext, FocusEvent, useState, useEffect, useMemo, useCallback } from 'react';
import { useAppSelector } from 'utilities/hooks';
import { InputGrp, SelectGrp } from 'core/components/form-controls';
import { useFieldArray } from 'react-hook-form';
import { DetailArrayField, DetailHour, DetailHourMap, TimeCardDate } from 'core/models';
import './time-card-styles.scss';
import { dateToString } from 'utilities/classUtils';
import { TimeCardContext } from './TimeCardContext';

type Props = {
  timeSheet: DetailArrayField;
  index: number;
};

/* 
  Note: This might look a little funky because we have the fields as well as the timeCardDates. The dates are grabbed
  off a separate request and matched on the dateId with the fields so that we have day and date info. So when modifying
  a field, if it's not already in the field array it's appended once the input is blurred. Any that aren't modified 
  will just be filtered out of the field array when submitting the form.  
 */
const TimeEntrySection = ({ timeSheet, index }: Props) => {
  const { earningsCode } = useAppSelector(({ dropdown }) => dropdown);
  const { timeCardDates } = useAppSelector(({ contractor }) => contractor);
  const payFrequency = useAppSelector(({ client }) => client.client?.clientPayrollFreq);
  const biWeeklyClient: boolean = payFrequency?.toLowerCase() === 'bi-weekly';
  
  const { defaultDetailHoursState, updateDirtyState, updateDetailHourState } = useContext(TimeCardContext);
  
  const detailHourMap: DetailHourMap = useMemo(() => structuredClone(defaultDetailHoursState), [defaultDetailHoursState]);
  const detailMapRecord: DetailHour[] = useMemo(() => detailHourMap?.[String(timeSheet.transmittalTimeCardDetailId)] ?? [], [detailHourMap]);
  
  const totalFields = useCallback((week: '1' | '2'): number => {
    const midpointId = timeCardDates?.[6]?.dateId; // this marks the end of week 1, used for comparison
    if (!midpointId) return 0;
    
    // week 1 dateIds <= midpointId, week 2 > midpointId. Filter/map/reduce fields to get totals for each week.
    return detailMapRecord
      ?.filter((hour) => {
        if (!(hour?.hours && ((hour?.dateId ?? 0) > 0))) return false;
        if (week === '1') return (hour?.dateId ?? 0) <= midpointId;
        return (hour?.dateId ?? 0) > midpointId;
      })
      ?.map((hour) => parseFloat(String(hour.hours)))
      ?.reduce((prev, current) => prev + current, 0) ?? 0;
  }, [timeCardDates, detailMapRecord]);
  
  const [weekOne, setWeekOne] = useState<TimeCardDate[]>(timeCardDates?.slice(0, 7));
  const [weekTwo, setWeekTwo] = useState<TimeCardDate[]>(timeCardDates?.slice(7));
  
  const [weekOneTotals, setWeekOneTotals] = useState<string>(totalFields('1').toFixed(2));
  const [weekTwoTotals, setWeekTwoTotals] = useState<string>(totalFields('2').toFixed(2));
  const [payCode, setPayCode] = useState<string>(detailMapRecord?.[0]?.earningsCode ?? '');
  
  useEffect(() => {
    setWeekOne(timeCardDates?.slice(0, 7));
    if (biWeeklyClient) setWeekTwo(timeCardDates?.slice(7));
  }, [timeCardDates]);
  
  useEffect(() => {
    setPayCode(detailMapRecord?.[0]?.earningsCode ?? ''); // these should all be the same
  }, [!!detailMapRecord?.[0]?.earningsCode]); // only set when this property goes from falsy to truthy
  
  useEffect(() => {
    setWeekOneTotals(totalFields('1').toFixed(2));
    if (biWeeklyClient) setWeekTwoTotals(totalFields('2').toFixed(2));
  }, [detailMapRecord]);
  
  /**
   * Updates a time entry record's hours. Finds the record to update in an array from a map-like object detailHourMap
   * and otherwise creates a new one with the entered hours. Updates the detail hour state, which is the object
   * detailHourMap is referencing. Note: this function is curried, so the event object is passed automatically when
   * called like modifyTimeEntryRecord(day, nestedIndex)
   * @param day TimeCardDate in this iteration; using the dateId to find the current record
   * @param nestedIndex Where the record is in the rendered array
   * @returns (e: FocusEvent<HTMLInputElement, Element>) => void
   */
  const modifyTimeEntryRecord = (day: TimeCardDate, nestedIndex: number) => (e: FocusEvent<HTMLInputElement, Element>) => {
    if (parseFloat(e.target.value) === 0 || e.target.value === '' || isNaN(parseFloat(e.target.value))) {
      e.target.value = (0).toFixed(2);
    }
    
    const match = detailMapRecord?.find((detailHour) => detailHour.dateId === day.dateId);
    if (!match) {
      const newRecord: DetailHour = {
        transmittalTimeCardDetailId: timeSheet.transmittalTimeCardDetailId ?? 0,
        transmittalTimeCardDetailHoursId: 0,
        dateId: day.dateId,
        hours: parseFloat(e.target.value === '' ? '0.00' : e.target.value),
        calendarDate: new Date(day.fullDate),
        //PI-8596 the earningsCode at the nested index was undefined when adding a new record causing '' to be stored. Use the pay code state if that has no value use old logic.
        earningsCode: (payCode) ? payCode : detailMapRecord?.[nestedIndex]?.earningsCode ?? '', // these are all the same
        notes: '',
      };
      const result = [...(detailMapRecord ?? []), newRecord];
      
      detailHourMap[String(timeSheet.transmittalTimeCardDetailId ?? 0)] = result;
      updateDetailHourState(detailHourMap);
      updateDirtyState(true);
      
      return;
    }
    
    const updatedHour: DetailHour = { ...match, hours: parseFloat(e.target.value === '' ? '0.00' : e.target.value) };
    const hourIndex = detailMapRecord?.findIndex((hour) => hour.dateId === match?.dateId);
    const clone = structuredClone(detailMapRecord);
    clone.splice(hourIndex, 1, updatedHour);
    
    detailHourMap[String(timeSheet.transmittalTimeCardDetailId ?? 0)] = clone;
    
    updateDetailHourState(detailHourMap);
    updateDirtyState(true);
    
    e.target.value = (parseFloat(e.target.value)).toFixed(2);
  };
  
  const updatePayCode = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPayCode(e.target.value);
    
    const result = structuredClone(detailMapRecord).map((detailHour) => ({
      ...detailHour,
      earningsCode: e.target.value,
    }));
    
    detailHourMap[String(timeSheet.transmittalTimeCardDetailId ?? 0)] = result;
    
    updateDetailHourState(detailHourMap);
    updateDirtyState(true);
  };
  
  return (
    <div className={`main-section ${biWeeklyClient && 'wide-section-3'}`}>
      <div className={`d-flex ${!biWeeklyClient && 'justify-content-between'}`}>
        <div className="section-title">Time Entry</div>
        <div
          className="section-title"
          style={biWeeklyClient ? { marginLeft: '22%' } : undefined}
        >
          {weekOneTotals}
        </div>
        {biWeeklyClient && <div
          className="section-title"
          style={{ marginLeft: '40%' }}
        >
          {weekTwoTotals}
        </div>}
      </div>
      <div className="column-wrapper">
        <div
          className="d-flex"
          style={{ gap: '4px' }}
        >
          <div className="dates-column">
            <span className="dm-form-label mb-2 mt-0">Day of Week</span>
            {weekOne?.map((day) => {
              return (
                <span
                  key={day.fullDate}
                  className="time-entry-day"
                >
                  <span className="day">{day.dayOfWeek}</span><span className="timecard-date">{day.fullDate}</span> 
                </span>
              );
            })}
            <span>Pay Code</span>
          </div>
          <div className={biWeeklyClient ? 'hours-column-1' : 'hours-column'}>
            <span className="dm-form-label mb-2 mt-0">Hours</span>
            {weekOne?.map((day, nestedIndex) => {
              const matchingField = detailMapRecord?.find((hour) => +(hour.dateId ?? 0) === day.dateId);
                
              return (
                <InputGrp
                  key={matchingField?.transmittalTimeCardDetailHoursId}
                  name={`details[${index}].hours[${nestedIndex}].hours`}
                  defaultValue={(matchingField?.hours)?.toFixed(2) ?? (0).toFixed(2)}
                  onBlur={modifyTimeEntryRecord(day, nestedIndex)}
                  inputStyle={{ height: '26px', textAlign: 'right' }}
                />
              );
            })}  
            <SelectGrp
              name="payCodeSelect"
              options={earningsCode}
              value={payCode}
              onChange={updatePayCode}
              selectStyles={{ height: '26px' }}
              showId
            />
          </div>
        </div>
        {biWeeklyClient && (
          <div
            className="d-flex"
            style={{ gap: '4px' }}
          >
            <div className="dates-column">
              <span className="dm-form-label mb-2 mt-0">Day of Week</span>
              {weekTwo?.map((day) => {
                return (
                  <span
                    key={day.fullDate}
                    className="time-entry-day"
                  >
                    <span className="day">{day.dayOfWeek}</span><span className="timecard-date">{day.fullDate}</span> 
                  </span>
                );
              })}
            </div>
            <div className="hours-column">
              <span className="dm-form-label mb-2 mt-0">Hours</span>
              {weekTwo?.map((day, nestedIndex) => {
                const weekAdjustedIndex = nestedIndex + 7;
                const matchingField = detailMapRecord?.find((hour) => +(hour.dateId ?? 0) === day.dateId);
                
                return (
                  <InputGrp
                    key={matchingField?.transmittalTimeCardDetailHoursId}
                    name={`details[${index}].hours[${weekAdjustedIndex}].hours`}
                    defaultValue={(matchingField?.hours)?.toFixed(2) ?? (0).toFixed(2)}
                    onBlur={modifyTimeEntryRecord(day, weekAdjustedIndex)}
                    inputStyle={{ height: '26px', textAlign: 'right' }}
                  />
                );
              })} 
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeEntrySection;