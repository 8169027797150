import DownloadIcon from '@mui/icons-material/Download';

//Since we just need to get to see if there is a length I am okay putting any. 
//I put this in shared because I had it in more than 2 spots so that means shared.
export const fileDownloadHeaderButton = (onClickDownload: () => void, selectedFiles: any[], buttonText: string = 'Download Selected Files') => 
    <div className="col align-items-end d-flex justify-content-end">
      <button
        type="button"
        onClick={onClickDownload}
        className="btn btn-link dm-grid-action-title p-0 mt-1"
        disabled={selectedFiles.length === 0}
      >
        {buttonText}&nbsp;
        <DownloadIcon />
      </button>
    </div>