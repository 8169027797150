import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import { Col, Collapse, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  InputGrp,
  SelectGrp,
  TextareaGrp,
} from 'core/components/form-controls';
import { PHONE_VALIDATION, UNSAVED_MESSAGE } from 'core/constants';
import { HrEmployeeEmergencyContact } from 'core/models/HrEmployeeEmergencyContact.model';
import {
  deleteHrEmployeeEmergencyContacts,
  postHrEmployeeEmergencyContacts,
  putHrEmployeeEmergencyContacts,
  removeHrEmployeeEmergencyContact,
} from 'core/store/slices/hr.slice';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { formatPhone } from 'utilities/utilities';
import { toggleBlockNavigation } from 'core/store/actions';

type Props = {
  emergencyContact: HrEmployeeEmergencyContact;
};

const EmergencyContact: React.FC<Props> = ({ emergencyContact }) => {
  const dispatch = useAppDispatch();
  const { register, formState, handleSubmit, errors, reset } =
    useForm<HrEmployeeEmergencyContact>({
      defaultValues: {
        ...emergencyContact,
        homePhone: formatPhone(emergencyContact.homePhone),
        cellPhone: formatPhone(emergencyContact.cellPhone),
        workPhone: formatPhone(emergencyContact.workPhone),
      },
    });
  const { isDirty } = formState;

  const stateDropDown = useAppSelector((state) => { return state.dropdown.state; });
  const countryDropDown = useAppSelector(
    (state) => { return state.dropdown.foreignCountry; },
  );

  const contactRelationshipDropDown = useAppSelector(
    (state) => { return state.dropdown.contactRelationship; },
  );

  const [showCollapse, setShowCollapse] = useState(false);

  useEffect(() => {
    reset({
      ...emergencyContact,
      homePhone: formatPhone(emergencyContact.homePhone),
      cellPhone: formatPhone(emergencyContact.cellPhone),
      workPhone: formatPhone(emergencyContact.workPhone),
    });
  }, [emergencyContact]);

  const deleteContact = () => {
    if (emergencyContact.contactId > 0) {
      dispatch(
        deleteHrEmployeeEmergencyContacts({
          contactId: emergencyContact.contactId,
          protectedEmpNo: emergencyContact.protectedEmpNo,
        }),
      );
    } else {
      dispatch(
        removeHrEmployeeEmergencyContact(emergencyContact.contactId),
      );
    }
  };

  const onSubmit = (data: HrEmployeeEmergencyContact) => {
    const mergedData = {
      ...emergencyContact,
      ...data,
    };
    if (mergedData.contactId > 0) {
      dispatch(putHrEmployeeEmergencyContacts(mergedData));
    } else {
      mergedData.contactId = 0;
      dispatch(postHrEmployeeEmergencyContacts(mergedData));
    }
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [isDirty]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xs="9">
          <Row className="no-gutters">
            <Col
              xs="auto"
              className="mr-1"
            >
              <InputGrp
                label="Contact #"
                ref={register({
                  required: {
                    value: true,
                    message: 'Contact # is required',
                  },
                  pattern: {
                    value: /^\d{1,2}$/,
                    message:
                      'Contact # must be a number between 0 and 99',
                  },
                  valueAsNumber: true,
                })}
                errors={errors.contactOrder}
                name="contactOrder"
                groupStyle={{ width: '70px' }}
              />
            </Col>
            <Col
              xs="auto"
              className="mr-1"
            >
              <InputGrp
                label="First Name"
                ref={register({
                  required: 'First Name is required',
                })}
                name="firstName"
                errors={errors.firstName}
              />
            </Col>
            <Col
              xs="auto"
              className="mr-1"
            >
              <InputGrp
                label="Mid. Initial"
                ref={register}
                name="middleInitial"
                groupStyle={{ width: '70px' }}
              />
            </Col>
            <Col
              xs="auto"
              className="mr-1"
            >
              <InputGrp
                label="Last Name"
                ref={register({
                  required: 'Last Name is required',
                })}
                name="lastName"
                errors={errors.lastName}
              />
            </Col>
            <Col
              xs="auto"
              className="mr-1"
            >
              <SelectGrp
                label="Relationship"
                ref={register({
                  valueAsNumber: true,
                })}
                noOption={true}
                noOptionValue={0}
                addOptionText="Relationship"
                name="relationshipId"
                options={contactRelationshipDropDown}
                defaultValue={emergencyContact.relationshipId}
              />
            </Col>
            <Col
              xs="auto"
              className="mr-1"
            >
              <InputGrp
                label="Cell Phone"
                name="cellPhone"
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement>,
                ) => {
                  const { value } = e.target;
                  e.target.value = formatPhone(value);
                }}
                errors={errors.cellPhone}
                ref={register({
                  pattern: {
                    value: PHONE_VALIDATION,
                    message: 'Invalid phone number',
                  },
                })}
              />
            </Col>
            <Col
              xs="auto"
              className="mr-1"
            >
              <InputGrp
                label="Home Phone"
                name="homePhone"
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement>,
                ) => {
                  const { value } = e.target;
                  e.target.value = formatPhone(value);
                }}
                errors={errors.homePhone}
                ref={register({
                  pattern: {
                    value: PHONE_VALIDATION,
                    message: 'Invalid phone number',
                  },
                })}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xs="3"
          className="mt-2"
        >
          <button
            type="button"
            className="btn mr-2"
            onClick={deleteContact}
          >
            Delete Contact{' '}
            <Icon
              name="minus-circle"
              className="fa-minus-circle"
            />
          </button>
          <button
            className="btn orange-button"
            disabled={!isDirty}
          >
            Save
          </button>
        </Col>
      </Row>
      <div
        className="dm-subtitle2"
        role="button"
        onClick={() => {
          setShowCollapse(!showCollapse);
        }}
      >
        <Icon name={showCollapse ? 'chevron-down' : 'chevron-right'} /> Contact Details
      </div>
      <Collapse in={showCollapse}>
        <div className="row bg-info">
          <div className="col-sm-9">
            <div className="row no-gutters">
              <div className="col mr-1">
                <InputGrp
                  label="Street Address"
                  ref={register}
                  name="address"
                />
              </div>
              <div className="col mr-1">
                <div className="d-flex flex-column">
                  <InputGrp
                    label="City"
                    ref={register}
                    name="city"
                  />
                </div>
              </div>
              <div className="col mr-1">
                <InputGrp
                  label="Work Phone"
                  name="workPhone"
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                  ) => {
                    const { value } = e.target;
                    e.target.value = formatPhone(value);
                  }}
                  ref={register({
                    pattern: PHONE_VALIDATION,
                  })}
                />
              </div>
            </div>
            <div className="row no-gutters">
              <div className="row no-gutters">
                <div className="col mr-1 d-flex flex-row">
                  <SelectGrp
                    label="State"
                    ref={register}
                    name="state"
                    options={stateDropDown}
                    groupClass="mr-1"
                    addEmptyText={true}
                  />
                  <InputGrp
                    label="Zip"
                    ref={register}
                    name="zip"
                    groupClass="mr-1"
                  />
                  <InputGrp
                    label="&nbsp;"
                    ref={register}
                    name="zip4"
                  />
                </div>
                <div className="col">
                  <SelectGrp
                    label="Country"
                    ref={register}
                    name="country"
                    options={countryDropDown}
                    addEmptyText={true}
                  />
                </div>
                <div className="col"></div>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col mr-1">
                <InputGrp
                  label="Home Email"
                  ref={register}
                  name="homeEmail"
                  type="email"
                />
              </div>
              <div className="col mr-1">
                <InputGrp
                  label="Work Email"
                  ref={register}
                  name="workEmail"
                  type="email"
                />
              </div>
              <div className="col">
                <InputGrp
                  label="Other Email"
                  ref={register}
                  name="otherEmail"
                  type="email"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <TextareaGrp
              label="Notes"
              ref={register}
              name="notes"
            />
          </div>
        </div>
      </Collapse>
      <hr />
    </form>
  );
};

export default EmergencyContact;
