import React, { useEffect } from 'react';
import Modal from 'core/components/modals/Modal';
import { ModalProps } from '../employee/EmployeeToolbar';
import { useDispatch } from 'react-redux';
import PdfViewer from 'core/components/shared/PDFViewer/PdfViewer';
import { clearEmployeeMasterReport } from 'core/store/actions';
import { useAppSelector } from 'utilities/hooks';

const EmployeeMasterReportModal: React.FC<ModalProps> = ({ show, onHide }) => {
  const dispatch = useDispatch();

  const { employeeMasterReport, loading } = useAppSelector(({ employeeReports }) => employeeReports);
  
  const clearAndHide = () => {
    dispatch(clearEmployeeMasterReport());
    onHide();
  };
  
  useEffect(() => {

  });

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        size="xl"
        show={show}
        onHide={clearAndHide}
        title="Employee Master Report PDF (Preview)"
      >
        {employeeMasterReport?.value?.length && !loading && (
          <PdfViewer
            pdfData={employeeMasterReport.value}
            reportName="EmployeeMasterReport"
          />
        )}
      </Modal>
    </div>
  );
};

export default EmployeeMasterReportModal;
