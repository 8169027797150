import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, ColDef, GridOptions } from '@ag-grid-enterprise/all-modules';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import WorkgroupForm from './WorkgroupForm';
import { WorkgroupItem } from '../../../core/models';
import AGEditButtonRendererComponent from '../../../utilities/ag-grid-renderers/AGEditButtonRendererComponent';
import Modal from 'core/components/modals/Modal';
import Icon from 'core/components/shared/Icon';

type PropTypes = {
  show: boolean;
  onHide: () => void;
  workgroups: any
};

const ManageWorkgroupsModal: React.FC<PropTypes> = ({ show, onHide, workgroups }) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [addEditItem, setAddEditItem] = useState<any>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const addItem = (e: any) => {
    e?.stopPropagation();
    setAddEditItem(new WorkgroupItem(0));
    setIsEdit(false);
    setShowAddForm(true);
  };

  const onEdit = (data: any) => {
    setAddEditItem(data);
    setIsEdit(true);
    setShowAddForm(true);
  };

  const columns: ColDef[] = [{
    field: 'levelName',
    headerName: 'LevelName',
    width: 150,
  }, {
    field: 'name',
    headerName: 'Name',
    width: 200,
  }, {
    field: 'code',
    headerName: 'Code',
    width: 90,
  }, {
    field: 'edit',
    headerName: 'Edit',
    cellRendererParams: { clickHandler: onEdit },
    cellRenderer: 'editButtonRendererComponent',
    cellClass: 'ag-cell-border',
    width: 50,
  }];

  const gridOptions: GridOptions = {
    columnDefs: columns,
    defaultColDef: {
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    // @ts-ignore
    frameworkComponents: {
      editButtonRendererComponent: AGEditButtonRendererComponent,
    },
    pagination: true,
    paginationPageSize: 10,
  };

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        title="Manage Workgroups"
      >

        {!showAddForm ? (
          <Row>
            <Col className="text-right pb-2"
              onClick={addItem}>
              <button className="btn btn-link dm-grid-action-title p-0 mr-2"
                onClick={addItem}>
                <span className="dm-grid-action-title">ADD Workgroup</span>
                <Icon name="plus-circle"
                  className="fa-plus-circle" /></button>
            </Col>
          </Row>
        ) : (<WorkgroupForm item={addEditItem}
          onHide={() => { return setShowAddForm(false); }}
          workgroups={workgroups}
          isEdit={isEdit} />)}
        <Col xs={12}
          className="table-wrapper-wrapper ag-theme-balham"
          style={{ height: '400px' }}>
          <AgGridReact
            gridOptions={gridOptions}
            rowData={workgroups}
            modules={AllModules}
          ></AgGridReact>
        </Col>

      </Modal>
    </div >
  );
};

export default ManageWorkgroupsModal;
