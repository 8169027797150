import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CheckCode, HttpResponse } from '../../models';
import { DropdownService } from '../../services';
import { handleError, loadCheckCodes, storeCheckCodes } from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadCheckCodes$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadCheckCodes.type),
    switchMap(() => {
      return from(DropdownService.getCheckCodes()).pipe(
        map((res: any) => {return res.data;}),
        map((res: CheckCode[]) => {return storeCheckCodes(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err)];}),
      );
    },
    ),
  );
};

export const epics: any[] = [loadCheckCodes$];
