import React from 'react';
import { useSelector } from 'react-redux';
import { CheckboxGrp, InputGrp, Labels } from 'core/components/form-controls';
import { SelectGrp } from 'core/components/form-controls/SelectGrp';
import { getIsFinishedPayroll } from 'core/store/selectors';
import Icon from 'core/components/shared/Icon';
import { formatWithCommas } from 'utilities/utilities';

const stateWh = [1];
const stateAddTaxes = [2, 3];

type Props = {
  index: number; 
  errors: any;
  payrollHistoryId: number;
};

const StateWithholdings: React.FC<Props> = ({
  payrollHistoryId,
  errors,
  index,
}) => {
  const isFinishedPayroll = useSelector(getIsFinishedPayroll);

  const onAdd = () => { };
  
  const onDelete = (id: number) => {

  };

  return (
    <>
      <div className="text-right mt-2 mb-2">
        {!isFinishedPayroll ? <button
          type="button"
          className="btn btn-link dm-grid-action-title p-0"
          onClick={onAdd}
          disabled={false}
        >
          Add Withholding
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button> : null}
      </div>
      <hr />
      {stateWh.map((stateWithholding, si) => {
        return (
          <div key={stateWithholding}>
            <div className="d-flex flex-row">
              <SelectGrp
                groupClass="gc04 mw175 mt-4"
                options={[]}
                name={`items[${index}].stateWithholdings[${si}].taxEntityId`}
                defaultValue={''}
                disabled={false}
              />
              <InputGrp
                groupClass="gc03 number-underline mw75"
                label="Amount"
                name={`items[${index}].stateWithholdings[${si}].withholding`}
                defaultValue={formatWithCommas(0)}
                disabled={false}
              />
              <InputGrp
                groupClass="gc03 number-underline mw75"
                label="Gross"
                type="text"
                name={`items[${index}].stateWithholdings[${si}].gross`}
                defaultValue={formatWithCommas(0)}
                disabled={true}
              />
              {(
                <button
                  type="button"
                  className="btn btn-link dm-grid-action-title"
                  onClick={() => { onDelete(si); }}
                  disabled={false}
                >
                  <Icon
                    name="minus-circle"
                    className="fa-minus-circle"
                  />
                </button>
              )}
            </div>
            <div>
              <table className="table table-striped table-hover w-100">
                <thead>
                  <tr>
                    <th style={{ width: '125px' }}>Description</th>
                    <th style={{ width: '125px' }}>Amount</th>
                    <th style={{ width: '125px' }}>Gross</th>
                    <th style={{ width: '50px' }}>EE Tax</th>
                  </tr>
                </thead>
                <tbody>
                  {stateAddTaxes?.map(
                    (aw, awi) => {
                      return (
                        <tr key={aw}>
                          <td style={{ padding: '0.4rem' }}>
                            <Labels
                              label={'[Description]'}
                              hasError={false}
                            />
                          </td>
                          <td>
                            <InputGrp
                              groupClass="number-underline"
                              type="text"
                              name={`items[${index}].stateWithholdings[${si}].addtlWithholdings[${awi}].withholding`}
                              defaultValue={formatWithCommas(0)}
                            />
                          </td>
                          <td>
                            <InputGrp
                              groupClass="number-underline"
                              type="text"
                              name={`items[${index}].stateWithholdings[${si}].addtlWithholdings[${awi}].gross`}
                              defaultValue={formatWithCommas(0)}
                              disabled={true}
                            />
                          </td>
                          <td>
                            <CheckboxGrp
                              name={`items[${index}].stateWithholdings[${si}].addtlWithholdings[${awi}].isEmployeeTax`}
                              checked={true}
                              disabled={true}
                            />
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default StateWithholdings;
