import { ApplicantStatus } from 'enums/ApplicantStatus';

export const statusOptions = [
  // {
  //   value: ApplicantStatus.REJECT,
  //   label: 'Reject',
  // },
  {
    value: ApplicantStatus.NEW_APPLICANT,
    label: 'New Applicant',
  },
  {
    value: ApplicantStatus.INTERVIEW,
    label: 'Interview',
  },
  {
    value: ApplicantStatus.HIRE,
    label: 'Offer',
  },
  {
    value: ApplicantStatus.ONBOARD,
    label: 'Onboard',
  },
  // {
  //   value: ApplicantStatus.EMPLOYEE,
  //   label: 'Employee',
  // },
];