import { GenericFieldInputSettings } from 'core/components/form-controls/types';
import { PayrollTimeCardJobInfo } from 'core/models/Contractor.model';

// Form config
export const fieldSettings: GenericFieldInputSettings<Partial<PayrollTimeCardJobInfo>> = {
  jobNo: {
    name: 'jobNo',
    label: 'Job Number',
  },
  description: {
    name: 'description',
    label: 'Description',
  },
  projectNo: {
    name: 'projectNo',
    label: 'Project Number',
  },
  name: {
    name: 'name',
    label: 'Name',
  },
  address1: {
    name: 'address1',
    label: 'Address 1',
  },
  address2: {
    name: 'address2',
    label: 'Address 2',
  },
  city: {
    name: 'city',
    label: 'City',
  },
  state: {
    name: 'state',
    label: 'State',
  },
  zip: {
    name: 'zip',
    label: 'ZIP',
  },
  zipPlus4: {
    name: 'zipPlus4',
    label: ' ',
  },
  county: {
    name: 'county',
    label: 'County',
  },
  taxCityId: {
    name: 'taxCityId',
  },
  taxCityCode: {
    name: 'taxCityCode',
    label: 'Taxing City',
  },
  bricklayerCementMasonRate: {
    name: 'bricklayerCementMasonRate',
    label: 'Bricklayer Cement Mason Rate',
  },
  startDate: {
    name: 'startDate',
    label: 'Date Started',
  },
  closeDate: {
    name: 'closeDate',
    label: 'Date Closed',
  },
  approvedForSpecialUnionRate: {
    name: 'approvedForSpecialUnionRate',
  },
  certYN: {
    name: 'certYN',
  },
  activeYN: {
    name: 'activeYN',
  },
  excludeFromWc: {
    name: 'excludeFromWc',
  },
  showAddressOnCertifiedReport: {
    name: 'showAddressOnCertifiedReport',
  },
  isOcip: {
    name: 'isOcip',
  },
  isSubContractor: {
    name: 'isSubContractor',
  },
  certIncAll: {
    name: 'certIncAll',
  },
  useDavisBaconWage: {
    name: 'useDavisBaconWage',
  },
  subjectToExecutiveOrder: {
    name: 'subjectToExecutiveOrder',
  },
  isNationalJob: {
    name: 'isNationalJob',
  },
  procoreCostCode: {
    name: 'procoreCostCode',
    label: 'Procore Cost Code',
  },
};

// Grid config
