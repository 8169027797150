import React, { useEffect, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GridOptions, GridReadyEvent, GridSizeChangedEvent,  ValueGetterParams } from '@ag-grid-enterprise/all-modules';
import { ControlDatePickerGrp, SelectGrp } from 'core/components/form-controls';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useEffectOnMount } from 'utilities/hooks';
import { DateTime } from 'luxon';
import '../corrections.scss';
import { convToDateString, ddLookup } from 'utilities/utilities';
import {
  Dropdown, 
  CorrectionsGetRequest,
  EEOInfoCorrection, 
  MescIdsArray, 
  UpdateEEOInfoRequest, 
  UpdateWorkersCompRequest, 
  WorkersCompCorrection,
} from 'core/models';
import {
  clearCorrections,
  getEEOInfoCorrections, 
  getWorkersCompCorrections, 
  putEEOInfoCorrections, 
  putWorkersCompCorrections, 
} from 'core/store/actions';

type Props = {
  empNos: string;
};
type FormProps = {
  beginDate: Date;
  endDate: Date;
  workCompCode: Dropdown | null;
  isOfficer: Dropdown | null;
  eeoRace: Dropdown | null;
  eeoOccupGrp: Dropdown | null;
  eeoUnitNo: Dropdown | null;
};

const officerOptions: Dropdown[] = [
  { id: 'Y',
    description: 'Yes' },
  { id: 'N',
    description: 'No' },
];

const CorrectionsList = ({ empNos }: Props) => {
  const columns: ColDef[] = [
    {
      field: 'checkDate',
      headerName: 'Check Date',
      sortable: true,
      valueGetter: (params: ValueGetterParams) => {
        return convToDateString(new Date(params.data.checkDate));
      },
    },
    {
      field: 'weekEnd',
      headerName: 'Week End',
      sortable: true,
      filter: true,
      suppressMenu: false,
      valueGetter: (params: ValueGetterParams) => {
        return convToDateString(new Date(params.data.weekEnd));
      },
    },
    {
      field: 'empNo',
      headerName: 'Emp #',
      sortable: true,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressMenu: false,
    },
    {
      field: 'midName',
      headerName: 'MI',
      suppressMenu: true,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressMenu: false,
    },
    {
      field: 'checkNo',
      headerName: 'Check Number',
    },
  ];
  
  const fieldNames = {
    workCompCode: 'worker comp. code',
    isOfficer: 'officer status',
    eeoRace: 'EEO race',
    eeoOccupGrp: 'EEO occup. group',
    eeoUnitNo: 'EEO unit no.',
  };

  const gridOptions: GridOptions = {
    columnDefs: columns,
    domLayout: 'autoHeight',
    defaultColDef: {
      flex: 1,
      autoHeight: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    pagination: true,
    paginationPageSize: 20,
  };
  
  const [rowData, setRowData] = useState<(EEOInfoCorrection | WorkersCompCorrection)[]>([]);
  const [mescIds, setMescIds] = useState<MescIdsArray>([]);
  
  const dispatch = useAppDispatch();
  
  const { pathname } = useLocation();
  const isEeo = pathname.includes('eeo-info');
  const getCorrectionsData = isEeo ? getEEOInfoCorrections : getWorkersCompCorrections;

  const { control, register, watch, errors, formState: { isDirty, dirtyFields } } = useForm<FormProps>({
    defaultValues: {
      beginDate: DateTime.local().startOf('year').toISO(),
      endDate: DateTime.local().endOf('year').toISO(),
      workCompCode: null,
      isOfficer: null,
      eeoRace: null,
      eeoOccupGrp: null,
      eeoUnitNo: null,
    },
  });
  const {
    beginDate: formBeginDate,
    endDate: formEndDate,
    workCompCode,
    isOfficer,
    eeoRace,
    eeoOccupGrp,
    eeoUnitNo,
  } = watch();
  
  const { eeoInfoCorrections, workersCompCorrections } = useAppSelector(({ corrections }) => corrections);
  const {
    eeoCode: eeoRaceOptions,
    eeoOccupationalGroup: eeoOccupGroupOptions,
    eeoUnitNo: eeoUnitNoOptions,
    workersCompensation: workersCompCodeOptions,
  } = useAppSelector(({ dropdown }) => dropdown);
  
  useEffect(() => {
    if (pathname.includes('workers-comp')) {
      columns.push(
        {
          field: 'wcCode',
          headerName: 'Worker Comp Code',
          valueGetter: (params: ValueGetterParams) => {
            return ddLookup(String(params.data.wcCode).trim(), workersCompCodeOptions);
          },
        },
        {
          field: 'isOfficer',
          headerName: 'Is Officer',
          valueGetter: (params: ValueGetterParams) => {
            return ddLookup(params.data.isOfficer, officerOptions);
          },
        },
      );
    } else if (pathname.includes('eeo-info')) {
      columns.push(
        {
          field: 'eeoRace',
          headerName: 'EEO Race',
          valueGetter: (params: ValueGetterParams) => { // TODO: we can probably just make this one valueGetter w/ extra params
            return ddLookup(params.data.eeoRace, eeoRaceOptions);
          },
        },
        {
          field: 'eeoOccupGroup',
          headerName: 'EEO Occup. Grp',
          valueGetter: (params: ValueGetterParams) => {
            return ddLookup(params.data.eeoOccupGroup, eeoOccupGroupOptions);
          },
        },
        {
          field: 'eeoUnitNo',
          headerName: 'EEO Unit No.',
          valueGetter: (params: ValueGetterParams) => {
            return ddLookup(params.data.eeoUnitNo, eeoUnitNoOptions);
          },
        },
      );
    }
  }, [pathname]);
  
  useEffect(() => {
    let corrections: (WorkersCompCorrection | EEOInfoCorrection)[] = [];
    
    if (pathname.includes('workers-comp')) {
      corrections = workersCompCorrections; 
      setRowData(workersCompCorrections);
    } else if (pathname.includes('eeo-info')) {
      corrections = eeoInfoCorrections;
      setRowData(eeoInfoCorrections);
    }
    if (!corrections.length) return;
    
    const mescIdMap = corrections?.map((correction) => { return correction.mescId; });
    setMescIds([...new Set(mescIdMap)]);
  }, [workersCompCorrections, eeoInfoCorrections]);
  
  // emulates componentDidMount lifecycle hook
  useEffectOnMount(() => {
    if (empNos === '') {
      clearData();
      return;
    }
    
    const request: CorrectionsGetRequest = {
      empNos,
      beginDate: new Date(formBeginDate).toISOString().slice(0, -1),
      endDate: new Date(formEndDate).toISOString().slice(0, -1),
    };
    
    dispatch(getCorrectionsData(request));
  }, [empNos, formBeginDate, formEndDate]);
  
  // emulates componentWillUnmount lifecycle hook
  useEffect(() => {
    return () => { dispatch(clearCorrections()); };
  }, []);
  
  const onGridReady = (params: GridReadyEvent) => {
    params.api.sizeColumnsToFit();
  };
  
  const resizeColumns = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit();
  };
  
  const convertUndefinedValue = (value: string | undefined): string | null => {
    if (['', 'null', undefined].includes(value?.trim())) {
      return null;
    } else if (value === 'clear') {
      return '';
    }
    return value as string | null;
  };
  
  const updateMescRecords = () => {
    const updatedFields = Object.keys(dirtyFields)
      .map((field) => {
        if (!dirtyFields[field as keyof typeof dirtyFields]) return console.warn('No dirty fields detected');
        const value = fieldNames[field as keyof typeof fieldNames];
        if (!value) return;
        return value;
      })
      .filter((field) => {return !!field;});
    
    if (updatedFields?.length && !confirm(`Update ${updatedFields.join(', ')} for all grid rows?`)) return console.warn('User aborted save');
    if (pathname.includes('eeo-info')) {
      const request: UpdateEEOInfoRequest = {
        newEEORace: convertUndefinedValue(eeoRace?.toString()),
        newEEOOccupGroup: convertUndefinedValue(eeoOccupGrp?.toString()),
        newEEOUnitNo:convertUndefinedValue(eeoUnitNo?.toString()),
        mescIdsToUpdate: mescIds,
      };
      dispatch(putEEOInfoCorrections(request));
    } else {
      const request: UpdateWorkersCompRequest = {
        newWcCode: convertUndefinedValue(workCompCode?.toString()),
        newIsOfficer: convertUndefinedValue(isOfficer?.toString()),
        mescIdsToUpdate: mescIds,
      };
      dispatch(putWorkersCompCorrections(request)); 
    }
  };
  
  const clearData = () => {
    setRowData([]);
    setMescIds([]);
  };
  
  const addDropdowns = () => {
    switch (pathname) {
      case '/workers-comp':
        return (
          <>
            <SelectGrp
              name="workCompCode"
              label="New worker comp. code"
              groupClass="groupClassAuto"
              options={[{ description: '',
                id: 'null' }, { description: '<Blank>',
                id: 'clear' }, ...workersCompCodeOptions]}
              ref={register}
            />
            <SelectGrp
              name="isOfficer"
              label="Is Officer"
              options={officerOptions}
              addEmptyValue
              ref={register}
            />
          </>
        );
      case '/eeo-info':
        return (
          <>
            <SelectGrp
              options={[{ description: '',
                id: 'null' }, { description: '<Blank>',
                id: 'clear' }, ...eeoRaceOptions]}
              groupClass="w-25"
              name="eeoRace"
              label="EEO Race:"
              ref={register}
            />
            <SelectGrp
              options={[{ description: '',
                id: 'null' }, { description: '<Blank>',
                id: 'clear' }, ...eeoOccupGroupOptions]}
              groupClass="w-25"
              name="eeoOccupGrp"
              label="EEO Occup. Grp:"
              ref={register}
            />
            <SelectGrp
              options={[{ description: '',
                id: 'null' }, { description: '<Blank>',
                id: 'clear' }, ...eeoUnitNoOptions]}
              name="eeoUnitNo"
              label="EEO Unit No:"
              ref={register}
            />
          </>
        );
    }
  };
  
  return (
    <>
      <div className="select-list-wrapper shadow border p-1">                 
        <div className="inputs-container selected-inputs m-2">
          <div className="d-flex flex-column w-25">
            <span className="dm-grid-action-title">For payrolls dated between</span>
            <div className="range-group">            
              <ControlDatePickerGrp
                groupClass="w-50"
                name="beginDate"
                label="From:"
                control={control}
                errors={errors.beginDate}
              />
              <ControlDatePickerGrp
                groupClass="w-50"
                name="endDate"
                label="To:"
                control={control}
                errors={errors.endDate}
              />
            </div>
          </div>
          <div className="d-flex flex-column w-50">
            <span className="dm-grid-action-title">New value(s)</span>
            <div className="inputs-container selected-inputs align-items-center">
              {addDropdowns()}
              <button
                className="btn btn-primary h-100 ml-3"
                onClick={updateMescRecords}
                disabled={!(rowData?.length && isDirty)}
                aria-disabled={!(rowData?.length && isDirty)}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="table-wrapper-wrapper ag-theme-balham">
        <AgGridReact
          gridOptions={gridOptions}
          rowData={rowData}
          onGridReady={onGridReady}
          onGridSizeChanged={resizeColumns}
        />
      </div>
    </>
  );
};

export default CorrectionsList;