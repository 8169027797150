import { ofType } from 'redux-observable';
import { Observable, forkJoin, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpResponse } from '../../models';
import {
  PayrollUpload,
  PayrollUploadFieldTranslation,
  PayrollUploadEarnCodeExceptionMap,
  PayrollUploadEarnCodeRateMap,
  PayrollUploadFile,
  PayrollUploadMap,
  PayrollUploadVM,
  OnTheClockRequest,
  TimeCard,
  AodPayrollUploadMap,
  PayrollUploadDefault,
  PayrollUploadFileCheck,
  PayrollUploadManageFiles,
} from '../../models/PayrollUpload.model';
import { PayrollUploadService } from '../../services/payroll-upload.service';
import {
  createPayrollUploadException,
  createPayrollUploadField,
  createPayrollUploadFile,
  createPayrollUploadRate,
  createPayrollUploadTranslation,
  deletePayrollUploadException,
  deletePayrollUploadFields,
  deletePayrollUploadRate,
  deletePayrollUploadTranslation,
  handleError,
  loadPayrollUpload,
  storePayrollUploadVM,
  storePayrollUploadException,
  storePayrollUploadRate,
  updatePayrollUploadException,
  updatePayrollUploadField,
  updatePayrollUploadFile,
  updatePayrollUploadRate,
  updatePayrollUploadTranslation,
  payrollUploadFileTool,
  handleSuccess,
  storePayrollUploadFileToolErrors,
  loadOnTheClockData,
  storeOnTheClockData,
  loadAodPayrollUploadMaps,
  storeAodPayrollUploadMaps,
  createDefaultPayrollUploadFile,
  storePayrollUploadFileToolCheckErrors,
  payrollUploadFileCheckTool,
  savePayrollUploadMapActiveStatuses,
  copyPayrollUploadMap,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadPayrollUpload$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadPayrollUpload.type),
    switchMap(() => {
      return from(PayrollUploadService.getPayrollUpload()).pipe(
        map((res: any) => { return res.data; }),
        map((res: PayrollUploadVM) => { return storePayrollUploadVM(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createPayrollUploadFile$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createPayrollUploadFile.type),
    switchMap((action: { payload: PayrollUpload; }) => {
      return from(PayrollUploadService.postPayrollUpload(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap(() => {
          return [loadPayrollUpload(),
            handleSuccess('Payroll Upload has been successfully created.'),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createDefaultPayrollUploadFile$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createDefaultPayrollUploadFile.type),
    switchMap((action: { payload: PayrollUploadDefault; }) => {
      return from(PayrollUploadService.postDefaultPayrollUpload(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap(() => {
          return [loadPayrollUpload(),
            handleSuccess('Payroll Upload has been successfully created.'),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updatePayrollUploadFile$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updatePayrollUploadFile.type),
    switchMap((action: { payload: PayrollUpload; }) => {
      return from(PayrollUploadService.putPayrollUpload(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap(() => {
          return [loadPayrollUpload(),
            handleSuccess('Payroll Upload has been successfully saved.'),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createPayrollUploadField$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createPayrollUploadField.type),
    switchMap((action: { payload: PayrollUploadMap; }) => {
      return from(
        PayrollUploadService.postPayrollUploadField(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        map(() => { return loadPayrollUpload(); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updatePayrollUploadField$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updatePayrollUploadField.type),
    switchMap((action: { payload: PayrollUploadMap; }) => {
      return from(
        PayrollUploadService.putPayrollUploadField(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        map(() => { return loadPayrollUpload(); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deletePayrollUploadFields$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deletePayrollUploadFields.type),
    switchMap((action: { payload: PayrollUploadMap[]; }) => {
      return forkJoin(
        action.payload.map((x: PayrollUploadMap) => { return from(PayrollUploadService.deletePayrollUploadField(x)); },
        ),
      ).pipe(
        map((res: any) => { return loadPayrollUpload(); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }))
    },
    ),
  );
};

const createPayrollUploadTranslation$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createPayrollUploadTranslation.type),
    switchMap((action: { payload: { mapId: number; customUploadMapId: number; translation: PayrollUploadFieldTranslation; }; }) => {
      return from(
        PayrollUploadService.postPayrollUploadFieldTranslation(
          action.payload.mapId,
          action.payload.customUploadMapId,
          action.payload.translation,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return loadPayrollUpload(); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updatePayrollUploadTranslation$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updatePayrollUploadTranslation.type),
    switchMap((action: { payload: { mapId: number; customUploadMapId: number; translation: PayrollUploadFieldTranslation; }; }) => {
      return from(
        PayrollUploadService.putPayrollUploadFieldTranslation(
          action.payload.mapId,
          action.payload.customUploadMapId,
          action.payload.translation,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return loadPayrollUpload(); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deletePayrollUploadTranslation$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deletePayrollUploadTranslation.type),
    switchMap((action: { payload: { mapId: number; customUploadMapId: number; translation: PayrollUploadFieldTranslation; }; }) => {
      return from(
        PayrollUploadService.deletePayrollUploadFieldTranslation(
          action.payload.mapId,
          action.payload.customUploadMapId,
          action.payload.translation,
        ),
      ).pipe(
        mergeMap(() => { return []; }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createPayrollUploadException$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createPayrollUploadException.type),
    switchMap((action: { payload: { mapId: number; customUploadMapId: number; exception: PayrollUploadEarnCodeExceptionMap; }; }) => {
      return from(
        PayrollUploadService.postPayrollUploadFieldException(
          action.payload.mapId,
          action.payload.customUploadMapId,
          action.payload.exception,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storePayrollUploadException(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updatePayrollUploadException$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updatePayrollUploadException.type),
    switchMap((action: { payload: { mapId: number; customUploadMapId: number; exception: PayrollUploadEarnCodeExceptionMap; }; }) => {
      return from(
        PayrollUploadService.putPayrollUploadFieldException(
          action.payload.mapId,
          action.payload.customUploadMapId,
          action.payload.exception,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storePayrollUploadException(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deletePayrollUploadException$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deletePayrollUploadException.type),
    switchMap((action: { payload: { mapId: number; customUploadMapId: number; exception: PayrollUploadEarnCodeExceptionMap; }; }) => {
      return from(
        PayrollUploadService.deletePayrollUploadFieldException(
          action.payload.mapId,
          action.payload.customUploadMapId,
          action.payload.exception,
        ),
      ).pipe(
        mergeMap(() => { return []; }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createPayrollUploadRate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createPayrollUploadRate.type),
    switchMap((action: { payload: { mapId: number; customUploadMapId: number; rate: PayrollUploadEarnCodeRateMap; }; }) => {
      return from(
        PayrollUploadService.postPayrollUploadFieldRate(
          action.payload.mapId,
          action.payload.customUploadMapId,
          action.payload.rate,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return loadPayrollUpload(); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updatePayrollUploadRate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updatePayrollUploadRate.type),
    switchMap((action: { payload: { mapId: number; customUploadMapId: number; rate: PayrollUploadEarnCodeRateMap; }; }) => {
      return from(
        PayrollUploadService.putPayrollUploadFieldRate(
          action.payload.mapId,
          action.payload.customUploadMapId,
          action.payload.rate,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storePayrollUploadRate(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deletePayrollUploadRate$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deletePayrollUploadRate.type),
    switchMap((action: { payload: { mapId: number; customUploadMapId: number; rate: PayrollUploadEarnCodeRateMap; }; }) => {
      return from(
        PayrollUploadService.deletePayrollUploadFieldRate(
          action.payload.mapId,
          action.payload.customUploadMapId,
          action.payload.rate,
        ),
      ).pipe(
        mergeMap(() => { return []; }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const payrollUploadFileTool$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(payrollUploadFileTool.type),
    switchMap((action: { payload: PayrollUploadFile; }) => {
      return from(
        PayrollUploadService.putPayrollUploadFileTool(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap(() => {
          return [
            loadPayrollUpload(),
            handleSuccess('Payroll Upload has been successfully saved.')];
        },
        ),
        catchError((err: HttpResponse<any>) => {
          return [handleError(err),
            storePayrollUploadFileToolErrors(err.value)];
        }),
      );
    },
    ),
  );
};

const payrollUploadFileCheckTool$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(payrollUploadFileCheckTool.type),
    switchMap((action: { payload: PayrollUploadFileCheck; }) => {
      return from(
        PayrollUploadService.putPayrollUploadFileCheckTool(action.payload),
      ).pipe(
        mergeMap(() => {
          return [storePayrollUploadFileToolCheckErrors({alreadyUploaded: false, message: ''})];
        },
        ),
        catchError((err: HttpResponse<any>) => {
          return [
            storePayrollUploadFileToolCheckErrors({alreadyUploaded: true, message: err.messages[0]})];
        }),
      );
    },
    ),
  );
};

const loadOnTheClockData$ = (
  action$: Observable<Actions>,
) => {
  return action$.pipe(
    ofType(loadOnTheClockData.type),
    switchMap((action: { payload: OnTheClockRequest; }) => {
      return from(
        PayrollUploadService.getOnTheClockData(action.payload),
      ).pipe(
        map((res: any) => {return res.data;}),
        map((res: TimeCard[]) => {return storeOnTheClockData(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err),storeOnTheClockData([])];}),
      );
    },
    ),
  );
};

const loadAodPayrollUploadMaps$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadAodPayrollUploadMaps.type),
    switchMap(() => {
      return from(PayrollUploadService.getAodPayrollUploadMap()).pipe(
        map((res: any) => { return res.data; }),
        map((res: AodPayrollUploadMap) => { return storeAodPayrollUploadMaps(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const savePayrollUploadMapActiveStatuses$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(savePayrollUploadMapActiveStatuses.type),
    switchMap((action: { payload: PayrollUploadManageFiles[]; }) => {
      return from(
        PayrollUploadService.savePayrollUploadMapActiveStatuses(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap(() => {
          return [
            loadPayrollUpload(),
            handleSuccess('Payroll Upload maps have been saved successfully.'),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => {
          return [
            storePayrollUploadFileToolCheckErrors({ alreadyUploaded: true, message: err.messages[0] })];
        }),
      );
    },
    ),
  );
};

const copyPayrollUploadMap$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(copyPayrollUploadMap.type),
    switchMap((action: { payload: number }) => {
      return from(PayrollUploadService.copyPayrollUploadMap(action.payload)).pipe(
        mergeMap(() => {
          return [
            loadPayrollUpload(),
            handleSuccess('Payroll Upload map has been successfully copied.'),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    }),
  );
};

export const epics: any[] = [
  loadPayrollUpload$,
  createPayrollUploadField$,
  createDefaultPayrollUploadFile$,
  createPayrollUploadFile$,
  createPayrollUploadTranslation$,
  createPayrollUploadException$,
  createPayrollUploadRate$,
  updatePayrollUploadField$,
  updatePayrollUploadFile$,
  updatePayrollUploadTranslation$,
  updatePayrollUploadException$,
  updatePayrollUploadRate$,
  payrollUploadFileCheckTool$,
  deletePayrollUploadFields$,
  deletePayrollUploadTranslation$,
  deletePayrollUploadException$,
  deletePayrollUploadRate$,
  payrollUploadFileTool$,
  loadOnTheClockData$,
  loadAodPayrollUploadMaps$,
  savePayrollUploadMapActiveStatuses$,
  copyPayrollUploadMap$,
];
