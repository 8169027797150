import React from 'react';
import styles from './styles.module.scss';
import { currencyFormatter } from 'utilities/utilities';
import { TimeCardsByDayData } from 'core/models/Contractor.model';

type Props = {
  selectedDate: TimeCardsByDayData;
  dates: TimeCardsByDayData[];
  setSelectedDate: React.Dispatch<React.SetStateAction<TimeCardsByDayData | null>>
};

const DateList = ({ selectedDate, setSelectedDate, dates }: Props) => {
  return (
    <ul className={styles['snapshot-list']}>
      {dates.map((x) => {
        const isSelected = x.day === selectedDate.day;
        
        return (
          <div
            onClick={() => { setSelectedDate(x); }}
            key={x.day}
          >
            <li className={`${isSelected ? styles['selected-snapshot'] : styles['job-snapshot']}`}>
              <div className="d-flex flex-column w-100">
                <div className={styles.title}>
                  <strong>{x.day},&nbsp;{x.date}</strong>
                </div>
                <div className={styles['hours-details']}>
                  <span>Hours:&nbsp;{x.totalHours.toFixed(2)}</span>
                  <span>Earnings:&nbsp;{currencyFormatter(x.totalEarnings)}</span>
                  <span>Deductions:&nbsp;{currencyFormatter(x.totalDeductions)}</span>
                </div>
              </div>
            </li>
          </div>
        );
      })}
    </ul>
  );
};

export default DateList;