import { createSelector } from 'reselect';
import { convToFormObject } from '../../../utilities/classUtils';
import { PayrollInfo } from '../../models';
import { payrollInfoState } from '../reducers';
import { State as PayrollState } from '../reducers/payroll-info.reducer';

export const getPayrollInfo = createSelector(
  payrollInfoState,
  (state: PayrollState) => {
    if (!state.payrollInfo) return state;

    return convToFormObject(state.payrollInfo, PayrollInfo.convProps);
  });

export const getPayrollInfoRaw = createSelector(payrollInfoState, (state: PayrollState) => {
  return state?.payrollInfo;
});