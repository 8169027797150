import Icon from 'core/components/shared/Icon';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Collapse, Row } from 'react-bootstrap';
import { CheckboxGrpInLine, InputGrp, RadioGrp } from 'core/components/form-controls';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';
import { Employee } from 'core/models/Employee.model';
import { loadEmployees, storeSelectedEmployee } from 'core/store/actions';
import { storeEmpNos, storeOrderByType } from 'core/store/actions/employee-information.action';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { OrderByType } from 'core/models/AccrualReport.model';

type Props = {
  onSelect: (protectedEmpNo: string) => void;
  accrualScreen?: boolean;
};

const radioOptionsSortBy: RadioOptions[] = [
  {
    value: 'LastName',
    label: 'Last Name',
  },
  {
    value: 'EmpNo',
    label: 'Emp. No.',
  },
];

const radioOptions: RadioOptions[] = [
  {
    value: 'Active',
    label: 'Active Employees',
  },
  {
    value: 'All',
    label: 'All Employees',
  },
  {
    value: 'Terminated',
    label: 'Terminated Employees',
  },
];

const EmployeeInformationSidebar: React.FC<Props> = ({ onSelect, accrualScreen = false  }) => {
  const dispatch = useAppDispatch();
  const employeeList = useAppSelector((state) => { return state.employees.employees; });

  const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterChecked, setFilteredChecked] = useState('Active');
  const [orderByChecked, setOrderByChecked] = useState('LastName');
  const [searchValue, setSearchValue] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(employeeList?.find((emp) => !emp.termDate) ?? null);

  const reportFilterRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    dispatch(loadEmployees());
  }, []);

  useEffect(() => {
    setFilteredEmployees(employeeList.filter((a) => { return a.termDate === null; }));
    setSelectedEmployee(employeeList?.find((emp) => !emp.termDate) ?? null);
  }, [employeeList]);

  useEffect(() => {
    if (reportFilterRef.current && reportFilterRef.current.checked) {
      dispatch(storeEmpNos(filteredEmployees.map((a) => { return a.empNo; })));
    }
  }, [filteredEmployees]);

  useEffect(() => {
    if (selectedEmployee && reportFilterRef.current) {
      onSelect(selectedEmployee.protectedEmpNo);
      if (reportFilterRef.current.checked) {
        dispatch(storeEmpNos(filteredEmployees.map((a) => { return a.empNo; })));
      } else {
        if (accrualScreen) dispatch(storeEmpNos([]));
        else dispatch(storeEmpNos([selectedEmployee.empNo]));
      }
      dispatch(storeSelectedEmployee(selectedEmployee));
    }
  }, [selectedEmployee]);

  useEffect(() => {
    const filteredEmployees = employeeList.filter((employee) => {
      switch (filterChecked) {
        case 'Active':
          return employee.termDate === null;
        case 'Terminated':
          return employee.termDate !== null;
        default:
          return true;
      }
    });

    if (searchValue.length === 0) {
      setFilteredEmployees(filteredEmployees);
      return;
    }
    if (!Number.isNaN(Number(searchValue))) {
      setFilteredEmployees(
        filteredEmployees.filter(
          (employee) => {
            return employee.empNo === Number(searchValue) ||
              employee.ssn === searchValue;
          },
        ),
      );
      return;
    }
    setFilteredEmployees(
      filteredEmployees.filter((employee) => {
        return (
          employee.firstName
            .toLowerCase()
            .startsWith(searchValue.toLowerCase()) ||
          employee.lastName
            .toLowerCase()
            .startsWith(searchValue.toLowerCase())
        );
      }),
    );
  }, [filterChecked, searchValue]);

  const filterEmployee = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const filterChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredChecked(e.target.value);
  };

  const orderByChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === OrderByType.EmpNo || e.target.value === OrderByType.LastName) {
      setOrderByChecked(e.target.value);
      dispatch(storeOrderByType(e.target.value as OrderByType));
    }
  };

  const reportFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      dispatch(storeEmpNos(filteredEmployees.map((a) => { return a.empNo; })));
    } else {
      if (accrualScreen) dispatch(storeEmpNos([]));
      else dispatch(storeEmpNos(selectedEmployee ? [selectedEmployee.empNo] : []));
    }
  };

  return (
    <Col
      sm="2"
      className="sidebar"
    >
      <div className="header">Report Options</div>
      <div className="content">
        <CheckboxGrpInLine
          label="Report On Filtered Employees"
          labelFirst={true}
          ref={reportFilterRef}
          onChange={reportFilter}
        />
        <RadioGrp
          label="Sort Report By:"
          radioOptions={radioOptionsSortBy}
          onChange={orderByChanged}
          defaultChecked={orderByChecked}
          name="orderBy"
        />
      </div>
      <div className="header">
        Search Employee <Icon name="search" />
      </div>
      <div className="content">
        <Row className="align-items-end no-gutters">
          <Col>
            <InputGrp
              label="Search by SSN, Emp# or Name"
              onChange={filterEmployee}
            />
          </Col>
          <Col xs="auto">
            <button
              className="btn btn-link font-weight-bolder"
              type="button"
              onClick={() => { return setOpenFilter(!openFilter); }}
            >
              Filter
            </button>
          </Col>
        </Row>
        <Collapse in={openFilter}>
          <Row>
            <Col>
              <div className="dm-card-subtitle2 mb-2">Filter</div>
              <hr className="hr-1 mb-2" />
              <div>
                <RadioGrp
                  radioOptions={radioOptions}
                  onChange={filterChanged}
                  defaultChecked={filterChecked}
                  name="filter"
                />
              </div>
            </Col>
          </Row>
        </Collapse>
      </div>
      <div className="header">Employee List</div>
      <div className="content employee-list">
        <table className="w-100">
          <thead>
            <tr>
              <th
                role="button"
                onClick={() => {
                  return setFilteredEmployees([
                    ...filteredEmployees.sort((a, b) => {
                      return a.firstName.toUpperCase() <
                        b.firstName.toUpperCase()
                        ? -1
                        : a.firstName.toUpperCase() >
                          b.firstName.toUpperCase()
                          ? 1
                          : 0;
                    },
                    ),
                  ]);
                }
                }
              >
                First Name
              </th>
              <th
                role="button"
                onClick={() => {
                  return setFilteredEmployees([
                    ...filteredEmployees.sort((a, b) => {
                      return a.lastName.toUpperCase() <
                        b.lastName.toUpperCase()
                        ? -1
                        : a.lastName.toUpperCase() >
                          b.lastName.toUpperCase()
                          ? 1
                          : 0;
                    },
                    ),
                  ]);
                }
                }
              >
                Last Name
              </th>
              <th
                role="button"
                onClick={() => {
                  return setFilteredEmployees([
                    ...filteredEmployees.sort(
                      (a, b) => { return a.empNo - b.empNo; },
                    ),
                  ]);
                }
                }
              >
                Emp #
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredEmployees.map((employee) => {
              return (
                <tr
                  key={employee.empNo}
                  role="button"
                  onClick={() => { return setSelectedEmployee(employee); }}
                  className={
                    employee.termDate !== null
                      ? 'table-danger'
                      : selectedEmployee?.empNo === employee.empNo 
                        ? 'emp-sidebar-row-selected'
                        : 'em-sidebar-tr'
                  }
                >
                  <td>{employee.firstName}</td>
                  <td>{employee.lastName}</td>
                  <td className="text-right">{employee.empNo}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Col>
  );
};

export default EmployeeInformationSidebar;
