import React, { useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import swoosh from '../../../../src/assets/images/swoosh.png';

type PropTypes = {
  title?: string;
  component: React.FunctionComponent<any>;
  children?: any;
};

const PopoverComponent: React.FC<PropTypes> = ({ title, component: Component, children }) => {
  const searchTargetRef = useRef(null);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const onClick = (e: any) => {
    setShow(true);
    setTarget(e.target);
  };

  return (
    <>
      <Overlay
        show={show}
        target={target}
        placement="bottom"
        rootClose={true}
        rootCloseEvent="click"
        onHide={() => { return setShow(false); }}
        container={searchTargetRef.current}
      >
        <Popover id="popover-basic">
          <Popover.Title
            bsPrefix="dm-primary-blue"
            style={{
              fontSize: '18px',
              fontWeight: 600,
              color: '#00558',
              padding: '10px 15px',
            }}>
            {title}<img src={swoosh}
              style={{
                paddingLeft: '10px',
                paddingTop: '10px',
                width: '45px',
                height: 'auto',
              }}
              alt="swoosh" />
          </Popover.Title>
          <Popover.Content>
            <Component onHide={() => { return setShow(false); }} />
          </Popover.Content>
        </Popover>
      </Overlay>
      <div onClick={onClick}>
        {children}
      </div>
    </>
  );
};

export default PopoverComponent;