import React, { useContext, useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import SendOfferModal from './SendOfferModal';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { get } from 'lodash';
import { setOfferValues } from 'core/store/slices/applicant-tracking-v2.slice';
import Select from 'react-select';
import { compStyles, StylesConfigType } from 'utilities/styles';
import { updateApplicantOffer } from 'core/store/actions/applicant-tracking.action';
import { getUsers } from 'core/store/slices/cmUsers.slice';
import { useForm } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import { ApplicantContext } from '../Applicant';

const Offer = () => {
  const {
    setValue,
    register,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      notify_users: '',
    },
  });
  const values = watch();
  
  const appDispatch = useAppDispatch();
  
  const { tab, isDirty, setIsDirty } = useContext(ApplicantContext);
  
  const users = useAppSelector(({ cmUsers }) => cmUsers.users);
  const statusValues = useAppSelector(({ applicantTrackingV2 }) => applicantTrackingV2.offer) || {};

  const onSaveSettings = () => {
    appDispatch(updateApplicantOffer());
    setIsDirty(false);
  };

  useEffect(() => {
    appDispatch(getUsers());
  }, []);
  
  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    const confirmationMessage =
      'It looks like you have been editing something. ' +
      'If you leave before saving, your changes will be lost.';

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  };

  useEffect(() => {
    if (isDirty && tab === 'status') {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [statusValues, values, isDirty]);

  const options = useMemo(() => {
    if (users.length) {
      return [
        { value: '', label: '<None>' },
        ...users.map((user) => {
          return {
            value: user.workEmail,
            label: user.fullName || '',
          };
        })];
    }
    return [];
  }, [users]);

  const onChangeReminderEmailYes = () => {
    appDispatch(
      setOfferValues({
        sendReminderEmail: true,
      }),
    );
  };
  
  const valueNotifOfferUpdate = get(statusValues, 'reminderEmails') && {
    value: get(statusValues, 'reminderEmails')?.[0]?.email,
    label: get(statusValues, 'reminderEmails')?.[0]?.name,
  };

  return (
    <div className="offer-content">
      <Prompt
        when={isDirty}
        message="It looks like you have been editing something. If you leave before saving, your changes will be lost."
      />
      <SendOfferModal />
      <form onSubmit={handleSubmit(onSaveSettings)}>
        <div className="p-4 border mb-4">
          <h4 className="mb-0">Offer Details</h4>
          <p>
            Select which screenings the applicant must pass before offer is
            accepted.
          </p>
          <Row className="py-1">
            <Col sm={2}>
              <span></span>
            </Col>
            <Col sm={3}>
              <span className="font-weight-bold">Screening Required</span>
            </Col>
            <Col sm={1}>
              <span className="font-weight-bold">Pass</span>
            </Col>
            <Col sm={1}>
              <span className="font-weight-bold">Fail</span>
            </Col>
            <Col sm={5}>
              <span className="font-weight-bold">Notes</span>
            </Col>
          </Row>
          {[
            {
              label: 'Background Check',
              noteKey: 'backgroundCheckNotes',
              require: 'requireBackgroundCheck',
              pass: 'passBackgroundCheck',
            },
            {
              label: 'Credential Check',
              noteKey: 'credentialCheckNotes',
              require: 'requireCredentialCheck',
              pass: 'passCredentialCheck',
            },
            {
              label: 'Drug Check',
              noteKey: 'drugScreenNotes',
              require: 'requireDrugScreening',
              pass: 'passDrugScreen',
            },
          ].map((item, index) => {
            const id = `${item.label
              .replaceAll(' ', '-')
              .toLowerCase()}-${index}`;
            return (
              <Row
                key={item.label}
                className="py-1"
              >
                <Col sm={2}>
                  <span className="font-weight-bold">{item.label}:</span>
                </Col>
                <Col sm={3}>
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: '2rem' }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: '0.25rem' }}
                    >
                      <input
                        type="checkbox"
                        name=""
                        id={`${id}-yes`}
                        checked={get(statusValues, item.require)}
                        onChange={() => {
                          setValue('notify_users', true, { shouldDirty: true });
                          setIsDirty(true);
                          appDispatch(
                            setOfferValues({
                              [item.require]: true,
                              [item.pass]: undefined,
                            }),
                          );
                        }}
                      />
                      <label
                        htmlFor={`${id}-yes`}
                        className="mb-0"
                      >
                        Yes
                      </label>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: '0.25rem' }}
                    >
                      <input
                        type="checkbox"
                        name=""
                        id={`${id}-no`}
                        checked={!get(statusValues, item.require)}
                        onChange={() => {
                          setValue('notify_users', false, {
                            shouldDirty: true,
                          });
                          setIsDirty(true);
                          appDispatch(
                            setOfferValues({
                              [item.require]: false,
                              [item.pass]: undefined,
                            }),
                          );
                        }}
                      />
                      <label
                        htmlFor={`${id}-no`}
                        className="mb-0"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </Col>
                <Col sm={1}>
                  <input
                    type="checkbox"
                    disabled={
                      (item.require === 'requireBackgroundCheck' &&
                        !get(statusValues, 'requireBackgroundCheck')) ||
                      (item.require === 'requireCredentialCheck' &&
                        !get(statusValues, 'requireCredentialCheck')) ||
                      (item.require === 'requireDrugScreening' &&
                        !get(statusValues, 'requireDrugScreening'))
                    }
                    checked={get(statusValues, item.pass) === true}
                    onChange={() => {
                      setValue('notify_users', true, { shouldDirty: true });
                      setIsDirty(true);
                      appDispatch(
                        setOfferValues({
                          [item.pass]: true,
                        }),
                      );
                    }}
                  />
                </Col>
                <Col sm={1}>
                  <input
                    type="checkbox"
                    disabled={
                      (item.require === 'requireBackgroundCheck' &&
                        !get(statusValues, 'requireBackgroundCheck')) ||
                      (item.require === 'requireCredentialCheck' &&
                        !get(statusValues, 'requireCredentialCheck')) ||
                      (item.require === 'requireDrugScreening' &&
                        !get(statusValues, 'requireDrugScreening'))
                    }
                    checked={get(statusValues, item.pass) === false}
                    onChange={() => {
                      setValue('notify_users', false, { shouldDirty: true });
                      setIsDirty(true);
                      appDispatch(
                        setOfferValues({
                          [item.pass]: false,
                        }),
                      );
                    }}
                  />
                </Col>
                <Col sm={5}>
                  <input
                    type="text"
                    className="w-100 px-1"
                    disabled={
                      (item.require === 'requireBackgroundCheck' &&
                        !get(statusValues, 'requireBackgroundCheck')) ||
                      (item.require === 'requireCredentialCheck' &&
                        !get(statusValues, 'requireCredentialCheck')) ||
                      (item.require === 'requireDrugScreening' &&
                        !get(statusValues, 'requireDrugScreening'))
                    }
                    value={get(statusValues, item.noteKey)}
                    onChange={(e) => {
                      setValue('notify_users', e.target.value, {
                        shouldDirty: true,
                      });
                      setIsDirty(true);
                      appDispatch(
                        setOfferValues({
                          [item.noteKey]: e.target.value,
                        }),
                      );
                    }}
                  />
                </Col>
              </Row>
            );
          })}
          <Row className="py-1">
            <Col sm={7}>
              <div className="d-flex justify-content-end">
                <span>User to be notified of Applicant offer updates.</span>
              </div>
            </Col>
            <Col sm={5}>
              <Select
                options={options}
                value={valueNotifOfferUpdate}
                styles={compStyles as StylesConfigType}
                onChange={(e: any) => {
                  setValue('notify_users', e, { shouldDirty: true });
                  setIsDirty(true);
                  appDispatch(
                    setOfferValues({
                      reminderEmails: [
                        {
                          name: e?.label,
                          email: e?.value,
                        },
                      ],
                    }),
                  );
                }}
                // @ts-ignore
                {...register('notify_users')}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end mt-3">
            <button
              type="submit"
              className="btn btn-primary orange-outline-button"
              disabled={!(isDirty)}
            >
              Save Settings
            </button>
          </div>
        </div>
        <div
          className="d-flex flex-column"
          style={{ gap: '0.25rem' }}
        >
          <h4 className="font-weight-bold mb-0">Reminder Email</h4>
          <div
            className="d-flex align-items-center"
            style={{ gap: '2rem' }}
          >
            <span>
              Would you like to setup email reminders about this
              applicant&apos;s offer status?
            </span>
            <div
              className="d-flex align-items-center"
              style={{ gap: '2rem' }}
            >
              <div
                className="d-flex align-items-center"
                style={{ gap: '0.25rem' }}
              >
                <input
                  type="checkbox"
                  name=""
                  id="reminder-email-yes"
                  checked={statusValues.sendReminderEmail}
                  onChange={() => {
                    setValue('notify_users', true, { shouldDirty: true });
                    setIsDirty(true);
                    onChangeReminderEmailYes();
                  }}
                />
                <label
                  htmlFor="reminder-email-yes"
                  className="mb-0"
                >
                  Yes
                </label>
              </div>
              <div
                className="d-flex align-items-center"
                style={{ gap: '0.25rem' }}
              >
                <input
                  type="checkbox"
                  name=""
                  id="reminder-email-no"
                  checked={!statusValues.sendReminderEmail}
                  onChange={() => {
                    setValue('notify_users', false, { shouldDirty: true });
                    setIsDirty(true);
                    appDispatch(
                      setOfferValues({
                        sendReminderEmail: false,
                        reminderEmailDayInterval: null,
                      }),
                    );
                  }}
                />
                <label
                  htmlFor="reminder-email-no"
                  className="mb-0"
                >
                  No
                </label>
              </div>
            </div>
          </div>
          <div
            className="d-flex align-items-center"
            style={{ gap: '2rem' }}
          >
            <span>
              How often would you like a reminder email sent until the offer is
              accepted or denied?
            </span>
            <div
              className="d-flex align-items-center"
              style={{ gap: '0.5rem' }}
            >
              <span>Every</span>
              <input
                type="number"
                style={{ width: 50 }}
                disabled={!statusValues.sendReminderEmail}
                defaultValue={statusValues?.reminderEmailDayInterval || ''}
                value={statusValues?.reminderEmailDayInterval || ''}
                onChange={(e) => {
                  setValue('notify_users', e.target.value, {
                    shouldDirty: true,
                  });
                  setIsDirty(true);
                  appDispatch(
                    setOfferValues({
                      reminderEmailDayInterval: e.target.value,
                    }),
                  );
                }}
              />
              <span>day(s).</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Offer;
