import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Client, DtoOptions } from '../../core/models';
import { useAppSelector } from '../../utilities/hooks';
import InputReportDateSelector from '../../core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from '../../core/components/modals/pdf-viewer.modal';
import { CheckboxGrpInLine } from '../../core/components/form-controls';
import { Payroll401kReportRequest } from '../../core/models/PayrollReports.model';
import { useForm } from 'react-hook-form';
import { CommonService } from '../../core/services';
import { clearPayroll401kReport, downloadPayroll401kReport } from '../../core/store/actions';
import { getPayroll401kReport } from '../../core/store/selectors/payroll-reports.selector';
import Icon from 'core/components/shared/Icon';

const Payroll401kReport = () => {

  const dispatch = useDispatch();

  const childRef: React.RefObject<any> = useRef();
  const report = useSelector(getPayroll401kReport);
  const client = useAppSelector((state) => { return state.client.client; }) as Client;
  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [fileType, setFileType] = useState<string>('pdf');
  const [disableAutoDateSelect, setDisableAutoDateSelect] = useState<boolean>(false);
  const reportName = 'Payroll401kReport';
  const { register, handleSubmit } =
    useForm<Payroll401kReportRequest>({});

  const downloadReport = (data: Payroll401kReportRequest) => {
    const submitData: Payroll401kReportRequest = {
      clientNo: client.clientNo,
      byWeekEndCheckDate: data.byWeekEndCheckDate,
      show401KMatching: data.show401KMatching,
      showTotal: data.showTotal,
      dtoOptions: reportDates,
      reportType: fileType,
    };
    dispatch(downloadPayroll401kReport(submitData));
  };

  useEffect(() => {
    if (report && fileType === 'excel') {
      CommonService.downloadBase64File(
        'application/excel',
        report,
        `${reportName}.xlsx`,
      );
      dispatch(clearPayroll401kReport());
    }
  }, [report]);

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const hidePdfViewer = () => {
    dispatch(clearPayroll401kReport());
  };

  return (
    <div>
      <div className="dm-panel dm-panel-border">
        <form onSubmit={handleSubmit(downloadReport)}>
          <div className="dm-grid-title">401k Report</div>
          <div className="w-100 text-right">
            <button
              type="submit"
              className="btn btn-link dm-grid-action-title "
              onClick={() => { return setFileType('excel'); }}
              disabled={!reportDates.length}
            >
              Download Report  <Icon
                name="download"
                className="fa-download"
              />
            </button>
          </div>
          <hr className="dm-panel-hr" />
          <div className="d-flex flex-row flex-grow-1">
            <div>
              <InputReportDateSelector
                ref={childRef}
                returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }}
                disableAutoDateSelect={disableAutoDateSelect}
              />
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <div className=" text-right">
                <button
                  type="button"
                  className="orange-outline-button mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="orange-button mr-2"
                  onClick={() => { return setFileType('pdf'); }}
                  disabled={!reportDates.length}
                >
                  Run Report
                </button>
              </div>
              <div
                className="w-50 dm-panel dm-panel-border"
                style={{ marginTop: '66px' }}
              >
                <div className="dm-grid-action-title mb-2">Report Options</div>
                <div>
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-row flex-grow-1">
                      <div className="d-flex flex-column flex-grow-1">
                        <CheckboxGrpInLine
                          name="byWeekEndCheckDate"
                          ref={register}
                          labelFirst={false}
                          onChange={(e: any) => {
                            return setDisableAutoDateSelect(e.target.checked);
                          }}
                          label="By Week End and Check Date"
                        />
                        <CheckboxGrpInLine
                          name="show401KMatching"
                          ref={register}
                          labelFirst={false}
                          label="Show 401K Matching"
                        />
                        <div className="dm-grid-action-title mb-2 ">Download Options</div>
                        <CheckboxGrpInLine
                          name="showTotal"
                          ref={register}
                          labelFirst={false}
                          label="Show Totals instead of Detail"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {report ? <PDFViewerModal
        show={report.length > 0}
        pdfData={report}
        onHide={hidePdfViewer}
        reportName="Payroll401kReport"
      /> : null}
    </div>
  );



};
export default Payroll401kReport;


