import React from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getEmployees } from 'core/store/selectors';
import DiagnosticDetailItem from './DiagnosticDetailItem';

const DiagnosticDetails = () => {
  const employees = useSelector(getEmployees); 
  
  return (
    <div className="dm-panel-border w-100">
      <div className={styles['details-container']}>
        <DiagnosticDetailItem employee={employees[0]} />
        <DiagnosticDetailItem employee={employees[1]} />
        <DiagnosticDetailItem employee={employees[2]} />
      </div>
    </div>
  );
};

export default DiagnosticDetails;