import { ICellEditorParams } from '@ag-grid-community/core';

export function agInputNumberEditor(): any { }

type StyleOverrides = {
  wrapper?: string;
  input?: string;
};

agInputNumberEditor.prototype.init = function (params: ICellEditorParams & { styleOverrides: StyleOverrides }) {
  this.divWrapper = document.createElement('div');
  this.divWrapper.style.cssText = `display: flex; justify-content: center ${params?.styleOverrides?.wrapper ?? ''}`;

  this.input = document.createElement('input');
  this.input.type = 'number';
  this.input.value = params.value;
  this.input.style.cssText = `padding-left: 5px; width: 100%; ${params?.styleOverrides?.input ?? ''}`;
  this.input.min = '0';
  this.divWrapper.appendChild(this.input);
};

agInputNumberEditor.prototype.getGui = function () {
  return this.divWrapper;
};

agInputNumberEditor.prototype.getValue = function () {
  return this.input.value;
};

agInputNumberEditor.prototype.destroy = function () {};

agInputNumberEditor.prototype.isPopup = function () {
  return false;
};
