import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpResponse, PayrollsUserOptions } from '../../models';
import { PayrollsUserOptionsService } from '../../services';
import { handleError } from '../actions/app.action';
import {
  loadPayrollsUserOptions,
  putPayrollsUserOptions,
  storePayrollsUserOptions,
} from '../actions/payrolls-user-options.action';

interface Actions {
  type: string;
  payload: any;
}

const loadPayrollsUserOptions$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadPayrollsUserOptions.type),
    switchMap(() => {
      return from(PayrollsUserOptionsService.getPayrollsUserOptions()).pipe(
        map((res: any) => { return res.data; }),
        map((res: PayrollsUserOptions) => { return storePayrollsUserOptions(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const putPayrollsUserOptions$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(putPayrollsUserOptions.type),
    switchMap((action: { payload: PayrollsUserOptions; }) => {
      return from(
        PayrollsUserOptionsService.putPayrollsUserOptions(
          action.payload,
        ),
      ).pipe(
        map((res: any) => { return res.data?.value; }),
        mergeMap((res: any) => { return [storePayrollsUserOptions(res)]; }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [loadPayrollsUserOptions$, putPayrollsUserOptions$];
