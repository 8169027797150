import React from 'react';
import CheckBoxForm from '../../Shared/CheckBoxForm';
import { Col, Row } from 'react-bootstrap';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for Checkbox and Grid select forms
const SelfServiceFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  return (
    <Row>
      {!!sectionFields?.length && (
        <Col xs={12}>
          <CheckBoxForm
            formMethods={formMethods}
            dFields={sectionFields.find(f => {
              return f.name === 'Self Service';
            })?.fields}
            section="Self Service"
            title="Self Service"
          />
        </Col>
      )}
    </Row>
  );
};

export default SelfServiceFields;