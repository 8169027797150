import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { AgGridReact } from '@ag-grid-community/react';
import { CellValueChangedEvent } from '@ag-grid-enterprise/all-modules';
import Icon from 'core/components/shared/Icon';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import { DataNode, EventDataNode, Key } from 'rc-tree/lib/interface';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Dropdown, Modal, ProgressBar, Row } from 'react-bootstrap';
import { SelectGrp } from 'core/components/form-controls';
import ConfirmationModal from 'core/components/modals/confirmation.modal';
import { File } from 'core/models/File.model';
import { CommonService, EmployeeService } from 'core/services';
import { agCheckboxEditor, agSelectEditor } from 'utilities/ag-grid-editors';
import { agCheckboxRenderer, agLookupRenderer } from 'utilities/ag-grid-renderers';
import DownloadEmployeeDocsRenderer from 'utilities/ag-grid-renderers/downloadEmployeeDocsRenderer';
import ViewEditAgreementRenderer from 'utilities/ag-grid-renderers/ViewEditAgreementRenderer';
import ViewEmployeeDocsCounterSignerRenderer from 'utilities/ag-grid-renderers/ViewEmployeeDocsCounterSignerRenderer';
import ViewEmployeeDocsRenderer from 'utilities/ag-grid-renderers/ViewEmployeeDocsRenderer';
import { stringToInt } from 'utilities/classUtils';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import AddFolder from './AddFolder';
import AssignFile from './AssignFile';
import {
  ColDef,
  GetRowIdParams,
  GridColumnsChangedEvent,
  ModuleRegistry,
  RowDataChangedEvent,
  SelectionChangedEvent,
  ValueSetterParams,
} from '@ag-grid-community/core';
import {
  addFileRecordId,
  clearFile,
  clearUserFiles,
  deleteClientFile,
  deleteFileFolder,
  getUserFiles,
  postClientFileUpload,
  postMoveClientH2R,
  putClientFile,
} from 'core/store/slices/file.slice';
import { FileService } from 'core/services/file.service';
import { handleError } from 'core/store/actions';

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

// TODO: puke
const fileDisplayType = [
  { label: 'Folder', id: 'Folder' },
  { label: 'Active Emps Sorted By Emp No', id: 'ActiveEmpsSortedByEmpNo' },
  { label: 'Active Emps Sorted By Last Name', id: 'ActiveEmpsSortedByLastName' },
  { label: 'All Emps Sorted By Emp No', id: 'AllEmpsSortedByEmpNo' },
  { label: 'All Emps Sorted By Last Name', id: 'AllEmpsSortedByLastName' },
  { label: 'Terminated Emps Sorted By Emp No', id: 'TerminatedEmpsSortedByEmpNo' },
  { label: 'Terminated Emps Sorted By Last Name', id: 'TerminatedEmpsSortedByLastName' },
];

const defaultColDef: ColDef = {
  suppressAutoSize: true,
  suppressSizeToFit: true,
};

//Not sure if all the checkbox editor locations should have these changes or not
const checkBoxCellStyle = { 'justify-content': 'center', height: '20px' };

const defaultColumnDefs: ColDef[] = [
  {
    field: 'name',
    headerName: 'File Name',
    sortable: true,
    editable: true,
    resizable: true,
    suppressSizeToFit: false,
    suppressAutoSize: false,
  },
  {
    field: 'extension',
    headerName: 'Extension',
    sortable: true,
  },
  {
    field: 'modifyDate',
    headerName: 'Modify Date',
    sortable: true,
  },
  {
    field: 'path',
    headerName: 'Download',
    cellRenderer: DownloadEmployeeDocsRenderer,
  },
  {
    field: 'path',
    headerName: 'View',
    cellRenderer: ViewEmployeeDocsRenderer,
  },
];

const onBoardingColumnDefs = (isSuperUser: boolean, fileFillTypes: any): ColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'File Name',
      sortable: true,
      editable: true,
      resizable: true,
      suppressSizeToFit: false,
      suppressAutoSize: false,
      minWidth: 200,
    },
    {
      field: 'extension',
      sortable: true,
      minWidth: 100,
    },
    {
      field: 'path',
      headerName: 'Download',
      minWidth: 100,
      cellRenderer: DownloadEmployeeDocsRenderer,
    },
    {
      field: 'path',
      headerName: 'View',
      minWidth: 100,
      cellRenderer: ViewEmployeeDocsRenderer,
    },
    {
      field: 'requireSignature',
      cellRenderer: agCheckboxRenderer,
      cellEditor: agCheckboxEditor,
      cellStyle: checkBoxCellStyle,
      minWidth: 110,
      editable: true,
      valueSetter: (params: ValueSetterParams) => {
        if (params.newValue === true && params.data.hidden === true) {
          alert(
            'You cannot hide require a signature on a hidden document. Unhide the document before you attemp to require a signature.',
          );
          return false;
        }
        params.data.requireSignature = params.newValue;
        return true;
      },
    },
    {
      field: 'hidden',
      headerName: 'Hide from Onboarding',
      minWidth: 115,
      cellRenderer: agCheckboxRenderer,
      cellEditor: agCheckboxEditor,
      cellStyle: checkBoxCellStyle,
      editable: true,
      valueSetter: (params: ValueSetterParams) => {
        if (
          params.newValue === true &&
          params.data.requireSignature === true
        ) {
          alert(
            'You cannot hide a document on MyInfo if a signature is required. Please remove the signature required and try again.',
          );
          return false;
        }
        params.data.hidden = params.newValue;
        return true;
      },
    },
    {
      field: 'fileFillTypeId',
      headerName: 'Fill Type',
      editable: true,
      minWidth: 100,
      hide: !isSuperUser,
      cellRenderer: agLookupRenderer,
      cellRendererParams: {
        options: fileFillTypes,
        labelField: 'description',
        valueField: 'id',
        showId: false,
      },
      cellEditor: agSelectEditor,
      cellEditorParams: {
        options: fileFillTypes,
        addEmptyValue: false,
      },
    },
    {
      headerName: 'Counter-Signers',
      minWidth: 100,
      field: 'fileAdminTypeIds',
      cellRenderer: ViewEmployeeDocsCounterSignerRenderer,
    },
    {
      field: 'agreement',
      minWidth: 100,
      cellRenderer: ViewEditAgreementRenderer,
    },
  ];
}

const myInfoColumnDefs = (isSuperUser: boolean, fileFillTypes: any) : ColDef[]  => {
  return [
    {
      field: 'name',
      headerName: 'File Name',
      sortable: true,
      editable: true,
      resizable: true,
      suppressSizeToFit: false,
      suppressAutoSize: false,
      minWidth: 200,
    },
    {
      field: 'extension',
      sortable: true,
      minWidth: 100,
    },
    {
      field: 'path',
      headerName: 'Download',
      minWidth: 100,
      cellRenderer: DownloadEmployeeDocsRenderer,
    },
    {
      field: 'path',
      headerName: 'View',
      minWidth: 100,
      cellRenderer: ViewEmployeeDocsRenderer,
    },
    {
      field: 'requireSignature',
      cellRenderer: agCheckboxRenderer,
      cellEditor: agCheckboxEditor,
      cellStyle: checkBoxCellStyle,
      minWidth: 110,
      editable: true,
      valueSetter: (params: ValueSetterParams) => {
        if (params.newValue === true && params.data.hidden === true) {
          alert(
            'You cannot hide require a signature on a hidden document. Unhide the document before you attemp to require a signature.',
          );
          return false;
        }
        params.data.requireSignature = params.newValue;
  
        return true;
      },
    },
    {
      field: 'hidden',
      headerName: 'Hide from MyInfo',
      minWidth: 110,
      cellRenderer: agCheckboxRenderer,
      cellEditor: agCheckboxEditor,
      cellStyle: checkBoxCellStyle,
      editable: true,
      valueSetter: (params: ValueSetterParams) => {
        if (
          params.newValue === true &&
          params.data.requireSignature === true
        ) {
          alert(
            'You cannot hide a document on MyInfo if a signature is required. Please remove the signature required and try again.',
          );
          return false;
        }
        params.data.hidden = params.newValue;
        return true;
      },
    },
    {
      field: 'fileFillTypeId',
      headerName: 'Fill Type',
      editable: true,
      hide: !isSuperUser,
      minWidth: 100,
      cellRenderer: agLookupRenderer,
      cellRendererParams: {
        options: fileFillTypes || [],
        labelField: 'description',
        valueField: 'id',
        showId: false,
      },
      cellEditor: agSelectEditor,
      cellEditorParams: {
        options: fileFillTypes || [],
        addEmptyValue: false,
      },
      valueSetter: (params: ValueSetterParams) => {
        params.data.fileFillTypeId = parseInt(params.newValue);
        return true;
      },
    },
    {
      field: 'fileAdminTypeIds',
      headerName: 'Counter-Signers',
      minWidth: 100,
      cellRenderer: ViewEmployeeDocsCounterSignerRenderer,
    },
    {
      field: 'agreement',
      minWidth: 100,
      cellRenderer: ViewEditAgreementRenderer,
    },
  ];
} 

const EmployeeDocsPage = () => {
  const dispatch = useAppDispatch();
  
  const { userFiles, loading: fileLoading, addFileRecordId: selectorAddFileRecordId } = useAppSelector(({ file }) => file);
  const fileFillTypes = useAppSelector(({ dropdown }) => dropdown.fileFillType);
  const clientNo = useAppSelector(({ client }) => client.client?.clientNo);

  const [disableManageFolders, setDisableManageFolders] = useState(true);
  const [selectedFolder, setSelectedFolder] = useState<EventDataNode<DataNode> | null>(null);
  const [disableDeleteFolder, setDisableDeleteFolder] = useState(true);
  const [disableAddFolder, setDisableAddFolder] = useState(false);
  const [disableManageFolder, setDisableManageFolder] = useState(false);
  const [disableAddFile, setDisableAddFile] = useState(true);
  const [rowData, setRowData] = useState<File.FileRecord[]>([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>(defaultColumnDefs);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [showAddFolder, setShowAddFolder] = useState(false);
  const [showAssignFile, setShowAssignFile] = useState(false);
  const [fileCategoryId, setFileCategoryId] = useState(-1);
  const [selectedFileRecordId, setSelectedFileRecordId] = useState(-1);
  const [selectedFileRecord, setSelectedFileRecord] = useState<File.FileRecord>();
  const [selectedFileFolder, setSelectedFileFolder] = useState<File.FolderRecord | null>(null);
  const [nodeInfo, setNodeInfo] = useState<any>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [colView, setColView] = useState<{ folderSize: number, fileSize: number }>({ folderSize: 3, fileSize: 8 });
  const [currentViewSelection, setCurrentViewSelection] = useState<string>('Folder');
  const userClients = useAppSelector(({ auth }) => auth.userAccess);
  const isSuperUser = !!userClients?.superUser;


  const gridRef = useRef<AgGridReact>(null);
  const fileUploadRef = useRef<HTMLInputElement>(null);

  //Will definitly needs to make this better this just makes it usable on mobile for the time being
  const getColSize = (x: MediaQueryList) : { folderSize: number, fileSize: number } => {
    if (x.matches) { // If media query matches
      return { folderSize: 12, fileSize: 11 };
    } else {
      return { folderSize: 3, fileSize: 8 };
    }
  };

  useEffect(() => {
    // standard mobile breakpoint
    const mediaQuery = window.matchMedia('(max-width: 767px)');
    setColView(getColSize(mediaQuery));
    
    const handleQueryChange = () => {
      setColView(getColSize(mediaQuery));
    };
    mediaQuery.addEventListener('change', handleQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleQueryChange);
    };
  }, []);

  useEffect(() => {
    setSelectedFileRecordId(selectorAddFileRecordId);
    const folderId = getFolderId();
    //PI-8641 We only want to show this prompt when they upload to an Employee Document folder. We know 1-12 are always going to be static folders for all clients so do not show it if not greater than these. 
    if (selectorAddFileRecordId > 0 && folderId > 12) {
      if (
        window.confirm(
          'Would you like to associate any employees to this file? This can be done at any time by clicking on the file and selecting "Assign Employee to Selected File".',
        )
      ) {
        setShowAssignFile(true);
      } else {
        dispatch(addFileRecordId(-1));
        setShowAssignFile(false);
      }
    }
  }, [selectorAddFileRecordId]);

  useEffect(() => {
    if (selectedFolder) {
      selectFiles(selectedFolder);
    }

    if (userFiles) {
      const newTreeData = userFiles.fileFolders.map((folder, index) => {
        return {
          key: `${folder.name}-${index}-${folder.fileCategoryId}`,
          title: `${folder.name} (${folder.files?.length ?? folder?.subFolders?.reduce((partialSum, x) => partialSum + (x?.files?.length ?? 0), 0)
          })`,
          icon: <Icon name="folder" />,
          children: folder.subFolders?.map(
            (subFolder, subFolderIndex) => {
              return {
                key: `${folder.name}-${subFolder.name}-${index}-${subFolderIndex}-${subFolder.fileCategoryId}`,
                title: `${subFolder.name} (${subFolder.files?.length ?? 0
                })`,
                icon: <Icon name="folder" />,
              };
            },
          ),
        } as DataNode;
      });
      setTreeData(newTreeData);
    }
  }, [userFiles]);

  useEffect(() => {
    setFileCategoryId(getFolderId());
  }, [selectedFolder]);

  useEffect(() => {
    dispatch(postMoveClientH2R('Folder'));
    return () => {
      dispatch(clearFile());
      dispatch(clearUserFiles());
    };
  }, []);


  const changeDisplayType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDisableManageFolders(e.target.value !== 'Folder');
    setCurrentViewSelection(e.target.value);
    setDisableAddFile(true); // must select new folder to re-enable this
    setSelectedFolder(null);
    setRowData([]);
    dispatch(getUserFiles(e.target.value));
  };

  const onSelect = (
    _selectedKeys: Key[],
    info: {
      event: 'select';
      selected: boolean;
      node: EventDataNode<DataNode>;
      selectedNodes: DataNode[];
      nativeEvent: MouseEvent;
    },
  ) => {
    setSelectedFolder(info.node);
    selectFiles(info.node);
  };

  const confirmClose = (confirm: boolean) => {
    if (confirm && nodeInfo) {
      setSelectedFolder(nodeInfo.node);
      selectFiles(nodeInfo.node);
    }
  };

  const selectFiles = (node: EventDataNode<DataNode>) => {
    setDisableManageFolders(true);
    setDisableDeleteFolder(true);
    setDisableAddFolder(true);
    setDisableAddFile(currentViewSelection !== 'Folder');
    if (selectorAddFileRecordId < 0) {
      setSelectedFileRecordId(-1);
    }

    const position = node.pos.split('-');
    if (position.length === 2) {
      const rowIndex = parseInt(position[1]);
      const newSelectedFolder = userFiles?.fileFolders?.[rowIndex];
      setSelectedFileFolder(newSelectedFolder ?? null);
      const newRowData = structuredClone(newSelectedFolder?.files) ?? [];
      setRowData(newRowData);
      setDisableManageFolder(
        userFiles?.fileFolders?.[rowIndex].readOnly ?? false,
      );
    }
    if (position.length === 3) {
      const rowIndex = parseInt(position[1]);
      const subFolderIndex = parseInt(position[2]);
      const newSelectedFolder = userFiles?.fileFolders?.[rowIndex].subFolders[subFolderIndex];
      setSelectedFileFolder(newSelectedFolder ?? null);
      const newRowData = structuredClone(newSelectedFolder?.files) ?? [];
      setDisableDeleteFolder(newRowData.length > 0);
      setDisableManageFolder(
        userFiles?.fileFolders?.[rowIndex].subFolders[subFolderIndex]
          .readOnly ?? false,
      );
      setRowData(newRowData);
    }

    switch ((node.key as string).split('-')[0]) {
      case 'Direct Deposit Documents':
      case 'I9 Documents':
      case 'Federal Tax Documents':
      case 'State Tax Documents':
      case 'Local Tax Documents': {
        const newColumnDefs = defaultColumnDefs.map((colDef) => {
          if (colDef.field === 'name') {
            return {
              ...colDef,
              editable: false,
            };
          }
          return colDef;
        });
        setColumnDefs(newColumnDefs);
        setDisableAddFile(true);
        break;
      }
      case 'MyInfo Documents':
        setColumnDefs(myInfoColumnDefs(isSuperUser, fileFillTypes));
        break;
      case 'Onboarding Documents':
        setColumnDefs(onBoardingColumnDefs(isSuperUser, fileFillTypes));
        break;
      case 'Employee Documents':
        setDisableAddFile(position.length === 2 || currentViewSelection !== 'Folder');
        setDisableManageFolders(false);
        setDisableAddFolder(position.length > 2);
        setColumnDefs(defaultColumnDefs);
        setFileCategoryId(getFolderId());
        break;
      default:
        setColumnDefs(defaultColumnDefs);
    }
  };

  const onRowDataChanged = (e: RowDataChangedEvent) => {
    e.columnApi.autoSizeAllColumns();
    e.api.redrawRows();
  };

  const onGridColumnsChanged = (e: GridColumnsChangedEvent) => {
    e.api.sizeColumnsToFit();
  };
  
  const onCellValueChanged = (e: CellValueChangedEvent) => {
    const newValue = stringToInt(e.newValue);
    if (e.oldValue !== newValue) {
      dispatch(putClientFile(e.data));
    } else {
      setNodeInfo(e.node);
    }
  };

  const deleteFolder = () => {
    if (selectedFolder) {
      dispatch(deleteFileFolder(getFolderId()));
      setSelectedFileFolder(null);
      setSelectedFolder(null);
    }
  };

  const getFolderId = () => {
    if (selectedFolder) {
      return parseInt(
        (selectedFolder.key as string).split('-').pop() ?? '',
      );
    }
    return 0;
  };

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!clientNo) return dispatch(handleError('Client number not properly set'));
    if (e.target.files) {
      const reader: FileReader = new FileReader();
      const file = e.target.files[0];
      reader.onload = (ev: ProgressEvent) => {
        dispatch(
          postClientFileUpload({
            data: (ev.target as FileReader).result as string,
            extension: `.${file.name.split('.').pop()}` || '',
            name: file.name.split('.')[0],
            fileCategoryId: getFolderId(),
            clientNo,
            protectedClientNo: CommonService.getProtectedClientNo(),
          }),
        );
      };
      reader.readAsDataURL(file);
      e.target.value = '';
    }
  };

  const onSelectionChanged = (e: SelectionChangedEvent) => {
    const selectedRows = e.api.getSelectedRows();
    if (selectedRows?.length) {
      setSelectedFileRecordId(selectedRows[0].fileRecordId as number);
      setSelectedFileRecord(selectedRows[0]);
    }
  };

  const getRowId = (params: GetRowIdParams) => {
    return params.data.fileRecordId;
  };

  const deleteFile = () => {
    dispatch(deleteClientFile(selectedFileRecordId));
  };

  const assignFile = () => {
    setShowAssignFile(true);
  };

  const closeAddFolderModal = () => {
    setShowAddFolder(false);
  };

  const closeAssignFileModal = () => {
    const selectedRows = gridRef?.current?.api.getSelectedRows();
    setShowAssignFile(false);
    dispatch(addFileRecordId(-1));
    if (selectedRows?.length) {
      setSelectedFileRecordId(selectedRows[0].fileRecordId as number);
    }
    setDisableManageFolder(false);
  };

  return (
    <Row>
      <Col xs={colView.folderSize} >
        <SelectGrp
          options={fileDisplayType}
          labelField="label"
          valueField="id"
          onChange={changeDisplayType}
        />
        {userFiles && (
          <Tree
            expandAction="click"
            onSelect={onSelect}
            treeData={treeData}
            height={750}
          ></Tree>
        )}
      </Col>
      <Col xs={colView.fileSize}>
        <div className="dm-grid-title pb-2 mr-3">
          Employee Documents
        </div>
        <Row>
          <Col xs="auto">
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                id="dropdown-basic"
                disabled={disableManageFolders}
              >
                <Icon name="key" /> Manage Folders
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => { return setShowAddFolder(true); }}
                  disabled={disableAddFolder}
                >
                  <Icon name="folder-plus" /> Add Sub-Folder in Selected
                  Folder
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => { return setShowAddFolder(true); }}
                  disabled={disableManageFolder}
                >
                  Manage Folder Name/Security
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={disableDeleteFolder}
                  onClick={deleteFolder}
                >
                  <Icon name="folder-minus" /> Delete Folder
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs="auto">
            <button
              type="button"
              className="btn btn-link"
              disabled={disableAddFile}
              onClick={() => { return fileUploadRef.current?.click(); }}
            >
              <Icon
                name="file-upload"
                className="fa-plus-circle"
              /> Add File
            </button>
            <input
              type="file"
              className="d-none"
              ref={fileUploadRef}
              onChange={uploadFile}
            />
          </Col>
          <Col xs="auto">
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                id="dropdown-basic"
                disabled={
                  selectedFileRecordId < 0 ||
                  disableManageFolders
                }
              >
                Manage Files
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => { return deleteFile(); }}
                  disabled={fileCategoryId === -1}
                >
                  <Icon name="trash-alt" /> Delete File
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={assignFile}
                  disabled={disableManageFolders}
                >
                  <Icon name="key" /> Assign Employee(s) to Selected
                  File
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <div
          className="ag-theme-balham"
          style={{ height: 400 }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            singleClickEdit={true}
            editType="fullRow"
            onCellValueChanged={onCellValueChanged}
            onRowDataChanged={onRowDataChanged}
            onGridColumnsChanged={onGridColumnsChanged}
            getRowId={getRowId}
            defaultColDef={defaultColDef}
            onSelectionChanged={onSelectionChanged}
            rowSelection="single"
            stopEditingWhenCellsLoseFocus={true}
          ></AgGridReact>
        </div>
        {showConfirmationModal && (
          <ConfirmationModal
            title="You Have Unsaved Changes"
            message={'Would you still like to close?'}
            show={showConfirmationModal}
            onConfirmed={confirmClose}
            onHide={() => { return setShowConfirmationModal(false); }}
          />
        )}
      </Col>

      <Modal
        show={showAddFolder}
        onHide={closeAddFolderModal}
        size="sm"
      >
        <Modal.Header closeButton={true}>
          <Modal.Title className="dm-card-title">
            New Folder
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddFolder
            onHide={closeAddFolderModal}
            fileFolder={selectedFileFolder}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={fileLoading}
        size="sm"
      >
        <Modal.Header>
          <Modal.Title className="dm-card-title">
            Loading...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProgressBar
            animated
            now={100}
          />
        </Modal.Body>
      </Modal>
      {selectedFileFolder && (
        <Modal
          show={showAssignFile}
          size="sm"
          onHide={closeAssignFileModal}
        >
          <Modal.Header closeButton={true}>
            <Modal.Title className="dm-card-title">
              Assign Employee(s) to Selected File
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedFileRecordId && <AssignFile
              onHide={closeAssignFileModal}
              fileRecordId={selectedFileRecordId}
              fileCategoryId={selectedFileFolder!.fileCategoryId}
              assignedTo={selectedFileRecord?.employeesAssignedTo ?? []}
            />}
          </Modal.Body>
        </Modal>
      )}
    </Row>
  );
};

export default EmployeeDocsPage;
