import PanelHeader from 'core/components/shared/PanelHeader';
import { DownloaderRequest, UserMap, UserMapItem } from 'core/models/Downloader.model';
import { storeUserMap, getUserMaps, postDownloader, getDownloaderFields, storeSelectedClients } from 'core/store/slices/downloader.slice';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'utilities/hooks';
import DownloaderMaps from './DownloaderMaps';
import { DateTime } from 'luxon';
import { getUsers } from 'core/store/slices/cmUsers.slice';
import NewMapDescriptionModal from './NewMapDescription.modal';

const DownloaderPage = () => {
  const dispatch = useDispatch();
  const client = useAppSelector((state) => { return state.client.client; });
  const userMapStore = useAppSelector((state) => { return state.downloader.userMaps; }) as UserMap;
  const [userMaps, setUserMaps] = useState<UserMap>();
  const { downloaderType } = useParams<any>();
  const [title, setTitle] = useState<string>('');
  const [IsHrDownload, setIsHrDownload] = useState<boolean>(false);
  const [IsEmpEarnDownload, setIsEmpEarnDownload] = useState<boolean>(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState<boolean>(false);
  

  useEffect(() => {
    dispatch(getUserMaps());
    dispatch(getUsers());
    dispatch(getDownloaderFields());
    //PI-8693 We now store the selected clients in state so they show on the edit map page. So be sure to clear them on inital DownloadPage load.
    dispatch(storeSelectedClients([]));
  }, []);
  
  useEffect(() => {
    if (userMapStore) {
      setUserMaps(userMapStore);
    }
  }, [userMapStore]);

  useEffect(() => {
    switch (downloaderType) {
      case 'ee':
        setTitle('Employee Earnings Download');
        setIsEmpEarnDownload(true);
        setIsHrDownload(false);
        break;
      case 'em':
        setTitle('Employee Master Download');
        setIsEmpEarnDownload(false);
        setIsHrDownload(false);
        break;
      case 'hr':
        setTitle('Human Resources Download');
        setIsHrDownload(true);
        setIsEmpEarnDownload(false);
        break;
    }
  }, [downloaderType]);

  const onAddMap = () => {
    const newMap: UserMapItem = {
      userMapId: 0,
      mapId: 0,
      userName: '',
      description: 'Not sure how to handle this',
      isEarningsDownload: IsEmpEarnDownload,
      isHrDownload: IsHrDownload,
      updateExportMapFields: false, //PI-8533: Only set this to true on FormatExcelForm
      earningsMapType: (IsHrDownload) ? 'EmployeeEarningsDetail' : null,
      font: undefined,
      headerFooter: undefined,
      downloadSchedule: undefined,
      selectedFields: [],
      emails: [],
    };

    dispatch(storeUserMap(newMap));
    setShowDescriptionModal(true);
  };

  return (
    <Row className="w-100">
      <Col>
        <div className="dm-panel dm-panel-border">
          <PanelHeader title={title}>
            <button
              className="btn btn-primary orange-outline-button mr-2 mb-3"
              type="button"
              onClick={() => {
                onAddMap();
              }}
            >
              New Map
            </button>
          </PanelHeader>
          <Tabs
            className="mt-3"
            defaultActiveKey="MDM"
            id="downloader-tabs"
          >
            <Tab
              eventKey="MDM"
              title="My Download Maps (MDM)"
            >
              {userMaps && (
                <DownloaderMaps
                  key={'MDM'}
                  downloaderType={downloaderType}
                  tab={'MDM'}
                  userMaps={userMaps}
                  isHrDownload = {IsHrDownload}
                  isEmpEarnDownload = {IsEmpEarnDownload}
                />
              )}
            </Tab>
            <Tab
              eventKey="DMPS"
              title="DMPS Download Maps (DMPS)"
            >
              {userMaps && (
                <DownloaderMaps
                  key={'DMPS'}
                  downloaderType={downloaderType}
                  tab={'DMPS'}
                  userMaps={userMaps}
                  isHrDownload = {IsHrDownload}
                  isEmpEarnDownload = {IsEmpEarnDownload}
                />
              )}
            </Tab>
            <Tab
              eventKey="CCM"
              title="Current Client Users Maps (CCM)"
            >
              {userMaps && (
                <DownloaderMaps
                  key={'CCM'}
                  downloaderType={downloaderType}
                  tab={'CCM'}
                  userMaps={userMaps}
                  isHrDownload = {IsHrDownload}
                  isEmpEarnDownload = {IsEmpEarnDownload}
                />
              )}
            </Tab>
          </Tabs>
        </div>
        {showDescriptionModal &&
          <NewMapDescriptionModal
            downloaderType={downloaderType}
            show={showDescriptionModal}
            onHide={() => { return setShowDescriptionModal(false); }}
          />
        }
      </Col>
    </Row>
  );
};

export default DownloaderPage;