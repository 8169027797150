import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  InputGrp,
  InputGrpInLine,
  RadioGrp,
} from '../../../core/components/form-controls';
import { TextareaGrp } from '../../../core/components/form-controls/TextareaGrp';
import { FieldInputSettings } from '../../../core/components/form-controls/types';
import ConfirmationModal from '../../../core/components/modals/confirmation.modal';
import { Client } from '../../../core/models';
import {
  PayrollUpload,
} from '../../../core/models/PayrollUpload.model';
import { storeSelectedPayrollUploadFile } from '../../../core/store/actions';
import { getClient } from '../../../core/store/selectors';
import { useRadioButtonChange } from '../../../utilities/hooks';

type PropTypes = {
  csvFile: any;
  fileName: string;
  show: boolean;
  payrollUploadClientFile: PayrollUpload;
  setHeaderLine: React.Dispatch<React.SetStateAction<number>>;
  onHide: () => void;
  next: () => void;
};

const UploadPayrollStep1Modal: React.FC<PropTypes> = ({
  csvFile,
  fileName,
  show,
  payrollUploadClientFile,
  setHeaderLine,
  onHide,
  next,
}) => {
  const dispatch = useDispatch();
  const client = useSelector(getClient) as Client;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [showUnsavedData, setShowUnsavedData] = useState(false);
  const clientFile = payrollUploadClientFile?.clients && payrollUploadClientFile.clients[0];

  const { register, errors, formState, setValue, handleSubmit } =
    useForm<PayrollUpload>({
      defaultValues: {
        ...payrollUploadClientFile,
      },
    });

  useEffect(() => {
      const startPosition = clientFile ? clientFile.startOnLine : 1;
      setFileHeaderAndBody(startPosition);
      setHeaderLine(startPosition);
      setValue('description', payrollUploadClientFile?.description);
      setValue('startOnLine', startPosition);
  }, []);

  useEffect(() => {
    if (formState) {
      setFormIsDirty(
        Object.keys(formState.touched).length !== 0 ||
        formState.isDirty,
      );
    }
  }, [formState]);

  const onStartOnLine = (e: any) => {
    if (+e.target.value > -1) {
      setFileHeaderAndBody(+e.target.value)
      setHeaderLine(+e.target.value)
    } else {
      setValue('startOnLine', 1);
      setHeaderLine(1)
    }
  };

  const setFileHeaderAndBody = (startPosition: number) => {
    const splitCSVFile = csvFile.split(/\n/, 10);
      const filterArray = ['', ...splitCSVFile];
      const header = startPosition > 1 ? filterArray.slice(1, startPosition).join('\n') : '';
      const body = startPosition < 1 ? filterArray.slice(1) : filterArray.slice(startPosition);
      setValue('fileHeader', header);
      setValue('fileBody', body.join('\n'));
  }

  const onNext = (data: any) => {
    const newUploadFile = {
      mapId: payrollUploadClientFile?.mapId ?? 0,
      code: '' + client.clientNo,
      style: data.style ?? 'D',
      description: data.description,
      quotedStrings: false,
      fields: payrollUploadClientFile?.fields,
      clients: [
        {
          clientUploadId: 0,
          mapId: payrollUploadClientFile?.mapId ?? 0,
          clientNo: client.clientNo,
          active: true,
          startOnLine: data.startOnLine,
          combineHours: data.combineHours === 'true',
          trackSalaryEmpOtherHours: data.trackSalaryEmpOtherHours === 'true',
          matchMultiRateByDept: data.matchMultiRateByDept === 'true',
          clientNumberInFile: data.clientNumberInFile === 'true',
          useClockNumber: false,
          defaultUploadPath: '',
          defaultUploadFileName: '',
          matchMultiRateBySubDept: false,
          matchMultiRateBySubDept2: false,
          matchMultiRateByDescription: data.matchMultiRateByDescription === 'true'
        },
      ],
    } as PayrollUpload;
    dispatch(storeSelectedPayrollUploadFile(newUploadFile));

    onHide();
    next();
  };

  const [fs, setFs] = useState<FieldInputSettings>({
    description: {
      name: 'description',
      label: 'Upload Template Description',
      required: true,
    },
    style: {
      name: 'style',
      defaultChecked: 'D',
      label: 'Choose the file that best describes your upload file:',
      radioOptions: [
        {
          value: 'D',
          label: 'Delimited (Comma delimited)',
        },
        {
          value: 'F',
          label: 'Fixed width',
        },
      ],
    },
    clientNumberInFile: {
      name: 'clientNumberInFile',
      label: 'Client Number in upload file:',
      defaultChecked: clientFile?.clientNumberInFile,
    },
    combineHours: {
      name: 'combineHours',
      label: 'Combine Hours',
      defaultChecked: clientFile?.combineHours,
    },
    trackSalaryEmpOtherHours: {
      name: 'trackSalaryEmpOtherHours',
      defaultChecked: clientFile?.trackSalaryEmpOtherHours,
      label: `Track Other Hours Column for salaried employees: 
			${'\n'}
		If other hours are found on a Salaried Employee, the row will be tracked`,
    },
    startOnLine: {
      name: 'startOnLine',
      label: 'Start import at row',
      type: 'number',
      labelWidth: 35,
    },
    matchMultiRateByDept: {
      name: 'matchMultiRateByDept',
      label: 'If the employee has more than one rate, match rate on Alt Dept in upload file.',
      defaultChecked: clientFile?.matchMultiRateByDept,
    },
    matchMultiRateByDescription: {
      name: 'matchMultiRateByDescription',
      label: 'If employee has more than one rate, match rate on Pay Rate Description in upload file.',
      defaultChecked: clientFile?.matchMultiRateByDescription,
    },
    fileHeader: {
      name: 'fileHeader',
      label: 'FILE HEADER',
      rows: 2,
    },
    fileBody: {
      name: 'fileBody',
      label: 'FILE BODY',
      rows: 8,
    },
  });
  const [handleRadioButtonChange] = useRadioButtonChange(fs);

  const closeModal = (e: any) => {
    if (!formIsDirty) {
      e.stopPropagation();
      onHide();
    } else {
      setShowConfirmationModal(true);
    }
  };

  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      onHide();
    }
  };

  return (
    <div onClick={(e) => closeModal}>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        animation={false}
      >
        <div className="modal-header">
          <div className="dm-card-title">
            Step 1 of 2: Modify Payroll Map
          </div>
          <button type="button" className="modal-close-btn"
            onClick={closeModal}>
            <Icon name="times"
            />
          </button>
        </div>
        <Modal.Body>
          <form onSubmit={handleSubmit(onNext)}>
            <div className="row">
              <div className="col-12">
                <div className="dm-card-subtitle">
                  Upload Options
                </div>
              </div>
              <div className="col-6">
                <InputGrp
                  {...fs.description}
                  errors={errors.description}
                  ref={register({
                    required: 'Description is required.',
                  })}
                />
              </div>
              <div className="col-6">
                <RadioGrp
                  {...fs.combineHours}
                  errors={
                    errors?.clients?.length &&
                    errors?.clients[0]?.combineHours
                  }
                  ref={register}
                  onChange={handleRadioButtonChange}
                />
              </div>
              <div className="col-6">
                <RadioGrp
                  {...fs.style}
                  errors={errors.style}
                  ref={register}
                  disabled={true}
                />
              </div>
              <div className="col-6">
                <RadioGrp
                  {...fs.trackSalaryEmpOtherHours}
                  errors={
                    errors?.clients?.length &&
                    errors?.clients[0]
                      ?.trackSalaryEmpOtherHours
                  }
                  ref={register}
                  onChange={handleRadioButtonChange}
                />
              </div>
              <div className="col-6">
                <RadioGrp
                  {...fs.clientNumberInFile}
                  errors={errors?.clients?.length &&
                    errors?.clients[0]
                      ?.clientNumberInFile}
                  ref={register}
                  disabled={false}
                />
              </div>
              <div className="col-6">
                <RadioGrp
                  {...fs.matchMultiRateByDept}
                  errors={
                    errors?.clients?.length &&
                    errors?.clients[0]?.matchMultiRateByDept
                  }
                  ref={register}
                  onChange={handleRadioButtonChange}
                />
              </div>
              <div className="col-6">
                <InputGrpInLine
                  {...fs.startOnLine}
                  errors={
                    errors?.clients?.length &&
                    errors?.clients[0]?.startOnLine
                  }
                  ref={register({ valueAsNumber: true })}
                  onChange={onStartOnLine}
                  groupClass="mb-1"
                />
                <h6 className="pl-1 mb-1">
                  If the data does not begin on the first line
                  of the upload
                </h6>
                <h6 className="pl-1">
                  file, then specify the first row of data in
                  the upload file.
                </h6>
              </div>
              <div className="col-6">
                <RadioGrp
                  {...fs.matchMultiRateByDescription}
                  errors={
                    errors?.clients?.length &&
                    errors?.clients[0]?.matchMultiRateByDescription
                  }
                  ref={register}
                  onChange={handleRadioButtonChange}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <TextareaGrp
                  {...fs.fileHeader}
                  ref={register}
                  disabled = {true}
                  style={{ fontFamily: 'monospace' }}
                />
              </div>
              <div className="col-12">
                <TextareaGrp
                  {...fs.fileBody}
                  ref={register}
                  disabled = {true}
                  style={{ fontFamily: 'monospace' }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-right">
                <button
                  type="button"
                  className="btn btn-link"
                  style={{ fontSize: '14px' }}
                  disabled={true}
                >
                  <Icon name="chevron-left" /> Previous
                </button>
                <button
                  type="submit"
                  className="btn btn-link"
                  style={{ fontSize: '14px' }}
                >
                  Next <Icon name="chevron-right" />
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {showConfirmationModal && (
        <ConfirmationModal
          title="You Have Unsaved Changes"
          message={'Would you still like to close?'}
          show={showConfirmationModal}
          onConfirmed={confirmClose}
          onHide={() => { return setShowConfirmationModal(false); }}
        />
      )}
    </div>
  );
};
export default UploadPayrollStep1Modal;