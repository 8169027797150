import React, { useEffect, useState } from 'react';
import { statusOptions } from '../status-options';
import Icon from 'core/components/shared/Icon';
import { ApplicantStatus } from 'enums/ApplicantStatus';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { isNil } from 'lodash';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { getApplicantStatus } from 'core/store/actions/applicant-tracking.action';
import Offer from './Offer';
import Interviews from './Interviews';
import Onboarding from './Onboarding';

type Props = {
  status?: number;
};

const Overview = ({ status }: Props) => {
  const appDispatch = useAppDispatch();
  const activeStatus = statusOptions.find(item => {
    return item.value === status;
  });
  const { offer } = useAppSelector(state => {
    return state.applicantTrackingV2;
  });

  const isStatusInterview = status === ApplicantStatus.INTERVIEW;
  const isStatusHire = status === ApplicantStatus.HIRE;
  const isStatusOnboard = status === ApplicantStatus.ONBOARD;

  let statusIcon: IconName = 'xmark-to-slot';
  let overrideLabel = '';
  let statusDescription: string[] = [];

  if (isStatusHire) {
    statusIcon = 'handshake-simple';
    statusDescription = ['Applicants at this stage have had their initial information reviewed, and not it will be decided whether to send them an offer, or to not pursue them any longer'];
  } else if (status === ApplicantStatus.NEW_APPLICANT) {
    statusIcon = 'user-plus';
    statusDescription = [
      'Applicants at this stage have completed and submitted an initial job application. Their information should be reviewed before progression to the next stage (or rejection).',
      'Their submitted application and all other information can be reviewed by clicking the \'Details\' tab.',
    ];
  } else if (isStatusInterview) {
    statusIcon = 'phone-arrow-right';
    statusDescription = ['Applicants at this stage have had their initial information reviewed and are ready to be interviewed.', 'Interviews can be modified by clicking the Edit icon and additional interviews can be added by clicking the Add Interview icon.'];
  } else if (isStatusOnboard) {
    overrideLabel = 'Onboarding';
    statusIcon = 'address-card';
    statusDescription = ['Applicants at this stage have accepted an offer and are ready to be onboarded into full employees. By pressing "Send Onboard Credentials" button, the system will automatically create an employee master record for this new employee and send an email containing a username and password for the MyInfo self service. Once the employee logs into the MyInfo website, they will be able to complete their onboarding process.'];
  } else if (status === ApplicantStatus.EMPLOYEE) {
    statusIcon = 'users';
  }

  useEffect(() => {
    appDispatch(getApplicantStatus());
  }, [status]);

  if (isNil(status)) {
    return null;
  }

  return (
    <div className="px-3 pb-3">
      <div
        className="p-3"
        style={{
          background: 'white',
        }}
      >
        <div
          className="d-flex align-items-center mb-1"
          style={{ gap: '0.5rem' }}
        >
          <Icon name={statusIcon} />
          <h4 className="font-weight-bold mb-0">{overrideLabel || activeStatus?.label}</h4>
          {isStatusHire && <span>{offer?.statusDescription}</span>}
        </div>
        <div className="mb-2">
          {statusDescription?.map(desc => {
            return (
              <p
                key={desc}
                className="mb-0"
              >
                {desc}
              </p>
            );
          })}
        </div>
        {isStatusInterview && (
          <Interviews />
        )}
        {isStatusHire && (
          <Offer />
        )}
        {isStatusOnboard && (
          <Onboarding />
        )}
      </div>
    </div>
  );
};

export default Overview;