import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';
import { ColDef, GridApi, GridOptions, RowSelectedEvent } from '@ag-grid-enterprise/all-modules';
import { getDropdownOptions } from 'core/store/selectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type PropTypes = {
  codes: string[];
  passDataFromLocationGrid: (codes: string[], modified: boolean) => void
};
const LocationGrid: React.FC<PropTypes> = ({ codes, passDataFromLocationGrid }) => {

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [changed, setChanged] = useState(false);

  const { locationOpts } =
        useSelector(getDropdownOptions);



  const columns: ColDef[] = [
    {
      field: 'select',
      headerName: '',
      editable: false,
      checkboxSelection: true,
      width: 40,
    },
    {
      field: 'locationCode',
      headerName: 'Code',
      width: 60,
    },
    {
      field: 'locationDesc',
      headerName: 'Description',
      width: 180,
    },
  ];

  const locationGridOpts: GridOptions = {
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    columnDefs: columns,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (codes && gridApi) {
      gridApi?.forEachNode((node) => {
        if (codes.includes('' + node.data.locationCode)) {
          node.setSelected(true);
        }
      });
    }
  }, [codes, gridApi]);


  const onRowClicked = (e: RowSelectedEvent) => {
    e && e.node.setSelected(e.node.isSelected() === true ? true : false);
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      setChanged(selectedRows.length ? true : false);
      passDataFromLocationGrid(selectedRows.map(r => {return r.locationCode;}), changed);
    }
  };

  return (
    <AgGridReact
      gridOptions={locationGridOpts}
      rowData={locationOpts}
      onGridReady={onGridReady}
      onRowClicked={onRowClicked}
      onSelectionChanged={onSelectionChanged}

    />
  );
};

export default LocationGrid;