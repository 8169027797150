import React from 'react';
import HamburgerMenu from 'core/components/shared/HamburgerMenu';

type DashboardToolBarProps = {
  toggleSidebar: () => void;
  showSidebarMenuIcon: boolean;
};

const DashboardToolBar: React.FC<DashboardToolBarProps> = ({ toggleSidebar, showSidebarMenuIcon }) => {

  return (
    <div className="dm-toolbar">
      {showSidebarMenuIcon && (
        <HamburgerMenu
          toggleSidebar={toggleSidebar}  
        />
      )}
    </div>
  );
};

export default DashboardToolBar;
