export interface ICreateOnboardEmployee {
  clientNo: number;
  empNo: number;
  firstName: string;
  middleInitial: string;
  lastName: string;
  suffix: string;
  ssn: string;
  gender: string;
  address: string;
  city: string;
  state: string;
  withHoldingState: string;
  zip: string;
  birthDate: Date | string | null;
  emailAddress: string;
  localTaxEntityID: number;
  emailTemplateID: number;
  selectedOnboardDocIDs: number[];
}

export class CreateOnboardEmployee implements ICreateOnboardEmployee {
  clientNo = 0;

  empNo = 0;

  firstName = '';

  middleInitial = '';

  lastName = '';

  suffix = '';

  ssn = '';

  gender = '';

  address = '';

  city = '';

  state = '';

  withHoldingState = '';

  zip = '';

  birthDate = '';

  emailAddress = '';

  localTaxEntityID = 0;

  emailTemplateID = 0;

  selectedOnboardDocIDs = [];

  constructor(clientNo: string | number, empNo: string | number, props?: { [key: string]: any }) {
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    props && CreateOnboardEmployee.convProps(this, props);
    props && props.ssn && (this.ssn = props.ssn.replace(/-/g, ''));

  }

  static convProps(that: { [key: string]: any }, props: { [key: string]: any }) {
    const numberProps = ['clientNo', 'empNo', 'localTaxEntityID', 'emailTemplateID'];

    Object.keys(props).forEach((prop: string) => {
      if (prop === 'birthDate') {
        that[prop] = CreateOnboardEmployee.dateToString(props[prop]);
      } else if (numberProps.includes(prop)) {
        that[prop] = CreateOnboardEmployee.stringToInt(props[prop]);
        // TODO this only works if zip is before zip4!!!
      } else if (prop === 'zip4') {
        that.zip = that.zip + props.zip4;
      } else if (prop === 'ssn') {
        that[prop] = props.ssn.replace(/-/g, '');
      } else if (prop === 'gender') {
        that[prop] = CreateOnboardEmployee.nullToString(props[prop]);
      } else {
        that[prop] = props[prop];
      }
    });
  }

  static nullToString(value: string | null | undefined) {
    return !value ? '' : value;
  }
  
  static stringToInt(i: any) {
    if (typeof i === 'string') {
      return i ? parseInt(i) : 0;
    }
    return i;
  }

  static dateToString(d: any) {
    if (!d) return null;
    if (new Date(d)?.getTime()) return new Date(d).toISOString();
    return (d instanceof Date) ? d.toISOString() : null;
  }

  static toObject(obj: ICreateOnboardEmployee): ICreateOnboardEmployee {
    const output = {};
    CreateOnboardEmployee.convProps(output, obj);
    return output as ICreateOnboardEmployee;
  }
}

export interface ResendOnboardingEmail {
  applicantId:number;
}