import { ICellRendererParams } from '@ag-grid-community/core';

export function agHoursRenderer(): any {}

agHoursRenderer.prototype.init = function (params: ICellRendererParams) {
  const value = params.value;
  this.divWrapper = document.createElement('div');
  this.divWrapper.innerHTML =
        value && typeof value === 'number' && value !== 0 ? value : ' -';
};

agHoursRenderer.prototype.getGui = function (params: ICellRendererParams) {
  return this.divWrapper;
};

agHoursRenderer.prototype.refresh = function (params: ICellRendererParams) {
  return true;
};

agHoursRenderer.prototype.destroy = function () {};
