import { ofType } from 'redux-observable';
import { catchError, from, map, Observable, switchMap } from 'rxjs';
import { HttpResponse } from '../../models';
import { EarningsReportsStandardReportsRequest, EmployeeWageDetailDownloadReportRequest } from '../../models/EarningsReports.model';
import { EarningsReportsService } from '../../services/earnings-reports.service';
import { handleError } from '../actions';
import {

  downloadEmployeeWageDetailDownloadReport,
  loadStandardReports,
  storeEmployeeWageDetailDownloadReport,
  storeStandardReports,
} from '../actions/earnings-reports.action';

interface Actions<T> {
  type: string;
  payload: T;
}

const loadStandardReports$ = (
  action$: Observable<Actions<EarningsReportsStandardReportsRequest>>,
) => {
  return action$.pipe(
    ofType(loadStandardReports.type),
    switchMap((action: { payload: EarningsReportsStandardReportsRequest; }) => {
      return from(
        EarningsReportsService.getStandardReports(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<string>) => { return storeStandardReports(res.value); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postEmployeeWageDetailDownloadReports$ = (
  action$: Observable<Actions<EmployeeWageDetailDownloadReportRequest>>,
) => {
  return action$.pipe(
    ofType(downloadEmployeeWageDetailDownloadReport.type),
    switchMap((action: { payload: EmployeeWageDetailDownloadReportRequest; }) => {
      return from(
        EarningsReportsService.getEmployeeWageDetailDownloadReport(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<string>) => { return storeEmployeeWageDetailDownloadReport(res.value); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadStandardReports$,
  postEmployeeWageDetailDownloadReports$,
];
