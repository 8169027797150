import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Deduction, DeductionRequest, GarnishmentEntities, HttpResponse } from '../../models';
import { DeductionService } from '../../services';
import {
  createDeduction,
  deleteDeduction,
  handleError,
  handleSuccess,
  loadCompanyBenefits,
  loadDeductions,
  loadGarnishmentEntities,
  setBlockNav,
  storeCompanyBenefits,
  storeDeductions,
  storeGarnishmentEntities,
  toggleBlockNavigation,
  triggerEmFieldValidation,
  updateSavingState,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadDeductions$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadDeductions.type),
    switchMap((action: { payload: string; }) => {
      return from(DeductionService.getDeductions(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: Deduction[]) => {
          return  [storeDeductions(res)];
        }),
        catchError((err: HttpResponse<any>) => {return [handleError(err), updateSavingState('error')];}),
      );
    },
    ),
  );
};

const createDeduction$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(createDeduction.type),
    switchMap((action: { payload: DeductionRequest; }) => {
      return from(DeductionService.postDeduction(action.payload.protectedEmpNo, action.payload.Data)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: HttpResponse<Deduction[]>) => {
          return [
            loadDeductions(action.payload.protectedEmpNo),
            storeDeductions(res.value),
            handleSuccess(res.messages),
            toggleBlockNavigation({ block: false }),
            triggerEmFieldValidation({
              section: 'deductions',
              actionType: createDeduction.type,
              callerPayload: res.value,
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => {
          const errorStream = [handleError(err), updateSavingState('error')];
          
          if (typeof err === 'string' && err === "Validation failed: \r\n -- : Error: All active '% of NET' deductions MUST EQUAL 100% Severity: Error") {
            errorStream.push(toggleBlockNavigation({
              block: true,
              message: err,
              type: 'directDeposit',
            }));
          }
          
          return errorStream;
        }),
      );
    },
    ),
  );
};

const deleteDeduction$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(deleteDeduction.type),
    switchMap((action: { payload: Deduction; }) => {
      return from(DeductionService.deleteDeduction(action.payload)).pipe(
        map(() => {return loadDeductions(action.payload.protectedEmpNo);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err), updateSavingState('error')];}),
      );
    },
    ),
  );
};

const loadCompanyBenefits$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadCompanyBenefits.type),
    switchMap((action: { payload: string; }) => {
      return from(DeductionService.getCompanyBenefits(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map((res: Deduction[]) => {return storeCompanyBenefits(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err), updateSavingState('error')];}),
      );
    },
    ),
  );
};

const loadGarnishmentEntities$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadGarnishmentEntities.type),
    switchMap(() => {
      return from(DeductionService.getGarnishmentEntities()).pipe(
        map((res: any) => {return res.data;}),
        map((res: GarnishmentEntities[]) => {return storeGarnishmentEntities(res);}),
        catchError((err: HttpResponse<any>) => {return [handleError(err), updateSavingState('error')];}),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadDeductions$,
  createDeduction$,
  deleteDeduction$,
  loadCompanyBenefits$,
  loadGarnishmentEntities$,
];
