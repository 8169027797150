import React, { useEffect, useState } from 'react';
import {
  ControlDatePickerGrp,
  InputGrp,
  InputGrpCurrency,
  InputGrpDecimal,
  SelectGrp,
} from 'core/components/form-controls';
import { ArrayField } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  getFilteredGarnishmentEntity,
  getGarnishmentFIPsCode,
  getTaxEntityFips,
} from 'core/store/selectors';
import { ClientMasterDtoTaxEntitiesFIPS, Deduction, GarnishmentEntities } from 'core/models';
import { CURRENCY_VALIDATION } from 'core/constants';
import {
  currencyFormatter,
  ddLookup,
  formatDecimal,
  fromCurrency,
  getCurrentDate,
  getAccess,
} from 'utilities/utilities';
import APVendorsModal from './APVendors.modal';
import {
  garnishmentCategoryOptions,
  garnishmentCategoryApVendorOptions,
} from 'dropdowns/garnishmentCategoryOptions';
import Icon from 'core/components/shared/Icon';
import { FormMethods } from 'core/components/form-controls/types';
import styles from 'core/components/form-controls/form-controls.module.scss';
import { garnishmentOptions } from 'dropdowns/deductionStatusOptions';
import { getPercentageOpts, validateExpireDate } from './DeductionUtilites';
import { selectGarnishmentEntities } from 'core/store/selectors/deduction.selector';
import { useAppSelector } from 'utilities/hooks';

type FormMethodUnion = 'errors' | 'setValue' | 'register' | 'watch' | 'getValues';
type PropTypes = {
  deduction: Partial<ArrayField<Deduction>>;
  index: number;
  onDelete: (ded: Deduction) => void;
  showSaveButton?: boolean;
  isCheckCalculator?: boolean;
  formMethods: Pick<FormMethods, FormMethodUnion>;
  control: any;
  onWatch?: (dedId: number, e: Deduction, valid: boolean) => void;
  updateNestedErrors?: (title: string, key?: string, propertyChain?: string) => void;
  onUpdateDeductionGarnishment?: (ded: Deduction) => void;
};

const EmpGarnishmentItem: React.FC<PropTypes> = ({
  deduction,
  onDelete,
  index,
  formMethods: { errors, setValue, register, watch, getValues },
  control,
  onWatch,
  updateNestedErrors,
  onUpdateDeductionGarnishment,
  isCheckCalculator = false,
}) => {

  const [showApVendorModal, setShowApVendorModal] = useState(false);
  const garnishmentEntityOpts = useSelector(getFilteredGarnishmentEntity);
  const garnishmentFIPsCodeOpts = useSelector(getGarnishmentFIPsCode);
  const taxEntityFips = useSelector(getTaxEntityFips) as ClientMasterDtoTaxEntitiesFIPS[];
  const garnishmentEntitiesCreditor = useSelector(selectGarnishmentEntities) as GarnishmentEntities[];
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });

  //Initalizes the names for the properties we are watching
  const categoryIdName = `garnishmentItems[${index}].categoryId`;
  const dateStartName = `garnishmentItems[${index}].dateStart`;
  const dateExpireName = `garnishmentItems[${index}].dateExpire`;
  const bankAccountNoName = `garnishmentItems[${index}].bankAcctNo`;
  const routingNoName = `garnishmentItems[${index}].routingNo`;
  const beginAmountName = `garnishmentItems[${index}].begAmount`;
  const currentAmountName = `garnishmentItems[${index}].currAmount`;
  const dedAmountName = `garnishmentItems[${index}].dedAmount`;
  const exemptAmountName = `garnishmentItems[${index}].exemptAmount`;
  const apidName = `garnishmentItems[${index}].apid`;
  const entityIdName = `garnishmentItems[${index}].entityId`;
  const stopName = `garnishmentItems[${index}].status`;
  const caseIdName = `garnishmentItems[${index}].caseId`;
  const fipsName = `garnishmentItems[${index}].fips`;
  const supportPctName = `garnishmentItems[${index}].supportPct`;
  const medicalInsName = `garnishmentItems[${index}].medicalIns`;

  const fs = {
    categoryId: {
      name: categoryIdName,
      label: 'CATEGORY',
      groupClass: 'groupClass11',
    },
    entityId: {
      name: entityIdName,
      label: 'ENTITY',
      modalTitle: 'ENTITIES',
      groupClass: 'groupClass08',
      type: 'number',
    },
    caseId: {
      name: caseIdName,
      label: 'CASE DESC',
      groupClass: 'groupClass10',
    },
    fips: {
      name: fipsName,
      label: 'F.I.P.S.',
      groupClass: 'groupClass08',
    },
    supportPct: {
      name: supportPctName,
      label: 'PERCENT',
      groupClass: 'groupClass08',
    },
    medicalIns: {
      name: medicalInsName,
      label: 'MED INS',
      groupClass: 'groupClass08',
    },
    exemptAmount: {
      name: exemptAmountName,
      label: 'EXEMPT AMOUNT',
      groupClass: 'groupClass11',
    },
    begAmount: {
      name: beginAmountName,
      label: 'BEGIN BALANCE',
      groupClass: 'groupClass11',
      errorMsg: 'Beg Balance must be > 0',
    },
    currAmount: {
      name: currentAmountName,
      label: 'REMAINING BALANCE',
      groupClass: 'groupClass13',
    },
    dedAmount: {
      name: dedAmountName,
      label: 'AMOUNT',
      groupClass: 'groupClass11',
    },
    dateStart: {
      name: dateStartName,
      label: 'START',
      required: !isCheckCalculator, 
      errorMsg: 'Start date is required.',
      groupClass: 'groupClass12',
    },
    dateExpire: {
      name: dateExpireName,
      label: 'EXPIRE',
      groupClass: 'groupClass12',
    },
    stop: {
      name: stopName,
      label: 'STOP',
      groupClass: 'groupClass12',
    },
    apid: {
      name: apidName,
      label: 'AP',
      groupClass: 'groupClass10',
    },
    bankAcctNo: {
      name: bankAccountNoName,
      label: 'ACCOUNT #',
      groupClass: 'groupClass12',
    },
    routingNo: {
      name: routingNoName,
      label: 'ROUTING NUMBER',
      groupClass: 'groupClass12',
    },
  };

  //These will be to filter what is readonly and what is not based on the categoryId that is selected
  const [booleanState, setBooleanState] = useState({
    ReadOnlyFIPS : true,
    ReadOnlyPercent: true,
    ReadOnlyMedIns: true,
    ReadOnlyExemptAmt: true,
    ReadOnlyBeginBal: true,
    ReadOnlyRemainBal: true,
    ReadOnlyAccountNo: true,
    ReadOnlyRoutingNo: true,
    ReadOnlyAP: false,
    ReadOnlyDedAmount: false,
  });
  
  useEffect(() => {
    if (updateNestedErrors) {
      updateNestedErrors('garnishments', 'garnishmentItems');
    }
  }, [errors]);
  
  //Set the watch values
  const entityId = watch(entityIdName);
  const apid = watch(apidName);
  const fips = watch(fipsName);
  const categoryId = watch(categoryIdName);
  const dateExpire = watch(dateExpireName);
  const disabledFIPS = !entityId || booleanState.ReadOnlyFIPS || (isCheckCalculator && (deduction?.dedId ?? 0) >= 0);

  //This will initalize anything I need when loading or adding a deduction 
  useEffect(() => {
    if (!deduction) return;

    onCategoryChange(deduction.categoryId ?? 1);
  }, [deduction]);

  //Only set the default date if the date start changes and the defaultDate
  const onStartDateChange = (startDate: Date) => {
    if (!deduction || !startDate) return;

    const defaultDate = getCurrentDate(20);
    if (!getValues(dateExpireName)) setValue(dateExpireName, defaultDate, { shouldDirty: false });
    updateGarnishmentField({
      dateStart: startDate,
      dateExpire: defaultDate,
    });
  };

  //This will set the fips record if there are any matching taxEntityFips to the fips that was selected
  useEffect(() => {
    if (categoryId != 1) {
      setValue(routingNoName, '', { shouldDirty: false });
      setValue(bankAccountNoName, '', { shouldDirty: false });
      return;
    }

    const matchingFipsRecord = taxEntityFips.find(x => {return x.fipsCode.trim() === fips?.trim();});
    
    if (!matchingFipsRecord || !(matchingFipsRecord.bankAcctNo && matchingFipsRecord.routingNo)) return;
    
    setValue(routingNoName, matchingFipsRecord.routingNo);
    setValue(bankAccountNoName, matchingFipsRecord.bankAcctNo);
    setValue(apidName, '');
    
    setBooleanState((prevState) => {
      return { ...prevState,
        ReadOnlyAP: true };
    });
  }, [fips]);

  //This will set the filters based on the category id.
  function onCategoryChange(id : number) {
    switch (+id) {
    //Friend of Court
      case 1:
        if ((deduction.dedId ?? -1) < 0) 
          supportPercentChange(50);
        setBooleanState((prevState) => {
          return { ...prevState,
            ReadOnlyFIPS: false,
            ReadOnlyPercent: false,
            ReadOnlyMedIns: false,
            ReadOnlyExemptAmt: true,
            ReadOnlyBeginBal: true,
            ReadOnlyRemainBal: true,
            ReadOnlyDedAmount: false,
            ReadOnlyAP: false };
        });
        break;
        //Creditor
      case 2:
        if ((deduction.dedId ?? -1) < 0) 
          supportPercentChange(25);
        setBooleanState((prevState) => {
          return { ...prevState,
            ReadOnlyFIPS: true,
            ReadOnlyPercent: false,
            ReadOnlyMedIns: true,
            ReadOnlyExemptAmt: true,
            ReadOnlyBeginBal: false,
            ReadOnlyRemainBal: false,
            ReadOnlyDedAmount: true,
            ReadOnlyAP: false };
        });
        break;
        //Levy
      case 3:
        if ((deduction.dedId ?? -1) < 0) 
          supportPercentChange(25);
        setBooleanState((prevState) => {
          return { ...prevState,
            ReadOnlyFIPS: true,
            ReadOnlyPercent: false,
            ReadOnlyMedIns: true,
            ReadOnlyExemptAmt: false,
            ReadOnlyBeginBal: false,
            ReadOnlyRemainBal: false,
            ReadOnlyDedAmount: false,
            ReadOnlyAP: false };
        });
        break;
        //Student Loan
      case 4:
        if ((deduction.dedId ?? -1) < 0) 
          supportPercentChange(15);
        setBooleanState((prevState) => {
          return { ...prevState,
            ReadOnlyFIPS: true,
            ReadOnlyPercent: true,
            ReadOnlyMedIns: true,
            ReadOnlyExemptAmt: true,
            ReadOnlyBeginBal: false,
            ReadOnlyRemainBal: false,
            ReadOnlyDedAmount: true,
            ReadOnlyAP: false };
        });
        break;
        //Bankrupt
      case 5:
        if ((deduction.dedId ?? -1) < 0) 
          supportPercentChange(0);
        setBooleanState((prevState) => {
          return { ...prevState,
            ReadOnlyFIPS: true,
            ReadOnlyPercent: true,
            ReadOnlyMedIns: true,
            ReadOnlyExemptAmt: true,
            ReadOnlyBeginBal: true,
            ReadOnlyRemainBal: true,
            ReadOnlyDedAmount: false,
            ReadOnlyAP: false };
        });
        break;
        //Court
      case 6:
        if ((deduction.dedId ?? -1) < 0) 
          supportPercentChange(0);
        setBooleanState((prevState) => {
          return { ...prevState,
            ReadOnlyFIPS: true,
            ReadOnlyPercent: true,
            ReadOnlyMedIns: true,
            ReadOnlyExemptAmt: true,
            ReadOnlyBeginBal: false,
            ReadOnlyRemainBal: false,
            ReadOnlyDedAmount: false,
            ReadOnlyAP: false };
        });
        break;
      //Not sure what the default should be yet...
      default:
        setBooleanState((prevState) => {
          return { ...prevState,
            ReadOnlyFIPS: false,
            ReadOnlyPercent: true,
            ReadOnlyMedIns: true,
            ReadOnlyExemptAmt: true,
            ReadOnlyBeginBal: true,
            ReadOnlyRemainBal: true,
            ReadOnlyDedAmount: false,
            ReadOnlyAP: false };
        });
        break;
    }
  }
  
  const onEntityId = (e: any) => {
    const id = '' + e.nativeEvent.target.value;
    const entity = garnishmentEntityOpts.find((ge) => { return ge.id === id; });
    if (entity) {
      supportPercentChange(-1);
      updateGarnishmentField({ 'entityId': +e.target.value ?? null });
    } 
  };

  const getSupportPercentOverride = (entityIdParam: number) : number => {
    const garnishmentEntityRecord = garnishmentEntitiesCreditor.find(x => {return x.categoryId === +categoryId && x.entityId === +entityIdParam;});
    return (garnishmentEntityRecord?.percentOverride ?? 0) * 100;
  };

  const supportPercentChange = (defaultPercent: number) => {
    const percentOverride = getSupportPercentOverride(entityId);
    if (percentOverride !== 0) { 
      setValue(supportPctName, percentOverride);
    } else if (defaultPercent !== -1) {
      setValue(supportPctName, defaultPercent);
    }
  };

  //Will get the fips options based on the entity id that was selected
  const getFipsOptions = (category: number) => {
    const entity = garnishmentEntityOpts.find((ge) => { return ge.id === ('' + entityId); })
    switch (+category) {
      case 1:
        return [
          ...garnishmentFIPsCodeOpts.filter(
            (fip) => { return fip.code === entity?.code; },
          ),
        ];
      default:
        return [
          {
            id: '',
            description: '',
          },
        ];
    }
  };

  //This function will check to see if it is a new deduction if so no prompt needed. If its an old deduction will prompt the user when
  //changing the category since it will clear some items.
  const updateBalanceValues = () => {
    if (getAccess(sectionAccess, 5)?.readOnly ?? false) return;

    if (booleanState.ReadOnlyBeginBal) setValue(beginAmountName, currencyFormatter(0), { shouldDirty: true });
    if (booleanState.ReadOnlyRemainBal) setValue(currentAmountName, currencyFormatter(0), { shouldDirty: true });
    if (booleanState.ReadOnlyRemainBal) setValue(exemptAmountName, currencyFormatter(0), { shouldDirty: true });
    if (booleanState.ReadOnlyDedAmount) setValue(dedAmountName, currencyFormatter(0), { shouldDirty: true });
  }

  const updateCategoryId = (id: number) => {
    onCategoryChange(id);
    if (id != 1) {
      setValue(routingNoName, '', { shouldDirty: true });
      setValue(bankAccountNoName, '', { shouldDirty: true });
    }
    updateBalanceValues();
  }

  const onHideModal = (apid?: number) => {
    setShowApVendorModal(false);
    const _apid = apid || deduction.apid;
    setValue(apidName, _apid);
  };

  const onFormClick = async () => {
    if (!onWatch) return;

    const formValues = getValues();
    const updatedDeduction = formValues.garnishmentItems.find((x: Deduction) => {return x.dedId === deduction.dedId;}) || deduction;
    onWatch(deduction.dedId || 0, updatedDeduction, true);
  };
  
  const updateGarnishmentField = (newVals: { [key: string]: any }) => {
    const cast = structuredClone(deduction) as Deduction;
    const updatedDed = new Deduction(
      cast.clientNo,
      cast.empNo,
      cast.protectedEmpNo,
      cast.dedId,
      cast.deductionType,
      { ...deduction, ...newVals },
    );
    
    onUpdateDeductionGarnishment?.(updatedDed);
  };
  
  return (
    <div
      className="d-flex flex-column"
      onChange={onFormClick}
    >
      <div className="d-flex flex-wrap">
        <input
          type="hidden"
          name={`garnishmentItems[${index}].dedId`}
          defaultValue={deduction.dedId ?? 0}
          ref={register({ valueAsNumber: true })}
        />
        <input
          type="hidden"
          name={`garnishmentItems[${index}].dedNo`}
          defaultValue={deduction.dedNo ?? 0}
          ref={register({ valueAsNumber: true })}
        />
        <SelectGrp
          {...fs.categoryId}
          {...getAccess(sectionAccess, 5, undefined, { readOnlyDefault: (deduction?.dedId ?? 0) > 0 })}
          addEmptyValue
          labelClass={(deduction?.dedId ?? 99999999) <= 0 ? `${styles['dm-required']}` : ''}
          selectClass={(deduction?.dedId ?? 0) < 1 ? styles['dm-form-control-highlight'] : ''}
          options={garnishmentCategoryOptions}
          errors={errors?.garnishmentItems?.[index]?.categoryId}
          defaultValue = {deduction?.categoryId ?? 0}
          setValue = {setValue}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            updateCategoryId(parseInt(e.target.value));
            updateGarnishmentField({ 'categoryId': e.target.value });
          }}
          ref={register({
            required: {
              value: (deduction?.dedId ?? 99999999) <= 0,
              message: 'Start Date is required',
            } })}
          required={(deduction?.dedId ?? 99999999) <= 0}
        />
        <SelectGrp
          {...fs.entityId}
          {...getAccess(sectionAccess, 5)}
          labelClass={`${styles['dm-required']}`}
          options={garnishmentEntityOpts}
          errors={errors?.garnishmentItems?.[index]?.entityId}
          defaultValue = {deduction.entityId}
          setValue = {setValue}
          onChange={onEntityId}
          addEmptyValue={true}
          ref={register({ required: 'Entity is required' })}
        />
        <InputGrp
          {...fs.caseId}
          {...getAccess(sectionAccess, 5)}
          labelClass={`${styles['dm-required']}`}
          errors={errors?.garnishmentItems?.[index]?.caseId}
          defaultValue = {deduction.caseId}
          ref={register({
            required: {
              value: true,
              message: 'Case description is required', 
            },
          })}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            updateGarnishmentField({ 'caseId': e.target.value });
          }}
        />
        <SelectGrp
          {...fs.fips}
          {...getAccess(sectionAccess, 5, undefined, { disabledDefault: disabledFIPS, disabledSameAsReadOnly: true })}
          errors={errors?.garnishmentItems?.[index]?.fips}
          defaultValue = {deduction.fips}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setValue(fipsName, e.target.value);
            updateGarnishmentField({ 'fips': e.target.value });
          }}
          options = {getFipsOptions(categoryId)}
          ref={register({
            required: {
              value: !disabledFIPS,
              message: 'Required for Friend of Court',
            },
            maxLength: {
              value: 7,
              message: 'Max length is 7 chars',
            },
          })}
        />
        <SelectGrp
          {...fs.supportPct}
          {...getAccess(sectionAccess, 5, undefined, { readOnlyDefault: booleanState.ReadOnlyPercent })}
          errors={errors?.garnishmentItems?.[index]?.supportPct}
          defaultValue = {deduction?.supportPct}
          ref={register}
          options={getPercentageOpts(categoryId, getSupportPercentOverride(entityId))}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            updateGarnishmentField({ 'supportPct': +e.target.value });
          }}
        />
        <SelectGrp
          {...fs.medicalIns}
          {...getAccess(sectionAccess, 5)}
          errors={errors?.garnishmentItems?.[index]?.medicalIns}
          defaultValue = {deduction?.medicalIns ?? 'N'}
          ref={register()}
          options={[
            {
              id: 'Y',
              description: 'Yes',
            },
            {
              id: 'N',
              description: 'No',
            },
          ]}
          disabled={booleanState.ReadOnlyMedIns || (isCheckCalculator && (deduction?.dedId ?? 0) >= 0)}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            updateGarnishmentField({ 'medicalIns': e.target.value });
          }}
        />
        <InputGrpCurrency
          {...fs.exemptAmount}
          {...getAccess(sectionAccess, 5)}
          errors={errors?.garnishmentItems?.[index]?.exemptAmount}
          defaultValue = {deduction?.exemptAmount}
          setValue={setValue}
          ref={register}
          disabled={booleanState.ReadOnlyExemptAmt ||
            (isCheckCalculator && (deduction?.dedId ?? 0) >= 0)}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            updateGarnishmentField({ 'exemptAmount': fromCurrency(e.target.value) });
          }}
        />
        <InputGrpCurrency
          {...fs.begAmount}
          {...getAccess(sectionAccess, 5, undefined, { readOnlyDefault: booleanState.ReadOnlyBeginBal })}
          errors={errors?.garnishmentItems?.[index]?.begAmount}
          required = {!booleanState.ReadOnlyBeginBal}
          defaultValue = {deduction.begAmount}
          setValue={setValue}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            updateGarnishmentField({ 'begAmount': fromCurrency(e.target.value) });
            const value = parseFloat(
              e.target.value.replace(/[^0-9.+-]/g, ''),
            );
            const formattedValue = currencyFormatter(value);
            setValue(beginAmountName, formattedValue);

            const cAmount = parseFloat(
              getValues(currentAmountName).replace(
                /[^0-9.+-]/g,
                '',
              ),
            );
            if (cAmount === 0 || value === 0)
              setValue(currentAmountName, formattedValue);
          }}
          ref={register()}
        />
        <InputGrpCurrency
          {...fs.currAmount}
          {...getAccess(sectionAccess, 5, undefined, { readOnlyDefault: booleanState.ReadOnlyRemainBal })}
          errors={errors?.garnishmentItems?.[index]?.currAmount}
          defaultValue = {deduction.currAmount}
          setValue={setValue}
          ref={register(CURRENCY_VALIDATION)}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            updateGarnishmentField({ 'currAmount': fromCurrency(e.target.value) });
          }}
        />
        <InputGrpDecimal
          {...fs.dedAmount}
          {...getAccess(sectionAccess, 5, undefined, { disabledSameAsReadOnly: true, disabledDefault: (isCheckCalculator && (deduction?.dedId ?? 0) >= 0) || booleanState.ReadOnlyDedAmount })}
          type={'text'}
          errors={errors?.garnishmentItems?.[index]?.dedAmount}
          labelClass={!booleanState.ReadOnlyDedAmount ? `${styles['dm-required']}` : ''}
          ref={register({ required: 'Amount is required' })}
          setValue={setValue}
          required = {(isCheckCalculator && (deduction?.dedId ?? 0) >= 0) || !booleanState.ReadOnlyDedAmount}
          strValue={formatDecimal(deduction?.dedAmount ?? 0)}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            e.target.value = formatDecimal(parseFloat(e?.target?.value)) as string;
            updateGarnishmentField({ 'dedAmount': fromCurrency(e.target.value) });
          }}
        />
      </div>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
      >
        <ControlDatePickerGrp
          {...fs.dateStart}
          {...getAccess(sectionAccess, 5)}
          errors={errors?.garnishmentItems?.[index]?.dateStart}
          value={deduction.dateStart}
          onChange={(startDate: Date) => onStartDateChange(startDate)}
          setValue={setValue}
          control={control}
          rules={register({
            required: {
              value: true,
              message: 'Start Date is required',
            },
          })}
        />
        <ControlDatePickerGrp
          {...fs.dateExpire}
          {...getAccess(sectionAccess, 5)}
          errors={errors?.garnishmentItems?.[index]?.dateExpire}
          value={deduction?.dateExpire || dateExpire}
          setValue = {setValue}
          rules={{
            validate: (value: Date) => { return validateExpireDate(value, new Date(getValues(dateStartName))); },
          }}
          control={control}
          onChange={(e: Date | null) => {
            updateGarnishmentField({ 'dateExpire': e });
          }}
        />
        <SelectGrp
          {...fs.stop}
          {...getAccess(sectionAccess, 5)}
          errors={errors?.garnishmentItems?.[index]?.status}
          defaultValue={deduction?.status ?? 'ACTIVE'}
          ref={register()}
          options={garnishmentOptions}
        />
        <div className="groupClass14" />
        <InputGrp
          {...fs.apid}
          {...getAccess(sectionAccess, 5, undefined, { readOnlyDefault: booleanState.ReadOnlyAP || (getValues(routingNoName) !== '' && getValues(bankAccountNoName) !== '' ) })}
          errors={errors?.garnishmentItems?.[index]?.apid}
          defaultValue = {apid}
          setValue = {setValue}
          ref={register()}
          control = {control}
          disabled = {booleanState.ReadOnlyAP || (getValues(routingNoName) !== '' && getValues(bankAccountNoName) !== '')}
          onClick={() => { return setShowApVendorModal(!booleanState.ReadOnlyAP); }}
        />
        <InputGrp
          {...fs.bankAcctNo}
          {...getAccess(sectionAccess, 5, undefined, { readOnlyDefault: booleanState.ReadOnlyAccountNo })}
          errors={errors?.garnishmentItems?.[index]?.bankAcctNo}
          defaultValue = {deduction.bankAcctNo}
          setValue = {setValue}
          ref={register({
            validate: (value: any) => {
              if (!getValues(routingNoName)) return true;
              const res = !!(value && getValues(routingNoName));
              return res;
            },
          })}
        />
        <InputGrp
          {...fs.routingNo}
          {...getAccess(sectionAccess, 5, undefined, { readOnlyDefault: booleanState.ReadOnlyRoutingNo })}
          errors={errors?.garnishmentItems?.[index]?.routingNo}
          defaultValue = {deduction.routingNo}
          setValue = {setValue}
          ref={register({
            validate: (value: any) => {
              const bankAcctNo = getValues(bankAccountNoName);
              if (!bankAcctNo) return true;
              const res = !!(value && bankAcctNo);
              return res;
            },
          })}
          type={'text'}
          maxLength={8}
        />
      </div>
      <div className="row">
        <div className="col-12 text-right">
          <button
            {...getAccess(sectionAccess, 5, undefined, { disabledSameAsReadOnly: true })}
            type="button"
            className="btn btn-link dm-grid-action-title mr-4"
            onClick={() => { return onDelete(deduction as Deduction); }}
          >
            Remove Garnishment{' '}
            <Icon
              name="minus-circle"
              className="fa-minus-circle"
            />
          </button>
        </div>
      </div>
      {showApVendorModal && (
        <APVendorsModal
          apid={deduction.apid || 0}
          categoryId={
            garnishmentCategoryApVendorOptions.find(
              (a) => { return a.garnishmentCategoryId === +categoryId; },
            )?.id ?? 0
          }
          entityId={+entityId}
          categoryOpts={garnishmentCategoryApVendorOptions}
          show={showApVendorModal}
          onHide={(apid?: number) => { return onHideModal(apid); }}
        />
      )}
    </div>
  );
};

export default EmpGarnishmentItem;
