import { createAction } from '@reduxjs/toolkit';
import { EmployeeToTransfer, Transfers } from '../../models';

export const loadEmployeeTransfer = createAction(
  '[employee] LOAD_EMPLOYEE_TRANSFER',
);
export const storeEmployeeTransfer = createAction<Transfers>(
  '[employee] STORE_EMPLOYEE_TRANSFER',
);
export const loadEmployeeTransferValidate = createAction<EmployeeToTransfer>(
  '[employee] LOAD_EMPLOYEE_TRANSFER_VALIDATE',
);
export const storeEmployeeTransferValidate = createAction<EmployeeToTransfer>(
  '[employee] STORE_EMPLOYEE_TRANSFER_VALIDATE',
);
export const executeEmployeeTransfer = createAction<EmployeeToTransfer>(
  '[employee] EXECUTE_EMPLOYEE_TRANSFER',
);
export const executeEmployeeTransferComplete = createAction(
  '[employee] EXECUTE_EMPLOYEE_TRANSFER_COMPLETE',
);
export const employeeTransferClearStatus = createAction(
  '[employee] EMPLOYEE_TRANSFER_CLEAR_STATUS',
);
export const employeeTransferSetErrorStatus = createAction(
  '[employee] EMPLOYEE_TRANSFER_SET_ERROR_STATUS',
);