import { convToClass, PropTypeLists } from '../../utilities/classUtils';
export class Employee {
  clientNo = 0;

  empNo = 0;

  protectedEmpNo = '';

  empId = 0;

  ssn = '';

  firstName = '';

  midName = '';

  lastName = '';

  suffix = '';

  address = '';

  city = '';

  state = '';

  zip = '';

  zip4 = '';

  country = '';

  loc: number | null = null;

  dept: number | null = null;

  subDept: number | null = null;

  subDept2: number | null = null;

  homePhone = '';

  cellPhone = '';

  email = '';

  email2 = '';

  hireDate: Date | string | null = null;

  termDate: Date | string | null = null;

  birthDate: Date | string | null = null;

  termCode = 0;

  eeo = '';

  exemptStatus = '';

  employeeStatus = '';

  isSupervisor = false;

  maritalStatus = 'S';

  sex = '';

  ssnisappliedFor = false;

  onboardingStatus = '';

  employeePhoto = '';

  photoPath = '';

  myInfoEmail = '';

  lastMyInfoLogin: Date | string | null = null;

  workPhone = '';

  workPhoneExt = '';

  payPeriod = '';

  nickName = '';

  preferredPronouns = '';

  hasNavigation = false;

  is1099Employee = false;

  ten99LookupId : number | null = null;
  
  taxpayerIdType : number | null = null;

  static readonly convProps: PropTypeLists = {
    dateProps: ['hireDate', 'termDate', 'birthDate', 'lastMyInfoLogin'],
    numberProps: [
      'clientNo',
      'empNo',
      'empId',
      'loc',
      'dept',
      'subDept',
      'subDept2',
      'termCode',
    ],
    //nullNumberProps: ['ten99LookupId', 'taxpayerIdType'],
    //boolProps: ['isSupervisor', 'ssnisappliedFor', 'is1099Employee', 'hasNavigation']
  };

  constructor(
    clientNo: string | number,
    empNo: string | number,
    props?: { [key: string]: any },
  ) {
    this.clientNo =
      typeof clientNo === 'string' ? parseInt(clientNo) : clientNo;
    this.empNo = typeof empNo === 'string' ? parseInt(empNo) : empNo;
    props && convToClass(this, props, Employee.convProps);
    props && props.ssn && (this.ssn = props.ssn.replace(/-/g, ''));
    props &&
      props.cellPhone &&
      (this.cellPhone = props.cellPhone.replace(/\D/g, ''));
    props &&
      props.homePhone &&
      (this.homePhone = props.homePhone.replace(/\D/g, ''));
    props &&
      props.workPhone &&
      (this.workPhone = props.workPhone.replace(/\D/g, ''));
  }
}

export interface ResendOnboardingEmailRequest {
  protectedEmpNo: string;
  empNo: number;
  clientNo: number;
  empId: number;
  emailAddress: string;
  isOnboardingEmailType: boolean;
}