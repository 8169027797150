import React, { CSSProperties, useEffect, useRef } from 'react';
import addPhoto2 from '../../../assets/images/addphoto2.png';

type PropTypes = {
  empPicData: string | undefined;
  empName: string | undefined;
  scaleFactor?: string;
  photoStyles?: CSSProperties;
};

const EmpPhoto: React.FC<PropTypes> = ({ empPicData, empName, scaleFactor = '85', photoStyles }) => {
  const uploadedImage = useRef<any>(null);

  useEffect(() => {
    uploadedImage.current.src = empPicData
      ? 'data:image/png;base64,' + empPicData
      : addPhoto2;
  }, [empPicData]);

  return (
    <>
      {(
        <img
          style={photoStyles}
          ref={uploadedImage}
          className="rounded-circle"
          alt={empName}
          width={scaleFactor}
          height={scaleFactor}
        />)}
    </>
  );
};

export default EmpPhoto;