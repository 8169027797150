import React from 'react';
import Icon from 'core/components/shared/Icon';
import { WorkFlowStatus, WorkFlowTaskList } from 'core/models/WorkFlow.model';
import { TableModel as Tm } from 'core/components/shared/table/types';

const colorScheme = {
  pending: 'gray',
  approved: '#4A9125',
  denied: '#D73C28',
  canceled: 'gray',
} as const;

const getStatusIcon = (status: WorkFlowStatus) => {
  switch (status) {
    case WorkFlowStatus.Approved:
      return (
        <Icon
          name="circle-check"
          color={colorScheme.approved}
          title="Approved"
        />
      );
    case WorkFlowStatus.Denied:
      return (
        <Icon
          name="circle-x"
          color={colorScheme.denied}
          title="Denied"
        />
      );
    case WorkFlowStatus.Pending:
      return (
        <Icon
          name="clock"
          color={colorScheme.pending}
          title="Pending"
        />
      );
    case WorkFlowStatus.Canceled:
      return (
        <Icon
          name="do-not-enter"
          color={colorScheme.canceled}
          title="Canceled"
        />
      );
  }
};

const defaultFilterModel: Tm.FilterModel<WorkFlowTaskList> = {
  startDate: {
    sortOrder: 'DESC',
    type: 'date',
    filterType: 'Not blank',
  },
};

export {
  colorScheme,
  getStatusIcon,
  defaultFilterModel,
};