import React, { useState, createContext, useEffect } from 'react';
import styles from './styles.module.scss';
import '../time-card-styles.scss';
import DiagnosticDetails from './DiagnosticDetails';
import { SelectGrp } from 'core/components/form-controls';
import { JobError, dummyErrorData } from './dummyData';
import { currencyFormatter } from 'utilities/utilities';
import Icon from 'core/components/shared/Icon';

export type DiagnosticContextType = {
  selectedError: string | null;
  showReviewed: boolean;
  expandAll: boolean;
  errors: JobError[];
  update: (newErrorState: JobError[]) => void;
};

export const DiagnosticContext = createContext<DiagnosticContextType>({
  selectedError: null,
  showReviewed: false,
  expandAll: false,
  errors: dummyErrorData,
  update: (_newErrorState: JobError[]) => { },
});

const errorSelectOpts = [
  { id: null, description: null },
  { id: 'error1', description: 'error1' },
  { id: 'error2', description: 'error2' },
  { id: 'error3', description: 'error3' },
  { id: 'Some other error', description: 'Some other error' },
];

const Diagnostics = () => {
  const [errorState, setErrorState] = useState<JobError[]>(dummyErrorData);
  const [selectedError, setSelectedError] = useState<string | null>(null);
  const [showReviewed, setShowReviewed] = useState<boolean>(false);
  const [expandAll, setExpandAll] = useState<boolean>(true);
  
  const diagnosticContextValue: DiagnosticContextType = {
    selectedError,
    showReviewed,
    expandAll,
    errors: errorState,
    update: (newErrorState: JobError[]) => { setErrorState(newErrorState); },
  };
  
  return (
    <main className={styles['main-content']}>
      <div className="d-flex align-items-end">
        <h1 className="dm-page-title mr-2">Diagnostics</h1>
        <div className={styles['action-bar']}>
          <div className="d-flex ml-5 align-items-center">
            <label
              htmlFor="errorSelect"
              className={styles['top-label-sm']}
            >Error Type</label>
            <SelectGrp
              name="errorSelect"
              groupClass="gc10 m-0 ml-2 mw150"
              options={errorSelectOpts}
              onChange={(e: any) => {
                setSelectedError(e.target.value);
                setErrorState(dummyErrorData?.filter((error) => e.target.value ? error.description === e.target.value : true));
              }}
            />
          </div>
          <div className="d-flex align-items-center ml-2">
            <label
              htmlFor="toggleShowReviewed"
              className={styles['top-label-sm']}
            >
              Show Reviewed Errors
            </label>
            <input
              className="ml-2"
              type="checkbox"
              name="toggleShowReviewed"
              checked={showReviewed}
              onChange={(e) => { setShowReviewed(e.target.checked); }}
            />
          </div>
          <button
            className="btn btn-link"
            onClick={() => { setExpandAll(!expandAll); }}
          >
            {expandAll ? 'Collapse' : 'Expand'} all&nbsp;<Icon name={expandAll ? 'chevron-circle-up' : 'chevron-circle-down'} />
          </button>
          <div className="head-section ml-auto">
            <strong>Totals</strong>
            <div><strong>Total hours:&nbsp;</strong>0.00</div>
            <div><strong>Regular:&nbsp;</strong>0.00</div>
            <div><strong>Time and a half:&nbsp;</strong>0.00</div>
            <div><strong>Double:&nbsp;</strong>0.00</div>
            <div><strong>Other hours:&nbsp;</strong>0.00</div>
            <div><strong>Ded. amount:&nbsp;</strong>{currencyFormatter(0)}</div>
            <div><strong>Other earnings:&nbsp;</strong>{currencyFormatter(0)}</div>
          </div>
        </div>
      </div>
      <hr className="dm-page-hr" />
      <div className={styles['page-body']}>
        <DiagnosticContext.Provider value={diagnosticContextValue}>
          <DiagnosticDetails />
        </DiagnosticContext.Provider>
      </div>
    </main>
  );
};

export default Diagnostics;