import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ControlDatePickerGrp, InputGrp, SelectModalGrp } from '../../../core/components/form-controls';
import DropdownOptionForm from '../../../core/components/form-controls/select-modal/DropdownOptionForm';
import { FieldInputSettings } from '../../../core/components/form-controls/types';
import { getGEDCDocCompCareerLevels, getGEDCDocCompContractVersion, getGEDCMultiSteps, getSelectedEmp } from '../../../core/store/selectors';
import { DocCompContract, EmpDocComp, Employee } from '../../../core/models';
import PanelHeader from '../../../core/components/shared/PanelHeader';
import { createEmployeeDocComp, updateEmployeeDocComp } from '../../../core/store/actions';
import HRDocComp from './HRDocComp';
import { DateTime } from 'luxon';
import { convToFormObject } from '../../../utilities/classUtils';

import Icon from 'core/components/shared/Icon';

const fs: FieldInputSettings = {
  effectiveDate: {
    name: 'effectiveDate',
    label: 'EFFECTIVE DATE',
    groupClass: 'gc10 mw125',
  },
  endDate: {
    name: 'endDate',
    label: 'STOP DATE',
    groupClass: 'gc10 mw125',
  },
  firstName: {
    name: 'firstName',
    label: 'FIRST NAME',
    groupClass: 'gc30 mw200',
  },
  lastName: {
    name: 'lastName',
    label: 'LAST NAME',
    groupClass: 'gc30 mw200',
  },
  contractVersionId: {
    name: 'contractVersionId',
    label: 'CONTRACT VERSION',
    groupClass: 'gc95 mw300',
    modalTitle: 'CONTRACT VERSION',
    formComponent: DropdownOptionForm,
    dropdownName: 'GEDCDocCompContractVersion',
    noOption: true,
    addOptionText: 'Contract Version',
  },
  careerLevelId: {
    name: 'careerLevelId',
    label: 'CAREER LEVEL',
    groupClass: 'gc95 mw300',
    modalTitle: 'CAREER LEVEL',
    formComponent: DropdownOptionForm,
    dropdownName: 'GEDCDocCompCareerLevel',
    noOption: true,
    addOptionText: 'Career Level',
  },
  multiStepId: {
    name: 'multiStepId',
    label: 'MULTI-STEP',
    groupClass: 'gc95 mw300',
    modalTitle: 'MULTI-STEP',
    formComponent: DropdownOptionForm,
    dropdownName: 'GEDCMultiStep',
    noOption: true,
    addOptionText: 'Multi-Step',
  },
};

type PropTypes = {
  initialDocComp: EmpDocComp;
  employeeDocComps: EmpDocComp[];
  onDelete: (item: EmpDocComp) => void;
};

const HRDocCompRecord: React.FC<PropTypes> = ({ initialDocComp, employeeDocComps, onDelete }) => {
  const dispatch = useDispatch();
  const { setValue, errors, register, handleSubmit, watch, control, reset } = useForm<any>();
  const {
    effectiveDate,
    endDate,
    contractVersionId,
    careerLevelId,
    multiStepId,
  } = watch(['effectiveDate', 'endDate', 'contractVersionId', 'careerLevelId', 'multiStepId']);
  const selEmp = useSelector(getSelectedEmp) as Employee;
  const careerLevelOpts = useSelector(getGEDCDocCompCareerLevels);
  const gEDCMultiStepsOpts = useSelector(getGEDCMultiSteps);
  const gEDCDocCompContractVersionOpts = useSelector(getGEDCDocCompContractVersion);
  const [selectedDocComp, setSelectedDocComp] = useState<EmpDocComp>(initialDocComp);
  const [ghostDocCompId, setGhostDocCompId] = useState<number>(-1);
  const [hasErrors, setHasErrors] = useState<boolean>(false);

  useEffect(() => {
    setSelectedDocComp(initialDocComp);
    initialDocComp && reset({ ...convToFormObject(initialDocComp, EmpDocComp.convProps) } as EmpDocComp);
  }, [initialDocComp]);


  const onAddDocComp = () => {
    const newDocCompContract: DocCompContract = {
      advanceAmendment: false,
      annualDues: 0,
      autoCalc: false,
      capPercentPerContract: 0,
      ceAnnual: 0,
      compensationIndex: 1,
      compensationType: 'C',
      deliveryEmail: '',
      deliveryMethod: 'E',
      docCompHeaderId: selectedDocComp.docCompHeaderId,
      docCompId: ghostDocCompId,
      dsoMembership: false,
      duesAnnual: 0,
      estimateCollectionPercent: 0,
      financeCharges: false,
      inVisalign: false,
      initialEmploymentTerm: 0,
      invisalignExpress: false,
      labsPaid: false,
      maximumCap: 0,
      negativeForgiveness: false,
      orientationPeriod: '',
      perDiem: 0,
      percentPerContract: 0,
      timeOffDays: 0,
      worksDaysPerWeek: 0,
    };
    const docComp = {
      ...selectedDocComp,
      contracts: selectedDocComp?.contracts?.concat(newDocCompContract),
    };
    setSelectedDocComp(docComp);
    setGhostDocCompId(ghostDocCompId - 1);
  };

  const onSave = (data: any) => {
    const docCompContracts: DocCompContract[] = selectedDocComp.contracts?.map((contract) => ({
      ...contract,
      docCompId: (contract.docCompId > 0) ? contract.docCompId : 0,
      capPercentPerContract: parseInt(contract.capPercentPerContract.toString())
    }));

    const empDoc = new EmpDocComp(selEmp.empId, selEmp.protectedEmpNo, docCompContracts, data);

    empDoc.docCompHeaderId = selectedDocComp.docCompHeaderId;
    selectedDocComp.docCompHeaderId ? dispatch(updateEmployeeDocComp(empDoc)) : dispatch(createEmployeeDocComp(empDoc));
    setHasErrors(false);
  };

  const btnSetSelectedDocComp = (props: any) => {
    setSelectedDocComp(props);
    props.effectiveDate ? setValue('effectiveDate', new Date(props?.effectiveDate)) : setValue('effectiveDate', null);
    props.endDate ? setValue('endDate', new Date(props?.endDate)) : setValue('endDate', null);
    setValue('firstName', props.firstName);
    setValue('lastName', props.lastName);
    setValue('contractVersionId', props.contractVersionId?.toString());
    setValue('careerLevelId', props.careerLevelId?.toString());
    setValue('multiStepId', props.multiStepId?.toString());
  };

  const onDeleteDocComp = (contract: any) => {
    const contracts = [...selectedDocComp?.contracts?.filter(c => { return c.docCompId !== contract.docCompId; })];
    const docComp = {
      ...selectedDocComp,
      contracts,
    };
    setSelectedDocComp(docComp);
  };

  const onDocCompChange = (contract: any) => {
    const index = selectedDocComp?.contracts?.findIndex(c => { return c.docCompId === contract.docCompId; });
    const contracts = [...selectedDocComp?.contracts];
    contracts[index] = contract;
    const docComp = {
      ...selectedDocComp,
      contracts,
    };
    setSelectedDocComp(docComp);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSave)} >
        <div className="d-flex flex-row flex-wrap mr-3">

          <div className="d-flex flex-column flex-wrap mr-3"
            style={{
              width: '15%',
              minWidth: '150px',
            }}>
            <label className="form-label">RECORD DATE</label>
            <div className="pt-2"
              style={{
                flex: '1',
                border: '1px solid #e3e3e3',
                backgroundColor: 'lightblue',
              }}>
              {employeeDocComps?.map((item: any, index: number) => {
                return (
                  <button
                    onClick={() => { return btnSetSelectedDocComp(item); }}
                    type="button"
                    key={index.toString()}
                    className="btn btn-link py-0"
                  >{DateTime.fromJSDate(new Date(item.effectiveDate)).toFormat('D')}
                  </button>
                );
              })}
            </div>
          </div>

          <div className="d-flex flex-column flex-wrap mr-3"
            style={{
              width: '15%',
              minWidth: '150px',
            }}>
            <ControlDatePickerGrp
              {...fs.effectiveDate}
              errors={errors.effectiveDate}
              value={effectiveDate}
              setValue={setValue}
              control={control} />
            <ControlDatePickerGrp
              {...fs.endDate}
              errors={errors.endDate}
              value={endDate}
              setValue={setValue}
              control={control} />
          </div>

          <div className="d-flex flex-column mt-4 mr-3"
            style={{
              width: '40%',
              minWidth: '450px',
            }}>
            <div className="font-weight-bold">Nickname for Warehouse Export</div>
            <div className="d-flex flex-row">
              <InputGrp {...fs.firstName}
                errors={errors.firstName}
                ref={register} />
              <InputGrp {...fs.lastName}
                errors={errors.lastName}
                ref={register} />
            </div>
          </div>

          <div className="d-flex flex-column"
            style={{
              width: '25%',
              minWidth: '200px',
            }}>
            <SelectModalGrp
              {...fs.contractVersionId}
              errors={errors.contractVersionId}
              control={control}
              value={contractVersionId?.toString()}
              setValue={setValue}
              options={gEDCDocCompContractVersionOpts} />
            <SelectModalGrp
              {...fs.careerLevelId}
              errors={errors.careerLevelId}
              control={control}
              value={careerLevelId?.toString()}
              setValue={setValue}
              options={careerLevelOpts} />
            <SelectModalGrp
              {...fs.multiStepId}
              errors={errors.multiStepId}
              control={control}
              value={multiStepId?.toString()}
              setValue={setValue}
              options={gEDCMultiStepsOpts} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right" >
            <button type="button"
              className="btn btn-link dm-grid-action-title mr-4"
              onClick={() => { return onDelete(selectedDocComp); }} >Delete this Doc Comp Record
              <Icon name="minus-circle"
                className="fa-minus-circle" />
            </button>
            <button type="submit"
              className="btn orange-button-sm mr-3">Save</button>
          </div>
        </div>
      </form>
      <div>
        <hr />
        <PanelHeader title="Doc Comp" >
          <button className="btn btn-link dm-grid-action-title p-0 mr-2"
            onClick={onAddDocComp}>
            Add New Doc Comp <Icon name="plus-circle"
              className="fa-plus-circle" />
          </button>
        </PanelHeader>
        <div>{selectedDocComp?.contracts?.map((contract) => {
          return <div key={contract.docCompId}
            className="dm-panel dm-panel-border" >
            <HRDocComp contract={contract}
              onDeleteCompChild={onDeleteDocComp}
              onFormChange={onDocCompChange}
              register={register}
              errors={errors}
              hasErrors={hasErrors}
              setHasErrors={setHasErrors}
              />
          </div>;
        })}</div>
      </div>
    </div>
  );
};

export default HRDocCompRecord;