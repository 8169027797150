import React from 'react';
import { useForm } from 'react-hook-form';
import { InputGrpInLine } from '..';
import { FieldInputSettings } from '../types';
import { Location } from '../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { getClient } from '../../../store/selectors';
import { createLocation, updateLocation } from '../../../store/actions';

const fs: FieldInputSettings = {
  locationCode: {
    name: 'locationCode',
    label: 'Location Code',
    labelWidth: 25,
    type: 'number',
    required: true,
  },
  locationDesc: {
    name: 'locationDesc',
    label: 'Description',
    labelWidth: 25,
    required: true,
  },
  glAccount: { name: 'glAccount', label: 'GL Account', labelWidth: 25 },
  quickenClass: {
    name: 'quickenClass',
    label: 'Quickbooks Class',
    labelWidth: 25,
  },
  alphaCode: { name: 'alphaCode', label: 'Alpha Code', labelWidth: 25 },
};

type PropTypes = {
  item: any;
  options: any[];
  onHide: any;
};

const LocationOptionForm: React.FC<PropTypes> = ({ item, options, onHide }) => {
  const dispatch = useDispatch();
  const currentClient = useSelector(getClient);

  const { register, errors, handleSubmit, setError, clearErrors } =
        useForm<Location>({
          defaultValues: item
            ? { ...item }
            : new Location(
              currentClient!.clientNo,
              currentClient!.clientID,
            ),
        });

  const onLocationCodeChange = (e: any) => {
    options.find((l: Location) => {return l.locationCode === +e.target.value;})
      ? setError('locationCode', {
        type: 'BadLocationCode',
        message: 'Location Code already exists.',
      })
      : clearErrors('locationCode');
  };

  const onSubmit = (data: any) => {
    const updatedLoc = { ...item, ...data } as Location;
    updatedLoc.locationCode = parseInt(data.locationCode);
    if (updatedLoc.locationID) {
      dispatch(updateLocation(updatedLoc));
    } else {
      updatedLoc.clientNo = currentClient!.clientNo;
      updatedLoc.clientID = currentClient!.clientID;
      updatedLoc.locationID = 0;
      dispatch(createLocation(updatedLoc));
    }
    onHide(false);
  };

  return (
    <div
      className="dm-panel dm-panel-border mx-4"
      style={{ display: 'flex', flexFlow: 'column nowrap' }}
    >
      <div className="dm-grid-action-title">Add/Edit</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGrpInLine
          {...fs.locationCode}
          errors={errors.locationCode}
          onChange={onLocationCodeChange}
          ref={register}
        />
        <InputGrpInLine
          {...fs.locationDesc}
          errors={errors.locationDesc}
          ref={register({ required: 'Description is Required' })}
        />
        <InputGrpInLine
          {...fs.glAccount}
          errors={errors.glAccount}
          ref={register}
        />
        <InputGrpInLine
          {...fs.quickenClass}
          errors={errors.quickenClass}
          ref={register}
        />
        <InputGrpInLine
          {...fs.alphaCode}
          errors={errors.alphaCode}
          ref={register}
        />

        <div style={{ display: 'flex' }}>
          <button
            type="button"
            className="btn btn-primary orange-outline-button ml-auto mr-2"
            onClick={() => {return onHide(false);}}
          >
            Cancel
          </button>
          <button type="submit" className="orange-button mr-2">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default LocationOptionForm;
