import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, ColDef, GridApi, GridOptions, GridReadyEvent, RowNode } from '@ag-grid-enterprise/all-modules';
import Icon from 'core/components/shared/Icon';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SelectGrp } from 'core/components/form-controls';
import { OnboardingUpdate, OnboardPayrollUser } from 'core/models';
import { handleError, loadOnboardPayrollUsers, updateOnboardUpdates } from 'core/store/actions';
import { getPayrollUsers, getSelectedEmp } from 'core/store/selectors';
import AGDeleteHeaderComponent from 'utilities/ag-grid-renderers/AGDeleteHeaderComponent';

const columns: ColDef[] = [
  {
    field: 'firstName',
    sortable: true,
    width: 120,
  },
  {
    field: 'lastName',
    sortable: true,
    width: 140,
  },
  {
    field: 'emailAddress',
    headerName: 'EMAIL',
    sortable: true,
  },
  {
    field: 'select',
    headerComponentFramework: AGDeleteHeaderComponent,
    checkboxSelection: true,
    cellStyle: { paddingLeft: '14px' },
    width: 45,
  },
];

const gridOptions: GridOptions = {
  columnDefs: columns,
  rowSelection: 'multiple',
  defaultColDef: {
    suppressMenu: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
};

type PropTypes = {
  show: boolean;
  onHide: () => void;
};

const OnboardEmailsModal: React.FC<PropTypes> = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [selectedUserName, setSelectedUserName] = useState('');
  const [employees, setEmployees] = useState<OnboardPayrollUser[]>([]);

  const payrollUsersOpts = useSelector(getPayrollUsers);
  const currentEmp = useSelector(getSelectedEmp);

  useEffect(() => {
    dispatch(loadOnboardPayrollUsers());
  }, []);
  
  const filteredUserOpts = payrollUsersOpts?.filter((x) => x.emailAddress && !employees.map((y) => y.dmUserName).includes(x.dmUserName)) ?? [];

  const onGridReady = (e: GridReadyEvent) => {
    setGridApi(e.api);
  };

  const onSelectEmployee = () => {
    if (!selectedUserName) return dispatch(handleError('Invalid employee username'));
    
    const selectedEmployee = payrollUsersOpts.find((emp: OnboardPayrollUser) => { return emp.dmUserName === selectedUserName; });
    if (!selectedEmployee) {
      dispatch(handleError('Error selecting employee'));
      console.error(`Employee with username ${selectedUserName} not found in payrollUsersOpts:`, payrollUsersOpts);
      return;
    }
    
    setEmployees((prev) => { return [...prev, selectedEmployee]; });
    setSelectedUserName('');
  };

  const onDeleteSelected = () => {
    if (!gridApi) {
      dispatch(handleError('Error deleting selected users'));
      console.error('gridApi not properly set in OnboardEmails modal');
      return;
    }
    
    const selectedNodes: RowNode[] = gridApi.getSelectedNodes();
    setEmployees(prev => {
      return prev.filter(e1 => { return !selectedNodes.find((node: RowNode) => { return +node.data.secUserID === +e1.secUserID; }); });
    });
  };
  
  const closeModal = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.stopPropagation();
    onHide();
  };

  const onSave = () => {
    if (!gridApi) {
      dispatch(handleError('Error saving users'));
      console.error('gridApi not properly set in OnboardEmails modal');
      return;
    }
    
    const updates: OnboardingUpdate[] = [];
    gridApi.forEachNode((node: RowNode) => {

      updates.push({
        empID: currentEmp ? currentEmp.empId : 0,
        secUserID: node.data.secUserID,
        email: node.data.emailAddress,
        name: node.data.clientEntityName,
      });
    });
    dispatch(updateOnboardUpdates(updates));
    closeModal();
    history.push(`/employee/detail/${currentEmp?.protectedEmpNo}/snapshot`);
  };

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        size="sm"
        animation={false}
      >
        <div className="modal-header">
          <div className="dm-card-title">Onboarding Emails</div>
          <button
            type="button"
            className="modal-close-btn"
            onClick={closeModal}
          >
            <Icon name="times" />
          </button>
        </div>
        <Modal.Body>
          <p>If there are any current employees that wish to receive &lsquo;Onboarding Status&rsquo; updates via email you can add them below.</p>
          <p>These update emails will be sent when the onboarding employee starts their onboarding process and when they complete their process.</p>
          <p>If you do not wish to send any &lsquo;Onboarding Status&rsquo; emails, simply click the cancel button.</p>
          <div className="row">
            <div className="col-8">
              <SelectGrp
                name="employee"
                label="Add Employee"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setSelectedUserName(e.target.value); }}
                options={[new OnboardPayrollUser(), ...filteredUserOpts]}
                valueField="dmUserName"
                labelField={['firstName', 'lastName']}
              />
            </div>
            <div className="col-4 pt-2">
              <button
                type="button"
                className="btn btn-primary orange-outline-button-sm mt-2"
                onClick={onSelectEmployee}
                disabled={selectedUserName === ''}
              >Add Employee</button>
            </div>
          </div>

          <hr className="hr-2 mt-1 mb-2" />
          <div className="dm-card-subtitle pb-1">Employees to receive emails</div>

          <div className="row">
            <div
              className="col-12 table-wrapper-wrapper ag-theme-balham"
              style={{ height: '150px' }}
            >
              <AgGridReact
                gridOptions={gridOptions}
                rowData={employees}
                modules={AllModules}
                onGridReady={onGridReady}
              />
            </div>
            <div className="col-12 text-right">
              <button
                type="button"
                className="btn btn-link"
                onClick={onDeleteSelected}
              >Remove Selected</button>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-right">
              <button
                type="button"
                className="btn btn-primary orange-outline-button"
                onClick={closeModal}
              >Cancel</button>
              <button
                type="button"
                className="btn btn-primary orange-button ml-2"
                onClick={onSave}
              >Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div >
  );
};
export default OnboardEmailsModal;
