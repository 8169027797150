import { AgGridReact } from '@ag-grid-community/react';
import {
  ColDef,
  GetDetailRowDataParams,
  GridOptions,
  IsRowMaster,
  MasterDetailModule,
  ValueGetterParams,
} from '@ag-grid-enterprise/all-modules';
import { CheckboxGrpInLine } from 'core/components/form-controls';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import Icon from 'core/components/shared/Icon';
import { RateMaster, RateMasterRequest } from 'core/models/ContractorReports.model';
import { CommonService } from 'core/services';
import {
  clearRateMasterReport,
  downloadRateMasterReport,
  getRateMaster,
} from 'core/store/slices/contractorReports.slice';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { agDateRenderer } from 'utilities/ag-grid-renderers';
import CheckboxRenderer from 'utilities/ag-grid-renderers/CheckboxRenderer';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { currencyFormatter } from 'utilities/utilities';

const RateMasterReport = () => {
  const dispatch = useAppDispatch();
  const childRef: React.RefObject<any> = useRef();
  const report = useAppSelector((state) => { return state.contractorReports.rateMasterReport; });
  const rateMaster = useAppSelector((state) => { return state.contractorReports.rateMaster; });
  const client = useAppSelector((state) => { return state.client.client; });
  const [rowData, setRowData] = useState<RateMaster[]>([]);

  const { register, handleSubmit, getValues } = useForm<RateMasterRequest>({
    defaultValues: {
      onlyCurrent: true,
      includeFringes: true,
      onlyActive: false,
    },
  });

  const [fileType, setFileType] = useState<string>('pdf');

  const isRowMaster = useMemo<IsRowMaster>(() => {
    return (dataItem: any) => {
      return dataItem ? dataItem.details.length > 0 : false;
    };
  }, []);

  useEffect(() => {
    onDispatchRateMaster();
  }, []);

  useEffect(() => {
    if (rateMaster?.length) {
      setRowData(rateMaster);
    }
  }, [rateMaster]);

  useEffect(() => {
    if (report && fileType === 'excel') {
      CommonService.downloadBase64File(
        'application/excel',
        report,
        'RateMasterReport.xlsx',
      );
      dispatch(clearRateMasterReport());
    }
  }, [report]);

  const onDispatchRateMaster = () => {
    const data = getValues();
    const request: RateMasterRequest = {
      clientNo: client?.clientNo!,
      onlyCurrent: data.onlyCurrent ?? true,
      includeFringes: data.includeFringes ?? true,
      onlyActive: data.onlyActive ?? false,
    };
    dispatch(getRateMaster(request));
  };

  const onSubmit = (data: RateMasterRequest) => {
    const submitData: RateMasterRequest = {
      clientNo: client?.clientNo!,
      onlyCurrent: data.onlyCurrent ?? true,
      includeFringes: data.includeFringes ?? true,
      onlyActive: data.onlyActive ?? false,
      reportType: fileType,
    };
    dispatch(downloadRateMasterReport(submitData));

  };

  const hidePdfViewer = () => {
    dispatch(clearRateMasterReport());
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const columns: ColDef[] = useMemo(
    () => {
      return [
        {
          field: 'effectiveDate',
          headerName: '',
          cellRenderer: 'agGroupCellRenderer',
          flex: 0,
          width: 40,
        },
        {
          field: 'effectiveDate',
          headerName: 'Effective Date',
          cellRenderer: 'dateRenderer',
        },
        {
          field: 'area',
          headerName: 'Area',
        },
        {
          field: 'areaDescription',
          headerName: 'Area Description',
        },
        {
          field: 'trade',
          headerName: 'Trade',
        },
        {
          field: 'tradeDescription',
          headerName: 'Trade Description',
        },
        {
          field: 'sub',
          headerName: 'Sub',
        },
        {
          field: 'subDescription',
          headerName: 'Sub Description',
        },
        {
          field: 'baseRate',
          headerName: 'Base Rate',
          cellStyle: { 'textAlign': 'right' },
          valueGetter: (params: ValueGetterParams) => {
            return currencyFormatter(params.data.baseRate);
          },
        },
      ];
    },
    []);

  const gridOptions: GridOptions = {
    columnDefs: columns,
    defaultColDef: {
      flex: 1,
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
      editable: false,
    },
    pagination: true,
    paginationPageSize: 10,
    detailCellRendererParams: {
      detailGridOptions: {
        defaultColDef: {
          flex: 1,
          suppressMenu: true,
          cellClass: 'ag-cell-left-border',
          headerClass: 'grid-header',
          editable: false,
        },
        columnDefs: [
          {
            field: 'typeDescription',
            label: 'Type Description',
            width: 100,
          },
          {
            field: 'description',
            label: 'Description',
            width: 100,
          },
          {
            field: 'rate',
            label: 'Rate',
            width: 100,
            cellStyle: { 'textAlign': 'right' },
            valueGetter: (params: ValueGetterParams) => {
              return currencyFormatter(params.data.rate);
            },
          },
          {
            field: 'paidByEmployer',
            label: 'Paid by Employer',
            width: 100,
            cellRenderer: 'checkBoxRenderer',
          },
          {
            field: 'formulaDescription',
            label: 'Formula Description',
            width: 100,
          },
        ],
        components: {
          checkBoxRenderer: CheckboxRenderer,
        },
      },
      getDetailRowData: (params: GetDetailRowDataParams) => {
        params.successCallback(params.data.details);
      },
    },
    components: {
      dateRenderer: agDateRenderer,
    },
  };

  return (

    <div className="dm-panel dm-panel-border">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="dm-grid-title">Rate Master Report</div>
        <div className="w-100 text-right">
          <button
            type="submit"
            className="btn btn-link dm-grid-action-title "
            onClick={() => { return setFileType('excel'); }}
          >
            Download Report{' '}
            <Icon name="download"
              className="fa-download" />
          </button>
        </div>
        <hr className="dm-panel-hr" />
        <div className="d-flex flex-row flex-grow-1">
          <div className="d-flex flex-row flex-grow-1">
            <button
              type="button"
              className="btn btn-link dm-grid-action-title mr-4 mb-10"
              onClick={onDispatchRateMaster}
              style={{paddingBottom:'15px'}}
            >
              Load Data
            </button>
            <CheckboxGrpInLine
              className="dm-grid-action-title ml-4"
              name="onlyCurrent"
              label="Show only current rates"
              ref={register()}
            />
            <CheckboxGrpInLine
              className="dm-grid-action-title ml-4"
              name="includeFringes"
              label="Show fringe information"
              ref={register()}
            />
            <CheckboxGrpInLine
              className="dm-grid-action-title ml-4"
              name="onlyActive"
              label="Only show Active Area-Trade-Subs"
              ref={register()}
            />
          </div>
          <div className="text-right">
            <button
              type="button"
              className="orange-outline-button mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="orange-button mr-2"
              onClick={() => { return setFileType('pdf'); }}
            >
              Run Report
            </button>
          </div>
        </div>
        <div className="flex-grow-1"
          style={{ marginTop: 20 }}>
          <div className="ag-theme-balham mt-2">
            <div
              className="ag-theme-balham-wrap"
              style={{ height: '400px' }}
            >
              <AgGridReact
                modules={[MasterDetailModule]}
                gridOptions={gridOptions}
                masterDetail={true}
                rowData={rowData}
                isRowMaster={isRowMaster}
              />
            </div>
          </div>
        </div>
        {
          report ? <PDFViewerModal
            show={report.length > 0}
            pdfData={report}
            onHide={hidePdfViewer}
            reportName="RateMasterReport"
          /> : null
        }
      </form>
    </div >
  );
};

export default RateMasterReport;

