import React, { useEffect, useMemo, useState } from 'react';
import { Modal as BootstrapModal, Tab, Tabs } from 'react-bootstrap';
import Modal from 'core/components/modals/Modal';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Dropdown,
  Employee,
  PayrollAdjustmentEmployee,
  PayrollAdjustmentSummary,
  PayrollAdjustmentDeduction,
  PayrollAdjustmentEarning,
  PayrollAdjustmentType,
  PayrollAdjustmentCheckRequest,
  PayrollAdjustmentTransaction,
  NegativeNetPayDecision,
} from 'core/models';
import {
  breakValidationChain,
  clearPrintAdjustmentCheck,
  clearValidatedCheck,
  createAdjustmentType,
  createPayrollAdjustmentCheck,
  deleteBlankCheck,
  loadPrintAdjustmentCheck,
  payrollAdjustmentCheckValidate,
  setCloseModal,
  updatePayrollAdjustmentCheck,
} from 'core/store/actions';
import { cloneDeep, merge, sum } from 'lodash';
import { getPayrollAdjustments } from 'core/store/selectors/payroll-adjustment.selector';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import VoidSearchModal from '../../../../core/components/modals/VoidSearch.modal';
import PdfViewer from 'core/components/shared/PDFViewer/PdfViewer';
import ModalEmpDetails from '../../shared/ModalEmpDetails';
import ModalHeader from '../../shared/ModalHeader';
import AdjustmentCheckItem from './AdjustmentCheckItem';
import { getIsFinishedPayroll } from 'core/store/selectors';
import ConfirmationModal from 'core/components/modals/confirmation.modal';

type PropTypes = {
  employee: Employee;
  employees: Employee[];
  newAdjustmentCheck: PayrollAdjustmentSummary | null;
  adjustmentCodeOpts: Dropdown[];
  show: boolean;
  payrollHistoryId: number | undefined;
  onHide: () => void;
};

type FormType = {
  items: PayrollAdjustmentSummary[];
};

const negativeNetPayErrMsg = 'The Net Pay amount is negative. Do you wish to move this negative amount to Federal W/H?';
enum ErrorModalButtons {
  StandardYesNoButtons = 0,
  BlockingErrorYesButton = 1, 
  NegativeNetPayYesNoButtons = 2,
}

const AdjustmentDetailModal = ({
  employee,
  employees,
  newAdjustmentCheck,
  adjustmentCodeOpts,
  payrollHistoryId,
  show,
  onHide,
}: PropTypes) => {
  const [currentEmployee, setCurrentEmployee] = useState<Employee>(employee);
  const [voidType, setVoidType] = useState<string>('');
  const [action, setAction] = useState<string>('');
  const [tabKey, setTabKey] = useState(0);
  const [checkIndex, setCheckIndex] = useState(0);
  const [showVoidSearchModal, setShowVoidSearchModal] = useState(false);
  const [showPrintAdjustedCheckModal, setShowPrintAdjustedCheckModal] = useState(false);
  const [showValidateErrorsModal, setShowValidateErrorsModal] = useState(false);
  const [errorModalButtons, setErrorModalButtons] = useState(ErrorModalButtons.StandardYesNoButtons);
  const isFinishedPayroll =  useSelector(getIsFinishedPayroll);

  const dispatch = useAppDispatch();

  const payrollAdjustments = useSelector(getPayrollAdjustments) as PayrollAdjustmentEmployee[];
  const employeePayrollAdjustment = useAppSelector((state) => {
    return state.payrollAdjustment.payrollAdjustments.find(
      (a) => {return a.empNo === currentEmployee.empNo;},
    );
  },
  ) as PayrollAdjustmentEmployee;
  const {
    validatedCheck,
    originalAdjustment,
    printAdjustmentCheck,
    validateCheckErrors,
    breakValidationChain: shouldBreakValidationChain,
    closeModal: shouldCloseModal,
  } = useAppSelector(({ payrollAdjustment }) => payrollAdjustment);

  const [employeeAdjustment, setEmployeeAdjustment] = useState<PayrollAdjustmentEmployee | null>(employeePayrollAdjustment);

  const {
    control,
    reset,
    handleSubmit,
    register,
    errors,
    formState,
    setValue,
    getValues,
    watch,
  } = useForm<FormType>({
    defaultValues: {
      items: useMemo(
        () => {return employeePayrollAdjustment?.adjustmentChecks || [];},
        [currentEmployee, employeePayrollAdjustment],
      ),
    },
  });
  
  const { isDirty } = formState;
  const { fields } = useFieldArray<PayrollAdjustmentSummary>({
    control,
    name: 'items',
  });
  const value = useWatch<PayrollAdjustmentSummary[]>({
    control,
    name: 'items',
  });
  
  useEffect(() => {
    setCurrentEmployee(employee);
    
    return () => {
      dispatch(clearValidatedCheck());
      dispatch(clearPrintAdjustmentCheck());
      dispatch(breakValidationChain(false));
    };
  }, []);
  
  useEffect(() => {
    if (!shouldCloseModal) return;
    closeModal();
  }, [shouldCloseModal]);

  useEffect(() => {
    if (employeePayrollAdjustment && employeePayrollAdjustment.empNo === currentEmployee.empNo) {
      reset({
        items: cloneDeep(employeePayrollAdjustment?.adjustmentChecks),
      },
      {
        dirtyFields: false,
        isDirty: false,
      },
      );
      if (
        employeePayrollAdjustment.adjustmentChecks[
          employeePayrollAdjustment?.adjustmentChecks?.length - 1
        ]
      ) {
        setTabKey(
          employeePayrollAdjustment.adjustmentChecks[
            employeePayrollAdjustment?.adjustmentChecks?.length - 1
          ]?.adjustmentId,
        );
      }
    }
  }, [employeePayrollAdjustment]);

  useEffect(() => {
    if (employeeAdjustment) {
      reset(
        { items: cloneDeep(employeeAdjustment?.adjustmentChecks) },
        { dirtyFields: false,
          isDirty: false },
      );
      setAction('');
      
      const checks = employeeAdjustment.adjustmentChecks;
      const newBlankCheck = checks?.find((check) => check.adjustmentId === 0);
      
      if (newBlankCheck) {
        setTabKey(checks?.[checks?.length - 1]?.adjustmentId);    
      } else {
        setTabKey(employeeAdjustment?.adjustmentChecks[0]?.adjustmentId ?? 0);
      }
    }
  }, [employeeAdjustment]);

  //Was updated in PI-8033 to handle NULL validateCheck for check calc errors that occur during validation
  useEffect(() => { 
    if (shouldBreakValidationChain) return;
    
    if (validatedCheck && !(validateCheckErrors && validateCheckErrors.length > 0)) {
      createOrUpdatePayrollAdjustment(validatedCheck);
      return;
    }

    if (validateCheckErrors && validateCheckErrors.length > 0) {

      //If validatedCheck is NULL and the validateCheckErrors contains negativeNetPayErrMsg, show the negative net pay Yes/No buttons; PI-8069
      if (!validatedCheck && validateCheckErrors.indexOf(negativeNetPayErrMsg) != -1) setErrorModalButtons(ErrorModalButtons.NegativeNetPayYesNoButtons);
      //If validatedCheck is NOT NULL and the validatedCheckErrors does not contain negativeNetPayErrMsg, show the simple Yes button; PI-8033
      else if (!validatedCheck && validateCheckErrors.indexOf(negativeNetPayErrMsg) == -1) setErrorModalButtons(ErrorModalButtons.BlockingErrorYesButton);
      //Else just show standard "Do you want to apply the calculation result" Yes/No buttons; 
      else setErrorModalButtons(ErrorModalButtons.StandardYesNoButtons);

      setShowValidateErrorsModal(true);
    }
    
  }, [validatedCheck, validateCheckErrors]);

  useEffect(() => {
    setAction('tab');
  }, [tabKey]);

  useEffect(() => {
    if (printAdjustmentCheck.length > 0) {
      setShowPrintAdjustedCheckModal(true);
    }
  }, [printAdjustmentCheck]);

  const disableAdd =
        employeePayrollAdjustment?.adjustmentChecks?.find(
          (c) => {return c.checkNo === 0;},
        ) || 0;

  const createOrUpdatePayrollAdjustment = (adjustment: PayrollAdjustmentSummary) => {
    if (!payrollHistoryId) return;
    if (adjustment.adjustmentId > 0) {
      dispatch(
        updatePayrollAdjustmentCheck({
          adjustmentId: adjustment!.adjustmentId,
          data: adjustment!,
          payrollHistoryId:
                        employeeAdjustment?.payrollHistoryId ??
                        payrollHistoryId,
          protectedEmpNo: getProtectedEmpNo(),
        }),
      );
    } else {
      const request:PayrollAdjustmentCheckRequest = {
        data: adjustment,
        payrollHistoryId:  employeeAdjustment?.payrollHistoryId ??
        payrollHistoryId,
        protectedEmpNo: getProtectedEmpNo(),
        adjustmentId: 0,
      };
      dispatch(
        createPayrollAdjustmentCheck(request),
      );
    }
    dispatch(clearValidatedCheck());
    dispatch(breakValidationChain(false));
  };

  const totalAmount = (check: PayrollAdjustmentSummary) => {
    if(!check.earnings) return;
    const item = {
      ...(value?.find(
        (a: PayrollAdjustmentSummary) => {return +(a.adjustmentId || 0) === check.adjustmentId;},
      ) as PayrollAdjustmentSummary),
    };
    return getTotalAmount(item);
  };

  const totalDeduction = (check: PayrollAdjustmentSummary) => {
    if(!check.deductions) return;
    const item = {
      ...(value?.find(
        (a: PayrollAdjustmentSummary) => {return +(a.adjustmentId || 0) === check.adjustmentId;},
      ) as PayrollAdjustmentSummary),
    };
    return getTotalDeductionAmount(item);
  };

  const getProtectedEmpNo = () => {
    const employee = employees.find(
      (e) => {return e.empNo === (employeeAdjustment?.empNo ?? currentEmployee.empNo);},
    ) as Employee;
    return employee.protectedEmpNo;
  };

  const buildValidateData = (checkIndex: number, formCheck: PayrollAdjustmentSummary | undefined): PayrollAdjustmentSummary => {
    const currentData = cloneDeep(employeePayrollAdjustment.adjustmentChecks?.[checkIndex]);

    const newData = merge(currentData, formCheck);
    newData.earnings = formCheck?.earnings ?? [];
    newData.deductions = formCheck?.deductions ?? [];
    newData.localWithholdings = formCheck?.localWithholdings ?? [];
    newData.stateWithholdings = formCheck?.stateWithholdings?.map((withholding) => ({
      ...withholding,
      addtlWithholdings: withholding?.addtlWithholdings?.map((addtl) => ({ ...addtl, categoryId: +addtl.categoryId })),
    })) ?? [];

    return newData;
  };

  const onSave = (): boolean => {
    if (!isDirty || isFinishedPayroll) return true;
    const checkIndex = value?.findIndex((a) => {return a.adjustmentId === tabKey;}) ?? 0;
    const formCheck = value?.[checkIndex];
    const newData = buildValidateData(checkIndex, formCheck);
    
    if (formCheck && payrollHistoryId) {
      setCheckIndex(checkIndex);
      const request:PayrollAdjustmentCheckRequest = {
        data: newData,
        payrollHistoryId: payrollHistoryId,
        protectedEmpNo: getProtectedEmpNo(),
        adjustmentId: 0,
      };
      dispatch(payrollAdjustmentCheckValidate(request));
    }
    return false;
  };

  //PI-8069: I tried to make it so we could front load this logic and not have to ask the user but......alas. 
  const handleNegativeNetPayDecision = (applyToFedWH: boolean) => {
    if (isFinishedPayroll) return;

    const checkIndex = value?.findIndex((a) => {return a.adjustmentId === tabKey;}) ?? 0;
    const formCheck = value?.[checkIndex];
    const validateData = buildValidateData(checkIndex, formCheck);
    if (!validateData || !originalAdjustment) return;

    //Set this so the backend actually handles negative netpay for the adjustment; 
    validateData.negativeNetPayDecision = (applyToFedWH) ? NegativeNetPayDecision.MoveToFederalWH : NegativeNetPayDecision.CreateNegativeDeduction;    

    if (formCheck && payrollHistoryId) {
      setCheckIndex(checkIndex);
      const request:PayrollAdjustmentCheckRequest = {
        data: validateData,
        payrollHistoryId: payrollHistoryId,
        protectedEmpNo: getProtectedEmpNo(),
        adjustmentId: 0,
      };
      dispatch(payrollAdjustmentCheckValidate(request));
    }
  };

  const onAddAdjustment = () => {
    if (newAdjustmentCheck) {
      dispatch(
        createAdjustmentType({
          empNo: currentEmployee!.empNo,
          blankCheck: newAdjustmentCheck,
        }),
      );
      setTabKey(newAdjustmentCheck.adjustmentId);
    }
  };

  const onPrint = (adjustmentId: number) => {
    dispatch(
      loadPrintAdjustmentCheck({
        adjustmentId,
        payrollHistoryId: employeeAdjustment?.payrollHistoryId || 0,
        protectedEmpNo: employeeAdjustment?.protectedEmpNo || '',
      }),
    );
  };

  const getTotalAmount = (check: PayrollAdjustmentSummary) => {
    const amounts: number[] = [];
    check?.earnings?.forEach((e: PayrollAdjustmentEarning) => {
      if (e.earnings) {
        const amount = +e.earnings;
        amounts.push(amount);
      }
    });

    return sum(amounts).toFixed(2);
  };

  const getTotalDeductionAmount = (check: PayrollAdjustmentSummary) => {
    const amounts: number[] = [];
    check?.deductions?.forEach((e: PayrollAdjustmentDeduction) => {
      if (e.amount) {
        const amount = +e.amount;
        amounts.push(amount);
      }
    });
    return sum(amounts).toFixed(2);
  };

  const closeModal = (e?: any) => {
    e?.stopPropagation();

    if (fields.length !== 0) {
      dispatch(deleteBlankCheck(currentEmployee?.empNo ?? 0));
    }
    onHide();
    dispatch(deleteBlankCheck(currentEmployee?.empNo ?? 0));
    dispatch(setCloseModal(false));
  };

  const moveEmp = (whichWay: string) => {
    if (employeeAdjustment && payrollAdjustments) {
      const currentIndex = payrollAdjustments.findIndex(
        (a) => {return a.empNo === employeeAdjustment.empNo;},
      );
      let tEmp: PayrollAdjustmentEmployee;
      if (whichWay === 'prev') {
        tEmp = payrollAdjustments[currentIndex - 1];
      } else {
        tEmp = payrollAdjustments[currentIndex + 1];
      }
      tEmp && setEmployeeAdjustment(tEmp);
      const emp = employees?.find((e) => {return e.empNo === tEmp?.empNo;});
      emp && setCurrentEmployee(emp);
    } else {
      setEmployeeAdjustment(payrollAdjustments[0]);
    }
  };

  const getPrevEmp = () => {
    setAction('prev');
    if (!onSave()) {
      return;
    }
    moveEmp('prev');
  };

  const getNextEmp = () => {
    setAction('next');
    if (!onSave()) {
      return;
    }
    moveEmp('next');
  };

  const onAdjustmentTypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    if (e.target.value.indexOf('Void') > -1) {
      setVoidType(e.target.value);
      setShowVoidSearchModal(true);
    }
  };

  const hidePrintAdjustedCheckModal = () => {
    setShowPrintAdjustedCheckModal(false);
    dispatch(clearPrintAdjustmentCheck());
  };

  const postFix = (adjustmentType: PayrollAdjustmentType) => {
    switch (adjustmentType) {
      case PayrollAdjustmentType.VoidCash:
      case PayrollAdjustmentType.VoidCorrection:
        return 'V';
      case PayrollAdjustmentType.ThirdPartySick:
        return 'S';
      case PayrollAdjustmentType.PrePaid:
        return 'PP';
    }
  };
  
  const onConfirmed = (confirmed: boolean) => {
    if (!validatedCheck) return console.error('No validated check stored from response');
    if (!confirmed) {
      if (originalAdjustment) {
        // createOrUpdatePayrollAdjustment(originalAdjustment); //PI-8069: If user selects "No", do not save; 
        if (action.length > 0 && action !== 'tab') {
          moveEmp(action);
        }
      } else if (action === 'tab') {
        const adjustmentCheck = employeePayrollAdjustment?.adjustmentChecks?.[checkIndex];
        setTabKey(adjustmentCheck.adjustmentId);
      }
    } else {
      createOrUpdatePayrollAdjustment(validatedCheck);
      if (action.length > 0 && action !== 'tab') {
        moveEmp(action);
      }
    }
  };


  const StandardYesNoButtons = (props: any) => {
    return (
      <div className="d-flex flex-row">
        <button 
          type="button" 
          className="btn btn-primary orange-outline-button mr-2" 
          onClick={() => {
            onConfirmed(false);
            setShowValidateErrorsModal(false);
          }}
        >
          No
        </button>
        <button
          type="button"
          className="btn btn-primary orange-button"
          onClick={() => {
            onConfirmed(true);
            setShowValidateErrorsModal(false);
          }}
        >
          Yes
        </button>
      </div>
    );
  };

  const NegativeNetPayYesNoButtons = (props: any) => {
    return (
      <div className="d-flex flex-row">
        <button 
          type="button" 
          className="btn btn-primary orange-outline-button mr-2" 
          onClick={() => {
            handleNegativeNetPayDecision(false);
            setShowValidateErrorsModal(false);
          }}
        >
          No
        </button>
        <button
          type="button"
          className="btn btn-primary orange-button"
          onClick={() => {
            handleNegativeNetPayDecision(true);
            setShowValidateErrorsModal(false);
          }}
        >
          Yes
        </button>
      </div>
    );
  };

  const BlockingErrorYesButton = (props: any) => {
    return (
      <div className="d-flex flex-row">
        <button
          type="button"
          className="btn btn-primary orange-button"
          onClick={() => { setShowValidateErrorsModal(false); }}
        >
          Ok
        </button>
      </div>
    );
  };

  const DetermineErrorModalButtons = () => {
    switch (errorModalButtons) {
      case ErrorModalButtons.StandardYesNoButtons: return <StandardYesNoButtons />;
      case ErrorModalButtons.BlockingErrorYesButton: return <BlockingErrorYesButton />;
      case ErrorModalButtons.NegativeNetPayYesNoButtons: return <NegativeNetPayYesNoButtons />;
    }
  };

  return (
    <div onClick={(e) => {return e.stopPropagation();}}>
      <BootstrapModal
        show={show}
        onHide={closeModal}
        size="sm"
        animation={false}
      >
        <ModalHeader
          getPrevEmp={getPrevEmp}
          getNextEmp={getNextEmp}
          closeModal={closeModal}
        />
        <BootstrapModal.Body>
          <div className="d-flex justify-content-between mb-3">
            <ModalEmpDetails
              currentEmployee={currentEmployee}
              onAdd={onAddAdjustment}
              ssn={employeeAdjustment?.ssn}
              addType={'Adjustment'}
              disabled={!!disableAdd}
            />
          </div>
          <Tabs
            defaultActiveKey={tabKey}
            activeKey={tabKey}
            id="adjustment-tabs"
            onSelect={(eventKey: string | null) => {
              onSave();
              setTabKey(parseInt(eventKey ?? '0'));
            }}
          >
            {fields?.map((item, index) => {
              const check = item as PayrollAdjustmentSummary;
              const disabled = check.adjustmentId !== 0;
              const voidDisabled = check.adjustmentType.startsWith('Void');

              return (
                <Tab
                  key={item.id}
                  eventKey={item.adjustmentId}
                  title={`${item.checkNo} ${postFix(check.adjustmentType)}`}
                >
                  <AdjustmentCheckItem
                    item={item}
                    check={check}
                    tabKey={tabKey}
                    index={index}
                    employee={employee}
                    employeeAdjustment={employeeAdjustment}
                    currentEmployee={currentEmployee}
                    control={control}
                    handleSubmit={handleSubmit}
                    register={register}
                    errors={errors}
                    isDirty={isDirty}
                    setValue={setValue}
                    getValues={getValues}
                    watch={watch}
                    disabled={disabled}
                    voidDisabled={voidDisabled}
                    adjustmentCodeOpts={adjustmentCodeOpts}
                    payrollHistoryId={payrollHistoryId}
                    totalDeduction={totalDeduction}
                    totalAmount={totalAmount}
                    onPrint={onPrint}
                    onSave={onSave}
                    closeModal={closeModal}
                    onAdjustmentTypeChange={
                      onAdjustmentTypeChange
                    }
                  />
                </Tab>
              );
            })}
          </Tabs>
        </BootstrapModal.Body>
      </BootstrapModal>
      {showVoidSearchModal && employeePayrollAdjustment && (
        <VoidSearchModal
          protectedEmpNo={employeePayrollAdjustment.protectedEmpNo}
          empNo={employeePayrollAdjustment.empNo}
          voidType={voidType}
          payrollHistoryId={
            employeePayrollAdjustment?.payrollHistoryId ?? 0
          }
          show={showVoidSearchModal}
          reset={() => { reset(); }}
          onHide={() => {return setShowVoidSearchModal(false); }}
        />
      )}
      {showValidateErrorsModal && (
        <Modal
          show={showValidateErrorsModal}
          onHide={() => { setShowValidateErrorsModal(false); }}
          title={'Summary Data Notice'}
        >
          {() => {
            return (
              <>
                <div className="d-flex flex-column">
                  <span className="mb-2">Adjustment check has been validated with the following errors:</span>
                  <ul>
                    {validateCheckErrors.map((error: string, index) => (<li key={index + error}>{error}</li>))}
                  </ul>
                  {(errorModalButtons == ErrorModalButtons.StandardYesNoButtons) ? <span>Do you want to apply the calculation result?</span> : null }
                </div>
                <div className="d-flex justify-content-end mt-2">                  
                  <DetermineErrorModalButtons />
                </div>
              </>
            );
          }}
        </Modal>
      )}
      {showPrintAdjustedCheckModal && (
        <BootstrapModal
          show={show}
          onHide={hidePrintAdjustedCheckModal}
        >
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Print</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <PdfViewer
              pdfData={printAdjustmentCheck}
              reportName="AdjustmentDetails"
            />
          </BootstrapModal.Body>
        </BootstrapModal>
      )}
    </div>
  );
};

export default AdjustmentDetailModal;
