import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class DeferredCompensation {
  clientNo = 0;

  empNo = 0;

  protectedEmpNo = '';

  eligible401K = false;

  eligible401KDate: Date | string | null = null;

  eligibleDate401KMatch: Date | string | null = null;

  eligibleDate401kContribution: Date | string | null = null;

  highlyCompEmp = false;

  unionIndicator = false;

  classExclusionId = 0;

  catchUpMethodId: number | null = 0;

  keyEmployee = false;

  divisionId = 0;

  eligibleCompanyCont = false;

  eligibleCompanyMatch = false;

  matchAccountNo = '';

  matchRoutingNo = '';

  contributionAccountNo = '';

  contributionRoutingNo = '';

  qualifiedForCatchUp = false;
  

  static readonly convProps: PropTypeLists = {
    dateProps: ['eligible401KDate', 'eligibleDate401KMatch', 'eligibleDate401kContribution'],
    numberProps: ['clientNo', 'empNo', 'classExclusionId', 'divisionId'],
    nullNumberProps: ['catchUpMethodId'],
    boolProps: ['eligible401K', 'highlyCompEmp', 'unionIndicator', 'keyEmployee',
      'eligibleCompanyCont', 'eligibleCompanyMatch', 'qualifiedForCatchUp'],
  };

  constructor(clientNo: string | number, empNo: string | number, protectedEmpNo: string, props?: { [key: string]: any }) {
    this.clientNo = (typeof clientNo === 'string') ? parseInt(clientNo) : clientNo;
    this.empNo = (typeof empNo === 'string') ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;
    props && convToClass(this, props, DeferredCompensation.convProps);
  }
}