import Icon from 'core/components/shared/Icon';
import { EducationHistory } from 'core/models';
import { TO_NAME, convertRegion } from 'core/states';
import React, { useState } from 'react';
import { Accordion, Card, Col, Row, Tab, Tabs } from 'react-bootstrap';

type Props = {
  histories: EducationHistory[]
};

const EducationHistoryComponent = ({
  histories,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header className="p-3">
          <Accordion.Toggle
            eventKey="4"
            style={{
              background: 'transparent',
              border: 'none',
            }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <Icon
              name={isOpen ? 'chevron-up' : 'chevron-down'}
              className="mr-1"
            />
            Education History
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="4">
          <Card.Body
            className="p-3"
            style={{ gap: '4rem' }}
          >
            <Tabs
              defaultActiveKey={histories?.[0]?.schoolName}
              id="uncontrolled-tab-example"
            >
              {histories.map((history, index) => {
                return (
                  <Tab
                    key={index}
                    eventKey={history?.schoolName}
                    title={history?.schoolName}
                    className="py-4"
                  >
                    <Row>
                      {history.schoolName && (
                        <Col sm={2}>
                          <p className="font-weight-bold mb-0">School Name</p>
                          <p className="mb-0">{history.schoolName}</p>
                        </Col>
                      )}
                      <Col sm={2}>
                        <p className="font-weight-bold mb-0">Address</p>
                        {history.streetAddress1 && (
                          <p className="mb-0">{history.streetAddress1}</p>
                        )}
                        {history.streetAddress2 && (
                          <p className="mb-0">{history.streetAddress2}</p>
                        )}
                        {(history.city || history.state) && (
                          <p className="mb-0">{history.city} {history.state && `, ${convertRegion(history.state, TO_NAME)}`}</p>
                        )}
                        {history.zip && (
                          <p className="mb-0">{history.zip}</p>
                        )}
                      </Col>
                      <Col sm={2}>
                        <p className="font-weight-bold mb-0">Degree Level</p>
                        <p className="mb-0">{history.degreeLevel}</p>
                      </Col>
                      <Col sm={2}>
                        <p className="font-weight-bold mb-0">Major</p>
                        <p className="mb-0">{history.major || ''}</p>
                      </Col>
                      <Col sm={2}>
                        <p className="font-weight-bold mb-0">GPA</p>
                        <p className="mb-0">{history.gpa || ''}</p>
                      </Col>
                      <Col sm={2}>
                        <p className="font-weight-bold mb-0">Enrollment</p>
                        <p className="mb-0">{history.enrollmentMonth}/{history.enrollmentYear}</p>
                      </Col>
                      <Col sm={2}>
                        <p className="font-weight-bold mb-0">Graduation</p>
                        <p className="mb-0">Graduated: {history.graduated ? 'Yes' : 'No'}</p>
                        <p className="mb-0">Month: {history.graduationMonth}</p>
                        <p className="mb-0">Year: {history.graduationYear}</p>
                      </Col>
                      <Col sm={2}>
                        <p className="font-weight-bold mb-0">Explanation (if necessary):</p>
                        <p className="mb-0">{history.explanation}</p>
                      </Col>
                    </Row>
                  </Tab>
                );
              })}
            </Tabs>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default EducationHistoryComponent;