import React, { useContext, useEffect, useState } from 'react';
import './time-card-styles.scss';
import InputSection, { inputStyle } from './InputSection';
import { InputGrp } from 'core/components/form-controls';
import { inputGrpStyle } from './ContractorTimeSheet';
import { createToggleShow } from 'utilities/utilities';
import JobNoModal from './modals/JobNo.modal';
import SubNoModal from './modals/SubNo.modal';
import { useAppSelector } from 'utilities/hooks';
import CostCodeModal from './modals/CostCode.modal';
import CityCodeModal from './modals/CityCode.modal';
import ModalInputButton from './ModalInputButton';
import ModalInput from './ModalInput';
import { getAllSubs } from 'core/store/actions';
import { DetailArrayField } from 'core/models';
import { TimeCardContext } from './TimeCardContext';

type Props = {
  timeSheet: DetailArrayField;
  index: number;
};

const JobInfoSection = ({ timeSheet, index }: Props) => {
  const { formMethods, updateDirtyState } = useContext(TimeCardContext);
  const { register, watch, setValue } = formMethods;
  
  const watchedJobNo: string = watch(`details[${index}].jobNumber`);
  const watchedSubNo: string = watch(`details[${index}].subNumber`);
  const watchedCostCode: string = watch(`details[${index}].costCode`);
  const watchedCityCode: number = watch(`details[${index}].cityEntityId`);
  
  const { allJobs, allSubs, allCostCodes, taxingCities } = useAppSelector(({ contractor }) => contractor);
  
  const [jobDesc, setJobDesc] = useState<string>('');
  const [subNoDesc, setSubNoDesc] = useState<string>('');
  const [costCodeDesc, setCostCodeDesc] = useState<string>('');

  const [cityDesc, setCityDesc] = useState<string>('');
  const [showModal, setShowModal] = useState<{ [key: string]: boolean }>({
    jobNo: false,
    subNo: false,
    costCode: false,
    cityCode: false,
  });
  const toggleShowModal = createToggleShow(setShowModal);
  
  useEffect(() => {
    const matchingJob = allJobs?.find((job) => job.jobNo === watchedJobNo);
    if (!matchingJob) {
      setJobDesc('');
      return;
    } 
    
    setJobDesc(matchingJob.description);
  }, [watchedJobNo]);

  useEffect(() => {
    const matchingSubNo = allSubs?.find((subNo) => subNo.subNo === watchedSubNo);
    if (!matchingSubNo) {
      setSubNoDesc('');
      return;
    } 
    
    setSubNoDesc(matchingSubNo.description);
  }, [watchedSubNo]);

  useEffect(() => {
    const matchingCostCode = allCostCodes?.find((costCode) => costCode.costCode === watchedCostCode);
    if (!matchingCostCode) {
      setCostCodeDesc('');
      return;
    } 
    
    setCostCodeDesc(matchingCostCode.description);
  }, [watchedCostCode]);
  
  useEffect(() => {
    const matchingCity = taxingCities?.find((city) => (city?.entityId ?? -1) === +watchedCityCode);
    if (!matchingCity) return;
    
    setCityDesc(matchingCity.codeDesc);
  }, [watchedCityCode]);
  
  const setControlledField = (property: keyof DetailArrayField, newValue: string | number | boolean) => {
    setValue(`details[${index}].${property}`, newValue);
    updateDirtyState(true);
  };
  
  const onCityCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCityDesc(e.target.value);
    updateDirtyState(true);
    
    const matchingCity = taxingCities?.find((city) => city.codeDesc.toLowerCase() === e.target.value.toLowerCase());
    if (!matchingCity) return;
    
    setControlledField('cityEntityId', matchingCity?.entityId ?? 0);
  };
  
  return (
    <div className="main-section">
      <div className="section-title">Job Info</div>
      <div className="input-section">
        <input
          type="hidden"
          name={`details[${index}].jobNumber`}
          defaultValue={timeSheet.jobNumber}
          ref={register()}
        />
        <span className="input-label">Job No</span>
        <ModalInput
          index={index}
          property="jobNumber"
          value={watchedJobNo}
          modalName="job number"
          onClick={() => { toggleShowModal('jobNo', true); }}
        />
      </div>
      <InputSection
        label=" "
        value={jobDesc}
        readOnly
        addtlInputStyles={{ textAlign: 'left' }}
      />
      <div className="input-section">
        <input
          type="hidden"
          name={`details[${index}].subNumber`}
          defaultValue={timeSheet.subNumber}
          ref={register()}
        />
        <span className="input-label">Sub No</span>
        <ModalInput
          index={index}
          property="subNumber"
          value={watchedSubNo}
          modalName="sub number"
          onClick={() => { toggleShowModal('subNo', true); }}
        />
      </div>
      <InputSection
        label=" "
        value={subNoDesc}
        readOnly
        addtlInputStyles={{ textAlign: 'left' }}
      />
      <div className="input-section">
        <span className="input-label">FO/Extra</span>
        <InputGrp
          name={`details[${index}].foExtra`}
          ref={register()}
          defaultValue={timeSheet.foExtra}
          groupStyle={inputGrpStyle}
          inputStyle={inputStyle}
        />        
      </div>
      <div className="input-section">
        <span className="input-label">Phase</span>
        <InputGrp
          name={`details[${index}].phase`}
          ref={register()}
          defaultValue={timeSheet.phase}
          groupStyle={inputGrpStyle}
          inputStyle={inputStyle}
        />        
      </div>
      <div className="input-section">
        <input
          type="hidden"
          name={`details[${index}].costCode`}
          defaultValue={timeSheet.costCode}
          ref={register()}
        />
        <span className="input-label">Cost Code</span>
        <ModalInput
          index={index}
          property="costCode"
          value={watchedCostCode}
          modalName="cost code"
          onClick={() => { toggleShowModal('costCode', true); }}
        />
      </div>
      <InputSection
        label=" "
        value={costCodeDesc}
        readOnly
        addtlInputStyles={{ textAlign: 'left' }}
      />
      <div className="input-section">
        <input
          type="hidden"
          name={`details[${index}].cityEntityId`}
          defaultValue={watchedCityCode}
          ref={register()}
        />
        <span className="input-label">City Code</span>
        <div className="modal-input-container">
          <InputGrp
            name={`details[${index}].cityEntityId`}
            value={cityDesc}
            onChange={onCityCodeChange}
            groupClass="modal-input"
            inputStyle={inputStyle}
          />     
          <ModalInputButton
            modalName="city code"
            onClick={() => { toggleShowModal('cityCode', true); }}
          />
        </div>
      </div>
      {showModal.jobNo && (
        <JobNoModal
          show={showModal.jobNo}
          onHide={() => { toggleShowModal('jobNo', false); }}
          setControlledField={setControlledField}
        />
      )}
      {showModal.subNo && (
        <SubNoModal
          show={showModal.subNo}
          onHide={() => { toggleShowModal('subNo', false); }}
          setControlledField={setControlledField}
        />
      )}
      {showModal.costCode && (
        <CostCodeModal
          show={showModal.costCode}
          onHide={() => { toggleShowModal('costCode', false); }}
          setControlledField={setControlledField}
        />
      )}
      {showModal.cityCode && (
        <CityCodeModal
          show={showModal.cityCode}
          onHide={() => { toggleShowModal('cityCode', false); }}
          setControlledField={setControlledField}
          setCityDesc={setCityDesc}
        />
      )}
    </div>
  );
};

export default JobInfoSection;