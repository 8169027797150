import React, { useState } from 'react';
import { DMDropdownMenu } from 'core/components/form-controls';
import { DMDropdownDataModel } from 'core/components/form-controls/DMDropDown';
import { NavLink, useHistory } from 'react-router-dom';
import { createToggleShow } from 'utilities/utilities';
import DiagnosticRulesModal from 'features/contractor/modals/DiagnosticRules.modal';
import JobSetupModal from 'features/contractor/modals/job-setup/JobSetup.modal';
import SubSetupModal from 'features/contractor/modals/sub-and-cost-code-setup/SubSetup.modal';
import CostCodeSetupModal from 'features/contractor/modals/sub-and-cost-code-setup/CostCodeSetup.modal';
import HamburgerMenu from 'core/components/shared/HamburgerMenu';

const toolsDD: DMDropdownDataModel = {
  id: 'time-card-tools-dd',
  title: 'Tools',
  items: [
    {
      id: 'cost-code-setup',
      text: 'Cost Code Setup',
      href: '#',
    },
    {
      id: 'diagnostic-rules',
      text: 'Diagnostic Rules',
      href: '#',
    },
    {
      id: 'job-setup',
      text: 'Job Setup',
      href: '#',
    },
    {
      id: 'sub-setup',
      text: 'Sub Setup',
      href: '#',
    },
  ],
};

const diagnosticsDD: DMDropdownDataModel = {
  id: 'time-card-diagnostics-dd',
  title: 'View',
  items: [
    {
      id: 'time-sheet-summary',
      text: 'Time Sheet Summary',
      href: '#',
    },
    {
      id: 'time-sheet-entry',
      text: 'Time Sheets',
      href: '/contractor-time-sheets',
    },
  ],
};

const analysisDD: DMDropdownDataModel = {
  id: 'time-card-analysis-dd',
  title: 'Analysis',
  items: [
    { // represents "time by employee" from old system
      id: 'diagnostics',
      text: 'Diagnostics',
      href: '#',
    },
    {
      id: 'job-roster',
      text: 'Job Roster',
      href: '#',
    },
    {
      id: 'time-by-day',
      text: 'Time by Day',
      href: '/time-by-day',
    },
  ],
};

type Props = {
  toggleSidebar: () => void;
  showSidebarMenuIcon: boolean;
};

const TimeCardToolbar = ({ showSidebarMenuIcon, toggleSidebar }: Props) => {
  const [showModal, setShowModal] = useState({
    diagnosticRules: false,
    jobSetup: false,
    subSetup: false,
    costCodeSetup: false,
  });
  const toggleShow = createToggleShow(setShowModal);
  
  const history = useHistory();
  
  const onMenuSelect = (menuId: string) => {
    switch (menuId) {
      case 'diagnostic-rules':
        toggleShow('diagnosticRules', true);
        break;
      case 'job-setup':
        toggleShow('jobSetup', true);
        break;
      case 'sub-setup':
        toggleShow('subSetup', true);
        break;
      case 'cost-code-setup':
        toggleShow('costCodeSetup', true);
        break;
      case 'diagnostics':
        history.push('/diagnostics');
        break;
      case 'job-roster':
        history.push('/job-roster');
        break;
      case 'time-by-day':
        history.push('/time-by-day');
        break;
      case 'time-sheet-summary':
        history.push('/time-sheet-summary');
        break;
    }
  };
  
  return (
    <div className="dm-toolbar">
      {showSidebarMenuIcon && (
        <HamburgerMenu
          toggleSidebar={toggleSidebar}
        />
      )}
      <NavLink to="/process-payroll">Process Payroll</NavLink>
      <NavLink to="/upload-payroll">Payroll Upload</NavLink>
      <NavLink to="/sales-tax-transmittal">Sales Tax Transmittal</NavLink>
      <DMDropdownMenu
        data={toolsDD}
        toggleClass="dm-toolbar-nav"
        whiteChevron={true}
        onSelect={onMenuSelect}
      />
      <DMDropdownMenu
        data={diagnosticsDD}
        toggleClass="dm-toolbar-nav"
        whiteChevron={true}
        onSelect={onMenuSelect}
      />
      <DMDropdownMenu
        data={analysisDD}
        toggleClass="dm-toolbar-nav"
        whiteChevron={true}
        onSelect={onMenuSelect}
      />
      {showModal.diagnosticRules && (
        <DiagnosticRulesModal
          show={showModal.diagnosticRules}
          onHide={() => { toggleShow('diagnosticRules', false); }}
        />
      )}
      {showModal.jobSetup && (
        <JobSetupModal
          show={showModal.jobSetup}
          onHide={() => { toggleShow('jobSetup', false); }}
        />
      )}
      {showModal.subSetup && (
        <SubSetupModal
          show={showModal.subSetup}
          onHide={() => { toggleShow('subSetup', false); }}
        />
      )}
      {showModal.costCodeSetup && (
        <CostCodeSetupModal
          show={showModal.costCodeSetup}
          onHide={() => { toggleShow('costCodeSetup', false); }}
        />
      )}
    </div>
  );
};

export default TimeCardToolbar;