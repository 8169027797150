import React, { useEffect, useMemo } from 'react';
import { useAppSelector } from 'utilities/hooks';
import { getInterviewLocations } from 'core/store/slices/applicantTracking.slice';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { StylesConfigType, compStyles } from 'utilities/styles';

interface PropsInterviewLocationsDropDown {
  onChange?: (e: any) => void,
  value?: any,
}

const InterviewLocationsDropDown = ({ onChange, value }: PropsInterviewLocationsDropDown) => {
  const dispatch = useDispatch();
  
  const { interviewLocations } = useAppSelector(({ applicantTracking }) => applicantTracking);

  useEffect(() => {
    dispatch(getInterviewLocations());
  }, []);

  const interviewLocationsOptions = useMemo(() => {
    return (
      [
        {
          value: null,
          label: '<None>',
        },
        ...interviewLocations?.map((value) => {
          return {
            value: value.interviewLocationId,
            label: value.description,
          };
        }) || [],
      ]
    );
  }, [interviewLocations]);

  return (
    <Select
      options={interviewLocationsOptions}
      styles={compStyles as StylesConfigType}
      onChange={onChange}
      value={interviewLocationsOptions.filter((data: any) => data.value === value)}
      placeholder="Location"
      name="location"
    />
  );
};

export default InterviewLocationsDropDown;
