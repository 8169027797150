import Icon from 'core/components/shared/Icon';
import React, { useState } from 'react';
import { useAccordionToggle } from 'react-bootstrap';
import styles from './nested-accordion.module.scss';

type PropTypes = {
  eventKey: any;
  title: string;
  containerClass?: string;
  iconClass?: string;
};

const Level1Toggle: React.FC<PropTypes> = ({
  eventKey,
  title,
  containerClass = styles['l1toggle-container'],
  iconClass = styles['l1toggle-icon'],
}) => {
  const [open, setOpen] = useState(false);
  const onClick = useAccordionToggle(eventKey, () => {
    setOpen(prev => { return !prev; });
  });

  return (
    <div className={containerClass}
      onClick={onClick} >
      <Icon name={open ? 'folder-open' : 'folder'}
        className={iconClass} />{title}
    </div >
  );
};

export default Level1Toggle;