/* eslint-disable react/display-name */
import React, { CSSProperties } from 'react';
import { Tooltip } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';

const toolTipStyles: CSSProperties = { fontSize: '0.75rem' };

type Props = {
  message: string;
};

const RenderToolTip = ({ message }: Props) => (props: OverlayInjectedProps) => {
  return (
    <Tooltip
      id="info-tooltip"
      {...props}
    >
      <div style={toolTipStyles}>
        {message}
      </div>
    </Tooltip>
  );
};

export default RenderToolTip;