import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import styles from './nested-accordion.module.scss';

type PropTypes = {
  item: any;
  data?: any;
  containerClass?: string;
};

const MenuItem: React.FC<PropTypes> = ({
  item: { href, title, modal: Modal },
  containerClass = styles['mi-container'],
}) => {
  const { protectedEmpNo } = useParams<any>();
  const [showModal, setShowModal] = useState(false);

  const href2 = href.replace(/{protectedEmpNo}/, protectedEmpNo);
  if (Modal) {
    return (
      <div className={containerClass}>
        <NavLink to="#" activeClassName="show" onClick={() => {return setShowModal(true);}}>
          {title}
        </NavLink>
        {showModal && <Modal show={showModal} onHide={() => {return setShowModal(false);}} />}
      </div>
    );
  }
  return (
    <div className={containerClass}>
      <NavLink to={href2} activeClassName="show">
        {title}
      </NavLink>
    </div>
  );
};

export default MenuItem;