import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PanelHeader from 'core/components/shared/PanelHeader';
import {
  HREducation,
  HREducationRequest,
} from 'core/models/HREmployee.model';
import {
  createHREducation,
  deleteHREducation,
  loadHREducation,
  updateHREducation,
} from 'core/store/actions/hr-employee.action';
import { getHireInfoEducation } from 'core/store/selectors/hr-employee.selector';
import EducationItem from './EducationItem';
import { useForm, useFieldArray } from 'react-hook-form';
import { UNSAVED_MESSAGE } from 'core/constants';
import { currencyFormatter, fromCurrency } from 'utilities/utilities';
import Icon from 'core/components/shared/Icon';
import { toggleBlockNavigation } from 'core/store/actions';

type RouteParams = {
  protectedEmpNo: string;
};
type Clone = Omit<HREducation, 'employeeCost' | 'employerCost'> &
{ employeeCost: string | number; employerCost: string | number };
type FormType = {
  educationItems: Clone[];
};

const EducationPage: React.FC = () => {
  const dispatch = useDispatch();

  const { protectedEmpNo } = useParams<RouteParams>();
  
  const [educationItems, setEducationItems] = useState<HREducation[]>([]);
  
  const educationStore = useSelector(getHireInfoEducation);
  
  
  const {
    reset,
    control,
    handleSubmit,
    formState,
    ...formMethods
  } = useForm<FormType>({
    defaultValues: {
      educationItems,
    },
  });

  const { fields, prepend } =
    useFieldArray<HREducation>({
      control,
      name: 'educationItems',
      keyName: 'id',
    });

  useEffect(() => {
    protectedEmpNo && dispatch(loadHREducation(protectedEmpNo));
  }, [protectedEmpNo]);

  useEffect(() => {
    educationStore && setEducationItems(educationStore);
  }, [educationStore]);

  useEffect(() => {
    if (!educationItems) return;
    reset({
      educationItems: educationItems.map((e: HREducation) => {
        return {
          ...e,
          employeeCost: e?.employeeCost && currencyFormatter(e.employeeCost),
          employerCost: e?.employerCost && currencyFormatter(e.employerCost),
        };
      }),
    });
  }, [educationItems]);

  const addEducation = () => {
    prepend({
      educationId: 0,
      protectedEmpNo,
    });
  };

  const deleteEducation = (item: HREducation) => {
    if (item.educationId) {
      dispatch(deleteHREducation({
        data: item,
        protectedEmpNo,
      }));
    } else {
      setEducationItems((prev: HREducation[]) => { return prev.filter((cb: HREducation) => { return cb.educationId; }); });
    }
  };

  const onSubmit = (data: FormType, e?: React.BaseSyntheticEvent) => {
    if (!e?.target.isConnected) {
      return false;
    }
    if (!data) return;
    
    data.educationItems.forEach((item) => {
      const request: HREducationRequest = {
        protectedEmpNo,
        data: {
          ...item,
          educationId: +item.educationId,
          enrollmentDate: item.enrollmentDate !== '' ? new Date(item.enrollmentDate!).toISOString() : null,
          graduateDate: item.graduateDate !== '' ? new Date(item.graduateDate!).toISOString() : null,
          employerCost: (item.employerCost && fromCurrency('' + item.employerCost)) || 0,
          employeeCost: (item.employeeCost && fromCurrency('' + item.employeeCost)) || 0,
        },
      };
      
      if (request.data.educationId !== 0) {
        dispatch(updateHREducation(request));
      } else {
        dispatch(createHREducation(request));
      }
    });
  };

  const onCancel = () => {
    reset();
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: formState.isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [formState.isDirty]);

  return (
    <div>
      <div className="dm-panel dm-panel-border" >
        <PanelHeader title="Education" >
          <button
            type="button"
            className="btn btn-link dm-grid-action-title p-0 mr-2"
            onClick={addEducation}
          >
            Add Education	<Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields?.map((item, index) => {
            return (
              <div key={item.id} >
                <EducationItem
                  item={item}
                  index={index}
                  control={control}
                  formMethods={formMethods}
                  onDelete={deleteEducation}
                />
                <hr className="dm-panel-hr mt-2" />
              </div>
            );
          })}

          <div className="text-right mt-3">
            <button
              type="button"
              className="btn btn-primary orange-outline-button ml-auto mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              disabled={!formState.isDirty}
              type="submit"
              className="btn orange-button mr-3"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EducationPage;
