import { createAction } from '@reduxjs/toolkit';
import { OrderByType } from 'core/models/AccrualReport.model';

export const storeEmpNos = createAction<number[]>(
  '[employee-information] STORE_EMP_NOS',
);

export const storeOrderByType = createAction<OrderByType>(
  '[employee-information] STORE_ORDER_BY_TYPE',
);
