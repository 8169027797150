import { Client } from './Client.model';
import { Employee } from './Employee.model';

export interface Transfers {
  employees: Partial<Employee>[];
  relatedClients: Partial<Client>[];
}

export class EmployeeToTransfer {
  targetClientNo = 0;

  targetEmpNo = 0;

  originClientNo = 0;

  originEmpNo = 0;

  originEmpID = 0;

  constructor(
    targetClientNo: number,
    targetEmpNo: number,
    originClientNo: number,
    originEmpNo: number,
    originEmpID: number,
  ) {
    this.targetClientNo = targetClientNo;
    this.targetEmpNo = targetEmpNo;
    this.originClientNo = originClientNo;
    this.originEmpNo = originEmpNo;
    this.originEmpID = originEmpID;
  }
}
