import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CheckboxGrpInLine, InputGrp } from 'core/components/form-controls';
import { Employee } from 'core/models';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import Icon from 'core/components/shared/Icon';
import DateObject from 'react-date-object';
ModuleRegistry.registerModules([ClientSideRowModelModule]);

type PropTypes = {
  emps: Employee[];
  show: boolean;
  onHide: () => void;
  addEmployees: (employees: Employee[]) => void;
};

const AddTimeSheetModal: React.FC<PropTypes> = ({
  emps,
  show,
  onHide,
  addEmployees,
}) => {
  const [rowData, setRowData] = useState(emps.filter(x => { return !x.termDate; }));
  
  const gridRef = useRef<AgGridReact>(null);

  const resize = () => {
    if (!gridRef.current) {
      return;
    }
    gridRef.current.api.sizeColumnsToFit();
  };

  const columnDefs = useMemo<ColDef[]>(
    () => {
      return [
        {
          headerName: '',
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          width: 50,
        },
        {
          field: 'firstName',
        },
        {
          field: 'lastName',
        },
        {
          field: 'empNo',
        },
        {
          field: 'termDate',
          hide: true,
          valueFormatter: (params) => {return formatDate(params.data.termDate);}, 
        },
      ];
    },
    [],
  );

  const formatDate = (date: string | null) => {
    return new DateObject(new Date(date || '')).format('MM/DD/YYYY');
  };

  const onSave = () => {
    if (!gridRef.current) {
      return;
    }
    const gridApi = gridRef.current.api;
    const employees : Employee[] = [];
    
    if (gridApi) {
      gridApi.getSelectedNodes().forEach((node) => {
        const employee = node.data as Employee;
        employees.push(employee);
      });
      
      addEmployees(employees);
      closeModal();
    }
  };

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };

  const filterTextBox = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (!gridRef.current) {
        return;
      }
      gridRef.current.api.setQuickFilter(e.currentTarget.value);
    },
    [],
  );

  const filterCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const empCopy = structuredClone(emps);
    
    if (!gridRef.current) { return; }
    if (checked) {
      setRowData(empCopy);
      gridRef.current.columnApi.setColumnVisible('termDate', true);
    } else {
      setRowData(empCopy.filter(x => { return !x.termDate; }));
      gridRef.current.columnApi.setColumnVisible('termDate', false);
    }
    
    resize();
  };

  return (
    <div onClick={(e) => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        size="sm"
        animation={false}
      >
        <div className="modal-header">
          <div className="dm-card-title">
            Add Time Sheet
          </div>
          <button
            type="button"
            onClick={closeModal}
            className="btn-link-modal"
          >
            <Icon
              name="times"
              className="modal-close-btn"
            />
          </button>
        </div>
        <Modal.Body>
          <div className="w-100">
            <InputGrp
              name="filter"
              onChange={filterTextBox}
              label="Filter"
            />
            <CheckboxGrpInLine
              name="showTermEmployees"
              label="Show Terminated Employees"
              groupClass = {'groupClass40 pt-1'}
              onChange = {filterCheckBox}
              defaultChecked = {false}
            />
            <div style={{
              height: '70vh',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
            >
              <AgGridReact
                ref={gridRef}
                className="ag-theme-balham"
                rowData={rowData}
                rowMultiSelectWithClick
                rowSelection="multiple"
                defaultColDef={{
                  suppressMenu: true,
                  resizable: true,
                }}
                onFirstDataRendered={resize}
                singleClickEdit={true}
                columnDefs={columnDefs}
              />
            </div>
          </div>
          <div className="d-flex mt-3">
            <button
              className="orange-outline-button ml-auto mr-2"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="orange-button mr-2"
              onClick={onSave}
            >
              Add
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddTimeSheetModal;
