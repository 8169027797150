import React, { useContext, useEffect, useState } from 'react';
import EmpPhoto from 'core/components/shared/EmpPhoto';
import { Employee } from 'core/models';
import styles from './styles.module.scss';
import '../time-card-styles.scss';
import Icon from 'core/components/shared/Icon';
import SummaryItem from './SummaryItem';
import { TimeCardDetail, TimeCardTotals } from 'core/models/Contractor.model';
import { TimeSheetSummaryContext, TimeSheetSummaryFilter, TimeCardArrayField } from './TimeSheetSummary';
import { validDetail, getTimeCardDetailTotals, createNewTimeSheet } from '../utilities';
import SummaryDeductionsModal from '../modals/SummaryDeduction.modal';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { getHomeAtsInfo, storeExpandedEmployees } from 'core/store/actions';

type Props = {
  employee: Employee | undefined,
  timeCard: TimeCardArrayField
  timeCardIndex: number,
  hidden: boolean;
  filters: TimeSheetSummaryFilter,
  setFilters: (newFilter: TimeSheetSummaryFilter) => void,
  removeTimeCard: (timeCardId: number) => void,
};

const SummaryGroup = ({
  employee,
  timeCard,
  timeCardIndex,
  hidden,
  filters,
  setFilters,
  removeTimeCard,
}: Props) => {
  //Get Info from redux state
  const { timeCardCrewSheetColumns, homeAtsInfo, payrollInfo, expandedEmployees } = useAppSelector(({ contractor }) => contractor);

  //Local state variables
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [currentDay, setCurrentDay] = useState<number>(filters.dayOfWeek);
  const [showDeductionsModal, setShowDeductionsModal] = useState<boolean>(false);
  const [totals, setTotals] = useState<TimeCardTotals>({
    regularTotal: 0,
    timeAndAHalfTotal: 0,
    doubleTotal: 0,
    otherEarningsHoursTotal: 0,
  });

  //Form Methods Used
  const { updateTimeCardState } = useContext(TimeSheetSummaryContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const empNo = employee?.empNo;
    const checkCode = timeCard.checkCode;
    if (!empNo || !checkCode) return;

    if (expandedEmployees?.find(x => x.empNo === empNo && x.checkCode === checkCode)) {
      setIsExpanded(true);
    } 
  }, []);

  useEffect(() => {
    const empNo = employee?.empNo;
    const checkCode = timeCard.checkCode;
    if (!empNo || !checkCode) return;
    const expandedEmployeeFilter = structuredClone(expandedEmployees)?.filter(x => x.empNo !== empNo && x.checkCode !== checkCode);

    if (isExpanded) {
      dispatch(storeExpandedEmployees([...expandedEmployees, { empNo: empNo, checkCode: checkCode }]));
    } else {
      dispatch(storeExpandedEmployees([...expandedEmployeeFilter ?? []]));
    }
  }, [isExpanded]);

  const removeDetailOverride = (timeCardId: number, timeCardDetailId: number) => {
    //If there is only one detail left remove the whole timeCard instead of just the detail. 
    if ((timeCard?.details?.length ?? 0) > 1) {
      if (!confirm('Are you sure you want to delete the row record? This action cannot be undone.')) return;
      updateTimeCardState({ type: 'DELETE_DETAIL', timeCardId, timeCardDetailId });
    } else {
      if (!confirm('Are you sure you want to delete the time sheet record? This action cannot be undone.')) return;
      removeTimeCard(timeCardId);
    }
  };
  
  useEffect(() => {
    setCurrentDay(filters.dayOfWeek);
    setTotals(getTimeCardDetailTotals((timeCard?.details ?? []), filters));
  }, [filters, timeCard]);

  useEffect(() => {
    if (timeCard.details?.length === 0) addTimeSheetDetail();
  }, [isExpanded]);

  const addTimeSheetDetail = () => {
    if (!timeCard.transmittalTimeCardId && timeCard.transmittalTimeCardId !== 0 || !employee || !payrollInfo) return;
    dispatch(getHomeAtsInfo({ protectedEmpNo: employee.protectedEmpNo, effectiveDate: payrollInfo.weekEnd }));

    const newTimeSheet = createNewTimeSheet(timeCard.transmittalTimeCardId, homeAtsInfo, 0);
    newTimeSheet.jobNumber = (filters.jobNo !== '<All>') ? filters.jobNo : '';
    newTimeSheet.subNumber = (filters.subNo !== '<All>') ? filters.subNo : '';
    newTimeSheet.phase = (filters.phase !== '<All>') ? filters.phase : '';
    newTimeSheet.costCode = (filters.costCode !== '<All>') ? filters.costCode : '';
    newTimeSheet.foExtra = (filters.foExtra !== '<All>') ? filters.foExtra : '';
   
    updateTimeCardState({ type: 'ADD_DETAIL', newTimeSheet });
  };

  return (
    <>
      <div
        className="time-card-header detail-item-header"
        onClick={() => { setIsExpanded(!isExpanded); }}
        hidden={hidden}
      >
        <div className="card-header-row">
          <div className="emp-main-info">
            <EmpPhoto
              empPicData={employee?.employeePhoto}
              empName={`${timeCard.firstName} ${timeCard.lastName}`}
              scaleFactor="65"
            />
            <div className="d-flex flex-column">
              <span className="dm-grid-title emp-name mb-1">{timeCard.firstName}&nbsp;{timeCard.lastName}</span>
              <div className="header-info-section">
                <span className="font-weight-bold">Emp No:&nbsp;{timeCard.empNo}</span>
                <span className="font-weight-bold">Check Code:&nbsp;{timeCard?.checkCode ?? '0'}</span>
              </div>
            </div>
            <div className="sub-head-section ml-3 mt-1">
              <strong>Employee Totals</strong>
              <div>
                <strong>Regular:&nbsp;</strong>{(totals?.regularTotal ?? 0).toFixed(2)}
              </div>
              <div>
                <strong>Time and a Half:&nbsp;</strong>{(totals?.timeAndAHalfTotal ?? 0).toFixed(2)}
              </div>
              <div>
                <strong>Double:&nbsp;</strong>{(totals?.doubleTotal ?? 0).toFixed(2)}
              </div>
              <div>
                <strong>Other Hours:&nbsp;</strong>{(totals?.otherEarningsHoursTotal ?? 0).toFixed(2)}
              </div>
            </div>
          </div>
          <div className="d-flex ml-auto">
            <button
              className="btn btn-link mr-2"
              type="button"
              onClick={(e) => {
                setShowDeductionsModal(true);
                e.stopPropagation();
              }}
              hidden={timeCardCrewSheetColumns?.hideDeductions ?? false}
            >
              View Deductions
            </button>
            <button
              type="button"
              className="btn btn-link mr-2"
              onClick={(e) => {
                addTimeSheetDetail();
                e.stopPropagation();
              }}
            >
              Add Entry&nbsp;
              <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
            <button
              className="btn btn-link"
              type="button"
              onClick={() => { setIsExpanded(!isExpanded); }}
            >
              {isExpanded ? 'Collapse' : 'Expand'}
            &nbsp;
              <Icon
                name={isExpanded ? 'chevron-circle-up' : 'chevron-circle-down'}
                className={isExpanded ? 'roster-chevron flipped' : 'roster-chevron'}
              />
            </button>
          </div>
        </div>
      </div>
      {isExpanded && timeCard.details && currentDay === filters.dayOfWeek ? (
        <ul className={`dm-panel-border ${styles['emp-job-group-list']}`}>
          {timeCard.details?.map((detail: TimeCardDetail, detailIndex) => {
            //Will check to see if we should include the detail if not do not add the record.
            if (!validDetail(detail, filters)) return;
            return (
              <SummaryItem
                key={detail.transmittalTimeCardDetailId}
                detailIndex={detailIndex}
                timeCardDetail={detail}
                timeCardIndex={timeCardIndex}
                transmittalTimeCardId={timeCard?.transmittalTimeCardId ?? 0}
                filters={filters}
                removeDetail={removeDetailOverride}
                setFilters={setFilters}
              />
            );
          },
          )}
        </ul>
      ) : null}
      {showDeductionsModal && (
        <SummaryDeductionsModal
          deductions={timeCard?.deductions ?? []}
          transmittalTimeCardId={timeCard?.transmittalTimeCardId ?? 0}
          show={showDeductionsModal}
          onHide={() => { setShowDeductionsModal(false); }}
          timeCardIndex={timeCardIndex}
        />
      )}
    </>
  );
};

export default SummaryGroup;