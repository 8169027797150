import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../utilities/hooks';
import { convToDateISOString } from '../../../utilities/utilities';
import { HrEmployeeCompanyIssuedProperty } from '../../models/HrEmployeeCompanyIssuedProperty.model';
import {
  deleteHrEmployeeCompanyProperty,
  postHrEmployeeCompanyProperty,
  putHrEmployeeCompanyProperty,
  removeHrEmployeeCompanyProperty,
} from '../../store/slices/hr.slice';
import {
  ControlDatePickerGrp,
  InputGrp,
  SelectModalGrp,
} from '../form-controls';
import DropdownOptionForm from '../form-controls/select-modal/DropdownOptionForm';
import Icon from './Icon';

type Props = {
  hrEmployeeCompanyIssuedProperty: HrEmployeeCompanyIssuedProperty;
};

type FormData = {
  propertyTypeId: string;
  issueDate: string;
  returnDate: string;
  identification: string;
  notes: string;
};

const HrEmployeeCompanyProperty: React.FC<Props> = ({
  hrEmployeeCompanyIssuedProperty,
}) => {
  const dispatch = useAppDispatch();
  const empIssuedPropertyTypeDropdown = useAppSelector(
    (state) => { return state.dropdown.empIssuedPropertyType; },
  );
  const { register, control, setValue, handleSubmit, formState, reset } =
    useForm<FormData>({
      defaultValues: {
        ...hrEmployeeCompanyIssuedProperty,
        propertyTypeId:
          hrEmployeeCompanyIssuedProperty.propertyTypeId.toString(),
      },
    });

  const { isDirty } = formState;

  const deleteHrEmployeeCompany = () => {
    dispatch(
      deleteHrEmployeeCompanyProperty({
        protectedEmpNo: hrEmployeeCompanyIssuedProperty.protectedEmpNo,
        propertyId: hrEmployeeCompanyIssuedProperty.propertyId,
      }),
    );
  };

  const onSubmit = (data: FormData, event?: React.BaseSyntheticEvent) => {
    if (!event?.target.isConnected) {
      return false;
    }
    const mergedData = {
      ...hrEmployeeCompanyIssuedProperty,
      propertyTypeId: parseInt(data.propertyTypeId),
      issueDate: convToDateISOString(data.issueDate),
      returnDate: convToDateISOString(data.returnDate),
      identification: data.identification,
      notes: data.notes,
    };
    if (hrEmployeeCompanyIssuedProperty.propertyId > 0) {
      dispatch(putHrEmployeeCompanyProperty(mergedData));
    } else {
      dispatch(removeHrEmployeeCompanyProperty(mergedData.propertyId));
      mergedData.propertyId = 0;
      dispatch(postHrEmployeeCompanyProperty(mergedData));
    }
    reset({ ...data });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Prompt
        when={isDirty}
        message={(location: any, action: any) => {
          if (action === 'PUSH') {
            return 'You have unsaved changes, are you sure you want to leave this page?';
          }
          return true;
        }}
      />
      <Row>
        <Col xs="2">
          <SelectModalGrp
            name="propertyTypeId"
            options={empIssuedPropertyTypeDropdown}
            control={control}
            value={hrEmployeeCompanyIssuedProperty?.propertyTypeId.toString()}
            setValue={setValue}
            label="Property Type"
            formComponent={DropdownOptionForm}
            modalTitle="Add New Property Type"
            dropdownName="EmpIssuedPropertyType"
            addOptionText="Type"
          />
        </Col>
        <Col>
          <ControlDatePickerGrp
            name="issueDate"
            control={control}
            setValue={setValue}
            label="Issue Date"
          />
        </Col>
        <Col>
          <ControlDatePickerGrp
            name="returnDate"
            control={control}
            setValue={setValue}
            label="Return Date"
          />
        </Col>
        <Col>
          <InputGrp
            name="identification"
            ref={register}
            label="Identification"
          />
        </Col>
        <Col>
          <InputGrp name="notes"
            ref={register}
            label="notes" />
        </Col>
        <Col xs="auto"
          className="mt-2 text-right">
          <button
            type="button"
            className="btn mr-2"
            onClick={deleteHrEmployeeCompany}
          >
            Delete Company Property{' '}
            <Icon name="minus-circle"
              className="fa-minus-circle" />
          </button>
          <button className="btn orange-button"
            disabled={!isDirty}>
            Save
          </button>
        </Col>
      </Row>
    </form>
  );
};

export default HrEmployeeCompanyProperty;
