import Icon from 'core/components/shared/Icon';
import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CheckboxGrpInLine } from 'core/components/form-controls';
import InputReportDateSelector from 'core/components/form-controls/InputReportDateSelector';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import { Client, DtoOptions, Location, Department, SubDepartment, SubDepartment2 } from 'core/models';
import { EmployeeAverageHoursWithStatusReportRequest } from 'core/models/AccountingReports.model';
import { CommonService } from 'core/services';
import { clearEmployeeAverageHoursWithStatusReport, downloadEmployeeAverageHoursWithStatusReport } from 'core/store/actions';
import { getLocationDepartmentsWithoutHome, getEmployeeAverageHoursWithStatusReport } from 'core/store/selectors';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

type FormType = {
  loc: number[];
  dept: number[];
  subDept: number[];
  subDept2: number[];
  excludeTermEmp: boolean;
};

const EmployeeAverageHoursWithStatusReport = () => {
  const dispatch = useAppDispatch();
  
  const { locationOpts, deptOpts, subdeptOpts, subdept2Opts } = useSelector(
    getLocationDepartmentsWithoutHome,
  );
  
  const childRef: React.RefObject<any> = useRef();

  const [fileType, setFileType] = useState<string>('pdf');
  const [reportDates, setReportDates] = useState<DtoOptions[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [subDepartments, setsubDepartments] = useState<SubDepartment[]>([]);
  const [subDepartments2, setsubDepartments2] = useState<SubDepartment2[]>([]);

  const reportName = 'EmployeeAverageHoursWithStatusReport';

  const report = useSelector(getEmployeeAverageHoursWithStatusReport);

  const client = useAppSelector((state) => { return state.client.client; }) as Client;

  const { register, handleSubmit, watch, setValue } = useForm<FormType>();
  const excludeTermEmp = watch('excludeTermEmp');

  useEffect(() => {
    if (report && fileType === 'excel') {
      CommonService.downloadBase64File(
        'application/excel',
        report,
        `${reportName}.xlsx`,
      );
      dispatch(clearEmployeeAverageHoursWithStatusReport());
    }
  }, [report]);

  const downloadReport = (data: EmployeeAverageHoursWithStatusReportRequest) => {
    const submitData: EmployeeAverageHoursWithStatusReportRequest = {
      clientNo: client.clientNo,
      loc: locations?.map(({ locationCode }) => { return locationCode; }),
      dept: departments?.map(({ deptCode }) => { return deptCode; }),
      subDept: subDepartments?.map(({ subDeptCode }) => { return subDeptCode; }),
      subDept2: subDepartments2?.map(({ sub2Code }) => { return sub2Code; }),
      excludeTermEmp: excludeTermEmp,
      dtoOptions: reportDates,
      reportOption: 'IncludeStatus',
      reportType: fileType,
    };
    dispatch(downloadEmployeeAverageHoursWithStatusReport(submitData));
  };
  
  const hidePdfViewer = () => {
    dispatch(clearEmployeeAverageHoursWithStatusReport());
  };

  const onCancel = () => {
    childRef?.current?.onCancel();
  };

  const onRemove = (selectedList: any, removeItem: any) => {
    setLocations(
      selectedList.filter((single: number) => { return single !== removeItem; }),
    );
    return selectedList;
  };

  return (
    <div className="dm-panel dm-panel-border">
      <form onSubmit={handleSubmit(downloadReport)}>
        <div className="dm-grid-title">Employee Average Hours With Status Report</div>
        <div className="w-100 text-right">
          <button
            type="submit"
            className="btn btn-link dm-grid-action-title "
            onClick={() => { return setFileType('excel'); }}
            disabled={!reportDates.length}
          >
            Download Report  <Icon
              name="download"
              className="fa-download"
            />
          </button>
        </div>
        <hr className="dm-panel-hr" />

        <div className="d-flex flex-row flex-grow-1">
          <div>
            <InputReportDateSelector
              ref={childRef}
              returnDates={(dates: DtoOptions[]) => { return setReportDates(dates); }
              }
            />
          </div>
          <div className="d-flex flex-column flex-grow-1">
            <div className="text-right">
              <button
                type="button"
                className="orange-outline-button mr-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="orange-button mr-2"
                onClick={() => { return setFileType('pdf'); }}
                disabled={!reportDates.length}
              >
                Run Report
              </button>
            </div>
            <div
              className="w-50 dm-panel-border p-2"
              style={{ marginTop: '66px' }}
            >
              <div className="d-flex flex-column">
                <div className="d-flex flex-column flex-grow-1">
                  {/** Col for title */}
                  <div className="dm-card-subtitle2 mb-2">
                    Report Filter
                  </div>
                  <hr className="dm-panel-hr" />
                </div>
                <div className="d-flex flex-column flex-grow-1">
                  {/** Location */}
                  <div>
                    {locationOpts.length ? (
                      <div className="d-flex flex-column">
                        <div className="form-label mt-1">
                          LOCATION
                        </div>
                        <Multiselect
                          onSelect={(selectedList) => { return setLocations(selectedList); }}
                          options={locationOpts}
                          displayValue="locationDesc"
                          onRemove={onRemove}
                          showCheckbox={true}
                          selectedValueDecorator={(v) => {
                            return locationOpts.find(o => { return o.locationDesc === v; })?.locationCode + ' - ' + v;
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                  {/** Department */}
                  <div>
                    {deptOpts.length ? (
                      <div>
                        <div className="form-label mt-1">
                          DEPT
                        </div>
                        <Multiselect
                          onSelect={(selectedList) => { setDepartments(selectedList); }}
                          options={deptOpts}
                          displayValue="deptDesc"
                          onRemove={onRemove}
                          showCheckbox={true}
                          selectedValueDecorator={(v) => {
                            return deptOpts.find(o => { return o.deptDesc === v; })?.deptCode + ' - ' + v;
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                  {/** Sub Department */}
                  <div>
                    {subdeptOpts.length ? (
                      <div>
                        <div className="form-label mt-1">
                          SUB DEPT
                        </div>
                        <Multiselect
                          onSelect={(selectedList) => { return setsubDepartments(selectedList); }}
                          options={subdeptOpts}
                          displayValue="subDeptDesc"
                          onRemove={onRemove}
                          showCheckbox={true}
                          selectedValueDecorator={(v) => {
                            return subdeptOpts.find(o => { return o.subDeptDesc === v; })?.subDeptCode + ' - ' + v;
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                  {/** Sub Department 2 */}
                  <div>
                    {subdept2Opts.length ? (
                      <div>
                        <div className="form-label mt-1">
                          Sub Dept 2
                        </div>
                        <Multiselect
                          onSelect={(selectedList) => { return setsubDepartments2(selectedList); }}
                          options={subdept2Opts}
                          displayValue="sub2Desc"
                          onRemove={onRemove}
                          showCheckbox={true}
                          selectedValueDecorator={(v) => {
                            return subdept2Opts.find(o => { return o.sub2Desc === v; })?.sub2Code + ' - ' + v;
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="mt-2">
                    <CheckboxGrpInLine
                      name="excludeTermEmp"
                      label="Exclude Terminated Employees"
                      groupClass="groupClassAuto"
                      ref={register}
                      value={excludeTermEmp}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setValue('excludeTermEmp', e.target.checked);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {report ? (<PDFViewerModal
        show={report?.length > 0}
        pdfData={report}
        onHide={hidePdfViewer}
        reportName="EmployeeAverageHoursWithStatusReport"
      />) : null}
    </div>
  );
};

export default EmployeeAverageHoursWithStatusReport;