import React, { Dispatch, SetStateAction, useImperativeHandle, FC, forwardRef, useEffect, useState, ChangeEvent, useRef, MutableRefObject } from 'react';
import DateObject from 'react-date-object';
import { useForm } from 'react-hook-form';
import Calendar from 'react-multi-date-picker';
import { RadioGrp, RadioOptions } from './RadioGrp';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { storeSelectedDates } from 'core/store/actions';

const dateSelectOpts: RadioOptions[] = [
  {
    value: 'weekEnd',
    label: 'By Week Ending',
  },
  {
    value: 'checkDate',
    label: 'By Check Date',
  },
];

type PropTypes = {
  returnDates: (dates: DateObject[]) => void;
  toggleMenuRef: MutableRefObject<HTMLInputElement | null>;
  position?: string;
};

export const QuickSelectPlugin: FC<PropTypes> = ({ returnDates, toggleMenuRef }) => {
  const onClick = (durationType: string) => {
    let startDate = new DateObject(new Date());
    let endDate = new DateObject(new Date());
    switch (durationType) {
      case 'NY':
        startDate = startDate.add(1, 'year').toFirstOfYear();
        endDate = endDate.add(1, 'year').toLastOfYear();
        break;
      case 'NM':
        startDate = startDate.add(1, 'month').toFirstOfMonth();
        endDate = endDate.add(1, 'month').toLastOfMonth();
        break;
      case 'TW':
        startDate = startDate.toFirstOfWeek();
        endDate = endDate.toLastOfWeek();
        break;
      case 'LW':
        startDate = startDate.subtract(7, 'days').toFirstOfWeek();
        endDate = endDate.subtract(7, 'days').toLastOfWeek();
        break;
      case 'TM':
        startDate = startDate.toFirstOfMonth();
        endDate = endDate.toLastOfMonth();
        break;
      case 'LM':
        startDate = startDate.subtract(1, 'month').toFirstOfMonth();
        endDate = endDate.subtract(1, 'month').toLastOfMonth();
        break;
      case 'L30':
        startDate = startDate.subtract(30, 'days');
        break;
      case 'L60':
        startDate = startDate.subtract(60, 'days');
        break;
      case 'L90':
        startDate = startDate.subtract(90, 'days');
        break;
      case 'L12':
        startDate = startDate.subtract(12, 'months');
        break;
      case 'TY':
        startDate = startDate.toFirstOfYear();
        endDate = endDate.toLastOfYear();
        break;
      case 'LY':
        startDate = startDate.subtract(1, 'year').toFirstOfYear();
        endDate = endDate.subtract(1, 'year').toLastOfYear();
        break;
    }
    returnDates([startDate, endDate]);
    toggleMenuRef?.current?.click();
  };

  return (
    <div className="d-flex flex-column">
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('TW'); }}
        >
          <b>This Week</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('LW'); }}
        >
          <b>Last Week</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('TM'); }}
        >
          <b>This Month</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('LM'); }}
        >
          <b>Last Month</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('NM'); }}
        >
          <b>Next Month</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('L30'); }}
        >
          <b>Last 30 days</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('L60'); }}
        >
          <b>Last 60 days</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('L90'); }}
        >
          <b>Last 90 days</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('L12'); }}
        >
          <b>Last 12 mths</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('TY'); }}
        >
          <b>This Year</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('LY'); }}
        >
          <b>Last Year</b>
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn"
          onClick={() => { return onClick('NY'); }}
        >
          <b>Next Year</b>
        </button>
      </div>
    </div>
  );
};

type SelectorPropTypes = {
  selected: string;
  onSelectChange: (e: any) => void;
  returnDates: (dates: string[]) => void;
  position?: string;
  hideDateTypes?: boolean;
  hideDateTitle?: boolean;
  dateTitleOverwrite?: string;
  normalRef?: boolean;
  useDatesFromStore?: boolean;
};

const InputDateSelector = forwardRef<SelectorPropTypes, any>(
  (
    {
      normalRef = false,
      returnDates,
      selected,
      onSelectChange,
      hideDateTypes,
      hideDateTitle = false,
      dateTitleOverwrite,
      useDatesFromStore = false,
    }: SelectorPropTypes,
    ref: any,
  ) => {
    const { register } = useForm({
      defaultValues: { dateType: 'checkDate' },
    });
    
    const { payrollSelectedDates } = useAppSelector(({ payroll }) => payroll);

    const [values, setValues] = useState([
      (useDatesFromStore && !!payrollSelectedDates?.[0]) ? new DateObject(payrollSelectedDates[0]) : new DateObject(new Date()).toFirstOfYear(),
      (useDatesFromStore && !!payrollSelectedDates?.[1]) ? new DateObject(payrollSelectedDates[1]) : new DateObject(new Date()).toLastOfYear(),
    ]);

    const [selectedOption, setSelectedOption] = useState<string>(selected);
    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [shouldClose, setShouldClose] = useState<boolean>(true);
    
    const toggleMenuRef = useRef<HTMLInputElement | null>(null);
    
    const dispatch = useAppDispatch();
    
    useEffect(() => {
      if (values && values?.length > 1 && !isCancel) {
        const cds = values.map((d) => { return new Date(d.toString()).toISOString(); },
        );
        returnDates(cds);
      } else {
        setIsCancel(false);
      }
    }, [values]);

    useEffect(() => {
      selectedOption && setValues([...values]);
    }, [selectedOption]);

    useImperativeHandle(ref, () => {
      if (normalRef) {
        return ref.current;
      }
      return {
        onCancel() {
          setIsCancel(true);

          setValues([
            new DateObject(new Date()).toFirstOfYear(),
            new DateObject(new Date()).toLastOfYear(),
          ]);
        },
      };
    });

    const onCalendarChange = (selectedDates: DateObject[]) => {
      setValues(selectedDates);
      dispatch(storeSelectedDates(selectedDates));
      
      if (selectedDates?.length === 2) {
        toggleMenuRef?.current?.click();
      }
    };

    return (
      <div>
        <div className="d-flex flex-column">
          {!(hideDateTitle || dateTitleOverwrite) ? (
            <div className="form-label">Select Date Range</div>
          ) : (
            <div className="form-label">{dateTitleOverwrite}</div>
          )}
          <input
            type="checkbox"
            ref={toggleMenuRef}
            style={{ display: 'none' }}
            onClick={() => { setShouldClose(true); }}
          />
          <Calendar
            value={values}
            inputMode="text"
            ref={ref}
            numberOfMonths={2}
            format={'MM/DD/YYYY'}
            onChange={onCalendarChange}
            onClose={() => shouldClose}
            style={{
              width: 180,
            }}
            range
            plugins={[
              <QuickSelectPlugin
                key={'quick-select-plugin'}
                returnDates={(dates) => { return onCalendarChange(dates); }}
                position="left"
                toggleMenuRef={toggleMenuRef}
              />,
            ]}
          />
          {!hideDateTypes ? (
            <RadioGrp
              groupClass="gc12 mw450"
              name="dateType"
              ref={register}
              radioOptions={dateSelectOpts}
              onChange={(
                event: ChangeEvent<HTMLInputElement>,
              ) => {
                setSelectedOption(event.target.value);
                onSelectChange(event.target.value);
              }}
            />
          ) : null}
        </div>
      </div>
    );
  },
);

InputDateSelector.displayName = 'InputDateSelector';

export default InputDateSelector;
