import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PanelHeader from 'core/components/shared/PanelHeader';
import { HRIncrease } from 'core/models/HREmployee.model';
import { loadPayRates } from 'core/store/actions';
import { loadHRIncrease } from 'core/store/actions/hr-employee.action';
import { getIncrease } from 'core/store/selectors/hr-employee.selector';
import { clearPayRateReport } from 'core/store/slices/payRateReports.slice';
import { downloadBase64File } from 'utilities/base64';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { PayIncrease } from './PayIncrease';
import CurrentPayRates from 'core/components/shared/CurrentPayRates';

const IncreasePage = () => {
  const dispatch = useAppDispatch();

  const [increase, setIncrease] = useState<HRIncrease>();
  const [dirtyState, setDirtyState] = useState<boolean>(false);
  
  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();

  const increaseStore = useSelector(getIncrease) as HRIncrease;
  const futurePayRateReport = useAppSelector(
    (state) => {return state.payRateReports.report;},
  );

  useEffect(() => {
    if (futurePayRateReport) {
      downloadBase64File(
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        futurePayRateReport,
        `futurePayRateReport${DateTime.local().toISO({
          includeOffset: false,
        })}.xlsx`,
      );
      dispatch(clearPayRateReport());
    }
  }, [futurePayRateReport]);

  useEffect(() => {
    dispatch(loadHRIncrease(protectedEmpNo));
    dispatch(loadPayRates(protectedEmpNo));
  }, [protectedEmpNo]);

  useEffect(() => {
    increaseStore && setIncrease(increaseStore);
  }, [increaseStore]);
  
  const updateDirtyState = (newVal: boolean) => {
    setDirtyState(newVal);
  };

  return (
    <>
      <div className="dm-panel dm-panel-border">
        <PanelHeader title="Increase"></PanelHeader>
        {increase && (
          <div>
            <PayIncrease
              increase={increase}
              protectedEmpNo={protectedEmpNo}
            />
          </div>
        )}
        <CurrentPayRates
          dirtyState={dirtyState}
          updateDirtyState={updateDirtyState}
          protectedEmpNo={protectedEmpNo}
        />
      </div>
    </>
  );
};

export default IncreasePage;
