import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  HttpResponse,
  RealignSupervisedEmployee,
  SupervisedEmployee,
} from '../../models';
import { EmployeeService } from '../../services';
import {
  handleError,
  loadSupervisorEmployees,
  reviseSupervisorEmployees,
  storeSupervisorEmployees,
  updateSupervisorEmployees,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadSupervisorEmployees$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadSupervisorEmployees.type),
    switchMap((action: { payload: { empNo: string; }; }) => {
      return from(
        EmployeeService.getSupervisedEmployees(action.payload.empNo),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: SupervisedEmployee[]) => { return storeSupervisorEmployees(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateSupervisorEmployee$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateSupervisorEmployees.type),
    switchMap((action: { payload: RealignSupervisedEmployee; }) => {
      return from(EmployeeService.putSupervisedEmployees(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<RealignSupervisedEmployee>) => { return reviseSupervisorEmployees(res.value.employees); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadSupervisorEmployees$,
  updateSupervisorEmployee$,
];
