import React, { useEffect, useState, CSSProperties, Fragment } from 'react';
import { FormMethods } from 'core/components/form-controls/types';
import styles from '../../styles.module.scss';
import { SelectGrp, InputGrpCurrency, InputGrpInLine } from 'core/components/form-controls';
import { PrevailingWage } from 'core/models/Contractor.model';
import { ArrayField, useFieldArray } from 'react-hook-form';
import { convToDateString, currencyFormatter, fromCurrency } from 'utilities/utilities';
import { useAppSelector } from 'utilities/hooks';
import ExpandGroup from 'core/components/shared/dm-table/ExpandGroup';
import _, { Dictionary } from 'lodash';
import { ColumnHeaderData, Row } from 'core/components/shared/dm-table/types';
import { RadioGrp, RadioOptions } from 'core/components/form-controls/RadioGrp';
import { dateToString } from 'utilities/classUtils';
import DatePickerGrpInLine from 'core/components/shared/DatePickerGrpInLine';

const sourceDropdownOpts = [
  { value: 1, description: 'Federal' },
  { value: 2, description: 'State' },
];

const buildTradeRow = (trade: Partial<ArrayField<PrevailingWage, 'id'>>): Row => {
  return {
    id: `${trade.prevailingWageTradeNameId}-${convToDateString(trade.effectiveDate ?? null)}-${trade.sourceId}-${trade.rate}-${trade.fringeRate}`,
    cells: [
      { children: convToDateString(trade.effectiveDate ?? null), styleClass: 'td' },
      { children: currencyFormatter(trade.rate ?? 0), styleClass: 'td' },
      { children: currencyFormatter(trade.fringeRate ?? 0), styleClass: 'td' },
      { children: trade.sourceId, styleClass: 'td' },
      { children: trade?.craftMatching ?? '', styleClass: 'td' },
    ],
  };
};

const sortOptions: RadioOptions[] = [
  { value: 'asc', label: 'Ascending' },
  { value: 'desc', label: 'Descending' },
];

const tradeDetailColumns: ColumnHeaderData[] = [
  { title: 'Effective Date', styleClass: 'th' },
  { title: 'Wage Rate', styleClass: 'th' },
  { title: 'Wage Fringe Rate', styleClass: 'th' },
  { title: 'Source', styleClass: 'th' },
  { title: 'Craft Matching', styleClass: 'th' },
];

type TradeArrayField = Partial<ArrayField<PrevailingWage, 'id'>>;
type DictArrayField = Dictionary<TradeArrayField[]>;
type RenderedKeyValueObject = {
  trade: string;
  items: Partial<ArrayField<PrevailingWage, 'id'>>[];
};

type Props = {
  register: FormMethods['register'];
  control: FormMethods['control'];
  jobNo: string;
};

const PrevailingWagesTab = ({ register, control, jobNo }: Props) => {
  const clientNo = useAppSelector(({ client }) => client.client?.clientNo);
  const tradeNameDropDown = useAppSelector(({ dropdown }) => dropdown.prevailingWageTradeName);
  
  const [sortSelection, setSortSelection] = useState<'asc' | 'desc'>('asc');
  const [groupedTrades, setGroupedTrades] = useState<RenderedKeyValueObject[]>();
  const [selectedTradeId, setSelectedTradeId] = useState(+(tradeNameDropDown?.[0]?.id ?? 0));
  const [newTradeState, setNewTradeState] = useState<PrevailingWage>({
    clientNo: clientNo ?? 0,
    jobNo: jobNo,
    prevailingWageTradeNameId: +(tradeNameDropDown?.[0]?.id ?? 0),
    effectiveDate: new Date(),
    rate: 0,
    fringeRate: 0,
    sourceId: 'Federal',
    craftMatching: '',
  });
  
  const { fields, append, remove } =
    useFieldArray<PrevailingWage>({
      control,
      name: 'prevailingWages',
      keyName: 'id',
    });
  
  const tradeGroupReactiveStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
  };
  
  useEffect(() => {
    setSortSelection('asc');
  }, []);  
  
  useEffect(() => {
    groupTrades();
  }, [fields.length]);

  // this is curried so usage is like someArray.sort(sortTrades('asc')) and the next 2 arguments are passed implicitly
  const sortTrades = (direction: 'asc' | 'desc') => (trade1: RenderedKeyValueObject, trade2: RenderedKeyValueObject) => {
    const beforeCriterion = direction === 'asc' ? -1 : 1; // if ascending, sort a before b (see cases below)
    const afterCriterion = direction === 'asc' ? 1 : -1; // if ascending, sort a after b
  
    if (trade1.trade < trade2.trade) return beforeCriterion;
    if (trade1.trade > trade2.trade) return afterCriterion;
  
    return 0;
  };
  
  const groupTrades = () => {
    // first, we group by each trade (none of these items have Ids.)
    const groupByTrade: DictArrayField = _.groupBy(fields, (e) =>
      tradeNameDropDown?.find((_trade) =>
        _trade.id === String(e.prevailingWageTradeNameId))?.description ?? e.prevailingWageTradeNameId);
    const renderedPairs: RenderedKeyValueObject[] = [];

    // next, convert the object to array of key-value pairs, the key being the trade group and the value its trades
    for (const [key, value] of Object.entries(groupByTrade)) {
      renderedPairs.push({ trade: key, items: value });
    }
    
    setGroupedTrades(renderedPairs);
  };
  
  const resetTradeState = () => {
    setNewTradeState({
      clientNo: clientNo ?? 0,
      jobNo: jobNo,
      prevailingWageTradeNameId: selectedTradeId,
      effectiveDate: new Date(),
      rate: 0,
      fringeRate: 0,
      sourceId: 'Federal',
      craftMatching: '',
    });
  };
  
  const addNewTrade = () => {
    if (!clientNo) return console.error('No client no. in state');
    append(newTradeState);
    resetTradeState();
  };
  
  const deleteTrade = (id: string) => {
    const matchingTrade = fields.findIndex((trade) => `${trade.prevailingWageTradeNameId}-${convToDateString(trade.effectiveDate ?? null)}-${trade.sourceId}-${trade.rate}-${trade.fringeRate}` === id);
    if (matchingTrade < 0) return console.error('Not found in field array');
    remove(matchingTrade);
  };
  
  const updateTradeState = (key: keyof PrevailingWage, newVal: string | number | Date, convCurrency?: boolean) => {
    setNewTradeState((prevState) => {
      return {
        ...prevState,
        [key]: convCurrency ? fromCurrency(String(newVal)) : newVal,
      };
    });
  };
  
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case 'asc':
        setSortSelection('asc');
        break;
      case 'desc':
        setSortSelection('desc');
        break;
    }
  };
  
  return (
    <div className={styles['prevailing-wage-container']}>
      <div className={styles['add-trade']}>
        <div className={styles['section-header']}>Add New Trade</div>
        <div className="d-flex">
          <div className={styles['label-column']}>
            <span style={{ height: '24px' }}>Trade Name:</span>
            <span style={{ height: '24px' }}>Effective Date:</span>
            <span style={{ height: '24px' }}>Source:</span>
            <span style={{ height: '24px' }}>Prevailing Wage Rate:</span>
            <span style={{ height: '24px' }}>Prevailing Wage Fringe Rate:</span>
            <span style={{ height: '24px' }}>Craft Matching:</span>
          </div>
          <div className={styles['input-column']}>
            <SelectGrp
              groupClass="m-0"
              name="tradeNameId"
              label=""
              groupStyle={{ height: '24px' }}
              options={tradeNameDropDown}
              onChange={(e: any) => {
                updateTradeState('prevailingWageTradeNameId', e.target.value);
                setSelectedTradeId(+e.target.value);
              }}
            />
            <DatePickerGrpInLine
              name="effectiveDate"
              groupClass="m-0"
              groupStyle={{ height: '24px' }}
              inputClass={styles['date-picker']}
              value={newTradeState.effectiveDate}
              onChange={(newDate: Date) => {
                updateTradeState('effectiveDate', newDate);
              }}
            />
            <SelectGrp
              name="sourceId"
              label=""
              groupClass="m-0"
              groupStyle={{ height: '24px' }}
              options={sourceDropdownOpts}
              onChange={(e: any) => {
                updateTradeState('sourceId', e.target.value);
              }}
            />
            <InputGrpCurrency 
              groupClass="m-0"
              groupStyle={{ height: '24px' }}
              defaultValue={currencyFormatter(newTradeState.rate)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateTradeState('rate', e.target.value, true);
              }}
              onBlur={(e: any) => {
                e.target.value = currencyFormatter(e.target.value);
              }}
            />
            <InputGrpCurrency 
              groupClass="m-0"
              groupStyle={{ height: '24px' }}
              defaultValue={currencyFormatter(newTradeState.fringeRate)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateTradeState('fringeRate', e.target.value, true);
              }}
              onBlur={(e: any) => {
                e.target.value = currencyFormatter(e.target.value);
              }}
            />
            <InputGrpInLine
              name="craftMatchingState"
              groupClass="m-0"
              groupStyle={{ height: '24px', width: '315px' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateTradeState('craftMatching', e.target.value);
              }}
            />
            <button
              className="orange-button-sm align-self-end"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addNewTrade();
              }}
            >
              Add New Trade
            </button>
          </div>
        </div>
      </div>
      <div className={styles['existing-trades']}>
        <span className={styles['section-header']}>Trades</span>
        <RadioGrp
          name="sort-trades-by"
          label="Sort by"
          controlled
          radioOptions={sortOptions}
          checked={sortSelection}
          onChange={handleRadioChange}
        />
        <div
          style={tradeGroupReactiveStyles}
        >
          {/* The rendered fields */}
          {groupedTrades
            ?.sort(sortTrades(sortSelection))
            ?.map((tradeGroup: RenderedKeyValueObject) => {
              const rows: Row[] = tradeGroup.items?.map((trade: Partial<ArrayField<PrevailingWage, 'id'>>) => buildTradeRow(trade));

              return (
                <ExpandGroup
                  key={tradeGroup.trade}
                  trade={tradeGroup.trade}
                  rows={rows}
                  columns={tradeDetailColumns}
                  deleteRow={(id: string) => { deleteTrade(id); }}
                />
              );
            })}
          {/* The hidden fields tracked by the form. Without each of these, the property won't be sent when saving. */}
          {fields
            .map((trade: TradeArrayField, index: number) => {
              return (
                <Fragment key={trade.id}>
                  <input
                    name={`prevailingWages[${index}].clientNo`}
                    defaultValue={trade.clientNo}
                    type="hidden"
                    ref={register({ valueAsNumber: true })}
                  />
                  <input
                    name={`prevailingWages[${index}].craftMatching`}
                    defaultValue={trade?.craftMatching}
                    type="hidden"
                    ref={register()}
                  />
                  <input
                    name={`prevailingWages[${index}].jobNo`}
                    defaultValue={jobNo}
                    type="hidden"
                    ref={register()}
                  />
                  <input
                    name={`prevailingWages[${index}].prevailingWageTradeNameId`}
                    defaultValue={trade.prevailingWageTradeNameId}
                    type="hidden"
                    ref={register({ valueAsNumber: true })}
                  />
                  <input
                    name={`prevailingWages[${index}].effectiveDate`}
                    defaultValue={dateToString(trade.effectiveDate ?? new Date()) ?? ''}
                    type="hidden"
                    ref={register()}
                  />
                  <input
                    name={`prevailingWages[${index}].rate`}
                    defaultValue={trade.rate}
                    type="hidden"
                    ref={register({ valueAsNumber: true })}
                  />
                  <input
                    name={`prevailingWages[${index}].fringeRate`}
                    defaultValue={trade.fringeRate}
                    type="hidden"
                    ref={register({ valueAsNumber: true })}
                  />
                  <input
                    name={`prevailingWages[${index}].sourceId`}
                    defaultValue={trade.sourceId}
                    type="hidden"
                    ref={register()}
                  />
                </Fragment>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default PrevailingWagesTab;