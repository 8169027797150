import Icon from 'core/components/shared/Icon';
import PanelHeader from 'core/components/shared/PanelHeader';
import { InterviewQuestion } from 'core/models';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { Row, Col } from 'react-bootstrap';
import InterviewQuestionModal from './InterviewQuestion.modal';
import { AgGridReact } from '@ag-grid-community/react';
import { GridApi, ICellRendererParams, ColDef, GridOptions } from '@ag-grid-community/core';
import { getInterviewQuestions, deleteInterviewQuestion } from 'core/store/slices/applicantTracking.slice';

const InterviewQuestionsPage = () => { 
  const dispatch = useAppDispatch();
  const [interviewQuestions] = useAppSelector(
    (state) => {
      return [state.applicantTracking.interviewQuestions];
    },
  );

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [rowData, setRowData] = useState<InterviewQuestion[]>([]);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<InterviewQuestion>();

  useEffect(()=>{
    dispatch(getInterviewQuestions());
  }, []);

  useEffect(()=>{
    interviewQuestions && setRowData(interviewQuestions);
  }, [interviewQuestions]);
  
  const onAdd = () => {
    setSelectedQuestion(undefined);
    setShowDetailModal(true);
  };

  const openDetailModal = (params: ICellRendererParams) =>{
    setSelectedQuestion(params.data);
    setShowDetailModal(true);
  };
  
  const onDeleteQuestion = (params: ICellRendererParams) =>{
    dispatch(deleteInterviewQuestion(params.data.interviewQuestionId));
  };

  const columns = useMemo<ColDef[]>(
    () => {
      return [
        {
          field: 'title',
          headerName: 'Question Name',
          cellStyle: { textAlign: 'left' },
          cellRenderer: (params: ICellRendererParams) => {
            if (params.data.isDefault) {
              return (<div style={{ width: 250 }}>{params.data.title}</div>);
            } else {   
              return (
                <button
                  type="button"
                  className="btn btn-link"                  
                  style={{ textAlign:'left' }}
                  onClick={() => { return openDetailModal(params); }
                  }
                >
                  {params.data.title}
                </button>             
              );
            }
          },
          width: 250,
        },    
        {
          field: 'description',
          headerName: 'Question Description',         
          width: 950,         
        },     
        {
          field: 'delete',
          cellRenderer: (params: ICellRendererParams) => {
            if (params.data.isDefault) {
              return (<span></span>);
            } else {               
              return (
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => { return onDeleteQuestion(params); }
                  }
                >
                  <Icon
                    name="minus-circle"
                    className="fa-minus-circle"
                  />
                </button>             
              );
            }
          },
          width: 70,
        },     
      ];
    },
    [],
  );

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    stopEditingWhenCellsLoseFocus:true,
    undoRedoCellEditing:true,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onHideChange  = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      setRowData([ ...interviewQuestions.filter((i:InterviewQuestion) => {return  i.isDefault !== true;}) ]);
    } else {
      setRowData([ ...interviewQuestions ]);
    }    
  };

  return (
    <Row className="no-gutters p-0">
      <Col className="mt-1 shadow p-3 border">
        <PanelHeader
          title="Interview Question"
          iconName="compass-drafting" 
          hideDefault={true}
          onHideChange={(e:any) => {return onHideChange(e);}}        
        > 
          <button
            type="button"
            className="btn btn-link dm-grid-action-title pb-0 mr-2"
            onClick={onAdd}
          >
            Add New Interview Question <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <div className="table-wrapper-wrapper ag-theme-balham mt-3">
          <AgGridReact
            gridOptions={gridOptions}
            rowData={rowData}
            columnDefs={columns}
            onGridReady={onGridReady}
          />
        </div>
        {showDetailModal && (
          <InterviewQuestionModal
            item={selectedQuestion}
            show={showDetailModal}
            onHide={() => { return setShowDetailModal(false); } }            
          />
        )}
      </Col>
    </Row>
  );
};

export default InterviewQuestionsPage;
