import React from 'react';
import { useAppSelector } from 'utilities/hooks';
import { Col, Row } from 'react-bootstrap';
import GridSelect from '../../Shared/GridSelect';
import { EEDComponentProps } from 'types/eed.types';

// Custom Layout for Checkbox and Grid select forms
const TrainingLicensesFields = ({ formMethods, sectionFields }: EEDComponentProps) => {
  const licensesOptions = useAppSelector((state) => { return state?.dropdown.licenseRenewalTypes; });

  return (
    <Row>
      {!!sectionFields?.length && (
        <Col>
          <GridSelect
            section="Training Licenses"
            title="Training Licenses"
            dFields={sectionFields.find(f => {
              return f.name === 'Training Licenses';
            })?.fields}
            options={licensesOptions}
            hasCodeColumn={false}
            isCodeField={false}
            formMethods={formMethods}
          />
        </Col>
      )}
    </Row>
  );
};

export default TrainingLicensesFields;