import { AgGridReact } from '@ag-grid-community/react';
import { GridApi, ColDef, GridOptions, RowSelectedEvent } from '@ag-grid-enterprise/all-modules';
import { CheckboxGrpInLine, InputGrp } from 'core/components/form-controls';
import Icon from 'core/components/shared/Icon';
import { Dropdown } from 'core/models';
import { UnionDuesSettingsRequest } from 'core/models/PayrollReports.model';
import { createUnionDuesSettings, deleteUnionDuesSettings, updateUnionDuesSettings } from 'core/store/actions';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'utilities/hooks';




type PropTypes = {
  setting?: UnionDuesSettingsRequest;
  show: boolean;
  onHide: () => void;
};

const UnionDuesSettingsModal: React.FC<PropTypes> = ({ setting, show, onHide }) => {
  const dispatch = useDispatch();
  const earningCodes = useAppSelector((state) => { return state.dropdown.earningsCode; });
  const client = useAppSelector((state) => { return state.client.client; });
  const user = useAppSelector((state) => { return state.auth.userAccess; });
  const [selectedSetting, setSelectedSetting] = useState<UnionDuesSettingsRequest>();

  const [selectedCodes, setSelectedCodes] = useState<Dropdown[]>();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const { register, handleSubmit,
  } = useForm<UnionDuesSettingsRequest>({
    defaultValues: setting,
  });

  useEffect(() => {
    setting && setSelectedSetting(setting);
  }, [setting]);

  const columns: ColDef[] = [
    {
      field: 'select',
      headerName: '',
      editable: false,
      checkboxSelection: true,
      width: 40,
    },
    {
      field: 'id',
      headerName: 'Code',
      width: 60,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 180,
    },
  ];

  const gridOptions: GridOptions = {
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    columnDefs: columns,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (selectedSetting && gridApi) {
      gridApi?.forEachNode((node) => {
        if (selectedSetting.earnCodeList?.split(',').find(id => { return id === node.data.id; })) {
          node.setSelected(true);
        }
      });
    }
  }, [selectedSetting, gridApi]);

  const onSubmit = (data: UnionDuesSettingsRequest) => {

    const setting: UnionDuesSettingsRequest = {
      settingID: data.settingID || 0,
      settingName: data.settingName,
      clientID: client?.clientID!,
      earnCodeList: selectedCodes?.map(c => { return c.id; })?.join(',') || '',
      modifyDate: DateTime.local().toISODate(),
      modifyUser: user?.dmUserName || '',
    };

    setting.settingID ?
      dispatch(updateUnionDuesSettings(setting)) :
      dispatch(createUnionDuesSettings(setting));
  };

  const onDelete = () => {
    selectedSetting && dispatch(deleteUnionDuesSettings(selectedSetting));
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      selectedRows && setSelectedCodes(selectedRows);
    }
  };

  const onClearSelections = () => {
    gridApi?.forEachNode((node) => {
      node.setSelected(false);
    });
  };

  const changeAllEarnings = () => {
    gridApi?.forEachNode((node) => {
      node.setSelected(true);
    });
  };

  const onRowClicked = (e: RowSelectedEvent) => {
    e && e.node.setSelected(e.node.isSelected() === true ? true : false);
  };

  const closeModal = (e?: any) => {
    e?.stopPropagation();
    onHide();
  };

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal show={show}
        onHide={onHide}
        size="sm"
        animation={false}>
        <div className="modal-header" >
          <div className="dm-grid-action-title mb-2">Setting Options</div>
          <button type="button"
            onClick={closeModal}>
            <Icon name="times"
              className="modal-close-btn" />
          </button>
        </div>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="hidden"
              name={'settingID'}
              ref={register()}
              defaultValue={selectedSetting?.settingID}
            />
            <div className="d-flex mt-2">
              <div className="d-flex flex-row flex-grow-1 ">
                <div className="d-flex flex-column mr-4">
                  <InputGrp
                    label="Setting Name"
                    name="settingName"
                    ref={register()}
                  />


                </div>
                <div className="d-flex flex-column flex-grow-1">
                  <div className="dm-grid-action-title mb-2">Earnings</div>
                  <div>
                    <div className="d-flex flex-row">
                      <CheckboxGrpInLine
                        name="allEarnings"
                        label="All Earnings"
                        onChange={changeAllEarnings}
                        ref={register()}
                      />
                      <button
                        type="button"
                        className="btn btn-link mb-2"
                        onClick={onClearSelections}>Clear Selections</button>
                    </div>
                  </div>
                  <div className="ag-theme-balham mt-2"
                    style={{ height: '220px' }}>
                    <AgGridReact
                      gridOptions={gridOptions}
                      rowData={earningCodes}
                      onGridReady={onGridReady}
                      onRowClicked={onRowClicked}
                      onSelectionChanged={onSelectionChanged}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-grow-1 text-right mt-4">
              <button
                type="button"
                className="btn btn-primary orange-outline-button ml-auto mr-2"
                onClick={() => { onDelete(); onHide(); }}
                disabled={!selectedSetting?.settingID}
              >
                Delete
              </button>
              <button type="submit"
                className="orange-button mr-2">
                Save
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div >);
};

export default UnionDuesSettingsModal;