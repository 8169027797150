import React, { useEffect, useState, CSSProperties } from 'react';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import QuickBooksExportResult from 'core/models/intuit/quickBooksExportResult.model';
import { handleSuccess, handleError, clearQuickBooksExportResultAndFileData,  } from 'core/store/actions';

const useQuickBooksExportResult = (setShowAlertModal: (showAlert: boolean) => void): QuickBooksExportResult | null => {
    const dispatch = useAppDispatch();
    const quickBooksExportResult = useAppSelector((state) => state.intuit.quickBooksExportResult);

    useEffect(() => {
        if (!quickBooksExportResult) return;

        if (!quickBooksExportResult.success) {

            if (quickBooksExportResult.accountMismatches.length > 0) {
                setShowAlertModal(true);
                return;
            }

            dispatch(handleError(quickBooksExportResult.message));
            dispatch(clearQuickBooksExportResultAndFileData());
            return;
        }

        dispatch(handleSuccess(quickBooksExportResult.message));
        dispatch(clearQuickBooksExportResultAndFileData());
    }, [quickBooksExportResult]);

    return quickBooksExportResult;
}

export default useQuickBooksExportResult;