import { AxiosResponse } from 'axios';
import { HttpResponse, RecurringEarnings } from '../models';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { EmployeeEndPoints } from './URL';

const getRecurringEarnings = (protectedEmpNo: string): Promise<AxiosResponse<RecurringEarnings[]>> => { return API_EMPLOYEE_MASTER.get<RecurringEarnings[]>(EmployeeEndPoints.GET_RECURRING_EARNINGS(CommonService.getProtectedClientNo(), protectedEmpNo)); };

const postRecurringEarning = (protectedEmpNo: string, data: RecurringEarnings[]): Promise<AxiosResponse<HttpResponse<RecurringEarnings[]>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<RecurringEarnings[]>>(EmployeeEndPoints.POST_RECURRING_EARNING(CommonService.getProtectedClientNo(), protectedEmpNo), data); };

const deleteRecurringEarning = (data: RecurringEarnings): Promise<AxiosResponse<RecurringEarnings>> => { return API_EMPLOYEE_MASTER.delete<RecurringEarnings>(EmployeeEndPoints.DELETE_RECURRING_EARNGING(CommonService.getProtectedClientNo(), data.protectedEmpNo, data.recurringId)); };

export const RecurringEarningsService = {
  getRecurringEarnings,
  postRecurringEarning,
  deleteRecurringEarning,
};
