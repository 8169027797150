import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AddPasswordToPDF, HttpResponse, PayStub } from '../../models';
import { EmployeeService } from '../../services';
import {
  handleError,
  loadPayStubPDF,
  loadPayStubs,
  storeAddPasswordToPDF,
  storePayStubPDF,
  storePayStubs,
  updateAddPasswordToPDF,
} from '../actions';

interface Actions {
  type: string;
  payload: any;
}

const loadPayStubs$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadPayStubs.type),
    switchMap((action: { payload: string; }) => {
      return from(EmployeeService.getPayStubs(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: PayStub[]) => { return storePayStubs(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadPayStubPDF$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(loadPayStubPDF.type),
    switchMap((action: { payload: PayStub; }) => {
      return from(EmployeeService.getPayStubPDF(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storePayStubPDF(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updateAddPasswordToPDF$ = (action$: Observable<Actions>) => {
  return action$.pipe(
    ofType(updateAddPasswordToPDF.type),
    switchMap((action: { payload: AddPasswordToPDF; }) => {
      return from(EmployeeService.putAddPasswordToPDF(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storeAddPasswordToPDF(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

export const epics: any[] = [
  loadPayStubs$,
  loadPayStubPDF$,

  updateAddPasswordToPDF$,
];
