import { ICellEditorParams } from '@ag-grid-community/core';
import { convDateTimeToDate } from '../utilities';

interface agDateEditorParams extends ICellEditorParams {
  minField?: string;
  maxField?: string;
}

export function agDateEditor(): any {}

agDateEditor.prototype.init = function (params: agDateEditorParams) {
  this.dateInput = document.createElement('input');
  this.dateInput.type = 'date';
  params.minField &&
        (this.dateInput.min = convDateTimeToDate(params.data[params.minField]));
  params.maxField &&
        (this.dateInput.max = convDateTimeToDate(params.data[params.maxField]));

  this.dateInput.value = params.value && convDateTimeToDate(params.value);
};

agDateEditor.prototype.getGui = function () {
  return this.dateInput;
};

agDateEditor.prototype.getValue = function () {
  return this.dateInput.value;
};

agDateEditor.prototype.destroy = function () {};

agDateEditor.prototype.isPopup = function () {
  return false;
};
