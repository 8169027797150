import { ClientFolderRequest, ClientLibraryRequest } from 'core/models/ClientLibrary.model';
import { ClientLibraryEndpoints } from './URL';
import { AxiosResponse } from 'axios';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';

const getClientLibrary = ( year: number): Promise<AxiosResponse<any>> => {
  return API_EMPLOYEE_MASTER.get<ClientLibraryRequest[]>(
    ClientLibraryEndpoints.GET_CLIENT_LIBRARY(
      CommonService.getProtectedClientNo(), year));
};



const getClientFolder = (folderPath: string): Promise<AxiosResponse<any>> => {
  return API_EMPLOYEE_MASTER.get<ClientFolderRequest[]>(
    ClientLibraryEndpoints.GET_CLIENT_FOLDER(
      CommonService.getProtectedClientNo(), folderPath ));
};


export const ClientLibraryService = {
  getClientLibrary,
  getClientFolder,
};