import React, { useEffect, useState } from 'react';
import Modal from 'core/components/modals/Modal';
import PayRateItem from './PayRateItem';
import { EmployeePayRate } from 'core/models';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import SaveButton from 'core/components/shared/SaveButton';
import { getSelectedEmp } from 'core/store/selectors';
import { useSelector } from 'react-redux';
import { handleError } from 'core/store/actions';
import { fromCurrency } from 'utilities/utilities';
import { EmpPayRateWorkFlow, WorkFlowTask } from 'core/models/WorkFlow.model';
import { postWorkFlowTask } from 'core/store/actions/work-flow.action';

type PropTypes = {
  show: boolean;
  onHide: () => void;
  selectedPayRate: EmployeePayRate;
  handleUpdateDirtyState: (newVal: boolean) => void;
  onDelete: (item: EmployeePayRate) => void;
};

const PayRateWorkFlowModal: React.FC<PropTypes> = ({ show, onHide, selectedPayRate, handleUpdateDirtyState, onDelete }) => {

  const [payRateState, setPayRateState] = useState<EmployeePayRate>(selectedPayRate);
  const sectionAccess = useAppSelector((state) => {return state.app.moduleAccess?.employeeMasterSections;});
  const showWorkTaskSuccessModal = useAppSelector((state) => { return state.workFlow.showWorkTaskSuccessModal; });
  const selEmp = useSelector(getSelectedEmp);
  const client = useAppSelector((state) => { return state.client.client; });
  const dispatch = useAppDispatch();
    
  const updatePayRate = (item: EmployeePayRate) => {              
    setPayRateState(item);
    handleUpdateDirtyState(true);
  };

  //If the success modal is shown hide the pay rate modal.
  useEffect(() => {
    if (showWorkTaskSuccessModal) return onHide();
  }, [showWorkTaskSuccessModal]);
      
  //Right now we just use the main delete from the currentPayRate.tsx as deleting does not require a workFlow. Update this to add a workflow instead of use this delete.
  const onDeleteWorkFlow = () => {
    onDelete(selectedPayRate);
    return onHide();
  };

  const onSubmit = () => {
    if (!payRateState) return dispatch(handleError('Error saving pay rates'));
    if (!(selEmp && client?.clientNo && client?.clientPayrollFreq)) return dispatch(handleError('Error with employee/client when saving'));
           
    const payRateStateCopy: EmployeePayRate = {
      ...payRateState,
      hourlyRate: (payRateState.hourlyRateNotRounded) ? +payRateState.hourlyRateNotRounded : fromCurrency(payRateState.hourlyRate?.toString()) ?? 0,
      salaryRate: (payRateState.salaryRateNotRounded) ? +payRateState.salaryRateNotRounded : fromCurrency(payRateState.salaryRate?.toString()) ?? 0,
      annualRate: (payRateState.annualRateNotRounded) ? +payRateState.annualRateNotRounded : fromCurrency(payRateState.annualRate?.toString()) ?? 0,
      rateId: (payRateState.rateId > 0) ? payRateState.rateId : 0,
    };
      
    const change: EmpPayRateWorkFlow = {
      RateId: payRateStateCopy.rateId,
      EmpId: payRateStateCopy.empId,
      ClientNo: +payRateStateCopy.clientNo,
      EmpNo: payRateStateCopy.empNo,
      HourlyRate: payRateStateCopy.hourlyRate,
      SalaryRate: payRateStateCopy.salaryRate,
      AnnualRate: payRateStateCopy.annualRate,
      TranDigit: payRateStateCopy.tranDigit,
      Location: payRateStateCopy.location,
      Department: payRateStateCopy.department,
      SubDepartment: payRateStateCopy.subDepartment,
      SubDepartment2: payRateStateCopy.subDepartment2,
      Description: payRateStateCopy.description,
      Notes: payRateStateCopy.notes,
      Area: payRateStateCopy.area,
      Trade: payRateStateCopy.trade,
      Sub: payRateStateCopy.sub,
      OverScale: payRateStateCopy.overScale,
      Clock: payRateStateCopy.clock,
      OriginalRateId: 0,
      EffectiveDate: null,
    };
    const changeJson = JSON.stringify(change);

    const request: WorkFlowTask = {
      workFlowType: 1,
      targetEmpId: selEmp.empId,
      change: changeJson,
      protectedEmpNo: selEmp.protectedEmpNo,
    };
      
    dispatch(postWorkFlowTask(request));
    handleUpdateDirtyState(false);
  };

  return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        title="Manage Workgroups Pay Rate"
        size="lg"
      >
        <PayRateItem
          payPeriodState={null}
          baseValue={undefined}
          payRate={payRateState}
          index={0}
          updatePayRate={updatePayRate}
          updateDirtyState={handleUpdateDirtyState} 
          usesPayRateWorkFlow={false}  
          openFromModal={true}    
          onDelete={onDeleteWorkFlow} 
          setSelectedPayRate={() => {}}
          setShowPayRateWorkFlow={() => {}}
        />
        <div className="text-right mt-3">
          <SaveButton
            saving={false}
            classes="btn orange-button mr-3"
            onClick={onSubmit}
            disabled={false}
          />
        </div>
      </Modal>
    </div >
  );
};

export default PayRateWorkFlowModal;
