import { createSelector } from 'reselect';
import { CheckCode } from '../../models';
import { checkCodeState } from '../reducers';
import { State as CheckCodeState } from '../reducers/check-code.reducer';

export const getCheckCodes = createSelector(
  checkCodeState,
  (state: CheckCodeState) => {return state.checkCode;},
);

export const getCheckCodesForDropdown = createSelector(
  checkCodeState,
  (state: CheckCodeState) => {
    const res = [{}];
    state.checkCode.forEach((cc: CheckCode, index: number) => {
      res.push({ id: cc.checkCode });
    });
    return state.checkCode;
  },
);
