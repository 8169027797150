import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, switchMap, filter } from 'rxjs/operators';
import { IntuitService } from 'core/services/intuit.service';
import { HttpResponse } from '../../models';
import { handleError, handleSuccess } from '../actions';
import IntuitAuth from 'core/models/intuit/intuitAuth.model';
import QuickBooksConnection from 'core/models/intuit/quickBooksConnection.model';
import ConnectionOption from 'core/models/intuit/connectionOption.model';
import QuickBooksExportResult from 'core/models/intuit/quickBooksExportResult.model';
import AccountMismatch from 'core/models/intuit/accountMismatch.model';
import { 
    loadIntuitAuth, 
    storeIntuitAuth, 
    manageQuickBooksConnection, 
    storeQuickBooksConnection, 
    exportToQuickBooks, 
    storeQuickBooksExportResult, 
    generateChartOfAccounts, 
    storeChartOfAccounts, 
    handleExportToQuickBooksError,
    handleGenerateChartOfAccountsError,
    handleIntuitAuthError, 
    handleQuickBooksConnectionError
} from '../actions/intuit/intuit.actions';

interface Actions {
    type: string;
    payload: any;
}

const loadIntuitAuth$ = (action$: Observable<Actions>) => {
    return action$.pipe(
        ofType(loadIntuitAuth.type),
        switchMap(() => {
            return from(IntuitService.getClientIntuitAuth()).pipe(
                map((res: any) => { return res.data; }),
                map((res: IntuitAuth) => { return storeIntuitAuth(res); }),
                catchError((err: HttpResponse<any>) => { return [handleIntuitAuthError(), handleError(err)]; }),
            );
        },
        ),
    );
};

const manageQuickBooksConnection$ = (action$: Observable<Actions>) => {
    return action$.pipe(
        ofType(manageQuickBooksConnection.type),
        switchMap((action: { payload: ConnectionOption; }) => {
            return from(IntuitService.putPayRate(action.payload)).pipe(
                map((res: any) => { return res.data; }),
                switchMap((res: HttpResponse<QuickBooksConnection>) => {
                    return [
                        storeQuickBooksConnection(res.value),
                        // handleSuccess(res.messages),
                    ];
                }),
                catchError((err: HttpResponse<any>) => { return [handleQuickBooksConnectionError(), handleError(err)]; }),
            );
        },
        ),
    );
};

const exportToQuickBooks$ = (action$: Observable<Actions>) => {
    return action$.pipe(
        ofType(exportToQuickBooks.type),
        switchMap((action: { payload: QuickBooksExportResult}) => {
            return from(IntuitService.postExportToQuickBooks()).pipe(
                map((res: any) => { return res.data; }),
                switchMap((res: HttpResponse<QuickBooksExportResult>) => {
                    return [
                        storeQuickBooksExportResult(res.value),
                        // handleSuccess(res.messages),
                    ];
                }),
                catchError((err: HttpResponse<any>) => { return [handleExportToQuickBooksError(), handleError(err)]; }),
            );
        },
        ),
    );
};

const generateChartOfAccounts$ = (action$: Observable<Actions>) => {
    return action$.pipe(
        ofType(generateChartOfAccounts.type),
        switchMap((action: { payload: Array<AccountMismatch>}) => {
            return from(IntuitService.postChartOfAccounts(action.payload)).pipe(
                map((res: any) => { return res.data; }),
                switchMap((res: HttpResponse<string>) => {
                    return [
                        storeChartOfAccounts(res.value)
                        // handleSuccess(res.messages),
                    ];
                }),
                catchError((err: HttpResponse<any>) => { return [handleGenerateChartOfAccountsError(), handleError(err)]; }),
            );
        },
        ),
    );
};

export const epics: any[] = [loadIntuitAuth$, manageQuickBooksConnection$, exportToQuickBooks$, generateChartOfAccounts$];