import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class EmpFte {
  clientNo = 0;

  empNo = 0;

  protectedClientNo = '';

  protectedEmpNo = '';

  empId = 0;

  fteHeadId = 0;

  effectiveDate: Date | string | null = null;

  endDate: Date | string | null = null;

  reasonId = 0;

  homeBaseLocation = 0;

  careerLevelId = 0;

  salaryGradeId = 0;

  geographicLevelId = 0;

  percentages: FtePercentage[] = [];

  isNew?: boolean = false;

  static readonly convProps: PropTypeLists = {
    numberProps: ['empId', 'fteHeadId', 'homeBaseLocation', 'careerLevelId', 'salaryGradeId', 'geographicLevelId'],
    dateProps: ['effectiveDate', 'endDate'],
  };

  constructor(empId: string | number, protectedEmpNo: string, props?: { [key: string]: any }) {
    this.empId = (typeof empId === 'string') ? parseInt(empId) : empId;
    this.protectedEmpNo = protectedEmpNo;

    props && convToClass(this, props, EmpFte.convProps);
  }
}

export class FtePercentage {
  fteId = 0;

  fteHeadId = 0;

  loc = 0;

  dept = 0;

  subDept = 0;

  subDept2 = 0;

  percent = 0;

  homeFte = false;

  promotion = false;
}