import { AxiosResponse } from "axios";
import { HttpResponse } from '../models';
import { CommonService } from './common.service';
import {
  PensionReportRequest,
  PensionSetting,
  AnnualFICATipCreditReportRequest,
  CashFlowReportRequest,
  Payroll401kReportRequest,
  Payroll4070TipReportRequest,
  ScheduledDeductionsReportRequest,
  WorkersCompensationReportRequest,
  DeductionOptionsReportRequest,
  MemoCashFlowReportRequest,
  TaxReportRequest,
  EmployeeLastCheckReportRequest,
  DeductionShortagesReportRequest,
  GrossToNetRequest,
  UnionDuesSettingsRequest,
  UnionDuesReportRequest,
  MonthlyCashFlowAnalysisDownloadReport,
  CARESCreditReportRequest,
} from '../models/PayrollReports.model';
import { PayrollReportsEndpoints } from './URL';
import { API_EMPLOYEE_MASTER } from './API';

const postPayroll401kReportsTab = (request: Payroll401kReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    PayrollReportsEndpoints.POST_PAYROLL_401K_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postPayroll4070TipReportsTab = (request: Payroll4070TipReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    PayrollReportsEndpoints.POST_PAYROLL_4070TIP_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postCashFlowReportsTab = (request: CashFlowReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    PayrollReportsEndpoints.POST_CASH_FLOW_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postAnnualFICATipCreditReportsTab = (request: AnnualFICATipCreditReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    PayrollReportsEndpoints.POST_ANNUAL_FICA_TIP_CREDIT_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postScheduledDeductionsReportTab = (request: ScheduledDeductionsReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    PayrollReportsEndpoints.POST_SCHEDULED_DEDUCTIONS_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postWorkersCompensationReportTab = (request: WorkersCompensationReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    PayrollReportsEndpoints.POST_WORKERS_COMPENSATION_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postPensionReport = (data: PensionReportRequest): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(PayrollReportsEndpoints.PAYROLL_PENSION_REPORT(CommonService.getProtectedClientNo()), data); };

const getPensionSettings = (): Promise<AxiosResponse<any>> => { return API_EMPLOYEE_MASTER.get<PensionSetting[]>(PayrollReportsEndpoints.PAYROLL_PENSION_SETTING(CommonService.getProtectedClientNo())); };

const postPensionSettings = (data: PensionSetting): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(PayrollReportsEndpoints.PAYROLL_PENSION_SETTING(CommonService.getProtectedClientNo()), data); };

const putPensionSettings = (data: PensionSetting): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<any>>(PayrollReportsEndpoints.PAYROLL_PENSION_SETTING_ID(CommonService.getProtectedClientNo(), data.settingID), data); };

const deletePensionSettings = (data: PensionSetting): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.delete<HttpResponse<any>>(PayrollReportsEndpoints.PAYROLL_PENSION_SETTING_ID(CommonService.getProtectedClientNo(), data.settingID)); };

const postDeductionOptionsReport = (request: DeductionOptionsReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    PayrollReportsEndpoints.PAYROLL_DEDUCTION_OPTIONS_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postTaxReport = (request: TaxReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    PayrollReportsEndpoints.PAYROLL_TAX_REPORT(
      CommonService.getProtectedClientNo()), request);
};
const postEmployeeLastCheckReport = (request: EmployeeLastCheckReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    PayrollReportsEndpoints.POST_EMPLOYEE_LAST_CHECK_REPORT(
      CommonService.getProtectedClientNo()), request);
};


const postMemoCashFlowReport = (request: MemoCashFlowReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    PayrollReportsEndpoints.POST_MEMO_CASH_FLOW_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postDedctionShortagesReport = (request: DeductionShortagesReportRequest): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    PayrollReportsEndpoints.POST_DEDUCTION_SHORTAGES_REPORT(
      CommonService.getProtectedClientNo()), request);
};

const postGrossToNetReport = (
  data: GrossToNetRequest,
): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    PayrollReportsEndpoints.POST_GROSS_TO_NET_REPORT(CommonService.getProtectedClientNo()),
    data,
  );
};

const postUnionDuesReport = (data: UnionDuesReportRequest): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(PayrollReportsEndpoints.POST_UNION_DUES_REPORT(CommonService.getProtectedClientNo()), data); };

const getUnionDuesSettings = (): Promise<AxiosResponse<any>> => { return API_EMPLOYEE_MASTER.get<UnionDuesSettingsRequest>(PayrollReportsEndpoints.GET_UNION_DUES_SETTINGS(CommonService.getProtectedClientNo())); };

const postUnionDuesSettings = (data: UnionDuesSettingsRequest): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(PayrollReportsEndpoints.ADD_UNION_DUES_SETTINGS(CommonService.getProtectedClientNo()), data); };

const putUnionDuesSettings = (data: UnionDuesSettingsRequest): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.put<HttpResponse<any>>(PayrollReportsEndpoints.PUT_UNION_DUES_SETTINGS(CommonService.getProtectedClientNo(), data.settingID), data); };

const deleteUnionDuesSettings = (data: UnionDuesSettingsRequest): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.delete<HttpResponse<any>>(PayrollReportsEndpoints.DELETE_UNION_DUES_SETTINGS(CommonService.getProtectedClientNo(), data.settingID)); };


const postMontlyCashFlowAnalysisDownloadReport = (data: MonthlyCashFlowAnalysisDownloadReport): Promise<AxiosResponse<HttpResponse<string>>> => {
  return API_EMPLOYEE_MASTER.post<HttpResponse<string>>(
    PayrollReportsEndpoints.POST_MONTHLY_CASHFLOW_ANALYSIS_DOWNLOAD_REPORT(
      CommonService.getProtectedClientNo()), data);
};

const postCARESCreditReport = (data: CARESCreditReportRequest): Promise<AxiosResponse<HttpResponse<any>>> => { return API_EMPLOYEE_MASTER.post<HttpResponse<any>>(PayrollReportsEndpoints.POST_CARES_CREDIT_REPORT(CommonService.getProtectedClientNo()), data); };

export const PayrollReportsService = {
  postPayroll401kReportsTab,
  postPayroll4070TipReportsTab,
  postPensionReport,
  getPensionSettings,
  postPensionSettings,
  putPensionSettings,
  deletePensionSettings,
  postCashFlowReportsTab,
  postAnnualFICATipCreditReportsTab,
  postScheduledDeductionsReportTab,
  postWorkersCompensationReportTab,
  postDeductionOptionsReport,
  postTaxReport,
  postMemoCashFlowReport,
  postEmployeeLastCheckReport,
  postDedctionShortagesReport,
  postGrossToNetReport,
  postUnionDuesReport,
  postUnionDuesSettings,
  putUnionDuesSettings,
  getUnionDuesSettings,
  deleteUnionDuesSettings,
  postMontlyCashFlowAnalysisDownloadReport,
  postCARESCreditReport,

};