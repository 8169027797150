import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PanelHeader from 'core/components/shared/PanelHeader';
import {
  HRPerformanceReview,
  HRReviewRequest,
} from 'core/models/HREmployee.model';
import {
  loadHRReview,
  updateHRReview,
} from 'core/store/actions/hr-employee.action';
import { getReviews } from 'core/store/selectors/hr-employee.selector';
import ReviewItem from './ReviewsItem';
import { useForm, useFieldArray } from 'react-hook-form';
import { UNSAVED_MESSAGE } from 'core/constants';
import { getJobTitles } from 'core/store/selectors';
import { SelectGrp } from 'core/components/form-controls';
import Icon from 'core/components/shared/Icon';
import { toggleBlockNavigation } from 'core/store/actions';

const ReviewsPage = () => {

  const dispatch = useDispatch();

  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();
  const [review, setReview] = useState<HRPerformanceReview>();
  const reviewStore = useSelector(getReviews) as HRPerformanceReview;
  const jobTitleOpts = useSelector(getJobTitles);


  useEffect(() => {
    protectedEmpNo && dispatch(loadHRReview(protectedEmpNo));
  }, [protectedEmpNo]);

  useEffect(() => {
    reviewStore && setReview(reviewStore);
  }, [reviewStore]);

  const {
    reset,
    control,
    handleSubmit,
    formState,
    ...formMethods
  } = useForm<HRPerformanceReview>({
    defaultValues: review,
  });

  const { fields, prepend, remove } =
    useFieldArray({
      control,
      name: 'performanceReviews',
      keyName: 'id',
    });


  useEffect(() => {
    review && reset(review);
  }, [review]);

  const addReview = () => {
    prepend({
      empReviewId: 0,
      evaluatorId: 0,
    });
  };

  const deleteReview = (index: number) => {
    remove(index);
  };

  const onSubmit = (data: HRPerformanceReview, e?: React.BaseSyntheticEvent) => {
    if (!e?.target.isConnected) {
      return false;
    }

    const request: HRReviewRequest = {
      protectedEmpNo,
      data: {
        ...data,
        performanceReviews: data.performanceReviews.map(p => {
          return {
            ...p,
            empReviewId: +p.empReviewId,
            nextEvaluateDate: p.nextEvaluateDate !== '' ? new Date(p.nextEvaluateDate!).toISOString() : null,
            dateOfEvaluation: p.dateOfEvaluation !== '' ? new Date(p.dateOfEvaluation!).toISOString() : null,
          };
        }),
      },
    };

    dispatch(updateHRReview(request));
  };

  const onCancel = () => {
    reset();
  };

  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: formState.isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [formState.isDirty]);
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <SelectGrp
          groupClass="gc12 mw125"
          label={'JOB TITLE'}
          name={'jobTitleId'}
          dropdownName="JobTitle"
          options={jobTitleOpts}
          control={control}
          ref={formMethods.register({ valueAsNumber: true })}
          defaultValue={review?.jobTitleId}
        />
        <div className="dm-panel dm-panel-border" >
          <PanelHeader title="Review" >
            <button
              type="button"
              className="btn btn-link dm-grid-action-title p-0 mr-2"
              onClick={addReview}
            >
              Add Review	<Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          </PanelHeader>

          {fields?.map((item, index) => {
            return (
              <div key={item.id} >
                <ReviewItem
                  item={item}
                  index={index}
                  control={control}
                  formMethods={formMethods}
                  onDelete={() => { return deleteReview(index); }}
                />
                <hr className="dm-panel-hr mt-2" />
              </div>
            );
          })}

          <div className="text-right mt-3">
            <button
              type="button"
              className="btn btn-primary orange-outline-button ml-auto mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              disabled={!formState.isDirty}
              type="submit"
              className="btn orange-button-sm mr-3"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ReviewsPage;
